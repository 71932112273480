import { Box, CircularProgress, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Alert } from '@material-ui/lab';
import React, { useState } from 'react';

import { ClientExternalFragment } from '../../graphql';
import { resyncClientContacts } from '../../modules/admin';
import { AlertSeverity, IntegrationType } from '../../utils/constants';
import { getApiErrorMessage } from '../../utils/errors';

interface Props {
  client: ClientExternalFragment;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const ResyncClientContactsModal: React.FC<Props> = ({ client, isOpen, setIsOpen }) => {
  const [alert, setAlert] = useState<AlertData | undefined>();
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    setAlert(undefined);
    setIsLoading(false);
    setIsOpen(false);
  };

  const onConfirm = async () => {
    setAlert(undefined);
    setIsLoading(true);
    try {
      await resyncClientContacts(client.id);
      setAlert({
        severity: AlertSeverity.SUCCESS,
        message: 'We are now resyncing your CRM data',
      });
    } catch (err) {
      const errorMessage = getApiErrorMessage(err);
      setAlert({
        severity: AlertSeverity.ERROR,
        message: errorMessage,
      });
    } finally {
      setIsLoading(false);
      handleClose();
    }
  };

  const selectedHubspotContactLists = (client.hubspotIntegration?.mapping as IntegrationMapping)?.selectedListIds;

  return (
    <Dialog open={isOpen} onClose={handleClose} aria-labelledby="resync-contacts">
      <DialogTitle>Resync Data from {client.integrationType}?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography>
            By selecting CONFIRM, we will proceed to
            {client.integrationType === IntegrationType.HubSpot && selectedHubspotContactLists?.length
              ? ` resync in the same ${selectedHubspotContactLists.length} contact lists you have originally selected from ${client.integrationType}`
              : ` resync data from ${client.integrationType}, include any new contacts added since our last sync.`}
          </Typography>
          <Box marginTop={1}>
            <Typography variant="subtitle1">
              Please be assured that this will not impact the existing job change notifications and enriched data in
              Warmly.
            </Typography>
          </Box>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {isLoading && (
          <Box marginTop={1}>
            <CircularProgress color="primary" />
          </Box>
        )}
        {!(alert?.severity === AlertSeverity.SUCCESS) ? (
          <>
            <Button onClick={handleClose} variant="outlined" color="primary" disabled={isLoading}>
              Cancel
            </Button>
            <Button onClick={onConfirm} variant="contained" color="primary" disabled={isLoading}>
              Confirm
            </Button>
          </>
        ) : (
          <Button onClick={handleClose} variant="outlined" color="primary" disabled={isLoading}>
            Ok
          </Button>
        )}
      </DialogActions>
      <Box>{alert && <Alert severity={alert.severity}>{alert.message}</Alert>}</Box>
    </Dialog>
  );
};

export default ResyncClientContactsModal;
