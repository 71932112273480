import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Box, Button, Collapse, Grid, IconButton, Link, Popover, TableCell, TableRow } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { AxiosError } from 'axios';
import React, { useContext, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useHistory } from 'react-router-dom';

import { LoadingAndAlertContext } from '../../..';
import { UserContext } from '../../../components/auth/UserRouter';
import { ClientContactExternalFragment, UserFragment } from '../../../graphql';
import { GQLHooks } from '../../../graphql/hasura/react';
import { logError, setAmplitudeEvent } from '../../../modules/analytics';
import { approveHubspotContact, approveSalesforceContact } from '../../../modules/cloudFunctions';
import {
  AlertSeverity,
  AmplitudeEventType,
  ClientJobChangeAction,
  IntegrationType,
  JobChangeStatusIconMap,
  JobChangeStatusMap,
  NavigationPath,
  UserRole,
  WarmlyColor,
  warmlyCustomErrorPrefix,
} from '../../../utils/constants';
import { getClientContactName, getDateStringFromTimestampString, getPreviousJob, wait } from '../../../utils/functions';
import { ClientContext } from '../../Main';
import NewJobChangeCrmAction from './NewJobChangeCrmAction';
import { removeEmailAdditions } from './NewJobChangeEmails';
import { NewJobChangeEmail, NewJobChangeOtherEmails } from './NewJobChangeEmails';
import TheOrgFindOrgChartButton from './TheOrgFindOrgChartButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    expanded: {
      backgroundColor: WarmlyColor.LIGHT_GRAY,
    },
    tableCell: {
      fontSize: '0.85rem',
      padding: '0px 4px 0px 4px',
      '&:last-child': {
        paddingRight: 0,
      },
    },
    tableCellBold: {
      fontSize: '0.85rem',
      padding: '0px 4px 0px 4px',
      fontWeight: 500,
    },
    avatarSmall: {
      width: 22,
      height: 22,
      marginRight: 6,
    },
    companyDomainLink: {
      cursor: 'pointer',
      marginRight: 8,
      color: WarmlyColor.BRIGHT_BLUE,
    },
    grayText: {
      color: WarmlyColor.GRAY,
      fontSize: '0.7rem',
    },
    reachOutIconButton: {
      padding: '6px',
    },
  })
);

const FallbackUnknown = () => <Box fontStyle="italic">unknown</Box>;

interface Props {
  clientContact: ClientContactExternalFragment;
  refetchData?: () => void;
}

const jobChangeUnassignedText = 'unassigned';

const NewJobChangeItem: React.FC<Props> = ({ clientContact, refetchData }) => {
  const classes = useStyles();

  const { user } = useContext(UserContext);
  const { selectedClient } = useContext(ClientContext);
  const { setSnackbarAlertData } = useContext(LoadingAndAlertContext);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const history = useHistory();

  const [unassignedPopoverAnchorEl, setUnassignedPopoverAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleClickUnassigned = (event: React.MouseEvent<HTMLButtonElement>) => {
    setUnassignedPopoverAnchorEl(event.currentTarget);
  };
  const handleCloseUnassigned = () => {
    setUnassignedPopoverAnchorEl(null);
  };

  const onClickSetupOwnerMapping = () => {
    history.push(NavigationPath.USERS);
  };

  const currentJob = clientContact.contactData?.currentJob;
  const [updateJobChangeById] = GQLHooks.Fragments.ClientContactJobChange.useUpdateById();

  const { previousCompanyName, previousCompanyTitle } = getPreviousJob(clientContact);
  const jobChangeCompanyName = currentJob?.companyName;
  const jobChangeTitle = currentJob?.title;
  const jobChangeStatus = clientContact.currentJobChange?.status;
  const startDate = currentJob?.startDateText;
  const employeeCount = currentJob?.company?.employeeCount;
  const hqLocation = currentJob?.company?.hqLocation;
  const websiteDomain = currentJob?.company?.websiteDomain;

  const jobChangeOwnerName = clientContact.currentJobChange?.ownerUser
    ? `${clientContact.currentJobChange?.ownerUser.firstName} ${clientContact.currentJobChange?.ownerUser.lastName}`
    : jobChangeUnassignedText;

  const linkedinUrl = clientContact.contactData?.linkedinUrl || clientContact.linkedinUrl;

  const allContactEmails = clientContact.contactData?.contactEmails || [];
  const validContactEmails = allContactEmails.filter((contactEmail) => contactEmail.isValid);
  const foundEmailToDisplay =
    validContactEmails.length > 0
      ? `${validContactEmails[validContactEmails.length - 1]?.email} ✅`
      : allContactEmails[allContactEmails.length - 1]?.email;
  const email = allContactEmails.length > 0 ? foundEmailToDisplay : clientContact.crmEmail || 'no email detected';

  const onClickEmail = (eventType: AmplitudeEventType) => {
    if (user.role === UserRole.CLIENT) {
      // send to amplitude - click email icon or text
      setAmplitudeEvent({
        userId: user.id,
        event: eventType,
        eventProperties: {
          email: email,
          path_name: window.location.pathname,
        },
      });
    }
  };

  const onClickLinkedin = () => {
    if (user.role === UserRole.CLIENT) {
      setAmplitudeEvent({
        userId: user.id,
        event: AmplitudeEventType.CLICK_LINKEDIN_PROFILE_ICON,
        eventProperties: {
          linkedin_url: linkedinUrl,
          path_name: window.location.pathname,
        },
      });
    }
  };

  const setJobChangeAmplitudeEvent = (
    user: UserFragment,
    clientContact: ClientContactExternalFragment,
    event: AmplitudeEventType
  ) => {
    setAmplitudeEvent({
      userId: user.id,
      event,
      userProperties: {
        contact_name: getClientContactName(clientContact),
        // TODO Decide what email we're actually recording
        contact_email: clientContact.crmEmail,
        client_contact_id: clientContact.id,
        path_name: window.location.pathname,
      },
    });
  };

  const onClickApprove = async () => {
    try {
      setIsLoading(true);
      const clientContactId = clientContact.id;
      const clientId = clientContact.clientId;

      if (clientContactId && clientId) {
        if (selectedClient.integrationType === IntegrationType.HubSpot) {
          // Wait at least 1 seconds so the user can see that the contact is being grayed out and processed by our server
          // Otherwise if it's too fast the contact will just disappear suddenly, which is a jarring UX
          await Promise.all([approveHubspotContact(clientContactId), wait(1000)]);
        } else if (selectedClient.integrationType === IntegrationType.Salesforce) {
          await Promise.all([approveSalesforceContact(clientContactId), wait(1000)]);
        }

        if (user.role === UserRole.CLIENT) {
          setJobChangeAmplitudeEvent(user, clientContact, AmplitudeEventType.APPROVE_JOB_CHANGE);
        }

        refetchData && refetchData();
      }

      const alertData: AlertData = {
        severity: AlertSeverity.SUCCESS,
        message: `Contact successfully synced! Job change info moved to Past Notifications`,
      };

      setSnackbarAlertData(alertData);
    } catch (err) {
      let errorMessage = 'Error occurred while syncing back CRM data, our team has been notified of the issue';
      let alertSeverity = AlertSeverity.ERROR;
      const responseMessage = (err as AxiosError).response?.data?.message as string | undefined;

      if (responseMessage?.startsWith(warmlyCustomErrorPrefix)) {
        errorMessage = responseMessage.replace(warmlyCustomErrorPrefix, '');
        alertSeverity = AlertSeverity.WARNING;
      } else {
        logError(err, 'Error while syncing back CRM data');
      }

      const alertData: AlertData = {
        severity: alertSeverity,
        message: errorMessage,
      };

      setSnackbarAlertData(alertData);
      setIsLoading(false);
    }
  };

  const onClickDismiss = async () => {
    try {
      setIsLoading(true);

      await updateJobChangeById({
        clientContactJobChangeId: clientContact.currentJobChange!.id,
        set: {
          clientAction: ClientJobChangeAction.DISMISS,
          clientActionUpdatedAt: new Date().toISOString(),
        },
      });

      refetchData && refetchData();

      if (user.role === UserRole.CLIENT) {
        // send to amplitude dismiss job change event
        setJobChangeAmplitudeEvent(user, clientContact, AmplitudeEventType.DISMISS_JOB_CHANGE);
      }

      const alertData: AlertData = {
        severity: AlertSeverity.INFO,
        message: `Job change notification dismissed to Past Notifications`,
      };

      setSnackbarAlertData(alertData);
    } catch (err) {
      logError(err, 'Error occurred while dismissing job change');
      const alertData: AlertData = {
        severity: AlertSeverity.ERROR,
        message: `Error occurred while dismissing notification`,
      };

      setSnackbarAlertData(alertData);
      setIsLoading(false);
    }
  };

  return (
    <React.Fragment>
      <TableRow className={open ? classes.expanded : ''}>
        <TableCell style={{ padding: '0px 6px 0px 6px' }}>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell className={open ? classes.tableCellBold : classes.tableCell}>
          {getDateStringFromTimestampString(clientContact.currentJobChange?.createdAt) ||
            getDateStringFromTimestampString(clientContact.contactData?.updatedAt)}
        </TableCell>
        <TableCell className={open ? classes.tableCellBold : classes.tableCell}>
          <Box display="flex" flexDirection="row" flexWrap="nowrap" justifyContent="flex-start" alignItems="center">
            <Avatar className={classes.avatarSmall} />
            <Box>
              {jobChangeOwnerName === jobChangeUnassignedText ? (
                <>
                  <Box style={{ cursor: 'pointer' }} color={WarmlyColor.GRAY} onClick={handleClickUnassigned}>
                    {jobChangeOwnerName}
                  </Box>
                  <Popover
                    open={Boolean(unassignedPopoverAnchorEl)}
                    anchorEl={unassignedPopoverAnchorEl}
                    onClose={handleCloseUnassigned}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                  >
                    <Box padding={2}>
                      <Button variant="contained" color="primary" onClick={onClickSetupOwnerMapping}>
                        Set up owner mapping
                      </Button>
                    </Box>
                  </Popover>
                </>
              ) : (
                <Box>{jobChangeOwnerName}</Box>
              )}
            </Box>
          </Box>
        </TableCell>
        <TableCell
          className={open ? classes.tableCellBold : classes.tableCell}
          // TODO: make truncating with ellipses work
          // style={{ maxWidth: CONTACT_COL_MAX_WIDTH }}
        >
          <Box display="flex" flexDirection="row" flexWrap="nowrap" justifyContent="flex-start" alignItems="center">
            <Avatar className={classes.avatarSmall} src={clientContact.contactData?.profilePhotoUrl || undefined} />
            <Box display="flex" flexDirection="column">
              <Box>{getClientContactName(clientContact)}</Box>
              <Box className={classes.grayText}>
                <NewJobChangeEmail email={email} collapseExpanded={open} />
              </Box>
            </Box>
          </Box>
        </TableCell>
        <TableCell className={open ? classes.tableCellBold : classes.tableCell}>
          <Grid container direction="column">
            <Grid item>
              {jobChangeStatus ? JobChangeStatusIconMap[jobChangeStatus] : ''}
              {jobChangeStatus ? JobChangeStatusMap[jobChangeStatus] : ''}
            </Grid>
            <Grid item className={classes.grayText}>
              {startDate && `Started: ${startDate}`}
            </Grid>
          </Grid>
        </TableCell>
        <TableCell className={open ? classes.tableCellBold : classes.tableCell} style={{ width: '200px' }}>
          <Box
            display="flex"
            flexDirection="row"
            flexWrap="noWrap"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
          >
            <Box>
              {websiteDomain ? (
                <Link
                  href={`https://${websiteDomain}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  underline="always"
                  className={classes.companyDomainLink}
                >
                  {jobChangeCompanyName}
                </Link>
              ) : jobChangeCompanyName ? (
                <Box color={WarmlyColor.BRIGHT_BLUE}>{jobChangeCompanyName}</Box>
              ) : (
                <FallbackUnknown />
              )}
            </Box>
            <Box marginLeft="5px">
              <TheOrgFindOrgChartButton
                companyName={jobChangeCompanyName as string}
                disabled={Boolean(!jobChangeCompanyName)}
              />
            </Box>
          </Box>
        </TableCell>
        <TableCell className={open ? classes.tableCellBold : classes.tableCell}>
          {jobChangeTitle || <FallbackUnknown />}
        </TableCell>
        <TableCell className={open ? classes.tableCellBold : classes.tableCell}>
          {hqLocation || <FallbackUnknown />}
        </TableCell>
        <TableCell className={open ? classes.tableCellBold : classes.tableCell} align="center">
          {employeeCount ? employeeCount.toLocaleString() : <FallbackUnknown />}
        </TableCell>
        <TableCell className={classes.tableCell}>
          <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
            <CopyToClipboard
              text={removeEmailAdditions(email) || ''}
              onCopy={() => {
                if (email) {
                  const alertData: AlertData = {
                    severity: AlertSeverity.SUCCESS,
                    message: `${removeEmailAdditions(email)} copied to clipboard!`,
                  };
                  onClickEmail(AmplitudeEventType.CLICK_EMAIL_ICON);
                  setSnackbarAlertData(alertData);
                }
              }}
            >
              <IconButton disabled={!Boolean(email)} className={classes.reachOutIconButton}>
                <MailOutlineIcon style={{ color: WarmlyColor.BLACK }} />
              </IconButton>
            </CopyToClipboard>
            <Link
              href={linkedinUrl ? linkedinUrl : ''}
              target="_blank"
              rel="noopener noreferrer"
              onClick={onClickLinkedin}
            >
              <IconButton disabled={!Boolean(linkedinUrl)} className={classes.reachOutIconButton}>
                <FontAwesomeIcon
                  color={linkedinUrl ? WarmlyColor.LINKEDIN_BLUE : WarmlyColor.DISABLED_GRAY}
                  icon={faLinkedin}
                />
              </IconButton>
            </Link>
          </Box>
        </TableCell>
        <TableCell className={classes.tableCell}>
          <Box padding="6px 4px">
            <NewJobChangeCrmAction
              client={selectedClient}
              clientContact={clientContact}
              isLoading={isLoading}
              onClickApprove={onClickApprove}
              onClickDismiss={onClickDismiss}
            />
          </Box>
        </TableCell>
      </TableRow>
      <TableRow className={open ? classes.expanded : ''}>
        <TableCell className={classes.tableCell} colSpan={3} />
        <TableCell className={classes.tableCell} colSpan={2}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box
              marginLeft="28px"
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              justifyItems="flex-start"
            >
              <NewJobChangeOtherEmails clientContact={clientContact} primaryEmail={email} />
            </Box>
          </Collapse>
        </TableCell>
        <TableCell className={classes.tableCell}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box display="flex" flexDirection="column" alignItems="flex-start" justifyItems="flex-start">
              <Box fontWeight="fontWeight">Former company</Box>
              <Box
                display="flex"
                flexDirection="row"
                flexWrap="noWrap"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Box>
                  {previousCompanyName ? (
                    <Box color={WarmlyColor.BRIGHT_BLUE} marginRight={1}>
                      {previousCompanyName}
                    </Box>
                  ) : (
                    <FallbackUnknown />
                  )}
                </Box>
                <Box marginLeft="5px">
                  <TheOrgFindOrgChartButton
                    companyName={previousCompanyName as string}
                    disabled={Boolean(!previousCompanyName)}
                  />
                </Box>
              </Box>
            </Box>
          </Collapse>
        </TableCell>
        <TableCell className={classes.tableCell}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box display="flex" flexDirection="column" alignItems="flex-start" justifyItems="flex-start">
              <Box fontWeight="fontWeight">Former job title</Box>
              <Box>{previousCompanyTitle || <FallbackUnknown />}</Box>
            </Box>
          </Collapse>
        </TableCell>
        <TableCell className={classes.tableCell} colSpan={4} />
      </TableRow>
    </React.Fragment>
  );
};

export default NewJobChangeItem;
