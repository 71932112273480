import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';

import { LoadingAndAlertContext } from '../..';
import { UserFragment } from '../../graphql';
import { GQLHooks } from '../../graphql/hasura/react';
import { analyticsSetUserOnLogin, setAmplitudeEvent, setLogUser } from '../../modules/analytics';
import DeactivatedAccount from '../../pages/DeactivatedAccount';
import Main from '../../pages/Main';
import QADashboardRouter from '../../pages/qa/QADashboardRouter';
import {
  AmplitudeEventType,
  ClientSubscriptionStatus,
  CURRENT_ENVIRONMENT,
  Environment,
  UserRole,
} from '../../utils/constants';
import { consoleLogDev } from '../../utils/errors';
import { AuthContext } from './AuthProvider';

export const UserContext = React.createContext<UserContextData>({} as UserContextData);

const UserRouter: React.FC = () => {
  const { authUser } = useContext(AuthContext);
  const [user, setUser] = useState<UserFragment | undefined>();
  const [analyticsLogged, setAnalyticsLogged] = useState(false);
  const { setIsLoading } = useContext(LoadingAndAlertContext);
  const { data, error } = GQLHooks.Fragments.User.useSubscribeToObjects({
    variables: {
      where: {
        firebaseUid: {
          _eq: authUser!.uid,
        },
      },
    },
  });

  useEffect(() => {
    if (data?.user?.[0]) {
      const loggedInUser = data.user[0];
      setUser(loggedInUser);
    } else {
      setUser(undefined);
    }
  }, [data]);

  useEffect(() => {
    if (user?.id) {
      // On initial login (when user.id is first set) or if user has changed,
      // we set Loading first
      setIsLoading(false);
    }
  }, [user, setIsLoading]);

  useEffect(() => {
    // Ensure we only log user sign-in once
    if (user && !analyticsLogged) {
      if (CURRENT_ENVIRONMENT === Environment.Production) {
        window['FS']?.identify(user.id, {
          displayName: `${user.firstName} ${user.lastName}`,
          email: user.email,
          clientName: user.client.name,
          clientId: user.client.id,
        });
        setLogUser({ id: user.id, email: user.email, userType: user.role });
        analyticsSetUserOnLogin({
          userId: user.id,
          firstName: user.firstName,
          lastName: user.lastName,
          clientName: user.client.name,
          clientId: user.client.id,
          userType: user.role as UserRole,
          paymentStatus: '',
          status: '',
          fullName: `${user.firstName}_${user.lastName}`,
          companyName_fullName: `${user.client.name}_${user.firstName}_${user.lastName}`,
        });
        const twentyMinutesAgo = moment(new Date()).subtract(20, 'm').toDate();
        const userCreatedAtDate = new Date(user.createdAt);

        // only send amplitude login event up if user is client
        // if user just created account then don't send a login event to track as part of DAU/WAU
        if (user.role === UserRole.CLIENT && new Date(user.createdAt) < twentyMinutesAgo) {
          setAmplitudeEvent({
            userId: user.id,
            event: AmplitudeEventType.LOGIN,
            userProperties: {
              first_name: user.firstName,
              last_name: user.lastName,
              client_name: user.client.name,
              client_id: user.client.id,
              email: user.email,
              date_created: userCreatedAtDate,
            },
          });
        }
      }
      setAnalyticsLogged(true);
    }
  }, [user, analyticsLogged]);

  if (error) {
    consoleLogDev('UserRouter error', error);
  }

  if (user?.role === UserRole.TEST || user?.role === UserRole.APPROVER_NEW || user?.role === UserRole.QA_NEW) {
    return (
      <UserContext.Provider value={{ user }}>
        <QADashboardRouter />
      </UserContext.Provider>
    );
  }

  if (user?.client.subscriptionStatus === ClientSubscriptionStatus.Deactivated) {
    return <DeactivatedAccount />;
  }

  if (user?.role === UserRole.CLIENT || user?.role === UserRole.INTERNAL) {
    return (
      <UserContext.Provider value={{ user }}>
        <Main />
      </UserContext.Provider>
    );
  } else {
    return null;
  }
};

export default UserRouter;
