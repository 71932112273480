import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/functions';
import 'firebase/storage';

import firebase from 'firebase/app';

import {
  CURRENT_ENVIRONMENT,
  Environment,
  REACT_APP_WARMLY_LOCAL_ENVIRONMENT,
} from '../utils/constants';

export const firebaseConfig = {
  [Environment.Production]: {
    apiKey: process.env.REACT_APP_FIREBASE_API_PRODUCTION,
    authDomain: 'jobattrition.firebaseapp.com',
    databaseURL: 'https://jobattrition.firebaseio.com',
    projectId: 'jobattrition',
    storageBucket: 'jobattrition.appspot.com',
    messagingSenderId: '1041662158336',
    appId: '1:1041662158336:web:2604715aa7c11cd54e5ae5',
    measurementId: 'G-ZE0J1XQDLE',
  },
  [Environment.Staging]: {
    apiKey: process.env.REACT_APP_FIREBASE_API_STAGING,
    authDomain: 'warmly-staging.firebaseapp.com',
    databaseURL: 'https://warmly-staging.firebaseio.com',
    projectId: 'warmly-staging',
    storageBucket: 'warmly-staging.appspot.com',
    messagingSenderId: '466262664744',
    appId: '1:466262664744:web:7c8b8860756d8857c1f71d',
    measurementId: 'G-BK8CJYNH2M',
  },
  [Environment.Development]: {
    apiKey: process.env.REACT_APP_FIREBASE_API_DEV,
    authDomain: 'warmly-staging.firebaseapp.com',
    databaseURL: 'https://warmly-staging.firebaseio.com',
    projectId: 'warmly-staging',
    storageBucket: 'warmly-staging.appspot.com',
    messagingSenderId: '466262664744',
    appId: '1:466262664744:web:7c8b8860756d8857c1f71d',
    measurementId: 'G-BK8CJYNH2M',
  },
};

const fbConfig = firebaseConfig[REACT_APP_WARMLY_LOCAL_ENVIRONMENT || CURRENT_ENVIRONMENT];

if (REACT_APP_WARMLY_LOCAL_ENVIRONMENT) {
  console.log('🚨  🚨  🚨  🚨  🚨');
  console.log(`Firebase local env is set as ${REACT_APP_WARMLY_LOCAL_ENVIRONMENT}`);
}

firebase.initializeApp(fbConfig);

export const firebaseAuth = firebase.auth();

export const functions = firebase.functions();
