import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormGroup,
  Grid,
  Input,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useState } from 'react';

import SelectDropdown from '../../../components/SelectDropdown';
import { adminCreateNewUser } from '../../../modules/admin';
import { AlertSeverity, UserQaQualification, UserRole } from '../../../utils/constants';
import { getApiErrorMessage } from '../../../utils/errors';
import ClientSelect from './ClientSelect';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const CreateUser: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [userCreated, setUserCreated] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [userRole, setUserRole] = useState('');
  const [userClientId, setUserClientId] = useState('');
  const [userQaQualifications, setUserQaQualifications] = useState<UserQaQualification[]>([]);

  const onClose = () => {
    setShowModal(false);
    setIsLoading(false);
    setUserCreated(false);
    setEmail('');
    setPassword('');
    setCompanyName('');
    setFirstName('');
    setLastName('');
    setErrorMessage('');
    setUserRole('');
    setUserClientId('');
    setUserQaQualifications([]);
  };

  const onSubmit = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    const newUserEmail = email.trim().toLowerCase();
    const newUserFirstName = firstName.trim();
    const newUserLastName = lastName.trim();
    const newUserClientName = companyName.trim();
    const newUserClientId = userClientId;
    const newUserRole = userRole;

    let validationErrorMessage = '';
    if (
      !newUserEmail ||
      !newUserFirstName ||
      !newUserLastName ||
      (!newUserClientName && !newUserClientId) ||
      !password.trim()
    ) {
      validationErrorMessage = 'Required fields: ';
      let emptyFields = [];
      if (!email) {
        emptyFields.push('email');
      }
      if (!newUserFirstName) {
        emptyFields.push('first name');
      }
      if (!newUserLastName) {
        emptyFields.push('last name');
      }
      if (!newUserClientName || !newUserClientId) {
        emptyFields.push('company');
      }
      if (!newUserRole) {
        emptyFields.push('user role');
      } else if (!password.trim() || password.length < 6) {
        emptyFields.push('password (must be at least 6 characters)');
      }

      validationErrorMessage += emptyFields.join(', ');
      validationErrorMessage += '. ';
    }

    if ((newUserRole === UserRole.QA_NEW || newUserRole === UserRole.APPROVER_NEW) && !userQaQualifications.length) {
      validationErrorMessage += 'QA users and approvers need to have QA qualifications. ';
    }

    if (
      newUserRole === UserRole.QA_NEW &&
      userQaQualifications.find(
        (qualification) =>
          qualification === UserQaQualification.APPROVE_ENRICH_CONTACT ||
          qualification === UserQaQualification.APPROVE_ENRICH_CONTACT_EXPERT
      )
    ) {
      validationErrorMessage += 'QA users cannot have approver qualifications. ';
    } else if (
      newUserRole === UserRole.APPROVER_NEW &&
      userQaQualifications.find(
        (qualification) =>
          qualification === UserQaQualification.ENRICH_CONTACT ||
          qualification === UserQaQualification.ENRICH_CONTACT_EXPERT
      )
    ) {
      validationErrorMessage += 'Approver users cannot have enrich contact qualifications. ';
    }

    if (validationErrorMessage) {
      setErrorMessage(validationErrorMessage);
      return;
    }

    try {
      setErrorMessage('');
      setIsLoading(true);

      await adminCreateNewUser({
        firstName: newUserFirstName,
        lastName: newUserLastName,
        email: newUserEmail,
        password,
        role: newUserRole as UserRole,
        clientId: newUserClientId || undefined,
        clientName: newUserClientName || undefined,
        qaQualifications: userQaQualifications,
      });

      setUserCreated(true);
    } catch (err) {
      const errMessage = getApiErrorMessage(err);
      setErrorMessage(errMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const onSelectUserRole = (newStatus: string) => {
    setUserRole(newStatus);
  };

  const userRoleOptions: SelectOption[] = Object.values(UserRole)
    .filter((value) => {
      // deprecated old QA roles
      return !(value === UserRole.APPROVER) && !(value === UserRole.QA);
    })
    .map((value) => {
      return { value, label: value };
    });

  const handleChangeQaQualifications = (event: React.ChangeEvent<{ value: unknown }>) => {
    setUserQaQualifications(event.target.value as UserQaQualification[]);
  };

  return (
    <>
      <Button variant="contained" color="primary" onClick={() => setShowModal(true)}>
        Create User (Be very careful when using this)
      </Button>
      <Dialog open={showModal} onClose={onClose} fullWidth>
        <DialogTitle>Create New User</DialogTitle>
        <DialogContent>
          {!userCreated && (
            <FormGroup>
              <Box marginTop={3}>
                <TextField
                  label="First Name*"
                  value={firstName}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setFirstName(event.target.value);
                  }}
                  fullWidth
                />
              </Box>

              <Box marginTop={3}>
                <TextField
                  label="Last Name*"
                  value={lastName}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setLastName(event.target.value);
                  }}
                  fullWidth
                />
              </Box>

              <Box marginTop={3}>
                Create a new client:
                <TextField
                  label="Company name*"
                  value={companyName}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setUserClientId('');
                    setCompanyName(event.target.value);
                  }}
                  fullWidth
                />
              </Box>

              <Box marginTop={3}>
                Or select a client:
                <ClientSelect
                  selectedClientId={userClientId}
                  onSelectClientId={(clientId) => {
                    setCompanyName('');
                    setUserClientId(clientId);
                  }}
                />
              </Box>

              <Box marginTop={3}>
                User Role*
                <SelectDropdown options={userRoleOptions} selectedValue={userRole} onSelect={onSelectUserRole} />
              </Box>

              <Box marginTop={3}>
                QA Qualifications (Required for QA/Approvers)
                <Select
                  multiple
                  fullWidth
                  value={userQaQualifications}
                  onChange={handleChangeQaQualifications}
                  input={<Input />}
                  renderValue={(selected) => (
                    <Box display="flex" flexWrap="wrap">
                      {(selected as string[]).map((value) => (
                        <Chip
                          key={value}
                          label={value}
                          style={{
                            margin: 2,
                          }}
                        />
                      ))}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  {Object.values(UserQaQualification).map((qualification) => (
                    <MenuItem key={qualification} value={qualification}>
                      {qualification}
                    </MenuItem>
                  ))}
                </Select>
              </Box>

              <Box marginTop={3}>
                <TextField
                  label="Email*"
                  type="email"
                  value={email}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setEmail(event.target.value);
                  }}
                  fullWidth
                />
              </Box>

              <Box marginTop={3}>
                <TextField
                  label="Password*"
                  type="password"
                  value={password}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setPassword(event.target.value);
                  }}
                  fullWidth
                />
              </Box>

              <Box marginTop={3}>
                <Grid container justify="flex-end">
                  {isLoading && (
                    <Box marginRight={2}>
                      <CircularProgress />
                    </Box>
                  )}
                  <Button variant="contained" color="primary" type="submit" onClick={onSubmit} disabled={isLoading}>
                    Create Account
                  </Button>
                </Grid>
              </Box>
            </FormGroup>
          )}
          {userCreated && (
            <>
              <Alert severity={AlertSeverity.SUCCESS}>User successfully created</Alert>
              <Box marginY={1} textAlign="right">
                <Button variant="contained" color="primary" type="submit" onClick={onClose}>
                  Ok
                </Button>
              </Box>
            </>
          )}
          <Box marginTop={3}>{errorMessage && <Alert severity={AlertSeverity.ERROR}>{errorMessage}</Alert>}</Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CreateUser;
