import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  bigint: number;
  date: string;
  jsonb: { [key: string]: any };
  timestamptz: string;
  uuid: string;
};

/** expression to compare columns of type Boolean. All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: Maybe<Scalars['Boolean']>;
  _gt?: Maybe<Scalars['Boolean']>;
  _gte?: Maybe<Scalars['Boolean']>;
  _in?: Maybe<Array<Scalars['Boolean']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Boolean']>;
  _lte?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Scalars['Boolean']>;
  _nin?: Maybe<Array<Scalars['Boolean']>>;
};

/** expression to compare columns of type Int. All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: Maybe<Scalars['Int']>;
  _gt?: Maybe<Scalars['Int']>;
  _gte?: Maybe<Scalars['Int']>;
  _in?: Maybe<Array<Scalars['Int']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Int']>;
  _lte?: Maybe<Scalars['Int']>;
  _neq?: Maybe<Scalars['Int']>;
  _nin?: Maybe<Array<Scalars['Int']>>;
};

/** expression to compare columns of type String. All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: Maybe<Scalars['String']>;
  _gt?: Maybe<Scalars['String']>;
  _gte?: Maybe<Scalars['String']>;
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['String']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['String']>;
  _lte?: Maybe<Scalars['String']>;
  _neq?: Maybe<Scalars['String']>;
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['String']>>;
  _nlike?: Maybe<Scalars['String']>;
  _nsimilar?: Maybe<Scalars['String']>;
  _similar?: Maybe<Scalars['String']>;
};

/** expression to compare columns of type bigint. All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: Maybe<Scalars['bigint']>;
  _gt?: Maybe<Scalars['bigint']>;
  _gte?: Maybe<Scalars['bigint']>;
  _in?: Maybe<Array<Scalars['bigint']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['bigint']>;
  _lte?: Maybe<Scalars['bigint']>;
  _neq?: Maybe<Scalars['bigint']>;
  _nin?: Maybe<Array<Scalars['bigint']>>;
};

/** columns and relationships of "client" */
export type Client = {
  __typename?: 'client';
  /** An array relationship */
  clientContacts: Array<ClientContact>;
  /** An aggregated array relationship */
  clientContacts_aggregate: ClientContact_Aggregate;
  /** An array relationship */
  clientEmailReports: Array<ClientEmailReport>;
  /** An aggregated array relationship */
  clientEmailReports_aggregate: ClientEmailReport_Aggregate;
  /** An array relationship */
  clientNotificationEmails: Array<ClientNotificationEmail>;
  /** An aggregated array relationship */
  clientNotificationEmails_aggregate: ClientNotificationEmail_Aggregate;
  codeName?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  /** An array relationship */
  csvUploads: Array<CsvUpload>;
  /** An aggregated array relationship */
  csvUploads_aggregate: CsvUpload_Aggregate;
  firestoreId?: Maybe<Scalars['String']>;
  /** An object relationship */
  hubspotIntegration?: Maybe<HubspotIntegration>;
  hubspotIntegrationId?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  integrationType?: Maybe<Scalars['String']>;
  /** An object relationship */
  lastCsvUpload?: Maybe<CsvUpload>;
  lastCsvUploadId?: Maybe<Scalars['uuid']>;
  name: Scalars['String'];
  ownerMappingStatus?: Maybe<Scalars['String']>;
  qaStatus?: Maybe<Scalars['String']>;
  qaStatusUpdatedAt?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  salesforceIntegration?: Maybe<SalesforceIntegration>;
  salesforceIntegrationId?: Maybe<Scalars['uuid']>;
  subscriptionStatus?: Maybe<Scalars['String']>;
  syncStatus?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
  /** An array relationship */
  userInvites: Array<UserInvite>;
  /** An aggregated array relationship */
  userInvites_aggregate: UserInvite_Aggregate;
  /** An array relationship */
  users: Array<User>;
  /** An aggregated array relationship */
  users_aggregate: User_Aggregate;
};

/** columns and relationships of "client" */
export type ClientClientContactsArgs = {
  distinct_on?: Maybe<Array<ClientContact_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ClientContact_Order_By>>;
  where?: Maybe<ClientContact_Bool_Exp>;
};

/** columns and relationships of "client" */
export type ClientClientContacts_AggregateArgs = {
  distinct_on?: Maybe<Array<ClientContact_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ClientContact_Order_By>>;
  where?: Maybe<ClientContact_Bool_Exp>;
};

/** columns and relationships of "client" */
export type ClientClientEmailReportsArgs = {
  distinct_on?: Maybe<Array<ClientEmailReport_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ClientEmailReport_Order_By>>;
  where?: Maybe<ClientEmailReport_Bool_Exp>;
};

/** columns and relationships of "client" */
export type ClientClientEmailReports_AggregateArgs = {
  distinct_on?: Maybe<Array<ClientEmailReport_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ClientEmailReport_Order_By>>;
  where?: Maybe<ClientEmailReport_Bool_Exp>;
};

/** columns and relationships of "client" */
export type ClientClientNotificationEmailsArgs = {
  distinct_on?: Maybe<Array<ClientNotificationEmail_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ClientNotificationEmail_Order_By>>;
  where?: Maybe<ClientNotificationEmail_Bool_Exp>;
};

/** columns and relationships of "client" */
export type ClientClientNotificationEmails_AggregateArgs = {
  distinct_on?: Maybe<Array<ClientNotificationEmail_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ClientNotificationEmail_Order_By>>;
  where?: Maybe<ClientNotificationEmail_Bool_Exp>;
};

/** columns and relationships of "client" */
export type ClientCsvUploadsArgs = {
  distinct_on?: Maybe<Array<CsvUpload_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CsvUpload_Order_By>>;
  where?: Maybe<CsvUpload_Bool_Exp>;
};

/** columns and relationships of "client" */
export type ClientCsvUploads_AggregateArgs = {
  distinct_on?: Maybe<Array<CsvUpload_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CsvUpload_Order_By>>;
  where?: Maybe<CsvUpload_Bool_Exp>;
};

/** columns and relationships of "client" */
export type ClientUserInvitesArgs = {
  distinct_on?: Maybe<Array<UserInvite_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserInvite_Order_By>>;
  where?: Maybe<UserInvite_Bool_Exp>;
};

/** columns and relationships of "client" */
export type ClientUserInvites_AggregateArgs = {
  distinct_on?: Maybe<Array<UserInvite_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserInvite_Order_By>>;
  where?: Maybe<UserInvite_Bool_Exp>;
};

/** columns and relationships of "client" */
export type ClientUsersArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};

/** columns and relationships of "client" */
export type ClientUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};

/** columns and relationships of "clientContact" */
export type ClientContact = {
  __typename?: 'clientContact';
  clientId: Scalars['uuid'];
  /** An object relationship */
  contactData?: Maybe<ContactData>;
  contactDataId?: Maybe<Scalars['uuid']>;
  createdAt: Scalars['timestamptz'];
  crmCompanyName?: Maybe<Scalars['String']>;
  /** An array relationship */
  crmData: Array<ClientContactCrmData>;
  /** An aggregated array relationship */
  crmData_aggregate: ClientContactCrmData_Aggregate;
  crmEmail?: Maybe<Scalars['String']>;
  crmEmailIsValid?: Maybe<Scalars['Boolean']>;
  crmEmailValidatedAt?: Maybe<Scalars['timestamptz']>;
  crmLinkedinUrl?: Maybe<Scalars['String']>;
  crmTitle?: Maybe<Scalars['String']>;
  csvUploadId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  currentJobChange?: Maybe<ClientContactJobChange>;
  currentJobChangeId?: Maybe<Scalars['uuid']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  enrichmentHistories: Array<CrmContactEnrichmentHistory>;
  /** An aggregated array relationship */
  enrichmentHistories_aggregate: CrmContactEnrichmentHistory_Aggregate;
  firestoreId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  hubspotId?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  /** An array relationship */
  jobChanges: Array<ClientContactJobChange>;
  /** An aggregated array relationship */
  jobChanges_aggregate: ClientContactJobChange_Aggregate;
  lastName?: Maybe<Scalars['String']>;
  linkedinId?: Maybe<Scalars['String']>;
  linkedinUrl?: Maybe<Scalars['String']>;
  /** An object relationship */
  ownerUser?: Maybe<User>;
  ownerUserId?: Maybe<Scalars['uuid']>;
  qaAssignedAt?: Maybe<Scalars['timestamptz']>;
  salesforceAccountId?: Maybe<Scalars['String']>;
  salesforceId?: Maybe<Scalars['String']>;
  salesforceOwnerId?: Maybe<Scalars['String']>;
  serialId: Scalars['Int'];
  setMonitoredAt?: Maybe<Scalars['timestamptz']>;
  setMonitoredFilterId?: Maybe<Scalars['uuid']>;
  updatedAt: Scalars['timestamptz'];
};

/** columns and relationships of "clientContact" */
export type ClientContactCrmDataArgs = {
  distinct_on?: Maybe<Array<ClientContactCrmData_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ClientContactCrmData_Order_By>>;
  where?: Maybe<ClientContactCrmData_Bool_Exp>;
};

/** columns and relationships of "clientContact" */
export type ClientContactCrmData_AggregateArgs = {
  distinct_on?: Maybe<Array<ClientContactCrmData_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ClientContactCrmData_Order_By>>;
  where?: Maybe<ClientContactCrmData_Bool_Exp>;
};

/** columns and relationships of "clientContact" */
export type ClientContactEnrichmentHistoriesArgs = {
  distinct_on?: Maybe<Array<CrmContactEnrichmentHistory_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CrmContactEnrichmentHistory_Order_By>>;
  where?: Maybe<CrmContactEnrichmentHistory_Bool_Exp>;
};

/** columns and relationships of "clientContact" */
export type ClientContactEnrichmentHistories_AggregateArgs = {
  distinct_on?: Maybe<Array<CrmContactEnrichmentHistory_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CrmContactEnrichmentHistory_Order_By>>;
  where?: Maybe<CrmContactEnrichmentHistory_Bool_Exp>;
};

/** columns and relationships of "clientContact" */
export type ClientContactJobChangesArgs = {
  distinct_on?: Maybe<Array<ClientContactJobChange_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ClientContactJobChange_Order_By>>;
  where?: Maybe<ClientContactJobChange_Bool_Exp>;
};

/** columns and relationships of "clientContact" */
export type ClientContactJobChanges_AggregateArgs = {
  distinct_on?: Maybe<Array<ClientContactJobChange_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ClientContactJobChange_Order_By>>;
  where?: Maybe<ClientContactJobChange_Bool_Exp>;
};

/** columns and relationships of "clientContactCrmData" */
export type ClientContactCrmData = {
  __typename?: 'clientContactCrmData';
  clientContactId: Scalars['uuid'];
  clientId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  crmType?: Maybe<Scalars['String']>;
  fieldLabel?: Maybe<Scalars['String']>;
  fieldName: Scalars['String'];
  fieldValue?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "clientContactCrmData" */
export type ClientContactCrmData_Aggregate = {
  __typename?: 'clientContactCrmData_aggregate';
  aggregate?: Maybe<ClientContactCrmData_Aggregate_Fields>;
  nodes: Array<ClientContactCrmData>;
};

/** aggregate fields of "clientContactCrmData" */
export type ClientContactCrmData_Aggregate_Fields = {
  __typename?: 'clientContactCrmData_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<ClientContactCrmData_Max_Fields>;
  min?: Maybe<ClientContactCrmData_Min_Fields>;
};

/** aggregate fields of "clientContactCrmData" */
export type ClientContactCrmData_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<ClientContactCrmData_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "clientContactCrmData" */
export type ClientContactCrmData_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<ClientContactCrmData_Max_Order_By>;
  min?: Maybe<ClientContactCrmData_Min_Order_By>;
};

/** input type for inserting array relation for remote table "clientContactCrmData" */
export type ClientContactCrmData_Arr_Rel_Insert_Input = {
  data: Array<ClientContactCrmData_Insert_Input>;
  on_conflict?: Maybe<ClientContactCrmData_On_Conflict>;
};

/** Boolean expression to filter rows from the table "clientContactCrmData". All fields are combined with a logical 'AND'. */
export type ClientContactCrmData_Bool_Exp = {
  _and?: Maybe<Array<Maybe<ClientContactCrmData_Bool_Exp>>>;
  _not?: Maybe<ClientContactCrmData_Bool_Exp>;
  _or?: Maybe<Array<Maybe<ClientContactCrmData_Bool_Exp>>>;
  clientContactId?: Maybe<Uuid_Comparison_Exp>;
  clientId?: Maybe<Uuid_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  crmType?: Maybe<String_Comparison_Exp>;
  fieldLabel?: Maybe<String_Comparison_Exp>;
  fieldName?: Maybe<String_Comparison_Exp>;
  fieldValue?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "clientContactCrmData" */
export enum ClientContactCrmData_Constraint {
  /** unique or primary key constraint */
  ClientContactCrmDataCrmTypeFieldNameClientContactIdKey = 'clientContactCrmData_crmType_fieldName_clientContactId_key',
  /** unique or primary key constraint */
  ClientContactCrmDataPkey = 'clientContactCrmData_pkey',
}

/** input type for inserting data into table "clientContactCrmData" */
export type ClientContactCrmData_Insert_Input = {
  clientContactId?: Maybe<Scalars['uuid']>;
  clientId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  crmType?: Maybe<Scalars['String']>;
  fieldLabel?: Maybe<Scalars['String']>;
  fieldName?: Maybe<Scalars['String']>;
  fieldValue?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type ClientContactCrmData_Max_Fields = {
  __typename?: 'clientContactCrmData_max_fields';
  clientContactId?: Maybe<Scalars['uuid']>;
  clientId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  crmType?: Maybe<Scalars['String']>;
  fieldLabel?: Maybe<Scalars['String']>;
  fieldName?: Maybe<Scalars['String']>;
  fieldValue?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "clientContactCrmData" */
export type ClientContactCrmData_Max_Order_By = {
  clientContactId?: Maybe<Order_By>;
  clientId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  crmType?: Maybe<Order_By>;
  fieldLabel?: Maybe<Order_By>;
  fieldName?: Maybe<Order_By>;
  fieldValue?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type ClientContactCrmData_Min_Fields = {
  __typename?: 'clientContactCrmData_min_fields';
  clientContactId?: Maybe<Scalars['uuid']>;
  clientId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  crmType?: Maybe<Scalars['String']>;
  fieldLabel?: Maybe<Scalars['String']>;
  fieldName?: Maybe<Scalars['String']>;
  fieldValue?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "clientContactCrmData" */
export type ClientContactCrmData_Min_Order_By = {
  clientContactId?: Maybe<Order_By>;
  clientId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  crmType?: Maybe<Order_By>;
  fieldLabel?: Maybe<Order_By>;
  fieldName?: Maybe<Order_By>;
  fieldValue?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** response of any mutation on the table "clientContactCrmData" */
export type ClientContactCrmData_Mutation_Response = {
  __typename?: 'clientContactCrmData_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<ClientContactCrmData>;
};

/** input type for inserting object relation for remote table "clientContactCrmData" */
export type ClientContactCrmData_Obj_Rel_Insert_Input = {
  data: ClientContactCrmData_Insert_Input;
  on_conflict?: Maybe<ClientContactCrmData_On_Conflict>;
};

/** on conflict condition type for table "clientContactCrmData" */
export type ClientContactCrmData_On_Conflict = {
  constraint: ClientContactCrmData_Constraint;
  update_columns: Array<ClientContactCrmData_Update_Column>;
  where?: Maybe<ClientContactCrmData_Bool_Exp>;
};

/** ordering options when selecting data from "clientContactCrmData" */
export type ClientContactCrmData_Order_By = {
  clientContactId?: Maybe<Order_By>;
  clientId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  crmType?: Maybe<Order_By>;
  fieldLabel?: Maybe<Order_By>;
  fieldName?: Maybe<Order_By>;
  fieldValue?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: "clientContactCrmData" */
export type ClientContactCrmData_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "clientContactCrmData" */
export enum ClientContactCrmData_Select_Column {
  /** column name */
  ClientContactId = 'clientContactId',
  /** column name */
  ClientId = 'clientId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CrmType = 'crmType',
  /** column name */
  FieldLabel = 'fieldLabel',
  /** column name */
  FieldName = 'fieldName',
  /** column name */
  FieldValue = 'fieldValue',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "clientContactCrmData" */
export type ClientContactCrmData_Set_Input = {
  clientContactId?: Maybe<Scalars['uuid']>;
  clientId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  crmType?: Maybe<Scalars['String']>;
  fieldLabel?: Maybe<Scalars['String']>;
  fieldName?: Maybe<Scalars['String']>;
  fieldValue?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "clientContactCrmData" */
export enum ClientContactCrmData_Update_Column {
  /** column name */
  ClientContactId = 'clientContactId',
  /** column name */
  ClientId = 'clientId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CrmType = 'crmType',
  /** column name */
  FieldLabel = 'fieldLabel',
  /** column name */
  FieldName = 'fieldName',
  /** column name */
  FieldValue = 'fieldValue',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** columns and relationships of "clientContactFilter" */
export type ClientContactFilter = {
  __typename?: 'clientContactFilter';
  clientId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  fieldCrmType?: Maybe<Scalars['String']>;
  fieldName: Scalars['String'];
  fieldOperator: Scalars['String'];
  fieldTable: Scalars['String'];
  fieldValue: Scalars['String'];
  id: Scalars['uuid'];
  lastFilteredClientContactCount?: Maybe<Scalars['Int']>;
  lastMonitoredAt?: Maybe<Scalars['timestamptz']>;
  lastMonitoredClientContactLimit?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "clientContactFilter" */
export type ClientContactFilter_Aggregate = {
  __typename?: 'clientContactFilter_aggregate';
  aggregate?: Maybe<ClientContactFilter_Aggregate_Fields>;
  nodes: Array<ClientContactFilter>;
};

/** aggregate fields of "clientContactFilter" */
export type ClientContactFilter_Aggregate_Fields = {
  __typename?: 'clientContactFilter_aggregate_fields';
  avg?: Maybe<ClientContactFilter_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<ClientContactFilter_Max_Fields>;
  min?: Maybe<ClientContactFilter_Min_Fields>;
  stddev?: Maybe<ClientContactFilter_Stddev_Fields>;
  stddev_pop?: Maybe<ClientContactFilter_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ClientContactFilter_Stddev_Samp_Fields>;
  sum?: Maybe<ClientContactFilter_Sum_Fields>;
  var_pop?: Maybe<ClientContactFilter_Var_Pop_Fields>;
  var_samp?: Maybe<ClientContactFilter_Var_Samp_Fields>;
  variance?: Maybe<ClientContactFilter_Variance_Fields>;
};

/** aggregate fields of "clientContactFilter" */
export type ClientContactFilter_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<ClientContactFilter_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "clientContactFilter" */
export type ClientContactFilter_Aggregate_Order_By = {
  avg?: Maybe<ClientContactFilter_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<ClientContactFilter_Max_Order_By>;
  min?: Maybe<ClientContactFilter_Min_Order_By>;
  stddev?: Maybe<ClientContactFilter_Stddev_Order_By>;
  stddev_pop?: Maybe<ClientContactFilter_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<ClientContactFilter_Stddev_Samp_Order_By>;
  sum?: Maybe<ClientContactFilter_Sum_Order_By>;
  var_pop?: Maybe<ClientContactFilter_Var_Pop_Order_By>;
  var_samp?: Maybe<ClientContactFilter_Var_Samp_Order_By>;
  variance?: Maybe<ClientContactFilter_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "clientContactFilter" */
export type ClientContactFilter_Arr_Rel_Insert_Input = {
  data: Array<ClientContactFilter_Insert_Input>;
  on_conflict?: Maybe<ClientContactFilter_On_Conflict>;
};

/** aggregate avg on columns */
export type ClientContactFilter_Avg_Fields = {
  __typename?: 'clientContactFilter_avg_fields';
  lastFilteredClientContactCount?: Maybe<Scalars['Float']>;
  lastMonitoredClientContactLimit?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "clientContactFilter" */
export type ClientContactFilter_Avg_Order_By = {
  lastFilteredClientContactCount?: Maybe<Order_By>;
  lastMonitoredClientContactLimit?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "clientContactFilter". All fields are combined with a logical 'AND'. */
export type ClientContactFilter_Bool_Exp = {
  _and?: Maybe<Array<Maybe<ClientContactFilter_Bool_Exp>>>;
  _not?: Maybe<ClientContactFilter_Bool_Exp>;
  _or?: Maybe<Array<Maybe<ClientContactFilter_Bool_Exp>>>;
  clientId?: Maybe<Uuid_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  fieldCrmType?: Maybe<String_Comparison_Exp>;
  fieldName?: Maybe<String_Comparison_Exp>;
  fieldOperator?: Maybe<String_Comparison_Exp>;
  fieldTable?: Maybe<String_Comparison_Exp>;
  fieldValue?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  lastFilteredClientContactCount?: Maybe<Int_Comparison_Exp>;
  lastMonitoredAt?: Maybe<Timestamptz_Comparison_Exp>;
  lastMonitoredClientContactLimit?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "clientContactFilter" */
export enum ClientContactFilter_Constraint {
  /** unique or primary key constraint */
  ClientContactFilterClientIdFieldTableFieldNameFieldOperKey = 'clientContactFilter_clientId_fieldTable_fieldName_fieldOper_key',
  /** unique or primary key constraint */
  ClientContactFilterPkey = 'clientContactFilter_pkey',
}

/** input type for incrementing integer column in table "clientContactFilter" */
export type ClientContactFilter_Inc_Input = {
  lastFilteredClientContactCount?: Maybe<Scalars['Int']>;
  lastMonitoredClientContactLimit?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "clientContactFilter" */
export type ClientContactFilter_Insert_Input = {
  clientId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  fieldCrmType?: Maybe<Scalars['String']>;
  fieldName?: Maybe<Scalars['String']>;
  fieldOperator?: Maybe<Scalars['String']>;
  fieldTable?: Maybe<Scalars['String']>;
  fieldValue?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastFilteredClientContactCount?: Maybe<Scalars['Int']>;
  lastMonitoredAt?: Maybe<Scalars['timestamptz']>;
  lastMonitoredClientContactLimit?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type ClientContactFilter_Max_Fields = {
  __typename?: 'clientContactFilter_max_fields';
  clientId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  fieldCrmType?: Maybe<Scalars['String']>;
  fieldName?: Maybe<Scalars['String']>;
  fieldOperator?: Maybe<Scalars['String']>;
  fieldTable?: Maybe<Scalars['String']>;
  fieldValue?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastFilteredClientContactCount?: Maybe<Scalars['Int']>;
  lastMonitoredAt?: Maybe<Scalars['timestamptz']>;
  lastMonitoredClientContactLimit?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "clientContactFilter" */
export type ClientContactFilter_Max_Order_By = {
  clientId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  fieldCrmType?: Maybe<Order_By>;
  fieldName?: Maybe<Order_By>;
  fieldOperator?: Maybe<Order_By>;
  fieldTable?: Maybe<Order_By>;
  fieldValue?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lastFilteredClientContactCount?: Maybe<Order_By>;
  lastMonitoredAt?: Maybe<Order_By>;
  lastMonitoredClientContactLimit?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type ClientContactFilter_Min_Fields = {
  __typename?: 'clientContactFilter_min_fields';
  clientId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  fieldCrmType?: Maybe<Scalars['String']>;
  fieldName?: Maybe<Scalars['String']>;
  fieldOperator?: Maybe<Scalars['String']>;
  fieldTable?: Maybe<Scalars['String']>;
  fieldValue?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastFilteredClientContactCount?: Maybe<Scalars['Int']>;
  lastMonitoredAt?: Maybe<Scalars['timestamptz']>;
  lastMonitoredClientContactLimit?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "clientContactFilter" */
export type ClientContactFilter_Min_Order_By = {
  clientId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  fieldCrmType?: Maybe<Order_By>;
  fieldName?: Maybe<Order_By>;
  fieldOperator?: Maybe<Order_By>;
  fieldTable?: Maybe<Order_By>;
  fieldValue?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lastFilteredClientContactCount?: Maybe<Order_By>;
  lastMonitoredAt?: Maybe<Order_By>;
  lastMonitoredClientContactLimit?: Maybe<Order_By>;
};

/** response of any mutation on the table "clientContactFilter" */
export type ClientContactFilter_Mutation_Response = {
  __typename?: 'clientContactFilter_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<ClientContactFilter>;
};

/** input type for inserting object relation for remote table "clientContactFilter" */
export type ClientContactFilter_Obj_Rel_Insert_Input = {
  data: ClientContactFilter_Insert_Input;
  on_conflict?: Maybe<ClientContactFilter_On_Conflict>;
};

/** on conflict condition type for table "clientContactFilter" */
export type ClientContactFilter_On_Conflict = {
  constraint: ClientContactFilter_Constraint;
  update_columns: Array<ClientContactFilter_Update_Column>;
  where?: Maybe<ClientContactFilter_Bool_Exp>;
};

/** ordering options when selecting data from "clientContactFilter" */
export type ClientContactFilter_Order_By = {
  clientId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  fieldCrmType?: Maybe<Order_By>;
  fieldName?: Maybe<Order_By>;
  fieldOperator?: Maybe<Order_By>;
  fieldTable?: Maybe<Order_By>;
  fieldValue?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lastFilteredClientContactCount?: Maybe<Order_By>;
  lastMonitoredAt?: Maybe<Order_By>;
  lastMonitoredClientContactLimit?: Maybe<Order_By>;
};

/** primary key columns input for table: "clientContactFilter" */
export type ClientContactFilter_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "clientContactFilter" */
export enum ClientContactFilter_Select_Column {
  /** column name */
  ClientId = 'clientId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  FieldCrmType = 'fieldCrmType',
  /** column name */
  FieldName = 'fieldName',
  /** column name */
  FieldOperator = 'fieldOperator',
  /** column name */
  FieldTable = 'fieldTable',
  /** column name */
  FieldValue = 'fieldValue',
  /** column name */
  Id = 'id',
  /** column name */
  LastFilteredClientContactCount = 'lastFilteredClientContactCount',
  /** column name */
  LastMonitoredAt = 'lastMonitoredAt',
  /** column name */
  LastMonitoredClientContactLimit = 'lastMonitoredClientContactLimit',
}

/** input type for updating data in table "clientContactFilter" */
export type ClientContactFilter_Set_Input = {
  clientId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  fieldCrmType?: Maybe<Scalars['String']>;
  fieldName?: Maybe<Scalars['String']>;
  fieldOperator?: Maybe<Scalars['String']>;
  fieldTable?: Maybe<Scalars['String']>;
  fieldValue?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastFilteredClientContactCount?: Maybe<Scalars['Int']>;
  lastMonitoredAt?: Maybe<Scalars['timestamptz']>;
  lastMonitoredClientContactLimit?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type ClientContactFilter_Stddev_Fields = {
  __typename?: 'clientContactFilter_stddev_fields';
  lastFilteredClientContactCount?: Maybe<Scalars['Float']>;
  lastMonitoredClientContactLimit?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "clientContactFilter" */
export type ClientContactFilter_Stddev_Order_By = {
  lastFilteredClientContactCount?: Maybe<Order_By>;
  lastMonitoredClientContactLimit?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type ClientContactFilter_Stddev_Pop_Fields = {
  __typename?: 'clientContactFilter_stddev_pop_fields';
  lastFilteredClientContactCount?: Maybe<Scalars['Float']>;
  lastMonitoredClientContactLimit?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "clientContactFilter" */
export type ClientContactFilter_Stddev_Pop_Order_By = {
  lastFilteredClientContactCount?: Maybe<Order_By>;
  lastMonitoredClientContactLimit?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type ClientContactFilter_Stddev_Samp_Fields = {
  __typename?: 'clientContactFilter_stddev_samp_fields';
  lastFilteredClientContactCount?: Maybe<Scalars['Float']>;
  lastMonitoredClientContactLimit?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "clientContactFilter" */
export type ClientContactFilter_Stddev_Samp_Order_By = {
  lastFilteredClientContactCount?: Maybe<Order_By>;
  lastMonitoredClientContactLimit?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type ClientContactFilter_Sum_Fields = {
  __typename?: 'clientContactFilter_sum_fields';
  lastFilteredClientContactCount?: Maybe<Scalars['Int']>;
  lastMonitoredClientContactLimit?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "clientContactFilter" */
export type ClientContactFilter_Sum_Order_By = {
  lastFilteredClientContactCount?: Maybe<Order_By>;
  lastMonitoredClientContactLimit?: Maybe<Order_By>;
};

/** update columns of table "clientContactFilter" */
export enum ClientContactFilter_Update_Column {
  /** column name */
  ClientId = 'clientId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  FieldCrmType = 'fieldCrmType',
  /** column name */
  FieldName = 'fieldName',
  /** column name */
  FieldOperator = 'fieldOperator',
  /** column name */
  FieldTable = 'fieldTable',
  /** column name */
  FieldValue = 'fieldValue',
  /** column name */
  Id = 'id',
  /** column name */
  LastFilteredClientContactCount = 'lastFilteredClientContactCount',
  /** column name */
  LastMonitoredAt = 'lastMonitoredAt',
  /** column name */
  LastMonitoredClientContactLimit = 'lastMonitoredClientContactLimit',
}

/** aggregate var_pop on columns */
export type ClientContactFilter_Var_Pop_Fields = {
  __typename?: 'clientContactFilter_var_pop_fields';
  lastFilteredClientContactCount?: Maybe<Scalars['Float']>;
  lastMonitoredClientContactLimit?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "clientContactFilter" */
export type ClientContactFilter_Var_Pop_Order_By = {
  lastFilteredClientContactCount?: Maybe<Order_By>;
  lastMonitoredClientContactLimit?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type ClientContactFilter_Var_Samp_Fields = {
  __typename?: 'clientContactFilter_var_samp_fields';
  lastFilteredClientContactCount?: Maybe<Scalars['Float']>;
  lastMonitoredClientContactLimit?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "clientContactFilter" */
export type ClientContactFilter_Var_Samp_Order_By = {
  lastFilteredClientContactCount?: Maybe<Order_By>;
  lastMonitoredClientContactLimit?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type ClientContactFilter_Variance_Fields = {
  __typename?: 'clientContactFilter_variance_fields';
  lastFilteredClientContactCount?: Maybe<Scalars['Float']>;
  lastMonitoredClientContactLimit?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "clientContactFilter" */
export type ClientContactFilter_Variance_Order_By = {
  lastFilteredClientContactCount?: Maybe<Order_By>;
  lastMonitoredClientContactLimit?: Maybe<Order_By>;
};

/** columns and relationships of "clientContactJobChange" */
export type ClientContactJobChange = {
  __typename?: 'clientContactJobChange';
  clientAction?: Maybe<Scalars['String']>;
  clientActionUpdatedAt?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  clientContact?: Maybe<ClientContact>;
  clientContactId?: Maybe<Scalars['uuid']>;
  clientId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  job: Job;
  jobId: Scalars['uuid'];
  /** An object relationship */
  ownerUser?: Maybe<User>;
  ownerUserId?: Maybe<Scalars['uuid']>;
  salesforceAccountId?: Maybe<Scalars['String']>;
  status: Scalars['String'];
};

/** aggregated selection of "clientContactJobChange" */
export type ClientContactJobChange_Aggregate = {
  __typename?: 'clientContactJobChange_aggregate';
  aggregate?: Maybe<ClientContactJobChange_Aggregate_Fields>;
  nodes: Array<ClientContactJobChange>;
};

/** aggregate fields of "clientContactJobChange" */
export type ClientContactJobChange_Aggregate_Fields = {
  __typename?: 'clientContactJobChange_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<ClientContactJobChange_Max_Fields>;
  min?: Maybe<ClientContactJobChange_Min_Fields>;
};

/** aggregate fields of "clientContactJobChange" */
export type ClientContactJobChange_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<ClientContactJobChange_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "clientContactJobChange" */
export type ClientContactJobChange_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<ClientContactJobChange_Max_Order_By>;
  min?: Maybe<ClientContactJobChange_Min_Order_By>;
};

/** input type for inserting array relation for remote table "clientContactJobChange" */
export type ClientContactJobChange_Arr_Rel_Insert_Input = {
  data: Array<ClientContactJobChange_Insert_Input>;
  on_conflict?: Maybe<ClientContactJobChange_On_Conflict>;
};

/** Boolean expression to filter rows from the table "clientContactJobChange". All fields are combined with a logical 'AND'. */
export type ClientContactJobChange_Bool_Exp = {
  _and?: Maybe<Array<Maybe<ClientContactJobChange_Bool_Exp>>>;
  _not?: Maybe<ClientContactJobChange_Bool_Exp>;
  _or?: Maybe<Array<Maybe<ClientContactJobChange_Bool_Exp>>>;
  clientAction?: Maybe<String_Comparison_Exp>;
  clientActionUpdatedAt?: Maybe<Timestamptz_Comparison_Exp>;
  clientContact?: Maybe<ClientContact_Bool_Exp>;
  clientContactId?: Maybe<Uuid_Comparison_Exp>;
  clientId?: Maybe<Uuid_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  job?: Maybe<Job_Bool_Exp>;
  jobId?: Maybe<Uuid_Comparison_Exp>;
  ownerUser?: Maybe<User_Bool_Exp>;
  ownerUserId?: Maybe<Uuid_Comparison_Exp>;
  salesforceAccountId?: Maybe<String_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "clientContactJobChange" */
export enum ClientContactJobChange_Constraint {
  /** unique or primary key constraint */
  ClientContactJobChangeClientContactIdJobIdKey = 'clientContactJobChange_clientContactId_jobId_key',
  /** unique or primary key constraint */
  ClientContactJobChangePkey = 'clientContactJobChange_pkey',
}

/** input type for inserting data into table "clientContactJobChange" */
export type ClientContactJobChange_Insert_Input = {
  clientAction?: Maybe<Scalars['String']>;
  clientActionUpdatedAt?: Maybe<Scalars['timestamptz']>;
  clientContact?: Maybe<ClientContact_Obj_Rel_Insert_Input>;
  clientContactId?: Maybe<Scalars['uuid']>;
  clientId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  job?: Maybe<Job_Obj_Rel_Insert_Input>;
  jobId?: Maybe<Scalars['uuid']>;
  ownerUser?: Maybe<User_Obj_Rel_Insert_Input>;
  ownerUserId?: Maybe<Scalars['uuid']>;
  salesforceAccountId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ClientContactJobChange_Max_Fields = {
  __typename?: 'clientContactJobChange_max_fields';
  clientAction?: Maybe<Scalars['String']>;
  clientActionUpdatedAt?: Maybe<Scalars['timestamptz']>;
  clientContactId?: Maybe<Scalars['uuid']>;
  clientId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  jobId?: Maybe<Scalars['uuid']>;
  ownerUserId?: Maybe<Scalars['uuid']>;
  salesforceAccountId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "clientContactJobChange" */
export type ClientContactJobChange_Max_Order_By = {
  clientAction?: Maybe<Order_By>;
  clientActionUpdatedAt?: Maybe<Order_By>;
  clientContactId?: Maybe<Order_By>;
  clientId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  jobId?: Maybe<Order_By>;
  ownerUserId?: Maybe<Order_By>;
  salesforceAccountId?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type ClientContactJobChange_Min_Fields = {
  __typename?: 'clientContactJobChange_min_fields';
  clientAction?: Maybe<Scalars['String']>;
  clientActionUpdatedAt?: Maybe<Scalars['timestamptz']>;
  clientContactId?: Maybe<Scalars['uuid']>;
  clientId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  jobId?: Maybe<Scalars['uuid']>;
  ownerUserId?: Maybe<Scalars['uuid']>;
  salesforceAccountId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "clientContactJobChange" */
export type ClientContactJobChange_Min_Order_By = {
  clientAction?: Maybe<Order_By>;
  clientActionUpdatedAt?: Maybe<Order_By>;
  clientContactId?: Maybe<Order_By>;
  clientId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  jobId?: Maybe<Order_By>;
  ownerUserId?: Maybe<Order_By>;
  salesforceAccountId?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
};

/** response of any mutation on the table "clientContactJobChange" */
export type ClientContactJobChange_Mutation_Response = {
  __typename?: 'clientContactJobChange_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<ClientContactJobChange>;
};

/** input type for inserting object relation for remote table "clientContactJobChange" */
export type ClientContactJobChange_Obj_Rel_Insert_Input = {
  data: ClientContactJobChange_Insert_Input;
  on_conflict?: Maybe<ClientContactJobChange_On_Conflict>;
};

/** on conflict condition type for table "clientContactJobChange" */
export type ClientContactJobChange_On_Conflict = {
  constraint: ClientContactJobChange_Constraint;
  update_columns: Array<ClientContactJobChange_Update_Column>;
  where?: Maybe<ClientContactJobChange_Bool_Exp>;
};

/** ordering options when selecting data from "clientContactJobChange" */
export type ClientContactJobChange_Order_By = {
  clientAction?: Maybe<Order_By>;
  clientActionUpdatedAt?: Maybe<Order_By>;
  clientContact?: Maybe<ClientContact_Order_By>;
  clientContactId?: Maybe<Order_By>;
  clientId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  job?: Maybe<Job_Order_By>;
  jobId?: Maybe<Order_By>;
  ownerUser?: Maybe<User_Order_By>;
  ownerUserId?: Maybe<Order_By>;
  salesforceAccountId?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
};

/** primary key columns input for table: "clientContactJobChange" */
export type ClientContactJobChange_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "clientContactJobChange" */
export enum ClientContactJobChange_Select_Column {
  /** column name */
  ClientAction = 'clientAction',
  /** column name */
  ClientActionUpdatedAt = 'clientActionUpdatedAt',
  /** column name */
  ClientContactId = 'clientContactId',
  /** column name */
  ClientId = 'clientId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  JobId = 'jobId',
  /** column name */
  OwnerUserId = 'ownerUserId',
  /** column name */
  SalesforceAccountId = 'salesforceAccountId',
  /** column name */
  Status = 'status',
}

/** input type for updating data in table "clientContactJobChange" */
export type ClientContactJobChange_Set_Input = {
  clientAction?: Maybe<Scalars['String']>;
  clientActionUpdatedAt?: Maybe<Scalars['timestamptz']>;
  clientContactId?: Maybe<Scalars['uuid']>;
  clientId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  jobId?: Maybe<Scalars['uuid']>;
  ownerUserId?: Maybe<Scalars['uuid']>;
  salesforceAccountId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

/** update columns of table "clientContactJobChange" */
export enum ClientContactJobChange_Update_Column {
  /** column name */
  ClientAction = 'clientAction',
  /** column name */
  ClientActionUpdatedAt = 'clientActionUpdatedAt',
  /** column name */
  ClientContactId = 'clientContactId',
  /** column name */
  ClientId = 'clientId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  JobId = 'jobId',
  /** column name */
  OwnerUserId = 'ownerUserId',
  /** column name */
  SalesforceAccountId = 'salesforceAccountId',
  /** column name */
  Status = 'status',
}

/** aggregated selection of "clientContact" */
export type ClientContact_Aggregate = {
  __typename?: 'clientContact_aggregate';
  aggregate?: Maybe<ClientContact_Aggregate_Fields>;
  nodes: Array<ClientContact>;
};

/** aggregate fields of "clientContact" */
export type ClientContact_Aggregate_Fields = {
  __typename?: 'clientContact_aggregate_fields';
  avg?: Maybe<ClientContact_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<ClientContact_Max_Fields>;
  min?: Maybe<ClientContact_Min_Fields>;
  stddev?: Maybe<ClientContact_Stddev_Fields>;
  stddev_pop?: Maybe<ClientContact_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ClientContact_Stddev_Samp_Fields>;
  sum?: Maybe<ClientContact_Sum_Fields>;
  var_pop?: Maybe<ClientContact_Var_Pop_Fields>;
  var_samp?: Maybe<ClientContact_Var_Samp_Fields>;
  variance?: Maybe<ClientContact_Variance_Fields>;
};

/** aggregate fields of "clientContact" */
export type ClientContact_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<ClientContact_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "clientContact" */
export type ClientContact_Aggregate_Order_By = {
  avg?: Maybe<ClientContact_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<ClientContact_Max_Order_By>;
  min?: Maybe<ClientContact_Min_Order_By>;
  stddev?: Maybe<ClientContact_Stddev_Order_By>;
  stddev_pop?: Maybe<ClientContact_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<ClientContact_Stddev_Samp_Order_By>;
  sum?: Maybe<ClientContact_Sum_Order_By>;
  var_pop?: Maybe<ClientContact_Var_Pop_Order_By>;
  var_samp?: Maybe<ClientContact_Var_Samp_Order_By>;
  variance?: Maybe<ClientContact_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "clientContact" */
export type ClientContact_Arr_Rel_Insert_Input = {
  data: Array<ClientContact_Insert_Input>;
  on_conflict?: Maybe<ClientContact_On_Conflict>;
};

/** aggregate avg on columns */
export type ClientContact_Avg_Fields = {
  __typename?: 'clientContact_avg_fields';
  serialId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "clientContact" */
export type ClientContact_Avg_Order_By = {
  serialId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "clientContact". All fields are combined with a logical 'AND'. */
export type ClientContact_Bool_Exp = {
  _and?: Maybe<Array<Maybe<ClientContact_Bool_Exp>>>;
  _not?: Maybe<ClientContact_Bool_Exp>;
  _or?: Maybe<Array<Maybe<ClientContact_Bool_Exp>>>;
  clientId?: Maybe<Uuid_Comparison_Exp>;
  contactData?: Maybe<ContactData_Bool_Exp>;
  contactDataId?: Maybe<Uuid_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  crmCompanyName?: Maybe<String_Comparison_Exp>;
  crmData?: Maybe<ClientContactCrmData_Bool_Exp>;
  crmEmail?: Maybe<String_Comparison_Exp>;
  crmEmailIsValid?: Maybe<Boolean_Comparison_Exp>;
  crmEmailValidatedAt?: Maybe<Timestamptz_Comparison_Exp>;
  crmLinkedinUrl?: Maybe<String_Comparison_Exp>;
  crmTitle?: Maybe<String_Comparison_Exp>;
  csvUploadId?: Maybe<Uuid_Comparison_Exp>;
  currentJobChange?: Maybe<ClientContactJobChange_Bool_Exp>;
  currentJobChangeId?: Maybe<Uuid_Comparison_Exp>;
  deletedAt?: Maybe<Timestamptz_Comparison_Exp>;
  enrichmentHistories?: Maybe<CrmContactEnrichmentHistory_Bool_Exp>;
  firestoreId?: Maybe<String_Comparison_Exp>;
  firstName?: Maybe<String_Comparison_Exp>;
  fullName?: Maybe<String_Comparison_Exp>;
  hubspotId?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  jobChanges?: Maybe<ClientContactJobChange_Bool_Exp>;
  lastName?: Maybe<String_Comparison_Exp>;
  linkedinId?: Maybe<String_Comparison_Exp>;
  linkedinUrl?: Maybe<String_Comparison_Exp>;
  ownerUser?: Maybe<User_Bool_Exp>;
  ownerUserId?: Maybe<Uuid_Comparison_Exp>;
  qaAssignedAt?: Maybe<Timestamptz_Comparison_Exp>;
  salesforceAccountId?: Maybe<String_Comparison_Exp>;
  salesforceId?: Maybe<String_Comparison_Exp>;
  salesforceOwnerId?: Maybe<String_Comparison_Exp>;
  serialId?: Maybe<Int_Comparison_Exp>;
  setMonitoredAt?: Maybe<Timestamptz_Comparison_Exp>;
  setMonitoredFilterId?: Maybe<Uuid_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "clientContact" */
export enum ClientContact_Constraint {
  /** unique or primary key constraint */
  ClientContactClientIdHubspotIdKey = 'clientContact_clientId_hubspotId_key',
  /** unique or primary key constraint */
  ClientContactClientIdSalesforceIdKey = 'clientContact_clientId_salesforceId_key',
  /** unique or primary key constraint */
  ClientContactFirestoreIdKey = 'clientContact_firestoreId_key',
  /** unique or primary key constraint */
  ClientContactPkey = 'clientContact_pkey',
}

/** input type for incrementing integer column in table "clientContact" */
export type ClientContact_Inc_Input = {
  serialId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "clientContact" */
export type ClientContact_Insert_Input = {
  clientId?: Maybe<Scalars['uuid']>;
  contactData?: Maybe<ContactData_Obj_Rel_Insert_Input>;
  contactDataId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  crmCompanyName?: Maybe<Scalars['String']>;
  crmData?: Maybe<ClientContactCrmData_Arr_Rel_Insert_Input>;
  crmEmail?: Maybe<Scalars['String']>;
  crmEmailIsValid?: Maybe<Scalars['Boolean']>;
  crmEmailValidatedAt?: Maybe<Scalars['timestamptz']>;
  crmLinkedinUrl?: Maybe<Scalars['String']>;
  crmTitle?: Maybe<Scalars['String']>;
  csvUploadId?: Maybe<Scalars['uuid']>;
  currentJobChange?: Maybe<ClientContactJobChange_Obj_Rel_Insert_Input>;
  currentJobChangeId?: Maybe<Scalars['uuid']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  enrichmentHistories?: Maybe<CrmContactEnrichmentHistory_Arr_Rel_Insert_Input>;
  firestoreId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  hubspotId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  jobChanges?: Maybe<ClientContactJobChange_Arr_Rel_Insert_Input>;
  lastName?: Maybe<Scalars['String']>;
  linkedinId?: Maybe<Scalars['String']>;
  linkedinUrl?: Maybe<Scalars['String']>;
  ownerUser?: Maybe<User_Obj_Rel_Insert_Input>;
  ownerUserId?: Maybe<Scalars['uuid']>;
  qaAssignedAt?: Maybe<Scalars['timestamptz']>;
  salesforceAccountId?: Maybe<Scalars['String']>;
  salesforceId?: Maybe<Scalars['String']>;
  salesforceOwnerId?: Maybe<Scalars['String']>;
  serialId?: Maybe<Scalars['Int']>;
  setMonitoredAt?: Maybe<Scalars['timestamptz']>;
  setMonitoredFilterId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type ClientContact_Max_Fields = {
  __typename?: 'clientContact_max_fields';
  clientId?: Maybe<Scalars['uuid']>;
  contactDataId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  crmCompanyName?: Maybe<Scalars['String']>;
  crmEmail?: Maybe<Scalars['String']>;
  crmEmailValidatedAt?: Maybe<Scalars['timestamptz']>;
  crmLinkedinUrl?: Maybe<Scalars['String']>;
  crmTitle?: Maybe<Scalars['String']>;
  csvUploadId?: Maybe<Scalars['uuid']>;
  currentJobChangeId?: Maybe<Scalars['uuid']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  firestoreId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  hubspotId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastName?: Maybe<Scalars['String']>;
  linkedinId?: Maybe<Scalars['String']>;
  linkedinUrl?: Maybe<Scalars['String']>;
  ownerUserId?: Maybe<Scalars['uuid']>;
  qaAssignedAt?: Maybe<Scalars['timestamptz']>;
  salesforceAccountId?: Maybe<Scalars['String']>;
  salesforceId?: Maybe<Scalars['String']>;
  salesforceOwnerId?: Maybe<Scalars['String']>;
  serialId?: Maybe<Scalars['Int']>;
  setMonitoredAt?: Maybe<Scalars['timestamptz']>;
  setMonitoredFilterId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "clientContact" */
export type ClientContact_Max_Order_By = {
  clientId?: Maybe<Order_By>;
  contactDataId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  crmCompanyName?: Maybe<Order_By>;
  crmEmail?: Maybe<Order_By>;
  crmEmailValidatedAt?: Maybe<Order_By>;
  crmLinkedinUrl?: Maybe<Order_By>;
  crmTitle?: Maybe<Order_By>;
  csvUploadId?: Maybe<Order_By>;
  currentJobChangeId?: Maybe<Order_By>;
  deletedAt?: Maybe<Order_By>;
  firestoreId?: Maybe<Order_By>;
  firstName?: Maybe<Order_By>;
  fullName?: Maybe<Order_By>;
  hubspotId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lastName?: Maybe<Order_By>;
  linkedinId?: Maybe<Order_By>;
  linkedinUrl?: Maybe<Order_By>;
  ownerUserId?: Maybe<Order_By>;
  qaAssignedAt?: Maybe<Order_By>;
  salesforceAccountId?: Maybe<Order_By>;
  salesforceId?: Maybe<Order_By>;
  salesforceOwnerId?: Maybe<Order_By>;
  serialId?: Maybe<Order_By>;
  setMonitoredAt?: Maybe<Order_By>;
  setMonitoredFilterId?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type ClientContact_Min_Fields = {
  __typename?: 'clientContact_min_fields';
  clientId?: Maybe<Scalars['uuid']>;
  contactDataId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  crmCompanyName?: Maybe<Scalars['String']>;
  crmEmail?: Maybe<Scalars['String']>;
  crmEmailValidatedAt?: Maybe<Scalars['timestamptz']>;
  crmLinkedinUrl?: Maybe<Scalars['String']>;
  crmTitle?: Maybe<Scalars['String']>;
  csvUploadId?: Maybe<Scalars['uuid']>;
  currentJobChangeId?: Maybe<Scalars['uuid']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  firestoreId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  hubspotId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastName?: Maybe<Scalars['String']>;
  linkedinId?: Maybe<Scalars['String']>;
  linkedinUrl?: Maybe<Scalars['String']>;
  ownerUserId?: Maybe<Scalars['uuid']>;
  qaAssignedAt?: Maybe<Scalars['timestamptz']>;
  salesforceAccountId?: Maybe<Scalars['String']>;
  salesforceId?: Maybe<Scalars['String']>;
  salesforceOwnerId?: Maybe<Scalars['String']>;
  serialId?: Maybe<Scalars['Int']>;
  setMonitoredAt?: Maybe<Scalars['timestamptz']>;
  setMonitoredFilterId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "clientContact" */
export type ClientContact_Min_Order_By = {
  clientId?: Maybe<Order_By>;
  contactDataId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  crmCompanyName?: Maybe<Order_By>;
  crmEmail?: Maybe<Order_By>;
  crmEmailValidatedAt?: Maybe<Order_By>;
  crmLinkedinUrl?: Maybe<Order_By>;
  crmTitle?: Maybe<Order_By>;
  csvUploadId?: Maybe<Order_By>;
  currentJobChangeId?: Maybe<Order_By>;
  deletedAt?: Maybe<Order_By>;
  firestoreId?: Maybe<Order_By>;
  firstName?: Maybe<Order_By>;
  fullName?: Maybe<Order_By>;
  hubspotId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lastName?: Maybe<Order_By>;
  linkedinId?: Maybe<Order_By>;
  linkedinUrl?: Maybe<Order_By>;
  ownerUserId?: Maybe<Order_By>;
  qaAssignedAt?: Maybe<Order_By>;
  salesforceAccountId?: Maybe<Order_By>;
  salesforceId?: Maybe<Order_By>;
  salesforceOwnerId?: Maybe<Order_By>;
  serialId?: Maybe<Order_By>;
  setMonitoredAt?: Maybe<Order_By>;
  setMonitoredFilterId?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** response of any mutation on the table "clientContact" */
export type ClientContact_Mutation_Response = {
  __typename?: 'clientContact_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<ClientContact>;
};

/** input type for inserting object relation for remote table "clientContact" */
export type ClientContact_Obj_Rel_Insert_Input = {
  data: ClientContact_Insert_Input;
  on_conflict?: Maybe<ClientContact_On_Conflict>;
};

/** on conflict condition type for table "clientContact" */
export type ClientContact_On_Conflict = {
  constraint: ClientContact_Constraint;
  update_columns: Array<ClientContact_Update_Column>;
  where?: Maybe<ClientContact_Bool_Exp>;
};

/** ordering options when selecting data from "clientContact" */
export type ClientContact_Order_By = {
  clientId?: Maybe<Order_By>;
  contactData?: Maybe<ContactData_Order_By>;
  contactDataId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  crmCompanyName?: Maybe<Order_By>;
  crmData_aggregate?: Maybe<ClientContactCrmData_Aggregate_Order_By>;
  crmEmail?: Maybe<Order_By>;
  crmEmailIsValid?: Maybe<Order_By>;
  crmEmailValidatedAt?: Maybe<Order_By>;
  crmLinkedinUrl?: Maybe<Order_By>;
  crmTitle?: Maybe<Order_By>;
  csvUploadId?: Maybe<Order_By>;
  currentJobChange?: Maybe<ClientContactJobChange_Order_By>;
  currentJobChangeId?: Maybe<Order_By>;
  deletedAt?: Maybe<Order_By>;
  enrichmentHistories_aggregate?: Maybe<CrmContactEnrichmentHistory_Aggregate_Order_By>;
  firestoreId?: Maybe<Order_By>;
  firstName?: Maybe<Order_By>;
  fullName?: Maybe<Order_By>;
  hubspotId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  jobChanges_aggregate?: Maybe<ClientContactJobChange_Aggregate_Order_By>;
  lastName?: Maybe<Order_By>;
  linkedinId?: Maybe<Order_By>;
  linkedinUrl?: Maybe<Order_By>;
  ownerUser?: Maybe<User_Order_By>;
  ownerUserId?: Maybe<Order_By>;
  qaAssignedAt?: Maybe<Order_By>;
  salesforceAccountId?: Maybe<Order_By>;
  salesforceId?: Maybe<Order_By>;
  salesforceOwnerId?: Maybe<Order_By>;
  serialId?: Maybe<Order_By>;
  setMonitoredAt?: Maybe<Order_By>;
  setMonitoredFilterId?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: "clientContact" */
export type ClientContact_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "clientContact" */
export enum ClientContact_Select_Column {
  /** column name */
  ClientId = 'clientId',
  /** column name */
  ContactDataId = 'contactDataId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CrmCompanyName = 'crmCompanyName',
  /** column name */
  CrmEmail = 'crmEmail',
  /** column name */
  CrmEmailIsValid = 'crmEmailIsValid',
  /** column name */
  CrmEmailValidatedAt = 'crmEmailValidatedAt',
  /** column name */
  CrmLinkedinUrl = 'crmLinkedinUrl',
  /** column name */
  CrmTitle = 'crmTitle',
  /** column name */
  CsvUploadId = 'csvUploadId',
  /** column name */
  CurrentJobChangeId = 'currentJobChangeId',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  FirestoreId = 'firestoreId',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  FullName = 'fullName',
  /** column name */
  HubspotId = 'hubspotId',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'lastName',
  /** column name */
  LinkedinId = 'linkedinId',
  /** column name */
  LinkedinUrl = 'linkedinUrl',
  /** column name */
  OwnerUserId = 'ownerUserId',
  /** column name */
  QaAssignedAt = 'qaAssignedAt',
  /** column name */
  SalesforceAccountId = 'salesforceAccountId',
  /** column name */
  SalesforceId = 'salesforceId',
  /** column name */
  SalesforceOwnerId = 'salesforceOwnerId',
  /** column name */
  SerialId = 'serialId',
  /** column name */
  SetMonitoredAt = 'setMonitoredAt',
  /** column name */
  SetMonitoredFilterId = 'setMonitoredFilterId',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "clientContact" */
export type ClientContact_Set_Input = {
  clientId?: Maybe<Scalars['uuid']>;
  contactDataId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  crmCompanyName?: Maybe<Scalars['String']>;
  crmEmail?: Maybe<Scalars['String']>;
  crmEmailIsValid?: Maybe<Scalars['Boolean']>;
  crmEmailValidatedAt?: Maybe<Scalars['timestamptz']>;
  crmLinkedinUrl?: Maybe<Scalars['String']>;
  crmTitle?: Maybe<Scalars['String']>;
  csvUploadId?: Maybe<Scalars['uuid']>;
  currentJobChangeId?: Maybe<Scalars['uuid']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  firestoreId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  hubspotId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastName?: Maybe<Scalars['String']>;
  linkedinId?: Maybe<Scalars['String']>;
  linkedinUrl?: Maybe<Scalars['String']>;
  ownerUserId?: Maybe<Scalars['uuid']>;
  qaAssignedAt?: Maybe<Scalars['timestamptz']>;
  salesforceAccountId?: Maybe<Scalars['String']>;
  salesforceId?: Maybe<Scalars['String']>;
  salesforceOwnerId?: Maybe<Scalars['String']>;
  serialId?: Maybe<Scalars['Int']>;
  setMonitoredAt?: Maybe<Scalars['timestamptz']>;
  setMonitoredFilterId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type ClientContact_Stddev_Fields = {
  __typename?: 'clientContact_stddev_fields';
  serialId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "clientContact" */
export type ClientContact_Stddev_Order_By = {
  serialId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type ClientContact_Stddev_Pop_Fields = {
  __typename?: 'clientContact_stddev_pop_fields';
  serialId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "clientContact" */
export type ClientContact_Stddev_Pop_Order_By = {
  serialId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type ClientContact_Stddev_Samp_Fields = {
  __typename?: 'clientContact_stddev_samp_fields';
  serialId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "clientContact" */
export type ClientContact_Stddev_Samp_Order_By = {
  serialId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type ClientContact_Sum_Fields = {
  __typename?: 'clientContact_sum_fields';
  serialId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "clientContact" */
export type ClientContact_Sum_Order_By = {
  serialId?: Maybe<Order_By>;
};

/** update columns of table "clientContact" */
export enum ClientContact_Update_Column {
  /** column name */
  ClientId = 'clientId',
  /** column name */
  ContactDataId = 'contactDataId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CrmCompanyName = 'crmCompanyName',
  /** column name */
  CrmEmail = 'crmEmail',
  /** column name */
  CrmEmailIsValid = 'crmEmailIsValid',
  /** column name */
  CrmEmailValidatedAt = 'crmEmailValidatedAt',
  /** column name */
  CrmLinkedinUrl = 'crmLinkedinUrl',
  /** column name */
  CrmTitle = 'crmTitle',
  /** column name */
  CsvUploadId = 'csvUploadId',
  /** column name */
  CurrentJobChangeId = 'currentJobChangeId',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  FirestoreId = 'firestoreId',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  FullName = 'fullName',
  /** column name */
  HubspotId = 'hubspotId',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'lastName',
  /** column name */
  LinkedinId = 'linkedinId',
  /** column name */
  LinkedinUrl = 'linkedinUrl',
  /** column name */
  OwnerUserId = 'ownerUserId',
  /** column name */
  QaAssignedAt = 'qaAssignedAt',
  /** column name */
  SalesforceAccountId = 'salesforceAccountId',
  /** column name */
  SalesforceId = 'salesforceId',
  /** column name */
  SalesforceOwnerId = 'salesforceOwnerId',
  /** column name */
  SerialId = 'serialId',
  /** column name */
  SetMonitoredAt = 'setMonitoredAt',
  /** column name */
  SetMonitoredFilterId = 'setMonitoredFilterId',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** aggregate var_pop on columns */
export type ClientContact_Var_Pop_Fields = {
  __typename?: 'clientContact_var_pop_fields';
  serialId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "clientContact" */
export type ClientContact_Var_Pop_Order_By = {
  serialId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type ClientContact_Var_Samp_Fields = {
  __typename?: 'clientContact_var_samp_fields';
  serialId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "clientContact" */
export type ClientContact_Var_Samp_Order_By = {
  serialId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type ClientContact_Variance_Fields = {
  __typename?: 'clientContact_variance_fields';
  serialId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "clientContact" */
export type ClientContact_Variance_Order_By = {
  serialId?: Maybe<Order_By>;
};

/** columns and relationships of "clientEmailReport" */
export type ClientEmailReport = {
  __typename?: 'clientEmailReport';
  clientId: Scalars['uuid'];
  emailCategory: Scalars['String'];
  emailHistoryId?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  json?: Maybe<Scalars['jsonb']>;
  sentAt: Scalars['timestamptz'];
};

/** columns and relationships of "clientEmailReport" */
export type ClientEmailReportJsonArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "clientEmailReport" */
export type ClientEmailReport_Aggregate = {
  __typename?: 'clientEmailReport_aggregate';
  aggregate?: Maybe<ClientEmailReport_Aggregate_Fields>;
  nodes: Array<ClientEmailReport>;
};

/** aggregate fields of "clientEmailReport" */
export type ClientEmailReport_Aggregate_Fields = {
  __typename?: 'clientEmailReport_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<ClientEmailReport_Max_Fields>;
  min?: Maybe<ClientEmailReport_Min_Fields>;
};

/** aggregate fields of "clientEmailReport" */
export type ClientEmailReport_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<ClientEmailReport_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "clientEmailReport" */
export type ClientEmailReport_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<ClientEmailReport_Max_Order_By>;
  min?: Maybe<ClientEmailReport_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ClientEmailReport_Append_Input = {
  json?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "clientEmailReport" */
export type ClientEmailReport_Arr_Rel_Insert_Input = {
  data: Array<ClientEmailReport_Insert_Input>;
  on_conflict?: Maybe<ClientEmailReport_On_Conflict>;
};

/** Boolean expression to filter rows from the table "clientEmailReport". All fields are combined with a logical 'AND'. */
export type ClientEmailReport_Bool_Exp = {
  _and?: Maybe<Array<Maybe<ClientEmailReport_Bool_Exp>>>;
  _not?: Maybe<ClientEmailReport_Bool_Exp>;
  _or?: Maybe<Array<Maybe<ClientEmailReport_Bool_Exp>>>;
  clientId?: Maybe<Uuid_Comparison_Exp>;
  emailCategory?: Maybe<String_Comparison_Exp>;
  emailHistoryId?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  json?: Maybe<Jsonb_Comparison_Exp>;
  sentAt?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "clientEmailReport" */
export enum ClientEmailReport_Constraint {
  /** unique or primary key constraint */
  ClientEmailReportPkey = 'clientEmailReport_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ClientEmailReport_Delete_At_Path_Input = {
  json?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ClientEmailReport_Delete_Elem_Input = {
  json?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ClientEmailReport_Delete_Key_Input = {
  json?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "clientEmailReport" */
export type ClientEmailReport_Insert_Input = {
  clientId?: Maybe<Scalars['uuid']>;
  emailCategory?: Maybe<Scalars['String']>;
  emailHistoryId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  json?: Maybe<Scalars['jsonb']>;
  sentAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type ClientEmailReport_Max_Fields = {
  __typename?: 'clientEmailReport_max_fields';
  clientId?: Maybe<Scalars['uuid']>;
  emailCategory?: Maybe<Scalars['String']>;
  emailHistoryId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  sentAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "clientEmailReport" */
export type ClientEmailReport_Max_Order_By = {
  clientId?: Maybe<Order_By>;
  emailCategory?: Maybe<Order_By>;
  emailHistoryId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  sentAt?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type ClientEmailReport_Min_Fields = {
  __typename?: 'clientEmailReport_min_fields';
  clientId?: Maybe<Scalars['uuid']>;
  emailCategory?: Maybe<Scalars['String']>;
  emailHistoryId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  sentAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "clientEmailReport" */
export type ClientEmailReport_Min_Order_By = {
  clientId?: Maybe<Order_By>;
  emailCategory?: Maybe<Order_By>;
  emailHistoryId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  sentAt?: Maybe<Order_By>;
};

/** response of any mutation on the table "clientEmailReport" */
export type ClientEmailReport_Mutation_Response = {
  __typename?: 'clientEmailReport_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<ClientEmailReport>;
};

/** input type for inserting object relation for remote table "clientEmailReport" */
export type ClientEmailReport_Obj_Rel_Insert_Input = {
  data: ClientEmailReport_Insert_Input;
  on_conflict?: Maybe<ClientEmailReport_On_Conflict>;
};

/** on conflict condition type for table "clientEmailReport" */
export type ClientEmailReport_On_Conflict = {
  constraint: ClientEmailReport_Constraint;
  update_columns: Array<ClientEmailReport_Update_Column>;
  where?: Maybe<ClientEmailReport_Bool_Exp>;
};

/** ordering options when selecting data from "clientEmailReport" */
export type ClientEmailReport_Order_By = {
  clientId?: Maybe<Order_By>;
  emailCategory?: Maybe<Order_By>;
  emailHistoryId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  json?: Maybe<Order_By>;
  sentAt?: Maybe<Order_By>;
};

/** primary key columns input for table: "clientEmailReport" */
export type ClientEmailReport_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ClientEmailReport_Prepend_Input = {
  json?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "clientEmailReport" */
export enum ClientEmailReport_Select_Column {
  /** column name */
  ClientId = 'clientId',
  /** column name */
  EmailCategory = 'emailCategory',
  /** column name */
  EmailHistoryId = 'emailHistoryId',
  /** column name */
  Id = 'id',
  /** column name */
  Json = 'json',
  /** column name */
  SentAt = 'sentAt',
}

/** input type for updating data in table "clientEmailReport" */
export type ClientEmailReport_Set_Input = {
  clientId?: Maybe<Scalars['uuid']>;
  emailCategory?: Maybe<Scalars['String']>;
  emailHistoryId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  json?: Maybe<Scalars['jsonb']>;
  sentAt?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "clientEmailReport" */
export enum ClientEmailReport_Update_Column {
  /** column name */
  ClientId = 'clientId',
  /** column name */
  EmailCategory = 'emailCategory',
  /** column name */
  EmailHistoryId = 'emailHistoryId',
  /** column name */
  Id = 'id',
  /** column name */
  Json = 'json',
  /** column name */
  SentAt = 'sentAt',
}

/** columns and relationships of "clientNotificationEmail" */
export type ClientNotificationEmail = {
  __typename?: 'clientNotificationEmail';
  clientId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  email: Scalars['String'];
  id: Scalars['uuid'];
};

/** aggregated selection of "clientNotificationEmail" */
export type ClientNotificationEmail_Aggregate = {
  __typename?: 'clientNotificationEmail_aggregate';
  aggregate?: Maybe<ClientNotificationEmail_Aggregate_Fields>;
  nodes: Array<ClientNotificationEmail>;
};

/** aggregate fields of "clientNotificationEmail" */
export type ClientNotificationEmail_Aggregate_Fields = {
  __typename?: 'clientNotificationEmail_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<ClientNotificationEmail_Max_Fields>;
  min?: Maybe<ClientNotificationEmail_Min_Fields>;
};

/** aggregate fields of "clientNotificationEmail" */
export type ClientNotificationEmail_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<ClientNotificationEmail_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "clientNotificationEmail" */
export type ClientNotificationEmail_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<ClientNotificationEmail_Max_Order_By>;
  min?: Maybe<ClientNotificationEmail_Min_Order_By>;
};

/** input type for inserting array relation for remote table "clientNotificationEmail" */
export type ClientNotificationEmail_Arr_Rel_Insert_Input = {
  data: Array<ClientNotificationEmail_Insert_Input>;
  on_conflict?: Maybe<ClientNotificationEmail_On_Conflict>;
};

/** Boolean expression to filter rows from the table "clientNotificationEmail". All fields are combined with a logical 'AND'. */
export type ClientNotificationEmail_Bool_Exp = {
  _and?: Maybe<Array<Maybe<ClientNotificationEmail_Bool_Exp>>>;
  _not?: Maybe<ClientNotificationEmail_Bool_Exp>;
  _or?: Maybe<Array<Maybe<ClientNotificationEmail_Bool_Exp>>>;
  clientId?: Maybe<Uuid_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "clientNotificationEmail" */
export enum ClientNotificationEmail_Constraint {
  /** unique or primary key constraint */
  ClientNotificationEmailClientIdEmailKey = 'clientNotificationEmail_clientId_email_key',
  /** unique or primary key constraint */
  ClientNotificationEmailPkey = 'clientNotificationEmail_pkey',
}

/** input type for inserting data into table "clientNotificationEmail" */
export type ClientNotificationEmail_Insert_Input = {
  clientId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type ClientNotificationEmail_Max_Fields = {
  __typename?: 'clientNotificationEmail_max_fields';
  clientId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "clientNotificationEmail" */
export type ClientNotificationEmail_Max_Order_By = {
  clientId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type ClientNotificationEmail_Min_Fields = {
  __typename?: 'clientNotificationEmail_min_fields';
  clientId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "clientNotificationEmail" */
export type ClientNotificationEmail_Min_Order_By = {
  clientId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** response of any mutation on the table "clientNotificationEmail" */
export type ClientNotificationEmail_Mutation_Response = {
  __typename?: 'clientNotificationEmail_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<ClientNotificationEmail>;
};

/** input type for inserting object relation for remote table "clientNotificationEmail" */
export type ClientNotificationEmail_Obj_Rel_Insert_Input = {
  data: ClientNotificationEmail_Insert_Input;
  on_conflict?: Maybe<ClientNotificationEmail_On_Conflict>;
};

/** on conflict condition type for table "clientNotificationEmail" */
export type ClientNotificationEmail_On_Conflict = {
  constraint: ClientNotificationEmail_Constraint;
  update_columns: Array<ClientNotificationEmail_Update_Column>;
  where?: Maybe<ClientNotificationEmail_Bool_Exp>;
};

/** ordering options when selecting data from "clientNotificationEmail" */
export type ClientNotificationEmail_Order_By = {
  clientId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** primary key columns input for table: "clientNotificationEmail" */
export type ClientNotificationEmail_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "clientNotificationEmail" */
export enum ClientNotificationEmail_Select_Column {
  /** column name */
  ClientId = 'clientId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
}

/** input type for updating data in table "clientNotificationEmail" */
export type ClientNotificationEmail_Set_Input = {
  clientId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "clientNotificationEmail" */
export enum ClientNotificationEmail_Update_Column {
  /** column name */
  ClientId = 'clientId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
}

/** columns and relationships of "clientTerritory" */
export type ClientTerritory = {
  __typename?: 'clientTerritory';
  clientId: Scalars['uuid'];
  companySizeMax?: Maybe<Scalars['Int']>;
  companySizeMin?: Maybe<Scalars['Int']>;
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  territory: Territory;
  territoryId: Scalars['uuid'];
  userId: Scalars['uuid'];
};

/** aggregated selection of "clientTerritory" */
export type ClientTerritory_Aggregate = {
  __typename?: 'clientTerritory_aggregate';
  aggregate?: Maybe<ClientTerritory_Aggregate_Fields>;
  nodes: Array<ClientTerritory>;
};

/** aggregate fields of "clientTerritory" */
export type ClientTerritory_Aggregate_Fields = {
  __typename?: 'clientTerritory_aggregate_fields';
  avg?: Maybe<ClientTerritory_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<ClientTerritory_Max_Fields>;
  min?: Maybe<ClientTerritory_Min_Fields>;
  stddev?: Maybe<ClientTerritory_Stddev_Fields>;
  stddev_pop?: Maybe<ClientTerritory_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ClientTerritory_Stddev_Samp_Fields>;
  sum?: Maybe<ClientTerritory_Sum_Fields>;
  var_pop?: Maybe<ClientTerritory_Var_Pop_Fields>;
  var_samp?: Maybe<ClientTerritory_Var_Samp_Fields>;
  variance?: Maybe<ClientTerritory_Variance_Fields>;
};

/** aggregate fields of "clientTerritory" */
export type ClientTerritory_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<ClientTerritory_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "clientTerritory" */
export type ClientTerritory_Aggregate_Order_By = {
  avg?: Maybe<ClientTerritory_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<ClientTerritory_Max_Order_By>;
  min?: Maybe<ClientTerritory_Min_Order_By>;
  stddev?: Maybe<ClientTerritory_Stddev_Order_By>;
  stddev_pop?: Maybe<ClientTerritory_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<ClientTerritory_Stddev_Samp_Order_By>;
  sum?: Maybe<ClientTerritory_Sum_Order_By>;
  var_pop?: Maybe<ClientTerritory_Var_Pop_Order_By>;
  var_samp?: Maybe<ClientTerritory_Var_Samp_Order_By>;
  variance?: Maybe<ClientTerritory_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "clientTerritory" */
export type ClientTerritory_Arr_Rel_Insert_Input = {
  data: Array<ClientTerritory_Insert_Input>;
  on_conflict?: Maybe<ClientTerritory_On_Conflict>;
};

/** aggregate avg on columns */
export type ClientTerritory_Avg_Fields = {
  __typename?: 'clientTerritory_avg_fields';
  companySizeMax?: Maybe<Scalars['Float']>;
  companySizeMin?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "clientTerritory" */
export type ClientTerritory_Avg_Order_By = {
  companySizeMax?: Maybe<Order_By>;
  companySizeMin?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "clientTerritory". All fields are combined with a logical 'AND'. */
export type ClientTerritory_Bool_Exp = {
  _and?: Maybe<Array<Maybe<ClientTerritory_Bool_Exp>>>;
  _not?: Maybe<ClientTerritory_Bool_Exp>;
  _or?: Maybe<Array<Maybe<ClientTerritory_Bool_Exp>>>;
  clientId?: Maybe<Uuid_Comparison_Exp>;
  companySizeMax?: Maybe<Int_Comparison_Exp>;
  companySizeMin?: Maybe<Int_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  territory?: Maybe<Territory_Bool_Exp>;
  territoryId?: Maybe<Uuid_Comparison_Exp>;
  userId?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "clientTerritory" */
export enum ClientTerritory_Constraint {
  /** unique or primary key constraint */
  ClientTerritoryClientIdUserIdTerritoryIdKey = 'clientTerritory_clientId_userId_territoryId_key',
  /** unique or primary key constraint */
  ClientTerritoryPkey = 'clientTerritory_pkey',
}

/** input type for incrementing integer column in table "clientTerritory" */
export type ClientTerritory_Inc_Input = {
  companySizeMax?: Maybe<Scalars['Int']>;
  companySizeMin?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "clientTerritory" */
export type ClientTerritory_Insert_Input = {
  clientId?: Maybe<Scalars['uuid']>;
  companySizeMax?: Maybe<Scalars['Int']>;
  companySizeMin?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  territory?: Maybe<Territory_Obj_Rel_Insert_Input>;
  territoryId?: Maybe<Scalars['uuid']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type ClientTerritory_Max_Fields = {
  __typename?: 'clientTerritory_max_fields';
  clientId?: Maybe<Scalars['uuid']>;
  companySizeMax?: Maybe<Scalars['Int']>;
  companySizeMin?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  territoryId?: Maybe<Scalars['uuid']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "clientTerritory" */
export type ClientTerritory_Max_Order_By = {
  clientId?: Maybe<Order_By>;
  companySizeMax?: Maybe<Order_By>;
  companySizeMin?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  territoryId?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type ClientTerritory_Min_Fields = {
  __typename?: 'clientTerritory_min_fields';
  clientId?: Maybe<Scalars['uuid']>;
  companySizeMax?: Maybe<Scalars['Int']>;
  companySizeMin?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  territoryId?: Maybe<Scalars['uuid']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "clientTerritory" */
export type ClientTerritory_Min_Order_By = {
  clientId?: Maybe<Order_By>;
  companySizeMax?: Maybe<Order_By>;
  companySizeMin?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  territoryId?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** response of any mutation on the table "clientTerritory" */
export type ClientTerritory_Mutation_Response = {
  __typename?: 'clientTerritory_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<ClientTerritory>;
};

/** input type for inserting object relation for remote table "clientTerritory" */
export type ClientTerritory_Obj_Rel_Insert_Input = {
  data: ClientTerritory_Insert_Input;
  on_conflict?: Maybe<ClientTerritory_On_Conflict>;
};

/** on conflict condition type for table "clientTerritory" */
export type ClientTerritory_On_Conflict = {
  constraint: ClientTerritory_Constraint;
  update_columns: Array<ClientTerritory_Update_Column>;
  where?: Maybe<ClientTerritory_Bool_Exp>;
};

/** ordering options when selecting data from "clientTerritory" */
export type ClientTerritory_Order_By = {
  clientId?: Maybe<Order_By>;
  companySizeMax?: Maybe<Order_By>;
  companySizeMin?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  territory?: Maybe<Territory_Order_By>;
  territoryId?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** primary key columns input for table: "clientTerritory" */
export type ClientTerritory_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "clientTerritory" */
export enum ClientTerritory_Select_Column {
  /** column name */
  ClientId = 'clientId',
  /** column name */
  CompanySizeMax = 'companySizeMax',
  /** column name */
  CompanySizeMin = 'companySizeMin',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  TerritoryId = 'territoryId',
  /** column name */
  UserId = 'userId',
}

/** input type for updating data in table "clientTerritory" */
export type ClientTerritory_Set_Input = {
  clientId?: Maybe<Scalars['uuid']>;
  companySizeMax?: Maybe<Scalars['Int']>;
  companySizeMin?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  territoryId?: Maybe<Scalars['uuid']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type ClientTerritory_Stddev_Fields = {
  __typename?: 'clientTerritory_stddev_fields';
  companySizeMax?: Maybe<Scalars['Float']>;
  companySizeMin?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "clientTerritory" */
export type ClientTerritory_Stddev_Order_By = {
  companySizeMax?: Maybe<Order_By>;
  companySizeMin?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type ClientTerritory_Stddev_Pop_Fields = {
  __typename?: 'clientTerritory_stddev_pop_fields';
  companySizeMax?: Maybe<Scalars['Float']>;
  companySizeMin?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "clientTerritory" */
export type ClientTerritory_Stddev_Pop_Order_By = {
  companySizeMax?: Maybe<Order_By>;
  companySizeMin?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type ClientTerritory_Stddev_Samp_Fields = {
  __typename?: 'clientTerritory_stddev_samp_fields';
  companySizeMax?: Maybe<Scalars['Float']>;
  companySizeMin?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "clientTerritory" */
export type ClientTerritory_Stddev_Samp_Order_By = {
  companySizeMax?: Maybe<Order_By>;
  companySizeMin?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type ClientTerritory_Sum_Fields = {
  __typename?: 'clientTerritory_sum_fields';
  companySizeMax?: Maybe<Scalars['Int']>;
  companySizeMin?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "clientTerritory" */
export type ClientTerritory_Sum_Order_By = {
  companySizeMax?: Maybe<Order_By>;
  companySizeMin?: Maybe<Order_By>;
};

/** update columns of table "clientTerritory" */
export enum ClientTerritory_Update_Column {
  /** column name */
  ClientId = 'clientId',
  /** column name */
  CompanySizeMax = 'companySizeMax',
  /** column name */
  CompanySizeMin = 'companySizeMin',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  TerritoryId = 'territoryId',
  /** column name */
  UserId = 'userId',
}

/** aggregate var_pop on columns */
export type ClientTerritory_Var_Pop_Fields = {
  __typename?: 'clientTerritory_var_pop_fields';
  companySizeMax?: Maybe<Scalars['Float']>;
  companySizeMin?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "clientTerritory" */
export type ClientTerritory_Var_Pop_Order_By = {
  companySizeMax?: Maybe<Order_By>;
  companySizeMin?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type ClientTerritory_Var_Samp_Fields = {
  __typename?: 'clientTerritory_var_samp_fields';
  companySizeMax?: Maybe<Scalars['Float']>;
  companySizeMin?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "clientTerritory" */
export type ClientTerritory_Var_Samp_Order_By = {
  companySizeMax?: Maybe<Order_By>;
  companySizeMin?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type ClientTerritory_Variance_Fields = {
  __typename?: 'clientTerritory_variance_fields';
  companySizeMax?: Maybe<Scalars['Float']>;
  companySizeMin?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "clientTerritory" */
export type ClientTerritory_Variance_Order_By = {
  companySizeMax?: Maybe<Order_By>;
  companySizeMin?: Maybe<Order_By>;
};

/** aggregated selection of "client" */
export type Client_Aggregate = {
  __typename?: 'client_aggregate';
  aggregate?: Maybe<Client_Aggregate_Fields>;
  nodes: Array<Client>;
};

/** aggregate fields of "client" */
export type Client_Aggregate_Fields = {
  __typename?: 'client_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Client_Max_Fields>;
  min?: Maybe<Client_Min_Fields>;
};

/** aggregate fields of "client" */
export type Client_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Client_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "client" */
export type Client_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Client_Max_Order_By>;
  min?: Maybe<Client_Min_Order_By>;
};

/** input type for inserting array relation for remote table "client" */
export type Client_Arr_Rel_Insert_Input = {
  data: Array<Client_Insert_Input>;
  on_conflict?: Maybe<Client_On_Conflict>;
};

/** Boolean expression to filter rows from the table "client". All fields are combined with a logical 'AND'. */
export type Client_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Client_Bool_Exp>>>;
  _not?: Maybe<Client_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Client_Bool_Exp>>>;
  clientContacts?: Maybe<ClientContact_Bool_Exp>;
  clientEmailReports?: Maybe<ClientEmailReport_Bool_Exp>;
  clientNotificationEmails?: Maybe<ClientNotificationEmail_Bool_Exp>;
  codeName?: Maybe<String_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  csvUploads?: Maybe<CsvUpload_Bool_Exp>;
  firestoreId?: Maybe<String_Comparison_Exp>;
  hubspotIntegration?: Maybe<HubspotIntegration_Bool_Exp>;
  hubspotIntegrationId?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  integrationType?: Maybe<String_Comparison_Exp>;
  lastCsvUpload?: Maybe<CsvUpload_Bool_Exp>;
  lastCsvUploadId?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  ownerMappingStatus?: Maybe<String_Comparison_Exp>;
  qaStatus?: Maybe<String_Comparison_Exp>;
  qaStatusUpdatedAt?: Maybe<Timestamptz_Comparison_Exp>;
  salesforceIntegration?: Maybe<SalesforceIntegration_Bool_Exp>;
  salesforceIntegrationId?: Maybe<Uuid_Comparison_Exp>;
  subscriptionStatus?: Maybe<String_Comparison_Exp>;
  syncStatus?: Maybe<String_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
  userInvites?: Maybe<UserInvite_Bool_Exp>;
  users?: Maybe<User_Bool_Exp>;
};

/** unique or primary key constraints on table "client" */
export enum Client_Constraint {
  /** unique or primary key constraint */
  ClientCodeNameKey = 'client_codeName_key',
  /** unique or primary key constraint */
  ClientFirestoreIdKey = 'client_firestoreId_key',
  /** unique or primary key constraint */
  ClientPkey = 'client_pkey',
}

/** input type for inserting data into table "client" */
export type Client_Insert_Input = {
  clientContacts?: Maybe<ClientContact_Arr_Rel_Insert_Input>;
  clientEmailReports?: Maybe<ClientEmailReport_Arr_Rel_Insert_Input>;
  clientNotificationEmails?: Maybe<ClientNotificationEmail_Arr_Rel_Insert_Input>;
  codeName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  csvUploads?: Maybe<CsvUpload_Arr_Rel_Insert_Input>;
  firestoreId?: Maybe<Scalars['String']>;
  hubspotIntegration?: Maybe<HubspotIntegration_Obj_Rel_Insert_Input>;
  hubspotIntegrationId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  integrationType?: Maybe<Scalars['String']>;
  lastCsvUpload?: Maybe<CsvUpload_Obj_Rel_Insert_Input>;
  lastCsvUploadId?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  ownerMappingStatus?: Maybe<Scalars['String']>;
  qaStatus?: Maybe<Scalars['String']>;
  qaStatusUpdatedAt?: Maybe<Scalars['timestamptz']>;
  salesforceIntegration?: Maybe<SalesforceIntegration_Obj_Rel_Insert_Input>;
  salesforceIntegrationId?: Maybe<Scalars['uuid']>;
  subscriptionStatus?: Maybe<Scalars['String']>;
  syncStatus?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userInvites?: Maybe<UserInvite_Arr_Rel_Insert_Input>;
  users?: Maybe<User_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Client_Max_Fields = {
  __typename?: 'client_max_fields';
  codeName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  firestoreId?: Maybe<Scalars['String']>;
  hubspotIntegrationId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  integrationType?: Maybe<Scalars['String']>;
  lastCsvUploadId?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  ownerMappingStatus?: Maybe<Scalars['String']>;
  qaStatus?: Maybe<Scalars['String']>;
  qaStatusUpdatedAt?: Maybe<Scalars['timestamptz']>;
  salesforceIntegrationId?: Maybe<Scalars['uuid']>;
  subscriptionStatus?: Maybe<Scalars['String']>;
  syncStatus?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "client" */
export type Client_Max_Order_By = {
  codeName?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  firestoreId?: Maybe<Order_By>;
  hubspotIntegrationId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  integrationType?: Maybe<Order_By>;
  lastCsvUploadId?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  ownerMappingStatus?: Maybe<Order_By>;
  qaStatus?: Maybe<Order_By>;
  qaStatusUpdatedAt?: Maybe<Order_By>;
  salesforceIntegrationId?: Maybe<Order_By>;
  subscriptionStatus?: Maybe<Order_By>;
  syncStatus?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Client_Min_Fields = {
  __typename?: 'client_min_fields';
  codeName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  firestoreId?: Maybe<Scalars['String']>;
  hubspotIntegrationId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  integrationType?: Maybe<Scalars['String']>;
  lastCsvUploadId?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  ownerMappingStatus?: Maybe<Scalars['String']>;
  qaStatus?: Maybe<Scalars['String']>;
  qaStatusUpdatedAt?: Maybe<Scalars['timestamptz']>;
  salesforceIntegrationId?: Maybe<Scalars['uuid']>;
  subscriptionStatus?: Maybe<Scalars['String']>;
  syncStatus?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "client" */
export type Client_Min_Order_By = {
  codeName?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  firestoreId?: Maybe<Order_By>;
  hubspotIntegrationId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  integrationType?: Maybe<Order_By>;
  lastCsvUploadId?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  ownerMappingStatus?: Maybe<Order_By>;
  qaStatus?: Maybe<Order_By>;
  qaStatusUpdatedAt?: Maybe<Order_By>;
  salesforceIntegrationId?: Maybe<Order_By>;
  subscriptionStatus?: Maybe<Order_By>;
  syncStatus?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** response of any mutation on the table "client" */
export type Client_Mutation_Response = {
  __typename?: 'client_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Client>;
};

/** input type for inserting object relation for remote table "client" */
export type Client_Obj_Rel_Insert_Input = {
  data: Client_Insert_Input;
  on_conflict?: Maybe<Client_On_Conflict>;
};

/** on conflict condition type for table "client" */
export type Client_On_Conflict = {
  constraint: Client_Constraint;
  update_columns: Array<Client_Update_Column>;
  where?: Maybe<Client_Bool_Exp>;
};

/** ordering options when selecting data from "client" */
export type Client_Order_By = {
  clientContacts_aggregate?: Maybe<ClientContact_Aggregate_Order_By>;
  clientEmailReports_aggregate?: Maybe<ClientEmailReport_Aggregate_Order_By>;
  clientNotificationEmails_aggregate?: Maybe<ClientNotificationEmail_Aggregate_Order_By>;
  codeName?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  csvUploads_aggregate?: Maybe<CsvUpload_Aggregate_Order_By>;
  firestoreId?: Maybe<Order_By>;
  hubspotIntegration?: Maybe<HubspotIntegration_Order_By>;
  hubspotIntegrationId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  integrationType?: Maybe<Order_By>;
  lastCsvUpload?: Maybe<CsvUpload_Order_By>;
  lastCsvUploadId?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  ownerMappingStatus?: Maybe<Order_By>;
  qaStatus?: Maybe<Order_By>;
  qaStatusUpdatedAt?: Maybe<Order_By>;
  salesforceIntegration?: Maybe<SalesforceIntegration_Order_By>;
  salesforceIntegrationId?: Maybe<Order_By>;
  subscriptionStatus?: Maybe<Order_By>;
  syncStatus?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  userInvites_aggregate?: Maybe<UserInvite_Aggregate_Order_By>;
  users_aggregate?: Maybe<User_Aggregate_Order_By>;
};

/** primary key columns input for table: "client" */
export type Client_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "client" */
export enum Client_Select_Column {
  /** column name */
  CodeName = 'codeName',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  FirestoreId = 'firestoreId',
  /** column name */
  HubspotIntegrationId = 'hubspotIntegrationId',
  /** column name */
  Id = 'id',
  /** column name */
  IntegrationType = 'integrationType',
  /** column name */
  LastCsvUploadId = 'lastCsvUploadId',
  /** column name */
  Name = 'name',
  /** column name */
  OwnerMappingStatus = 'ownerMappingStatus',
  /** column name */
  QaStatus = 'qaStatus',
  /** column name */
  QaStatusUpdatedAt = 'qaStatusUpdatedAt',
  /** column name */
  SalesforceIntegrationId = 'salesforceIntegrationId',
  /** column name */
  SubscriptionStatus = 'subscriptionStatus',
  /** column name */
  SyncStatus = 'syncStatus',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "client" */
export type Client_Set_Input = {
  codeName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  firestoreId?: Maybe<Scalars['String']>;
  hubspotIntegrationId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  integrationType?: Maybe<Scalars['String']>;
  lastCsvUploadId?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  ownerMappingStatus?: Maybe<Scalars['String']>;
  qaStatus?: Maybe<Scalars['String']>;
  qaStatusUpdatedAt?: Maybe<Scalars['timestamptz']>;
  salesforceIntegrationId?: Maybe<Scalars['uuid']>;
  subscriptionStatus?: Maybe<Scalars['String']>;
  syncStatus?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "client" */
export enum Client_Update_Column {
  /** column name */
  CodeName = 'codeName',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  FirestoreId = 'firestoreId',
  /** column name */
  HubspotIntegrationId = 'hubspotIntegrationId',
  /** column name */
  Id = 'id',
  /** column name */
  IntegrationType = 'integrationType',
  /** column name */
  LastCsvUploadId = 'lastCsvUploadId',
  /** column name */
  Name = 'name',
  /** column name */
  OwnerMappingStatus = 'ownerMappingStatus',
  /** column name */
  QaStatus = 'qaStatus',
  /** column name */
  QaStatusUpdatedAt = 'qaStatusUpdatedAt',
  /** column name */
  SalesforceIntegrationId = 'salesforceIntegrationId',
  /** column name */
  SubscriptionStatus = 'subscriptionStatus',
  /** column name */
  SyncStatus = 'syncStatus',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** columns and relationships of "cloudTask" */
export type CloudTask = {
  __typename?: 'cloudTask';
  createdAt: Scalars['timestamptz'];
  /** An object relationship */
  createdByUser?: Maybe<User>;
  createdByUserId?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  executionTime?: Maybe<Scalars['Int']>;
  id: Scalars['uuid'];
  queueName: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  taskData?: Maybe<Scalars['jsonb']>;
  taskIdentifier: Scalars['String'];
  taskName?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
};

/** columns and relationships of "cloudTask" */
export type CloudTaskTaskDataArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "cloudTask" */
export type CloudTask_Aggregate = {
  __typename?: 'cloudTask_aggregate';
  aggregate?: Maybe<CloudTask_Aggregate_Fields>;
  nodes: Array<CloudTask>;
};

/** aggregate fields of "cloudTask" */
export type CloudTask_Aggregate_Fields = {
  __typename?: 'cloudTask_aggregate_fields';
  avg?: Maybe<CloudTask_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<CloudTask_Max_Fields>;
  min?: Maybe<CloudTask_Min_Fields>;
  stddev?: Maybe<CloudTask_Stddev_Fields>;
  stddev_pop?: Maybe<CloudTask_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<CloudTask_Stddev_Samp_Fields>;
  sum?: Maybe<CloudTask_Sum_Fields>;
  var_pop?: Maybe<CloudTask_Var_Pop_Fields>;
  var_samp?: Maybe<CloudTask_Var_Samp_Fields>;
  variance?: Maybe<CloudTask_Variance_Fields>;
};

/** aggregate fields of "cloudTask" */
export type CloudTask_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<CloudTask_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "cloudTask" */
export type CloudTask_Aggregate_Order_By = {
  avg?: Maybe<CloudTask_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<CloudTask_Max_Order_By>;
  min?: Maybe<CloudTask_Min_Order_By>;
  stddev?: Maybe<CloudTask_Stddev_Order_By>;
  stddev_pop?: Maybe<CloudTask_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<CloudTask_Stddev_Samp_Order_By>;
  sum?: Maybe<CloudTask_Sum_Order_By>;
  var_pop?: Maybe<CloudTask_Var_Pop_Order_By>;
  var_samp?: Maybe<CloudTask_Var_Samp_Order_By>;
  variance?: Maybe<CloudTask_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type CloudTask_Append_Input = {
  taskData?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "cloudTask" */
export type CloudTask_Arr_Rel_Insert_Input = {
  data: Array<CloudTask_Insert_Input>;
  on_conflict?: Maybe<CloudTask_On_Conflict>;
};

/** aggregate avg on columns */
export type CloudTask_Avg_Fields = {
  __typename?: 'cloudTask_avg_fields';
  executionTime?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "cloudTask" */
export type CloudTask_Avg_Order_By = {
  executionTime?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "cloudTask". All fields are combined with a logical 'AND'. */
export type CloudTask_Bool_Exp = {
  _and?: Maybe<Array<Maybe<CloudTask_Bool_Exp>>>;
  _not?: Maybe<CloudTask_Bool_Exp>;
  _or?: Maybe<Array<Maybe<CloudTask_Bool_Exp>>>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  createdByUser?: Maybe<User_Bool_Exp>;
  createdByUserId?: Maybe<Uuid_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  executionTime?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  queueName?: Maybe<String_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  taskData?: Maybe<Jsonb_Comparison_Exp>;
  taskIdentifier?: Maybe<String_Comparison_Exp>;
  taskName?: Maybe<String_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "cloudTask" */
export enum CloudTask_Constraint {
  /** unique or primary key constraint */
  CloudTaskPkey = 'cloudTask_pkey',
  /** unique or primary key constraint */
  CloudTaskTaskIdentifierKey = 'cloudTask_taskIdentifier_key',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type CloudTask_Delete_At_Path_Input = {
  taskData?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type CloudTask_Delete_Elem_Input = {
  taskData?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type CloudTask_Delete_Key_Input = {
  taskData?: Maybe<Scalars['String']>;
};

/** input type for incrementing integer column in table "cloudTask" */
export type CloudTask_Inc_Input = {
  executionTime?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "cloudTask" */
export type CloudTask_Insert_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdByUser?: Maybe<User_Obj_Rel_Insert_Input>;
  createdByUserId?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  executionTime?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  queueName?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  taskData?: Maybe<Scalars['jsonb']>;
  taskIdentifier?: Maybe<Scalars['String']>;
  taskName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type CloudTask_Max_Fields = {
  __typename?: 'cloudTask_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdByUserId?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  executionTime?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  queueName?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  taskIdentifier?: Maybe<Scalars['String']>;
  taskName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "cloudTask" */
export type CloudTask_Max_Order_By = {
  createdAt?: Maybe<Order_By>;
  createdByUserId?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  executionTime?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  queueName?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  taskIdentifier?: Maybe<Order_By>;
  taskName?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type CloudTask_Min_Fields = {
  __typename?: 'cloudTask_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdByUserId?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  executionTime?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  queueName?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  taskIdentifier?: Maybe<Scalars['String']>;
  taskName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "cloudTask" */
export type CloudTask_Min_Order_By = {
  createdAt?: Maybe<Order_By>;
  createdByUserId?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  executionTime?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  queueName?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  taskIdentifier?: Maybe<Order_By>;
  taskName?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** response of any mutation on the table "cloudTask" */
export type CloudTask_Mutation_Response = {
  __typename?: 'cloudTask_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<CloudTask>;
};

/** input type for inserting object relation for remote table "cloudTask" */
export type CloudTask_Obj_Rel_Insert_Input = {
  data: CloudTask_Insert_Input;
  on_conflict?: Maybe<CloudTask_On_Conflict>;
};

/** on conflict condition type for table "cloudTask" */
export type CloudTask_On_Conflict = {
  constraint: CloudTask_Constraint;
  update_columns: Array<CloudTask_Update_Column>;
  where?: Maybe<CloudTask_Bool_Exp>;
};

/** ordering options when selecting data from "cloudTask" */
export type CloudTask_Order_By = {
  createdAt?: Maybe<Order_By>;
  createdByUser?: Maybe<User_Order_By>;
  createdByUserId?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  executionTime?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  queueName?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  taskData?: Maybe<Order_By>;
  taskIdentifier?: Maybe<Order_By>;
  taskName?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: "cloudTask" */
export type CloudTask_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type CloudTask_Prepend_Input = {
  taskData?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "cloudTask" */
export enum CloudTask_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CreatedByUserId = 'createdByUserId',
  /** column name */
  Description = 'description',
  /** column name */
  ExecutionTime = 'executionTime',
  /** column name */
  Id = 'id',
  /** column name */
  QueueName = 'queueName',
  /** column name */
  Status = 'status',
  /** column name */
  TaskData = 'taskData',
  /** column name */
  TaskIdentifier = 'taskIdentifier',
  /** column name */
  TaskName = 'taskName',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "cloudTask" */
export type CloudTask_Set_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdByUserId?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  executionTime?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  queueName?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  taskData?: Maybe<Scalars['jsonb']>;
  taskIdentifier?: Maybe<Scalars['String']>;
  taskName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type CloudTask_Stddev_Fields = {
  __typename?: 'cloudTask_stddev_fields';
  executionTime?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "cloudTask" */
export type CloudTask_Stddev_Order_By = {
  executionTime?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type CloudTask_Stddev_Pop_Fields = {
  __typename?: 'cloudTask_stddev_pop_fields';
  executionTime?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "cloudTask" */
export type CloudTask_Stddev_Pop_Order_By = {
  executionTime?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type CloudTask_Stddev_Samp_Fields = {
  __typename?: 'cloudTask_stddev_samp_fields';
  executionTime?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "cloudTask" */
export type CloudTask_Stddev_Samp_Order_By = {
  executionTime?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type CloudTask_Sum_Fields = {
  __typename?: 'cloudTask_sum_fields';
  executionTime?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "cloudTask" */
export type CloudTask_Sum_Order_By = {
  executionTime?: Maybe<Order_By>;
};

/** update columns of table "cloudTask" */
export enum CloudTask_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CreatedByUserId = 'createdByUserId',
  /** column name */
  Description = 'description',
  /** column name */
  ExecutionTime = 'executionTime',
  /** column name */
  Id = 'id',
  /** column name */
  QueueName = 'queueName',
  /** column name */
  Status = 'status',
  /** column name */
  TaskData = 'taskData',
  /** column name */
  TaskIdentifier = 'taskIdentifier',
  /** column name */
  TaskName = 'taskName',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** aggregate var_pop on columns */
export type CloudTask_Var_Pop_Fields = {
  __typename?: 'cloudTask_var_pop_fields';
  executionTime?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "cloudTask" */
export type CloudTask_Var_Pop_Order_By = {
  executionTime?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type CloudTask_Var_Samp_Fields = {
  __typename?: 'cloudTask_var_samp_fields';
  executionTime?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "cloudTask" */
export type CloudTask_Var_Samp_Order_By = {
  executionTime?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type CloudTask_Variance_Fields = {
  __typename?: 'cloudTask_variance_fields';
  executionTime?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "cloudTask" */
export type CloudTask_Variance_Order_By = {
  executionTime?: Maybe<Order_By>;
};

/** columns and relationships of "company" */
export type Company = {
  __typename?: 'company';
  /** An array relationship */
  companyNames: Array<CompanyName>;
  /** An aggregated array relationship */
  companyNames_aggregate: CompanyName_Aggregate;
  createdAt: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  employeeCount?: Maybe<Scalars['Int']>;
  estimatedSize?: Maybe<Scalars['String']>;
  firestoreId?: Maybe<Scalars['String']>;
  hqLocation?: Maybe<Scalars['String']>;
  /** An object relationship */
  hqTerritory?: Maybe<Territory>;
  hqTerritoryId?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  industry?: Maybe<Scalars['String']>;
  /** An array relationship */
  jobs: Array<Job>;
  /** An aggregated array relationship */
  jobs_aggregate: Job_Aggregate;
  linkedinId?: Maybe<Scalars['String']>;
  linkedinUrl?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  /** An object relationship */
  primaryName?: Maybe<CompanyName>;
  primaryNameId?: Maybe<Scalars['uuid']>;
  updatedAt: Scalars['timestamptz'];
  websiteDomain?: Maybe<Scalars['String']>;
  websiteRootDomain?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
};

/** columns and relationships of "company" */
export type CompanyCompanyNamesArgs = {
  distinct_on?: Maybe<Array<CompanyName_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CompanyName_Order_By>>;
  where?: Maybe<CompanyName_Bool_Exp>;
};

/** columns and relationships of "company" */
export type CompanyCompanyNames_AggregateArgs = {
  distinct_on?: Maybe<Array<CompanyName_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CompanyName_Order_By>>;
  where?: Maybe<CompanyName_Bool_Exp>;
};

/** columns and relationships of "company" */
export type CompanyJobsArgs = {
  distinct_on?: Maybe<Array<Job_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Job_Order_By>>;
  where?: Maybe<Job_Bool_Exp>;
};

/** columns and relationships of "company" */
export type CompanyJobs_AggregateArgs = {
  distinct_on?: Maybe<Array<Job_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Job_Order_By>>;
  where?: Maybe<Job_Bool_Exp>;
};

/** columns and relationships of "companyName" */
export type CompanyName = {
  __typename?: 'companyName';
  companyId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  name: Scalars['String'];
};

/** aggregated selection of "companyName" */
export type CompanyName_Aggregate = {
  __typename?: 'companyName_aggregate';
  aggregate?: Maybe<CompanyName_Aggregate_Fields>;
  nodes: Array<CompanyName>;
};

/** aggregate fields of "companyName" */
export type CompanyName_Aggregate_Fields = {
  __typename?: 'companyName_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<CompanyName_Max_Fields>;
  min?: Maybe<CompanyName_Min_Fields>;
};

/** aggregate fields of "companyName" */
export type CompanyName_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<CompanyName_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "companyName" */
export type CompanyName_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<CompanyName_Max_Order_By>;
  min?: Maybe<CompanyName_Min_Order_By>;
};

/** input type for inserting array relation for remote table "companyName" */
export type CompanyName_Arr_Rel_Insert_Input = {
  data: Array<CompanyName_Insert_Input>;
  on_conflict?: Maybe<CompanyName_On_Conflict>;
};

/** Boolean expression to filter rows from the table "companyName". All fields are combined with a logical 'AND'. */
export type CompanyName_Bool_Exp = {
  _and?: Maybe<Array<Maybe<CompanyName_Bool_Exp>>>;
  _not?: Maybe<CompanyName_Bool_Exp>;
  _or?: Maybe<Array<Maybe<CompanyName_Bool_Exp>>>;
  companyId?: Maybe<Uuid_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "companyName" */
export enum CompanyName_Constraint {
  /** unique or primary key constraint */
  CompanyNamePkey = 'companyName_pkey',
}

/** input type for inserting data into table "companyName" */
export type CompanyName_Insert_Input = {
  companyId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type CompanyName_Max_Fields = {
  __typename?: 'companyName_max_fields';
  companyId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "companyName" */
export type CompanyName_Max_Order_By = {
  companyId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type CompanyName_Min_Fields = {
  __typename?: 'companyName_min_fields';
  companyId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "companyName" */
export type CompanyName_Min_Order_By = {
  companyId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** response of any mutation on the table "companyName" */
export type CompanyName_Mutation_Response = {
  __typename?: 'companyName_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<CompanyName>;
};

/** input type for inserting object relation for remote table "companyName" */
export type CompanyName_Obj_Rel_Insert_Input = {
  data: CompanyName_Insert_Input;
  on_conflict?: Maybe<CompanyName_On_Conflict>;
};

/** on conflict condition type for table "companyName" */
export type CompanyName_On_Conflict = {
  constraint: CompanyName_Constraint;
  update_columns: Array<CompanyName_Update_Column>;
  where?: Maybe<CompanyName_Bool_Exp>;
};

/** ordering options when selecting data from "companyName" */
export type CompanyName_Order_By = {
  companyId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** primary key columns input for table: "companyName" */
export type CompanyName_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "companyName" */
export enum CompanyName_Select_Column {
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
}

/** input type for updating data in table "companyName" */
export type CompanyName_Set_Input = {
  companyId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** update columns of table "companyName" */
export enum CompanyName_Update_Column {
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
}

/** aggregated selection of "company" */
export type Company_Aggregate = {
  __typename?: 'company_aggregate';
  aggregate?: Maybe<Company_Aggregate_Fields>;
  nodes: Array<Company>;
};

/** aggregate fields of "company" */
export type Company_Aggregate_Fields = {
  __typename?: 'company_aggregate_fields';
  avg?: Maybe<Company_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Company_Max_Fields>;
  min?: Maybe<Company_Min_Fields>;
  stddev?: Maybe<Company_Stddev_Fields>;
  stddev_pop?: Maybe<Company_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Company_Stddev_Samp_Fields>;
  sum?: Maybe<Company_Sum_Fields>;
  var_pop?: Maybe<Company_Var_Pop_Fields>;
  var_samp?: Maybe<Company_Var_Samp_Fields>;
  variance?: Maybe<Company_Variance_Fields>;
};

/** aggregate fields of "company" */
export type Company_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Company_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "company" */
export type Company_Aggregate_Order_By = {
  avg?: Maybe<Company_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Company_Max_Order_By>;
  min?: Maybe<Company_Min_Order_By>;
  stddev?: Maybe<Company_Stddev_Order_By>;
  stddev_pop?: Maybe<Company_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Company_Stddev_Samp_Order_By>;
  sum?: Maybe<Company_Sum_Order_By>;
  var_pop?: Maybe<Company_Var_Pop_Order_By>;
  var_samp?: Maybe<Company_Var_Samp_Order_By>;
  variance?: Maybe<Company_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "company" */
export type Company_Arr_Rel_Insert_Input = {
  data: Array<Company_Insert_Input>;
  on_conflict?: Maybe<Company_On_Conflict>;
};

/** aggregate avg on columns */
export type Company_Avg_Fields = {
  __typename?: 'company_avg_fields';
  employeeCount?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "company" */
export type Company_Avg_Order_By = {
  employeeCount?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "company". All fields are combined with a logical 'AND'. */
export type Company_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Company_Bool_Exp>>>;
  _not?: Maybe<Company_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Company_Bool_Exp>>>;
  companyNames?: Maybe<CompanyName_Bool_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  employeeCount?: Maybe<Int_Comparison_Exp>;
  estimatedSize?: Maybe<String_Comparison_Exp>;
  firestoreId?: Maybe<String_Comparison_Exp>;
  hqLocation?: Maybe<String_Comparison_Exp>;
  hqTerritory?: Maybe<Territory_Bool_Exp>;
  hqTerritoryId?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  industry?: Maybe<String_Comparison_Exp>;
  jobs?: Maybe<Job_Bool_Exp>;
  linkedinId?: Maybe<String_Comparison_Exp>;
  linkedinUrl?: Maybe<String_Comparison_Exp>;
  logoUrl?: Maybe<String_Comparison_Exp>;
  primaryName?: Maybe<CompanyName_Bool_Exp>;
  primaryNameId?: Maybe<Uuid_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
  websiteDomain?: Maybe<String_Comparison_Exp>;
  websiteRootDomain?: Maybe<String_Comparison_Exp>;
  websiteUrl?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "company" */
export enum Company_Constraint {
  /** unique or primary key constraint */
  CompanyFirestoreIdKey = 'company_firestoreId_key',
  /** unique or primary key constraint */
  CompanyLinkedinIdKey = 'company_linkedinId_key',
  /** unique or primary key constraint */
  CompanyPkey = 'company_pkey',
}

/** input type for incrementing integer column in table "company" */
export type Company_Inc_Input = {
  employeeCount?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "company" */
export type Company_Insert_Input = {
  companyNames?: Maybe<CompanyName_Arr_Rel_Insert_Input>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  employeeCount?: Maybe<Scalars['Int']>;
  estimatedSize?: Maybe<Scalars['String']>;
  firestoreId?: Maybe<Scalars['String']>;
  hqLocation?: Maybe<Scalars['String']>;
  hqTerritory?: Maybe<Territory_Obj_Rel_Insert_Input>;
  hqTerritoryId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  industry?: Maybe<Scalars['String']>;
  jobs?: Maybe<Job_Arr_Rel_Insert_Input>;
  linkedinId?: Maybe<Scalars['String']>;
  linkedinUrl?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  primaryName?: Maybe<CompanyName_Obj_Rel_Insert_Input>;
  primaryNameId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  websiteDomain?: Maybe<Scalars['String']>;
  websiteRootDomain?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Company_Max_Fields = {
  __typename?: 'company_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  employeeCount?: Maybe<Scalars['Int']>;
  estimatedSize?: Maybe<Scalars['String']>;
  firestoreId?: Maybe<Scalars['String']>;
  hqLocation?: Maybe<Scalars['String']>;
  hqTerritoryId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  industry?: Maybe<Scalars['String']>;
  linkedinId?: Maybe<Scalars['String']>;
  linkedinUrl?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  primaryNameId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  websiteDomain?: Maybe<Scalars['String']>;
  websiteRootDomain?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "company" */
export type Company_Max_Order_By = {
  createdAt?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  employeeCount?: Maybe<Order_By>;
  estimatedSize?: Maybe<Order_By>;
  firestoreId?: Maybe<Order_By>;
  hqLocation?: Maybe<Order_By>;
  hqTerritoryId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  industry?: Maybe<Order_By>;
  linkedinId?: Maybe<Order_By>;
  linkedinUrl?: Maybe<Order_By>;
  logoUrl?: Maybe<Order_By>;
  primaryNameId?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  websiteDomain?: Maybe<Order_By>;
  websiteRootDomain?: Maybe<Order_By>;
  websiteUrl?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Company_Min_Fields = {
  __typename?: 'company_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  employeeCount?: Maybe<Scalars['Int']>;
  estimatedSize?: Maybe<Scalars['String']>;
  firestoreId?: Maybe<Scalars['String']>;
  hqLocation?: Maybe<Scalars['String']>;
  hqTerritoryId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  industry?: Maybe<Scalars['String']>;
  linkedinId?: Maybe<Scalars['String']>;
  linkedinUrl?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  primaryNameId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  websiteDomain?: Maybe<Scalars['String']>;
  websiteRootDomain?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "company" */
export type Company_Min_Order_By = {
  createdAt?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  employeeCount?: Maybe<Order_By>;
  estimatedSize?: Maybe<Order_By>;
  firestoreId?: Maybe<Order_By>;
  hqLocation?: Maybe<Order_By>;
  hqTerritoryId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  industry?: Maybe<Order_By>;
  linkedinId?: Maybe<Order_By>;
  linkedinUrl?: Maybe<Order_By>;
  logoUrl?: Maybe<Order_By>;
  primaryNameId?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  websiteDomain?: Maybe<Order_By>;
  websiteRootDomain?: Maybe<Order_By>;
  websiteUrl?: Maybe<Order_By>;
};

/** response of any mutation on the table "company" */
export type Company_Mutation_Response = {
  __typename?: 'company_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Company>;
};

/** input type for inserting object relation for remote table "company" */
export type Company_Obj_Rel_Insert_Input = {
  data: Company_Insert_Input;
  on_conflict?: Maybe<Company_On_Conflict>;
};

/** on conflict condition type for table "company" */
export type Company_On_Conflict = {
  constraint: Company_Constraint;
  update_columns: Array<Company_Update_Column>;
  where?: Maybe<Company_Bool_Exp>;
};

/** ordering options when selecting data from "company" */
export type Company_Order_By = {
  companyNames_aggregate?: Maybe<CompanyName_Aggregate_Order_By>;
  createdAt?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  employeeCount?: Maybe<Order_By>;
  estimatedSize?: Maybe<Order_By>;
  firestoreId?: Maybe<Order_By>;
  hqLocation?: Maybe<Order_By>;
  hqTerritory?: Maybe<Territory_Order_By>;
  hqTerritoryId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  industry?: Maybe<Order_By>;
  jobs_aggregate?: Maybe<Job_Aggregate_Order_By>;
  linkedinId?: Maybe<Order_By>;
  linkedinUrl?: Maybe<Order_By>;
  logoUrl?: Maybe<Order_By>;
  primaryName?: Maybe<CompanyName_Order_By>;
  primaryNameId?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  websiteDomain?: Maybe<Order_By>;
  websiteRootDomain?: Maybe<Order_By>;
  websiteUrl?: Maybe<Order_By>;
};

/** primary key columns input for table: "company" */
export type Company_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "company" */
export enum Company_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  EmployeeCount = 'employeeCount',
  /** column name */
  EstimatedSize = 'estimatedSize',
  /** column name */
  FirestoreId = 'firestoreId',
  /** column name */
  HqLocation = 'hqLocation',
  /** column name */
  HqTerritoryId = 'hqTerritoryId',
  /** column name */
  Id = 'id',
  /** column name */
  Industry = 'industry',
  /** column name */
  LinkedinId = 'linkedinId',
  /** column name */
  LinkedinUrl = 'linkedinUrl',
  /** column name */
  LogoUrl = 'logoUrl',
  /** column name */
  PrimaryNameId = 'primaryNameId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WebsiteDomain = 'websiteDomain',
  /** column name */
  WebsiteRootDomain = 'websiteRootDomain',
  /** column name */
  WebsiteUrl = 'websiteUrl',
}

/** input type for updating data in table "company" */
export type Company_Set_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  employeeCount?: Maybe<Scalars['Int']>;
  estimatedSize?: Maybe<Scalars['String']>;
  firestoreId?: Maybe<Scalars['String']>;
  hqLocation?: Maybe<Scalars['String']>;
  hqTerritoryId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  industry?: Maybe<Scalars['String']>;
  linkedinId?: Maybe<Scalars['String']>;
  linkedinUrl?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  primaryNameId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  websiteDomain?: Maybe<Scalars['String']>;
  websiteRootDomain?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Company_Stddev_Fields = {
  __typename?: 'company_stddev_fields';
  employeeCount?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "company" */
export type Company_Stddev_Order_By = {
  employeeCount?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Company_Stddev_Pop_Fields = {
  __typename?: 'company_stddev_pop_fields';
  employeeCount?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "company" */
export type Company_Stddev_Pop_Order_By = {
  employeeCount?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Company_Stddev_Samp_Fields = {
  __typename?: 'company_stddev_samp_fields';
  employeeCount?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "company" */
export type Company_Stddev_Samp_Order_By = {
  employeeCount?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Company_Sum_Fields = {
  __typename?: 'company_sum_fields';
  employeeCount?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "company" */
export type Company_Sum_Order_By = {
  employeeCount?: Maybe<Order_By>;
};

/** update columns of table "company" */
export enum Company_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  EmployeeCount = 'employeeCount',
  /** column name */
  EstimatedSize = 'estimatedSize',
  /** column name */
  FirestoreId = 'firestoreId',
  /** column name */
  HqLocation = 'hqLocation',
  /** column name */
  HqTerritoryId = 'hqTerritoryId',
  /** column name */
  Id = 'id',
  /** column name */
  Industry = 'industry',
  /** column name */
  LinkedinId = 'linkedinId',
  /** column name */
  LinkedinUrl = 'linkedinUrl',
  /** column name */
  LogoUrl = 'logoUrl',
  /** column name */
  PrimaryNameId = 'primaryNameId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WebsiteDomain = 'websiteDomain',
  /** column name */
  WebsiteRootDomain = 'websiteRootDomain',
  /** column name */
  WebsiteUrl = 'websiteUrl',
}

/** aggregate var_pop on columns */
export type Company_Var_Pop_Fields = {
  __typename?: 'company_var_pop_fields';
  employeeCount?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "company" */
export type Company_Var_Pop_Order_By = {
  employeeCount?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Company_Var_Samp_Fields = {
  __typename?: 'company_var_samp_fields';
  employeeCount?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "company" */
export type Company_Var_Samp_Order_By = {
  employeeCount?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Company_Variance_Fields = {
  __typename?: 'company_variance_fields';
  employeeCount?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "company" */
export type Company_Variance_Order_By = {
  employeeCount?: Maybe<Order_By>;
};

/** columns and relationships of "contactData" */
export type ContactData = {
  __typename?: 'contactData';
  /** An array relationship */
  clientContacts: Array<ClientContact>;
  /** An aggregated array relationship */
  clientContacts_aggregate: ClientContact_Aggregate;
  /** An array relationship */
  contactEmails: Array<ContactEmail>;
  /** An aggregated array relationship */
  contactEmails_aggregate: ContactEmail_Aggregate;
  createdAt?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  currentJob?: Maybe<Job>;
  currentJobId?: Maybe<Scalars['uuid']>;
  firestoreId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  /** An array relationship */
  jobs: Array<Job>;
  /** An aggregated array relationship */
  jobs_aggregate: Job_Aggregate;
  lastName?: Maybe<Scalars['String']>;
  /** An object relationship */
  lastProxycrawlData?: Maybe<ProxycrawlData>;
  lastProxycrawlDataId?: Maybe<Scalars['uuid']>;
  linkedinId?: Maybe<Scalars['String']>;
  linkedinUrl?: Maybe<Scalars['String']>;
  profilePhotoUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** columns and relationships of "contactData" */
export type ContactDataClientContactsArgs = {
  distinct_on?: Maybe<Array<ClientContact_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ClientContact_Order_By>>;
  where?: Maybe<ClientContact_Bool_Exp>;
};

/** columns and relationships of "contactData" */
export type ContactDataClientContacts_AggregateArgs = {
  distinct_on?: Maybe<Array<ClientContact_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ClientContact_Order_By>>;
  where?: Maybe<ClientContact_Bool_Exp>;
};

/** columns and relationships of "contactData" */
export type ContactDataContactEmailsArgs = {
  distinct_on?: Maybe<Array<ContactEmail_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContactEmail_Order_By>>;
  where?: Maybe<ContactEmail_Bool_Exp>;
};

/** columns and relationships of "contactData" */
export type ContactDataContactEmails_AggregateArgs = {
  distinct_on?: Maybe<Array<ContactEmail_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContactEmail_Order_By>>;
  where?: Maybe<ContactEmail_Bool_Exp>;
};

/** columns and relationships of "contactData" */
export type ContactDataJobsArgs = {
  distinct_on?: Maybe<Array<Job_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Job_Order_By>>;
  where?: Maybe<Job_Bool_Exp>;
};

/** columns and relationships of "contactData" */
export type ContactDataJobs_AggregateArgs = {
  distinct_on?: Maybe<Array<Job_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Job_Order_By>>;
  where?: Maybe<Job_Bool_Exp>;
};

/** aggregated selection of "contactData" */
export type ContactData_Aggregate = {
  __typename?: 'contactData_aggregate';
  aggregate?: Maybe<ContactData_Aggregate_Fields>;
  nodes: Array<ContactData>;
};

/** aggregate fields of "contactData" */
export type ContactData_Aggregate_Fields = {
  __typename?: 'contactData_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<ContactData_Max_Fields>;
  min?: Maybe<ContactData_Min_Fields>;
};

/** aggregate fields of "contactData" */
export type ContactData_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<ContactData_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "contactData" */
export type ContactData_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<ContactData_Max_Order_By>;
  min?: Maybe<ContactData_Min_Order_By>;
};

/** input type for inserting array relation for remote table "contactData" */
export type ContactData_Arr_Rel_Insert_Input = {
  data: Array<ContactData_Insert_Input>;
  on_conflict?: Maybe<ContactData_On_Conflict>;
};

/** Boolean expression to filter rows from the table "contactData". All fields are combined with a logical 'AND'. */
export type ContactData_Bool_Exp = {
  _and?: Maybe<Array<Maybe<ContactData_Bool_Exp>>>;
  _not?: Maybe<ContactData_Bool_Exp>;
  _or?: Maybe<Array<Maybe<ContactData_Bool_Exp>>>;
  clientContacts?: Maybe<ClientContact_Bool_Exp>;
  contactEmails?: Maybe<ContactEmail_Bool_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  currentJob?: Maybe<Job_Bool_Exp>;
  currentJobId?: Maybe<Uuid_Comparison_Exp>;
  firestoreId?: Maybe<String_Comparison_Exp>;
  firstName?: Maybe<String_Comparison_Exp>;
  fullName?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  jobs?: Maybe<Job_Bool_Exp>;
  lastName?: Maybe<String_Comparison_Exp>;
  lastProxycrawlData?: Maybe<ProxycrawlData_Bool_Exp>;
  lastProxycrawlDataId?: Maybe<Uuid_Comparison_Exp>;
  linkedinId?: Maybe<String_Comparison_Exp>;
  linkedinUrl?: Maybe<String_Comparison_Exp>;
  profilePhotoUrl?: Maybe<String_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "contactData" */
export enum ContactData_Constraint {
  /** unique or primary key constraint */
  ContactDataFirestoreIdKey = 'contactData_firestoreId_key',
  /** unique or primary key constraint */
  ContactDataLinkedinIdKey = 'contactData_linkedinId_key',
  /** unique or primary key constraint */
  ContactDataPkey = 'contactData_pkey',
}

/** input type for inserting data into table "contactData" */
export type ContactData_Insert_Input = {
  clientContacts?: Maybe<ClientContact_Arr_Rel_Insert_Input>;
  contactEmails?: Maybe<ContactEmail_Arr_Rel_Insert_Input>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  currentJob?: Maybe<Job_Obj_Rel_Insert_Input>;
  currentJobId?: Maybe<Scalars['uuid']>;
  firestoreId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  jobs?: Maybe<Job_Arr_Rel_Insert_Input>;
  lastName?: Maybe<Scalars['String']>;
  lastProxycrawlData?: Maybe<ProxycrawlData_Obj_Rel_Insert_Input>;
  lastProxycrawlDataId?: Maybe<Scalars['uuid']>;
  linkedinId?: Maybe<Scalars['String']>;
  linkedinUrl?: Maybe<Scalars['String']>;
  profilePhotoUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type ContactData_Max_Fields = {
  __typename?: 'contactData_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  currentJobId?: Maybe<Scalars['uuid']>;
  firestoreId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastName?: Maybe<Scalars['String']>;
  lastProxycrawlDataId?: Maybe<Scalars['uuid']>;
  linkedinId?: Maybe<Scalars['String']>;
  linkedinUrl?: Maybe<Scalars['String']>;
  profilePhotoUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "contactData" */
export type ContactData_Max_Order_By = {
  createdAt?: Maybe<Order_By>;
  currentJobId?: Maybe<Order_By>;
  firestoreId?: Maybe<Order_By>;
  firstName?: Maybe<Order_By>;
  fullName?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lastName?: Maybe<Order_By>;
  lastProxycrawlDataId?: Maybe<Order_By>;
  linkedinId?: Maybe<Order_By>;
  linkedinUrl?: Maybe<Order_By>;
  profilePhotoUrl?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type ContactData_Min_Fields = {
  __typename?: 'contactData_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  currentJobId?: Maybe<Scalars['uuid']>;
  firestoreId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastName?: Maybe<Scalars['String']>;
  lastProxycrawlDataId?: Maybe<Scalars['uuid']>;
  linkedinId?: Maybe<Scalars['String']>;
  linkedinUrl?: Maybe<Scalars['String']>;
  profilePhotoUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "contactData" */
export type ContactData_Min_Order_By = {
  createdAt?: Maybe<Order_By>;
  currentJobId?: Maybe<Order_By>;
  firestoreId?: Maybe<Order_By>;
  firstName?: Maybe<Order_By>;
  fullName?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lastName?: Maybe<Order_By>;
  lastProxycrawlDataId?: Maybe<Order_By>;
  linkedinId?: Maybe<Order_By>;
  linkedinUrl?: Maybe<Order_By>;
  profilePhotoUrl?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** response of any mutation on the table "contactData" */
export type ContactData_Mutation_Response = {
  __typename?: 'contactData_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<ContactData>;
};

/** input type for inserting object relation for remote table "contactData" */
export type ContactData_Obj_Rel_Insert_Input = {
  data: ContactData_Insert_Input;
  on_conflict?: Maybe<ContactData_On_Conflict>;
};

/** on conflict condition type for table "contactData" */
export type ContactData_On_Conflict = {
  constraint: ContactData_Constraint;
  update_columns: Array<ContactData_Update_Column>;
  where?: Maybe<ContactData_Bool_Exp>;
};

/** ordering options when selecting data from "contactData" */
export type ContactData_Order_By = {
  clientContacts_aggregate?: Maybe<ClientContact_Aggregate_Order_By>;
  contactEmails_aggregate?: Maybe<ContactEmail_Aggregate_Order_By>;
  createdAt?: Maybe<Order_By>;
  currentJob?: Maybe<Job_Order_By>;
  currentJobId?: Maybe<Order_By>;
  firestoreId?: Maybe<Order_By>;
  firstName?: Maybe<Order_By>;
  fullName?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  jobs_aggregate?: Maybe<Job_Aggregate_Order_By>;
  lastName?: Maybe<Order_By>;
  lastProxycrawlData?: Maybe<ProxycrawlData_Order_By>;
  lastProxycrawlDataId?: Maybe<Order_By>;
  linkedinId?: Maybe<Order_By>;
  linkedinUrl?: Maybe<Order_By>;
  profilePhotoUrl?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: "contactData" */
export type ContactData_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "contactData" */
export enum ContactData_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CurrentJobId = 'currentJobId',
  /** column name */
  FirestoreId = 'firestoreId',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  FullName = 'fullName',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'lastName',
  /** column name */
  LastProxycrawlDataId = 'lastProxycrawlDataId',
  /** column name */
  LinkedinId = 'linkedinId',
  /** column name */
  LinkedinUrl = 'linkedinUrl',
  /** column name */
  ProfilePhotoUrl = 'profilePhotoUrl',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "contactData" */
export type ContactData_Set_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  currentJobId?: Maybe<Scalars['uuid']>;
  firestoreId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastName?: Maybe<Scalars['String']>;
  lastProxycrawlDataId?: Maybe<Scalars['uuid']>;
  linkedinId?: Maybe<Scalars['String']>;
  linkedinUrl?: Maybe<Scalars['String']>;
  profilePhotoUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "contactData" */
export enum ContactData_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CurrentJobId = 'currentJobId',
  /** column name */
  FirestoreId = 'firestoreId',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  FullName = 'fullName',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'lastName',
  /** column name */
  LastProxycrawlDataId = 'lastProxycrawlDataId',
  /** column name */
  LinkedinId = 'linkedinId',
  /** column name */
  LinkedinUrl = 'linkedinUrl',
  /** column name */
  ProfilePhotoUrl = 'profilePhotoUrl',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** columns and relationships of "contactEmail" */
export type ContactEmail = {
  __typename?: 'contactEmail';
  contactDataId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  email: Scalars['String'];
  id: Scalars['uuid'];
  isValid?: Maybe<Scalars['Boolean']>;
  source?: Maybe<Scalars['String']>;
  validatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "contactEmail" */
export type ContactEmail_Aggregate = {
  __typename?: 'contactEmail_aggregate';
  aggregate?: Maybe<ContactEmail_Aggregate_Fields>;
  nodes: Array<ContactEmail>;
};

/** aggregate fields of "contactEmail" */
export type ContactEmail_Aggregate_Fields = {
  __typename?: 'contactEmail_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<ContactEmail_Max_Fields>;
  min?: Maybe<ContactEmail_Min_Fields>;
};

/** aggregate fields of "contactEmail" */
export type ContactEmail_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<ContactEmail_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "contactEmail" */
export type ContactEmail_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<ContactEmail_Max_Order_By>;
  min?: Maybe<ContactEmail_Min_Order_By>;
};

/** input type for inserting array relation for remote table "contactEmail" */
export type ContactEmail_Arr_Rel_Insert_Input = {
  data: Array<ContactEmail_Insert_Input>;
  on_conflict?: Maybe<ContactEmail_On_Conflict>;
};

/** Boolean expression to filter rows from the table "contactEmail". All fields are combined with a logical 'AND'. */
export type ContactEmail_Bool_Exp = {
  _and?: Maybe<Array<Maybe<ContactEmail_Bool_Exp>>>;
  _not?: Maybe<ContactEmail_Bool_Exp>;
  _or?: Maybe<Array<Maybe<ContactEmail_Bool_Exp>>>;
  contactDataId?: Maybe<Uuid_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  isValid?: Maybe<Boolean_Comparison_Exp>;
  source?: Maybe<String_Comparison_Exp>;
  validatedAt?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "contactEmail" */
export enum ContactEmail_Constraint {
  /** unique or primary key constraint */
  ContactEmailEmailContactDataIdKey = 'contactEmail_email_contactDataId_key',
  /** unique or primary key constraint */
  ContactEmailPkey = 'contactEmail_pkey',
}

/** input type for inserting data into table "contactEmail" */
export type ContactEmail_Insert_Input = {
  contactDataId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  isValid?: Maybe<Scalars['Boolean']>;
  source?: Maybe<Scalars['String']>;
  validatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type ContactEmail_Max_Fields = {
  __typename?: 'contactEmail_max_fields';
  contactDataId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  source?: Maybe<Scalars['String']>;
  validatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "contactEmail" */
export type ContactEmail_Max_Order_By = {
  contactDataId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  source?: Maybe<Order_By>;
  validatedAt?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type ContactEmail_Min_Fields = {
  __typename?: 'contactEmail_min_fields';
  contactDataId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  source?: Maybe<Scalars['String']>;
  validatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "contactEmail" */
export type ContactEmail_Min_Order_By = {
  contactDataId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  source?: Maybe<Order_By>;
  validatedAt?: Maybe<Order_By>;
};

/** response of any mutation on the table "contactEmail" */
export type ContactEmail_Mutation_Response = {
  __typename?: 'contactEmail_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<ContactEmail>;
};

/** input type for inserting object relation for remote table "contactEmail" */
export type ContactEmail_Obj_Rel_Insert_Input = {
  data: ContactEmail_Insert_Input;
  on_conflict?: Maybe<ContactEmail_On_Conflict>;
};

/** on conflict condition type for table "contactEmail" */
export type ContactEmail_On_Conflict = {
  constraint: ContactEmail_Constraint;
  update_columns: Array<ContactEmail_Update_Column>;
  where?: Maybe<ContactEmail_Bool_Exp>;
};

/** ordering options when selecting data from "contactEmail" */
export type ContactEmail_Order_By = {
  contactDataId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  isValid?: Maybe<Order_By>;
  source?: Maybe<Order_By>;
  validatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: "contactEmail" */
export type ContactEmail_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "contactEmail" */
export enum ContactEmail_Select_Column {
  /** column name */
  ContactDataId = 'contactDataId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  IsValid = 'isValid',
  /** column name */
  Source = 'source',
  /** column name */
  ValidatedAt = 'validatedAt',
}

/** input type for updating data in table "contactEmail" */
export type ContactEmail_Set_Input = {
  contactDataId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  isValid?: Maybe<Scalars['Boolean']>;
  source?: Maybe<Scalars['String']>;
  validatedAt?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "contactEmail" */
export enum ContactEmail_Update_Column {
  /** column name */
  ContactDataId = 'contactDataId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  IsValid = 'isValid',
  /** column name */
  Source = 'source',
  /** column name */
  ValidatedAt = 'validatedAt',
}

/** columns and relationships of "crmContactEnrichmentHistory" */
export type CrmContactEnrichmentHistory = {
  __typename?: 'crmContactEnrichmentHistory';
  clientContactId?: Maybe<Scalars['uuid']>;
  clientId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  crmField: Scalars['String'];
  crmType: Scalars['String'];
  enrichmentType: Scalars['String'];
  id: Scalars['uuid'];
  previousValue?: Maybe<Scalars['String']>;
  updatedValue: Scalars['String'];
};

/** aggregated selection of "crmContactEnrichmentHistory" */
export type CrmContactEnrichmentHistory_Aggregate = {
  __typename?: 'crmContactEnrichmentHistory_aggregate';
  aggregate?: Maybe<CrmContactEnrichmentHistory_Aggregate_Fields>;
  nodes: Array<CrmContactEnrichmentHistory>;
};

/** aggregate fields of "crmContactEnrichmentHistory" */
export type CrmContactEnrichmentHistory_Aggregate_Fields = {
  __typename?: 'crmContactEnrichmentHistory_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<CrmContactEnrichmentHistory_Max_Fields>;
  min?: Maybe<CrmContactEnrichmentHistory_Min_Fields>;
};

/** aggregate fields of "crmContactEnrichmentHistory" */
export type CrmContactEnrichmentHistory_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<CrmContactEnrichmentHistory_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "crmContactEnrichmentHistory" */
export type CrmContactEnrichmentHistory_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<CrmContactEnrichmentHistory_Max_Order_By>;
  min?: Maybe<CrmContactEnrichmentHistory_Min_Order_By>;
};

/** input type for inserting array relation for remote table "crmContactEnrichmentHistory" */
export type CrmContactEnrichmentHistory_Arr_Rel_Insert_Input = {
  data: Array<CrmContactEnrichmentHistory_Insert_Input>;
  on_conflict?: Maybe<CrmContactEnrichmentHistory_On_Conflict>;
};

/** Boolean expression to filter rows from the table "crmContactEnrichmentHistory". All fields are combined with a logical 'AND'. */
export type CrmContactEnrichmentHistory_Bool_Exp = {
  _and?: Maybe<Array<Maybe<CrmContactEnrichmentHistory_Bool_Exp>>>;
  _not?: Maybe<CrmContactEnrichmentHistory_Bool_Exp>;
  _or?: Maybe<Array<Maybe<CrmContactEnrichmentHistory_Bool_Exp>>>;
  clientContactId?: Maybe<Uuid_Comparison_Exp>;
  clientId?: Maybe<Uuid_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  crmField?: Maybe<String_Comparison_Exp>;
  crmType?: Maybe<String_Comparison_Exp>;
  enrichmentType?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  previousValue?: Maybe<String_Comparison_Exp>;
  updatedValue?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "crmContactEnrichmentHistory" */
export enum CrmContactEnrichmentHistory_Constraint {
  /** unique or primary key constraint */
  CrmContactEnrichmentHistoryPkey = 'crmContactEnrichmentHistory_pkey',
}

/** input type for inserting data into table "crmContactEnrichmentHistory" */
export type CrmContactEnrichmentHistory_Insert_Input = {
  clientContactId?: Maybe<Scalars['uuid']>;
  clientId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  crmField?: Maybe<Scalars['String']>;
  crmType?: Maybe<Scalars['String']>;
  enrichmentType?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  previousValue?: Maybe<Scalars['String']>;
  updatedValue?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type CrmContactEnrichmentHistory_Max_Fields = {
  __typename?: 'crmContactEnrichmentHistory_max_fields';
  clientContactId?: Maybe<Scalars['uuid']>;
  clientId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  crmField?: Maybe<Scalars['String']>;
  crmType?: Maybe<Scalars['String']>;
  enrichmentType?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  previousValue?: Maybe<Scalars['String']>;
  updatedValue?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "crmContactEnrichmentHistory" */
export type CrmContactEnrichmentHistory_Max_Order_By = {
  clientContactId?: Maybe<Order_By>;
  clientId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  crmField?: Maybe<Order_By>;
  crmType?: Maybe<Order_By>;
  enrichmentType?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  previousValue?: Maybe<Order_By>;
  updatedValue?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type CrmContactEnrichmentHistory_Min_Fields = {
  __typename?: 'crmContactEnrichmentHistory_min_fields';
  clientContactId?: Maybe<Scalars['uuid']>;
  clientId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  crmField?: Maybe<Scalars['String']>;
  crmType?: Maybe<Scalars['String']>;
  enrichmentType?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  previousValue?: Maybe<Scalars['String']>;
  updatedValue?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "crmContactEnrichmentHistory" */
export type CrmContactEnrichmentHistory_Min_Order_By = {
  clientContactId?: Maybe<Order_By>;
  clientId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  crmField?: Maybe<Order_By>;
  crmType?: Maybe<Order_By>;
  enrichmentType?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  previousValue?: Maybe<Order_By>;
  updatedValue?: Maybe<Order_By>;
};

/** response of any mutation on the table "crmContactEnrichmentHistory" */
export type CrmContactEnrichmentHistory_Mutation_Response = {
  __typename?: 'crmContactEnrichmentHistory_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<CrmContactEnrichmentHistory>;
};

/** input type for inserting object relation for remote table "crmContactEnrichmentHistory" */
export type CrmContactEnrichmentHistory_Obj_Rel_Insert_Input = {
  data: CrmContactEnrichmentHistory_Insert_Input;
  on_conflict?: Maybe<CrmContactEnrichmentHistory_On_Conflict>;
};

/** on conflict condition type for table "crmContactEnrichmentHistory" */
export type CrmContactEnrichmentHistory_On_Conflict = {
  constraint: CrmContactEnrichmentHistory_Constraint;
  update_columns: Array<CrmContactEnrichmentHistory_Update_Column>;
  where?: Maybe<CrmContactEnrichmentHistory_Bool_Exp>;
};

/** ordering options when selecting data from "crmContactEnrichmentHistory" */
export type CrmContactEnrichmentHistory_Order_By = {
  clientContactId?: Maybe<Order_By>;
  clientId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  crmField?: Maybe<Order_By>;
  crmType?: Maybe<Order_By>;
  enrichmentType?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  previousValue?: Maybe<Order_By>;
  updatedValue?: Maybe<Order_By>;
};

/** primary key columns input for table: "crmContactEnrichmentHistory" */
export type CrmContactEnrichmentHistory_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "crmContactEnrichmentHistory" */
export enum CrmContactEnrichmentHistory_Select_Column {
  /** column name */
  ClientContactId = 'clientContactId',
  /** column name */
  ClientId = 'clientId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CrmField = 'crmField',
  /** column name */
  CrmType = 'crmType',
  /** column name */
  EnrichmentType = 'enrichmentType',
  /** column name */
  Id = 'id',
  /** column name */
  PreviousValue = 'previousValue',
  /** column name */
  UpdatedValue = 'updatedValue',
}

/** input type for updating data in table "crmContactEnrichmentHistory" */
export type CrmContactEnrichmentHistory_Set_Input = {
  clientContactId?: Maybe<Scalars['uuid']>;
  clientId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  crmField?: Maybe<Scalars['String']>;
  crmType?: Maybe<Scalars['String']>;
  enrichmentType?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  previousValue?: Maybe<Scalars['String']>;
  updatedValue?: Maybe<Scalars['String']>;
};

/** update columns of table "crmContactEnrichmentHistory" */
export enum CrmContactEnrichmentHistory_Update_Column {
  /** column name */
  ClientContactId = 'clientContactId',
  /** column name */
  ClientId = 'clientId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CrmField = 'crmField',
  /** column name */
  CrmType = 'crmType',
  /** column name */
  EnrichmentType = 'enrichmentType',
  /** column name */
  Id = 'id',
  /** column name */
  PreviousValue = 'previousValue',
  /** column name */
  UpdatedValue = 'updatedValue',
}

/** columns and relationships of "csvUpload" */
export type CsvUpload = {
  __typename?: 'csvUpload';
  clientId: Scalars['uuid'];
  contactCount?: Maybe<Scalars['Int']>;
  fileName?: Maybe<Scalars['String']>;
  filePath?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  mapping?: Maybe<Scalars['jsonb']>;
  syncCompletedAt?: Maybe<Scalars['timestamptz']>;
  uploadedAt?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  uploadedByUser?: Maybe<User>;
  uploadedByUserId?: Maybe<Scalars['uuid']>;
};

/** columns and relationships of "csvUpload" */
export type CsvUploadMappingArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "csvUpload" */
export type CsvUpload_Aggregate = {
  __typename?: 'csvUpload_aggregate';
  aggregate?: Maybe<CsvUpload_Aggregate_Fields>;
  nodes: Array<CsvUpload>;
};

/** aggregate fields of "csvUpload" */
export type CsvUpload_Aggregate_Fields = {
  __typename?: 'csvUpload_aggregate_fields';
  avg?: Maybe<CsvUpload_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<CsvUpload_Max_Fields>;
  min?: Maybe<CsvUpload_Min_Fields>;
  stddev?: Maybe<CsvUpload_Stddev_Fields>;
  stddev_pop?: Maybe<CsvUpload_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<CsvUpload_Stddev_Samp_Fields>;
  sum?: Maybe<CsvUpload_Sum_Fields>;
  var_pop?: Maybe<CsvUpload_Var_Pop_Fields>;
  var_samp?: Maybe<CsvUpload_Var_Samp_Fields>;
  variance?: Maybe<CsvUpload_Variance_Fields>;
};

/** aggregate fields of "csvUpload" */
export type CsvUpload_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<CsvUpload_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "csvUpload" */
export type CsvUpload_Aggregate_Order_By = {
  avg?: Maybe<CsvUpload_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<CsvUpload_Max_Order_By>;
  min?: Maybe<CsvUpload_Min_Order_By>;
  stddev?: Maybe<CsvUpload_Stddev_Order_By>;
  stddev_pop?: Maybe<CsvUpload_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<CsvUpload_Stddev_Samp_Order_By>;
  sum?: Maybe<CsvUpload_Sum_Order_By>;
  var_pop?: Maybe<CsvUpload_Var_Pop_Order_By>;
  var_samp?: Maybe<CsvUpload_Var_Samp_Order_By>;
  variance?: Maybe<CsvUpload_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type CsvUpload_Append_Input = {
  mapping?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "csvUpload" */
export type CsvUpload_Arr_Rel_Insert_Input = {
  data: Array<CsvUpload_Insert_Input>;
  on_conflict?: Maybe<CsvUpload_On_Conflict>;
};

/** aggregate avg on columns */
export type CsvUpload_Avg_Fields = {
  __typename?: 'csvUpload_avg_fields';
  contactCount?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "csvUpload" */
export type CsvUpload_Avg_Order_By = {
  contactCount?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "csvUpload". All fields are combined with a logical 'AND'. */
export type CsvUpload_Bool_Exp = {
  _and?: Maybe<Array<Maybe<CsvUpload_Bool_Exp>>>;
  _not?: Maybe<CsvUpload_Bool_Exp>;
  _or?: Maybe<Array<Maybe<CsvUpload_Bool_Exp>>>;
  clientId?: Maybe<Uuid_Comparison_Exp>;
  contactCount?: Maybe<Int_Comparison_Exp>;
  fileName?: Maybe<String_Comparison_Exp>;
  filePath?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  mapping?: Maybe<Jsonb_Comparison_Exp>;
  syncCompletedAt?: Maybe<Timestamptz_Comparison_Exp>;
  uploadedAt?: Maybe<Timestamptz_Comparison_Exp>;
  uploadedByUser?: Maybe<User_Bool_Exp>;
  uploadedByUserId?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "csvUpload" */
export enum CsvUpload_Constraint {
  /** unique or primary key constraint */
  CsvUploadPkey = 'csvUpload_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type CsvUpload_Delete_At_Path_Input = {
  mapping?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type CsvUpload_Delete_Elem_Input = {
  mapping?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type CsvUpload_Delete_Key_Input = {
  mapping?: Maybe<Scalars['String']>;
};

/** input type for incrementing integer column in table "csvUpload" */
export type CsvUpload_Inc_Input = {
  contactCount?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "csvUpload" */
export type CsvUpload_Insert_Input = {
  clientId?: Maybe<Scalars['uuid']>;
  contactCount?: Maybe<Scalars['Int']>;
  fileName?: Maybe<Scalars['String']>;
  filePath?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  mapping?: Maybe<Scalars['jsonb']>;
  syncCompletedAt?: Maybe<Scalars['timestamptz']>;
  uploadedAt?: Maybe<Scalars['timestamptz']>;
  uploadedByUser?: Maybe<User_Obj_Rel_Insert_Input>;
  uploadedByUserId?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type CsvUpload_Max_Fields = {
  __typename?: 'csvUpload_max_fields';
  clientId?: Maybe<Scalars['uuid']>;
  contactCount?: Maybe<Scalars['Int']>;
  fileName?: Maybe<Scalars['String']>;
  filePath?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  syncCompletedAt?: Maybe<Scalars['timestamptz']>;
  uploadedAt?: Maybe<Scalars['timestamptz']>;
  uploadedByUserId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "csvUpload" */
export type CsvUpload_Max_Order_By = {
  clientId?: Maybe<Order_By>;
  contactCount?: Maybe<Order_By>;
  fileName?: Maybe<Order_By>;
  filePath?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  syncCompletedAt?: Maybe<Order_By>;
  uploadedAt?: Maybe<Order_By>;
  uploadedByUserId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type CsvUpload_Min_Fields = {
  __typename?: 'csvUpload_min_fields';
  clientId?: Maybe<Scalars['uuid']>;
  contactCount?: Maybe<Scalars['Int']>;
  fileName?: Maybe<Scalars['String']>;
  filePath?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  syncCompletedAt?: Maybe<Scalars['timestamptz']>;
  uploadedAt?: Maybe<Scalars['timestamptz']>;
  uploadedByUserId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "csvUpload" */
export type CsvUpload_Min_Order_By = {
  clientId?: Maybe<Order_By>;
  contactCount?: Maybe<Order_By>;
  fileName?: Maybe<Order_By>;
  filePath?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  syncCompletedAt?: Maybe<Order_By>;
  uploadedAt?: Maybe<Order_By>;
  uploadedByUserId?: Maybe<Order_By>;
};

/** response of any mutation on the table "csvUpload" */
export type CsvUpload_Mutation_Response = {
  __typename?: 'csvUpload_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<CsvUpload>;
};

/** input type for inserting object relation for remote table "csvUpload" */
export type CsvUpload_Obj_Rel_Insert_Input = {
  data: CsvUpload_Insert_Input;
  on_conflict?: Maybe<CsvUpload_On_Conflict>;
};

/** on conflict condition type for table "csvUpload" */
export type CsvUpload_On_Conflict = {
  constraint: CsvUpload_Constraint;
  update_columns: Array<CsvUpload_Update_Column>;
  where?: Maybe<CsvUpload_Bool_Exp>;
};

/** ordering options when selecting data from "csvUpload" */
export type CsvUpload_Order_By = {
  clientId?: Maybe<Order_By>;
  contactCount?: Maybe<Order_By>;
  fileName?: Maybe<Order_By>;
  filePath?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  mapping?: Maybe<Order_By>;
  syncCompletedAt?: Maybe<Order_By>;
  uploadedAt?: Maybe<Order_By>;
  uploadedByUser?: Maybe<User_Order_By>;
  uploadedByUserId?: Maybe<Order_By>;
};

/** primary key columns input for table: "csvUpload" */
export type CsvUpload_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type CsvUpload_Prepend_Input = {
  mapping?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "csvUpload" */
export enum CsvUpload_Select_Column {
  /** column name */
  ClientId = 'clientId',
  /** column name */
  ContactCount = 'contactCount',
  /** column name */
  FileName = 'fileName',
  /** column name */
  FilePath = 'filePath',
  /** column name */
  Id = 'id',
  /** column name */
  Mapping = 'mapping',
  /** column name */
  SyncCompletedAt = 'syncCompletedAt',
  /** column name */
  UploadedAt = 'uploadedAt',
  /** column name */
  UploadedByUserId = 'uploadedByUserId',
}

/** input type for updating data in table "csvUpload" */
export type CsvUpload_Set_Input = {
  clientId?: Maybe<Scalars['uuid']>;
  contactCount?: Maybe<Scalars['Int']>;
  fileName?: Maybe<Scalars['String']>;
  filePath?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  mapping?: Maybe<Scalars['jsonb']>;
  syncCompletedAt?: Maybe<Scalars['timestamptz']>;
  uploadedAt?: Maybe<Scalars['timestamptz']>;
  uploadedByUserId?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type CsvUpload_Stddev_Fields = {
  __typename?: 'csvUpload_stddev_fields';
  contactCount?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "csvUpload" */
export type CsvUpload_Stddev_Order_By = {
  contactCount?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type CsvUpload_Stddev_Pop_Fields = {
  __typename?: 'csvUpload_stddev_pop_fields';
  contactCount?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "csvUpload" */
export type CsvUpload_Stddev_Pop_Order_By = {
  contactCount?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type CsvUpload_Stddev_Samp_Fields = {
  __typename?: 'csvUpload_stddev_samp_fields';
  contactCount?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "csvUpload" */
export type CsvUpload_Stddev_Samp_Order_By = {
  contactCount?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type CsvUpload_Sum_Fields = {
  __typename?: 'csvUpload_sum_fields';
  contactCount?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "csvUpload" */
export type CsvUpload_Sum_Order_By = {
  contactCount?: Maybe<Order_By>;
};

/** update columns of table "csvUpload" */
export enum CsvUpload_Update_Column {
  /** column name */
  ClientId = 'clientId',
  /** column name */
  ContactCount = 'contactCount',
  /** column name */
  FileName = 'fileName',
  /** column name */
  FilePath = 'filePath',
  /** column name */
  Id = 'id',
  /** column name */
  Mapping = 'mapping',
  /** column name */
  SyncCompletedAt = 'syncCompletedAt',
  /** column name */
  UploadedAt = 'uploadedAt',
  /** column name */
  UploadedByUserId = 'uploadedByUserId',
}

/** aggregate var_pop on columns */
export type CsvUpload_Var_Pop_Fields = {
  __typename?: 'csvUpload_var_pop_fields';
  contactCount?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "csvUpload" */
export type CsvUpload_Var_Pop_Order_By = {
  contactCount?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type CsvUpload_Var_Samp_Fields = {
  __typename?: 'csvUpload_var_samp_fields';
  contactCount?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "csvUpload" */
export type CsvUpload_Var_Samp_Order_By = {
  contactCount?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type CsvUpload_Variance_Fields = {
  __typename?: 'csvUpload_variance_fields';
  contactCount?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "csvUpload" */
export type CsvUpload_Variance_Order_By = {
  contactCount?: Maybe<Order_By>;
};

/** expression to compare columns of type date. All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: Maybe<Scalars['date']>;
  _gt?: Maybe<Scalars['date']>;
  _gte?: Maybe<Scalars['date']>;
  _in?: Maybe<Array<Scalars['date']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['date']>;
  _lte?: Maybe<Scalars['date']>;
  _neq?: Maybe<Scalars['date']>;
  _nin?: Maybe<Array<Scalars['date']>>;
};

/** columns and relationships of "emailHistory" */
export type EmailHistory = {
  __typename?: 'emailHistory';
  category: Scalars['String'];
  clientId?: Maybe<Scalars['uuid']>;
  createdAt: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailService: Scalars['String'];
  emailServiceMessageId?: Maybe<Scalars['String']>;
  firestoreId?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  status?: Maybe<Scalars['String']>;
  statusUpdatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "emailHistory" */
export type EmailHistory_Aggregate = {
  __typename?: 'emailHistory_aggregate';
  aggregate?: Maybe<EmailHistory_Aggregate_Fields>;
  nodes: Array<EmailHistory>;
};

/** aggregate fields of "emailHistory" */
export type EmailHistory_Aggregate_Fields = {
  __typename?: 'emailHistory_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<EmailHistory_Max_Fields>;
  min?: Maybe<EmailHistory_Min_Fields>;
};

/** aggregate fields of "emailHistory" */
export type EmailHistory_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<EmailHistory_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "emailHistory" */
export type EmailHistory_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<EmailHistory_Max_Order_By>;
  min?: Maybe<EmailHistory_Min_Order_By>;
};

/** input type for inserting array relation for remote table "emailHistory" */
export type EmailHistory_Arr_Rel_Insert_Input = {
  data: Array<EmailHistory_Insert_Input>;
  on_conflict?: Maybe<EmailHistory_On_Conflict>;
};

/** Boolean expression to filter rows from the table "emailHistory". All fields are combined with a logical 'AND'. */
export type EmailHistory_Bool_Exp = {
  _and?: Maybe<Array<Maybe<EmailHistory_Bool_Exp>>>;
  _not?: Maybe<EmailHistory_Bool_Exp>;
  _or?: Maybe<Array<Maybe<EmailHistory_Bool_Exp>>>;
  category?: Maybe<String_Comparison_Exp>;
  clientId?: Maybe<Uuid_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  emailService?: Maybe<String_Comparison_Exp>;
  emailServiceMessageId?: Maybe<String_Comparison_Exp>;
  firestoreId?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  statusUpdatedAt?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "emailHistory" */
export enum EmailHistory_Constraint {
  /** unique or primary key constraint */
  EmailHistoryFirestoreIdKey = 'emailHistory_firestoreId_key',
  /** unique or primary key constraint */
  EmailHistoryPkey = 'emailHistory_pkey',
}

/** input type for inserting data into table "emailHistory" */
export type EmailHistory_Insert_Input = {
  category?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailService?: Maybe<Scalars['String']>;
  emailServiceMessageId?: Maybe<Scalars['String']>;
  firestoreId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  statusUpdatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type EmailHistory_Max_Fields = {
  __typename?: 'emailHistory_max_fields';
  category?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailService?: Maybe<Scalars['String']>;
  emailServiceMessageId?: Maybe<Scalars['String']>;
  firestoreId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  statusUpdatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "emailHistory" */
export type EmailHistory_Max_Order_By = {
  category?: Maybe<Order_By>;
  clientId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  emailService?: Maybe<Order_By>;
  emailServiceMessageId?: Maybe<Order_By>;
  firestoreId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  statusUpdatedAt?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type EmailHistory_Min_Fields = {
  __typename?: 'emailHistory_min_fields';
  category?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailService?: Maybe<Scalars['String']>;
  emailServiceMessageId?: Maybe<Scalars['String']>;
  firestoreId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  statusUpdatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "emailHistory" */
export type EmailHistory_Min_Order_By = {
  category?: Maybe<Order_By>;
  clientId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  emailService?: Maybe<Order_By>;
  emailServiceMessageId?: Maybe<Order_By>;
  firestoreId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  statusUpdatedAt?: Maybe<Order_By>;
};

/** response of any mutation on the table "emailHistory" */
export type EmailHistory_Mutation_Response = {
  __typename?: 'emailHistory_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<EmailHistory>;
};

/** input type for inserting object relation for remote table "emailHistory" */
export type EmailHistory_Obj_Rel_Insert_Input = {
  data: EmailHistory_Insert_Input;
  on_conflict?: Maybe<EmailHistory_On_Conflict>;
};

/** on conflict condition type for table "emailHistory" */
export type EmailHistory_On_Conflict = {
  constraint: EmailHistory_Constraint;
  update_columns: Array<EmailHistory_Update_Column>;
  where?: Maybe<EmailHistory_Bool_Exp>;
};

/** ordering options when selecting data from "emailHistory" */
export type EmailHistory_Order_By = {
  category?: Maybe<Order_By>;
  clientId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  emailService?: Maybe<Order_By>;
  emailServiceMessageId?: Maybe<Order_By>;
  firestoreId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  statusUpdatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: "emailHistory" */
export type EmailHistory_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "emailHistory" */
export enum EmailHistory_Select_Column {
  /** column name */
  Category = 'category',
  /** column name */
  ClientId = 'clientId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Email = 'email',
  /** column name */
  EmailService = 'emailService',
  /** column name */
  EmailServiceMessageId = 'emailServiceMessageId',
  /** column name */
  FirestoreId = 'firestoreId',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  StatusUpdatedAt = 'statusUpdatedAt',
}

/** input type for updating data in table "emailHistory" */
export type EmailHistory_Set_Input = {
  category?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailService?: Maybe<Scalars['String']>;
  emailServiceMessageId?: Maybe<Scalars['String']>;
  firestoreId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  statusUpdatedAt?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "emailHistory" */
export enum EmailHistory_Update_Column {
  /** column name */
  Category = 'category',
  /** column name */
  ClientId = 'clientId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Email = 'email',
  /** column name */
  EmailService = 'emailService',
  /** column name */
  EmailServiceMessageId = 'emailServiceMessageId',
  /** column name */
  FirestoreId = 'firestoreId',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  StatusUpdatedAt = 'statusUpdatedAt',
}

/** columns and relationships of "googleChannel" */
export type GoogleChannel = {
  __typename?: 'googleChannel';
  channelId: Scalars['String'];
  expiration?: Maybe<Scalars['timestamptz']>;
  id: Scalars['Int'];
  resourceId: Scalars['String'];
  resourceUri?: Maybe<Scalars['String']>;
  stoppedAt?: Maybe<Scalars['timestamptz']>;
  zoomUserId: Scalars['uuid'];
};

/** aggregated selection of "googleChannel" */
export type GoogleChannel_Aggregate = {
  __typename?: 'googleChannel_aggregate';
  aggregate?: Maybe<GoogleChannel_Aggregate_Fields>;
  nodes: Array<GoogleChannel>;
};

/** aggregate fields of "googleChannel" */
export type GoogleChannel_Aggregate_Fields = {
  __typename?: 'googleChannel_aggregate_fields';
  avg?: Maybe<GoogleChannel_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<GoogleChannel_Max_Fields>;
  min?: Maybe<GoogleChannel_Min_Fields>;
  stddev?: Maybe<GoogleChannel_Stddev_Fields>;
  stddev_pop?: Maybe<GoogleChannel_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<GoogleChannel_Stddev_Samp_Fields>;
  sum?: Maybe<GoogleChannel_Sum_Fields>;
  var_pop?: Maybe<GoogleChannel_Var_Pop_Fields>;
  var_samp?: Maybe<GoogleChannel_Var_Samp_Fields>;
  variance?: Maybe<GoogleChannel_Variance_Fields>;
};

/** aggregate fields of "googleChannel" */
export type GoogleChannel_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<GoogleChannel_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "googleChannel" */
export type GoogleChannel_Aggregate_Order_By = {
  avg?: Maybe<GoogleChannel_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<GoogleChannel_Max_Order_By>;
  min?: Maybe<GoogleChannel_Min_Order_By>;
  stddev?: Maybe<GoogleChannel_Stddev_Order_By>;
  stddev_pop?: Maybe<GoogleChannel_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<GoogleChannel_Stddev_Samp_Order_By>;
  sum?: Maybe<GoogleChannel_Sum_Order_By>;
  var_pop?: Maybe<GoogleChannel_Var_Pop_Order_By>;
  var_samp?: Maybe<GoogleChannel_Var_Samp_Order_By>;
  variance?: Maybe<GoogleChannel_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "googleChannel" */
export type GoogleChannel_Arr_Rel_Insert_Input = {
  data: Array<GoogleChannel_Insert_Input>;
  on_conflict?: Maybe<GoogleChannel_On_Conflict>;
};

/** aggregate avg on columns */
export type GoogleChannel_Avg_Fields = {
  __typename?: 'googleChannel_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "googleChannel" */
export type GoogleChannel_Avg_Order_By = {
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "googleChannel". All fields are combined with a logical 'AND'. */
export type GoogleChannel_Bool_Exp = {
  _and?: Maybe<Array<Maybe<GoogleChannel_Bool_Exp>>>;
  _not?: Maybe<GoogleChannel_Bool_Exp>;
  _or?: Maybe<Array<Maybe<GoogleChannel_Bool_Exp>>>;
  channelId?: Maybe<String_Comparison_Exp>;
  expiration?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  resourceId?: Maybe<String_Comparison_Exp>;
  resourceUri?: Maybe<String_Comparison_Exp>;
  stoppedAt?: Maybe<Timestamptz_Comparison_Exp>;
  zoomUserId?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "googleChannel" */
export enum GoogleChannel_Constraint {
  /** unique or primary key constraint */
  GoogleChannelChannelIdKey = 'googleChannel_channelId_key',
  /** unique or primary key constraint */
  GoogleChannelPkey = 'googleChannel_pkey',
}

/** input type for incrementing integer column in table "googleChannel" */
export type GoogleChannel_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "googleChannel" */
export type GoogleChannel_Insert_Input = {
  channelId?: Maybe<Scalars['String']>;
  expiration?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  resourceId?: Maybe<Scalars['String']>;
  resourceUri?: Maybe<Scalars['String']>;
  stoppedAt?: Maybe<Scalars['timestamptz']>;
  zoomUserId?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type GoogleChannel_Max_Fields = {
  __typename?: 'googleChannel_max_fields';
  channelId?: Maybe<Scalars['String']>;
  expiration?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  resourceId?: Maybe<Scalars['String']>;
  resourceUri?: Maybe<Scalars['String']>;
  stoppedAt?: Maybe<Scalars['timestamptz']>;
  zoomUserId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "googleChannel" */
export type GoogleChannel_Max_Order_By = {
  channelId?: Maybe<Order_By>;
  expiration?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  resourceId?: Maybe<Order_By>;
  resourceUri?: Maybe<Order_By>;
  stoppedAt?: Maybe<Order_By>;
  zoomUserId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type GoogleChannel_Min_Fields = {
  __typename?: 'googleChannel_min_fields';
  channelId?: Maybe<Scalars['String']>;
  expiration?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  resourceId?: Maybe<Scalars['String']>;
  resourceUri?: Maybe<Scalars['String']>;
  stoppedAt?: Maybe<Scalars['timestamptz']>;
  zoomUserId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "googleChannel" */
export type GoogleChannel_Min_Order_By = {
  channelId?: Maybe<Order_By>;
  expiration?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  resourceId?: Maybe<Order_By>;
  resourceUri?: Maybe<Order_By>;
  stoppedAt?: Maybe<Order_By>;
  zoomUserId?: Maybe<Order_By>;
};

/** response of any mutation on the table "googleChannel" */
export type GoogleChannel_Mutation_Response = {
  __typename?: 'googleChannel_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<GoogleChannel>;
};

/** input type for inserting object relation for remote table "googleChannel" */
export type GoogleChannel_Obj_Rel_Insert_Input = {
  data: GoogleChannel_Insert_Input;
  on_conflict?: Maybe<GoogleChannel_On_Conflict>;
};

/** on conflict condition type for table "googleChannel" */
export type GoogleChannel_On_Conflict = {
  constraint: GoogleChannel_Constraint;
  update_columns: Array<GoogleChannel_Update_Column>;
  where?: Maybe<GoogleChannel_Bool_Exp>;
};

/** ordering options when selecting data from "googleChannel" */
export type GoogleChannel_Order_By = {
  channelId?: Maybe<Order_By>;
  expiration?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  resourceId?: Maybe<Order_By>;
  resourceUri?: Maybe<Order_By>;
  stoppedAt?: Maybe<Order_By>;
  zoomUserId?: Maybe<Order_By>;
};

/** primary key columns input for table: "googleChannel" */
export type GoogleChannel_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "googleChannel" */
export enum GoogleChannel_Select_Column {
  /** column name */
  ChannelId = 'channelId',
  /** column name */
  Expiration = 'expiration',
  /** column name */
  Id = 'id',
  /** column name */
  ResourceId = 'resourceId',
  /** column name */
  ResourceUri = 'resourceUri',
  /** column name */
  StoppedAt = 'stoppedAt',
  /** column name */
  ZoomUserId = 'zoomUserId',
}

/** input type for updating data in table "googleChannel" */
export type GoogleChannel_Set_Input = {
  channelId?: Maybe<Scalars['String']>;
  expiration?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  resourceId?: Maybe<Scalars['String']>;
  resourceUri?: Maybe<Scalars['String']>;
  stoppedAt?: Maybe<Scalars['timestamptz']>;
  zoomUserId?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type GoogleChannel_Stddev_Fields = {
  __typename?: 'googleChannel_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "googleChannel" */
export type GoogleChannel_Stddev_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type GoogleChannel_Stddev_Pop_Fields = {
  __typename?: 'googleChannel_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "googleChannel" */
export type GoogleChannel_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type GoogleChannel_Stddev_Samp_Fields = {
  __typename?: 'googleChannel_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "googleChannel" */
export type GoogleChannel_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type GoogleChannel_Sum_Fields = {
  __typename?: 'googleChannel_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "googleChannel" */
export type GoogleChannel_Sum_Order_By = {
  id?: Maybe<Order_By>;
};

/** update columns of table "googleChannel" */
export enum GoogleChannel_Update_Column {
  /** column name */
  ChannelId = 'channelId',
  /** column name */
  Expiration = 'expiration',
  /** column name */
  Id = 'id',
  /** column name */
  ResourceId = 'resourceId',
  /** column name */
  ResourceUri = 'resourceUri',
  /** column name */
  StoppedAt = 'stoppedAt',
  /** column name */
  ZoomUserId = 'zoomUserId',
}

/** aggregate var_pop on columns */
export type GoogleChannel_Var_Pop_Fields = {
  __typename?: 'googleChannel_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "googleChannel" */
export type GoogleChannel_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type GoogleChannel_Var_Samp_Fields = {
  __typename?: 'googleChannel_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "googleChannel" */
export type GoogleChannel_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type GoogleChannel_Variance_Fields = {
  __typename?: 'googleChannel_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "googleChannel" */
export type GoogleChannel_Variance_Order_By = {
  id?: Maybe<Order_By>;
};

/** columns and relationships of "hubspotContactList" */
export type HubspotContactList = {
  __typename?: 'hubspotContactList';
  clientId: Scalars['uuid'];
  contactCount?: Maybe<Scalars['Int']>;
  hubspotIntegrationId: Scalars['uuid'];
  id: Scalars['uuid'];
  internalListId?: Maybe<Scalars['String']>;
  json?: Maybe<Scalars['jsonb']>;
  lastSyncedAt?: Maybe<Scalars['timestamptz']>;
  listId: Scalars['String'];
  name: Scalars['String'];
};

/** columns and relationships of "hubspotContactList" */
export type HubspotContactListJsonArgs = {
  path?: Maybe<Scalars['String']>;
};

/** columns and relationships of "hubspotContactListHubspotContact" */
export type HubspotContactListHubspotContact = {
  __typename?: 'hubspotContactListHubspotContact';
  clientId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  hubspotContactListId: Scalars['uuid'];
  hubspotId: Scalars['String'];
  id: Scalars['uuid'];
  internalListId?: Maybe<Scalars['String']>;
};

/** aggregated selection of "hubspotContactListHubspotContact" */
export type HubspotContactListHubspotContact_Aggregate = {
  __typename?: 'hubspotContactListHubspotContact_aggregate';
  aggregate?: Maybe<HubspotContactListHubspotContact_Aggregate_Fields>;
  nodes: Array<HubspotContactListHubspotContact>;
};

/** aggregate fields of "hubspotContactListHubspotContact" */
export type HubspotContactListHubspotContact_Aggregate_Fields = {
  __typename?: 'hubspotContactListHubspotContact_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<HubspotContactListHubspotContact_Max_Fields>;
  min?: Maybe<HubspotContactListHubspotContact_Min_Fields>;
};

/** aggregate fields of "hubspotContactListHubspotContact" */
export type HubspotContactListHubspotContact_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<HubspotContactListHubspotContact_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "hubspotContactListHubspotContact" */
export type HubspotContactListHubspotContact_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<HubspotContactListHubspotContact_Max_Order_By>;
  min?: Maybe<HubspotContactListHubspotContact_Min_Order_By>;
};

/** input type for inserting array relation for remote table "hubspotContactListHubspotContact" */
export type HubspotContactListHubspotContact_Arr_Rel_Insert_Input = {
  data: Array<HubspotContactListHubspotContact_Insert_Input>;
  on_conflict?: Maybe<HubspotContactListHubspotContact_On_Conflict>;
};

/** Boolean expression to filter rows from the table "hubspotContactListHubspotContact". All fields are combined with a logical 'AND'. */
export type HubspotContactListHubspotContact_Bool_Exp = {
  _and?: Maybe<Array<Maybe<HubspotContactListHubspotContact_Bool_Exp>>>;
  _not?: Maybe<HubspotContactListHubspotContact_Bool_Exp>;
  _or?: Maybe<Array<Maybe<HubspotContactListHubspotContact_Bool_Exp>>>;
  clientId?: Maybe<Uuid_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  hubspotContactListId?: Maybe<Uuid_Comparison_Exp>;
  hubspotId?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  internalListId?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "hubspotContactListHubspotContact" */
export enum HubspotContactListHubspotContact_Constraint {
  /** unique or primary key constraint */
  HubspotContactListHubspotContactClientIdHubspotContactListId = 'hubspotContactListHubspotContact_clientId_hubspotContactListId_',
  /** unique or primary key constraint */
  HubspotContactListHubspotContactPkey = 'hubspotContactListHubspotContact_pkey',
}

/** input type for inserting data into table "hubspotContactListHubspotContact" */
export type HubspotContactListHubspotContact_Insert_Input = {
  clientId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  hubspotContactListId?: Maybe<Scalars['uuid']>;
  hubspotId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  internalListId?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type HubspotContactListHubspotContact_Max_Fields = {
  __typename?: 'hubspotContactListHubspotContact_max_fields';
  clientId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  hubspotContactListId?: Maybe<Scalars['uuid']>;
  hubspotId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  internalListId?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "hubspotContactListHubspotContact" */
export type HubspotContactListHubspotContact_Max_Order_By = {
  clientId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  hubspotContactListId?: Maybe<Order_By>;
  hubspotId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  internalListId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type HubspotContactListHubspotContact_Min_Fields = {
  __typename?: 'hubspotContactListHubspotContact_min_fields';
  clientId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  hubspotContactListId?: Maybe<Scalars['uuid']>;
  hubspotId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  internalListId?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "hubspotContactListHubspotContact" */
export type HubspotContactListHubspotContact_Min_Order_By = {
  clientId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  hubspotContactListId?: Maybe<Order_By>;
  hubspotId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  internalListId?: Maybe<Order_By>;
};

/** response of any mutation on the table "hubspotContactListHubspotContact" */
export type HubspotContactListHubspotContact_Mutation_Response = {
  __typename?: 'hubspotContactListHubspotContact_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<HubspotContactListHubspotContact>;
};

/** input type for inserting object relation for remote table "hubspotContactListHubspotContact" */
export type HubspotContactListHubspotContact_Obj_Rel_Insert_Input = {
  data: HubspotContactListHubspotContact_Insert_Input;
  on_conflict?: Maybe<HubspotContactListHubspotContact_On_Conflict>;
};

/** on conflict condition type for table "hubspotContactListHubspotContact" */
export type HubspotContactListHubspotContact_On_Conflict = {
  constraint: HubspotContactListHubspotContact_Constraint;
  update_columns: Array<HubspotContactListHubspotContact_Update_Column>;
  where?: Maybe<HubspotContactListHubspotContact_Bool_Exp>;
};

/** ordering options when selecting data from "hubspotContactListHubspotContact" */
export type HubspotContactListHubspotContact_Order_By = {
  clientId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  hubspotContactListId?: Maybe<Order_By>;
  hubspotId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  internalListId?: Maybe<Order_By>;
};

/** primary key columns input for table: "hubspotContactListHubspotContact" */
export type HubspotContactListHubspotContact_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "hubspotContactListHubspotContact" */
export enum HubspotContactListHubspotContact_Select_Column {
  /** column name */
  ClientId = 'clientId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  HubspotContactListId = 'hubspotContactListId',
  /** column name */
  HubspotId = 'hubspotId',
  /** column name */
  Id = 'id',
  /** column name */
  InternalListId = 'internalListId',
}

/** input type for updating data in table "hubspotContactListHubspotContact" */
export type HubspotContactListHubspotContact_Set_Input = {
  clientId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  hubspotContactListId?: Maybe<Scalars['uuid']>;
  hubspotId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  internalListId?: Maybe<Scalars['String']>;
};

/** update columns of table "hubspotContactListHubspotContact" */
export enum HubspotContactListHubspotContact_Update_Column {
  /** column name */
  ClientId = 'clientId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  HubspotContactListId = 'hubspotContactListId',
  /** column name */
  HubspotId = 'hubspotId',
  /** column name */
  Id = 'id',
  /** column name */
  InternalListId = 'internalListId',
}

/** aggregated selection of "hubspotContactList" */
export type HubspotContactList_Aggregate = {
  __typename?: 'hubspotContactList_aggregate';
  aggregate?: Maybe<HubspotContactList_Aggregate_Fields>;
  nodes: Array<HubspotContactList>;
};

/** aggregate fields of "hubspotContactList" */
export type HubspotContactList_Aggregate_Fields = {
  __typename?: 'hubspotContactList_aggregate_fields';
  avg?: Maybe<HubspotContactList_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<HubspotContactList_Max_Fields>;
  min?: Maybe<HubspotContactList_Min_Fields>;
  stddev?: Maybe<HubspotContactList_Stddev_Fields>;
  stddev_pop?: Maybe<HubspotContactList_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<HubspotContactList_Stddev_Samp_Fields>;
  sum?: Maybe<HubspotContactList_Sum_Fields>;
  var_pop?: Maybe<HubspotContactList_Var_Pop_Fields>;
  var_samp?: Maybe<HubspotContactList_Var_Samp_Fields>;
  variance?: Maybe<HubspotContactList_Variance_Fields>;
};

/** aggregate fields of "hubspotContactList" */
export type HubspotContactList_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<HubspotContactList_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "hubspotContactList" */
export type HubspotContactList_Aggregate_Order_By = {
  avg?: Maybe<HubspotContactList_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<HubspotContactList_Max_Order_By>;
  min?: Maybe<HubspotContactList_Min_Order_By>;
  stddev?: Maybe<HubspotContactList_Stddev_Order_By>;
  stddev_pop?: Maybe<HubspotContactList_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<HubspotContactList_Stddev_Samp_Order_By>;
  sum?: Maybe<HubspotContactList_Sum_Order_By>;
  var_pop?: Maybe<HubspotContactList_Var_Pop_Order_By>;
  var_samp?: Maybe<HubspotContactList_Var_Samp_Order_By>;
  variance?: Maybe<HubspotContactList_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type HubspotContactList_Append_Input = {
  json?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "hubspotContactList" */
export type HubspotContactList_Arr_Rel_Insert_Input = {
  data: Array<HubspotContactList_Insert_Input>;
  on_conflict?: Maybe<HubspotContactList_On_Conflict>;
};

/** aggregate avg on columns */
export type HubspotContactList_Avg_Fields = {
  __typename?: 'hubspotContactList_avg_fields';
  contactCount?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "hubspotContactList" */
export type HubspotContactList_Avg_Order_By = {
  contactCount?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "hubspotContactList". All fields are combined with a logical 'AND'. */
export type HubspotContactList_Bool_Exp = {
  _and?: Maybe<Array<Maybe<HubspotContactList_Bool_Exp>>>;
  _not?: Maybe<HubspotContactList_Bool_Exp>;
  _or?: Maybe<Array<Maybe<HubspotContactList_Bool_Exp>>>;
  clientId?: Maybe<Uuid_Comparison_Exp>;
  contactCount?: Maybe<Int_Comparison_Exp>;
  hubspotIntegrationId?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  internalListId?: Maybe<String_Comparison_Exp>;
  json?: Maybe<Jsonb_Comparison_Exp>;
  lastSyncedAt?: Maybe<Timestamptz_Comparison_Exp>;
  listId?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "hubspotContactList" */
export enum HubspotContactList_Constraint {
  /** unique or primary key constraint */
  HubspotContactListHubspotIntegrationIdListIdKey = 'hubspotContactList_hubspotIntegrationId_listId_key',
  /** unique or primary key constraint */
  HubspotContactListPkey = 'hubspotContactList_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type HubspotContactList_Delete_At_Path_Input = {
  json?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type HubspotContactList_Delete_Elem_Input = {
  json?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type HubspotContactList_Delete_Key_Input = {
  json?: Maybe<Scalars['String']>;
};

/** input type for incrementing integer column in table "hubspotContactList" */
export type HubspotContactList_Inc_Input = {
  contactCount?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "hubspotContactList" */
export type HubspotContactList_Insert_Input = {
  clientId?: Maybe<Scalars['uuid']>;
  contactCount?: Maybe<Scalars['Int']>;
  hubspotIntegrationId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  internalListId?: Maybe<Scalars['String']>;
  json?: Maybe<Scalars['jsonb']>;
  lastSyncedAt?: Maybe<Scalars['timestamptz']>;
  listId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type HubspotContactList_Max_Fields = {
  __typename?: 'hubspotContactList_max_fields';
  clientId?: Maybe<Scalars['uuid']>;
  contactCount?: Maybe<Scalars['Int']>;
  hubspotIntegrationId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  internalListId?: Maybe<Scalars['String']>;
  lastSyncedAt?: Maybe<Scalars['timestamptz']>;
  listId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "hubspotContactList" */
export type HubspotContactList_Max_Order_By = {
  clientId?: Maybe<Order_By>;
  contactCount?: Maybe<Order_By>;
  hubspotIntegrationId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  internalListId?: Maybe<Order_By>;
  lastSyncedAt?: Maybe<Order_By>;
  listId?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type HubspotContactList_Min_Fields = {
  __typename?: 'hubspotContactList_min_fields';
  clientId?: Maybe<Scalars['uuid']>;
  contactCount?: Maybe<Scalars['Int']>;
  hubspotIntegrationId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  internalListId?: Maybe<Scalars['String']>;
  lastSyncedAt?: Maybe<Scalars['timestamptz']>;
  listId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "hubspotContactList" */
export type HubspotContactList_Min_Order_By = {
  clientId?: Maybe<Order_By>;
  contactCount?: Maybe<Order_By>;
  hubspotIntegrationId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  internalListId?: Maybe<Order_By>;
  lastSyncedAt?: Maybe<Order_By>;
  listId?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** response of any mutation on the table "hubspotContactList" */
export type HubspotContactList_Mutation_Response = {
  __typename?: 'hubspotContactList_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<HubspotContactList>;
};

/** input type for inserting object relation for remote table "hubspotContactList" */
export type HubspotContactList_Obj_Rel_Insert_Input = {
  data: HubspotContactList_Insert_Input;
  on_conflict?: Maybe<HubspotContactList_On_Conflict>;
};

/** on conflict condition type for table "hubspotContactList" */
export type HubspotContactList_On_Conflict = {
  constraint: HubspotContactList_Constraint;
  update_columns: Array<HubspotContactList_Update_Column>;
  where?: Maybe<HubspotContactList_Bool_Exp>;
};

/** ordering options when selecting data from "hubspotContactList" */
export type HubspotContactList_Order_By = {
  clientId?: Maybe<Order_By>;
  contactCount?: Maybe<Order_By>;
  hubspotIntegrationId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  internalListId?: Maybe<Order_By>;
  json?: Maybe<Order_By>;
  lastSyncedAt?: Maybe<Order_By>;
  listId?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** primary key columns input for table: "hubspotContactList" */
export type HubspotContactList_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type HubspotContactList_Prepend_Input = {
  json?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "hubspotContactList" */
export enum HubspotContactList_Select_Column {
  /** column name */
  ClientId = 'clientId',
  /** column name */
  ContactCount = 'contactCount',
  /** column name */
  HubspotIntegrationId = 'hubspotIntegrationId',
  /** column name */
  Id = 'id',
  /** column name */
  InternalListId = 'internalListId',
  /** column name */
  Json = 'json',
  /** column name */
  LastSyncedAt = 'lastSyncedAt',
  /** column name */
  ListId = 'listId',
  /** column name */
  Name = 'name',
}

/** input type for updating data in table "hubspotContactList" */
export type HubspotContactList_Set_Input = {
  clientId?: Maybe<Scalars['uuid']>;
  contactCount?: Maybe<Scalars['Int']>;
  hubspotIntegrationId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  internalListId?: Maybe<Scalars['String']>;
  json?: Maybe<Scalars['jsonb']>;
  lastSyncedAt?: Maybe<Scalars['timestamptz']>;
  listId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type HubspotContactList_Stddev_Fields = {
  __typename?: 'hubspotContactList_stddev_fields';
  contactCount?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "hubspotContactList" */
export type HubspotContactList_Stddev_Order_By = {
  contactCount?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type HubspotContactList_Stddev_Pop_Fields = {
  __typename?: 'hubspotContactList_stddev_pop_fields';
  contactCount?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "hubspotContactList" */
export type HubspotContactList_Stddev_Pop_Order_By = {
  contactCount?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type HubspotContactList_Stddev_Samp_Fields = {
  __typename?: 'hubspotContactList_stddev_samp_fields';
  contactCount?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "hubspotContactList" */
export type HubspotContactList_Stddev_Samp_Order_By = {
  contactCount?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type HubspotContactList_Sum_Fields = {
  __typename?: 'hubspotContactList_sum_fields';
  contactCount?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "hubspotContactList" */
export type HubspotContactList_Sum_Order_By = {
  contactCount?: Maybe<Order_By>;
};

/** update columns of table "hubspotContactList" */
export enum HubspotContactList_Update_Column {
  /** column name */
  ClientId = 'clientId',
  /** column name */
  ContactCount = 'contactCount',
  /** column name */
  HubspotIntegrationId = 'hubspotIntegrationId',
  /** column name */
  Id = 'id',
  /** column name */
  InternalListId = 'internalListId',
  /** column name */
  Json = 'json',
  /** column name */
  LastSyncedAt = 'lastSyncedAt',
  /** column name */
  ListId = 'listId',
  /** column name */
  Name = 'name',
}

/** aggregate var_pop on columns */
export type HubspotContactList_Var_Pop_Fields = {
  __typename?: 'hubspotContactList_var_pop_fields';
  contactCount?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "hubspotContactList" */
export type HubspotContactList_Var_Pop_Order_By = {
  contactCount?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type HubspotContactList_Var_Samp_Fields = {
  __typename?: 'hubspotContactList_var_samp_fields';
  contactCount?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "hubspotContactList" */
export type HubspotContactList_Var_Samp_Order_By = {
  contactCount?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type HubspotContactList_Variance_Fields = {
  __typename?: 'hubspotContactList_variance_fields';
  contactCount?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "hubspotContactList" */
export type HubspotContactList_Variance_Order_By = {
  contactCount?: Maybe<Order_By>;
};

/** columns and relationships of "hubspotContactProperty" */
export type HubspotContactProperty = {
  __typename?: 'hubspotContactProperty';
  clientId: Scalars['uuid'];
  hubspotIntegrationId: Scalars['uuid'];
  id: Scalars['uuid'];
  json?: Maybe<Scalars['jsonb']>;
  label?: Maybe<Scalars['String']>;
  lastSyncedAt?: Maybe<Scalars['timestamptz']>;
  name: Scalars['String'];
};

/** columns and relationships of "hubspotContactProperty" */
export type HubspotContactPropertyJsonArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "hubspotContactProperty" */
export type HubspotContactProperty_Aggregate = {
  __typename?: 'hubspotContactProperty_aggregate';
  aggregate?: Maybe<HubspotContactProperty_Aggregate_Fields>;
  nodes: Array<HubspotContactProperty>;
};

/** aggregate fields of "hubspotContactProperty" */
export type HubspotContactProperty_Aggregate_Fields = {
  __typename?: 'hubspotContactProperty_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<HubspotContactProperty_Max_Fields>;
  min?: Maybe<HubspotContactProperty_Min_Fields>;
};

/** aggregate fields of "hubspotContactProperty" */
export type HubspotContactProperty_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<HubspotContactProperty_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "hubspotContactProperty" */
export type HubspotContactProperty_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<HubspotContactProperty_Max_Order_By>;
  min?: Maybe<HubspotContactProperty_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type HubspotContactProperty_Append_Input = {
  json?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "hubspotContactProperty" */
export type HubspotContactProperty_Arr_Rel_Insert_Input = {
  data: Array<HubspotContactProperty_Insert_Input>;
  on_conflict?: Maybe<HubspotContactProperty_On_Conflict>;
};

/** Boolean expression to filter rows from the table "hubspotContactProperty". All fields are combined with a logical 'AND'. */
export type HubspotContactProperty_Bool_Exp = {
  _and?: Maybe<Array<Maybe<HubspotContactProperty_Bool_Exp>>>;
  _not?: Maybe<HubspotContactProperty_Bool_Exp>;
  _or?: Maybe<Array<Maybe<HubspotContactProperty_Bool_Exp>>>;
  clientId?: Maybe<Uuid_Comparison_Exp>;
  hubspotIntegrationId?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  json?: Maybe<Jsonb_Comparison_Exp>;
  label?: Maybe<String_Comparison_Exp>;
  lastSyncedAt?: Maybe<Timestamptz_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "hubspotContactProperty" */
export enum HubspotContactProperty_Constraint {
  /** unique or primary key constraint */
  HubspotContactPropertyHubspotIntegrationIdNameKey = 'hubspotContactProperty_hubspotIntegrationId_name_key',
  /** unique or primary key constraint */
  HubspotContactPropertyPkey = 'hubspotContactProperty_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type HubspotContactProperty_Delete_At_Path_Input = {
  json?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type HubspotContactProperty_Delete_Elem_Input = {
  json?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type HubspotContactProperty_Delete_Key_Input = {
  json?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "hubspotContactProperty" */
export type HubspotContactProperty_Insert_Input = {
  clientId?: Maybe<Scalars['uuid']>;
  hubspotIntegrationId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  json?: Maybe<Scalars['jsonb']>;
  label?: Maybe<Scalars['String']>;
  lastSyncedAt?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type HubspotContactProperty_Max_Fields = {
  __typename?: 'hubspotContactProperty_max_fields';
  clientId?: Maybe<Scalars['uuid']>;
  hubspotIntegrationId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  label?: Maybe<Scalars['String']>;
  lastSyncedAt?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "hubspotContactProperty" */
export type HubspotContactProperty_Max_Order_By = {
  clientId?: Maybe<Order_By>;
  hubspotIntegrationId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  label?: Maybe<Order_By>;
  lastSyncedAt?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type HubspotContactProperty_Min_Fields = {
  __typename?: 'hubspotContactProperty_min_fields';
  clientId?: Maybe<Scalars['uuid']>;
  hubspotIntegrationId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  label?: Maybe<Scalars['String']>;
  lastSyncedAt?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "hubspotContactProperty" */
export type HubspotContactProperty_Min_Order_By = {
  clientId?: Maybe<Order_By>;
  hubspotIntegrationId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  label?: Maybe<Order_By>;
  lastSyncedAt?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** response of any mutation on the table "hubspotContactProperty" */
export type HubspotContactProperty_Mutation_Response = {
  __typename?: 'hubspotContactProperty_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<HubspotContactProperty>;
};

/** input type for inserting object relation for remote table "hubspotContactProperty" */
export type HubspotContactProperty_Obj_Rel_Insert_Input = {
  data: HubspotContactProperty_Insert_Input;
  on_conflict?: Maybe<HubspotContactProperty_On_Conflict>;
};

/** on conflict condition type for table "hubspotContactProperty" */
export type HubspotContactProperty_On_Conflict = {
  constraint: HubspotContactProperty_Constraint;
  update_columns: Array<HubspotContactProperty_Update_Column>;
  where?: Maybe<HubspotContactProperty_Bool_Exp>;
};

/** ordering options when selecting data from "hubspotContactProperty" */
export type HubspotContactProperty_Order_By = {
  clientId?: Maybe<Order_By>;
  hubspotIntegrationId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  json?: Maybe<Order_By>;
  label?: Maybe<Order_By>;
  lastSyncedAt?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** primary key columns input for table: "hubspotContactProperty" */
export type HubspotContactProperty_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type HubspotContactProperty_Prepend_Input = {
  json?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "hubspotContactProperty" */
export enum HubspotContactProperty_Select_Column {
  /** column name */
  ClientId = 'clientId',
  /** column name */
  HubspotIntegrationId = 'hubspotIntegrationId',
  /** column name */
  Id = 'id',
  /** column name */
  Json = 'json',
  /** column name */
  Label = 'label',
  /** column name */
  LastSyncedAt = 'lastSyncedAt',
  /** column name */
  Name = 'name',
}

/** input type for updating data in table "hubspotContactProperty" */
export type HubspotContactProperty_Set_Input = {
  clientId?: Maybe<Scalars['uuid']>;
  hubspotIntegrationId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  json?: Maybe<Scalars['jsonb']>;
  label?: Maybe<Scalars['String']>;
  lastSyncedAt?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
};

/** update columns of table "hubspotContactProperty" */
export enum HubspotContactProperty_Update_Column {
  /** column name */
  ClientId = 'clientId',
  /** column name */
  HubspotIntegrationId = 'hubspotIntegrationId',
  /** column name */
  Id = 'id',
  /** column name */
  Json = 'json',
  /** column name */
  Label = 'label',
  /** column name */
  LastSyncedAt = 'lastSyncedAt',
  /** column name */
  Name = 'name',
}

/** columns and relationships of "hubspotIntegration" */
export type HubspotIntegration = {
  __typename?: 'hubspotIntegration';
  accessToken?: Maybe<Scalars['String']>;
  accessTokenExpireAt?: Maybe<Scalars['timestamptz']>;
  clientId: Scalars['uuid'];
  contactsLastSyncedAt?: Maybe<Scalars['timestamptz']>;
  contactsLastSyncedCount?: Maybe<Scalars['Int']>;
  createdAt: Scalars['timestamptz'];
  /** An array relationship */
  hubspotContactLists: Array<HubspotContactList>;
  /** An aggregated array relationship */
  hubspotContactLists_aggregate: HubspotContactList_Aggregate;
  /** An array relationship */
  hubspotContactProperties: Array<HubspotContactProperty>;
  /** An aggregated array relationship */
  hubspotContactProperties_aggregate: HubspotContactProperty_Aggregate;
  hubspotId?: Maybe<Scalars['String']>;
  hubspotUserEmail?: Maybe<Scalars['String']>;
  hubspotUserId?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  mapping?: Maybe<Scalars['jsonb']>;
  refreshToken?: Maybe<Scalars['String']>;
  totalContactCount?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['timestamptz'];
  warmlyContactPropertiesCreatedAt?: Maybe<Scalars['timestamptz']>;
};

/** columns and relationships of "hubspotIntegration" */
export type HubspotIntegrationHubspotContactListsArgs = {
  distinct_on?: Maybe<Array<HubspotContactList_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HubspotContactList_Order_By>>;
  where?: Maybe<HubspotContactList_Bool_Exp>;
};

/** columns and relationships of "hubspotIntegration" */
export type HubspotIntegrationHubspotContactLists_AggregateArgs = {
  distinct_on?: Maybe<Array<HubspotContactList_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HubspotContactList_Order_By>>;
  where?: Maybe<HubspotContactList_Bool_Exp>;
};

/** columns and relationships of "hubspotIntegration" */
export type HubspotIntegrationHubspotContactPropertiesArgs = {
  distinct_on?: Maybe<Array<HubspotContactProperty_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HubspotContactProperty_Order_By>>;
  where?: Maybe<HubspotContactProperty_Bool_Exp>;
};

/** columns and relationships of "hubspotIntegration" */
export type HubspotIntegrationHubspotContactProperties_AggregateArgs = {
  distinct_on?: Maybe<Array<HubspotContactProperty_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HubspotContactProperty_Order_By>>;
  where?: Maybe<HubspotContactProperty_Bool_Exp>;
};

/** columns and relationships of "hubspotIntegration" */
export type HubspotIntegrationMappingArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "hubspotIntegration" */
export type HubspotIntegration_Aggregate = {
  __typename?: 'hubspotIntegration_aggregate';
  aggregate?: Maybe<HubspotIntegration_Aggregate_Fields>;
  nodes: Array<HubspotIntegration>;
};

/** aggregate fields of "hubspotIntegration" */
export type HubspotIntegration_Aggregate_Fields = {
  __typename?: 'hubspotIntegration_aggregate_fields';
  avg?: Maybe<HubspotIntegration_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<HubspotIntegration_Max_Fields>;
  min?: Maybe<HubspotIntegration_Min_Fields>;
  stddev?: Maybe<HubspotIntegration_Stddev_Fields>;
  stddev_pop?: Maybe<HubspotIntegration_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<HubspotIntegration_Stddev_Samp_Fields>;
  sum?: Maybe<HubspotIntegration_Sum_Fields>;
  var_pop?: Maybe<HubspotIntegration_Var_Pop_Fields>;
  var_samp?: Maybe<HubspotIntegration_Var_Samp_Fields>;
  variance?: Maybe<HubspotIntegration_Variance_Fields>;
};

/** aggregate fields of "hubspotIntegration" */
export type HubspotIntegration_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<HubspotIntegration_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "hubspotIntegration" */
export type HubspotIntegration_Aggregate_Order_By = {
  avg?: Maybe<HubspotIntegration_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<HubspotIntegration_Max_Order_By>;
  min?: Maybe<HubspotIntegration_Min_Order_By>;
  stddev?: Maybe<HubspotIntegration_Stddev_Order_By>;
  stddev_pop?: Maybe<HubspotIntegration_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<HubspotIntegration_Stddev_Samp_Order_By>;
  sum?: Maybe<HubspotIntegration_Sum_Order_By>;
  var_pop?: Maybe<HubspotIntegration_Var_Pop_Order_By>;
  var_samp?: Maybe<HubspotIntegration_Var_Samp_Order_By>;
  variance?: Maybe<HubspotIntegration_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type HubspotIntegration_Append_Input = {
  mapping?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "hubspotIntegration" */
export type HubspotIntegration_Arr_Rel_Insert_Input = {
  data: Array<HubspotIntegration_Insert_Input>;
  on_conflict?: Maybe<HubspotIntegration_On_Conflict>;
};

/** aggregate avg on columns */
export type HubspotIntegration_Avg_Fields = {
  __typename?: 'hubspotIntegration_avg_fields';
  contactsLastSyncedCount?: Maybe<Scalars['Float']>;
  totalContactCount?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "hubspotIntegration" */
export type HubspotIntegration_Avg_Order_By = {
  contactsLastSyncedCount?: Maybe<Order_By>;
  totalContactCount?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "hubspotIntegration". All fields are combined with a logical 'AND'. */
export type HubspotIntegration_Bool_Exp = {
  _and?: Maybe<Array<Maybe<HubspotIntegration_Bool_Exp>>>;
  _not?: Maybe<HubspotIntegration_Bool_Exp>;
  _or?: Maybe<Array<Maybe<HubspotIntegration_Bool_Exp>>>;
  accessToken?: Maybe<String_Comparison_Exp>;
  accessTokenExpireAt?: Maybe<Timestamptz_Comparison_Exp>;
  clientId?: Maybe<Uuid_Comparison_Exp>;
  contactsLastSyncedAt?: Maybe<Timestamptz_Comparison_Exp>;
  contactsLastSyncedCount?: Maybe<Int_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  hubspotContactLists?: Maybe<HubspotContactList_Bool_Exp>;
  hubspotContactProperties?: Maybe<HubspotContactProperty_Bool_Exp>;
  hubspotId?: Maybe<String_Comparison_Exp>;
  hubspotUserEmail?: Maybe<String_Comparison_Exp>;
  hubspotUserId?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  mapping?: Maybe<Jsonb_Comparison_Exp>;
  refreshToken?: Maybe<String_Comparison_Exp>;
  totalContactCount?: Maybe<Int_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
  warmlyContactPropertiesCreatedAt?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "hubspotIntegration" */
export enum HubspotIntegration_Constraint {
  /** unique or primary key constraint */
  HubspotIntegrationClientIdKey = 'hubspotIntegration_clientId_key',
  /** unique or primary key constraint */
  HubspotIntegrationPkey = 'hubspotIntegration_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type HubspotIntegration_Delete_At_Path_Input = {
  mapping?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type HubspotIntegration_Delete_Elem_Input = {
  mapping?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type HubspotIntegration_Delete_Key_Input = {
  mapping?: Maybe<Scalars['String']>;
};

/** input type for incrementing integer column in table "hubspotIntegration" */
export type HubspotIntegration_Inc_Input = {
  contactsLastSyncedCount?: Maybe<Scalars['Int']>;
  totalContactCount?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "hubspotIntegration" */
export type HubspotIntegration_Insert_Input = {
  accessToken?: Maybe<Scalars['String']>;
  accessTokenExpireAt?: Maybe<Scalars['timestamptz']>;
  clientId?: Maybe<Scalars['uuid']>;
  contactsLastSyncedAt?: Maybe<Scalars['timestamptz']>;
  contactsLastSyncedCount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  hubspotContactLists?: Maybe<HubspotContactList_Arr_Rel_Insert_Input>;
  hubspotContactProperties?: Maybe<HubspotContactProperty_Arr_Rel_Insert_Input>;
  hubspotId?: Maybe<Scalars['String']>;
  hubspotUserEmail?: Maybe<Scalars['String']>;
  hubspotUserId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  mapping?: Maybe<Scalars['jsonb']>;
  refreshToken?: Maybe<Scalars['String']>;
  totalContactCount?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  warmlyContactPropertiesCreatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type HubspotIntegration_Max_Fields = {
  __typename?: 'hubspotIntegration_max_fields';
  accessToken?: Maybe<Scalars['String']>;
  accessTokenExpireAt?: Maybe<Scalars['timestamptz']>;
  clientId?: Maybe<Scalars['uuid']>;
  contactsLastSyncedAt?: Maybe<Scalars['timestamptz']>;
  contactsLastSyncedCount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  hubspotId?: Maybe<Scalars['String']>;
  hubspotUserEmail?: Maybe<Scalars['String']>;
  hubspotUserId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  refreshToken?: Maybe<Scalars['String']>;
  totalContactCount?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  warmlyContactPropertiesCreatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "hubspotIntegration" */
export type HubspotIntegration_Max_Order_By = {
  accessToken?: Maybe<Order_By>;
  accessTokenExpireAt?: Maybe<Order_By>;
  clientId?: Maybe<Order_By>;
  contactsLastSyncedAt?: Maybe<Order_By>;
  contactsLastSyncedCount?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  hubspotId?: Maybe<Order_By>;
  hubspotUserEmail?: Maybe<Order_By>;
  hubspotUserId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  refreshToken?: Maybe<Order_By>;
  totalContactCount?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  warmlyContactPropertiesCreatedAt?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type HubspotIntegration_Min_Fields = {
  __typename?: 'hubspotIntegration_min_fields';
  accessToken?: Maybe<Scalars['String']>;
  accessTokenExpireAt?: Maybe<Scalars['timestamptz']>;
  clientId?: Maybe<Scalars['uuid']>;
  contactsLastSyncedAt?: Maybe<Scalars['timestamptz']>;
  contactsLastSyncedCount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  hubspotId?: Maybe<Scalars['String']>;
  hubspotUserEmail?: Maybe<Scalars['String']>;
  hubspotUserId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  refreshToken?: Maybe<Scalars['String']>;
  totalContactCount?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  warmlyContactPropertiesCreatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "hubspotIntegration" */
export type HubspotIntegration_Min_Order_By = {
  accessToken?: Maybe<Order_By>;
  accessTokenExpireAt?: Maybe<Order_By>;
  clientId?: Maybe<Order_By>;
  contactsLastSyncedAt?: Maybe<Order_By>;
  contactsLastSyncedCount?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  hubspotId?: Maybe<Order_By>;
  hubspotUserEmail?: Maybe<Order_By>;
  hubspotUserId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  refreshToken?: Maybe<Order_By>;
  totalContactCount?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  warmlyContactPropertiesCreatedAt?: Maybe<Order_By>;
};

/** response of any mutation on the table "hubspotIntegration" */
export type HubspotIntegration_Mutation_Response = {
  __typename?: 'hubspotIntegration_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<HubspotIntegration>;
};

/** input type for inserting object relation for remote table "hubspotIntegration" */
export type HubspotIntegration_Obj_Rel_Insert_Input = {
  data: HubspotIntegration_Insert_Input;
  on_conflict?: Maybe<HubspotIntegration_On_Conflict>;
};

/** on conflict condition type for table "hubspotIntegration" */
export type HubspotIntegration_On_Conflict = {
  constraint: HubspotIntegration_Constraint;
  update_columns: Array<HubspotIntegration_Update_Column>;
  where?: Maybe<HubspotIntegration_Bool_Exp>;
};

/** ordering options when selecting data from "hubspotIntegration" */
export type HubspotIntegration_Order_By = {
  accessToken?: Maybe<Order_By>;
  accessTokenExpireAt?: Maybe<Order_By>;
  clientId?: Maybe<Order_By>;
  contactsLastSyncedAt?: Maybe<Order_By>;
  contactsLastSyncedCount?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  hubspotContactLists_aggregate?: Maybe<HubspotContactList_Aggregate_Order_By>;
  hubspotContactProperties_aggregate?: Maybe<HubspotContactProperty_Aggregate_Order_By>;
  hubspotId?: Maybe<Order_By>;
  hubspotUserEmail?: Maybe<Order_By>;
  hubspotUserId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  mapping?: Maybe<Order_By>;
  refreshToken?: Maybe<Order_By>;
  totalContactCount?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  warmlyContactPropertiesCreatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: "hubspotIntegration" */
export type HubspotIntegration_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type HubspotIntegration_Prepend_Input = {
  mapping?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "hubspotIntegration" */
export enum HubspotIntegration_Select_Column {
  /** column name */
  AccessToken = 'accessToken',
  /** column name */
  AccessTokenExpireAt = 'accessTokenExpireAt',
  /** column name */
  ClientId = 'clientId',
  /** column name */
  ContactsLastSyncedAt = 'contactsLastSyncedAt',
  /** column name */
  ContactsLastSyncedCount = 'contactsLastSyncedCount',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  HubspotId = 'hubspotId',
  /** column name */
  HubspotUserEmail = 'hubspotUserEmail',
  /** column name */
  HubspotUserId = 'hubspotUserId',
  /** column name */
  Id = 'id',
  /** column name */
  Mapping = 'mapping',
  /** column name */
  RefreshToken = 'refreshToken',
  /** column name */
  TotalContactCount = 'totalContactCount',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WarmlyContactPropertiesCreatedAt = 'warmlyContactPropertiesCreatedAt',
}

/** input type for updating data in table "hubspotIntegration" */
export type HubspotIntegration_Set_Input = {
  accessToken?: Maybe<Scalars['String']>;
  accessTokenExpireAt?: Maybe<Scalars['timestamptz']>;
  clientId?: Maybe<Scalars['uuid']>;
  contactsLastSyncedAt?: Maybe<Scalars['timestamptz']>;
  contactsLastSyncedCount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  hubspotId?: Maybe<Scalars['String']>;
  hubspotUserEmail?: Maybe<Scalars['String']>;
  hubspotUserId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  mapping?: Maybe<Scalars['jsonb']>;
  refreshToken?: Maybe<Scalars['String']>;
  totalContactCount?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  warmlyContactPropertiesCreatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type HubspotIntegration_Stddev_Fields = {
  __typename?: 'hubspotIntegration_stddev_fields';
  contactsLastSyncedCount?: Maybe<Scalars['Float']>;
  totalContactCount?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "hubspotIntegration" */
export type HubspotIntegration_Stddev_Order_By = {
  contactsLastSyncedCount?: Maybe<Order_By>;
  totalContactCount?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type HubspotIntegration_Stddev_Pop_Fields = {
  __typename?: 'hubspotIntegration_stddev_pop_fields';
  contactsLastSyncedCount?: Maybe<Scalars['Float']>;
  totalContactCount?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "hubspotIntegration" */
export type HubspotIntegration_Stddev_Pop_Order_By = {
  contactsLastSyncedCount?: Maybe<Order_By>;
  totalContactCount?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type HubspotIntegration_Stddev_Samp_Fields = {
  __typename?: 'hubspotIntegration_stddev_samp_fields';
  contactsLastSyncedCount?: Maybe<Scalars['Float']>;
  totalContactCount?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "hubspotIntegration" */
export type HubspotIntegration_Stddev_Samp_Order_By = {
  contactsLastSyncedCount?: Maybe<Order_By>;
  totalContactCount?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type HubspotIntegration_Sum_Fields = {
  __typename?: 'hubspotIntegration_sum_fields';
  contactsLastSyncedCount?: Maybe<Scalars['Int']>;
  totalContactCount?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "hubspotIntegration" */
export type HubspotIntegration_Sum_Order_By = {
  contactsLastSyncedCount?: Maybe<Order_By>;
  totalContactCount?: Maybe<Order_By>;
};

/** update columns of table "hubspotIntegration" */
export enum HubspotIntegration_Update_Column {
  /** column name */
  AccessToken = 'accessToken',
  /** column name */
  AccessTokenExpireAt = 'accessTokenExpireAt',
  /** column name */
  ClientId = 'clientId',
  /** column name */
  ContactsLastSyncedAt = 'contactsLastSyncedAt',
  /** column name */
  ContactsLastSyncedCount = 'contactsLastSyncedCount',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  HubspotId = 'hubspotId',
  /** column name */
  HubspotUserEmail = 'hubspotUserEmail',
  /** column name */
  HubspotUserId = 'hubspotUserId',
  /** column name */
  Id = 'id',
  /** column name */
  Mapping = 'mapping',
  /** column name */
  RefreshToken = 'refreshToken',
  /** column name */
  TotalContactCount = 'totalContactCount',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WarmlyContactPropertiesCreatedAt = 'warmlyContactPropertiesCreatedAt',
}

/** aggregate var_pop on columns */
export type HubspotIntegration_Var_Pop_Fields = {
  __typename?: 'hubspotIntegration_var_pop_fields';
  contactsLastSyncedCount?: Maybe<Scalars['Float']>;
  totalContactCount?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "hubspotIntegration" */
export type HubspotIntegration_Var_Pop_Order_By = {
  contactsLastSyncedCount?: Maybe<Order_By>;
  totalContactCount?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type HubspotIntegration_Var_Samp_Fields = {
  __typename?: 'hubspotIntegration_var_samp_fields';
  contactsLastSyncedCount?: Maybe<Scalars['Float']>;
  totalContactCount?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "hubspotIntegration" */
export type HubspotIntegration_Var_Samp_Order_By = {
  contactsLastSyncedCount?: Maybe<Order_By>;
  totalContactCount?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type HubspotIntegration_Variance_Fields = {
  __typename?: 'hubspotIntegration_variance_fields';
  contactsLastSyncedCount?: Maybe<Scalars['Float']>;
  totalContactCount?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "hubspotIntegration" */
export type HubspotIntegration_Variance_Order_By = {
  contactsLastSyncedCount?: Maybe<Order_By>;
  totalContactCount?: Maybe<Order_By>;
};

/** columns and relationships of "job" */
export type Job = {
  __typename?: 'job';
  /** An object relationship */
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['uuid']>;
  companyName?: Maybe<Scalars['String']>;
  /** An array relationship */
  contactData: Array<ContactData>;
  contactDataId: Scalars['uuid'];
  /** An aggregated array relationship */
  contactData_aggregate: ContactData_Aggregate;
  createdAt: Scalars['timestamptz'];
  duration?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['date']>;
  endDateText?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  startDate?: Maybe<Scalars['date']>;
  startDateText?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** columns and relationships of "job" */
export type JobContactDataArgs = {
  distinct_on?: Maybe<Array<ContactData_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContactData_Order_By>>;
  where?: Maybe<ContactData_Bool_Exp>;
};

/** columns and relationships of "job" */
export type JobContactData_AggregateArgs = {
  distinct_on?: Maybe<Array<ContactData_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContactData_Order_By>>;
  where?: Maybe<ContactData_Bool_Exp>;
};

/** aggregated selection of "job" */
export type Job_Aggregate = {
  __typename?: 'job_aggregate';
  aggregate?: Maybe<Job_Aggregate_Fields>;
  nodes: Array<Job>;
};

/** aggregate fields of "job" */
export type Job_Aggregate_Fields = {
  __typename?: 'job_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Job_Max_Fields>;
  min?: Maybe<Job_Min_Fields>;
};

/** aggregate fields of "job" */
export type Job_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Job_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "job" */
export type Job_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Job_Max_Order_By>;
  min?: Maybe<Job_Min_Order_By>;
};

/** input type for inserting array relation for remote table "job" */
export type Job_Arr_Rel_Insert_Input = {
  data: Array<Job_Insert_Input>;
  on_conflict?: Maybe<Job_On_Conflict>;
};

/** Boolean expression to filter rows from the table "job". All fields are combined with a logical 'AND'. */
export type Job_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Job_Bool_Exp>>>;
  _not?: Maybe<Job_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Job_Bool_Exp>>>;
  company?: Maybe<Company_Bool_Exp>;
  companyId?: Maybe<Uuid_Comparison_Exp>;
  companyName?: Maybe<String_Comparison_Exp>;
  contactData?: Maybe<ContactData_Bool_Exp>;
  contactDataId?: Maybe<Uuid_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  duration?: Maybe<String_Comparison_Exp>;
  endDate?: Maybe<Date_Comparison_Exp>;
  endDateText?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  startDate?: Maybe<Date_Comparison_Exp>;
  startDateText?: Maybe<String_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "job" */
export enum Job_Constraint {
  /** unique or primary key constraint */
  JobPkey = 'job_pkey',
}

/** input type for inserting data into table "job" */
export type Job_Insert_Input = {
  company?: Maybe<Company_Obj_Rel_Insert_Input>;
  companyId?: Maybe<Scalars['uuid']>;
  companyName?: Maybe<Scalars['String']>;
  contactData?: Maybe<ContactData_Arr_Rel_Insert_Input>;
  contactDataId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  duration?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['date']>;
  endDateText?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  startDate?: Maybe<Scalars['date']>;
  startDateText?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Job_Max_Fields = {
  __typename?: 'job_max_fields';
  companyId?: Maybe<Scalars['uuid']>;
  companyName?: Maybe<Scalars['String']>;
  contactDataId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  duration?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['date']>;
  endDateText?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  startDate?: Maybe<Scalars['date']>;
  startDateText?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "job" */
export type Job_Max_Order_By = {
  companyId?: Maybe<Order_By>;
  companyName?: Maybe<Order_By>;
  contactDataId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  duration?: Maybe<Order_By>;
  endDate?: Maybe<Order_By>;
  endDateText?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  startDate?: Maybe<Order_By>;
  startDateText?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Job_Min_Fields = {
  __typename?: 'job_min_fields';
  companyId?: Maybe<Scalars['uuid']>;
  companyName?: Maybe<Scalars['String']>;
  contactDataId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  duration?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['date']>;
  endDateText?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  startDate?: Maybe<Scalars['date']>;
  startDateText?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "job" */
export type Job_Min_Order_By = {
  companyId?: Maybe<Order_By>;
  companyName?: Maybe<Order_By>;
  contactDataId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  duration?: Maybe<Order_By>;
  endDate?: Maybe<Order_By>;
  endDateText?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  startDate?: Maybe<Order_By>;
  startDateText?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** response of any mutation on the table "job" */
export type Job_Mutation_Response = {
  __typename?: 'job_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Job>;
};

/** input type for inserting object relation for remote table "job" */
export type Job_Obj_Rel_Insert_Input = {
  data: Job_Insert_Input;
  on_conflict?: Maybe<Job_On_Conflict>;
};

/** on conflict condition type for table "job" */
export type Job_On_Conflict = {
  constraint: Job_Constraint;
  update_columns: Array<Job_Update_Column>;
  where?: Maybe<Job_Bool_Exp>;
};

/** ordering options when selecting data from "job" */
export type Job_Order_By = {
  company?: Maybe<Company_Order_By>;
  companyId?: Maybe<Order_By>;
  companyName?: Maybe<Order_By>;
  contactDataId?: Maybe<Order_By>;
  contactData_aggregate?: Maybe<ContactData_Aggregate_Order_By>;
  createdAt?: Maybe<Order_By>;
  duration?: Maybe<Order_By>;
  endDate?: Maybe<Order_By>;
  endDateText?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  startDate?: Maybe<Order_By>;
  startDateText?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** primary key columns input for table: "job" */
export type Job_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "job" */
export enum Job_Select_Column {
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  CompanyName = 'companyName',
  /** column name */
  ContactDataId = 'contactDataId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Duration = 'duration',
  /** column name */
  EndDate = 'endDate',
  /** column name */
  EndDateText = 'endDateText',
  /** column name */
  Id = 'id',
  /** column name */
  StartDate = 'startDate',
  /** column name */
  StartDateText = 'startDateText',
  /** column name */
  Title = 'title',
}

/** input type for updating data in table "job" */
export type Job_Set_Input = {
  companyId?: Maybe<Scalars['uuid']>;
  companyName?: Maybe<Scalars['String']>;
  contactDataId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  duration?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['date']>;
  endDateText?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  startDate?: Maybe<Scalars['date']>;
  startDateText?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** update columns of table "job" */
export enum Job_Update_Column {
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  CompanyName = 'companyName',
  /** column name */
  ContactDataId = 'contactDataId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Duration = 'duration',
  /** column name */
  EndDate = 'endDate',
  /** column name */
  EndDateText = 'endDateText',
  /** column name */
  Id = 'id',
  /** column name */
  StartDate = 'startDate',
  /** column name */
  StartDateText = 'startDateText',
  /** column name */
  Title = 'title',
}

/** expression to compare columns of type jsonb. All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  /** is the column contained in the given json value */
  _contained_in?: Maybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: Maybe<Scalars['jsonb']>;
  _eq?: Maybe<Scalars['jsonb']>;
  _gt?: Maybe<Scalars['jsonb']>;
  _gte?: Maybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: Maybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: Maybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: Maybe<Array<Scalars['String']>>;
  _in?: Maybe<Array<Scalars['jsonb']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['jsonb']>;
  _lte?: Maybe<Scalars['jsonb']>;
  _neq?: Maybe<Scalars['jsonb']>;
  _nin?: Maybe<Array<Scalars['jsonb']>>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** delete data from the table: "client" */
  delete_client?: Maybe<Client_Mutation_Response>;
  /** delete data from the table: "clientContact" */
  delete_clientContact?: Maybe<ClientContact_Mutation_Response>;
  /** delete data from the table: "clientContactCrmData" */
  delete_clientContactCrmData?: Maybe<ClientContactCrmData_Mutation_Response>;
  /** delete single row from the table: "clientContactCrmData" */
  delete_clientContactCrmData_by_pk?: Maybe<ClientContactCrmData>;
  /** delete data from the table: "clientContactFilter" */
  delete_clientContactFilter?: Maybe<ClientContactFilter_Mutation_Response>;
  /** delete single row from the table: "clientContactFilter" */
  delete_clientContactFilter_by_pk?: Maybe<ClientContactFilter>;
  /** delete data from the table: "clientContactJobChange" */
  delete_clientContactJobChange?: Maybe<ClientContactJobChange_Mutation_Response>;
  /** delete single row from the table: "clientContactJobChange" */
  delete_clientContactJobChange_by_pk?: Maybe<ClientContactJobChange>;
  /** delete single row from the table: "clientContact" */
  delete_clientContact_by_pk?: Maybe<ClientContact>;
  /** delete data from the table: "clientEmailReport" */
  delete_clientEmailReport?: Maybe<ClientEmailReport_Mutation_Response>;
  /** delete single row from the table: "clientEmailReport" */
  delete_clientEmailReport_by_pk?: Maybe<ClientEmailReport>;
  /** delete data from the table: "clientNotificationEmail" */
  delete_clientNotificationEmail?: Maybe<ClientNotificationEmail_Mutation_Response>;
  /** delete single row from the table: "clientNotificationEmail" */
  delete_clientNotificationEmail_by_pk?: Maybe<ClientNotificationEmail>;
  /** delete data from the table: "clientTerritory" */
  delete_clientTerritory?: Maybe<ClientTerritory_Mutation_Response>;
  /** delete single row from the table: "clientTerritory" */
  delete_clientTerritory_by_pk?: Maybe<ClientTerritory>;
  /** delete single row from the table: "client" */
  delete_client_by_pk?: Maybe<Client>;
  /** delete data from the table: "cloudTask" */
  delete_cloudTask?: Maybe<CloudTask_Mutation_Response>;
  /** delete single row from the table: "cloudTask" */
  delete_cloudTask_by_pk?: Maybe<CloudTask>;
  /** delete data from the table: "company" */
  delete_company?: Maybe<Company_Mutation_Response>;
  /** delete data from the table: "companyName" */
  delete_companyName?: Maybe<CompanyName_Mutation_Response>;
  /** delete single row from the table: "companyName" */
  delete_companyName_by_pk?: Maybe<CompanyName>;
  /** delete single row from the table: "company" */
  delete_company_by_pk?: Maybe<Company>;
  /** delete data from the table: "contactData" */
  delete_contactData?: Maybe<ContactData_Mutation_Response>;
  /** delete single row from the table: "contactData" */
  delete_contactData_by_pk?: Maybe<ContactData>;
  /** delete data from the table: "contactEmail" */
  delete_contactEmail?: Maybe<ContactEmail_Mutation_Response>;
  /** delete single row from the table: "contactEmail" */
  delete_contactEmail_by_pk?: Maybe<ContactEmail>;
  /** delete data from the table: "crmContactEnrichmentHistory" */
  delete_crmContactEnrichmentHistory?: Maybe<CrmContactEnrichmentHistory_Mutation_Response>;
  /** delete single row from the table: "crmContactEnrichmentHistory" */
  delete_crmContactEnrichmentHistory_by_pk?: Maybe<CrmContactEnrichmentHistory>;
  /** delete data from the table: "csvUpload" */
  delete_csvUpload?: Maybe<CsvUpload_Mutation_Response>;
  /** delete single row from the table: "csvUpload" */
  delete_csvUpload_by_pk?: Maybe<CsvUpload>;
  /** delete data from the table: "emailHistory" */
  delete_emailHistory?: Maybe<EmailHistory_Mutation_Response>;
  /** delete single row from the table: "emailHistory" */
  delete_emailHistory_by_pk?: Maybe<EmailHistory>;
  /** delete data from the table: "googleChannel" */
  delete_googleChannel?: Maybe<GoogleChannel_Mutation_Response>;
  /** delete single row from the table: "googleChannel" */
  delete_googleChannel_by_pk?: Maybe<GoogleChannel>;
  /** delete data from the table: "hubspotContactList" */
  delete_hubspotContactList?: Maybe<HubspotContactList_Mutation_Response>;
  /** delete data from the table: "hubspotContactListHubspotContact" */
  delete_hubspotContactListHubspotContact?: Maybe<HubspotContactListHubspotContact_Mutation_Response>;
  /** delete single row from the table: "hubspotContactListHubspotContact" */
  delete_hubspotContactListHubspotContact_by_pk?: Maybe<HubspotContactListHubspotContact>;
  /** delete single row from the table: "hubspotContactList" */
  delete_hubspotContactList_by_pk?: Maybe<HubspotContactList>;
  /** delete data from the table: "hubspotContactProperty" */
  delete_hubspotContactProperty?: Maybe<HubspotContactProperty_Mutation_Response>;
  /** delete single row from the table: "hubspotContactProperty" */
  delete_hubspotContactProperty_by_pk?: Maybe<HubspotContactProperty>;
  /** delete data from the table: "hubspotIntegration" */
  delete_hubspotIntegration?: Maybe<HubspotIntegration_Mutation_Response>;
  /** delete single row from the table: "hubspotIntegration" */
  delete_hubspotIntegration_by_pk?: Maybe<HubspotIntegration>;
  /** delete data from the table: "job" */
  delete_job?: Maybe<Job_Mutation_Response>;
  /** delete single row from the table: "job" */
  delete_job_by_pk?: Maybe<Job>;
  /** delete data from the table: "proxycrawlData" */
  delete_proxycrawlData?: Maybe<ProxycrawlData_Mutation_Response>;
  /** delete single row from the table: "proxycrawlData" */
  delete_proxycrawlData_by_pk?: Maybe<ProxycrawlData>;
  /** delete data from the table: "qaQualification" */
  delete_qaQualification?: Maybe<QaQualification_Mutation_Response>;
  /** delete single row from the table: "qaQualification" */
  delete_qaQualification_by_pk?: Maybe<QaQualification>;
  /** delete data from the table: "qaTask" */
  delete_qaTask?: Maybe<QaTask_Mutation_Response>;
  /** delete data from the table: "qaTaskAction" */
  delete_qaTaskAction?: Maybe<QaTaskAction_Mutation_Response>;
  /** delete single row from the table: "qaTaskAction" */
  delete_qaTaskAction_by_pk?: Maybe<QaTaskAction>;
  /** delete single row from the table: "qaTask" */
  delete_qaTask_by_pk?: Maybe<QaTask>;
  /** delete data from the table: "salesforceAccount" */
  delete_salesforceAccount?: Maybe<SalesforceAccount_Mutation_Response>;
  /** delete single row from the table: "salesforceAccount" */
  delete_salesforceAccount_by_pk?: Maybe<SalesforceAccount>;
  /** delete data from the table: "salesforceContactField" */
  delete_salesforceContactField?: Maybe<SalesforceContactField_Mutation_Response>;
  /** delete single row from the table: "salesforceContactField" */
  delete_salesforceContactField_by_pk?: Maybe<SalesforceContactField>;
  /** delete data from the table: "salesforceIntegration" */
  delete_salesforceIntegration?: Maybe<SalesforceIntegration_Mutation_Response>;
  /** delete single row from the table: "salesforceIntegration" */
  delete_salesforceIntegration_by_pk?: Maybe<SalesforceIntegration>;
  /** delete data from the table: "salesforceOpportunity" */
  delete_salesforceOpportunity?: Maybe<SalesforceOpportunity_Mutation_Response>;
  /** delete single row from the table: "salesforceOpportunity" */
  delete_salesforceOpportunity_by_pk?: Maybe<SalesforceOpportunity>;
  /** delete data from the table: "sendgridEvent" */
  delete_sendgridEvent?: Maybe<SendgridEvent_Mutation_Response>;
  /** delete single row from the table: "sendgridEvent" */
  delete_sendgridEvent_by_pk?: Maybe<SendgridEvent>;
  /** delete data from the table: "territory" */
  delete_territory?: Maybe<Territory_Mutation_Response>;
  /** delete data from the table: "territorySearchTerm" */
  delete_territorySearchTerm?: Maybe<TerritorySearchTerm_Mutation_Response>;
  /** delete single row from the table: "territorySearchTerm" */
  delete_territorySearchTerm_by_pk?: Maybe<TerritorySearchTerm>;
  /** delete single row from the table: "territory" */
  delete_territory_by_pk?: Maybe<Territory>;
  /** delete data from the table: "theOrgSearchTerm" */
  delete_theOrgSearchTerm?: Maybe<TheOrgSearchTerm_Mutation_Response>;
  /** delete single row from the table: "theOrgSearchTerm" */
  delete_theOrgSearchTerm_by_pk?: Maybe<TheOrgSearchTerm>;
  /** delete data from the table: "user" */
  delete_user?: Maybe<User_Mutation_Response>;
  /** delete data from the table: "userInvite" */
  delete_userInvite?: Maybe<UserInvite_Mutation_Response>;
  /** delete single row from the table: "userInvite" */
  delete_userInvite_by_pk?: Maybe<UserInvite>;
  /** delete single row from the table: "user" */
  delete_user_by_pk?: Maybe<User>;
  /** delete data from the table: "zoomCompany" */
  delete_zoomCompany?: Maybe<ZoomCompany_Mutation_Response>;
  /** delete single row from the table: "zoomCompany" */
  delete_zoomCompany_by_pk?: Maybe<ZoomCompany>;
  /** delete data from the table: "zoomContact" */
  delete_zoomContact?: Maybe<ZoomContact_Mutation_Response>;
  /** delete data from the table: "zoomContactEnrichmentHistory" */
  delete_zoomContactEnrichmentHistory?: Maybe<ZoomContactEnrichmentHistory_Mutation_Response>;
  /** delete single row from the table: "zoomContactEnrichmentHistory" */
  delete_zoomContactEnrichmentHistory_by_pk?: Maybe<ZoomContactEnrichmentHistory>;
  /** delete data from the table: "zoomContactInfo" */
  delete_zoomContactInfo?: Maybe<ZoomContactInfo_Mutation_Response>;
  /** delete single row from the table: "zoomContactInfo" */
  delete_zoomContactInfo_by_pk?: Maybe<ZoomContactInfo>;
  /** delete data from the table: "zoomContactNotes" */
  delete_zoomContactNotes?: Maybe<ZoomContactNotes_Mutation_Response>;
  /** delete single row from the table: "zoomContactNotes" */
  delete_zoomContactNotes_by_pk?: Maybe<ZoomContactNotes>;
  /** delete single row from the table: "zoomContact" */
  delete_zoomContact_by_pk?: Maybe<ZoomContact>;
  /** delete data from the table: "zoomEmailHistory" */
  delete_zoomEmailHistory?: Maybe<ZoomEmailHistory_Mutation_Response>;
  /** delete single row from the table: "zoomEmailHistory" */
  delete_zoomEmailHistory_by_pk?: Maybe<ZoomEmailHistory>;
  /** delete data from the table: "zoomEvent" */
  delete_zoomEvent?: Maybe<ZoomEvent_Mutation_Response>;
  /** delete data from the table: "zoomEventParticipant" */
  delete_zoomEventParticipant?: Maybe<ZoomEventParticipant_Mutation_Response>;
  /** delete single row from the table: "zoomEventParticipant" */
  delete_zoomEventParticipant_by_pk?: Maybe<ZoomEventParticipant>;
  /** delete single row from the table: "zoomEvent" */
  delete_zoomEvent_by_pk?: Maybe<ZoomEvent>;
  /** delete data from the table: "zoomExternalAuthUser" */
  delete_zoomExternalAuthUser?: Maybe<ZoomExternalAuthUser_Mutation_Response>;
  /** delete single row from the table: "zoomExternalAuthUser" */
  delete_zoomExternalAuthUser_by_pk?: Maybe<ZoomExternalAuthUser>;
  /** delete data from the table: "zoomUser" */
  delete_zoomUser?: Maybe<ZoomUser_Mutation_Response>;
  /** delete single row from the table: "zoomUser" */
  delete_zoomUser_by_pk?: Maybe<ZoomUser>;
  /** insert data into the table: "client" */
  insert_client?: Maybe<Client_Mutation_Response>;
  /** insert data into the table: "clientContact" */
  insert_clientContact?: Maybe<ClientContact_Mutation_Response>;
  /** insert data into the table: "clientContactCrmData" */
  insert_clientContactCrmData?: Maybe<ClientContactCrmData_Mutation_Response>;
  /** insert a single row into the table: "clientContactCrmData" */
  insert_clientContactCrmData_one?: Maybe<ClientContactCrmData>;
  /** insert data into the table: "clientContactFilter" */
  insert_clientContactFilter?: Maybe<ClientContactFilter_Mutation_Response>;
  /** insert a single row into the table: "clientContactFilter" */
  insert_clientContactFilter_one?: Maybe<ClientContactFilter>;
  /** insert data into the table: "clientContactJobChange" */
  insert_clientContactJobChange?: Maybe<ClientContactJobChange_Mutation_Response>;
  /** insert a single row into the table: "clientContactJobChange" */
  insert_clientContactJobChange_one?: Maybe<ClientContactJobChange>;
  /** insert a single row into the table: "clientContact" */
  insert_clientContact_one?: Maybe<ClientContact>;
  /** insert data into the table: "clientEmailReport" */
  insert_clientEmailReport?: Maybe<ClientEmailReport_Mutation_Response>;
  /** insert a single row into the table: "clientEmailReport" */
  insert_clientEmailReport_one?: Maybe<ClientEmailReport>;
  /** insert data into the table: "clientNotificationEmail" */
  insert_clientNotificationEmail?: Maybe<ClientNotificationEmail_Mutation_Response>;
  /** insert a single row into the table: "clientNotificationEmail" */
  insert_clientNotificationEmail_one?: Maybe<ClientNotificationEmail>;
  /** insert data into the table: "clientTerritory" */
  insert_clientTerritory?: Maybe<ClientTerritory_Mutation_Response>;
  /** insert a single row into the table: "clientTerritory" */
  insert_clientTerritory_one?: Maybe<ClientTerritory>;
  /** insert a single row into the table: "client" */
  insert_client_one?: Maybe<Client>;
  /** insert data into the table: "cloudTask" */
  insert_cloudTask?: Maybe<CloudTask_Mutation_Response>;
  /** insert a single row into the table: "cloudTask" */
  insert_cloudTask_one?: Maybe<CloudTask>;
  /** insert data into the table: "company" */
  insert_company?: Maybe<Company_Mutation_Response>;
  /** insert data into the table: "companyName" */
  insert_companyName?: Maybe<CompanyName_Mutation_Response>;
  /** insert a single row into the table: "companyName" */
  insert_companyName_one?: Maybe<CompanyName>;
  /** insert a single row into the table: "company" */
  insert_company_one?: Maybe<Company>;
  /** insert data into the table: "contactData" */
  insert_contactData?: Maybe<ContactData_Mutation_Response>;
  /** insert a single row into the table: "contactData" */
  insert_contactData_one?: Maybe<ContactData>;
  /** insert data into the table: "contactEmail" */
  insert_contactEmail?: Maybe<ContactEmail_Mutation_Response>;
  /** insert a single row into the table: "contactEmail" */
  insert_contactEmail_one?: Maybe<ContactEmail>;
  /** insert data into the table: "crmContactEnrichmentHistory" */
  insert_crmContactEnrichmentHistory?: Maybe<CrmContactEnrichmentHistory_Mutation_Response>;
  /** insert a single row into the table: "crmContactEnrichmentHistory" */
  insert_crmContactEnrichmentHistory_one?: Maybe<CrmContactEnrichmentHistory>;
  /** insert data into the table: "csvUpload" */
  insert_csvUpload?: Maybe<CsvUpload_Mutation_Response>;
  /** insert a single row into the table: "csvUpload" */
  insert_csvUpload_one?: Maybe<CsvUpload>;
  /** insert data into the table: "emailHistory" */
  insert_emailHistory?: Maybe<EmailHistory_Mutation_Response>;
  /** insert a single row into the table: "emailHistory" */
  insert_emailHistory_one?: Maybe<EmailHistory>;
  /** insert data into the table: "googleChannel" */
  insert_googleChannel?: Maybe<GoogleChannel_Mutation_Response>;
  /** insert a single row into the table: "googleChannel" */
  insert_googleChannel_one?: Maybe<GoogleChannel>;
  /** insert data into the table: "hubspotContactList" */
  insert_hubspotContactList?: Maybe<HubspotContactList_Mutation_Response>;
  /** insert data into the table: "hubspotContactListHubspotContact" */
  insert_hubspotContactListHubspotContact?: Maybe<HubspotContactListHubspotContact_Mutation_Response>;
  /** insert a single row into the table: "hubspotContactListHubspotContact" */
  insert_hubspotContactListHubspotContact_one?: Maybe<HubspotContactListHubspotContact>;
  /** insert a single row into the table: "hubspotContactList" */
  insert_hubspotContactList_one?: Maybe<HubspotContactList>;
  /** insert data into the table: "hubspotContactProperty" */
  insert_hubspotContactProperty?: Maybe<HubspotContactProperty_Mutation_Response>;
  /** insert a single row into the table: "hubspotContactProperty" */
  insert_hubspotContactProperty_one?: Maybe<HubspotContactProperty>;
  /** insert data into the table: "hubspotIntegration" */
  insert_hubspotIntegration?: Maybe<HubspotIntegration_Mutation_Response>;
  /** insert a single row into the table: "hubspotIntegration" */
  insert_hubspotIntegration_one?: Maybe<HubspotIntegration>;
  /** insert data into the table: "job" */
  insert_job?: Maybe<Job_Mutation_Response>;
  /** insert a single row into the table: "job" */
  insert_job_one?: Maybe<Job>;
  /** insert data into the table: "proxycrawlData" */
  insert_proxycrawlData?: Maybe<ProxycrawlData_Mutation_Response>;
  /** insert a single row into the table: "proxycrawlData" */
  insert_proxycrawlData_one?: Maybe<ProxycrawlData>;
  /** insert data into the table: "qaQualification" */
  insert_qaQualification?: Maybe<QaQualification_Mutation_Response>;
  /** insert a single row into the table: "qaQualification" */
  insert_qaQualification_one?: Maybe<QaQualification>;
  /** insert data into the table: "qaTask" */
  insert_qaTask?: Maybe<QaTask_Mutation_Response>;
  /** insert data into the table: "qaTaskAction" */
  insert_qaTaskAction?: Maybe<QaTaskAction_Mutation_Response>;
  /** insert a single row into the table: "qaTaskAction" */
  insert_qaTaskAction_one?: Maybe<QaTaskAction>;
  /** insert a single row into the table: "qaTask" */
  insert_qaTask_one?: Maybe<QaTask>;
  /** insert data into the table: "salesforceAccount" */
  insert_salesforceAccount?: Maybe<SalesforceAccount_Mutation_Response>;
  /** insert a single row into the table: "salesforceAccount" */
  insert_salesforceAccount_one?: Maybe<SalesforceAccount>;
  /** insert data into the table: "salesforceContactField" */
  insert_salesforceContactField?: Maybe<SalesforceContactField_Mutation_Response>;
  /** insert a single row into the table: "salesforceContactField" */
  insert_salesforceContactField_one?: Maybe<SalesforceContactField>;
  /** insert data into the table: "salesforceIntegration" */
  insert_salesforceIntegration?: Maybe<SalesforceIntegration_Mutation_Response>;
  /** insert a single row into the table: "salesforceIntegration" */
  insert_salesforceIntegration_one?: Maybe<SalesforceIntegration>;
  /** insert data into the table: "salesforceOpportunity" */
  insert_salesforceOpportunity?: Maybe<SalesforceOpportunity_Mutation_Response>;
  /** insert a single row into the table: "salesforceOpportunity" */
  insert_salesforceOpportunity_one?: Maybe<SalesforceOpportunity>;
  /** insert data into the table: "sendgridEvent" */
  insert_sendgridEvent?: Maybe<SendgridEvent_Mutation_Response>;
  /** insert a single row into the table: "sendgridEvent" */
  insert_sendgridEvent_one?: Maybe<SendgridEvent>;
  /** insert data into the table: "territory" */
  insert_territory?: Maybe<Territory_Mutation_Response>;
  /** insert data into the table: "territorySearchTerm" */
  insert_territorySearchTerm?: Maybe<TerritorySearchTerm_Mutation_Response>;
  /** insert a single row into the table: "territorySearchTerm" */
  insert_territorySearchTerm_one?: Maybe<TerritorySearchTerm>;
  /** insert a single row into the table: "territory" */
  insert_territory_one?: Maybe<Territory>;
  /** insert data into the table: "theOrgSearchTerm" */
  insert_theOrgSearchTerm?: Maybe<TheOrgSearchTerm_Mutation_Response>;
  /** insert a single row into the table: "theOrgSearchTerm" */
  insert_theOrgSearchTerm_one?: Maybe<TheOrgSearchTerm>;
  /** insert data into the table: "user" */
  insert_user?: Maybe<User_Mutation_Response>;
  /** insert data into the table: "userInvite" */
  insert_userInvite?: Maybe<UserInvite_Mutation_Response>;
  /** insert a single row into the table: "userInvite" */
  insert_userInvite_one?: Maybe<UserInvite>;
  /** insert a single row into the table: "user" */
  insert_user_one?: Maybe<User>;
  /** insert data into the table: "zoomCompany" */
  insert_zoomCompany?: Maybe<ZoomCompany_Mutation_Response>;
  /** insert a single row into the table: "zoomCompany" */
  insert_zoomCompany_one?: Maybe<ZoomCompany>;
  /** insert data into the table: "zoomContact" */
  insert_zoomContact?: Maybe<ZoomContact_Mutation_Response>;
  /** insert data into the table: "zoomContactEnrichmentHistory" */
  insert_zoomContactEnrichmentHistory?: Maybe<ZoomContactEnrichmentHistory_Mutation_Response>;
  /** insert a single row into the table: "zoomContactEnrichmentHistory" */
  insert_zoomContactEnrichmentHistory_one?: Maybe<ZoomContactEnrichmentHistory>;
  /** insert data into the table: "zoomContactInfo" */
  insert_zoomContactInfo?: Maybe<ZoomContactInfo_Mutation_Response>;
  /** insert a single row into the table: "zoomContactInfo" */
  insert_zoomContactInfo_one?: Maybe<ZoomContactInfo>;
  /** insert data into the table: "zoomContactNotes" */
  insert_zoomContactNotes?: Maybe<ZoomContactNotes_Mutation_Response>;
  /** insert a single row into the table: "zoomContactNotes" */
  insert_zoomContactNotes_one?: Maybe<ZoomContactNotes>;
  /** insert a single row into the table: "zoomContact" */
  insert_zoomContact_one?: Maybe<ZoomContact>;
  /** insert data into the table: "zoomEmailHistory" */
  insert_zoomEmailHistory?: Maybe<ZoomEmailHistory_Mutation_Response>;
  /** insert a single row into the table: "zoomEmailHistory" */
  insert_zoomEmailHistory_one?: Maybe<ZoomEmailHistory>;
  /** insert data into the table: "zoomEvent" */
  insert_zoomEvent?: Maybe<ZoomEvent_Mutation_Response>;
  /** insert data into the table: "zoomEventParticipant" */
  insert_zoomEventParticipant?: Maybe<ZoomEventParticipant_Mutation_Response>;
  /** insert a single row into the table: "zoomEventParticipant" */
  insert_zoomEventParticipant_one?: Maybe<ZoomEventParticipant>;
  /** insert a single row into the table: "zoomEvent" */
  insert_zoomEvent_one?: Maybe<ZoomEvent>;
  /** insert data into the table: "zoomExternalAuthUser" */
  insert_zoomExternalAuthUser?: Maybe<ZoomExternalAuthUser_Mutation_Response>;
  /** insert a single row into the table: "zoomExternalAuthUser" */
  insert_zoomExternalAuthUser_one?: Maybe<ZoomExternalAuthUser>;
  /** insert data into the table: "zoomUser" */
  insert_zoomUser?: Maybe<ZoomUser_Mutation_Response>;
  /** insert a single row into the table: "zoomUser" */
  insert_zoomUser_one?: Maybe<ZoomUser>;
  /** update data of the table: "client" */
  update_client?: Maybe<Client_Mutation_Response>;
  /** update data of the table: "clientContact" */
  update_clientContact?: Maybe<ClientContact_Mutation_Response>;
  /** update data of the table: "clientContactCrmData" */
  update_clientContactCrmData?: Maybe<ClientContactCrmData_Mutation_Response>;
  /** update single row of the table: "clientContactCrmData" */
  update_clientContactCrmData_by_pk?: Maybe<ClientContactCrmData>;
  /** update data of the table: "clientContactFilter" */
  update_clientContactFilter?: Maybe<ClientContactFilter_Mutation_Response>;
  /** update single row of the table: "clientContactFilter" */
  update_clientContactFilter_by_pk?: Maybe<ClientContactFilter>;
  /** update data of the table: "clientContactJobChange" */
  update_clientContactJobChange?: Maybe<ClientContactJobChange_Mutation_Response>;
  /** update single row of the table: "clientContactJobChange" */
  update_clientContactJobChange_by_pk?: Maybe<ClientContactJobChange>;
  /** update single row of the table: "clientContact" */
  update_clientContact_by_pk?: Maybe<ClientContact>;
  /** update data of the table: "clientEmailReport" */
  update_clientEmailReport?: Maybe<ClientEmailReport_Mutation_Response>;
  /** update single row of the table: "clientEmailReport" */
  update_clientEmailReport_by_pk?: Maybe<ClientEmailReport>;
  /** update data of the table: "clientNotificationEmail" */
  update_clientNotificationEmail?: Maybe<ClientNotificationEmail_Mutation_Response>;
  /** update single row of the table: "clientNotificationEmail" */
  update_clientNotificationEmail_by_pk?: Maybe<ClientNotificationEmail>;
  /** update data of the table: "clientTerritory" */
  update_clientTerritory?: Maybe<ClientTerritory_Mutation_Response>;
  /** update single row of the table: "clientTerritory" */
  update_clientTerritory_by_pk?: Maybe<ClientTerritory>;
  /** update single row of the table: "client" */
  update_client_by_pk?: Maybe<Client>;
  /** update data of the table: "cloudTask" */
  update_cloudTask?: Maybe<CloudTask_Mutation_Response>;
  /** update single row of the table: "cloudTask" */
  update_cloudTask_by_pk?: Maybe<CloudTask>;
  /** update data of the table: "company" */
  update_company?: Maybe<Company_Mutation_Response>;
  /** update data of the table: "companyName" */
  update_companyName?: Maybe<CompanyName_Mutation_Response>;
  /** update single row of the table: "companyName" */
  update_companyName_by_pk?: Maybe<CompanyName>;
  /** update single row of the table: "company" */
  update_company_by_pk?: Maybe<Company>;
  /** update data of the table: "contactData" */
  update_contactData?: Maybe<ContactData_Mutation_Response>;
  /** update single row of the table: "contactData" */
  update_contactData_by_pk?: Maybe<ContactData>;
  /** update data of the table: "contactEmail" */
  update_contactEmail?: Maybe<ContactEmail_Mutation_Response>;
  /** update single row of the table: "contactEmail" */
  update_contactEmail_by_pk?: Maybe<ContactEmail>;
  /** update data of the table: "crmContactEnrichmentHistory" */
  update_crmContactEnrichmentHistory?: Maybe<CrmContactEnrichmentHistory_Mutation_Response>;
  /** update single row of the table: "crmContactEnrichmentHistory" */
  update_crmContactEnrichmentHistory_by_pk?: Maybe<CrmContactEnrichmentHistory>;
  /** update data of the table: "csvUpload" */
  update_csvUpload?: Maybe<CsvUpload_Mutation_Response>;
  /** update single row of the table: "csvUpload" */
  update_csvUpload_by_pk?: Maybe<CsvUpload>;
  /** update data of the table: "emailHistory" */
  update_emailHistory?: Maybe<EmailHistory_Mutation_Response>;
  /** update single row of the table: "emailHistory" */
  update_emailHistory_by_pk?: Maybe<EmailHistory>;
  /** update data of the table: "googleChannel" */
  update_googleChannel?: Maybe<GoogleChannel_Mutation_Response>;
  /** update single row of the table: "googleChannel" */
  update_googleChannel_by_pk?: Maybe<GoogleChannel>;
  /** update data of the table: "hubspotContactList" */
  update_hubspotContactList?: Maybe<HubspotContactList_Mutation_Response>;
  /** update data of the table: "hubspotContactListHubspotContact" */
  update_hubspotContactListHubspotContact?: Maybe<HubspotContactListHubspotContact_Mutation_Response>;
  /** update single row of the table: "hubspotContactListHubspotContact" */
  update_hubspotContactListHubspotContact_by_pk?: Maybe<HubspotContactListHubspotContact>;
  /** update single row of the table: "hubspotContactList" */
  update_hubspotContactList_by_pk?: Maybe<HubspotContactList>;
  /** update data of the table: "hubspotContactProperty" */
  update_hubspotContactProperty?: Maybe<HubspotContactProperty_Mutation_Response>;
  /** update single row of the table: "hubspotContactProperty" */
  update_hubspotContactProperty_by_pk?: Maybe<HubspotContactProperty>;
  /** update data of the table: "hubspotIntegration" */
  update_hubspotIntegration?: Maybe<HubspotIntegration_Mutation_Response>;
  /** update single row of the table: "hubspotIntegration" */
  update_hubspotIntegration_by_pk?: Maybe<HubspotIntegration>;
  /** update data of the table: "job" */
  update_job?: Maybe<Job_Mutation_Response>;
  /** update single row of the table: "job" */
  update_job_by_pk?: Maybe<Job>;
  /** update data of the table: "proxycrawlData" */
  update_proxycrawlData?: Maybe<ProxycrawlData_Mutation_Response>;
  /** update single row of the table: "proxycrawlData" */
  update_proxycrawlData_by_pk?: Maybe<ProxycrawlData>;
  /** update data of the table: "qaQualification" */
  update_qaQualification?: Maybe<QaQualification_Mutation_Response>;
  /** update single row of the table: "qaQualification" */
  update_qaQualification_by_pk?: Maybe<QaQualification>;
  /** update data of the table: "qaTask" */
  update_qaTask?: Maybe<QaTask_Mutation_Response>;
  /** update data of the table: "qaTaskAction" */
  update_qaTaskAction?: Maybe<QaTaskAction_Mutation_Response>;
  /** update single row of the table: "qaTaskAction" */
  update_qaTaskAction_by_pk?: Maybe<QaTaskAction>;
  /** update single row of the table: "qaTask" */
  update_qaTask_by_pk?: Maybe<QaTask>;
  /** update data of the table: "salesforceAccount" */
  update_salesforceAccount?: Maybe<SalesforceAccount_Mutation_Response>;
  /** update single row of the table: "salesforceAccount" */
  update_salesforceAccount_by_pk?: Maybe<SalesforceAccount>;
  /** update data of the table: "salesforceContactField" */
  update_salesforceContactField?: Maybe<SalesforceContactField_Mutation_Response>;
  /** update single row of the table: "salesforceContactField" */
  update_salesforceContactField_by_pk?: Maybe<SalesforceContactField>;
  /** update data of the table: "salesforceIntegration" */
  update_salesforceIntegration?: Maybe<SalesforceIntegration_Mutation_Response>;
  /** update single row of the table: "salesforceIntegration" */
  update_salesforceIntegration_by_pk?: Maybe<SalesforceIntegration>;
  /** update data of the table: "salesforceOpportunity" */
  update_salesforceOpportunity?: Maybe<SalesforceOpportunity_Mutation_Response>;
  /** update single row of the table: "salesforceOpportunity" */
  update_salesforceOpportunity_by_pk?: Maybe<SalesforceOpportunity>;
  /** update data of the table: "sendgridEvent" */
  update_sendgridEvent?: Maybe<SendgridEvent_Mutation_Response>;
  /** update single row of the table: "sendgridEvent" */
  update_sendgridEvent_by_pk?: Maybe<SendgridEvent>;
  /** update data of the table: "territory" */
  update_territory?: Maybe<Territory_Mutation_Response>;
  /** update data of the table: "territorySearchTerm" */
  update_territorySearchTerm?: Maybe<TerritorySearchTerm_Mutation_Response>;
  /** update single row of the table: "territorySearchTerm" */
  update_territorySearchTerm_by_pk?: Maybe<TerritorySearchTerm>;
  /** update single row of the table: "territory" */
  update_territory_by_pk?: Maybe<Territory>;
  /** update data of the table: "theOrgSearchTerm" */
  update_theOrgSearchTerm?: Maybe<TheOrgSearchTerm_Mutation_Response>;
  /** update single row of the table: "theOrgSearchTerm" */
  update_theOrgSearchTerm_by_pk?: Maybe<TheOrgSearchTerm>;
  /** update data of the table: "user" */
  update_user?: Maybe<User_Mutation_Response>;
  /** update data of the table: "userInvite" */
  update_userInvite?: Maybe<UserInvite_Mutation_Response>;
  /** update single row of the table: "userInvite" */
  update_userInvite_by_pk?: Maybe<UserInvite>;
  /** update single row of the table: "user" */
  update_user_by_pk?: Maybe<User>;
  /** update data of the table: "zoomCompany" */
  update_zoomCompany?: Maybe<ZoomCompany_Mutation_Response>;
  /** update single row of the table: "zoomCompany" */
  update_zoomCompany_by_pk?: Maybe<ZoomCompany>;
  /** update data of the table: "zoomContact" */
  update_zoomContact?: Maybe<ZoomContact_Mutation_Response>;
  /** update data of the table: "zoomContactEnrichmentHistory" */
  update_zoomContactEnrichmentHistory?: Maybe<ZoomContactEnrichmentHistory_Mutation_Response>;
  /** update single row of the table: "zoomContactEnrichmentHistory" */
  update_zoomContactEnrichmentHistory_by_pk?: Maybe<ZoomContactEnrichmentHistory>;
  /** update data of the table: "zoomContactInfo" */
  update_zoomContactInfo?: Maybe<ZoomContactInfo_Mutation_Response>;
  /** update single row of the table: "zoomContactInfo" */
  update_zoomContactInfo_by_pk?: Maybe<ZoomContactInfo>;
  /** update data of the table: "zoomContactNotes" */
  update_zoomContactNotes?: Maybe<ZoomContactNotes_Mutation_Response>;
  /** update single row of the table: "zoomContactNotes" */
  update_zoomContactNotes_by_pk?: Maybe<ZoomContactNotes>;
  /** update single row of the table: "zoomContact" */
  update_zoomContact_by_pk?: Maybe<ZoomContact>;
  /** update data of the table: "zoomEmailHistory" */
  update_zoomEmailHistory?: Maybe<ZoomEmailHistory_Mutation_Response>;
  /** update single row of the table: "zoomEmailHistory" */
  update_zoomEmailHistory_by_pk?: Maybe<ZoomEmailHistory>;
  /** update data of the table: "zoomEvent" */
  update_zoomEvent?: Maybe<ZoomEvent_Mutation_Response>;
  /** update data of the table: "zoomEventParticipant" */
  update_zoomEventParticipant?: Maybe<ZoomEventParticipant_Mutation_Response>;
  /** update single row of the table: "zoomEventParticipant" */
  update_zoomEventParticipant_by_pk?: Maybe<ZoomEventParticipant>;
  /** update single row of the table: "zoomEvent" */
  update_zoomEvent_by_pk?: Maybe<ZoomEvent>;
  /** update data of the table: "zoomExternalAuthUser" */
  update_zoomExternalAuthUser?: Maybe<ZoomExternalAuthUser_Mutation_Response>;
  /** update single row of the table: "zoomExternalAuthUser" */
  update_zoomExternalAuthUser_by_pk?: Maybe<ZoomExternalAuthUser>;
  /** update data of the table: "zoomUser" */
  update_zoomUser?: Maybe<ZoomUser_Mutation_Response>;
  /** update single row of the table: "zoomUser" */
  update_zoomUser_by_pk?: Maybe<ZoomUser>;
};

/** mutation root */
export type Mutation_RootDelete_ClientArgs = {
  where: Client_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_ClientContactArgs = {
  where: ClientContact_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_ClientContactCrmDataArgs = {
  where: ClientContactCrmData_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_ClientContactCrmData_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_ClientContactFilterArgs = {
  where: ClientContactFilter_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_ClientContactFilter_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_ClientContactJobChangeArgs = {
  where: ClientContactJobChange_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_ClientContactJobChange_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_ClientContact_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_ClientEmailReportArgs = {
  where: ClientEmailReport_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_ClientEmailReport_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_ClientNotificationEmailArgs = {
  where: ClientNotificationEmail_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_ClientNotificationEmail_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_ClientTerritoryArgs = {
  where: ClientTerritory_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_ClientTerritory_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Client_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_CloudTaskArgs = {
  where: CloudTask_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_CloudTask_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_CompanyArgs = {
  where: Company_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_CompanyNameArgs = {
  where: CompanyName_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_CompanyName_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Company_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_ContactDataArgs = {
  where: ContactData_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_ContactData_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_ContactEmailArgs = {
  where: ContactEmail_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_ContactEmail_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_CrmContactEnrichmentHistoryArgs = {
  where: CrmContactEnrichmentHistory_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_CrmContactEnrichmentHistory_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_CsvUploadArgs = {
  where: CsvUpload_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_CsvUpload_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_EmailHistoryArgs = {
  where: EmailHistory_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_EmailHistory_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_GoogleChannelArgs = {
  where: GoogleChannel_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_GoogleChannel_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_HubspotContactListArgs = {
  where: HubspotContactList_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_HubspotContactListHubspotContactArgs = {
  where: HubspotContactListHubspotContact_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_HubspotContactListHubspotContact_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_HubspotContactList_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_HubspotContactPropertyArgs = {
  where: HubspotContactProperty_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_HubspotContactProperty_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_HubspotIntegrationArgs = {
  where: HubspotIntegration_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_HubspotIntegration_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_JobArgs = {
  where: Job_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Job_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_ProxycrawlDataArgs = {
  where: ProxycrawlData_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_ProxycrawlData_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_QaQualificationArgs = {
  where: QaQualification_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_QaQualification_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_QaTaskArgs = {
  where: QaTask_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_QaTaskActionArgs = {
  where: QaTaskAction_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_QaTaskAction_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_QaTask_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_SalesforceAccountArgs = {
  where: SalesforceAccount_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_SalesforceAccount_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_SalesforceContactFieldArgs = {
  where: SalesforceContactField_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_SalesforceContactField_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_SalesforceIntegrationArgs = {
  where: SalesforceIntegration_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_SalesforceIntegration_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_SalesforceOpportunityArgs = {
  where: SalesforceOpportunity_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_SalesforceOpportunity_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_SendgridEventArgs = {
  where: SendgridEvent_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_SendgridEvent_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_TerritoryArgs = {
  where: Territory_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_TerritorySearchTermArgs = {
  where: TerritorySearchTerm_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_TerritorySearchTerm_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Territory_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_TheOrgSearchTermArgs = {
  where: TheOrgSearchTerm_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_TheOrgSearchTerm_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_UserArgs = {
  where: User_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_UserInviteArgs = {
  where: UserInvite_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_UserInvite_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_User_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_ZoomCompanyArgs = {
  where: ZoomCompany_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_ZoomCompany_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_ZoomContactArgs = {
  where: ZoomContact_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_ZoomContactEnrichmentHistoryArgs = {
  where: ZoomContactEnrichmentHistory_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_ZoomContactEnrichmentHistory_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_ZoomContactInfoArgs = {
  where: ZoomContactInfo_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_ZoomContactInfo_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_ZoomContactNotesArgs = {
  where: ZoomContactNotes_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_ZoomContactNotes_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_ZoomContact_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_ZoomEmailHistoryArgs = {
  where: ZoomEmailHistory_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_ZoomEmailHistory_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_ZoomEventArgs = {
  where: ZoomEvent_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_ZoomEventParticipantArgs = {
  where: ZoomEventParticipant_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_ZoomEventParticipant_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_ZoomEvent_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_ZoomExternalAuthUserArgs = {
  where: ZoomExternalAuthUser_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_ZoomExternalAuthUser_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_ZoomUserArgs = {
  where: ZoomUser_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_ZoomUser_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootInsert_ClientArgs = {
  objects: Array<Client_Insert_Input>;
  on_conflict?: Maybe<Client_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ClientContactArgs = {
  objects: Array<ClientContact_Insert_Input>;
  on_conflict?: Maybe<ClientContact_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ClientContactCrmDataArgs = {
  objects: Array<ClientContactCrmData_Insert_Input>;
  on_conflict?: Maybe<ClientContactCrmData_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ClientContactCrmData_OneArgs = {
  object: ClientContactCrmData_Insert_Input;
  on_conflict?: Maybe<ClientContactCrmData_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ClientContactFilterArgs = {
  objects: Array<ClientContactFilter_Insert_Input>;
  on_conflict?: Maybe<ClientContactFilter_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ClientContactFilter_OneArgs = {
  object: ClientContactFilter_Insert_Input;
  on_conflict?: Maybe<ClientContactFilter_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ClientContactJobChangeArgs = {
  objects: Array<ClientContactJobChange_Insert_Input>;
  on_conflict?: Maybe<ClientContactJobChange_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ClientContactJobChange_OneArgs = {
  object: ClientContactJobChange_Insert_Input;
  on_conflict?: Maybe<ClientContactJobChange_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ClientContact_OneArgs = {
  object: ClientContact_Insert_Input;
  on_conflict?: Maybe<ClientContact_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ClientEmailReportArgs = {
  objects: Array<ClientEmailReport_Insert_Input>;
  on_conflict?: Maybe<ClientEmailReport_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ClientEmailReport_OneArgs = {
  object: ClientEmailReport_Insert_Input;
  on_conflict?: Maybe<ClientEmailReport_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ClientNotificationEmailArgs = {
  objects: Array<ClientNotificationEmail_Insert_Input>;
  on_conflict?: Maybe<ClientNotificationEmail_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ClientNotificationEmail_OneArgs = {
  object: ClientNotificationEmail_Insert_Input;
  on_conflict?: Maybe<ClientNotificationEmail_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ClientTerritoryArgs = {
  objects: Array<ClientTerritory_Insert_Input>;
  on_conflict?: Maybe<ClientTerritory_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ClientTerritory_OneArgs = {
  object: ClientTerritory_Insert_Input;
  on_conflict?: Maybe<ClientTerritory_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Client_OneArgs = {
  object: Client_Insert_Input;
  on_conflict?: Maybe<Client_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_CloudTaskArgs = {
  objects: Array<CloudTask_Insert_Input>;
  on_conflict?: Maybe<CloudTask_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_CloudTask_OneArgs = {
  object: CloudTask_Insert_Input;
  on_conflict?: Maybe<CloudTask_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_CompanyArgs = {
  objects: Array<Company_Insert_Input>;
  on_conflict?: Maybe<Company_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_CompanyNameArgs = {
  objects: Array<CompanyName_Insert_Input>;
  on_conflict?: Maybe<CompanyName_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_CompanyName_OneArgs = {
  object: CompanyName_Insert_Input;
  on_conflict?: Maybe<CompanyName_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Company_OneArgs = {
  object: Company_Insert_Input;
  on_conflict?: Maybe<Company_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ContactDataArgs = {
  objects: Array<ContactData_Insert_Input>;
  on_conflict?: Maybe<ContactData_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ContactData_OneArgs = {
  object: ContactData_Insert_Input;
  on_conflict?: Maybe<ContactData_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ContactEmailArgs = {
  objects: Array<ContactEmail_Insert_Input>;
  on_conflict?: Maybe<ContactEmail_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ContactEmail_OneArgs = {
  object: ContactEmail_Insert_Input;
  on_conflict?: Maybe<ContactEmail_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_CrmContactEnrichmentHistoryArgs = {
  objects: Array<CrmContactEnrichmentHistory_Insert_Input>;
  on_conflict?: Maybe<CrmContactEnrichmentHistory_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_CrmContactEnrichmentHistory_OneArgs = {
  object: CrmContactEnrichmentHistory_Insert_Input;
  on_conflict?: Maybe<CrmContactEnrichmentHistory_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_CsvUploadArgs = {
  objects: Array<CsvUpload_Insert_Input>;
  on_conflict?: Maybe<CsvUpload_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_CsvUpload_OneArgs = {
  object: CsvUpload_Insert_Input;
  on_conflict?: Maybe<CsvUpload_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_EmailHistoryArgs = {
  objects: Array<EmailHistory_Insert_Input>;
  on_conflict?: Maybe<EmailHistory_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_EmailHistory_OneArgs = {
  object: EmailHistory_Insert_Input;
  on_conflict?: Maybe<EmailHistory_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_GoogleChannelArgs = {
  objects: Array<GoogleChannel_Insert_Input>;
  on_conflict?: Maybe<GoogleChannel_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_GoogleChannel_OneArgs = {
  object: GoogleChannel_Insert_Input;
  on_conflict?: Maybe<GoogleChannel_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_HubspotContactListArgs = {
  objects: Array<HubspotContactList_Insert_Input>;
  on_conflict?: Maybe<HubspotContactList_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_HubspotContactListHubspotContactArgs = {
  objects: Array<HubspotContactListHubspotContact_Insert_Input>;
  on_conflict?: Maybe<HubspotContactListHubspotContact_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_HubspotContactListHubspotContact_OneArgs = {
  object: HubspotContactListHubspotContact_Insert_Input;
  on_conflict?: Maybe<HubspotContactListHubspotContact_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_HubspotContactList_OneArgs = {
  object: HubspotContactList_Insert_Input;
  on_conflict?: Maybe<HubspotContactList_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_HubspotContactPropertyArgs = {
  objects: Array<HubspotContactProperty_Insert_Input>;
  on_conflict?: Maybe<HubspotContactProperty_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_HubspotContactProperty_OneArgs = {
  object: HubspotContactProperty_Insert_Input;
  on_conflict?: Maybe<HubspotContactProperty_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_HubspotIntegrationArgs = {
  objects: Array<HubspotIntegration_Insert_Input>;
  on_conflict?: Maybe<HubspotIntegration_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_HubspotIntegration_OneArgs = {
  object: HubspotIntegration_Insert_Input;
  on_conflict?: Maybe<HubspotIntegration_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_JobArgs = {
  objects: Array<Job_Insert_Input>;
  on_conflict?: Maybe<Job_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Job_OneArgs = {
  object: Job_Insert_Input;
  on_conflict?: Maybe<Job_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ProxycrawlDataArgs = {
  objects: Array<ProxycrawlData_Insert_Input>;
  on_conflict?: Maybe<ProxycrawlData_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ProxycrawlData_OneArgs = {
  object: ProxycrawlData_Insert_Input;
  on_conflict?: Maybe<ProxycrawlData_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_QaQualificationArgs = {
  objects: Array<QaQualification_Insert_Input>;
  on_conflict?: Maybe<QaQualification_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_QaQualification_OneArgs = {
  object: QaQualification_Insert_Input;
  on_conflict?: Maybe<QaQualification_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_QaTaskArgs = {
  objects: Array<QaTask_Insert_Input>;
  on_conflict?: Maybe<QaTask_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_QaTaskActionArgs = {
  objects: Array<QaTaskAction_Insert_Input>;
  on_conflict?: Maybe<QaTaskAction_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_QaTaskAction_OneArgs = {
  object: QaTaskAction_Insert_Input;
  on_conflict?: Maybe<QaTaskAction_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_QaTask_OneArgs = {
  object: QaTask_Insert_Input;
  on_conflict?: Maybe<QaTask_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_SalesforceAccountArgs = {
  objects: Array<SalesforceAccount_Insert_Input>;
  on_conflict?: Maybe<SalesforceAccount_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_SalesforceAccount_OneArgs = {
  object: SalesforceAccount_Insert_Input;
  on_conflict?: Maybe<SalesforceAccount_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_SalesforceContactFieldArgs = {
  objects: Array<SalesforceContactField_Insert_Input>;
  on_conflict?: Maybe<SalesforceContactField_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_SalesforceContactField_OneArgs = {
  object: SalesforceContactField_Insert_Input;
  on_conflict?: Maybe<SalesforceContactField_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_SalesforceIntegrationArgs = {
  objects: Array<SalesforceIntegration_Insert_Input>;
  on_conflict?: Maybe<SalesforceIntegration_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_SalesforceIntegration_OneArgs = {
  object: SalesforceIntegration_Insert_Input;
  on_conflict?: Maybe<SalesforceIntegration_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_SalesforceOpportunityArgs = {
  objects: Array<SalesforceOpportunity_Insert_Input>;
  on_conflict?: Maybe<SalesforceOpportunity_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_SalesforceOpportunity_OneArgs = {
  object: SalesforceOpportunity_Insert_Input;
  on_conflict?: Maybe<SalesforceOpportunity_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_SendgridEventArgs = {
  objects: Array<SendgridEvent_Insert_Input>;
  on_conflict?: Maybe<SendgridEvent_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_SendgridEvent_OneArgs = {
  object: SendgridEvent_Insert_Input;
  on_conflict?: Maybe<SendgridEvent_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_TerritoryArgs = {
  objects: Array<Territory_Insert_Input>;
  on_conflict?: Maybe<Territory_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_TerritorySearchTermArgs = {
  objects: Array<TerritorySearchTerm_Insert_Input>;
  on_conflict?: Maybe<TerritorySearchTerm_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_TerritorySearchTerm_OneArgs = {
  object: TerritorySearchTerm_Insert_Input;
  on_conflict?: Maybe<TerritorySearchTerm_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Territory_OneArgs = {
  object: Territory_Insert_Input;
  on_conflict?: Maybe<Territory_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_TheOrgSearchTermArgs = {
  objects: Array<TheOrgSearchTerm_Insert_Input>;
  on_conflict?: Maybe<TheOrgSearchTerm_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_TheOrgSearchTerm_OneArgs = {
  object: TheOrgSearchTerm_Insert_Input;
  on_conflict?: Maybe<TheOrgSearchTerm_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_UserArgs = {
  objects: Array<User_Insert_Input>;
  on_conflict?: Maybe<User_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_UserInviteArgs = {
  objects: Array<UserInvite_Insert_Input>;
  on_conflict?: Maybe<UserInvite_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_UserInvite_OneArgs = {
  object: UserInvite_Insert_Input;
  on_conflict?: Maybe<UserInvite_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_OneArgs = {
  object: User_Insert_Input;
  on_conflict?: Maybe<User_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ZoomCompanyArgs = {
  objects: Array<ZoomCompany_Insert_Input>;
  on_conflict?: Maybe<ZoomCompany_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ZoomCompany_OneArgs = {
  object: ZoomCompany_Insert_Input;
  on_conflict?: Maybe<ZoomCompany_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ZoomContactArgs = {
  objects: Array<ZoomContact_Insert_Input>;
  on_conflict?: Maybe<ZoomContact_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ZoomContactEnrichmentHistoryArgs = {
  objects: Array<ZoomContactEnrichmentHistory_Insert_Input>;
  on_conflict?: Maybe<ZoomContactEnrichmentHistory_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ZoomContactEnrichmentHistory_OneArgs = {
  object: ZoomContactEnrichmentHistory_Insert_Input;
  on_conflict?: Maybe<ZoomContactEnrichmentHistory_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ZoomContactInfoArgs = {
  objects: Array<ZoomContactInfo_Insert_Input>;
  on_conflict?: Maybe<ZoomContactInfo_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ZoomContactInfo_OneArgs = {
  object: ZoomContactInfo_Insert_Input;
  on_conflict?: Maybe<ZoomContactInfo_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ZoomContactNotesArgs = {
  objects: Array<ZoomContactNotes_Insert_Input>;
  on_conflict?: Maybe<ZoomContactNotes_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ZoomContactNotes_OneArgs = {
  object: ZoomContactNotes_Insert_Input;
  on_conflict?: Maybe<ZoomContactNotes_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ZoomContact_OneArgs = {
  object: ZoomContact_Insert_Input;
  on_conflict?: Maybe<ZoomContact_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ZoomEmailHistoryArgs = {
  objects: Array<ZoomEmailHistory_Insert_Input>;
  on_conflict?: Maybe<ZoomEmailHistory_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ZoomEmailHistory_OneArgs = {
  object: ZoomEmailHistory_Insert_Input;
  on_conflict?: Maybe<ZoomEmailHistory_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ZoomEventArgs = {
  objects: Array<ZoomEvent_Insert_Input>;
  on_conflict?: Maybe<ZoomEvent_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ZoomEventParticipantArgs = {
  objects: Array<ZoomEventParticipant_Insert_Input>;
  on_conflict?: Maybe<ZoomEventParticipant_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ZoomEventParticipant_OneArgs = {
  object: ZoomEventParticipant_Insert_Input;
  on_conflict?: Maybe<ZoomEventParticipant_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ZoomEvent_OneArgs = {
  object: ZoomEvent_Insert_Input;
  on_conflict?: Maybe<ZoomEvent_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ZoomExternalAuthUserArgs = {
  objects: Array<ZoomExternalAuthUser_Insert_Input>;
  on_conflict?: Maybe<ZoomExternalAuthUser_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ZoomExternalAuthUser_OneArgs = {
  object: ZoomExternalAuthUser_Insert_Input;
  on_conflict?: Maybe<ZoomExternalAuthUser_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ZoomUserArgs = {
  objects: Array<ZoomUser_Insert_Input>;
  on_conflict?: Maybe<ZoomUser_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ZoomUser_OneArgs = {
  object: ZoomUser_Insert_Input;
  on_conflict?: Maybe<ZoomUser_On_Conflict>;
};

/** mutation root */
export type Mutation_RootUpdate_ClientArgs = {
  _set?: Maybe<Client_Set_Input>;
  where: Client_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_ClientContactArgs = {
  _inc?: Maybe<ClientContact_Inc_Input>;
  _set?: Maybe<ClientContact_Set_Input>;
  where: ClientContact_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_ClientContactCrmDataArgs = {
  _set?: Maybe<ClientContactCrmData_Set_Input>;
  where: ClientContactCrmData_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_ClientContactCrmData_By_PkArgs = {
  _set?: Maybe<ClientContactCrmData_Set_Input>;
  pk_columns: ClientContactCrmData_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_ClientContactFilterArgs = {
  _inc?: Maybe<ClientContactFilter_Inc_Input>;
  _set?: Maybe<ClientContactFilter_Set_Input>;
  where: ClientContactFilter_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_ClientContactFilter_By_PkArgs = {
  _inc?: Maybe<ClientContactFilter_Inc_Input>;
  _set?: Maybe<ClientContactFilter_Set_Input>;
  pk_columns: ClientContactFilter_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_ClientContactJobChangeArgs = {
  _set?: Maybe<ClientContactJobChange_Set_Input>;
  where: ClientContactJobChange_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_ClientContactJobChange_By_PkArgs = {
  _set?: Maybe<ClientContactJobChange_Set_Input>;
  pk_columns: ClientContactJobChange_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_ClientContact_By_PkArgs = {
  _inc?: Maybe<ClientContact_Inc_Input>;
  _set?: Maybe<ClientContact_Set_Input>;
  pk_columns: ClientContact_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_ClientEmailReportArgs = {
  _append?: Maybe<ClientEmailReport_Append_Input>;
  _delete_at_path?: Maybe<ClientEmailReport_Delete_At_Path_Input>;
  _delete_elem?: Maybe<ClientEmailReport_Delete_Elem_Input>;
  _delete_key?: Maybe<ClientEmailReport_Delete_Key_Input>;
  _prepend?: Maybe<ClientEmailReport_Prepend_Input>;
  _set?: Maybe<ClientEmailReport_Set_Input>;
  where: ClientEmailReport_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_ClientEmailReport_By_PkArgs = {
  _append?: Maybe<ClientEmailReport_Append_Input>;
  _delete_at_path?: Maybe<ClientEmailReport_Delete_At_Path_Input>;
  _delete_elem?: Maybe<ClientEmailReport_Delete_Elem_Input>;
  _delete_key?: Maybe<ClientEmailReport_Delete_Key_Input>;
  _prepend?: Maybe<ClientEmailReport_Prepend_Input>;
  _set?: Maybe<ClientEmailReport_Set_Input>;
  pk_columns: ClientEmailReport_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_ClientNotificationEmailArgs = {
  _set?: Maybe<ClientNotificationEmail_Set_Input>;
  where: ClientNotificationEmail_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_ClientNotificationEmail_By_PkArgs = {
  _set?: Maybe<ClientNotificationEmail_Set_Input>;
  pk_columns: ClientNotificationEmail_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_ClientTerritoryArgs = {
  _inc?: Maybe<ClientTerritory_Inc_Input>;
  _set?: Maybe<ClientTerritory_Set_Input>;
  where: ClientTerritory_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_ClientTerritory_By_PkArgs = {
  _inc?: Maybe<ClientTerritory_Inc_Input>;
  _set?: Maybe<ClientTerritory_Set_Input>;
  pk_columns: ClientTerritory_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Client_By_PkArgs = {
  _set?: Maybe<Client_Set_Input>;
  pk_columns: Client_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_CloudTaskArgs = {
  _append?: Maybe<CloudTask_Append_Input>;
  _delete_at_path?: Maybe<CloudTask_Delete_At_Path_Input>;
  _delete_elem?: Maybe<CloudTask_Delete_Elem_Input>;
  _delete_key?: Maybe<CloudTask_Delete_Key_Input>;
  _inc?: Maybe<CloudTask_Inc_Input>;
  _prepend?: Maybe<CloudTask_Prepend_Input>;
  _set?: Maybe<CloudTask_Set_Input>;
  where: CloudTask_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_CloudTask_By_PkArgs = {
  _append?: Maybe<CloudTask_Append_Input>;
  _delete_at_path?: Maybe<CloudTask_Delete_At_Path_Input>;
  _delete_elem?: Maybe<CloudTask_Delete_Elem_Input>;
  _delete_key?: Maybe<CloudTask_Delete_Key_Input>;
  _inc?: Maybe<CloudTask_Inc_Input>;
  _prepend?: Maybe<CloudTask_Prepend_Input>;
  _set?: Maybe<CloudTask_Set_Input>;
  pk_columns: CloudTask_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_CompanyArgs = {
  _inc?: Maybe<Company_Inc_Input>;
  _set?: Maybe<Company_Set_Input>;
  where: Company_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_CompanyNameArgs = {
  _set?: Maybe<CompanyName_Set_Input>;
  where: CompanyName_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_CompanyName_By_PkArgs = {
  _set?: Maybe<CompanyName_Set_Input>;
  pk_columns: CompanyName_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Company_By_PkArgs = {
  _inc?: Maybe<Company_Inc_Input>;
  _set?: Maybe<Company_Set_Input>;
  pk_columns: Company_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_ContactDataArgs = {
  _set?: Maybe<ContactData_Set_Input>;
  where: ContactData_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_ContactData_By_PkArgs = {
  _set?: Maybe<ContactData_Set_Input>;
  pk_columns: ContactData_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_ContactEmailArgs = {
  _set?: Maybe<ContactEmail_Set_Input>;
  where: ContactEmail_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_ContactEmail_By_PkArgs = {
  _set?: Maybe<ContactEmail_Set_Input>;
  pk_columns: ContactEmail_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_CrmContactEnrichmentHistoryArgs = {
  _set?: Maybe<CrmContactEnrichmentHistory_Set_Input>;
  where: CrmContactEnrichmentHistory_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_CrmContactEnrichmentHistory_By_PkArgs = {
  _set?: Maybe<CrmContactEnrichmentHistory_Set_Input>;
  pk_columns: CrmContactEnrichmentHistory_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_CsvUploadArgs = {
  _append?: Maybe<CsvUpload_Append_Input>;
  _delete_at_path?: Maybe<CsvUpload_Delete_At_Path_Input>;
  _delete_elem?: Maybe<CsvUpload_Delete_Elem_Input>;
  _delete_key?: Maybe<CsvUpload_Delete_Key_Input>;
  _inc?: Maybe<CsvUpload_Inc_Input>;
  _prepend?: Maybe<CsvUpload_Prepend_Input>;
  _set?: Maybe<CsvUpload_Set_Input>;
  where: CsvUpload_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_CsvUpload_By_PkArgs = {
  _append?: Maybe<CsvUpload_Append_Input>;
  _delete_at_path?: Maybe<CsvUpload_Delete_At_Path_Input>;
  _delete_elem?: Maybe<CsvUpload_Delete_Elem_Input>;
  _delete_key?: Maybe<CsvUpload_Delete_Key_Input>;
  _inc?: Maybe<CsvUpload_Inc_Input>;
  _prepend?: Maybe<CsvUpload_Prepend_Input>;
  _set?: Maybe<CsvUpload_Set_Input>;
  pk_columns: CsvUpload_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_EmailHistoryArgs = {
  _set?: Maybe<EmailHistory_Set_Input>;
  where: EmailHistory_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_EmailHistory_By_PkArgs = {
  _set?: Maybe<EmailHistory_Set_Input>;
  pk_columns: EmailHistory_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_GoogleChannelArgs = {
  _inc?: Maybe<GoogleChannel_Inc_Input>;
  _set?: Maybe<GoogleChannel_Set_Input>;
  where: GoogleChannel_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_GoogleChannel_By_PkArgs = {
  _inc?: Maybe<GoogleChannel_Inc_Input>;
  _set?: Maybe<GoogleChannel_Set_Input>;
  pk_columns: GoogleChannel_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_HubspotContactListArgs = {
  _append?: Maybe<HubspotContactList_Append_Input>;
  _delete_at_path?: Maybe<HubspotContactList_Delete_At_Path_Input>;
  _delete_elem?: Maybe<HubspotContactList_Delete_Elem_Input>;
  _delete_key?: Maybe<HubspotContactList_Delete_Key_Input>;
  _inc?: Maybe<HubspotContactList_Inc_Input>;
  _prepend?: Maybe<HubspotContactList_Prepend_Input>;
  _set?: Maybe<HubspotContactList_Set_Input>;
  where: HubspotContactList_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_HubspotContactListHubspotContactArgs = {
  _set?: Maybe<HubspotContactListHubspotContact_Set_Input>;
  where: HubspotContactListHubspotContact_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_HubspotContactListHubspotContact_By_PkArgs = {
  _set?: Maybe<HubspotContactListHubspotContact_Set_Input>;
  pk_columns: HubspotContactListHubspotContact_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_HubspotContactList_By_PkArgs = {
  _append?: Maybe<HubspotContactList_Append_Input>;
  _delete_at_path?: Maybe<HubspotContactList_Delete_At_Path_Input>;
  _delete_elem?: Maybe<HubspotContactList_Delete_Elem_Input>;
  _delete_key?: Maybe<HubspotContactList_Delete_Key_Input>;
  _inc?: Maybe<HubspotContactList_Inc_Input>;
  _prepend?: Maybe<HubspotContactList_Prepend_Input>;
  _set?: Maybe<HubspotContactList_Set_Input>;
  pk_columns: HubspotContactList_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_HubspotContactPropertyArgs = {
  _append?: Maybe<HubspotContactProperty_Append_Input>;
  _delete_at_path?: Maybe<HubspotContactProperty_Delete_At_Path_Input>;
  _delete_elem?: Maybe<HubspotContactProperty_Delete_Elem_Input>;
  _delete_key?: Maybe<HubspotContactProperty_Delete_Key_Input>;
  _prepend?: Maybe<HubspotContactProperty_Prepend_Input>;
  _set?: Maybe<HubspotContactProperty_Set_Input>;
  where: HubspotContactProperty_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_HubspotContactProperty_By_PkArgs = {
  _append?: Maybe<HubspotContactProperty_Append_Input>;
  _delete_at_path?: Maybe<HubspotContactProperty_Delete_At_Path_Input>;
  _delete_elem?: Maybe<HubspotContactProperty_Delete_Elem_Input>;
  _delete_key?: Maybe<HubspotContactProperty_Delete_Key_Input>;
  _prepend?: Maybe<HubspotContactProperty_Prepend_Input>;
  _set?: Maybe<HubspotContactProperty_Set_Input>;
  pk_columns: HubspotContactProperty_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_HubspotIntegrationArgs = {
  _append?: Maybe<HubspotIntegration_Append_Input>;
  _delete_at_path?: Maybe<HubspotIntegration_Delete_At_Path_Input>;
  _delete_elem?: Maybe<HubspotIntegration_Delete_Elem_Input>;
  _delete_key?: Maybe<HubspotIntegration_Delete_Key_Input>;
  _inc?: Maybe<HubspotIntegration_Inc_Input>;
  _prepend?: Maybe<HubspotIntegration_Prepend_Input>;
  _set?: Maybe<HubspotIntegration_Set_Input>;
  where: HubspotIntegration_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_HubspotIntegration_By_PkArgs = {
  _append?: Maybe<HubspotIntegration_Append_Input>;
  _delete_at_path?: Maybe<HubspotIntegration_Delete_At_Path_Input>;
  _delete_elem?: Maybe<HubspotIntegration_Delete_Elem_Input>;
  _delete_key?: Maybe<HubspotIntegration_Delete_Key_Input>;
  _inc?: Maybe<HubspotIntegration_Inc_Input>;
  _prepend?: Maybe<HubspotIntegration_Prepend_Input>;
  _set?: Maybe<HubspotIntegration_Set_Input>;
  pk_columns: HubspotIntegration_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_JobArgs = {
  _set?: Maybe<Job_Set_Input>;
  where: Job_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Job_By_PkArgs = {
  _set?: Maybe<Job_Set_Input>;
  pk_columns: Job_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_ProxycrawlDataArgs = {
  _append?: Maybe<ProxycrawlData_Append_Input>;
  _delete_at_path?: Maybe<ProxycrawlData_Delete_At_Path_Input>;
  _delete_elem?: Maybe<ProxycrawlData_Delete_Elem_Input>;
  _delete_key?: Maybe<ProxycrawlData_Delete_Key_Input>;
  _prepend?: Maybe<ProxycrawlData_Prepend_Input>;
  _set?: Maybe<ProxycrawlData_Set_Input>;
  where: ProxycrawlData_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_ProxycrawlData_By_PkArgs = {
  _append?: Maybe<ProxycrawlData_Append_Input>;
  _delete_at_path?: Maybe<ProxycrawlData_Delete_At_Path_Input>;
  _delete_elem?: Maybe<ProxycrawlData_Delete_Elem_Input>;
  _delete_key?: Maybe<ProxycrawlData_Delete_Key_Input>;
  _prepend?: Maybe<ProxycrawlData_Prepend_Input>;
  _set?: Maybe<ProxycrawlData_Set_Input>;
  pk_columns: ProxycrawlData_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_QaQualificationArgs = {
  _set?: Maybe<QaQualification_Set_Input>;
  where: QaQualification_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_QaQualification_By_PkArgs = {
  _set?: Maybe<QaQualification_Set_Input>;
  pk_columns: QaQualification_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_QaTaskArgs = {
  _inc?: Maybe<QaTask_Inc_Input>;
  _set?: Maybe<QaTask_Set_Input>;
  where: QaTask_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_QaTaskActionArgs = {
  _inc?: Maybe<QaTaskAction_Inc_Input>;
  _set?: Maybe<QaTaskAction_Set_Input>;
  where: QaTaskAction_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_QaTaskAction_By_PkArgs = {
  _inc?: Maybe<QaTaskAction_Inc_Input>;
  _set?: Maybe<QaTaskAction_Set_Input>;
  pk_columns: QaTaskAction_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_QaTask_By_PkArgs = {
  _inc?: Maybe<QaTask_Inc_Input>;
  _set?: Maybe<QaTask_Set_Input>;
  pk_columns: QaTask_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_SalesforceAccountArgs = {
  _set?: Maybe<SalesforceAccount_Set_Input>;
  where: SalesforceAccount_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_SalesforceAccount_By_PkArgs = {
  _set?: Maybe<SalesforceAccount_Set_Input>;
  pk_columns: SalesforceAccount_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_SalesforceContactFieldArgs = {
  _append?: Maybe<SalesforceContactField_Append_Input>;
  _delete_at_path?: Maybe<SalesforceContactField_Delete_At_Path_Input>;
  _delete_elem?: Maybe<SalesforceContactField_Delete_Elem_Input>;
  _delete_key?: Maybe<SalesforceContactField_Delete_Key_Input>;
  _prepend?: Maybe<SalesforceContactField_Prepend_Input>;
  _set?: Maybe<SalesforceContactField_Set_Input>;
  where: SalesforceContactField_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_SalesforceContactField_By_PkArgs = {
  _append?: Maybe<SalesforceContactField_Append_Input>;
  _delete_at_path?: Maybe<SalesforceContactField_Delete_At_Path_Input>;
  _delete_elem?: Maybe<SalesforceContactField_Delete_Elem_Input>;
  _delete_key?: Maybe<SalesforceContactField_Delete_Key_Input>;
  _prepend?: Maybe<SalesforceContactField_Prepend_Input>;
  _set?: Maybe<SalesforceContactField_Set_Input>;
  pk_columns: SalesforceContactField_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_SalesforceIntegrationArgs = {
  _append?: Maybe<SalesforceIntegration_Append_Input>;
  _delete_at_path?: Maybe<SalesforceIntegration_Delete_At_Path_Input>;
  _delete_elem?: Maybe<SalesforceIntegration_Delete_Elem_Input>;
  _delete_key?: Maybe<SalesforceIntegration_Delete_Key_Input>;
  _inc?: Maybe<SalesforceIntegration_Inc_Input>;
  _prepend?: Maybe<SalesforceIntegration_Prepend_Input>;
  _set?: Maybe<SalesforceIntegration_Set_Input>;
  where: SalesforceIntegration_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_SalesforceIntegration_By_PkArgs = {
  _append?: Maybe<SalesforceIntegration_Append_Input>;
  _delete_at_path?: Maybe<SalesforceIntegration_Delete_At_Path_Input>;
  _delete_elem?: Maybe<SalesforceIntegration_Delete_Elem_Input>;
  _delete_key?: Maybe<SalesforceIntegration_Delete_Key_Input>;
  _inc?: Maybe<SalesforceIntegration_Inc_Input>;
  _prepend?: Maybe<SalesforceIntegration_Prepend_Input>;
  _set?: Maybe<SalesforceIntegration_Set_Input>;
  pk_columns: SalesforceIntegration_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_SalesforceOpportunityArgs = {
  _set?: Maybe<SalesforceOpportunity_Set_Input>;
  where: SalesforceOpportunity_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_SalesforceOpportunity_By_PkArgs = {
  _set?: Maybe<SalesforceOpportunity_Set_Input>;
  pk_columns: SalesforceOpportunity_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_SendgridEventArgs = {
  _append?: Maybe<SendgridEvent_Append_Input>;
  _delete_at_path?: Maybe<SendgridEvent_Delete_At_Path_Input>;
  _delete_elem?: Maybe<SendgridEvent_Delete_Elem_Input>;
  _delete_key?: Maybe<SendgridEvent_Delete_Key_Input>;
  _prepend?: Maybe<SendgridEvent_Prepend_Input>;
  _set?: Maybe<SendgridEvent_Set_Input>;
  where: SendgridEvent_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_SendgridEvent_By_PkArgs = {
  _append?: Maybe<SendgridEvent_Append_Input>;
  _delete_at_path?: Maybe<SendgridEvent_Delete_At_Path_Input>;
  _delete_elem?: Maybe<SendgridEvent_Delete_Elem_Input>;
  _delete_key?: Maybe<SendgridEvent_Delete_Key_Input>;
  _prepend?: Maybe<SendgridEvent_Prepend_Input>;
  _set?: Maybe<SendgridEvent_Set_Input>;
  pk_columns: SendgridEvent_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_TerritoryArgs = {
  _append?: Maybe<Territory_Append_Input>;
  _delete_at_path?: Maybe<Territory_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Territory_Delete_Elem_Input>;
  _delete_key?: Maybe<Territory_Delete_Key_Input>;
  _prepend?: Maybe<Territory_Prepend_Input>;
  _set?: Maybe<Territory_Set_Input>;
  where: Territory_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_TerritorySearchTermArgs = {
  _set?: Maybe<TerritorySearchTerm_Set_Input>;
  where: TerritorySearchTerm_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_TerritorySearchTerm_By_PkArgs = {
  _set?: Maybe<TerritorySearchTerm_Set_Input>;
  pk_columns: TerritorySearchTerm_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Territory_By_PkArgs = {
  _append?: Maybe<Territory_Append_Input>;
  _delete_at_path?: Maybe<Territory_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Territory_Delete_Elem_Input>;
  _delete_key?: Maybe<Territory_Delete_Key_Input>;
  _prepend?: Maybe<Territory_Prepend_Input>;
  _set?: Maybe<Territory_Set_Input>;
  pk_columns: Territory_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_TheOrgSearchTermArgs = {
  _append?: Maybe<TheOrgSearchTerm_Append_Input>;
  _delete_at_path?: Maybe<TheOrgSearchTerm_Delete_At_Path_Input>;
  _delete_elem?: Maybe<TheOrgSearchTerm_Delete_Elem_Input>;
  _delete_key?: Maybe<TheOrgSearchTerm_Delete_Key_Input>;
  _prepend?: Maybe<TheOrgSearchTerm_Prepend_Input>;
  _set?: Maybe<TheOrgSearchTerm_Set_Input>;
  where: TheOrgSearchTerm_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_TheOrgSearchTerm_By_PkArgs = {
  _append?: Maybe<TheOrgSearchTerm_Append_Input>;
  _delete_at_path?: Maybe<TheOrgSearchTerm_Delete_At_Path_Input>;
  _delete_elem?: Maybe<TheOrgSearchTerm_Delete_Elem_Input>;
  _delete_key?: Maybe<TheOrgSearchTerm_Delete_Key_Input>;
  _prepend?: Maybe<TheOrgSearchTerm_Prepend_Input>;
  _set?: Maybe<TheOrgSearchTerm_Set_Input>;
  pk_columns: TheOrgSearchTerm_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_UserArgs = {
  _append?: Maybe<User_Append_Input>;
  _delete_at_path?: Maybe<User_Delete_At_Path_Input>;
  _delete_elem?: Maybe<User_Delete_Elem_Input>;
  _delete_key?: Maybe<User_Delete_Key_Input>;
  _prepend?: Maybe<User_Prepend_Input>;
  _set?: Maybe<User_Set_Input>;
  where: User_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_UserInviteArgs = {
  _set?: Maybe<UserInvite_Set_Input>;
  where: UserInvite_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_UserInvite_By_PkArgs = {
  _set?: Maybe<UserInvite_Set_Input>;
  pk_columns: UserInvite_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_User_By_PkArgs = {
  _append?: Maybe<User_Append_Input>;
  _delete_at_path?: Maybe<User_Delete_At_Path_Input>;
  _delete_elem?: Maybe<User_Delete_Elem_Input>;
  _delete_key?: Maybe<User_Delete_Key_Input>;
  _prepend?: Maybe<User_Prepend_Input>;
  _set?: Maybe<User_Set_Input>;
  pk_columns: User_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomCompanyArgs = {
  _set?: Maybe<ZoomCompany_Set_Input>;
  where: ZoomCompany_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomCompany_By_PkArgs = {
  _set?: Maybe<ZoomCompany_Set_Input>;
  pk_columns: ZoomCompany_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomContactArgs = {
  _set?: Maybe<ZoomContact_Set_Input>;
  where: ZoomContact_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomContactEnrichmentHistoryArgs = {
  _set?: Maybe<ZoomContactEnrichmentHistory_Set_Input>;
  where: ZoomContactEnrichmentHistory_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomContactEnrichmentHistory_By_PkArgs = {
  _set?: Maybe<ZoomContactEnrichmentHistory_Set_Input>;
  pk_columns: ZoomContactEnrichmentHistory_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomContactInfoArgs = {
  _set?: Maybe<ZoomContactInfo_Set_Input>;
  where: ZoomContactInfo_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomContactInfo_By_PkArgs = {
  _set?: Maybe<ZoomContactInfo_Set_Input>;
  pk_columns: ZoomContactInfo_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomContactNotesArgs = {
  _append?: Maybe<ZoomContactNotes_Append_Input>;
  _delete_at_path?: Maybe<ZoomContactNotes_Delete_At_Path_Input>;
  _delete_elem?: Maybe<ZoomContactNotes_Delete_Elem_Input>;
  _delete_key?: Maybe<ZoomContactNotes_Delete_Key_Input>;
  _prepend?: Maybe<ZoomContactNotes_Prepend_Input>;
  _set?: Maybe<ZoomContactNotes_Set_Input>;
  where: ZoomContactNotes_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomContactNotes_By_PkArgs = {
  _append?: Maybe<ZoomContactNotes_Append_Input>;
  _delete_at_path?: Maybe<ZoomContactNotes_Delete_At_Path_Input>;
  _delete_elem?: Maybe<ZoomContactNotes_Delete_Elem_Input>;
  _delete_key?: Maybe<ZoomContactNotes_Delete_Key_Input>;
  _prepend?: Maybe<ZoomContactNotes_Prepend_Input>;
  _set?: Maybe<ZoomContactNotes_Set_Input>;
  pk_columns: ZoomContactNotes_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomContact_By_PkArgs = {
  _set?: Maybe<ZoomContact_Set_Input>;
  pk_columns: ZoomContact_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomEmailHistoryArgs = {
  _append?: Maybe<ZoomEmailHistory_Append_Input>;
  _delete_at_path?: Maybe<ZoomEmailHistory_Delete_At_Path_Input>;
  _delete_elem?: Maybe<ZoomEmailHistory_Delete_Elem_Input>;
  _delete_key?: Maybe<ZoomEmailHistory_Delete_Key_Input>;
  _inc?: Maybe<ZoomEmailHistory_Inc_Input>;
  _prepend?: Maybe<ZoomEmailHistory_Prepend_Input>;
  _set?: Maybe<ZoomEmailHistory_Set_Input>;
  where: ZoomEmailHistory_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomEmailHistory_By_PkArgs = {
  _append?: Maybe<ZoomEmailHistory_Append_Input>;
  _delete_at_path?: Maybe<ZoomEmailHistory_Delete_At_Path_Input>;
  _delete_elem?: Maybe<ZoomEmailHistory_Delete_Elem_Input>;
  _delete_key?: Maybe<ZoomEmailHistory_Delete_Key_Input>;
  _inc?: Maybe<ZoomEmailHistory_Inc_Input>;
  _prepend?: Maybe<ZoomEmailHistory_Prepend_Input>;
  _set?: Maybe<ZoomEmailHistory_Set_Input>;
  pk_columns: ZoomEmailHistory_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomEventArgs = {
  _append?: Maybe<ZoomEvent_Append_Input>;
  _delete_at_path?: Maybe<ZoomEvent_Delete_At_Path_Input>;
  _delete_elem?: Maybe<ZoomEvent_Delete_Elem_Input>;
  _delete_key?: Maybe<ZoomEvent_Delete_Key_Input>;
  _inc?: Maybe<ZoomEvent_Inc_Input>;
  _prepend?: Maybe<ZoomEvent_Prepend_Input>;
  _set?: Maybe<ZoomEvent_Set_Input>;
  where: ZoomEvent_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomEventParticipantArgs = {
  _inc?: Maybe<ZoomEventParticipant_Inc_Input>;
  _set?: Maybe<ZoomEventParticipant_Set_Input>;
  where: ZoomEventParticipant_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomEventParticipant_By_PkArgs = {
  _inc?: Maybe<ZoomEventParticipant_Inc_Input>;
  _set?: Maybe<ZoomEventParticipant_Set_Input>;
  pk_columns: ZoomEventParticipant_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomEvent_By_PkArgs = {
  _append?: Maybe<ZoomEvent_Append_Input>;
  _delete_at_path?: Maybe<ZoomEvent_Delete_At_Path_Input>;
  _delete_elem?: Maybe<ZoomEvent_Delete_Elem_Input>;
  _delete_key?: Maybe<ZoomEvent_Delete_Key_Input>;
  _inc?: Maybe<ZoomEvent_Inc_Input>;
  _prepend?: Maybe<ZoomEvent_Prepend_Input>;
  _set?: Maybe<ZoomEvent_Set_Input>;
  pk_columns: ZoomEvent_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomExternalAuthUserArgs = {
  _set?: Maybe<ZoomExternalAuthUser_Set_Input>;
  where: ZoomExternalAuthUser_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomExternalAuthUser_By_PkArgs = {
  _set?: Maybe<ZoomExternalAuthUser_Set_Input>;
  pk_columns: ZoomExternalAuthUser_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomUserArgs = {
  _set?: Maybe<ZoomUser_Set_Input>;
  where: ZoomUser_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_ZoomUser_By_PkArgs = {
  _set?: Maybe<ZoomUser_Set_Input>;
  pk_columns: ZoomUser_Pk_Columns_Input;
};

/** column ordering options */
export enum Order_By {
  /** in the ascending order, nulls last */
  Asc = 'asc',
  /** in the ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in the ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in the descending order, nulls first */
  Desc = 'desc',
  /** in the descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in the descending order, nulls last */
  DescNullsLast = 'desc_nulls_last',
}

/** columns and relationships of "proxycrawlData" */
export type ProxycrawlData = {
  __typename?: 'proxycrawlData';
  contactDataId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  linkedinDataJson?: Maybe<Scalars['jsonb']>;
  linkedinUrl: Scalars['String'];
  proxycrawlCompletedAt?: Maybe<Scalars['timestamptz']>;
  proxycrawlRequestSentAt?: Maybe<Scalars['timestamptz']>;
  rid: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  statusDescription?: Maybe<Scalars['String']>;
  statusUpdatedAt?: Maybe<Scalars['timestamptz']>;
};

/** columns and relationships of "proxycrawlData" */
export type ProxycrawlDataLinkedinDataJsonArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "proxycrawlData" */
export type ProxycrawlData_Aggregate = {
  __typename?: 'proxycrawlData_aggregate';
  aggregate?: Maybe<ProxycrawlData_Aggregate_Fields>;
  nodes: Array<ProxycrawlData>;
};

/** aggregate fields of "proxycrawlData" */
export type ProxycrawlData_Aggregate_Fields = {
  __typename?: 'proxycrawlData_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<ProxycrawlData_Max_Fields>;
  min?: Maybe<ProxycrawlData_Min_Fields>;
};

/** aggregate fields of "proxycrawlData" */
export type ProxycrawlData_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<ProxycrawlData_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "proxycrawlData" */
export type ProxycrawlData_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<ProxycrawlData_Max_Order_By>;
  min?: Maybe<ProxycrawlData_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ProxycrawlData_Append_Input = {
  linkedinDataJson?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "proxycrawlData" */
export type ProxycrawlData_Arr_Rel_Insert_Input = {
  data: Array<ProxycrawlData_Insert_Input>;
  on_conflict?: Maybe<ProxycrawlData_On_Conflict>;
};

/** Boolean expression to filter rows from the table "proxycrawlData". All fields are combined with a logical 'AND'. */
export type ProxycrawlData_Bool_Exp = {
  _and?: Maybe<Array<Maybe<ProxycrawlData_Bool_Exp>>>;
  _not?: Maybe<ProxycrawlData_Bool_Exp>;
  _or?: Maybe<Array<Maybe<ProxycrawlData_Bool_Exp>>>;
  contactDataId?: Maybe<Uuid_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  linkedinDataJson?: Maybe<Jsonb_Comparison_Exp>;
  linkedinUrl?: Maybe<String_Comparison_Exp>;
  proxycrawlCompletedAt?: Maybe<Timestamptz_Comparison_Exp>;
  proxycrawlRequestSentAt?: Maybe<Timestamptz_Comparison_Exp>;
  rid?: Maybe<String_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  statusDescription?: Maybe<String_Comparison_Exp>;
  statusUpdatedAt?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "proxycrawlData" */
export enum ProxycrawlData_Constraint {
  /** unique or primary key constraint */
  ProxycrawlDataPkey = 'proxycrawlData_pkey',
  /** unique or primary key constraint */
  ProxycrawlDataRidKey = 'proxycrawlData_rid_key',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ProxycrawlData_Delete_At_Path_Input = {
  linkedinDataJson?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ProxycrawlData_Delete_Elem_Input = {
  linkedinDataJson?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ProxycrawlData_Delete_Key_Input = {
  linkedinDataJson?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "proxycrawlData" */
export type ProxycrawlData_Insert_Input = {
  contactDataId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  linkedinDataJson?: Maybe<Scalars['jsonb']>;
  linkedinUrl?: Maybe<Scalars['String']>;
  proxycrawlCompletedAt?: Maybe<Scalars['timestamptz']>;
  proxycrawlRequestSentAt?: Maybe<Scalars['timestamptz']>;
  rid?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  statusDescription?: Maybe<Scalars['String']>;
  statusUpdatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type ProxycrawlData_Max_Fields = {
  __typename?: 'proxycrawlData_max_fields';
  contactDataId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  linkedinUrl?: Maybe<Scalars['String']>;
  proxycrawlCompletedAt?: Maybe<Scalars['timestamptz']>;
  proxycrawlRequestSentAt?: Maybe<Scalars['timestamptz']>;
  rid?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  statusDescription?: Maybe<Scalars['String']>;
  statusUpdatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "proxycrawlData" */
export type ProxycrawlData_Max_Order_By = {
  contactDataId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  linkedinUrl?: Maybe<Order_By>;
  proxycrawlCompletedAt?: Maybe<Order_By>;
  proxycrawlRequestSentAt?: Maybe<Order_By>;
  rid?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  statusDescription?: Maybe<Order_By>;
  statusUpdatedAt?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type ProxycrawlData_Min_Fields = {
  __typename?: 'proxycrawlData_min_fields';
  contactDataId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  linkedinUrl?: Maybe<Scalars['String']>;
  proxycrawlCompletedAt?: Maybe<Scalars['timestamptz']>;
  proxycrawlRequestSentAt?: Maybe<Scalars['timestamptz']>;
  rid?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  statusDescription?: Maybe<Scalars['String']>;
  statusUpdatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "proxycrawlData" */
export type ProxycrawlData_Min_Order_By = {
  contactDataId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  linkedinUrl?: Maybe<Order_By>;
  proxycrawlCompletedAt?: Maybe<Order_By>;
  proxycrawlRequestSentAt?: Maybe<Order_By>;
  rid?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  statusDescription?: Maybe<Order_By>;
  statusUpdatedAt?: Maybe<Order_By>;
};

/** response of any mutation on the table "proxycrawlData" */
export type ProxycrawlData_Mutation_Response = {
  __typename?: 'proxycrawlData_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<ProxycrawlData>;
};

/** input type for inserting object relation for remote table "proxycrawlData" */
export type ProxycrawlData_Obj_Rel_Insert_Input = {
  data: ProxycrawlData_Insert_Input;
  on_conflict?: Maybe<ProxycrawlData_On_Conflict>;
};

/** on conflict condition type for table "proxycrawlData" */
export type ProxycrawlData_On_Conflict = {
  constraint: ProxycrawlData_Constraint;
  update_columns: Array<ProxycrawlData_Update_Column>;
  where?: Maybe<ProxycrawlData_Bool_Exp>;
};

/** ordering options when selecting data from "proxycrawlData" */
export type ProxycrawlData_Order_By = {
  contactDataId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  linkedinDataJson?: Maybe<Order_By>;
  linkedinUrl?: Maybe<Order_By>;
  proxycrawlCompletedAt?: Maybe<Order_By>;
  proxycrawlRequestSentAt?: Maybe<Order_By>;
  rid?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  statusDescription?: Maybe<Order_By>;
  statusUpdatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: "proxycrawlData" */
export type ProxycrawlData_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ProxycrawlData_Prepend_Input = {
  linkedinDataJson?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "proxycrawlData" */
export enum ProxycrawlData_Select_Column {
  /** column name */
  ContactDataId = 'contactDataId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  LinkedinDataJson = 'linkedinDataJson',
  /** column name */
  LinkedinUrl = 'linkedinUrl',
  /** column name */
  ProxycrawlCompletedAt = 'proxycrawlCompletedAt',
  /** column name */
  ProxycrawlRequestSentAt = 'proxycrawlRequestSentAt',
  /** column name */
  Rid = 'rid',
  /** column name */
  Status = 'status',
  /** column name */
  StatusDescription = 'statusDescription',
  /** column name */
  StatusUpdatedAt = 'statusUpdatedAt',
}

/** input type for updating data in table "proxycrawlData" */
export type ProxycrawlData_Set_Input = {
  contactDataId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  linkedinDataJson?: Maybe<Scalars['jsonb']>;
  linkedinUrl?: Maybe<Scalars['String']>;
  proxycrawlCompletedAt?: Maybe<Scalars['timestamptz']>;
  proxycrawlRequestSentAt?: Maybe<Scalars['timestamptz']>;
  rid?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  statusDescription?: Maybe<Scalars['String']>;
  statusUpdatedAt?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "proxycrawlData" */
export enum ProxycrawlData_Update_Column {
  /** column name */
  ContactDataId = 'contactDataId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  LinkedinDataJson = 'linkedinDataJson',
  /** column name */
  LinkedinUrl = 'linkedinUrl',
  /** column name */
  ProxycrawlCompletedAt = 'proxycrawlCompletedAt',
  /** column name */
  ProxycrawlRequestSentAt = 'proxycrawlRequestSentAt',
  /** column name */
  Rid = 'rid',
  /** column name */
  Status = 'status',
  /** column name */
  StatusDescription = 'statusDescription',
  /** column name */
  StatusUpdatedAt = 'statusUpdatedAt',
}

/** columns and relationships of "qaQualification" */
export type QaQualification = {
  __typename?: 'qaQualification';
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  qualification: Scalars['String'];
  userId: Scalars['uuid'];
};

/** aggregated selection of "qaQualification" */
export type QaQualification_Aggregate = {
  __typename?: 'qaQualification_aggregate';
  aggregate?: Maybe<QaQualification_Aggregate_Fields>;
  nodes: Array<QaQualification>;
};

/** aggregate fields of "qaQualification" */
export type QaQualification_Aggregate_Fields = {
  __typename?: 'qaQualification_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<QaQualification_Max_Fields>;
  min?: Maybe<QaQualification_Min_Fields>;
};

/** aggregate fields of "qaQualification" */
export type QaQualification_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<QaQualification_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "qaQualification" */
export type QaQualification_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<QaQualification_Max_Order_By>;
  min?: Maybe<QaQualification_Min_Order_By>;
};

/** input type for inserting array relation for remote table "qaQualification" */
export type QaQualification_Arr_Rel_Insert_Input = {
  data: Array<QaQualification_Insert_Input>;
  on_conflict?: Maybe<QaQualification_On_Conflict>;
};

/** Boolean expression to filter rows from the table "qaQualification". All fields are combined with a logical 'AND'. */
export type QaQualification_Bool_Exp = {
  _and?: Maybe<Array<Maybe<QaQualification_Bool_Exp>>>;
  _not?: Maybe<QaQualification_Bool_Exp>;
  _or?: Maybe<Array<Maybe<QaQualification_Bool_Exp>>>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  qualification?: Maybe<String_Comparison_Exp>;
  userId?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "qaQualification" */
export enum QaQualification_Constraint {
  /** unique or primary key constraint */
  QaQualificationPkey = 'qaQualification_pkey',
}

/** input type for inserting data into table "qaQualification" */
export type QaQualification_Insert_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  qualification?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type QaQualification_Max_Fields = {
  __typename?: 'qaQualification_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  qualification?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "qaQualification" */
export type QaQualification_Max_Order_By = {
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  qualification?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type QaQualification_Min_Fields = {
  __typename?: 'qaQualification_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  qualification?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "qaQualification" */
export type QaQualification_Min_Order_By = {
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  qualification?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** response of any mutation on the table "qaQualification" */
export type QaQualification_Mutation_Response = {
  __typename?: 'qaQualification_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<QaQualification>;
};

/** input type for inserting object relation for remote table "qaQualification" */
export type QaQualification_Obj_Rel_Insert_Input = {
  data: QaQualification_Insert_Input;
  on_conflict?: Maybe<QaQualification_On_Conflict>;
};

/** on conflict condition type for table "qaQualification" */
export type QaQualification_On_Conflict = {
  constraint: QaQualification_Constraint;
  update_columns: Array<QaQualification_Update_Column>;
  where?: Maybe<QaQualification_Bool_Exp>;
};

/** ordering options when selecting data from "qaQualification" */
export type QaQualification_Order_By = {
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  qualification?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** primary key columns input for table: "qaQualification" */
export type QaQualification_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "qaQualification" */
export enum QaQualification_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Qualification = 'qualification',
  /** column name */
  UserId = 'userId',
}

/** input type for updating data in table "qaQualification" */
export type QaQualification_Set_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  qualification?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** update columns of table "qaQualification" */
export enum QaQualification_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Qualification = 'qualification',
  /** column name */
  UserId = 'userId',
}

/** columns and relationships of "qaTask" */
export type QaTask = {
  __typename?: 'qaTask';
  /** An object relationship */
  assignedUser?: Maybe<User>;
  assignedUserId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  clientContact?: Maybe<ClientContact>;
  clientContactId?: Maybe<Scalars['uuid']>;
  clientId?: Maybe<Scalars['uuid']>;
  completedAt?: Maybe<Scalars['timestamptz']>;
  createdAt: Scalars['timestamptz'];
  dueAt?: Maybe<Scalars['timestamptz']>;
  firestoreId?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  isEscalated?: Maybe<Scalars['Boolean']>;
  lastFetchedAt?: Maybe<Scalars['timestamptz']>;
  previousQaTaskId?: Maybe<Scalars['uuid']>;
  priority?: Maybe<Scalars['Int']>;
  /** An array relationship */
  qaTaskActions: Array<QaTaskAction>;
  /** An aggregated array relationship */
  qaTaskActions_aggregate: QaTaskAction_Aggregate;
  status: Scalars['String'];
  taskType?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
};

/** columns and relationships of "qaTask" */
export type QaTaskQaTaskActionsArgs = {
  distinct_on?: Maybe<Array<QaTaskAction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<QaTaskAction_Order_By>>;
  where?: Maybe<QaTaskAction_Bool_Exp>;
};

/** columns and relationships of "qaTask" */
export type QaTaskQaTaskActions_AggregateArgs = {
  distinct_on?: Maybe<Array<QaTaskAction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<QaTaskAction_Order_By>>;
  where?: Maybe<QaTaskAction_Bool_Exp>;
};

/** columns and relationships of "qaTaskAction" */
export type QaTaskAction = {
  __typename?: 'qaTaskAction';
  actionType?: Maybe<Scalars['String']>;
  assessment?: Maybe<Scalars['String']>;
  clientContactId?: Maybe<Scalars['uuid']>;
  completedAt?: Maybe<Scalars['timestamptz']>;
  createdAt: Scalars['timestamptz'];
  executionTime?: Maybe<Scalars['Int']>;
  id: Scalars['uuid'];
  jobChangeStatus?: Maybe<Scalars['String']>;
  linkedinUrl?: Maybe<Scalars['String']>;
  linkedinUrlStatus?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  previousLinkedinJob?: Maybe<Scalars['String']>;
  previousQaTaskActionId?: Maybe<Scalars['uuid']>;
  qaTaskId: Scalars['uuid'];
  qaUserId?: Maybe<Scalars['uuid']>;
  startedAt?: Maybe<Scalars['timestamptz']>;
  updatedEmail?: Maybe<Scalars['String']>;
  updatedLinkedinJob?: Maybe<Scalars['String']>;
};

/** aggregated selection of "qaTaskAction" */
export type QaTaskAction_Aggregate = {
  __typename?: 'qaTaskAction_aggregate';
  aggregate?: Maybe<QaTaskAction_Aggregate_Fields>;
  nodes: Array<QaTaskAction>;
};

/** aggregate fields of "qaTaskAction" */
export type QaTaskAction_Aggregate_Fields = {
  __typename?: 'qaTaskAction_aggregate_fields';
  avg?: Maybe<QaTaskAction_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<QaTaskAction_Max_Fields>;
  min?: Maybe<QaTaskAction_Min_Fields>;
  stddev?: Maybe<QaTaskAction_Stddev_Fields>;
  stddev_pop?: Maybe<QaTaskAction_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<QaTaskAction_Stddev_Samp_Fields>;
  sum?: Maybe<QaTaskAction_Sum_Fields>;
  var_pop?: Maybe<QaTaskAction_Var_Pop_Fields>;
  var_samp?: Maybe<QaTaskAction_Var_Samp_Fields>;
  variance?: Maybe<QaTaskAction_Variance_Fields>;
};

/** aggregate fields of "qaTaskAction" */
export type QaTaskAction_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<QaTaskAction_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "qaTaskAction" */
export type QaTaskAction_Aggregate_Order_By = {
  avg?: Maybe<QaTaskAction_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<QaTaskAction_Max_Order_By>;
  min?: Maybe<QaTaskAction_Min_Order_By>;
  stddev?: Maybe<QaTaskAction_Stddev_Order_By>;
  stddev_pop?: Maybe<QaTaskAction_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<QaTaskAction_Stddev_Samp_Order_By>;
  sum?: Maybe<QaTaskAction_Sum_Order_By>;
  var_pop?: Maybe<QaTaskAction_Var_Pop_Order_By>;
  var_samp?: Maybe<QaTaskAction_Var_Samp_Order_By>;
  variance?: Maybe<QaTaskAction_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "qaTaskAction" */
export type QaTaskAction_Arr_Rel_Insert_Input = {
  data: Array<QaTaskAction_Insert_Input>;
  on_conflict?: Maybe<QaTaskAction_On_Conflict>;
};

/** aggregate avg on columns */
export type QaTaskAction_Avg_Fields = {
  __typename?: 'qaTaskAction_avg_fields';
  executionTime?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "qaTaskAction" */
export type QaTaskAction_Avg_Order_By = {
  executionTime?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "qaTaskAction". All fields are combined with a logical 'AND'. */
export type QaTaskAction_Bool_Exp = {
  _and?: Maybe<Array<Maybe<QaTaskAction_Bool_Exp>>>;
  _not?: Maybe<QaTaskAction_Bool_Exp>;
  _or?: Maybe<Array<Maybe<QaTaskAction_Bool_Exp>>>;
  actionType?: Maybe<String_Comparison_Exp>;
  assessment?: Maybe<String_Comparison_Exp>;
  clientContactId?: Maybe<Uuid_Comparison_Exp>;
  completedAt?: Maybe<Timestamptz_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  executionTime?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  jobChangeStatus?: Maybe<String_Comparison_Exp>;
  linkedinUrl?: Maybe<String_Comparison_Exp>;
  linkedinUrlStatus?: Maybe<String_Comparison_Exp>;
  notes?: Maybe<String_Comparison_Exp>;
  previousLinkedinJob?: Maybe<String_Comparison_Exp>;
  previousQaTaskActionId?: Maybe<Uuid_Comparison_Exp>;
  qaTaskId?: Maybe<Uuid_Comparison_Exp>;
  qaUserId?: Maybe<Uuid_Comparison_Exp>;
  startedAt?: Maybe<Timestamptz_Comparison_Exp>;
  updatedEmail?: Maybe<String_Comparison_Exp>;
  updatedLinkedinJob?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "qaTaskAction" */
export enum QaTaskAction_Constraint {
  /** unique or primary key constraint */
  QaTaskActionPkey = 'qaTaskAction_pkey',
  /** unique or primary key constraint */
  QaTaskActionQaTaskIdQaUserIdClientContactIdKey = 'qaTaskAction_qaTaskId_qaUserId_clientContactId_key',
}

/** input type for incrementing integer column in table "qaTaskAction" */
export type QaTaskAction_Inc_Input = {
  executionTime?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "qaTaskAction" */
export type QaTaskAction_Insert_Input = {
  actionType?: Maybe<Scalars['String']>;
  assessment?: Maybe<Scalars['String']>;
  clientContactId?: Maybe<Scalars['uuid']>;
  completedAt?: Maybe<Scalars['timestamptz']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  executionTime?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  jobChangeStatus?: Maybe<Scalars['String']>;
  linkedinUrl?: Maybe<Scalars['String']>;
  linkedinUrlStatus?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  previousLinkedinJob?: Maybe<Scalars['String']>;
  previousQaTaskActionId?: Maybe<Scalars['uuid']>;
  qaTaskId?: Maybe<Scalars['uuid']>;
  qaUserId?: Maybe<Scalars['uuid']>;
  startedAt?: Maybe<Scalars['timestamptz']>;
  updatedEmail?: Maybe<Scalars['String']>;
  updatedLinkedinJob?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type QaTaskAction_Max_Fields = {
  __typename?: 'qaTaskAction_max_fields';
  actionType?: Maybe<Scalars['String']>;
  assessment?: Maybe<Scalars['String']>;
  clientContactId?: Maybe<Scalars['uuid']>;
  completedAt?: Maybe<Scalars['timestamptz']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  executionTime?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  jobChangeStatus?: Maybe<Scalars['String']>;
  linkedinUrl?: Maybe<Scalars['String']>;
  linkedinUrlStatus?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  previousLinkedinJob?: Maybe<Scalars['String']>;
  previousQaTaskActionId?: Maybe<Scalars['uuid']>;
  qaTaskId?: Maybe<Scalars['uuid']>;
  qaUserId?: Maybe<Scalars['uuid']>;
  startedAt?: Maybe<Scalars['timestamptz']>;
  updatedEmail?: Maybe<Scalars['String']>;
  updatedLinkedinJob?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "qaTaskAction" */
export type QaTaskAction_Max_Order_By = {
  actionType?: Maybe<Order_By>;
  assessment?: Maybe<Order_By>;
  clientContactId?: Maybe<Order_By>;
  completedAt?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  executionTime?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  jobChangeStatus?: Maybe<Order_By>;
  linkedinUrl?: Maybe<Order_By>;
  linkedinUrlStatus?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  previousLinkedinJob?: Maybe<Order_By>;
  previousQaTaskActionId?: Maybe<Order_By>;
  qaTaskId?: Maybe<Order_By>;
  qaUserId?: Maybe<Order_By>;
  startedAt?: Maybe<Order_By>;
  updatedEmail?: Maybe<Order_By>;
  updatedLinkedinJob?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type QaTaskAction_Min_Fields = {
  __typename?: 'qaTaskAction_min_fields';
  actionType?: Maybe<Scalars['String']>;
  assessment?: Maybe<Scalars['String']>;
  clientContactId?: Maybe<Scalars['uuid']>;
  completedAt?: Maybe<Scalars['timestamptz']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  executionTime?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  jobChangeStatus?: Maybe<Scalars['String']>;
  linkedinUrl?: Maybe<Scalars['String']>;
  linkedinUrlStatus?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  previousLinkedinJob?: Maybe<Scalars['String']>;
  previousQaTaskActionId?: Maybe<Scalars['uuid']>;
  qaTaskId?: Maybe<Scalars['uuid']>;
  qaUserId?: Maybe<Scalars['uuid']>;
  startedAt?: Maybe<Scalars['timestamptz']>;
  updatedEmail?: Maybe<Scalars['String']>;
  updatedLinkedinJob?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "qaTaskAction" */
export type QaTaskAction_Min_Order_By = {
  actionType?: Maybe<Order_By>;
  assessment?: Maybe<Order_By>;
  clientContactId?: Maybe<Order_By>;
  completedAt?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  executionTime?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  jobChangeStatus?: Maybe<Order_By>;
  linkedinUrl?: Maybe<Order_By>;
  linkedinUrlStatus?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  previousLinkedinJob?: Maybe<Order_By>;
  previousQaTaskActionId?: Maybe<Order_By>;
  qaTaskId?: Maybe<Order_By>;
  qaUserId?: Maybe<Order_By>;
  startedAt?: Maybe<Order_By>;
  updatedEmail?: Maybe<Order_By>;
  updatedLinkedinJob?: Maybe<Order_By>;
};

/** response of any mutation on the table "qaTaskAction" */
export type QaTaskAction_Mutation_Response = {
  __typename?: 'qaTaskAction_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<QaTaskAction>;
};

/** input type for inserting object relation for remote table "qaTaskAction" */
export type QaTaskAction_Obj_Rel_Insert_Input = {
  data: QaTaskAction_Insert_Input;
  on_conflict?: Maybe<QaTaskAction_On_Conflict>;
};

/** on conflict condition type for table "qaTaskAction" */
export type QaTaskAction_On_Conflict = {
  constraint: QaTaskAction_Constraint;
  update_columns: Array<QaTaskAction_Update_Column>;
  where?: Maybe<QaTaskAction_Bool_Exp>;
};

/** ordering options when selecting data from "qaTaskAction" */
export type QaTaskAction_Order_By = {
  actionType?: Maybe<Order_By>;
  assessment?: Maybe<Order_By>;
  clientContactId?: Maybe<Order_By>;
  completedAt?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  executionTime?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  jobChangeStatus?: Maybe<Order_By>;
  linkedinUrl?: Maybe<Order_By>;
  linkedinUrlStatus?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  previousLinkedinJob?: Maybe<Order_By>;
  previousQaTaskActionId?: Maybe<Order_By>;
  qaTaskId?: Maybe<Order_By>;
  qaUserId?: Maybe<Order_By>;
  startedAt?: Maybe<Order_By>;
  updatedEmail?: Maybe<Order_By>;
  updatedLinkedinJob?: Maybe<Order_By>;
};

/** primary key columns input for table: "qaTaskAction" */
export type QaTaskAction_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "qaTaskAction" */
export enum QaTaskAction_Select_Column {
  /** column name */
  ActionType = 'actionType',
  /** column name */
  Assessment = 'assessment',
  /** column name */
  ClientContactId = 'clientContactId',
  /** column name */
  CompletedAt = 'completedAt',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ExecutionTime = 'executionTime',
  /** column name */
  Id = 'id',
  /** column name */
  JobChangeStatus = 'jobChangeStatus',
  /** column name */
  LinkedinUrl = 'linkedinUrl',
  /** column name */
  LinkedinUrlStatus = 'linkedinUrlStatus',
  /** column name */
  Notes = 'notes',
  /** column name */
  PreviousLinkedinJob = 'previousLinkedinJob',
  /** column name */
  PreviousQaTaskActionId = 'previousQaTaskActionId',
  /** column name */
  QaTaskId = 'qaTaskId',
  /** column name */
  QaUserId = 'qaUserId',
  /** column name */
  StartedAt = 'startedAt',
  /** column name */
  UpdatedEmail = 'updatedEmail',
  /** column name */
  UpdatedLinkedinJob = 'updatedLinkedinJob',
}

/** input type for updating data in table "qaTaskAction" */
export type QaTaskAction_Set_Input = {
  actionType?: Maybe<Scalars['String']>;
  assessment?: Maybe<Scalars['String']>;
  clientContactId?: Maybe<Scalars['uuid']>;
  completedAt?: Maybe<Scalars['timestamptz']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  executionTime?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  jobChangeStatus?: Maybe<Scalars['String']>;
  linkedinUrl?: Maybe<Scalars['String']>;
  linkedinUrlStatus?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  previousLinkedinJob?: Maybe<Scalars['String']>;
  previousQaTaskActionId?: Maybe<Scalars['uuid']>;
  qaTaskId?: Maybe<Scalars['uuid']>;
  qaUserId?: Maybe<Scalars['uuid']>;
  startedAt?: Maybe<Scalars['timestamptz']>;
  updatedEmail?: Maybe<Scalars['String']>;
  updatedLinkedinJob?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type QaTaskAction_Stddev_Fields = {
  __typename?: 'qaTaskAction_stddev_fields';
  executionTime?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "qaTaskAction" */
export type QaTaskAction_Stddev_Order_By = {
  executionTime?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type QaTaskAction_Stddev_Pop_Fields = {
  __typename?: 'qaTaskAction_stddev_pop_fields';
  executionTime?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "qaTaskAction" */
export type QaTaskAction_Stddev_Pop_Order_By = {
  executionTime?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type QaTaskAction_Stddev_Samp_Fields = {
  __typename?: 'qaTaskAction_stddev_samp_fields';
  executionTime?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "qaTaskAction" */
export type QaTaskAction_Stddev_Samp_Order_By = {
  executionTime?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type QaTaskAction_Sum_Fields = {
  __typename?: 'qaTaskAction_sum_fields';
  executionTime?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "qaTaskAction" */
export type QaTaskAction_Sum_Order_By = {
  executionTime?: Maybe<Order_By>;
};

/** update columns of table "qaTaskAction" */
export enum QaTaskAction_Update_Column {
  /** column name */
  ActionType = 'actionType',
  /** column name */
  Assessment = 'assessment',
  /** column name */
  ClientContactId = 'clientContactId',
  /** column name */
  CompletedAt = 'completedAt',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ExecutionTime = 'executionTime',
  /** column name */
  Id = 'id',
  /** column name */
  JobChangeStatus = 'jobChangeStatus',
  /** column name */
  LinkedinUrl = 'linkedinUrl',
  /** column name */
  LinkedinUrlStatus = 'linkedinUrlStatus',
  /** column name */
  Notes = 'notes',
  /** column name */
  PreviousLinkedinJob = 'previousLinkedinJob',
  /** column name */
  PreviousQaTaskActionId = 'previousQaTaskActionId',
  /** column name */
  QaTaskId = 'qaTaskId',
  /** column name */
  QaUserId = 'qaUserId',
  /** column name */
  StartedAt = 'startedAt',
  /** column name */
  UpdatedEmail = 'updatedEmail',
  /** column name */
  UpdatedLinkedinJob = 'updatedLinkedinJob',
}

/** aggregate var_pop on columns */
export type QaTaskAction_Var_Pop_Fields = {
  __typename?: 'qaTaskAction_var_pop_fields';
  executionTime?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "qaTaskAction" */
export type QaTaskAction_Var_Pop_Order_By = {
  executionTime?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type QaTaskAction_Var_Samp_Fields = {
  __typename?: 'qaTaskAction_var_samp_fields';
  executionTime?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "qaTaskAction" */
export type QaTaskAction_Var_Samp_Order_By = {
  executionTime?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type QaTaskAction_Variance_Fields = {
  __typename?: 'qaTaskAction_variance_fields';
  executionTime?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "qaTaskAction" */
export type QaTaskAction_Variance_Order_By = {
  executionTime?: Maybe<Order_By>;
};

/** aggregated selection of "qaTask" */
export type QaTask_Aggregate = {
  __typename?: 'qaTask_aggregate';
  aggregate?: Maybe<QaTask_Aggregate_Fields>;
  nodes: Array<QaTask>;
};

/** aggregate fields of "qaTask" */
export type QaTask_Aggregate_Fields = {
  __typename?: 'qaTask_aggregate_fields';
  avg?: Maybe<QaTask_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<QaTask_Max_Fields>;
  min?: Maybe<QaTask_Min_Fields>;
  stddev?: Maybe<QaTask_Stddev_Fields>;
  stddev_pop?: Maybe<QaTask_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<QaTask_Stddev_Samp_Fields>;
  sum?: Maybe<QaTask_Sum_Fields>;
  var_pop?: Maybe<QaTask_Var_Pop_Fields>;
  var_samp?: Maybe<QaTask_Var_Samp_Fields>;
  variance?: Maybe<QaTask_Variance_Fields>;
};

/** aggregate fields of "qaTask" */
export type QaTask_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<QaTask_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "qaTask" */
export type QaTask_Aggregate_Order_By = {
  avg?: Maybe<QaTask_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<QaTask_Max_Order_By>;
  min?: Maybe<QaTask_Min_Order_By>;
  stddev?: Maybe<QaTask_Stddev_Order_By>;
  stddev_pop?: Maybe<QaTask_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<QaTask_Stddev_Samp_Order_By>;
  sum?: Maybe<QaTask_Sum_Order_By>;
  var_pop?: Maybe<QaTask_Var_Pop_Order_By>;
  var_samp?: Maybe<QaTask_Var_Samp_Order_By>;
  variance?: Maybe<QaTask_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "qaTask" */
export type QaTask_Arr_Rel_Insert_Input = {
  data: Array<QaTask_Insert_Input>;
  on_conflict?: Maybe<QaTask_On_Conflict>;
};

/** aggregate avg on columns */
export type QaTask_Avg_Fields = {
  __typename?: 'qaTask_avg_fields';
  priority?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "qaTask" */
export type QaTask_Avg_Order_By = {
  priority?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "qaTask". All fields are combined with a logical 'AND'. */
export type QaTask_Bool_Exp = {
  _and?: Maybe<Array<Maybe<QaTask_Bool_Exp>>>;
  _not?: Maybe<QaTask_Bool_Exp>;
  _or?: Maybe<Array<Maybe<QaTask_Bool_Exp>>>;
  assignedUser?: Maybe<User_Bool_Exp>;
  assignedUserId?: Maybe<Uuid_Comparison_Exp>;
  clientContact?: Maybe<ClientContact_Bool_Exp>;
  clientContactId?: Maybe<Uuid_Comparison_Exp>;
  clientId?: Maybe<Uuid_Comparison_Exp>;
  completedAt?: Maybe<Timestamptz_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  dueAt?: Maybe<Timestamptz_Comparison_Exp>;
  firestoreId?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  isEscalated?: Maybe<Boolean_Comparison_Exp>;
  lastFetchedAt?: Maybe<Timestamptz_Comparison_Exp>;
  previousQaTaskId?: Maybe<Uuid_Comparison_Exp>;
  priority?: Maybe<Int_Comparison_Exp>;
  qaTaskActions?: Maybe<QaTaskAction_Bool_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  taskType?: Maybe<String_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "qaTask" */
export enum QaTask_Constraint {
  /** unique or primary key constraint */
  QaTaskFirestoreIdKey = 'qaTask_firestoreId_key',
  /** unique or primary key constraint */
  QaTaskPkey = 'qaTask_pkey',
}

/** input type for incrementing integer column in table "qaTask" */
export type QaTask_Inc_Input = {
  priority?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "qaTask" */
export type QaTask_Insert_Input = {
  assignedUser?: Maybe<User_Obj_Rel_Insert_Input>;
  assignedUserId?: Maybe<Scalars['uuid']>;
  clientContact?: Maybe<ClientContact_Obj_Rel_Insert_Input>;
  clientContactId?: Maybe<Scalars['uuid']>;
  clientId?: Maybe<Scalars['uuid']>;
  completedAt?: Maybe<Scalars['timestamptz']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  dueAt?: Maybe<Scalars['timestamptz']>;
  firestoreId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  isEscalated?: Maybe<Scalars['Boolean']>;
  lastFetchedAt?: Maybe<Scalars['timestamptz']>;
  previousQaTaskId?: Maybe<Scalars['uuid']>;
  priority?: Maybe<Scalars['Int']>;
  qaTaskActions?: Maybe<QaTaskAction_Arr_Rel_Insert_Input>;
  status?: Maybe<Scalars['String']>;
  taskType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type QaTask_Max_Fields = {
  __typename?: 'qaTask_max_fields';
  assignedUserId?: Maybe<Scalars['uuid']>;
  clientContactId?: Maybe<Scalars['uuid']>;
  clientId?: Maybe<Scalars['uuid']>;
  completedAt?: Maybe<Scalars['timestamptz']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  dueAt?: Maybe<Scalars['timestamptz']>;
  firestoreId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastFetchedAt?: Maybe<Scalars['timestamptz']>;
  previousQaTaskId?: Maybe<Scalars['uuid']>;
  priority?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  taskType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "qaTask" */
export type QaTask_Max_Order_By = {
  assignedUserId?: Maybe<Order_By>;
  clientContactId?: Maybe<Order_By>;
  clientId?: Maybe<Order_By>;
  completedAt?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  dueAt?: Maybe<Order_By>;
  firestoreId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lastFetchedAt?: Maybe<Order_By>;
  previousQaTaskId?: Maybe<Order_By>;
  priority?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  taskType?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type QaTask_Min_Fields = {
  __typename?: 'qaTask_min_fields';
  assignedUserId?: Maybe<Scalars['uuid']>;
  clientContactId?: Maybe<Scalars['uuid']>;
  clientId?: Maybe<Scalars['uuid']>;
  completedAt?: Maybe<Scalars['timestamptz']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  dueAt?: Maybe<Scalars['timestamptz']>;
  firestoreId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastFetchedAt?: Maybe<Scalars['timestamptz']>;
  previousQaTaskId?: Maybe<Scalars['uuid']>;
  priority?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  taskType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "qaTask" */
export type QaTask_Min_Order_By = {
  assignedUserId?: Maybe<Order_By>;
  clientContactId?: Maybe<Order_By>;
  clientId?: Maybe<Order_By>;
  completedAt?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  dueAt?: Maybe<Order_By>;
  firestoreId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lastFetchedAt?: Maybe<Order_By>;
  previousQaTaskId?: Maybe<Order_By>;
  priority?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  taskType?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** response of any mutation on the table "qaTask" */
export type QaTask_Mutation_Response = {
  __typename?: 'qaTask_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<QaTask>;
};

/** input type for inserting object relation for remote table "qaTask" */
export type QaTask_Obj_Rel_Insert_Input = {
  data: QaTask_Insert_Input;
  on_conflict?: Maybe<QaTask_On_Conflict>;
};

/** on conflict condition type for table "qaTask" */
export type QaTask_On_Conflict = {
  constraint: QaTask_Constraint;
  update_columns: Array<QaTask_Update_Column>;
  where?: Maybe<QaTask_Bool_Exp>;
};

/** ordering options when selecting data from "qaTask" */
export type QaTask_Order_By = {
  assignedUser?: Maybe<User_Order_By>;
  assignedUserId?: Maybe<Order_By>;
  clientContact?: Maybe<ClientContact_Order_By>;
  clientContactId?: Maybe<Order_By>;
  clientId?: Maybe<Order_By>;
  completedAt?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  dueAt?: Maybe<Order_By>;
  firestoreId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  isEscalated?: Maybe<Order_By>;
  lastFetchedAt?: Maybe<Order_By>;
  previousQaTaskId?: Maybe<Order_By>;
  priority?: Maybe<Order_By>;
  qaTaskActions_aggregate?: Maybe<QaTaskAction_Aggregate_Order_By>;
  status?: Maybe<Order_By>;
  taskType?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: "qaTask" */
export type QaTask_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "qaTask" */
export enum QaTask_Select_Column {
  /** column name */
  AssignedUserId = 'assignedUserId',
  /** column name */
  ClientContactId = 'clientContactId',
  /** column name */
  ClientId = 'clientId',
  /** column name */
  CompletedAt = 'completedAt',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DueAt = 'dueAt',
  /** column name */
  FirestoreId = 'firestoreId',
  /** column name */
  Id = 'id',
  /** column name */
  IsEscalated = 'isEscalated',
  /** column name */
  LastFetchedAt = 'lastFetchedAt',
  /** column name */
  PreviousQaTaskId = 'previousQaTaskId',
  /** column name */
  Priority = 'priority',
  /** column name */
  Status = 'status',
  /** column name */
  TaskType = 'taskType',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "qaTask" */
export type QaTask_Set_Input = {
  assignedUserId?: Maybe<Scalars['uuid']>;
  clientContactId?: Maybe<Scalars['uuid']>;
  clientId?: Maybe<Scalars['uuid']>;
  completedAt?: Maybe<Scalars['timestamptz']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  dueAt?: Maybe<Scalars['timestamptz']>;
  firestoreId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  isEscalated?: Maybe<Scalars['Boolean']>;
  lastFetchedAt?: Maybe<Scalars['timestamptz']>;
  previousQaTaskId?: Maybe<Scalars['uuid']>;
  priority?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  taskType?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type QaTask_Stddev_Fields = {
  __typename?: 'qaTask_stddev_fields';
  priority?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "qaTask" */
export type QaTask_Stddev_Order_By = {
  priority?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type QaTask_Stddev_Pop_Fields = {
  __typename?: 'qaTask_stddev_pop_fields';
  priority?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "qaTask" */
export type QaTask_Stddev_Pop_Order_By = {
  priority?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type QaTask_Stddev_Samp_Fields = {
  __typename?: 'qaTask_stddev_samp_fields';
  priority?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "qaTask" */
export type QaTask_Stddev_Samp_Order_By = {
  priority?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type QaTask_Sum_Fields = {
  __typename?: 'qaTask_sum_fields';
  priority?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "qaTask" */
export type QaTask_Sum_Order_By = {
  priority?: Maybe<Order_By>;
};

/** update columns of table "qaTask" */
export enum QaTask_Update_Column {
  /** column name */
  AssignedUserId = 'assignedUserId',
  /** column name */
  ClientContactId = 'clientContactId',
  /** column name */
  ClientId = 'clientId',
  /** column name */
  CompletedAt = 'completedAt',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DueAt = 'dueAt',
  /** column name */
  FirestoreId = 'firestoreId',
  /** column name */
  Id = 'id',
  /** column name */
  IsEscalated = 'isEscalated',
  /** column name */
  LastFetchedAt = 'lastFetchedAt',
  /** column name */
  PreviousQaTaskId = 'previousQaTaskId',
  /** column name */
  Priority = 'priority',
  /** column name */
  Status = 'status',
  /** column name */
  TaskType = 'taskType',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** aggregate var_pop on columns */
export type QaTask_Var_Pop_Fields = {
  __typename?: 'qaTask_var_pop_fields';
  priority?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "qaTask" */
export type QaTask_Var_Pop_Order_By = {
  priority?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type QaTask_Var_Samp_Fields = {
  __typename?: 'qaTask_var_samp_fields';
  priority?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "qaTask" */
export type QaTask_Var_Samp_Order_By = {
  priority?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type QaTask_Variance_Fields = {
  __typename?: 'qaTask_variance_fields';
  priority?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "qaTask" */
export type QaTask_Variance_Order_By = {
  priority?: Maybe<Order_By>;
};

/** query root */
export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "client" */
  client: Array<Client>;
  /** fetch data from the table: "clientContact" */
  clientContact: Array<ClientContact>;
  /** fetch data from the table: "clientContactCrmData" */
  clientContactCrmData: Array<ClientContactCrmData>;
  /** fetch aggregated fields from the table: "clientContactCrmData" */
  clientContactCrmData_aggregate: ClientContactCrmData_Aggregate;
  /** fetch data from the table: "clientContactCrmData" using primary key columns */
  clientContactCrmData_by_pk?: Maybe<ClientContactCrmData>;
  /** fetch data from the table: "clientContactFilter" */
  clientContactFilter: Array<ClientContactFilter>;
  /** fetch aggregated fields from the table: "clientContactFilter" */
  clientContactFilter_aggregate: ClientContactFilter_Aggregate;
  /** fetch data from the table: "clientContactFilter" using primary key columns */
  clientContactFilter_by_pk?: Maybe<ClientContactFilter>;
  /** fetch data from the table: "clientContactJobChange" */
  clientContactJobChange: Array<ClientContactJobChange>;
  /** fetch aggregated fields from the table: "clientContactJobChange" */
  clientContactJobChange_aggregate: ClientContactJobChange_Aggregate;
  /** fetch data from the table: "clientContactJobChange" using primary key columns */
  clientContactJobChange_by_pk?: Maybe<ClientContactJobChange>;
  /** fetch aggregated fields from the table: "clientContact" */
  clientContact_aggregate: ClientContact_Aggregate;
  /** fetch data from the table: "clientContact" using primary key columns */
  clientContact_by_pk?: Maybe<ClientContact>;
  /** fetch data from the table: "clientEmailReport" */
  clientEmailReport: Array<ClientEmailReport>;
  /** fetch aggregated fields from the table: "clientEmailReport" */
  clientEmailReport_aggregate: ClientEmailReport_Aggregate;
  /** fetch data from the table: "clientEmailReport" using primary key columns */
  clientEmailReport_by_pk?: Maybe<ClientEmailReport>;
  /** fetch data from the table: "clientNotificationEmail" */
  clientNotificationEmail: Array<ClientNotificationEmail>;
  /** fetch aggregated fields from the table: "clientNotificationEmail" */
  clientNotificationEmail_aggregate: ClientNotificationEmail_Aggregate;
  /** fetch data from the table: "clientNotificationEmail" using primary key columns */
  clientNotificationEmail_by_pk?: Maybe<ClientNotificationEmail>;
  /** fetch data from the table: "clientTerritory" */
  clientTerritory: Array<ClientTerritory>;
  /** fetch aggregated fields from the table: "clientTerritory" */
  clientTerritory_aggregate: ClientTerritory_Aggregate;
  /** fetch data from the table: "clientTerritory" using primary key columns */
  clientTerritory_by_pk?: Maybe<ClientTerritory>;
  /** fetch aggregated fields from the table: "client" */
  client_aggregate: Client_Aggregate;
  /** fetch data from the table: "client" using primary key columns */
  client_by_pk?: Maybe<Client>;
  /** fetch data from the table: "cloudTask" */
  cloudTask: Array<CloudTask>;
  /** fetch aggregated fields from the table: "cloudTask" */
  cloudTask_aggregate: CloudTask_Aggregate;
  /** fetch data from the table: "cloudTask" using primary key columns */
  cloudTask_by_pk?: Maybe<CloudTask>;
  /** fetch data from the table: "company" */
  company: Array<Company>;
  /** fetch data from the table: "companyName" */
  companyName: Array<CompanyName>;
  /** fetch aggregated fields from the table: "companyName" */
  companyName_aggregate: CompanyName_Aggregate;
  /** fetch data from the table: "companyName" using primary key columns */
  companyName_by_pk?: Maybe<CompanyName>;
  /** fetch aggregated fields from the table: "company" */
  company_aggregate: Company_Aggregate;
  /** fetch data from the table: "company" using primary key columns */
  company_by_pk?: Maybe<Company>;
  /** fetch data from the table: "contactData" */
  contactData: Array<ContactData>;
  /** fetch aggregated fields from the table: "contactData" */
  contactData_aggregate: ContactData_Aggregate;
  /** fetch data from the table: "contactData" using primary key columns */
  contactData_by_pk?: Maybe<ContactData>;
  /** fetch data from the table: "contactEmail" */
  contactEmail: Array<ContactEmail>;
  /** fetch aggregated fields from the table: "contactEmail" */
  contactEmail_aggregate: ContactEmail_Aggregate;
  /** fetch data from the table: "contactEmail" using primary key columns */
  contactEmail_by_pk?: Maybe<ContactEmail>;
  /** fetch data from the table: "crmContactEnrichmentHistory" */
  crmContactEnrichmentHistory: Array<CrmContactEnrichmentHistory>;
  /** fetch aggregated fields from the table: "crmContactEnrichmentHistory" */
  crmContactEnrichmentHistory_aggregate: CrmContactEnrichmentHistory_Aggregate;
  /** fetch data from the table: "crmContactEnrichmentHistory" using primary key columns */
  crmContactEnrichmentHistory_by_pk?: Maybe<CrmContactEnrichmentHistory>;
  /** fetch data from the table: "csvUpload" */
  csvUpload: Array<CsvUpload>;
  /** fetch aggregated fields from the table: "csvUpload" */
  csvUpload_aggregate: CsvUpload_Aggregate;
  /** fetch data from the table: "csvUpload" using primary key columns */
  csvUpload_by_pk?: Maybe<CsvUpload>;
  /** fetch data from the table: "emailHistory" */
  emailHistory: Array<EmailHistory>;
  /** fetch aggregated fields from the table: "emailHistory" */
  emailHistory_aggregate: EmailHistory_Aggregate;
  /** fetch data from the table: "emailHistory" using primary key columns */
  emailHistory_by_pk?: Maybe<EmailHistory>;
  /** fetch data from the table: "googleChannel" */
  googleChannel: Array<GoogleChannel>;
  /** fetch aggregated fields from the table: "googleChannel" */
  googleChannel_aggregate: GoogleChannel_Aggregate;
  /** fetch data from the table: "googleChannel" using primary key columns */
  googleChannel_by_pk?: Maybe<GoogleChannel>;
  /** fetch data from the table: "hubspotContactList" */
  hubspotContactList: Array<HubspotContactList>;
  /** fetch data from the table: "hubspotContactListHubspotContact" */
  hubspotContactListHubspotContact: Array<HubspotContactListHubspotContact>;
  /** fetch aggregated fields from the table: "hubspotContactListHubspotContact" */
  hubspotContactListHubspotContact_aggregate: HubspotContactListHubspotContact_Aggregate;
  /** fetch data from the table: "hubspotContactListHubspotContact" using primary key columns */
  hubspotContactListHubspotContact_by_pk?: Maybe<HubspotContactListHubspotContact>;
  /** fetch aggregated fields from the table: "hubspotContactList" */
  hubspotContactList_aggregate: HubspotContactList_Aggregate;
  /** fetch data from the table: "hubspotContactList" using primary key columns */
  hubspotContactList_by_pk?: Maybe<HubspotContactList>;
  /** fetch data from the table: "hubspotContactProperty" */
  hubspotContactProperty: Array<HubspotContactProperty>;
  /** fetch aggregated fields from the table: "hubspotContactProperty" */
  hubspotContactProperty_aggregate: HubspotContactProperty_Aggregate;
  /** fetch data from the table: "hubspotContactProperty" using primary key columns */
  hubspotContactProperty_by_pk?: Maybe<HubspotContactProperty>;
  /** fetch data from the table: "hubspotIntegration" */
  hubspotIntegration: Array<HubspotIntegration>;
  /** fetch aggregated fields from the table: "hubspotIntegration" */
  hubspotIntegration_aggregate: HubspotIntegration_Aggregate;
  /** fetch data from the table: "hubspotIntegration" using primary key columns */
  hubspotIntegration_by_pk?: Maybe<HubspotIntegration>;
  /** fetch data from the table: "job" */
  job: Array<Job>;
  /** fetch aggregated fields from the table: "job" */
  job_aggregate: Job_Aggregate;
  /** fetch data from the table: "job" using primary key columns */
  job_by_pk?: Maybe<Job>;
  /** fetch data from the table: "proxycrawlData" */
  proxycrawlData: Array<ProxycrawlData>;
  /** fetch aggregated fields from the table: "proxycrawlData" */
  proxycrawlData_aggregate: ProxycrawlData_Aggregate;
  /** fetch data from the table: "proxycrawlData" using primary key columns */
  proxycrawlData_by_pk?: Maybe<ProxycrawlData>;
  /** fetch data from the table: "qaQualification" */
  qaQualification: Array<QaQualification>;
  /** fetch aggregated fields from the table: "qaQualification" */
  qaQualification_aggregate: QaQualification_Aggregate;
  /** fetch data from the table: "qaQualification" using primary key columns */
  qaQualification_by_pk?: Maybe<QaQualification>;
  /** fetch data from the table: "qaTask" */
  qaTask: Array<QaTask>;
  /** fetch data from the table: "qaTaskAction" */
  qaTaskAction: Array<QaTaskAction>;
  /** fetch aggregated fields from the table: "qaTaskAction" */
  qaTaskAction_aggregate: QaTaskAction_Aggregate;
  /** fetch data from the table: "qaTaskAction" using primary key columns */
  qaTaskAction_by_pk?: Maybe<QaTaskAction>;
  /** fetch aggregated fields from the table: "qaTask" */
  qaTask_aggregate: QaTask_Aggregate;
  /** fetch data from the table: "qaTask" using primary key columns */
  qaTask_by_pk?: Maybe<QaTask>;
  /** fetch data from the table: "salesforceAccount" */
  salesforceAccount: Array<SalesforceAccount>;
  /** fetch aggregated fields from the table: "salesforceAccount" */
  salesforceAccount_aggregate: SalesforceAccount_Aggregate;
  /** fetch data from the table: "salesforceAccount" using primary key columns */
  salesforceAccount_by_pk?: Maybe<SalesforceAccount>;
  /** fetch data from the table: "salesforceContactField" */
  salesforceContactField: Array<SalesforceContactField>;
  /** fetch aggregated fields from the table: "salesforceContactField" */
  salesforceContactField_aggregate: SalesforceContactField_Aggregate;
  /** fetch data from the table: "salesforceContactField" using primary key columns */
  salesforceContactField_by_pk?: Maybe<SalesforceContactField>;
  /** fetch data from the table: "salesforceIntegration" */
  salesforceIntegration: Array<SalesforceIntegration>;
  /** fetch aggregated fields from the table: "salesforceIntegration" */
  salesforceIntegration_aggregate: SalesforceIntegration_Aggregate;
  /** fetch data from the table: "salesforceIntegration" using primary key columns */
  salesforceIntegration_by_pk?: Maybe<SalesforceIntegration>;
  /** fetch data from the table: "salesforceOpportunity" */
  salesforceOpportunity: Array<SalesforceOpportunity>;
  /** fetch aggregated fields from the table: "salesforceOpportunity" */
  salesforceOpportunity_aggregate: SalesforceOpportunity_Aggregate;
  /** fetch data from the table: "salesforceOpportunity" using primary key columns */
  salesforceOpportunity_by_pk?: Maybe<SalesforceOpportunity>;
  /** fetch data from the table: "sendgridEvent" */
  sendgridEvent: Array<SendgridEvent>;
  /** fetch aggregated fields from the table: "sendgridEvent" */
  sendgridEvent_aggregate: SendgridEvent_Aggregate;
  /** fetch data from the table: "sendgridEvent" using primary key columns */
  sendgridEvent_by_pk?: Maybe<SendgridEvent>;
  /** fetch data from the table: "territory" */
  territory: Array<Territory>;
  /** fetch data from the table: "territorySearchTerm" */
  territorySearchTerm: Array<TerritorySearchTerm>;
  /** fetch aggregated fields from the table: "territorySearchTerm" */
  territorySearchTerm_aggregate: TerritorySearchTerm_Aggregate;
  /** fetch data from the table: "territorySearchTerm" using primary key columns */
  territorySearchTerm_by_pk?: Maybe<TerritorySearchTerm>;
  /** fetch aggregated fields from the table: "territory" */
  territory_aggregate: Territory_Aggregate;
  /** fetch data from the table: "territory" using primary key columns */
  territory_by_pk?: Maybe<Territory>;
  /** fetch data from the table: "theOrgSearchTerm" */
  theOrgSearchTerm: Array<TheOrgSearchTerm>;
  /** fetch aggregated fields from the table: "theOrgSearchTerm" */
  theOrgSearchTerm_aggregate: TheOrgSearchTerm_Aggregate;
  /** fetch data from the table: "theOrgSearchTerm" using primary key columns */
  theOrgSearchTerm_by_pk?: Maybe<TheOrgSearchTerm>;
  /** fetch data from the table: "user" */
  user: Array<User>;
  /** fetch data from the table: "userInvite" */
  userInvite: Array<UserInvite>;
  /** fetch aggregated fields from the table: "userInvite" */
  userInvite_aggregate: UserInvite_Aggregate;
  /** fetch data from the table: "userInvite" using primary key columns */
  userInvite_by_pk?: Maybe<UserInvite>;
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: User_Aggregate;
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>;
  /** fetch data from the table: "warmCompany" */
  warmCompany: Array<WarmCompany>;
  /** fetch aggregated fields from the table: "warmCompany" */
  warmCompany_aggregate: WarmCompany_Aggregate;
  /** fetch data from the table: "zoomCompany" */
  zoomCompany: Array<ZoomCompany>;
  /** fetch aggregated fields from the table: "zoomCompany" */
  zoomCompany_aggregate: ZoomCompany_Aggregate;
  /** fetch data from the table: "zoomCompany" using primary key columns */
  zoomCompany_by_pk?: Maybe<ZoomCompany>;
  /** fetch data from the table: "zoomContact" */
  zoomContact: Array<ZoomContact>;
  /** fetch data from the table: "zoomContactEnrichmentHistory" */
  zoomContactEnrichmentHistory: Array<ZoomContactEnrichmentHistory>;
  /** fetch aggregated fields from the table: "zoomContactEnrichmentHistory" */
  zoomContactEnrichmentHistory_aggregate: ZoomContactEnrichmentHistory_Aggregate;
  /** fetch data from the table: "zoomContactEnrichmentHistory" using primary key columns */
  zoomContactEnrichmentHistory_by_pk?: Maybe<ZoomContactEnrichmentHistory>;
  /** fetch data from the table: "zoomContactInfo" */
  zoomContactInfo: Array<ZoomContactInfo>;
  /** fetch aggregated fields from the table: "zoomContactInfo" */
  zoomContactInfo_aggregate: ZoomContactInfo_Aggregate;
  /** fetch data from the table: "zoomContactInfo" using primary key columns */
  zoomContactInfo_by_pk?: Maybe<ZoomContactInfo>;
  /** fetch data from the table: "zoomContactNotes" */
  zoomContactNotes: Array<ZoomContactNotes>;
  /** fetch aggregated fields from the table: "zoomContactNotes" */
  zoomContactNotes_aggregate: ZoomContactNotes_Aggregate;
  /** fetch data from the table: "zoomContactNotes" using primary key columns */
  zoomContactNotes_by_pk?: Maybe<ZoomContactNotes>;
  /** fetch aggregated fields from the table: "zoomContact" */
  zoomContact_aggregate: ZoomContact_Aggregate;
  /** fetch data from the table: "zoomContact" using primary key columns */
  zoomContact_by_pk?: Maybe<ZoomContact>;
  /** fetch data from the table: "zoomEmailHistory" */
  zoomEmailHistory: Array<ZoomEmailHistory>;
  /** fetch aggregated fields from the table: "zoomEmailHistory" */
  zoomEmailHistory_aggregate: ZoomEmailHistory_Aggregate;
  /** fetch data from the table: "zoomEmailHistory" using primary key columns */
  zoomEmailHistory_by_pk?: Maybe<ZoomEmailHistory>;
  /** fetch data from the table: "zoomEvent" */
  zoomEvent: Array<ZoomEvent>;
  /** fetch data from the table: "zoomEventParticipant" */
  zoomEventParticipant: Array<ZoomEventParticipant>;
  /** fetch aggregated fields from the table: "zoomEventParticipant" */
  zoomEventParticipant_aggregate: ZoomEventParticipant_Aggregate;
  /** fetch data from the table: "zoomEventParticipant" using primary key columns */
  zoomEventParticipant_by_pk?: Maybe<ZoomEventParticipant>;
  /** fetch aggregated fields from the table: "zoomEvent" */
  zoomEvent_aggregate: ZoomEvent_Aggregate;
  /** fetch data from the table: "zoomEvent" using primary key columns */
  zoomEvent_by_pk?: Maybe<ZoomEvent>;
  /** fetch data from the table: "zoomExternalAuthUser" */
  zoomExternalAuthUser: Array<ZoomExternalAuthUser>;
  /** fetch aggregated fields from the table: "zoomExternalAuthUser" */
  zoomExternalAuthUser_aggregate: ZoomExternalAuthUser_Aggregate;
  /** fetch data from the table: "zoomExternalAuthUser" using primary key columns */
  zoomExternalAuthUser_by_pk?: Maybe<ZoomExternalAuthUser>;
  /** fetch data from the table: "zoomUser" */
  zoomUser: Array<ZoomUser>;
  /** fetch aggregated fields from the table: "zoomUser" */
  zoomUser_aggregate: ZoomUser_Aggregate;
  /** fetch data from the table: "zoomUser" using primary key columns */
  zoomUser_by_pk?: Maybe<ZoomUser>;
};

/** query root */
export type Query_RootClientArgs = {
  distinct_on?: Maybe<Array<Client_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Client_Order_By>>;
  where?: Maybe<Client_Bool_Exp>;
};

/** query root */
export type Query_RootClientContactArgs = {
  distinct_on?: Maybe<Array<ClientContact_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ClientContact_Order_By>>;
  where?: Maybe<ClientContact_Bool_Exp>;
};

/** query root */
export type Query_RootClientContactCrmDataArgs = {
  distinct_on?: Maybe<Array<ClientContactCrmData_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ClientContactCrmData_Order_By>>;
  where?: Maybe<ClientContactCrmData_Bool_Exp>;
};

/** query root */
export type Query_RootClientContactCrmData_AggregateArgs = {
  distinct_on?: Maybe<Array<ClientContactCrmData_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ClientContactCrmData_Order_By>>;
  where?: Maybe<ClientContactCrmData_Bool_Exp>;
};

/** query root */
export type Query_RootClientContactCrmData_By_PkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootClientContactFilterArgs = {
  distinct_on?: Maybe<Array<ClientContactFilter_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ClientContactFilter_Order_By>>;
  where?: Maybe<ClientContactFilter_Bool_Exp>;
};

/** query root */
export type Query_RootClientContactFilter_AggregateArgs = {
  distinct_on?: Maybe<Array<ClientContactFilter_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ClientContactFilter_Order_By>>;
  where?: Maybe<ClientContactFilter_Bool_Exp>;
};

/** query root */
export type Query_RootClientContactFilter_By_PkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootClientContactJobChangeArgs = {
  distinct_on?: Maybe<Array<ClientContactJobChange_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ClientContactJobChange_Order_By>>;
  where?: Maybe<ClientContactJobChange_Bool_Exp>;
};

/** query root */
export type Query_RootClientContactJobChange_AggregateArgs = {
  distinct_on?: Maybe<Array<ClientContactJobChange_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ClientContactJobChange_Order_By>>;
  where?: Maybe<ClientContactJobChange_Bool_Exp>;
};

/** query root */
export type Query_RootClientContactJobChange_By_PkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootClientContact_AggregateArgs = {
  distinct_on?: Maybe<Array<ClientContact_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ClientContact_Order_By>>;
  where?: Maybe<ClientContact_Bool_Exp>;
};

/** query root */
export type Query_RootClientContact_By_PkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootClientEmailReportArgs = {
  distinct_on?: Maybe<Array<ClientEmailReport_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ClientEmailReport_Order_By>>;
  where?: Maybe<ClientEmailReport_Bool_Exp>;
};

/** query root */
export type Query_RootClientEmailReport_AggregateArgs = {
  distinct_on?: Maybe<Array<ClientEmailReport_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ClientEmailReport_Order_By>>;
  where?: Maybe<ClientEmailReport_Bool_Exp>;
};

/** query root */
export type Query_RootClientEmailReport_By_PkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootClientNotificationEmailArgs = {
  distinct_on?: Maybe<Array<ClientNotificationEmail_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ClientNotificationEmail_Order_By>>;
  where?: Maybe<ClientNotificationEmail_Bool_Exp>;
};

/** query root */
export type Query_RootClientNotificationEmail_AggregateArgs = {
  distinct_on?: Maybe<Array<ClientNotificationEmail_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ClientNotificationEmail_Order_By>>;
  where?: Maybe<ClientNotificationEmail_Bool_Exp>;
};

/** query root */
export type Query_RootClientNotificationEmail_By_PkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootClientTerritoryArgs = {
  distinct_on?: Maybe<Array<ClientTerritory_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ClientTerritory_Order_By>>;
  where?: Maybe<ClientTerritory_Bool_Exp>;
};

/** query root */
export type Query_RootClientTerritory_AggregateArgs = {
  distinct_on?: Maybe<Array<ClientTerritory_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ClientTerritory_Order_By>>;
  where?: Maybe<ClientTerritory_Bool_Exp>;
};

/** query root */
export type Query_RootClientTerritory_By_PkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootClient_AggregateArgs = {
  distinct_on?: Maybe<Array<Client_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Client_Order_By>>;
  where?: Maybe<Client_Bool_Exp>;
};

/** query root */
export type Query_RootClient_By_PkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootCloudTaskArgs = {
  distinct_on?: Maybe<Array<CloudTask_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CloudTask_Order_By>>;
  where?: Maybe<CloudTask_Bool_Exp>;
};

/** query root */
export type Query_RootCloudTask_AggregateArgs = {
  distinct_on?: Maybe<Array<CloudTask_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CloudTask_Order_By>>;
  where?: Maybe<CloudTask_Bool_Exp>;
};

/** query root */
export type Query_RootCloudTask_By_PkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootCompanyArgs = {
  distinct_on?: Maybe<Array<Company_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Company_Order_By>>;
  where?: Maybe<Company_Bool_Exp>;
};

/** query root */
export type Query_RootCompanyNameArgs = {
  distinct_on?: Maybe<Array<CompanyName_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CompanyName_Order_By>>;
  where?: Maybe<CompanyName_Bool_Exp>;
};

/** query root */
export type Query_RootCompanyName_AggregateArgs = {
  distinct_on?: Maybe<Array<CompanyName_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CompanyName_Order_By>>;
  where?: Maybe<CompanyName_Bool_Exp>;
};

/** query root */
export type Query_RootCompanyName_By_PkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootCompany_AggregateArgs = {
  distinct_on?: Maybe<Array<Company_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Company_Order_By>>;
  where?: Maybe<Company_Bool_Exp>;
};

/** query root */
export type Query_RootCompany_By_PkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootContactDataArgs = {
  distinct_on?: Maybe<Array<ContactData_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContactData_Order_By>>;
  where?: Maybe<ContactData_Bool_Exp>;
};

/** query root */
export type Query_RootContactData_AggregateArgs = {
  distinct_on?: Maybe<Array<ContactData_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContactData_Order_By>>;
  where?: Maybe<ContactData_Bool_Exp>;
};

/** query root */
export type Query_RootContactData_By_PkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootContactEmailArgs = {
  distinct_on?: Maybe<Array<ContactEmail_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContactEmail_Order_By>>;
  where?: Maybe<ContactEmail_Bool_Exp>;
};

/** query root */
export type Query_RootContactEmail_AggregateArgs = {
  distinct_on?: Maybe<Array<ContactEmail_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContactEmail_Order_By>>;
  where?: Maybe<ContactEmail_Bool_Exp>;
};

/** query root */
export type Query_RootContactEmail_By_PkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootCrmContactEnrichmentHistoryArgs = {
  distinct_on?: Maybe<Array<CrmContactEnrichmentHistory_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CrmContactEnrichmentHistory_Order_By>>;
  where?: Maybe<CrmContactEnrichmentHistory_Bool_Exp>;
};

/** query root */
export type Query_RootCrmContactEnrichmentHistory_AggregateArgs = {
  distinct_on?: Maybe<Array<CrmContactEnrichmentHistory_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CrmContactEnrichmentHistory_Order_By>>;
  where?: Maybe<CrmContactEnrichmentHistory_Bool_Exp>;
};

/** query root */
export type Query_RootCrmContactEnrichmentHistory_By_PkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootCsvUploadArgs = {
  distinct_on?: Maybe<Array<CsvUpload_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CsvUpload_Order_By>>;
  where?: Maybe<CsvUpload_Bool_Exp>;
};

/** query root */
export type Query_RootCsvUpload_AggregateArgs = {
  distinct_on?: Maybe<Array<CsvUpload_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CsvUpload_Order_By>>;
  where?: Maybe<CsvUpload_Bool_Exp>;
};

/** query root */
export type Query_RootCsvUpload_By_PkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootEmailHistoryArgs = {
  distinct_on?: Maybe<Array<EmailHistory_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<EmailHistory_Order_By>>;
  where?: Maybe<EmailHistory_Bool_Exp>;
};

/** query root */
export type Query_RootEmailHistory_AggregateArgs = {
  distinct_on?: Maybe<Array<EmailHistory_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<EmailHistory_Order_By>>;
  where?: Maybe<EmailHistory_Bool_Exp>;
};

/** query root */
export type Query_RootEmailHistory_By_PkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootGoogleChannelArgs = {
  distinct_on?: Maybe<Array<GoogleChannel_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<GoogleChannel_Order_By>>;
  where?: Maybe<GoogleChannel_Bool_Exp>;
};

/** query root */
export type Query_RootGoogleChannel_AggregateArgs = {
  distinct_on?: Maybe<Array<GoogleChannel_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<GoogleChannel_Order_By>>;
  where?: Maybe<GoogleChannel_Bool_Exp>;
};

/** query root */
export type Query_RootGoogleChannel_By_PkArgs = {
  id: Scalars['Int'];
};

/** query root */
export type Query_RootHubspotContactListArgs = {
  distinct_on?: Maybe<Array<HubspotContactList_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HubspotContactList_Order_By>>;
  where?: Maybe<HubspotContactList_Bool_Exp>;
};

/** query root */
export type Query_RootHubspotContactListHubspotContactArgs = {
  distinct_on?: Maybe<Array<HubspotContactListHubspotContact_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HubspotContactListHubspotContact_Order_By>>;
  where?: Maybe<HubspotContactListHubspotContact_Bool_Exp>;
};

/** query root */
export type Query_RootHubspotContactListHubspotContact_AggregateArgs = {
  distinct_on?: Maybe<Array<HubspotContactListHubspotContact_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HubspotContactListHubspotContact_Order_By>>;
  where?: Maybe<HubspotContactListHubspotContact_Bool_Exp>;
};

/** query root */
export type Query_RootHubspotContactListHubspotContact_By_PkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootHubspotContactList_AggregateArgs = {
  distinct_on?: Maybe<Array<HubspotContactList_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HubspotContactList_Order_By>>;
  where?: Maybe<HubspotContactList_Bool_Exp>;
};

/** query root */
export type Query_RootHubspotContactList_By_PkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootHubspotContactPropertyArgs = {
  distinct_on?: Maybe<Array<HubspotContactProperty_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HubspotContactProperty_Order_By>>;
  where?: Maybe<HubspotContactProperty_Bool_Exp>;
};

/** query root */
export type Query_RootHubspotContactProperty_AggregateArgs = {
  distinct_on?: Maybe<Array<HubspotContactProperty_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HubspotContactProperty_Order_By>>;
  where?: Maybe<HubspotContactProperty_Bool_Exp>;
};

/** query root */
export type Query_RootHubspotContactProperty_By_PkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootHubspotIntegrationArgs = {
  distinct_on?: Maybe<Array<HubspotIntegration_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HubspotIntegration_Order_By>>;
  where?: Maybe<HubspotIntegration_Bool_Exp>;
};

/** query root */
export type Query_RootHubspotIntegration_AggregateArgs = {
  distinct_on?: Maybe<Array<HubspotIntegration_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HubspotIntegration_Order_By>>;
  where?: Maybe<HubspotIntegration_Bool_Exp>;
};

/** query root */
export type Query_RootHubspotIntegration_By_PkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootJobArgs = {
  distinct_on?: Maybe<Array<Job_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Job_Order_By>>;
  where?: Maybe<Job_Bool_Exp>;
};

/** query root */
export type Query_RootJob_AggregateArgs = {
  distinct_on?: Maybe<Array<Job_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Job_Order_By>>;
  where?: Maybe<Job_Bool_Exp>;
};

/** query root */
export type Query_RootJob_By_PkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootProxycrawlDataArgs = {
  distinct_on?: Maybe<Array<ProxycrawlData_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProxycrawlData_Order_By>>;
  where?: Maybe<ProxycrawlData_Bool_Exp>;
};

/** query root */
export type Query_RootProxycrawlData_AggregateArgs = {
  distinct_on?: Maybe<Array<ProxycrawlData_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProxycrawlData_Order_By>>;
  where?: Maybe<ProxycrawlData_Bool_Exp>;
};

/** query root */
export type Query_RootProxycrawlData_By_PkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootQaQualificationArgs = {
  distinct_on?: Maybe<Array<QaQualification_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<QaQualification_Order_By>>;
  where?: Maybe<QaQualification_Bool_Exp>;
};

/** query root */
export type Query_RootQaQualification_AggregateArgs = {
  distinct_on?: Maybe<Array<QaQualification_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<QaQualification_Order_By>>;
  where?: Maybe<QaQualification_Bool_Exp>;
};

/** query root */
export type Query_RootQaQualification_By_PkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootQaTaskArgs = {
  distinct_on?: Maybe<Array<QaTask_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<QaTask_Order_By>>;
  where?: Maybe<QaTask_Bool_Exp>;
};

/** query root */
export type Query_RootQaTaskActionArgs = {
  distinct_on?: Maybe<Array<QaTaskAction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<QaTaskAction_Order_By>>;
  where?: Maybe<QaTaskAction_Bool_Exp>;
};

/** query root */
export type Query_RootQaTaskAction_AggregateArgs = {
  distinct_on?: Maybe<Array<QaTaskAction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<QaTaskAction_Order_By>>;
  where?: Maybe<QaTaskAction_Bool_Exp>;
};

/** query root */
export type Query_RootQaTaskAction_By_PkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootQaTask_AggregateArgs = {
  distinct_on?: Maybe<Array<QaTask_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<QaTask_Order_By>>;
  where?: Maybe<QaTask_Bool_Exp>;
};

/** query root */
export type Query_RootQaTask_By_PkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootSalesforceAccountArgs = {
  distinct_on?: Maybe<Array<SalesforceAccount_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SalesforceAccount_Order_By>>;
  where?: Maybe<SalesforceAccount_Bool_Exp>;
};

/** query root */
export type Query_RootSalesforceAccount_AggregateArgs = {
  distinct_on?: Maybe<Array<SalesforceAccount_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SalesforceAccount_Order_By>>;
  where?: Maybe<SalesforceAccount_Bool_Exp>;
};

/** query root */
export type Query_RootSalesforceAccount_By_PkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootSalesforceContactFieldArgs = {
  distinct_on?: Maybe<Array<SalesforceContactField_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SalesforceContactField_Order_By>>;
  where?: Maybe<SalesforceContactField_Bool_Exp>;
};

/** query root */
export type Query_RootSalesforceContactField_AggregateArgs = {
  distinct_on?: Maybe<Array<SalesforceContactField_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SalesforceContactField_Order_By>>;
  where?: Maybe<SalesforceContactField_Bool_Exp>;
};

/** query root */
export type Query_RootSalesforceContactField_By_PkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootSalesforceIntegrationArgs = {
  distinct_on?: Maybe<Array<SalesforceIntegration_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SalesforceIntegration_Order_By>>;
  where?: Maybe<SalesforceIntegration_Bool_Exp>;
};

/** query root */
export type Query_RootSalesforceIntegration_AggregateArgs = {
  distinct_on?: Maybe<Array<SalesforceIntegration_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SalesforceIntegration_Order_By>>;
  where?: Maybe<SalesforceIntegration_Bool_Exp>;
};

/** query root */
export type Query_RootSalesforceIntegration_By_PkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootSalesforceOpportunityArgs = {
  distinct_on?: Maybe<Array<SalesforceOpportunity_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SalesforceOpportunity_Order_By>>;
  where?: Maybe<SalesforceOpportunity_Bool_Exp>;
};

/** query root */
export type Query_RootSalesforceOpportunity_AggregateArgs = {
  distinct_on?: Maybe<Array<SalesforceOpportunity_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SalesforceOpportunity_Order_By>>;
  where?: Maybe<SalesforceOpportunity_Bool_Exp>;
};

/** query root */
export type Query_RootSalesforceOpportunity_By_PkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootSendgridEventArgs = {
  distinct_on?: Maybe<Array<SendgridEvent_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SendgridEvent_Order_By>>;
  where?: Maybe<SendgridEvent_Bool_Exp>;
};

/** query root */
export type Query_RootSendgridEvent_AggregateArgs = {
  distinct_on?: Maybe<Array<SendgridEvent_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SendgridEvent_Order_By>>;
  where?: Maybe<SendgridEvent_Bool_Exp>;
};

/** query root */
export type Query_RootSendgridEvent_By_PkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootTerritoryArgs = {
  distinct_on?: Maybe<Array<Territory_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Territory_Order_By>>;
  where?: Maybe<Territory_Bool_Exp>;
};

/** query root */
export type Query_RootTerritorySearchTermArgs = {
  distinct_on?: Maybe<Array<TerritorySearchTerm_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<TerritorySearchTerm_Order_By>>;
  where?: Maybe<TerritorySearchTerm_Bool_Exp>;
};

/** query root */
export type Query_RootTerritorySearchTerm_AggregateArgs = {
  distinct_on?: Maybe<Array<TerritorySearchTerm_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<TerritorySearchTerm_Order_By>>;
  where?: Maybe<TerritorySearchTerm_Bool_Exp>;
};

/** query root */
export type Query_RootTerritorySearchTerm_By_PkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootTerritory_AggregateArgs = {
  distinct_on?: Maybe<Array<Territory_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Territory_Order_By>>;
  where?: Maybe<Territory_Bool_Exp>;
};

/** query root */
export type Query_RootTerritory_By_PkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootTheOrgSearchTermArgs = {
  distinct_on?: Maybe<Array<TheOrgSearchTerm_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<TheOrgSearchTerm_Order_By>>;
  where?: Maybe<TheOrgSearchTerm_Bool_Exp>;
};

/** query root */
export type Query_RootTheOrgSearchTerm_AggregateArgs = {
  distinct_on?: Maybe<Array<TheOrgSearchTerm_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<TheOrgSearchTerm_Order_By>>;
  where?: Maybe<TheOrgSearchTerm_Bool_Exp>;
};

/** query root */
export type Query_RootTheOrgSearchTerm_By_PkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootUserArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};

/** query root */
export type Query_RootUserInviteArgs = {
  distinct_on?: Maybe<Array<UserInvite_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserInvite_Order_By>>;
  where?: Maybe<UserInvite_Bool_Exp>;
};

/** query root */
export type Query_RootUserInvite_AggregateArgs = {
  distinct_on?: Maybe<Array<UserInvite_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserInvite_Order_By>>;
  where?: Maybe<UserInvite_Bool_Exp>;
};

/** query root */
export type Query_RootUserInvite_By_PkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootUser_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};

/** query root */
export type Query_RootUser_By_PkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootWarmCompanyArgs = {
  distinct_on?: Maybe<Array<WarmCompany_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<WarmCompany_Order_By>>;
  where?: Maybe<WarmCompany_Bool_Exp>;
};

/** query root */
export type Query_RootWarmCompany_AggregateArgs = {
  distinct_on?: Maybe<Array<WarmCompany_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<WarmCompany_Order_By>>;
  where?: Maybe<WarmCompany_Bool_Exp>;
};

/** query root */
export type Query_RootZoomCompanyArgs = {
  distinct_on?: Maybe<Array<ZoomCompany_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ZoomCompany_Order_By>>;
  where?: Maybe<ZoomCompany_Bool_Exp>;
};

/** query root */
export type Query_RootZoomCompany_AggregateArgs = {
  distinct_on?: Maybe<Array<ZoomCompany_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ZoomCompany_Order_By>>;
  where?: Maybe<ZoomCompany_Bool_Exp>;
};

/** query root */
export type Query_RootZoomCompany_By_PkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootZoomContactArgs = {
  distinct_on?: Maybe<Array<ZoomContact_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ZoomContact_Order_By>>;
  where?: Maybe<ZoomContact_Bool_Exp>;
};

/** query root */
export type Query_RootZoomContactEnrichmentHistoryArgs = {
  distinct_on?: Maybe<Array<ZoomContactEnrichmentHistory_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ZoomContactEnrichmentHistory_Order_By>>;
  where?: Maybe<ZoomContactEnrichmentHistory_Bool_Exp>;
};

/** query root */
export type Query_RootZoomContactEnrichmentHistory_AggregateArgs = {
  distinct_on?: Maybe<Array<ZoomContactEnrichmentHistory_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ZoomContactEnrichmentHistory_Order_By>>;
  where?: Maybe<ZoomContactEnrichmentHistory_Bool_Exp>;
};

/** query root */
export type Query_RootZoomContactEnrichmentHistory_By_PkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootZoomContactInfoArgs = {
  distinct_on?: Maybe<Array<ZoomContactInfo_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ZoomContactInfo_Order_By>>;
  where?: Maybe<ZoomContactInfo_Bool_Exp>;
};

/** query root */
export type Query_RootZoomContactInfo_AggregateArgs = {
  distinct_on?: Maybe<Array<ZoomContactInfo_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ZoomContactInfo_Order_By>>;
  where?: Maybe<ZoomContactInfo_Bool_Exp>;
};

/** query root */
export type Query_RootZoomContactInfo_By_PkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootZoomContactNotesArgs = {
  distinct_on?: Maybe<Array<ZoomContactNotes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ZoomContactNotes_Order_By>>;
  where?: Maybe<ZoomContactNotes_Bool_Exp>;
};

/** query root */
export type Query_RootZoomContactNotes_AggregateArgs = {
  distinct_on?: Maybe<Array<ZoomContactNotes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ZoomContactNotes_Order_By>>;
  where?: Maybe<ZoomContactNotes_Bool_Exp>;
};

/** query root */
export type Query_RootZoomContactNotes_By_PkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootZoomContact_AggregateArgs = {
  distinct_on?: Maybe<Array<ZoomContact_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ZoomContact_Order_By>>;
  where?: Maybe<ZoomContact_Bool_Exp>;
};

/** query root */
export type Query_RootZoomContact_By_PkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootZoomEmailHistoryArgs = {
  distinct_on?: Maybe<Array<ZoomEmailHistory_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ZoomEmailHistory_Order_By>>;
  where?: Maybe<ZoomEmailHistory_Bool_Exp>;
};

/** query root */
export type Query_RootZoomEmailHistory_AggregateArgs = {
  distinct_on?: Maybe<Array<ZoomEmailHistory_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ZoomEmailHistory_Order_By>>;
  where?: Maybe<ZoomEmailHistory_Bool_Exp>;
};

/** query root */
export type Query_RootZoomEmailHistory_By_PkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootZoomEventArgs = {
  distinct_on?: Maybe<Array<ZoomEvent_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ZoomEvent_Order_By>>;
  where?: Maybe<ZoomEvent_Bool_Exp>;
};

/** query root */
export type Query_RootZoomEventParticipantArgs = {
  distinct_on?: Maybe<Array<ZoomEventParticipant_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ZoomEventParticipant_Order_By>>;
  where?: Maybe<ZoomEventParticipant_Bool_Exp>;
};

/** query root */
export type Query_RootZoomEventParticipant_AggregateArgs = {
  distinct_on?: Maybe<Array<ZoomEventParticipant_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ZoomEventParticipant_Order_By>>;
  where?: Maybe<ZoomEventParticipant_Bool_Exp>;
};

/** query root */
export type Query_RootZoomEventParticipant_By_PkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootZoomEvent_AggregateArgs = {
  distinct_on?: Maybe<Array<ZoomEvent_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ZoomEvent_Order_By>>;
  where?: Maybe<ZoomEvent_Bool_Exp>;
};

/** query root */
export type Query_RootZoomEvent_By_PkArgs = {
  id: Scalars['Int'];
};

/** query root */
export type Query_RootZoomExternalAuthUserArgs = {
  distinct_on?: Maybe<Array<ZoomExternalAuthUser_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ZoomExternalAuthUser_Order_By>>;
  where?: Maybe<ZoomExternalAuthUser_Bool_Exp>;
};

/** query root */
export type Query_RootZoomExternalAuthUser_AggregateArgs = {
  distinct_on?: Maybe<Array<ZoomExternalAuthUser_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ZoomExternalAuthUser_Order_By>>;
  where?: Maybe<ZoomExternalAuthUser_Bool_Exp>;
};

/** query root */
export type Query_RootZoomExternalAuthUser_By_PkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type Query_RootZoomUserArgs = {
  distinct_on?: Maybe<Array<ZoomUser_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ZoomUser_Order_By>>;
  where?: Maybe<ZoomUser_Bool_Exp>;
};

/** query root */
export type Query_RootZoomUser_AggregateArgs = {
  distinct_on?: Maybe<Array<ZoomUser_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ZoomUser_Order_By>>;
  where?: Maybe<ZoomUser_Bool_Exp>;
};

/** query root */
export type Query_RootZoomUser_By_PkArgs = {
  id: Scalars['uuid'];
};

/** columns and relationships of "salesforceAccount" */
export type SalesforceAccount = {
  __typename?: 'salesforceAccount';
  clientId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  ownerId: Scalars['String'];
  salesforceId: Scalars['String'];
  salesforceIntegrationId: Scalars['uuid'];
  type?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
  website?: Maybe<Scalars['String']>;
  websiteDomain?: Maybe<Scalars['String']>;
  websiteRootDomain?: Maybe<Scalars['String']>;
};

/** aggregated selection of "salesforceAccount" */
export type SalesforceAccount_Aggregate = {
  __typename?: 'salesforceAccount_aggregate';
  aggregate?: Maybe<SalesforceAccount_Aggregate_Fields>;
  nodes: Array<SalesforceAccount>;
};

/** aggregate fields of "salesforceAccount" */
export type SalesforceAccount_Aggregate_Fields = {
  __typename?: 'salesforceAccount_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<SalesforceAccount_Max_Fields>;
  min?: Maybe<SalesforceAccount_Min_Fields>;
};

/** aggregate fields of "salesforceAccount" */
export type SalesforceAccount_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<SalesforceAccount_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "salesforceAccount" */
export type SalesforceAccount_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<SalesforceAccount_Max_Order_By>;
  min?: Maybe<SalesforceAccount_Min_Order_By>;
};

/** input type for inserting array relation for remote table "salesforceAccount" */
export type SalesforceAccount_Arr_Rel_Insert_Input = {
  data: Array<SalesforceAccount_Insert_Input>;
  on_conflict?: Maybe<SalesforceAccount_On_Conflict>;
};

/** Boolean expression to filter rows from the table "salesforceAccount". All fields are combined with a logical 'AND'. */
export type SalesforceAccount_Bool_Exp = {
  _and?: Maybe<Array<Maybe<SalesforceAccount_Bool_Exp>>>;
  _not?: Maybe<SalesforceAccount_Bool_Exp>;
  _or?: Maybe<Array<Maybe<SalesforceAccount_Bool_Exp>>>;
  clientId?: Maybe<Uuid_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  ownerId?: Maybe<String_Comparison_Exp>;
  salesforceId?: Maybe<String_Comparison_Exp>;
  salesforceIntegrationId?: Maybe<Uuid_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
  website?: Maybe<String_Comparison_Exp>;
  websiteDomain?: Maybe<String_Comparison_Exp>;
  websiteRootDomain?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "salesforceAccount" */
export enum SalesforceAccount_Constraint {
  /** unique or primary key constraint */
  SalesforceAccountPkey = 'salesforceAccount_pkey',
  /** unique or primary key constraint */
  SalesforceAccountSalesforceIdKey = 'salesforceAccount_salesforceId_key',
}

/** input type for inserting data into table "salesforceAccount" */
export type SalesforceAccount_Insert_Input = {
  clientId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['String']>;
  salesforceId?: Maybe<Scalars['String']>;
  salesforceIntegrationId?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  website?: Maybe<Scalars['String']>;
  websiteDomain?: Maybe<Scalars['String']>;
  websiteRootDomain?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type SalesforceAccount_Max_Fields = {
  __typename?: 'salesforceAccount_max_fields';
  clientId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['String']>;
  salesforceId?: Maybe<Scalars['String']>;
  salesforceIntegrationId?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  website?: Maybe<Scalars['String']>;
  websiteDomain?: Maybe<Scalars['String']>;
  websiteRootDomain?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "salesforceAccount" */
export type SalesforceAccount_Max_Order_By = {
  clientId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  ownerId?: Maybe<Order_By>;
  salesforceId?: Maybe<Order_By>;
  salesforceIntegrationId?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  website?: Maybe<Order_By>;
  websiteDomain?: Maybe<Order_By>;
  websiteRootDomain?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type SalesforceAccount_Min_Fields = {
  __typename?: 'salesforceAccount_min_fields';
  clientId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['String']>;
  salesforceId?: Maybe<Scalars['String']>;
  salesforceIntegrationId?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  website?: Maybe<Scalars['String']>;
  websiteDomain?: Maybe<Scalars['String']>;
  websiteRootDomain?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "salesforceAccount" */
export type SalesforceAccount_Min_Order_By = {
  clientId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  ownerId?: Maybe<Order_By>;
  salesforceId?: Maybe<Order_By>;
  salesforceIntegrationId?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  website?: Maybe<Order_By>;
  websiteDomain?: Maybe<Order_By>;
  websiteRootDomain?: Maybe<Order_By>;
};

/** response of any mutation on the table "salesforceAccount" */
export type SalesforceAccount_Mutation_Response = {
  __typename?: 'salesforceAccount_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<SalesforceAccount>;
};

/** input type for inserting object relation for remote table "salesforceAccount" */
export type SalesforceAccount_Obj_Rel_Insert_Input = {
  data: SalesforceAccount_Insert_Input;
  on_conflict?: Maybe<SalesforceAccount_On_Conflict>;
};

/** on conflict condition type for table "salesforceAccount" */
export type SalesforceAccount_On_Conflict = {
  constraint: SalesforceAccount_Constraint;
  update_columns: Array<SalesforceAccount_Update_Column>;
  where?: Maybe<SalesforceAccount_Bool_Exp>;
};

/** ordering options when selecting data from "salesforceAccount" */
export type SalesforceAccount_Order_By = {
  clientId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  ownerId?: Maybe<Order_By>;
  salesforceId?: Maybe<Order_By>;
  salesforceIntegrationId?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  website?: Maybe<Order_By>;
  websiteDomain?: Maybe<Order_By>;
  websiteRootDomain?: Maybe<Order_By>;
};

/** primary key columns input for table: "salesforceAccount" */
export type SalesforceAccount_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "salesforceAccount" */
export enum SalesforceAccount_Select_Column {
  /** column name */
  ClientId = 'clientId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OwnerId = 'ownerId',
  /** column name */
  SalesforceId = 'salesforceId',
  /** column name */
  SalesforceIntegrationId = 'salesforceIntegrationId',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Website = 'website',
  /** column name */
  WebsiteDomain = 'websiteDomain',
  /** column name */
  WebsiteRootDomain = 'websiteRootDomain',
}

/** input type for updating data in table "salesforceAccount" */
export type SalesforceAccount_Set_Input = {
  clientId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['String']>;
  salesforceId?: Maybe<Scalars['String']>;
  salesforceIntegrationId?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  website?: Maybe<Scalars['String']>;
  websiteDomain?: Maybe<Scalars['String']>;
  websiteRootDomain?: Maybe<Scalars['String']>;
};

/** update columns of table "salesforceAccount" */
export enum SalesforceAccount_Update_Column {
  /** column name */
  ClientId = 'clientId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OwnerId = 'ownerId',
  /** column name */
  SalesforceId = 'salesforceId',
  /** column name */
  SalesforceIntegrationId = 'salesforceIntegrationId',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Website = 'website',
  /** column name */
  WebsiteDomain = 'websiteDomain',
  /** column name */
  WebsiteRootDomain = 'websiteRootDomain',
}

/** columns and relationships of "salesforceContactField" */
export type SalesforceContactField = {
  __typename?: 'salesforceContactField';
  clientId: Scalars['uuid'];
  id: Scalars['uuid'];
  json?: Maybe<Scalars['jsonb']>;
  label?: Maybe<Scalars['String']>;
  lastSyncedAt?: Maybe<Scalars['timestamptz']>;
  name: Scalars['String'];
  salesforceIntegrationId: Scalars['uuid'];
};

/** columns and relationships of "salesforceContactField" */
export type SalesforceContactFieldJsonArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "salesforceContactField" */
export type SalesforceContactField_Aggregate = {
  __typename?: 'salesforceContactField_aggregate';
  aggregate?: Maybe<SalesforceContactField_Aggregate_Fields>;
  nodes: Array<SalesforceContactField>;
};

/** aggregate fields of "salesforceContactField" */
export type SalesforceContactField_Aggregate_Fields = {
  __typename?: 'salesforceContactField_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<SalesforceContactField_Max_Fields>;
  min?: Maybe<SalesforceContactField_Min_Fields>;
};

/** aggregate fields of "salesforceContactField" */
export type SalesforceContactField_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<SalesforceContactField_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "salesforceContactField" */
export type SalesforceContactField_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<SalesforceContactField_Max_Order_By>;
  min?: Maybe<SalesforceContactField_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type SalesforceContactField_Append_Input = {
  json?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "salesforceContactField" */
export type SalesforceContactField_Arr_Rel_Insert_Input = {
  data: Array<SalesforceContactField_Insert_Input>;
  on_conflict?: Maybe<SalesforceContactField_On_Conflict>;
};

/** Boolean expression to filter rows from the table "salesforceContactField". All fields are combined with a logical 'AND'. */
export type SalesforceContactField_Bool_Exp = {
  _and?: Maybe<Array<Maybe<SalesforceContactField_Bool_Exp>>>;
  _not?: Maybe<SalesforceContactField_Bool_Exp>;
  _or?: Maybe<Array<Maybe<SalesforceContactField_Bool_Exp>>>;
  clientId?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  json?: Maybe<Jsonb_Comparison_Exp>;
  label?: Maybe<String_Comparison_Exp>;
  lastSyncedAt?: Maybe<Timestamptz_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  salesforceIntegrationId?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "salesforceContactField" */
export enum SalesforceContactField_Constraint {
  /** unique or primary key constraint */
  SalesforceContactFieldPkey = 'salesforceContactField_pkey',
  /** unique or primary key constraint */
  SalesforceContactFieldSalesforceIntegrationIdNameKey = 'salesforceContactField_salesforceIntegrationId_name_key',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type SalesforceContactField_Delete_At_Path_Input = {
  json?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type SalesforceContactField_Delete_Elem_Input = {
  json?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type SalesforceContactField_Delete_Key_Input = {
  json?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "salesforceContactField" */
export type SalesforceContactField_Insert_Input = {
  clientId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  json?: Maybe<Scalars['jsonb']>;
  label?: Maybe<Scalars['String']>;
  lastSyncedAt?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  salesforceIntegrationId?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type SalesforceContactField_Max_Fields = {
  __typename?: 'salesforceContactField_max_fields';
  clientId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  label?: Maybe<Scalars['String']>;
  lastSyncedAt?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  salesforceIntegrationId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "salesforceContactField" */
export type SalesforceContactField_Max_Order_By = {
  clientId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  label?: Maybe<Order_By>;
  lastSyncedAt?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  salesforceIntegrationId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type SalesforceContactField_Min_Fields = {
  __typename?: 'salesforceContactField_min_fields';
  clientId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  label?: Maybe<Scalars['String']>;
  lastSyncedAt?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  salesforceIntegrationId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "salesforceContactField" */
export type SalesforceContactField_Min_Order_By = {
  clientId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  label?: Maybe<Order_By>;
  lastSyncedAt?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  salesforceIntegrationId?: Maybe<Order_By>;
};

/** response of any mutation on the table "salesforceContactField" */
export type SalesforceContactField_Mutation_Response = {
  __typename?: 'salesforceContactField_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<SalesforceContactField>;
};

/** input type for inserting object relation for remote table "salesforceContactField" */
export type SalesforceContactField_Obj_Rel_Insert_Input = {
  data: SalesforceContactField_Insert_Input;
  on_conflict?: Maybe<SalesforceContactField_On_Conflict>;
};

/** on conflict condition type for table "salesforceContactField" */
export type SalesforceContactField_On_Conflict = {
  constraint: SalesforceContactField_Constraint;
  update_columns: Array<SalesforceContactField_Update_Column>;
  where?: Maybe<SalesforceContactField_Bool_Exp>;
};

/** ordering options when selecting data from "salesforceContactField" */
export type SalesforceContactField_Order_By = {
  clientId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  json?: Maybe<Order_By>;
  label?: Maybe<Order_By>;
  lastSyncedAt?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  salesforceIntegrationId?: Maybe<Order_By>;
};

/** primary key columns input for table: "salesforceContactField" */
export type SalesforceContactField_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type SalesforceContactField_Prepend_Input = {
  json?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "salesforceContactField" */
export enum SalesforceContactField_Select_Column {
  /** column name */
  ClientId = 'clientId',
  /** column name */
  Id = 'id',
  /** column name */
  Json = 'json',
  /** column name */
  Label = 'label',
  /** column name */
  LastSyncedAt = 'lastSyncedAt',
  /** column name */
  Name = 'name',
  /** column name */
  SalesforceIntegrationId = 'salesforceIntegrationId',
}

/** input type for updating data in table "salesforceContactField" */
export type SalesforceContactField_Set_Input = {
  clientId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  json?: Maybe<Scalars['jsonb']>;
  label?: Maybe<Scalars['String']>;
  lastSyncedAt?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  salesforceIntegrationId?: Maybe<Scalars['uuid']>;
};

/** update columns of table "salesforceContactField" */
export enum SalesforceContactField_Update_Column {
  /** column name */
  ClientId = 'clientId',
  /** column name */
  Id = 'id',
  /** column name */
  Json = 'json',
  /** column name */
  Label = 'label',
  /** column name */
  LastSyncedAt = 'lastSyncedAt',
  /** column name */
  Name = 'name',
  /** column name */
  SalesforceIntegrationId = 'salesforceIntegrationId',
}

/** columns and relationships of "salesforceIntegration" */
export type SalesforceIntegration = {
  __typename?: 'salesforceIntegration';
  accessToken?: Maybe<Scalars['String']>;
  accountsLastSyncedAt?: Maybe<Scalars['timestamptz']>;
  clientId: Scalars['uuid'];
  contactsLastSyncedAt?: Maybe<Scalars['timestamptz']>;
  contactsLastSyncedCount?: Maybe<Scalars['Int']>;
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  instanceUrl?: Maybe<Scalars['String']>;
  mapping?: Maybe<Scalars['jsonb']>;
  opportunitiesLastSyncedAt?: Maybe<Scalars['timestamptz']>;
  refreshToken?: Maybe<Scalars['String']>;
  /** An array relationship */
  salesforceContactFields: Array<SalesforceContactField>;
  /** An aggregated array relationship */
  salesforceContactFields_aggregate: SalesforceContactField_Aggregate;
  totalContactCount?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['timestamptz'];
  warmlyContactFieldsCreatedAt?: Maybe<Scalars['timestamptz']>;
  warmlyContactFieldsVersion?: Maybe<Scalars['String']>;
};

/** columns and relationships of "salesforceIntegration" */
export type SalesforceIntegrationMappingArgs = {
  path?: Maybe<Scalars['String']>;
};

/** columns and relationships of "salesforceIntegration" */
export type SalesforceIntegrationSalesforceContactFieldsArgs = {
  distinct_on?: Maybe<Array<SalesforceContactField_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SalesforceContactField_Order_By>>;
  where?: Maybe<SalesforceContactField_Bool_Exp>;
};

/** columns and relationships of "salesforceIntegration" */
export type SalesforceIntegrationSalesforceContactFields_AggregateArgs = {
  distinct_on?: Maybe<Array<SalesforceContactField_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SalesforceContactField_Order_By>>;
  where?: Maybe<SalesforceContactField_Bool_Exp>;
};

/** aggregated selection of "salesforceIntegration" */
export type SalesforceIntegration_Aggregate = {
  __typename?: 'salesforceIntegration_aggregate';
  aggregate?: Maybe<SalesforceIntegration_Aggregate_Fields>;
  nodes: Array<SalesforceIntegration>;
};

/** aggregate fields of "salesforceIntegration" */
export type SalesforceIntegration_Aggregate_Fields = {
  __typename?: 'salesforceIntegration_aggregate_fields';
  avg?: Maybe<SalesforceIntegration_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<SalesforceIntegration_Max_Fields>;
  min?: Maybe<SalesforceIntegration_Min_Fields>;
  stddev?: Maybe<SalesforceIntegration_Stddev_Fields>;
  stddev_pop?: Maybe<SalesforceIntegration_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<SalesforceIntegration_Stddev_Samp_Fields>;
  sum?: Maybe<SalesforceIntegration_Sum_Fields>;
  var_pop?: Maybe<SalesforceIntegration_Var_Pop_Fields>;
  var_samp?: Maybe<SalesforceIntegration_Var_Samp_Fields>;
  variance?: Maybe<SalesforceIntegration_Variance_Fields>;
};

/** aggregate fields of "salesforceIntegration" */
export type SalesforceIntegration_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<SalesforceIntegration_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "salesforceIntegration" */
export type SalesforceIntegration_Aggregate_Order_By = {
  avg?: Maybe<SalesforceIntegration_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<SalesforceIntegration_Max_Order_By>;
  min?: Maybe<SalesforceIntegration_Min_Order_By>;
  stddev?: Maybe<SalesforceIntegration_Stddev_Order_By>;
  stddev_pop?: Maybe<SalesforceIntegration_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<SalesforceIntegration_Stddev_Samp_Order_By>;
  sum?: Maybe<SalesforceIntegration_Sum_Order_By>;
  var_pop?: Maybe<SalesforceIntegration_Var_Pop_Order_By>;
  var_samp?: Maybe<SalesforceIntegration_Var_Samp_Order_By>;
  variance?: Maybe<SalesforceIntegration_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type SalesforceIntegration_Append_Input = {
  mapping?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "salesforceIntegration" */
export type SalesforceIntegration_Arr_Rel_Insert_Input = {
  data: Array<SalesforceIntegration_Insert_Input>;
  on_conflict?: Maybe<SalesforceIntegration_On_Conflict>;
};

/** aggregate avg on columns */
export type SalesforceIntegration_Avg_Fields = {
  __typename?: 'salesforceIntegration_avg_fields';
  contactsLastSyncedCount?: Maybe<Scalars['Float']>;
  totalContactCount?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "salesforceIntegration" */
export type SalesforceIntegration_Avg_Order_By = {
  contactsLastSyncedCount?: Maybe<Order_By>;
  totalContactCount?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "salesforceIntegration". All fields are combined with a logical 'AND'. */
export type SalesforceIntegration_Bool_Exp = {
  _and?: Maybe<Array<Maybe<SalesforceIntegration_Bool_Exp>>>;
  _not?: Maybe<SalesforceIntegration_Bool_Exp>;
  _or?: Maybe<Array<Maybe<SalesforceIntegration_Bool_Exp>>>;
  accessToken?: Maybe<String_Comparison_Exp>;
  accountsLastSyncedAt?: Maybe<Timestamptz_Comparison_Exp>;
  clientId?: Maybe<Uuid_Comparison_Exp>;
  contactsLastSyncedAt?: Maybe<Timestamptz_Comparison_Exp>;
  contactsLastSyncedCount?: Maybe<Int_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  instanceUrl?: Maybe<String_Comparison_Exp>;
  mapping?: Maybe<Jsonb_Comparison_Exp>;
  opportunitiesLastSyncedAt?: Maybe<Timestamptz_Comparison_Exp>;
  refreshToken?: Maybe<String_Comparison_Exp>;
  salesforceContactFields?: Maybe<SalesforceContactField_Bool_Exp>;
  totalContactCount?: Maybe<Int_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
  warmlyContactFieldsCreatedAt?: Maybe<Timestamptz_Comparison_Exp>;
  warmlyContactFieldsVersion?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "salesforceIntegration" */
export enum SalesforceIntegration_Constraint {
  /** unique or primary key constraint */
  SalesforceIntegrationClientIdKey = 'salesforceIntegration_clientId_key',
  /** unique or primary key constraint */
  SalesforceIntegrationPkey = 'salesforceIntegration_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type SalesforceIntegration_Delete_At_Path_Input = {
  mapping?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type SalesforceIntegration_Delete_Elem_Input = {
  mapping?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type SalesforceIntegration_Delete_Key_Input = {
  mapping?: Maybe<Scalars['String']>;
};

/** input type for incrementing integer column in table "salesforceIntegration" */
export type SalesforceIntegration_Inc_Input = {
  contactsLastSyncedCount?: Maybe<Scalars['Int']>;
  totalContactCount?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "salesforceIntegration" */
export type SalesforceIntegration_Insert_Input = {
  accessToken?: Maybe<Scalars['String']>;
  accountsLastSyncedAt?: Maybe<Scalars['timestamptz']>;
  clientId?: Maybe<Scalars['uuid']>;
  contactsLastSyncedAt?: Maybe<Scalars['timestamptz']>;
  contactsLastSyncedCount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  instanceUrl?: Maybe<Scalars['String']>;
  mapping?: Maybe<Scalars['jsonb']>;
  opportunitiesLastSyncedAt?: Maybe<Scalars['timestamptz']>;
  refreshToken?: Maybe<Scalars['String']>;
  salesforceContactFields?: Maybe<SalesforceContactField_Arr_Rel_Insert_Input>;
  totalContactCount?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  warmlyContactFieldsCreatedAt?: Maybe<Scalars['timestamptz']>;
  warmlyContactFieldsVersion?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type SalesforceIntegration_Max_Fields = {
  __typename?: 'salesforceIntegration_max_fields';
  accessToken?: Maybe<Scalars['String']>;
  accountsLastSyncedAt?: Maybe<Scalars['timestamptz']>;
  clientId?: Maybe<Scalars['uuid']>;
  contactsLastSyncedAt?: Maybe<Scalars['timestamptz']>;
  contactsLastSyncedCount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  instanceUrl?: Maybe<Scalars['String']>;
  opportunitiesLastSyncedAt?: Maybe<Scalars['timestamptz']>;
  refreshToken?: Maybe<Scalars['String']>;
  totalContactCount?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  warmlyContactFieldsCreatedAt?: Maybe<Scalars['timestamptz']>;
  warmlyContactFieldsVersion?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "salesforceIntegration" */
export type SalesforceIntegration_Max_Order_By = {
  accessToken?: Maybe<Order_By>;
  accountsLastSyncedAt?: Maybe<Order_By>;
  clientId?: Maybe<Order_By>;
  contactsLastSyncedAt?: Maybe<Order_By>;
  contactsLastSyncedCount?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  instanceUrl?: Maybe<Order_By>;
  opportunitiesLastSyncedAt?: Maybe<Order_By>;
  refreshToken?: Maybe<Order_By>;
  totalContactCount?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  warmlyContactFieldsCreatedAt?: Maybe<Order_By>;
  warmlyContactFieldsVersion?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type SalesforceIntegration_Min_Fields = {
  __typename?: 'salesforceIntegration_min_fields';
  accessToken?: Maybe<Scalars['String']>;
  accountsLastSyncedAt?: Maybe<Scalars['timestamptz']>;
  clientId?: Maybe<Scalars['uuid']>;
  contactsLastSyncedAt?: Maybe<Scalars['timestamptz']>;
  contactsLastSyncedCount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  instanceUrl?: Maybe<Scalars['String']>;
  opportunitiesLastSyncedAt?: Maybe<Scalars['timestamptz']>;
  refreshToken?: Maybe<Scalars['String']>;
  totalContactCount?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  warmlyContactFieldsCreatedAt?: Maybe<Scalars['timestamptz']>;
  warmlyContactFieldsVersion?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "salesforceIntegration" */
export type SalesforceIntegration_Min_Order_By = {
  accessToken?: Maybe<Order_By>;
  accountsLastSyncedAt?: Maybe<Order_By>;
  clientId?: Maybe<Order_By>;
  contactsLastSyncedAt?: Maybe<Order_By>;
  contactsLastSyncedCount?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  instanceUrl?: Maybe<Order_By>;
  opportunitiesLastSyncedAt?: Maybe<Order_By>;
  refreshToken?: Maybe<Order_By>;
  totalContactCount?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  warmlyContactFieldsCreatedAt?: Maybe<Order_By>;
  warmlyContactFieldsVersion?: Maybe<Order_By>;
};

/** response of any mutation on the table "salesforceIntegration" */
export type SalesforceIntegration_Mutation_Response = {
  __typename?: 'salesforceIntegration_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<SalesforceIntegration>;
};

/** input type for inserting object relation for remote table "salesforceIntegration" */
export type SalesforceIntegration_Obj_Rel_Insert_Input = {
  data: SalesforceIntegration_Insert_Input;
  on_conflict?: Maybe<SalesforceIntegration_On_Conflict>;
};

/** on conflict condition type for table "salesforceIntegration" */
export type SalesforceIntegration_On_Conflict = {
  constraint: SalesforceIntegration_Constraint;
  update_columns: Array<SalesforceIntegration_Update_Column>;
  where?: Maybe<SalesforceIntegration_Bool_Exp>;
};

/** ordering options when selecting data from "salesforceIntegration" */
export type SalesforceIntegration_Order_By = {
  accessToken?: Maybe<Order_By>;
  accountsLastSyncedAt?: Maybe<Order_By>;
  clientId?: Maybe<Order_By>;
  contactsLastSyncedAt?: Maybe<Order_By>;
  contactsLastSyncedCount?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  instanceUrl?: Maybe<Order_By>;
  mapping?: Maybe<Order_By>;
  opportunitiesLastSyncedAt?: Maybe<Order_By>;
  refreshToken?: Maybe<Order_By>;
  salesforceContactFields_aggregate?: Maybe<SalesforceContactField_Aggregate_Order_By>;
  totalContactCount?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  warmlyContactFieldsCreatedAt?: Maybe<Order_By>;
  warmlyContactFieldsVersion?: Maybe<Order_By>;
};

/** primary key columns input for table: "salesforceIntegration" */
export type SalesforceIntegration_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type SalesforceIntegration_Prepend_Input = {
  mapping?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "salesforceIntegration" */
export enum SalesforceIntegration_Select_Column {
  /** column name */
  AccessToken = 'accessToken',
  /** column name */
  AccountsLastSyncedAt = 'accountsLastSyncedAt',
  /** column name */
  ClientId = 'clientId',
  /** column name */
  ContactsLastSyncedAt = 'contactsLastSyncedAt',
  /** column name */
  ContactsLastSyncedCount = 'contactsLastSyncedCount',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  InstanceUrl = 'instanceUrl',
  /** column name */
  Mapping = 'mapping',
  /** column name */
  OpportunitiesLastSyncedAt = 'opportunitiesLastSyncedAt',
  /** column name */
  RefreshToken = 'refreshToken',
  /** column name */
  TotalContactCount = 'totalContactCount',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WarmlyContactFieldsCreatedAt = 'warmlyContactFieldsCreatedAt',
  /** column name */
  WarmlyContactFieldsVersion = 'warmlyContactFieldsVersion',
}

/** input type for updating data in table "salesforceIntegration" */
export type SalesforceIntegration_Set_Input = {
  accessToken?: Maybe<Scalars['String']>;
  accountsLastSyncedAt?: Maybe<Scalars['timestamptz']>;
  clientId?: Maybe<Scalars['uuid']>;
  contactsLastSyncedAt?: Maybe<Scalars['timestamptz']>;
  contactsLastSyncedCount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  instanceUrl?: Maybe<Scalars['String']>;
  mapping?: Maybe<Scalars['jsonb']>;
  opportunitiesLastSyncedAt?: Maybe<Scalars['timestamptz']>;
  refreshToken?: Maybe<Scalars['String']>;
  totalContactCount?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  warmlyContactFieldsCreatedAt?: Maybe<Scalars['timestamptz']>;
  warmlyContactFieldsVersion?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type SalesforceIntegration_Stddev_Fields = {
  __typename?: 'salesforceIntegration_stddev_fields';
  contactsLastSyncedCount?: Maybe<Scalars['Float']>;
  totalContactCount?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "salesforceIntegration" */
export type SalesforceIntegration_Stddev_Order_By = {
  contactsLastSyncedCount?: Maybe<Order_By>;
  totalContactCount?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type SalesforceIntegration_Stddev_Pop_Fields = {
  __typename?: 'salesforceIntegration_stddev_pop_fields';
  contactsLastSyncedCount?: Maybe<Scalars['Float']>;
  totalContactCount?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "salesforceIntegration" */
export type SalesforceIntegration_Stddev_Pop_Order_By = {
  contactsLastSyncedCount?: Maybe<Order_By>;
  totalContactCount?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type SalesforceIntegration_Stddev_Samp_Fields = {
  __typename?: 'salesforceIntegration_stddev_samp_fields';
  contactsLastSyncedCount?: Maybe<Scalars['Float']>;
  totalContactCount?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "salesforceIntegration" */
export type SalesforceIntegration_Stddev_Samp_Order_By = {
  contactsLastSyncedCount?: Maybe<Order_By>;
  totalContactCount?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type SalesforceIntegration_Sum_Fields = {
  __typename?: 'salesforceIntegration_sum_fields';
  contactsLastSyncedCount?: Maybe<Scalars['Int']>;
  totalContactCount?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "salesforceIntegration" */
export type SalesforceIntegration_Sum_Order_By = {
  contactsLastSyncedCount?: Maybe<Order_By>;
  totalContactCount?: Maybe<Order_By>;
};

/** update columns of table "salesforceIntegration" */
export enum SalesforceIntegration_Update_Column {
  /** column name */
  AccessToken = 'accessToken',
  /** column name */
  AccountsLastSyncedAt = 'accountsLastSyncedAt',
  /** column name */
  ClientId = 'clientId',
  /** column name */
  ContactsLastSyncedAt = 'contactsLastSyncedAt',
  /** column name */
  ContactsLastSyncedCount = 'contactsLastSyncedCount',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  InstanceUrl = 'instanceUrl',
  /** column name */
  Mapping = 'mapping',
  /** column name */
  OpportunitiesLastSyncedAt = 'opportunitiesLastSyncedAt',
  /** column name */
  RefreshToken = 'refreshToken',
  /** column name */
  TotalContactCount = 'totalContactCount',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WarmlyContactFieldsCreatedAt = 'warmlyContactFieldsCreatedAt',
  /** column name */
  WarmlyContactFieldsVersion = 'warmlyContactFieldsVersion',
}

/** aggregate var_pop on columns */
export type SalesforceIntegration_Var_Pop_Fields = {
  __typename?: 'salesforceIntegration_var_pop_fields';
  contactsLastSyncedCount?: Maybe<Scalars['Float']>;
  totalContactCount?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "salesforceIntegration" */
export type SalesforceIntegration_Var_Pop_Order_By = {
  contactsLastSyncedCount?: Maybe<Order_By>;
  totalContactCount?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type SalesforceIntegration_Var_Samp_Fields = {
  __typename?: 'salesforceIntegration_var_samp_fields';
  contactsLastSyncedCount?: Maybe<Scalars['Float']>;
  totalContactCount?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "salesforceIntegration" */
export type SalesforceIntegration_Var_Samp_Order_By = {
  contactsLastSyncedCount?: Maybe<Order_By>;
  totalContactCount?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type SalesforceIntegration_Variance_Fields = {
  __typename?: 'salesforceIntegration_variance_fields';
  contactsLastSyncedCount?: Maybe<Scalars['Float']>;
  totalContactCount?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "salesforceIntegration" */
export type SalesforceIntegration_Variance_Order_By = {
  contactsLastSyncedCount?: Maybe<Order_By>;
  totalContactCount?: Maybe<Order_By>;
};

/** columns and relationships of "salesforceOpportunity" */
export type SalesforceOpportunity = {
  __typename?: 'salesforceOpportunity';
  accountId?: Maybe<Scalars['String']>;
  clientId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  ownerId?: Maybe<Scalars['String']>;
  salesforceId: Scalars['String'];
  salesforceIntegrationId: Scalars['uuid'];
  stage: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
};

/** aggregated selection of "salesforceOpportunity" */
export type SalesforceOpportunity_Aggregate = {
  __typename?: 'salesforceOpportunity_aggregate';
  aggregate?: Maybe<SalesforceOpportunity_Aggregate_Fields>;
  nodes: Array<SalesforceOpportunity>;
};

/** aggregate fields of "salesforceOpportunity" */
export type SalesforceOpportunity_Aggregate_Fields = {
  __typename?: 'salesforceOpportunity_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<SalesforceOpportunity_Max_Fields>;
  min?: Maybe<SalesforceOpportunity_Min_Fields>;
};

/** aggregate fields of "salesforceOpportunity" */
export type SalesforceOpportunity_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<SalesforceOpportunity_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "salesforceOpportunity" */
export type SalesforceOpportunity_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<SalesforceOpportunity_Max_Order_By>;
  min?: Maybe<SalesforceOpportunity_Min_Order_By>;
};

/** input type for inserting array relation for remote table "salesforceOpportunity" */
export type SalesforceOpportunity_Arr_Rel_Insert_Input = {
  data: Array<SalesforceOpportunity_Insert_Input>;
  on_conflict?: Maybe<SalesforceOpportunity_On_Conflict>;
};

/** Boolean expression to filter rows from the table "salesforceOpportunity". All fields are combined with a logical 'AND'. */
export type SalesforceOpportunity_Bool_Exp = {
  _and?: Maybe<Array<Maybe<SalesforceOpportunity_Bool_Exp>>>;
  _not?: Maybe<SalesforceOpportunity_Bool_Exp>;
  _or?: Maybe<Array<Maybe<SalesforceOpportunity_Bool_Exp>>>;
  accountId?: Maybe<String_Comparison_Exp>;
  clientId?: Maybe<Uuid_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  ownerId?: Maybe<String_Comparison_Exp>;
  salesforceId?: Maybe<String_Comparison_Exp>;
  salesforceIntegrationId?: Maybe<Uuid_Comparison_Exp>;
  stage?: Maybe<String_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "salesforceOpportunity" */
export enum SalesforceOpportunity_Constraint {
  /** unique or primary key constraint */
  SalesforceOpportunityPkey = 'salesforceOpportunity_pkey',
  /** unique or primary key constraint */
  SalesforceOpportunitySalesforceIdKey = 'salesforceOpportunity_salesforceId_key',
}

/** input type for inserting data into table "salesforceOpportunity" */
export type SalesforceOpportunity_Insert_Input = {
  accountId?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['String']>;
  salesforceId?: Maybe<Scalars['String']>;
  salesforceIntegrationId?: Maybe<Scalars['uuid']>;
  stage?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type SalesforceOpportunity_Max_Fields = {
  __typename?: 'salesforceOpportunity_max_fields';
  accountId?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['String']>;
  salesforceId?: Maybe<Scalars['String']>;
  salesforceIntegrationId?: Maybe<Scalars['uuid']>;
  stage?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "salesforceOpportunity" */
export type SalesforceOpportunity_Max_Order_By = {
  accountId?: Maybe<Order_By>;
  clientId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  ownerId?: Maybe<Order_By>;
  salesforceId?: Maybe<Order_By>;
  salesforceIntegrationId?: Maybe<Order_By>;
  stage?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type SalesforceOpportunity_Min_Fields = {
  __typename?: 'salesforceOpportunity_min_fields';
  accountId?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['String']>;
  salesforceId?: Maybe<Scalars['String']>;
  salesforceIntegrationId?: Maybe<Scalars['uuid']>;
  stage?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "salesforceOpportunity" */
export type SalesforceOpportunity_Min_Order_By = {
  accountId?: Maybe<Order_By>;
  clientId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  ownerId?: Maybe<Order_By>;
  salesforceId?: Maybe<Order_By>;
  salesforceIntegrationId?: Maybe<Order_By>;
  stage?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** response of any mutation on the table "salesforceOpportunity" */
export type SalesforceOpportunity_Mutation_Response = {
  __typename?: 'salesforceOpportunity_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<SalesforceOpportunity>;
};

/** input type for inserting object relation for remote table "salesforceOpportunity" */
export type SalesforceOpportunity_Obj_Rel_Insert_Input = {
  data: SalesforceOpportunity_Insert_Input;
  on_conflict?: Maybe<SalesforceOpportunity_On_Conflict>;
};

/** on conflict condition type for table "salesforceOpportunity" */
export type SalesforceOpportunity_On_Conflict = {
  constraint: SalesforceOpportunity_Constraint;
  update_columns: Array<SalesforceOpportunity_Update_Column>;
  where?: Maybe<SalesforceOpportunity_Bool_Exp>;
};

/** ordering options when selecting data from "salesforceOpportunity" */
export type SalesforceOpportunity_Order_By = {
  accountId?: Maybe<Order_By>;
  clientId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  ownerId?: Maybe<Order_By>;
  salesforceId?: Maybe<Order_By>;
  salesforceIntegrationId?: Maybe<Order_By>;
  stage?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: "salesforceOpportunity" */
export type SalesforceOpportunity_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "salesforceOpportunity" */
export enum SalesforceOpportunity_Select_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  ClientId = 'clientId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OwnerId = 'ownerId',
  /** column name */
  SalesforceId = 'salesforceId',
  /** column name */
  SalesforceIntegrationId = 'salesforceIntegrationId',
  /** column name */
  Stage = 'stage',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "salesforceOpportunity" */
export type SalesforceOpportunity_Set_Input = {
  accountId?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['String']>;
  salesforceId?: Maybe<Scalars['String']>;
  salesforceIntegrationId?: Maybe<Scalars['uuid']>;
  stage?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "salesforceOpportunity" */
export enum SalesforceOpportunity_Update_Column {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  ClientId = 'clientId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OwnerId = 'ownerId',
  /** column name */
  SalesforceId = 'salesforceId',
  /** column name */
  SalesforceIntegrationId = 'salesforceIntegrationId',
  /** column name */
  Stage = 'stage',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** columns and relationships of "sendgridEvent" */
export type SendgridEvent = {
  __typename?: 'sendgridEvent';
  clientId?: Maybe<Scalars['uuid']>;
  createdAt: Scalars['timestamptz'];
  email: Scalars['String'];
  emailHistoryId?: Maybe<Scalars['uuid']>;
  event?: Maybe<Scalars['String']>;
  eventJson?: Maybe<Scalars['jsonb']>;
  id: Scalars['uuid'];
  sgEventId?: Maybe<Scalars['String']>;
  sgMessageId?: Maybe<Scalars['String']>;
  sgTemplateId?: Maybe<Scalars['String']>;
  sgTemplateName?: Maybe<Scalars['String']>;
};

/** columns and relationships of "sendgridEvent" */
export type SendgridEventEventJsonArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "sendgridEvent" */
export type SendgridEvent_Aggregate = {
  __typename?: 'sendgridEvent_aggregate';
  aggregate?: Maybe<SendgridEvent_Aggregate_Fields>;
  nodes: Array<SendgridEvent>;
};

/** aggregate fields of "sendgridEvent" */
export type SendgridEvent_Aggregate_Fields = {
  __typename?: 'sendgridEvent_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<SendgridEvent_Max_Fields>;
  min?: Maybe<SendgridEvent_Min_Fields>;
};

/** aggregate fields of "sendgridEvent" */
export type SendgridEvent_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<SendgridEvent_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "sendgridEvent" */
export type SendgridEvent_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<SendgridEvent_Max_Order_By>;
  min?: Maybe<SendgridEvent_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type SendgridEvent_Append_Input = {
  eventJson?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "sendgridEvent" */
export type SendgridEvent_Arr_Rel_Insert_Input = {
  data: Array<SendgridEvent_Insert_Input>;
  on_conflict?: Maybe<SendgridEvent_On_Conflict>;
};

/** Boolean expression to filter rows from the table "sendgridEvent". All fields are combined with a logical 'AND'. */
export type SendgridEvent_Bool_Exp = {
  _and?: Maybe<Array<Maybe<SendgridEvent_Bool_Exp>>>;
  _not?: Maybe<SendgridEvent_Bool_Exp>;
  _or?: Maybe<Array<Maybe<SendgridEvent_Bool_Exp>>>;
  clientId?: Maybe<Uuid_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  emailHistoryId?: Maybe<Uuid_Comparison_Exp>;
  event?: Maybe<String_Comparison_Exp>;
  eventJson?: Maybe<Jsonb_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  sgEventId?: Maybe<String_Comparison_Exp>;
  sgMessageId?: Maybe<String_Comparison_Exp>;
  sgTemplateId?: Maybe<String_Comparison_Exp>;
  sgTemplateName?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "sendgridEvent" */
export enum SendgridEvent_Constraint {
  /** unique or primary key constraint */
  SendgridEventPkey = 'sendgridEvent_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type SendgridEvent_Delete_At_Path_Input = {
  eventJson?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type SendgridEvent_Delete_Elem_Input = {
  eventJson?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type SendgridEvent_Delete_Key_Input = {
  eventJson?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "sendgridEvent" */
export type SendgridEvent_Insert_Input = {
  clientId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  emailHistoryId?: Maybe<Scalars['uuid']>;
  event?: Maybe<Scalars['String']>;
  eventJson?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['uuid']>;
  sgEventId?: Maybe<Scalars['String']>;
  sgMessageId?: Maybe<Scalars['String']>;
  sgTemplateId?: Maybe<Scalars['String']>;
  sgTemplateName?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type SendgridEvent_Max_Fields = {
  __typename?: 'sendgridEvent_max_fields';
  clientId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  emailHistoryId?: Maybe<Scalars['uuid']>;
  event?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  sgEventId?: Maybe<Scalars['String']>;
  sgMessageId?: Maybe<Scalars['String']>;
  sgTemplateId?: Maybe<Scalars['String']>;
  sgTemplateName?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "sendgridEvent" */
export type SendgridEvent_Max_Order_By = {
  clientId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  emailHistoryId?: Maybe<Order_By>;
  event?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  sgEventId?: Maybe<Order_By>;
  sgMessageId?: Maybe<Order_By>;
  sgTemplateId?: Maybe<Order_By>;
  sgTemplateName?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type SendgridEvent_Min_Fields = {
  __typename?: 'sendgridEvent_min_fields';
  clientId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  emailHistoryId?: Maybe<Scalars['uuid']>;
  event?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  sgEventId?: Maybe<Scalars['String']>;
  sgMessageId?: Maybe<Scalars['String']>;
  sgTemplateId?: Maybe<Scalars['String']>;
  sgTemplateName?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "sendgridEvent" */
export type SendgridEvent_Min_Order_By = {
  clientId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  emailHistoryId?: Maybe<Order_By>;
  event?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  sgEventId?: Maybe<Order_By>;
  sgMessageId?: Maybe<Order_By>;
  sgTemplateId?: Maybe<Order_By>;
  sgTemplateName?: Maybe<Order_By>;
};

/** response of any mutation on the table "sendgridEvent" */
export type SendgridEvent_Mutation_Response = {
  __typename?: 'sendgridEvent_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<SendgridEvent>;
};

/** input type for inserting object relation for remote table "sendgridEvent" */
export type SendgridEvent_Obj_Rel_Insert_Input = {
  data: SendgridEvent_Insert_Input;
  on_conflict?: Maybe<SendgridEvent_On_Conflict>;
};

/** on conflict condition type for table "sendgridEvent" */
export type SendgridEvent_On_Conflict = {
  constraint: SendgridEvent_Constraint;
  update_columns: Array<SendgridEvent_Update_Column>;
  where?: Maybe<SendgridEvent_Bool_Exp>;
};

/** ordering options when selecting data from "sendgridEvent" */
export type SendgridEvent_Order_By = {
  clientId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  emailHistoryId?: Maybe<Order_By>;
  event?: Maybe<Order_By>;
  eventJson?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  sgEventId?: Maybe<Order_By>;
  sgMessageId?: Maybe<Order_By>;
  sgTemplateId?: Maybe<Order_By>;
  sgTemplateName?: Maybe<Order_By>;
};

/** primary key columns input for table: "sendgridEvent" */
export type SendgridEvent_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type SendgridEvent_Prepend_Input = {
  eventJson?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "sendgridEvent" */
export enum SendgridEvent_Select_Column {
  /** column name */
  ClientId = 'clientId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  EmailHistoryId = 'emailHistoryId',
  /** column name */
  Event = 'event',
  /** column name */
  EventJson = 'eventJson',
  /** column name */
  Id = 'id',
  /** column name */
  SgEventId = 'sgEventId',
  /** column name */
  SgMessageId = 'sgMessageId',
  /** column name */
  SgTemplateId = 'sgTemplateId',
  /** column name */
  SgTemplateName = 'sgTemplateName',
}

/** input type for updating data in table "sendgridEvent" */
export type SendgridEvent_Set_Input = {
  clientId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  emailHistoryId?: Maybe<Scalars['uuid']>;
  event?: Maybe<Scalars['String']>;
  eventJson?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['uuid']>;
  sgEventId?: Maybe<Scalars['String']>;
  sgMessageId?: Maybe<Scalars['String']>;
  sgTemplateId?: Maybe<Scalars['String']>;
  sgTemplateName?: Maybe<Scalars['String']>;
};

/** update columns of table "sendgridEvent" */
export enum SendgridEvent_Update_Column {
  /** column name */
  ClientId = 'clientId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  EmailHistoryId = 'emailHistoryId',
  /** column name */
  Event = 'event',
  /** column name */
  EventJson = 'eventJson',
  /** column name */
  Id = 'id',
  /** column name */
  SgEventId = 'sgEventId',
  /** column name */
  SgMessageId = 'sgMessageId',
  /** column name */
  SgTemplateId = 'sgTemplateId',
  /** column name */
  SgTemplateName = 'sgTemplateName',
}

/** subscription root */
export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "client" */
  client: Array<Client>;
  /** fetch data from the table: "clientContact" */
  clientContact: Array<ClientContact>;
  /** fetch data from the table: "clientContactCrmData" */
  clientContactCrmData: Array<ClientContactCrmData>;
  /** fetch aggregated fields from the table: "clientContactCrmData" */
  clientContactCrmData_aggregate: ClientContactCrmData_Aggregate;
  /** fetch data from the table: "clientContactCrmData" using primary key columns */
  clientContactCrmData_by_pk?: Maybe<ClientContactCrmData>;
  /** fetch data from the table: "clientContactFilter" */
  clientContactFilter: Array<ClientContactFilter>;
  /** fetch aggregated fields from the table: "clientContactFilter" */
  clientContactFilter_aggregate: ClientContactFilter_Aggregate;
  /** fetch data from the table: "clientContactFilter" using primary key columns */
  clientContactFilter_by_pk?: Maybe<ClientContactFilter>;
  /** fetch data from the table: "clientContactJobChange" */
  clientContactJobChange: Array<ClientContactJobChange>;
  /** fetch aggregated fields from the table: "clientContactJobChange" */
  clientContactJobChange_aggregate: ClientContactJobChange_Aggregate;
  /** fetch data from the table: "clientContactJobChange" using primary key columns */
  clientContactJobChange_by_pk?: Maybe<ClientContactJobChange>;
  /** fetch aggregated fields from the table: "clientContact" */
  clientContact_aggregate: ClientContact_Aggregate;
  /** fetch data from the table: "clientContact" using primary key columns */
  clientContact_by_pk?: Maybe<ClientContact>;
  /** fetch data from the table: "clientEmailReport" */
  clientEmailReport: Array<ClientEmailReport>;
  /** fetch aggregated fields from the table: "clientEmailReport" */
  clientEmailReport_aggregate: ClientEmailReport_Aggregate;
  /** fetch data from the table: "clientEmailReport" using primary key columns */
  clientEmailReport_by_pk?: Maybe<ClientEmailReport>;
  /** fetch data from the table: "clientNotificationEmail" */
  clientNotificationEmail: Array<ClientNotificationEmail>;
  /** fetch aggregated fields from the table: "clientNotificationEmail" */
  clientNotificationEmail_aggregate: ClientNotificationEmail_Aggregate;
  /** fetch data from the table: "clientNotificationEmail" using primary key columns */
  clientNotificationEmail_by_pk?: Maybe<ClientNotificationEmail>;
  /** fetch data from the table: "clientTerritory" */
  clientTerritory: Array<ClientTerritory>;
  /** fetch aggregated fields from the table: "clientTerritory" */
  clientTerritory_aggregate: ClientTerritory_Aggregate;
  /** fetch data from the table: "clientTerritory" using primary key columns */
  clientTerritory_by_pk?: Maybe<ClientTerritory>;
  /** fetch aggregated fields from the table: "client" */
  client_aggregate: Client_Aggregate;
  /** fetch data from the table: "client" using primary key columns */
  client_by_pk?: Maybe<Client>;
  /** fetch data from the table: "cloudTask" */
  cloudTask: Array<CloudTask>;
  /** fetch aggregated fields from the table: "cloudTask" */
  cloudTask_aggregate: CloudTask_Aggregate;
  /** fetch data from the table: "cloudTask" using primary key columns */
  cloudTask_by_pk?: Maybe<CloudTask>;
  /** fetch data from the table: "company" */
  company: Array<Company>;
  /** fetch data from the table: "companyName" */
  companyName: Array<CompanyName>;
  /** fetch aggregated fields from the table: "companyName" */
  companyName_aggregate: CompanyName_Aggregate;
  /** fetch data from the table: "companyName" using primary key columns */
  companyName_by_pk?: Maybe<CompanyName>;
  /** fetch aggregated fields from the table: "company" */
  company_aggregate: Company_Aggregate;
  /** fetch data from the table: "company" using primary key columns */
  company_by_pk?: Maybe<Company>;
  /** fetch data from the table: "contactData" */
  contactData: Array<ContactData>;
  /** fetch aggregated fields from the table: "contactData" */
  contactData_aggregate: ContactData_Aggregate;
  /** fetch data from the table: "contactData" using primary key columns */
  contactData_by_pk?: Maybe<ContactData>;
  /** fetch data from the table: "contactEmail" */
  contactEmail: Array<ContactEmail>;
  /** fetch aggregated fields from the table: "contactEmail" */
  contactEmail_aggregate: ContactEmail_Aggregate;
  /** fetch data from the table: "contactEmail" using primary key columns */
  contactEmail_by_pk?: Maybe<ContactEmail>;
  /** fetch data from the table: "crmContactEnrichmentHistory" */
  crmContactEnrichmentHistory: Array<CrmContactEnrichmentHistory>;
  /** fetch aggregated fields from the table: "crmContactEnrichmentHistory" */
  crmContactEnrichmentHistory_aggregate: CrmContactEnrichmentHistory_Aggregate;
  /** fetch data from the table: "crmContactEnrichmentHistory" using primary key columns */
  crmContactEnrichmentHistory_by_pk?: Maybe<CrmContactEnrichmentHistory>;
  /** fetch data from the table: "csvUpload" */
  csvUpload: Array<CsvUpload>;
  /** fetch aggregated fields from the table: "csvUpload" */
  csvUpload_aggregate: CsvUpload_Aggregate;
  /** fetch data from the table: "csvUpload" using primary key columns */
  csvUpload_by_pk?: Maybe<CsvUpload>;
  /** fetch data from the table: "emailHistory" */
  emailHistory: Array<EmailHistory>;
  /** fetch aggregated fields from the table: "emailHistory" */
  emailHistory_aggregate: EmailHistory_Aggregate;
  /** fetch data from the table: "emailHistory" using primary key columns */
  emailHistory_by_pk?: Maybe<EmailHistory>;
  /** fetch data from the table: "googleChannel" */
  googleChannel: Array<GoogleChannel>;
  /** fetch aggregated fields from the table: "googleChannel" */
  googleChannel_aggregate: GoogleChannel_Aggregate;
  /** fetch data from the table: "googleChannel" using primary key columns */
  googleChannel_by_pk?: Maybe<GoogleChannel>;
  /** fetch data from the table: "hubspotContactList" */
  hubspotContactList: Array<HubspotContactList>;
  /** fetch data from the table: "hubspotContactListHubspotContact" */
  hubspotContactListHubspotContact: Array<HubspotContactListHubspotContact>;
  /** fetch aggregated fields from the table: "hubspotContactListHubspotContact" */
  hubspotContactListHubspotContact_aggregate: HubspotContactListHubspotContact_Aggregate;
  /** fetch data from the table: "hubspotContactListHubspotContact" using primary key columns */
  hubspotContactListHubspotContact_by_pk?: Maybe<HubspotContactListHubspotContact>;
  /** fetch aggregated fields from the table: "hubspotContactList" */
  hubspotContactList_aggregate: HubspotContactList_Aggregate;
  /** fetch data from the table: "hubspotContactList" using primary key columns */
  hubspotContactList_by_pk?: Maybe<HubspotContactList>;
  /** fetch data from the table: "hubspotContactProperty" */
  hubspotContactProperty: Array<HubspotContactProperty>;
  /** fetch aggregated fields from the table: "hubspotContactProperty" */
  hubspotContactProperty_aggregate: HubspotContactProperty_Aggregate;
  /** fetch data from the table: "hubspotContactProperty" using primary key columns */
  hubspotContactProperty_by_pk?: Maybe<HubspotContactProperty>;
  /** fetch data from the table: "hubspotIntegration" */
  hubspotIntegration: Array<HubspotIntegration>;
  /** fetch aggregated fields from the table: "hubspotIntegration" */
  hubspotIntegration_aggregate: HubspotIntegration_Aggregate;
  /** fetch data from the table: "hubspotIntegration" using primary key columns */
  hubspotIntegration_by_pk?: Maybe<HubspotIntegration>;
  /** fetch data from the table: "job" */
  job: Array<Job>;
  /** fetch aggregated fields from the table: "job" */
  job_aggregate: Job_Aggregate;
  /** fetch data from the table: "job" using primary key columns */
  job_by_pk?: Maybe<Job>;
  /** fetch data from the table: "proxycrawlData" */
  proxycrawlData: Array<ProxycrawlData>;
  /** fetch aggregated fields from the table: "proxycrawlData" */
  proxycrawlData_aggregate: ProxycrawlData_Aggregate;
  /** fetch data from the table: "proxycrawlData" using primary key columns */
  proxycrawlData_by_pk?: Maybe<ProxycrawlData>;
  /** fetch data from the table: "qaQualification" */
  qaQualification: Array<QaQualification>;
  /** fetch aggregated fields from the table: "qaQualification" */
  qaQualification_aggregate: QaQualification_Aggregate;
  /** fetch data from the table: "qaQualification" using primary key columns */
  qaQualification_by_pk?: Maybe<QaQualification>;
  /** fetch data from the table: "qaTask" */
  qaTask: Array<QaTask>;
  /** fetch data from the table: "qaTaskAction" */
  qaTaskAction: Array<QaTaskAction>;
  /** fetch aggregated fields from the table: "qaTaskAction" */
  qaTaskAction_aggregate: QaTaskAction_Aggregate;
  /** fetch data from the table: "qaTaskAction" using primary key columns */
  qaTaskAction_by_pk?: Maybe<QaTaskAction>;
  /** fetch aggregated fields from the table: "qaTask" */
  qaTask_aggregate: QaTask_Aggregate;
  /** fetch data from the table: "qaTask" using primary key columns */
  qaTask_by_pk?: Maybe<QaTask>;
  /** fetch data from the table: "salesforceAccount" */
  salesforceAccount: Array<SalesforceAccount>;
  /** fetch aggregated fields from the table: "salesforceAccount" */
  salesforceAccount_aggregate: SalesforceAccount_Aggregate;
  /** fetch data from the table: "salesforceAccount" using primary key columns */
  salesforceAccount_by_pk?: Maybe<SalesforceAccount>;
  /** fetch data from the table: "salesforceContactField" */
  salesforceContactField: Array<SalesforceContactField>;
  /** fetch aggregated fields from the table: "salesforceContactField" */
  salesforceContactField_aggregate: SalesforceContactField_Aggregate;
  /** fetch data from the table: "salesforceContactField" using primary key columns */
  salesforceContactField_by_pk?: Maybe<SalesforceContactField>;
  /** fetch data from the table: "salesforceIntegration" */
  salesforceIntegration: Array<SalesforceIntegration>;
  /** fetch aggregated fields from the table: "salesforceIntegration" */
  salesforceIntegration_aggregate: SalesforceIntegration_Aggregate;
  /** fetch data from the table: "salesforceIntegration" using primary key columns */
  salesforceIntegration_by_pk?: Maybe<SalesforceIntegration>;
  /** fetch data from the table: "salesforceOpportunity" */
  salesforceOpportunity: Array<SalesforceOpportunity>;
  /** fetch aggregated fields from the table: "salesforceOpportunity" */
  salesforceOpportunity_aggregate: SalesforceOpportunity_Aggregate;
  /** fetch data from the table: "salesforceOpportunity" using primary key columns */
  salesforceOpportunity_by_pk?: Maybe<SalesforceOpportunity>;
  /** fetch data from the table: "sendgridEvent" */
  sendgridEvent: Array<SendgridEvent>;
  /** fetch aggregated fields from the table: "sendgridEvent" */
  sendgridEvent_aggregate: SendgridEvent_Aggregate;
  /** fetch data from the table: "sendgridEvent" using primary key columns */
  sendgridEvent_by_pk?: Maybe<SendgridEvent>;
  /** fetch data from the table: "territory" */
  territory: Array<Territory>;
  /** fetch data from the table: "territorySearchTerm" */
  territorySearchTerm: Array<TerritorySearchTerm>;
  /** fetch aggregated fields from the table: "territorySearchTerm" */
  territorySearchTerm_aggregate: TerritorySearchTerm_Aggregate;
  /** fetch data from the table: "territorySearchTerm" using primary key columns */
  territorySearchTerm_by_pk?: Maybe<TerritorySearchTerm>;
  /** fetch aggregated fields from the table: "territory" */
  territory_aggregate: Territory_Aggregate;
  /** fetch data from the table: "territory" using primary key columns */
  territory_by_pk?: Maybe<Territory>;
  /** fetch data from the table: "theOrgSearchTerm" */
  theOrgSearchTerm: Array<TheOrgSearchTerm>;
  /** fetch aggregated fields from the table: "theOrgSearchTerm" */
  theOrgSearchTerm_aggregate: TheOrgSearchTerm_Aggregate;
  /** fetch data from the table: "theOrgSearchTerm" using primary key columns */
  theOrgSearchTerm_by_pk?: Maybe<TheOrgSearchTerm>;
  /** fetch data from the table: "user" */
  user: Array<User>;
  /** fetch data from the table: "userInvite" */
  userInvite: Array<UserInvite>;
  /** fetch aggregated fields from the table: "userInvite" */
  userInvite_aggregate: UserInvite_Aggregate;
  /** fetch data from the table: "userInvite" using primary key columns */
  userInvite_by_pk?: Maybe<UserInvite>;
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: User_Aggregate;
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>;
  /** fetch data from the table: "warmCompany" */
  warmCompany: Array<WarmCompany>;
  /** fetch aggregated fields from the table: "warmCompany" */
  warmCompany_aggregate: WarmCompany_Aggregate;
  /** fetch data from the table: "zoomCompany" */
  zoomCompany: Array<ZoomCompany>;
  /** fetch aggregated fields from the table: "zoomCompany" */
  zoomCompany_aggregate: ZoomCompany_Aggregate;
  /** fetch data from the table: "zoomCompany" using primary key columns */
  zoomCompany_by_pk?: Maybe<ZoomCompany>;
  /** fetch data from the table: "zoomContact" */
  zoomContact: Array<ZoomContact>;
  /** fetch data from the table: "zoomContactEnrichmentHistory" */
  zoomContactEnrichmentHistory: Array<ZoomContactEnrichmentHistory>;
  /** fetch aggregated fields from the table: "zoomContactEnrichmentHistory" */
  zoomContactEnrichmentHistory_aggregate: ZoomContactEnrichmentHistory_Aggregate;
  /** fetch data from the table: "zoomContactEnrichmentHistory" using primary key columns */
  zoomContactEnrichmentHistory_by_pk?: Maybe<ZoomContactEnrichmentHistory>;
  /** fetch data from the table: "zoomContactInfo" */
  zoomContactInfo: Array<ZoomContactInfo>;
  /** fetch aggregated fields from the table: "zoomContactInfo" */
  zoomContactInfo_aggregate: ZoomContactInfo_Aggregate;
  /** fetch data from the table: "zoomContactInfo" using primary key columns */
  zoomContactInfo_by_pk?: Maybe<ZoomContactInfo>;
  /** fetch data from the table: "zoomContactNotes" */
  zoomContactNotes: Array<ZoomContactNotes>;
  /** fetch aggregated fields from the table: "zoomContactNotes" */
  zoomContactNotes_aggregate: ZoomContactNotes_Aggregate;
  /** fetch data from the table: "zoomContactNotes" using primary key columns */
  zoomContactNotes_by_pk?: Maybe<ZoomContactNotes>;
  /** fetch aggregated fields from the table: "zoomContact" */
  zoomContact_aggregate: ZoomContact_Aggregate;
  /** fetch data from the table: "zoomContact" using primary key columns */
  zoomContact_by_pk?: Maybe<ZoomContact>;
  /** fetch data from the table: "zoomEmailHistory" */
  zoomEmailHistory: Array<ZoomEmailHistory>;
  /** fetch aggregated fields from the table: "zoomEmailHistory" */
  zoomEmailHistory_aggregate: ZoomEmailHistory_Aggregate;
  /** fetch data from the table: "zoomEmailHistory" using primary key columns */
  zoomEmailHistory_by_pk?: Maybe<ZoomEmailHistory>;
  /** fetch data from the table: "zoomEvent" */
  zoomEvent: Array<ZoomEvent>;
  /** fetch data from the table: "zoomEventParticipant" */
  zoomEventParticipant: Array<ZoomEventParticipant>;
  /** fetch aggregated fields from the table: "zoomEventParticipant" */
  zoomEventParticipant_aggregate: ZoomEventParticipant_Aggregate;
  /** fetch data from the table: "zoomEventParticipant" using primary key columns */
  zoomEventParticipant_by_pk?: Maybe<ZoomEventParticipant>;
  /** fetch aggregated fields from the table: "zoomEvent" */
  zoomEvent_aggregate: ZoomEvent_Aggregate;
  /** fetch data from the table: "zoomEvent" using primary key columns */
  zoomEvent_by_pk?: Maybe<ZoomEvent>;
  /** fetch data from the table: "zoomExternalAuthUser" */
  zoomExternalAuthUser: Array<ZoomExternalAuthUser>;
  /** fetch aggregated fields from the table: "zoomExternalAuthUser" */
  zoomExternalAuthUser_aggregate: ZoomExternalAuthUser_Aggregate;
  /** fetch data from the table: "zoomExternalAuthUser" using primary key columns */
  zoomExternalAuthUser_by_pk?: Maybe<ZoomExternalAuthUser>;
  /** fetch data from the table: "zoomUser" */
  zoomUser: Array<ZoomUser>;
  /** fetch aggregated fields from the table: "zoomUser" */
  zoomUser_aggregate: ZoomUser_Aggregate;
  /** fetch data from the table: "zoomUser" using primary key columns */
  zoomUser_by_pk?: Maybe<ZoomUser>;
};

/** subscription root */
export type Subscription_RootClientArgs = {
  distinct_on?: Maybe<Array<Client_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Client_Order_By>>;
  where?: Maybe<Client_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootClientContactArgs = {
  distinct_on?: Maybe<Array<ClientContact_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ClientContact_Order_By>>;
  where?: Maybe<ClientContact_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootClientContactCrmDataArgs = {
  distinct_on?: Maybe<Array<ClientContactCrmData_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ClientContactCrmData_Order_By>>;
  where?: Maybe<ClientContactCrmData_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootClientContactCrmData_AggregateArgs = {
  distinct_on?: Maybe<Array<ClientContactCrmData_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ClientContactCrmData_Order_By>>;
  where?: Maybe<ClientContactCrmData_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootClientContactCrmData_By_PkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootClientContactFilterArgs = {
  distinct_on?: Maybe<Array<ClientContactFilter_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ClientContactFilter_Order_By>>;
  where?: Maybe<ClientContactFilter_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootClientContactFilter_AggregateArgs = {
  distinct_on?: Maybe<Array<ClientContactFilter_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ClientContactFilter_Order_By>>;
  where?: Maybe<ClientContactFilter_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootClientContactFilter_By_PkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootClientContactJobChangeArgs = {
  distinct_on?: Maybe<Array<ClientContactJobChange_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ClientContactJobChange_Order_By>>;
  where?: Maybe<ClientContactJobChange_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootClientContactJobChange_AggregateArgs = {
  distinct_on?: Maybe<Array<ClientContactJobChange_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ClientContactJobChange_Order_By>>;
  where?: Maybe<ClientContactJobChange_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootClientContactJobChange_By_PkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootClientContact_AggregateArgs = {
  distinct_on?: Maybe<Array<ClientContact_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ClientContact_Order_By>>;
  where?: Maybe<ClientContact_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootClientContact_By_PkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootClientEmailReportArgs = {
  distinct_on?: Maybe<Array<ClientEmailReport_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ClientEmailReport_Order_By>>;
  where?: Maybe<ClientEmailReport_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootClientEmailReport_AggregateArgs = {
  distinct_on?: Maybe<Array<ClientEmailReport_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ClientEmailReport_Order_By>>;
  where?: Maybe<ClientEmailReport_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootClientEmailReport_By_PkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootClientNotificationEmailArgs = {
  distinct_on?: Maybe<Array<ClientNotificationEmail_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ClientNotificationEmail_Order_By>>;
  where?: Maybe<ClientNotificationEmail_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootClientNotificationEmail_AggregateArgs = {
  distinct_on?: Maybe<Array<ClientNotificationEmail_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ClientNotificationEmail_Order_By>>;
  where?: Maybe<ClientNotificationEmail_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootClientNotificationEmail_By_PkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootClientTerritoryArgs = {
  distinct_on?: Maybe<Array<ClientTerritory_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ClientTerritory_Order_By>>;
  where?: Maybe<ClientTerritory_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootClientTerritory_AggregateArgs = {
  distinct_on?: Maybe<Array<ClientTerritory_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ClientTerritory_Order_By>>;
  where?: Maybe<ClientTerritory_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootClientTerritory_By_PkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootClient_AggregateArgs = {
  distinct_on?: Maybe<Array<Client_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Client_Order_By>>;
  where?: Maybe<Client_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootClient_By_PkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootCloudTaskArgs = {
  distinct_on?: Maybe<Array<CloudTask_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CloudTask_Order_By>>;
  where?: Maybe<CloudTask_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootCloudTask_AggregateArgs = {
  distinct_on?: Maybe<Array<CloudTask_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CloudTask_Order_By>>;
  where?: Maybe<CloudTask_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootCloudTask_By_PkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootCompanyArgs = {
  distinct_on?: Maybe<Array<Company_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Company_Order_By>>;
  where?: Maybe<Company_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootCompanyNameArgs = {
  distinct_on?: Maybe<Array<CompanyName_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CompanyName_Order_By>>;
  where?: Maybe<CompanyName_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootCompanyName_AggregateArgs = {
  distinct_on?: Maybe<Array<CompanyName_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CompanyName_Order_By>>;
  where?: Maybe<CompanyName_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootCompanyName_By_PkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootCompany_AggregateArgs = {
  distinct_on?: Maybe<Array<Company_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Company_Order_By>>;
  where?: Maybe<Company_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootCompany_By_PkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootContactDataArgs = {
  distinct_on?: Maybe<Array<ContactData_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContactData_Order_By>>;
  where?: Maybe<ContactData_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootContactData_AggregateArgs = {
  distinct_on?: Maybe<Array<ContactData_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContactData_Order_By>>;
  where?: Maybe<ContactData_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootContactData_By_PkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootContactEmailArgs = {
  distinct_on?: Maybe<Array<ContactEmail_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContactEmail_Order_By>>;
  where?: Maybe<ContactEmail_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootContactEmail_AggregateArgs = {
  distinct_on?: Maybe<Array<ContactEmail_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ContactEmail_Order_By>>;
  where?: Maybe<ContactEmail_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootContactEmail_By_PkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootCrmContactEnrichmentHistoryArgs = {
  distinct_on?: Maybe<Array<CrmContactEnrichmentHistory_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CrmContactEnrichmentHistory_Order_By>>;
  where?: Maybe<CrmContactEnrichmentHistory_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootCrmContactEnrichmentHistory_AggregateArgs = {
  distinct_on?: Maybe<Array<CrmContactEnrichmentHistory_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CrmContactEnrichmentHistory_Order_By>>;
  where?: Maybe<CrmContactEnrichmentHistory_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootCrmContactEnrichmentHistory_By_PkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootCsvUploadArgs = {
  distinct_on?: Maybe<Array<CsvUpload_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CsvUpload_Order_By>>;
  where?: Maybe<CsvUpload_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootCsvUpload_AggregateArgs = {
  distinct_on?: Maybe<Array<CsvUpload_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CsvUpload_Order_By>>;
  where?: Maybe<CsvUpload_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootCsvUpload_By_PkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootEmailHistoryArgs = {
  distinct_on?: Maybe<Array<EmailHistory_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<EmailHistory_Order_By>>;
  where?: Maybe<EmailHistory_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootEmailHistory_AggregateArgs = {
  distinct_on?: Maybe<Array<EmailHistory_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<EmailHistory_Order_By>>;
  where?: Maybe<EmailHistory_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootEmailHistory_By_PkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootGoogleChannelArgs = {
  distinct_on?: Maybe<Array<GoogleChannel_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<GoogleChannel_Order_By>>;
  where?: Maybe<GoogleChannel_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootGoogleChannel_AggregateArgs = {
  distinct_on?: Maybe<Array<GoogleChannel_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<GoogleChannel_Order_By>>;
  where?: Maybe<GoogleChannel_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootGoogleChannel_By_PkArgs = {
  id: Scalars['Int'];
};

/** subscription root */
export type Subscription_RootHubspotContactListArgs = {
  distinct_on?: Maybe<Array<HubspotContactList_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HubspotContactList_Order_By>>;
  where?: Maybe<HubspotContactList_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootHubspotContactListHubspotContactArgs = {
  distinct_on?: Maybe<Array<HubspotContactListHubspotContact_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HubspotContactListHubspotContact_Order_By>>;
  where?: Maybe<HubspotContactListHubspotContact_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootHubspotContactListHubspotContact_AggregateArgs = {
  distinct_on?: Maybe<Array<HubspotContactListHubspotContact_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HubspotContactListHubspotContact_Order_By>>;
  where?: Maybe<HubspotContactListHubspotContact_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootHubspotContactListHubspotContact_By_PkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootHubspotContactList_AggregateArgs = {
  distinct_on?: Maybe<Array<HubspotContactList_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HubspotContactList_Order_By>>;
  where?: Maybe<HubspotContactList_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootHubspotContactList_By_PkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootHubspotContactPropertyArgs = {
  distinct_on?: Maybe<Array<HubspotContactProperty_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HubspotContactProperty_Order_By>>;
  where?: Maybe<HubspotContactProperty_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootHubspotContactProperty_AggregateArgs = {
  distinct_on?: Maybe<Array<HubspotContactProperty_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HubspotContactProperty_Order_By>>;
  where?: Maybe<HubspotContactProperty_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootHubspotContactProperty_By_PkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootHubspotIntegrationArgs = {
  distinct_on?: Maybe<Array<HubspotIntegration_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HubspotIntegration_Order_By>>;
  where?: Maybe<HubspotIntegration_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootHubspotIntegration_AggregateArgs = {
  distinct_on?: Maybe<Array<HubspotIntegration_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HubspotIntegration_Order_By>>;
  where?: Maybe<HubspotIntegration_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootHubspotIntegration_By_PkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootJobArgs = {
  distinct_on?: Maybe<Array<Job_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Job_Order_By>>;
  where?: Maybe<Job_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootJob_AggregateArgs = {
  distinct_on?: Maybe<Array<Job_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Job_Order_By>>;
  where?: Maybe<Job_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootJob_By_PkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootProxycrawlDataArgs = {
  distinct_on?: Maybe<Array<ProxycrawlData_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProxycrawlData_Order_By>>;
  where?: Maybe<ProxycrawlData_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootProxycrawlData_AggregateArgs = {
  distinct_on?: Maybe<Array<ProxycrawlData_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProxycrawlData_Order_By>>;
  where?: Maybe<ProxycrawlData_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootProxycrawlData_By_PkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootQaQualificationArgs = {
  distinct_on?: Maybe<Array<QaQualification_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<QaQualification_Order_By>>;
  where?: Maybe<QaQualification_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootQaQualification_AggregateArgs = {
  distinct_on?: Maybe<Array<QaQualification_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<QaQualification_Order_By>>;
  where?: Maybe<QaQualification_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootQaQualification_By_PkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootQaTaskArgs = {
  distinct_on?: Maybe<Array<QaTask_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<QaTask_Order_By>>;
  where?: Maybe<QaTask_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootQaTaskActionArgs = {
  distinct_on?: Maybe<Array<QaTaskAction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<QaTaskAction_Order_By>>;
  where?: Maybe<QaTaskAction_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootQaTaskAction_AggregateArgs = {
  distinct_on?: Maybe<Array<QaTaskAction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<QaTaskAction_Order_By>>;
  where?: Maybe<QaTaskAction_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootQaTaskAction_By_PkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootQaTask_AggregateArgs = {
  distinct_on?: Maybe<Array<QaTask_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<QaTask_Order_By>>;
  where?: Maybe<QaTask_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootQaTask_By_PkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootSalesforceAccountArgs = {
  distinct_on?: Maybe<Array<SalesforceAccount_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SalesforceAccount_Order_By>>;
  where?: Maybe<SalesforceAccount_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootSalesforceAccount_AggregateArgs = {
  distinct_on?: Maybe<Array<SalesforceAccount_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SalesforceAccount_Order_By>>;
  where?: Maybe<SalesforceAccount_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootSalesforceAccount_By_PkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootSalesforceContactFieldArgs = {
  distinct_on?: Maybe<Array<SalesforceContactField_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SalesforceContactField_Order_By>>;
  where?: Maybe<SalesforceContactField_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootSalesforceContactField_AggregateArgs = {
  distinct_on?: Maybe<Array<SalesforceContactField_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SalesforceContactField_Order_By>>;
  where?: Maybe<SalesforceContactField_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootSalesforceContactField_By_PkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootSalesforceIntegrationArgs = {
  distinct_on?: Maybe<Array<SalesforceIntegration_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SalesforceIntegration_Order_By>>;
  where?: Maybe<SalesforceIntegration_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootSalesforceIntegration_AggregateArgs = {
  distinct_on?: Maybe<Array<SalesforceIntegration_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SalesforceIntegration_Order_By>>;
  where?: Maybe<SalesforceIntegration_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootSalesforceIntegration_By_PkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootSalesforceOpportunityArgs = {
  distinct_on?: Maybe<Array<SalesforceOpportunity_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SalesforceOpportunity_Order_By>>;
  where?: Maybe<SalesforceOpportunity_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootSalesforceOpportunity_AggregateArgs = {
  distinct_on?: Maybe<Array<SalesforceOpportunity_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SalesforceOpportunity_Order_By>>;
  where?: Maybe<SalesforceOpportunity_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootSalesforceOpportunity_By_PkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootSendgridEventArgs = {
  distinct_on?: Maybe<Array<SendgridEvent_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SendgridEvent_Order_By>>;
  where?: Maybe<SendgridEvent_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootSendgridEvent_AggregateArgs = {
  distinct_on?: Maybe<Array<SendgridEvent_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SendgridEvent_Order_By>>;
  where?: Maybe<SendgridEvent_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootSendgridEvent_By_PkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootTerritoryArgs = {
  distinct_on?: Maybe<Array<Territory_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Territory_Order_By>>;
  where?: Maybe<Territory_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootTerritorySearchTermArgs = {
  distinct_on?: Maybe<Array<TerritorySearchTerm_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<TerritorySearchTerm_Order_By>>;
  where?: Maybe<TerritorySearchTerm_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootTerritorySearchTerm_AggregateArgs = {
  distinct_on?: Maybe<Array<TerritorySearchTerm_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<TerritorySearchTerm_Order_By>>;
  where?: Maybe<TerritorySearchTerm_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootTerritorySearchTerm_By_PkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootTerritory_AggregateArgs = {
  distinct_on?: Maybe<Array<Territory_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Territory_Order_By>>;
  where?: Maybe<Territory_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootTerritory_By_PkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootTheOrgSearchTermArgs = {
  distinct_on?: Maybe<Array<TheOrgSearchTerm_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<TheOrgSearchTerm_Order_By>>;
  where?: Maybe<TheOrgSearchTerm_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootTheOrgSearchTerm_AggregateArgs = {
  distinct_on?: Maybe<Array<TheOrgSearchTerm_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<TheOrgSearchTerm_Order_By>>;
  where?: Maybe<TheOrgSearchTerm_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootTheOrgSearchTerm_By_PkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootUserArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootUserInviteArgs = {
  distinct_on?: Maybe<Array<UserInvite_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserInvite_Order_By>>;
  where?: Maybe<UserInvite_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootUserInvite_AggregateArgs = {
  distinct_on?: Maybe<Array<UserInvite_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserInvite_Order_By>>;
  where?: Maybe<UserInvite_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootUserInvite_By_PkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootUser_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootUser_By_PkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootWarmCompanyArgs = {
  distinct_on?: Maybe<Array<WarmCompany_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<WarmCompany_Order_By>>;
  where?: Maybe<WarmCompany_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootWarmCompany_AggregateArgs = {
  distinct_on?: Maybe<Array<WarmCompany_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<WarmCompany_Order_By>>;
  where?: Maybe<WarmCompany_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootZoomCompanyArgs = {
  distinct_on?: Maybe<Array<ZoomCompany_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ZoomCompany_Order_By>>;
  where?: Maybe<ZoomCompany_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootZoomCompany_AggregateArgs = {
  distinct_on?: Maybe<Array<ZoomCompany_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ZoomCompany_Order_By>>;
  where?: Maybe<ZoomCompany_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootZoomCompany_By_PkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootZoomContactArgs = {
  distinct_on?: Maybe<Array<ZoomContact_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ZoomContact_Order_By>>;
  where?: Maybe<ZoomContact_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootZoomContactEnrichmentHistoryArgs = {
  distinct_on?: Maybe<Array<ZoomContactEnrichmentHistory_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ZoomContactEnrichmentHistory_Order_By>>;
  where?: Maybe<ZoomContactEnrichmentHistory_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootZoomContactEnrichmentHistory_AggregateArgs = {
  distinct_on?: Maybe<Array<ZoomContactEnrichmentHistory_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ZoomContactEnrichmentHistory_Order_By>>;
  where?: Maybe<ZoomContactEnrichmentHistory_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootZoomContactEnrichmentHistory_By_PkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootZoomContactInfoArgs = {
  distinct_on?: Maybe<Array<ZoomContactInfo_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ZoomContactInfo_Order_By>>;
  where?: Maybe<ZoomContactInfo_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootZoomContactInfo_AggregateArgs = {
  distinct_on?: Maybe<Array<ZoomContactInfo_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ZoomContactInfo_Order_By>>;
  where?: Maybe<ZoomContactInfo_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootZoomContactInfo_By_PkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootZoomContactNotesArgs = {
  distinct_on?: Maybe<Array<ZoomContactNotes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ZoomContactNotes_Order_By>>;
  where?: Maybe<ZoomContactNotes_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootZoomContactNotes_AggregateArgs = {
  distinct_on?: Maybe<Array<ZoomContactNotes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ZoomContactNotes_Order_By>>;
  where?: Maybe<ZoomContactNotes_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootZoomContactNotes_By_PkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootZoomContact_AggregateArgs = {
  distinct_on?: Maybe<Array<ZoomContact_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ZoomContact_Order_By>>;
  where?: Maybe<ZoomContact_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootZoomContact_By_PkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootZoomEmailHistoryArgs = {
  distinct_on?: Maybe<Array<ZoomEmailHistory_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ZoomEmailHistory_Order_By>>;
  where?: Maybe<ZoomEmailHistory_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootZoomEmailHistory_AggregateArgs = {
  distinct_on?: Maybe<Array<ZoomEmailHistory_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ZoomEmailHistory_Order_By>>;
  where?: Maybe<ZoomEmailHistory_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootZoomEmailHistory_By_PkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootZoomEventArgs = {
  distinct_on?: Maybe<Array<ZoomEvent_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ZoomEvent_Order_By>>;
  where?: Maybe<ZoomEvent_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootZoomEventParticipantArgs = {
  distinct_on?: Maybe<Array<ZoomEventParticipant_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ZoomEventParticipant_Order_By>>;
  where?: Maybe<ZoomEventParticipant_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootZoomEventParticipant_AggregateArgs = {
  distinct_on?: Maybe<Array<ZoomEventParticipant_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ZoomEventParticipant_Order_By>>;
  where?: Maybe<ZoomEventParticipant_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootZoomEventParticipant_By_PkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootZoomEvent_AggregateArgs = {
  distinct_on?: Maybe<Array<ZoomEvent_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ZoomEvent_Order_By>>;
  where?: Maybe<ZoomEvent_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootZoomEvent_By_PkArgs = {
  id: Scalars['Int'];
};

/** subscription root */
export type Subscription_RootZoomExternalAuthUserArgs = {
  distinct_on?: Maybe<Array<ZoomExternalAuthUser_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ZoomExternalAuthUser_Order_By>>;
  where?: Maybe<ZoomExternalAuthUser_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootZoomExternalAuthUser_AggregateArgs = {
  distinct_on?: Maybe<Array<ZoomExternalAuthUser_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ZoomExternalAuthUser_Order_By>>;
  where?: Maybe<ZoomExternalAuthUser_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootZoomExternalAuthUser_By_PkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type Subscription_RootZoomUserArgs = {
  distinct_on?: Maybe<Array<ZoomUser_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ZoomUser_Order_By>>;
  where?: Maybe<ZoomUser_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootZoomUser_AggregateArgs = {
  distinct_on?: Maybe<Array<ZoomUser_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ZoomUser_Order_By>>;
  where?: Maybe<ZoomUser_Bool_Exp>;
};

/** subscription root */
export type Subscription_RootZoomUser_By_PkArgs = {
  id: Scalars['uuid'];
};

/** columns and relationships of "territory" */
export type Territory = {
  __typename?: 'territory';
  administrativeArea1?: Maybe<Scalars['String']>;
  administrativeArea2?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  formattedAddress: Scalars['String'];
  id: Scalars['uuid'];
  json: Scalars['jsonb'];
  locality?: Maybe<Scalars['String']>;
  placeId: Scalars['String'];
  type: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
};

/** columns and relationships of "territory" */
export type TerritoryJsonArgs = {
  path?: Maybe<Scalars['String']>;
};

/** columns and relationships of "territorySearchTerm" */
export type TerritorySearchTerm = {
  __typename?: 'territorySearchTerm';
  id: Scalars['uuid'];
  searchTerm: Scalars['String'];
  territoryId: Scalars['uuid'];
};

/** aggregated selection of "territorySearchTerm" */
export type TerritorySearchTerm_Aggregate = {
  __typename?: 'territorySearchTerm_aggregate';
  aggregate?: Maybe<TerritorySearchTerm_Aggregate_Fields>;
  nodes: Array<TerritorySearchTerm>;
};

/** aggregate fields of "territorySearchTerm" */
export type TerritorySearchTerm_Aggregate_Fields = {
  __typename?: 'territorySearchTerm_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<TerritorySearchTerm_Max_Fields>;
  min?: Maybe<TerritorySearchTerm_Min_Fields>;
};

/** aggregate fields of "territorySearchTerm" */
export type TerritorySearchTerm_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<TerritorySearchTerm_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "territorySearchTerm" */
export type TerritorySearchTerm_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<TerritorySearchTerm_Max_Order_By>;
  min?: Maybe<TerritorySearchTerm_Min_Order_By>;
};

/** input type for inserting array relation for remote table "territorySearchTerm" */
export type TerritorySearchTerm_Arr_Rel_Insert_Input = {
  data: Array<TerritorySearchTerm_Insert_Input>;
  on_conflict?: Maybe<TerritorySearchTerm_On_Conflict>;
};

/** Boolean expression to filter rows from the table "territorySearchTerm". All fields are combined with a logical 'AND'. */
export type TerritorySearchTerm_Bool_Exp = {
  _and?: Maybe<Array<Maybe<TerritorySearchTerm_Bool_Exp>>>;
  _not?: Maybe<TerritorySearchTerm_Bool_Exp>;
  _or?: Maybe<Array<Maybe<TerritorySearchTerm_Bool_Exp>>>;
  id?: Maybe<Uuid_Comparison_Exp>;
  searchTerm?: Maybe<String_Comparison_Exp>;
  territoryId?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "territorySearchTerm" */
export enum TerritorySearchTerm_Constraint {
  /** unique or primary key constraint */
  TerritorySearchTermPkey = 'territorySearchTerm_pkey',
  /** unique or primary key constraint */
  TerritorySearchTermSearchTermTerritoryIdKey = 'territorySearchTerm_searchTerm_territoryId_key',
}

/** input type for inserting data into table "territorySearchTerm" */
export type TerritorySearchTerm_Insert_Input = {
  id?: Maybe<Scalars['uuid']>;
  searchTerm?: Maybe<Scalars['String']>;
  territoryId?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type TerritorySearchTerm_Max_Fields = {
  __typename?: 'territorySearchTerm_max_fields';
  id?: Maybe<Scalars['uuid']>;
  searchTerm?: Maybe<Scalars['String']>;
  territoryId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "territorySearchTerm" */
export type TerritorySearchTerm_Max_Order_By = {
  id?: Maybe<Order_By>;
  searchTerm?: Maybe<Order_By>;
  territoryId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type TerritorySearchTerm_Min_Fields = {
  __typename?: 'territorySearchTerm_min_fields';
  id?: Maybe<Scalars['uuid']>;
  searchTerm?: Maybe<Scalars['String']>;
  territoryId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "territorySearchTerm" */
export type TerritorySearchTerm_Min_Order_By = {
  id?: Maybe<Order_By>;
  searchTerm?: Maybe<Order_By>;
  territoryId?: Maybe<Order_By>;
};

/** response of any mutation on the table "territorySearchTerm" */
export type TerritorySearchTerm_Mutation_Response = {
  __typename?: 'territorySearchTerm_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<TerritorySearchTerm>;
};

/** input type for inserting object relation for remote table "territorySearchTerm" */
export type TerritorySearchTerm_Obj_Rel_Insert_Input = {
  data: TerritorySearchTerm_Insert_Input;
  on_conflict?: Maybe<TerritorySearchTerm_On_Conflict>;
};

/** on conflict condition type for table "territorySearchTerm" */
export type TerritorySearchTerm_On_Conflict = {
  constraint: TerritorySearchTerm_Constraint;
  update_columns: Array<TerritorySearchTerm_Update_Column>;
  where?: Maybe<TerritorySearchTerm_Bool_Exp>;
};

/** ordering options when selecting data from "territorySearchTerm" */
export type TerritorySearchTerm_Order_By = {
  id?: Maybe<Order_By>;
  searchTerm?: Maybe<Order_By>;
  territoryId?: Maybe<Order_By>;
};

/** primary key columns input for table: "territorySearchTerm" */
export type TerritorySearchTerm_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "territorySearchTerm" */
export enum TerritorySearchTerm_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  SearchTerm = 'searchTerm',
  /** column name */
  TerritoryId = 'territoryId',
}

/** input type for updating data in table "territorySearchTerm" */
export type TerritorySearchTerm_Set_Input = {
  id?: Maybe<Scalars['uuid']>;
  searchTerm?: Maybe<Scalars['String']>;
  territoryId?: Maybe<Scalars['uuid']>;
};

/** update columns of table "territorySearchTerm" */
export enum TerritorySearchTerm_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  SearchTerm = 'searchTerm',
  /** column name */
  TerritoryId = 'territoryId',
}

/** aggregated selection of "territory" */
export type Territory_Aggregate = {
  __typename?: 'territory_aggregate';
  aggregate?: Maybe<Territory_Aggregate_Fields>;
  nodes: Array<Territory>;
};

/** aggregate fields of "territory" */
export type Territory_Aggregate_Fields = {
  __typename?: 'territory_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Territory_Max_Fields>;
  min?: Maybe<Territory_Min_Fields>;
};

/** aggregate fields of "territory" */
export type Territory_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Territory_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "territory" */
export type Territory_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Territory_Max_Order_By>;
  min?: Maybe<Territory_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Territory_Append_Input = {
  json?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "territory" */
export type Territory_Arr_Rel_Insert_Input = {
  data: Array<Territory_Insert_Input>;
  on_conflict?: Maybe<Territory_On_Conflict>;
};

/** Boolean expression to filter rows from the table "territory". All fields are combined with a logical 'AND'. */
export type Territory_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Territory_Bool_Exp>>>;
  _not?: Maybe<Territory_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Territory_Bool_Exp>>>;
  administrativeArea1?: Maybe<String_Comparison_Exp>;
  administrativeArea2?: Maybe<String_Comparison_Exp>;
  country?: Maybe<String_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  formattedAddress?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  json?: Maybe<Jsonb_Comparison_Exp>;
  locality?: Maybe<String_Comparison_Exp>;
  placeId?: Maybe<String_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "territory" */
export enum Territory_Constraint {
  /** unique or primary key constraint */
  TerritoryPkey = 'territory_pkey',
  /** unique or primary key constraint */
  TerritoryPlaceIdKey = 'territory_placeId_key',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Territory_Delete_At_Path_Input = {
  json?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Territory_Delete_Elem_Input = {
  json?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Territory_Delete_Key_Input = {
  json?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "territory" */
export type Territory_Insert_Input = {
  administrativeArea1?: Maybe<Scalars['String']>;
  administrativeArea2?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  formattedAddress?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  json?: Maybe<Scalars['jsonb']>;
  locality?: Maybe<Scalars['String']>;
  placeId?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Territory_Max_Fields = {
  __typename?: 'territory_max_fields';
  administrativeArea1?: Maybe<Scalars['String']>;
  administrativeArea2?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  formattedAddress?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  locality?: Maybe<Scalars['String']>;
  placeId?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "territory" */
export type Territory_Max_Order_By = {
  administrativeArea1?: Maybe<Order_By>;
  administrativeArea2?: Maybe<Order_By>;
  country?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  formattedAddress?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  locality?: Maybe<Order_By>;
  placeId?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Territory_Min_Fields = {
  __typename?: 'territory_min_fields';
  administrativeArea1?: Maybe<Scalars['String']>;
  administrativeArea2?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  formattedAddress?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  locality?: Maybe<Scalars['String']>;
  placeId?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "territory" */
export type Territory_Min_Order_By = {
  administrativeArea1?: Maybe<Order_By>;
  administrativeArea2?: Maybe<Order_By>;
  country?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  formattedAddress?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  locality?: Maybe<Order_By>;
  placeId?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** response of any mutation on the table "territory" */
export type Territory_Mutation_Response = {
  __typename?: 'territory_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Territory>;
};

/** input type for inserting object relation for remote table "territory" */
export type Territory_Obj_Rel_Insert_Input = {
  data: Territory_Insert_Input;
  on_conflict?: Maybe<Territory_On_Conflict>;
};

/** on conflict condition type for table "territory" */
export type Territory_On_Conflict = {
  constraint: Territory_Constraint;
  update_columns: Array<Territory_Update_Column>;
  where?: Maybe<Territory_Bool_Exp>;
};

/** ordering options when selecting data from "territory" */
export type Territory_Order_By = {
  administrativeArea1?: Maybe<Order_By>;
  administrativeArea2?: Maybe<Order_By>;
  country?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  formattedAddress?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  json?: Maybe<Order_By>;
  locality?: Maybe<Order_By>;
  placeId?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: "territory" */
export type Territory_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Territory_Prepend_Input = {
  json?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "territory" */
export enum Territory_Select_Column {
  /** column name */
  AdministrativeArea1 = 'administrativeArea1',
  /** column name */
  AdministrativeArea2 = 'administrativeArea2',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  FormattedAddress = 'formattedAddress',
  /** column name */
  Id = 'id',
  /** column name */
  Json = 'json',
  /** column name */
  Locality = 'locality',
  /** column name */
  PlaceId = 'placeId',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "territory" */
export type Territory_Set_Input = {
  administrativeArea1?: Maybe<Scalars['String']>;
  administrativeArea2?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  formattedAddress?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  json?: Maybe<Scalars['jsonb']>;
  locality?: Maybe<Scalars['String']>;
  placeId?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "territory" */
export enum Territory_Update_Column {
  /** column name */
  AdministrativeArea1 = 'administrativeArea1',
  /** column name */
  AdministrativeArea2 = 'administrativeArea2',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  FormattedAddress = 'formattedAddress',
  /** column name */
  Id = 'id',
  /** column name */
  Json = 'json',
  /** column name */
  Locality = 'locality',
  /** column name */
  PlaceId = 'placeId',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** columns and relationships of "theOrgSearchTerm" */
export type TheOrgSearchTerm = {
  __typename?: 'theOrgSearchTerm';
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  json: Scalars['jsonb'];
  searchTerm: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
};

/** columns and relationships of "theOrgSearchTerm" */
export type TheOrgSearchTermJsonArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "theOrgSearchTerm" */
export type TheOrgSearchTerm_Aggregate = {
  __typename?: 'theOrgSearchTerm_aggregate';
  aggregate?: Maybe<TheOrgSearchTerm_Aggregate_Fields>;
  nodes: Array<TheOrgSearchTerm>;
};

/** aggregate fields of "theOrgSearchTerm" */
export type TheOrgSearchTerm_Aggregate_Fields = {
  __typename?: 'theOrgSearchTerm_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<TheOrgSearchTerm_Max_Fields>;
  min?: Maybe<TheOrgSearchTerm_Min_Fields>;
};

/** aggregate fields of "theOrgSearchTerm" */
export type TheOrgSearchTerm_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<TheOrgSearchTerm_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "theOrgSearchTerm" */
export type TheOrgSearchTerm_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<TheOrgSearchTerm_Max_Order_By>;
  min?: Maybe<TheOrgSearchTerm_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type TheOrgSearchTerm_Append_Input = {
  json?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "theOrgSearchTerm" */
export type TheOrgSearchTerm_Arr_Rel_Insert_Input = {
  data: Array<TheOrgSearchTerm_Insert_Input>;
  on_conflict?: Maybe<TheOrgSearchTerm_On_Conflict>;
};

/** Boolean expression to filter rows from the table "theOrgSearchTerm". All fields are combined with a logical 'AND'. */
export type TheOrgSearchTerm_Bool_Exp = {
  _and?: Maybe<Array<Maybe<TheOrgSearchTerm_Bool_Exp>>>;
  _not?: Maybe<TheOrgSearchTerm_Bool_Exp>;
  _or?: Maybe<Array<Maybe<TheOrgSearchTerm_Bool_Exp>>>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  json?: Maybe<Jsonb_Comparison_Exp>;
  searchTerm?: Maybe<String_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "theOrgSearchTerm" */
export enum TheOrgSearchTerm_Constraint {
  /** unique or primary key constraint */
  TheOrgSearchTermPkey = 'theOrgSearchTerm_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type TheOrgSearchTerm_Delete_At_Path_Input = {
  json?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type TheOrgSearchTerm_Delete_Elem_Input = {
  json?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type TheOrgSearchTerm_Delete_Key_Input = {
  json?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "theOrgSearchTerm" */
export type TheOrgSearchTerm_Insert_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  json?: Maybe<Scalars['jsonb']>;
  searchTerm?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type TheOrgSearchTerm_Max_Fields = {
  __typename?: 'theOrgSearchTerm_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  searchTerm?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "theOrgSearchTerm" */
export type TheOrgSearchTerm_Max_Order_By = {
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  searchTerm?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type TheOrgSearchTerm_Min_Fields = {
  __typename?: 'theOrgSearchTerm_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  searchTerm?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "theOrgSearchTerm" */
export type TheOrgSearchTerm_Min_Order_By = {
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  searchTerm?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** response of any mutation on the table "theOrgSearchTerm" */
export type TheOrgSearchTerm_Mutation_Response = {
  __typename?: 'theOrgSearchTerm_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<TheOrgSearchTerm>;
};

/** input type for inserting object relation for remote table "theOrgSearchTerm" */
export type TheOrgSearchTerm_Obj_Rel_Insert_Input = {
  data: TheOrgSearchTerm_Insert_Input;
  on_conflict?: Maybe<TheOrgSearchTerm_On_Conflict>;
};

/** on conflict condition type for table "theOrgSearchTerm" */
export type TheOrgSearchTerm_On_Conflict = {
  constraint: TheOrgSearchTerm_Constraint;
  update_columns: Array<TheOrgSearchTerm_Update_Column>;
  where?: Maybe<TheOrgSearchTerm_Bool_Exp>;
};

/** ordering options when selecting data from "theOrgSearchTerm" */
export type TheOrgSearchTerm_Order_By = {
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  json?: Maybe<Order_By>;
  searchTerm?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: "theOrgSearchTerm" */
export type TheOrgSearchTerm_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type TheOrgSearchTerm_Prepend_Input = {
  json?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "theOrgSearchTerm" */
export enum TheOrgSearchTerm_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Json = 'json',
  /** column name */
  SearchTerm = 'searchTerm',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "theOrgSearchTerm" */
export type TheOrgSearchTerm_Set_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  json?: Maybe<Scalars['jsonb']>;
  searchTerm?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "theOrgSearchTerm" */
export enum TheOrgSearchTerm_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Json = 'json',
  /** column name */
  SearchTerm = 'searchTerm',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** expression to compare columns of type timestamptz. All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamptz']>;
  _gt?: Maybe<Scalars['timestamptz']>;
  _gte?: Maybe<Scalars['timestamptz']>;
  _in?: Maybe<Array<Scalars['timestamptz']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamptz']>;
  _lte?: Maybe<Scalars['timestamptz']>;
  _neq?: Maybe<Scalars['timestamptz']>;
  _nin?: Maybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "user" */
export type User = {
  __typename?: 'user';
  /** An object relationship */
  client: Client;
  clientId: Scalars['uuid'];
  clientPermission?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  email: Scalars['String'];
  firebaseUid: Scalars['String'];
  firestoreClientId?: Maybe<Scalars['String']>;
  firestoreId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  id: Scalars['uuid'];
  jobChangeFilters?: Maybe<Scalars['jsonb']>;
  lastName: Scalars['String'];
  /** An array relationship */
  qaQualifications: Array<QaQualification>;
  /** An aggregated array relationship */
  qaQualifications_aggregate: QaQualification_Aggregate;
  role: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
};

/** columns and relationships of "user" */
export type UserJobChangeFiltersArgs = {
  path?: Maybe<Scalars['String']>;
};

/** columns and relationships of "user" */
export type UserQaQualificationsArgs = {
  distinct_on?: Maybe<Array<QaQualification_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<QaQualification_Order_By>>;
  where?: Maybe<QaQualification_Bool_Exp>;
};

/** columns and relationships of "user" */
export type UserQaQualifications_AggregateArgs = {
  distinct_on?: Maybe<Array<QaQualification_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<QaQualification_Order_By>>;
  where?: Maybe<QaQualification_Bool_Exp>;
};

/** columns and relationships of "userInvite" */
export type UserInvite = {
  __typename?: 'userInvite';
  createdAt: Scalars['timestamptz'];
  expireAt: Scalars['timestamptz'];
  firestoreId?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  inviteeClientPermission?: Maybe<Scalars['String']>;
  inviteeEmail: Scalars['String'];
  /** An object relationship */
  inviterClient: Client;
  inviterClientId: Scalars['uuid'];
  inviterClientName: Scalars['String'];
  /** An object relationship */
  inviterUser: User;
  inviterUserId: Scalars['uuid'];
  inviterUserName: Scalars['String'];
  status: Scalars['String'];
};

/** aggregated selection of "userInvite" */
export type UserInvite_Aggregate = {
  __typename?: 'userInvite_aggregate';
  aggregate?: Maybe<UserInvite_Aggregate_Fields>;
  nodes: Array<UserInvite>;
};

/** aggregate fields of "userInvite" */
export type UserInvite_Aggregate_Fields = {
  __typename?: 'userInvite_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<UserInvite_Max_Fields>;
  min?: Maybe<UserInvite_Min_Fields>;
};

/** aggregate fields of "userInvite" */
export type UserInvite_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<UserInvite_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "userInvite" */
export type UserInvite_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<UserInvite_Max_Order_By>;
  min?: Maybe<UserInvite_Min_Order_By>;
};

/** input type for inserting array relation for remote table "userInvite" */
export type UserInvite_Arr_Rel_Insert_Input = {
  data: Array<UserInvite_Insert_Input>;
  on_conflict?: Maybe<UserInvite_On_Conflict>;
};

/** Boolean expression to filter rows from the table "userInvite". All fields are combined with a logical 'AND'. */
export type UserInvite_Bool_Exp = {
  _and?: Maybe<Array<Maybe<UserInvite_Bool_Exp>>>;
  _not?: Maybe<UserInvite_Bool_Exp>;
  _or?: Maybe<Array<Maybe<UserInvite_Bool_Exp>>>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  expireAt?: Maybe<Timestamptz_Comparison_Exp>;
  firestoreId?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  inviteeClientPermission?: Maybe<String_Comparison_Exp>;
  inviteeEmail?: Maybe<String_Comparison_Exp>;
  inviterClient?: Maybe<Client_Bool_Exp>;
  inviterClientId?: Maybe<Uuid_Comparison_Exp>;
  inviterClientName?: Maybe<String_Comparison_Exp>;
  inviterUser?: Maybe<User_Bool_Exp>;
  inviterUserId?: Maybe<Uuid_Comparison_Exp>;
  inviterUserName?: Maybe<String_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "userInvite" */
export enum UserInvite_Constraint {
  /** unique or primary key constraint */
  UserInvitePkey = 'userInvite_pkey',
}

/** input type for inserting data into table "userInvite" */
export type UserInvite_Insert_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  expireAt?: Maybe<Scalars['timestamptz']>;
  firestoreId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  inviteeClientPermission?: Maybe<Scalars['String']>;
  inviteeEmail?: Maybe<Scalars['String']>;
  inviterClient?: Maybe<Client_Obj_Rel_Insert_Input>;
  inviterClientId?: Maybe<Scalars['uuid']>;
  inviterClientName?: Maybe<Scalars['String']>;
  inviterUser?: Maybe<User_Obj_Rel_Insert_Input>;
  inviterUserId?: Maybe<Scalars['uuid']>;
  inviterUserName?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type UserInvite_Max_Fields = {
  __typename?: 'userInvite_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  expireAt?: Maybe<Scalars['timestamptz']>;
  firestoreId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  inviteeClientPermission?: Maybe<Scalars['String']>;
  inviteeEmail?: Maybe<Scalars['String']>;
  inviterClientId?: Maybe<Scalars['uuid']>;
  inviterClientName?: Maybe<Scalars['String']>;
  inviterUserId?: Maybe<Scalars['uuid']>;
  inviterUserName?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "userInvite" */
export type UserInvite_Max_Order_By = {
  createdAt?: Maybe<Order_By>;
  expireAt?: Maybe<Order_By>;
  firestoreId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inviteeClientPermission?: Maybe<Order_By>;
  inviteeEmail?: Maybe<Order_By>;
  inviterClientId?: Maybe<Order_By>;
  inviterClientName?: Maybe<Order_By>;
  inviterUserId?: Maybe<Order_By>;
  inviterUserName?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type UserInvite_Min_Fields = {
  __typename?: 'userInvite_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  expireAt?: Maybe<Scalars['timestamptz']>;
  firestoreId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  inviteeClientPermission?: Maybe<Scalars['String']>;
  inviteeEmail?: Maybe<Scalars['String']>;
  inviterClientId?: Maybe<Scalars['uuid']>;
  inviterClientName?: Maybe<Scalars['String']>;
  inviterUserId?: Maybe<Scalars['uuid']>;
  inviterUserName?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "userInvite" */
export type UserInvite_Min_Order_By = {
  createdAt?: Maybe<Order_By>;
  expireAt?: Maybe<Order_By>;
  firestoreId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inviteeClientPermission?: Maybe<Order_By>;
  inviteeEmail?: Maybe<Order_By>;
  inviterClientId?: Maybe<Order_By>;
  inviterClientName?: Maybe<Order_By>;
  inviterUserId?: Maybe<Order_By>;
  inviterUserName?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
};

/** response of any mutation on the table "userInvite" */
export type UserInvite_Mutation_Response = {
  __typename?: 'userInvite_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<UserInvite>;
};

/** input type for inserting object relation for remote table "userInvite" */
export type UserInvite_Obj_Rel_Insert_Input = {
  data: UserInvite_Insert_Input;
  on_conflict?: Maybe<UserInvite_On_Conflict>;
};

/** on conflict condition type for table "userInvite" */
export type UserInvite_On_Conflict = {
  constraint: UserInvite_Constraint;
  update_columns: Array<UserInvite_Update_Column>;
  where?: Maybe<UserInvite_Bool_Exp>;
};

/** ordering options when selecting data from "userInvite" */
export type UserInvite_Order_By = {
  createdAt?: Maybe<Order_By>;
  expireAt?: Maybe<Order_By>;
  firestoreId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inviteeClientPermission?: Maybe<Order_By>;
  inviteeEmail?: Maybe<Order_By>;
  inviterClient?: Maybe<Client_Order_By>;
  inviterClientId?: Maybe<Order_By>;
  inviterClientName?: Maybe<Order_By>;
  inviterUser?: Maybe<User_Order_By>;
  inviterUserId?: Maybe<Order_By>;
  inviterUserName?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
};

/** primary key columns input for table: "userInvite" */
export type UserInvite_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "userInvite" */
export enum UserInvite_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ExpireAt = 'expireAt',
  /** column name */
  FirestoreId = 'firestoreId',
  /** column name */
  Id = 'id',
  /** column name */
  InviteeClientPermission = 'inviteeClientPermission',
  /** column name */
  InviteeEmail = 'inviteeEmail',
  /** column name */
  InviterClientId = 'inviterClientId',
  /** column name */
  InviterClientName = 'inviterClientName',
  /** column name */
  InviterUserId = 'inviterUserId',
  /** column name */
  InviterUserName = 'inviterUserName',
  /** column name */
  Status = 'status',
}

/** input type for updating data in table "userInvite" */
export type UserInvite_Set_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  expireAt?: Maybe<Scalars['timestamptz']>;
  firestoreId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  inviteeClientPermission?: Maybe<Scalars['String']>;
  inviteeEmail?: Maybe<Scalars['String']>;
  inviterClientId?: Maybe<Scalars['uuid']>;
  inviterClientName?: Maybe<Scalars['String']>;
  inviterUserId?: Maybe<Scalars['uuid']>;
  inviterUserName?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

/** update columns of table "userInvite" */
export enum UserInvite_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ExpireAt = 'expireAt',
  /** column name */
  FirestoreId = 'firestoreId',
  /** column name */
  Id = 'id',
  /** column name */
  InviteeClientPermission = 'inviteeClientPermission',
  /** column name */
  InviteeEmail = 'inviteeEmail',
  /** column name */
  InviterClientId = 'inviterClientId',
  /** column name */
  InviterClientName = 'inviterClientName',
  /** column name */
  InviterUserId = 'inviterUserId',
  /** column name */
  InviterUserName = 'inviterUserName',
  /** column name */
  Status = 'status',
}

/** aggregated selection of "user" */
export type User_Aggregate = {
  __typename?: 'user_aggregate';
  aggregate?: Maybe<User_Aggregate_Fields>;
  nodes: Array<User>;
};

/** aggregate fields of "user" */
export type User_Aggregate_Fields = {
  __typename?: 'user_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<User_Max_Fields>;
  min?: Maybe<User_Min_Fields>;
};

/** aggregate fields of "user" */
export type User_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user" */
export type User_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<User_Max_Order_By>;
  min?: Maybe<User_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type User_Append_Input = {
  jobChangeFilters?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "user" */
export type User_Arr_Rel_Insert_Input = {
  data: Array<User_Insert_Input>;
  on_conflict?: Maybe<User_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user". All fields are combined with a logical 'AND'. */
export type User_Bool_Exp = {
  _and?: Maybe<Array<Maybe<User_Bool_Exp>>>;
  _not?: Maybe<User_Bool_Exp>;
  _or?: Maybe<Array<Maybe<User_Bool_Exp>>>;
  client?: Maybe<Client_Bool_Exp>;
  clientId?: Maybe<Uuid_Comparison_Exp>;
  clientPermission?: Maybe<String_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  firebaseUid?: Maybe<String_Comparison_Exp>;
  firestoreClientId?: Maybe<String_Comparison_Exp>;
  firestoreId?: Maybe<String_Comparison_Exp>;
  firstName?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  jobChangeFilters?: Maybe<Jsonb_Comparison_Exp>;
  lastName?: Maybe<String_Comparison_Exp>;
  qaQualifications?: Maybe<QaQualification_Bool_Exp>;
  role?: Maybe<String_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "user" */
export enum User_Constraint {
  /** unique or primary key constraint */
  UserEmailKey = 'user_email_key',
  /** unique or primary key constraint */
  UserFirebaseUidKey = 'user_firebaseUid_key',
  /** unique or primary key constraint */
  UserFirestoreIdKey = 'user_firestoreId_key',
  /** unique or primary key constraint */
  UserPkey = 'user_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type User_Delete_At_Path_Input = {
  jobChangeFilters?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type User_Delete_Elem_Input = {
  jobChangeFilters?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type User_Delete_Key_Input = {
  jobChangeFilters?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "user" */
export type User_Insert_Input = {
  client?: Maybe<Client_Obj_Rel_Insert_Input>;
  clientId?: Maybe<Scalars['uuid']>;
  clientPermission?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  firebaseUid?: Maybe<Scalars['String']>;
  firestoreClientId?: Maybe<Scalars['String']>;
  firestoreId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  jobChangeFilters?: Maybe<Scalars['jsonb']>;
  lastName?: Maybe<Scalars['String']>;
  qaQualifications?: Maybe<QaQualification_Arr_Rel_Insert_Input>;
  role?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type User_Max_Fields = {
  __typename?: 'user_max_fields';
  clientId?: Maybe<Scalars['uuid']>;
  clientPermission?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  firebaseUid?: Maybe<Scalars['String']>;
  firestoreClientId?: Maybe<Scalars['String']>;
  firestoreId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastName?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "user" */
export type User_Max_Order_By = {
  clientId?: Maybe<Order_By>;
  clientPermission?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  firebaseUid?: Maybe<Order_By>;
  firestoreClientId?: Maybe<Order_By>;
  firestoreId?: Maybe<Order_By>;
  firstName?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lastName?: Maybe<Order_By>;
  role?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type User_Min_Fields = {
  __typename?: 'user_min_fields';
  clientId?: Maybe<Scalars['uuid']>;
  clientPermission?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  firebaseUid?: Maybe<Scalars['String']>;
  firestoreClientId?: Maybe<Scalars['String']>;
  firestoreId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastName?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "user" */
export type User_Min_Order_By = {
  clientId?: Maybe<Order_By>;
  clientPermission?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  firebaseUid?: Maybe<Order_By>;
  firestoreClientId?: Maybe<Order_By>;
  firestoreId?: Maybe<Order_By>;
  firstName?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lastName?: Maybe<Order_By>;
  role?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** response of any mutation on the table "user" */
export type User_Mutation_Response = {
  __typename?: 'user_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<User>;
};

/** input type for inserting object relation for remote table "user" */
export type User_Obj_Rel_Insert_Input = {
  data: User_Insert_Input;
  on_conflict?: Maybe<User_On_Conflict>;
};

/** on conflict condition type for table "user" */
export type User_On_Conflict = {
  constraint: User_Constraint;
  update_columns: Array<User_Update_Column>;
  where?: Maybe<User_Bool_Exp>;
};

/** ordering options when selecting data from "user" */
export type User_Order_By = {
  client?: Maybe<Client_Order_By>;
  clientId?: Maybe<Order_By>;
  clientPermission?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  firebaseUid?: Maybe<Order_By>;
  firestoreClientId?: Maybe<Order_By>;
  firestoreId?: Maybe<Order_By>;
  firstName?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  jobChangeFilters?: Maybe<Order_By>;
  lastName?: Maybe<Order_By>;
  qaQualifications_aggregate?: Maybe<QaQualification_Aggregate_Order_By>;
  role?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: "user" */
export type User_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type User_Prepend_Input = {
  jobChangeFilters?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "user" */
export enum User_Select_Column {
  /** column name */
  ClientId = 'clientId',
  /** column name */
  ClientPermission = 'clientPermission',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  FirebaseUid = 'firebaseUid',
  /** column name */
  FirestoreClientId = 'firestoreClientId',
  /** column name */
  FirestoreId = 'firestoreId',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  Id = 'id',
  /** column name */
  JobChangeFilters = 'jobChangeFilters',
  /** column name */
  LastName = 'lastName',
  /** column name */
  Role = 'role',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "user" */
export type User_Set_Input = {
  clientId?: Maybe<Scalars['uuid']>;
  clientPermission?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  firebaseUid?: Maybe<Scalars['String']>;
  firestoreClientId?: Maybe<Scalars['String']>;
  firestoreId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  jobChangeFilters?: Maybe<Scalars['jsonb']>;
  lastName?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "user" */
export enum User_Update_Column {
  /** column name */
  ClientId = 'clientId',
  /** column name */
  ClientPermission = 'clientPermission',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  FirebaseUid = 'firebaseUid',
  /** column name */
  FirestoreClientId = 'firestoreClientId',
  /** column name */
  FirestoreId = 'firestoreId',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  Id = 'id',
  /** column name */
  JobChangeFilters = 'jobChangeFilters',
  /** column name */
  LastName = 'lastName',
  /** column name */
  Role = 'role',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** expression to compare columns of type uuid. All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: Maybe<Scalars['uuid']>;
  _gt?: Maybe<Scalars['uuid']>;
  _gte?: Maybe<Scalars['uuid']>;
  _in?: Maybe<Array<Scalars['uuid']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['uuid']>;
  _lte?: Maybe<Scalars['uuid']>;
  _neq?: Maybe<Scalars['uuid']>;
  _nin?: Maybe<Array<Scalars['uuid']>>;
};

/** columns and relationships of "warmCompany" */
export type WarmCompany = {
  __typename?: 'warmCompany';
  clientContactCount?: Maybe<Scalars['bigint']>;
  clientId?: Maybe<Scalars['uuid']>;
  clientName?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['uuid']>;
  companyLinkedinUrl?: Maybe<Scalars['String']>;
  companyLogoUrl?: Maybe<Scalars['String']>;
  companyPrimaryName?: Maybe<Scalars['String']>;
};

/** aggregated selection of "warmCompany" */
export type WarmCompany_Aggregate = {
  __typename?: 'warmCompany_aggregate';
  aggregate?: Maybe<WarmCompany_Aggregate_Fields>;
  nodes: Array<WarmCompany>;
};

/** aggregate fields of "warmCompany" */
export type WarmCompany_Aggregate_Fields = {
  __typename?: 'warmCompany_aggregate_fields';
  avg?: Maybe<WarmCompany_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<WarmCompany_Max_Fields>;
  min?: Maybe<WarmCompany_Min_Fields>;
  stddev?: Maybe<WarmCompany_Stddev_Fields>;
  stddev_pop?: Maybe<WarmCompany_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<WarmCompany_Stddev_Samp_Fields>;
  sum?: Maybe<WarmCompany_Sum_Fields>;
  var_pop?: Maybe<WarmCompany_Var_Pop_Fields>;
  var_samp?: Maybe<WarmCompany_Var_Samp_Fields>;
  variance?: Maybe<WarmCompany_Variance_Fields>;
};

/** aggregate fields of "warmCompany" */
export type WarmCompany_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<WarmCompany_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "warmCompany" */
export type WarmCompany_Aggregate_Order_By = {
  avg?: Maybe<WarmCompany_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<WarmCompany_Max_Order_By>;
  min?: Maybe<WarmCompany_Min_Order_By>;
  stddev?: Maybe<WarmCompany_Stddev_Order_By>;
  stddev_pop?: Maybe<WarmCompany_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<WarmCompany_Stddev_Samp_Order_By>;
  sum?: Maybe<WarmCompany_Sum_Order_By>;
  var_pop?: Maybe<WarmCompany_Var_Pop_Order_By>;
  var_samp?: Maybe<WarmCompany_Var_Samp_Order_By>;
  variance?: Maybe<WarmCompany_Variance_Order_By>;
};

/** aggregate avg on columns */
export type WarmCompany_Avg_Fields = {
  __typename?: 'warmCompany_avg_fields';
  clientContactCount?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "warmCompany" */
export type WarmCompany_Avg_Order_By = {
  clientContactCount?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "warmCompany". All fields are combined with a logical 'AND'. */
export type WarmCompany_Bool_Exp = {
  _and?: Maybe<Array<Maybe<WarmCompany_Bool_Exp>>>;
  _not?: Maybe<WarmCompany_Bool_Exp>;
  _or?: Maybe<Array<Maybe<WarmCompany_Bool_Exp>>>;
  clientContactCount?: Maybe<Bigint_Comparison_Exp>;
  clientId?: Maybe<Uuid_Comparison_Exp>;
  clientName?: Maybe<String_Comparison_Exp>;
  companyId?: Maybe<Uuid_Comparison_Exp>;
  companyLinkedinUrl?: Maybe<String_Comparison_Exp>;
  companyLogoUrl?: Maybe<String_Comparison_Exp>;
  companyPrimaryName?: Maybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type WarmCompany_Max_Fields = {
  __typename?: 'warmCompany_max_fields';
  clientContactCount?: Maybe<Scalars['bigint']>;
  clientId?: Maybe<Scalars['uuid']>;
  clientName?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['uuid']>;
  companyLinkedinUrl?: Maybe<Scalars['String']>;
  companyLogoUrl?: Maybe<Scalars['String']>;
  companyPrimaryName?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "warmCompany" */
export type WarmCompany_Max_Order_By = {
  clientContactCount?: Maybe<Order_By>;
  clientId?: Maybe<Order_By>;
  clientName?: Maybe<Order_By>;
  companyId?: Maybe<Order_By>;
  companyLinkedinUrl?: Maybe<Order_By>;
  companyLogoUrl?: Maybe<Order_By>;
  companyPrimaryName?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type WarmCompany_Min_Fields = {
  __typename?: 'warmCompany_min_fields';
  clientContactCount?: Maybe<Scalars['bigint']>;
  clientId?: Maybe<Scalars['uuid']>;
  clientName?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['uuid']>;
  companyLinkedinUrl?: Maybe<Scalars['String']>;
  companyLogoUrl?: Maybe<Scalars['String']>;
  companyPrimaryName?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "warmCompany" */
export type WarmCompany_Min_Order_By = {
  clientContactCount?: Maybe<Order_By>;
  clientId?: Maybe<Order_By>;
  clientName?: Maybe<Order_By>;
  companyId?: Maybe<Order_By>;
  companyLinkedinUrl?: Maybe<Order_By>;
  companyLogoUrl?: Maybe<Order_By>;
  companyPrimaryName?: Maybe<Order_By>;
};

/** ordering options when selecting data from "warmCompany" */
export type WarmCompany_Order_By = {
  clientContactCount?: Maybe<Order_By>;
  clientId?: Maybe<Order_By>;
  clientName?: Maybe<Order_By>;
  companyId?: Maybe<Order_By>;
  companyLinkedinUrl?: Maybe<Order_By>;
  companyLogoUrl?: Maybe<Order_By>;
  companyPrimaryName?: Maybe<Order_By>;
};

/** select columns of table "warmCompany" */
export enum WarmCompany_Select_Column {
  /** column name */
  ClientContactCount = 'clientContactCount',
  /** column name */
  ClientId = 'clientId',
  /** column name */
  ClientName = 'clientName',
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  CompanyLinkedinUrl = 'companyLinkedinUrl',
  /** column name */
  CompanyLogoUrl = 'companyLogoUrl',
  /** column name */
  CompanyPrimaryName = 'companyPrimaryName',
}

/** aggregate stddev on columns */
export type WarmCompany_Stddev_Fields = {
  __typename?: 'warmCompany_stddev_fields';
  clientContactCount?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "warmCompany" */
export type WarmCompany_Stddev_Order_By = {
  clientContactCount?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type WarmCompany_Stddev_Pop_Fields = {
  __typename?: 'warmCompany_stddev_pop_fields';
  clientContactCount?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "warmCompany" */
export type WarmCompany_Stddev_Pop_Order_By = {
  clientContactCount?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type WarmCompany_Stddev_Samp_Fields = {
  __typename?: 'warmCompany_stddev_samp_fields';
  clientContactCount?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "warmCompany" */
export type WarmCompany_Stddev_Samp_Order_By = {
  clientContactCount?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type WarmCompany_Sum_Fields = {
  __typename?: 'warmCompany_sum_fields';
  clientContactCount?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "warmCompany" */
export type WarmCompany_Sum_Order_By = {
  clientContactCount?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type WarmCompany_Var_Pop_Fields = {
  __typename?: 'warmCompany_var_pop_fields';
  clientContactCount?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "warmCompany" */
export type WarmCompany_Var_Pop_Order_By = {
  clientContactCount?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type WarmCompany_Var_Samp_Fields = {
  __typename?: 'warmCompany_var_samp_fields';
  clientContactCount?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "warmCompany" */
export type WarmCompany_Var_Samp_Order_By = {
  clientContactCount?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type WarmCompany_Variance_Fields = {
  __typename?: 'warmCompany_variance_fields';
  clientContactCount?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "warmCompany" */
export type WarmCompany_Variance_Order_By = {
  clientContactCount?: Maybe<Order_By>;
};

/** columns and relationships of "zoomCompany" */
export type ZoomCompany = {
  __typename?: 'zoomCompany';
  alexaRanking?: Maybe<Scalars['String']>;
  apolloOrganizationId?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  clearbitCompanyId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  crunchbaseUrl?: Maybe<Scalars['String']>;
  dataVendorName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  estimatedSize?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  industry?: Maybe<Scalars['String']>;
  keywords?: Maybe<Scalars['String']>;
  latestFundingRoundDate?: Maybe<Scalars['String']>;
  latestFundingStage?: Maybe<Scalars['String']>;
  linkedInUid?: Maybe<Scalars['String']>;
  linkedInUrl?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  seoDescription?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  technologyNames?: Maybe<Scalars['String']>;
  totalFunding?: Maybe<Scalars['String']>;
  totalFundingPrinted?: Maybe<Scalars['String']>;
  twitterUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  websiteDomain?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  yearFounded?: Maybe<Scalars['String']>;
};

/** aggregated selection of "zoomCompany" */
export type ZoomCompany_Aggregate = {
  __typename?: 'zoomCompany_aggregate';
  aggregate?: Maybe<ZoomCompany_Aggregate_Fields>;
  nodes: Array<ZoomCompany>;
};

/** aggregate fields of "zoomCompany" */
export type ZoomCompany_Aggregate_Fields = {
  __typename?: 'zoomCompany_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<ZoomCompany_Max_Fields>;
  min?: Maybe<ZoomCompany_Min_Fields>;
};

/** aggregate fields of "zoomCompany" */
export type ZoomCompany_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<ZoomCompany_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "zoomCompany" */
export type ZoomCompany_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<ZoomCompany_Max_Order_By>;
  min?: Maybe<ZoomCompany_Min_Order_By>;
};

/** input type for inserting array relation for remote table "zoomCompany" */
export type ZoomCompany_Arr_Rel_Insert_Input = {
  data: Array<ZoomCompany_Insert_Input>;
  on_conflict?: Maybe<ZoomCompany_On_Conflict>;
};

/** Boolean expression to filter rows from the table "zoomCompany". All fields are combined with a logical 'AND'. */
export type ZoomCompany_Bool_Exp = {
  _and?: Maybe<Array<Maybe<ZoomCompany_Bool_Exp>>>;
  _not?: Maybe<ZoomCompany_Bool_Exp>;
  _or?: Maybe<Array<Maybe<ZoomCompany_Bool_Exp>>>;
  alexaRanking?: Maybe<String_Comparison_Exp>;
  apolloOrganizationId?: Maybe<String_Comparison_Exp>;
  city?: Maybe<String_Comparison_Exp>;
  clearbitCompanyId?: Maybe<String_Comparison_Exp>;
  country?: Maybe<String_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  crunchbaseUrl?: Maybe<String_Comparison_Exp>;
  dataVendorName?: Maybe<String_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  estimatedSize?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  industry?: Maybe<String_Comparison_Exp>;
  keywords?: Maybe<String_Comparison_Exp>;
  latestFundingRoundDate?: Maybe<String_Comparison_Exp>;
  latestFundingStage?: Maybe<String_Comparison_Exp>;
  linkedInUid?: Maybe<String_Comparison_Exp>;
  linkedInUrl?: Maybe<String_Comparison_Exp>;
  logoUrl?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  phone?: Maybe<String_Comparison_Exp>;
  seoDescription?: Maybe<String_Comparison_Exp>;
  state?: Maybe<String_Comparison_Exp>;
  technologyNames?: Maybe<String_Comparison_Exp>;
  totalFunding?: Maybe<String_Comparison_Exp>;
  totalFundingPrinted?: Maybe<String_Comparison_Exp>;
  twitterUrl?: Maybe<String_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
  websiteDomain?: Maybe<String_Comparison_Exp>;
  websiteUrl?: Maybe<String_Comparison_Exp>;
  yearFounded?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "zoomCompany" */
export enum ZoomCompany_Constraint {
  /** unique or primary key constraint */
  ZoomCompanyPkey = 'zoomCompany_pkey',
  /** unique or primary key constraint */
  ZoomCompanyWebsiteDomainKey = 'zoomCompany_websiteDomain_key',
}

/** input type for inserting data into table "zoomCompany" */
export type ZoomCompany_Insert_Input = {
  alexaRanking?: Maybe<Scalars['String']>;
  apolloOrganizationId?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  clearbitCompanyId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  crunchbaseUrl?: Maybe<Scalars['String']>;
  dataVendorName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  estimatedSize?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  industry?: Maybe<Scalars['String']>;
  keywords?: Maybe<Scalars['String']>;
  latestFundingRoundDate?: Maybe<Scalars['String']>;
  latestFundingStage?: Maybe<Scalars['String']>;
  linkedInUid?: Maybe<Scalars['String']>;
  linkedInUrl?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  seoDescription?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  technologyNames?: Maybe<Scalars['String']>;
  totalFunding?: Maybe<Scalars['String']>;
  totalFundingPrinted?: Maybe<Scalars['String']>;
  twitterUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  websiteDomain?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  yearFounded?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ZoomCompany_Max_Fields = {
  __typename?: 'zoomCompany_max_fields';
  alexaRanking?: Maybe<Scalars['String']>;
  apolloOrganizationId?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  clearbitCompanyId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  crunchbaseUrl?: Maybe<Scalars['String']>;
  dataVendorName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  estimatedSize?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  industry?: Maybe<Scalars['String']>;
  keywords?: Maybe<Scalars['String']>;
  latestFundingRoundDate?: Maybe<Scalars['String']>;
  latestFundingStage?: Maybe<Scalars['String']>;
  linkedInUid?: Maybe<Scalars['String']>;
  linkedInUrl?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  seoDescription?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  technologyNames?: Maybe<Scalars['String']>;
  totalFunding?: Maybe<Scalars['String']>;
  totalFundingPrinted?: Maybe<Scalars['String']>;
  twitterUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  websiteDomain?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  yearFounded?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "zoomCompany" */
export type ZoomCompany_Max_Order_By = {
  alexaRanking?: Maybe<Order_By>;
  apolloOrganizationId?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  clearbitCompanyId?: Maybe<Order_By>;
  country?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  crunchbaseUrl?: Maybe<Order_By>;
  dataVendorName?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  estimatedSize?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  industry?: Maybe<Order_By>;
  keywords?: Maybe<Order_By>;
  latestFundingRoundDate?: Maybe<Order_By>;
  latestFundingStage?: Maybe<Order_By>;
  linkedInUid?: Maybe<Order_By>;
  linkedInUrl?: Maybe<Order_By>;
  logoUrl?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  seoDescription?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  technologyNames?: Maybe<Order_By>;
  totalFunding?: Maybe<Order_By>;
  totalFundingPrinted?: Maybe<Order_By>;
  twitterUrl?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  websiteDomain?: Maybe<Order_By>;
  websiteUrl?: Maybe<Order_By>;
  yearFounded?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type ZoomCompany_Min_Fields = {
  __typename?: 'zoomCompany_min_fields';
  alexaRanking?: Maybe<Scalars['String']>;
  apolloOrganizationId?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  clearbitCompanyId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  crunchbaseUrl?: Maybe<Scalars['String']>;
  dataVendorName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  estimatedSize?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  industry?: Maybe<Scalars['String']>;
  keywords?: Maybe<Scalars['String']>;
  latestFundingRoundDate?: Maybe<Scalars['String']>;
  latestFundingStage?: Maybe<Scalars['String']>;
  linkedInUid?: Maybe<Scalars['String']>;
  linkedInUrl?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  seoDescription?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  technologyNames?: Maybe<Scalars['String']>;
  totalFunding?: Maybe<Scalars['String']>;
  totalFundingPrinted?: Maybe<Scalars['String']>;
  twitterUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  websiteDomain?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  yearFounded?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "zoomCompany" */
export type ZoomCompany_Min_Order_By = {
  alexaRanking?: Maybe<Order_By>;
  apolloOrganizationId?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  clearbitCompanyId?: Maybe<Order_By>;
  country?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  crunchbaseUrl?: Maybe<Order_By>;
  dataVendorName?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  estimatedSize?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  industry?: Maybe<Order_By>;
  keywords?: Maybe<Order_By>;
  latestFundingRoundDate?: Maybe<Order_By>;
  latestFundingStage?: Maybe<Order_By>;
  linkedInUid?: Maybe<Order_By>;
  linkedInUrl?: Maybe<Order_By>;
  logoUrl?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  seoDescription?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  technologyNames?: Maybe<Order_By>;
  totalFunding?: Maybe<Order_By>;
  totalFundingPrinted?: Maybe<Order_By>;
  twitterUrl?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  websiteDomain?: Maybe<Order_By>;
  websiteUrl?: Maybe<Order_By>;
  yearFounded?: Maybe<Order_By>;
};

/** response of any mutation on the table "zoomCompany" */
export type ZoomCompany_Mutation_Response = {
  __typename?: 'zoomCompany_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<ZoomCompany>;
};

/** input type for inserting object relation for remote table "zoomCompany" */
export type ZoomCompany_Obj_Rel_Insert_Input = {
  data: ZoomCompany_Insert_Input;
  on_conflict?: Maybe<ZoomCompany_On_Conflict>;
};

/** on conflict condition type for table "zoomCompany" */
export type ZoomCompany_On_Conflict = {
  constraint: ZoomCompany_Constraint;
  update_columns: Array<ZoomCompany_Update_Column>;
  where?: Maybe<ZoomCompany_Bool_Exp>;
};

/** ordering options when selecting data from "zoomCompany" */
export type ZoomCompany_Order_By = {
  alexaRanking?: Maybe<Order_By>;
  apolloOrganizationId?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  clearbitCompanyId?: Maybe<Order_By>;
  country?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  crunchbaseUrl?: Maybe<Order_By>;
  dataVendorName?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  estimatedSize?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  industry?: Maybe<Order_By>;
  keywords?: Maybe<Order_By>;
  latestFundingRoundDate?: Maybe<Order_By>;
  latestFundingStage?: Maybe<Order_By>;
  linkedInUid?: Maybe<Order_By>;
  linkedInUrl?: Maybe<Order_By>;
  logoUrl?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  seoDescription?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  technologyNames?: Maybe<Order_By>;
  totalFunding?: Maybe<Order_By>;
  totalFundingPrinted?: Maybe<Order_By>;
  twitterUrl?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  websiteDomain?: Maybe<Order_By>;
  websiteUrl?: Maybe<Order_By>;
  yearFounded?: Maybe<Order_By>;
};

/** primary key columns input for table: "zoomCompany" */
export type ZoomCompany_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "zoomCompany" */
export enum ZoomCompany_Select_Column {
  /** column name */
  AlexaRanking = 'alexaRanking',
  /** column name */
  ApolloOrganizationId = 'apolloOrganizationId',
  /** column name */
  City = 'city',
  /** column name */
  ClearbitCompanyId = 'clearbitCompanyId',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CrunchbaseUrl = 'crunchbaseUrl',
  /** column name */
  DataVendorName = 'dataVendorName',
  /** column name */
  Description = 'description',
  /** column name */
  EstimatedSize = 'estimatedSize',
  /** column name */
  Id = 'id',
  /** column name */
  Industry = 'industry',
  /** column name */
  Keywords = 'keywords',
  /** column name */
  LatestFundingRoundDate = 'latestFundingRoundDate',
  /** column name */
  LatestFundingStage = 'latestFundingStage',
  /** column name */
  LinkedInUid = 'linkedInUid',
  /** column name */
  LinkedInUrl = 'linkedInUrl',
  /** column name */
  LogoUrl = 'logoUrl',
  /** column name */
  Name = 'name',
  /** column name */
  Phone = 'phone',
  /** column name */
  SeoDescription = 'seoDescription',
  /** column name */
  State = 'state',
  /** column name */
  TechnologyNames = 'technologyNames',
  /** column name */
  TotalFunding = 'totalFunding',
  /** column name */
  TotalFundingPrinted = 'totalFundingPrinted',
  /** column name */
  TwitterUrl = 'twitterUrl',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WebsiteDomain = 'websiteDomain',
  /** column name */
  WebsiteUrl = 'websiteUrl',
  /** column name */
  YearFounded = 'yearFounded',
}

/** input type for updating data in table "zoomCompany" */
export type ZoomCompany_Set_Input = {
  alexaRanking?: Maybe<Scalars['String']>;
  apolloOrganizationId?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  clearbitCompanyId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  crunchbaseUrl?: Maybe<Scalars['String']>;
  dataVendorName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  estimatedSize?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  industry?: Maybe<Scalars['String']>;
  keywords?: Maybe<Scalars['String']>;
  latestFundingRoundDate?: Maybe<Scalars['String']>;
  latestFundingStage?: Maybe<Scalars['String']>;
  linkedInUid?: Maybe<Scalars['String']>;
  linkedInUrl?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  seoDescription?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  technologyNames?: Maybe<Scalars['String']>;
  totalFunding?: Maybe<Scalars['String']>;
  totalFundingPrinted?: Maybe<Scalars['String']>;
  twitterUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  websiteDomain?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  yearFounded?: Maybe<Scalars['String']>;
};

/** update columns of table "zoomCompany" */
export enum ZoomCompany_Update_Column {
  /** column name */
  AlexaRanking = 'alexaRanking',
  /** column name */
  ApolloOrganizationId = 'apolloOrganizationId',
  /** column name */
  City = 'city',
  /** column name */
  ClearbitCompanyId = 'clearbitCompanyId',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CrunchbaseUrl = 'crunchbaseUrl',
  /** column name */
  DataVendorName = 'dataVendorName',
  /** column name */
  Description = 'description',
  /** column name */
  EstimatedSize = 'estimatedSize',
  /** column name */
  Id = 'id',
  /** column name */
  Industry = 'industry',
  /** column name */
  Keywords = 'keywords',
  /** column name */
  LatestFundingRoundDate = 'latestFundingRoundDate',
  /** column name */
  LatestFundingStage = 'latestFundingStage',
  /** column name */
  LinkedInUid = 'linkedInUid',
  /** column name */
  LinkedInUrl = 'linkedInUrl',
  /** column name */
  LogoUrl = 'logoUrl',
  /** column name */
  Name = 'name',
  /** column name */
  Phone = 'phone',
  /** column name */
  SeoDescription = 'seoDescription',
  /** column name */
  State = 'state',
  /** column name */
  TechnologyNames = 'technologyNames',
  /** column name */
  TotalFunding = 'totalFunding',
  /** column name */
  TotalFundingPrinted = 'totalFundingPrinted',
  /** column name */
  TwitterUrl = 'twitterUrl',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  WebsiteDomain = 'websiteDomain',
  /** column name */
  WebsiteUrl = 'websiteUrl',
  /** column name */
  YearFounded = 'yearFounded',
}

/** columns and relationships of "zoomContact" */
export type ZoomContact = {
  __typename?: 'zoomContact';
  apolloContactId?: Maybe<Scalars['String']>;
  apolloId?: Maybe<Scalars['String']>;
  apolloLastUpdatedAt?: Maybe<Scalars['String']>;
  apolloOrganizationId?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  clearbitPersonId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  dataVendorName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  gravityContactId?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  lastName?: Maybe<Scalars['String']>;
  linkedInUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  twitterUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  zoomCompany?: Maybe<ZoomCompany>;
  zoomCompanyId?: Maybe<Scalars['uuid']>;
};

/** columns and relationships of "zoomContactEnrichmentHistory" */
export type ZoomContactEnrichmentHistory = {
  __typename?: 'zoomContactEnrichmentHistory';
  email: Scalars['String'];
  id: Scalars['uuid'];
  lastEnrichedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "zoomContactEnrichmentHistory" */
export type ZoomContactEnrichmentHistory_Aggregate = {
  __typename?: 'zoomContactEnrichmentHistory_aggregate';
  aggregate?: Maybe<ZoomContactEnrichmentHistory_Aggregate_Fields>;
  nodes: Array<ZoomContactEnrichmentHistory>;
};

/** aggregate fields of "zoomContactEnrichmentHistory" */
export type ZoomContactEnrichmentHistory_Aggregate_Fields = {
  __typename?: 'zoomContactEnrichmentHistory_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<ZoomContactEnrichmentHistory_Max_Fields>;
  min?: Maybe<ZoomContactEnrichmentHistory_Min_Fields>;
};

/** aggregate fields of "zoomContactEnrichmentHistory" */
export type ZoomContactEnrichmentHistory_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<ZoomContactEnrichmentHistory_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "zoomContactEnrichmentHistory" */
export type ZoomContactEnrichmentHistory_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<ZoomContactEnrichmentHistory_Max_Order_By>;
  min?: Maybe<ZoomContactEnrichmentHistory_Min_Order_By>;
};

/** input type for inserting array relation for remote table "zoomContactEnrichmentHistory" */
export type ZoomContactEnrichmentHistory_Arr_Rel_Insert_Input = {
  data: Array<ZoomContactEnrichmentHistory_Insert_Input>;
  on_conflict?: Maybe<ZoomContactEnrichmentHistory_On_Conflict>;
};

/** Boolean expression to filter rows from the table "zoomContactEnrichmentHistory". All fields are combined with a logical 'AND'. */
export type ZoomContactEnrichmentHistory_Bool_Exp = {
  _and?: Maybe<Array<Maybe<ZoomContactEnrichmentHistory_Bool_Exp>>>;
  _not?: Maybe<ZoomContactEnrichmentHistory_Bool_Exp>;
  _or?: Maybe<Array<Maybe<ZoomContactEnrichmentHistory_Bool_Exp>>>;
  email?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  lastEnrichedAt?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "zoomContactEnrichmentHistory" */
export enum ZoomContactEnrichmentHistory_Constraint {
  /** unique or primary key constraint */
  ZoomEnrichmentHistoryEmailKey = 'zoomEnrichmentHistory_email_key',
  /** unique or primary key constraint */
  ZoomEnrichmentHistoryPkey = 'zoomEnrichmentHistory_pkey',
}

/** input type for inserting data into table "zoomContactEnrichmentHistory" */
export type ZoomContactEnrichmentHistory_Insert_Input = {
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastEnrichedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type ZoomContactEnrichmentHistory_Max_Fields = {
  __typename?: 'zoomContactEnrichmentHistory_max_fields';
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastEnrichedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "zoomContactEnrichmentHistory" */
export type ZoomContactEnrichmentHistory_Max_Order_By = {
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lastEnrichedAt?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type ZoomContactEnrichmentHistory_Min_Fields = {
  __typename?: 'zoomContactEnrichmentHistory_min_fields';
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastEnrichedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "zoomContactEnrichmentHistory" */
export type ZoomContactEnrichmentHistory_Min_Order_By = {
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lastEnrichedAt?: Maybe<Order_By>;
};

/** response of any mutation on the table "zoomContactEnrichmentHistory" */
export type ZoomContactEnrichmentHistory_Mutation_Response = {
  __typename?: 'zoomContactEnrichmentHistory_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<ZoomContactEnrichmentHistory>;
};

/** input type for inserting object relation for remote table "zoomContactEnrichmentHistory" */
export type ZoomContactEnrichmentHistory_Obj_Rel_Insert_Input = {
  data: ZoomContactEnrichmentHistory_Insert_Input;
  on_conflict?: Maybe<ZoomContactEnrichmentHistory_On_Conflict>;
};

/** on conflict condition type for table "zoomContactEnrichmentHistory" */
export type ZoomContactEnrichmentHistory_On_Conflict = {
  constraint: ZoomContactEnrichmentHistory_Constraint;
  update_columns: Array<ZoomContactEnrichmentHistory_Update_Column>;
  where?: Maybe<ZoomContactEnrichmentHistory_Bool_Exp>;
};

/** ordering options when selecting data from "zoomContactEnrichmentHistory" */
export type ZoomContactEnrichmentHistory_Order_By = {
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lastEnrichedAt?: Maybe<Order_By>;
};

/** primary key columns input for table: "zoomContactEnrichmentHistory" */
export type ZoomContactEnrichmentHistory_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "zoomContactEnrichmentHistory" */
export enum ZoomContactEnrichmentHistory_Select_Column {
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  LastEnrichedAt = 'lastEnrichedAt',
}

/** input type for updating data in table "zoomContactEnrichmentHistory" */
export type ZoomContactEnrichmentHistory_Set_Input = {
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastEnrichedAt?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "zoomContactEnrichmentHistory" */
export enum ZoomContactEnrichmentHistory_Update_Column {
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  LastEnrichedAt = 'lastEnrichedAt',
}

/** columns and relationships of "zoomContactInfo" */
export type ZoomContactInfo = {
  __typename?: 'zoomContactInfo';
  ask?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  linkedInUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  twitterUrl?: Maybe<Scalars['String']>;
  /** An object relationship */
  zoomCompany?: Maybe<ZoomCompany>;
  zoomCompanyId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  zoomContact?: Maybe<ZoomContact>;
  zoomContactId?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "zoomContactInfo" */
export type ZoomContactInfo_Aggregate = {
  __typename?: 'zoomContactInfo_aggregate';
  aggregate?: Maybe<ZoomContactInfo_Aggregate_Fields>;
  nodes: Array<ZoomContactInfo>;
};

/** aggregate fields of "zoomContactInfo" */
export type ZoomContactInfo_Aggregate_Fields = {
  __typename?: 'zoomContactInfo_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<ZoomContactInfo_Max_Fields>;
  min?: Maybe<ZoomContactInfo_Min_Fields>;
};

/** aggregate fields of "zoomContactInfo" */
export type ZoomContactInfo_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<ZoomContactInfo_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "zoomContactInfo" */
export type ZoomContactInfo_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<ZoomContactInfo_Max_Order_By>;
  min?: Maybe<ZoomContactInfo_Min_Order_By>;
};

/** input type for inserting array relation for remote table "zoomContactInfo" */
export type ZoomContactInfo_Arr_Rel_Insert_Input = {
  data: Array<ZoomContactInfo_Insert_Input>;
  on_conflict?: Maybe<ZoomContactInfo_On_Conflict>;
};

/** Boolean expression to filter rows from the table "zoomContactInfo". All fields are combined with a logical 'AND'. */
export type ZoomContactInfo_Bool_Exp = {
  _and?: Maybe<Array<Maybe<ZoomContactInfo_Bool_Exp>>>;
  _not?: Maybe<ZoomContactInfo_Bool_Exp>;
  _or?: Maybe<Array<Maybe<ZoomContactInfo_Bool_Exp>>>;
  ask?: Maybe<String_Comparison_Exp>;
  city?: Maybe<String_Comparison_Exp>;
  country?: Maybe<String_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  headline?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  linkedInUrl?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  photoUrl?: Maybe<String_Comparison_Exp>;
  state?: Maybe<String_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  twitterUrl?: Maybe<String_Comparison_Exp>;
  zoomCompany?: Maybe<ZoomCompany_Bool_Exp>;
  zoomCompanyId?: Maybe<Uuid_Comparison_Exp>;
  zoomContact?: Maybe<ZoomContact_Bool_Exp>;
  zoomContactId?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "zoomContactInfo" */
export enum ZoomContactInfo_Constraint {
  /** unique or primary key constraint */
  ZoomContactInfoEmailKey = 'zoomContactInfo_email_key',
  /** unique or primary key constraint */
  ZoomContactInfoPkey = 'zoomContactInfo_pkey',
}

/** input type for inserting data into table "zoomContactInfo" */
export type ZoomContactInfo_Insert_Input = {
  ask?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  linkedInUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  twitterUrl?: Maybe<Scalars['String']>;
  zoomCompany?: Maybe<ZoomCompany_Obj_Rel_Insert_Input>;
  zoomCompanyId?: Maybe<Scalars['uuid']>;
  zoomContact?: Maybe<ZoomContact_Obj_Rel_Insert_Input>;
  zoomContactId?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type ZoomContactInfo_Max_Fields = {
  __typename?: 'zoomContactInfo_max_fields';
  ask?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  linkedInUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  twitterUrl?: Maybe<Scalars['String']>;
  zoomCompanyId?: Maybe<Scalars['uuid']>;
  zoomContactId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "zoomContactInfo" */
export type ZoomContactInfo_Max_Order_By = {
  ask?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  country?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  headline?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  linkedInUrl?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  photoUrl?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  twitterUrl?: Maybe<Order_By>;
  zoomCompanyId?: Maybe<Order_By>;
  zoomContactId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type ZoomContactInfo_Min_Fields = {
  __typename?: 'zoomContactInfo_min_fields';
  ask?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  linkedInUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  twitterUrl?: Maybe<Scalars['String']>;
  zoomCompanyId?: Maybe<Scalars['uuid']>;
  zoomContactId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "zoomContactInfo" */
export type ZoomContactInfo_Min_Order_By = {
  ask?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  country?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  headline?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  linkedInUrl?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  photoUrl?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  twitterUrl?: Maybe<Order_By>;
  zoomCompanyId?: Maybe<Order_By>;
  zoomContactId?: Maybe<Order_By>;
};

/** response of any mutation on the table "zoomContactInfo" */
export type ZoomContactInfo_Mutation_Response = {
  __typename?: 'zoomContactInfo_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<ZoomContactInfo>;
};

/** input type for inserting object relation for remote table "zoomContactInfo" */
export type ZoomContactInfo_Obj_Rel_Insert_Input = {
  data: ZoomContactInfo_Insert_Input;
  on_conflict?: Maybe<ZoomContactInfo_On_Conflict>;
};

/** on conflict condition type for table "zoomContactInfo" */
export type ZoomContactInfo_On_Conflict = {
  constraint: ZoomContactInfo_Constraint;
  update_columns: Array<ZoomContactInfo_Update_Column>;
  where?: Maybe<ZoomContactInfo_Bool_Exp>;
};

/** ordering options when selecting data from "zoomContactInfo" */
export type ZoomContactInfo_Order_By = {
  ask?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  country?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  headline?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  linkedInUrl?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  photoUrl?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  twitterUrl?: Maybe<Order_By>;
  zoomCompany?: Maybe<ZoomCompany_Order_By>;
  zoomCompanyId?: Maybe<Order_By>;
  zoomContact?: Maybe<ZoomContact_Order_By>;
  zoomContactId?: Maybe<Order_By>;
};

/** primary key columns input for table: "zoomContactInfo" */
export type ZoomContactInfo_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "zoomContactInfo" */
export enum ZoomContactInfo_Select_Column {
  /** column name */
  Ask = 'ask',
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  Email = 'email',
  /** column name */
  Headline = 'headline',
  /** column name */
  Id = 'id',
  /** column name */
  LinkedInUrl = 'linkedInUrl',
  /** column name */
  Name = 'name',
  /** column name */
  PhotoUrl = 'photoUrl',
  /** column name */
  State = 'state',
  /** column name */
  Title = 'title',
  /** column name */
  TwitterUrl = 'twitterUrl',
  /** column name */
  ZoomCompanyId = 'zoomCompanyId',
  /** column name */
  ZoomContactId = 'zoomContactId',
}

/** input type for updating data in table "zoomContactInfo" */
export type ZoomContactInfo_Set_Input = {
  ask?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  linkedInUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  twitterUrl?: Maybe<Scalars['String']>;
  zoomCompanyId?: Maybe<Scalars['uuid']>;
  zoomContactId?: Maybe<Scalars['uuid']>;
};

/** update columns of table "zoomContactInfo" */
export enum ZoomContactInfo_Update_Column {
  /** column name */
  Ask = 'ask',
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  Email = 'email',
  /** column name */
  Headline = 'headline',
  /** column name */
  Id = 'id',
  /** column name */
  LinkedInUrl = 'linkedInUrl',
  /** column name */
  Name = 'name',
  /** column name */
  PhotoUrl = 'photoUrl',
  /** column name */
  State = 'state',
  /** column name */
  Title = 'title',
  /** column name */
  TwitterUrl = 'twitterUrl',
  /** column name */
  ZoomCompanyId = 'zoomCompanyId',
  /** column name */
  ZoomContactId = 'zoomContactId',
}

/** columns and relationships of "zoomContactNotes" */
export type ZoomContactNotes = {
  __typename?: 'zoomContactNotes';
  contactEmail: Scalars['String'];
  id: Scalars['uuid'];
  notes?: Maybe<Scalars['jsonb']>;
  ownerEmail: Scalars['String'];
};

/** columns and relationships of "zoomContactNotes" */
export type ZoomContactNotesNotesArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "zoomContactNotes" */
export type ZoomContactNotes_Aggregate = {
  __typename?: 'zoomContactNotes_aggregate';
  aggregate?: Maybe<ZoomContactNotes_Aggregate_Fields>;
  nodes: Array<ZoomContactNotes>;
};

/** aggregate fields of "zoomContactNotes" */
export type ZoomContactNotes_Aggregate_Fields = {
  __typename?: 'zoomContactNotes_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<ZoomContactNotes_Max_Fields>;
  min?: Maybe<ZoomContactNotes_Min_Fields>;
};

/** aggregate fields of "zoomContactNotes" */
export type ZoomContactNotes_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<ZoomContactNotes_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "zoomContactNotes" */
export type ZoomContactNotes_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<ZoomContactNotes_Max_Order_By>;
  min?: Maybe<ZoomContactNotes_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ZoomContactNotes_Append_Input = {
  notes?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "zoomContactNotes" */
export type ZoomContactNotes_Arr_Rel_Insert_Input = {
  data: Array<ZoomContactNotes_Insert_Input>;
  on_conflict?: Maybe<ZoomContactNotes_On_Conflict>;
};

/** Boolean expression to filter rows from the table "zoomContactNotes". All fields are combined with a logical 'AND'. */
export type ZoomContactNotes_Bool_Exp = {
  _and?: Maybe<Array<Maybe<ZoomContactNotes_Bool_Exp>>>;
  _not?: Maybe<ZoomContactNotes_Bool_Exp>;
  _or?: Maybe<Array<Maybe<ZoomContactNotes_Bool_Exp>>>;
  contactEmail?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  notes?: Maybe<Jsonb_Comparison_Exp>;
  ownerEmail?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "zoomContactNotes" */
export enum ZoomContactNotes_Constraint {
  /** unique or primary key constraint */
  ZoomContactNotesOwnerEmailContactEmailKey = 'zoomContactNotes_ownerEmail_contactEmail_key',
  /** unique or primary key constraint */
  ZoomContactNotesPkey = 'zoomContactNotes_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ZoomContactNotes_Delete_At_Path_Input = {
  notes?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ZoomContactNotes_Delete_Elem_Input = {
  notes?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ZoomContactNotes_Delete_Key_Input = {
  notes?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "zoomContactNotes" */
export type ZoomContactNotes_Insert_Input = {
  contactEmail?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  notes?: Maybe<Scalars['jsonb']>;
  ownerEmail?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ZoomContactNotes_Max_Fields = {
  __typename?: 'zoomContactNotes_max_fields';
  contactEmail?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  ownerEmail?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "zoomContactNotes" */
export type ZoomContactNotes_Max_Order_By = {
  contactEmail?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  ownerEmail?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type ZoomContactNotes_Min_Fields = {
  __typename?: 'zoomContactNotes_min_fields';
  contactEmail?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  ownerEmail?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "zoomContactNotes" */
export type ZoomContactNotes_Min_Order_By = {
  contactEmail?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  ownerEmail?: Maybe<Order_By>;
};

/** response of any mutation on the table "zoomContactNotes" */
export type ZoomContactNotes_Mutation_Response = {
  __typename?: 'zoomContactNotes_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<ZoomContactNotes>;
};

/** input type for inserting object relation for remote table "zoomContactNotes" */
export type ZoomContactNotes_Obj_Rel_Insert_Input = {
  data: ZoomContactNotes_Insert_Input;
  on_conflict?: Maybe<ZoomContactNotes_On_Conflict>;
};

/** on conflict condition type for table "zoomContactNotes" */
export type ZoomContactNotes_On_Conflict = {
  constraint: ZoomContactNotes_Constraint;
  update_columns: Array<ZoomContactNotes_Update_Column>;
  where?: Maybe<ZoomContactNotes_Bool_Exp>;
};

/** ordering options when selecting data from "zoomContactNotes" */
export type ZoomContactNotes_Order_By = {
  contactEmail?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  ownerEmail?: Maybe<Order_By>;
};

/** primary key columns input for table: "zoomContactNotes" */
export type ZoomContactNotes_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ZoomContactNotes_Prepend_Input = {
  notes?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "zoomContactNotes" */
export enum ZoomContactNotes_Select_Column {
  /** column name */
  ContactEmail = 'contactEmail',
  /** column name */
  Id = 'id',
  /** column name */
  Notes = 'notes',
  /** column name */
  OwnerEmail = 'ownerEmail',
}

/** input type for updating data in table "zoomContactNotes" */
export type ZoomContactNotes_Set_Input = {
  contactEmail?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  notes?: Maybe<Scalars['jsonb']>;
  ownerEmail?: Maybe<Scalars['String']>;
};

/** update columns of table "zoomContactNotes" */
export enum ZoomContactNotes_Update_Column {
  /** column name */
  ContactEmail = 'contactEmail',
  /** column name */
  Id = 'id',
  /** column name */
  Notes = 'notes',
  /** column name */
  OwnerEmail = 'ownerEmail',
}

/** aggregated selection of "zoomContact" */
export type ZoomContact_Aggregate = {
  __typename?: 'zoomContact_aggregate';
  aggregate?: Maybe<ZoomContact_Aggregate_Fields>;
  nodes: Array<ZoomContact>;
};

/** aggregate fields of "zoomContact" */
export type ZoomContact_Aggregate_Fields = {
  __typename?: 'zoomContact_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<ZoomContact_Max_Fields>;
  min?: Maybe<ZoomContact_Min_Fields>;
};

/** aggregate fields of "zoomContact" */
export type ZoomContact_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<ZoomContact_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "zoomContact" */
export type ZoomContact_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<ZoomContact_Max_Order_By>;
  min?: Maybe<ZoomContact_Min_Order_By>;
};

/** input type for inserting array relation for remote table "zoomContact" */
export type ZoomContact_Arr_Rel_Insert_Input = {
  data: Array<ZoomContact_Insert_Input>;
  on_conflict?: Maybe<ZoomContact_On_Conflict>;
};

/** Boolean expression to filter rows from the table "zoomContact". All fields are combined with a logical 'AND'. */
export type ZoomContact_Bool_Exp = {
  _and?: Maybe<Array<Maybe<ZoomContact_Bool_Exp>>>;
  _not?: Maybe<ZoomContact_Bool_Exp>;
  _or?: Maybe<Array<Maybe<ZoomContact_Bool_Exp>>>;
  apolloContactId?: Maybe<String_Comparison_Exp>;
  apolloId?: Maybe<String_Comparison_Exp>;
  apolloLastUpdatedAt?: Maybe<String_Comparison_Exp>;
  apolloOrganizationId?: Maybe<String_Comparison_Exp>;
  city?: Maybe<String_Comparison_Exp>;
  clearbitPersonId?: Maybe<String_Comparison_Exp>;
  country?: Maybe<String_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  dataVendorName?: Maybe<String_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  firstName?: Maybe<String_Comparison_Exp>;
  gravityContactId?: Maybe<String_Comparison_Exp>;
  headline?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  lastName?: Maybe<String_Comparison_Exp>;
  linkedInUrl?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  photoUrl?: Maybe<String_Comparison_Exp>;
  state?: Maybe<String_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  twitterUrl?: Maybe<String_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
  zoomCompany?: Maybe<ZoomCompany_Bool_Exp>;
  zoomCompanyId?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "zoomContact" */
export enum ZoomContact_Constraint {
  /** unique or primary key constraint */
  ZoomContactEmailKey = 'zoomContact_email_key',
  /** unique or primary key constraint */
  ZoomContactsPkey = 'zoomContacts_pkey',
}

/** input type for inserting data into table "zoomContact" */
export type ZoomContact_Insert_Input = {
  apolloContactId?: Maybe<Scalars['String']>;
  apolloId?: Maybe<Scalars['String']>;
  apolloLastUpdatedAt?: Maybe<Scalars['String']>;
  apolloOrganizationId?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  clearbitPersonId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  dataVendorName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  gravityContactId?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastName?: Maybe<Scalars['String']>;
  linkedInUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  twitterUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  zoomCompany?: Maybe<ZoomCompany_Obj_Rel_Insert_Input>;
  zoomCompanyId?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type ZoomContact_Max_Fields = {
  __typename?: 'zoomContact_max_fields';
  apolloContactId?: Maybe<Scalars['String']>;
  apolloId?: Maybe<Scalars['String']>;
  apolloLastUpdatedAt?: Maybe<Scalars['String']>;
  apolloOrganizationId?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  clearbitPersonId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  dataVendorName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  gravityContactId?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastName?: Maybe<Scalars['String']>;
  linkedInUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  twitterUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  zoomCompanyId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "zoomContact" */
export type ZoomContact_Max_Order_By = {
  apolloContactId?: Maybe<Order_By>;
  apolloId?: Maybe<Order_By>;
  apolloLastUpdatedAt?: Maybe<Order_By>;
  apolloOrganizationId?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  clearbitPersonId?: Maybe<Order_By>;
  country?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  dataVendorName?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  firstName?: Maybe<Order_By>;
  gravityContactId?: Maybe<Order_By>;
  headline?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lastName?: Maybe<Order_By>;
  linkedInUrl?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  photoUrl?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  twitterUrl?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  zoomCompanyId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type ZoomContact_Min_Fields = {
  __typename?: 'zoomContact_min_fields';
  apolloContactId?: Maybe<Scalars['String']>;
  apolloId?: Maybe<Scalars['String']>;
  apolloLastUpdatedAt?: Maybe<Scalars['String']>;
  apolloOrganizationId?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  clearbitPersonId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  dataVendorName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  gravityContactId?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastName?: Maybe<Scalars['String']>;
  linkedInUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  twitterUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  zoomCompanyId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "zoomContact" */
export type ZoomContact_Min_Order_By = {
  apolloContactId?: Maybe<Order_By>;
  apolloId?: Maybe<Order_By>;
  apolloLastUpdatedAt?: Maybe<Order_By>;
  apolloOrganizationId?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  clearbitPersonId?: Maybe<Order_By>;
  country?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  dataVendorName?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  firstName?: Maybe<Order_By>;
  gravityContactId?: Maybe<Order_By>;
  headline?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lastName?: Maybe<Order_By>;
  linkedInUrl?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  photoUrl?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  twitterUrl?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  zoomCompanyId?: Maybe<Order_By>;
};

/** response of any mutation on the table "zoomContact" */
export type ZoomContact_Mutation_Response = {
  __typename?: 'zoomContact_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<ZoomContact>;
};

/** input type for inserting object relation for remote table "zoomContact" */
export type ZoomContact_Obj_Rel_Insert_Input = {
  data: ZoomContact_Insert_Input;
  on_conflict?: Maybe<ZoomContact_On_Conflict>;
};

/** on conflict condition type for table "zoomContact" */
export type ZoomContact_On_Conflict = {
  constraint: ZoomContact_Constraint;
  update_columns: Array<ZoomContact_Update_Column>;
  where?: Maybe<ZoomContact_Bool_Exp>;
};

/** ordering options when selecting data from "zoomContact" */
export type ZoomContact_Order_By = {
  apolloContactId?: Maybe<Order_By>;
  apolloId?: Maybe<Order_By>;
  apolloLastUpdatedAt?: Maybe<Order_By>;
  apolloOrganizationId?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  clearbitPersonId?: Maybe<Order_By>;
  country?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  dataVendorName?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  firstName?: Maybe<Order_By>;
  gravityContactId?: Maybe<Order_By>;
  headline?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lastName?: Maybe<Order_By>;
  linkedInUrl?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  photoUrl?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  twitterUrl?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  zoomCompany?: Maybe<ZoomCompany_Order_By>;
  zoomCompanyId?: Maybe<Order_By>;
};

/** primary key columns input for table: "zoomContact" */
export type ZoomContact_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "zoomContact" */
export enum ZoomContact_Select_Column {
  /** column name */
  ApolloContactId = 'apolloContactId',
  /** column name */
  ApolloId = 'apolloId',
  /** column name */
  ApolloLastUpdatedAt = 'apolloLastUpdatedAt',
  /** column name */
  ApolloOrganizationId = 'apolloOrganizationId',
  /** column name */
  City = 'city',
  /** column name */
  ClearbitPersonId = 'clearbitPersonId',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DataVendorName = 'dataVendorName',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  GravityContactId = 'gravityContactId',
  /** column name */
  Headline = 'headline',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'lastName',
  /** column name */
  LinkedInUrl = 'linkedInUrl',
  /** column name */
  Name = 'name',
  /** column name */
  PhotoUrl = 'photoUrl',
  /** column name */
  State = 'state',
  /** column name */
  Title = 'title',
  /** column name */
  TwitterUrl = 'twitterUrl',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  ZoomCompanyId = 'zoomCompanyId',
}

/** input type for updating data in table "zoomContact" */
export type ZoomContact_Set_Input = {
  apolloContactId?: Maybe<Scalars['String']>;
  apolloId?: Maybe<Scalars['String']>;
  apolloLastUpdatedAt?: Maybe<Scalars['String']>;
  apolloOrganizationId?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  clearbitPersonId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  dataVendorName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  gravityContactId?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastName?: Maybe<Scalars['String']>;
  linkedInUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  twitterUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  zoomCompanyId?: Maybe<Scalars['uuid']>;
};

/** update columns of table "zoomContact" */
export enum ZoomContact_Update_Column {
  /** column name */
  ApolloContactId = 'apolloContactId',
  /** column name */
  ApolloId = 'apolloId',
  /** column name */
  ApolloLastUpdatedAt = 'apolloLastUpdatedAt',
  /** column name */
  ApolloOrganizationId = 'apolloOrganizationId',
  /** column name */
  City = 'city',
  /** column name */
  ClearbitPersonId = 'clearbitPersonId',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DataVendorName = 'dataVendorName',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  GravityContactId = 'gravityContactId',
  /** column name */
  Headline = 'headline',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'lastName',
  /** column name */
  LinkedInUrl = 'linkedInUrl',
  /** column name */
  Name = 'name',
  /** column name */
  PhotoUrl = 'photoUrl',
  /** column name */
  State = 'state',
  /** column name */
  Title = 'title',
  /** column name */
  TwitterUrl = 'twitterUrl',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  ZoomCompanyId = 'zoomCompanyId',
}

/** columns and relationships of "zoomEmailHistory" */
export type ZoomEmailHistory = {
  __typename?: 'zoomEmailHistory';
  bcc?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  category: Scalars['String'];
  cc?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  description: Scalars['String'];
  emailService: Scalars['String'];
  emailServiceMessageId?: Maybe<Scalars['String']>;
  emailServiceResponseJson?: Maybe<Scalars['jsonb']>;
  from: Scalars['String'];
  id: Scalars['uuid'];
  status: Scalars['String'];
  subject?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  zoomEvent?: Maybe<ZoomEvent>;
  zoomEventId?: Maybe<Scalars['Int']>;
  /** An object relationship */
  zoomUser?: Maybe<ZoomUser>;
  zoomUserId?: Maybe<Scalars['uuid']>;
};

/** columns and relationships of "zoomEmailHistory" */
export type ZoomEmailHistoryEmailServiceResponseJsonArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "zoomEmailHistory" */
export type ZoomEmailHistory_Aggregate = {
  __typename?: 'zoomEmailHistory_aggregate';
  aggregate?: Maybe<ZoomEmailHistory_Aggregate_Fields>;
  nodes: Array<ZoomEmailHistory>;
};

/** aggregate fields of "zoomEmailHistory" */
export type ZoomEmailHistory_Aggregate_Fields = {
  __typename?: 'zoomEmailHistory_aggregate_fields';
  avg?: Maybe<ZoomEmailHistory_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<ZoomEmailHistory_Max_Fields>;
  min?: Maybe<ZoomEmailHistory_Min_Fields>;
  stddev?: Maybe<ZoomEmailHistory_Stddev_Fields>;
  stddev_pop?: Maybe<ZoomEmailHistory_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ZoomEmailHistory_Stddev_Samp_Fields>;
  sum?: Maybe<ZoomEmailHistory_Sum_Fields>;
  var_pop?: Maybe<ZoomEmailHistory_Var_Pop_Fields>;
  var_samp?: Maybe<ZoomEmailHistory_Var_Samp_Fields>;
  variance?: Maybe<ZoomEmailHistory_Variance_Fields>;
};

/** aggregate fields of "zoomEmailHistory" */
export type ZoomEmailHistory_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<ZoomEmailHistory_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "zoomEmailHistory" */
export type ZoomEmailHistory_Aggregate_Order_By = {
  avg?: Maybe<ZoomEmailHistory_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<ZoomEmailHistory_Max_Order_By>;
  min?: Maybe<ZoomEmailHistory_Min_Order_By>;
  stddev?: Maybe<ZoomEmailHistory_Stddev_Order_By>;
  stddev_pop?: Maybe<ZoomEmailHistory_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<ZoomEmailHistory_Stddev_Samp_Order_By>;
  sum?: Maybe<ZoomEmailHistory_Sum_Order_By>;
  var_pop?: Maybe<ZoomEmailHistory_Var_Pop_Order_By>;
  var_samp?: Maybe<ZoomEmailHistory_Var_Samp_Order_By>;
  variance?: Maybe<ZoomEmailHistory_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ZoomEmailHistory_Append_Input = {
  emailServiceResponseJson?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "zoomEmailHistory" */
export type ZoomEmailHistory_Arr_Rel_Insert_Input = {
  data: Array<ZoomEmailHistory_Insert_Input>;
  on_conflict?: Maybe<ZoomEmailHistory_On_Conflict>;
};

/** aggregate avg on columns */
export type ZoomEmailHistory_Avg_Fields = {
  __typename?: 'zoomEmailHistory_avg_fields';
  zoomEventId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "zoomEmailHistory" */
export type ZoomEmailHistory_Avg_Order_By = {
  zoomEventId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "zoomEmailHistory". All fields are combined with a logical 'AND'. */
export type ZoomEmailHistory_Bool_Exp = {
  _and?: Maybe<Array<Maybe<ZoomEmailHistory_Bool_Exp>>>;
  _not?: Maybe<ZoomEmailHistory_Bool_Exp>;
  _or?: Maybe<Array<Maybe<ZoomEmailHistory_Bool_Exp>>>;
  bcc?: Maybe<String_Comparison_Exp>;
  body?: Maybe<String_Comparison_Exp>;
  category?: Maybe<String_Comparison_Exp>;
  cc?: Maybe<String_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  emailService?: Maybe<String_Comparison_Exp>;
  emailServiceMessageId?: Maybe<String_Comparison_Exp>;
  emailServiceResponseJson?: Maybe<Jsonb_Comparison_Exp>;
  from?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  subject?: Maybe<String_Comparison_Exp>;
  to?: Maybe<String_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
  zoomEvent?: Maybe<ZoomEvent_Bool_Exp>;
  zoomEventId?: Maybe<Int_Comparison_Exp>;
  zoomUser?: Maybe<ZoomUser_Bool_Exp>;
  zoomUserId?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "zoomEmailHistory" */
export enum ZoomEmailHistory_Constraint {
  /** unique or primary key constraint */
  ZoomEmailHistoryPkey = 'zoomEmailHistory_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ZoomEmailHistory_Delete_At_Path_Input = {
  emailServiceResponseJson?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ZoomEmailHistory_Delete_Elem_Input = {
  emailServiceResponseJson?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ZoomEmailHistory_Delete_Key_Input = {
  emailServiceResponseJson?: Maybe<Scalars['String']>;
};

/** input type for incrementing integer column in table "zoomEmailHistory" */
export type ZoomEmailHistory_Inc_Input = {
  zoomEventId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "zoomEmailHistory" */
export type ZoomEmailHistory_Insert_Input = {
  bcc?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  cc?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  emailService?: Maybe<Scalars['String']>;
  emailServiceMessageId?: Maybe<Scalars['String']>;
  emailServiceResponseJson?: Maybe<Scalars['jsonb']>;
  from?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  zoomEvent?: Maybe<ZoomEvent_Obj_Rel_Insert_Input>;
  zoomEventId?: Maybe<Scalars['Int']>;
  zoomUser?: Maybe<ZoomUser_Obj_Rel_Insert_Input>;
  zoomUserId?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type ZoomEmailHistory_Max_Fields = {
  __typename?: 'zoomEmailHistory_max_fields';
  bcc?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  cc?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  emailService?: Maybe<Scalars['String']>;
  emailServiceMessageId?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  zoomEventId?: Maybe<Scalars['Int']>;
  zoomUserId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "zoomEmailHistory" */
export type ZoomEmailHistory_Max_Order_By = {
  bcc?: Maybe<Order_By>;
  body?: Maybe<Order_By>;
  category?: Maybe<Order_By>;
  cc?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  emailService?: Maybe<Order_By>;
  emailServiceMessageId?: Maybe<Order_By>;
  from?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  subject?: Maybe<Order_By>;
  to?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  zoomEventId?: Maybe<Order_By>;
  zoomUserId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type ZoomEmailHistory_Min_Fields = {
  __typename?: 'zoomEmailHistory_min_fields';
  bcc?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  cc?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  emailService?: Maybe<Scalars['String']>;
  emailServiceMessageId?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  zoomEventId?: Maybe<Scalars['Int']>;
  zoomUserId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "zoomEmailHistory" */
export type ZoomEmailHistory_Min_Order_By = {
  bcc?: Maybe<Order_By>;
  body?: Maybe<Order_By>;
  category?: Maybe<Order_By>;
  cc?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  emailService?: Maybe<Order_By>;
  emailServiceMessageId?: Maybe<Order_By>;
  from?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  subject?: Maybe<Order_By>;
  to?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  zoomEventId?: Maybe<Order_By>;
  zoomUserId?: Maybe<Order_By>;
};

/** response of any mutation on the table "zoomEmailHistory" */
export type ZoomEmailHistory_Mutation_Response = {
  __typename?: 'zoomEmailHistory_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<ZoomEmailHistory>;
};

/** input type for inserting object relation for remote table "zoomEmailHistory" */
export type ZoomEmailHistory_Obj_Rel_Insert_Input = {
  data: ZoomEmailHistory_Insert_Input;
  on_conflict?: Maybe<ZoomEmailHistory_On_Conflict>;
};

/** on conflict condition type for table "zoomEmailHistory" */
export type ZoomEmailHistory_On_Conflict = {
  constraint: ZoomEmailHistory_Constraint;
  update_columns: Array<ZoomEmailHistory_Update_Column>;
  where?: Maybe<ZoomEmailHistory_Bool_Exp>;
};

/** ordering options when selecting data from "zoomEmailHistory" */
export type ZoomEmailHistory_Order_By = {
  bcc?: Maybe<Order_By>;
  body?: Maybe<Order_By>;
  category?: Maybe<Order_By>;
  cc?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  emailService?: Maybe<Order_By>;
  emailServiceMessageId?: Maybe<Order_By>;
  emailServiceResponseJson?: Maybe<Order_By>;
  from?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  subject?: Maybe<Order_By>;
  to?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  zoomEvent?: Maybe<ZoomEvent_Order_By>;
  zoomEventId?: Maybe<Order_By>;
  zoomUser?: Maybe<ZoomUser_Order_By>;
  zoomUserId?: Maybe<Order_By>;
};

/** primary key columns input for table: "zoomEmailHistory" */
export type ZoomEmailHistory_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ZoomEmailHistory_Prepend_Input = {
  emailServiceResponseJson?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "zoomEmailHistory" */
export enum ZoomEmailHistory_Select_Column {
  /** column name */
  Bcc = 'bcc',
  /** column name */
  Body = 'body',
  /** column name */
  Category = 'category',
  /** column name */
  Cc = 'cc',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  EmailService = 'emailService',
  /** column name */
  EmailServiceMessageId = 'emailServiceMessageId',
  /** column name */
  EmailServiceResponseJson = 'emailServiceResponseJson',
  /** column name */
  From = 'from',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  Subject = 'subject',
  /** column name */
  To = 'to',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  ZoomEventId = 'zoomEventId',
  /** column name */
  ZoomUserId = 'zoomUserId',
}

/** input type for updating data in table "zoomEmailHistory" */
export type ZoomEmailHistory_Set_Input = {
  bcc?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  cc?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  emailService?: Maybe<Scalars['String']>;
  emailServiceMessageId?: Maybe<Scalars['String']>;
  emailServiceResponseJson?: Maybe<Scalars['jsonb']>;
  from?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  zoomEventId?: Maybe<Scalars['Int']>;
  zoomUserId?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type ZoomEmailHistory_Stddev_Fields = {
  __typename?: 'zoomEmailHistory_stddev_fields';
  zoomEventId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "zoomEmailHistory" */
export type ZoomEmailHistory_Stddev_Order_By = {
  zoomEventId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type ZoomEmailHistory_Stddev_Pop_Fields = {
  __typename?: 'zoomEmailHistory_stddev_pop_fields';
  zoomEventId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "zoomEmailHistory" */
export type ZoomEmailHistory_Stddev_Pop_Order_By = {
  zoomEventId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type ZoomEmailHistory_Stddev_Samp_Fields = {
  __typename?: 'zoomEmailHistory_stddev_samp_fields';
  zoomEventId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "zoomEmailHistory" */
export type ZoomEmailHistory_Stddev_Samp_Order_By = {
  zoomEventId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type ZoomEmailHistory_Sum_Fields = {
  __typename?: 'zoomEmailHistory_sum_fields';
  zoomEventId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "zoomEmailHistory" */
export type ZoomEmailHistory_Sum_Order_By = {
  zoomEventId?: Maybe<Order_By>;
};

/** update columns of table "zoomEmailHistory" */
export enum ZoomEmailHistory_Update_Column {
  /** column name */
  Bcc = 'bcc',
  /** column name */
  Body = 'body',
  /** column name */
  Category = 'category',
  /** column name */
  Cc = 'cc',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  EmailService = 'emailService',
  /** column name */
  EmailServiceMessageId = 'emailServiceMessageId',
  /** column name */
  EmailServiceResponseJson = 'emailServiceResponseJson',
  /** column name */
  From = 'from',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  Subject = 'subject',
  /** column name */
  To = 'to',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  ZoomEventId = 'zoomEventId',
  /** column name */
  ZoomUserId = 'zoomUserId',
}

/** aggregate var_pop on columns */
export type ZoomEmailHistory_Var_Pop_Fields = {
  __typename?: 'zoomEmailHistory_var_pop_fields';
  zoomEventId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "zoomEmailHistory" */
export type ZoomEmailHistory_Var_Pop_Order_By = {
  zoomEventId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type ZoomEmailHistory_Var_Samp_Fields = {
  __typename?: 'zoomEmailHistory_var_samp_fields';
  zoomEventId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "zoomEmailHistory" */
export type ZoomEmailHistory_Var_Samp_Order_By = {
  zoomEventId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type ZoomEmailHistory_Variance_Fields = {
  __typename?: 'zoomEmailHistory_variance_fields';
  zoomEventId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "zoomEmailHistory" */
export type ZoomEmailHistory_Variance_Order_By = {
  zoomEventId?: Maybe<Order_By>;
};

/** columns and relationships of "zoomEvent" */
export type ZoomEvent = {
  __typename?: 'zoomEvent';
  conferenceData?: Maybe<Scalars['jsonb']>;
  created?: Maybe<Scalars['timestamptz']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['timestamptz']>;
  eventJson?: Maybe<Scalars['jsonb']>;
  googleId: Scalars['String'];
  guestsCanInviteOthers: Scalars['Boolean'];
  guestsCanModify: Scalars['Boolean'];
  guestsCanSeeOtherGuests: Scalars['Boolean'];
  hangoutLink?: Maybe<Scalars['String']>;
  htmlLink?: Maybe<Scalars['String']>;
  iCalUID?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  lastEnrichedAt?: Maybe<Scalars['timestamptz']>;
  location?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['timestamptz']>;
  status: Scalars['String'];
  summary?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  zoomEventParticipants: Array<ZoomEventParticipant>;
  /** An aggregated array relationship */
  zoomEventParticipants_aggregate: ZoomEventParticipant_Aggregate;
};

/** columns and relationships of "zoomEvent" */
export type ZoomEventConferenceDataArgs = {
  path?: Maybe<Scalars['String']>;
};

/** columns and relationships of "zoomEvent" */
export type ZoomEventEventJsonArgs = {
  path?: Maybe<Scalars['String']>;
};

/** columns and relationships of "zoomEvent" */
export type ZoomEventZoomEventParticipantsArgs = {
  distinct_on?: Maybe<Array<ZoomEventParticipant_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ZoomEventParticipant_Order_By>>;
  where?: Maybe<ZoomEventParticipant_Bool_Exp>;
};

/** columns and relationships of "zoomEvent" */
export type ZoomEventZoomEventParticipants_AggregateArgs = {
  distinct_on?: Maybe<Array<ZoomEventParticipant_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ZoomEventParticipant_Order_By>>;
  where?: Maybe<ZoomEventParticipant_Bool_Exp>;
};

/** columns and relationships of "zoomEventParticipant" */
export type ZoomEventParticipant = {
  __typename?: 'zoomEventParticipant';
  createdAt?: Maybe<Scalars['timestamptz']>;
  email: Scalars['String'];
  id: Scalars['uuid'];
  inMeetingStatus?: Maybe<Scalars['String']>;
  lastEnrichedAt?: Maybe<Scalars['timestamptz']>;
  organizer?: Maybe<Scalars['Boolean']>;
  responseStatus: Scalars['String'];
  updatedAt?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  zoomContact?: Maybe<ZoomContact>;
  zoomContactId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  zoomContactInfo?: Maybe<ZoomContactInfo>;
  zoomContactInfoId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  zoomEvent: ZoomEvent;
  zoomEventId: Scalars['Int'];
  /** An object relationship */
  zoomUser?: Maybe<ZoomUser>;
  zoomUserId?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "zoomEventParticipant" */
export type ZoomEventParticipant_Aggregate = {
  __typename?: 'zoomEventParticipant_aggregate';
  aggregate?: Maybe<ZoomEventParticipant_Aggregate_Fields>;
  nodes: Array<ZoomEventParticipant>;
};

/** aggregate fields of "zoomEventParticipant" */
export type ZoomEventParticipant_Aggregate_Fields = {
  __typename?: 'zoomEventParticipant_aggregate_fields';
  avg?: Maybe<ZoomEventParticipant_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<ZoomEventParticipant_Max_Fields>;
  min?: Maybe<ZoomEventParticipant_Min_Fields>;
  stddev?: Maybe<ZoomEventParticipant_Stddev_Fields>;
  stddev_pop?: Maybe<ZoomEventParticipant_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ZoomEventParticipant_Stddev_Samp_Fields>;
  sum?: Maybe<ZoomEventParticipant_Sum_Fields>;
  var_pop?: Maybe<ZoomEventParticipant_Var_Pop_Fields>;
  var_samp?: Maybe<ZoomEventParticipant_Var_Samp_Fields>;
  variance?: Maybe<ZoomEventParticipant_Variance_Fields>;
};

/** aggregate fields of "zoomEventParticipant" */
export type ZoomEventParticipant_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<ZoomEventParticipant_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "zoomEventParticipant" */
export type ZoomEventParticipant_Aggregate_Order_By = {
  avg?: Maybe<ZoomEventParticipant_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<ZoomEventParticipant_Max_Order_By>;
  min?: Maybe<ZoomEventParticipant_Min_Order_By>;
  stddev?: Maybe<ZoomEventParticipant_Stddev_Order_By>;
  stddev_pop?: Maybe<ZoomEventParticipant_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<ZoomEventParticipant_Stddev_Samp_Order_By>;
  sum?: Maybe<ZoomEventParticipant_Sum_Order_By>;
  var_pop?: Maybe<ZoomEventParticipant_Var_Pop_Order_By>;
  var_samp?: Maybe<ZoomEventParticipant_Var_Samp_Order_By>;
  variance?: Maybe<ZoomEventParticipant_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "zoomEventParticipant" */
export type ZoomEventParticipant_Arr_Rel_Insert_Input = {
  data: Array<ZoomEventParticipant_Insert_Input>;
  on_conflict?: Maybe<ZoomEventParticipant_On_Conflict>;
};

/** aggregate avg on columns */
export type ZoomEventParticipant_Avg_Fields = {
  __typename?: 'zoomEventParticipant_avg_fields';
  zoomEventId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "zoomEventParticipant" */
export type ZoomEventParticipant_Avg_Order_By = {
  zoomEventId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "zoomEventParticipant". All fields are combined with a logical 'AND'. */
export type ZoomEventParticipant_Bool_Exp = {
  _and?: Maybe<Array<Maybe<ZoomEventParticipant_Bool_Exp>>>;
  _not?: Maybe<ZoomEventParticipant_Bool_Exp>;
  _or?: Maybe<Array<Maybe<ZoomEventParticipant_Bool_Exp>>>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  inMeetingStatus?: Maybe<String_Comparison_Exp>;
  lastEnrichedAt?: Maybe<Timestamptz_Comparison_Exp>;
  organizer?: Maybe<Boolean_Comparison_Exp>;
  responseStatus?: Maybe<String_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
  zoomContact?: Maybe<ZoomContact_Bool_Exp>;
  zoomContactId?: Maybe<Uuid_Comparison_Exp>;
  zoomContactInfo?: Maybe<ZoomContactInfo_Bool_Exp>;
  zoomContactInfoId?: Maybe<Uuid_Comparison_Exp>;
  zoomEvent?: Maybe<ZoomEvent_Bool_Exp>;
  zoomEventId?: Maybe<Int_Comparison_Exp>;
  zoomUser?: Maybe<ZoomUser_Bool_Exp>;
  zoomUserId?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "zoomEventParticipant" */
export enum ZoomEventParticipant_Constraint {
  /** unique or primary key constraint */
  ZoomEventParticipantPkey = 'zoomEventParticipant_pkey',
  /** unique or primary key constraint */
  ZoomEventParticipantZoomEventIdEmailKey = 'zoomEventParticipant_zoomEventId_email_key',
}

/** input type for incrementing integer column in table "zoomEventParticipant" */
export type ZoomEventParticipant_Inc_Input = {
  zoomEventId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "zoomEventParticipant" */
export type ZoomEventParticipant_Insert_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  inMeetingStatus?: Maybe<Scalars['String']>;
  lastEnrichedAt?: Maybe<Scalars['timestamptz']>;
  organizer?: Maybe<Scalars['Boolean']>;
  responseStatus?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  zoomContact?: Maybe<ZoomContact_Obj_Rel_Insert_Input>;
  zoomContactId?: Maybe<Scalars['uuid']>;
  zoomContactInfo?: Maybe<ZoomContactInfo_Obj_Rel_Insert_Input>;
  zoomContactInfoId?: Maybe<Scalars['uuid']>;
  zoomEvent?: Maybe<ZoomEvent_Obj_Rel_Insert_Input>;
  zoomEventId?: Maybe<Scalars['Int']>;
  zoomUser?: Maybe<ZoomUser_Obj_Rel_Insert_Input>;
  zoomUserId?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type ZoomEventParticipant_Max_Fields = {
  __typename?: 'zoomEventParticipant_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  inMeetingStatus?: Maybe<Scalars['String']>;
  lastEnrichedAt?: Maybe<Scalars['timestamptz']>;
  responseStatus?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  zoomContactId?: Maybe<Scalars['uuid']>;
  zoomContactInfoId?: Maybe<Scalars['uuid']>;
  zoomEventId?: Maybe<Scalars['Int']>;
  zoomUserId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "zoomEventParticipant" */
export type ZoomEventParticipant_Max_Order_By = {
  createdAt?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inMeetingStatus?: Maybe<Order_By>;
  lastEnrichedAt?: Maybe<Order_By>;
  responseStatus?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  zoomContactId?: Maybe<Order_By>;
  zoomContactInfoId?: Maybe<Order_By>;
  zoomEventId?: Maybe<Order_By>;
  zoomUserId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type ZoomEventParticipant_Min_Fields = {
  __typename?: 'zoomEventParticipant_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  inMeetingStatus?: Maybe<Scalars['String']>;
  lastEnrichedAt?: Maybe<Scalars['timestamptz']>;
  responseStatus?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  zoomContactId?: Maybe<Scalars['uuid']>;
  zoomContactInfoId?: Maybe<Scalars['uuid']>;
  zoomEventId?: Maybe<Scalars['Int']>;
  zoomUserId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "zoomEventParticipant" */
export type ZoomEventParticipant_Min_Order_By = {
  createdAt?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inMeetingStatus?: Maybe<Order_By>;
  lastEnrichedAt?: Maybe<Order_By>;
  responseStatus?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  zoomContactId?: Maybe<Order_By>;
  zoomContactInfoId?: Maybe<Order_By>;
  zoomEventId?: Maybe<Order_By>;
  zoomUserId?: Maybe<Order_By>;
};

/** response of any mutation on the table "zoomEventParticipant" */
export type ZoomEventParticipant_Mutation_Response = {
  __typename?: 'zoomEventParticipant_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<ZoomEventParticipant>;
};

/** input type for inserting object relation for remote table "zoomEventParticipant" */
export type ZoomEventParticipant_Obj_Rel_Insert_Input = {
  data: ZoomEventParticipant_Insert_Input;
  on_conflict?: Maybe<ZoomEventParticipant_On_Conflict>;
};

/** on conflict condition type for table "zoomEventParticipant" */
export type ZoomEventParticipant_On_Conflict = {
  constraint: ZoomEventParticipant_Constraint;
  update_columns: Array<ZoomEventParticipant_Update_Column>;
  where?: Maybe<ZoomEventParticipant_Bool_Exp>;
};

/** ordering options when selecting data from "zoomEventParticipant" */
export type ZoomEventParticipant_Order_By = {
  createdAt?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inMeetingStatus?: Maybe<Order_By>;
  lastEnrichedAt?: Maybe<Order_By>;
  organizer?: Maybe<Order_By>;
  responseStatus?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  zoomContact?: Maybe<ZoomContact_Order_By>;
  zoomContactId?: Maybe<Order_By>;
  zoomContactInfo?: Maybe<ZoomContactInfo_Order_By>;
  zoomContactInfoId?: Maybe<Order_By>;
  zoomEvent?: Maybe<ZoomEvent_Order_By>;
  zoomEventId?: Maybe<Order_By>;
  zoomUser?: Maybe<ZoomUser_Order_By>;
  zoomUserId?: Maybe<Order_By>;
};

/** primary key columns input for table: "zoomEventParticipant" */
export type ZoomEventParticipant_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "zoomEventParticipant" */
export enum ZoomEventParticipant_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  InMeetingStatus = 'inMeetingStatus',
  /** column name */
  LastEnrichedAt = 'lastEnrichedAt',
  /** column name */
  Organizer = 'organizer',
  /** column name */
  ResponseStatus = 'responseStatus',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  ZoomContactId = 'zoomContactId',
  /** column name */
  ZoomContactInfoId = 'zoomContactInfoId',
  /** column name */
  ZoomEventId = 'zoomEventId',
  /** column name */
  ZoomUserId = 'zoomUserId',
}

/** input type for updating data in table "zoomEventParticipant" */
export type ZoomEventParticipant_Set_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  inMeetingStatus?: Maybe<Scalars['String']>;
  lastEnrichedAt?: Maybe<Scalars['timestamptz']>;
  organizer?: Maybe<Scalars['Boolean']>;
  responseStatus?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  zoomContactId?: Maybe<Scalars['uuid']>;
  zoomContactInfoId?: Maybe<Scalars['uuid']>;
  zoomEventId?: Maybe<Scalars['Int']>;
  zoomUserId?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type ZoomEventParticipant_Stddev_Fields = {
  __typename?: 'zoomEventParticipant_stddev_fields';
  zoomEventId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "zoomEventParticipant" */
export type ZoomEventParticipant_Stddev_Order_By = {
  zoomEventId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type ZoomEventParticipant_Stddev_Pop_Fields = {
  __typename?: 'zoomEventParticipant_stddev_pop_fields';
  zoomEventId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "zoomEventParticipant" */
export type ZoomEventParticipant_Stddev_Pop_Order_By = {
  zoomEventId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type ZoomEventParticipant_Stddev_Samp_Fields = {
  __typename?: 'zoomEventParticipant_stddev_samp_fields';
  zoomEventId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "zoomEventParticipant" */
export type ZoomEventParticipant_Stddev_Samp_Order_By = {
  zoomEventId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type ZoomEventParticipant_Sum_Fields = {
  __typename?: 'zoomEventParticipant_sum_fields';
  zoomEventId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "zoomEventParticipant" */
export type ZoomEventParticipant_Sum_Order_By = {
  zoomEventId?: Maybe<Order_By>;
};

/** update columns of table "zoomEventParticipant" */
export enum ZoomEventParticipant_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  InMeetingStatus = 'inMeetingStatus',
  /** column name */
  LastEnrichedAt = 'lastEnrichedAt',
  /** column name */
  Organizer = 'organizer',
  /** column name */
  ResponseStatus = 'responseStatus',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  ZoomContactId = 'zoomContactId',
  /** column name */
  ZoomContactInfoId = 'zoomContactInfoId',
  /** column name */
  ZoomEventId = 'zoomEventId',
  /** column name */
  ZoomUserId = 'zoomUserId',
}

/** aggregate var_pop on columns */
export type ZoomEventParticipant_Var_Pop_Fields = {
  __typename?: 'zoomEventParticipant_var_pop_fields';
  zoomEventId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "zoomEventParticipant" */
export type ZoomEventParticipant_Var_Pop_Order_By = {
  zoomEventId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type ZoomEventParticipant_Var_Samp_Fields = {
  __typename?: 'zoomEventParticipant_var_samp_fields';
  zoomEventId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "zoomEventParticipant" */
export type ZoomEventParticipant_Var_Samp_Order_By = {
  zoomEventId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type ZoomEventParticipant_Variance_Fields = {
  __typename?: 'zoomEventParticipant_variance_fields';
  zoomEventId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "zoomEventParticipant" */
export type ZoomEventParticipant_Variance_Order_By = {
  zoomEventId?: Maybe<Order_By>;
};

/** aggregated selection of "zoomEvent" */
export type ZoomEvent_Aggregate = {
  __typename?: 'zoomEvent_aggregate';
  aggregate?: Maybe<ZoomEvent_Aggregate_Fields>;
  nodes: Array<ZoomEvent>;
};

/** aggregate fields of "zoomEvent" */
export type ZoomEvent_Aggregate_Fields = {
  __typename?: 'zoomEvent_aggregate_fields';
  avg?: Maybe<ZoomEvent_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<ZoomEvent_Max_Fields>;
  min?: Maybe<ZoomEvent_Min_Fields>;
  stddev?: Maybe<ZoomEvent_Stddev_Fields>;
  stddev_pop?: Maybe<ZoomEvent_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<ZoomEvent_Stddev_Samp_Fields>;
  sum?: Maybe<ZoomEvent_Sum_Fields>;
  var_pop?: Maybe<ZoomEvent_Var_Pop_Fields>;
  var_samp?: Maybe<ZoomEvent_Var_Samp_Fields>;
  variance?: Maybe<ZoomEvent_Variance_Fields>;
};

/** aggregate fields of "zoomEvent" */
export type ZoomEvent_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<ZoomEvent_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "zoomEvent" */
export type ZoomEvent_Aggregate_Order_By = {
  avg?: Maybe<ZoomEvent_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<ZoomEvent_Max_Order_By>;
  min?: Maybe<ZoomEvent_Min_Order_By>;
  stddev?: Maybe<ZoomEvent_Stddev_Order_By>;
  stddev_pop?: Maybe<ZoomEvent_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<ZoomEvent_Stddev_Samp_Order_By>;
  sum?: Maybe<ZoomEvent_Sum_Order_By>;
  var_pop?: Maybe<ZoomEvent_Var_Pop_Order_By>;
  var_samp?: Maybe<ZoomEvent_Var_Samp_Order_By>;
  variance?: Maybe<ZoomEvent_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ZoomEvent_Append_Input = {
  conferenceData?: Maybe<Scalars['jsonb']>;
  eventJson?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "zoomEvent" */
export type ZoomEvent_Arr_Rel_Insert_Input = {
  data: Array<ZoomEvent_Insert_Input>;
  on_conflict?: Maybe<ZoomEvent_On_Conflict>;
};

/** aggregate avg on columns */
export type ZoomEvent_Avg_Fields = {
  __typename?: 'zoomEvent_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "zoomEvent" */
export type ZoomEvent_Avg_Order_By = {
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "zoomEvent". All fields are combined with a logical 'AND'. */
export type ZoomEvent_Bool_Exp = {
  _and?: Maybe<Array<Maybe<ZoomEvent_Bool_Exp>>>;
  _not?: Maybe<ZoomEvent_Bool_Exp>;
  _or?: Maybe<Array<Maybe<ZoomEvent_Bool_Exp>>>;
  conferenceData?: Maybe<Jsonb_Comparison_Exp>;
  created?: Maybe<Timestamptz_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  end?: Maybe<Timestamptz_Comparison_Exp>;
  eventJson?: Maybe<Jsonb_Comparison_Exp>;
  googleId?: Maybe<String_Comparison_Exp>;
  guestsCanInviteOthers?: Maybe<Boolean_Comparison_Exp>;
  guestsCanModify?: Maybe<Boolean_Comparison_Exp>;
  guestsCanSeeOtherGuests?: Maybe<Boolean_Comparison_Exp>;
  hangoutLink?: Maybe<String_Comparison_Exp>;
  htmlLink?: Maybe<String_Comparison_Exp>;
  iCalUID?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  lastEnrichedAt?: Maybe<Timestamptz_Comparison_Exp>;
  location?: Maybe<String_Comparison_Exp>;
  start?: Maybe<Timestamptz_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  summary?: Maybe<String_Comparison_Exp>;
  updated?: Maybe<Timestamptz_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
  zoomEventParticipants?: Maybe<ZoomEventParticipant_Bool_Exp>;
};

/** unique or primary key constraints on table "zoomEvent" */
export enum ZoomEvent_Constraint {
  /** unique or primary key constraint */
  ZoomEventGoogleIdKey = 'zoomEvent_googleId_key',
  /** unique or primary key constraint */
  ZoomEventPkey = 'zoomEvent_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ZoomEvent_Delete_At_Path_Input = {
  conferenceData?: Maybe<Array<Maybe<Scalars['String']>>>;
  eventJson?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ZoomEvent_Delete_Elem_Input = {
  conferenceData?: Maybe<Scalars['Int']>;
  eventJson?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ZoomEvent_Delete_Key_Input = {
  conferenceData?: Maybe<Scalars['String']>;
  eventJson?: Maybe<Scalars['String']>;
};

/** input type for incrementing integer column in table "zoomEvent" */
export type ZoomEvent_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "zoomEvent" */
export type ZoomEvent_Insert_Input = {
  conferenceData?: Maybe<Scalars['jsonb']>;
  created?: Maybe<Scalars['timestamptz']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['timestamptz']>;
  eventJson?: Maybe<Scalars['jsonb']>;
  googleId?: Maybe<Scalars['String']>;
  guestsCanInviteOthers?: Maybe<Scalars['Boolean']>;
  guestsCanModify?: Maybe<Scalars['Boolean']>;
  guestsCanSeeOtherGuests?: Maybe<Scalars['Boolean']>;
  hangoutLink?: Maybe<Scalars['String']>;
  htmlLink?: Maybe<Scalars['String']>;
  iCalUID?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  lastEnrichedAt?: Maybe<Scalars['timestamptz']>;
  location?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  zoomEventParticipants?: Maybe<ZoomEventParticipant_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type ZoomEvent_Max_Fields = {
  __typename?: 'zoomEvent_max_fields';
  created?: Maybe<Scalars['timestamptz']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['timestamptz']>;
  googleId?: Maybe<Scalars['String']>;
  hangoutLink?: Maybe<Scalars['String']>;
  htmlLink?: Maybe<Scalars['String']>;
  iCalUID?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  lastEnrichedAt?: Maybe<Scalars['timestamptz']>;
  location?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "zoomEvent" */
export type ZoomEvent_Max_Order_By = {
  created?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  end?: Maybe<Order_By>;
  googleId?: Maybe<Order_By>;
  hangoutLink?: Maybe<Order_By>;
  htmlLink?: Maybe<Order_By>;
  iCalUID?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lastEnrichedAt?: Maybe<Order_By>;
  location?: Maybe<Order_By>;
  start?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  summary?: Maybe<Order_By>;
  updated?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type ZoomEvent_Min_Fields = {
  __typename?: 'zoomEvent_min_fields';
  created?: Maybe<Scalars['timestamptz']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['timestamptz']>;
  googleId?: Maybe<Scalars['String']>;
  hangoutLink?: Maybe<Scalars['String']>;
  htmlLink?: Maybe<Scalars['String']>;
  iCalUID?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  lastEnrichedAt?: Maybe<Scalars['timestamptz']>;
  location?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "zoomEvent" */
export type ZoomEvent_Min_Order_By = {
  created?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  end?: Maybe<Order_By>;
  googleId?: Maybe<Order_By>;
  hangoutLink?: Maybe<Order_By>;
  htmlLink?: Maybe<Order_By>;
  iCalUID?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lastEnrichedAt?: Maybe<Order_By>;
  location?: Maybe<Order_By>;
  start?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  summary?: Maybe<Order_By>;
  updated?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** response of any mutation on the table "zoomEvent" */
export type ZoomEvent_Mutation_Response = {
  __typename?: 'zoomEvent_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<ZoomEvent>;
};

/** input type for inserting object relation for remote table "zoomEvent" */
export type ZoomEvent_Obj_Rel_Insert_Input = {
  data: ZoomEvent_Insert_Input;
  on_conflict?: Maybe<ZoomEvent_On_Conflict>;
};

/** on conflict condition type for table "zoomEvent" */
export type ZoomEvent_On_Conflict = {
  constraint: ZoomEvent_Constraint;
  update_columns: Array<ZoomEvent_Update_Column>;
  where?: Maybe<ZoomEvent_Bool_Exp>;
};

/** ordering options when selecting data from "zoomEvent" */
export type ZoomEvent_Order_By = {
  conferenceData?: Maybe<Order_By>;
  created?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  end?: Maybe<Order_By>;
  eventJson?: Maybe<Order_By>;
  googleId?: Maybe<Order_By>;
  guestsCanInviteOthers?: Maybe<Order_By>;
  guestsCanModify?: Maybe<Order_By>;
  guestsCanSeeOtherGuests?: Maybe<Order_By>;
  hangoutLink?: Maybe<Order_By>;
  htmlLink?: Maybe<Order_By>;
  iCalUID?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lastEnrichedAt?: Maybe<Order_By>;
  location?: Maybe<Order_By>;
  start?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  summary?: Maybe<Order_By>;
  updated?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  zoomEventParticipants_aggregate?: Maybe<ZoomEventParticipant_Aggregate_Order_By>;
};

/** primary key columns input for table: "zoomEvent" */
export type ZoomEvent_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ZoomEvent_Prepend_Input = {
  conferenceData?: Maybe<Scalars['jsonb']>;
  eventJson?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "zoomEvent" */
export enum ZoomEvent_Select_Column {
  /** column name */
  ConferenceData = 'conferenceData',
  /** column name */
  Created = 'created',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  End = 'end',
  /** column name */
  EventJson = 'eventJson',
  /** column name */
  GoogleId = 'googleId',
  /** column name */
  GuestsCanInviteOthers = 'guestsCanInviteOthers',
  /** column name */
  GuestsCanModify = 'guestsCanModify',
  /** column name */
  GuestsCanSeeOtherGuests = 'guestsCanSeeOtherGuests',
  /** column name */
  HangoutLink = 'hangoutLink',
  /** column name */
  HtmlLink = 'htmlLink',
  /** column name */
  ICalUid = 'iCalUID',
  /** column name */
  Id = 'id',
  /** column name */
  LastEnrichedAt = 'lastEnrichedAt',
  /** column name */
  Location = 'location',
  /** column name */
  Start = 'start',
  /** column name */
  Status = 'status',
  /** column name */
  Summary = 'summary',
  /** column name */
  Updated = 'updated',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "zoomEvent" */
export type ZoomEvent_Set_Input = {
  conferenceData?: Maybe<Scalars['jsonb']>;
  created?: Maybe<Scalars['timestamptz']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['timestamptz']>;
  eventJson?: Maybe<Scalars['jsonb']>;
  googleId?: Maybe<Scalars['String']>;
  guestsCanInviteOthers?: Maybe<Scalars['Boolean']>;
  guestsCanModify?: Maybe<Scalars['Boolean']>;
  guestsCanSeeOtherGuests?: Maybe<Scalars['Boolean']>;
  hangoutLink?: Maybe<Scalars['String']>;
  htmlLink?: Maybe<Scalars['String']>;
  iCalUID?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  lastEnrichedAt?: Maybe<Scalars['timestamptz']>;
  location?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['timestamptz']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type ZoomEvent_Stddev_Fields = {
  __typename?: 'zoomEvent_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "zoomEvent" */
export type ZoomEvent_Stddev_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type ZoomEvent_Stddev_Pop_Fields = {
  __typename?: 'zoomEvent_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "zoomEvent" */
export type ZoomEvent_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type ZoomEvent_Stddev_Samp_Fields = {
  __typename?: 'zoomEvent_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "zoomEvent" */
export type ZoomEvent_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type ZoomEvent_Sum_Fields = {
  __typename?: 'zoomEvent_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "zoomEvent" */
export type ZoomEvent_Sum_Order_By = {
  id?: Maybe<Order_By>;
};

/** update columns of table "zoomEvent" */
export enum ZoomEvent_Update_Column {
  /** column name */
  ConferenceData = 'conferenceData',
  /** column name */
  Created = 'created',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  End = 'end',
  /** column name */
  EventJson = 'eventJson',
  /** column name */
  GoogleId = 'googleId',
  /** column name */
  GuestsCanInviteOthers = 'guestsCanInviteOthers',
  /** column name */
  GuestsCanModify = 'guestsCanModify',
  /** column name */
  GuestsCanSeeOtherGuests = 'guestsCanSeeOtherGuests',
  /** column name */
  HangoutLink = 'hangoutLink',
  /** column name */
  HtmlLink = 'htmlLink',
  /** column name */
  ICalUid = 'iCalUID',
  /** column name */
  Id = 'id',
  /** column name */
  LastEnrichedAt = 'lastEnrichedAt',
  /** column name */
  Location = 'location',
  /** column name */
  Start = 'start',
  /** column name */
  Status = 'status',
  /** column name */
  Summary = 'summary',
  /** column name */
  Updated = 'updated',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** aggregate var_pop on columns */
export type ZoomEvent_Var_Pop_Fields = {
  __typename?: 'zoomEvent_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "zoomEvent" */
export type ZoomEvent_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type ZoomEvent_Var_Samp_Fields = {
  __typename?: 'zoomEvent_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "zoomEvent" */
export type ZoomEvent_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type ZoomEvent_Variance_Fields = {
  __typename?: 'zoomEvent_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "zoomEvent" */
export type ZoomEvent_Variance_Order_By = {
  id?: Maybe<Order_By>;
};

/** columns and relationships of "zoomExternalAuthUser" */
export type ZoomExternalAuthUser = {
  __typename?: 'zoomExternalAuthUser';
  externalUserId: Scalars['String'];
  googleIdToken?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  zoomAccessToken: Scalars['String'];
  zoomRefreshToken: Scalars['String'];
};

/** aggregated selection of "zoomExternalAuthUser" */
export type ZoomExternalAuthUser_Aggregate = {
  __typename?: 'zoomExternalAuthUser_aggregate';
  aggregate?: Maybe<ZoomExternalAuthUser_Aggregate_Fields>;
  nodes: Array<ZoomExternalAuthUser>;
};

/** aggregate fields of "zoomExternalAuthUser" */
export type ZoomExternalAuthUser_Aggregate_Fields = {
  __typename?: 'zoomExternalAuthUser_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<ZoomExternalAuthUser_Max_Fields>;
  min?: Maybe<ZoomExternalAuthUser_Min_Fields>;
};

/** aggregate fields of "zoomExternalAuthUser" */
export type ZoomExternalAuthUser_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<ZoomExternalAuthUser_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "zoomExternalAuthUser" */
export type ZoomExternalAuthUser_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<ZoomExternalAuthUser_Max_Order_By>;
  min?: Maybe<ZoomExternalAuthUser_Min_Order_By>;
};

/** input type for inserting array relation for remote table "zoomExternalAuthUser" */
export type ZoomExternalAuthUser_Arr_Rel_Insert_Input = {
  data: Array<ZoomExternalAuthUser_Insert_Input>;
  on_conflict?: Maybe<ZoomExternalAuthUser_On_Conflict>;
};

/** Boolean expression to filter rows from the table "zoomExternalAuthUser". All fields are combined with a logical 'AND'. */
export type ZoomExternalAuthUser_Bool_Exp = {
  _and?: Maybe<Array<Maybe<ZoomExternalAuthUser_Bool_Exp>>>;
  _not?: Maybe<ZoomExternalAuthUser_Bool_Exp>;
  _or?: Maybe<Array<Maybe<ZoomExternalAuthUser_Bool_Exp>>>;
  externalUserId?: Maybe<String_Comparison_Exp>;
  googleIdToken?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  zoomAccessToken?: Maybe<String_Comparison_Exp>;
  zoomRefreshToken?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "zoomExternalAuthUser" */
export enum ZoomExternalAuthUser_Constraint {
  /** unique or primary key constraint */
  ZoomExternalAuthUserExternalUserIdKey = 'zoomExternalAuthUser_externalUserId_key',
  /** unique or primary key constraint */
  ZoomExternalAuthUserPkey = 'zoomExternalAuthUser_pkey',
}

/** input type for inserting data into table "zoomExternalAuthUser" */
export type ZoomExternalAuthUser_Insert_Input = {
  externalUserId?: Maybe<Scalars['String']>;
  googleIdToken?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  zoomAccessToken?: Maybe<Scalars['String']>;
  zoomRefreshToken?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type ZoomExternalAuthUser_Max_Fields = {
  __typename?: 'zoomExternalAuthUser_max_fields';
  externalUserId?: Maybe<Scalars['String']>;
  googleIdToken?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  zoomAccessToken?: Maybe<Scalars['String']>;
  zoomRefreshToken?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "zoomExternalAuthUser" */
export type ZoomExternalAuthUser_Max_Order_By = {
  externalUserId?: Maybe<Order_By>;
  googleIdToken?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  zoomAccessToken?: Maybe<Order_By>;
  zoomRefreshToken?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type ZoomExternalAuthUser_Min_Fields = {
  __typename?: 'zoomExternalAuthUser_min_fields';
  externalUserId?: Maybe<Scalars['String']>;
  googleIdToken?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  zoomAccessToken?: Maybe<Scalars['String']>;
  zoomRefreshToken?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "zoomExternalAuthUser" */
export type ZoomExternalAuthUser_Min_Order_By = {
  externalUserId?: Maybe<Order_By>;
  googleIdToken?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  zoomAccessToken?: Maybe<Order_By>;
  zoomRefreshToken?: Maybe<Order_By>;
};

/** response of any mutation on the table "zoomExternalAuthUser" */
export type ZoomExternalAuthUser_Mutation_Response = {
  __typename?: 'zoomExternalAuthUser_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<ZoomExternalAuthUser>;
};

/** input type for inserting object relation for remote table "zoomExternalAuthUser" */
export type ZoomExternalAuthUser_Obj_Rel_Insert_Input = {
  data: ZoomExternalAuthUser_Insert_Input;
  on_conflict?: Maybe<ZoomExternalAuthUser_On_Conflict>;
};

/** on conflict condition type for table "zoomExternalAuthUser" */
export type ZoomExternalAuthUser_On_Conflict = {
  constraint: ZoomExternalAuthUser_Constraint;
  update_columns: Array<ZoomExternalAuthUser_Update_Column>;
  where?: Maybe<ZoomExternalAuthUser_Bool_Exp>;
};

/** ordering options when selecting data from "zoomExternalAuthUser" */
export type ZoomExternalAuthUser_Order_By = {
  externalUserId?: Maybe<Order_By>;
  googleIdToken?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  zoomAccessToken?: Maybe<Order_By>;
  zoomRefreshToken?: Maybe<Order_By>;
};

/** primary key columns input for table: "zoomExternalAuthUser" */
export type ZoomExternalAuthUser_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "zoomExternalAuthUser" */
export enum ZoomExternalAuthUser_Select_Column {
  /** column name */
  ExternalUserId = 'externalUserId',
  /** column name */
  GoogleIdToken = 'googleIdToken',
  /** column name */
  Id = 'id',
  /** column name */
  ZoomAccessToken = 'zoomAccessToken',
  /** column name */
  ZoomRefreshToken = 'zoomRefreshToken',
}

/** input type for updating data in table "zoomExternalAuthUser" */
export type ZoomExternalAuthUser_Set_Input = {
  externalUserId?: Maybe<Scalars['String']>;
  googleIdToken?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  zoomAccessToken?: Maybe<Scalars['String']>;
  zoomRefreshToken?: Maybe<Scalars['String']>;
};

/** update columns of table "zoomExternalAuthUser" */
export enum ZoomExternalAuthUser_Update_Column {
  /** column name */
  ExternalUserId = 'externalUserId',
  /** column name */
  GoogleIdToken = 'googleIdToken',
  /** column name */
  Id = 'id',
  /** column name */
  ZoomAccessToken = 'zoomAccessToken',
  /** column name */
  ZoomRefreshToken = 'zoomRefreshToken',
}

/** columns and relationships of "zoomUser" */
export type ZoomUser = {
  __typename?: 'zoomUser';
  calendarEnrichmentStatus?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  email: Scalars['String'];
  firebaseUid: Scalars['String'];
  firstName: Scalars['String'];
  googleAccessToken?: Maybe<Scalars['String']>;
  googleRefreshToken?: Maybe<Scalars['String']>;
  googleSyncToken?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  integrationType?: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
  nylasAccessToken?: Maybe<Scalars['String']>;
  nylasAccountId?: Maybe<Scalars['String']>;
  nylasLinkedAt?: Maybe<Scalars['timestamptz']>;
  nylasSyncState?: Maybe<Scalars['String']>;
  onboardVersion?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  syncGoogleCalendar: Scalars['Boolean'];
  updatedAt: Scalars['timestamptz'];
  zoomExternalAuthUserId?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "zoomUser" */
export type ZoomUser_Aggregate = {
  __typename?: 'zoomUser_aggregate';
  aggregate?: Maybe<ZoomUser_Aggregate_Fields>;
  nodes: Array<ZoomUser>;
};

/** aggregate fields of "zoomUser" */
export type ZoomUser_Aggregate_Fields = {
  __typename?: 'zoomUser_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<ZoomUser_Max_Fields>;
  min?: Maybe<ZoomUser_Min_Fields>;
};

/** aggregate fields of "zoomUser" */
export type ZoomUser_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<ZoomUser_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "zoomUser" */
export type ZoomUser_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<ZoomUser_Max_Order_By>;
  min?: Maybe<ZoomUser_Min_Order_By>;
};

/** input type for inserting array relation for remote table "zoomUser" */
export type ZoomUser_Arr_Rel_Insert_Input = {
  data: Array<ZoomUser_Insert_Input>;
  on_conflict?: Maybe<ZoomUser_On_Conflict>;
};

/** Boolean expression to filter rows from the table "zoomUser". All fields are combined with a logical 'AND'. */
export type ZoomUser_Bool_Exp = {
  _and?: Maybe<Array<Maybe<ZoomUser_Bool_Exp>>>;
  _not?: Maybe<ZoomUser_Bool_Exp>;
  _or?: Maybe<Array<Maybe<ZoomUser_Bool_Exp>>>;
  calendarEnrichmentStatus?: Maybe<String_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  firebaseUid?: Maybe<String_Comparison_Exp>;
  firstName?: Maybe<String_Comparison_Exp>;
  googleAccessToken?: Maybe<String_Comparison_Exp>;
  googleRefreshToken?: Maybe<String_Comparison_Exp>;
  googleSyncToken?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  integrationType?: Maybe<String_Comparison_Exp>;
  lastName?: Maybe<String_Comparison_Exp>;
  nylasAccessToken?: Maybe<String_Comparison_Exp>;
  nylasAccountId?: Maybe<String_Comparison_Exp>;
  nylasLinkedAt?: Maybe<Timestamptz_Comparison_Exp>;
  nylasSyncState?: Maybe<String_Comparison_Exp>;
  onboardVersion?: Maybe<String_Comparison_Exp>;
  role?: Maybe<String_Comparison_Exp>;
  syncGoogleCalendar?: Maybe<Boolean_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
  zoomExternalAuthUserId?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "zoomUser" */
export enum ZoomUser_Constraint {
  /** unique or primary key constraint */
  ZoomUserEmailKey = 'zoomUser_email_key',
  /** unique or primary key constraint */
  ZoomUserFirebaseUidKey = 'zoomUser_firebaseUid_key',
  /** unique or primary key constraint */
  ZoomUserPkey = 'zoomUser_pkey',
}

/** input type for inserting data into table "zoomUser" */
export type ZoomUser_Insert_Input = {
  calendarEnrichmentStatus?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  firebaseUid?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  googleAccessToken?: Maybe<Scalars['String']>;
  googleRefreshToken?: Maybe<Scalars['String']>;
  googleSyncToken?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  integrationType?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  nylasAccessToken?: Maybe<Scalars['String']>;
  nylasAccountId?: Maybe<Scalars['String']>;
  nylasLinkedAt?: Maybe<Scalars['timestamptz']>;
  nylasSyncState?: Maybe<Scalars['String']>;
  onboardVersion?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  syncGoogleCalendar?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  zoomExternalAuthUserId?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type ZoomUser_Max_Fields = {
  __typename?: 'zoomUser_max_fields';
  calendarEnrichmentStatus?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  firebaseUid?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  googleAccessToken?: Maybe<Scalars['String']>;
  googleRefreshToken?: Maybe<Scalars['String']>;
  googleSyncToken?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  integrationType?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  nylasAccessToken?: Maybe<Scalars['String']>;
  nylasAccountId?: Maybe<Scalars['String']>;
  nylasLinkedAt?: Maybe<Scalars['timestamptz']>;
  nylasSyncState?: Maybe<Scalars['String']>;
  onboardVersion?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  zoomExternalAuthUserId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "zoomUser" */
export type ZoomUser_Max_Order_By = {
  calendarEnrichmentStatus?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  firebaseUid?: Maybe<Order_By>;
  firstName?: Maybe<Order_By>;
  googleAccessToken?: Maybe<Order_By>;
  googleRefreshToken?: Maybe<Order_By>;
  googleSyncToken?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  integrationType?: Maybe<Order_By>;
  lastName?: Maybe<Order_By>;
  nylasAccessToken?: Maybe<Order_By>;
  nylasAccountId?: Maybe<Order_By>;
  nylasLinkedAt?: Maybe<Order_By>;
  nylasSyncState?: Maybe<Order_By>;
  onboardVersion?: Maybe<Order_By>;
  role?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  zoomExternalAuthUserId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type ZoomUser_Min_Fields = {
  __typename?: 'zoomUser_min_fields';
  calendarEnrichmentStatus?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  firebaseUid?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  googleAccessToken?: Maybe<Scalars['String']>;
  googleRefreshToken?: Maybe<Scalars['String']>;
  googleSyncToken?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  integrationType?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  nylasAccessToken?: Maybe<Scalars['String']>;
  nylasAccountId?: Maybe<Scalars['String']>;
  nylasLinkedAt?: Maybe<Scalars['timestamptz']>;
  nylasSyncState?: Maybe<Scalars['String']>;
  onboardVersion?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  zoomExternalAuthUserId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "zoomUser" */
export type ZoomUser_Min_Order_By = {
  calendarEnrichmentStatus?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  firebaseUid?: Maybe<Order_By>;
  firstName?: Maybe<Order_By>;
  googleAccessToken?: Maybe<Order_By>;
  googleRefreshToken?: Maybe<Order_By>;
  googleSyncToken?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  integrationType?: Maybe<Order_By>;
  lastName?: Maybe<Order_By>;
  nylasAccessToken?: Maybe<Order_By>;
  nylasAccountId?: Maybe<Order_By>;
  nylasLinkedAt?: Maybe<Order_By>;
  nylasSyncState?: Maybe<Order_By>;
  onboardVersion?: Maybe<Order_By>;
  role?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  zoomExternalAuthUserId?: Maybe<Order_By>;
};

/** response of any mutation on the table "zoomUser" */
export type ZoomUser_Mutation_Response = {
  __typename?: 'zoomUser_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<ZoomUser>;
};

/** input type for inserting object relation for remote table "zoomUser" */
export type ZoomUser_Obj_Rel_Insert_Input = {
  data: ZoomUser_Insert_Input;
  on_conflict?: Maybe<ZoomUser_On_Conflict>;
};

/** on conflict condition type for table "zoomUser" */
export type ZoomUser_On_Conflict = {
  constraint: ZoomUser_Constraint;
  update_columns: Array<ZoomUser_Update_Column>;
  where?: Maybe<ZoomUser_Bool_Exp>;
};

/** ordering options when selecting data from "zoomUser" */
export type ZoomUser_Order_By = {
  calendarEnrichmentStatus?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  firebaseUid?: Maybe<Order_By>;
  firstName?: Maybe<Order_By>;
  googleAccessToken?: Maybe<Order_By>;
  googleRefreshToken?: Maybe<Order_By>;
  googleSyncToken?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  integrationType?: Maybe<Order_By>;
  lastName?: Maybe<Order_By>;
  nylasAccessToken?: Maybe<Order_By>;
  nylasAccountId?: Maybe<Order_By>;
  nylasLinkedAt?: Maybe<Order_By>;
  nylasSyncState?: Maybe<Order_By>;
  onboardVersion?: Maybe<Order_By>;
  role?: Maybe<Order_By>;
  syncGoogleCalendar?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
  zoomExternalAuthUserId?: Maybe<Order_By>;
};

/** primary key columns input for table: "zoomUser" */
export type ZoomUser_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "zoomUser" */
export enum ZoomUser_Select_Column {
  /** column name */
  CalendarEnrichmentStatus = 'calendarEnrichmentStatus',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  FirebaseUid = 'firebaseUid',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  GoogleAccessToken = 'googleAccessToken',
  /** column name */
  GoogleRefreshToken = 'googleRefreshToken',
  /** column name */
  GoogleSyncToken = 'googleSyncToken',
  /** column name */
  Id = 'id',
  /** column name */
  IntegrationType = 'integrationType',
  /** column name */
  LastName = 'lastName',
  /** column name */
  NylasAccessToken = 'nylasAccessToken',
  /** column name */
  NylasAccountId = 'nylasAccountId',
  /** column name */
  NylasLinkedAt = 'nylasLinkedAt',
  /** column name */
  NylasSyncState = 'nylasSyncState',
  /** column name */
  OnboardVersion = 'onboardVersion',
  /** column name */
  Role = 'role',
  /** column name */
  SyncGoogleCalendar = 'syncGoogleCalendar',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  ZoomExternalAuthUserId = 'zoomExternalAuthUserId',
}

/** input type for updating data in table "zoomUser" */
export type ZoomUser_Set_Input = {
  calendarEnrichmentStatus?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  firebaseUid?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  googleAccessToken?: Maybe<Scalars['String']>;
  googleRefreshToken?: Maybe<Scalars['String']>;
  googleSyncToken?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  integrationType?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  nylasAccessToken?: Maybe<Scalars['String']>;
  nylasAccountId?: Maybe<Scalars['String']>;
  nylasLinkedAt?: Maybe<Scalars['timestamptz']>;
  nylasSyncState?: Maybe<Scalars['String']>;
  onboardVersion?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  syncGoogleCalendar?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  zoomExternalAuthUserId?: Maybe<Scalars['uuid']>;
};

/** update columns of table "zoomUser" */
export enum ZoomUser_Update_Column {
  /** column name */
  CalendarEnrichmentStatus = 'calendarEnrichmentStatus',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  FirebaseUid = 'firebaseUid',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  GoogleAccessToken = 'googleAccessToken',
  /** column name */
  GoogleRefreshToken = 'googleRefreshToken',
  /** column name */
  GoogleSyncToken = 'googleSyncToken',
  /** column name */
  Id = 'id',
  /** column name */
  IntegrationType = 'integrationType',
  /** column name */
  LastName = 'lastName',
  /** column name */
  NylasAccessToken = 'nylasAccessToken',
  /** column name */
  NylasAccountId = 'nylasAccountId',
  /** column name */
  NylasLinkedAt = 'nylasLinkedAt',
  /** column name */
  NylasSyncState = 'nylasSyncState',
  /** column name */
  OnboardVersion = 'onboardVersion',
  /** column name */
  Role = 'role',
  /** column name */
  SyncGoogleCalendar = 'syncGoogleCalendar',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  ZoomExternalAuthUserId = 'zoomExternalAuthUserId',
}

export type ClientContactFilterFragment = { __typename?: 'clientContactFilter' } & Pick<
  ClientContactFilter,
  | 'id'
  | 'clientId'
  | 'fieldTable'
  | 'fieldName'
  | 'fieldOperator'
  | 'fieldValue'
  | 'fieldCrmType'
  | 'lastFilteredClientContactCount'
  | 'lastMonitoredClientContactLimit'
  | 'lastMonitoredAt'
  | 'createdAt'
>;

export type ClientContactJobChangeFragment = { __typename?: 'clientContactJobChange' } & Pick<
  ClientContactJobChange,
  'id' | 'clientAction' | 'clientActionUpdatedAt' | 'status' | 'ownerUserId' | 'salesforceAccountId' | 'createdAt'
> & {
    job: { __typename?: 'job' } & Pick<
      Job,
      | 'id'
      | 'companyName'
      | 'title'
      | 'startDate'
      | 'endDate'
      | 'startDateText'
      | 'endDateText'
      | 'duration'
      | 'companyId'
    >;
    ownerUser?: Maybe<{ __typename?: 'user' } & Pick<User, 'firstName' | 'lastName' | 'email'>>;
  };

export type ClientContactExternalFragment = { __typename?: 'clientContact' } & Pick<
  ClientContact,
  | 'id'
  | 'firstName'
  | 'lastName'
  | 'fullName'
  | 'clientId'
  | 'crmEmail'
  | 'crmEmailIsValid'
  | 'crmEmailValidatedAt'
  | 'linkedinUrl'
  | 'linkedinId'
  | 'crmLinkedinUrl'
  | 'crmCompanyName'
  | 'crmTitle'
  | 'hubspotId'
  | 'salesforceId'
  | 'ownerUserId'
  | 'updatedAt'
  | 'createdAt'
> & {
    contactData?: Maybe<
      { __typename?: 'contactData' } & Pick<
        ContactData,
        'id' | 'linkedinId' | 'linkedinUrl' | 'profilePhotoUrl' | 'updatedAt'
      > & {
          currentJob?: Maybe<
            { __typename?: 'job' } & Pick<
              Job,
              | 'id'
              | 'companyName'
              | 'title'
              | 'startDate'
              | 'endDate'
              | 'startDateText'
              | 'endDateText'
              | 'duration'
              | 'companyId'
            > & {
                company?: Maybe<
                  { __typename?: 'company' } & Pick<
                    Company,
                    | 'id'
                    | 'employeeCount'
                    | 'estimatedSize'
                    | 'hqLocation'
                    | 'websiteDomain'
                    | 'websiteRootDomain'
                    | 'websiteUrl'
                  >
                >;
              }
          >;
          jobs: Array<
            { __typename?: 'job' } & Pick<
              Job,
              | 'id'
              | 'companyName'
              | 'title'
              | 'createdAt'
              | 'startDate'
              | 'endDate'
              | 'startDateText'
              | 'endDateText'
              | 'duration'
              | 'companyId'
            >
          >;
          contactEmails: Array<
            { __typename?: 'contactEmail' } & Pick<ContactEmail, 'id' | 'email' | 'isValid' | 'createdAt'>
          >;
        }
    >;
    currentJobChange?: Maybe<{ __typename?: 'clientContactJobChange' } & ClientContactJobChangeFragment>;
    enrichmentHistories: Array<
      { __typename?: 'crmContactEnrichmentHistory' } & Pick<
        CrmContactEnrichmentHistory,
        | 'clientContactId'
        | 'clientId'
        | 'enrichmentType'
        | 'crmType'
        | 'crmField'
        | 'previousValue'
        | 'updatedValue'
        | 'createdAt'
      >
    >;
    ownerUser?: Maybe<{ __typename?: 'user' } & Pick<User, 'firstName' | 'lastName' | 'email'>>;
  };

export type ClientContactQaFragment = { __typename?: 'clientContact' } & Pick<
  ClientContact,
  | 'id'
  | 'firstName'
  | 'lastName'
  | 'fullName'
  | 'linkedinUrl'
  | 'linkedinId'
  | 'crmLinkedinUrl'
  | 'crmCompanyName'
  | 'crmTitle'
  | 'createdAt'
  | 'serialId'
  | 'qaAssignedAt'
> & {
    crmData: Array<
      { __typename?: 'clientContactCrmData' } & Pick<
        ClientContactCrmData,
        'id' | 'fieldName' | 'fieldLabel' | 'fieldValue' | 'crmType'
      >
    >;
  };

export type CrmContactEnrichmentHistoryFragment = { __typename?: 'crmContactEnrichmentHistory' } & Pick<
  CrmContactEnrichmentHistory,
  | 'id'
  | 'clientContactId'
  | 'clientId'
  | 'enrichmentType'
  | 'crmType'
  | 'crmField'
  | 'previousValue'
  | 'updatedValue'
  | 'createdAt'
>;

export type ClientContactCrmDataFragment = { __typename?: 'clientContactCrmData' } & Pick<
  ClientContactCrmData,
  'id' | 'clientContactId' | 'clientId' | 'crmType' | 'fieldName' | 'fieldLabel' | 'fieldValue'
>;

export type ClientExternalFragment = { __typename?: 'client' } & Pick<
  Client,
  'id' | 'name' | 'integrationType' | 'syncStatus' | 'ownerMappingStatus' | 'subscriptionStatus' | 'createdAt'
> & {
    salesforceIntegration?: Maybe<
      { __typename?: 'salesforceIntegration' } & Pick<
        SalesforceIntegration,
        | 'id'
        | 'mapping'
        | 'totalContactCount'
        | 'contactsLastSyncedAt'
        | 'contactsLastSyncedCount'
        | 'accountsLastSyncedAt'
        | 'opportunitiesLastSyncedAt'
        | 'warmlyContactFieldsCreatedAt'
      >
    >;
    hubspotIntegration?: Maybe<
      { __typename?: 'hubspotIntegration' } & Pick<
        HubspotIntegration,
        | 'id'
        | 'mapping'
        | 'totalContactCount'
        | 'contactsLastSyncedAt'
        | 'contactsLastSyncedCount'
        | 'warmlyContactPropertiesCreatedAt'
      >
    >;
    lastCsvUpload?: Maybe<
      { __typename?: 'csvUpload' } & Pick<
        CsvUpload,
        'id' | 'syncCompletedAt' | 'fileName' | 'contactCount' | 'uploadedAt'
      >
    >;
  };

export type ClientQaFragment = { __typename?: 'client' } & Pick<
  Client,
  'id' | 'codeName' | 'createdAt' | 'qaStatus' | 'qaStatusUpdatedAt'
>;

export type ClientInternalFragment = { __typename?: 'client' } & Pick<
  Client,
  'id' | 'name' | 'codeName' | 'integrationType' | 'createdAt' | 'qaStatus' | 'qaStatusUpdatedAt'
> & {
    clientEmailReports: Array<
      { __typename?: 'clientEmailReport' } & Pick<ClientEmailReport, 'emailCategory' | 'sentAt'>
    >;
  };

export type ClientSelectFragment = { __typename?: 'client' } & Pick<Client, 'id' | 'name' | 'codeName'>;

export type ClientNotificationEmailFragment = { __typename?: 'clientNotificationEmail' } & Pick<
  ClientNotificationEmail,
  'id' | 'email' | 'clientId' | 'createdAt'
>;

export type CloudTaskFragment = { __typename?: 'cloudTask' } & Pick<
  CloudTask,
  'id' | 'queueName' | 'description' | 'status' | 'createdAt' | 'updatedAt' | 'executionTime' | 'taskIdentifier'
> & { createdByUser?: Maybe<{ __typename?: 'user' } & Pick<User, 'firstName' | 'lastName' | 'email'>> };

export type QueryClientContactCountQueryVariables = Exact<{
  where: ClientContact_Bool_Exp;
}>;

export type QueryClientContactCountQuery = { __typename?: 'query_root' } & {
  clientContact_aggregate: { __typename?: 'clientContact_aggregate' } & {
    aggregate?: Maybe<
      { __typename?: 'clientContact_aggregate_fields' } & { totalCount: ClientContact_Aggregate_Fields['count'] }
    >;
  };
};

export type QueryClientsClientContactCountQueryVariables = Exact<{
  whereClient?: Maybe<Client_Bool_Exp>;
  whereClientContact?: Maybe<ClientContact_Bool_Exp>;
}>;

export type QueryClientsClientContactCountQuery = { __typename?: 'query_root' } & {
  client: Array<
    { __typename?: 'client' } & Pick<Client, 'id' | 'name'> & {
        allClientContacts: { __typename?: 'clientContact_aggregate' } & {
          aggregate?: Maybe<
            { __typename?: 'clientContact_aggregate_fields' } & { totalCount: ClientContact_Aggregate_Fields['count'] }
          >;
        };
        clientContacts: { __typename?: 'clientContact_aggregate' } & {
          aggregate?: Maybe<
            { __typename?: 'clientContact_aggregate_fields' } & { totalCount: ClientContact_Aggregate_Fields['count'] }
          >;
        };
      }
  >;
};

export type QueryWarmCompanyQueryVariables = Exact<{
  where: WarmCompany_Bool_Exp;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<WarmCompany_Order_By>>;
}>;

export type QueryWarmCompanyQuery = { __typename?: 'query_root' } & {
  warmCompany: Array<
    { __typename?: 'warmCompany' } & Pick<
      WarmCompany,
      | 'clientContactCount'
      | 'clientId'
      | 'clientName'
      | 'companyId'
      | 'companyLinkedinUrl'
      | 'companyPrimaryName'
      | 'companyLogoUrl'
    >
  >;
};

export type QueryWarmCompanyAggQueryVariables = Exact<{
  where: WarmCompany_Bool_Exp;
}>;

export type QueryWarmCompanyAggQuery = { __typename?: 'query_root' } & {
  warmCompany_aggregate: { __typename?: 'warmCompany_aggregate' } & {
    aggregate?: Maybe<
      { __typename?: 'warmCompany_aggregate_fields' } & { totalCount: WarmCompany_Aggregate_Fields['count'] }
    >;
  };
};

export type ClientEmailReportFragment = { __typename?: 'clientEmailReport' } & Pick<
  ClientEmailReport,
  'id' | 'clientId' | 'sentAt' | 'json' | 'emailCategory' | 'emailHistoryId'
>;

export type EmailHistoryFragment = { __typename?: 'emailHistory' } & Pick<
  EmailHistory,
  | 'id'
  | 'firestoreId'
  | 'emailService'
  | 'emailServiceMessageId'
  | 'category'
  | 'status'
  | 'statusUpdatedAt'
  | 'description'
  | 'email'
  | 'clientId'
  | 'createdAt'
>;

export type SendgridEventFragment = { __typename?: 'sendgridEvent' } & Pick<
  SendgridEvent,
  | 'id'
  | 'clientId'
  | 'email'
  | 'emailHistoryId'
  | 'event'
  | 'eventJson'
  | 'sgMessageId'
  | 'sgEventId'
  | 'sgTemplateId'
  | 'sgTemplateName'
  | 'createdAt'
>;

export type QueryClientContactFilterByIdQueryVariables = Exact<{
  clientContactFilterId: Scalars['uuid'];
}>;

export type QueryClientContactFilterByIdQuery = { __typename?: 'query_root' } & {
  clientContactFilter_by_pk?: Maybe<{ __typename?: 'clientContactFilter' } & ClientContactFilterFragment>;
};

export type QueryClientContactFilterObjectsQueryVariables = Exact<{
  distinct_on?: Maybe<Array<ClientContactFilter_Select_Column>>;
  where?: Maybe<ClientContactFilter_Bool_Exp>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ClientContactFilter_Order_By>>;
}>;

export type QueryClientContactFilterObjectsQuery = { __typename?: 'query_root' } & {
  clientContactFilter: Array<{ __typename?: 'clientContactFilter' } & ClientContactFilterFragment>;
};

export type SubscribeToClientContactFilterByIdSubscriptionVariables = Exact<{
  clientContactFilterId: Scalars['uuid'];
}>;

export type SubscribeToClientContactFilterByIdSubscription = { __typename?: 'subscription_root' } & {
  clientContactFilter_by_pk?: Maybe<{ __typename?: 'clientContactFilter' } & ClientContactFilterFragment>;
};

export type SubscribeToClientContactFilterObjectsSubscriptionVariables = Exact<{
  distinct_on?: Maybe<Array<ClientContactFilter_Select_Column>>;
  where?: Maybe<ClientContactFilter_Bool_Exp>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ClientContactFilter_Order_By>>;
}>;

export type SubscribeToClientContactFilterObjectsSubscription = { __typename?: 'subscription_root' } & {
  clientContactFilter: Array<{ __typename?: 'clientContactFilter' } & ClientContactFilterFragment>;
};

export type InsertClientContactFilterMutationVariables = Exact<{
  objects: Array<ClientContactFilter_Insert_Input>;
}>;

export type InsertClientContactFilterMutation = { __typename?: 'mutation_root' } & {
  insert_clientContactFilter?: Maybe<
    { __typename?: 'clientContactFilter_mutation_response' } & Pick<
      ClientContactFilter_Mutation_Response,
      'affected_rows'
    > & { returning: Array<{ __typename?: 'clientContactFilter' } & ClientContactFilterFragment> }
  >;
};

export type InsertClientContactFilterWithOnConflictMutationVariables = Exact<{
  objects: Array<ClientContactFilter_Insert_Input>;
  onConflict?: Maybe<ClientContactFilter_On_Conflict>;
}>;

export type InsertClientContactFilterWithOnConflictMutation = { __typename?: 'mutation_root' } & {
  insert_clientContactFilter?: Maybe<
    { __typename?: 'clientContactFilter_mutation_response' } & Pick<
      ClientContactFilter_Mutation_Response,
      'affected_rows'
    > & { returning: Array<{ __typename?: 'clientContactFilter' } & ClientContactFilterFragment> }
  >;
};

export type UpdateClientContactFilterByIdMutationVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
  set?: Maybe<ClientContactFilter_Set_Input>;
}>;

export type UpdateClientContactFilterByIdMutation = { __typename?: 'mutation_root' } & {
  update_clientContactFilter?: Maybe<
    { __typename?: 'clientContactFilter_mutation_response' } & Pick<
      ClientContactFilter_Mutation_Response,
      'affected_rows'
    > & { returning: Array<{ __typename?: 'clientContactFilter' } & ClientContactFilterFragment> }
  >;
};

export type UpdateClientContactFilterMutationVariables = Exact<{
  set?: Maybe<ClientContactFilter_Set_Input>;
  where: ClientContactFilter_Bool_Exp;
}>;

export type UpdateClientContactFilterMutation = { __typename?: 'mutation_root' } & {
  update_clientContactFilter?: Maybe<
    { __typename?: 'clientContactFilter_mutation_response' } & Pick<
      ClientContactFilter_Mutation_Response,
      'affected_rows'
    > & { returning: Array<{ __typename?: 'clientContactFilter' } & ClientContactFilterFragment> }
  >;
};

export type RemoveClientContactFilterModelByIdMutationVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
}>;

export type RemoveClientContactFilterModelByIdMutation = { __typename?: 'mutation_root' } & {
  delete_clientContactFilter?: Maybe<
    { __typename?: 'clientContactFilter_mutation_response' } & Pick<
      ClientContactFilter_Mutation_Response,
      'affected_rows'
    >
  >;
};

export type RemoveClientContactFilterModelMutationVariables = Exact<{
  where: ClientContactFilter_Bool_Exp;
}>;

export type RemoveClientContactFilterModelMutation = { __typename?: 'mutation_root' } & {
  delete_clientContactFilter?: Maybe<
    { __typename?: 'clientContactFilter_mutation_response' } & Pick<
      ClientContactFilter_Mutation_Response,
      'affected_rows'
    >
  >;
};

export type QueryClientContactJobChangeByIdQueryVariables = Exact<{
  clientContactJobChangeId: Scalars['uuid'];
}>;

export type QueryClientContactJobChangeByIdQuery = { __typename?: 'query_root' } & {
  clientContactJobChange_by_pk?: Maybe<{ __typename?: 'clientContactJobChange' } & ClientContactJobChangeFragment>;
};

export type QueryClientContactJobChangeObjectsQueryVariables = Exact<{
  distinct_on?: Maybe<Array<ClientContactJobChange_Select_Column>>;
  where?: Maybe<ClientContactJobChange_Bool_Exp>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ClientContactJobChange_Order_By>>;
}>;

export type QueryClientContactJobChangeObjectsQuery = { __typename?: 'query_root' } & {
  clientContactJobChange: Array<{ __typename?: 'clientContactJobChange' } & ClientContactJobChangeFragment>;
};

export type SubscribeToClientContactJobChangeByIdSubscriptionVariables = Exact<{
  clientContactJobChangeId: Scalars['uuid'];
}>;

export type SubscribeToClientContactJobChangeByIdSubscription = { __typename?: 'subscription_root' } & {
  clientContactJobChange_by_pk?: Maybe<{ __typename?: 'clientContactJobChange' } & ClientContactJobChangeFragment>;
};

export type SubscribeToClientContactJobChangeObjectsSubscriptionVariables = Exact<{
  distinct_on?: Maybe<Array<ClientContactJobChange_Select_Column>>;
  where?: Maybe<ClientContactJobChange_Bool_Exp>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ClientContactJobChange_Order_By>>;
}>;

export type SubscribeToClientContactJobChangeObjectsSubscription = { __typename?: 'subscription_root' } & {
  clientContactJobChange: Array<{ __typename?: 'clientContactJobChange' } & ClientContactJobChangeFragment>;
};

export type InsertClientContactJobChangeMutationVariables = Exact<{
  objects: Array<ClientContactJobChange_Insert_Input>;
}>;

export type InsertClientContactJobChangeMutation = { __typename?: 'mutation_root' } & {
  insert_clientContactJobChange?: Maybe<
    { __typename?: 'clientContactJobChange_mutation_response' } & Pick<
      ClientContactJobChange_Mutation_Response,
      'affected_rows'
    > & { returning: Array<{ __typename?: 'clientContactJobChange' } & ClientContactJobChangeFragment> }
  >;
};

export type InsertClientContactJobChangeWithOnConflictMutationVariables = Exact<{
  objects: Array<ClientContactJobChange_Insert_Input>;
  onConflict?: Maybe<ClientContactJobChange_On_Conflict>;
}>;

export type InsertClientContactJobChangeWithOnConflictMutation = { __typename?: 'mutation_root' } & {
  insert_clientContactJobChange?: Maybe<
    { __typename?: 'clientContactJobChange_mutation_response' } & Pick<
      ClientContactJobChange_Mutation_Response,
      'affected_rows'
    > & { returning: Array<{ __typename?: 'clientContactJobChange' } & ClientContactJobChangeFragment> }
  >;
};

export type UpdateClientContactJobChangeByIdMutationVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
  set?: Maybe<ClientContactJobChange_Set_Input>;
}>;

export type UpdateClientContactJobChangeByIdMutation = { __typename?: 'mutation_root' } & {
  update_clientContactJobChange?: Maybe<
    { __typename?: 'clientContactJobChange_mutation_response' } & Pick<
      ClientContactJobChange_Mutation_Response,
      'affected_rows'
    > & { returning: Array<{ __typename?: 'clientContactJobChange' } & ClientContactJobChangeFragment> }
  >;
};

export type UpdateClientContactJobChangeMutationVariables = Exact<{
  set?: Maybe<ClientContactJobChange_Set_Input>;
  where: ClientContactJobChange_Bool_Exp;
}>;

export type UpdateClientContactJobChangeMutation = { __typename?: 'mutation_root' } & {
  update_clientContactJobChange?: Maybe<
    { __typename?: 'clientContactJobChange_mutation_response' } & Pick<
      ClientContactJobChange_Mutation_Response,
      'affected_rows'
    > & { returning: Array<{ __typename?: 'clientContactJobChange' } & ClientContactJobChangeFragment> }
  >;
};

export type RemoveClientContactJobChangeModelByIdMutationVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
}>;

export type RemoveClientContactJobChangeModelByIdMutation = { __typename?: 'mutation_root' } & {
  delete_clientContactJobChange?: Maybe<
    { __typename?: 'clientContactJobChange_mutation_response' } & Pick<
      ClientContactJobChange_Mutation_Response,
      'affected_rows'
    >
  >;
};

export type RemoveClientContactJobChangeModelMutationVariables = Exact<{
  where: ClientContactJobChange_Bool_Exp;
}>;

export type RemoveClientContactJobChangeModelMutation = { __typename?: 'mutation_root' } & {
  delete_clientContactJobChange?: Maybe<
    { __typename?: 'clientContactJobChange_mutation_response' } & Pick<
      ClientContactJobChange_Mutation_Response,
      'affected_rows'
    >
  >;
};

export type QueryClientContactExternalByIdQueryVariables = Exact<{
  clientContactId: Scalars['uuid'];
}>;

export type QueryClientContactExternalByIdQuery = { __typename?: 'query_root' } & {
  clientContact_by_pk?: Maybe<{ __typename?: 'clientContact' } & ClientContactExternalFragment>;
};

export type QueryClientContactExternalObjectsQueryVariables = Exact<{
  distinct_on?: Maybe<Array<ClientContact_Select_Column>>;
  where?: Maybe<ClientContact_Bool_Exp>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ClientContact_Order_By>>;
}>;

export type QueryClientContactExternalObjectsQuery = { __typename?: 'query_root' } & {
  clientContact: Array<{ __typename?: 'clientContact' } & ClientContactExternalFragment>;
};

export type SubscribeToClientContactExternalByIdSubscriptionVariables = Exact<{
  clientContactId: Scalars['uuid'];
}>;

export type SubscribeToClientContactExternalByIdSubscription = { __typename?: 'subscription_root' } & {
  clientContact_by_pk?: Maybe<{ __typename?: 'clientContact' } & ClientContactExternalFragment>;
};

export type SubscribeToClientContactExternalObjectsSubscriptionVariables = Exact<{
  distinct_on?: Maybe<Array<ClientContact_Select_Column>>;
  where?: Maybe<ClientContact_Bool_Exp>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ClientContact_Order_By>>;
}>;

export type SubscribeToClientContactExternalObjectsSubscription = { __typename?: 'subscription_root' } & {
  clientContact: Array<{ __typename?: 'clientContact' } & ClientContactExternalFragment>;
};

export type InsertClientContactExternalMutationVariables = Exact<{
  objects: Array<ClientContact_Insert_Input>;
}>;

export type InsertClientContactExternalMutation = { __typename?: 'mutation_root' } & {
  insert_clientContact?: Maybe<
    { __typename?: 'clientContact_mutation_response' } & Pick<ClientContact_Mutation_Response, 'affected_rows'> & {
        returning: Array<{ __typename?: 'clientContact' } & ClientContactExternalFragment>;
      }
  >;
};

export type InsertClientContactExternalWithOnConflictMutationVariables = Exact<{
  objects: Array<ClientContact_Insert_Input>;
  onConflict?: Maybe<ClientContact_On_Conflict>;
}>;

export type InsertClientContactExternalWithOnConflictMutation = { __typename?: 'mutation_root' } & {
  insert_clientContact?: Maybe<
    { __typename?: 'clientContact_mutation_response' } & Pick<ClientContact_Mutation_Response, 'affected_rows'> & {
        returning: Array<{ __typename?: 'clientContact' } & ClientContactExternalFragment>;
      }
  >;
};

export type UpdateClientContactExternalByIdMutationVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
  set?: Maybe<ClientContact_Set_Input>;
}>;

export type UpdateClientContactExternalByIdMutation = { __typename?: 'mutation_root' } & {
  update_clientContact?: Maybe<
    { __typename?: 'clientContact_mutation_response' } & Pick<ClientContact_Mutation_Response, 'affected_rows'> & {
        returning: Array<{ __typename?: 'clientContact' } & ClientContactExternalFragment>;
      }
  >;
};

export type UpdateClientContactExternalMutationVariables = Exact<{
  set?: Maybe<ClientContact_Set_Input>;
  where: ClientContact_Bool_Exp;
}>;

export type UpdateClientContactExternalMutation = { __typename?: 'mutation_root' } & {
  update_clientContact?: Maybe<
    { __typename?: 'clientContact_mutation_response' } & Pick<ClientContact_Mutation_Response, 'affected_rows'> & {
        returning: Array<{ __typename?: 'clientContact' } & ClientContactExternalFragment>;
      }
  >;
};

export type RemoveClientContactModelByIdMutationVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
}>;

export type RemoveClientContactModelByIdMutation = { __typename?: 'mutation_root' } & {
  delete_clientContact?: Maybe<
    { __typename?: 'clientContact_mutation_response' } & Pick<ClientContact_Mutation_Response, 'affected_rows'>
  >;
};

export type RemoveClientContactModelMutationVariables = Exact<{
  where: ClientContact_Bool_Exp;
}>;

export type RemoveClientContactModelMutation = { __typename?: 'mutation_root' } & {
  delete_clientContact?: Maybe<
    { __typename?: 'clientContact_mutation_response' } & Pick<ClientContact_Mutation_Response, 'affected_rows'>
  >;
};

export type QueryClientContactQaByIdQueryVariables = Exact<{
  clientContactId: Scalars['uuid'];
}>;

export type QueryClientContactQaByIdQuery = { __typename?: 'query_root' } & {
  clientContact_by_pk?: Maybe<{ __typename?: 'clientContact' } & ClientContactQaFragment>;
};

export type QueryClientContactQaObjectsQueryVariables = Exact<{
  distinct_on?: Maybe<Array<ClientContact_Select_Column>>;
  where?: Maybe<ClientContact_Bool_Exp>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ClientContact_Order_By>>;
}>;

export type QueryClientContactQaObjectsQuery = { __typename?: 'query_root' } & {
  clientContact: Array<{ __typename?: 'clientContact' } & ClientContactQaFragment>;
};

export type SubscribeToClientContactQaByIdSubscriptionVariables = Exact<{
  clientContactId: Scalars['uuid'];
}>;

export type SubscribeToClientContactQaByIdSubscription = { __typename?: 'subscription_root' } & {
  clientContact_by_pk?: Maybe<{ __typename?: 'clientContact' } & ClientContactQaFragment>;
};

export type SubscribeToClientContactQaObjectsSubscriptionVariables = Exact<{
  distinct_on?: Maybe<Array<ClientContact_Select_Column>>;
  where?: Maybe<ClientContact_Bool_Exp>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ClientContact_Order_By>>;
}>;

export type SubscribeToClientContactQaObjectsSubscription = { __typename?: 'subscription_root' } & {
  clientContact: Array<{ __typename?: 'clientContact' } & ClientContactQaFragment>;
};

export type InsertClientContactQaMutationVariables = Exact<{
  objects: Array<ClientContact_Insert_Input>;
}>;

export type InsertClientContactQaMutation = { __typename?: 'mutation_root' } & {
  insert_clientContact?: Maybe<
    { __typename?: 'clientContact_mutation_response' } & Pick<ClientContact_Mutation_Response, 'affected_rows'> & {
        returning: Array<{ __typename?: 'clientContact' } & ClientContactQaFragment>;
      }
  >;
};

export type InsertClientContactQaWithOnConflictMutationVariables = Exact<{
  objects: Array<ClientContact_Insert_Input>;
  onConflict?: Maybe<ClientContact_On_Conflict>;
}>;

export type InsertClientContactQaWithOnConflictMutation = { __typename?: 'mutation_root' } & {
  insert_clientContact?: Maybe<
    { __typename?: 'clientContact_mutation_response' } & Pick<ClientContact_Mutation_Response, 'affected_rows'> & {
        returning: Array<{ __typename?: 'clientContact' } & ClientContactQaFragment>;
      }
  >;
};

export type UpdateClientContactQaByIdMutationVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
  set?: Maybe<ClientContact_Set_Input>;
}>;

export type UpdateClientContactQaByIdMutation = { __typename?: 'mutation_root' } & {
  update_clientContact?: Maybe<
    { __typename?: 'clientContact_mutation_response' } & Pick<ClientContact_Mutation_Response, 'affected_rows'> & {
        returning: Array<{ __typename?: 'clientContact' } & ClientContactQaFragment>;
      }
  >;
};

export type UpdateClientContactQaMutationVariables = Exact<{
  set?: Maybe<ClientContact_Set_Input>;
  where: ClientContact_Bool_Exp;
}>;

export type UpdateClientContactQaMutation = { __typename?: 'mutation_root' } & {
  update_clientContact?: Maybe<
    { __typename?: 'clientContact_mutation_response' } & Pick<ClientContact_Mutation_Response, 'affected_rows'> & {
        returning: Array<{ __typename?: 'clientContact' } & ClientContactQaFragment>;
      }
  >;
};

export type QueryCrmContactEnrichmentHistoryByIdQueryVariables = Exact<{
  crmContactEnrichmentHistoryId: Scalars['uuid'];
}>;

export type QueryCrmContactEnrichmentHistoryByIdQuery = { __typename?: 'query_root' } & {
  crmContactEnrichmentHistory_by_pk?: Maybe<
    { __typename?: 'crmContactEnrichmentHistory' } & CrmContactEnrichmentHistoryFragment
  >;
};

export type QueryCrmContactEnrichmentHistoryObjectsQueryVariables = Exact<{
  distinct_on?: Maybe<Array<CrmContactEnrichmentHistory_Select_Column>>;
  where?: Maybe<CrmContactEnrichmentHistory_Bool_Exp>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CrmContactEnrichmentHistory_Order_By>>;
}>;

export type QueryCrmContactEnrichmentHistoryObjectsQuery = { __typename?: 'query_root' } & {
  crmContactEnrichmentHistory: Array<
    { __typename?: 'crmContactEnrichmentHistory' } & CrmContactEnrichmentHistoryFragment
  >;
};

export type SubscribeToCrmContactEnrichmentHistoryByIdSubscriptionVariables = Exact<{
  crmContactEnrichmentHistoryId: Scalars['uuid'];
}>;

export type SubscribeToCrmContactEnrichmentHistoryByIdSubscription = { __typename?: 'subscription_root' } & {
  crmContactEnrichmentHistory_by_pk?: Maybe<
    { __typename?: 'crmContactEnrichmentHistory' } & CrmContactEnrichmentHistoryFragment
  >;
};

export type SubscribeToCrmContactEnrichmentHistoryObjectsSubscriptionVariables = Exact<{
  distinct_on?: Maybe<Array<CrmContactEnrichmentHistory_Select_Column>>;
  where?: Maybe<CrmContactEnrichmentHistory_Bool_Exp>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CrmContactEnrichmentHistory_Order_By>>;
}>;

export type SubscribeToCrmContactEnrichmentHistoryObjectsSubscription = { __typename?: 'subscription_root' } & {
  crmContactEnrichmentHistory: Array<
    { __typename?: 'crmContactEnrichmentHistory' } & CrmContactEnrichmentHistoryFragment
  >;
};

export type InsertCrmContactEnrichmentHistoryMutationVariables = Exact<{
  objects: Array<CrmContactEnrichmentHistory_Insert_Input>;
}>;

export type InsertCrmContactEnrichmentHistoryMutation = { __typename?: 'mutation_root' } & {
  insert_crmContactEnrichmentHistory?: Maybe<
    { __typename?: 'crmContactEnrichmentHistory_mutation_response' } & Pick<
      CrmContactEnrichmentHistory_Mutation_Response,
      'affected_rows'
    > & { returning: Array<{ __typename?: 'crmContactEnrichmentHistory' } & CrmContactEnrichmentHistoryFragment> }
  >;
};

export type InsertCrmContactEnrichmentHistoryWithOnConflictMutationVariables = Exact<{
  objects: Array<CrmContactEnrichmentHistory_Insert_Input>;
  onConflict?: Maybe<CrmContactEnrichmentHistory_On_Conflict>;
}>;

export type InsertCrmContactEnrichmentHistoryWithOnConflictMutation = { __typename?: 'mutation_root' } & {
  insert_crmContactEnrichmentHistory?: Maybe<
    { __typename?: 'crmContactEnrichmentHistory_mutation_response' } & Pick<
      CrmContactEnrichmentHistory_Mutation_Response,
      'affected_rows'
    > & { returning: Array<{ __typename?: 'crmContactEnrichmentHistory' } & CrmContactEnrichmentHistoryFragment> }
  >;
};

export type UpdateCrmContactEnrichmentHistoryByIdMutationVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
  set?: Maybe<CrmContactEnrichmentHistory_Set_Input>;
}>;

export type UpdateCrmContactEnrichmentHistoryByIdMutation = { __typename?: 'mutation_root' } & {
  update_crmContactEnrichmentHistory?: Maybe<
    { __typename?: 'crmContactEnrichmentHistory_mutation_response' } & Pick<
      CrmContactEnrichmentHistory_Mutation_Response,
      'affected_rows'
    > & { returning: Array<{ __typename?: 'crmContactEnrichmentHistory' } & CrmContactEnrichmentHistoryFragment> }
  >;
};

export type UpdateCrmContactEnrichmentHistoryMutationVariables = Exact<{
  set?: Maybe<CrmContactEnrichmentHistory_Set_Input>;
  where: CrmContactEnrichmentHistory_Bool_Exp;
}>;

export type UpdateCrmContactEnrichmentHistoryMutation = { __typename?: 'mutation_root' } & {
  update_crmContactEnrichmentHistory?: Maybe<
    { __typename?: 'crmContactEnrichmentHistory_mutation_response' } & Pick<
      CrmContactEnrichmentHistory_Mutation_Response,
      'affected_rows'
    > & { returning: Array<{ __typename?: 'crmContactEnrichmentHistory' } & CrmContactEnrichmentHistoryFragment> }
  >;
};

export type RemoveCrmContactEnrichmentHistoryModelByIdMutationVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
}>;

export type RemoveCrmContactEnrichmentHistoryModelByIdMutation = { __typename?: 'mutation_root' } & {
  delete_crmContactEnrichmentHistory?: Maybe<
    { __typename?: 'crmContactEnrichmentHistory_mutation_response' } & Pick<
      CrmContactEnrichmentHistory_Mutation_Response,
      'affected_rows'
    >
  >;
};

export type RemoveCrmContactEnrichmentHistoryModelMutationVariables = Exact<{
  where: CrmContactEnrichmentHistory_Bool_Exp;
}>;

export type RemoveCrmContactEnrichmentHistoryModelMutation = { __typename?: 'mutation_root' } & {
  delete_crmContactEnrichmentHistory?: Maybe<
    { __typename?: 'crmContactEnrichmentHistory_mutation_response' } & Pick<
      CrmContactEnrichmentHistory_Mutation_Response,
      'affected_rows'
    >
  >;
};

export type QueryClientContactCrmDataByIdQueryVariables = Exact<{
  clientContactCrmDataId: Scalars['uuid'];
}>;

export type QueryClientContactCrmDataByIdQuery = { __typename?: 'query_root' } & {
  clientContactCrmData_by_pk?: Maybe<{ __typename?: 'clientContactCrmData' } & ClientContactCrmDataFragment>;
};

export type QueryClientContactCrmDataObjectsQueryVariables = Exact<{
  distinct_on?: Maybe<Array<ClientContactCrmData_Select_Column>>;
  where?: Maybe<ClientContactCrmData_Bool_Exp>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ClientContactCrmData_Order_By>>;
}>;

export type QueryClientContactCrmDataObjectsQuery = { __typename?: 'query_root' } & {
  clientContactCrmData: Array<{ __typename?: 'clientContactCrmData' } & ClientContactCrmDataFragment>;
};

export type SubscribeToClientContactCrmDataByIdSubscriptionVariables = Exact<{
  clientContactCrmDataId: Scalars['uuid'];
}>;

export type SubscribeToClientContactCrmDataByIdSubscription = { __typename?: 'subscription_root' } & {
  clientContactCrmData_by_pk?: Maybe<{ __typename?: 'clientContactCrmData' } & ClientContactCrmDataFragment>;
};

export type SubscribeToClientContactCrmDataObjectsSubscriptionVariables = Exact<{
  distinct_on?: Maybe<Array<ClientContactCrmData_Select_Column>>;
  where?: Maybe<ClientContactCrmData_Bool_Exp>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ClientContactCrmData_Order_By>>;
}>;

export type SubscribeToClientContactCrmDataObjectsSubscription = { __typename?: 'subscription_root' } & {
  clientContactCrmData: Array<{ __typename?: 'clientContactCrmData' } & ClientContactCrmDataFragment>;
};

export type InsertClientContactCrmDataMutationVariables = Exact<{
  objects: Array<ClientContactCrmData_Insert_Input>;
}>;

export type InsertClientContactCrmDataMutation = { __typename?: 'mutation_root' } & {
  insert_clientContactCrmData?: Maybe<
    { __typename?: 'clientContactCrmData_mutation_response' } & Pick<
      ClientContactCrmData_Mutation_Response,
      'affected_rows'
    > & { returning: Array<{ __typename?: 'clientContactCrmData' } & ClientContactCrmDataFragment> }
  >;
};

export type InsertClientContactCrmDataWithOnConflictMutationVariables = Exact<{
  objects: Array<ClientContactCrmData_Insert_Input>;
  onConflict?: Maybe<ClientContactCrmData_On_Conflict>;
}>;

export type InsertClientContactCrmDataWithOnConflictMutation = { __typename?: 'mutation_root' } & {
  insert_clientContactCrmData?: Maybe<
    { __typename?: 'clientContactCrmData_mutation_response' } & Pick<
      ClientContactCrmData_Mutation_Response,
      'affected_rows'
    > & { returning: Array<{ __typename?: 'clientContactCrmData' } & ClientContactCrmDataFragment> }
  >;
};

export type UpdateClientContactCrmDataByIdMutationVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
  set?: Maybe<ClientContactCrmData_Set_Input>;
}>;

export type UpdateClientContactCrmDataByIdMutation = { __typename?: 'mutation_root' } & {
  update_clientContactCrmData?: Maybe<
    { __typename?: 'clientContactCrmData_mutation_response' } & Pick<
      ClientContactCrmData_Mutation_Response,
      'affected_rows'
    > & { returning: Array<{ __typename?: 'clientContactCrmData' } & ClientContactCrmDataFragment> }
  >;
};

export type UpdateClientContactCrmDataMutationVariables = Exact<{
  set?: Maybe<ClientContactCrmData_Set_Input>;
  where: ClientContactCrmData_Bool_Exp;
}>;

export type UpdateClientContactCrmDataMutation = { __typename?: 'mutation_root' } & {
  update_clientContactCrmData?: Maybe<
    { __typename?: 'clientContactCrmData_mutation_response' } & Pick<
      ClientContactCrmData_Mutation_Response,
      'affected_rows'
    > & { returning: Array<{ __typename?: 'clientContactCrmData' } & ClientContactCrmDataFragment> }
  >;
};

export type RemoveClientContactCrmDataModelByIdMutationVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
}>;

export type RemoveClientContactCrmDataModelByIdMutation = { __typename?: 'mutation_root' } & {
  delete_clientContactCrmData?: Maybe<
    { __typename?: 'clientContactCrmData_mutation_response' } & Pick<
      ClientContactCrmData_Mutation_Response,
      'affected_rows'
    >
  >;
};

export type RemoveClientContactCrmDataModelMutationVariables = Exact<{
  where: ClientContactCrmData_Bool_Exp;
}>;

export type RemoveClientContactCrmDataModelMutation = { __typename?: 'mutation_root' } & {
  delete_clientContactCrmData?: Maybe<
    { __typename?: 'clientContactCrmData_mutation_response' } & Pick<
      ClientContactCrmData_Mutation_Response,
      'affected_rows'
    >
  >;
};

export type QueryClientExternalByIdQueryVariables = Exact<{
  clientId: Scalars['uuid'];
}>;

export type QueryClientExternalByIdQuery = { __typename?: 'query_root' } & {
  client_by_pk?: Maybe<{ __typename?: 'client' } & ClientExternalFragment>;
};

export type QueryClientExternalObjectsQueryVariables = Exact<{
  distinct_on?: Maybe<Array<Client_Select_Column>>;
  where?: Maybe<Client_Bool_Exp>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Client_Order_By>>;
}>;

export type QueryClientExternalObjectsQuery = { __typename?: 'query_root' } & {
  client: Array<{ __typename?: 'client' } & ClientExternalFragment>;
};

export type SubscribeToClientExternalByIdSubscriptionVariables = Exact<{
  clientId: Scalars['uuid'];
}>;

export type SubscribeToClientExternalByIdSubscription = { __typename?: 'subscription_root' } & {
  client_by_pk?: Maybe<{ __typename?: 'client' } & ClientExternalFragment>;
};

export type SubscribeToClientExternalObjectsSubscriptionVariables = Exact<{
  distinct_on?: Maybe<Array<Client_Select_Column>>;
  where?: Maybe<Client_Bool_Exp>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Client_Order_By>>;
}>;

export type SubscribeToClientExternalObjectsSubscription = { __typename?: 'subscription_root' } & {
  client: Array<{ __typename?: 'client' } & ClientExternalFragment>;
};

export type InsertClientExternalMutationVariables = Exact<{
  objects: Array<Client_Insert_Input>;
}>;

export type InsertClientExternalMutation = { __typename?: 'mutation_root' } & {
  insert_client?: Maybe<
    { __typename?: 'client_mutation_response' } & Pick<Client_Mutation_Response, 'affected_rows'> & {
        returning: Array<{ __typename?: 'client' } & ClientExternalFragment>;
      }
  >;
};

export type InsertClientExternalWithOnConflictMutationVariables = Exact<{
  objects: Array<Client_Insert_Input>;
  onConflict?: Maybe<Client_On_Conflict>;
}>;

export type InsertClientExternalWithOnConflictMutation = { __typename?: 'mutation_root' } & {
  insert_client?: Maybe<
    { __typename?: 'client_mutation_response' } & Pick<Client_Mutation_Response, 'affected_rows'> & {
        returning: Array<{ __typename?: 'client' } & ClientExternalFragment>;
      }
  >;
};

export type UpdateClientExternalByIdMutationVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
  set?: Maybe<Client_Set_Input>;
}>;

export type UpdateClientExternalByIdMutation = { __typename?: 'mutation_root' } & {
  update_client?: Maybe<
    { __typename?: 'client_mutation_response' } & Pick<Client_Mutation_Response, 'affected_rows'> & {
        returning: Array<{ __typename?: 'client' } & ClientExternalFragment>;
      }
  >;
};

export type UpdateClientExternalMutationVariables = Exact<{
  set?: Maybe<Client_Set_Input>;
  where: Client_Bool_Exp;
}>;

export type UpdateClientExternalMutation = { __typename?: 'mutation_root' } & {
  update_client?: Maybe<
    { __typename?: 'client_mutation_response' } & Pick<Client_Mutation_Response, 'affected_rows'> & {
        returning: Array<{ __typename?: 'client' } & ClientExternalFragment>;
      }
  >;
};

export type RemoveClientModelByIdMutationVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
}>;

export type RemoveClientModelByIdMutation = { __typename?: 'mutation_root' } & {
  delete_client?: Maybe<{ __typename?: 'client_mutation_response' } & Pick<Client_Mutation_Response, 'affected_rows'>>;
};

export type RemoveClientModelMutationVariables = Exact<{
  where: Client_Bool_Exp;
}>;

export type RemoveClientModelMutation = { __typename?: 'mutation_root' } & {
  delete_client?: Maybe<{ __typename?: 'client_mutation_response' } & Pick<Client_Mutation_Response, 'affected_rows'>>;
};

export type QueryClientQaByIdQueryVariables = Exact<{
  clientId: Scalars['uuid'];
}>;

export type QueryClientQaByIdQuery = { __typename?: 'query_root' } & {
  client_by_pk?: Maybe<{ __typename?: 'client' } & ClientQaFragment>;
};

export type QueryClientQaObjectsQueryVariables = Exact<{
  distinct_on?: Maybe<Array<Client_Select_Column>>;
  where?: Maybe<Client_Bool_Exp>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Client_Order_By>>;
}>;

export type QueryClientQaObjectsQuery = { __typename?: 'query_root' } & {
  client: Array<{ __typename?: 'client' } & ClientQaFragment>;
};

export type SubscribeToClientQaByIdSubscriptionVariables = Exact<{
  clientId: Scalars['uuid'];
}>;

export type SubscribeToClientQaByIdSubscription = { __typename?: 'subscription_root' } & {
  client_by_pk?: Maybe<{ __typename?: 'client' } & ClientQaFragment>;
};

export type SubscribeToClientQaObjectsSubscriptionVariables = Exact<{
  distinct_on?: Maybe<Array<Client_Select_Column>>;
  where?: Maybe<Client_Bool_Exp>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Client_Order_By>>;
}>;

export type SubscribeToClientQaObjectsSubscription = { __typename?: 'subscription_root' } & {
  client: Array<{ __typename?: 'client' } & ClientQaFragment>;
};

export type InsertClientQaMutationVariables = Exact<{
  objects: Array<Client_Insert_Input>;
}>;

export type InsertClientQaMutation = { __typename?: 'mutation_root' } & {
  insert_client?: Maybe<
    { __typename?: 'client_mutation_response' } & Pick<Client_Mutation_Response, 'affected_rows'> & {
        returning: Array<{ __typename?: 'client' } & ClientQaFragment>;
      }
  >;
};

export type InsertClientQaWithOnConflictMutationVariables = Exact<{
  objects: Array<Client_Insert_Input>;
  onConflict?: Maybe<Client_On_Conflict>;
}>;

export type InsertClientQaWithOnConflictMutation = { __typename?: 'mutation_root' } & {
  insert_client?: Maybe<
    { __typename?: 'client_mutation_response' } & Pick<Client_Mutation_Response, 'affected_rows'> & {
        returning: Array<{ __typename?: 'client' } & ClientQaFragment>;
      }
  >;
};

export type UpdateClientQaByIdMutationVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
  set?: Maybe<Client_Set_Input>;
}>;

export type UpdateClientQaByIdMutation = { __typename?: 'mutation_root' } & {
  update_client?: Maybe<
    { __typename?: 'client_mutation_response' } & Pick<Client_Mutation_Response, 'affected_rows'> & {
        returning: Array<{ __typename?: 'client' } & ClientQaFragment>;
      }
  >;
};

export type UpdateClientQaMutationVariables = Exact<{
  set?: Maybe<Client_Set_Input>;
  where: Client_Bool_Exp;
}>;

export type UpdateClientQaMutation = { __typename?: 'mutation_root' } & {
  update_client?: Maybe<
    { __typename?: 'client_mutation_response' } & Pick<Client_Mutation_Response, 'affected_rows'> & {
        returning: Array<{ __typename?: 'client' } & ClientQaFragment>;
      }
  >;
};

export type QueryClientInternalByIdQueryVariables = Exact<{
  clientId: Scalars['uuid'];
}>;

export type QueryClientInternalByIdQuery = { __typename?: 'query_root' } & {
  client_by_pk?: Maybe<{ __typename?: 'client' } & ClientInternalFragment>;
};

export type QueryClientInternalObjectsQueryVariables = Exact<{
  distinct_on?: Maybe<Array<Client_Select_Column>>;
  where?: Maybe<Client_Bool_Exp>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Client_Order_By>>;
}>;

export type QueryClientInternalObjectsQuery = { __typename?: 'query_root' } & {
  client: Array<{ __typename?: 'client' } & ClientInternalFragment>;
};

export type SubscribeToClientInternalByIdSubscriptionVariables = Exact<{
  clientId: Scalars['uuid'];
}>;

export type SubscribeToClientInternalByIdSubscription = { __typename?: 'subscription_root' } & {
  client_by_pk?: Maybe<{ __typename?: 'client' } & ClientInternalFragment>;
};

export type SubscribeToClientInternalObjectsSubscriptionVariables = Exact<{
  distinct_on?: Maybe<Array<Client_Select_Column>>;
  where?: Maybe<Client_Bool_Exp>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Client_Order_By>>;
}>;

export type SubscribeToClientInternalObjectsSubscription = { __typename?: 'subscription_root' } & {
  client: Array<{ __typename?: 'client' } & ClientInternalFragment>;
};

export type InsertClientInternalMutationVariables = Exact<{
  objects: Array<Client_Insert_Input>;
}>;

export type InsertClientInternalMutation = { __typename?: 'mutation_root' } & {
  insert_client?: Maybe<
    { __typename?: 'client_mutation_response' } & Pick<Client_Mutation_Response, 'affected_rows'> & {
        returning: Array<{ __typename?: 'client' } & ClientInternalFragment>;
      }
  >;
};

export type InsertClientInternalWithOnConflictMutationVariables = Exact<{
  objects: Array<Client_Insert_Input>;
  onConflict?: Maybe<Client_On_Conflict>;
}>;

export type InsertClientInternalWithOnConflictMutation = { __typename?: 'mutation_root' } & {
  insert_client?: Maybe<
    { __typename?: 'client_mutation_response' } & Pick<Client_Mutation_Response, 'affected_rows'> & {
        returning: Array<{ __typename?: 'client' } & ClientInternalFragment>;
      }
  >;
};

export type UpdateClientInternalByIdMutationVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
  set?: Maybe<Client_Set_Input>;
}>;

export type UpdateClientInternalByIdMutation = { __typename?: 'mutation_root' } & {
  update_client?: Maybe<
    { __typename?: 'client_mutation_response' } & Pick<Client_Mutation_Response, 'affected_rows'> & {
        returning: Array<{ __typename?: 'client' } & ClientInternalFragment>;
      }
  >;
};

export type UpdateClientInternalMutationVariables = Exact<{
  set?: Maybe<Client_Set_Input>;
  where: Client_Bool_Exp;
}>;

export type UpdateClientInternalMutation = { __typename?: 'mutation_root' } & {
  update_client?: Maybe<
    { __typename?: 'client_mutation_response' } & Pick<Client_Mutation_Response, 'affected_rows'> & {
        returning: Array<{ __typename?: 'client' } & ClientInternalFragment>;
      }
  >;
};

export type QueryClientSelectByIdQueryVariables = Exact<{
  clientId: Scalars['uuid'];
}>;

export type QueryClientSelectByIdQuery = { __typename?: 'query_root' } & {
  client_by_pk?: Maybe<{ __typename?: 'client' } & ClientSelectFragment>;
};

export type QueryClientSelectObjectsQueryVariables = Exact<{
  distinct_on?: Maybe<Array<Client_Select_Column>>;
  where?: Maybe<Client_Bool_Exp>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Client_Order_By>>;
}>;

export type QueryClientSelectObjectsQuery = { __typename?: 'query_root' } & {
  client: Array<{ __typename?: 'client' } & ClientSelectFragment>;
};

export type SubscribeToClientSelectByIdSubscriptionVariables = Exact<{
  clientId: Scalars['uuid'];
}>;

export type SubscribeToClientSelectByIdSubscription = { __typename?: 'subscription_root' } & {
  client_by_pk?: Maybe<{ __typename?: 'client' } & ClientSelectFragment>;
};

export type SubscribeToClientSelectObjectsSubscriptionVariables = Exact<{
  distinct_on?: Maybe<Array<Client_Select_Column>>;
  where?: Maybe<Client_Bool_Exp>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Client_Order_By>>;
}>;

export type SubscribeToClientSelectObjectsSubscription = { __typename?: 'subscription_root' } & {
  client: Array<{ __typename?: 'client' } & ClientSelectFragment>;
};

export type InsertClientSelectMutationVariables = Exact<{
  objects: Array<Client_Insert_Input>;
}>;

export type InsertClientSelectMutation = { __typename?: 'mutation_root' } & {
  insert_client?: Maybe<
    { __typename?: 'client_mutation_response' } & Pick<Client_Mutation_Response, 'affected_rows'> & {
        returning: Array<{ __typename?: 'client' } & ClientSelectFragment>;
      }
  >;
};

export type InsertClientSelectWithOnConflictMutationVariables = Exact<{
  objects: Array<Client_Insert_Input>;
  onConflict?: Maybe<Client_On_Conflict>;
}>;

export type InsertClientSelectWithOnConflictMutation = { __typename?: 'mutation_root' } & {
  insert_client?: Maybe<
    { __typename?: 'client_mutation_response' } & Pick<Client_Mutation_Response, 'affected_rows'> & {
        returning: Array<{ __typename?: 'client' } & ClientSelectFragment>;
      }
  >;
};

export type UpdateClientSelectByIdMutationVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
  set?: Maybe<Client_Set_Input>;
}>;

export type UpdateClientSelectByIdMutation = { __typename?: 'mutation_root' } & {
  update_client?: Maybe<
    { __typename?: 'client_mutation_response' } & Pick<Client_Mutation_Response, 'affected_rows'> & {
        returning: Array<{ __typename?: 'client' } & ClientSelectFragment>;
      }
  >;
};

export type UpdateClientSelectMutationVariables = Exact<{
  set?: Maybe<Client_Set_Input>;
  where: Client_Bool_Exp;
}>;

export type UpdateClientSelectMutation = { __typename?: 'mutation_root' } & {
  update_client?: Maybe<
    { __typename?: 'client_mutation_response' } & Pick<Client_Mutation_Response, 'affected_rows'> & {
        returning: Array<{ __typename?: 'client' } & ClientSelectFragment>;
      }
  >;
};

export type QueryClientNotificationEmailByIdQueryVariables = Exact<{
  clientNotificationEmailId: Scalars['uuid'];
}>;

export type QueryClientNotificationEmailByIdQuery = { __typename?: 'query_root' } & {
  clientNotificationEmail_by_pk?: Maybe<{ __typename?: 'clientNotificationEmail' } & ClientNotificationEmailFragment>;
};

export type QueryClientNotificationEmailObjectsQueryVariables = Exact<{
  distinct_on?: Maybe<Array<ClientNotificationEmail_Select_Column>>;
  where?: Maybe<ClientNotificationEmail_Bool_Exp>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ClientNotificationEmail_Order_By>>;
}>;

export type QueryClientNotificationEmailObjectsQuery = { __typename?: 'query_root' } & {
  clientNotificationEmail: Array<{ __typename?: 'clientNotificationEmail' } & ClientNotificationEmailFragment>;
};

export type SubscribeToClientNotificationEmailByIdSubscriptionVariables = Exact<{
  clientNotificationEmailId: Scalars['uuid'];
}>;

export type SubscribeToClientNotificationEmailByIdSubscription = { __typename?: 'subscription_root' } & {
  clientNotificationEmail_by_pk?: Maybe<{ __typename?: 'clientNotificationEmail' } & ClientNotificationEmailFragment>;
};

export type SubscribeToClientNotificationEmailObjectsSubscriptionVariables = Exact<{
  distinct_on?: Maybe<Array<ClientNotificationEmail_Select_Column>>;
  where?: Maybe<ClientNotificationEmail_Bool_Exp>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ClientNotificationEmail_Order_By>>;
}>;

export type SubscribeToClientNotificationEmailObjectsSubscription = { __typename?: 'subscription_root' } & {
  clientNotificationEmail: Array<{ __typename?: 'clientNotificationEmail' } & ClientNotificationEmailFragment>;
};

export type InsertClientNotificationEmailMutationVariables = Exact<{
  objects: Array<ClientNotificationEmail_Insert_Input>;
}>;

export type InsertClientNotificationEmailMutation = { __typename?: 'mutation_root' } & {
  insert_clientNotificationEmail?: Maybe<
    { __typename?: 'clientNotificationEmail_mutation_response' } & Pick<
      ClientNotificationEmail_Mutation_Response,
      'affected_rows'
    > & { returning: Array<{ __typename?: 'clientNotificationEmail' } & ClientNotificationEmailFragment> }
  >;
};

export type InsertClientNotificationEmailWithOnConflictMutationVariables = Exact<{
  objects: Array<ClientNotificationEmail_Insert_Input>;
  onConflict?: Maybe<ClientNotificationEmail_On_Conflict>;
}>;

export type InsertClientNotificationEmailWithOnConflictMutation = { __typename?: 'mutation_root' } & {
  insert_clientNotificationEmail?: Maybe<
    { __typename?: 'clientNotificationEmail_mutation_response' } & Pick<
      ClientNotificationEmail_Mutation_Response,
      'affected_rows'
    > & { returning: Array<{ __typename?: 'clientNotificationEmail' } & ClientNotificationEmailFragment> }
  >;
};

export type UpdateClientNotificationEmailByIdMutationVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
  set?: Maybe<ClientNotificationEmail_Set_Input>;
}>;

export type UpdateClientNotificationEmailByIdMutation = { __typename?: 'mutation_root' } & {
  update_clientNotificationEmail?: Maybe<
    { __typename?: 'clientNotificationEmail_mutation_response' } & Pick<
      ClientNotificationEmail_Mutation_Response,
      'affected_rows'
    > & { returning: Array<{ __typename?: 'clientNotificationEmail' } & ClientNotificationEmailFragment> }
  >;
};

export type UpdateClientNotificationEmailMutationVariables = Exact<{
  set?: Maybe<ClientNotificationEmail_Set_Input>;
  where: ClientNotificationEmail_Bool_Exp;
}>;

export type UpdateClientNotificationEmailMutation = { __typename?: 'mutation_root' } & {
  update_clientNotificationEmail?: Maybe<
    { __typename?: 'clientNotificationEmail_mutation_response' } & Pick<
      ClientNotificationEmail_Mutation_Response,
      'affected_rows'
    > & { returning: Array<{ __typename?: 'clientNotificationEmail' } & ClientNotificationEmailFragment> }
  >;
};

export type RemoveClientNotificationEmailModelByIdMutationVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
}>;

export type RemoveClientNotificationEmailModelByIdMutation = { __typename?: 'mutation_root' } & {
  delete_clientNotificationEmail?: Maybe<
    { __typename?: 'clientNotificationEmail_mutation_response' } & Pick<
      ClientNotificationEmail_Mutation_Response,
      'affected_rows'
    >
  >;
};

export type RemoveClientNotificationEmailModelMutationVariables = Exact<{
  where: ClientNotificationEmail_Bool_Exp;
}>;

export type RemoveClientNotificationEmailModelMutation = { __typename?: 'mutation_root' } & {
  delete_clientNotificationEmail?: Maybe<
    { __typename?: 'clientNotificationEmail_mutation_response' } & Pick<
      ClientNotificationEmail_Mutation_Response,
      'affected_rows'
    >
  >;
};

export type QueryCloudTaskByIdQueryVariables = Exact<{
  cloudTaskId: Scalars['uuid'];
}>;

export type QueryCloudTaskByIdQuery = { __typename?: 'query_root' } & {
  cloudTask_by_pk?: Maybe<{ __typename?: 'cloudTask' } & CloudTaskFragment>;
};

export type QueryCloudTaskObjectsQueryVariables = Exact<{
  distinct_on?: Maybe<Array<CloudTask_Select_Column>>;
  where?: Maybe<CloudTask_Bool_Exp>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CloudTask_Order_By>>;
}>;

export type QueryCloudTaskObjectsQuery = { __typename?: 'query_root' } & {
  cloudTask: Array<{ __typename?: 'cloudTask' } & CloudTaskFragment>;
};

export type SubscribeToCloudTaskByIdSubscriptionVariables = Exact<{
  cloudTaskId: Scalars['uuid'];
}>;

export type SubscribeToCloudTaskByIdSubscription = { __typename?: 'subscription_root' } & {
  cloudTask_by_pk?: Maybe<{ __typename?: 'cloudTask' } & CloudTaskFragment>;
};

export type SubscribeToCloudTaskObjectsSubscriptionVariables = Exact<{
  distinct_on?: Maybe<Array<CloudTask_Select_Column>>;
  where?: Maybe<CloudTask_Bool_Exp>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CloudTask_Order_By>>;
}>;

export type SubscribeToCloudTaskObjectsSubscription = { __typename?: 'subscription_root' } & {
  cloudTask: Array<{ __typename?: 'cloudTask' } & CloudTaskFragment>;
};

export type InsertCloudTaskMutationVariables = Exact<{
  objects: Array<CloudTask_Insert_Input>;
}>;

export type InsertCloudTaskMutation = { __typename?: 'mutation_root' } & {
  insert_cloudTask?: Maybe<
    { __typename?: 'cloudTask_mutation_response' } & Pick<CloudTask_Mutation_Response, 'affected_rows'> & {
        returning: Array<{ __typename?: 'cloudTask' } & CloudTaskFragment>;
      }
  >;
};

export type InsertCloudTaskWithOnConflictMutationVariables = Exact<{
  objects: Array<CloudTask_Insert_Input>;
  onConflict?: Maybe<CloudTask_On_Conflict>;
}>;

export type InsertCloudTaskWithOnConflictMutation = { __typename?: 'mutation_root' } & {
  insert_cloudTask?: Maybe<
    { __typename?: 'cloudTask_mutation_response' } & Pick<CloudTask_Mutation_Response, 'affected_rows'> & {
        returning: Array<{ __typename?: 'cloudTask' } & CloudTaskFragment>;
      }
  >;
};

export type UpdateCloudTaskByIdMutationVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
  set?: Maybe<CloudTask_Set_Input>;
}>;

export type UpdateCloudTaskByIdMutation = { __typename?: 'mutation_root' } & {
  update_cloudTask?: Maybe<
    { __typename?: 'cloudTask_mutation_response' } & Pick<CloudTask_Mutation_Response, 'affected_rows'> & {
        returning: Array<{ __typename?: 'cloudTask' } & CloudTaskFragment>;
      }
  >;
};

export type UpdateCloudTaskMutationVariables = Exact<{
  set?: Maybe<CloudTask_Set_Input>;
  where: CloudTask_Bool_Exp;
}>;

export type UpdateCloudTaskMutation = { __typename?: 'mutation_root' } & {
  update_cloudTask?: Maybe<
    { __typename?: 'cloudTask_mutation_response' } & Pick<CloudTask_Mutation_Response, 'affected_rows'> & {
        returning: Array<{ __typename?: 'cloudTask' } & CloudTaskFragment>;
      }
  >;
};

export type RemoveCloudTaskModelByIdMutationVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
}>;

export type RemoveCloudTaskModelByIdMutation = { __typename?: 'mutation_root' } & {
  delete_cloudTask?: Maybe<
    { __typename?: 'cloudTask_mutation_response' } & Pick<CloudTask_Mutation_Response, 'affected_rows'>
  >;
};

export type RemoveCloudTaskModelMutationVariables = Exact<{
  where: CloudTask_Bool_Exp;
}>;

export type RemoveCloudTaskModelMutation = { __typename?: 'mutation_root' } & {
  delete_cloudTask?: Maybe<
    { __typename?: 'cloudTask_mutation_response' } & Pick<CloudTask_Mutation_Response, 'affected_rows'>
  >;
};

export type QueryClientEmailReportByIdQueryVariables = Exact<{
  clientEmailReportId: Scalars['uuid'];
}>;

export type QueryClientEmailReportByIdQuery = { __typename?: 'query_root' } & {
  clientEmailReport_by_pk?: Maybe<{ __typename?: 'clientEmailReport' } & ClientEmailReportFragment>;
};

export type QueryClientEmailReportObjectsQueryVariables = Exact<{
  distinct_on?: Maybe<Array<ClientEmailReport_Select_Column>>;
  where?: Maybe<ClientEmailReport_Bool_Exp>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ClientEmailReport_Order_By>>;
}>;

export type QueryClientEmailReportObjectsQuery = { __typename?: 'query_root' } & {
  clientEmailReport: Array<{ __typename?: 'clientEmailReport' } & ClientEmailReportFragment>;
};

export type SubscribeToClientEmailReportByIdSubscriptionVariables = Exact<{
  clientEmailReportId: Scalars['uuid'];
}>;

export type SubscribeToClientEmailReportByIdSubscription = { __typename?: 'subscription_root' } & {
  clientEmailReport_by_pk?: Maybe<{ __typename?: 'clientEmailReport' } & ClientEmailReportFragment>;
};

export type SubscribeToClientEmailReportObjectsSubscriptionVariables = Exact<{
  distinct_on?: Maybe<Array<ClientEmailReport_Select_Column>>;
  where?: Maybe<ClientEmailReport_Bool_Exp>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ClientEmailReport_Order_By>>;
}>;

export type SubscribeToClientEmailReportObjectsSubscription = { __typename?: 'subscription_root' } & {
  clientEmailReport: Array<{ __typename?: 'clientEmailReport' } & ClientEmailReportFragment>;
};

export type InsertClientEmailReportMutationVariables = Exact<{
  objects: Array<ClientEmailReport_Insert_Input>;
}>;

export type InsertClientEmailReportMutation = { __typename?: 'mutation_root' } & {
  insert_clientEmailReport?: Maybe<
    { __typename?: 'clientEmailReport_mutation_response' } & Pick<
      ClientEmailReport_Mutation_Response,
      'affected_rows'
    > & { returning: Array<{ __typename?: 'clientEmailReport' } & ClientEmailReportFragment> }
  >;
};

export type InsertClientEmailReportWithOnConflictMutationVariables = Exact<{
  objects: Array<ClientEmailReport_Insert_Input>;
  onConflict?: Maybe<ClientEmailReport_On_Conflict>;
}>;

export type InsertClientEmailReportWithOnConflictMutation = { __typename?: 'mutation_root' } & {
  insert_clientEmailReport?: Maybe<
    { __typename?: 'clientEmailReport_mutation_response' } & Pick<
      ClientEmailReport_Mutation_Response,
      'affected_rows'
    > & { returning: Array<{ __typename?: 'clientEmailReport' } & ClientEmailReportFragment> }
  >;
};

export type UpdateClientEmailReportByIdMutationVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
  set?: Maybe<ClientEmailReport_Set_Input>;
}>;

export type UpdateClientEmailReportByIdMutation = { __typename?: 'mutation_root' } & {
  update_clientEmailReport?: Maybe<
    { __typename?: 'clientEmailReport_mutation_response' } & Pick<
      ClientEmailReport_Mutation_Response,
      'affected_rows'
    > & { returning: Array<{ __typename?: 'clientEmailReport' } & ClientEmailReportFragment> }
  >;
};

export type UpdateClientEmailReportMutationVariables = Exact<{
  set?: Maybe<ClientEmailReport_Set_Input>;
  where: ClientEmailReport_Bool_Exp;
}>;

export type UpdateClientEmailReportMutation = { __typename?: 'mutation_root' } & {
  update_clientEmailReport?: Maybe<
    { __typename?: 'clientEmailReport_mutation_response' } & Pick<
      ClientEmailReport_Mutation_Response,
      'affected_rows'
    > & { returning: Array<{ __typename?: 'clientEmailReport' } & ClientEmailReportFragment> }
  >;
};

export type RemoveClientEmailReportModelByIdMutationVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
}>;

export type RemoveClientEmailReportModelByIdMutation = { __typename?: 'mutation_root' } & {
  delete_clientEmailReport?: Maybe<
    { __typename?: 'clientEmailReport_mutation_response' } & Pick<ClientEmailReport_Mutation_Response, 'affected_rows'>
  >;
};

export type RemoveClientEmailReportModelMutationVariables = Exact<{
  where: ClientEmailReport_Bool_Exp;
}>;

export type RemoveClientEmailReportModelMutation = { __typename?: 'mutation_root' } & {
  delete_clientEmailReport?: Maybe<
    { __typename?: 'clientEmailReport_mutation_response' } & Pick<ClientEmailReport_Mutation_Response, 'affected_rows'>
  >;
};

export type QueryEmailHistoryByIdQueryVariables = Exact<{
  emailHistoryId: Scalars['uuid'];
}>;

export type QueryEmailHistoryByIdQuery = { __typename?: 'query_root' } & {
  emailHistory_by_pk?: Maybe<{ __typename?: 'emailHistory' } & EmailHistoryFragment>;
};

export type QueryEmailHistoryObjectsQueryVariables = Exact<{
  distinct_on?: Maybe<Array<EmailHistory_Select_Column>>;
  where?: Maybe<EmailHistory_Bool_Exp>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<EmailHistory_Order_By>>;
}>;

export type QueryEmailHistoryObjectsQuery = { __typename?: 'query_root' } & {
  emailHistory: Array<{ __typename?: 'emailHistory' } & EmailHistoryFragment>;
};

export type SubscribeToEmailHistoryByIdSubscriptionVariables = Exact<{
  emailHistoryId: Scalars['uuid'];
}>;

export type SubscribeToEmailHistoryByIdSubscription = { __typename?: 'subscription_root' } & {
  emailHistory_by_pk?: Maybe<{ __typename?: 'emailHistory' } & EmailHistoryFragment>;
};

export type SubscribeToEmailHistoryObjectsSubscriptionVariables = Exact<{
  distinct_on?: Maybe<Array<EmailHistory_Select_Column>>;
  where?: Maybe<EmailHistory_Bool_Exp>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<EmailHistory_Order_By>>;
}>;

export type SubscribeToEmailHistoryObjectsSubscription = { __typename?: 'subscription_root' } & {
  emailHistory: Array<{ __typename?: 'emailHistory' } & EmailHistoryFragment>;
};

export type InsertEmailHistoryMutationVariables = Exact<{
  objects: Array<EmailHistory_Insert_Input>;
}>;

export type InsertEmailHistoryMutation = { __typename?: 'mutation_root' } & {
  insert_emailHistory?: Maybe<
    { __typename?: 'emailHistory_mutation_response' } & Pick<EmailHistory_Mutation_Response, 'affected_rows'> & {
        returning: Array<{ __typename?: 'emailHistory' } & EmailHistoryFragment>;
      }
  >;
};

export type InsertEmailHistoryWithOnConflictMutationVariables = Exact<{
  objects: Array<EmailHistory_Insert_Input>;
  onConflict?: Maybe<EmailHistory_On_Conflict>;
}>;

export type InsertEmailHistoryWithOnConflictMutation = { __typename?: 'mutation_root' } & {
  insert_emailHistory?: Maybe<
    { __typename?: 'emailHistory_mutation_response' } & Pick<EmailHistory_Mutation_Response, 'affected_rows'> & {
        returning: Array<{ __typename?: 'emailHistory' } & EmailHistoryFragment>;
      }
  >;
};

export type UpdateEmailHistoryByIdMutationVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
  set?: Maybe<EmailHistory_Set_Input>;
}>;

export type UpdateEmailHistoryByIdMutation = { __typename?: 'mutation_root' } & {
  update_emailHistory?: Maybe<
    { __typename?: 'emailHistory_mutation_response' } & Pick<EmailHistory_Mutation_Response, 'affected_rows'> & {
        returning: Array<{ __typename?: 'emailHistory' } & EmailHistoryFragment>;
      }
  >;
};

export type UpdateEmailHistoryMutationVariables = Exact<{
  set?: Maybe<EmailHistory_Set_Input>;
  where: EmailHistory_Bool_Exp;
}>;

export type UpdateEmailHistoryMutation = { __typename?: 'mutation_root' } & {
  update_emailHistory?: Maybe<
    { __typename?: 'emailHistory_mutation_response' } & Pick<EmailHistory_Mutation_Response, 'affected_rows'> & {
        returning: Array<{ __typename?: 'emailHistory' } & EmailHistoryFragment>;
      }
  >;
};

export type RemoveEmailHistoryModelByIdMutationVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
}>;

export type RemoveEmailHistoryModelByIdMutation = { __typename?: 'mutation_root' } & {
  delete_emailHistory?: Maybe<
    { __typename?: 'emailHistory_mutation_response' } & Pick<EmailHistory_Mutation_Response, 'affected_rows'>
  >;
};

export type RemoveEmailHistoryModelMutationVariables = Exact<{
  where: EmailHistory_Bool_Exp;
}>;

export type RemoveEmailHistoryModelMutation = { __typename?: 'mutation_root' } & {
  delete_emailHistory?: Maybe<
    { __typename?: 'emailHistory_mutation_response' } & Pick<EmailHistory_Mutation_Response, 'affected_rows'>
  >;
};

export type QuerySendgridEventByIdQueryVariables = Exact<{
  sendgridEventId: Scalars['uuid'];
}>;

export type QuerySendgridEventByIdQuery = { __typename?: 'query_root' } & {
  sendgridEvent_by_pk?: Maybe<{ __typename?: 'sendgridEvent' } & SendgridEventFragment>;
};

export type QuerySendgridEventObjectsQueryVariables = Exact<{
  distinct_on?: Maybe<Array<SendgridEvent_Select_Column>>;
  where?: Maybe<SendgridEvent_Bool_Exp>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SendgridEvent_Order_By>>;
}>;

export type QuerySendgridEventObjectsQuery = { __typename?: 'query_root' } & {
  sendgridEvent: Array<{ __typename?: 'sendgridEvent' } & SendgridEventFragment>;
};

export type SubscribeToSendgridEventByIdSubscriptionVariables = Exact<{
  sendgridEventId: Scalars['uuid'];
}>;

export type SubscribeToSendgridEventByIdSubscription = { __typename?: 'subscription_root' } & {
  sendgridEvent_by_pk?: Maybe<{ __typename?: 'sendgridEvent' } & SendgridEventFragment>;
};

export type SubscribeToSendgridEventObjectsSubscriptionVariables = Exact<{
  distinct_on?: Maybe<Array<SendgridEvent_Select_Column>>;
  where?: Maybe<SendgridEvent_Bool_Exp>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SendgridEvent_Order_By>>;
}>;

export type SubscribeToSendgridEventObjectsSubscription = { __typename?: 'subscription_root' } & {
  sendgridEvent: Array<{ __typename?: 'sendgridEvent' } & SendgridEventFragment>;
};

export type InsertSendgridEventMutationVariables = Exact<{
  objects: Array<SendgridEvent_Insert_Input>;
}>;

export type InsertSendgridEventMutation = { __typename?: 'mutation_root' } & {
  insert_sendgridEvent?: Maybe<
    { __typename?: 'sendgridEvent_mutation_response' } & Pick<SendgridEvent_Mutation_Response, 'affected_rows'> & {
        returning: Array<{ __typename?: 'sendgridEvent' } & SendgridEventFragment>;
      }
  >;
};

export type InsertSendgridEventWithOnConflictMutationVariables = Exact<{
  objects: Array<SendgridEvent_Insert_Input>;
  onConflict?: Maybe<SendgridEvent_On_Conflict>;
}>;

export type InsertSendgridEventWithOnConflictMutation = { __typename?: 'mutation_root' } & {
  insert_sendgridEvent?: Maybe<
    { __typename?: 'sendgridEvent_mutation_response' } & Pick<SendgridEvent_Mutation_Response, 'affected_rows'> & {
        returning: Array<{ __typename?: 'sendgridEvent' } & SendgridEventFragment>;
      }
  >;
};

export type UpdateSendgridEventByIdMutationVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
  set?: Maybe<SendgridEvent_Set_Input>;
}>;

export type UpdateSendgridEventByIdMutation = { __typename?: 'mutation_root' } & {
  update_sendgridEvent?: Maybe<
    { __typename?: 'sendgridEvent_mutation_response' } & Pick<SendgridEvent_Mutation_Response, 'affected_rows'> & {
        returning: Array<{ __typename?: 'sendgridEvent' } & SendgridEventFragment>;
      }
  >;
};

export type UpdateSendgridEventMutationVariables = Exact<{
  set?: Maybe<SendgridEvent_Set_Input>;
  where: SendgridEvent_Bool_Exp;
}>;

export type UpdateSendgridEventMutation = { __typename?: 'mutation_root' } & {
  update_sendgridEvent?: Maybe<
    { __typename?: 'sendgridEvent_mutation_response' } & Pick<SendgridEvent_Mutation_Response, 'affected_rows'> & {
        returning: Array<{ __typename?: 'sendgridEvent' } & SendgridEventFragment>;
      }
  >;
};

export type RemoveSendgridEventModelByIdMutationVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
}>;

export type RemoveSendgridEventModelByIdMutation = { __typename?: 'mutation_root' } & {
  delete_sendgridEvent?: Maybe<
    { __typename?: 'sendgridEvent_mutation_response' } & Pick<SendgridEvent_Mutation_Response, 'affected_rows'>
  >;
};

export type RemoveSendgridEventModelMutationVariables = Exact<{
  where: SendgridEvent_Bool_Exp;
}>;

export type RemoveSendgridEventModelMutation = { __typename?: 'mutation_root' } & {
  delete_sendgridEvent?: Maybe<
    { __typename?: 'sendgridEvent_mutation_response' } & Pick<SendgridEvent_Mutation_Response, 'affected_rows'>
  >;
};

export type QueryCsvUploadByIdQueryVariables = Exact<{
  csvUploadId: Scalars['uuid'];
}>;

export type QueryCsvUploadByIdQuery = { __typename?: 'query_root' } & {
  csvUpload_by_pk?: Maybe<{ __typename?: 'csvUpload' } & CsvUploadFragment>;
};

export type QueryCsvUploadObjectsQueryVariables = Exact<{
  distinct_on?: Maybe<Array<CsvUpload_Select_Column>>;
  where?: Maybe<CsvUpload_Bool_Exp>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CsvUpload_Order_By>>;
}>;

export type QueryCsvUploadObjectsQuery = { __typename?: 'query_root' } & {
  csvUpload: Array<{ __typename?: 'csvUpload' } & CsvUploadFragment>;
};

export type SubscribeToCsvUploadByIdSubscriptionVariables = Exact<{
  csvUploadId: Scalars['uuid'];
}>;

export type SubscribeToCsvUploadByIdSubscription = { __typename?: 'subscription_root' } & {
  csvUpload_by_pk?: Maybe<{ __typename?: 'csvUpload' } & CsvUploadFragment>;
};

export type SubscribeToCsvUploadObjectsSubscriptionVariables = Exact<{
  distinct_on?: Maybe<Array<CsvUpload_Select_Column>>;
  where?: Maybe<CsvUpload_Bool_Exp>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CsvUpload_Order_By>>;
}>;

export type SubscribeToCsvUploadObjectsSubscription = { __typename?: 'subscription_root' } & {
  csvUpload: Array<{ __typename?: 'csvUpload' } & CsvUploadFragment>;
};

export type InsertCsvUploadMutationVariables = Exact<{
  objects: Array<CsvUpload_Insert_Input>;
}>;

export type InsertCsvUploadMutation = { __typename?: 'mutation_root' } & {
  insert_csvUpload?: Maybe<
    { __typename?: 'csvUpload_mutation_response' } & Pick<CsvUpload_Mutation_Response, 'affected_rows'> & {
        returning: Array<{ __typename?: 'csvUpload' } & CsvUploadFragment>;
      }
  >;
};

export type InsertCsvUploadWithOnConflictMutationVariables = Exact<{
  objects: Array<CsvUpload_Insert_Input>;
  onConflict?: Maybe<CsvUpload_On_Conflict>;
}>;

export type InsertCsvUploadWithOnConflictMutation = { __typename?: 'mutation_root' } & {
  insert_csvUpload?: Maybe<
    { __typename?: 'csvUpload_mutation_response' } & Pick<CsvUpload_Mutation_Response, 'affected_rows'> & {
        returning: Array<{ __typename?: 'csvUpload' } & CsvUploadFragment>;
      }
  >;
};

export type UpdateCsvUploadByIdMutationVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
  set?: Maybe<CsvUpload_Set_Input>;
}>;

export type UpdateCsvUploadByIdMutation = { __typename?: 'mutation_root' } & {
  update_csvUpload?: Maybe<
    { __typename?: 'csvUpload_mutation_response' } & Pick<CsvUpload_Mutation_Response, 'affected_rows'> & {
        returning: Array<{ __typename?: 'csvUpload' } & CsvUploadFragment>;
      }
  >;
};

export type UpdateCsvUploadMutationVariables = Exact<{
  set?: Maybe<CsvUpload_Set_Input>;
  where: CsvUpload_Bool_Exp;
}>;

export type UpdateCsvUploadMutation = { __typename?: 'mutation_root' } & {
  update_csvUpload?: Maybe<
    { __typename?: 'csvUpload_mutation_response' } & Pick<CsvUpload_Mutation_Response, 'affected_rows'> & {
        returning: Array<{ __typename?: 'csvUpload' } & CsvUploadFragment>;
      }
  >;
};

export type RemoveCsvUploadModelByIdMutationVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
}>;

export type RemoveCsvUploadModelByIdMutation = { __typename?: 'mutation_root' } & {
  delete_csvUpload?: Maybe<
    { __typename?: 'csvUpload_mutation_response' } & Pick<CsvUpload_Mutation_Response, 'affected_rows'>
  >;
};

export type RemoveCsvUploadModelMutationVariables = Exact<{
  where: CsvUpload_Bool_Exp;
}>;

export type RemoveCsvUploadModelMutation = { __typename?: 'mutation_root' } & {
  delete_csvUpload?: Maybe<
    { __typename?: 'csvUpload_mutation_response' } & Pick<CsvUpload_Mutation_Response, 'affected_rows'>
  >;
};

export type QueryHubspotContactListByIdQueryVariables = Exact<{
  hubspotContactListId: Scalars['uuid'];
}>;

export type QueryHubspotContactListByIdQuery = { __typename?: 'query_root' } & {
  hubspotContactList_by_pk?: Maybe<{ __typename?: 'hubspotContactList' } & HubspotContactListFragment>;
};

export type QueryHubspotContactListObjectsQueryVariables = Exact<{
  distinct_on?: Maybe<Array<HubspotContactList_Select_Column>>;
  where?: Maybe<HubspotContactList_Bool_Exp>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HubspotContactList_Order_By>>;
}>;

export type QueryHubspotContactListObjectsQuery = { __typename?: 'query_root' } & {
  hubspotContactList: Array<{ __typename?: 'hubspotContactList' } & HubspotContactListFragment>;
};

export type SubscribeToHubspotContactListByIdSubscriptionVariables = Exact<{
  hubspotContactListId: Scalars['uuid'];
}>;

export type SubscribeToHubspotContactListByIdSubscription = { __typename?: 'subscription_root' } & {
  hubspotContactList_by_pk?: Maybe<{ __typename?: 'hubspotContactList' } & HubspotContactListFragment>;
};

export type SubscribeToHubspotContactListObjectsSubscriptionVariables = Exact<{
  distinct_on?: Maybe<Array<HubspotContactList_Select_Column>>;
  where?: Maybe<HubspotContactList_Bool_Exp>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HubspotContactList_Order_By>>;
}>;

export type SubscribeToHubspotContactListObjectsSubscription = { __typename?: 'subscription_root' } & {
  hubspotContactList: Array<{ __typename?: 'hubspotContactList' } & HubspotContactListFragment>;
};

export type InsertHubspotContactListMutationVariables = Exact<{
  objects: Array<HubspotContactList_Insert_Input>;
}>;

export type InsertHubspotContactListMutation = { __typename?: 'mutation_root' } & {
  insert_hubspotContactList?: Maybe<
    { __typename?: 'hubspotContactList_mutation_response' } & Pick<
      HubspotContactList_Mutation_Response,
      'affected_rows'
    > & { returning: Array<{ __typename?: 'hubspotContactList' } & HubspotContactListFragment> }
  >;
};

export type InsertHubspotContactListWithOnConflictMutationVariables = Exact<{
  objects: Array<HubspotContactList_Insert_Input>;
  onConflict?: Maybe<HubspotContactList_On_Conflict>;
}>;

export type InsertHubspotContactListWithOnConflictMutation = { __typename?: 'mutation_root' } & {
  insert_hubspotContactList?: Maybe<
    { __typename?: 'hubspotContactList_mutation_response' } & Pick<
      HubspotContactList_Mutation_Response,
      'affected_rows'
    > & { returning: Array<{ __typename?: 'hubspotContactList' } & HubspotContactListFragment> }
  >;
};

export type UpdateHubspotContactListByIdMutationVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
  set?: Maybe<HubspotContactList_Set_Input>;
}>;

export type UpdateHubspotContactListByIdMutation = { __typename?: 'mutation_root' } & {
  update_hubspotContactList?: Maybe<
    { __typename?: 'hubspotContactList_mutation_response' } & Pick<
      HubspotContactList_Mutation_Response,
      'affected_rows'
    > & { returning: Array<{ __typename?: 'hubspotContactList' } & HubspotContactListFragment> }
  >;
};

export type UpdateHubspotContactListMutationVariables = Exact<{
  set?: Maybe<HubspotContactList_Set_Input>;
  where: HubspotContactList_Bool_Exp;
}>;

export type UpdateHubspotContactListMutation = { __typename?: 'mutation_root' } & {
  update_hubspotContactList?: Maybe<
    { __typename?: 'hubspotContactList_mutation_response' } & Pick<
      HubspotContactList_Mutation_Response,
      'affected_rows'
    > & { returning: Array<{ __typename?: 'hubspotContactList' } & HubspotContactListFragment> }
  >;
};

export type RemoveHubspotContactListModelByIdMutationVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
}>;

export type RemoveHubspotContactListModelByIdMutation = { __typename?: 'mutation_root' } & {
  delete_hubspotContactList?: Maybe<
    { __typename?: 'hubspotContactList_mutation_response' } & Pick<
      HubspotContactList_Mutation_Response,
      'affected_rows'
    >
  >;
};

export type RemoveHubspotContactListModelMutationVariables = Exact<{
  where: HubspotContactList_Bool_Exp;
}>;

export type RemoveHubspotContactListModelMutation = { __typename?: 'mutation_root' } & {
  delete_hubspotContactList?: Maybe<
    { __typename?: 'hubspotContactList_mutation_response' } & Pick<
      HubspotContactList_Mutation_Response,
      'affected_rows'
    >
  >;
};

export type QueryHubspotContactListHubspotContactByIdQueryVariables = Exact<{
  hubspotContactListHubspotContactId: Scalars['uuid'];
}>;

export type QueryHubspotContactListHubspotContactByIdQuery = { __typename?: 'query_root' } & {
  hubspotContactListHubspotContact_by_pk?: Maybe<
    { __typename?: 'hubspotContactListHubspotContact' } & HubspotContactListHubspotContactFragment
  >;
};

export type QueryHubspotContactListHubspotContactObjectsQueryVariables = Exact<{
  distinct_on?: Maybe<Array<HubspotContactListHubspotContact_Select_Column>>;
  where?: Maybe<HubspotContactListHubspotContact_Bool_Exp>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HubspotContactListHubspotContact_Order_By>>;
}>;

export type QueryHubspotContactListHubspotContactObjectsQuery = { __typename?: 'query_root' } & {
  hubspotContactListHubspotContact: Array<
    { __typename?: 'hubspotContactListHubspotContact' } & HubspotContactListHubspotContactFragment
  >;
};

export type SubscribeToHubspotContactListHubspotContactByIdSubscriptionVariables = Exact<{
  hubspotContactListHubspotContactId: Scalars['uuid'];
}>;

export type SubscribeToHubspotContactListHubspotContactByIdSubscription = { __typename?: 'subscription_root' } & {
  hubspotContactListHubspotContact_by_pk?: Maybe<
    { __typename?: 'hubspotContactListHubspotContact' } & HubspotContactListHubspotContactFragment
  >;
};

export type SubscribeToHubspotContactListHubspotContactObjectsSubscriptionVariables = Exact<{
  distinct_on?: Maybe<Array<HubspotContactListHubspotContact_Select_Column>>;
  where?: Maybe<HubspotContactListHubspotContact_Bool_Exp>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HubspotContactListHubspotContact_Order_By>>;
}>;

export type SubscribeToHubspotContactListHubspotContactObjectsSubscription = { __typename?: 'subscription_root' } & {
  hubspotContactListHubspotContact: Array<
    { __typename?: 'hubspotContactListHubspotContact' } & HubspotContactListHubspotContactFragment
  >;
};

export type InsertHubspotContactListHubspotContactMutationVariables = Exact<{
  objects: Array<HubspotContactListHubspotContact_Insert_Input>;
}>;

export type InsertHubspotContactListHubspotContactMutation = { __typename?: 'mutation_root' } & {
  insert_hubspotContactListHubspotContact?: Maybe<
    { __typename?: 'hubspotContactListHubspotContact_mutation_response' } & Pick<
      HubspotContactListHubspotContact_Mutation_Response,
      'affected_rows'
    > & {
        returning: Array<
          { __typename?: 'hubspotContactListHubspotContact' } & HubspotContactListHubspotContactFragment
        >;
      }
  >;
};

export type InsertHubspotContactListHubspotContactWithOnConflictMutationVariables = Exact<{
  objects: Array<HubspotContactListHubspotContact_Insert_Input>;
  onConflict?: Maybe<HubspotContactListHubspotContact_On_Conflict>;
}>;

export type InsertHubspotContactListHubspotContactWithOnConflictMutation = { __typename?: 'mutation_root' } & {
  insert_hubspotContactListHubspotContact?: Maybe<
    { __typename?: 'hubspotContactListHubspotContact_mutation_response' } & Pick<
      HubspotContactListHubspotContact_Mutation_Response,
      'affected_rows'
    > & {
        returning: Array<
          { __typename?: 'hubspotContactListHubspotContact' } & HubspotContactListHubspotContactFragment
        >;
      }
  >;
};

export type UpdateHubspotContactListHubspotContactByIdMutationVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
  set?: Maybe<HubspotContactListHubspotContact_Set_Input>;
}>;

export type UpdateHubspotContactListHubspotContactByIdMutation = { __typename?: 'mutation_root' } & {
  update_hubspotContactListHubspotContact?: Maybe<
    { __typename?: 'hubspotContactListHubspotContact_mutation_response' } & Pick<
      HubspotContactListHubspotContact_Mutation_Response,
      'affected_rows'
    > & {
        returning: Array<
          { __typename?: 'hubspotContactListHubspotContact' } & HubspotContactListHubspotContactFragment
        >;
      }
  >;
};

export type UpdateHubspotContactListHubspotContactMutationVariables = Exact<{
  set?: Maybe<HubspotContactListHubspotContact_Set_Input>;
  where: HubspotContactListHubspotContact_Bool_Exp;
}>;

export type UpdateHubspotContactListHubspotContactMutation = { __typename?: 'mutation_root' } & {
  update_hubspotContactListHubspotContact?: Maybe<
    { __typename?: 'hubspotContactListHubspotContact_mutation_response' } & Pick<
      HubspotContactListHubspotContact_Mutation_Response,
      'affected_rows'
    > & {
        returning: Array<
          { __typename?: 'hubspotContactListHubspotContact' } & HubspotContactListHubspotContactFragment
        >;
      }
  >;
};

export type RemoveHubspotContactListHubspotContactModelByIdMutationVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
}>;

export type RemoveHubspotContactListHubspotContactModelByIdMutation = { __typename?: 'mutation_root' } & {
  delete_hubspotContactListHubspotContact?: Maybe<
    { __typename?: 'hubspotContactListHubspotContact_mutation_response' } & Pick<
      HubspotContactListHubspotContact_Mutation_Response,
      'affected_rows'
    >
  >;
};

export type RemoveHubspotContactListHubspotContactModelMutationVariables = Exact<{
  where: HubspotContactListHubspotContact_Bool_Exp;
}>;

export type RemoveHubspotContactListHubspotContactModelMutation = { __typename?: 'mutation_root' } & {
  delete_hubspotContactListHubspotContact?: Maybe<
    { __typename?: 'hubspotContactListHubspotContact_mutation_response' } & Pick<
      HubspotContactListHubspotContact_Mutation_Response,
      'affected_rows'
    >
  >;
};

export type QueryHubspotContactPropertyByIdQueryVariables = Exact<{
  hubspotContactPropertyId: Scalars['uuid'];
}>;

export type QueryHubspotContactPropertyByIdQuery = { __typename?: 'query_root' } & {
  hubspotContactProperty_by_pk?: Maybe<{ __typename?: 'hubspotContactProperty' } & HubspotContactPropertyFragment>;
};

export type QueryHubspotContactPropertyObjectsQueryVariables = Exact<{
  distinct_on?: Maybe<Array<HubspotContactProperty_Select_Column>>;
  where?: Maybe<HubspotContactProperty_Bool_Exp>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HubspotContactProperty_Order_By>>;
}>;

export type QueryHubspotContactPropertyObjectsQuery = { __typename?: 'query_root' } & {
  hubspotContactProperty: Array<{ __typename?: 'hubspotContactProperty' } & HubspotContactPropertyFragment>;
};

export type SubscribeToHubspotContactPropertyByIdSubscriptionVariables = Exact<{
  hubspotContactPropertyId: Scalars['uuid'];
}>;

export type SubscribeToHubspotContactPropertyByIdSubscription = { __typename?: 'subscription_root' } & {
  hubspotContactProperty_by_pk?: Maybe<{ __typename?: 'hubspotContactProperty' } & HubspotContactPropertyFragment>;
};

export type SubscribeToHubspotContactPropertyObjectsSubscriptionVariables = Exact<{
  distinct_on?: Maybe<Array<HubspotContactProperty_Select_Column>>;
  where?: Maybe<HubspotContactProperty_Bool_Exp>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HubspotContactProperty_Order_By>>;
}>;

export type SubscribeToHubspotContactPropertyObjectsSubscription = { __typename?: 'subscription_root' } & {
  hubspotContactProperty: Array<{ __typename?: 'hubspotContactProperty' } & HubspotContactPropertyFragment>;
};

export type InsertHubspotContactPropertyMutationVariables = Exact<{
  objects: Array<HubspotContactProperty_Insert_Input>;
}>;

export type InsertHubspotContactPropertyMutation = { __typename?: 'mutation_root' } & {
  insert_hubspotContactProperty?: Maybe<
    { __typename?: 'hubspotContactProperty_mutation_response' } & Pick<
      HubspotContactProperty_Mutation_Response,
      'affected_rows'
    > & { returning: Array<{ __typename?: 'hubspotContactProperty' } & HubspotContactPropertyFragment> }
  >;
};

export type InsertHubspotContactPropertyWithOnConflictMutationVariables = Exact<{
  objects: Array<HubspotContactProperty_Insert_Input>;
  onConflict?: Maybe<HubspotContactProperty_On_Conflict>;
}>;

export type InsertHubspotContactPropertyWithOnConflictMutation = { __typename?: 'mutation_root' } & {
  insert_hubspotContactProperty?: Maybe<
    { __typename?: 'hubspotContactProperty_mutation_response' } & Pick<
      HubspotContactProperty_Mutation_Response,
      'affected_rows'
    > & { returning: Array<{ __typename?: 'hubspotContactProperty' } & HubspotContactPropertyFragment> }
  >;
};

export type UpdateHubspotContactPropertyByIdMutationVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
  set?: Maybe<HubspotContactProperty_Set_Input>;
}>;

export type UpdateHubspotContactPropertyByIdMutation = { __typename?: 'mutation_root' } & {
  update_hubspotContactProperty?: Maybe<
    { __typename?: 'hubspotContactProperty_mutation_response' } & Pick<
      HubspotContactProperty_Mutation_Response,
      'affected_rows'
    > & { returning: Array<{ __typename?: 'hubspotContactProperty' } & HubspotContactPropertyFragment> }
  >;
};

export type UpdateHubspotContactPropertyMutationVariables = Exact<{
  set?: Maybe<HubspotContactProperty_Set_Input>;
  where: HubspotContactProperty_Bool_Exp;
}>;

export type UpdateHubspotContactPropertyMutation = { __typename?: 'mutation_root' } & {
  update_hubspotContactProperty?: Maybe<
    { __typename?: 'hubspotContactProperty_mutation_response' } & Pick<
      HubspotContactProperty_Mutation_Response,
      'affected_rows'
    > & { returning: Array<{ __typename?: 'hubspotContactProperty' } & HubspotContactPropertyFragment> }
  >;
};

export type RemoveHubspotContactPropertyModelByIdMutationVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
}>;

export type RemoveHubspotContactPropertyModelByIdMutation = { __typename?: 'mutation_root' } & {
  delete_hubspotContactProperty?: Maybe<
    { __typename?: 'hubspotContactProperty_mutation_response' } & Pick<
      HubspotContactProperty_Mutation_Response,
      'affected_rows'
    >
  >;
};

export type RemoveHubspotContactPropertyModelMutationVariables = Exact<{
  where: HubspotContactProperty_Bool_Exp;
}>;

export type RemoveHubspotContactPropertyModelMutation = { __typename?: 'mutation_root' } & {
  delete_hubspotContactProperty?: Maybe<
    { __typename?: 'hubspotContactProperty_mutation_response' } & Pick<
      HubspotContactProperty_Mutation_Response,
      'affected_rows'
    >
  >;
};

export type QuerySalesforceContactFieldByIdQueryVariables = Exact<{
  salesforceContactFieldId: Scalars['uuid'];
}>;

export type QuerySalesforceContactFieldByIdQuery = { __typename?: 'query_root' } & {
  salesforceContactField_by_pk?: Maybe<{ __typename?: 'salesforceContactField' } & SalesforceContactFieldFragment>;
};

export type QuerySalesforceContactFieldObjectsQueryVariables = Exact<{
  distinct_on?: Maybe<Array<SalesforceContactField_Select_Column>>;
  where?: Maybe<SalesforceContactField_Bool_Exp>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SalesforceContactField_Order_By>>;
}>;

export type QuerySalesforceContactFieldObjectsQuery = { __typename?: 'query_root' } & {
  salesforceContactField: Array<{ __typename?: 'salesforceContactField' } & SalesforceContactFieldFragment>;
};

export type SubscribeToSalesforceContactFieldByIdSubscriptionVariables = Exact<{
  salesforceContactFieldId: Scalars['uuid'];
}>;

export type SubscribeToSalesforceContactFieldByIdSubscription = { __typename?: 'subscription_root' } & {
  salesforceContactField_by_pk?: Maybe<{ __typename?: 'salesforceContactField' } & SalesforceContactFieldFragment>;
};

export type SubscribeToSalesforceContactFieldObjectsSubscriptionVariables = Exact<{
  distinct_on?: Maybe<Array<SalesforceContactField_Select_Column>>;
  where?: Maybe<SalesforceContactField_Bool_Exp>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SalesforceContactField_Order_By>>;
}>;

export type SubscribeToSalesforceContactFieldObjectsSubscription = { __typename?: 'subscription_root' } & {
  salesforceContactField: Array<{ __typename?: 'salesforceContactField' } & SalesforceContactFieldFragment>;
};

export type InsertSalesforceContactFieldMutationVariables = Exact<{
  objects: Array<SalesforceContactField_Insert_Input>;
}>;

export type InsertSalesforceContactFieldMutation = { __typename?: 'mutation_root' } & {
  insert_salesforceContactField?: Maybe<
    { __typename?: 'salesforceContactField_mutation_response' } & Pick<
      SalesforceContactField_Mutation_Response,
      'affected_rows'
    > & { returning: Array<{ __typename?: 'salesforceContactField' } & SalesforceContactFieldFragment> }
  >;
};

export type InsertSalesforceContactFieldWithOnConflictMutationVariables = Exact<{
  objects: Array<SalesforceContactField_Insert_Input>;
  onConflict?: Maybe<SalesforceContactField_On_Conflict>;
}>;

export type InsertSalesforceContactFieldWithOnConflictMutation = { __typename?: 'mutation_root' } & {
  insert_salesforceContactField?: Maybe<
    { __typename?: 'salesforceContactField_mutation_response' } & Pick<
      SalesforceContactField_Mutation_Response,
      'affected_rows'
    > & { returning: Array<{ __typename?: 'salesforceContactField' } & SalesforceContactFieldFragment> }
  >;
};

export type UpdateSalesforceContactFieldByIdMutationVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
  set?: Maybe<SalesforceContactField_Set_Input>;
}>;

export type UpdateSalesforceContactFieldByIdMutation = { __typename?: 'mutation_root' } & {
  update_salesforceContactField?: Maybe<
    { __typename?: 'salesforceContactField_mutation_response' } & Pick<
      SalesforceContactField_Mutation_Response,
      'affected_rows'
    > & { returning: Array<{ __typename?: 'salesforceContactField' } & SalesforceContactFieldFragment> }
  >;
};

export type UpdateSalesforceContactFieldMutationVariables = Exact<{
  set?: Maybe<SalesforceContactField_Set_Input>;
  where: SalesforceContactField_Bool_Exp;
}>;

export type UpdateSalesforceContactFieldMutation = { __typename?: 'mutation_root' } & {
  update_salesforceContactField?: Maybe<
    { __typename?: 'salesforceContactField_mutation_response' } & Pick<
      SalesforceContactField_Mutation_Response,
      'affected_rows'
    > & { returning: Array<{ __typename?: 'salesforceContactField' } & SalesforceContactFieldFragment> }
  >;
};

export type RemoveSalesforceContactFieldModelByIdMutationVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
}>;

export type RemoveSalesforceContactFieldModelByIdMutation = { __typename?: 'mutation_root' } & {
  delete_salesforceContactField?: Maybe<
    { __typename?: 'salesforceContactField_mutation_response' } & Pick<
      SalesforceContactField_Mutation_Response,
      'affected_rows'
    >
  >;
};

export type RemoveSalesforceContactFieldModelMutationVariables = Exact<{
  where: SalesforceContactField_Bool_Exp;
}>;

export type RemoveSalesforceContactFieldModelMutation = { __typename?: 'mutation_root' } & {
  delete_salesforceContactField?: Maybe<
    { __typename?: 'salesforceContactField_mutation_response' } & Pick<
      SalesforceContactField_Mutation_Response,
      'affected_rows'
    >
  >;
};

export type QueryHubspotIntegrationByIdQueryVariables = Exact<{
  hubspotIntegrationId: Scalars['uuid'];
}>;

export type QueryHubspotIntegrationByIdQuery = { __typename?: 'query_root' } & {
  hubspotIntegration_by_pk?: Maybe<{ __typename?: 'hubspotIntegration' } & HubspotIntegrationFragment>;
};

export type QueryHubspotIntegrationObjectsQueryVariables = Exact<{
  distinct_on?: Maybe<Array<HubspotIntegration_Select_Column>>;
  where?: Maybe<HubspotIntegration_Bool_Exp>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HubspotIntegration_Order_By>>;
}>;

export type QueryHubspotIntegrationObjectsQuery = { __typename?: 'query_root' } & {
  hubspotIntegration: Array<{ __typename?: 'hubspotIntegration' } & HubspotIntegrationFragment>;
};

export type SubscribeToHubspotIntegrationByIdSubscriptionVariables = Exact<{
  hubspotIntegrationId: Scalars['uuid'];
}>;

export type SubscribeToHubspotIntegrationByIdSubscription = { __typename?: 'subscription_root' } & {
  hubspotIntegration_by_pk?: Maybe<{ __typename?: 'hubspotIntegration' } & HubspotIntegrationFragment>;
};

export type SubscribeToHubspotIntegrationObjectsSubscriptionVariables = Exact<{
  distinct_on?: Maybe<Array<HubspotIntegration_Select_Column>>;
  where?: Maybe<HubspotIntegration_Bool_Exp>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HubspotIntegration_Order_By>>;
}>;

export type SubscribeToHubspotIntegrationObjectsSubscription = { __typename?: 'subscription_root' } & {
  hubspotIntegration: Array<{ __typename?: 'hubspotIntegration' } & HubspotIntegrationFragment>;
};

export type InsertHubspotIntegrationMutationVariables = Exact<{
  objects: Array<HubspotIntegration_Insert_Input>;
}>;

export type InsertHubspotIntegrationMutation = { __typename?: 'mutation_root' } & {
  insert_hubspotIntegration?: Maybe<
    { __typename?: 'hubspotIntegration_mutation_response' } & Pick<
      HubspotIntegration_Mutation_Response,
      'affected_rows'
    > & { returning: Array<{ __typename?: 'hubspotIntegration' } & HubspotIntegrationFragment> }
  >;
};

export type InsertHubspotIntegrationWithOnConflictMutationVariables = Exact<{
  objects: Array<HubspotIntegration_Insert_Input>;
  onConflict?: Maybe<HubspotIntegration_On_Conflict>;
}>;

export type InsertHubspotIntegrationWithOnConflictMutation = { __typename?: 'mutation_root' } & {
  insert_hubspotIntegration?: Maybe<
    { __typename?: 'hubspotIntegration_mutation_response' } & Pick<
      HubspotIntegration_Mutation_Response,
      'affected_rows'
    > & { returning: Array<{ __typename?: 'hubspotIntegration' } & HubspotIntegrationFragment> }
  >;
};

export type UpdateHubspotIntegrationByIdMutationVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
  set?: Maybe<HubspotIntegration_Set_Input>;
}>;

export type UpdateHubspotIntegrationByIdMutation = { __typename?: 'mutation_root' } & {
  update_hubspotIntegration?: Maybe<
    { __typename?: 'hubspotIntegration_mutation_response' } & Pick<
      HubspotIntegration_Mutation_Response,
      'affected_rows'
    > & { returning: Array<{ __typename?: 'hubspotIntegration' } & HubspotIntegrationFragment> }
  >;
};

export type UpdateHubspotIntegrationMutationVariables = Exact<{
  set?: Maybe<HubspotIntegration_Set_Input>;
  where: HubspotIntegration_Bool_Exp;
}>;

export type UpdateHubspotIntegrationMutation = { __typename?: 'mutation_root' } & {
  update_hubspotIntegration?: Maybe<
    { __typename?: 'hubspotIntegration_mutation_response' } & Pick<
      HubspotIntegration_Mutation_Response,
      'affected_rows'
    > & { returning: Array<{ __typename?: 'hubspotIntegration' } & HubspotIntegrationFragment> }
  >;
};

export type RemoveHubspotIntegrationModelByIdMutationVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
}>;

export type RemoveHubspotIntegrationModelByIdMutation = { __typename?: 'mutation_root' } & {
  delete_hubspotIntegration?: Maybe<
    { __typename?: 'hubspotIntegration_mutation_response' } & Pick<
      HubspotIntegration_Mutation_Response,
      'affected_rows'
    >
  >;
};

export type RemoveHubspotIntegrationModelMutationVariables = Exact<{
  where: HubspotIntegration_Bool_Exp;
}>;

export type RemoveHubspotIntegrationModelMutation = { __typename?: 'mutation_root' } & {
  delete_hubspotIntegration?: Maybe<
    { __typename?: 'hubspotIntegration_mutation_response' } & Pick<
      HubspotIntegration_Mutation_Response,
      'affected_rows'
    >
  >;
};

export type QuerySalesforceIntegrationByIdQueryVariables = Exact<{
  salesforceIntegrationId: Scalars['uuid'];
}>;

export type QuerySalesforceIntegrationByIdQuery = { __typename?: 'query_root' } & {
  salesforceIntegration_by_pk?: Maybe<{ __typename?: 'salesforceIntegration' } & SalesforceIntegrationFragment>;
};

export type QuerySalesforceIntegrationObjectsQueryVariables = Exact<{
  distinct_on?: Maybe<Array<SalesforceIntegration_Select_Column>>;
  where?: Maybe<SalesforceIntegration_Bool_Exp>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SalesforceIntegration_Order_By>>;
}>;

export type QuerySalesforceIntegrationObjectsQuery = { __typename?: 'query_root' } & {
  salesforceIntegration: Array<{ __typename?: 'salesforceIntegration' } & SalesforceIntegrationFragment>;
};

export type SubscribeToSalesforceIntegrationByIdSubscriptionVariables = Exact<{
  salesforceIntegrationId: Scalars['uuid'];
}>;

export type SubscribeToSalesforceIntegrationByIdSubscription = { __typename?: 'subscription_root' } & {
  salesforceIntegration_by_pk?: Maybe<{ __typename?: 'salesforceIntegration' } & SalesforceIntegrationFragment>;
};

export type SubscribeToSalesforceIntegrationObjectsSubscriptionVariables = Exact<{
  distinct_on?: Maybe<Array<SalesforceIntegration_Select_Column>>;
  where?: Maybe<SalesforceIntegration_Bool_Exp>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<SalesforceIntegration_Order_By>>;
}>;

export type SubscribeToSalesforceIntegrationObjectsSubscription = { __typename?: 'subscription_root' } & {
  salesforceIntegration: Array<{ __typename?: 'salesforceIntegration' } & SalesforceIntegrationFragment>;
};

export type InsertSalesforceIntegrationMutationVariables = Exact<{
  objects: Array<SalesforceIntegration_Insert_Input>;
}>;

export type InsertSalesforceIntegrationMutation = { __typename?: 'mutation_root' } & {
  insert_salesforceIntegration?: Maybe<
    { __typename?: 'salesforceIntegration_mutation_response' } & Pick<
      SalesforceIntegration_Mutation_Response,
      'affected_rows'
    > & { returning: Array<{ __typename?: 'salesforceIntegration' } & SalesforceIntegrationFragment> }
  >;
};

export type InsertSalesforceIntegrationWithOnConflictMutationVariables = Exact<{
  objects: Array<SalesforceIntegration_Insert_Input>;
  onConflict?: Maybe<SalesforceIntegration_On_Conflict>;
}>;

export type InsertSalesforceIntegrationWithOnConflictMutation = { __typename?: 'mutation_root' } & {
  insert_salesforceIntegration?: Maybe<
    { __typename?: 'salesforceIntegration_mutation_response' } & Pick<
      SalesforceIntegration_Mutation_Response,
      'affected_rows'
    > & { returning: Array<{ __typename?: 'salesforceIntegration' } & SalesforceIntegrationFragment> }
  >;
};

export type UpdateSalesforceIntegrationByIdMutationVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
  set?: Maybe<SalesforceIntegration_Set_Input>;
}>;

export type UpdateSalesforceIntegrationByIdMutation = { __typename?: 'mutation_root' } & {
  update_salesforceIntegration?: Maybe<
    { __typename?: 'salesforceIntegration_mutation_response' } & Pick<
      SalesforceIntegration_Mutation_Response,
      'affected_rows'
    > & { returning: Array<{ __typename?: 'salesforceIntegration' } & SalesforceIntegrationFragment> }
  >;
};

export type UpdateSalesforceIntegrationMutationVariables = Exact<{
  set?: Maybe<SalesforceIntegration_Set_Input>;
  where: SalesforceIntegration_Bool_Exp;
}>;

export type UpdateSalesforceIntegrationMutation = { __typename?: 'mutation_root' } & {
  update_salesforceIntegration?: Maybe<
    { __typename?: 'salesforceIntegration_mutation_response' } & Pick<
      SalesforceIntegration_Mutation_Response,
      'affected_rows'
    > & { returning: Array<{ __typename?: 'salesforceIntegration' } & SalesforceIntegrationFragment> }
  >;
};

export type RemoveSalesforceIntegrationModelByIdMutationVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
}>;

export type RemoveSalesforceIntegrationModelByIdMutation = { __typename?: 'mutation_root' } & {
  delete_salesforceIntegration?: Maybe<
    { __typename?: 'salesforceIntegration_mutation_response' } & Pick<
      SalesforceIntegration_Mutation_Response,
      'affected_rows'
    >
  >;
};

export type RemoveSalesforceIntegrationModelMutationVariables = Exact<{
  where: SalesforceIntegration_Bool_Exp;
}>;

export type RemoveSalesforceIntegrationModelMutation = { __typename?: 'mutation_root' } & {
  delete_salesforceIntegration?: Maybe<
    { __typename?: 'salesforceIntegration_mutation_response' } & Pick<
      SalesforceIntegration_Mutation_Response,
      'affected_rows'
    >
  >;
};

export type QueryQaTaskActionByIdQueryVariables = Exact<{
  qaTaskActionId: Scalars['uuid'];
}>;

export type QueryQaTaskActionByIdQuery = { __typename?: 'query_root' } & {
  qaTaskAction_by_pk?: Maybe<{ __typename?: 'qaTaskAction' } & QaTaskActionFragment>;
};

export type QueryQaTaskActionObjectsQueryVariables = Exact<{
  distinct_on?: Maybe<Array<QaTaskAction_Select_Column>>;
  where?: Maybe<QaTaskAction_Bool_Exp>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<QaTaskAction_Order_By>>;
}>;

export type QueryQaTaskActionObjectsQuery = { __typename?: 'query_root' } & {
  qaTaskAction: Array<{ __typename?: 'qaTaskAction' } & QaTaskActionFragment>;
};

export type SubscribeToQaTaskActionByIdSubscriptionVariables = Exact<{
  qaTaskActionId: Scalars['uuid'];
}>;

export type SubscribeToQaTaskActionByIdSubscription = { __typename?: 'subscription_root' } & {
  qaTaskAction_by_pk?: Maybe<{ __typename?: 'qaTaskAction' } & QaTaskActionFragment>;
};

export type SubscribeToQaTaskActionObjectsSubscriptionVariables = Exact<{
  distinct_on?: Maybe<Array<QaTaskAction_Select_Column>>;
  where?: Maybe<QaTaskAction_Bool_Exp>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<QaTaskAction_Order_By>>;
}>;

export type SubscribeToQaTaskActionObjectsSubscription = { __typename?: 'subscription_root' } & {
  qaTaskAction: Array<{ __typename?: 'qaTaskAction' } & QaTaskActionFragment>;
};

export type InsertQaTaskActionMutationVariables = Exact<{
  objects: Array<QaTaskAction_Insert_Input>;
}>;

export type InsertQaTaskActionMutation = { __typename?: 'mutation_root' } & {
  insert_qaTaskAction?: Maybe<
    { __typename?: 'qaTaskAction_mutation_response' } & Pick<QaTaskAction_Mutation_Response, 'affected_rows'> & {
        returning: Array<{ __typename?: 'qaTaskAction' } & QaTaskActionFragment>;
      }
  >;
};

export type InsertQaTaskActionWithOnConflictMutationVariables = Exact<{
  objects: Array<QaTaskAction_Insert_Input>;
  onConflict?: Maybe<QaTaskAction_On_Conflict>;
}>;

export type InsertQaTaskActionWithOnConflictMutation = { __typename?: 'mutation_root' } & {
  insert_qaTaskAction?: Maybe<
    { __typename?: 'qaTaskAction_mutation_response' } & Pick<QaTaskAction_Mutation_Response, 'affected_rows'> & {
        returning: Array<{ __typename?: 'qaTaskAction' } & QaTaskActionFragment>;
      }
  >;
};

export type UpdateQaTaskActionByIdMutationVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
  set?: Maybe<QaTaskAction_Set_Input>;
}>;

export type UpdateQaTaskActionByIdMutation = { __typename?: 'mutation_root' } & {
  update_qaTaskAction?: Maybe<
    { __typename?: 'qaTaskAction_mutation_response' } & Pick<QaTaskAction_Mutation_Response, 'affected_rows'> & {
        returning: Array<{ __typename?: 'qaTaskAction' } & QaTaskActionFragment>;
      }
  >;
};

export type UpdateQaTaskActionMutationVariables = Exact<{
  set?: Maybe<QaTaskAction_Set_Input>;
  where: QaTaskAction_Bool_Exp;
}>;

export type UpdateQaTaskActionMutation = { __typename?: 'mutation_root' } & {
  update_qaTaskAction?: Maybe<
    { __typename?: 'qaTaskAction_mutation_response' } & Pick<QaTaskAction_Mutation_Response, 'affected_rows'> & {
        returning: Array<{ __typename?: 'qaTaskAction' } & QaTaskActionFragment>;
      }
  >;
};

export type RemoveQaTaskActionModelByIdMutationVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
}>;

export type RemoveQaTaskActionModelByIdMutation = { __typename?: 'mutation_root' } & {
  delete_qaTaskAction?: Maybe<
    { __typename?: 'qaTaskAction_mutation_response' } & Pick<QaTaskAction_Mutation_Response, 'affected_rows'>
  >;
};

export type RemoveQaTaskActionModelMutationVariables = Exact<{
  where: QaTaskAction_Bool_Exp;
}>;

export type RemoveQaTaskActionModelMutation = { __typename?: 'mutation_root' } & {
  delete_qaTaskAction?: Maybe<
    { __typename?: 'qaTaskAction_mutation_response' } & Pick<QaTaskAction_Mutation_Response, 'affected_rows'>
  >;
};

export type QueryQaTaskByIdQueryVariables = Exact<{
  qaTaskId: Scalars['uuid'];
}>;

export type QueryQaTaskByIdQuery = { __typename?: 'query_root' } & {
  qaTask_by_pk?: Maybe<{ __typename?: 'qaTask' } & QaTaskFragment>;
};

export type QueryQaTaskObjectsQueryVariables = Exact<{
  distinct_on?: Maybe<Array<QaTask_Select_Column>>;
  where?: Maybe<QaTask_Bool_Exp>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<QaTask_Order_By>>;
}>;

export type QueryQaTaskObjectsQuery = { __typename?: 'query_root' } & {
  qaTask: Array<{ __typename?: 'qaTask' } & QaTaskFragment>;
};

export type SubscribeToQaTaskByIdSubscriptionVariables = Exact<{
  qaTaskId: Scalars['uuid'];
}>;

export type SubscribeToQaTaskByIdSubscription = { __typename?: 'subscription_root' } & {
  qaTask_by_pk?: Maybe<{ __typename?: 'qaTask' } & QaTaskFragment>;
};

export type SubscribeToQaTaskObjectsSubscriptionVariables = Exact<{
  distinct_on?: Maybe<Array<QaTask_Select_Column>>;
  where?: Maybe<QaTask_Bool_Exp>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<QaTask_Order_By>>;
}>;

export type SubscribeToQaTaskObjectsSubscription = { __typename?: 'subscription_root' } & {
  qaTask: Array<{ __typename?: 'qaTask' } & QaTaskFragment>;
};

export type InsertQaTaskMutationVariables = Exact<{
  objects: Array<QaTask_Insert_Input>;
}>;

export type InsertQaTaskMutation = { __typename?: 'mutation_root' } & {
  insert_qaTask?: Maybe<
    { __typename?: 'qaTask_mutation_response' } & Pick<QaTask_Mutation_Response, 'affected_rows'> & {
        returning: Array<{ __typename?: 'qaTask' } & QaTaskFragment>;
      }
  >;
};

export type InsertQaTaskWithOnConflictMutationVariables = Exact<{
  objects: Array<QaTask_Insert_Input>;
  onConflict?: Maybe<QaTask_On_Conflict>;
}>;

export type InsertQaTaskWithOnConflictMutation = { __typename?: 'mutation_root' } & {
  insert_qaTask?: Maybe<
    { __typename?: 'qaTask_mutation_response' } & Pick<QaTask_Mutation_Response, 'affected_rows'> & {
        returning: Array<{ __typename?: 'qaTask' } & QaTaskFragment>;
      }
  >;
};

export type UpdateQaTaskByIdMutationVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
  set?: Maybe<QaTask_Set_Input>;
}>;

export type UpdateQaTaskByIdMutation = { __typename?: 'mutation_root' } & {
  update_qaTask?: Maybe<
    { __typename?: 'qaTask_mutation_response' } & Pick<QaTask_Mutation_Response, 'affected_rows'> & {
        returning: Array<{ __typename?: 'qaTask' } & QaTaskFragment>;
      }
  >;
};

export type UpdateQaTaskMutationVariables = Exact<{
  set?: Maybe<QaTask_Set_Input>;
  where: QaTask_Bool_Exp;
}>;

export type UpdateQaTaskMutation = { __typename?: 'mutation_root' } & {
  update_qaTask?: Maybe<
    { __typename?: 'qaTask_mutation_response' } & Pick<QaTask_Mutation_Response, 'affected_rows'> & {
        returning: Array<{ __typename?: 'qaTask' } & QaTaskFragment>;
      }
  >;
};

export type RemoveQaTaskModelByIdMutationVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
}>;

export type RemoveQaTaskModelByIdMutation = { __typename?: 'mutation_root' } & {
  delete_qaTask?: Maybe<{ __typename?: 'qaTask_mutation_response' } & Pick<QaTask_Mutation_Response, 'affected_rows'>>;
};

export type RemoveQaTaskModelMutationVariables = Exact<{
  where: QaTask_Bool_Exp;
}>;

export type RemoveQaTaskModelMutation = { __typename?: 'mutation_root' } & {
  delete_qaTask?: Maybe<{ __typename?: 'qaTask_mutation_response' } & Pick<QaTask_Mutation_Response, 'affected_rows'>>;
};

export type QueryClientTerritoryByIdQueryVariables = Exact<{
  clientTerritoryId: Scalars['uuid'];
}>;

export type QueryClientTerritoryByIdQuery = { __typename?: 'query_root' } & {
  clientTerritory_by_pk?: Maybe<{ __typename?: 'clientTerritory' } & ClientTerritoryFragment>;
};

export type QueryClientTerritoryObjectsQueryVariables = Exact<{
  distinct_on?: Maybe<Array<ClientTerritory_Select_Column>>;
  where?: Maybe<ClientTerritory_Bool_Exp>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ClientTerritory_Order_By>>;
}>;

export type QueryClientTerritoryObjectsQuery = { __typename?: 'query_root' } & {
  clientTerritory: Array<{ __typename?: 'clientTerritory' } & ClientTerritoryFragment>;
};

export type SubscribeToClientTerritoryByIdSubscriptionVariables = Exact<{
  clientTerritoryId: Scalars['uuid'];
}>;

export type SubscribeToClientTerritoryByIdSubscription = { __typename?: 'subscription_root' } & {
  clientTerritory_by_pk?: Maybe<{ __typename?: 'clientTerritory' } & ClientTerritoryFragment>;
};

export type SubscribeToClientTerritoryObjectsSubscriptionVariables = Exact<{
  distinct_on?: Maybe<Array<ClientTerritory_Select_Column>>;
  where?: Maybe<ClientTerritory_Bool_Exp>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ClientTerritory_Order_By>>;
}>;

export type SubscribeToClientTerritoryObjectsSubscription = { __typename?: 'subscription_root' } & {
  clientTerritory: Array<{ __typename?: 'clientTerritory' } & ClientTerritoryFragment>;
};

export type InsertClientTerritoryMutationVariables = Exact<{
  objects: Array<ClientTerritory_Insert_Input>;
}>;

export type InsertClientTerritoryMutation = { __typename?: 'mutation_root' } & {
  insert_clientTerritory?: Maybe<
    { __typename?: 'clientTerritory_mutation_response' } & Pick<ClientTerritory_Mutation_Response, 'affected_rows'> & {
        returning: Array<{ __typename?: 'clientTerritory' } & ClientTerritoryFragment>;
      }
  >;
};

export type InsertClientTerritoryWithOnConflictMutationVariables = Exact<{
  objects: Array<ClientTerritory_Insert_Input>;
  onConflict?: Maybe<ClientTerritory_On_Conflict>;
}>;

export type InsertClientTerritoryWithOnConflictMutation = { __typename?: 'mutation_root' } & {
  insert_clientTerritory?: Maybe<
    { __typename?: 'clientTerritory_mutation_response' } & Pick<ClientTerritory_Mutation_Response, 'affected_rows'> & {
        returning: Array<{ __typename?: 'clientTerritory' } & ClientTerritoryFragment>;
      }
  >;
};

export type UpdateClientTerritoryByIdMutationVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
  set?: Maybe<ClientTerritory_Set_Input>;
}>;

export type UpdateClientTerritoryByIdMutation = { __typename?: 'mutation_root' } & {
  update_clientTerritory?: Maybe<
    { __typename?: 'clientTerritory_mutation_response' } & Pick<ClientTerritory_Mutation_Response, 'affected_rows'> & {
        returning: Array<{ __typename?: 'clientTerritory' } & ClientTerritoryFragment>;
      }
  >;
};

export type UpdateClientTerritoryMutationVariables = Exact<{
  set?: Maybe<ClientTerritory_Set_Input>;
  where: ClientTerritory_Bool_Exp;
}>;

export type UpdateClientTerritoryMutation = { __typename?: 'mutation_root' } & {
  update_clientTerritory?: Maybe<
    { __typename?: 'clientTerritory_mutation_response' } & Pick<ClientTerritory_Mutation_Response, 'affected_rows'> & {
        returning: Array<{ __typename?: 'clientTerritory' } & ClientTerritoryFragment>;
      }
  >;
};

export type RemoveClientTerritoryModelByIdMutationVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
}>;

export type RemoveClientTerritoryModelByIdMutation = { __typename?: 'mutation_root' } & {
  delete_clientTerritory?: Maybe<
    { __typename?: 'clientTerritory_mutation_response' } & Pick<ClientTerritory_Mutation_Response, 'affected_rows'>
  >;
};

export type RemoveClientTerritoryModelMutationVariables = Exact<{
  where: ClientTerritory_Bool_Exp;
}>;

export type RemoveClientTerritoryModelMutation = { __typename?: 'mutation_root' } & {
  delete_clientTerritory?: Maybe<
    { __typename?: 'clientTerritory_mutation_response' } & Pick<ClientTerritory_Mutation_Response, 'affected_rows'>
  >;
};

export type QueryUserByIdQueryVariables = Exact<{
  userId: Scalars['uuid'];
}>;

export type QueryUserByIdQuery = { __typename?: 'query_root' } & {
  user_by_pk?: Maybe<{ __typename?: 'user' } & UserFragment>;
};

export type QueryUserObjectsQueryVariables = Exact<{
  distinct_on?: Maybe<Array<User_Select_Column>>;
  where?: Maybe<User_Bool_Exp>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
}>;

export type QueryUserObjectsQuery = { __typename?: 'query_root' } & {
  user: Array<{ __typename?: 'user' } & UserFragment>;
};

export type SubscribeToUserByIdSubscriptionVariables = Exact<{
  userId: Scalars['uuid'];
}>;

export type SubscribeToUserByIdSubscription = { __typename?: 'subscription_root' } & {
  user_by_pk?: Maybe<{ __typename?: 'user' } & UserFragment>;
};

export type SubscribeToUserObjectsSubscriptionVariables = Exact<{
  distinct_on?: Maybe<Array<User_Select_Column>>;
  where?: Maybe<User_Bool_Exp>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
}>;

export type SubscribeToUserObjectsSubscription = { __typename?: 'subscription_root' } & {
  user: Array<{ __typename?: 'user' } & UserFragment>;
};

export type InsertUserMutationVariables = Exact<{
  objects: Array<User_Insert_Input>;
}>;

export type InsertUserMutation = { __typename?: 'mutation_root' } & {
  insert_user?: Maybe<
    { __typename?: 'user_mutation_response' } & Pick<User_Mutation_Response, 'affected_rows'> & {
        returning: Array<{ __typename?: 'user' } & UserFragment>;
      }
  >;
};

export type InsertUserWithOnConflictMutationVariables = Exact<{
  objects: Array<User_Insert_Input>;
  onConflict?: Maybe<User_On_Conflict>;
}>;

export type InsertUserWithOnConflictMutation = { __typename?: 'mutation_root' } & {
  insert_user?: Maybe<
    { __typename?: 'user_mutation_response' } & Pick<User_Mutation_Response, 'affected_rows'> & {
        returning: Array<{ __typename?: 'user' } & UserFragment>;
      }
  >;
};

export type UpdateUserByIdMutationVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
  set?: Maybe<User_Set_Input>;
}>;

export type UpdateUserByIdMutation = { __typename?: 'mutation_root' } & {
  update_user?: Maybe<
    { __typename?: 'user_mutation_response' } & Pick<User_Mutation_Response, 'affected_rows'> & {
        returning: Array<{ __typename?: 'user' } & UserFragment>;
      }
  >;
};

export type UpdateUserMutationVariables = Exact<{
  set?: Maybe<User_Set_Input>;
  where: User_Bool_Exp;
}>;

export type UpdateUserMutation = { __typename?: 'mutation_root' } & {
  update_user?: Maybe<
    { __typename?: 'user_mutation_response' } & Pick<User_Mutation_Response, 'affected_rows'> & {
        returning: Array<{ __typename?: 'user' } & UserFragment>;
      }
  >;
};

export type RemoveUserModelByIdMutationVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
}>;

export type RemoveUserModelByIdMutation = { __typename?: 'mutation_root' } & {
  delete_user?: Maybe<{ __typename?: 'user_mutation_response' } & Pick<User_Mutation_Response, 'affected_rows'>>;
};

export type RemoveUserModelMutationVariables = Exact<{
  where: User_Bool_Exp;
}>;

export type RemoveUserModelMutation = { __typename?: 'mutation_root' } & {
  delete_user?: Maybe<{ __typename?: 'user_mutation_response' } & Pick<User_Mutation_Response, 'affected_rows'>>;
};

export type QueryUserInviteByIdQueryVariables = Exact<{
  userInviteId: Scalars['uuid'];
}>;

export type QueryUserInviteByIdQuery = { __typename?: 'query_root' } & {
  userInvite_by_pk?: Maybe<{ __typename?: 'userInvite' } & UserInviteFragment>;
};

export type QueryUserInviteObjectsQueryVariables = Exact<{
  distinct_on?: Maybe<Array<UserInvite_Select_Column>>;
  where?: Maybe<UserInvite_Bool_Exp>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserInvite_Order_By>>;
}>;

export type QueryUserInviteObjectsQuery = { __typename?: 'query_root' } & {
  userInvite: Array<{ __typename?: 'userInvite' } & UserInviteFragment>;
};

export type SubscribeToUserInviteByIdSubscriptionVariables = Exact<{
  userInviteId: Scalars['uuid'];
}>;

export type SubscribeToUserInviteByIdSubscription = { __typename?: 'subscription_root' } & {
  userInvite_by_pk?: Maybe<{ __typename?: 'userInvite' } & UserInviteFragment>;
};

export type SubscribeToUserInviteObjectsSubscriptionVariables = Exact<{
  distinct_on?: Maybe<Array<UserInvite_Select_Column>>;
  where?: Maybe<UserInvite_Bool_Exp>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserInvite_Order_By>>;
}>;

export type SubscribeToUserInviteObjectsSubscription = { __typename?: 'subscription_root' } & {
  userInvite: Array<{ __typename?: 'userInvite' } & UserInviteFragment>;
};

export type InsertUserInviteMutationVariables = Exact<{
  objects: Array<UserInvite_Insert_Input>;
}>;

export type InsertUserInviteMutation = { __typename?: 'mutation_root' } & {
  insert_userInvite?: Maybe<
    { __typename?: 'userInvite_mutation_response' } & Pick<UserInvite_Mutation_Response, 'affected_rows'> & {
        returning: Array<{ __typename?: 'userInvite' } & UserInviteFragment>;
      }
  >;
};

export type InsertUserInviteWithOnConflictMutationVariables = Exact<{
  objects: Array<UserInvite_Insert_Input>;
  onConflict?: Maybe<UserInvite_On_Conflict>;
}>;

export type InsertUserInviteWithOnConflictMutation = { __typename?: 'mutation_root' } & {
  insert_userInvite?: Maybe<
    { __typename?: 'userInvite_mutation_response' } & Pick<UserInvite_Mutation_Response, 'affected_rows'> & {
        returning: Array<{ __typename?: 'userInvite' } & UserInviteFragment>;
      }
  >;
};

export type UpdateUserInviteByIdMutationVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
  set?: Maybe<UserInvite_Set_Input>;
}>;

export type UpdateUserInviteByIdMutation = { __typename?: 'mutation_root' } & {
  update_userInvite?: Maybe<
    { __typename?: 'userInvite_mutation_response' } & Pick<UserInvite_Mutation_Response, 'affected_rows'> & {
        returning: Array<{ __typename?: 'userInvite' } & UserInviteFragment>;
      }
  >;
};

export type UpdateUserInviteMutationVariables = Exact<{
  set?: Maybe<UserInvite_Set_Input>;
  where: UserInvite_Bool_Exp;
}>;

export type UpdateUserInviteMutation = { __typename?: 'mutation_root' } & {
  update_userInvite?: Maybe<
    { __typename?: 'userInvite_mutation_response' } & Pick<UserInvite_Mutation_Response, 'affected_rows'> & {
        returning: Array<{ __typename?: 'userInvite' } & UserInviteFragment>;
      }
  >;
};

export type RemoveUserInviteModelByIdMutationVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
}>;

export type RemoveUserInviteModelByIdMutation = { __typename?: 'mutation_root' } & {
  delete_userInvite?: Maybe<
    { __typename?: 'userInvite_mutation_response' } & Pick<UserInvite_Mutation_Response, 'affected_rows'>
  >;
};

export type RemoveUserInviteModelMutationVariables = Exact<{
  where: UserInvite_Bool_Exp;
}>;

export type RemoveUserInviteModelMutation = { __typename?: 'mutation_root' } & {
  delete_userInvite?: Maybe<
    { __typename?: 'userInvite_mutation_response' } & Pick<UserInvite_Mutation_Response, 'affected_rows'>
  >;
};

export type QueryQaQualificationByIdQueryVariables = Exact<{
  qaQualificationId: Scalars['uuid'];
}>;

export type QueryQaQualificationByIdQuery = { __typename?: 'query_root' } & {
  qaQualification_by_pk?: Maybe<{ __typename?: 'qaQualification' } & QaQualificationFragment>;
};

export type QueryQaQualificationObjectsQueryVariables = Exact<{
  distinct_on?: Maybe<Array<QaQualification_Select_Column>>;
  where?: Maybe<QaQualification_Bool_Exp>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<QaQualification_Order_By>>;
}>;

export type QueryQaQualificationObjectsQuery = { __typename?: 'query_root' } & {
  qaQualification: Array<{ __typename?: 'qaQualification' } & QaQualificationFragment>;
};

export type SubscribeToQaQualificationByIdSubscriptionVariables = Exact<{
  qaQualificationId: Scalars['uuid'];
}>;

export type SubscribeToQaQualificationByIdSubscription = { __typename?: 'subscription_root' } & {
  qaQualification_by_pk?: Maybe<{ __typename?: 'qaQualification' } & QaQualificationFragment>;
};

export type SubscribeToQaQualificationObjectsSubscriptionVariables = Exact<{
  distinct_on?: Maybe<Array<QaQualification_Select_Column>>;
  where?: Maybe<QaQualification_Bool_Exp>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<QaQualification_Order_By>>;
}>;

export type SubscribeToQaQualificationObjectsSubscription = { __typename?: 'subscription_root' } & {
  qaQualification: Array<{ __typename?: 'qaQualification' } & QaQualificationFragment>;
};

export type InsertQaQualificationMutationVariables = Exact<{
  objects: Array<QaQualification_Insert_Input>;
}>;

export type InsertQaQualificationMutation = { __typename?: 'mutation_root' } & {
  insert_qaQualification?: Maybe<
    { __typename?: 'qaQualification_mutation_response' } & Pick<QaQualification_Mutation_Response, 'affected_rows'> & {
        returning: Array<{ __typename?: 'qaQualification' } & QaQualificationFragment>;
      }
  >;
};

export type InsertQaQualificationWithOnConflictMutationVariables = Exact<{
  objects: Array<QaQualification_Insert_Input>;
  onConflict?: Maybe<QaQualification_On_Conflict>;
}>;

export type InsertQaQualificationWithOnConflictMutation = { __typename?: 'mutation_root' } & {
  insert_qaQualification?: Maybe<
    { __typename?: 'qaQualification_mutation_response' } & Pick<QaQualification_Mutation_Response, 'affected_rows'> & {
        returning: Array<{ __typename?: 'qaQualification' } & QaQualificationFragment>;
      }
  >;
};

export type UpdateQaQualificationByIdMutationVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
  set?: Maybe<QaQualification_Set_Input>;
}>;

export type UpdateQaQualificationByIdMutation = { __typename?: 'mutation_root' } & {
  update_qaQualification?: Maybe<
    { __typename?: 'qaQualification_mutation_response' } & Pick<QaQualification_Mutation_Response, 'affected_rows'> & {
        returning: Array<{ __typename?: 'qaQualification' } & QaQualificationFragment>;
      }
  >;
};

export type UpdateQaQualificationMutationVariables = Exact<{
  set?: Maybe<QaQualification_Set_Input>;
  where: QaQualification_Bool_Exp;
}>;

export type UpdateQaQualificationMutation = { __typename?: 'mutation_root' } & {
  update_qaQualification?: Maybe<
    { __typename?: 'qaQualification_mutation_response' } & Pick<QaQualification_Mutation_Response, 'affected_rows'> & {
        returning: Array<{ __typename?: 'qaQualification' } & QaQualificationFragment>;
      }
  >;
};

export type RemoveQaQualificationModelByIdMutationVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
}>;

export type RemoveQaQualificationModelByIdMutation = { __typename?: 'mutation_root' } & {
  delete_qaQualification?: Maybe<
    { __typename?: 'qaQualification_mutation_response' } & Pick<QaQualification_Mutation_Response, 'affected_rows'>
  >;
};

export type RemoveQaQualificationModelMutationVariables = Exact<{
  where: QaQualification_Bool_Exp;
}>;

export type RemoveQaQualificationModelMutation = { __typename?: 'mutation_root' } & {
  delete_qaQualification?: Maybe<
    { __typename?: 'qaQualification_mutation_response' } & Pick<QaQualification_Mutation_Response, 'affected_rows'>
  >;
};

export type CsvUploadFragment = { __typename?: 'csvUpload' } & Pick<
  CsvUpload,
  | 'id'
  | 'clientId'
  | 'fileName'
  | 'filePath'
  | 'mapping'
  | 'syncCompletedAt'
  | 'contactCount'
  | 'uploadedAt'
  | 'uploadedByUserId'
>;

export type HubspotContactListFragment = { __typename?: 'hubspotContactList' } & Pick<
  HubspotContactList,
  'id' | 'name' | 'lastSyncedAt' | 'contactCount' | 'hubspotIntegrationId' | 'listId' | 'clientId'
>;

export type HubspotContactListHubspotContactFragment = { __typename?: 'hubspotContactListHubspotContact' } & Pick<
  HubspotContactListHubspotContact,
  'id' | 'hubspotContactListId' | 'internalListId' | 'clientId' | 'hubspotId'
>;

export type HubspotContactPropertyFragment = { __typename?: 'hubspotContactProperty' } & Pick<
  HubspotContactProperty,
  'id' | 'hubspotIntegrationId' | 'name' | 'label' | 'lastSyncedAt' | 'clientId'
>;

export type SalesforceContactFieldFragment = { __typename?: 'salesforceContactField' } & Pick<
  SalesforceContactField,
  'id' | 'salesforceIntegrationId' | 'name' | 'label' | 'lastSyncedAt' | 'clientId'
>;

export type HubspotIntegrationFragment = { __typename?: 'hubspotIntegration' } & Pick<
  HubspotIntegration,
  'id' | 'clientId' | 'mapping'
>;

export type SalesforceIntegrationFragment = { __typename?: 'salesforceIntegration' } & Pick<
  SalesforceIntegration,
  'id' | 'clientId' | 'mapping'
>;

export type QaTaskActionFragment = { __typename?: 'qaTaskAction' } & Pick<
  QaTaskAction,
  | 'id'
  | 'qaTaskId'
  | 'qaUserId'
  | 'clientContactId'
  | 'actionType'
  | 'completedAt'
  | 'executionTime'
  | 'createdAt'
  | 'previousQaTaskActionId'
  | 'jobChangeStatus'
  | 'linkedinUrl'
  | 'linkedinUrlStatus'
  | 'updatedEmail'
  | 'updatedLinkedinJob'
  | 'notes'
  | 'previousLinkedinJob'
  | 'assessment'
>;

export type QaTaskFragment = { __typename?: 'qaTask' } & Pick<
  QaTask,
  | 'id'
  | 'assignedUserId'
  | 'clientContactId'
  | 'clientId'
  | 'completedAt'
  | 'dueAt'
  | 'createdAt'
  | 'updatedAt'
  | 'isEscalated'
  | 'lastFetchedAt'
  | 'priority'
  | 'status'
  | 'taskType'
  | 'previousQaTaskId'
> & {
    assignedUser?: Maybe<{ __typename?: 'user' } & Pick<User, 'id' | 'email' | 'firstName' | 'lastName'>>;
    clientContact?: Maybe<
      { __typename?: 'clientContact' } & Pick<
        ClientContact,
        | 'id'
        | 'firstName'
        | 'lastName'
        | 'fullName'
        | 'linkedinUrl'
        | 'linkedinId'
        | 'crmEmail'
        | 'crmLinkedinUrl'
        | 'crmCompanyName'
        | 'crmTitle'
        | 'createdAt'
        | 'serialId'
        | 'qaAssignedAt'
      >
    >;
    qaTaskActions: Array<{ __typename?: 'qaTaskAction' } & QaTaskActionFragment>;
  };

export type ClientTerritoryFragment = { __typename?: 'clientTerritory' } & Pick<
  ClientTerritory,
  'id' | 'clientId' | 'userId' | 'territoryId' | 'companySizeMin' | 'companySizeMax'
> & {
    territory: { __typename?: 'territory' } & Pick<
      Territory,
      | 'id'
      | 'placeId'
      | 'type'
      | 'country'
      | 'administrativeArea1'
      | 'administrativeArea2'
      | 'locality'
      | 'formattedAddress'
    >;
  };

export type UserFragment = { __typename?: 'user' } & Pick<
  User,
  | 'id'
  | 'firstName'
  | 'lastName'
  | 'clientId'
  | 'email'
  | 'role'
  | 'createdAt'
  | 'updatedAt'
  | 'jobChangeFilters'
  | 'clientPermission'
> & { client: { __typename?: 'client' } & Pick<Client, 'id' | 'name' | 'subscriptionStatus'> };

export type UserInviteFragment = { __typename?: 'userInvite' } & Pick<
  UserInvite,
  | 'id'
  | 'inviteeEmail'
  | 'inviteeClientPermission'
  | 'inviterUserName'
  | 'inviterClientName'
  | 'status'
  | 'expireAt'
  | 'createdAt'
>;

export type QaQualificationFragment = { __typename?: 'qaQualification' } & Pick<
  QaQualification,
  'id' | 'qualification' | 'userId' | 'createdAt'
>;

export const ClientContactFilterFragmentDoc = gql`
  fragment ClientContactFilter on clientContactFilter {
    id
    clientId
    fieldTable
    fieldName
    fieldOperator
    fieldValue
    fieldCrmType
    lastFilteredClientContactCount
    lastMonitoredClientContactLimit
    lastMonitoredAt
    createdAt
  }
`;
export const ClientContactJobChangeFragmentDoc = gql`
  fragment ClientContactJobChange on clientContactJobChange {
    id
    clientAction
    clientActionUpdatedAt
    status
    job {
      id
      companyName
      title
      startDate
      endDate
      startDateText
      endDateText
      duration
      companyId
    }
    ownerUserId
    salesforceAccountId
    ownerUser {
      firstName
      lastName
      email
    }
    createdAt
  }
`;
export const ClientContactExternalFragmentDoc = gql`
  fragment ClientContactExternal on clientContact {
    id
    firstName
    lastName
    fullName
    clientId
    crmEmail
    crmEmailIsValid
    crmEmailValidatedAt
    linkedinUrl
    linkedinId
    crmLinkedinUrl
    crmCompanyName
    crmTitle
    contactData {
      id
      linkedinId
      linkedinUrl
      currentJob {
        id
        companyName
        title
        startDate
        endDate
        startDateText
        endDateText
        duration
        companyId
        company {
          id
          employeeCount
          estimatedSize
          hqLocation
          websiteDomain
          websiteRootDomain
          websiteUrl
        }
      }
      jobs {
        id
        companyName
        title
        createdAt
        startDate
        endDate
        startDateText
        endDateText
        duration
        startDate
        endDate
        companyId
      }
      profilePhotoUrl
      contactEmails {
        id
        email
        isValid
        createdAt
      }
      updatedAt
    }
    hubspotId
    salesforceId
    currentJobChange {
      ...ClientContactJobChange
    }
    enrichmentHistories {
      clientContactId
      clientId
      enrichmentType
      crmType
      crmField
      previousValue
      updatedValue
      createdAt
    }
    ownerUserId
    ownerUser {
      firstName
      lastName
      email
    }
    updatedAt
    createdAt
  }
  ${ClientContactJobChangeFragmentDoc}
`;
export const ClientContactQaFragmentDoc = gql`
  fragment ClientContactQa on clientContact {
    id
    firstName
    lastName
    fullName
    linkedinUrl
    linkedinId
    crmLinkedinUrl
    crmCompanyName
    crmTitle
    createdAt
    serialId
    qaAssignedAt
    crmData {
      id
      fieldName
      fieldLabel
      fieldValue
      crmType
    }
  }
`;
export const CrmContactEnrichmentHistoryFragmentDoc = gql`
  fragment CrmContactEnrichmentHistory on crmContactEnrichmentHistory {
    id
    clientContactId
    clientId
    enrichmentType
    crmType
    crmField
    previousValue
    updatedValue
    createdAt
  }
`;
export const ClientContactCrmDataFragmentDoc = gql`
  fragment ClientContactCrmData on clientContactCrmData {
    id
    clientContactId
    clientId
    crmType
    fieldName
    fieldLabel
    fieldValue
  }
`;
export const ClientExternalFragmentDoc = gql`
  fragment ClientExternal on client {
    id
    name
    integrationType
    syncStatus
    ownerMappingStatus
    subscriptionStatus
    createdAt
    salesforceIntegration {
      id
      mapping
      totalContactCount
      contactsLastSyncedAt
      contactsLastSyncedCount
      accountsLastSyncedAt
      opportunitiesLastSyncedAt
      warmlyContactFieldsCreatedAt
    }
    hubspotIntegration {
      id
      mapping
      totalContactCount
      contactsLastSyncedAt
      contactsLastSyncedCount
      warmlyContactPropertiesCreatedAt
    }
    lastCsvUpload {
      id
      syncCompletedAt
      fileName
      contactCount
      uploadedAt
    }
  }
`;
export const ClientQaFragmentDoc = gql`
  fragment ClientQa on client {
    id
    codeName
    createdAt
    qaStatus
    qaStatusUpdatedAt
  }
`;
export const ClientInternalFragmentDoc = gql`
  fragment ClientInternal on client {
    id
    name
    codeName
    integrationType
    createdAt
    clientEmailReports {
      emailCategory
      sentAt
    }
    qaStatus
    qaStatusUpdatedAt
  }
`;
export const ClientSelectFragmentDoc = gql`
  fragment ClientSelect on client {
    id
    name
    codeName
  }
`;
export const ClientNotificationEmailFragmentDoc = gql`
  fragment ClientNotificationEmail on clientNotificationEmail {
    id
    email
    clientId
    createdAt
  }
`;
export const CloudTaskFragmentDoc = gql`
  fragment CloudTask on cloudTask {
    id
    createdByUser {
      firstName
      lastName
      email
    }
    queueName
    description
    status
    createdAt
    updatedAt
    executionTime
    taskIdentifier
  }
`;
export const ClientEmailReportFragmentDoc = gql`
  fragment ClientEmailReport on clientEmailReport {
    id
    clientId
    sentAt
    json
    emailCategory
    emailHistoryId
  }
`;
export const EmailHistoryFragmentDoc = gql`
  fragment EmailHistory on emailHistory {
    id
    firestoreId
    emailService
    emailServiceMessageId
    category
    status
    statusUpdatedAt
    description
    email
    clientId
    createdAt
  }
`;
export const SendgridEventFragmentDoc = gql`
  fragment SendgridEvent on sendgridEvent {
    id
    clientId
    email
    emailHistoryId
    event
    eventJson
    sgMessageId
    sgEventId
    sgTemplateId
    sgTemplateName
    createdAt
  }
`;
export const CsvUploadFragmentDoc = gql`
  fragment CsvUpload on csvUpload {
    id
    clientId
    fileName
    filePath
    mapping
    syncCompletedAt
    contactCount
    uploadedAt
    uploadedByUserId
  }
`;
export const HubspotContactListFragmentDoc = gql`
  fragment HubspotContactList on hubspotContactList {
    id
    name
    lastSyncedAt
    contactCount
    hubspotIntegrationId
    listId
    clientId
  }
`;
export const HubspotContactListHubspotContactFragmentDoc = gql`
  fragment HubspotContactListHubspotContact on hubspotContactListHubspotContact {
    id
    hubspotContactListId
    internalListId
    clientId
    hubspotId
  }
`;
export const HubspotContactPropertyFragmentDoc = gql`
  fragment HubspotContactProperty on hubspotContactProperty {
    id
    hubspotIntegrationId
    name
    label
    lastSyncedAt
    clientId
  }
`;
export const SalesforceContactFieldFragmentDoc = gql`
  fragment SalesforceContactField on salesforceContactField {
    id
    salesforceIntegrationId
    name
    label
    lastSyncedAt
    clientId
  }
`;
export const HubspotIntegrationFragmentDoc = gql`
  fragment HubspotIntegration on hubspotIntegration {
    id
    clientId
    mapping
  }
`;
export const SalesforceIntegrationFragmentDoc = gql`
  fragment SalesforceIntegration on salesforceIntegration {
    id
    clientId
    mapping
  }
`;
export const QaTaskActionFragmentDoc = gql`
  fragment QaTaskAction on qaTaskAction {
    id
    qaTaskId
    qaUserId
    clientContactId
    actionType
    completedAt
    executionTime
    createdAt
    previousQaTaskActionId
    jobChangeStatus
    linkedinUrl
    linkedinUrlStatus
    updatedEmail
    updatedLinkedinJob
    notes
    previousLinkedinJob
    assessment
  }
`;
export const QaTaskFragmentDoc = gql`
  fragment QaTask on qaTask {
    id
    assignedUserId
    assignedUser {
      id
      email
      firstName
      lastName
    }
    clientContactId
    clientContact {
      id
      firstName
      lastName
      fullName
      linkedinUrl
      linkedinId
      crmEmail
      crmLinkedinUrl
      crmCompanyName
      crmTitle
      createdAt
      serialId
      qaAssignedAt
    }
    clientId
    completedAt
    dueAt
    createdAt
    updatedAt
    isEscalated
    lastFetchedAt
    priority
    status
    taskType
    previousQaTaskId
    qaTaskActions {
      ...QaTaskAction
    }
  }
  ${QaTaskActionFragmentDoc}
`;
export const ClientTerritoryFragmentDoc = gql`
  fragment ClientTerritory on clientTerritory {
    id
    clientId
    userId
    territoryId
    companySizeMin
    companySizeMax
    territory {
      id
      placeId
      type
      country
      administrativeArea1
      administrativeArea2
      locality
      formattedAddress
    }
  }
`;
export const UserFragmentDoc = gql`
  fragment User on user {
    id
    firstName
    lastName
    clientId
    email
    role
    createdAt
    updatedAt
    jobChangeFilters
    client {
      id
      name
      subscriptionStatus
    }
    clientPermission
  }
`;
export const UserInviteFragmentDoc = gql`
  fragment UserInvite on userInvite {
    id
    inviteeEmail
    inviteeClientPermission
    inviterUserName
    inviterClientName
    status
    expireAt
    createdAt
  }
`;
export const QaQualificationFragmentDoc = gql`
  fragment QaQualification on qaQualification {
    id
    qualification
    userId
    createdAt
  }
`;
export const QueryClientContactCountDocument = gql`
  query queryClientContactCount($where: clientContact_bool_exp!) {
    clientContact_aggregate(where: $where) {
      aggregate {
        totalCount: count
      }
    }
  }
`;

/**
 * __useQueryClientContactCountQuery__
 *
 * To run a query within a React component, call `useQueryClientContactCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryClientContactCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryClientContactCountQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useQueryClientContactCountQuery(
  baseOptions: Apollo.QueryHookOptions<QueryClientContactCountQuery, QueryClientContactCountQueryVariables>
) {
  return Apollo.useQuery<QueryClientContactCountQuery, QueryClientContactCountQueryVariables>(
    QueryClientContactCountDocument,
    baseOptions
  );
}
export function useQueryClientContactCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryClientContactCountQuery, QueryClientContactCountQueryVariables>
) {
  return Apollo.useLazyQuery<QueryClientContactCountQuery, QueryClientContactCountQueryVariables>(
    QueryClientContactCountDocument,
    baseOptions
  );
}
export type QueryClientContactCountQueryHookResult = ReturnType<typeof useQueryClientContactCountQuery>;
export type QueryClientContactCountLazyQueryHookResult = ReturnType<typeof useQueryClientContactCountLazyQuery>;
export type QueryClientContactCountQueryResult = Apollo.QueryResult<
  QueryClientContactCountQuery,
  QueryClientContactCountQueryVariables
>;
export const QueryClientsClientContactCountDocument = gql`
  query queryClientsClientContactCount($whereClient: client_bool_exp, $whereClientContact: clientContact_bool_exp) {
    client(where: $whereClient) {
      id
      name
      allClientContacts: clientContacts_aggregate {
        aggregate {
          totalCount: count
        }
      }
      clientContacts: clientContacts_aggregate(where: $whereClientContact) {
        aggregate {
          totalCount: count
        }
      }
    }
  }
`;

/**
 * __useQueryClientsClientContactCountQuery__
 *
 * To run a query within a React component, call `useQueryClientsClientContactCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryClientsClientContactCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryClientsClientContactCountQuery({
 *   variables: {
 *      whereClient: // value for 'whereClient'
 *      whereClientContact: // value for 'whereClientContact'
 *   },
 * });
 */
export function useQueryClientsClientContactCountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    QueryClientsClientContactCountQuery,
    QueryClientsClientContactCountQueryVariables
  >
) {
  return Apollo.useQuery<QueryClientsClientContactCountQuery, QueryClientsClientContactCountQueryVariables>(
    QueryClientsClientContactCountDocument,
    baseOptions
  );
}
export function useQueryClientsClientContactCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryClientsClientContactCountQuery,
    QueryClientsClientContactCountQueryVariables
  >
) {
  return Apollo.useLazyQuery<QueryClientsClientContactCountQuery, QueryClientsClientContactCountQueryVariables>(
    QueryClientsClientContactCountDocument,
    baseOptions
  );
}
export type QueryClientsClientContactCountQueryHookResult = ReturnType<typeof useQueryClientsClientContactCountQuery>;
export type QueryClientsClientContactCountLazyQueryHookResult = ReturnType<
  typeof useQueryClientsClientContactCountLazyQuery
>;
export type QueryClientsClientContactCountQueryResult = Apollo.QueryResult<
  QueryClientsClientContactCountQuery,
  QueryClientsClientContactCountQueryVariables
>;
export const QueryWarmCompanyDocument = gql`
  query queryWarmCompany($where: warmCompany_bool_exp!, $offset: Int, $limit: Int, $order_by: [warmCompany_order_by!]) {
    warmCompany(where: $where, offset: $offset, limit: $limit, order_by: $order_by) {
      clientContactCount
      clientId
      clientName
      companyId
      companyLinkedinUrl
      companyPrimaryName
      companyLogoUrl
    }
  }
`;

/**
 * __useQueryWarmCompanyQuery__
 *
 * To run a query within a React component, call `useQueryWarmCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryWarmCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryWarmCompanyQuery({
 *   variables: {
 *      where: // value for 'where'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useQueryWarmCompanyQuery(
  baseOptions: Apollo.QueryHookOptions<QueryWarmCompanyQuery, QueryWarmCompanyQueryVariables>
) {
  return Apollo.useQuery<QueryWarmCompanyQuery, QueryWarmCompanyQueryVariables>(QueryWarmCompanyDocument, baseOptions);
}
export function useQueryWarmCompanyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryWarmCompanyQuery, QueryWarmCompanyQueryVariables>
) {
  return Apollo.useLazyQuery<QueryWarmCompanyQuery, QueryWarmCompanyQueryVariables>(
    QueryWarmCompanyDocument,
    baseOptions
  );
}
export type QueryWarmCompanyQueryHookResult = ReturnType<typeof useQueryWarmCompanyQuery>;
export type QueryWarmCompanyLazyQueryHookResult = ReturnType<typeof useQueryWarmCompanyLazyQuery>;
export type QueryWarmCompanyQueryResult = Apollo.QueryResult<QueryWarmCompanyQuery, QueryWarmCompanyQueryVariables>;
export const QueryWarmCompanyAggDocument = gql`
  query queryWarmCompanyAgg($where: warmCompany_bool_exp!) {
    warmCompany_aggregate(where: $where) {
      aggregate {
        totalCount: count
      }
    }
  }
`;

/**
 * __useQueryWarmCompanyAggQuery__
 *
 * To run a query within a React component, call `useQueryWarmCompanyAggQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryWarmCompanyAggQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryWarmCompanyAggQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useQueryWarmCompanyAggQuery(
  baseOptions: Apollo.QueryHookOptions<QueryWarmCompanyAggQuery, QueryWarmCompanyAggQueryVariables>
) {
  return Apollo.useQuery<QueryWarmCompanyAggQuery, QueryWarmCompanyAggQueryVariables>(
    QueryWarmCompanyAggDocument,
    baseOptions
  );
}
export function useQueryWarmCompanyAggLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryWarmCompanyAggQuery, QueryWarmCompanyAggQueryVariables>
) {
  return Apollo.useLazyQuery<QueryWarmCompanyAggQuery, QueryWarmCompanyAggQueryVariables>(
    QueryWarmCompanyAggDocument,
    baseOptions
  );
}
export type QueryWarmCompanyAggQueryHookResult = ReturnType<typeof useQueryWarmCompanyAggQuery>;
export type QueryWarmCompanyAggLazyQueryHookResult = ReturnType<typeof useQueryWarmCompanyAggLazyQuery>;
export type QueryWarmCompanyAggQueryResult = Apollo.QueryResult<
  QueryWarmCompanyAggQuery,
  QueryWarmCompanyAggQueryVariables
>;
export const QueryClientContactFilterByIdDocument = gql`
  query queryClientContactFilterById($clientContactFilterId: uuid!) {
    clientContactFilter_by_pk(id: $clientContactFilterId) {
      ...ClientContactFilter
    }
  }
  ${ClientContactFilterFragmentDoc}
`;

/**
 * __useQueryClientContactFilterByIdQuery__
 *
 * To run a query within a React component, call `useQueryClientContactFilterByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryClientContactFilterByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryClientContactFilterByIdQuery({
 *   variables: {
 *      clientContactFilterId: // value for 'clientContactFilterId'
 *   },
 * });
 */
export function useQueryClientContactFilterByIdQuery(
  baseOptions: Apollo.QueryHookOptions<QueryClientContactFilterByIdQuery, QueryClientContactFilterByIdQueryVariables>
) {
  return Apollo.useQuery<QueryClientContactFilterByIdQuery, QueryClientContactFilterByIdQueryVariables>(
    QueryClientContactFilterByIdDocument,
    baseOptions
  );
}
export function useQueryClientContactFilterByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryClientContactFilterByIdQuery,
    QueryClientContactFilterByIdQueryVariables
  >
) {
  return Apollo.useLazyQuery<QueryClientContactFilterByIdQuery, QueryClientContactFilterByIdQueryVariables>(
    QueryClientContactFilterByIdDocument,
    baseOptions
  );
}
export type QueryClientContactFilterByIdQueryHookResult = ReturnType<typeof useQueryClientContactFilterByIdQuery>;
export type QueryClientContactFilterByIdLazyQueryHookResult = ReturnType<
  typeof useQueryClientContactFilterByIdLazyQuery
>;
export type QueryClientContactFilterByIdQueryResult = Apollo.QueryResult<
  QueryClientContactFilterByIdQuery,
  QueryClientContactFilterByIdQueryVariables
>;
export const QueryClientContactFilterObjectsDocument = gql`
  query queryClientContactFilterObjects(
    $distinct_on: [clientContactFilter_select_column!]
    $where: clientContactFilter_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [clientContactFilter_order_by!]
  ) {
    clientContactFilter(distinct_on: $distinct_on, where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      ...ClientContactFilter
    }
  }
  ${ClientContactFilterFragmentDoc}
`;

/**
 * __useQueryClientContactFilterObjectsQuery__
 *
 * To run a query within a React component, call `useQueryClientContactFilterObjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryClientContactFilterObjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryClientContactFilterObjectsQuery({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useQueryClientContactFilterObjectsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    QueryClientContactFilterObjectsQuery,
    QueryClientContactFilterObjectsQueryVariables
  >
) {
  return Apollo.useQuery<QueryClientContactFilterObjectsQuery, QueryClientContactFilterObjectsQueryVariables>(
    QueryClientContactFilterObjectsDocument,
    baseOptions
  );
}
export function useQueryClientContactFilterObjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryClientContactFilterObjectsQuery,
    QueryClientContactFilterObjectsQueryVariables
  >
) {
  return Apollo.useLazyQuery<QueryClientContactFilterObjectsQuery, QueryClientContactFilterObjectsQueryVariables>(
    QueryClientContactFilterObjectsDocument,
    baseOptions
  );
}
export type QueryClientContactFilterObjectsQueryHookResult = ReturnType<typeof useQueryClientContactFilterObjectsQuery>;
export type QueryClientContactFilterObjectsLazyQueryHookResult = ReturnType<
  typeof useQueryClientContactFilterObjectsLazyQuery
>;
export type QueryClientContactFilterObjectsQueryResult = Apollo.QueryResult<
  QueryClientContactFilterObjectsQuery,
  QueryClientContactFilterObjectsQueryVariables
>;
export const SubscribeToClientContactFilterByIdDocument = gql`
  subscription subscribeToClientContactFilterById($clientContactFilterId: uuid!) {
    clientContactFilter_by_pk(id: $clientContactFilterId) {
      ...ClientContactFilter
    }
  }
  ${ClientContactFilterFragmentDoc}
`;

/**
 * __useSubscribeToClientContactFilterByIdSubscription__
 *
 * To run a query within a React component, call `useSubscribeToClientContactFilterByIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToClientContactFilterByIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToClientContactFilterByIdSubscription({
 *   variables: {
 *      clientContactFilterId: // value for 'clientContactFilterId'
 *   },
 * });
 */
export function useSubscribeToClientContactFilterByIdSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    SubscribeToClientContactFilterByIdSubscription,
    SubscribeToClientContactFilterByIdSubscriptionVariables
  >
) {
  return Apollo.useSubscription<
    SubscribeToClientContactFilterByIdSubscription,
    SubscribeToClientContactFilterByIdSubscriptionVariables
  >(SubscribeToClientContactFilterByIdDocument, baseOptions);
}
export type SubscribeToClientContactFilterByIdSubscriptionHookResult = ReturnType<
  typeof useSubscribeToClientContactFilterByIdSubscription
>;
export type SubscribeToClientContactFilterByIdSubscriptionResult = Apollo.SubscriptionResult<SubscribeToClientContactFilterByIdSubscription>;
export const SubscribeToClientContactFilterObjectsDocument = gql`
  subscription subscribeToClientContactFilterObjects(
    $distinct_on: [clientContactFilter_select_column!]
    $where: clientContactFilter_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [clientContactFilter_order_by!]
  ) {
    clientContactFilter(distinct_on: $distinct_on, where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      ...ClientContactFilter
    }
  }
  ${ClientContactFilterFragmentDoc}
`;

/**
 * __useSubscribeToClientContactFilterObjectsSubscription__
 *
 * To run a query within a React component, call `useSubscribeToClientContactFilterObjectsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToClientContactFilterObjectsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToClientContactFilterObjectsSubscription({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useSubscribeToClientContactFilterObjectsSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    SubscribeToClientContactFilterObjectsSubscription,
    SubscribeToClientContactFilterObjectsSubscriptionVariables
  >
) {
  return Apollo.useSubscription<
    SubscribeToClientContactFilterObjectsSubscription,
    SubscribeToClientContactFilterObjectsSubscriptionVariables
  >(SubscribeToClientContactFilterObjectsDocument, baseOptions);
}
export type SubscribeToClientContactFilterObjectsSubscriptionHookResult = ReturnType<
  typeof useSubscribeToClientContactFilterObjectsSubscription
>;
export type SubscribeToClientContactFilterObjectsSubscriptionResult = Apollo.SubscriptionResult<SubscribeToClientContactFilterObjectsSubscription>;
export const InsertClientContactFilterDocument = gql`
  mutation insertClientContactFilter($objects: [clientContactFilter_insert_input!]!) {
    insert_clientContactFilter(objects: $objects) {
      affected_rows
      returning {
        ...ClientContactFilter
      }
    }
  }
  ${ClientContactFilterFragmentDoc}
`;
export type InsertClientContactFilterMutationFn = Apollo.MutationFunction<
  InsertClientContactFilterMutation,
  InsertClientContactFilterMutationVariables
>;

/**
 * __useInsertClientContactFilterMutation__
 *
 * To run a mutation, you first call `useInsertClientContactFilterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertClientContactFilterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertClientContactFilterMutation, { data, loading, error }] = useInsertClientContactFilterMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertClientContactFilterMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertClientContactFilterMutation,
    InsertClientContactFilterMutationVariables
  >
) {
  return Apollo.useMutation<InsertClientContactFilterMutation, InsertClientContactFilterMutationVariables>(
    InsertClientContactFilterDocument,
    baseOptions
  );
}
export type InsertClientContactFilterMutationHookResult = ReturnType<typeof useInsertClientContactFilterMutation>;
export type InsertClientContactFilterMutationResult = Apollo.MutationResult<InsertClientContactFilterMutation>;
export type InsertClientContactFilterMutationOptions = Apollo.BaseMutationOptions<
  InsertClientContactFilterMutation,
  InsertClientContactFilterMutationVariables
>;
export const InsertClientContactFilterWithOnConflictDocument = gql`
  mutation insertClientContactFilterWithOnConflict(
    $objects: [clientContactFilter_insert_input!]!
    $onConflict: clientContactFilter_on_conflict
  ) {
    insert_clientContactFilter(objects: $objects, on_conflict: $onConflict) {
      affected_rows
      returning {
        ...ClientContactFilter
      }
    }
  }
  ${ClientContactFilterFragmentDoc}
`;
export type InsertClientContactFilterWithOnConflictMutationFn = Apollo.MutationFunction<
  InsertClientContactFilterWithOnConflictMutation,
  InsertClientContactFilterWithOnConflictMutationVariables
>;

/**
 * __useInsertClientContactFilterWithOnConflictMutation__
 *
 * To run a mutation, you first call `useInsertClientContactFilterWithOnConflictMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertClientContactFilterWithOnConflictMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertClientContactFilterWithOnConflictMutation, { data, loading, error }] = useInsertClientContactFilterWithOnConflictMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *      onConflict: // value for 'onConflict'
 *   },
 * });
 */
export function useInsertClientContactFilterWithOnConflictMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertClientContactFilterWithOnConflictMutation,
    InsertClientContactFilterWithOnConflictMutationVariables
  >
) {
  return Apollo.useMutation<
    InsertClientContactFilterWithOnConflictMutation,
    InsertClientContactFilterWithOnConflictMutationVariables
  >(InsertClientContactFilterWithOnConflictDocument, baseOptions);
}
export type InsertClientContactFilterWithOnConflictMutationHookResult = ReturnType<
  typeof useInsertClientContactFilterWithOnConflictMutation
>;
export type InsertClientContactFilterWithOnConflictMutationResult = Apollo.MutationResult<InsertClientContactFilterWithOnConflictMutation>;
export type InsertClientContactFilterWithOnConflictMutationOptions = Apollo.BaseMutationOptions<
  InsertClientContactFilterWithOnConflictMutation,
  InsertClientContactFilterWithOnConflictMutationVariables
>;
export const UpdateClientContactFilterByIdDocument = gql`
  mutation updateClientContactFilterById($id: uuid, $set: clientContactFilter_set_input) {
    update_clientContactFilter(_set: $set, where: { id: { _eq: $id } }) {
      affected_rows
      returning {
        ...ClientContactFilter
      }
    }
  }
  ${ClientContactFilterFragmentDoc}
`;
export type UpdateClientContactFilterByIdMutationFn = Apollo.MutationFunction<
  UpdateClientContactFilterByIdMutation,
  UpdateClientContactFilterByIdMutationVariables
>;

/**
 * __useUpdateClientContactFilterByIdMutation__
 *
 * To run a mutation, you first call `useUpdateClientContactFilterByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientContactFilterByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientContactFilterByIdMutation, { data, loading, error }] = useUpdateClientContactFilterByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateClientContactFilterByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateClientContactFilterByIdMutation,
    UpdateClientContactFilterByIdMutationVariables
  >
) {
  return Apollo.useMutation<UpdateClientContactFilterByIdMutation, UpdateClientContactFilterByIdMutationVariables>(
    UpdateClientContactFilterByIdDocument,
    baseOptions
  );
}
export type UpdateClientContactFilterByIdMutationHookResult = ReturnType<
  typeof useUpdateClientContactFilterByIdMutation
>;
export type UpdateClientContactFilterByIdMutationResult = Apollo.MutationResult<UpdateClientContactFilterByIdMutation>;
export type UpdateClientContactFilterByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateClientContactFilterByIdMutation,
  UpdateClientContactFilterByIdMutationVariables
>;
export const UpdateClientContactFilterDocument = gql`
  mutation updateClientContactFilter($set: clientContactFilter_set_input, $where: clientContactFilter_bool_exp!) {
    update_clientContactFilter(_set: $set, where: $where) {
      affected_rows
      returning {
        ...ClientContactFilter
      }
    }
  }
  ${ClientContactFilterFragmentDoc}
`;
export type UpdateClientContactFilterMutationFn = Apollo.MutationFunction<
  UpdateClientContactFilterMutation,
  UpdateClientContactFilterMutationVariables
>;

/**
 * __useUpdateClientContactFilterMutation__
 *
 * To run a mutation, you first call `useUpdateClientContactFilterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientContactFilterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientContactFilterMutation, { data, loading, error }] = useUpdateClientContactFilterMutation({
 *   variables: {
 *      set: // value for 'set'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateClientContactFilterMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateClientContactFilterMutation,
    UpdateClientContactFilterMutationVariables
  >
) {
  return Apollo.useMutation<UpdateClientContactFilterMutation, UpdateClientContactFilterMutationVariables>(
    UpdateClientContactFilterDocument,
    baseOptions
  );
}
export type UpdateClientContactFilterMutationHookResult = ReturnType<typeof useUpdateClientContactFilterMutation>;
export type UpdateClientContactFilterMutationResult = Apollo.MutationResult<UpdateClientContactFilterMutation>;
export type UpdateClientContactFilterMutationOptions = Apollo.BaseMutationOptions<
  UpdateClientContactFilterMutation,
  UpdateClientContactFilterMutationVariables
>;
export const RemoveClientContactFilterModelByIdDocument = gql`
  mutation removeClientContactFilterModelById($id: uuid) {
    delete_clientContactFilter(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
export type RemoveClientContactFilterModelByIdMutationFn = Apollo.MutationFunction<
  RemoveClientContactFilterModelByIdMutation,
  RemoveClientContactFilterModelByIdMutationVariables
>;

/**
 * __useRemoveClientContactFilterModelByIdMutation__
 *
 * To run a mutation, you first call `useRemoveClientContactFilterModelByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveClientContactFilterModelByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeClientContactFilterModelByIdMutation, { data, loading, error }] = useRemoveClientContactFilterModelByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveClientContactFilterModelByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveClientContactFilterModelByIdMutation,
    RemoveClientContactFilterModelByIdMutationVariables
  >
) {
  return Apollo.useMutation<
    RemoveClientContactFilterModelByIdMutation,
    RemoveClientContactFilterModelByIdMutationVariables
  >(RemoveClientContactFilterModelByIdDocument, baseOptions);
}
export type RemoveClientContactFilterModelByIdMutationHookResult = ReturnType<
  typeof useRemoveClientContactFilterModelByIdMutation
>;
export type RemoveClientContactFilterModelByIdMutationResult = Apollo.MutationResult<RemoveClientContactFilterModelByIdMutation>;
export type RemoveClientContactFilterModelByIdMutationOptions = Apollo.BaseMutationOptions<
  RemoveClientContactFilterModelByIdMutation,
  RemoveClientContactFilterModelByIdMutationVariables
>;
export const RemoveClientContactFilterModelDocument = gql`
  mutation removeClientContactFilterModel($where: clientContactFilter_bool_exp!) {
    delete_clientContactFilter(where: $where) {
      affected_rows
    }
  }
`;
export type RemoveClientContactFilterModelMutationFn = Apollo.MutationFunction<
  RemoveClientContactFilterModelMutation,
  RemoveClientContactFilterModelMutationVariables
>;

/**
 * __useRemoveClientContactFilterModelMutation__
 *
 * To run a mutation, you first call `useRemoveClientContactFilterModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveClientContactFilterModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeClientContactFilterModelMutation, { data, loading, error }] = useRemoveClientContactFilterModelMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useRemoveClientContactFilterModelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveClientContactFilterModelMutation,
    RemoveClientContactFilterModelMutationVariables
  >
) {
  return Apollo.useMutation<RemoveClientContactFilterModelMutation, RemoveClientContactFilterModelMutationVariables>(
    RemoveClientContactFilterModelDocument,
    baseOptions
  );
}
export type RemoveClientContactFilterModelMutationHookResult = ReturnType<
  typeof useRemoveClientContactFilterModelMutation
>;
export type RemoveClientContactFilterModelMutationResult = Apollo.MutationResult<RemoveClientContactFilterModelMutation>;
export type RemoveClientContactFilterModelMutationOptions = Apollo.BaseMutationOptions<
  RemoveClientContactFilterModelMutation,
  RemoveClientContactFilterModelMutationVariables
>;
export const QueryClientContactJobChangeByIdDocument = gql`
  query queryClientContactJobChangeById($clientContactJobChangeId: uuid!) {
    clientContactJobChange_by_pk(id: $clientContactJobChangeId) {
      ...ClientContactJobChange
    }
  }
  ${ClientContactJobChangeFragmentDoc}
`;

/**
 * __useQueryClientContactJobChangeByIdQuery__
 *
 * To run a query within a React component, call `useQueryClientContactJobChangeByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryClientContactJobChangeByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryClientContactJobChangeByIdQuery({
 *   variables: {
 *      clientContactJobChangeId: // value for 'clientContactJobChangeId'
 *   },
 * });
 */
export function useQueryClientContactJobChangeByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryClientContactJobChangeByIdQuery,
    QueryClientContactJobChangeByIdQueryVariables
  >
) {
  return Apollo.useQuery<QueryClientContactJobChangeByIdQuery, QueryClientContactJobChangeByIdQueryVariables>(
    QueryClientContactJobChangeByIdDocument,
    baseOptions
  );
}
export function useQueryClientContactJobChangeByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryClientContactJobChangeByIdQuery,
    QueryClientContactJobChangeByIdQueryVariables
  >
) {
  return Apollo.useLazyQuery<QueryClientContactJobChangeByIdQuery, QueryClientContactJobChangeByIdQueryVariables>(
    QueryClientContactJobChangeByIdDocument,
    baseOptions
  );
}
export type QueryClientContactJobChangeByIdQueryHookResult = ReturnType<typeof useQueryClientContactJobChangeByIdQuery>;
export type QueryClientContactJobChangeByIdLazyQueryHookResult = ReturnType<
  typeof useQueryClientContactJobChangeByIdLazyQuery
>;
export type QueryClientContactJobChangeByIdQueryResult = Apollo.QueryResult<
  QueryClientContactJobChangeByIdQuery,
  QueryClientContactJobChangeByIdQueryVariables
>;
export const QueryClientContactJobChangeObjectsDocument = gql`
  query queryClientContactJobChangeObjects(
    $distinct_on: [clientContactJobChange_select_column!]
    $where: clientContactJobChange_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [clientContactJobChange_order_by!]
  ) {
    clientContactJobChange(
      distinct_on: $distinct_on
      where: $where
      limit: $limit
      offset: $offset
      order_by: $order_by
    ) {
      ...ClientContactJobChange
    }
  }
  ${ClientContactJobChangeFragmentDoc}
`;

/**
 * __useQueryClientContactJobChangeObjectsQuery__
 *
 * To run a query within a React component, call `useQueryClientContactJobChangeObjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryClientContactJobChangeObjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryClientContactJobChangeObjectsQuery({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useQueryClientContactJobChangeObjectsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    QueryClientContactJobChangeObjectsQuery,
    QueryClientContactJobChangeObjectsQueryVariables
  >
) {
  return Apollo.useQuery<QueryClientContactJobChangeObjectsQuery, QueryClientContactJobChangeObjectsQueryVariables>(
    QueryClientContactJobChangeObjectsDocument,
    baseOptions
  );
}
export function useQueryClientContactJobChangeObjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryClientContactJobChangeObjectsQuery,
    QueryClientContactJobChangeObjectsQueryVariables
  >
) {
  return Apollo.useLazyQuery<QueryClientContactJobChangeObjectsQuery, QueryClientContactJobChangeObjectsQueryVariables>(
    QueryClientContactJobChangeObjectsDocument,
    baseOptions
  );
}
export type QueryClientContactJobChangeObjectsQueryHookResult = ReturnType<
  typeof useQueryClientContactJobChangeObjectsQuery
>;
export type QueryClientContactJobChangeObjectsLazyQueryHookResult = ReturnType<
  typeof useQueryClientContactJobChangeObjectsLazyQuery
>;
export type QueryClientContactJobChangeObjectsQueryResult = Apollo.QueryResult<
  QueryClientContactJobChangeObjectsQuery,
  QueryClientContactJobChangeObjectsQueryVariables
>;
export const SubscribeToClientContactJobChangeByIdDocument = gql`
  subscription subscribeToClientContactJobChangeById($clientContactJobChangeId: uuid!) {
    clientContactJobChange_by_pk(id: $clientContactJobChangeId) {
      ...ClientContactJobChange
    }
  }
  ${ClientContactJobChangeFragmentDoc}
`;

/**
 * __useSubscribeToClientContactJobChangeByIdSubscription__
 *
 * To run a query within a React component, call `useSubscribeToClientContactJobChangeByIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToClientContactJobChangeByIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToClientContactJobChangeByIdSubscription({
 *   variables: {
 *      clientContactJobChangeId: // value for 'clientContactJobChangeId'
 *   },
 * });
 */
export function useSubscribeToClientContactJobChangeByIdSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    SubscribeToClientContactJobChangeByIdSubscription,
    SubscribeToClientContactJobChangeByIdSubscriptionVariables
  >
) {
  return Apollo.useSubscription<
    SubscribeToClientContactJobChangeByIdSubscription,
    SubscribeToClientContactJobChangeByIdSubscriptionVariables
  >(SubscribeToClientContactJobChangeByIdDocument, baseOptions);
}
export type SubscribeToClientContactJobChangeByIdSubscriptionHookResult = ReturnType<
  typeof useSubscribeToClientContactJobChangeByIdSubscription
>;
export type SubscribeToClientContactJobChangeByIdSubscriptionResult = Apollo.SubscriptionResult<SubscribeToClientContactJobChangeByIdSubscription>;
export const SubscribeToClientContactJobChangeObjectsDocument = gql`
  subscription subscribeToClientContactJobChangeObjects(
    $distinct_on: [clientContactJobChange_select_column!]
    $where: clientContactJobChange_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [clientContactJobChange_order_by!]
  ) {
    clientContactJobChange(
      distinct_on: $distinct_on
      where: $where
      limit: $limit
      offset: $offset
      order_by: $order_by
    ) {
      ...ClientContactJobChange
    }
  }
  ${ClientContactJobChangeFragmentDoc}
`;

/**
 * __useSubscribeToClientContactJobChangeObjectsSubscription__
 *
 * To run a query within a React component, call `useSubscribeToClientContactJobChangeObjectsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToClientContactJobChangeObjectsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToClientContactJobChangeObjectsSubscription({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useSubscribeToClientContactJobChangeObjectsSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    SubscribeToClientContactJobChangeObjectsSubscription,
    SubscribeToClientContactJobChangeObjectsSubscriptionVariables
  >
) {
  return Apollo.useSubscription<
    SubscribeToClientContactJobChangeObjectsSubscription,
    SubscribeToClientContactJobChangeObjectsSubscriptionVariables
  >(SubscribeToClientContactJobChangeObjectsDocument, baseOptions);
}
export type SubscribeToClientContactJobChangeObjectsSubscriptionHookResult = ReturnType<
  typeof useSubscribeToClientContactJobChangeObjectsSubscription
>;
export type SubscribeToClientContactJobChangeObjectsSubscriptionResult = Apollo.SubscriptionResult<SubscribeToClientContactJobChangeObjectsSubscription>;
export const InsertClientContactJobChangeDocument = gql`
  mutation insertClientContactJobChange($objects: [clientContactJobChange_insert_input!]!) {
    insert_clientContactJobChange(objects: $objects) {
      affected_rows
      returning {
        ...ClientContactJobChange
      }
    }
  }
  ${ClientContactJobChangeFragmentDoc}
`;
export type InsertClientContactJobChangeMutationFn = Apollo.MutationFunction<
  InsertClientContactJobChangeMutation,
  InsertClientContactJobChangeMutationVariables
>;

/**
 * __useInsertClientContactJobChangeMutation__
 *
 * To run a mutation, you first call `useInsertClientContactJobChangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertClientContactJobChangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertClientContactJobChangeMutation, { data, loading, error }] = useInsertClientContactJobChangeMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertClientContactJobChangeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertClientContactJobChangeMutation,
    InsertClientContactJobChangeMutationVariables
  >
) {
  return Apollo.useMutation<InsertClientContactJobChangeMutation, InsertClientContactJobChangeMutationVariables>(
    InsertClientContactJobChangeDocument,
    baseOptions
  );
}
export type InsertClientContactJobChangeMutationHookResult = ReturnType<typeof useInsertClientContactJobChangeMutation>;
export type InsertClientContactJobChangeMutationResult = Apollo.MutationResult<InsertClientContactJobChangeMutation>;
export type InsertClientContactJobChangeMutationOptions = Apollo.BaseMutationOptions<
  InsertClientContactJobChangeMutation,
  InsertClientContactJobChangeMutationVariables
>;
export const InsertClientContactJobChangeWithOnConflictDocument = gql`
  mutation insertClientContactJobChangeWithOnConflict(
    $objects: [clientContactJobChange_insert_input!]!
    $onConflict: clientContactJobChange_on_conflict
  ) {
    insert_clientContactJobChange(objects: $objects, on_conflict: $onConflict) {
      affected_rows
      returning {
        ...ClientContactJobChange
      }
    }
  }
  ${ClientContactJobChangeFragmentDoc}
`;
export type InsertClientContactJobChangeWithOnConflictMutationFn = Apollo.MutationFunction<
  InsertClientContactJobChangeWithOnConflictMutation,
  InsertClientContactJobChangeWithOnConflictMutationVariables
>;

/**
 * __useInsertClientContactJobChangeWithOnConflictMutation__
 *
 * To run a mutation, you first call `useInsertClientContactJobChangeWithOnConflictMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertClientContactJobChangeWithOnConflictMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertClientContactJobChangeWithOnConflictMutation, { data, loading, error }] = useInsertClientContactJobChangeWithOnConflictMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *      onConflict: // value for 'onConflict'
 *   },
 * });
 */
export function useInsertClientContactJobChangeWithOnConflictMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertClientContactJobChangeWithOnConflictMutation,
    InsertClientContactJobChangeWithOnConflictMutationVariables
  >
) {
  return Apollo.useMutation<
    InsertClientContactJobChangeWithOnConflictMutation,
    InsertClientContactJobChangeWithOnConflictMutationVariables
  >(InsertClientContactJobChangeWithOnConflictDocument, baseOptions);
}
export type InsertClientContactJobChangeWithOnConflictMutationHookResult = ReturnType<
  typeof useInsertClientContactJobChangeWithOnConflictMutation
>;
export type InsertClientContactJobChangeWithOnConflictMutationResult = Apollo.MutationResult<InsertClientContactJobChangeWithOnConflictMutation>;
export type InsertClientContactJobChangeWithOnConflictMutationOptions = Apollo.BaseMutationOptions<
  InsertClientContactJobChangeWithOnConflictMutation,
  InsertClientContactJobChangeWithOnConflictMutationVariables
>;
export const UpdateClientContactJobChangeByIdDocument = gql`
  mutation updateClientContactJobChangeById($id: uuid, $set: clientContactJobChange_set_input) {
    update_clientContactJobChange(_set: $set, where: { id: { _eq: $id } }) {
      affected_rows
      returning {
        ...ClientContactJobChange
      }
    }
  }
  ${ClientContactJobChangeFragmentDoc}
`;
export type UpdateClientContactJobChangeByIdMutationFn = Apollo.MutationFunction<
  UpdateClientContactJobChangeByIdMutation,
  UpdateClientContactJobChangeByIdMutationVariables
>;

/**
 * __useUpdateClientContactJobChangeByIdMutation__
 *
 * To run a mutation, you first call `useUpdateClientContactJobChangeByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientContactJobChangeByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientContactJobChangeByIdMutation, { data, loading, error }] = useUpdateClientContactJobChangeByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateClientContactJobChangeByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateClientContactJobChangeByIdMutation,
    UpdateClientContactJobChangeByIdMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateClientContactJobChangeByIdMutation,
    UpdateClientContactJobChangeByIdMutationVariables
  >(UpdateClientContactJobChangeByIdDocument, baseOptions);
}
export type UpdateClientContactJobChangeByIdMutationHookResult = ReturnType<
  typeof useUpdateClientContactJobChangeByIdMutation
>;
export type UpdateClientContactJobChangeByIdMutationResult = Apollo.MutationResult<UpdateClientContactJobChangeByIdMutation>;
export type UpdateClientContactJobChangeByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateClientContactJobChangeByIdMutation,
  UpdateClientContactJobChangeByIdMutationVariables
>;
export const UpdateClientContactJobChangeDocument = gql`
  mutation updateClientContactJobChange(
    $set: clientContactJobChange_set_input
    $where: clientContactJobChange_bool_exp!
  ) {
    update_clientContactJobChange(_set: $set, where: $where) {
      affected_rows
      returning {
        ...ClientContactJobChange
      }
    }
  }
  ${ClientContactJobChangeFragmentDoc}
`;
export type UpdateClientContactJobChangeMutationFn = Apollo.MutationFunction<
  UpdateClientContactJobChangeMutation,
  UpdateClientContactJobChangeMutationVariables
>;

/**
 * __useUpdateClientContactJobChangeMutation__
 *
 * To run a mutation, you first call `useUpdateClientContactJobChangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientContactJobChangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientContactJobChangeMutation, { data, loading, error }] = useUpdateClientContactJobChangeMutation({
 *   variables: {
 *      set: // value for 'set'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateClientContactJobChangeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateClientContactJobChangeMutation,
    UpdateClientContactJobChangeMutationVariables
  >
) {
  return Apollo.useMutation<UpdateClientContactJobChangeMutation, UpdateClientContactJobChangeMutationVariables>(
    UpdateClientContactJobChangeDocument,
    baseOptions
  );
}
export type UpdateClientContactJobChangeMutationHookResult = ReturnType<typeof useUpdateClientContactJobChangeMutation>;
export type UpdateClientContactJobChangeMutationResult = Apollo.MutationResult<UpdateClientContactJobChangeMutation>;
export type UpdateClientContactJobChangeMutationOptions = Apollo.BaseMutationOptions<
  UpdateClientContactJobChangeMutation,
  UpdateClientContactJobChangeMutationVariables
>;
export const RemoveClientContactJobChangeModelByIdDocument = gql`
  mutation removeClientContactJobChangeModelById($id: uuid) {
    delete_clientContactJobChange(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
export type RemoveClientContactJobChangeModelByIdMutationFn = Apollo.MutationFunction<
  RemoveClientContactJobChangeModelByIdMutation,
  RemoveClientContactJobChangeModelByIdMutationVariables
>;

/**
 * __useRemoveClientContactJobChangeModelByIdMutation__
 *
 * To run a mutation, you first call `useRemoveClientContactJobChangeModelByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveClientContactJobChangeModelByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeClientContactJobChangeModelByIdMutation, { data, loading, error }] = useRemoveClientContactJobChangeModelByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveClientContactJobChangeModelByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveClientContactJobChangeModelByIdMutation,
    RemoveClientContactJobChangeModelByIdMutationVariables
  >
) {
  return Apollo.useMutation<
    RemoveClientContactJobChangeModelByIdMutation,
    RemoveClientContactJobChangeModelByIdMutationVariables
  >(RemoveClientContactJobChangeModelByIdDocument, baseOptions);
}
export type RemoveClientContactJobChangeModelByIdMutationHookResult = ReturnType<
  typeof useRemoveClientContactJobChangeModelByIdMutation
>;
export type RemoveClientContactJobChangeModelByIdMutationResult = Apollo.MutationResult<RemoveClientContactJobChangeModelByIdMutation>;
export type RemoveClientContactJobChangeModelByIdMutationOptions = Apollo.BaseMutationOptions<
  RemoveClientContactJobChangeModelByIdMutation,
  RemoveClientContactJobChangeModelByIdMutationVariables
>;
export const RemoveClientContactJobChangeModelDocument = gql`
  mutation removeClientContactJobChangeModel($where: clientContactJobChange_bool_exp!) {
    delete_clientContactJobChange(where: $where) {
      affected_rows
    }
  }
`;
export type RemoveClientContactJobChangeModelMutationFn = Apollo.MutationFunction<
  RemoveClientContactJobChangeModelMutation,
  RemoveClientContactJobChangeModelMutationVariables
>;

/**
 * __useRemoveClientContactJobChangeModelMutation__
 *
 * To run a mutation, you first call `useRemoveClientContactJobChangeModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveClientContactJobChangeModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeClientContactJobChangeModelMutation, { data, loading, error }] = useRemoveClientContactJobChangeModelMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useRemoveClientContactJobChangeModelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveClientContactJobChangeModelMutation,
    RemoveClientContactJobChangeModelMutationVariables
  >
) {
  return Apollo.useMutation<
    RemoveClientContactJobChangeModelMutation,
    RemoveClientContactJobChangeModelMutationVariables
  >(RemoveClientContactJobChangeModelDocument, baseOptions);
}
export type RemoveClientContactJobChangeModelMutationHookResult = ReturnType<
  typeof useRemoveClientContactJobChangeModelMutation
>;
export type RemoveClientContactJobChangeModelMutationResult = Apollo.MutationResult<RemoveClientContactJobChangeModelMutation>;
export type RemoveClientContactJobChangeModelMutationOptions = Apollo.BaseMutationOptions<
  RemoveClientContactJobChangeModelMutation,
  RemoveClientContactJobChangeModelMutationVariables
>;
export const QueryClientContactExternalByIdDocument = gql`
  query queryClientContactExternalById($clientContactId: uuid!) {
    clientContact_by_pk(id: $clientContactId) {
      ...ClientContactExternal
    }
  }
  ${ClientContactExternalFragmentDoc}
`;

/**
 * __useQueryClientContactExternalByIdQuery__
 *
 * To run a query within a React component, call `useQueryClientContactExternalByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryClientContactExternalByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryClientContactExternalByIdQuery({
 *   variables: {
 *      clientContactId: // value for 'clientContactId'
 *   },
 * });
 */
export function useQueryClientContactExternalByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryClientContactExternalByIdQuery,
    QueryClientContactExternalByIdQueryVariables
  >
) {
  return Apollo.useQuery<QueryClientContactExternalByIdQuery, QueryClientContactExternalByIdQueryVariables>(
    QueryClientContactExternalByIdDocument,
    baseOptions
  );
}
export function useQueryClientContactExternalByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryClientContactExternalByIdQuery,
    QueryClientContactExternalByIdQueryVariables
  >
) {
  return Apollo.useLazyQuery<QueryClientContactExternalByIdQuery, QueryClientContactExternalByIdQueryVariables>(
    QueryClientContactExternalByIdDocument,
    baseOptions
  );
}
export type QueryClientContactExternalByIdQueryHookResult = ReturnType<typeof useQueryClientContactExternalByIdQuery>;
export type QueryClientContactExternalByIdLazyQueryHookResult = ReturnType<
  typeof useQueryClientContactExternalByIdLazyQuery
>;
export type QueryClientContactExternalByIdQueryResult = Apollo.QueryResult<
  QueryClientContactExternalByIdQuery,
  QueryClientContactExternalByIdQueryVariables
>;
export const QueryClientContactExternalObjectsDocument = gql`
  query queryClientContactExternalObjects(
    $distinct_on: [clientContact_select_column!]
    $where: clientContact_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [clientContact_order_by!]
  ) {
    clientContact(distinct_on: $distinct_on, where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      ...ClientContactExternal
    }
  }
  ${ClientContactExternalFragmentDoc}
`;

/**
 * __useQueryClientContactExternalObjectsQuery__
 *
 * To run a query within a React component, call `useQueryClientContactExternalObjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryClientContactExternalObjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryClientContactExternalObjectsQuery({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useQueryClientContactExternalObjectsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    QueryClientContactExternalObjectsQuery,
    QueryClientContactExternalObjectsQueryVariables
  >
) {
  return Apollo.useQuery<QueryClientContactExternalObjectsQuery, QueryClientContactExternalObjectsQueryVariables>(
    QueryClientContactExternalObjectsDocument,
    baseOptions
  );
}
export function useQueryClientContactExternalObjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryClientContactExternalObjectsQuery,
    QueryClientContactExternalObjectsQueryVariables
  >
) {
  return Apollo.useLazyQuery<QueryClientContactExternalObjectsQuery, QueryClientContactExternalObjectsQueryVariables>(
    QueryClientContactExternalObjectsDocument,
    baseOptions
  );
}
export type QueryClientContactExternalObjectsQueryHookResult = ReturnType<
  typeof useQueryClientContactExternalObjectsQuery
>;
export type QueryClientContactExternalObjectsLazyQueryHookResult = ReturnType<
  typeof useQueryClientContactExternalObjectsLazyQuery
>;
export type QueryClientContactExternalObjectsQueryResult = Apollo.QueryResult<
  QueryClientContactExternalObjectsQuery,
  QueryClientContactExternalObjectsQueryVariables
>;
export const SubscribeToClientContactExternalByIdDocument = gql`
  subscription subscribeToClientContactExternalById($clientContactId: uuid!) {
    clientContact_by_pk(id: $clientContactId) {
      ...ClientContactExternal
    }
  }
  ${ClientContactExternalFragmentDoc}
`;

/**
 * __useSubscribeToClientContactExternalByIdSubscription__
 *
 * To run a query within a React component, call `useSubscribeToClientContactExternalByIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToClientContactExternalByIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToClientContactExternalByIdSubscription({
 *   variables: {
 *      clientContactId: // value for 'clientContactId'
 *   },
 * });
 */
export function useSubscribeToClientContactExternalByIdSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    SubscribeToClientContactExternalByIdSubscription,
    SubscribeToClientContactExternalByIdSubscriptionVariables
  >
) {
  return Apollo.useSubscription<
    SubscribeToClientContactExternalByIdSubscription,
    SubscribeToClientContactExternalByIdSubscriptionVariables
  >(SubscribeToClientContactExternalByIdDocument, baseOptions);
}
export type SubscribeToClientContactExternalByIdSubscriptionHookResult = ReturnType<
  typeof useSubscribeToClientContactExternalByIdSubscription
>;
export type SubscribeToClientContactExternalByIdSubscriptionResult = Apollo.SubscriptionResult<SubscribeToClientContactExternalByIdSubscription>;
export const SubscribeToClientContactExternalObjectsDocument = gql`
  subscription subscribeToClientContactExternalObjects(
    $distinct_on: [clientContact_select_column!]
    $where: clientContact_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [clientContact_order_by!]
  ) {
    clientContact(distinct_on: $distinct_on, where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      ...ClientContactExternal
    }
  }
  ${ClientContactExternalFragmentDoc}
`;

/**
 * __useSubscribeToClientContactExternalObjectsSubscription__
 *
 * To run a query within a React component, call `useSubscribeToClientContactExternalObjectsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToClientContactExternalObjectsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToClientContactExternalObjectsSubscription({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useSubscribeToClientContactExternalObjectsSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    SubscribeToClientContactExternalObjectsSubscription,
    SubscribeToClientContactExternalObjectsSubscriptionVariables
  >
) {
  return Apollo.useSubscription<
    SubscribeToClientContactExternalObjectsSubscription,
    SubscribeToClientContactExternalObjectsSubscriptionVariables
  >(SubscribeToClientContactExternalObjectsDocument, baseOptions);
}
export type SubscribeToClientContactExternalObjectsSubscriptionHookResult = ReturnType<
  typeof useSubscribeToClientContactExternalObjectsSubscription
>;
export type SubscribeToClientContactExternalObjectsSubscriptionResult = Apollo.SubscriptionResult<SubscribeToClientContactExternalObjectsSubscription>;
export const InsertClientContactExternalDocument = gql`
  mutation insertClientContactExternal($objects: [clientContact_insert_input!]!) {
    insert_clientContact(objects: $objects) {
      affected_rows
      returning {
        ...ClientContactExternal
      }
    }
  }
  ${ClientContactExternalFragmentDoc}
`;
export type InsertClientContactExternalMutationFn = Apollo.MutationFunction<
  InsertClientContactExternalMutation,
  InsertClientContactExternalMutationVariables
>;

/**
 * __useInsertClientContactExternalMutation__
 *
 * To run a mutation, you first call `useInsertClientContactExternalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertClientContactExternalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertClientContactExternalMutation, { data, loading, error }] = useInsertClientContactExternalMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertClientContactExternalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertClientContactExternalMutation,
    InsertClientContactExternalMutationVariables
  >
) {
  return Apollo.useMutation<InsertClientContactExternalMutation, InsertClientContactExternalMutationVariables>(
    InsertClientContactExternalDocument,
    baseOptions
  );
}
export type InsertClientContactExternalMutationHookResult = ReturnType<typeof useInsertClientContactExternalMutation>;
export type InsertClientContactExternalMutationResult = Apollo.MutationResult<InsertClientContactExternalMutation>;
export type InsertClientContactExternalMutationOptions = Apollo.BaseMutationOptions<
  InsertClientContactExternalMutation,
  InsertClientContactExternalMutationVariables
>;
export const InsertClientContactExternalWithOnConflictDocument = gql`
  mutation insertClientContactExternalWithOnConflict(
    $objects: [clientContact_insert_input!]!
    $onConflict: clientContact_on_conflict
  ) {
    insert_clientContact(objects: $objects, on_conflict: $onConflict) {
      affected_rows
      returning {
        ...ClientContactExternal
      }
    }
  }
  ${ClientContactExternalFragmentDoc}
`;
export type InsertClientContactExternalWithOnConflictMutationFn = Apollo.MutationFunction<
  InsertClientContactExternalWithOnConflictMutation,
  InsertClientContactExternalWithOnConflictMutationVariables
>;

/**
 * __useInsertClientContactExternalWithOnConflictMutation__
 *
 * To run a mutation, you first call `useInsertClientContactExternalWithOnConflictMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertClientContactExternalWithOnConflictMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertClientContactExternalWithOnConflictMutation, { data, loading, error }] = useInsertClientContactExternalWithOnConflictMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *      onConflict: // value for 'onConflict'
 *   },
 * });
 */
export function useInsertClientContactExternalWithOnConflictMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertClientContactExternalWithOnConflictMutation,
    InsertClientContactExternalWithOnConflictMutationVariables
  >
) {
  return Apollo.useMutation<
    InsertClientContactExternalWithOnConflictMutation,
    InsertClientContactExternalWithOnConflictMutationVariables
  >(InsertClientContactExternalWithOnConflictDocument, baseOptions);
}
export type InsertClientContactExternalWithOnConflictMutationHookResult = ReturnType<
  typeof useInsertClientContactExternalWithOnConflictMutation
>;
export type InsertClientContactExternalWithOnConflictMutationResult = Apollo.MutationResult<InsertClientContactExternalWithOnConflictMutation>;
export type InsertClientContactExternalWithOnConflictMutationOptions = Apollo.BaseMutationOptions<
  InsertClientContactExternalWithOnConflictMutation,
  InsertClientContactExternalWithOnConflictMutationVariables
>;
export const UpdateClientContactExternalByIdDocument = gql`
  mutation updateClientContactExternalById($id: uuid, $set: clientContact_set_input) {
    update_clientContact(_set: $set, where: { id: { _eq: $id } }) {
      affected_rows
      returning {
        ...ClientContactExternal
      }
    }
  }
  ${ClientContactExternalFragmentDoc}
`;
export type UpdateClientContactExternalByIdMutationFn = Apollo.MutationFunction<
  UpdateClientContactExternalByIdMutation,
  UpdateClientContactExternalByIdMutationVariables
>;

/**
 * __useUpdateClientContactExternalByIdMutation__
 *
 * To run a mutation, you first call `useUpdateClientContactExternalByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientContactExternalByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientContactExternalByIdMutation, { data, loading, error }] = useUpdateClientContactExternalByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateClientContactExternalByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateClientContactExternalByIdMutation,
    UpdateClientContactExternalByIdMutationVariables
  >
) {
  return Apollo.useMutation<UpdateClientContactExternalByIdMutation, UpdateClientContactExternalByIdMutationVariables>(
    UpdateClientContactExternalByIdDocument,
    baseOptions
  );
}
export type UpdateClientContactExternalByIdMutationHookResult = ReturnType<
  typeof useUpdateClientContactExternalByIdMutation
>;
export type UpdateClientContactExternalByIdMutationResult = Apollo.MutationResult<UpdateClientContactExternalByIdMutation>;
export type UpdateClientContactExternalByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateClientContactExternalByIdMutation,
  UpdateClientContactExternalByIdMutationVariables
>;
export const UpdateClientContactExternalDocument = gql`
  mutation updateClientContactExternal($set: clientContact_set_input, $where: clientContact_bool_exp!) {
    update_clientContact(_set: $set, where: $where) {
      affected_rows
      returning {
        ...ClientContactExternal
      }
    }
  }
  ${ClientContactExternalFragmentDoc}
`;
export type UpdateClientContactExternalMutationFn = Apollo.MutationFunction<
  UpdateClientContactExternalMutation,
  UpdateClientContactExternalMutationVariables
>;

/**
 * __useUpdateClientContactExternalMutation__
 *
 * To run a mutation, you first call `useUpdateClientContactExternalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientContactExternalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientContactExternalMutation, { data, loading, error }] = useUpdateClientContactExternalMutation({
 *   variables: {
 *      set: // value for 'set'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateClientContactExternalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateClientContactExternalMutation,
    UpdateClientContactExternalMutationVariables
  >
) {
  return Apollo.useMutation<UpdateClientContactExternalMutation, UpdateClientContactExternalMutationVariables>(
    UpdateClientContactExternalDocument,
    baseOptions
  );
}
export type UpdateClientContactExternalMutationHookResult = ReturnType<typeof useUpdateClientContactExternalMutation>;
export type UpdateClientContactExternalMutationResult = Apollo.MutationResult<UpdateClientContactExternalMutation>;
export type UpdateClientContactExternalMutationOptions = Apollo.BaseMutationOptions<
  UpdateClientContactExternalMutation,
  UpdateClientContactExternalMutationVariables
>;
export const RemoveClientContactModelByIdDocument = gql`
  mutation removeClientContactModelById($id: uuid) {
    delete_clientContact(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
export type RemoveClientContactModelByIdMutationFn = Apollo.MutationFunction<
  RemoveClientContactModelByIdMutation,
  RemoveClientContactModelByIdMutationVariables
>;

/**
 * __useRemoveClientContactModelByIdMutation__
 *
 * To run a mutation, you first call `useRemoveClientContactModelByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveClientContactModelByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeClientContactModelByIdMutation, { data, loading, error }] = useRemoveClientContactModelByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveClientContactModelByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveClientContactModelByIdMutation,
    RemoveClientContactModelByIdMutationVariables
  >
) {
  return Apollo.useMutation<RemoveClientContactModelByIdMutation, RemoveClientContactModelByIdMutationVariables>(
    RemoveClientContactModelByIdDocument,
    baseOptions
  );
}
export type RemoveClientContactModelByIdMutationHookResult = ReturnType<typeof useRemoveClientContactModelByIdMutation>;
export type RemoveClientContactModelByIdMutationResult = Apollo.MutationResult<RemoveClientContactModelByIdMutation>;
export type RemoveClientContactModelByIdMutationOptions = Apollo.BaseMutationOptions<
  RemoveClientContactModelByIdMutation,
  RemoveClientContactModelByIdMutationVariables
>;
export const RemoveClientContactModelDocument = gql`
  mutation removeClientContactModel($where: clientContact_bool_exp!) {
    delete_clientContact(where: $where) {
      affected_rows
    }
  }
`;
export type RemoveClientContactModelMutationFn = Apollo.MutationFunction<
  RemoveClientContactModelMutation,
  RemoveClientContactModelMutationVariables
>;

/**
 * __useRemoveClientContactModelMutation__
 *
 * To run a mutation, you first call `useRemoveClientContactModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveClientContactModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeClientContactModelMutation, { data, loading, error }] = useRemoveClientContactModelMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useRemoveClientContactModelMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveClientContactModelMutation, RemoveClientContactModelMutationVariables>
) {
  return Apollo.useMutation<RemoveClientContactModelMutation, RemoveClientContactModelMutationVariables>(
    RemoveClientContactModelDocument,
    baseOptions
  );
}
export type RemoveClientContactModelMutationHookResult = ReturnType<typeof useRemoveClientContactModelMutation>;
export type RemoveClientContactModelMutationResult = Apollo.MutationResult<RemoveClientContactModelMutation>;
export type RemoveClientContactModelMutationOptions = Apollo.BaseMutationOptions<
  RemoveClientContactModelMutation,
  RemoveClientContactModelMutationVariables
>;
export const QueryClientContactQaByIdDocument = gql`
  query queryClientContactQaById($clientContactId: uuid!) {
    clientContact_by_pk(id: $clientContactId) {
      ...ClientContactQa
    }
  }
  ${ClientContactQaFragmentDoc}
`;

/**
 * __useQueryClientContactQaByIdQuery__
 *
 * To run a query within a React component, call `useQueryClientContactQaByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryClientContactQaByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryClientContactQaByIdQuery({
 *   variables: {
 *      clientContactId: // value for 'clientContactId'
 *   },
 * });
 */
export function useQueryClientContactQaByIdQuery(
  baseOptions: Apollo.QueryHookOptions<QueryClientContactQaByIdQuery, QueryClientContactQaByIdQueryVariables>
) {
  return Apollo.useQuery<QueryClientContactQaByIdQuery, QueryClientContactQaByIdQueryVariables>(
    QueryClientContactQaByIdDocument,
    baseOptions
  );
}
export function useQueryClientContactQaByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryClientContactQaByIdQuery, QueryClientContactQaByIdQueryVariables>
) {
  return Apollo.useLazyQuery<QueryClientContactQaByIdQuery, QueryClientContactQaByIdQueryVariables>(
    QueryClientContactQaByIdDocument,
    baseOptions
  );
}
export type QueryClientContactQaByIdQueryHookResult = ReturnType<typeof useQueryClientContactQaByIdQuery>;
export type QueryClientContactQaByIdLazyQueryHookResult = ReturnType<typeof useQueryClientContactQaByIdLazyQuery>;
export type QueryClientContactQaByIdQueryResult = Apollo.QueryResult<
  QueryClientContactQaByIdQuery,
  QueryClientContactQaByIdQueryVariables
>;
export const QueryClientContactQaObjectsDocument = gql`
  query queryClientContactQaObjects(
    $distinct_on: [clientContact_select_column!]
    $where: clientContact_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [clientContact_order_by!]
  ) {
    clientContact(distinct_on: $distinct_on, where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      ...ClientContactQa
    }
  }
  ${ClientContactQaFragmentDoc}
`;

/**
 * __useQueryClientContactQaObjectsQuery__
 *
 * To run a query within a React component, call `useQueryClientContactQaObjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryClientContactQaObjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryClientContactQaObjectsQuery({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useQueryClientContactQaObjectsQuery(
  baseOptions?: Apollo.QueryHookOptions<QueryClientContactQaObjectsQuery, QueryClientContactQaObjectsQueryVariables>
) {
  return Apollo.useQuery<QueryClientContactQaObjectsQuery, QueryClientContactQaObjectsQueryVariables>(
    QueryClientContactQaObjectsDocument,
    baseOptions
  );
}
export function useQueryClientContactQaObjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryClientContactQaObjectsQuery, QueryClientContactQaObjectsQueryVariables>
) {
  return Apollo.useLazyQuery<QueryClientContactQaObjectsQuery, QueryClientContactQaObjectsQueryVariables>(
    QueryClientContactQaObjectsDocument,
    baseOptions
  );
}
export type QueryClientContactQaObjectsQueryHookResult = ReturnType<typeof useQueryClientContactQaObjectsQuery>;
export type QueryClientContactQaObjectsLazyQueryHookResult = ReturnType<typeof useQueryClientContactQaObjectsLazyQuery>;
export type QueryClientContactQaObjectsQueryResult = Apollo.QueryResult<
  QueryClientContactQaObjectsQuery,
  QueryClientContactQaObjectsQueryVariables
>;
export const SubscribeToClientContactQaByIdDocument = gql`
  subscription subscribeToClientContactQaById($clientContactId: uuid!) {
    clientContact_by_pk(id: $clientContactId) {
      ...ClientContactQa
    }
  }
  ${ClientContactQaFragmentDoc}
`;

/**
 * __useSubscribeToClientContactQaByIdSubscription__
 *
 * To run a query within a React component, call `useSubscribeToClientContactQaByIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToClientContactQaByIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToClientContactQaByIdSubscription({
 *   variables: {
 *      clientContactId: // value for 'clientContactId'
 *   },
 * });
 */
export function useSubscribeToClientContactQaByIdSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    SubscribeToClientContactQaByIdSubscription,
    SubscribeToClientContactQaByIdSubscriptionVariables
  >
) {
  return Apollo.useSubscription<
    SubscribeToClientContactQaByIdSubscription,
    SubscribeToClientContactQaByIdSubscriptionVariables
  >(SubscribeToClientContactQaByIdDocument, baseOptions);
}
export type SubscribeToClientContactQaByIdSubscriptionHookResult = ReturnType<
  typeof useSubscribeToClientContactQaByIdSubscription
>;
export type SubscribeToClientContactQaByIdSubscriptionResult = Apollo.SubscriptionResult<SubscribeToClientContactQaByIdSubscription>;
export const SubscribeToClientContactQaObjectsDocument = gql`
  subscription subscribeToClientContactQaObjects(
    $distinct_on: [clientContact_select_column!]
    $where: clientContact_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [clientContact_order_by!]
  ) {
    clientContact(distinct_on: $distinct_on, where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      ...ClientContactQa
    }
  }
  ${ClientContactQaFragmentDoc}
`;

/**
 * __useSubscribeToClientContactQaObjectsSubscription__
 *
 * To run a query within a React component, call `useSubscribeToClientContactQaObjectsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToClientContactQaObjectsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToClientContactQaObjectsSubscription({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useSubscribeToClientContactQaObjectsSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    SubscribeToClientContactQaObjectsSubscription,
    SubscribeToClientContactQaObjectsSubscriptionVariables
  >
) {
  return Apollo.useSubscription<
    SubscribeToClientContactQaObjectsSubscription,
    SubscribeToClientContactQaObjectsSubscriptionVariables
  >(SubscribeToClientContactQaObjectsDocument, baseOptions);
}
export type SubscribeToClientContactQaObjectsSubscriptionHookResult = ReturnType<
  typeof useSubscribeToClientContactQaObjectsSubscription
>;
export type SubscribeToClientContactQaObjectsSubscriptionResult = Apollo.SubscriptionResult<SubscribeToClientContactQaObjectsSubscription>;
export const InsertClientContactQaDocument = gql`
  mutation insertClientContactQa($objects: [clientContact_insert_input!]!) {
    insert_clientContact(objects: $objects) {
      affected_rows
      returning {
        ...ClientContactQa
      }
    }
  }
  ${ClientContactQaFragmentDoc}
`;
export type InsertClientContactQaMutationFn = Apollo.MutationFunction<
  InsertClientContactQaMutation,
  InsertClientContactQaMutationVariables
>;

/**
 * __useInsertClientContactQaMutation__
 *
 * To run a mutation, you first call `useInsertClientContactQaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertClientContactQaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertClientContactQaMutation, { data, loading, error }] = useInsertClientContactQaMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertClientContactQaMutation(
  baseOptions?: Apollo.MutationHookOptions<InsertClientContactQaMutation, InsertClientContactQaMutationVariables>
) {
  return Apollo.useMutation<InsertClientContactQaMutation, InsertClientContactQaMutationVariables>(
    InsertClientContactQaDocument,
    baseOptions
  );
}
export type InsertClientContactQaMutationHookResult = ReturnType<typeof useInsertClientContactQaMutation>;
export type InsertClientContactQaMutationResult = Apollo.MutationResult<InsertClientContactQaMutation>;
export type InsertClientContactQaMutationOptions = Apollo.BaseMutationOptions<
  InsertClientContactQaMutation,
  InsertClientContactQaMutationVariables
>;
export const InsertClientContactQaWithOnConflictDocument = gql`
  mutation insertClientContactQaWithOnConflict(
    $objects: [clientContact_insert_input!]!
    $onConflict: clientContact_on_conflict
  ) {
    insert_clientContact(objects: $objects, on_conflict: $onConflict) {
      affected_rows
      returning {
        ...ClientContactQa
      }
    }
  }
  ${ClientContactQaFragmentDoc}
`;
export type InsertClientContactQaWithOnConflictMutationFn = Apollo.MutationFunction<
  InsertClientContactQaWithOnConflictMutation,
  InsertClientContactQaWithOnConflictMutationVariables
>;

/**
 * __useInsertClientContactQaWithOnConflictMutation__
 *
 * To run a mutation, you first call `useInsertClientContactQaWithOnConflictMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertClientContactQaWithOnConflictMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertClientContactQaWithOnConflictMutation, { data, loading, error }] = useInsertClientContactQaWithOnConflictMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *      onConflict: // value for 'onConflict'
 *   },
 * });
 */
export function useInsertClientContactQaWithOnConflictMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertClientContactQaWithOnConflictMutation,
    InsertClientContactQaWithOnConflictMutationVariables
  >
) {
  return Apollo.useMutation<
    InsertClientContactQaWithOnConflictMutation,
    InsertClientContactQaWithOnConflictMutationVariables
  >(InsertClientContactQaWithOnConflictDocument, baseOptions);
}
export type InsertClientContactQaWithOnConflictMutationHookResult = ReturnType<
  typeof useInsertClientContactQaWithOnConflictMutation
>;
export type InsertClientContactQaWithOnConflictMutationResult = Apollo.MutationResult<InsertClientContactQaWithOnConflictMutation>;
export type InsertClientContactQaWithOnConflictMutationOptions = Apollo.BaseMutationOptions<
  InsertClientContactQaWithOnConflictMutation,
  InsertClientContactQaWithOnConflictMutationVariables
>;
export const UpdateClientContactQaByIdDocument = gql`
  mutation updateClientContactQaById($id: uuid, $set: clientContact_set_input) {
    update_clientContact(_set: $set, where: { id: { _eq: $id } }) {
      affected_rows
      returning {
        ...ClientContactQa
      }
    }
  }
  ${ClientContactQaFragmentDoc}
`;
export type UpdateClientContactQaByIdMutationFn = Apollo.MutationFunction<
  UpdateClientContactQaByIdMutation,
  UpdateClientContactQaByIdMutationVariables
>;

/**
 * __useUpdateClientContactQaByIdMutation__
 *
 * To run a mutation, you first call `useUpdateClientContactQaByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientContactQaByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientContactQaByIdMutation, { data, loading, error }] = useUpdateClientContactQaByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateClientContactQaByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateClientContactQaByIdMutation,
    UpdateClientContactQaByIdMutationVariables
  >
) {
  return Apollo.useMutation<UpdateClientContactQaByIdMutation, UpdateClientContactQaByIdMutationVariables>(
    UpdateClientContactQaByIdDocument,
    baseOptions
  );
}
export type UpdateClientContactQaByIdMutationHookResult = ReturnType<typeof useUpdateClientContactQaByIdMutation>;
export type UpdateClientContactQaByIdMutationResult = Apollo.MutationResult<UpdateClientContactQaByIdMutation>;
export type UpdateClientContactQaByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateClientContactQaByIdMutation,
  UpdateClientContactQaByIdMutationVariables
>;
export const UpdateClientContactQaDocument = gql`
  mutation updateClientContactQa($set: clientContact_set_input, $where: clientContact_bool_exp!) {
    update_clientContact(_set: $set, where: $where) {
      affected_rows
      returning {
        ...ClientContactQa
      }
    }
  }
  ${ClientContactQaFragmentDoc}
`;
export type UpdateClientContactQaMutationFn = Apollo.MutationFunction<
  UpdateClientContactQaMutation,
  UpdateClientContactQaMutationVariables
>;

/**
 * __useUpdateClientContactQaMutation__
 *
 * To run a mutation, you first call `useUpdateClientContactQaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientContactQaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientContactQaMutation, { data, loading, error }] = useUpdateClientContactQaMutation({
 *   variables: {
 *      set: // value for 'set'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateClientContactQaMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateClientContactQaMutation, UpdateClientContactQaMutationVariables>
) {
  return Apollo.useMutation<UpdateClientContactQaMutation, UpdateClientContactQaMutationVariables>(
    UpdateClientContactQaDocument,
    baseOptions
  );
}
export type UpdateClientContactQaMutationHookResult = ReturnType<typeof useUpdateClientContactQaMutation>;
export type UpdateClientContactQaMutationResult = Apollo.MutationResult<UpdateClientContactQaMutation>;
export type UpdateClientContactQaMutationOptions = Apollo.BaseMutationOptions<
  UpdateClientContactQaMutation,
  UpdateClientContactQaMutationVariables
>;
export const QueryCrmContactEnrichmentHistoryByIdDocument = gql`
  query queryCrmContactEnrichmentHistoryById($crmContactEnrichmentHistoryId: uuid!) {
    crmContactEnrichmentHistory_by_pk(id: $crmContactEnrichmentHistoryId) {
      ...CrmContactEnrichmentHistory
    }
  }
  ${CrmContactEnrichmentHistoryFragmentDoc}
`;

/**
 * __useQueryCrmContactEnrichmentHistoryByIdQuery__
 *
 * To run a query within a React component, call `useQueryCrmContactEnrichmentHistoryByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryCrmContactEnrichmentHistoryByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryCrmContactEnrichmentHistoryByIdQuery({
 *   variables: {
 *      crmContactEnrichmentHistoryId: // value for 'crmContactEnrichmentHistoryId'
 *   },
 * });
 */
export function useQueryCrmContactEnrichmentHistoryByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryCrmContactEnrichmentHistoryByIdQuery,
    QueryCrmContactEnrichmentHistoryByIdQueryVariables
  >
) {
  return Apollo.useQuery<QueryCrmContactEnrichmentHistoryByIdQuery, QueryCrmContactEnrichmentHistoryByIdQueryVariables>(
    QueryCrmContactEnrichmentHistoryByIdDocument,
    baseOptions
  );
}
export function useQueryCrmContactEnrichmentHistoryByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryCrmContactEnrichmentHistoryByIdQuery,
    QueryCrmContactEnrichmentHistoryByIdQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    QueryCrmContactEnrichmentHistoryByIdQuery,
    QueryCrmContactEnrichmentHistoryByIdQueryVariables
  >(QueryCrmContactEnrichmentHistoryByIdDocument, baseOptions);
}
export type QueryCrmContactEnrichmentHistoryByIdQueryHookResult = ReturnType<
  typeof useQueryCrmContactEnrichmentHistoryByIdQuery
>;
export type QueryCrmContactEnrichmentHistoryByIdLazyQueryHookResult = ReturnType<
  typeof useQueryCrmContactEnrichmentHistoryByIdLazyQuery
>;
export type QueryCrmContactEnrichmentHistoryByIdQueryResult = Apollo.QueryResult<
  QueryCrmContactEnrichmentHistoryByIdQuery,
  QueryCrmContactEnrichmentHistoryByIdQueryVariables
>;
export const QueryCrmContactEnrichmentHistoryObjectsDocument = gql`
  query queryCrmContactEnrichmentHistoryObjects(
    $distinct_on: [crmContactEnrichmentHistory_select_column!]
    $where: crmContactEnrichmentHistory_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [crmContactEnrichmentHistory_order_by!]
  ) {
    crmContactEnrichmentHistory(
      distinct_on: $distinct_on
      where: $where
      limit: $limit
      offset: $offset
      order_by: $order_by
    ) {
      ...CrmContactEnrichmentHistory
    }
  }
  ${CrmContactEnrichmentHistoryFragmentDoc}
`;

/**
 * __useQueryCrmContactEnrichmentHistoryObjectsQuery__
 *
 * To run a query within a React component, call `useQueryCrmContactEnrichmentHistoryObjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryCrmContactEnrichmentHistoryObjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryCrmContactEnrichmentHistoryObjectsQuery({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useQueryCrmContactEnrichmentHistoryObjectsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    QueryCrmContactEnrichmentHistoryObjectsQuery,
    QueryCrmContactEnrichmentHistoryObjectsQueryVariables
  >
) {
  return Apollo.useQuery<
    QueryCrmContactEnrichmentHistoryObjectsQuery,
    QueryCrmContactEnrichmentHistoryObjectsQueryVariables
  >(QueryCrmContactEnrichmentHistoryObjectsDocument, baseOptions);
}
export function useQueryCrmContactEnrichmentHistoryObjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryCrmContactEnrichmentHistoryObjectsQuery,
    QueryCrmContactEnrichmentHistoryObjectsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    QueryCrmContactEnrichmentHistoryObjectsQuery,
    QueryCrmContactEnrichmentHistoryObjectsQueryVariables
  >(QueryCrmContactEnrichmentHistoryObjectsDocument, baseOptions);
}
export type QueryCrmContactEnrichmentHistoryObjectsQueryHookResult = ReturnType<
  typeof useQueryCrmContactEnrichmentHistoryObjectsQuery
>;
export type QueryCrmContactEnrichmentHistoryObjectsLazyQueryHookResult = ReturnType<
  typeof useQueryCrmContactEnrichmentHistoryObjectsLazyQuery
>;
export type QueryCrmContactEnrichmentHistoryObjectsQueryResult = Apollo.QueryResult<
  QueryCrmContactEnrichmentHistoryObjectsQuery,
  QueryCrmContactEnrichmentHistoryObjectsQueryVariables
>;
export const SubscribeToCrmContactEnrichmentHistoryByIdDocument = gql`
  subscription subscribeToCrmContactEnrichmentHistoryById($crmContactEnrichmentHistoryId: uuid!) {
    crmContactEnrichmentHistory_by_pk(id: $crmContactEnrichmentHistoryId) {
      ...CrmContactEnrichmentHistory
    }
  }
  ${CrmContactEnrichmentHistoryFragmentDoc}
`;

/**
 * __useSubscribeToCrmContactEnrichmentHistoryByIdSubscription__
 *
 * To run a query within a React component, call `useSubscribeToCrmContactEnrichmentHistoryByIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToCrmContactEnrichmentHistoryByIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToCrmContactEnrichmentHistoryByIdSubscription({
 *   variables: {
 *      crmContactEnrichmentHistoryId: // value for 'crmContactEnrichmentHistoryId'
 *   },
 * });
 */
export function useSubscribeToCrmContactEnrichmentHistoryByIdSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    SubscribeToCrmContactEnrichmentHistoryByIdSubscription,
    SubscribeToCrmContactEnrichmentHistoryByIdSubscriptionVariables
  >
) {
  return Apollo.useSubscription<
    SubscribeToCrmContactEnrichmentHistoryByIdSubscription,
    SubscribeToCrmContactEnrichmentHistoryByIdSubscriptionVariables
  >(SubscribeToCrmContactEnrichmentHistoryByIdDocument, baseOptions);
}
export type SubscribeToCrmContactEnrichmentHistoryByIdSubscriptionHookResult = ReturnType<
  typeof useSubscribeToCrmContactEnrichmentHistoryByIdSubscription
>;
export type SubscribeToCrmContactEnrichmentHistoryByIdSubscriptionResult = Apollo.SubscriptionResult<SubscribeToCrmContactEnrichmentHistoryByIdSubscription>;
export const SubscribeToCrmContactEnrichmentHistoryObjectsDocument = gql`
  subscription subscribeToCrmContactEnrichmentHistoryObjects(
    $distinct_on: [crmContactEnrichmentHistory_select_column!]
    $where: crmContactEnrichmentHistory_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [crmContactEnrichmentHistory_order_by!]
  ) {
    crmContactEnrichmentHistory(
      distinct_on: $distinct_on
      where: $where
      limit: $limit
      offset: $offset
      order_by: $order_by
    ) {
      ...CrmContactEnrichmentHistory
    }
  }
  ${CrmContactEnrichmentHistoryFragmentDoc}
`;

/**
 * __useSubscribeToCrmContactEnrichmentHistoryObjectsSubscription__
 *
 * To run a query within a React component, call `useSubscribeToCrmContactEnrichmentHistoryObjectsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToCrmContactEnrichmentHistoryObjectsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToCrmContactEnrichmentHistoryObjectsSubscription({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useSubscribeToCrmContactEnrichmentHistoryObjectsSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    SubscribeToCrmContactEnrichmentHistoryObjectsSubscription,
    SubscribeToCrmContactEnrichmentHistoryObjectsSubscriptionVariables
  >
) {
  return Apollo.useSubscription<
    SubscribeToCrmContactEnrichmentHistoryObjectsSubscription,
    SubscribeToCrmContactEnrichmentHistoryObjectsSubscriptionVariables
  >(SubscribeToCrmContactEnrichmentHistoryObjectsDocument, baseOptions);
}
export type SubscribeToCrmContactEnrichmentHistoryObjectsSubscriptionHookResult = ReturnType<
  typeof useSubscribeToCrmContactEnrichmentHistoryObjectsSubscription
>;
export type SubscribeToCrmContactEnrichmentHistoryObjectsSubscriptionResult = Apollo.SubscriptionResult<SubscribeToCrmContactEnrichmentHistoryObjectsSubscription>;
export const InsertCrmContactEnrichmentHistoryDocument = gql`
  mutation insertCrmContactEnrichmentHistory($objects: [crmContactEnrichmentHistory_insert_input!]!) {
    insert_crmContactEnrichmentHistory(objects: $objects) {
      affected_rows
      returning {
        ...CrmContactEnrichmentHistory
      }
    }
  }
  ${CrmContactEnrichmentHistoryFragmentDoc}
`;
export type InsertCrmContactEnrichmentHistoryMutationFn = Apollo.MutationFunction<
  InsertCrmContactEnrichmentHistoryMutation,
  InsertCrmContactEnrichmentHistoryMutationVariables
>;

/**
 * __useInsertCrmContactEnrichmentHistoryMutation__
 *
 * To run a mutation, you first call `useInsertCrmContactEnrichmentHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertCrmContactEnrichmentHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertCrmContactEnrichmentHistoryMutation, { data, loading, error }] = useInsertCrmContactEnrichmentHistoryMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertCrmContactEnrichmentHistoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertCrmContactEnrichmentHistoryMutation,
    InsertCrmContactEnrichmentHistoryMutationVariables
  >
) {
  return Apollo.useMutation<
    InsertCrmContactEnrichmentHistoryMutation,
    InsertCrmContactEnrichmentHistoryMutationVariables
  >(InsertCrmContactEnrichmentHistoryDocument, baseOptions);
}
export type InsertCrmContactEnrichmentHistoryMutationHookResult = ReturnType<
  typeof useInsertCrmContactEnrichmentHistoryMutation
>;
export type InsertCrmContactEnrichmentHistoryMutationResult = Apollo.MutationResult<InsertCrmContactEnrichmentHistoryMutation>;
export type InsertCrmContactEnrichmentHistoryMutationOptions = Apollo.BaseMutationOptions<
  InsertCrmContactEnrichmentHistoryMutation,
  InsertCrmContactEnrichmentHistoryMutationVariables
>;
export const InsertCrmContactEnrichmentHistoryWithOnConflictDocument = gql`
  mutation insertCrmContactEnrichmentHistoryWithOnConflict(
    $objects: [crmContactEnrichmentHistory_insert_input!]!
    $onConflict: crmContactEnrichmentHistory_on_conflict
  ) {
    insert_crmContactEnrichmentHistory(objects: $objects, on_conflict: $onConflict) {
      affected_rows
      returning {
        ...CrmContactEnrichmentHistory
      }
    }
  }
  ${CrmContactEnrichmentHistoryFragmentDoc}
`;
export type InsertCrmContactEnrichmentHistoryWithOnConflictMutationFn = Apollo.MutationFunction<
  InsertCrmContactEnrichmentHistoryWithOnConflictMutation,
  InsertCrmContactEnrichmentHistoryWithOnConflictMutationVariables
>;

/**
 * __useInsertCrmContactEnrichmentHistoryWithOnConflictMutation__
 *
 * To run a mutation, you first call `useInsertCrmContactEnrichmentHistoryWithOnConflictMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertCrmContactEnrichmentHistoryWithOnConflictMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertCrmContactEnrichmentHistoryWithOnConflictMutation, { data, loading, error }] = useInsertCrmContactEnrichmentHistoryWithOnConflictMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *      onConflict: // value for 'onConflict'
 *   },
 * });
 */
export function useInsertCrmContactEnrichmentHistoryWithOnConflictMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertCrmContactEnrichmentHistoryWithOnConflictMutation,
    InsertCrmContactEnrichmentHistoryWithOnConflictMutationVariables
  >
) {
  return Apollo.useMutation<
    InsertCrmContactEnrichmentHistoryWithOnConflictMutation,
    InsertCrmContactEnrichmentHistoryWithOnConflictMutationVariables
  >(InsertCrmContactEnrichmentHistoryWithOnConflictDocument, baseOptions);
}
export type InsertCrmContactEnrichmentHistoryWithOnConflictMutationHookResult = ReturnType<
  typeof useInsertCrmContactEnrichmentHistoryWithOnConflictMutation
>;
export type InsertCrmContactEnrichmentHistoryWithOnConflictMutationResult = Apollo.MutationResult<InsertCrmContactEnrichmentHistoryWithOnConflictMutation>;
export type InsertCrmContactEnrichmentHistoryWithOnConflictMutationOptions = Apollo.BaseMutationOptions<
  InsertCrmContactEnrichmentHistoryWithOnConflictMutation,
  InsertCrmContactEnrichmentHistoryWithOnConflictMutationVariables
>;
export const UpdateCrmContactEnrichmentHistoryByIdDocument = gql`
  mutation updateCrmContactEnrichmentHistoryById($id: uuid, $set: crmContactEnrichmentHistory_set_input) {
    update_crmContactEnrichmentHistory(_set: $set, where: { id: { _eq: $id } }) {
      affected_rows
      returning {
        ...CrmContactEnrichmentHistory
      }
    }
  }
  ${CrmContactEnrichmentHistoryFragmentDoc}
`;
export type UpdateCrmContactEnrichmentHistoryByIdMutationFn = Apollo.MutationFunction<
  UpdateCrmContactEnrichmentHistoryByIdMutation,
  UpdateCrmContactEnrichmentHistoryByIdMutationVariables
>;

/**
 * __useUpdateCrmContactEnrichmentHistoryByIdMutation__
 *
 * To run a mutation, you first call `useUpdateCrmContactEnrichmentHistoryByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCrmContactEnrichmentHistoryByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCrmContactEnrichmentHistoryByIdMutation, { data, loading, error }] = useUpdateCrmContactEnrichmentHistoryByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateCrmContactEnrichmentHistoryByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCrmContactEnrichmentHistoryByIdMutation,
    UpdateCrmContactEnrichmentHistoryByIdMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateCrmContactEnrichmentHistoryByIdMutation,
    UpdateCrmContactEnrichmentHistoryByIdMutationVariables
  >(UpdateCrmContactEnrichmentHistoryByIdDocument, baseOptions);
}
export type UpdateCrmContactEnrichmentHistoryByIdMutationHookResult = ReturnType<
  typeof useUpdateCrmContactEnrichmentHistoryByIdMutation
>;
export type UpdateCrmContactEnrichmentHistoryByIdMutationResult = Apollo.MutationResult<UpdateCrmContactEnrichmentHistoryByIdMutation>;
export type UpdateCrmContactEnrichmentHistoryByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateCrmContactEnrichmentHistoryByIdMutation,
  UpdateCrmContactEnrichmentHistoryByIdMutationVariables
>;
export const UpdateCrmContactEnrichmentHistoryDocument = gql`
  mutation updateCrmContactEnrichmentHistory(
    $set: crmContactEnrichmentHistory_set_input
    $where: crmContactEnrichmentHistory_bool_exp!
  ) {
    update_crmContactEnrichmentHistory(_set: $set, where: $where) {
      affected_rows
      returning {
        ...CrmContactEnrichmentHistory
      }
    }
  }
  ${CrmContactEnrichmentHistoryFragmentDoc}
`;
export type UpdateCrmContactEnrichmentHistoryMutationFn = Apollo.MutationFunction<
  UpdateCrmContactEnrichmentHistoryMutation,
  UpdateCrmContactEnrichmentHistoryMutationVariables
>;

/**
 * __useUpdateCrmContactEnrichmentHistoryMutation__
 *
 * To run a mutation, you first call `useUpdateCrmContactEnrichmentHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCrmContactEnrichmentHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCrmContactEnrichmentHistoryMutation, { data, loading, error }] = useUpdateCrmContactEnrichmentHistoryMutation({
 *   variables: {
 *      set: // value for 'set'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateCrmContactEnrichmentHistoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCrmContactEnrichmentHistoryMutation,
    UpdateCrmContactEnrichmentHistoryMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateCrmContactEnrichmentHistoryMutation,
    UpdateCrmContactEnrichmentHistoryMutationVariables
  >(UpdateCrmContactEnrichmentHistoryDocument, baseOptions);
}
export type UpdateCrmContactEnrichmentHistoryMutationHookResult = ReturnType<
  typeof useUpdateCrmContactEnrichmentHistoryMutation
>;
export type UpdateCrmContactEnrichmentHistoryMutationResult = Apollo.MutationResult<UpdateCrmContactEnrichmentHistoryMutation>;
export type UpdateCrmContactEnrichmentHistoryMutationOptions = Apollo.BaseMutationOptions<
  UpdateCrmContactEnrichmentHistoryMutation,
  UpdateCrmContactEnrichmentHistoryMutationVariables
>;
export const RemoveCrmContactEnrichmentHistoryModelByIdDocument = gql`
  mutation removeCrmContactEnrichmentHistoryModelById($id: uuid) {
    delete_crmContactEnrichmentHistory(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
export type RemoveCrmContactEnrichmentHistoryModelByIdMutationFn = Apollo.MutationFunction<
  RemoveCrmContactEnrichmentHistoryModelByIdMutation,
  RemoveCrmContactEnrichmentHistoryModelByIdMutationVariables
>;

/**
 * __useRemoveCrmContactEnrichmentHistoryModelByIdMutation__
 *
 * To run a mutation, you first call `useRemoveCrmContactEnrichmentHistoryModelByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCrmContactEnrichmentHistoryModelByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCrmContactEnrichmentHistoryModelByIdMutation, { data, loading, error }] = useRemoveCrmContactEnrichmentHistoryModelByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveCrmContactEnrichmentHistoryModelByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveCrmContactEnrichmentHistoryModelByIdMutation,
    RemoveCrmContactEnrichmentHistoryModelByIdMutationVariables
  >
) {
  return Apollo.useMutation<
    RemoveCrmContactEnrichmentHistoryModelByIdMutation,
    RemoveCrmContactEnrichmentHistoryModelByIdMutationVariables
  >(RemoveCrmContactEnrichmentHistoryModelByIdDocument, baseOptions);
}
export type RemoveCrmContactEnrichmentHistoryModelByIdMutationHookResult = ReturnType<
  typeof useRemoveCrmContactEnrichmentHistoryModelByIdMutation
>;
export type RemoveCrmContactEnrichmentHistoryModelByIdMutationResult = Apollo.MutationResult<RemoveCrmContactEnrichmentHistoryModelByIdMutation>;
export type RemoveCrmContactEnrichmentHistoryModelByIdMutationOptions = Apollo.BaseMutationOptions<
  RemoveCrmContactEnrichmentHistoryModelByIdMutation,
  RemoveCrmContactEnrichmentHistoryModelByIdMutationVariables
>;
export const RemoveCrmContactEnrichmentHistoryModelDocument = gql`
  mutation removeCrmContactEnrichmentHistoryModel($where: crmContactEnrichmentHistory_bool_exp!) {
    delete_crmContactEnrichmentHistory(where: $where) {
      affected_rows
    }
  }
`;
export type RemoveCrmContactEnrichmentHistoryModelMutationFn = Apollo.MutationFunction<
  RemoveCrmContactEnrichmentHistoryModelMutation,
  RemoveCrmContactEnrichmentHistoryModelMutationVariables
>;

/**
 * __useRemoveCrmContactEnrichmentHistoryModelMutation__
 *
 * To run a mutation, you first call `useRemoveCrmContactEnrichmentHistoryModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCrmContactEnrichmentHistoryModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCrmContactEnrichmentHistoryModelMutation, { data, loading, error }] = useRemoveCrmContactEnrichmentHistoryModelMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useRemoveCrmContactEnrichmentHistoryModelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveCrmContactEnrichmentHistoryModelMutation,
    RemoveCrmContactEnrichmentHistoryModelMutationVariables
  >
) {
  return Apollo.useMutation<
    RemoveCrmContactEnrichmentHistoryModelMutation,
    RemoveCrmContactEnrichmentHistoryModelMutationVariables
  >(RemoveCrmContactEnrichmentHistoryModelDocument, baseOptions);
}
export type RemoveCrmContactEnrichmentHistoryModelMutationHookResult = ReturnType<
  typeof useRemoveCrmContactEnrichmentHistoryModelMutation
>;
export type RemoveCrmContactEnrichmentHistoryModelMutationResult = Apollo.MutationResult<RemoveCrmContactEnrichmentHistoryModelMutation>;
export type RemoveCrmContactEnrichmentHistoryModelMutationOptions = Apollo.BaseMutationOptions<
  RemoveCrmContactEnrichmentHistoryModelMutation,
  RemoveCrmContactEnrichmentHistoryModelMutationVariables
>;
export const QueryClientContactCrmDataByIdDocument = gql`
  query queryClientContactCrmDataById($clientContactCrmDataId: uuid!) {
    clientContactCrmData_by_pk(id: $clientContactCrmDataId) {
      ...ClientContactCrmData
    }
  }
  ${ClientContactCrmDataFragmentDoc}
`;

/**
 * __useQueryClientContactCrmDataByIdQuery__
 *
 * To run a query within a React component, call `useQueryClientContactCrmDataByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryClientContactCrmDataByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryClientContactCrmDataByIdQuery({
 *   variables: {
 *      clientContactCrmDataId: // value for 'clientContactCrmDataId'
 *   },
 * });
 */
export function useQueryClientContactCrmDataByIdQuery(
  baseOptions: Apollo.QueryHookOptions<QueryClientContactCrmDataByIdQuery, QueryClientContactCrmDataByIdQueryVariables>
) {
  return Apollo.useQuery<QueryClientContactCrmDataByIdQuery, QueryClientContactCrmDataByIdQueryVariables>(
    QueryClientContactCrmDataByIdDocument,
    baseOptions
  );
}
export function useQueryClientContactCrmDataByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryClientContactCrmDataByIdQuery,
    QueryClientContactCrmDataByIdQueryVariables
  >
) {
  return Apollo.useLazyQuery<QueryClientContactCrmDataByIdQuery, QueryClientContactCrmDataByIdQueryVariables>(
    QueryClientContactCrmDataByIdDocument,
    baseOptions
  );
}
export type QueryClientContactCrmDataByIdQueryHookResult = ReturnType<typeof useQueryClientContactCrmDataByIdQuery>;
export type QueryClientContactCrmDataByIdLazyQueryHookResult = ReturnType<
  typeof useQueryClientContactCrmDataByIdLazyQuery
>;
export type QueryClientContactCrmDataByIdQueryResult = Apollo.QueryResult<
  QueryClientContactCrmDataByIdQuery,
  QueryClientContactCrmDataByIdQueryVariables
>;
export const QueryClientContactCrmDataObjectsDocument = gql`
  query queryClientContactCrmDataObjects(
    $distinct_on: [clientContactCrmData_select_column!]
    $where: clientContactCrmData_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [clientContactCrmData_order_by!]
  ) {
    clientContactCrmData(
      distinct_on: $distinct_on
      where: $where
      limit: $limit
      offset: $offset
      order_by: $order_by
    ) {
      ...ClientContactCrmData
    }
  }
  ${ClientContactCrmDataFragmentDoc}
`;

/**
 * __useQueryClientContactCrmDataObjectsQuery__
 *
 * To run a query within a React component, call `useQueryClientContactCrmDataObjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryClientContactCrmDataObjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryClientContactCrmDataObjectsQuery({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useQueryClientContactCrmDataObjectsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    QueryClientContactCrmDataObjectsQuery,
    QueryClientContactCrmDataObjectsQueryVariables
  >
) {
  return Apollo.useQuery<QueryClientContactCrmDataObjectsQuery, QueryClientContactCrmDataObjectsQueryVariables>(
    QueryClientContactCrmDataObjectsDocument,
    baseOptions
  );
}
export function useQueryClientContactCrmDataObjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryClientContactCrmDataObjectsQuery,
    QueryClientContactCrmDataObjectsQueryVariables
  >
) {
  return Apollo.useLazyQuery<QueryClientContactCrmDataObjectsQuery, QueryClientContactCrmDataObjectsQueryVariables>(
    QueryClientContactCrmDataObjectsDocument,
    baseOptions
  );
}
export type QueryClientContactCrmDataObjectsQueryHookResult = ReturnType<
  typeof useQueryClientContactCrmDataObjectsQuery
>;
export type QueryClientContactCrmDataObjectsLazyQueryHookResult = ReturnType<
  typeof useQueryClientContactCrmDataObjectsLazyQuery
>;
export type QueryClientContactCrmDataObjectsQueryResult = Apollo.QueryResult<
  QueryClientContactCrmDataObjectsQuery,
  QueryClientContactCrmDataObjectsQueryVariables
>;
export const SubscribeToClientContactCrmDataByIdDocument = gql`
  subscription subscribeToClientContactCrmDataById($clientContactCrmDataId: uuid!) {
    clientContactCrmData_by_pk(id: $clientContactCrmDataId) {
      ...ClientContactCrmData
    }
  }
  ${ClientContactCrmDataFragmentDoc}
`;

/**
 * __useSubscribeToClientContactCrmDataByIdSubscription__
 *
 * To run a query within a React component, call `useSubscribeToClientContactCrmDataByIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToClientContactCrmDataByIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToClientContactCrmDataByIdSubscription({
 *   variables: {
 *      clientContactCrmDataId: // value for 'clientContactCrmDataId'
 *   },
 * });
 */
export function useSubscribeToClientContactCrmDataByIdSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    SubscribeToClientContactCrmDataByIdSubscription,
    SubscribeToClientContactCrmDataByIdSubscriptionVariables
  >
) {
  return Apollo.useSubscription<
    SubscribeToClientContactCrmDataByIdSubscription,
    SubscribeToClientContactCrmDataByIdSubscriptionVariables
  >(SubscribeToClientContactCrmDataByIdDocument, baseOptions);
}
export type SubscribeToClientContactCrmDataByIdSubscriptionHookResult = ReturnType<
  typeof useSubscribeToClientContactCrmDataByIdSubscription
>;
export type SubscribeToClientContactCrmDataByIdSubscriptionResult = Apollo.SubscriptionResult<SubscribeToClientContactCrmDataByIdSubscription>;
export const SubscribeToClientContactCrmDataObjectsDocument = gql`
  subscription subscribeToClientContactCrmDataObjects(
    $distinct_on: [clientContactCrmData_select_column!]
    $where: clientContactCrmData_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [clientContactCrmData_order_by!]
  ) {
    clientContactCrmData(
      distinct_on: $distinct_on
      where: $where
      limit: $limit
      offset: $offset
      order_by: $order_by
    ) {
      ...ClientContactCrmData
    }
  }
  ${ClientContactCrmDataFragmentDoc}
`;

/**
 * __useSubscribeToClientContactCrmDataObjectsSubscription__
 *
 * To run a query within a React component, call `useSubscribeToClientContactCrmDataObjectsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToClientContactCrmDataObjectsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToClientContactCrmDataObjectsSubscription({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useSubscribeToClientContactCrmDataObjectsSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    SubscribeToClientContactCrmDataObjectsSubscription,
    SubscribeToClientContactCrmDataObjectsSubscriptionVariables
  >
) {
  return Apollo.useSubscription<
    SubscribeToClientContactCrmDataObjectsSubscription,
    SubscribeToClientContactCrmDataObjectsSubscriptionVariables
  >(SubscribeToClientContactCrmDataObjectsDocument, baseOptions);
}
export type SubscribeToClientContactCrmDataObjectsSubscriptionHookResult = ReturnType<
  typeof useSubscribeToClientContactCrmDataObjectsSubscription
>;
export type SubscribeToClientContactCrmDataObjectsSubscriptionResult = Apollo.SubscriptionResult<SubscribeToClientContactCrmDataObjectsSubscription>;
export const InsertClientContactCrmDataDocument = gql`
  mutation insertClientContactCrmData($objects: [clientContactCrmData_insert_input!]!) {
    insert_clientContactCrmData(objects: $objects) {
      affected_rows
      returning {
        ...ClientContactCrmData
      }
    }
  }
  ${ClientContactCrmDataFragmentDoc}
`;
export type InsertClientContactCrmDataMutationFn = Apollo.MutationFunction<
  InsertClientContactCrmDataMutation,
  InsertClientContactCrmDataMutationVariables
>;

/**
 * __useInsertClientContactCrmDataMutation__
 *
 * To run a mutation, you first call `useInsertClientContactCrmDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertClientContactCrmDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertClientContactCrmDataMutation, { data, loading, error }] = useInsertClientContactCrmDataMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertClientContactCrmDataMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertClientContactCrmDataMutation,
    InsertClientContactCrmDataMutationVariables
  >
) {
  return Apollo.useMutation<InsertClientContactCrmDataMutation, InsertClientContactCrmDataMutationVariables>(
    InsertClientContactCrmDataDocument,
    baseOptions
  );
}
export type InsertClientContactCrmDataMutationHookResult = ReturnType<typeof useInsertClientContactCrmDataMutation>;
export type InsertClientContactCrmDataMutationResult = Apollo.MutationResult<InsertClientContactCrmDataMutation>;
export type InsertClientContactCrmDataMutationOptions = Apollo.BaseMutationOptions<
  InsertClientContactCrmDataMutation,
  InsertClientContactCrmDataMutationVariables
>;
export const InsertClientContactCrmDataWithOnConflictDocument = gql`
  mutation insertClientContactCrmDataWithOnConflict(
    $objects: [clientContactCrmData_insert_input!]!
    $onConflict: clientContactCrmData_on_conflict
  ) {
    insert_clientContactCrmData(objects: $objects, on_conflict: $onConflict) {
      affected_rows
      returning {
        ...ClientContactCrmData
      }
    }
  }
  ${ClientContactCrmDataFragmentDoc}
`;
export type InsertClientContactCrmDataWithOnConflictMutationFn = Apollo.MutationFunction<
  InsertClientContactCrmDataWithOnConflictMutation,
  InsertClientContactCrmDataWithOnConflictMutationVariables
>;

/**
 * __useInsertClientContactCrmDataWithOnConflictMutation__
 *
 * To run a mutation, you first call `useInsertClientContactCrmDataWithOnConflictMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertClientContactCrmDataWithOnConflictMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertClientContactCrmDataWithOnConflictMutation, { data, loading, error }] = useInsertClientContactCrmDataWithOnConflictMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *      onConflict: // value for 'onConflict'
 *   },
 * });
 */
export function useInsertClientContactCrmDataWithOnConflictMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertClientContactCrmDataWithOnConflictMutation,
    InsertClientContactCrmDataWithOnConflictMutationVariables
  >
) {
  return Apollo.useMutation<
    InsertClientContactCrmDataWithOnConflictMutation,
    InsertClientContactCrmDataWithOnConflictMutationVariables
  >(InsertClientContactCrmDataWithOnConflictDocument, baseOptions);
}
export type InsertClientContactCrmDataWithOnConflictMutationHookResult = ReturnType<
  typeof useInsertClientContactCrmDataWithOnConflictMutation
>;
export type InsertClientContactCrmDataWithOnConflictMutationResult = Apollo.MutationResult<InsertClientContactCrmDataWithOnConflictMutation>;
export type InsertClientContactCrmDataWithOnConflictMutationOptions = Apollo.BaseMutationOptions<
  InsertClientContactCrmDataWithOnConflictMutation,
  InsertClientContactCrmDataWithOnConflictMutationVariables
>;
export const UpdateClientContactCrmDataByIdDocument = gql`
  mutation updateClientContactCrmDataById($id: uuid, $set: clientContactCrmData_set_input) {
    update_clientContactCrmData(_set: $set, where: { id: { _eq: $id } }) {
      affected_rows
      returning {
        ...ClientContactCrmData
      }
    }
  }
  ${ClientContactCrmDataFragmentDoc}
`;
export type UpdateClientContactCrmDataByIdMutationFn = Apollo.MutationFunction<
  UpdateClientContactCrmDataByIdMutation,
  UpdateClientContactCrmDataByIdMutationVariables
>;

/**
 * __useUpdateClientContactCrmDataByIdMutation__
 *
 * To run a mutation, you first call `useUpdateClientContactCrmDataByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientContactCrmDataByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientContactCrmDataByIdMutation, { data, loading, error }] = useUpdateClientContactCrmDataByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateClientContactCrmDataByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateClientContactCrmDataByIdMutation,
    UpdateClientContactCrmDataByIdMutationVariables
  >
) {
  return Apollo.useMutation<UpdateClientContactCrmDataByIdMutation, UpdateClientContactCrmDataByIdMutationVariables>(
    UpdateClientContactCrmDataByIdDocument,
    baseOptions
  );
}
export type UpdateClientContactCrmDataByIdMutationHookResult = ReturnType<
  typeof useUpdateClientContactCrmDataByIdMutation
>;
export type UpdateClientContactCrmDataByIdMutationResult = Apollo.MutationResult<UpdateClientContactCrmDataByIdMutation>;
export type UpdateClientContactCrmDataByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateClientContactCrmDataByIdMutation,
  UpdateClientContactCrmDataByIdMutationVariables
>;
export const UpdateClientContactCrmDataDocument = gql`
  mutation updateClientContactCrmData($set: clientContactCrmData_set_input, $where: clientContactCrmData_bool_exp!) {
    update_clientContactCrmData(_set: $set, where: $where) {
      affected_rows
      returning {
        ...ClientContactCrmData
      }
    }
  }
  ${ClientContactCrmDataFragmentDoc}
`;
export type UpdateClientContactCrmDataMutationFn = Apollo.MutationFunction<
  UpdateClientContactCrmDataMutation,
  UpdateClientContactCrmDataMutationVariables
>;

/**
 * __useUpdateClientContactCrmDataMutation__
 *
 * To run a mutation, you first call `useUpdateClientContactCrmDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientContactCrmDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientContactCrmDataMutation, { data, loading, error }] = useUpdateClientContactCrmDataMutation({
 *   variables: {
 *      set: // value for 'set'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateClientContactCrmDataMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateClientContactCrmDataMutation,
    UpdateClientContactCrmDataMutationVariables
  >
) {
  return Apollo.useMutation<UpdateClientContactCrmDataMutation, UpdateClientContactCrmDataMutationVariables>(
    UpdateClientContactCrmDataDocument,
    baseOptions
  );
}
export type UpdateClientContactCrmDataMutationHookResult = ReturnType<typeof useUpdateClientContactCrmDataMutation>;
export type UpdateClientContactCrmDataMutationResult = Apollo.MutationResult<UpdateClientContactCrmDataMutation>;
export type UpdateClientContactCrmDataMutationOptions = Apollo.BaseMutationOptions<
  UpdateClientContactCrmDataMutation,
  UpdateClientContactCrmDataMutationVariables
>;
export const RemoveClientContactCrmDataModelByIdDocument = gql`
  mutation removeClientContactCrmDataModelById($id: uuid) {
    delete_clientContactCrmData(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
export type RemoveClientContactCrmDataModelByIdMutationFn = Apollo.MutationFunction<
  RemoveClientContactCrmDataModelByIdMutation,
  RemoveClientContactCrmDataModelByIdMutationVariables
>;

/**
 * __useRemoveClientContactCrmDataModelByIdMutation__
 *
 * To run a mutation, you first call `useRemoveClientContactCrmDataModelByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveClientContactCrmDataModelByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeClientContactCrmDataModelByIdMutation, { data, loading, error }] = useRemoveClientContactCrmDataModelByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveClientContactCrmDataModelByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveClientContactCrmDataModelByIdMutation,
    RemoveClientContactCrmDataModelByIdMutationVariables
  >
) {
  return Apollo.useMutation<
    RemoveClientContactCrmDataModelByIdMutation,
    RemoveClientContactCrmDataModelByIdMutationVariables
  >(RemoveClientContactCrmDataModelByIdDocument, baseOptions);
}
export type RemoveClientContactCrmDataModelByIdMutationHookResult = ReturnType<
  typeof useRemoveClientContactCrmDataModelByIdMutation
>;
export type RemoveClientContactCrmDataModelByIdMutationResult = Apollo.MutationResult<RemoveClientContactCrmDataModelByIdMutation>;
export type RemoveClientContactCrmDataModelByIdMutationOptions = Apollo.BaseMutationOptions<
  RemoveClientContactCrmDataModelByIdMutation,
  RemoveClientContactCrmDataModelByIdMutationVariables
>;
export const RemoveClientContactCrmDataModelDocument = gql`
  mutation removeClientContactCrmDataModel($where: clientContactCrmData_bool_exp!) {
    delete_clientContactCrmData(where: $where) {
      affected_rows
    }
  }
`;
export type RemoveClientContactCrmDataModelMutationFn = Apollo.MutationFunction<
  RemoveClientContactCrmDataModelMutation,
  RemoveClientContactCrmDataModelMutationVariables
>;

/**
 * __useRemoveClientContactCrmDataModelMutation__
 *
 * To run a mutation, you first call `useRemoveClientContactCrmDataModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveClientContactCrmDataModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeClientContactCrmDataModelMutation, { data, loading, error }] = useRemoveClientContactCrmDataModelMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useRemoveClientContactCrmDataModelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveClientContactCrmDataModelMutation,
    RemoveClientContactCrmDataModelMutationVariables
  >
) {
  return Apollo.useMutation<RemoveClientContactCrmDataModelMutation, RemoveClientContactCrmDataModelMutationVariables>(
    RemoveClientContactCrmDataModelDocument,
    baseOptions
  );
}
export type RemoveClientContactCrmDataModelMutationHookResult = ReturnType<
  typeof useRemoveClientContactCrmDataModelMutation
>;
export type RemoveClientContactCrmDataModelMutationResult = Apollo.MutationResult<RemoveClientContactCrmDataModelMutation>;
export type RemoveClientContactCrmDataModelMutationOptions = Apollo.BaseMutationOptions<
  RemoveClientContactCrmDataModelMutation,
  RemoveClientContactCrmDataModelMutationVariables
>;
export const QueryClientExternalByIdDocument = gql`
  query queryClientExternalById($clientId: uuid!) {
    client_by_pk(id: $clientId) {
      ...ClientExternal
    }
  }
  ${ClientExternalFragmentDoc}
`;

/**
 * __useQueryClientExternalByIdQuery__
 *
 * To run a query within a React component, call `useQueryClientExternalByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryClientExternalByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryClientExternalByIdQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useQueryClientExternalByIdQuery(
  baseOptions: Apollo.QueryHookOptions<QueryClientExternalByIdQuery, QueryClientExternalByIdQueryVariables>
) {
  return Apollo.useQuery<QueryClientExternalByIdQuery, QueryClientExternalByIdQueryVariables>(
    QueryClientExternalByIdDocument,
    baseOptions
  );
}
export function useQueryClientExternalByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryClientExternalByIdQuery, QueryClientExternalByIdQueryVariables>
) {
  return Apollo.useLazyQuery<QueryClientExternalByIdQuery, QueryClientExternalByIdQueryVariables>(
    QueryClientExternalByIdDocument,
    baseOptions
  );
}
export type QueryClientExternalByIdQueryHookResult = ReturnType<typeof useQueryClientExternalByIdQuery>;
export type QueryClientExternalByIdLazyQueryHookResult = ReturnType<typeof useQueryClientExternalByIdLazyQuery>;
export type QueryClientExternalByIdQueryResult = Apollo.QueryResult<
  QueryClientExternalByIdQuery,
  QueryClientExternalByIdQueryVariables
>;
export const QueryClientExternalObjectsDocument = gql`
  query queryClientExternalObjects(
    $distinct_on: [client_select_column!]
    $where: client_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [client_order_by!]
  ) {
    client(distinct_on: $distinct_on, where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      ...ClientExternal
    }
  }
  ${ClientExternalFragmentDoc}
`;

/**
 * __useQueryClientExternalObjectsQuery__
 *
 * To run a query within a React component, call `useQueryClientExternalObjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryClientExternalObjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryClientExternalObjectsQuery({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useQueryClientExternalObjectsQuery(
  baseOptions?: Apollo.QueryHookOptions<QueryClientExternalObjectsQuery, QueryClientExternalObjectsQueryVariables>
) {
  return Apollo.useQuery<QueryClientExternalObjectsQuery, QueryClientExternalObjectsQueryVariables>(
    QueryClientExternalObjectsDocument,
    baseOptions
  );
}
export function useQueryClientExternalObjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryClientExternalObjectsQuery, QueryClientExternalObjectsQueryVariables>
) {
  return Apollo.useLazyQuery<QueryClientExternalObjectsQuery, QueryClientExternalObjectsQueryVariables>(
    QueryClientExternalObjectsDocument,
    baseOptions
  );
}
export type QueryClientExternalObjectsQueryHookResult = ReturnType<typeof useQueryClientExternalObjectsQuery>;
export type QueryClientExternalObjectsLazyQueryHookResult = ReturnType<typeof useQueryClientExternalObjectsLazyQuery>;
export type QueryClientExternalObjectsQueryResult = Apollo.QueryResult<
  QueryClientExternalObjectsQuery,
  QueryClientExternalObjectsQueryVariables
>;
export const SubscribeToClientExternalByIdDocument = gql`
  subscription subscribeToClientExternalById($clientId: uuid!) {
    client_by_pk(id: $clientId) {
      ...ClientExternal
    }
  }
  ${ClientExternalFragmentDoc}
`;

/**
 * __useSubscribeToClientExternalByIdSubscription__
 *
 * To run a query within a React component, call `useSubscribeToClientExternalByIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToClientExternalByIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToClientExternalByIdSubscription({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useSubscribeToClientExternalByIdSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    SubscribeToClientExternalByIdSubscription,
    SubscribeToClientExternalByIdSubscriptionVariables
  >
) {
  return Apollo.useSubscription<
    SubscribeToClientExternalByIdSubscription,
    SubscribeToClientExternalByIdSubscriptionVariables
  >(SubscribeToClientExternalByIdDocument, baseOptions);
}
export type SubscribeToClientExternalByIdSubscriptionHookResult = ReturnType<
  typeof useSubscribeToClientExternalByIdSubscription
>;
export type SubscribeToClientExternalByIdSubscriptionResult = Apollo.SubscriptionResult<SubscribeToClientExternalByIdSubscription>;
export const SubscribeToClientExternalObjectsDocument = gql`
  subscription subscribeToClientExternalObjects(
    $distinct_on: [client_select_column!]
    $where: client_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [client_order_by!]
  ) {
    client(distinct_on: $distinct_on, where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      ...ClientExternal
    }
  }
  ${ClientExternalFragmentDoc}
`;

/**
 * __useSubscribeToClientExternalObjectsSubscription__
 *
 * To run a query within a React component, call `useSubscribeToClientExternalObjectsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToClientExternalObjectsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToClientExternalObjectsSubscription({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useSubscribeToClientExternalObjectsSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    SubscribeToClientExternalObjectsSubscription,
    SubscribeToClientExternalObjectsSubscriptionVariables
  >
) {
  return Apollo.useSubscription<
    SubscribeToClientExternalObjectsSubscription,
    SubscribeToClientExternalObjectsSubscriptionVariables
  >(SubscribeToClientExternalObjectsDocument, baseOptions);
}
export type SubscribeToClientExternalObjectsSubscriptionHookResult = ReturnType<
  typeof useSubscribeToClientExternalObjectsSubscription
>;
export type SubscribeToClientExternalObjectsSubscriptionResult = Apollo.SubscriptionResult<SubscribeToClientExternalObjectsSubscription>;
export const InsertClientExternalDocument = gql`
  mutation insertClientExternal($objects: [client_insert_input!]!) {
    insert_client(objects: $objects) {
      affected_rows
      returning {
        ...ClientExternal
      }
    }
  }
  ${ClientExternalFragmentDoc}
`;
export type InsertClientExternalMutationFn = Apollo.MutationFunction<
  InsertClientExternalMutation,
  InsertClientExternalMutationVariables
>;

/**
 * __useInsertClientExternalMutation__
 *
 * To run a mutation, you first call `useInsertClientExternalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertClientExternalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertClientExternalMutation, { data, loading, error }] = useInsertClientExternalMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertClientExternalMutation(
  baseOptions?: Apollo.MutationHookOptions<InsertClientExternalMutation, InsertClientExternalMutationVariables>
) {
  return Apollo.useMutation<InsertClientExternalMutation, InsertClientExternalMutationVariables>(
    InsertClientExternalDocument,
    baseOptions
  );
}
export type InsertClientExternalMutationHookResult = ReturnType<typeof useInsertClientExternalMutation>;
export type InsertClientExternalMutationResult = Apollo.MutationResult<InsertClientExternalMutation>;
export type InsertClientExternalMutationOptions = Apollo.BaseMutationOptions<
  InsertClientExternalMutation,
  InsertClientExternalMutationVariables
>;
export const InsertClientExternalWithOnConflictDocument = gql`
  mutation insertClientExternalWithOnConflict($objects: [client_insert_input!]!, $onConflict: client_on_conflict) {
    insert_client(objects: $objects, on_conflict: $onConflict) {
      affected_rows
      returning {
        ...ClientExternal
      }
    }
  }
  ${ClientExternalFragmentDoc}
`;
export type InsertClientExternalWithOnConflictMutationFn = Apollo.MutationFunction<
  InsertClientExternalWithOnConflictMutation,
  InsertClientExternalWithOnConflictMutationVariables
>;

/**
 * __useInsertClientExternalWithOnConflictMutation__
 *
 * To run a mutation, you first call `useInsertClientExternalWithOnConflictMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertClientExternalWithOnConflictMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertClientExternalWithOnConflictMutation, { data, loading, error }] = useInsertClientExternalWithOnConflictMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *      onConflict: // value for 'onConflict'
 *   },
 * });
 */
export function useInsertClientExternalWithOnConflictMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertClientExternalWithOnConflictMutation,
    InsertClientExternalWithOnConflictMutationVariables
  >
) {
  return Apollo.useMutation<
    InsertClientExternalWithOnConflictMutation,
    InsertClientExternalWithOnConflictMutationVariables
  >(InsertClientExternalWithOnConflictDocument, baseOptions);
}
export type InsertClientExternalWithOnConflictMutationHookResult = ReturnType<
  typeof useInsertClientExternalWithOnConflictMutation
>;
export type InsertClientExternalWithOnConflictMutationResult = Apollo.MutationResult<InsertClientExternalWithOnConflictMutation>;
export type InsertClientExternalWithOnConflictMutationOptions = Apollo.BaseMutationOptions<
  InsertClientExternalWithOnConflictMutation,
  InsertClientExternalWithOnConflictMutationVariables
>;
export const UpdateClientExternalByIdDocument = gql`
  mutation updateClientExternalById($id: uuid, $set: client_set_input) {
    update_client(_set: $set, where: { id: { _eq: $id } }) {
      affected_rows
      returning {
        ...ClientExternal
      }
    }
  }
  ${ClientExternalFragmentDoc}
`;
export type UpdateClientExternalByIdMutationFn = Apollo.MutationFunction<
  UpdateClientExternalByIdMutation,
  UpdateClientExternalByIdMutationVariables
>;

/**
 * __useUpdateClientExternalByIdMutation__
 *
 * To run a mutation, you first call `useUpdateClientExternalByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientExternalByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientExternalByIdMutation, { data, loading, error }] = useUpdateClientExternalByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateClientExternalByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateClientExternalByIdMutation, UpdateClientExternalByIdMutationVariables>
) {
  return Apollo.useMutation<UpdateClientExternalByIdMutation, UpdateClientExternalByIdMutationVariables>(
    UpdateClientExternalByIdDocument,
    baseOptions
  );
}
export type UpdateClientExternalByIdMutationHookResult = ReturnType<typeof useUpdateClientExternalByIdMutation>;
export type UpdateClientExternalByIdMutationResult = Apollo.MutationResult<UpdateClientExternalByIdMutation>;
export type UpdateClientExternalByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateClientExternalByIdMutation,
  UpdateClientExternalByIdMutationVariables
>;
export const UpdateClientExternalDocument = gql`
  mutation updateClientExternal($set: client_set_input, $where: client_bool_exp!) {
    update_client(_set: $set, where: $where) {
      affected_rows
      returning {
        ...ClientExternal
      }
    }
  }
  ${ClientExternalFragmentDoc}
`;
export type UpdateClientExternalMutationFn = Apollo.MutationFunction<
  UpdateClientExternalMutation,
  UpdateClientExternalMutationVariables
>;

/**
 * __useUpdateClientExternalMutation__
 *
 * To run a mutation, you first call `useUpdateClientExternalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientExternalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientExternalMutation, { data, loading, error }] = useUpdateClientExternalMutation({
 *   variables: {
 *      set: // value for 'set'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateClientExternalMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateClientExternalMutation, UpdateClientExternalMutationVariables>
) {
  return Apollo.useMutation<UpdateClientExternalMutation, UpdateClientExternalMutationVariables>(
    UpdateClientExternalDocument,
    baseOptions
  );
}
export type UpdateClientExternalMutationHookResult = ReturnType<typeof useUpdateClientExternalMutation>;
export type UpdateClientExternalMutationResult = Apollo.MutationResult<UpdateClientExternalMutation>;
export type UpdateClientExternalMutationOptions = Apollo.BaseMutationOptions<
  UpdateClientExternalMutation,
  UpdateClientExternalMutationVariables
>;
export const RemoveClientModelByIdDocument = gql`
  mutation removeClientModelById($id: uuid) {
    delete_client(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
export type RemoveClientModelByIdMutationFn = Apollo.MutationFunction<
  RemoveClientModelByIdMutation,
  RemoveClientModelByIdMutationVariables
>;

/**
 * __useRemoveClientModelByIdMutation__
 *
 * To run a mutation, you first call `useRemoveClientModelByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveClientModelByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeClientModelByIdMutation, { data, loading, error }] = useRemoveClientModelByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveClientModelByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveClientModelByIdMutation, RemoveClientModelByIdMutationVariables>
) {
  return Apollo.useMutation<RemoveClientModelByIdMutation, RemoveClientModelByIdMutationVariables>(
    RemoveClientModelByIdDocument,
    baseOptions
  );
}
export type RemoveClientModelByIdMutationHookResult = ReturnType<typeof useRemoveClientModelByIdMutation>;
export type RemoveClientModelByIdMutationResult = Apollo.MutationResult<RemoveClientModelByIdMutation>;
export type RemoveClientModelByIdMutationOptions = Apollo.BaseMutationOptions<
  RemoveClientModelByIdMutation,
  RemoveClientModelByIdMutationVariables
>;
export const RemoveClientModelDocument = gql`
  mutation removeClientModel($where: client_bool_exp!) {
    delete_client(where: $where) {
      affected_rows
    }
  }
`;
export type RemoveClientModelMutationFn = Apollo.MutationFunction<
  RemoveClientModelMutation,
  RemoveClientModelMutationVariables
>;

/**
 * __useRemoveClientModelMutation__
 *
 * To run a mutation, you first call `useRemoveClientModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveClientModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeClientModelMutation, { data, loading, error }] = useRemoveClientModelMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useRemoveClientModelMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveClientModelMutation, RemoveClientModelMutationVariables>
) {
  return Apollo.useMutation<RemoveClientModelMutation, RemoveClientModelMutationVariables>(
    RemoveClientModelDocument,
    baseOptions
  );
}
export type RemoveClientModelMutationHookResult = ReturnType<typeof useRemoveClientModelMutation>;
export type RemoveClientModelMutationResult = Apollo.MutationResult<RemoveClientModelMutation>;
export type RemoveClientModelMutationOptions = Apollo.BaseMutationOptions<
  RemoveClientModelMutation,
  RemoveClientModelMutationVariables
>;
export const QueryClientQaByIdDocument = gql`
  query queryClientQaById($clientId: uuid!) {
    client_by_pk(id: $clientId) {
      ...ClientQa
    }
  }
  ${ClientQaFragmentDoc}
`;

/**
 * __useQueryClientQaByIdQuery__
 *
 * To run a query within a React component, call `useQueryClientQaByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryClientQaByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryClientQaByIdQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useQueryClientQaByIdQuery(
  baseOptions: Apollo.QueryHookOptions<QueryClientQaByIdQuery, QueryClientQaByIdQueryVariables>
) {
  return Apollo.useQuery<QueryClientQaByIdQuery, QueryClientQaByIdQueryVariables>(
    QueryClientQaByIdDocument,
    baseOptions
  );
}
export function useQueryClientQaByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryClientQaByIdQuery, QueryClientQaByIdQueryVariables>
) {
  return Apollo.useLazyQuery<QueryClientQaByIdQuery, QueryClientQaByIdQueryVariables>(
    QueryClientQaByIdDocument,
    baseOptions
  );
}
export type QueryClientQaByIdQueryHookResult = ReturnType<typeof useQueryClientQaByIdQuery>;
export type QueryClientQaByIdLazyQueryHookResult = ReturnType<typeof useQueryClientQaByIdLazyQuery>;
export type QueryClientQaByIdQueryResult = Apollo.QueryResult<QueryClientQaByIdQuery, QueryClientQaByIdQueryVariables>;
export const QueryClientQaObjectsDocument = gql`
  query queryClientQaObjects(
    $distinct_on: [client_select_column!]
    $where: client_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [client_order_by!]
  ) {
    client(distinct_on: $distinct_on, where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      ...ClientQa
    }
  }
  ${ClientQaFragmentDoc}
`;

/**
 * __useQueryClientQaObjectsQuery__
 *
 * To run a query within a React component, call `useQueryClientQaObjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryClientQaObjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryClientQaObjectsQuery({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useQueryClientQaObjectsQuery(
  baseOptions?: Apollo.QueryHookOptions<QueryClientQaObjectsQuery, QueryClientQaObjectsQueryVariables>
) {
  return Apollo.useQuery<QueryClientQaObjectsQuery, QueryClientQaObjectsQueryVariables>(
    QueryClientQaObjectsDocument,
    baseOptions
  );
}
export function useQueryClientQaObjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryClientQaObjectsQuery, QueryClientQaObjectsQueryVariables>
) {
  return Apollo.useLazyQuery<QueryClientQaObjectsQuery, QueryClientQaObjectsQueryVariables>(
    QueryClientQaObjectsDocument,
    baseOptions
  );
}
export type QueryClientQaObjectsQueryHookResult = ReturnType<typeof useQueryClientQaObjectsQuery>;
export type QueryClientQaObjectsLazyQueryHookResult = ReturnType<typeof useQueryClientQaObjectsLazyQuery>;
export type QueryClientQaObjectsQueryResult = Apollo.QueryResult<
  QueryClientQaObjectsQuery,
  QueryClientQaObjectsQueryVariables
>;
export const SubscribeToClientQaByIdDocument = gql`
  subscription subscribeToClientQaById($clientId: uuid!) {
    client_by_pk(id: $clientId) {
      ...ClientQa
    }
  }
  ${ClientQaFragmentDoc}
`;

/**
 * __useSubscribeToClientQaByIdSubscription__
 *
 * To run a query within a React component, call `useSubscribeToClientQaByIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToClientQaByIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToClientQaByIdSubscription({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useSubscribeToClientQaByIdSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    SubscribeToClientQaByIdSubscription,
    SubscribeToClientQaByIdSubscriptionVariables
  >
) {
  return Apollo.useSubscription<SubscribeToClientQaByIdSubscription, SubscribeToClientQaByIdSubscriptionVariables>(
    SubscribeToClientQaByIdDocument,
    baseOptions
  );
}
export type SubscribeToClientQaByIdSubscriptionHookResult = ReturnType<typeof useSubscribeToClientQaByIdSubscription>;
export type SubscribeToClientQaByIdSubscriptionResult = Apollo.SubscriptionResult<SubscribeToClientQaByIdSubscription>;
export const SubscribeToClientQaObjectsDocument = gql`
  subscription subscribeToClientQaObjects(
    $distinct_on: [client_select_column!]
    $where: client_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [client_order_by!]
  ) {
    client(distinct_on: $distinct_on, where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      ...ClientQa
    }
  }
  ${ClientQaFragmentDoc}
`;

/**
 * __useSubscribeToClientQaObjectsSubscription__
 *
 * To run a query within a React component, call `useSubscribeToClientQaObjectsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToClientQaObjectsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToClientQaObjectsSubscription({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useSubscribeToClientQaObjectsSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    SubscribeToClientQaObjectsSubscription,
    SubscribeToClientQaObjectsSubscriptionVariables
  >
) {
  return Apollo.useSubscription<
    SubscribeToClientQaObjectsSubscription,
    SubscribeToClientQaObjectsSubscriptionVariables
  >(SubscribeToClientQaObjectsDocument, baseOptions);
}
export type SubscribeToClientQaObjectsSubscriptionHookResult = ReturnType<
  typeof useSubscribeToClientQaObjectsSubscription
>;
export type SubscribeToClientQaObjectsSubscriptionResult = Apollo.SubscriptionResult<SubscribeToClientQaObjectsSubscription>;
export const InsertClientQaDocument = gql`
  mutation insertClientQa($objects: [client_insert_input!]!) {
    insert_client(objects: $objects) {
      affected_rows
      returning {
        ...ClientQa
      }
    }
  }
  ${ClientQaFragmentDoc}
`;
export type InsertClientQaMutationFn = Apollo.MutationFunction<InsertClientQaMutation, InsertClientQaMutationVariables>;

/**
 * __useInsertClientQaMutation__
 *
 * To run a mutation, you first call `useInsertClientQaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertClientQaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertClientQaMutation, { data, loading, error }] = useInsertClientQaMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertClientQaMutation(
  baseOptions?: Apollo.MutationHookOptions<InsertClientQaMutation, InsertClientQaMutationVariables>
) {
  return Apollo.useMutation<InsertClientQaMutation, InsertClientQaMutationVariables>(
    InsertClientQaDocument,
    baseOptions
  );
}
export type InsertClientQaMutationHookResult = ReturnType<typeof useInsertClientQaMutation>;
export type InsertClientQaMutationResult = Apollo.MutationResult<InsertClientQaMutation>;
export type InsertClientQaMutationOptions = Apollo.BaseMutationOptions<
  InsertClientQaMutation,
  InsertClientQaMutationVariables
>;
export const InsertClientQaWithOnConflictDocument = gql`
  mutation insertClientQaWithOnConflict($objects: [client_insert_input!]!, $onConflict: client_on_conflict) {
    insert_client(objects: $objects, on_conflict: $onConflict) {
      affected_rows
      returning {
        ...ClientQa
      }
    }
  }
  ${ClientQaFragmentDoc}
`;
export type InsertClientQaWithOnConflictMutationFn = Apollo.MutationFunction<
  InsertClientQaWithOnConflictMutation,
  InsertClientQaWithOnConflictMutationVariables
>;

/**
 * __useInsertClientQaWithOnConflictMutation__
 *
 * To run a mutation, you first call `useInsertClientQaWithOnConflictMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertClientQaWithOnConflictMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertClientQaWithOnConflictMutation, { data, loading, error }] = useInsertClientQaWithOnConflictMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *      onConflict: // value for 'onConflict'
 *   },
 * });
 */
export function useInsertClientQaWithOnConflictMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertClientQaWithOnConflictMutation,
    InsertClientQaWithOnConflictMutationVariables
  >
) {
  return Apollo.useMutation<InsertClientQaWithOnConflictMutation, InsertClientQaWithOnConflictMutationVariables>(
    InsertClientQaWithOnConflictDocument,
    baseOptions
  );
}
export type InsertClientQaWithOnConflictMutationHookResult = ReturnType<typeof useInsertClientQaWithOnConflictMutation>;
export type InsertClientQaWithOnConflictMutationResult = Apollo.MutationResult<InsertClientQaWithOnConflictMutation>;
export type InsertClientQaWithOnConflictMutationOptions = Apollo.BaseMutationOptions<
  InsertClientQaWithOnConflictMutation,
  InsertClientQaWithOnConflictMutationVariables
>;
export const UpdateClientQaByIdDocument = gql`
  mutation updateClientQaById($id: uuid, $set: client_set_input) {
    update_client(_set: $set, where: { id: { _eq: $id } }) {
      affected_rows
      returning {
        ...ClientQa
      }
    }
  }
  ${ClientQaFragmentDoc}
`;
export type UpdateClientQaByIdMutationFn = Apollo.MutationFunction<
  UpdateClientQaByIdMutation,
  UpdateClientQaByIdMutationVariables
>;

/**
 * __useUpdateClientQaByIdMutation__
 *
 * To run a mutation, you first call `useUpdateClientQaByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientQaByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientQaByIdMutation, { data, loading, error }] = useUpdateClientQaByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateClientQaByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateClientQaByIdMutation, UpdateClientQaByIdMutationVariables>
) {
  return Apollo.useMutation<UpdateClientQaByIdMutation, UpdateClientQaByIdMutationVariables>(
    UpdateClientQaByIdDocument,
    baseOptions
  );
}
export type UpdateClientQaByIdMutationHookResult = ReturnType<typeof useUpdateClientQaByIdMutation>;
export type UpdateClientQaByIdMutationResult = Apollo.MutationResult<UpdateClientQaByIdMutation>;
export type UpdateClientQaByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateClientQaByIdMutation,
  UpdateClientQaByIdMutationVariables
>;
export const UpdateClientQaDocument = gql`
  mutation updateClientQa($set: client_set_input, $where: client_bool_exp!) {
    update_client(_set: $set, where: $where) {
      affected_rows
      returning {
        ...ClientQa
      }
    }
  }
  ${ClientQaFragmentDoc}
`;
export type UpdateClientQaMutationFn = Apollo.MutationFunction<UpdateClientQaMutation, UpdateClientQaMutationVariables>;

/**
 * __useUpdateClientQaMutation__
 *
 * To run a mutation, you first call `useUpdateClientQaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientQaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientQaMutation, { data, loading, error }] = useUpdateClientQaMutation({
 *   variables: {
 *      set: // value for 'set'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateClientQaMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateClientQaMutation, UpdateClientQaMutationVariables>
) {
  return Apollo.useMutation<UpdateClientQaMutation, UpdateClientQaMutationVariables>(
    UpdateClientQaDocument,
    baseOptions
  );
}
export type UpdateClientQaMutationHookResult = ReturnType<typeof useUpdateClientQaMutation>;
export type UpdateClientQaMutationResult = Apollo.MutationResult<UpdateClientQaMutation>;
export type UpdateClientQaMutationOptions = Apollo.BaseMutationOptions<
  UpdateClientQaMutation,
  UpdateClientQaMutationVariables
>;
export const QueryClientInternalByIdDocument = gql`
  query queryClientInternalById($clientId: uuid!) {
    client_by_pk(id: $clientId) {
      ...ClientInternal
    }
  }
  ${ClientInternalFragmentDoc}
`;

/**
 * __useQueryClientInternalByIdQuery__
 *
 * To run a query within a React component, call `useQueryClientInternalByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryClientInternalByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryClientInternalByIdQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useQueryClientInternalByIdQuery(
  baseOptions: Apollo.QueryHookOptions<QueryClientInternalByIdQuery, QueryClientInternalByIdQueryVariables>
) {
  return Apollo.useQuery<QueryClientInternalByIdQuery, QueryClientInternalByIdQueryVariables>(
    QueryClientInternalByIdDocument,
    baseOptions
  );
}
export function useQueryClientInternalByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryClientInternalByIdQuery, QueryClientInternalByIdQueryVariables>
) {
  return Apollo.useLazyQuery<QueryClientInternalByIdQuery, QueryClientInternalByIdQueryVariables>(
    QueryClientInternalByIdDocument,
    baseOptions
  );
}
export type QueryClientInternalByIdQueryHookResult = ReturnType<typeof useQueryClientInternalByIdQuery>;
export type QueryClientInternalByIdLazyQueryHookResult = ReturnType<typeof useQueryClientInternalByIdLazyQuery>;
export type QueryClientInternalByIdQueryResult = Apollo.QueryResult<
  QueryClientInternalByIdQuery,
  QueryClientInternalByIdQueryVariables
>;
export const QueryClientInternalObjectsDocument = gql`
  query queryClientInternalObjects(
    $distinct_on: [client_select_column!]
    $where: client_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [client_order_by!]
  ) {
    client(distinct_on: $distinct_on, where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      ...ClientInternal
    }
  }
  ${ClientInternalFragmentDoc}
`;

/**
 * __useQueryClientInternalObjectsQuery__
 *
 * To run a query within a React component, call `useQueryClientInternalObjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryClientInternalObjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryClientInternalObjectsQuery({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useQueryClientInternalObjectsQuery(
  baseOptions?: Apollo.QueryHookOptions<QueryClientInternalObjectsQuery, QueryClientInternalObjectsQueryVariables>
) {
  return Apollo.useQuery<QueryClientInternalObjectsQuery, QueryClientInternalObjectsQueryVariables>(
    QueryClientInternalObjectsDocument,
    baseOptions
  );
}
export function useQueryClientInternalObjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryClientInternalObjectsQuery, QueryClientInternalObjectsQueryVariables>
) {
  return Apollo.useLazyQuery<QueryClientInternalObjectsQuery, QueryClientInternalObjectsQueryVariables>(
    QueryClientInternalObjectsDocument,
    baseOptions
  );
}
export type QueryClientInternalObjectsQueryHookResult = ReturnType<typeof useQueryClientInternalObjectsQuery>;
export type QueryClientInternalObjectsLazyQueryHookResult = ReturnType<typeof useQueryClientInternalObjectsLazyQuery>;
export type QueryClientInternalObjectsQueryResult = Apollo.QueryResult<
  QueryClientInternalObjectsQuery,
  QueryClientInternalObjectsQueryVariables
>;
export const SubscribeToClientInternalByIdDocument = gql`
  subscription subscribeToClientInternalById($clientId: uuid!) {
    client_by_pk(id: $clientId) {
      ...ClientInternal
    }
  }
  ${ClientInternalFragmentDoc}
`;

/**
 * __useSubscribeToClientInternalByIdSubscription__
 *
 * To run a query within a React component, call `useSubscribeToClientInternalByIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToClientInternalByIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToClientInternalByIdSubscription({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useSubscribeToClientInternalByIdSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    SubscribeToClientInternalByIdSubscription,
    SubscribeToClientInternalByIdSubscriptionVariables
  >
) {
  return Apollo.useSubscription<
    SubscribeToClientInternalByIdSubscription,
    SubscribeToClientInternalByIdSubscriptionVariables
  >(SubscribeToClientInternalByIdDocument, baseOptions);
}
export type SubscribeToClientInternalByIdSubscriptionHookResult = ReturnType<
  typeof useSubscribeToClientInternalByIdSubscription
>;
export type SubscribeToClientInternalByIdSubscriptionResult = Apollo.SubscriptionResult<SubscribeToClientInternalByIdSubscription>;
export const SubscribeToClientInternalObjectsDocument = gql`
  subscription subscribeToClientInternalObjects(
    $distinct_on: [client_select_column!]
    $where: client_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [client_order_by!]
  ) {
    client(distinct_on: $distinct_on, where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      ...ClientInternal
    }
  }
  ${ClientInternalFragmentDoc}
`;

/**
 * __useSubscribeToClientInternalObjectsSubscription__
 *
 * To run a query within a React component, call `useSubscribeToClientInternalObjectsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToClientInternalObjectsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToClientInternalObjectsSubscription({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useSubscribeToClientInternalObjectsSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    SubscribeToClientInternalObjectsSubscription,
    SubscribeToClientInternalObjectsSubscriptionVariables
  >
) {
  return Apollo.useSubscription<
    SubscribeToClientInternalObjectsSubscription,
    SubscribeToClientInternalObjectsSubscriptionVariables
  >(SubscribeToClientInternalObjectsDocument, baseOptions);
}
export type SubscribeToClientInternalObjectsSubscriptionHookResult = ReturnType<
  typeof useSubscribeToClientInternalObjectsSubscription
>;
export type SubscribeToClientInternalObjectsSubscriptionResult = Apollo.SubscriptionResult<SubscribeToClientInternalObjectsSubscription>;
export const InsertClientInternalDocument = gql`
  mutation insertClientInternal($objects: [client_insert_input!]!) {
    insert_client(objects: $objects) {
      affected_rows
      returning {
        ...ClientInternal
      }
    }
  }
  ${ClientInternalFragmentDoc}
`;
export type InsertClientInternalMutationFn = Apollo.MutationFunction<
  InsertClientInternalMutation,
  InsertClientInternalMutationVariables
>;

/**
 * __useInsertClientInternalMutation__
 *
 * To run a mutation, you first call `useInsertClientInternalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertClientInternalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertClientInternalMutation, { data, loading, error }] = useInsertClientInternalMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertClientInternalMutation(
  baseOptions?: Apollo.MutationHookOptions<InsertClientInternalMutation, InsertClientInternalMutationVariables>
) {
  return Apollo.useMutation<InsertClientInternalMutation, InsertClientInternalMutationVariables>(
    InsertClientInternalDocument,
    baseOptions
  );
}
export type InsertClientInternalMutationHookResult = ReturnType<typeof useInsertClientInternalMutation>;
export type InsertClientInternalMutationResult = Apollo.MutationResult<InsertClientInternalMutation>;
export type InsertClientInternalMutationOptions = Apollo.BaseMutationOptions<
  InsertClientInternalMutation,
  InsertClientInternalMutationVariables
>;
export const InsertClientInternalWithOnConflictDocument = gql`
  mutation insertClientInternalWithOnConflict($objects: [client_insert_input!]!, $onConflict: client_on_conflict) {
    insert_client(objects: $objects, on_conflict: $onConflict) {
      affected_rows
      returning {
        ...ClientInternal
      }
    }
  }
  ${ClientInternalFragmentDoc}
`;
export type InsertClientInternalWithOnConflictMutationFn = Apollo.MutationFunction<
  InsertClientInternalWithOnConflictMutation,
  InsertClientInternalWithOnConflictMutationVariables
>;

/**
 * __useInsertClientInternalWithOnConflictMutation__
 *
 * To run a mutation, you first call `useInsertClientInternalWithOnConflictMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertClientInternalWithOnConflictMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertClientInternalWithOnConflictMutation, { data, loading, error }] = useInsertClientInternalWithOnConflictMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *      onConflict: // value for 'onConflict'
 *   },
 * });
 */
export function useInsertClientInternalWithOnConflictMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertClientInternalWithOnConflictMutation,
    InsertClientInternalWithOnConflictMutationVariables
  >
) {
  return Apollo.useMutation<
    InsertClientInternalWithOnConflictMutation,
    InsertClientInternalWithOnConflictMutationVariables
  >(InsertClientInternalWithOnConflictDocument, baseOptions);
}
export type InsertClientInternalWithOnConflictMutationHookResult = ReturnType<
  typeof useInsertClientInternalWithOnConflictMutation
>;
export type InsertClientInternalWithOnConflictMutationResult = Apollo.MutationResult<InsertClientInternalWithOnConflictMutation>;
export type InsertClientInternalWithOnConflictMutationOptions = Apollo.BaseMutationOptions<
  InsertClientInternalWithOnConflictMutation,
  InsertClientInternalWithOnConflictMutationVariables
>;
export const UpdateClientInternalByIdDocument = gql`
  mutation updateClientInternalById($id: uuid, $set: client_set_input) {
    update_client(_set: $set, where: { id: { _eq: $id } }) {
      affected_rows
      returning {
        ...ClientInternal
      }
    }
  }
  ${ClientInternalFragmentDoc}
`;
export type UpdateClientInternalByIdMutationFn = Apollo.MutationFunction<
  UpdateClientInternalByIdMutation,
  UpdateClientInternalByIdMutationVariables
>;

/**
 * __useUpdateClientInternalByIdMutation__
 *
 * To run a mutation, you first call `useUpdateClientInternalByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientInternalByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientInternalByIdMutation, { data, loading, error }] = useUpdateClientInternalByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateClientInternalByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateClientInternalByIdMutation, UpdateClientInternalByIdMutationVariables>
) {
  return Apollo.useMutation<UpdateClientInternalByIdMutation, UpdateClientInternalByIdMutationVariables>(
    UpdateClientInternalByIdDocument,
    baseOptions
  );
}
export type UpdateClientInternalByIdMutationHookResult = ReturnType<typeof useUpdateClientInternalByIdMutation>;
export type UpdateClientInternalByIdMutationResult = Apollo.MutationResult<UpdateClientInternalByIdMutation>;
export type UpdateClientInternalByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateClientInternalByIdMutation,
  UpdateClientInternalByIdMutationVariables
>;
export const UpdateClientInternalDocument = gql`
  mutation updateClientInternal($set: client_set_input, $where: client_bool_exp!) {
    update_client(_set: $set, where: $where) {
      affected_rows
      returning {
        ...ClientInternal
      }
    }
  }
  ${ClientInternalFragmentDoc}
`;
export type UpdateClientInternalMutationFn = Apollo.MutationFunction<
  UpdateClientInternalMutation,
  UpdateClientInternalMutationVariables
>;

/**
 * __useUpdateClientInternalMutation__
 *
 * To run a mutation, you first call `useUpdateClientInternalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientInternalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientInternalMutation, { data, loading, error }] = useUpdateClientInternalMutation({
 *   variables: {
 *      set: // value for 'set'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateClientInternalMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateClientInternalMutation, UpdateClientInternalMutationVariables>
) {
  return Apollo.useMutation<UpdateClientInternalMutation, UpdateClientInternalMutationVariables>(
    UpdateClientInternalDocument,
    baseOptions
  );
}
export type UpdateClientInternalMutationHookResult = ReturnType<typeof useUpdateClientInternalMutation>;
export type UpdateClientInternalMutationResult = Apollo.MutationResult<UpdateClientInternalMutation>;
export type UpdateClientInternalMutationOptions = Apollo.BaseMutationOptions<
  UpdateClientInternalMutation,
  UpdateClientInternalMutationVariables
>;
export const QueryClientSelectByIdDocument = gql`
  query queryClientSelectById($clientId: uuid!) {
    client_by_pk(id: $clientId) {
      ...ClientSelect
    }
  }
  ${ClientSelectFragmentDoc}
`;

/**
 * __useQueryClientSelectByIdQuery__
 *
 * To run a query within a React component, call `useQueryClientSelectByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryClientSelectByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryClientSelectByIdQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useQueryClientSelectByIdQuery(
  baseOptions: Apollo.QueryHookOptions<QueryClientSelectByIdQuery, QueryClientSelectByIdQueryVariables>
) {
  return Apollo.useQuery<QueryClientSelectByIdQuery, QueryClientSelectByIdQueryVariables>(
    QueryClientSelectByIdDocument,
    baseOptions
  );
}
export function useQueryClientSelectByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryClientSelectByIdQuery, QueryClientSelectByIdQueryVariables>
) {
  return Apollo.useLazyQuery<QueryClientSelectByIdQuery, QueryClientSelectByIdQueryVariables>(
    QueryClientSelectByIdDocument,
    baseOptions
  );
}
export type QueryClientSelectByIdQueryHookResult = ReturnType<typeof useQueryClientSelectByIdQuery>;
export type QueryClientSelectByIdLazyQueryHookResult = ReturnType<typeof useQueryClientSelectByIdLazyQuery>;
export type QueryClientSelectByIdQueryResult = Apollo.QueryResult<
  QueryClientSelectByIdQuery,
  QueryClientSelectByIdQueryVariables
>;
export const QueryClientSelectObjectsDocument = gql`
  query queryClientSelectObjects(
    $distinct_on: [client_select_column!]
    $where: client_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [client_order_by!]
  ) {
    client(distinct_on: $distinct_on, where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      ...ClientSelect
    }
  }
  ${ClientSelectFragmentDoc}
`;

/**
 * __useQueryClientSelectObjectsQuery__
 *
 * To run a query within a React component, call `useQueryClientSelectObjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryClientSelectObjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryClientSelectObjectsQuery({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useQueryClientSelectObjectsQuery(
  baseOptions?: Apollo.QueryHookOptions<QueryClientSelectObjectsQuery, QueryClientSelectObjectsQueryVariables>
) {
  return Apollo.useQuery<QueryClientSelectObjectsQuery, QueryClientSelectObjectsQueryVariables>(
    QueryClientSelectObjectsDocument,
    baseOptions
  );
}
export function useQueryClientSelectObjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryClientSelectObjectsQuery, QueryClientSelectObjectsQueryVariables>
) {
  return Apollo.useLazyQuery<QueryClientSelectObjectsQuery, QueryClientSelectObjectsQueryVariables>(
    QueryClientSelectObjectsDocument,
    baseOptions
  );
}
export type QueryClientSelectObjectsQueryHookResult = ReturnType<typeof useQueryClientSelectObjectsQuery>;
export type QueryClientSelectObjectsLazyQueryHookResult = ReturnType<typeof useQueryClientSelectObjectsLazyQuery>;
export type QueryClientSelectObjectsQueryResult = Apollo.QueryResult<
  QueryClientSelectObjectsQuery,
  QueryClientSelectObjectsQueryVariables
>;
export const SubscribeToClientSelectByIdDocument = gql`
  subscription subscribeToClientSelectById($clientId: uuid!) {
    client_by_pk(id: $clientId) {
      ...ClientSelect
    }
  }
  ${ClientSelectFragmentDoc}
`;

/**
 * __useSubscribeToClientSelectByIdSubscription__
 *
 * To run a query within a React component, call `useSubscribeToClientSelectByIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToClientSelectByIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToClientSelectByIdSubscription({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useSubscribeToClientSelectByIdSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    SubscribeToClientSelectByIdSubscription,
    SubscribeToClientSelectByIdSubscriptionVariables
  >
) {
  return Apollo.useSubscription<
    SubscribeToClientSelectByIdSubscription,
    SubscribeToClientSelectByIdSubscriptionVariables
  >(SubscribeToClientSelectByIdDocument, baseOptions);
}
export type SubscribeToClientSelectByIdSubscriptionHookResult = ReturnType<
  typeof useSubscribeToClientSelectByIdSubscription
>;
export type SubscribeToClientSelectByIdSubscriptionResult = Apollo.SubscriptionResult<SubscribeToClientSelectByIdSubscription>;
export const SubscribeToClientSelectObjectsDocument = gql`
  subscription subscribeToClientSelectObjects(
    $distinct_on: [client_select_column!]
    $where: client_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [client_order_by!]
  ) {
    client(distinct_on: $distinct_on, where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      ...ClientSelect
    }
  }
  ${ClientSelectFragmentDoc}
`;

/**
 * __useSubscribeToClientSelectObjectsSubscription__
 *
 * To run a query within a React component, call `useSubscribeToClientSelectObjectsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToClientSelectObjectsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToClientSelectObjectsSubscription({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useSubscribeToClientSelectObjectsSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    SubscribeToClientSelectObjectsSubscription,
    SubscribeToClientSelectObjectsSubscriptionVariables
  >
) {
  return Apollo.useSubscription<
    SubscribeToClientSelectObjectsSubscription,
    SubscribeToClientSelectObjectsSubscriptionVariables
  >(SubscribeToClientSelectObjectsDocument, baseOptions);
}
export type SubscribeToClientSelectObjectsSubscriptionHookResult = ReturnType<
  typeof useSubscribeToClientSelectObjectsSubscription
>;
export type SubscribeToClientSelectObjectsSubscriptionResult = Apollo.SubscriptionResult<SubscribeToClientSelectObjectsSubscription>;
export const InsertClientSelectDocument = gql`
  mutation insertClientSelect($objects: [client_insert_input!]!) {
    insert_client(objects: $objects) {
      affected_rows
      returning {
        ...ClientSelect
      }
    }
  }
  ${ClientSelectFragmentDoc}
`;
export type InsertClientSelectMutationFn = Apollo.MutationFunction<
  InsertClientSelectMutation,
  InsertClientSelectMutationVariables
>;

/**
 * __useInsertClientSelectMutation__
 *
 * To run a mutation, you first call `useInsertClientSelectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertClientSelectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertClientSelectMutation, { data, loading, error }] = useInsertClientSelectMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertClientSelectMutation(
  baseOptions?: Apollo.MutationHookOptions<InsertClientSelectMutation, InsertClientSelectMutationVariables>
) {
  return Apollo.useMutation<InsertClientSelectMutation, InsertClientSelectMutationVariables>(
    InsertClientSelectDocument,
    baseOptions
  );
}
export type InsertClientSelectMutationHookResult = ReturnType<typeof useInsertClientSelectMutation>;
export type InsertClientSelectMutationResult = Apollo.MutationResult<InsertClientSelectMutation>;
export type InsertClientSelectMutationOptions = Apollo.BaseMutationOptions<
  InsertClientSelectMutation,
  InsertClientSelectMutationVariables
>;
export const InsertClientSelectWithOnConflictDocument = gql`
  mutation insertClientSelectWithOnConflict($objects: [client_insert_input!]!, $onConflict: client_on_conflict) {
    insert_client(objects: $objects, on_conflict: $onConflict) {
      affected_rows
      returning {
        ...ClientSelect
      }
    }
  }
  ${ClientSelectFragmentDoc}
`;
export type InsertClientSelectWithOnConflictMutationFn = Apollo.MutationFunction<
  InsertClientSelectWithOnConflictMutation,
  InsertClientSelectWithOnConflictMutationVariables
>;

/**
 * __useInsertClientSelectWithOnConflictMutation__
 *
 * To run a mutation, you first call `useInsertClientSelectWithOnConflictMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertClientSelectWithOnConflictMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertClientSelectWithOnConflictMutation, { data, loading, error }] = useInsertClientSelectWithOnConflictMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *      onConflict: // value for 'onConflict'
 *   },
 * });
 */
export function useInsertClientSelectWithOnConflictMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertClientSelectWithOnConflictMutation,
    InsertClientSelectWithOnConflictMutationVariables
  >
) {
  return Apollo.useMutation<
    InsertClientSelectWithOnConflictMutation,
    InsertClientSelectWithOnConflictMutationVariables
  >(InsertClientSelectWithOnConflictDocument, baseOptions);
}
export type InsertClientSelectWithOnConflictMutationHookResult = ReturnType<
  typeof useInsertClientSelectWithOnConflictMutation
>;
export type InsertClientSelectWithOnConflictMutationResult = Apollo.MutationResult<InsertClientSelectWithOnConflictMutation>;
export type InsertClientSelectWithOnConflictMutationOptions = Apollo.BaseMutationOptions<
  InsertClientSelectWithOnConflictMutation,
  InsertClientSelectWithOnConflictMutationVariables
>;
export const UpdateClientSelectByIdDocument = gql`
  mutation updateClientSelectById($id: uuid, $set: client_set_input) {
    update_client(_set: $set, where: { id: { _eq: $id } }) {
      affected_rows
      returning {
        ...ClientSelect
      }
    }
  }
  ${ClientSelectFragmentDoc}
`;
export type UpdateClientSelectByIdMutationFn = Apollo.MutationFunction<
  UpdateClientSelectByIdMutation,
  UpdateClientSelectByIdMutationVariables
>;

/**
 * __useUpdateClientSelectByIdMutation__
 *
 * To run a mutation, you first call `useUpdateClientSelectByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientSelectByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientSelectByIdMutation, { data, loading, error }] = useUpdateClientSelectByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateClientSelectByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateClientSelectByIdMutation, UpdateClientSelectByIdMutationVariables>
) {
  return Apollo.useMutation<UpdateClientSelectByIdMutation, UpdateClientSelectByIdMutationVariables>(
    UpdateClientSelectByIdDocument,
    baseOptions
  );
}
export type UpdateClientSelectByIdMutationHookResult = ReturnType<typeof useUpdateClientSelectByIdMutation>;
export type UpdateClientSelectByIdMutationResult = Apollo.MutationResult<UpdateClientSelectByIdMutation>;
export type UpdateClientSelectByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateClientSelectByIdMutation,
  UpdateClientSelectByIdMutationVariables
>;
export const UpdateClientSelectDocument = gql`
  mutation updateClientSelect($set: client_set_input, $where: client_bool_exp!) {
    update_client(_set: $set, where: $where) {
      affected_rows
      returning {
        ...ClientSelect
      }
    }
  }
  ${ClientSelectFragmentDoc}
`;
export type UpdateClientSelectMutationFn = Apollo.MutationFunction<
  UpdateClientSelectMutation,
  UpdateClientSelectMutationVariables
>;

/**
 * __useUpdateClientSelectMutation__
 *
 * To run a mutation, you first call `useUpdateClientSelectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientSelectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientSelectMutation, { data, loading, error }] = useUpdateClientSelectMutation({
 *   variables: {
 *      set: // value for 'set'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateClientSelectMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateClientSelectMutation, UpdateClientSelectMutationVariables>
) {
  return Apollo.useMutation<UpdateClientSelectMutation, UpdateClientSelectMutationVariables>(
    UpdateClientSelectDocument,
    baseOptions
  );
}
export type UpdateClientSelectMutationHookResult = ReturnType<typeof useUpdateClientSelectMutation>;
export type UpdateClientSelectMutationResult = Apollo.MutationResult<UpdateClientSelectMutation>;
export type UpdateClientSelectMutationOptions = Apollo.BaseMutationOptions<
  UpdateClientSelectMutation,
  UpdateClientSelectMutationVariables
>;
export const QueryClientNotificationEmailByIdDocument = gql`
  query queryClientNotificationEmailById($clientNotificationEmailId: uuid!) {
    clientNotificationEmail_by_pk(id: $clientNotificationEmailId) {
      ...ClientNotificationEmail
    }
  }
  ${ClientNotificationEmailFragmentDoc}
`;

/**
 * __useQueryClientNotificationEmailByIdQuery__
 *
 * To run a query within a React component, call `useQueryClientNotificationEmailByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryClientNotificationEmailByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryClientNotificationEmailByIdQuery({
 *   variables: {
 *      clientNotificationEmailId: // value for 'clientNotificationEmailId'
 *   },
 * });
 */
export function useQueryClientNotificationEmailByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryClientNotificationEmailByIdQuery,
    QueryClientNotificationEmailByIdQueryVariables
  >
) {
  return Apollo.useQuery<QueryClientNotificationEmailByIdQuery, QueryClientNotificationEmailByIdQueryVariables>(
    QueryClientNotificationEmailByIdDocument,
    baseOptions
  );
}
export function useQueryClientNotificationEmailByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryClientNotificationEmailByIdQuery,
    QueryClientNotificationEmailByIdQueryVariables
  >
) {
  return Apollo.useLazyQuery<QueryClientNotificationEmailByIdQuery, QueryClientNotificationEmailByIdQueryVariables>(
    QueryClientNotificationEmailByIdDocument,
    baseOptions
  );
}
export type QueryClientNotificationEmailByIdQueryHookResult = ReturnType<
  typeof useQueryClientNotificationEmailByIdQuery
>;
export type QueryClientNotificationEmailByIdLazyQueryHookResult = ReturnType<
  typeof useQueryClientNotificationEmailByIdLazyQuery
>;
export type QueryClientNotificationEmailByIdQueryResult = Apollo.QueryResult<
  QueryClientNotificationEmailByIdQuery,
  QueryClientNotificationEmailByIdQueryVariables
>;
export const QueryClientNotificationEmailObjectsDocument = gql`
  query queryClientNotificationEmailObjects(
    $distinct_on: [clientNotificationEmail_select_column!]
    $where: clientNotificationEmail_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [clientNotificationEmail_order_by!]
  ) {
    clientNotificationEmail(
      distinct_on: $distinct_on
      where: $where
      limit: $limit
      offset: $offset
      order_by: $order_by
    ) {
      ...ClientNotificationEmail
    }
  }
  ${ClientNotificationEmailFragmentDoc}
`;

/**
 * __useQueryClientNotificationEmailObjectsQuery__
 *
 * To run a query within a React component, call `useQueryClientNotificationEmailObjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryClientNotificationEmailObjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryClientNotificationEmailObjectsQuery({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useQueryClientNotificationEmailObjectsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    QueryClientNotificationEmailObjectsQuery,
    QueryClientNotificationEmailObjectsQueryVariables
  >
) {
  return Apollo.useQuery<QueryClientNotificationEmailObjectsQuery, QueryClientNotificationEmailObjectsQueryVariables>(
    QueryClientNotificationEmailObjectsDocument,
    baseOptions
  );
}
export function useQueryClientNotificationEmailObjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryClientNotificationEmailObjectsQuery,
    QueryClientNotificationEmailObjectsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    QueryClientNotificationEmailObjectsQuery,
    QueryClientNotificationEmailObjectsQueryVariables
  >(QueryClientNotificationEmailObjectsDocument, baseOptions);
}
export type QueryClientNotificationEmailObjectsQueryHookResult = ReturnType<
  typeof useQueryClientNotificationEmailObjectsQuery
>;
export type QueryClientNotificationEmailObjectsLazyQueryHookResult = ReturnType<
  typeof useQueryClientNotificationEmailObjectsLazyQuery
>;
export type QueryClientNotificationEmailObjectsQueryResult = Apollo.QueryResult<
  QueryClientNotificationEmailObjectsQuery,
  QueryClientNotificationEmailObjectsQueryVariables
>;
export const SubscribeToClientNotificationEmailByIdDocument = gql`
  subscription subscribeToClientNotificationEmailById($clientNotificationEmailId: uuid!) {
    clientNotificationEmail_by_pk(id: $clientNotificationEmailId) {
      ...ClientNotificationEmail
    }
  }
  ${ClientNotificationEmailFragmentDoc}
`;

/**
 * __useSubscribeToClientNotificationEmailByIdSubscription__
 *
 * To run a query within a React component, call `useSubscribeToClientNotificationEmailByIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToClientNotificationEmailByIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToClientNotificationEmailByIdSubscription({
 *   variables: {
 *      clientNotificationEmailId: // value for 'clientNotificationEmailId'
 *   },
 * });
 */
export function useSubscribeToClientNotificationEmailByIdSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    SubscribeToClientNotificationEmailByIdSubscription,
    SubscribeToClientNotificationEmailByIdSubscriptionVariables
  >
) {
  return Apollo.useSubscription<
    SubscribeToClientNotificationEmailByIdSubscription,
    SubscribeToClientNotificationEmailByIdSubscriptionVariables
  >(SubscribeToClientNotificationEmailByIdDocument, baseOptions);
}
export type SubscribeToClientNotificationEmailByIdSubscriptionHookResult = ReturnType<
  typeof useSubscribeToClientNotificationEmailByIdSubscription
>;
export type SubscribeToClientNotificationEmailByIdSubscriptionResult = Apollo.SubscriptionResult<SubscribeToClientNotificationEmailByIdSubscription>;
export const SubscribeToClientNotificationEmailObjectsDocument = gql`
  subscription subscribeToClientNotificationEmailObjects(
    $distinct_on: [clientNotificationEmail_select_column!]
    $where: clientNotificationEmail_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [clientNotificationEmail_order_by!]
  ) {
    clientNotificationEmail(
      distinct_on: $distinct_on
      where: $where
      limit: $limit
      offset: $offset
      order_by: $order_by
    ) {
      ...ClientNotificationEmail
    }
  }
  ${ClientNotificationEmailFragmentDoc}
`;

/**
 * __useSubscribeToClientNotificationEmailObjectsSubscription__
 *
 * To run a query within a React component, call `useSubscribeToClientNotificationEmailObjectsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToClientNotificationEmailObjectsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToClientNotificationEmailObjectsSubscription({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useSubscribeToClientNotificationEmailObjectsSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    SubscribeToClientNotificationEmailObjectsSubscription,
    SubscribeToClientNotificationEmailObjectsSubscriptionVariables
  >
) {
  return Apollo.useSubscription<
    SubscribeToClientNotificationEmailObjectsSubscription,
    SubscribeToClientNotificationEmailObjectsSubscriptionVariables
  >(SubscribeToClientNotificationEmailObjectsDocument, baseOptions);
}
export type SubscribeToClientNotificationEmailObjectsSubscriptionHookResult = ReturnType<
  typeof useSubscribeToClientNotificationEmailObjectsSubscription
>;
export type SubscribeToClientNotificationEmailObjectsSubscriptionResult = Apollo.SubscriptionResult<SubscribeToClientNotificationEmailObjectsSubscription>;
export const InsertClientNotificationEmailDocument = gql`
  mutation insertClientNotificationEmail($objects: [clientNotificationEmail_insert_input!]!) {
    insert_clientNotificationEmail(objects: $objects) {
      affected_rows
      returning {
        ...ClientNotificationEmail
      }
    }
  }
  ${ClientNotificationEmailFragmentDoc}
`;
export type InsertClientNotificationEmailMutationFn = Apollo.MutationFunction<
  InsertClientNotificationEmailMutation,
  InsertClientNotificationEmailMutationVariables
>;

/**
 * __useInsertClientNotificationEmailMutation__
 *
 * To run a mutation, you first call `useInsertClientNotificationEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertClientNotificationEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertClientNotificationEmailMutation, { data, loading, error }] = useInsertClientNotificationEmailMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertClientNotificationEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertClientNotificationEmailMutation,
    InsertClientNotificationEmailMutationVariables
  >
) {
  return Apollo.useMutation<InsertClientNotificationEmailMutation, InsertClientNotificationEmailMutationVariables>(
    InsertClientNotificationEmailDocument,
    baseOptions
  );
}
export type InsertClientNotificationEmailMutationHookResult = ReturnType<
  typeof useInsertClientNotificationEmailMutation
>;
export type InsertClientNotificationEmailMutationResult = Apollo.MutationResult<InsertClientNotificationEmailMutation>;
export type InsertClientNotificationEmailMutationOptions = Apollo.BaseMutationOptions<
  InsertClientNotificationEmailMutation,
  InsertClientNotificationEmailMutationVariables
>;
export const InsertClientNotificationEmailWithOnConflictDocument = gql`
  mutation insertClientNotificationEmailWithOnConflict(
    $objects: [clientNotificationEmail_insert_input!]!
    $onConflict: clientNotificationEmail_on_conflict
  ) {
    insert_clientNotificationEmail(objects: $objects, on_conflict: $onConflict) {
      affected_rows
      returning {
        ...ClientNotificationEmail
      }
    }
  }
  ${ClientNotificationEmailFragmentDoc}
`;
export type InsertClientNotificationEmailWithOnConflictMutationFn = Apollo.MutationFunction<
  InsertClientNotificationEmailWithOnConflictMutation,
  InsertClientNotificationEmailWithOnConflictMutationVariables
>;

/**
 * __useInsertClientNotificationEmailWithOnConflictMutation__
 *
 * To run a mutation, you first call `useInsertClientNotificationEmailWithOnConflictMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertClientNotificationEmailWithOnConflictMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertClientNotificationEmailWithOnConflictMutation, { data, loading, error }] = useInsertClientNotificationEmailWithOnConflictMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *      onConflict: // value for 'onConflict'
 *   },
 * });
 */
export function useInsertClientNotificationEmailWithOnConflictMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertClientNotificationEmailWithOnConflictMutation,
    InsertClientNotificationEmailWithOnConflictMutationVariables
  >
) {
  return Apollo.useMutation<
    InsertClientNotificationEmailWithOnConflictMutation,
    InsertClientNotificationEmailWithOnConflictMutationVariables
  >(InsertClientNotificationEmailWithOnConflictDocument, baseOptions);
}
export type InsertClientNotificationEmailWithOnConflictMutationHookResult = ReturnType<
  typeof useInsertClientNotificationEmailWithOnConflictMutation
>;
export type InsertClientNotificationEmailWithOnConflictMutationResult = Apollo.MutationResult<InsertClientNotificationEmailWithOnConflictMutation>;
export type InsertClientNotificationEmailWithOnConflictMutationOptions = Apollo.BaseMutationOptions<
  InsertClientNotificationEmailWithOnConflictMutation,
  InsertClientNotificationEmailWithOnConflictMutationVariables
>;
export const UpdateClientNotificationEmailByIdDocument = gql`
  mutation updateClientNotificationEmailById($id: uuid, $set: clientNotificationEmail_set_input) {
    update_clientNotificationEmail(_set: $set, where: { id: { _eq: $id } }) {
      affected_rows
      returning {
        ...ClientNotificationEmail
      }
    }
  }
  ${ClientNotificationEmailFragmentDoc}
`;
export type UpdateClientNotificationEmailByIdMutationFn = Apollo.MutationFunction<
  UpdateClientNotificationEmailByIdMutation,
  UpdateClientNotificationEmailByIdMutationVariables
>;

/**
 * __useUpdateClientNotificationEmailByIdMutation__
 *
 * To run a mutation, you first call `useUpdateClientNotificationEmailByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientNotificationEmailByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientNotificationEmailByIdMutation, { data, loading, error }] = useUpdateClientNotificationEmailByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateClientNotificationEmailByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateClientNotificationEmailByIdMutation,
    UpdateClientNotificationEmailByIdMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateClientNotificationEmailByIdMutation,
    UpdateClientNotificationEmailByIdMutationVariables
  >(UpdateClientNotificationEmailByIdDocument, baseOptions);
}
export type UpdateClientNotificationEmailByIdMutationHookResult = ReturnType<
  typeof useUpdateClientNotificationEmailByIdMutation
>;
export type UpdateClientNotificationEmailByIdMutationResult = Apollo.MutationResult<UpdateClientNotificationEmailByIdMutation>;
export type UpdateClientNotificationEmailByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateClientNotificationEmailByIdMutation,
  UpdateClientNotificationEmailByIdMutationVariables
>;
export const UpdateClientNotificationEmailDocument = gql`
  mutation updateClientNotificationEmail(
    $set: clientNotificationEmail_set_input
    $where: clientNotificationEmail_bool_exp!
  ) {
    update_clientNotificationEmail(_set: $set, where: $where) {
      affected_rows
      returning {
        ...ClientNotificationEmail
      }
    }
  }
  ${ClientNotificationEmailFragmentDoc}
`;
export type UpdateClientNotificationEmailMutationFn = Apollo.MutationFunction<
  UpdateClientNotificationEmailMutation,
  UpdateClientNotificationEmailMutationVariables
>;

/**
 * __useUpdateClientNotificationEmailMutation__
 *
 * To run a mutation, you first call `useUpdateClientNotificationEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientNotificationEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientNotificationEmailMutation, { data, loading, error }] = useUpdateClientNotificationEmailMutation({
 *   variables: {
 *      set: // value for 'set'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateClientNotificationEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateClientNotificationEmailMutation,
    UpdateClientNotificationEmailMutationVariables
  >
) {
  return Apollo.useMutation<UpdateClientNotificationEmailMutation, UpdateClientNotificationEmailMutationVariables>(
    UpdateClientNotificationEmailDocument,
    baseOptions
  );
}
export type UpdateClientNotificationEmailMutationHookResult = ReturnType<
  typeof useUpdateClientNotificationEmailMutation
>;
export type UpdateClientNotificationEmailMutationResult = Apollo.MutationResult<UpdateClientNotificationEmailMutation>;
export type UpdateClientNotificationEmailMutationOptions = Apollo.BaseMutationOptions<
  UpdateClientNotificationEmailMutation,
  UpdateClientNotificationEmailMutationVariables
>;
export const RemoveClientNotificationEmailModelByIdDocument = gql`
  mutation removeClientNotificationEmailModelById($id: uuid) {
    delete_clientNotificationEmail(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
export type RemoveClientNotificationEmailModelByIdMutationFn = Apollo.MutationFunction<
  RemoveClientNotificationEmailModelByIdMutation,
  RemoveClientNotificationEmailModelByIdMutationVariables
>;

/**
 * __useRemoveClientNotificationEmailModelByIdMutation__
 *
 * To run a mutation, you first call `useRemoveClientNotificationEmailModelByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveClientNotificationEmailModelByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeClientNotificationEmailModelByIdMutation, { data, loading, error }] = useRemoveClientNotificationEmailModelByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveClientNotificationEmailModelByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveClientNotificationEmailModelByIdMutation,
    RemoveClientNotificationEmailModelByIdMutationVariables
  >
) {
  return Apollo.useMutation<
    RemoveClientNotificationEmailModelByIdMutation,
    RemoveClientNotificationEmailModelByIdMutationVariables
  >(RemoveClientNotificationEmailModelByIdDocument, baseOptions);
}
export type RemoveClientNotificationEmailModelByIdMutationHookResult = ReturnType<
  typeof useRemoveClientNotificationEmailModelByIdMutation
>;
export type RemoveClientNotificationEmailModelByIdMutationResult = Apollo.MutationResult<RemoveClientNotificationEmailModelByIdMutation>;
export type RemoveClientNotificationEmailModelByIdMutationOptions = Apollo.BaseMutationOptions<
  RemoveClientNotificationEmailModelByIdMutation,
  RemoveClientNotificationEmailModelByIdMutationVariables
>;
export const RemoveClientNotificationEmailModelDocument = gql`
  mutation removeClientNotificationEmailModel($where: clientNotificationEmail_bool_exp!) {
    delete_clientNotificationEmail(where: $where) {
      affected_rows
    }
  }
`;
export type RemoveClientNotificationEmailModelMutationFn = Apollo.MutationFunction<
  RemoveClientNotificationEmailModelMutation,
  RemoveClientNotificationEmailModelMutationVariables
>;

/**
 * __useRemoveClientNotificationEmailModelMutation__
 *
 * To run a mutation, you first call `useRemoveClientNotificationEmailModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveClientNotificationEmailModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeClientNotificationEmailModelMutation, { data, loading, error }] = useRemoveClientNotificationEmailModelMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useRemoveClientNotificationEmailModelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveClientNotificationEmailModelMutation,
    RemoveClientNotificationEmailModelMutationVariables
  >
) {
  return Apollo.useMutation<
    RemoveClientNotificationEmailModelMutation,
    RemoveClientNotificationEmailModelMutationVariables
  >(RemoveClientNotificationEmailModelDocument, baseOptions);
}
export type RemoveClientNotificationEmailModelMutationHookResult = ReturnType<
  typeof useRemoveClientNotificationEmailModelMutation
>;
export type RemoveClientNotificationEmailModelMutationResult = Apollo.MutationResult<RemoveClientNotificationEmailModelMutation>;
export type RemoveClientNotificationEmailModelMutationOptions = Apollo.BaseMutationOptions<
  RemoveClientNotificationEmailModelMutation,
  RemoveClientNotificationEmailModelMutationVariables
>;
export const QueryCloudTaskByIdDocument = gql`
  query queryCloudTaskById($cloudTaskId: uuid!) {
    cloudTask_by_pk(id: $cloudTaskId) {
      ...CloudTask
    }
  }
  ${CloudTaskFragmentDoc}
`;

/**
 * __useQueryCloudTaskByIdQuery__
 *
 * To run a query within a React component, call `useQueryCloudTaskByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryCloudTaskByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryCloudTaskByIdQuery({
 *   variables: {
 *      cloudTaskId: // value for 'cloudTaskId'
 *   },
 * });
 */
export function useQueryCloudTaskByIdQuery(
  baseOptions: Apollo.QueryHookOptions<QueryCloudTaskByIdQuery, QueryCloudTaskByIdQueryVariables>
) {
  return Apollo.useQuery<QueryCloudTaskByIdQuery, QueryCloudTaskByIdQueryVariables>(
    QueryCloudTaskByIdDocument,
    baseOptions
  );
}
export function useQueryCloudTaskByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryCloudTaskByIdQuery, QueryCloudTaskByIdQueryVariables>
) {
  return Apollo.useLazyQuery<QueryCloudTaskByIdQuery, QueryCloudTaskByIdQueryVariables>(
    QueryCloudTaskByIdDocument,
    baseOptions
  );
}
export type QueryCloudTaskByIdQueryHookResult = ReturnType<typeof useQueryCloudTaskByIdQuery>;
export type QueryCloudTaskByIdLazyQueryHookResult = ReturnType<typeof useQueryCloudTaskByIdLazyQuery>;
export type QueryCloudTaskByIdQueryResult = Apollo.QueryResult<
  QueryCloudTaskByIdQuery,
  QueryCloudTaskByIdQueryVariables
>;
export const QueryCloudTaskObjectsDocument = gql`
  query queryCloudTaskObjects(
    $distinct_on: [cloudTask_select_column!]
    $where: cloudTask_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [cloudTask_order_by!]
  ) {
    cloudTask(distinct_on: $distinct_on, where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      ...CloudTask
    }
  }
  ${CloudTaskFragmentDoc}
`;

/**
 * __useQueryCloudTaskObjectsQuery__
 *
 * To run a query within a React component, call `useQueryCloudTaskObjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryCloudTaskObjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryCloudTaskObjectsQuery({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useQueryCloudTaskObjectsQuery(
  baseOptions?: Apollo.QueryHookOptions<QueryCloudTaskObjectsQuery, QueryCloudTaskObjectsQueryVariables>
) {
  return Apollo.useQuery<QueryCloudTaskObjectsQuery, QueryCloudTaskObjectsQueryVariables>(
    QueryCloudTaskObjectsDocument,
    baseOptions
  );
}
export function useQueryCloudTaskObjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryCloudTaskObjectsQuery, QueryCloudTaskObjectsQueryVariables>
) {
  return Apollo.useLazyQuery<QueryCloudTaskObjectsQuery, QueryCloudTaskObjectsQueryVariables>(
    QueryCloudTaskObjectsDocument,
    baseOptions
  );
}
export type QueryCloudTaskObjectsQueryHookResult = ReturnType<typeof useQueryCloudTaskObjectsQuery>;
export type QueryCloudTaskObjectsLazyQueryHookResult = ReturnType<typeof useQueryCloudTaskObjectsLazyQuery>;
export type QueryCloudTaskObjectsQueryResult = Apollo.QueryResult<
  QueryCloudTaskObjectsQuery,
  QueryCloudTaskObjectsQueryVariables
>;
export const SubscribeToCloudTaskByIdDocument = gql`
  subscription subscribeToCloudTaskById($cloudTaskId: uuid!) {
    cloudTask_by_pk(id: $cloudTaskId) {
      ...CloudTask
    }
  }
  ${CloudTaskFragmentDoc}
`;

/**
 * __useSubscribeToCloudTaskByIdSubscription__
 *
 * To run a query within a React component, call `useSubscribeToCloudTaskByIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToCloudTaskByIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToCloudTaskByIdSubscription({
 *   variables: {
 *      cloudTaskId: // value for 'cloudTaskId'
 *   },
 * });
 */
export function useSubscribeToCloudTaskByIdSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    SubscribeToCloudTaskByIdSubscription,
    SubscribeToCloudTaskByIdSubscriptionVariables
  >
) {
  return Apollo.useSubscription<SubscribeToCloudTaskByIdSubscription, SubscribeToCloudTaskByIdSubscriptionVariables>(
    SubscribeToCloudTaskByIdDocument,
    baseOptions
  );
}
export type SubscribeToCloudTaskByIdSubscriptionHookResult = ReturnType<typeof useSubscribeToCloudTaskByIdSubscription>;
export type SubscribeToCloudTaskByIdSubscriptionResult = Apollo.SubscriptionResult<SubscribeToCloudTaskByIdSubscription>;
export const SubscribeToCloudTaskObjectsDocument = gql`
  subscription subscribeToCloudTaskObjects(
    $distinct_on: [cloudTask_select_column!]
    $where: cloudTask_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [cloudTask_order_by!]
  ) {
    cloudTask(distinct_on: $distinct_on, where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      ...CloudTask
    }
  }
  ${CloudTaskFragmentDoc}
`;

/**
 * __useSubscribeToCloudTaskObjectsSubscription__
 *
 * To run a query within a React component, call `useSubscribeToCloudTaskObjectsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToCloudTaskObjectsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToCloudTaskObjectsSubscription({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useSubscribeToCloudTaskObjectsSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    SubscribeToCloudTaskObjectsSubscription,
    SubscribeToCloudTaskObjectsSubscriptionVariables
  >
) {
  return Apollo.useSubscription<
    SubscribeToCloudTaskObjectsSubscription,
    SubscribeToCloudTaskObjectsSubscriptionVariables
  >(SubscribeToCloudTaskObjectsDocument, baseOptions);
}
export type SubscribeToCloudTaskObjectsSubscriptionHookResult = ReturnType<
  typeof useSubscribeToCloudTaskObjectsSubscription
>;
export type SubscribeToCloudTaskObjectsSubscriptionResult = Apollo.SubscriptionResult<SubscribeToCloudTaskObjectsSubscription>;
export const InsertCloudTaskDocument = gql`
  mutation insertCloudTask($objects: [cloudTask_insert_input!]!) {
    insert_cloudTask(objects: $objects) {
      affected_rows
      returning {
        ...CloudTask
      }
    }
  }
  ${CloudTaskFragmentDoc}
`;
export type InsertCloudTaskMutationFn = Apollo.MutationFunction<
  InsertCloudTaskMutation,
  InsertCloudTaskMutationVariables
>;

/**
 * __useInsertCloudTaskMutation__
 *
 * To run a mutation, you first call `useInsertCloudTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertCloudTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertCloudTaskMutation, { data, loading, error }] = useInsertCloudTaskMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertCloudTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<InsertCloudTaskMutation, InsertCloudTaskMutationVariables>
) {
  return Apollo.useMutation<InsertCloudTaskMutation, InsertCloudTaskMutationVariables>(
    InsertCloudTaskDocument,
    baseOptions
  );
}
export type InsertCloudTaskMutationHookResult = ReturnType<typeof useInsertCloudTaskMutation>;
export type InsertCloudTaskMutationResult = Apollo.MutationResult<InsertCloudTaskMutation>;
export type InsertCloudTaskMutationOptions = Apollo.BaseMutationOptions<
  InsertCloudTaskMutation,
  InsertCloudTaskMutationVariables
>;
export const InsertCloudTaskWithOnConflictDocument = gql`
  mutation insertCloudTaskWithOnConflict($objects: [cloudTask_insert_input!]!, $onConflict: cloudTask_on_conflict) {
    insert_cloudTask(objects: $objects, on_conflict: $onConflict) {
      affected_rows
      returning {
        ...CloudTask
      }
    }
  }
  ${CloudTaskFragmentDoc}
`;
export type InsertCloudTaskWithOnConflictMutationFn = Apollo.MutationFunction<
  InsertCloudTaskWithOnConflictMutation,
  InsertCloudTaskWithOnConflictMutationVariables
>;

/**
 * __useInsertCloudTaskWithOnConflictMutation__
 *
 * To run a mutation, you first call `useInsertCloudTaskWithOnConflictMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertCloudTaskWithOnConflictMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertCloudTaskWithOnConflictMutation, { data, loading, error }] = useInsertCloudTaskWithOnConflictMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *      onConflict: // value for 'onConflict'
 *   },
 * });
 */
export function useInsertCloudTaskWithOnConflictMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertCloudTaskWithOnConflictMutation,
    InsertCloudTaskWithOnConflictMutationVariables
  >
) {
  return Apollo.useMutation<InsertCloudTaskWithOnConflictMutation, InsertCloudTaskWithOnConflictMutationVariables>(
    InsertCloudTaskWithOnConflictDocument,
    baseOptions
  );
}
export type InsertCloudTaskWithOnConflictMutationHookResult = ReturnType<
  typeof useInsertCloudTaskWithOnConflictMutation
>;
export type InsertCloudTaskWithOnConflictMutationResult = Apollo.MutationResult<InsertCloudTaskWithOnConflictMutation>;
export type InsertCloudTaskWithOnConflictMutationOptions = Apollo.BaseMutationOptions<
  InsertCloudTaskWithOnConflictMutation,
  InsertCloudTaskWithOnConflictMutationVariables
>;
export const UpdateCloudTaskByIdDocument = gql`
  mutation updateCloudTaskById($id: uuid, $set: cloudTask_set_input) {
    update_cloudTask(_set: $set, where: { id: { _eq: $id } }) {
      affected_rows
      returning {
        ...CloudTask
      }
    }
  }
  ${CloudTaskFragmentDoc}
`;
export type UpdateCloudTaskByIdMutationFn = Apollo.MutationFunction<
  UpdateCloudTaskByIdMutation,
  UpdateCloudTaskByIdMutationVariables
>;

/**
 * __useUpdateCloudTaskByIdMutation__
 *
 * To run a mutation, you first call `useUpdateCloudTaskByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCloudTaskByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCloudTaskByIdMutation, { data, loading, error }] = useUpdateCloudTaskByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateCloudTaskByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateCloudTaskByIdMutation, UpdateCloudTaskByIdMutationVariables>
) {
  return Apollo.useMutation<UpdateCloudTaskByIdMutation, UpdateCloudTaskByIdMutationVariables>(
    UpdateCloudTaskByIdDocument,
    baseOptions
  );
}
export type UpdateCloudTaskByIdMutationHookResult = ReturnType<typeof useUpdateCloudTaskByIdMutation>;
export type UpdateCloudTaskByIdMutationResult = Apollo.MutationResult<UpdateCloudTaskByIdMutation>;
export type UpdateCloudTaskByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateCloudTaskByIdMutation,
  UpdateCloudTaskByIdMutationVariables
>;
export const UpdateCloudTaskDocument = gql`
  mutation updateCloudTask($set: cloudTask_set_input, $where: cloudTask_bool_exp!) {
    update_cloudTask(_set: $set, where: $where) {
      affected_rows
      returning {
        ...CloudTask
      }
    }
  }
  ${CloudTaskFragmentDoc}
`;
export type UpdateCloudTaskMutationFn = Apollo.MutationFunction<
  UpdateCloudTaskMutation,
  UpdateCloudTaskMutationVariables
>;

/**
 * __useUpdateCloudTaskMutation__
 *
 * To run a mutation, you first call `useUpdateCloudTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCloudTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCloudTaskMutation, { data, loading, error }] = useUpdateCloudTaskMutation({
 *   variables: {
 *      set: // value for 'set'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateCloudTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateCloudTaskMutation, UpdateCloudTaskMutationVariables>
) {
  return Apollo.useMutation<UpdateCloudTaskMutation, UpdateCloudTaskMutationVariables>(
    UpdateCloudTaskDocument,
    baseOptions
  );
}
export type UpdateCloudTaskMutationHookResult = ReturnType<typeof useUpdateCloudTaskMutation>;
export type UpdateCloudTaskMutationResult = Apollo.MutationResult<UpdateCloudTaskMutation>;
export type UpdateCloudTaskMutationOptions = Apollo.BaseMutationOptions<
  UpdateCloudTaskMutation,
  UpdateCloudTaskMutationVariables
>;
export const RemoveCloudTaskModelByIdDocument = gql`
  mutation removeCloudTaskModelById($id: uuid) {
    delete_cloudTask(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
export type RemoveCloudTaskModelByIdMutationFn = Apollo.MutationFunction<
  RemoveCloudTaskModelByIdMutation,
  RemoveCloudTaskModelByIdMutationVariables
>;

/**
 * __useRemoveCloudTaskModelByIdMutation__
 *
 * To run a mutation, you first call `useRemoveCloudTaskModelByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCloudTaskModelByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCloudTaskModelByIdMutation, { data, loading, error }] = useRemoveCloudTaskModelByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveCloudTaskModelByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveCloudTaskModelByIdMutation, RemoveCloudTaskModelByIdMutationVariables>
) {
  return Apollo.useMutation<RemoveCloudTaskModelByIdMutation, RemoveCloudTaskModelByIdMutationVariables>(
    RemoveCloudTaskModelByIdDocument,
    baseOptions
  );
}
export type RemoveCloudTaskModelByIdMutationHookResult = ReturnType<typeof useRemoveCloudTaskModelByIdMutation>;
export type RemoveCloudTaskModelByIdMutationResult = Apollo.MutationResult<RemoveCloudTaskModelByIdMutation>;
export type RemoveCloudTaskModelByIdMutationOptions = Apollo.BaseMutationOptions<
  RemoveCloudTaskModelByIdMutation,
  RemoveCloudTaskModelByIdMutationVariables
>;
export const RemoveCloudTaskModelDocument = gql`
  mutation removeCloudTaskModel($where: cloudTask_bool_exp!) {
    delete_cloudTask(where: $where) {
      affected_rows
    }
  }
`;
export type RemoveCloudTaskModelMutationFn = Apollo.MutationFunction<
  RemoveCloudTaskModelMutation,
  RemoveCloudTaskModelMutationVariables
>;

/**
 * __useRemoveCloudTaskModelMutation__
 *
 * To run a mutation, you first call `useRemoveCloudTaskModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCloudTaskModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCloudTaskModelMutation, { data, loading, error }] = useRemoveCloudTaskModelMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useRemoveCloudTaskModelMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveCloudTaskModelMutation, RemoveCloudTaskModelMutationVariables>
) {
  return Apollo.useMutation<RemoveCloudTaskModelMutation, RemoveCloudTaskModelMutationVariables>(
    RemoveCloudTaskModelDocument,
    baseOptions
  );
}
export type RemoveCloudTaskModelMutationHookResult = ReturnType<typeof useRemoveCloudTaskModelMutation>;
export type RemoveCloudTaskModelMutationResult = Apollo.MutationResult<RemoveCloudTaskModelMutation>;
export type RemoveCloudTaskModelMutationOptions = Apollo.BaseMutationOptions<
  RemoveCloudTaskModelMutation,
  RemoveCloudTaskModelMutationVariables
>;
export const QueryClientEmailReportByIdDocument = gql`
  query queryClientEmailReportById($clientEmailReportId: uuid!) {
    clientEmailReport_by_pk(id: $clientEmailReportId) {
      ...ClientEmailReport
    }
  }
  ${ClientEmailReportFragmentDoc}
`;

/**
 * __useQueryClientEmailReportByIdQuery__
 *
 * To run a query within a React component, call `useQueryClientEmailReportByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryClientEmailReportByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryClientEmailReportByIdQuery({
 *   variables: {
 *      clientEmailReportId: // value for 'clientEmailReportId'
 *   },
 * });
 */
export function useQueryClientEmailReportByIdQuery(
  baseOptions: Apollo.QueryHookOptions<QueryClientEmailReportByIdQuery, QueryClientEmailReportByIdQueryVariables>
) {
  return Apollo.useQuery<QueryClientEmailReportByIdQuery, QueryClientEmailReportByIdQueryVariables>(
    QueryClientEmailReportByIdDocument,
    baseOptions
  );
}
export function useQueryClientEmailReportByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryClientEmailReportByIdQuery, QueryClientEmailReportByIdQueryVariables>
) {
  return Apollo.useLazyQuery<QueryClientEmailReportByIdQuery, QueryClientEmailReportByIdQueryVariables>(
    QueryClientEmailReportByIdDocument,
    baseOptions
  );
}
export type QueryClientEmailReportByIdQueryHookResult = ReturnType<typeof useQueryClientEmailReportByIdQuery>;
export type QueryClientEmailReportByIdLazyQueryHookResult = ReturnType<typeof useQueryClientEmailReportByIdLazyQuery>;
export type QueryClientEmailReportByIdQueryResult = Apollo.QueryResult<
  QueryClientEmailReportByIdQuery,
  QueryClientEmailReportByIdQueryVariables
>;
export const QueryClientEmailReportObjectsDocument = gql`
  query queryClientEmailReportObjects(
    $distinct_on: [clientEmailReport_select_column!]
    $where: clientEmailReport_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [clientEmailReport_order_by!]
  ) {
    clientEmailReport(distinct_on: $distinct_on, where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      ...ClientEmailReport
    }
  }
  ${ClientEmailReportFragmentDoc}
`;

/**
 * __useQueryClientEmailReportObjectsQuery__
 *
 * To run a query within a React component, call `useQueryClientEmailReportObjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryClientEmailReportObjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryClientEmailReportObjectsQuery({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useQueryClientEmailReportObjectsQuery(
  baseOptions?: Apollo.QueryHookOptions<QueryClientEmailReportObjectsQuery, QueryClientEmailReportObjectsQueryVariables>
) {
  return Apollo.useQuery<QueryClientEmailReportObjectsQuery, QueryClientEmailReportObjectsQueryVariables>(
    QueryClientEmailReportObjectsDocument,
    baseOptions
  );
}
export function useQueryClientEmailReportObjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryClientEmailReportObjectsQuery,
    QueryClientEmailReportObjectsQueryVariables
  >
) {
  return Apollo.useLazyQuery<QueryClientEmailReportObjectsQuery, QueryClientEmailReportObjectsQueryVariables>(
    QueryClientEmailReportObjectsDocument,
    baseOptions
  );
}
export type QueryClientEmailReportObjectsQueryHookResult = ReturnType<typeof useQueryClientEmailReportObjectsQuery>;
export type QueryClientEmailReportObjectsLazyQueryHookResult = ReturnType<
  typeof useQueryClientEmailReportObjectsLazyQuery
>;
export type QueryClientEmailReportObjectsQueryResult = Apollo.QueryResult<
  QueryClientEmailReportObjectsQuery,
  QueryClientEmailReportObjectsQueryVariables
>;
export const SubscribeToClientEmailReportByIdDocument = gql`
  subscription subscribeToClientEmailReportById($clientEmailReportId: uuid!) {
    clientEmailReport_by_pk(id: $clientEmailReportId) {
      ...ClientEmailReport
    }
  }
  ${ClientEmailReportFragmentDoc}
`;

/**
 * __useSubscribeToClientEmailReportByIdSubscription__
 *
 * To run a query within a React component, call `useSubscribeToClientEmailReportByIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToClientEmailReportByIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToClientEmailReportByIdSubscription({
 *   variables: {
 *      clientEmailReportId: // value for 'clientEmailReportId'
 *   },
 * });
 */
export function useSubscribeToClientEmailReportByIdSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    SubscribeToClientEmailReportByIdSubscription,
    SubscribeToClientEmailReportByIdSubscriptionVariables
  >
) {
  return Apollo.useSubscription<
    SubscribeToClientEmailReportByIdSubscription,
    SubscribeToClientEmailReportByIdSubscriptionVariables
  >(SubscribeToClientEmailReportByIdDocument, baseOptions);
}
export type SubscribeToClientEmailReportByIdSubscriptionHookResult = ReturnType<
  typeof useSubscribeToClientEmailReportByIdSubscription
>;
export type SubscribeToClientEmailReportByIdSubscriptionResult = Apollo.SubscriptionResult<SubscribeToClientEmailReportByIdSubscription>;
export const SubscribeToClientEmailReportObjectsDocument = gql`
  subscription subscribeToClientEmailReportObjects(
    $distinct_on: [clientEmailReport_select_column!]
    $where: clientEmailReport_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [clientEmailReport_order_by!]
  ) {
    clientEmailReport(distinct_on: $distinct_on, where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      ...ClientEmailReport
    }
  }
  ${ClientEmailReportFragmentDoc}
`;

/**
 * __useSubscribeToClientEmailReportObjectsSubscription__
 *
 * To run a query within a React component, call `useSubscribeToClientEmailReportObjectsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToClientEmailReportObjectsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToClientEmailReportObjectsSubscription({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useSubscribeToClientEmailReportObjectsSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    SubscribeToClientEmailReportObjectsSubscription,
    SubscribeToClientEmailReportObjectsSubscriptionVariables
  >
) {
  return Apollo.useSubscription<
    SubscribeToClientEmailReportObjectsSubscription,
    SubscribeToClientEmailReportObjectsSubscriptionVariables
  >(SubscribeToClientEmailReportObjectsDocument, baseOptions);
}
export type SubscribeToClientEmailReportObjectsSubscriptionHookResult = ReturnType<
  typeof useSubscribeToClientEmailReportObjectsSubscription
>;
export type SubscribeToClientEmailReportObjectsSubscriptionResult = Apollo.SubscriptionResult<SubscribeToClientEmailReportObjectsSubscription>;
export const InsertClientEmailReportDocument = gql`
  mutation insertClientEmailReport($objects: [clientEmailReport_insert_input!]!) {
    insert_clientEmailReport(objects: $objects) {
      affected_rows
      returning {
        ...ClientEmailReport
      }
    }
  }
  ${ClientEmailReportFragmentDoc}
`;
export type InsertClientEmailReportMutationFn = Apollo.MutationFunction<
  InsertClientEmailReportMutation,
  InsertClientEmailReportMutationVariables
>;

/**
 * __useInsertClientEmailReportMutation__
 *
 * To run a mutation, you first call `useInsertClientEmailReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertClientEmailReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertClientEmailReportMutation, { data, loading, error }] = useInsertClientEmailReportMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertClientEmailReportMutation(
  baseOptions?: Apollo.MutationHookOptions<InsertClientEmailReportMutation, InsertClientEmailReportMutationVariables>
) {
  return Apollo.useMutation<InsertClientEmailReportMutation, InsertClientEmailReportMutationVariables>(
    InsertClientEmailReportDocument,
    baseOptions
  );
}
export type InsertClientEmailReportMutationHookResult = ReturnType<typeof useInsertClientEmailReportMutation>;
export type InsertClientEmailReportMutationResult = Apollo.MutationResult<InsertClientEmailReportMutation>;
export type InsertClientEmailReportMutationOptions = Apollo.BaseMutationOptions<
  InsertClientEmailReportMutation,
  InsertClientEmailReportMutationVariables
>;
export const InsertClientEmailReportWithOnConflictDocument = gql`
  mutation insertClientEmailReportWithOnConflict(
    $objects: [clientEmailReport_insert_input!]!
    $onConflict: clientEmailReport_on_conflict
  ) {
    insert_clientEmailReport(objects: $objects, on_conflict: $onConflict) {
      affected_rows
      returning {
        ...ClientEmailReport
      }
    }
  }
  ${ClientEmailReportFragmentDoc}
`;
export type InsertClientEmailReportWithOnConflictMutationFn = Apollo.MutationFunction<
  InsertClientEmailReportWithOnConflictMutation,
  InsertClientEmailReportWithOnConflictMutationVariables
>;

/**
 * __useInsertClientEmailReportWithOnConflictMutation__
 *
 * To run a mutation, you first call `useInsertClientEmailReportWithOnConflictMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertClientEmailReportWithOnConflictMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertClientEmailReportWithOnConflictMutation, { data, loading, error }] = useInsertClientEmailReportWithOnConflictMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *      onConflict: // value for 'onConflict'
 *   },
 * });
 */
export function useInsertClientEmailReportWithOnConflictMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertClientEmailReportWithOnConflictMutation,
    InsertClientEmailReportWithOnConflictMutationVariables
  >
) {
  return Apollo.useMutation<
    InsertClientEmailReportWithOnConflictMutation,
    InsertClientEmailReportWithOnConflictMutationVariables
  >(InsertClientEmailReportWithOnConflictDocument, baseOptions);
}
export type InsertClientEmailReportWithOnConflictMutationHookResult = ReturnType<
  typeof useInsertClientEmailReportWithOnConflictMutation
>;
export type InsertClientEmailReportWithOnConflictMutationResult = Apollo.MutationResult<InsertClientEmailReportWithOnConflictMutation>;
export type InsertClientEmailReportWithOnConflictMutationOptions = Apollo.BaseMutationOptions<
  InsertClientEmailReportWithOnConflictMutation,
  InsertClientEmailReportWithOnConflictMutationVariables
>;
export const UpdateClientEmailReportByIdDocument = gql`
  mutation updateClientEmailReportById($id: uuid, $set: clientEmailReport_set_input) {
    update_clientEmailReport(_set: $set, where: { id: { _eq: $id } }) {
      affected_rows
      returning {
        ...ClientEmailReport
      }
    }
  }
  ${ClientEmailReportFragmentDoc}
`;
export type UpdateClientEmailReportByIdMutationFn = Apollo.MutationFunction<
  UpdateClientEmailReportByIdMutation,
  UpdateClientEmailReportByIdMutationVariables
>;

/**
 * __useUpdateClientEmailReportByIdMutation__
 *
 * To run a mutation, you first call `useUpdateClientEmailReportByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientEmailReportByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientEmailReportByIdMutation, { data, loading, error }] = useUpdateClientEmailReportByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateClientEmailReportByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateClientEmailReportByIdMutation,
    UpdateClientEmailReportByIdMutationVariables
  >
) {
  return Apollo.useMutation<UpdateClientEmailReportByIdMutation, UpdateClientEmailReportByIdMutationVariables>(
    UpdateClientEmailReportByIdDocument,
    baseOptions
  );
}
export type UpdateClientEmailReportByIdMutationHookResult = ReturnType<typeof useUpdateClientEmailReportByIdMutation>;
export type UpdateClientEmailReportByIdMutationResult = Apollo.MutationResult<UpdateClientEmailReportByIdMutation>;
export type UpdateClientEmailReportByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateClientEmailReportByIdMutation,
  UpdateClientEmailReportByIdMutationVariables
>;
export const UpdateClientEmailReportDocument = gql`
  mutation updateClientEmailReport($set: clientEmailReport_set_input, $where: clientEmailReport_bool_exp!) {
    update_clientEmailReport(_set: $set, where: $where) {
      affected_rows
      returning {
        ...ClientEmailReport
      }
    }
  }
  ${ClientEmailReportFragmentDoc}
`;
export type UpdateClientEmailReportMutationFn = Apollo.MutationFunction<
  UpdateClientEmailReportMutation,
  UpdateClientEmailReportMutationVariables
>;

/**
 * __useUpdateClientEmailReportMutation__
 *
 * To run a mutation, you first call `useUpdateClientEmailReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientEmailReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientEmailReportMutation, { data, loading, error }] = useUpdateClientEmailReportMutation({
 *   variables: {
 *      set: // value for 'set'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateClientEmailReportMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateClientEmailReportMutation, UpdateClientEmailReportMutationVariables>
) {
  return Apollo.useMutation<UpdateClientEmailReportMutation, UpdateClientEmailReportMutationVariables>(
    UpdateClientEmailReportDocument,
    baseOptions
  );
}
export type UpdateClientEmailReportMutationHookResult = ReturnType<typeof useUpdateClientEmailReportMutation>;
export type UpdateClientEmailReportMutationResult = Apollo.MutationResult<UpdateClientEmailReportMutation>;
export type UpdateClientEmailReportMutationOptions = Apollo.BaseMutationOptions<
  UpdateClientEmailReportMutation,
  UpdateClientEmailReportMutationVariables
>;
export const RemoveClientEmailReportModelByIdDocument = gql`
  mutation removeClientEmailReportModelById($id: uuid) {
    delete_clientEmailReport(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
export type RemoveClientEmailReportModelByIdMutationFn = Apollo.MutationFunction<
  RemoveClientEmailReportModelByIdMutation,
  RemoveClientEmailReportModelByIdMutationVariables
>;

/**
 * __useRemoveClientEmailReportModelByIdMutation__
 *
 * To run a mutation, you first call `useRemoveClientEmailReportModelByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveClientEmailReportModelByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeClientEmailReportModelByIdMutation, { data, loading, error }] = useRemoveClientEmailReportModelByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveClientEmailReportModelByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveClientEmailReportModelByIdMutation,
    RemoveClientEmailReportModelByIdMutationVariables
  >
) {
  return Apollo.useMutation<
    RemoveClientEmailReportModelByIdMutation,
    RemoveClientEmailReportModelByIdMutationVariables
  >(RemoveClientEmailReportModelByIdDocument, baseOptions);
}
export type RemoveClientEmailReportModelByIdMutationHookResult = ReturnType<
  typeof useRemoveClientEmailReportModelByIdMutation
>;
export type RemoveClientEmailReportModelByIdMutationResult = Apollo.MutationResult<RemoveClientEmailReportModelByIdMutation>;
export type RemoveClientEmailReportModelByIdMutationOptions = Apollo.BaseMutationOptions<
  RemoveClientEmailReportModelByIdMutation,
  RemoveClientEmailReportModelByIdMutationVariables
>;
export const RemoveClientEmailReportModelDocument = gql`
  mutation removeClientEmailReportModel($where: clientEmailReport_bool_exp!) {
    delete_clientEmailReport(where: $where) {
      affected_rows
    }
  }
`;
export type RemoveClientEmailReportModelMutationFn = Apollo.MutationFunction<
  RemoveClientEmailReportModelMutation,
  RemoveClientEmailReportModelMutationVariables
>;

/**
 * __useRemoveClientEmailReportModelMutation__
 *
 * To run a mutation, you first call `useRemoveClientEmailReportModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveClientEmailReportModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeClientEmailReportModelMutation, { data, loading, error }] = useRemoveClientEmailReportModelMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useRemoveClientEmailReportModelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveClientEmailReportModelMutation,
    RemoveClientEmailReportModelMutationVariables
  >
) {
  return Apollo.useMutation<RemoveClientEmailReportModelMutation, RemoveClientEmailReportModelMutationVariables>(
    RemoveClientEmailReportModelDocument,
    baseOptions
  );
}
export type RemoveClientEmailReportModelMutationHookResult = ReturnType<typeof useRemoveClientEmailReportModelMutation>;
export type RemoveClientEmailReportModelMutationResult = Apollo.MutationResult<RemoveClientEmailReportModelMutation>;
export type RemoveClientEmailReportModelMutationOptions = Apollo.BaseMutationOptions<
  RemoveClientEmailReportModelMutation,
  RemoveClientEmailReportModelMutationVariables
>;
export const QueryEmailHistoryByIdDocument = gql`
  query queryEmailHistoryById($emailHistoryId: uuid!) {
    emailHistory_by_pk(id: $emailHistoryId) {
      ...EmailHistory
    }
  }
  ${EmailHistoryFragmentDoc}
`;

/**
 * __useQueryEmailHistoryByIdQuery__
 *
 * To run a query within a React component, call `useQueryEmailHistoryByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryEmailHistoryByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryEmailHistoryByIdQuery({
 *   variables: {
 *      emailHistoryId: // value for 'emailHistoryId'
 *   },
 * });
 */
export function useQueryEmailHistoryByIdQuery(
  baseOptions: Apollo.QueryHookOptions<QueryEmailHistoryByIdQuery, QueryEmailHistoryByIdQueryVariables>
) {
  return Apollo.useQuery<QueryEmailHistoryByIdQuery, QueryEmailHistoryByIdQueryVariables>(
    QueryEmailHistoryByIdDocument,
    baseOptions
  );
}
export function useQueryEmailHistoryByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryEmailHistoryByIdQuery, QueryEmailHistoryByIdQueryVariables>
) {
  return Apollo.useLazyQuery<QueryEmailHistoryByIdQuery, QueryEmailHistoryByIdQueryVariables>(
    QueryEmailHistoryByIdDocument,
    baseOptions
  );
}
export type QueryEmailHistoryByIdQueryHookResult = ReturnType<typeof useQueryEmailHistoryByIdQuery>;
export type QueryEmailHistoryByIdLazyQueryHookResult = ReturnType<typeof useQueryEmailHistoryByIdLazyQuery>;
export type QueryEmailHistoryByIdQueryResult = Apollo.QueryResult<
  QueryEmailHistoryByIdQuery,
  QueryEmailHistoryByIdQueryVariables
>;
export const QueryEmailHistoryObjectsDocument = gql`
  query queryEmailHistoryObjects(
    $distinct_on: [emailHistory_select_column!]
    $where: emailHistory_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [emailHistory_order_by!]
  ) {
    emailHistory(distinct_on: $distinct_on, where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      ...EmailHistory
    }
  }
  ${EmailHistoryFragmentDoc}
`;

/**
 * __useQueryEmailHistoryObjectsQuery__
 *
 * To run a query within a React component, call `useQueryEmailHistoryObjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryEmailHistoryObjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryEmailHistoryObjectsQuery({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useQueryEmailHistoryObjectsQuery(
  baseOptions?: Apollo.QueryHookOptions<QueryEmailHistoryObjectsQuery, QueryEmailHistoryObjectsQueryVariables>
) {
  return Apollo.useQuery<QueryEmailHistoryObjectsQuery, QueryEmailHistoryObjectsQueryVariables>(
    QueryEmailHistoryObjectsDocument,
    baseOptions
  );
}
export function useQueryEmailHistoryObjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryEmailHistoryObjectsQuery, QueryEmailHistoryObjectsQueryVariables>
) {
  return Apollo.useLazyQuery<QueryEmailHistoryObjectsQuery, QueryEmailHistoryObjectsQueryVariables>(
    QueryEmailHistoryObjectsDocument,
    baseOptions
  );
}
export type QueryEmailHistoryObjectsQueryHookResult = ReturnType<typeof useQueryEmailHistoryObjectsQuery>;
export type QueryEmailHistoryObjectsLazyQueryHookResult = ReturnType<typeof useQueryEmailHistoryObjectsLazyQuery>;
export type QueryEmailHistoryObjectsQueryResult = Apollo.QueryResult<
  QueryEmailHistoryObjectsQuery,
  QueryEmailHistoryObjectsQueryVariables
>;
export const SubscribeToEmailHistoryByIdDocument = gql`
  subscription subscribeToEmailHistoryById($emailHistoryId: uuid!) {
    emailHistory_by_pk(id: $emailHistoryId) {
      ...EmailHistory
    }
  }
  ${EmailHistoryFragmentDoc}
`;

/**
 * __useSubscribeToEmailHistoryByIdSubscription__
 *
 * To run a query within a React component, call `useSubscribeToEmailHistoryByIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToEmailHistoryByIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToEmailHistoryByIdSubscription({
 *   variables: {
 *      emailHistoryId: // value for 'emailHistoryId'
 *   },
 * });
 */
export function useSubscribeToEmailHistoryByIdSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    SubscribeToEmailHistoryByIdSubscription,
    SubscribeToEmailHistoryByIdSubscriptionVariables
  >
) {
  return Apollo.useSubscription<
    SubscribeToEmailHistoryByIdSubscription,
    SubscribeToEmailHistoryByIdSubscriptionVariables
  >(SubscribeToEmailHistoryByIdDocument, baseOptions);
}
export type SubscribeToEmailHistoryByIdSubscriptionHookResult = ReturnType<
  typeof useSubscribeToEmailHistoryByIdSubscription
>;
export type SubscribeToEmailHistoryByIdSubscriptionResult = Apollo.SubscriptionResult<SubscribeToEmailHistoryByIdSubscription>;
export const SubscribeToEmailHistoryObjectsDocument = gql`
  subscription subscribeToEmailHistoryObjects(
    $distinct_on: [emailHistory_select_column!]
    $where: emailHistory_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [emailHistory_order_by!]
  ) {
    emailHistory(distinct_on: $distinct_on, where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      ...EmailHistory
    }
  }
  ${EmailHistoryFragmentDoc}
`;

/**
 * __useSubscribeToEmailHistoryObjectsSubscription__
 *
 * To run a query within a React component, call `useSubscribeToEmailHistoryObjectsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToEmailHistoryObjectsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToEmailHistoryObjectsSubscription({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useSubscribeToEmailHistoryObjectsSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    SubscribeToEmailHistoryObjectsSubscription,
    SubscribeToEmailHistoryObjectsSubscriptionVariables
  >
) {
  return Apollo.useSubscription<
    SubscribeToEmailHistoryObjectsSubscription,
    SubscribeToEmailHistoryObjectsSubscriptionVariables
  >(SubscribeToEmailHistoryObjectsDocument, baseOptions);
}
export type SubscribeToEmailHistoryObjectsSubscriptionHookResult = ReturnType<
  typeof useSubscribeToEmailHistoryObjectsSubscription
>;
export type SubscribeToEmailHistoryObjectsSubscriptionResult = Apollo.SubscriptionResult<SubscribeToEmailHistoryObjectsSubscription>;
export const InsertEmailHistoryDocument = gql`
  mutation insertEmailHistory($objects: [emailHistory_insert_input!]!) {
    insert_emailHistory(objects: $objects) {
      affected_rows
      returning {
        ...EmailHistory
      }
    }
  }
  ${EmailHistoryFragmentDoc}
`;
export type InsertEmailHistoryMutationFn = Apollo.MutationFunction<
  InsertEmailHistoryMutation,
  InsertEmailHistoryMutationVariables
>;

/**
 * __useInsertEmailHistoryMutation__
 *
 * To run a mutation, you first call `useInsertEmailHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertEmailHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertEmailHistoryMutation, { data, loading, error }] = useInsertEmailHistoryMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertEmailHistoryMutation(
  baseOptions?: Apollo.MutationHookOptions<InsertEmailHistoryMutation, InsertEmailHistoryMutationVariables>
) {
  return Apollo.useMutation<InsertEmailHistoryMutation, InsertEmailHistoryMutationVariables>(
    InsertEmailHistoryDocument,
    baseOptions
  );
}
export type InsertEmailHistoryMutationHookResult = ReturnType<typeof useInsertEmailHistoryMutation>;
export type InsertEmailHistoryMutationResult = Apollo.MutationResult<InsertEmailHistoryMutation>;
export type InsertEmailHistoryMutationOptions = Apollo.BaseMutationOptions<
  InsertEmailHistoryMutation,
  InsertEmailHistoryMutationVariables
>;
export const InsertEmailHistoryWithOnConflictDocument = gql`
  mutation insertEmailHistoryWithOnConflict(
    $objects: [emailHistory_insert_input!]!
    $onConflict: emailHistory_on_conflict
  ) {
    insert_emailHistory(objects: $objects, on_conflict: $onConflict) {
      affected_rows
      returning {
        ...EmailHistory
      }
    }
  }
  ${EmailHistoryFragmentDoc}
`;
export type InsertEmailHistoryWithOnConflictMutationFn = Apollo.MutationFunction<
  InsertEmailHistoryWithOnConflictMutation,
  InsertEmailHistoryWithOnConflictMutationVariables
>;

/**
 * __useInsertEmailHistoryWithOnConflictMutation__
 *
 * To run a mutation, you first call `useInsertEmailHistoryWithOnConflictMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertEmailHistoryWithOnConflictMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertEmailHistoryWithOnConflictMutation, { data, loading, error }] = useInsertEmailHistoryWithOnConflictMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *      onConflict: // value for 'onConflict'
 *   },
 * });
 */
export function useInsertEmailHistoryWithOnConflictMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertEmailHistoryWithOnConflictMutation,
    InsertEmailHistoryWithOnConflictMutationVariables
  >
) {
  return Apollo.useMutation<
    InsertEmailHistoryWithOnConflictMutation,
    InsertEmailHistoryWithOnConflictMutationVariables
  >(InsertEmailHistoryWithOnConflictDocument, baseOptions);
}
export type InsertEmailHistoryWithOnConflictMutationHookResult = ReturnType<
  typeof useInsertEmailHistoryWithOnConflictMutation
>;
export type InsertEmailHistoryWithOnConflictMutationResult = Apollo.MutationResult<InsertEmailHistoryWithOnConflictMutation>;
export type InsertEmailHistoryWithOnConflictMutationOptions = Apollo.BaseMutationOptions<
  InsertEmailHistoryWithOnConflictMutation,
  InsertEmailHistoryWithOnConflictMutationVariables
>;
export const UpdateEmailHistoryByIdDocument = gql`
  mutation updateEmailHistoryById($id: uuid, $set: emailHistory_set_input) {
    update_emailHistory(_set: $set, where: { id: { _eq: $id } }) {
      affected_rows
      returning {
        ...EmailHistory
      }
    }
  }
  ${EmailHistoryFragmentDoc}
`;
export type UpdateEmailHistoryByIdMutationFn = Apollo.MutationFunction<
  UpdateEmailHistoryByIdMutation,
  UpdateEmailHistoryByIdMutationVariables
>;

/**
 * __useUpdateEmailHistoryByIdMutation__
 *
 * To run a mutation, you first call `useUpdateEmailHistoryByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmailHistoryByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmailHistoryByIdMutation, { data, loading, error }] = useUpdateEmailHistoryByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateEmailHistoryByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateEmailHistoryByIdMutation, UpdateEmailHistoryByIdMutationVariables>
) {
  return Apollo.useMutation<UpdateEmailHistoryByIdMutation, UpdateEmailHistoryByIdMutationVariables>(
    UpdateEmailHistoryByIdDocument,
    baseOptions
  );
}
export type UpdateEmailHistoryByIdMutationHookResult = ReturnType<typeof useUpdateEmailHistoryByIdMutation>;
export type UpdateEmailHistoryByIdMutationResult = Apollo.MutationResult<UpdateEmailHistoryByIdMutation>;
export type UpdateEmailHistoryByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateEmailHistoryByIdMutation,
  UpdateEmailHistoryByIdMutationVariables
>;
export const UpdateEmailHistoryDocument = gql`
  mutation updateEmailHistory($set: emailHistory_set_input, $where: emailHistory_bool_exp!) {
    update_emailHistory(_set: $set, where: $where) {
      affected_rows
      returning {
        ...EmailHistory
      }
    }
  }
  ${EmailHistoryFragmentDoc}
`;
export type UpdateEmailHistoryMutationFn = Apollo.MutationFunction<
  UpdateEmailHistoryMutation,
  UpdateEmailHistoryMutationVariables
>;

/**
 * __useUpdateEmailHistoryMutation__
 *
 * To run a mutation, you first call `useUpdateEmailHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmailHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmailHistoryMutation, { data, loading, error }] = useUpdateEmailHistoryMutation({
 *   variables: {
 *      set: // value for 'set'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateEmailHistoryMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateEmailHistoryMutation, UpdateEmailHistoryMutationVariables>
) {
  return Apollo.useMutation<UpdateEmailHistoryMutation, UpdateEmailHistoryMutationVariables>(
    UpdateEmailHistoryDocument,
    baseOptions
  );
}
export type UpdateEmailHistoryMutationHookResult = ReturnType<typeof useUpdateEmailHistoryMutation>;
export type UpdateEmailHistoryMutationResult = Apollo.MutationResult<UpdateEmailHistoryMutation>;
export type UpdateEmailHistoryMutationOptions = Apollo.BaseMutationOptions<
  UpdateEmailHistoryMutation,
  UpdateEmailHistoryMutationVariables
>;
export const RemoveEmailHistoryModelByIdDocument = gql`
  mutation removeEmailHistoryModelById($id: uuid) {
    delete_emailHistory(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
export type RemoveEmailHistoryModelByIdMutationFn = Apollo.MutationFunction<
  RemoveEmailHistoryModelByIdMutation,
  RemoveEmailHistoryModelByIdMutationVariables
>;

/**
 * __useRemoveEmailHistoryModelByIdMutation__
 *
 * To run a mutation, you first call `useRemoveEmailHistoryModelByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveEmailHistoryModelByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeEmailHistoryModelByIdMutation, { data, loading, error }] = useRemoveEmailHistoryModelByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveEmailHistoryModelByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveEmailHistoryModelByIdMutation,
    RemoveEmailHistoryModelByIdMutationVariables
  >
) {
  return Apollo.useMutation<RemoveEmailHistoryModelByIdMutation, RemoveEmailHistoryModelByIdMutationVariables>(
    RemoveEmailHistoryModelByIdDocument,
    baseOptions
  );
}
export type RemoveEmailHistoryModelByIdMutationHookResult = ReturnType<typeof useRemoveEmailHistoryModelByIdMutation>;
export type RemoveEmailHistoryModelByIdMutationResult = Apollo.MutationResult<RemoveEmailHistoryModelByIdMutation>;
export type RemoveEmailHistoryModelByIdMutationOptions = Apollo.BaseMutationOptions<
  RemoveEmailHistoryModelByIdMutation,
  RemoveEmailHistoryModelByIdMutationVariables
>;
export const RemoveEmailHistoryModelDocument = gql`
  mutation removeEmailHistoryModel($where: emailHistory_bool_exp!) {
    delete_emailHistory(where: $where) {
      affected_rows
    }
  }
`;
export type RemoveEmailHistoryModelMutationFn = Apollo.MutationFunction<
  RemoveEmailHistoryModelMutation,
  RemoveEmailHistoryModelMutationVariables
>;

/**
 * __useRemoveEmailHistoryModelMutation__
 *
 * To run a mutation, you first call `useRemoveEmailHistoryModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveEmailHistoryModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeEmailHistoryModelMutation, { data, loading, error }] = useRemoveEmailHistoryModelMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useRemoveEmailHistoryModelMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveEmailHistoryModelMutation, RemoveEmailHistoryModelMutationVariables>
) {
  return Apollo.useMutation<RemoveEmailHistoryModelMutation, RemoveEmailHistoryModelMutationVariables>(
    RemoveEmailHistoryModelDocument,
    baseOptions
  );
}
export type RemoveEmailHistoryModelMutationHookResult = ReturnType<typeof useRemoveEmailHistoryModelMutation>;
export type RemoveEmailHistoryModelMutationResult = Apollo.MutationResult<RemoveEmailHistoryModelMutation>;
export type RemoveEmailHistoryModelMutationOptions = Apollo.BaseMutationOptions<
  RemoveEmailHistoryModelMutation,
  RemoveEmailHistoryModelMutationVariables
>;
export const QuerySendgridEventByIdDocument = gql`
  query querySendgridEventById($sendgridEventId: uuid!) {
    sendgridEvent_by_pk(id: $sendgridEventId) {
      ...SendgridEvent
    }
  }
  ${SendgridEventFragmentDoc}
`;

/**
 * __useQuerySendgridEventByIdQuery__
 *
 * To run a query within a React component, call `useQuerySendgridEventByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuerySendgridEventByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuerySendgridEventByIdQuery({
 *   variables: {
 *      sendgridEventId: // value for 'sendgridEventId'
 *   },
 * });
 */
export function useQuerySendgridEventByIdQuery(
  baseOptions: Apollo.QueryHookOptions<QuerySendgridEventByIdQuery, QuerySendgridEventByIdQueryVariables>
) {
  return Apollo.useQuery<QuerySendgridEventByIdQuery, QuerySendgridEventByIdQueryVariables>(
    QuerySendgridEventByIdDocument,
    baseOptions
  );
}
export function useQuerySendgridEventByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QuerySendgridEventByIdQuery, QuerySendgridEventByIdQueryVariables>
) {
  return Apollo.useLazyQuery<QuerySendgridEventByIdQuery, QuerySendgridEventByIdQueryVariables>(
    QuerySendgridEventByIdDocument,
    baseOptions
  );
}
export type QuerySendgridEventByIdQueryHookResult = ReturnType<typeof useQuerySendgridEventByIdQuery>;
export type QuerySendgridEventByIdLazyQueryHookResult = ReturnType<typeof useQuerySendgridEventByIdLazyQuery>;
export type QuerySendgridEventByIdQueryResult = Apollo.QueryResult<
  QuerySendgridEventByIdQuery,
  QuerySendgridEventByIdQueryVariables
>;
export const QuerySendgridEventObjectsDocument = gql`
  query querySendgridEventObjects(
    $distinct_on: [sendgridEvent_select_column!]
    $where: sendgridEvent_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [sendgridEvent_order_by!]
  ) {
    sendgridEvent(distinct_on: $distinct_on, where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      ...SendgridEvent
    }
  }
  ${SendgridEventFragmentDoc}
`;

/**
 * __useQuerySendgridEventObjectsQuery__
 *
 * To run a query within a React component, call `useQuerySendgridEventObjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuerySendgridEventObjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuerySendgridEventObjectsQuery({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useQuerySendgridEventObjectsQuery(
  baseOptions?: Apollo.QueryHookOptions<QuerySendgridEventObjectsQuery, QuerySendgridEventObjectsQueryVariables>
) {
  return Apollo.useQuery<QuerySendgridEventObjectsQuery, QuerySendgridEventObjectsQueryVariables>(
    QuerySendgridEventObjectsDocument,
    baseOptions
  );
}
export function useQuerySendgridEventObjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QuerySendgridEventObjectsQuery, QuerySendgridEventObjectsQueryVariables>
) {
  return Apollo.useLazyQuery<QuerySendgridEventObjectsQuery, QuerySendgridEventObjectsQueryVariables>(
    QuerySendgridEventObjectsDocument,
    baseOptions
  );
}
export type QuerySendgridEventObjectsQueryHookResult = ReturnType<typeof useQuerySendgridEventObjectsQuery>;
export type QuerySendgridEventObjectsLazyQueryHookResult = ReturnType<typeof useQuerySendgridEventObjectsLazyQuery>;
export type QuerySendgridEventObjectsQueryResult = Apollo.QueryResult<
  QuerySendgridEventObjectsQuery,
  QuerySendgridEventObjectsQueryVariables
>;
export const SubscribeToSendgridEventByIdDocument = gql`
  subscription subscribeToSendgridEventById($sendgridEventId: uuid!) {
    sendgridEvent_by_pk(id: $sendgridEventId) {
      ...SendgridEvent
    }
  }
  ${SendgridEventFragmentDoc}
`;

/**
 * __useSubscribeToSendgridEventByIdSubscription__
 *
 * To run a query within a React component, call `useSubscribeToSendgridEventByIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToSendgridEventByIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToSendgridEventByIdSubscription({
 *   variables: {
 *      sendgridEventId: // value for 'sendgridEventId'
 *   },
 * });
 */
export function useSubscribeToSendgridEventByIdSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    SubscribeToSendgridEventByIdSubscription,
    SubscribeToSendgridEventByIdSubscriptionVariables
  >
) {
  return Apollo.useSubscription<
    SubscribeToSendgridEventByIdSubscription,
    SubscribeToSendgridEventByIdSubscriptionVariables
  >(SubscribeToSendgridEventByIdDocument, baseOptions);
}
export type SubscribeToSendgridEventByIdSubscriptionHookResult = ReturnType<
  typeof useSubscribeToSendgridEventByIdSubscription
>;
export type SubscribeToSendgridEventByIdSubscriptionResult = Apollo.SubscriptionResult<SubscribeToSendgridEventByIdSubscription>;
export const SubscribeToSendgridEventObjectsDocument = gql`
  subscription subscribeToSendgridEventObjects(
    $distinct_on: [sendgridEvent_select_column!]
    $where: sendgridEvent_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [sendgridEvent_order_by!]
  ) {
    sendgridEvent(distinct_on: $distinct_on, where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      ...SendgridEvent
    }
  }
  ${SendgridEventFragmentDoc}
`;

/**
 * __useSubscribeToSendgridEventObjectsSubscription__
 *
 * To run a query within a React component, call `useSubscribeToSendgridEventObjectsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToSendgridEventObjectsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToSendgridEventObjectsSubscription({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useSubscribeToSendgridEventObjectsSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    SubscribeToSendgridEventObjectsSubscription,
    SubscribeToSendgridEventObjectsSubscriptionVariables
  >
) {
  return Apollo.useSubscription<
    SubscribeToSendgridEventObjectsSubscription,
    SubscribeToSendgridEventObjectsSubscriptionVariables
  >(SubscribeToSendgridEventObjectsDocument, baseOptions);
}
export type SubscribeToSendgridEventObjectsSubscriptionHookResult = ReturnType<
  typeof useSubscribeToSendgridEventObjectsSubscription
>;
export type SubscribeToSendgridEventObjectsSubscriptionResult = Apollo.SubscriptionResult<SubscribeToSendgridEventObjectsSubscription>;
export const InsertSendgridEventDocument = gql`
  mutation insertSendgridEvent($objects: [sendgridEvent_insert_input!]!) {
    insert_sendgridEvent(objects: $objects) {
      affected_rows
      returning {
        ...SendgridEvent
      }
    }
  }
  ${SendgridEventFragmentDoc}
`;
export type InsertSendgridEventMutationFn = Apollo.MutationFunction<
  InsertSendgridEventMutation,
  InsertSendgridEventMutationVariables
>;

/**
 * __useInsertSendgridEventMutation__
 *
 * To run a mutation, you first call `useInsertSendgridEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertSendgridEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertSendgridEventMutation, { data, loading, error }] = useInsertSendgridEventMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertSendgridEventMutation(
  baseOptions?: Apollo.MutationHookOptions<InsertSendgridEventMutation, InsertSendgridEventMutationVariables>
) {
  return Apollo.useMutation<InsertSendgridEventMutation, InsertSendgridEventMutationVariables>(
    InsertSendgridEventDocument,
    baseOptions
  );
}
export type InsertSendgridEventMutationHookResult = ReturnType<typeof useInsertSendgridEventMutation>;
export type InsertSendgridEventMutationResult = Apollo.MutationResult<InsertSendgridEventMutation>;
export type InsertSendgridEventMutationOptions = Apollo.BaseMutationOptions<
  InsertSendgridEventMutation,
  InsertSendgridEventMutationVariables
>;
export const InsertSendgridEventWithOnConflictDocument = gql`
  mutation insertSendgridEventWithOnConflict(
    $objects: [sendgridEvent_insert_input!]!
    $onConflict: sendgridEvent_on_conflict
  ) {
    insert_sendgridEvent(objects: $objects, on_conflict: $onConflict) {
      affected_rows
      returning {
        ...SendgridEvent
      }
    }
  }
  ${SendgridEventFragmentDoc}
`;
export type InsertSendgridEventWithOnConflictMutationFn = Apollo.MutationFunction<
  InsertSendgridEventWithOnConflictMutation,
  InsertSendgridEventWithOnConflictMutationVariables
>;

/**
 * __useInsertSendgridEventWithOnConflictMutation__
 *
 * To run a mutation, you first call `useInsertSendgridEventWithOnConflictMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertSendgridEventWithOnConflictMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertSendgridEventWithOnConflictMutation, { data, loading, error }] = useInsertSendgridEventWithOnConflictMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *      onConflict: // value for 'onConflict'
 *   },
 * });
 */
export function useInsertSendgridEventWithOnConflictMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertSendgridEventWithOnConflictMutation,
    InsertSendgridEventWithOnConflictMutationVariables
  >
) {
  return Apollo.useMutation<
    InsertSendgridEventWithOnConflictMutation,
    InsertSendgridEventWithOnConflictMutationVariables
  >(InsertSendgridEventWithOnConflictDocument, baseOptions);
}
export type InsertSendgridEventWithOnConflictMutationHookResult = ReturnType<
  typeof useInsertSendgridEventWithOnConflictMutation
>;
export type InsertSendgridEventWithOnConflictMutationResult = Apollo.MutationResult<InsertSendgridEventWithOnConflictMutation>;
export type InsertSendgridEventWithOnConflictMutationOptions = Apollo.BaseMutationOptions<
  InsertSendgridEventWithOnConflictMutation,
  InsertSendgridEventWithOnConflictMutationVariables
>;
export const UpdateSendgridEventByIdDocument = gql`
  mutation updateSendgridEventById($id: uuid, $set: sendgridEvent_set_input) {
    update_sendgridEvent(_set: $set, where: { id: { _eq: $id } }) {
      affected_rows
      returning {
        ...SendgridEvent
      }
    }
  }
  ${SendgridEventFragmentDoc}
`;
export type UpdateSendgridEventByIdMutationFn = Apollo.MutationFunction<
  UpdateSendgridEventByIdMutation,
  UpdateSendgridEventByIdMutationVariables
>;

/**
 * __useUpdateSendgridEventByIdMutation__
 *
 * To run a mutation, you first call `useUpdateSendgridEventByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSendgridEventByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSendgridEventByIdMutation, { data, loading, error }] = useUpdateSendgridEventByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateSendgridEventByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateSendgridEventByIdMutation, UpdateSendgridEventByIdMutationVariables>
) {
  return Apollo.useMutation<UpdateSendgridEventByIdMutation, UpdateSendgridEventByIdMutationVariables>(
    UpdateSendgridEventByIdDocument,
    baseOptions
  );
}
export type UpdateSendgridEventByIdMutationHookResult = ReturnType<typeof useUpdateSendgridEventByIdMutation>;
export type UpdateSendgridEventByIdMutationResult = Apollo.MutationResult<UpdateSendgridEventByIdMutation>;
export type UpdateSendgridEventByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateSendgridEventByIdMutation,
  UpdateSendgridEventByIdMutationVariables
>;
export const UpdateSendgridEventDocument = gql`
  mutation updateSendgridEvent($set: sendgridEvent_set_input, $where: sendgridEvent_bool_exp!) {
    update_sendgridEvent(_set: $set, where: $where) {
      affected_rows
      returning {
        ...SendgridEvent
      }
    }
  }
  ${SendgridEventFragmentDoc}
`;
export type UpdateSendgridEventMutationFn = Apollo.MutationFunction<
  UpdateSendgridEventMutation,
  UpdateSendgridEventMutationVariables
>;

/**
 * __useUpdateSendgridEventMutation__
 *
 * To run a mutation, you first call `useUpdateSendgridEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSendgridEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSendgridEventMutation, { data, loading, error }] = useUpdateSendgridEventMutation({
 *   variables: {
 *      set: // value for 'set'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateSendgridEventMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateSendgridEventMutation, UpdateSendgridEventMutationVariables>
) {
  return Apollo.useMutation<UpdateSendgridEventMutation, UpdateSendgridEventMutationVariables>(
    UpdateSendgridEventDocument,
    baseOptions
  );
}
export type UpdateSendgridEventMutationHookResult = ReturnType<typeof useUpdateSendgridEventMutation>;
export type UpdateSendgridEventMutationResult = Apollo.MutationResult<UpdateSendgridEventMutation>;
export type UpdateSendgridEventMutationOptions = Apollo.BaseMutationOptions<
  UpdateSendgridEventMutation,
  UpdateSendgridEventMutationVariables
>;
export const RemoveSendgridEventModelByIdDocument = gql`
  mutation removeSendgridEventModelById($id: uuid) {
    delete_sendgridEvent(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
export type RemoveSendgridEventModelByIdMutationFn = Apollo.MutationFunction<
  RemoveSendgridEventModelByIdMutation,
  RemoveSendgridEventModelByIdMutationVariables
>;

/**
 * __useRemoveSendgridEventModelByIdMutation__
 *
 * To run a mutation, you first call `useRemoveSendgridEventModelByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSendgridEventModelByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSendgridEventModelByIdMutation, { data, loading, error }] = useRemoveSendgridEventModelByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveSendgridEventModelByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveSendgridEventModelByIdMutation,
    RemoveSendgridEventModelByIdMutationVariables
  >
) {
  return Apollo.useMutation<RemoveSendgridEventModelByIdMutation, RemoveSendgridEventModelByIdMutationVariables>(
    RemoveSendgridEventModelByIdDocument,
    baseOptions
  );
}
export type RemoveSendgridEventModelByIdMutationHookResult = ReturnType<typeof useRemoveSendgridEventModelByIdMutation>;
export type RemoveSendgridEventModelByIdMutationResult = Apollo.MutationResult<RemoveSendgridEventModelByIdMutation>;
export type RemoveSendgridEventModelByIdMutationOptions = Apollo.BaseMutationOptions<
  RemoveSendgridEventModelByIdMutation,
  RemoveSendgridEventModelByIdMutationVariables
>;
export const RemoveSendgridEventModelDocument = gql`
  mutation removeSendgridEventModel($where: sendgridEvent_bool_exp!) {
    delete_sendgridEvent(where: $where) {
      affected_rows
    }
  }
`;
export type RemoveSendgridEventModelMutationFn = Apollo.MutationFunction<
  RemoveSendgridEventModelMutation,
  RemoveSendgridEventModelMutationVariables
>;

/**
 * __useRemoveSendgridEventModelMutation__
 *
 * To run a mutation, you first call `useRemoveSendgridEventModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSendgridEventModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSendgridEventModelMutation, { data, loading, error }] = useRemoveSendgridEventModelMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useRemoveSendgridEventModelMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveSendgridEventModelMutation, RemoveSendgridEventModelMutationVariables>
) {
  return Apollo.useMutation<RemoveSendgridEventModelMutation, RemoveSendgridEventModelMutationVariables>(
    RemoveSendgridEventModelDocument,
    baseOptions
  );
}
export type RemoveSendgridEventModelMutationHookResult = ReturnType<typeof useRemoveSendgridEventModelMutation>;
export type RemoveSendgridEventModelMutationResult = Apollo.MutationResult<RemoveSendgridEventModelMutation>;
export type RemoveSendgridEventModelMutationOptions = Apollo.BaseMutationOptions<
  RemoveSendgridEventModelMutation,
  RemoveSendgridEventModelMutationVariables
>;
export const QueryCsvUploadByIdDocument = gql`
  query queryCsvUploadById($csvUploadId: uuid!) {
    csvUpload_by_pk(id: $csvUploadId) {
      ...CsvUpload
    }
  }
  ${CsvUploadFragmentDoc}
`;

/**
 * __useQueryCsvUploadByIdQuery__
 *
 * To run a query within a React component, call `useQueryCsvUploadByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryCsvUploadByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryCsvUploadByIdQuery({
 *   variables: {
 *      csvUploadId: // value for 'csvUploadId'
 *   },
 * });
 */
export function useQueryCsvUploadByIdQuery(
  baseOptions: Apollo.QueryHookOptions<QueryCsvUploadByIdQuery, QueryCsvUploadByIdQueryVariables>
) {
  return Apollo.useQuery<QueryCsvUploadByIdQuery, QueryCsvUploadByIdQueryVariables>(
    QueryCsvUploadByIdDocument,
    baseOptions
  );
}
export function useQueryCsvUploadByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryCsvUploadByIdQuery, QueryCsvUploadByIdQueryVariables>
) {
  return Apollo.useLazyQuery<QueryCsvUploadByIdQuery, QueryCsvUploadByIdQueryVariables>(
    QueryCsvUploadByIdDocument,
    baseOptions
  );
}
export type QueryCsvUploadByIdQueryHookResult = ReturnType<typeof useQueryCsvUploadByIdQuery>;
export type QueryCsvUploadByIdLazyQueryHookResult = ReturnType<typeof useQueryCsvUploadByIdLazyQuery>;
export type QueryCsvUploadByIdQueryResult = Apollo.QueryResult<
  QueryCsvUploadByIdQuery,
  QueryCsvUploadByIdQueryVariables
>;
export const QueryCsvUploadObjectsDocument = gql`
  query queryCsvUploadObjects(
    $distinct_on: [csvUpload_select_column!]
    $where: csvUpload_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [csvUpload_order_by!]
  ) {
    csvUpload(distinct_on: $distinct_on, where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      ...CsvUpload
    }
  }
  ${CsvUploadFragmentDoc}
`;

/**
 * __useQueryCsvUploadObjectsQuery__
 *
 * To run a query within a React component, call `useQueryCsvUploadObjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryCsvUploadObjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryCsvUploadObjectsQuery({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useQueryCsvUploadObjectsQuery(
  baseOptions?: Apollo.QueryHookOptions<QueryCsvUploadObjectsQuery, QueryCsvUploadObjectsQueryVariables>
) {
  return Apollo.useQuery<QueryCsvUploadObjectsQuery, QueryCsvUploadObjectsQueryVariables>(
    QueryCsvUploadObjectsDocument,
    baseOptions
  );
}
export function useQueryCsvUploadObjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryCsvUploadObjectsQuery, QueryCsvUploadObjectsQueryVariables>
) {
  return Apollo.useLazyQuery<QueryCsvUploadObjectsQuery, QueryCsvUploadObjectsQueryVariables>(
    QueryCsvUploadObjectsDocument,
    baseOptions
  );
}
export type QueryCsvUploadObjectsQueryHookResult = ReturnType<typeof useQueryCsvUploadObjectsQuery>;
export type QueryCsvUploadObjectsLazyQueryHookResult = ReturnType<typeof useQueryCsvUploadObjectsLazyQuery>;
export type QueryCsvUploadObjectsQueryResult = Apollo.QueryResult<
  QueryCsvUploadObjectsQuery,
  QueryCsvUploadObjectsQueryVariables
>;
export const SubscribeToCsvUploadByIdDocument = gql`
  subscription subscribeToCsvUploadById($csvUploadId: uuid!) {
    csvUpload_by_pk(id: $csvUploadId) {
      ...CsvUpload
    }
  }
  ${CsvUploadFragmentDoc}
`;

/**
 * __useSubscribeToCsvUploadByIdSubscription__
 *
 * To run a query within a React component, call `useSubscribeToCsvUploadByIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToCsvUploadByIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToCsvUploadByIdSubscription({
 *   variables: {
 *      csvUploadId: // value for 'csvUploadId'
 *   },
 * });
 */
export function useSubscribeToCsvUploadByIdSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    SubscribeToCsvUploadByIdSubscription,
    SubscribeToCsvUploadByIdSubscriptionVariables
  >
) {
  return Apollo.useSubscription<SubscribeToCsvUploadByIdSubscription, SubscribeToCsvUploadByIdSubscriptionVariables>(
    SubscribeToCsvUploadByIdDocument,
    baseOptions
  );
}
export type SubscribeToCsvUploadByIdSubscriptionHookResult = ReturnType<typeof useSubscribeToCsvUploadByIdSubscription>;
export type SubscribeToCsvUploadByIdSubscriptionResult = Apollo.SubscriptionResult<SubscribeToCsvUploadByIdSubscription>;
export const SubscribeToCsvUploadObjectsDocument = gql`
  subscription subscribeToCsvUploadObjects(
    $distinct_on: [csvUpload_select_column!]
    $where: csvUpload_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [csvUpload_order_by!]
  ) {
    csvUpload(distinct_on: $distinct_on, where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      ...CsvUpload
    }
  }
  ${CsvUploadFragmentDoc}
`;

/**
 * __useSubscribeToCsvUploadObjectsSubscription__
 *
 * To run a query within a React component, call `useSubscribeToCsvUploadObjectsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToCsvUploadObjectsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToCsvUploadObjectsSubscription({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useSubscribeToCsvUploadObjectsSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    SubscribeToCsvUploadObjectsSubscription,
    SubscribeToCsvUploadObjectsSubscriptionVariables
  >
) {
  return Apollo.useSubscription<
    SubscribeToCsvUploadObjectsSubscription,
    SubscribeToCsvUploadObjectsSubscriptionVariables
  >(SubscribeToCsvUploadObjectsDocument, baseOptions);
}
export type SubscribeToCsvUploadObjectsSubscriptionHookResult = ReturnType<
  typeof useSubscribeToCsvUploadObjectsSubscription
>;
export type SubscribeToCsvUploadObjectsSubscriptionResult = Apollo.SubscriptionResult<SubscribeToCsvUploadObjectsSubscription>;
export const InsertCsvUploadDocument = gql`
  mutation insertCsvUpload($objects: [csvUpload_insert_input!]!) {
    insert_csvUpload(objects: $objects) {
      affected_rows
      returning {
        ...CsvUpload
      }
    }
  }
  ${CsvUploadFragmentDoc}
`;
export type InsertCsvUploadMutationFn = Apollo.MutationFunction<
  InsertCsvUploadMutation,
  InsertCsvUploadMutationVariables
>;

/**
 * __useInsertCsvUploadMutation__
 *
 * To run a mutation, you first call `useInsertCsvUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertCsvUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertCsvUploadMutation, { data, loading, error }] = useInsertCsvUploadMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertCsvUploadMutation(
  baseOptions?: Apollo.MutationHookOptions<InsertCsvUploadMutation, InsertCsvUploadMutationVariables>
) {
  return Apollo.useMutation<InsertCsvUploadMutation, InsertCsvUploadMutationVariables>(
    InsertCsvUploadDocument,
    baseOptions
  );
}
export type InsertCsvUploadMutationHookResult = ReturnType<typeof useInsertCsvUploadMutation>;
export type InsertCsvUploadMutationResult = Apollo.MutationResult<InsertCsvUploadMutation>;
export type InsertCsvUploadMutationOptions = Apollo.BaseMutationOptions<
  InsertCsvUploadMutation,
  InsertCsvUploadMutationVariables
>;
export const InsertCsvUploadWithOnConflictDocument = gql`
  mutation insertCsvUploadWithOnConflict($objects: [csvUpload_insert_input!]!, $onConflict: csvUpload_on_conflict) {
    insert_csvUpload(objects: $objects, on_conflict: $onConflict) {
      affected_rows
      returning {
        ...CsvUpload
      }
    }
  }
  ${CsvUploadFragmentDoc}
`;
export type InsertCsvUploadWithOnConflictMutationFn = Apollo.MutationFunction<
  InsertCsvUploadWithOnConflictMutation,
  InsertCsvUploadWithOnConflictMutationVariables
>;

/**
 * __useInsertCsvUploadWithOnConflictMutation__
 *
 * To run a mutation, you first call `useInsertCsvUploadWithOnConflictMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertCsvUploadWithOnConflictMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertCsvUploadWithOnConflictMutation, { data, loading, error }] = useInsertCsvUploadWithOnConflictMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *      onConflict: // value for 'onConflict'
 *   },
 * });
 */
export function useInsertCsvUploadWithOnConflictMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertCsvUploadWithOnConflictMutation,
    InsertCsvUploadWithOnConflictMutationVariables
  >
) {
  return Apollo.useMutation<InsertCsvUploadWithOnConflictMutation, InsertCsvUploadWithOnConflictMutationVariables>(
    InsertCsvUploadWithOnConflictDocument,
    baseOptions
  );
}
export type InsertCsvUploadWithOnConflictMutationHookResult = ReturnType<
  typeof useInsertCsvUploadWithOnConflictMutation
>;
export type InsertCsvUploadWithOnConflictMutationResult = Apollo.MutationResult<InsertCsvUploadWithOnConflictMutation>;
export type InsertCsvUploadWithOnConflictMutationOptions = Apollo.BaseMutationOptions<
  InsertCsvUploadWithOnConflictMutation,
  InsertCsvUploadWithOnConflictMutationVariables
>;
export const UpdateCsvUploadByIdDocument = gql`
  mutation updateCsvUploadById($id: uuid, $set: csvUpload_set_input) {
    update_csvUpload(_set: $set, where: { id: { _eq: $id } }) {
      affected_rows
      returning {
        ...CsvUpload
      }
    }
  }
  ${CsvUploadFragmentDoc}
`;
export type UpdateCsvUploadByIdMutationFn = Apollo.MutationFunction<
  UpdateCsvUploadByIdMutation,
  UpdateCsvUploadByIdMutationVariables
>;

/**
 * __useUpdateCsvUploadByIdMutation__
 *
 * To run a mutation, you first call `useUpdateCsvUploadByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCsvUploadByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCsvUploadByIdMutation, { data, loading, error }] = useUpdateCsvUploadByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateCsvUploadByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateCsvUploadByIdMutation, UpdateCsvUploadByIdMutationVariables>
) {
  return Apollo.useMutation<UpdateCsvUploadByIdMutation, UpdateCsvUploadByIdMutationVariables>(
    UpdateCsvUploadByIdDocument,
    baseOptions
  );
}
export type UpdateCsvUploadByIdMutationHookResult = ReturnType<typeof useUpdateCsvUploadByIdMutation>;
export type UpdateCsvUploadByIdMutationResult = Apollo.MutationResult<UpdateCsvUploadByIdMutation>;
export type UpdateCsvUploadByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateCsvUploadByIdMutation,
  UpdateCsvUploadByIdMutationVariables
>;
export const UpdateCsvUploadDocument = gql`
  mutation updateCsvUpload($set: csvUpload_set_input, $where: csvUpload_bool_exp!) {
    update_csvUpload(_set: $set, where: $where) {
      affected_rows
      returning {
        ...CsvUpload
      }
    }
  }
  ${CsvUploadFragmentDoc}
`;
export type UpdateCsvUploadMutationFn = Apollo.MutationFunction<
  UpdateCsvUploadMutation,
  UpdateCsvUploadMutationVariables
>;

/**
 * __useUpdateCsvUploadMutation__
 *
 * To run a mutation, you first call `useUpdateCsvUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCsvUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCsvUploadMutation, { data, loading, error }] = useUpdateCsvUploadMutation({
 *   variables: {
 *      set: // value for 'set'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateCsvUploadMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateCsvUploadMutation, UpdateCsvUploadMutationVariables>
) {
  return Apollo.useMutation<UpdateCsvUploadMutation, UpdateCsvUploadMutationVariables>(
    UpdateCsvUploadDocument,
    baseOptions
  );
}
export type UpdateCsvUploadMutationHookResult = ReturnType<typeof useUpdateCsvUploadMutation>;
export type UpdateCsvUploadMutationResult = Apollo.MutationResult<UpdateCsvUploadMutation>;
export type UpdateCsvUploadMutationOptions = Apollo.BaseMutationOptions<
  UpdateCsvUploadMutation,
  UpdateCsvUploadMutationVariables
>;
export const RemoveCsvUploadModelByIdDocument = gql`
  mutation removeCsvUploadModelById($id: uuid) {
    delete_csvUpload(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
export type RemoveCsvUploadModelByIdMutationFn = Apollo.MutationFunction<
  RemoveCsvUploadModelByIdMutation,
  RemoveCsvUploadModelByIdMutationVariables
>;

/**
 * __useRemoveCsvUploadModelByIdMutation__
 *
 * To run a mutation, you first call `useRemoveCsvUploadModelByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCsvUploadModelByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCsvUploadModelByIdMutation, { data, loading, error }] = useRemoveCsvUploadModelByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveCsvUploadModelByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveCsvUploadModelByIdMutation, RemoveCsvUploadModelByIdMutationVariables>
) {
  return Apollo.useMutation<RemoveCsvUploadModelByIdMutation, RemoveCsvUploadModelByIdMutationVariables>(
    RemoveCsvUploadModelByIdDocument,
    baseOptions
  );
}
export type RemoveCsvUploadModelByIdMutationHookResult = ReturnType<typeof useRemoveCsvUploadModelByIdMutation>;
export type RemoveCsvUploadModelByIdMutationResult = Apollo.MutationResult<RemoveCsvUploadModelByIdMutation>;
export type RemoveCsvUploadModelByIdMutationOptions = Apollo.BaseMutationOptions<
  RemoveCsvUploadModelByIdMutation,
  RemoveCsvUploadModelByIdMutationVariables
>;
export const RemoveCsvUploadModelDocument = gql`
  mutation removeCsvUploadModel($where: csvUpload_bool_exp!) {
    delete_csvUpload(where: $where) {
      affected_rows
    }
  }
`;
export type RemoveCsvUploadModelMutationFn = Apollo.MutationFunction<
  RemoveCsvUploadModelMutation,
  RemoveCsvUploadModelMutationVariables
>;

/**
 * __useRemoveCsvUploadModelMutation__
 *
 * To run a mutation, you first call `useRemoveCsvUploadModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCsvUploadModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCsvUploadModelMutation, { data, loading, error }] = useRemoveCsvUploadModelMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useRemoveCsvUploadModelMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveCsvUploadModelMutation, RemoveCsvUploadModelMutationVariables>
) {
  return Apollo.useMutation<RemoveCsvUploadModelMutation, RemoveCsvUploadModelMutationVariables>(
    RemoveCsvUploadModelDocument,
    baseOptions
  );
}
export type RemoveCsvUploadModelMutationHookResult = ReturnType<typeof useRemoveCsvUploadModelMutation>;
export type RemoveCsvUploadModelMutationResult = Apollo.MutationResult<RemoveCsvUploadModelMutation>;
export type RemoveCsvUploadModelMutationOptions = Apollo.BaseMutationOptions<
  RemoveCsvUploadModelMutation,
  RemoveCsvUploadModelMutationVariables
>;
export const QueryHubspotContactListByIdDocument = gql`
  query queryHubspotContactListById($hubspotContactListId: uuid!) {
    hubspotContactList_by_pk(id: $hubspotContactListId) {
      ...HubspotContactList
    }
  }
  ${HubspotContactListFragmentDoc}
`;

/**
 * __useQueryHubspotContactListByIdQuery__
 *
 * To run a query within a React component, call `useQueryHubspotContactListByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryHubspotContactListByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryHubspotContactListByIdQuery({
 *   variables: {
 *      hubspotContactListId: // value for 'hubspotContactListId'
 *   },
 * });
 */
export function useQueryHubspotContactListByIdQuery(
  baseOptions: Apollo.QueryHookOptions<QueryHubspotContactListByIdQuery, QueryHubspotContactListByIdQueryVariables>
) {
  return Apollo.useQuery<QueryHubspotContactListByIdQuery, QueryHubspotContactListByIdQueryVariables>(
    QueryHubspotContactListByIdDocument,
    baseOptions
  );
}
export function useQueryHubspotContactListByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryHubspotContactListByIdQuery, QueryHubspotContactListByIdQueryVariables>
) {
  return Apollo.useLazyQuery<QueryHubspotContactListByIdQuery, QueryHubspotContactListByIdQueryVariables>(
    QueryHubspotContactListByIdDocument,
    baseOptions
  );
}
export type QueryHubspotContactListByIdQueryHookResult = ReturnType<typeof useQueryHubspotContactListByIdQuery>;
export type QueryHubspotContactListByIdLazyQueryHookResult = ReturnType<typeof useQueryHubspotContactListByIdLazyQuery>;
export type QueryHubspotContactListByIdQueryResult = Apollo.QueryResult<
  QueryHubspotContactListByIdQuery,
  QueryHubspotContactListByIdQueryVariables
>;
export const QueryHubspotContactListObjectsDocument = gql`
  query queryHubspotContactListObjects(
    $distinct_on: [hubspotContactList_select_column!]
    $where: hubspotContactList_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [hubspotContactList_order_by!]
  ) {
    hubspotContactList(distinct_on: $distinct_on, where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      ...HubspotContactList
    }
  }
  ${HubspotContactListFragmentDoc}
`;

/**
 * __useQueryHubspotContactListObjectsQuery__
 *
 * To run a query within a React component, call `useQueryHubspotContactListObjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryHubspotContactListObjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryHubspotContactListObjectsQuery({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useQueryHubspotContactListObjectsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    QueryHubspotContactListObjectsQuery,
    QueryHubspotContactListObjectsQueryVariables
  >
) {
  return Apollo.useQuery<QueryHubspotContactListObjectsQuery, QueryHubspotContactListObjectsQueryVariables>(
    QueryHubspotContactListObjectsDocument,
    baseOptions
  );
}
export function useQueryHubspotContactListObjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryHubspotContactListObjectsQuery,
    QueryHubspotContactListObjectsQueryVariables
  >
) {
  return Apollo.useLazyQuery<QueryHubspotContactListObjectsQuery, QueryHubspotContactListObjectsQueryVariables>(
    QueryHubspotContactListObjectsDocument,
    baseOptions
  );
}
export type QueryHubspotContactListObjectsQueryHookResult = ReturnType<typeof useQueryHubspotContactListObjectsQuery>;
export type QueryHubspotContactListObjectsLazyQueryHookResult = ReturnType<
  typeof useQueryHubspotContactListObjectsLazyQuery
>;
export type QueryHubspotContactListObjectsQueryResult = Apollo.QueryResult<
  QueryHubspotContactListObjectsQuery,
  QueryHubspotContactListObjectsQueryVariables
>;
export const SubscribeToHubspotContactListByIdDocument = gql`
  subscription subscribeToHubspotContactListById($hubspotContactListId: uuid!) {
    hubspotContactList_by_pk(id: $hubspotContactListId) {
      ...HubspotContactList
    }
  }
  ${HubspotContactListFragmentDoc}
`;

/**
 * __useSubscribeToHubspotContactListByIdSubscription__
 *
 * To run a query within a React component, call `useSubscribeToHubspotContactListByIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToHubspotContactListByIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToHubspotContactListByIdSubscription({
 *   variables: {
 *      hubspotContactListId: // value for 'hubspotContactListId'
 *   },
 * });
 */
export function useSubscribeToHubspotContactListByIdSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    SubscribeToHubspotContactListByIdSubscription,
    SubscribeToHubspotContactListByIdSubscriptionVariables
  >
) {
  return Apollo.useSubscription<
    SubscribeToHubspotContactListByIdSubscription,
    SubscribeToHubspotContactListByIdSubscriptionVariables
  >(SubscribeToHubspotContactListByIdDocument, baseOptions);
}
export type SubscribeToHubspotContactListByIdSubscriptionHookResult = ReturnType<
  typeof useSubscribeToHubspotContactListByIdSubscription
>;
export type SubscribeToHubspotContactListByIdSubscriptionResult = Apollo.SubscriptionResult<SubscribeToHubspotContactListByIdSubscription>;
export const SubscribeToHubspotContactListObjectsDocument = gql`
  subscription subscribeToHubspotContactListObjects(
    $distinct_on: [hubspotContactList_select_column!]
    $where: hubspotContactList_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [hubspotContactList_order_by!]
  ) {
    hubspotContactList(distinct_on: $distinct_on, where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      ...HubspotContactList
    }
  }
  ${HubspotContactListFragmentDoc}
`;

/**
 * __useSubscribeToHubspotContactListObjectsSubscription__
 *
 * To run a query within a React component, call `useSubscribeToHubspotContactListObjectsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToHubspotContactListObjectsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToHubspotContactListObjectsSubscription({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useSubscribeToHubspotContactListObjectsSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    SubscribeToHubspotContactListObjectsSubscription,
    SubscribeToHubspotContactListObjectsSubscriptionVariables
  >
) {
  return Apollo.useSubscription<
    SubscribeToHubspotContactListObjectsSubscription,
    SubscribeToHubspotContactListObjectsSubscriptionVariables
  >(SubscribeToHubspotContactListObjectsDocument, baseOptions);
}
export type SubscribeToHubspotContactListObjectsSubscriptionHookResult = ReturnType<
  typeof useSubscribeToHubspotContactListObjectsSubscription
>;
export type SubscribeToHubspotContactListObjectsSubscriptionResult = Apollo.SubscriptionResult<SubscribeToHubspotContactListObjectsSubscription>;
export const InsertHubspotContactListDocument = gql`
  mutation insertHubspotContactList($objects: [hubspotContactList_insert_input!]!) {
    insert_hubspotContactList(objects: $objects) {
      affected_rows
      returning {
        ...HubspotContactList
      }
    }
  }
  ${HubspotContactListFragmentDoc}
`;
export type InsertHubspotContactListMutationFn = Apollo.MutationFunction<
  InsertHubspotContactListMutation,
  InsertHubspotContactListMutationVariables
>;

/**
 * __useInsertHubspotContactListMutation__
 *
 * To run a mutation, you first call `useInsertHubspotContactListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertHubspotContactListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertHubspotContactListMutation, { data, loading, error }] = useInsertHubspotContactListMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertHubspotContactListMutation(
  baseOptions?: Apollo.MutationHookOptions<InsertHubspotContactListMutation, InsertHubspotContactListMutationVariables>
) {
  return Apollo.useMutation<InsertHubspotContactListMutation, InsertHubspotContactListMutationVariables>(
    InsertHubspotContactListDocument,
    baseOptions
  );
}
export type InsertHubspotContactListMutationHookResult = ReturnType<typeof useInsertHubspotContactListMutation>;
export type InsertHubspotContactListMutationResult = Apollo.MutationResult<InsertHubspotContactListMutation>;
export type InsertHubspotContactListMutationOptions = Apollo.BaseMutationOptions<
  InsertHubspotContactListMutation,
  InsertHubspotContactListMutationVariables
>;
export const InsertHubspotContactListWithOnConflictDocument = gql`
  mutation insertHubspotContactListWithOnConflict(
    $objects: [hubspotContactList_insert_input!]!
    $onConflict: hubspotContactList_on_conflict
  ) {
    insert_hubspotContactList(objects: $objects, on_conflict: $onConflict) {
      affected_rows
      returning {
        ...HubspotContactList
      }
    }
  }
  ${HubspotContactListFragmentDoc}
`;
export type InsertHubspotContactListWithOnConflictMutationFn = Apollo.MutationFunction<
  InsertHubspotContactListWithOnConflictMutation,
  InsertHubspotContactListWithOnConflictMutationVariables
>;

/**
 * __useInsertHubspotContactListWithOnConflictMutation__
 *
 * To run a mutation, you first call `useInsertHubspotContactListWithOnConflictMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertHubspotContactListWithOnConflictMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertHubspotContactListWithOnConflictMutation, { data, loading, error }] = useInsertHubspotContactListWithOnConflictMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *      onConflict: // value for 'onConflict'
 *   },
 * });
 */
export function useInsertHubspotContactListWithOnConflictMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertHubspotContactListWithOnConflictMutation,
    InsertHubspotContactListWithOnConflictMutationVariables
  >
) {
  return Apollo.useMutation<
    InsertHubspotContactListWithOnConflictMutation,
    InsertHubspotContactListWithOnConflictMutationVariables
  >(InsertHubspotContactListWithOnConflictDocument, baseOptions);
}
export type InsertHubspotContactListWithOnConflictMutationHookResult = ReturnType<
  typeof useInsertHubspotContactListWithOnConflictMutation
>;
export type InsertHubspotContactListWithOnConflictMutationResult = Apollo.MutationResult<InsertHubspotContactListWithOnConflictMutation>;
export type InsertHubspotContactListWithOnConflictMutationOptions = Apollo.BaseMutationOptions<
  InsertHubspotContactListWithOnConflictMutation,
  InsertHubspotContactListWithOnConflictMutationVariables
>;
export const UpdateHubspotContactListByIdDocument = gql`
  mutation updateHubspotContactListById($id: uuid, $set: hubspotContactList_set_input) {
    update_hubspotContactList(_set: $set, where: { id: { _eq: $id } }) {
      affected_rows
      returning {
        ...HubspotContactList
      }
    }
  }
  ${HubspotContactListFragmentDoc}
`;
export type UpdateHubspotContactListByIdMutationFn = Apollo.MutationFunction<
  UpdateHubspotContactListByIdMutation,
  UpdateHubspotContactListByIdMutationVariables
>;

/**
 * __useUpdateHubspotContactListByIdMutation__
 *
 * To run a mutation, you first call `useUpdateHubspotContactListByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHubspotContactListByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHubspotContactListByIdMutation, { data, loading, error }] = useUpdateHubspotContactListByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateHubspotContactListByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateHubspotContactListByIdMutation,
    UpdateHubspotContactListByIdMutationVariables
  >
) {
  return Apollo.useMutation<UpdateHubspotContactListByIdMutation, UpdateHubspotContactListByIdMutationVariables>(
    UpdateHubspotContactListByIdDocument,
    baseOptions
  );
}
export type UpdateHubspotContactListByIdMutationHookResult = ReturnType<typeof useUpdateHubspotContactListByIdMutation>;
export type UpdateHubspotContactListByIdMutationResult = Apollo.MutationResult<UpdateHubspotContactListByIdMutation>;
export type UpdateHubspotContactListByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateHubspotContactListByIdMutation,
  UpdateHubspotContactListByIdMutationVariables
>;
export const UpdateHubspotContactListDocument = gql`
  mutation updateHubspotContactList($set: hubspotContactList_set_input, $where: hubspotContactList_bool_exp!) {
    update_hubspotContactList(_set: $set, where: $where) {
      affected_rows
      returning {
        ...HubspotContactList
      }
    }
  }
  ${HubspotContactListFragmentDoc}
`;
export type UpdateHubspotContactListMutationFn = Apollo.MutationFunction<
  UpdateHubspotContactListMutation,
  UpdateHubspotContactListMutationVariables
>;

/**
 * __useUpdateHubspotContactListMutation__
 *
 * To run a mutation, you first call `useUpdateHubspotContactListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHubspotContactListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHubspotContactListMutation, { data, loading, error }] = useUpdateHubspotContactListMutation({
 *   variables: {
 *      set: // value for 'set'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateHubspotContactListMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateHubspotContactListMutation, UpdateHubspotContactListMutationVariables>
) {
  return Apollo.useMutation<UpdateHubspotContactListMutation, UpdateHubspotContactListMutationVariables>(
    UpdateHubspotContactListDocument,
    baseOptions
  );
}
export type UpdateHubspotContactListMutationHookResult = ReturnType<typeof useUpdateHubspotContactListMutation>;
export type UpdateHubspotContactListMutationResult = Apollo.MutationResult<UpdateHubspotContactListMutation>;
export type UpdateHubspotContactListMutationOptions = Apollo.BaseMutationOptions<
  UpdateHubspotContactListMutation,
  UpdateHubspotContactListMutationVariables
>;
export const RemoveHubspotContactListModelByIdDocument = gql`
  mutation removeHubspotContactListModelById($id: uuid) {
    delete_hubspotContactList(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
export type RemoveHubspotContactListModelByIdMutationFn = Apollo.MutationFunction<
  RemoveHubspotContactListModelByIdMutation,
  RemoveHubspotContactListModelByIdMutationVariables
>;

/**
 * __useRemoveHubspotContactListModelByIdMutation__
 *
 * To run a mutation, you first call `useRemoveHubspotContactListModelByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveHubspotContactListModelByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeHubspotContactListModelByIdMutation, { data, loading, error }] = useRemoveHubspotContactListModelByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveHubspotContactListModelByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveHubspotContactListModelByIdMutation,
    RemoveHubspotContactListModelByIdMutationVariables
  >
) {
  return Apollo.useMutation<
    RemoveHubspotContactListModelByIdMutation,
    RemoveHubspotContactListModelByIdMutationVariables
  >(RemoveHubspotContactListModelByIdDocument, baseOptions);
}
export type RemoveHubspotContactListModelByIdMutationHookResult = ReturnType<
  typeof useRemoveHubspotContactListModelByIdMutation
>;
export type RemoveHubspotContactListModelByIdMutationResult = Apollo.MutationResult<RemoveHubspotContactListModelByIdMutation>;
export type RemoveHubspotContactListModelByIdMutationOptions = Apollo.BaseMutationOptions<
  RemoveHubspotContactListModelByIdMutation,
  RemoveHubspotContactListModelByIdMutationVariables
>;
export const RemoveHubspotContactListModelDocument = gql`
  mutation removeHubspotContactListModel($where: hubspotContactList_bool_exp!) {
    delete_hubspotContactList(where: $where) {
      affected_rows
    }
  }
`;
export type RemoveHubspotContactListModelMutationFn = Apollo.MutationFunction<
  RemoveHubspotContactListModelMutation,
  RemoveHubspotContactListModelMutationVariables
>;

/**
 * __useRemoveHubspotContactListModelMutation__
 *
 * To run a mutation, you first call `useRemoveHubspotContactListModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveHubspotContactListModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeHubspotContactListModelMutation, { data, loading, error }] = useRemoveHubspotContactListModelMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useRemoveHubspotContactListModelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveHubspotContactListModelMutation,
    RemoveHubspotContactListModelMutationVariables
  >
) {
  return Apollo.useMutation<RemoveHubspotContactListModelMutation, RemoveHubspotContactListModelMutationVariables>(
    RemoveHubspotContactListModelDocument,
    baseOptions
  );
}
export type RemoveHubspotContactListModelMutationHookResult = ReturnType<
  typeof useRemoveHubspotContactListModelMutation
>;
export type RemoveHubspotContactListModelMutationResult = Apollo.MutationResult<RemoveHubspotContactListModelMutation>;
export type RemoveHubspotContactListModelMutationOptions = Apollo.BaseMutationOptions<
  RemoveHubspotContactListModelMutation,
  RemoveHubspotContactListModelMutationVariables
>;
export const QueryHubspotContactListHubspotContactByIdDocument = gql`
  query queryHubspotContactListHubspotContactById($hubspotContactListHubspotContactId: uuid!) {
    hubspotContactListHubspotContact_by_pk(id: $hubspotContactListHubspotContactId) {
      ...HubspotContactListHubspotContact
    }
  }
  ${HubspotContactListHubspotContactFragmentDoc}
`;

/**
 * __useQueryHubspotContactListHubspotContactByIdQuery__
 *
 * To run a query within a React component, call `useQueryHubspotContactListHubspotContactByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryHubspotContactListHubspotContactByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryHubspotContactListHubspotContactByIdQuery({
 *   variables: {
 *      hubspotContactListHubspotContactId: // value for 'hubspotContactListHubspotContactId'
 *   },
 * });
 */
export function useQueryHubspotContactListHubspotContactByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryHubspotContactListHubspotContactByIdQuery,
    QueryHubspotContactListHubspotContactByIdQueryVariables
  >
) {
  return Apollo.useQuery<
    QueryHubspotContactListHubspotContactByIdQuery,
    QueryHubspotContactListHubspotContactByIdQueryVariables
  >(QueryHubspotContactListHubspotContactByIdDocument, baseOptions);
}
export function useQueryHubspotContactListHubspotContactByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryHubspotContactListHubspotContactByIdQuery,
    QueryHubspotContactListHubspotContactByIdQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    QueryHubspotContactListHubspotContactByIdQuery,
    QueryHubspotContactListHubspotContactByIdQueryVariables
  >(QueryHubspotContactListHubspotContactByIdDocument, baseOptions);
}
export type QueryHubspotContactListHubspotContactByIdQueryHookResult = ReturnType<
  typeof useQueryHubspotContactListHubspotContactByIdQuery
>;
export type QueryHubspotContactListHubspotContactByIdLazyQueryHookResult = ReturnType<
  typeof useQueryHubspotContactListHubspotContactByIdLazyQuery
>;
export type QueryHubspotContactListHubspotContactByIdQueryResult = Apollo.QueryResult<
  QueryHubspotContactListHubspotContactByIdQuery,
  QueryHubspotContactListHubspotContactByIdQueryVariables
>;
export const QueryHubspotContactListHubspotContactObjectsDocument = gql`
  query queryHubspotContactListHubspotContactObjects(
    $distinct_on: [hubspotContactListHubspotContact_select_column!]
    $where: hubspotContactListHubspotContact_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [hubspotContactListHubspotContact_order_by!]
  ) {
    hubspotContactListHubspotContact(
      distinct_on: $distinct_on
      where: $where
      limit: $limit
      offset: $offset
      order_by: $order_by
    ) {
      ...HubspotContactListHubspotContact
    }
  }
  ${HubspotContactListHubspotContactFragmentDoc}
`;

/**
 * __useQueryHubspotContactListHubspotContactObjectsQuery__
 *
 * To run a query within a React component, call `useQueryHubspotContactListHubspotContactObjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryHubspotContactListHubspotContactObjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryHubspotContactListHubspotContactObjectsQuery({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useQueryHubspotContactListHubspotContactObjectsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    QueryHubspotContactListHubspotContactObjectsQuery,
    QueryHubspotContactListHubspotContactObjectsQueryVariables
  >
) {
  return Apollo.useQuery<
    QueryHubspotContactListHubspotContactObjectsQuery,
    QueryHubspotContactListHubspotContactObjectsQueryVariables
  >(QueryHubspotContactListHubspotContactObjectsDocument, baseOptions);
}
export function useQueryHubspotContactListHubspotContactObjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryHubspotContactListHubspotContactObjectsQuery,
    QueryHubspotContactListHubspotContactObjectsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    QueryHubspotContactListHubspotContactObjectsQuery,
    QueryHubspotContactListHubspotContactObjectsQueryVariables
  >(QueryHubspotContactListHubspotContactObjectsDocument, baseOptions);
}
export type QueryHubspotContactListHubspotContactObjectsQueryHookResult = ReturnType<
  typeof useQueryHubspotContactListHubspotContactObjectsQuery
>;
export type QueryHubspotContactListHubspotContactObjectsLazyQueryHookResult = ReturnType<
  typeof useQueryHubspotContactListHubspotContactObjectsLazyQuery
>;
export type QueryHubspotContactListHubspotContactObjectsQueryResult = Apollo.QueryResult<
  QueryHubspotContactListHubspotContactObjectsQuery,
  QueryHubspotContactListHubspotContactObjectsQueryVariables
>;
export const SubscribeToHubspotContactListHubspotContactByIdDocument = gql`
  subscription subscribeToHubspotContactListHubspotContactById($hubspotContactListHubspotContactId: uuid!) {
    hubspotContactListHubspotContact_by_pk(id: $hubspotContactListHubspotContactId) {
      ...HubspotContactListHubspotContact
    }
  }
  ${HubspotContactListHubspotContactFragmentDoc}
`;

/**
 * __useSubscribeToHubspotContactListHubspotContactByIdSubscription__
 *
 * To run a query within a React component, call `useSubscribeToHubspotContactListHubspotContactByIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToHubspotContactListHubspotContactByIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToHubspotContactListHubspotContactByIdSubscription({
 *   variables: {
 *      hubspotContactListHubspotContactId: // value for 'hubspotContactListHubspotContactId'
 *   },
 * });
 */
export function useSubscribeToHubspotContactListHubspotContactByIdSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    SubscribeToHubspotContactListHubspotContactByIdSubscription,
    SubscribeToHubspotContactListHubspotContactByIdSubscriptionVariables
  >
) {
  return Apollo.useSubscription<
    SubscribeToHubspotContactListHubspotContactByIdSubscription,
    SubscribeToHubspotContactListHubspotContactByIdSubscriptionVariables
  >(SubscribeToHubspotContactListHubspotContactByIdDocument, baseOptions);
}
export type SubscribeToHubspotContactListHubspotContactByIdSubscriptionHookResult = ReturnType<
  typeof useSubscribeToHubspotContactListHubspotContactByIdSubscription
>;
export type SubscribeToHubspotContactListHubspotContactByIdSubscriptionResult = Apollo.SubscriptionResult<SubscribeToHubspotContactListHubspotContactByIdSubscription>;
export const SubscribeToHubspotContactListHubspotContactObjectsDocument = gql`
  subscription subscribeToHubspotContactListHubspotContactObjects(
    $distinct_on: [hubspotContactListHubspotContact_select_column!]
    $where: hubspotContactListHubspotContact_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [hubspotContactListHubspotContact_order_by!]
  ) {
    hubspotContactListHubspotContact(
      distinct_on: $distinct_on
      where: $where
      limit: $limit
      offset: $offset
      order_by: $order_by
    ) {
      ...HubspotContactListHubspotContact
    }
  }
  ${HubspotContactListHubspotContactFragmentDoc}
`;

/**
 * __useSubscribeToHubspotContactListHubspotContactObjectsSubscription__
 *
 * To run a query within a React component, call `useSubscribeToHubspotContactListHubspotContactObjectsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToHubspotContactListHubspotContactObjectsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToHubspotContactListHubspotContactObjectsSubscription({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useSubscribeToHubspotContactListHubspotContactObjectsSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    SubscribeToHubspotContactListHubspotContactObjectsSubscription,
    SubscribeToHubspotContactListHubspotContactObjectsSubscriptionVariables
  >
) {
  return Apollo.useSubscription<
    SubscribeToHubspotContactListHubspotContactObjectsSubscription,
    SubscribeToHubspotContactListHubspotContactObjectsSubscriptionVariables
  >(SubscribeToHubspotContactListHubspotContactObjectsDocument, baseOptions);
}
export type SubscribeToHubspotContactListHubspotContactObjectsSubscriptionHookResult = ReturnType<
  typeof useSubscribeToHubspotContactListHubspotContactObjectsSubscription
>;
export type SubscribeToHubspotContactListHubspotContactObjectsSubscriptionResult = Apollo.SubscriptionResult<SubscribeToHubspotContactListHubspotContactObjectsSubscription>;
export const InsertHubspotContactListHubspotContactDocument = gql`
  mutation insertHubspotContactListHubspotContact($objects: [hubspotContactListHubspotContact_insert_input!]!) {
    insert_hubspotContactListHubspotContact(objects: $objects) {
      affected_rows
      returning {
        ...HubspotContactListHubspotContact
      }
    }
  }
  ${HubspotContactListHubspotContactFragmentDoc}
`;
export type InsertHubspotContactListHubspotContactMutationFn = Apollo.MutationFunction<
  InsertHubspotContactListHubspotContactMutation,
  InsertHubspotContactListHubspotContactMutationVariables
>;

/**
 * __useInsertHubspotContactListHubspotContactMutation__
 *
 * To run a mutation, you first call `useInsertHubspotContactListHubspotContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertHubspotContactListHubspotContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertHubspotContactListHubspotContactMutation, { data, loading, error }] = useInsertHubspotContactListHubspotContactMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertHubspotContactListHubspotContactMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertHubspotContactListHubspotContactMutation,
    InsertHubspotContactListHubspotContactMutationVariables
  >
) {
  return Apollo.useMutation<
    InsertHubspotContactListHubspotContactMutation,
    InsertHubspotContactListHubspotContactMutationVariables
  >(InsertHubspotContactListHubspotContactDocument, baseOptions);
}
export type InsertHubspotContactListHubspotContactMutationHookResult = ReturnType<
  typeof useInsertHubspotContactListHubspotContactMutation
>;
export type InsertHubspotContactListHubspotContactMutationResult = Apollo.MutationResult<InsertHubspotContactListHubspotContactMutation>;
export type InsertHubspotContactListHubspotContactMutationOptions = Apollo.BaseMutationOptions<
  InsertHubspotContactListHubspotContactMutation,
  InsertHubspotContactListHubspotContactMutationVariables
>;
export const InsertHubspotContactListHubspotContactWithOnConflictDocument = gql`
  mutation insertHubspotContactListHubspotContactWithOnConflict(
    $objects: [hubspotContactListHubspotContact_insert_input!]!
    $onConflict: hubspotContactListHubspotContact_on_conflict
  ) {
    insert_hubspotContactListHubspotContact(objects: $objects, on_conflict: $onConflict) {
      affected_rows
      returning {
        ...HubspotContactListHubspotContact
      }
    }
  }
  ${HubspotContactListHubspotContactFragmentDoc}
`;
export type InsertHubspotContactListHubspotContactWithOnConflictMutationFn = Apollo.MutationFunction<
  InsertHubspotContactListHubspotContactWithOnConflictMutation,
  InsertHubspotContactListHubspotContactWithOnConflictMutationVariables
>;

/**
 * __useInsertHubspotContactListHubspotContactWithOnConflictMutation__
 *
 * To run a mutation, you first call `useInsertHubspotContactListHubspotContactWithOnConflictMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertHubspotContactListHubspotContactWithOnConflictMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertHubspotContactListHubspotContactWithOnConflictMutation, { data, loading, error }] = useInsertHubspotContactListHubspotContactWithOnConflictMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *      onConflict: // value for 'onConflict'
 *   },
 * });
 */
export function useInsertHubspotContactListHubspotContactWithOnConflictMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertHubspotContactListHubspotContactWithOnConflictMutation,
    InsertHubspotContactListHubspotContactWithOnConflictMutationVariables
  >
) {
  return Apollo.useMutation<
    InsertHubspotContactListHubspotContactWithOnConflictMutation,
    InsertHubspotContactListHubspotContactWithOnConflictMutationVariables
  >(InsertHubspotContactListHubspotContactWithOnConflictDocument, baseOptions);
}
export type InsertHubspotContactListHubspotContactWithOnConflictMutationHookResult = ReturnType<
  typeof useInsertHubspotContactListHubspotContactWithOnConflictMutation
>;
export type InsertHubspotContactListHubspotContactWithOnConflictMutationResult = Apollo.MutationResult<InsertHubspotContactListHubspotContactWithOnConflictMutation>;
export type InsertHubspotContactListHubspotContactWithOnConflictMutationOptions = Apollo.BaseMutationOptions<
  InsertHubspotContactListHubspotContactWithOnConflictMutation,
  InsertHubspotContactListHubspotContactWithOnConflictMutationVariables
>;
export const UpdateHubspotContactListHubspotContactByIdDocument = gql`
  mutation updateHubspotContactListHubspotContactById($id: uuid, $set: hubspotContactListHubspotContact_set_input) {
    update_hubspotContactListHubspotContact(_set: $set, where: { id: { _eq: $id } }) {
      affected_rows
      returning {
        ...HubspotContactListHubspotContact
      }
    }
  }
  ${HubspotContactListHubspotContactFragmentDoc}
`;
export type UpdateHubspotContactListHubspotContactByIdMutationFn = Apollo.MutationFunction<
  UpdateHubspotContactListHubspotContactByIdMutation,
  UpdateHubspotContactListHubspotContactByIdMutationVariables
>;

/**
 * __useUpdateHubspotContactListHubspotContactByIdMutation__
 *
 * To run a mutation, you first call `useUpdateHubspotContactListHubspotContactByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHubspotContactListHubspotContactByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHubspotContactListHubspotContactByIdMutation, { data, loading, error }] = useUpdateHubspotContactListHubspotContactByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateHubspotContactListHubspotContactByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateHubspotContactListHubspotContactByIdMutation,
    UpdateHubspotContactListHubspotContactByIdMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateHubspotContactListHubspotContactByIdMutation,
    UpdateHubspotContactListHubspotContactByIdMutationVariables
  >(UpdateHubspotContactListHubspotContactByIdDocument, baseOptions);
}
export type UpdateHubspotContactListHubspotContactByIdMutationHookResult = ReturnType<
  typeof useUpdateHubspotContactListHubspotContactByIdMutation
>;
export type UpdateHubspotContactListHubspotContactByIdMutationResult = Apollo.MutationResult<UpdateHubspotContactListHubspotContactByIdMutation>;
export type UpdateHubspotContactListHubspotContactByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateHubspotContactListHubspotContactByIdMutation,
  UpdateHubspotContactListHubspotContactByIdMutationVariables
>;
export const UpdateHubspotContactListHubspotContactDocument = gql`
  mutation updateHubspotContactListHubspotContact(
    $set: hubspotContactListHubspotContact_set_input
    $where: hubspotContactListHubspotContact_bool_exp!
  ) {
    update_hubspotContactListHubspotContact(_set: $set, where: $where) {
      affected_rows
      returning {
        ...HubspotContactListHubspotContact
      }
    }
  }
  ${HubspotContactListHubspotContactFragmentDoc}
`;
export type UpdateHubspotContactListHubspotContactMutationFn = Apollo.MutationFunction<
  UpdateHubspotContactListHubspotContactMutation,
  UpdateHubspotContactListHubspotContactMutationVariables
>;

/**
 * __useUpdateHubspotContactListHubspotContactMutation__
 *
 * To run a mutation, you first call `useUpdateHubspotContactListHubspotContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHubspotContactListHubspotContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHubspotContactListHubspotContactMutation, { data, loading, error }] = useUpdateHubspotContactListHubspotContactMutation({
 *   variables: {
 *      set: // value for 'set'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateHubspotContactListHubspotContactMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateHubspotContactListHubspotContactMutation,
    UpdateHubspotContactListHubspotContactMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateHubspotContactListHubspotContactMutation,
    UpdateHubspotContactListHubspotContactMutationVariables
  >(UpdateHubspotContactListHubspotContactDocument, baseOptions);
}
export type UpdateHubspotContactListHubspotContactMutationHookResult = ReturnType<
  typeof useUpdateHubspotContactListHubspotContactMutation
>;
export type UpdateHubspotContactListHubspotContactMutationResult = Apollo.MutationResult<UpdateHubspotContactListHubspotContactMutation>;
export type UpdateHubspotContactListHubspotContactMutationOptions = Apollo.BaseMutationOptions<
  UpdateHubspotContactListHubspotContactMutation,
  UpdateHubspotContactListHubspotContactMutationVariables
>;
export const RemoveHubspotContactListHubspotContactModelByIdDocument = gql`
  mutation removeHubspotContactListHubspotContactModelById($id: uuid) {
    delete_hubspotContactListHubspotContact(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
export type RemoveHubspotContactListHubspotContactModelByIdMutationFn = Apollo.MutationFunction<
  RemoveHubspotContactListHubspotContactModelByIdMutation,
  RemoveHubspotContactListHubspotContactModelByIdMutationVariables
>;

/**
 * __useRemoveHubspotContactListHubspotContactModelByIdMutation__
 *
 * To run a mutation, you first call `useRemoveHubspotContactListHubspotContactModelByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveHubspotContactListHubspotContactModelByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeHubspotContactListHubspotContactModelByIdMutation, { data, loading, error }] = useRemoveHubspotContactListHubspotContactModelByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveHubspotContactListHubspotContactModelByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveHubspotContactListHubspotContactModelByIdMutation,
    RemoveHubspotContactListHubspotContactModelByIdMutationVariables
  >
) {
  return Apollo.useMutation<
    RemoveHubspotContactListHubspotContactModelByIdMutation,
    RemoveHubspotContactListHubspotContactModelByIdMutationVariables
  >(RemoveHubspotContactListHubspotContactModelByIdDocument, baseOptions);
}
export type RemoveHubspotContactListHubspotContactModelByIdMutationHookResult = ReturnType<
  typeof useRemoveHubspotContactListHubspotContactModelByIdMutation
>;
export type RemoveHubspotContactListHubspotContactModelByIdMutationResult = Apollo.MutationResult<RemoveHubspotContactListHubspotContactModelByIdMutation>;
export type RemoveHubspotContactListHubspotContactModelByIdMutationOptions = Apollo.BaseMutationOptions<
  RemoveHubspotContactListHubspotContactModelByIdMutation,
  RemoveHubspotContactListHubspotContactModelByIdMutationVariables
>;
export const RemoveHubspotContactListHubspotContactModelDocument = gql`
  mutation removeHubspotContactListHubspotContactModel($where: hubspotContactListHubspotContact_bool_exp!) {
    delete_hubspotContactListHubspotContact(where: $where) {
      affected_rows
    }
  }
`;
export type RemoveHubspotContactListHubspotContactModelMutationFn = Apollo.MutationFunction<
  RemoveHubspotContactListHubspotContactModelMutation,
  RemoveHubspotContactListHubspotContactModelMutationVariables
>;

/**
 * __useRemoveHubspotContactListHubspotContactModelMutation__
 *
 * To run a mutation, you first call `useRemoveHubspotContactListHubspotContactModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveHubspotContactListHubspotContactModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeHubspotContactListHubspotContactModelMutation, { data, loading, error }] = useRemoveHubspotContactListHubspotContactModelMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useRemoveHubspotContactListHubspotContactModelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveHubspotContactListHubspotContactModelMutation,
    RemoveHubspotContactListHubspotContactModelMutationVariables
  >
) {
  return Apollo.useMutation<
    RemoveHubspotContactListHubspotContactModelMutation,
    RemoveHubspotContactListHubspotContactModelMutationVariables
  >(RemoveHubspotContactListHubspotContactModelDocument, baseOptions);
}
export type RemoveHubspotContactListHubspotContactModelMutationHookResult = ReturnType<
  typeof useRemoveHubspotContactListHubspotContactModelMutation
>;
export type RemoveHubspotContactListHubspotContactModelMutationResult = Apollo.MutationResult<RemoveHubspotContactListHubspotContactModelMutation>;
export type RemoveHubspotContactListHubspotContactModelMutationOptions = Apollo.BaseMutationOptions<
  RemoveHubspotContactListHubspotContactModelMutation,
  RemoveHubspotContactListHubspotContactModelMutationVariables
>;
export const QueryHubspotContactPropertyByIdDocument = gql`
  query queryHubspotContactPropertyById($hubspotContactPropertyId: uuid!) {
    hubspotContactProperty_by_pk(id: $hubspotContactPropertyId) {
      ...HubspotContactProperty
    }
  }
  ${HubspotContactPropertyFragmentDoc}
`;

/**
 * __useQueryHubspotContactPropertyByIdQuery__
 *
 * To run a query within a React component, call `useQueryHubspotContactPropertyByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryHubspotContactPropertyByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryHubspotContactPropertyByIdQuery({
 *   variables: {
 *      hubspotContactPropertyId: // value for 'hubspotContactPropertyId'
 *   },
 * });
 */
export function useQueryHubspotContactPropertyByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryHubspotContactPropertyByIdQuery,
    QueryHubspotContactPropertyByIdQueryVariables
  >
) {
  return Apollo.useQuery<QueryHubspotContactPropertyByIdQuery, QueryHubspotContactPropertyByIdQueryVariables>(
    QueryHubspotContactPropertyByIdDocument,
    baseOptions
  );
}
export function useQueryHubspotContactPropertyByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryHubspotContactPropertyByIdQuery,
    QueryHubspotContactPropertyByIdQueryVariables
  >
) {
  return Apollo.useLazyQuery<QueryHubspotContactPropertyByIdQuery, QueryHubspotContactPropertyByIdQueryVariables>(
    QueryHubspotContactPropertyByIdDocument,
    baseOptions
  );
}
export type QueryHubspotContactPropertyByIdQueryHookResult = ReturnType<typeof useQueryHubspotContactPropertyByIdQuery>;
export type QueryHubspotContactPropertyByIdLazyQueryHookResult = ReturnType<
  typeof useQueryHubspotContactPropertyByIdLazyQuery
>;
export type QueryHubspotContactPropertyByIdQueryResult = Apollo.QueryResult<
  QueryHubspotContactPropertyByIdQuery,
  QueryHubspotContactPropertyByIdQueryVariables
>;
export const QueryHubspotContactPropertyObjectsDocument = gql`
  query queryHubspotContactPropertyObjects(
    $distinct_on: [hubspotContactProperty_select_column!]
    $where: hubspotContactProperty_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [hubspotContactProperty_order_by!]
  ) {
    hubspotContactProperty(
      distinct_on: $distinct_on
      where: $where
      limit: $limit
      offset: $offset
      order_by: $order_by
    ) {
      ...HubspotContactProperty
    }
  }
  ${HubspotContactPropertyFragmentDoc}
`;

/**
 * __useQueryHubspotContactPropertyObjectsQuery__
 *
 * To run a query within a React component, call `useQueryHubspotContactPropertyObjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryHubspotContactPropertyObjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryHubspotContactPropertyObjectsQuery({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useQueryHubspotContactPropertyObjectsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    QueryHubspotContactPropertyObjectsQuery,
    QueryHubspotContactPropertyObjectsQueryVariables
  >
) {
  return Apollo.useQuery<QueryHubspotContactPropertyObjectsQuery, QueryHubspotContactPropertyObjectsQueryVariables>(
    QueryHubspotContactPropertyObjectsDocument,
    baseOptions
  );
}
export function useQueryHubspotContactPropertyObjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryHubspotContactPropertyObjectsQuery,
    QueryHubspotContactPropertyObjectsQueryVariables
  >
) {
  return Apollo.useLazyQuery<QueryHubspotContactPropertyObjectsQuery, QueryHubspotContactPropertyObjectsQueryVariables>(
    QueryHubspotContactPropertyObjectsDocument,
    baseOptions
  );
}
export type QueryHubspotContactPropertyObjectsQueryHookResult = ReturnType<
  typeof useQueryHubspotContactPropertyObjectsQuery
>;
export type QueryHubspotContactPropertyObjectsLazyQueryHookResult = ReturnType<
  typeof useQueryHubspotContactPropertyObjectsLazyQuery
>;
export type QueryHubspotContactPropertyObjectsQueryResult = Apollo.QueryResult<
  QueryHubspotContactPropertyObjectsQuery,
  QueryHubspotContactPropertyObjectsQueryVariables
>;
export const SubscribeToHubspotContactPropertyByIdDocument = gql`
  subscription subscribeToHubspotContactPropertyById($hubspotContactPropertyId: uuid!) {
    hubspotContactProperty_by_pk(id: $hubspotContactPropertyId) {
      ...HubspotContactProperty
    }
  }
  ${HubspotContactPropertyFragmentDoc}
`;

/**
 * __useSubscribeToHubspotContactPropertyByIdSubscription__
 *
 * To run a query within a React component, call `useSubscribeToHubspotContactPropertyByIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToHubspotContactPropertyByIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToHubspotContactPropertyByIdSubscription({
 *   variables: {
 *      hubspotContactPropertyId: // value for 'hubspotContactPropertyId'
 *   },
 * });
 */
export function useSubscribeToHubspotContactPropertyByIdSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    SubscribeToHubspotContactPropertyByIdSubscription,
    SubscribeToHubspotContactPropertyByIdSubscriptionVariables
  >
) {
  return Apollo.useSubscription<
    SubscribeToHubspotContactPropertyByIdSubscription,
    SubscribeToHubspotContactPropertyByIdSubscriptionVariables
  >(SubscribeToHubspotContactPropertyByIdDocument, baseOptions);
}
export type SubscribeToHubspotContactPropertyByIdSubscriptionHookResult = ReturnType<
  typeof useSubscribeToHubspotContactPropertyByIdSubscription
>;
export type SubscribeToHubspotContactPropertyByIdSubscriptionResult = Apollo.SubscriptionResult<SubscribeToHubspotContactPropertyByIdSubscription>;
export const SubscribeToHubspotContactPropertyObjectsDocument = gql`
  subscription subscribeToHubspotContactPropertyObjects(
    $distinct_on: [hubspotContactProperty_select_column!]
    $where: hubspotContactProperty_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [hubspotContactProperty_order_by!]
  ) {
    hubspotContactProperty(
      distinct_on: $distinct_on
      where: $where
      limit: $limit
      offset: $offset
      order_by: $order_by
    ) {
      ...HubspotContactProperty
    }
  }
  ${HubspotContactPropertyFragmentDoc}
`;

/**
 * __useSubscribeToHubspotContactPropertyObjectsSubscription__
 *
 * To run a query within a React component, call `useSubscribeToHubspotContactPropertyObjectsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToHubspotContactPropertyObjectsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToHubspotContactPropertyObjectsSubscription({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useSubscribeToHubspotContactPropertyObjectsSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    SubscribeToHubspotContactPropertyObjectsSubscription,
    SubscribeToHubspotContactPropertyObjectsSubscriptionVariables
  >
) {
  return Apollo.useSubscription<
    SubscribeToHubspotContactPropertyObjectsSubscription,
    SubscribeToHubspotContactPropertyObjectsSubscriptionVariables
  >(SubscribeToHubspotContactPropertyObjectsDocument, baseOptions);
}
export type SubscribeToHubspotContactPropertyObjectsSubscriptionHookResult = ReturnType<
  typeof useSubscribeToHubspotContactPropertyObjectsSubscription
>;
export type SubscribeToHubspotContactPropertyObjectsSubscriptionResult = Apollo.SubscriptionResult<SubscribeToHubspotContactPropertyObjectsSubscription>;
export const InsertHubspotContactPropertyDocument = gql`
  mutation insertHubspotContactProperty($objects: [hubspotContactProperty_insert_input!]!) {
    insert_hubspotContactProperty(objects: $objects) {
      affected_rows
      returning {
        ...HubspotContactProperty
      }
    }
  }
  ${HubspotContactPropertyFragmentDoc}
`;
export type InsertHubspotContactPropertyMutationFn = Apollo.MutationFunction<
  InsertHubspotContactPropertyMutation,
  InsertHubspotContactPropertyMutationVariables
>;

/**
 * __useInsertHubspotContactPropertyMutation__
 *
 * To run a mutation, you first call `useInsertHubspotContactPropertyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertHubspotContactPropertyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertHubspotContactPropertyMutation, { data, loading, error }] = useInsertHubspotContactPropertyMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertHubspotContactPropertyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertHubspotContactPropertyMutation,
    InsertHubspotContactPropertyMutationVariables
  >
) {
  return Apollo.useMutation<InsertHubspotContactPropertyMutation, InsertHubspotContactPropertyMutationVariables>(
    InsertHubspotContactPropertyDocument,
    baseOptions
  );
}
export type InsertHubspotContactPropertyMutationHookResult = ReturnType<typeof useInsertHubspotContactPropertyMutation>;
export type InsertHubspotContactPropertyMutationResult = Apollo.MutationResult<InsertHubspotContactPropertyMutation>;
export type InsertHubspotContactPropertyMutationOptions = Apollo.BaseMutationOptions<
  InsertHubspotContactPropertyMutation,
  InsertHubspotContactPropertyMutationVariables
>;
export const InsertHubspotContactPropertyWithOnConflictDocument = gql`
  mutation insertHubspotContactPropertyWithOnConflict(
    $objects: [hubspotContactProperty_insert_input!]!
    $onConflict: hubspotContactProperty_on_conflict
  ) {
    insert_hubspotContactProperty(objects: $objects, on_conflict: $onConflict) {
      affected_rows
      returning {
        ...HubspotContactProperty
      }
    }
  }
  ${HubspotContactPropertyFragmentDoc}
`;
export type InsertHubspotContactPropertyWithOnConflictMutationFn = Apollo.MutationFunction<
  InsertHubspotContactPropertyWithOnConflictMutation,
  InsertHubspotContactPropertyWithOnConflictMutationVariables
>;

/**
 * __useInsertHubspotContactPropertyWithOnConflictMutation__
 *
 * To run a mutation, you first call `useInsertHubspotContactPropertyWithOnConflictMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertHubspotContactPropertyWithOnConflictMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertHubspotContactPropertyWithOnConflictMutation, { data, loading, error }] = useInsertHubspotContactPropertyWithOnConflictMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *      onConflict: // value for 'onConflict'
 *   },
 * });
 */
export function useInsertHubspotContactPropertyWithOnConflictMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertHubspotContactPropertyWithOnConflictMutation,
    InsertHubspotContactPropertyWithOnConflictMutationVariables
  >
) {
  return Apollo.useMutation<
    InsertHubspotContactPropertyWithOnConflictMutation,
    InsertHubspotContactPropertyWithOnConflictMutationVariables
  >(InsertHubspotContactPropertyWithOnConflictDocument, baseOptions);
}
export type InsertHubspotContactPropertyWithOnConflictMutationHookResult = ReturnType<
  typeof useInsertHubspotContactPropertyWithOnConflictMutation
>;
export type InsertHubspotContactPropertyWithOnConflictMutationResult = Apollo.MutationResult<InsertHubspotContactPropertyWithOnConflictMutation>;
export type InsertHubspotContactPropertyWithOnConflictMutationOptions = Apollo.BaseMutationOptions<
  InsertHubspotContactPropertyWithOnConflictMutation,
  InsertHubspotContactPropertyWithOnConflictMutationVariables
>;
export const UpdateHubspotContactPropertyByIdDocument = gql`
  mutation updateHubspotContactPropertyById($id: uuid, $set: hubspotContactProperty_set_input) {
    update_hubspotContactProperty(_set: $set, where: { id: { _eq: $id } }) {
      affected_rows
      returning {
        ...HubspotContactProperty
      }
    }
  }
  ${HubspotContactPropertyFragmentDoc}
`;
export type UpdateHubspotContactPropertyByIdMutationFn = Apollo.MutationFunction<
  UpdateHubspotContactPropertyByIdMutation,
  UpdateHubspotContactPropertyByIdMutationVariables
>;

/**
 * __useUpdateHubspotContactPropertyByIdMutation__
 *
 * To run a mutation, you first call `useUpdateHubspotContactPropertyByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHubspotContactPropertyByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHubspotContactPropertyByIdMutation, { data, loading, error }] = useUpdateHubspotContactPropertyByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateHubspotContactPropertyByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateHubspotContactPropertyByIdMutation,
    UpdateHubspotContactPropertyByIdMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateHubspotContactPropertyByIdMutation,
    UpdateHubspotContactPropertyByIdMutationVariables
  >(UpdateHubspotContactPropertyByIdDocument, baseOptions);
}
export type UpdateHubspotContactPropertyByIdMutationHookResult = ReturnType<
  typeof useUpdateHubspotContactPropertyByIdMutation
>;
export type UpdateHubspotContactPropertyByIdMutationResult = Apollo.MutationResult<UpdateHubspotContactPropertyByIdMutation>;
export type UpdateHubspotContactPropertyByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateHubspotContactPropertyByIdMutation,
  UpdateHubspotContactPropertyByIdMutationVariables
>;
export const UpdateHubspotContactPropertyDocument = gql`
  mutation updateHubspotContactProperty(
    $set: hubspotContactProperty_set_input
    $where: hubspotContactProperty_bool_exp!
  ) {
    update_hubspotContactProperty(_set: $set, where: $where) {
      affected_rows
      returning {
        ...HubspotContactProperty
      }
    }
  }
  ${HubspotContactPropertyFragmentDoc}
`;
export type UpdateHubspotContactPropertyMutationFn = Apollo.MutationFunction<
  UpdateHubspotContactPropertyMutation,
  UpdateHubspotContactPropertyMutationVariables
>;

/**
 * __useUpdateHubspotContactPropertyMutation__
 *
 * To run a mutation, you first call `useUpdateHubspotContactPropertyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHubspotContactPropertyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHubspotContactPropertyMutation, { data, loading, error }] = useUpdateHubspotContactPropertyMutation({
 *   variables: {
 *      set: // value for 'set'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateHubspotContactPropertyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateHubspotContactPropertyMutation,
    UpdateHubspotContactPropertyMutationVariables
  >
) {
  return Apollo.useMutation<UpdateHubspotContactPropertyMutation, UpdateHubspotContactPropertyMutationVariables>(
    UpdateHubspotContactPropertyDocument,
    baseOptions
  );
}
export type UpdateHubspotContactPropertyMutationHookResult = ReturnType<typeof useUpdateHubspotContactPropertyMutation>;
export type UpdateHubspotContactPropertyMutationResult = Apollo.MutationResult<UpdateHubspotContactPropertyMutation>;
export type UpdateHubspotContactPropertyMutationOptions = Apollo.BaseMutationOptions<
  UpdateHubspotContactPropertyMutation,
  UpdateHubspotContactPropertyMutationVariables
>;
export const RemoveHubspotContactPropertyModelByIdDocument = gql`
  mutation removeHubspotContactPropertyModelById($id: uuid) {
    delete_hubspotContactProperty(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
export type RemoveHubspotContactPropertyModelByIdMutationFn = Apollo.MutationFunction<
  RemoveHubspotContactPropertyModelByIdMutation,
  RemoveHubspotContactPropertyModelByIdMutationVariables
>;

/**
 * __useRemoveHubspotContactPropertyModelByIdMutation__
 *
 * To run a mutation, you first call `useRemoveHubspotContactPropertyModelByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveHubspotContactPropertyModelByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeHubspotContactPropertyModelByIdMutation, { data, loading, error }] = useRemoveHubspotContactPropertyModelByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveHubspotContactPropertyModelByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveHubspotContactPropertyModelByIdMutation,
    RemoveHubspotContactPropertyModelByIdMutationVariables
  >
) {
  return Apollo.useMutation<
    RemoveHubspotContactPropertyModelByIdMutation,
    RemoveHubspotContactPropertyModelByIdMutationVariables
  >(RemoveHubspotContactPropertyModelByIdDocument, baseOptions);
}
export type RemoveHubspotContactPropertyModelByIdMutationHookResult = ReturnType<
  typeof useRemoveHubspotContactPropertyModelByIdMutation
>;
export type RemoveHubspotContactPropertyModelByIdMutationResult = Apollo.MutationResult<RemoveHubspotContactPropertyModelByIdMutation>;
export type RemoveHubspotContactPropertyModelByIdMutationOptions = Apollo.BaseMutationOptions<
  RemoveHubspotContactPropertyModelByIdMutation,
  RemoveHubspotContactPropertyModelByIdMutationVariables
>;
export const RemoveHubspotContactPropertyModelDocument = gql`
  mutation removeHubspotContactPropertyModel($where: hubspotContactProperty_bool_exp!) {
    delete_hubspotContactProperty(where: $where) {
      affected_rows
    }
  }
`;
export type RemoveHubspotContactPropertyModelMutationFn = Apollo.MutationFunction<
  RemoveHubspotContactPropertyModelMutation,
  RemoveHubspotContactPropertyModelMutationVariables
>;

/**
 * __useRemoveHubspotContactPropertyModelMutation__
 *
 * To run a mutation, you first call `useRemoveHubspotContactPropertyModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveHubspotContactPropertyModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeHubspotContactPropertyModelMutation, { data, loading, error }] = useRemoveHubspotContactPropertyModelMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useRemoveHubspotContactPropertyModelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveHubspotContactPropertyModelMutation,
    RemoveHubspotContactPropertyModelMutationVariables
  >
) {
  return Apollo.useMutation<
    RemoveHubspotContactPropertyModelMutation,
    RemoveHubspotContactPropertyModelMutationVariables
  >(RemoveHubspotContactPropertyModelDocument, baseOptions);
}
export type RemoveHubspotContactPropertyModelMutationHookResult = ReturnType<
  typeof useRemoveHubspotContactPropertyModelMutation
>;
export type RemoveHubspotContactPropertyModelMutationResult = Apollo.MutationResult<RemoveHubspotContactPropertyModelMutation>;
export type RemoveHubspotContactPropertyModelMutationOptions = Apollo.BaseMutationOptions<
  RemoveHubspotContactPropertyModelMutation,
  RemoveHubspotContactPropertyModelMutationVariables
>;
export const QuerySalesforceContactFieldByIdDocument = gql`
  query querySalesforceContactFieldById($salesforceContactFieldId: uuid!) {
    salesforceContactField_by_pk(id: $salesforceContactFieldId) {
      ...SalesforceContactField
    }
  }
  ${SalesforceContactFieldFragmentDoc}
`;

/**
 * __useQuerySalesforceContactFieldByIdQuery__
 *
 * To run a query within a React component, call `useQuerySalesforceContactFieldByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuerySalesforceContactFieldByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuerySalesforceContactFieldByIdQuery({
 *   variables: {
 *      salesforceContactFieldId: // value for 'salesforceContactFieldId'
 *   },
 * });
 */
export function useQuerySalesforceContactFieldByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    QuerySalesforceContactFieldByIdQuery,
    QuerySalesforceContactFieldByIdQueryVariables
  >
) {
  return Apollo.useQuery<QuerySalesforceContactFieldByIdQuery, QuerySalesforceContactFieldByIdQueryVariables>(
    QuerySalesforceContactFieldByIdDocument,
    baseOptions
  );
}
export function useQuerySalesforceContactFieldByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QuerySalesforceContactFieldByIdQuery,
    QuerySalesforceContactFieldByIdQueryVariables
  >
) {
  return Apollo.useLazyQuery<QuerySalesforceContactFieldByIdQuery, QuerySalesforceContactFieldByIdQueryVariables>(
    QuerySalesforceContactFieldByIdDocument,
    baseOptions
  );
}
export type QuerySalesforceContactFieldByIdQueryHookResult = ReturnType<typeof useQuerySalesforceContactFieldByIdQuery>;
export type QuerySalesforceContactFieldByIdLazyQueryHookResult = ReturnType<
  typeof useQuerySalesforceContactFieldByIdLazyQuery
>;
export type QuerySalesforceContactFieldByIdQueryResult = Apollo.QueryResult<
  QuerySalesforceContactFieldByIdQuery,
  QuerySalesforceContactFieldByIdQueryVariables
>;
export const QuerySalesforceContactFieldObjectsDocument = gql`
  query querySalesforceContactFieldObjects(
    $distinct_on: [salesforceContactField_select_column!]
    $where: salesforceContactField_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [salesforceContactField_order_by!]
  ) {
    salesforceContactField(
      distinct_on: $distinct_on
      where: $where
      limit: $limit
      offset: $offset
      order_by: $order_by
    ) {
      ...SalesforceContactField
    }
  }
  ${SalesforceContactFieldFragmentDoc}
`;

/**
 * __useQuerySalesforceContactFieldObjectsQuery__
 *
 * To run a query within a React component, call `useQuerySalesforceContactFieldObjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuerySalesforceContactFieldObjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuerySalesforceContactFieldObjectsQuery({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useQuerySalesforceContactFieldObjectsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    QuerySalesforceContactFieldObjectsQuery,
    QuerySalesforceContactFieldObjectsQueryVariables
  >
) {
  return Apollo.useQuery<QuerySalesforceContactFieldObjectsQuery, QuerySalesforceContactFieldObjectsQueryVariables>(
    QuerySalesforceContactFieldObjectsDocument,
    baseOptions
  );
}
export function useQuerySalesforceContactFieldObjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QuerySalesforceContactFieldObjectsQuery,
    QuerySalesforceContactFieldObjectsQueryVariables
  >
) {
  return Apollo.useLazyQuery<QuerySalesforceContactFieldObjectsQuery, QuerySalesforceContactFieldObjectsQueryVariables>(
    QuerySalesforceContactFieldObjectsDocument,
    baseOptions
  );
}
export type QuerySalesforceContactFieldObjectsQueryHookResult = ReturnType<
  typeof useQuerySalesforceContactFieldObjectsQuery
>;
export type QuerySalesforceContactFieldObjectsLazyQueryHookResult = ReturnType<
  typeof useQuerySalesforceContactFieldObjectsLazyQuery
>;
export type QuerySalesforceContactFieldObjectsQueryResult = Apollo.QueryResult<
  QuerySalesforceContactFieldObjectsQuery,
  QuerySalesforceContactFieldObjectsQueryVariables
>;
export const SubscribeToSalesforceContactFieldByIdDocument = gql`
  subscription subscribeToSalesforceContactFieldById($salesforceContactFieldId: uuid!) {
    salesforceContactField_by_pk(id: $salesforceContactFieldId) {
      ...SalesforceContactField
    }
  }
  ${SalesforceContactFieldFragmentDoc}
`;

/**
 * __useSubscribeToSalesforceContactFieldByIdSubscription__
 *
 * To run a query within a React component, call `useSubscribeToSalesforceContactFieldByIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToSalesforceContactFieldByIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToSalesforceContactFieldByIdSubscription({
 *   variables: {
 *      salesforceContactFieldId: // value for 'salesforceContactFieldId'
 *   },
 * });
 */
export function useSubscribeToSalesforceContactFieldByIdSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    SubscribeToSalesforceContactFieldByIdSubscription,
    SubscribeToSalesforceContactFieldByIdSubscriptionVariables
  >
) {
  return Apollo.useSubscription<
    SubscribeToSalesforceContactFieldByIdSubscription,
    SubscribeToSalesforceContactFieldByIdSubscriptionVariables
  >(SubscribeToSalesforceContactFieldByIdDocument, baseOptions);
}
export type SubscribeToSalesforceContactFieldByIdSubscriptionHookResult = ReturnType<
  typeof useSubscribeToSalesforceContactFieldByIdSubscription
>;
export type SubscribeToSalesforceContactFieldByIdSubscriptionResult = Apollo.SubscriptionResult<SubscribeToSalesforceContactFieldByIdSubscription>;
export const SubscribeToSalesforceContactFieldObjectsDocument = gql`
  subscription subscribeToSalesforceContactFieldObjects(
    $distinct_on: [salesforceContactField_select_column!]
    $where: salesforceContactField_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [salesforceContactField_order_by!]
  ) {
    salesforceContactField(
      distinct_on: $distinct_on
      where: $where
      limit: $limit
      offset: $offset
      order_by: $order_by
    ) {
      ...SalesforceContactField
    }
  }
  ${SalesforceContactFieldFragmentDoc}
`;

/**
 * __useSubscribeToSalesforceContactFieldObjectsSubscription__
 *
 * To run a query within a React component, call `useSubscribeToSalesforceContactFieldObjectsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToSalesforceContactFieldObjectsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToSalesforceContactFieldObjectsSubscription({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useSubscribeToSalesforceContactFieldObjectsSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    SubscribeToSalesforceContactFieldObjectsSubscription,
    SubscribeToSalesforceContactFieldObjectsSubscriptionVariables
  >
) {
  return Apollo.useSubscription<
    SubscribeToSalesforceContactFieldObjectsSubscription,
    SubscribeToSalesforceContactFieldObjectsSubscriptionVariables
  >(SubscribeToSalesforceContactFieldObjectsDocument, baseOptions);
}
export type SubscribeToSalesforceContactFieldObjectsSubscriptionHookResult = ReturnType<
  typeof useSubscribeToSalesforceContactFieldObjectsSubscription
>;
export type SubscribeToSalesforceContactFieldObjectsSubscriptionResult = Apollo.SubscriptionResult<SubscribeToSalesforceContactFieldObjectsSubscription>;
export const InsertSalesforceContactFieldDocument = gql`
  mutation insertSalesforceContactField($objects: [salesforceContactField_insert_input!]!) {
    insert_salesforceContactField(objects: $objects) {
      affected_rows
      returning {
        ...SalesforceContactField
      }
    }
  }
  ${SalesforceContactFieldFragmentDoc}
`;
export type InsertSalesforceContactFieldMutationFn = Apollo.MutationFunction<
  InsertSalesforceContactFieldMutation,
  InsertSalesforceContactFieldMutationVariables
>;

/**
 * __useInsertSalesforceContactFieldMutation__
 *
 * To run a mutation, you first call `useInsertSalesforceContactFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertSalesforceContactFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertSalesforceContactFieldMutation, { data, loading, error }] = useInsertSalesforceContactFieldMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertSalesforceContactFieldMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertSalesforceContactFieldMutation,
    InsertSalesforceContactFieldMutationVariables
  >
) {
  return Apollo.useMutation<InsertSalesforceContactFieldMutation, InsertSalesforceContactFieldMutationVariables>(
    InsertSalesforceContactFieldDocument,
    baseOptions
  );
}
export type InsertSalesforceContactFieldMutationHookResult = ReturnType<typeof useInsertSalesforceContactFieldMutation>;
export type InsertSalesforceContactFieldMutationResult = Apollo.MutationResult<InsertSalesforceContactFieldMutation>;
export type InsertSalesforceContactFieldMutationOptions = Apollo.BaseMutationOptions<
  InsertSalesforceContactFieldMutation,
  InsertSalesforceContactFieldMutationVariables
>;
export const InsertSalesforceContactFieldWithOnConflictDocument = gql`
  mutation insertSalesforceContactFieldWithOnConflict(
    $objects: [salesforceContactField_insert_input!]!
    $onConflict: salesforceContactField_on_conflict
  ) {
    insert_salesforceContactField(objects: $objects, on_conflict: $onConflict) {
      affected_rows
      returning {
        ...SalesforceContactField
      }
    }
  }
  ${SalesforceContactFieldFragmentDoc}
`;
export type InsertSalesforceContactFieldWithOnConflictMutationFn = Apollo.MutationFunction<
  InsertSalesforceContactFieldWithOnConflictMutation,
  InsertSalesforceContactFieldWithOnConflictMutationVariables
>;

/**
 * __useInsertSalesforceContactFieldWithOnConflictMutation__
 *
 * To run a mutation, you first call `useInsertSalesforceContactFieldWithOnConflictMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertSalesforceContactFieldWithOnConflictMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertSalesforceContactFieldWithOnConflictMutation, { data, loading, error }] = useInsertSalesforceContactFieldWithOnConflictMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *      onConflict: // value for 'onConflict'
 *   },
 * });
 */
export function useInsertSalesforceContactFieldWithOnConflictMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertSalesforceContactFieldWithOnConflictMutation,
    InsertSalesforceContactFieldWithOnConflictMutationVariables
  >
) {
  return Apollo.useMutation<
    InsertSalesforceContactFieldWithOnConflictMutation,
    InsertSalesforceContactFieldWithOnConflictMutationVariables
  >(InsertSalesforceContactFieldWithOnConflictDocument, baseOptions);
}
export type InsertSalesforceContactFieldWithOnConflictMutationHookResult = ReturnType<
  typeof useInsertSalesforceContactFieldWithOnConflictMutation
>;
export type InsertSalesforceContactFieldWithOnConflictMutationResult = Apollo.MutationResult<InsertSalesforceContactFieldWithOnConflictMutation>;
export type InsertSalesforceContactFieldWithOnConflictMutationOptions = Apollo.BaseMutationOptions<
  InsertSalesforceContactFieldWithOnConflictMutation,
  InsertSalesforceContactFieldWithOnConflictMutationVariables
>;
export const UpdateSalesforceContactFieldByIdDocument = gql`
  mutation updateSalesforceContactFieldById($id: uuid, $set: salesforceContactField_set_input) {
    update_salesforceContactField(_set: $set, where: { id: { _eq: $id } }) {
      affected_rows
      returning {
        ...SalesforceContactField
      }
    }
  }
  ${SalesforceContactFieldFragmentDoc}
`;
export type UpdateSalesforceContactFieldByIdMutationFn = Apollo.MutationFunction<
  UpdateSalesforceContactFieldByIdMutation,
  UpdateSalesforceContactFieldByIdMutationVariables
>;

/**
 * __useUpdateSalesforceContactFieldByIdMutation__
 *
 * To run a mutation, you first call `useUpdateSalesforceContactFieldByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSalesforceContactFieldByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSalesforceContactFieldByIdMutation, { data, loading, error }] = useUpdateSalesforceContactFieldByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateSalesforceContactFieldByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSalesforceContactFieldByIdMutation,
    UpdateSalesforceContactFieldByIdMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateSalesforceContactFieldByIdMutation,
    UpdateSalesforceContactFieldByIdMutationVariables
  >(UpdateSalesforceContactFieldByIdDocument, baseOptions);
}
export type UpdateSalesforceContactFieldByIdMutationHookResult = ReturnType<
  typeof useUpdateSalesforceContactFieldByIdMutation
>;
export type UpdateSalesforceContactFieldByIdMutationResult = Apollo.MutationResult<UpdateSalesforceContactFieldByIdMutation>;
export type UpdateSalesforceContactFieldByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateSalesforceContactFieldByIdMutation,
  UpdateSalesforceContactFieldByIdMutationVariables
>;
export const UpdateSalesforceContactFieldDocument = gql`
  mutation updateSalesforceContactField(
    $set: salesforceContactField_set_input
    $where: salesforceContactField_bool_exp!
  ) {
    update_salesforceContactField(_set: $set, where: $where) {
      affected_rows
      returning {
        ...SalesforceContactField
      }
    }
  }
  ${SalesforceContactFieldFragmentDoc}
`;
export type UpdateSalesforceContactFieldMutationFn = Apollo.MutationFunction<
  UpdateSalesforceContactFieldMutation,
  UpdateSalesforceContactFieldMutationVariables
>;

/**
 * __useUpdateSalesforceContactFieldMutation__
 *
 * To run a mutation, you first call `useUpdateSalesforceContactFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSalesforceContactFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSalesforceContactFieldMutation, { data, loading, error }] = useUpdateSalesforceContactFieldMutation({
 *   variables: {
 *      set: // value for 'set'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateSalesforceContactFieldMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSalesforceContactFieldMutation,
    UpdateSalesforceContactFieldMutationVariables
  >
) {
  return Apollo.useMutation<UpdateSalesforceContactFieldMutation, UpdateSalesforceContactFieldMutationVariables>(
    UpdateSalesforceContactFieldDocument,
    baseOptions
  );
}
export type UpdateSalesforceContactFieldMutationHookResult = ReturnType<typeof useUpdateSalesforceContactFieldMutation>;
export type UpdateSalesforceContactFieldMutationResult = Apollo.MutationResult<UpdateSalesforceContactFieldMutation>;
export type UpdateSalesforceContactFieldMutationOptions = Apollo.BaseMutationOptions<
  UpdateSalesforceContactFieldMutation,
  UpdateSalesforceContactFieldMutationVariables
>;
export const RemoveSalesforceContactFieldModelByIdDocument = gql`
  mutation removeSalesforceContactFieldModelById($id: uuid) {
    delete_salesforceContactField(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
export type RemoveSalesforceContactFieldModelByIdMutationFn = Apollo.MutationFunction<
  RemoveSalesforceContactFieldModelByIdMutation,
  RemoveSalesforceContactFieldModelByIdMutationVariables
>;

/**
 * __useRemoveSalesforceContactFieldModelByIdMutation__
 *
 * To run a mutation, you first call `useRemoveSalesforceContactFieldModelByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSalesforceContactFieldModelByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSalesforceContactFieldModelByIdMutation, { data, loading, error }] = useRemoveSalesforceContactFieldModelByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveSalesforceContactFieldModelByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveSalesforceContactFieldModelByIdMutation,
    RemoveSalesforceContactFieldModelByIdMutationVariables
  >
) {
  return Apollo.useMutation<
    RemoveSalesforceContactFieldModelByIdMutation,
    RemoveSalesforceContactFieldModelByIdMutationVariables
  >(RemoveSalesforceContactFieldModelByIdDocument, baseOptions);
}
export type RemoveSalesforceContactFieldModelByIdMutationHookResult = ReturnType<
  typeof useRemoveSalesforceContactFieldModelByIdMutation
>;
export type RemoveSalesforceContactFieldModelByIdMutationResult = Apollo.MutationResult<RemoveSalesforceContactFieldModelByIdMutation>;
export type RemoveSalesforceContactFieldModelByIdMutationOptions = Apollo.BaseMutationOptions<
  RemoveSalesforceContactFieldModelByIdMutation,
  RemoveSalesforceContactFieldModelByIdMutationVariables
>;
export const RemoveSalesforceContactFieldModelDocument = gql`
  mutation removeSalesforceContactFieldModel($where: salesforceContactField_bool_exp!) {
    delete_salesforceContactField(where: $where) {
      affected_rows
    }
  }
`;
export type RemoveSalesforceContactFieldModelMutationFn = Apollo.MutationFunction<
  RemoveSalesforceContactFieldModelMutation,
  RemoveSalesforceContactFieldModelMutationVariables
>;

/**
 * __useRemoveSalesforceContactFieldModelMutation__
 *
 * To run a mutation, you first call `useRemoveSalesforceContactFieldModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSalesforceContactFieldModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSalesforceContactFieldModelMutation, { data, loading, error }] = useRemoveSalesforceContactFieldModelMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useRemoveSalesforceContactFieldModelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveSalesforceContactFieldModelMutation,
    RemoveSalesforceContactFieldModelMutationVariables
  >
) {
  return Apollo.useMutation<
    RemoveSalesforceContactFieldModelMutation,
    RemoveSalesforceContactFieldModelMutationVariables
  >(RemoveSalesforceContactFieldModelDocument, baseOptions);
}
export type RemoveSalesforceContactFieldModelMutationHookResult = ReturnType<
  typeof useRemoveSalesforceContactFieldModelMutation
>;
export type RemoveSalesforceContactFieldModelMutationResult = Apollo.MutationResult<RemoveSalesforceContactFieldModelMutation>;
export type RemoveSalesforceContactFieldModelMutationOptions = Apollo.BaseMutationOptions<
  RemoveSalesforceContactFieldModelMutation,
  RemoveSalesforceContactFieldModelMutationVariables
>;
export const QueryHubspotIntegrationByIdDocument = gql`
  query queryHubspotIntegrationById($hubspotIntegrationId: uuid!) {
    hubspotIntegration_by_pk(id: $hubspotIntegrationId) {
      ...HubspotIntegration
    }
  }
  ${HubspotIntegrationFragmentDoc}
`;

/**
 * __useQueryHubspotIntegrationByIdQuery__
 *
 * To run a query within a React component, call `useQueryHubspotIntegrationByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryHubspotIntegrationByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryHubspotIntegrationByIdQuery({
 *   variables: {
 *      hubspotIntegrationId: // value for 'hubspotIntegrationId'
 *   },
 * });
 */
export function useQueryHubspotIntegrationByIdQuery(
  baseOptions: Apollo.QueryHookOptions<QueryHubspotIntegrationByIdQuery, QueryHubspotIntegrationByIdQueryVariables>
) {
  return Apollo.useQuery<QueryHubspotIntegrationByIdQuery, QueryHubspotIntegrationByIdQueryVariables>(
    QueryHubspotIntegrationByIdDocument,
    baseOptions
  );
}
export function useQueryHubspotIntegrationByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryHubspotIntegrationByIdQuery, QueryHubspotIntegrationByIdQueryVariables>
) {
  return Apollo.useLazyQuery<QueryHubspotIntegrationByIdQuery, QueryHubspotIntegrationByIdQueryVariables>(
    QueryHubspotIntegrationByIdDocument,
    baseOptions
  );
}
export type QueryHubspotIntegrationByIdQueryHookResult = ReturnType<typeof useQueryHubspotIntegrationByIdQuery>;
export type QueryHubspotIntegrationByIdLazyQueryHookResult = ReturnType<typeof useQueryHubspotIntegrationByIdLazyQuery>;
export type QueryHubspotIntegrationByIdQueryResult = Apollo.QueryResult<
  QueryHubspotIntegrationByIdQuery,
  QueryHubspotIntegrationByIdQueryVariables
>;
export const QueryHubspotIntegrationObjectsDocument = gql`
  query queryHubspotIntegrationObjects(
    $distinct_on: [hubspotIntegration_select_column!]
    $where: hubspotIntegration_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [hubspotIntegration_order_by!]
  ) {
    hubspotIntegration(distinct_on: $distinct_on, where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      ...HubspotIntegration
    }
  }
  ${HubspotIntegrationFragmentDoc}
`;

/**
 * __useQueryHubspotIntegrationObjectsQuery__
 *
 * To run a query within a React component, call `useQueryHubspotIntegrationObjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryHubspotIntegrationObjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryHubspotIntegrationObjectsQuery({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useQueryHubspotIntegrationObjectsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    QueryHubspotIntegrationObjectsQuery,
    QueryHubspotIntegrationObjectsQueryVariables
  >
) {
  return Apollo.useQuery<QueryHubspotIntegrationObjectsQuery, QueryHubspotIntegrationObjectsQueryVariables>(
    QueryHubspotIntegrationObjectsDocument,
    baseOptions
  );
}
export function useQueryHubspotIntegrationObjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryHubspotIntegrationObjectsQuery,
    QueryHubspotIntegrationObjectsQueryVariables
  >
) {
  return Apollo.useLazyQuery<QueryHubspotIntegrationObjectsQuery, QueryHubspotIntegrationObjectsQueryVariables>(
    QueryHubspotIntegrationObjectsDocument,
    baseOptions
  );
}
export type QueryHubspotIntegrationObjectsQueryHookResult = ReturnType<typeof useQueryHubspotIntegrationObjectsQuery>;
export type QueryHubspotIntegrationObjectsLazyQueryHookResult = ReturnType<
  typeof useQueryHubspotIntegrationObjectsLazyQuery
>;
export type QueryHubspotIntegrationObjectsQueryResult = Apollo.QueryResult<
  QueryHubspotIntegrationObjectsQuery,
  QueryHubspotIntegrationObjectsQueryVariables
>;
export const SubscribeToHubspotIntegrationByIdDocument = gql`
  subscription subscribeToHubspotIntegrationById($hubspotIntegrationId: uuid!) {
    hubspotIntegration_by_pk(id: $hubspotIntegrationId) {
      ...HubspotIntegration
    }
  }
  ${HubspotIntegrationFragmentDoc}
`;

/**
 * __useSubscribeToHubspotIntegrationByIdSubscription__
 *
 * To run a query within a React component, call `useSubscribeToHubspotIntegrationByIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToHubspotIntegrationByIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToHubspotIntegrationByIdSubscription({
 *   variables: {
 *      hubspotIntegrationId: // value for 'hubspotIntegrationId'
 *   },
 * });
 */
export function useSubscribeToHubspotIntegrationByIdSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    SubscribeToHubspotIntegrationByIdSubscription,
    SubscribeToHubspotIntegrationByIdSubscriptionVariables
  >
) {
  return Apollo.useSubscription<
    SubscribeToHubspotIntegrationByIdSubscription,
    SubscribeToHubspotIntegrationByIdSubscriptionVariables
  >(SubscribeToHubspotIntegrationByIdDocument, baseOptions);
}
export type SubscribeToHubspotIntegrationByIdSubscriptionHookResult = ReturnType<
  typeof useSubscribeToHubspotIntegrationByIdSubscription
>;
export type SubscribeToHubspotIntegrationByIdSubscriptionResult = Apollo.SubscriptionResult<SubscribeToHubspotIntegrationByIdSubscription>;
export const SubscribeToHubspotIntegrationObjectsDocument = gql`
  subscription subscribeToHubspotIntegrationObjects(
    $distinct_on: [hubspotIntegration_select_column!]
    $where: hubspotIntegration_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [hubspotIntegration_order_by!]
  ) {
    hubspotIntegration(distinct_on: $distinct_on, where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      ...HubspotIntegration
    }
  }
  ${HubspotIntegrationFragmentDoc}
`;

/**
 * __useSubscribeToHubspotIntegrationObjectsSubscription__
 *
 * To run a query within a React component, call `useSubscribeToHubspotIntegrationObjectsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToHubspotIntegrationObjectsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToHubspotIntegrationObjectsSubscription({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useSubscribeToHubspotIntegrationObjectsSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    SubscribeToHubspotIntegrationObjectsSubscription,
    SubscribeToHubspotIntegrationObjectsSubscriptionVariables
  >
) {
  return Apollo.useSubscription<
    SubscribeToHubspotIntegrationObjectsSubscription,
    SubscribeToHubspotIntegrationObjectsSubscriptionVariables
  >(SubscribeToHubspotIntegrationObjectsDocument, baseOptions);
}
export type SubscribeToHubspotIntegrationObjectsSubscriptionHookResult = ReturnType<
  typeof useSubscribeToHubspotIntegrationObjectsSubscription
>;
export type SubscribeToHubspotIntegrationObjectsSubscriptionResult = Apollo.SubscriptionResult<SubscribeToHubspotIntegrationObjectsSubscription>;
export const InsertHubspotIntegrationDocument = gql`
  mutation insertHubspotIntegration($objects: [hubspotIntegration_insert_input!]!) {
    insert_hubspotIntegration(objects: $objects) {
      affected_rows
      returning {
        ...HubspotIntegration
      }
    }
  }
  ${HubspotIntegrationFragmentDoc}
`;
export type InsertHubspotIntegrationMutationFn = Apollo.MutationFunction<
  InsertHubspotIntegrationMutation,
  InsertHubspotIntegrationMutationVariables
>;

/**
 * __useInsertHubspotIntegrationMutation__
 *
 * To run a mutation, you first call `useInsertHubspotIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertHubspotIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertHubspotIntegrationMutation, { data, loading, error }] = useInsertHubspotIntegrationMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertHubspotIntegrationMutation(
  baseOptions?: Apollo.MutationHookOptions<InsertHubspotIntegrationMutation, InsertHubspotIntegrationMutationVariables>
) {
  return Apollo.useMutation<InsertHubspotIntegrationMutation, InsertHubspotIntegrationMutationVariables>(
    InsertHubspotIntegrationDocument,
    baseOptions
  );
}
export type InsertHubspotIntegrationMutationHookResult = ReturnType<typeof useInsertHubspotIntegrationMutation>;
export type InsertHubspotIntegrationMutationResult = Apollo.MutationResult<InsertHubspotIntegrationMutation>;
export type InsertHubspotIntegrationMutationOptions = Apollo.BaseMutationOptions<
  InsertHubspotIntegrationMutation,
  InsertHubspotIntegrationMutationVariables
>;
export const InsertHubspotIntegrationWithOnConflictDocument = gql`
  mutation insertHubspotIntegrationWithOnConflict(
    $objects: [hubspotIntegration_insert_input!]!
    $onConflict: hubspotIntegration_on_conflict
  ) {
    insert_hubspotIntegration(objects: $objects, on_conflict: $onConflict) {
      affected_rows
      returning {
        ...HubspotIntegration
      }
    }
  }
  ${HubspotIntegrationFragmentDoc}
`;
export type InsertHubspotIntegrationWithOnConflictMutationFn = Apollo.MutationFunction<
  InsertHubspotIntegrationWithOnConflictMutation,
  InsertHubspotIntegrationWithOnConflictMutationVariables
>;

/**
 * __useInsertHubspotIntegrationWithOnConflictMutation__
 *
 * To run a mutation, you first call `useInsertHubspotIntegrationWithOnConflictMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertHubspotIntegrationWithOnConflictMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertHubspotIntegrationWithOnConflictMutation, { data, loading, error }] = useInsertHubspotIntegrationWithOnConflictMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *      onConflict: // value for 'onConflict'
 *   },
 * });
 */
export function useInsertHubspotIntegrationWithOnConflictMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertHubspotIntegrationWithOnConflictMutation,
    InsertHubspotIntegrationWithOnConflictMutationVariables
  >
) {
  return Apollo.useMutation<
    InsertHubspotIntegrationWithOnConflictMutation,
    InsertHubspotIntegrationWithOnConflictMutationVariables
  >(InsertHubspotIntegrationWithOnConflictDocument, baseOptions);
}
export type InsertHubspotIntegrationWithOnConflictMutationHookResult = ReturnType<
  typeof useInsertHubspotIntegrationWithOnConflictMutation
>;
export type InsertHubspotIntegrationWithOnConflictMutationResult = Apollo.MutationResult<InsertHubspotIntegrationWithOnConflictMutation>;
export type InsertHubspotIntegrationWithOnConflictMutationOptions = Apollo.BaseMutationOptions<
  InsertHubspotIntegrationWithOnConflictMutation,
  InsertHubspotIntegrationWithOnConflictMutationVariables
>;
export const UpdateHubspotIntegrationByIdDocument = gql`
  mutation updateHubspotIntegrationById($id: uuid, $set: hubspotIntegration_set_input) {
    update_hubspotIntegration(_set: $set, where: { id: { _eq: $id } }) {
      affected_rows
      returning {
        ...HubspotIntegration
      }
    }
  }
  ${HubspotIntegrationFragmentDoc}
`;
export type UpdateHubspotIntegrationByIdMutationFn = Apollo.MutationFunction<
  UpdateHubspotIntegrationByIdMutation,
  UpdateHubspotIntegrationByIdMutationVariables
>;

/**
 * __useUpdateHubspotIntegrationByIdMutation__
 *
 * To run a mutation, you first call `useUpdateHubspotIntegrationByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHubspotIntegrationByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHubspotIntegrationByIdMutation, { data, loading, error }] = useUpdateHubspotIntegrationByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateHubspotIntegrationByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateHubspotIntegrationByIdMutation,
    UpdateHubspotIntegrationByIdMutationVariables
  >
) {
  return Apollo.useMutation<UpdateHubspotIntegrationByIdMutation, UpdateHubspotIntegrationByIdMutationVariables>(
    UpdateHubspotIntegrationByIdDocument,
    baseOptions
  );
}
export type UpdateHubspotIntegrationByIdMutationHookResult = ReturnType<typeof useUpdateHubspotIntegrationByIdMutation>;
export type UpdateHubspotIntegrationByIdMutationResult = Apollo.MutationResult<UpdateHubspotIntegrationByIdMutation>;
export type UpdateHubspotIntegrationByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateHubspotIntegrationByIdMutation,
  UpdateHubspotIntegrationByIdMutationVariables
>;
export const UpdateHubspotIntegrationDocument = gql`
  mutation updateHubspotIntegration($set: hubspotIntegration_set_input, $where: hubspotIntegration_bool_exp!) {
    update_hubspotIntegration(_set: $set, where: $where) {
      affected_rows
      returning {
        ...HubspotIntegration
      }
    }
  }
  ${HubspotIntegrationFragmentDoc}
`;
export type UpdateHubspotIntegrationMutationFn = Apollo.MutationFunction<
  UpdateHubspotIntegrationMutation,
  UpdateHubspotIntegrationMutationVariables
>;

/**
 * __useUpdateHubspotIntegrationMutation__
 *
 * To run a mutation, you first call `useUpdateHubspotIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHubspotIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHubspotIntegrationMutation, { data, loading, error }] = useUpdateHubspotIntegrationMutation({
 *   variables: {
 *      set: // value for 'set'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateHubspotIntegrationMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateHubspotIntegrationMutation, UpdateHubspotIntegrationMutationVariables>
) {
  return Apollo.useMutation<UpdateHubspotIntegrationMutation, UpdateHubspotIntegrationMutationVariables>(
    UpdateHubspotIntegrationDocument,
    baseOptions
  );
}
export type UpdateHubspotIntegrationMutationHookResult = ReturnType<typeof useUpdateHubspotIntegrationMutation>;
export type UpdateHubspotIntegrationMutationResult = Apollo.MutationResult<UpdateHubspotIntegrationMutation>;
export type UpdateHubspotIntegrationMutationOptions = Apollo.BaseMutationOptions<
  UpdateHubspotIntegrationMutation,
  UpdateHubspotIntegrationMutationVariables
>;
export const RemoveHubspotIntegrationModelByIdDocument = gql`
  mutation removeHubspotIntegrationModelById($id: uuid) {
    delete_hubspotIntegration(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
export type RemoveHubspotIntegrationModelByIdMutationFn = Apollo.MutationFunction<
  RemoveHubspotIntegrationModelByIdMutation,
  RemoveHubspotIntegrationModelByIdMutationVariables
>;

/**
 * __useRemoveHubspotIntegrationModelByIdMutation__
 *
 * To run a mutation, you first call `useRemoveHubspotIntegrationModelByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveHubspotIntegrationModelByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeHubspotIntegrationModelByIdMutation, { data, loading, error }] = useRemoveHubspotIntegrationModelByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveHubspotIntegrationModelByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveHubspotIntegrationModelByIdMutation,
    RemoveHubspotIntegrationModelByIdMutationVariables
  >
) {
  return Apollo.useMutation<
    RemoveHubspotIntegrationModelByIdMutation,
    RemoveHubspotIntegrationModelByIdMutationVariables
  >(RemoveHubspotIntegrationModelByIdDocument, baseOptions);
}
export type RemoveHubspotIntegrationModelByIdMutationHookResult = ReturnType<
  typeof useRemoveHubspotIntegrationModelByIdMutation
>;
export type RemoveHubspotIntegrationModelByIdMutationResult = Apollo.MutationResult<RemoveHubspotIntegrationModelByIdMutation>;
export type RemoveHubspotIntegrationModelByIdMutationOptions = Apollo.BaseMutationOptions<
  RemoveHubspotIntegrationModelByIdMutation,
  RemoveHubspotIntegrationModelByIdMutationVariables
>;
export const RemoveHubspotIntegrationModelDocument = gql`
  mutation removeHubspotIntegrationModel($where: hubspotIntegration_bool_exp!) {
    delete_hubspotIntegration(where: $where) {
      affected_rows
    }
  }
`;
export type RemoveHubspotIntegrationModelMutationFn = Apollo.MutationFunction<
  RemoveHubspotIntegrationModelMutation,
  RemoveHubspotIntegrationModelMutationVariables
>;

/**
 * __useRemoveHubspotIntegrationModelMutation__
 *
 * To run a mutation, you first call `useRemoveHubspotIntegrationModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveHubspotIntegrationModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeHubspotIntegrationModelMutation, { data, loading, error }] = useRemoveHubspotIntegrationModelMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useRemoveHubspotIntegrationModelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveHubspotIntegrationModelMutation,
    RemoveHubspotIntegrationModelMutationVariables
  >
) {
  return Apollo.useMutation<RemoveHubspotIntegrationModelMutation, RemoveHubspotIntegrationModelMutationVariables>(
    RemoveHubspotIntegrationModelDocument,
    baseOptions
  );
}
export type RemoveHubspotIntegrationModelMutationHookResult = ReturnType<
  typeof useRemoveHubspotIntegrationModelMutation
>;
export type RemoveHubspotIntegrationModelMutationResult = Apollo.MutationResult<RemoveHubspotIntegrationModelMutation>;
export type RemoveHubspotIntegrationModelMutationOptions = Apollo.BaseMutationOptions<
  RemoveHubspotIntegrationModelMutation,
  RemoveHubspotIntegrationModelMutationVariables
>;
export const QuerySalesforceIntegrationByIdDocument = gql`
  query querySalesforceIntegrationById($salesforceIntegrationId: uuid!) {
    salesforceIntegration_by_pk(id: $salesforceIntegrationId) {
      ...SalesforceIntegration
    }
  }
  ${SalesforceIntegrationFragmentDoc}
`;

/**
 * __useQuerySalesforceIntegrationByIdQuery__
 *
 * To run a query within a React component, call `useQuerySalesforceIntegrationByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuerySalesforceIntegrationByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuerySalesforceIntegrationByIdQuery({
 *   variables: {
 *      salesforceIntegrationId: // value for 'salesforceIntegrationId'
 *   },
 * });
 */
export function useQuerySalesforceIntegrationByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    QuerySalesforceIntegrationByIdQuery,
    QuerySalesforceIntegrationByIdQueryVariables
  >
) {
  return Apollo.useQuery<QuerySalesforceIntegrationByIdQuery, QuerySalesforceIntegrationByIdQueryVariables>(
    QuerySalesforceIntegrationByIdDocument,
    baseOptions
  );
}
export function useQuerySalesforceIntegrationByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QuerySalesforceIntegrationByIdQuery,
    QuerySalesforceIntegrationByIdQueryVariables
  >
) {
  return Apollo.useLazyQuery<QuerySalesforceIntegrationByIdQuery, QuerySalesforceIntegrationByIdQueryVariables>(
    QuerySalesforceIntegrationByIdDocument,
    baseOptions
  );
}
export type QuerySalesforceIntegrationByIdQueryHookResult = ReturnType<typeof useQuerySalesforceIntegrationByIdQuery>;
export type QuerySalesforceIntegrationByIdLazyQueryHookResult = ReturnType<
  typeof useQuerySalesforceIntegrationByIdLazyQuery
>;
export type QuerySalesforceIntegrationByIdQueryResult = Apollo.QueryResult<
  QuerySalesforceIntegrationByIdQuery,
  QuerySalesforceIntegrationByIdQueryVariables
>;
export const QuerySalesforceIntegrationObjectsDocument = gql`
  query querySalesforceIntegrationObjects(
    $distinct_on: [salesforceIntegration_select_column!]
    $where: salesforceIntegration_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [salesforceIntegration_order_by!]
  ) {
    salesforceIntegration(
      distinct_on: $distinct_on
      where: $where
      limit: $limit
      offset: $offset
      order_by: $order_by
    ) {
      ...SalesforceIntegration
    }
  }
  ${SalesforceIntegrationFragmentDoc}
`;

/**
 * __useQuerySalesforceIntegrationObjectsQuery__
 *
 * To run a query within a React component, call `useQuerySalesforceIntegrationObjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuerySalesforceIntegrationObjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuerySalesforceIntegrationObjectsQuery({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useQuerySalesforceIntegrationObjectsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    QuerySalesforceIntegrationObjectsQuery,
    QuerySalesforceIntegrationObjectsQueryVariables
  >
) {
  return Apollo.useQuery<QuerySalesforceIntegrationObjectsQuery, QuerySalesforceIntegrationObjectsQueryVariables>(
    QuerySalesforceIntegrationObjectsDocument,
    baseOptions
  );
}
export function useQuerySalesforceIntegrationObjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QuerySalesforceIntegrationObjectsQuery,
    QuerySalesforceIntegrationObjectsQueryVariables
  >
) {
  return Apollo.useLazyQuery<QuerySalesforceIntegrationObjectsQuery, QuerySalesforceIntegrationObjectsQueryVariables>(
    QuerySalesforceIntegrationObjectsDocument,
    baseOptions
  );
}
export type QuerySalesforceIntegrationObjectsQueryHookResult = ReturnType<
  typeof useQuerySalesforceIntegrationObjectsQuery
>;
export type QuerySalesforceIntegrationObjectsLazyQueryHookResult = ReturnType<
  typeof useQuerySalesforceIntegrationObjectsLazyQuery
>;
export type QuerySalesforceIntegrationObjectsQueryResult = Apollo.QueryResult<
  QuerySalesforceIntegrationObjectsQuery,
  QuerySalesforceIntegrationObjectsQueryVariables
>;
export const SubscribeToSalesforceIntegrationByIdDocument = gql`
  subscription subscribeToSalesforceIntegrationById($salesforceIntegrationId: uuid!) {
    salesforceIntegration_by_pk(id: $salesforceIntegrationId) {
      ...SalesforceIntegration
    }
  }
  ${SalesforceIntegrationFragmentDoc}
`;

/**
 * __useSubscribeToSalesforceIntegrationByIdSubscription__
 *
 * To run a query within a React component, call `useSubscribeToSalesforceIntegrationByIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToSalesforceIntegrationByIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToSalesforceIntegrationByIdSubscription({
 *   variables: {
 *      salesforceIntegrationId: // value for 'salesforceIntegrationId'
 *   },
 * });
 */
export function useSubscribeToSalesforceIntegrationByIdSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    SubscribeToSalesforceIntegrationByIdSubscription,
    SubscribeToSalesforceIntegrationByIdSubscriptionVariables
  >
) {
  return Apollo.useSubscription<
    SubscribeToSalesforceIntegrationByIdSubscription,
    SubscribeToSalesforceIntegrationByIdSubscriptionVariables
  >(SubscribeToSalesforceIntegrationByIdDocument, baseOptions);
}
export type SubscribeToSalesforceIntegrationByIdSubscriptionHookResult = ReturnType<
  typeof useSubscribeToSalesforceIntegrationByIdSubscription
>;
export type SubscribeToSalesforceIntegrationByIdSubscriptionResult = Apollo.SubscriptionResult<SubscribeToSalesforceIntegrationByIdSubscription>;
export const SubscribeToSalesforceIntegrationObjectsDocument = gql`
  subscription subscribeToSalesforceIntegrationObjects(
    $distinct_on: [salesforceIntegration_select_column!]
    $where: salesforceIntegration_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [salesforceIntegration_order_by!]
  ) {
    salesforceIntegration(
      distinct_on: $distinct_on
      where: $where
      limit: $limit
      offset: $offset
      order_by: $order_by
    ) {
      ...SalesforceIntegration
    }
  }
  ${SalesforceIntegrationFragmentDoc}
`;

/**
 * __useSubscribeToSalesforceIntegrationObjectsSubscription__
 *
 * To run a query within a React component, call `useSubscribeToSalesforceIntegrationObjectsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToSalesforceIntegrationObjectsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToSalesforceIntegrationObjectsSubscription({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useSubscribeToSalesforceIntegrationObjectsSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    SubscribeToSalesforceIntegrationObjectsSubscription,
    SubscribeToSalesforceIntegrationObjectsSubscriptionVariables
  >
) {
  return Apollo.useSubscription<
    SubscribeToSalesforceIntegrationObjectsSubscription,
    SubscribeToSalesforceIntegrationObjectsSubscriptionVariables
  >(SubscribeToSalesforceIntegrationObjectsDocument, baseOptions);
}
export type SubscribeToSalesforceIntegrationObjectsSubscriptionHookResult = ReturnType<
  typeof useSubscribeToSalesforceIntegrationObjectsSubscription
>;
export type SubscribeToSalesforceIntegrationObjectsSubscriptionResult = Apollo.SubscriptionResult<SubscribeToSalesforceIntegrationObjectsSubscription>;
export const InsertSalesforceIntegrationDocument = gql`
  mutation insertSalesforceIntegration($objects: [salesforceIntegration_insert_input!]!) {
    insert_salesforceIntegration(objects: $objects) {
      affected_rows
      returning {
        ...SalesforceIntegration
      }
    }
  }
  ${SalesforceIntegrationFragmentDoc}
`;
export type InsertSalesforceIntegrationMutationFn = Apollo.MutationFunction<
  InsertSalesforceIntegrationMutation,
  InsertSalesforceIntegrationMutationVariables
>;

/**
 * __useInsertSalesforceIntegrationMutation__
 *
 * To run a mutation, you first call `useInsertSalesforceIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertSalesforceIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertSalesforceIntegrationMutation, { data, loading, error }] = useInsertSalesforceIntegrationMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertSalesforceIntegrationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertSalesforceIntegrationMutation,
    InsertSalesforceIntegrationMutationVariables
  >
) {
  return Apollo.useMutation<InsertSalesforceIntegrationMutation, InsertSalesforceIntegrationMutationVariables>(
    InsertSalesforceIntegrationDocument,
    baseOptions
  );
}
export type InsertSalesforceIntegrationMutationHookResult = ReturnType<typeof useInsertSalesforceIntegrationMutation>;
export type InsertSalesforceIntegrationMutationResult = Apollo.MutationResult<InsertSalesforceIntegrationMutation>;
export type InsertSalesforceIntegrationMutationOptions = Apollo.BaseMutationOptions<
  InsertSalesforceIntegrationMutation,
  InsertSalesforceIntegrationMutationVariables
>;
export const InsertSalesforceIntegrationWithOnConflictDocument = gql`
  mutation insertSalesforceIntegrationWithOnConflict(
    $objects: [salesforceIntegration_insert_input!]!
    $onConflict: salesforceIntegration_on_conflict
  ) {
    insert_salesforceIntegration(objects: $objects, on_conflict: $onConflict) {
      affected_rows
      returning {
        ...SalesforceIntegration
      }
    }
  }
  ${SalesforceIntegrationFragmentDoc}
`;
export type InsertSalesforceIntegrationWithOnConflictMutationFn = Apollo.MutationFunction<
  InsertSalesforceIntegrationWithOnConflictMutation,
  InsertSalesforceIntegrationWithOnConflictMutationVariables
>;

/**
 * __useInsertSalesforceIntegrationWithOnConflictMutation__
 *
 * To run a mutation, you first call `useInsertSalesforceIntegrationWithOnConflictMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertSalesforceIntegrationWithOnConflictMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertSalesforceIntegrationWithOnConflictMutation, { data, loading, error }] = useInsertSalesforceIntegrationWithOnConflictMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *      onConflict: // value for 'onConflict'
 *   },
 * });
 */
export function useInsertSalesforceIntegrationWithOnConflictMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertSalesforceIntegrationWithOnConflictMutation,
    InsertSalesforceIntegrationWithOnConflictMutationVariables
  >
) {
  return Apollo.useMutation<
    InsertSalesforceIntegrationWithOnConflictMutation,
    InsertSalesforceIntegrationWithOnConflictMutationVariables
  >(InsertSalesforceIntegrationWithOnConflictDocument, baseOptions);
}
export type InsertSalesforceIntegrationWithOnConflictMutationHookResult = ReturnType<
  typeof useInsertSalesforceIntegrationWithOnConflictMutation
>;
export type InsertSalesforceIntegrationWithOnConflictMutationResult = Apollo.MutationResult<InsertSalesforceIntegrationWithOnConflictMutation>;
export type InsertSalesforceIntegrationWithOnConflictMutationOptions = Apollo.BaseMutationOptions<
  InsertSalesforceIntegrationWithOnConflictMutation,
  InsertSalesforceIntegrationWithOnConflictMutationVariables
>;
export const UpdateSalesforceIntegrationByIdDocument = gql`
  mutation updateSalesforceIntegrationById($id: uuid, $set: salesforceIntegration_set_input) {
    update_salesforceIntegration(_set: $set, where: { id: { _eq: $id } }) {
      affected_rows
      returning {
        ...SalesforceIntegration
      }
    }
  }
  ${SalesforceIntegrationFragmentDoc}
`;
export type UpdateSalesforceIntegrationByIdMutationFn = Apollo.MutationFunction<
  UpdateSalesforceIntegrationByIdMutation,
  UpdateSalesforceIntegrationByIdMutationVariables
>;

/**
 * __useUpdateSalesforceIntegrationByIdMutation__
 *
 * To run a mutation, you first call `useUpdateSalesforceIntegrationByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSalesforceIntegrationByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSalesforceIntegrationByIdMutation, { data, loading, error }] = useUpdateSalesforceIntegrationByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateSalesforceIntegrationByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSalesforceIntegrationByIdMutation,
    UpdateSalesforceIntegrationByIdMutationVariables
  >
) {
  return Apollo.useMutation<UpdateSalesforceIntegrationByIdMutation, UpdateSalesforceIntegrationByIdMutationVariables>(
    UpdateSalesforceIntegrationByIdDocument,
    baseOptions
  );
}
export type UpdateSalesforceIntegrationByIdMutationHookResult = ReturnType<
  typeof useUpdateSalesforceIntegrationByIdMutation
>;
export type UpdateSalesforceIntegrationByIdMutationResult = Apollo.MutationResult<UpdateSalesforceIntegrationByIdMutation>;
export type UpdateSalesforceIntegrationByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateSalesforceIntegrationByIdMutation,
  UpdateSalesforceIntegrationByIdMutationVariables
>;
export const UpdateSalesforceIntegrationDocument = gql`
  mutation updateSalesforceIntegration($set: salesforceIntegration_set_input, $where: salesforceIntegration_bool_exp!) {
    update_salesforceIntegration(_set: $set, where: $where) {
      affected_rows
      returning {
        ...SalesforceIntegration
      }
    }
  }
  ${SalesforceIntegrationFragmentDoc}
`;
export type UpdateSalesforceIntegrationMutationFn = Apollo.MutationFunction<
  UpdateSalesforceIntegrationMutation,
  UpdateSalesforceIntegrationMutationVariables
>;

/**
 * __useUpdateSalesforceIntegrationMutation__
 *
 * To run a mutation, you first call `useUpdateSalesforceIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSalesforceIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSalesforceIntegrationMutation, { data, loading, error }] = useUpdateSalesforceIntegrationMutation({
 *   variables: {
 *      set: // value for 'set'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateSalesforceIntegrationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSalesforceIntegrationMutation,
    UpdateSalesforceIntegrationMutationVariables
  >
) {
  return Apollo.useMutation<UpdateSalesforceIntegrationMutation, UpdateSalesforceIntegrationMutationVariables>(
    UpdateSalesforceIntegrationDocument,
    baseOptions
  );
}
export type UpdateSalesforceIntegrationMutationHookResult = ReturnType<typeof useUpdateSalesforceIntegrationMutation>;
export type UpdateSalesforceIntegrationMutationResult = Apollo.MutationResult<UpdateSalesforceIntegrationMutation>;
export type UpdateSalesforceIntegrationMutationOptions = Apollo.BaseMutationOptions<
  UpdateSalesforceIntegrationMutation,
  UpdateSalesforceIntegrationMutationVariables
>;
export const RemoveSalesforceIntegrationModelByIdDocument = gql`
  mutation removeSalesforceIntegrationModelById($id: uuid) {
    delete_salesforceIntegration(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
export type RemoveSalesforceIntegrationModelByIdMutationFn = Apollo.MutationFunction<
  RemoveSalesforceIntegrationModelByIdMutation,
  RemoveSalesforceIntegrationModelByIdMutationVariables
>;

/**
 * __useRemoveSalesforceIntegrationModelByIdMutation__
 *
 * To run a mutation, you first call `useRemoveSalesforceIntegrationModelByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSalesforceIntegrationModelByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSalesforceIntegrationModelByIdMutation, { data, loading, error }] = useRemoveSalesforceIntegrationModelByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveSalesforceIntegrationModelByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveSalesforceIntegrationModelByIdMutation,
    RemoveSalesforceIntegrationModelByIdMutationVariables
  >
) {
  return Apollo.useMutation<
    RemoveSalesforceIntegrationModelByIdMutation,
    RemoveSalesforceIntegrationModelByIdMutationVariables
  >(RemoveSalesforceIntegrationModelByIdDocument, baseOptions);
}
export type RemoveSalesforceIntegrationModelByIdMutationHookResult = ReturnType<
  typeof useRemoveSalesforceIntegrationModelByIdMutation
>;
export type RemoveSalesforceIntegrationModelByIdMutationResult = Apollo.MutationResult<RemoveSalesforceIntegrationModelByIdMutation>;
export type RemoveSalesforceIntegrationModelByIdMutationOptions = Apollo.BaseMutationOptions<
  RemoveSalesforceIntegrationModelByIdMutation,
  RemoveSalesforceIntegrationModelByIdMutationVariables
>;
export const RemoveSalesforceIntegrationModelDocument = gql`
  mutation removeSalesforceIntegrationModel($where: salesforceIntegration_bool_exp!) {
    delete_salesforceIntegration(where: $where) {
      affected_rows
    }
  }
`;
export type RemoveSalesforceIntegrationModelMutationFn = Apollo.MutationFunction<
  RemoveSalesforceIntegrationModelMutation,
  RemoveSalesforceIntegrationModelMutationVariables
>;

/**
 * __useRemoveSalesforceIntegrationModelMutation__
 *
 * To run a mutation, you first call `useRemoveSalesforceIntegrationModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSalesforceIntegrationModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSalesforceIntegrationModelMutation, { data, loading, error }] = useRemoveSalesforceIntegrationModelMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useRemoveSalesforceIntegrationModelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveSalesforceIntegrationModelMutation,
    RemoveSalesforceIntegrationModelMutationVariables
  >
) {
  return Apollo.useMutation<
    RemoveSalesforceIntegrationModelMutation,
    RemoveSalesforceIntegrationModelMutationVariables
  >(RemoveSalesforceIntegrationModelDocument, baseOptions);
}
export type RemoveSalesforceIntegrationModelMutationHookResult = ReturnType<
  typeof useRemoveSalesforceIntegrationModelMutation
>;
export type RemoveSalesforceIntegrationModelMutationResult = Apollo.MutationResult<RemoveSalesforceIntegrationModelMutation>;
export type RemoveSalesforceIntegrationModelMutationOptions = Apollo.BaseMutationOptions<
  RemoveSalesforceIntegrationModelMutation,
  RemoveSalesforceIntegrationModelMutationVariables
>;
export const QueryQaTaskActionByIdDocument = gql`
  query queryQaTaskActionById($qaTaskActionId: uuid!) {
    qaTaskAction_by_pk(id: $qaTaskActionId) {
      ...QaTaskAction
    }
  }
  ${QaTaskActionFragmentDoc}
`;

/**
 * __useQueryQaTaskActionByIdQuery__
 *
 * To run a query within a React component, call `useQueryQaTaskActionByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryQaTaskActionByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryQaTaskActionByIdQuery({
 *   variables: {
 *      qaTaskActionId: // value for 'qaTaskActionId'
 *   },
 * });
 */
export function useQueryQaTaskActionByIdQuery(
  baseOptions: Apollo.QueryHookOptions<QueryQaTaskActionByIdQuery, QueryQaTaskActionByIdQueryVariables>
) {
  return Apollo.useQuery<QueryQaTaskActionByIdQuery, QueryQaTaskActionByIdQueryVariables>(
    QueryQaTaskActionByIdDocument,
    baseOptions
  );
}
export function useQueryQaTaskActionByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryQaTaskActionByIdQuery, QueryQaTaskActionByIdQueryVariables>
) {
  return Apollo.useLazyQuery<QueryQaTaskActionByIdQuery, QueryQaTaskActionByIdQueryVariables>(
    QueryQaTaskActionByIdDocument,
    baseOptions
  );
}
export type QueryQaTaskActionByIdQueryHookResult = ReturnType<typeof useQueryQaTaskActionByIdQuery>;
export type QueryQaTaskActionByIdLazyQueryHookResult = ReturnType<typeof useQueryQaTaskActionByIdLazyQuery>;
export type QueryQaTaskActionByIdQueryResult = Apollo.QueryResult<
  QueryQaTaskActionByIdQuery,
  QueryQaTaskActionByIdQueryVariables
>;
export const QueryQaTaskActionObjectsDocument = gql`
  query queryQaTaskActionObjects(
    $distinct_on: [qaTaskAction_select_column!]
    $where: qaTaskAction_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [qaTaskAction_order_by!]
  ) {
    qaTaskAction(distinct_on: $distinct_on, where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      ...QaTaskAction
    }
  }
  ${QaTaskActionFragmentDoc}
`;

/**
 * __useQueryQaTaskActionObjectsQuery__
 *
 * To run a query within a React component, call `useQueryQaTaskActionObjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryQaTaskActionObjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryQaTaskActionObjectsQuery({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useQueryQaTaskActionObjectsQuery(
  baseOptions?: Apollo.QueryHookOptions<QueryQaTaskActionObjectsQuery, QueryQaTaskActionObjectsQueryVariables>
) {
  return Apollo.useQuery<QueryQaTaskActionObjectsQuery, QueryQaTaskActionObjectsQueryVariables>(
    QueryQaTaskActionObjectsDocument,
    baseOptions
  );
}
export function useQueryQaTaskActionObjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryQaTaskActionObjectsQuery, QueryQaTaskActionObjectsQueryVariables>
) {
  return Apollo.useLazyQuery<QueryQaTaskActionObjectsQuery, QueryQaTaskActionObjectsQueryVariables>(
    QueryQaTaskActionObjectsDocument,
    baseOptions
  );
}
export type QueryQaTaskActionObjectsQueryHookResult = ReturnType<typeof useQueryQaTaskActionObjectsQuery>;
export type QueryQaTaskActionObjectsLazyQueryHookResult = ReturnType<typeof useQueryQaTaskActionObjectsLazyQuery>;
export type QueryQaTaskActionObjectsQueryResult = Apollo.QueryResult<
  QueryQaTaskActionObjectsQuery,
  QueryQaTaskActionObjectsQueryVariables
>;
export const SubscribeToQaTaskActionByIdDocument = gql`
  subscription subscribeToQaTaskActionById($qaTaskActionId: uuid!) {
    qaTaskAction_by_pk(id: $qaTaskActionId) {
      ...QaTaskAction
    }
  }
  ${QaTaskActionFragmentDoc}
`;

/**
 * __useSubscribeToQaTaskActionByIdSubscription__
 *
 * To run a query within a React component, call `useSubscribeToQaTaskActionByIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToQaTaskActionByIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToQaTaskActionByIdSubscription({
 *   variables: {
 *      qaTaskActionId: // value for 'qaTaskActionId'
 *   },
 * });
 */
export function useSubscribeToQaTaskActionByIdSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    SubscribeToQaTaskActionByIdSubscription,
    SubscribeToQaTaskActionByIdSubscriptionVariables
  >
) {
  return Apollo.useSubscription<
    SubscribeToQaTaskActionByIdSubscription,
    SubscribeToQaTaskActionByIdSubscriptionVariables
  >(SubscribeToQaTaskActionByIdDocument, baseOptions);
}
export type SubscribeToQaTaskActionByIdSubscriptionHookResult = ReturnType<
  typeof useSubscribeToQaTaskActionByIdSubscription
>;
export type SubscribeToQaTaskActionByIdSubscriptionResult = Apollo.SubscriptionResult<SubscribeToQaTaskActionByIdSubscription>;
export const SubscribeToQaTaskActionObjectsDocument = gql`
  subscription subscribeToQaTaskActionObjects(
    $distinct_on: [qaTaskAction_select_column!]
    $where: qaTaskAction_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [qaTaskAction_order_by!]
  ) {
    qaTaskAction(distinct_on: $distinct_on, where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      ...QaTaskAction
    }
  }
  ${QaTaskActionFragmentDoc}
`;

/**
 * __useSubscribeToQaTaskActionObjectsSubscription__
 *
 * To run a query within a React component, call `useSubscribeToQaTaskActionObjectsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToQaTaskActionObjectsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToQaTaskActionObjectsSubscription({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useSubscribeToQaTaskActionObjectsSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    SubscribeToQaTaskActionObjectsSubscription,
    SubscribeToQaTaskActionObjectsSubscriptionVariables
  >
) {
  return Apollo.useSubscription<
    SubscribeToQaTaskActionObjectsSubscription,
    SubscribeToQaTaskActionObjectsSubscriptionVariables
  >(SubscribeToQaTaskActionObjectsDocument, baseOptions);
}
export type SubscribeToQaTaskActionObjectsSubscriptionHookResult = ReturnType<
  typeof useSubscribeToQaTaskActionObjectsSubscription
>;
export type SubscribeToQaTaskActionObjectsSubscriptionResult = Apollo.SubscriptionResult<SubscribeToQaTaskActionObjectsSubscription>;
export const InsertQaTaskActionDocument = gql`
  mutation insertQaTaskAction($objects: [qaTaskAction_insert_input!]!) {
    insert_qaTaskAction(objects: $objects) {
      affected_rows
      returning {
        ...QaTaskAction
      }
    }
  }
  ${QaTaskActionFragmentDoc}
`;
export type InsertQaTaskActionMutationFn = Apollo.MutationFunction<
  InsertQaTaskActionMutation,
  InsertQaTaskActionMutationVariables
>;

/**
 * __useInsertQaTaskActionMutation__
 *
 * To run a mutation, you first call `useInsertQaTaskActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertQaTaskActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertQaTaskActionMutation, { data, loading, error }] = useInsertQaTaskActionMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertQaTaskActionMutation(
  baseOptions?: Apollo.MutationHookOptions<InsertQaTaskActionMutation, InsertQaTaskActionMutationVariables>
) {
  return Apollo.useMutation<InsertQaTaskActionMutation, InsertQaTaskActionMutationVariables>(
    InsertQaTaskActionDocument,
    baseOptions
  );
}
export type InsertQaTaskActionMutationHookResult = ReturnType<typeof useInsertQaTaskActionMutation>;
export type InsertQaTaskActionMutationResult = Apollo.MutationResult<InsertQaTaskActionMutation>;
export type InsertQaTaskActionMutationOptions = Apollo.BaseMutationOptions<
  InsertQaTaskActionMutation,
  InsertQaTaskActionMutationVariables
>;
export const InsertQaTaskActionWithOnConflictDocument = gql`
  mutation insertQaTaskActionWithOnConflict(
    $objects: [qaTaskAction_insert_input!]!
    $onConflict: qaTaskAction_on_conflict
  ) {
    insert_qaTaskAction(objects: $objects, on_conflict: $onConflict) {
      affected_rows
      returning {
        ...QaTaskAction
      }
    }
  }
  ${QaTaskActionFragmentDoc}
`;
export type InsertQaTaskActionWithOnConflictMutationFn = Apollo.MutationFunction<
  InsertQaTaskActionWithOnConflictMutation,
  InsertQaTaskActionWithOnConflictMutationVariables
>;

/**
 * __useInsertQaTaskActionWithOnConflictMutation__
 *
 * To run a mutation, you first call `useInsertQaTaskActionWithOnConflictMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertQaTaskActionWithOnConflictMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertQaTaskActionWithOnConflictMutation, { data, loading, error }] = useInsertQaTaskActionWithOnConflictMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *      onConflict: // value for 'onConflict'
 *   },
 * });
 */
export function useInsertQaTaskActionWithOnConflictMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertQaTaskActionWithOnConflictMutation,
    InsertQaTaskActionWithOnConflictMutationVariables
  >
) {
  return Apollo.useMutation<
    InsertQaTaskActionWithOnConflictMutation,
    InsertQaTaskActionWithOnConflictMutationVariables
  >(InsertQaTaskActionWithOnConflictDocument, baseOptions);
}
export type InsertQaTaskActionWithOnConflictMutationHookResult = ReturnType<
  typeof useInsertQaTaskActionWithOnConflictMutation
>;
export type InsertQaTaskActionWithOnConflictMutationResult = Apollo.MutationResult<InsertQaTaskActionWithOnConflictMutation>;
export type InsertQaTaskActionWithOnConflictMutationOptions = Apollo.BaseMutationOptions<
  InsertQaTaskActionWithOnConflictMutation,
  InsertQaTaskActionWithOnConflictMutationVariables
>;
export const UpdateQaTaskActionByIdDocument = gql`
  mutation updateQaTaskActionById($id: uuid, $set: qaTaskAction_set_input) {
    update_qaTaskAction(_set: $set, where: { id: { _eq: $id } }) {
      affected_rows
      returning {
        ...QaTaskAction
      }
    }
  }
  ${QaTaskActionFragmentDoc}
`;
export type UpdateQaTaskActionByIdMutationFn = Apollo.MutationFunction<
  UpdateQaTaskActionByIdMutation,
  UpdateQaTaskActionByIdMutationVariables
>;

/**
 * __useUpdateQaTaskActionByIdMutation__
 *
 * To run a mutation, you first call `useUpdateQaTaskActionByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQaTaskActionByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQaTaskActionByIdMutation, { data, loading, error }] = useUpdateQaTaskActionByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateQaTaskActionByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateQaTaskActionByIdMutation, UpdateQaTaskActionByIdMutationVariables>
) {
  return Apollo.useMutation<UpdateQaTaskActionByIdMutation, UpdateQaTaskActionByIdMutationVariables>(
    UpdateQaTaskActionByIdDocument,
    baseOptions
  );
}
export type UpdateQaTaskActionByIdMutationHookResult = ReturnType<typeof useUpdateQaTaskActionByIdMutation>;
export type UpdateQaTaskActionByIdMutationResult = Apollo.MutationResult<UpdateQaTaskActionByIdMutation>;
export type UpdateQaTaskActionByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateQaTaskActionByIdMutation,
  UpdateQaTaskActionByIdMutationVariables
>;
export const UpdateQaTaskActionDocument = gql`
  mutation updateQaTaskAction($set: qaTaskAction_set_input, $where: qaTaskAction_bool_exp!) {
    update_qaTaskAction(_set: $set, where: $where) {
      affected_rows
      returning {
        ...QaTaskAction
      }
    }
  }
  ${QaTaskActionFragmentDoc}
`;
export type UpdateQaTaskActionMutationFn = Apollo.MutationFunction<
  UpdateQaTaskActionMutation,
  UpdateQaTaskActionMutationVariables
>;

/**
 * __useUpdateQaTaskActionMutation__
 *
 * To run a mutation, you first call `useUpdateQaTaskActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQaTaskActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQaTaskActionMutation, { data, loading, error }] = useUpdateQaTaskActionMutation({
 *   variables: {
 *      set: // value for 'set'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateQaTaskActionMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateQaTaskActionMutation, UpdateQaTaskActionMutationVariables>
) {
  return Apollo.useMutation<UpdateQaTaskActionMutation, UpdateQaTaskActionMutationVariables>(
    UpdateQaTaskActionDocument,
    baseOptions
  );
}
export type UpdateQaTaskActionMutationHookResult = ReturnType<typeof useUpdateQaTaskActionMutation>;
export type UpdateQaTaskActionMutationResult = Apollo.MutationResult<UpdateQaTaskActionMutation>;
export type UpdateQaTaskActionMutationOptions = Apollo.BaseMutationOptions<
  UpdateQaTaskActionMutation,
  UpdateQaTaskActionMutationVariables
>;
export const RemoveQaTaskActionModelByIdDocument = gql`
  mutation removeQaTaskActionModelById($id: uuid) {
    delete_qaTaskAction(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
export type RemoveQaTaskActionModelByIdMutationFn = Apollo.MutationFunction<
  RemoveQaTaskActionModelByIdMutation,
  RemoveQaTaskActionModelByIdMutationVariables
>;

/**
 * __useRemoveQaTaskActionModelByIdMutation__
 *
 * To run a mutation, you first call `useRemoveQaTaskActionModelByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveQaTaskActionModelByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeQaTaskActionModelByIdMutation, { data, loading, error }] = useRemoveQaTaskActionModelByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveQaTaskActionModelByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveQaTaskActionModelByIdMutation,
    RemoveQaTaskActionModelByIdMutationVariables
  >
) {
  return Apollo.useMutation<RemoveQaTaskActionModelByIdMutation, RemoveQaTaskActionModelByIdMutationVariables>(
    RemoveQaTaskActionModelByIdDocument,
    baseOptions
  );
}
export type RemoveQaTaskActionModelByIdMutationHookResult = ReturnType<typeof useRemoveQaTaskActionModelByIdMutation>;
export type RemoveQaTaskActionModelByIdMutationResult = Apollo.MutationResult<RemoveQaTaskActionModelByIdMutation>;
export type RemoveQaTaskActionModelByIdMutationOptions = Apollo.BaseMutationOptions<
  RemoveQaTaskActionModelByIdMutation,
  RemoveQaTaskActionModelByIdMutationVariables
>;
export const RemoveQaTaskActionModelDocument = gql`
  mutation removeQaTaskActionModel($where: qaTaskAction_bool_exp!) {
    delete_qaTaskAction(where: $where) {
      affected_rows
    }
  }
`;
export type RemoveQaTaskActionModelMutationFn = Apollo.MutationFunction<
  RemoveQaTaskActionModelMutation,
  RemoveQaTaskActionModelMutationVariables
>;

/**
 * __useRemoveQaTaskActionModelMutation__
 *
 * To run a mutation, you first call `useRemoveQaTaskActionModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveQaTaskActionModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeQaTaskActionModelMutation, { data, loading, error }] = useRemoveQaTaskActionModelMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useRemoveQaTaskActionModelMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveQaTaskActionModelMutation, RemoveQaTaskActionModelMutationVariables>
) {
  return Apollo.useMutation<RemoveQaTaskActionModelMutation, RemoveQaTaskActionModelMutationVariables>(
    RemoveQaTaskActionModelDocument,
    baseOptions
  );
}
export type RemoveQaTaskActionModelMutationHookResult = ReturnType<typeof useRemoveQaTaskActionModelMutation>;
export type RemoveQaTaskActionModelMutationResult = Apollo.MutationResult<RemoveQaTaskActionModelMutation>;
export type RemoveQaTaskActionModelMutationOptions = Apollo.BaseMutationOptions<
  RemoveQaTaskActionModelMutation,
  RemoveQaTaskActionModelMutationVariables
>;
export const QueryQaTaskByIdDocument = gql`
  query queryQaTaskById($qaTaskId: uuid!) {
    qaTask_by_pk(id: $qaTaskId) {
      ...QaTask
    }
  }
  ${QaTaskFragmentDoc}
`;

/**
 * __useQueryQaTaskByIdQuery__
 *
 * To run a query within a React component, call `useQueryQaTaskByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryQaTaskByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryQaTaskByIdQuery({
 *   variables: {
 *      qaTaskId: // value for 'qaTaskId'
 *   },
 * });
 */
export function useQueryQaTaskByIdQuery(
  baseOptions: Apollo.QueryHookOptions<QueryQaTaskByIdQuery, QueryQaTaskByIdQueryVariables>
) {
  return Apollo.useQuery<QueryQaTaskByIdQuery, QueryQaTaskByIdQueryVariables>(QueryQaTaskByIdDocument, baseOptions);
}
export function useQueryQaTaskByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryQaTaskByIdQuery, QueryQaTaskByIdQueryVariables>
) {
  return Apollo.useLazyQuery<QueryQaTaskByIdQuery, QueryQaTaskByIdQueryVariables>(QueryQaTaskByIdDocument, baseOptions);
}
export type QueryQaTaskByIdQueryHookResult = ReturnType<typeof useQueryQaTaskByIdQuery>;
export type QueryQaTaskByIdLazyQueryHookResult = ReturnType<typeof useQueryQaTaskByIdLazyQuery>;
export type QueryQaTaskByIdQueryResult = Apollo.QueryResult<QueryQaTaskByIdQuery, QueryQaTaskByIdQueryVariables>;
export const QueryQaTaskObjectsDocument = gql`
  query queryQaTaskObjects(
    $distinct_on: [qaTask_select_column!]
    $where: qaTask_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [qaTask_order_by!]
  ) {
    qaTask(distinct_on: $distinct_on, where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      ...QaTask
    }
  }
  ${QaTaskFragmentDoc}
`;

/**
 * __useQueryQaTaskObjectsQuery__
 *
 * To run a query within a React component, call `useQueryQaTaskObjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryQaTaskObjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryQaTaskObjectsQuery({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useQueryQaTaskObjectsQuery(
  baseOptions?: Apollo.QueryHookOptions<QueryQaTaskObjectsQuery, QueryQaTaskObjectsQueryVariables>
) {
  return Apollo.useQuery<QueryQaTaskObjectsQuery, QueryQaTaskObjectsQueryVariables>(
    QueryQaTaskObjectsDocument,
    baseOptions
  );
}
export function useQueryQaTaskObjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryQaTaskObjectsQuery, QueryQaTaskObjectsQueryVariables>
) {
  return Apollo.useLazyQuery<QueryQaTaskObjectsQuery, QueryQaTaskObjectsQueryVariables>(
    QueryQaTaskObjectsDocument,
    baseOptions
  );
}
export type QueryQaTaskObjectsQueryHookResult = ReturnType<typeof useQueryQaTaskObjectsQuery>;
export type QueryQaTaskObjectsLazyQueryHookResult = ReturnType<typeof useQueryQaTaskObjectsLazyQuery>;
export type QueryQaTaskObjectsQueryResult = Apollo.QueryResult<
  QueryQaTaskObjectsQuery,
  QueryQaTaskObjectsQueryVariables
>;
export const SubscribeToQaTaskByIdDocument = gql`
  subscription subscribeToQaTaskById($qaTaskId: uuid!) {
    qaTask_by_pk(id: $qaTaskId) {
      ...QaTask
    }
  }
  ${QaTaskFragmentDoc}
`;

/**
 * __useSubscribeToQaTaskByIdSubscription__
 *
 * To run a query within a React component, call `useSubscribeToQaTaskByIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToQaTaskByIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToQaTaskByIdSubscription({
 *   variables: {
 *      qaTaskId: // value for 'qaTaskId'
 *   },
 * });
 */
export function useSubscribeToQaTaskByIdSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    SubscribeToQaTaskByIdSubscription,
    SubscribeToQaTaskByIdSubscriptionVariables
  >
) {
  return Apollo.useSubscription<SubscribeToQaTaskByIdSubscription, SubscribeToQaTaskByIdSubscriptionVariables>(
    SubscribeToQaTaskByIdDocument,
    baseOptions
  );
}
export type SubscribeToQaTaskByIdSubscriptionHookResult = ReturnType<typeof useSubscribeToQaTaskByIdSubscription>;
export type SubscribeToQaTaskByIdSubscriptionResult = Apollo.SubscriptionResult<SubscribeToQaTaskByIdSubscription>;
export const SubscribeToQaTaskObjectsDocument = gql`
  subscription subscribeToQaTaskObjects(
    $distinct_on: [qaTask_select_column!]
    $where: qaTask_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [qaTask_order_by!]
  ) {
    qaTask(distinct_on: $distinct_on, where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      ...QaTask
    }
  }
  ${QaTaskFragmentDoc}
`;

/**
 * __useSubscribeToQaTaskObjectsSubscription__
 *
 * To run a query within a React component, call `useSubscribeToQaTaskObjectsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToQaTaskObjectsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToQaTaskObjectsSubscription({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useSubscribeToQaTaskObjectsSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    SubscribeToQaTaskObjectsSubscription,
    SubscribeToQaTaskObjectsSubscriptionVariables
  >
) {
  return Apollo.useSubscription<SubscribeToQaTaskObjectsSubscription, SubscribeToQaTaskObjectsSubscriptionVariables>(
    SubscribeToQaTaskObjectsDocument,
    baseOptions
  );
}
export type SubscribeToQaTaskObjectsSubscriptionHookResult = ReturnType<typeof useSubscribeToQaTaskObjectsSubscription>;
export type SubscribeToQaTaskObjectsSubscriptionResult = Apollo.SubscriptionResult<SubscribeToQaTaskObjectsSubscription>;
export const InsertQaTaskDocument = gql`
  mutation insertQaTask($objects: [qaTask_insert_input!]!) {
    insert_qaTask(objects: $objects) {
      affected_rows
      returning {
        ...QaTask
      }
    }
  }
  ${QaTaskFragmentDoc}
`;
export type InsertQaTaskMutationFn = Apollo.MutationFunction<InsertQaTaskMutation, InsertQaTaskMutationVariables>;

/**
 * __useInsertQaTaskMutation__
 *
 * To run a mutation, you first call `useInsertQaTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertQaTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertQaTaskMutation, { data, loading, error }] = useInsertQaTaskMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertQaTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<InsertQaTaskMutation, InsertQaTaskMutationVariables>
) {
  return Apollo.useMutation<InsertQaTaskMutation, InsertQaTaskMutationVariables>(InsertQaTaskDocument, baseOptions);
}
export type InsertQaTaskMutationHookResult = ReturnType<typeof useInsertQaTaskMutation>;
export type InsertQaTaskMutationResult = Apollo.MutationResult<InsertQaTaskMutation>;
export type InsertQaTaskMutationOptions = Apollo.BaseMutationOptions<
  InsertQaTaskMutation,
  InsertQaTaskMutationVariables
>;
export const InsertQaTaskWithOnConflictDocument = gql`
  mutation insertQaTaskWithOnConflict($objects: [qaTask_insert_input!]!, $onConflict: qaTask_on_conflict) {
    insert_qaTask(objects: $objects, on_conflict: $onConflict) {
      affected_rows
      returning {
        ...QaTask
      }
    }
  }
  ${QaTaskFragmentDoc}
`;
export type InsertQaTaskWithOnConflictMutationFn = Apollo.MutationFunction<
  InsertQaTaskWithOnConflictMutation,
  InsertQaTaskWithOnConflictMutationVariables
>;

/**
 * __useInsertQaTaskWithOnConflictMutation__
 *
 * To run a mutation, you first call `useInsertQaTaskWithOnConflictMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertQaTaskWithOnConflictMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertQaTaskWithOnConflictMutation, { data, loading, error }] = useInsertQaTaskWithOnConflictMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *      onConflict: // value for 'onConflict'
 *   },
 * });
 */
export function useInsertQaTaskWithOnConflictMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertQaTaskWithOnConflictMutation,
    InsertQaTaskWithOnConflictMutationVariables
  >
) {
  return Apollo.useMutation<InsertQaTaskWithOnConflictMutation, InsertQaTaskWithOnConflictMutationVariables>(
    InsertQaTaskWithOnConflictDocument,
    baseOptions
  );
}
export type InsertQaTaskWithOnConflictMutationHookResult = ReturnType<typeof useInsertQaTaskWithOnConflictMutation>;
export type InsertQaTaskWithOnConflictMutationResult = Apollo.MutationResult<InsertQaTaskWithOnConflictMutation>;
export type InsertQaTaskWithOnConflictMutationOptions = Apollo.BaseMutationOptions<
  InsertQaTaskWithOnConflictMutation,
  InsertQaTaskWithOnConflictMutationVariables
>;
export const UpdateQaTaskByIdDocument = gql`
  mutation updateQaTaskById($id: uuid, $set: qaTask_set_input) {
    update_qaTask(_set: $set, where: { id: { _eq: $id } }) {
      affected_rows
      returning {
        ...QaTask
      }
    }
  }
  ${QaTaskFragmentDoc}
`;
export type UpdateQaTaskByIdMutationFn = Apollo.MutationFunction<
  UpdateQaTaskByIdMutation,
  UpdateQaTaskByIdMutationVariables
>;

/**
 * __useUpdateQaTaskByIdMutation__
 *
 * To run a mutation, you first call `useUpdateQaTaskByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQaTaskByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQaTaskByIdMutation, { data, loading, error }] = useUpdateQaTaskByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateQaTaskByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateQaTaskByIdMutation, UpdateQaTaskByIdMutationVariables>
) {
  return Apollo.useMutation<UpdateQaTaskByIdMutation, UpdateQaTaskByIdMutationVariables>(
    UpdateQaTaskByIdDocument,
    baseOptions
  );
}
export type UpdateQaTaskByIdMutationHookResult = ReturnType<typeof useUpdateQaTaskByIdMutation>;
export type UpdateQaTaskByIdMutationResult = Apollo.MutationResult<UpdateQaTaskByIdMutation>;
export type UpdateQaTaskByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateQaTaskByIdMutation,
  UpdateQaTaskByIdMutationVariables
>;
export const UpdateQaTaskDocument = gql`
  mutation updateQaTask($set: qaTask_set_input, $where: qaTask_bool_exp!) {
    update_qaTask(_set: $set, where: $where) {
      affected_rows
      returning {
        ...QaTask
      }
    }
  }
  ${QaTaskFragmentDoc}
`;
export type UpdateQaTaskMutationFn = Apollo.MutationFunction<UpdateQaTaskMutation, UpdateQaTaskMutationVariables>;

/**
 * __useUpdateQaTaskMutation__
 *
 * To run a mutation, you first call `useUpdateQaTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQaTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQaTaskMutation, { data, loading, error }] = useUpdateQaTaskMutation({
 *   variables: {
 *      set: // value for 'set'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateQaTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateQaTaskMutation, UpdateQaTaskMutationVariables>
) {
  return Apollo.useMutation<UpdateQaTaskMutation, UpdateQaTaskMutationVariables>(UpdateQaTaskDocument, baseOptions);
}
export type UpdateQaTaskMutationHookResult = ReturnType<typeof useUpdateQaTaskMutation>;
export type UpdateQaTaskMutationResult = Apollo.MutationResult<UpdateQaTaskMutation>;
export type UpdateQaTaskMutationOptions = Apollo.BaseMutationOptions<
  UpdateQaTaskMutation,
  UpdateQaTaskMutationVariables
>;
export const RemoveQaTaskModelByIdDocument = gql`
  mutation removeQaTaskModelById($id: uuid) {
    delete_qaTask(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
export type RemoveQaTaskModelByIdMutationFn = Apollo.MutationFunction<
  RemoveQaTaskModelByIdMutation,
  RemoveQaTaskModelByIdMutationVariables
>;

/**
 * __useRemoveQaTaskModelByIdMutation__
 *
 * To run a mutation, you first call `useRemoveQaTaskModelByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveQaTaskModelByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeQaTaskModelByIdMutation, { data, loading, error }] = useRemoveQaTaskModelByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveQaTaskModelByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveQaTaskModelByIdMutation, RemoveQaTaskModelByIdMutationVariables>
) {
  return Apollo.useMutation<RemoveQaTaskModelByIdMutation, RemoveQaTaskModelByIdMutationVariables>(
    RemoveQaTaskModelByIdDocument,
    baseOptions
  );
}
export type RemoveQaTaskModelByIdMutationHookResult = ReturnType<typeof useRemoveQaTaskModelByIdMutation>;
export type RemoveQaTaskModelByIdMutationResult = Apollo.MutationResult<RemoveQaTaskModelByIdMutation>;
export type RemoveQaTaskModelByIdMutationOptions = Apollo.BaseMutationOptions<
  RemoveQaTaskModelByIdMutation,
  RemoveQaTaskModelByIdMutationVariables
>;
export const RemoveQaTaskModelDocument = gql`
  mutation removeQaTaskModel($where: qaTask_bool_exp!) {
    delete_qaTask(where: $where) {
      affected_rows
    }
  }
`;
export type RemoveQaTaskModelMutationFn = Apollo.MutationFunction<
  RemoveQaTaskModelMutation,
  RemoveQaTaskModelMutationVariables
>;

/**
 * __useRemoveQaTaskModelMutation__
 *
 * To run a mutation, you first call `useRemoveQaTaskModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveQaTaskModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeQaTaskModelMutation, { data, loading, error }] = useRemoveQaTaskModelMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useRemoveQaTaskModelMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveQaTaskModelMutation, RemoveQaTaskModelMutationVariables>
) {
  return Apollo.useMutation<RemoveQaTaskModelMutation, RemoveQaTaskModelMutationVariables>(
    RemoveQaTaskModelDocument,
    baseOptions
  );
}
export type RemoveQaTaskModelMutationHookResult = ReturnType<typeof useRemoveQaTaskModelMutation>;
export type RemoveQaTaskModelMutationResult = Apollo.MutationResult<RemoveQaTaskModelMutation>;
export type RemoveQaTaskModelMutationOptions = Apollo.BaseMutationOptions<
  RemoveQaTaskModelMutation,
  RemoveQaTaskModelMutationVariables
>;
export const QueryClientTerritoryByIdDocument = gql`
  query queryClientTerritoryById($clientTerritoryId: uuid!) {
    clientTerritory_by_pk(id: $clientTerritoryId) {
      ...ClientTerritory
    }
  }
  ${ClientTerritoryFragmentDoc}
`;

/**
 * __useQueryClientTerritoryByIdQuery__
 *
 * To run a query within a React component, call `useQueryClientTerritoryByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryClientTerritoryByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryClientTerritoryByIdQuery({
 *   variables: {
 *      clientTerritoryId: // value for 'clientTerritoryId'
 *   },
 * });
 */
export function useQueryClientTerritoryByIdQuery(
  baseOptions: Apollo.QueryHookOptions<QueryClientTerritoryByIdQuery, QueryClientTerritoryByIdQueryVariables>
) {
  return Apollo.useQuery<QueryClientTerritoryByIdQuery, QueryClientTerritoryByIdQueryVariables>(
    QueryClientTerritoryByIdDocument,
    baseOptions
  );
}
export function useQueryClientTerritoryByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryClientTerritoryByIdQuery, QueryClientTerritoryByIdQueryVariables>
) {
  return Apollo.useLazyQuery<QueryClientTerritoryByIdQuery, QueryClientTerritoryByIdQueryVariables>(
    QueryClientTerritoryByIdDocument,
    baseOptions
  );
}
export type QueryClientTerritoryByIdQueryHookResult = ReturnType<typeof useQueryClientTerritoryByIdQuery>;
export type QueryClientTerritoryByIdLazyQueryHookResult = ReturnType<typeof useQueryClientTerritoryByIdLazyQuery>;
export type QueryClientTerritoryByIdQueryResult = Apollo.QueryResult<
  QueryClientTerritoryByIdQuery,
  QueryClientTerritoryByIdQueryVariables
>;
export const QueryClientTerritoryObjectsDocument = gql`
  query queryClientTerritoryObjects(
    $distinct_on: [clientTerritory_select_column!]
    $where: clientTerritory_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [clientTerritory_order_by!]
  ) {
    clientTerritory(distinct_on: $distinct_on, where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      ...ClientTerritory
    }
  }
  ${ClientTerritoryFragmentDoc}
`;

/**
 * __useQueryClientTerritoryObjectsQuery__
 *
 * To run a query within a React component, call `useQueryClientTerritoryObjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryClientTerritoryObjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryClientTerritoryObjectsQuery({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useQueryClientTerritoryObjectsQuery(
  baseOptions?: Apollo.QueryHookOptions<QueryClientTerritoryObjectsQuery, QueryClientTerritoryObjectsQueryVariables>
) {
  return Apollo.useQuery<QueryClientTerritoryObjectsQuery, QueryClientTerritoryObjectsQueryVariables>(
    QueryClientTerritoryObjectsDocument,
    baseOptions
  );
}
export function useQueryClientTerritoryObjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryClientTerritoryObjectsQuery, QueryClientTerritoryObjectsQueryVariables>
) {
  return Apollo.useLazyQuery<QueryClientTerritoryObjectsQuery, QueryClientTerritoryObjectsQueryVariables>(
    QueryClientTerritoryObjectsDocument,
    baseOptions
  );
}
export type QueryClientTerritoryObjectsQueryHookResult = ReturnType<typeof useQueryClientTerritoryObjectsQuery>;
export type QueryClientTerritoryObjectsLazyQueryHookResult = ReturnType<typeof useQueryClientTerritoryObjectsLazyQuery>;
export type QueryClientTerritoryObjectsQueryResult = Apollo.QueryResult<
  QueryClientTerritoryObjectsQuery,
  QueryClientTerritoryObjectsQueryVariables
>;
export const SubscribeToClientTerritoryByIdDocument = gql`
  subscription subscribeToClientTerritoryById($clientTerritoryId: uuid!) {
    clientTerritory_by_pk(id: $clientTerritoryId) {
      ...ClientTerritory
    }
  }
  ${ClientTerritoryFragmentDoc}
`;

/**
 * __useSubscribeToClientTerritoryByIdSubscription__
 *
 * To run a query within a React component, call `useSubscribeToClientTerritoryByIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToClientTerritoryByIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToClientTerritoryByIdSubscription({
 *   variables: {
 *      clientTerritoryId: // value for 'clientTerritoryId'
 *   },
 * });
 */
export function useSubscribeToClientTerritoryByIdSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    SubscribeToClientTerritoryByIdSubscription,
    SubscribeToClientTerritoryByIdSubscriptionVariables
  >
) {
  return Apollo.useSubscription<
    SubscribeToClientTerritoryByIdSubscription,
    SubscribeToClientTerritoryByIdSubscriptionVariables
  >(SubscribeToClientTerritoryByIdDocument, baseOptions);
}
export type SubscribeToClientTerritoryByIdSubscriptionHookResult = ReturnType<
  typeof useSubscribeToClientTerritoryByIdSubscription
>;
export type SubscribeToClientTerritoryByIdSubscriptionResult = Apollo.SubscriptionResult<SubscribeToClientTerritoryByIdSubscription>;
export const SubscribeToClientTerritoryObjectsDocument = gql`
  subscription subscribeToClientTerritoryObjects(
    $distinct_on: [clientTerritory_select_column!]
    $where: clientTerritory_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [clientTerritory_order_by!]
  ) {
    clientTerritory(distinct_on: $distinct_on, where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      ...ClientTerritory
    }
  }
  ${ClientTerritoryFragmentDoc}
`;

/**
 * __useSubscribeToClientTerritoryObjectsSubscription__
 *
 * To run a query within a React component, call `useSubscribeToClientTerritoryObjectsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToClientTerritoryObjectsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToClientTerritoryObjectsSubscription({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useSubscribeToClientTerritoryObjectsSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    SubscribeToClientTerritoryObjectsSubscription,
    SubscribeToClientTerritoryObjectsSubscriptionVariables
  >
) {
  return Apollo.useSubscription<
    SubscribeToClientTerritoryObjectsSubscription,
    SubscribeToClientTerritoryObjectsSubscriptionVariables
  >(SubscribeToClientTerritoryObjectsDocument, baseOptions);
}
export type SubscribeToClientTerritoryObjectsSubscriptionHookResult = ReturnType<
  typeof useSubscribeToClientTerritoryObjectsSubscription
>;
export type SubscribeToClientTerritoryObjectsSubscriptionResult = Apollo.SubscriptionResult<SubscribeToClientTerritoryObjectsSubscription>;
export const InsertClientTerritoryDocument = gql`
  mutation insertClientTerritory($objects: [clientTerritory_insert_input!]!) {
    insert_clientTerritory(objects: $objects) {
      affected_rows
      returning {
        ...ClientTerritory
      }
    }
  }
  ${ClientTerritoryFragmentDoc}
`;
export type InsertClientTerritoryMutationFn = Apollo.MutationFunction<
  InsertClientTerritoryMutation,
  InsertClientTerritoryMutationVariables
>;

/**
 * __useInsertClientTerritoryMutation__
 *
 * To run a mutation, you first call `useInsertClientTerritoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertClientTerritoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertClientTerritoryMutation, { data, loading, error }] = useInsertClientTerritoryMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertClientTerritoryMutation(
  baseOptions?: Apollo.MutationHookOptions<InsertClientTerritoryMutation, InsertClientTerritoryMutationVariables>
) {
  return Apollo.useMutation<InsertClientTerritoryMutation, InsertClientTerritoryMutationVariables>(
    InsertClientTerritoryDocument,
    baseOptions
  );
}
export type InsertClientTerritoryMutationHookResult = ReturnType<typeof useInsertClientTerritoryMutation>;
export type InsertClientTerritoryMutationResult = Apollo.MutationResult<InsertClientTerritoryMutation>;
export type InsertClientTerritoryMutationOptions = Apollo.BaseMutationOptions<
  InsertClientTerritoryMutation,
  InsertClientTerritoryMutationVariables
>;
export const InsertClientTerritoryWithOnConflictDocument = gql`
  mutation insertClientTerritoryWithOnConflict(
    $objects: [clientTerritory_insert_input!]!
    $onConflict: clientTerritory_on_conflict
  ) {
    insert_clientTerritory(objects: $objects, on_conflict: $onConflict) {
      affected_rows
      returning {
        ...ClientTerritory
      }
    }
  }
  ${ClientTerritoryFragmentDoc}
`;
export type InsertClientTerritoryWithOnConflictMutationFn = Apollo.MutationFunction<
  InsertClientTerritoryWithOnConflictMutation,
  InsertClientTerritoryWithOnConflictMutationVariables
>;

/**
 * __useInsertClientTerritoryWithOnConflictMutation__
 *
 * To run a mutation, you first call `useInsertClientTerritoryWithOnConflictMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertClientTerritoryWithOnConflictMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertClientTerritoryWithOnConflictMutation, { data, loading, error }] = useInsertClientTerritoryWithOnConflictMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *      onConflict: // value for 'onConflict'
 *   },
 * });
 */
export function useInsertClientTerritoryWithOnConflictMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertClientTerritoryWithOnConflictMutation,
    InsertClientTerritoryWithOnConflictMutationVariables
  >
) {
  return Apollo.useMutation<
    InsertClientTerritoryWithOnConflictMutation,
    InsertClientTerritoryWithOnConflictMutationVariables
  >(InsertClientTerritoryWithOnConflictDocument, baseOptions);
}
export type InsertClientTerritoryWithOnConflictMutationHookResult = ReturnType<
  typeof useInsertClientTerritoryWithOnConflictMutation
>;
export type InsertClientTerritoryWithOnConflictMutationResult = Apollo.MutationResult<InsertClientTerritoryWithOnConflictMutation>;
export type InsertClientTerritoryWithOnConflictMutationOptions = Apollo.BaseMutationOptions<
  InsertClientTerritoryWithOnConflictMutation,
  InsertClientTerritoryWithOnConflictMutationVariables
>;
export const UpdateClientTerritoryByIdDocument = gql`
  mutation updateClientTerritoryById($id: uuid, $set: clientTerritory_set_input) {
    update_clientTerritory(_set: $set, where: { id: { _eq: $id } }) {
      affected_rows
      returning {
        ...ClientTerritory
      }
    }
  }
  ${ClientTerritoryFragmentDoc}
`;
export type UpdateClientTerritoryByIdMutationFn = Apollo.MutationFunction<
  UpdateClientTerritoryByIdMutation,
  UpdateClientTerritoryByIdMutationVariables
>;

/**
 * __useUpdateClientTerritoryByIdMutation__
 *
 * To run a mutation, you first call `useUpdateClientTerritoryByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientTerritoryByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientTerritoryByIdMutation, { data, loading, error }] = useUpdateClientTerritoryByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateClientTerritoryByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateClientTerritoryByIdMutation,
    UpdateClientTerritoryByIdMutationVariables
  >
) {
  return Apollo.useMutation<UpdateClientTerritoryByIdMutation, UpdateClientTerritoryByIdMutationVariables>(
    UpdateClientTerritoryByIdDocument,
    baseOptions
  );
}
export type UpdateClientTerritoryByIdMutationHookResult = ReturnType<typeof useUpdateClientTerritoryByIdMutation>;
export type UpdateClientTerritoryByIdMutationResult = Apollo.MutationResult<UpdateClientTerritoryByIdMutation>;
export type UpdateClientTerritoryByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateClientTerritoryByIdMutation,
  UpdateClientTerritoryByIdMutationVariables
>;
export const UpdateClientTerritoryDocument = gql`
  mutation updateClientTerritory($set: clientTerritory_set_input, $where: clientTerritory_bool_exp!) {
    update_clientTerritory(_set: $set, where: $where) {
      affected_rows
      returning {
        ...ClientTerritory
      }
    }
  }
  ${ClientTerritoryFragmentDoc}
`;
export type UpdateClientTerritoryMutationFn = Apollo.MutationFunction<
  UpdateClientTerritoryMutation,
  UpdateClientTerritoryMutationVariables
>;

/**
 * __useUpdateClientTerritoryMutation__
 *
 * To run a mutation, you first call `useUpdateClientTerritoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientTerritoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientTerritoryMutation, { data, loading, error }] = useUpdateClientTerritoryMutation({
 *   variables: {
 *      set: // value for 'set'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateClientTerritoryMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateClientTerritoryMutation, UpdateClientTerritoryMutationVariables>
) {
  return Apollo.useMutation<UpdateClientTerritoryMutation, UpdateClientTerritoryMutationVariables>(
    UpdateClientTerritoryDocument,
    baseOptions
  );
}
export type UpdateClientTerritoryMutationHookResult = ReturnType<typeof useUpdateClientTerritoryMutation>;
export type UpdateClientTerritoryMutationResult = Apollo.MutationResult<UpdateClientTerritoryMutation>;
export type UpdateClientTerritoryMutationOptions = Apollo.BaseMutationOptions<
  UpdateClientTerritoryMutation,
  UpdateClientTerritoryMutationVariables
>;
export const RemoveClientTerritoryModelByIdDocument = gql`
  mutation removeClientTerritoryModelById($id: uuid) {
    delete_clientTerritory(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
export type RemoveClientTerritoryModelByIdMutationFn = Apollo.MutationFunction<
  RemoveClientTerritoryModelByIdMutation,
  RemoveClientTerritoryModelByIdMutationVariables
>;

/**
 * __useRemoveClientTerritoryModelByIdMutation__
 *
 * To run a mutation, you first call `useRemoveClientTerritoryModelByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveClientTerritoryModelByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeClientTerritoryModelByIdMutation, { data, loading, error }] = useRemoveClientTerritoryModelByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveClientTerritoryModelByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveClientTerritoryModelByIdMutation,
    RemoveClientTerritoryModelByIdMutationVariables
  >
) {
  return Apollo.useMutation<RemoveClientTerritoryModelByIdMutation, RemoveClientTerritoryModelByIdMutationVariables>(
    RemoveClientTerritoryModelByIdDocument,
    baseOptions
  );
}
export type RemoveClientTerritoryModelByIdMutationHookResult = ReturnType<
  typeof useRemoveClientTerritoryModelByIdMutation
>;
export type RemoveClientTerritoryModelByIdMutationResult = Apollo.MutationResult<RemoveClientTerritoryModelByIdMutation>;
export type RemoveClientTerritoryModelByIdMutationOptions = Apollo.BaseMutationOptions<
  RemoveClientTerritoryModelByIdMutation,
  RemoveClientTerritoryModelByIdMutationVariables
>;
export const RemoveClientTerritoryModelDocument = gql`
  mutation removeClientTerritoryModel($where: clientTerritory_bool_exp!) {
    delete_clientTerritory(where: $where) {
      affected_rows
    }
  }
`;
export type RemoveClientTerritoryModelMutationFn = Apollo.MutationFunction<
  RemoveClientTerritoryModelMutation,
  RemoveClientTerritoryModelMutationVariables
>;

/**
 * __useRemoveClientTerritoryModelMutation__
 *
 * To run a mutation, you first call `useRemoveClientTerritoryModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveClientTerritoryModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeClientTerritoryModelMutation, { data, loading, error }] = useRemoveClientTerritoryModelMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useRemoveClientTerritoryModelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveClientTerritoryModelMutation,
    RemoveClientTerritoryModelMutationVariables
  >
) {
  return Apollo.useMutation<RemoveClientTerritoryModelMutation, RemoveClientTerritoryModelMutationVariables>(
    RemoveClientTerritoryModelDocument,
    baseOptions
  );
}
export type RemoveClientTerritoryModelMutationHookResult = ReturnType<typeof useRemoveClientTerritoryModelMutation>;
export type RemoveClientTerritoryModelMutationResult = Apollo.MutationResult<RemoveClientTerritoryModelMutation>;
export type RemoveClientTerritoryModelMutationOptions = Apollo.BaseMutationOptions<
  RemoveClientTerritoryModelMutation,
  RemoveClientTerritoryModelMutationVariables
>;
export const QueryUserByIdDocument = gql`
  query queryUserById($userId: uuid!) {
    user_by_pk(id: $userId) {
      ...User
    }
  }
  ${UserFragmentDoc}
`;

/**
 * __useQueryUserByIdQuery__
 *
 * To run a query within a React component, call `useQueryUserByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryUserByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryUserByIdQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useQueryUserByIdQuery(
  baseOptions: Apollo.QueryHookOptions<QueryUserByIdQuery, QueryUserByIdQueryVariables>
) {
  return Apollo.useQuery<QueryUserByIdQuery, QueryUserByIdQueryVariables>(QueryUserByIdDocument, baseOptions);
}
export function useQueryUserByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryUserByIdQuery, QueryUserByIdQueryVariables>
) {
  return Apollo.useLazyQuery<QueryUserByIdQuery, QueryUserByIdQueryVariables>(QueryUserByIdDocument, baseOptions);
}
export type QueryUserByIdQueryHookResult = ReturnType<typeof useQueryUserByIdQuery>;
export type QueryUserByIdLazyQueryHookResult = ReturnType<typeof useQueryUserByIdLazyQuery>;
export type QueryUserByIdQueryResult = Apollo.QueryResult<QueryUserByIdQuery, QueryUserByIdQueryVariables>;
export const QueryUserObjectsDocument = gql`
  query queryUserObjects(
    $distinct_on: [user_select_column!]
    $where: user_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [user_order_by!]
  ) {
    user(distinct_on: $distinct_on, where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      ...User
    }
  }
  ${UserFragmentDoc}
`;

/**
 * __useQueryUserObjectsQuery__
 *
 * To run a query within a React component, call `useQueryUserObjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryUserObjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryUserObjectsQuery({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useQueryUserObjectsQuery(
  baseOptions?: Apollo.QueryHookOptions<QueryUserObjectsQuery, QueryUserObjectsQueryVariables>
) {
  return Apollo.useQuery<QueryUserObjectsQuery, QueryUserObjectsQueryVariables>(QueryUserObjectsDocument, baseOptions);
}
export function useQueryUserObjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryUserObjectsQuery, QueryUserObjectsQueryVariables>
) {
  return Apollo.useLazyQuery<QueryUserObjectsQuery, QueryUserObjectsQueryVariables>(
    QueryUserObjectsDocument,
    baseOptions
  );
}
export type QueryUserObjectsQueryHookResult = ReturnType<typeof useQueryUserObjectsQuery>;
export type QueryUserObjectsLazyQueryHookResult = ReturnType<typeof useQueryUserObjectsLazyQuery>;
export type QueryUserObjectsQueryResult = Apollo.QueryResult<QueryUserObjectsQuery, QueryUserObjectsQueryVariables>;
export const SubscribeToUserByIdDocument = gql`
  subscription subscribeToUserById($userId: uuid!) {
    user_by_pk(id: $userId) {
      ...User
    }
  }
  ${UserFragmentDoc}
`;

/**
 * __useSubscribeToUserByIdSubscription__
 *
 * To run a query within a React component, call `useSubscribeToUserByIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToUserByIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToUserByIdSubscription({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useSubscribeToUserByIdSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<SubscribeToUserByIdSubscription, SubscribeToUserByIdSubscriptionVariables>
) {
  return Apollo.useSubscription<SubscribeToUserByIdSubscription, SubscribeToUserByIdSubscriptionVariables>(
    SubscribeToUserByIdDocument,
    baseOptions
  );
}
export type SubscribeToUserByIdSubscriptionHookResult = ReturnType<typeof useSubscribeToUserByIdSubscription>;
export type SubscribeToUserByIdSubscriptionResult = Apollo.SubscriptionResult<SubscribeToUserByIdSubscription>;
export const SubscribeToUserObjectsDocument = gql`
  subscription subscribeToUserObjects(
    $distinct_on: [user_select_column!]
    $where: user_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [user_order_by!]
  ) {
    user(distinct_on: $distinct_on, where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      ...User
    }
  }
  ${UserFragmentDoc}
`;

/**
 * __useSubscribeToUserObjectsSubscription__
 *
 * To run a query within a React component, call `useSubscribeToUserObjectsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToUserObjectsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToUserObjectsSubscription({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useSubscribeToUserObjectsSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    SubscribeToUserObjectsSubscription,
    SubscribeToUserObjectsSubscriptionVariables
  >
) {
  return Apollo.useSubscription<SubscribeToUserObjectsSubscription, SubscribeToUserObjectsSubscriptionVariables>(
    SubscribeToUserObjectsDocument,
    baseOptions
  );
}
export type SubscribeToUserObjectsSubscriptionHookResult = ReturnType<typeof useSubscribeToUserObjectsSubscription>;
export type SubscribeToUserObjectsSubscriptionResult = Apollo.SubscriptionResult<SubscribeToUserObjectsSubscription>;
export const InsertUserDocument = gql`
  mutation insertUser($objects: [user_insert_input!]!) {
    insert_user(objects: $objects) {
      affected_rows
      returning {
        ...User
      }
    }
  }
  ${UserFragmentDoc}
`;
export type InsertUserMutationFn = Apollo.MutationFunction<InsertUserMutation, InsertUserMutationVariables>;

/**
 * __useInsertUserMutation__
 *
 * To run a mutation, you first call `useInsertUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertUserMutation, { data, loading, error }] = useInsertUserMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertUserMutation(
  baseOptions?: Apollo.MutationHookOptions<InsertUserMutation, InsertUserMutationVariables>
) {
  return Apollo.useMutation<InsertUserMutation, InsertUserMutationVariables>(InsertUserDocument, baseOptions);
}
export type InsertUserMutationHookResult = ReturnType<typeof useInsertUserMutation>;
export type InsertUserMutationResult = Apollo.MutationResult<InsertUserMutation>;
export type InsertUserMutationOptions = Apollo.BaseMutationOptions<InsertUserMutation, InsertUserMutationVariables>;
export const InsertUserWithOnConflictDocument = gql`
  mutation insertUserWithOnConflict($objects: [user_insert_input!]!, $onConflict: user_on_conflict) {
    insert_user(objects: $objects, on_conflict: $onConflict) {
      affected_rows
      returning {
        ...User
      }
    }
  }
  ${UserFragmentDoc}
`;
export type InsertUserWithOnConflictMutationFn = Apollo.MutationFunction<
  InsertUserWithOnConflictMutation,
  InsertUserWithOnConflictMutationVariables
>;

/**
 * __useInsertUserWithOnConflictMutation__
 *
 * To run a mutation, you first call `useInsertUserWithOnConflictMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertUserWithOnConflictMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertUserWithOnConflictMutation, { data, loading, error }] = useInsertUserWithOnConflictMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *      onConflict: // value for 'onConflict'
 *   },
 * });
 */
export function useInsertUserWithOnConflictMutation(
  baseOptions?: Apollo.MutationHookOptions<InsertUserWithOnConflictMutation, InsertUserWithOnConflictMutationVariables>
) {
  return Apollo.useMutation<InsertUserWithOnConflictMutation, InsertUserWithOnConflictMutationVariables>(
    InsertUserWithOnConflictDocument,
    baseOptions
  );
}
export type InsertUserWithOnConflictMutationHookResult = ReturnType<typeof useInsertUserWithOnConflictMutation>;
export type InsertUserWithOnConflictMutationResult = Apollo.MutationResult<InsertUserWithOnConflictMutation>;
export type InsertUserWithOnConflictMutationOptions = Apollo.BaseMutationOptions<
  InsertUserWithOnConflictMutation,
  InsertUserWithOnConflictMutationVariables
>;
export const UpdateUserByIdDocument = gql`
  mutation updateUserById($id: uuid, $set: user_set_input) {
    update_user(_set: $set, where: { id: { _eq: $id } }) {
      affected_rows
      returning {
        ...User
      }
    }
  }
  ${UserFragmentDoc}
`;
export type UpdateUserByIdMutationFn = Apollo.MutationFunction<UpdateUserByIdMutation, UpdateUserByIdMutationVariables>;

/**
 * __useUpdateUserByIdMutation__
 *
 * To run a mutation, you first call `useUpdateUserByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserByIdMutation, { data, loading, error }] = useUpdateUserByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateUserByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateUserByIdMutation, UpdateUserByIdMutationVariables>
) {
  return Apollo.useMutation<UpdateUserByIdMutation, UpdateUserByIdMutationVariables>(
    UpdateUserByIdDocument,
    baseOptions
  );
}
export type UpdateUserByIdMutationHookResult = ReturnType<typeof useUpdateUserByIdMutation>;
export type UpdateUserByIdMutationResult = Apollo.MutationResult<UpdateUserByIdMutation>;
export type UpdateUserByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserByIdMutation,
  UpdateUserByIdMutationVariables
>;
export const UpdateUserDocument = gql`
  mutation updateUser($set: user_set_input, $where: user_bool_exp!) {
    update_user(_set: $set, where: $where) {
      affected_rows
      returning {
        ...User
      }
    }
  }
  ${UserFragmentDoc}
`;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      set: // value for 'set'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>
) {
  return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, baseOptions);
}
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const RemoveUserModelByIdDocument = gql`
  mutation removeUserModelById($id: uuid) {
    delete_user(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
export type RemoveUserModelByIdMutationFn = Apollo.MutationFunction<
  RemoveUserModelByIdMutation,
  RemoveUserModelByIdMutationVariables
>;

/**
 * __useRemoveUserModelByIdMutation__
 *
 * To run a mutation, you first call `useRemoveUserModelByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserModelByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserModelByIdMutation, { data, loading, error }] = useRemoveUserModelByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveUserModelByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveUserModelByIdMutation, RemoveUserModelByIdMutationVariables>
) {
  return Apollo.useMutation<RemoveUserModelByIdMutation, RemoveUserModelByIdMutationVariables>(
    RemoveUserModelByIdDocument,
    baseOptions
  );
}
export type RemoveUserModelByIdMutationHookResult = ReturnType<typeof useRemoveUserModelByIdMutation>;
export type RemoveUserModelByIdMutationResult = Apollo.MutationResult<RemoveUserModelByIdMutation>;
export type RemoveUserModelByIdMutationOptions = Apollo.BaseMutationOptions<
  RemoveUserModelByIdMutation,
  RemoveUserModelByIdMutationVariables
>;
export const RemoveUserModelDocument = gql`
  mutation removeUserModel($where: user_bool_exp!) {
    delete_user(where: $where) {
      affected_rows
    }
  }
`;
export type RemoveUserModelMutationFn = Apollo.MutationFunction<
  RemoveUserModelMutation,
  RemoveUserModelMutationVariables
>;

/**
 * __useRemoveUserModelMutation__
 *
 * To run a mutation, you first call `useRemoveUserModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserModelMutation, { data, loading, error }] = useRemoveUserModelMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useRemoveUserModelMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveUserModelMutation, RemoveUserModelMutationVariables>
) {
  return Apollo.useMutation<RemoveUserModelMutation, RemoveUserModelMutationVariables>(
    RemoveUserModelDocument,
    baseOptions
  );
}
export type RemoveUserModelMutationHookResult = ReturnType<typeof useRemoveUserModelMutation>;
export type RemoveUserModelMutationResult = Apollo.MutationResult<RemoveUserModelMutation>;
export type RemoveUserModelMutationOptions = Apollo.BaseMutationOptions<
  RemoveUserModelMutation,
  RemoveUserModelMutationVariables
>;
export const QueryUserInviteByIdDocument = gql`
  query queryUserInviteById($userInviteId: uuid!) {
    userInvite_by_pk(id: $userInviteId) {
      ...UserInvite
    }
  }
  ${UserInviteFragmentDoc}
`;

/**
 * __useQueryUserInviteByIdQuery__
 *
 * To run a query within a React component, call `useQueryUserInviteByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryUserInviteByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryUserInviteByIdQuery({
 *   variables: {
 *      userInviteId: // value for 'userInviteId'
 *   },
 * });
 */
export function useQueryUserInviteByIdQuery(
  baseOptions: Apollo.QueryHookOptions<QueryUserInviteByIdQuery, QueryUserInviteByIdQueryVariables>
) {
  return Apollo.useQuery<QueryUserInviteByIdQuery, QueryUserInviteByIdQueryVariables>(
    QueryUserInviteByIdDocument,
    baseOptions
  );
}
export function useQueryUserInviteByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryUserInviteByIdQuery, QueryUserInviteByIdQueryVariables>
) {
  return Apollo.useLazyQuery<QueryUserInviteByIdQuery, QueryUserInviteByIdQueryVariables>(
    QueryUserInviteByIdDocument,
    baseOptions
  );
}
export type QueryUserInviteByIdQueryHookResult = ReturnType<typeof useQueryUserInviteByIdQuery>;
export type QueryUserInviteByIdLazyQueryHookResult = ReturnType<typeof useQueryUserInviteByIdLazyQuery>;
export type QueryUserInviteByIdQueryResult = Apollo.QueryResult<
  QueryUserInviteByIdQuery,
  QueryUserInviteByIdQueryVariables
>;
export const QueryUserInviteObjectsDocument = gql`
  query queryUserInviteObjects(
    $distinct_on: [userInvite_select_column!]
    $where: userInvite_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [userInvite_order_by!]
  ) {
    userInvite(distinct_on: $distinct_on, where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      ...UserInvite
    }
  }
  ${UserInviteFragmentDoc}
`;

/**
 * __useQueryUserInviteObjectsQuery__
 *
 * To run a query within a React component, call `useQueryUserInviteObjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryUserInviteObjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryUserInviteObjectsQuery({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useQueryUserInviteObjectsQuery(
  baseOptions?: Apollo.QueryHookOptions<QueryUserInviteObjectsQuery, QueryUserInviteObjectsQueryVariables>
) {
  return Apollo.useQuery<QueryUserInviteObjectsQuery, QueryUserInviteObjectsQueryVariables>(
    QueryUserInviteObjectsDocument,
    baseOptions
  );
}
export function useQueryUserInviteObjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryUserInviteObjectsQuery, QueryUserInviteObjectsQueryVariables>
) {
  return Apollo.useLazyQuery<QueryUserInviteObjectsQuery, QueryUserInviteObjectsQueryVariables>(
    QueryUserInviteObjectsDocument,
    baseOptions
  );
}
export type QueryUserInviteObjectsQueryHookResult = ReturnType<typeof useQueryUserInviteObjectsQuery>;
export type QueryUserInviteObjectsLazyQueryHookResult = ReturnType<typeof useQueryUserInviteObjectsLazyQuery>;
export type QueryUserInviteObjectsQueryResult = Apollo.QueryResult<
  QueryUserInviteObjectsQuery,
  QueryUserInviteObjectsQueryVariables
>;
export const SubscribeToUserInviteByIdDocument = gql`
  subscription subscribeToUserInviteById($userInviteId: uuid!) {
    userInvite_by_pk(id: $userInviteId) {
      ...UserInvite
    }
  }
  ${UserInviteFragmentDoc}
`;

/**
 * __useSubscribeToUserInviteByIdSubscription__
 *
 * To run a query within a React component, call `useSubscribeToUserInviteByIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToUserInviteByIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToUserInviteByIdSubscription({
 *   variables: {
 *      userInviteId: // value for 'userInviteId'
 *   },
 * });
 */
export function useSubscribeToUserInviteByIdSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    SubscribeToUserInviteByIdSubscription,
    SubscribeToUserInviteByIdSubscriptionVariables
  >
) {
  return Apollo.useSubscription<SubscribeToUserInviteByIdSubscription, SubscribeToUserInviteByIdSubscriptionVariables>(
    SubscribeToUserInviteByIdDocument,
    baseOptions
  );
}
export type SubscribeToUserInviteByIdSubscriptionHookResult = ReturnType<
  typeof useSubscribeToUserInviteByIdSubscription
>;
export type SubscribeToUserInviteByIdSubscriptionResult = Apollo.SubscriptionResult<SubscribeToUserInviteByIdSubscription>;
export const SubscribeToUserInviteObjectsDocument = gql`
  subscription subscribeToUserInviteObjects(
    $distinct_on: [userInvite_select_column!]
    $where: userInvite_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [userInvite_order_by!]
  ) {
    userInvite(distinct_on: $distinct_on, where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      ...UserInvite
    }
  }
  ${UserInviteFragmentDoc}
`;

/**
 * __useSubscribeToUserInviteObjectsSubscription__
 *
 * To run a query within a React component, call `useSubscribeToUserInviteObjectsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToUserInviteObjectsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToUserInviteObjectsSubscription({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useSubscribeToUserInviteObjectsSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    SubscribeToUserInviteObjectsSubscription,
    SubscribeToUserInviteObjectsSubscriptionVariables
  >
) {
  return Apollo.useSubscription<
    SubscribeToUserInviteObjectsSubscription,
    SubscribeToUserInviteObjectsSubscriptionVariables
  >(SubscribeToUserInviteObjectsDocument, baseOptions);
}
export type SubscribeToUserInviteObjectsSubscriptionHookResult = ReturnType<
  typeof useSubscribeToUserInviteObjectsSubscription
>;
export type SubscribeToUserInviteObjectsSubscriptionResult = Apollo.SubscriptionResult<SubscribeToUserInviteObjectsSubscription>;
export const InsertUserInviteDocument = gql`
  mutation insertUserInvite($objects: [userInvite_insert_input!]!) {
    insert_userInvite(objects: $objects) {
      affected_rows
      returning {
        ...UserInvite
      }
    }
  }
  ${UserInviteFragmentDoc}
`;
export type InsertUserInviteMutationFn = Apollo.MutationFunction<
  InsertUserInviteMutation,
  InsertUserInviteMutationVariables
>;

/**
 * __useInsertUserInviteMutation__
 *
 * To run a mutation, you first call `useInsertUserInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertUserInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertUserInviteMutation, { data, loading, error }] = useInsertUserInviteMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertUserInviteMutation(
  baseOptions?: Apollo.MutationHookOptions<InsertUserInviteMutation, InsertUserInviteMutationVariables>
) {
  return Apollo.useMutation<InsertUserInviteMutation, InsertUserInviteMutationVariables>(
    InsertUserInviteDocument,
    baseOptions
  );
}
export type InsertUserInviteMutationHookResult = ReturnType<typeof useInsertUserInviteMutation>;
export type InsertUserInviteMutationResult = Apollo.MutationResult<InsertUserInviteMutation>;
export type InsertUserInviteMutationOptions = Apollo.BaseMutationOptions<
  InsertUserInviteMutation,
  InsertUserInviteMutationVariables
>;
export const InsertUserInviteWithOnConflictDocument = gql`
  mutation insertUserInviteWithOnConflict($objects: [userInvite_insert_input!]!, $onConflict: userInvite_on_conflict) {
    insert_userInvite(objects: $objects, on_conflict: $onConflict) {
      affected_rows
      returning {
        ...UserInvite
      }
    }
  }
  ${UserInviteFragmentDoc}
`;
export type InsertUserInviteWithOnConflictMutationFn = Apollo.MutationFunction<
  InsertUserInviteWithOnConflictMutation,
  InsertUserInviteWithOnConflictMutationVariables
>;

/**
 * __useInsertUserInviteWithOnConflictMutation__
 *
 * To run a mutation, you first call `useInsertUserInviteWithOnConflictMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertUserInviteWithOnConflictMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertUserInviteWithOnConflictMutation, { data, loading, error }] = useInsertUserInviteWithOnConflictMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *      onConflict: // value for 'onConflict'
 *   },
 * });
 */
export function useInsertUserInviteWithOnConflictMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertUserInviteWithOnConflictMutation,
    InsertUserInviteWithOnConflictMutationVariables
  >
) {
  return Apollo.useMutation<InsertUserInviteWithOnConflictMutation, InsertUserInviteWithOnConflictMutationVariables>(
    InsertUserInviteWithOnConflictDocument,
    baseOptions
  );
}
export type InsertUserInviteWithOnConflictMutationHookResult = ReturnType<
  typeof useInsertUserInviteWithOnConflictMutation
>;
export type InsertUserInviteWithOnConflictMutationResult = Apollo.MutationResult<InsertUserInviteWithOnConflictMutation>;
export type InsertUserInviteWithOnConflictMutationOptions = Apollo.BaseMutationOptions<
  InsertUserInviteWithOnConflictMutation,
  InsertUserInviteWithOnConflictMutationVariables
>;
export const UpdateUserInviteByIdDocument = gql`
  mutation updateUserInviteById($id: uuid, $set: userInvite_set_input) {
    update_userInvite(_set: $set, where: { id: { _eq: $id } }) {
      affected_rows
      returning {
        ...UserInvite
      }
    }
  }
  ${UserInviteFragmentDoc}
`;
export type UpdateUserInviteByIdMutationFn = Apollo.MutationFunction<
  UpdateUserInviteByIdMutation,
  UpdateUserInviteByIdMutationVariables
>;

/**
 * __useUpdateUserInviteByIdMutation__
 *
 * To run a mutation, you first call `useUpdateUserInviteByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserInviteByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserInviteByIdMutation, { data, loading, error }] = useUpdateUserInviteByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateUserInviteByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateUserInviteByIdMutation, UpdateUserInviteByIdMutationVariables>
) {
  return Apollo.useMutation<UpdateUserInviteByIdMutation, UpdateUserInviteByIdMutationVariables>(
    UpdateUserInviteByIdDocument,
    baseOptions
  );
}
export type UpdateUserInviteByIdMutationHookResult = ReturnType<typeof useUpdateUserInviteByIdMutation>;
export type UpdateUserInviteByIdMutationResult = Apollo.MutationResult<UpdateUserInviteByIdMutation>;
export type UpdateUserInviteByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserInviteByIdMutation,
  UpdateUserInviteByIdMutationVariables
>;
export const UpdateUserInviteDocument = gql`
  mutation updateUserInvite($set: userInvite_set_input, $where: userInvite_bool_exp!) {
    update_userInvite(_set: $set, where: $where) {
      affected_rows
      returning {
        ...UserInvite
      }
    }
  }
  ${UserInviteFragmentDoc}
`;
export type UpdateUserInviteMutationFn = Apollo.MutationFunction<
  UpdateUserInviteMutation,
  UpdateUserInviteMutationVariables
>;

/**
 * __useUpdateUserInviteMutation__
 *
 * To run a mutation, you first call `useUpdateUserInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserInviteMutation, { data, loading, error }] = useUpdateUserInviteMutation({
 *   variables: {
 *      set: // value for 'set'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateUserInviteMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateUserInviteMutation, UpdateUserInviteMutationVariables>
) {
  return Apollo.useMutation<UpdateUserInviteMutation, UpdateUserInviteMutationVariables>(
    UpdateUserInviteDocument,
    baseOptions
  );
}
export type UpdateUserInviteMutationHookResult = ReturnType<typeof useUpdateUserInviteMutation>;
export type UpdateUserInviteMutationResult = Apollo.MutationResult<UpdateUserInviteMutation>;
export type UpdateUserInviteMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserInviteMutation,
  UpdateUserInviteMutationVariables
>;
export const RemoveUserInviteModelByIdDocument = gql`
  mutation removeUserInviteModelById($id: uuid) {
    delete_userInvite(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
export type RemoveUserInviteModelByIdMutationFn = Apollo.MutationFunction<
  RemoveUserInviteModelByIdMutation,
  RemoveUserInviteModelByIdMutationVariables
>;

/**
 * __useRemoveUserInviteModelByIdMutation__
 *
 * To run a mutation, you first call `useRemoveUserInviteModelByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserInviteModelByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserInviteModelByIdMutation, { data, loading, error }] = useRemoveUserInviteModelByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveUserInviteModelByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveUserInviteModelByIdMutation,
    RemoveUserInviteModelByIdMutationVariables
  >
) {
  return Apollo.useMutation<RemoveUserInviteModelByIdMutation, RemoveUserInviteModelByIdMutationVariables>(
    RemoveUserInviteModelByIdDocument,
    baseOptions
  );
}
export type RemoveUserInviteModelByIdMutationHookResult = ReturnType<typeof useRemoveUserInviteModelByIdMutation>;
export type RemoveUserInviteModelByIdMutationResult = Apollo.MutationResult<RemoveUserInviteModelByIdMutation>;
export type RemoveUserInviteModelByIdMutationOptions = Apollo.BaseMutationOptions<
  RemoveUserInviteModelByIdMutation,
  RemoveUserInviteModelByIdMutationVariables
>;
export const RemoveUserInviteModelDocument = gql`
  mutation removeUserInviteModel($where: userInvite_bool_exp!) {
    delete_userInvite(where: $where) {
      affected_rows
    }
  }
`;
export type RemoveUserInviteModelMutationFn = Apollo.MutationFunction<
  RemoveUserInviteModelMutation,
  RemoveUserInviteModelMutationVariables
>;

/**
 * __useRemoveUserInviteModelMutation__
 *
 * To run a mutation, you first call `useRemoveUserInviteModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserInviteModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserInviteModelMutation, { data, loading, error }] = useRemoveUserInviteModelMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useRemoveUserInviteModelMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveUserInviteModelMutation, RemoveUserInviteModelMutationVariables>
) {
  return Apollo.useMutation<RemoveUserInviteModelMutation, RemoveUserInviteModelMutationVariables>(
    RemoveUserInviteModelDocument,
    baseOptions
  );
}
export type RemoveUserInviteModelMutationHookResult = ReturnType<typeof useRemoveUserInviteModelMutation>;
export type RemoveUserInviteModelMutationResult = Apollo.MutationResult<RemoveUserInviteModelMutation>;
export type RemoveUserInviteModelMutationOptions = Apollo.BaseMutationOptions<
  RemoveUserInviteModelMutation,
  RemoveUserInviteModelMutationVariables
>;
export const QueryQaQualificationByIdDocument = gql`
  query queryQaQualificationById($qaQualificationId: uuid!) {
    qaQualification_by_pk(id: $qaQualificationId) {
      ...QaQualification
    }
  }
  ${QaQualificationFragmentDoc}
`;

/**
 * __useQueryQaQualificationByIdQuery__
 *
 * To run a query within a React component, call `useQueryQaQualificationByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryQaQualificationByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryQaQualificationByIdQuery({
 *   variables: {
 *      qaQualificationId: // value for 'qaQualificationId'
 *   },
 * });
 */
export function useQueryQaQualificationByIdQuery(
  baseOptions: Apollo.QueryHookOptions<QueryQaQualificationByIdQuery, QueryQaQualificationByIdQueryVariables>
) {
  return Apollo.useQuery<QueryQaQualificationByIdQuery, QueryQaQualificationByIdQueryVariables>(
    QueryQaQualificationByIdDocument,
    baseOptions
  );
}
export function useQueryQaQualificationByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryQaQualificationByIdQuery, QueryQaQualificationByIdQueryVariables>
) {
  return Apollo.useLazyQuery<QueryQaQualificationByIdQuery, QueryQaQualificationByIdQueryVariables>(
    QueryQaQualificationByIdDocument,
    baseOptions
  );
}
export type QueryQaQualificationByIdQueryHookResult = ReturnType<typeof useQueryQaQualificationByIdQuery>;
export type QueryQaQualificationByIdLazyQueryHookResult = ReturnType<typeof useQueryQaQualificationByIdLazyQuery>;
export type QueryQaQualificationByIdQueryResult = Apollo.QueryResult<
  QueryQaQualificationByIdQuery,
  QueryQaQualificationByIdQueryVariables
>;
export const QueryQaQualificationObjectsDocument = gql`
  query queryQaQualificationObjects(
    $distinct_on: [qaQualification_select_column!]
    $where: qaQualification_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [qaQualification_order_by!]
  ) {
    qaQualification(distinct_on: $distinct_on, where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      ...QaQualification
    }
  }
  ${QaQualificationFragmentDoc}
`;

/**
 * __useQueryQaQualificationObjectsQuery__
 *
 * To run a query within a React component, call `useQueryQaQualificationObjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryQaQualificationObjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryQaQualificationObjectsQuery({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useQueryQaQualificationObjectsQuery(
  baseOptions?: Apollo.QueryHookOptions<QueryQaQualificationObjectsQuery, QueryQaQualificationObjectsQueryVariables>
) {
  return Apollo.useQuery<QueryQaQualificationObjectsQuery, QueryQaQualificationObjectsQueryVariables>(
    QueryQaQualificationObjectsDocument,
    baseOptions
  );
}
export function useQueryQaQualificationObjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueryQaQualificationObjectsQuery, QueryQaQualificationObjectsQueryVariables>
) {
  return Apollo.useLazyQuery<QueryQaQualificationObjectsQuery, QueryQaQualificationObjectsQueryVariables>(
    QueryQaQualificationObjectsDocument,
    baseOptions
  );
}
export type QueryQaQualificationObjectsQueryHookResult = ReturnType<typeof useQueryQaQualificationObjectsQuery>;
export type QueryQaQualificationObjectsLazyQueryHookResult = ReturnType<typeof useQueryQaQualificationObjectsLazyQuery>;
export type QueryQaQualificationObjectsQueryResult = Apollo.QueryResult<
  QueryQaQualificationObjectsQuery,
  QueryQaQualificationObjectsQueryVariables
>;
export const SubscribeToQaQualificationByIdDocument = gql`
  subscription subscribeToQaQualificationById($qaQualificationId: uuid!) {
    qaQualification_by_pk(id: $qaQualificationId) {
      ...QaQualification
    }
  }
  ${QaQualificationFragmentDoc}
`;

/**
 * __useSubscribeToQaQualificationByIdSubscription__
 *
 * To run a query within a React component, call `useSubscribeToQaQualificationByIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToQaQualificationByIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToQaQualificationByIdSubscription({
 *   variables: {
 *      qaQualificationId: // value for 'qaQualificationId'
 *   },
 * });
 */
export function useSubscribeToQaQualificationByIdSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    SubscribeToQaQualificationByIdSubscription,
    SubscribeToQaQualificationByIdSubscriptionVariables
  >
) {
  return Apollo.useSubscription<
    SubscribeToQaQualificationByIdSubscription,
    SubscribeToQaQualificationByIdSubscriptionVariables
  >(SubscribeToQaQualificationByIdDocument, baseOptions);
}
export type SubscribeToQaQualificationByIdSubscriptionHookResult = ReturnType<
  typeof useSubscribeToQaQualificationByIdSubscription
>;
export type SubscribeToQaQualificationByIdSubscriptionResult = Apollo.SubscriptionResult<SubscribeToQaQualificationByIdSubscription>;
export const SubscribeToQaQualificationObjectsDocument = gql`
  subscription subscribeToQaQualificationObjects(
    $distinct_on: [qaQualification_select_column!]
    $where: qaQualification_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [qaQualification_order_by!]
  ) {
    qaQualification(distinct_on: $distinct_on, where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      ...QaQualification
    }
  }
  ${QaQualificationFragmentDoc}
`;

/**
 * __useSubscribeToQaQualificationObjectsSubscription__
 *
 * To run a query within a React component, call `useSubscribeToQaQualificationObjectsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToQaQualificationObjectsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToQaQualificationObjectsSubscription({
 *   variables: {
 *      distinct_on: // value for 'distinct_on'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useSubscribeToQaQualificationObjectsSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    SubscribeToQaQualificationObjectsSubscription,
    SubscribeToQaQualificationObjectsSubscriptionVariables
  >
) {
  return Apollo.useSubscription<
    SubscribeToQaQualificationObjectsSubscription,
    SubscribeToQaQualificationObjectsSubscriptionVariables
  >(SubscribeToQaQualificationObjectsDocument, baseOptions);
}
export type SubscribeToQaQualificationObjectsSubscriptionHookResult = ReturnType<
  typeof useSubscribeToQaQualificationObjectsSubscription
>;
export type SubscribeToQaQualificationObjectsSubscriptionResult = Apollo.SubscriptionResult<SubscribeToQaQualificationObjectsSubscription>;
export const InsertQaQualificationDocument = gql`
  mutation insertQaQualification($objects: [qaQualification_insert_input!]!) {
    insert_qaQualification(objects: $objects) {
      affected_rows
      returning {
        ...QaQualification
      }
    }
  }
  ${QaQualificationFragmentDoc}
`;
export type InsertQaQualificationMutationFn = Apollo.MutationFunction<
  InsertQaQualificationMutation,
  InsertQaQualificationMutationVariables
>;

/**
 * __useInsertQaQualificationMutation__
 *
 * To run a mutation, you first call `useInsertQaQualificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertQaQualificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertQaQualificationMutation, { data, loading, error }] = useInsertQaQualificationMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertQaQualificationMutation(
  baseOptions?: Apollo.MutationHookOptions<InsertQaQualificationMutation, InsertQaQualificationMutationVariables>
) {
  return Apollo.useMutation<InsertQaQualificationMutation, InsertQaQualificationMutationVariables>(
    InsertQaQualificationDocument,
    baseOptions
  );
}
export type InsertQaQualificationMutationHookResult = ReturnType<typeof useInsertQaQualificationMutation>;
export type InsertQaQualificationMutationResult = Apollo.MutationResult<InsertQaQualificationMutation>;
export type InsertQaQualificationMutationOptions = Apollo.BaseMutationOptions<
  InsertQaQualificationMutation,
  InsertQaQualificationMutationVariables
>;
export const InsertQaQualificationWithOnConflictDocument = gql`
  mutation insertQaQualificationWithOnConflict(
    $objects: [qaQualification_insert_input!]!
    $onConflict: qaQualification_on_conflict
  ) {
    insert_qaQualification(objects: $objects, on_conflict: $onConflict) {
      affected_rows
      returning {
        ...QaQualification
      }
    }
  }
  ${QaQualificationFragmentDoc}
`;
export type InsertQaQualificationWithOnConflictMutationFn = Apollo.MutationFunction<
  InsertQaQualificationWithOnConflictMutation,
  InsertQaQualificationWithOnConflictMutationVariables
>;

/**
 * __useInsertQaQualificationWithOnConflictMutation__
 *
 * To run a mutation, you first call `useInsertQaQualificationWithOnConflictMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertQaQualificationWithOnConflictMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertQaQualificationWithOnConflictMutation, { data, loading, error }] = useInsertQaQualificationWithOnConflictMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *      onConflict: // value for 'onConflict'
 *   },
 * });
 */
export function useInsertQaQualificationWithOnConflictMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertQaQualificationWithOnConflictMutation,
    InsertQaQualificationWithOnConflictMutationVariables
  >
) {
  return Apollo.useMutation<
    InsertQaQualificationWithOnConflictMutation,
    InsertQaQualificationWithOnConflictMutationVariables
  >(InsertQaQualificationWithOnConflictDocument, baseOptions);
}
export type InsertQaQualificationWithOnConflictMutationHookResult = ReturnType<
  typeof useInsertQaQualificationWithOnConflictMutation
>;
export type InsertQaQualificationWithOnConflictMutationResult = Apollo.MutationResult<InsertQaQualificationWithOnConflictMutation>;
export type InsertQaQualificationWithOnConflictMutationOptions = Apollo.BaseMutationOptions<
  InsertQaQualificationWithOnConflictMutation,
  InsertQaQualificationWithOnConflictMutationVariables
>;
export const UpdateQaQualificationByIdDocument = gql`
  mutation updateQaQualificationById($id: uuid, $set: qaQualification_set_input) {
    update_qaQualification(_set: $set, where: { id: { _eq: $id } }) {
      affected_rows
      returning {
        ...QaQualification
      }
    }
  }
  ${QaQualificationFragmentDoc}
`;
export type UpdateQaQualificationByIdMutationFn = Apollo.MutationFunction<
  UpdateQaQualificationByIdMutation,
  UpdateQaQualificationByIdMutationVariables
>;

/**
 * __useUpdateQaQualificationByIdMutation__
 *
 * To run a mutation, you first call `useUpdateQaQualificationByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQaQualificationByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQaQualificationByIdMutation, { data, loading, error }] = useUpdateQaQualificationByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateQaQualificationByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateQaQualificationByIdMutation,
    UpdateQaQualificationByIdMutationVariables
  >
) {
  return Apollo.useMutation<UpdateQaQualificationByIdMutation, UpdateQaQualificationByIdMutationVariables>(
    UpdateQaQualificationByIdDocument,
    baseOptions
  );
}
export type UpdateQaQualificationByIdMutationHookResult = ReturnType<typeof useUpdateQaQualificationByIdMutation>;
export type UpdateQaQualificationByIdMutationResult = Apollo.MutationResult<UpdateQaQualificationByIdMutation>;
export type UpdateQaQualificationByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateQaQualificationByIdMutation,
  UpdateQaQualificationByIdMutationVariables
>;
export const UpdateQaQualificationDocument = gql`
  mutation updateQaQualification($set: qaQualification_set_input, $where: qaQualification_bool_exp!) {
    update_qaQualification(_set: $set, where: $where) {
      affected_rows
      returning {
        ...QaQualification
      }
    }
  }
  ${QaQualificationFragmentDoc}
`;
export type UpdateQaQualificationMutationFn = Apollo.MutationFunction<
  UpdateQaQualificationMutation,
  UpdateQaQualificationMutationVariables
>;

/**
 * __useUpdateQaQualificationMutation__
 *
 * To run a mutation, you first call `useUpdateQaQualificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQaQualificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQaQualificationMutation, { data, loading, error }] = useUpdateQaQualificationMutation({
 *   variables: {
 *      set: // value for 'set'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateQaQualificationMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateQaQualificationMutation, UpdateQaQualificationMutationVariables>
) {
  return Apollo.useMutation<UpdateQaQualificationMutation, UpdateQaQualificationMutationVariables>(
    UpdateQaQualificationDocument,
    baseOptions
  );
}
export type UpdateQaQualificationMutationHookResult = ReturnType<typeof useUpdateQaQualificationMutation>;
export type UpdateQaQualificationMutationResult = Apollo.MutationResult<UpdateQaQualificationMutation>;
export type UpdateQaQualificationMutationOptions = Apollo.BaseMutationOptions<
  UpdateQaQualificationMutation,
  UpdateQaQualificationMutationVariables
>;
export const RemoveQaQualificationModelByIdDocument = gql`
  mutation removeQaQualificationModelById($id: uuid) {
    delete_qaQualification(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
export type RemoveQaQualificationModelByIdMutationFn = Apollo.MutationFunction<
  RemoveQaQualificationModelByIdMutation,
  RemoveQaQualificationModelByIdMutationVariables
>;

/**
 * __useRemoveQaQualificationModelByIdMutation__
 *
 * To run a mutation, you first call `useRemoveQaQualificationModelByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveQaQualificationModelByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeQaQualificationModelByIdMutation, { data, loading, error }] = useRemoveQaQualificationModelByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveQaQualificationModelByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveQaQualificationModelByIdMutation,
    RemoveQaQualificationModelByIdMutationVariables
  >
) {
  return Apollo.useMutation<RemoveQaQualificationModelByIdMutation, RemoveQaQualificationModelByIdMutationVariables>(
    RemoveQaQualificationModelByIdDocument,
    baseOptions
  );
}
export type RemoveQaQualificationModelByIdMutationHookResult = ReturnType<
  typeof useRemoveQaQualificationModelByIdMutation
>;
export type RemoveQaQualificationModelByIdMutationResult = Apollo.MutationResult<RemoveQaQualificationModelByIdMutation>;
export type RemoveQaQualificationModelByIdMutationOptions = Apollo.BaseMutationOptions<
  RemoveQaQualificationModelByIdMutation,
  RemoveQaQualificationModelByIdMutationVariables
>;
export const RemoveQaQualificationModelDocument = gql`
  mutation removeQaQualificationModel($where: qaQualification_bool_exp!) {
    delete_qaQualification(where: $where) {
      affected_rows
    }
  }
`;
export type RemoveQaQualificationModelMutationFn = Apollo.MutationFunction<
  RemoveQaQualificationModelMutation,
  RemoveQaQualificationModelMutationVariables
>;

/**
 * __useRemoveQaQualificationModelMutation__
 *
 * To run a mutation, you first call `useRemoveQaQualificationModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveQaQualificationModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeQaQualificationModelMutation, { data, loading, error }] = useRemoveQaQualificationModelMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useRemoveQaQualificationModelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveQaQualificationModelMutation,
    RemoveQaQualificationModelMutationVariables
  >
) {
  return Apollo.useMutation<RemoveQaQualificationModelMutation, RemoveQaQualificationModelMutationVariables>(
    RemoveQaQualificationModelDocument,
    baseOptions
  );
}
export type RemoveQaQualificationModelMutationHookResult = ReturnType<typeof useRemoveQaQualificationModelMutation>;
export type RemoveQaQualificationModelMutationResult = Apollo.MutationResult<RemoveQaQualificationModelMutation>;
export type RemoveQaQualificationModelMutationOptions = Apollo.BaseMutationOptions<
  RemoveQaQualificationModelMutation,
  RemoveQaQualificationModelMutationVariables
>;
