import {
  Box, 
  Button,
  Grid,
  Typography, 
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { useContext } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

import { LoadingAndAlertContext } from '../../..';
import { ClientContactExternalFragment, } from '../../../graphql';
import { AlertSeverity, WarmlyColor, } from '../../../utils/constants';
import { LightTooltip } from './NewJobChangeCrmAction';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    emailTextButton: {
      textTransform: 'none',
      padding: '0px',
      color: WarmlyColor.GRAY,
    },
  }),
);

const MAX_DISPLAY_LENGTH = 40;

enum EmailStatus {
  VALIDATED = '✅',
  NOT_VALIDATED = '(not validated)',
  OUTDATED = '(outdated)',
}

export const removeEmailAdditions = (email: string): string => {
  Object.values(EmailStatus).forEach(status => {
    if (email.includes(status)) {
      email = email.replace(status, '');
    }
  });
  return email.trim();
};

export const compressEmailString = (email: string): string => {
  if (email.length > MAX_DISPLAY_LENGTH) {
    let lengthBeforeEllipses = MAX_DISPLAY_LENGTH - 3;
    let startIndexAfterEllipses = email.length;
    Object.values(EmailStatus).forEach(status => {
      if (email.includes(status)) {
        lengthBeforeEllipses -= status.length;
        startIndexAfterEllipses -= status.length;
      }
    });
    return email.substr(0, lengthBeforeEllipses) + '...' + email.substr(startIndexAfterEllipses);
  }
  return email;
}

interface EmailProps {
  email: string,
  collapseExpanded?: Boolean,
}

const NewJobChangeEmail: React.FC<EmailProps> = ({ email, collapseExpanded }) => {
  const { setSnackbarAlertData } = useContext(LoadingAndAlertContext);

  const classes = useStyles();

  return (
     email === 'no email detected' ? (
      <Box fontStyle="italic">{email}</Box>
    ) : (
        <CopyToClipboard
          text={removeEmailAdditions(email) || ''}
          onCopy={() => {
            if (email) {
              const alertData: AlertData = {
                severity: AlertSeverity.SUCCESS,
                message: `${removeEmailAdditions(email)} copied to clipboard!`,
              };
              setSnackbarAlertData(alertData);
            }
          }}
        >
          <LightTooltip title={email.length > MAX_DISPLAY_LENGTH ? email : ''}>
          <Button 
            className={classes.emailTextButton} 
            aria-label="Click to copy email"
          >
            <Typography variant="subtitle1">
              <Box fontSize="0.65rem" fontWeight={collapseExpanded ? 'bold': ''}>{compressEmailString(email)}</Box>
            </Typography>
          </Button>
          </LightTooltip>
        </CopyToClipboard>
    )
  );
};

interface OtherEmailsProps {
  clientContact: ClientContactExternalFragment;
  primaryEmail: string;
}

const NewJobChangeOtherEmails: React.FC<OtherEmailsProps> = ({ clientContact, primaryEmail }) => {

  let emails: string[] = [];

  if (clientContact.contactData?.contactEmails?.length) {
    if (clientContact.crmEmail) {
      emails.push(
        `${clientContact.crmEmail} ${
          clientContact.crmEmailIsValid === false ? EmailStatus.OUTDATED : clientContact.crmEmailIsValid === true ? ` ${EmailStatus.VALIDATED}` : ''}`
      );
    }

    emails = emails.concat(
      clientContact.contactData.contactEmails.map((email) => {
        return email.isValid ? (
          `${email.email} ${EmailStatus.VALIDATED}`
        ) : email.isValid === false ? (
          `${email.email} ${EmailStatus.OUTDATED}`
        ) : (
          `${email.email} ${EmailStatus.NOT_VALIDATED}`
        );
      })
    );
  } else if (clientContact.crmEmail) {
    emails.push(
      `${clientContact.crmEmail} ${
        clientContact.crmEmailIsValid === false ? EmailStatus.OUTDATED : clientContact.crmEmailIsValid === true ? ` ${EmailStatus.VALIDATED}` : ''
      }`
    );
  }

  // Do not show the primary email that is already displayed 
  emails = emails.filter(email => email.trim() !== primaryEmail.trim());

  return (
    <Grid container item direction="column" justify="center">
      <Grid item>
        <Box fontWeight="bold">Other emails</Box>
      </Grid>
      { emails.length > 0 ? 
        emails.map((email) => (
          <Grid item key={email}><NewJobChangeEmail email={email} /></Grid>
        )) 
        : <Box fontStyle="italic">none</Box>
      }
    </Grid>
  );
};

export { NewJobChangeEmail, NewJobChangeOtherEmails };
