import React, { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';

import { firebaseAuth } from '../../config/firebase';

export const AuthContext = React.createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const [authUser, loadingAuth, error] = useAuthState(firebaseAuth);
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    if (authUser) {
      setErrorMessage('');
    } else if (!loadingAuth && error) {
      setErrorMessage('An error occurred during authentication, please verifiy your credentials');
    }
  }, [authUser, loadingAuth, error]);

  return (
    <AuthContext.Provider
      value={{
        authUser: authUser || undefined,
        errorMessage,
        loadingAuth,
      }}
    >
      {!loadingAuth && children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
