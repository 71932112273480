import firebase from 'firebase/app';

const cloudStorageRef = firebase.storage().ref();

export const uploadFileToCloudStorage = async (file: File, folder: string, subFolder: string) => {
  const currentDate = new Date();

  // We leverage the toISOString method to append a timestamp to the upload in the format of
  // YYYY-MM-DDTHH-MM-SS
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toISOString
  const currentDateFormattedString = currentDate.toISOString().split('.')[0].replace(/:/g, '-');

  const fileName = currentDateFormattedString + ' ' + file.name;

  const uploadPath = `${folder}/${subFolder}/${fileName}`;

  const fileRef = cloudStorageRef.child(uploadPath);

  const fileSnapshot = await fileRef.put(file);

  const filePath = fileSnapshot.ref.fullPath;

  return { filePath, fileName };
};
