import { 
  Box, 
  Button,
  Checkbox,
  DialogActions,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton, 
  Popover,
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import FilterListIcon from '@material-ui/icons/FilterList';
import React, { useState } from 'react';

import { QAJobChangeStatus } from '../../../utils/constants';

const useStyles = makeStyles(() =>
  createStyles({
    filterIconButton: {
      padding: '5px',
    },
  }),
);

interface Props {
  filter?: JobChangeFilter;
  handleAddOrUpdateFilter: (filter: JobChangeFilter) => void;
  handleClearFilter: (filter: JobChangeFilter) => void;
}

const FilterByJobUpdateTypePopover: React.FC<Props> = ({ filter, handleAddOrUpdateFilter, handleClearFilter }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  let currCheckedState = {
    newCompany: true,
    newJobTitle: true,
    inBetweenJobs: true,
  }
  // Map of checkedState keys to currentJobChange status
  const jobUpdateTypeMap: Record<string, QAJobChangeStatus> = {
    'inBetweenJobs': QAJobChangeStatus.LEFT_OLD_JOB_NO_NEW_JOB,
    'newCompany': QAJobChangeStatus.JOB_CHANGED,
    'newJobTitle': QAJobChangeStatus.JOB_CHANGED_SAME_COMPANY,
  };
  // Set the initial checked state to the current filter conditions
  if (filter && filter.jobChangeTypes) {
    for (const jobUpdateType in currCheckedState ) {
      if (!filter.jobChangeTypes.includes(jobUpdateTypeMap[jobUpdateType])) {
        currCheckedState[jobUpdateType] = false;
      }
    }
  }
  const [checkedState, setCheckedState] = useState(currCheckedState);
  const classes = useStyles();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setCheckedState(currCheckedState);
    setAnchorEl(null);
  };

  const onResetChecked = () => {
    let resetCheckedState = {...checkedState};
    Object.keys(checkedState).forEach((jobUpdateType) => {
      resetCheckedState[jobUpdateType] = true;
    });
    setCheckedState(resetCheckedState);
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedState({ ...checkedState, [event.target.name]: event.target.checked });
  };
 
  const onClickApply = () => {
    let allChecked = true;
    for (const jobUpdateType in checkedState) {
      if (checkedState[jobUpdateType] === false) {
        allChecked = false;
        break;
      }
    }
    if (allChecked) {
      if (!filter) {
        setAnchorEl(null);
        return;
      } else {
        handleClearFilter(filter);
      }
    } else {
      const checkedJobUpdateTypes = Object.keys(checkedState).filter((jobUpdateType) =>
        checkedState[jobUpdateType] === true
      ).map((checkedJobUpdateType) =>
        jobUpdateTypeMap[checkedJobUpdateType]
      );

      handleAddOrUpdateFilter({
        jobChangeTypes: checkedJobUpdateTypes,
      });
    }
    setAnchorEl(null);
  }

  const { newCompany, newJobTitle, inBetweenJobs } = checkedState;
  const error = [newCompany, newJobTitle, inBetweenJobs].filter((v) => v).length < 1;

  const open = Boolean(anchorEl);
  const id = open ? 'filter-by-job-update-type-popover' : undefined;

  return (
    <Box>
      <IconButton aria-label="filter" onClick={handleClick} className={classes.filterIconButton}>
        <FilterListIcon fontSize="small" />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box padding={1}>
        <FormControl component="fieldset" error={error}>
            <Box>Filter by type of job update</Box>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox 
                  checked={newCompany} 
                  onChange={handleChange} 
                  name="newCompany"
                  color="primary"
                />
              }
              label="🏢New company"
            />
            <FormControlLabel
              control={
                <Checkbox 
                  checked={newJobTitle} 
                  onChange={handleChange} 
                  name="newJobTitle"
                  color="primary"
                />
              }              
              label="🙌New job title"
            />
            <FormControlLabel
              control={
                <Checkbox 
                  checked={inBetweenJobs} 
                  onChange={handleChange} 
                  name="inBetweenJobs"
                  color="primary"
                />
              }
              label="💼In between jobs"
            />
          </FormGroup>
          {error && <FormHelperText>Pick at least one job update type</FormHelperText>}
        </FormControl>
        </Box>
        <Grid container justify="center">
          <Grid item>
            <DialogActions>
              <Button variant="outlined" autoFocus onClick={onResetChecked} color="primary">
                Reset
              </Button>
              <Button variant="contained" onClick={onClickApply} color="primary">
                Apply
              </Button>
            </DialogActions>
          </Grid>
        </Grid>
      </Popover>
    </Box>

  );
}

export default FilterByJobUpdateTypePopover;