import { Box, Button, CircularProgress, Link, makeStyles, Tooltip, withStyles, } from '@material-ui/core';
import React, { useState } from 'react';

import FirstTimeApproveModal from '../../../components/crm/FirstTimeApproveModal';
import { ClientContactExternalFragment, ClientExternalFragment } from '../../../graphql';
import { IntegrationType, WarmlyColor } from '../../../utils/constants';

const useStyles = makeStyles((theme) => ({
  doneButton: {
    fontSize: '0.75rem',
    padding: '2px 2px',
    minWidth: 40,
    marginBottom: 2,
    boxShadow: 'none',
  },
  notUsefulLink: {
    color: WarmlyColor.GRAY,
    cursor: 'pointer',
    fontSize: '0.65rem',
  },
}));

export const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 12,
  },
}))(Tooltip);

interface Props {
  client: ClientExternalFragment;
  clientContact: ClientContactExternalFragment;
  onClickApprove: () => void;
  onClickDismiss: () => void;
  isLoading: boolean;
}

const NewJobChangeCrmAction: React.FC<Props> = ({
  client,
  clientContact,
  onClickApprove,
  onClickDismiss,
  isLoading,
}) => {
  const classes = useStyles();

  const [showFirstTimeApproveModal, setShowFirstTimeApproveModal] = useState(false);
  const isFirstTimeApprove =
    (client.integrationType === IntegrationType.HubSpot &&
      !client.hubspotIntegration?.warmlyContactPropertiesCreatedAt) ||
    (client.integrationType === IntegrationType.Salesforce &&
      !client.salesforceIntegration?.warmlyContactFieldsCreatedAt);

  // We disable syncing back to CRM if the client contact does not have integration with HubSpot or Salesforce
  const disableCrmSync = (!clientContact.salesforceId && !clientContact.hubspotId) || !client.integrationType;

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      { isLoading ? (<Box><CircularProgress size={18} color="secondary" /></Box>)
        : (
        <Box>
          <LightTooltip
            title={
              disableCrmSync
                ? 'Contact must be synced from a CRM to enable syncing back data'
                : 'Sync new job data from Warmly back to your CRM'
            }
          >
            <Box>
              <Button 
                variant="contained"
                color="primary"
                onClick={() => {
                  isFirstTimeApprove ? setShowFirstTimeApproveModal(true) : onClickApprove();
                }}
                disabled={disableCrmSync}
                className={classes.doneButton}
              >
                {!isLoading && 'Done'}
              </Button>
            </Box>
          </LightTooltip>
          <LightTooltip 
            title="Do not show this specific job change notification again. This person will remain in your Contacts tab."
          >
            <Link underline="always" className={classes.notUsefulLink} onClick={onClickDismiss}>
              {!isLoading && <Box>Not useful</Box>}
            </Link>
          </LightTooltip>
        </Box>
      )}
      {isFirstTimeApprove && (
        <FirstTimeApproveModal
          client={client}
          isOpen={showFirstTimeApproveModal}
          setIsOpen={setShowFirstTimeApproveModal}
          onConfirm={onClickApprove}
        />
      )}
    </Box>
  );
};

export default NewJobChangeCrmAction;
