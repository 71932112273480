import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import React from 'react';

import { ClientSelectFragment } from '../../../graphql';
import { GQLHooks } from '../../../graphql/hasura/react';
import { LocalStorageItem } from '../../../utils/constants';
import { consoleLogDev } from '../../../utils/errors';

interface ClientSelectProps {
  selectedClientId?: string;
  onSelectClientId: (clientId: string) => void;
  useCodeNameOnly?: boolean;
}
const ClientSelect: React.FC<ClientSelectProps> = ({
  selectedClientId = '',
  onSelectClientId,
  useCodeNameOnly = false,
}) => {
  const { objects: clients } = GQLHooks.Fragments.ClientSelect.useQueryObjects({
    variables: {
      where: {},
    },
  });

  let sortedClients: ClientSelectFragment[] = [];

  if (!useCodeNameOnly) {
    sortedClients = clients
      .slice()
      .sort((clientA, clientB) => (clientA.name.toLowerCase() > clientB.name.toLowerCase() ? 1 : -1));
  } else {
    sortedClients = clients
      .slice()
      .sort((clientA, clientB) =>
        (clientA.codeName || '').toLowerCase() > (clientB.codeName || '').toLowerCase() ? 1 : -1
      );
  }

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedId = event.target.value as string;
    consoleLogDev(`Selected Client ID`, selectedId);
    onSelectClientId(selectedId);

    window.localStorage.setItem(LocalStorageItem.LAST_SELECTED_CLIENT_ID, selectedId);
  };

  if (!clients.find((client) => client.id === selectedClientId)) {
    selectedClientId = '';
  }

  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel id="select-client-dropdown-label">Current Client</InputLabel>
      <Select
        labelId="select-client-dropdown-label"
        value={selectedClientId}
        onChange={handleChange}
        label="Current Client"
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {sortedClients.map((client) => {
          const codeName = client.codeName || 'Name unknown';
          return (
            <MenuItem key={client.id} value={client.id}>
              {useCodeNameOnly ? codeName : `${client.name} (${codeName})` || ''}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default ClientSelect;
