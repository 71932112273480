import 'react-dropdown/style.css';

import { TableCell, TableRow } from '@material-ui/core';
import React, { useState } from 'react';
import ReactDropdown, { Option } from 'react-dropdown';

import { ClientContactAttribute, ClientContactAttributeMetaData } from '../../../utils/constants';

export interface RowData {
  headerName: string;
  attributeData: string[];
  attribute: ClientContactAttribute;
}

interface AttributeRowProps {
  rowData: RowData;
  onChangeOption: (option: Option) => void;
}

const dropdownOptions: Option[] = Object.keys(ClientContactAttributeMetaData).map((attribute) => ({
  label: ClientContactAttributeMetaData[attribute].displayText,
  value: attribute,
}));

const AttributeRow: React.FC<AttributeRowProps> = ({
  rowData: { headerName, attributeData, attribute },
  onChangeOption,
}) => {
  const [selectedValue, setSelectedValue] = useState<ClientContactAttribute>(attribute);

  const onChange = (option: Option) => {
    setSelectedValue(option.value as ClientContactAttribute);
    onChangeOption(option);
  };

  return (
    <TableRow hover tabIndex={-1}>
      <TableCell>{headerName}</TableCell>
      <TableCell>
        {attributeData.slice(0, 3).map((data, index) => (
          <div key={index}>{data}</div>
        ))}
      </TableCell>
      <TableCell>
        <ReactDropdown
          options={dropdownOptions}
          onChange={onChange}
          value={selectedValue}
          placeholder="Select an option"
        />
      </TableCell>
    </TableRow>
  );
};

export default AttributeRow;
