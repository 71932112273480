import { Box, Chip, CircularProgress, Grid, IconButton, makeStyles, TextField, Typography } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AddIcon from '@material-ui/icons/Add';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RemoveIcon from '@material-ui/icons/Remove';
import React, { useContext, useMemo, useState } from 'react';

import { LoadingAndAlertContext } from '../../..';
import { ClientTerritoryFragment, UserFragment } from '../../../graphql';
import { GQLHooks } from '../../../graphql/hasura/react';
import { mapTerritoryForUser } from '../../../modules/cloudFunctions';
import { AlertSeverity } from '../../../utils/constants';
import { consoleLogDev, getApiErrorMessage } from '../../../utils/errors';
import TerritoryAutocomplete from './TerritoryAutocomplete';
import TerritoryOwnerRemoveModal from './TerritoryOwnerRemoveModal';

const FIXED_HEIGHT = 40;

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    height: FIXED_HEIGHT,
  },
  heading: {
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    flexBasis: '66.66%',
    color: theme.palette.text.secondary,
  },

  thirdHeading: {
    flexBasis: '66.66%',
    color: theme.palette.text.secondary,
  },
  row: {
    height: '100%',
  },
  rowItem: {
    height: FIXED_HEIGHT,
    borderRight: 'solid 2px',
    borderRightColor: theme.palette.background.default,
  },
  avatar: {
    height: FIXED_HEIGHT,
    width: FIXED_HEIGHT,
  },
}));

interface Props {
  user: UserFragment;
  isAdmin: boolean;
  clientTerritories: ClientTerritoryFragment[];
  refetchClientTerritories: () => Promise<{}>;
}

const TerritoryOwnerMappingItem: React.FC<Props> = ({ user, isAdmin, clientTerritories, refetchClientTerritories }) => {
  const clientTerritory = clientTerritories.find(territory => territory.userId === user.id);
  const initialCompanySizeMin = clientTerritory?.companySizeMin || '';
  const initialCompanySizeMax = clientTerritory?.companySizeMax || '';
  const { setSnackbarAlertData } = useContext(LoadingAndAlertContext);
  const [isUpdatingOwnerMapping, setIsUpdatingOwnerMapping] = useState(false);
  const [showTerritoryRemoveModal, setShowTerritoryRemoveModal] = useState(false);
  const [companySizeMin, setCompanySizeMin] = useState(initialCompanySizeMin);
  const [companySizeMax, setCompanySizeMax] = useState(initialCompanySizeMax);
  const [territoryToRemove, setTerritoryToRemove] = useState<ClientTerritoryFragment>();

  const [updateClientTerritories] = GQLHooks.Fragments.ClientTerritory.useUpdateObjects();

  const classes = useStyles();

  const companySizeChanged =
    companySizeMin.toString() !== initialCompanySizeMin.toString() ||
    companySizeMax.toString() !== initialCompanySizeMax.toString();

  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange = (panel: string) => (_event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const onClickUpdateCompanySize = async () => {
    if (!isAdmin) {
      return;
    }
    const companySizeMinUpdated = companySizeMin ? +companySizeMin : null;
    const companySizeMaxUpdated = companySizeMax ? +companySizeMax : null;

    if (companySizeMinUpdated && companySizeMaxUpdated && companySizeMaxUpdated <= companySizeMinUpdated) {
      setSnackbarAlertData({
        severity: AlertSeverity.ERROR,
        message: 'Max must be greater than min',
      });
    } else {
      try {
        setIsUpdatingOwnerMapping(true);
        await updateClientTerritories({
          variables: {
            set: {
              companySizeMax: companySizeMaxUpdated,
              companySizeMin: companySizeMinUpdated,
            },
            where: {
              userId: {
                _eq: user.id,
              },
            },
          },
        });

        await refetchClientTerritories();

        setSnackbarAlertData({
          severity: AlertSeverity.SUCCESS,
          message: 'Company size updated',
        });
      } catch (err) {
        consoleLogDev(err);
        const errorMessage = getApiErrorMessage(err);
        setSnackbarAlertData({
          severity: AlertSeverity.ERROR,
          message: errorMessage,
        });
      } finally {
        setIsUpdatingOwnerMapping(false);
      }
    }
  };

  const onSelectPlace = async (place: google.maps.places.AutocompletePrediction) => {
    if (!isAdmin) {
      return;
    }
    if (place) {
      try {
        setIsUpdatingOwnerMapping(true);
        await mapTerritoryForUser({
          userId: user.id,
          placeId: place.place_id,
        });

        await refetchClientTerritories();

        setSnackbarAlertData({
          severity: AlertSeverity.SUCCESS,
          message: 'Territory successfully mapped',
        });
      } catch (err) {
        consoleLogDev(err);
        const errorMessage = getApiErrorMessage(err);
        setSnackbarAlertData({
          severity: AlertSeverity.ERROR,
          message: errorMessage,
        });
      } finally {
        setIsUpdatingOwnerMapping(false);
      }
    }
  };

  const territoryItems = useMemo(() => {
    const onClickRemove = (clientTerritory: ClientTerritoryFragment) => {
      if (!isAdmin) {
        return;
      }

      setShowTerritoryRemoveModal(true);
      setTerritoryToRemove(clientTerritory);
    };

    return clientTerritories
      .filter((clientTerritory) => clientTerritory.userId === user.id)
      .map((clientTerritory) => {
        return (
          <Box display="inline-block" margin={1} key={clientTerritory.id}>
            <Chip
              id={clientTerritory.id}
              variant="outlined"
              size="medium"
              label={clientTerritory.territory.formattedAddress}
              onDelete={isAdmin ? () => onClickRemove(clientTerritory) : undefined}
              color="primary"
            />
          </Box>
        );
      });
  }, [clientTerritories, user, isAdmin]);

  const displayName = user.firstName ? `${user.firstName} ${user.lastName}` : user.email;
  return (
    <Grid container spacing={2} style={{ marginBottom: 3 }}>
      <Grid container item xs={12}>
        <div className={classes.root}>
          <Accordion expanded={expanded === 'panel1'} onChange={isAdmin ? handleChange('panel1') : undefined}>
            <AccordionSummary
              expandIcon={isAdmin ? <ExpandMoreIcon /> : undefined}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Grid container justify="space-between" alignItems="center">
                <Grid item xs={2}>
                  <Box marginBottom={1}>
                    <Typography>{displayName}</Typography>
                  </Box>
                </Grid>
                <Grid container item xs={7}>
                  {territoryItems}
                  {isAdmin ? (
                    <Box display="inline-block" margin={1}>
                      <Chip
                        variant="outlined"
                        size="medium"
                        icon={<AddIcon />}
                        label={'Add Territory'}
                        color="primary"
                        clickable
                      />
                    </Box>
                  ) : null}
                </Grid>
                <Grid container item xs={3}>
                  {clientTerritories.length ? (
                    <>
                      <Grid item xs={12}>
                        <Box fontStyle="italic">
                          <Typography variant="subtitle1">Company Size</Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={3}>
                        <Box textAlign="center">
                          <Typography>
                            {initialCompanySizeMin ? initialCompanySizeMin.toLocaleString() : 'no min'}
                          </Typography>
                        </Box>
                      </Grid>
                      <RemoveIcon />
                      <Grid item xs={3}>
                        <Box textAlign="center">
                          <Typography>
                            {initialCompanySizeMax ? initialCompanySizeMax.toLocaleString() : 'no max'}
                          </Typography>
                        </Box>
                      </Grid>
                    </>
                  ) : null}
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Grid item xs={2}></Grid>
              <Grid container item xs={7} alignItems="center" spacing={2}>
                <Grid item>
                  <TerritoryAutocomplete onSelectPlace={onSelectPlace} />
                </Grid>
                <Grid item>{isUpdatingOwnerMapping && <CircularProgress />}</Grid>
              </Grid>
              <Grid container item xs={3} justify="space-between" alignItems="center">
                {clientTerritories.length ? (
                  <>
                    <Grid item xs={4}>
                      <TextField
                        label="Company size - MIN"
                        type="number"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={companySizeMin}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          setCompanySizeMin(event.target.value);
                        }}
                      />
                    </Grid>
                    <RemoveIcon />
                    <Grid item xs={4}>
                      <TextField
                        label="Company size - MAX"
                        type="number"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          setCompanySizeMax(event.target.value);
                        }}
                        value={companySizeMax}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton
                        aria-label="confirm"
                        disabled={!companySizeChanged || isUpdatingOwnerMapping}
                        color="primary"
                        onClick={onClickUpdateCompanySize}
                      >
                        {isUpdatingOwnerMapping ? <CircularProgress size={25} /> : <CheckCircleIcon />}
                      </IconButton>
                    </Grid>
                  </>
                ) : null}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </div>
      </Grid>

      {territoryToRemove && isAdmin && (
        <TerritoryOwnerRemoveModal
          isOpen={showTerritoryRemoveModal}
          setIsOpen={setShowTerritoryRemoveModal}
          user={user}
          clientTerritory={territoryToRemove}
          onRemoveSuccess={refetchClientTerritories}
        />
      )}
    </Grid>
  );
};

export default TerritoryOwnerMappingItem;
