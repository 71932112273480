import { Box } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { Route, Switch } from 'react-router-dom';

import AdminRoute from '../components/auth/AdminRoute';
import { UserContext } from '../components/auth/UserRouter';
import Sidebar from '../components/Sidebar';
import { ClientExternalFragment } from '../graphql';
import { GQLHooks } from '../graphql/hasura/react';
import { NavigationPath } from '../utils/constants';
import { consoleLogDev } from '../utils/errors';
import AdminPanel from './dashboard/AdminPanel';
import ContactUs from './dashboard/ContactUs';
import EnrichedCrm from './dashboard/EnrichedCrm';
import JobChanges from './dashboard/JobChanges';
import UploadOrSync from './dashboard/UploadOrSync';
import Users from './dashboard/Users';
import WarmCompanies from './dashboard/WarmCompanies';
import Integration from './Integration';
import HubspotIntegrationSteps from './integration/HubspotIntegrationSteps';
import SalesforceIntegrationSteps from './integration/SalesforceIntegrationSteps';
import Settings from './Settings';

export const ClientContext = React.createContext<ClientContextData>({} as ClientContextData);

const Main: React.FC = () => {
  const { user } = useContext(UserContext);
  const [selectedClientId, setSelectedClientId] = useState(user.clientId);
  const [selectedClient, setSelectedClient] = useState<ClientExternalFragment>();

  // Note that certain Client fields are only returned if the authUser is internal/QA
  // TODO maybe provider error handling for this
  const { error } = GQLHooks.Fragments.ClientExternal.useSubscribeToById({
    clientId: selectedClientId,
    options: {
      shouldResubscribe: true,
      onSubscriptionData: (data) => {
        if (data.subscriptionData.data?.client_by_pk) {
          setSelectedClient(data.subscriptionData.data.client_by_pk);
        }
      },
    },
  });

  if (error) {
    consoleLogDev(error.message);
  }

  return selectedClient ? (
    <ClientContext.Provider
      value={{
        selectedClientId,
        setSelectedClientId,
        selectedClient,
      }}
    >
      <Box display="flex">
        <Sidebar />
        <Box maxWidth="85%" display="flex" flexGrow={1} marginLeft={5} marginTop={6}>
          <Switch>
            <Route exact={true} path={NavigationPath.JOB_CHANGES}>
              <JobChanges />
            </Route>
            <Route exact={true} path={NavigationPath.WARM_COMPANIES}>
              <WarmCompanies />
            </Route>
            <Route exact={true} path={NavigationPath.ENRICHED_CRM}>
              <EnrichedCrm />
            </Route>
            <Route exact={true} path={NavigationPath.UPLOAD}>
              <UploadOrSync />
            </Route>
            <Route exact={true} path={NavigationPath.USERS}>
              <Users />
            </Route>
            <Route exact={true} path={NavigationPath.CONTACT_US}>
              <ContactUs />
            </Route>
            <Route path={NavigationPath.SETTINGS}>
              <Settings />
            </Route>
            <Route exact={true} path={NavigationPath.INTEGRATION_SALESFORCE}>
              <SalesforceIntegrationSteps />
            </Route>
            <Route exact={true} path={NavigationPath.INTEGRATION_HUBSPOT}>
              <HubspotIntegrationSteps />
            </Route>
            <Route path={NavigationPath.INTEGRATION}>
              <Integration />
            </Route>
            <AdminRoute path={NavigationPath.ADMIN}>
              <AdminPanel />
            </AdminRoute>
            <Route>
              <JobChanges />
            </Route>
          </Switch>
        </Box>
      </Box>
    </ClientContext.Provider>
  ) : null;
};

export default Main;
