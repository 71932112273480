import { CURRENT_ENVIRONMENT, Environment } from '../utils/constants';

// NOTE: the Development Hasura URI for web-dashboard is different than
// the backend services, because the backend services are running in Docker
const hasuraUriByEnvironment: Record<Environment, string> = {
  [Environment.Development]: 'http://localhost:5000/hasura/v1/graphql',
  [Environment.Staging]: 'https://hasura-dot-warmly-staging.uc.r.appspot.com/v1/graphql',
  [Environment.Production]: 'https://hasura.getwarmly.com/v1/graphql',
};

const hasuraWsByEnvironment: Record<Environment, string> = {
  [Environment.Development]: 'ws://localhost:5000/hasura/v1/graphql',
  [Environment.Staging]: 'wss://hasura-dot-warmly-staging.uc.r.appspot.com/v1/graphql',
  [Environment.Production]: 'wss://hasura.getwarmly.com/v1/graphql',
};

export const HASURA_URI = hasuraUriByEnvironment[CURRENT_ENVIRONMENT];
export const HASURA_WS = hasuraWsByEnvironment[CURRENT_ENVIRONMENT];
