import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from '@material-ui/core';
import React, { useState } from 'react';

import { ClientNotificationEmailFragment } from '../../../graphql';
import { GQLHooks } from '../../../graphql/hasura/react';

interface Props {
  emailNotification: ClientNotificationEmailFragment;
  refetchEmailNotifications?: () => void;
}

const DeleteNotificationEmail: React.FC<Props> = ({ emailNotification, refetchEmailNotifications }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [deleteNotificationEmail] = GQLHooks.Models.ClientNotificationEmail.useRemoveById();

  const onCloseModal = () => {
    setShowDeleteModal(false);
  };

  const onClickConfirm = async () => {
    try {
      setIsUpdating(true);

      await deleteNotificationEmail({
        clientNotificationEmailId: emailNotification.id,
      });
      refetchEmailNotifications && refetchEmailNotifications();
    } catch {
      // do nothing for now
    } finally {
      setIsUpdating(false);
      onCloseModal();
    }
  };

  return (
    <Box id={emailNotification.email} marginLeft={1}>
      <Grid container justify="space-between" alignItems="center">
        <Grid item>
          <Typography variant="subtitle2">{emailNotification.email}</Typography>
        </Grid>
        <Grid item>
          <Box marginLeft={2} style={{ cursor: 'pointer' }} onClick={() => setShowDeleteModal(true)}>
            <FontAwesomeIcon icon={faTimes} size="sm" />
          </Box>
        </Grid>
      </Grid>

      <Dialog open={showDeleteModal} onClose={onCloseModal} fullWidth={true} maxWidth="sm">
        <DialogTitle>Confirm change</DialogTitle>
        <DialogContent>
          <DialogContentText component="div">
            Remove
            <Box display="inline" marginX={1} color="primary.main">
              {emailNotification.email}
            </Box>
            from job change notifications?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {isUpdating && (
            <Box marginLeft={2}>
              <CircularProgress />
            </Box>
          )}
          <Button onClick={onCloseModal} variant="outlined" color="primary">
            Cancel
          </Button>
          <Button onClick={onClickConfirm} variant="contained" color="primary" disabled={isUpdating} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default DeleteNotificationEmail;
