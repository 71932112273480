import firebase from 'firebase/app';

import { firebaseAuth } from '../config/firebase';

export const getAuthToken = () => {
  return firebaseAuth.currentUser?.getIdToken();
};

export const reauthenticateUser = (user: firebase.User, password: string) => {
  const cred = firebase.auth.EmailAuthProvider.credential(user.email!, password);

  return user.reauthenticateWithCredential(cred);
};

export const changePassword = (user: firebase.User, newPassword: string) => {
  return user.updatePassword(newPassword);
};

export const authUserHasCustomClaims = async (authUser: firebase.User) => {
  const idTokenResult = await authUser.getIdTokenResult();
  const hasuraClaim = idTokenResult.claims['https://hasura.io/jwt/claims'];

  return Boolean(hasuraClaim);
};
