// eslint-disable-next-line simple-import-sort/imports
import { Box, Link } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import React, { useContext, useState } from 'react';
import { LoadingAndAlertContext } from '../../..';
import { logError } from '../../../modules/analytics';

import { assignOwners } from '../../../modules/cloudFunctions';
import { WARMLY_EMAIL, AlertSeverity } from '../../../utils/constants';
import { ClientContext } from '../../Main';

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const ConfirmMappingModal: React.FC<Props> = ({ isOpen, setIsOpen }) => {
  const { selectedClientId } = useContext(ClientContext);
  const [isConfirming, setIsConfirming] = useState(false);
  const { setSnackbarAlertData } = useContext(LoadingAndAlertContext);

  const handleClose = () => {
    setIsOpen(false);
    setIsConfirming(false);
  };

  const onClickConfirm = async () => {
    try {
      await assignOwners(selectedClientId);
      const alertData: AlertData = {
        severity: AlertSeverity.SUCCESS,
        message: `Mapping unassigned job changes`,
      };

      setSnackbarAlertData(alertData);
    } catch (err) {
      logError(err, 'Error while confirming mapping');
      const alertData: AlertData = {
        severity: AlertSeverity.ERROR,
        message: `Error occurred while while confirming mapping, our team has been notified of the issue`,
      };

      setSnackbarAlertData(alertData);
    } finally {
      handleClose();
    }
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogContent>
        <Box marginTop={2}>
          By confirming, we will go through all of your currently unassigned job changes and assigned owners based on
          the latest owner mapping.
        </Box>
        <Box marginTop={2}>
          As always, if you have any questions, please contact us at{' '}
          <Link
            href={`https://mail.google.com/mail/?view=cm&fs=1&to=${WARMLY_EMAIL.CSM}&su=[Warmly] Question about CRM Integration`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {WARMLY_EMAIL.CSM}
          </Link>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined" color="primary">
          Cancel
        </Button>
        <Button onClick={onClickConfirm} variant="contained" color="primary" disabled={isConfirming}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmMappingModal;
