import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Box, Grid, Link, makeStyles, Typography } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

import { LoadingAndAlertContext } from '../../..';
import { UserContext } from '../../../components/auth/UserRouter';
import { ClientContactExternalFragment } from '../../../graphql';
import { setAmplitudeEvent } from '../../../modules/analytics';
import {
  AlertSeverity,
  AmplitudeEventType,
  ClientJobChangeAction,
  JobChangeStatusIconMap,
  JobChangeStatusMap,
  UserRole,
  WarmlyColor,
} from '../../../utils/constants';
import { getClientContactName, getDateStringFromTimestampString } from '../../../utils/functions';
import JobChangeEmail from '../JobChangeEmail';

const BASE_FIXED_HEIGHT = 120;
const useStyles = makeStyles((theme) => ({
  announcement: {
    border: '1px solid #8686ff',
    backgroundColor: '#e8e8ff',
    padding: '12px',
  },
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  avatar: {
    height: BASE_FIXED_HEIGHT - 16,
    width: BASE_FIXED_HEIGHT - 16,
  },
  row: {
    height: '100%',
  },
  rowItem: {
    height: BASE_FIXED_HEIGHT - 16,
  },
  dropDownButton: {
    minWidth: 30,
    padding: 2,
    // marginLeft: 10,
  },
  stickyActionsColumn: {
    '& table:first-child': {
      '& tr': {
        '& td:first-child, th:first-child': {
          backgroundColor: '#f5f5f5',
          position: 'sticky',
          left: 0,
          zIndex: 999,
        },
        '& th:first-child': {
          zIndex: 9999,
        },
      },
    },
  },
}));
const pastJobChangeItemHeaderText = {
  fullName: 'Full Name',
  updated: 'Updated',
  profileImage: 'Profile Image',
  email: 'Email',
  company: 'Company',
  title: 'Title',
  jobUpdate: 'Job Update',
  contactInfo: 'Contact Info',
  crmAction: 'CRM Action',
};

interface JobChangeItemDataProps {
  dataValue: string;
}

const JobChangeItemData: React.FC<JobChangeItemDataProps> = ({ dataValue }) => {
  return (
    <Grid item xs={12}>
      <Box textAlign="center" style={{ overflowWrap: 'anywhere' }}>
        <Typography variant="subtitle1">{dataValue || ''}</Typography>
      </Box>
    </Grid>
  );
};

// TODO the way we currently render headers is very confusion (much strange!), we should make it more clean
const HEADER_HEIGHT = 40;
const JobChangeItemHeader: React.FC<{ header: string }> = ({ header }) => {
  return (
    <Grid container style={{ height: HEADER_HEIGHT }}>
      <Box textAlign="center" fontWeight="bold" width="100%" marginTop="auto" marginBottom={1}>
        {header}
      </Box>
    </Grid>
  );
};
interface Props {
  clientContact: ClientContactExternalFragment;
  showHeaders?: boolean;
}

const PastJobChangeItem: React.FC<Props> = ({ clientContact, showHeaders }) => {
  const { user } = useContext(UserContext);
  const [emailToCopy, setEmailToCopy] = useState(clientContact.crmEmail);
  const { setSnackbarAlertData } = useContext(LoadingAndAlertContext);
  
  const FIXED_HEIGHT = showHeaders ? BASE_FIXED_HEIGHT + HEADER_HEIGHT : BASE_FIXED_HEIGHT;

  const classes = useStyles();

  const jobChange = clientContact.currentJobChange!;

  const jobChangeCompanyName = jobChange.job.companyName;
  const jobChangeTitle = jobChange.job.title;
  const jobChangeStatus = jobChange.status;
  const startDate = jobChange.job.startDateText;
  const endDate = jobChange.job.endDateText;

  // TODO Decide what email to actually show if there are multiple valid emails
  // or if crmEmail is not yet validated
  const contactEmails = clientContact.contactData?.contactEmails;
  const validContactEmailsSortedDesc = contactEmails
    ?.filter((email) => email.isValid === true)
    .sort((emailA, emailB) => {
      return +new Date(emailB.createdAt) - +new Date(emailA.createdAt);
    });

  let email = clientContact.crmEmail;
  let crmEmailIsValid = Boolean(clientContact.crmEmail && clientContact.crmEmailIsValid);

  if (!crmEmailIsValid) {
    const lastestValidContactEmail = validContactEmailsSortedDesc?.[0];

    if (lastestValidContactEmail) {
      email = lastestValidContactEmail.email;
    }
  }

  const linkedinUrl = clientContact.contactData?.linkedinUrl || clientContact.linkedinUrl;

  const onClickLinkedin = () => {
    if (user.role === UserRole.CLIENT) {
      setAmplitudeEvent({
        userId: user.id,
        event: AmplitudeEventType.CLICK_LINKEDIN_PROFILE_ICON,
        eventProperties: {
          linkedin_url: linkedinUrl,
          path_name: window.location.pathname,
        },
      });
    }
  };

  const onClickEmail = (eventType: AmplitudeEventType) => {
    if (user.role === UserRole.CLIENT) {
      // send to amplitude - click email icon or text
      setAmplitudeEvent({
        userId: user.id,
        event: eventType,
        eventProperties: {
          email: email,
          path_name: window.location.pathname,
        },
      });
    }
  };

  const ROW_MARGIN = '2px';

  return (
    <Grid container style={{ width: 1900 }}>
      <Box width={100} height={FIXED_HEIGHT} marginY={ROW_MARGIN}>
        {showHeaders ? <JobChangeItemHeader header={pastJobChangeItemHeaderText.fullName} /> : null}
        <Box height={BASE_FIXED_HEIGHT} bgcolor={WarmlyColor.WHITE} padding={1}>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{ height: BASE_FIXED_HEIGHT - 20 }}
          >
            <Grid item>
              <Box marginRight={1} fontWeight="bold" textAlign="center">
                {getClientContactName(clientContact)}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Box width={100} height={FIXED_HEIGHT} marginY={ROW_MARGIN}>
        {showHeaders ? <JobChangeItemHeader header={pastJobChangeItemHeaderText.updated} /> : null}
        <Box height={BASE_FIXED_HEIGHT} bgcolor={WarmlyColor.WHITE} padding={1}>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{ height: BASE_FIXED_HEIGHT - 20 }}
          >
            <Grid item>
              <Box marginRight={1} textAlign="center">
                {getDateStringFromTimestampString(jobChange.createdAt)}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Box width={100} height={FIXED_HEIGHT} marginY={ROW_MARGIN}>
        {showHeaders ? <JobChangeItemHeader header={pastJobChangeItemHeaderText.profileImage} /> : null}
        <Box height={BASE_FIXED_HEIGHT} bgcolor={WarmlyColor.WHITE} padding={1}>
          <Grid container item justify="center" alignItems="center">
            <Avatar
              variant="square"
              className={classes.avatar}
              src={clientContact.contactData?.profilePhotoUrl || undefined}
            >
              {getClientContactName(clientContact, true)}
            </Avatar>
          </Grid>
        </Box>
      </Box>

      <Box width={250} height={FIXED_HEIGHT} marginY={ROW_MARGIN}>
        {showHeaders ? <JobChangeItemHeader header={pastJobChangeItemHeaderText.email} /> : null}
        <Box height={BASE_FIXED_HEIGHT} bgcolor={WarmlyColor.WHITE} padding={1}>
          <JobChangeEmail 
            clientContact={clientContact} 
            onSelectEmail={setEmailToCopy}
            setAmplitudeEmailClickEvent={onClickEmail}
          />
        </Box>
      </Box>

      <Box width={250} height={FIXED_HEIGHT} marginY={ROW_MARGIN}>
        {showHeaders ? <JobChangeItemHeader header={pastJobChangeItemHeaderText.company} /> : null}
        <Box height={BASE_FIXED_HEIGHT} bgcolor={WarmlyColor.WHITE} padding={1}>
          <Grid container item justify="center" alignItems="center" className={classes.rowItem}>
            <JobChangeItemData dataValue={jobChangeCompanyName || '-'} />
          </Grid>
        </Box>
      </Box>

      <Box width={250} height={FIXED_HEIGHT} marginY={ROW_MARGIN}>
        {showHeaders ? <JobChangeItemHeader header={pastJobChangeItemHeaderText.title} /> : null}
        <Box height={BASE_FIXED_HEIGHT} bgcolor={WarmlyColor.WHITE} padding={1}>
          <Grid container item justify="center" alignItems="center" className={classes.rowItem}>
            <JobChangeItemData dataValue={jobChangeTitle || '-'} />
          </Grid>
        </Box>
      </Box>

      <Box width={250} height={FIXED_HEIGHT} marginY={ROW_MARGIN}>
        {showHeaders ? <JobChangeItemHeader header={pastJobChangeItemHeaderText.jobUpdate} /> : null}
        <Box height={BASE_FIXED_HEIGHT} bgcolor={WarmlyColor.WHITE} padding={1}>
          <Grid
            container
            item
            direction="column"
            justify="center"
            alignItems="center"
            spacing={1}
            style={{ height: BASE_FIXED_HEIGHT - 8 }}
          >
            <Grid item>
              <Typography variant="subtitle1">
                {jobChangeStatus ? JobChangeStatusIconMap[jobChangeStatus] : ''}
                {'  '}
                {jobChangeStatus ? JobChangeStatusMap[jobChangeStatus] : ''}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1">{startDate && `Started: ${startDate}`}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1">{endDate && `⚠️ Left this new job in ${endDate}`}</Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Box width={80} height={FIXED_HEIGHT} marginY={ROW_MARGIN}>
        {showHeaders ? <JobChangeItemHeader header={pastJobChangeItemHeaderText.contactInfo} /> : null}
        <Box height={BASE_FIXED_HEIGHT} bgcolor={WarmlyColor.WHITE} padding={1}>
          <Grid container justify="center" alignItems="center" className={classes.row}>
            <Grid container item direction="column" justify="center" alignItems="center">
              <Box aria-label="linked-in" marginBottom={1} color={linkedinUrl ? undefined : 'grey.200'}>
                {linkedinUrl ? (
                  <Link href={linkedinUrl} target="_blank" rel="noopener noreferrer" onClick={onClickLinkedin}>
                    <FontAwesomeIcon icon={faLinkedin} size="2x" />
                  </Link>
                ) : (
                  <FontAwesomeIcon icon={faLinkedin} size="2x" />
                )}
              </Box>
              <Box aria-label="email" style={{ cursor: 'pointer' }} color={Boolean(email) ? undefined : 'grey.200'}>
                {email ? (
                  <Link>
                    <CopyToClipboard
                      text={emailToCopy || ''}
                      onCopy={() => {
                        if (emailToCopy) {
                          const alertData: AlertData = {
                            severity: AlertSeverity.SUCCESS,
                            message: `${emailToCopy} copied to clipboard!`,
                          };
                          onClickEmail(AmplitudeEventType.CLICK_EMAIL_ICON);
                          setSnackbarAlertData(alertData);
                        }
                      }}
                    >
                      <FontAwesomeIcon icon={faEnvelope} size="2x" />
                    </CopyToClipboard>
                  </Link>
                ) : (
                  <FontAwesomeIcon icon={faEnvelope} size="2x" />
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Box width={220} height={FIXED_HEIGHT} marginY={ROW_MARGIN}>
        {showHeaders ? <JobChangeItemHeader header={pastJobChangeItemHeaderText.crmAction} /> : null}
        <Box height={BASE_FIXED_HEIGHT} bgcolor={WarmlyColor.WHITE} padding={1}>
          <Grid container direction="column" style={{ height: '100%' }} justify="center">
            <Grid item><Box textAlign="center">{jobChange.clientAction === ClientJobChangeAction.APPROVE ? '✅  Approved' : 'Dismissed'}</Box></Grid>
          </Grid>
        </Box>
      </Box>
    </Grid>
  );
};

export default PastJobChangeItem;
