import { faTable } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Link, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';

import { ClientExternalFragment } from '../../graphql';
import { HubspotURL, IntegrationType, SalesforceURL, WARMLY_EMAIL } from '../../utils/constants';

interface Props {
  client: ClientExternalFragment;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onConfirm?: () => void;
}
const FirstTimeApproveModal: React.FC<Props> = ({ client, isOpen, setIsOpen, onConfirm }) => {
  const handleClose = () => {
    setIsOpen(false);
  };

  const onClickConfirm = () => {
    setIsOpen(false);
    onConfirm && onConfirm();
  };

  const integrationType = client.integrationType;
  const fieldDisplayText = integrationType === IntegrationType.HubSpot ? 'property' : 'field';
  const fieldDisplayTextPlural = integrationType === IntegrationType.HubSpot ? 'properties' : 'fields';
  const isFirstTimeApprove =
    (client.integrationType === IntegrationType.HubSpot &&
      !client.hubspotIntegration?.warmlyContactPropertiesCreatedAt) ||
    (client.integrationType === IntegrationType.Salesforce &&
      !client.salesforceIntegration?.warmlyContactFieldsCreatedAt);

  return (
    <Dialog open={isOpen} onClose={handleClose} aria-labelledby="reset-password-form">
      <DialogTitle>Sync to {client.integrationType}</DialogTitle>
      <DialogContent>
        <Box>
          Hello!{' '}
          <span role="img" aria-label="wave-hello">
            👋
          </span>{' '}
          If you are considering syncing back data from Warmly to {integrationType}, we would like to inform you on how
          this works!
        </Box>
        <Box marginTop={1}>
          Clicking "DONE" will sync data back to {integrationType} as described below. Clicking "Not useful" will
          dismiss the notification without syncing anything.
        </Box>
        <Box marginTop={1}>If it is your first time syncing, we will first:</Box>
        <List dense={true}>
          <ListItem>
            <ListItemIcon>
              <FontAwesomeIcon icon={faTable} color="primary" />
            </ListItemIcon>
            <ListItemText
              primary={
                integrationType === IntegrationType.Salesforce
                  ? `Create six custom ${fieldDisplayTextPlural} to store the enriched data: New Title, New Company, New Company Account, LinkedIn URL, New Company URL, and New Email`
                  : `Create five custom ${fieldDisplayTextPlural} to store the enriched data: Latest Job Title, Latest Company Name, LinkedIn URL, New Company URL, and New Email`
              }
              secondary={
                integrationType === IntegrationType.HubSpot ? (
                  <Link href={HubspotURL.CUSTOM_PROPERTIES} target="_blank" rel="noopener noreferrer">
                    What’s this? Open new tab for help page.
                  </Link>
                ) : (
                  <Link href={SalesforceURL.ADD_CUSTOM_FIELD} target="_blank" rel="noopener noreferrer">
                    What’s this? Open new tab for help page.
                  </Link>
                )
              }
            />
          </ListItem>

          <ListItem>
            <ListItemIcon>
              <FontAwesomeIcon icon={faTable} color="primary" />
            </ListItemIcon>
            <ListItemText
              primary={
                integrationType === IntegrationType.Salesforce
                  ? `Create one other custom ${fieldDisplayText}, Last Position Change Date, which is the approximate start date for the most recent job position`
                  : `Create one other custom ${fieldDisplayText}, Job Change, to indicate if we detected a job change (true / false). This is only synced if you hit APPROVE in the Job Changes page`
              }
              secondary={
                integrationType === IntegrationType.HubSpot ? (
                  <Link href={HubspotURL.CUSTOM_PROPERTIES} target="_blank" rel="noopener noreferrer">
                    What’s this? Open new tab for help page.
                  </Link>
                ) : (
                  <Link href={SalesforceURL.ADD_CUSTOM_FIELD} target="_blank" rel="noopener noreferrer">
                    What’s this? Open new tab for help page.
                  </Link>
                )
              }
            />
          </ListItem>

          <ListItem>
            <ListItemIcon>
              <FontAwesomeIcon icon={faTable} color="primary" />
            </ListItemIcon>
            <ListItemText primary={`Never overwrite any non-Warmly-created ${fieldDisplayTextPlural} in your CRM`} />
          </ListItem>
        </List>
        {isFirstTimeApprove && (
          <Box marginBottom={2} fontWeight="bold">
            Please note that it may take up to ~1 minute to set up these {fieldDisplayTextPlural} when you sync for the
            first time
          </Box>
        )}
        <Box marginBottom={2} fontWeight="bold">
          Any job change that you approve for sync (by clicking "DONE") or dismiss (by clicking "Not useful") can be
          found in the Past Notifications tab.
        </Box>
        <Box>
          As always, if you have any questions, please contact us at{' '}
          <Link
            href={`https://mail.google.com/mail/?view=cm&fs=1&to=${WARMLY_EMAIL.CSM}&su=[Warmly] Question about Approving Job Changes`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {WARMLY_EMAIL.CSM}
          </Link>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined" color="primary">
          {onConfirm ? 'Cancel' : 'Ok'}
        </Button>
        {onConfirm && (
          <Button onClick={onClickConfirm} variant="contained" color="primary">
            Proceed
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default FirstTimeApproveModal;
