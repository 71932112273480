import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';

import { IntegrationType,NavigationPath } from '../utils/constants';
import { ClientContext } from './Main';

const Integration: React.FC = () => {
  const { selectedClient } = useContext(ClientContext);

  if (selectedClient?.integrationType === IntegrationType.HubSpot) {
    return <Redirect to={NavigationPath.INTEGRATION_HUBSPOT} />;
  } else if (selectedClient?.integrationType === IntegrationType.Salesforce) {
    return <Redirect to={NavigationPath.INTEGRATION_SALESFORCE} />;
  } else {
    return <Redirect to={NavigationPath.MAIN} />;
  }
};

export default Integration;
