import { Box, Button, CircularProgress, Grid, Hidden, TextField, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import firebase from 'firebase';
import React, { useContext, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Redirect } from 'react-router-dom';

import PasswordResetModal from '../components/account/PasswordResetModal';
import { AuthContext } from '../components/auth/AuthProvider';
import NavBar from '../components/NavBar';
import { firebaseAuth } from '../config/firebase';
import { AlertSeverity, IMAGE_PERSON_STANDING, NavigationPath, WarmlyColor } from '../utils/constants';
import { getAuthErrorMessage } from '../utils/errors';

export default function Login() {
  // const [isLoading, setIsLoading] = useState(false);
  const [loadingAuth] = useAuthState(firebaseAuth);

  const { errorMessage: userDataErrorMessage, authUser } = useContext(AuthContext);
  const [email, setEmail] = useState('');
  const [passwordModalIsOpen, setPasswordModalIsOpen] = useState(false);
  const [password, setPassword] = useState('');
  const [loginErrorMessage, setLoginErrorMessage] = useState('');

  if (authUser) {
    return <Redirect to={NavigationPath.MAIN} />;
  }

  const onSubmit = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setLoginErrorMessage('');

    await firebaseAuth.signInWithEmailAndPassword(email, password).catch((err: firebase.auth.Error) => {
      const errorMessage = getAuthErrorMessage(err);
      setLoginErrorMessage(errorMessage);
    });
  };

  return (
    <>
      <NavBar hideButtons />
      <Grid container justify="center" style={{ backgroundColor: WarmlyColor.WHITE }}>
        <Hidden xsDown>
          <Grid item sm={3}>
            <img className="person-standing" alt="Person" src={IMAGE_PERSON_STANDING} width="75%" />
          </Grid>
        </Hidden>

        <Grid item xs={8} sm={6} md={4}>
          <form>
            <Box marginTop={4} marginLeft={-4}>
              <Typography variant="h1" color="primary">
                Login
              </Typography>

              <Box marginTop={4}>
                <TextField
                  label="Email"
                  type="email"
                  value={email}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setEmail(event.target.value);
                  }}
                  fullWidth
                />
              </Box>

              <Box marginTop={4}>
                <TextField
                  label="Password"
                  type="password"
                  value={password}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setPassword(event.target.value);
                  }}
                  fullWidth
                />
              </Box>

              <Box marginTop={4}>
                {loadingAuth ? (
                  <Box marginLeft={2} marginTop={2}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    onClick={onSubmit}
                    disabled={Boolean(loadingAuth)}
                  >
                    Login
                  </Button>
                )}
              </Box>

              <Box marginTop={4}>
                {userDataErrorMessage && <Alert severity={AlertSeverity.ERROR}>{userDataErrorMessage}</Alert>}
                {loginErrorMessage && <Alert severity={AlertSeverity.ERROR}>{loginErrorMessage}</Alert>}
              </Box>

              <Box>
                <Button variant="text" size="small" onClick={() => setPasswordModalIsOpen(true)}>
                  Forgot password
                </Button>
              </Box>
              <PasswordResetModal isOpen={passwordModalIsOpen} setIsOpen={setPasswordModalIsOpen} />
            </Box>
          </form>
        </Grid>
      </Grid>
    </>
  );
}
