import { Box, Button, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext } from 'react';

import { LoadingAndAlertContext } from '../../..';
import { QaTaskActionFragment } from '../../../graphql';
import { GQLHooks } from '../../../graphql/hasura/react';
import { logError } from '../../../modules/analytics';
import { confirmQATask } from '../../../modules/qaTasks';
import {
  AlertSeverity,
  QaActionAssessment,
  QaActionLinkedinUrlStatus,
  QAButtonType,
  QATaskActionType,
} from '../../../utils/constants';

const useStyles = makeStyles(() => ({
  buttonCorrect: {
    backgroundColor: 'green',
  },
  buttonIncorrect: {
    backgroundColor: 'red',
  },
  buttonEscalate: {
    backgroundColor: 'grey',
  },
}));

interface Props {
  qaTaskAction: QaTaskActionFragment;
  buttonType: string;
  QATaskActionData: QATaskActionData;
  clearForm: () => void;
  validationError?: boolean;
  getQaTask: () => void;
}

const QAConfirmTaskButton: React.FC<Props> = ({
  qaTaskAction,
  buttonType,
  QATaskActionData,
  clearForm,
  validationError = false,
  getQaTask,
}) => {
  const { setIsLoading, setSnackbarAlertData } = useContext(LoadingAndAlertContext);
  const [updateQaTaskAction] = GQLHooks.Fragments.QaTaskAction.useUpdateById();

  const classes = useStyles();

  const confirmTask = async () => {
    try {
      setIsLoading(true);

      clearForm();

      const completedAtDate = new Date();

      const executionTime = Math.round((completedAtDate.getTime() - new Date(qaTaskAction.createdAt).getTime()) / 1000);

      if (buttonType === QAButtonType.CORRECT) {
        QATaskActionData.QAApproverAssessment = true;
      } else if (buttonType === QAButtonType.INCORRECT) {
        QATaskActionData.QAApproverAssessment = false;
      } else if (buttonType === QAButtonType.ESCALATE) {
        QATaskActionData.actionType = QATaskActionType.ESCALATE;
      }

      await updateQaTaskAction({
        qaTaskActionId: qaTaskAction.id,
        set: {
          executionTime,
          completedAt: completedAtDate.toISOString(),
          actionType: QATaskActionData.actionType || undefined,
          jobChangeStatus: QATaskActionData.QAJobChangeStatus || undefined,
          linkedinUrl: QATaskActionData.QALinkedInURL || undefined,
          linkedinUrlStatus: QATaskActionData.QALinkedInURLNotFound
            ? QaActionLinkedinUrlStatus.NOT_FOUND
            : QaActionLinkedinUrlStatus.FOUND,
          updatedEmail: QATaskActionData.QANewEmail?.trim().toLowerCase() || undefined,
          updatedLinkedinJob: QATaskActionData.QANewLinkedInJob || undefined,
          previousLinkedinJob: QATaskActionData.QAOldLinkedInJob || undefined,
          notes: QATaskActionData.QAApproverNotes || QATaskActionData.QANotes || undefined,
          assessment:
            typeof QATaskActionData.QAApproverAssessment === 'boolean'
              ? QATaskActionData.QAApproverAssessment
                ? QaActionAssessment.CORRECT
                : QaActionAssessment.INCORRECT
              : null,
        },
      });

      await confirmQATask(qaTaskAction.id);

      getQaTask();

      const alertData: AlertData = {
        severity: AlertSeverity.SUCCESS,
        message: `Great job!! On to the next one 😀 `,
      };

      setSnackbarAlertData(alertData);
    } catch (err) {
      logError(err, 'Error while getting next task');

      const alertData: AlertData = {
        severity: AlertSeverity.ERROR,
        message: `Error while getting next task`,
      };

      setSnackbarAlertData(alertData);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {buttonType === QAButtonType.NEXT && (
        <Tooltip title="Send ">
          <Box display="flex">
            <Button variant="contained" color="primary" onClick={confirmTask} disabled={validationError}>
              Next
            </Button>
          </Box>
        </Tooltip>
      )}

      {buttonType === QAButtonType.INCORRECT && (
        <Button
          className={classes.buttonIncorrect}
          variant="contained"
          color="primary"
          onClick={confirmTask}
          disabled={validationError}
        >
          Incorrect
        </Button>
      )}

      {buttonType === QAButtonType.CORRECT && (
        <Button
          className={classes.buttonCorrect}
          variant="contained"
          color="primary"
          onClick={confirmTask}
          disabled={validationError}
        >
          Correct
        </Button>
      )}

      {buttonType === QAButtonType.ESCALATE && (
        <Button
          className={classes.buttonEscalate}
          variant="contained"
          color="primary"
          onClick={confirmTask}
          disabled={validationError}
        >
          Escalate
        </Button>
      )}
    </>
  );
};

export default QAConfirmTaskButton;
