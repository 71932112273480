import { Box, Button, Grid, Typography } from '@material-ui/core';
import React, { useContext, useState } from 'react';

import HighlightText from '../../../components/HighlightText';
import { CURRENT_ENVIRONMENT, Environment } from '../../../utils/constants';
import { ClientContext } from '../../Main';
import NotificationSettings from '../../settings/NotificationSettings';
import InviteUser from '../users/InviteUser';
import UsersTable from '../users/UsersTable';
import GenerateJobChangesModal from './allClientsManagement/clientManagementActions/GenerateJobChangesModal';
import DeleteClientContactsModal from './allClientsManagement/DeleteClientContactsModal';
import DeleteClientModal from './allClientsManagement/DeleteClientModal';
import ResetClientIntegrationModal from './allClientsManagement/ResetClientIntegrationModal';
import ClientSelect from './ClientSelect';

const ClientManagement: React.FC = () => {
  const { selectedClient, selectedClientId, setSelectedClientId } = useContext(ClientContext);
  const [showDeleteClientContactsModal, setShowDeleteClientContactsModal] = useState(false);
  const [showGenerateJobChangesModal, setShowGenerateJobChangesModal] = useState(false);
  const [showResetClientIntegrationModal, setShowResetClientIntegrationModal] = useState(false);
  const [showDeleteClientModal, setShowDeleteClientModal] = useState(false);

  return (
    <>
      <Grid container item direction="row" justify="space-around" alignItems="center">
        <Grid container item direction="row" justify="flex-start" alignItems="center">
          <HighlightText>You are currently managing {selectedClient.name}</HighlightText>
        </Grid>
      </Grid>

      <Grid container item direction="column" alignItems="flex-start">
        <Box width="50%">
          <ClientSelect
            selectedClientId={selectedClientId}
            onSelectClientId={(clientId) => setSelectedClientId(clientId)}
          />
        </Box>
      </Grid>

      <Grid container item spacing={3}>
        {CURRENT_ENVIRONMENT !== Environment.Production && (
          <Grid item>
            <Typography>For testing purposes only: generate job changes</Typography>
            <Button
              variant="contained"
              onClick={() => {
                setShowGenerateJobChangesModal(true);
              }}
            >
              Generate Job Changes
            </Button>
          </Grid>
        )}

        <Grid item>
          <Typography>This will open a modal where you can confirm to delete the client's contacts</Typography>
          <Button
            variant="contained"
            onClick={() => {
              setShowDeleteClientContactsModal(true);
            }}
          >
            Delete Client Contacts
          </Button>
        </Grid>

        {/* <Grid item>
          <Typography>
            This will open a modal where you can confirm to resync the client's contacts from their CRM
          </Typography>
          <Button
            variant="contained"
            onClick={() => {
              setShowResyncClientContactsModal(true);
            }}
          >
            Resync Client Contacts
          </Button>
        </Grid> */}

        <Grid item>
          <Typography>This will open a modal where you can confirm to reset the client's CRM integration</Typography>
          <Button
            variant="contained"
            onClick={() => {
              setShowResetClientIntegrationModal(true);
            }}
          >
            Reset CRM Integration
          </Button>
        </Grid>

        <Grid item>
          <Typography>
            This will open a modal where you can confirm to delete the client and all associated data
          </Typography>
          <Button
            variant="contained"
            onClick={() => {
              setShowDeleteClientModal(true);
            }}
          >
            Delete Client
          </Button>
        </Grid>
      </Grid>

      <Grid container item>
        <Box padding={2} width="100%" borderColor="secondary.main" borderRadius={16} border={1}>
          <InviteUser />
          <UsersTable />
        </Box>
      </Grid>

      <Grid container item>
        <Box padding={2} width="100%" borderColor="secondary.main" borderRadius={16} border={1}>
          <NotificationSettings />
        </Box>
      </Grid>

      <DeleteClientContactsModal
        client={selectedClient}
        isOpen={showDeleteClientContactsModal}
        setIsOpen={setShowDeleteClientContactsModal}
      />

      <ResetClientIntegrationModal
        client={selectedClient}
        isOpen={showResetClientIntegrationModal}
        setIsOpen={setShowResetClientIntegrationModal}
      />

      <GenerateJobChangesModal
        client={selectedClient}
        isOpen={showGenerateJobChangesModal}
        setIsOpen={setShowGenerateJobChangesModal}
      />

      <DeleteClientModal client={selectedClient} isOpen={showDeleteClientModal} setIsOpen={setShowDeleteClientModal} />
    </>
  );
};

export default ClientManagement;
