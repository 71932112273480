import { AxiosError } from 'axios';
import firebase from 'firebase';

import { CURRENT_ENVIRONMENT, Environment, HTTP_RESPONSE, WARMLY_EMAIL } from './constants';

// https://firebase.google.com/docs/reference/js/firebase.auth.Auth#error-codes_3
// https://firebase.google.com/docs/reference/js/firebase.auth.Error

export enum FirebaseAuthError {
  EMAIL_IN_USE = 'auth/email-already-in-use',
  INVALID_EMAIL = 'auth/invalid-email',
  WEAK_PASSWORD = 'auth/weak-password',
  WRONG_PASSWORD = 'auth/wrong-password',
  USER_DISABLED = 'auth/user-disabled',
  USER_NOT_FOUND = 'auth/user-not-found',
  INVALID_ACTION_CODE = 'auth/invalid-action-code',
  EXPIRED_ACTION_CODE = 'auth/expired-action-code',
  TOO_MANY_REQUESTS = 'auth/too-many-requests',
}

const DEFAULT_ERROR_MESSAGE = `The server encountered an error while processing your request. \n If you continue to encounter this error, please contact us at ${WARMLY_EMAIL.CSM}`;

export const isAuthError = (err: Error) => {
  const errorCode = err['code'];

  if (typeof errorCode === 'string' && errorCode.startsWith('auth/')) {
    return true;
  } else {
    return false;
  }
};

export const getAuthErrorMessage = (err: firebase.auth.Error): string => {
  const errorCode = err.code;

  if (CURRENT_ENVIRONMENT === Environment.Development) {
    console.error(err);
  }

  switch (errorCode) {
    case FirebaseAuthError.EMAIL_IN_USE:
      return 'An account with this email already exists';
    case FirebaseAuthError.INVALID_EMAIL:
      return 'Email address invalid. Please verify that the email is correct';
    case FirebaseAuthError.WEAK_PASSWORD:
      return 'The password you chose was too weak (min. 6 characters), please enter another password';
    case FirebaseAuthError.WRONG_PASSWORD:
      return 'Password incorrect';
    case FirebaseAuthError.USER_DISABLED:
      return `The account has been disabled. If you believe this to be an error, please contact us at ${WARMLY_EMAIL.CSM}`;
    case FirebaseAuthError.USER_NOT_FOUND:
      return 'We did not find a user with the provided credentials';
    case FirebaseAuthError.INVALID_ACTION_CODE:
      return 'Invalid action. The link has already been used (please note that certain links may only be accessed once)';
    case FirebaseAuthError.EXPIRED_ACTION_CODE:
      return 'Invalid action. The link you used is expired';
    case FirebaseAuthError.TOO_MANY_REQUESTS:
      return 'Too many attempts, please try again in a few minutes';
    default:
      return DEFAULT_ERROR_MESSAGE;
  }
};

export const getApiErrorMessage = (err: AxiosError) => {
  const errorCode = err.response?.status;

  switch (errorCode) {
    case HTTP_RESPONSE.BAD_REQUEST:
      return 'The request was invalid. Please verify that your data is correct';
    case HTTP_RESPONSE.FORBIDDEN:
      return 'You do not have permission to perform this action';
    case HTTP_RESPONSE.INTERNAL_SERVER_ERROR:
      return DEFAULT_ERROR_MESSAGE;
    default:
      return DEFAULT_ERROR_MESSAGE;
  }
};

export const consoleLogDev = (message: string, item?: any) => {
  if (CURRENT_ENVIRONMENT === Environment.Development) {
    console.log('🔦   🔦   🔦');
    console.log(message, item);
  }
};
