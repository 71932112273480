import { faArrowAltCircleDown } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InfoIcon from '@material-ui/icons/Info';
import Skeleton from '@material-ui/lab/Skeleton';
import { isEqual } from 'lodash';
import React, { useContext, useEffect, useMemo, useState } from 'react';

import { LoadingAndAlertContext } from '../../..';
import { UserContext } from '../../../components/auth/UserRouter';
import FirstTimeApproveModal from '../../../components/crm/FirstTimeApproveModal';
import ExportCSVButton from '../../../components/exportCSV/ExportCSVButton';
import ExportCSVModal from '../../../components/exportCSV/ExportCSVModal';
import {
  ClientContact_Bool_Exp,
  ClientContactCrmData_Select_Column,
  ClientContactExternalFragment,
  ClientExternalFragment,
  Order_By,
  useQueryClientContactCountQuery,
} from '../../../graphql';
import { GQLHooks } from '../../../graphql/hasura/react';
import { logError } from '../../../modules/analytics';
import { exportContactsCsv } from '../../../modules/cloudFunctions';
import { AlertSeverity, UserClientPermission } from '../../../utils/constants';
import { WarmlyColor } from '../../../utils/constants';
import { ClientContext } from '../../Main';
import FilterByCompanySizePopover from './FilterByCompanySizePopover';
import FilterByJobUpdateTypePopover from './FilterByJobUpdateTypePopover';
import FilterByOwnerPopover from './FilterByOwnerPopover';
import JobChangeFilter from './JobChangeFilter';
import NewJobChangeItem from './NewJobChangeItem';

const DEFAULT_CONTACTS_PER_PAGE = 10;
const FIXED_HEADER_HEIGHT = 60;
const HEAD_CELL_PADDING = '0 4px 8px 4px';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    tableHeadCell: {
      height: FIXED_HEADER_HEIGHT,
      fontSize: '0.75rem',
      padding: HEAD_CELL_PADDING,
      lineHeight: '0.9rem',
      '&:last-child': {
        padding: HEAD_CELL_PADDING,
      },
    },
    tableHeadCellFiltered: {
      height: FIXED_HEADER_HEIGHT,
      fontSize: '0.75rem',
      padding: HEAD_CELL_PADDING,
      lineHeight: '0.9rem',
      '&:last-child': {
        padding: HEAD_CELL_PADDING,
      },
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.primary.main,
      borderBottom: `2px solid ${theme.palette.primary.main}`,
    },
    tableSortLabel: {
      '& .MuiTableSortLabel-icon': {
        margin: '0px',
      },
    },
    filterAccordion: {
      backgroundColor: 'transparent',
      border: `1px solid ${WarmlyColor.DISABLED_GRAY}`,
      borderRadius: '5px',
    },
    noWrap: {
      flexWrap: 'nowrap',
    },
  })
);

enum JobChangeColumnId {
  detectedOn,
  contactOwner,
  contact,
  jobUpdate,
  currentCompany,
  currentJobTitle,
  companyHQ,
  companySize,
  reachOut,
  action,
}

interface HeadCell {
  id: JobChangeColumnId;
  label: string;
  sortable: boolean;
  filterable: boolean;
  colWidth: string;
  align: 'left' | 'right' | 'inherit' | 'center' | 'justify' | undefined;
}

interface TableHeadProps {
  showFilterPopover: (id: JobChangeColumnId) => React.ReactNode;
  filtersApplied: Record<string, boolean>;
  client: ClientExternalFragment;
}

const headCells: HeadCell[] = [
  {
    id: JobChangeColumnId.detectedOn,
    label: 'Detected',
    sortable: false,
    filterable: false,
    colWidth: '5%',
    align: 'left',
  },
  {
    id: JobChangeColumnId.contactOwner,
    label: 'Contact owner',
    sortable: false,
    filterable: true,
    colWidth: '10%',
    align: 'left',
  },
  {
    id: JobChangeColumnId.contact,
    label: 'Contact',
    sortable: false,
    filterable: true,
    colWidth: '18%',
    align: 'left',
  },
  {
    id: JobChangeColumnId.jobUpdate,
    label: 'Job update',
    sortable: false,
    filterable: true,
    colWidth: '10%',
    align: 'center',
  },
  {
    id: JobChangeColumnId.currentCompany,
    label: 'Current company',
    sortable: false,
    filterable: true,
    colWidth: '15%',
    align: 'left',
  },
  {
    id: JobChangeColumnId.currentJobTitle,
    label: 'Current job title',
    sortable: false,
    filterable: true,
    colWidth: '12%',
    align: 'left',
  },
  {
    id: JobChangeColumnId.companyHQ,
    label: 'Company HQ',
    sortable: false,
    filterable: true,
    colWidth: '12%',
    align: 'left',
  },
  {
    id: JobChangeColumnId.companySize,
    label: 'Company size',
    sortable: false,
    filterable: true,
    colWidth: '5%',
    align: 'center',
  },
  {
    id: JobChangeColumnId.reachOut,
    label: 'Reach out',
    sortable: false,
    filterable: false,
    colWidth: '8%',
    align: 'center',
  },
  {
    id: JobChangeColumnId.action,
    label: 'Action',
    sortable: false,
    filterable: false,
    colWidth: '5%',
    align: 'center',
  },
];

const NewJobChangeTableHead: React.FC<TableHeadProps> = ({ client, showFilterPopover, filtersApplied }) => {
  const classes = useStyles();
  const [showFirstTimeApproveModal, setShowFirstTimeApproveModal] = useState(false);

  return (
    <TableHead>
      <TableRow>
        <TableCell align="center" padding="none" />
        {headCells.map((headCell: HeadCell) => (
          <TableCell
            key={headCell.id}
            padding="none"
            className={filtersApplied[headCell.id] ? classes.tableHeadCellFiltered : classes.tableHeadCell}
            align={headCell.align}
            style={{ width: headCell.colWidth }}
          >
            <Box display="flex" flexDirection="column" height="100%" justifyContent="flex-end">
              {headCell.label === 'Action' && client.integrationType && (
                <>
                  <IconButton color="primary" aria-label="more-info" onClick={() => setShowFirstTimeApproveModal(true)}>
                    <InfoIcon />
                  </IconButton>
                  <FirstTimeApproveModal
                    client={client}
                    isOpen={showFirstTimeApproveModal}
                    setIsOpen={setShowFirstTimeApproveModal}
                  />
                </>
              )}
              {headCell.filterable && <Box>{showFilterPopover(headCell.id)}</Box>}
              <Box>
                {headCell.sortable ? (
                  <TableSortLabel className={classes.tableSortLabel}>{headCell.label}</TableSortLabel>
                ) : (
                  headCell.label
                )}
              </Box>
            </Box>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const NewJobChangeTable = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_CONTACTS_PER_PAGE);
  const { selectedClient } = useContext(ClientContext);
  const { user } = useContext(UserContext);
  const { setIsLoading, setSnackbarAlertData } = useContext(LoadingAndAlertContext);
  const [showExportCsvModal, setShowExportCsvModal] = useState(false);
  const [clientContacts, setClientContacts] = useState<ClientContactExternalFragment[]>([]);
  const [filtersApplied, setFiltersApplied] = useState<Record<string, boolean>>({});

  const { objects: distinctCrmData, loading: loadingDistinctCrmData } =
    GQLHooks.Fragments.ClientContactCrmData.useQueryObjects({
      variables: {
        where: {
          clientId: {
            _eq: selectedClient.id,
          },
        },
        distinct_on: [ClientContactCrmData_Select_Column.CrmType, ClientContactCrmData_Select_Column.FieldName],
      },
    });

  const classes = useStyles();

  const [updateUser] = GQLHooks.Fragments.User.useUpdateById();
  const integrationType = selectedClient.integrationType;

  const jobChangeFilters: JobChangeFilter[] = useMemo(() => {
    return user.jobChangeFilters?.[selectedClient.id]
      ? (user.jobChangeFilters as JobChangeFiltersByClientId)[selectedClient.id]
      : [];
  }, [user.jobChangeFilters, selectedClient.id]);

  useEffect(() => {
    const updatedFiltersApplied: Record<string, boolean> = {};

    if (jobChangeFilters.find((filter) => filter.jobChangeTypes)) {
      updatedFiltersApplied[JobChangeColumnId.jobUpdate] = true;
    }

    if (jobChangeFilters.find((filter) => filter.employeeCount)) {
      updatedFiltersApplied[JobChangeColumnId.companySize] = true;
    }

    if (jobChangeFilters.find((filter) => filter.ownerUserIds)) {
      updatedFiltersApplied[JobChangeColumnId.contactOwner] = true;
    }

    setFiltersApplied(updatedFiltersApplied);
  }, [jobChangeFilters]);

  const clientContactQueryFilters = useMemo(() => {
    const filtersQuery: ClientContact_Bool_Exp =
      user.clientPermission !== UserClientPermission.Admin
        ? {
            _and: [
              {
                _or: [
                  {
                    currentJobChange: {
                      ownerUserId: {
                        _eq: user.id,
                      },
                    },
                  },
                  {
                    ownerUserId: {
                      _eq: user.id,
                    },
                  },
                ],
              },
            ],
          }
        : {};

    for (const filter of jobChangeFilters) {
      let minEmployeeCount, maxEmployeeCount;
      if (filter.employeeCount && filter.employeeCount.range) {
        minEmployeeCount = filter.employeeCount.range[0];
        maxEmployeeCount = filter.employeeCount.range[1];
      }

      let queryFilter: ClientContact_Bool_Exp | undefined;

      if (filter.crmType) {
        queryFilter = {
          crmData: {
            crmType: filter.crmType
              ? {
                  _eq: filter.crmType,
                }
              : {
                  _is_null: true,
                },
            fieldName: {
              _eq: filter.fieldName,
            },
            fieldValue: {
              _in: filter.fieldValueSearchTerms,
            },
          },
        };
      } else if (filter.jobChangeTypes) {
        queryFilter = {
          currentJobChange: {
            status: {
              _in: filter.jobChangeTypes,
            },
          },
        };
      } else if (filter.employeeCount) {
        queryFilter = {
          currentJobChange: {
            job: {
              company: {
                _and: [{ employeeCount: { _gte: minEmployeeCount } }, { employeeCount: { _lte: maxEmployeeCount } }],
              },
            },
          },
        };
      } else if (filter.ownerUserIds) {
        if (filter.ownerUserIds.includes(null)) {
          const userIdsExcludingNull = filter.ownerUserIds.filter((userId) => userId !== null) as string[];

          queryFilter = {
            _or: [
              {
                currentJobChange: {
                  ownerUserId: {
                    _in: userIdsExcludingNull,
                  },
                },
              },
              {
                currentJobChange: {
                  ownerUserId: {
                    _is_null: true,
                  },
                },
              },
            ],
          };
        } else {
          queryFilter = {
            currentJobChange: {
              ownerUserId: {
                _in: filter.ownerUserIds as string[],
              },
            },
          };
        }
      }

      if (queryFilter) {
        filtersQuery._and = filtersQuery._and ? [...filtersQuery._and, queryFilter] : [queryFilter];
      }
    }

    return filtersQuery;
  }, [jobChangeFilters, user.clientPermission, user.id]);

  const {
    objects: clientContactsFromQuery,
    loading: loadingClientContacts,
    refetch: refetchClientContacts,
  } = GQLHooks.Fragments.ClientContactExternal.useQueryObjects({
    variables: {
      where: {
        clientId: {
          _eq: selectedClient.id,
        },
        currentJobChange: {
          clientAction: {
            _is_null: true,
          },
        },
        ...clientContactQueryFilters,
      },
      limit: rowsPerPage,
      offset: currentPage * rowsPerPage,
      order_by: [
        {
          currentJobChange: {
            job: {
              company: {
                employeeCount: Order_By.DescNullsLast,
              },
            },
          },
        },
        {
          updatedAt: Order_By.Desc,
        },
      ],
    },
    fetchPolicy: 'network-only',
  });

  // TODO atm we pull all users, we can improve by only get users that have assigned territories or have current job changes assigned
  const { objects: ownerUsers } = GQLHooks.Fragments.User.useQueryObjects({
    variables: {
      where: {
        clientId: {
          _eq: selectedClient.id,
        },
      },
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    // We use this effect because Apollo resets the result array to empty array
    // between each query, and we don't want to show a "flash of empty page" whenever
    // the user changes page number
    if (!loadingClientContacts && clientContactsFromQuery) {
      setClientContacts(clientContactsFromQuery);
    }
  }, [loadingClientContacts, clientContactsFromQuery]);

  const {
    data,
    loading: loadingTotalCount,
    refetch: refetchClientContactCount,
  } = useQueryClientContactCountQuery({
    variables: {
      where: {
        clientId: {
          _eq: selectedClient.id,
        },
        currentJobChange: {
          clientAction: {
            _is_null: true,
          },
        },
        ...clientContactQueryFilters,
      },
    },
    fetchPolicy: 'network-only',
  });

  const totalClientContactCount = data?.clientContact_aggregate.aggregate?.totalCount;

  const refetchData = () => {
    refetchClientContactCount && refetchClientContactCount();
    refetchClientContacts && refetchClientContacts();
  };

  const addJobChangeFilter = (filter: JobChangeFilter) => {
    const updatedJobChangeFilters = {
      ...(user.jobChangeFilters || {}),
    };

    updatedJobChangeFilters[selectedClient.id] = [...(updatedJobChangeFilters[selectedClient.id] || []), filter];

    updateUser({
      userId: user.id,
      set: {
        jobChangeFilters: updatedJobChangeFilters,
      },
    });
  };

  const handleUpdateJobChangeFilter = (updatedFilter: JobChangeFilter, index: number) => {
    const updatedJobChangeFilters = {
      ...(user.jobChangeFilters || {}),
    };

    updatedJobChangeFilters[selectedClient.id][index] = updatedFilter;

    updateUser({
      userId: user.id,
      set: {
        jobChangeFilters: updatedJobChangeFilters,
      },
    });
  };

  const handleDeleteJobChangeFilter = (filterToDelete: JobChangeFilter) => {
    const updatedJobChangeFilters: JobChangeFiltersByClientId = {
      ...(user.jobChangeFilters || {}),
    };

    updatedJobChangeFilters[selectedClient.id] = updatedJobChangeFilters[selectedClient.id].filter(
      (filter) =>
        !(
          filter.crmType === filterToDelete.crmType &&
          filter.fieldName === filterToDelete.fieldName &&
          isEqual(filter.fieldValueSearchTerms, filterToDelete.fieldValueSearchTerms) &&
          isEqual(filter.jobChangeTypes, filterToDelete.jobChangeTypes) &&
          isEqual(filter.employeeCount, filterToDelete.employeeCount) &&
          isEqual(filter.ownerUserIds, filterToDelete.ownerUserIds)
        )
    );

    updateUser({
      userId: user.id,
      set: {
        jobChangeFilters: updatedJobChangeFilters,
      },
    });
  };

  // Remove filters that should not be displayed in the advanced filters dropdown
  const jobChangeFilterItemsCrmFieldsOnly: JobChangeFilter[] = jobChangeFilters.filter(
    (filter) =>
      filter.crmType !== undefined && filter.fieldName !== undefined && filter.fieldValueSearchTerms !== undefined
  );
  // We add a null value to represent an "empty" filter that can be used to add new filters
  const jobChangeFilterItemsAdvanced = [...jobChangeFilterItemsCrmFieldsOnly, null];

  const showFilterPopover = (id: JobChangeColumnId): React.ReactNode => {
    switch (id) {
      case JobChangeColumnId.jobUpdate:
        const jobUpdateFilterIndex = jobChangeFilters.findIndex((filter) => filter.jobChangeTypes);
        const handleAddOrUpdateJobUpdateFilter = filtersApplied[JobChangeColumnId.jobUpdate]
          ? (filter: JobChangeFilter) => {
              handleUpdateJobChangeFilter(filter, jobUpdateFilterIndex);
            }
          : addJobChangeFilter;

        return (
          <FilterByJobUpdateTypePopover
            filter={jobChangeFilters[jobUpdateFilterIndex]}
            handleAddOrUpdateFilter={handleAddOrUpdateJobUpdateFilter}
            handleClearFilter={handleDeleteJobChangeFilter}
          />
        );
      case JobChangeColumnId.companySize:
        const companySizeFilterIndex = jobChangeFilters.findIndex((filter) => filter.employeeCount);
        const handleAddOrUpdateCompanySizeFilter = filtersApplied[JobChangeColumnId.companySize]
          ? (filter: JobChangeFilter) => {
              handleUpdateJobChangeFilter(filter, companySizeFilterIndex);
            }
          : addJobChangeFilter;

        return (
          <FilterByCompanySizePopover
            filter={jobChangeFilters[companySizeFilterIndex]}
            handleAddOrUpdateFilter={handleAddOrUpdateCompanySizeFilter}
            handleClearFilter={handleDeleteJobChangeFilter}
          />
        );
      case JobChangeColumnId.contactOwner:
        if (user.clientPermission !== UserClientPermission.Admin) {
          return null;
        }

        const ownerUserFilterIndex = jobChangeFilters.findIndex((filter) => filter.ownerUserIds);
        const handleAddOrUpdateOwnerUserFilter = filtersApplied[JobChangeColumnId.contactOwner]
          ? (filter: JobChangeFilter) => {
              handleUpdateJobChangeFilter(filter, ownerUserFilterIndex);
            }
          : addJobChangeFilter;

        return (
          <FilterByOwnerPopover
            ownerUsers={ownerUsers}
            filter={jobChangeFilters[ownerUserFilterIndex]}
            handleAddOrUpdateFilter={handleAddOrUpdateOwnerUserFilter}
            handleClearFilter={handleDeleteJobChangeFilter}
          />
        );
      default:
        return null;
    }
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const onConfirmSendCSVExport = async () => {
    try {
      setIsLoading(true);

      const clientId = selectedClient.id,
        jobChangesOnly = true;
      await exportContactsCsv(clientId, jobChangesOnly);

      const alertData: AlertData = {
        severity: AlertSeverity.SUCCESS,
        message: `We are now processing your request to email CSV`,
      };

      setSnackbarAlertData(alertData);
    } catch (err) {
      logError(err, `Error while sending csv export email for ${selectedClient?.name}`);

      const alertData: AlertData = {
        severity: AlertSeverity.ERROR,
        message: `Error while sending CSV export`,
      };

      setSnackbarAlertData(alertData);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Grid container item className={classes.noWrap} direction="row" justify="space-between" alignItems="flex-start">
        <Grid item>
          <Box marginBottom={2} width="65vw">
            {!loadingDistinctCrmData && Boolean(distinctCrmData.length) && (
              <Accordion className={classes.filterAccordion}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  Advanced filters{' '}
                  {jobChangeFilterItemsCrmFieldsOnly.length ? `(${jobChangeFilterItemsCrmFieldsOnly.length})` : ''}
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container direction="column">
                    {jobChangeFilterItemsAdvanced.map((filter, i) => {
                      if (!filter) {
                        return (
                          <Grid item key="null">
                            {i > 0 && <Typography style={{ marginLeft: '27%' }}>AND</Typography>}
                            <JobChangeFilter
                              distinctCrmData={distinctCrmData}
                              handleAddOrUpdateFilter={addJobChangeFilter}
                              handleDeleteFilter={handleDeleteJobChangeFilter}
                            />
                          </Grid>
                        );
                      }
                      const selectedCrmData = distinctCrmData.find(
                        (crmData) => crmData.crmType === filter.crmType && crmData.fieldName === filter.fieldName
                      );
                      const updateJobChangeFilter = (filter: JobChangeFilter) => {
                        handleUpdateJobChangeFilter(filter, i);
                      };
                      return (
                        <Grid item key={i}>
                          {i > 0 && <Typography style={{ marginLeft: '27%' }}>AND</Typography>}
                          <JobChangeFilter
                            distinctCrmData={distinctCrmData}
                            selectedCrmData={selectedCrmData}
                            filter={filter}
                            handleAddOrUpdateFilter={updateJobChangeFilter}
                            handleDeleteFilter={handleDeleteJobChangeFilter}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            )}
          </Box>
        </Grid>
        <Grid container item className={classes.noWrap} direction="row" justify="flex-end" alignItems="center">
          <Grid item>
            <ExportCSVButton
              size="small"
              variant="outlined"
              startIcon={<FontAwesomeIcon icon={faArrowAltCircleDown} />}
              onClick={onConfirmSendCSVExport}
            >
              Email job change CSV
            </ExportCSVButton>
          </Grid>
          <Grid item>
            <IconButton
              color="primary"
              aria-label="more-info"
              component="span"
              onClick={() => setShowExportCsvModal(true)}
              style={{ padding: '5px' }}
            >
              <InfoIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        {loadingClientContacts ? (
          <Skeleton width="100%" height={1000} style={{ marginTop: '-240px' }}></Skeleton>
        ) : (
          <Paper className={classes.paper}>
            <TableContainer>
              <Table size="small" aria-label="collapsible table">
                <NewJobChangeTableHead
                  showFilterPopover={showFilterPopover}
                  filtersApplied={filtersApplied}
                  client={selectedClient}
                />
                <TableBody>
                  {totalClientContactCount
                    ? clientContacts.map((clientContact, i) => (
                        <NewJobChangeItem
                          key={clientContact.id}
                          clientContact={clientContact}
                          refetchData={refetchData}
                        />
                      ))
                    : !Boolean(clientContacts.length) &&
                      Boolean(jobChangeFilters.length) && (
                        <TableRow>
                          <TableCell />
                          <TableCell colSpan={100}>
                            <Box fontStyle="italic">No results with the applied filters.</Box>
                          </TableCell>
                        </TableRow>
                      )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25]}
              count={totalClientContactCount as number}
              rowsPerPage={rowsPerPage}
              page={currentPage}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
              onPageChange={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
        )}
      </Grid>
      <Grid container item direction="column" alignItems="flex-start">
        {!totalClientContactCount && !loadingTotalCount && !jobChangeFilters.length && (
          <Box>
            <Typography variant="h5">
              No new job changes have been detected yet
              {integrationType ? `, or you have already synced back all job changes to ${integrationType}` : ''}.
            </Typography>
            <Typography variant="h6">
              Our systems are constantly working to provide you with the latest data on your contacts.
            </Typography>
            <Typography variant="h6">You will receive an email with updates once the process is complete.</Typography>
          </Box>
        )}
      </Grid>
      <ExportCSVModal setIsOpen={setShowExportCsvModal} isOpen={showExportCsvModal} jobChangesOnly={true} />
    </>
  );
};

export default NewJobChangeTable;
