import { Box, Grid, Step, StepLabel, Stepper, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';

import { ClientExternalFragment } from '../../graphql';
import { NavigationPath, SyncStatus, WarmlyColor } from '../../utils/constants';
import { ClientContext } from '../Main';
import CustomizeFields from './integrationSteps/CustomizeFields';
import IntegrationComplete from './integrationSteps/IntegrationComplete';
import SyncContacts from './integrationSteps/SyncContacts';

const useStyles = makeStyles((theme) =>
  createStyles({
    stepper: {
      backgroundColor: 'transparent',
      padding: '24px 0',
      '& .MuiStepConnector-active': {
        '& .MuiStepConnector-line': {
          borderColor: theme.palette.primary.main,
        },
      },
      '& .MuiStepConnector-completed': {
        '& .MuiStepConnector-line': {
          borderColor: theme.palette.primary.main,
        },
      },
      '& .MuiStepConnector-lineHorizontal': {
        borderTopWidth: '2px',
      },
    },
    stepLabel: {
      '& .MuiStepIcon-text': {
        visibility: 'hidden',
      },
      '& .MuiStepIcon-active': {
        backgroundColor: theme.palette.primary.main,
        padding: '11px',
        margin: '-6px',
        borderRadius: '50%',
        border: `6px solid ${WarmlyColor.SELECTED_BLUE}`,
      },
      '& .MuiStepIcon-completed': {
        backgroundColor: theme.palette.primary.main,
        borderRadius: '50%',
      },
    },
  })
);

enum IntegrationStep {
  CUSTOMIZE_FIELDS = 0,
  SYNC_CONTACTS = 1,
  INTEGRATION_COMPLETE = 2,
}

const getIntegrationStep = (client: ClientExternalFragment): IntegrationStep => {
  if (!client.salesforceIntegration?.mapping) {
    return IntegrationStep.CUSTOMIZE_FIELDS;
  } else if (!client.syncStatus) {
    return IntegrationStep.SYNC_CONTACTS;
  } else {
    return IntegrationStep.INTEGRATION_COMPLETE;
  }
};

const SalesforceIntegrationSteps: React.FC = () => {
  const classes = useStyles();
  const { selectedClient } = useContext(ClientContext);
  const syncStatus = selectedClient.syncStatus;

  const activeStep = getIntegrationStep(selectedClient!);

  if (!selectedClient.salesforceIntegration) {
    return <Redirect to={NavigationPath.UPLOAD} />;
  }

  return (
    <Grid container direction="column">
      <Grid container>
        <Typography component="div" variant="h1">
          <Box fontWeight="bold" color={WarmlyColor.BLACK}>
            Salesforce Integration
          </Box>
        </Typography>
      </Grid>
      {syncStatus !== SyncStatus.COMPLETE ? (
        <Grid container>
          <Box width="100%">
            <Stepper activeStep={activeStep} classes={{ root: classes.stepper }} alternativeLabel>
              <Step>
                <StepLabel className={classes.stepLabel}>Map fields</StepLabel>
              </Step>
              <Step>
                <StepLabel className={classes.stepLabel}>Sync data</StepLabel>
              </Step>
              <Step>
                <StepLabel className={classes.stepLabel}>Integration</StepLabel>
              </Step>
            </Stepper>
          </Box>
        </Grid>
      ) : null}
      {activeStep === IntegrationStep.CUSTOMIZE_FIELDS && <CustomizeFields />}
      {activeStep === IntegrationStep.SYNC_CONTACTS && <SyncContacts />}
      {activeStep === IntegrationStep.INTEGRATION_COMPLETE && <IntegrationComplete />}
    </Grid>
  );
};

export default SalesforceIntegrationSteps;
