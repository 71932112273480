import {
  ApolloCache,
  ApolloClient,
  ApolloQueryResult,
  DataProxy,
  FetchResult,
  MutationOptions,
  Observable,
  ObservableQuery,
  QueryOptions,
  SubscriptionOptions,
} from '@apollo/client';
import {
  convertToGraph,
  defaultCacheIdFromObject,
  ensureTypenameOnFragment,
  ensureTypenameOnFragments,
  FieldMap,
  generateOptimisticResponseForMutation,
  generateUpdateFunctionForMutation,
  getLogLevel,
  ObjectWithId,
  stripInsertInputClientFields,
} from 'graphql-codegen-hasura-core';

import { ClientContactFilterFragment } from '../';
import { ClientContactFilter } from '../';
import { ClientContactFilterFragmentDoc } from '../';
import { QueryClientContactFilterObjectsQueryVariables } from '../';
import { QueryClientContactFilterByIdQuery } from '../';
import { QueryClientContactFilterByIdDocument } from '../';
import { QueryClientContactFilterByIdQueryVariables } from '../';
import { QueryClientContactFilterObjectsQuery } from '../';
import { QueryClientContactFilterObjectsDocument } from '../';
import { SubscribeToClientContactFilterByIdSubscription } from '../';
import { SubscribeToClientContactFilterByIdDocument } from '../';
import { SubscribeToClientContactFilterByIdSubscriptionVariables } from '../';
import { SubscribeToClientContactFilterObjectsSubscription } from '../';
import { SubscribeToClientContactFilterObjectsDocument } from '../';
import { SubscribeToClientContactFilterObjectsSubscriptionVariables } from '../';
import { ClientContactFilter_Insert_Input } from '../';
import { ClientContactFilter_On_Conflict } from '../';
import { InsertClientContactFilterMutation } from '../';
import { InsertClientContactFilterWithOnConflictMutation } from '../';
import { InsertClientContactFilterMutationVariables } from '../';
import { InsertClientContactFilterWithOnConflictMutationVariables } from '../';
import { InsertClientContactFilterDocument } from '../';
import { InsertClientContactFilterWithOnConflictDocument } from '../';
import { ClientContactFilter_Set_Input } from '../';
import { UpdateClientContactFilterByIdMutation } from '../';
import { UpdateClientContactFilterByIdMutationVariables } from '../';
import { UpdateClientContactFilterByIdDocument } from '../';
import { UpdateClientContactFilterMutation } from '../';
import { UpdateClientContactFilterMutationVariables } from '../';
import { UpdateClientContactFilterDocument } from '../';
import { RemoveClientContactFilterModelMutation } from '../';
import { RemoveClientContactFilterModelMutationVariables } from '../';
import { RemoveClientContactFilterModelDocument } from '../';
import { RemoveClientContactFilterModelByIdMutation } from '../';
import { RemoveClientContactFilterModelByIdMutationVariables } from '../';
import { RemoveClientContactFilterModelByIdDocument } from '../';
import { ClientContactJobChangeFragment } from '../';
import { ClientContactJobChange } from '../';
import { ClientContactJobChangeFragmentDoc } from '../';
import { QueryClientContactJobChangeObjectsQueryVariables } from '../';
import { QueryClientContactJobChangeByIdQuery } from '../';
import { QueryClientContactJobChangeByIdDocument } from '../';
import { QueryClientContactJobChangeByIdQueryVariables } from '../';
import { QueryClientContactJobChangeObjectsQuery } from '../';
import { QueryClientContactJobChangeObjectsDocument } from '../';
import { SubscribeToClientContactJobChangeByIdSubscription } from '../';
import { SubscribeToClientContactJobChangeByIdDocument } from '../';
import { SubscribeToClientContactJobChangeByIdSubscriptionVariables } from '../';
import { SubscribeToClientContactJobChangeObjectsSubscription } from '../';
import { SubscribeToClientContactJobChangeObjectsDocument } from '../';
import { SubscribeToClientContactJobChangeObjectsSubscriptionVariables } from '../';
import { ClientContactJobChange_Insert_Input } from '../';
import { ClientContactJobChange_On_Conflict } from '../';
import { InsertClientContactJobChangeMutation } from '../';
import { InsertClientContactJobChangeWithOnConflictMutation } from '../';
import { InsertClientContactJobChangeMutationVariables } from '../';
import { InsertClientContactJobChangeWithOnConflictMutationVariables } from '../';
import { InsertClientContactJobChangeDocument } from '../';
import { InsertClientContactJobChangeWithOnConflictDocument } from '../';
import { ClientContactJobChange_Set_Input } from '../';
import { UpdateClientContactJobChangeByIdMutation } from '../';
import { UpdateClientContactJobChangeByIdMutationVariables } from '../';
import { UpdateClientContactJobChangeByIdDocument } from '../';
import { UpdateClientContactJobChangeMutation } from '../';
import { UpdateClientContactJobChangeMutationVariables } from '../';
import { UpdateClientContactJobChangeDocument } from '../';
import { RemoveClientContactJobChangeModelMutation } from '../';
import { RemoveClientContactJobChangeModelMutationVariables } from '../';
import { RemoveClientContactJobChangeModelDocument } from '../';
import { RemoveClientContactJobChangeModelByIdMutation } from '../';
import { RemoveClientContactJobChangeModelByIdMutationVariables } from '../';
import { RemoveClientContactJobChangeModelByIdDocument } from '../';
import { ClientContactExternalFragment } from '../';
import { ClientContact } from '../';
import { ClientContactExternalFragmentDoc } from '../';
import { QueryClientContactExternalObjectsQueryVariables } from '../';
import { QueryClientContactExternalByIdQuery } from '../';
import { QueryClientContactExternalByIdDocument } from '../';
import { QueryClientContactExternalByIdQueryVariables } from '../';
import { QueryClientContactExternalObjectsQuery } from '../';
import { QueryClientContactExternalObjectsDocument } from '../';
import { SubscribeToClientContactExternalByIdSubscription } from '../';
import { SubscribeToClientContactExternalByIdDocument } from '../';
import { SubscribeToClientContactExternalByIdSubscriptionVariables } from '../';
import { SubscribeToClientContactExternalObjectsSubscription } from '../';
import { SubscribeToClientContactExternalObjectsDocument } from '../';
import { SubscribeToClientContactExternalObjectsSubscriptionVariables } from '../';
import { ClientContact_Insert_Input } from '../';
import { ClientContact_On_Conflict } from '../';
import { InsertClientContactExternalMutation } from '../';
import { InsertClientContactExternalWithOnConflictMutation } from '../';
import { InsertClientContactExternalMutationVariables } from '../';
import { InsertClientContactExternalWithOnConflictMutationVariables } from '../';
import { InsertClientContactExternalDocument } from '../';
import { InsertClientContactExternalWithOnConflictDocument } from '../';
import { ClientContact_Set_Input } from '../';
import { UpdateClientContactExternalByIdMutation } from '../';
import { UpdateClientContactExternalByIdMutationVariables } from '../';
import { UpdateClientContactExternalByIdDocument } from '../';
import { UpdateClientContactExternalMutation } from '../';
import { UpdateClientContactExternalMutationVariables } from '../';
import { UpdateClientContactExternalDocument } from '../';
import { RemoveClientContactModelMutation } from '../';
import { RemoveClientContactModelMutationVariables } from '../';
import { RemoveClientContactModelDocument } from '../';
import { RemoveClientContactModelByIdMutation } from '../';
import { RemoveClientContactModelByIdMutationVariables } from '../';
import { RemoveClientContactModelByIdDocument } from '../';
import { ClientContactQaFragment } from '../';
import { ClientContactQaFragmentDoc } from '../';
import { QueryClientContactQaObjectsQueryVariables } from '../';
import { QueryClientContactQaByIdQuery } from '../';
import { QueryClientContactQaByIdDocument } from '../';
import { QueryClientContactQaByIdQueryVariables } from '../';
import { QueryClientContactQaObjectsQuery } from '../';
import { QueryClientContactQaObjectsDocument } from '../';
import { SubscribeToClientContactQaByIdSubscription } from '../';
import { SubscribeToClientContactQaByIdDocument } from '../';
import { SubscribeToClientContactQaByIdSubscriptionVariables } from '../';
import { SubscribeToClientContactQaObjectsSubscription } from '../';
import { SubscribeToClientContactQaObjectsDocument } from '../';
import { SubscribeToClientContactQaObjectsSubscriptionVariables } from '../';
import { InsertClientContactQaMutation } from '../';
import { InsertClientContactQaWithOnConflictMutation } from '../';
import { InsertClientContactQaMutationVariables } from '../';
import { InsertClientContactQaWithOnConflictMutationVariables } from '../';
import { InsertClientContactQaDocument } from '../';
import { InsertClientContactQaWithOnConflictDocument } from '../';
import { UpdateClientContactQaByIdMutation } from '../';
import { UpdateClientContactQaByIdMutationVariables } from '../';
import { UpdateClientContactQaByIdDocument } from '../';
import { UpdateClientContactQaMutation } from '../';
import { UpdateClientContactQaMutationVariables } from '../';
import { UpdateClientContactQaDocument } from '../';
import { CrmContactEnrichmentHistoryFragment } from '../';
import { CrmContactEnrichmentHistory } from '../';
import { CrmContactEnrichmentHistoryFragmentDoc } from '../';
import { QueryCrmContactEnrichmentHistoryObjectsQueryVariables } from '../';
import { QueryCrmContactEnrichmentHistoryByIdQuery } from '../';
import { QueryCrmContactEnrichmentHistoryByIdDocument } from '../';
import { QueryCrmContactEnrichmentHistoryByIdQueryVariables } from '../';
import { QueryCrmContactEnrichmentHistoryObjectsQuery } from '../';
import { QueryCrmContactEnrichmentHistoryObjectsDocument } from '../';
import { SubscribeToCrmContactEnrichmentHistoryByIdSubscription } from '../';
import { SubscribeToCrmContactEnrichmentHistoryByIdDocument } from '../';
import { SubscribeToCrmContactEnrichmentHistoryByIdSubscriptionVariables } from '../';
import { SubscribeToCrmContactEnrichmentHistoryObjectsSubscription } from '../';
import { SubscribeToCrmContactEnrichmentHistoryObjectsDocument } from '../';
import { SubscribeToCrmContactEnrichmentHistoryObjectsSubscriptionVariables } from '../';
import { CrmContactEnrichmentHistory_Insert_Input } from '../';
import { CrmContactEnrichmentHistory_On_Conflict } from '../';
import { InsertCrmContactEnrichmentHistoryMutation } from '../';
import { InsertCrmContactEnrichmentHistoryWithOnConflictMutation } from '../';
import { InsertCrmContactEnrichmentHistoryMutationVariables } from '../';
import { InsertCrmContactEnrichmentHistoryWithOnConflictMutationVariables } from '../';
import { InsertCrmContactEnrichmentHistoryDocument } from '../';
import { InsertCrmContactEnrichmentHistoryWithOnConflictDocument } from '../';
import { CrmContactEnrichmentHistory_Set_Input } from '../';
import { UpdateCrmContactEnrichmentHistoryByIdMutation } from '../';
import { UpdateCrmContactEnrichmentHistoryByIdMutationVariables } from '../';
import { UpdateCrmContactEnrichmentHistoryByIdDocument } from '../';
import { UpdateCrmContactEnrichmentHistoryMutation } from '../';
import { UpdateCrmContactEnrichmentHistoryMutationVariables } from '../';
import { UpdateCrmContactEnrichmentHistoryDocument } from '../';
import { RemoveCrmContactEnrichmentHistoryModelMutation } from '../';
import { RemoveCrmContactEnrichmentHistoryModelMutationVariables } from '../';
import { RemoveCrmContactEnrichmentHistoryModelDocument } from '../';
import { RemoveCrmContactEnrichmentHistoryModelByIdMutation } from '../';
import { RemoveCrmContactEnrichmentHistoryModelByIdMutationVariables } from '../';
import { RemoveCrmContactEnrichmentHistoryModelByIdDocument } from '../';
import { ClientContactCrmDataFragment } from '../';
import { ClientContactCrmData } from '../';
import { ClientContactCrmDataFragmentDoc } from '../';
import { QueryClientContactCrmDataObjectsQueryVariables } from '../';
import { QueryClientContactCrmDataByIdQuery } from '../';
import { QueryClientContactCrmDataByIdDocument } from '../';
import { QueryClientContactCrmDataByIdQueryVariables } from '../';
import { QueryClientContactCrmDataObjectsQuery } from '../';
import { QueryClientContactCrmDataObjectsDocument } from '../';
import { SubscribeToClientContactCrmDataByIdSubscription } from '../';
import { SubscribeToClientContactCrmDataByIdDocument } from '../';
import { SubscribeToClientContactCrmDataByIdSubscriptionVariables } from '../';
import { SubscribeToClientContactCrmDataObjectsSubscription } from '../';
import { SubscribeToClientContactCrmDataObjectsDocument } from '../';
import { SubscribeToClientContactCrmDataObjectsSubscriptionVariables } from '../';
import { ClientContactCrmData_Insert_Input } from '../';
import { ClientContactCrmData_On_Conflict } from '../';
import { InsertClientContactCrmDataMutation } from '../';
import { InsertClientContactCrmDataWithOnConflictMutation } from '../';
import { InsertClientContactCrmDataMutationVariables } from '../';
import { InsertClientContactCrmDataWithOnConflictMutationVariables } from '../';
import { InsertClientContactCrmDataDocument } from '../';
import { InsertClientContactCrmDataWithOnConflictDocument } from '../';
import { ClientContactCrmData_Set_Input } from '../';
import { UpdateClientContactCrmDataByIdMutation } from '../';
import { UpdateClientContactCrmDataByIdMutationVariables } from '../';
import { UpdateClientContactCrmDataByIdDocument } from '../';
import { UpdateClientContactCrmDataMutation } from '../';
import { UpdateClientContactCrmDataMutationVariables } from '../';
import { UpdateClientContactCrmDataDocument } from '../';
import { RemoveClientContactCrmDataModelMutation } from '../';
import { RemoveClientContactCrmDataModelMutationVariables } from '../';
import { RemoveClientContactCrmDataModelDocument } from '../';
import { RemoveClientContactCrmDataModelByIdMutation } from '../';
import { RemoveClientContactCrmDataModelByIdMutationVariables } from '../';
import { RemoveClientContactCrmDataModelByIdDocument } from '../';
import { ClientExternalFragment } from '../';
import { Client } from '../';
import { ClientExternalFragmentDoc } from '../';
import { QueryClientExternalObjectsQueryVariables } from '../';
import { QueryClientExternalByIdQuery } from '../';
import { QueryClientExternalByIdDocument } from '../';
import { QueryClientExternalByIdQueryVariables } from '../';
import { QueryClientExternalObjectsQuery } from '../';
import { QueryClientExternalObjectsDocument } from '../';
import { SubscribeToClientExternalByIdSubscription } from '../';
import { SubscribeToClientExternalByIdDocument } from '../';
import { SubscribeToClientExternalByIdSubscriptionVariables } from '../';
import { SubscribeToClientExternalObjectsSubscription } from '../';
import { SubscribeToClientExternalObjectsDocument } from '../';
import { SubscribeToClientExternalObjectsSubscriptionVariables } from '../';
import { Client_Insert_Input } from '../';
import { Client_On_Conflict } from '../';
import { InsertClientExternalMutation } from '../';
import { InsertClientExternalWithOnConflictMutation } from '../';
import { InsertClientExternalMutationVariables } from '../';
import { InsertClientExternalWithOnConflictMutationVariables } from '../';
import { InsertClientExternalDocument } from '../';
import { InsertClientExternalWithOnConflictDocument } from '../';
import { Client_Set_Input } from '../';
import { UpdateClientExternalByIdMutation } from '../';
import { UpdateClientExternalByIdMutationVariables } from '../';
import { UpdateClientExternalByIdDocument } from '../';
import { UpdateClientExternalMutation } from '../';
import { UpdateClientExternalMutationVariables } from '../';
import { UpdateClientExternalDocument } from '../';
import { RemoveClientModelMutation } from '../';
import { RemoveClientModelMutationVariables } from '../';
import { RemoveClientModelDocument } from '../';
import { RemoveClientModelByIdMutation } from '../';
import { RemoveClientModelByIdMutationVariables } from '../';
import { RemoveClientModelByIdDocument } from '../';
import { ClientQaFragment } from '../';
import { ClientQaFragmentDoc } from '../';
import { QueryClientQaObjectsQueryVariables } from '../';
import { QueryClientQaByIdQuery } from '../';
import { QueryClientQaByIdDocument } from '../';
import { QueryClientQaByIdQueryVariables } from '../';
import { QueryClientQaObjectsQuery } from '../';
import { QueryClientQaObjectsDocument } from '../';
import { SubscribeToClientQaByIdSubscription } from '../';
import { SubscribeToClientQaByIdDocument } from '../';
import { SubscribeToClientQaByIdSubscriptionVariables } from '../';
import { SubscribeToClientQaObjectsSubscription } from '../';
import { SubscribeToClientQaObjectsDocument } from '../';
import { SubscribeToClientQaObjectsSubscriptionVariables } from '../';
import { InsertClientQaMutation } from '../';
import { InsertClientQaWithOnConflictMutation } from '../';
import { InsertClientQaMutationVariables } from '../';
import { InsertClientQaWithOnConflictMutationVariables } from '../';
import { InsertClientQaDocument } from '../';
import { InsertClientQaWithOnConflictDocument } from '../';
import { UpdateClientQaByIdMutation } from '../';
import { UpdateClientQaByIdMutationVariables } from '../';
import { UpdateClientQaByIdDocument } from '../';
import { UpdateClientQaMutation } from '../';
import { UpdateClientQaMutationVariables } from '../';
import { UpdateClientQaDocument } from '../';
import { ClientInternalFragment } from '../';
import { ClientInternalFragmentDoc } from '../';
import { QueryClientInternalObjectsQueryVariables } from '../';
import { QueryClientInternalByIdQuery } from '../';
import { QueryClientInternalByIdDocument } from '../';
import { QueryClientInternalByIdQueryVariables } from '../';
import { QueryClientInternalObjectsQuery } from '../';
import { QueryClientInternalObjectsDocument } from '../';
import { SubscribeToClientInternalByIdSubscription } from '../';
import { SubscribeToClientInternalByIdDocument } from '../';
import { SubscribeToClientInternalByIdSubscriptionVariables } from '../';
import { SubscribeToClientInternalObjectsSubscription } from '../';
import { SubscribeToClientInternalObjectsDocument } from '../';
import { SubscribeToClientInternalObjectsSubscriptionVariables } from '../';
import { InsertClientInternalMutation } from '../';
import { InsertClientInternalWithOnConflictMutation } from '../';
import { InsertClientInternalMutationVariables } from '../';
import { InsertClientInternalWithOnConflictMutationVariables } from '../';
import { InsertClientInternalDocument } from '../';
import { InsertClientInternalWithOnConflictDocument } from '../';
import { UpdateClientInternalByIdMutation } from '../';
import { UpdateClientInternalByIdMutationVariables } from '../';
import { UpdateClientInternalByIdDocument } from '../';
import { UpdateClientInternalMutation } from '../';
import { UpdateClientInternalMutationVariables } from '../';
import { UpdateClientInternalDocument } from '../';
import { ClientSelectFragment } from '../';
import { ClientSelectFragmentDoc } from '../';
import { QueryClientSelectObjectsQueryVariables } from '../';
import { QueryClientSelectByIdQuery } from '../';
import { QueryClientSelectByIdDocument } from '../';
import { QueryClientSelectByIdQueryVariables } from '../';
import { QueryClientSelectObjectsQuery } from '../';
import { QueryClientSelectObjectsDocument } from '../';
import { SubscribeToClientSelectByIdSubscription } from '../';
import { SubscribeToClientSelectByIdDocument } from '../';
import { SubscribeToClientSelectByIdSubscriptionVariables } from '../';
import { SubscribeToClientSelectObjectsSubscription } from '../';
import { SubscribeToClientSelectObjectsDocument } from '../';
import { SubscribeToClientSelectObjectsSubscriptionVariables } from '../';
import { InsertClientSelectMutation } from '../';
import { InsertClientSelectWithOnConflictMutation } from '../';
import { InsertClientSelectMutationVariables } from '../';
import { InsertClientSelectWithOnConflictMutationVariables } from '../';
import { InsertClientSelectDocument } from '../';
import { InsertClientSelectWithOnConflictDocument } from '../';
import { UpdateClientSelectByIdMutation } from '../';
import { UpdateClientSelectByIdMutationVariables } from '../';
import { UpdateClientSelectByIdDocument } from '../';
import { UpdateClientSelectMutation } from '../';
import { UpdateClientSelectMutationVariables } from '../';
import { UpdateClientSelectDocument } from '../';
import { ClientNotificationEmailFragment } from '../';
import { ClientNotificationEmail } from '../';
import { ClientNotificationEmailFragmentDoc } from '../';
import { QueryClientNotificationEmailObjectsQueryVariables } from '../';
import { QueryClientNotificationEmailByIdQuery } from '../';
import { QueryClientNotificationEmailByIdDocument } from '../';
import { QueryClientNotificationEmailByIdQueryVariables } from '../';
import { QueryClientNotificationEmailObjectsQuery } from '../';
import { QueryClientNotificationEmailObjectsDocument } from '../';
import { SubscribeToClientNotificationEmailByIdSubscription } from '../';
import { SubscribeToClientNotificationEmailByIdDocument } from '../';
import { SubscribeToClientNotificationEmailByIdSubscriptionVariables } from '../';
import { SubscribeToClientNotificationEmailObjectsSubscription } from '../';
import { SubscribeToClientNotificationEmailObjectsDocument } from '../';
import { SubscribeToClientNotificationEmailObjectsSubscriptionVariables } from '../';
import { ClientNotificationEmail_Insert_Input } from '../';
import { ClientNotificationEmail_On_Conflict } from '../';
import { InsertClientNotificationEmailMutation } from '../';
import { InsertClientNotificationEmailWithOnConflictMutation } from '../';
import { InsertClientNotificationEmailMutationVariables } from '../';
import { InsertClientNotificationEmailWithOnConflictMutationVariables } from '../';
import { InsertClientNotificationEmailDocument } from '../';
import { InsertClientNotificationEmailWithOnConflictDocument } from '../';
import { ClientNotificationEmail_Set_Input } from '../';
import { UpdateClientNotificationEmailByIdMutation } from '../';
import { UpdateClientNotificationEmailByIdMutationVariables } from '../';
import { UpdateClientNotificationEmailByIdDocument } from '../';
import { UpdateClientNotificationEmailMutation } from '../';
import { UpdateClientNotificationEmailMutationVariables } from '../';
import { UpdateClientNotificationEmailDocument } from '../';
import { RemoveClientNotificationEmailModelMutation } from '../';
import { RemoveClientNotificationEmailModelMutationVariables } from '../';
import { RemoveClientNotificationEmailModelDocument } from '../';
import { RemoveClientNotificationEmailModelByIdMutation } from '../';
import { RemoveClientNotificationEmailModelByIdMutationVariables } from '../';
import { RemoveClientNotificationEmailModelByIdDocument } from '../';
import { CloudTaskFragment } from '../';
import { CloudTask } from '../';
import { CloudTaskFragmentDoc } from '../';
import { QueryCloudTaskObjectsQueryVariables } from '../';
import { QueryCloudTaskByIdQuery } from '../';
import { QueryCloudTaskByIdDocument } from '../';
import { QueryCloudTaskByIdQueryVariables } from '../';
import { QueryCloudTaskObjectsQuery } from '../';
import { QueryCloudTaskObjectsDocument } from '../';
import { SubscribeToCloudTaskByIdSubscription } from '../';
import { SubscribeToCloudTaskByIdDocument } from '../';
import { SubscribeToCloudTaskByIdSubscriptionVariables } from '../';
import { SubscribeToCloudTaskObjectsSubscription } from '../';
import { SubscribeToCloudTaskObjectsDocument } from '../';
import { SubscribeToCloudTaskObjectsSubscriptionVariables } from '../';
import { CloudTask_Insert_Input } from '../';
import { CloudTask_On_Conflict } from '../';
import { InsertCloudTaskMutation } from '../';
import { InsertCloudTaskWithOnConflictMutation } from '../';
import { InsertCloudTaskMutationVariables } from '../';
import { InsertCloudTaskWithOnConflictMutationVariables } from '../';
import { InsertCloudTaskDocument } from '../';
import { InsertCloudTaskWithOnConflictDocument } from '../';
import { CloudTask_Set_Input } from '../';
import { UpdateCloudTaskByIdMutation } from '../';
import { UpdateCloudTaskByIdMutationVariables } from '../';
import { UpdateCloudTaskByIdDocument } from '../';
import { UpdateCloudTaskMutation } from '../';
import { UpdateCloudTaskMutationVariables } from '../';
import { UpdateCloudTaskDocument } from '../';
import { RemoveCloudTaskModelMutation } from '../';
import { RemoveCloudTaskModelMutationVariables } from '../';
import { RemoveCloudTaskModelDocument } from '../';
import { RemoveCloudTaskModelByIdMutation } from '../';
import { RemoveCloudTaskModelByIdMutationVariables } from '../';
import { RemoveCloudTaskModelByIdDocument } from '../';
import { ClientEmailReportFragment } from '../';
import { ClientEmailReport } from '../';
import { ClientEmailReportFragmentDoc } from '../';
import { QueryClientEmailReportObjectsQueryVariables } from '../';
import { QueryClientEmailReportByIdQuery } from '../';
import { QueryClientEmailReportByIdDocument } from '../';
import { QueryClientEmailReportByIdQueryVariables } from '../';
import { QueryClientEmailReportObjectsQuery } from '../';
import { QueryClientEmailReportObjectsDocument } from '../';
import { SubscribeToClientEmailReportByIdSubscription } from '../';
import { SubscribeToClientEmailReportByIdDocument } from '../';
import { SubscribeToClientEmailReportByIdSubscriptionVariables } from '../';
import { SubscribeToClientEmailReportObjectsSubscription } from '../';
import { SubscribeToClientEmailReportObjectsDocument } from '../';
import { SubscribeToClientEmailReportObjectsSubscriptionVariables } from '../';
import { ClientEmailReport_Insert_Input } from '../';
import { ClientEmailReport_On_Conflict } from '../';
import { InsertClientEmailReportMutation } from '../';
import { InsertClientEmailReportWithOnConflictMutation } from '../';
import { InsertClientEmailReportMutationVariables } from '../';
import { InsertClientEmailReportWithOnConflictMutationVariables } from '../';
import { InsertClientEmailReportDocument } from '../';
import { InsertClientEmailReportWithOnConflictDocument } from '../';
import { ClientEmailReport_Set_Input } from '../';
import { UpdateClientEmailReportByIdMutation } from '../';
import { UpdateClientEmailReportByIdMutationVariables } from '../';
import { UpdateClientEmailReportByIdDocument } from '../';
import { UpdateClientEmailReportMutation } from '../';
import { UpdateClientEmailReportMutationVariables } from '../';
import { UpdateClientEmailReportDocument } from '../';
import { RemoveClientEmailReportModelMutation } from '../';
import { RemoveClientEmailReportModelMutationVariables } from '../';
import { RemoveClientEmailReportModelDocument } from '../';
import { RemoveClientEmailReportModelByIdMutation } from '../';
import { RemoveClientEmailReportModelByIdMutationVariables } from '../';
import { RemoveClientEmailReportModelByIdDocument } from '../';
import { EmailHistoryFragment } from '../';
import { EmailHistory } from '../';
import { EmailHistoryFragmentDoc } from '../';
import { QueryEmailHistoryObjectsQueryVariables } from '../';
import { QueryEmailHistoryByIdQuery } from '../';
import { QueryEmailHistoryByIdDocument } from '../';
import { QueryEmailHistoryByIdQueryVariables } from '../';
import { QueryEmailHistoryObjectsQuery } from '../';
import { QueryEmailHistoryObjectsDocument } from '../';
import { SubscribeToEmailHistoryByIdSubscription } from '../';
import { SubscribeToEmailHistoryByIdDocument } from '../';
import { SubscribeToEmailHistoryByIdSubscriptionVariables } from '../';
import { SubscribeToEmailHistoryObjectsSubscription } from '../';
import { SubscribeToEmailHistoryObjectsDocument } from '../';
import { SubscribeToEmailHistoryObjectsSubscriptionVariables } from '../';
import { EmailHistory_Insert_Input } from '../';
import { EmailHistory_On_Conflict } from '../';
import { InsertEmailHistoryMutation } from '../';
import { InsertEmailHistoryWithOnConflictMutation } from '../';
import { InsertEmailHistoryMutationVariables } from '../';
import { InsertEmailHistoryWithOnConflictMutationVariables } from '../';
import { InsertEmailHistoryDocument } from '../';
import { InsertEmailHistoryWithOnConflictDocument } from '../';
import { EmailHistory_Set_Input } from '../';
import { UpdateEmailHistoryByIdMutation } from '../';
import { UpdateEmailHistoryByIdMutationVariables } from '../';
import { UpdateEmailHistoryByIdDocument } from '../';
import { UpdateEmailHistoryMutation } from '../';
import { UpdateEmailHistoryMutationVariables } from '../';
import { UpdateEmailHistoryDocument } from '../';
import { RemoveEmailHistoryModelMutation } from '../';
import { RemoveEmailHistoryModelMutationVariables } from '../';
import { RemoveEmailHistoryModelDocument } from '../';
import { RemoveEmailHistoryModelByIdMutation } from '../';
import { RemoveEmailHistoryModelByIdMutationVariables } from '../';
import { RemoveEmailHistoryModelByIdDocument } from '../';
import { SendgridEventFragment } from '../';
import { SendgridEvent } from '../';
import { SendgridEventFragmentDoc } from '../';
import { QuerySendgridEventObjectsQueryVariables } from '../';
import { QuerySendgridEventByIdQuery } from '../';
import { QuerySendgridEventByIdDocument } from '../';
import { QuerySendgridEventByIdQueryVariables } from '../';
import { QuerySendgridEventObjectsQuery } from '../';
import { QuerySendgridEventObjectsDocument } from '../';
import { SubscribeToSendgridEventByIdSubscription } from '../';
import { SubscribeToSendgridEventByIdDocument } from '../';
import { SubscribeToSendgridEventByIdSubscriptionVariables } from '../';
import { SubscribeToSendgridEventObjectsSubscription } from '../';
import { SubscribeToSendgridEventObjectsDocument } from '../';
import { SubscribeToSendgridEventObjectsSubscriptionVariables } from '../';
import { SendgridEvent_Insert_Input } from '../';
import { SendgridEvent_On_Conflict } from '../';
import { InsertSendgridEventMutation } from '../';
import { InsertSendgridEventWithOnConflictMutation } from '../';
import { InsertSendgridEventMutationVariables } from '../';
import { InsertSendgridEventWithOnConflictMutationVariables } from '../';
import { InsertSendgridEventDocument } from '../';
import { InsertSendgridEventWithOnConflictDocument } from '../';
import { SendgridEvent_Set_Input } from '../';
import { UpdateSendgridEventByIdMutation } from '../';
import { UpdateSendgridEventByIdMutationVariables } from '../';
import { UpdateSendgridEventByIdDocument } from '../';
import { UpdateSendgridEventMutation } from '../';
import { UpdateSendgridEventMutationVariables } from '../';
import { UpdateSendgridEventDocument } from '../';
import { RemoveSendgridEventModelMutation } from '../';
import { RemoveSendgridEventModelMutationVariables } from '../';
import { RemoveSendgridEventModelDocument } from '../';
import { RemoveSendgridEventModelByIdMutation } from '../';
import { RemoveSendgridEventModelByIdMutationVariables } from '../';
import { RemoveSendgridEventModelByIdDocument } from '../';
import { CsvUploadFragment } from '../';
import { CsvUpload } from '../';
import { CsvUploadFragmentDoc } from '../';
import { QueryCsvUploadObjectsQueryVariables } from '../';
import { QueryCsvUploadByIdQuery } from '../';
import { QueryCsvUploadByIdDocument } from '../';
import { QueryCsvUploadByIdQueryVariables } from '../';
import { QueryCsvUploadObjectsQuery } from '../';
import { QueryCsvUploadObjectsDocument } from '../';
import { SubscribeToCsvUploadByIdSubscription } from '../';
import { SubscribeToCsvUploadByIdDocument } from '../';
import { SubscribeToCsvUploadByIdSubscriptionVariables } from '../';
import { SubscribeToCsvUploadObjectsSubscription } from '../';
import { SubscribeToCsvUploadObjectsDocument } from '../';
import { SubscribeToCsvUploadObjectsSubscriptionVariables } from '../';
import { CsvUpload_Insert_Input } from '../';
import { CsvUpload_On_Conflict } from '../';
import { InsertCsvUploadMutation } from '../';
import { InsertCsvUploadWithOnConflictMutation } from '../';
import { InsertCsvUploadMutationVariables } from '../';
import { InsertCsvUploadWithOnConflictMutationVariables } from '../';
import { InsertCsvUploadDocument } from '../';
import { InsertCsvUploadWithOnConflictDocument } from '../';
import { CsvUpload_Set_Input } from '../';
import { UpdateCsvUploadByIdMutation } from '../';
import { UpdateCsvUploadByIdMutationVariables } from '../';
import { UpdateCsvUploadByIdDocument } from '../';
import { UpdateCsvUploadMutation } from '../';
import { UpdateCsvUploadMutationVariables } from '../';
import { UpdateCsvUploadDocument } from '../';
import { RemoveCsvUploadModelMutation } from '../';
import { RemoveCsvUploadModelMutationVariables } from '../';
import { RemoveCsvUploadModelDocument } from '../';
import { RemoveCsvUploadModelByIdMutation } from '../';
import { RemoveCsvUploadModelByIdMutationVariables } from '../';
import { RemoveCsvUploadModelByIdDocument } from '../';
import { HubspotContactListFragment } from '../';
import { HubspotContactList } from '../';
import { HubspotContactListFragmentDoc } from '../';
import { QueryHubspotContactListObjectsQueryVariables } from '../';
import { QueryHubspotContactListByIdQuery } from '../';
import { QueryHubspotContactListByIdDocument } from '../';
import { QueryHubspotContactListByIdQueryVariables } from '../';
import { QueryHubspotContactListObjectsQuery } from '../';
import { QueryHubspotContactListObjectsDocument } from '../';
import { SubscribeToHubspotContactListByIdSubscription } from '../';
import { SubscribeToHubspotContactListByIdDocument } from '../';
import { SubscribeToHubspotContactListByIdSubscriptionVariables } from '../';
import { SubscribeToHubspotContactListObjectsSubscription } from '../';
import { SubscribeToHubspotContactListObjectsDocument } from '../';
import { SubscribeToHubspotContactListObjectsSubscriptionVariables } from '../';
import { HubspotContactList_Insert_Input } from '../';
import { HubspotContactList_On_Conflict } from '../';
import { InsertHubspotContactListMutation } from '../';
import { InsertHubspotContactListWithOnConflictMutation } from '../';
import { InsertHubspotContactListMutationVariables } from '../';
import { InsertHubspotContactListWithOnConflictMutationVariables } from '../';
import { InsertHubspotContactListDocument } from '../';
import { InsertHubspotContactListWithOnConflictDocument } from '../';
import { HubspotContactList_Set_Input } from '../';
import { UpdateHubspotContactListByIdMutation } from '../';
import { UpdateHubspotContactListByIdMutationVariables } from '../';
import { UpdateHubspotContactListByIdDocument } from '../';
import { UpdateHubspotContactListMutation } from '../';
import { UpdateHubspotContactListMutationVariables } from '../';
import { UpdateHubspotContactListDocument } from '../';
import { RemoveHubspotContactListModelMutation } from '../';
import { RemoveHubspotContactListModelMutationVariables } from '../';
import { RemoveHubspotContactListModelDocument } from '../';
import { RemoveHubspotContactListModelByIdMutation } from '../';
import { RemoveHubspotContactListModelByIdMutationVariables } from '../';
import { RemoveHubspotContactListModelByIdDocument } from '../';
import { HubspotContactListHubspotContactFragment } from '../';
import { HubspotContactListHubspotContact } from '../';
import { HubspotContactListHubspotContactFragmentDoc } from '../';
import { QueryHubspotContactListHubspotContactObjectsQueryVariables } from '../';
import { QueryHubspotContactListHubspotContactByIdQuery } from '../';
import { QueryHubspotContactListHubspotContactByIdDocument } from '../';
import { QueryHubspotContactListHubspotContactByIdQueryVariables } from '../';
import { QueryHubspotContactListHubspotContactObjectsQuery } from '../';
import { QueryHubspotContactListHubspotContactObjectsDocument } from '../';
import { SubscribeToHubspotContactListHubspotContactByIdSubscription } from '../';
import { SubscribeToHubspotContactListHubspotContactByIdDocument } from '../';
import { SubscribeToHubspotContactListHubspotContactByIdSubscriptionVariables } from '../';
import { SubscribeToHubspotContactListHubspotContactObjectsSubscription } from '../';
import { SubscribeToHubspotContactListHubspotContactObjectsDocument } from '../';
import { SubscribeToHubspotContactListHubspotContactObjectsSubscriptionVariables } from '../';
import { HubspotContactListHubspotContact_Insert_Input } from '../';
import { HubspotContactListHubspotContact_On_Conflict } from '../';
import { InsertHubspotContactListHubspotContactMutation } from '../';
import { InsertHubspotContactListHubspotContactWithOnConflictMutation } from '../';
import { InsertHubspotContactListHubspotContactMutationVariables } from '../';
import { InsertHubspotContactListHubspotContactWithOnConflictMutationVariables } from '../';
import { InsertHubspotContactListHubspotContactDocument } from '../';
import { InsertHubspotContactListHubspotContactWithOnConflictDocument } from '../';
import { HubspotContactListHubspotContact_Set_Input } from '../';
import { UpdateHubspotContactListHubspotContactByIdMutation } from '../';
import { UpdateHubspotContactListHubspotContactByIdMutationVariables } from '../';
import { UpdateHubspotContactListHubspotContactByIdDocument } from '../';
import { UpdateHubspotContactListHubspotContactMutation } from '../';
import { UpdateHubspotContactListHubspotContactMutationVariables } from '../';
import { UpdateHubspotContactListHubspotContactDocument } from '../';
import { RemoveHubspotContactListHubspotContactModelMutation } from '../';
import { RemoveHubspotContactListHubspotContactModelMutationVariables } from '../';
import { RemoveHubspotContactListHubspotContactModelDocument } from '../';
import { RemoveHubspotContactListHubspotContactModelByIdMutation } from '../';
import { RemoveHubspotContactListHubspotContactModelByIdMutationVariables } from '../';
import { RemoveHubspotContactListHubspotContactModelByIdDocument } from '../';
import { HubspotContactPropertyFragment } from '../';
import { HubspotContactProperty } from '../';
import { HubspotContactPropertyFragmentDoc } from '../';
import { QueryHubspotContactPropertyObjectsQueryVariables } from '../';
import { QueryHubspotContactPropertyByIdQuery } from '../';
import { QueryHubspotContactPropertyByIdDocument } from '../';
import { QueryHubspotContactPropertyByIdQueryVariables } from '../';
import { QueryHubspotContactPropertyObjectsQuery } from '../';
import { QueryHubspotContactPropertyObjectsDocument } from '../';
import { SubscribeToHubspotContactPropertyByIdSubscription } from '../';
import { SubscribeToHubspotContactPropertyByIdDocument } from '../';
import { SubscribeToHubspotContactPropertyByIdSubscriptionVariables } from '../';
import { SubscribeToHubspotContactPropertyObjectsSubscription } from '../';
import { SubscribeToHubspotContactPropertyObjectsDocument } from '../';
import { SubscribeToHubspotContactPropertyObjectsSubscriptionVariables } from '../';
import { HubspotContactProperty_Insert_Input } from '../';
import { HubspotContactProperty_On_Conflict } from '../';
import { InsertHubspotContactPropertyMutation } from '../';
import { InsertHubspotContactPropertyWithOnConflictMutation } from '../';
import { InsertHubspotContactPropertyMutationVariables } from '../';
import { InsertHubspotContactPropertyWithOnConflictMutationVariables } from '../';
import { InsertHubspotContactPropertyDocument } from '../';
import { InsertHubspotContactPropertyWithOnConflictDocument } from '../';
import { HubspotContactProperty_Set_Input } from '../';
import { UpdateHubspotContactPropertyByIdMutation } from '../';
import { UpdateHubspotContactPropertyByIdMutationVariables } from '../';
import { UpdateHubspotContactPropertyByIdDocument } from '../';
import { UpdateHubspotContactPropertyMutation } from '../';
import { UpdateHubspotContactPropertyMutationVariables } from '../';
import { UpdateHubspotContactPropertyDocument } from '../';
import { RemoveHubspotContactPropertyModelMutation } from '../';
import { RemoveHubspotContactPropertyModelMutationVariables } from '../';
import { RemoveHubspotContactPropertyModelDocument } from '../';
import { RemoveHubspotContactPropertyModelByIdMutation } from '../';
import { RemoveHubspotContactPropertyModelByIdMutationVariables } from '../';
import { RemoveHubspotContactPropertyModelByIdDocument } from '../';
import { SalesforceContactFieldFragment } from '../';
import { SalesforceContactField } from '../';
import { SalesforceContactFieldFragmentDoc } from '../';
import { QuerySalesforceContactFieldObjectsQueryVariables } from '../';
import { QuerySalesforceContactFieldByIdQuery } from '../';
import { QuerySalesforceContactFieldByIdDocument } from '../';
import { QuerySalesforceContactFieldByIdQueryVariables } from '../';
import { QuerySalesforceContactFieldObjectsQuery } from '../';
import { QuerySalesforceContactFieldObjectsDocument } from '../';
import { SubscribeToSalesforceContactFieldByIdSubscription } from '../';
import { SubscribeToSalesforceContactFieldByIdDocument } from '../';
import { SubscribeToSalesforceContactFieldByIdSubscriptionVariables } from '../';
import { SubscribeToSalesforceContactFieldObjectsSubscription } from '../';
import { SubscribeToSalesforceContactFieldObjectsDocument } from '../';
import { SubscribeToSalesforceContactFieldObjectsSubscriptionVariables } from '../';
import { SalesforceContactField_Insert_Input } from '../';
import { SalesforceContactField_On_Conflict } from '../';
import { InsertSalesforceContactFieldMutation } from '../';
import { InsertSalesforceContactFieldWithOnConflictMutation } from '../';
import { InsertSalesforceContactFieldMutationVariables } from '../';
import { InsertSalesforceContactFieldWithOnConflictMutationVariables } from '../';
import { InsertSalesforceContactFieldDocument } from '../';
import { InsertSalesforceContactFieldWithOnConflictDocument } from '../';
import { SalesforceContactField_Set_Input } from '../';
import { UpdateSalesforceContactFieldByIdMutation } from '../';
import { UpdateSalesforceContactFieldByIdMutationVariables } from '../';
import { UpdateSalesforceContactFieldByIdDocument } from '../';
import { UpdateSalesforceContactFieldMutation } from '../';
import { UpdateSalesforceContactFieldMutationVariables } from '../';
import { UpdateSalesforceContactFieldDocument } from '../';
import { RemoveSalesforceContactFieldModelMutation } from '../';
import { RemoveSalesforceContactFieldModelMutationVariables } from '../';
import { RemoveSalesforceContactFieldModelDocument } from '../';
import { RemoveSalesforceContactFieldModelByIdMutation } from '../';
import { RemoveSalesforceContactFieldModelByIdMutationVariables } from '../';
import { RemoveSalesforceContactFieldModelByIdDocument } from '../';
import { HubspotIntegrationFragment } from '../';
import { HubspotIntegration } from '../';
import { HubspotIntegrationFragmentDoc } from '../';
import { QueryHubspotIntegrationObjectsQueryVariables } from '../';
import { QueryHubspotIntegrationByIdQuery } from '../';
import { QueryHubspotIntegrationByIdDocument } from '../';
import { QueryHubspotIntegrationByIdQueryVariables } from '../';
import { QueryHubspotIntegrationObjectsQuery } from '../';
import { QueryHubspotIntegrationObjectsDocument } from '../';
import { SubscribeToHubspotIntegrationByIdSubscription } from '../';
import { SubscribeToHubspotIntegrationByIdDocument } from '../';
import { SubscribeToHubspotIntegrationByIdSubscriptionVariables } from '../';
import { SubscribeToHubspotIntegrationObjectsSubscription } from '../';
import { SubscribeToHubspotIntegrationObjectsDocument } from '../';
import { SubscribeToHubspotIntegrationObjectsSubscriptionVariables } from '../';
import { HubspotIntegration_Insert_Input } from '../';
import { HubspotIntegration_On_Conflict } from '../';
import { InsertHubspotIntegrationMutation } from '../';
import { InsertHubspotIntegrationWithOnConflictMutation } from '../';
import { InsertHubspotIntegrationMutationVariables } from '../';
import { InsertHubspotIntegrationWithOnConflictMutationVariables } from '../';
import { InsertHubspotIntegrationDocument } from '../';
import { InsertHubspotIntegrationWithOnConflictDocument } from '../';
import { HubspotIntegration_Set_Input } from '../';
import { UpdateHubspotIntegrationByIdMutation } from '../';
import { UpdateHubspotIntegrationByIdMutationVariables } from '../';
import { UpdateHubspotIntegrationByIdDocument } from '../';
import { UpdateHubspotIntegrationMutation } from '../';
import { UpdateHubspotIntegrationMutationVariables } from '../';
import { UpdateHubspotIntegrationDocument } from '../';
import { RemoveHubspotIntegrationModelMutation } from '../';
import { RemoveHubspotIntegrationModelMutationVariables } from '../';
import { RemoveHubspotIntegrationModelDocument } from '../';
import { RemoveHubspotIntegrationModelByIdMutation } from '../';
import { RemoveHubspotIntegrationModelByIdMutationVariables } from '../';
import { RemoveHubspotIntegrationModelByIdDocument } from '../';
import { SalesforceIntegrationFragment } from '../';
import { SalesforceIntegration } from '../';
import { SalesforceIntegrationFragmentDoc } from '../';
import { QuerySalesforceIntegrationObjectsQueryVariables } from '../';
import { QuerySalesforceIntegrationByIdQuery } from '../';
import { QuerySalesforceIntegrationByIdDocument } from '../';
import { QuerySalesforceIntegrationByIdQueryVariables } from '../';
import { QuerySalesforceIntegrationObjectsQuery } from '../';
import { QuerySalesforceIntegrationObjectsDocument } from '../';
import { SubscribeToSalesforceIntegrationByIdSubscription } from '../';
import { SubscribeToSalesforceIntegrationByIdDocument } from '../';
import { SubscribeToSalesforceIntegrationByIdSubscriptionVariables } from '../';
import { SubscribeToSalesforceIntegrationObjectsSubscription } from '../';
import { SubscribeToSalesforceIntegrationObjectsDocument } from '../';
import { SubscribeToSalesforceIntegrationObjectsSubscriptionVariables } from '../';
import { SalesforceIntegration_Insert_Input } from '../';
import { SalesforceIntegration_On_Conflict } from '../';
import { InsertSalesforceIntegrationMutation } from '../';
import { InsertSalesforceIntegrationWithOnConflictMutation } from '../';
import { InsertSalesforceIntegrationMutationVariables } from '../';
import { InsertSalesforceIntegrationWithOnConflictMutationVariables } from '../';
import { InsertSalesforceIntegrationDocument } from '../';
import { InsertSalesforceIntegrationWithOnConflictDocument } from '../';
import { SalesforceIntegration_Set_Input } from '../';
import { UpdateSalesforceIntegrationByIdMutation } from '../';
import { UpdateSalesforceIntegrationByIdMutationVariables } from '../';
import { UpdateSalesforceIntegrationByIdDocument } from '../';
import { UpdateSalesforceIntegrationMutation } from '../';
import { UpdateSalesforceIntegrationMutationVariables } from '../';
import { UpdateSalesforceIntegrationDocument } from '../';
import { RemoveSalesforceIntegrationModelMutation } from '../';
import { RemoveSalesforceIntegrationModelMutationVariables } from '../';
import { RemoveSalesforceIntegrationModelDocument } from '../';
import { RemoveSalesforceIntegrationModelByIdMutation } from '../';
import { RemoveSalesforceIntegrationModelByIdMutationVariables } from '../';
import { RemoveSalesforceIntegrationModelByIdDocument } from '../';
import { QaTaskActionFragment } from '../';
import { QaTaskAction } from '../';
import { QaTaskActionFragmentDoc } from '../';
import { QueryQaTaskActionObjectsQueryVariables } from '../';
import { QueryQaTaskActionByIdQuery } from '../';
import { QueryQaTaskActionByIdDocument } from '../';
import { QueryQaTaskActionByIdQueryVariables } from '../';
import { QueryQaTaskActionObjectsQuery } from '../';
import { QueryQaTaskActionObjectsDocument } from '../';
import { SubscribeToQaTaskActionByIdSubscription } from '../';
import { SubscribeToQaTaskActionByIdDocument } from '../';
import { SubscribeToQaTaskActionByIdSubscriptionVariables } from '../';
import { SubscribeToQaTaskActionObjectsSubscription } from '../';
import { SubscribeToQaTaskActionObjectsDocument } from '../';
import { SubscribeToQaTaskActionObjectsSubscriptionVariables } from '../';
import { QaTaskAction_Insert_Input } from '../';
import { QaTaskAction_On_Conflict } from '../';
import { InsertQaTaskActionMutation } from '../';
import { InsertQaTaskActionWithOnConflictMutation } from '../';
import { InsertQaTaskActionMutationVariables } from '../';
import { InsertQaTaskActionWithOnConflictMutationVariables } from '../';
import { InsertQaTaskActionDocument } from '../';
import { InsertQaTaskActionWithOnConflictDocument } from '../';
import { QaTaskAction_Set_Input } from '../';
import { UpdateQaTaskActionByIdMutation } from '../';
import { UpdateQaTaskActionByIdMutationVariables } from '../';
import { UpdateQaTaskActionByIdDocument } from '../';
import { UpdateQaTaskActionMutation } from '../';
import { UpdateQaTaskActionMutationVariables } from '../';
import { UpdateQaTaskActionDocument } from '../';
import { RemoveQaTaskActionModelMutation } from '../';
import { RemoveQaTaskActionModelMutationVariables } from '../';
import { RemoveQaTaskActionModelDocument } from '../';
import { RemoveQaTaskActionModelByIdMutation } from '../';
import { RemoveQaTaskActionModelByIdMutationVariables } from '../';
import { RemoveQaTaskActionModelByIdDocument } from '../';
import { QaTaskFragment } from '../';
import { QaTask } from '../';
import { QaTaskFragmentDoc } from '../';
import { QueryQaTaskObjectsQueryVariables } from '../';
import { QueryQaTaskByIdQuery } from '../';
import { QueryQaTaskByIdDocument } from '../';
import { QueryQaTaskByIdQueryVariables } from '../';
import { QueryQaTaskObjectsQuery } from '../';
import { QueryQaTaskObjectsDocument } from '../';
import { SubscribeToQaTaskByIdSubscription } from '../';
import { SubscribeToQaTaskByIdDocument } from '../';
import { SubscribeToQaTaskByIdSubscriptionVariables } from '../';
import { SubscribeToQaTaskObjectsSubscription } from '../';
import { SubscribeToQaTaskObjectsDocument } from '../';
import { SubscribeToQaTaskObjectsSubscriptionVariables } from '../';
import { QaTask_Insert_Input } from '../';
import { QaTask_On_Conflict } from '../';
import { InsertQaTaskMutation } from '../';
import { InsertQaTaskWithOnConflictMutation } from '../';
import { InsertQaTaskMutationVariables } from '../';
import { InsertQaTaskWithOnConflictMutationVariables } from '../';
import { InsertQaTaskDocument } from '../';
import { InsertQaTaskWithOnConflictDocument } from '../';
import { QaTask_Set_Input } from '../';
import { UpdateQaTaskByIdMutation } from '../';
import { UpdateQaTaskByIdMutationVariables } from '../';
import { UpdateQaTaskByIdDocument } from '../';
import { UpdateQaTaskMutation } from '../';
import { UpdateQaTaskMutationVariables } from '../';
import { UpdateQaTaskDocument } from '../';
import { RemoveQaTaskModelMutation } from '../';
import { RemoveQaTaskModelMutationVariables } from '../';
import { RemoveQaTaskModelDocument } from '../';
import { RemoveQaTaskModelByIdMutation } from '../';
import { RemoveQaTaskModelByIdMutationVariables } from '../';
import { RemoveQaTaskModelByIdDocument } from '../';
import { ClientTerritoryFragment } from '../';
import { ClientTerritory } from '../';
import { ClientTerritoryFragmentDoc } from '../';
import { QueryClientTerritoryObjectsQueryVariables } from '../';
import { QueryClientTerritoryByIdQuery } from '../';
import { QueryClientTerritoryByIdDocument } from '../';
import { QueryClientTerritoryByIdQueryVariables } from '../';
import { QueryClientTerritoryObjectsQuery } from '../';
import { QueryClientTerritoryObjectsDocument } from '../';
import { SubscribeToClientTerritoryByIdSubscription } from '../';
import { SubscribeToClientTerritoryByIdDocument } from '../';
import { SubscribeToClientTerritoryByIdSubscriptionVariables } from '../';
import { SubscribeToClientTerritoryObjectsSubscription } from '../';
import { SubscribeToClientTerritoryObjectsDocument } from '../';
import { SubscribeToClientTerritoryObjectsSubscriptionVariables } from '../';
import { ClientTerritory_Insert_Input } from '../';
import { ClientTerritory_On_Conflict } from '../';
import { InsertClientTerritoryMutation } from '../';
import { InsertClientTerritoryWithOnConflictMutation } from '../';
import { InsertClientTerritoryMutationVariables } from '../';
import { InsertClientTerritoryWithOnConflictMutationVariables } from '../';
import { InsertClientTerritoryDocument } from '../';
import { InsertClientTerritoryWithOnConflictDocument } from '../';
import { ClientTerritory_Set_Input } from '../';
import { UpdateClientTerritoryByIdMutation } from '../';
import { UpdateClientTerritoryByIdMutationVariables } from '../';
import { UpdateClientTerritoryByIdDocument } from '../';
import { UpdateClientTerritoryMutation } from '../';
import { UpdateClientTerritoryMutationVariables } from '../';
import { UpdateClientTerritoryDocument } from '../';
import { RemoveClientTerritoryModelMutation } from '../';
import { RemoveClientTerritoryModelMutationVariables } from '../';
import { RemoveClientTerritoryModelDocument } from '../';
import { RemoveClientTerritoryModelByIdMutation } from '../';
import { RemoveClientTerritoryModelByIdMutationVariables } from '../';
import { RemoveClientTerritoryModelByIdDocument } from '../';
import { UserFragment } from '../';
import { User } from '../';
import { UserFragmentDoc } from '../';
import { QueryUserObjectsQueryVariables } from '../';
import { QueryUserByIdQuery } from '../';
import { QueryUserByIdDocument } from '../';
import { QueryUserByIdQueryVariables } from '../';
import { QueryUserObjectsQuery } from '../';
import { QueryUserObjectsDocument } from '../';
import { SubscribeToUserByIdSubscription } from '../';
import { SubscribeToUserByIdDocument } from '../';
import { SubscribeToUserByIdSubscriptionVariables } from '../';
import { SubscribeToUserObjectsSubscription } from '../';
import { SubscribeToUserObjectsDocument } from '../';
import { SubscribeToUserObjectsSubscriptionVariables } from '../';
import { User_Insert_Input } from '../';
import { User_On_Conflict } from '../';
import { InsertUserMutation } from '../';
import { InsertUserWithOnConflictMutation } from '../';
import { InsertUserMutationVariables } from '../';
import { InsertUserWithOnConflictMutationVariables } from '../';
import { InsertUserDocument } from '../';
import { InsertUserWithOnConflictDocument } from '../';
import { User_Set_Input } from '../';
import { UpdateUserByIdMutation } from '../';
import { UpdateUserByIdMutationVariables } from '../';
import { UpdateUserByIdDocument } from '../';
import { UpdateUserMutation } from '../';
import { UpdateUserMutationVariables } from '../';
import { UpdateUserDocument } from '../';
import { RemoveUserModelMutation } from '../';
import { RemoveUserModelMutationVariables } from '../';
import { RemoveUserModelDocument } from '../';
import { RemoveUserModelByIdMutation } from '../';
import { RemoveUserModelByIdMutationVariables } from '../';
import { RemoveUserModelByIdDocument } from '../';
import { UserInviteFragment } from '../';
import { UserInvite } from '../';
import { UserInviteFragmentDoc } from '../';
import { QueryUserInviteObjectsQueryVariables } from '../';
import { QueryUserInviteByIdQuery } from '../';
import { QueryUserInviteByIdDocument } from '../';
import { QueryUserInviteByIdQueryVariables } from '../';
import { QueryUserInviteObjectsQuery } from '../';
import { QueryUserInviteObjectsDocument } from '../';
import { SubscribeToUserInviteByIdSubscription } from '../';
import { SubscribeToUserInviteByIdDocument } from '../';
import { SubscribeToUserInviteByIdSubscriptionVariables } from '../';
import { SubscribeToUserInviteObjectsSubscription } from '../';
import { SubscribeToUserInviteObjectsDocument } from '../';
import { SubscribeToUserInviteObjectsSubscriptionVariables } from '../';
import { UserInvite_Insert_Input } from '../';
import { UserInvite_On_Conflict } from '../';
import { InsertUserInviteMutation } from '../';
import { InsertUserInviteWithOnConflictMutation } from '../';
import { InsertUserInviteMutationVariables } from '../';
import { InsertUserInviteWithOnConflictMutationVariables } from '../';
import { InsertUserInviteDocument } from '../';
import { InsertUserInviteWithOnConflictDocument } from '../';
import { UserInvite_Set_Input } from '../';
import { UpdateUserInviteByIdMutation } from '../';
import { UpdateUserInviteByIdMutationVariables } from '../';
import { UpdateUserInviteByIdDocument } from '../';
import { UpdateUserInviteMutation } from '../';
import { UpdateUserInviteMutationVariables } from '../';
import { UpdateUserInviteDocument } from '../';
import { RemoveUserInviteModelMutation } from '../';
import { RemoveUserInviteModelMutationVariables } from '../';
import { RemoveUserInviteModelDocument } from '../';
import { RemoveUserInviteModelByIdMutation } from '../';
import { RemoveUserInviteModelByIdMutationVariables } from '../';
import { RemoveUserInviteModelByIdDocument } from '../';
import { QaQualificationFragment } from '../';
import { QaQualification } from '../';
import { QaQualificationFragmentDoc } from '../';
import { QueryQaQualificationObjectsQueryVariables } from '../';
import { QueryQaQualificationByIdQuery } from '../';
import { QueryQaQualificationByIdDocument } from '../';
import { QueryQaQualificationByIdQueryVariables } from '../';
import { QueryQaQualificationObjectsQuery } from '../';
import { QueryQaQualificationObjectsDocument } from '../';
import { SubscribeToQaQualificationByIdSubscription } from '../';
import { SubscribeToQaQualificationByIdDocument } from '../';
import { SubscribeToQaQualificationByIdSubscriptionVariables } from '../';
import { SubscribeToQaQualificationObjectsSubscription } from '../';
import { SubscribeToQaQualificationObjectsDocument } from '../';
import { SubscribeToQaQualificationObjectsSubscriptionVariables } from '../';
import { QaQualification_Insert_Input } from '../';
import { QaQualification_On_Conflict } from '../';
import { InsertQaQualificationMutation } from '../';
import { InsertQaQualificationWithOnConflictMutation } from '../';
import { InsertQaQualificationMutationVariables } from '../';
import { InsertQaQualificationWithOnConflictMutationVariables } from '../';
import { InsertQaQualificationDocument } from '../';
import { InsertQaQualificationWithOnConflictDocument } from '../';
import { QaQualification_Set_Input } from '../';
import { UpdateQaQualificationByIdMutation } from '../';
import { UpdateQaQualificationByIdMutationVariables } from '../';
import { UpdateQaQualificationByIdDocument } from '../';
import { UpdateQaQualificationMutation } from '../';
import { UpdateQaQualificationMutationVariables } from '../';
import { UpdateQaQualificationDocument } from '../';
import { RemoveQaQualificationModelMutation } from '../';
import { RemoveQaQualificationModelMutationVariables } from '../';
import { RemoveQaQualificationModelDocument } from '../';
import { RemoveQaQualificationModelByIdMutation } from '../';
import { RemoveQaQualificationModelByIdMutationVariables } from '../';
import { RemoveQaQualificationModelByIdDocument } from '../';

// GLOBAL TYPES
//------------------------------------------------
export type RemoveEntitiesQueryHelperResultEx = { affected_rows: number };

//
// GLOBAL VALUES
const logLevel = getLogLevel();

// clientContactFilter HELPERS
//------------------------------------------------

export type ClientContactFilterByIdHelperResultEx = {
  clientContactFilter: ClientContactFilterFragment | null | undefined;
};
export type ClientContactFilterObjectsHelperResultEx = { objects: ClientContactFilterFragment[] };

// Direct Client & Cache Fragment Helpers
//
function cacheGetDataIdForClientContactFilter(clientContactFilterId: string): string {
  return defaultCacheIdFromObject({ __typename: 'clientContactFilter', id: clientContactFilterId });
}

function cacheReadFragmentClientContactFilterById({
  apolloCache,
  clientContactFilterId,
}: {
  apolloCache: ApolloCache<object>;
  clientContactFilterId: string;
}): ClientContactFilterFragment | null | undefined {
  return apolloCache.readFragment<ClientContactFilterFragment | null | undefined>({
    fragment: ClientContactFilterFragmentDoc,
    fragmentName: 'ClientContactFilter',
    id: defaultCacheIdFromObject({ __typename: 'clientContactFilter', id: clientContactFilterId }),
  });
}

function cacheWriteFragmentClientContactFilterById({
  apolloCache,
  clientContactFilterId,
  clientContactFilterPartial,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  clientContactFilterId: string;
  clientContactFilterPartial: Partial<ClientContactFilterFragment> | ClientContactFilter_Insert_Input | null;
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): Partial<ClientContactFilterFragment> {
  const parsedFragment = convertToGraph({
    input: clientContactFilterPartial,
    typename: 'clientContactFilter',
    fieldMap,
  });
  if (logLevel >= 2) console.log(' --> cacheWriteFragmentClientContactFilterById - parsedFragment:', parsedFragment);
  apolloCache.writeFragment<Partial<ClientContactFilterFragment> | null>({
    fragment: ClientContactFilterFragmentDoc,
    fragmentName: 'ClientContactFilter',
    id: defaultCacheIdFromObject({ ...parsedFragment, id: clientContactFilterId }),
    data: parsedFragment,
    broadcast: apolloBroadcast,
  });
  return parsedFragment;
}

function cacheReadQueryClientContactFilterById({
  apolloCache,
  clientContactFilterId,
}: {
  apolloCache: ApolloCache<object>;
  clientContactFilterId: string;
}): ClientContactFilterFragment | null | undefined {
  try {
    return apolloCache.readQuery<ClientContactFilterFragment | null>({
      query: QueryClientContactFilterByIdDocument,
      variables: { clientContactFilterId },
    });
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheReadQueryClientContactFilterById threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheWriteQueryClientContactFilterById({
  apolloCache,
  clientContactFilterId,
  clientContactFilter,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  clientContactFilterId: string;
  clientContactFilter: ClientContactFilterFragment | ClientContactFilter_Insert_Input | null;
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): void {
  try {
    const clientContactFilterPartial = convertToGraph({
      input: clientContactFilter,
      typename: 'clientContactFilter',
      fieldMap,
    });
    return apolloCache.writeQuery<ClientContactFilterFragment | null>({
      query: QueryClientContactFilterByIdDocument,
      variables: { clientContactFilterId },
      data: clientContactFilter ? clientContactFilterPartial : null,
      broadcast: apolloBroadcast,
    });
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheWriteQueryClientContactFilterById threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheReadQueryClientContactFilterObjects({
  apolloCache,
  variables,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryClientContactFilterObjectsQueryVariables;
}): ClientContactFilter[] | null | undefined {
  try {
    return (
      apolloCache.readQuery<{ ClientContactFilter: ClientContactFilter[] | null }>({
        query: QueryClientContactFilterObjectsDocument,
        variables,
      })?.ClientContactFilter || []
    );
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheReadQueryClientContactFilterObjects threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheWriteQueryClientContactFilterObjects({
  apolloCache,
  variables,
  data,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryClientContactFilterObjectsQueryVariables;
  data: (ClientContactFilter | ClientContactFilter_Insert_Input)[];
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): void {
  try {
    const objects = convertToGraph({ input: data, typename: 'clientContactFilter', fieldMap });
    return apolloCache.writeQuery<{ ClientContactFilter: ClientContactFilter[] }>({
      query: QueryClientContactFilterObjectsDocument,
      variables,
      data: { ClientContactFilter: objects },
      broadcast: apolloBroadcast,
    });
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheWriteQueryClientContactFilterObjects threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheWriteQueryClientContactFilterInsert({
  apolloCache,
  variables,
  clientContactFilter,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryClientContactFilterObjectsQueryVariables;
  clientContactFilter: ClientContactFilter_Insert_Input;
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): void {
  const currentObjects = cacheReadQueryClientContactFilterObjects({ apolloCache, variables }) || [];
  const objectsWithInserted = [
    ...currentObjects,
    convertToGraph({ input: clientContactFilter, typename: 'clientContactFilter', fieldMap }),
  ];
  if (logLevel >= 2)
    console.log(' --> cacheWriteQueryClientContactFilterInsert - objectsWithInserted:', objectsWithInserted);
  return cacheWriteQueryClientContactFilterObjects({
    apolloCache,
    variables,
    data: objectsWithInserted,
    apolloBroadcast,
  });
}

function cacheWriteQueryClientContactFilterRemove({
  apolloCache,
  variables,
  clientContactFilterId,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryClientContactFilterObjectsQueryVariables;
  clientContactFilterId: string;
  apolloBroadcast?: boolean;
}): void {
  const currentObjects = cacheReadQueryClientContactFilterObjects({ apolloCache, variables }) || [];
  const objectsWithRemoved = currentObjects.filter((objectItem) => objectItem.id !== clientContactFilterId) || [];
  if (logLevel >= 2)
    console.log(' --> cacheWriteQueryClientContactFilterRemove - objectsWithRemoved:', objectsWithRemoved);
  return cacheWriteQueryClientContactFilterObjects({
    apolloCache,
    variables,
    data: objectsWithRemoved,
    apolloBroadcast,
  });
}

// Query Fetch Helper
//
export type QueryClientContactFilterByIdApolloQueryResult = ApolloQueryResult<QueryClientContactFilterByIdQuery>;
export type QueryClientContactFilterByIdApolloQueryHelperResultEx = QueryClientContactFilterByIdApolloQueryResult &
  ClientContactFilterByIdHelperResultEx;

async function queryClientContactFilterById({
  apolloClient,
  clientContactFilterId,
  options,
}: {
  apolloClient: ApolloClient<object>;
  clientContactFilterId: string;
  options?: Omit<QueryOptions<QueryClientContactFilterByIdQueryVariables>, 'query' | 'variables'>;
}): Promise<QueryClientContactFilterByIdApolloQueryHelperResultEx> {
  const query: QueryClientContactFilterByIdApolloQueryResult = await apolloClient.query<QueryClientContactFilterByIdQuery>(
    { query: QueryClientContactFilterByIdDocument, variables: { clientContactFilterId }, ...options }
  );

  return { ...query, clientContactFilter: query?.data?.clientContactFilter_by_pk };
}

// Query Watch ById Helper
//
export type WatchQueryClientContactFilterByIdApolloObservableQuery = ObservableQuery<QueryClientContactFilterByIdQuery>;
async function watchQueryClientContactFilterById({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<QueryOptions<QueryClientContactFilterByIdQueryVariables>, 'query'>;
}): Promise<WatchQueryClientContactFilterByIdApolloObservableQuery> {
  return apolloClient.watchQuery<QueryClientContactFilterByIdQuery>({
    query: QueryClientContactFilterByIdDocument,
    ...options,
  });
}

// Query Fetch Objects Helper
//
export type QueryClientContactFilterObjectsObjectsApolloQueryResult = ApolloQueryResult<QueryClientContactFilterObjectsQuery>;
export type QueryClientContactFilterObjectsObjectsApolloQueryResultEx = QueryClientContactFilterObjectsObjectsApolloQueryResult &
  ClientContactFilterObjectsHelperResultEx;

async function queryClientContactFilterObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<QueryOptions<QueryClientContactFilterObjectsQueryVariables>, 'query'>;
}): Promise<QueryClientContactFilterObjectsObjectsApolloQueryResultEx> {
  const query: QueryClientContactFilterObjectsObjectsApolloQueryResult = await apolloClient.query<QueryClientContactFilterObjectsQuery>(
    { query: QueryClientContactFilterObjectsDocument, ...options }
  );

  return { ...query, objects: query?.data?.clientContactFilter || [] };
}

// Query Watch Objects Helper
//
export type WatchQueryClientContactFilterObjectsApolloObservableQuery = ObservableQuery<QueryClientContactFilterObjectsQuery>;
async function watchQueryClientContactFilterObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<QueryOptions<QueryClientContactFilterObjectsQueryVariables>, 'query'>;
}): Promise<WatchQueryClientContactFilterObjectsApolloObservableQuery> {
  return apolloClient.watchQuery<QueryClientContactFilterObjectsQuery>({
    query: QueryClientContactFilterObjectsDocument,
    ...options,
  });
}

// Subscription Fetch ById Helper
//
export type SubscribeToClientContactFilterByIdSubscriptionFetchResult = FetchResult<
  SubscribeToClientContactFilterByIdSubscription,
  Record<string, any>,
  Record<string, any>
>;
export type SubscribeToClientContactFilterByIdSubscriptionFetchResultEx = FetchResult<
  SubscribeToClientContactFilterByIdSubscription,
  Record<string, any>,
  Record<string, any>
> &
  ClientContactFilterByIdHelperResultEx;

async function subscribeToClientContactFilterById({
  apolloClient,
  clientContactFilterId,
  options,
}: {
  apolloClient: ApolloClient<object>;
  clientContactFilterId: string;
  options?: Omit<SubscriptionOptions<SubscribeToClientContactFilterByIdSubscriptionVariables>, 'query' | 'variables'>;
}): Promise<Observable<SubscribeToClientContactFilterByIdSubscriptionFetchResultEx>> {
  const subscription: Observable<SubscribeToClientContactFilterByIdSubscriptionFetchResult> = apolloClient.subscribe<SubscribeToClientContactFilterByIdSubscription>(
    { query: SubscribeToClientContactFilterByIdDocument, variables: { clientContactFilterId }, ...options }
  );

  return subscription.map((value) => {
    return {
      context: value.context,
      errors: value.errors,
      data: value.data,
      extensions: value.extensions,
      clientContactFilter: value?.data?.clientContactFilter_by_pk || [],
    } as SubscribeToClientContactFilterByIdSubscriptionFetchResultEx;
  });
}

// Subscription Fetch Objects Helper
//
export type SubscribeToClientContactFilterObjectsSubscriptionFetchResult = FetchResult<
  SubscribeToClientContactFilterObjectsSubscription,
  Record<string, any>,
  Record<string, any>
>;
export type SubscribeToClientContactFilterObjectsSubscriptionFetchResultEx = FetchResult<
  SubscribeToClientContactFilterObjectsSubscription,
  Record<string, any>,
  Record<string, any>
> &
  ClientContactFilterObjectsHelperResultEx;

async function subscribeToClientContactFilterObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options?: Omit<SubscriptionOptions<SubscribeToClientContactFilterObjectsSubscriptionVariables>, 'query'>;
}): Promise<Observable<SubscribeToClientContactFilterObjectsSubscriptionFetchResultEx>> {
  const subscription: Observable<SubscribeToClientContactFilterObjectsSubscriptionFetchResult> = apolloClient.subscribe<SubscribeToClientContactFilterObjectsSubscription>(
    { query: SubscribeToClientContactFilterObjectsDocument, ...options }
  );

  return subscription.map((value) => {
    return {
      context: value.context,
      errors: value.errors,
      data: value.data,
      extensions: value.extensions,
      objects: value?.data?.clientContactFilter || [],
    } as SubscribeToClientContactFilterObjectsSubscriptionFetchResultEx;
  });
}

// Insert Helper
//
type InsertClientContactFilterFetchResult = FetchResult<
  InsertClientContactFilterMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertClientContactFilterFetchHelperResultEx = InsertClientContactFilterFetchResult &
  ClientContactFilterByIdHelperResultEx;

async function insertClientContactFilter({
  apolloClient,
  clientContactFilter,
  autoOptimisticResponse,
  fieldMap,
  options,
}: {
  apolloClient: ApolloClient<object>;
  clientContactFilter: ClientContactFilter_Insert_Input;
  autoOptimisticResponse?: boolean;
  fieldMap?: FieldMap;
  options?: Omit<
    MutationOptions<InsertClientContactFilterMutation, InsertClientContactFilterMutationVariables>,
    'mutation' | 'variables'
  >;
}): Promise<InsertClientContactFilterFetchHelperResultEx> {
  const objectForInsert = stripInsertInputClientFields({ input: clientContactFilter });
  const mutationOptions: MutationOptions<
    InsertClientContactFilterMutation,
    InsertClientContactFilterMutationVariables
  > = { mutation: InsertClientContactFilterDocument, variables: { objects: [objectForInsert] }, ...options };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    if (!objectForInsert.id)
      throw new Error(`if autoOptimisticResponse = true, id must be set in object 'clientContactFilter'`);
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertClientContactFilterMutation>({
      operationType: 'insert',
      entityName: 'clientContactFilter',
      objects: [objectForInsert as ClientContactFilter_Insert_Input & ObjectWithId],
      fieldMap,
    });
    if (logLevel >= 2)
      console.log(' --> insertClientContactFilter - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: InsertClientContactFilterFetchResult = await apolloClient.mutate<
    InsertClientContactFilterMutation,
    InsertClientContactFilterMutationVariables
  >(mutationOptions);

  return {
    ...mutation,
    clientContactFilter:
      mutation?.data?.insert_clientContactFilter?.returning && mutation.data.insert_clientContactFilter.returning[0],
  };
}

async function insertClientContactFilterWithOnConflict({
  apolloClient,
  clientContactFilter,
  onConflict,
  autoOptimisticResponse,
  fieldMap,
  options,
}: {
  apolloClient: ApolloClient<object>;
  clientContactFilter: ClientContactFilter_Insert_Input;
  onConflict: ClientContactFilter_On_Conflict;
  autoOptimisticResponse?: boolean;
  fieldMap?: FieldMap;
  options?: Omit<
    MutationOptions<
      InsertClientContactFilterWithOnConflictMutation,
      InsertClientContactFilterWithOnConflictMutationVariables
    >,
    'mutation' | 'variables'
  >;
}): Promise<InsertClientContactFilterFetchHelperResultEx> {
  const objectForInsert = stripInsertInputClientFields({ input: clientContactFilter });
  const mutationOptions: MutationOptions<
    InsertClientContactFilterWithOnConflictMutation,
    InsertClientContactFilterWithOnConflictMutationVariables
  > = {
    mutation: InsertClientContactFilterWithOnConflictDocument,
    variables: { objects: [objectForInsert], onConflict },
    ...options,
  };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    if (!objectForInsert.id)
      throw new Error(`if autoOptimisticResponse = true, id must be set in object 'clientContactFilter'`);
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertClientContactFilterWithOnConflictMutation>(
      {
        operationType: 'insert',
        entityName: 'clientContactFilter',
        objects: [objectForInsert as ClientContactFilter_Insert_Input & ObjectWithId],
        fieldMap,
      }
    );
    if (logLevel >= 2)
      console.log(
        ' --> insertClientContactFilterWithOnConflict - optimisticResponse:',
        mutationOptions.optimisticResponse
      );
  }

  const mutation: InsertClientContactFilterFetchResult = await apolloClient.mutate<
    InsertClientContactFilterWithOnConflictMutation,
    InsertClientContactFilterWithOnConflictMutationVariables
  >(mutationOptions);

  return {
    ...mutation,
    clientContactFilter:
      mutation?.data?.insert_clientContactFilter?.returning && mutation.data.insert_clientContactFilter.returning[0],
  };
}

// Insert Objects Helper
//
type InsertClientContactFilterObjectsFetchResult = FetchResult<
  InsertClientContactFilterMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertClientContactFilterObjectsHelperResultEx = InsertClientContactFilterObjectsFetchResult &
  ClientContactFilterObjectsHelperResultEx;

async function insertClientContactFilterObjects({
  apolloClient,
  objects,
  autoOptimisticResponse,
  fieldMap,
  options,
}: {
  apolloClient: ApolloClient<object>;
  objects: ClientContactFilter_Insert_Input[];
  autoOptimisticResponse?: boolean;
  fieldMap?: FieldMap;
  options?: Omit<
    MutationOptions<InsertClientContactFilterMutation, InsertClientContactFilterMutationVariables>,
    'mutation' | 'variables'
  >;
}): Promise<InsertClientContactFilterObjectsHelperResultEx> {
  const objectsForInsert = objects.map((objectItem) => stripInsertInputClientFields({ input: objectItem }));
  const mutationOptions: MutationOptions<
    InsertClientContactFilterMutation,
    InsertClientContactFilterMutationVariables
  > = { mutation: InsertClientContactFilterDocument, variables: { objects: objectsForInsert }, ...options };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    if (objectsForInsert.find((objectItem) => !objectItem.id))
      throw new Error(`if autoOptimisticResponse = true, ids must be set in objects`);
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertClientContactFilterMutation>({
      operationType: 'insert',
      entityName: 'clientContactFilter',
      objects: objectsForInsert as (ClientContactFilter_Insert_Input & ObjectWithId)[],
      fieldMap,
    });
    if (logLevel >= 2)
      console.log(' --> insertClientContactFilter - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: InsertClientContactFilterObjectsFetchResult = await apolloClient.mutate<
    InsertClientContactFilterMutation,
    InsertClientContactFilterMutationVariables
  >(mutationOptions);

  return { ...mutation, objects: mutation?.data?.insert_clientContactFilter?.returning || [] };
}

// Update Helper
//
type UpdateClientContactFilterByIdQueryResult = FetchResult<
  UpdateClientContactFilterByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateClientContactFilterByIdHelperResultEx = UpdateClientContactFilterByIdQueryResult &
  ClientContactFilterByIdHelperResultEx;

async function updateClientContactFilterById({
  apolloClient,
  clientContactFilterId,
  set,
  autoOptimisticResponse,
  options,
}: {
  apolloClient: ApolloClient<object>;
  clientContactFilterId: string;
  set: ClientContactFilter_Set_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationOptions<UpdateClientContactFilterByIdMutation, UpdateClientContactFilterByIdMutationVariables>,
    'mutation'
  >;
}): Promise<UpdateClientContactFilterByIdHelperResultEx> {
  const mutationOptions: MutationOptions<
    UpdateClientContactFilterByIdMutation,
    UpdateClientContactFilterByIdMutationVariables
  > = { mutation: UpdateClientContactFilterByIdDocument, variables: { id: clientContactFilterId, set }, ...options };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateClientContactFilterByIdMutation>({
      operationType: 'update',
      entityName: 'clientContactFilter',
      objects: [{ id: clientContactFilterId, ...set }],
    });
    if (logLevel >= 2)
      console.log(' --> updateClientContactFilterById - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: UpdateClientContactFilterByIdQueryResult = await apolloClient.mutate<
    UpdateClientContactFilterByIdMutation,
    UpdateClientContactFilterByIdMutationVariables
  >(mutationOptions);

  return {
    ...mutation,
    clientContactFilter:
      mutation?.data?.update_clientContactFilter?.returning && mutation.data.update_clientContactFilter.returning[0],
  };
}

// Update Objects Helper
//
type UpdateClientContactFilterObjectsFetchResult = FetchResult<
  UpdateClientContactFilterMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateClientContactFilterObjectsHelperResultEx = UpdateClientContactFilterObjectsFetchResult &
  ClientContactFilterObjectsHelperResultEx;

async function updateClientContactFilterObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<
    MutationOptions<UpdateClientContactFilterMutation, UpdateClientContactFilterMutationVariables>,
    'mutation'
  >;
}): Promise<UpdateClientContactFilterObjectsHelperResultEx> {
  const mutation: UpdateClientContactFilterObjectsFetchResult = await apolloClient.mutate<
    UpdateClientContactFilterMutation,
    UpdateClientContactFilterMutationVariables
  >({ mutation: UpdateClientContactFilterDocument, ...options });

  return { ...mutation, objects: mutation?.data?.update_clientContactFilter?.returning || [] };
}

// Delete Helper
//

type RemoveClientContactFilterModelByIdQueryResult = FetchResult<
  RemoveClientContactFilterModelByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveClientContactFilterModelByIdQueryHelperResultEx = RemoveClientContactFilterModelByIdQueryResult &
  RemoveEntitiesQueryHelperResultEx;

async function removeClientContactFilterModelById({
  apolloClient,
  clientContactFilterId,
  autoOptimisticResponse,
  options,
}: {
  apolloClient: ApolloClient<object>;
  clientContactFilterId: string;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationOptions<RemoveClientContactFilterModelByIdMutation, RemoveClientContactFilterModelByIdMutationVariables>,
    'mutation'
  >;
}): Promise<RemoveClientContactFilterModelByIdQueryHelperResultEx> {
  const mutationOptions: MutationOptions<
    RemoveClientContactFilterModelByIdMutation,
    RemoveClientContactFilterModelByIdMutationVariables
  > = { mutation: RemoveClientContactFilterModelByIdDocument, variables: { id: clientContactFilterId }, ...options };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<RemoveClientContactFilterModelByIdMutation>(
      { operationType: 'delete', entityName: 'clientContactFilter', objects: [{ id: clientContactFilterId }] }
    );
    if (logLevel >= 2)
      console.log(' --> removeClientContactFilterModelById - optimisticResponse:', mutationOptions.optimisticResponse);
  }
  if (!options || !options.update) {
    mutationOptions.update = generateUpdateFunctionForMutation<RemoveClientContactFilterModelByIdMutation>({
      operationType: 'delete',
      entityName: 'clientContactFilter',
      entityId: clientContactFilterId,
    });
  }

  const mutation: RemoveClientContactFilterModelByIdQueryResult = await apolloClient.mutate<
    RemoveClientContactFilterModelByIdMutation,
    RemoveClientContactFilterModelByIdMutationVariables
  >(mutationOptions);

  return { ...mutation, affected_rows: mutation?.data?.delete_clientContactFilter?.affected_rows || 0 };
}

type RemoveClientContactFilterModelObjectsQueryResult = FetchResult<
  RemoveClientContactFilterModelMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveClientContactFilterModelObjectsQueryHelperResultEx = RemoveClientContactFilterModelObjectsQueryResult &
  RemoveEntitiesQueryHelperResultEx;

async function removeClientContactFilterModelObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<
    MutationOptions<RemoveClientContactFilterModelMutation, RemoveClientContactFilterModelMutationVariables>,
    'mutation'
  >;
}): Promise<RemoveClientContactFilterModelObjectsQueryHelperResultEx> {
  const mutation: RemoveClientContactFilterModelObjectsQueryResult = await apolloClient.mutate<
    RemoveClientContactFilterModelMutation,
    RemoveClientContactFilterModelMutationVariables
  >({ mutation: RemoveClientContactFilterModelDocument, ...options });

  return { ...mutation, affected_rows: mutation?.data?.delete_clientContactFilter?.affected_rows || 0 };
}

// ClientContactFilter Fragment Helper Object
//------------------------------------------------

export const ClientContactFilterFragmentGQLHelper = {
  cacheGetDataId: cacheGetDataIdForClientContactFilter,
  cacheReadFragmentById: cacheReadFragmentClientContactFilterById,
  cacheWriteFragmentById: cacheWriteFragmentClientContactFilterById,
  cacheReadQueryById: cacheReadQueryClientContactFilterById,
  cacheWriteQueryById: cacheWriteQueryClientContactFilterById,
  cacheReadQueryObjects: cacheReadQueryClientContactFilterObjects,
  cacheWriteQueryObjects: cacheWriteQueryClientContactFilterObjects,
  cacheWriteQueryInsert: cacheWriteQueryClientContactFilterInsert,
  cacheWriteQueryRemove: cacheWriteQueryClientContactFilterRemove,
  queryById: queryClientContactFilterById,
  queryObjects: queryClientContactFilterObjects,
  watchQueryById: watchQueryClientContactFilterById,
  watchQueryObjects: watchQueryClientContactFilterObjects,
  subscribeToById: subscribeToClientContactFilterById,
  subscribeToObjects: subscribeToClientContactFilterObjects,
  insert: insertClientContactFilter,
  insertWithOnConflict: insertClientContactFilterWithOnConflict,
  insertObjects: insertClientContactFilterObjects,
  updateById: updateClientContactFilterById,
  updateObjects: updateClientContactFilterObjects,
};

// clientContactFilter Entity Helper Object
//------------------------------------------------

export const ClientContactFilterModelGQLHelper = {
  removeById: removeClientContactFilterModelById,
  removeObjects: removeClientContactFilterModelObjects,
};

// clientContactJobChange HELPERS
//------------------------------------------------

export type ClientContactJobChangeByIdHelperResultEx = {
  clientContactJobChange: ClientContactJobChangeFragment | null | undefined;
};
export type ClientContactJobChangeObjectsHelperResultEx = { objects: ClientContactJobChangeFragment[] };

// Direct Client & Cache Fragment Helpers
//
function cacheGetDataIdForClientContactJobChange(clientContactJobChangeId: string): string {
  return defaultCacheIdFromObject({ __typename: 'clientContactJobChange', id: clientContactJobChangeId });
}

function cacheReadFragmentClientContactJobChangeById({
  apolloCache,
  clientContactJobChangeId,
}: {
  apolloCache: ApolloCache<object>;
  clientContactJobChangeId: string;
}): ClientContactJobChangeFragment | null | undefined {
  return apolloCache.readFragment<ClientContactJobChangeFragment | null | undefined>({
    fragment: ClientContactJobChangeFragmentDoc,
    fragmentName: 'ClientContactJobChange',
    id: defaultCacheIdFromObject({ __typename: 'clientContactJobChange', id: clientContactJobChangeId }),
  });
}

function cacheWriteFragmentClientContactJobChangeById({
  apolloCache,
  clientContactJobChangeId,
  clientContactJobChangePartial,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  clientContactJobChangeId: string;
  clientContactJobChangePartial: Partial<ClientContactJobChangeFragment> | ClientContactJobChange_Insert_Input | null;
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): Partial<ClientContactJobChangeFragment> {
  const parsedFragment = convertToGraph({
    input: clientContactJobChangePartial,
    typename: 'clientContactJobChange',
    fieldMap,
  });
  if (logLevel >= 2) console.log(' --> cacheWriteFragmentClientContactJobChangeById - parsedFragment:', parsedFragment);
  apolloCache.writeFragment<Partial<ClientContactJobChangeFragment> | null>({
    fragment: ClientContactJobChangeFragmentDoc,
    fragmentName: 'ClientContactJobChange',
    id: defaultCacheIdFromObject({ ...parsedFragment, id: clientContactJobChangeId }),
    data: parsedFragment,
    broadcast: apolloBroadcast,
  });
  return parsedFragment;
}

function cacheReadQueryClientContactJobChangeById({
  apolloCache,
  clientContactJobChangeId,
}: {
  apolloCache: ApolloCache<object>;
  clientContactJobChangeId: string;
}): ClientContactJobChangeFragment | null | undefined {
  try {
    return apolloCache.readQuery<ClientContactJobChangeFragment | null>({
      query: QueryClientContactJobChangeByIdDocument,
      variables: { clientContactJobChangeId },
    });
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheReadQueryClientContactJobChangeById threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheWriteQueryClientContactJobChangeById({
  apolloCache,
  clientContactJobChangeId,
  clientContactJobChange,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  clientContactJobChangeId: string;
  clientContactJobChange: ClientContactJobChangeFragment | ClientContactJobChange_Insert_Input | null;
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): void {
  try {
    const clientContactJobChangePartial = convertToGraph({
      input: clientContactJobChange,
      typename: 'clientContactJobChange',
      fieldMap,
    });
    return apolloCache.writeQuery<ClientContactJobChangeFragment | null>({
      query: QueryClientContactJobChangeByIdDocument,
      variables: { clientContactJobChangeId },
      data: clientContactJobChange ? clientContactJobChangePartial : null,
      broadcast: apolloBroadcast,
    });
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheWriteQueryClientContactJobChangeById threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheReadQueryClientContactJobChangeObjects({
  apolloCache,
  variables,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryClientContactJobChangeObjectsQueryVariables;
}): ClientContactJobChange[] | null | undefined {
  try {
    return (
      apolloCache.readQuery<{ ClientContactJobChange: ClientContactJobChange[] | null }>({
        query: QueryClientContactJobChangeObjectsDocument,
        variables,
      })?.ClientContactJobChange || []
    );
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheReadQueryClientContactJobChangeObjects threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheWriteQueryClientContactJobChangeObjects({
  apolloCache,
  variables,
  data,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryClientContactJobChangeObjectsQueryVariables;
  data: (ClientContactJobChange | ClientContactJobChange_Insert_Input)[];
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): void {
  try {
    const objects = convertToGraph({ input: data, typename: 'clientContactJobChange', fieldMap });
    return apolloCache.writeQuery<{ ClientContactJobChange: ClientContactJobChange[] }>({
      query: QueryClientContactJobChangeObjectsDocument,
      variables,
      data: { ClientContactJobChange: objects },
      broadcast: apolloBroadcast,
    });
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheWriteQueryClientContactJobChangeObjects threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheWriteQueryClientContactJobChangeInsert({
  apolloCache,
  variables,
  clientContactJobChange,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryClientContactJobChangeObjectsQueryVariables;
  clientContactJobChange: ClientContactJobChange_Insert_Input;
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): void {
  const currentObjects = cacheReadQueryClientContactJobChangeObjects({ apolloCache, variables }) || [];
  const objectsWithInserted = [
    ...currentObjects,
    convertToGraph({ input: clientContactJobChange, typename: 'clientContactJobChange', fieldMap }),
  ];
  if (logLevel >= 2)
    console.log(' --> cacheWriteQueryClientContactJobChangeInsert - objectsWithInserted:', objectsWithInserted);
  return cacheWriteQueryClientContactJobChangeObjects({
    apolloCache,
    variables,
    data: objectsWithInserted,
    apolloBroadcast,
  });
}

function cacheWriteQueryClientContactJobChangeRemove({
  apolloCache,
  variables,
  clientContactJobChangeId,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryClientContactJobChangeObjectsQueryVariables;
  clientContactJobChangeId: string;
  apolloBroadcast?: boolean;
}): void {
  const currentObjects = cacheReadQueryClientContactJobChangeObjects({ apolloCache, variables }) || [];
  const objectsWithRemoved = currentObjects.filter((objectItem) => objectItem.id !== clientContactJobChangeId) || [];
  if (logLevel >= 2)
    console.log(' --> cacheWriteQueryClientContactJobChangeRemove - objectsWithRemoved:', objectsWithRemoved);
  return cacheWriteQueryClientContactJobChangeObjects({
    apolloCache,
    variables,
    data: objectsWithRemoved,
    apolloBroadcast,
  });
}

// Query Fetch Helper
//
export type QueryClientContactJobChangeByIdApolloQueryResult = ApolloQueryResult<QueryClientContactJobChangeByIdQuery>;
export type QueryClientContactJobChangeByIdApolloQueryHelperResultEx = QueryClientContactJobChangeByIdApolloQueryResult &
  ClientContactJobChangeByIdHelperResultEx;

async function queryClientContactJobChangeById({
  apolloClient,
  clientContactJobChangeId,
  options,
}: {
  apolloClient: ApolloClient<object>;
  clientContactJobChangeId: string;
  options?: Omit<QueryOptions<QueryClientContactJobChangeByIdQueryVariables>, 'query' | 'variables'>;
}): Promise<QueryClientContactJobChangeByIdApolloQueryHelperResultEx> {
  const query: QueryClientContactJobChangeByIdApolloQueryResult = await apolloClient.query<QueryClientContactJobChangeByIdQuery>(
    { query: QueryClientContactJobChangeByIdDocument, variables: { clientContactJobChangeId }, ...options }
  );

  return { ...query, clientContactJobChange: query?.data?.clientContactJobChange_by_pk };
}

// Query Watch ById Helper
//
export type WatchQueryClientContactJobChangeByIdApolloObservableQuery = ObservableQuery<QueryClientContactJobChangeByIdQuery>;
async function watchQueryClientContactJobChangeById({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<QueryOptions<QueryClientContactJobChangeByIdQueryVariables>, 'query'>;
}): Promise<WatchQueryClientContactJobChangeByIdApolloObservableQuery> {
  return apolloClient.watchQuery<QueryClientContactJobChangeByIdQuery>({
    query: QueryClientContactJobChangeByIdDocument,
    ...options,
  });
}

// Query Fetch Objects Helper
//
export type QueryClientContactJobChangeObjectsObjectsApolloQueryResult = ApolloQueryResult<QueryClientContactJobChangeObjectsQuery>;
export type QueryClientContactJobChangeObjectsObjectsApolloQueryResultEx = QueryClientContactJobChangeObjectsObjectsApolloQueryResult &
  ClientContactJobChangeObjectsHelperResultEx;

async function queryClientContactJobChangeObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<QueryOptions<QueryClientContactJobChangeObjectsQueryVariables>, 'query'>;
}): Promise<QueryClientContactJobChangeObjectsObjectsApolloQueryResultEx> {
  const query: QueryClientContactJobChangeObjectsObjectsApolloQueryResult = await apolloClient.query<QueryClientContactJobChangeObjectsQuery>(
    { query: QueryClientContactJobChangeObjectsDocument, ...options }
  );

  return { ...query, objects: query?.data?.clientContactJobChange || [] };
}

// Query Watch Objects Helper
//
export type WatchQueryClientContactJobChangeObjectsApolloObservableQuery = ObservableQuery<QueryClientContactJobChangeObjectsQuery>;
async function watchQueryClientContactJobChangeObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<QueryOptions<QueryClientContactJobChangeObjectsQueryVariables>, 'query'>;
}): Promise<WatchQueryClientContactJobChangeObjectsApolloObservableQuery> {
  return apolloClient.watchQuery<QueryClientContactJobChangeObjectsQuery>({
    query: QueryClientContactJobChangeObjectsDocument,
    ...options,
  });
}

// Subscription Fetch ById Helper
//
export type SubscribeToClientContactJobChangeByIdSubscriptionFetchResult = FetchResult<
  SubscribeToClientContactJobChangeByIdSubscription,
  Record<string, any>,
  Record<string, any>
>;
export type SubscribeToClientContactJobChangeByIdSubscriptionFetchResultEx = FetchResult<
  SubscribeToClientContactJobChangeByIdSubscription,
  Record<string, any>,
  Record<string, any>
> &
  ClientContactJobChangeByIdHelperResultEx;

async function subscribeToClientContactJobChangeById({
  apolloClient,
  clientContactJobChangeId,
  options,
}: {
  apolloClient: ApolloClient<object>;
  clientContactJobChangeId: string;
  options?: Omit<
    SubscriptionOptions<SubscribeToClientContactJobChangeByIdSubscriptionVariables>,
    'query' | 'variables'
  >;
}): Promise<Observable<SubscribeToClientContactJobChangeByIdSubscriptionFetchResultEx>> {
  const subscription: Observable<SubscribeToClientContactJobChangeByIdSubscriptionFetchResult> = apolloClient.subscribe<SubscribeToClientContactJobChangeByIdSubscription>(
    { query: SubscribeToClientContactJobChangeByIdDocument, variables: { clientContactJobChangeId }, ...options }
  );

  return subscription.map((value) => {
    return {
      context: value.context,
      errors: value.errors,
      data: value.data,
      extensions: value.extensions,
      clientContactJobChange: value?.data?.clientContactJobChange_by_pk || [],
    } as SubscribeToClientContactJobChangeByIdSubscriptionFetchResultEx;
  });
}

// Subscription Fetch Objects Helper
//
export type SubscribeToClientContactJobChangeObjectsSubscriptionFetchResult = FetchResult<
  SubscribeToClientContactJobChangeObjectsSubscription,
  Record<string, any>,
  Record<string, any>
>;
export type SubscribeToClientContactJobChangeObjectsSubscriptionFetchResultEx = FetchResult<
  SubscribeToClientContactJobChangeObjectsSubscription,
  Record<string, any>,
  Record<string, any>
> &
  ClientContactJobChangeObjectsHelperResultEx;

async function subscribeToClientContactJobChangeObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options?: Omit<SubscriptionOptions<SubscribeToClientContactJobChangeObjectsSubscriptionVariables>, 'query'>;
}): Promise<Observable<SubscribeToClientContactJobChangeObjectsSubscriptionFetchResultEx>> {
  const subscription: Observable<SubscribeToClientContactJobChangeObjectsSubscriptionFetchResult> = apolloClient.subscribe<SubscribeToClientContactJobChangeObjectsSubscription>(
    { query: SubscribeToClientContactJobChangeObjectsDocument, ...options }
  );

  return subscription.map((value) => {
    return {
      context: value.context,
      errors: value.errors,
      data: value.data,
      extensions: value.extensions,
      objects: value?.data?.clientContactJobChange || [],
    } as SubscribeToClientContactJobChangeObjectsSubscriptionFetchResultEx;
  });
}

// Insert Helper
//
type InsertClientContactJobChangeFetchResult = FetchResult<
  InsertClientContactJobChangeMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertClientContactJobChangeFetchHelperResultEx = InsertClientContactJobChangeFetchResult &
  ClientContactJobChangeByIdHelperResultEx;

async function insertClientContactJobChange({
  apolloClient,
  clientContactJobChange,
  autoOptimisticResponse,
  fieldMap,
  options,
}: {
  apolloClient: ApolloClient<object>;
  clientContactJobChange: ClientContactJobChange_Insert_Input;
  autoOptimisticResponse?: boolean;
  fieldMap?: FieldMap;
  options?: Omit<
    MutationOptions<InsertClientContactJobChangeMutation, InsertClientContactJobChangeMutationVariables>,
    'mutation' | 'variables'
  >;
}): Promise<InsertClientContactJobChangeFetchHelperResultEx> {
  const objectForInsert = stripInsertInputClientFields({ input: clientContactJobChange });
  const mutationOptions: MutationOptions<
    InsertClientContactJobChangeMutation,
    InsertClientContactJobChangeMutationVariables
  > = { mutation: InsertClientContactJobChangeDocument, variables: { objects: [objectForInsert] }, ...options };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    if (!objectForInsert.id)
      throw new Error(`if autoOptimisticResponse = true, id must be set in object 'clientContactJobChange'`);
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertClientContactJobChangeMutation>({
      operationType: 'insert',
      entityName: 'clientContactJobChange',
      objects: [objectForInsert as ClientContactJobChange_Insert_Input & ObjectWithId],
      fieldMap,
    });
    if (logLevel >= 2)
      console.log(' --> insertClientContactJobChange - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: InsertClientContactJobChangeFetchResult = await apolloClient.mutate<
    InsertClientContactJobChangeMutation,
    InsertClientContactJobChangeMutationVariables
  >(mutationOptions);

  return {
    ...mutation,
    clientContactJobChange:
      mutation?.data?.insert_clientContactJobChange?.returning &&
      mutation.data.insert_clientContactJobChange.returning[0],
  };
}

async function insertClientContactJobChangeWithOnConflict({
  apolloClient,
  clientContactJobChange,
  onConflict,
  autoOptimisticResponse,
  fieldMap,
  options,
}: {
  apolloClient: ApolloClient<object>;
  clientContactJobChange: ClientContactJobChange_Insert_Input;
  onConflict: ClientContactJobChange_On_Conflict;
  autoOptimisticResponse?: boolean;
  fieldMap?: FieldMap;
  options?: Omit<
    MutationOptions<
      InsertClientContactJobChangeWithOnConflictMutation,
      InsertClientContactJobChangeWithOnConflictMutationVariables
    >,
    'mutation' | 'variables'
  >;
}): Promise<InsertClientContactJobChangeFetchHelperResultEx> {
  const objectForInsert = stripInsertInputClientFields({ input: clientContactJobChange });
  const mutationOptions: MutationOptions<
    InsertClientContactJobChangeWithOnConflictMutation,
    InsertClientContactJobChangeWithOnConflictMutationVariables
  > = {
    mutation: InsertClientContactJobChangeWithOnConflictDocument,
    variables: { objects: [objectForInsert], onConflict },
    ...options,
  };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    if (!objectForInsert.id)
      throw new Error(`if autoOptimisticResponse = true, id must be set in object 'clientContactJobChange'`);
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertClientContactJobChangeWithOnConflictMutation>(
      {
        operationType: 'insert',
        entityName: 'clientContactJobChange',
        objects: [objectForInsert as ClientContactJobChange_Insert_Input & ObjectWithId],
        fieldMap,
      }
    );
    if (logLevel >= 2)
      console.log(
        ' --> insertClientContactJobChangeWithOnConflict - optimisticResponse:',
        mutationOptions.optimisticResponse
      );
  }

  const mutation: InsertClientContactJobChangeFetchResult = await apolloClient.mutate<
    InsertClientContactJobChangeWithOnConflictMutation,
    InsertClientContactJobChangeWithOnConflictMutationVariables
  >(mutationOptions);

  return {
    ...mutation,
    clientContactJobChange:
      mutation?.data?.insert_clientContactJobChange?.returning &&
      mutation.data.insert_clientContactJobChange.returning[0],
  };
}

// Insert Objects Helper
//
type InsertClientContactJobChangeObjectsFetchResult = FetchResult<
  InsertClientContactJobChangeMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertClientContactJobChangeObjectsHelperResultEx = InsertClientContactJobChangeObjectsFetchResult &
  ClientContactJobChangeObjectsHelperResultEx;

async function insertClientContactJobChangeObjects({
  apolloClient,
  objects,
  autoOptimisticResponse,
  fieldMap,
  options,
}: {
  apolloClient: ApolloClient<object>;
  objects: ClientContactJobChange_Insert_Input[];
  autoOptimisticResponse?: boolean;
  fieldMap?: FieldMap;
  options?: Omit<
    MutationOptions<InsertClientContactJobChangeMutation, InsertClientContactJobChangeMutationVariables>,
    'mutation' | 'variables'
  >;
}): Promise<InsertClientContactJobChangeObjectsHelperResultEx> {
  const objectsForInsert = objects.map((objectItem) => stripInsertInputClientFields({ input: objectItem }));
  const mutationOptions: MutationOptions<
    InsertClientContactJobChangeMutation,
    InsertClientContactJobChangeMutationVariables
  > = { mutation: InsertClientContactJobChangeDocument, variables: { objects: objectsForInsert }, ...options };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    if (objectsForInsert.find((objectItem) => !objectItem.id))
      throw new Error(`if autoOptimisticResponse = true, ids must be set in objects`);
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertClientContactJobChangeMutation>({
      operationType: 'insert',
      entityName: 'clientContactJobChange',
      objects: objectsForInsert as (ClientContactJobChange_Insert_Input & ObjectWithId)[],
      fieldMap,
    });
    if (logLevel >= 2)
      console.log(' --> insertClientContactJobChange - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: InsertClientContactJobChangeObjectsFetchResult = await apolloClient.mutate<
    InsertClientContactJobChangeMutation,
    InsertClientContactJobChangeMutationVariables
  >(mutationOptions);

  return { ...mutation, objects: mutation?.data?.insert_clientContactJobChange?.returning || [] };
}

// Update Helper
//
type UpdateClientContactJobChangeByIdQueryResult = FetchResult<
  UpdateClientContactJobChangeByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateClientContactJobChangeByIdHelperResultEx = UpdateClientContactJobChangeByIdQueryResult &
  ClientContactJobChangeByIdHelperResultEx;

async function updateClientContactJobChangeById({
  apolloClient,
  clientContactJobChangeId,
  set,
  autoOptimisticResponse,
  options,
}: {
  apolloClient: ApolloClient<object>;
  clientContactJobChangeId: string;
  set: ClientContactJobChange_Set_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationOptions<UpdateClientContactJobChangeByIdMutation, UpdateClientContactJobChangeByIdMutationVariables>,
    'mutation'
  >;
}): Promise<UpdateClientContactJobChangeByIdHelperResultEx> {
  const mutationOptions: MutationOptions<
    UpdateClientContactJobChangeByIdMutation,
    UpdateClientContactJobChangeByIdMutationVariables
  > = {
    mutation: UpdateClientContactJobChangeByIdDocument,
    variables: { id: clientContactJobChangeId, set },
    ...options,
  };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateClientContactJobChangeByIdMutation>(
      {
        operationType: 'update',
        entityName: 'clientContactJobChange',
        objects: [{ id: clientContactJobChangeId, ...set }],
      }
    );
    if (logLevel >= 2)
      console.log(' --> updateClientContactJobChangeById - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: UpdateClientContactJobChangeByIdQueryResult = await apolloClient.mutate<
    UpdateClientContactJobChangeByIdMutation,
    UpdateClientContactJobChangeByIdMutationVariables
  >(mutationOptions);

  return {
    ...mutation,
    clientContactJobChange:
      mutation?.data?.update_clientContactJobChange?.returning &&
      mutation.data.update_clientContactJobChange.returning[0],
  };
}

// Update Objects Helper
//
type UpdateClientContactJobChangeObjectsFetchResult = FetchResult<
  UpdateClientContactJobChangeMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateClientContactJobChangeObjectsHelperResultEx = UpdateClientContactJobChangeObjectsFetchResult &
  ClientContactJobChangeObjectsHelperResultEx;

async function updateClientContactJobChangeObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<
    MutationOptions<UpdateClientContactJobChangeMutation, UpdateClientContactJobChangeMutationVariables>,
    'mutation'
  >;
}): Promise<UpdateClientContactJobChangeObjectsHelperResultEx> {
  const mutation: UpdateClientContactJobChangeObjectsFetchResult = await apolloClient.mutate<
    UpdateClientContactJobChangeMutation,
    UpdateClientContactJobChangeMutationVariables
  >({ mutation: UpdateClientContactJobChangeDocument, ...options });

  return { ...mutation, objects: mutation?.data?.update_clientContactJobChange?.returning || [] };
}

// Delete Helper
//

type RemoveClientContactJobChangeModelByIdQueryResult = FetchResult<
  RemoveClientContactJobChangeModelByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveClientContactJobChangeModelByIdQueryHelperResultEx = RemoveClientContactJobChangeModelByIdQueryResult &
  RemoveEntitiesQueryHelperResultEx;

async function removeClientContactJobChangeModelById({
  apolloClient,
  clientContactJobChangeId,
  autoOptimisticResponse,
  options,
}: {
  apolloClient: ApolloClient<object>;
  clientContactJobChangeId: string;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationOptions<
      RemoveClientContactJobChangeModelByIdMutation,
      RemoveClientContactJobChangeModelByIdMutationVariables
    >,
    'mutation'
  >;
}): Promise<RemoveClientContactJobChangeModelByIdQueryHelperResultEx> {
  const mutationOptions: MutationOptions<
    RemoveClientContactJobChangeModelByIdMutation,
    RemoveClientContactJobChangeModelByIdMutationVariables
  > = {
    mutation: RemoveClientContactJobChangeModelByIdDocument,
    variables: { id: clientContactJobChangeId },
    ...options,
  };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<RemoveClientContactJobChangeModelByIdMutation>(
      { operationType: 'delete', entityName: 'clientContactJobChange', objects: [{ id: clientContactJobChangeId }] }
    );
    if (logLevel >= 2)
      console.log(
        ' --> removeClientContactJobChangeModelById - optimisticResponse:',
        mutationOptions.optimisticResponse
      );
  }
  if (!options || !options.update) {
    mutationOptions.update = generateUpdateFunctionForMutation<RemoveClientContactJobChangeModelByIdMutation>({
      operationType: 'delete',
      entityName: 'clientContactJobChange',
      entityId: clientContactJobChangeId,
    });
  }

  const mutation: RemoveClientContactJobChangeModelByIdQueryResult = await apolloClient.mutate<
    RemoveClientContactJobChangeModelByIdMutation,
    RemoveClientContactJobChangeModelByIdMutationVariables
  >(mutationOptions);

  return { ...mutation, affected_rows: mutation?.data?.delete_clientContactJobChange?.affected_rows || 0 };
}

type RemoveClientContactJobChangeModelObjectsQueryResult = FetchResult<
  RemoveClientContactJobChangeModelMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveClientContactJobChangeModelObjectsQueryHelperResultEx = RemoveClientContactJobChangeModelObjectsQueryResult &
  RemoveEntitiesQueryHelperResultEx;

async function removeClientContactJobChangeModelObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<
    MutationOptions<RemoveClientContactJobChangeModelMutation, RemoveClientContactJobChangeModelMutationVariables>,
    'mutation'
  >;
}): Promise<RemoveClientContactJobChangeModelObjectsQueryHelperResultEx> {
  const mutation: RemoveClientContactJobChangeModelObjectsQueryResult = await apolloClient.mutate<
    RemoveClientContactJobChangeModelMutation,
    RemoveClientContactJobChangeModelMutationVariables
  >({ mutation: RemoveClientContactJobChangeModelDocument, ...options });

  return { ...mutation, affected_rows: mutation?.data?.delete_clientContactJobChange?.affected_rows || 0 };
}

// ClientContactJobChange Fragment Helper Object
//------------------------------------------------

export const ClientContactJobChangeFragmentGQLHelper = {
  cacheGetDataId: cacheGetDataIdForClientContactJobChange,
  cacheReadFragmentById: cacheReadFragmentClientContactJobChangeById,
  cacheWriteFragmentById: cacheWriteFragmentClientContactJobChangeById,
  cacheReadQueryById: cacheReadQueryClientContactJobChangeById,
  cacheWriteQueryById: cacheWriteQueryClientContactJobChangeById,
  cacheReadQueryObjects: cacheReadQueryClientContactJobChangeObjects,
  cacheWriteQueryObjects: cacheWriteQueryClientContactJobChangeObjects,
  cacheWriteQueryInsert: cacheWriteQueryClientContactJobChangeInsert,
  cacheWriteQueryRemove: cacheWriteQueryClientContactJobChangeRemove,
  queryById: queryClientContactJobChangeById,
  queryObjects: queryClientContactJobChangeObjects,
  watchQueryById: watchQueryClientContactJobChangeById,
  watchQueryObjects: watchQueryClientContactJobChangeObjects,
  subscribeToById: subscribeToClientContactJobChangeById,
  subscribeToObjects: subscribeToClientContactJobChangeObjects,
  insert: insertClientContactJobChange,
  insertWithOnConflict: insertClientContactJobChangeWithOnConflict,
  insertObjects: insertClientContactJobChangeObjects,
  updateById: updateClientContactJobChangeById,
  updateObjects: updateClientContactJobChangeObjects,
};

// clientContactJobChange Entity Helper Object
//------------------------------------------------

export const ClientContactJobChangeModelGQLHelper = {
  removeById: removeClientContactJobChangeModelById,
  removeObjects: removeClientContactJobChangeModelObjects,
};

// clientContact HELPERS
//------------------------------------------------

export type ClientContactExternalByIdHelperResultEx = {
  clientContactExternal: ClientContactExternalFragment | null | undefined;
};
export type ClientContactExternalObjectsHelperResultEx = { objects: ClientContactExternalFragment[] };

// Direct Client & Cache Fragment Helpers
//
function cacheGetDataIdForClientContactExternal(clientContactId: string): string {
  return defaultCacheIdFromObject({ __typename: 'clientContact', id: clientContactId });
}

function cacheReadFragmentClientContactExternalById({
  apolloCache,
  clientContactId,
}: {
  apolloCache: ApolloCache<object>;
  clientContactId: string;
}): ClientContactExternalFragment | null | undefined {
  return apolloCache.readFragment<ClientContactExternalFragment | null | undefined>({
    fragment: ClientContactExternalFragmentDoc,
    fragmentName: 'ClientContactExternal',
    id: defaultCacheIdFromObject({ __typename: 'clientContact', id: clientContactId }),
  });
}

function cacheWriteFragmentClientContactExternalById({
  apolloCache,
  clientContactId,
  clientContactExternalPartial,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  clientContactId: string;
  clientContactExternalPartial: Partial<ClientContactExternalFragment> | ClientContact_Insert_Input | null;
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): Partial<ClientContactExternalFragment> {
  const parsedFragment = convertToGraph({ input: clientContactExternalPartial, typename: 'clientContact', fieldMap });
  if (logLevel >= 2) console.log(' --> cacheWriteFragmentClientContactExternalById - parsedFragment:', parsedFragment);
  apolloCache.writeFragment<Partial<ClientContactExternalFragment> | null>({
    fragment: ClientContactExternalFragmentDoc,
    fragmentName: 'ClientContactExternal',
    id: defaultCacheIdFromObject({ ...parsedFragment, id: clientContactId }),
    data: parsedFragment,
    broadcast: apolloBroadcast,
  });
  return parsedFragment;
}

function cacheReadQueryClientContactExternalById({
  apolloCache,
  clientContactId,
}: {
  apolloCache: ApolloCache<object>;
  clientContactId: string;
}): ClientContactExternalFragment | null | undefined {
  try {
    return apolloCache.readQuery<ClientContactExternalFragment | null>({
      query: QueryClientContactExternalByIdDocument,
      variables: { clientContactId },
    });
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheReadQueryClientContactExternalById threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheWriteQueryClientContactExternalById({
  apolloCache,
  clientContactId,
  clientContactExternal,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  clientContactId: string;
  clientContactExternal: ClientContactExternalFragment | ClientContact_Insert_Input | null;
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): void {
  try {
    const clientContactExternalPartial = convertToGraph({
      input: clientContactExternal,
      typename: 'clientContact',
      fieldMap,
    });
    return apolloCache.writeQuery<ClientContactExternalFragment | null>({
      query: QueryClientContactExternalByIdDocument,
      variables: { clientContactId },
      data: clientContactExternal ? clientContactExternalPartial : null,
      broadcast: apolloBroadcast,
    });
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheWriteQueryClientContactExternalById threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheReadQueryClientContactExternalObjects({
  apolloCache,
  variables,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryClientContactExternalObjectsQueryVariables;
}): ClientContact[] | null | undefined {
  try {
    return (
      apolloCache.readQuery<{ ClientContact: ClientContact[] | null }>({
        query: QueryClientContactExternalObjectsDocument,
        variables,
      })?.ClientContact || []
    );
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheReadQueryClientContactExternalObjects threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheWriteQueryClientContactExternalObjects({
  apolloCache,
  variables,
  data,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryClientContactExternalObjectsQueryVariables;
  data: (ClientContact | ClientContact_Insert_Input)[];
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): void {
  try {
    const objects = convertToGraph({ input: data, typename: 'clientContact', fieldMap });
    return apolloCache.writeQuery<{ ClientContact: ClientContact[] }>({
      query: QueryClientContactExternalObjectsDocument,
      variables,
      data: { ClientContact: objects },
      broadcast: apolloBroadcast,
    });
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheWriteQueryClientContactExternalObjects threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheWriteQueryClientContactExternalInsert({
  apolloCache,
  variables,
  clientContact,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryClientContactExternalObjectsQueryVariables;
  clientContact: ClientContact_Insert_Input;
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): void {
  const currentObjects = cacheReadQueryClientContactExternalObjects({ apolloCache, variables }) || [];
  const objectsWithInserted = [
    ...currentObjects,
    convertToGraph({ input: clientContact, typename: 'clientContact', fieldMap }),
  ];
  if (logLevel >= 2)
    console.log(' --> cacheWriteQueryClientContactExternalInsert - objectsWithInserted:', objectsWithInserted);
  return cacheWriteQueryClientContactExternalObjects({
    apolloCache,
    variables,
    data: objectsWithInserted,
    apolloBroadcast,
  });
}

function cacheWriteQueryClientContactExternalRemove({
  apolloCache,
  variables,
  clientContactId,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryClientContactExternalObjectsQueryVariables;
  clientContactId: string;
  apolloBroadcast?: boolean;
}): void {
  const currentObjects = cacheReadQueryClientContactExternalObjects({ apolloCache, variables }) || [];
  const objectsWithRemoved = currentObjects.filter((objectItem) => objectItem.id !== clientContactId) || [];
  if (logLevel >= 2)
    console.log(' --> cacheWriteQueryClientContactExternalRemove - objectsWithRemoved:', objectsWithRemoved);
  return cacheWriteQueryClientContactExternalObjects({
    apolloCache,
    variables,
    data: objectsWithRemoved,
    apolloBroadcast,
  });
}

// Query Fetch Helper
//
export type QueryClientContactExternalByIdApolloQueryResult = ApolloQueryResult<QueryClientContactExternalByIdQuery>;
export type QueryClientContactExternalByIdApolloQueryHelperResultEx = QueryClientContactExternalByIdApolloQueryResult &
  ClientContactExternalByIdHelperResultEx;

async function queryClientContactExternalById({
  apolloClient,
  clientContactId,
  options,
}: {
  apolloClient: ApolloClient<object>;
  clientContactId: string;
  options?: Omit<QueryOptions<QueryClientContactExternalByIdQueryVariables>, 'query' | 'variables'>;
}): Promise<QueryClientContactExternalByIdApolloQueryHelperResultEx> {
  const query: QueryClientContactExternalByIdApolloQueryResult = await apolloClient.query<QueryClientContactExternalByIdQuery>(
    { query: QueryClientContactExternalByIdDocument, variables: { clientContactId }, ...options }
  );

  return { ...query, clientContactExternal: query?.data?.clientContact_by_pk };
}

// Query Watch ById Helper
//
export type WatchQueryClientContactExternalByIdApolloObservableQuery = ObservableQuery<QueryClientContactExternalByIdQuery>;
async function watchQueryClientContactExternalById({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<QueryOptions<QueryClientContactExternalByIdQueryVariables>, 'query'>;
}): Promise<WatchQueryClientContactExternalByIdApolloObservableQuery> {
  return apolloClient.watchQuery<QueryClientContactExternalByIdQuery>({
    query: QueryClientContactExternalByIdDocument,
    ...options,
  });
}

// Query Fetch Objects Helper
//
export type QueryClientContactExternalObjectsObjectsApolloQueryResult = ApolloQueryResult<QueryClientContactExternalObjectsQuery>;
export type QueryClientContactExternalObjectsObjectsApolloQueryResultEx = QueryClientContactExternalObjectsObjectsApolloQueryResult &
  ClientContactExternalObjectsHelperResultEx;

async function queryClientContactExternalObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<QueryOptions<QueryClientContactExternalObjectsQueryVariables>, 'query'>;
}): Promise<QueryClientContactExternalObjectsObjectsApolloQueryResultEx> {
  const query: QueryClientContactExternalObjectsObjectsApolloQueryResult = await apolloClient.query<QueryClientContactExternalObjectsQuery>(
    { query: QueryClientContactExternalObjectsDocument, ...options }
  );

  return { ...query, objects: query?.data?.clientContact || [] };
}

// Query Watch Objects Helper
//
export type WatchQueryClientContactExternalObjectsApolloObservableQuery = ObservableQuery<QueryClientContactExternalObjectsQuery>;
async function watchQueryClientContactExternalObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<QueryOptions<QueryClientContactExternalObjectsQueryVariables>, 'query'>;
}): Promise<WatchQueryClientContactExternalObjectsApolloObservableQuery> {
  return apolloClient.watchQuery<QueryClientContactExternalObjectsQuery>({
    query: QueryClientContactExternalObjectsDocument,
    ...options,
  });
}

// Subscription Fetch ById Helper
//
export type SubscribeToClientContactExternalByIdSubscriptionFetchResult = FetchResult<
  SubscribeToClientContactExternalByIdSubscription,
  Record<string, any>,
  Record<string, any>
>;
export type SubscribeToClientContactExternalByIdSubscriptionFetchResultEx = FetchResult<
  SubscribeToClientContactExternalByIdSubscription,
  Record<string, any>,
  Record<string, any>
> &
  ClientContactExternalByIdHelperResultEx;

async function subscribeToClientContactExternalById({
  apolloClient,
  clientContactId,
  options,
}: {
  apolloClient: ApolloClient<object>;
  clientContactId: string;
  options?: Omit<SubscriptionOptions<SubscribeToClientContactExternalByIdSubscriptionVariables>, 'query' | 'variables'>;
}): Promise<Observable<SubscribeToClientContactExternalByIdSubscriptionFetchResultEx>> {
  const subscription: Observable<SubscribeToClientContactExternalByIdSubscriptionFetchResult> = apolloClient.subscribe<SubscribeToClientContactExternalByIdSubscription>(
    { query: SubscribeToClientContactExternalByIdDocument, variables: { clientContactId }, ...options }
  );

  return subscription.map((value) => {
    return {
      context: value.context,
      errors: value.errors,
      data: value.data,
      extensions: value.extensions,
      clientContactExternal: value?.data?.clientContact_by_pk || [],
    } as SubscribeToClientContactExternalByIdSubscriptionFetchResultEx;
  });
}

// Subscription Fetch Objects Helper
//
export type SubscribeToClientContactExternalObjectsSubscriptionFetchResult = FetchResult<
  SubscribeToClientContactExternalObjectsSubscription,
  Record<string, any>,
  Record<string, any>
>;
export type SubscribeToClientContactExternalObjectsSubscriptionFetchResultEx = FetchResult<
  SubscribeToClientContactExternalObjectsSubscription,
  Record<string, any>,
  Record<string, any>
> &
  ClientContactExternalObjectsHelperResultEx;

async function subscribeToClientContactExternalObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options?: Omit<SubscriptionOptions<SubscribeToClientContactExternalObjectsSubscriptionVariables>, 'query'>;
}): Promise<Observable<SubscribeToClientContactExternalObjectsSubscriptionFetchResultEx>> {
  const subscription: Observable<SubscribeToClientContactExternalObjectsSubscriptionFetchResult> = apolloClient.subscribe<SubscribeToClientContactExternalObjectsSubscription>(
    { query: SubscribeToClientContactExternalObjectsDocument, ...options }
  );

  return subscription.map((value) => {
    return {
      context: value.context,
      errors: value.errors,
      data: value.data,
      extensions: value.extensions,
      objects: value?.data?.clientContact || [],
    } as SubscribeToClientContactExternalObjectsSubscriptionFetchResultEx;
  });
}

// Insert Helper
//
type InsertClientContactExternalFetchResult = FetchResult<
  InsertClientContactExternalMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertClientContactExternalFetchHelperResultEx = InsertClientContactExternalFetchResult &
  ClientContactExternalByIdHelperResultEx;

async function insertClientContactExternal({
  apolloClient,
  clientContact,
  autoOptimisticResponse,
  fieldMap,
  options,
}: {
  apolloClient: ApolloClient<object>;
  clientContact: ClientContact_Insert_Input;
  autoOptimisticResponse?: boolean;
  fieldMap?: FieldMap;
  options?: Omit<
    MutationOptions<InsertClientContactExternalMutation, InsertClientContactExternalMutationVariables>,
    'mutation' | 'variables'
  >;
}): Promise<InsertClientContactExternalFetchHelperResultEx> {
  const objectForInsert = stripInsertInputClientFields({ input: clientContact });
  const mutationOptions: MutationOptions<
    InsertClientContactExternalMutation,
    InsertClientContactExternalMutationVariables
  > = { mutation: InsertClientContactExternalDocument, variables: { objects: [objectForInsert] }, ...options };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    if (!objectForInsert.id)
      throw new Error(`if autoOptimisticResponse = true, id must be set in object 'clientContact'`);
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertClientContactExternalMutation>({
      operationType: 'insert',
      entityName: 'clientContact',
      objects: [objectForInsert as ClientContact_Insert_Input & ObjectWithId],
      fieldMap,
    });
    if (logLevel >= 2)
      console.log(' --> insertClientContactExternal - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: InsertClientContactExternalFetchResult = await apolloClient.mutate<
    InsertClientContactExternalMutation,
    InsertClientContactExternalMutationVariables
  >(mutationOptions);

  return {
    ...mutation,
    clientContactExternal:
      mutation?.data?.insert_clientContact?.returning && mutation.data.insert_clientContact.returning[0],
  };
}

async function insertClientContactExternalWithOnConflict({
  apolloClient,
  clientContact,
  onConflict,
  autoOptimisticResponse,
  fieldMap,
  options,
}: {
  apolloClient: ApolloClient<object>;
  clientContact: ClientContact_Insert_Input;
  onConflict: ClientContact_On_Conflict;
  autoOptimisticResponse?: boolean;
  fieldMap?: FieldMap;
  options?: Omit<
    MutationOptions<
      InsertClientContactExternalWithOnConflictMutation,
      InsertClientContactExternalWithOnConflictMutationVariables
    >,
    'mutation' | 'variables'
  >;
}): Promise<InsertClientContactExternalFetchHelperResultEx> {
  const objectForInsert = stripInsertInputClientFields({ input: clientContact });
  const mutationOptions: MutationOptions<
    InsertClientContactExternalWithOnConflictMutation,
    InsertClientContactExternalWithOnConflictMutationVariables
  > = {
    mutation: InsertClientContactExternalWithOnConflictDocument,
    variables: { objects: [objectForInsert], onConflict },
    ...options,
  };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    if (!objectForInsert.id)
      throw new Error(`if autoOptimisticResponse = true, id must be set in object 'clientContact'`);
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertClientContactExternalWithOnConflictMutation>(
      {
        operationType: 'insert',
        entityName: 'clientContact',
        objects: [objectForInsert as ClientContact_Insert_Input & ObjectWithId],
        fieldMap,
      }
    );
    if (logLevel >= 2)
      console.log(
        ' --> insertClientContactExternalWithOnConflict - optimisticResponse:',
        mutationOptions.optimisticResponse
      );
  }

  const mutation: InsertClientContactExternalFetchResult = await apolloClient.mutate<
    InsertClientContactExternalWithOnConflictMutation,
    InsertClientContactExternalWithOnConflictMutationVariables
  >(mutationOptions);

  return {
    ...mutation,
    clientContactExternal:
      mutation?.data?.insert_clientContact?.returning && mutation.data.insert_clientContact.returning[0],
  };
}

// Insert Objects Helper
//
type InsertClientContactExternalObjectsFetchResult = FetchResult<
  InsertClientContactExternalMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertClientContactExternalObjectsHelperResultEx = InsertClientContactExternalObjectsFetchResult &
  ClientContactExternalObjectsHelperResultEx;

async function insertClientContactExternalObjects({
  apolloClient,
  objects,
  autoOptimisticResponse,
  fieldMap,
  options,
}: {
  apolloClient: ApolloClient<object>;
  objects: ClientContact_Insert_Input[];
  autoOptimisticResponse?: boolean;
  fieldMap?: FieldMap;
  options?: Omit<
    MutationOptions<InsertClientContactExternalMutation, InsertClientContactExternalMutationVariables>,
    'mutation' | 'variables'
  >;
}): Promise<InsertClientContactExternalObjectsHelperResultEx> {
  const objectsForInsert = objects.map((objectItem) => stripInsertInputClientFields({ input: objectItem }));
  const mutationOptions: MutationOptions<
    InsertClientContactExternalMutation,
    InsertClientContactExternalMutationVariables
  > = { mutation: InsertClientContactExternalDocument, variables: { objects: objectsForInsert }, ...options };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    if (objectsForInsert.find((objectItem) => !objectItem.id))
      throw new Error(`if autoOptimisticResponse = true, ids must be set in objects`);
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertClientContactExternalMutation>({
      operationType: 'insert',
      entityName: 'clientContact',
      objects: objectsForInsert as (ClientContact_Insert_Input & ObjectWithId)[],
      fieldMap,
    });
    if (logLevel >= 2)
      console.log(' --> insertClientContactExternal - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: InsertClientContactExternalObjectsFetchResult = await apolloClient.mutate<
    InsertClientContactExternalMutation,
    InsertClientContactExternalMutationVariables
  >(mutationOptions);

  return { ...mutation, objects: mutation?.data?.insert_clientContact?.returning || [] };
}

// Update Helper
//
type UpdateClientContactExternalByIdQueryResult = FetchResult<
  UpdateClientContactExternalByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateClientContactExternalByIdHelperResultEx = UpdateClientContactExternalByIdQueryResult &
  ClientContactExternalByIdHelperResultEx;

async function updateClientContactExternalById({
  apolloClient,
  clientContactId,
  set,
  autoOptimisticResponse,
  options,
}: {
  apolloClient: ApolloClient<object>;
  clientContactId: string;
  set: ClientContact_Set_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationOptions<UpdateClientContactExternalByIdMutation, UpdateClientContactExternalByIdMutationVariables>,
    'mutation'
  >;
}): Promise<UpdateClientContactExternalByIdHelperResultEx> {
  const mutationOptions: MutationOptions<
    UpdateClientContactExternalByIdMutation,
    UpdateClientContactExternalByIdMutationVariables
  > = { mutation: UpdateClientContactExternalByIdDocument, variables: { id: clientContactId, set }, ...options };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateClientContactExternalByIdMutation>(
      { operationType: 'update', entityName: 'clientContact', objects: [{ id: clientContactId, ...set }] }
    );
    if (logLevel >= 2)
      console.log(' --> updateClientContactExternalById - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: UpdateClientContactExternalByIdQueryResult = await apolloClient.mutate<
    UpdateClientContactExternalByIdMutation,
    UpdateClientContactExternalByIdMutationVariables
  >(mutationOptions);

  return {
    ...mutation,
    clientContactExternal:
      mutation?.data?.update_clientContact?.returning && mutation.data.update_clientContact.returning[0],
  };
}

// Update Objects Helper
//
type UpdateClientContactExternalObjectsFetchResult = FetchResult<
  UpdateClientContactExternalMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateClientContactExternalObjectsHelperResultEx = UpdateClientContactExternalObjectsFetchResult &
  ClientContactExternalObjectsHelperResultEx;

async function updateClientContactExternalObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<
    MutationOptions<UpdateClientContactExternalMutation, UpdateClientContactExternalMutationVariables>,
    'mutation'
  >;
}): Promise<UpdateClientContactExternalObjectsHelperResultEx> {
  const mutation: UpdateClientContactExternalObjectsFetchResult = await apolloClient.mutate<
    UpdateClientContactExternalMutation,
    UpdateClientContactExternalMutationVariables
  >({ mutation: UpdateClientContactExternalDocument, ...options });

  return { ...mutation, objects: mutation?.data?.update_clientContact?.returning || [] };
}

// Delete Helper
//

type RemoveClientContactModelByIdQueryResult = FetchResult<
  RemoveClientContactModelByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveClientContactModelByIdQueryHelperResultEx = RemoveClientContactModelByIdQueryResult &
  RemoveEntitiesQueryHelperResultEx;

async function removeClientContactModelById({
  apolloClient,
  clientContactId,
  autoOptimisticResponse,
  options,
}: {
  apolloClient: ApolloClient<object>;
  clientContactId: string;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationOptions<RemoveClientContactModelByIdMutation, RemoveClientContactModelByIdMutationVariables>,
    'mutation'
  >;
}): Promise<RemoveClientContactModelByIdQueryHelperResultEx> {
  const mutationOptions: MutationOptions<
    RemoveClientContactModelByIdMutation,
    RemoveClientContactModelByIdMutationVariables
  > = { mutation: RemoveClientContactModelByIdDocument, variables: { id: clientContactId }, ...options };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<RemoveClientContactModelByIdMutation>({
      operationType: 'delete',
      entityName: 'clientContact',
      objects: [{ id: clientContactId }],
    });
    if (logLevel >= 2)
      console.log(' --> removeClientContactModelById - optimisticResponse:', mutationOptions.optimisticResponse);
  }
  if (!options || !options.update) {
    mutationOptions.update = generateUpdateFunctionForMutation<RemoveClientContactModelByIdMutation>({
      operationType: 'delete',
      entityName: 'clientContact',
      entityId: clientContactId,
    });
  }

  const mutation: RemoveClientContactModelByIdQueryResult = await apolloClient.mutate<
    RemoveClientContactModelByIdMutation,
    RemoveClientContactModelByIdMutationVariables
  >(mutationOptions);

  return { ...mutation, affected_rows: mutation?.data?.delete_clientContact?.affected_rows || 0 };
}

type RemoveClientContactModelObjectsQueryResult = FetchResult<
  RemoveClientContactModelMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveClientContactModelObjectsQueryHelperResultEx = RemoveClientContactModelObjectsQueryResult &
  RemoveEntitiesQueryHelperResultEx;

async function removeClientContactModelObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<
    MutationOptions<RemoveClientContactModelMutation, RemoveClientContactModelMutationVariables>,
    'mutation'
  >;
}): Promise<RemoveClientContactModelObjectsQueryHelperResultEx> {
  const mutation: RemoveClientContactModelObjectsQueryResult = await apolloClient.mutate<
    RemoveClientContactModelMutation,
    RemoveClientContactModelMutationVariables
  >({ mutation: RemoveClientContactModelDocument, ...options });

  return { ...mutation, affected_rows: mutation?.data?.delete_clientContact?.affected_rows || 0 };
}

// ClientContactExternal Fragment Helper Object
//------------------------------------------------

export const ClientContactExternalFragmentGQLHelper = {
  cacheGetDataId: cacheGetDataIdForClientContactExternal,
  cacheReadFragmentById: cacheReadFragmentClientContactExternalById,
  cacheWriteFragmentById: cacheWriteFragmentClientContactExternalById,
  cacheReadQueryById: cacheReadQueryClientContactExternalById,
  cacheWriteQueryById: cacheWriteQueryClientContactExternalById,
  cacheReadQueryObjects: cacheReadQueryClientContactExternalObjects,
  cacheWriteQueryObjects: cacheWriteQueryClientContactExternalObjects,
  cacheWriteQueryInsert: cacheWriteQueryClientContactExternalInsert,
  cacheWriteQueryRemove: cacheWriteQueryClientContactExternalRemove,
  queryById: queryClientContactExternalById,
  queryObjects: queryClientContactExternalObjects,
  watchQueryById: watchQueryClientContactExternalById,
  watchQueryObjects: watchQueryClientContactExternalObjects,
  subscribeToById: subscribeToClientContactExternalById,
  subscribeToObjects: subscribeToClientContactExternalObjects,
  insert: insertClientContactExternal,
  insertWithOnConflict: insertClientContactExternalWithOnConflict,
  insertObjects: insertClientContactExternalObjects,
  updateById: updateClientContactExternalById,
  updateObjects: updateClientContactExternalObjects,
};

// clientContact Entity Helper Object
//------------------------------------------------

export const ClientContactModelGQLHelper = {
  removeById: removeClientContactModelById,
  removeObjects: removeClientContactModelObjects,
};

// clientContact HELPERS
//------------------------------------------------

export type ClientContactQaByIdHelperResultEx = { clientContactQa: ClientContactQaFragment | null | undefined };
export type ClientContactQaObjectsHelperResultEx = { objects: ClientContactQaFragment[] };

// Direct Client & Cache Fragment Helpers
//
function cacheGetDataIdForClientContactQa(clientContactId: string): string {
  return defaultCacheIdFromObject({ __typename: 'clientContact', id: clientContactId });
}

function cacheReadFragmentClientContactQaById({
  apolloCache,
  clientContactId,
}: {
  apolloCache: ApolloCache<object>;
  clientContactId: string;
}): ClientContactQaFragment | null | undefined {
  return apolloCache.readFragment<ClientContactQaFragment | null | undefined>({
    fragment: ClientContactQaFragmentDoc,
    fragmentName: 'ClientContactQa',
    id: defaultCacheIdFromObject({ __typename: 'clientContact', id: clientContactId }),
  });
}

function cacheWriteFragmentClientContactQaById({
  apolloCache,
  clientContactId,
  clientContactQaPartial,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  clientContactId: string;
  clientContactQaPartial: Partial<ClientContactQaFragment> | ClientContact_Insert_Input | null;
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): Partial<ClientContactQaFragment> {
  const parsedFragment = convertToGraph({ input: clientContactQaPartial, typename: 'clientContact', fieldMap });
  if (logLevel >= 2) console.log(' --> cacheWriteFragmentClientContactQaById - parsedFragment:', parsedFragment);
  apolloCache.writeFragment<Partial<ClientContactQaFragment> | null>({
    fragment: ClientContactQaFragmentDoc,
    fragmentName: 'ClientContactQa',
    id: defaultCacheIdFromObject({ ...parsedFragment, id: clientContactId }),
    data: parsedFragment,
    broadcast: apolloBroadcast,
  });
  return parsedFragment;
}

function cacheReadQueryClientContactQaById({
  apolloCache,
  clientContactId,
}: {
  apolloCache: ApolloCache<object>;
  clientContactId: string;
}): ClientContactQaFragment | null | undefined {
  try {
    return apolloCache.readQuery<ClientContactQaFragment | null>({
      query: QueryClientContactQaByIdDocument,
      variables: { clientContactId },
    });
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheReadQueryClientContactQaById threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheWriteQueryClientContactQaById({
  apolloCache,
  clientContactId,
  clientContactQa,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  clientContactId: string;
  clientContactQa: ClientContactQaFragment | ClientContact_Insert_Input | null;
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): void {
  try {
    const clientContactQaPartial = convertToGraph({ input: clientContactQa, typename: 'clientContact', fieldMap });
    return apolloCache.writeQuery<ClientContactQaFragment | null>({
      query: QueryClientContactQaByIdDocument,
      variables: { clientContactId },
      data: clientContactQa ? clientContactQaPartial : null,
      broadcast: apolloBroadcast,
    });
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheWriteQueryClientContactQaById threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheReadQueryClientContactQaObjects({
  apolloCache,
  variables,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryClientContactQaObjectsQueryVariables;
}): ClientContact[] | null | undefined {
  try {
    return (
      apolloCache.readQuery<{ ClientContact: ClientContact[] | null }>({
        query: QueryClientContactQaObjectsDocument,
        variables,
      })?.ClientContact || []
    );
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheReadQueryClientContactQaObjects threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheWriteQueryClientContactQaObjects({
  apolloCache,
  variables,
  data,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryClientContactQaObjectsQueryVariables;
  data: (ClientContact | ClientContact_Insert_Input)[];
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): void {
  try {
    const objects = convertToGraph({ input: data, typename: 'clientContact', fieldMap });
    return apolloCache.writeQuery<{ ClientContact: ClientContact[] }>({
      query: QueryClientContactQaObjectsDocument,
      variables,
      data: { ClientContact: objects },
      broadcast: apolloBroadcast,
    });
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheWriteQueryClientContactQaObjects threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheWriteQueryClientContactQaInsert({
  apolloCache,
  variables,
  clientContact,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryClientContactQaObjectsQueryVariables;
  clientContact: ClientContact_Insert_Input;
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): void {
  const currentObjects = cacheReadQueryClientContactQaObjects({ apolloCache, variables }) || [];
  const objectsWithInserted = [
    ...currentObjects,
    convertToGraph({ input: clientContact, typename: 'clientContact', fieldMap }),
  ];
  if (logLevel >= 2)
    console.log(' --> cacheWriteQueryClientContactQaInsert - objectsWithInserted:', objectsWithInserted);
  return cacheWriteQueryClientContactQaObjects({ apolloCache, variables, data: objectsWithInserted, apolloBroadcast });
}

function cacheWriteQueryClientContactQaRemove({
  apolloCache,
  variables,
  clientContactId,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryClientContactQaObjectsQueryVariables;
  clientContactId: string;
  apolloBroadcast?: boolean;
}): void {
  const currentObjects = cacheReadQueryClientContactQaObjects({ apolloCache, variables }) || [];
  const objectsWithRemoved = currentObjects.filter((objectItem) => objectItem.id !== clientContactId) || [];
  if (logLevel >= 2) console.log(' --> cacheWriteQueryClientContactQaRemove - objectsWithRemoved:', objectsWithRemoved);
  return cacheWriteQueryClientContactQaObjects({ apolloCache, variables, data: objectsWithRemoved, apolloBroadcast });
}

// Query Fetch Helper
//
export type QueryClientContactQaByIdApolloQueryResult = ApolloQueryResult<QueryClientContactQaByIdQuery>;
export type QueryClientContactQaByIdApolloQueryHelperResultEx = QueryClientContactQaByIdApolloQueryResult &
  ClientContactQaByIdHelperResultEx;

async function queryClientContactQaById({
  apolloClient,
  clientContactId,
  options,
}: {
  apolloClient: ApolloClient<object>;
  clientContactId: string;
  options?: Omit<QueryOptions<QueryClientContactQaByIdQueryVariables>, 'query' | 'variables'>;
}): Promise<QueryClientContactQaByIdApolloQueryHelperResultEx> {
  const query: QueryClientContactQaByIdApolloQueryResult = await apolloClient.query<QueryClientContactQaByIdQuery>({
    query: QueryClientContactQaByIdDocument,
    variables: { clientContactId },
    ...options,
  });

  return { ...query, clientContactQa: query?.data?.clientContact_by_pk };
}

// Query Watch ById Helper
//
export type WatchQueryClientContactQaByIdApolloObservableQuery = ObservableQuery<QueryClientContactQaByIdQuery>;
async function watchQueryClientContactQaById({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<QueryOptions<QueryClientContactQaByIdQueryVariables>, 'query'>;
}): Promise<WatchQueryClientContactQaByIdApolloObservableQuery> {
  return apolloClient.watchQuery<QueryClientContactQaByIdQuery>({
    query: QueryClientContactQaByIdDocument,
    ...options,
  });
}

// Query Fetch Objects Helper
//
export type QueryClientContactQaObjectsObjectsApolloQueryResult = ApolloQueryResult<QueryClientContactQaObjectsQuery>;
export type QueryClientContactQaObjectsObjectsApolloQueryResultEx = QueryClientContactQaObjectsObjectsApolloQueryResult &
  ClientContactQaObjectsHelperResultEx;

async function queryClientContactQaObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<QueryOptions<QueryClientContactQaObjectsQueryVariables>, 'query'>;
}): Promise<QueryClientContactQaObjectsObjectsApolloQueryResultEx> {
  const query: QueryClientContactQaObjectsObjectsApolloQueryResult = await apolloClient.query<QueryClientContactQaObjectsQuery>(
    { query: QueryClientContactQaObjectsDocument, ...options }
  );

  return { ...query, objects: query?.data?.clientContact || [] };
}

// Query Watch Objects Helper
//
export type WatchQueryClientContactQaObjectsApolloObservableQuery = ObservableQuery<QueryClientContactQaObjectsQuery>;
async function watchQueryClientContactQaObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<QueryOptions<QueryClientContactQaObjectsQueryVariables>, 'query'>;
}): Promise<WatchQueryClientContactQaObjectsApolloObservableQuery> {
  return apolloClient.watchQuery<QueryClientContactQaObjectsQuery>({
    query: QueryClientContactQaObjectsDocument,
    ...options,
  });
}

// Subscription Fetch ById Helper
//
export type SubscribeToClientContactQaByIdSubscriptionFetchResult = FetchResult<
  SubscribeToClientContactQaByIdSubscription,
  Record<string, any>,
  Record<string, any>
>;
export type SubscribeToClientContactQaByIdSubscriptionFetchResultEx = FetchResult<
  SubscribeToClientContactQaByIdSubscription,
  Record<string, any>,
  Record<string, any>
> &
  ClientContactQaByIdHelperResultEx;

async function subscribeToClientContactQaById({
  apolloClient,
  clientContactId,
  options,
}: {
  apolloClient: ApolloClient<object>;
  clientContactId: string;
  options?: Omit<SubscriptionOptions<SubscribeToClientContactQaByIdSubscriptionVariables>, 'query' | 'variables'>;
}): Promise<Observable<SubscribeToClientContactQaByIdSubscriptionFetchResultEx>> {
  const subscription: Observable<SubscribeToClientContactQaByIdSubscriptionFetchResult> = apolloClient.subscribe<SubscribeToClientContactQaByIdSubscription>(
    { query: SubscribeToClientContactQaByIdDocument, variables: { clientContactId }, ...options }
  );

  return subscription.map((value) => {
    return {
      context: value.context,
      errors: value.errors,
      data: value.data,
      extensions: value.extensions,
      clientContactQa: value?.data?.clientContact_by_pk || [],
    } as SubscribeToClientContactQaByIdSubscriptionFetchResultEx;
  });
}

// Subscription Fetch Objects Helper
//
export type SubscribeToClientContactQaObjectsSubscriptionFetchResult = FetchResult<
  SubscribeToClientContactQaObjectsSubscription,
  Record<string, any>,
  Record<string, any>
>;
export type SubscribeToClientContactQaObjectsSubscriptionFetchResultEx = FetchResult<
  SubscribeToClientContactQaObjectsSubscription,
  Record<string, any>,
  Record<string, any>
> &
  ClientContactQaObjectsHelperResultEx;

async function subscribeToClientContactQaObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options?: Omit<SubscriptionOptions<SubscribeToClientContactQaObjectsSubscriptionVariables>, 'query'>;
}): Promise<Observable<SubscribeToClientContactQaObjectsSubscriptionFetchResultEx>> {
  const subscription: Observable<SubscribeToClientContactQaObjectsSubscriptionFetchResult> = apolloClient.subscribe<SubscribeToClientContactQaObjectsSubscription>(
    { query: SubscribeToClientContactQaObjectsDocument, ...options }
  );

  return subscription.map((value) => {
    return {
      context: value.context,
      errors: value.errors,
      data: value.data,
      extensions: value.extensions,
      objects: value?.data?.clientContact || [],
    } as SubscribeToClientContactQaObjectsSubscriptionFetchResultEx;
  });
}

// Insert Helper
//
type InsertClientContactQaFetchResult = FetchResult<
  InsertClientContactQaMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertClientContactQaFetchHelperResultEx = InsertClientContactQaFetchResult &
  ClientContactQaByIdHelperResultEx;

async function insertClientContactQa({
  apolloClient,
  clientContact,
  autoOptimisticResponse,
  fieldMap,
  options,
}: {
  apolloClient: ApolloClient<object>;
  clientContact: ClientContact_Insert_Input;
  autoOptimisticResponse?: boolean;
  fieldMap?: FieldMap;
  options?: Omit<
    MutationOptions<InsertClientContactQaMutation, InsertClientContactQaMutationVariables>,
    'mutation' | 'variables'
  >;
}): Promise<InsertClientContactQaFetchHelperResultEx> {
  const objectForInsert = stripInsertInputClientFields({ input: clientContact });
  const mutationOptions: MutationOptions<InsertClientContactQaMutation, InsertClientContactQaMutationVariables> = {
    mutation: InsertClientContactQaDocument,
    variables: { objects: [objectForInsert] },
    ...options,
  };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    if (!objectForInsert.id)
      throw new Error(`if autoOptimisticResponse = true, id must be set in object 'clientContact'`);
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertClientContactQaMutation>({
      operationType: 'insert',
      entityName: 'clientContact',
      objects: [objectForInsert as ClientContact_Insert_Input & ObjectWithId],
      fieldMap,
    });
    if (logLevel >= 2)
      console.log(' --> insertClientContactQa - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: InsertClientContactQaFetchResult = await apolloClient.mutate<
    InsertClientContactQaMutation,
    InsertClientContactQaMutationVariables
  >(mutationOptions);

  return {
    ...mutation,
    clientContactQa: mutation?.data?.insert_clientContact?.returning && mutation.data.insert_clientContact.returning[0],
  };
}

async function insertClientContactQaWithOnConflict({
  apolloClient,
  clientContact,
  onConflict,
  autoOptimisticResponse,
  fieldMap,
  options,
}: {
  apolloClient: ApolloClient<object>;
  clientContact: ClientContact_Insert_Input;
  onConflict: ClientContact_On_Conflict;
  autoOptimisticResponse?: boolean;
  fieldMap?: FieldMap;
  options?: Omit<
    MutationOptions<InsertClientContactQaWithOnConflictMutation, InsertClientContactQaWithOnConflictMutationVariables>,
    'mutation' | 'variables'
  >;
}): Promise<InsertClientContactQaFetchHelperResultEx> {
  const objectForInsert = stripInsertInputClientFields({ input: clientContact });
  const mutationOptions: MutationOptions<
    InsertClientContactQaWithOnConflictMutation,
    InsertClientContactQaWithOnConflictMutationVariables
  > = {
    mutation: InsertClientContactQaWithOnConflictDocument,
    variables: { objects: [objectForInsert], onConflict },
    ...options,
  };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    if (!objectForInsert.id)
      throw new Error(`if autoOptimisticResponse = true, id must be set in object 'clientContact'`);
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertClientContactQaWithOnConflictMutation>(
      {
        operationType: 'insert',
        entityName: 'clientContact',
        objects: [objectForInsert as ClientContact_Insert_Input & ObjectWithId],
        fieldMap,
      }
    );
    if (logLevel >= 2)
      console.log(' --> insertClientContactQaWithOnConflict - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: InsertClientContactQaFetchResult = await apolloClient.mutate<
    InsertClientContactQaWithOnConflictMutation,
    InsertClientContactQaWithOnConflictMutationVariables
  >(mutationOptions);

  return {
    ...mutation,
    clientContactQa: mutation?.data?.insert_clientContact?.returning && mutation.data.insert_clientContact.returning[0],
  };
}

// Insert Objects Helper
//
type InsertClientContactQaObjectsFetchResult = FetchResult<
  InsertClientContactQaMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertClientContactQaObjectsHelperResultEx = InsertClientContactQaObjectsFetchResult &
  ClientContactQaObjectsHelperResultEx;

async function insertClientContactQaObjects({
  apolloClient,
  objects,
  autoOptimisticResponse,
  fieldMap,
  options,
}: {
  apolloClient: ApolloClient<object>;
  objects: ClientContact_Insert_Input[];
  autoOptimisticResponse?: boolean;
  fieldMap?: FieldMap;
  options?: Omit<
    MutationOptions<InsertClientContactQaMutation, InsertClientContactQaMutationVariables>,
    'mutation' | 'variables'
  >;
}): Promise<InsertClientContactQaObjectsHelperResultEx> {
  const objectsForInsert = objects.map((objectItem) => stripInsertInputClientFields({ input: objectItem }));
  const mutationOptions: MutationOptions<InsertClientContactQaMutation, InsertClientContactQaMutationVariables> = {
    mutation: InsertClientContactQaDocument,
    variables: { objects: objectsForInsert },
    ...options,
  };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    if (objectsForInsert.find((objectItem) => !objectItem.id))
      throw new Error(`if autoOptimisticResponse = true, ids must be set in objects`);
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertClientContactQaMutation>({
      operationType: 'insert',
      entityName: 'clientContact',
      objects: objectsForInsert as (ClientContact_Insert_Input & ObjectWithId)[],
      fieldMap,
    });
    if (logLevel >= 2)
      console.log(' --> insertClientContactQa - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: InsertClientContactQaObjectsFetchResult = await apolloClient.mutate<
    InsertClientContactQaMutation,
    InsertClientContactQaMutationVariables
  >(mutationOptions);

  return { ...mutation, objects: mutation?.data?.insert_clientContact?.returning || [] };
}

// Update Helper
//
type UpdateClientContactQaByIdQueryResult = FetchResult<
  UpdateClientContactQaByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateClientContactQaByIdHelperResultEx = UpdateClientContactQaByIdQueryResult &
  ClientContactQaByIdHelperResultEx;

async function updateClientContactQaById({
  apolloClient,
  clientContactId,
  set,
  autoOptimisticResponse,
  options,
}: {
  apolloClient: ApolloClient<object>;
  clientContactId: string;
  set: ClientContact_Set_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationOptions<UpdateClientContactQaByIdMutation, UpdateClientContactQaByIdMutationVariables>,
    'mutation'
  >;
}): Promise<UpdateClientContactQaByIdHelperResultEx> {
  const mutationOptions: MutationOptions<
    UpdateClientContactQaByIdMutation,
    UpdateClientContactQaByIdMutationVariables
  > = { mutation: UpdateClientContactQaByIdDocument, variables: { id: clientContactId, set }, ...options };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateClientContactQaByIdMutation>({
      operationType: 'update',
      entityName: 'clientContact',
      objects: [{ id: clientContactId, ...set }],
    });
    if (logLevel >= 2)
      console.log(' --> updateClientContactQaById - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: UpdateClientContactQaByIdQueryResult = await apolloClient.mutate<
    UpdateClientContactQaByIdMutation,
    UpdateClientContactQaByIdMutationVariables
  >(mutationOptions);

  return {
    ...mutation,
    clientContactQa: mutation?.data?.update_clientContact?.returning && mutation.data.update_clientContact.returning[0],
  };
}

// Update Objects Helper
//
type UpdateClientContactQaObjectsFetchResult = FetchResult<
  UpdateClientContactQaMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateClientContactQaObjectsHelperResultEx = UpdateClientContactQaObjectsFetchResult &
  ClientContactQaObjectsHelperResultEx;

async function updateClientContactQaObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<MutationOptions<UpdateClientContactQaMutation, UpdateClientContactQaMutationVariables>, 'mutation'>;
}): Promise<UpdateClientContactQaObjectsHelperResultEx> {
  const mutation: UpdateClientContactQaObjectsFetchResult = await apolloClient.mutate<
    UpdateClientContactQaMutation,
    UpdateClientContactQaMutationVariables
  >({ mutation: UpdateClientContactQaDocument, ...options });

  return { ...mutation, objects: mutation?.data?.update_clientContact?.returning || [] };
}

// ClientContactQa Fragment Helper Object
//------------------------------------------------

export const ClientContactQaFragmentGQLHelper = {
  cacheGetDataId: cacheGetDataIdForClientContactQa,
  cacheReadFragmentById: cacheReadFragmentClientContactQaById,
  cacheWriteFragmentById: cacheWriteFragmentClientContactQaById,
  cacheReadQueryById: cacheReadQueryClientContactQaById,
  cacheWriteQueryById: cacheWriteQueryClientContactQaById,
  cacheReadQueryObjects: cacheReadQueryClientContactQaObjects,
  cacheWriteQueryObjects: cacheWriteQueryClientContactQaObjects,
  cacheWriteQueryInsert: cacheWriteQueryClientContactQaInsert,
  cacheWriteQueryRemove: cacheWriteQueryClientContactQaRemove,
  queryById: queryClientContactQaById,
  queryObjects: queryClientContactQaObjects,
  watchQueryById: watchQueryClientContactQaById,
  watchQueryObjects: watchQueryClientContactQaObjects,
  subscribeToById: subscribeToClientContactQaById,
  subscribeToObjects: subscribeToClientContactQaObjects,
  insert: insertClientContactQa,
  insertWithOnConflict: insertClientContactQaWithOnConflict,
  insertObjects: insertClientContactQaObjects,
  updateById: updateClientContactQaById,
  updateObjects: updateClientContactQaObjects,
};

// crmContactEnrichmentHistory HELPERS
//------------------------------------------------

export type CrmContactEnrichmentHistoryByIdHelperResultEx = {
  crmContactEnrichmentHistory: CrmContactEnrichmentHistoryFragment | null | undefined;
};
export type CrmContactEnrichmentHistoryObjectsHelperResultEx = { objects: CrmContactEnrichmentHistoryFragment[] };

// Direct Client & Cache Fragment Helpers
//
function cacheGetDataIdForCrmContactEnrichmentHistory(crmContactEnrichmentHistoryId: string): string {
  return defaultCacheIdFromObject({ __typename: 'crmContactEnrichmentHistory', id: crmContactEnrichmentHistoryId });
}

function cacheReadFragmentCrmContactEnrichmentHistoryById({
  apolloCache,
  crmContactEnrichmentHistoryId,
}: {
  apolloCache: ApolloCache<object>;
  crmContactEnrichmentHistoryId: string;
}): CrmContactEnrichmentHistoryFragment | null | undefined {
  return apolloCache.readFragment<CrmContactEnrichmentHistoryFragment | null | undefined>({
    fragment: CrmContactEnrichmentHistoryFragmentDoc,
    fragmentName: 'CrmContactEnrichmentHistory',
    id: defaultCacheIdFromObject({ __typename: 'crmContactEnrichmentHistory', id: crmContactEnrichmentHistoryId }),
  });
}

function cacheWriteFragmentCrmContactEnrichmentHistoryById({
  apolloCache,
  crmContactEnrichmentHistoryId,
  crmContactEnrichmentHistoryPartial,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  crmContactEnrichmentHistoryId: string;
  crmContactEnrichmentHistoryPartial:
    | Partial<CrmContactEnrichmentHistoryFragment>
    | CrmContactEnrichmentHistory_Insert_Input
    | null;
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): Partial<CrmContactEnrichmentHistoryFragment> {
  const parsedFragment = convertToGraph({
    input: crmContactEnrichmentHistoryPartial,
    typename: 'crmContactEnrichmentHistory',
    fieldMap,
  });
  if (logLevel >= 2)
    console.log(' --> cacheWriteFragmentCrmContactEnrichmentHistoryById - parsedFragment:', parsedFragment);
  apolloCache.writeFragment<Partial<CrmContactEnrichmentHistoryFragment> | null>({
    fragment: CrmContactEnrichmentHistoryFragmentDoc,
    fragmentName: 'CrmContactEnrichmentHistory',
    id: defaultCacheIdFromObject({ ...parsedFragment, id: crmContactEnrichmentHistoryId }),
    data: parsedFragment,
    broadcast: apolloBroadcast,
  });
  return parsedFragment;
}

function cacheReadQueryCrmContactEnrichmentHistoryById({
  apolloCache,
  crmContactEnrichmentHistoryId,
}: {
  apolloCache: ApolloCache<object>;
  crmContactEnrichmentHistoryId: string;
}): CrmContactEnrichmentHistoryFragment | null | undefined {
  try {
    return apolloCache.readQuery<CrmContactEnrichmentHistoryFragment | null>({
      query: QueryCrmContactEnrichmentHistoryByIdDocument,
      variables: { crmContactEnrichmentHistoryId },
    });
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheReadQueryCrmContactEnrichmentHistoryById threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheWriteQueryCrmContactEnrichmentHistoryById({
  apolloCache,
  crmContactEnrichmentHistoryId,
  crmContactEnrichmentHistory,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  crmContactEnrichmentHistoryId: string;
  crmContactEnrichmentHistory: CrmContactEnrichmentHistoryFragment | CrmContactEnrichmentHistory_Insert_Input | null;
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): void {
  try {
    const crmContactEnrichmentHistoryPartial = convertToGraph({
      input: crmContactEnrichmentHistory,
      typename: 'crmContactEnrichmentHistory',
      fieldMap,
    });
    return apolloCache.writeQuery<CrmContactEnrichmentHistoryFragment | null>({
      query: QueryCrmContactEnrichmentHistoryByIdDocument,
      variables: { crmContactEnrichmentHistoryId },
      data: crmContactEnrichmentHistory ? crmContactEnrichmentHistoryPartial : null,
      broadcast: apolloBroadcast,
    });
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheWriteQueryCrmContactEnrichmentHistoryById threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheReadQueryCrmContactEnrichmentHistoryObjects({
  apolloCache,
  variables,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryCrmContactEnrichmentHistoryObjectsQueryVariables;
}): CrmContactEnrichmentHistory[] | null | undefined {
  try {
    return (
      apolloCache.readQuery<{ CrmContactEnrichmentHistory: CrmContactEnrichmentHistory[] | null }>({
        query: QueryCrmContactEnrichmentHistoryObjectsDocument,
        variables,
      })?.CrmContactEnrichmentHistory || []
    );
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheReadQueryCrmContactEnrichmentHistoryObjects threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheWriteQueryCrmContactEnrichmentHistoryObjects({
  apolloCache,
  variables,
  data,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryCrmContactEnrichmentHistoryObjectsQueryVariables;
  data: (CrmContactEnrichmentHistory | CrmContactEnrichmentHistory_Insert_Input)[];
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): void {
  try {
    const objects = convertToGraph({ input: data, typename: 'crmContactEnrichmentHistory', fieldMap });
    return apolloCache.writeQuery<{ CrmContactEnrichmentHistory: CrmContactEnrichmentHistory[] }>({
      query: QueryCrmContactEnrichmentHistoryObjectsDocument,
      variables,
      data: { CrmContactEnrichmentHistory: objects },
      broadcast: apolloBroadcast,
    });
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheWriteQueryCrmContactEnrichmentHistoryObjects threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheWriteQueryCrmContactEnrichmentHistoryInsert({
  apolloCache,
  variables,
  crmContactEnrichmentHistory,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryCrmContactEnrichmentHistoryObjectsQueryVariables;
  crmContactEnrichmentHistory: CrmContactEnrichmentHistory_Insert_Input;
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): void {
  const currentObjects = cacheReadQueryCrmContactEnrichmentHistoryObjects({ apolloCache, variables }) || [];
  const objectsWithInserted = [
    ...currentObjects,
    convertToGraph({ input: crmContactEnrichmentHistory, typename: 'crmContactEnrichmentHistory', fieldMap }),
  ];
  if (logLevel >= 2)
    console.log(' --> cacheWriteQueryCrmContactEnrichmentHistoryInsert - objectsWithInserted:', objectsWithInserted);
  return cacheWriteQueryCrmContactEnrichmentHistoryObjects({
    apolloCache,
    variables,
    data: objectsWithInserted,
    apolloBroadcast,
  });
}

function cacheWriteQueryCrmContactEnrichmentHistoryRemove({
  apolloCache,
  variables,
  crmContactEnrichmentHistoryId,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryCrmContactEnrichmentHistoryObjectsQueryVariables;
  crmContactEnrichmentHistoryId: string;
  apolloBroadcast?: boolean;
}): void {
  const currentObjects = cacheReadQueryCrmContactEnrichmentHistoryObjects({ apolloCache, variables }) || [];
  const objectsWithRemoved =
    currentObjects.filter((objectItem) => objectItem.id !== crmContactEnrichmentHistoryId) || [];
  if (logLevel >= 2)
    console.log(' --> cacheWriteQueryCrmContactEnrichmentHistoryRemove - objectsWithRemoved:', objectsWithRemoved);
  return cacheWriteQueryCrmContactEnrichmentHistoryObjects({
    apolloCache,
    variables,
    data: objectsWithRemoved,
    apolloBroadcast,
  });
}

// Query Fetch Helper
//
export type QueryCrmContactEnrichmentHistoryByIdApolloQueryResult = ApolloQueryResult<QueryCrmContactEnrichmentHistoryByIdQuery>;
export type QueryCrmContactEnrichmentHistoryByIdApolloQueryHelperResultEx = QueryCrmContactEnrichmentHistoryByIdApolloQueryResult &
  CrmContactEnrichmentHistoryByIdHelperResultEx;

async function queryCrmContactEnrichmentHistoryById({
  apolloClient,
  crmContactEnrichmentHistoryId,
  options,
}: {
  apolloClient: ApolloClient<object>;
  crmContactEnrichmentHistoryId: string;
  options?: Omit<QueryOptions<QueryCrmContactEnrichmentHistoryByIdQueryVariables>, 'query' | 'variables'>;
}): Promise<QueryCrmContactEnrichmentHistoryByIdApolloQueryHelperResultEx> {
  const query: QueryCrmContactEnrichmentHistoryByIdApolloQueryResult = await apolloClient.query<QueryCrmContactEnrichmentHistoryByIdQuery>(
    { query: QueryCrmContactEnrichmentHistoryByIdDocument, variables: { crmContactEnrichmentHistoryId }, ...options }
  );

  return { ...query, crmContactEnrichmentHistory: query?.data?.crmContactEnrichmentHistory_by_pk };
}

// Query Watch ById Helper
//
export type WatchQueryCrmContactEnrichmentHistoryByIdApolloObservableQuery = ObservableQuery<QueryCrmContactEnrichmentHistoryByIdQuery>;
async function watchQueryCrmContactEnrichmentHistoryById({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<QueryOptions<QueryCrmContactEnrichmentHistoryByIdQueryVariables>, 'query'>;
}): Promise<WatchQueryCrmContactEnrichmentHistoryByIdApolloObservableQuery> {
  return apolloClient.watchQuery<QueryCrmContactEnrichmentHistoryByIdQuery>({
    query: QueryCrmContactEnrichmentHistoryByIdDocument,
    ...options,
  });
}

// Query Fetch Objects Helper
//
export type QueryCrmContactEnrichmentHistoryObjectsObjectsApolloQueryResult = ApolloQueryResult<QueryCrmContactEnrichmentHistoryObjectsQuery>;
export type QueryCrmContactEnrichmentHistoryObjectsObjectsApolloQueryResultEx = QueryCrmContactEnrichmentHistoryObjectsObjectsApolloQueryResult &
  CrmContactEnrichmentHistoryObjectsHelperResultEx;

async function queryCrmContactEnrichmentHistoryObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<QueryOptions<QueryCrmContactEnrichmentHistoryObjectsQueryVariables>, 'query'>;
}): Promise<QueryCrmContactEnrichmentHistoryObjectsObjectsApolloQueryResultEx> {
  const query: QueryCrmContactEnrichmentHistoryObjectsObjectsApolloQueryResult = await apolloClient.query<QueryCrmContactEnrichmentHistoryObjectsQuery>(
    { query: QueryCrmContactEnrichmentHistoryObjectsDocument, ...options }
  );

  return { ...query, objects: query?.data?.crmContactEnrichmentHistory || [] };
}

// Query Watch Objects Helper
//
export type WatchQueryCrmContactEnrichmentHistoryObjectsApolloObservableQuery = ObservableQuery<QueryCrmContactEnrichmentHistoryObjectsQuery>;
async function watchQueryCrmContactEnrichmentHistoryObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<QueryOptions<QueryCrmContactEnrichmentHistoryObjectsQueryVariables>, 'query'>;
}): Promise<WatchQueryCrmContactEnrichmentHistoryObjectsApolloObservableQuery> {
  return apolloClient.watchQuery<QueryCrmContactEnrichmentHistoryObjectsQuery>({
    query: QueryCrmContactEnrichmentHistoryObjectsDocument,
    ...options,
  });
}

// Subscription Fetch ById Helper
//
export type SubscribeToCrmContactEnrichmentHistoryByIdSubscriptionFetchResult = FetchResult<
  SubscribeToCrmContactEnrichmentHistoryByIdSubscription,
  Record<string, any>,
  Record<string, any>
>;
export type SubscribeToCrmContactEnrichmentHistoryByIdSubscriptionFetchResultEx = FetchResult<
  SubscribeToCrmContactEnrichmentHistoryByIdSubscription,
  Record<string, any>,
  Record<string, any>
> &
  CrmContactEnrichmentHistoryByIdHelperResultEx;

async function subscribeToCrmContactEnrichmentHistoryById({
  apolloClient,
  crmContactEnrichmentHistoryId,
  options,
}: {
  apolloClient: ApolloClient<object>;
  crmContactEnrichmentHistoryId: string;
  options?: Omit<
    SubscriptionOptions<SubscribeToCrmContactEnrichmentHistoryByIdSubscriptionVariables>,
    'query' | 'variables'
  >;
}): Promise<Observable<SubscribeToCrmContactEnrichmentHistoryByIdSubscriptionFetchResultEx>> {
  const subscription: Observable<SubscribeToCrmContactEnrichmentHistoryByIdSubscriptionFetchResult> = apolloClient.subscribe<SubscribeToCrmContactEnrichmentHistoryByIdSubscription>(
    {
      query: SubscribeToCrmContactEnrichmentHistoryByIdDocument,
      variables: { crmContactEnrichmentHistoryId },
      ...options,
    }
  );

  return subscription.map((value) => {
    return {
      context: value.context,
      errors: value.errors,
      data: value.data,
      extensions: value.extensions,
      crmContactEnrichmentHistory: value?.data?.crmContactEnrichmentHistory_by_pk || [],
    } as SubscribeToCrmContactEnrichmentHistoryByIdSubscriptionFetchResultEx;
  });
}

// Subscription Fetch Objects Helper
//
export type SubscribeToCrmContactEnrichmentHistoryObjectsSubscriptionFetchResult = FetchResult<
  SubscribeToCrmContactEnrichmentHistoryObjectsSubscription,
  Record<string, any>,
  Record<string, any>
>;
export type SubscribeToCrmContactEnrichmentHistoryObjectsSubscriptionFetchResultEx = FetchResult<
  SubscribeToCrmContactEnrichmentHistoryObjectsSubscription,
  Record<string, any>,
  Record<string, any>
> &
  CrmContactEnrichmentHistoryObjectsHelperResultEx;

async function subscribeToCrmContactEnrichmentHistoryObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options?: Omit<SubscriptionOptions<SubscribeToCrmContactEnrichmentHistoryObjectsSubscriptionVariables>, 'query'>;
}): Promise<Observable<SubscribeToCrmContactEnrichmentHistoryObjectsSubscriptionFetchResultEx>> {
  const subscription: Observable<SubscribeToCrmContactEnrichmentHistoryObjectsSubscriptionFetchResult> = apolloClient.subscribe<SubscribeToCrmContactEnrichmentHistoryObjectsSubscription>(
    { query: SubscribeToCrmContactEnrichmentHistoryObjectsDocument, ...options }
  );

  return subscription.map((value) => {
    return {
      context: value.context,
      errors: value.errors,
      data: value.data,
      extensions: value.extensions,
      objects: value?.data?.crmContactEnrichmentHistory || [],
    } as SubscribeToCrmContactEnrichmentHistoryObjectsSubscriptionFetchResultEx;
  });
}

// Insert Helper
//
type InsertCrmContactEnrichmentHistoryFetchResult = FetchResult<
  InsertCrmContactEnrichmentHistoryMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertCrmContactEnrichmentHistoryFetchHelperResultEx = InsertCrmContactEnrichmentHistoryFetchResult &
  CrmContactEnrichmentHistoryByIdHelperResultEx;

async function insertCrmContactEnrichmentHistory({
  apolloClient,
  crmContactEnrichmentHistory,
  autoOptimisticResponse,
  fieldMap,
  options,
}: {
  apolloClient: ApolloClient<object>;
  crmContactEnrichmentHistory: CrmContactEnrichmentHistory_Insert_Input;
  autoOptimisticResponse?: boolean;
  fieldMap?: FieldMap;
  options?: Omit<
    MutationOptions<InsertCrmContactEnrichmentHistoryMutation, InsertCrmContactEnrichmentHistoryMutationVariables>,
    'mutation' | 'variables'
  >;
}): Promise<InsertCrmContactEnrichmentHistoryFetchHelperResultEx> {
  const objectForInsert = stripInsertInputClientFields({ input: crmContactEnrichmentHistory });
  const mutationOptions: MutationOptions<
    InsertCrmContactEnrichmentHistoryMutation,
    InsertCrmContactEnrichmentHistoryMutationVariables
  > = { mutation: InsertCrmContactEnrichmentHistoryDocument, variables: { objects: [objectForInsert] }, ...options };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    if (!objectForInsert.id)
      throw new Error(`if autoOptimisticResponse = true, id must be set in object 'crmContactEnrichmentHistory'`);
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertCrmContactEnrichmentHistoryMutation>(
      {
        operationType: 'insert',
        entityName: 'crmContactEnrichmentHistory',
        objects: [objectForInsert as CrmContactEnrichmentHistory_Insert_Input & ObjectWithId],
        fieldMap,
      }
    );
    if (logLevel >= 2)
      console.log(' --> insertCrmContactEnrichmentHistory - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: InsertCrmContactEnrichmentHistoryFetchResult = await apolloClient.mutate<
    InsertCrmContactEnrichmentHistoryMutation,
    InsertCrmContactEnrichmentHistoryMutationVariables
  >(mutationOptions);

  return {
    ...mutation,
    crmContactEnrichmentHistory:
      mutation?.data?.insert_crmContactEnrichmentHistory?.returning &&
      mutation.data.insert_crmContactEnrichmentHistory.returning[0],
  };
}

async function insertCrmContactEnrichmentHistoryWithOnConflict({
  apolloClient,
  crmContactEnrichmentHistory,
  onConflict,
  autoOptimisticResponse,
  fieldMap,
  options,
}: {
  apolloClient: ApolloClient<object>;
  crmContactEnrichmentHistory: CrmContactEnrichmentHistory_Insert_Input;
  onConflict: CrmContactEnrichmentHistory_On_Conflict;
  autoOptimisticResponse?: boolean;
  fieldMap?: FieldMap;
  options?: Omit<
    MutationOptions<
      InsertCrmContactEnrichmentHistoryWithOnConflictMutation,
      InsertCrmContactEnrichmentHistoryWithOnConflictMutationVariables
    >,
    'mutation' | 'variables'
  >;
}): Promise<InsertCrmContactEnrichmentHistoryFetchHelperResultEx> {
  const objectForInsert = stripInsertInputClientFields({ input: crmContactEnrichmentHistory });
  const mutationOptions: MutationOptions<
    InsertCrmContactEnrichmentHistoryWithOnConflictMutation,
    InsertCrmContactEnrichmentHistoryWithOnConflictMutationVariables
  > = {
    mutation: InsertCrmContactEnrichmentHistoryWithOnConflictDocument,
    variables: { objects: [objectForInsert], onConflict },
    ...options,
  };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    if (!objectForInsert.id)
      throw new Error(`if autoOptimisticResponse = true, id must be set in object 'crmContactEnrichmentHistory'`);
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertCrmContactEnrichmentHistoryWithOnConflictMutation>(
      {
        operationType: 'insert',
        entityName: 'crmContactEnrichmentHistory',
        objects: [objectForInsert as CrmContactEnrichmentHistory_Insert_Input & ObjectWithId],
        fieldMap,
      }
    );
    if (logLevel >= 2)
      console.log(
        ' --> insertCrmContactEnrichmentHistoryWithOnConflict - optimisticResponse:',
        mutationOptions.optimisticResponse
      );
  }

  const mutation: InsertCrmContactEnrichmentHistoryFetchResult = await apolloClient.mutate<
    InsertCrmContactEnrichmentHistoryWithOnConflictMutation,
    InsertCrmContactEnrichmentHistoryWithOnConflictMutationVariables
  >(mutationOptions);

  return {
    ...mutation,
    crmContactEnrichmentHistory:
      mutation?.data?.insert_crmContactEnrichmentHistory?.returning &&
      mutation.data.insert_crmContactEnrichmentHistory.returning[0],
  };
}

// Insert Objects Helper
//
type InsertCrmContactEnrichmentHistoryObjectsFetchResult = FetchResult<
  InsertCrmContactEnrichmentHistoryMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertCrmContactEnrichmentHistoryObjectsHelperResultEx = InsertCrmContactEnrichmentHistoryObjectsFetchResult &
  CrmContactEnrichmentHistoryObjectsHelperResultEx;

async function insertCrmContactEnrichmentHistoryObjects({
  apolloClient,
  objects,
  autoOptimisticResponse,
  fieldMap,
  options,
}: {
  apolloClient: ApolloClient<object>;
  objects: CrmContactEnrichmentHistory_Insert_Input[];
  autoOptimisticResponse?: boolean;
  fieldMap?: FieldMap;
  options?: Omit<
    MutationOptions<InsertCrmContactEnrichmentHistoryMutation, InsertCrmContactEnrichmentHistoryMutationVariables>,
    'mutation' | 'variables'
  >;
}): Promise<InsertCrmContactEnrichmentHistoryObjectsHelperResultEx> {
  const objectsForInsert = objects.map((objectItem) => stripInsertInputClientFields({ input: objectItem }));
  const mutationOptions: MutationOptions<
    InsertCrmContactEnrichmentHistoryMutation,
    InsertCrmContactEnrichmentHistoryMutationVariables
  > = { mutation: InsertCrmContactEnrichmentHistoryDocument, variables: { objects: objectsForInsert }, ...options };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    if (objectsForInsert.find((objectItem) => !objectItem.id))
      throw new Error(`if autoOptimisticResponse = true, ids must be set in objects`);
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertCrmContactEnrichmentHistoryMutation>(
      {
        operationType: 'insert',
        entityName: 'crmContactEnrichmentHistory',
        objects: objectsForInsert as (CrmContactEnrichmentHistory_Insert_Input & ObjectWithId)[],
        fieldMap,
      }
    );
    if (logLevel >= 2)
      console.log(' --> insertCrmContactEnrichmentHistory - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: InsertCrmContactEnrichmentHistoryObjectsFetchResult = await apolloClient.mutate<
    InsertCrmContactEnrichmentHistoryMutation,
    InsertCrmContactEnrichmentHistoryMutationVariables
  >(mutationOptions);

  return { ...mutation, objects: mutation?.data?.insert_crmContactEnrichmentHistory?.returning || [] };
}

// Update Helper
//
type UpdateCrmContactEnrichmentHistoryByIdQueryResult = FetchResult<
  UpdateCrmContactEnrichmentHistoryByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateCrmContactEnrichmentHistoryByIdHelperResultEx = UpdateCrmContactEnrichmentHistoryByIdQueryResult &
  CrmContactEnrichmentHistoryByIdHelperResultEx;

async function updateCrmContactEnrichmentHistoryById({
  apolloClient,
  crmContactEnrichmentHistoryId,
  set,
  autoOptimisticResponse,
  options,
}: {
  apolloClient: ApolloClient<object>;
  crmContactEnrichmentHistoryId: string;
  set: CrmContactEnrichmentHistory_Set_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationOptions<
      UpdateCrmContactEnrichmentHistoryByIdMutation,
      UpdateCrmContactEnrichmentHistoryByIdMutationVariables
    >,
    'mutation'
  >;
}): Promise<UpdateCrmContactEnrichmentHistoryByIdHelperResultEx> {
  const mutationOptions: MutationOptions<
    UpdateCrmContactEnrichmentHistoryByIdMutation,
    UpdateCrmContactEnrichmentHistoryByIdMutationVariables
  > = {
    mutation: UpdateCrmContactEnrichmentHistoryByIdDocument,
    variables: { id: crmContactEnrichmentHistoryId, set },
    ...options,
  };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateCrmContactEnrichmentHistoryByIdMutation>(
      {
        operationType: 'update',
        entityName: 'crmContactEnrichmentHistory',
        objects: [{ id: crmContactEnrichmentHistoryId, ...set }],
      }
    );
    if (logLevel >= 2)
      console.log(
        ' --> updateCrmContactEnrichmentHistoryById - optimisticResponse:',
        mutationOptions.optimisticResponse
      );
  }

  const mutation: UpdateCrmContactEnrichmentHistoryByIdQueryResult = await apolloClient.mutate<
    UpdateCrmContactEnrichmentHistoryByIdMutation,
    UpdateCrmContactEnrichmentHistoryByIdMutationVariables
  >(mutationOptions);

  return {
    ...mutation,
    crmContactEnrichmentHistory:
      mutation?.data?.update_crmContactEnrichmentHistory?.returning &&
      mutation.data.update_crmContactEnrichmentHistory.returning[0],
  };
}

// Update Objects Helper
//
type UpdateCrmContactEnrichmentHistoryObjectsFetchResult = FetchResult<
  UpdateCrmContactEnrichmentHistoryMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateCrmContactEnrichmentHistoryObjectsHelperResultEx = UpdateCrmContactEnrichmentHistoryObjectsFetchResult &
  CrmContactEnrichmentHistoryObjectsHelperResultEx;

async function updateCrmContactEnrichmentHistoryObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<
    MutationOptions<UpdateCrmContactEnrichmentHistoryMutation, UpdateCrmContactEnrichmentHistoryMutationVariables>,
    'mutation'
  >;
}): Promise<UpdateCrmContactEnrichmentHistoryObjectsHelperResultEx> {
  const mutation: UpdateCrmContactEnrichmentHistoryObjectsFetchResult = await apolloClient.mutate<
    UpdateCrmContactEnrichmentHistoryMutation,
    UpdateCrmContactEnrichmentHistoryMutationVariables
  >({ mutation: UpdateCrmContactEnrichmentHistoryDocument, ...options });

  return { ...mutation, objects: mutation?.data?.update_crmContactEnrichmentHistory?.returning || [] };
}

// Delete Helper
//

type RemoveCrmContactEnrichmentHistoryModelByIdQueryResult = FetchResult<
  RemoveCrmContactEnrichmentHistoryModelByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveCrmContactEnrichmentHistoryModelByIdQueryHelperResultEx = RemoveCrmContactEnrichmentHistoryModelByIdQueryResult &
  RemoveEntitiesQueryHelperResultEx;

async function removeCrmContactEnrichmentHistoryModelById({
  apolloClient,
  crmContactEnrichmentHistoryId,
  autoOptimisticResponse,
  options,
}: {
  apolloClient: ApolloClient<object>;
  crmContactEnrichmentHistoryId: string;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationOptions<
      RemoveCrmContactEnrichmentHistoryModelByIdMutation,
      RemoveCrmContactEnrichmentHistoryModelByIdMutationVariables
    >,
    'mutation'
  >;
}): Promise<RemoveCrmContactEnrichmentHistoryModelByIdQueryHelperResultEx> {
  const mutationOptions: MutationOptions<
    RemoveCrmContactEnrichmentHistoryModelByIdMutation,
    RemoveCrmContactEnrichmentHistoryModelByIdMutationVariables
  > = {
    mutation: RemoveCrmContactEnrichmentHistoryModelByIdDocument,
    variables: { id: crmContactEnrichmentHistoryId },
    ...options,
  };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<RemoveCrmContactEnrichmentHistoryModelByIdMutation>(
      {
        operationType: 'delete',
        entityName: 'crmContactEnrichmentHistory',
        objects: [{ id: crmContactEnrichmentHistoryId }],
      }
    );
    if (logLevel >= 2)
      console.log(
        ' --> removeCrmContactEnrichmentHistoryModelById - optimisticResponse:',
        mutationOptions.optimisticResponse
      );
  }
  if (!options || !options.update) {
    mutationOptions.update = generateUpdateFunctionForMutation<RemoveCrmContactEnrichmentHistoryModelByIdMutation>({
      operationType: 'delete',
      entityName: 'crmContactEnrichmentHistory',
      entityId: crmContactEnrichmentHistoryId,
    });
  }

  const mutation: RemoveCrmContactEnrichmentHistoryModelByIdQueryResult = await apolloClient.mutate<
    RemoveCrmContactEnrichmentHistoryModelByIdMutation,
    RemoveCrmContactEnrichmentHistoryModelByIdMutationVariables
  >(mutationOptions);

  return { ...mutation, affected_rows: mutation?.data?.delete_crmContactEnrichmentHistory?.affected_rows || 0 };
}

type RemoveCrmContactEnrichmentHistoryModelObjectsQueryResult = FetchResult<
  RemoveCrmContactEnrichmentHistoryModelMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveCrmContactEnrichmentHistoryModelObjectsQueryHelperResultEx = RemoveCrmContactEnrichmentHistoryModelObjectsQueryResult &
  RemoveEntitiesQueryHelperResultEx;

async function removeCrmContactEnrichmentHistoryModelObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<
    MutationOptions<
      RemoveCrmContactEnrichmentHistoryModelMutation,
      RemoveCrmContactEnrichmentHistoryModelMutationVariables
    >,
    'mutation'
  >;
}): Promise<RemoveCrmContactEnrichmentHistoryModelObjectsQueryHelperResultEx> {
  const mutation: RemoveCrmContactEnrichmentHistoryModelObjectsQueryResult = await apolloClient.mutate<
    RemoveCrmContactEnrichmentHistoryModelMutation,
    RemoveCrmContactEnrichmentHistoryModelMutationVariables
  >({ mutation: RemoveCrmContactEnrichmentHistoryModelDocument, ...options });

  return { ...mutation, affected_rows: mutation?.data?.delete_crmContactEnrichmentHistory?.affected_rows || 0 };
}

// CrmContactEnrichmentHistory Fragment Helper Object
//------------------------------------------------

export const CrmContactEnrichmentHistoryFragmentGQLHelper = {
  cacheGetDataId: cacheGetDataIdForCrmContactEnrichmentHistory,
  cacheReadFragmentById: cacheReadFragmentCrmContactEnrichmentHistoryById,
  cacheWriteFragmentById: cacheWriteFragmentCrmContactEnrichmentHistoryById,
  cacheReadQueryById: cacheReadQueryCrmContactEnrichmentHistoryById,
  cacheWriteQueryById: cacheWriteQueryCrmContactEnrichmentHistoryById,
  cacheReadQueryObjects: cacheReadQueryCrmContactEnrichmentHistoryObjects,
  cacheWriteQueryObjects: cacheWriteQueryCrmContactEnrichmentHistoryObjects,
  cacheWriteQueryInsert: cacheWriteQueryCrmContactEnrichmentHistoryInsert,
  cacheWriteQueryRemove: cacheWriteQueryCrmContactEnrichmentHistoryRemove,
  queryById: queryCrmContactEnrichmentHistoryById,
  queryObjects: queryCrmContactEnrichmentHistoryObjects,
  watchQueryById: watchQueryCrmContactEnrichmentHistoryById,
  watchQueryObjects: watchQueryCrmContactEnrichmentHistoryObjects,
  subscribeToById: subscribeToCrmContactEnrichmentHistoryById,
  subscribeToObjects: subscribeToCrmContactEnrichmentHistoryObjects,
  insert: insertCrmContactEnrichmentHistory,
  insertWithOnConflict: insertCrmContactEnrichmentHistoryWithOnConflict,
  insertObjects: insertCrmContactEnrichmentHistoryObjects,
  updateById: updateCrmContactEnrichmentHistoryById,
  updateObjects: updateCrmContactEnrichmentHistoryObjects,
};

// crmContactEnrichmentHistory Entity Helper Object
//------------------------------------------------

export const CrmContactEnrichmentHistoryModelGQLHelper = {
  removeById: removeCrmContactEnrichmentHistoryModelById,
  removeObjects: removeCrmContactEnrichmentHistoryModelObjects,
};

// clientContactCrmData HELPERS
//------------------------------------------------

export type ClientContactCrmDataByIdHelperResultEx = {
  clientContactCrmData: ClientContactCrmDataFragment | null | undefined;
};
export type ClientContactCrmDataObjectsHelperResultEx = { objects: ClientContactCrmDataFragment[] };

// Direct Client & Cache Fragment Helpers
//
function cacheGetDataIdForClientContactCrmData(clientContactCrmDataId: string): string {
  return defaultCacheIdFromObject({ __typename: 'clientContactCrmData', id: clientContactCrmDataId });
}

function cacheReadFragmentClientContactCrmDataById({
  apolloCache,
  clientContactCrmDataId,
}: {
  apolloCache: ApolloCache<object>;
  clientContactCrmDataId: string;
}): ClientContactCrmDataFragment | null | undefined {
  return apolloCache.readFragment<ClientContactCrmDataFragment | null | undefined>({
    fragment: ClientContactCrmDataFragmentDoc,
    fragmentName: 'ClientContactCrmData',
    id: defaultCacheIdFromObject({ __typename: 'clientContactCrmData', id: clientContactCrmDataId }),
  });
}

function cacheWriteFragmentClientContactCrmDataById({
  apolloCache,
  clientContactCrmDataId,
  clientContactCrmDataPartial,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  clientContactCrmDataId: string;
  clientContactCrmDataPartial: Partial<ClientContactCrmDataFragment> | ClientContactCrmData_Insert_Input | null;
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): Partial<ClientContactCrmDataFragment> {
  const parsedFragment = convertToGraph({
    input: clientContactCrmDataPartial,
    typename: 'clientContactCrmData',
    fieldMap,
  });
  if (logLevel >= 2) console.log(' --> cacheWriteFragmentClientContactCrmDataById - parsedFragment:', parsedFragment);
  apolloCache.writeFragment<Partial<ClientContactCrmDataFragment> | null>({
    fragment: ClientContactCrmDataFragmentDoc,
    fragmentName: 'ClientContactCrmData',
    id: defaultCacheIdFromObject({ ...parsedFragment, id: clientContactCrmDataId }),
    data: parsedFragment,
    broadcast: apolloBroadcast,
  });
  return parsedFragment;
}

function cacheReadQueryClientContactCrmDataById({
  apolloCache,
  clientContactCrmDataId,
}: {
  apolloCache: ApolloCache<object>;
  clientContactCrmDataId: string;
}): ClientContactCrmDataFragment | null | undefined {
  try {
    return apolloCache.readQuery<ClientContactCrmDataFragment | null>({
      query: QueryClientContactCrmDataByIdDocument,
      variables: { clientContactCrmDataId },
    });
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheReadQueryClientContactCrmDataById threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheWriteQueryClientContactCrmDataById({
  apolloCache,
  clientContactCrmDataId,
  clientContactCrmData,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  clientContactCrmDataId: string;
  clientContactCrmData: ClientContactCrmDataFragment | ClientContactCrmData_Insert_Input | null;
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): void {
  try {
    const clientContactCrmDataPartial = convertToGraph({
      input: clientContactCrmData,
      typename: 'clientContactCrmData',
      fieldMap,
    });
    return apolloCache.writeQuery<ClientContactCrmDataFragment | null>({
      query: QueryClientContactCrmDataByIdDocument,
      variables: { clientContactCrmDataId },
      data: clientContactCrmData ? clientContactCrmDataPartial : null,
      broadcast: apolloBroadcast,
    });
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheWriteQueryClientContactCrmDataById threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheReadQueryClientContactCrmDataObjects({
  apolloCache,
  variables,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryClientContactCrmDataObjectsQueryVariables;
}): ClientContactCrmData[] | null | undefined {
  try {
    return (
      apolloCache.readQuery<{ ClientContactCrmData: ClientContactCrmData[] | null }>({
        query: QueryClientContactCrmDataObjectsDocument,
        variables,
      })?.ClientContactCrmData || []
    );
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheReadQueryClientContactCrmDataObjects threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheWriteQueryClientContactCrmDataObjects({
  apolloCache,
  variables,
  data,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryClientContactCrmDataObjectsQueryVariables;
  data: (ClientContactCrmData | ClientContactCrmData_Insert_Input)[];
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): void {
  try {
    const objects = convertToGraph({ input: data, typename: 'clientContactCrmData', fieldMap });
    return apolloCache.writeQuery<{ ClientContactCrmData: ClientContactCrmData[] }>({
      query: QueryClientContactCrmDataObjectsDocument,
      variables,
      data: { ClientContactCrmData: objects },
      broadcast: apolloBroadcast,
    });
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheWriteQueryClientContactCrmDataObjects threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheWriteQueryClientContactCrmDataInsert({
  apolloCache,
  variables,
  clientContactCrmData,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryClientContactCrmDataObjectsQueryVariables;
  clientContactCrmData: ClientContactCrmData_Insert_Input;
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): void {
  const currentObjects = cacheReadQueryClientContactCrmDataObjects({ apolloCache, variables }) || [];
  const objectsWithInserted = [
    ...currentObjects,
    convertToGraph({ input: clientContactCrmData, typename: 'clientContactCrmData', fieldMap }),
  ];
  if (logLevel >= 2)
    console.log(' --> cacheWriteQueryClientContactCrmDataInsert - objectsWithInserted:', objectsWithInserted);
  return cacheWriteQueryClientContactCrmDataObjects({
    apolloCache,
    variables,
    data: objectsWithInserted,
    apolloBroadcast,
  });
}

function cacheWriteQueryClientContactCrmDataRemove({
  apolloCache,
  variables,
  clientContactCrmDataId,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryClientContactCrmDataObjectsQueryVariables;
  clientContactCrmDataId: string;
  apolloBroadcast?: boolean;
}): void {
  const currentObjects = cacheReadQueryClientContactCrmDataObjects({ apolloCache, variables }) || [];
  const objectsWithRemoved = currentObjects.filter((objectItem) => objectItem.id !== clientContactCrmDataId) || [];
  if (logLevel >= 2)
    console.log(' --> cacheWriteQueryClientContactCrmDataRemove - objectsWithRemoved:', objectsWithRemoved);
  return cacheWriteQueryClientContactCrmDataObjects({
    apolloCache,
    variables,
    data: objectsWithRemoved,
    apolloBroadcast,
  });
}

// Query Fetch Helper
//
export type QueryClientContactCrmDataByIdApolloQueryResult = ApolloQueryResult<QueryClientContactCrmDataByIdQuery>;
export type QueryClientContactCrmDataByIdApolloQueryHelperResultEx = QueryClientContactCrmDataByIdApolloQueryResult &
  ClientContactCrmDataByIdHelperResultEx;

async function queryClientContactCrmDataById({
  apolloClient,
  clientContactCrmDataId,
  options,
}: {
  apolloClient: ApolloClient<object>;
  clientContactCrmDataId: string;
  options?: Omit<QueryOptions<QueryClientContactCrmDataByIdQueryVariables>, 'query' | 'variables'>;
}): Promise<QueryClientContactCrmDataByIdApolloQueryHelperResultEx> {
  const query: QueryClientContactCrmDataByIdApolloQueryResult = await apolloClient.query<QueryClientContactCrmDataByIdQuery>(
    { query: QueryClientContactCrmDataByIdDocument, variables: { clientContactCrmDataId }, ...options }
  );

  return { ...query, clientContactCrmData: query?.data?.clientContactCrmData_by_pk };
}

// Query Watch ById Helper
//
export type WatchQueryClientContactCrmDataByIdApolloObservableQuery = ObservableQuery<QueryClientContactCrmDataByIdQuery>;
async function watchQueryClientContactCrmDataById({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<QueryOptions<QueryClientContactCrmDataByIdQueryVariables>, 'query'>;
}): Promise<WatchQueryClientContactCrmDataByIdApolloObservableQuery> {
  return apolloClient.watchQuery<QueryClientContactCrmDataByIdQuery>({
    query: QueryClientContactCrmDataByIdDocument,
    ...options,
  });
}

// Query Fetch Objects Helper
//
export type QueryClientContactCrmDataObjectsObjectsApolloQueryResult = ApolloQueryResult<QueryClientContactCrmDataObjectsQuery>;
export type QueryClientContactCrmDataObjectsObjectsApolloQueryResultEx = QueryClientContactCrmDataObjectsObjectsApolloQueryResult &
  ClientContactCrmDataObjectsHelperResultEx;

async function queryClientContactCrmDataObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<QueryOptions<QueryClientContactCrmDataObjectsQueryVariables>, 'query'>;
}): Promise<QueryClientContactCrmDataObjectsObjectsApolloQueryResultEx> {
  const query: QueryClientContactCrmDataObjectsObjectsApolloQueryResult = await apolloClient.query<QueryClientContactCrmDataObjectsQuery>(
    { query: QueryClientContactCrmDataObjectsDocument, ...options }
  );

  return { ...query, objects: query?.data?.clientContactCrmData || [] };
}

// Query Watch Objects Helper
//
export type WatchQueryClientContactCrmDataObjectsApolloObservableQuery = ObservableQuery<QueryClientContactCrmDataObjectsQuery>;
async function watchQueryClientContactCrmDataObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<QueryOptions<QueryClientContactCrmDataObjectsQueryVariables>, 'query'>;
}): Promise<WatchQueryClientContactCrmDataObjectsApolloObservableQuery> {
  return apolloClient.watchQuery<QueryClientContactCrmDataObjectsQuery>({
    query: QueryClientContactCrmDataObjectsDocument,
    ...options,
  });
}

// Subscription Fetch ById Helper
//
export type SubscribeToClientContactCrmDataByIdSubscriptionFetchResult = FetchResult<
  SubscribeToClientContactCrmDataByIdSubscription,
  Record<string, any>,
  Record<string, any>
>;
export type SubscribeToClientContactCrmDataByIdSubscriptionFetchResultEx = FetchResult<
  SubscribeToClientContactCrmDataByIdSubscription,
  Record<string, any>,
  Record<string, any>
> &
  ClientContactCrmDataByIdHelperResultEx;

async function subscribeToClientContactCrmDataById({
  apolloClient,
  clientContactCrmDataId,
  options,
}: {
  apolloClient: ApolloClient<object>;
  clientContactCrmDataId: string;
  options?: Omit<SubscriptionOptions<SubscribeToClientContactCrmDataByIdSubscriptionVariables>, 'query' | 'variables'>;
}): Promise<Observable<SubscribeToClientContactCrmDataByIdSubscriptionFetchResultEx>> {
  const subscription: Observable<SubscribeToClientContactCrmDataByIdSubscriptionFetchResult> = apolloClient.subscribe<SubscribeToClientContactCrmDataByIdSubscription>(
    { query: SubscribeToClientContactCrmDataByIdDocument, variables: { clientContactCrmDataId }, ...options }
  );

  return subscription.map((value) => {
    return {
      context: value.context,
      errors: value.errors,
      data: value.data,
      extensions: value.extensions,
      clientContactCrmData: value?.data?.clientContactCrmData_by_pk || [],
    } as SubscribeToClientContactCrmDataByIdSubscriptionFetchResultEx;
  });
}

// Subscription Fetch Objects Helper
//
export type SubscribeToClientContactCrmDataObjectsSubscriptionFetchResult = FetchResult<
  SubscribeToClientContactCrmDataObjectsSubscription,
  Record<string, any>,
  Record<string, any>
>;
export type SubscribeToClientContactCrmDataObjectsSubscriptionFetchResultEx = FetchResult<
  SubscribeToClientContactCrmDataObjectsSubscription,
  Record<string, any>,
  Record<string, any>
> &
  ClientContactCrmDataObjectsHelperResultEx;

async function subscribeToClientContactCrmDataObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options?: Omit<SubscriptionOptions<SubscribeToClientContactCrmDataObjectsSubscriptionVariables>, 'query'>;
}): Promise<Observable<SubscribeToClientContactCrmDataObjectsSubscriptionFetchResultEx>> {
  const subscription: Observable<SubscribeToClientContactCrmDataObjectsSubscriptionFetchResult> = apolloClient.subscribe<SubscribeToClientContactCrmDataObjectsSubscription>(
    { query: SubscribeToClientContactCrmDataObjectsDocument, ...options }
  );

  return subscription.map((value) => {
    return {
      context: value.context,
      errors: value.errors,
      data: value.data,
      extensions: value.extensions,
      objects: value?.data?.clientContactCrmData || [],
    } as SubscribeToClientContactCrmDataObjectsSubscriptionFetchResultEx;
  });
}

// Insert Helper
//
type InsertClientContactCrmDataFetchResult = FetchResult<
  InsertClientContactCrmDataMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertClientContactCrmDataFetchHelperResultEx = InsertClientContactCrmDataFetchResult &
  ClientContactCrmDataByIdHelperResultEx;

async function insertClientContactCrmData({
  apolloClient,
  clientContactCrmData,
  autoOptimisticResponse,
  fieldMap,
  options,
}: {
  apolloClient: ApolloClient<object>;
  clientContactCrmData: ClientContactCrmData_Insert_Input;
  autoOptimisticResponse?: boolean;
  fieldMap?: FieldMap;
  options?: Omit<
    MutationOptions<InsertClientContactCrmDataMutation, InsertClientContactCrmDataMutationVariables>,
    'mutation' | 'variables'
  >;
}): Promise<InsertClientContactCrmDataFetchHelperResultEx> {
  const objectForInsert = stripInsertInputClientFields({ input: clientContactCrmData });
  const mutationOptions: MutationOptions<
    InsertClientContactCrmDataMutation,
    InsertClientContactCrmDataMutationVariables
  > = { mutation: InsertClientContactCrmDataDocument, variables: { objects: [objectForInsert] }, ...options };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    if (!objectForInsert.id)
      throw new Error(`if autoOptimisticResponse = true, id must be set in object 'clientContactCrmData'`);
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertClientContactCrmDataMutation>({
      operationType: 'insert',
      entityName: 'clientContactCrmData',
      objects: [objectForInsert as ClientContactCrmData_Insert_Input & ObjectWithId],
      fieldMap,
    });
    if (logLevel >= 2)
      console.log(' --> insertClientContactCrmData - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: InsertClientContactCrmDataFetchResult = await apolloClient.mutate<
    InsertClientContactCrmDataMutation,
    InsertClientContactCrmDataMutationVariables
  >(mutationOptions);

  return {
    ...mutation,
    clientContactCrmData:
      mutation?.data?.insert_clientContactCrmData?.returning && mutation.data.insert_clientContactCrmData.returning[0],
  };
}

async function insertClientContactCrmDataWithOnConflict({
  apolloClient,
  clientContactCrmData,
  onConflict,
  autoOptimisticResponse,
  fieldMap,
  options,
}: {
  apolloClient: ApolloClient<object>;
  clientContactCrmData: ClientContactCrmData_Insert_Input;
  onConflict: ClientContactCrmData_On_Conflict;
  autoOptimisticResponse?: boolean;
  fieldMap?: FieldMap;
  options?: Omit<
    MutationOptions<
      InsertClientContactCrmDataWithOnConflictMutation,
      InsertClientContactCrmDataWithOnConflictMutationVariables
    >,
    'mutation' | 'variables'
  >;
}): Promise<InsertClientContactCrmDataFetchHelperResultEx> {
  const objectForInsert = stripInsertInputClientFields({ input: clientContactCrmData });
  const mutationOptions: MutationOptions<
    InsertClientContactCrmDataWithOnConflictMutation,
    InsertClientContactCrmDataWithOnConflictMutationVariables
  > = {
    mutation: InsertClientContactCrmDataWithOnConflictDocument,
    variables: { objects: [objectForInsert], onConflict },
    ...options,
  };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    if (!objectForInsert.id)
      throw new Error(`if autoOptimisticResponse = true, id must be set in object 'clientContactCrmData'`);
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertClientContactCrmDataWithOnConflictMutation>(
      {
        operationType: 'insert',
        entityName: 'clientContactCrmData',
        objects: [objectForInsert as ClientContactCrmData_Insert_Input & ObjectWithId],
        fieldMap,
      }
    );
    if (logLevel >= 2)
      console.log(
        ' --> insertClientContactCrmDataWithOnConflict - optimisticResponse:',
        mutationOptions.optimisticResponse
      );
  }

  const mutation: InsertClientContactCrmDataFetchResult = await apolloClient.mutate<
    InsertClientContactCrmDataWithOnConflictMutation,
    InsertClientContactCrmDataWithOnConflictMutationVariables
  >(mutationOptions);

  return {
    ...mutation,
    clientContactCrmData:
      mutation?.data?.insert_clientContactCrmData?.returning && mutation.data.insert_clientContactCrmData.returning[0],
  };
}

// Insert Objects Helper
//
type InsertClientContactCrmDataObjectsFetchResult = FetchResult<
  InsertClientContactCrmDataMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertClientContactCrmDataObjectsHelperResultEx = InsertClientContactCrmDataObjectsFetchResult &
  ClientContactCrmDataObjectsHelperResultEx;

async function insertClientContactCrmDataObjects({
  apolloClient,
  objects,
  autoOptimisticResponse,
  fieldMap,
  options,
}: {
  apolloClient: ApolloClient<object>;
  objects: ClientContactCrmData_Insert_Input[];
  autoOptimisticResponse?: boolean;
  fieldMap?: FieldMap;
  options?: Omit<
    MutationOptions<InsertClientContactCrmDataMutation, InsertClientContactCrmDataMutationVariables>,
    'mutation' | 'variables'
  >;
}): Promise<InsertClientContactCrmDataObjectsHelperResultEx> {
  const objectsForInsert = objects.map((objectItem) => stripInsertInputClientFields({ input: objectItem }));
  const mutationOptions: MutationOptions<
    InsertClientContactCrmDataMutation,
    InsertClientContactCrmDataMutationVariables
  > = { mutation: InsertClientContactCrmDataDocument, variables: { objects: objectsForInsert }, ...options };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    if (objectsForInsert.find((objectItem) => !objectItem.id))
      throw new Error(`if autoOptimisticResponse = true, ids must be set in objects`);
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertClientContactCrmDataMutation>({
      operationType: 'insert',
      entityName: 'clientContactCrmData',
      objects: objectsForInsert as (ClientContactCrmData_Insert_Input & ObjectWithId)[],
      fieldMap,
    });
    if (logLevel >= 2)
      console.log(' --> insertClientContactCrmData - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: InsertClientContactCrmDataObjectsFetchResult = await apolloClient.mutate<
    InsertClientContactCrmDataMutation,
    InsertClientContactCrmDataMutationVariables
  >(mutationOptions);

  return { ...mutation, objects: mutation?.data?.insert_clientContactCrmData?.returning || [] };
}

// Update Helper
//
type UpdateClientContactCrmDataByIdQueryResult = FetchResult<
  UpdateClientContactCrmDataByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateClientContactCrmDataByIdHelperResultEx = UpdateClientContactCrmDataByIdQueryResult &
  ClientContactCrmDataByIdHelperResultEx;

async function updateClientContactCrmDataById({
  apolloClient,
  clientContactCrmDataId,
  set,
  autoOptimisticResponse,
  options,
}: {
  apolloClient: ApolloClient<object>;
  clientContactCrmDataId: string;
  set: ClientContactCrmData_Set_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationOptions<UpdateClientContactCrmDataByIdMutation, UpdateClientContactCrmDataByIdMutationVariables>,
    'mutation'
  >;
}): Promise<UpdateClientContactCrmDataByIdHelperResultEx> {
  const mutationOptions: MutationOptions<
    UpdateClientContactCrmDataByIdMutation,
    UpdateClientContactCrmDataByIdMutationVariables
  > = { mutation: UpdateClientContactCrmDataByIdDocument, variables: { id: clientContactCrmDataId, set }, ...options };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateClientContactCrmDataByIdMutation>({
      operationType: 'update',
      entityName: 'clientContactCrmData',
      objects: [{ id: clientContactCrmDataId, ...set }],
    });
    if (logLevel >= 2)
      console.log(' --> updateClientContactCrmDataById - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: UpdateClientContactCrmDataByIdQueryResult = await apolloClient.mutate<
    UpdateClientContactCrmDataByIdMutation,
    UpdateClientContactCrmDataByIdMutationVariables
  >(mutationOptions);

  return {
    ...mutation,
    clientContactCrmData:
      mutation?.data?.update_clientContactCrmData?.returning && mutation.data.update_clientContactCrmData.returning[0],
  };
}

// Update Objects Helper
//
type UpdateClientContactCrmDataObjectsFetchResult = FetchResult<
  UpdateClientContactCrmDataMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateClientContactCrmDataObjectsHelperResultEx = UpdateClientContactCrmDataObjectsFetchResult &
  ClientContactCrmDataObjectsHelperResultEx;

async function updateClientContactCrmDataObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<
    MutationOptions<UpdateClientContactCrmDataMutation, UpdateClientContactCrmDataMutationVariables>,
    'mutation'
  >;
}): Promise<UpdateClientContactCrmDataObjectsHelperResultEx> {
  const mutation: UpdateClientContactCrmDataObjectsFetchResult = await apolloClient.mutate<
    UpdateClientContactCrmDataMutation,
    UpdateClientContactCrmDataMutationVariables
  >({ mutation: UpdateClientContactCrmDataDocument, ...options });

  return { ...mutation, objects: mutation?.data?.update_clientContactCrmData?.returning || [] };
}

// Delete Helper
//

type RemoveClientContactCrmDataModelByIdQueryResult = FetchResult<
  RemoveClientContactCrmDataModelByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveClientContactCrmDataModelByIdQueryHelperResultEx = RemoveClientContactCrmDataModelByIdQueryResult &
  RemoveEntitiesQueryHelperResultEx;

async function removeClientContactCrmDataModelById({
  apolloClient,
  clientContactCrmDataId,
  autoOptimisticResponse,
  options,
}: {
  apolloClient: ApolloClient<object>;
  clientContactCrmDataId: string;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationOptions<RemoveClientContactCrmDataModelByIdMutation, RemoveClientContactCrmDataModelByIdMutationVariables>,
    'mutation'
  >;
}): Promise<RemoveClientContactCrmDataModelByIdQueryHelperResultEx> {
  const mutationOptions: MutationOptions<
    RemoveClientContactCrmDataModelByIdMutation,
    RemoveClientContactCrmDataModelByIdMutationVariables
  > = { mutation: RemoveClientContactCrmDataModelByIdDocument, variables: { id: clientContactCrmDataId }, ...options };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<RemoveClientContactCrmDataModelByIdMutation>(
      { operationType: 'delete', entityName: 'clientContactCrmData', objects: [{ id: clientContactCrmDataId }] }
    );
    if (logLevel >= 2)
      console.log(' --> removeClientContactCrmDataModelById - optimisticResponse:', mutationOptions.optimisticResponse);
  }
  if (!options || !options.update) {
    mutationOptions.update = generateUpdateFunctionForMutation<RemoveClientContactCrmDataModelByIdMutation>({
      operationType: 'delete',
      entityName: 'clientContactCrmData',
      entityId: clientContactCrmDataId,
    });
  }

  const mutation: RemoveClientContactCrmDataModelByIdQueryResult = await apolloClient.mutate<
    RemoveClientContactCrmDataModelByIdMutation,
    RemoveClientContactCrmDataModelByIdMutationVariables
  >(mutationOptions);

  return { ...mutation, affected_rows: mutation?.data?.delete_clientContactCrmData?.affected_rows || 0 };
}

type RemoveClientContactCrmDataModelObjectsQueryResult = FetchResult<
  RemoveClientContactCrmDataModelMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveClientContactCrmDataModelObjectsQueryHelperResultEx = RemoveClientContactCrmDataModelObjectsQueryResult &
  RemoveEntitiesQueryHelperResultEx;

async function removeClientContactCrmDataModelObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<
    MutationOptions<RemoveClientContactCrmDataModelMutation, RemoveClientContactCrmDataModelMutationVariables>,
    'mutation'
  >;
}): Promise<RemoveClientContactCrmDataModelObjectsQueryHelperResultEx> {
  const mutation: RemoveClientContactCrmDataModelObjectsQueryResult = await apolloClient.mutate<
    RemoveClientContactCrmDataModelMutation,
    RemoveClientContactCrmDataModelMutationVariables
  >({ mutation: RemoveClientContactCrmDataModelDocument, ...options });

  return { ...mutation, affected_rows: mutation?.data?.delete_clientContactCrmData?.affected_rows || 0 };
}

// ClientContactCrmData Fragment Helper Object
//------------------------------------------------

export const ClientContactCrmDataFragmentGQLHelper = {
  cacheGetDataId: cacheGetDataIdForClientContactCrmData,
  cacheReadFragmentById: cacheReadFragmentClientContactCrmDataById,
  cacheWriteFragmentById: cacheWriteFragmentClientContactCrmDataById,
  cacheReadQueryById: cacheReadQueryClientContactCrmDataById,
  cacheWriteQueryById: cacheWriteQueryClientContactCrmDataById,
  cacheReadQueryObjects: cacheReadQueryClientContactCrmDataObjects,
  cacheWriteQueryObjects: cacheWriteQueryClientContactCrmDataObjects,
  cacheWriteQueryInsert: cacheWriteQueryClientContactCrmDataInsert,
  cacheWriteQueryRemove: cacheWriteQueryClientContactCrmDataRemove,
  queryById: queryClientContactCrmDataById,
  queryObjects: queryClientContactCrmDataObjects,
  watchQueryById: watchQueryClientContactCrmDataById,
  watchQueryObjects: watchQueryClientContactCrmDataObjects,
  subscribeToById: subscribeToClientContactCrmDataById,
  subscribeToObjects: subscribeToClientContactCrmDataObjects,
  insert: insertClientContactCrmData,
  insertWithOnConflict: insertClientContactCrmDataWithOnConflict,
  insertObjects: insertClientContactCrmDataObjects,
  updateById: updateClientContactCrmDataById,
  updateObjects: updateClientContactCrmDataObjects,
};

// clientContactCrmData Entity Helper Object
//------------------------------------------------

export const ClientContactCrmDataModelGQLHelper = {
  removeById: removeClientContactCrmDataModelById,
  removeObjects: removeClientContactCrmDataModelObjects,
};

// client HELPERS
//------------------------------------------------

export type ClientExternalByIdHelperResultEx = { clientExternal: ClientExternalFragment | null | undefined };
export type ClientExternalObjectsHelperResultEx = { objects: ClientExternalFragment[] };

// Direct Client & Cache Fragment Helpers
//
function cacheGetDataIdForClientExternal(clientId: string): string {
  return defaultCacheIdFromObject({ __typename: 'client', id: clientId });
}

function cacheReadFragmentClientExternalById({
  apolloCache,
  clientId,
}: {
  apolloCache: ApolloCache<object>;
  clientId: string;
}): ClientExternalFragment | null | undefined {
  return apolloCache.readFragment<ClientExternalFragment | null | undefined>({
    fragment: ClientExternalFragmentDoc,
    fragmentName: 'ClientExternal',
    id: defaultCacheIdFromObject({ __typename: 'client', id: clientId }),
  });
}

function cacheWriteFragmentClientExternalById({
  apolloCache,
  clientId,
  clientExternalPartial,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  clientId: string;
  clientExternalPartial: Partial<ClientExternalFragment> | Client_Insert_Input | null;
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): Partial<ClientExternalFragment> {
  const parsedFragment = convertToGraph({ input: clientExternalPartial, typename: 'client', fieldMap });
  if (logLevel >= 2) console.log(' --> cacheWriteFragmentClientExternalById - parsedFragment:', parsedFragment);
  apolloCache.writeFragment<Partial<ClientExternalFragment> | null>({
    fragment: ClientExternalFragmentDoc,
    fragmentName: 'ClientExternal',
    id: defaultCacheIdFromObject({ ...parsedFragment, id: clientId }),
    data: parsedFragment,
    broadcast: apolloBroadcast,
  });
  return parsedFragment;
}

function cacheReadQueryClientExternalById({
  apolloCache,
  clientId,
}: {
  apolloCache: ApolloCache<object>;
  clientId: string;
}): ClientExternalFragment | null | undefined {
  try {
    return apolloCache.readQuery<ClientExternalFragment | null>({
      query: QueryClientExternalByIdDocument,
      variables: { clientId },
    });
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheReadQueryClientExternalById threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheWriteQueryClientExternalById({
  apolloCache,
  clientId,
  clientExternal,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  clientId: string;
  clientExternal: ClientExternalFragment | Client_Insert_Input | null;
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): void {
  try {
    const clientExternalPartial = convertToGraph({ input: clientExternal, typename: 'client', fieldMap });
    return apolloCache.writeQuery<ClientExternalFragment | null>({
      query: QueryClientExternalByIdDocument,
      variables: { clientId },
      data: clientExternal ? clientExternalPartial : null,
      broadcast: apolloBroadcast,
    });
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheWriteQueryClientExternalById threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheReadQueryClientExternalObjects({
  apolloCache,
  variables,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryClientExternalObjectsQueryVariables;
}): Client[] | null | undefined {
  try {
    return (
      apolloCache.readQuery<{ Client: Client[] | null }>({ query: QueryClientExternalObjectsDocument, variables })
        ?.Client || []
    );
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheReadQueryClientExternalObjects threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheWriteQueryClientExternalObjects({
  apolloCache,
  variables,
  data,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryClientExternalObjectsQueryVariables;
  data: (Client | Client_Insert_Input)[];
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): void {
  try {
    const objects = convertToGraph({ input: data, typename: 'client', fieldMap });
    return apolloCache.writeQuery<{ Client: Client[] }>({
      query: QueryClientExternalObjectsDocument,
      variables,
      data: { Client: objects },
      broadcast: apolloBroadcast,
    });
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheWriteQueryClientExternalObjects threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheWriteQueryClientExternalInsert({
  apolloCache,
  variables,
  client,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryClientExternalObjectsQueryVariables;
  client: Client_Insert_Input;
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): void {
  const currentObjects = cacheReadQueryClientExternalObjects({ apolloCache, variables }) || [];
  const objectsWithInserted = [...currentObjects, convertToGraph({ input: client, typename: 'client', fieldMap })];
  if (logLevel >= 2)
    console.log(' --> cacheWriteQueryClientExternalInsert - objectsWithInserted:', objectsWithInserted);
  return cacheWriteQueryClientExternalObjects({ apolloCache, variables, data: objectsWithInserted, apolloBroadcast });
}

function cacheWriteQueryClientExternalRemove({
  apolloCache,
  variables,
  clientId,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryClientExternalObjectsQueryVariables;
  clientId: string;
  apolloBroadcast?: boolean;
}): void {
  const currentObjects = cacheReadQueryClientExternalObjects({ apolloCache, variables }) || [];
  const objectsWithRemoved = currentObjects.filter((objectItem) => objectItem.id !== clientId) || [];
  if (logLevel >= 2) console.log(' --> cacheWriteQueryClientExternalRemove - objectsWithRemoved:', objectsWithRemoved);
  return cacheWriteQueryClientExternalObjects({ apolloCache, variables, data: objectsWithRemoved, apolloBroadcast });
}

// Query Fetch Helper
//
export type QueryClientExternalByIdApolloQueryResult = ApolloQueryResult<QueryClientExternalByIdQuery>;
export type QueryClientExternalByIdApolloQueryHelperResultEx = QueryClientExternalByIdApolloQueryResult &
  ClientExternalByIdHelperResultEx;

async function queryClientExternalById({
  apolloClient,
  clientId,
  options,
}: {
  apolloClient: ApolloClient<object>;
  clientId: string;
  options?: Omit<QueryOptions<QueryClientExternalByIdQueryVariables>, 'query' | 'variables'>;
}): Promise<QueryClientExternalByIdApolloQueryHelperResultEx> {
  const query: QueryClientExternalByIdApolloQueryResult = await apolloClient.query<QueryClientExternalByIdQuery>({
    query: QueryClientExternalByIdDocument,
    variables: { clientId },
    ...options,
  });

  return { ...query, clientExternal: query?.data?.client_by_pk };
}

// Query Watch ById Helper
//
export type WatchQueryClientExternalByIdApolloObservableQuery = ObservableQuery<QueryClientExternalByIdQuery>;
async function watchQueryClientExternalById({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<QueryOptions<QueryClientExternalByIdQueryVariables>, 'query'>;
}): Promise<WatchQueryClientExternalByIdApolloObservableQuery> {
  return apolloClient.watchQuery<QueryClientExternalByIdQuery>({ query: QueryClientExternalByIdDocument, ...options });
}

// Query Fetch Objects Helper
//
export type QueryClientExternalObjectsObjectsApolloQueryResult = ApolloQueryResult<QueryClientExternalObjectsQuery>;
export type QueryClientExternalObjectsObjectsApolloQueryResultEx = QueryClientExternalObjectsObjectsApolloQueryResult &
  ClientExternalObjectsHelperResultEx;

async function queryClientExternalObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<QueryOptions<QueryClientExternalObjectsQueryVariables>, 'query'>;
}): Promise<QueryClientExternalObjectsObjectsApolloQueryResultEx> {
  const query: QueryClientExternalObjectsObjectsApolloQueryResult = await apolloClient.query<QueryClientExternalObjectsQuery>(
    { query: QueryClientExternalObjectsDocument, ...options }
  );

  return { ...query, objects: query?.data?.client || [] };
}

// Query Watch Objects Helper
//
export type WatchQueryClientExternalObjectsApolloObservableQuery = ObservableQuery<QueryClientExternalObjectsQuery>;
async function watchQueryClientExternalObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<QueryOptions<QueryClientExternalObjectsQueryVariables>, 'query'>;
}): Promise<WatchQueryClientExternalObjectsApolloObservableQuery> {
  return apolloClient.watchQuery<QueryClientExternalObjectsQuery>({
    query: QueryClientExternalObjectsDocument,
    ...options,
  });
}

// Subscription Fetch ById Helper
//
export type SubscribeToClientExternalByIdSubscriptionFetchResult = FetchResult<
  SubscribeToClientExternalByIdSubscription,
  Record<string, any>,
  Record<string, any>
>;
export type SubscribeToClientExternalByIdSubscriptionFetchResultEx = FetchResult<
  SubscribeToClientExternalByIdSubscription,
  Record<string, any>,
  Record<string, any>
> &
  ClientExternalByIdHelperResultEx;

async function subscribeToClientExternalById({
  apolloClient,
  clientId,
  options,
}: {
  apolloClient: ApolloClient<object>;
  clientId: string;
  options?: Omit<SubscriptionOptions<SubscribeToClientExternalByIdSubscriptionVariables>, 'query' | 'variables'>;
}): Promise<Observable<SubscribeToClientExternalByIdSubscriptionFetchResultEx>> {
  const subscription: Observable<SubscribeToClientExternalByIdSubscriptionFetchResult> = apolloClient.subscribe<SubscribeToClientExternalByIdSubscription>(
    { query: SubscribeToClientExternalByIdDocument, variables: { clientId }, ...options }
  );

  return subscription.map((value) => {
    return {
      context: value.context,
      errors: value.errors,
      data: value.data,
      extensions: value.extensions,
      clientExternal: value?.data?.client_by_pk || [],
    } as SubscribeToClientExternalByIdSubscriptionFetchResultEx;
  });
}

// Subscription Fetch Objects Helper
//
export type SubscribeToClientExternalObjectsSubscriptionFetchResult = FetchResult<
  SubscribeToClientExternalObjectsSubscription,
  Record<string, any>,
  Record<string, any>
>;
export type SubscribeToClientExternalObjectsSubscriptionFetchResultEx = FetchResult<
  SubscribeToClientExternalObjectsSubscription,
  Record<string, any>,
  Record<string, any>
> &
  ClientExternalObjectsHelperResultEx;

async function subscribeToClientExternalObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options?: Omit<SubscriptionOptions<SubscribeToClientExternalObjectsSubscriptionVariables>, 'query'>;
}): Promise<Observable<SubscribeToClientExternalObjectsSubscriptionFetchResultEx>> {
  const subscription: Observable<SubscribeToClientExternalObjectsSubscriptionFetchResult> = apolloClient.subscribe<SubscribeToClientExternalObjectsSubscription>(
    { query: SubscribeToClientExternalObjectsDocument, ...options }
  );

  return subscription.map((value) => {
    return {
      context: value.context,
      errors: value.errors,
      data: value.data,
      extensions: value.extensions,
      objects: value?.data?.client || [],
    } as SubscribeToClientExternalObjectsSubscriptionFetchResultEx;
  });
}

// Insert Helper
//
type InsertClientExternalFetchResult = FetchResult<
  InsertClientExternalMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertClientExternalFetchHelperResultEx = InsertClientExternalFetchResult &
  ClientExternalByIdHelperResultEx;

async function insertClientExternal({
  apolloClient,
  client,
  autoOptimisticResponse,
  fieldMap,
  options,
}: {
  apolloClient: ApolloClient<object>;
  client: Client_Insert_Input;
  autoOptimisticResponse?: boolean;
  fieldMap?: FieldMap;
  options?: Omit<
    MutationOptions<InsertClientExternalMutation, InsertClientExternalMutationVariables>,
    'mutation' | 'variables'
  >;
}): Promise<InsertClientExternalFetchHelperResultEx> {
  const objectForInsert = stripInsertInputClientFields({ input: client });
  const mutationOptions: MutationOptions<InsertClientExternalMutation, InsertClientExternalMutationVariables> = {
    mutation: InsertClientExternalDocument,
    variables: { objects: [objectForInsert] },
    ...options,
  };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    if (!objectForInsert.id) throw new Error(`if autoOptimisticResponse = true, id must be set in object 'client'`);
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertClientExternalMutation>({
      operationType: 'insert',
      entityName: 'client',
      objects: [objectForInsert as Client_Insert_Input & ObjectWithId],
      fieldMap,
    });
    if (logLevel >= 2)
      console.log(' --> insertClientExternal - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: InsertClientExternalFetchResult = await apolloClient.mutate<
    InsertClientExternalMutation,
    InsertClientExternalMutationVariables
  >(mutationOptions);

  return {
    ...mutation,
    clientExternal: mutation?.data?.insert_client?.returning && mutation.data.insert_client.returning[0],
  };
}

async function insertClientExternalWithOnConflict({
  apolloClient,
  client,
  onConflict,
  autoOptimisticResponse,
  fieldMap,
  options,
}: {
  apolloClient: ApolloClient<object>;
  client: Client_Insert_Input;
  onConflict: Client_On_Conflict;
  autoOptimisticResponse?: boolean;
  fieldMap?: FieldMap;
  options?: Omit<
    MutationOptions<InsertClientExternalWithOnConflictMutation, InsertClientExternalWithOnConflictMutationVariables>,
    'mutation' | 'variables'
  >;
}): Promise<InsertClientExternalFetchHelperResultEx> {
  const objectForInsert = stripInsertInputClientFields({ input: client });
  const mutationOptions: MutationOptions<
    InsertClientExternalWithOnConflictMutation,
    InsertClientExternalWithOnConflictMutationVariables
  > = {
    mutation: InsertClientExternalWithOnConflictDocument,
    variables: { objects: [objectForInsert], onConflict },
    ...options,
  };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    if (!objectForInsert.id) throw new Error(`if autoOptimisticResponse = true, id must be set in object 'client'`);
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertClientExternalWithOnConflictMutation>(
      {
        operationType: 'insert',
        entityName: 'client',
        objects: [objectForInsert as Client_Insert_Input & ObjectWithId],
        fieldMap,
      }
    );
    if (logLevel >= 2)
      console.log(' --> insertClientExternalWithOnConflict - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: InsertClientExternalFetchResult = await apolloClient.mutate<
    InsertClientExternalWithOnConflictMutation,
    InsertClientExternalWithOnConflictMutationVariables
  >(mutationOptions);

  return {
    ...mutation,
    clientExternal: mutation?.data?.insert_client?.returning && mutation.data.insert_client.returning[0],
  };
}

// Insert Objects Helper
//
type InsertClientExternalObjectsFetchResult = FetchResult<
  InsertClientExternalMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertClientExternalObjectsHelperResultEx = InsertClientExternalObjectsFetchResult &
  ClientExternalObjectsHelperResultEx;

async function insertClientExternalObjects({
  apolloClient,
  objects,
  autoOptimisticResponse,
  fieldMap,
  options,
}: {
  apolloClient: ApolloClient<object>;
  objects: Client_Insert_Input[];
  autoOptimisticResponse?: boolean;
  fieldMap?: FieldMap;
  options?: Omit<
    MutationOptions<InsertClientExternalMutation, InsertClientExternalMutationVariables>,
    'mutation' | 'variables'
  >;
}): Promise<InsertClientExternalObjectsHelperResultEx> {
  const objectsForInsert = objects.map((objectItem) => stripInsertInputClientFields({ input: objectItem }));
  const mutationOptions: MutationOptions<InsertClientExternalMutation, InsertClientExternalMutationVariables> = {
    mutation: InsertClientExternalDocument,
    variables: { objects: objectsForInsert },
    ...options,
  };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    if (objectsForInsert.find((objectItem) => !objectItem.id))
      throw new Error(`if autoOptimisticResponse = true, ids must be set in objects`);
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertClientExternalMutation>({
      operationType: 'insert',
      entityName: 'client',
      objects: objectsForInsert as (Client_Insert_Input & ObjectWithId)[],
      fieldMap,
    });
    if (logLevel >= 2)
      console.log(' --> insertClientExternal - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: InsertClientExternalObjectsFetchResult = await apolloClient.mutate<
    InsertClientExternalMutation,
    InsertClientExternalMutationVariables
  >(mutationOptions);

  return { ...mutation, objects: mutation?.data?.insert_client?.returning || [] };
}

// Update Helper
//
type UpdateClientExternalByIdQueryResult = FetchResult<
  UpdateClientExternalByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateClientExternalByIdHelperResultEx = UpdateClientExternalByIdQueryResult &
  ClientExternalByIdHelperResultEx;

async function updateClientExternalById({
  apolloClient,
  clientId,
  set,
  autoOptimisticResponse,
  options,
}: {
  apolloClient: ApolloClient<object>;
  clientId: string;
  set: Client_Set_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationOptions<UpdateClientExternalByIdMutation, UpdateClientExternalByIdMutationVariables>,
    'mutation'
  >;
}): Promise<UpdateClientExternalByIdHelperResultEx> {
  const mutationOptions: MutationOptions<
    UpdateClientExternalByIdMutation,
    UpdateClientExternalByIdMutationVariables
  > = { mutation: UpdateClientExternalByIdDocument, variables: { id: clientId, set }, ...options };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateClientExternalByIdMutation>({
      operationType: 'update',
      entityName: 'client',
      objects: [{ id: clientId, ...set }],
    });
    if (logLevel >= 2)
      console.log(' --> updateClientExternalById - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: UpdateClientExternalByIdQueryResult = await apolloClient.mutate<
    UpdateClientExternalByIdMutation,
    UpdateClientExternalByIdMutationVariables
  >(mutationOptions);

  return {
    ...mutation,
    clientExternal: mutation?.data?.update_client?.returning && mutation.data.update_client.returning[0],
  };
}

// Update Objects Helper
//
type UpdateClientExternalObjectsFetchResult = FetchResult<
  UpdateClientExternalMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateClientExternalObjectsHelperResultEx = UpdateClientExternalObjectsFetchResult &
  ClientExternalObjectsHelperResultEx;

async function updateClientExternalObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<MutationOptions<UpdateClientExternalMutation, UpdateClientExternalMutationVariables>, 'mutation'>;
}): Promise<UpdateClientExternalObjectsHelperResultEx> {
  const mutation: UpdateClientExternalObjectsFetchResult = await apolloClient.mutate<
    UpdateClientExternalMutation,
    UpdateClientExternalMutationVariables
  >({ mutation: UpdateClientExternalDocument, ...options });

  return { ...mutation, objects: mutation?.data?.update_client?.returning || [] };
}

// Delete Helper
//

type RemoveClientModelByIdQueryResult = FetchResult<
  RemoveClientModelByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveClientModelByIdQueryHelperResultEx = RemoveClientModelByIdQueryResult &
  RemoveEntitiesQueryHelperResultEx;

async function removeClientModelById({
  apolloClient,
  clientId,
  autoOptimisticResponse,
  options,
}: {
  apolloClient: ApolloClient<object>;
  clientId: string;
  autoOptimisticResponse?: boolean;
  options?: Omit<MutationOptions<RemoveClientModelByIdMutation, RemoveClientModelByIdMutationVariables>, 'mutation'>;
}): Promise<RemoveClientModelByIdQueryHelperResultEx> {
  const mutationOptions: MutationOptions<RemoveClientModelByIdMutation, RemoveClientModelByIdMutationVariables> = {
    mutation: RemoveClientModelByIdDocument,
    variables: { id: clientId },
    ...options,
  };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<RemoveClientModelByIdMutation>({
      operationType: 'delete',
      entityName: 'client',
      objects: [{ id: clientId }],
    });
    if (logLevel >= 2)
      console.log(' --> removeClientModelById - optimisticResponse:', mutationOptions.optimisticResponse);
  }
  if (!options || !options.update) {
    mutationOptions.update = generateUpdateFunctionForMutation<RemoveClientModelByIdMutation>({
      operationType: 'delete',
      entityName: 'client',
      entityId: clientId,
    });
  }

  const mutation: RemoveClientModelByIdQueryResult = await apolloClient.mutate<
    RemoveClientModelByIdMutation,
    RemoveClientModelByIdMutationVariables
  >(mutationOptions);

  return { ...mutation, affected_rows: mutation?.data?.delete_client?.affected_rows || 0 };
}

type RemoveClientModelObjectsQueryResult = FetchResult<
  RemoveClientModelMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveClientModelObjectsQueryHelperResultEx = RemoveClientModelObjectsQueryResult &
  RemoveEntitiesQueryHelperResultEx;

async function removeClientModelObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<MutationOptions<RemoveClientModelMutation, RemoveClientModelMutationVariables>, 'mutation'>;
}): Promise<RemoveClientModelObjectsQueryHelperResultEx> {
  const mutation: RemoveClientModelObjectsQueryResult = await apolloClient.mutate<
    RemoveClientModelMutation,
    RemoveClientModelMutationVariables
  >({ mutation: RemoveClientModelDocument, ...options });

  return { ...mutation, affected_rows: mutation?.data?.delete_client?.affected_rows || 0 };
}

// ClientExternal Fragment Helper Object
//------------------------------------------------

export const ClientExternalFragmentGQLHelper = {
  cacheGetDataId: cacheGetDataIdForClientExternal,
  cacheReadFragmentById: cacheReadFragmentClientExternalById,
  cacheWriteFragmentById: cacheWriteFragmentClientExternalById,
  cacheReadQueryById: cacheReadQueryClientExternalById,
  cacheWriteQueryById: cacheWriteQueryClientExternalById,
  cacheReadQueryObjects: cacheReadQueryClientExternalObjects,
  cacheWriteQueryObjects: cacheWriteQueryClientExternalObjects,
  cacheWriteQueryInsert: cacheWriteQueryClientExternalInsert,
  cacheWriteQueryRemove: cacheWriteQueryClientExternalRemove,
  queryById: queryClientExternalById,
  queryObjects: queryClientExternalObjects,
  watchQueryById: watchQueryClientExternalById,
  watchQueryObjects: watchQueryClientExternalObjects,
  subscribeToById: subscribeToClientExternalById,
  subscribeToObjects: subscribeToClientExternalObjects,
  insert: insertClientExternal,
  insertWithOnConflict: insertClientExternalWithOnConflict,
  insertObjects: insertClientExternalObjects,
  updateById: updateClientExternalById,
  updateObjects: updateClientExternalObjects,
};

// client Entity Helper Object
//------------------------------------------------

export const ClientModelGQLHelper = {
  removeById: removeClientModelById,
  removeObjects: removeClientModelObjects,
};

// client HELPERS
//------------------------------------------------

export type ClientQaByIdHelperResultEx = { clientQa: ClientQaFragment | null | undefined };
export type ClientQaObjectsHelperResultEx = { objects: ClientQaFragment[] };

// Direct Client & Cache Fragment Helpers
//
function cacheGetDataIdForClientQa(clientId: string): string {
  return defaultCacheIdFromObject({ __typename: 'client', id: clientId });
}

function cacheReadFragmentClientQaById({
  apolloCache,
  clientId,
}: {
  apolloCache: ApolloCache<object>;
  clientId: string;
}): ClientQaFragment | null | undefined {
  return apolloCache.readFragment<ClientQaFragment | null | undefined>({
    fragment: ClientQaFragmentDoc,
    fragmentName: 'ClientQa',
    id: defaultCacheIdFromObject({ __typename: 'client', id: clientId }),
  });
}

function cacheWriteFragmentClientQaById({
  apolloCache,
  clientId,
  clientQaPartial,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  clientId: string;
  clientQaPartial: Partial<ClientQaFragment> | Client_Insert_Input | null;
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): Partial<ClientQaFragment> {
  const parsedFragment = convertToGraph({ input: clientQaPartial, typename: 'client', fieldMap });
  if (logLevel >= 2) console.log(' --> cacheWriteFragmentClientQaById - parsedFragment:', parsedFragment);
  apolloCache.writeFragment<Partial<ClientQaFragment> | null>({
    fragment: ClientQaFragmentDoc,
    fragmentName: 'ClientQa',
    id: defaultCacheIdFromObject({ ...parsedFragment, id: clientId }),
    data: parsedFragment,
    broadcast: apolloBroadcast,
  });
  return parsedFragment;
}

function cacheReadQueryClientQaById({
  apolloCache,
  clientId,
}: {
  apolloCache: ApolloCache<object>;
  clientId: string;
}): ClientQaFragment | null | undefined {
  try {
    return apolloCache.readQuery<ClientQaFragment | null>({
      query: QueryClientQaByIdDocument,
      variables: { clientId },
    });
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheReadQueryClientQaById threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheWriteQueryClientQaById({
  apolloCache,
  clientId,
  clientQa,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  clientId: string;
  clientQa: ClientQaFragment | Client_Insert_Input | null;
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): void {
  try {
    const clientQaPartial = convertToGraph({ input: clientQa, typename: 'client', fieldMap });
    return apolloCache.writeQuery<ClientQaFragment | null>({
      query: QueryClientQaByIdDocument,
      variables: { clientId },
      data: clientQa ? clientQaPartial : null,
      broadcast: apolloBroadcast,
    });
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheWriteQueryClientQaById threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheReadQueryClientQaObjects({
  apolloCache,
  variables,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryClientQaObjectsQueryVariables;
}): Client[] | null | undefined {
  try {
    return (
      apolloCache.readQuery<{ Client: Client[] | null }>({ query: QueryClientQaObjectsDocument, variables })?.Client ||
      []
    );
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheReadQueryClientQaObjects threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheWriteQueryClientQaObjects({
  apolloCache,
  variables,
  data,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryClientQaObjectsQueryVariables;
  data: (Client | Client_Insert_Input)[];
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): void {
  try {
    const objects = convertToGraph({ input: data, typename: 'client', fieldMap });
    return apolloCache.writeQuery<{ Client: Client[] }>({
      query: QueryClientQaObjectsDocument,
      variables,
      data: { Client: objects },
      broadcast: apolloBroadcast,
    });
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheWriteQueryClientQaObjects threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheWriteQueryClientQaInsert({
  apolloCache,
  variables,
  client,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryClientQaObjectsQueryVariables;
  client: Client_Insert_Input;
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): void {
  const currentObjects = cacheReadQueryClientQaObjects({ apolloCache, variables }) || [];
  const objectsWithInserted = [...currentObjects, convertToGraph({ input: client, typename: 'client', fieldMap })];
  if (logLevel >= 2) console.log(' --> cacheWriteQueryClientQaInsert - objectsWithInserted:', objectsWithInserted);
  return cacheWriteQueryClientQaObjects({ apolloCache, variables, data: objectsWithInserted, apolloBroadcast });
}

function cacheWriteQueryClientQaRemove({
  apolloCache,
  variables,
  clientId,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryClientQaObjectsQueryVariables;
  clientId: string;
  apolloBroadcast?: boolean;
}): void {
  const currentObjects = cacheReadQueryClientQaObjects({ apolloCache, variables }) || [];
  const objectsWithRemoved = currentObjects.filter((objectItem) => objectItem.id !== clientId) || [];
  if (logLevel >= 2) console.log(' --> cacheWriteQueryClientQaRemove - objectsWithRemoved:', objectsWithRemoved);
  return cacheWriteQueryClientQaObjects({ apolloCache, variables, data: objectsWithRemoved, apolloBroadcast });
}

// Query Fetch Helper
//
export type QueryClientQaByIdApolloQueryResult = ApolloQueryResult<QueryClientQaByIdQuery>;
export type QueryClientQaByIdApolloQueryHelperResultEx = QueryClientQaByIdApolloQueryResult &
  ClientQaByIdHelperResultEx;

async function queryClientQaById({
  apolloClient,
  clientId,
  options,
}: {
  apolloClient: ApolloClient<object>;
  clientId: string;
  options?: Omit<QueryOptions<QueryClientQaByIdQueryVariables>, 'query' | 'variables'>;
}): Promise<QueryClientQaByIdApolloQueryHelperResultEx> {
  const query: QueryClientQaByIdApolloQueryResult = await apolloClient.query<QueryClientQaByIdQuery>({
    query: QueryClientQaByIdDocument,
    variables: { clientId },
    ...options,
  });

  return { ...query, clientQa: query?.data?.client_by_pk };
}

// Query Watch ById Helper
//
export type WatchQueryClientQaByIdApolloObservableQuery = ObservableQuery<QueryClientQaByIdQuery>;
async function watchQueryClientQaById({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<QueryOptions<QueryClientQaByIdQueryVariables>, 'query'>;
}): Promise<WatchQueryClientQaByIdApolloObservableQuery> {
  return apolloClient.watchQuery<QueryClientQaByIdQuery>({ query: QueryClientQaByIdDocument, ...options });
}

// Query Fetch Objects Helper
//
export type QueryClientQaObjectsObjectsApolloQueryResult = ApolloQueryResult<QueryClientQaObjectsQuery>;
export type QueryClientQaObjectsObjectsApolloQueryResultEx = QueryClientQaObjectsObjectsApolloQueryResult &
  ClientQaObjectsHelperResultEx;

async function queryClientQaObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<QueryOptions<QueryClientQaObjectsQueryVariables>, 'query'>;
}): Promise<QueryClientQaObjectsObjectsApolloQueryResultEx> {
  const query: QueryClientQaObjectsObjectsApolloQueryResult = await apolloClient.query<QueryClientQaObjectsQuery>({
    query: QueryClientQaObjectsDocument,
    ...options,
  });

  return { ...query, objects: query?.data?.client || [] };
}

// Query Watch Objects Helper
//
export type WatchQueryClientQaObjectsApolloObservableQuery = ObservableQuery<QueryClientQaObjectsQuery>;
async function watchQueryClientQaObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<QueryOptions<QueryClientQaObjectsQueryVariables>, 'query'>;
}): Promise<WatchQueryClientQaObjectsApolloObservableQuery> {
  return apolloClient.watchQuery<QueryClientQaObjectsQuery>({ query: QueryClientQaObjectsDocument, ...options });
}

// Subscription Fetch ById Helper
//
export type SubscribeToClientQaByIdSubscriptionFetchResult = FetchResult<
  SubscribeToClientQaByIdSubscription,
  Record<string, any>,
  Record<string, any>
>;
export type SubscribeToClientQaByIdSubscriptionFetchResultEx = FetchResult<
  SubscribeToClientQaByIdSubscription,
  Record<string, any>,
  Record<string, any>
> &
  ClientQaByIdHelperResultEx;

async function subscribeToClientQaById({
  apolloClient,
  clientId,
  options,
}: {
  apolloClient: ApolloClient<object>;
  clientId: string;
  options?: Omit<SubscriptionOptions<SubscribeToClientQaByIdSubscriptionVariables>, 'query' | 'variables'>;
}): Promise<Observable<SubscribeToClientQaByIdSubscriptionFetchResultEx>> {
  const subscription: Observable<SubscribeToClientQaByIdSubscriptionFetchResult> = apolloClient.subscribe<SubscribeToClientQaByIdSubscription>(
    { query: SubscribeToClientQaByIdDocument, variables: { clientId }, ...options }
  );

  return subscription.map((value) => {
    return {
      context: value.context,
      errors: value.errors,
      data: value.data,
      extensions: value.extensions,
      clientQa: value?.data?.client_by_pk || [],
    } as SubscribeToClientQaByIdSubscriptionFetchResultEx;
  });
}

// Subscription Fetch Objects Helper
//
export type SubscribeToClientQaObjectsSubscriptionFetchResult = FetchResult<
  SubscribeToClientQaObjectsSubscription,
  Record<string, any>,
  Record<string, any>
>;
export type SubscribeToClientQaObjectsSubscriptionFetchResultEx = FetchResult<
  SubscribeToClientQaObjectsSubscription,
  Record<string, any>,
  Record<string, any>
> &
  ClientQaObjectsHelperResultEx;

async function subscribeToClientQaObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options?: Omit<SubscriptionOptions<SubscribeToClientQaObjectsSubscriptionVariables>, 'query'>;
}): Promise<Observable<SubscribeToClientQaObjectsSubscriptionFetchResultEx>> {
  const subscription: Observable<SubscribeToClientQaObjectsSubscriptionFetchResult> = apolloClient.subscribe<SubscribeToClientQaObjectsSubscription>(
    { query: SubscribeToClientQaObjectsDocument, ...options }
  );

  return subscription.map((value) => {
    return {
      context: value.context,
      errors: value.errors,
      data: value.data,
      extensions: value.extensions,
      objects: value?.data?.client || [],
    } as SubscribeToClientQaObjectsSubscriptionFetchResultEx;
  });
}

// Insert Helper
//
type InsertClientQaFetchResult = FetchResult<InsertClientQaMutation, Record<string, any>, Record<string, any>>;
export type InsertClientQaFetchHelperResultEx = InsertClientQaFetchResult & ClientQaByIdHelperResultEx;

async function insertClientQa({
  apolloClient,
  client,
  autoOptimisticResponse,
  fieldMap,
  options,
}: {
  apolloClient: ApolloClient<object>;
  client: Client_Insert_Input;
  autoOptimisticResponse?: boolean;
  fieldMap?: FieldMap;
  options?: Omit<MutationOptions<InsertClientQaMutation, InsertClientQaMutationVariables>, 'mutation' | 'variables'>;
}): Promise<InsertClientQaFetchHelperResultEx> {
  const objectForInsert = stripInsertInputClientFields({ input: client });
  const mutationOptions: MutationOptions<InsertClientQaMutation, InsertClientQaMutationVariables> = {
    mutation: InsertClientQaDocument,
    variables: { objects: [objectForInsert] },
    ...options,
  };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    if (!objectForInsert.id) throw new Error(`if autoOptimisticResponse = true, id must be set in object 'client'`);
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertClientQaMutation>({
      operationType: 'insert',
      entityName: 'client',
      objects: [objectForInsert as Client_Insert_Input & ObjectWithId],
      fieldMap,
    });
    if (logLevel >= 2) console.log(' --> insertClientQa - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: InsertClientQaFetchResult = await apolloClient.mutate<
    InsertClientQaMutation,
    InsertClientQaMutationVariables
  >(mutationOptions);

  return {
    ...mutation,
    clientQa: mutation?.data?.insert_client?.returning && mutation.data.insert_client.returning[0],
  };
}

async function insertClientQaWithOnConflict({
  apolloClient,
  client,
  onConflict,
  autoOptimisticResponse,
  fieldMap,
  options,
}: {
  apolloClient: ApolloClient<object>;
  client: Client_Insert_Input;
  onConflict: Client_On_Conflict;
  autoOptimisticResponse?: boolean;
  fieldMap?: FieldMap;
  options?: Omit<
    MutationOptions<InsertClientQaWithOnConflictMutation, InsertClientQaWithOnConflictMutationVariables>,
    'mutation' | 'variables'
  >;
}): Promise<InsertClientQaFetchHelperResultEx> {
  const objectForInsert = stripInsertInputClientFields({ input: client });
  const mutationOptions: MutationOptions<
    InsertClientQaWithOnConflictMutation,
    InsertClientQaWithOnConflictMutationVariables
  > = {
    mutation: InsertClientQaWithOnConflictDocument,
    variables: { objects: [objectForInsert], onConflict },
    ...options,
  };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    if (!objectForInsert.id) throw new Error(`if autoOptimisticResponse = true, id must be set in object 'client'`);
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertClientQaWithOnConflictMutation>({
      operationType: 'insert',
      entityName: 'client',
      objects: [objectForInsert as Client_Insert_Input & ObjectWithId],
      fieldMap,
    });
    if (logLevel >= 2)
      console.log(' --> insertClientQaWithOnConflict - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: InsertClientQaFetchResult = await apolloClient.mutate<
    InsertClientQaWithOnConflictMutation,
    InsertClientQaWithOnConflictMutationVariables
  >(mutationOptions);

  return {
    ...mutation,
    clientQa: mutation?.data?.insert_client?.returning && mutation.data.insert_client.returning[0],
  };
}

// Insert Objects Helper
//
type InsertClientQaObjectsFetchResult = FetchResult<InsertClientQaMutation, Record<string, any>, Record<string, any>>;
export type InsertClientQaObjectsHelperResultEx = InsertClientQaObjectsFetchResult & ClientQaObjectsHelperResultEx;

async function insertClientQaObjects({
  apolloClient,
  objects,
  autoOptimisticResponse,
  fieldMap,
  options,
}: {
  apolloClient: ApolloClient<object>;
  objects: Client_Insert_Input[];
  autoOptimisticResponse?: boolean;
  fieldMap?: FieldMap;
  options?: Omit<MutationOptions<InsertClientQaMutation, InsertClientQaMutationVariables>, 'mutation' | 'variables'>;
}): Promise<InsertClientQaObjectsHelperResultEx> {
  const objectsForInsert = objects.map((objectItem) => stripInsertInputClientFields({ input: objectItem }));
  const mutationOptions: MutationOptions<InsertClientQaMutation, InsertClientQaMutationVariables> = {
    mutation: InsertClientQaDocument,
    variables: { objects: objectsForInsert },
    ...options,
  };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    if (objectsForInsert.find((objectItem) => !objectItem.id))
      throw new Error(`if autoOptimisticResponse = true, ids must be set in objects`);
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertClientQaMutation>({
      operationType: 'insert',
      entityName: 'client',
      objects: objectsForInsert as (Client_Insert_Input & ObjectWithId)[],
      fieldMap,
    });
    if (logLevel >= 2) console.log(' --> insertClientQa - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: InsertClientQaObjectsFetchResult = await apolloClient.mutate<
    InsertClientQaMutation,
    InsertClientQaMutationVariables
  >(mutationOptions);

  return { ...mutation, objects: mutation?.data?.insert_client?.returning || [] };
}

// Update Helper
//
type UpdateClientQaByIdQueryResult = FetchResult<UpdateClientQaByIdMutation, Record<string, any>, Record<string, any>>;
export type UpdateClientQaByIdHelperResultEx = UpdateClientQaByIdQueryResult & ClientQaByIdHelperResultEx;

async function updateClientQaById({
  apolloClient,
  clientId,
  set,
  autoOptimisticResponse,
  options,
}: {
  apolloClient: ApolloClient<object>;
  clientId: string;
  set: Client_Set_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<MutationOptions<UpdateClientQaByIdMutation, UpdateClientQaByIdMutationVariables>, 'mutation'>;
}): Promise<UpdateClientQaByIdHelperResultEx> {
  const mutationOptions: MutationOptions<UpdateClientQaByIdMutation, UpdateClientQaByIdMutationVariables> = {
    mutation: UpdateClientQaByIdDocument,
    variables: { id: clientId, set },
    ...options,
  };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateClientQaByIdMutation>({
      operationType: 'update',
      entityName: 'client',
      objects: [{ id: clientId, ...set }],
    });
    if (logLevel >= 2) console.log(' --> updateClientQaById - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: UpdateClientQaByIdQueryResult = await apolloClient.mutate<
    UpdateClientQaByIdMutation,
    UpdateClientQaByIdMutationVariables
  >(mutationOptions);

  return {
    ...mutation,
    clientQa: mutation?.data?.update_client?.returning && mutation.data.update_client.returning[0],
  };
}

// Update Objects Helper
//
type UpdateClientQaObjectsFetchResult = FetchResult<UpdateClientQaMutation, Record<string, any>, Record<string, any>>;
export type UpdateClientQaObjectsHelperResultEx = UpdateClientQaObjectsFetchResult & ClientQaObjectsHelperResultEx;

async function updateClientQaObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<MutationOptions<UpdateClientQaMutation, UpdateClientQaMutationVariables>, 'mutation'>;
}): Promise<UpdateClientQaObjectsHelperResultEx> {
  const mutation: UpdateClientQaObjectsFetchResult = await apolloClient.mutate<
    UpdateClientQaMutation,
    UpdateClientQaMutationVariables
  >({ mutation: UpdateClientQaDocument, ...options });

  return { ...mutation, objects: mutation?.data?.update_client?.returning || [] };
}

// ClientQa Fragment Helper Object
//------------------------------------------------

export const ClientQaFragmentGQLHelper = {
  cacheGetDataId: cacheGetDataIdForClientQa,
  cacheReadFragmentById: cacheReadFragmentClientQaById,
  cacheWriteFragmentById: cacheWriteFragmentClientQaById,
  cacheReadQueryById: cacheReadQueryClientQaById,
  cacheWriteQueryById: cacheWriteQueryClientQaById,
  cacheReadQueryObjects: cacheReadQueryClientQaObjects,
  cacheWriteQueryObjects: cacheWriteQueryClientQaObjects,
  cacheWriteQueryInsert: cacheWriteQueryClientQaInsert,
  cacheWriteQueryRemove: cacheWriteQueryClientQaRemove,
  queryById: queryClientQaById,
  queryObjects: queryClientQaObjects,
  watchQueryById: watchQueryClientQaById,
  watchQueryObjects: watchQueryClientQaObjects,
  subscribeToById: subscribeToClientQaById,
  subscribeToObjects: subscribeToClientQaObjects,
  insert: insertClientQa,
  insertWithOnConflict: insertClientQaWithOnConflict,
  insertObjects: insertClientQaObjects,
  updateById: updateClientQaById,
  updateObjects: updateClientQaObjects,
};

// client HELPERS
//------------------------------------------------

export type ClientInternalByIdHelperResultEx = { clientInternal: ClientInternalFragment | null | undefined };
export type ClientInternalObjectsHelperResultEx = { objects: ClientInternalFragment[] };

// Direct Client & Cache Fragment Helpers
//
function cacheGetDataIdForClientInternal(clientId: string): string {
  return defaultCacheIdFromObject({ __typename: 'client', id: clientId });
}

function cacheReadFragmentClientInternalById({
  apolloCache,
  clientId,
}: {
  apolloCache: ApolloCache<object>;
  clientId: string;
}): ClientInternalFragment | null | undefined {
  return apolloCache.readFragment<ClientInternalFragment | null | undefined>({
    fragment: ClientInternalFragmentDoc,
    fragmentName: 'ClientInternal',
    id: defaultCacheIdFromObject({ __typename: 'client', id: clientId }),
  });
}

function cacheWriteFragmentClientInternalById({
  apolloCache,
  clientId,
  clientInternalPartial,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  clientId: string;
  clientInternalPartial: Partial<ClientInternalFragment> | Client_Insert_Input | null;
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): Partial<ClientInternalFragment> {
  const parsedFragment = convertToGraph({ input: clientInternalPartial, typename: 'client', fieldMap });
  if (logLevel >= 2) console.log(' --> cacheWriteFragmentClientInternalById - parsedFragment:', parsedFragment);
  apolloCache.writeFragment<Partial<ClientInternalFragment> | null>({
    fragment: ClientInternalFragmentDoc,
    fragmentName: 'ClientInternal',
    id: defaultCacheIdFromObject({ ...parsedFragment, id: clientId }),
    data: parsedFragment,
    broadcast: apolloBroadcast,
  });
  return parsedFragment;
}

function cacheReadQueryClientInternalById({
  apolloCache,
  clientId,
}: {
  apolloCache: ApolloCache<object>;
  clientId: string;
}): ClientInternalFragment | null | undefined {
  try {
    return apolloCache.readQuery<ClientInternalFragment | null>({
      query: QueryClientInternalByIdDocument,
      variables: { clientId },
    });
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheReadQueryClientInternalById threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheWriteQueryClientInternalById({
  apolloCache,
  clientId,
  clientInternal,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  clientId: string;
  clientInternal: ClientInternalFragment | Client_Insert_Input | null;
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): void {
  try {
    const clientInternalPartial = convertToGraph({ input: clientInternal, typename: 'client', fieldMap });
    return apolloCache.writeQuery<ClientInternalFragment | null>({
      query: QueryClientInternalByIdDocument,
      variables: { clientId },
      data: clientInternal ? clientInternalPartial : null,
      broadcast: apolloBroadcast,
    });
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheWriteQueryClientInternalById threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheReadQueryClientInternalObjects({
  apolloCache,
  variables,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryClientInternalObjectsQueryVariables;
}): Client[] | null | undefined {
  try {
    return (
      apolloCache.readQuery<{ Client: Client[] | null }>({ query: QueryClientInternalObjectsDocument, variables })
        ?.Client || []
    );
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheReadQueryClientInternalObjects threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheWriteQueryClientInternalObjects({
  apolloCache,
  variables,
  data,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryClientInternalObjectsQueryVariables;
  data: (Client | Client_Insert_Input)[];
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): void {
  try {
    const objects = convertToGraph({ input: data, typename: 'client', fieldMap });
    return apolloCache.writeQuery<{ Client: Client[] }>({
      query: QueryClientInternalObjectsDocument,
      variables,
      data: { Client: objects },
      broadcast: apolloBroadcast,
    });
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheWriteQueryClientInternalObjects threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheWriteQueryClientInternalInsert({
  apolloCache,
  variables,
  client,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryClientInternalObjectsQueryVariables;
  client: Client_Insert_Input;
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): void {
  const currentObjects = cacheReadQueryClientInternalObjects({ apolloCache, variables }) || [];
  const objectsWithInserted = [...currentObjects, convertToGraph({ input: client, typename: 'client', fieldMap })];
  if (logLevel >= 2)
    console.log(' --> cacheWriteQueryClientInternalInsert - objectsWithInserted:', objectsWithInserted);
  return cacheWriteQueryClientInternalObjects({ apolloCache, variables, data: objectsWithInserted, apolloBroadcast });
}

function cacheWriteQueryClientInternalRemove({
  apolloCache,
  variables,
  clientId,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryClientInternalObjectsQueryVariables;
  clientId: string;
  apolloBroadcast?: boolean;
}): void {
  const currentObjects = cacheReadQueryClientInternalObjects({ apolloCache, variables }) || [];
  const objectsWithRemoved = currentObjects.filter((objectItem) => objectItem.id !== clientId) || [];
  if (logLevel >= 2) console.log(' --> cacheWriteQueryClientInternalRemove - objectsWithRemoved:', objectsWithRemoved);
  return cacheWriteQueryClientInternalObjects({ apolloCache, variables, data: objectsWithRemoved, apolloBroadcast });
}

// Query Fetch Helper
//
export type QueryClientInternalByIdApolloQueryResult = ApolloQueryResult<QueryClientInternalByIdQuery>;
export type QueryClientInternalByIdApolloQueryHelperResultEx = QueryClientInternalByIdApolloQueryResult &
  ClientInternalByIdHelperResultEx;

async function queryClientInternalById({
  apolloClient,
  clientId,
  options,
}: {
  apolloClient: ApolloClient<object>;
  clientId: string;
  options?: Omit<QueryOptions<QueryClientInternalByIdQueryVariables>, 'query' | 'variables'>;
}): Promise<QueryClientInternalByIdApolloQueryHelperResultEx> {
  const query: QueryClientInternalByIdApolloQueryResult = await apolloClient.query<QueryClientInternalByIdQuery>({
    query: QueryClientInternalByIdDocument,
    variables: { clientId },
    ...options,
  });

  return { ...query, clientInternal: query?.data?.client_by_pk };
}

// Query Watch ById Helper
//
export type WatchQueryClientInternalByIdApolloObservableQuery = ObservableQuery<QueryClientInternalByIdQuery>;
async function watchQueryClientInternalById({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<QueryOptions<QueryClientInternalByIdQueryVariables>, 'query'>;
}): Promise<WatchQueryClientInternalByIdApolloObservableQuery> {
  return apolloClient.watchQuery<QueryClientInternalByIdQuery>({ query: QueryClientInternalByIdDocument, ...options });
}

// Query Fetch Objects Helper
//
export type QueryClientInternalObjectsObjectsApolloQueryResult = ApolloQueryResult<QueryClientInternalObjectsQuery>;
export type QueryClientInternalObjectsObjectsApolloQueryResultEx = QueryClientInternalObjectsObjectsApolloQueryResult &
  ClientInternalObjectsHelperResultEx;

async function queryClientInternalObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<QueryOptions<QueryClientInternalObjectsQueryVariables>, 'query'>;
}): Promise<QueryClientInternalObjectsObjectsApolloQueryResultEx> {
  const query: QueryClientInternalObjectsObjectsApolloQueryResult = await apolloClient.query<QueryClientInternalObjectsQuery>(
    { query: QueryClientInternalObjectsDocument, ...options }
  );

  return { ...query, objects: query?.data?.client || [] };
}

// Query Watch Objects Helper
//
export type WatchQueryClientInternalObjectsApolloObservableQuery = ObservableQuery<QueryClientInternalObjectsQuery>;
async function watchQueryClientInternalObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<QueryOptions<QueryClientInternalObjectsQueryVariables>, 'query'>;
}): Promise<WatchQueryClientInternalObjectsApolloObservableQuery> {
  return apolloClient.watchQuery<QueryClientInternalObjectsQuery>({
    query: QueryClientInternalObjectsDocument,
    ...options,
  });
}

// Subscription Fetch ById Helper
//
export type SubscribeToClientInternalByIdSubscriptionFetchResult = FetchResult<
  SubscribeToClientInternalByIdSubscription,
  Record<string, any>,
  Record<string, any>
>;
export type SubscribeToClientInternalByIdSubscriptionFetchResultEx = FetchResult<
  SubscribeToClientInternalByIdSubscription,
  Record<string, any>,
  Record<string, any>
> &
  ClientInternalByIdHelperResultEx;

async function subscribeToClientInternalById({
  apolloClient,
  clientId,
  options,
}: {
  apolloClient: ApolloClient<object>;
  clientId: string;
  options?: Omit<SubscriptionOptions<SubscribeToClientInternalByIdSubscriptionVariables>, 'query' | 'variables'>;
}): Promise<Observable<SubscribeToClientInternalByIdSubscriptionFetchResultEx>> {
  const subscription: Observable<SubscribeToClientInternalByIdSubscriptionFetchResult> = apolloClient.subscribe<SubscribeToClientInternalByIdSubscription>(
    { query: SubscribeToClientInternalByIdDocument, variables: { clientId }, ...options }
  );

  return subscription.map((value) => {
    return {
      context: value.context,
      errors: value.errors,
      data: value.data,
      extensions: value.extensions,
      clientInternal: value?.data?.client_by_pk || [],
    } as SubscribeToClientInternalByIdSubscriptionFetchResultEx;
  });
}

// Subscription Fetch Objects Helper
//
export type SubscribeToClientInternalObjectsSubscriptionFetchResult = FetchResult<
  SubscribeToClientInternalObjectsSubscription,
  Record<string, any>,
  Record<string, any>
>;
export type SubscribeToClientInternalObjectsSubscriptionFetchResultEx = FetchResult<
  SubscribeToClientInternalObjectsSubscription,
  Record<string, any>,
  Record<string, any>
> &
  ClientInternalObjectsHelperResultEx;

async function subscribeToClientInternalObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options?: Omit<SubscriptionOptions<SubscribeToClientInternalObjectsSubscriptionVariables>, 'query'>;
}): Promise<Observable<SubscribeToClientInternalObjectsSubscriptionFetchResultEx>> {
  const subscription: Observable<SubscribeToClientInternalObjectsSubscriptionFetchResult> = apolloClient.subscribe<SubscribeToClientInternalObjectsSubscription>(
    { query: SubscribeToClientInternalObjectsDocument, ...options }
  );

  return subscription.map((value) => {
    return {
      context: value.context,
      errors: value.errors,
      data: value.data,
      extensions: value.extensions,
      objects: value?.data?.client || [],
    } as SubscribeToClientInternalObjectsSubscriptionFetchResultEx;
  });
}

// Insert Helper
//
type InsertClientInternalFetchResult = FetchResult<
  InsertClientInternalMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertClientInternalFetchHelperResultEx = InsertClientInternalFetchResult &
  ClientInternalByIdHelperResultEx;

async function insertClientInternal({
  apolloClient,
  client,
  autoOptimisticResponse,
  fieldMap,
  options,
}: {
  apolloClient: ApolloClient<object>;
  client: Client_Insert_Input;
  autoOptimisticResponse?: boolean;
  fieldMap?: FieldMap;
  options?: Omit<
    MutationOptions<InsertClientInternalMutation, InsertClientInternalMutationVariables>,
    'mutation' | 'variables'
  >;
}): Promise<InsertClientInternalFetchHelperResultEx> {
  const objectForInsert = stripInsertInputClientFields({ input: client });
  const mutationOptions: MutationOptions<InsertClientInternalMutation, InsertClientInternalMutationVariables> = {
    mutation: InsertClientInternalDocument,
    variables: { objects: [objectForInsert] },
    ...options,
  };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    if (!objectForInsert.id) throw new Error(`if autoOptimisticResponse = true, id must be set in object 'client'`);
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertClientInternalMutation>({
      operationType: 'insert',
      entityName: 'client',
      objects: [objectForInsert as Client_Insert_Input & ObjectWithId],
      fieldMap,
    });
    if (logLevel >= 2)
      console.log(' --> insertClientInternal - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: InsertClientInternalFetchResult = await apolloClient.mutate<
    InsertClientInternalMutation,
    InsertClientInternalMutationVariables
  >(mutationOptions);

  return {
    ...mutation,
    clientInternal: mutation?.data?.insert_client?.returning && mutation.data.insert_client.returning[0],
  };
}

async function insertClientInternalWithOnConflict({
  apolloClient,
  client,
  onConflict,
  autoOptimisticResponse,
  fieldMap,
  options,
}: {
  apolloClient: ApolloClient<object>;
  client: Client_Insert_Input;
  onConflict: Client_On_Conflict;
  autoOptimisticResponse?: boolean;
  fieldMap?: FieldMap;
  options?: Omit<
    MutationOptions<InsertClientInternalWithOnConflictMutation, InsertClientInternalWithOnConflictMutationVariables>,
    'mutation' | 'variables'
  >;
}): Promise<InsertClientInternalFetchHelperResultEx> {
  const objectForInsert = stripInsertInputClientFields({ input: client });
  const mutationOptions: MutationOptions<
    InsertClientInternalWithOnConflictMutation,
    InsertClientInternalWithOnConflictMutationVariables
  > = {
    mutation: InsertClientInternalWithOnConflictDocument,
    variables: { objects: [objectForInsert], onConflict },
    ...options,
  };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    if (!objectForInsert.id) throw new Error(`if autoOptimisticResponse = true, id must be set in object 'client'`);
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertClientInternalWithOnConflictMutation>(
      {
        operationType: 'insert',
        entityName: 'client',
        objects: [objectForInsert as Client_Insert_Input & ObjectWithId],
        fieldMap,
      }
    );
    if (logLevel >= 2)
      console.log(' --> insertClientInternalWithOnConflict - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: InsertClientInternalFetchResult = await apolloClient.mutate<
    InsertClientInternalWithOnConflictMutation,
    InsertClientInternalWithOnConflictMutationVariables
  >(mutationOptions);

  return {
    ...mutation,
    clientInternal: mutation?.data?.insert_client?.returning && mutation.data.insert_client.returning[0],
  };
}

// Insert Objects Helper
//
type InsertClientInternalObjectsFetchResult = FetchResult<
  InsertClientInternalMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertClientInternalObjectsHelperResultEx = InsertClientInternalObjectsFetchResult &
  ClientInternalObjectsHelperResultEx;

async function insertClientInternalObjects({
  apolloClient,
  objects,
  autoOptimisticResponse,
  fieldMap,
  options,
}: {
  apolloClient: ApolloClient<object>;
  objects: Client_Insert_Input[];
  autoOptimisticResponse?: boolean;
  fieldMap?: FieldMap;
  options?: Omit<
    MutationOptions<InsertClientInternalMutation, InsertClientInternalMutationVariables>,
    'mutation' | 'variables'
  >;
}): Promise<InsertClientInternalObjectsHelperResultEx> {
  const objectsForInsert = objects.map((objectItem) => stripInsertInputClientFields({ input: objectItem }));
  const mutationOptions: MutationOptions<InsertClientInternalMutation, InsertClientInternalMutationVariables> = {
    mutation: InsertClientInternalDocument,
    variables: { objects: objectsForInsert },
    ...options,
  };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    if (objectsForInsert.find((objectItem) => !objectItem.id))
      throw new Error(`if autoOptimisticResponse = true, ids must be set in objects`);
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertClientInternalMutation>({
      operationType: 'insert',
      entityName: 'client',
      objects: objectsForInsert as (Client_Insert_Input & ObjectWithId)[],
      fieldMap,
    });
    if (logLevel >= 2)
      console.log(' --> insertClientInternal - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: InsertClientInternalObjectsFetchResult = await apolloClient.mutate<
    InsertClientInternalMutation,
    InsertClientInternalMutationVariables
  >(mutationOptions);

  return { ...mutation, objects: mutation?.data?.insert_client?.returning || [] };
}

// Update Helper
//
type UpdateClientInternalByIdQueryResult = FetchResult<
  UpdateClientInternalByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateClientInternalByIdHelperResultEx = UpdateClientInternalByIdQueryResult &
  ClientInternalByIdHelperResultEx;

async function updateClientInternalById({
  apolloClient,
  clientId,
  set,
  autoOptimisticResponse,
  options,
}: {
  apolloClient: ApolloClient<object>;
  clientId: string;
  set: Client_Set_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationOptions<UpdateClientInternalByIdMutation, UpdateClientInternalByIdMutationVariables>,
    'mutation'
  >;
}): Promise<UpdateClientInternalByIdHelperResultEx> {
  const mutationOptions: MutationOptions<
    UpdateClientInternalByIdMutation,
    UpdateClientInternalByIdMutationVariables
  > = { mutation: UpdateClientInternalByIdDocument, variables: { id: clientId, set }, ...options };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateClientInternalByIdMutation>({
      operationType: 'update',
      entityName: 'client',
      objects: [{ id: clientId, ...set }],
    });
    if (logLevel >= 2)
      console.log(' --> updateClientInternalById - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: UpdateClientInternalByIdQueryResult = await apolloClient.mutate<
    UpdateClientInternalByIdMutation,
    UpdateClientInternalByIdMutationVariables
  >(mutationOptions);

  return {
    ...mutation,
    clientInternal: mutation?.data?.update_client?.returning && mutation.data.update_client.returning[0],
  };
}

// Update Objects Helper
//
type UpdateClientInternalObjectsFetchResult = FetchResult<
  UpdateClientInternalMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateClientInternalObjectsHelperResultEx = UpdateClientInternalObjectsFetchResult &
  ClientInternalObjectsHelperResultEx;

async function updateClientInternalObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<MutationOptions<UpdateClientInternalMutation, UpdateClientInternalMutationVariables>, 'mutation'>;
}): Promise<UpdateClientInternalObjectsHelperResultEx> {
  const mutation: UpdateClientInternalObjectsFetchResult = await apolloClient.mutate<
    UpdateClientInternalMutation,
    UpdateClientInternalMutationVariables
  >({ mutation: UpdateClientInternalDocument, ...options });

  return { ...mutation, objects: mutation?.data?.update_client?.returning || [] };
}

// ClientInternal Fragment Helper Object
//------------------------------------------------

export const ClientInternalFragmentGQLHelper = {
  cacheGetDataId: cacheGetDataIdForClientInternal,
  cacheReadFragmentById: cacheReadFragmentClientInternalById,
  cacheWriteFragmentById: cacheWriteFragmentClientInternalById,
  cacheReadQueryById: cacheReadQueryClientInternalById,
  cacheWriteQueryById: cacheWriteQueryClientInternalById,
  cacheReadQueryObjects: cacheReadQueryClientInternalObjects,
  cacheWriteQueryObjects: cacheWriteQueryClientInternalObjects,
  cacheWriteQueryInsert: cacheWriteQueryClientInternalInsert,
  cacheWriteQueryRemove: cacheWriteQueryClientInternalRemove,
  queryById: queryClientInternalById,
  queryObjects: queryClientInternalObjects,
  watchQueryById: watchQueryClientInternalById,
  watchQueryObjects: watchQueryClientInternalObjects,
  subscribeToById: subscribeToClientInternalById,
  subscribeToObjects: subscribeToClientInternalObjects,
  insert: insertClientInternal,
  insertWithOnConflict: insertClientInternalWithOnConflict,
  insertObjects: insertClientInternalObjects,
  updateById: updateClientInternalById,
  updateObjects: updateClientInternalObjects,
};

// client HELPERS
//------------------------------------------------

export type ClientSelectByIdHelperResultEx = { clientSelect: ClientSelectFragment | null | undefined };
export type ClientSelectObjectsHelperResultEx = { objects: ClientSelectFragment[] };

// Direct Client & Cache Fragment Helpers
//
function cacheGetDataIdForClientSelect(clientId: string): string {
  return defaultCacheIdFromObject({ __typename: 'client', id: clientId });
}

function cacheReadFragmentClientSelectById({
  apolloCache,
  clientId,
}: {
  apolloCache: ApolloCache<object>;
  clientId: string;
}): ClientSelectFragment | null | undefined {
  return apolloCache.readFragment<ClientSelectFragment | null | undefined>({
    fragment: ClientSelectFragmentDoc,
    fragmentName: 'ClientSelect',
    id: defaultCacheIdFromObject({ __typename: 'client', id: clientId }),
  });
}

function cacheWriteFragmentClientSelectById({
  apolloCache,
  clientId,
  clientSelectPartial,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  clientId: string;
  clientSelectPartial: Partial<ClientSelectFragment> | Client_Insert_Input | null;
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): Partial<ClientSelectFragment> {
  const parsedFragment = convertToGraph({ input: clientSelectPartial, typename: 'client', fieldMap });
  if (logLevel >= 2) console.log(' --> cacheWriteFragmentClientSelectById - parsedFragment:', parsedFragment);
  apolloCache.writeFragment<Partial<ClientSelectFragment> | null>({
    fragment: ClientSelectFragmentDoc,
    fragmentName: 'ClientSelect',
    id: defaultCacheIdFromObject({ ...parsedFragment, id: clientId }),
    data: parsedFragment,
    broadcast: apolloBroadcast,
  });
  return parsedFragment;
}

function cacheReadQueryClientSelectById({
  apolloCache,
  clientId,
}: {
  apolloCache: ApolloCache<object>;
  clientId: string;
}): ClientSelectFragment | null | undefined {
  try {
    return apolloCache.readQuery<ClientSelectFragment | null>({
      query: QueryClientSelectByIdDocument,
      variables: { clientId },
    });
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheReadQueryClientSelectById threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheWriteQueryClientSelectById({
  apolloCache,
  clientId,
  clientSelect,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  clientId: string;
  clientSelect: ClientSelectFragment | Client_Insert_Input | null;
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): void {
  try {
    const clientSelectPartial = convertToGraph({ input: clientSelect, typename: 'client', fieldMap });
    return apolloCache.writeQuery<ClientSelectFragment | null>({
      query: QueryClientSelectByIdDocument,
      variables: { clientId },
      data: clientSelect ? clientSelectPartial : null,
      broadcast: apolloBroadcast,
    });
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheWriteQueryClientSelectById threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheReadQueryClientSelectObjects({
  apolloCache,
  variables,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryClientSelectObjectsQueryVariables;
}): Client[] | null | undefined {
  try {
    return (
      apolloCache.readQuery<{ Client: Client[] | null }>({ query: QueryClientSelectObjectsDocument, variables })
        ?.Client || []
    );
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheReadQueryClientSelectObjects threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheWriteQueryClientSelectObjects({
  apolloCache,
  variables,
  data,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryClientSelectObjectsQueryVariables;
  data: (Client | Client_Insert_Input)[];
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): void {
  try {
    const objects = convertToGraph({ input: data, typename: 'client', fieldMap });
    return apolloCache.writeQuery<{ Client: Client[] }>({
      query: QueryClientSelectObjectsDocument,
      variables,
      data: { Client: objects },
      broadcast: apolloBroadcast,
    });
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheWriteQueryClientSelectObjects threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheWriteQueryClientSelectInsert({
  apolloCache,
  variables,
  client,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryClientSelectObjectsQueryVariables;
  client: Client_Insert_Input;
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): void {
  const currentObjects = cacheReadQueryClientSelectObjects({ apolloCache, variables }) || [];
  const objectsWithInserted = [...currentObjects, convertToGraph({ input: client, typename: 'client', fieldMap })];
  if (logLevel >= 2) console.log(' --> cacheWriteQueryClientSelectInsert - objectsWithInserted:', objectsWithInserted);
  return cacheWriteQueryClientSelectObjects({ apolloCache, variables, data: objectsWithInserted, apolloBroadcast });
}

function cacheWriteQueryClientSelectRemove({
  apolloCache,
  variables,
  clientId,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryClientSelectObjectsQueryVariables;
  clientId: string;
  apolloBroadcast?: boolean;
}): void {
  const currentObjects = cacheReadQueryClientSelectObjects({ apolloCache, variables }) || [];
  const objectsWithRemoved = currentObjects.filter((objectItem) => objectItem.id !== clientId) || [];
  if (logLevel >= 2) console.log(' --> cacheWriteQueryClientSelectRemove - objectsWithRemoved:', objectsWithRemoved);
  return cacheWriteQueryClientSelectObjects({ apolloCache, variables, data: objectsWithRemoved, apolloBroadcast });
}

// Query Fetch Helper
//
export type QueryClientSelectByIdApolloQueryResult = ApolloQueryResult<QueryClientSelectByIdQuery>;
export type QueryClientSelectByIdApolloQueryHelperResultEx = QueryClientSelectByIdApolloQueryResult &
  ClientSelectByIdHelperResultEx;

async function queryClientSelectById({
  apolloClient,
  clientId,
  options,
}: {
  apolloClient: ApolloClient<object>;
  clientId: string;
  options?: Omit<QueryOptions<QueryClientSelectByIdQueryVariables>, 'query' | 'variables'>;
}): Promise<QueryClientSelectByIdApolloQueryHelperResultEx> {
  const query: QueryClientSelectByIdApolloQueryResult = await apolloClient.query<QueryClientSelectByIdQuery>({
    query: QueryClientSelectByIdDocument,
    variables: { clientId },
    ...options,
  });

  return { ...query, clientSelect: query?.data?.client_by_pk };
}

// Query Watch ById Helper
//
export type WatchQueryClientSelectByIdApolloObservableQuery = ObservableQuery<QueryClientSelectByIdQuery>;
async function watchQueryClientSelectById({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<QueryOptions<QueryClientSelectByIdQueryVariables>, 'query'>;
}): Promise<WatchQueryClientSelectByIdApolloObservableQuery> {
  return apolloClient.watchQuery<QueryClientSelectByIdQuery>({ query: QueryClientSelectByIdDocument, ...options });
}

// Query Fetch Objects Helper
//
export type QueryClientSelectObjectsObjectsApolloQueryResult = ApolloQueryResult<QueryClientSelectObjectsQuery>;
export type QueryClientSelectObjectsObjectsApolloQueryResultEx = QueryClientSelectObjectsObjectsApolloQueryResult &
  ClientSelectObjectsHelperResultEx;

async function queryClientSelectObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<QueryOptions<QueryClientSelectObjectsQueryVariables>, 'query'>;
}): Promise<QueryClientSelectObjectsObjectsApolloQueryResultEx> {
  const query: QueryClientSelectObjectsObjectsApolloQueryResult = await apolloClient.query<QueryClientSelectObjectsQuery>(
    { query: QueryClientSelectObjectsDocument, ...options }
  );

  return { ...query, objects: query?.data?.client || [] };
}

// Query Watch Objects Helper
//
export type WatchQueryClientSelectObjectsApolloObservableQuery = ObservableQuery<QueryClientSelectObjectsQuery>;
async function watchQueryClientSelectObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<QueryOptions<QueryClientSelectObjectsQueryVariables>, 'query'>;
}): Promise<WatchQueryClientSelectObjectsApolloObservableQuery> {
  return apolloClient.watchQuery<QueryClientSelectObjectsQuery>({
    query: QueryClientSelectObjectsDocument,
    ...options,
  });
}

// Subscription Fetch ById Helper
//
export type SubscribeToClientSelectByIdSubscriptionFetchResult = FetchResult<
  SubscribeToClientSelectByIdSubscription,
  Record<string, any>,
  Record<string, any>
>;
export type SubscribeToClientSelectByIdSubscriptionFetchResultEx = FetchResult<
  SubscribeToClientSelectByIdSubscription,
  Record<string, any>,
  Record<string, any>
> &
  ClientSelectByIdHelperResultEx;

async function subscribeToClientSelectById({
  apolloClient,
  clientId,
  options,
}: {
  apolloClient: ApolloClient<object>;
  clientId: string;
  options?: Omit<SubscriptionOptions<SubscribeToClientSelectByIdSubscriptionVariables>, 'query' | 'variables'>;
}): Promise<Observable<SubscribeToClientSelectByIdSubscriptionFetchResultEx>> {
  const subscription: Observable<SubscribeToClientSelectByIdSubscriptionFetchResult> = apolloClient.subscribe<SubscribeToClientSelectByIdSubscription>(
    { query: SubscribeToClientSelectByIdDocument, variables: { clientId }, ...options }
  );

  return subscription.map((value) => {
    return {
      context: value.context,
      errors: value.errors,
      data: value.data,
      extensions: value.extensions,
      clientSelect: value?.data?.client_by_pk || [],
    } as SubscribeToClientSelectByIdSubscriptionFetchResultEx;
  });
}

// Subscription Fetch Objects Helper
//
export type SubscribeToClientSelectObjectsSubscriptionFetchResult = FetchResult<
  SubscribeToClientSelectObjectsSubscription,
  Record<string, any>,
  Record<string, any>
>;
export type SubscribeToClientSelectObjectsSubscriptionFetchResultEx = FetchResult<
  SubscribeToClientSelectObjectsSubscription,
  Record<string, any>,
  Record<string, any>
> &
  ClientSelectObjectsHelperResultEx;

async function subscribeToClientSelectObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options?: Omit<SubscriptionOptions<SubscribeToClientSelectObjectsSubscriptionVariables>, 'query'>;
}): Promise<Observable<SubscribeToClientSelectObjectsSubscriptionFetchResultEx>> {
  const subscription: Observable<SubscribeToClientSelectObjectsSubscriptionFetchResult> = apolloClient.subscribe<SubscribeToClientSelectObjectsSubscription>(
    { query: SubscribeToClientSelectObjectsDocument, ...options }
  );

  return subscription.map((value) => {
    return {
      context: value.context,
      errors: value.errors,
      data: value.data,
      extensions: value.extensions,
      objects: value?.data?.client || [],
    } as SubscribeToClientSelectObjectsSubscriptionFetchResultEx;
  });
}

// Insert Helper
//
type InsertClientSelectFetchResult = FetchResult<InsertClientSelectMutation, Record<string, any>, Record<string, any>>;
export type InsertClientSelectFetchHelperResultEx = InsertClientSelectFetchResult & ClientSelectByIdHelperResultEx;

async function insertClientSelect({
  apolloClient,
  client,
  autoOptimisticResponse,
  fieldMap,
  options,
}: {
  apolloClient: ApolloClient<object>;
  client: Client_Insert_Input;
  autoOptimisticResponse?: boolean;
  fieldMap?: FieldMap;
  options?: Omit<
    MutationOptions<InsertClientSelectMutation, InsertClientSelectMutationVariables>,
    'mutation' | 'variables'
  >;
}): Promise<InsertClientSelectFetchHelperResultEx> {
  const objectForInsert = stripInsertInputClientFields({ input: client });
  const mutationOptions: MutationOptions<InsertClientSelectMutation, InsertClientSelectMutationVariables> = {
    mutation: InsertClientSelectDocument,
    variables: { objects: [objectForInsert] },
    ...options,
  };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    if (!objectForInsert.id) throw new Error(`if autoOptimisticResponse = true, id must be set in object 'client'`);
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertClientSelectMutation>({
      operationType: 'insert',
      entityName: 'client',
      objects: [objectForInsert as Client_Insert_Input & ObjectWithId],
      fieldMap,
    });
    if (logLevel >= 2) console.log(' --> insertClientSelect - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: InsertClientSelectFetchResult = await apolloClient.mutate<
    InsertClientSelectMutation,
    InsertClientSelectMutationVariables
  >(mutationOptions);

  return {
    ...mutation,
    clientSelect: mutation?.data?.insert_client?.returning && mutation.data.insert_client.returning[0],
  };
}

async function insertClientSelectWithOnConflict({
  apolloClient,
  client,
  onConflict,
  autoOptimisticResponse,
  fieldMap,
  options,
}: {
  apolloClient: ApolloClient<object>;
  client: Client_Insert_Input;
  onConflict: Client_On_Conflict;
  autoOptimisticResponse?: boolean;
  fieldMap?: FieldMap;
  options?: Omit<
    MutationOptions<InsertClientSelectWithOnConflictMutation, InsertClientSelectWithOnConflictMutationVariables>,
    'mutation' | 'variables'
  >;
}): Promise<InsertClientSelectFetchHelperResultEx> {
  const objectForInsert = stripInsertInputClientFields({ input: client });
  const mutationOptions: MutationOptions<
    InsertClientSelectWithOnConflictMutation,
    InsertClientSelectWithOnConflictMutationVariables
  > = {
    mutation: InsertClientSelectWithOnConflictDocument,
    variables: { objects: [objectForInsert], onConflict },
    ...options,
  };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    if (!objectForInsert.id) throw new Error(`if autoOptimisticResponse = true, id must be set in object 'client'`);
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertClientSelectWithOnConflictMutation>(
      {
        operationType: 'insert',
        entityName: 'client',
        objects: [objectForInsert as Client_Insert_Input & ObjectWithId],
        fieldMap,
      }
    );
    if (logLevel >= 2)
      console.log(' --> insertClientSelectWithOnConflict - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: InsertClientSelectFetchResult = await apolloClient.mutate<
    InsertClientSelectWithOnConflictMutation,
    InsertClientSelectWithOnConflictMutationVariables
  >(mutationOptions);

  return {
    ...mutation,
    clientSelect: mutation?.data?.insert_client?.returning && mutation.data.insert_client.returning[0],
  };
}

// Insert Objects Helper
//
type InsertClientSelectObjectsFetchResult = FetchResult<
  InsertClientSelectMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertClientSelectObjectsHelperResultEx = InsertClientSelectObjectsFetchResult &
  ClientSelectObjectsHelperResultEx;

async function insertClientSelectObjects({
  apolloClient,
  objects,
  autoOptimisticResponse,
  fieldMap,
  options,
}: {
  apolloClient: ApolloClient<object>;
  objects: Client_Insert_Input[];
  autoOptimisticResponse?: boolean;
  fieldMap?: FieldMap;
  options?: Omit<
    MutationOptions<InsertClientSelectMutation, InsertClientSelectMutationVariables>,
    'mutation' | 'variables'
  >;
}): Promise<InsertClientSelectObjectsHelperResultEx> {
  const objectsForInsert = objects.map((objectItem) => stripInsertInputClientFields({ input: objectItem }));
  const mutationOptions: MutationOptions<InsertClientSelectMutation, InsertClientSelectMutationVariables> = {
    mutation: InsertClientSelectDocument,
    variables: { objects: objectsForInsert },
    ...options,
  };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    if (objectsForInsert.find((objectItem) => !objectItem.id))
      throw new Error(`if autoOptimisticResponse = true, ids must be set in objects`);
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertClientSelectMutation>({
      operationType: 'insert',
      entityName: 'client',
      objects: objectsForInsert as (Client_Insert_Input & ObjectWithId)[],
      fieldMap,
    });
    if (logLevel >= 2) console.log(' --> insertClientSelect - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: InsertClientSelectObjectsFetchResult = await apolloClient.mutate<
    InsertClientSelectMutation,
    InsertClientSelectMutationVariables
  >(mutationOptions);

  return { ...mutation, objects: mutation?.data?.insert_client?.returning || [] };
}

// Update Helper
//
type UpdateClientSelectByIdQueryResult = FetchResult<
  UpdateClientSelectByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateClientSelectByIdHelperResultEx = UpdateClientSelectByIdQueryResult & ClientSelectByIdHelperResultEx;

async function updateClientSelectById({
  apolloClient,
  clientId,
  set,
  autoOptimisticResponse,
  options,
}: {
  apolloClient: ApolloClient<object>;
  clientId: string;
  set: Client_Set_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<MutationOptions<UpdateClientSelectByIdMutation, UpdateClientSelectByIdMutationVariables>, 'mutation'>;
}): Promise<UpdateClientSelectByIdHelperResultEx> {
  const mutationOptions: MutationOptions<UpdateClientSelectByIdMutation, UpdateClientSelectByIdMutationVariables> = {
    mutation: UpdateClientSelectByIdDocument,
    variables: { id: clientId, set },
    ...options,
  };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateClientSelectByIdMutation>({
      operationType: 'update',
      entityName: 'client',
      objects: [{ id: clientId, ...set }],
    });
    if (logLevel >= 2)
      console.log(' --> updateClientSelectById - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: UpdateClientSelectByIdQueryResult = await apolloClient.mutate<
    UpdateClientSelectByIdMutation,
    UpdateClientSelectByIdMutationVariables
  >(mutationOptions);

  return {
    ...mutation,
    clientSelect: mutation?.data?.update_client?.returning && mutation.data.update_client.returning[0],
  };
}

// Update Objects Helper
//
type UpdateClientSelectObjectsFetchResult = FetchResult<
  UpdateClientSelectMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateClientSelectObjectsHelperResultEx = UpdateClientSelectObjectsFetchResult &
  ClientSelectObjectsHelperResultEx;

async function updateClientSelectObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<MutationOptions<UpdateClientSelectMutation, UpdateClientSelectMutationVariables>, 'mutation'>;
}): Promise<UpdateClientSelectObjectsHelperResultEx> {
  const mutation: UpdateClientSelectObjectsFetchResult = await apolloClient.mutate<
    UpdateClientSelectMutation,
    UpdateClientSelectMutationVariables
  >({ mutation: UpdateClientSelectDocument, ...options });

  return { ...mutation, objects: mutation?.data?.update_client?.returning || [] };
}

// ClientSelect Fragment Helper Object
//------------------------------------------------

export const ClientSelectFragmentGQLHelper = {
  cacheGetDataId: cacheGetDataIdForClientSelect,
  cacheReadFragmentById: cacheReadFragmentClientSelectById,
  cacheWriteFragmentById: cacheWriteFragmentClientSelectById,
  cacheReadQueryById: cacheReadQueryClientSelectById,
  cacheWriteQueryById: cacheWriteQueryClientSelectById,
  cacheReadQueryObjects: cacheReadQueryClientSelectObjects,
  cacheWriteQueryObjects: cacheWriteQueryClientSelectObjects,
  cacheWriteQueryInsert: cacheWriteQueryClientSelectInsert,
  cacheWriteQueryRemove: cacheWriteQueryClientSelectRemove,
  queryById: queryClientSelectById,
  queryObjects: queryClientSelectObjects,
  watchQueryById: watchQueryClientSelectById,
  watchQueryObjects: watchQueryClientSelectObjects,
  subscribeToById: subscribeToClientSelectById,
  subscribeToObjects: subscribeToClientSelectObjects,
  insert: insertClientSelect,
  insertWithOnConflict: insertClientSelectWithOnConflict,
  insertObjects: insertClientSelectObjects,
  updateById: updateClientSelectById,
  updateObjects: updateClientSelectObjects,
};

// clientNotificationEmail HELPERS
//------------------------------------------------

export type ClientNotificationEmailByIdHelperResultEx = {
  clientNotificationEmail: ClientNotificationEmailFragment | null | undefined;
};
export type ClientNotificationEmailObjectsHelperResultEx = { objects: ClientNotificationEmailFragment[] };

// Direct Client & Cache Fragment Helpers
//
function cacheGetDataIdForClientNotificationEmail(clientNotificationEmailId: string): string {
  return defaultCacheIdFromObject({ __typename: 'clientNotificationEmail', id: clientNotificationEmailId });
}

function cacheReadFragmentClientNotificationEmailById({
  apolloCache,
  clientNotificationEmailId,
}: {
  apolloCache: ApolloCache<object>;
  clientNotificationEmailId: string;
}): ClientNotificationEmailFragment | null | undefined {
  return apolloCache.readFragment<ClientNotificationEmailFragment | null | undefined>({
    fragment: ClientNotificationEmailFragmentDoc,
    fragmentName: 'ClientNotificationEmail',
    id: defaultCacheIdFromObject({ __typename: 'clientNotificationEmail', id: clientNotificationEmailId }),
  });
}

function cacheWriteFragmentClientNotificationEmailById({
  apolloCache,
  clientNotificationEmailId,
  clientNotificationEmailPartial,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  clientNotificationEmailId: string;
  clientNotificationEmailPartial:
    | Partial<ClientNotificationEmailFragment>
    | ClientNotificationEmail_Insert_Input
    | null;
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): Partial<ClientNotificationEmailFragment> {
  const parsedFragment = convertToGraph({
    input: clientNotificationEmailPartial,
    typename: 'clientNotificationEmail',
    fieldMap,
  });
  if (logLevel >= 2)
    console.log(' --> cacheWriteFragmentClientNotificationEmailById - parsedFragment:', parsedFragment);
  apolloCache.writeFragment<Partial<ClientNotificationEmailFragment> | null>({
    fragment: ClientNotificationEmailFragmentDoc,
    fragmentName: 'ClientNotificationEmail',
    id: defaultCacheIdFromObject({ ...parsedFragment, id: clientNotificationEmailId }),
    data: parsedFragment,
    broadcast: apolloBroadcast,
  });
  return parsedFragment;
}

function cacheReadQueryClientNotificationEmailById({
  apolloCache,
  clientNotificationEmailId,
}: {
  apolloCache: ApolloCache<object>;
  clientNotificationEmailId: string;
}): ClientNotificationEmailFragment | null | undefined {
  try {
    return apolloCache.readQuery<ClientNotificationEmailFragment | null>({
      query: QueryClientNotificationEmailByIdDocument,
      variables: { clientNotificationEmailId },
    });
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheReadQueryClientNotificationEmailById threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheWriteQueryClientNotificationEmailById({
  apolloCache,
  clientNotificationEmailId,
  clientNotificationEmail,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  clientNotificationEmailId: string;
  clientNotificationEmail: ClientNotificationEmailFragment | ClientNotificationEmail_Insert_Input | null;
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): void {
  try {
    const clientNotificationEmailPartial = convertToGraph({
      input: clientNotificationEmail,
      typename: 'clientNotificationEmail',
      fieldMap,
    });
    return apolloCache.writeQuery<ClientNotificationEmailFragment | null>({
      query: QueryClientNotificationEmailByIdDocument,
      variables: { clientNotificationEmailId },
      data: clientNotificationEmail ? clientNotificationEmailPartial : null,
      broadcast: apolloBroadcast,
    });
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheWriteQueryClientNotificationEmailById threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheReadQueryClientNotificationEmailObjects({
  apolloCache,
  variables,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryClientNotificationEmailObjectsQueryVariables;
}): ClientNotificationEmail[] | null | undefined {
  try {
    return (
      apolloCache.readQuery<{ ClientNotificationEmail: ClientNotificationEmail[] | null }>({
        query: QueryClientNotificationEmailObjectsDocument,
        variables,
      })?.ClientNotificationEmail || []
    );
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheReadQueryClientNotificationEmailObjects threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheWriteQueryClientNotificationEmailObjects({
  apolloCache,
  variables,
  data,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryClientNotificationEmailObjectsQueryVariables;
  data: (ClientNotificationEmail | ClientNotificationEmail_Insert_Input)[];
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): void {
  try {
    const objects = convertToGraph({ input: data, typename: 'clientNotificationEmail', fieldMap });
    return apolloCache.writeQuery<{ ClientNotificationEmail: ClientNotificationEmail[] }>({
      query: QueryClientNotificationEmailObjectsDocument,
      variables,
      data: { ClientNotificationEmail: objects },
      broadcast: apolloBroadcast,
    });
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheWriteQueryClientNotificationEmailObjects threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheWriteQueryClientNotificationEmailInsert({
  apolloCache,
  variables,
  clientNotificationEmail,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryClientNotificationEmailObjectsQueryVariables;
  clientNotificationEmail: ClientNotificationEmail_Insert_Input;
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): void {
  const currentObjects = cacheReadQueryClientNotificationEmailObjects({ apolloCache, variables }) || [];
  const objectsWithInserted = [
    ...currentObjects,
    convertToGraph({ input: clientNotificationEmail, typename: 'clientNotificationEmail', fieldMap }),
  ];
  if (logLevel >= 2)
    console.log(' --> cacheWriteQueryClientNotificationEmailInsert - objectsWithInserted:', objectsWithInserted);
  return cacheWriteQueryClientNotificationEmailObjects({
    apolloCache,
    variables,
    data: objectsWithInserted,
    apolloBroadcast,
  });
}

function cacheWriteQueryClientNotificationEmailRemove({
  apolloCache,
  variables,
  clientNotificationEmailId,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryClientNotificationEmailObjectsQueryVariables;
  clientNotificationEmailId: string;
  apolloBroadcast?: boolean;
}): void {
  const currentObjects = cacheReadQueryClientNotificationEmailObjects({ apolloCache, variables }) || [];
  const objectsWithRemoved = currentObjects.filter((objectItem) => objectItem.id !== clientNotificationEmailId) || [];
  if (logLevel >= 2)
    console.log(' --> cacheWriteQueryClientNotificationEmailRemove - objectsWithRemoved:', objectsWithRemoved);
  return cacheWriteQueryClientNotificationEmailObjects({
    apolloCache,
    variables,
    data: objectsWithRemoved,
    apolloBroadcast,
  });
}

// Query Fetch Helper
//
export type QueryClientNotificationEmailByIdApolloQueryResult = ApolloQueryResult<QueryClientNotificationEmailByIdQuery>;
export type QueryClientNotificationEmailByIdApolloQueryHelperResultEx = QueryClientNotificationEmailByIdApolloQueryResult &
  ClientNotificationEmailByIdHelperResultEx;

async function queryClientNotificationEmailById({
  apolloClient,
  clientNotificationEmailId,
  options,
}: {
  apolloClient: ApolloClient<object>;
  clientNotificationEmailId: string;
  options?: Omit<QueryOptions<QueryClientNotificationEmailByIdQueryVariables>, 'query' | 'variables'>;
}): Promise<QueryClientNotificationEmailByIdApolloQueryHelperResultEx> {
  const query: QueryClientNotificationEmailByIdApolloQueryResult = await apolloClient.query<QueryClientNotificationEmailByIdQuery>(
    { query: QueryClientNotificationEmailByIdDocument, variables: { clientNotificationEmailId }, ...options }
  );

  return { ...query, clientNotificationEmail: query?.data?.clientNotificationEmail_by_pk };
}

// Query Watch ById Helper
//
export type WatchQueryClientNotificationEmailByIdApolloObservableQuery = ObservableQuery<QueryClientNotificationEmailByIdQuery>;
async function watchQueryClientNotificationEmailById({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<QueryOptions<QueryClientNotificationEmailByIdQueryVariables>, 'query'>;
}): Promise<WatchQueryClientNotificationEmailByIdApolloObservableQuery> {
  return apolloClient.watchQuery<QueryClientNotificationEmailByIdQuery>({
    query: QueryClientNotificationEmailByIdDocument,
    ...options,
  });
}

// Query Fetch Objects Helper
//
export type QueryClientNotificationEmailObjectsObjectsApolloQueryResult = ApolloQueryResult<QueryClientNotificationEmailObjectsQuery>;
export type QueryClientNotificationEmailObjectsObjectsApolloQueryResultEx = QueryClientNotificationEmailObjectsObjectsApolloQueryResult &
  ClientNotificationEmailObjectsHelperResultEx;

async function queryClientNotificationEmailObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<QueryOptions<QueryClientNotificationEmailObjectsQueryVariables>, 'query'>;
}): Promise<QueryClientNotificationEmailObjectsObjectsApolloQueryResultEx> {
  const query: QueryClientNotificationEmailObjectsObjectsApolloQueryResult = await apolloClient.query<QueryClientNotificationEmailObjectsQuery>(
    { query: QueryClientNotificationEmailObjectsDocument, ...options }
  );

  return { ...query, objects: query?.data?.clientNotificationEmail || [] };
}

// Query Watch Objects Helper
//
export type WatchQueryClientNotificationEmailObjectsApolloObservableQuery = ObservableQuery<QueryClientNotificationEmailObjectsQuery>;
async function watchQueryClientNotificationEmailObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<QueryOptions<QueryClientNotificationEmailObjectsQueryVariables>, 'query'>;
}): Promise<WatchQueryClientNotificationEmailObjectsApolloObservableQuery> {
  return apolloClient.watchQuery<QueryClientNotificationEmailObjectsQuery>({
    query: QueryClientNotificationEmailObjectsDocument,
    ...options,
  });
}

// Subscription Fetch ById Helper
//
export type SubscribeToClientNotificationEmailByIdSubscriptionFetchResult = FetchResult<
  SubscribeToClientNotificationEmailByIdSubscription,
  Record<string, any>,
  Record<string, any>
>;
export type SubscribeToClientNotificationEmailByIdSubscriptionFetchResultEx = FetchResult<
  SubscribeToClientNotificationEmailByIdSubscription,
  Record<string, any>,
  Record<string, any>
> &
  ClientNotificationEmailByIdHelperResultEx;

async function subscribeToClientNotificationEmailById({
  apolloClient,
  clientNotificationEmailId,
  options,
}: {
  apolloClient: ApolloClient<object>;
  clientNotificationEmailId: string;
  options?: Omit<
    SubscriptionOptions<SubscribeToClientNotificationEmailByIdSubscriptionVariables>,
    'query' | 'variables'
  >;
}): Promise<Observable<SubscribeToClientNotificationEmailByIdSubscriptionFetchResultEx>> {
  const subscription: Observable<SubscribeToClientNotificationEmailByIdSubscriptionFetchResult> = apolloClient.subscribe<SubscribeToClientNotificationEmailByIdSubscription>(
    { query: SubscribeToClientNotificationEmailByIdDocument, variables: { clientNotificationEmailId }, ...options }
  );

  return subscription.map((value) => {
    return {
      context: value.context,
      errors: value.errors,
      data: value.data,
      extensions: value.extensions,
      clientNotificationEmail: value?.data?.clientNotificationEmail_by_pk || [],
    } as SubscribeToClientNotificationEmailByIdSubscriptionFetchResultEx;
  });
}

// Subscription Fetch Objects Helper
//
export type SubscribeToClientNotificationEmailObjectsSubscriptionFetchResult = FetchResult<
  SubscribeToClientNotificationEmailObjectsSubscription,
  Record<string, any>,
  Record<string, any>
>;
export type SubscribeToClientNotificationEmailObjectsSubscriptionFetchResultEx = FetchResult<
  SubscribeToClientNotificationEmailObjectsSubscription,
  Record<string, any>,
  Record<string, any>
> &
  ClientNotificationEmailObjectsHelperResultEx;

async function subscribeToClientNotificationEmailObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options?: Omit<SubscriptionOptions<SubscribeToClientNotificationEmailObjectsSubscriptionVariables>, 'query'>;
}): Promise<Observable<SubscribeToClientNotificationEmailObjectsSubscriptionFetchResultEx>> {
  const subscription: Observable<SubscribeToClientNotificationEmailObjectsSubscriptionFetchResult> = apolloClient.subscribe<SubscribeToClientNotificationEmailObjectsSubscription>(
    { query: SubscribeToClientNotificationEmailObjectsDocument, ...options }
  );

  return subscription.map((value) => {
    return {
      context: value.context,
      errors: value.errors,
      data: value.data,
      extensions: value.extensions,
      objects: value?.data?.clientNotificationEmail || [],
    } as SubscribeToClientNotificationEmailObjectsSubscriptionFetchResultEx;
  });
}

// Insert Helper
//
type InsertClientNotificationEmailFetchResult = FetchResult<
  InsertClientNotificationEmailMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertClientNotificationEmailFetchHelperResultEx = InsertClientNotificationEmailFetchResult &
  ClientNotificationEmailByIdHelperResultEx;

async function insertClientNotificationEmail({
  apolloClient,
  clientNotificationEmail,
  autoOptimisticResponse,
  fieldMap,
  options,
}: {
  apolloClient: ApolloClient<object>;
  clientNotificationEmail: ClientNotificationEmail_Insert_Input;
  autoOptimisticResponse?: boolean;
  fieldMap?: FieldMap;
  options?: Omit<
    MutationOptions<InsertClientNotificationEmailMutation, InsertClientNotificationEmailMutationVariables>,
    'mutation' | 'variables'
  >;
}): Promise<InsertClientNotificationEmailFetchHelperResultEx> {
  const objectForInsert = stripInsertInputClientFields({ input: clientNotificationEmail });
  const mutationOptions: MutationOptions<
    InsertClientNotificationEmailMutation,
    InsertClientNotificationEmailMutationVariables
  > = { mutation: InsertClientNotificationEmailDocument, variables: { objects: [objectForInsert] }, ...options };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    if (!objectForInsert.id)
      throw new Error(`if autoOptimisticResponse = true, id must be set in object 'clientNotificationEmail'`);
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertClientNotificationEmailMutation>({
      operationType: 'insert',
      entityName: 'clientNotificationEmail',
      objects: [objectForInsert as ClientNotificationEmail_Insert_Input & ObjectWithId],
      fieldMap,
    });
    if (logLevel >= 2)
      console.log(' --> insertClientNotificationEmail - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: InsertClientNotificationEmailFetchResult = await apolloClient.mutate<
    InsertClientNotificationEmailMutation,
    InsertClientNotificationEmailMutationVariables
  >(mutationOptions);

  return {
    ...mutation,
    clientNotificationEmail:
      mutation?.data?.insert_clientNotificationEmail?.returning &&
      mutation.data.insert_clientNotificationEmail.returning[0],
  };
}

async function insertClientNotificationEmailWithOnConflict({
  apolloClient,
  clientNotificationEmail,
  onConflict,
  autoOptimisticResponse,
  fieldMap,
  options,
}: {
  apolloClient: ApolloClient<object>;
  clientNotificationEmail: ClientNotificationEmail_Insert_Input;
  onConflict: ClientNotificationEmail_On_Conflict;
  autoOptimisticResponse?: boolean;
  fieldMap?: FieldMap;
  options?: Omit<
    MutationOptions<
      InsertClientNotificationEmailWithOnConflictMutation,
      InsertClientNotificationEmailWithOnConflictMutationVariables
    >,
    'mutation' | 'variables'
  >;
}): Promise<InsertClientNotificationEmailFetchHelperResultEx> {
  const objectForInsert = stripInsertInputClientFields({ input: clientNotificationEmail });
  const mutationOptions: MutationOptions<
    InsertClientNotificationEmailWithOnConflictMutation,
    InsertClientNotificationEmailWithOnConflictMutationVariables
  > = {
    mutation: InsertClientNotificationEmailWithOnConflictDocument,
    variables: { objects: [objectForInsert], onConflict },
    ...options,
  };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    if (!objectForInsert.id)
      throw new Error(`if autoOptimisticResponse = true, id must be set in object 'clientNotificationEmail'`);
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertClientNotificationEmailWithOnConflictMutation>(
      {
        operationType: 'insert',
        entityName: 'clientNotificationEmail',
        objects: [objectForInsert as ClientNotificationEmail_Insert_Input & ObjectWithId],
        fieldMap,
      }
    );
    if (logLevel >= 2)
      console.log(
        ' --> insertClientNotificationEmailWithOnConflict - optimisticResponse:',
        mutationOptions.optimisticResponse
      );
  }

  const mutation: InsertClientNotificationEmailFetchResult = await apolloClient.mutate<
    InsertClientNotificationEmailWithOnConflictMutation,
    InsertClientNotificationEmailWithOnConflictMutationVariables
  >(mutationOptions);

  return {
    ...mutation,
    clientNotificationEmail:
      mutation?.data?.insert_clientNotificationEmail?.returning &&
      mutation.data.insert_clientNotificationEmail.returning[0],
  };
}

// Insert Objects Helper
//
type InsertClientNotificationEmailObjectsFetchResult = FetchResult<
  InsertClientNotificationEmailMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertClientNotificationEmailObjectsHelperResultEx = InsertClientNotificationEmailObjectsFetchResult &
  ClientNotificationEmailObjectsHelperResultEx;

async function insertClientNotificationEmailObjects({
  apolloClient,
  objects,
  autoOptimisticResponse,
  fieldMap,
  options,
}: {
  apolloClient: ApolloClient<object>;
  objects: ClientNotificationEmail_Insert_Input[];
  autoOptimisticResponse?: boolean;
  fieldMap?: FieldMap;
  options?: Omit<
    MutationOptions<InsertClientNotificationEmailMutation, InsertClientNotificationEmailMutationVariables>,
    'mutation' | 'variables'
  >;
}): Promise<InsertClientNotificationEmailObjectsHelperResultEx> {
  const objectsForInsert = objects.map((objectItem) => stripInsertInputClientFields({ input: objectItem }));
  const mutationOptions: MutationOptions<
    InsertClientNotificationEmailMutation,
    InsertClientNotificationEmailMutationVariables
  > = { mutation: InsertClientNotificationEmailDocument, variables: { objects: objectsForInsert }, ...options };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    if (objectsForInsert.find((objectItem) => !objectItem.id))
      throw new Error(`if autoOptimisticResponse = true, ids must be set in objects`);
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertClientNotificationEmailMutation>({
      operationType: 'insert',
      entityName: 'clientNotificationEmail',
      objects: objectsForInsert as (ClientNotificationEmail_Insert_Input & ObjectWithId)[],
      fieldMap,
    });
    if (logLevel >= 2)
      console.log(' --> insertClientNotificationEmail - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: InsertClientNotificationEmailObjectsFetchResult = await apolloClient.mutate<
    InsertClientNotificationEmailMutation,
    InsertClientNotificationEmailMutationVariables
  >(mutationOptions);

  return { ...mutation, objects: mutation?.data?.insert_clientNotificationEmail?.returning || [] };
}

// Update Helper
//
type UpdateClientNotificationEmailByIdQueryResult = FetchResult<
  UpdateClientNotificationEmailByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateClientNotificationEmailByIdHelperResultEx = UpdateClientNotificationEmailByIdQueryResult &
  ClientNotificationEmailByIdHelperResultEx;

async function updateClientNotificationEmailById({
  apolloClient,
  clientNotificationEmailId,
  set,
  autoOptimisticResponse,
  options,
}: {
  apolloClient: ApolloClient<object>;
  clientNotificationEmailId: string;
  set: ClientNotificationEmail_Set_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationOptions<UpdateClientNotificationEmailByIdMutation, UpdateClientNotificationEmailByIdMutationVariables>,
    'mutation'
  >;
}): Promise<UpdateClientNotificationEmailByIdHelperResultEx> {
  const mutationOptions: MutationOptions<
    UpdateClientNotificationEmailByIdMutation,
    UpdateClientNotificationEmailByIdMutationVariables
  > = {
    mutation: UpdateClientNotificationEmailByIdDocument,
    variables: { id: clientNotificationEmailId, set },
    ...options,
  };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateClientNotificationEmailByIdMutation>(
      {
        operationType: 'update',
        entityName: 'clientNotificationEmail',
        objects: [{ id: clientNotificationEmailId, ...set }],
      }
    );
    if (logLevel >= 2)
      console.log(' --> updateClientNotificationEmailById - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: UpdateClientNotificationEmailByIdQueryResult = await apolloClient.mutate<
    UpdateClientNotificationEmailByIdMutation,
    UpdateClientNotificationEmailByIdMutationVariables
  >(mutationOptions);

  return {
    ...mutation,
    clientNotificationEmail:
      mutation?.data?.update_clientNotificationEmail?.returning &&
      mutation.data.update_clientNotificationEmail.returning[0],
  };
}

// Update Objects Helper
//
type UpdateClientNotificationEmailObjectsFetchResult = FetchResult<
  UpdateClientNotificationEmailMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateClientNotificationEmailObjectsHelperResultEx = UpdateClientNotificationEmailObjectsFetchResult &
  ClientNotificationEmailObjectsHelperResultEx;

async function updateClientNotificationEmailObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<
    MutationOptions<UpdateClientNotificationEmailMutation, UpdateClientNotificationEmailMutationVariables>,
    'mutation'
  >;
}): Promise<UpdateClientNotificationEmailObjectsHelperResultEx> {
  const mutation: UpdateClientNotificationEmailObjectsFetchResult = await apolloClient.mutate<
    UpdateClientNotificationEmailMutation,
    UpdateClientNotificationEmailMutationVariables
  >({ mutation: UpdateClientNotificationEmailDocument, ...options });

  return { ...mutation, objects: mutation?.data?.update_clientNotificationEmail?.returning || [] };
}

// Delete Helper
//

type RemoveClientNotificationEmailModelByIdQueryResult = FetchResult<
  RemoveClientNotificationEmailModelByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveClientNotificationEmailModelByIdQueryHelperResultEx = RemoveClientNotificationEmailModelByIdQueryResult &
  RemoveEntitiesQueryHelperResultEx;

async function removeClientNotificationEmailModelById({
  apolloClient,
  clientNotificationEmailId,
  autoOptimisticResponse,
  options,
}: {
  apolloClient: ApolloClient<object>;
  clientNotificationEmailId: string;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationOptions<
      RemoveClientNotificationEmailModelByIdMutation,
      RemoveClientNotificationEmailModelByIdMutationVariables
    >,
    'mutation'
  >;
}): Promise<RemoveClientNotificationEmailModelByIdQueryHelperResultEx> {
  const mutationOptions: MutationOptions<
    RemoveClientNotificationEmailModelByIdMutation,
    RemoveClientNotificationEmailModelByIdMutationVariables
  > = {
    mutation: RemoveClientNotificationEmailModelByIdDocument,
    variables: { id: clientNotificationEmailId },
    ...options,
  };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<RemoveClientNotificationEmailModelByIdMutation>(
      { operationType: 'delete', entityName: 'clientNotificationEmail', objects: [{ id: clientNotificationEmailId }] }
    );
    if (logLevel >= 2)
      console.log(
        ' --> removeClientNotificationEmailModelById - optimisticResponse:',
        mutationOptions.optimisticResponse
      );
  }
  if (!options || !options.update) {
    mutationOptions.update = generateUpdateFunctionForMutation<RemoveClientNotificationEmailModelByIdMutation>({
      operationType: 'delete',
      entityName: 'clientNotificationEmail',
      entityId: clientNotificationEmailId,
    });
  }

  const mutation: RemoveClientNotificationEmailModelByIdQueryResult = await apolloClient.mutate<
    RemoveClientNotificationEmailModelByIdMutation,
    RemoveClientNotificationEmailModelByIdMutationVariables
  >(mutationOptions);

  return { ...mutation, affected_rows: mutation?.data?.delete_clientNotificationEmail?.affected_rows || 0 };
}

type RemoveClientNotificationEmailModelObjectsQueryResult = FetchResult<
  RemoveClientNotificationEmailModelMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveClientNotificationEmailModelObjectsQueryHelperResultEx = RemoveClientNotificationEmailModelObjectsQueryResult &
  RemoveEntitiesQueryHelperResultEx;

async function removeClientNotificationEmailModelObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<
    MutationOptions<RemoveClientNotificationEmailModelMutation, RemoveClientNotificationEmailModelMutationVariables>,
    'mutation'
  >;
}): Promise<RemoveClientNotificationEmailModelObjectsQueryHelperResultEx> {
  const mutation: RemoveClientNotificationEmailModelObjectsQueryResult = await apolloClient.mutate<
    RemoveClientNotificationEmailModelMutation,
    RemoveClientNotificationEmailModelMutationVariables
  >({ mutation: RemoveClientNotificationEmailModelDocument, ...options });

  return { ...mutation, affected_rows: mutation?.data?.delete_clientNotificationEmail?.affected_rows || 0 };
}

// ClientNotificationEmail Fragment Helper Object
//------------------------------------------------

export const ClientNotificationEmailFragmentGQLHelper = {
  cacheGetDataId: cacheGetDataIdForClientNotificationEmail,
  cacheReadFragmentById: cacheReadFragmentClientNotificationEmailById,
  cacheWriteFragmentById: cacheWriteFragmentClientNotificationEmailById,
  cacheReadQueryById: cacheReadQueryClientNotificationEmailById,
  cacheWriteQueryById: cacheWriteQueryClientNotificationEmailById,
  cacheReadQueryObjects: cacheReadQueryClientNotificationEmailObjects,
  cacheWriteQueryObjects: cacheWriteQueryClientNotificationEmailObjects,
  cacheWriteQueryInsert: cacheWriteQueryClientNotificationEmailInsert,
  cacheWriteQueryRemove: cacheWriteQueryClientNotificationEmailRemove,
  queryById: queryClientNotificationEmailById,
  queryObjects: queryClientNotificationEmailObjects,
  watchQueryById: watchQueryClientNotificationEmailById,
  watchQueryObjects: watchQueryClientNotificationEmailObjects,
  subscribeToById: subscribeToClientNotificationEmailById,
  subscribeToObjects: subscribeToClientNotificationEmailObjects,
  insert: insertClientNotificationEmail,
  insertWithOnConflict: insertClientNotificationEmailWithOnConflict,
  insertObjects: insertClientNotificationEmailObjects,
  updateById: updateClientNotificationEmailById,
  updateObjects: updateClientNotificationEmailObjects,
};

// clientNotificationEmail Entity Helper Object
//------------------------------------------------

export const ClientNotificationEmailModelGQLHelper = {
  removeById: removeClientNotificationEmailModelById,
  removeObjects: removeClientNotificationEmailModelObjects,
};

// cloudTask HELPERS
//------------------------------------------------

export type CloudTaskByIdHelperResultEx = { cloudTask: CloudTaskFragment | null | undefined };
export type CloudTaskObjectsHelperResultEx = { objects: CloudTaskFragment[] };

// Direct Client & Cache Fragment Helpers
//
function cacheGetDataIdForCloudTask(cloudTaskId: string): string {
  return defaultCacheIdFromObject({ __typename: 'cloudTask', id: cloudTaskId });
}

function cacheReadFragmentCloudTaskById({
  apolloCache,
  cloudTaskId,
}: {
  apolloCache: ApolloCache<object>;
  cloudTaskId: string;
}): CloudTaskFragment | null | undefined {
  return apolloCache.readFragment<CloudTaskFragment | null | undefined>({
    fragment: CloudTaskFragmentDoc,
    fragmentName: 'CloudTask',
    id: defaultCacheIdFromObject({ __typename: 'cloudTask', id: cloudTaskId }),
  });
}

function cacheWriteFragmentCloudTaskById({
  apolloCache,
  cloudTaskId,
  cloudTaskPartial,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  cloudTaskId: string;
  cloudTaskPartial: Partial<CloudTaskFragment> | CloudTask_Insert_Input | null;
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): Partial<CloudTaskFragment> {
  const parsedFragment = convertToGraph({ input: cloudTaskPartial, typename: 'cloudTask', fieldMap });
  if (logLevel >= 2) console.log(' --> cacheWriteFragmentCloudTaskById - parsedFragment:', parsedFragment);
  apolloCache.writeFragment<Partial<CloudTaskFragment> | null>({
    fragment: CloudTaskFragmentDoc,
    fragmentName: 'CloudTask',
    id: defaultCacheIdFromObject({ ...parsedFragment, id: cloudTaskId }),
    data: parsedFragment,
    broadcast: apolloBroadcast,
  });
  return parsedFragment;
}

function cacheReadQueryCloudTaskById({
  apolloCache,
  cloudTaskId,
}: {
  apolloCache: ApolloCache<object>;
  cloudTaskId: string;
}): CloudTaskFragment | null | undefined {
  try {
    return apolloCache.readQuery<CloudTaskFragment | null>({
      query: QueryCloudTaskByIdDocument,
      variables: { cloudTaskId },
    });
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheReadQueryCloudTaskById threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheWriteQueryCloudTaskById({
  apolloCache,
  cloudTaskId,
  cloudTask,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  cloudTaskId: string;
  cloudTask: CloudTaskFragment | CloudTask_Insert_Input | null;
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): void {
  try {
    const cloudTaskPartial = convertToGraph({ input: cloudTask, typename: 'cloudTask', fieldMap });
    return apolloCache.writeQuery<CloudTaskFragment | null>({
      query: QueryCloudTaskByIdDocument,
      variables: { cloudTaskId },
      data: cloudTask ? cloudTaskPartial : null,
      broadcast: apolloBroadcast,
    });
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheWriteQueryCloudTaskById threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheReadQueryCloudTaskObjects({
  apolloCache,
  variables,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryCloudTaskObjectsQueryVariables;
}): CloudTask[] | null | undefined {
  try {
    return (
      apolloCache.readQuery<{ CloudTask: CloudTask[] | null }>({ query: QueryCloudTaskObjectsDocument, variables })
        ?.CloudTask || []
    );
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheReadQueryCloudTaskObjects threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheWriteQueryCloudTaskObjects({
  apolloCache,
  variables,
  data,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryCloudTaskObjectsQueryVariables;
  data: (CloudTask | CloudTask_Insert_Input)[];
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): void {
  try {
    const objects = convertToGraph({ input: data, typename: 'cloudTask', fieldMap });
    return apolloCache.writeQuery<{ CloudTask: CloudTask[] }>({
      query: QueryCloudTaskObjectsDocument,
      variables,
      data: { CloudTask: objects },
      broadcast: apolloBroadcast,
    });
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheWriteQueryCloudTaskObjects threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheWriteQueryCloudTaskInsert({
  apolloCache,
  variables,
  cloudTask,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryCloudTaskObjectsQueryVariables;
  cloudTask: CloudTask_Insert_Input;
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): void {
  const currentObjects = cacheReadQueryCloudTaskObjects({ apolloCache, variables }) || [];
  const objectsWithInserted = [
    ...currentObjects,
    convertToGraph({ input: cloudTask, typename: 'cloudTask', fieldMap }),
  ];
  if (logLevel >= 2) console.log(' --> cacheWriteQueryCloudTaskInsert - objectsWithInserted:', objectsWithInserted);
  return cacheWriteQueryCloudTaskObjects({ apolloCache, variables, data: objectsWithInserted, apolloBroadcast });
}

function cacheWriteQueryCloudTaskRemove({
  apolloCache,
  variables,
  cloudTaskId,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryCloudTaskObjectsQueryVariables;
  cloudTaskId: string;
  apolloBroadcast?: boolean;
}): void {
  const currentObjects = cacheReadQueryCloudTaskObjects({ apolloCache, variables }) || [];
  const objectsWithRemoved = currentObjects.filter((objectItem) => objectItem.id !== cloudTaskId) || [];
  if (logLevel >= 2) console.log(' --> cacheWriteQueryCloudTaskRemove - objectsWithRemoved:', objectsWithRemoved);
  return cacheWriteQueryCloudTaskObjects({ apolloCache, variables, data: objectsWithRemoved, apolloBroadcast });
}

// Query Fetch Helper
//
export type QueryCloudTaskByIdApolloQueryResult = ApolloQueryResult<QueryCloudTaskByIdQuery>;
export type QueryCloudTaskByIdApolloQueryHelperResultEx = QueryCloudTaskByIdApolloQueryResult &
  CloudTaskByIdHelperResultEx;

async function queryCloudTaskById({
  apolloClient,
  cloudTaskId,
  options,
}: {
  apolloClient: ApolloClient<object>;
  cloudTaskId: string;
  options?: Omit<QueryOptions<QueryCloudTaskByIdQueryVariables>, 'query' | 'variables'>;
}): Promise<QueryCloudTaskByIdApolloQueryHelperResultEx> {
  const query: QueryCloudTaskByIdApolloQueryResult = await apolloClient.query<QueryCloudTaskByIdQuery>({
    query: QueryCloudTaskByIdDocument,
    variables: { cloudTaskId },
    ...options,
  });

  return { ...query, cloudTask: query?.data?.cloudTask_by_pk };
}

// Query Watch ById Helper
//
export type WatchQueryCloudTaskByIdApolloObservableQuery = ObservableQuery<QueryCloudTaskByIdQuery>;
async function watchQueryCloudTaskById({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<QueryOptions<QueryCloudTaskByIdQueryVariables>, 'query'>;
}): Promise<WatchQueryCloudTaskByIdApolloObservableQuery> {
  return apolloClient.watchQuery<QueryCloudTaskByIdQuery>({ query: QueryCloudTaskByIdDocument, ...options });
}

// Query Fetch Objects Helper
//
export type QueryCloudTaskObjectsObjectsApolloQueryResult = ApolloQueryResult<QueryCloudTaskObjectsQuery>;
export type QueryCloudTaskObjectsObjectsApolloQueryResultEx = QueryCloudTaskObjectsObjectsApolloQueryResult &
  CloudTaskObjectsHelperResultEx;

async function queryCloudTaskObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<QueryOptions<QueryCloudTaskObjectsQueryVariables>, 'query'>;
}): Promise<QueryCloudTaskObjectsObjectsApolloQueryResultEx> {
  const query: QueryCloudTaskObjectsObjectsApolloQueryResult = await apolloClient.query<QueryCloudTaskObjectsQuery>({
    query: QueryCloudTaskObjectsDocument,
    ...options,
  });

  return { ...query, objects: query?.data?.cloudTask || [] };
}

// Query Watch Objects Helper
//
export type WatchQueryCloudTaskObjectsApolloObservableQuery = ObservableQuery<QueryCloudTaskObjectsQuery>;
async function watchQueryCloudTaskObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<QueryOptions<QueryCloudTaskObjectsQueryVariables>, 'query'>;
}): Promise<WatchQueryCloudTaskObjectsApolloObservableQuery> {
  return apolloClient.watchQuery<QueryCloudTaskObjectsQuery>({ query: QueryCloudTaskObjectsDocument, ...options });
}

// Subscription Fetch ById Helper
//
export type SubscribeToCloudTaskByIdSubscriptionFetchResult = FetchResult<
  SubscribeToCloudTaskByIdSubscription,
  Record<string, any>,
  Record<string, any>
>;
export type SubscribeToCloudTaskByIdSubscriptionFetchResultEx = FetchResult<
  SubscribeToCloudTaskByIdSubscription,
  Record<string, any>,
  Record<string, any>
> &
  CloudTaskByIdHelperResultEx;

async function subscribeToCloudTaskById({
  apolloClient,
  cloudTaskId,
  options,
}: {
  apolloClient: ApolloClient<object>;
  cloudTaskId: string;
  options?: Omit<SubscriptionOptions<SubscribeToCloudTaskByIdSubscriptionVariables>, 'query' | 'variables'>;
}): Promise<Observable<SubscribeToCloudTaskByIdSubscriptionFetchResultEx>> {
  const subscription: Observable<SubscribeToCloudTaskByIdSubscriptionFetchResult> = apolloClient.subscribe<SubscribeToCloudTaskByIdSubscription>(
    { query: SubscribeToCloudTaskByIdDocument, variables: { cloudTaskId }, ...options }
  );

  return subscription.map((value) => {
    return {
      context: value.context,
      errors: value.errors,
      data: value.data,
      extensions: value.extensions,
      cloudTask: value?.data?.cloudTask_by_pk || [],
    } as SubscribeToCloudTaskByIdSubscriptionFetchResultEx;
  });
}

// Subscription Fetch Objects Helper
//
export type SubscribeToCloudTaskObjectsSubscriptionFetchResult = FetchResult<
  SubscribeToCloudTaskObjectsSubscription,
  Record<string, any>,
  Record<string, any>
>;
export type SubscribeToCloudTaskObjectsSubscriptionFetchResultEx = FetchResult<
  SubscribeToCloudTaskObjectsSubscription,
  Record<string, any>,
  Record<string, any>
> &
  CloudTaskObjectsHelperResultEx;

async function subscribeToCloudTaskObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options?: Omit<SubscriptionOptions<SubscribeToCloudTaskObjectsSubscriptionVariables>, 'query'>;
}): Promise<Observable<SubscribeToCloudTaskObjectsSubscriptionFetchResultEx>> {
  const subscription: Observable<SubscribeToCloudTaskObjectsSubscriptionFetchResult> = apolloClient.subscribe<SubscribeToCloudTaskObjectsSubscription>(
    { query: SubscribeToCloudTaskObjectsDocument, ...options }
  );

  return subscription.map((value) => {
    return {
      context: value.context,
      errors: value.errors,
      data: value.data,
      extensions: value.extensions,
      objects: value?.data?.cloudTask || [],
    } as SubscribeToCloudTaskObjectsSubscriptionFetchResultEx;
  });
}

// Insert Helper
//
type InsertCloudTaskFetchResult = FetchResult<InsertCloudTaskMutation, Record<string, any>, Record<string, any>>;
export type InsertCloudTaskFetchHelperResultEx = InsertCloudTaskFetchResult & CloudTaskByIdHelperResultEx;

async function insertCloudTask({
  apolloClient,
  cloudTask,
  autoOptimisticResponse,
  fieldMap,
  options,
}: {
  apolloClient: ApolloClient<object>;
  cloudTask: CloudTask_Insert_Input;
  autoOptimisticResponse?: boolean;
  fieldMap?: FieldMap;
  options?: Omit<MutationOptions<InsertCloudTaskMutation, InsertCloudTaskMutationVariables>, 'mutation' | 'variables'>;
}): Promise<InsertCloudTaskFetchHelperResultEx> {
  const objectForInsert = stripInsertInputClientFields({ input: cloudTask });
  const mutationOptions: MutationOptions<InsertCloudTaskMutation, InsertCloudTaskMutationVariables> = {
    mutation: InsertCloudTaskDocument,
    variables: { objects: [objectForInsert] },
    ...options,
  };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    if (!objectForInsert.id) throw new Error(`if autoOptimisticResponse = true, id must be set in object 'cloudTask'`);
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertCloudTaskMutation>({
      operationType: 'insert',
      entityName: 'cloudTask',
      objects: [objectForInsert as CloudTask_Insert_Input & ObjectWithId],
      fieldMap,
    });
    if (logLevel >= 2) console.log(' --> insertCloudTask - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: InsertCloudTaskFetchResult = await apolloClient.mutate<
    InsertCloudTaskMutation,
    InsertCloudTaskMutationVariables
  >(mutationOptions);

  return {
    ...mutation,
    cloudTask: mutation?.data?.insert_cloudTask?.returning && mutation.data.insert_cloudTask.returning[0],
  };
}

async function insertCloudTaskWithOnConflict({
  apolloClient,
  cloudTask,
  onConflict,
  autoOptimisticResponse,
  fieldMap,
  options,
}: {
  apolloClient: ApolloClient<object>;
  cloudTask: CloudTask_Insert_Input;
  onConflict: CloudTask_On_Conflict;
  autoOptimisticResponse?: boolean;
  fieldMap?: FieldMap;
  options?: Omit<
    MutationOptions<InsertCloudTaskWithOnConflictMutation, InsertCloudTaskWithOnConflictMutationVariables>,
    'mutation' | 'variables'
  >;
}): Promise<InsertCloudTaskFetchHelperResultEx> {
  const objectForInsert = stripInsertInputClientFields({ input: cloudTask });
  const mutationOptions: MutationOptions<
    InsertCloudTaskWithOnConflictMutation,
    InsertCloudTaskWithOnConflictMutationVariables
  > = {
    mutation: InsertCloudTaskWithOnConflictDocument,
    variables: { objects: [objectForInsert], onConflict },
    ...options,
  };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    if (!objectForInsert.id) throw new Error(`if autoOptimisticResponse = true, id must be set in object 'cloudTask'`);
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertCloudTaskWithOnConflictMutation>({
      operationType: 'insert',
      entityName: 'cloudTask',
      objects: [objectForInsert as CloudTask_Insert_Input & ObjectWithId],
      fieldMap,
    });
    if (logLevel >= 2)
      console.log(' --> insertCloudTaskWithOnConflict - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: InsertCloudTaskFetchResult = await apolloClient.mutate<
    InsertCloudTaskWithOnConflictMutation,
    InsertCloudTaskWithOnConflictMutationVariables
  >(mutationOptions);

  return {
    ...mutation,
    cloudTask: mutation?.data?.insert_cloudTask?.returning && mutation.data.insert_cloudTask.returning[0],
  };
}

// Insert Objects Helper
//
type InsertCloudTaskObjectsFetchResult = FetchResult<InsertCloudTaskMutation, Record<string, any>, Record<string, any>>;
export type InsertCloudTaskObjectsHelperResultEx = InsertCloudTaskObjectsFetchResult & CloudTaskObjectsHelperResultEx;

async function insertCloudTaskObjects({
  apolloClient,
  objects,
  autoOptimisticResponse,
  fieldMap,
  options,
}: {
  apolloClient: ApolloClient<object>;
  objects: CloudTask_Insert_Input[];
  autoOptimisticResponse?: boolean;
  fieldMap?: FieldMap;
  options?: Omit<MutationOptions<InsertCloudTaskMutation, InsertCloudTaskMutationVariables>, 'mutation' | 'variables'>;
}): Promise<InsertCloudTaskObjectsHelperResultEx> {
  const objectsForInsert = objects.map((objectItem) => stripInsertInputClientFields({ input: objectItem }));
  const mutationOptions: MutationOptions<InsertCloudTaskMutation, InsertCloudTaskMutationVariables> = {
    mutation: InsertCloudTaskDocument,
    variables: { objects: objectsForInsert },
    ...options,
  };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    if (objectsForInsert.find((objectItem) => !objectItem.id))
      throw new Error(`if autoOptimisticResponse = true, ids must be set in objects`);
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertCloudTaskMutation>({
      operationType: 'insert',
      entityName: 'cloudTask',
      objects: objectsForInsert as (CloudTask_Insert_Input & ObjectWithId)[],
      fieldMap,
    });
    if (logLevel >= 2) console.log(' --> insertCloudTask - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: InsertCloudTaskObjectsFetchResult = await apolloClient.mutate<
    InsertCloudTaskMutation,
    InsertCloudTaskMutationVariables
  >(mutationOptions);

  return { ...mutation, objects: mutation?.data?.insert_cloudTask?.returning || [] };
}

// Update Helper
//
type UpdateCloudTaskByIdQueryResult = FetchResult<
  UpdateCloudTaskByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateCloudTaskByIdHelperResultEx = UpdateCloudTaskByIdQueryResult & CloudTaskByIdHelperResultEx;

async function updateCloudTaskById({
  apolloClient,
  cloudTaskId,
  set,
  autoOptimisticResponse,
  options,
}: {
  apolloClient: ApolloClient<object>;
  cloudTaskId: string;
  set: CloudTask_Set_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<MutationOptions<UpdateCloudTaskByIdMutation, UpdateCloudTaskByIdMutationVariables>, 'mutation'>;
}): Promise<UpdateCloudTaskByIdHelperResultEx> {
  const mutationOptions: MutationOptions<UpdateCloudTaskByIdMutation, UpdateCloudTaskByIdMutationVariables> = {
    mutation: UpdateCloudTaskByIdDocument,
    variables: { id: cloudTaskId, set },
    ...options,
  };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateCloudTaskByIdMutation>({
      operationType: 'update',
      entityName: 'cloudTask',
      objects: [{ id: cloudTaskId, ...set }],
    });
    if (logLevel >= 2)
      console.log(' --> updateCloudTaskById - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: UpdateCloudTaskByIdQueryResult = await apolloClient.mutate<
    UpdateCloudTaskByIdMutation,
    UpdateCloudTaskByIdMutationVariables
  >(mutationOptions);

  return {
    ...mutation,
    cloudTask: mutation?.data?.update_cloudTask?.returning && mutation.data.update_cloudTask.returning[0],
  };
}

// Update Objects Helper
//
type UpdateCloudTaskObjectsFetchResult = FetchResult<UpdateCloudTaskMutation, Record<string, any>, Record<string, any>>;
export type UpdateCloudTaskObjectsHelperResultEx = UpdateCloudTaskObjectsFetchResult & CloudTaskObjectsHelperResultEx;

async function updateCloudTaskObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<MutationOptions<UpdateCloudTaskMutation, UpdateCloudTaskMutationVariables>, 'mutation'>;
}): Promise<UpdateCloudTaskObjectsHelperResultEx> {
  const mutation: UpdateCloudTaskObjectsFetchResult = await apolloClient.mutate<
    UpdateCloudTaskMutation,
    UpdateCloudTaskMutationVariables
  >({ mutation: UpdateCloudTaskDocument, ...options });

  return { ...mutation, objects: mutation?.data?.update_cloudTask?.returning || [] };
}

// Delete Helper
//

type RemoveCloudTaskModelByIdQueryResult = FetchResult<
  RemoveCloudTaskModelByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveCloudTaskModelByIdQueryHelperResultEx = RemoveCloudTaskModelByIdQueryResult &
  RemoveEntitiesQueryHelperResultEx;

async function removeCloudTaskModelById({
  apolloClient,
  cloudTaskId,
  autoOptimisticResponse,
  options,
}: {
  apolloClient: ApolloClient<object>;
  cloudTaskId: string;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationOptions<RemoveCloudTaskModelByIdMutation, RemoveCloudTaskModelByIdMutationVariables>,
    'mutation'
  >;
}): Promise<RemoveCloudTaskModelByIdQueryHelperResultEx> {
  const mutationOptions: MutationOptions<
    RemoveCloudTaskModelByIdMutation,
    RemoveCloudTaskModelByIdMutationVariables
  > = { mutation: RemoveCloudTaskModelByIdDocument, variables: { id: cloudTaskId }, ...options };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<RemoveCloudTaskModelByIdMutation>({
      operationType: 'delete',
      entityName: 'cloudTask',
      objects: [{ id: cloudTaskId }],
    });
    if (logLevel >= 2)
      console.log(' --> removeCloudTaskModelById - optimisticResponse:', mutationOptions.optimisticResponse);
  }
  if (!options || !options.update) {
    mutationOptions.update = generateUpdateFunctionForMutation<RemoveCloudTaskModelByIdMutation>({
      operationType: 'delete',
      entityName: 'cloudTask',
      entityId: cloudTaskId,
    });
  }

  const mutation: RemoveCloudTaskModelByIdQueryResult = await apolloClient.mutate<
    RemoveCloudTaskModelByIdMutation,
    RemoveCloudTaskModelByIdMutationVariables
  >(mutationOptions);

  return { ...mutation, affected_rows: mutation?.data?.delete_cloudTask?.affected_rows || 0 };
}

type RemoveCloudTaskModelObjectsQueryResult = FetchResult<
  RemoveCloudTaskModelMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveCloudTaskModelObjectsQueryHelperResultEx = RemoveCloudTaskModelObjectsQueryResult &
  RemoveEntitiesQueryHelperResultEx;

async function removeCloudTaskModelObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<MutationOptions<RemoveCloudTaskModelMutation, RemoveCloudTaskModelMutationVariables>, 'mutation'>;
}): Promise<RemoveCloudTaskModelObjectsQueryHelperResultEx> {
  const mutation: RemoveCloudTaskModelObjectsQueryResult = await apolloClient.mutate<
    RemoveCloudTaskModelMutation,
    RemoveCloudTaskModelMutationVariables
  >({ mutation: RemoveCloudTaskModelDocument, ...options });

  return { ...mutation, affected_rows: mutation?.data?.delete_cloudTask?.affected_rows || 0 };
}

// CloudTask Fragment Helper Object
//------------------------------------------------

export const CloudTaskFragmentGQLHelper = {
  cacheGetDataId: cacheGetDataIdForCloudTask,
  cacheReadFragmentById: cacheReadFragmentCloudTaskById,
  cacheWriteFragmentById: cacheWriteFragmentCloudTaskById,
  cacheReadQueryById: cacheReadQueryCloudTaskById,
  cacheWriteQueryById: cacheWriteQueryCloudTaskById,
  cacheReadQueryObjects: cacheReadQueryCloudTaskObjects,
  cacheWriteQueryObjects: cacheWriteQueryCloudTaskObjects,
  cacheWriteQueryInsert: cacheWriteQueryCloudTaskInsert,
  cacheWriteQueryRemove: cacheWriteQueryCloudTaskRemove,
  queryById: queryCloudTaskById,
  queryObjects: queryCloudTaskObjects,
  watchQueryById: watchQueryCloudTaskById,
  watchQueryObjects: watchQueryCloudTaskObjects,
  subscribeToById: subscribeToCloudTaskById,
  subscribeToObjects: subscribeToCloudTaskObjects,
  insert: insertCloudTask,
  insertWithOnConflict: insertCloudTaskWithOnConflict,
  insertObjects: insertCloudTaskObjects,
  updateById: updateCloudTaskById,
  updateObjects: updateCloudTaskObjects,
};

// cloudTask Entity Helper Object
//------------------------------------------------

export const CloudTaskModelGQLHelper = {
  removeById: removeCloudTaskModelById,
  removeObjects: removeCloudTaskModelObjects,
};

// clientEmailReport HELPERS
//------------------------------------------------

export type ClientEmailReportByIdHelperResultEx = { clientEmailReport: ClientEmailReportFragment | null | undefined };
export type ClientEmailReportObjectsHelperResultEx = { objects: ClientEmailReportFragment[] };

// Direct Client & Cache Fragment Helpers
//
function cacheGetDataIdForClientEmailReport(clientEmailReportId: string): string {
  return defaultCacheIdFromObject({ __typename: 'clientEmailReport', id: clientEmailReportId });
}

function cacheReadFragmentClientEmailReportById({
  apolloCache,
  clientEmailReportId,
}: {
  apolloCache: ApolloCache<object>;
  clientEmailReportId: string;
}): ClientEmailReportFragment | null | undefined {
  return apolloCache.readFragment<ClientEmailReportFragment | null | undefined>({
    fragment: ClientEmailReportFragmentDoc,
    fragmentName: 'ClientEmailReport',
    id: defaultCacheIdFromObject({ __typename: 'clientEmailReport', id: clientEmailReportId }),
  });
}

function cacheWriteFragmentClientEmailReportById({
  apolloCache,
  clientEmailReportId,
  clientEmailReportPartial,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  clientEmailReportId: string;
  clientEmailReportPartial: Partial<ClientEmailReportFragment> | ClientEmailReport_Insert_Input | null;
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): Partial<ClientEmailReportFragment> {
  const parsedFragment = convertToGraph({ input: clientEmailReportPartial, typename: 'clientEmailReport', fieldMap });
  if (logLevel >= 2) console.log(' --> cacheWriteFragmentClientEmailReportById - parsedFragment:', parsedFragment);
  apolloCache.writeFragment<Partial<ClientEmailReportFragment> | null>({
    fragment: ClientEmailReportFragmentDoc,
    fragmentName: 'ClientEmailReport',
    id: defaultCacheIdFromObject({ ...parsedFragment, id: clientEmailReportId }),
    data: parsedFragment,
    broadcast: apolloBroadcast,
  });
  return parsedFragment;
}

function cacheReadQueryClientEmailReportById({
  apolloCache,
  clientEmailReportId,
}: {
  apolloCache: ApolloCache<object>;
  clientEmailReportId: string;
}): ClientEmailReportFragment | null | undefined {
  try {
    return apolloCache.readQuery<ClientEmailReportFragment | null>({
      query: QueryClientEmailReportByIdDocument,
      variables: { clientEmailReportId },
    });
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheReadQueryClientEmailReportById threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheWriteQueryClientEmailReportById({
  apolloCache,
  clientEmailReportId,
  clientEmailReport,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  clientEmailReportId: string;
  clientEmailReport: ClientEmailReportFragment | ClientEmailReport_Insert_Input | null;
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): void {
  try {
    const clientEmailReportPartial = convertToGraph({
      input: clientEmailReport,
      typename: 'clientEmailReport',
      fieldMap,
    });
    return apolloCache.writeQuery<ClientEmailReportFragment | null>({
      query: QueryClientEmailReportByIdDocument,
      variables: { clientEmailReportId },
      data: clientEmailReport ? clientEmailReportPartial : null,
      broadcast: apolloBroadcast,
    });
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheWriteQueryClientEmailReportById threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheReadQueryClientEmailReportObjects({
  apolloCache,
  variables,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryClientEmailReportObjectsQueryVariables;
}): ClientEmailReport[] | null | undefined {
  try {
    return (
      apolloCache.readQuery<{ ClientEmailReport: ClientEmailReport[] | null }>({
        query: QueryClientEmailReportObjectsDocument,
        variables,
      })?.ClientEmailReport || []
    );
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheReadQueryClientEmailReportObjects threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheWriteQueryClientEmailReportObjects({
  apolloCache,
  variables,
  data,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryClientEmailReportObjectsQueryVariables;
  data: (ClientEmailReport | ClientEmailReport_Insert_Input)[];
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): void {
  try {
    const objects = convertToGraph({ input: data, typename: 'clientEmailReport', fieldMap });
    return apolloCache.writeQuery<{ ClientEmailReport: ClientEmailReport[] }>({
      query: QueryClientEmailReportObjectsDocument,
      variables,
      data: { ClientEmailReport: objects },
      broadcast: apolloBroadcast,
    });
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheWriteQueryClientEmailReportObjects threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheWriteQueryClientEmailReportInsert({
  apolloCache,
  variables,
  clientEmailReport,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryClientEmailReportObjectsQueryVariables;
  clientEmailReport: ClientEmailReport_Insert_Input;
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): void {
  const currentObjects = cacheReadQueryClientEmailReportObjects({ apolloCache, variables }) || [];
  const objectsWithInserted = [
    ...currentObjects,
    convertToGraph({ input: clientEmailReport, typename: 'clientEmailReport', fieldMap }),
  ];
  if (logLevel >= 2)
    console.log(' --> cacheWriteQueryClientEmailReportInsert - objectsWithInserted:', objectsWithInserted);
  return cacheWriteQueryClientEmailReportObjects({
    apolloCache,
    variables,
    data: objectsWithInserted,
    apolloBroadcast,
  });
}

function cacheWriteQueryClientEmailReportRemove({
  apolloCache,
  variables,
  clientEmailReportId,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryClientEmailReportObjectsQueryVariables;
  clientEmailReportId: string;
  apolloBroadcast?: boolean;
}): void {
  const currentObjects = cacheReadQueryClientEmailReportObjects({ apolloCache, variables }) || [];
  const objectsWithRemoved = currentObjects.filter((objectItem) => objectItem.id !== clientEmailReportId) || [];
  if (logLevel >= 2)
    console.log(' --> cacheWriteQueryClientEmailReportRemove - objectsWithRemoved:', objectsWithRemoved);
  return cacheWriteQueryClientEmailReportObjects({ apolloCache, variables, data: objectsWithRemoved, apolloBroadcast });
}

// Query Fetch Helper
//
export type QueryClientEmailReportByIdApolloQueryResult = ApolloQueryResult<QueryClientEmailReportByIdQuery>;
export type QueryClientEmailReportByIdApolloQueryHelperResultEx = QueryClientEmailReportByIdApolloQueryResult &
  ClientEmailReportByIdHelperResultEx;

async function queryClientEmailReportById({
  apolloClient,
  clientEmailReportId,
  options,
}: {
  apolloClient: ApolloClient<object>;
  clientEmailReportId: string;
  options?: Omit<QueryOptions<QueryClientEmailReportByIdQueryVariables>, 'query' | 'variables'>;
}): Promise<QueryClientEmailReportByIdApolloQueryHelperResultEx> {
  const query: QueryClientEmailReportByIdApolloQueryResult = await apolloClient.query<QueryClientEmailReportByIdQuery>({
    query: QueryClientEmailReportByIdDocument,
    variables: { clientEmailReportId },
    ...options,
  });

  return { ...query, clientEmailReport: query?.data?.clientEmailReport_by_pk };
}

// Query Watch ById Helper
//
export type WatchQueryClientEmailReportByIdApolloObservableQuery = ObservableQuery<QueryClientEmailReportByIdQuery>;
async function watchQueryClientEmailReportById({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<QueryOptions<QueryClientEmailReportByIdQueryVariables>, 'query'>;
}): Promise<WatchQueryClientEmailReportByIdApolloObservableQuery> {
  return apolloClient.watchQuery<QueryClientEmailReportByIdQuery>({
    query: QueryClientEmailReportByIdDocument,
    ...options,
  });
}

// Query Fetch Objects Helper
//
export type QueryClientEmailReportObjectsObjectsApolloQueryResult = ApolloQueryResult<QueryClientEmailReportObjectsQuery>;
export type QueryClientEmailReportObjectsObjectsApolloQueryResultEx = QueryClientEmailReportObjectsObjectsApolloQueryResult &
  ClientEmailReportObjectsHelperResultEx;

async function queryClientEmailReportObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<QueryOptions<QueryClientEmailReportObjectsQueryVariables>, 'query'>;
}): Promise<QueryClientEmailReportObjectsObjectsApolloQueryResultEx> {
  const query: QueryClientEmailReportObjectsObjectsApolloQueryResult = await apolloClient.query<QueryClientEmailReportObjectsQuery>(
    { query: QueryClientEmailReportObjectsDocument, ...options }
  );

  return { ...query, objects: query?.data?.clientEmailReport || [] };
}

// Query Watch Objects Helper
//
export type WatchQueryClientEmailReportObjectsApolloObservableQuery = ObservableQuery<QueryClientEmailReportObjectsQuery>;
async function watchQueryClientEmailReportObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<QueryOptions<QueryClientEmailReportObjectsQueryVariables>, 'query'>;
}): Promise<WatchQueryClientEmailReportObjectsApolloObservableQuery> {
  return apolloClient.watchQuery<QueryClientEmailReportObjectsQuery>({
    query: QueryClientEmailReportObjectsDocument,
    ...options,
  });
}

// Subscription Fetch ById Helper
//
export type SubscribeToClientEmailReportByIdSubscriptionFetchResult = FetchResult<
  SubscribeToClientEmailReportByIdSubscription,
  Record<string, any>,
  Record<string, any>
>;
export type SubscribeToClientEmailReportByIdSubscriptionFetchResultEx = FetchResult<
  SubscribeToClientEmailReportByIdSubscription,
  Record<string, any>,
  Record<string, any>
> &
  ClientEmailReportByIdHelperResultEx;

async function subscribeToClientEmailReportById({
  apolloClient,
  clientEmailReportId,
  options,
}: {
  apolloClient: ApolloClient<object>;
  clientEmailReportId: string;
  options?: Omit<SubscriptionOptions<SubscribeToClientEmailReportByIdSubscriptionVariables>, 'query' | 'variables'>;
}): Promise<Observable<SubscribeToClientEmailReportByIdSubscriptionFetchResultEx>> {
  const subscription: Observable<SubscribeToClientEmailReportByIdSubscriptionFetchResult> = apolloClient.subscribe<SubscribeToClientEmailReportByIdSubscription>(
    { query: SubscribeToClientEmailReportByIdDocument, variables: { clientEmailReportId }, ...options }
  );

  return subscription.map((value) => {
    return {
      context: value.context,
      errors: value.errors,
      data: value.data,
      extensions: value.extensions,
      clientEmailReport: value?.data?.clientEmailReport_by_pk || [],
    } as SubscribeToClientEmailReportByIdSubscriptionFetchResultEx;
  });
}

// Subscription Fetch Objects Helper
//
export type SubscribeToClientEmailReportObjectsSubscriptionFetchResult = FetchResult<
  SubscribeToClientEmailReportObjectsSubscription,
  Record<string, any>,
  Record<string, any>
>;
export type SubscribeToClientEmailReportObjectsSubscriptionFetchResultEx = FetchResult<
  SubscribeToClientEmailReportObjectsSubscription,
  Record<string, any>,
  Record<string, any>
> &
  ClientEmailReportObjectsHelperResultEx;

async function subscribeToClientEmailReportObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options?: Omit<SubscriptionOptions<SubscribeToClientEmailReportObjectsSubscriptionVariables>, 'query'>;
}): Promise<Observable<SubscribeToClientEmailReportObjectsSubscriptionFetchResultEx>> {
  const subscription: Observable<SubscribeToClientEmailReportObjectsSubscriptionFetchResult> = apolloClient.subscribe<SubscribeToClientEmailReportObjectsSubscription>(
    { query: SubscribeToClientEmailReportObjectsDocument, ...options }
  );

  return subscription.map((value) => {
    return {
      context: value.context,
      errors: value.errors,
      data: value.data,
      extensions: value.extensions,
      objects: value?.data?.clientEmailReport || [],
    } as SubscribeToClientEmailReportObjectsSubscriptionFetchResultEx;
  });
}

// Insert Helper
//
type InsertClientEmailReportFetchResult = FetchResult<
  InsertClientEmailReportMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertClientEmailReportFetchHelperResultEx = InsertClientEmailReportFetchResult &
  ClientEmailReportByIdHelperResultEx;

async function insertClientEmailReport({
  apolloClient,
  clientEmailReport,
  autoOptimisticResponse,
  fieldMap,
  options,
}: {
  apolloClient: ApolloClient<object>;
  clientEmailReport: ClientEmailReport_Insert_Input;
  autoOptimisticResponse?: boolean;
  fieldMap?: FieldMap;
  options?: Omit<
    MutationOptions<InsertClientEmailReportMutation, InsertClientEmailReportMutationVariables>,
    'mutation' | 'variables'
  >;
}): Promise<InsertClientEmailReportFetchHelperResultEx> {
  const objectForInsert = stripInsertInputClientFields({ input: clientEmailReport });
  const mutationOptions: MutationOptions<InsertClientEmailReportMutation, InsertClientEmailReportMutationVariables> = {
    mutation: InsertClientEmailReportDocument,
    variables: { objects: [objectForInsert] },
    ...options,
  };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    if (!objectForInsert.id)
      throw new Error(`if autoOptimisticResponse = true, id must be set in object 'clientEmailReport'`);
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertClientEmailReportMutation>({
      operationType: 'insert',
      entityName: 'clientEmailReport',
      objects: [objectForInsert as ClientEmailReport_Insert_Input & ObjectWithId],
      fieldMap,
    });
    if (logLevel >= 2)
      console.log(' --> insertClientEmailReport - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: InsertClientEmailReportFetchResult = await apolloClient.mutate<
    InsertClientEmailReportMutation,
    InsertClientEmailReportMutationVariables
  >(mutationOptions);

  return {
    ...mutation,
    clientEmailReport:
      mutation?.data?.insert_clientEmailReport?.returning && mutation.data.insert_clientEmailReport.returning[0],
  };
}

async function insertClientEmailReportWithOnConflict({
  apolloClient,
  clientEmailReport,
  onConflict,
  autoOptimisticResponse,
  fieldMap,
  options,
}: {
  apolloClient: ApolloClient<object>;
  clientEmailReport: ClientEmailReport_Insert_Input;
  onConflict: ClientEmailReport_On_Conflict;
  autoOptimisticResponse?: boolean;
  fieldMap?: FieldMap;
  options?: Omit<
    MutationOptions<
      InsertClientEmailReportWithOnConflictMutation,
      InsertClientEmailReportWithOnConflictMutationVariables
    >,
    'mutation' | 'variables'
  >;
}): Promise<InsertClientEmailReportFetchHelperResultEx> {
  const objectForInsert = stripInsertInputClientFields({ input: clientEmailReport });
  const mutationOptions: MutationOptions<
    InsertClientEmailReportWithOnConflictMutation,
    InsertClientEmailReportWithOnConflictMutationVariables
  > = {
    mutation: InsertClientEmailReportWithOnConflictDocument,
    variables: { objects: [objectForInsert], onConflict },
    ...options,
  };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    if (!objectForInsert.id)
      throw new Error(`if autoOptimisticResponse = true, id must be set in object 'clientEmailReport'`);
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertClientEmailReportWithOnConflictMutation>(
      {
        operationType: 'insert',
        entityName: 'clientEmailReport',
        objects: [objectForInsert as ClientEmailReport_Insert_Input & ObjectWithId],
        fieldMap,
      }
    );
    if (logLevel >= 2)
      console.log(
        ' --> insertClientEmailReportWithOnConflict - optimisticResponse:',
        mutationOptions.optimisticResponse
      );
  }

  const mutation: InsertClientEmailReportFetchResult = await apolloClient.mutate<
    InsertClientEmailReportWithOnConflictMutation,
    InsertClientEmailReportWithOnConflictMutationVariables
  >(mutationOptions);

  return {
    ...mutation,
    clientEmailReport:
      mutation?.data?.insert_clientEmailReport?.returning && mutation.data.insert_clientEmailReport.returning[0],
  };
}

// Insert Objects Helper
//
type InsertClientEmailReportObjectsFetchResult = FetchResult<
  InsertClientEmailReportMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertClientEmailReportObjectsHelperResultEx = InsertClientEmailReportObjectsFetchResult &
  ClientEmailReportObjectsHelperResultEx;

async function insertClientEmailReportObjects({
  apolloClient,
  objects,
  autoOptimisticResponse,
  fieldMap,
  options,
}: {
  apolloClient: ApolloClient<object>;
  objects: ClientEmailReport_Insert_Input[];
  autoOptimisticResponse?: boolean;
  fieldMap?: FieldMap;
  options?: Omit<
    MutationOptions<InsertClientEmailReportMutation, InsertClientEmailReportMutationVariables>,
    'mutation' | 'variables'
  >;
}): Promise<InsertClientEmailReportObjectsHelperResultEx> {
  const objectsForInsert = objects.map((objectItem) => stripInsertInputClientFields({ input: objectItem }));
  const mutationOptions: MutationOptions<InsertClientEmailReportMutation, InsertClientEmailReportMutationVariables> = {
    mutation: InsertClientEmailReportDocument,
    variables: { objects: objectsForInsert },
    ...options,
  };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    if (objectsForInsert.find((objectItem) => !objectItem.id))
      throw new Error(`if autoOptimisticResponse = true, ids must be set in objects`);
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertClientEmailReportMutation>({
      operationType: 'insert',
      entityName: 'clientEmailReport',
      objects: objectsForInsert as (ClientEmailReport_Insert_Input & ObjectWithId)[],
      fieldMap,
    });
    if (logLevel >= 2)
      console.log(' --> insertClientEmailReport - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: InsertClientEmailReportObjectsFetchResult = await apolloClient.mutate<
    InsertClientEmailReportMutation,
    InsertClientEmailReportMutationVariables
  >(mutationOptions);

  return { ...mutation, objects: mutation?.data?.insert_clientEmailReport?.returning || [] };
}

// Update Helper
//
type UpdateClientEmailReportByIdQueryResult = FetchResult<
  UpdateClientEmailReportByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateClientEmailReportByIdHelperResultEx = UpdateClientEmailReportByIdQueryResult &
  ClientEmailReportByIdHelperResultEx;

async function updateClientEmailReportById({
  apolloClient,
  clientEmailReportId,
  set,
  autoOptimisticResponse,
  options,
}: {
  apolloClient: ApolloClient<object>;
  clientEmailReportId: string;
  set: ClientEmailReport_Set_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationOptions<UpdateClientEmailReportByIdMutation, UpdateClientEmailReportByIdMutationVariables>,
    'mutation'
  >;
}): Promise<UpdateClientEmailReportByIdHelperResultEx> {
  const mutationOptions: MutationOptions<
    UpdateClientEmailReportByIdMutation,
    UpdateClientEmailReportByIdMutationVariables
  > = { mutation: UpdateClientEmailReportByIdDocument, variables: { id: clientEmailReportId, set }, ...options };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateClientEmailReportByIdMutation>({
      operationType: 'update',
      entityName: 'clientEmailReport',
      objects: [{ id: clientEmailReportId, ...set }],
    });
    if (logLevel >= 2)
      console.log(' --> updateClientEmailReportById - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: UpdateClientEmailReportByIdQueryResult = await apolloClient.mutate<
    UpdateClientEmailReportByIdMutation,
    UpdateClientEmailReportByIdMutationVariables
  >(mutationOptions);

  return {
    ...mutation,
    clientEmailReport:
      mutation?.data?.update_clientEmailReport?.returning && mutation.data.update_clientEmailReport.returning[0],
  };
}

// Update Objects Helper
//
type UpdateClientEmailReportObjectsFetchResult = FetchResult<
  UpdateClientEmailReportMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateClientEmailReportObjectsHelperResultEx = UpdateClientEmailReportObjectsFetchResult &
  ClientEmailReportObjectsHelperResultEx;

async function updateClientEmailReportObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<MutationOptions<UpdateClientEmailReportMutation, UpdateClientEmailReportMutationVariables>, 'mutation'>;
}): Promise<UpdateClientEmailReportObjectsHelperResultEx> {
  const mutation: UpdateClientEmailReportObjectsFetchResult = await apolloClient.mutate<
    UpdateClientEmailReportMutation,
    UpdateClientEmailReportMutationVariables
  >({ mutation: UpdateClientEmailReportDocument, ...options });

  return { ...mutation, objects: mutation?.data?.update_clientEmailReport?.returning || [] };
}

// Delete Helper
//

type RemoveClientEmailReportModelByIdQueryResult = FetchResult<
  RemoveClientEmailReportModelByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveClientEmailReportModelByIdQueryHelperResultEx = RemoveClientEmailReportModelByIdQueryResult &
  RemoveEntitiesQueryHelperResultEx;

async function removeClientEmailReportModelById({
  apolloClient,
  clientEmailReportId,
  autoOptimisticResponse,
  options,
}: {
  apolloClient: ApolloClient<object>;
  clientEmailReportId: string;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationOptions<RemoveClientEmailReportModelByIdMutation, RemoveClientEmailReportModelByIdMutationVariables>,
    'mutation'
  >;
}): Promise<RemoveClientEmailReportModelByIdQueryHelperResultEx> {
  const mutationOptions: MutationOptions<
    RemoveClientEmailReportModelByIdMutation,
    RemoveClientEmailReportModelByIdMutationVariables
  > = { mutation: RemoveClientEmailReportModelByIdDocument, variables: { id: clientEmailReportId }, ...options };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<RemoveClientEmailReportModelByIdMutation>(
      { operationType: 'delete', entityName: 'clientEmailReport', objects: [{ id: clientEmailReportId }] }
    );
    if (logLevel >= 2)
      console.log(' --> removeClientEmailReportModelById - optimisticResponse:', mutationOptions.optimisticResponse);
  }
  if (!options || !options.update) {
    mutationOptions.update = generateUpdateFunctionForMutation<RemoveClientEmailReportModelByIdMutation>({
      operationType: 'delete',
      entityName: 'clientEmailReport',
      entityId: clientEmailReportId,
    });
  }

  const mutation: RemoveClientEmailReportModelByIdQueryResult = await apolloClient.mutate<
    RemoveClientEmailReportModelByIdMutation,
    RemoveClientEmailReportModelByIdMutationVariables
  >(mutationOptions);

  return { ...mutation, affected_rows: mutation?.data?.delete_clientEmailReport?.affected_rows || 0 };
}

type RemoveClientEmailReportModelObjectsQueryResult = FetchResult<
  RemoveClientEmailReportModelMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveClientEmailReportModelObjectsQueryHelperResultEx = RemoveClientEmailReportModelObjectsQueryResult &
  RemoveEntitiesQueryHelperResultEx;

async function removeClientEmailReportModelObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<
    MutationOptions<RemoveClientEmailReportModelMutation, RemoveClientEmailReportModelMutationVariables>,
    'mutation'
  >;
}): Promise<RemoveClientEmailReportModelObjectsQueryHelperResultEx> {
  const mutation: RemoveClientEmailReportModelObjectsQueryResult = await apolloClient.mutate<
    RemoveClientEmailReportModelMutation,
    RemoveClientEmailReportModelMutationVariables
  >({ mutation: RemoveClientEmailReportModelDocument, ...options });

  return { ...mutation, affected_rows: mutation?.data?.delete_clientEmailReport?.affected_rows || 0 };
}

// ClientEmailReport Fragment Helper Object
//------------------------------------------------

export const ClientEmailReportFragmentGQLHelper = {
  cacheGetDataId: cacheGetDataIdForClientEmailReport,
  cacheReadFragmentById: cacheReadFragmentClientEmailReportById,
  cacheWriteFragmentById: cacheWriteFragmentClientEmailReportById,
  cacheReadQueryById: cacheReadQueryClientEmailReportById,
  cacheWriteQueryById: cacheWriteQueryClientEmailReportById,
  cacheReadQueryObjects: cacheReadQueryClientEmailReportObjects,
  cacheWriteQueryObjects: cacheWriteQueryClientEmailReportObjects,
  cacheWriteQueryInsert: cacheWriteQueryClientEmailReportInsert,
  cacheWriteQueryRemove: cacheWriteQueryClientEmailReportRemove,
  queryById: queryClientEmailReportById,
  queryObjects: queryClientEmailReportObjects,
  watchQueryById: watchQueryClientEmailReportById,
  watchQueryObjects: watchQueryClientEmailReportObjects,
  subscribeToById: subscribeToClientEmailReportById,
  subscribeToObjects: subscribeToClientEmailReportObjects,
  insert: insertClientEmailReport,
  insertWithOnConflict: insertClientEmailReportWithOnConflict,
  insertObjects: insertClientEmailReportObjects,
  updateById: updateClientEmailReportById,
  updateObjects: updateClientEmailReportObjects,
};

// clientEmailReport Entity Helper Object
//------------------------------------------------

export const ClientEmailReportModelGQLHelper = {
  removeById: removeClientEmailReportModelById,
  removeObjects: removeClientEmailReportModelObjects,
};

// emailHistory HELPERS
//------------------------------------------------

export type EmailHistoryByIdHelperResultEx = { emailHistory: EmailHistoryFragment | null | undefined };
export type EmailHistoryObjectsHelperResultEx = { objects: EmailHistoryFragment[] };

// Direct Client & Cache Fragment Helpers
//
function cacheGetDataIdForEmailHistory(emailHistoryId: string): string {
  return defaultCacheIdFromObject({ __typename: 'emailHistory', id: emailHistoryId });
}

function cacheReadFragmentEmailHistoryById({
  apolloCache,
  emailHistoryId,
}: {
  apolloCache: ApolloCache<object>;
  emailHistoryId: string;
}): EmailHistoryFragment | null | undefined {
  return apolloCache.readFragment<EmailHistoryFragment | null | undefined>({
    fragment: EmailHistoryFragmentDoc,
    fragmentName: 'EmailHistory',
    id: defaultCacheIdFromObject({ __typename: 'emailHistory', id: emailHistoryId }),
  });
}

function cacheWriteFragmentEmailHistoryById({
  apolloCache,
  emailHistoryId,
  emailHistoryPartial,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  emailHistoryId: string;
  emailHistoryPartial: Partial<EmailHistoryFragment> | EmailHistory_Insert_Input | null;
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): Partial<EmailHistoryFragment> {
  const parsedFragment = convertToGraph({ input: emailHistoryPartial, typename: 'emailHistory', fieldMap });
  if (logLevel >= 2) console.log(' --> cacheWriteFragmentEmailHistoryById - parsedFragment:', parsedFragment);
  apolloCache.writeFragment<Partial<EmailHistoryFragment> | null>({
    fragment: EmailHistoryFragmentDoc,
    fragmentName: 'EmailHistory',
    id: defaultCacheIdFromObject({ ...parsedFragment, id: emailHistoryId }),
    data: parsedFragment,
    broadcast: apolloBroadcast,
  });
  return parsedFragment;
}

function cacheReadQueryEmailHistoryById({
  apolloCache,
  emailHistoryId,
}: {
  apolloCache: ApolloCache<object>;
  emailHistoryId: string;
}): EmailHistoryFragment | null | undefined {
  try {
    return apolloCache.readQuery<EmailHistoryFragment | null>({
      query: QueryEmailHistoryByIdDocument,
      variables: { emailHistoryId },
    });
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheReadQueryEmailHistoryById threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheWriteQueryEmailHistoryById({
  apolloCache,
  emailHistoryId,
  emailHistory,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  emailHistoryId: string;
  emailHistory: EmailHistoryFragment | EmailHistory_Insert_Input | null;
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): void {
  try {
    const emailHistoryPartial = convertToGraph({ input: emailHistory, typename: 'emailHistory', fieldMap });
    return apolloCache.writeQuery<EmailHistoryFragment | null>({
      query: QueryEmailHistoryByIdDocument,
      variables: { emailHistoryId },
      data: emailHistory ? emailHistoryPartial : null,
      broadcast: apolloBroadcast,
    });
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheWriteQueryEmailHistoryById threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheReadQueryEmailHistoryObjects({
  apolloCache,
  variables,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryEmailHistoryObjectsQueryVariables;
}): EmailHistory[] | null | undefined {
  try {
    return (
      apolloCache.readQuery<{ EmailHistory: EmailHistory[] | null }>({
        query: QueryEmailHistoryObjectsDocument,
        variables,
      })?.EmailHistory || []
    );
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheReadQueryEmailHistoryObjects threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheWriteQueryEmailHistoryObjects({
  apolloCache,
  variables,
  data,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryEmailHistoryObjectsQueryVariables;
  data: (EmailHistory | EmailHistory_Insert_Input)[];
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): void {
  try {
    const objects = convertToGraph({ input: data, typename: 'emailHistory', fieldMap });
    return apolloCache.writeQuery<{ EmailHistory: EmailHistory[] }>({
      query: QueryEmailHistoryObjectsDocument,
      variables,
      data: { EmailHistory: objects },
      broadcast: apolloBroadcast,
    });
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheWriteQueryEmailHistoryObjects threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheWriteQueryEmailHistoryInsert({
  apolloCache,
  variables,
  emailHistory,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryEmailHistoryObjectsQueryVariables;
  emailHistory: EmailHistory_Insert_Input;
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): void {
  const currentObjects = cacheReadQueryEmailHistoryObjects({ apolloCache, variables }) || [];
  const objectsWithInserted = [
    ...currentObjects,
    convertToGraph({ input: emailHistory, typename: 'emailHistory', fieldMap }),
  ];
  if (logLevel >= 2) console.log(' --> cacheWriteQueryEmailHistoryInsert - objectsWithInserted:', objectsWithInserted);
  return cacheWriteQueryEmailHistoryObjects({ apolloCache, variables, data: objectsWithInserted, apolloBroadcast });
}

function cacheWriteQueryEmailHistoryRemove({
  apolloCache,
  variables,
  emailHistoryId,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryEmailHistoryObjectsQueryVariables;
  emailHistoryId: string;
  apolloBroadcast?: boolean;
}): void {
  const currentObjects = cacheReadQueryEmailHistoryObjects({ apolloCache, variables }) || [];
  const objectsWithRemoved = currentObjects.filter((objectItem) => objectItem.id !== emailHistoryId) || [];
  if (logLevel >= 2) console.log(' --> cacheWriteQueryEmailHistoryRemove - objectsWithRemoved:', objectsWithRemoved);
  return cacheWriteQueryEmailHistoryObjects({ apolloCache, variables, data: objectsWithRemoved, apolloBroadcast });
}

// Query Fetch Helper
//
export type QueryEmailHistoryByIdApolloQueryResult = ApolloQueryResult<QueryEmailHistoryByIdQuery>;
export type QueryEmailHistoryByIdApolloQueryHelperResultEx = QueryEmailHistoryByIdApolloQueryResult &
  EmailHistoryByIdHelperResultEx;

async function queryEmailHistoryById({
  apolloClient,
  emailHistoryId,
  options,
}: {
  apolloClient: ApolloClient<object>;
  emailHistoryId: string;
  options?: Omit<QueryOptions<QueryEmailHistoryByIdQueryVariables>, 'query' | 'variables'>;
}): Promise<QueryEmailHistoryByIdApolloQueryHelperResultEx> {
  const query: QueryEmailHistoryByIdApolloQueryResult = await apolloClient.query<QueryEmailHistoryByIdQuery>({
    query: QueryEmailHistoryByIdDocument,
    variables: { emailHistoryId },
    ...options,
  });

  return { ...query, emailHistory: query?.data?.emailHistory_by_pk };
}

// Query Watch ById Helper
//
export type WatchQueryEmailHistoryByIdApolloObservableQuery = ObservableQuery<QueryEmailHistoryByIdQuery>;
async function watchQueryEmailHistoryById({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<QueryOptions<QueryEmailHistoryByIdQueryVariables>, 'query'>;
}): Promise<WatchQueryEmailHistoryByIdApolloObservableQuery> {
  return apolloClient.watchQuery<QueryEmailHistoryByIdQuery>({ query: QueryEmailHistoryByIdDocument, ...options });
}

// Query Fetch Objects Helper
//
export type QueryEmailHistoryObjectsObjectsApolloQueryResult = ApolloQueryResult<QueryEmailHistoryObjectsQuery>;
export type QueryEmailHistoryObjectsObjectsApolloQueryResultEx = QueryEmailHistoryObjectsObjectsApolloQueryResult &
  EmailHistoryObjectsHelperResultEx;

async function queryEmailHistoryObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<QueryOptions<QueryEmailHistoryObjectsQueryVariables>, 'query'>;
}): Promise<QueryEmailHistoryObjectsObjectsApolloQueryResultEx> {
  const query: QueryEmailHistoryObjectsObjectsApolloQueryResult = await apolloClient.query<QueryEmailHistoryObjectsQuery>(
    { query: QueryEmailHistoryObjectsDocument, ...options }
  );

  return { ...query, objects: query?.data?.emailHistory || [] };
}

// Query Watch Objects Helper
//
export type WatchQueryEmailHistoryObjectsApolloObservableQuery = ObservableQuery<QueryEmailHistoryObjectsQuery>;
async function watchQueryEmailHistoryObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<QueryOptions<QueryEmailHistoryObjectsQueryVariables>, 'query'>;
}): Promise<WatchQueryEmailHistoryObjectsApolloObservableQuery> {
  return apolloClient.watchQuery<QueryEmailHistoryObjectsQuery>({
    query: QueryEmailHistoryObjectsDocument,
    ...options,
  });
}

// Subscription Fetch ById Helper
//
export type SubscribeToEmailHistoryByIdSubscriptionFetchResult = FetchResult<
  SubscribeToEmailHistoryByIdSubscription,
  Record<string, any>,
  Record<string, any>
>;
export type SubscribeToEmailHistoryByIdSubscriptionFetchResultEx = FetchResult<
  SubscribeToEmailHistoryByIdSubscription,
  Record<string, any>,
  Record<string, any>
> &
  EmailHistoryByIdHelperResultEx;

async function subscribeToEmailHistoryById({
  apolloClient,
  emailHistoryId,
  options,
}: {
  apolloClient: ApolloClient<object>;
  emailHistoryId: string;
  options?: Omit<SubscriptionOptions<SubscribeToEmailHistoryByIdSubscriptionVariables>, 'query' | 'variables'>;
}): Promise<Observable<SubscribeToEmailHistoryByIdSubscriptionFetchResultEx>> {
  const subscription: Observable<SubscribeToEmailHistoryByIdSubscriptionFetchResult> = apolloClient.subscribe<SubscribeToEmailHistoryByIdSubscription>(
    { query: SubscribeToEmailHistoryByIdDocument, variables: { emailHistoryId }, ...options }
  );

  return subscription.map((value) => {
    return {
      context: value.context,
      errors: value.errors,
      data: value.data,
      extensions: value.extensions,
      emailHistory: value?.data?.emailHistory_by_pk || [],
    } as SubscribeToEmailHistoryByIdSubscriptionFetchResultEx;
  });
}

// Subscription Fetch Objects Helper
//
export type SubscribeToEmailHistoryObjectsSubscriptionFetchResult = FetchResult<
  SubscribeToEmailHistoryObjectsSubscription,
  Record<string, any>,
  Record<string, any>
>;
export type SubscribeToEmailHistoryObjectsSubscriptionFetchResultEx = FetchResult<
  SubscribeToEmailHistoryObjectsSubscription,
  Record<string, any>,
  Record<string, any>
> &
  EmailHistoryObjectsHelperResultEx;

async function subscribeToEmailHistoryObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options?: Omit<SubscriptionOptions<SubscribeToEmailHistoryObjectsSubscriptionVariables>, 'query'>;
}): Promise<Observable<SubscribeToEmailHistoryObjectsSubscriptionFetchResultEx>> {
  const subscription: Observable<SubscribeToEmailHistoryObjectsSubscriptionFetchResult> = apolloClient.subscribe<SubscribeToEmailHistoryObjectsSubscription>(
    { query: SubscribeToEmailHistoryObjectsDocument, ...options }
  );

  return subscription.map((value) => {
    return {
      context: value.context,
      errors: value.errors,
      data: value.data,
      extensions: value.extensions,
      objects: value?.data?.emailHistory || [],
    } as SubscribeToEmailHistoryObjectsSubscriptionFetchResultEx;
  });
}

// Insert Helper
//
type InsertEmailHistoryFetchResult = FetchResult<InsertEmailHistoryMutation, Record<string, any>, Record<string, any>>;
export type InsertEmailHistoryFetchHelperResultEx = InsertEmailHistoryFetchResult & EmailHistoryByIdHelperResultEx;

async function insertEmailHistory({
  apolloClient,
  emailHistory,
  autoOptimisticResponse,
  fieldMap,
  options,
}: {
  apolloClient: ApolloClient<object>;
  emailHistory: EmailHistory_Insert_Input;
  autoOptimisticResponse?: boolean;
  fieldMap?: FieldMap;
  options?: Omit<
    MutationOptions<InsertEmailHistoryMutation, InsertEmailHistoryMutationVariables>,
    'mutation' | 'variables'
  >;
}): Promise<InsertEmailHistoryFetchHelperResultEx> {
  const objectForInsert = stripInsertInputClientFields({ input: emailHistory });
  const mutationOptions: MutationOptions<InsertEmailHistoryMutation, InsertEmailHistoryMutationVariables> = {
    mutation: InsertEmailHistoryDocument,
    variables: { objects: [objectForInsert] },
    ...options,
  };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    if (!objectForInsert.id)
      throw new Error(`if autoOptimisticResponse = true, id must be set in object 'emailHistory'`);
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertEmailHistoryMutation>({
      operationType: 'insert',
      entityName: 'emailHistory',
      objects: [objectForInsert as EmailHistory_Insert_Input & ObjectWithId],
      fieldMap,
    });
    if (logLevel >= 2) console.log(' --> insertEmailHistory - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: InsertEmailHistoryFetchResult = await apolloClient.mutate<
    InsertEmailHistoryMutation,
    InsertEmailHistoryMutationVariables
  >(mutationOptions);

  return {
    ...mutation,
    emailHistory: mutation?.data?.insert_emailHistory?.returning && mutation.data.insert_emailHistory.returning[0],
  };
}

async function insertEmailHistoryWithOnConflict({
  apolloClient,
  emailHistory,
  onConflict,
  autoOptimisticResponse,
  fieldMap,
  options,
}: {
  apolloClient: ApolloClient<object>;
  emailHistory: EmailHistory_Insert_Input;
  onConflict: EmailHistory_On_Conflict;
  autoOptimisticResponse?: boolean;
  fieldMap?: FieldMap;
  options?: Omit<
    MutationOptions<InsertEmailHistoryWithOnConflictMutation, InsertEmailHistoryWithOnConflictMutationVariables>,
    'mutation' | 'variables'
  >;
}): Promise<InsertEmailHistoryFetchHelperResultEx> {
  const objectForInsert = stripInsertInputClientFields({ input: emailHistory });
  const mutationOptions: MutationOptions<
    InsertEmailHistoryWithOnConflictMutation,
    InsertEmailHistoryWithOnConflictMutationVariables
  > = {
    mutation: InsertEmailHistoryWithOnConflictDocument,
    variables: { objects: [objectForInsert], onConflict },
    ...options,
  };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    if (!objectForInsert.id)
      throw new Error(`if autoOptimisticResponse = true, id must be set in object 'emailHistory'`);
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertEmailHistoryWithOnConflictMutation>(
      {
        operationType: 'insert',
        entityName: 'emailHistory',
        objects: [objectForInsert as EmailHistory_Insert_Input & ObjectWithId],
        fieldMap,
      }
    );
    if (logLevel >= 2)
      console.log(' --> insertEmailHistoryWithOnConflict - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: InsertEmailHistoryFetchResult = await apolloClient.mutate<
    InsertEmailHistoryWithOnConflictMutation,
    InsertEmailHistoryWithOnConflictMutationVariables
  >(mutationOptions);

  return {
    ...mutation,
    emailHistory: mutation?.data?.insert_emailHistory?.returning && mutation.data.insert_emailHistory.returning[0],
  };
}

// Insert Objects Helper
//
type InsertEmailHistoryObjectsFetchResult = FetchResult<
  InsertEmailHistoryMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertEmailHistoryObjectsHelperResultEx = InsertEmailHistoryObjectsFetchResult &
  EmailHistoryObjectsHelperResultEx;

async function insertEmailHistoryObjects({
  apolloClient,
  objects,
  autoOptimisticResponse,
  fieldMap,
  options,
}: {
  apolloClient: ApolloClient<object>;
  objects: EmailHistory_Insert_Input[];
  autoOptimisticResponse?: boolean;
  fieldMap?: FieldMap;
  options?: Omit<
    MutationOptions<InsertEmailHistoryMutation, InsertEmailHistoryMutationVariables>,
    'mutation' | 'variables'
  >;
}): Promise<InsertEmailHistoryObjectsHelperResultEx> {
  const objectsForInsert = objects.map((objectItem) => stripInsertInputClientFields({ input: objectItem }));
  const mutationOptions: MutationOptions<InsertEmailHistoryMutation, InsertEmailHistoryMutationVariables> = {
    mutation: InsertEmailHistoryDocument,
    variables: { objects: objectsForInsert },
    ...options,
  };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    if (objectsForInsert.find((objectItem) => !objectItem.id))
      throw new Error(`if autoOptimisticResponse = true, ids must be set in objects`);
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertEmailHistoryMutation>({
      operationType: 'insert',
      entityName: 'emailHistory',
      objects: objectsForInsert as (EmailHistory_Insert_Input & ObjectWithId)[],
      fieldMap,
    });
    if (logLevel >= 2) console.log(' --> insertEmailHistory - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: InsertEmailHistoryObjectsFetchResult = await apolloClient.mutate<
    InsertEmailHistoryMutation,
    InsertEmailHistoryMutationVariables
  >(mutationOptions);

  return { ...mutation, objects: mutation?.data?.insert_emailHistory?.returning || [] };
}

// Update Helper
//
type UpdateEmailHistoryByIdQueryResult = FetchResult<
  UpdateEmailHistoryByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateEmailHistoryByIdHelperResultEx = UpdateEmailHistoryByIdQueryResult & EmailHistoryByIdHelperResultEx;

async function updateEmailHistoryById({
  apolloClient,
  emailHistoryId,
  set,
  autoOptimisticResponse,
  options,
}: {
  apolloClient: ApolloClient<object>;
  emailHistoryId: string;
  set: EmailHistory_Set_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<MutationOptions<UpdateEmailHistoryByIdMutation, UpdateEmailHistoryByIdMutationVariables>, 'mutation'>;
}): Promise<UpdateEmailHistoryByIdHelperResultEx> {
  const mutationOptions: MutationOptions<UpdateEmailHistoryByIdMutation, UpdateEmailHistoryByIdMutationVariables> = {
    mutation: UpdateEmailHistoryByIdDocument,
    variables: { id: emailHistoryId, set },
    ...options,
  };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateEmailHistoryByIdMutation>({
      operationType: 'update',
      entityName: 'emailHistory',
      objects: [{ id: emailHistoryId, ...set }],
    });
    if (logLevel >= 2)
      console.log(' --> updateEmailHistoryById - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: UpdateEmailHistoryByIdQueryResult = await apolloClient.mutate<
    UpdateEmailHistoryByIdMutation,
    UpdateEmailHistoryByIdMutationVariables
  >(mutationOptions);

  return {
    ...mutation,
    emailHistory: mutation?.data?.update_emailHistory?.returning && mutation.data.update_emailHistory.returning[0],
  };
}

// Update Objects Helper
//
type UpdateEmailHistoryObjectsFetchResult = FetchResult<
  UpdateEmailHistoryMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateEmailHistoryObjectsHelperResultEx = UpdateEmailHistoryObjectsFetchResult &
  EmailHistoryObjectsHelperResultEx;

async function updateEmailHistoryObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<MutationOptions<UpdateEmailHistoryMutation, UpdateEmailHistoryMutationVariables>, 'mutation'>;
}): Promise<UpdateEmailHistoryObjectsHelperResultEx> {
  const mutation: UpdateEmailHistoryObjectsFetchResult = await apolloClient.mutate<
    UpdateEmailHistoryMutation,
    UpdateEmailHistoryMutationVariables
  >({ mutation: UpdateEmailHistoryDocument, ...options });

  return { ...mutation, objects: mutation?.data?.update_emailHistory?.returning || [] };
}

// Delete Helper
//

type RemoveEmailHistoryModelByIdQueryResult = FetchResult<
  RemoveEmailHistoryModelByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveEmailHistoryModelByIdQueryHelperResultEx = RemoveEmailHistoryModelByIdQueryResult &
  RemoveEntitiesQueryHelperResultEx;

async function removeEmailHistoryModelById({
  apolloClient,
  emailHistoryId,
  autoOptimisticResponse,
  options,
}: {
  apolloClient: ApolloClient<object>;
  emailHistoryId: string;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationOptions<RemoveEmailHistoryModelByIdMutation, RemoveEmailHistoryModelByIdMutationVariables>,
    'mutation'
  >;
}): Promise<RemoveEmailHistoryModelByIdQueryHelperResultEx> {
  const mutationOptions: MutationOptions<
    RemoveEmailHistoryModelByIdMutation,
    RemoveEmailHistoryModelByIdMutationVariables
  > = { mutation: RemoveEmailHistoryModelByIdDocument, variables: { id: emailHistoryId }, ...options };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<RemoveEmailHistoryModelByIdMutation>({
      operationType: 'delete',
      entityName: 'emailHistory',
      objects: [{ id: emailHistoryId }],
    });
    if (logLevel >= 2)
      console.log(' --> removeEmailHistoryModelById - optimisticResponse:', mutationOptions.optimisticResponse);
  }
  if (!options || !options.update) {
    mutationOptions.update = generateUpdateFunctionForMutation<RemoveEmailHistoryModelByIdMutation>({
      operationType: 'delete',
      entityName: 'emailHistory',
      entityId: emailHistoryId,
    });
  }

  const mutation: RemoveEmailHistoryModelByIdQueryResult = await apolloClient.mutate<
    RemoveEmailHistoryModelByIdMutation,
    RemoveEmailHistoryModelByIdMutationVariables
  >(mutationOptions);

  return { ...mutation, affected_rows: mutation?.data?.delete_emailHistory?.affected_rows || 0 };
}

type RemoveEmailHistoryModelObjectsQueryResult = FetchResult<
  RemoveEmailHistoryModelMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveEmailHistoryModelObjectsQueryHelperResultEx = RemoveEmailHistoryModelObjectsQueryResult &
  RemoveEntitiesQueryHelperResultEx;

async function removeEmailHistoryModelObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<MutationOptions<RemoveEmailHistoryModelMutation, RemoveEmailHistoryModelMutationVariables>, 'mutation'>;
}): Promise<RemoveEmailHistoryModelObjectsQueryHelperResultEx> {
  const mutation: RemoveEmailHistoryModelObjectsQueryResult = await apolloClient.mutate<
    RemoveEmailHistoryModelMutation,
    RemoveEmailHistoryModelMutationVariables
  >({ mutation: RemoveEmailHistoryModelDocument, ...options });

  return { ...mutation, affected_rows: mutation?.data?.delete_emailHistory?.affected_rows || 0 };
}

// EmailHistory Fragment Helper Object
//------------------------------------------------

export const EmailHistoryFragmentGQLHelper = {
  cacheGetDataId: cacheGetDataIdForEmailHistory,
  cacheReadFragmentById: cacheReadFragmentEmailHistoryById,
  cacheWriteFragmentById: cacheWriteFragmentEmailHistoryById,
  cacheReadQueryById: cacheReadQueryEmailHistoryById,
  cacheWriteQueryById: cacheWriteQueryEmailHistoryById,
  cacheReadQueryObjects: cacheReadQueryEmailHistoryObjects,
  cacheWriteQueryObjects: cacheWriteQueryEmailHistoryObjects,
  cacheWriteQueryInsert: cacheWriteQueryEmailHistoryInsert,
  cacheWriteQueryRemove: cacheWriteQueryEmailHistoryRemove,
  queryById: queryEmailHistoryById,
  queryObjects: queryEmailHistoryObjects,
  watchQueryById: watchQueryEmailHistoryById,
  watchQueryObjects: watchQueryEmailHistoryObjects,
  subscribeToById: subscribeToEmailHistoryById,
  subscribeToObjects: subscribeToEmailHistoryObjects,
  insert: insertEmailHistory,
  insertWithOnConflict: insertEmailHistoryWithOnConflict,
  insertObjects: insertEmailHistoryObjects,
  updateById: updateEmailHistoryById,
  updateObjects: updateEmailHistoryObjects,
};

// emailHistory Entity Helper Object
//------------------------------------------------

export const EmailHistoryModelGQLHelper = {
  removeById: removeEmailHistoryModelById,
  removeObjects: removeEmailHistoryModelObjects,
};

// sendgridEvent HELPERS
//------------------------------------------------

export type SendgridEventByIdHelperResultEx = { sendgridEvent: SendgridEventFragment | null | undefined };
export type SendgridEventObjectsHelperResultEx = { objects: SendgridEventFragment[] };

// Direct Client & Cache Fragment Helpers
//
function cacheGetDataIdForSendgridEvent(sendgridEventId: string): string {
  return defaultCacheIdFromObject({ __typename: 'sendgridEvent', id: sendgridEventId });
}

function cacheReadFragmentSendgridEventById({
  apolloCache,
  sendgridEventId,
}: {
  apolloCache: ApolloCache<object>;
  sendgridEventId: string;
}): SendgridEventFragment | null | undefined {
  return apolloCache.readFragment<SendgridEventFragment | null | undefined>({
    fragment: SendgridEventFragmentDoc,
    fragmentName: 'SendgridEvent',
    id: defaultCacheIdFromObject({ __typename: 'sendgridEvent', id: sendgridEventId }),
  });
}

function cacheWriteFragmentSendgridEventById({
  apolloCache,
  sendgridEventId,
  sendgridEventPartial,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  sendgridEventId: string;
  sendgridEventPartial: Partial<SendgridEventFragment> | SendgridEvent_Insert_Input | null;
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): Partial<SendgridEventFragment> {
  const parsedFragment = convertToGraph({ input: sendgridEventPartial, typename: 'sendgridEvent', fieldMap });
  if (logLevel >= 2) console.log(' --> cacheWriteFragmentSendgridEventById - parsedFragment:', parsedFragment);
  apolloCache.writeFragment<Partial<SendgridEventFragment> | null>({
    fragment: SendgridEventFragmentDoc,
    fragmentName: 'SendgridEvent',
    id: defaultCacheIdFromObject({ ...parsedFragment, id: sendgridEventId }),
    data: parsedFragment,
    broadcast: apolloBroadcast,
  });
  return parsedFragment;
}

function cacheReadQuerySendgridEventById({
  apolloCache,
  sendgridEventId,
}: {
  apolloCache: ApolloCache<object>;
  sendgridEventId: string;
}): SendgridEventFragment | null | undefined {
  try {
    return apolloCache.readQuery<SendgridEventFragment | null>({
      query: QuerySendgridEventByIdDocument,
      variables: { sendgridEventId },
    });
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheReadQuerySendgridEventById threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheWriteQuerySendgridEventById({
  apolloCache,
  sendgridEventId,
  sendgridEvent,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  sendgridEventId: string;
  sendgridEvent: SendgridEventFragment | SendgridEvent_Insert_Input | null;
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): void {
  try {
    const sendgridEventPartial = convertToGraph({ input: sendgridEvent, typename: 'sendgridEvent', fieldMap });
    return apolloCache.writeQuery<SendgridEventFragment | null>({
      query: QuerySendgridEventByIdDocument,
      variables: { sendgridEventId },
      data: sendgridEvent ? sendgridEventPartial : null,
      broadcast: apolloBroadcast,
    });
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheWriteQuerySendgridEventById threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheReadQuerySendgridEventObjects({
  apolloCache,
  variables,
}: {
  apolloCache: ApolloCache<object>;
  variables: QuerySendgridEventObjectsQueryVariables;
}): SendgridEvent[] | null | undefined {
  try {
    return (
      apolloCache.readQuery<{ SendgridEvent: SendgridEvent[] | null }>({
        query: QuerySendgridEventObjectsDocument,
        variables,
      })?.SendgridEvent || []
    );
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheReadQuerySendgridEventObjects threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheWriteQuerySendgridEventObjects({
  apolloCache,
  variables,
  data,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  variables: QuerySendgridEventObjectsQueryVariables;
  data: (SendgridEvent | SendgridEvent_Insert_Input)[];
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): void {
  try {
    const objects = convertToGraph({ input: data, typename: 'sendgridEvent', fieldMap });
    return apolloCache.writeQuery<{ SendgridEvent: SendgridEvent[] }>({
      query: QuerySendgridEventObjectsDocument,
      variables,
      data: { SendgridEvent: objects },
      broadcast: apolloBroadcast,
    });
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheWriteQuerySendgridEventObjects threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheWriteQuerySendgridEventInsert({
  apolloCache,
  variables,
  sendgridEvent,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  variables: QuerySendgridEventObjectsQueryVariables;
  sendgridEvent: SendgridEvent_Insert_Input;
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): void {
  const currentObjects = cacheReadQuerySendgridEventObjects({ apolloCache, variables }) || [];
  const objectsWithInserted = [
    ...currentObjects,
    convertToGraph({ input: sendgridEvent, typename: 'sendgridEvent', fieldMap }),
  ];
  if (logLevel >= 2) console.log(' --> cacheWriteQuerySendgridEventInsert - objectsWithInserted:', objectsWithInserted);
  return cacheWriteQuerySendgridEventObjects({ apolloCache, variables, data: objectsWithInserted, apolloBroadcast });
}

function cacheWriteQuerySendgridEventRemove({
  apolloCache,
  variables,
  sendgridEventId,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  variables: QuerySendgridEventObjectsQueryVariables;
  sendgridEventId: string;
  apolloBroadcast?: boolean;
}): void {
  const currentObjects = cacheReadQuerySendgridEventObjects({ apolloCache, variables }) || [];
  const objectsWithRemoved = currentObjects.filter((objectItem) => objectItem.id !== sendgridEventId) || [];
  if (logLevel >= 2) console.log(' --> cacheWriteQuerySendgridEventRemove - objectsWithRemoved:', objectsWithRemoved);
  return cacheWriteQuerySendgridEventObjects({ apolloCache, variables, data: objectsWithRemoved, apolloBroadcast });
}

// Query Fetch Helper
//
export type QuerySendgridEventByIdApolloQueryResult = ApolloQueryResult<QuerySendgridEventByIdQuery>;
export type QuerySendgridEventByIdApolloQueryHelperResultEx = QuerySendgridEventByIdApolloQueryResult &
  SendgridEventByIdHelperResultEx;

async function querySendgridEventById({
  apolloClient,
  sendgridEventId,
  options,
}: {
  apolloClient: ApolloClient<object>;
  sendgridEventId: string;
  options?: Omit<QueryOptions<QuerySendgridEventByIdQueryVariables>, 'query' | 'variables'>;
}): Promise<QuerySendgridEventByIdApolloQueryHelperResultEx> {
  const query: QuerySendgridEventByIdApolloQueryResult = await apolloClient.query<QuerySendgridEventByIdQuery>({
    query: QuerySendgridEventByIdDocument,
    variables: { sendgridEventId },
    ...options,
  });

  return { ...query, sendgridEvent: query?.data?.sendgridEvent_by_pk };
}

// Query Watch ById Helper
//
export type WatchQuerySendgridEventByIdApolloObservableQuery = ObservableQuery<QuerySendgridEventByIdQuery>;
async function watchQuerySendgridEventById({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<QueryOptions<QuerySendgridEventByIdQueryVariables>, 'query'>;
}): Promise<WatchQuerySendgridEventByIdApolloObservableQuery> {
  return apolloClient.watchQuery<QuerySendgridEventByIdQuery>({ query: QuerySendgridEventByIdDocument, ...options });
}

// Query Fetch Objects Helper
//
export type QuerySendgridEventObjectsObjectsApolloQueryResult = ApolloQueryResult<QuerySendgridEventObjectsQuery>;
export type QuerySendgridEventObjectsObjectsApolloQueryResultEx = QuerySendgridEventObjectsObjectsApolloQueryResult &
  SendgridEventObjectsHelperResultEx;

async function querySendgridEventObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<QueryOptions<QuerySendgridEventObjectsQueryVariables>, 'query'>;
}): Promise<QuerySendgridEventObjectsObjectsApolloQueryResultEx> {
  const query: QuerySendgridEventObjectsObjectsApolloQueryResult = await apolloClient.query<QuerySendgridEventObjectsQuery>(
    { query: QuerySendgridEventObjectsDocument, ...options }
  );

  return { ...query, objects: query?.data?.sendgridEvent || [] };
}

// Query Watch Objects Helper
//
export type WatchQuerySendgridEventObjectsApolloObservableQuery = ObservableQuery<QuerySendgridEventObjectsQuery>;
async function watchQuerySendgridEventObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<QueryOptions<QuerySendgridEventObjectsQueryVariables>, 'query'>;
}): Promise<WatchQuerySendgridEventObjectsApolloObservableQuery> {
  return apolloClient.watchQuery<QuerySendgridEventObjectsQuery>({
    query: QuerySendgridEventObjectsDocument,
    ...options,
  });
}

// Subscription Fetch ById Helper
//
export type SubscribeToSendgridEventByIdSubscriptionFetchResult = FetchResult<
  SubscribeToSendgridEventByIdSubscription,
  Record<string, any>,
  Record<string, any>
>;
export type SubscribeToSendgridEventByIdSubscriptionFetchResultEx = FetchResult<
  SubscribeToSendgridEventByIdSubscription,
  Record<string, any>,
  Record<string, any>
> &
  SendgridEventByIdHelperResultEx;

async function subscribeToSendgridEventById({
  apolloClient,
  sendgridEventId,
  options,
}: {
  apolloClient: ApolloClient<object>;
  sendgridEventId: string;
  options?: Omit<SubscriptionOptions<SubscribeToSendgridEventByIdSubscriptionVariables>, 'query' | 'variables'>;
}): Promise<Observable<SubscribeToSendgridEventByIdSubscriptionFetchResultEx>> {
  const subscription: Observable<SubscribeToSendgridEventByIdSubscriptionFetchResult> = apolloClient.subscribe<SubscribeToSendgridEventByIdSubscription>(
    { query: SubscribeToSendgridEventByIdDocument, variables: { sendgridEventId }, ...options }
  );

  return subscription.map((value) => {
    return {
      context: value.context,
      errors: value.errors,
      data: value.data,
      extensions: value.extensions,
      sendgridEvent: value?.data?.sendgridEvent_by_pk || [],
    } as SubscribeToSendgridEventByIdSubscriptionFetchResultEx;
  });
}

// Subscription Fetch Objects Helper
//
export type SubscribeToSendgridEventObjectsSubscriptionFetchResult = FetchResult<
  SubscribeToSendgridEventObjectsSubscription,
  Record<string, any>,
  Record<string, any>
>;
export type SubscribeToSendgridEventObjectsSubscriptionFetchResultEx = FetchResult<
  SubscribeToSendgridEventObjectsSubscription,
  Record<string, any>,
  Record<string, any>
> &
  SendgridEventObjectsHelperResultEx;

async function subscribeToSendgridEventObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options?: Omit<SubscriptionOptions<SubscribeToSendgridEventObjectsSubscriptionVariables>, 'query'>;
}): Promise<Observable<SubscribeToSendgridEventObjectsSubscriptionFetchResultEx>> {
  const subscription: Observable<SubscribeToSendgridEventObjectsSubscriptionFetchResult> = apolloClient.subscribe<SubscribeToSendgridEventObjectsSubscription>(
    { query: SubscribeToSendgridEventObjectsDocument, ...options }
  );

  return subscription.map((value) => {
    return {
      context: value.context,
      errors: value.errors,
      data: value.data,
      extensions: value.extensions,
      objects: value?.data?.sendgridEvent || [],
    } as SubscribeToSendgridEventObjectsSubscriptionFetchResultEx;
  });
}

// Insert Helper
//
type InsertSendgridEventFetchResult = FetchResult<
  InsertSendgridEventMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertSendgridEventFetchHelperResultEx = InsertSendgridEventFetchResult & SendgridEventByIdHelperResultEx;

async function insertSendgridEvent({
  apolloClient,
  sendgridEvent,
  autoOptimisticResponse,
  fieldMap,
  options,
}: {
  apolloClient: ApolloClient<object>;
  sendgridEvent: SendgridEvent_Insert_Input;
  autoOptimisticResponse?: boolean;
  fieldMap?: FieldMap;
  options?: Omit<
    MutationOptions<InsertSendgridEventMutation, InsertSendgridEventMutationVariables>,
    'mutation' | 'variables'
  >;
}): Promise<InsertSendgridEventFetchHelperResultEx> {
  const objectForInsert = stripInsertInputClientFields({ input: sendgridEvent });
  const mutationOptions: MutationOptions<InsertSendgridEventMutation, InsertSendgridEventMutationVariables> = {
    mutation: InsertSendgridEventDocument,
    variables: { objects: [objectForInsert] },
    ...options,
  };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    if (!objectForInsert.id)
      throw new Error(`if autoOptimisticResponse = true, id must be set in object 'sendgridEvent'`);
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertSendgridEventMutation>({
      operationType: 'insert',
      entityName: 'sendgridEvent',
      objects: [objectForInsert as SendgridEvent_Insert_Input & ObjectWithId],
      fieldMap,
    });
    if (logLevel >= 2)
      console.log(' --> insertSendgridEvent - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: InsertSendgridEventFetchResult = await apolloClient.mutate<
    InsertSendgridEventMutation,
    InsertSendgridEventMutationVariables
  >(mutationOptions);

  return {
    ...mutation,
    sendgridEvent: mutation?.data?.insert_sendgridEvent?.returning && mutation.data.insert_sendgridEvent.returning[0],
  };
}

async function insertSendgridEventWithOnConflict({
  apolloClient,
  sendgridEvent,
  onConflict,
  autoOptimisticResponse,
  fieldMap,
  options,
}: {
  apolloClient: ApolloClient<object>;
  sendgridEvent: SendgridEvent_Insert_Input;
  onConflict: SendgridEvent_On_Conflict;
  autoOptimisticResponse?: boolean;
  fieldMap?: FieldMap;
  options?: Omit<
    MutationOptions<InsertSendgridEventWithOnConflictMutation, InsertSendgridEventWithOnConflictMutationVariables>,
    'mutation' | 'variables'
  >;
}): Promise<InsertSendgridEventFetchHelperResultEx> {
  const objectForInsert = stripInsertInputClientFields({ input: sendgridEvent });
  const mutationOptions: MutationOptions<
    InsertSendgridEventWithOnConflictMutation,
    InsertSendgridEventWithOnConflictMutationVariables
  > = {
    mutation: InsertSendgridEventWithOnConflictDocument,
    variables: { objects: [objectForInsert], onConflict },
    ...options,
  };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    if (!objectForInsert.id)
      throw new Error(`if autoOptimisticResponse = true, id must be set in object 'sendgridEvent'`);
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertSendgridEventWithOnConflictMutation>(
      {
        operationType: 'insert',
        entityName: 'sendgridEvent',
        objects: [objectForInsert as SendgridEvent_Insert_Input & ObjectWithId],
        fieldMap,
      }
    );
    if (logLevel >= 2)
      console.log(' --> insertSendgridEventWithOnConflict - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: InsertSendgridEventFetchResult = await apolloClient.mutate<
    InsertSendgridEventWithOnConflictMutation,
    InsertSendgridEventWithOnConflictMutationVariables
  >(mutationOptions);

  return {
    ...mutation,
    sendgridEvent: mutation?.data?.insert_sendgridEvent?.returning && mutation.data.insert_sendgridEvent.returning[0],
  };
}

// Insert Objects Helper
//
type InsertSendgridEventObjectsFetchResult = FetchResult<
  InsertSendgridEventMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertSendgridEventObjectsHelperResultEx = InsertSendgridEventObjectsFetchResult &
  SendgridEventObjectsHelperResultEx;

async function insertSendgridEventObjects({
  apolloClient,
  objects,
  autoOptimisticResponse,
  fieldMap,
  options,
}: {
  apolloClient: ApolloClient<object>;
  objects: SendgridEvent_Insert_Input[];
  autoOptimisticResponse?: boolean;
  fieldMap?: FieldMap;
  options?: Omit<
    MutationOptions<InsertSendgridEventMutation, InsertSendgridEventMutationVariables>,
    'mutation' | 'variables'
  >;
}): Promise<InsertSendgridEventObjectsHelperResultEx> {
  const objectsForInsert = objects.map((objectItem) => stripInsertInputClientFields({ input: objectItem }));
  const mutationOptions: MutationOptions<InsertSendgridEventMutation, InsertSendgridEventMutationVariables> = {
    mutation: InsertSendgridEventDocument,
    variables: { objects: objectsForInsert },
    ...options,
  };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    if (objectsForInsert.find((objectItem) => !objectItem.id))
      throw new Error(`if autoOptimisticResponse = true, ids must be set in objects`);
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertSendgridEventMutation>({
      operationType: 'insert',
      entityName: 'sendgridEvent',
      objects: objectsForInsert as (SendgridEvent_Insert_Input & ObjectWithId)[],
      fieldMap,
    });
    if (logLevel >= 2)
      console.log(' --> insertSendgridEvent - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: InsertSendgridEventObjectsFetchResult = await apolloClient.mutate<
    InsertSendgridEventMutation,
    InsertSendgridEventMutationVariables
  >(mutationOptions);

  return { ...mutation, objects: mutation?.data?.insert_sendgridEvent?.returning || [] };
}

// Update Helper
//
type UpdateSendgridEventByIdQueryResult = FetchResult<
  UpdateSendgridEventByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateSendgridEventByIdHelperResultEx = UpdateSendgridEventByIdQueryResult &
  SendgridEventByIdHelperResultEx;

async function updateSendgridEventById({
  apolloClient,
  sendgridEventId,
  set,
  autoOptimisticResponse,
  options,
}: {
  apolloClient: ApolloClient<object>;
  sendgridEventId: string;
  set: SendgridEvent_Set_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationOptions<UpdateSendgridEventByIdMutation, UpdateSendgridEventByIdMutationVariables>,
    'mutation'
  >;
}): Promise<UpdateSendgridEventByIdHelperResultEx> {
  const mutationOptions: MutationOptions<UpdateSendgridEventByIdMutation, UpdateSendgridEventByIdMutationVariables> = {
    mutation: UpdateSendgridEventByIdDocument,
    variables: { id: sendgridEventId, set },
    ...options,
  };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateSendgridEventByIdMutation>({
      operationType: 'update',
      entityName: 'sendgridEvent',
      objects: [{ id: sendgridEventId, ...set }],
    });
    if (logLevel >= 2)
      console.log(' --> updateSendgridEventById - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: UpdateSendgridEventByIdQueryResult = await apolloClient.mutate<
    UpdateSendgridEventByIdMutation,
    UpdateSendgridEventByIdMutationVariables
  >(mutationOptions);

  return {
    ...mutation,
    sendgridEvent: mutation?.data?.update_sendgridEvent?.returning && mutation.data.update_sendgridEvent.returning[0],
  };
}

// Update Objects Helper
//
type UpdateSendgridEventObjectsFetchResult = FetchResult<
  UpdateSendgridEventMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateSendgridEventObjectsHelperResultEx = UpdateSendgridEventObjectsFetchResult &
  SendgridEventObjectsHelperResultEx;

async function updateSendgridEventObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<MutationOptions<UpdateSendgridEventMutation, UpdateSendgridEventMutationVariables>, 'mutation'>;
}): Promise<UpdateSendgridEventObjectsHelperResultEx> {
  const mutation: UpdateSendgridEventObjectsFetchResult = await apolloClient.mutate<
    UpdateSendgridEventMutation,
    UpdateSendgridEventMutationVariables
  >({ mutation: UpdateSendgridEventDocument, ...options });

  return { ...mutation, objects: mutation?.data?.update_sendgridEvent?.returning || [] };
}

// Delete Helper
//

type RemoveSendgridEventModelByIdQueryResult = FetchResult<
  RemoveSendgridEventModelByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveSendgridEventModelByIdQueryHelperResultEx = RemoveSendgridEventModelByIdQueryResult &
  RemoveEntitiesQueryHelperResultEx;

async function removeSendgridEventModelById({
  apolloClient,
  sendgridEventId,
  autoOptimisticResponse,
  options,
}: {
  apolloClient: ApolloClient<object>;
  sendgridEventId: string;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationOptions<RemoveSendgridEventModelByIdMutation, RemoveSendgridEventModelByIdMutationVariables>,
    'mutation'
  >;
}): Promise<RemoveSendgridEventModelByIdQueryHelperResultEx> {
  const mutationOptions: MutationOptions<
    RemoveSendgridEventModelByIdMutation,
    RemoveSendgridEventModelByIdMutationVariables
  > = { mutation: RemoveSendgridEventModelByIdDocument, variables: { id: sendgridEventId }, ...options };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<RemoveSendgridEventModelByIdMutation>({
      operationType: 'delete',
      entityName: 'sendgridEvent',
      objects: [{ id: sendgridEventId }],
    });
    if (logLevel >= 2)
      console.log(' --> removeSendgridEventModelById - optimisticResponse:', mutationOptions.optimisticResponse);
  }
  if (!options || !options.update) {
    mutationOptions.update = generateUpdateFunctionForMutation<RemoveSendgridEventModelByIdMutation>({
      operationType: 'delete',
      entityName: 'sendgridEvent',
      entityId: sendgridEventId,
    });
  }

  const mutation: RemoveSendgridEventModelByIdQueryResult = await apolloClient.mutate<
    RemoveSendgridEventModelByIdMutation,
    RemoveSendgridEventModelByIdMutationVariables
  >(mutationOptions);

  return { ...mutation, affected_rows: mutation?.data?.delete_sendgridEvent?.affected_rows || 0 };
}

type RemoveSendgridEventModelObjectsQueryResult = FetchResult<
  RemoveSendgridEventModelMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveSendgridEventModelObjectsQueryHelperResultEx = RemoveSendgridEventModelObjectsQueryResult &
  RemoveEntitiesQueryHelperResultEx;

async function removeSendgridEventModelObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<
    MutationOptions<RemoveSendgridEventModelMutation, RemoveSendgridEventModelMutationVariables>,
    'mutation'
  >;
}): Promise<RemoveSendgridEventModelObjectsQueryHelperResultEx> {
  const mutation: RemoveSendgridEventModelObjectsQueryResult = await apolloClient.mutate<
    RemoveSendgridEventModelMutation,
    RemoveSendgridEventModelMutationVariables
  >({ mutation: RemoveSendgridEventModelDocument, ...options });

  return { ...mutation, affected_rows: mutation?.data?.delete_sendgridEvent?.affected_rows || 0 };
}

// SendgridEvent Fragment Helper Object
//------------------------------------------------

export const SendgridEventFragmentGQLHelper = {
  cacheGetDataId: cacheGetDataIdForSendgridEvent,
  cacheReadFragmentById: cacheReadFragmentSendgridEventById,
  cacheWriteFragmentById: cacheWriteFragmentSendgridEventById,
  cacheReadQueryById: cacheReadQuerySendgridEventById,
  cacheWriteQueryById: cacheWriteQuerySendgridEventById,
  cacheReadQueryObjects: cacheReadQuerySendgridEventObjects,
  cacheWriteQueryObjects: cacheWriteQuerySendgridEventObjects,
  cacheWriteQueryInsert: cacheWriteQuerySendgridEventInsert,
  cacheWriteQueryRemove: cacheWriteQuerySendgridEventRemove,
  queryById: querySendgridEventById,
  queryObjects: querySendgridEventObjects,
  watchQueryById: watchQuerySendgridEventById,
  watchQueryObjects: watchQuerySendgridEventObjects,
  subscribeToById: subscribeToSendgridEventById,
  subscribeToObjects: subscribeToSendgridEventObjects,
  insert: insertSendgridEvent,
  insertWithOnConflict: insertSendgridEventWithOnConflict,
  insertObjects: insertSendgridEventObjects,
  updateById: updateSendgridEventById,
  updateObjects: updateSendgridEventObjects,
};

// sendgridEvent Entity Helper Object
//------------------------------------------------

export const SendgridEventModelGQLHelper = {
  removeById: removeSendgridEventModelById,
  removeObjects: removeSendgridEventModelObjects,
};

// csvUpload HELPERS
//------------------------------------------------

export type CsvUploadByIdHelperResultEx = { csvUpload: CsvUploadFragment | null | undefined };
export type CsvUploadObjectsHelperResultEx = { objects: CsvUploadFragment[] };

// Direct Client & Cache Fragment Helpers
//
function cacheGetDataIdForCsvUpload(csvUploadId: string): string {
  return defaultCacheIdFromObject({ __typename: 'csvUpload', id: csvUploadId });
}

function cacheReadFragmentCsvUploadById({
  apolloCache,
  csvUploadId,
}: {
  apolloCache: ApolloCache<object>;
  csvUploadId: string;
}): CsvUploadFragment | null | undefined {
  return apolloCache.readFragment<CsvUploadFragment | null | undefined>({
    fragment: CsvUploadFragmentDoc,
    fragmentName: 'CsvUpload',
    id: defaultCacheIdFromObject({ __typename: 'csvUpload', id: csvUploadId }),
  });
}

function cacheWriteFragmentCsvUploadById({
  apolloCache,
  csvUploadId,
  csvUploadPartial,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  csvUploadId: string;
  csvUploadPartial: Partial<CsvUploadFragment> | CsvUpload_Insert_Input | null;
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): Partial<CsvUploadFragment> {
  const parsedFragment = convertToGraph({ input: csvUploadPartial, typename: 'csvUpload', fieldMap });
  if (logLevel >= 2) console.log(' --> cacheWriteFragmentCsvUploadById - parsedFragment:', parsedFragment);
  apolloCache.writeFragment<Partial<CsvUploadFragment> | null>({
    fragment: CsvUploadFragmentDoc,
    fragmentName: 'CsvUpload',
    id: defaultCacheIdFromObject({ ...parsedFragment, id: csvUploadId }),
    data: parsedFragment,
    broadcast: apolloBroadcast,
  });
  return parsedFragment;
}

function cacheReadQueryCsvUploadById({
  apolloCache,
  csvUploadId,
}: {
  apolloCache: ApolloCache<object>;
  csvUploadId: string;
}): CsvUploadFragment | null | undefined {
  try {
    return apolloCache.readQuery<CsvUploadFragment | null>({
      query: QueryCsvUploadByIdDocument,
      variables: { csvUploadId },
    });
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheReadQueryCsvUploadById threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheWriteQueryCsvUploadById({
  apolloCache,
  csvUploadId,
  csvUpload,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  csvUploadId: string;
  csvUpload: CsvUploadFragment | CsvUpload_Insert_Input | null;
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): void {
  try {
    const csvUploadPartial = convertToGraph({ input: csvUpload, typename: 'csvUpload', fieldMap });
    return apolloCache.writeQuery<CsvUploadFragment | null>({
      query: QueryCsvUploadByIdDocument,
      variables: { csvUploadId },
      data: csvUpload ? csvUploadPartial : null,
      broadcast: apolloBroadcast,
    });
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheWriteQueryCsvUploadById threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheReadQueryCsvUploadObjects({
  apolloCache,
  variables,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryCsvUploadObjectsQueryVariables;
}): CsvUpload[] | null | undefined {
  try {
    return (
      apolloCache.readQuery<{ CsvUpload: CsvUpload[] | null }>({ query: QueryCsvUploadObjectsDocument, variables })
        ?.CsvUpload || []
    );
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheReadQueryCsvUploadObjects threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheWriteQueryCsvUploadObjects({
  apolloCache,
  variables,
  data,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryCsvUploadObjectsQueryVariables;
  data: (CsvUpload | CsvUpload_Insert_Input)[];
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): void {
  try {
    const objects = convertToGraph({ input: data, typename: 'csvUpload', fieldMap });
    return apolloCache.writeQuery<{ CsvUpload: CsvUpload[] }>({
      query: QueryCsvUploadObjectsDocument,
      variables,
      data: { CsvUpload: objects },
      broadcast: apolloBroadcast,
    });
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheWriteQueryCsvUploadObjects threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheWriteQueryCsvUploadInsert({
  apolloCache,
  variables,
  csvUpload,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryCsvUploadObjectsQueryVariables;
  csvUpload: CsvUpload_Insert_Input;
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): void {
  const currentObjects = cacheReadQueryCsvUploadObjects({ apolloCache, variables }) || [];
  const objectsWithInserted = [
    ...currentObjects,
    convertToGraph({ input: csvUpload, typename: 'csvUpload', fieldMap }),
  ];
  if (logLevel >= 2) console.log(' --> cacheWriteQueryCsvUploadInsert - objectsWithInserted:', objectsWithInserted);
  return cacheWriteQueryCsvUploadObjects({ apolloCache, variables, data: objectsWithInserted, apolloBroadcast });
}

function cacheWriteQueryCsvUploadRemove({
  apolloCache,
  variables,
  csvUploadId,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryCsvUploadObjectsQueryVariables;
  csvUploadId: string;
  apolloBroadcast?: boolean;
}): void {
  const currentObjects = cacheReadQueryCsvUploadObjects({ apolloCache, variables }) || [];
  const objectsWithRemoved = currentObjects.filter((objectItem) => objectItem.id !== csvUploadId) || [];
  if (logLevel >= 2) console.log(' --> cacheWriteQueryCsvUploadRemove - objectsWithRemoved:', objectsWithRemoved);
  return cacheWriteQueryCsvUploadObjects({ apolloCache, variables, data: objectsWithRemoved, apolloBroadcast });
}

// Query Fetch Helper
//
export type QueryCsvUploadByIdApolloQueryResult = ApolloQueryResult<QueryCsvUploadByIdQuery>;
export type QueryCsvUploadByIdApolloQueryHelperResultEx = QueryCsvUploadByIdApolloQueryResult &
  CsvUploadByIdHelperResultEx;

async function queryCsvUploadById({
  apolloClient,
  csvUploadId,
  options,
}: {
  apolloClient: ApolloClient<object>;
  csvUploadId: string;
  options?: Omit<QueryOptions<QueryCsvUploadByIdQueryVariables>, 'query' | 'variables'>;
}): Promise<QueryCsvUploadByIdApolloQueryHelperResultEx> {
  const query: QueryCsvUploadByIdApolloQueryResult = await apolloClient.query<QueryCsvUploadByIdQuery>({
    query: QueryCsvUploadByIdDocument,
    variables: { csvUploadId },
    ...options,
  });

  return { ...query, csvUpload: query?.data?.csvUpload_by_pk };
}

// Query Watch ById Helper
//
export type WatchQueryCsvUploadByIdApolloObservableQuery = ObservableQuery<QueryCsvUploadByIdQuery>;
async function watchQueryCsvUploadById({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<QueryOptions<QueryCsvUploadByIdQueryVariables>, 'query'>;
}): Promise<WatchQueryCsvUploadByIdApolloObservableQuery> {
  return apolloClient.watchQuery<QueryCsvUploadByIdQuery>({ query: QueryCsvUploadByIdDocument, ...options });
}

// Query Fetch Objects Helper
//
export type QueryCsvUploadObjectsObjectsApolloQueryResult = ApolloQueryResult<QueryCsvUploadObjectsQuery>;
export type QueryCsvUploadObjectsObjectsApolloQueryResultEx = QueryCsvUploadObjectsObjectsApolloQueryResult &
  CsvUploadObjectsHelperResultEx;

async function queryCsvUploadObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<QueryOptions<QueryCsvUploadObjectsQueryVariables>, 'query'>;
}): Promise<QueryCsvUploadObjectsObjectsApolloQueryResultEx> {
  const query: QueryCsvUploadObjectsObjectsApolloQueryResult = await apolloClient.query<QueryCsvUploadObjectsQuery>({
    query: QueryCsvUploadObjectsDocument,
    ...options,
  });

  return { ...query, objects: query?.data?.csvUpload || [] };
}

// Query Watch Objects Helper
//
export type WatchQueryCsvUploadObjectsApolloObservableQuery = ObservableQuery<QueryCsvUploadObjectsQuery>;
async function watchQueryCsvUploadObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<QueryOptions<QueryCsvUploadObjectsQueryVariables>, 'query'>;
}): Promise<WatchQueryCsvUploadObjectsApolloObservableQuery> {
  return apolloClient.watchQuery<QueryCsvUploadObjectsQuery>({ query: QueryCsvUploadObjectsDocument, ...options });
}

// Subscription Fetch ById Helper
//
export type SubscribeToCsvUploadByIdSubscriptionFetchResult = FetchResult<
  SubscribeToCsvUploadByIdSubscription,
  Record<string, any>,
  Record<string, any>
>;
export type SubscribeToCsvUploadByIdSubscriptionFetchResultEx = FetchResult<
  SubscribeToCsvUploadByIdSubscription,
  Record<string, any>,
  Record<string, any>
> &
  CsvUploadByIdHelperResultEx;

async function subscribeToCsvUploadById({
  apolloClient,
  csvUploadId,
  options,
}: {
  apolloClient: ApolloClient<object>;
  csvUploadId: string;
  options?: Omit<SubscriptionOptions<SubscribeToCsvUploadByIdSubscriptionVariables>, 'query' | 'variables'>;
}): Promise<Observable<SubscribeToCsvUploadByIdSubscriptionFetchResultEx>> {
  const subscription: Observable<SubscribeToCsvUploadByIdSubscriptionFetchResult> = apolloClient.subscribe<SubscribeToCsvUploadByIdSubscription>(
    { query: SubscribeToCsvUploadByIdDocument, variables: { csvUploadId }, ...options }
  );

  return subscription.map((value) => {
    return {
      context: value.context,
      errors: value.errors,
      data: value.data,
      extensions: value.extensions,
      csvUpload: value?.data?.csvUpload_by_pk || [],
    } as SubscribeToCsvUploadByIdSubscriptionFetchResultEx;
  });
}

// Subscription Fetch Objects Helper
//
export type SubscribeToCsvUploadObjectsSubscriptionFetchResult = FetchResult<
  SubscribeToCsvUploadObjectsSubscription,
  Record<string, any>,
  Record<string, any>
>;
export type SubscribeToCsvUploadObjectsSubscriptionFetchResultEx = FetchResult<
  SubscribeToCsvUploadObjectsSubscription,
  Record<string, any>,
  Record<string, any>
> &
  CsvUploadObjectsHelperResultEx;

async function subscribeToCsvUploadObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options?: Omit<SubscriptionOptions<SubscribeToCsvUploadObjectsSubscriptionVariables>, 'query'>;
}): Promise<Observable<SubscribeToCsvUploadObjectsSubscriptionFetchResultEx>> {
  const subscription: Observable<SubscribeToCsvUploadObjectsSubscriptionFetchResult> = apolloClient.subscribe<SubscribeToCsvUploadObjectsSubscription>(
    { query: SubscribeToCsvUploadObjectsDocument, ...options }
  );

  return subscription.map((value) => {
    return {
      context: value.context,
      errors: value.errors,
      data: value.data,
      extensions: value.extensions,
      objects: value?.data?.csvUpload || [],
    } as SubscribeToCsvUploadObjectsSubscriptionFetchResultEx;
  });
}

// Insert Helper
//
type InsertCsvUploadFetchResult = FetchResult<InsertCsvUploadMutation, Record<string, any>, Record<string, any>>;
export type InsertCsvUploadFetchHelperResultEx = InsertCsvUploadFetchResult & CsvUploadByIdHelperResultEx;

async function insertCsvUpload({
  apolloClient,
  csvUpload,
  autoOptimisticResponse,
  fieldMap,
  options,
}: {
  apolloClient: ApolloClient<object>;
  csvUpload: CsvUpload_Insert_Input;
  autoOptimisticResponse?: boolean;
  fieldMap?: FieldMap;
  options?: Omit<MutationOptions<InsertCsvUploadMutation, InsertCsvUploadMutationVariables>, 'mutation' | 'variables'>;
}): Promise<InsertCsvUploadFetchHelperResultEx> {
  const objectForInsert = stripInsertInputClientFields({ input: csvUpload });
  const mutationOptions: MutationOptions<InsertCsvUploadMutation, InsertCsvUploadMutationVariables> = {
    mutation: InsertCsvUploadDocument,
    variables: { objects: [objectForInsert] },
    ...options,
  };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    if (!objectForInsert.id) throw new Error(`if autoOptimisticResponse = true, id must be set in object 'csvUpload'`);
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertCsvUploadMutation>({
      operationType: 'insert',
      entityName: 'csvUpload',
      objects: [objectForInsert as CsvUpload_Insert_Input & ObjectWithId],
      fieldMap,
    });
    if (logLevel >= 2) console.log(' --> insertCsvUpload - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: InsertCsvUploadFetchResult = await apolloClient.mutate<
    InsertCsvUploadMutation,
    InsertCsvUploadMutationVariables
  >(mutationOptions);

  return {
    ...mutation,
    csvUpload: mutation?.data?.insert_csvUpload?.returning && mutation.data.insert_csvUpload.returning[0],
  };
}

async function insertCsvUploadWithOnConflict({
  apolloClient,
  csvUpload,
  onConflict,
  autoOptimisticResponse,
  fieldMap,
  options,
}: {
  apolloClient: ApolloClient<object>;
  csvUpload: CsvUpload_Insert_Input;
  onConflict: CsvUpload_On_Conflict;
  autoOptimisticResponse?: boolean;
  fieldMap?: FieldMap;
  options?: Omit<
    MutationOptions<InsertCsvUploadWithOnConflictMutation, InsertCsvUploadWithOnConflictMutationVariables>,
    'mutation' | 'variables'
  >;
}): Promise<InsertCsvUploadFetchHelperResultEx> {
  const objectForInsert = stripInsertInputClientFields({ input: csvUpload });
  const mutationOptions: MutationOptions<
    InsertCsvUploadWithOnConflictMutation,
    InsertCsvUploadWithOnConflictMutationVariables
  > = {
    mutation: InsertCsvUploadWithOnConflictDocument,
    variables: { objects: [objectForInsert], onConflict },
    ...options,
  };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    if (!objectForInsert.id) throw new Error(`if autoOptimisticResponse = true, id must be set in object 'csvUpload'`);
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertCsvUploadWithOnConflictMutation>({
      operationType: 'insert',
      entityName: 'csvUpload',
      objects: [objectForInsert as CsvUpload_Insert_Input & ObjectWithId],
      fieldMap,
    });
    if (logLevel >= 2)
      console.log(' --> insertCsvUploadWithOnConflict - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: InsertCsvUploadFetchResult = await apolloClient.mutate<
    InsertCsvUploadWithOnConflictMutation,
    InsertCsvUploadWithOnConflictMutationVariables
  >(mutationOptions);

  return {
    ...mutation,
    csvUpload: mutation?.data?.insert_csvUpload?.returning && mutation.data.insert_csvUpload.returning[0],
  };
}

// Insert Objects Helper
//
type InsertCsvUploadObjectsFetchResult = FetchResult<InsertCsvUploadMutation, Record<string, any>, Record<string, any>>;
export type InsertCsvUploadObjectsHelperResultEx = InsertCsvUploadObjectsFetchResult & CsvUploadObjectsHelperResultEx;

async function insertCsvUploadObjects({
  apolloClient,
  objects,
  autoOptimisticResponse,
  fieldMap,
  options,
}: {
  apolloClient: ApolloClient<object>;
  objects: CsvUpload_Insert_Input[];
  autoOptimisticResponse?: boolean;
  fieldMap?: FieldMap;
  options?: Omit<MutationOptions<InsertCsvUploadMutation, InsertCsvUploadMutationVariables>, 'mutation' | 'variables'>;
}): Promise<InsertCsvUploadObjectsHelperResultEx> {
  const objectsForInsert = objects.map((objectItem) => stripInsertInputClientFields({ input: objectItem }));
  const mutationOptions: MutationOptions<InsertCsvUploadMutation, InsertCsvUploadMutationVariables> = {
    mutation: InsertCsvUploadDocument,
    variables: { objects: objectsForInsert },
    ...options,
  };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    if (objectsForInsert.find((objectItem) => !objectItem.id))
      throw new Error(`if autoOptimisticResponse = true, ids must be set in objects`);
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertCsvUploadMutation>({
      operationType: 'insert',
      entityName: 'csvUpload',
      objects: objectsForInsert as (CsvUpload_Insert_Input & ObjectWithId)[],
      fieldMap,
    });
    if (logLevel >= 2) console.log(' --> insertCsvUpload - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: InsertCsvUploadObjectsFetchResult = await apolloClient.mutate<
    InsertCsvUploadMutation,
    InsertCsvUploadMutationVariables
  >(mutationOptions);

  return { ...mutation, objects: mutation?.data?.insert_csvUpload?.returning || [] };
}

// Update Helper
//
type UpdateCsvUploadByIdQueryResult = FetchResult<
  UpdateCsvUploadByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateCsvUploadByIdHelperResultEx = UpdateCsvUploadByIdQueryResult & CsvUploadByIdHelperResultEx;

async function updateCsvUploadById({
  apolloClient,
  csvUploadId,
  set,
  autoOptimisticResponse,
  options,
}: {
  apolloClient: ApolloClient<object>;
  csvUploadId: string;
  set: CsvUpload_Set_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<MutationOptions<UpdateCsvUploadByIdMutation, UpdateCsvUploadByIdMutationVariables>, 'mutation'>;
}): Promise<UpdateCsvUploadByIdHelperResultEx> {
  const mutationOptions: MutationOptions<UpdateCsvUploadByIdMutation, UpdateCsvUploadByIdMutationVariables> = {
    mutation: UpdateCsvUploadByIdDocument,
    variables: { id: csvUploadId, set },
    ...options,
  };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateCsvUploadByIdMutation>({
      operationType: 'update',
      entityName: 'csvUpload',
      objects: [{ id: csvUploadId, ...set }],
    });
    if (logLevel >= 2)
      console.log(' --> updateCsvUploadById - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: UpdateCsvUploadByIdQueryResult = await apolloClient.mutate<
    UpdateCsvUploadByIdMutation,
    UpdateCsvUploadByIdMutationVariables
  >(mutationOptions);

  return {
    ...mutation,
    csvUpload: mutation?.data?.update_csvUpload?.returning && mutation.data.update_csvUpload.returning[0],
  };
}

// Update Objects Helper
//
type UpdateCsvUploadObjectsFetchResult = FetchResult<UpdateCsvUploadMutation, Record<string, any>, Record<string, any>>;
export type UpdateCsvUploadObjectsHelperResultEx = UpdateCsvUploadObjectsFetchResult & CsvUploadObjectsHelperResultEx;

async function updateCsvUploadObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<MutationOptions<UpdateCsvUploadMutation, UpdateCsvUploadMutationVariables>, 'mutation'>;
}): Promise<UpdateCsvUploadObjectsHelperResultEx> {
  const mutation: UpdateCsvUploadObjectsFetchResult = await apolloClient.mutate<
    UpdateCsvUploadMutation,
    UpdateCsvUploadMutationVariables
  >({ mutation: UpdateCsvUploadDocument, ...options });

  return { ...mutation, objects: mutation?.data?.update_csvUpload?.returning || [] };
}

// Delete Helper
//

type RemoveCsvUploadModelByIdQueryResult = FetchResult<
  RemoveCsvUploadModelByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveCsvUploadModelByIdQueryHelperResultEx = RemoveCsvUploadModelByIdQueryResult &
  RemoveEntitiesQueryHelperResultEx;

async function removeCsvUploadModelById({
  apolloClient,
  csvUploadId,
  autoOptimisticResponse,
  options,
}: {
  apolloClient: ApolloClient<object>;
  csvUploadId: string;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationOptions<RemoveCsvUploadModelByIdMutation, RemoveCsvUploadModelByIdMutationVariables>,
    'mutation'
  >;
}): Promise<RemoveCsvUploadModelByIdQueryHelperResultEx> {
  const mutationOptions: MutationOptions<
    RemoveCsvUploadModelByIdMutation,
    RemoveCsvUploadModelByIdMutationVariables
  > = { mutation: RemoveCsvUploadModelByIdDocument, variables: { id: csvUploadId }, ...options };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<RemoveCsvUploadModelByIdMutation>({
      operationType: 'delete',
      entityName: 'csvUpload',
      objects: [{ id: csvUploadId }],
    });
    if (logLevel >= 2)
      console.log(' --> removeCsvUploadModelById - optimisticResponse:', mutationOptions.optimisticResponse);
  }
  if (!options || !options.update) {
    mutationOptions.update = generateUpdateFunctionForMutation<RemoveCsvUploadModelByIdMutation>({
      operationType: 'delete',
      entityName: 'csvUpload',
      entityId: csvUploadId,
    });
  }

  const mutation: RemoveCsvUploadModelByIdQueryResult = await apolloClient.mutate<
    RemoveCsvUploadModelByIdMutation,
    RemoveCsvUploadModelByIdMutationVariables
  >(mutationOptions);

  return { ...mutation, affected_rows: mutation?.data?.delete_csvUpload?.affected_rows || 0 };
}

type RemoveCsvUploadModelObjectsQueryResult = FetchResult<
  RemoveCsvUploadModelMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveCsvUploadModelObjectsQueryHelperResultEx = RemoveCsvUploadModelObjectsQueryResult &
  RemoveEntitiesQueryHelperResultEx;

async function removeCsvUploadModelObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<MutationOptions<RemoveCsvUploadModelMutation, RemoveCsvUploadModelMutationVariables>, 'mutation'>;
}): Promise<RemoveCsvUploadModelObjectsQueryHelperResultEx> {
  const mutation: RemoveCsvUploadModelObjectsQueryResult = await apolloClient.mutate<
    RemoveCsvUploadModelMutation,
    RemoveCsvUploadModelMutationVariables
  >({ mutation: RemoveCsvUploadModelDocument, ...options });

  return { ...mutation, affected_rows: mutation?.data?.delete_csvUpload?.affected_rows || 0 };
}

// CsvUpload Fragment Helper Object
//------------------------------------------------

export const CsvUploadFragmentGQLHelper = {
  cacheGetDataId: cacheGetDataIdForCsvUpload,
  cacheReadFragmentById: cacheReadFragmentCsvUploadById,
  cacheWriteFragmentById: cacheWriteFragmentCsvUploadById,
  cacheReadQueryById: cacheReadQueryCsvUploadById,
  cacheWriteQueryById: cacheWriteQueryCsvUploadById,
  cacheReadQueryObjects: cacheReadQueryCsvUploadObjects,
  cacheWriteQueryObjects: cacheWriteQueryCsvUploadObjects,
  cacheWriteQueryInsert: cacheWriteQueryCsvUploadInsert,
  cacheWriteQueryRemove: cacheWriteQueryCsvUploadRemove,
  queryById: queryCsvUploadById,
  queryObjects: queryCsvUploadObjects,
  watchQueryById: watchQueryCsvUploadById,
  watchQueryObjects: watchQueryCsvUploadObjects,
  subscribeToById: subscribeToCsvUploadById,
  subscribeToObjects: subscribeToCsvUploadObjects,
  insert: insertCsvUpload,
  insertWithOnConflict: insertCsvUploadWithOnConflict,
  insertObjects: insertCsvUploadObjects,
  updateById: updateCsvUploadById,
  updateObjects: updateCsvUploadObjects,
};

// csvUpload Entity Helper Object
//------------------------------------------------

export const CsvUploadModelGQLHelper = {
  removeById: removeCsvUploadModelById,
  removeObjects: removeCsvUploadModelObjects,
};

// hubspotContactList HELPERS
//------------------------------------------------

export type HubspotContactListByIdHelperResultEx = {
  hubspotContactList: HubspotContactListFragment | null | undefined;
};
export type HubspotContactListObjectsHelperResultEx = { objects: HubspotContactListFragment[] };

// Direct Client & Cache Fragment Helpers
//
function cacheGetDataIdForHubspotContactList(hubspotContactListId: string): string {
  return defaultCacheIdFromObject({ __typename: 'hubspotContactList', id: hubspotContactListId });
}

function cacheReadFragmentHubspotContactListById({
  apolloCache,
  hubspotContactListId,
}: {
  apolloCache: ApolloCache<object>;
  hubspotContactListId: string;
}): HubspotContactListFragment | null | undefined {
  return apolloCache.readFragment<HubspotContactListFragment | null | undefined>({
    fragment: HubspotContactListFragmentDoc,
    fragmentName: 'HubspotContactList',
    id: defaultCacheIdFromObject({ __typename: 'hubspotContactList', id: hubspotContactListId }),
  });
}

function cacheWriteFragmentHubspotContactListById({
  apolloCache,
  hubspotContactListId,
  hubspotContactListPartial,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  hubspotContactListId: string;
  hubspotContactListPartial: Partial<HubspotContactListFragment> | HubspotContactList_Insert_Input | null;
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): Partial<HubspotContactListFragment> {
  const parsedFragment = convertToGraph({ input: hubspotContactListPartial, typename: 'hubspotContactList', fieldMap });
  if (logLevel >= 2) console.log(' --> cacheWriteFragmentHubspotContactListById - parsedFragment:', parsedFragment);
  apolloCache.writeFragment<Partial<HubspotContactListFragment> | null>({
    fragment: HubspotContactListFragmentDoc,
    fragmentName: 'HubspotContactList',
    id: defaultCacheIdFromObject({ ...parsedFragment, id: hubspotContactListId }),
    data: parsedFragment,
    broadcast: apolloBroadcast,
  });
  return parsedFragment;
}

function cacheReadQueryHubspotContactListById({
  apolloCache,
  hubspotContactListId,
}: {
  apolloCache: ApolloCache<object>;
  hubspotContactListId: string;
}): HubspotContactListFragment | null | undefined {
  try {
    return apolloCache.readQuery<HubspotContactListFragment | null>({
      query: QueryHubspotContactListByIdDocument,
      variables: { hubspotContactListId },
    });
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheReadQueryHubspotContactListById threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheWriteQueryHubspotContactListById({
  apolloCache,
  hubspotContactListId,
  hubspotContactList,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  hubspotContactListId: string;
  hubspotContactList: HubspotContactListFragment | HubspotContactList_Insert_Input | null;
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): void {
  try {
    const hubspotContactListPartial = convertToGraph({
      input: hubspotContactList,
      typename: 'hubspotContactList',
      fieldMap,
    });
    return apolloCache.writeQuery<HubspotContactListFragment | null>({
      query: QueryHubspotContactListByIdDocument,
      variables: { hubspotContactListId },
      data: hubspotContactList ? hubspotContactListPartial : null,
      broadcast: apolloBroadcast,
    });
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheWriteQueryHubspotContactListById threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheReadQueryHubspotContactListObjects({
  apolloCache,
  variables,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryHubspotContactListObjectsQueryVariables;
}): HubspotContactList[] | null | undefined {
  try {
    return (
      apolloCache.readQuery<{ HubspotContactList: HubspotContactList[] | null }>({
        query: QueryHubspotContactListObjectsDocument,
        variables,
      })?.HubspotContactList || []
    );
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheReadQueryHubspotContactListObjects threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheWriteQueryHubspotContactListObjects({
  apolloCache,
  variables,
  data,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryHubspotContactListObjectsQueryVariables;
  data: (HubspotContactList | HubspotContactList_Insert_Input)[];
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): void {
  try {
    const objects = convertToGraph({ input: data, typename: 'hubspotContactList', fieldMap });
    return apolloCache.writeQuery<{ HubspotContactList: HubspotContactList[] }>({
      query: QueryHubspotContactListObjectsDocument,
      variables,
      data: { HubspotContactList: objects },
      broadcast: apolloBroadcast,
    });
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheWriteQueryHubspotContactListObjects threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheWriteQueryHubspotContactListInsert({
  apolloCache,
  variables,
  hubspotContactList,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryHubspotContactListObjectsQueryVariables;
  hubspotContactList: HubspotContactList_Insert_Input;
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): void {
  const currentObjects = cacheReadQueryHubspotContactListObjects({ apolloCache, variables }) || [];
  const objectsWithInserted = [
    ...currentObjects,
    convertToGraph({ input: hubspotContactList, typename: 'hubspotContactList', fieldMap }),
  ];
  if (logLevel >= 2)
    console.log(' --> cacheWriteQueryHubspotContactListInsert - objectsWithInserted:', objectsWithInserted);
  return cacheWriteQueryHubspotContactListObjects({
    apolloCache,
    variables,
    data: objectsWithInserted,
    apolloBroadcast,
  });
}

function cacheWriteQueryHubspotContactListRemove({
  apolloCache,
  variables,
  hubspotContactListId,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryHubspotContactListObjectsQueryVariables;
  hubspotContactListId: string;
  apolloBroadcast?: boolean;
}): void {
  const currentObjects = cacheReadQueryHubspotContactListObjects({ apolloCache, variables }) || [];
  const objectsWithRemoved = currentObjects.filter((objectItem) => objectItem.id !== hubspotContactListId) || [];
  if (logLevel >= 2)
    console.log(' --> cacheWriteQueryHubspotContactListRemove - objectsWithRemoved:', objectsWithRemoved);
  return cacheWriteQueryHubspotContactListObjects({
    apolloCache,
    variables,
    data: objectsWithRemoved,
    apolloBroadcast,
  });
}

// Query Fetch Helper
//
export type QueryHubspotContactListByIdApolloQueryResult = ApolloQueryResult<QueryHubspotContactListByIdQuery>;
export type QueryHubspotContactListByIdApolloQueryHelperResultEx = QueryHubspotContactListByIdApolloQueryResult &
  HubspotContactListByIdHelperResultEx;

async function queryHubspotContactListById({
  apolloClient,
  hubspotContactListId,
  options,
}: {
  apolloClient: ApolloClient<object>;
  hubspotContactListId: string;
  options?: Omit<QueryOptions<QueryHubspotContactListByIdQueryVariables>, 'query' | 'variables'>;
}): Promise<QueryHubspotContactListByIdApolloQueryHelperResultEx> {
  const query: QueryHubspotContactListByIdApolloQueryResult = await apolloClient.query<QueryHubspotContactListByIdQuery>(
    { query: QueryHubspotContactListByIdDocument, variables: { hubspotContactListId }, ...options }
  );

  return { ...query, hubspotContactList: query?.data?.hubspotContactList_by_pk };
}

// Query Watch ById Helper
//
export type WatchQueryHubspotContactListByIdApolloObservableQuery = ObservableQuery<QueryHubspotContactListByIdQuery>;
async function watchQueryHubspotContactListById({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<QueryOptions<QueryHubspotContactListByIdQueryVariables>, 'query'>;
}): Promise<WatchQueryHubspotContactListByIdApolloObservableQuery> {
  return apolloClient.watchQuery<QueryHubspotContactListByIdQuery>({
    query: QueryHubspotContactListByIdDocument,
    ...options,
  });
}

// Query Fetch Objects Helper
//
export type QueryHubspotContactListObjectsObjectsApolloQueryResult = ApolloQueryResult<QueryHubspotContactListObjectsQuery>;
export type QueryHubspotContactListObjectsObjectsApolloQueryResultEx = QueryHubspotContactListObjectsObjectsApolloQueryResult &
  HubspotContactListObjectsHelperResultEx;

async function queryHubspotContactListObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<QueryOptions<QueryHubspotContactListObjectsQueryVariables>, 'query'>;
}): Promise<QueryHubspotContactListObjectsObjectsApolloQueryResultEx> {
  const query: QueryHubspotContactListObjectsObjectsApolloQueryResult = await apolloClient.query<QueryHubspotContactListObjectsQuery>(
    { query: QueryHubspotContactListObjectsDocument, ...options }
  );

  return { ...query, objects: query?.data?.hubspotContactList || [] };
}

// Query Watch Objects Helper
//
export type WatchQueryHubspotContactListObjectsApolloObservableQuery = ObservableQuery<QueryHubspotContactListObjectsQuery>;
async function watchQueryHubspotContactListObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<QueryOptions<QueryHubspotContactListObjectsQueryVariables>, 'query'>;
}): Promise<WatchQueryHubspotContactListObjectsApolloObservableQuery> {
  return apolloClient.watchQuery<QueryHubspotContactListObjectsQuery>({
    query: QueryHubspotContactListObjectsDocument,
    ...options,
  });
}

// Subscription Fetch ById Helper
//
export type SubscribeToHubspotContactListByIdSubscriptionFetchResult = FetchResult<
  SubscribeToHubspotContactListByIdSubscription,
  Record<string, any>,
  Record<string, any>
>;
export type SubscribeToHubspotContactListByIdSubscriptionFetchResultEx = FetchResult<
  SubscribeToHubspotContactListByIdSubscription,
  Record<string, any>,
  Record<string, any>
> &
  HubspotContactListByIdHelperResultEx;

async function subscribeToHubspotContactListById({
  apolloClient,
  hubspotContactListId,
  options,
}: {
  apolloClient: ApolloClient<object>;
  hubspotContactListId: string;
  options?: Omit<SubscriptionOptions<SubscribeToHubspotContactListByIdSubscriptionVariables>, 'query' | 'variables'>;
}): Promise<Observable<SubscribeToHubspotContactListByIdSubscriptionFetchResultEx>> {
  const subscription: Observable<SubscribeToHubspotContactListByIdSubscriptionFetchResult> = apolloClient.subscribe<SubscribeToHubspotContactListByIdSubscription>(
    { query: SubscribeToHubspotContactListByIdDocument, variables: { hubspotContactListId }, ...options }
  );

  return subscription.map((value) => {
    return {
      context: value.context,
      errors: value.errors,
      data: value.data,
      extensions: value.extensions,
      hubspotContactList: value?.data?.hubspotContactList_by_pk || [],
    } as SubscribeToHubspotContactListByIdSubscriptionFetchResultEx;
  });
}

// Subscription Fetch Objects Helper
//
export type SubscribeToHubspotContactListObjectsSubscriptionFetchResult = FetchResult<
  SubscribeToHubspotContactListObjectsSubscription,
  Record<string, any>,
  Record<string, any>
>;
export type SubscribeToHubspotContactListObjectsSubscriptionFetchResultEx = FetchResult<
  SubscribeToHubspotContactListObjectsSubscription,
  Record<string, any>,
  Record<string, any>
> &
  HubspotContactListObjectsHelperResultEx;

async function subscribeToHubspotContactListObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options?: Omit<SubscriptionOptions<SubscribeToHubspotContactListObjectsSubscriptionVariables>, 'query'>;
}): Promise<Observable<SubscribeToHubspotContactListObjectsSubscriptionFetchResultEx>> {
  const subscription: Observable<SubscribeToHubspotContactListObjectsSubscriptionFetchResult> = apolloClient.subscribe<SubscribeToHubspotContactListObjectsSubscription>(
    { query: SubscribeToHubspotContactListObjectsDocument, ...options }
  );

  return subscription.map((value) => {
    return {
      context: value.context,
      errors: value.errors,
      data: value.data,
      extensions: value.extensions,
      objects: value?.data?.hubspotContactList || [],
    } as SubscribeToHubspotContactListObjectsSubscriptionFetchResultEx;
  });
}

// Insert Helper
//
type InsertHubspotContactListFetchResult = FetchResult<
  InsertHubspotContactListMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertHubspotContactListFetchHelperResultEx = InsertHubspotContactListFetchResult &
  HubspotContactListByIdHelperResultEx;

async function insertHubspotContactList({
  apolloClient,
  hubspotContactList,
  autoOptimisticResponse,
  fieldMap,
  options,
}: {
  apolloClient: ApolloClient<object>;
  hubspotContactList: HubspotContactList_Insert_Input;
  autoOptimisticResponse?: boolean;
  fieldMap?: FieldMap;
  options?: Omit<
    MutationOptions<InsertHubspotContactListMutation, InsertHubspotContactListMutationVariables>,
    'mutation' | 'variables'
  >;
}): Promise<InsertHubspotContactListFetchHelperResultEx> {
  const objectForInsert = stripInsertInputClientFields({ input: hubspotContactList });
  const mutationOptions: MutationOptions<
    InsertHubspotContactListMutation,
    InsertHubspotContactListMutationVariables
  > = { mutation: InsertHubspotContactListDocument, variables: { objects: [objectForInsert] }, ...options };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    if (!objectForInsert.id)
      throw new Error(`if autoOptimisticResponse = true, id must be set in object 'hubspotContactList'`);
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertHubspotContactListMutation>({
      operationType: 'insert',
      entityName: 'hubspotContactList',
      objects: [objectForInsert as HubspotContactList_Insert_Input & ObjectWithId],
      fieldMap,
    });
    if (logLevel >= 2)
      console.log(' --> insertHubspotContactList - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: InsertHubspotContactListFetchResult = await apolloClient.mutate<
    InsertHubspotContactListMutation,
    InsertHubspotContactListMutationVariables
  >(mutationOptions);

  return {
    ...mutation,
    hubspotContactList:
      mutation?.data?.insert_hubspotContactList?.returning && mutation.data.insert_hubspotContactList.returning[0],
  };
}

async function insertHubspotContactListWithOnConflict({
  apolloClient,
  hubspotContactList,
  onConflict,
  autoOptimisticResponse,
  fieldMap,
  options,
}: {
  apolloClient: ApolloClient<object>;
  hubspotContactList: HubspotContactList_Insert_Input;
  onConflict: HubspotContactList_On_Conflict;
  autoOptimisticResponse?: boolean;
  fieldMap?: FieldMap;
  options?: Omit<
    MutationOptions<
      InsertHubspotContactListWithOnConflictMutation,
      InsertHubspotContactListWithOnConflictMutationVariables
    >,
    'mutation' | 'variables'
  >;
}): Promise<InsertHubspotContactListFetchHelperResultEx> {
  const objectForInsert = stripInsertInputClientFields({ input: hubspotContactList });
  const mutationOptions: MutationOptions<
    InsertHubspotContactListWithOnConflictMutation,
    InsertHubspotContactListWithOnConflictMutationVariables
  > = {
    mutation: InsertHubspotContactListWithOnConflictDocument,
    variables: { objects: [objectForInsert], onConflict },
    ...options,
  };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    if (!objectForInsert.id)
      throw new Error(`if autoOptimisticResponse = true, id must be set in object 'hubspotContactList'`);
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertHubspotContactListWithOnConflictMutation>(
      {
        operationType: 'insert',
        entityName: 'hubspotContactList',
        objects: [objectForInsert as HubspotContactList_Insert_Input & ObjectWithId],
        fieldMap,
      }
    );
    if (logLevel >= 2)
      console.log(
        ' --> insertHubspotContactListWithOnConflict - optimisticResponse:',
        mutationOptions.optimisticResponse
      );
  }

  const mutation: InsertHubspotContactListFetchResult = await apolloClient.mutate<
    InsertHubspotContactListWithOnConflictMutation,
    InsertHubspotContactListWithOnConflictMutationVariables
  >(mutationOptions);

  return {
    ...mutation,
    hubspotContactList:
      mutation?.data?.insert_hubspotContactList?.returning && mutation.data.insert_hubspotContactList.returning[0],
  };
}

// Insert Objects Helper
//
type InsertHubspotContactListObjectsFetchResult = FetchResult<
  InsertHubspotContactListMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertHubspotContactListObjectsHelperResultEx = InsertHubspotContactListObjectsFetchResult &
  HubspotContactListObjectsHelperResultEx;

async function insertHubspotContactListObjects({
  apolloClient,
  objects,
  autoOptimisticResponse,
  fieldMap,
  options,
}: {
  apolloClient: ApolloClient<object>;
  objects: HubspotContactList_Insert_Input[];
  autoOptimisticResponse?: boolean;
  fieldMap?: FieldMap;
  options?: Omit<
    MutationOptions<InsertHubspotContactListMutation, InsertHubspotContactListMutationVariables>,
    'mutation' | 'variables'
  >;
}): Promise<InsertHubspotContactListObjectsHelperResultEx> {
  const objectsForInsert = objects.map((objectItem) => stripInsertInputClientFields({ input: objectItem }));
  const mutationOptions: MutationOptions<
    InsertHubspotContactListMutation,
    InsertHubspotContactListMutationVariables
  > = { mutation: InsertHubspotContactListDocument, variables: { objects: objectsForInsert }, ...options };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    if (objectsForInsert.find((objectItem) => !objectItem.id))
      throw new Error(`if autoOptimisticResponse = true, ids must be set in objects`);
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertHubspotContactListMutation>({
      operationType: 'insert',
      entityName: 'hubspotContactList',
      objects: objectsForInsert as (HubspotContactList_Insert_Input & ObjectWithId)[],
      fieldMap,
    });
    if (logLevel >= 2)
      console.log(' --> insertHubspotContactList - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: InsertHubspotContactListObjectsFetchResult = await apolloClient.mutate<
    InsertHubspotContactListMutation,
    InsertHubspotContactListMutationVariables
  >(mutationOptions);

  return { ...mutation, objects: mutation?.data?.insert_hubspotContactList?.returning || [] };
}

// Update Helper
//
type UpdateHubspotContactListByIdQueryResult = FetchResult<
  UpdateHubspotContactListByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateHubspotContactListByIdHelperResultEx = UpdateHubspotContactListByIdQueryResult &
  HubspotContactListByIdHelperResultEx;

async function updateHubspotContactListById({
  apolloClient,
  hubspotContactListId,
  set,
  autoOptimisticResponse,
  options,
}: {
  apolloClient: ApolloClient<object>;
  hubspotContactListId: string;
  set: HubspotContactList_Set_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationOptions<UpdateHubspotContactListByIdMutation, UpdateHubspotContactListByIdMutationVariables>,
    'mutation'
  >;
}): Promise<UpdateHubspotContactListByIdHelperResultEx> {
  const mutationOptions: MutationOptions<
    UpdateHubspotContactListByIdMutation,
    UpdateHubspotContactListByIdMutationVariables
  > = { mutation: UpdateHubspotContactListByIdDocument, variables: { id: hubspotContactListId, set }, ...options };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateHubspotContactListByIdMutation>({
      operationType: 'update',
      entityName: 'hubspotContactList',
      objects: [{ id: hubspotContactListId, ...set }],
    });
    if (logLevel >= 2)
      console.log(' --> updateHubspotContactListById - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: UpdateHubspotContactListByIdQueryResult = await apolloClient.mutate<
    UpdateHubspotContactListByIdMutation,
    UpdateHubspotContactListByIdMutationVariables
  >(mutationOptions);

  return {
    ...mutation,
    hubspotContactList:
      mutation?.data?.update_hubspotContactList?.returning && mutation.data.update_hubspotContactList.returning[0],
  };
}

// Update Objects Helper
//
type UpdateHubspotContactListObjectsFetchResult = FetchResult<
  UpdateHubspotContactListMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateHubspotContactListObjectsHelperResultEx = UpdateHubspotContactListObjectsFetchResult &
  HubspotContactListObjectsHelperResultEx;

async function updateHubspotContactListObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<
    MutationOptions<UpdateHubspotContactListMutation, UpdateHubspotContactListMutationVariables>,
    'mutation'
  >;
}): Promise<UpdateHubspotContactListObjectsHelperResultEx> {
  const mutation: UpdateHubspotContactListObjectsFetchResult = await apolloClient.mutate<
    UpdateHubspotContactListMutation,
    UpdateHubspotContactListMutationVariables
  >({ mutation: UpdateHubspotContactListDocument, ...options });

  return { ...mutation, objects: mutation?.data?.update_hubspotContactList?.returning || [] };
}

// Delete Helper
//

type RemoveHubspotContactListModelByIdQueryResult = FetchResult<
  RemoveHubspotContactListModelByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveHubspotContactListModelByIdQueryHelperResultEx = RemoveHubspotContactListModelByIdQueryResult &
  RemoveEntitiesQueryHelperResultEx;

async function removeHubspotContactListModelById({
  apolloClient,
  hubspotContactListId,
  autoOptimisticResponse,
  options,
}: {
  apolloClient: ApolloClient<object>;
  hubspotContactListId: string;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationOptions<RemoveHubspotContactListModelByIdMutation, RemoveHubspotContactListModelByIdMutationVariables>,
    'mutation'
  >;
}): Promise<RemoveHubspotContactListModelByIdQueryHelperResultEx> {
  const mutationOptions: MutationOptions<
    RemoveHubspotContactListModelByIdMutation,
    RemoveHubspotContactListModelByIdMutationVariables
  > = { mutation: RemoveHubspotContactListModelByIdDocument, variables: { id: hubspotContactListId }, ...options };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<RemoveHubspotContactListModelByIdMutation>(
      { operationType: 'delete', entityName: 'hubspotContactList', objects: [{ id: hubspotContactListId }] }
    );
    if (logLevel >= 2)
      console.log(' --> removeHubspotContactListModelById - optimisticResponse:', mutationOptions.optimisticResponse);
  }
  if (!options || !options.update) {
    mutationOptions.update = generateUpdateFunctionForMutation<RemoveHubspotContactListModelByIdMutation>({
      operationType: 'delete',
      entityName: 'hubspotContactList',
      entityId: hubspotContactListId,
    });
  }

  const mutation: RemoveHubspotContactListModelByIdQueryResult = await apolloClient.mutate<
    RemoveHubspotContactListModelByIdMutation,
    RemoveHubspotContactListModelByIdMutationVariables
  >(mutationOptions);

  return { ...mutation, affected_rows: mutation?.data?.delete_hubspotContactList?.affected_rows || 0 };
}

type RemoveHubspotContactListModelObjectsQueryResult = FetchResult<
  RemoveHubspotContactListModelMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveHubspotContactListModelObjectsQueryHelperResultEx = RemoveHubspotContactListModelObjectsQueryResult &
  RemoveEntitiesQueryHelperResultEx;

async function removeHubspotContactListModelObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<
    MutationOptions<RemoveHubspotContactListModelMutation, RemoveHubspotContactListModelMutationVariables>,
    'mutation'
  >;
}): Promise<RemoveHubspotContactListModelObjectsQueryHelperResultEx> {
  const mutation: RemoveHubspotContactListModelObjectsQueryResult = await apolloClient.mutate<
    RemoveHubspotContactListModelMutation,
    RemoveHubspotContactListModelMutationVariables
  >({ mutation: RemoveHubspotContactListModelDocument, ...options });

  return { ...mutation, affected_rows: mutation?.data?.delete_hubspotContactList?.affected_rows || 0 };
}

// HubspotContactList Fragment Helper Object
//------------------------------------------------

export const HubspotContactListFragmentGQLHelper = {
  cacheGetDataId: cacheGetDataIdForHubspotContactList,
  cacheReadFragmentById: cacheReadFragmentHubspotContactListById,
  cacheWriteFragmentById: cacheWriteFragmentHubspotContactListById,
  cacheReadQueryById: cacheReadQueryHubspotContactListById,
  cacheWriteQueryById: cacheWriteQueryHubspotContactListById,
  cacheReadQueryObjects: cacheReadQueryHubspotContactListObjects,
  cacheWriteQueryObjects: cacheWriteQueryHubspotContactListObjects,
  cacheWriteQueryInsert: cacheWriteQueryHubspotContactListInsert,
  cacheWriteQueryRemove: cacheWriteQueryHubspotContactListRemove,
  queryById: queryHubspotContactListById,
  queryObjects: queryHubspotContactListObjects,
  watchQueryById: watchQueryHubspotContactListById,
  watchQueryObjects: watchQueryHubspotContactListObjects,
  subscribeToById: subscribeToHubspotContactListById,
  subscribeToObjects: subscribeToHubspotContactListObjects,
  insert: insertHubspotContactList,
  insertWithOnConflict: insertHubspotContactListWithOnConflict,
  insertObjects: insertHubspotContactListObjects,
  updateById: updateHubspotContactListById,
  updateObjects: updateHubspotContactListObjects,
};

// hubspotContactList Entity Helper Object
//------------------------------------------------

export const HubspotContactListModelGQLHelper = {
  removeById: removeHubspotContactListModelById,
  removeObjects: removeHubspotContactListModelObjects,
};

// hubspotContactListHubspotContact HELPERS
//------------------------------------------------

export type HubspotContactListHubspotContactByIdHelperResultEx = {
  hubspotContactListHubspotContact: HubspotContactListHubspotContactFragment | null | undefined;
};
export type HubspotContactListHubspotContactObjectsHelperResultEx = {
  objects: HubspotContactListHubspotContactFragment[];
};

// Direct Client & Cache Fragment Helpers
//
function cacheGetDataIdForHubspotContactListHubspotContact(hubspotContactListHubspotContactId: string): string {
  return defaultCacheIdFromObject({
    __typename: 'hubspotContactListHubspotContact',
    id: hubspotContactListHubspotContactId,
  });
}

function cacheReadFragmentHubspotContactListHubspotContactById({
  apolloCache,
  hubspotContactListHubspotContactId,
}: {
  apolloCache: ApolloCache<object>;
  hubspotContactListHubspotContactId: string;
}): HubspotContactListHubspotContactFragment | null | undefined {
  return apolloCache.readFragment<HubspotContactListHubspotContactFragment | null | undefined>({
    fragment: HubspotContactListHubspotContactFragmentDoc,
    fragmentName: 'HubspotContactListHubspotContact',
    id: defaultCacheIdFromObject({
      __typename: 'hubspotContactListHubspotContact',
      id: hubspotContactListHubspotContactId,
    }),
  });
}

function cacheWriteFragmentHubspotContactListHubspotContactById({
  apolloCache,
  hubspotContactListHubspotContactId,
  hubspotContactListHubspotContactPartial,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  hubspotContactListHubspotContactId: string;
  hubspotContactListHubspotContactPartial:
    | Partial<HubspotContactListHubspotContactFragment>
    | HubspotContactListHubspotContact_Insert_Input
    | null;
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): Partial<HubspotContactListHubspotContactFragment> {
  const parsedFragment = convertToGraph({
    input: hubspotContactListHubspotContactPartial,
    typename: 'hubspotContactListHubspotContact',
    fieldMap,
  });
  if (logLevel >= 2)
    console.log(' --> cacheWriteFragmentHubspotContactListHubspotContactById - parsedFragment:', parsedFragment);
  apolloCache.writeFragment<Partial<HubspotContactListHubspotContactFragment> | null>({
    fragment: HubspotContactListHubspotContactFragmentDoc,
    fragmentName: 'HubspotContactListHubspotContact',
    id: defaultCacheIdFromObject({ ...parsedFragment, id: hubspotContactListHubspotContactId }),
    data: parsedFragment,
    broadcast: apolloBroadcast,
  });
  return parsedFragment;
}

function cacheReadQueryHubspotContactListHubspotContactById({
  apolloCache,
  hubspotContactListHubspotContactId,
}: {
  apolloCache: ApolloCache<object>;
  hubspotContactListHubspotContactId: string;
}): HubspotContactListHubspotContactFragment | null | undefined {
  try {
    return apolloCache.readQuery<HubspotContactListHubspotContactFragment | null>({
      query: QueryHubspotContactListHubspotContactByIdDocument,
      variables: { hubspotContactListHubspotContactId },
    });
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheReadQueryHubspotContactListHubspotContactById threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheWriteQueryHubspotContactListHubspotContactById({
  apolloCache,
  hubspotContactListHubspotContactId,
  hubspotContactListHubspotContact,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  hubspotContactListHubspotContactId: string;
  hubspotContactListHubspotContact:
    | HubspotContactListHubspotContactFragment
    | HubspotContactListHubspotContact_Insert_Input
    | null;
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): void {
  try {
    const hubspotContactListHubspotContactPartial = convertToGraph({
      input: hubspotContactListHubspotContact,
      typename: 'hubspotContactListHubspotContact',
      fieldMap,
    });
    return apolloCache.writeQuery<HubspotContactListHubspotContactFragment | null>({
      query: QueryHubspotContactListHubspotContactByIdDocument,
      variables: { hubspotContactListHubspotContactId },
      data: hubspotContactListHubspotContact ? hubspotContactListHubspotContactPartial : null,
      broadcast: apolloBroadcast,
    });
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheWriteQueryHubspotContactListHubspotContactById threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheReadQueryHubspotContactListHubspotContactObjects({
  apolloCache,
  variables,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryHubspotContactListHubspotContactObjectsQueryVariables;
}): HubspotContactListHubspotContact[] | null | undefined {
  try {
    return (
      apolloCache.readQuery<{ HubspotContactListHubspotContact: HubspotContactListHubspotContact[] | null }>({
        query: QueryHubspotContactListHubspotContactObjectsDocument,
        variables,
      })?.HubspotContactListHubspotContact || []
    );
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheReadQueryHubspotContactListHubspotContactObjects threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheWriteQueryHubspotContactListHubspotContactObjects({
  apolloCache,
  variables,
  data,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryHubspotContactListHubspotContactObjectsQueryVariables;
  data: (HubspotContactListHubspotContact | HubspotContactListHubspotContact_Insert_Input)[];
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): void {
  try {
    const objects = convertToGraph({ input: data, typename: 'hubspotContactListHubspotContact', fieldMap });
    return apolloCache.writeQuery<{ HubspotContactListHubspotContact: HubspotContactListHubspotContact[] }>({
      query: QueryHubspotContactListHubspotContactObjectsDocument,
      variables,
      data: { HubspotContactListHubspotContact: objects },
      broadcast: apolloBroadcast,
    });
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheWriteQueryHubspotContactListHubspotContactObjects threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheWriteQueryHubspotContactListHubspotContactInsert({
  apolloCache,
  variables,
  hubspotContactListHubspotContact,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryHubspotContactListHubspotContactObjectsQueryVariables;
  hubspotContactListHubspotContact: HubspotContactListHubspotContact_Insert_Input;
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): void {
  const currentObjects = cacheReadQueryHubspotContactListHubspotContactObjects({ apolloCache, variables }) || [];
  const objectsWithInserted = [
    ...currentObjects,
    convertToGraph({ input: hubspotContactListHubspotContact, typename: 'hubspotContactListHubspotContact', fieldMap }),
  ];
  if (logLevel >= 2)
    console.log(
      ' --> cacheWriteQueryHubspotContactListHubspotContactInsert - objectsWithInserted:',
      objectsWithInserted
    );
  return cacheWriteQueryHubspotContactListHubspotContactObjects({
    apolloCache,
    variables,
    data: objectsWithInserted,
    apolloBroadcast,
  });
}

function cacheWriteQueryHubspotContactListHubspotContactRemove({
  apolloCache,
  variables,
  hubspotContactListHubspotContactId,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryHubspotContactListHubspotContactObjectsQueryVariables;
  hubspotContactListHubspotContactId: string;
  apolloBroadcast?: boolean;
}): void {
  const currentObjects = cacheReadQueryHubspotContactListHubspotContactObjects({ apolloCache, variables }) || [];
  const objectsWithRemoved =
    currentObjects.filter((objectItem) => objectItem.id !== hubspotContactListHubspotContactId) || [];
  if (logLevel >= 2)
    console.log(' --> cacheWriteQueryHubspotContactListHubspotContactRemove - objectsWithRemoved:', objectsWithRemoved);
  return cacheWriteQueryHubspotContactListHubspotContactObjects({
    apolloCache,
    variables,
    data: objectsWithRemoved,
    apolloBroadcast,
  });
}

// Query Fetch Helper
//
export type QueryHubspotContactListHubspotContactByIdApolloQueryResult = ApolloQueryResult<QueryHubspotContactListHubspotContactByIdQuery>;
export type QueryHubspotContactListHubspotContactByIdApolloQueryHelperResultEx = QueryHubspotContactListHubspotContactByIdApolloQueryResult &
  HubspotContactListHubspotContactByIdHelperResultEx;

async function queryHubspotContactListHubspotContactById({
  apolloClient,
  hubspotContactListHubspotContactId,
  options,
}: {
  apolloClient: ApolloClient<object>;
  hubspotContactListHubspotContactId: string;
  options?: Omit<QueryOptions<QueryHubspotContactListHubspotContactByIdQueryVariables>, 'query' | 'variables'>;
}): Promise<QueryHubspotContactListHubspotContactByIdApolloQueryHelperResultEx> {
  const query: QueryHubspotContactListHubspotContactByIdApolloQueryResult = await apolloClient.query<QueryHubspotContactListHubspotContactByIdQuery>(
    {
      query: QueryHubspotContactListHubspotContactByIdDocument,
      variables: { hubspotContactListHubspotContactId },
      ...options,
    }
  );

  return { ...query, hubspotContactListHubspotContact: query?.data?.hubspotContactListHubspotContact_by_pk };
}

// Query Watch ById Helper
//
export type WatchQueryHubspotContactListHubspotContactByIdApolloObservableQuery = ObservableQuery<QueryHubspotContactListHubspotContactByIdQuery>;
async function watchQueryHubspotContactListHubspotContactById({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<QueryOptions<QueryHubspotContactListHubspotContactByIdQueryVariables>, 'query'>;
}): Promise<WatchQueryHubspotContactListHubspotContactByIdApolloObservableQuery> {
  return apolloClient.watchQuery<QueryHubspotContactListHubspotContactByIdQuery>({
    query: QueryHubspotContactListHubspotContactByIdDocument,
    ...options,
  });
}

// Query Fetch Objects Helper
//
export type QueryHubspotContactListHubspotContactObjectsObjectsApolloQueryResult = ApolloQueryResult<QueryHubspotContactListHubspotContactObjectsQuery>;
export type QueryHubspotContactListHubspotContactObjectsObjectsApolloQueryResultEx = QueryHubspotContactListHubspotContactObjectsObjectsApolloQueryResult &
  HubspotContactListHubspotContactObjectsHelperResultEx;

async function queryHubspotContactListHubspotContactObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<QueryOptions<QueryHubspotContactListHubspotContactObjectsQueryVariables>, 'query'>;
}): Promise<QueryHubspotContactListHubspotContactObjectsObjectsApolloQueryResultEx> {
  const query: QueryHubspotContactListHubspotContactObjectsObjectsApolloQueryResult = await apolloClient.query<QueryHubspotContactListHubspotContactObjectsQuery>(
    { query: QueryHubspotContactListHubspotContactObjectsDocument, ...options }
  );

  return { ...query, objects: query?.data?.hubspotContactListHubspotContact || [] };
}

// Query Watch Objects Helper
//
export type WatchQueryHubspotContactListHubspotContactObjectsApolloObservableQuery = ObservableQuery<QueryHubspotContactListHubspotContactObjectsQuery>;
async function watchQueryHubspotContactListHubspotContactObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<QueryOptions<QueryHubspotContactListHubspotContactObjectsQueryVariables>, 'query'>;
}): Promise<WatchQueryHubspotContactListHubspotContactObjectsApolloObservableQuery> {
  return apolloClient.watchQuery<QueryHubspotContactListHubspotContactObjectsQuery>({
    query: QueryHubspotContactListHubspotContactObjectsDocument,
    ...options,
  });
}

// Subscription Fetch ById Helper
//
export type SubscribeToHubspotContactListHubspotContactByIdSubscriptionFetchResult = FetchResult<
  SubscribeToHubspotContactListHubspotContactByIdSubscription,
  Record<string, any>,
  Record<string, any>
>;
export type SubscribeToHubspotContactListHubspotContactByIdSubscriptionFetchResultEx = FetchResult<
  SubscribeToHubspotContactListHubspotContactByIdSubscription,
  Record<string, any>,
  Record<string, any>
> &
  HubspotContactListHubspotContactByIdHelperResultEx;

async function subscribeToHubspotContactListHubspotContactById({
  apolloClient,
  hubspotContactListHubspotContactId,
  options,
}: {
  apolloClient: ApolloClient<object>;
  hubspotContactListHubspotContactId: string;
  options?: Omit<
    SubscriptionOptions<SubscribeToHubspotContactListHubspotContactByIdSubscriptionVariables>,
    'query' | 'variables'
  >;
}): Promise<Observable<SubscribeToHubspotContactListHubspotContactByIdSubscriptionFetchResultEx>> {
  const subscription: Observable<SubscribeToHubspotContactListHubspotContactByIdSubscriptionFetchResult> = apolloClient.subscribe<SubscribeToHubspotContactListHubspotContactByIdSubscription>(
    {
      query: SubscribeToHubspotContactListHubspotContactByIdDocument,
      variables: { hubspotContactListHubspotContactId },
      ...options,
    }
  );

  return subscription.map((value) => {
    return {
      context: value.context,
      errors: value.errors,
      data: value.data,
      extensions: value.extensions,
      hubspotContactListHubspotContact: value?.data?.hubspotContactListHubspotContact_by_pk || [],
    } as SubscribeToHubspotContactListHubspotContactByIdSubscriptionFetchResultEx;
  });
}

// Subscription Fetch Objects Helper
//
export type SubscribeToHubspotContactListHubspotContactObjectsSubscriptionFetchResult = FetchResult<
  SubscribeToHubspotContactListHubspotContactObjectsSubscription,
  Record<string, any>,
  Record<string, any>
>;
export type SubscribeToHubspotContactListHubspotContactObjectsSubscriptionFetchResultEx = FetchResult<
  SubscribeToHubspotContactListHubspotContactObjectsSubscription,
  Record<string, any>,
  Record<string, any>
> &
  HubspotContactListHubspotContactObjectsHelperResultEx;

async function subscribeToHubspotContactListHubspotContactObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options?: Omit<SubscriptionOptions<SubscribeToHubspotContactListHubspotContactObjectsSubscriptionVariables>, 'query'>;
}): Promise<Observable<SubscribeToHubspotContactListHubspotContactObjectsSubscriptionFetchResultEx>> {
  const subscription: Observable<SubscribeToHubspotContactListHubspotContactObjectsSubscriptionFetchResult> = apolloClient.subscribe<SubscribeToHubspotContactListHubspotContactObjectsSubscription>(
    { query: SubscribeToHubspotContactListHubspotContactObjectsDocument, ...options }
  );

  return subscription.map((value) => {
    return {
      context: value.context,
      errors: value.errors,
      data: value.data,
      extensions: value.extensions,
      objects: value?.data?.hubspotContactListHubspotContact || [],
    } as SubscribeToHubspotContactListHubspotContactObjectsSubscriptionFetchResultEx;
  });
}

// Insert Helper
//
type InsertHubspotContactListHubspotContactFetchResult = FetchResult<
  InsertHubspotContactListHubspotContactMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertHubspotContactListHubspotContactFetchHelperResultEx = InsertHubspotContactListHubspotContactFetchResult &
  HubspotContactListHubspotContactByIdHelperResultEx;

async function insertHubspotContactListHubspotContact({
  apolloClient,
  hubspotContactListHubspotContact,
  autoOptimisticResponse,
  fieldMap,
  options,
}: {
  apolloClient: ApolloClient<object>;
  hubspotContactListHubspotContact: HubspotContactListHubspotContact_Insert_Input;
  autoOptimisticResponse?: boolean;
  fieldMap?: FieldMap;
  options?: Omit<
    MutationOptions<
      InsertHubspotContactListHubspotContactMutation,
      InsertHubspotContactListHubspotContactMutationVariables
    >,
    'mutation' | 'variables'
  >;
}): Promise<InsertHubspotContactListHubspotContactFetchHelperResultEx> {
  const objectForInsert = stripInsertInputClientFields({ input: hubspotContactListHubspotContact });
  const mutationOptions: MutationOptions<
    InsertHubspotContactListHubspotContactMutation,
    InsertHubspotContactListHubspotContactMutationVariables
  > = {
    mutation: InsertHubspotContactListHubspotContactDocument,
    variables: { objects: [objectForInsert] },
    ...options,
  };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    if (!objectForInsert.id)
      throw new Error(`if autoOptimisticResponse = true, id must be set in object 'hubspotContactListHubspotContact'`);
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertHubspotContactListHubspotContactMutation>(
      {
        operationType: 'insert',
        entityName: 'hubspotContactListHubspotContact',
        objects: [objectForInsert as HubspotContactListHubspotContact_Insert_Input & ObjectWithId],
        fieldMap,
      }
    );
    if (logLevel >= 2)
      console.log(
        ' --> insertHubspotContactListHubspotContact - optimisticResponse:',
        mutationOptions.optimisticResponse
      );
  }

  const mutation: InsertHubspotContactListHubspotContactFetchResult = await apolloClient.mutate<
    InsertHubspotContactListHubspotContactMutation,
    InsertHubspotContactListHubspotContactMutationVariables
  >(mutationOptions);

  return {
    ...mutation,
    hubspotContactListHubspotContact:
      mutation?.data?.insert_hubspotContactListHubspotContact?.returning &&
      mutation.data.insert_hubspotContactListHubspotContact.returning[0],
  };
}

async function insertHubspotContactListHubspotContactWithOnConflict({
  apolloClient,
  hubspotContactListHubspotContact,
  onConflict,
  autoOptimisticResponse,
  fieldMap,
  options,
}: {
  apolloClient: ApolloClient<object>;
  hubspotContactListHubspotContact: HubspotContactListHubspotContact_Insert_Input;
  onConflict: HubspotContactListHubspotContact_On_Conflict;
  autoOptimisticResponse?: boolean;
  fieldMap?: FieldMap;
  options?: Omit<
    MutationOptions<
      InsertHubspotContactListHubspotContactWithOnConflictMutation,
      InsertHubspotContactListHubspotContactWithOnConflictMutationVariables
    >,
    'mutation' | 'variables'
  >;
}): Promise<InsertHubspotContactListHubspotContactFetchHelperResultEx> {
  const objectForInsert = stripInsertInputClientFields({ input: hubspotContactListHubspotContact });
  const mutationOptions: MutationOptions<
    InsertHubspotContactListHubspotContactWithOnConflictMutation,
    InsertHubspotContactListHubspotContactWithOnConflictMutationVariables
  > = {
    mutation: InsertHubspotContactListHubspotContactWithOnConflictDocument,
    variables: { objects: [objectForInsert], onConflict },
    ...options,
  };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    if (!objectForInsert.id)
      throw new Error(`if autoOptimisticResponse = true, id must be set in object 'hubspotContactListHubspotContact'`);
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertHubspotContactListHubspotContactWithOnConflictMutation>(
      {
        operationType: 'insert',
        entityName: 'hubspotContactListHubspotContact',
        objects: [objectForInsert as HubspotContactListHubspotContact_Insert_Input & ObjectWithId],
        fieldMap,
      }
    );
    if (logLevel >= 2)
      console.log(
        ' --> insertHubspotContactListHubspotContactWithOnConflict - optimisticResponse:',
        mutationOptions.optimisticResponse
      );
  }

  const mutation: InsertHubspotContactListHubspotContactFetchResult = await apolloClient.mutate<
    InsertHubspotContactListHubspotContactWithOnConflictMutation,
    InsertHubspotContactListHubspotContactWithOnConflictMutationVariables
  >(mutationOptions);

  return {
    ...mutation,
    hubspotContactListHubspotContact:
      mutation?.data?.insert_hubspotContactListHubspotContact?.returning &&
      mutation.data.insert_hubspotContactListHubspotContact.returning[0],
  };
}

// Insert Objects Helper
//
type InsertHubspotContactListHubspotContactObjectsFetchResult = FetchResult<
  InsertHubspotContactListHubspotContactMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertHubspotContactListHubspotContactObjectsHelperResultEx = InsertHubspotContactListHubspotContactObjectsFetchResult &
  HubspotContactListHubspotContactObjectsHelperResultEx;

async function insertHubspotContactListHubspotContactObjects({
  apolloClient,
  objects,
  autoOptimisticResponse,
  fieldMap,
  options,
}: {
  apolloClient: ApolloClient<object>;
  objects: HubspotContactListHubspotContact_Insert_Input[];
  autoOptimisticResponse?: boolean;
  fieldMap?: FieldMap;
  options?: Omit<
    MutationOptions<
      InsertHubspotContactListHubspotContactMutation,
      InsertHubspotContactListHubspotContactMutationVariables
    >,
    'mutation' | 'variables'
  >;
}): Promise<InsertHubspotContactListHubspotContactObjectsHelperResultEx> {
  const objectsForInsert = objects.map((objectItem) => stripInsertInputClientFields({ input: objectItem }));
  const mutationOptions: MutationOptions<
    InsertHubspotContactListHubspotContactMutation,
    InsertHubspotContactListHubspotContactMutationVariables
  > = {
    mutation: InsertHubspotContactListHubspotContactDocument,
    variables: { objects: objectsForInsert },
    ...options,
  };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    if (objectsForInsert.find((objectItem) => !objectItem.id))
      throw new Error(`if autoOptimisticResponse = true, ids must be set in objects`);
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertHubspotContactListHubspotContactMutation>(
      {
        operationType: 'insert',
        entityName: 'hubspotContactListHubspotContact',
        objects: objectsForInsert as (HubspotContactListHubspotContact_Insert_Input & ObjectWithId)[],
        fieldMap,
      }
    );
    if (logLevel >= 2)
      console.log(
        ' --> insertHubspotContactListHubspotContact - optimisticResponse:',
        mutationOptions.optimisticResponse
      );
  }

  const mutation: InsertHubspotContactListHubspotContactObjectsFetchResult = await apolloClient.mutate<
    InsertHubspotContactListHubspotContactMutation,
    InsertHubspotContactListHubspotContactMutationVariables
  >(mutationOptions);

  return { ...mutation, objects: mutation?.data?.insert_hubspotContactListHubspotContact?.returning || [] };
}

// Update Helper
//
type UpdateHubspotContactListHubspotContactByIdQueryResult = FetchResult<
  UpdateHubspotContactListHubspotContactByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateHubspotContactListHubspotContactByIdHelperResultEx = UpdateHubspotContactListHubspotContactByIdQueryResult &
  HubspotContactListHubspotContactByIdHelperResultEx;

async function updateHubspotContactListHubspotContactById({
  apolloClient,
  hubspotContactListHubspotContactId,
  set,
  autoOptimisticResponse,
  options,
}: {
  apolloClient: ApolloClient<object>;
  hubspotContactListHubspotContactId: string;
  set: HubspotContactListHubspotContact_Set_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationOptions<
      UpdateHubspotContactListHubspotContactByIdMutation,
      UpdateHubspotContactListHubspotContactByIdMutationVariables
    >,
    'mutation'
  >;
}): Promise<UpdateHubspotContactListHubspotContactByIdHelperResultEx> {
  const mutationOptions: MutationOptions<
    UpdateHubspotContactListHubspotContactByIdMutation,
    UpdateHubspotContactListHubspotContactByIdMutationVariables
  > = {
    mutation: UpdateHubspotContactListHubspotContactByIdDocument,
    variables: { id: hubspotContactListHubspotContactId, set },
    ...options,
  };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateHubspotContactListHubspotContactByIdMutation>(
      {
        operationType: 'update',
        entityName: 'hubspotContactListHubspotContact',
        objects: [{ id: hubspotContactListHubspotContactId, ...set }],
      }
    );
    if (logLevel >= 2)
      console.log(
        ' --> updateHubspotContactListHubspotContactById - optimisticResponse:',
        mutationOptions.optimisticResponse
      );
  }

  const mutation: UpdateHubspotContactListHubspotContactByIdQueryResult = await apolloClient.mutate<
    UpdateHubspotContactListHubspotContactByIdMutation,
    UpdateHubspotContactListHubspotContactByIdMutationVariables
  >(mutationOptions);

  return {
    ...mutation,
    hubspotContactListHubspotContact:
      mutation?.data?.update_hubspotContactListHubspotContact?.returning &&
      mutation.data.update_hubspotContactListHubspotContact.returning[0],
  };
}

// Update Objects Helper
//
type UpdateHubspotContactListHubspotContactObjectsFetchResult = FetchResult<
  UpdateHubspotContactListHubspotContactMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateHubspotContactListHubspotContactObjectsHelperResultEx = UpdateHubspotContactListHubspotContactObjectsFetchResult &
  HubspotContactListHubspotContactObjectsHelperResultEx;

async function updateHubspotContactListHubspotContactObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<
    MutationOptions<
      UpdateHubspotContactListHubspotContactMutation,
      UpdateHubspotContactListHubspotContactMutationVariables
    >,
    'mutation'
  >;
}): Promise<UpdateHubspotContactListHubspotContactObjectsHelperResultEx> {
  const mutation: UpdateHubspotContactListHubspotContactObjectsFetchResult = await apolloClient.mutate<
    UpdateHubspotContactListHubspotContactMutation,
    UpdateHubspotContactListHubspotContactMutationVariables
  >({ mutation: UpdateHubspotContactListHubspotContactDocument, ...options });

  return { ...mutation, objects: mutation?.data?.update_hubspotContactListHubspotContact?.returning || [] };
}

// Delete Helper
//

type RemoveHubspotContactListHubspotContactModelByIdQueryResult = FetchResult<
  RemoveHubspotContactListHubspotContactModelByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveHubspotContactListHubspotContactModelByIdQueryHelperResultEx = RemoveHubspotContactListHubspotContactModelByIdQueryResult &
  RemoveEntitiesQueryHelperResultEx;

async function removeHubspotContactListHubspotContactModelById({
  apolloClient,
  hubspotContactListHubspotContactId,
  autoOptimisticResponse,
  options,
}: {
  apolloClient: ApolloClient<object>;
  hubspotContactListHubspotContactId: string;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationOptions<
      RemoveHubspotContactListHubspotContactModelByIdMutation,
      RemoveHubspotContactListHubspotContactModelByIdMutationVariables
    >,
    'mutation'
  >;
}): Promise<RemoveHubspotContactListHubspotContactModelByIdQueryHelperResultEx> {
  const mutationOptions: MutationOptions<
    RemoveHubspotContactListHubspotContactModelByIdMutation,
    RemoveHubspotContactListHubspotContactModelByIdMutationVariables
  > = {
    mutation: RemoveHubspotContactListHubspotContactModelByIdDocument,
    variables: { id: hubspotContactListHubspotContactId },
    ...options,
  };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<RemoveHubspotContactListHubspotContactModelByIdMutation>(
      {
        operationType: 'delete',
        entityName: 'hubspotContactListHubspotContact',
        objects: [{ id: hubspotContactListHubspotContactId }],
      }
    );
    if (logLevel >= 2)
      console.log(
        ' --> removeHubspotContactListHubspotContactModelById - optimisticResponse:',
        mutationOptions.optimisticResponse
      );
  }
  if (!options || !options.update) {
    mutationOptions.update = generateUpdateFunctionForMutation<RemoveHubspotContactListHubspotContactModelByIdMutation>(
      {
        operationType: 'delete',
        entityName: 'hubspotContactListHubspotContact',
        entityId: hubspotContactListHubspotContactId,
      }
    );
  }

  const mutation: RemoveHubspotContactListHubspotContactModelByIdQueryResult = await apolloClient.mutate<
    RemoveHubspotContactListHubspotContactModelByIdMutation,
    RemoveHubspotContactListHubspotContactModelByIdMutationVariables
  >(mutationOptions);

  return { ...mutation, affected_rows: mutation?.data?.delete_hubspotContactListHubspotContact?.affected_rows || 0 };
}

type RemoveHubspotContactListHubspotContactModelObjectsQueryResult = FetchResult<
  RemoveHubspotContactListHubspotContactModelMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveHubspotContactListHubspotContactModelObjectsQueryHelperResultEx = RemoveHubspotContactListHubspotContactModelObjectsQueryResult &
  RemoveEntitiesQueryHelperResultEx;

async function removeHubspotContactListHubspotContactModelObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<
    MutationOptions<
      RemoveHubspotContactListHubspotContactModelMutation,
      RemoveHubspotContactListHubspotContactModelMutationVariables
    >,
    'mutation'
  >;
}): Promise<RemoveHubspotContactListHubspotContactModelObjectsQueryHelperResultEx> {
  const mutation: RemoveHubspotContactListHubspotContactModelObjectsQueryResult = await apolloClient.mutate<
    RemoveHubspotContactListHubspotContactModelMutation,
    RemoveHubspotContactListHubspotContactModelMutationVariables
  >({ mutation: RemoveHubspotContactListHubspotContactModelDocument, ...options });

  return { ...mutation, affected_rows: mutation?.data?.delete_hubspotContactListHubspotContact?.affected_rows || 0 };
}

// HubspotContactListHubspotContact Fragment Helper Object
//------------------------------------------------

export const HubspotContactListHubspotContactFragmentGQLHelper = {
  cacheGetDataId: cacheGetDataIdForHubspotContactListHubspotContact,
  cacheReadFragmentById: cacheReadFragmentHubspotContactListHubspotContactById,
  cacheWriteFragmentById: cacheWriteFragmentHubspotContactListHubspotContactById,
  cacheReadQueryById: cacheReadQueryHubspotContactListHubspotContactById,
  cacheWriteQueryById: cacheWriteQueryHubspotContactListHubspotContactById,
  cacheReadQueryObjects: cacheReadQueryHubspotContactListHubspotContactObjects,
  cacheWriteQueryObjects: cacheWriteQueryHubspotContactListHubspotContactObjects,
  cacheWriteQueryInsert: cacheWriteQueryHubspotContactListHubspotContactInsert,
  cacheWriteQueryRemove: cacheWriteQueryHubspotContactListHubspotContactRemove,
  queryById: queryHubspotContactListHubspotContactById,
  queryObjects: queryHubspotContactListHubspotContactObjects,
  watchQueryById: watchQueryHubspotContactListHubspotContactById,
  watchQueryObjects: watchQueryHubspotContactListHubspotContactObjects,
  subscribeToById: subscribeToHubspotContactListHubspotContactById,
  subscribeToObjects: subscribeToHubspotContactListHubspotContactObjects,
  insert: insertHubspotContactListHubspotContact,
  insertWithOnConflict: insertHubspotContactListHubspotContactWithOnConflict,
  insertObjects: insertHubspotContactListHubspotContactObjects,
  updateById: updateHubspotContactListHubspotContactById,
  updateObjects: updateHubspotContactListHubspotContactObjects,
};

// hubspotContactListHubspotContact Entity Helper Object
//------------------------------------------------

export const HubspotContactListHubspotContactModelGQLHelper = {
  removeById: removeHubspotContactListHubspotContactModelById,
  removeObjects: removeHubspotContactListHubspotContactModelObjects,
};

// hubspotContactProperty HELPERS
//------------------------------------------------

export type HubspotContactPropertyByIdHelperResultEx = {
  hubspotContactProperty: HubspotContactPropertyFragment | null | undefined;
};
export type HubspotContactPropertyObjectsHelperResultEx = { objects: HubspotContactPropertyFragment[] };

// Direct Client & Cache Fragment Helpers
//
function cacheGetDataIdForHubspotContactProperty(hubspotContactPropertyId: string): string {
  return defaultCacheIdFromObject({ __typename: 'hubspotContactProperty', id: hubspotContactPropertyId });
}

function cacheReadFragmentHubspotContactPropertyById({
  apolloCache,
  hubspotContactPropertyId,
}: {
  apolloCache: ApolloCache<object>;
  hubspotContactPropertyId: string;
}): HubspotContactPropertyFragment | null | undefined {
  return apolloCache.readFragment<HubspotContactPropertyFragment | null | undefined>({
    fragment: HubspotContactPropertyFragmentDoc,
    fragmentName: 'HubspotContactProperty',
    id: defaultCacheIdFromObject({ __typename: 'hubspotContactProperty', id: hubspotContactPropertyId }),
  });
}

function cacheWriteFragmentHubspotContactPropertyById({
  apolloCache,
  hubspotContactPropertyId,
  hubspotContactPropertyPartial,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  hubspotContactPropertyId: string;
  hubspotContactPropertyPartial: Partial<HubspotContactPropertyFragment> | HubspotContactProperty_Insert_Input | null;
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): Partial<HubspotContactPropertyFragment> {
  const parsedFragment = convertToGraph({
    input: hubspotContactPropertyPartial,
    typename: 'hubspotContactProperty',
    fieldMap,
  });
  if (logLevel >= 2) console.log(' --> cacheWriteFragmentHubspotContactPropertyById - parsedFragment:', parsedFragment);
  apolloCache.writeFragment<Partial<HubspotContactPropertyFragment> | null>({
    fragment: HubspotContactPropertyFragmentDoc,
    fragmentName: 'HubspotContactProperty',
    id: defaultCacheIdFromObject({ ...parsedFragment, id: hubspotContactPropertyId }),
    data: parsedFragment,
    broadcast: apolloBroadcast,
  });
  return parsedFragment;
}

function cacheReadQueryHubspotContactPropertyById({
  apolloCache,
  hubspotContactPropertyId,
}: {
  apolloCache: ApolloCache<object>;
  hubspotContactPropertyId: string;
}): HubspotContactPropertyFragment | null | undefined {
  try {
    return apolloCache.readQuery<HubspotContactPropertyFragment | null>({
      query: QueryHubspotContactPropertyByIdDocument,
      variables: { hubspotContactPropertyId },
    });
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheReadQueryHubspotContactPropertyById threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheWriteQueryHubspotContactPropertyById({
  apolloCache,
  hubspotContactPropertyId,
  hubspotContactProperty,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  hubspotContactPropertyId: string;
  hubspotContactProperty: HubspotContactPropertyFragment | HubspotContactProperty_Insert_Input | null;
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): void {
  try {
    const hubspotContactPropertyPartial = convertToGraph({
      input: hubspotContactProperty,
      typename: 'hubspotContactProperty',
      fieldMap,
    });
    return apolloCache.writeQuery<HubspotContactPropertyFragment | null>({
      query: QueryHubspotContactPropertyByIdDocument,
      variables: { hubspotContactPropertyId },
      data: hubspotContactProperty ? hubspotContactPropertyPartial : null,
      broadcast: apolloBroadcast,
    });
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheWriteQueryHubspotContactPropertyById threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheReadQueryHubspotContactPropertyObjects({
  apolloCache,
  variables,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryHubspotContactPropertyObjectsQueryVariables;
}): HubspotContactProperty[] | null | undefined {
  try {
    return (
      apolloCache.readQuery<{ HubspotContactProperty: HubspotContactProperty[] | null }>({
        query: QueryHubspotContactPropertyObjectsDocument,
        variables,
      })?.HubspotContactProperty || []
    );
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheReadQueryHubspotContactPropertyObjects threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheWriteQueryHubspotContactPropertyObjects({
  apolloCache,
  variables,
  data,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryHubspotContactPropertyObjectsQueryVariables;
  data: (HubspotContactProperty | HubspotContactProperty_Insert_Input)[];
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): void {
  try {
    const objects = convertToGraph({ input: data, typename: 'hubspotContactProperty', fieldMap });
    return apolloCache.writeQuery<{ HubspotContactProperty: HubspotContactProperty[] }>({
      query: QueryHubspotContactPropertyObjectsDocument,
      variables,
      data: { HubspotContactProperty: objects },
      broadcast: apolloBroadcast,
    });
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheWriteQueryHubspotContactPropertyObjects threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheWriteQueryHubspotContactPropertyInsert({
  apolloCache,
  variables,
  hubspotContactProperty,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryHubspotContactPropertyObjectsQueryVariables;
  hubspotContactProperty: HubspotContactProperty_Insert_Input;
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): void {
  const currentObjects = cacheReadQueryHubspotContactPropertyObjects({ apolloCache, variables }) || [];
  const objectsWithInserted = [
    ...currentObjects,
    convertToGraph({ input: hubspotContactProperty, typename: 'hubspotContactProperty', fieldMap }),
  ];
  if (logLevel >= 2)
    console.log(' --> cacheWriteQueryHubspotContactPropertyInsert - objectsWithInserted:', objectsWithInserted);
  return cacheWriteQueryHubspotContactPropertyObjects({
    apolloCache,
    variables,
    data: objectsWithInserted,
    apolloBroadcast,
  });
}

function cacheWriteQueryHubspotContactPropertyRemove({
  apolloCache,
  variables,
  hubspotContactPropertyId,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryHubspotContactPropertyObjectsQueryVariables;
  hubspotContactPropertyId: string;
  apolloBroadcast?: boolean;
}): void {
  const currentObjects = cacheReadQueryHubspotContactPropertyObjects({ apolloCache, variables }) || [];
  const objectsWithRemoved = currentObjects.filter((objectItem) => objectItem.id !== hubspotContactPropertyId) || [];
  if (logLevel >= 2)
    console.log(' --> cacheWriteQueryHubspotContactPropertyRemove - objectsWithRemoved:', objectsWithRemoved);
  return cacheWriteQueryHubspotContactPropertyObjects({
    apolloCache,
    variables,
    data: objectsWithRemoved,
    apolloBroadcast,
  });
}

// Query Fetch Helper
//
export type QueryHubspotContactPropertyByIdApolloQueryResult = ApolloQueryResult<QueryHubspotContactPropertyByIdQuery>;
export type QueryHubspotContactPropertyByIdApolloQueryHelperResultEx = QueryHubspotContactPropertyByIdApolloQueryResult &
  HubspotContactPropertyByIdHelperResultEx;

async function queryHubspotContactPropertyById({
  apolloClient,
  hubspotContactPropertyId,
  options,
}: {
  apolloClient: ApolloClient<object>;
  hubspotContactPropertyId: string;
  options?: Omit<QueryOptions<QueryHubspotContactPropertyByIdQueryVariables>, 'query' | 'variables'>;
}): Promise<QueryHubspotContactPropertyByIdApolloQueryHelperResultEx> {
  const query: QueryHubspotContactPropertyByIdApolloQueryResult = await apolloClient.query<QueryHubspotContactPropertyByIdQuery>(
    { query: QueryHubspotContactPropertyByIdDocument, variables: { hubspotContactPropertyId }, ...options }
  );

  return { ...query, hubspotContactProperty: query?.data?.hubspotContactProperty_by_pk };
}

// Query Watch ById Helper
//
export type WatchQueryHubspotContactPropertyByIdApolloObservableQuery = ObservableQuery<QueryHubspotContactPropertyByIdQuery>;
async function watchQueryHubspotContactPropertyById({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<QueryOptions<QueryHubspotContactPropertyByIdQueryVariables>, 'query'>;
}): Promise<WatchQueryHubspotContactPropertyByIdApolloObservableQuery> {
  return apolloClient.watchQuery<QueryHubspotContactPropertyByIdQuery>({
    query: QueryHubspotContactPropertyByIdDocument,
    ...options,
  });
}

// Query Fetch Objects Helper
//
export type QueryHubspotContactPropertyObjectsObjectsApolloQueryResult = ApolloQueryResult<QueryHubspotContactPropertyObjectsQuery>;
export type QueryHubspotContactPropertyObjectsObjectsApolloQueryResultEx = QueryHubspotContactPropertyObjectsObjectsApolloQueryResult &
  HubspotContactPropertyObjectsHelperResultEx;

async function queryHubspotContactPropertyObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<QueryOptions<QueryHubspotContactPropertyObjectsQueryVariables>, 'query'>;
}): Promise<QueryHubspotContactPropertyObjectsObjectsApolloQueryResultEx> {
  const query: QueryHubspotContactPropertyObjectsObjectsApolloQueryResult = await apolloClient.query<QueryHubspotContactPropertyObjectsQuery>(
    { query: QueryHubspotContactPropertyObjectsDocument, ...options }
  );

  return { ...query, objects: query?.data?.hubspotContactProperty || [] };
}

// Query Watch Objects Helper
//
export type WatchQueryHubspotContactPropertyObjectsApolloObservableQuery = ObservableQuery<QueryHubspotContactPropertyObjectsQuery>;
async function watchQueryHubspotContactPropertyObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<QueryOptions<QueryHubspotContactPropertyObjectsQueryVariables>, 'query'>;
}): Promise<WatchQueryHubspotContactPropertyObjectsApolloObservableQuery> {
  return apolloClient.watchQuery<QueryHubspotContactPropertyObjectsQuery>({
    query: QueryHubspotContactPropertyObjectsDocument,
    ...options,
  });
}

// Subscription Fetch ById Helper
//
export type SubscribeToHubspotContactPropertyByIdSubscriptionFetchResult = FetchResult<
  SubscribeToHubspotContactPropertyByIdSubscription,
  Record<string, any>,
  Record<string, any>
>;
export type SubscribeToHubspotContactPropertyByIdSubscriptionFetchResultEx = FetchResult<
  SubscribeToHubspotContactPropertyByIdSubscription,
  Record<string, any>,
  Record<string, any>
> &
  HubspotContactPropertyByIdHelperResultEx;

async function subscribeToHubspotContactPropertyById({
  apolloClient,
  hubspotContactPropertyId,
  options,
}: {
  apolloClient: ApolloClient<object>;
  hubspotContactPropertyId: string;
  options?: Omit<
    SubscriptionOptions<SubscribeToHubspotContactPropertyByIdSubscriptionVariables>,
    'query' | 'variables'
  >;
}): Promise<Observable<SubscribeToHubspotContactPropertyByIdSubscriptionFetchResultEx>> {
  const subscription: Observable<SubscribeToHubspotContactPropertyByIdSubscriptionFetchResult> = apolloClient.subscribe<SubscribeToHubspotContactPropertyByIdSubscription>(
    { query: SubscribeToHubspotContactPropertyByIdDocument, variables: { hubspotContactPropertyId }, ...options }
  );

  return subscription.map((value) => {
    return {
      context: value.context,
      errors: value.errors,
      data: value.data,
      extensions: value.extensions,
      hubspotContactProperty: value?.data?.hubspotContactProperty_by_pk || [],
    } as SubscribeToHubspotContactPropertyByIdSubscriptionFetchResultEx;
  });
}

// Subscription Fetch Objects Helper
//
export type SubscribeToHubspotContactPropertyObjectsSubscriptionFetchResult = FetchResult<
  SubscribeToHubspotContactPropertyObjectsSubscription,
  Record<string, any>,
  Record<string, any>
>;
export type SubscribeToHubspotContactPropertyObjectsSubscriptionFetchResultEx = FetchResult<
  SubscribeToHubspotContactPropertyObjectsSubscription,
  Record<string, any>,
  Record<string, any>
> &
  HubspotContactPropertyObjectsHelperResultEx;

async function subscribeToHubspotContactPropertyObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options?: Omit<SubscriptionOptions<SubscribeToHubspotContactPropertyObjectsSubscriptionVariables>, 'query'>;
}): Promise<Observable<SubscribeToHubspotContactPropertyObjectsSubscriptionFetchResultEx>> {
  const subscription: Observable<SubscribeToHubspotContactPropertyObjectsSubscriptionFetchResult> = apolloClient.subscribe<SubscribeToHubspotContactPropertyObjectsSubscription>(
    { query: SubscribeToHubspotContactPropertyObjectsDocument, ...options }
  );

  return subscription.map((value) => {
    return {
      context: value.context,
      errors: value.errors,
      data: value.data,
      extensions: value.extensions,
      objects: value?.data?.hubspotContactProperty || [],
    } as SubscribeToHubspotContactPropertyObjectsSubscriptionFetchResultEx;
  });
}

// Insert Helper
//
type InsertHubspotContactPropertyFetchResult = FetchResult<
  InsertHubspotContactPropertyMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertHubspotContactPropertyFetchHelperResultEx = InsertHubspotContactPropertyFetchResult &
  HubspotContactPropertyByIdHelperResultEx;

async function insertHubspotContactProperty({
  apolloClient,
  hubspotContactProperty,
  autoOptimisticResponse,
  fieldMap,
  options,
}: {
  apolloClient: ApolloClient<object>;
  hubspotContactProperty: HubspotContactProperty_Insert_Input;
  autoOptimisticResponse?: boolean;
  fieldMap?: FieldMap;
  options?: Omit<
    MutationOptions<InsertHubspotContactPropertyMutation, InsertHubspotContactPropertyMutationVariables>,
    'mutation' | 'variables'
  >;
}): Promise<InsertHubspotContactPropertyFetchHelperResultEx> {
  const objectForInsert = stripInsertInputClientFields({ input: hubspotContactProperty });
  const mutationOptions: MutationOptions<
    InsertHubspotContactPropertyMutation,
    InsertHubspotContactPropertyMutationVariables
  > = { mutation: InsertHubspotContactPropertyDocument, variables: { objects: [objectForInsert] }, ...options };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    if (!objectForInsert.id)
      throw new Error(`if autoOptimisticResponse = true, id must be set in object 'hubspotContactProperty'`);
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertHubspotContactPropertyMutation>({
      operationType: 'insert',
      entityName: 'hubspotContactProperty',
      objects: [objectForInsert as HubspotContactProperty_Insert_Input & ObjectWithId],
      fieldMap,
    });
    if (logLevel >= 2)
      console.log(' --> insertHubspotContactProperty - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: InsertHubspotContactPropertyFetchResult = await apolloClient.mutate<
    InsertHubspotContactPropertyMutation,
    InsertHubspotContactPropertyMutationVariables
  >(mutationOptions);

  return {
    ...mutation,
    hubspotContactProperty:
      mutation?.data?.insert_hubspotContactProperty?.returning &&
      mutation.data.insert_hubspotContactProperty.returning[0],
  };
}

async function insertHubspotContactPropertyWithOnConflict({
  apolloClient,
  hubspotContactProperty,
  onConflict,
  autoOptimisticResponse,
  fieldMap,
  options,
}: {
  apolloClient: ApolloClient<object>;
  hubspotContactProperty: HubspotContactProperty_Insert_Input;
  onConflict: HubspotContactProperty_On_Conflict;
  autoOptimisticResponse?: boolean;
  fieldMap?: FieldMap;
  options?: Omit<
    MutationOptions<
      InsertHubspotContactPropertyWithOnConflictMutation,
      InsertHubspotContactPropertyWithOnConflictMutationVariables
    >,
    'mutation' | 'variables'
  >;
}): Promise<InsertHubspotContactPropertyFetchHelperResultEx> {
  const objectForInsert = stripInsertInputClientFields({ input: hubspotContactProperty });
  const mutationOptions: MutationOptions<
    InsertHubspotContactPropertyWithOnConflictMutation,
    InsertHubspotContactPropertyWithOnConflictMutationVariables
  > = {
    mutation: InsertHubspotContactPropertyWithOnConflictDocument,
    variables: { objects: [objectForInsert], onConflict },
    ...options,
  };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    if (!objectForInsert.id)
      throw new Error(`if autoOptimisticResponse = true, id must be set in object 'hubspotContactProperty'`);
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertHubspotContactPropertyWithOnConflictMutation>(
      {
        operationType: 'insert',
        entityName: 'hubspotContactProperty',
        objects: [objectForInsert as HubspotContactProperty_Insert_Input & ObjectWithId],
        fieldMap,
      }
    );
    if (logLevel >= 2)
      console.log(
        ' --> insertHubspotContactPropertyWithOnConflict - optimisticResponse:',
        mutationOptions.optimisticResponse
      );
  }

  const mutation: InsertHubspotContactPropertyFetchResult = await apolloClient.mutate<
    InsertHubspotContactPropertyWithOnConflictMutation,
    InsertHubspotContactPropertyWithOnConflictMutationVariables
  >(mutationOptions);

  return {
    ...mutation,
    hubspotContactProperty:
      mutation?.data?.insert_hubspotContactProperty?.returning &&
      mutation.data.insert_hubspotContactProperty.returning[0],
  };
}

// Insert Objects Helper
//
type InsertHubspotContactPropertyObjectsFetchResult = FetchResult<
  InsertHubspotContactPropertyMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertHubspotContactPropertyObjectsHelperResultEx = InsertHubspotContactPropertyObjectsFetchResult &
  HubspotContactPropertyObjectsHelperResultEx;

async function insertHubspotContactPropertyObjects({
  apolloClient,
  objects,
  autoOptimisticResponse,
  fieldMap,
  options,
}: {
  apolloClient: ApolloClient<object>;
  objects: HubspotContactProperty_Insert_Input[];
  autoOptimisticResponse?: boolean;
  fieldMap?: FieldMap;
  options?: Omit<
    MutationOptions<InsertHubspotContactPropertyMutation, InsertHubspotContactPropertyMutationVariables>,
    'mutation' | 'variables'
  >;
}): Promise<InsertHubspotContactPropertyObjectsHelperResultEx> {
  const objectsForInsert = objects.map((objectItem) => stripInsertInputClientFields({ input: objectItem }));
  const mutationOptions: MutationOptions<
    InsertHubspotContactPropertyMutation,
    InsertHubspotContactPropertyMutationVariables
  > = { mutation: InsertHubspotContactPropertyDocument, variables: { objects: objectsForInsert }, ...options };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    if (objectsForInsert.find((objectItem) => !objectItem.id))
      throw new Error(`if autoOptimisticResponse = true, ids must be set in objects`);
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertHubspotContactPropertyMutation>({
      operationType: 'insert',
      entityName: 'hubspotContactProperty',
      objects: objectsForInsert as (HubspotContactProperty_Insert_Input & ObjectWithId)[],
      fieldMap,
    });
    if (logLevel >= 2)
      console.log(' --> insertHubspotContactProperty - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: InsertHubspotContactPropertyObjectsFetchResult = await apolloClient.mutate<
    InsertHubspotContactPropertyMutation,
    InsertHubspotContactPropertyMutationVariables
  >(mutationOptions);

  return { ...mutation, objects: mutation?.data?.insert_hubspotContactProperty?.returning || [] };
}

// Update Helper
//
type UpdateHubspotContactPropertyByIdQueryResult = FetchResult<
  UpdateHubspotContactPropertyByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateHubspotContactPropertyByIdHelperResultEx = UpdateHubspotContactPropertyByIdQueryResult &
  HubspotContactPropertyByIdHelperResultEx;

async function updateHubspotContactPropertyById({
  apolloClient,
  hubspotContactPropertyId,
  set,
  autoOptimisticResponse,
  options,
}: {
  apolloClient: ApolloClient<object>;
  hubspotContactPropertyId: string;
  set: HubspotContactProperty_Set_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationOptions<UpdateHubspotContactPropertyByIdMutation, UpdateHubspotContactPropertyByIdMutationVariables>,
    'mutation'
  >;
}): Promise<UpdateHubspotContactPropertyByIdHelperResultEx> {
  const mutationOptions: MutationOptions<
    UpdateHubspotContactPropertyByIdMutation,
    UpdateHubspotContactPropertyByIdMutationVariables
  > = {
    mutation: UpdateHubspotContactPropertyByIdDocument,
    variables: { id: hubspotContactPropertyId, set },
    ...options,
  };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateHubspotContactPropertyByIdMutation>(
      {
        operationType: 'update',
        entityName: 'hubspotContactProperty',
        objects: [{ id: hubspotContactPropertyId, ...set }],
      }
    );
    if (logLevel >= 2)
      console.log(' --> updateHubspotContactPropertyById - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: UpdateHubspotContactPropertyByIdQueryResult = await apolloClient.mutate<
    UpdateHubspotContactPropertyByIdMutation,
    UpdateHubspotContactPropertyByIdMutationVariables
  >(mutationOptions);

  return {
    ...mutation,
    hubspotContactProperty:
      mutation?.data?.update_hubspotContactProperty?.returning &&
      mutation.data.update_hubspotContactProperty.returning[0],
  };
}

// Update Objects Helper
//
type UpdateHubspotContactPropertyObjectsFetchResult = FetchResult<
  UpdateHubspotContactPropertyMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateHubspotContactPropertyObjectsHelperResultEx = UpdateHubspotContactPropertyObjectsFetchResult &
  HubspotContactPropertyObjectsHelperResultEx;

async function updateHubspotContactPropertyObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<
    MutationOptions<UpdateHubspotContactPropertyMutation, UpdateHubspotContactPropertyMutationVariables>,
    'mutation'
  >;
}): Promise<UpdateHubspotContactPropertyObjectsHelperResultEx> {
  const mutation: UpdateHubspotContactPropertyObjectsFetchResult = await apolloClient.mutate<
    UpdateHubspotContactPropertyMutation,
    UpdateHubspotContactPropertyMutationVariables
  >({ mutation: UpdateHubspotContactPropertyDocument, ...options });

  return { ...mutation, objects: mutation?.data?.update_hubspotContactProperty?.returning || [] };
}

// Delete Helper
//

type RemoveHubspotContactPropertyModelByIdQueryResult = FetchResult<
  RemoveHubspotContactPropertyModelByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveHubspotContactPropertyModelByIdQueryHelperResultEx = RemoveHubspotContactPropertyModelByIdQueryResult &
  RemoveEntitiesQueryHelperResultEx;

async function removeHubspotContactPropertyModelById({
  apolloClient,
  hubspotContactPropertyId,
  autoOptimisticResponse,
  options,
}: {
  apolloClient: ApolloClient<object>;
  hubspotContactPropertyId: string;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationOptions<
      RemoveHubspotContactPropertyModelByIdMutation,
      RemoveHubspotContactPropertyModelByIdMutationVariables
    >,
    'mutation'
  >;
}): Promise<RemoveHubspotContactPropertyModelByIdQueryHelperResultEx> {
  const mutationOptions: MutationOptions<
    RemoveHubspotContactPropertyModelByIdMutation,
    RemoveHubspotContactPropertyModelByIdMutationVariables
  > = {
    mutation: RemoveHubspotContactPropertyModelByIdDocument,
    variables: { id: hubspotContactPropertyId },
    ...options,
  };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<RemoveHubspotContactPropertyModelByIdMutation>(
      { operationType: 'delete', entityName: 'hubspotContactProperty', objects: [{ id: hubspotContactPropertyId }] }
    );
    if (logLevel >= 2)
      console.log(
        ' --> removeHubspotContactPropertyModelById - optimisticResponse:',
        mutationOptions.optimisticResponse
      );
  }
  if (!options || !options.update) {
    mutationOptions.update = generateUpdateFunctionForMutation<RemoveHubspotContactPropertyModelByIdMutation>({
      operationType: 'delete',
      entityName: 'hubspotContactProperty',
      entityId: hubspotContactPropertyId,
    });
  }

  const mutation: RemoveHubspotContactPropertyModelByIdQueryResult = await apolloClient.mutate<
    RemoveHubspotContactPropertyModelByIdMutation,
    RemoveHubspotContactPropertyModelByIdMutationVariables
  >(mutationOptions);

  return { ...mutation, affected_rows: mutation?.data?.delete_hubspotContactProperty?.affected_rows || 0 };
}

type RemoveHubspotContactPropertyModelObjectsQueryResult = FetchResult<
  RemoveHubspotContactPropertyModelMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveHubspotContactPropertyModelObjectsQueryHelperResultEx = RemoveHubspotContactPropertyModelObjectsQueryResult &
  RemoveEntitiesQueryHelperResultEx;

async function removeHubspotContactPropertyModelObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<
    MutationOptions<RemoveHubspotContactPropertyModelMutation, RemoveHubspotContactPropertyModelMutationVariables>,
    'mutation'
  >;
}): Promise<RemoveHubspotContactPropertyModelObjectsQueryHelperResultEx> {
  const mutation: RemoveHubspotContactPropertyModelObjectsQueryResult = await apolloClient.mutate<
    RemoveHubspotContactPropertyModelMutation,
    RemoveHubspotContactPropertyModelMutationVariables
  >({ mutation: RemoveHubspotContactPropertyModelDocument, ...options });

  return { ...mutation, affected_rows: mutation?.data?.delete_hubspotContactProperty?.affected_rows || 0 };
}

// HubspotContactProperty Fragment Helper Object
//------------------------------------------------

export const HubspotContactPropertyFragmentGQLHelper = {
  cacheGetDataId: cacheGetDataIdForHubspotContactProperty,
  cacheReadFragmentById: cacheReadFragmentHubspotContactPropertyById,
  cacheWriteFragmentById: cacheWriteFragmentHubspotContactPropertyById,
  cacheReadQueryById: cacheReadQueryHubspotContactPropertyById,
  cacheWriteQueryById: cacheWriteQueryHubspotContactPropertyById,
  cacheReadQueryObjects: cacheReadQueryHubspotContactPropertyObjects,
  cacheWriteQueryObjects: cacheWriteQueryHubspotContactPropertyObjects,
  cacheWriteQueryInsert: cacheWriteQueryHubspotContactPropertyInsert,
  cacheWriteQueryRemove: cacheWriteQueryHubspotContactPropertyRemove,
  queryById: queryHubspotContactPropertyById,
  queryObjects: queryHubspotContactPropertyObjects,
  watchQueryById: watchQueryHubspotContactPropertyById,
  watchQueryObjects: watchQueryHubspotContactPropertyObjects,
  subscribeToById: subscribeToHubspotContactPropertyById,
  subscribeToObjects: subscribeToHubspotContactPropertyObjects,
  insert: insertHubspotContactProperty,
  insertWithOnConflict: insertHubspotContactPropertyWithOnConflict,
  insertObjects: insertHubspotContactPropertyObjects,
  updateById: updateHubspotContactPropertyById,
  updateObjects: updateHubspotContactPropertyObjects,
};

// hubspotContactProperty Entity Helper Object
//------------------------------------------------

export const HubspotContactPropertyModelGQLHelper = {
  removeById: removeHubspotContactPropertyModelById,
  removeObjects: removeHubspotContactPropertyModelObjects,
};

// salesforceContactField HELPERS
//------------------------------------------------

export type SalesforceContactFieldByIdHelperResultEx = {
  salesforceContactField: SalesforceContactFieldFragment | null | undefined;
};
export type SalesforceContactFieldObjectsHelperResultEx = { objects: SalesforceContactFieldFragment[] };

// Direct Client & Cache Fragment Helpers
//
function cacheGetDataIdForSalesforceContactField(salesforceContactFieldId: string): string {
  return defaultCacheIdFromObject({ __typename: 'salesforceContactField', id: salesforceContactFieldId });
}

function cacheReadFragmentSalesforceContactFieldById({
  apolloCache,
  salesforceContactFieldId,
}: {
  apolloCache: ApolloCache<object>;
  salesforceContactFieldId: string;
}): SalesforceContactFieldFragment | null | undefined {
  return apolloCache.readFragment<SalesforceContactFieldFragment | null | undefined>({
    fragment: SalesforceContactFieldFragmentDoc,
    fragmentName: 'SalesforceContactField',
    id: defaultCacheIdFromObject({ __typename: 'salesforceContactField', id: salesforceContactFieldId }),
  });
}

function cacheWriteFragmentSalesforceContactFieldById({
  apolloCache,
  salesforceContactFieldId,
  salesforceContactFieldPartial,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  salesforceContactFieldId: string;
  salesforceContactFieldPartial: Partial<SalesforceContactFieldFragment> | SalesforceContactField_Insert_Input | null;
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): Partial<SalesforceContactFieldFragment> {
  const parsedFragment = convertToGraph({
    input: salesforceContactFieldPartial,
    typename: 'salesforceContactField',
    fieldMap,
  });
  if (logLevel >= 2) console.log(' --> cacheWriteFragmentSalesforceContactFieldById - parsedFragment:', parsedFragment);
  apolloCache.writeFragment<Partial<SalesforceContactFieldFragment> | null>({
    fragment: SalesforceContactFieldFragmentDoc,
    fragmentName: 'SalesforceContactField',
    id: defaultCacheIdFromObject({ ...parsedFragment, id: salesforceContactFieldId }),
    data: parsedFragment,
    broadcast: apolloBroadcast,
  });
  return parsedFragment;
}

function cacheReadQuerySalesforceContactFieldById({
  apolloCache,
  salesforceContactFieldId,
}: {
  apolloCache: ApolloCache<object>;
  salesforceContactFieldId: string;
}): SalesforceContactFieldFragment | null | undefined {
  try {
    return apolloCache.readQuery<SalesforceContactFieldFragment | null>({
      query: QuerySalesforceContactFieldByIdDocument,
      variables: { salesforceContactFieldId },
    });
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheReadQuerySalesforceContactFieldById threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheWriteQuerySalesforceContactFieldById({
  apolloCache,
  salesforceContactFieldId,
  salesforceContactField,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  salesforceContactFieldId: string;
  salesforceContactField: SalesforceContactFieldFragment | SalesforceContactField_Insert_Input | null;
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): void {
  try {
    const salesforceContactFieldPartial = convertToGraph({
      input: salesforceContactField,
      typename: 'salesforceContactField',
      fieldMap,
    });
    return apolloCache.writeQuery<SalesforceContactFieldFragment | null>({
      query: QuerySalesforceContactFieldByIdDocument,
      variables: { salesforceContactFieldId },
      data: salesforceContactField ? salesforceContactFieldPartial : null,
      broadcast: apolloBroadcast,
    });
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheWriteQuerySalesforceContactFieldById threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheReadQuerySalesforceContactFieldObjects({
  apolloCache,
  variables,
}: {
  apolloCache: ApolloCache<object>;
  variables: QuerySalesforceContactFieldObjectsQueryVariables;
}): SalesforceContactField[] | null | undefined {
  try {
    return (
      apolloCache.readQuery<{ SalesforceContactField: SalesforceContactField[] | null }>({
        query: QuerySalesforceContactFieldObjectsDocument,
        variables,
      })?.SalesforceContactField || []
    );
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheReadQuerySalesforceContactFieldObjects threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheWriteQuerySalesforceContactFieldObjects({
  apolloCache,
  variables,
  data,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  variables: QuerySalesforceContactFieldObjectsQueryVariables;
  data: (SalesforceContactField | SalesforceContactField_Insert_Input)[];
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): void {
  try {
    const objects = convertToGraph({ input: data, typename: 'salesforceContactField', fieldMap });
    return apolloCache.writeQuery<{ SalesforceContactField: SalesforceContactField[] }>({
      query: QuerySalesforceContactFieldObjectsDocument,
      variables,
      data: { SalesforceContactField: objects },
      broadcast: apolloBroadcast,
    });
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheWriteQuerySalesforceContactFieldObjects threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheWriteQuerySalesforceContactFieldInsert({
  apolloCache,
  variables,
  salesforceContactField,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  variables: QuerySalesforceContactFieldObjectsQueryVariables;
  salesforceContactField: SalesforceContactField_Insert_Input;
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): void {
  const currentObjects = cacheReadQuerySalesforceContactFieldObjects({ apolloCache, variables }) || [];
  const objectsWithInserted = [
    ...currentObjects,
    convertToGraph({ input: salesforceContactField, typename: 'salesforceContactField', fieldMap }),
  ];
  if (logLevel >= 2)
    console.log(' --> cacheWriteQuerySalesforceContactFieldInsert - objectsWithInserted:', objectsWithInserted);
  return cacheWriteQuerySalesforceContactFieldObjects({
    apolloCache,
    variables,
    data: objectsWithInserted,
    apolloBroadcast,
  });
}

function cacheWriteQuerySalesforceContactFieldRemove({
  apolloCache,
  variables,
  salesforceContactFieldId,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  variables: QuerySalesforceContactFieldObjectsQueryVariables;
  salesforceContactFieldId: string;
  apolloBroadcast?: boolean;
}): void {
  const currentObjects = cacheReadQuerySalesforceContactFieldObjects({ apolloCache, variables }) || [];
  const objectsWithRemoved = currentObjects.filter((objectItem) => objectItem.id !== salesforceContactFieldId) || [];
  if (logLevel >= 2)
    console.log(' --> cacheWriteQuerySalesforceContactFieldRemove - objectsWithRemoved:', objectsWithRemoved);
  return cacheWriteQuerySalesforceContactFieldObjects({
    apolloCache,
    variables,
    data: objectsWithRemoved,
    apolloBroadcast,
  });
}

// Query Fetch Helper
//
export type QuerySalesforceContactFieldByIdApolloQueryResult = ApolloQueryResult<QuerySalesforceContactFieldByIdQuery>;
export type QuerySalesforceContactFieldByIdApolloQueryHelperResultEx = QuerySalesforceContactFieldByIdApolloQueryResult &
  SalesforceContactFieldByIdHelperResultEx;

async function querySalesforceContactFieldById({
  apolloClient,
  salesforceContactFieldId,
  options,
}: {
  apolloClient: ApolloClient<object>;
  salesforceContactFieldId: string;
  options?: Omit<QueryOptions<QuerySalesforceContactFieldByIdQueryVariables>, 'query' | 'variables'>;
}): Promise<QuerySalesforceContactFieldByIdApolloQueryHelperResultEx> {
  const query: QuerySalesforceContactFieldByIdApolloQueryResult = await apolloClient.query<QuerySalesforceContactFieldByIdQuery>(
    { query: QuerySalesforceContactFieldByIdDocument, variables: { salesforceContactFieldId }, ...options }
  );

  return { ...query, salesforceContactField: query?.data?.salesforceContactField_by_pk };
}

// Query Watch ById Helper
//
export type WatchQuerySalesforceContactFieldByIdApolloObservableQuery = ObservableQuery<QuerySalesforceContactFieldByIdQuery>;
async function watchQuerySalesforceContactFieldById({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<QueryOptions<QuerySalesforceContactFieldByIdQueryVariables>, 'query'>;
}): Promise<WatchQuerySalesforceContactFieldByIdApolloObservableQuery> {
  return apolloClient.watchQuery<QuerySalesforceContactFieldByIdQuery>({
    query: QuerySalesforceContactFieldByIdDocument,
    ...options,
  });
}

// Query Fetch Objects Helper
//
export type QuerySalesforceContactFieldObjectsObjectsApolloQueryResult = ApolloQueryResult<QuerySalesforceContactFieldObjectsQuery>;
export type QuerySalesforceContactFieldObjectsObjectsApolloQueryResultEx = QuerySalesforceContactFieldObjectsObjectsApolloQueryResult &
  SalesforceContactFieldObjectsHelperResultEx;

async function querySalesforceContactFieldObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<QueryOptions<QuerySalesforceContactFieldObjectsQueryVariables>, 'query'>;
}): Promise<QuerySalesforceContactFieldObjectsObjectsApolloQueryResultEx> {
  const query: QuerySalesforceContactFieldObjectsObjectsApolloQueryResult = await apolloClient.query<QuerySalesforceContactFieldObjectsQuery>(
    { query: QuerySalesforceContactFieldObjectsDocument, ...options }
  );

  return { ...query, objects: query?.data?.salesforceContactField || [] };
}

// Query Watch Objects Helper
//
export type WatchQuerySalesforceContactFieldObjectsApolloObservableQuery = ObservableQuery<QuerySalesforceContactFieldObjectsQuery>;
async function watchQuerySalesforceContactFieldObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<QueryOptions<QuerySalesforceContactFieldObjectsQueryVariables>, 'query'>;
}): Promise<WatchQuerySalesforceContactFieldObjectsApolloObservableQuery> {
  return apolloClient.watchQuery<QuerySalesforceContactFieldObjectsQuery>({
    query: QuerySalesforceContactFieldObjectsDocument,
    ...options,
  });
}

// Subscription Fetch ById Helper
//
export type SubscribeToSalesforceContactFieldByIdSubscriptionFetchResult = FetchResult<
  SubscribeToSalesforceContactFieldByIdSubscription,
  Record<string, any>,
  Record<string, any>
>;
export type SubscribeToSalesforceContactFieldByIdSubscriptionFetchResultEx = FetchResult<
  SubscribeToSalesforceContactFieldByIdSubscription,
  Record<string, any>,
  Record<string, any>
> &
  SalesforceContactFieldByIdHelperResultEx;

async function subscribeToSalesforceContactFieldById({
  apolloClient,
  salesforceContactFieldId,
  options,
}: {
  apolloClient: ApolloClient<object>;
  salesforceContactFieldId: string;
  options?: Omit<
    SubscriptionOptions<SubscribeToSalesforceContactFieldByIdSubscriptionVariables>,
    'query' | 'variables'
  >;
}): Promise<Observable<SubscribeToSalesforceContactFieldByIdSubscriptionFetchResultEx>> {
  const subscription: Observable<SubscribeToSalesforceContactFieldByIdSubscriptionFetchResult> = apolloClient.subscribe<SubscribeToSalesforceContactFieldByIdSubscription>(
    { query: SubscribeToSalesforceContactFieldByIdDocument, variables: { salesforceContactFieldId }, ...options }
  );

  return subscription.map((value) => {
    return {
      context: value.context,
      errors: value.errors,
      data: value.data,
      extensions: value.extensions,
      salesforceContactField: value?.data?.salesforceContactField_by_pk || [],
    } as SubscribeToSalesforceContactFieldByIdSubscriptionFetchResultEx;
  });
}

// Subscription Fetch Objects Helper
//
export type SubscribeToSalesforceContactFieldObjectsSubscriptionFetchResult = FetchResult<
  SubscribeToSalesforceContactFieldObjectsSubscription,
  Record<string, any>,
  Record<string, any>
>;
export type SubscribeToSalesforceContactFieldObjectsSubscriptionFetchResultEx = FetchResult<
  SubscribeToSalesforceContactFieldObjectsSubscription,
  Record<string, any>,
  Record<string, any>
> &
  SalesforceContactFieldObjectsHelperResultEx;

async function subscribeToSalesforceContactFieldObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options?: Omit<SubscriptionOptions<SubscribeToSalesforceContactFieldObjectsSubscriptionVariables>, 'query'>;
}): Promise<Observable<SubscribeToSalesforceContactFieldObjectsSubscriptionFetchResultEx>> {
  const subscription: Observable<SubscribeToSalesforceContactFieldObjectsSubscriptionFetchResult> = apolloClient.subscribe<SubscribeToSalesforceContactFieldObjectsSubscription>(
    { query: SubscribeToSalesforceContactFieldObjectsDocument, ...options }
  );

  return subscription.map((value) => {
    return {
      context: value.context,
      errors: value.errors,
      data: value.data,
      extensions: value.extensions,
      objects: value?.data?.salesforceContactField || [],
    } as SubscribeToSalesforceContactFieldObjectsSubscriptionFetchResultEx;
  });
}

// Insert Helper
//
type InsertSalesforceContactFieldFetchResult = FetchResult<
  InsertSalesforceContactFieldMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertSalesforceContactFieldFetchHelperResultEx = InsertSalesforceContactFieldFetchResult &
  SalesforceContactFieldByIdHelperResultEx;

async function insertSalesforceContactField({
  apolloClient,
  salesforceContactField,
  autoOptimisticResponse,
  fieldMap,
  options,
}: {
  apolloClient: ApolloClient<object>;
  salesforceContactField: SalesforceContactField_Insert_Input;
  autoOptimisticResponse?: boolean;
  fieldMap?: FieldMap;
  options?: Omit<
    MutationOptions<InsertSalesforceContactFieldMutation, InsertSalesforceContactFieldMutationVariables>,
    'mutation' | 'variables'
  >;
}): Promise<InsertSalesforceContactFieldFetchHelperResultEx> {
  const objectForInsert = stripInsertInputClientFields({ input: salesforceContactField });
  const mutationOptions: MutationOptions<
    InsertSalesforceContactFieldMutation,
    InsertSalesforceContactFieldMutationVariables
  > = { mutation: InsertSalesforceContactFieldDocument, variables: { objects: [objectForInsert] }, ...options };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    if (!objectForInsert.id)
      throw new Error(`if autoOptimisticResponse = true, id must be set in object 'salesforceContactField'`);
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertSalesforceContactFieldMutation>({
      operationType: 'insert',
      entityName: 'salesforceContactField',
      objects: [objectForInsert as SalesforceContactField_Insert_Input & ObjectWithId],
      fieldMap,
    });
    if (logLevel >= 2)
      console.log(' --> insertSalesforceContactField - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: InsertSalesforceContactFieldFetchResult = await apolloClient.mutate<
    InsertSalesforceContactFieldMutation,
    InsertSalesforceContactFieldMutationVariables
  >(mutationOptions);

  return {
    ...mutation,
    salesforceContactField:
      mutation?.data?.insert_salesforceContactField?.returning &&
      mutation.data.insert_salesforceContactField.returning[0],
  };
}

async function insertSalesforceContactFieldWithOnConflict({
  apolloClient,
  salesforceContactField,
  onConflict,
  autoOptimisticResponse,
  fieldMap,
  options,
}: {
  apolloClient: ApolloClient<object>;
  salesforceContactField: SalesforceContactField_Insert_Input;
  onConflict: SalesforceContactField_On_Conflict;
  autoOptimisticResponse?: boolean;
  fieldMap?: FieldMap;
  options?: Omit<
    MutationOptions<
      InsertSalesforceContactFieldWithOnConflictMutation,
      InsertSalesforceContactFieldWithOnConflictMutationVariables
    >,
    'mutation' | 'variables'
  >;
}): Promise<InsertSalesforceContactFieldFetchHelperResultEx> {
  const objectForInsert = stripInsertInputClientFields({ input: salesforceContactField });
  const mutationOptions: MutationOptions<
    InsertSalesforceContactFieldWithOnConflictMutation,
    InsertSalesforceContactFieldWithOnConflictMutationVariables
  > = {
    mutation: InsertSalesforceContactFieldWithOnConflictDocument,
    variables: { objects: [objectForInsert], onConflict },
    ...options,
  };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    if (!objectForInsert.id)
      throw new Error(`if autoOptimisticResponse = true, id must be set in object 'salesforceContactField'`);
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertSalesforceContactFieldWithOnConflictMutation>(
      {
        operationType: 'insert',
        entityName: 'salesforceContactField',
        objects: [objectForInsert as SalesforceContactField_Insert_Input & ObjectWithId],
        fieldMap,
      }
    );
    if (logLevel >= 2)
      console.log(
        ' --> insertSalesforceContactFieldWithOnConflict - optimisticResponse:',
        mutationOptions.optimisticResponse
      );
  }

  const mutation: InsertSalesforceContactFieldFetchResult = await apolloClient.mutate<
    InsertSalesforceContactFieldWithOnConflictMutation,
    InsertSalesforceContactFieldWithOnConflictMutationVariables
  >(mutationOptions);

  return {
    ...mutation,
    salesforceContactField:
      mutation?.data?.insert_salesforceContactField?.returning &&
      mutation.data.insert_salesforceContactField.returning[0],
  };
}

// Insert Objects Helper
//
type InsertSalesforceContactFieldObjectsFetchResult = FetchResult<
  InsertSalesforceContactFieldMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertSalesforceContactFieldObjectsHelperResultEx = InsertSalesforceContactFieldObjectsFetchResult &
  SalesforceContactFieldObjectsHelperResultEx;

async function insertSalesforceContactFieldObjects({
  apolloClient,
  objects,
  autoOptimisticResponse,
  fieldMap,
  options,
}: {
  apolloClient: ApolloClient<object>;
  objects: SalesforceContactField_Insert_Input[];
  autoOptimisticResponse?: boolean;
  fieldMap?: FieldMap;
  options?: Omit<
    MutationOptions<InsertSalesforceContactFieldMutation, InsertSalesforceContactFieldMutationVariables>,
    'mutation' | 'variables'
  >;
}): Promise<InsertSalesforceContactFieldObjectsHelperResultEx> {
  const objectsForInsert = objects.map((objectItem) => stripInsertInputClientFields({ input: objectItem }));
  const mutationOptions: MutationOptions<
    InsertSalesforceContactFieldMutation,
    InsertSalesforceContactFieldMutationVariables
  > = { mutation: InsertSalesforceContactFieldDocument, variables: { objects: objectsForInsert }, ...options };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    if (objectsForInsert.find((objectItem) => !objectItem.id))
      throw new Error(`if autoOptimisticResponse = true, ids must be set in objects`);
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertSalesforceContactFieldMutation>({
      operationType: 'insert',
      entityName: 'salesforceContactField',
      objects: objectsForInsert as (SalesforceContactField_Insert_Input & ObjectWithId)[],
      fieldMap,
    });
    if (logLevel >= 2)
      console.log(' --> insertSalesforceContactField - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: InsertSalesforceContactFieldObjectsFetchResult = await apolloClient.mutate<
    InsertSalesforceContactFieldMutation,
    InsertSalesforceContactFieldMutationVariables
  >(mutationOptions);

  return { ...mutation, objects: mutation?.data?.insert_salesforceContactField?.returning || [] };
}

// Update Helper
//
type UpdateSalesforceContactFieldByIdQueryResult = FetchResult<
  UpdateSalesforceContactFieldByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateSalesforceContactFieldByIdHelperResultEx = UpdateSalesforceContactFieldByIdQueryResult &
  SalesforceContactFieldByIdHelperResultEx;

async function updateSalesforceContactFieldById({
  apolloClient,
  salesforceContactFieldId,
  set,
  autoOptimisticResponse,
  options,
}: {
  apolloClient: ApolloClient<object>;
  salesforceContactFieldId: string;
  set: SalesforceContactField_Set_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationOptions<UpdateSalesforceContactFieldByIdMutation, UpdateSalesforceContactFieldByIdMutationVariables>,
    'mutation'
  >;
}): Promise<UpdateSalesforceContactFieldByIdHelperResultEx> {
  const mutationOptions: MutationOptions<
    UpdateSalesforceContactFieldByIdMutation,
    UpdateSalesforceContactFieldByIdMutationVariables
  > = {
    mutation: UpdateSalesforceContactFieldByIdDocument,
    variables: { id: salesforceContactFieldId, set },
    ...options,
  };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateSalesforceContactFieldByIdMutation>(
      {
        operationType: 'update',
        entityName: 'salesforceContactField',
        objects: [{ id: salesforceContactFieldId, ...set }],
      }
    );
    if (logLevel >= 2)
      console.log(' --> updateSalesforceContactFieldById - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: UpdateSalesforceContactFieldByIdQueryResult = await apolloClient.mutate<
    UpdateSalesforceContactFieldByIdMutation,
    UpdateSalesforceContactFieldByIdMutationVariables
  >(mutationOptions);

  return {
    ...mutation,
    salesforceContactField:
      mutation?.data?.update_salesforceContactField?.returning &&
      mutation.data.update_salesforceContactField.returning[0],
  };
}

// Update Objects Helper
//
type UpdateSalesforceContactFieldObjectsFetchResult = FetchResult<
  UpdateSalesforceContactFieldMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateSalesforceContactFieldObjectsHelperResultEx = UpdateSalesforceContactFieldObjectsFetchResult &
  SalesforceContactFieldObjectsHelperResultEx;

async function updateSalesforceContactFieldObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<
    MutationOptions<UpdateSalesforceContactFieldMutation, UpdateSalesforceContactFieldMutationVariables>,
    'mutation'
  >;
}): Promise<UpdateSalesforceContactFieldObjectsHelperResultEx> {
  const mutation: UpdateSalesforceContactFieldObjectsFetchResult = await apolloClient.mutate<
    UpdateSalesforceContactFieldMutation,
    UpdateSalesforceContactFieldMutationVariables
  >({ mutation: UpdateSalesforceContactFieldDocument, ...options });

  return { ...mutation, objects: mutation?.data?.update_salesforceContactField?.returning || [] };
}

// Delete Helper
//

type RemoveSalesforceContactFieldModelByIdQueryResult = FetchResult<
  RemoveSalesforceContactFieldModelByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveSalesforceContactFieldModelByIdQueryHelperResultEx = RemoveSalesforceContactFieldModelByIdQueryResult &
  RemoveEntitiesQueryHelperResultEx;

async function removeSalesforceContactFieldModelById({
  apolloClient,
  salesforceContactFieldId,
  autoOptimisticResponse,
  options,
}: {
  apolloClient: ApolloClient<object>;
  salesforceContactFieldId: string;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationOptions<
      RemoveSalesforceContactFieldModelByIdMutation,
      RemoveSalesforceContactFieldModelByIdMutationVariables
    >,
    'mutation'
  >;
}): Promise<RemoveSalesforceContactFieldModelByIdQueryHelperResultEx> {
  const mutationOptions: MutationOptions<
    RemoveSalesforceContactFieldModelByIdMutation,
    RemoveSalesforceContactFieldModelByIdMutationVariables
  > = {
    mutation: RemoveSalesforceContactFieldModelByIdDocument,
    variables: { id: salesforceContactFieldId },
    ...options,
  };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<RemoveSalesforceContactFieldModelByIdMutation>(
      { operationType: 'delete', entityName: 'salesforceContactField', objects: [{ id: salesforceContactFieldId }] }
    );
    if (logLevel >= 2)
      console.log(
        ' --> removeSalesforceContactFieldModelById - optimisticResponse:',
        mutationOptions.optimisticResponse
      );
  }
  if (!options || !options.update) {
    mutationOptions.update = generateUpdateFunctionForMutation<RemoveSalesforceContactFieldModelByIdMutation>({
      operationType: 'delete',
      entityName: 'salesforceContactField',
      entityId: salesforceContactFieldId,
    });
  }

  const mutation: RemoveSalesforceContactFieldModelByIdQueryResult = await apolloClient.mutate<
    RemoveSalesforceContactFieldModelByIdMutation,
    RemoveSalesforceContactFieldModelByIdMutationVariables
  >(mutationOptions);

  return { ...mutation, affected_rows: mutation?.data?.delete_salesforceContactField?.affected_rows || 0 };
}

type RemoveSalesforceContactFieldModelObjectsQueryResult = FetchResult<
  RemoveSalesforceContactFieldModelMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveSalesforceContactFieldModelObjectsQueryHelperResultEx = RemoveSalesforceContactFieldModelObjectsQueryResult &
  RemoveEntitiesQueryHelperResultEx;

async function removeSalesforceContactFieldModelObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<
    MutationOptions<RemoveSalesforceContactFieldModelMutation, RemoveSalesforceContactFieldModelMutationVariables>,
    'mutation'
  >;
}): Promise<RemoveSalesforceContactFieldModelObjectsQueryHelperResultEx> {
  const mutation: RemoveSalesforceContactFieldModelObjectsQueryResult = await apolloClient.mutate<
    RemoveSalesforceContactFieldModelMutation,
    RemoveSalesforceContactFieldModelMutationVariables
  >({ mutation: RemoveSalesforceContactFieldModelDocument, ...options });

  return { ...mutation, affected_rows: mutation?.data?.delete_salesforceContactField?.affected_rows || 0 };
}

// SalesforceContactField Fragment Helper Object
//------------------------------------------------

export const SalesforceContactFieldFragmentGQLHelper = {
  cacheGetDataId: cacheGetDataIdForSalesforceContactField,
  cacheReadFragmentById: cacheReadFragmentSalesforceContactFieldById,
  cacheWriteFragmentById: cacheWriteFragmentSalesforceContactFieldById,
  cacheReadQueryById: cacheReadQuerySalesforceContactFieldById,
  cacheWriteQueryById: cacheWriteQuerySalesforceContactFieldById,
  cacheReadQueryObjects: cacheReadQuerySalesforceContactFieldObjects,
  cacheWriteQueryObjects: cacheWriteQuerySalesforceContactFieldObjects,
  cacheWriteQueryInsert: cacheWriteQuerySalesforceContactFieldInsert,
  cacheWriteQueryRemove: cacheWriteQuerySalesforceContactFieldRemove,
  queryById: querySalesforceContactFieldById,
  queryObjects: querySalesforceContactFieldObjects,
  watchQueryById: watchQuerySalesforceContactFieldById,
  watchQueryObjects: watchQuerySalesforceContactFieldObjects,
  subscribeToById: subscribeToSalesforceContactFieldById,
  subscribeToObjects: subscribeToSalesforceContactFieldObjects,
  insert: insertSalesforceContactField,
  insertWithOnConflict: insertSalesforceContactFieldWithOnConflict,
  insertObjects: insertSalesforceContactFieldObjects,
  updateById: updateSalesforceContactFieldById,
  updateObjects: updateSalesforceContactFieldObjects,
};

// salesforceContactField Entity Helper Object
//------------------------------------------------

export const SalesforceContactFieldModelGQLHelper = {
  removeById: removeSalesforceContactFieldModelById,
  removeObjects: removeSalesforceContactFieldModelObjects,
};

// hubspotIntegration HELPERS
//------------------------------------------------

export type HubspotIntegrationByIdHelperResultEx = {
  hubspotIntegration: HubspotIntegrationFragment | null | undefined;
};
export type HubspotIntegrationObjectsHelperResultEx = { objects: HubspotIntegrationFragment[] };

// Direct Client & Cache Fragment Helpers
//
function cacheGetDataIdForHubspotIntegration(hubspotIntegrationId: string): string {
  return defaultCacheIdFromObject({ __typename: 'hubspotIntegration', id: hubspotIntegrationId });
}

function cacheReadFragmentHubspotIntegrationById({
  apolloCache,
  hubspotIntegrationId,
}: {
  apolloCache: ApolloCache<object>;
  hubspotIntegrationId: string;
}): HubspotIntegrationFragment | null | undefined {
  return apolloCache.readFragment<HubspotIntegrationFragment | null | undefined>({
    fragment: HubspotIntegrationFragmentDoc,
    fragmentName: 'HubspotIntegration',
    id: defaultCacheIdFromObject({ __typename: 'hubspotIntegration', id: hubspotIntegrationId }),
  });
}

function cacheWriteFragmentHubspotIntegrationById({
  apolloCache,
  hubspotIntegrationId,
  hubspotIntegrationPartial,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  hubspotIntegrationId: string;
  hubspotIntegrationPartial: Partial<HubspotIntegrationFragment> | HubspotIntegration_Insert_Input | null;
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): Partial<HubspotIntegrationFragment> {
  const parsedFragment = convertToGraph({ input: hubspotIntegrationPartial, typename: 'hubspotIntegration', fieldMap });
  if (logLevel >= 2) console.log(' --> cacheWriteFragmentHubspotIntegrationById - parsedFragment:', parsedFragment);
  apolloCache.writeFragment<Partial<HubspotIntegrationFragment> | null>({
    fragment: HubspotIntegrationFragmentDoc,
    fragmentName: 'HubspotIntegration',
    id: defaultCacheIdFromObject({ ...parsedFragment, id: hubspotIntegrationId }),
    data: parsedFragment,
    broadcast: apolloBroadcast,
  });
  return parsedFragment;
}

function cacheReadQueryHubspotIntegrationById({
  apolloCache,
  hubspotIntegrationId,
}: {
  apolloCache: ApolloCache<object>;
  hubspotIntegrationId: string;
}): HubspotIntegrationFragment | null | undefined {
  try {
    return apolloCache.readQuery<HubspotIntegrationFragment | null>({
      query: QueryHubspotIntegrationByIdDocument,
      variables: { hubspotIntegrationId },
    });
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheReadQueryHubspotIntegrationById threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheWriteQueryHubspotIntegrationById({
  apolloCache,
  hubspotIntegrationId,
  hubspotIntegration,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  hubspotIntegrationId: string;
  hubspotIntegration: HubspotIntegrationFragment | HubspotIntegration_Insert_Input | null;
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): void {
  try {
    const hubspotIntegrationPartial = convertToGraph({
      input: hubspotIntegration,
      typename: 'hubspotIntegration',
      fieldMap,
    });
    return apolloCache.writeQuery<HubspotIntegrationFragment | null>({
      query: QueryHubspotIntegrationByIdDocument,
      variables: { hubspotIntegrationId },
      data: hubspotIntegration ? hubspotIntegrationPartial : null,
      broadcast: apolloBroadcast,
    });
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheWriteQueryHubspotIntegrationById threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheReadQueryHubspotIntegrationObjects({
  apolloCache,
  variables,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryHubspotIntegrationObjectsQueryVariables;
}): HubspotIntegration[] | null | undefined {
  try {
    return (
      apolloCache.readQuery<{ HubspotIntegration: HubspotIntegration[] | null }>({
        query: QueryHubspotIntegrationObjectsDocument,
        variables,
      })?.HubspotIntegration || []
    );
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheReadQueryHubspotIntegrationObjects threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheWriteQueryHubspotIntegrationObjects({
  apolloCache,
  variables,
  data,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryHubspotIntegrationObjectsQueryVariables;
  data: (HubspotIntegration | HubspotIntegration_Insert_Input)[];
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): void {
  try {
    const objects = convertToGraph({ input: data, typename: 'hubspotIntegration', fieldMap });
    return apolloCache.writeQuery<{ HubspotIntegration: HubspotIntegration[] }>({
      query: QueryHubspotIntegrationObjectsDocument,
      variables,
      data: { HubspotIntegration: objects },
      broadcast: apolloBroadcast,
    });
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheWriteQueryHubspotIntegrationObjects threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheWriteQueryHubspotIntegrationInsert({
  apolloCache,
  variables,
  hubspotIntegration,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryHubspotIntegrationObjectsQueryVariables;
  hubspotIntegration: HubspotIntegration_Insert_Input;
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): void {
  const currentObjects = cacheReadQueryHubspotIntegrationObjects({ apolloCache, variables }) || [];
  const objectsWithInserted = [
    ...currentObjects,
    convertToGraph({ input: hubspotIntegration, typename: 'hubspotIntegration', fieldMap }),
  ];
  if (logLevel >= 2)
    console.log(' --> cacheWriteQueryHubspotIntegrationInsert - objectsWithInserted:', objectsWithInserted);
  return cacheWriteQueryHubspotIntegrationObjects({
    apolloCache,
    variables,
    data: objectsWithInserted,
    apolloBroadcast,
  });
}

function cacheWriteQueryHubspotIntegrationRemove({
  apolloCache,
  variables,
  hubspotIntegrationId,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryHubspotIntegrationObjectsQueryVariables;
  hubspotIntegrationId: string;
  apolloBroadcast?: boolean;
}): void {
  const currentObjects = cacheReadQueryHubspotIntegrationObjects({ apolloCache, variables }) || [];
  const objectsWithRemoved = currentObjects.filter((objectItem) => objectItem.id !== hubspotIntegrationId) || [];
  if (logLevel >= 2)
    console.log(' --> cacheWriteQueryHubspotIntegrationRemove - objectsWithRemoved:', objectsWithRemoved);
  return cacheWriteQueryHubspotIntegrationObjects({
    apolloCache,
    variables,
    data: objectsWithRemoved,
    apolloBroadcast,
  });
}

// Query Fetch Helper
//
export type QueryHubspotIntegrationByIdApolloQueryResult = ApolloQueryResult<QueryHubspotIntegrationByIdQuery>;
export type QueryHubspotIntegrationByIdApolloQueryHelperResultEx = QueryHubspotIntegrationByIdApolloQueryResult &
  HubspotIntegrationByIdHelperResultEx;

async function queryHubspotIntegrationById({
  apolloClient,
  hubspotIntegrationId,
  options,
}: {
  apolloClient: ApolloClient<object>;
  hubspotIntegrationId: string;
  options?: Omit<QueryOptions<QueryHubspotIntegrationByIdQueryVariables>, 'query' | 'variables'>;
}): Promise<QueryHubspotIntegrationByIdApolloQueryHelperResultEx> {
  const query: QueryHubspotIntegrationByIdApolloQueryResult = await apolloClient.query<QueryHubspotIntegrationByIdQuery>(
    { query: QueryHubspotIntegrationByIdDocument, variables: { hubspotIntegrationId }, ...options }
  );

  return { ...query, hubspotIntegration: query?.data?.hubspotIntegration_by_pk };
}

// Query Watch ById Helper
//
export type WatchQueryHubspotIntegrationByIdApolloObservableQuery = ObservableQuery<QueryHubspotIntegrationByIdQuery>;
async function watchQueryHubspotIntegrationById({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<QueryOptions<QueryHubspotIntegrationByIdQueryVariables>, 'query'>;
}): Promise<WatchQueryHubspotIntegrationByIdApolloObservableQuery> {
  return apolloClient.watchQuery<QueryHubspotIntegrationByIdQuery>({
    query: QueryHubspotIntegrationByIdDocument,
    ...options,
  });
}

// Query Fetch Objects Helper
//
export type QueryHubspotIntegrationObjectsObjectsApolloQueryResult = ApolloQueryResult<QueryHubspotIntegrationObjectsQuery>;
export type QueryHubspotIntegrationObjectsObjectsApolloQueryResultEx = QueryHubspotIntegrationObjectsObjectsApolloQueryResult &
  HubspotIntegrationObjectsHelperResultEx;

async function queryHubspotIntegrationObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<QueryOptions<QueryHubspotIntegrationObjectsQueryVariables>, 'query'>;
}): Promise<QueryHubspotIntegrationObjectsObjectsApolloQueryResultEx> {
  const query: QueryHubspotIntegrationObjectsObjectsApolloQueryResult = await apolloClient.query<QueryHubspotIntegrationObjectsQuery>(
    { query: QueryHubspotIntegrationObjectsDocument, ...options }
  );

  return { ...query, objects: query?.data?.hubspotIntegration || [] };
}

// Query Watch Objects Helper
//
export type WatchQueryHubspotIntegrationObjectsApolloObservableQuery = ObservableQuery<QueryHubspotIntegrationObjectsQuery>;
async function watchQueryHubspotIntegrationObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<QueryOptions<QueryHubspotIntegrationObjectsQueryVariables>, 'query'>;
}): Promise<WatchQueryHubspotIntegrationObjectsApolloObservableQuery> {
  return apolloClient.watchQuery<QueryHubspotIntegrationObjectsQuery>({
    query: QueryHubspotIntegrationObjectsDocument,
    ...options,
  });
}

// Subscription Fetch ById Helper
//
export type SubscribeToHubspotIntegrationByIdSubscriptionFetchResult = FetchResult<
  SubscribeToHubspotIntegrationByIdSubscription,
  Record<string, any>,
  Record<string, any>
>;
export type SubscribeToHubspotIntegrationByIdSubscriptionFetchResultEx = FetchResult<
  SubscribeToHubspotIntegrationByIdSubscription,
  Record<string, any>,
  Record<string, any>
> &
  HubspotIntegrationByIdHelperResultEx;

async function subscribeToHubspotIntegrationById({
  apolloClient,
  hubspotIntegrationId,
  options,
}: {
  apolloClient: ApolloClient<object>;
  hubspotIntegrationId: string;
  options?: Omit<SubscriptionOptions<SubscribeToHubspotIntegrationByIdSubscriptionVariables>, 'query' | 'variables'>;
}): Promise<Observable<SubscribeToHubspotIntegrationByIdSubscriptionFetchResultEx>> {
  const subscription: Observable<SubscribeToHubspotIntegrationByIdSubscriptionFetchResult> = apolloClient.subscribe<SubscribeToHubspotIntegrationByIdSubscription>(
    { query: SubscribeToHubspotIntegrationByIdDocument, variables: { hubspotIntegrationId }, ...options }
  );

  return subscription.map((value) => {
    return {
      context: value.context,
      errors: value.errors,
      data: value.data,
      extensions: value.extensions,
      hubspotIntegration: value?.data?.hubspotIntegration_by_pk || [],
    } as SubscribeToHubspotIntegrationByIdSubscriptionFetchResultEx;
  });
}

// Subscription Fetch Objects Helper
//
export type SubscribeToHubspotIntegrationObjectsSubscriptionFetchResult = FetchResult<
  SubscribeToHubspotIntegrationObjectsSubscription,
  Record<string, any>,
  Record<string, any>
>;
export type SubscribeToHubspotIntegrationObjectsSubscriptionFetchResultEx = FetchResult<
  SubscribeToHubspotIntegrationObjectsSubscription,
  Record<string, any>,
  Record<string, any>
> &
  HubspotIntegrationObjectsHelperResultEx;

async function subscribeToHubspotIntegrationObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options?: Omit<SubscriptionOptions<SubscribeToHubspotIntegrationObjectsSubscriptionVariables>, 'query'>;
}): Promise<Observable<SubscribeToHubspotIntegrationObjectsSubscriptionFetchResultEx>> {
  const subscription: Observable<SubscribeToHubspotIntegrationObjectsSubscriptionFetchResult> = apolloClient.subscribe<SubscribeToHubspotIntegrationObjectsSubscription>(
    { query: SubscribeToHubspotIntegrationObjectsDocument, ...options }
  );

  return subscription.map((value) => {
    return {
      context: value.context,
      errors: value.errors,
      data: value.data,
      extensions: value.extensions,
      objects: value?.data?.hubspotIntegration || [],
    } as SubscribeToHubspotIntegrationObjectsSubscriptionFetchResultEx;
  });
}

// Insert Helper
//
type InsertHubspotIntegrationFetchResult = FetchResult<
  InsertHubspotIntegrationMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertHubspotIntegrationFetchHelperResultEx = InsertHubspotIntegrationFetchResult &
  HubspotIntegrationByIdHelperResultEx;

async function insertHubspotIntegration({
  apolloClient,
  hubspotIntegration,
  autoOptimisticResponse,
  fieldMap,
  options,
}: {
  apolloClient: ApolloClient<object>;
  hubspotIntegration: HubspotIntegration_Insert_Input;
  autoOptimisticResponse?: boolean;
  fieldMap?: FieldMap;
  options?: Omit<
    MutationOptions<InsertHubspotIntegrationMutation, InsertHubspotIntegrationMutationVariables>,
    'mutation' | 'variables'
  >;
}): Promise<InsertHubspotIntegrationFetchHelperResultEx> {
  const objectForInsert = stripInsertInputClientFields({ input: hubspotIntegration });
  const mutationOptions: MutationOptions<
    InsertHubspotIntegrationMutation,
    InsertHubspotIntegrationMutationVariables
  > = { mutation: InsertHubspotIntegrationDocument, variables: { objects: [objectForInsert] }, ...options };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    if (!objectForInsert.id)
      throw new Error(`if autoOptimisticResponse = true, id must be set in object 'hubspotIntegration'`);
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertHubspotIntegrationMutation>({
      operationType: 'insert',
      entityName: 'hubspotIntegration',
      objects: [objectForInsert as HubspotIntegration_Insert_Input & ObjectWithId],
      fieldMap,
    });
    if (logLevel >= 2)
      console.log(' --> insertHubspotIntegration - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: InsertHubspotIntegrationFetchResult = await apolloClient.mutate<
    InsertHubspotIntegrationMutation,
    InsertHubspotIntegrationMutationVariables
  >(mutationOptions);

  return {
    ...mutation,
    hubspotIntegration:
      mutation?.data?.insert_hubspotIntegration?.returning && mutation.data.insert_hubspotIntegration.returning[0],
  };
}

async function insertHubspotIntegrationWithOnConflict({
  apolloClient,
  hubspotIntegration,
  onConflict,
  autoOptimisticResponse,
  fieldMap,
  options,
}: {
  apolloClient: ApolloClient<object>;
  hubspotIntegration: HubspotIntegration_Insert_Input;
  onConflict: HubspotIntegration_On_Conflict;
  autoOptimisticResponse?: boolean;
  fieldMap?: FieldMap;
  options?: Omit<
    MutationOptions<
      InsertHubspotIntegrationWithOnConflictMutation,
      InsertHubspotIntegrationWithOnConflictMutationVariables
    >,
    'mutation' | 'variables'
  >;
}): Promise<InsertHubspotIntegrationFetchHelperResultEx> {
  const objectForInsert = stripInsertInputClientFields({ input: hubspotIntegration });
  const mutationOptions: MutationOptions<
    InsertHubspotIntegrationWithOnConflictMutation,
    InsertHubspotIntegrationWithOnConflictMutationVariables
  > = {
    mutation: InsertHubspotIntegrationWithOnConflictDocument,
    variables: { objects: [objectForInsert], onConflict },
    ...options,
  };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    if (!objectForInsert.id)
      throw new Error(`if autoOptimisticResponse = true, id must be set in object 'hubspotIntegration'`);
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertHubspotIntegrationWithOnConflictMutation>(
      {
        operationType: 'insert',
        entityName: 'hubspotIntegration',
        objects: [objectForInsert as HubspotIntegration_Insert_Input & ObjectWithId],
        fieldMap,
      }
    );
    if (logLevel >= 2)
      console.log(
        ' --> insertHubspotIntegrationWithOnConflict - optimisticResponse:',
        mutationOptions.optimisticResponse
      );
  }

  const mutation: InsertHubspotIntegrationFetchResult = await apolloClient.mutate<
    InsertHubspotIntegrationWithOnConflictMutation,
    InsertHubspotIntegrationWithOnConflictMutationVariables
  >(mutationOptions);

  return {
    ...mutation,
    hubspotIntegration:
      mutation?.data?.insert_hubspotIntegration?.returning && mutation.data.insert_hubspotIntegration.returning[0],
  };
}

// Insert Objects Helper
//
type InsertHubspotIntegrationObjectsFetchResult = FetchResult<
  InsertHubspotIntegrationMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertHubspotIntegrationObjectsHelperResultEx = InsertHubspotIntegrationObjectsFetchResult &
  HubspotIntegrationObjectsHelperResultEx;

async function insertHubspotIntegrationObjects({
  apolloClient,
  objects,
  autoOptimisticResponse,
  fieldMap,
  options,
}: {
  apolloClient: ApolloClient<object>;
  objects: HubspotIntegration_Insert_Input[];
  autoOptimisticResponse?: boolean;
  fieldMap?: FieldMap;
  options?: Omit<
    MutationOptions<InsertHubspotIntegrationMutation, InsertHubspotIntegrationMutationVariables>,
    'mutation' | 'variables'
  >;
}): Promise<InsertHubspotIntegrationObjectsHelperResultEx> {
  const objectsForInsert = objects.map((objectItem) => stripInsertInputClientFields({ input: objectItem }));
  const mutationOptions: MutationOptions<
    InsertHubspotIntegrationMutation,
    InsertHubspotIntegrationMutationVariables
  > = { mutation: InsertHubspotIntegrationDocument, variables: { objects: objectsForInsert }, ...options };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    if (objectsForInsert.find((objectItem) => !objectItem.id))
      throw new Error(`if autoOptimisticResponse = true, ids must be set in objects`);
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertHubspotIntegrationMutation>({
      operationType: 'insert',
      entityName: 'hubspotIntegration',
      objects: objectsForInsert as (HubspotIntegration_Insert_Input & ObjectWithId)[],
      fieldMap,
    });
    if (logLevel >= 2)
      console.log(' --> insertHubspotIntegration - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: InsertHubspotIntegrationObjectsFetchResult = await apolloClient.mutate<
    InsertHubspotIntegrationMutation,
    InsertHubspotIntegrationMutationVariables
  >(mutationOptions);

  return { ...mutation, objects: mutation?.data?.insert_hubspotIntegration?.returning || [] };
}

// Update Helper
//
type UpdateHubspotIntegrationByIdQueryResult = FetchResult<
  UpdateHubspotIntegrationByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateHubspotIntegrationByIdHelperResultEx = UpdateHubspotIntegrationByIdQueryResult &
  HubspotIntegrationByIdHelperResultEx;

async function updateHubspotIntegrationById({
  apolloClient,
  hubspotIntegrationId,
  set,
  autoOptimisticResponse,
  options,
}: {
  apolloClient: ApolloClient<object>;
  hubspotIntegrationId: string;
  set: HubspotIntegration_Set_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationOptions<UpdateHubspotIntegrationByIdMutation, UpdateHubspotIntegrationByIdMutationVariables>,
    'mutation'
  >;
}): Promise<UpdateHubspotIntegrationByIdHelperResultEx> {
  const mutationOptions: MutationOptions<
    UpdateHubspotIntegrationByIdMutation,
    UpdateHubspotIntegrationByIdMutationVariables
  > = { mutation: UpdateHubspotIntegrationByIdDocument, variables: { id: hubspotIntegrationId, set }, ...options };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateHubspotIntegrationByIdMutation>({
      operationType: 'update',
      entityName: 'hubspotIntegration',
      objects: [{ id: hubspotIntegrationId, ...set }],
    });
    if (logLevel >= 2)
      console.log(' --> updateHubspotIntegrationById - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: UpdateHubspotIntegrationByIdQueryResult = await apolloClient.mutate<
    UpdateHubspotIntegrationByIdMutation,
    UpdateHubspotIntegrationByIdMutationVariables
  >(mutationOptions);

  return {
    ...mutation,
    hubspotIntegration:
      mutation?.data?.update_hubspotIntegration?.returning && mutation.data.update_hubspotIntegration.returning[0],
  };
}

// Update Objects Helper
//
type UpdateHubspotIntegrationObjectsFetchResult = FetchResult<
  UpdateHubspotIntegrationMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateHubspotIntegrationObjectsHelperResultEx = UpdateHubspotIntegrationObjectsFetchResult &
  HubspotIntegrationObjectsHelperResultEx;

async function updateHubspotIntegrationObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<
    MutationOptions<UpdateHubspotIntegrationMutation, UpdateHubspotIntegrationMutationVariables>,
    'mutation'
  >;
}): Promise<UpdateHubspotIntegrationObjectsHelperResultEx> {
  const mutation: UpdateHubspotIntegrationObjectsFetchResult = await apolloClient.mutate<
    UpdateHubspotIntegrationMutation,
    UpdateHubspotIntegrationMutationVariables
  >({ mutation: UpdateHubspotIntegrationDocument, ...options });

  return { ...mutation, objects: mutation?.data?.update_hubspotIntegration?.returning || [] };
}

// Delete Helper
//

type RemoveHubspotIntegrationModelByIdQueryResult = FetchResult<
  RemoveHubspotIntegrationModelByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveHubspotIntegrationModelByIdQueryHelperResultEx = RemoveHubspotIntegrationModelByIdQueryResult &
  RemoveEntitiesQueryHelperResultEx;

async function removeHubspotIntegrationModelById({
  apolloClient,
  hubspotIntegrationId,
  autoOptimisticResponse,
  options,
}: {
  apolloClient: ApolloClient<object>;
  hubspotIntegrationId: string;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationOptions<RemoveHubspotIntegrationModelByIdMutation, RemoveHubspotIntegrationModelByIdMutationVariables>,
    'mutation'
  >;
}): Promise<RemoveHubspotIntegrationModelByIdQueryHelperResultEx> {
  const mutationOptions: MutationOptions<
    RemoveHubspotIntegrationModelByIdMutation,
    RemoveHubspotIntegrationModelByIdMutationVariables
  > = { mutation: RemoveHubspotIntegrationModelByIdDocument, variables: { id: hubspotIntegrationId }, ...options };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<RemoveHubspotIntegrationModelByIdMutation>(
      { operationType: 'delete', entityName: 'hubspotIntegration', objects: [{ id: hubspotIntegrationId }] }
    );
    if (logLevel >= 2)
      console.log(' --> removeHubspotIntegrationModelById - optimisticResponse:', mutationOptions.optimisticResponse);
  }
  if (!options || !options.update) {
    mutationOptions.update = generateUpdateFunctionForMutation<RemoveHubspotIntegrationModelByIdMutation>({
      operationType: 'delete',
      entityName: 'hubspotIntegration',
      entityId: hubspotIntegrationId,
    });
  }

  const mutation: RemoveHubspotIntegrationModelByIdQueryResult = await apolloClient.mutate<
    RemoveHubspotIntegrationModelByIdMutation,
    RemoveHubspotIntegrationModelByIdMutationVariables
  >(mutationOptions);

  return { ...mutation, affected_rows: mutation?.data?.delete_hubspotIntegration?.affected_rows || 0 };
}

type RemoveHubspotIntegrationModelObjectsQueryResult = FetchResult<
  RemoveHubspotIntegrationModelMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveHubspotIntegrationModelObjectsQueryHelperResultEx = RemoveHubspotIntegrationModelObjectsQueryResult &
  RemoveEntitiesQueryHelperResultEx;

async function removeHubspotIntegrationModelObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<
    MutationOptions<RemoveHubspotIntegrationModelMutation, RemoveHubspotIntegrationModelMutationVariables>,
    'mutation'
  >;
}): Promise<RemoveHubspotIntegrationModelObjectsQueryHelperResultEx> {
  const mutation: RemoveHubspotIntegrationModelObjectsQueryResult = await apolloClient.mutate<
    RemoveHubspotIntegrationModelMutation,
    RemoveHubspotIntegrationModelMutationVariables
  >({ mutation: RemoveHubspotIntegrationModelDocument, ...options });

  return { ...mutation, affected_rows: mutation?.data?.delete_hubspotIntegration?.affected_rows || 0 };
}

// HubspotIntegration Fragment Helper Object
//------------------------------------------------

export const HubspotIntegrationFragmentGQLHelper = {
  cacheGetDataId: cacheGetDataIdForHubspotIntegration,
  cacheReadFragmentById: cacheReadFragmentHubspotIntegrationById,
  cacheWriteFragmentById: cacheWriteFragmentHubspotIntegrationById,
  cacheReadQueryById: cacheReadQueryHubspotIntegrationById,
  cacheWriteQueryById: cacheWriteQueryHubspotIntegrationById,
  cacheReadQueryObjects: cacheReadQueryHubspotIntegrationObjects,
  cacheWriteQueryObjects: cacheWriteQueryHubspotIntegrationObjects,
  cacheWriteQueryInsert: cacheWriteQueryHubspotIntegrationInsert,
  cacheWriteQueryRemove: cacheWriteQueryHubspotIntegrationRemove,
  queryById: queryHubspotIntegrationById,
  queryObjects: queryHubspotIntegrationObjects,
  watchQueryById: watchQueryHubspotIntegrationById,
  watchQueryObjects: watchQueryHubspotIntegrationObjects,
  subscribeToById: subscribeToHubspotIntegrationById,
  subscribeToObjects: subscribeToHubspotIntegrationObjects,
  insert: insertHubspotIntegration,
  insertWithOnConflict: insertHubspotIntegrationWithOnConflict,
  insertObjects: insertHubspotIntegrationObjects,
  updateById: updateHubspotIntegrationById,
  updateObjects: updateHubspotIntegrationObjects,
};

// hubspotIntegration Entity Helper Object
//------------------------------------------------

export const HubspotIntegrationModelGQLHelper = {
  removeById: removeHubspotIntegrationModelById,
  removeObjects: removeHubspotIntegrationModelObjects,
};

// salesforceIntegration HELPERS
//------------------------------------------------

export type SalesforceIntegrationByIdHelperResultEx = {
  salesforceIntegration: SalesforceIntegrationFragment | null | undefined;
};
export type SalesforceIntegrationObjectsHelperResultEx = { objects: SalesforceIntegrationFragment[] };

// Direct Client & Cache Fragment Helpers
//
function cacheGetDataIdForSalesforceIntegration(salesforceIntegrationId: string): string {
  return defaultCacheIdFromObject({ __typename: 'salesforceIntegration', id: salesforceIntegrationId });
}

function cacheReadFragmentSalesforceIntegrationById({
  apolloCache,
  salesforceIntegrationId,
}: {
  apolloCache: ApolloCache<object>;
  salesforceIntegrationId: string;
}): SalesforceIntegrationFragment | null | undefined {
  return apolloCache.readFragment<SalesforceIntegrationFragment | null | undefined>({
    fragment: SalesforceIntegrationFragmentDoc,
    fragmentName: 'SalesforceIntegration',
    id: defaultCacheIdFromObject({ __typename: 'salesforceIntegration', id: salesforceIntegrationId }),
  });
}

function cacheWriteFragmentSalesforceIntegrationById({
  apolloCache,
  salesforceIntegrationId,
  salesforceIntegrationPartial,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  salesforceIntegrationId: string;
  salesforceIntegrationPartial: Partial<SalesforceIntegrationFragment> | SalesforceIntegration_Insert_Input | null;
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): Partial<SalesforceIntegrationFragment> {
  const parsedFragment = convertToGraph({
    input: salesforceIntegrationPartial,
    typename: 'salesforceIntegration',
    fieldMap,
  });
  if (logLevel >= 2) console.log(' --> cacheWriteFragmentSalesforceIntegrationById - parsedFragment:', parsedFragment);
  apolloCache.writeFragment<Partial<SalesforceIntegrationFragment> | null>({
    fragment: SalesforceIntegrationFragmentDoc,
    fragmentName: 'SalesforceIntegration',
    id: defaultCacheIdFromObject({ ...parsedFragment, id: salesforceIntegrationId }),
    data: parsedFragment,
    broadcast: apolloBroadcast,
  });
  return parsedFragment;
}

function cacheReadQuerySalesforceIntegrationById({
  apolloCache,
  salesforceIntegrationId,
}: {
  apolloCache: ApolloCache<object>;
  salesforceIntegrationId: string;
}): SalesforceIntegrationFragment | null | undefined {
  try {
    return apolloCache.readQuery<SalesforceIntegrationFragment | null>({
      query: QuerySalesforceIntegrationByIdDocument,
      variables: { salesforceIntegrationId },
    });
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheReadQuerySalesforceIntegrationById threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheWriteQuerySalesforceIntegrationById({
  apolloCache,
  salesforceIntegrationId,
  salesforceIntegration,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  salesforceIntegrationId: string;
  salesforceIntegration: SalesforceIntegrationFragment | SalesforceIntegration_Insert_Input | null;
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): void {
  try {
    const salesforceIntegrationPartial = convertToGraph({
      input: salesforceIntegration,
      typename: 'salesforceIntegration',
      fieldMap,
    });
    return apolloCache.writeQuery<SalesforceIntegrationFragment | null>({
      query: QuerySalesforceIntegrationByIdDocument,
      variables: { salesforceIntegrationId },
      data: salesforceIntegration ? salesforceIntegrationPartial : null,
      broadcast: apolloBroadcast,
    });
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheWriteQuerySalesforceIntegrationById threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheReadQuerySalesforceIntegrationObjects({
  apolloCache,
  variables,
}: {
  apolloCache: ApolloCache<object>;
  variables: QuerySalesforceIntegrationObjectsQueryVariables;
}): SalesforceIntegration[] | null | undefined {
  try {
    return (
      apolloCache.readQuery<{ SalesforceIntegration: SalesforceIntegration[] | null }>({
        query: QuerySalesforceIntegrationObjectsDocument,
        variables,
      })?.SalesforceIntegration || []
    );
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheReadQuerySalesforceIntegrationObjects threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheWriteQuerySalesforceIntegrationObjects({
  apolloCache,
  variables,
  data,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  variables: QuerySalesforceIntegrationObjectsQueryVariables;
  data: (SalesforceIntegration | SalesforceIntegration_Insert_Input)[];
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): void {
  try {
    const objects = convertToGraph({ input: data, typename: 'salesforceIntegration', fieldMap });
    return apolloCache.writeQuery<{ SalesforceIntegration: SalesforceIntegration[] }>({
      query: QuerySalesforceIntegrationObjectsDocument,
      variables,
      data: { SalesforceIntegration: objects },
      broadcast: apolloBroadcast,
    });
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheWriteQuerySalesforceIntegrationObjects threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheWriteQuerySalesforceIntegrationInsert({
  apolloCache,
  variables,
  salesforceIntegration,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  variables: QuerySalesforceIntegrationObjectsQueryVariables;
  salesforceIntegration: SalesforceIntegration_Insert_Input;
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): void {
  const currentObjects = cacheReadQuerySalesforceIntegrationObjects({ apolloCache, variables }) || [];
  const objectsWithInserted = [
    ...currentObjects,
    convertToGraph({ input: salesforceIntegration, typename: 'salesforceIntegration', fieldMap }),
  ];
  if (logLevel >= 2)
    console.log(' --> cacheWriteQuerySalesforceIntegrationInsert - objectsWithInserted:', objectsWithInserted);
  return cacheWriteQuerySalesforceIntegrationObjects({
    apolloCache,
    variables,
    data: objectsWithInserted,
    apolloBroadcast,
  });
}

function cacheWriteQuerySalesforceIntegrationRemove({
  apolloCache,
  variables,
  salesforceIntegrationId,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  variables: QuerySalesforceIntegrationObjectsQueryVariables;
  salesforceIntegrationId: string;
  apolloBroadcast?: boolean;
}): void {
  const currentObjects = cacheReadQuerySalesforceIntegrationObjects({ apolloCache, variables }) || [];
  const objectsWithRemoved = currentObjects.filter((objectItem) => objectItem.id !== salesforceIntegrationId) || [];
  if (logLevel >= 2)
    console.log(' --> cacheWriteQuerySalesforceIntegrationRemove - objectsWithRemoved:', objectsWithRemoved);
  return cacheWriteQuerySalesforceIntegrationObjects({
    apolloCache,
    variables,
    data: objectsWithRemoved,
    apolloBroadcast,
  });
}

// Query Fetch Helper
//
export type QuerySalesforceIntegrationByIdApolloQueryResult = ApolloQueryResult<QuerySalesforceIntegrationByIdQuery>;
export type QuerySalesforceIntegrationByIdApolloQueryHelperResultEx = QuerySalesforceIntegrationByIdApolloQueryResult &
  SalesforceIntegrationByIdHelperResultEx;

async function querySalesforceIntegrationById({
  apolloClient,
  salesforceIntegrationId,
  options,
}: {
  apolloClient: ApolloClient<object>;
  salesforceIntegrationId: string;
  options?: Omit<QueryOptions<QuerySalesforceIntegrationByIdQueryVariables>, 'query' | 'variables'>;
}): Promise<QuerySalesforceIntegrationByIdApolloQueryHelperResultEx> {
  const query: QuerySalesforceIntegrationByIdApolloQueryResult = await apolloClient.query<QuerySalesforceIntegrationByIdQuery>(
    { query: QuerySalesforceIntegrationByIdDocument, variables: { salesforceIntegrationId }, ...options }
  );

  return { ...query, salesforceIntegration: query?.data?.salesforceIntegration_by_pk };
}

// Query Watch ById Helper
//
export type WatchQuerySalesforceIntegrationByIdApolloObservableQuery = ObservableQuery<QuerySalesforceIntegrationByIdQuery>;
async function watchQuerySalesforceIntegrationById({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<QueryOptions<QuerySalesforceIntegrationByIdQueryVariables>, 'query'>;
}): Promise<WatchQuerySalesforceIntegrationByIdApolloObservableQuery> {
  return apolloClient.watchQuery<QuerySalesforceIntegrationByIdQuery>({
    query: QuerySalesforceIntegrationByIdDocument,
    ...options,
  });
}

// Query Fetch Objects Helper
//
export type QuerySalesforceIntegrationObjectsObjectsApolloQueryResult = ApolloQueryResult<QuerySalesforceIntegrationObjectsQuery>;
export type QuerySalesforceIntegrationObjectsObjectsApolloQueryResultEx = QuerySalesforceIntegrationObjectsObjectsApolloQueryResult &
  SalesforceIntegrationObjectsHelperResultEx;

async function querySalesforceIntegrationObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<QueryOptions<QuerySalesforceIntegrationObjectsQueryVariables>, 'query'>;
}): Promise<QuerySalesforceIntegrationObjectsObjectsApolloQueryResultEx> {
  const query: QuerySalesforceIntegrationObjectsObjectsApolloQueryResult = await apolloClient.query<QuerySalesforceIntegrationObjectsQuery>(
    { query: QuerySalesforceIntegrationObjectsDocument, ...options }
  );

  return { ...query, objects: query?.data?.salesforceIntegration || [] };
}

// Query Watch Objects Helper
//
export type WatchQuerySalesforceIntegrationObjectsApolloObservableQuery = ObservableQuery<QuerySalesforceIntegrationObjectsQuery>;
async function watchQuerySalesforceIntegrationObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<QueryOptions<QuerySalesforceIntegrationObjectsQueryVariables>, 'query'>;
}): Promise<WatchQuerySalesforceIntegrationObjectsApolloObservableQuery> {
  return apolloClient.watchQuery<QuerySalesforceIntegrationObjectsQuery>({
    query: QuerySalesforceIntegrationObjectsDocument,
    ...options,
  });
}

// Subscription Fetch ById Helper
//
export type SubscribeToSalesforceIntegrationByIdSubscriptionFetchResult = FetchResult<
  SubscribeToSalesforceIntegrationByIdSubscription,
  Record<string, any>,
  Record<string, any>
>;
export type SubscribeToSalesforceIntegrationByIdSubscriptionFetchResultEx = FetchResult<
  SubscribeToSalesforceIntegrationByIdSubscription,
  Record<string, any>,
  Record<string, any>
> &
  SalesforceIntegrationByIdHelperResultEx;

async function subscribeToSalesforceIntegrationById({
  apolloClient,
  salesforceIntegrationId,
  options,
}: {
  apolloClient: ApolloClient<object>;
  salesforceIntegrationId: string;
  options?: Omit<SubscriptionOptions<SubscribeToSalesforceIntegrationByIdSubscriptionVariables>, 'query' | 'variables'>;
}): Promise<Observable<SubscribeToSalesforceIntegrationByIdSubscriptionFetchResultEx>> {
  const subscription: Observable<SubscribeToSalesforceIntegrationByIdSubscriptionFetchResult> = apolloClient.subscribe<SubscribeToSalesforceIntegrationByIdSubscription>(
    { query: SubscribeToSalesforceIntegrationByIdDocument, variables: { salesforceIntegrationId }, ...options }
  );

  return subscription.map((value) => {
    return {
      context: value.context,
      errors: value.errors,
      data: value.data,
      extensions: value.extensions,
      salesforceIntegration: value?.data?.salesforceIntegration_by_pk || [],
    } as SubscribeToSalesforceIntegrationByIdSubscriptionFetchResultEx;
  });
}

// Subscription Fetch Objects Helper
//
export type SubscribeToSalesforceIntegrationObjectsSubscriptionFetchResult = FetchResult<
  SubscribeToSalesforceIntegrationObjectsSubscription,
  Record<string, any>,
  Record<string, any>
>;
export type SubscribeToSalesforceIntegrationObjectsSubscriptionFetchResultEx = FetchResult<
  SubscribeToSalesforceIntegrationObjectsSubscription,
  Record<string, any>,
  Record<string, any>
> &
  SalesforceIntegrationObjectsHelperResultEx;

async function subscribeToSalesforceIntegrationObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options?: Omit<SubscriptionOptions<SubscribeToSalesforceIntegrationObjectsSubscriptionVariables>, 'query'>;
}): Promise<Observable<SubscribeToSalesforceIntegrationObjectsSubscriptionFetchResultEx>> {
  const subscription: Observable<SubscribeToSalesforceIntegrationObjectsSubscriptionFetchResult> = apolloClient.subscribe<SubscribeToSalesforceIntegrationObjectsSubscription>(
    { query: SubscribeToSalesforceIntegrationObjectsDocument, ...options }
  );

  return subscription.map((value) => {
    return {
      context: value.context,
      errors: value.errors,
      data: value.data,
      extensions: value.extensions,
      objects: value?.data?.salesforceIntegration || [],
    } as SubscribeToSalesforceIntegrationObjectsSubscriptionFetchResultEx;
  });
}

// Insert Helper
//
type InsertSalesforceIntegrationFetchResult = FetchResult<
  InsertSalesforceIntegrationMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertSalesforceIntegrationFetchHelperResultEx = InsertSalesforceIntegrationFetchResult &
  SalesforceIntegrationByIdHelperResultEx;

async function insertSalesforceIntegration({
  apolloClient,
  salesforceIntegration,
  autoOptimisticResponse,
  fieldMap,
  options,
}: {
  apolloClient: ApolloClient<object>;
  salesforceIntegration: SalesforceIntegration_Insert_Input;
  autoOptimisticResponse?: boolean;
  fieldMap?: FieldMap;
  options?: Omit<
    MutationOptions<InsertSalesforceIntegrationMutation, InsertSalesforceIntegrationMutationVariables>,
    'mutation' | 'variables'
  >;
}): Promise<InsertSalesforceIntegrationFetchHelperResultEx> {
  const objectForInsert = stripInsertInputClientFields({ input: salesforceIntegration });
  const mutationOptions: MutationOptions<
    InsertSalesforceIntegrationMutation,
    InsertSalesforceIntegrationMutationVariables
  > = { mutation: InsertSalesforceIntegrationDocument, variables: { objects: [objectForInsert] }, ...options };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    if (!objectForInsert.id)
      throw new Error(`if autoOptimisticResponse = true, id must be set in object 'salesforceIntegration'`);
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertSalesforceIntegrationMutation>({
      operationType: 'insert',
      entityName: 'salesforceIntegration',
      objects: [objectForInsert as SalesforceIntegration_Insert_Input & ObjectWithId],
      fieldMap,
    });
    if (logLevel >= 2)
      console.log(' --> insertSalesforceIntegration - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: InsertSalesforceIntegrationFetchResult = await apolloClient.mutate<
    InsertSalesforceIntegrationMutation,
    InsertSalesforceIntegrationMutationVariables
  >(mutationOptions);

  return {
    ...mutation,
    salesforceIntegration:
      mutation?.data?.insert_salesforceIntegration?.returning &&
      mutation.data.insert_salesforceIntegration.returning[0],
  };
}

async function insertSalesforceIntegrationWithOnConflict({
  apolloClient,
  salesforceIntegration,
  onConflict,
  autoOptimisticResponse,
  fieldMap,
  options,
}: {
  apolloClient: ApolloClient<object>;
  salesforceIntegration: SalesforceIntegration_Insert_Input;
  onConflict: SalesforceIntegration_On_Conflict;
  autoOptimisticResponse?: boolean;
  fieldMap?: FieldMap;
  options?: Omit<
    MutationOptions<
      InsertSalesforceIntegrationWithOnConflictMutation,
      InsertSalesforceIntegrationWithOnConflictMutationVariables
    >,
    'mutation' | 'variables'
  >;
}): Promise<InsertSalesforceIntegrationFetchHelperResultEx> {
  const objectForInsert = stripInsertInputClientFields({ input: salesforceIntegration });
  const mutationOptions: MutationOptions<
    InsertSalesforceIntegrationWithOnConflictMutation,
    InsertSalesforceIntegrationWithOnConflictMutationVariables
  > = {
    mutation: InsertSalesforceIntegrationWithOnConflictDocument,
    variables: { objects: [objectForInsert], onConflict },
    ...options,
  };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    if (!objectForInsert.id)
      throw new Error(`if autoOptimisticResponse = true, id must be set in object 'salesforceIntegration'`);
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertSalesforceIntegrationWithOnConflictMutation>(
      {
        operationType: 'insert',
        entityName: 'salesforceIntegration',
        objects: [objectForInsert as SalesforceIntegration_Insert_Input & ObjectWithId],
        fieldMap,
      }
    );
    if (logLevel >= 2)
      console.log(
        ' --> insertSalesforceIntegrationWithOnConflict - optimisticResponse:',
        mutationOptions.optimisticResponse
      );
  }

  const mutation: InsertSalesforceIntegrationFetchResult = await apolloClient.mutate<
    InsertSalesforceIntegrationWithOnConflictMutation,
    InsertSalesforceIntegrationWithOnConflictMutationVariables
  >(mutationOptions);

  return {
    ...mutation,
    salesforceIntegration:
      mutation?.data?.insert_salesforceIntegration?.returning &&
      mutation.data.insert_salesforceIntegration.returning[0],
  };
}

// Insert Objects Helper
//
type InsertSalesforceIntegrationObjectsFetchResult = FetchResult<
  InsertSalesforceIntegrationMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertSalesforceIntegrationObjectsHelperResultEx = InsertSalesforceIntegrationObjectsFetchResult &
  SalesforceIntegrationObjectsHelperResultEx;

async function insertSalesforceIntegrationObjects({
  apolloClient,
  objects,
  autoOptimisticResponse,
  fieldMap,
  options,
}: {
  apolloClient: ApolloClient<object>;
  objects: SalesforceIntegration_Insert_Input[];
  autoOptimisticResponse?: boolean;
  fieldMap?: FieldMap;
  options?: Omit<
    MutationOptions<InsertSalesforceIntegrationMutation, InsertSalesforceIntegrationMutationVariables>,
    'mutation' | 'variables'
  >;
}): Promise<InsertSalesforceIntegrationObjectsHelperResultEx> {
  const objectsForInsert = objects.map((objectItem) => stripInsertInputClientFields({ input: objectItem }));
  const mutationOptions: MutationOptions<
    InsertSalesforceIntegrationMutation,
    InsertSalesforceIntegrationMutationVariables
  > = { mutation: InsertSalesforceIntegrationDocument, variables: { objects: objectsForInsert }, ...options };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    if (objectsForInsert.find((objectItem) => !objectItem.id))
      throw new Error(`if autoOptimisticResponse = true, ids must be set in objects`);
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertSalesforceIntegrationMutation>({
      operationType: 'insert',
      entityName: 'salesforceIntegration',
      objects: objectsForInsert as (SalesforceIntegration_Insert_Input & ObjectWithId)[],
      fieldMap,
    });
    if (logLevel >= 2)
      console.log(' --> insertSalesforceIntegration - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: InsertSalesforceIntegrationObjectsFetchResult = await apolloClient.mutate<
    InsertSalesforceIntegrationMutation,
    InsertSalesforceIntegrationMutationVariables
  >(mutationOptions);

  return { ...mutation, objects: mutation?.data?.insert_salesforceIntegration?.returning || [] };
}

// Update Helper
//
type UpdateSalesforceIntegrationByIdQueryResult = FetchResult<
  UpdateSalesforceIntegrationByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateSalesforceIntegrationByIdHelperResultEx = UpdateSalesforceIntegrationByIdQueryResult &
  SalesforceIntegrationByIdHelperResultEx;

async function updateSalesforceIntegrationById({
  apolloClient,
  salesforceIntegrationId,
  set,
  autoOptimisticResponse,
  options,
}: {
  apolloClient: ApolloClient<object>;
  salesforceIntegrationId: string;
  set: SalesforceIntegration_Set_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationOptions<UpdateSalesforceIntegrationByIdMutation, UpdateSalesforceIntegrationByIdMutationVariables>,
    'mutation'
  >;
}): Promise<UpdateSalesforceIntegrationByIdHelperResultEx> {
  const mutationOptions: MutationOptions<
    UpdateSalesforceIntegrationByIdMutation,
    UpdateSalesforceIntegrationByIdMutationVariables
  > = {
    mutation: UpdateSalesforceIntegrationByIdDocument,
    variables: { id: salesforceIntegrationId, set },
    ...options,
  };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateSalesforceIntegrationByIdMutation>(
      {
        operationType: 'update',
        entityName: 'salesforceIntegration',
        objects: [{ id: salesforceIntegrationId, ...set }],
      }
    );
    if (logLevel >= 2)
      console.log(' --> updateSalesforceIntegrationById - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: UpdateSalesforceIntegrationByIdQueryResult = await apolloClient.mutate<
    UpdateSalesforceIntegrationByIdMutation,
    UpdateSalesforceIntegrationByIdMutationVariables
  >(mutationOptions);

  return {
    ...mutation,
    salesforceIntegration:
      mutation?.data?.update_salesforceIntegration?.returning &&
      mutation.data.update_salesforceIntegration.returning[0],
  };
}

// Update Objects Helper
//
type UpdateSalesforceIntegrationObjectsFetchResult = FetchResult<
  UpdateSalesforceIntegrationMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateSalesforceIntegrationObjectsHelperResultEx = UpdateSalesforceIntegrationObjectsFetchResult &
  SalesforceIntegrationObjectsHelperResultEx;

async function updateSalesforceIntegrationObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<
    MutationOptions<UpdateSalesforceIntegrationMutation, UpdateSalesforceIntegrationMutationVariables>,
    'mutation'
  >;
}): Promise<UpdateSalesforceIntegrationObjectsHelperResultEx> {
  const mutation: UpdateSalesforceIntegrationObjectsFetchResult = await apolloClient.mutate<
    UpdateSalesforceIntegrationMutation,
    UpdateSalesforceIntegrationMutationVariables
  >({ mutation: UpdateSalesforceIntegrationDocument, ...options });

  return { ...mutation, objects: mutation?.data?.update_salesforceIntegration?.returning || [] };
}

// Delete Helper
//

type RemoveSalesforceIntegrationModelByIdQueryResult = FetchResult<
  RemoveSalesforceIntegrationModelByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveSalesforceIntegrationModelByIdQueryHelperResultEx = RemoveSalesforceIntegrationModelByIdQueryResult &
  RemoveEntitiesQueryHelperResultEx;

async function removeSalesforceIntegrationModelById({
  apolloClient,
  salesforceIntegrationId,
  autoOptimisticResponse,
  options,
}: {
  apolloClient: ApolloClient<object>;
  salesforceIntegrationId: string;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationOptions<
      RemoveSalesforceIntegrationModelByIdMutation,
      RemoveSalesforceIntegrationModelByIdMutationVariables
    >,
    'mutation'
  >;
}): Promise<RemoveSalesforceIntegrationModelByIdQueryHelperResultEx> {
  const mutationOptions: MutationOptions<
    RemoveSalesforceIntegrationModelByIdMutation,
    RemoveSalesforceIntegrationModelByIdMutationVariables
  > = {
    mutation: RemoveSalesforceIntegrationModelByIdDocument,
    variables: { id: salesforceIntegrationId },
    ...options,
  };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<RemoveSalesforceIntegrationModelByIdMutation>(
      { operationType: 'delete', entityName: 'salesforceIntegration', objects: [{ id: salesforceIntegrationId }] }
    );
    if (logLevel >= 2)
      console.log(
        ' --> removeSalesforceIntegrationModelById - optimisticResponse:',
        mutationOptions.optimisticResponse
      );
  }
  if (!options || !options.update) {
    mutationOptions.update = generateUpdateFunctionForMutation<RemoveSalesforceIntegrationModelByIdMutation>({
      operationType: 'delete',
      entityName: 'salesforceIntegration',
      entityId: salesforceIntegrationId,
    });
  }

  const mutation: RemoveSalesforceIntegrationModelByIdQueryResult = await apolloClient.mutate<
    RemoveSalesforceIntegrationModelByIdMutation,
    RemoveSalesforceIntegrationModelByIdMutationVariables
  >(mutationOptions);

  return { ...mutation, affected_rows: mutation?.data?.delete_salesforceIntegration?.affected_rows || 0 };
}

type RemoveSalesforceIntegrationModelObjectsQueryResult = FetchResult<
  RemoveSalesforceIntegrationModelMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveSalesforceIntegrationModelObjectsQueryHelperResultEx = RemoveSalesforceIntegrationModelObjectsQueryResult &
  RemoveEntitiesQueryHelperResultEx;

async function removeSalesforceIntegrationModelObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<
    MutationOptions<RemoveSalesforceIntegrationModelMutation, RemoveSalesforceIntegrationModelMutationVariables>,
    'mutation'
  >;
}): Promise<RemoveSalesforceIntegrationModelObjectsQueryHelperResultEx> {
  const mutation: RemoveSalesforceIntegrationModelObjectsQueryResult = await apolloClient.mutate<
    RemoveSalesforceIntegrationModelMutation,
    RemoveSalesforceIntegrationModelMutationVariables
  >({ mutation: RemoveSalesforceIntegrationModelDocument, ...options });

  return { ...mutation, affected_rows: mutation?.data?.delete_salesforceIntegration?.affected_rows || 0 };
}

// SalesforceIntegration Fragment Helper Object
//------------------------------------------------

export const SalesforceIntegrationFragmentGQLHelper = {
  cacheGetDataId: cacheGetDataIdForSalesforceIntegration,
  cacheReadFragmentById: cacheReadFragmentSalesforceIntegrationById,
  cacheWriteFragmentById: cacheWriteFragmentSalesforceIntegrationById,
  cacheReadQueryById: cacheReadQuerySalesforceIntegrationById,
  cacheWriteQueryById: cacheWriteQuerySalesforceIntegrationById,
  cacheReadQueryObjects: cacheReadQuerySalesforceIntegrationObjects,
  cacheWriteQueryObjects: cacheWriteQuerySalesforceIntegrationObjects,
  cacheWriteQueryInsert: cacheWriteQuerySalesforceIntegrationInsert,
  cacheWriteQueryRemove: cacheWriteQuerySalesforceIntegrationRemove,
  queryById: querySalesforceIntegrationById,
  queryObjects: querySalesforceIntegrationObjects,
  watchQueryById: watchQuerySalesforceIntegrationById,
  watchQueryObjects: watchQuerySalesforceIntegrationObjects,
  subscribeToById: subscribeToSalesforceIntegrationById,
  subscribeToObjects: subscribeToSalesforceIntegrationObjects,
  insert: insertSalesforceIntegration,
  insertWithOnConflict: insertSalesforceIntegrationWithOnConflict,
  insertObjects: insertSalesforceIntegrationObjects,
  updateById: updateSalesforceIntegrationById,
  updateObjects: updateSalesforceIntegrationObjects,
};

// salesforceIntegration Entity Helper Object
//------------------------------------------------

export const SalesforceIntegrationModelGQLHelper = {
  removeById: removeSalesforceIntegrationModelById,
  removeObjects: removeSalesforceIntegrationModelObjects,
};

// qaTaskAction HELPERS
//------------------------------------------------

export type QaTaskActionByIdHelperResultEx = { qaTaskAction: QaTaskActionFragment | null | undefined };
export type QaTaskActionObjectsHelperResultEx = { objects: QaTaskActionFragment[] };

// Direct Client & Cache Fragment Helpers
//
function cacheGetDataIdForQaTaskAction(qaTaskActionId: string): string {
  return defaultCacheIdFromObject({ __typename: 'qaTaskAction', id: qaTaskActionId });
}

function cacheReadFragmentQaTaskActionById({
  apolloCache,
  qaTaskActionId,
}: {
  apolloCache: ApolloCache<object>;
  qaTaskActionId: string;
}): QaTaskActionFragment | null | undefined {
  return apolloCache.readFragment<QaTaskActionFragment | null | undefined>({
    fragment: QaTaskActionFragmentDoc,
    fragmentName: 'QaTaskAction',
    id: defaultCacheIdFromObject({ __typename: 'qaTaskAction', id: qaTaskActionId }),
  });
}

function cacheWriteFragmentQaTaskActionById({
  apolloCache,
  qaTaskActionId,
  qaTaskActionPartial,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  qaTaskActionId: string;
  qaTaskActionPartial: Partial<QaTaskActionFragment> | QaTaskAction_Insert_Input | null;
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): Partial<QaTaskActionFragment> {
  const parsedFragment = convertToGraph({ input: qaTaskActionPartial, typename: 'qaTaskAction', fieldMap });
  if (logLevel >= 2) console.log(' --> cacheWriteFragmentQaTaskActionById - parsedFragment:', parsedFragment);
  apolloCache.writeFragment<Partial<QaTaskActionFragment> | null>({
    fragment: QaTaskActionFragmentDoc,
    fragmentName: 'QaTaskAction',
    id: defaultCacheIdFromObject({ ...parsedFragment, id: qaTaskActionId }),
    data: parsedFragment,
    broadcast: apolloBroadcast,
  });
  return parsedFragment;
}

function cacheReadQueryQaTaskActionById({
  apolloCache,
  qaTaskActionId,
}: {
  apolloCache: ApolloCache<object>;
  qaTaskActionId: string;
}): QaTaskActionFragment | null | undefined {
  try {
    return apolloCache.readQuery<QaTaskActionFragment | null>({
      query: QueryQaTaskActionByIdDocument,
      variables: { qaTaskActionId },
    });
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheReadQueryQaTaskActionById threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheWriteQueryQaTaskActionById({
  apolloCache,
  qaTaskActionId,
  qaTaskAction,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  qaTaskActionId: string;
  qaTaskAction: QaTaskActionFragment | QaTaskAction_Insert_Input | null;
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): void {
  try {
    const qaTaskActionPartial = convertToGraph({ input: qaTaskAction, typename: 'qaTaskAction', fieldMap });
    return apolloCache.writeQuery<QaTaskActionFragment | null>({
      query: QueryQaTaskActionByIdDocument,
      variables: { qaTaskActionId },
      data: qaTaskAction ? qaTaskActionPartial : null,
      broadcast: apolloBroadcast,
    });
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheWriteQueryQaTaskActionById threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheReadQueryQaTaskActionObjects({
  apolloCache,
  variables,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryQaTaskActionObjectsQueryVariables;
}): QaTaskAction[] | null | undefined {
  try {
    return (
      apolloCache.readQuery<{ QaTaskAction: QaTaskAction[] | null }>({
        query: QueryQaTaskActionObjectsDocument,
        variables,
      })?.QaTaskAction || []
    );
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheReadQueryQaTaskActionObjects threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheWriteQueryQaTaskActionObjects({
  apolloCache,
  variables,
  data,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryQaTaskActionObjectsQueryVariables;
  data: (QaTaskAction | QaTaskAction_Insert_Input)[];
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): void {
  try {
    const objects = convertToGraph({ input: data, typename: 'qaTaskAction', fieldMap });
    return apolloCache.writeQuery<{ QaTaskAction: QaTaskAction[] }>({
      query: QueryQaTaskActionObjectsDocument,
      variables,
      data: { QaTaskAction: objects },
      broadcast: apolloBroadcast,
    });
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheWriteQueryQaTaskActionObjects threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheWriteQueryQaTaskActionInsert({
  apolloCache,
  variables,
  qaTaskAction,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryQaTaskActionObjectsQueryVariables;
  qaTaskAction: QaTaskAction_Insert_Input;
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): void {
  const currentObjects = cacheReadQueryQaTaskActionObjects({ apolloCache, variables }) || [];
  const objectsWithInserted = [
    ...currentObjects,
    convertToGraph({ input: qaTaskAction, typename: 'qaTaskAction', fieldMap }),
  ];
  if (logLevel >= 2) console.log(' --> cacheWriteQueryQaTaskActionInsert - objectsWithInserted:', objectsWithInserted);
  return cacheWriteQueryQaTaskActionObjects({ apolloCache, variables, data: objectsWithInserted, apolloBroadcast });
}

function cacheWriteQueryQaTaskActionRemove({
  apolloCache,
  variables,
  qaTaskActionId,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryQaTaskActionObjectsQueryVariables;
  qaTaskActionId: string;
  apolloBroadcast?: boolean;
}): void {
  const currentObjects = cacheReadQueryQaTaskActionObjects({ apolloCache, variables }) || [];
  const objectsWithRemoved = currentObjects.filter((objectItem) => objectItem.id !== qaTaskActionId) || [];
  if (logLevel >= 2) console.log(' --> cacheWriteQueryQaTaskActionRemove - objectsWithRemoved:', objectsWithRemoved);
  return cacheWriteQueryQaTaskActionObjects({ apolloCache, variables, data: objectsWithRemoved, apolloBroadcast });
}

// Query Fetch Helper
//
export type QueryQaTaskActionByIdApolloQueryResult = ApolloQueryResult<QueryQaTaskActionByIdQuery>;
export type QueryQaTaskActionByIdApolloQueryHelperResultEx = QueryQaTaskActionByIdApolloQueryResult &
  QaTaskActionByIdHelperResultEx;

async function queryQaTaskActionById({
  apolloClient,
  qaTaskActionId,
  options,
}: {
  apolloClient: ApolloClient<object>;
  qaTaskActionId: string;
  options?: Omit<QueryOptions<QueryQaTaskActionByIdQueryVariables>, 'query' | 'variables'>;
}): Promise<QueryQaTaskActionByIdApolloQueryHelperResultEx> {
  const query: QueryQaTaskActionByIdApolloQueryResult = await apolloClient.query<QueryQaTaskActionByIdQuery>({
    query: QueryQaTaskActionByIdDocument,
    variables: { qaTaskActionId },
    ...options,
  });

  return { ...query, qaTaskAction: query?.data?.qaTaskAction_by_pk };
}

// Query Watch ById Helper
//
export type WatchQueryQaTaskActionByIdApolloObservableQuery = ObservableQuery<QueryQaTaskActionByIdQuery>;
async function watchQueryQaTaskActionById({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<QueryOptions<QueryQaTaskActionByIdQueryVariables>, 'query'>;
}): Promise<WatchQueryQaTaskActionByIdApolloObservableQuery> {
  return apolloClient.watchQuery<QueryQaTaskActionByIdQuery>({ query: QueryQaTaskActionByIdDocument, ...options });
}

// Query Fetch Objects Helper
//
export type QueryQaTaskActionObjectsObjectsApolloQueryResult = ApolloQueryResult<QueryQaTaskActionObjectsQuery>;
export type QueryQaTaskActionObjectsObjectsApolloQueryResultEx = QueryQaTaskActionObjectsObjectsApolloQueryResult &
  QaTaskActionObjectsHelperResultEx;

async function queryQaTaskActionObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<QueryOptions<QueryQaTaskActionObjectsQueryVariables>, 'query'>;
}): Promise<QueryQaTaskActionObjectsObjectsApolloQueryResultEx> {
  const query: QueryQaTaskActionObjectsObjectsApolloQueryResult = await apolloClient.query<QueryQaTaskActionObjectsQuery>(
    { query: QueryQaTaskActionObjectsDocument, ...options }
  );

  return { ...query, objects: query?.data?.qaTaskAction || [] };
}

// Query Watch Objects Helper
//
export type WatchQueryQaTaskActionObjectsApolloObservableQuery = ObservableQuery<QueryQaTaskActionObjectsQuery>;
async function watchQueryQaTaskActionObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<QueryOptions<QueryQaTaskActionObjectsQueryVariables>, 'query'>;
}): Promise<WatchQueryQaTaskActionObjectsApolloObservableQuery> {
  return apolloClient.watchQuery<QueryQaTaskActionObjectsQuery>({
    query: QueryQaTaskActionObjectsDocument,
    ...options,
  });
}

// Subscription Fetch ById Helper
//
export type SubscribeToQaTaskActionByIdSubscriptionFetchResult = FetchResult<
  SubscribeToQaTaskActionByIdSubscription,
  Record<string, any>,
  Record<string, any>
>;
export type SubscribeToQaTaskActionByIdSubscriptionFetchResultEx = FetchResult<
  SubscribeToQaTaskActionByIdSubscription,
  Record<string, any>,
  Record<string, any>
> &
  QaTaskActionByIdHelperResultEx;

async function subscribeToQaTaskActionById({
  apolloClient,
  qaTaskActionId,
  options,
}: {
  apolloClient: ApolloClient<object>;
  qaTaskActionId: string;
  options?: Omit<SubscriptionOptions<SubscribeToQaTaskActionByIdSubscriptionVariables>, 'query' | 'variables'>;
}): Promise<Observable<SubscribeToQaTaskActionByIdSubscriptionFetchResultEx>> {
  const subscription: Observable<SubscribeToQaTaskActionByIdSubscriptionFetchResult> = apolloClient.subscribe<SubscribeToQaTaskActionByIdSubscription>(
    { query: SubscribeToQaTaskActionByIdDocument, variables: { qaTaskActionId }, ...options }
  );

  return subscription.map((value) => {
    return {
      context: value.context,
      errors: value.errors,
      data: value.data,
      extensions: value.extensions,
      qaTaskAction: value?.data?.qaTaskAction_by_pk || [],
    } as SubscribeToQaTaskActionByIdSubscriptionFetchResultEx;
  });
}

// Subscription Fetch Objects Helper
//
export type SubscribeToQaTaskActionObjectsSubscriptionFetchResult = FetchResult<
  SubscribeToQaTaskActionObjectsSubscription,
  Record<string, any>,
  Record<string, any>
>;
export type SubscribeToQaTaskActionObjectsSubscriptionFetchResultEx = FetchResult<
  SubscribeToQaTaskActionObjectsSubscription,
  Record<string, any>,
  Record<string, any>
> &
  QaTaskActionObjectsHelperResultEx;

async function subscribeToQaTaskActionObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options?: Omit<SubscriptionOptions<SubscribeToQaTaskActionObjectsSubscriptionVariables>, 'query'>;
}): Promise<Observable<SubscribeToQaTaskActionObjectsSubscriptionFetchResultEx>> {
  const subscription: Observable<SubscribeToQaTaskActionObjectsSubscriptionFetchResult> = apolloClient.subscribe<SubscribeToQaTaskActionObjectsSubscription>(
    { query: SubscribeToQaTaskActionObjectsDocument, ...options }
  );

  return subscription.map((value) => {
    return {
      context: value.context,
      errors: value.errors,
      data: value.data,
      extensions: value.extensions,
      objects: value?.data?.qaTaskAction || [],
    } as SubscribeToQaTaskActionObjectsSubscriptionFetchResultEx;
  });
}

// Insert Helper
//
type InsertQaTaskActionFetchResult = FetchResult<InsertQaTaskActionMutation, Record<string, any>, Record<string, any>>;
export type InsertQaTaskActionFetchHelperResultEx = InsertQaTaskActionFetchResult & QaTaskActionByIdHelperResultEx;

async function insertQaTaskAction({
  apolloClient,
  qaTaskAction,
  autoOptimisticResponse,
  fieldMap,
  options,
}: {
  apolloClient: ApolloClient<object>;
  qaTaskAction: QaTaskAction_Insert_Input;
  autoOptimisticResponse?: boolean;
  fieldMap?: FieldMap;
  options?: Omit<
    MutationOptions<InsertQaTaskActionMutation, InsertQaTaskActionMutationVariables>,
    'mutation' | 'variables'
  >;
}): Promise<InsertQaTaskActionFetchHelperResultEx> {
  const objectForInsert = stripInsertInputClientFields({ input: qaTaskAction });
  const mutationOptions: MutationOptions<InsertQaTaskActionMutation, InsertQaTaskActionMutationVariables> = {
    mutation: InsertQaTaskActionDocument,
    variables: { objects: [objectForInsert] },
    ...options,
  };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    if (!objectForInsert.id)
      throw new Error(`if autoOptimisticResponse = true, id must be set in object 'qaTaskAction'`);
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertQaTaskActionMutation>({
      operationType: 'insert',
      entityName: 'qaTaskAction',
      objects: [objectForInsert as QaTaskAction_Insert_Input & ObjectWithId],
      fieldMap,
    });
    if (logLevel >= 2) console.log(' --> insertQaTaskAction - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: InsertQaTaskActionFetchResult = await apolloClient.mutate<
    InsertQaTaskActionMutation,
    InsertQaTaskActionMutationVariables
  >(mutationOptions);

  return {
    ...mutation,
    qaTaskAction: mutation?.data?.insert_qaTaskAction?.returning && mutation.data.insert_qaTaskAction.returning[0],
  };
}

async function insertQaTaskActionWithOnConflict({
  apolloClient,
  qaTaskAction,
  onConflict,
  autoOptimisticResponse,
  fieldMap,
  options,
}: {
  apolloClient: ApolloClient<object>;
  qaTaskAction: QaTaskAction_Insert_Input;
  onConflict: QaTaskAction_On_Conflict;
  autoOptimisticResponse?: boolean;
  fieldMap?: FieldMap;
  options?: Omit<
    MutationOptions<InsertQaTaskActionWithOnConflictMutation, InsertQaTaskActionWithOnConflictMutationVariables>,
    'mutation' | 'variables'
  >;
}): Promise<InsertQaTaskActionFetchHelperResultEx> {
  const objectForInsert = stripInsertInputClientFields({ input: qaTaskAction });
  const mutationOptions: MutationOptions<
    InsertQaTaskActionWithOnConflictMutation,
    InsertQaTaskActionWithOnConflictMutationVariables
  > = {
    mutation: InsertQaTaskActionWithOnConflictDocument,
    variables: { objects: [objectForInsert], onConflict },
    ...options,
  };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    if (!objectForInsert.id)
      throw new Error(`if autoOptimisticResponse = true, id must be set in object 'qaTaskAction'`);
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertQaTaskActionWithOnConflictMutation>(
      {
        operationType: 'insert',
        entityName: 'qaTaskAction',
        objects: [objectForInsert as QaTaskAction_Insert_Input & ObjectWithId],
        fieldMap,
      }
    );
    if (logLevel >= 2)
      console.log(' --> insertQaTaskActionWithOnConflict - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: InsertQaTaskActionFetchResult = await apolloClient.mutate<
    InsertQaTaskActionWithOnConflictMutation,
    InsertQaTaskActionWithOnConflictMutationVariables
  >(mutationOptions);

  return {
    ...mutation,
    qaTaskAction: mutation?.data?.insert_qaTaskAction?.returning && mutation.data.insert_qaTaskAction.returning[0],
  };
}

// Insert Objects Helper
//
type InsertQaTaskActionObjectsFetchResult = FetchResult<
  InsertQaTaskActionMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertQaTaskActionObjectsHelperResultEx = InsertQaTaskActionObjectsFetchResult &
  QaTaskActionObjectsHelperResultEx;

async function insertQaTaskActionObjects({
  apolloClient,
  objects,
  autoOptimisticResponse,
  fieldMap,
  options,
}: {
  apolloClient: ApolloClient<object>;
  objects: QaTaskAction_Insert_Input[];
  autoOptimisticResponse?: boolean;
  fieldMap?: FieldMap;
  options?: Omit<
    MutationOptions<InsertQaTaskActionMutation, InsertQaTaskActionMutationVariables>,
    'mutation' | 'variables'
  >;
}): Promise<InsertQaTaskActionObjectsHelperResultEx> {
  const objectsForInsert = objects.map((objectItem) => stripInsertInputClientFields({ input: objectItem }));
  const mutationOptions: MutationOptions<InsertQaTaskActionMutation, InsertQaTaskActionMutationVariables> = {
    mutation: InsertQaTaskActionDocument,
    variables: { objects: objectsForInsert },
    ...options,
  };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    if (objectsForInsert.find((objectItem) => !objectItem.id))
      throw new Error(`if autoOptimisticResponse = true, ids must be set in objects`);
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertQaTaskActionMutation>({
      operationType: 'insert',
      entityName: 'qaTaskAction',
      objects: objectsForInsert as (QaTaskAction_Insert_Input & ObjectWithId)[],
      fieldMap,
    });
    if (logLevel >= 2) console.log(' --> insertQaTaskAction - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: InsertQaTaskActionObjectsFetchResult = await apolloClient.mutate<
    InsertQaTaskActionMutation,
    InsertQaTaskActionMutationVariables
  >(mutationOptions);

  return { ...mutation, objects: mutation?.data?.insert_qaTaskAction?.returning || [] };
}

// Update Helper
//
type UpdateQaTaskActionByIdQueryResult = FetchResult<
  UpdateQaTaskActionByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateQaTaskActionByIdHelperResultEx = UpdateQaTaskActionByIdQueryResult & QaTaskActionByIdHelperResultEx;

async function updateQaTaskActionById({
  apolloClient,
  qaTaskActionId,
  set,
  autoOptimisticResponse,
  options,
}: {
  apolloClient: ApolloClient<object>;
  qaTaskActionId: string;
  set: QaTaskAction_Set_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<MutationOptions<UpdateQaTaskActionByIdMutation, UpdateQaTaskActionByIdMutationVariables>, 'mutation'>;
}): Promise<UpdateQaTaskActionByIdHelperResultEx> {
  const mutationOptions: MutationOptions<UpdateQaTaskActionByIdMutation, UpdateQaTaskActionByIdMutationVariables> = {
    mutation: UpdateQaTaskActionByIdDocument,
    variables: { id: qaTaskActionId, set },
    ...options,
  };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateQaTaskActionByIdMutation>({
      operationType: 'update',
      entityName: 'qaTaskAction',
      objects: [{ id: qaTaskActionId, ...set }],
    });
    if (logLevel >= 2)
      console.log(' --> updateQaTaskActionById - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: UpdateQaTaskActionByIdQueryResult = await apolloClient.mutate<
    UpdateQaTaskActionByIdMutation,
    UpdateQaTaskActionByIdMutationVariables
  >(mutationOptions);

  return {
    ...mutation,
    qaTaskAction: mutation?.data?.update_qaTaskAction?.returning && mutation.data.update_qaTaskAction.returning[0],
  };
}

// Update Objects Helper
//
type UpdateQaTaskActionObjectsFetchResult = FetchResult<
  UpdateQaTaskActionMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateQaTaskActionObjectsHelperResultEx = UpdateQaTaskActionObjectsFetchResult &
  QaTaskActionObjectsHelperResultEx;

async function updateQaTaskActionObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<MutationOptions<UpdateQaTaskActionMutation, UpdateQaTaskActionMutationVariables>, 'mutation'>;
}): Promise<UpdateQaTaskActionObjectsHelperResultEx> {
  const mutation: UpdateQaTaskActionObjectsFetchResult = await apolloClient.mutate<
    UpdateQaTaskActionMutation,
    UpdateQaTaskActionMutationVariables
  >({ mutation: UpdateQaTaskActionDocument, ...options });

  return { ...mutation, objects: mutation?.data?.update_qaTaskAction?.returning || [] };
}

// Delete Helper
//

type RemoveQaTaskActionModelByIdQueryResult = FetchResult<
  RemoveQaTaskActionModelByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveQaTaskActionModelByIdQueryHelperResultEx = RemoveQaTaskActionModelByIdQueryResult &
  RemoveEntitiesQueryHelperResultEx;

async function removeQaTaskActionModelById({
  apolloClient,
  qaTaskActionId,
  autoOptimisticResponse,
  options,
}: {
  apolloClient: ApolloClient<object>;
  qaTaskActionId: string;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationOptions<RemoveQaTaskActionModelByIdMutation, RemoveQaTaskActionModelByIdMutationVariables>,
    'mutation'
  >;
}): Promise<RemoveQaTaskActionModelByIdQueryHelperResultEx> {
  const mutationOptions: MutationOptions<
    RemoveQaTaskActionModelByIdMutation,
    RemoveQaTaskActionModelByIdMutationVariables
  > = { mutation: RemoveQaTaskActionModelByIdDocument, variables: { id: qaTaskActionId }, ...options };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<RemoveQaTaskActionModelByIdMutation>({
      operationType: 'delete',
      entityName: 'qaTaskAction',
      objects: [{ id: qaTaskActionId }],
    });
    if (logLevel >= 2)
      console.log(' --> removeQaTaskActionModelById - optimisticResponse:', mutationOptions.optimisticResponse);
  }
  if (!options || !options.update) {
    mutationOptions.update = generateUpdateFunctionForMutation<RemoveQaTaskActionModelByIdMutation>({
      operationType: 'delete',
      entityName: 'qaTaskAction',
      entityId: qaTaskActionId,
    });
  }

  const mutation: RemoveQaTaskActionModelByIdQueryResult = await apolloClient.mutate<
    RemoveQaTaskActionModelByIdMutation,
    RemoveQaTaskActionModelByIdMutationVariables
  >(mutationOptions);

  return { ...mutation, affected_rows: mutation?.data?.delete_qaTaskAction?.affected_rows || 0 };
}

type RemoveQaTaskActionModelObjectsQueryResult = FetchResult<
  RemoveQaTaskActionModelMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveQaTaskActionModelObjectsQueryHelperResultEx = RemoveQaTaskActionModelObjectsQueryResult &
  RemoveEntitiesQueryHelperResultEx;

async function removeQaTaskActionModelObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<MutationOptions<RemoveQaTaskActionModelMutation, RemoveQaTaskActionModelMutationVariables>, 'mutation'>;
}): Promise<RemoveQaTaskActionModelObjectsQueryHelperResultEx> {
  const mutation: RemoveQaTaskActionModelObjectsQueryResult = await apolloClient.mutate<
    RemoveQaTaskActionModelMutation,
    RemoveQaTaskActionModelMutationVariables
  >({ mutation: RemoveQaTaskActionModelDocument, ...options });

  return { ...mutation, affected_rows: mutation?.data?.delete_qaTaskAction?.affected_rows || 0 };
}

// QaTaskAction Fragment Helper Object
//------------------------------------------------

export const QaTaskActionFragmentGQLHelper = {
  cacheGetDataId: cacheGetDataIdForQaTaskAction,
  cacheReadFragmentById: cacheReadFragmentQaTaskActionById,
  cacheWriteFragmentById: cacheWriteFragmentQaTaskActionById,
  cacheReadQueryById: cacheReadQueryQaTaskActionById,
  cacheWriteQueryById: cacheWriteQueryQaTaskActionById,
  cacheReadQueryObjects: cacheReadQueryQaTaskActionObjects,
  cacheWriteQueryObjects: cacheWriteQueryQaTaskActionObjects,
  cacheWriteQueryInsert: cacheWriteQueryQaTaskActionInsert,
  cacheWriteQueryRemove: cacheWriteQueryQaTaskActionRemove,
  queryById: queryQaTaskActionById,
  queryObjects: queryQaTaskActionObjects,
  watchQueryById: watchQueryQaTaskActionById,
  watchQueryObjects: watchQueryQaTaskActionObjects,
  subscribeToById: subscribeToQaTaskActionById,
  subscribeToObjects: subscribeToQaTaskActionObjects,
  insert: insertQaTaskAction,
  insertWithOnConflict: insertQaTaskActionWithOnConflict,
  insertObjects: insertQaTaskActionObjects,
  updateById: updateQaTaskActionById,
  updateObjects: updateQaTaskActionObjects,
};

// qaTaskAction Entity Helper Object
//------------------------------------------------

export const QaTaskActionModelGQLHelper = {
  removeById: removeQaTaskActionModelById,
  removeObjects: removeQaTaskActionModelObjects,
};

// qaTask HELPERS
//------------------------------------------------

export type QaTaskByIdHelperResultEx = { qaTask: QaTaskFragment | null | undefined };
export type QaTaskObjectsHelperResultEx = { objects: QaTaskFragment[] };

// Direct Client & Cache Fragment Helpers
//
function cacheGetDataIdForQaTask(qaTaskId: string): string {
  return defaultCacheIdFromObject({ __typename: 'qaTask', id: qaTaskId });
}

function cacheReadFragmentQaTaskById({
  apolloCache,
  qaTaskId,
}: {
  apolloCache: ApolloCache<object>;
  qaTaskId: string;
}): QaTaskFragment | null | undefined {
  return apolloCache.readFragment<QaTaskFragment | null | undefined>({
    fragment: QaTaskFragmentDoc,
    fragmentName: 'QaTask',
    id: defaultCacheIdFromObject({ __typename: 'qaTask', id: qaTaskId }),
  });
}

function cacheWriteFragmentQaTaskById({
  apolloCache,
  qaTaskId,
  qaTaskPartial,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  qaTaskId: string;
  qaTaskPartial: Partial<QaTaskFragment> | QaTask_Insert_Input | null;
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): Partial<QaTaskFragment> {
  const parsedFragment = convertToGraph({ input: qaTaskPartial, typename: 'qaTask', fieldMap });
  if (logLevel >= 2) console.log(' --> cacheWriteFragmentQaTaskById - parsedFragment:', parsedFragment);
  apolloCache.writeFragment<Partial<QaTaskFragment> | null>({
    fragment: QaTaskFragmentDoc,
    fragmentName: 'QaTask',
    id: defaultCacheIdFromObject({ ...parsedFragment, id: qaTaskId }),
    data: parsedFragment,
    broadcast: apolloBroadcast,
  });
  return parsedFragment;
}

function cacheReadQueryQaTaskById({
  apolloCache,
  qaTaskId,
}: {
  apolloCache: ApolloCache<object>;
  qaTaskId: string;
}): QaTaskFragment | null | undefined {
  try {
    return apolloCache.readQuery<QaTaskFragment | null>({ query: QueryQaTaskByIdDocument, variables: { qaTaskId } });
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheReadQueryQaTaskById threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheWriteQueryQaTaskById({
  apolloCache,
  qaTaskId,
  qaTask,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  qaTaskId: string;
  qaTask: QaTaskFragment | QaTask_Insert_Input | null;
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): void {
  try {
    const qaTaskPartial = convertToGraph({ input: qaTask, typename: 'qaTask', fieldMap });
    return apolloCache.writeQuery<QaTaskFragment | null>({
      query: QueryQaTaskByIdDocument,
      variables: { qaTaskId },
      data: qaTask ? qaTaskPartial : null,
      broadcast: apolloBroadcast,
    });
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheWriteQueryQaTaskById threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheReadQueryQaTaskObjects({
  apolloCache,
  variables,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryQaTaskObjectsQueryVariables;
}): QaTask[] | null | undefined {
  try {
    return (
      apolloCache.readQuery<{ QaTask: QaTask[] | null }>({ query: QueryQaTaskObjectsDocument, variables })?.QaTask || []
    );
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheReadQueryQaTaskObjects threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheWriteQueryQaTaskObjects({
  apolloCache,
  variables,
  data,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryQaTaskObjectsQueryVariables;
  data: (QaTask | QaTask_Insert_Input)[];
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): void {
  try {
    const objects = convertToGraph({ input: data, typename: 'qaTask', fieldMap });
    return apolloCache.writeQuery<{ QaTask: QaTask[] }>({
      query: QueryQaTaskObjectsDocument,
      variables,
      data: { QaTask: objects },
      broadcast: apolloBroadcast,
    });
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheWriteQueryQaTaskObjects threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheWriteQueryQaTaskInsert({
  apolloCache,
  variables,
  qaTask,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryQaTaskObjectsQueryVariables;
  qaTask: QaTask_Insert_Input;
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): void {
  const currentObjects = cacheReadQueryQaTaskObjects({ apolloCache, variables }) || [];
  const objectsWithInserted = [...currentObjects, convertToGraph({ input: qaTask, typename: 'qaTask', fieldMap })];
  if (logLevel >= 2) console.log(' --> cacheWriteQueryQaTaskInsert - objectsWithInserted:', objectsWithInserted);
  return cacheWriteQueryQaTaskObjects({ apolloCache, variables, data: objectsWithInserted, apolloBroadcast });
}

function cacheWriteQueryQaTaskRemove({
  apolloCache,
  variables,
  qaTaskId,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryQaTaskObjectsQueryVariables;
  qaTaskId: string;
  apolloBroadcast?: boolean;
}): void {
  const currentObjects = cacheReadQueryQaTaskObjects({ apolloCache, variables }) || [];
  const objectsWithRemoved = currentObjects.filter((objectItem) => objectItem.id !== qaTaskId) || [];
  if (logLevel >= 2) console.log(' --> cacheWriteQueryQaTaskRemove - objectsWithRemoved:', objectsWithRemoved);
  return cacheWriteQueryQaTaskObjects({ apolloCache, variables, data: objectsWithRemoved, apolloBroadcast });
}

// Query Fetch Helper
//
export type QueryQaTaskByIdApolloQueryResult = ApolloQueryResult<QueryQaTaskByIdQuery>;
export type QueryQaTaskByIdApolloQueryHelperResultEx = QueryQaTaskByIdApolloQueryResult & QaTaskByIdHelperResultEx;

async function queryQaTaskById({
  apolloClient,
  qaTaskId,
  options,
}: {
  apolloClient: ApolloClient<object>;
  qaTaskId: string;
  options?: Omit<QueryOptions<QueryQaTaskByIdQueryVariables>, 'query' | 'variables'>;
}): Promise<QueryQaTaskByIdApolloQueryHelperResultEx> {
  const query: QueryQaTaskByIdApolloQueryResult = await apolloClient.query<QueryQaTaskByIdQuery>({
    query: QueryQaTaskByIdDocument,
    variables: { qaTaskId },
    ...options,
  });

  return { ...query, qaTask: query?.data?.qaTask_by_pk };
}

// Query Watch ById Helper
//
export type WatchQueryQaTaskByIdApolloObservableQuery = ObservableQuery<QueryQaTaskByIdQuery>;
async function watchQueryQaTaskById({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<QueryOptions<QueryQaTaskByIdQueryVariables>, 'query'>;
}): Promise<WatchQueryQaTaskByIdApolloObservableQuery> {
  return apolloClient.watchQuery<QueryQaTaskByIdQuery>({ query: QueryQaTaskByIdDocument, ...options });
}

// Query Fetch Objects Helper
//
export type QueryQaTaskObjectsObjectsApolloQueryResult = ApolloQueryResult<QueryQaTaskObjectsQuery>;
export type QueryQaTaskObjectsObjectsApolloQueryResultEx = QueryQaTaskObjectsObjectsApolloQueryResult &
  QaTaskObjectsHelperResultEx;

async function queryQaTaskObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<QueryOptions<QueryQaTaskObjectsQueryVariables>, 'query'>;
}): Promise<QueryQaTaskObjectsObjectsApolloQueryResultEx> {
  const query: QueryQaTaskObjectsObjectsApolloQueryResult = await apolloClient.query<QueryQaTaskObjectsQuery>({
    query: QueryQaTaskObjectsDocument,
    ...options,
  });

  return { ...query, objects: query?.data?.qaTask || [] };
}

// Query Watch Objects Helper
//
export type WatchQueryQaTaskObjectsApolloObservableQuery = ObservableQuery<QueryQaTaskObjectsQuery>;
async function watchQueryQaTaskObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<QueryOptions<QueryQaTaskObjectsQueryVariables>, 'query'>;
}): Promise<WatchQueryQaTaskObjectsApolloObservableQuery> {
  return apolloClient.watchQuery<QueryQaTaskObjectsQuery>({ query: QueryQaTaskObjectsDocument, ...options });
}

// Subscription Fetch ById Helper
//
export type SubscribeToQaTaskByIdSubscriptionFetchResult = FetchResult<
  SubscribeToQaTaskByIdSubscription,
  Record<string, any>,
  Record<string, any>
>;
export type SubscribeToQaTaskByIdSubscriptionFetchResultEx = FetchResult<
  SubscribeToQaTaskByIdSubscription,
  Record<string, any>,
  Record<string, any>
> &
  QaTaskByIdHelperResultEx;

async function subscribeToQaTaskById({
  apolloClient,
  qaTaskId,
  options,
}: {
  apolloClient: ApolloClient<object>;
  qaTaskId: string;
  options?: Omit<SubscriptionOptions<SubscribeToQaTaskByIdSubscriptionVariables>, 'query' | 'variables'>;
}): Promise<Observable<SubscribeToQaTaskByIdSubscriptionFetchResultEx>> {
  const subscription: Observable<SubscribeToQaTaskByIdSubscriptionFetchResult> = apolloClient.subscribe<SubscribeToQaTaskByIdSubscription>(
    { query: SubscribeToQaTaskByIdDocument, variables: { qaTaskId }, ...options }
  );

  return subscription.map((value) => {
    return {
      context: value.context,
      errors: value.errors,
      data: value.data,
      extensions: value.extensions,
      qaTask: value?.data?.qaTask_by_pk || [],
    } as SubscribeToQaTaskByIdSubscriptionFetchResultEx;
  });
}

// Subscription Fetch Objects Helper
//
export type SubscribeToQaTaskObjectsSubscriptionFetchResult = FetchResult<
  SubscribeToQaTaskObjectsSubscription,
  Record<string, any>,
  Record<string, any>
>;
export type SubscribeToQaTaskObjectsSubscriptionFetchResultEx = FetchResult<
  SubscribeToQaTaskObjectsSubscription,
  Record<string, any>,
  Record<string, any>
> &
  QaTaskObjectsHelperResultEx;

async function subscribeToQaTaskObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options?: Omit<SubscriptionOptions<SubscribeToQaTaskObjectsSubscriptionVariables>, 'query'>;
}): Promise<Observable<SubscribeToQaTaskObjectsSubscriptionFetchResultEx>> {
  const subscription: Observable<SubscribeToQaTaskObjectsSubscriptionFetchResult> = apolloClient.subscribe<SubscribeToQaTaskObjectsSubscription>(
    { query: SubscribeToQaTaskObjectsDocument, ...options }
  );

  return subscription.map((value) => {
    return {
      context: value.context,
      errors: value.errors,
      data: value.data,
      extensions: value.extensions,
      objects: value?.data?.qaTask || [],
    } as SubscribeToQaTaskObjectsSubscriptionFetchResultEx;
  });
}

// Insert Helper
//
type InsertQaTaskFetchResult = FetchResult<InsertQaTaskMutation, Record<string, any>, Record<string, any>>;
export type InsertQaTaskFetchHelperResultEx = InsertQaTaskFetchResult & QaTaskByIdHelperResultEx;

async function insertQaTask({
  apolloClient,
  qaTask,
  autoOptimisticResponse,
  fieldMap,
  options,
}: {
  apolloClient: ApolloClient<object>;
  qaTask: QaTask_Insert_Input;
  autoOptimisticResponse?: boolean;
  fieldMap?: FieldMap;
  options?: Omit<MutationOptions<InsertQaTaskMutation, InsertQaTaskMutationVariables>, 'mutation' | 'variables'>;
}): Promise<InsertQaTaskFetchHelperResultEx> {
  const objectForInsert = stripInsertInputClientFields({ input: qaTask });
  const mutationOptions: MutationOptions<InsertQaTaskMutation, InsertQaTaskMutationVariables> = {
    mutation: InsertQaTaskDocument,
    variables: { objects: [objectForInsert] },
    ...options,
  };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    if (!objectForInsert.id) throw new Error(`if autoOptimisticResponse = true, id must be set in object 'qaTask'`);
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertQaTaskMutation>({
      operationType: 'insert',
      entityName: 'qaTask',
      objects: [objectForInsert as QaTask_Insert_Input & ObjectWithId],
      fieldMap,
    });
    if (logLevel >= 2) console.log(' --> insertQaTask - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: InsertQaTaskFetchResult = await apolloClient.mutate<
    InsertQaTaskMutation,
    InsertQaTaskMutationVariables
  >(mutationOptions);

  return { ...mutation, qaTask: mutation?.data?.insert_qaTask?.returning && mutation.data.insert_qaTask.returning[0] };
}

async function insertQaTaskWithOnConflict({
  apolloClient,
  qaTask,
  onConflict,
  autoOptimisticResponse,
  fieldMap,
  options,
}: {
  apolloClient: ApolloClient<object>;
  qaTask: QaTask_Insert_Input;
  onConflict: QaTask_On_Conflict;
  autoOptimisticResponse?: boolean;
  fieldMap?: FieldMap;
  options?: Omit<
    MutationOptions<InsertQaTaskWithOnConflictMutation, InsertQaTaskWithOnConflictMutationVariables>,
    'mutation' | 'variables'
  >;
}): Promise<InsertQaTaskFetchHelperResultEx> {
  const objectForInsert = stripInsertInputClientFields({ input: qaTask });
  const mutationOptions: MutationOptions<
    InsertQaTaskWithOnConflictMutation,
    InsertQaTaskWithOnConflictMutationVariables
  > = {
    mutation: InsertQaTaskWithOnConflictDocument,
    variables: { objects: [objectForInsert], onConflict },
    ...options,
  };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    if (!objectForInsert.id) throw new Error(`if autoOptimisticResponse = true, id must be set in object 'qaTask'`);
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertQaTaskWithOnConflictMutation>({
      operationType: 'insert',
      entityName: 'qaTask',
      objects: [objectForInsert as QaTask_Insert_Input & ObjectWithId],
      fieldMap,
    });
    if (logLevel >= 2)
      console.log(' --> insertQaTaskWithOnConflict - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: InsertQaTaskFetchResult = await apolloClient.mutate<
    InsertQaTaskWithOnConflictMutation,
    InsertQaTaskWithOnConflictMutationVariables
  >(mutationOptions);

  return { ...mutation, qaTask: mutation?.data?.insert_qaTask?.returning && mutation.data.insert_qaTask.returning[0] };
}

// Insert Objects Helper
//
type InsertQaTaskObjectsFetchResult = FetchResult<InsertQaTaskMutation, Record<string, any>, Record<string, any>>;
export type InsertQaTaskObjectsHelperResultEx = InsertQaTaskObjectsFetchResult & QaTaskObjectsHelperResultEx;

async function insertQaTaskObjects({
  apolloClient,
  objects,
  autoOptimisticResponse,
  fieldMap,
  options,
}: {
  apolloClient: ApolloClient<object>;
  objects: QaTask_Insert_Input[];
  autoOptimisticResponse?: boolean;
  fieldMap?: FieldMap;
  options?: Omit<MutationOptions<InsertQaTaskMutation, InsertQaTaskMutationVariables>, 'mutation' | 'variables'>;
}): Promise<InsertQaTaskObjectsHelperResultEx> {
  const objectsForInsert = objects.map((objectItem) => stripInsertInputClientFields({ input: objectItem }));
  const mutationOptions: MutationOptions<InsertQaTaskMutation, InsertQaTaskMutationVariables> = {
    mutation: InsertQaTaskDocument,
    variables: { objects: objectsForInsert },
    ...options,
  };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    if (objectsForInsert.find((objectItem) => !objectItem.id))
      throw new Error(`if autoOptimisticResponse = true, ids must be set in objects`);
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertQaTaskMutation>({
      operationType: 'insert',
      entityName: 'qaTask',
      objects: objectsForInsert as (QaTask_Insert_Input & ObjectWithId)[],
      fieldMap,
    });
    if (logLevel >= 2) console.log(' --> insertQaTask - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: InsertQaTaskObjectsFetchResult = await apolloClient.mutate<
    InsertQaTaskMutation,
    InsertQaTaskMutationVariables
  >(mutationOptions);

  return { ...mutation, objects: mutation?.data?.insert_qaTask?.returning || [] };
}

// Update Helper
//
type UpdateQaTaskByIdQueryResult = FetchResult<UpdateQaTaskByIdMutation, Record<string, any>, Record<string, any>>;
export type UpdateQaTaskByIdHelperResultEx = UpdateQaTaskByIdQueryResult & QaTaskByIdHelperResultEx;

async function updateQaTaskById({
  apolloClient,
  qaTaskId,
  set,
  autoOptimisticResponse,
  options,
}: {
  apolloClient: ApolloClient<object>;
  qaTaskId: string;
  set: QaTask_Set_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<MutationOptions<UpdateQaTaskByIdMutation, UpdateQaTaskByIdMutationVariables>, 'mutation'>;
}): Promise<UpdateQaTaskByIdHelperResultEx> {
  const mutationOptions: MutationOptions<UpdateQaTaskByIdMutation, UpdateQaTaskByIdMutationVariables> = {
    mutation: UpdateQaTaskByIdDocument,
    variables: { id: qaTaskId, set },
    ...options,
  };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateQaTaskByIdMutation>({
      operationType: 'update',
      entityName: 'qaTask',
      objects: [{ id: qaTaskId, ...set }],
    });
    if (logLevel >= 2) console.log(' --> updateQaTaskById - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: UpdateQaTaskByIdQueryResult = await apolloClient.mutate<
    UpdateQaTaskByIdMutation,
    UpdateQaTaskByIdMutationVariables
  >(mutationOptions);

  return { ...mutation, qaTask: mutation?.data?.update_qaTask?.returning && mutation.data.update_qaTask.returning[0] };
}

// Update Objects Helper
//
type UpdateQaTaskObjectsFetchResult = FetchResult<UpdateQaTaskMutation, Record<string, any>, Record<string, any>>;
export type UpdateQaTaskObjectsHelperResultEx = UpdateQaTaskObjectsFetchResult & QaTaskObjectsHelperResultEx;

async function updateQaTaskObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<MutationOptions<UpdateQaTaskMutation, UpdateQaTaskMutationVariables>, 'mutation'>;
}): Promise<UpdateQaTaskObjectsHelperResultEx> {
  const mutation: UpdateQaTaskObjectsFetchResult = await apolloClient.mutate<
    UpdateQaTaskMutation,
    UpdateQaTaskMutationVariables
  >({ mutation: UpdateQaTaskDocument, ...options });

  return { ...mutation, objects: mutation?.data?.update_qaTask?.returning || [] };
}

// Delete Helper
//

type RemoveQaTaskModelByIdQueryResult = FetchResult<
  RemoveQaTaskModelByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveQaTaskModelByIdQueryHelperResultEx = RemoveQaTaskModelByIdQueryResult &
  RemoveEntitiesQueryHelperResultEx;

async function removeQaTaskModelById({
  apolloClient,
  qaTaskId,
  autoOptimisticResponse,
  options,
}: {
  apolloClient: ApolloClient<object>;
  qaTaskId: string;
  autoOptimisticResponse?: boolean;
  options?: Omit<MutationOptions<RemoveQaTaskModelByIdMutation, RemoveQaTaskModelByIdMutationVariables>, 'mutation'>;
}): Promise<RemoveQaTaskModelByIdQueryHelperResultEx> {
  const mutationOptions: MutationOptions<RemoveQaTaskModelByIdMutation, RemoveQaTaskModelByIdMutationVariables> = {
    mutation: RemoveQaTaskModelByIdDocument,
    variables: { id: qaTaskId },
    ...options,
  };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<RemoveQaTaskModelByIdMutation>({
      operationType: 'delete',
      entityName: 'qaTask',
      objects: [{ id: qaTaskId }],
    });
    if (logLevel >= 2)
      console.log(' --> removeQaTaskModelById - optimisticResponse:', mutationOptions.optimisticResponse);
  }
  if (!options || !options.update) {
    mutationOptions.update = generateUpdateFunctionForMutation<RemoveQaTaskModelByIdMutation>({
      operationType: 'delete',
      entityName: 'qaTask',
      entityId: qaTaskId,
    });
  }

  const mutation: RemoveQaTaskModelByIdQueryResult = await apolloClient.mutate<
    RemoveQaTaskModelByIdMutation,
    RemoveQaTaskModelByIdMutationVariables
  >(mutationOptions);

  return { ...mutation, affected_rows: mutation?.data?.delete_qaTask?.affected_rows || 0 };
}

type RemoveQaTaskModelObjectsQueryResult = FetchResult<
  RemoveQaTaskModelMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveQaTaskModelObjectsQueryHelperResultEx = RemoveQaTaskModelObjectsQueryResult &
  RemoveEntitiesQueryHelperResultEx;

async function removeQaTaskModelObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<MutationOptions<RemoveQaTaskModelMutation, RemoveQaTaskModelMutationVariables>, 'mutation'>;
}): Promise<RemoveQaTaskModelObjectsQueryHelperResultEx> {
  const mutation: RemoveQaTaskModelObjectsQueryResult = await apolloClient.mutate<
    RemoveQaTaskModelMutation,
    RemoveQaTaskModelMutationVariables
  >({ mutation: RemoveQaTaskModelDocument, ...options });

  return { ...mutation, affected_rows: mutation?.data?.delete_qaTask?.affected_rows || 0 };
}

// QaTask Fragment Helper Object
//------------------------------------------------

export const QaTaskFragmentGQLHelper = {
  cacheGetDataId: cacheGetDataIdForQaTask,
  cacheReadFragmentById: cacheReadFragmentQaTaskById,
  cacheWriteFragmentById: cacheWriteFragmentQaTaskById,
  cacheReadQueryById: cacheReadQueryQaTaskById,
  cacheWriteQueryById: cacheWriteQueryQaTaskById,
  cacheReadQueryObjects: cacheReadQueryQaTaskObjects,
  cacheWriteQueryObjects: cacheWriteQueryQaTaskObjects,
  cacheWriteQueryInsert: cacheWriteQueryQaTaskInsert,
  cacheWriteQueryRemove: cacheWriteQueryQaTaskRemove,
  queryById: queryQaTaskById,
  queryObjects: queryQaTaskObjects,
  watchQueryById: watchQueryQaTaskById,
  watchQueryObjects: watchQueryQaTaskObjects,
  subscribeToById: subscribeToQaTaskById,
  subscribeToObjects: subscribeToQaTaskObjects,
  insert: insertQaTask,
  insertWithOnConflict: insertQaTaskWithOnConflict,
  insertObjects: insertQaTaskObjects,
  updateById: updateQaTaskById,
  updateObjects: updateQaTaskObjects,
};

// qaTask Entity Helper Object
//------------------------------------------------

export const QaTaskModelGQLHelper = {
  removeById: removeQaTaskModelById,
  removeObjects: removeQaTaskModelObjects,
};

// clientTerritory HELPERS
//------------------------------------------------

export type ClientTerritoryByIdHelperResultEx = { clientTerritory: ClientTerritoryFragment | null | undefined };
export type ClientTerritoryObjectsHelperResultEx = { objects: ClientTerritoryFragment[] };

// Direct Client & Cache Fragment Helpers
//
function cacheGetDataIdForClientTerritory(clientTerritoryId: string): string {
  return defaultCacheIdFromObject({ __typename: 'clientTerritory', id: clientTerritoryId });
}

function cacheReadFragmentClientTerritoryById({
  apolloCache,
  clientTerritoryId,
}: {
  apolloCache: ApolloCache<object>;
  clientTerritoryId: string;
}): ClientTerritoryFragment | null | undefined {
  return apolloCache.readFragment<ClientTerritoryFragment | null | undefined>({
    fragment: ClientTerritoryFragmentDoc,
    fragmentName: 'ClientTerritory',
    id: defaultCacheIdFromObject({ __typename: 'clientTerritory', id: clientTerritoryId }),
  });
}

function cacheWriteFragmentClientTerritoryById({
  apolloCache,
  clientTerritoryId,
  clientTerritoryPartial,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  clientTerritoryId: string;
  clientTerritoryPartial: Partial<ClientTerritoryFragment> | ClientTerritory_Insert_Input | null;
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): Partial<ClientTerritoryFragment> {
  const parsedFragment = convertToGraph({ input: clientTerritoryPartial, typename: 'clientTerritory', fieldMap });
  if (logLevel >= 2) console.log(' --> cacheWriteFragmentClientTerritoryById - parsedFragment:', parsedFragment);
  apolloCache.writeFragment<Partial<ClientTerritoryFragment> | null>({
    fragment: ClientTerritoryFragmentDoc,
    fragmentName: 'ClientTerritory',
    id: defaultCacheIdFromObject({ ...parsedFragment, id: clientTerritoryId }),
    data: parsedFragment,
    broadcast: apolloBroadcast,
  });
  return parsedFragment;
}

function cacheReadQueryClientTerritoryById({
  apolloCache,
  clientTerritoryId,
}: {
  apolloCache: ApolloCache<object>;
  clientTerritoryId: string;
}): ClientTerritoryFragment | null | undefined {
  try {
    return apolloCache.readQuery<ClientTerritoryFragment | null>({
      query: QueryClientTerritoryByIdDocument,
      variables: { clientTerritoryId },
    });
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheReadQueryClientTerritoryById threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheWriteQueryClientTerritoryById({
  apolloCache,
  clientTerritoryId,
  clientTerritory,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  clientTerritoryId: string;
  clientTerritory: ClientTerritoryFragment | ClientTerritory_Insert_Input | null;
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): void {
  try {
    const clientTerritoryPartial = convertToGraph({ input: clientTerritory, typename: 'clientTerritory', fieldMap });
    return apolloCache.writeQuery<ClientTerritoryFragment | null>({
      query: QueryClientTerritoryByIdDocument,
      variables: { clientTerritoryId },
      data: clientTerritory ? clientTerritoryPartial : null,
      broadcast: apolloBroadcast,
    });
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheWriteQueryClientTerritoryById threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheReadQueryClientTerritoryObjects({
  apolloCache,
  variables,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryClientTerritoryObjectsQueryVariables;
}): ClientTerritory[] | null | undefined {
  try {
    return (
      apolloCache.readQuery<{ ClientTerritory: ClientTerritory[] | null }>({
        query: QueryClientTerritoryObjectsDocument,
        variables,
      })?.ClientTerritory || []
    );
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheReadQueryClientTerritoryObjects threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheWriteQueryClientTerritoryObjects({
  apolloCache,
  variables,
  data,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryClientTerritoryObjectsQueryVariables;
  data: (ClientTerritory | ClientTerritory_Insert_Input)[];
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): void {
  try {
    const objects = convertToGraph({ input: data, typename: 'clientTerritory', fieldMap });
    return apolloCache.writeQuery<{ ClientTerritory: ClientTerritory[] }>({
      query: QueryClientTerritoryObjectsDocument,
      variables,
      data: { ClientTerritory: objects },
      broadcast: apolloBroadcast,
    });
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheWriteQueryClientTerritoryObjects threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheWriteQueryClientTerritoryInsert({
  apolloCache,
  variables,
  clientTerritory,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryClientTerritoryObjectsQueryVariables;
  clientTerritory: ClientTerritory_Insert_Input;
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): void {
  const currentObjects = cacheReadQueryClientTerritoryObjects({ apolloCache, variables }) || [];
  const objectsWithInserted = [
    ...currentObjects,
    convertToGraph({ input: clientTerritory, typename: 'clientTerritory', fieldMap }),
  ];
  if (logLevel >= 2)
    console.log(' --> cacheWriteQueryClientTerritoryInsert - objectsWithInserted:', objectsWithInserted);
  return cacheWriteQueryClientTerritoryObjects({ apolloCache, variables, data: objectsWithInserted, apolloBroadcast });
}

function cacheWriteQueryClientTerritoryRemove({
  apolloCache,
  variables,
  clientTerritoryId,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryClientTerritoryObjectsQueryVariables;
  clientTerritoryId: string;
  apolloBroadcast?: boolean;
}): void {
  const currentObjects = cacheReadQueryClientTerritoryObjects({ apolloCache, variables }) || [];
  const objectsWithRemoved = currentObjects.filter((objectItem) => objectItem.id !== clientTerritoryId) || [];
  if (logLevel >= 2) console.log(' --> cacheWriteQueryClientTerritoryRemove - objectsWithRemoved:', objectsWithRemoved);
  return cacheWriteQueryClientTerritoryObjects({ apolloCache, variables, data: objectsWithRemoved, apolloBroadcast });
}

// Query Fetch Helper
//
export type QueryClientTerritoryByIdApolloQueryResult = ApolloQueryResult<QueryClientTerritoryByIdQuery>;
export type QueryClientTerritoryByIdApolloQueryHelperResultEx = QueryClientTerritoryByIdApolloQueryResult &
  ClientTerritoryByIdHelperResultEx;

async function queryClientTerritoryById({
  apolloClient,
  clientTerritoryId,
  options,
}: {
  apolloClient: ApolloClient<object>;
  clientTerritoryId: string;
  options?: Omit<QueryOptions<QueryClientTerritoryByIdQueryVariables>, 'query' | 'variables'>;
}): Promise<QueryClientTerritoryByIdApolloQueryHelperResultEx> {
  const query: QueryClientTerritoryByIdApolloQueryResult = await apolloClient.query<QueryClientTerritoryByIdQuery>({
    query: QueryClientTerritoryByIdDocument,
    variables: { clientTerritoryId },
    ...options,
  });

  return { ...query, clientTerritory: query?.data?.clientTerritory_by_pk };
}

// Query Watch ById Helper
//
export type WatchQueryClientTerritoryByIdApolloObservableQuery = ObservableQuery<QueryClientTerritoryByIdQuery>;
async function watchQueryClientTerritoryById({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<QueryOptions<QueryClientTerritoryByIdQueryVariables>, 'query'>;
}): Promise<WatchQueryClientTerritoryByIdApolloObservableQuery> {
  return apolloClient.watchQuery<QueryClientTerritoryByIdQuery>({
    query: QueryClientTerritoryByIdDocument,
    ...options,
  });
}

// Query Fetch Objects Helper
//
export type QueryClientTerritoryObjectsObjectsApolloQueryResult = ApolloQueryResult<QueryClientTerritoryObjectsQuery>;
export type QueryClientTerritoryObjectsObjectsApolloQueryResultEx = QueryClientTerritoryObjectsObjectsApolloQueryResult &
  ClientTerritoryObjectsHelperResultEx;

async function queryClientTerritoryObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<QueryOptions<QueryClientTerritoryObjectsQueryVariables>, 'query'>;
}): Promise<QueryClientTerritoryObjectsObjectsApolloQueryResultEx> {
  const query: QueryClientTerritoryObjectsObjectsApolloQueryResult = await apolloClient.query<QueryClientTerritoryObjectsQuery>(
    { query: QueryClientTerritoryObjectsDocument, ...options }
  );

  return { ...query, objects: query?.data?.clientTerritory || [] };
}

// Query Watch Objects Helper
//
export type WatchQueryClientTerritoryObjectsApolloObservableQuery = ObservableQuery<QueryClientTerritoryObjectsQuery>;
async function watchQueryClientTerritoryObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<QueryOptions<QueryClientTerritoryObjectsQueryVariables>, 'query'>;
}): Promise<WatchQueryClientTerritoryObjectsApolloObservableQuery> {
  return apolloClient.watchQuery<QueryClientTerritoryObjectsQuery>({
    query: QueryClientTerritoryObjectsDocument,
    ...options,
  });
}

// Subscription Fetch ById Helper
//
export type SubscribeToClientTerritoryByIdSubscriptionFetchResult = FetchResult<
  SubscribeToClientTerritoryByIdSubscription,
  Record<string, any>,
  Record<string, any>
>;
export type SubscribeToClientTerritoryByIdSubscriptionFetchResultEx = FetchResult<
  SubscribeToClientTerritoryByIdSubscription,
  Record<string, any>,
  Record<string, any>
> &
  ClientTerritoryByIdHelperResultEx;

async function subscribeToClientTerritoryById({
  apolloClient,
  clientTerritoryId,
  options,
}: {
  apolloClient: ApolloClient<object>;
  clientTerritoryId: string;
  options?: Omit<SubscriptionOptions<SubscribeToClientTerritoryByIdSubscriptionVariables>, 'query' | 'variables'>;
}): Promise<Observable<SubscribeToClientTerritoryByIdSubscriptionFetchResultEx>> {
  const subscription: Observable<SubscribeToClientTerritoryByIdSubscriptionFetchResult> = apolloClient.subscribe<SubscribeToClientTerritoryByIdSubscription>(
    { query: SubscribeToClientTerritoryByIdDocument, variables: { clientTerritoryId }, ...options }
  );

  return subscription.map((value) => {
    return {
      context: value.context,
      errors: value.errors,
      data: value.data,
      extensions: value.extensions,
      clientTerritory: value?.data?.clientTerritory_by_pk || [],
    } as SubscribeToClientTerritoryByIdSubscriptionFetchResultEx;
  });
}

// Subscription Fetch Objects Helper
//
export type SubscribeToClientTerritoryObjectsSubscriptionFetchResult = FetchResult<
  SubscribeToClientTerritoryObjectsSubscription,
  Record<string, any>,
  Record<string, any>
>;
export type SubscribeToClientTerritoryObjectsSubscriptionFetchResultEx = FetchResult<
  SubscribeToClientTerritoryObjectsSubscription,
  Record<string, any>,
  Record<string, any>
> &
  ClientTerritoryObjectsHelperResultEx;

async function subscribeToClientTerritoryObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options?: Omit<SubscriptionOptions<SubscribeToClientTerritoryObjectsSubscriptionVariables>, 'query'>;
}): Promise<Observable<SubscribeToClientTerritoryObjectsSubscriptionFetchResultEx>> {
  const subscription: Observable<SubscribeToClientTerritoryObjectsSubscriptionFetchResult> = apolloClient.subscribe<SubscribeToClientTerritoryObjectsSubscription>(
    { query: SubscribeToClientTerritoryObjectsDocument, ...options }
  );

  return subscription.map((value) => {
    return {
      context: value.context,
      errors: value.errors,
      data: value.data,
      extensions: value.extensions,
      objects: value?.data?.clientTerritory || [],
    } as SubscribeToClientTerritoryObjectsSubscriptionFetchResultEx;
  });
}

// Insert Helper
//
type InsertClientTerritoryFetchResult = FetchResult<
  InsertClientTerritoryMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertClientTerritoryFetchHelperResultEx = InsertClientTerritoryFetchResult &
  ClientTerritoryByIdHelperResultEx;

async function insertClientTerritory({
  apolloClient,
  clientTerritory,
  autoOptimisticResponse,
  fieldMap,
  options,
}: {
  apolloClient: ApolloClient<object>;
  clientTerritory: ClientTerritory_Insert_Input;
  autoOptimisticResponse?: boolean;
  fieldMap?: FieldMap;
  options?: Omit<
    MutationOptions<InsertClientTerritoryMutation, InsertClientTerritoryMutationVariables>,
    'mutation' | 'variables'
  >;
}): Promise<InsertClientTerritoryFetchHelperResultEx> {
  const objectForInsert = stripInsertInputClientFields({ input: clientTerritory });
  const mutationOptions: MutationOptions<InsertClientTerritoryMutation, InsertClientTerritoryMutationVariables> = {
    mutation: InsertClientTerritoryDocument,
    variables: { objects: [objectForInsert] },
    ...options,
  };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    if (!objectForInsert.id)
      throw new Error(`if autoOptimisticResponse = true, id must be set in object 'clientTerritory'`);
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertClientTerritoryMutation>({
      operationType: 'insert',
      entityName: 'clientTerritory',
      objects: [objectForInsert as ClientTerritory_Insert_Input & ObjectWithId],
      fieldMap,
    });
    if (logLevel >= 2)
      console.log(' --> insertClientTerritory - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: InsertClientTerritoryFetchResult = await apolloClient.mutate<
    InsertClientTerritoryMutation,
    InsertClientTerritoryMutationVariables
  >(mutationOptions);

  return {
    ...mutation,
    clientTerritory:
      mutation?.data?.insert_clientTerritory?.returning && mutation.data.insert_clientTerritory.returning[0],
  };
}

async function insertClientTerritoryWithOnConflict({
  apolloClient,
  clientTerritory,
  onConflict,
  autoOptimisticResponse,
  fieldMap,
  options,
}: {
  apolloClient: ApolloClient<object>;
  clientTerritory: ClientTerritory_Insert_Input;
  onConflict: ClientTerritory_On_Conflict;
  autoOptimisticResponse?: boolean;
  fieldMap?: FieldMap;
  options?: Omit<
    MutationOptions<InsertClientTerritoryWithOnConflictMutation, InsertClientTerritoryWithOnConflictMutationVariables>,
    'mutation' | 'variables'
  >;
}): Promise<InsertClientTerritoryFetchHelperResultEx> {
  const objectForInsert = stripInsertInputClientFields({ input: clientTerritory });
  const mutationOptions: MutationOptions<
    InsertClientTerritoryWithOnConflictMutation,
    InsertClientTerritoryWithOnConflictMutationVariables
  > = {
    mutation: InsertClientTerritoryWithOnConflictDocument,
    variables: { objects: [objectForInsert], onConflict },
    ...options,
  };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    if (!objectForInsert.id)
      throw new Error(`if autoOptimisticResponse = true, id must be set in object 'clientTerritory'`);
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertClientTerritoryWithOnConflictMutation>(
      {
        operationType: 'insert',
        entityName: 'clientTerritory',
        objects: [objectForInsert as ClientTerritory_Insert_Input & ObjectWithId],
        fieldMap,
      }
    );
    if (logLevel >= 2)
      console.log(' --> insertClientTerritoryWithOnConflict - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: InsertClientTerritoryFetchResult = await apolloClient.mutate<
    InsertClientTerritoryWithOnConflictMutation,
    InsertClientTerritoryWithOnConflictMutationVariables
  >(mutationOptions);

  return {
    ...mutation,
    clientTerritory:
      mutation?.data?.insert_clientTerritory?.returning && mutation.data.insert_clientTerritory.returning[0],
  };
}

// Insert Objects Helper
//
type InsertClientTerritoryObjectsFetchResult = FetchResult<
  InsertClientTerritoryMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertClientTerritoryObjectsHelperResultEx = InsertClientTerritoryObjectsFetchResult &
  ClientTerritoryObjectsHelperResultEx;

async function insertClientTerritoryObjects({
  apolloClient,
  objects,
  autoOptimisticResponse,
  fieldMap,
  options,
}: {
  apolloClient: ApolloClient<object>;
  objects: ClientTerritory_Insert_Input[];
  autoOptimisticResponse?: boolean;
  fieldMap?: FieldMap;
  options?: Omit<
    MutationOptions<InsertClientTerritoryMutation, InsertClientTerritoryMutationVariables>,
    'mutation' | 'variables'
  >;
}): Promise<InsertClientTerritoryObjectsHelperResultEx> {
  const objectsForInsert = objects.map((objectItem) => stripInsertInputClientFields({ input: objectItem }));
  const mutationOptions: MutationOptions<InsertClientTerritoryMutation, InsertClientTerritoryMutationVariables> = {
    mutation: InsertClientTerritoryDocument,
    variables: { objects: objectsForInsert },
    ...options,
  };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    if (objectsForInsert.find((objectItem) => !objectItem.id))
      throw new Error(`if autoOptimisticResponse = true, ids must be set in objects`);
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertClientTerritoryMutation>({
      operationType: 'insert',
      entityName: 'clientTerritory',
      objects: objectsForInsert as (ClientTerritory_Insert_Input & ObjectWithId)[],
      fieldMap,
    });
    if (logLevel >= 2)
      console.log(' --> insertClientTerritory - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: InsertClientTerritoryObjectsFetchResult = await apolloClient.mutate<
    InsertClientTerritoryMutation,
    InsertClientTerritoryMutationVariables
  >(mutationOptions);

  return { ...mutation, objects: mutation?.data?.insert_clientTerritory?.returning || [] };
}

// Update Helper
//
type UpdateClientTerritoryByIdQueryResult = FetchResult<
  UpdateClientTerritoryByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateClientTerritoryByIdHelperResultEx = UpdateClientTerritoryByIdQueryResult &
  ClientTerritoryByIdHelperResultEx;

async function updateClientTerritoryById({
  apolloClient,
  clientTerritoryId,
  set,
  autoOptimisticResponse,
  options,
}: {
  apolloClient: ApolloClient<object>;
  clientTerritoryId: string;
  set: ClientTerritory_Set_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationOptions<UpdateClientTerritoryByIdMutation, UpdateClientTerritoryByIdMutationVariables>,
    'mutation'
  >;
}): Promise<UpdateClientTerritoryByIdHelperResultEx> {
  const mutationOptions: MutationOptions<
    UpdateClientTerritoryByIdMutation,
    UpdateClientTerritoryByIdMutationVariables
  > = { mutation: UpdateClientTerritoryByIdDocument, variables: { id: clientTerritoryId, set }, ...options };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateClientTerritoryByIdMutation>({
      operationType: 'update',
      entityName: 'clientTerritory',
      objects: [{ id: clientTerritoryId, ...set }],
    });
    if (logLevel >= 2)
      console.log(' --> updateClientTerritoryById - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: UpdateClientTerritoryByIdQueryResult = await apolloClient.mutate<
    UpdateClientTerritoryByIdMutation,
    UpdateClientTerritoryByIdMutationVariables
  >(mutationOptions);

  return {
    ...mutation,
    clientTerritory:
      mutation?.data?.update_clientTerritory?.returning && mutation.data.update_clientTerritory.returning[0],
  };
}

// Update Objects Helper
//
type UpdateClientTerritoryObjectsFetchResult = FetchResult<
  UpdateClientTerritoryMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateClientTerritoryObjectsHelperResultEx = UpdateClientTerritoryObjectsFetchResult &
  ClientTerritoryObjectsHelperResultEx;

async function updateClientTerritoryObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<MutationOptions<UpdateClientTerritoryMutation, UpdateClientTerritoryMutationVariables>, 'mutation'>;
}): Promise<UpdateClientTerritoryObjectsHelperResultEx> {
  const mutation: UpdateClientTerritoryObjectsFetchResult = await apolloClient.mutate<
    UpdateClientTerritoryMutation,
    UpdateClientTerritoryMutationVariables
  >({ mutation: UpdateClientTerritoryDocument, ...options });

  return { ...mutation, objects: mutation?.data?.update_clientTerritory?.returning || [] };
}

// Delete Helper
//

type RemoveClientTerritoryModelByIdQueryResult = FetchResult<
  RemoveClientTerritoryModelByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveClientTerritoryModelByIdQueryHelperResultEx = RemoveClientTerritoryModelByIdQueryResult &
  RemoveEntitiesQueryHelperResultEx;

async function removeClientTerritoryModelById({
  apolloClient,
  clientTerritoryId,
  autoOptimisticResponse,
  options,
}: {
  apolloClient: ApolloClient<object>;
  clientTerritoryId: string;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationOptions<RemoveClientTerritoryModelByIdMutation, RemoveClientTerritoryModelByIdMutationVariables>,
    'mutation'
  >;
}): Promise<RemoveClientTerritoryModelByIdQueryHelperResultEx> {
  const mutationOptions: MutationOptions<
    RemoveClientTerritoryModelByIdMutation,
    RemoveClientTerritoryModelByIdMutationVariables
  > = { mutation: RemoveClientTerritoryModelByIdDocument, variables: { id: clientTerritoryId }, ...options };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<RemoveClientTerritoryModelByIdMutation>({
      operationType: 'delete',
      entityName: 'clientTerritory',
      objects: [{ id: clientTerritoryId }],
    });
    if (logLevel >= 2)
      console.log(' --> removeClientTerritoryModelById - optimisticResponse:', mutationOptions.optimisticResponse);
  }
  if (!options || !options.update) {
    mutationOptions.update = generateUpdateFunctionForMutation<RemoveClientTerritoryModelByIdMutation>({
      operationType: 'delete',
      entityName: 'clientTerritory',
      entityId: clientTerritoryId,
    });
  }

  const mutation: RemoveClientTerritoryModelByIdQueryResult = await apolloClient.mutate<
    RemoveClientTerritoryModelByIdMutation,
    RemoveClientTerritoryModelByIdMutationVariables
  >(mutationOptions);

  return { ...mutation, affected_rows: mutation?.data?.delete_clientTerritory?.affected_rows || 0 };
}

type RemoveClientTerritoryModelObjectsQueryResult = FetchResult<
  RemoveClientTerritoryModelMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveClientTerritoryModelObjectsQueryHelperResultEx = RemoveClientTerritoryModelObjectsQueryResult &
  RemoveEntitiesQueryHelperResultEx;

async function removeClientTerritoryModelObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<
    MutationOptions<RemoveClientTerritoryModelMutation, RemoveClientTerritoryModelMutationVariables>,
    'mutation'
  >;
}): Promise<RemoveClientTerritoryModelObjectsQueryHelperResultEx> {
  const mutation: RemoveClientTerritoryModelObjectsQueryResult = await apolloClient.mutate<
    RemoveClientTerritoryModelMutation,
    RemoveClientTerritoryModelMutationVariables
  >({ mutation: RemoveClientTerritoryModelDocument, ...options });

  return { ...mutation, affected_rows: mutation?.data?.delete_clientTerritory?.affected_rows || 0 };
}

// ClientTerritory Fragment Helper Object
//------------------------------------------------

export const ClientTerritoryFragmentGQLHelper = {
  cacheGetDataId: cacheGetDataIdForClientTerritory,
  cacheReadFragmentById: cacheReadFragmentClientTerritoryById,
  cacheWriteFragmentById: cacheWriteFragmentClientTerritoryById,
  cacheReadQueryById: cacheReadQueryClientTerritoryById,
  cacheWriteQueryById: cacheWriteQueryClientTerritoryById,
  cacheReadQueryObjects: cacheReadQueryClientTerritoryObjects,
  cacheWriteQueryObjects: cacheWriteQueryClientTerritoryObjects,
  cacheWriteQueryInsert: cacheWriteQueryClientTerritoryInsert,
  cacheWriteQueryRemove: cacheWriteQueryClientTerritoryRemove,
  queryById: queryClientTerritoryById,
  queryObjects: queryClientTerritoryObjects,
  watchQueryById: watchQueryClientTerritoryById,
  watchQueryObjects: watchQueryClientTerritoryObjects,
  subscribeToById: subscribeToClientTerritoryById,
  subscribeToObjects: subscribeToClientTerritoryObjects,
  insert: insertClientTerritory,
  insertWithOnConflict: insertClientTerritoryWithOnConflict,
  insertObjects: insertClientTerritoryObjects,
  updateById: updateClientTerritoryById,
  updateObjects: updateClientTerritoryObjects,
};

// clientTerritory Entity Helper Object
//------------------------------------------------

export const ClientTerritoryModelGQLHelper = {
  removeById: removeClientTerritoryModelById,
  removeObjects: removeClientTerritoryModelObjects,
};

// user HELPERS
//------------------------------------------------

export type UserByIdHelperResultEx = { user: UserFragment | null | undefined };
export type UserObjectsHelperResultEx = { objects: UserFragment[] };

// Direct Client & Cache Fragment Helpers
//
function cacheGetDataIdForUser(userId: string): string {
  return defaultCacheIdFromObject({ __typename: 'user', id: userId });
}

function cacheReadFragmentUserById({
  apolloCache,
  userId,
}: {
  apolloCache: ApolloCache<object>;
  userId: string;
}): UserFragment | null | undefined {
  return apolloCache.readFragment<UserFragment | null | undefined>({
    fragment: UserFragmentDoc,
    fragmentName: 'User',
    id: defaultCacheIdFromObject({ __typename: 'user', id: userId }),
  });
}

function cacheWriteFragmentUserById({
  apolloCache,
  userId,
  userPartial,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  userId: string;
  userPartial: Partial<UserFragment> | User_Insert_Input | null;
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): Partial<UserFragment> {
  const parsedFragment = convertToGraph({ input: userPartial, typename: 'user', fieldMap });
  if (logLevel >= 2) console.log(' --> cacheWriteFragmentUserById - parsedFragment:', parsedFragment);
  apolloCache.writeFragment<Partial<UserFragment> | null>({
    fragment: UserFragmentDoc,
    fragmentName: 'User',
    id: defaultCacheIdFromObject({ ...parsedFragment, id: userId }),
    data: parsedFragment,
    broadcast: apolloBroadcast,
  });
  return parsedFragment;
}

function cacheReadQueryUserById({
  apolloCache,
  userId,
}: {
  apolloCache: ApolloCache<object>;
  userId: string;
}): UserFragment | null | undefined {
  try {
    return apolloCache.readQuery<UserFragment | null>({ query: QueryUserByIdDocument, variables: { userId } });
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheReadQueryUserById threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheWriteQueryUserById({
  apolloCache,
  userId,
  user,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  userId: string;
  user: UserFragment | User_Insert_Input | null;
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): void {
  try {
    const userPartial = convertToGraph({ input: user, typename: 'user', fieldMap });
    return apolloCache.writeQuery<UserFragment | null>({
      query: QueryUserByIdDocument,
      variables: { userId },
      data: user ? userPartial : null,
      broadcast: apolloBroadcast,
    });
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheWriteQueryUserById threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheReadQueryUserObjects({
  apolloCache,
  variables,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryUserObjectsQueryVariables;
}): User[] | null | undefined {
  try {
    return apolloCache.readQuery<{ User: User[] | null }>({ query: QueryUserObjectsDocument, variables })?.User || [];
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheReadQueryUserObjects threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheWriteQueryUserObjects({
  apolloCache,
  variables,
  data,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryUserObjectsQueryVariables;
  data: (User | User_Insert_Input)[];
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): void {
  try {
    const objects = convertToGraph({ input: data, typename: 'user', fieldMap });
    return apolloCache.writeQuery<{ User: User[] }>({
      query: QueryUserObjectsDocument,
      variables,
      data: { User: objects },
      broadcast: apolloBroadcast,
    });
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheWriteQueryUserObjects threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheWriteQueryUserInsert({
  apolloCache,
  variables,
  user,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryUserObjectsQueryVariables;
  user: User_Insert_Input;
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): void {
  const currentObjects = cacheReadQueryUserObjects({ apolloCache, variables }) || [];
  const objectsWithInserted = [...currentObjects, convertToGraph({ input: user, typename: 'user', fieldMap })];
  if (logLevel >= 2) console.log(' --> cacheWriteQueryUserInsert - objectsWithInserted:', objectsWithInserted);
  return cacheWriteQueryUserObjects({ apolloCache, variables, data: objectsWithInserted, apolloBroadcast });
}

function cacheWriteQueryUserRemove({
  apolloCache,
  variables,
  userId,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryUserObjectsQueryVariables;
  userId: string;
  apolloBroadcast?: boolean;
}): void {
  const currentObjects = cacheReadQueryUserObjects({ apolloCache, variables }) || [];
  const objectsWithRemoved = currentObjects.filter((objectItem) => objectItem.id !== userId) || [];
  if (logLevel >= 2) console.log(' --> cacheWriteQueryUserRemove - objectsWithRemoved:', objectsWithRemoved);
  return cacheWriteQueryUserObjects({ apolloCache, variables, data: objectsWithRemoved, apolloBroadcast });
}

// Query Fetch Helper
//
export type QueryUserByIdApolloQueryResult = ApolloQueryResult<QueryUserByIdQuery>;
export type QueryUserByIdApolloQueryHelperResultEx = QueryUserByIdApolloQueryResult & UserByIdHelperResultEx;

async function queryUserById({
  apolloClient,
  userId,
  options,
}: {
  apolloClient: ApolloClient<object>;
  userId: string;
  options?: Omit<QueryOptions<QueryUserByIdQueryVariables>, 'query' | 'variables'>;
}): Promise<QueryUserByIdApolloQueryHelperResultEx> {
  const query: QueryUserByIdApolloQueryResult = await apolloClient.query<QueryUserByIdQuery>({
    query: QueryUserByIdDocument,
    variables: { userId },
    ...options,
  });

  return { ...query, user: query?.data?.user_by_pk };
}

// Query Watch ById Helper
//
export type WatchQueryUserByIdApolloObservableQuery = ObservableQuery<QueryUserByIdQuery>;
async function watchQueryUserById({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<QueryOptions<QueryUserByIdQueryVariables>, 'query'>;
}): Promise<WatchQueryUserByIdApolloObservableQuery> {
  return apolloClient.watchQuery<QueryUserByIdQuery>({ query: QueryUserByIdDocument, ...options });
}

// Query Fetch Objects Helper
//
export type QueryUserObjectsObjectsApolloQueryResult = ApolloQueryResult<QueryUserObjectsQuery>;
export type QueryUserObjectsObjectsApolloQueryResultEx = QueryUserObjectsObjectsApolloQueryResult &
  UserObjectsHelperResultEx;

async function queryUserObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<QueryOptions<QueryUserObjectsQueryVariables>, 'query'>;
}): Promise<QueryUserObjectsObjectsApolloQueryResultEx> {
  const query: QueryUserObjectsObjectsApolloQueryResult = await apolloClient.query<QueryUserObjectsQuery>({
    query: QueryUserObjectsDocument,
    ...options,
  });

  return { ...query, objects: query?.data?.user || [] };
}

// Query Watch Objects Helper
//
export type WatchQueryUserObjectsApolloObservableQuery = ObservableQuery<QueryUserObjectsQuery>;
async function watchQueryUserObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<QueryOptions<QueryUserObjectsQueryVariables>, 'query'>;
}): Promise<WatchQueryUserObjectsApolloObservableQuery> {
  return apolloClient.watchQuery<QueryUserObjectsQuery>({ query: QueryUserObjectsDocument, ...options });
}

// Subscription Fetch ById Helper
//
export type SubscribeToUserByIdSubscriptionFetchResult = FetchResult<
  SubscribeToUserByIdSubscription,
  Record<string, any>,
  Record<string, any>
>;
export type SubscribeToUserByIdSubscriptionFetchResultEx = FetchResult<
  SubscribeToUserByIdSubscription,
  Record<string, any>,
  Record<string, any>
> &
  UserByIdHelperResultEx;

async function subscribeToUserById({
  apolloClient,
  userId,
  options,
}: {
  apolloClient: ApolloClient<object>;
  userId: string;
  options?: Omit<SubscriptionOptions<SubscribeToUserByIdSubscriptionVariables>, 'query' | 'variables'>;
}): Promise<Observable<SubscribeToUserByIdSubscriptionFetchResultEx>> {
  const subscription: Observable<SubscribeToUserByIdSubscriptionFetchResult> = apolloClient.subscribe<SubscribeToUserByIdSubscription>(
    { query: SubscribeToUserByIdDocument, variables: { userId }, ...options }
  );

  return subscription.map((value) => {
    return {
      context: value.context,
      errors: value.errors,
      data: value.data,
      extensions: value.extensions,
      user: value?.data?.user_by_pk || [],
    } as SubscribeToUserByIdSubscriptionFetchResultEx;
  });
}

// Subscription Fetch Objects Helper
//
export type SubscribeToUserObjectsSubscriptionFetchResult = FetchResult<
  SubscribeToUserObjectsSubscription,
  Record<string, any>,
  Record<string, any>
>;
export type SubscribeToUserObjectsSubscriptionFetchResultEx = FetchResult<
  SubscribeToUserObjectsSubscription,
  Record<string, any>,
  Record<string, any>
> &
  UserObjectsHelperResultEx;

async function subscribeToUserObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options?: Omit<SubscriptionOptions<SubscribeToUserObjectsSubscriptionVariables>, 'query'>;
}): Promise<Observable<SubscribeToUserObjectsSubscriptionFetchResultEx>> {
  const subscription: Observable<SubscribeToUserObjectsSubscriptionFetchResult> = apolloClient.subscribe<SubscribeToUserObjectsSubscription>(
    { query: SubscribeToUserObjectsDocument, ...options }
  );

  return subscription.map((value) => {
    return {
      context: value.context,
      errors: value.errors,
      data: value.data,
      extensions: value.extensions,
      objects: value?.data?.user || [],
    } as SubscribeToUserObjectsSubscriptionFetchResultEx;
  });
}

// Insert Helper
//
type InsertUserFetchResult = FetchResult<InsertUserMutation, Record<string, any>, Record<string, any>>;
export type InsertUserFetchHelperResultEx = InsertUserFetchResult & UserByIdHelperResultEx;

async function insertUser({
  apolloClient,
  user,
  autoOptimisticResponse,
  fieldMap,
  options,
}: {
  apolloClient: ApolloClient<object>;
  user: User_Insert_Input;
  autoOptimisticResponse?: boolean;
  fieldMap?: FieldMap;
  options?: Omit<MutationOptions<InsertUserMutation, InsertUserMutationVariables>, 'mutation' | 'variables'>;
}): Promise<InsertUserFetchHelperResultEx> {
  const objectForInsert = stripInsertInputClientFields({ input: user });
  const mutationOptions: MutationOptions<InsertUserMutation, InsertUserMutationVariables> = {
    mutation: InsertUserDocument,
    variables: { objects: [objectForInsert] },
    ...options,
  };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    if (!objectForInsert.id) throw new Error(`if autoOptimisticResponse = true, id must be set in object 'user'`);
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertUserMutation>({
      operationType: 'insert',
      entityName: 'user',
      objects: [objectForInsert as User_Insert_Input & ObjectWithId],
      fieldMap,
    });
    if (logLevel >= 2) console.log(' --> insertUser - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: InsertUserFetchResult = await apolloClient.mutate<InsertUserMutation, InsertUserMutationVariables>(
    mutationOptions
  );

  return { ...mutation, user: mutation?.data?.insert_user?.returning && mutation.data.insert_user.returning[0] };
}

async function insertUserWithOnConflict({
  apolloClient,
  user,
  onConflict,
  autoOptimisticResponse,
  fieldMap,
  options,
}: {
  apolloClient: ApolloClient<object>;
  user: User_Insert_Input;
  onConflict: User_On_Conflict;
  autoOptimisticResponse?: boolean;
  fieldMap?: FieldMap;
  options?: Omit<
    MutationOptions<InsertUserWithOnConflictMutation, InsertUserWithOnConflictMutationVariables>,
    'mutation' | 'variables'
  >;
}): Promise<InsertUserFetchHelperResultEx> {
  const objectForInsert = stripInsertInputClientFields({ input: user });
  const mutationOptions: MutationOptions<
    InsertUserWithOnConflictMutation,
    InsertUserWithOnConflictMutationVariables
  > = { mutation: InsertUserWithOnConflictDocument, variables: { objects: [objectForInsert], onConflict }, ...options };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    if (!objectForInsert.id) throw new Error(`if autoOptimisticResponse = true, id must be set in object 'user'`);
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertUserWithOnConflictMutation>({
      operationType: 'insert',
      entityName: 'user',
      objects: [objectForInsert as User_Insert_Input & ObjectWithId],
      fieldMap,
    });
    if (logLevel >= 2)
      console.log(' --> insertUserWithOnConflict - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: InsertUserFetchResult = await apolloClient.mutate<
    InsertUserWithOnConflictMutation,
    InsertUserWithOnConflictMutationVariables
  >(mutationOptions);

  return { ...mutation, user: mutation?.data?.insert_user?.returning && mutation.data.insert_user.returning[0] };
}

// Insert Objects Helper
//
type InsertUserObjectsFetchResult = FetchResult<InsertUserMutation, Record<string, any>, Record<string, any>>;
export type InsertUserObjectsHelperResultEx = InsertUserObjectsFetchResult & UserObjectsHelperResultEx;

async function insertUserObjects({
  apolloClient,
  objects,
  autoOptimisticResponse,
  fieldMap,
  options,
}: {
  apolloClient: ApolloClient<object>;
  objects: User_Insert_Input[];
  autoOptimisticResponse?: boolean;
  fieldMap?: FieldMap;
  options?: Omit<MutationOptions<InsertUserMutation, InsertUserMutationVariables>, 'mutation' | 'variables'>;
}): Promise<InsertUserObjectsHelperResultEx> {
  const objectsForInsert = objects.map((objectItem) => stripInsertInputClientFields({ input: objectItem }));
  const mutationOptions: MutationOptions<InsertUserMutation, InsertUserMutationVariables> = {
    mutation: InsertUserDocument,
    variables: { objects: objectsForInsert },
    ...options,
  };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    if (objectsForInsert.find((objectItem) => !objectItem.id))
      throw new Error(`if autoOptimisticResponse = true, ids must be set in objects`);
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertUserMutation>({
      operationType: 'insert',
      entityName: 'user',
      objects: objectsForInsert as (User_Insert_Input & ObjectWithId)[],
      fieldMap,
    });
    if (logLevel >= 2) console.log(' --> insertUser - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: InsertUserObjectsFetchResult = await apolloClient.mutate<
    InsertUserMutation,
    InsertUserMutationVariables
  >(mutationOptions);

  return { ...mutation, objects: mutation?.data?.insert_user?.returning || [] };
}

// Update Helper
//
type UpdateUserByIdQueryResult = FetchResult<UpdateUserByIdMutation, Record<string, any>, Record<string, any>>;
export type UpdateUserByIdHelperResultEx = UpdateUserByIdQueryResult & UserByIdHelperResultEx;

async function updateUserById({
  apolloClient,
  userId,
  set,
  autoOptimisticResponse,
  options,
}: {
  apolloClient: ApolloClient<object>;
  userId: string;
  set: User_Set_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<MutationOptions<UpdateUserByIdMutation, UpdateUserByIdMutationVariables>, 'mutation'>;
}): Promise<UpdateUserByIdHelperResultEx> {
  const mutationOptions: MutationOptions<UpdateUserByIdMutation, UpdateUserByIdMutationVariables> = {
    mutation: UpdateUserByIdDocument,
    variables: { id: userId, set },
    ...options,
  };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateUserByIdMutation>({
      operationType: 'update',
      entityName: 'user',
      objects: [{ id: userId, ...set }],
    });
    if (logLevel >= 2) console.log(' --> updateUserById - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: UpdateUserByIdQueryResult = await apolloClient.mutate<
    UpdateUserByIdMutation,
    UpdateUserByIdMutationVariables
  >(mutationOptions);

  return { ...mutation, user: mutation?.data?.update_user?.returning && mutation.data.update_user.returning[0] };
}

// Update Objects Helper
//
type UpdateUserObjectsFetchResult = FetchResult<UpdateUserMutation, Record<string, any>, Record<string, any>>;
export type UpdateUserObjectsHelperResultEx = UpdateUserObjectsFetchResult & UserObjectsHelperResultEx;

async function updateUserObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<MutationOptions<UpdateUserMutation, UpdateUserMutationVariables>, 'mutation'>;
}): Promise<UpdateUserObjectsHelperResultEx> {
  const mutation: UpdateUserObjectsFetchResult = await apolloClient.mutate<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >({ mutation: UpdateUserDocument, ...options });

  return { ...mutation, objects: mutation?.data?.update_user?.returning || [] };
}

// Delete Helper
//

type RemoveUserModelByIdQueryResult = FetchResult<
  RemoveUserModelByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveUserModelByIdQueryHelperResultEx = RemoveUserModelByIdQueryResult & RemoveEntitiesQueryHelperResultEx;

async function removeUserModelById({
  apolloClient,
  userId,
  autoOptimisticResponse,
  options,
}: {
  apolloClient: ApolloClient<object>;
  userId: string;
  autoOptimisticResponse?: boolean;
  options?: Omit<MutationOptions<RemoveUserModelByIdMutation, RemoveUserModelByIdMutationVariables>, 'mutation'>;
}): Promise<RemoveUserModelByIdQueryHelperResultEx> {
  const mutationOptions: MutationOptions<RemoveUserModelByIdMutation, RemoveUserModelByIdMutationVariables> = {
    mutation: RemoveUserModelByIdDocument,
    variables: { id: userId },
    ...options,
  };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<RemoveUserModelByIdMutation>({
      operationType: 'delete',
      entityName: 'user',
      objects: [{ id: userId }],
    });
    if (logLevel >= 2)
      console.log(' --> removeUserModelById - optimisticResponse:', mutationOptions.optimisticResponse);
  }
  if (!options || !options.update) {
    mutationOptions.update = generateUpdateFunctionForMutation<RemoveUserModelByIdMutation>({
      operationType: 'delete',
      entityName: 'user',
      entityId: userId,
    });
  }

  const mutation: RemoveUserModelByIdQueryResult = await apolloClient.mutate<
    RemoveUserModelByIdMutation,
    RemoveUserModelByIdMutationVariables
  >(mutationOptions);

  return { ...mutation, affected_rows: mutation?.data?.delete_user?.affected_rows || 0 };
}

type RemoveUserModelObjectsQueryResult = FetchResult<RemoveUserModelMutation, Record<string, any>, Record<string, any>>;
export type RemoveUserModelObjectsQueryHelperResultEx = RemoveUserModelObjectsQueryResult &
  RemoveEntitiesQueryHelperResultEx;

async function removeUserModelObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<MutationOptions<RemoveUserModelMutation, RemoveUserModelMutationVariables>, 'mutation'>;
}): Promise<RemoveUserModelObjectsQueryHelperResultEx> {
  const mutation: RemoveUserModelObjectsQueryResult = await apolloClient.mutate<
    RemoveUserModelMutation,
    RemoveUserModelMutationVariables
  >({ mutation: RemoveUserModelDocument, ...options });

  return { ...mutation, affected_rows: mutation?.data?.delete_user?.affected_rows || 0 };
}

// User Fragment Helper Object
//------------------------------------------------

export const UserFragmentGQLHelper = {
  cacheGetDataId: cacheGetDataIdForUser,
  cacheReadFragmentById: cacheReadFragmentUserById,
  cacheWriteFragmentById: cacheWriteFragmentUserById,
  cacheReadQueryById: cacheReadQueryUserById,
  cacheWriteQueryById: cacheWriteQueryUserById,
  cacheReadQueryObjects: cacheReadQueryUserObjects,
  cacheWriteQueryObjects: cacheWriteQueryUserObjects,
  cacheWriteQueryInsert: cacheWriteQueryUserInsert,
  cacheWriteQueryRemove: cacheWriteQueryUserRemove,
  queryById: queryUserById,
  queryObjects: queryUserObjects,
  watchQueryById: watchQueryUserById,
  watchQueryObjects: watchQueryUserObjects,
  subscribeToById: subscribeToUserById,
  subscribeToObjects: subscribeToUserObjects,
  insert: insertUser,
  insertWithOnConflict: insertUserWithOnConflict,
  insertObjects: insertUserObjects,
  updateById: updateUserById,
  updateObjects: updateUserObjects,
};

// user Entity Helper Object
//------------------------------------------------

export const UserModelGQLHelper = {
  removeById: removeUserModelById,
  removeObjects: removeUserModelObjects,
};

// userInvite HELPERS
//------------------------------------------------

export type UserInviteByIdHelperResultEx = { userInvite: UserInviteFragment | null | undefined };
export type UserInviteObjectsHelperResultEx = { objects: UserInviteFragment[] };

// Direct Client & Cache Fragment Helpers
//
function cacheGetDataIdForUserInvite(userInviteId: string): string {
  return defaultCacheIdFromObject({ __typename: 'userInvite', id: userInviteId });
}

function cacheReadFragmentUserInviteById({
  apolloCache,
  userInviteId,
}: {
  apolloCache: ApolloCache<object>;
  userInviteId: string;
}): UserInviteFragment | null | undefined {
  return apolloCache.readFragment<UserInviteFragment | null | undefined>({
    fragment: UserInviteFragmentDoc,
    fragmentName: 'UserInvite',
    id: defaultCacheIdFromObject({ __typename: 'userInvite', id: userInviteId }),
  });
}

function cacheWriteFragmentUserInviteById({
  apolloCache,
  userInviteId,
  userInvitePartial,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  userInviteId: string;
  userInvitePartial: Partial<UserInviteFragment> | UserInvite_Insert_Input | null;
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): Partial<UserInviteFragment> {
  const parsedFragment = convertToGraph({ input: userInvitePartial, typename: 'userInvite', fieldMap });
  if (logLevel >= 2) console.log(' --> cacheWriteFragmentUserInviteById - parsedFragment:', parsedFragment);
  apolloCache.writeFragment<Partial<UserInviteFragment> | null>({
    fragment: UserInviteFragmentDoc,
    fragmentName: 'UserInvite',
    id: defaultCacheIdFromObject({ ...parsedFragment, id: userInviteId }),
    data: parsedFragment,
    broadcast: apolloBroadcast,
  });
  return parsedFragment;
}

function cacheReadQueryUserInviteById({
  apolloCache,
  userInviteId,
}: {
  apolloCache: ApolloCache<object>;
  userInviteId: string;
}): UserInviteFragment | null | undefined {
  try {
    return apolloCache.readQuery<UserInviteFragment | null>({
      query: QueryUserInviteByIdDocument,
      variables: { userInviteId },
    });
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheReadQueryUserInviteById threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheWriteQueryUserInviteById({
  apolloCache,
  userInviteId,
  userInvite,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  userInviteId: string;
  userInvite: UserInviteFragment | UserInvite_Insert_Input | null;
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): void {
  try {
    const userInvitePartial = convertToGraph({ input: userInvite, typename: 'userInvite', fieldMap });
    return apolloCache.writeQuery<UserInviteFragment | null>({
      query: QueryUserInviteByIdDocument,
      variables: { userInviteId },
      data: userInvite ? userInvitePartial : null,
      broadcast: apolloBroadcast,
    });
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheWriteQueryUserInviteById threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheReadQueryUserInviteObjects({
  apolloCache,
  variables,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryUserInviteObjectsQueryVariables;
}): UserInvite[] | null | undefined {
  try {
    return (
      apolloCache.readQuery<{ UserInvite: UserInvite[] | null }>({ query: QueryUserInviteObjectsDocument, variables })
        ?.UserInvite || []
    );
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheReadQueryUserInviteObjects threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheWriteQueryUserInviteObjects({
  apolloCache,
  variables,
  data,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryUserInviteObjectsQueryVariables;
  data: (UserInvite | UserInvite_Insert_Input)[];
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): void {
  try {
    const objects = convertToGraph({ input: data, typename: 'userInvite', fieldMap });
    return apolloCache.writeQuery<{ UserInvite: UserInvite[] }>({
      query: QueryUserInviteObjectsDocument,
      variables,
      data: { UserInvite: objects },
      broadcast: apolloBroadcast,
    });
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheWriteQueryUserInviteObjects threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheWriteQueryUserInviteInsert({
  apolloCache,
  variables,
  userInvite,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryUserInviteObjectsQueryVariables;
  userInvite: UserInvite_Insert_Input;
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): void {
  const currentObjects = cacheReadQueryUserInviteObjects({ apolloCache, variables }) || [];
  const objectsWithInserted = [
    ...currentObjects,
    convertToGraph({ input: userInvite, typename: 'userInvite', fieldMap }),
  ];
  if (logLevel >= 2) console.log(' --> cacheWriteQueryUserInviteInsert - objectsWithInserted:', objectsWithInserted);
  return cacheWriteQueryUserInviteObjects({ apolloCache, variables, data: objectsWithInserted, apolloBroadcast });
}

function cacheWriteQueryUserInviteRemove({
  apolloCache,
  variables,
  userInviteId,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryUserInviteObjectsQueryVariables;
  userInviteId: string;
  apolloBroadcast?: boolean;
}): void {
  const currentObjects = cacheReadQueryUserInviteObjects({ apolloCache, variables }) || [];
  const objectsWithRemoved = currentObjects.filter((objectItem) => objectItem.id !== userInviteId) || [];
  if (logLevel >= 2) console.log(' --> cacheWriteQueryUserInviteRemove - objectsWithRemoved:', objectsWithRemoved);
  return cacheWriteQueryUserInviteObjects({ apolloCache, variables, data: objectsWithRemoved, apolloBroadcast });
}

// Query Fetch Helper
//
export type QueryUserInviteByIdApolloQueryResult = ApolloQueryResult<QueryUserInviteByIdQuery>;
export type QueryUserInviteByIdApolloQueryHelperResultEx = QueryUserInviteByIdApolloQueryResult &
  UserInviteByIdHelperResultEx;

async function queryUserInviteById({
  apolloClient,
  userInviteId,
  options,
}: {
  apolloClient: ApolloClient<object>;
  userInviteId: string;
  options?: Omit<QueryOptions<QueryUserInviteByIdQueryVariables>, 'query' | 'variables'>;
}): Promise<QueryUserInviteByIdApolloQueryHelperResultEx> {
  const query: QueryUserInviteByIdApolloQueryResult = await apolloClient.query<QueryUserInviteByIdQuery>({
    query: QueryUserInviteByIdDocument,
    variables: { userInviteId },
    ...options,
  });

  return { ...query, userInvite: query?.data?.userInvite_by_pk };
}

// Query Watch ById Helper
//
export type WatchQueryUserInviteByIdApolloObservableQuery = ObservableQuery<QueryUserInviteByIdQuery>;
async function watchQueryUserInviteById({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<QueryOptions<QueryUserInviteByIdQueryVariables>, 'query'>;
}): Promise<WatchQueryUserInviteByIdApolloObservableQuery> {
  return apolloClient.watchQuery<QueryUserInviteByIdQuery>({ query: QueryUserInviteByIdDocument, ...options });
}

// Query Fetch Objects Helper
//
export type QueryUserInviteObjectsObjectsApolloQueryResult = ApolloQueryResult<QueryUserInviteObjectsQuery>;
export type QueryUserInviteObjectsObjectsApolloQueryResultEx = QueryUserInviteObjectsObjectsApolloQueryResult &
  UserInviteObjectsHelperResultEx;

async function queryUserInviteObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<QueryOptions<QueryUserInviteObjectsQueryVariables>, 'query'>;
}): Promise<QueryUserInviteObjectsObjectsApolloQueryResultEx> {
  const query: QueryUserInviteObjectsObjectsApolloQueryResult = await apolloClient.query<QueryUserInviteObjectsQuery>({
    query: QueryUserInviteObjectsDocument,
    ...options,
  });

  return { ...query, objects: query?.data?.userInvite || [] };
}

// Query Watch Objects Helper
//
export type WatchQueryUserInviteObjectsApolloObservableQuery = ObservableQuery<QueryUserInviteObjectsQuery>;
async function watchQueryUserInviteObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<QueryOptions<QueryUserInviteObjectsQueryVariables>, 'query'>;
}): Promise<WatchQueryUserInviteObjectsApolloObservableQuery> {
  return apolloClient.watchQuery<QueryUserInviteObjectsQuery>({ query: QueryUserInviteObjectsDocument, ...options });
}

// Subscription Fetch ById Helper
//
export type SubscribeToUserInviteByIdSubscriptionFetchResult = FetchResult<
  SubscribeToUserInviteByIdSubscription,
  Record<string, any>,
  Record<string, any>
>;
export type SubscribeToUserInviteByIdSubscriptionFetchResultEx = FetchResult<
  SubscribeToUserInviteByIdSubscription,
  Record<string, any>,
  Record<string, any>
> &
  UserInviteByIdHelperResultEx;

async function subscribeToUserInviteById({
  apolloClient,
  userInviteId,
  options,
}: {
  apolloClient: ApolloClient<object>;
  userInviteId: string;
  options?: Omit<SubscriptionOptions<SubscribeToUserInviteByIdSubscriptionVariables>, 'query' | 'variables'>;
}): Promise<Observable<SubscribeToUserInviteByIdSubscriptionFetchResultEx>> {
  const subscription: Observable<SubscribeToUserInviteByIdSubscriptionFetchResult> = apolloClient.subscribe<SubscribeToUserInviteByIdSubscription>(
    { query: SubscribeToUserInviteByIdDocument, variables: { userInviteId }, ...options }
  );

  return subscription.map((value) => {
    return {
      context: value.context,
      errors: value.errors,
      data: value.data,
      extensions: value.extensions,
      userInvite: value?.data?.userInvite_by_pk || [],
    } as SubscribeToUserInviteByIdSubscriptionFetchResultEx;
  });
}

// Subscription Fetch Objects Helper
//
export type SubscribeToUserInviteObjectsSubscriptionFetchResult = FetchResult<
  SubscribeToUserInviteObjectsSubscription,
  Record<string, any>,
  Record<string, any>
>;
export type SubscribeToUserInviteObjectsSubscriptionFetchResultEx = FetchResult<
  SubscribeToUserInviteObjectsSubscription,
  Record<string, any>,
  Record<string, any>
> &
  UserInviteObjectsHelperResultEx;

async function subscribeToUserInviteObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options?: Omit<SubscriptionOptions<SubscribeToUserInviteObjectsSubscriptionVariables>, 'query'>;
}): Promise<Observable<SubscribeToUserInviteObjectsSubscriptionFetchResultEx>> {
  const subscription: Observable<SubscribeToUserInviteObjectsSubscriptionFetchResult> = apolloClient.subscribe<SubscribeToUserInviteObjectsSubscription>(
    { query: SubscribeToUserInviteObjectsDocument, ...options }
  );

  return subscription.map((value) => {
    return {
      context: value.context,
      errors: value.errors,
      data: value.data,
      extensions: value.extensions,
      objects: value?.data?.userInvite || [],
    } as SubscribeToUserInviteObjectsSubscriptionFetchResultEx;
  });
}

// Insert Helper
//
type InsertUserInviteFetchResult = FetchResult<InsertUserInviteMutation, Record<string, any>, Record<string, any>>;
export type InsertUserInviteFetchHelperResultEx = InsertUserInviteFetchResult & UserInviteByIdHelperResultEx;

async function insertUserInvite({
  apolloClient,
  userInvite,
  autoOptimisticResponse,
  fieldMap,
  options,
}: {
  apolloClient: ApolloClient<object>;
  userInvite: UserInvite_Insert_Input;
  autoOptimisticResponse?: boolean;
  fieldMap?: FieldMap;
  options?: Omit<
    MutationOptions<InsertUserInviteMutation, InsertUserInviteMutationVariables>,
    'mutation' | 'variables'
  >;
}): Promise<InsertUserInviteFetchHelperResultEx> {
  const objectForInsert = stripInsertInputClientFields({ input: userInvite });
  const mutationOptions: MutationOptions<InsertUserInviteMutation, InsertUserInviteMutationVariables> = {
    mutation: InsertUserInviteDocument,
    variables: { objects: [objectForInsert] },
    ...options,
  };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    if (!objectForInsert.id) throw new Error(`if autoOptimisticResponse = true, id must be set in object 'userInvite'`);
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertUserInviteMutation>({
      operationType: 'insert',
      entityName: 'userInvite',
      objects: [objectForInsert as UserInvite_Insert_Input & ObjectWithId],
      fieldMap,
    });
    if (logLevel >= 2) console.log(' --> insertUserInvite - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: InsertUserInviteFetchResult = await apolloClient.mutate<
    InsertUserInviteMutation,
    InsertUserInviteMutationVariables
  >(mutationOptions);

  return {
    ...mutation,
    userInvite: mutation?.data?.insert_userInvite?.returning && mutation.data.insert_userInvite.returning[0],
  };
}

async function insertUserInviteWithOnConflict({
  apolloClient,
  userInvite,
  onConflict,
  autoOptimisticResponse,
  fieldMap,
  options,
}: {
  apolloClient: ApolloClient<object>;
  userInvite: UserInvite_Insert_Input;
  onConflict: UserInvite_On_Conflict;
  autoOptimisticResponse?: boolean;
  fieldMap?: FieldMap;
  options?: Omit<
    MutationOptions<InsertUserInviteWithOnConflictMutation, InsertUserInviteWithOnConflictMutationVariables>,
    'mutation' | 'variables'
  >;
}): Promise<InsertUserInviteFetchHelperResultEx> {
  const objectForInsert = stripInsertInputClientFields({ input: userInvite });
  const mutationOptions: MutationOptions<
    InsertUserInviteWithOnConflictMutation,
    InsertUserInviteWithOnConflictMutationVariables
  > = {
    mutation: InsertUserInviteWithOnConflictDocument,
    variables: { objects: [objectForInsert], onConflict },
    ...options,
  };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    if (!objectForInsert.id) throw new Error(`if autoOptimisticResponse = true, id must be set in object 'userInvite'`);
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertUserInviteWithOnConflictMutation>({
      operationType: 'insert',
      entityName: 'userInvite',
      objects: [objectForInsert as UserInvite_Insert_Input & ObjectWithId],
      fieldMap,
    });
    if (logLevel >= 2)
      console.log(' --> insertUserInviteWithOnConflict - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: InsertUserInviteFetchResult = await apolloClient.mutate<
    InsertUserInviteWithOnConflictMutation,
    InsertUserInviteWithOnConflictMutationVariables
  >(mutationOptions);

  return {
    ...mutation,
    userInvite: mutation?.data?.insert_userInvite?.returning && mutation.data.insert_userInvite.returning[0],
  };
}

// Insert Objects Helper
//
type InsertUserInviteObjectsFetchResult = FetchResult<
  InsertUserInviteMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertUserInviteObjectsHelperResultEx = InsertUserInviteObjectsFetchResult &
  UserInviteObjectsHelperResultEx;

async function insertUserInviteObjects({
  apolloClient,
  objects,
  autoOptimisticResponse,
  fieldMap,
  options,
}: {
  apolloClient: ApolloClient<object>;
  objects: UserInvite_Insert_Input[];
  autoOptimisticResponse?: boolean;
  fieldMap?: FieldMap;
  options?: Omit<
    MutationOptions<InsertUserInviteMutation, InsertUserInviteMutationVariables>,
    'mutation' | 'variables'
  >;
}): Promise<InsertUserInviteObjectsHelperResultEx> {
  const objectsForInsert = objects.map((objectItem) => stripInsertInputClientFields({ input: objectItem }));
  const mutationOptions: MutationOptions<InsertUserInviteMutation, InsertUserInviteMutationVariables> = {
    mutation: InsertUserInviteDocument,
    variables: { objects: objectsForInsert },
    ...options,
  };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    if (objectsForInsert.find((objectItem) => !objectItem.id))
      throw new Error(`if autoOptimisticResponse = true, ids must be set in objects`);
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertUserInviteMutation>({
      operationType: 'insert',
      entityName: 'userInvite',
      objects: objectsForInsert as (UserInvite_Insert_Input & ObjectWithId)[],
      fieldMap,
    });
    if (logLevel >= 2) console.log(' --> insertUserInvite - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: InsertUserInviteObjectsFetchResult = await apolloClient.mutate<
    InsertUserInviteMutation,
    InsertUserInviteMutationVariables
  >(mutationOptions);

  return { ...mutation, objects: mutation?.data?.insert_userInvite?.returning || [] };
}

// Update Helper
//
type UpdateUserInviteByIdQueryResult = FetchResult<
  UpdateUserInviteByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateUserInviteByIdHelperResultEx = UpdateUserInviteByIdQueryResult & UserInviteByIdHelperResultEx;

async function updateUserInviteById({
  apolloClient,
  userInviteId,
  set,
  autoOptimisticResponse,
  options,
}: {
  apolloClient: ApolloClient<object>;
  userInviteId: string;
  set: UserInvite_Set_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<MutationOptions<UpdateUserInviteByIdMutation, UpdateUserInviteByIdMutationVariables>, 'mutation'>;
}): Promise<UpdateUserInviteByIdHelperResultEx> {
  const mutationOptions: MutationOptions<UpdateUserInviteByIdMutation, UpdateUserInviteByIdMutationVariables> = {
    mutation: UpdateUserInviteByIdDocument,
    variables: { id: userInviteId, set },
    ...options,
  };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateUserInviteByIdMutation>({
      operationType: 'update',
      entityName: 'userInvite',
      objects: [{ id: userInviteId, ...set }],
    });
    if (logLevel >= 2)
      console.log(' --> updateUserInviteById - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: UpdateUserInviteByIdQueryResult = await apolloClient.mutate<
    UpdateUserInviteByIdMutation,
    UpdateUserInviteByIdMutationVariables
  >(mutationOptions);

  return {
    ...mutation,
    userInvite: mutation?.data?.update_userInvite?.returning && mutation.data.update_userInvite.returning[0],
  };
}

// Update Objects Helper
//
type UpdateUserInviteObjectsFetchResult = FetchResult<
  UpdateUserInviteMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateUserInviteObjectsHelperResultEx = UpdateUserInviteObjectsFetchResult &
  UserInviteObjectsHelperResultEx;

async function updateUserInviteObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<MutationOptions<UpdateUserInviteMutation, UpdateUserInviteMutationVariables>, 'mutation'>;
}): Promise<UpdateUserInviteObjectsHelperResultEx> {
  const mutation: UpdateUserInviteObjectsFetchResult = await apolloClient.mutate<
    UpdateUserInviteMutation,
    UpdateUserInviteMutationVariables
  >({ mutation: UpdateUserInviteDocument, ...options });

  return { ...mutation, objects: mutation?.data?.update_userInvite?.returning || [] };
}

// Delete Helper
//

type RemoveUserInviteModelByIdQueryResult = FetchResult<
  RemoveUserInviteModelByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveUserInviteModelByIdQueryHelperResultEx = RemoveUserInviteModelByIdQueryResult &
  RemoveEntitiesQueryHelperResultEx;

async function removeUserInviteModelById({
  apolloClient,
  userInviteId,
  autoOptimisticResponse,
  options,
}: {
  apolloClient: ApolloClient<object>;
  userInviteId: string;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationOptions<RemoveUserInviteModelByIdMutation, RemoveUserInviteModelByIdMutationVariables>,
    'mutation'
  >;
}): Promise<RemoveUserInviteModelByIdQueryHelperResultEx> {
  const mutationOptions: MutationOptions<
    RemoveUserInviteModelByIdMutation,
    RemoveUserInviteModelByIdMutationVariables
  > = { mutation: RemoveUserInviteModelByIdDocument, variables: { id: userInviteId }, ...options };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<RemoveUserInviteModelByIdMutation>({
      operationType: 'delete',
      entityName: 'userInvite',
      objects: [{ id: userInviteId }],
    });
    if (logLevel >= 2)
      console.log(' --> removeUserInviteModelById - optimisticResponse:', mutationOptions.optimisticResponse);
  }
  if (!options || !options.update) {
    mutationOptions.update = generateUpdateFunctionForMutation<RemoveUserInviteModelByIdMutation>({
      operationType: 'delete',
      entityName: 'userInvite',
      entityId: userInviteId,
    });
  }

  const mutation: RemoveUserInviteModelByIdQueryResult = await apolloClient.mutate<
    RemoveUserInviteModelByIdMutation,
    RemoveUserInviteModelByIdMutationVariables
  >(mutationOptions);

  return { ...mutation, affected_rows: mutation?.data?.delete_userInvite?.affected_rows || 0 };
}

type RemoveUserInviteModelObjectsQueryResult = FetchResult<
  RemoveUserInviteModelMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveUserInviteModelObjectsQueryHelperResultEx = RemoveUserInviteModelObjectsQueryResult &
  RemoveEntitiesQueryHelperResultEx;

async function removeUserInviteModelObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<MutationOptions<RemoveUserInviteModelMutation, RemoveUserInviteModelMutationVariables>, 'mutation'>;
}): Promise<RemoveUserInviteModelObjectsQueryHelperResultEx> {
  const mutation: RemoveUserInviteModelObjectsQueryResult = await apolloClient.mutate<
    RemoveUserInviteModelMutation,
    RemoveUserInviteModelMutationVariables
  >({ mutation: RemoveUserInviteModelDocument, ...options });

  return { ...mutation, affected_rows: mutation?.data?.delete_userInvite?.affected_rows || 0 };
}

// UserInvite Fragment Helper Object
//------------------------------------------------

export const UserInviteFragmentGQLHelper = {
  cacheGetDataId: cacheGetDataIdForUserInvite,
  cacheReadFragmentById: cacheReadFragmentUserInviteById,
  cacheWriteFragmentById: cacheWriteFragmentUserInviteById,
  cacheReadQueryById: cacheReadQueryUserInviteById,
  cacheWriteQueryById: cacheWriteQueryUserInviteById,
  cacheReadQueryObjects: cacheReadQueryUserInviteObjects,
  cacheWriteQueryObjects: cacheWriteQueryUserInviteObjects,
  cacheWriteQueryInsert: cacheWriteQueryUserInviteInsert,
  cacheWriteQueryRemove: cacheWriteQueryUserInviteRemove,
  queryById: queryUserInviteById,
  queryObjects: queryUserInviteObjects,
  watchQueryById: watchQueryUserInviteById,
  watchQueryObjects: watchQueryUserInviteObjects,
  subscribeToById: subscribeToUserInviteById,
  subscribeToObjects: subscribeToUserInviteObjects,
  insert: insertUserInvite,
  insertWithOnConflict: insertUserInviteWithOnConflict,
  insertObjects: insertUserInviteObjects,
  updateById: updateUserInviteById,
  updateObjects: updateUserInviteObjects,
};

// userInvite Entity Helper Object
//------------------------------------------------

export const UserInviteModelGQLHelper = {
  removeById: removeUserInviteModelById,
  removeObjects: removeUserInviteModelObjects,
};

// qaQualification HELPERS
//------------------------------------------------

export type QaQualificationByIdHelperResultEx = { qaQualification: QaQualificationFragment | null | undefined };
export type QaQualificationObjectsHelperResultEx = { objects: QaQualificationFragment[] };

// Direct Client & Cache Fragment Helpers
//
function cacheGetDataIdForQaQualification(qaQualificationId: string): string {
  return defaultCacheIdFromObject({ __typename: 'qaQualification', id: qaQualificationId });
}

function cacheReadFragmentQaQualificationById({
  apolloCache,
  qaQualificationId,
}: {
  apolloCache: ApolloCache<object>;
  qaQualificationId: string;
}): QaQualificationFragment | null | undefined {
  return apolloCache.readFragment<QaQualificationFragment | null | undefined>({
    fragment: QaQualificationFragmentDoc,
    fragmentName: 'QaQualification',
    id: defaultCacheIdFromObject({ __typename: 'qaQualification', id: qaQualificationId }),
  });
}

function cacheWriteFragmentQaQualificationById({
  apolloCache,
  qaQualificationId,
  qaQualificationPartial,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  qaQualificationId: string;
  qaQualificationPartial: Partial<QaQualificationFragment> | QaQualification_Insert_Input | null;
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): Partial<QaQualificationFragment> {
  const parsedFragment = convertToGraph({ input: qaQualificationPartial, typename: 'qaQualification', fieldMap });
  if (logLevel >= 2) console.log(' --> cacheWriteFragmentQaQualificationById - parsedFragment:', parsedFragment);
  apolloCache.writeFragment<Partial<QaQualificationFragment> | null>({
    fragment: QaQualificationFragmentDoc,
    fragmentName: 'QaQualification',
    id: defaultCacheIdFromObject({ ...parsedFragment, id: qaQualificationId }),
    data: parsedFragment,
    broadcast: apolloBroadcast,
  });
  return parsedFragment;
}

function cacheReadQueryQaQualificationById({
  apolloCache,
  qaQualificationId,
}: {
  apolloCache: ApolloCache<object>;
  qaQualificationId: string;
}): QaQualificationFragment | null | undefined {
  try {
    return apolloCache.readQuery<QaQualificationFragment | null>({
      query: QueryQaQualificationByIdDocument,
      variables: { qaQualificationId },
    });
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheReadQueryQaQualificationById threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheWriteQueryQaQualificationById({
  apolloCache,
  qaQualificationId,
  qaQualification,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  qaQualificationId: string;
  qaQualification: QaQualificationFragment | QaQualification_Insert_Input | null;
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): void {
  try {
    const qaQualificationPartial = convertToGraph({ input: qaQualification, typename: 'qaQualification', fieldMap });
    return apolloCache.writeQuery<QaQualificationFragment | null>({
      query: QueryQaQualificationByIdDocument,
      variables: { qaQualificationId },
      data: qaQualification ? qaQualificationPartial : null,
      broadcast: apolloBroadcast,
    });
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheWriteQueryQaQualificationById threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheReadQueryQaQualificationObjects({
  apolloCache,
  variables,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryQaQualificationObjectsQueryVariables;
}): QaQualification[] | null | undefined {
  try {
    return (
      apolloCache.readQuery<{ QaQualification: QaQualification[] | null }>({
        query: QueryQaQualificationObjectsDocument,
        variables,
      })?.QaQualification || []
    );
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheReadQueryQaQualificationObjects threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheWriteQueryQaQualificationObjects({
  apolloCache,
  variables,
  data,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryQaQualificationObjectsQueryVariables;
  data: (QaQualification | QaQualification_Insert_Input)[];
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): void {
  try {
    const objects = convertToGraph({ input: data, typename: 'qaQualification', fieldMap });
    return apolloCache.writeQuery<{ QaQualification: QaQualification[] }>({
      query: QueryQaQualificationObjectsDocument,
      variables,
      data: { QaQualification: objects },
      broadcast: apolloBroadcast,
    });
  } catch (error) {
    //DEVNOTE: Remove after this apollographql issue has been addressed: https://github.com/apollographql/apollo-client/issues/6094
    console.warn(
      'cacheWriteQueryQaQualificationObjects threw error. Could be related to this apolloGraphQl Issue. If so, can ignore: https://github.com/apollographql/apollo-client/issues/6094'
    );
  }
  return undefined;
}

function cacheWriteQueryQaQualificationInsert({
  apolloCache,
  variables,
  qaQualification,
  fieldMap,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryQaQualificationObjectsQueryVariables;
  qaQualification: QaQualification_Insert_Input;
  fieldMap?: FieldMap;
  apolloBroadcast?: boolean;
}): void {
  const currentObjects = cacheReadQueryQaQualificationObjects({ apolloCache, variables }) || [];
  const objectsWithInserted = [
    ...currentObjects,
    convertToGraph({ input: qaQualification, typename: 'qaQualification', fieldMap }),
  ];
  if (logLevel >= 2)
    console.log(' --> cacheWriteQueryQaQualificationInsert - objectsWithInserted:', objectsWithInserted);
  return cacheWriteQueryQaQualificationObjects({ apolloCache, variables, data: objectsWithInserted, apolloBroadcast });
}

function cacheWriteQueryQaQualificationRemove({
  apolloCache,
  variables,
  qaQualificationId,
  apolloBroadcast,
}: {
  apolloCache: ApolloCache<object>;
  variables: QueryQaQualificationObjectsQueryVariables;
  qaQualificationId: string;
  apolloBroadcast?: boolean;
}): void {
  const currentObjects = cacheReadQueryQaQualificationObjects({ apolloCache, variables }) || [];
  const objectsWithRemoved = currentObjects.filter((objectItem) => objectItem.id !== qaQualificationId) || [];
  if (logLevel >= 2) console.log(' --> cacheWriteQueryQaQualificationRemove - objectsWithRemoved:', objectsWithRemoved);
  return cacheWriteQueryQaQualificationObjects({ apolloCache, variables, data: objectsWithRemoved, apolloBroadcast });
}

// Query Fetch Helper
//
export type QueryQaQualificationByIdApolloQueryResult = ApolloQueryResult<QueryQaQualificationByIdQuery>;
export type QueryQaQualificationByIdApolloQueryHelperResultEx = QueryQaQualificationByIdApolloQueryResult &
  QaQualificationByIdHelperResultEx;

async function queryQaQualificationById({
  apolloClient,
  qaQualificationId,
  options,
}: {
  apolloClient: ApolloClient<object>;
  qaQualificationId: string;
  options?: Omit<QueryOptions<QueryQaQualificationByIdQueryVariables>, 'query' | 'variables'>;
}): Promise<QueryQaQualificationByIdApolloQueryHelperResultEx> {
  const query: QueryQaQualificationByIdApolloQueryResult = await apolloClient.query<QueryQaQualificationByIdQuery>({
    query: QueryQaQualificationByIdDocument,
    variables: { qaQualificationId },
    ...options,
  });

  return { ...query, qaQualification: query?.data?.qaQualification_by_pk };
}

// Query Watch ById Helper
//
export type WatchQueryQaQualificationByIdApolloObservableQuery = ObservableQuery<QueryQaQualificationByIdQuery>;
async function watchQueryQaQualificationById({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<QueryOptions<QueryQaQualificationByIdQueryVariables>, 'query'>;
}): Promise<WatchQueryQaQualificationByIdApolloObservableQuery> {
  return apolloClient.watchQuery<QueryQaQualificationByIdQuery>({
    query: QueryQaQualificationByIdDocument,
    ...options,
  });
}

// Query Fetch Objects Helper
//
export type QueryQaQualificationObjectsObjectsApolloQueryResult = ApolloQueryResult<QueryQaQualificationObjectsQuery>;
export type QueryQaQualificationObjectsObjectsApolloQueryResultEx = QueryQaQualificationObjectsObjectsApolloQueryResult &
  QaQualificationObjectsHelperResultEx;

async function queryQaQualificationObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<QueryOptions<QueryQaQualificationObjectsQueryVariables>, 'query'>;
}): Promise<QueryQaQualificationObjectsObjectsApolloQueryResultEx> {
  const query: QueryQaQualificationObjectsObjectsApolloQueryResult = await apolloClient.query<QueryQaQualificationObjectsQuery>(
    { query: QueryQaQualificationObjectsDocument, ...options }
  );

  return { ...query, objects: query?.data?.qaQualification || [] };
}

// Query Watch Objects Helper
//
export type WatchQueryQaQualificationObjectsApolloObservableQuery = ObservableQuery<QueryQaQualificationObjectsQuery>;
async function watchQueryQaQualificationObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<QueryOptions<QueryQaQualificationObjectsQueryVariables>, 'query'>;
}): Promise<WatchQueryQaQualificationObjectsApolloObservableQuery> {
  return apolloClient.watchQuery<QueryQaQualificationObjectsQuery>({
    query: QueryQaQualificationObjectsDocument,
    ...options,
  });
}

// Subscription Fetch ById Helper
//
export type SubscribeToQaQualificationByIdSubscriptionFetchResult = FetchResult<
  SubscribeToQaQualificationByIdSubscription,
  Record<string, any>,
  Record<string, any>
>;
export type SubscribeToQaQualificationByIdSubscriptionFetchResultEx = FetchResult<
  SubscribeToQaQualificationByIdSubscription,
  Record<string, any>,
  Record<string, any>
> &
  QaQualificationByIdHelperResultEx;

async function subscribeToQaQualificationById({
  apolloClient,
  qaQualificationId,
  options,
}: {
  apolloClient: ApolloClient<object>;
  qaQualificationId: string;
  options?: Omit<SubscriptionOptions<SubscribeToQaQualificationByIdSubscriptionVariables>, 'query' | 'variables'>;
}): Promise<Observable<SubscribeToQaQualificationByIdSubscriptionFetchResultEx>> {
  const subscription: Observable<SubscribeToQaQualificationByIdSubscriptionFetchResult> = apolloClient.subscribe<SubscribeToQaQualificationByIdSubscription>(
    { query: SubscribeToQaQualificationByIdDocument, variables: { qaQualificationId }, ...options }
  );

  return subscription.map((value) => {
    return {
      context: value.context,
      errors: value.errors,
      data: value.data,
      extensions: value.extensions,
      qaQualification: value?.data?.qaQualification_by_pk || [],
    } as SubscribeToQaQualificationByIdSubscriptionFetchResultEx;
  });
}

// Subscription Fetch Objects Helper
//
export type SubscribeToQaQualificationObjectsSubscriptionFetchResult = FetchResult<
  SubscribeToQaQualificationObjectsSubscription,
  Record<string, any>,
  Record<string, any>
>;
export type SubscribeToQaQualificationObjectsSubscriptionFetchResultEx = FetchResult<
  SubscribeToQaQualificationObjectsSubscription,
  Record<string, any>,
  Record<string, any>
> &
  QaQualificationObjectsHelperResultEx;

async function subscribeToQaQualificationObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options?: Omit<SubscriptionOptions<SubscribeToQaQualificationObjectsSubscriptionVariables>, 'query'>;
}): Promise<Observable<SubscribeToQaQualificationObjectsSubscriptionFetchResultEx>> {
  const subscription: Observable<SubscribeToQaQualificationObjectsSubscriptionFetchResult> = apolloClient.subscribe<SubscribeToQaQualificationObjectsSubscription>(
    { query: SubscribeToQaQualificationObjectsDocument, ...options }
  );

  return subscription.map((value) => {
    return {
      context: value.context,
      errors: value.errors,
      data: value.data,
      extensions: value.extensions,
      objects: value?.data?.qaQualification || [],
    } as SubscribeToQaQualificationObjectsSubscriptionFetchResultEx;
  });
}

// Insert Helper
//
type InsertQaQualificationFetchResult = FetchResult<
  InsertQaQualificationMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertQaQualificationFetchHelperResultEx = InsertQaQualificationFetchResult &
  QaQualificationByIdHelperResultEx;

async function insertQaQualification({
  apolloClient,
  qaQualification,
  autoOptimisticResponse,
  fieldMap,
  options,
}: {
  apolloClient: ApolloClient<object>;
  qaQualification: QaQualification_Insert_Input;
  autoOptimisticResponse?: boolean;
  fieldMap?: FieldMap;
  options?: Omit<
    MutationOptions<InsertQaQualificationMutation, InsertQaQualificationMutationVariables>,
    'mutation' | 'variables'
  >;
}): Promise<InsertQaQualificationFetchHelperResultEx> {
  const objectForInsert = stripInsertInputClientFields({ input: qaQualification });
  const mutationOptions: MutationOptions<InsertQaQualificationMutation, InsertQaQualificationMutationVariables> = {
    mutation: InsertQaQualificationDocument,
    variables: { objects: [objectForInsert] },
    ...options,
  };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    if (!objectForInsert.id)
      throw new Error(`if autoOptimisticResponse = true, id must be set in object 'qaQualification'`);
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertQaQualificationMutation>({
      operationType: 'insert',
      entityName: 'qaQualification',
      objects: [objectForInsert as QaQualification_Insert_Input & ObjectWithId],
      fieldMap,
    });
    if (logLevel >= 2)
      console.log(' --> insertQaQualification - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: InsertQaQualificationFetchResult = await apolloClient.mutate<
    InsertQaQualificationMutation,
    InsertQaQualificationMutationVariables
  >(mutationOptions);

  return {
    ...mutation,
    qaQualification:
      mutation?.data?.insert_qaQualification?.returning && mutation.data.insert_qaQualification.returning[0],
  };
}

async function insertQaQualificationWithOnConflict({
  apolloClient,
  qaQualification,
  onConflict,
  autoOptimisticResponse,
  fieldMap,
  options,
}: {
  apolloClient: ApolloClient<object>;
  qaQualification: QaQualification_Insert_Input;
  onConflict: QaQualification_On_Conflict;
  autoOptimisticResponse?: boolean;
  fieldMap?: FieldMap;
  options?: Omit<
    MutationOptions<InsertQaQualificationWithOnConflictMutation, InsertQaQualificationWithOnConflictMutationVariables>,
    'mutation' | 'variables'
  >;
}): Promise<InsertQaQualificationFetchHelperResultEx> {
  const objectForInsert = stripInsertInputClientFields({ input: qaQualification });
  const mutationOptions: MutationOptions<
    InsertQaQualificationWithOnConflictMutation,
    InsertQaQualificationWithOnConflictMutationVariables
  > = {
    mutation: InsertQaQualificationWithOnConflictDocument,
    variables: { objects: [objectForInsert], onConflict },
    ...options,
  };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    if (!objectForInsert.id)
      throw new Error(`if autoOptimisticResponse = true, id must be set in object 'qaQualification'`);
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertQaQualificationWithOnConflictMutation>(
      {
        operationType: 'insert',
        entityName: 'qaQualification',
        objects: [objectForInsert as QaQualification_Insert_Input & ObjectWithId],
        fieldMap,
      }
    );
    if (logLevel >= 2)
      console.log(' --> insertQaQualificationWithOnConflict - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: InsertQaQualificationFetchResult = await apolloClient.mutate<
    InsertQaQualificationWithOnConflictMutation,
    InsertQaQualificationWithOnConflictMutationVariables
  >(mutationOptions);

  return {
    ...mutation,
    qaQualification:
      mutation?.data?.insert_qaQualification?.returning && mutation.data.insert_qaQualification.returning[0],
  };
}

// Insert Objects Helper
//
type InsertQaQualificationObjectsFetchResult = FetchResult<
  InsertQaQualificationMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertQaQualificationObjectsHelperResultEx = InsertQaQualificationObjectsFetchResult &
  QaQualificationObjectsHelperResultEx;

async function insertQaQualificationObjects({
  apolloClient,
  objects,
  autoOptimisticResponse,
  fieldMap,
  options,
}: {
  apolloClient: ApolloClient<object>;
  objects: QaQualification_Insert_Input[];
  autoOptimisticResponse?: boolean;
  fieldMap?: FieldMap;
  options?: Omit<
    MutationOptions<InsertQaQualificationMutation, InsertQaQualificationMutationVariables>,
    'mutation' | 'variables'
  >;
}): Promise<InsertQaQualificationObjectsHelperResultEx> {
  const objectsForInsert = objects.map((objectItem) => stripInsertInputClientFields({ input: objectItem }));
  const mutationOptions: MutationOptions<InsertQaQualificationMutation, InsertQaQualificationMutationVariables> = {
    mutation: InsertQaQualificationDocument,
    variables: { objects: objectsForInsert },
    ...options,
  };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    if (objectsForInsert.find((objectItem) => !objectItem.id))
      throw new Error(`if autoOptimisticResponse = true, ids must be set in objects`);
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertQaQualificationMutation>({
      operationType: 'insert',
      entityName: 'qaQualification',
      objects: objectsForInsert as (QaQualification_Insert_Input & ObjectWithId)[],
      fieldMap,
    });
    if (logLevel >= 2)
      console.log(' --> insertQaQualification - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: InsertQaQualificationObjectsFetchResult = await apolloClient.mutate<
    InsertQaQualificationMutation,
    InsertQaQualificationMutationVariables
  >(mutationOptions);

  return { ...mutation, objects: mutation?.data?.insert_qaQualification?.returning || [] };
}

// Update Helper
//
type UpdateQaQualificationByIdQueryResult = FetchResult<
  UpdateQaQualificationByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateQaQualificationByIdHelperResultEx = UpdateQaQualificationByIdQueryResult &
  QaQualificationByIdHelperResultEx;

async function updateQaQualificationById({
  apolloClient,
  qaQualificationId,
  set,
  autoOptimisticResponse,
  options,
}: {
  apolloClient: ApolloClient<object>;
  qaQualificationId: string;
  set: QaQualification_Set_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationOptions<UpdateQaQualificationByIdMutation, UpdateQaQualificationByIdMutationVariables>,
    'mutation'
  >;
}): Promise<UpdateQaQualificationByIdHelperResultEx> {
  const mutationOptions: MutationOptions<
    UpdateQaQualificationByIdMutation,
    UpdateQaQualificationByIdMutationVariables
  > = { mutation: UpdateQaQualificationByIdDocument, variables: { id: qaQualificationId, set }, ...options };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateQaQualificationByIdMutation>({
      operationType: 'update',
      entityName: 'qaQualification',
      objects: [{ id: qaQualificationId, ...set }],
    });
    if (logLevel >= 2)
      console.log(' --> updateQaQualificationById - optimisticResponse:', mutationOptions.optimisticResponse);
  }

  const mutation: UpdateQaQualificationByIdQueryResult = await apolloClient.mutate<
    UpdateQaQualificationByIdMutation,
    UpdateQaQualificationByIdMutationVariables
  >(mutationOptions);

  return {
    ...mutation,
    qaQualification:
      mutation?.data?.update_qaQualification?.returning && mutation.data.update_qaQualification.returning[0],
  };
}

// Update Objects Helper
//
type UpdateQaQualificationObjectsFetchResult = FetchResult<
  UpdateQaQualificationMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateQaQualificationObjectsHelperResultEx = UpdateQaQualificationObjectsFetchResult &
  QaQualificationObjectsHelperResultEx;

async function updateQaQualificationObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<MutationOptions<UpdateQaQualificationMutation, UpdateQaQualificationMutationVariables>, 'mutation'>;
}): Promise<UpdateQaQualificationObjectsHelperResultEx> {
  const mutation: UpdateQaQualificationObjectsFetchResult = await apolloClient.mutate<
    UpdateQaQualificationMutation,
    UpdateQaQualificationMutationVariables
  >({ mutation: UpdateQaQualificationDocument, ...options });

  return { ...mutation, objects: mutation?.data?.update_qaQualification?.returning || [] };
}

// Delete Helper
//

type RemoveQaQualificationModelByIdQueryResult = FetchResult<
  RemoveQaQualificationModelByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveQaQualificationModelByIdQueryHelperResultEx = RemoveQaQualificationModelByIdQueryResult &
  RemoveEntitiesQueryHelperResultEx;

async function removeQaQualificationModelById({
  apolloClient,
  qaQualificationId,
  autoOptimisticResponse,
  options,
}: {
  apolloClient: ApolloClient<object>;
  qaQualificationId: string;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationOptions<RemoveQaQualificationModelByIdMutation, RemoveQaQualificationModelByIdMutationVariables>,
    'mutation'
  >;
}): Promise<RemoveQaQualificationModelByIdQueryHelperResultEx> {
  const mutationOptions: MutationOptions<
    RemoveQaQualificationModelByIdMutation,
    RemoveQaQualificationModelByIdMutationVariables
  > = { mutation: RemoveQaQualificationModelByIdDocument, variables: { id: qaQualificationId }, ...options };
  if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
    mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<RemoveQaQualificationModelByIdMutation>({
      operationType: 'delete',
      entityName: 'qaQualification',
      objects: [{ id: qaQualificationId }],
    });
    if (logLevel >= 2)
      console.log(' --> removeQaQualificationModelById - optimisticResponse:', mutationOptions.optimisticResponse);
  }
  if (!options || !options.update) {
    mutationOptions.update = generateUpdateFunctionForMutation<RemoveQaQualificationModelByIdMutation>({
      operationType: 'delete',
      entityName: 'qaQualification',
      entityId: qaQualificationId,
    });
  }

  const mutation: RemoveQaQualificationModelByIdQueryResult = await apolloClient.mutate<
    RemoveQaQualificationModelByIdMutation,
    RemoveQaQualificationModelByIdMutationVariables
  >(mutationOptions);

  return { ...mutation, affected_rows: mutation?.data?.delete_qaQualification?.affected_rows || 0 };
}

type RemoveQaQualificationModelObjectsQueryResult = FetchResult<
  RemoveQaQualificationModelMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveQaQualificationModelObjectsQueryHelperResultEx = RemoveQaQualificationModelObjectsQueryResult &
  RemoveEntitiesQueryHelperResultEx;

async function removeQaQualificationModelObjects({
  apolloClient,
  options,
}: {
  apolloClient: ApolloClient<object>;
  options: Omit<
    MutationOptions<RemoveQaQualificationModelMutation, RemoveQaQualificationModelMutationVariables>,
    'mutation'
  >;
}): Promise<RemoveQaQualificationModelObjectsQueryHelperResultEx> {
  const mutation: RemoveQaQualificationModelObjectsQueryResult = await apolloClient.mutate<
    RemoveQaQualificationModelMutation,
    RemoveQaQualificationModelMutationVariables
  >({ mutation: RemoveQaQualificationModelDocument, ...options });

  return { ...mutation, affected_rows: mutation?.data?.delete_qaQualification?.affected_rows || 0 };
}

// QaQualification Fragment Helper Object
//------------------------------------------------

export const QaQualificationFragmentGQLHelper = {
  cacheGetDataId: cacheGetDataIdForQaQualification,
  cacheReadFragmentById: cacheReadFragmentQaQualificationById,
  cacheWriteFragmentById: cacheWriteFragmentQaQualificationById,
  cacheReadQueryById: cacheReadQueryQaQualificationById,
  cacheWriteQueryById: cacheWriteQueryQaQualificationById,
  cacheReadQueryObjects: cacheReadQueryQaQualificationObjects,
  cacheWriteQueryObjects: cacheWriteQueryQaQualificationObjects,
  cacheWriteQueryInsert: cacheWriteQueryQaQualificationInsert,
  cacheWriteQueryRemove: cacheWriteQueryQaQualificationRemove,
  queryById: queryQaQualificationById,
  queryObjects: queryQaQualificationObjects,
  watchQueryById: watchQueryQaQualificationById,
  watchQueryObjects: watchQueryQaQualificationObjects,
  subscribeToById: subscribeToQaQualificationById,
  subscribeToObjects: subscribeToQaQualificationObjects,
  insert: insertQaQualification,
  insertWithOnConflict: insertQaQualificationWithOnConflict,
  insertObjects: insertQaQualificationObjects,
  updateById: updateQaQualificationById,
  updateObjects: updateQaQualificationObjects,
};

// qaQualification Entity Helper Object
//------------------------------------------------

export const QaQualificationModelGQLHelper = {
  removeById: removeQaQualificationModelById,
  removeObjects: removeQaQualificationModelObjects,
};

// COMBINED HELPER OBJECT
//------------------------------------------------
export const GQLHelpers = {
  Fragments: {
    ClientContactFilter: ClientContactFilterFragmentGQLHelper,
    ClientContactJobChange: ClientContactJobChangeFragmentGQLHelper,
    ClientContactExternal: ClientContactExternalFragmentGQLHelper,
    ClientContactQa: ClientContactQaFragmentGQLHelper,
    CrmContactEnrichmentHistory: CrmContactEnrichmentHistoryFragmentGQLHelper,
    ClientContactCrmData: ClientContactCrmDataFragmentGQLHelper,
    ClientExternal: ClientExternalFragmentGQLHelper,
    ClientQa: ClientQaFragmentGQLHelper,
    ClientInternal: ClientInternalFragmentGQLHelper,
    ClientSelect: ClientSelectFragmentGQLHelper,
    ClientNotificationEmail: ClientNotificationEmailFragmentGQLHelper,
    CloudTask: CloudTaskFragmentGQLHelper,
    ClientEmailReport: ClientEmailReportFragmentGQLHelper,
    EmailHistory: EmailHistoryFragmentGQLHelper,
    SendgridEvent: SendgridEventFragmentGQLHelper,
    CsvUpload: CsvUploadFragmentGQLHelper,
    HubspotContactList: HubspotContactListFragmentGQLHelper,
    HubspotContactListHubspotContact: HubspotContactListHubspotContactFragmentGQLHelper,
    HubspotContactProperty: HubspotContactPropertyFragmentGQLHelper,
    SalesforceContactField: SalesforceContactFieldFragmentGQLHelper,
    HubspotIntegration: HubspotIntegrationFragmentGQLHelper,
    SalesforceIntegration: SalesforceIntegrationFragmentGQLHelper,
    QaTaskAction: QaTaskActionFragmentGQLHelper,
    QaTask: QaTaskFragmentGQLHelper,
    ClientTerritory: ClientTerritoryFragmentGQLHelper,
    User: UserFragmentGQLHelper,
    UserInvite: UserInviteFragmentGQLHelper,
    QaQualification: QaQualificationFragmentGQLHelper,
  },
  Models: {
    ClientContactFilter: ClientContactFilterModelGQLHelper,
    ClientContactJobChange: ClientContactJobChangeModelGQLHelper,
    ClientContact: ClientContactModelGQLHelper,
    CrmContactEnrichmentHistory: CrmContactEnrichmentHistoryModelGQLHelper,
    ClientContactCrmData: ClientContactCrmDataModelGQLHelper,
    Client: ClientModelGQLHelper,
    ClientNotificationEmail: ClientNotificationEmailModelGQLHelper,
    CloudTask: CloudTaskModelGQLHelper,
    ClientEmailReport: ClientEmailReportModelGQLHelper,
    EmailHistory: EmailHistoryModelGQLHelper,
    SendgridEvent: SendgridEventModelGQLHelper,
    CsvUpload: CsvUploadModelGQLHelper,
    HubspotContactList: HubspotContactListModelGQLHelper,
    HubspotContactListHubspotContact: HubspotContactListHubspotContactModelGQLHelper,
    HubspotContactProperty: HubspotContactPropertyModelGQLHelper,
    SalesforceContactField: SalesforceContactFieldModelGQLHelper,
    HubspotIntegration: HubspotIntegrationModelGQLHelper,
    SalesforceIntegration: SalesforceIntegrationModelGQLHelper,
    QaTaskAction: QaTaskActionModelGQLHelper,
    QaTask: QaTaskModelGQLHelper,
    ClientTerritory: ClientTerritoryModelGQLHelper,
    User: UserModelGQLHelper,
    UserInvite: UserInviteModelGQLHelper,
    QaQualification: QaQualificationModelGQLHelper,
  },
};
