import { Box, Grid, Link, Typography } from '@material-ui/core';
import React from 'react';

import NavBar from '../components/NavBar';
import { UNDER_MAINTENANCE, WARMLY_EMAIL, WarmlyColor } from '../utils/constants';

const UnderMaintenance: React.FC = () => {
  return (
    <>
      <NavBar />
      <Grid
        container
        direction="column"
        alignItems="center"
        justify="center"
        style={{ backgroundColor: WarmlyColor.WHITE }}
        spacing={3}
      >
        <Grid item>
          <Typography variant="h1">Scheduled Maintenance Underway: Back Online by Monday</Typography>
        </Grid>
        <Grid item xs={6} md={4}>
          <img className="person-standing" alt="Person" src={UNDER_MAINTENANCE} width="100%" />
        </Grid>
        <Grid item xs={6}>
          <Box textAlign="center">
            <Typography variant="h5">
              Warmly is currently undergoing extensive maintenance for some much needed TLC. We expect to resume normal
              operations by the next business day. In the meantime, please feel free to contact us if you have any
              questions.
            </Typography>
            <Typography variant="h5">
              <Link
                target="_blank"
                rel="noopener noreferrer"
                href={`https://mail.google.com/mail/?view=cm&fs=1&to=${WARMLY_EMAIL.SUPPORT_GENERAL}&su=[Warmly] Question`}
              >
                {WARMLY_EMAIL.SUPPORT_GENERAL}
              </Link>
            </Typography>
            <Box marginY={1}>
              <Typography variant="caption">
                <Link target="_blank" rel="noopener noreferrer" href="https://ls.graphics/illustrations">
                  illustration provided by ls.graphics
                </Link>
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default UnderMaintenance;
