import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faBusinessTime, faEnvelope, faIdCard, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  LinearProgress,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { DropzoneArea } from 'material-ui-dropzone';
import Papa from 'papaparse';
import React, { useContext, useEffect, useState } from 'react';

import { LoadingAndAlertContext } from '../..';
import { GQLHooks } from '../../graphql/hasura/react';
import { logError } from '../../modules/analytics';
import { uploadCsvClientContacts } from '../../modules/cloudFunctions';
import { uploadFileToCloudStorage } from '../../modules/cloudStorage';
import { ClientContext } from '../../pages/Main';
import {
  AlertSeverity,
  ClientContactAttribute,
  StorageFolder,
  SyncStatus,
  WARMLY_EMAIL,
  WarmlyColor,
} from '../../utils/constants';
import { getAttribute, getDateStringFromTimestampString, MappedAttributes } from '../../utils/functions';
import { UserContext } from '../auth/UserRouter';
import { RowData } from './uploadCSV/AttributeRow';
import AttributesTable from './uploadCSV/AttributesTable';

interface UploadCsvModalProps {
  showUploadCsvModal: boolean;
  setShowUploadCsvModal: React.Dispatch<React.SetStateAction<boolean>>;
}

// Adopted from https://github.com/mholt/PapaParse/issues/752
const papaParsePromise = (file: File, config?: Papa.ParseConfig): Promise<Papa.ParseResult<any>> =>
  new Promise((resolve, reject) => {
    Papa.parse(file, {
      ...config,
      complete: function (results) {
        resolve(results);
      },
      error: function (error) {
        reject(error);
      },
    });
  });

const UploadCsvModal: React.FC<UploadCsvModalProps> = ({ showUploadCsvModal, setShowUploadCsvModal }) => {
  const { user } = useContext(UserContext);
  const { selectedClient } = useContext(ClientContext);
  const { isLoading, setIsLoading } = useContext(LoadingAndAlertContext);
  const [csvFile, setCsvFile] = useState<File>();
  const [errorMessage, setErrorMessage] = useState('');
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [attributeRowsData, setAttributeRowsData] = useState<RowData[]>();
  // Dictionary that maps CSV headers to our own attributes
  const [mappedAttributes, setMappedAttributes] = useState<MappedAttributes>({});
  const [filePath, setFilePath] = useState('');
  const [fileName, setFileName] = useState('');
  const [insertCsvUpload] = GQLHooks.Fragments.CsvUpload.useInsert();

  const syncStatus = selectedClient.syncStatus;

  useEffect(() => {
    if (csvFile) {
      setIsLoading(true);

      uploadFileToCloudStorage(csvFile, StorageFolder.CLIENT_UPLOAD, user?.clientId!)
        .then(({ filePath, fileName }) => {
          if (filePath) {
            setFilePath(filePath);
            setFileName(fileName);
          } else {
            throw new Error('No file path from uploading file');
          }
        })
        .catch((err) => {
          setIsLoading(false);
          logError(err);
          setErrorMessage(
            `The server encountered an issue while uploading your file. We apologize for the inconvenience. If you encounter this error more than once, please email us at ${WARMLY_EMAIL.CSM}`
          );
        });
    }
  }, [csvFile, setIsLoading, user]);

  useEffect(() => {
    if (filePath && csvFile && !isConfirmed) {
      const parseData = async () => {
        try {
          setErrorMessage('');
          const parseResult = await papaParsePromise(csvFile);

          if (!parseResult.data || !parseResult.data.length) {
            throw new Error('No data found');
          } else if (parseResult.errors.length) {
            throw parseResult.errors;
          }

          const csvHeaders: string[] = parseResult.data[0].map((header: string) => header.trim());

          // csvDataExcludingHeaders is used for previewing data in the table
          const csvDataExcludingHeaders: string[] = parseResult.data.slice(1, 4);

          const mappedAttributesFromCsv: MappedAttributes = {};

          const attributeRowsDataFromCsv: RowData[] = csvHeaders.map((header, index) => {
            // For each CSV header, we first check whether there is a existing attribute
            // in our database that approximately corresponds to the header, if not, we assume
            // that the attribute will be new and same as the CSV header
            const attribute = getAttribute(header);
            // We need to trim the header in case the header is an empty string
            if (attribute && header.trim()) {
              // If there is a corresponding attribute, we record the mapped attribute name
              // so we can transform the data accordingly before saving to database
              mappedAttributesFromCsv[header] = attribute;
            }

            const attributeData: string[] = csvDataExcludingHeaders.map((dataRow) => {
              return dataRow[index];
            });

            return {
              headerName: header,
              attributeData,
              attribute,
            };
          });

          setMappedAttributes(mappedAttributesFromCsv);
          setAttributeRowsData(attributeRowsDataFromCsv);
        } catch (err) {
          logError(err);
          setErrorMessage(
            `An error occurred while processing the .csv file, please verify that it is in valid CSV format with the minimum required information. If you continue to experience this error, please email us at ${WARMLY_EMAIL.CSM}`
          );
        } finally {
          setIsLoading(false);
        }
      };
      parseData();
    }
  }, [filePath, csvFile, setIsLoading, isConfirmed]);

  const onClose = () => {
    // Reset all local state when the modal is closed
    setIsConfirmed(false);
    setErrorMessage('');
    setFilePath('');
    setFileName('');
    setAttributeRowsData(undefined);
    setCsvFile(undefined);
    setMappedAttributes({});
    setShowUploadCsvModal(false);
  };

  const onCancel = () => {
    onClose();
  };

  const handleFileOnDrop = (file: File) => {
    setCsvFile(file);
  };

  const onConfirmSubmit = async () => {
    setIsLoading(true);
    setErrorMessage('');

    try {
      const allAttributes = Object.values(mappedAttributes);

      let validationErrorMessage = '';

      // If linkedIn URL is provided, we don't require any other data
      if (
        !allAttributes.includes(ClientContactAttribute.LINKEDIN_URL) &&
        !allAttributes.includes(ClientContactAttribute.LINKEDIN_ID)
      ) {
        if (
          !allAttributes.includes(ClientContactAttribute.FULL_NAME) &&
          !(
            allAttributes.includes(ClientContactAttribute.FIRST_NAME) &&
            allAttributes.includes(ClientContactAttribute.LAST_NAME)
          )
        ) {
          validationErrorMessage += 'Name is required (either Full Name or First + Last Name) \n';
        }

        if (
          !allAttributes.includes(ClientContactAttribute.CURRENT_COMPANY) &&
          !allAttributes.includes(ClientContactAttribute.EMAIL)
        ) {
          validationErrorMessage += 'At least one other identifier (Current Company or Email) is required';
        }
      }

      if (validationErrorMessage) {
        setErrorMessage(validationErrorMessage);
        return;
      }

      const attributesExcludingOther = allAttributes.filter((attr) => attr !== ClientContactAttribute.OTHER);
      const distinctArttributesExcludingOther = [...new Set(attributesExcludingOther)];

      if (attributesExcludingOther.length !== distinctArttributesExcludingOther.length) {
        setErrorMessage('Each attribute (apart from Other) can only be mapped to a single header');
        return;
      }

      setIsConfirmed(true);

      const inserCsvUploadMutation = await insertCsvUpload({
        csvUpload: {
          clientId: selectedClient.id,
          fileName,
          filePath,
          mapping: mappedAttributes,
          uploadedAt: new Date().toISOString(),
          uploadedByUserId: user.id,
        },
      });

      const csvUploadId = inserCsvUploadMutation.csvUpload?.id;

      if (!csvUploadId || inserCsvUploadMutation.errors) {
        throw inserCsvUploadMutation.errors;
      }

      const uploadResponse = await uploadCsvClientContacts(csvUploadId);

      if (uploadResponse.status >= 400) {
        throw uploadResponse;
      }
    } catch (err) {
      logError(err);
      setIsConfirmed(false);
      setErrorMessage(
        `An error occured while processing your data. Please contact us at ${WARMLY_EMAIL.CSM} if you continue to experience this issue.`
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={showUploadCsvModal} onClose={onClose} maxWidth="xl">
      <DialogTitle disableTypography>
        <Typography variant="h3">Upload CSV</Typography>
      </DialogTitle>
      <DialogContent>
        {syncStatus === SyncStatus.SYNCING && (
          <>
            <Box marginY={2}>
              <LinearProgress variant="query" />
            </Box>
            <Typography>
              Your CSV file is currently being processed by our server. You may leave this page and return later to
              check the status. You should expect to see the All Contacts page populated within a few minutes (larger
              lists may take 10 - 15 minutes). If you have any questions, please contact us at{' '}
              <Link
                href={`https://mail.google.com/mail/?view=cm&fs=1&to=${WARMLY_EMAIL.CSM}&su=[Warmly] CSV Upload`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {WARMLY_EMAIL.CSM}
              </Link>
            </Typography>
          </>
        )}
        {syncStatus === SyncStatus.ERROR && (
          <Box paddingY={2}>
            <Alert severity={AlertSeverity.ERROR}>
              <Typography>
                {`Our server encountered an error while processing your CSV file. Our tech team has been notified and will attempt to remedy this issue
                as soon as possible. You may attempt to reupload a different file, but if you experience further errors
                or have any questions, please contact us at`}{' '}
                <Link
                  href={`https://mail.google.com/mail/?view=cm&fs=1&to=${WARMLY_EMAIL.CSM}&su=[Warmly] CSV Upload`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {WARMLY_EMAIL.CSM}
                </Link>
              </Typography>
            </Alert>
          </Box>
        )}
        {syncStatus === SyncStatus.COMPLETE && (
          <Box paddingY={2}>
            <Alert severity={AlertSeverity.SUCCESS}>
              {`✅ ${selectedClient.lastCsvUpload?.contactCount?.toLocaleString()} contacts successfully synced from your CSV file at ${getDateStringFromTimestampString(
                selectedClient.lastCsvUpload?.syncCompletedAt
              )}. We are in process of enriching your data and will notify you of any detected job changes. 
                Please note that we attempt to remove duplicate contacts by checking contacts that share the same email, 
                so this number may be different from the number of records in your CSV file.`}
            </Alert>
          </Box>
        )}
        {syncStatus !== SyncStatus.SYNCING && (
          <>
            <Typography>
              Before you upload your CSV file, please make sure that your data has as many of the following columns as
              possible.
            </Typography>
            <Box marginTop={2}>
              <Grid container>
                <Grid item xs={6}>
                  <List dense={true}>
                    <ListItem>
                      <ListItemIcon>
                        <FontAwesomeIcon size="lg" icon={faUser} color={WarmlyColor.DARK_BLUE} />
                      </ListItemIcon>
                      <ListItemText primary="Name (First / Last)" />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <FontAwesomeIcon size="lg" icon={faEnvelope} color={WarmlyColor.DARK_BLUE} />
                      </ListItemIcon>
                      <ListItemText primary="Email" />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <FontAwesomeIcon size="lg" icon={faLinkedin} color={WarmlyColor.DARK_BLUE} />
                      </ListItemIcon>
                      <ListItemText primary="LinkedIn" />
                    </ListItem>
                  </List>
                </Grid>
                <Grid item xs={6}>
                  <List dense={true}>
                    <ListItem>
                      <ListItemIcon>
                        <FontAwesomeIcon size="lg" icon={faBusinessTime} color={WarmlyColor.DARK_BLUE} />
                      </ListItemIcon>
                      <ListItemText primary="Company Name" />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <FontAwesomeIcon size="lg" icon={faIdCard} color={WarmlyColor.DARK_BLUE} />
                      </ListItemIcon>
                      <ListItemText primary="Job Title" />
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </Box>
            <Box marginY={1}>
              <strong>
                If LinkedIn is not provided, we require at minimum a) contact's name and b) email or company.
              </strong>
              {!filePath && (
                <Box marginY={2}>
                  Please note that for larger (10MB+) files, it may take up to a few minutes to upload all the data. For
                  files larger than 50MB, please contact us first at{' '}
                  <Link
                    href={`https://mail.google.com/mail/?view=cm&fs=1&to=${WARMLY_EMAIL.CSM}&su=[Warmly] CSV Integration`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {WARMLY_EMAIL.CSM}
                  </Link>
                  .
                </Box>
              )}
            </Box>
            <Box marginY={2} display={attributeRowsData ? 'none' : 'block'}>
              <DropzoneArea
                filesLimit={1}
                dropzoneText={'Drag and drop a file here or click. Please note that only CSV files are supported.'}
                acceptedFiles={['.csv']}
                maxFileSize={50000000}
                onChange={(files: File[]) => {
                  handleFileOnDrop(files[0]);
                }}
              />
            </Box>

            {errorMessage && (
              <Box marginTop={1}>
                <Alert severity={AlertSeverity.ERROR}>{errorMessage}</Alert>
              </Box>
            )}

            {attributeRowsData && (
              <>
                <AttributesTable
                  attributeRowsData={attributeRowsData}
                  mappedAttributes={mappedAttributes}
                  setMappedAttributes={setMappedAttributes}
                />

                <Box marginTop={1} paddingY={2}>
                  <Grid container justify="flex-end" spacing={2}>
                    {!isConfirmed ? (
                      <>
                        <Grid item>
                          <Button variant="contained" onClick={onCancel} disabled={isLoading}>
                            Cancel
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              onConfirmSubmit();
                            }}
                            disabled={isConfirmed || isLoading}
                          >
                            Confirm
                          </Button>
                        </Grid>
                      </>
                    ) : (
                      <Grid item>
                        <Button variant="contained" onClick={onCancel}>
                          Close
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </Box>
              </>
            )}
          </>
        )}

        {!csvFile && !attributeRowsData && (
          <Box textAlign="right">
            <Button variant="contained" onClick={onCancel}>
              Close
            </Button>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default UploadCsvModal;
