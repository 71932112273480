import { Box, Button, FormControl, Grid, TextField, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import firebase from 'firebase';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import NavBar from '../components/NavBar';
import { firebaseAuth } from '../config/firebase';
import { logError } from '../modules/analytics';
import { AlertSeverity, NavigationPath, WarmlyColor } from '../utils/constants';
import { getAuthErrorMessage, isAuthError } from '../utils/errors';

const ResetPassword = () => {
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [alert, setAlert] = useState<AlertData>();
  const [passwordResetComplete, setPasswordResetComplete] = useState(false);
  const query = new URLSearchParams(useLocation().search);
  const oobCode = query.get('oobCode') || '';
  const history = useHistory();

  const passwordDoesNotMatch = Boolean(password && confirmPassword && password !== confirmPassword);

  useEffect(() => {
    if (oobCode) {
      firebaseAuth
        .verifyPasswordResetCode(oobCode)
        .then((email: string) => {
          setEmail(email);
        })
        .catch((err) => {
          const errorMessage = getAuthErrorMessage(err);
          setAlert({
            severity: AlertSeverity.ERROR,
            message: errorMessage,
          });
        });
    } else {
      history.push(NavigationPath.MAIN);
    }
  }, [history, oobCode]);

  const onSubmitPassword = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    if (!password || !confirmPassword) {
      setAlert({
        severity: AlertSeverity.ERROR,
        message: 'Please input your new password',
      });
      return;
    }

    try {
      setIsLoading(true);

      await firebaseAuth.confirmPasswordReset(oobCode, password);

      setPasswordResetComplete(true);
      setAlert(undefined);
    } catch (err) {
      if (!isAuthError(err)) {
        logError(err, 'Error resetting password');
      }
      const errorMessage = getAuthErrorMessage(err);
      setAlert({
        severity: AlertSeverity.ERROR,
        message: errorMessage,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onClickContinue = () => {
    setIsLoading(true);

    firebaseAuth
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        history.push(NavigationPath.MAIN);
      })
      .catch((err: firebase.auth.Error) => {
        const errorMessage = getAuthErrorMessage(err);
        setAlert({
          severity: AlertSeverity.ERROR,
          message: errorMessage,
        });
        setIsLoading(false);
      });
  };

  return (
    <>
      <NavBar />
      <Grid container justify="center" style={{ backgroundColor: WarmlyColor.WHITE }}>
        <Grid item xs={8} sm={6} md={4}>
          {email && !passwordResetComplete && (
            <FormControl fullWidth>
              <Box marginTop={4}>
                <Typography variant="h4" color="primary">
                  Reset password {email && `for ${email}`}
                </Typography>

                <Box marginTop={3}>
                  <TextField
                    label="New Password*"
                    type="password"
                    value={password}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setPassword(event.target.value);
                    }}
                    fullWidth
                  />
                </Box>

                <Box marginTop={3}>
                  <TextField
                    error={passwordDoesNotMatch}
                    label="Confirm New Password*"
                    type="password"
                    value={confirmPassword}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setConfirmPassword(event.target.value);
                    }}
                    fullWidth
                    helperText={passwordDoesNotMatch ? 'Password does not match' : undefined}
                  />
                </Box>

                <Box marginTop={3}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    onClick={onSubmitPassword}
                    disabled={isLoading || passwordDoesNotMatch}
                  >
                    Submit
                  </Button>
                </Box>
              </Box>
            </FormControl>
          )}
          <Box marginY={3}>{alert && <Alert severity={alert.severity}>{alert.message}</Alert>}</Box>
          {email && passwordResetComplete && (
            <>
              <Box marginY={4}>
                <Box marginBottom={2}>Your password has been reset.</Box>
                <Button variant="contained" color="primary" onClick={onClickContinue} disabled={isLoading}>
                  Continue to Dashboard
                </Button>
              </Box>
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default ResetPassword;
