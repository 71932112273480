import { Backdrop, Box, CircularProgress, Grid, Typography } from '@material-ui/core';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import CustomTablePagination from '../../components/CustomTablePagination';
import { ClientContactExternalFragment, Order_By, useQueryClientContactCountQuery } from '../../graphql';
import { GQLHooks } from '../../graphql/hasura/react';
import { ClientContext } from '../Main';
import EnrichedCrmHeaders from './enrichedCrm/EnrichedCrmHeaders';
import EnrichedCrmItem from './enrichedCrm/EnrichedCrmItem';
import EnrichedCrmOptions from './enrichedCrm/EnrichedCrmOptions';

interface ExpandedClientContactsById {
  [id: string]: boolean;
}

export interface CheckedClientContactsById {
  [id: string]: boolean;
}

interface UpdatedById {
  [id: string]: boolean;
}

export interface ContactUpdatedById {
  linkedInUpdated: UpdatedById;
  emailUpdated: UpdatedById;
  companyNameUpdated: UpdatedById;
  titleUpdated: UpdatedById;
  anyUpdated: UpdatedById;
}

const CONTACTS_PER_PAGE = 10;

// We auto-refresh contacts once a minute, so if a client has uploaded something recently,
// it would show up within one minute
const autoRefreshTimeInMilliSeconds = 60 * 1000;

const EnrichedCrm = () => {
  const { selectedClient } = useContext(ClientContext);
  const [pagingState, setPagingState] = useState({
    cursor: null as string | null,
    isNext: true,
    currentPage: 0,
  });
  const [clientContacts, setClientContacts] = useState<ClientContactExternalFragment[]>([]);

  const {
    objects: clientContactsFromQuery,
    loading: loadingClientContacts,
  } = GQLHooks.Fragments.ClientContactExternal.useQueryObjects({
    variables: {
      where: {
        clientId: {
          _eq: selectedClient.id,
        },
        // Only add cursor if not null and set WHERE based on paging direction
        ...(pagingState.cursor && {
          updatedAt: {
            ...(pagingState.isNext ? { _lt: pagingState.cursor } : { _gt: pagingState.cursor }),
          },
        }),
      },
      limit: CONTACTS_PER_PAGE,
      order_by: [
        {
          updatedAt: pagingState.isNext ? Order_By.DescNullsLast : Order_By.AscNullsFirst,
        },
      ],
    },
    pollInterval: autoRefreshTimeInMilliSeconds,
  });

  const { data, loading: loadingTotalCount } = useQueryClientContactCountQuery({
    variables: {
      where: {
        clientId: {
          _eq: selectedClient.id,
        },
      },
    },
    pollInterval: autoRefreshTimeInMilliSeconds,
  });

  useEffect(() => {
    // We use this effect because Apollo resets the result array to empty array
    // between each query, and we don't want to show a "flash of empty page" whenever
    // the user changes page number
    if (!loadingClientContacts && clientContactsFromQuery) {
      let contacts: ClientContactExternalFragment[];
      if (pagingState.isNext) {
        contacts = clientContactsFromQuery;
      } else {
        // Contacts come back in opposite order when previous paging
        contacts = JSON.parse(JSON.stringify(clientContactsFromQuery));
        contacts.reverse();
      }
      setClientContacts(contacts);
    }
  }, [loadingClientContacts, clientContactsFromQuery, pagingState]);

  const totalClientContactCount = data?.clientContact_aggregate.aggregate?.totalCount;

  const [expandedClientContactsById, setExpandedClientContactsById] = useState<ExpandedClientContactsById>({});

  const setAllRowsExpanded = useCallback(
    (expanded: boolean) => {
      const updatedExpanded = (clientContacts || []).reduce((acc: ExpandedClientContactsById, clientContact) => {
        acc[clientContact.id] = expanded;
        return acc;
      }, {});

      setExpandedClientContactsById(updatedExpanded);
    },
    [clientContacts]
  );

  const onChangePage = (page: number) => {
    const isNext = pagingState.currentPage < page;
    const cursor = clientContacts?.length ? clientContacts[isNext ? clientContacts.length - 1 : 0].updatedAt : null;
    setPagingState({
      cursor,
      isNext,
      currentPage: page,
    });
  };

  return (
    <>
      <Grid container direction="column" justify="flex-start" alignItems="flex-start" spacing={3}>
        <Grid container item>
          <Typography variant="h1" color="primary">
            Your Enriched CRM
          </Typography>
        </Grid>

        <Grid container item direction="column" alignItems="center">
          {Boolean(clientContacts?.length) ? (
            <>
              <EnrichedCrmOptions setAllRowsExpanded={setAllRowsExpanded} />
              <EnrichedCrmHeaders totalClientContactCount={totalClientContactCount ?? undefined} />
              {clientContacts.map((clientContact) => {
                const expanded = expandedClientContactsById[clientContact.id];

                const toggleExpanded = () => {
                  setExpandedClientContactsById({
                    ...expandedClientContactsById,
                    [clientContact.id]: !expanded,
                  });
                };

                return (
                  <EnrichedCrmItem
                    key={clientContact.id}
                    clientContact={clientContact}
                    expanded={expanded}
                    toggleExpanded={toggleExpanded}
                  />
                );
              })}
              {totalClientContactCount && (
                <Box marginTop={2}>
                  <CustomTablePagination
                    currentPage={pagingState.currentPage}
                    totalCount={totalClientContactCount}
                    itemsPerPage={CONTACTS_PER_PAGE}
                    onChangePage={onChangePage}
                  />
                </Box>
              )}
            </>
          ) : !loadingClientContacts && !loadingTotalCount ? (
            <>
              <Typography variant="h6">
                You have not yet provided us with your contacts. Please use the Upload page to either upload a CSV file
                or sync in contacts from HubSpot or Salesforce.
              </Typography>
              <Box marginTop={2} fontWeight="bold" color="green" fontSize={20}>
                If you have recently uploaded contacts, please note that it may take a few minutes for the data to show
                up.
              </Box>
            </>
          ) : null}
          <Backdrop open={loadingClientContacts} style={{ zIndex: 10000 }}>
            <Grid container direction="column" alignItems="center" justify="center">
              <Grid item xs>
                <CircularProgress color="primary" />
              </Grid>
            </Grid>
          </Backdrop>
        </Grid>
      </Grid>
    </>
  );
};

export default EnrichedCrm;
