import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';
import { Option } from 'react-dropdown';

import { ClientContactAttribute } from '../../../utils/constants';
import { MappedAttributes } from '../../../utils/functions';
import AttributeRow, { RowData } from './AttributeRow';

const columns = [
  { id: 'attributes_csv_header', label: 'Header from CSV', minWidth: 170 },
  { id: 'attribuets_data_preview', label: 'Data Preview', minWidth: 200 },
  {
    id: 'attribute_select_dropdown',
    label: 'Attribute',
    minWidth: 170,
  },
];

interface Props {
  attributeRowsData: RowData[];
  mappedAttributes: MappedAttributes;
  setMappedAttributes: React.Dispatch<React.SetStateAction<MappedAttributes>>;
}

const AttributesTable: React.FC<Props> = ({ attributeRowsData, mappedAttributes, setMappedAttributes }) => {
  return (
    <Paper>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} align="center" style={{ minWidth: column.minWidth }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {attributeRowsData.map((rowData, rowIndex) => {
              const onChangeOption = (option: Option) => {
                setMappedAttributes({
                  ...mappedAttributes,
                  [rowData.headerName]: option.value as ClientContactAttribute,
                });
              };
              return <AttributeRow key={rowIndex} rowData={rowData} onChangeOption={onChangeOption} />;
            })}
          </TableBody>
        </Table>
    </Paper>
  );
};

export default AttributesTable;
