import { Button } from '@material-ui/core';
import React, { useContext } from 'react';

import { LoadingAndAlertContext } from '../../..';
import { findUpdatedEmail } from '../../../modules/admin';
import { logError } from '../../../modules/analytics';
import { AlertSeverity } from '../../../utils/constants';

interface Props {
  companyDomain: string;
  id: string;
  fullName: string;
  setGeneratedEmail: React.Dispatch<React.SetStateAction<string>>;
  setIsEmailLoading: React.Dispatch<React.SetStateAction<boolean>>;
  isEmailLoading: boolean;
  firstName: string;
  lastName: string;
  oldEmail: string;
}

const QAFindNewEmailButton: React.FC<Props> = ({
  fullName,
  firstName,
  lastName,
  oldEmail,
  companyDomain,
  id,
  setGeneratedEmail,
  setIsEmailLoading,
  isEmailLoading,
}) => {
  const { setSnackbarAlertData } = useContext(LoadingAndAlertContext);

  const onClickFindUpdtedEmail = async () => {
    try {
      setIsEmailLoading(true);

      const newEmail = (await findUpdatedEmail(id, firstName, lastName, oldEmail, companyDomain)).data || '';

      if (newEmail === '') {
        setGeneratedEmail('No email found');
      } else {
        setGeneratedEmail(newEmail);
      }

      const alertData: AlertData = {
        severity: AlertSeverity.SUCCESS,
        message: `Looking for ${fullName}'s updated Email`,
      };

      setSnackbarAlertData(alertData);
    } catch (err) {
      logError(err, `Error looking for ${fullName}'s updated Email`);

      const alertData: AlertData = {
        severity: AlertSeverity.ERROR,
        message: `${fullName}'s email was not found or is invalid`,
      };

      setSnackbarAlertData(alertData);
    } finally {
      setIsEmailLoading(false);
      console.log('is loading', isEmailLoading);
    }
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={onClickFindUpdtedEmail}
        disabled={isEmailLoading}
      >
        Find New Email
      </Button>
    </>
  );
};

export default QAFindNewEmailButton;
