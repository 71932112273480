import { faBell, faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  Grid,
  LinearProgress,
  Link,
  MenuItem,
  Paper,
  Select,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RefreshIcon from '@material-ui/icons/Refresh';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import React, { useContext, useState } from 'react';

import { LoadingAndAlertContext } from '../../..';
import LoadingButtonAnimated from '../../../components/LoadingButtonAnimated';
import { HubspotContactProperty, SalesforceContactField } from '../../../graphql';
import { GQLHooks } from '../../../graphql/hasura/react';
import {
  createSyncHubspotPropertyCloudTask,
  createSyncSalesforceFieldCloudTask,
  refreshHubspotContactProperties,
  refreshSalesforceContactFields,
} from '../../../modules/cloudFunctions';
import {
  AlertSeverity,
  HubspotCustomPropertyName,
  IntegrationType,
  SalesforceCustomFieldName,
  SyncStatus,
  WARMLY_EMAIL,
  WarmlyColor,
} from '../../../utils/constants';
import { consoleLogDev } from '../../../utils/errors';
import { capitalizeFirstLetter } from '../../../utils/functions';
import { ClientContext } from '../../Main';
import ResyncClientContactsModal from '../ResyncClientContactsModal';
import SyncHubspotContactListsModal from '../SyncHubspotContactListsModal';
import HelpMenu from './HelpMenu';

interface PermissionItemProps {
  name: string;
  allowed: boolean;
  onClickResync?: () => void;
}
const PermissionItem: React.FC<PermissionItemProps> = ({ name, allowed, onClickResync }) => {
  return (
    <>
      {allowed ? (
        <Grid container item direction="row" spacing={1} alignItems="center">
          <Grid item>
            <FontAwesomeIcon icon={faCheckCircle} color={WarmlyColor.SUCCESS_GREEN} size="lg" />
          </Grid>
          <Grid item>
            <Box fontWeight="fontWeightBold">{capitalizeFirstLetter(name)}</Box>
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          item
          direction="row"
          spacing={1}
          alignItems="center"
          style={{ backgroundColor: WarmlyColor.LIGHT_ORANGE, borderRadius: '10px' }}
        >
          <Grid item>
            <FontAwesomeIcon icon={faBell} color={WarmlyColor.RED} size="lg" />
          </Grid>
          <Grid item>
            <Box marginLeft="2px">
              <Box fontWeight="fontWeightBold">{capitalizeFirstLetter(name)}</Box>
              <Box fontSize="0.85rem">
                {capitalizeFirstLetter(name)} not yet synced. Resync with Salesforce to provide more context on leads
              </Box>
            </Box>
          </Grid>
          <Grid item style={{ marginLeft: 'auto' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={onClickResync}
              style={{ fontWeight: 500, boxShadow: 'none' }}
            >
              Resync
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );
};

const useStyles = makeStyles(() => ({
  select: {
    '& .MuiOutlinedInput-input': {
      padding: '10px',
    },
  },
  cardIcon: {
    backgroundColor: WarmlyColor.SELECTED_BLUE,
    padding: '6px',
    borderRadius: '50%',
  },
  cardContent: {
    margin: '0 8px 0 48px',
  },
}));

const warmlyFields: string[] = [
  ...Object.values(HubspotCustomPropertyName),
  ...Object.values(SalesforceCustomFieldName),
];

const IntegrationComplete: React.FC = () => {
  const { selectedClient } = useContext(ClientContext);
  const { setSnackbarAlertData } = useContext(LoadingAndAlertContext);
  const syncStatus = selectedClient.syncStatus;
  const integration =
    selectedClient.integrationType === IntegrationType.HubSpot
      ? selectedClient.hubspotIntegration
      : selectedClient.salesforceIntegration;
  const [selectedFieldName, setSelectedFieldName] = useState('');
  const [isUpdateClient, setIsUpdatingClient] = useState(false);
  const [showResyncContactsModal, setShowResyncContactsModal] = useState(false);
  const [showSyncHubspotContactListsModal, setShowSyncHubspotContactLists] = useState(false);
  const [crmContactFields, setCrmContactFields] = useState<(HubspotContactProperty | SalesforceContactField)[]>();
  const [updateClient] = GQLHooks.Fragments.ClientExternal.useUpdateById();

  const classes = useStyles();

  const { error: subscribeHubspotPropertiesError } = GQLHooks.Fragments.HubspotContactProperty.useSubscribeToObjects({
    variables: {
      where: {
        clientId: {
          _eq: selectedClient.id,
        },
      },
    },
    skip: selectedClient.integrationType !== IntegrationType.HubSpot,
    shouldResubscribe: true,
    onSubscriptionData: (data) => {
      if (data.subscriptionData.data?.hubspotContactProperty.length) {
        setCrmContactFields(data.subscriptionData.data.hubspotContactProperty);
      }
    },
  });

  const { error: subscribeSalesforceFieldsError } = GQLHooks.Fragments.SalesforceContactField.useSubscribeToObjects({
    variables: {
      where: {
        clientId: {
          _eq: selectedClient.id,
        },
      },
    },
    skip: selectedClient.integrationType !== IntegrationType.Salesforce,
    shouldResubscribe: true,
    onSubscriptionData: (data) => {
      if (data.subscriptionData.data?.salesforceContactField.length) {
        setCrmContactFields(data.subscriptionData.data.salesforceContactField);
      }
    },
  });

  if (subscribeHubspotPropertiesError) {
    consoleLogDev('Sub hubspot error', subscribeHubspotPropertiesError);
  }

  if (subscribeSalesforceFieldsError) {
    consoleLogDev('Sub hubspot error', subscribeSalesforceFieldsError);
  }

  const fieldDisplayText = selectedClient.integrationType === IntegrationType.HubSpot ? 'property' : 'field';
  const fieldDisplayTextPlural = selectedClient.integrationType === IntegrationType.HubSpot ? 'properties' : 'fields';

  let syncStatusToDisplay = '';
  let bodyMessage = '';

  if (syncStatus === SyncStatus.ERROR) {
    syncStatusToDisplay = 'Error';
    bodyMessage =
      `An error occurred while attemping to sync ${selectedClient.integrationType} data with our system. ` +
      'Our tech team has been notified and will attempt to remedy this issue as soon as possible. ' +
      'If you continue to experience this issue or have any questions regarding the status of the error, ' +
      'please contact us at the link below.';
  } else if (syncStatus === SyncStatus.SYNCING) {
    syncStatusToDisplay = 'In progress';
    bodyMessage = `You may leave this page and return later to check the status.`;
  } else if (syncStatus === SyncStatus.SYNCING_FIELD || syncStatus === SyncStatus.SYNCING_PROPERTY) {
    syncStatusToDisplay = 'In progress';
    bodyMessage = `We are currently attempting to sync in additional ${fieldDisplayText} from ${selectedClient.integrationType}. You may leave this page and return later to check the status.`;
  } else if (syncStatus === SyncStatus.ENRICHING_CRM) {
    syncStatusToDisplay = 'In progress';
    bodyMessage = `We are currently enriching your CRM by syncing Warmly's data back to ${selectedClient.integrationType}.
      Please note that only the custom Warmly ${fieldDisplayTextPlural} will be synced, so non-Warmly provided data will not be impacted.
      You may leave this page and return later to check the status.`;
  } else if (syncStatus === SyncStatus.COMPLETE) {
    syncStatusToDisplay = 'Complete';
    bodyMessage = `✅ Your contacts have been successfully synced. \n We are in process of enriching your data and will notify you of any detected job changes.`;
  } else if (!syncStatus) {
    // For clients who integrated with HubSpot before the HubSpot integration refactor, they would not have a syncStatus
    syncStatusToDisplay = 'Complete';
    bodyMessage = `✅ HubSpot contacts successfully synced. We are in process of enriching your data and will notify you of any detected job changes.`;
  }

  const onClickBack = async () => {
    try {
      if (integration?.contactsLastSyncedAt) {
        setIsUpdatingClient(true);
        await updateClient({
          clientId: selectedClient.id,
          set: {
            syncStatus: SyncStatus.COMPLETE,
          },
        });
      } else {
        setIsUpdatingClient(true);
        await updateClient({
          clientId: selectedClient.id,
          set: {
            syncStatus: null,
          },
        });
      }
    } catch (err) {
      const alertData: AlertData = {
        severity: AlertSeverity.ERROR,
        message: 'An error occurred on our server and our team has been notified.',
      };
      setSnackbarAlertData(alertData);
    } finally {
      setIsUpdatingClient(false);
    }
  };

  // Sorted alphabetically, and filter out the Warmly fields
  const sortedFields = crmContactFields
    ?.filter((field) => !warmlyFields.includes(field.name))
    .sort((fieldA, fieldB) => ((fieldA.label || '') > (fieldB.label || '') ? 1 : -1));

  const fieldsAlreadySynced = sortedFields?.filter((field) => field.lastSyncedAt);

  const fieldsAlreadySyncedText = fieldsAlreadySynced
    ?.map((field) => {
      return field.label;
    })
    .join(', ');

  const handleChangeField = (event: React.ChangeEvent<{ value: unknown }>) => {
    const fieldName = event.target.value as string;
    setSelectedFieldName(fieldName);
  };

  const onClickRefreshField = async () => {
    try {
      if (selectedClient.integrationType === IntegrationType.HubSpot) {
        await refreshHubspotContactProperties(integration!.id);
      } else if (selectedClient.integrationType === IntegrationType.Salesforce) {
        await refreshSalesforceContactFields(integration!.id);
      }

      const alertData: AlertData = {
        severity: AlertSeverity.SUCCESS,
        message: `${fieldDisplayText}s refreshed`,
      };
      setSnackbarAlertData(alertData);
    } catch (err) {
      const alertData: AlertData = {
        severity: AlertSeverity.ERROR,
        message: 'An error occurred on our server and our team has been notified.',
      };
      setSnackbarAlertData(alertData);
    }
  };

  const onClickSyncField = async () => {
    try {
      if (!selectedFieldName) {
        const alertData: AlertData = {
          severity: AlertSeverity.ERROR,
          message: `Please select a ${fieldDisplayText}`,
        };
        setSnackbarAlertData(alertData);
      } else {
        setIsUpdatingClient(true);
        if (selectedClient.integrationType === IntegrationType.HubSpot) {
          await createSyncHubspotPropertyCloudTask(selectedClient.id, selectedFieldName);
        } else if (selectedClient.integrationType === IntegrationType.Salesforce) {
          await createSyncSalesforceFieldCloudTask(selectedClient.id, selectedFieldName);
        }
      }
    } catch (err) {
      const alertData: AlertData = {
        severity: AlertSeverity.ERROR,
        message: 'An error occurred on our server and our team has been notified.',
      };
      setSnackbarAlertData(alertData);
    } finally {
      setIsUpdatingClient(false);
    }
  };

  return (
    <>
      {/* Only show Step 3 box when syncing Salesforce (not completed) or 
      when integration type is HubSpot (even if completed) */}
      {syncStatus !== SyncStatus.COMPLETE || selectedClient.integrationType === IntegrationType.HubSpot ? (
        <Box display="flex" justifyContent="center" marginBottom={4}>
          <Grid container item direction="column" xs={9}>
            <Grid item style={{ paddingBottom: 20 }}>
              <Typography component="div" variant="h4">
                <Box fontWeight="bold">Step 3 – Integration</Box>
              </Typography>
            </Grid>
            <Grid item>
              <Paper>
                <Box padding={2}>
                  <Typography>Sync status:</Typography>
                  <Typography component="div" variant="h4" color="primary">
                    <Box fontWeight="fontWeightBold">{syncStatusToDisplay}</Box>
                  </Typography>
                </Box>
                {(syncStatus === SyncStatus.SYNCING ||
                  syncStatus === SyncStatus.SYNCING_PROPERTY ||
                  syncStatus === SyncStatus.SYNCING_FIELD ||
                  syncStatus === SyncStatus.ENRICHING_CRM) && <LinearProgress variant="query" />}
                <Box padding={2}>
                  <Grid container item direction="row" justify="space-between">
                    <Grid item>
                      <Typography>{bodyMessage}</Typography>
                    </Grid>
                    <Grid item>
                      Questions? Email{' '}
                      <Link
                        href={`https://mail.google.com/mail/?view=cm&fs=1&to=${WARMLY_EMAIL.CSM}&su=[Warmly] Question about CRM Integration`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ fontWeight: 'bold' }}
                      >
                        {WARMLY_EMAIL.CSM}
                      </Link>
                    </Grid>
                  </Grid>
                </Box>

                {syncStatus === SyncStatus.ERROR && (
                  <Box padding={2}>
                    <Button variant="outlined" color="primary" onClick={onClickBack} disabled={isUpdateClient}>
                      Back
                    </Button>
                  </Box>
                )}
              </Paper>
            </Grid>
          </Grid>
        </Box>
      ) : null}
      {/* Display the updated Salesforce integration UI for sync status complete */}
      {syncStatus === SyncStatus.COMPLETE && selectedClient.integrationType === IntegrationType.Salesforce && (
        <>
          <Box display="flex" flexDirection="row" alignItems="center" paddingY={4}>
            <FontAwesomeIcon
              icon={faCheckCircle}
              size="2x"
              color={WarmlyColor.SUCCESS_GREEN}
              style={{ marginRight: 8 }}
            />
            <Typography component="div" variant="h4">
              <Box fontWeight="bold">Integration complete</Box>
            </Typography>
          </Box>
          <Grid container direction="row" spacing={1}>
            <Grid container item direction="column" xs={8} spacing={2}>
              <Grid item>
                <Typography component="div" variant="h6">
                  <Box fontWeight="bold">Settings</Box>
                </Typography>
              </Grid>
              <Grid item>
                <Card>
                  <CardHeader
                    avatar={<VpnKeyIcon color="primary" fontSize="large" className={classes.cardIcon} />}
                    title={<Box fontWeight="fontWeightBold">Allow access to contacts and accounts</Box>}
                    subheader="We use both to organize Warmly's leads and provide context"
                  />
                  <CardContent className={classes.cardContent}>
                    <Grid container direction="column" spacing={3}>
                      <PermissionItem name="contacts" allowed />
                      <PermissionItem
                        name="accounts & opportunities"
                        allowed={Boolean(
                          selectedClient.salesforceIntegration?.accountsLastSyncedAt &&
                            selectedClient.salesforceIntegration?.opportunitiesLastSyncedAt
                        )}
                        onClickResync={() => {
                          setShowResyncContactsModal(true);
                        }}
                      />
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item>
                <Card>
                  <CardHeader
                    avatar={<AddIcon color="primary" fontSize="large" className={classes.cardIcon} />}
                    title={<Box fontWeight="fontWeightBold">Sync in additional contact fields</Box>}
                    subheader="Use these additional contact fields to sort through your leads"
                  />
                  <CardContent style={{ marginLeft: 48 }}>
                    <Box marginBottom={3}>
                      <Typography style={{ textDecoration: 'underline' }}>Synced Salesforce fields:</Typography>
                      <Typography component="div">
                        <Box fontSize="0.85rem">{fieldsAlreadySyncedText}</Box>
                      </Typography>
                    </Box>
                    <Grid container item xs={12} alignItems="center">
                      <Grid item xs={4}>
                        <FormControl variant="outlined" style={{ width: '100%' }}>
                          <Select
                            autoWidth
                            value={selectedFieldName}
                            onChange={handleChangeField}
                            className={classes.select}
                            displayEmpty
                          >
                            <MenuItem value="">
                              <em>Select field</em>
                            </MenuItem>
                            {(sortedFields || []).map((field) => {
                              return (
                                <MenuItem key={field.name} value={field.name}>
                                  {field.label}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item container direction="row" xs={8} spacing={1} alignItems="center">
                        <Grid item>
                          <LoadingButtonAnimated
                            initialIcon={<RefreshIcon />}
                            onClick={onClickRefreshField}
                            tooltipText={`If you do not see the ${fieldDisplayText} you are looking for, press this to refresh the list.`}
                          />
                        </Grid>
                        <Grid item style={{ marginLeft: 'auto' }}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={onClickSyncField}
                            disabled={isUpdateClient}
                            style={{ fontWeight: 500, boxShadow: 'none' }}
                          >
                            Confirm
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item>
                <Card>
                  <CardHeader
                    avatar={<SystemUpdateAltIcon color="primary" fontSize="large" className={classes.cardIcon} />}
                    title={<Box fontWeight="fontWeightBold">Resync Salesforce</Box>}
                    subheader="to update records that have been added to your CRM since your last sync"
                  />
                  <CardContent style={{ marginLeft: 48 }}>
                    <Grid container direction="row" alignItems="center">
                      <Grid item>
                        <Typography component="div">
                          <Box fontSize="0.85rem">
                            This will not impact your existing job change notifications and enriched data in Warmly.
                          </Box>
                        </Typography>
                      </Grid>
                      <Grid item style={{ marginLeft: 'auto' }}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            setShowResyncContactsModal(true);
                          }}
                          style={{ fontWeight: 500, boxShadow: 'none' }}
                        >
                          Resync
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <HelpMenu />
            </Grid>
          </Grid>
        </>
      )}
      {/* Keep same accordion UI below for HubSpot integration sync status complete */}
      {syncStatus === SyncStatus.COMPLETE && selectedClient.integrationType === IntegrationType.HubSpot && (
        <>
          <Grid item xs={11}>
            <Paper>
              <Box padding={2}>
                <Typography>
                  You have synced the following {selectedClient.integrationType} {fieldDisplayTextPlural} into Warmly:{' '}
                  <strong>{fieldsAlreadySyncedText}</strong>
                </Typography>
              </Box>
            </Paper>
          </Grid>

          <Grid item xs={11}>
            <Paper>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography style={{ fontWeight: 'bold' }}>
                    ➕ Optional: Sync in Additional {capitalizeFirstLetter(fieldDisplayText)}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography>
                        If you have discussed with our team of using a custom {fieldDisplayText} to filter your
                        contacts, you may also sync it in here:
                      </Typography>
                    </Grid>
                    <Grid container item xs={12} alignItems="center">
                      <Grid item xs={4}>
                        <FormControl style={{ width: '100%' }}>
                          <Select autoWidth value={selectedFieldName} onChange={handleChangeField}>
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            {(sortedFields || []).map((field) => {
                              return (
                                <MenuItem key={field.name} value={field.name}>
                                  {field.label}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item container xs={8} spacing={1} alignItems="center">
                        <Grid item>
                          <LoadingButtonAnimated
                            initialIcon={<RefreshIcon />}
                            onClick={onClickRefreshField}
                            tooltipText={`If you do not see the ${fieldDisplayText} you are looking for, press this to refresh the list.`}
                          />
                        </Grid>
                        <Grid item>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={onClickSyncField}
                            disabled={isUpdateClient}
                          >
                            Confirm
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Paper>
          </Grid>

          {(selectedClient.hubspotIntegration?.mapping as IntegrationMapping)?.selectedListIds?.length ? (
            <>
              <Grid item xs={11}>
                <Paper>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography style={{ fontWeight: 'bold' }}>
                        ➕ Optional: Sync in Additional Contact Lists
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={2}>
                        <Grid item xs={8}>
                          <Typography>
                            If you have would like to sync in additional contact lists, you may do so by clicking the
                            SYNC LISTS button. You will receive additional information and get a chance to confirm
                            before the syncing starts.
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Box marginX={2}>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                setShowSyncHubspotContactLists(true);
                              }}
                            >
                              Sync Lists
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Paper>
              </Grid>
              <SyncHubspotContactListsModal
                isOpen={showSyncHubspotContactListsModal}
                setIsOpen={setShowSyncHubspotContactLists}
                client={selectedClient}
              />
            </>
          ) : null}

          <Grid item xs={11}>
            <Paper>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography style={{ fontWeight: 'bold' }}>🔄 Need to Resync Your Contacts?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    <Grid item xs={8}>
                      <Typography>
                        If you have would like to sync in contacts that were added after your last sync with Warmly, or
                        to resync your data for any other reason, you may do so by clicking the RESYNC button. You will
                        receive additional information and get a chance to confirm before the resync starts.
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Box marginX={2}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            setShowResyncContactsModal(true);
                          }}
                        >
                          Resync
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Paper>
          </Grid>
        </>
      )}
      <ResyncClientContactsModal
        isOpen={showResyncContactsModal}
        setIsOpen={setShowResyncContactsModal}
        client={selectedClient}
      />
    </>
  );
};

export default IntegrationComplete;
