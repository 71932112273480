import { Box } from '@material-ui/core';
import React from 'react';

const HighlightText: React.FC = ({ children, ...props }) => {
  return (
    <Box bgcolor="secondary.main" color="white" borderRadius={4} padding={1} fontWeight="bold" display="inline" {...props}>
      {children}
    </Box>
  );
};

export default HighlightText;
