import { Box, Grid, Link, Typography } from '@material-ui/core';
import React from 'react';

import NavBar from '../components/NavBar';
import { WARMLY_EMAIL, WarmlyColor } from '../utils/constants';

const DeactivatedAccount: React.FC = () => {
  return (
    <>
      <NavBar />
      <Grid
        container
        direction="column"
        alignItems="center"
        justify="center"
        style={{ backgroundColor: WarmlyColor.WHITE }}
        spacing={3}
      >
        <Grid item>
          <Typography variant="h1">Account Deactivated</Typography>
        </Grid>
        <Grid item>
          <Typography variant="h6">
            This account has been deactivated. All data in relation to the deactivated account (including any data
            imported from external sources) are permanently deleted.
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Box textAlign="center">
            <Typography variant="h5">If you believe this to be an error, please contact us:</Typography>

            <Typography variant="h5">
              <Link
                target="_blank"
                rel="noopener noreferrer"
                href={`https://mail.google.com/mail/?view=cm&fs=1&to=${WARMLY_EMAIL.SUPPORT_GENERAL}&su=[Warmly] Question`}
              >
                {WARMLY_EMAIL.SUPPORT_GENERAL}
              </Link>
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default DeactivatedAccount;
