import { Box, CircularProgress, Grid } from '@material-ui/core';
import React from 'react';

import HighlightText from '../../../components/HighlightText';
import { useQueryClientsClientContactCountQuery } from '../../../graphql';
import { GQLHooks } from '../../../graphql/hasura/react';
import ClientsManagementTable from './allClientsManagement/ClientsManagementTable';

const AllClientsManagement: React.FC = () => {
  const { objects: clients = [], loading: loadingClients } = GQLHooks.Fragments.ClientInternal.useQueryObjects({
    variables: {
      where: {},
    },
  });

  const {
    data: clientsClientContactCountQuery,
    loading: loadingClientsClientContactsCount,
  } = useQueryClientsClientContactCountQuery({
    variables: {
      whereClientContact: {
        qaAssignedAt: {
          _is_null: false,
        }
      },
    },
  });

  const clientsClientContactCount = clientsClientContactCountQuery?.client || [];

  return (
    <>
      <Grid container item direction="row" justify="space-around" alignItems="center">
        <Grid container item direction="row" justify="flex-start" alignItems="center">
          <HighlightText>{clients?.length}</HighlightText>
          <Box marginX={1}>total clients</Box>
        </Grid>
      </Grid>

      <Grid container item>
        <Grid item xs={12}>
          {!loadingClients && !loadingClientsClientContactsCount ? (
            <ClientsManagementTable clients={clients} clientsClientContactCount={clientsClientContactCount} />
          ) : (
            <CircularProgress />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default AllClientsManagement;
