export * from './constantsAll';

export enum Environment {
  Development = 'development',
  Staging = 'staging',
  Production = 'production',
}

export const CURRENT_ENVIRONMENT: Environment = process.env.REACT_APP_WARMLY_ENVIRONMENT as Environment;
export const REACT_APP_WARMLY_LOCAL_ENVIRONMENT: Environment = process.env
  .REACT_APP_WARMLY_LOCAL_ENVIRONMENT as Environment;

export const AMPLITUDE_API_KEY = process.env.REACT_APP_AMPLITUDE_API_KEY || '';

export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '';

export const GOOGLE_RECAPTCHA_SITE_KEY = process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY || '';

// In local dev, all of our services are proxied through nginx
// NOTE the base url is shared between web-dashboard and zoom-app
// but we should never have both dashboards running at the same time
// see the docker-compose and nginx files for details
export const NGINX_LOCALHOST_DOMAIN = 'localhost:5000';
export const NGINX_LOCALHOST_URL = 'http://' + NGINX_LOCALHOST_DOMAIN;

const hasuraUriByEnvironment: Record<Environment, string> = {
  [Environment.Production]: 'https://hasura.getwarmly.com/v1/graphql',
  [Environment.Staging]: 'https://hasura-dot-warmly-staging.uc.r.appspot.com/v1/graphql',
  [Environment.Development]: `${NGINX_LOCALHOST_URL}/hasura/v1/graphql`,
};

const hasuraWsByEnvironment: Record<Environment, string> = {
  [Environment.Production]: 'wss://hasura.getwarmly.com/v1/graphql',
  [Environment.Staging]: 'wss://hasura-dot-warmly-staging.uc.r.appspot.com/v1/graphql',
  [Environment.Development]: `ws://${NGINX_LOCALHOST_DOMAIN}/hasura/v1/graphql`,
};

const apiBaseUrlByEnvironment: Record<Environment, string> = {
  [Environment.Production]: 'https://api.getwarmly.com',
  [Environment.Staging]: 'https://warmly-staging.uc.r.appspot.com',
  [Environment.Development]: `${NGINX_LOCALHOST_URL}/api`,
};

const API_BASE_URL = apiBaseUrlByEnvironment[CURRENT_ENVIRONMENT];
export const HASURA_URI = hasuraUriByEnvironment[CURRENT_ENVIRONMENT];
export const HASURA_WS = hasuraWsByEnvironment[CURRENT_ENVIRONMENT];

export enum WarmlyColor {
  DARK_BLUE = '#293767',
  WARMLY_PURPLE = '#4b5ea3',
  SALMON = '#ff8769',
  WHITE = '#ffffff',
  BLACK = '#000000',
  LIGHT_BLUE = '#f4f8f9',
  GRAY = '#808080',
  LIGHT_GRAY = '#f5f5f5',
  DARK_GRAY = '#414141',
  DISABLED_GRAY = '#bdbdbd',
  LINKEDIN_BLUE = '#2867b2',
  BRIGHT_BLUE = '#5096f1',
  SELECTED_BLUE = '#e2edfb',
  SUCCESS_GREEN = '#2ab930',
  WARNING_YELLOW = '#ec9525',
  RED = '#da100b',
  LIGHT_ORANGE = '#fbe5c9',
}

export enum ChipColor {
  PURPLE = '#4B5EA3',
  LIGHT_GRAY = '#AEB4A9',
  MAROON = '#89043D',
  BROWN = '#6D4C3D',
}

export enum QAButtonType {
  CORRECT = 'correct',
  INCORRECT = 'incorrect',
  NEXT = 'next',
  ESCALATE = 'escalate',
}

export enum AmplitudeEventType {
  LOGIN = 'login',
  APPROVE_JOB_CHANGE = 'approve_job_change',
  DISMISS_JOB_CHANGE = 'dismiss_job_change',
  CLICK_LINKEDIN_PROFILE_ICON = 'click_linkedin_profile_icon',
  CLICK_EMAIL_ICON = 'click_email_icon',
  CLICK_EMAIL_TEXT = 'click_email_text',
}

export enum NavigationPath {
  MAIN = '/',
  LOGIN = '/login',
  UNDER_MAINTENANCE = '/maintenance',
  SIGN_UP = '/sign-up',
  ONBOARD = '/onboard',
  RESET_PASSWORD = '/reset-password',
  JOB_CHANGES = '/job-changes',
  ENRICHED_CRM = '/enriched-crm',
  LOGOUT = '/logout',
  ADMIN = '/admin',
  UPLOAD = '/upload',
  CONTACT_US = '/contact',
  SETTINGS = '/settings',
  USERS = '/users',
  INTEGRATION = '/integration',
  INTEGRATION_SALESFORCE = '/integration/salesforce',
  INTEGRATION_HUBSPOT = '/integration/hubspot',
  WARM_COMPANIES = '/warm-companies',
  ADMIN_CLIENT_MANAGEMENT = '/admin/client-management',
  ADMIN_ALL_CLIENTS_MANAGEMENT = '/admin/all-clients-management',
  ADMIN_QA_DATA_REVIEW = '/admin/qa-review',
  ADMIN_UPLOAD = '/admin/upload',
  ADMIN_QA_COLLECTOR_VIEW = '/admin/qa-collector-view',
  ADMIN_QA_APPROVER_VIEW = '/admin/qa-approver-view',
  ADMIN_QA_MANAGEMENT = '/admin/qa-management',
  ADMIN_CLOUD_TASKS = '/admin/cloud-tasks',
  ADMIN_USERS_MANAGEMENT = '/admin/users-management',
  GENERIC_ERROR = '/error',
}

export enum WARMLY_EMAIL {
  CEO = 'max@warmly.ai',
  CTO = 'carina@warmly.ai',
  SUPPORT_GENERAL = 'support@warmly.ai',
  CSM = 'hello@warmly.ai',
}

export enum csvExportType {
  QA = 'QA',
  CLIENT = 'CLIENT',
}

export enum QAJobChangeStatus {
  NO_JOB_CHANGE = 'No job change',
  LEFT_OLD_JOB_NO_NEW_JOB = 'Left old job but no new job',
  JOB_CHANGED = 'Job changed',
  JOB_CHANGED_SAME_COMPANY = 'Job changed but same company',
}

export enum ClientContactAttribute {
  FULL_NAME = 'FULL_NAME',
  FIRST_NAME = 'FIRST_NAME',
  LAST_NAME = 'LAST_NAME',
  CURRENT_COMPANY = 'CURRENT_COMPANY',
  CURRENT_TITLE = 'CURRENT_TITLE',
  LINKEDIN_URL = 'LINKEDIN_URL',
  LINKEDIN_ID = 'LINKEDIN_ID',
  EMAIL = 'EMAIL',
  HUBSPOT_ID = 'HUBSPOT_ID',
  SALESFORCE_ID = 'SALESFORCE_ID',
  OTHER = 'OTHER',
}

export enum QATaskType {
  ENRICH_CONTACT = 'ENRICH_CONTACT',
  APPROVE_ENRICH_CONTACT = 'APPROVE_ENRICH_CONTACT',
}

export enum QATaskActionType {
  ESCALATE = 'ESCALATE',
  RESOLVE = 'RESOLVE',
}

enum ApiEndPoint {
  PARSE_CLIENT_CSV = '/parseClientCsv',
  DISMISS_CRM_JOB_CHANGES = '/client-contact/dismiss-client-contact-job-change',
  INVITE_NEW_USER = '/user/invite-new-user',
  RESET_PASSWORD = '/user/reset-password',
  SALESFORCE_AUTH = '/sf/auth',
  SALESFORCE_SYNC_CONTACTS = '/sf/sync-contacts',
  SALESFORCE_APPROVE_CONTACT = '/sf/approve-contact',
  SALESFORCE_CREATE_ENRICH_CRM_TASK = '/sf/create-enrich-crm-task',
  SALESFORCE_CREATE_SYNC_FIELD_TASK = '/sf/create-sync-field-task',
  SALESFORCE_REFRESH_CONTACT_FIELDS = '/sf/refresh-contact-fields',
  HUBSPOT_AUTH = '/hubspot/auth',
  HUBSPOT_SYNC_CONTACTS = '/hubspot/sync-contacts',
  HUBSPOT_APPROVE_CONTACT = '/hubspot/approve-contact',
  HUBSPOT_CREATE_ENRICH_CRM_TASK = '/hubspot/create-enrich-crm-task',
  HUBSPOT_CREATE_SYNC_PROPERTY_TASK = '/hubspot/create-sync-property-task',
  HUBSPOT_REFRESH_CONTACT_LISTS = '/hubspot/refresh-contact-lists',
  HUBSPOT_REFRESH_CONTACT_PROPERTIES = '/hubspot/refresh-contact-properties',
  ADMIN_GENERATE_JOB_CHANGES = '/admin/generate-job-changes',
  ADMIN_CREATE_NEW_USER = '/admin/create-new-user',
  ADMIN_UPDATE_USER_ROLE = `/admin/update-user-role`,
  ADMIN_SEND_QA_CSV_EXPORT = '/admin/export-qa-csv',
  ADMIN_DELETE_CLIENT_CONTACTS = '/admin/delete-client-contacts',
  ADMIN_RESYNC_CLIENT_CONTACTS = '/admin/resync-client-contacts',
  ADMIN_CREATE_PROXYCRAWL_TASK = '/admin/create-proxycrawl-task',
  ADMIN_RECREATE_TASK = '/admin/recreate-task',
  ADMIN_CREATE_DEDUP_CLIENT_CONTACTS_TASK = '/admin/create-dedup-client-contacts-task',
  ADMIN_SEND_WEEKLY_JOB_CHANGE_REPORT = `/admin/send-weekly-report`,
  ADMIN_DELETE_CLIENT = `/admin/delete-client`,
  SCRAPE_CONTACTS = '/jobChange/send-contacts-to-proxycrawl',
  QA_CONFIRM_TASK = '/qaTask/qa-handle-task-action',
  QA_GET_NEXT_TASK = '/qaTask/qa-get-next-task',
  QA_CREATE_TASKS = '/qaTask/qa-create-tasks',
  QA_FIND_UPDATED_EMAIL = '/qaTask/qa-find-updated-email',
  CREATE_CONTACT_DATA_ID = `/jobChange/create-contact-data`,
  EMAIL_VALIDATION_CHECK = `/emailPing/the-checker`,
  CREATE_UPLOAD_CSV_TASK = '/csv/create-upload-csv-task',
  EXPORT_CONTACTS_CSV = '/csv/export-contacts-csv',
  CREATE_SCHEDULED_EMAIL_TASK = '/emails/create-scheduled-email-task',
  USER_SIGN_UP = '/user/sign-up',
  USER_MAP_TERRITORY = '/user/map-territory',
  USER_VERIFY_RECAPTCHA = '/user/verify-recaptcha',
  COMPANY_GET_ORG_CHART = '/company/the-org',
  CLIENT_ASSIGN_OWNERS = '/client/assign-owners',
}

// GAE = google app engine, this is to differentiate from our old API which used Cloud Functions
export const gaeApiURL = {
  admin: {
    generateJobChanges: API_BASE_URL + ApiEndPoint.ADMIN_GENERATE_JOB_CHANGES,
    createNewUser: API_BASE_URL + ApiEndPoint.ADMIN_CREATE_NEW_USER,
    updateUserRole: API_BASE_URL + ApiEndPoint.ADMIN_UPDATE_USER_ROLE,
    sendQACSVExport: API_BASE_URL + ApiEndPoint.ADMIN_SEND_QA_CSV_EXPORT,
    deleteClientContacts: API_BASE_URL + ApiEndPoint.ADMIN_DELETE_CLIENT_CONTACTS,
    resyncClientContacts: API_BASE_URL + ApiEndPoint.ADMIN_RESYNC_CLIENT_CONTACTS,
    createProxyCrawlTask: API_BASE_URL + ApiEndPoint.ADMIN_CREATE_PROXYCRAWL_TASK,
    recreateTask: API_BASE_URL + ApiEndPoint.ADMIN_RECREATE_TASK,
    createDedupClientContactsTask: API_BASE_URL + ApiEndPoint.ADMIN_CREATE_DEDUP_CLIENT_CONTACTS_TASK,
    sendWeeklyJobChangeReport: API_BASE_URL + ApiEndPoint.ADMIN_SEND_WEEKLY_JOB_CHANGE_REPORT,
    deleteClient: API_BASE_URL + ApiEndPoint.ADMIN_DELETE_CLIENT,
  },
  hubspot: {
    auth: API_BASE_URL + ApiEndPoint.HUBSPOT_AUTH,
    syncContacts: API_BASE_URL + ApiEndPoint.HUBSPOT_SYNC_CONTACTS,
    approveContact: API_BASE_URL + ApiEndPoint.HUBSPOT_APPROVE_CONTACT,
    createEnrichCrmTask: API_BASE_URL + ApiEndPoint.HUBSPOT_CREATE_ENRICH_CRM_TASK,
    createSyncPropertyTask: API_BASE_URL + ApiEndPoint.HUBSPOT_CREATE_SYNC_PROPERTY_TASK,
    refreshContactLists: API_BASE_URL + ApiEndPoint.HUBSPOT_REFRESH_CONTACT_LISTS,
    refreshContactProperties: API_BASE_URL + ApiEndPoint.HUBSPOT_REFRESH_CONTACT_PROPERTIES,
  },
  salesforce: {
    auth: API_BASE_URL + ApiEndPoint.SALESFORCE_AUTH,
    syncContacts: API_BASE_URL + ApiEndPoint.SALESFORCE_SYNC_CONTACTS,
    approveContact: API_BASE_URL + ApiEndPoint.SALESFORCE_APPROVE_CONTACT,
    createEnrichCrmTask: API_BASE_URL + ApiEndPoint.SALESFORCE_CREATE_ENRICH_CRM_TASK,
    createSyncFieldTask: API_BASE_URL + ApiEndPoint.SALESFORCE_CREATE_SYNC_FIELD_TASK,
    refreshContactFields: API_BASE_URL + ApiEndPoint.SALESFORCE_REFRESH_CONTACT_FIELDS,
  },
  user: {
    inviteNewUser: API_BASE_URL + ApiEndPoint.INVITE_NEW_USER,
    resetPassword: API_BASE_URL + ApiEndPoint.RESET_PASSWORD,
    signUp: API_BASE_URL + ApiEndPoint.USER_SIGN_UP,
    mapTerritory: API_BASE_URL + ApiEndPoint.USER_MAP_TERRITORY,
    verifyRecaptcha: API_BASE_URL + ApiEndPoint.USER_VERIFY_RECAPTCHA,
  },
  csv: {
    createUploadCsvTask: API_BASE_URL + ApiEndPoint.CREATE_UPLOAD_CSV_TASK,
    exportContactsCsv: API_BASE_URL + ApiEndPoint.EXPORT_CONTACTS_CSV,
  },
  qaTask: {
    confirmQATask: API_BASE_URL + ApiEndPoint.QA_CONFIRM_TASK,
    getQATask: API_BASE_URL + ApiEndPoint.QA_GET_NEXT_TASK,
    createQATasks: API_BASE_URL + ApiEndPoint.QA_CREATE_TASKS,
    findUpdatedEmail: API_BASE_URL + ApiEndPoint.QA_FIND_UPDATED_EMAIL,
  },
  emails: {
    createScheduledEmailTask: API_BASE_URL + ApiEndPoint.CREATE_SCHEDULED_EMAIL_TASK,
  },
  company: {
    getTheOrg: API_BASE_URL + ApiEndPoint.COMPANY_GET_ORG_CHART,
  },
  client: {
    assignOwners: API_BASE_URL + ApiEndPoint.CLIENT_ASSIGN_OWNERS,
  },
};

type ClientContactMetaData = {
  displayText: string;
  potentialCsvHeaders: string[];
};

// Field is the actual name of the field in Firebase
export const ClientContactAttributeMetaData: {
  [attribute in ClientContactAttribute]: ClientContactMetaData;
} = {
  [ClientContactAttribute.FULL_NAME]: {
    displayText: 'Full Name',
    potentialCsvHeaders: ['full name', 'fullname', 'name', 'full_name'],
  },
  [ClientContactAttribute.FIRST_NAME]: {
    displayText: 'First Name',
    potentialCsvHeaders: ['firstname', 'first name', 'first_name'],
  },
  [ClientContactAttribute.LAST_NAME]: {
    displayText: 'Last Name',
    potentialCsvHeaders: ['lastname', 'last name', 'surname', 'last_name'],
  },
  [ClientContactAttribute.CURRENT_COMPANY]: {
    displayText: 'Company',
    potentialCsvHeaders: ['employer', 'company', 'company_name'],
  },
  [ClientContactAttribute.CURRENT_TITLE]: {
    displayText: 'Title',
    potentialCsvHeaders: ['title', 'position', 'job title'],
  },
  [ClientContactAttribute.LINKEDIN_URL]: {
    displayText: 'LinkedIn URL',
    potentialCsvHeaders: ['linkedin', 'linkedin url'],
  },
  [ClientContactAttribute.LINKEDIN_ID]: {
    displayText: 'LinkedIn ID',
    potentialCsvHeaders: ['linkedinid', 'linkedin id', 'linkedin_id'],
  },
  [ClientContactAttribute.EMAIL]: {
    displayText: 'Email',
    potentialCsvHeaders: ['email', 'e-mail'],
  },
  [ClientContactAttribute.SALESFORCE_ID]: {
    displayText: 'Salesforce ID',
    potentialCsvHeaders: ['salesforceid', 'salesforce', 'salesforce_id'],
  },
  [ClientContactAttribute.HUBSPOT_ID]: {
    displayText: 'HubSpot ID',
    potentialCsvHeaders: ['hubspotid', 'hubspot', 'hubspot_id'],
  },
  [ClientContactAttribute.OTHER]: {
    displayText: 'Other',
    potentialCsvHeaders: [],
  },
};

export const JobChangeStatusMap: Record<QAJobChangeStatus, string> = {
  [QAJobChangeStatus.LEFT_OLD_JOB_NO_NEW_JOB]: 'In between jobs',
  [QAJobChangeStatus.JOB_CHANGED]: 'New company',
  [QAJobChangeStatus.JOB_CHANGED_SAME_COMPANY]: 'New job title',
  [QAJobChangeStatus.NO_JOB_CHANGE]: 'No job change',
};

export const JobChangeStatusIconMap = {
  [QAJobChangeStatus.LEFT_OLD_JOB_NO_NEW_JOB]: '💼',
  [QAJobChangeStatus.JOB_CHANGED]: '🏢',
  [QAJobChangeStatus.JOB_CHANGED_SAME_COMPANY]: '🙌',
};

export enum StorageFolder {
  CLIENT_UPLOAD = 'client_upload',
}

export enum HTTP_RESPONSE {
  OK = 200,
  NO_CONTENT = 204,
  REDIRECT = 303,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  CONFLICT = 409,
  INTERNAL_SERVER_ERROR = 500,
}

export enum AlertSeverity {
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
  SUCCESS = 'success',
}

export enum SalesforceURL {
  EDITIONS_WITH_API_ACCESS = 'https://help.salesforce.com/articleView?type=1&mode=1&id=000326486',
  ADMIN_USER_DESCRIPTION = 'https://help.salesforce.com/articleView?id=basics_understanding_administrator.htm&type=5',
  ADD_ADMIN_USER = 'https://help.salesforce.com/articleView?id=000327017&type=1&mode=1',
  ADD_CUSTOM_FIELD = 'https://help.salesforce.com/articleView?id=adding_fields.htm&type=0',
}

export enum HubspotURL {
  USER_PERMISSIONS_GUIDE = 'https://knowledge.hubspot.com/settings/hubspot-user-permissions-guide',
  CUSTOM_PROPERTIES = 'https://knowledge.hubspot.com/crm-setup/manage-your-properties#create-custom-properties',
}

export enum SyncStatus {
  SYNCING = 'SYNCING',
  COMPLETE = 'COMPLETE',
  ERROR = 'ERROR',
  SYNCING_PROPERTY = 'SYNCING_PROPERTY',
  SYNCING_FIELD = 'SYNCING_FIELD',
  ENRICHING_CRM = 'ENRICHING_CRM',
}

// We attempt to use recommended default events as much as possible
// https://support.google.com/analytics/answer/9267735?hl=en&authuser=1&ref_topic=9267641
export enum AnalyticsEvent {
  LOGIN = 'login',
  SIGN_UP = 'sign_up',
}

export enum AnalyticsParameter {
  FIRST_NAME = 'first_name',
  LAST_NAME = 'last_name',
  FULL_NAME = 'full_name',
  COMPANY_NAME_FULL_NAME = 'companyName_fullName',
  EMAIL = 'email',
  CLIENT_NAME = 'client_name',
  CLIENT_ID = 'client_id',
  USER_TYPE = 'user_type',
  PAYMENT_STATUS = 'payment_status',
  STATUS = 'status',
}

// To increase security, we do not use semantic words for the actual keys, since the local storage items are easily accessible
// by the users
export enum LocalStorageItem {
  LAST_SELECTED_CLIENT_ID = 'warmly_lsci',
}

export const SENTRY_DSN = 'https://55efe3649813490ba0f2ca790ca0c8fb@o406756.ingest.sentry.io/5280634';

export const taskQueue = {
  syncCrmContacts: {
    name: 'sync-crm-contacts',
    endpoint: '/handleTasks/sync-crm-contacts',
  },
  sendProxyCrawlRequests: {
    name: 'send-proxycrawl-requests',
    endpoint: '/handleTasks/send-proxycrawl-requests',
  },
  clientContactActions: {
    name: 'client-contact-actions',
    endpoint: '/handleTasks/client-contact-actions',
  },
  scheduledEmail: {
    name: 'scheduled-email',
    endpoint: '/handleTasks/scheduled-email',
  },
  scheduledQuickActions: {
    name: 'scheduled-quick-actions',
    endpoint: '/handleTasks/scheduled-quick-actions',
  },
};

export const warmlyCustomErrorPrefix = 'warmly_custom_error';
