import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Link, List, ListItem, Typography } from '@material-ui/core';
import React, { useContext } from 'react';

import { IntegrationType, WARMLY_EMAIL, WarmlyColor } from '../../../utils/constants';
import { ClientContext } from '../../Main';

interface HelpCenterListItemProps {
  url: string,
  text: string,
}

const HelpCenterListItem: React.FC<HelpCenterListItemProps> = ({ url, text }) => {
  return (
    <ListItem 
      component={Link}
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      disableGutters
    >
    <Box color={WarmlyColor.DARK_GRAY}>
      {text}{' '}
      <FontAwesomeIcon icon={faExternalLinkAlt} size="xs" color={WarmlyColor.GRAY} />
    </Box>
  </ListItem>
  );
}

const HelpMenu: React.FC = () => {
  const { selectedClient } = useContext(ClientContext);

  return (
    <Box marginLeft={2} marginTop={1}>
      <Typography component="div" variant="h6">
        <Box fontWeight="bold">Help</Box>
      </Typography>
      <List>
        { selectedClient.integrationType === IntegrationType.Salesforce && (
          <>
            <HelpCenterListItem 
              url="https://www.notion.so/warmlycomma/Salesforce-Integration-db37879b2bef48c38d57ad06ab78f74a#c4fc78af14ab411db7808b6fbd29705b"
              text="What fields does Warmly pull in from my Salesforce?"
            />
            <HelpCenterListItem
              url="https://www.notion.so/warmlycomma/View-sync-data-back-to-Salesforce-02e9d596a81a4e349c99c77c28ddef66#568e308da7124befa27add184fe6e212"
              text="What fields does Warmly write back to my Salesforce?"
            />
            <HelpCenterListItem
              url="#"
              text="Warmly's Salesforce Workflow Playbook"
            />
          </>
        )}
        { selectedClient.integrationType === IntegrationType.HubSpot && (
          <>
            <HelpCenterListItem 
              url="https://www.notion.so/warmlycomma/Integrate-with-HubSpot-CRM-5199d80de6eb46f7a8cbb50fb0481074"
              text="HubSpot Integration Guide"
            />
          </>
        )}
        <ListItem disableGutters>
          <Box color={WarmlyColor.DARK_GRAY}>
            More questions? Email us at{' '}
            <Link
              href={`https://mail.google.com/mail/?view=cm&fs=1&to=${WARMLY_EMAIL.CSM}&su=[Warmly] Salesforce Integration`}
              target="_blank"
              rel="noopener noreferrer"
              style={{ fontWeight: 'bold' }}
            >
              {WARMLY_EMAIL.CSM}
            </Link>
          </Box>
        </ListItem>
      </List>
    </Box>
  );
};

export default HelpMenu;
