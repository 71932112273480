import {
  ApolloError,
  FetchResult,
  LazyQueryHookOptions,
  LazyQueryResult,
  MutationFunctionOptions,
  MutationHookOptions,
  MutationTuple,
  QueryHookOptions,
  QueryLazyOptions,
  SubscribeToMoreOptions,
  SubscriptionHookOptions,
  SubscriptionResult,
  useLazyQuery,
  useMutation,
  useQuery,
  useSubscription,
} from '@apollo/client';
import {
  generateOptimisticResponseForMutation,
  generateUpdateFunctionForMutation,
  ObjectWithId,
  stripInsertInputClientFields,
} from 'graphql-codegen-hasura-core';

import { ClientContactFilterFragment } from '../';
import { QueryClientContactFilterByIdQuery } from '../';
import { QueryClientContactFilterByIdQueryVariables } from '../';
import { QueryClientContactFilterByIdDocument } from '../';
import { QueryClientContactFilterObjectsQuery } from '../';
import { QueryClientContactFilterObjectsDocument } from '../';
import { QueryClientContactFilterObjectsQueryVariables } from '../';
import { SubscribeToClientContactFilterByIdSubscription } from '../';
import { SubscribeToClientContactFilterByIdSubscriptionVariables } from '../';
import { SubscribeToClientContactFilterByIdDocument } from '../';
import { SubscribeToClientContactFilterObjectsSubscription } from '../';
import { SubscribeToClientContactFilterObjectsDocument } from '../';
import { SubscribeToClientContactFilterObjectsSubscriptionVariables } from '../';
import { ClientContactFilter_Insert_Input } from '../';
import { ClientContactFilter_On_Conflict } from '../';
import { InsertClientContactFilterMutation } from '../';
import { InsertClientContactFilterWithOnConflictMutation } from '../';
import { InsertClientContactFilterMutationVariables } from '../';
import { InsertClientContactFilterWithOnConflictMutationVariables } from '../';
import { InsertClientContactFilterDocument } from '../';
import { InsertClientContactFilterWithOnConflictDocument } from '../';
import { ClientContactFilter_Set_Input } from '../';
import { UpdateClientContactFilterByIdMutation } from '../';
import { UpdateClientContactFilterByIdMutationVariables } from '../';
import { UpdateClientContactFilterByIdDocument } from '../';
import { UpdateClientContactFilterMutation } from '../';
import { UpdateClientContactFilterMutationVariables } from '../';
import { UpdateClientContactFilterDocument } from '../';
import { RemoveClientContactFilterModelMutation } from '../';
import { RemoveClientContactFilterModelMutationVariables } from '../';
import { RemoveClientContactFilterModelDocument } from '../';
import { RemoveClientContactFilterModelByIdMutation } from '../';
import { RemoveClientContactFilterModelByIdMutationVariables } from '../';
import { RemoveClientContactFilterModelByIdDocument } from '../';
import { ClientContactJobChangeFragment } from '../';
import { QueryClientContactJobChangeByIdQuery } from '../';
import { QueryClientContactJobChangeByIdQueryVariables } from '../';
import { QueryClientContactJobChangeByIdDocument } from '../';
import { QueryClientContactJobChangeObjectsQuery } from '../';
import { QueryClientContactJobChangeObjectsDocument } from '../';
import { QueryClientContactJobChangeObjectsQueryVariables } from '../';
import { SubscribeToClientContactJobChangeByIdSubscription } from '../';
import { SubscribeToClientContactJobChangeByIdSubscriptionVariables } from '../';
import { SubscribeToClientContactJobChangeByIdDocument } from '../';
import { SubscribeToClientContactJobChangeObjectsSubscription } from '../';
import { SubscribeToClientContactJobChangeObjectsDocument } from '../';
import { SubscribeToClientContactJobChangeObjectsSubscriptionVariables } from '../';
import { ClientContactJobChange_Insert_Input } from '../';
import { ClientContactJobChange_On_Conflict } from '../';
import { InsertClientContactJobChangeMutation } from '../';
import { InsertClientContactJobChangeWithOnConflictMutation } from '../';
import { InsertClientContactJobChangeMutationVariables } from '../';
import { InsertClientContactJobChangeWithOnConflictMutationVariables } from '../';
import { InsertClientContactJobChangeDocument } from '../';
import { InsertClientContactJobChangeWithOnConflictDocument } from '../';
import { ClientContactJobChange_Set_Input } from '../';
import { UpdateClientContactJobChangeByIdMutation } from '../';
import { UpdateClientContactJobChangeByIdMutationVariables } from '../';
import { UpdateClientContactJobChangeByIdDocument } from '../';
import { UpdateClientContactJobChangeMutation } from '../';
import { UpdateClientContactJobChangeMutationVariables } from '../';
import { UpdateClientContactJobChangeDocument } from '../';
import { RemoveClientContactJobChangeModelMutation } from '../';
import { RemoveClientContactJobChangeModelMutationVariables } from '../';
import { RemoveClientContactJobChangeModelDocument } from '../';
import { RemoveClientContactJobChangeModelByIdMutation } from '../';
import { RemoveClientContactJobChangeModelByIdMutationVariables } from '../';
import { RemoveClientContactJobChangeModelByIdDocument } from '../';
import { ClientContactExternalFragment } from '../';
import { QueryClientContactExternalByIdQuery } from '../';
import { QueryClientContactExternalByIdQueryVariables } from '../';
import { QueryClientContactExternalByIdDocument } from '../';
import { QueryClientContactExternalObjectsQuery } from '../';
import { QueryClientContactExternalObjectsDocument } from '../';
import { QueryClientContactExternalObjectsQueryVariables } from '../';
import { SubscribeToClientContactExternalByIdSubscription } from '../';
import { SubscribeToClientContactExternalByIdSubscriptionVariables } from '../';
import { SubscribeToClientContactExternalByIdDocument } from '../';
import { SubscribeToClientContactExternalObjectsSubscription } from '../';
import { SubscribeToClientContactExternalObjectsDocument } from '../';
import { SubscribeToClientContactExternalObjectsSubscriptionVariables } from '../';
import { ClientContact_Insert_Input } from '../';
import { ClientContact_On_Conflict } from '../';
import { InsertClientContactExternalMutation } from '../';
import { InsertClientContactExternalWithOnConflictMutation } from '../';
import { InsertClientContactExternalMutationVariables } from '../';
import { InsertClientContactExternalWithOnConflictMutationVariables } from '../';
import { InsertClientContactExternalDocument } from '../';
import { InsertClientContactExternalWithOnConflictDocument } from '../';
import { ClientContact_Set_Input } from '../';
import { UpdateClientContactExternalByIdMutation } from '../';
import { UpdateClientContactExternalByIdMutationVariables } from '../';
import { UpdateClientContactExternalByIdDocument } from '../';
import { UpdateClientContactExternalMutation } from '../';
import { UpdateClientContactExternalMutationVariables } from '../';
import { UpdateClientContactExternalDocument } from '../';
import { RemoveClientContactModelMutation } from '../';
import { RemoveClientContactModelMutationVariables } from '../';
import { RemoveClientContactModelDocument } from '../';
import { RemoveClientContactModelByIdMutation } from '../';
import { RemoveClientContactModelByIdMutationVariables } from '../';
import { RemoveClientContactModelByIdDocument } from '../';
import { ClientContactQaFragment } from '../';
import { QueryClientContactQaByIdQuery } from '../';
import { QueryClientContactQaByIdQueryVariables } from '../';
import { QueryClientContactQaByIdDocument } from '../';
import { QueryClientContactQaObjectsQuery } from '../';
import { QueryClientContactQaObjectsDocument } from '../';
import { QueryClientContactQaObjectsQueryVariables } from '../';
import { SubscribeToClientContactQaByIdSubscription } from '../';
import { SubscribeToClientContactQaByIdSubscriptionVariables } from '../';
import { SubscribeToClientContactQaByIdDocument } from '../';
import { SubscribeToClientContactQaObjectsSubscription } from '../';
import { SubscribeToClientContactQaObjectsDocument } from '../';
import { SubscribeToClientContactQaObjectsSubscriptionVariables } from '../';
import { InsertClientContactQaMutation } from '../';
import { InsertClientContactQaWithOnConflictMutation } from '../';
import { InsertClientContactQaMutationVariables } from '../';
import { InsertClientContactQaWithOnConflictMutationVariables } from '../';
import { InsertClientContactQaDocument } from '../';
import { InsertClientContactQaWithOnConflictDocument } from '../';
import { UpdateClientContactQaByIdMutation } from '../';
import { UpdateClientContactQaByIdMutationVariables } from '../';
import { UpdateClientContactQaByIdDocument } from '../';
import { UpdateClientContactQaMutation } from '../';
import { UpdateClientContactQaMutationVariables } from '../';
import { UpdateClientContactQaDocument } from '../';
import { CrmContactEnrichmentHistoryFragment } from '../';
import { QueryCrmContactEnrichmentHistoryByIdQuery } from '../';
import { QueryCrmContactEnrichmentHistoryByIdQueryVariables } from '../';
import { QueryCrmContactEnrichmentHistoryByIdDocument } from '../';
import { QueryCrmContactEnrichmentHistoryObjectsQuery } from '../';
import { QueryCrmContactEnrichmentHistoryObjectsDocument } from '../';
import { QueryCrmContactEnrichmentHistoryObjectsQueryVariables } from '../';
import { SubscribeToCrmContactEnrichmentHistoryByIdSubscription } from '../';
import { SubscribeToCrmContactEnrichmentHistoryByIdSubscriptionVariables } from '../';
import { SubscribeToCrmContactEnrichmentHistoryByIdDocument } from '../';
import { SubscribeToCrmContactEnrichmentHistoryObjectsSubscription } from '../';
import { SubscribeToCrmContactEnrichmentHistoryObjectsDocument } from '../';
import { SubscribeToCrmContactEnrichmentHistoryObjectsSubscriptionVariables } from '../';
import { CrmContactEnrichmentHistory_Insert_Input } from '../';
import { CrmContactEnrichmentHistory_On_Conflict } from '../';
import { InsertCrmContactEnrichmentHistoryMutation } from '../';
import { InsertCrmContactEnrichmentHistoryWithOnConflictMutation } from '../';
import { InsertCrmContactEnrichmentHistoryMutationVariables } from '../';
import { InsertCrmContactEnrichmentHistoryWithOnConflictMutationVariables } from '../';
import { InsertCrmContactEnrichmentHistoryDocument } from '../';
import { InsertCrmContactEnrichmentHistoryWithOnConflictDocument } from '../';
import { CrmContactEnrichmentHistory_Set_Input } from '../';
import { UpdateCrmContactEnrichmentHistoryByIdMutation } from '../';
import { UpdateCrmContactEnrichmentHistoryByIdMutationVariables } from '../';
import { UpdateCrmContactEnrichmentHistoryByIdDocument } from '../';
import { UpdateCrmContactEnrichmentHistoryMutation } from '../';
import { UpdateCrmContactEnrichmentHistoryMutationVariables } from '../';
import { UpdateCrmContactEnrichmentHistoryDocument } from '../';
import { RemoveCrmContactEnrichmentHistoryModelMutation } from '../';
import { RemoveCrmContactEnrichmentHistoryModelMutationVariables } from '../';
import { RemoveCrmContactEnrichmentHistoryModelDocument } from '../';
import { RemoveCrmContactEnrichmentHistoryModelByIdMutation } from '../';
import { RemoveCrmContactEnrichmentHistoryModelByIdMutationVariables } from '../';
import { RemoveCrmContactEnrichmentHistoryModelByIdDocument } from '../';
import { ClientContactCrmDataFragment } from '../';
import { QueryClientContactCrmDataByIdQuery } from '../';
import { QueryClientContactCrmDataByIdQueryVariables } from '../';
import { QueryClientContactCrmDataByIdDocument } from '../';
import { QueryClientContactCrmDataObjectsQuery } from '../';
import { QueryClientContactCrmDataObjectsDocument } from '../';
import { QueryClientContactCrmDataObjectsQueryVariables } from '../';
import { SubscribeToClientContactCrmDataByIdSubscription } from '../';
import { SubscribeToClientContactCrmDataByIdSubscriptionVariables } from '../';
import { SubscribeToClientContactCrmDataByIdDocument } from '../';
import { SubscribeToClientContactCrmDataObjectsSubscription } from '../';
import { SubscribeToClientContactCrmDataObjectsDocument } from '../';
import { SubscribeToClientContactCrmDataObjectsSubscriptionVariables } from '../';
import { ClientContactCrmData_Insert_Input } from '../';
import { ClientContactCrmData_On_Conflict } from '../';
import { InsertClientContactCrmDataMutation } from '../';
import { InsertClientContactCrmDataWithOnConflictMutation } from '../';
import { InsertClientContactCrmDataMutationVariables } from '../';
import { InsertClientContactCrmDataWithOnConflictMutationVariables } from '../';
import { InsertClientContactCrmDataDocument } from '../';
import { InsertClientContactCrmDataWithOnConflictDocument } from '../';
import { ClientContactCrmData_Set_Input } from '../';
import { UpdateClientContactCrmDataByIdMutation } from '../';
import { UpdateClientContactCrmDataByIdMutationVariables } from '../';
import { UpdateClientContactCrmDataByIdDocument } from '../';
import { UpdateClientContactCrmDataMutation } from '../';
import { UpdateClientContactCrmDataMutationVariables } from '../';
import { UpdateClientContactCrmDataDocument } from '../';
import { RemoveClientContactCrmDataModelMutation } from '../';
import { RemoveClientContactCrmDataModelMutationVariables } from '../';
import { RemoveClientContactCrmDataModelDocument } from '../';
import { RemoveClientContactCrmDataModelByIdMutation } from '../';
import { RemoveClientContactCrmDataModelByIdMutationVariables } from '../';
import { RemoveClientContactCrmDataModelByIdDocument } from '../';
import { ClientExternalFragment } from '../';
import { QueryClientExternalByIdQuery } from '../';
import { QueryClientExternalByIdQueryVariables } from '../';
import { QueryClientExternalByIdDocument } from '../';
import { QueryClientExternalObjectsQuery } from '../';
import { QueryClientExternalObjectsDocument } from '../';
import { QueryClientExternalObjectsQueryVariables } from '../';
import { SubscribeToClientExternalByIdSubscription } from '../';
import { SubscribeToClientExternalByIdSubscriptionVariables } from '../';
import { SubscribeToClientExternalByIdDocument } from '../';
import { SubscribeToClientExternalObjectsSubscription } from '../';
import { SubscribeToClientExternalObjectsDocument } from '../';
import { SubscribeToClientExternalObjectsSubscriptionVariables } from '../';
import { Client_Insert_Input } from '../';
import { Client_On_Conflict } from '../';
import { InsertClientExternalMutation } from '../';
import { InsertClientExternalWithOnConflictMutation } from '../';
import { InsertClientExternalMutationVariables } from '../';
import { InsertClientExternalWithOnConflictMutationVariables } from '../';
import { InsertClientExternalDocument } from '../';
import { InsertClientExternalWithOnConflictDocument } from '../';
import { Client_Set_Input } from '../';
import { UpdateClientExternalByIdMutation } from '../';
import { UpdateClientExternalByIdMutationVariables } from '../';
import { UpdateClientExternalByIdDocument } from '../';
import { UpdateClientExternalMutation } from '../';
import { UpdateClientExternalMutationVariables } from '../';
import { UpdateClientExternalDocument } from '../';
import { RemoveClientModelMutation } from '../';
import { RemoveClientModelMutationVariables } from '../';
import { RemoveClientModelDocument } from '../';
import { RemoveClientModelByIdMutation } from '../';
import { RemoveClientModelByIdMutationVariables } from '../';
import { RemoveClientModelByIdDocument } from '../';
import { ClientQaFragment } from '../';
import { QueryClientQaByIdQuery } from '../';
import { QueryClientQaByIdQueryVariables } from '../';
import { QueryClientQaByIdDocument } from '../';
import { QueryClientQaObjectsQuery } from '../';
import { QueryClientQaObjectsDocument } from '../';
import { QueryClientQaObjectsQueryVariables } from '../';
import { SubscribeToClientQaByIdSubscription } from '../';
import { SubscribeToClientQaByIdSubscriptionVariables } from '../';
import { SubscribeToClientQaByIdDocument } from '../';
import { SubscribeToClientQaObjectsSubscription } from '../';
import { SubscribeToClientQaObjectsDocument } from '../';
import { SubscribeToClientQaObjectsSubscriptionVariables } from '../';
import { InsertClientQaMutation } from '../';
import { InsertClientQaWithOnConflictMutation } from '../';
import { InsertClientQaMutationVariables } from '../';
import { InsertClientQaWithOnConflictMutationVariables } from '../';
import { InsertClientQaDocument } from '../';
import { InsertClientQaWithOnConflictDocument } from '../';
import { UpdateClientQaByIdMutation } from '../';
import { UpdateClientQaByIdMutationVariables } from '../';
import { UpdateClientQaByIdDocument } from '../';
import { UpdateClientQaMutation } from '../';
import { UpdateClientQaMutationVariables } from '../';
import { UpdateClientQaDocument } from '../';
import { ClientInternalFragment } from '../';
import { QueryClientInternalByIdQuery } from '../';
import { QueryClientInternalByIdQueryVariables } from '../';
import { QueryClientInternalByIdDocument } from '../';
import { QueryClientInternalObjectsQuery } from '../';
import { QueryClientInternalObjectsDocument } from '../';
import { QueryClientInternalObjectsQueryVariables } from '../';
import { SubscribeToClientInternalByIdSubscription } from '../';
import { SubscribeToClientInternalByIdSubscriptionVariables } from '../';
import { SubscribeToClientInternalByIdDocument } from '../';
import { SubscribeToClientInternalObjectsSubscription } from '../';
import { SubscribeToClientInternalObjectsDocument } from '../';
import { SubscribeToClientInternalObjectsSubscriptionVariables } from '../';
import { InsertClientInternalMutation } from '../';
import { InsertClientInternalWithOnConflictMutation } from '../';
import { InsertClientInternalMutationVariables } from '../';
import { InsertClientInternalWithOnConflictMutationVariables } from '../';
import { InsertClientInternalDocument } from '../';
import { InsertClientInternalWithOnConflictDocument } from '../';
import { UpdateClientInternalByIdMutation } from '../';
import { UpdateClientInternalByIdMutationVariables } from '../';
import { UpdateClientInternalByIdDocument } from '../';
import { UpdateClientInternalMutation } from '../';
import { UpdateClientInternalMutationVariables } from '../';
import { UpdateClientInternalDocument } from '../';
import { ClientSelectFragment } from '../';
import { QueryClientSelectByIdQuery } from '../';
import { QueryClientSelectByIdQueryVariables } from '../';
import { QueryClientSelectByIdDocument } from '../';
import { QueryClientSelectObjectsQuery } from '../';
import { QueryClientSelectObjectsDocument } from '../';
import { QueryClientSelectObjectsQueryVariables } from '../';
import { SubscribeToClientSelectByIdSubscription } from '../';
import { SubscribeToClientSelectByIdSubscriptionVariables } from '../';
import { SubscribeToClientSelectByIdDocument } from '../';
import { SubscribeToClientSelectObjectsSubscription } from '../';
import { SubscribeToClientSelectObjectsDocument } from '../';
import { SubscribeToClientSelectObjectsSubscriptionVariables } from '../';
import { InsertClientSelectMutation } from '../';
import { InsertClientSelectWithOnConflictMutation } from '../';
import { InsertClientSelectMutationVariables } from '../';
import { InsertClientSelectWithOnConflictMutationVariables } from '../';
import { InsertClientSelectDocument } from '../';
import { InsertClientSelectWithOnConflictDocument } from '../';
import { UpdateClientSelectByIdMutation } from '../';
import { UpdateClientSelectByIdMutationVariables } from '../';
import { UpdateClientSelectByIdDocument } from '../';
import { UpdateClientSelectMutation } from '../';
import { UpdateClientSelectMutationVariables } from '../';
import { UpdateClientSelectDocument } from '../';
import { ClientNotificationEmailFragment } from '../';
import { QueryClientNotificationEmailByIdQuery } from '../';
import { QueryClientNotificationEmailByIdQueryVariables } from '../';
import { QueryClientNotificationEmailByIdDocument } from '../';
import { QueryClientNotificationEmailObjectsQuery } from '../';
import { QueryClientNotificationEmailObjectsDocument } from '../';
import { QueryClientNotificationEmailObjectsQueryVariables } from '../';
import { SubscribeToClientNotificationEmailByIdSubscription } from '../';
import { SubscribeToClientNotificationEmailByIdSubscriptionVariables } from '../';
import { SubscribeToClientNotificationEmailByIdDocument } from '../';
import { SubscribeToClientNotificationEmailObjectsSubscription } from '../';
import { SubscribeToClientNotificationEmailObjectsDocument } from '../';
import { SubscribeToClientNotificationEmailObjectsSubscriptionVariables } from '../';
import { ClientNotificationEmail_Insert_Input } from '../';
import { ClientNotificationEmail_On_Conflict } from '../';
import { InsertClientNotificationEmailMutation } from '../';
import { InsertClientNotificationEmailWithOnConflictMutation } from '../';
import { InsertClientNotificationEmailMutationVariables } from '../';
import { InsertClientNotificationEmailWithOnConflictMutationVariables } from '../';
import { InsertClientNotificationEmailDocument } from '../';
import { InsertClientNotificationEmailWithOnConflictDocument } from '../';
import { ClientNotificationEmail_Set_Input } from '../';
import { UpdateClientNotificationEmailByIdMutation } from '../';
import { UpdateClientNotificationEmailByIdMutationVariables } from '../';
import { UpdateClientNotificationEmailByIdDocument } from '../';
import { UpdateClientNotificationEmailMutation } from '../';
import { UpdateClientNotificationEmailMutationVariables } from '../';
import { UpdateClientNotificationEmailDocument } from '../';
import { RemoveClientNotificationEmailModelMutation } from '../';
import { RemoveClientNotificationEmailModelMutationVariables } from '../';
import { RemoveClientNotificationEmailModelDocument } from '../';
import { RemoveClientNotificationEmailModelByIdMutation } from '../';
import { RemoveClientNotificationEmailModelByIdMutationVariables } from '../';
import { RemoveClientNotificationEmailModelByIdDocument } from '../';
import { CloudTaskFragment } from '../';
import { QueryCloudTaskByIdQuery } from '../';
import { QueryCloudTaskByIdQueryVariables } from '../';
import { QueryCloudTaskByIdDocument } from '../';
import { QueryCloudTaskObjectsQuery } from '../';
import { QueryCloudTaskObjectsDocument } from '../';
import { QueryCloudTaskObjectsQueryVariables } from '../';
import { SubscribeToCloudTaskByIdSubscription } from '../';
import { SubscribeToCloudTaskByIdSubscriptionVariables } from '../';
import { SubscribeToCloudTaskByIdDocument } from '../';
import { SubscribeToCloudTaskObjectsSubscription } from '../';
import { SubscribeToCloudTaskObjectsDocument } from '../';
import { SubscribeToCloudTaskObjectsSubscriptionVariables } from '../';
import { CloudTask_Insert_Input } from '../';
import { CloudTask_On_Conflict } from '../';
import { InsertCloudTaskMutation } from '../';
import { InsertCloudTaskWithOnConflictMutation } from '../';
import { InsertCloudTaskMutationVariables } from '../';
import { InsertCloudTaskWithOnConflictMutationVariables } from '../';
import { InsertCloudTaskDocument } from '../';
import { InsertCloudTaskWithOnConflictDocument } from '../';
import { CloudTask_Set_Input } from '../';
import { UpdateCloudTaskByIdMutation } from '../';
import { UpdateCloudTaskByIdMutationVariables } from '../';
import { UpdateCloudTaskByIdDocument } from '../';
import { UpdateCloudTaskMutation } from '../';
import { UpdateCloudTaskMutationVariables } from '../';
import { UpdateCloudTaskDocument } from '../';
import { RemoveCloudTaskModelMutation } from '../';
import { RemoveCloudTaskModelMutationVariables } from '../';
import { RemoveCloudTaskModelDocument } from '../';
import { RemoveCloudTaskModelByIdMutation } from '../';
import { RemoveCloudTaskModelByIdMutationVariables } from '../';
import { RemoveCloudTaskModelByIdDocument } from '../';
import { ClientEmailReportFragment } from '../';
import { QueryClientEmailReportByIdQuery } from '../';
import { QueryClientEmailReportByIdQueryVariables } from '../';
import { QueryClientEmailReportByIdDocument } from '../';
import { QueryClientEmailReportObjectsQuery } from '../';
import { QueryClientEmailReportObjectsDocument } from '../';
import { QueryClientEmailReportObjectsQueryVariables } from '../';
import { SubscribeToClientEmailReportByIdSubscription } from '../';
import { SubscribeToClientEmailReportByIdSubscriptionVariables } from '../';
import { SubscribeToClientEmailReportByIdDocument } from '../';
import { SubscribeToClientEmailReportObjectsSubscription } from '../';
import { SubscribeToClientEmailReportObjectsDocument } from '../';
import { SubscribeToClientEmailReportObjectsSubscriptionVariables } from '../';
import { ClientEmailReport_Insert_Input } from '../';
import { ClientEmailReport_On_Conflict } from '../';
import { InsertClientEmailReportMutation } from '../';
import { InsertClientEmailReportWithOnConflictMutation } from '../';
import { InsertClientEmailReportMutationVariables } from '../';
import { InsertClientEmailReportWithOnConflictMutationVariables } from '../';
import { InsertClientEmailReportDocument } from '../';
import { InsertClientEmailReportWithOnConflictDocument } from '../';
import { ClientEmailReport_Set_Input } from '../';
import { UpdateClientEmailReportByIdMutation } from '../';
import { UpdateClientEmailReportByIdMutationVariables } from '../';
import { UpdateClientEmailReportByIdDocument } from '../';
import { UpdateClientEmailReportMutation } from '../';
import { UpdateClientEmailReportMutationVariables } from '../';
import { UpdateClientEmailReportDocument } from '../';
import { RemoveClientEmailReportModelMutation } from '../';
import { RemoveClientEmailReportModelMutationVariables } from '../';
import { RemoveClientEmailReportModelDocument } from '../';
import { RemoveClientEmailReportModelByIdMutation } from '../';
import { RemoveClientEmailReportModelByIdMutationVariables } from '../';
import { RemoveClientEmailReportModelByIdDocument } from '../';
import { EmailHistoryFragment } from '../';
import { QueryEmailHistoryByIdQuery } from '../';
import { QueryEmailHistoryByIdQueryVariables } from '../';
import { QueryEmailHistoryByIdDocument } from '../';
import { QueryEmailHistoryObjectsQuery } from '../';
import { QueryEmailHistoryObjectsDocument } from '../';
import { QueryEmailHistoryObjectsQueryVariables } from '../';
import { SubscribeToEmailHistoryByIdSubscription } from '../';
import { SubscribeToEmailHistoryByIdSubscriptionVariables } from '../';
import { SubscribeToEmailHistoryByIdDocument } from '../';
import { SubscribeToEmailHistoryObjectsSubscription } from '../';
import { SubscribeToEmailHistoryObjectsDocument } from '../';
import { SubscribeToEmailHistoryObjectsSubscriptionVariables } from '../';
import { EmailHistory_Insert_Input } from '../';
import { EmailHistory_On_Conflict } from '../';
import { InsertEmailHistoryMutation } from '../';
import { InsertEmailHistoryWithOnConflictMutation } from '../';
import { InsertEmailHistoryMutationVariables } from '../';
import { InsertEmailHistoryWithOnConflictMutationVariables } from '../';
import { InsertEmailHistoryDocument } from '../';
import { InsertEmailHistoryWithOnConflictDocument } from '../';
import { EmailHistory_Set_Input } from '../';
import { UpdateEmailHistoryByIdMutation } from '../';
import { UpdateEmailHistoryByIdMutationVariables } from '../';
import { UpdateEmailHistoryByIdDocument } from '../';
import { UpdateEmailHistoryMutation } from '../';
import { UpdateEmailHistoryMutationVariables } from '../';
import { UpdateEmailHistoryDocument } from '../';
import { RemoveEmailHistoryModelMutation } from '../';
import { RemoveEmailHistoryModelMutationVariables } from '../';
import { RemoveEmailHistoryModelDocument } from '../';
import { RemoveEmailHistoryModelByIdMutation } from '../';
import { RemoveEmailHistoryModelByIdMutationVariables } from '../';
import { RemoveEmailHistoryModelByIdDocument } from '../';
import { SendgridEventFragment } from '../';
import { QuerySendgridEventByIdQuery } from '../';
import { QuerySendgridEventByIdQueryVariables } from '../';
import { QuerySendgridEventByIdDocument } from '../';
import { QuerySendgridEventObjectsQuery } from '../';
import { QuerySendgridEventObjectsDocument } from '../';
import { QuerySendgridEventObjectsQueryVariables } from '../';
import { SubscribeToSendgridEventByIdSubscription } from '../';
import { SubscribeToSendgridEventByIdSubscriptionVariables } from '../';
import { SubscribeToSendgridEventByIdDocument } from '../';
import { SubscribeToSendgridEventObjectsSubscription } from '../';
import { SubscribeToSendgridEventObjectsDocument } from '../';
import { SubscribeToSendgridEventObjectsSubscriptionVariables } from '../';
import { SendgridEvent_Insert_Input } from '../';
import { SendgridEvent_On_Conflict } from '../';
import { InsertSendgridEventMutation } from '../';
import { InsertSendgridEventWithOnConflictMutation } from '../';
import { InsertSendgridEventMutationVariables } from '../';
import { InsertSendgridEventWithOnConflictMutationVariables } from '../';
import { InsertSendgridEventDocument } from '../';
import { InsertSendgridEventWithOnConflictDocument } from '../';
import { SendgridEvent_Set_Input } from '../';
import { UpdateSendgridEventByIdMutation } from '../';
import { UpdateSendgridEventByIdMutationVariables } from '../';
import { UpdateSendgridEventByIdDocument } from '../';
import { UpdateSendgridEventMutation } from '../';
import { UpdateSendgridEventMutationVariables } from '../';
import { UpdateSendgridEventDocument } from '../';
import { RemoveSendgridEventModelMutation } from '../';
import { RemoveSendgridEventModelMutationVariables } from '../';
import { RemoveSendgridEventModelDocument } from '../';
import { RemoveSendgridEventModelByIdMutation } from '../';
import { RemoveSendgridEventModelByIdMutationVariables } from '../';
import { RemoveSendgridEventModelByIdDocument } from '../';
import { CsvUploadFragment } from '../';
import { QueryCsvUploadByIdQuery } from '../';
import { QueryCsvUploadByIdQueryVariables } from '../';
import { QueryCsvUploadByIdDocument } from '../';
import { QueryCsvUploadObjectsQuery } from '../';
import { QueryCsvUploadObjectsDocument } from '../';
import { QueryCsvUploadObjectsQueryVariables } from '../';
import { SubscribeToCsvUploadByIdSubscription } from '../';
import { SubscribeToCsvUploadByIdSubscriptionVariables } from '../';
import { SubscribeToCsvUploadByIdDocument } from '../';
import { SubscribeToCsvUploadObjectsSubscription } from '../';
import { SubscribeToCsvUploadObjectsDocument } from '../';
import { SubscribeToCsvUploadObjectsSubscriptionVariables } from '../';
import { CsvUpload_Insert_Input } from '../';
import { CsvUpload_On_Conflict } from '../';
import { InsertCsvUploadMutation } from '../';
import { InsertCsvUploadWithOnConflictMutation } from '../';
import { InsertCsvUploadMutationVariables } from '../';
import { InsertCsvUploadWithOnConflictMutationVariables } from '../';
import { InsertCsvUploadDocument } from '../';
import { InsertCsvUploadWithOnConflictDocument } from '../';
import { CsvUpload_Set_Input } from '../';
import { UpdateCsvUploadByIdMutation } from '../';
import { UpdateCsvUploadByIdMutationVariables } from '../';
import { UpdateCsvUploadByIdDocument } from '../';
import { UpdateCsvUploadMutation } from '../';
import { UpdateCsvUploadMutationVariables } from '../';
import { UpdateCsvUploadDocument } from '../';
import { RemoveCsvUploadModelMutation } from '../';
import { RemoveCsvUploadModelMutationVariables } from '../';
import { RemoveCsvUploadModelDocument } from '../';
import { RemoveCsvUploadModelByIdMutation } from '../';
import { RemoveCsvUploadModelByIdMutationVariables } from '../';
import { RemoveCsvUploadModelByIdDocument } from '../';
import { HubspotContactListFragment } from '../';
import { QueryHubspotContactListByIdQuery } from '../';
import { QueryHubspotContactListByIdQueryVariables } from '../';
import { QueryHubspotContactListByIdDocument } from '../';
import { QueryHubspotContactListObjectsQuery } from '../';
import { QueryHubspotContactListObjectsDocument } from '../';
import { QueryHubspotContactListObjectsQueryVariables } from '../';
import { SubscribeToHubspotContactListByIdSubscription } from '../';
import { SubscribeToHubspotContactListByIdSubscriptionVariables } from '../';
import { SubscribeToHubspotContactListByIdDocument } from '../';
import { SubscribeToHubspotContactListObjectsSubscription } from '../';
import { SubscribeToHubspotContactListObjectsDocument } from '../';
import { SubscribeToHubspotContactListObjectsSubscriptionVariables } from '../';
import { HubspotContactList_Insert_Input } from '../';
import { HubspotContactList_On_Conflict } from '../';
import { InsertHubspotContactListMutation } from '../';
import { InsertHubspotContactListWithOnConflictMutation } from '../';
import { InsertHubspotContactListMutationVariables } from '../';
import { InsertHubspotContactListWithOnConflictMutationVariables } from '../';
import { InsertHubspotContactListDocument } from '../';
import { InsertHubspotContactListWithOnConflictDocument } from '../';
import { HubspotContactList_Set_Input } from '../';
import { UpdateHubspotContactListByIdMutation } from '../';
import { UpdateHubspotContactListByIdMutationVariables } from '../';
import { UpdateHubspotContactListByIdDocument } from '../';
import { UpdateHubspotContactListMutation } from '../';
import { UpdateHubspotContactListMutationVariables } from '../';
import { UpdateHubspotContactListDocument } from '../';
import { RemoveHubspotContactListModelMutation } from '../';
import { RemoveHubspotContactListModelMutationVariables } from '../';
import { RemoveHubspotContactListModelDocument } from '../';
import { RemoveHubspotContactListModelByIdMutation } from '../';
import { RemoveHubspotContactListModelByIdMutationVariables } from '../';
import { RemoveHubspotContactListModelByIdDocument } from '../';
import { HubspotContactListHubspotContactFragment } from '../';
import { QueryHubspotContactListHubspotContactByIdQuery } from '../';
import { QueryHubspotContactListHubspotContactByIdQueryVariables } from '../';
import { QueryHubspotContactListHubspotContactByIdDocument } from '../';
import { QueryHubspotContactListHubspotContactObjectsQuery } from '../';
import { QueryHubspotContactListHubspotContactObjectsDocument } from '../';
import { QueryHubspotContactListHubspotContactObjectsQueryVariables } from '../';
import { SubscribeToHubspotContactListHubspotContactByIdSubscription } from '../';
import { SubscribeToHubspotContactListHubspotContactByIdSubscriptionVariables } from '../';
import { SubscribeToHubspotContactListHubspotContactByIdDocument } from '../';
import { SubscribeToHubspotContactListHubspotContactObjectsSubscription } from '../';
import { SubscribeToHubspotContactListHubspotContactObjectsDocument } from '../';
import { SubscribeToHubspotContactListHubspotContactObjectsSubscriptionVariables } from '../';
import { HubspotContactListHubspotContact_Insert_Input } from '../';
import { HubspotContactListHubspotContact_On_Conflict } from '../';
import { InsertHubspotContactListHubspotContactMutation } from '../';
import { InsertHubspotContactListHubspotContactWithOnConflictMutation } from '../';
import { InsertHubspotContactListHubspotContactMutationVariables } from '../';
import { InsertHubspotContactListHubspotContactWithOnConflictMutationVariables } from '../';
import { InsertHubspotContactListHubspotContactDocument } from '../';
import { InsertHubspotContactListHubspotContactWithOnConflictDocument } from '../';
import { HubspotContactListHubspotContact_Set_Input } from '../';
import { UpdateHubspotContactListHubspotContactByIdMutation } from '../';
import { UpdateHubspotContactListHubspotContactByIdMutationVariables } from '../';
import { UpdateHubspotContactListHubspotContactByIdDocument } from '../';
import { UpdateHubspotContactListHubspotContactMutation } from '../';
import { UpdateHubspotContactListHubspotContactMutationVariables } from '../';
import { UpdateHubspotContactListHubspotContactDocument } from '../';
import { RemoveHubspotContactListHubspotContactModelMutation } from '../';
import { RemoveHubspotContactListHubspotContactModelMutationVariables } from '../';
import { RemoveHubspotContactListHubspotContactModelDocument } from '../';
import { RemoveHubspotContactListHubspotContactModelByIdMutation } from '../';
import { RemoveHubspotContactListHubspotContactModelByIdMutationVariables } from '../';
import { RemoveHubspotContactListHubspotContactModelByIdDocument } from '../';
import { HubspotContactPropertyFragment } from '../';
import { QueryHubspotContactPropertyByIdQuery } from '../';
import { QueryHubspotContactPropertyByIdQueryVariables } from '../';
import { QueryHubspotContactPropertyByIdDocument } from '../';
import { QueryHubspotContactPropertyObjectsQuery } from '../';
import { QueryHubspotContactPropertyObjectsDocument } from '../';
import { QueryHubspotContactPropertyObjectsQueryVariables } from '../';
import { SubscribeToHubspotContactPropertyByIdSubscription } from '../';
import { SubscribeToHubspotContactPropertyByIdSubscriptionVariables } from '../';
import { SubscribeToHubspotContactPropertyByIdDocument } from '../';
import { SubscribeToHubspotContactPropertyObjectsSubscription } from '../';
import { SubscribeToHubspotContactPropertyObjectsDocument } from '../';
import { SubscribeToHubspotContactPropertyObjectsSubscriptionVariables } from '../';
import { HubspotContactProperty_Insert_Input } from '../';
import { HubspotContactProperty_On_Conflict } from '../';
import { InsertHubspotContactPropertyMutation } from '../';
import { InsertHubspotContactPropertyWithOnConflictMutation } from '../';
import { InsertHubspotContactPropertyMutationVariables } from '../';
import { InsertHubspotContactPropertyWithOnConflictMutationVariables } from '../';
import { InsertHubspotContactPropertyDocument } from '../';
import { InsertHubspotContactPropertyWithOnConflictDocument } from '../';
import { HubspotContactProperty_Set_Input } from '../';
import { UpdateHubspotContactPropertyByIdMutation } from '../';
import { UpdateHubspotContactPropertyByIdMutationVariables } from '../';
import { UpdateHubspotContactPropertyByIdDocument } from '../';
import { UpdateHubspotContactPropertyMutation } from '../';
import { UpdateHubspotContactPropertyMutationVariables } from '../';
import { UpdateHubspotContactPropertyDocument } from '../';
import { RemoveHubspotContactPropertyModelMutation } from '../';
import { RemoveHubspotContactPropertyModelMutationVariables } from '../';
import { RemoveHubspotContactPropertyModelDocument } from '../';
import { RemoveHubspotContactPropertyModelByIdMutation } from '../';
import { RemoveHubspotContactPropertyModelByIdMutationVariables } from '../';
import { RemoveHubspotContactPropertyModelByIdDocument } from '../';
import { SalesforceContactFieldFragment } from '../';
import { QuerySalesforceContactFieldByIdQuery } from '../';
import { QuerySalesforceContactFieldByIdQueryVariables } from '../';
import { QuerySalesforceContactFieldByIdDocument } from '../';
import { QuerySalesforceContactFieldObjectsQuery } from '../';
import { QuerySalesforceContactFieldObjectsDocument } from '../';
import { QuerySalesforceContactFieldObjectsQueryVariables } from '../';
import { SubscribeToSalesforceContactFieldByIdSubscription } from '../';
import { SubscribeToSalesforceContactFieldByIdSubscriptionVariables } from '../';
import { SubscribeToSalesforceContactFieldByIdDocument } from '../';
import { SubscribeToSalesforceContactFieldObjectsSubscription } from '../';
import { SubscribeToSalesforceContactFieldObjectsDocument } from '../';
import { SubscribeToSalesforceContactFieldObjectsSubscriptionVariables } from '../';
import { SalesforceContactField_Insert_Input } from '../';
import { SalesforceContactField_On_Conflict } from '../';
import { InsertSalesforceContactFieldMutation } from '../';
import { InsertSalesforceContactFieldWithOnConflictMutation } from '../';
import { InsertSalesforceContactFieldMutationVariables } from '../';
import { InsertSalesforceContactFieldWithOnConflictMutationVariables } from '../';
import { InsertSalesforceContactFieldDocument } from '../';
import { InsertSalesforceContactFieldWithOnConflictDocument } from '../';
import { SalesforceContactField_Set_Input } from '../';
import { UpdateSalesforceContactFieldByIdMutation } from '../';
import { UpdateSalesforceContactFieldByIdMutationVariables } from '../';
import { UpdateSalesforceContactFieldByIdDocument } from '../';
import { UpdateSalesforceContactFieldMutation } from '../';
import { UpdateSalesforceContactFieldMutationVariables } from '../';
import { UpdateSalesforceContactFieldDocument } from '../';
import { RemoveSalesforceContactFieldModelMutation } from '../';
import { RemoveSalesforceContactFieldModelMutationVariables } from '../';
import { RemoveSalesforceContactFieldModelDocument } from '../';
import { RemoveSalesforceContactFieldModelByIdMutation } from '../';
import { RemoveSalesforceContactFieldModelByIdMutationVariables } from '../';
import { RemoveSalesforceContactFieldModelByIdDocument } from '../';
import { HubspotIntegrationFragment } from '../';
import { QueryHubspotIntegrationByIdQuery } from '../';
import { QueryHubspotIntegrationByIdQueryVariables } from '../';
import { QueryHubspotIntegrationByIdDocument } from '../';
import { QueryHubspotIntegrationObjectsQuery } from '../';
import { QueryHubspotIntegrationObjectsDocument } from '../';
import { QueryHubspotIntegrationObjectsQueryVariables } from '../';
import { SubscribeToHubspotIntegrationByIdSubscription } from '../';
import { SubscribeToHubspotIntegrationByIdSubscriptionVariables } from '../';
import { SubscribeToHubspotIntegrationByIdDocument } from '../';
import { SubscribeToHubspotIntegrationObjectsSubscription } from '../';
import { SubscribeToHubspotIntegrationObjectsDocument } from '../';
import { SubscribeToHubspotIntegrationObjectsSubscriptionVariables } from '../';
import { HubspotIntegration_Insert_Input } from '../';
import { HubspotIntegration_On_Conflict } from '../';
import { InsertHubspotIntegrationMutation } from '../';
import { InsertHubspotIntegrationWithOnConflictMutation } from '../';
import { InsertHubspotIntegrationMutationVariables } from '../';
import { InsertHubspotIntegrationWithOnConflictMutationVariables } from '../';
import { InsertHubspotIntegrationDocument } from '../';
import { InsertHubspotIntegrationWithOnConflictDocument } from '../';
import { HubspotIntegration_Set_Input } from '../';
import { UpdateHubspotIntegrationByIdMutation } from '../';
import { UpdateHubspotIntegrationByIdMutationVariables } from '../';
import { UpdateHubspotIntegrationByIdDocument } from '../';
import { UpdateHubspotIntegrationMutation } from '../';
import { UpdateHubspotIntegrationMutationVariables } from '../';
import { UpdateHubspotIntegrationDocument } from '../';
import { RemoveHubspotIntegrationModelMutation } from '../';
import { RemoveHubspotIntegrationModelMutationVariables } from '../';
import { RemoveHubspotIntegrationModelDocument } from '../';
import { RemoveHubspotIntegrationModelByIdMutation } from '../';
import { RemoveHubspotIntegrationModelByIdMutationVariables } from '../';
import { RemoveHubspotIntegrationModelByIdDocument } from '../';
import { SalesforceIntegrationFragment } from '../';
import { QuerySalesforceIntegrationByIdQuery } from '../';
import { QuerySalesforceIntegrationByIdQueryVariables } from '../';
import { QuerySalesforceIntegrationByIdDocument } from '../';
import { QuerySalesforceIntegrationObjectsQuery } from '../';
import { QuerySalesforceIntegrationObjectsDocument } from '../';
import { QuerySalesforceIntegrationObjectsQueryVariables } from '../';
import { SubscribeToSalesforceIntegrationByIdSubscription } from '../';
import { SubscribeToSalesforceIntegrationByIdSubscriptionVariables } from '../';
import { SubscribeToSalesforceIntegrationByIdDocument } from '../';
import { SubscribeToSalesforceIntegrationObjectsSubscription } from '../';
import { SubscribeToSalesforceIntegrationObjectsDocument } from '../';
import { SubscribeToSalesforceIntegrationObjectsSubscriptionVariables } from '../';
import { SalesforceIntegration_Insert_Input } from '../';
import { SalesforceIntegration_On_Conflict } from '../';
import { InsertSalesforceIntegrationMutation } from '../';
import { InsertSalesforceIntegrationWithOnConflictMutation } from '../';
import { InsertSalesforceIntegrationMutationVariables } from '../';
import { InsertSalesforceIntegrationWithOnConflictMutationVariables } from '../';
import { InsertSalesforceIntegrationDocument } from '../';
import { InsertSalesforceIntegrationWithOnConflictDocument } from '../';
import { SalesforceIntegration_Set_Input } from '../';
import { UpdateSalesforceIntegrationByIdMutation } from '../';
import { UpdateSalesforceIntegrationByIdMutationVariables } from '../';
import { UpdateSalesforceIntegrationByIdDocument } from '../';
import { UpdateSalesforceIntegrationMutation } from '../';
import { UpdateSalesforceIntegrationMutationVariables } from '../';
import { UpdateSalesforceIntegrationDocument } from '../';
import { RemoveSalesforceIntegrationModelMutation } from '../';
import { RemoveSalesforceIntegrationModelMutationVariables } from '../';
import { RemoveSalesforceIntegrationModelDocument } from '../';
import { RemoveSalesforceIntegrationModelByIdMutation } from '../';
import { RemoveSalesforceIntegrationModelByIdMutationVariables } from '../';
import { RemoveSalesforceIntegrationModelByIdDocument } from '../';
import { QaTaskActionFragment } from '../';
import { QueryQaTaskActionByIdQuery } from '../';
import { QueryQaTaskActionByIdQueryVariables } from '../';
import { QueryQaTaskActionByIdDocument } from '../';
import { QueryQaTaskActionObjectsQuery } from '../';
import { QueryQaTaskActionObjectsDocument } from '../';
import { QueryQaTaskActionObjectsQueryVariables } from '../';
import { SubscribeToQaTaskActionByIdSubscription } from '../';
import { SubscribeToQaTaskActionByIdSubscriptionVariables } from '../';
import { SubscribeToQaTaskActionByIdDocument } from '../';
import { SubscribeToQaTaskActionObjectsSubscription } from '../';
import { SubscribeToQaTaskActionObjectsDocument } from '../';
import { SubscribeToQaTaskActionObjectsSubscriptionVariables } from '../';
import { QaTaskAction_Insert_Input } from '../';
import { QaTaskAction_On_Conflict } from '../';
import { InsertQaTaskActionMutation } from '../';
import { InsertQaTaskActionWithOnConflictMutation } from '../';
import { InsertQaTaskActionMutationVariables } from '../';
import { InsertQaTaskActionWithOnConflictMutationVariables } from '../';
import { InsertQaTaskActionDocument } from '../';
import { InsertQaTaskActionWithOnConflictDocument } from '../';
import { QaTaskAction_Set_Input } from '../';
import { UpdateQaTaskActionByIdMutation } from '../';
import { UpdateQaTaskActionByIdMutationVariables } from '../';
import { UpdateQaTaskActionByIdDocument } from '../';
import { UpdateQaTaskActionMutation } from '../';
import { UpdateQaTaskActionMutationVariables } from '../';
import { UpdateQaTaskActionDocument } from '../';
import { RemoveQaTaskActionModelMutation } from '../';
import { RemoveQaTaskActionModelMutationVariables } from '../';
import { RemoveQaTaskActionModelDocument } from '../';
import { RemoveQaTaskActionModelByIdMutation } from '../';
import { RemoveQaTaskActionModelByIdMutationVariables } from '../';
import { RemoveQaTaskActionModelByIdDocument } from '../';
import { QaTaskFragment } from '../';
import { QueryQaTaskByIdQuery } from '../';
import { QueryQaTaskByIdQueryVariables } from '../';
import { QueryQaTaskByIdDocument } from '../';
import { QueryQaTaskObjectsQuery } from '../';
import { QueryQaTaskObjectsDocument } from '../';
import { QueryQaTaskObjectsQueryVariables } from '../';
import { SubscribeToQaTaskByIdSubscription } from '../';
import { SubscribeToQaTaskByIdSubscriptionVariables } from '../';
import { SubscribeToQaTaskByIdDocument } from '../';
import { SubscribeToQaTaskObjectsSubscription } from '../';
import { SubscribeToQaTaskObjectsDocument } from '../';
import { SubscribeToQaTaskObjectsSubscriptionVariables } from '../';
import { QaTask_Insert_Input } from '../';
import { QaTask_On_Conflict } from '../';
import { InsertQaTaskMutation } from '../';
import { InsertQaTaskWithOnConflictMutation } from '../';
import { InsertQaTaskMutationVariables } from '../';
import { InsertQaTaskWithOnConflictMutationVariables } from '../';
import { InsertQaTaskDocument } from '../';
import { InsertQaTaskWithOnConflictDocument } from '../';
import { QaTask_Set_Input } from '../';
import { UpdateQaTaskByIdMutation } from '../';
import { UpdateQaTaskByIdMutationVariables } from '../';
import { UpdateQaTaskByIdDocument } from '../';
import { UpdateQaTaskMutation } from '../';
import { UpdateQaTaskMutationVariables } from '../';
import { UpdateQaTaskDocument } from '../';
import { RemoveQaTaskModelMutation } from '../';
import { RemoveQaTaskModelMutationVariables } from '../';
import { RemoveQaTaskModelDocument } from '../';
import { RemoveQaTaskModelByIdMutation } from '../';
import { RemoveQaTaskModelByIdMutationVariables } from '../';
import { RemoveQaTaskModelByIdDocument } from '../';
import { ClientTerritoryFragment } from '../';
import { QueryClientTerritoryByIdQuery } from '../';
import { QueryClientTerritoryByIdQueryVariables } from '../';
import { QueryClientTerritoryByIdDocument } from '../';
import { QueryClientTerritoryObjectsQuery } from '../';
import { QueryClientTerritoryObjectsDocument } from '../';
import { QueryClientTerritoryObjectsQueryVariables } from '../';
import { SubscribeToClientTerritoryByIdSubscription } from '../';
import { SubscribeToClientTerritoryByIdSubscriptionVariables } from '../';
import { SubscribeToClientTerritoryByIdDocument } from '../';
import { SubscribeToClientTerritoryObjectsSubscription } from '../';
import { SubscribeToClientTerritoryObjectsDocument } from '../';
import { SubscribeToClientTerritoryObjectsSubscriptionVariables } from '../';
import { ClientTerritory_Insert_Input } from '../';
import { ClientTerritory_On_Conflict } from '../';
import { InsertClientTerritoryMutation } from '../';
import { InsertClientTerritoryWithOnConflictMutation } from '../';
import { InsertClientTerritoryMutationVariables } from '../';
import { InsertClientTerritoryWithOnConflictMutationVariables } from '../';
import { InsertClientTerritoryDocument } from '../';
import { InsertClientTerritoryWithOnConflictDocument } from '../';
import { ClientTerritory_Set_Input } from '../';
import { UpdateClientTerritoryByIdMutation } from '../';
import { UpdateClientTerritoryByIdMutationVariables } from '../';
import { UpdateClientTerritoryByIdDocument } from '../';
import { UpdateClientTerritoryMutation } from '../';
import { UpdateClientTerritoryMutationVariables } from '../';
import { UpdateClientTerritoryDocument } from '../';
import { RemoveClientTerritoryModelMutation } from '../';
import { RemoveClientTerritoryModelMutationVariables } from '../';
import { RemoveClientTerritoryModelDocument } from '../';
import { RemoveClientTerritoryModelByIdMutation } from '../';
import { RemoveClientTerritoryModelByIdMutationVariables } from '../';
import { RemoveClientTerritoryModelByIdDocument } from '../';
import { UserFragment } from '../';
import { QueryUserByIdQuery } from '../';
import { QueryUserByIdQueryVariables } from '../';
import { QueryUserByIdDocument } from '../';
import { QueryUserObjectsQuery } from '../';
import { QueryUserObjectsDocument } from '../';
import { QueryUserObjectsQueryVariables } from '../';
import { SubscribeToUserByIdSubscription } from '../';
import { SubscribeToUserByIdSubscriptionVariables } from '../';
import { SubscribeToUserByIdDocument } from '../';
import { SubscribeToUserObjectsSubscription } from '../';
import { SubscribeToUserObjectsDocument } from '../';
import { SubscribeToUserObjectsSubscriptionVariables } from '../';
import { User_Insert_Input } from '../';
import { User_On_Conflict } from '../';
import { InsertUserMutation } from '../';
import { InsertUserWithOnConflictMutation } from '../';
import { InsertUserMutationVariables } from '../';
import { InsertUserWithOnConflictMutationVariables } from '../';
import { InsertUserDocument } from '../';
import { InsertUserWithOnConflictDocument } from '../';
import { User_Set_Input } from '../';
import { UpdateUserByIdMutation } from '../';
import { UpdateUserByIdMutationVariables } from '../';
import { UpdateUserByIdDocument } from '../';
import { UpdateUserMutation } from '../';
import { UpdateUserMutationVariables } from '../';
import { UpdateUserDocument } from '../';
import { RemoveUserModelMutation } from '../';
import { RemoveUserModelMutationVariables } from '../';
import { RemoveUserModelDocument } from '../';
import { RemoveUserModelByIdMutation } from '../';
import { RemoveUserModelByIdMutationVariables } from '../';
import { RemoveUserModelByIdDocument } from '../';
import { UserInviteFragment } from '../';
import { QueryUserInviteByIdQuery } from '../';
import { QueryUserInviteByIdQueryVariables } from '../';
import { QueryUserInviteByIdDocument } from '../';
import { QueryUserInviteObjectsQuery } from '../';
import { QueryUserInviteObjectsDocument } from '../';
import { QueryUserInviteObjectsQueryVariables } from '../';
import { SubscribeToUserInviteByIdSubscription } from '../';
import { SubscribeToUserInviteByIdSubscriptionVariables } from '../';
import { SubscribeToUserInviteByIdDocument } from '../';
import { SubscribeToUserInviteObjectsSubscription } from '../';
import { SubscribeToUserInviteObjectsDocument } from '../';
import { SubscribeToUserInviteObjectsSubscriptionVariables } from '../';
import { UserInvite_Insert_Input } from '../';
import { UserInvite_On_Conflict } from '../';
import { InsertUserInviteMutation } from '../';
import { InsertUserInviteWithOnConflictMutation } from '../';
import { InsertUserInviteMutationVariables } from '../';
import { InsertUserInviteWithOnConflictMutationVariables } from '../';
import { InsertUserInviteDocument } from '../';
import { InsertUserInviteWithOnConflictDocument } from '../';
import { UserInvite_Set_Input } from '../';
import { UpdateUserInviteByIdMutation } from '../';
import { UpdateUserInviteByIdMutationVariables } from '../';
import { UpdateUserInviteByIdDocument } from '../';
import { UpdateUserInviteMutation } from '../';
import { UpdateUserInviteMutationVariables } from '../';
import { UpdateUserInviteDocument } from '../';
import { RemoveUserInviteModelMutation } from '../';
import { RemoveUserInviteModelMutationVariables } from '../';
import { RemoveUserInviteModelDocument } from '../';
import { RemoveUserInviteModelByIdMutation } from '../';
import { RemoveUserInviteModelByIdMutationVariables } from '../';
import { RemoveUserInviteModelByIdDocument } from '../';
import { QaQualificationFragment } from '../';
import { QueryQaQualificationByIdQuery } from '../';
import { QueryQaQualificationByIdQueryVariables } from '../';
import { QueryQaQualificationByIdDocument } from '../';
import { QueryQaQualificationObjectsQuery } from '../';
import { QueryQaQualificationObjectsDocument } from '../';
import { QueryQaQualificationObjectsQueryVariables } from '../';
import { SubscribeToQaQualificationByIdSubscription } from '../';
import { SubscribeToQaQualificationByIdSubscriptionVariables } from '../';
import { SubscribeToQaQualificationByIdDocument } from '../';
import { SubscribeToQaQualificationObjectsSubscription } from '../';
import { SubscribeToQaQualificationObjectsDocument } from '../';
import { SubscribeToQaQualificationObjectsSubscriptionVariables } from '../';
import { QaQualification_Insert_Input } from '../';
import { QaQualification_On_Conflict } from '../';
import { InsertQaQualificationMutation } from '../';
import { InsertQaQualificationWithOnConflictMutation } from '../';
import { InsertQaQualificationMutationVariables } from '../';
import { InsertQaQualificationWithOnConflictMutationVariables } from '../';
import { InsertQaQualificationDocument } from '../';
import { InsertQaQualificationWithOnConflictDocument } from '../';
import { QaQualification_Set_Input } from '../';
import { UpdateQaQualificationByIdMutation } from '../';
import { UpdateQaQualificationByIdMutationVariables } from '../';
import { UpdateQaQualificationByIdDocument } from '../';
import { UpdateQaQualificationMutation } from '../';
import { UpdateQaQualificationMutationVariables } from '../';
import { UpdateQaQualificationDocument } from '../';
import { RemoveQaQualificationModelMutation } from '../';
import { RemoveQaQualificationModelMutationVariables } from '../';
import { RemoveQaQualificationModelDocument } from '../';
import { RemoveQaQualificationModelByIdMutation } from '../';
import { RemoveQaQualificationModelByIdMutationVariables } from '../';
import { RemoveQaQualificationModelByIdDocument } from '../';

// GLOBAL TYPES
//------------------------------------------------
export type RemoveEntitiesQueryHookResultEx = { affected_rows: number };

// clientContactFilter REACT
//------------------------------------------------

export type ClientContactFilterByIdHookResultEx = {
  clientContactFilter: ClientContactFilterFragment | null | undefined;
};
export type ClientContactFilterObjectsHookResultEx = { objects: ClientContactFilterFragment[] };

/**
 *  Query Hook
 */

// Types
type QueryClientContactFilterByIdResult = LazyQueryResult<
  QueryClientContactFilterByIdQuery,
  QueryClientContactFilterByIdQueryVariables
>;
type QueryClientContactFilterByIdSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<
          QueryClientContactFilterByIdQuery,
          QueryClientContactFilterByIdQueryVariables,
          QueryClientContactFilterByIdQuery
        >,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryClientContactFilterByIdResultEx = Omit<QueryClientContactFilterByIdResult, 'subscribeToMore'> & {
  subscribeToMore: QueryClientContactFilterByIdSubScribeToMore;
} & ClientContactFilterByIdHookResultEx;

// Function
function useQueryClientContactFilterById({
  clientContactFilterId,
  options,
}: {
  clientContactFilterId: string;
  options?: Omit<
    QueryHookOptions<QueryClientContactFilterByIdQuery, QueryClientContactFilterByIdQueryVariables>,
    'query' | 'variables'
  >;
}): QueryClientContactFilterByIdResultEx {
  const _query: QueryClientContactFilterByIdResult = useQuery<
    QueryClientContactFilterByIdQuery,
    QueryClientContactFilterByIdQueryVariables
  >(QueryClientContactFilterByIdDocument, { variables: { clientContactFilterId }, ...options });

  const typedSubscribeToMore: QueryClientContactFilterByIdSubScribeToMore = (options) => {
    _query.subscribeToMore({
      document: QueryClientContactFilterByIdDocument,
      variables: { clientContactFilterId } as QueryClientContactFilterByIdQueryVariables,
      ...options,
    });
  };
  const { subscribeToMore, ...query } = _query;
  return {
    ...query,
    subscribeToMore: typedSubscribeToMore,
    clientContactFilter: query?.data?.clientContactFilter_by_pk,
  };
}

/**
 *  Lazy Query Hook
 */

// Types
type PickQueryClientContactFilterByIdFn = (
  query: QueryClientContactFilterByIdQuery | null | undefined
) => ClientContactFilterFragment | null | undefined;
type QueryClientContactFilterByIdLazyFn = [
  (options?: QueryLazyOptions<QueryClientContactFilterByIdQueryVariables> | undefined) => void,
  QueryClientContactFilterByIdResult
];
type QueryClientContactFilterByIdWrappedLazyFn = (
  options: Omit<QueryLazyOptions<QueryClientContactFilterByIdQueryVariables>, 'variables'>
) => void;
export type QueryClientContactFilterByIdLazyReturn = [
  QueryClientContactFilterByIdWrappedLazyFn,
  QueryClientContactFilterByIdResultEx
];

// Function
function useQueryClientContactFilterByIdLazy({
  clientContactFilterId,
  options,
}: {
  clientContactFilterId: string;
  options?: Omit<
    QueryHookOptions<QueryClientContactFilterByIdQuery, QueryClientContactFilterByIdQueryVariables>,
    'query' | 'variables'
  >;
}): QueryClientContactFilterByIdLazyReturn {
  const lazyQuery: QueryClientContactFilterByIdLazyFn = useLazyQuery<
    QueryClientContactFilterByIdQuery,
    QueryClientContactFilterByIdQueryVariables
  >(QueryClientContactFilterByIdDocument, options);

  // Setting up typed version of lazyQuery
  const pickQueryClientContactFilterById: PickQueryClientContactFilterByIdFn = (query) => {
    return query && query.clientContactFilter_by_pk;
  };
  const wrappedLazyQuery: QueryClientContactFilterByIdWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryClientContactFilterByIdSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({
        document: QueryClientContactFilterByIdDocument,
        variables: { clientContactFilterId } as QueryClientContactFilterByIdQueryVariables,
        ...options,
      });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    {
      ...lazyQueryResult,
      subscribeToMore: typedSubcribeToMore,
      clientContactFilter: pickQueryClientContactFilterById(lazyQuery[1].data),
    },
  ];
}

/**
 *  Query Collection Hook
 */

// Types
export type QueryClientContactFilterObjectsResult = LazyQueryResult<
  QueryClientContactFilterObjectsQuery,
  QueryClientContactFilterObjectsQueryVariables
>;
type QueryClientContactFilterObjectsSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<
          QueryClientContactFilterObjectsQuery,
          QueryClientContactFilterObjectsQueryVariables,
          QueryClientContactFilterObjectsQuery
        >,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryClientContactFilterObjectsResultEx = Omit<QueryClientContactFilterObjectsResult, 'subscribeToMore'> & {
  subscribeToMore: QueryClientContactFilterObjectsSubScribeToMore;
} & ClientContactFilterObjectsHookResultEx;

// Function
function useQueryClientContactFilterObjects(
  options: Omit<
    QueryHookOptions<QueryClientContactFilterObjectsQuery, QueryClientContactFilterObjectsQueryVariables>,
    'query'
  >
): QueryClientContactFilterObjectsResultEx {
  const _query: QueryClientContactFilterObjectsResult = useQuery<
    QueryClientContactFilterObjectsQuery,
    QueryClientContactFilterObjectsQueryVariables
  >(QueryClientContactFilterObjectsDocument, options);

  const typedSubscribeToMore: QueryClientContactFilterObjectsSubScribeToMore = (options) => {
    _query.subscribeToMore({ document: QueryClientContactFilterObjectsDocument, ...options });
  };
  const { subscribeToMore, ...query } = _query;

  return { ...query, subscribeToMore: typedSubscribeToMore, objects: query?.data?.clientContactFilter || [] };
}

/**
 *  Lazy Query Collection Hook
 */

// Types
type PickQueryClientContactFilterObjectsFn = (
  query: QueryClientContactFilterObjectsQuery | null | undefined
) => ClientContactFilterFragment[];
type QueryClientContactFilterObjectsLazyFn = [
  (options?: QueryLazyOptions<QueryClientContactFilterObjectsQueryVariables> | undefined) => void,
  QueryClientContactFilterObjectsResult
];
type QueryClientContactFilterObjectsWrappedLazyFn = (
  options?: QueryLazyOptions<QueryClientContactFilterObjectsQueryVariables>
) => void;
export type QueryClientContactFilterObjectsLazyReturn = [
  QueryClientContactFilterObjectsWrappedLazyFn,
  QueryClientContactFilterObjectsResultEx
];

// Function
function useQueryClientContactFilterObjectsLazy(
  options?: Omit<
    LazyQueryHookOptions<QueryClientContactFilterObjectsQuery, QueryClientContactFilterObjectsQueryVariables>,
    'query'
  >
): QueryClientContactFilterObjectsLazyReturn {
  const lazyQuery: QueryClientContactFilterObjectsLazyFn = useLazyQuery<
    QueryClientContactFilterObjectsQuery,
    QueryClientContactFilterObjectsQueryVariables
  >(QueryClientContactFilterObjectsDocument, options);

  // Setting up typed version of lazyQuery
  const pickObjects: PickQueryClientContactFilterObjectsFn = (
    query: QueryClientContactFilterObjectsQuery | null | undefined
  ) => {
    return query?.clientContactFilter || [];
  };
  const wrappedLazyQuery: QueryClientContactFilterObjectsWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryClientContactFilterObjectsSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({ document: QueryClientContactFilterObjectsDocument, ...options });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    { ...lazyQueryResult, subscribeToMore: typedSubcribeToMore, objects: pickObjects(lazyQuery[1].data) },
  ];
}

/**
 *  Subscription Hook
 */

// Types
type SubscribeToClientContactFilterByIdResult = {
  variables?: SubscribeToClientContactFilterByIdSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToClientContactFilterByIdSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToClientContactFilterByIdResultEx = SubscribeToClientContactFilterByIdResult &
  ClientContactFilterByIdHookResultEx;

// Function
function useSubscribeToClientContactFilterById({
  clientContactFilterId,
  options,
}: {
  clientContactFilterId: string;
  options?: Omit<
    SubscriptionHookOptions<
      SubscribeToClientContactFilterByIdSubscription,
      SubscribeToClientContactFilterByIdSubscriptionVariables
    >,
    'query' | 'variables'
  >;
}): SubscribeToClientContactFilterByIdResultEx {
  const subscription: SubscribeToClientContactFilterByIdResult = useSubscription<
    SubscribeToClientContactFilterByIdSubscription,
    SubscribeToClientContactFilterByIdSubscriptionVariables
  >(SubscribeToClientContactFilterByIdDocument, { variables: { clientContactFilterId }, ...options });
  return { ...subscription, clientContactFilter: subscription?.data?.clientContactFilter_by_pk };
}

/**
 *  Subscription Collection Hook
 */

// Types
export type SubscribeToClientContactFilterObjectsResult = {
  variables?: SubscribeToClientContactFilterObjectsSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToClientContactFilterObjectsSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToClientContactFilterObjectsResultEx = SubscribeToClientContactFilterObjectsResult &
  ClientContactFilterObjectsHookResultEx;

// Function
function useSubscribeToClientContactFilterObjects(
  options: Omit<
    SubscriptionHookOptions<
      SubscribeToClientContactFilterObjectsSubscription,
      SubscribeToClientContactFilterObjectsSubscriptionVariables
    >,
    'query'
  >
): SubscribeToClientContactFilterObjectsResultEx {
  const subscription: SubscribeToClientContactFilterObjectsResult = useSubscription<
    SubscribeToClientContactFilterObjectsSubscription,
    SubscribeToClientContactFilterObjectsSubscriptionVariables
  >(SubscribeToClientContactFilterObjectsDocument, options);
  return { ...subscription, objects: subscription?.data?.clientContactFilter || [] };
}

/**
 *  Insert Hooks
 */

// Types
type InsertClientContactFilterMutationResult = FetchResult<
  InsertClientContactFilterMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertClientContactFilterMutationResultEx = InsertClientContactFilterMutationResult &
  ClientContactFilterByIdHookResultEx;

type PickInsertClientContactFilterFn = (
  mutation: InsertClientContactFilterMutation | null | undefined
) => ClientContactFilterFragment | null | undefined;
type InsertClientContactFilterLazyMutationFn = MutationTuple<
  InsertClientContactFilterMutation,
  InsertClientContactFilterMutationVariables
>;
type InsertClientContactFilterWrappedLazyMutationFn = ({
  clientContactFilter,
  autoOptimisticResponse,
  options,
}: {
  clientContactFilter: ClientContactFilter_Insert_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<InsertClientContactFilterMutation, InsertClientContactFilterMutationVariables>,
    'variables'
  >;
}) => Promise<InsertClientContactFilterMutationResultEx>;
export type InsertClientContactFilterLazyMutationReturn = [
  InsertClientContactFilterWrappedLazyMutationFn,
  InsertClientContactFilterMutationResultEx
];

// Function
function useInsertClientContactFilter(
  options?: Omit<
    MutationHookOptions<InsertClientContactFilterMutation, InsertClientContactFilterMutationVariables>,
    'mutation' | 'variables'
  >
): InsertClientContactFilterLazyMutationReturn {
  const lazyMutation: InsertClientContactFilterLazyMutationFn = useMutation<
    InsertClientContactFilterMutation,
    InsertClientContactFilterMutationVariables
  >(InsertClientContactFilterDocument, options);
  const pickClientContactFilter: PickInsertClientContactFilterFn = (mutation) => {
    return mutation?.insert_clientContactFilter?.returning && mutation?.insert_clientContactFilter?.returning[0];
  };

  const wrappedLazyMutation: InsertClientContactFilterWrappedLazyMutationFn = async ({
    clientContactFilter,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: clientContactFilter });
    const mutationOptions: MutationFunctionOptions<
      InsertClientContactFilterMutation,
      InsertClientContactFilterMutationVariables
    > = { variables: { objects: [objectForInsert] }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id)
        throw new Error(`if autoOptimisticResponse = true, id must be set in object 'clientContactFilter'`);
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertClientContactFilterMutation>({
        operationType: 'insert',
        entityName: 'clientContactFilter',
        objects: [objectForInsert as ClientContactFilter_Insert_Input & ObjectWithId],
      });
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, clientContactFilter: pickClientContactFilter(fetchResult.data) };
  };

  return [
    wrappedLazyMutation,
    { ...lazyMutation[1], clientContactFilter: pickClientContactFilter(lazyMutation[1].data) },
  ];
}

//
//

// Types
type InsertClientContactFilterWithOnConflictMutationResult = FetchResult<
  InsertClientContactFilterWithOnConflictMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertClientContactFilterWithOnConflictMutationResultEx = InsertClientContactFilterWithOnConflictMutationResult &
  ClientContactFilterByIdHookResultEx;

type InsertClientContactFilterWithOnConflictLazyMutationFn = MutationTuple<
  InsertClientContactFilterWithOnConflictMutation,
  InsertClientContactFilterWithOnConflictMutationVariables
>;
type InsertClientContactFilterWithOnConflictWrappedLazyMutationFn = ({
  clientContactFilter,
  onConflict,
  autoOptimisticResponse,
  options,
}: {
  clientContactFilter: ClientContactFilter_Insert_Input;
  onConflict: ClientContactFilter_On_Conflict;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<
      InsertClientContactFilterWithOnConflictMutation,
      InsertClientContactFilterWithOnConflictMutationVariables
    >,
    'variables'
  >;
}) => Promise<InsertClientContactFilterWithOnConflictMutationResultEx>;
export type InsertClientContactFilterWithOnConflictLazyMutationReturn = [
  InsertClientContactFilterWithOnConflictWrappedLazyMutationFn,
  InsertClientContactFilterWithOnConflictMutationResultEx
];

// Function
function useInsertClientContactFilterWithOnConflict(
  options?: Omit<
    MutationHookOptions<
      InsertClientContactFilterWithOnConflictMutation,
      InsertClientContactFilterWithOnConflictMutationVariables
    >,
    'mutation' | 'variables'
  >
): InsertClientContactFilterWithOnConflictLazyMutationReturn {
  const lazyMutation: InsertClientContactFilterWithOnConflictLazyMutationFn = useMutation<
    InsertClientContactFilterWithOnConflictMutation,
    InsertClientContactFilterWithOnConflictMutationVariables
  >(InsertClientContactFilterWithOnConflictDocument, options);
  const pickClientContactFilter: PickInsertClientContactFilterFn = (
    mutation: InsertClientContactFilterWithOnConflictMutation | null | undefined
  ) => {
    return mutation?.insert_clientContactFilter?.returning && mutation.insert_clientContactFilter.returning[0];
  };

  const wrappedLazyMutation: InsertClientContactFilterWithOnConflictWrappedLazyMutationFn = async ({
    clientContactFilter,
    onConflict,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: clientContactFilter });
    const mutationOptions: MutationFunctionOptions<
      InsertClientContactFilterWithOnConflictMutation,
      InsertClientContactFilterWithOnConflictMutationVariables
    > = { variables: { objects: [objectForInsert], onConflict }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id)
        throw new Error(`if autoOptimisticResponse = true, id must be set in object 'clientContactFilter'`);
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertClientContactFilterWithOnConflictMutation>(
        {
          operationType: 'insert',
          entityName: 'clientContactFilter',
          objects: [objectForInsert as ClientContactFilter_Insert_Input & ObjectWithId],
        }
      );
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, clientContactFilter: pickClientContactFilter(fetchResult.data) };
  };

  return [
    wrappedLazyMutation,
    { ...lazyMutation[1], clientContactFilter: pickClientContactFilter(lazyMutation[1].data) },
  ];
}

// Types
type InsertClientContactFilterObjectsMutationResult = FetchResult<
  InsertClientContactFilterMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertClientContactFilterObjectsMutationResultEx = InsertClientContactFilterMutationResult &
  ClientContactFilterObjectsHookResultEx;

type PickInsertClientContactFilterObjectsFn = (
  mutation: InsertClientContactFilterMutation | null | undefined
) => ClientContactFilterFragment[];
type InsertClientContactFilterObjectsLazyMutationFn = MutationTuple<
  InsertClientContactFilterMutation,
  InsertClientContactFilterMutationVariables
>;
type InsertClientContactFilterObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<InsertClientContactFilterMutation, InsertClientContactFilterMutationVariables>
) => Promise<InsertClientContactFilterObjectsMutationResultEx>;
export type InsertClientContactFilterObjectsLazyMutationReturn = [
  InsertClientContactFilterObjectsWrappedLazyMutationFn,
  InsertClientContactFilterObjectsMutationResultEx
];

// Function
function useInsertClientContactFilterObjects(
  options?: Omit<
    MutationHookOptions<InsertClientContactFilterMutation, InsertClientContactFilterMutationVariables>,
    'mutation'
  >
): InsertClientContactFilterObjectsLazyMutationReturn {
  const lazyMutation: InsertClientContactFilterObjectsLazyMutationFn = useMutation<
    InsertClientContactFilterMutation,
    InsertClientContactFilterMutationVariables
  >(InsertClientContactFilterDocument, options);
  const pickObjects: PickInsertClientContactFilterObjectsFn = (
    mutation: InsertClientContactFilterMutation | null | undefined
  ) => {
    return mutation?.insert_clientContactFilter?.returning || [];
  };

  const wrappedLazyMutation: InsertClientContactFilterObjectsWrappedLazyMutationFn = async (options) => {
    if (options && options.variables && options.variables.objects)
      options.variables.objects = options.variables.objects.map((objectItem) =>
        stripInsertInputClientFields({ input: objectItem })
      );
    const fetchResult: InsertClientContactFilterObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Update Hooks
 */

type UpdateClientContactFilterByIdMutationResult = FetchResult<
  UpdateClientContactFilterByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateClientContactFilterByIdMutationResultEx = UpdateClientContactFilterByIdMutationResult &
  ClientContactFilterByIdHookResultEx;

type PickUpdateClientContactFilterByIdFn = (
  mutation: UpdateClientContactFilterByIdMutation | null | undefined
) => ClientContactFilterFragment | null | undefined;
type UpdateClientContactFilterByIdLazyMutationFn = MutationTuple<
  UpdateClientContactFilterByIdMutation,
  UpdateClientContactFilterByIdMutationVariables
>;
type UpdateClientContactFilterByIdWrappedLazyMutationFn = ({
  clientContactFilterId,
  set,
  autoOptimisticResponse,
  options,
}: {
  clientContactFilterId: string;
  set: ClientContactFilter_Set_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<UpdateClientContactFilterByIdMutation, UpdateClientContactFilterByIdMutationVariables>,
    'variables'
  >;
}) => Promise<UpdateClientContactFilterByIdMutationResultEx>;
export type UpdateClientContactFilterByIdLazyMutationReturn = [
  UpdateClientContactFilterByIdWrappedLazyMutationFn,
  UpdateClientContactFilterByIdMutationResultEx
];

function useUpdateClientContactFilterById(
  options?: Omit<
    MutationHookOptions<UpdateClientContactFilterByIdMutation, UpdateClientContactFilterByIdMutationVariables>,
    'mutation' | 'variables'
  >
): UpdateClientContactFilterByIdLazyMutationReturn {
  const lazyMutation: UpdateClientContactFilterByIdLazyMutationFn = useMutation<
    UpdateClientContactFilterByIdMutation,
    UpdateClientContactFilterByIdMutationVariables
  >(UpdateClientContactFilterByIdDocument, options);

  const pickClientContactFilter: PickUpdateClientContactFilterByIdFn = (mutation) => {
    return mutation?.update_clientContactFilter?.returning && mutation.update_clientContactFilter!.returning[0];
  };

  const wrappedLazyMutation: UpdateClientContactFilterByIdWrappedLazyMutationFn = async ({
    clientContactFilterId,
    set,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<
      UpdateClientContactFilterByIdMutation,
      UpdateClientContactFilterByIdMutationVariables
    > = { variables: { id: clientContactFilterId, set }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateClientContactFilterByIdMutation>(
        { operationType: 'update', entityName: 'clientContactFilter', objects: [{ id: clientContactFilterId, ...set }] }
      );
    }

    const fetchResult: UpdateClientContactFilterByIdMutationResult = await lazyMutation[0]({
      variables: { id: clientContactFilterId, set },
      ...mutationOptions,
    });
    return { ...fetchResult, clientContactFilter: pickClientContactFilter(fetchResult.data) };
  };

  return [
    wrappedLazyMutation,
    { ...lazyMutation[1], clientContactFilter: pickClientContactFilter(lazyMutation[1].data) },
  ];
}

//
//

// Types
type UpdateClientContactFilterObjectsMutationResult = FetchResult<
  UpdateClientContactFilterMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateClientContactFilterObjectsMutationResultEx = UpdateClientContactFilterObjectsMutationResult &
  ClientContactFilterObjectsHookResultEx;

// Function
type PickUpdateClientContactFilterObjectsFn = (
  mutation: UpdateClientContactFilterMutation | null | undefined
) => ClientContactFilterFragment[];
type UpdateClientContactFilterObjectsLazyMutationFn = MutationTuple<
  UpdateClientContactFilterMutation,
  UpdateClientContactFilterMutationVariables
>;
type UpdateClientContactFilterObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<UpdateClientContactFilterMutation, UpdateClientContactFilterMutationVariables>
) => Promise<UpdateClientContactFilterObjectsMutationResultEx>;
export type UpdateClientContactFilterObjectsLazyMutationReturn = [
  UpdateClientContactFilterObjectsWrappedLazyMutationFn,
  UpdateClientContactFilterObjectsMutationResultEx
];

function useUpdateClientContactFilterObjects(
  options?: Omit<
    MutationHookOptions<UpdateClientContactFilterMutation, UpdateClientContactFilterMutationVariables>,
    'mutation'
  >
): UpdateClientContactFilterObjectsLazyMutationReturn {
  const lazyMutation: UpdateClientContactFilterObjectsLazyMutationFn = useMutation<
    UpdateClientContactFilterMutation,
    UpdateClientContactFilterMutationVariables
  >(UpdateClientContactFilterDocument, options);

  const pickObjects: PickUpdateClientContactFilterObjectsFn = (
    mutation: UpdateClientContactFilterMutation | null | undefined
  ) => {
    return mutation?.update_clientContactFilter?.returning || [];
  };

  const wrappedLazyMutation: UpdateClientContactFilterObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: UpdateClientContactFilterObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Delete Hooks
 */

// Types
type RemoveClientContactFilterModelByIdFetchResult = FetchResult<
  RemoveClientContactFilterModelByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveClientContactFilterModelByIdMutationResultEx = RemoveClientContactFilterModelByIdFetchResult &
  RemoveEntitiesQueryHookResultEx;

// Function
type PickRemoveClientContactFilterModelFn = (
  mutation: RemoveClientContactFilterModelByIdMutation | null | undefined
) => number;
type RemoveClientContactFilterModelLazyMutationFn = MutationTuple<
  RemoveClientContactFilterModelByIdMutation,
  RemoveClientContactFilterModelByIdMutationVariables
>;
type RemoveClientContactFilterModelWrappedLazyMutationFn = ({
  clientContactFilterId,
  autoOptimisticResponse,
  options,
}: {
  clientContactFilterId: string;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<
      RemoveClientContactFilterModelByIdMutation,
      RemoveClientContactFilterModelByIdMutationVariables
    >,
    'variables'
  >;
}) => Promise<RemoveClientContactFilterModelByIdMutationResultEx>;
export type RemoveClientContactFilterModelLazyMutationReturn = [
  RemoveClientContactFilterModelWrappedLazyMutationFn,
  RemoveClientContactFilterModelByIdMutationResultEx
];

function useRemoveClientContactFilterModelById(
  options?: Omit<
    MutationHookOptions<
      RemoveClientContactFilterModelByIdMutation,
      RemoveClientContactFilterModelByIdMutationVariables
    >,
    'mutation' | 'variables'
  >
) {
  const lazyMutation: RemoveClientContactFilterModelLazyMutationFn = useMutation<
    RemoveClientContactFilterModelByIdMutation,
    RemoveClientContactFilterModelByIdMutationVariables
  >(RemoveClientContactFilterModelByIdDocument, options);

  const pickAffectedRows: PickRemoveClientContactFilterModelFn = (
    mutation: RemoveClientContactFilterModelByIdMutation | null | undefined
  ) => {
    return mutation?.delete_clientContactFilter?.affected_rows || 0;
  };

  const wrappedLazyMutation: RemoveClientContactFilterModelWrappedLazyMutationFn = async ({
    clientContactFilterId,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<
      RemoveClientContactFilterModelMutation,
      RemoveClientContactFilterModelByIdMutationVariables
    > = { variables: { id: clientContactFilterId }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<RemoveClientContactFilterModelMutation>(
        { operationType: 'delete', entityName: 'clientContactFilter', objects: [{ id: clientContactFilterId }] }
      );
    }
    if (!options || !options.update) {
      mutationOptions.update = generateUpdateFunctionForMutation<RemoveClientContactFilterModelByIdMutation>({
        operationType: 'delete',
        entityName: 'clientContactFilter',
        entityId: clientContactFilterId,
      });
    }

    const fetchResult: RemoveClientContactFilterModelByIdFetchResult = await lazyMutation[0](mutationOptions);
    return { ...fetchResult, affected_rows: pickAffectedRows(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], affected_rows: pickAffectedRows(lazyMutation[1].data) }] as [
    typeof wrappedLazyMutation,
    typeof lazyMutation[1] & { affected_rows: ReturnType<typeof pickAffectedRows> }
  ];
}

//
//

// Types
type RemoveClientContactFilterModelObjectsMutationResult = FetchResult<
  RemoveClientContactFilterModelMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveClientContactFilterModelObjectsMutationResultEx = RemoveClientContactFilterModelObjectsMutationResult &
  RemoveEntitiesQueryHookResultEx;

// Function
type PickRemoveClientContactFilterModelObjectsFn = (
  mutation: RemoveClientContactFilterModelMutation | null | undefined
) => number;
type RemoveClientContactFilterModelObjectsLazyMutationFn = MutationTuple<
  RemoveClientContactFilterModelMutation,
  RemoveClientContactFilterModelMutationVariables
>;
type RemoveClientContactFilterModelObjectsWrappedLazyMutationFn = (
  options: MutationFunctionOptions<
    RemoveClientContactFilterModelMutation,
    RemoveClientContactFilterModelMutationVariables
  >
) => Promise<RemoveClientContactFilterModelObjectsMutationResultEx>;
export type RemoveClientContactFilterModelObjectsLazyMutationReturn = [
  RemoveClientContactFilterModelObjectsWrappedLazyMutationFn,
  RemoveClientContactFilterModelObjectsMutationResultEx
];

function useRemoveClientContactFilterModelObjects(
  options?: Omit<
    MutationHookOptions<RemoveClientContactFilterModelMutation, RemoveClientContactFilterModelMutationVariables>,
    'mutation'
  >
): RemoveClientContactFilterModelObjectsLazyMutationReturn {
  const lazyMutation = useMutation<
    RemoveClientContactFilterModelMutation,
    RemoveClientContactFilterModelMutationVariables
  >(RemoveClientContactFilterModelDocument, options);

  const pickAffectedRows: PickRemoveClientContactFilterModelObjectsFn = (
    mutation: RemoveClientContactFilterModelMutation | null | undefined
  ) => {
    return mutation?.delete_clientContactFilter?.affected_rows || 0;
  };

  const wrappedLazyMutation: RemoveClientContactFilterModelObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: RemoveClientContactFilterModelObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, affected_rows: pickAffectedRows(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], affected_rows: pickAffectedRows(lazyMutation[1].data) }];
}

// ClientContactFilter Fragment Helper Object
//------------------------------------------------

export const ClientContactFilterFragmentGQLHooks = {
  useQueryById: useQueryClientContactFilterById,
  useQueryByIdLazy: useQueryClientContactFilterByIdLazy,
  useQueryObjects: useQueryClientContactFilterObjects,
  useQueryObjectsLazy: useQueryClientContactFilterObjectsLazy,
  useSubscribeToById: useSubscribeToClientContactFilterById,
  useSubscribeToObjects: useSubscribeToClientContactFilterObjects,
  useInsert: useInsertClientContactFilter,
  useInsertWithOnConflict: useInsertClientContactFilterWithOnConflict,
  useInsertObjects: useInsertClientContactFilterObjects,
  useUpdateById: useUpdateClientContactFilterById,
  useUpdateObjects: useUpdateClientContactFilterObjects,
};

// clientContactFilter MODEL HOOKS OBJECT
//------------------------------------------------

export const ClientContactFilterModelGQLHooks = {
  useRemoveById: useRemoveClientContactFilterModelById,
  useRemoveObjects: useRemoveClientContactFilterModelObjects,
};

// clientContactJobChange REACT
//------------------------------------------------

export type ClientContactJobChangeByIdHookResultEx = {
  clientContactJobChange: ClientContactJobChangeFragment | null | undefined;
};
export type ClientContactJobChangeObjectsHookResultEx = { objects: ClientContactJobChangeFragment[] };

/**
 *  Query Hook
 */

// Types
type QueryClientContactJobChangeByIdResult = LazyQueryResult<
  QueryClientContactJobChangeByIdQuery,
  QueryClientContactJobChangeByIdQueryVariables
>;
type QueryClientContactJobChangeByIdSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<
          QueryClientContactJobChangeByIdQuery,
          QueryClientContactJobChangeByIdQueryVariables,
          QueryClientContactJobChangeByIdQuery
        >,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryClientContactJobChangeByIdResultEx = Omit<QueryClientContactJobChangeByIdResult, 'subscribeToMore'> & {
  subscribeToMore: QueryClientContactJobChangeByIdSubScribeToMore;
} & ClientContactJobChangeByIdHookResultEx;

// Function
function useQueryClientContactJobChangeById({
  clientContactJobChangeId,
  options,
}: {
  clientContactJobChangeId: string;
  options?: Omit<
    QueryHookOptions<QueryClientContactJobChangeByIdQuery, QueryClientContactJobChangeByIdQueryVariables>,
    'query' | 'variables'
  >;
}): QueryClientContactJobChangeByIdResultEx {
  const _query: QueryClientContactJobChangeByIdResult = useQuery<
    QueryClientContactJobChangeByIdQuery,
    QueryClientContactJobChangeByIdQueryVariables
  >(QueryClientContactJobChangeByIdDocument, { variables: { clientContactJobChangeId }, ...options });

  const typedSubscribeToMore: QueryClientContactJobChangeByIdSubScribeToMore = (options) => {
    _query.subscribeToMore({
      document: QueryClientContactJobChangeByIdDocument,
      variables: { clientContactJobChangeId } as QueryClientContactJobChangeByIdQueryVariables,
      ...options,
    });
  };
  const { subscribeToMore, ...query } = _query;
  return {
    ...query,
    subscribeToMore: typedSubscribeToMore,
    clientContactJobChange: query?.data?.clientContactJobChange_by_pk,
  };
}

/**
 *  Lazy Query Hook
 */

// Types
type PickQueryClientContactJobChangeByIdFn = (
  query: QueryClientContactJobChangeByIdQuery | null | undefined
) => ClientContactJobChangeFragment | null | undefined;
type QueryClientContactJobChangeByIdLazyFn = [
  (options?: QueryLazyOptions<QueryClientContactJobChangeByIdQueryVariables> | undefined) => void,
  QueryClientContactJobChangeByIdResult
];
type QueryClientContactJobChangeByIdWrappedLazyFn = (
  options: Omit<QueryLazyOptions<QueryClientContactJobChangeByIdQueryVariables>, 'variables'>
) => void;
export type QueryClientContactJobChangeByIdLazyReturn = [
  QueryClientContactJobChangeByIdWrappedLazyFn,
  QueryClientContactJobChangeByIdResultEx
];

// Function
function useQueryClientContactJobChangeByIdLazy({
  clientContactJobChangeId,
  options,
}: {
  clientContactJobChangeId: string;
  options?: Omit<
    QueryHookOptions<QueryClientContactJobChangeByIdQuery, QueryClientContactJobChangeByIdQueryVariables>,
    'query' | 'variables'
  >;
}): QueryClientContactJobChangeByIdLazyReturn {
  const lazyQuery: QueryClientContactJobChangeByIdLazyFn = useLazyQuery<
    QueryClientContactJobChangeByIdQuery,
    QueryClientContactJobChangeByIdQueryVariables
  >(QueryClientContactJobChangeByIdDocument, options);

  // Setting up typed version of lazyQuery
  const pickQueryClientContactJobChangeById: PickQueryClientContactJobChangeByIdFn = (query) => {
    return query && query.clientContactJobChange_by_pk;
  };
  const wrappedLazyQuery: QueryClientContactJobChangeByIdWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryClientContactJobChangeByIdSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({
        document: QueryClientContactJobChangeByIdDocument,
        variables: { clientContactJobChangeId } as QueryClientContactJobChangeByIdQueryVariables,
        ...options,
      });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    {
      ...lazyQueryResult,
      subscribeToMore: typedSubcribeToMore,
      clientContactJobChange: pickQueryClientContactJobChangeById(lazyQuery[1].data),
    },
  ];
}

/**
 *  Query Collection Hook
 */

// Types
export type QueryClientContactJobChangeObjectsResult = LazyQueryResult<
  QueryClientContactJobChangeObjectsQuery,
  QueryClientContactJobChangeObjectsQueryVariables
>;
type QueryClientContactJobChangeObjectsSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<
          QueryClientContactJobChangeObjectsQuery,
          QueryClientContactJobChangeObjectsQueryVariables,
          QueryClientContactJobChangeObjectsQuery
        >,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryClientContactJobChangeObjectsResultEx = Omit<
  QueryClientContactJobChangeObjectsResult,
  'subscribeToMore'
> & { subscribeToMore: QueryClientContactJobChangeObjectsSubScribeToMore } & ClientContactJobChangeObjectsHookResultEx;

// Function
function useQueryClientContactJobChangeObjects(
  options: Omit<
    QueryHookOptions<QueryClientContactJobChangeObjectsQuery, QueryClientContactJobChangeObjectsQueryVariables>,
    'query'
  >
): QueryClientContactJobChangeObjectsResultEx {
  const _query: QueryClientContactJobChangeObjectsResult = useQuery<
    QueryClientContactJobChangeObjectsQuery,
    QueryClientContactJobChangeObjectsQueryVariables
  >(QueryClientContactJobChangeObjectsDocument, options);

  const typedSubscribeToMore: QueryClientContactJobChangeObjectsSubScribeToMore = (options) => {
    _query.subscribeToMore({ document: QueryClientContactJobChangeObjectsDocument, ...options });
  };
  const { subscribeToMore, ...query } = _query;

  return { ...query, subscribeToMore: typedSubscribeToMore, objects: query?.data?.clientContactJobChange || [] };
}

/**
 *  Lazy Query Collection Hook
 */

// Types
type PickQueryClientContactJobChangeObjectsFn = (
  query: QueryClientContactJobChangeObjectsQuery | null | undefined
) => ClientContactJobChangeFragment[];
type QueryClientContactJobChangeObjectsLazyFn = [
  (options?: QueryLazyOptions<QueryClientContactJobChangeObjectsQueryVariables> | undefined) => void,
  QueryClientContactJobChangeObjectsResult
];
type QueryClientContactJobChangeObjectsWrappedLazyFn = (
  options?: QueryLazyOptions<QueryClientContactJobChangeObjectsQueryVariables>
) => void;
export type QueryClientContactJobChangeObjectsLazyReturn = [
  QueryClientContactJobChangeObjectsWrappedLazyFn,
  QueryClientContactJobChangeObjectsResultEx
];

// Function
function useQueryClientContactJobChangeObjectsLazy(
  options?: Omit<
    LazyQueryHookOptions<QueryClientContactJobChangeObjectsQuery, QueryClientContactJobChangeObjectsQueryVariables>,
    'query'
  >
): QueryClientContactJobChangeObjectsLazyReturn {
  const lazyQuery: QueryClientContactJobChangeObjectsLazyFn = useLazyQuery<
    QueryClientContactJobChangeObjectsQuery,
    QueryClientContactJobChangeObjectsQueryVariables
  >(QueryClientContactJobChangeObjectsDocument, options);

  // Setting up typed version of lazyQuery
  const pickObjects: PickQueryClientContactJobChangeObjectsFn = (
    query: QueryClientContactJobChangeObjectsQuery | null | undefined
  ) => {
    return query?.clientContactJobChange || [];
  };
  const wrappedLazyQuery: QueryClientContactJobChangeObjectsWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryClientContactJobChangeObjectsSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({ document: QueryClientContactJobChangeObjectsDocument, ...options });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    { ...lazyQueryResult, subscribeToMore: typedSubcribeToMore, objects: pickObjects(lazyQuery[1].data) },
  ];
}

/**
 *  Subscription Hook
 */

// Types
type SubscribeToClientContactJobChangeByIdResult = {
  variables?: SubscribeToClientContactJobChangeByIdSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToClientContactJobChangeByIdSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToClientContactJobChangeByIdResultEx = SubscribeToClientContactJobChangeByIdResult &
  ClientContactJobChangeByIdHookResultEx;

// Function
function useSubscribeToClientContactJobChangeById({
  clientContactJobChangeId,
  options,
}: {
  clientContactJobChangeId: string;
  options?: Omit<
    SubscriptionHookOptions<
      SubscribeToClientContactJobChangeByIdSubscription,
      SubscribeToClientContactJobChangeByIdSubscriptionVariables
    >,
    'query' | 'variables'
  >;
}): SubscribeToClientContactJobChangeByIdResultEx {
  const subscription: SubscribeToClientContactJobChangeByIdResult = useSubscription<
    SubscribeToClientContactJobChangeByIdSubscription,
    SubscribeToClientContactJobChangeByIdSubscriptionVariables
  >(SubscribeToClientContactJobChangeByIdDocument, { variables: { clientContactJobChangeId }, ...options });
  return { ...subscription, clientContactJobChange: subscription?.data?.clientContactJobChange_by_pk };
}

/**
 *  Subscription Collection Hook
 */

// Types
export type SubscribeToClientContactJobChangeObjectsResult = {
  variables?: SubscribeToClientContactJobChangeObjectsSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToClientContactJobChangeObjectsSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToClientContactJobChangeObjectsResultEx = SubscribeToClientContactJobChangeObjectsResult &
  ClientContactJobChangeObjectsHookResultEx;

// Function
function useSubscribeToClientContactJobChangeObjects(
  options: Omit<
    SubscriptionHookOptions<
      SubscribeToClientContactJobChangeObjectsSubscription,
      SubscribeToClientContactJobChangeObjectsSubscriptionVariables
    >,
    'query'
  >
): SubscribeToClientContactJobChangeObjectsResultEx {
  const subscription: SubscribeToClientContactJobChangeObjectsResult = useSubscription<
    SubscribeToClientContactJobChangeObjectsSubscription,
    SubscribeToClientContactJobChangeObjectsSubscriptionVariables
  >(SubscribeToClientContactJobChangeObjectsDocument, options);
  return { ...subscription, objects: subscription?.data?.clientContactJobChange || [] };
}

/**
 *  Insert Hooks
 */

// Types
type InsertClientContactJobChangeMutationResult = FetchResult<
  InsertClientContactJobChangeMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertClientContactJobChangeMutationResultEx = InsertClientContactJobChangeMutationResult &
  ClientContactJobChangeByIdHookResultEx;

type PickInsertClientContactJobChangeFn = (
  mutation: InsertClientContactJobChangeMutation | null | undefined
) => ClientContactJobChangeFragment | null | undefined;
type InsertClientContactJobChangeLazyMutationFn = MutationTuple<
  InsertClientContactJobChangeMutation,
  InsertClientContactJobChangeMutationVariables
>;
type InsertClientContactJobChangeWrappedLazyMutationFn = ({
  clientContactJobChange,
  autoOptimisticResponse,
  options,
}: {
  clientContactJobChange: ClientContactJobChange_Insert_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<InsertClientContactJobChangeMutation, InsertClientContactJobChangeMutationVariables>,
    'variables'
  >;
}) => Promise<InsertClientContactJobChangeMutationResultEx>;
export type InsertClientContactJobChangeLazyMutationReturn = [
  InsertClientContactJobChangeWrappedLazyMutationFn,
  InsertClientContactJobChangeMutationResultEx
];

// Function
function useInsertClientContactJobChange(
  options?: Omit<
    MutationHookOptions<InsertClientContactJobChangeMutation, InsertClientContactJobChangeMutationVariables>,
    'mutation' | 'variables'
  >
): InsertClientContactJobChangeLazyMutationReturn {
  const lazyMutation: InsertClientContactJobChangeLazyMutationFn = useMutation<
    InsertClientContactJobChangeMutation,
    InsertClientContactJobChangeMutationVariables
  >(InsertClientContactJobChangeDocument, options);
  const pickClientContactJobChange: PickInsertClientContactJobChangeFn = (mutation) => {
    return mutation?.insert_clientContactJobChange?.returning && mutation?.insert_clientContactJobChange?.returning[0];
  };

  const wrappedLazyMutation: InsertClientContactJobChangeWrappedLazyMutationFn = async ({
    clientContactJobChange,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: clientContactJobChange });
    const mutationOptions: MutationFunctionOptions<
      InsertClientContactJobChangeMutation,
      InsertClientContactJobChangeMutationVariables
    > = { variables: { objects: [objectForInsert] }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id)
        throw new Error(`if autoOptimisticResponse = true, id must be set in object 'clientContactJobChange'`);
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertClientContactJobChangeMutation>({
        operationType: 'insert',
        entityName: 'clientContactJobChange',
        objects: [objectForInsert as ClientContactJobChange_Insert_Input & ObjectWithId],
      });
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, clientContactJobChange: pickClientContactJobChange(fetchResult.data) };
  };

  return [
    wrappedLazyMutation,
    { ...lazyMutation[1], clientContactJobChange: pickClientContactJobChange(lazyMutation[1].data) },
  ];
}

//
//

// Types
type InsertClientContactJobChangeWithOnConflictMutationResult = FetchResult<
  InsertClientContactJobChangeWithOnConflictMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertClientContactJobChangeWithOnConflictMutationResultEx = InsertClientContactJobChangeWithOnConflictMutationResult &
  ClientContactJobChangeByIdHookResultEx;

type InsertClientContactJobChangeWithOnConflictLazyMutationFn = MutationTuple<
  InsertClientContactJobChangeWithOnConflictMutation,
  InsertClientContactJobChangeWithOnConflictMutationVariables
>;
type InsertClientContactJobChangeWithOnConflictWrappedLazyMutationFn = ({
  clientContactJobChange,
  onConflict,
  autoOptimisticResponse,
  options,
}: {
  clientContactJobChange: ClientContactJobChange_Insert_Input;
  onConflict: ClientContactJobChange_On_Conflict;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<
      InsertClientContactJobChangeWithOnConflictMutation,
      InsertClientContactJobChangeWithOnConflictMutationVariables
    >,
    'variables'
  >;
}) => Promise<InsertClientContactJobChangeWithOnConflictMutationResultEx>;
export type InsertClientContactJobChangeWithOnConflictLazyMutationReturn = [
  InsertClientContactJobChangeWithOnConflictWrappedLazyMutationFn,
  InsertClientContactJobChangeWithOnConflictMutationResultEx
];

// Function
function useInsertClientContactJobChangeWithOnConflict(
  options?: Omit<
    MutationHookOptions<
      InsertClientContactJobChangeWithOnConflictMutation,
      InsertClientContactJobChangeWithOnConflictMutationVariables
    >,
    'mutation' | 'variables'
  >
): InsertClientContactJobChangeWithOnConflictLazyMutationReturn {
  const lazyMutation: InsertClientContactJobChangeWithOnConflictLazyMutationFn = useMutation<
    InsertClientContactJobChangeWithOnConflictMutation,
    InsertClientContactJobChangeWithOnConflictMutationVariables
  >(InsertClientContactJobChangeWithOnConflictDocument, options);
  const pickClientContactJobChange: PickInsertClientContactJobChangeFn = (
    mutation: InsertClientContactJobChangeWithOnConflictMutation | null | undefined
  ) => {
    return mutation?.insert_clientContactJobChange?.returning && mutation.insert_clientContactJobChange.returning[0];
  };

  const wrappedLazyMutation: InsertClientContactJobChangeWithOnConflictWrappedLazyMutationFn = async ({
    clientContactJobChange,
    onConflict,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: clientContactJobChange });
    const mutationOptions: MutationFunctionOptions<
      InsertClientContactJobChangeWithOnConflictMutation,
      InsertClientContactJobChangeWithOnConflictMutationVariables
    > = { variables: { objects: [objectForInsert], onConflict }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id)
        throw new Error(`if autoOptimisticResponse = true, id must be set in object 'clientContactJobChange'`);
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertClientContactJobChangeWithOnConflictMutation>(
        {
          operationType: 'insert',
          entityName: 'clientContactJobChange',
          objects: [objectForInsert as ClientContactJobChange_Insert_Input & ObjectWithId],
        }
      );
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, clientContactJobChange: pickClientContactJobChange(fetchResult.data) };
  };

  return [
    wrappedLazyMutation,
    { ...lazyMutation[1], clientContactJobChange: pickClientContactJobChange(lazyMutation[1].data) },
  ];
}

// Types
type InsertClientContactJobChangeObjectsMutationResult = FetchResult<
  InsertClientContactJobChangeMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertClientContactJobChangeObjectsMutationResultEx = InsertClientContactJobChangeMutationResult &
  ClientContactJobChangeObjectsHookResultEx;

type PickInsertClientContactJobChangeObjectsFn = (
  mutation: InsertClientContactJobChangeMutation | null | undefined
) => ClientContactJobChangeFragment[];
type InsertClientContactJobChangeObjectsLazyMutationFn = MutationTuple<
  InsertClientContactJobChangeMutation,
  InsertClientContactJobChangeMutationVariables
>;
type InsertClientContactJobChangeObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<InsertClientContactJobChangeMutation, InsertClientContactJobChangeMutationVariables>
) => Promise<InsertClientContactJobChangeObjectsMutationResultEx>;
export type InsertClientContactJobChangeObjectsLazyMutationReturn = [
  InsertClientContactJobChangeObjectsWrappedLazyMutationFn,
  InsertClientContactJobChangeObjectsMutationResultEx
];

// Function
function useInsertClientContactJobChangeObjects(
  options?: Omit<
    MutationHookOptions<InsertClientContactJobChangeMutation, InsertClientContactJobChangeMutationVariables>,
    'mutation'
  >
): InsertClientContactJobChangeObjectsLazyMutationReturn {
  const lazyMutation: InsertClientContactJobChangeObjectsLazyMutationFn = useMutation<
    InsertClientContactJobChangeMutation,
    InsertClientContactJobChangeMutationVariables
  >(InsertClientContactJobChangeDocument, options);
  const pickObjects: PickInsertClientContactJobChangeObjectsFn = (
    mutation: InsertClientContactJobChangeMutation | null | undefined
  ) => {
    return mutation?.insert_clientContactJobChange?.returning || [];
  };

  const wrappedLazyMutation: InsertClientContactJobChangeObjectsWrappedLazyMutationFn = async (options) => {
    if (options && options.variables && options.variables.objects)
      options.variables.objects = options.variables.objects.map((objectItem) =>
        stripInsertInputClientFields({ input: objectItem })
      );
    const fetchResult: InsertClientContactJobChangeObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Update Hooks
 */

type UpdateClientContactJobChangeByIdMutationResult = FetchResult<
  UpdateClientContactJobChangeByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateClientContactJobChangeByIdMutationResultEx = UpdateClientContactJobChangeByIdMutationResult &
  ClientContactJobChangeByIdHookResultEx;

type PickUpdateClientContactJobChangeByIdFn = (
  mutation: UpdateClientContactJobChangeByIdMutation | null | undefined
) => ClientContactJobChangeFragment | null | undefined;
type UpdateClientContactJobChangeByIdLazyMutationFn = MutationTuple<
  UpdateClientContactJobChangeByIdMutation,
  UpdateClientContactJobChangeByIdMutationVariables
>;
type UpdateClientContactJobChangeByIdWrappedLazyMutationFn = ({
  clientContactJobChangeId,
  set,
  autoOptimisticResponse,
  options,
}: {
  clientContactJobChangeId: string;
  set: ClientContactJobChange_Set_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<
      UpdateClientContactJobChangeByIdMutation,
      UpdateClientContactJobChangeByIdMutationVariables
    >,
    'variables'
  >;
}) => Promise<UpdateClientContactJobChangeByIdMutationResultEx>;
export type UpdateClientContactJobChangeByIdLazyMutationReturn = [
  UpdateClientContactJobChangeByIdWrappedLazyMutationFn,
  UpdateClientContactJobChangeByIdMutationResultEx
];

function useUpdateClientContactJobChangeById(
  options?: Omit<
    MutationHookOptions<UpdateClientContactJobChangeByIdMutation, UpdateClientContactJobChangeByIdMutationVariables>,
    'mutation' | 'variables'
  >
): UpdateClientContactJobChangeByIdLazyMutationReturn {
  const lazyMutation: UpdateClientContactJobChangeByIdLazyMutationFn = useMutation<
    UpdateClientContactJobChangeByIdMutation,
    UpdateClientContactJobChangeByIdMutationVariables
  >(UpdateClientContactJobChangeByIdDocument, options);

  const pickClientContactJobChange: PickUpdateClientContactJobChangeByIdFn = (mutation) => {
    return mutation?.update_clientContactJobChange?.returning && mutation.update_clientContactJobChange!.returning[0];
  };

  const wrappedLazyMutation: UpdateClientContactJobChangeByIdWrappedLazyMutationFn = async ({
    clientContactJobChangeId,
    set,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<
      UpdateClientContactJobChangeByIdMutation,
      UpdateClientContactJobChangeByIdMutationVariables
    > = { variables: { id: clientContactJobChangeId, set }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateClientContactJobChangeByIdMutation>(
        {
          operationType: 'update',
          entityName: 'clientContactJobChange',
          objects: [{ id: clientContactJobChangeId, ...set }],
        }
      );
    }

    const fetchResult: UpdateClientContactJobChangeByIdMutationResult = await lazyMutation[0]({
      variables: { id: clientContactJobChangeId, set },
      ...mutationOptions,
    });
    return { ...fetchResult, clientContactJobChange: pickClientContactJobChange(fetchResult.data) };
  };

  return [
    wrappedLazyMutation,
    { ...lazyMutation[1], clientContactJobChange: pickClientContactJobChange(lazyMutation[1].data) },
  ];
}

//
//

// Types
type UpdateClientContactJobChangeObjectsMutationResult = FetchResult<
  UpdateClientContactJobChangeMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateClientContactJobChangeObjectsMutationResultEx = UpdateClientContactJobChangeObjectsMutationResult &
  ClientContactJobChangeObjectsHookResultEx;

// Function
type PickUpdateClientContactJobChangeObjectsFn = (
  mutation: UpdateClientContactJobChangeMutation | null | undefined
) => ClientContactJobChangeFragment[];
type UpdateClientContactJobChangeObjectsLazyMutationFn = MutationTuple<
  UpdateClientContactJobChangeMutation,
  UpdateClientContactJobChangeMutationVariables
>;
type UpdateClientContactJobChangeObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<UpdateClientContactJobChangeMutation, UpdateClientContactJobChangeMutationVariables>
) => Promise<UpdateClientContactJobChangeObjectsMutationResultEx>;
export type UpdateClientContactJobChangeObjectsLazyMutationReturn = [
  UpdateClientContactJobChangeObjectsWrappedLazyMutationFn,
  UpdateClientContactJobChangeObjectsMutationResultEx
];

function useUpdateClientContactJobChangeObjects(
  options?: Omit<
    MutationHookOptions<UpdateClientContactJobChangeMutation, UpdateClientContactJobChangeMutationVariables>,
    'mutation'
  >
): UpdateClientContactJobChangeObjectsLazyMutationReturn {
  const lazyMutation: UpdateClientContactJobChangeObjectsLazyMutationFn = useMutation<
    UpdateClientContactJobChangeMutation,
    UpdateClientContactJobChangeMutationVariables
  >(UpdateClientContactJobChangeDocument, options);

  const pickObjects: PickUpdateClientContactJobChangeObjectsFn = (
    mutation: UpdateClientContactJobChangeMutation | null | undefined
  ) => {
    return mutation?.update_clientContactJobChange?.returning || [];
  };

  const wrappedLazyMutation: UpdateClientContactJobChangeObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: UpdateClientContactJobChangeObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Delete Hooks
 */

// Types
type RemoveClientContactJobChangeModelByIdFetchResult = FetchResult<
  RemoveClientContactJobChangeModelByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveClientContactJobChangeModelByIdMutationResultEx = RemoveClientContactJobChangeModelByIdFetchResult &
  RemoveEntitiesQueryHookResultEx;

// Function
type PickRemoveClientContactJobChangeModelFn = (
  mutation: RemoveClientContactJobChangeModelByIdMutation | null | undefined
) => number;
type RemoveClientContactJobChangeModelLazyMutationFn = MutationTuple<
  RemoveClientContactJobChangeModelByIdMutation,
  RemoveClientContactJobChangeModelByIdMutationVariables
>;
type RemoveClientContactJobChangeModelWrappedLazyMutationFn = ({
  clientContactJobChangeId,
  autoOptimisticResponse,
  options,
}: {
  clientContactJobChangeId: string;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<
      RemoveClientContactJobChangeModelByIdMutation,
      RemoveClientContactJobChangeModelByIdMutationVariables
    >,
    'variables'
  >;
}) => Promise<RemoveClientContactJobChangeModelByIdMutationResultEx>;
export type RemoveClientContactJobChangeModelLazyMutationReturn = [
  RemoveClientContactJobChangeModelWrappedLazyMutationFn,
  RemoveClientContactJobChangeModelByIdMutationResultEx
];

function useRemoveClientContactJobChangeModelById(
  options?: Omit<
    MutationHookOptions<
      RemoveClientContactJobChangeModelByIdMutation,
      RemoveClientContactJobChangeModelByIdMutationVariables
    >,
    'mutation' | 'variables'
  >
) {
  const lazyMutation: RemoveClientContactJobChangeModelLazyMutationFn = useMutation<
    RemoveClientContactJobChangeModelByIdMutation,
    RemoveClientContactJobChangeModelByIdMutationVariables
  >(RemoveClientContactJobChangeModelByIdDocument, options);

  const pickAffectedRows: PickRemoveClientContactJobChangeModelFn = (
    mutation: RemoveClientContactJobChangeModelByIdMutation | null | undefined
  ) => {
    return mutation?.delete_clientContactJobChange?.affected_rows || 0;
  };

  const wrappedLazyMutation: RemoveClientContactJobChangeModelWrappedLazyMutationFn = async ({
    clientContactJobChangeId,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<
      RemoveClientContactJobChangeModelMutation,
      RemoveClientContactJobChangeModelByIdMutationVariables
    > = { variables: { id: clientContactJobChangeId }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<RemoveClientContactJobChangeModelMutation>(
        { operationType: 'delete', entityName: 'clientContactJobChange', objects: [{ id: clientContactJobChangeId }] }
      );
    }
    if (!options || !options.update) {
      mutationOptions.update = generateUpdateFunctionForMutation<RemoveClientContactJobChangeModelByIdMutation>({
        operationType: 'delete',
        entityName: 'clientContactJobChange',
        entityId: clientContactJobChangeId,
      });
    }

    const fetchResult: RemoveClientContactJobChangeModelByIdFetchResult = await lazyMutation[0](mutationOptions);
    return { ...fetchResult, affected_rows: pickAffectedRows(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], affected_rows: pickAffectedRows(lazyMutation[1].data) }] as [
    typeof wrappedLazyMutation,
    typeof lazyMutation[1] & { affected_rows: ReturnType<typeof pickAffectedRows> }
  ];
}

//
//

// Types
type RemoveClientContactJobChangeModelObjectsMutationResult = FetchResult<
  RemoveClientContactJobChangeModelMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveClientContactJobChangeModelObjectsMutationResultEx = RemoveClientContactJobChangeModelObjectsMutationResult &
  RemoveEntitiesQueryHookResultEx;

// Function
type PickRemoveClientContactJobChangeModelObjectsFn = (
  mutation: RemoveClientContactJobChangeModelMutation | null | undefined
) => number;
type RemoveClientContactJobChangeModelObjectsLazyMutationFn = MutationTuple<
  RemoveClientContactJobChangeModelMutation,
  RemoveClientContactJobChangeModelMutationVariables
>;
type RemoveClientContactJobChangeModelObjectsWrappedLazyMutationFn = (
  options: MutationFunctionOptions<
    RemoveClientContactJobChangeModelMutation,
    RemoveClientContactJobChangeModelMutationVariables
  >
) => Promise<RemoveClientContactJobChangeModelObjectsMutationResultEx>;
export type RemoveClientContactJobChangeModelObjectsLazyMutationReturn = [
  RemoveClientContactJobChangeModelObjectsWrappedLazyMutationFn,
  RemoveClientContactJobChangeModelObjectsMutationResultEx
];

function useRemoveClientContactJobChangeModelObjects(
  options?: Omit<
    MutationHookOptions<RemoveClientContactJobChangeModelMutation, RemoveClientContactJobChangeModelMutationVariables>,
    'mutation'
  >
): RemoveClientContactJobChangeModelObjectsLazyMutationReturn {
  const lazyMutation = useMutation<
    RemoveClientContactJobChangeModelMutation,
    RemoveClientContactJobChangeModelMutationVariables
  >(RemoveClientContactJobChangeModelDocument, options);

  const pickAffectedRows: PickRemoveClientContactJobChangeModelObjectsFn = (
    mutation: RemoveClientContactJobChangeModelMutation | null | undefined
  ) => {
    return mutation?.delete_clientContactJobChange?.affected_rows || 0;
  };

  const wrappedLazyMutation: RemoveClientContactJobChangeModelObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: RemoveClientContactJobChangeModelObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, affected_rows: pickAffectedRows(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], affected_rows: pickAffectedRows(lazyMutation[1].data) }];
}

// ClientContactJobChange Fragment Helper Object
//------------------------------------------------

export const ClientContactJobChangeFragmentGQLHooks = {
  useQueryById: useQueryClientContactJobChangeById,
  useQueryByIdLazy: useQueryClientContactJobChangeByIdLazy,
  useQueryObjects: useQueryClientContactJobChangeObjects,
  useQueryObjectsLazy: useQueryClientContactJobChangeObjectsLazy,
  useSubscribeToById: useSubscribeToClientContactJobChangeById,
  useSubscribeToObjects: useSubscribeToClientContactJobChangeObjects,
  useInsert: useInsertClientContactJobChange,
  useInsertWithOnConflict: useInsertClientContactJobChangeWithOnConflict,
  useInsertObjects: useInsertClientContactJobChangeObjects,
  useUpdateById: useUpdateClientContactJobChangeById,
  useUpdateObjects: useUpdateClientContactJobChangeObjects,
};

// clientContactJobChange MODEL HOOKS OBJECT
//------------------------------------------------

export const ClientContactJobChangeModelGQLHooks = {
  useRemoveById: useRemoveClientContactJobChangeModelById,
  useRemoveObjects: useRemoveClientContactJobChangeModelObjects,
};

// clientContact REACT
//------------------------------------------------

export type ClientContactExternalByIdHookResultEx = {
  clientContactExternal: ClientContactExternalFragment | null | undefined;
};
export type ClientContactExternalObjectsHookResultEx = { objects: ClientContactExternalFragment[] };

/**
 *  Query Hook
 */

// Types
type QueryClientContactExternalByIdResult = LazyQueryResult<
  QueryClientContactExternalByIdQuery,
  QueryClientContactExternalByIdQueryVariables
>;
type QueryClientContactExternalByIdSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<
          QueryClientContactExternalByIdQuery,
          QueryClientContactExternalByIdQueryVariables,
          QueryClientContactExternalByIdQuery
        >,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryClientContactExternalByIdResultEx = Omit<QueryClientContactExternalByIdResult, 'subscribeToMore'> & {
  subscribeToMore: QueryClientContactExternalByIdSubScribeToMore;
} & ClientContactExternalByIdHookResultEx;

// Function
function useQueryClientContactExternalById({
  clientContactId,
  options,
}: {
  clientContactId: string;
  options?: Omit<
    QueryHookOptions<QueryClientContactExternalByIdQuery, QueryClientContactExternalByIdQueryVariables>,
    'query' | 'variables'
  >;
}): QueryClientContactExternalByIdResultEx {
  const _query: QueryClientContactExternalByIdResult = useQuery<
    QueryClientContactExternalByIdQuery,
    QueryClientContactExternalByIdQueryVariables
  >(QueryClientContactExternalByIdDocument, { variables: { clientContactId }, ...options });

  const typedSubscribeToMore: QueryClientContactExternalByIdSubScribeToMore = (options) => {
    _query.subscribeToMore({
      document: QueryClientContactExternalByIdDocument,
      variables: { clientContactId } as QueryClientContactExternalByIdQueryVariables,
      ...options,
    });
  };
  const { subscribeToMore, ...query } = _query;
  return { ...query, subscribeToMore: typedSubscribeToMore, clientContactExternal: query?.data?.clientContact_by_pk };
}

/**
 *  Lazy Query Hook
 */

// Types
type PickQueryClientContactExternalByIdFn = (
  query: QueryClientContactExternalByIdQuery | null | undefined
) => ClientContactExternalFragment | null | undefined;
type QueryClientContactExternalByIdLazyFn = [
  (options?: QueryLazyOptions<QueryClientContactExternalByIdQueryVariables> | undefined) => void,
  QueryClientContactExternalByIdResult
];
type QueryClientContactExternalByIdWrappedLazyFn = (
  options: Omit<QueryLazyOptions<QueryClientContactExternalByIdQueryVariables>, 'variables'>
) => void;
export type QueryClientContactExternalByIdLazyReturn = [
  QueryClientContactExternalByIdWrappedLazyFn,
  QueryClientContactExternalByIdResultEx
];

// Function
function useQueryClientContactExternalByIdLazy({
  clientContactId,
  options,
}: {
  clientContactId: string;
  options?: Omit<
    QueryHookOptions<QueryClientContactExternalByIdQuery, QueryClientContactExternalByIdQueryVariables>,
    'query' | 'variables'
  >;
}): QueryClientContactExternalByIdLazyReturn {
  const lazyQuery: QueryClientContactExternalByIdLazyFn = useLazyQuery<
    QueryClientContactExternalByIdQuery,
    QueryClientContactExternalByIdQueryVariables
  >(QueryClientContactExternalByIdDocument, options);

  // Setting up typed version of lazyQuery
  const pickQueryClientContactExternalById: PickQueryClientContactExternalByIdFn = (query) => {
    return query && query.clientContact_by_pk;
  };
  const wrappedLazyQuery: QueryClientContactExternalByIdWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryClientContactExternalByIdSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({
        document: QueryClientContactExternalByIdDocument,
        variables: { clientContactId } as QueryClientContactExternalByIdQueryVariables,
        ...options,
      });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    {
      ...lazyQueryResult,
      subscribeToMore: typedSubcribeToMore,
      clientContactExternal: pickQueryClientContactExternalById(lazyQuery[1].data),
    },
  ];
}

/**
 *  Query Collection Hook
 */

// Types
export type QueryClientContactExternalObjectsResult = LazyQueryResult<
  QueryClientContactExternalObjectsQuery,
  QueryClientContactExternalObjectsQueryVariables
>;
type QueryClientContactExternalObjectsSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<
          QueryClientContactExternalObjectsQuery,
          QueryClientContactExternalObjectsQueryVariables,
          QueryClientContactExternalObjectsQuery
        >,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryClientContactExternalObjectsResultEx = Omit<
  QueryClientContactExternalObjectsResult,
  'subscribeToMore'
> & { subscribeToMore: QueryClientContactExternalObjectsSubScribeToMore } & ClientContactExternalObjectsHookResultEx;

// Function
function useQueryClientContactExternalObjects(
  options: Omit<
    QueryHookOptions<QueryClientContactExternalObjectsQuery, QueryClientContactExternalObjectsQueryVariables>,
    'query'
  >
): QueryClientContactExternalObjectsResultEx {
  const _query: QueryClientContactExternalObjectsResult = useQuery<
    QueryClientContactExternalObjectsQuery,
    QueryClientContactExternalObjectsQueryVariables
  >(QueryClientContactExternalObjectsDocument, options);

  const typedSubscribeToMore: QueryClientContactExternalObjectsSubScribeToMore = (options) => {
    _query.subscribeToMore({ document: QueryClientContactExternalObjectsDocument, ...options });
  };
  const { subscribeToMore, ...query } = _query;

  return { ...query, subscribeToMore: typedSubscribeToMore, objects: query?.data?.clientContact || [] };
}

/**
 *  Lazy Query Collection Hook
 */

// Types
type PickQueryClientContactExternalObjectsFn = (
  query: QueryClientContactExternalObjectsQuery | null | undefined
) => ClientContactExternalFragment[];
type QueryClientContactExternalObjectsLazyFn = [
  (options?: QueryLazyOptions<QueryClientContactExternalObjectsQueryVariables> | undefined) => void,
  QueryClientContactExternalObjectsResult
];
type QueryClientContactExternalObjectsWrappedLazyFn = (
  options?: QueryLazyOptions<QueryClientContactExternalObjectsQueryVariables>
) => void;
export type QueryClientContactExternalObjectsLazyReturn = [
  QueryClientContactExternalObjectsWrappedLazyFn,
  QueryClientContactExternalObjectsResultEx
];

// Function
function useQueryClientContactExternalObjectsLazy(
  options?: Omit<
    LazyQueryHookOptions<QueryClientContactExternalObjectsQuery, QueryClientContactExternalObjectsQueryVariables>,
    'query'
  >
): QueryClientContactExternalObjectsLazyReturn {
  const lazyQuery: QueryClientContactExternalObjectsLazyFn = useLazyQuery<
    QueryClientContactExternalObjectsQuery,
    QueryClientContactExternalObjectsQueryVariables
  >(QueryClientContactExternalObjectsDocument, options);

  // Setting up typed version of lazyQuery
  const pickObjects: PickQueryClientContactExternalObjectsFn = (
    query: QueryClientContactExternalObjectsQuery | null | undefined
  ) => {
    return query?.clientContact || [];
  };
  const wrappedLazyQuery: QueryClientContactExternalObjectsWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryClientContactExternalObjectsSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({ document: QueryClientContactExternalObjectsDocument, ...options });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    { ...lazyQueryResult, subscribeToMore: typedSubcribeToMore, objects: pickObjects(lazyQuery[1].data) },
  ];
}

/**
 *  Subscription Hook
 */

// Types
type SubscribeToClientContactExternalByIdResult = {
  variables?: SubscribeToClientContactExternalByIdSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToClientContactExternalByIdSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToClientContactExternalByIdResultEx = SubscribeToClientContactExternalByIdResult &
  ClientContactExternalByIdHookResultEx;

// Function
function useSubscribeToClientContactExternalById({
  clientContactId,
  options,
}: {
  clientContactId: string;
  options?: Omit<
    SubscriptionHookOptions<
      SubscribeToClientContactExternalByIdSubscription,
      SubscribeToClientContactExternalByIdSubscriptionVariables
    >,
    'query' | 'variables'
  >;
}): SubscribeToClientContactExternalByIdResultEx {
  const subscription: SubscribeToClientContactExternalByIdResult = useSubscription<
    SubscribeToClientContactExternalByIdSubscription,
    SubscribeToClientContactExternalByIdSubscriptionVariables
  >(SubscribeToClientContactExternalByIdDocument, { variables: { clientContactId }, ...options });
  return { ...subscription, clientContactExternal: subscription?.data?.clientContact_by_pk };
}

/**
 *  Subscription Collection Hook
 */

// Types
export type SubscribeToClientContactExternalObjectsResult = {
  variables?: SubscribeToClientContactExternalObjectsSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToClientContactExternalObjectsSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToClientContactExternalObjectsResultEx = SubscribeToClientContactExternalObjectsResult &
  ClientContactExternalObjectsHookResultEx;

// Function
function useSubscribeToClientContactExternalObjects(
  options: Omit<
    SubscriptionHookOptions<
      SubscribeToClientContactExternalObjectsSubscription,
      SubscribeToClientContactExternalObjectsSubscriptionVariables
    >,
    'query'
  >
): SubscribeToClientContactExternalObjectsResultEx {
  const subscription: SubscribeToClientContactExternalObjectsResult = useSubscription<
    SubscribeToClientContactExternalObjectsSubscription,
    SubscribeToClientContactExternalObjectsSubscriptionVariables
  >(SubscribeToClientContactExternalObjectsDocument, options);
  return { ...subscription, objects: subscription?.data?.clientContact || [] };
}

/**
 *  Insert Hooks
 */

// Types
type InsertClientContactExternalMutationResult = FetchResult<
  InsertClientContactExternalMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertClientContactExternalMutationResultEx = InsertClientContactExternalMutationResult &
  ClientContactExternalByIdHookResultEx;

type PickInsertClientContactExternalFn = (
  mutation: InsertClientContactExternalMutation | null | undefined
) => ClientContactExternalFragment | null | undefined;
type InsertClientContactExternalLazyMutationFn = MutationTuple<
  InsertClientContactExternalMutation,
  InsertClientContactExternalMutationVariables
>;
type InsertClientContactExternalWrappedLazyMutationFn = ({
  clientContact,
  autoOptimisticResponse,
  options,
}: {
  clientContact: ClientContact_Insert_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<InsertClientContactExternalMutation, InsertClientContactExternalMutationVariables>,
    'variables'
  >;
}) => Promise<InsertClientContactExternalMutationResultEx>;
export type InsertClientContactExternalLazyMutationReturn = [
  InsertClientContactExternalWrappedLazyMutationFn,
  InsertClientContactExternalMutationResultEx
];

// Function
function useInsertClientContactExternal(
  options?: Omit<
    MutationHookOptions<InsertClientContactExternalMutation, InsertClientContactExternalMutationVariables>,
    'mutation' | 'variables'
  >
): InsertClientContactExternalLazyMutationReturn {
  const lazyMutation: InsertClientContactExternalLazyMutationFn = useMutation<
    InsertClientContactExternalMutation,
    InsertClientContactExternalMutationVariables
  >(InsertClientContactExternalDocument, options);
  const pickClientContactExternal: PickInsertClientContactExternalFn = (mutation) => {
    return mutation?.insert_clientContact?.returning && mutation?.insert_clientContact?.returning[0];
  };

  const wrappedLazyMutation: InsertClientContactExternalWrappedLazyMutationFn = async ({
    clientContact,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: clientContact });
    const mutationOptions: MutationFunctionOptions<
      InsertClientContactExternalMutation,
      InsertClientContactExternalMutationVariables
    > = { variables: { objects: [objectForInsert] }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id)
        throw new Error(`if autoOptimisticResponse = true, id must be set in object 'clientContact'`);
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertClientContactExternalMutation>({
        operationType: 'insert',
        entityName: 'clientContact',
        objects: [objectForInsert as ClientContact_Insert_Input & ObjectWithId],
      });
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, clientContactExternal: pickClientContactExternal(fetchResult.data) };
  };

  return [
    wrappedLazyMutation,
    { ...lazyMutation[1], clientContactExternal: pickClientContactExternal(lazyMutation[1].data) },
  ];
}

//
//

// Types
type InsertClientContactExternalWithOnConflictMutationResult = FetchResult<
  InsertClientContactExternalWithOnConflictMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertClientContactExternalWithOnConflictMutationResultEx = InsertClientContactExternalWithOnConflictMutationResult &
  ClientContactExternalByIdHookResultEx;

type InsertClientContactExternalWithOnConflictLazyMutationFn = MutationTuple<
  InsertClientContactExternalWithOnConflictMutation,
  InsertClientContactExternalWithOnConflictMutationVariables
>;
type InsertClientContactExternalWithOnConflictWrappedLazyMutationFn = ({
  clientContact,
  onConflict,
  autoOptimisticResponse,
  options,
}: {
  clientContact: ClientContact_Insert_Input;
  onConflict: ClientContact_On_Conflict;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<
      InsertClientContactExternalWithOnConflictMutation,
      InsertClientContactExternalWithOnConflictMutationVariables
    >,
    'variables'
  >;
}) => Promise<InsertClientContactExternalWithOnConflictMutationResultEx>;
export type InsertClientContactExternalWithOnConflictLazyMutationReturn = [
  InsertClientContactExternalWithOnConflictWrappedLazyMutationFn,
  InsertClientContactExternalWithOnConflictMutationResultEx
];

// Function
function useInsertClientContactExternalWithOnConflict(
  options?: Omit<
    MutationHookOptions<
      InsertClientContactExternalWithOnConflictMutation,
      InsertClientContactExternalWithOnConflictMutationVariables
    >,
    'mutation' | 'variables'
  >
): InsertClientContactExternalWithOnConflictLazyMutationReturn {
  const lazyMutation: InsertClientContactExternalWithOnConflictLazyMutationFn = useMutation<
    InsertClientContactExternalWithOnConflictMutation,
    InsertClientContactExternalWithOnConflictMutationVariables
  >(InsertClientContactExternalWithOnConflictDocument, options);
  const pickClientContactExternal: PickInsertClientContactExternalFn = (
    mutation: InsertClientContactExternalWithOnConflictMutation | null | undefined
  ) => {
    return mutation?.insert_clientContact?.returning && mutation.insert_clientContact.returning[0];
  };

  const wrappedLazyMutation: InsertClientContactExternalWithOnConflictWrappedLazyMutationFn = async ({
    clientContact,
    onConflict,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: clientContact });
    const mutationOptions: MutationFunctionOptions<
      InsertClientContactExternalWithOnConflictMutation,
      InsertClientContactExternalWithOnConflictMutationVariables
    > = { variables: { objects: [objectForInsert], onConflict }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id)
        throw new Error(`if autoOptimisticResponse = true, id must be set in object 'clientContact'`);
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertClientContactExternalWithOnConflictMutation>(
        {
          operationType: 'insert',
          entityName: 'clientContact',
          objects: [objectForInsert as ClientContact_Insert_Input & ObjectWithId],
        }
      );
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, clientContactExternal: pickClientContactExternal(fetchResult.data) };
  };

  return [
    wrappedLazyMutation,
    { ...lazyMutation[1], clientContactExternal: pickClientContactExternal(lazyMutation[1].data) },
  ];
}

// Types
type InsertClientContactExternalObjectsMutationResult = FetchResult<
  InsertClientContactExternalMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertClientContactExternalObjectsMutationResultEx = InsertClientContactExternalMutationResult &
  ClientContactExternalObjectsHookResultEx;

type PickInsertClientContactExternalObjectsFn = (
  mutation: InsertClientContactExternalMutation | null | undefined
) => ClientContactExternalFragment[];
type InsertClientContactExternalObjectsLazyMutationFn = MutationTuple<
  InsertClientContactExternalMutation,
  InsertClientContactExternalMutationVariables
>;
type InsertClientContactExternalObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<InsertClientContactExternalMutation, InsertClientContactExternalMutationVariables>
) => Promise<InsertClientContactExternalObjectsMutationResultEx>;
export type InsertClientContactExternalObjectsLazyMutationReturn = [
  InsertClientContactExternalObjectsWrappedLazyMutationFn,
  InsertClientContactExternalObjectsMutationResultEx
];

// Function
function useInsertClientContactExternalObjects(
  options?: Omit<
    MutationHookOptions<InsertClientContactExternalMutation, InsertClientContactExternalMutationVariables>,
    'mutation'
  >
): InsertClientContactExternalObjectsLazyMutationReturn {
  const lazyMutation: InsertClientContactExternalObjectsLazyMutationFn = useMutation<
    InsertClientContactExternalMutation,
    InsertClientContactExternalMutationVariables
  >(InsertClientContactExternalDocument, options);
  const pickObjects: PickInsertClientContactExternalObjectsFn = (
    mutation: InsertClientContactExternalMutation | null | undefined
  ) => {
    return mutation?.insert_clientContact?.returning || [];
  };

  const wrappedLazyMutation: InsertClientContactExternalObjectsWrappedLazyMutationFn = async (options) => {
    if (options && options.variables && options.variables.objects)
      options.variables.objects = options.variables.objects.map((objectItem) =>
        stripInsertInputClientFields({ input: objectItem })
      );
    const fetchResult: InsertClientContactExternalObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Update Hooks
 */

type UpdateClientContactExternalByIdMutationResult = FetchResult<
  UpdateClientContactExternalByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateClientContactExternalByIdMutationResultEx = UpdateClientContactExternalByIdMutationResult &
  ClientContactExternalByIdHookResultEx;

type PickUpdateClientContactExternalByIdFn = (
  mutation: UpdateClientContactExternalByIdMutation | null | undefined
) => ClientContactExternalFragment | null | undefined;
type UpdateClientContactExternalByIdLazyMutationFn = MutationTuple<
  UpdateClientContactExternalByIdMutation,
  UpdateClientContactExternalByIdMutationVariables
>;
type UpdateClientContactExternalByIdWrappedLazyMutationFn = ({
  clientContactId,
  set,
  autoOptimisticResponse,
  options,
}: {
  clientContactId: string;
  set: ClientContact_Set_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<UpdateClientContactExternalByIdMutation, UpdateClientContactExternalByIdMutationVariables>,
    'variables'
  >;
}) => Promise<UpdateClientContactExternalByIdMutationResultEx>;
export type UpdateClientContactExternalByIdLazyMutationReturn = [
  UpdateClientContactExternalByIdWrappedLazyMutationFn,
  UpdateClientContactExternalByIdMutationResultEx
];

function useUpdateClientContactExternalById(
  options?: Omit<
    MutationHookOptions<UpdateClientContactExternalByIdMutation, UpdateClientContactExternalByIdMutationVariables>,
    'mutation' | 'variables'
  >
): UpdateClientContactExternalByIdLazyMutationReturn {
  const lazyMutation: UpdateClientContactExternalByIdLazyMutationFn = useMutation<
    UpdateClientContactExternalByIdMutation,
    UpdateClientContactExternalByIdMutationVariables
  >(UpdateClientContactExternalByIdDocument, options);

  const pickClientContactExternal: PickUpdateClientContactExternalByIdFn = (mutation) => {
    return mutation?.update_clientContact?.returning && mutation.update_clientContact!.returning[0];
  };

  const wrappedLazyMutation: UpdateClientContactExternalByIdWrappedLazyMutationFn = async ({
    clientContactId,
    set,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<
      UpdateClientContactExternalByIdMutation,
      UpdateClientContactExternalByIdMutationVariables
    > = { variables: { id: clientContactId, set }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateClientContactExternalByIdMutation>(
        { operationType: 'update', entityName: 'clientContact', objects: [{ id: clientContactId, ...set }] }
      );
    }

    const fetchResult: UpdateClientContactExternalByIdMutationResult = await lazyMutation[0]({
      variables: { id: clientContactId, set },
      ...mutationOptions,
    });
    return { ...fetchResult, clientContactExternal: pickClientContactExternal(fetchResult.data) };
  };

  return [
    wrappedLazyMutation,
    { ...lazyMutation[1], clientContactExternal: pickClientContactExternal(lazyMutation[1].data) },
  ];
}

//
//

// Types
type UpdateClientContactExternalObjectsMutationResult = FetchResult<
  UpdateClientContactExternalMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateClientContactExternalObjectsMutationResultEx = UpdateClientContactExternalObjectsMutationResult &
  ClientContactExternalObjectsHookResultEx;

// Function
type PickUpdateClientContactExternalObjectsFn = (
  mutation: UpdateClientContactExternalMutation | null | undefined
) => ClientContactExternalFragment[];
type UpdateClientContactExternalObjectsLazyMutationFn = MutationTuple<
  UpdateClientContactExternalMutation,
  UpdateClientContactExternalMutationVariables
>;
type UpdateClientContactExternalObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<UpdateClientContactExternalMutation, UpdateClientContactExternalMutationVariables>
) => Promise<UpdateClientContactExternalObjectsMutationResultEx>;
export type UpdateClientContactExternalObjectsLazyMutationReturn = [
  UpdateClientContactExternalObjectsWrappedLazyMutationFn,
  UpdateClientContactExternalObjectsMutationResultEx
];

function useUpdateClientContactExternalObjects(
  options?: Omit<
    MutationHookOptions<UpdateClientContactExternalMutation, UpdateClientContactExternalMutationVariables>,
    'mutation'
  >
): UpdateClientContactExternalObjectsLazyMutationReturn {
  const lazyMutation: UpdateClientContactExternalObjectsLazyMutationFn = useMutation<
    UpdateClientContactExternalMutation,
    UpdateClientContactExternalMutationVariables
  >(UpdateClientContactExternalDocument, options);

  const pickObjects: PickUpdateClientContactExternalObjectsFn = (
    mutation: UpdateClientContactExternalMutation | null | undefined
  ) => {
    return mutation?.update_clientContact?.returning || [];
  };

  const wrappedLazyMutation: UpdateClientContactExternalObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: UpdateClientContactExternalObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Delete Hooks
 */

// Types
type RemoveClientContactModelByIdFetchResult = FetchResult<
  RemoveClientContactModelByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveClientContactModelByIdMutationResultEx = RemoveClientContactModelByIdFetchResult &
  RemoveEntitiesQueryHookResultEx;

// Function
type PickRemoveClientContactModelFn = (mutation: RemoveClientContactModelByIdMutation | null | undefined) => number;
type RemoveClientContactModelLazyMutationFn = MutationTuple<
  RemoveClientContactModelByIdMutation,
  RemoveClientContactModelByIdMutationVariables
>;
type RemoveClientContactModelWrappedLazyMutationFn = ({
  clientContactId,
  autoOptimisticResponse,
  options,
}: {
  clientContactId: string;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<RemoveClientContactModelByIdMutation, RemoveClientContactModelByIdMutationVariables>,
    'variables'
  >;
}) => Promise<RemoveClientContactModelByIdMutationResultEx>;
export type RemoveClientContactModelLazyMutationReturn = [
  RemoveClientContactModelWrappedLazyMutationFn,
  RemoveClientContactModelByIdMutationResultEx
];

function useRemoveClientContactModelById(
  options?: Omit<
    MutationHookOptions<RemoveClientContactModelByIdMutation, RemoveClientContactModelByIdMutationVariables>,
    'mutation' | 'variables'
  >
) {
  const lazyMutation: RemoveClientContactModelLazyMutationFn = useMutation<
    RemoveClientContactModelByIdMutation,
    RemoveClientContactModelByIdMutationVariables
  >(RemoveClientContactModelByIdDocument, options);

  const pickAffectedRows: PickRemoveClientContactModelFn = (
    mutation: RemoveClientContactModelByIdMutation | null | undefined
  ) => {
    return mutation?.delete_clientContact?.affected_rows || 0;
  };

  const wrappedLazyMutation: RemoveClientContactModelWrappedLazyMutationFn = async ({
    clientContactId,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<
      RemoveClientContactModelMutation,
      RemoveClientContactModelByIdMutationVariables
    > = { variables: { id: clientContactId }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<RemoveClientContactModelMutation>({
        operationType: 'delete',
        entityName: 'clientContact',
        objects: [{ id: clientContactId }],
      });
    }
    if (!options || !options.update) {
      mutationOptions.update = generateUpdateFunctionForMutation<RemoveClientContactModelByIdMutation>({
        operationType: 'delete',
        entityName: 'clientContact',
        entityId: clientContactId,
      });
    }

    const fetchResult: RemoveClientContactModelByIdFetchResult = await lazyMutation[0](mutationOptions);
    return { ...fetchResult, affected_rows: pickAffectedRows(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], affected_rows: pickAffectedRows(lazyMutation[1].data) }] as [
    typeof wrappedLazyMutation,
    typeof lazyMutation[1] & { affected_rows: ReturnType<typeof pickAffectedRows> }
  ];
}

//
//

// Types
type RemoveClientContactModelObjectsMutationResult = FetchResult<
  RemoveClientContactModelMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveClientContactModelObjectsMutationResultEx = RemoveClientContactModelObjectsMutationResult &
  RemoveEntitiesQueryHookResultEx;

// Function
type PickRemoveClientContactModelObjectsFn = (mutation: RemoveClientContactModelMutation | null | undefined) => number;
type RemoveClientContactModelObjectsLazyMutationFn = MutationTuple<
  RemoveClientContactModelMutation,
  RemoveClientContactModelMutationVariables
>;
type RemoveClientContactModelObjectsWrappedLazyMutationFn = (
  options: MutationFunctionOptions<RemoveClientContactModelMutation, RemoveClientContactModelMutationVariables>
) => Promise<RemoveClientContactModelObjectsMutationResultEx>;
export type RemoveClientContactModelObjectsLazyMutationReturn = [
  RemoveClientContactModelObjectsWrappedLazyMutationFn,
  RemoveClientContactModelObjectsMutationResultEx
];

function useRemoveClientContactModelObjects(
  options?: Omit<
    MutationHookOptions<RemoveClientContactModelMutation, RemoveClientContactModelMutationVariables>,
    'mutation'
  >
): RemoveClientContactModelObjectsLazyMutationReturn {
  const lazyMutation = useMutation<RemoveClientContactModelMutation, RemoveClientContactModelMutationVariables>(
    RemoveClientContactModelDocument,
    options
  );

  const pickAffectedRows: PickRemoveClientContactModelObjectsFn = (
    mutation: RemoveClientContactModelMutation | null | undefined
  ) => {
    return mutation?.delete_clientContact?.affected_rows || 0;
  };

  const wrappedLazyMutation: RemoveClientContactModelObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: RemoveClientContactModelObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, affected_rows: pickAffectedRows(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], affected_rows: pickAffectedRows(lazyMutation[1].data) }];
}

// ClientContactExternal Fragment Helper Object
//------------------------------------------------

export const ClientContactExternalFragmentGQLHooks = {
  useQueryById: useQueryClientContactExternalById,
  useQueryByIdLazy: useQueryClientContactExternalByIdLazy,
  useQueryObjects: useQueryClientContactExternalObjects,
  useQueryObjectsLazy: useQueryClientContactExternalObjectsLazy,
  useSubscribeToById: useSubscribeToClientContactExternalById,
  useSubscribeToObjects: useSubscribeToClientContactExternalObjects,
  useInsert: useInsertClientContactExternal,
  useInsertWithOnConflict: useInsertClientContactExternalWithOnConflict,
  useInsertObjects: useInsertClientContactExternalObjects,
  useUpdateById: useUpdateClientContactExternalById,
  useUpdateObjects: useUpdateClientContactExternalObjects,
};

// clientContact MODEL HOOKS OBJECT
//------------------------------------------------

export const ClientContactModelGQLHooks = {
  useRemoveById: useRemoveClientContactModelById,
  useRemoveObjects: useRemoveClientContactModelObjects,
};

// clientContact REACT
//------------------------------------------------

export type ClientContactQaByIdHookResultEx = { clientContactQa: ClientContactQaFragment | null | undefined };
export type ClientContactQaObjectsHookResultEx = { objects: ClientContactQaFragment[] };

/**
 *  Query Hook
 */

// Types
type QueryClientContactQaByIdResult = LazyQueryResult<
  QueryClientContactQaByIdQuery,
  QueryClientContactQaByIdQueryVariables
>;
type QueryClientContactQaByIdSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<
          QueryClientContactQaByIdQuery,
          QueryClientContactQaByIdQueryVariables,
          QueryClientContactQaByIdQuery
        >,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryClientContactQaByIdResultEx = Omit<QueryClientContactQaByIdResult, 'subscribeToMore'> & {
  subscribeToMore: QueryClientContactQaByIdSubScribeToMore;
} & ClientContactQaByIdHookResultEx;

// Function
function useQueryClientContactQaById({
  clientContactId,
  options,
}: {
  clientContactId: string;
  options?: Omit<
    QueryHookOptions<QueryClientContactQaByIdQuery, QueryClientContactQaByIdQueryVariables>,
    'query' | 'variables'
  >;
}): QueryClientContactQaByIdResultEx {
  const _query: QueryClientContactQaByIdResult = useQuery<
    QueryClientContactQaByIdQuery,
    QueryClientContactQaByIdQueryVariables
  >(QueryClientContactQaByIdDocument, { variables: { clientContactId }, ...options });

  const typedSubscribeToMore: QueryClientContactQaByIdSubScribeToMore = (options) => {
    _query.subscribeToMore({
      document: QueryClientContactQaByIdDocument,
      variables: { clientContactId } as QueryClientContactQaByIdQueryVariables,
      ...options,
    });
  };
  const { subscribeToMore, ...query } = _query;
  return { ...query, subscribeToMore: typedSubscribeToMore, clientContactQa: query?.data?.clientContact_by_pk };
}

/**
 *  Lazy Query Hook
 */

// Types
type PickQueryClientContactQaByIdFn = (
  query: QueryClientContactQaByIdQuery | null | undefined
) => ClientContactQaFragment | null | undefined;
type QueryClientContactQaByIdLazyFn = [
  (options?: QueryLazyOptions<QueryClientContactQaByIdQueryVariables> | undefined) => void,
  QueryClientContactQaByIdResult
];
type QueryClientContactQaByIdWrappedLazyFn = (
  options: Omit<QueryLazyOptions<QueryClientContactQaByIdQueryVariables>, 'variables'>
) => void;
export type QueryClientContactQaByIdLazyReturn = [
  QueryClientContactQaByIdWrappedLazyFn,
  QueryClientContactQaByIdResultEx
];

// Function
function useQueryClientContactQaByIdLazy({
  clientContactId,
  options,
}: {
  clientContactId: string;
  options?: Omit<
    QueryHookOptions<QueryClientContactQaByIdQuery, QueryClientContactQaByIdQueryVariables>,
    'query' | 'variables'
  >;
}): QueryClientContactQaByIdLazyReturn {
  const lazyQuery: QueryClientContactQaByIdLazyFn = useLazyQuery<
    QueryClientContactQaByIdQuery,
    QueryClientContactQaByIdQueryVariables
  >(QueryClientContactQaByIdDocument, options);

  // Setting up typed version of lazyQuery
  const pickQueryClientContactQaById: PickQueryClientContactQaByIdFn = (query) => {
    return query && query.clientContact_by_pk;
  };
  const wrappedLazyQuery: QueryClientContactQaByIdWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryClientContactQaByIdSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({
        document: QueryClientContactQaByIdDocument,
        variables: { clientContactId } as QueryClientContactQaByIdQueryVariables,
        ...options,
      });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    {
      ...lazyQueryResult,
      subscribeToMore: typedSubcribeToMore,
      clientContactQa: pickQueryClientContactQaById(lazyQuery[1].data),
    },
  ];
}

/**
 *  Query Collection Hook
 */

// Types
export type QueryClientContactQaObjectsResult = LazyQueryResult<
  QueryClientContactQaObjectsQuery,
  QueryClientContactQaObjectsQueryVariables
>;
type QueryClientContactQaObjectsSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<
          QueryClientContactQaObjectsQuery,
          QueryClientContactQaObjectsQueryVariables,
          QueryClientContactQaObjectsQuery
        >,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryClientContactQaObjectsResultEx = Omit<QueryClientContactQaObjectsResult, 'subscribeToMore'> & {
  subscribeToMore: QueryClientContactQaObjectsSubScribeToMore;
} & ClientContactQaObjectsHookResultEx;

// Function
function useQueryClientContactQaObjects(
  options: Omit<QueryHookOptions<QueryClientContactQaObjectsQuery, QueryClientContactQaObjectsQueryVariables>, 'query'>
): QueryClientContactQaObjectsResultEx {
  const _query: QueryClientContactQaObjectsResult = useQuery<
    QueryClientContactQaObjectsQuery,
    QueryClientContactQaObjectsQueryVariables
  >(QueryClientContactQaObjectsDocument, options);

  const typedSubscribeToMore: QueryClientContactQaObjectsSubScribeToMore = (options) => {
    _query.subscribeToMore({ document: QueryClientContactQaObjectsDocument, ...options });
  };
  const { subscribeToMore, ...query } = _query;

  return { ...query, subscribeToMore: typedSubscribeToMore, objects: query?.data?.clientContact || [] };
}

/**
 *  Lazy Query Collection Hook
 */

// Types
type PickQueryClientContactQaObjectsFn = (
  query: QueryClientContactQaObjectsQuery | null | undefined
) => ClientContactQaFragment[];
type QueryClientContactQaObjectsLazyFn = [
  (options?: QueryLazyOptions<QueryClientContactQaObjectsQueryVariables> | undefined) => void,
  QueryClientContactQaObjectsResult
];
type QueryClientContactQaObjectsWrappedLazyFn = (
  options?: QueryLazyOptions<QueryClientContactQaObjectsQueryVariables>
) => void;
export type QueryClientContactQaObjectsLazyReturn = [
  QueryClientContactQaObjectsWrappedLazyFn,
  QueryClientContactQaObjectsResultEx
];

// Function
function useQueryClientContactQaObjectsLazy(
  options?: Omit<
    LazyQueryHookOptions<QueryClientContactQaObjectsQuery, QueryClientContactQaObjectsQueryVariables>,
    'query'
  >
): QueryClientContactQaObjectsLazyReturn {
  const lazyQuery: QueryClientContactQaObjectsLazyFn = useLazyQuery<
    QueryClientContactQaObjectsQuery,
    QueryClientContactQaObjectsQueryVariables
  >(QueryClientContactQaObjectsDocument, options);

  // Setting up typed version of lazyQuery
  const pickObjects: PickQueryClientContactQaObjectsFn = (
    query: QueryClientContactQaObjectsQuery | null | undefined
  ) => {
    return query?.clientContact || [];
  };
  const wrappedLazyQuery: QueryClientContactQaObjectsWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryClientContactQaObjectsSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({ document: QueryClientContactQaObjectsDocument, ...options });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    { ...lazyQueryResult, subscribeToMore: typedSubcribeToMore, objects: pickObjects(lazyQuery[1].data) },
  ];
}

/**
 *  Subscription Hook
 */

// Types
type SubscribeToClientContactQaByIdResult = {
  variables?: SubscribeToClientContactQaByIdSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToClientContactQaByIdSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToClientContactQaByIdResultEx = SubscribeToClientContactQaByIdResult &
  ClientContactQaByIdHookResultEx;

// Function
function useSubscribeToClientContactQaById({
  clientContactId,
  options,
}: {
  clientContactId: string;
  options?: Omit<
    SubscriptionHookOptions<
      SubscribeToClientContactQaByIdSubscription,
      SubscribeToClientContactQaByIdSubscriptionVariables
    >,
    'query' | 'variables'
  >;
}): SubscribeToClientContactQaByIdResultEx {
  const subscription: SubscribeToClientContactQaByIdResult = useSubscription<
    SubscribeToClientContactQaByIdSubscription,
    SubscribeToClientContactQaByIdSubscriptionVariables
  >(SubscribeToClientContactQaByIdDocument, { variables: { clientContactId }, ...options });
  return { ...subscription, clientContactQa: subscription?.data?.clientContact_by_pk };
}

/**
 *  Subscription Collection Hook
 */

// Types
export type SubscribeToClientContactQaObjectsResult = {
  variables?: SubscribeToClientContactQaObjectsSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToClientContactQaObjectsSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToClientContactQaObjectsResultEx = SubscribeToClientContactQaObjectsResult &
  ClientContactQaObjectsHookResultEx;

// Function
function useSubscribeToClientContactQaObjects(
  options: Omit<
    SubscriptionHookOptions<
      SubscribeToClientContactQaObjectsSubscription,
      SubscribeToClientContactQaObjectsSubscriptionVariables
    >,
    'query'
  >
): SubscribeToClientContactQaObjectsResultEx {
  const subscription: SubscribeToClientContactQaObjectsResult = useSubscription<
    SubscribeToClientContactQaObjectsSubscription,
    SubscribeToClientContactQaObjectsSubscriptionVariables
  >(SubscribeToClientContactQaObjectsDocument, options);
  return { ...subscription, objects: subscription?.data?.clientContact || [] };
}

/**
 *  Insert Hooks
 */

// Types
type InsertClientContactQaMutationResult = FetchResult<
  InsertClientContactQaMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertClientContactQaMutationResultEx = InsertClientContactQaMutationResult &
  ClientContactQaByIdHookResultEx;

type PickInsertClientContactQaFn = (
  mutation: InsertClientContactQaMutation | null | undefined
) => ClientContactQaFragment | null | undefined;
type InsertClientContactQaLazyMutationFn = MutationTuple<
  InsertClientContactQaMutation,
  InsertClientContactQaMutationVariables
>;
type InsertClientContactQaWrappedLazyMutationFn = ({
  clientContact,
  autoOptimisticResponse,
  options,
}: {
  clientContact: ClientContact_Insert_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<InsertClientContactQaMutation, InsertClientContactQaMutationVariables>,
    'variables'
  >;
}) => Promise<InsertClientContactQaMutationResultEx>;
export type InsertClientContactQaLazyMutationReturn = [
  InsertClientContactQaWrappedLazyMutationFn,
  InsertClientContactQaMutationResultEx
];

// Function
function useInsertClientContactQa(
  options?: Omit<
    MutationHookOptions<InsertClientContactQaMutation, InsertClientContactQaMutationVariables>,
    'mutation' | 'variables'
  >
): InsertClientContactQaLazyMutationReturn {
  const lazyMutation: InsertClientContactQaLazyMutationFn = useMutation<
    InsertClientContactQaMutation,
    InsertClientContactQaMutationVariables
  >(InsertClientContactQaDocument, options);
  const pickClientContactQa: PickInsertClientContactQaFn = (mutation) => {
    return mutation?.insert_clientContact?.returning && mutation?.insert_clientContact?.returning[0];
  };

  const wrappedLazyMutation: InsertClientContactQaWrappedLazyMutationFn = async ({
    clientContact,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: clientContact });
    const mutationOptions: MutationFunctionOptions<
      InsertClientContactQaMutation,
      InsertClientContactQaMutationVariables
    > = { variables: { objects: [objectForInsert] }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id)
        throw new Error(`if autoOptimisticResponse = true, id must be set in object 'clientContact'`);
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertClientContactQaMutation>({
        operationType: 'insert',
        entityName: 'clientContact',
        objects: [objectForInsert as ClientContact_Insert_Input & ObjectWithId],
      });
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, clientContactQa: pickClientContactQa(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], clientContactQa: pickClientContactQa(lazyMutation[1].data) }];
}

//
//

// Types
type InsertClientContactQaWithOnConflictMutationResult = FetchResult<
  InsertClientContactQaWithOnConflictMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertClientContactQaWithOnConflictMutationResultEx = InsertClientContactQaWithOnConflictMutationResult &
  ClientContactQaByIdHookResultEx;

type InsertClientContactQaWithOnConflictLazyMutationFn = MutationTuple<
  InsertClientContactQaWithOnConflictMutation,
  InsertClientContactQaWithOnConflictMutationVariables
>;
type InsertClientContactQaWithOnConflictWrappedLazyMutationFn = ({
  clientContact,
  onConflict,
  autoOptimisticResponse,
  options,
}: {
  clientContact: ClientContact_Insert_Input;
  onConflict: ClientContact_On_Conflict;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<
      InsertClientContactQaWithOnConflictMutation,
      InsertClientContactQaWithOnConflictMutationVariables
    >,
    'variables'
  >;
}) => Promise<InsertClientContactQaWithOnConflictMutationResultEx>;
export type InsertClientContactQaWithOnConflictLazyMutationReturn = [
  InsertClientContactQaWithOnConflictWrappedLazyMutationFn,
  InsertClientContactQaWithOnConflictMutationResultEx
];

// Function
function useInsertClientContactQaWithOnConflict(
  options?: Omit<
    MutationHookOptions<
      InsertClientContactQaWithOnConflictMutation,
      InsertClientContactQaWithOnConflictMutationVariables
    >,
    'mutation' | 'variables'
  >
): InsertClientContactQaWithOnConflictLazyMutationReturn {
  const lazyMutation: InsertClientContactQaWithOnConflictLazyMutationFn = useMutation<
    InsertClientContactQaWithOnConflictMutation,
    InsertClientContactQaWithOnConflictMutationVariables
  >(InsertClientContactQaWithOnConflictDocument, options);
  const pickClientContactQa: PickInsertClientContactQaFn = (
    mutation: InsertClientContactQaWithOnConflictMutation | null | undefined
  ) => {
    return mutation?.insert_clientContact?.returning && mutation.insert_clientContact.returning[0];
  };

  const wrappedLazyMutation: InsertClientContactQaWithOnConflictWrappedLazyMutationFn = async ({
    clientContact,
    onConflict,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: clientContact });
    const mutationOptions: MutationFunctionOptions<
      InsertClientContactQaWithOnConflictMutation,
      InsertClientContactQaWithOnConflictMutationVariables
    > = { variables: { objects: [objectForInsert], onConflict }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id)
        throw new Error(`if autoOptimisticResponse = true, id must be set in object 'clientContact'`);
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertClientContactQaWithOnConflictMutation>(
        {
          operationType: 'insert',
          entityName: 'clientContact',
          objects: [objectForInsert as ClientContact_Insert_Input & ObjectWithId],
        }
      );
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, clientContactQa: pickClientContactQa(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], clientContactQa: pickClientContactQa(lazyMutation[1].data) }];
}

// Types
type InsertClientContactQaObjectsMutationResult = FetchResult<
  InsertClientContactQaMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertClientContactQaObjectsMutationResultEx = InsertClientContactQaMutationResult &
  ClientContactQaObjectsHookResultEx;

type PickInsertClientContactQaObjectsFn = (
  mutation: InsertClientContactQaMutation | null | undefined
) => ClientContactQaFragment[];
type InsertClientContactQaObjectsLazyMutationFn = MutationTuple<
  InsertClientContactQaMutation,
  InsertClientContactQaMutationVariables
>;
type InsertClientContactQaObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<InsertClientContactQaMutation, InsertClientContactQaMutationVariables>
) => Promise<InsertClientContactQaObjectsMutationResultEx>;
export type InsertClientContactQaObjectsLazyMutationReturn = [
  InsertClientContactQaObjectsWrappedLazyMutationFn,
  InsertClientContactQaObjectsMutationResultEx
];

// Function
function useInsertClientContactQaObjects(
  options?: Omit<MutationHookOptions<InsertClientContactQaMutation, InsertClientContactQaMutationVariables>, 'mutation'>
): InsertClientContactQaObjectsLazyMutationReturn {
  const lazyMutation: InsertClientContactQaObjectsLazyMutationFn = useMutation<
    InsertClientContactQaMutation,
    InsertClientContactQaMutationVariables
  >(InsertClientContactQaDocument, options);
  const pickObjects: PickInsertClientContactQaObjectsFn = (
    mutation: InsertClientContactQaMutation | null | undefined
  ) => {
    return mutation?.insert_clientContact?.returning || [];
  };

  const wrappedLazyMutation: InsertClientContactQaObjectsWrappedLazyMutationFn = async (options) => {
    if (options && options.variables && options.variables.objects)
      options.variables.objects = options.variables.objects.map((objectItem) =>
        stripInsertInputClientFields({ input: objectItem })
      );
    const fetchResult: InsertClientContactQaObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Update Hooks
 */

type UpdateClientContactQaByIdMutationResult = FetchResult<
  UpdateClientContactQaByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateClientContactQaByIdMutationResultEx = UpdateClientContactQaByIdMutationResult &
  ClientContactQaByIdHookResultEx;

type PickUpdateClientContactQaByIdFn = (
  mutation: UpdateClientContactQaByIdMutation | null | undefined
) => ClientContactQaFragment | null | undefined;
type UpdateClientContactQaByIdLazyMutationFn = MutationTuple<
  UpdateClientContactQaByIdMutation,
  UpdateClientContactQaByIdMutationVariables
>;
type UpdateClientContactQaByIdWrappedLazyMutationFn = ({
  clientContactId,
  set,
  autoOptimisticResponse,
  options,
}: {
  clientContactId: string;
  set: ClientContact_Set_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<UpdateClientContactQaByIdMutation, UpdateClientContactQaByIdMutationVariables>,
    'variables'
  >;
}) => Promise<UpdateClientContactQaByIdMutationResultEx>;
export type UpdateClientContactQaByIdLazyMutationReturn = [
  UpdateClientContactQaByIdWrappedLazyMutationFn,
  UpdateClientContactQaByIdMutationResultEx
];

function useUpdateClientContactQaById(
  options?: Omit<
    MutationHookOptions<UpdateClientContactQaByIdMutation, UpdateClientContactQaByIdMutationVariables>,
    'mutation' | 'variables'
  >
): UpdateClientContactQaByIdLazyMutationReturn {
  const lazyMutation: UpdateClientContactQaByIdLazyMutationFn = useMutation<
    UpdateClientContactQaByIdMutation,
    UpdateClientContactQaByIdMutationVariables
  >(UpdateClientContactQaByIdDocument, options);

  const pickClientContactQa: PickUpdateClientContactQaByIdFn = (mutation) => {
    return mutation?.update_clientContact?.returning && mutation.update_clientContact!.returning[0];
  };

  const wrappedLazyMutation: UpdateClientContactQaByIdWrappedLazyMutationFn = async ({
    clientContactId,
    set,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<
      UpdateClientContactQaByIdMutation,
      UpdateClientContactQaByIdMutationVariables
    > = { variables: { id: clientContactId, set }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateClientContactQaByIdMutation>({
        operationType: 'update',
        entityName: 'clientContact',
        objects: [{ id: clientContactId, ...set }],
      });
    }

    const fetchResult: UpdateClientContactQaByIdMutationResult = await lazyMutation[0]({
      variables: { id: clientContactId, set },
      ...mutationOptions,
    });
    return { ...fetchResult, clientContactQa: pickClientContactQa(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], clientContactQa: pickClientContactQa(lazyMutation[1].data) }];
}

//
//

// Types
type UpdateClientContactQaObjectsMutationResult = FetchResult<
  UpdateClientContactQaMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateClientContactQaObjectsMutationResultEx = UpdateClientContactQaObjectsMutationResult &
  ClientContactQaObjectsHookResultEx;

// Function
type PickUpdateClientContactQaObjectsFn = (
  mutation: UpdateClientContactQaMutation | null | undefined
) => ClientContactQaFragment[];
type UpdateClientContactQaObjectsLazyMutationFn = MutationTuple<
  UpdateClientContactQaMutation,
  UpdateClientContactQaMutationVariables
>;
type UpdateClientContactQaObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<UpdateClientContactQaMutation, UpdateClientContactQaMutationVariables>
) => Promise<UpdateClientContactQaObjectsMutationResultEx>;
export type UpdateClientContactQaObjectsLazyMutationReturn = [
  UpdateClientContactQaObjectsWrappedLazyMutationFn,
  UpdateClientContactQaObjectsMutationResultEx
];

function useUpdateClientContactQaObjects(
  options?: Omit<MutationHookOptions<UpdateClientContactQaMutation, UpdateClientContactQaMutationVariables>, 'mutation'>
): UpdateClientContactQaObjectsLazyMutationReturn {
  const lazyMutation: UpdateClientContactQaObjectsLazyMutationFn = useMutation<
    UpdateClientContactQaMutation,
    UpdateClientContactQaMutationVariables
  >(UpdateClientContactQaDocument, options);

  const pickObjects: PickUpdateClientContactQaObjectsFn = (
    mutation: UpdateClientContactQaMutation | null | undefined
  ) => {
    return mutation?.update_clientContact?.returning || [];
  };

  const wrappedLazyMutation: UpdateClientContactQaObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: UpdateClientContactQaObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

// ClientContactQa Fragment Helper Object
//------------------------------------------------

export const ClientContactQaFragmentGQLHooks = {
  useQueryById: useQueryClientContactQaById,
  useQueryByIdLazy: useQueryClientContactQaByIdLazy,
  useQueryObjects: useQueryClientContactQaObjects,
  useQueryObjectsLazy: useQueryClientContactQaObjectsLazy,
  useSubscribeToById: useSubscribeToClientContactQaById,
  useSubscribeToObjects: useSubscribeToClientContactQaObjects,
  useInsert: useInsertClientContactQa,
  useInsertWithOnConflict: useInsertClientContactQaWithOnConflict,
  useInsertObjects: useInsertClientContactQaObjects,
  useUpdateById: useUpdateClientContactQaById,
  useUpdateObjects: useUpdateClientContactQaObjects,
};

// crmContactEnrichmentHistory REACT
//------------------------------------------------

export type CrmContactEnrichmentHistoryByIdHookResultEx = {
  crmContactEnrichmentHistory: CrmContactEnrichmentHistoryFragment | null | undefined;
};
export type CrmContactEnrichmentHistoryObjectsHookResultEx = { objects: CrmContactEnrichmentHistoryFragment[] };

/**
 *  Query Hook
 */

// Types
type QueryCrmContactEnrichmentHistoryByIdResult = LazyQueryResult<
  QueryCrmContactEnrichmentHistoryByIdQuery,
  QueryCrmContactEnrichmentHistoryByIdQueryVariables
>;
type QueryCrmContactEnrichmentHistoryByIdSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<
          QueryCrmContactEnrichmentHistoryByIdQuery,
          QueryCrmContactEnrichmentHistoryByIdQueryVariables,
          QueryCrmContactEnrichmentHistoryByIdQuery
        >,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryCrmContactEnrichmentHistoryByIdResultEx = Omit<
  QueryCrmContactEnrichmentHistoryByIdResult,
  'subscribeToMore'
> & {
  subscribeToMore: QueryCrmContactEnrichmentHistoryByIdSubScribeToMore;
} & CrmContactEnrichmentHistoryByIdHookResultEx;

// Function
function useQueryCrmContactEnrichmentHistoryById({
  crmContactEnrichmentHistoryId,
  options,
}: {
  crmContactEnrichmentHistoryId: string;
  options?: Omit<
    QueryHookOptions<QueryCrmContactEnrichmentHistoryByIdQuery, QueryCrmContactEnrichmentHistoryByIdQueryVariables>,
    'query' | 'variables'
  >;
}): QueryCrmContactEnrichmentHistoryByIdResultEx {
  const _query: QueryCrmContactEnrichmentHistoryByIdResult = useQuery<
    QueryCrmContactEnrichmentHistoryByIdQuery,
    QueryCrmContactEnrichmentHistoryByIdQueryVariables
  >(QueryCrmContactEnrichmentHistoryByIdDocument, { variables: { crmContactEnrichmentHistoryId }, ...options });

  const typedSubscribeToMore: QueryCrmContactEnrichmentHistoryByIdSubScribeToMore = (options) => {
    _query.subscribeToMore({
      document: QueryCrmContactEnrichmentHistoryByIdDocument,
      variables: { crmContactEnrichmentHistoryId } as QueryCrmContactEnrichmentHistoryByIdQueryVariables,
      ...options,
    });
  };
  const { subscribeToMore, ...query } = _query;
  return {
    ...query,
    subscribeToMore: typedSubscribeToMore,
    crmContactEnrichmentHistory: query?.data?.crmContactEnrichmentHistory_by_pk,
  };
}

/**
 *  Lazy Query Hook
 */

// Types
type PickQueryCrmContactEnrichmentHistoryByIdFn = (
  query: QueryCrmContactEnrichmentHistoryByIdQuery | null | undefined
) => CrmContactEnrichmentHistoryFragment | null | undefined;
type QueryCrmContactEnrichmentHistoryByIdLazyFn = [
  (options?: QueryLazyOptions<QueryCrmContactEnrichmentHistoryByIdQueryVariables> | undefined) => void,
  QueryCrmContactEnrichmentHistoryByIdResult
];
type QueryCrmContactEnrichmentHistoryByIdWrappedLazyFn = (
  options: Omit<QueryLazyOptions<QueryCrmContactEnrichmentHistoryByIdQueryVariables>, 'variables'>
) => void;
export type QueryCrmContactEnrichmentHistoryByIdLazyReturn = [
  QueryCrmContactEnrichmentHistoryByIdWrappedLazyFn,
  QueryCrmContactEnrichmentHistoryByIdResultEx
];

// Function
function useQueryCrmContactEnrichmentHistoryByIdLazy({
  crmContactEnrichmentHistoryId,
  options,
}: {
  crmContactEnrichmentHistoryId: string;
  options?: Omit<
    QueryHookOptions<QueryCrmContactEnrichmentHistoryByIdQuery, QueryCrmContactEnrichmentHistoryByIdQueryVariables>,
    'query' | 'variables'
  >;
}): QueryCrmContactEnrichmentHistoryByIdLazyReturn {
  const lazyQuery: QueryCrmContactEnrichmentHistoryByIdLazyFn = useLazyQuery<
    QueryCrmContactEnrichmentHistoryByIdQuery,
    QueryCrmContactEnrichmentHistoryByIdQueryVariables
  >(QueryCrmContactEnrichmentHistoryByIdDocument, options);

  // Setting up typed version of lazyQuery
  const pickQueryCrmContactEnrichmentHistoryById: PickQueryCrmContactEnrichmentHistoryByIdFn = (query) => {
    return query && query.crmContactEnrichmentHistory_by_pk;
  };
  const wrappedLazyQuery: QueryCrmContactEnrichmentHistoryByIdWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryCrmContactEnrichmentHistoryByIdSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({
        document: QueryCrmContactEnrichmentHistoryByIdDocument,
        variables: { crmContactEnrichmentHistoryId } as QueryCrmContactEnrichmentHistoryByIdQueryVariables,
        ...options,
      });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    {
      ...lazyQueryResult,
      subscribeToMore: typedSubcribeToMore,
      crmContactEnrichmentHistory: pickQueryCrmContactEnrichmentHistoryById(lazyQuery[1].data),
    },
  ];
}

/**
 *  Query Collection Hook
 */

// Types
export type QueryCrmContactEnrichmentHistoryObjectsResult = LazyQueryResult<
  QueryCrmContactEnrichmentHistoryObjectsQuery,
  QueryCrmContactEnrichmentHistoryObjectsQueryVariables
>;
type QueryCrmContactEnrichmentHistoryObjectsSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<
          QueryCrmContactEnrichmentHistoryObjectsQuery,
          QueryCrmContactEnrichmentHistoryObjectsQueryVariables,
          QueryCrmContactEnrichmentHistoryObjectsQuery
        >,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryCrmContactEnrichmentHistoryObjectsResultEx = Omit<
  QueryCrmContactEnrichmentHistoryObjectsResult,
  'subscribeToMore'
> & {
  subscribeToMore: QueryCrmContactEnrichmentHistoryObjectsSubScribeToMore;
} & CrmContactEnrichmentHistoryObjectsHookResultEx;

// Function
function useQueryCrmContactEnrichmentHistoryObjects(
  options: Omit<
    QueryHookOptions<
      QueryCrmContactEnrichmentHistoryObjectsQuery,
      QueryCrmContactEnrichmentHistoryObjectsQueryVariables
    >,
    'query'
  >
): QueryCrmContactEnrichmentHistoryObjectsResultEx {
  const _query: QueryCrmContactEnrichmentHistoryObjectsResult = useQuery<
    QueryCrmContactEnrichmentHistoryObjectsQuery,
    QueryCrmContactEnrichmentHistoryObjectsQueryVariables
  >(QueryCrmContactEnrichmentHistoryObjectsDocument, options);

  const typedSubscribeToMore: QueryCrmContactEnrichmentHistoryObjectsSubScribeToMore = (options) => {
    _query.subscribeToMore({ document: QueryCrmContactEnrichmentHistoryObjectsDocument, ...options });
  };
  const { subscribeToMore, ...query } = _query;

  return { ...query, subscribeToMore: typedSubscribeToMore, objects: query?.data?.crmContactEnrichmentHistory || [] };
}

/**
 *  Lazy Query Collection Hook
 */

// Types
type PickQueryCrmContactEnrichmentHistoryObjectsFn = (
  query: QueryCrmContactEnrichmentHistoryObjectsQuery | null | undefined
) => CrmContactEnrichmentHistoryFragment[];
type QueryCrmContactEnrichmentHistoryObjectsLazyFn = [
  (options?: QueryLazyOptions<QueryCrmContactEnrichmentHistoryObjectsQueryVariables> | undefined) => void,
  QueryCrmContactEnrichmentHistoryObjectsResult
];
type QueryCrmContactEnrichmentHistoryObjectsWrappedLazyFn = (
  options?: QueryLazyOptions<QueryCrmContactEnrichmentHistoryObjectsQueryVariables>
) => void;
export type QueryCrmContactEnrichmentHistoryObjectsLazyReturn = [
  QueryCrmContactEnrichmentHistoryObjectsWrappedLazyFn,
  QueryCrmContactEnrichmentHistoryObjectsResultEx
];

// Function
function useQueryCrmContactEnrichmentHistoryObjectsLazy(
  options?: Omit<
    LazyQueryHookOptions<
      QueryCrmContactEnrichmentHistoryObjectsQuery,
      QueryCrmContactEnrichmentHistoryObjectsQueryVariables
    >,
    'query'
  >
): QueryCrmContactEnrichmentHistoryObjectsLazyReturn {
  const lazyQuery: QueryCrmContactEnrichmentHistoryObjectsLazyFn = useLazyQuery<
    QueryCrmContactEnrichmentHistoryObjectsQuery,
    QueryCrmContactEnrichmentHistoryObjectsQueryVariables
  >(QueryCrmContactEnrichmentHistoryObjectsDocument, options);

  // Setting up typed version of lazyQuery
  const pickObjects: PickQueryCrmContactEnrichmentHistoryObjectsFn = (
    query: QueryCrmContactEnrichmentHistoryObjectsQuery | null | undefined
  ) => {
    return query?.crmContactEnrichmentHistory || [];
  };
  const wrappedLazyQuery: QueryCrmContactEnrichmentHistoryObjectsWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryCrmContactEnrichmentHistoryObjectsSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({ document: QueryCrmContactEnrichmentHistoryObjectsDocument, ...options });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    { ...lazyQueryResult, subscribeToMore: typedSubcribeToMore, objects: pickObjects(lazyQuery[1].data) },
  ];
}

/**
 *  Subscription Hook
 */

// Types
type SubscribeToCrmContactEnrichmentHistoryByIdResult = {
  variables?: SubscribeToCrmContactEnrichmentHistoryByIdSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToCrmContactEnrichmentHistoryByIdSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToCrmContactEnrichmentHistoryByIdResultEx = SubscribeToCrmContactEnrichmentHistoryByIdResult &
  CrmContactEnrichmentHistoryByIdHookResultEx;

// Function
function useSubscribeToCrmContactEnrichmentHistoryById({
  crmContactEnrichmentHistoryId,
  options,
}: {
  crmContactEnrichmentHistoryId: string;
  options?: Omit<
    SubscriptionHookOptions<
      SubscribeToCrmContactEnrichmentHistoryByIdSubscription,
      SubscribeToCrmContactEnrichmentHistoryByIdSubscriptionVariables
    >,
    'query' | 'variables'
  >;
}): SubscribeToCrmContactEnrichmentHistoryByIdResultEx {
  const subscription: SubscribeToCrmContactEnrichmentHistoryByIdResult = useSubscription<
    SubscribeToCrmContactEnrichmentHistoryByIdSubscription,
    SubscribeToCrmContactEnrichmentHistoryByIdSubscriptionVariables
  >(SubscribeToCrmContactEnrichmentHistoryByIdDocument, { variables: { crmContactEnrichmentHistoryId }, ...options });
  return { ...subscription, crmContactEnrichmentHistory: subscription?.data?.crmContactEnrichmentHistory_by_pk };
}

/**
 *  Subscription Collection Hook
 */

// Types
export type SubscribeToCrmContactEnrichmentHistoryObjectsResult = {
  variables?: SubscribeToCrmContactEnrichmentHistoryObjectsSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToCrmContactEnrichmentHistoryObjectsSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToCrmContactEnrichmentHistoryObjectsResultEx = SubscribeToCrmContactEnrichmentHistoryObjectsResult &
  CrmContactEnrichmentHistoryObjectsHookResultEx;

// Function
function useSubscribeToCrmContactEnrichmentHistoryObjects(
  options: Omit<
    SubscriptionHookOptions<
      SubscribeToCrmContactEnrichmentHistoryObjectsSubscription,
      SubscribeToCrmContactEnrichmentHistoryObjectsSubscriptionVariables
    >,
    'query'
  >
): SubscribeToCrmContactEnrichmentHistoryObjectsResultEx {
  const subscription: SubscribeToCrmContactEnrichmentHistoryObjectsResult = useSubscription<
    SubscribeToCrmContactEnrichmentHistoryObjectsSubscription,
    SubscribeToCrmContactEnrichmentHistoryObjectsSubscriptionVariables
  >(SubscribeToCrmContactEnrichmentHistoryObjectsDocument, options);
  return { ...subscription, objects: subscription?.data?.crmContactEnrichmentHistory || [] };
}

/**
 *  Insert Hooks
 */

// Types
type InsertCrmContactEnrichmentHistoryMutationResult = FetchResult<
  InsertCrmContactEnrichmentHistoryMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertCrmContactEnrichmentHistoryMutationResultEx = InsertCrmContactEnrichmentHistoryMutationResult &
  CrmContactEnrichmentHistoryByIdHookResultEx;

type PickInsertCrmContactEnrichmentHistoryFn = (
  mutation: InsertCrmContactEnrichmentHistoryMutation | null | undefined
) => CrmContactEnrichmentHistoryFragment | null | undefined;
type InsertCrmContactEnrichmentHistoryLazyMutationFn = MutationTuple<
  InsertCrmContactEnrichmentHistoryMutation,
  InsertCrmContactEnrichmentHistoryMutationVariables
>;
type InsertCrmContactEnrichmentHistoryWrappedLazyMutationFn = ({
  crmContactEnrichmentHistory,
  autoOptimisticResponse,
  options,
}: {
  crmContactEnrichmentHistory: CrmContactEnrichmentHistory_Insert_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<
      InsertCrmContactEnrichmentHistoryMutation,
      InsertCrmContactEnrichmentHistoryMutationVariables
    >,
    'variables'
  >;
}) => Promise<InsertCrmContactEnrichmentHistoryMutationResultEx>;
export type InsertCrmContactEnrichmentHistoryLazyMutationReturn = [
  InsertCrmContactEnrichmentHistoryWrappedLazyMutationFn,
  InsertCrmContactEnrichmentHistoryMutationResultEx
];

// Function
function useInsertCrmContactEnrichmentHistory(
  options?: Omit<
    MutationHookOptions<InsertCrmContactEnrichmentHistoryMutation, InsertCrmContactEnrichmentHistoryMutationVariables>,
    'mutation' | 'variables'
  >
): InsertCrmContactEnrichmentHistoryLazyMutationReturn {
  const lazyMutation: InsertCrmContactEnrichmentHistoryLazyMutationFn = useMutation<
    InsertCrmContactEnrichmentHistoryMutation,
    InsertCrmContactEnrichmentHistoryMutationVariables
  >(InsertCrmContactEnrichmentHistoryDocument, options);
  const pickCrmContactEnrichmentHistory: PickInsertCrmContactEnrichmentHistoryFn = (mutation) => {
    return (
      mutation?.insert_crmContactEnrichmentHistory?.returning &&
      mutation?.insert_crmContactEnrichmentHistory?.returning[0]
    );
  };

  const wrappedLazyMutation: InsertCrmContactEnrichmentHistoryWrappedLazyMutationFn = async ({
    crmContactEnrichmentHistory,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: crmContactEnrichmentHistory });
    const mutationOptions: MutationFunctionOptions<
      InsertCrmContactEnrichmentHistoryMutation,
      InsertCrmContactEnrichmentHistoryMutationVariables
    > = { variables: { objects: [objectForInsert] }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id)
        throw new Error(`if autoOptimisticResponse = true, id must be set in object 'crmContactEnrichmentHistory'`);
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertCrmContactEnrichmentHistoryMutation>(
        {
          operationType: 'insert',
          entityName: 'crmContactEnrichmentHistory',
          objects: [objectForInsert as CrmContactEnrichmentHistory_Insert_Input & ObjectWithId],
        }
      );
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, crmContactEnrichmentHistory: pickCrmContactEnrichmentHistory(fetchResult.data) };
  };

  return [
    wrappedLazyMutation,
    { ...lazyMutation[1], crmContactEnrichmentHistory: pickCrmContactEnrichmentHistory(lazyMutation[1].data) },
  ];
}

//
//

// Types
type InsertCrmContactEnrichmentHistoryWithOnConflictMutationResult = FetchResult<
  InsertCrmContactEnrichmentHistoryWithOnConflictMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertCrmContactEnrichmentHistoryWithOnConflictMutationResultEx = InsertCrmContactEnrichmentHistoryWithOnConflictMutationResult &
  CrmContactEnrichmentHistoryByIdHookResultEx;

type InsertCrmContactEnrichmentHistoryWithOnConflictLazyMutationFn = MutationTuple<
  InsertCrmContactEnrichmentHistoryWithOnConflictMutation,
  InsertCrmContactEnrichmentHistoryWithOnConflictMutationVariables
>;
type InsertCrmContactEnrichmentHistoryWithOnConflictWrappedLazyMutationFn = ({
  crmContactEnrichmentHistory,
  onConflict,
  autoOptimisticResponse,
  options,
}: {
  crmContactEnrichmentHistory: CrmContactEnrichmentHistory_Insert_Input;
  onConflict: CrmContactEnrichmentHistory_On_Conflict;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<
      InsertCrmContactEnrichmentHistoryWithOnConflictMutation,
      InsertCrmContactEnrichmentHistoryWithOnConflictMutationVariables
    >,
    'variables'
  >;
}) => Promise<InsertCrmContactEnrichmentHistoryWithOnConflictMutationResultEx>;
export type InsertCrmContactEnrichmentHistoryWithOnConflictLazyMutationReturn = [
  InsertCrmContactEnrichmentHistoryWithOnConflictWrappedLazyMutationFn,
  InsertCrmContactEnrichmentHistoryWithOnConflictMutationResultEx
];

// Function
function useInsertCrmContactEnrichmentHistoryWithOnConflict(
  options?: Omit<
    MutationHookOptions<
      InsertCrmContactEnrichmentHistoryWithOnConflictMutation,
      InsertCrmContactEnrichmentHistoryWithOnConflictMutationVariables
    >,
    'mutation' | 'variables'
  >
): InsertCrmContactEnrichmentHistoryWithOnConflictLazyMutationReturn {
  const lazyMutation: InsertCrmContactEnrichmentHistoryWithOnConflictLazyMutationFn = useMutation<
    InsertCrmContactEnrichmentHistoryWithOnConflictMutation,
    InsertCrmContactEnrichmentHistoryWithOnConflictMutationVariables
  >(InsertCrmContactEnrichmentHistoryWithOnConflictDocument, options);
  const pickCrmContactEnrichmentHistory: PickInsertCrmContactEnrichmentHistoryFn = (
    mutation: InsertCrmContactEnrichmentHistoryWithOnConflictMutation | null | undefined
  ) => {
    return (
      mutation?.insert_crmContactEnrichmentHistory?.returning &&
      mutation.insert_crmContactEnrichmentHistory.returning[0]
    );
  };

  const wrappedLazyMutation: InsertCrmContactEnrichmentHistoryWithOnConflictWrappedLazyMutationFn = async ({
    crmContactEnrichmentHistory,
    onConflict,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: crmContactEnrichmentHistory });
    const mutationOptions: MutationFunctionOptions<
      InsertCrmContactEnrichmentHistoryWithOnConflictMutation,
      InsertCrmContactEnrichmentHistoryWithOnConflictMutationVariables
    > = { variables: { objects: [objectForInsert], onConflict }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id)
        throw new Error(`if autoOptimisticResponse = true, id must be set in object 'crmContactEnrichmentHistory'`);
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertCrmContactEnrichmentHistoryWithOnConflictMutation>(
        {
          operationType: 'insert',
          entityName: 'crmContactEnrichmentHistory',
          objects: [objectForInsert as CrmContactEnrichmentHistory_Insert_Input & ObjectWithId],
        }
      );
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, crmContactEnrichmentHistory: pickCrmContactEnrichmentHistory(fetchResult.data) };
  };

  return [
    wrappedLazyMutation,
    { ...lazyMutation[1], crmContactEnrichmentHistory: pickCrmContactEnrichmentHistory(lazyMutation[1].data) },
  ];
}

// Types
type InsertCrmContactEnrichmentHistoryObjectsMutationResult = FetchResult<
  InsertCrmContactEnrichmentHistoryMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertCrmContactEnrichmentHistoryObjectsMutationResultEx = InsertCrmContactEnrichmentHistoryMutationResult &
  CrmContactEnrichmentHistoryObjectsHookResultEx;

type PickInsertCrmContactEnrichmentHistoryObjectsFn = (
  mutation: InsertCrmContactEnrichmentHistoryMutation | null | undefined
) => CrmContactEnrichmentHistoryFragment[];
type InsertCrmContactEnrichmentHistoryObjectsLazyMutationFn = MutationTuple<
  InsertCrmContactEnrichmentHistoryMutation,
  InsertCrmContactEnrichmentHistoryMutationVariables
>;
type InsertCrmContactEnrichmentHistoryObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<
    InsertCrmContactEnrichmentHistoryMutation,
    InsertCrmContactEnrichmentHistoryMutationVariables
  >
) => Promise<InsertCrmContactEnrichmentHistoryObjectsMutationResultEx>;
export type InsertCrmContactEnrichmentHistoryObjectsLazyMutationReturn = [
  InsertCrmContactEnrichmentHistoryObjectsWrappedLazyMutationFn,
  InsertCrmContactEnrichmentHistoryObjectsMutationResultEx
];

// Function
function useInsertCrmContactEnrichmentHistoryObjects(
  options?: Omit<
    MutationHookOptions<InsertCrmContactEnrichmentHistoryMutation, InsertCrmContactEnrichmentHistoryMutationVariables>,
    'mutation'
  >
): InsertCrmContactEnrichmentHistoryObjectsLazyMutationReturn {
  const lazyMutation: InsertCrmContactEnrichmentHistoryObjectsLazyMutationFn = useMutation<
    InsertCrmContactEnrichmentHistoryMutation,
    InsertCrmContactEnrichmentHistoryMutationVariables
  >(InsertCrmContactEnrichmentHistoryDocument, options);
  const pickObjects: PickInsertCrmContactEnrichmentHistoryObjectsFn = (
    mutation: InsertCrmContactEnrichmentHistoryMutation | null | undefined
  ) => {
    return mutation?.insert_crmContactEnrichmentHistory?.returning || [];
  };

  const wrappedLazyMutation: InsertCrmContactEnrichmentHistoryObjectsWrappedLazyMutationFn = async (options) => {
    if (options && options.variables && options.variables.objects)
      options.variables.objects = options.variables.objects.map((objectItem) =>
        stripInsertInputClientFields({ input: objectItem })
      );
    const fetchResult: InsertCrmContactEnrichmentHistoryObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Update Hooks
 */

type UpdateCrmContactEnrichmentHistoryByIdMutationResult = FetchResult<
  UpdateCrmContactEnrichmentHistoryByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateCrmContactEnrichmentHistoryByIdMutationResultEx = UpdateCrmContactEnrichmentHistoryByIdMutationResult &
  CrmContactEnrichmentHistoryByIdHookResultEx;

type PickUpdateCrmContactEnrichmentHistoryByIdFn = (
  mutation: UpdateCrmContactEnrichmentHistoryByIdMutation | null | undefined
) => CrmContactEnrichmentHistoryFragment | null | undefined;
type UpdateCrmContactEnrichmentHistoryByIdLazyMutationFn = MutationTuple<
  UpdateCrmContactEnrichmentHistoryByIdMutation,
  UpdateCrmContactEnrichmentHistoryByIdMutationVariables
>;
type UpdateCrmContactEnrichmentHistoryByIdWrappedLazyMutationFn = ({
  crmContactEnrichmentHistoryId,
  set,
  autoOptimisticResponse,
  options,
}: {
  crmContactEnrichmentHistoryId: string;
  set: CrmContactEnrichmentHistory_Set_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<
      UpdateCrmContactEnrichmentHistoryByIdMutation,
      UpdateCrmContactEnrichmentHistoryByIdMutationVariables
    >,
    'variables'
  >;
}) => Promise<UpdateCrmContactEnrichmentHistoryByIdMutationResultEx>;
export type UpdateCrmContactEnrichmentHistoryByIdLazyMutationReturn = [
  UpdateCrmContactEnrichmentHistoryByIdWrappedLazyMutationFn,
  UpdateCrmContactEnrichmentHistoryByIdMutationResultEx
];

function useUpdateCrmContactEnrichmentHistoryById(
  options?: Omit<
    MutationHookOptions<
      UpdateCrmContactEnrichmentHistoryByIdMutation,
      UpdateCrmContactEnrichmentHistoryByIdMutationVariables
    >,
    'mutation' | 'variables'
  >
): UpdateCrmContactEnrichmentHistoryByIdLazyMutationReturn {
  const lazyMutation: UpdateCrmContactEnrichmentHistoryByIdLazyMutationFn = useMutation<
    UpdateCrmContactEnrichmentHistoryByIdMutation,
    UpdateCrmContactEnrichmentHistoryByIdMutationVariables
  >(UpdateCrmContactEnrichmentHistoryByIdDocument, options);

  const pickCrmContactEnrichmentHistory: PickUpdateCrmContactEnrichmentHistoryByIdFn = (mutation) => {
    return (
      mutation?.update_crmContactEnrichmentHistory?.returning &&
      mutation.update_crmContactEnrichmentHistory!.returning[0]
    );
  };

  const wrappedLazyMutation: UpdateCrmContactEnrichmentHistoryByIdWrappedLazyMutationFn = async ({
    crmContactEnrichmentHistoryId,
    set,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<
      UpdateCrmContactEnrichmentHistoryByIdMutation,
      UpdateCrmContactEnrichmentHistoryByIdMutationVariables
    > = { variables: { id: crmContactEnrichmentHistoryId, set }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateCrmContactEnrichmentHistoryByIdMutation>(
        {
          operationType: 'update',
          entityName: 'crmContactEnrichmentHistory',
          objects: [{ id: crmContactEnrichmentHistoryId, ...set }],
        }
      );
    }

    const fetchResult: UpdateCrmContactEnrichmentHistoryByIdMutationResult = await lazyMutation[0]({
      variables: { id: crmContactEnrichmentHistoryId, set },
      ...mutationOptions,
    });
    return { ...fetchResult, crmContactEnrichmentHistory: pickCrmContactEnrichmentHistory(fetchResult.data) };
  };

  return [
    wrappedLazyMutation,
    { ...lazyMutation[1], crmContactEnrichmentHistory: pickCrmContactEnrichmentHistory(lazyMutation[1].data) },
  ];
}

//
//

// Types
type UpdateCrmContactEnrichmentHistoryObjectsMutationResult = FetchResult<
  UpdateCrmContactEnrichmentHistoryMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateCrmContactEnrichmentHistoryObjectsMutationResultEx = UpdateCrmContactEnrichmentHistoryObjectsMutationResult &
  CrmContactEnrichmentHistoryObjectsHookResultEx;

// Function
type PickUpdateCrmContactEnrichmentHistoryObjectsFn = (
  mutation: UpdateCrmContactEnrichmentHistoryMutation | null | undefined
) => CrmContactEnrichmentHistoryFragment[];
type UpdateCrmContactEnrichmentHistoryObjectsLazyMutationFn = MutationTuple<
  UpdateCrmContactEnrichmentHistoryMutation,
  UpdateCrmContactEnrichmentHistoryMutationVariables
>;
type UpdateCrmContactEnrichmentHistoryObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<
    UpdateCrmContactEnrichmentHistoryMutation,
    UpdateCrmContactEnrichmentHistoryMutationVariables
  >
) => Promise<UpdateCrmContactEnrichmentHistoryObjectsMutationResultEx>;
export type UpdateCrmContactEnrichmentHistoryObjectsLazyMutationReturn = [
  UpdateCrmContactEnrichmentHistoryObjectsWrappedLazyMutationFn,
  UpdateCrmContactEnrichmentHistoryObjectsMutationResultEx
];

function useUpdateCrmContactEnrichmentHistoryObjects(
  options?: Omit<
    MutationHookOptions<UpdateCrmContactEnrichmentHistoryMutation, UpdateCrmContactEnrichmentHistoryMutationVariables>,
    'mutation'
  >
): UpdateCrmContactEnrichmentHistoryObjectsLazyMutationReturn {
  const lazyMutation: UpdateCrmContactEnrichmentHistoryObjectsLazyMutationFn = useMutation<
    UpdateCrmContactEnrichmentHistoryMutation,
    UpdateCrmContactEnrichmentHistoryMutationVariables
  >(UpdateCrmContactEnrichmentHistoryDocument, options);

  const pickObjects: PickUpdateCrmContactEnrichmentHistoryObjectsFn = (
    mutation: UpdateCrmContactEnrichmentHistoryMutation | null | undefined
  ) => {
    return mutation?.update_crmContactEnrichmentHistory?.returning || [];
  };

  const wrappedLazyMutation: UpdateCrmContactEnrichmentHistoryObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: UpdateCrmContactEnrichmentHistoryObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Delete Hooks
 */

// Types
type RemoveCrmContactEnrichmentHistoryModelByIdFetchResult = FetchResult<
  RemoveCrmContactEnrichmentHistoryModelByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveCrmContactEnrichmentHistoryModelByIdMutationResultEx = RemoveCrmContactEnrichmentHistoryModelByIdFetchResult &
  RemoveEntitiesQueryHookResultEx;

// Function
type PickRemoveCrmContactEnrichmentHistoryModelFn = (
  mutation: RemoveCrmContactEnrichmentHistoryModelByIdMutation | null | undefined
) => number;
type RemoveCrmContactEnrichmentHistoryModelLazyMutationFn = MutationTuple<
  RemoveCrmContactEnrichmentHistoryModelByIdMutation,
  RemoveCrmContactEnrichmentHistoryModelByIdMutationVariables
>;
type RemoveCrmContactEnrichmentHistoryModelWrappedLazyMutationFn = ({
  crmContactEnrichmentHistoryId,
  autoOptimisticResponse,
  options,
}: {
  crmContactEnrichmentHistoryId: string;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<
      RemoveCrmContactEnrichmentHistoryModelByIdMutation,
      RemoveCrmContactEnrichmentHistoryModelByIdMutationVariables
    >,
    'variables'
  >;
}) => Promise<RemoveCrmContactEnrichmentHistoryModelByIdMutationResultEx>;
export type RemoveCrmContactEnrichmentHistoryModelLazyMutationReturn = [
  RemoveCrmContactEnrichmentHistoryModelWrappedLazyMutationFn,
  RemoveCrmContactEnrichmentHistoryModelByIdMutationResultEx
];

function useRemoveCrmContactEnrichmentHistoryModelById(
  options?: Omit<
    MutationHookOptions<
      RemoveCrmContactEnrichmentHistoryModelByIdMutation,
      RemoveCrmContactEnrichmentHistoryModelByIdMutationVariables
    >,
    'mutation' | 'variables'
  >
) {
  const lazyMutation: RemoveCrmContactEnrichmentHistoryModelLazyMutationFn = useMutation<
    RemoveCrmContactEnrichmentHistoryModelByIdMutation,
    RemoveCrmContactEnrichmentHistoryModelByIdMutationVariables
  >(RemoveCrmContactEnrichmentHistoryModelByIdDocument, options);

  const pickAffectedRows: PickRemoveCrmContactEnrichmentHistoryModelFn = (
    mutation: RemoveCrmContactEnrichmentHistoryModelByIdMutation | null | undefined
  ) => {
    return mutation?.delete_crmContactEnrichmentHistory?.affected_rows || 0;
  };

  const wrappedLazyMutation: RemoveCrmContactEnrichmentHistoryModelWrappedLazyMutationFn = async ({
    crmContactEnrichmentHistoryId,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<
      RemoveCrmContactEnrichmentHistoryModelMutation,
      RemoveCrmContactEnrichmentHistoryModelByIdMutationVariables
    > = { variables: { id: crmContactEnrichmentHistoryId }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<RemoveCrmContactEnrichmentHistoryModelMutation>(
        {
          operationType: 'delete',
          entityName: 'crmContactEnrichmentHistory',
          objects: [{ id: crmContactEnrichmentHistoryId }],
        }
      );
    }
    if (!options || !options.update) {
      mutationOptions.update = generateUpdateFunctionForMutation<RemoveCrmContactEnrichmentHistoryModelByIdMutation>({
        operationType: 'delete',
        entityName: 'crmContactEnrichmentHistory',
        entityId: crmContactEnrichmentHistoryId,
      });
    }

    const fetchResult: RemoveCrmContactEnrichmentHistoryModelByIdFetchResult = await lazyMutation[0](mutationOptions);
    return { ...fetchResult, affected_rows: pickAffectedRows(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], affected_rows: pickAffectedRows(lazyMutation[1].data) }] as [
    typeof wrappedLazyMutation,
    typeof lazyMutation[1] & { affected_rows: ReturnType<typeof pickAffectedRows> }
  ];
}

//
//

// Types
type RemoveCrmContactEnrichmentHistoryModelObjectsMutationResult = FetchResult<
  RemoveCrmContactEnrichmentHistoryModelMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveCrmContactEnrichmentHistoryModelObjectsMutationResultEx = RemoveCrmContactEnrichmentHistoryModelObjectsMutationResult &
  RemoveEntitiesQueryHookResultEx;

// Function
type PickRemoveCrmContactEnrichmentHistoryModelObjectsFn = (
  mutation: RemoveCrmContactEnrichmentHistoryModelMutation | null | undefined
) => number;
type RemoveCrmContactEnrichmentHistoryModelObjectsLazyMutationFn = MutationTuple<
  RemoveCrmContactEnrichmentHistoryModelMutation,
  RemoveCrmContactEnrichmentHistoryModelMutationVariables
>;
type RemoveCrmContactEnrichmentHistoryModelObjectsWrappedLazyMutationFn = (
  options: MutationFunctionOptions<
    RemoveCrmContactEnrichmentHistoryModelMutation,
    RemoveCrmContactEnrichmentHistoryModelMutationVariables
  >
) => Promise<RemoveCrmContactEnrichmentHistoryModelObjectsMutationResultEx>;
export type RemoveCrmContactEnrichmentHistoryModelObjectsLazyMutationReturn = [
  RemoveCrmContactEnrichmentHistoryModelObjectsWrappedLazyMutationFn,
  RemoveCrmContactEnrichmentHistoryModelObjectsMutationResultEx
];

function useRemoveCrmContactEnrichmentHistoryModelObjects(
  options?: Omit<
    MutationHookOptions<
      RemoveCrmContactEnrichmentHistoryModelMutation,
      RemoveCrmContactEnrichmentHistoryModelMutationVariables
    >,
    'mutation'
  >
): RemoveCrmContactEnrichmentHistoryModelObjectsLazyMutationReturn {
  const lazyMutation = useMutation<
    RemoveCrmContactEnrichmentHistoryModelMutation,
    RemoveCrmContactEnrichmentHistoryModelMutationVariables
  >(RemoveCrmContactEnrichmentHistoryModelDocument, options);

  const pickAffectedRows: PickRemoveCrmContactEnrichmentHistoryModelObjectsFn = (
    mutation: RemoveCrmContactEnrichmentHistoryModelMutation | null | undefined
  ) => {
    return mutation?.delete_crmContactEnrichmentHistory?.affected_rows || 0;
  };

  const wrappedLazyMutation: RemoveCrmContactEnrichmentHistoryModelObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: RemoveCrmContactEnrichmentHistoryModelObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, affected_rows: pickAffectedRows(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], affected_rows: pickAffectedRows(lazyMutation[1].data) }];
}

// CrmContactEnrichmentHistory Fragment Helper Object
//------------------------------------------------

export const CrmContactEnrichmentHistoryFragmentGQLHooks = {
  useQueryById: useQueryCrmContactEnrichmentHistoryById,
  useQueryByIdLazy: useQueryCrmContactEnrichmentHistoryByIdLazy,
  useQueryObjects: useQueryCrmContactEnrichmentHistoryObjects,
  useQueryObjectsLazy: useQueryCrmContactEnrichmentHistoryObjectsLazy,
  useSubscribeToById: useSubscribeToCrmContactEnrichmentHistoryById,
  useSubscribeToObjects: useSubscribeToCrmContactEnrichmentHistoryObjects,
  useInsert: useInsertCrmContactEnrichmentHistory,
  useInsertWithOnConflict: useInsertCrmContactEnrichmentHistoryWithOnConflict,
  useInsertObjects: useInsertCrmContactEnrichmentHistoryObjects,
  useUpdateById: useUpdateCrmContactEnrichmentHistoryById,
  useUpdateObjects: useUpdateCrmContactEnrichmentHistoryObjects,
};

// crmContactEnrichmentHistory MODEL HOOKS OBJECT
//------------------------------------------------

export const CrmContactEnrichmentHistoryModelGQLHooks = {
  useRemoveById: useRemoveCrmContactEnrichmentHistoryModelById,
  useRemoveObjects: useRemoveCrmContactEnrichmentHistoryModelObjects,
};

// clientContactCrmData REACT
//------------------------------------------------

export type ClientContactCrmDataByIdHookResultEx = {
  clientContactCrmData: ClientContactCrmDataFragment | null | undefined;
};
export type ClientContactCrmDataObjectsHookResultEx = { objects: ClientContactCrmDataFragment[] };

/**
 *  Query Hook
 */

// Types
type QueryClientContactCrmDataByIdResult = LazyQueryResult<
  QueryClientContactCrmDataByIdQuery,
  QueryClientContactCrmDataByIdQueryVariables
>;
type QueryClientContactCrmDataByIdSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<
          QueryClientContactCrmDataByIdQuery,
          QueryClientContactCrmDataByIdQueryVariables,
          QueryClientContactCrmDataByIdQuery
        >,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryClientContactCrmDataByIdResultEx = Omit<QueryClientContactCrmDataByIdResult, 'subscribeToMore'> & {
  subscribeToMore: QueryClientContactCrmDataByIdSubScribeToMore;
} & ClientContactCrmDataByIdHookResultEx;

// Function
function useQueryClientContactCrmDataById({
  clientContactCrmDataId,
  options,
}: {
  clientContactCrmDataId: string;
  options?: Omit<
    QueryHookOptions<QueryClientContactCrmDataByIdQuery, QueryClientContactCrmDataByIdQueryVariables>,
    'query' | 'variables'
  >;
}): QueryClientContactCrmDataByIdResultEx {
  const _query: QueryClientContactCrmDataByIdResult = useQuery<
    QueryClientContactCrmDataByIdQuery,
    QueryClientContactCrmDataByIdQueryVariables
  >(QueryClientContactCrmDataByIdDocument, { variables: { clientContactCrmDataId }, ...options });

  const typedSubscribeToMore: QueryClientContactCrmDataByIdSubScribeToMore = (options) => {
    _query.subscribeToMore({
      document: QueryClientContactCrmDataByIdDocument,
      variables: { clientContactCrmDataId } as QueryClientContactCrmDataByIdQueryVariables,
      ...options,
    });
  };
  const { subscribeToMore, ...query } = _query;
  return {
    ...query,
    subscribeToMore: typedSubscribeToMore,
    clientContactCrmData: query?.data?.clientContactCrmData_by_pk,
  };
}

/**
 *  Lazy Query Hook
 */

// Types
type PickQueryClientContactCrmDataByIdFn = (
  query: QueryClientContactCrmDataByIdQuery | null | undefined
) => ClientContactCrmDataFragment | null | undefined;
type QueryClientContactCrmDataByIdLazyFn = [
  (options?: QueryLazyOptions<QueryClientContactCrmDataByIdQueryVariables> | undefined) => void,
  QueryClientContactCrmDataByIdResult
];
type QueryClientContactCrmDataByIdWrappedLazyFn = (
  options: Omit<QueryLazyOptions<QueryClientContactCrmDataByIdQueryVariables>, 'variables'>
) => void;
export type QueryClientContactCrmDataByIdLazyReturn = [
  QueryClientContactCrmDataByIdWrappedLazyFn,
  QueryClientContactCrmDataByIdResultEx
];

// Function
function useQueryClientContactCrmDataByIdLazy({
  clientContactCrmDataId,
  options,
}: {
  clientContactCrmDataId: string;
  options?: Omit<
    QueryHookOptions<QueryClientContactCrmDataByIdQuery, QueryClientContactCrmDataByIdQueryVariables>,
    'query' | 'variables'
  >;
}): QueryClientContactCrmDataByIdLazyReturn {
  const lazyQuery: QueryClientContactCrmDataByIdLazyFn = useLazyQuery<
    QueryClientContactCrmDataByIdQuery,
    QueryClientContactCrmDataByIdQueryVariables
  >(QueryClientContactCrmDataByIdDocument, options);

  // Setting up typed version of lazyQuery
  const pickQueryClientContactCrmDataById: PickQueryClientContactCrmDataByIdFn = (query) => {
    return query && query.clientContactCrmData_by_pk;
  };
  const wrappedLazyQuery: QueryClientContactCrmDataByIdWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryClientContactCrmDataByIdSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({
        document: QueryClientContactCrmDataByIdDocument,
        variables: { clientContactCrmDataId } as QueryClientContactCrmDataByIdQueryVariables,
        ...options,
      });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    {
      ...lazyQueryResult,
      subscribeToMore: typedSubcribeToMore,
      clientContactCrmData: pickQueryClientContactCrmDataById(lazyQuery[1].data),
    },
  ];
}

/**
 *  Query Collection Hook
 */

// Types
export type QueryClientContactCrmDataObjectsResult = LazyQueryResult<
  QueryClientContactCrmDataObjectsQuery,
  QueryClientContactCrmDataObjectsQueryVariables
>;
type QueryClientContactCrmDataObjectsSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<
          QueryClientContactCrmDataObjectsQuery,
          QueryClientContactCrmDataObjectsQueryVariables,
          QueryClientContactCrmDataObjectsQuery
        >,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryClientContactCrmDataObjectsResultEx = Omit<
  QueryClientContactCrmDataObjectsResult,
  'subscribeToMore'
> & { subscribeToMore: QueryClientContactCrmDataObjectsSubScribeToMore } & ClientContactCrmDataObjectsHookResultEx;

// Function
function useQueryClientContactCrmDataObjects(
  options: Omit<
    QueryHookOptions<QueryClientContactCrmDataObjectsQuery, QueryClientContactCrmDataObjectsQueryVariables>,
    'query'
  >
): QueryClientContactCrmDataObjectsResultEx {
  const _query: QueryClientContactCrmDataObjectsResult = useQuery<
    QueryClientContactCrmDataObjectsQuery,
    QueryClientContactCrmDataObjectsQueryVariables
  >(QueryClientContactCrmDataObjectsDocument, options);

  const typedSubscribeToMore: QueryClientContactCrmDataObjectsSubScribeToMore = (options) => {
    _query.subscribeToMore({ document: QueryClientContactCrmDataObjectsDocument, ...options });
  };
  const { subscribeToMore, ...query } = _query;

  return { ...query, subscribeToMore: typedSubscribeToMore, objects: query?.data?.clientContactCrmData || [] };
}

/**
 *  Lazy Query Collection Hook
 */

// Types
type PickQueryClientContactCrmDataObjectsFn = (
  query: QueryClientContactCrmDataObjectsQuery | null | undefined
) => ClientContactCrmDataFragment[];
type QueryClientContactCrmDataObjectsLazyFn = [
  (options?: QueryLazyOptions<QueryClientContactCrmDataObjectsQueryVariables> | undefined) => void,
  QueryClientContactCrmDataObjectsResult
];
type QueryClientContactCrmDataObjectsWrappedLazyFn = (
  options?: QueryLazyOptions<QueryClientContactCrmDataObjectsQueryVariables>
) => void;
export type QueryClientContactCrmDataObjectsLazyReturn = [
  QueryClientContactCrmDataObjectsWrappedLazyFn,
  QueryClientContactCrmDataObjectsResultEx
];

// Function
function useQueryClientContactCrmDataObjectsLazy(
  options?: Omit<
    LazyQueryHookOptions<QueryClientContactCrmDataObjectsQuery, QueryClientContactCrmDataObjectsQueryVariables>,
    'query'
  >
): QueryClientContactCrmDataObjectsLazyReturn {
  const lazyQuery: QueryClientContactCrmDataObjectsLazyFn = useLazyQuery<
    QueryClientContactCrmDataObjectsQuery,
    QueryClientContactCrmDataObjectsQueryVariables
  >(QueryClientContactCrmDataObjectsDocument, options);

  // Setting up typed version of lazyQuery
  const pickObjects: PickQueryClientContactCrmDataObjectsFn = (
    query: QueryClientContactCrmDataObjectsQuery | null | undefined
  ) => {
    return query?.clientContactCrmData || [];
  };
  const wrappedLazyQuery: QueryClientContactCrmDataObjectsWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryClientContactCrmDataObjectsSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({ document: QueryClientContactCrmDataObjectsDocument, ...options });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    { ...lazyQueryResult, subscribeToMore: typedSubcribeToMore, objects: pickObjects(lazyQuery[1].data) },
  ];
}

/**
 *  Subscription Hook
 */

// Types
type SubscribeToClientContactCrmDataByIdResult = {
  variables?: SubscribeToClientContactCrmDataByIdSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToClientContactCrmDataByIdSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToClientContactCrmDataByIdResultEx = SubscribeToClientContactCrmDataByIdResult &
  ClientContactCrmDataByIdHookResultEx;

// Function
function useSubscribeToClientContactCrmDataById({
  clientContactCrmDataId,
  options,
}: {
  clientContactCrmDataId: string;
  options?: Omit<
    SubscriptionHookOptions<
      SubscribeToClientContactCrmDataByIdSubscription,
      SubscribeToClientContactCrmDataByIdSubscriptionVariables
    >,
    'query' | 'variables'
  >;
}): SubscribeToClientContactCrmDataByIdResultEx {
  const subscription: SubscribeToClientContactCrmDataByIdResult = useSubscription<
    SubscribeToClientContactCrmDataByIdSubscription,
    SubscribeToClientContactCrmDataByIdSubscriptionVariables
  >(SubscribeToClientContactCrmDataByIdDocument, { variables: { clientContactCrmDataId }, ...options });
  return { ...subscription, clientContactCrmData: subscription?.data?.clientContactCrmData_by_pk };
}

/**
 *  Subscription Collection Hook
 */

// Types
export type SubscribeToClientContactCrmDataObjectsResult = {
  variables?: SubscribeToClientContactCrmDataObjectsSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToClientContactCrmDataObjectsSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToClientContactCrmDataObjectsResultEx = SubscribeToClientContactCrmDataObjectsResult &
  ClientContactCrmDataObjectsHookResultEx;

// Function
function useSubscribeToClientContactCrmDataObjects(
  options: Omit<
    SubscriptionHookOptions<
      SubscribeToClientContactCrmDataObjectsSubscription,
      SubscribeToClientContactCrmDataObjectsSubscriptionVariables
    >,
    'query'
  >
): SubscribeToClientContactCrmDataObjectsResultEx {
  const subscription: SubscribeToClientContactCrmDataObjectsResult = useSubscription<
    SubscribeToClientContactCrmDataObjectsSubscription,
    SubscribeToClientContactCrmDataObjectsSubscriptionVariables
  >(SubscribeToClientContactCrmDataObjectsDocument, options);
  return { ...subscription, objects: subscription?.data?.clientContactCrmData || [] };
}

/**
 *  Insert Hooks
 */

// Types
type InsertClientContactCrmDataMutationResult = FetchResult<
  InsertClientContactCrmDataMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertClientContactCrmDataMutationResultEx = InsertClientContactCrmDataMutationResult &
  ClientContactCrmDataByIdHookResultEx;

type PickInsertClientContactCrmDataFn = (
  mutation: InsertClientContactCrmDataMutation | null | undefined
) => ClientContactCrmDataFragment | null | undefined;
type InsertClientContactCrmDataLazyMutationFn = MutationTuple<
  InsertClientContactCrmDataMutation,
  InsertClientContactCrmDataMutationVariables
>;
type InsertClientContactCrmDataWrappedLazyMutationFn = ({
  clientContactCrmData,
  autoOptimisticResponse,
  options,
}: {
  clientContactCrmData: ClientContactCrmData_Insert_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<InsertClientContactCrmDataMutation, InsertClientContactCrmDataMutationVariables>,
    'variables'
  >;
}) => Promise<InsertClientContactCrmDataMutationResultEx>;
export type InsertClientContactCrmDataLazyMutationReturn = [
  InsertClientContactCrmDataWrappedLazyMutationFn,
  InsertClientContactCrmDataMutationResultEx
];

// Function
function useInsertClientContactCrmData(
  options?: Omit<
    MutationHookOptions<InsertClientContactCrmDataMutation, InsertClientContactCrmDataMutationVariables>,
    'mutation' | 'variables'
  >
): InsertClientContactCrmDataLazyMutationReturn {
  const lazyMutation: InsertClientContactCrmDataLazyMutationFn = useMutation<
    InsertClientContactCrmDataMutation,
    InsertClientContactCrmDataMutationVariables
  >(InsertClientContactCrmDataDocument, options);
  const pickClientContactCrmData: PickInsertClientContactCrmDataFn = (mutation) => {
    return mutation?.insert_clientContactCrmData?.returning && mutation?.insert_clientContactCrmData?.returning[0];
  };

  const wrappedLazyMutation: InsertClientContactCrmDataWrappedLazyMutationFn = async ({
    clientContactCrmData,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: clientContactCrmData });
    const mutationOptions: MutationFunctionOptions<
      InsertClientContactCrmDataMutation,
      InsertClientContactCrmDataMutationVariables
    > = { variables: { objects: [objectForInsert] }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id)
        throw new Error(`if autoOptimisticResponse = true, id must be set in object 'clientContactCrmData'`);
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertClientContactCrmDataMutation>({
        operationType: 'insert',
        entityName: 'clientContactCrmData',
        objects: [objectForInsert as ClientContactCrmData_Insert_Input & ObjectWithId],
      });
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, clientContactCrmData: pickClientContactCrmData(fetchResult.data) };
  };

  return [
    wrappedLazyMutation,
    { ...lazyMutation[1], clientContactCrmData: pickClientContactCrmData(lazyMutation[1].data) },
  ];
}

//
//

// Types
type InsertClientContactCrmDataWithOnConflictMutationResult = FetchResult<
  InsertClientContactCrmDataWithOnConflictMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertClientContactCrmDataWithOnConflictMutationResultEx = InsertClientContactCrmDataWithOnConflictMutationResult &
  ClientContactCrmDataByIdHookResultEx;

type InsertClientContactCrmDataWithOnConflictLazyMutationFn = MutationTuple<
  InsertClientContactCrmDataWithOnConflictMutation,
  InsertClientContactCrmDataWithOnConflictMutationVariables
>;
type InsertClientContactCrmDataWithOnConflictWrappedLazyMutationFn = ({
  clientContactCrmData,
  onConflict,
  autoOptimisticResponse,
  options,
}: {
  clientContactCrmData: ClientContactCrmData_Insert_Input;
  onConflict: ClientContactCrmData_On_Conflict;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<
      InsertClientContactCrmDataWithOnConflictMutation,
      InsertClientContactCrmDataWithOnConflictMutationVariables
    >,
    'variables'
  >;
}) => Promise<InsertClientContactCrmDataWithOnConflictMutationResultEx>;
export type InsertClientContactCrmDataWithOnConflictLazyMutationReturn = [
  InsertClientContactCrmDataWithOnConflictWrappedLazyMutationFn,
  InsertClientContactCrmDataWithOnConflictMutationResultEx
];

// Function
function useInsertClientContactCrmDataWithOnConflict(
  options?: Omit<
    MutationHookOptions<
      InsertClientContactCrmDataWithOnConflictMutation,
      InsertClientContactCrmDataWithOnConflictMutationVariables
    >,
    'mutation' | 'variables'
  >
): InsertClientContactCrmDataWithOnConflictLazyMutationReturn {
  const lazyMutation: InsertClientContactCrmDataWithOnConflictLazyMutationFn = useMutation<
    InsertClientContactCrmDataWithOnConflictMutation,
    InsertClientContactCrmDataWithOnConflictMutationVariables
  >(InsertClientContactCrmDataWithOnConflictDocument, options);
  const pickClientContactCrmData: PickInsertClientContactCrmDataFn = (
    mutation: InsertClientContactCrmDataWithOnConflictMutation | null | undefined
  ) => {
    return mutation?.insert_clientContactCrmData?.returning && mutation.insert_clientContactCrmData.returning[0];
  };

  const wrappedLazyMutation: InsertClientContactCrmDataWithOnConflictWrappedLazyMutationFn = async ({
    clientContactCrmData,
    onConflict,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: clientContactCrmData });
    const mutationOptions: MutationFunctionOptions<
      InsertClientContactCrmDataWithOnConflictMutation,
      InsertClientContactCrmDataWithOnConflictMutationVariables
    > = { variables: { objects: [objectForInsert], onConflict }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id)
        throw new Error(`if autoOptimisticResponse = true, id must be set in object 'clientContactCrmData'`);
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertClientContactCrmDataWithOnConflictMutation>(
        {
          operationType: 'insert',
          entityName: 'clientContactCrmData',
          objects: [objectForInsert as ClientContactCrmData_Insert_Input & ObjectWithId],
        }
      );
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, clientContactCrmData: pickClientContactCrmData(fetchResult.data) };
  };

  return [
    wrappedLazyMutation,
    { ...lazyMutation[1], clientContactCrmData: pickClientContactCrmData(lazyMutation[1].data) },
  ];
}

// Types
type InsertClientContactCrmDataObjectsMutationResult = FetchResult<
  InsertClientContactCrmDataMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertClientContactCrmDataObjectsMutationResultEx = InsertClientContactCrmDataMutationResult &
  ClientContactCrmDataObjectsHookResultEx;

type PickInsertClientContactCrmDataObjectsFn = (
  mutation: InsertClientContactCrmDataMutation | null | undefined
) => ClientContactCrmDataFragment[];
type InsertClientContactCrmDataObjectsLazyMutationFn = MutationTuple<
  InsertClientContactCrmDataMutation,
  InsertClientContactCrmDataMutationVariables
>;
type InsertClientContactCrmDataObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<InsertClientContactCrmDataMutation, InsertClientContactCrmDataMutationVariables>
) => Promise<InsertClientContactCrmDataObjectsMutationResultEx>;
export type InsertClientContactCrmDataObjectsLazyMutationReturn = [
  InsertClientContactCrmDataObjectsWrappedLazyMutationFn,
  InsertClientContactCrmDataObjectsMutationResultEx
];

// Function
function useInsertClientContactCrmDataObjects(
  options?: Omit<
    MutationHookOptions<InsertClientContactCrmDataMutation, InsertClientContactCrmDataMutationVariables>,
    'mutation'
  >
): InsertClientContactCrmDataObjectsLazyMutationReturn {
  const lazyMutation: InsertClientContactCrmDataObjectsLazyMutationFn = useMutation<
    InsertClientContactCrmDataMutation,
    InsertClientContactCrmDataMutationVariables
  >(InsertClientContactCrmDataDocument, options);
  const pickObjects: PickInsertClientContactCrmDataObjectsFn = (
    mutation: InsertClientContactCrmDataMutation | null | undefined
  ) => {
    return mutation?.insert_clientContactCrmData?.returning || [];
  };

  const wrappedLazyMutation: InsertClientContactCrmDataObjectsWrappedLazyMutationFn = async (options) => {
    if (options && options.variables && options.variables.objects)
      options.variables.objects = options.variables.objects.map((objectItem) =>
        stripInsertInputClientFields({ input: objectItem })
      );
    const fetchResult: InsertClientContactCrmDataObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Update Hooks
 */

type UpdateClientContactCrmDataByIdMutationResult = FetchResult<
  UpdateClientContactCrmDataByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateClientContactCrmDataByIdMutationResultEx = UpdateClientContactCrmDataByIdMutationResult &
  ClientContactCrmDataByIdHookResultEx;

type PickUpdateClientContactCrmDataByIdFn = (
  mutation: UpdateClientContactCrmDataByIdMutation | null | undefined
) => ClientContactCrmDataFragment | null | undefined;
type UpdateClientContactCrmDataByIdLazyMutationFn = MutationTuple<
  UpdateClientContactCrmDataByIdMutation,
  UpdateClientContactCrmDataByIdMutationVariables
>;
type UpdateClientContactCrmDataByIdWrappedLazyMutationFn = ({
  clientContactCrmDataId,
  set,
  autoOptimisticResponse,
  options,
}: {
  clientContactCrmDataId: string;
  set: ClientContactCrmData_Set_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<UpdateClientContactCrmDataByIdMutation, UpdateClientContactCrmDataByIdMutationVariables>,
    'variables'
  >;
}) => Promise<UpdateClientContactCrmDataByIdMutationResultEx>;
export type UpdateClientContactCrmDataByIdLazyMutationReturn = [
  UpdateClientContactCrmDataByIdWrappedLazyMutationFn,
  UpdateClientContactCrmDataByIdMutationResultEx
];

function useUpdateClientContactCrmDataById(
  options?: Omit<
    MutationHookOptions<UpdateClientContactCrmDataByIdMutation, UpdateClientContactCrmDataByIdMutationVariables>,
    'mutation' | 'variables'
  >
): UpdateClientContactCrmDataByIdLazyMutationReturn {
  const lazyMutation: UpdateClientContactCrmDataByIdLazyMutationFn = useMutation<
    UpdateClientContactCrmDataByIdMutation,
    UpdateClientContactCrmDataByIdMutationVariables
  >(UpdateClientContactCrmDataByIdDocument, options);

  const pickClientContactCrmData: PickUpdateClientContactCrmDataByIdFn = (mutation) => {
    return mutation?.update_clientContactCrmData?.returning && mutation.update_clientContactCrmData!.returning[0];
  };

  const wrappedLazyMutation: UpdateClientContactCrmDataByIdWrappedLazyMutationFn = async ({
    clientContactCrmDataId,
    set,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<
      UpdateClientContactCrmDataByIdMutation,
      UpdateClientContactCrmDataByIdMutationVariables
    > = { variables: { id: clientContactCrmDataId, set }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateClientContactCrmDataByIdMutation>(
        {
          operationType: 'update',
          entityName: 'clientContactCrmData',
          objects: [{ id: clientContactCrmDataId, ...set }],
        }
      );
    }

    const fetchResult: UpdateClientContactCrmDataByIdMutationResult = await lazyMutation[0]({
      variables: { id: clientContactCrmDataId, set },
      ...mutationOptions,
    });
    return { ...fetchResult, clientContactCrmData: pickClientContactCrmData(fetchResult.data) };
  };

  return [
    wrappedLazyMutation,
    { ...lazyMutation[1], clientContactCrmData: pickClientContactCrmData(lazyMutation[1].data) },
  ];
}

//
//

// Types
type UpdateClientContactCrmDataObjectsMutationResult = FetchResult<
  UpdateClientContactCrmDataMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateClientContactCrmDataObjectsMutationResultEx = UpdateClientContactCrmDataObjectsMutationResult &
  ClientContactCrmDataObjectsHookResultEx;

// Function
type PickUpdateClientContactCrmDataObjectsFn = (
  mutation: UpdateClientContactCrmDataMutation | null | undefined
) => ClientContactCrmDataFragment[];
type UpdateClientContactCrmDataObjectsLazyMutationFn = MutationTuple<
  UpdateClientContactCrmDataMutation,
  UpdateClientContactCrmDataMutationVariables
>;
type UpdateClientContactCrmDataObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<UpdateClientContactCrmDataMutation, UpdateClientContactCrmDataMutationVariables>
) => Promise<UpdateClientContactCrmDataObjectsMutationResultEx>;
export type UpdateClientContactCrmDataObjectsLazyMutationReturn = [
  UpdateClientContactCrmDataObjectsWrappedLazyMutationFn,
  UpdateClientContactCrmDataObjectsMutationResultEx
];

function useUpdateClientContactCrmDataObjects(
  options?: Omit<
    MutationHookOptions<UpdateClientContactCrmDataMutation, UpdateClientContactCrmDataMutationVariables>,
    'mutation'
  >
): UpdateClientContactCrmDataObjectsLazyMutationReturn {
  const lazyMutation: UpdateClientContactCrmDataObjectsLazyMutationFn = useMutation<
    UpdateClientContactCrmDataMutation,
    UpdateClientContactCrmDataMutationVariables
  >(UpdateClientContactCrmDataDocument, options);

  const pickObjects: PickUpdateClientContactCrmDataObjectsFn = (
    mutation: UpdateClientContactCrmDataMutation | null | undefined
  ) => {
    return mutation?.update_clientContactCrmData?.returning || [];
  };

  const wrappedLazyMutation: UpdateClientContactCrmDataObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: UpdateClientContactCrmDataObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Delete Hooks
 */

// Types
type RemoveClientContactCrmDataModelByIdFetchResult = FetchResult<
  RemoveClientContactCrmDataModelByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveClientContactCrmDataModelByIdMutationResultEx = RemoveClientContactCrmDataModelByIdFetchResult &
  RemoveEntitiesQueryHookResultEx;

// Function
type PickRemoveClientContactCrmDataModelFn = (
  mutation: RemoveClientContactCrmDataModelByIdMutation | null | undefined
) => number;
type RemoveClientContactCrmDataModelLazyMutationFn = MutationTuple<
  RemoveClientContactCrmDataModelByIdMutation,
  RemoveClientContactCrmDataModelByIdMutationVariables
>;
type RemoveClientContactCrmDataModelWrappedLazyMutationFn = ({
  clientContactCrmDataId,
  autoOptimisticResponse,
  options,
}: {
  clientContactCrmDataId: string;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<
      RemoveClientContactCrmDataModelByIdMutation,
      RemoveClientContactCrmDataModelByIdMutationVariables
    >,
    'variables'
  >;
}) => Promise<RemoveClientContactCrmDataModelByIdMutationResultEx>;
export type RemoveClientContactCrmDataModelLazyMutationReturn = [
  RemoveClientContactCrmDataModelWrappedLazyMutationFn,
  RemoveClientContactCrmDataModelByIdMutationResultEx
];

function useRemoveClientContactCrmDataModelById(
  options?: Omit<
    MutationHookOptions<
      RemoveClientContactCrmDataModelByIdMutation,
      RemoveClientContactCrmDataModelByIdMutationVariables
    >,
    'mutation' | 'variables'
  >
) {
  const lazyMutation: RemoveClientContactCrmDataModelLazyMutationFn = useMutation<
    RemoveClientContactCrmDataModelByIdMutation,
    RemoveClientContactCrmDataModelByIdMutationVariables
  >(RemoveClientContactCrmDataModelByIdDocument, options);

  const pickAffectedRows: PickRemoveClientContactCrmDataModelFn = (
    mutation: RemoveClientContactCrmDataModelByIdMutation | null | undefined
  ) => {
    return mutation?.delete_clientContactCrmData?.affected_rows || 0;
  };

  const wrappedLazyMutation: RemoveClientContactCrmDataModelWrappedLazyMutationFn = async ({
    clientContactCrmDataId,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<
      RemoveClientContactCrmDataModelMutation,
      RemoveClientContactCrmDataModelByIdMutationVariables
    > = { variables: { id: clientContactCrmDataId }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<RemoveClientContactCrmDataModelMutation>(
        { operationType: 'delete', entityName: 'clientContactCrmData', objects: [{ id: clientContactCrmDataId }] }
      );
    }
    if (!options || !options.update) {
      mutationOptions.update = generateUpdateFunctionForMutation<RemoveClientContactCrmDataModelByIdMutation>({
        operationType: 'delete',
        entityName: 'clientContactCrmData',
        entityId: clientContactCrmDataId,
      });
    }

    const fetchResult: RemoveClientContactCrmDataModelByIdFetchResult = await lazyMutation[0](mutationOptions);
    return { ...fetchResult, affected_rows: pickAffectedRows(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], affected_rows: pickAffectedRows(lazyMutation[1].data) }] as [
    typeof wrappedLazyMutation,
    typeof lazyMutation[1] & { affected_rows: ReturnType<typeof pickAffectedRows> }
  ];
}

//
//

// Types
type RemoveClientContactCrmDataModelObjectsMutationResult = FetchResult<
  RemoveClientContactCrmDataModelMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveClientContactCrmDataModelObjectsMutationResultEx = RemoveClientContactCrmDataModelObjectsMutationResult &
  RemoveEntitiesQueryHookResultEx;

// Function
type PickRemoveClientContactCrmDataModelObjectsFn = (
  mutation: RemoveClientContactCrmDataModelMutation | null | undefined
) => number;
type RemoveClientContactCrmDataModelObjectsLazyMutationFn = MutationTuple<
  RemoveClientContactCrmDataModelMutation,
  RemoveClientContactCrmDataModelMutationVariables
>;
type RemoveClientContactCrmDataModelObjectsWrappedLazyMutationFn = (
  options: MutationFunctionOptions<
    RemoveClientContactCrmDataModelMutation,
    RemoveClientContactCrmDataModelMutationVariables
  >
) => Promise<RemoveClientContactCrmDataModelObjectsMutationResultEx>;
export type RemoveClientContactCrmDataModelObjectsLazyMutationReturn = [
  RemoveClientContactCrmDataModelObjectsWrappedLazyMutationFn,
  RemoveClientContactCrmDataModelObjectsMutationResultEx
];

function useRemoveClientContactCrmDataModelObjects(
  options?: Omit<
    MutationHookOptions<RemoveClientContactCrmDataModelMutation, RemoveClientContactCrmDataModelMutationVariables>,
    'mutation'
  >
): RemoveClientContactCrmDataModelObjectsLazyMutationReturn {
  const lazyMutation = useMutation<
    RemoveClientContactCrmDataModelMutation,
    RemoveClientContactCrmDataModelMutationVariables
  >(RemoveClientContactCrmDataModelDocument, options);

  const pickAffectedRows: PickRemoveClientContactCrmDataModelObjectsFn = (
    mutation: RemoveClientContactCrmDataModelMutation | null | undefined
  ) => {
    return mutation?.delete_clientContactCrmData?.affected_rows || 0;
  };

  const wrappedLazyMutation: RemoveClientContactCrmDataModelObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: RemoveClientContactCrmDataModelObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, affected_rows: pickAffectedRows(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], affected_rows: pickAffectedRows(lazyMutation[1].data) }];
}

// ClientContactCrmData Fragment Helper Object
//------------------------------------------------

export const ClientContactCrmDataFragmentGQLHooks = {
  useQueryById: useQueryClientContactCrmDataById,
  useQueryByIdLazy: useQueryClientContactCrmDataByIdLazy,
  useQueryObjects: useQueryClientContactCrmDataObjects,
  useQueryObjectsLazy: useQueryClientContactCrmDataObjectsLazy,
  useSubscribeToById: useSubscribeToClientContactCrmDataById,
  useSubscribeToObjects: useSubscribeToClientContactCrmDataObjects,
  useInsert: useInsertClientContactCrmData,
  useInsertWithOnConflict: useInsertClientContactCrmDataWithOnConflict,
  useInsertObjects: useInsertClientContactCrmDataObjects,
  useUpdateById: useUpdateClientContactCrmDataById,
  useUpdateObjects: useUpdateClientContactCrmDataObjects,
};

// clientContactCrmData MODEL HOOKS OBJECT
//------------------------------------------------

export const ClientContactCrmDataModelGQLHooks = {
  useRemoveById: useRemoveClientContactCrmDataModelById,
  useRemoveObjects: useRemoveClientContactCrmDataModelObjects,
};

// client REACT
//------------------------------------------------

export type ClientExternalByIdHookResultEx = { clientExternal: ClientExternalFragment | null | undefined };
export type ClientExternalObjectsHookResultEx = { objects: ClientExternalFragment[] };

/**
 *  Query Hook
 */

// Types
type QueryClientExternalByIdResult = LazyQueryResult<
  QueryClientExternalByIdQuery,
  QueryClientExternalByIdQueryVariables
>;
type QueryClientExternalByIdSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<
          QueryClientExternalByIdQuery,
          QueryClientExternalByIdQueryVariables,
          QueryClientExternalByIdQuery
        >,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryClientExternalByIdResultEx = Omit<QueryClientExternalByIdResult, 'subscribeToMore'> & {
  subscribeToMore: QueryClientExternalByIdSubScribeToMore;
} & ClientExternalByIdHookResultEx;

// Function
function useQueryClientExternalById({
  clientId,
  options,
}: {
  clientId: string;
  options?: Omit<
    QueryHookOptions<QueryClientExternalByIdQuery, QueryClientExternalByIdQueryVariables>,
    'query' | 'variables'
  >;
}): QueryClientExternalByIdResultEx {
  const _query: QueryClientExternalByIdResult = useQuery<
    QueryClientExternalByIdQuery,
    QueryClientExternalByIdQueryVariables
  >(QueryClientExternalByIdDocument, { variables: { clientId }, ...options });

  const typedSubscribeToMore: QueryClientExternalByIdSubScribeToMore = (options) => {
    _query.subscribeToMore({
      document: QueryClientExternalByIdDocument,
      variables: { clientId } as QueryClientExternalByIdQueryVariables,
      ...options,
    });
  };
  const { subscribeToMore, ...query } = _query;
  return { ...query, subscribeToMore: typedSubscribeToMore, clientExternal: query?.data?.client_by_pk };
}

/**
 *  Lazy Query Hook
 */

// Types
type PickQueryClientExternalByIdFn = (
  query: QueryClientExternalByIdQuery | null | undefined
) => ClientExternalFragment | null | undefined;
type QueryClientExternalByIdLazyFn = [
  (options?: QueryLazyOptions<QueryClientExternalByIdQueryVariables> | undefined) => void,
  QueryClientExternalByIdResult
];
type QueryClientExternalByIdWrappedLazyFn = (
  options: Omit<QueryLazyOptions<QueryClientExternalByIdQueryVariables>, 'variables'>
) => void;
export type QueryClientExternalByIdLazyReturn = [QueryClientExternalByIdWrappedLazyFn, QueryClientExternalByIdResultEx];

// Function
function useQueryClientExternalByIdLazy({
  clientId,
  options,
}: {
  clientId: string;
  options?: Omit<
    QueryHookOptions<QueryClientExternalByIdQuery, QueryClientExternalByIdQueryVariables>,
    'query' | 'variables'
  >;
}): QueryClientExternalByIdLazyReturn {
  const lazyQuery: QueryClientExternalByIdLazyFn = useLazyQuery<
    QueryClientExternalByIdQuery,
    QueryClientExternalByIdQueryVariables
  >(QueryClientExternalByIdDocument, options);

  // Setting up typed version of lazyQuery
  const pickQueryClientExternalById: PickQueryClientExternalByIdFn = (query) => {
    return query && query.client_by_pk;
  };
  const wrappedLazyQuery: QueryClientExternalByIdWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryClientExternalByIdSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({
        document: QueryClientExternalByIdDocument,
        variables: { clientId } as QueryClientExternalByIdQueryVariables,
        ...options,
      });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    {
      ...lazyQueryResult,
      subscribeToMore: typedSubcribeToMore,
      clientExternal: pickQueryClientExternalById(lazyQuery[1].data),
    },
  ];
}

/**
 *  Query Collection Hook
 */

// Types
export type QueryClientExternalObjectsResult = LazyQueryResult<
  QueryClientExternalObjectsQuery,
  QueryClientExternalObjectsQueryVariables
>;
type QueryClientExternalObjectsSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<
          QueryClientExternalObjectsQuery,
          QueryClientExternalObjectsQueryVariables,
          QueryClientExternalObjectsQuery
        >,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryClientExternalObjectsResultEx = Omit<QueryClientExternalObjectsResult, 'subscribeToMore'> & {
  subscribeToMore: QueryClientExternalObjectsSubScribeToMore;
} & ClientExternalObjectsHookResultEx;

// Function
function useQueryClientExternalObjects(
  options: Omit<QueryHookOptions<QueryClientExternalObjectsQuery, QueryClientExternalObjectsQueryVariables>, 'query'>
): QueryClientExternalObjectsResultEx {
  const _query: QueryClientExternalObjectsResult = useQuery<
    QueryClientExternalObjectsQuery,
    QueryClientExternalObjectsQueryVariables
  >(QueryClientExternalObjectsDocument, options);

  const typedSubscribeToMore: QueryClientExternalObjectsSubScribeToMore = (options) => {
    _query.subscribeToMore({ document: QueryClientExternalObjectsDocument, ...options });
  };
  const { subscribeToMore, ...query } = _query;

  return { ...query, subscribeToMore: typedSubscribeToMore, objects: query?.data?.client || [] };
}

/**
 *  Lazy Query Collection Hook
 */

// Types
type PickQueryClientExternalObjectsFn = (
  query: QueryClientExternalObjectsQuery | null | undefined
) => ClientExternalFragment[];
type QueryClientExternalObjectsLazyFn = [
  (options?: QueryLazyOptions<QueryClientExternalObjectsQueryVariables> | undefined) => void,
  QueryClientExternalObjectsResult
];
type QueryClientExternalObjectsWrappedLazyFn = (
  options?: QueryLazyOptions<QueryClientExternalObjectsQueryVariables>
) => void;
export type QueryClientExternalObjectsLazyReturn = [
  QueryClientExternalObjectsWrappedLazyFn,
  QueryClientExternalObjectsResultEx
];

// Function
function useQueryClientExternalObjectsLazy(
  options?: Omit<
    LazyQueryHookOptions<QueryClientExternalObjectsQuery, QueryClientExternalObjectsQueryVariables>,
    'query'
  >
): QueryClientExternalObjectsLazyReturn {
  const lazyQuery: QueryClientExternalObjectsLazyFn = useLazyQuery<
    QueryClientExternalObjectsQuery,
    QueryClientExternalObjectsQueryVariables
  >(QueryClientExternalObjectsDocument, options);

  // Setting up typed version of lazyQuery
  const pickObjects: PickQueryClientExternalObjectsFn = (query: QueryClientExternalObjectsQuery | null | undefined) => {
    return query?.client || [];
  };
  const wrappedLazyQuery: QueryClientExternalObjectsWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryClientExternalObjectsSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({ document: QueryClientExternalObjectsDocument, ...options });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    { ...lazyQueryResult, subscribeToMore: typedSubcribeToMore, objects: pickObjects(lazyQuery[1].data) },
  ];
}

/**
 *  Subscription Hook
 */

// Types
type SubscribeToClientExternalByIdResult = {
  variables?: SubscribeToClientExternalByIdSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToClientExternalByIdSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToClientExternalByIdResultEx = SubscribeToClientExternalByIdResult &
  ClientExternalByIdHookResultEx;

// Function
function useSubscribeToClientExternalById({
  clientId,
  options,
}: {
  clientId: string;
  options?: Omit<
    SubscriptionHookOptions<
      SubscribeToClientExternalByIdSubscription,
      SubscribeToClientExternalByIdSubscriptionVariables
    >,
    'query' | 'variables'
  >;
}): SubscribeToClientExternalByIdResultEx {
  const subscription: SubscribeToClientExternalByIdResult = useSubscription<
    SubscribeToClientExternalByIdSubscription,
    SubscribeToClientExternalByIdSubscriptionVariables
  >(SubscribeToClientExternalByIdDocument, { variables: { clientId }, ...options });
  return { ...subscription, clientExternal: subscription?.data?.client_by_pk };
}

/**
 *  Subscription Collection Hook
 */

// Types
export type SubscribeToClientExternalObjectsResult = {
  variables?: SubscribeToClientExternalObjectsSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToClientExternalObjectsSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToClientExternalObjectsResultEx = SubscribeToClientExternalObjectsResult &
  ClientExternalObjectsHookResultEx;

// Function
function useSubscribeToClientExternalObjects(
  options: Omit<
    SubscriptionHookOptions<
      SubscribeToClientExternalObjectsSubscription,
      SubscribeToClientExternalObjectsSubscriptionVariables
    >,
    'query'
  >
): SubscribeToClientExternalObjectsResultEx {
  const subscription: SubscribeToClientExternalObjectsResult = useSubscription<
    SubscribeToClientExternalObjectsSubscription,
    SubscribeToClientExternalObjectsSubscriptionVariables
  >(SubscribeToClientExternalObjectsDocument, options);
  return { ...subscription, objects: subscription?.data?.client || [] };
}

/**
 *  Insert Hooks
 */

// Types
type InsertClientExternalMutationResult = FetchResult<
  InsertClientExternalMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertClientExternalMutationResultEx = InsertClientExternalMutationResult & ClientExternalByIdHookResultEx;

type PickInsertClientExternalFn = (
  mutation: InsertClientExternalMutation | null | undefined
) => ClientExternalFragment | null | undefined;
type InsertClientExternalLazyMutationFn = MutationTuple<
  InsertClientExternalMutation,
  InsertClientExternalMutationVariables
>;
type InsertClientExternalWrappedLazyMutationFn = ({
  client,
  autoOptimisticResponse,
  options,
}: {
  client: Client_Insert_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<InsertClientExternalMutation, InsertClientExternalMutationVariables>,
    'variables'
  >;
}) => Promise<InsertClientExternalMutationResultEx>;
export type InsertClientExternalLazyMutationReturn = [
  InsertClientExternalWrappedLazyMutationFn,
  InsertClientExternalMutationResultEx
];

// Function
function useInsertClientExternal(
  options?: Omit<
    MutationHookOptions<InsertClientExternalMutation, InsertClientExternalMutationVariables>,
    'mutation' | 'variables'
  >
): InsertClientExternalLazyMutationReturn {
  const lazyMutation: InsertClientExternalLazyMutationFn = useMutation<
    InsertClientExternalMutation,
    InsertClientExternalMutationVariables
  >(InsertClientExternalDocument, options);
  const pickClientExternal: PickInsertClientExternalFn = (mutation) => {
    return mutation?.insert_client?.returning && mutation?.insert_client?.returning[0];
  };

  const wrappedLazyMutation: InsertClientExternalWrappedLazyMutationFn = async ({
    client,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: client });
    const mutationOptions: MutationFunctionOptions<
      InsertClientExternalMutation,
      InsertClientExternalMutationVariables
    > = { variables: { objects: [objectForInsert] }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id) throw new Error(`if autoOptimisticResponse = true, id must be set in object 'client'`);
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertClientExternalMutation>({
        operationType: 'insert',
        entityName: 'client',
        objects: [objectForInsert as Client_Insert_Input & ObjectWithId],
      });
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, clientExternal: pickClientExternal(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], clientExternal: pickClientExternal(lazyMutation[1].data) }];
}

//
//

// Types
type InsertClientExternalWithOnConflictMutationResult = FetchResult<
  InsertClientExternalWithOnConflictMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertClientExternalWithOnConflictMutationResultEx = InsertClientExternalWithOnConflictMutationResult &
  ClientExternalByIdHookResultEx;

type InsertClientExternalWithOnConflictLazyMutationFn = MutationTuple<
  InsertClientExternalWithOnConflictMutation,
  InsertClientExternalWithOnConflictMutationVariables
>;
type InsertClientExternalWithOnConflictWrappedLazyMutationFn = ({
  client,
  onConflict,
  autoOptimisticResponse,
  options,
}: {
  client: Client_Insert_Input;
  onConflict: Client_On_Conflict;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<
      InsertClientExternalWithOnConflictMutation,
      InsertClientExternalWithOnConflictMutationVariables
    >,
    'variables'
  >;
}) => Promise<InsertClientExternalWithOnConflictMutationResultEx>;
export type InsertClientExternalWithOnConflictLazyMutationReturn = [
  InsertClientExternalWithOnConflictWrappedLazyMutationFn,
  InsertClientExternalWithOnConflictMutationResultEx
];

// Function
function useInsertClientExternalWithOnConflict(
  options?: Omit<
    MutationHookOptions<
      InsertClientExternalWithOnConflictMutation,
      InsertClientExternalWithOnConflictMutationVariables
    >,
    'mutation' | 'variables'
  >
): InsertClientExternalWithOnConflictLazyMutationReturn {
  const lazyMutation: InsertClientExternalWithOnConflictLazyMutationFn = useMutation<
    InsertClientExternalWithOnConflictMutation,
    InsertClientExternalWithOnConflictMutationVariables
  >(InsertClientExternalWithOnConflictDocument, options);
  const pickClientExternal: PickInsertClientExternalFn = (
    mutation: InsertClientExternalWithOnConflictMutation | null | undefined
  ) => {
    return mutation?.insert_client?.returning && mutation.insert_client.returning[0];
  };

  const wrappedLazyMutation: InsertClientExternalWithOnConflictWrappedLazyMutationFn = async ({
    client,
    onConflict,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: client });
    const mutationOptions: MutationFunctionOptions<
      InsertClientExternalWithOnConflictMutation,
      InsertClientExternalWithOnConflictMutationVariables
    > = { variables: { objects: [objectForInsert], onConflict }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id) throw new Error(`if autoOptimisticResponse = true, id must be set in object 'client'`);
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertClientExternalWithOnConflictMutation>(
        {
          operationType: 'insert',
          entityName: 'client',
          objects: [objectForInsert as Client_Insert_Input & ObjectWithId],
        }
      );
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, clientExternal: pickClientExternal(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], clientExternal: pickClientExternal(lazyMutation[1].data) }];
}

// Types
type InsertClientExternalObjectsMutationResult = FetchResult<
  InsertClientExternalMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertClientExternalObjectsMutationResultEx = InsertClientExternalMutationResult &
  ClientExternalObjectsHookResultEx;

type PickInsertClientExternalObjectsFn = (
  mutation: InsertClientExternalMutation | null | undefined
) => ClientExternalFragment[];
type InsertClientExternalObjectsLazyMutationFn = MutationTuple<
  InsertClientExternalMutation,
  InsertClientExternalMutationVariables
>;
type InsertClientExternalObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<InsertClientExternalMutation, InsertClientExternalMutationVariables>
) => Promise<InsertClientExternalObjectsMutationResultEx>;
export type InsertClientExternalObjectsLazyMutationReturn = [
  InsertClientExternalObjectsWrappedLazyMutationFn,
  InsertClientExternalObjectsMutationResultEx
];

// Function
function useInsertClientExternalObjects(
  options?: Omit<MutationHookOptions<InsertClientExternalMutation, InsertClientExternalMutationVariables>, 'mutation'>
): InsertClientExternalObjectsLazyMutationReturn {
  const lazyMutation: InsertClientExternalObjectsLazyMutationFn = useMutation<
    InsertClientExternalMutation,
    InsertClientExternalMutationVariables
  >(InsertClientExternalDocument, options);
  const pickObjects: PickInsertClientExternalObjectsFn = (
    mutation: InsertClientExternalMutation | null | undefined
  ) => {
    return mutation?.insert_client?.returning || [];
  };

  const wrappedLazyMutation: InsertClientExternalObjectsWrappedLazyMutationFn = async (options) => {
    if (options && options.variables && options.variables.objects)
      options.variables.objects = options.variables.objects.map((objectItem) =>
        stripInsertInputClientFields({ input: objectItem })
      );
    const fetchResult: InsertClientExternalObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Update Hooks
 */

type UpdateClientExternalByIdMutationResult = FetchResult<
  UpdateClientExternalByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateClientExternalByIdMutationResultEx = UpdateClientExternalByIdMutationResult &
  ClientExternalByIdHookResultEx;

type PickUpdateClientExternalByIdFn = (
  mutation: UpdateClientExternalByIdMutation | null | undefined
) => ClientExternalFragment | null | undefined;
type UpdateClientExternalByIdLazyMutationFn = MutationTuple<
  UpdateClientExternalByIdMutation,
  UpdateClientExternalByIdMutationVariables
>;
type UpdateClientExternalByIdWrappedLazyMutationFn = ({
  clientId,
  set,
  autoOptimisticResponse,
  options,
}: {
  clientId: string;
  set: Client_Set_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<UpdateClientExternalByIdMutation, UpdateClientExternalByIdMutationVariables>,
    'variables'
  >;
}) => Promise<UpdateClientExternalByIdMutationResultEx>;
export type UpdateClientExternalByIdLazyMutationReturn = [
  UpdateClientExternalByIdWrappedLazyMutationFn,
  UpdateClientExternalByIdMutationResultEx
];

function useUpdateClientExternalById(
  options?: Omit<
    MutationHookOptions<UpdateClientExternalByIdMutation, UpdateClientExternalByIdMutationVariables>,
    'mutation' | 'variables'
  >
): UpdateClientExternalByIdLazyMutationReturn {
  const lazyMutation: UpdateClientExternalByIdLazyMutationFn = useMutation<
    UpdateClientExternalByIdMutation,
    UpdateClientExternalByIdMutationVariables
  >(UpdateClientExternalByIdDocument, options);

  const pickClientExternal: PickUpdateClientExternalByIdFn = (mutation) => {
    return mutation?.update_client?.returning && mutation.update_client!.returning[0];
  };

  const wrappedLazyMutation: UpdateClientExternalByIdWrappedLazyMutationFn = async ({
    clientId,
    set,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<
      UpdateClientExternalByIdMutation,
      UpdateClientExternalByIdMutationVariables
    > = { variables: { id: clientId, set }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateClientExternalByIdMutation>({
        operationType: 'update',
        entityName: 'client',
        objects: [{ id: clientId, ...set }],
      });
    }

    const fetchResult: UpdateClientExternalByIdMutationResult = await lazyMutation[0]({
      variables: { id: clientId, set },
      ...mutationOptions,
    });
    return { ...fetchResult, clientExternal: pickClientExternal(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], clientExternal: pickClientExternal(lazyMutation[1].data) }];
}

//
//

// Types
type UpdateClientExternalObjectsMutationResult = FetchResult<
  UpdateClientExternalMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateClientExternalObjectsMutationResultEx = UpdateClientExternalObjectsMutationResult &
  ClientExternalObjectsHookResultEx;

// Function
type PickUpdateClientExternalObjectsFn = (
  mutation: UpdateClientExternalMutation | null | undefined
) => ClientExternalFragment[];
type UpdateClientExternalObjectsLazyMutationFn = MutationTuple<
  UpdateClientExternalMutation,
  UpdateClientExternalMutationVariables
>;
type UpdateClientExternalObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<UpdateClientExternalMutation, UpdateClientExternalMutationVariables>
) => Promise<UpdateClientExternalObjectsMutationResultEx>;
export type UpdateClientExternalObjectsLazyMutationReturn = [
  UpdateClientExternalObjectsWrappedLazyMutationFn,
  UpdateClientExternalObjectsMutationResultEx
];

function useUpdateClientExternalObjects(
  options?: Omit<MutationHookOptions<UpdateClientExternalMutation, UpdateClientExternalMutationVariables>, 'mutation'>
): UpdateClientExternalObjectsLazyMutationReturn {
  const lazyMutation: UpdateClientExternalObjectsLazyMutationFn = useMutation<
    UpdateClientExternalMutation,
    UpdateClientExternalMutationVariables
  >(UpdateClientExternalDocument, options);

  const pickObjects: PickUpdateClientExternalObjectsFn = (
    mutation: UpdateClientExternalMutation | null | undefined
  ) => {
    return mutation?.update_client?.returning || [];
  };

  const wrappedLazyMutation: UpdateClientExternalObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: UpdateClientExternalObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Delete Hooks
 */

// Types
type RemoveClientModelByIdFetchResult = FetchResult<
  RemoveClientModelByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveClientModelByIdMutationResultEx = RemoveClientModelByIdFetchResult & RemoveEntitiesQueryHookResultEx;

// Function
type PickRemoveClientModelFn = (mutation: RemoveClientModelByIdMutation | null | undefined) => number;
type RemoveClientModelLazyMutationFn = MutationTuple<
  RemoveClientModelByIdMutation,
  RemoveClientModelByIdMutationVariables
>;
type RemoveClientModelWrappedLazyMutationFn = ({
  clientId,
  autoOptimisticResponse,
  options,
}: {
  clientId: string;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<RemoveClientModelByIdMutation, RemoveClientModelByIdMutationVariables>,
    'variables'
  >;
}) => Promise<RemoveClientModelByIdMutationResultEx>;
export type RemoveClientModelLazyMutationReturn = [
  RemoveClientModelWrappedLazyMutationFn,
  RemoveClientModelByIdMutationResultEx
];

function useRemoveClientModelById(
  options?: Omit<
    MutationHookOptions<RemoveClientModelByIdMutation, RemoveClientModelByIdMutationVariables>,
    'mutation' | 'variables'
  >
) {
  const lazyMutation: RemoveClientModelLazyMutationFn = useMutation<
    RemoveClientModelByIdMutation,
    RemoveClientModelByIdMutationVariables
  >(RemoveClientModelByIdDocument, options);

  const pickAffectedRows: PickRemoveClientModelFn = (mutation: RemoveClientModelByIdMutation | null | undefined) => {
    return mutation?.delete_client?.affected_rows || 0;
  };

  const wrappedLazyMutation: RemoveClientModelWrappedLazyMutationFn = async ({
    clientId,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<
      RemoveClientModelMutation,
      RemoveClientModelByIdMutationVariables
    > = { variables: { id: clientId }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<RemoveClientModelMutation>({
        operationType: 'delete',
        entityName: 'client',
        objects: [{ id: clientId }],
      });
    }
    if (!options || !options.update) {
      mutationOptions.update = generateUpdateFunctionForMutation<RemoveClientModelByIdMutation>({
        operationType: 'delete',
        entityName: 'client',
        entityId: clientId,
      });
    }

    const fetchResult: RemoveClientModelByIdFetchResult = await lazyMutation[0](mutationOptions);
    return { ...fetchResult, affected_rows: pickAffectedRows(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], affected_rows: pickAffectedRows(lazyMutation[1].data) }] as [
    typeof wrappedLazyMutation,
    typeof lazyMutation[1] & { affected_rows: ReturnType<typeof pickAffectedRows> }
  ];
}

//
//

// Types
type RemoveClientModelObjectsMutationResult = FetchResult<
  RemoveClientModelMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveClientModelObjectsMutationResultEx = RemoveClientModelObjectsMutationResult &
  RemoveEntitiesQueryHookResultEx;

// Function
type PickRemoveClientModelObjectsFn = (mutation: RemoveClientModelMutation | null | undefined) => number;
type RemoveClientModelObjectsLazyMutationFn = MutationTuple<
  RemoveClientModelMutation,
  RemoveClientModelMutationVariables
>;
type RemoveClientModelObjectsWrappedLazyMutationFn = (
  options: MutationFunctionOptions<RemoveClientModelMutation, RemoveClientModelMutationVariables>
) => Promise<RemoveClientModelObjectsMutationResultEx>;
export type RemoveClientModelObjectsLazyMutationReturn = [
  RemoveClientModelObjectsWrappedLazyMutationFn,
  RemoveClientModelObjectsMutationResultEx
];

function useRemoveClientModelObjects(
  options?: Omit<MutationHookOptions<RemoveClientModelMutation, RemoveClientModelMutationVariables>, 'mutation'>
): RemoveClientModelObjectsLazyMutationReturn {
  const lazyMutation = useMutation<RemoveClientModelMutation, RemoveClientModelMutationVariables>(
    RemoveClientModelDocument,
    options
  );

  const pickAffectedRows: PickRemoveClientModelObjectsFn = (mutation: RemoveClientModelMutation | null | undefined) => {
    return mutation?.delete_client?.affected_rows || 0;
  };

  const wrappedLazyMutation: RemoveClientModelObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: RemoveClientModelObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, affected_rows: pickAffectedRows(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], affected_rows: pickAffectedRows(lazyMutation[1].data) }];
}

// ClientExternal Fragment Helper Object
//------------------------------------------------

export const ClientExternalFragmentGQLHooks = {
  useQueryById: useQueryClientExternalById,
  useQueryByIdLazy: useQueryClientExternalByIdLazy,
  useQueryObjects: useQueryClientExternalObjects,
  useQueryObjectsLazy: useQueryClientExternalObjectsLazy,
  useSubscribeToById: useSubscribeToClientExternalById,
  useSubscribeToObjects: useSubscribeToClientExternalObjects,
  useInsert: useInsertClientExternal,
  useInsertWithOnConflict: useInsertClientExternalWithOnConflict,
  useInsertObjects: useInsertClientExternalObjects,
  useUpdateById: useUpdateClientExternalById,
  useUpdateObjects: useUpdateClientExternalObjects,
};

// client MODEL HOOKS OBJECT
//------------------------------------------------

export const ClientModelGQLHooks = {
  useRemoveById: useRemoveClientModelById,
  useRemoveObjects: useRemoveClientModelObjects,
};

// client REACT
//------------------------------------------------

export type ClientQaByIdHookResultEx = { clientQa: ClientQaFragment | null | undefined };
export type ClientQaObjectsHookResultEx = { objects: ClientQaFragment[] };

/**
 *  Query Hook
 */

// Types
type QueryClientQaByIdResult = LazyQueryResult<QueryClientQaByIdQuery, QueryClientQaByIdQueryVariables>;
type QueryClientQaByIdSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<QueryClientQaByIdQuery, QueryClientQaByIdQueryVariables, QueryClientQaByIdQuery>,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryClientQaByIdResultEx = Omit<QueryClientQaByIdResult, 'subscribeToMore'> & {
  subscribeToMore: QueryClientQaByIdSubScribeToMore;
} & ClientQaByIdHookResultEx;

// Function
function useQueryClientQaById({
  clientId,
  options,
}: {
  clientId: string;
  options?: Omit<QueryHookOptions<QueryClientQaByIdQuery, QueryClientQaByIdQueryVariables>, 'query' | 'variables'>;
}): QueryClientQaByIdResultEx {
  const _query: QueryClientQaByIdResult = useQuery<QueryClientQaByIdQuery, QueryClientQaByIdQueryVariables>(
    QueryClientQaByIdDocument,
    { variables: { clientId }, ...options }
  );

  const typedSubscribeToMore: QueryClientQaByIdSubScribeToMore = (options) => {
    _query.subscribeToMore({
      document: QueryClientQaByIdDocument,
      variables: { clientId } as QueryClientQaByIdQueryVariables,
      ...options,
    });
  };
  const { subscribeToMore, ...query } = _query;
  return { ...query, subscribeToMore: typedSubscribeToMore, clientQa: query?.data?.client_by_pk };
}

/**
 *  Lazy Query Hook
 */

// Types
type PickQueryClientQaByIdFn = (
  query: QueryClientQaByIdQuery | null | undefined
) => ClientQaFragment | null | undefined;
type QueryClientQaByIdLazyFn = [
  (options?: QueryLazyOptions<QueryClientQaByIdQueryVariables> | undefined) => void,
  QueryClientQaByIdResult
];
type QueryClientQaByIdWrappedLazyFn = (
  options: Omit<QueryLazyOptions<QueryClientQaByIdQueryVariables>, 'variables'>
) => void;
export type QueryClientQaByIdLazyReturn = [QueryClientQaByIdWrappedLazyFn, QueryClientQaByIdResultEx];

// Function
function useQueryClientQaByIdLazy({
  clientId,
  options,
}: {
  clientId: string;
  options?: Omit<QueryHookOptions<QueryClientQaByIdQuery, QueryClientQaByIdQueryVariables>, 'query' | 'variables'>;
}): QueryClientQaByIdLazyReturn {
  const lazyQuery: QueryClientQaByIdLazyFn = useLazyQuery<QueryClientQaByIdQuery, QueryClientQaByIdQueryVariables>(
    QueryClientQaByIdDocument,
    options
  );

  // Setting up typed version of lazyQuery
  const pickQueryClientQaById: PickQueryClientQaByIdFn = (query) => {
    return query && query.client_by_pk;
  };
  const wrappedLazyQuery: QueryClientQaByIdWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryClientQaByIdSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({
        document: QueryClientQaByIdDocument,
        variables: { clientId } as QueryClientQaByIdQueryVariables,
        ...options,
      });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    { ...lazyQueryResult, subscribeToMore: typedSubcribeToMore, clientQa: pickQueryClientQaById(lazyQuery[1].data) },
  ];
}

/**
 *  Query Collection Hook
 */

// Types
export type QueryClientQaObjectsResult = LazyQueryResult<QueryClientQaObjectsQuery, QueryClientQaObjectsQueryVariables>;
type QueryClientQaObjectsSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<
          QueryClientQaObjectsQuery,
          QueryClientQaObjectsQueryVariables,
          QueryClientQaObjectsQuery
        >,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryClientQaObjectsResultEx = Omit<QueryClientQaObjectsResult, 'subscribeToMore'> & {
  subscribeToMore: QueryClientQaObjectsSubScribeToMore;
} & ClientQaObjectsHookResultEx;

// Function
function useQueryClientQaObjects(
  options: Omit<QueryHookOptions<QueryClientQaObjectsQuery, QueryClientQaObjectsQueryVariables>, 'query'>
): QueryClientQaObjectsResultEx {
  const _query: QueryClientQaObjectsResult = useQuery<QueryClientQaObjectsQuery, QueryClientQaObjectsQueryVariables>(
    QueryClientQaObjectsDocument,
    options
  );

  const typedSubscribeToMore: QueryClientQaObjectsSubScribeToMore = (options) => {
    _query.subscribeToMore({ document: QueryClientQaObjectsDocument, ...options });
  };
  const { subscribeToMore, ...query } = _query;

  return { ...query, subscribeToMore: typedSubscribeToMore, objects: query?.data?.client || [] };
}

/**
 *  Lazy Query Collection Hook
 */

// Types
type PickQueryClientQaObjectsFn = (query: QueryClientQaObjectsQuery | null | undefined) => ClientQaFragment[];
type QueryClientQaObjectsLazyFn = [
  (options?: QueryLazyOptions<QueryClientQaObjectsQueryVariables> | undefined) => void,
  QueryClientQaObjectsResult
];
type QueryClientQaObjectsWrappedLazyFn = (options?: QueryLazyOptions<QueryClientQaObjectsQueryVariables>) => void;
export type QueryClientQaObjectsLazyReturn = [QueryClientQaObjectsWrappedLazyFn, QueryClientQaObjectsResultEx];

// Function
function useQueryClientQaObjectsLazy(
  options?: Omit<LazyQueryHookOptions<QueryClientQaObjectsQuery, QueryClientQaObjectsQueryVariables>, 'query'>
): QueryClientQaObjectsLazyReturn {
  const lazyQuery: QueryClientQaObjectsLazyFn = useLazyQuery<
    QueryClientQaObjectsQuery,
    QueryClientQaObjectsQueryVariables
  >(QueryClientQaObjectsDocument, options);

  // Setting up typed version of lazyQuery
  const pickObjects: PickQueryClientQaObjectsFn = (query: QueryClientQaObjectsQuery | null | undefined) => {
    return query?.client || [];
  };
  const wrappedLazyQuery: QueryClientQaObjectsWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryClientQaObjectsSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({ document: QueryClientQaObjectsDocument, ...options });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    { ...lazyQueryResult, subscribeToMore: typedSubcribeToMore, objects: pickObjects(lazyQuery[1].data) },
  ];
}

/**
 *  Subscription Hook
 */

// Types
type SubscribeToClientQaByIdResult = {
  variables?: SubscribeToClientQaByIdSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToClientQaByIdSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToClientQaByIdResultEx = SubscribeToClientQaByIdResult & ClientQaByIdHookResultEx;

// Function
function useSubscribeToClientQaById({
  clientId,
  options,
}: {
  clientId: string;
  options?: Omit<
    SubscriptionHookOptions<SubscribeToClientQaByIdSubscription, SubscribeToClientQaByIdSubscriptionVariables>,
    'query' | 'variables'
  >;
}): SubscribeToClientQaByIdResultEx {
  const subscription: SubscribeToClientQaByIdResult = useSubscription<
    SubscribeToClientQaByIdSubscription,
    SubscribeToClientQaByIdSubscriptionVariables
  >(SubscribeToClientQaByIdDocument, { variables: { clientId }, ...options });
  return { ...subscription, clientQa: subscription?.data?.client_by_pk };
}

/**
 *  Subscription Collection Hook
 */

// Types
export type SubscribeToClientQaObjectsResult = {
  variables?: SubscribeToClientQaObjectsSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToClientQaObjectsSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToClientQaObjectsResultEx = SubscribeToClientQaObjectsResult & ClientQaObjectsHookResultEx;

// Function
function useSubscribeToClientQaObjects(
  options: Omit<
    SubscriptionHookOptions<SubscribeToClientQaObjectsSubscription, SubscribeToClientQaObjectsSubscriptionVariables>,
    'query'
  >
): SubscribeToClientQaObjectsResultEx {
  const subscription: SubscribeToClientQaObjectsResult = useSubscription<
    SubscribeToClientQaObjectsSubscription,
    SubscribeToClientQaObjectsSubscriptionVariables
  >(SubscribeToClientQaObjectsDocument, options);
  return { ...subscription, objects: subscription?.data?.client || [] };
}

/**
 *  Insert Hooks
 */

// Types
type InsertClientQaMutationResult = FetchResult<InsertClientQaMutation, Record<string, any>, Record<string, any>>;
export type InsertClientQaMutationResultEx = InsertClientQaMutationResult & ClientQaByIdHookResultEx;

type PickInsertClientQaFn = (
  mutation: InsertClientQaMutation | null | undefined
) => ClientQaFragment | null | undefined;
type InsertClientQaLazyMutationFn = MutationTuple<InsertClientQaMutation, InsertClientQaMutationVariables>;
type InsertClientQaWrappedLazyMutationFn = ({
  client,
  autoOptimisticResponse,
  options,
}: {
  client: Client_Insert_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<MutationFunctionOptions<InsertClientQaMutation, InsertClientQaMutationVariables>, 'variables'>;
}) => Promise<InsertClientQaMutationResultEx>;
export type InsertClientQaLazyMutationReturn = [InsertClientQaWrappedLazyMutationFn, InsertClientQaMutationResultEx];

// Function
function useInsertClientQa(
  options?: Omit<MutationHookOptions<InsertClientQaMutation, InsertClientQaMutationVariables>, 'mutation' | 'variables'>
): InsertClientQaLazyMutationReturn {
  const lazyMutation: InsertClientQaLazyMutationFn = useMutation<
    InsertClientQaMutation,
    InsertClientQaMutationVariables
  >(InsertClientQaDocument, options);
  const pickClientQa: PickInsertClientQaFn = (mutation) => {
    return mutation?.insert_client?.returning && mutation?.insert_client?.returning[0];
  };

  const wrappedLazyMutation: InsertClientQaWrappedLazyMutationFn = async ({
    client,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: client });
    const mutationOptions: MutationFunctionOptions<InsertClientQaMutation, InsertClientQaMutationVariables> = {
      variables: { objects: [objectForInsert] },
      ...options,
    };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id) throw new Error(`if autoOptimisticResponse = true, id must be set in object 'client'`);
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertClientQaMutation>({
        operationType: 'insert',
        entityName: 'client',
        objects: [objectForInsert as Client_Insert_Input & ObjectWithId],
      });
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, clientQa: pickClientQa(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], clientQa: pickClientQa(lazyMutation[1].data) }];
}

//
//

// Types
type InsertClientQaWithOnConflictMutationResult = FetchResult<
  InsertClientQaWithOnConflictMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertClientQaWithOnConflictMutationResultEx = InsertClientQaWithOnConflictMutationResult &
  ClientQaByIdHookResultEx;

type InsertClientQaWithOnConflictLazyMutationFn = MutationTuple<
  InsertClientQaWithOnConflictMutation,
  InsertClientQaWithOnConflictMutationVariables
>;
type InsertClientQaWithOnConflictWrappedLazyMutationFn = ({
  client,
  onConflict,
  autoOptimisticResponse,
  options,
}: {
  client: Client_Insert_Input;
  onConflict: Client_On_Conflict;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<InsertClientQaWithOnConflictMutation, InsertClientQaWithOnConflictMutationVariables>,
    'variables'
  >;
}) => Promise<InsertClientQaWithOnConflictMutationResultEx>;
export type InsertClientQaWithOnConflictLazyMutationReturn = [
  InsertClientQaWithOnConflictWrappedLazyMutationFn,
  InsertClientQaWithOnConflictMutationResultEx
];

// Function
function useInsertClientQaWithOnConflict(
  options?: Omit<
    MutationHookOptions<InsertClientQaWithOnConflictMutation, InsertClientQaWithOnConflictMutationVariables>,
    'mutation' | 'variables'
  >
): InsertClientQaWithOnConflictLazyMutationReturn {
  const lazyMutation: InsertClientQaWithOnConflictLazyMutationFn = useMutation<
    InsertClientQaWithOnConflictMutation,
    InsertClientQaWithOnConflictMutationVariables
  >(InsertClientQaWithOnConflictDocument, options);
  const pickClientQa: PickInsertClientQaFn = (mutation: InsertClientQaWithOnConflictMutation | null | undefined) => {
    return mutation?.insert_client?.returning && mutation.insert_client.returning[0];
  };

  const wrappedLazyMutation: InsertClientQaWithOnConflictWrappedLazyMutationFn = async ({
    client,
    onConflict,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: client });
    const mutationOptions: MutationFunctionOptions<
      InsertClientQaWithOnConflictMutation,
      InsertClientQaWithOnConflictMutationVariables
    > = { variables: { objects: [objectForInsert], onConflict }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id) throw new Error(`if autoOptimisticResponse = true, id must be set in object 'client'`);
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertClientQaWithOnConflictMutation>({
        operationType: 'insert',
        entityName: 'client',
        objects: [objectForInsert as Client_Insert_Input & ObjectWithId],
      });
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, clientQa: pickClientQa(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], clientQa: pickClientQa(lazyMutation[1].data) }];
}

// Types
type InsertClientQaObjectsMutationResult = FetchResult<
  InsertClientQaMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertClientQaObjectsMutationResultEx = InsertClientQaMutationResult & ClientQaObjectsHookResultEx;

type PickInsertClientQaObjectsFn = (mutation: InsertClientQaMutation | null | undefined) => ClientQaFragment[];
type InsertClientQaObjectsLazyMutationFn = MutationTuple<InsertClientQaMutation, InsertClientQaMutationVariables>;
type InsertClientQaObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<InsertClientQaMutation, InsertClientQaMutationVariables>
) => Promise<InsertClientQaObjectsMutationResultEx>;
export type InsertClientQaObjectsLazyMutationReturn = [
  InsertClientQaObjectsWrappedLazyMutationFn,
  InsertClientQaObjectsMutationResultEx
];

// Function
function useInsertClientQaObjects(
  options?: Omit<MutationHookOptions<InsertClientQaMutation, InsertClientQaMutationVariables>, 'mutation'>
): InsertClientQaObjectsLazyMutationReturn {
  const lazyMutation: InsertClientQaObjectsLazyMutationFn = useMutation<
    InsertClientQaMutation,
    InsertClientQaMutationVariables
  >(InsertClientQaDocument, options);
  const pickObjects: PickInsertClientQaObjectsFn = (mutation: InsertClientQaMutation | null | undefined) => {
    return mutation?.insert_client?.returning || [];
  };

  const wrappedLazyMutation: InsertClientQaObjectsWrappedLazyMutationFn = async (options) => {
    if (options && options.variables && options.variables.objects)
      options.variables.objects = options.variables.objects.map((objectItem) =>
        stripInsertInputClientFields({ input: objectItem })
      );
    const fetchResult: InsertClientQaObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Update Hooks
 */

type UpdateClientQaByIdMutationResult = FetchResult<
  UpdateClientQaByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateClientQaByIdMutationResultEx = UpdateClientQaByIdMutationResult & ClientQaByIdHookResultEx;

type PickUpdateClientQaByIdFn = (
  mutation: UpdateClientQaByIdMutation | null | undefined
) => ClientQaFragment | null | undefined;
type UpdateClientQaByIdLazyMutationFn = MutationTuple<UpdateClientQaByIdMutation, UpdateClientQaByIdMutationVariables>;
type UpdateClientQaByIdWrappedLazyMutationFn = ({
  clientId,
  set,
  autoOptimisticResponse,
  options,
}: {
  clientId: string;
  set: Client_Set_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<MutationFunctionOptions<UpdateClientQaByIdMutation, UpdateClientQaByIdMutationVariables>, 'variables'>;
}) => Promise<UpdateClientQaByIdMutationResultEx>;
export type UpdateClientQaByIdLazyMutationReturn = [
  UpdateClientQaByIdWrappedLazyMutationFn,
  UpdateClientQaByIdMutationResultEx
];

function useUpdateClientQaById(
  options?: Omit<
    MutationHookOptions<UpdateClientQaByIdMutation, UpdateClientQaByIdMutationVariables>,
    'mutation' | 'variables'
  >
): UpdateClientQaByIdLazyMutationReturn {
  const lazyMutation: UpdateClientQaByIdLazyMutationFn = useMutation<
    UpdateClientQaByIdMutation,
    UpdateClientQaByIdMutationVariables
  >(UpdateClientQaByIdDocument, options);

  const pickClientQa: PickUpdateClientQaByIdFn = (mutation) => {
    return mutation?.update_client?.returning && mutation.update_client!.returning[0];
  };

  const wrappedLazyMutation: UpdateClientQaByIdWrappedLazyMutationFn = async ({
    clientId,
    set,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<UpdateClientQaByIdMutation, UpdateClientQaByIdMutationVariables> = {
      variables: { id: clientId, set },
      ...options,
    };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateClientQaByIdMutation>({
        operationType: 'update',
        entityName: 'client',
        objects: [{ id: clientId, ...set }],
      });
    }

    const fetchResult: UpdateClientQaByIdMutationResult = await lazyMutation[0]({
      variables: { id: clientId, set },
      ...mutationOptions,
    });
    return { ...fetchResult, clientQa: pickClientQa(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], clientQa: pickClientQa(lazyMutation[1].data) }];
}

//
//

// Types
type UpdateClientQaObjectsMutationResult = FetchResult<
  UpdateClientQaMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateClientQaObjectsMutationResultEx = UpdateClientQaObjectsMutationResult & ClientQaObjectsHookResultEx;

// Function
type PickUpdateClientQaObjectsFn = (mutation: UpdateClientQaMutation | null | undefined) => ClientQaFragment[];
type UpdateClientQaObjectsLazyMutationFn = MutationTuple<UpdateClientQaMutation, UpdateClientQaMutationVariables>;
type UpdateClientQaObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<UpdateClientQaMutation, UpdateClientQaMutationVariables>
) => Promise<UpdateClientQaObjectsMutationResultEx>;
export type UpdateClientQaObjectsLazyMutationReturn = [
  UpdateClientQaObjectsWrappedLazyMutationFn,
  UpdateClientQaObjectsMutationResultEx
];

function useUpdateClientQaObjects(
  options?: Omit<MutationHookOptions<UpdateClientQaMutation, UpdateClientQaMutationVariables>, 'mutation'>
): UpdateClientQaObjectsLazyMutationReturn {
  const lazyMutation: UpdateClientQaObjectsLazyMutationFn = useMutation<
    UpdateClientQaMutation,
    UpdateClientQaMutationVariables
  >(UpdateClientQaDocument, options);

  const pickObjects: PickUpdateClientQaObjectsFn = (mutation: UpdateClientQaMutation | null | undefined) => {
    return mutation?.update_client?.returning || [];
  };

  const wrappedLazyMutation: UpdateClientQaObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: UpdateClientQaObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

// ClientQa Fragment Helper Object
//------------------------------------------------

export const ClientQaFragmentGQLHooks = {
  useQueryById: useQueryClientQaById,
  useQueryByIdLazy: useQueryClientQaByIdLazy,
  useQueryObjects: useQueryClientQaObjects,
  useQueryObjectsLazy: useQueryClientQaObjectsLazy,
  useSubscribeToById: useSubscribeToClientQaById,
  useSubscribeToObjects: useSubscribeToClientQaObjects,
  useInsert: useInsertClientQa,
  useInsertWithOnConflict: useInsertClientQaWithOnConflict,
  useInsertObjects: useInsertClientQaObjects,
  useUpdateById: useUpdateClientQaById,
  useUpdateObjects: useUpdateClientQaObjects,
};

// client REACT
//------------------------------------------------

export type ClientInternalByIdHookResultEx = { clientInternal: ClientInternalFragment | null | undefined };
export type ClientInternalObjectsHookResultEx = { objects: ClientInternalFragment[] };

/**
 *  Query Hook
 */

// Types
type QueryClientInternalByIdResult = LazyQueryResult<
  QueryClientInternalByIdQuery,
  QueryClientInternalByIdQueryVariables
>;
type QueryClientInternalByIdSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<
          QueryClientInternalByIdQuery,
          QueryClientInternalByIdQueryVariables,
          QueryClientInternalByIdQuery
        >,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryClientInternalByIdResultEx = Omit<QueryClientInternalByIdResult, 'subscribeToMore'> & {
  subscribeToMore: QueryClientInternalByIdSubScribeToMore;
} & ClientInternalByIdHookResultEx;

// Function
function useQueryClientInternalById({
  clientId,
  options,
}: {
  clientId: string;
  options?: Omit<
    QueryHookOptions<QueryClientInternalByIdQuery, QueryClientInternalByIdQueryVariables>,
    'query' | 'variables'
  >;
}): QueryClientInternalByIdResultEx {
  const _query: QueryClientInternalByIdResult = useQuery<
    QueryClientInternalByIdQuery,
    QueryClientInternalByIdQueryVariables
  >(QueryClientInternalByIdDocument, { variables: { clientId }, ...options });

  const typedSubscribeToMore: QueryClientInternalByIdSubScribeToMore = (options) => {
    _query.subscribeToMore({
      document: QueryClientInternalByIdDocument,
      variables: { clientId } as QueryClientInternalByIdQueryVariables,
      ...options,
    });
  };
  const { subscribeToMore, ...query } = _query;
  return { ...query, subscribeToMore: typedSubscribeToMore, clientInternal: query?.data?.client_by_pk };
}

/**
 *  Lazy Query Hook
 */

// Types
type PickQueryClientInternalByIdFn = (
  query: QueryClientInternalByIdQuery | null | undefined
) => ClientInternalFragment | null | undefined;
type QueryClientInternalByIdLazyFn = [
  (options?: QueryLazyOptions<QueryClientInternalByIdQueryVariables> | undefined) => void,
  QueryClientInternalByIdResult
];
type QueryClientInternalByIdWrappedLazyFn = (
  options: Omit<QueryLazyOptions<QueryClientInternalByIdQueryVariables>, 'variables'>
) => void;
export type QueryClientInternalByIdLazyReturn = [QueryClientInternalByIdWrappedLazyFn, QueryClientInternalByIdResultEx];

// Function
function useQueryClientInternalByIdLazy({
  clientId,
  options,
}: {
  clientId: string;
  options?: Omit<
    QueryHookOptions<QueryClientInternalByIdQuery, QueryClientInternalByIdQueryVariables>,
    'query' | 'variables'
  >;
}): QueryClientInternalByIdLazyReturn {
  const lazyQuery: QueryClientInternalByIdLazyFn = useLazyQuery<
    QueryClientInternalByIdQuery,
    QueryClientInternalByIdQueryVariables
  >(QueryClientInternalByIdDocument, options);

  // Setting up typed version of lazyQuery
  const pickQueryClientInternalById: PickQueryClientInternalByIdFn = (query) => {
    return query && query.client_by_pk;
  };
  const wrappedLazyQuery: QueryClientInternalByIdWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryClientInternalByIdSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({
        document: QueryClientInternalByIdDocument,
        variables: { clientId } as QueryClientInternalByIdQueryVariables,
        ...options,
      });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    {
      ...lazyQueryResult,
      subscribeToMore: typedSubcribeToMore,
      clientInternal: pickQueryClientInternalById(lazyQuery[1].data),
    },
  ];
}

/**
 *  Query Collection Hook
 */

// Types
export type QueryClientInternalObjectsResult = LazyQueryResult<
  QueryClientInternalObjectsQuery,
  QueryClientInternalObjectsQueryVariables
>;
type QueryClientInternalObjectsSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<
          QueryClientInternalObjectsQuery,
          QueryClientInternalObjectsQueryVariables,
          QueryClientInternalObjectsQuery
        >,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryClientInternalObjectsResultEx = Omit<QueryClientInternalObjectsResult, 'subscribeToMore'> & {
  subscribeToMore: QueryClientInternalObjectsSubScribeToMore;
} & ClientInternalObjectsHookResultEx;

// Function
function useQueryClientInternalObjects(
  options: Omit<QueryHookOptions<QueryClientInternalObjectsQuery, QueryClientInternalObjectsQueryVariables>, 'query'>
): QueryClientInternalObjectsResultEx {
  const _query: QueryClientInternalObjectsResult = useQuery<
    QueryClientInternalObjectsQuery,
    QueryClientInternalObjectsQueryVariables
  >(QueryClientInternalObjectsDocument, options);

  const typedSubscribeToMore: QueryClientInternalObjectsSubScribeToMore = (options) => {
    _query.subscribeToMore({ document: QueryClientInternalObjectsDocument, ...options });
  };
  const { subscribeToMore, ...query } = _query;

  return { ...query, subscribeToMore: typedSubscribeToMore, objects: query?.data?.client || [] };
}

/**
 *  Lazy Query Collection Hook
 */

// Types
type PickQueryClientInternalObjectsFn = (
  query: QueryClientInternalObjectsQuery | null | undefined
) => ClientInternalFragment[];
type QueryClientInternalObjectsLazyFn = [
  (options?: QueryLazyOptions<QueryClientInternalObjectsQueryVariables> | undefined) => void,
  QueryClientInternalObjectsResult
];
type QueryClientInternalObjectsWrappedLazyFn = (
  options?: QueryLazyOptions<QueryClientInternalObjectsQueryVariables>
) => void;
export type QueryClientInternalObjectsLazyReturn = [
  QueryClientInternalObjectsWrappedLazyFn,
  QueryClientInternalObjectsResultEx
];

// Function
function useQueryClientInternalObjectsLazy(
  options?: Omit<
    LazyQueryHookOptions<QueryClientInternalObjectsQuery, QueryClientInternalObjectsQueryVariables>,
    'query'
  >
): QueryClientInternalObjectsLazyReturn {
  const lazyQuery: QueryClientInternalObjectsLazyFn = useLazyQuery<
    QueryClientInternalObjectsQuery,
    QueryClientInternalObjectsQueryVariables
  >(QueryClientInternalObjectsDocument, options);

  // Setting up typed version of lazyQuery
  const pickObjects: PickQueryClientInternalObjectsFn = (query: QueryClientInternalObjectsQuery | null | undefined) => {
    return query?.client || [];
  };
  const wrappedLazyQuery: QueryClientInternalObjectsWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryClientInternalObjectsSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({ document: QueryClientInternalObjectsDocument, ...options });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    { ...lazyQueryResult, subscribeToMore: typedSubcribeToMore, objects: pickObjects(lazyQuery[1].data) },
  ];
}

/**
 *  Subscription Hook
 */

// Types
type SubscribeToClientInternalByIdResult = {
  variables?: SubscribeToClientInternalByIdSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToClientInternalByIdSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToClientInternalByIdResultEx = SubscribeToClientInternalByIdResult &
  ClientInternalByIdHookResultEx;

// Function
function useSubscribeToClientInternalById({
  clientId,
  options,
}: {
  clientId: string;
  options?: Omit<
    SubscriptionHookOptions<
      SubscribeToClientInternalByIdSubscription,
      SubscribeToClientInternalByIdSubscriptionVariables
    >,
    'query' | 'variables'
  >;
}): SubscribeToClientInternalByIdResultEx {
  const subscription: SubscribeToClientInternalByIdResult = useSubscription<
    SubscribeToClientInternalByIdSubscription,
    SubscribeToClientInternalByIdSubscriptionVariables
  >(SubscribeToClientInternalByIdDocument, { variables: { clientId }, ...options });
  return { ...subscription, clientInternal: subscription?.data?.client_by_pk };
}

/**
 *  Subscription Collection Hook
 */

// Types
export type SubscribeToClientInternalObjectsResult = {
  variables?: SubscribeToClientInternalObjectsSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToClientInternalObjectsSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToClientInternalObjectsResultEx = SubscribeToClientInternalObjectsResult &
  ClientInternalObjectsHookResultEx;

// Function
function useSubscribeToClientInternalObjects(
  options: Omit<
    SubscriptionHookOptions<
      SubscribeToClientInternalObjectsSubscription,
      SubscribeToClientInternalObjectsSubscriptionVariables
    >,
    'query'
  >
): SubscribeToClientInternalObjectsResultEx {
  const subscription: SubscribeToClientInternalObjectsResult = useSubscription<
    SubscribeToClientInternalObjectsSubscription,
    SubscribeToClientInternalObjectsSubscriptionVariables
  >(SubscribeToClientInternalObjectsDocument, options);
  return { ...subscription, objects: subscription?.data?.client || [] };
}

/**
 *  Insert Hooks
 */

// Types
type InsertClientInternalMutationResult = FetchResult<
  InsertClientInternalMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertClientInternalMutationResultEx = InsertClientInternalMutationResult & ClientInternalByIdHookResultEx;

type PickInsertClientInternalFn = (
  mutation: InsertClientInternalMutation | null | undefined
) => ClientInternalFragment | null | undefined;
type InsertClientInternalLazyMutationFn = MutationTuple<
  InsertClientInternalMutation,
  InsertClientInternalMutationVariables
>;
type InsertClientInternalWrappedLazyMutationFn = ({
  client,
  autoOptimisticResponse,
  options,
}: {
  client: Client_Insert_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<InsertClientInternalMutation, InsertClientInternalMutationVariables>,
    'variables'
  >;
}) => Promise<InsertClientInternalMutationResultEx>;
export type InsertClientInternalLazyMutationReturn = [
  InsertClientInternalWrappedLazyMutationFn,
  InsertClientInternalMutationResultEx
];

// Function
function useInsertClientInternal(
  options?: Omit<
    MutationHookOptions<InsertClientInternalMutation, InsertClientInternalMutationVariables>,
    'mutation' | 'variables'
  >
): InsertClientInternalLazyMutationReturn {
  const lazyMutation: InsertClientInternalLazyMutationFn = useMutation<
    InsertClientInternalMutation,
    InsertClientInternalMutationVariables
  >(InsertClientInternalDocument, options);
  const pickClientInternal: PickInsertClientInternalFn = (mutation) => {
    return mutation?.insert_client?.returning && mutation?.insert_client?.returning[0];
  };

  const wrappedLazyMutation: InsertClientInternalWrappedLazyMutationFn = async ({
    client,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: client });
    const mutationOptions: MutationFunctionOptions<
      InsertClientInternalMutation,
      InsertClientInternalMutationVariables
    > = { variables: { objects: [objectForInsert] }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id) throw new Error(`if autoOptimisticResponse = true, id must be set in object 'client'`);
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertClientInternalMutation>({
        operationType: 'insert',
        entityName: 'client',
        objects: [objectForInsert as Client_Insert_Input & ObjectWithId],
      });
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, clientInternal: pickClientInternal(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], clientInternal: pickClientInternal(lazyMutation[1].data) }];
}

//
//

// Types
type InsertClientInternalWithOnConflictMutationResult = FetchResult<
  InsertClientInternalWithOnConflictMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertClientInternalWithOnConflictMutationResultEx = InsertClientInternalWithOnConflictMutationResult &
  ClientInternalByIdHookResultEx;

type InsertClientInternalWithOnConflictLazyMutationFn = MutationTuple<
  InsertClientInternalWithOnConflictMutation,
  InsertClientInternalWithOnConflictMutationVariables
>;
type InsertClientInternalWithOnConflictWrappedLazyMutationFn = ({
  client,
  onConflict,
  autoOptimisticResponse,
  options,
}: {
  client: Client_Insert_Input;
  onConflict: Client_On_Conflict;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<
      InsertClientInternalWithOnConflictMutation,
      InsertClientInternalWithOnConflictMutationVariables
    >,
    'variables'
  >;
}) => Promise<InsertClientInternalWithOnConflictMutationResultEx>;
export type InsertClientInternalWithOnConflictLazyMutationReturn = [
  InsertClientInternalWithOnConflictWrappedLazyMutationFn,
  InsertClientInternalWithOnConflictMutationResultEx
];

// Function
function useInsertClientInternalWithOnConflict(
  options?: Omit<
    MutationHookOptions<
      InsertClientInternalWithOnConflictMutation,
      InsertClientInternalWithOnConflictMutationVariables
    >,
    'mutation' | 'variables'
  >
): InsertClientInternalWithOnConflictLazyMutationReturn {
  const lazyMutation: InsertClientInternalWithOnConflictLazyMutationFn = useMutation<
    InsertClientInternalWithOnConflictMutation,
    InsertClientInternalWithOnConflictMutationVariables
  >(InsertClientInternalWithOnConflictDocument, options);
  const pickClientInternal: PickInsertClientInternalFn = (
    mutation: InsertClientInternalWithOnConflictMutation | null | undefined
  ) => {
    return mutation?.insert_client?.returning && mutation.insert_client.returning[0];
  };

  const wrappedLazyMutation: InsertClientInternalWithOnConflictWrappedLazyMutationFn = async ({
    client,
    onConflict,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: client });
    const mutationOptions: MutationFunctionOptions<
      InsertClientInternalWithOnConflictMutation,
      InsertClientInternalWithOnConflictMutationVariables
    > = { variables: { objects: [objectForInsert], onConflict }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id) throw new Error(`if autoOptimisticResponse = true, id must be set in object 'client'`);
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertClientInternalWithOnConflictMutation>(
        {
          operationType: 'insert',
          entityName: 'client',
          objects: [objectForInsert as Client_Insert_Input & ObjectWithId],
        }
      );
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, clientInternal: pickClientInternal(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], clientInternal: pickClientInternal(lazyMutation[1].data) }];
}

// Types
type InsertClientInternalObjectsMutationResult = FetchResult<
  InsertClientInternalMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertClientInternalObjectsMutationResultEx = InsertClientInternalMutationResult &
  ClientInternalObjectsHookResultEx;

type PickInsertClientInternalObjectsFn = (
  mutation: InsertClientInternalMutation | null | undefined
) => ClientInternalFragment[];
type InsertClientInternalObjectsLazyMutationFn = MutationTuple<
  InsertClientInternalMutation,
  InsertClientInternalMutationVariables
>;
type InsertClientInternalObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<InsertClientInternalMutation, InsertClientInternalMutationVariables>
) => Promise<InsertClientInternalObjectsMutationResultEx>;
export type InsertClientInternalObjectsLazyMutationReturn = [
  InsertClientInternalObjectsWrappedLazyMutationFn,
  InsertClientInternalObjectsMutationResultEx
];

// Function
function useInsertClientInternalObjects(
  options?: Omit<MutationHookOptions<InsertClientInternalMutation, InsertClientInternalMutationVariables>, 'mutation'>
): InsertClientInternalObjectsLazyMutationReturn {
  const lazyMutation: InsertClientInternalObjectsLazyMutationFn = useMutation<
    InsertClientInternalMutation,
    InsertClientInternalMutationVariables
  >(InsertClientInternalDocument, options);
  const pickObjects: PickInsertClientInternalObjectsFn = (
    mutation: InsertClientInternalMutation | null | undefined
  ) => {
    return mutation?.insert_client?.returning || [];
  };

  const wrappedLazyMutation: InsertClientInternalObjectsWrappedLazyMutationFn = async (options) => {
    if (options && options.variables && options.variables.objects)
      options.variables.objects = options.variables.objects.map((objectItem) =>
        stripInsertInputClientFields({ input: objectItem })
      );
    const fetchResult: InsertClientInternalObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Update Hooks
 */

type UpdateClientInternalByIdMutationResult = FetchResult<
  UpdateClientInternalByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateClientInternalByIdMutationResultEx = UpdateClientInternalByIdMutationResult &
  ClientInternalByIdHookResultEx;

type PickUpdateClientInternalByIdFn = (
  mutation: UpdateClientInternalByIdMutation | null | undefined
) => ClientInternalFragment | null | undefined;
type UpdateClientInternalByIdLazyMutationFn = MutationTuple<
  UpdateClientInternalByIdMutation,
  UpdateClientInternalByIdMutationVariables
>;
type UpdateClientInternalByIdWrappedLazyMutationFn = ({
  clientId,
  set,
  autoOptimisticResponse,
  options,
}: {
  clientId: string;
  set: Client_Set_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<UpdateClientInternalByIdMutation, UpdateClientInternalByIdMutationVariables>,
    'variables'
  >;
}) => Promise<UpdateClientInternalByIdMutationResultEx>;
export type UpdateClientInternalByIdLazyMutationReturn = [
  UpdateClientInternalByIdWrappedLazyMutationFn,
  UpdateClientInternalByIdMutationResultEx
];

function useUpdateClientInternalById(
  options?: Omit<
    MutationHookOptions<UpdateClientInternalByIdMutation, UpdateClientInternalByIdMutationVariables>,
    'mutation' | 'variables'
  >
): UpdateClientInternalByIdLazyMutationReturn {
  const lazyMutation: UpdateClientInternalByIdLazyMutationFn = useMutation<
    UpdateClientInternalByIdMutation,
    UpdateClientInternalByIdMutationVariables
  >(UpdateClientInternalByIdDocument, options);

  const pickClientInternal: PickUpdateClientInternalByIdFn = (mutation) => {
    return mutation?.update_client?.returning && mutation.update_client!.returning[0];
  };

  const wrappedLazyMutation: UpdateClientInternalByIdWrappedLazyMutationFn = async ({
    clientId,
    set,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<
      UpdateClientInternalByIdMutation,
      UpdateClientInternalByIdMutationVariables
    > = { variables: { id: clientId, set }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateClientInternalByIdMutation>({
        operationType: 'update',
        entityName: 'client',
        objects: [{ id: clientId, ...set }],
      });
    }

    const fetchResult: UpdateClientInternalByIdMutationResult = await lazyMutation[0]({
      variables: { id: clientId, set },
      ...mutationOptions,
    });
    return { ...fetchResult, clientInternal: pickClientInternal(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], clientInternal: pickClientInternal(lazyMutation[1].data) }];
}

//
//

// Types
type UpdateClientInternalObjectsMutationResult = FetchResult<
  UpdateClientInternalMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateClientInternalObjectsMutationResultEx = UpdateClientInternalObjectsMutationResult &
  ClientInternalObjectsHookResultEx;

// Function
type PickUpdateClientInternalObjectsFn = (
  mutation: UpdateClientInternalMutation | null | undefined
) => ClientInternalFragment[];
type UpdateClientInternalObjectsLazyMutationFn = MutationTuple<
  UpdateClientInternalMutation,
  UpdateClientInternalMutationVariables
>;
type UpdateClientInternalObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<UpdateClientInternalMutation, UpdateClientInternalMutationVariables>
) => Promise<UpdateClientInternalObjectsMutationResultEx>;
export type UpdateClientInternalObjectsLazyMutationReturn = [
  UpdateClientInternalObjectsWrappedLazyMutationFn,
  UpdateClientInternalObjectsMutationResultEx
];

function useUpdateClientInternalObjects(
  options?: Omit<MutationHookOptions<UpdateClientInternalMutation, UpdateClientInternalMutationVariables>, 'mutation'>
): UpdateClientInternalObjectsLazyMutationReturn {
  const lazyMutation: UpdateClientInternalObjectsLazyMutationFn = useMutation<
    UpdateClientInternalMutation,
    UpdateClientInternalMutationVariables
  >(UpdateClientInternalDocument, options);

  const pickObjects: PickUpdateClientInternalObjectsFn = (
    mutation: UpdateClientInternalMutation | null | undefined
  ) => {
    return mutation?.update_client?.returning || [];
  };

  const wrappedLazyMutation: UpdateClientInternalObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: UpdateClientInternalObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

// ClientInternal Fragment Helper Object
//------------------------------------------------

export const ClientInternalFragmentGQLHooks = {
  useQueryById: useQueryClientInternalById,
  useQueryByIdLazy: useQueryClientInternalByIdLazy,
  useQueryObjects: useQueryClientInternalObjects,
  useQueryObjectsLazy: useQueryClientInternalObjectsLazy,
  useSubscribeToById: useSubscribeToClientInternalById,
  useSubscribeToObjects: useSubscribeToClientInternalObjects,
  useInsert: useInsertClientInternal,
  useInsertWithOnConflict: useInsertClientInternalWithOnConflict,
  useInsertObjects: useInsertClientInternalObjects,
  useUpdateById: useUpdateClientInternalById,
  useUpdateObjects: useUpdateClientInternalObjects,
};

// client REACT
//------------------------------------------------

export type ClientSelectByIdHookResultEx = { clientSelect: ClientSelectFragment | null | undefined };
export type ClientSelectObjectsHookResultEx = { objects: ClientSelectFragment[] };

/**
 *  Query Hook
 */

// Types
type QueryClientSelectByIdResult = LazyQueryResult<QueryClientSelectByIdQuery, QueryClientSelectByIdQueryVariables>;
type QueryClientSelectByIdSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<
          QueryClientSelectByIdQuery,
          QueryClientSelectByIdQueryVariables,
          QueryClientSelectByIdQuery
        >,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryClientSelectByIdResultEx = Omit<QueryClientSelectByIdResult, 'subscribeToMore'> & {
  subscribeToMore: QueryClientSelectByIdSubScribeToMore;
} & ClientSelectByIdHookResultEx;

// Function
function useQueryClientSelectById({
  clientId,
  options,
}: {
  clientId: string;
  options?: Omit<
    QueryHookOptions<QueryClientSelectByIdQuery, QueryClientSelectByIdQueryVariables>,
    'query' | 'variables'
  >;
}): QueryClientSelectByIdResultEx {
  const _query: QueryClientSelectByIdResult = useQuery<QueryClientSelectByIdQuery, QueryClientSelectByIdQueryVariables>(
    QueryClientSelectByIdDocument,
    { variables: { clientId }, ...options }
  );

  const typedSubscribeToMore: QueryClientSelectByIdSubScribeToMore = (options) => {
    _query.subscribeToMore({
      document: QueryClientSelectByIdDocument,
      variables: { clientId } as QueryClientSelectByIdQueryVariables,
      ...options,
    });
  };
  const { subscribeToMore, ...query } = _query;
  return { ...query, subscribeToMore: typedSubscribeToMore, clientSelect: query?.data?.client_by_pk };
}

/**
 *  Lazy Query Hook
 */

// Types
type PickQueryClientSelectByIdFn = (
  query: QueryClientSelectByIdQuery | null | undefined
) => ClientSelectFragment | null | undefined;
type QueryClientSelectByIdLazyFn = [
  (options?: QueryLazyOptions<QueryClientSelectByIdQueryVariables> | undefined) => void,
  QueryClientSelectByIdResult
];
type QueryClientSelectByIdWrappedLazyFn = (
  options: Omit<QueryLazyOptions<QueryClientSelectByIdQueryVariables>, 'variables'>
) => void;
export type QueryClientSelectByIdLazyReturn = [QueryClientSelectByIdWrappedLazyFn, QueryClientSelectByIdResultEx];

// Function
function useQueryClientSelectByIdLazy({
  clientId,
  options,
}: {
  clientId: string;
  options?: Omit<
    QueryHookOptions<QueryClientSelectByIdQuery, QueryClientSelectByIdQueryVariables>,
    'query' | 'variables'
  >;
}): QueryClientSelectByIdLazyReturn {
  const lazyQuery: QueryClientSelectByIdLazyFn = useLazyQuery<
    QueryClientSelectByIdQuery,
    QueryClientSelectByIdQueryVariables
  >(QueryClientSelectByIdDocument, options);

  // Setting up typed version of lazyQuery
  const pickQueryClientSelectById: PickQueryClientSelectByIdFn = (query) => {
    return query && query.client_by_pk;
  };
  const wrappedLazyQuery: QueryClientSelectByIdWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryClientSelectByIdSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({
        document: QueryClientSelectByIdDocument,
        variables: { clientId } as QueryClientSelectByIdQueryVariables,
        ...options,
      });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    {
      ...lazyQueryResult,
      subscribeToMore: typedSubcribeToMore,
      clientSelect: pickQueryClientSelectById(lazyQuery[1].data),
    },
  ];
}

/**
 *  Query Collection Hook
 */

// Types
export type QueryClientSelectObjectsResult = LazyQueryResult<
  QueryClientSelectObjectsQuery,
  QueryClientSelectObjectsQueryVariables
>;
type QueryClientSelectObjectsSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<
          QueryClientSelectObjectsQuery,
          QueryClientSelectObjectsQueryVariables,
          QueryClientSelectObjectsQuery
        >,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryClientSelectObjectsResultEx = Omit<QueryClientSelectObjectsResult, 'subscribeToMore'> & {
  subscribeToMore: QueryClientSelectObjectsSubScribeToMore;
} & ClientSelectObjectsHookResultEx;

// Function
function useQueryClientSelectObjects(
  options: Omit<QueryHookOptions<QueryClientSelectObjectsQuery, QueryClientSelectObjectsQueryVariables>, 'query'>
): QueryClientSelectObjectsResultEx {
  const _query: QueryClientSelectObjectsResult = useQuery<
    QueryClientSelectObjectsQuery,
    QueryClientSelectObjectsQueryVariables
  >(QueryClientSelectObjectsDocument, options);

  const typedSubscribeToMore: QueryClientSelectObjectsSubScribeToMore = (options) => {
    _query.subscribeToMore({ document: QueryClientSelectObjectsDocument, ...options });
  };
  const { subscribeToMore, ...query } = _query;

  return { ...query, subscribeToMore: typedSubscribeToMore, objects: query?.data?.client || [] };
}

/**
 *  Lazy Query Collection Hook
 */

// Types
type PickQueryClientSelectObjectsFn = (
  query: QueryClientSelectObjectsQuery | null | undefined
) => ClientSelectFragment[];
type QueryClientSelectObjectsLazyFn = [
  (options?: QueryLazyOptions<QueryClientSelectObjectsQueryVariables> | undefined) => void,
  QueryClientSelectObjectsResult
];
type QueryClientSelectObjectsWrappedLazyFn = (
  options?: QueryLazyOptions<QueryClientSelectObjectsQueryVariables>
) => void;
export type QueryClientSelectObjectsLazyReturn = [
  QueryClientSelectObjectsWrappedLazyFn,
  QueryClientSelectObjectsResultEx
];

// Function
function useQueryClientSelectObjectsLazy(
  options?: Omit<LazyQueryHookOptions<QueryClientSelectObjectsQuery, QueryClientSelectObjectsQueryVariables>, 'query'>
): QueryClientSelectObjectsLazyReturn {
  const lazyQuery: QueryClientSelectObjectsLazyFn = useLazyQuery<
    QueryClientSelectObjectsQuery,
    QueryClientSelectObjectsQueryVariables
  >(QueryClientSelectObjectsDocument, options);

  // Setting up typed version of lazyQuery
  const pickObjects: PickQueryClientSelectObjectsFn = (query: QueryClientSelectObjectsQuery | null | undefined) => {
    return query?.client || [];
  };
  const wrappedLazyQuery: QueryClientSelectObjectsWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryClientSelectObjectsSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({ document: QueryClientSelectObjectsDocument, ...options });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    { ...lazyQueryResult, subscribeToMore: typedSubcribeToMore, objects: pickObjects(lazyQuery[1].data) },
  ];
}

/**
 *  Subscription Hook
 */

// Types
type SubscribeToClientSelectByIdResult = {
  variables?: SubscribeToClientSelectByIdSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToClientSelectByIdSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToClientSelectByIdResultEx = SubscribeToClientSelectByIdResult & ClientSelectByIdHookResultEx;

// Function
function useSubscribeToClientSelectById({
  clientId,
  options,
}: {
  clientId: string;
  options?: Omit<
    SubscriptionHookOptions<SubscribeToClientSelectByIdSubscription, SubscribeToClientSelectByIdSubscriptionVariables>,
    'query' | 'variables'
  >;
}): SubscribeToClientSelectByIdResultEx {
  const subscription: SubscribeToClientSelectByIdResult = useSubscription<
    SubscribeToClientSelectByIdSubscription,
    SubscribeToClientSelectByIdSubscriptionVariables
  >(SubscribeToClientSelectByIdDocument, { variables: { clientId }, ...options });
  return { ...subscription, clientSelect: subscription?.data?.client_by_pk };
}

/**
 *  Subscription Collection Hook
 */

// Types
export type SubscribeToClientSelectObjectsResult = {
  variables?: SubscribeToClientSelectObjectsSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToClientSelectObjectsSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToClientSelectObjectsResultEx = SubscribeToClientSelectObjectsResult &
  ClientSelectObjectsHookResultEx;

// Function
function useSubscribeToClientSelectObjects(
  options: Omit<
    SubscriptionHookOptions<
      SubscribeToClientSelectObjectsSubscription,
      SubscribeToClientSelectObjectsSubscriptionVariables
    >,
    'query'
  >
): SubscribeToClientSelectObjectsResultEx {
  const subscription: SubscribeToClientSelectObjectsResult = useSubscription<
    SubscribeToClientSelectObjectsSubscription,
    SubscribeToClientSelectObjectsSubscriptionVariables
  >(SubscribeToClientSelectObjectsDocument, options);
  return { ...subscription, objects: subscription?.data?.client || [] };
}

/**
 *  Insert Hooks
 */

// Types
type InsertClientSelectMutationResult = FetchResult<
  InsertClientSelectMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertClientSelectMutationResultEx = InsertClientSelectMutationResult & ClientSelectByIdHookResultEx;

type PickInsertClientSelectFn = (
  mutation: InsertClientSelectMutation | null | undefined
) => ClientSelectFragment | null | undefined;
type InsertClientSelectLazyMutationFn = MutationTuple<InsertClientSelectMutation, InsertClientSelectMutationVariables>;
type InsertClientSelectWrappedLazyMutationFn = ({
  client,
  autoOptimisticResponse,
  options,
}: {
  client: Client_Insert_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<MutationFunctionOptions<InsertClientSelectMutation, InsertClientSelectMutationVariables>, 'variables'>;
}) => Promise<InsertClientSelectMutationResultEx>;
export type InsertClientSelectLazyMutationReturn = [
  InsertClientSelectWrappedLazyMutationFn,
  InsertClientSelectMutationResultEx
];

// Function
function useInsertClientSelect(
  options?: Omit<
    MutationHookOptions<InsertClientSelectMutation, InsertClientSelectMutationVariables>,
    'mutation' | 'variables'
  >
): InsertClientSelectLazyMutationReturn {
  const lazyMutation: InsertClientSelectLazyMutationFn = useMutation<
    InsertClientSelectMutation,
    InsertClientSelectMutationVariables
  >(InsertClientSelectDocument, options);
  const pickClientSelect: PickInsertClientSelectFn = (mutation) => {
    return mutation?.insert_client?.returning && mutation?.insert_client?.returning[0];
  };

  const wrappedLazyMutation: InsertClientSelectWrappedLazyMutationFn = async ({
    client,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: client });
    const mutationOptions: MutationFunctionOptions<InsertClientSelectMutation, InsertClientSelectMutationVariables> = {
      variables: { objects: [objectForInsert] },
      ...options,
    };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id) throw new Error(`if autoOptimisticResponse = true, id must be set in object 'client'`);
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertClientSelectMutation>({
        operationType: 'insert',
        entityName: 'client',
        objects: [objectForInsert as Client_Insert_Input & ObjectWithId],
      });
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, clientSelect: pickClientSelect(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], clientSelect: pickClientSelect(lazyMutation[1].data) }];
}

//
//

// Types
type InsertClientSelectWithOnConflictMutationResult = FetchResult<
  InsertClientSelectWithOnConflictMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertClientSelectWithOnConflictMutationResultEx = InsertClientSelectWithOnConflictMutationResult &
  ClientSelectByIdHookResultEx;

type InsertClientSelectWithOnConflictLazyMutationFn = MutationTuple<
  InsertClientSelectWithOnConflictMutation,
  InsertClientSelectWithOnConflictMutationVariables
>;
type InsertClientSelectWithOnConflictWrappedLazyMutationFn = ({
  client,
  onConflict,
  autoOptimisticResponse,
  options,
}: {
  client: Client_Insert_Input;
  onConflict: Client_On_Conflict;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<
      InsertClientSelectWithOnConflictMutation,
      InsertClientSelectWithOnConflictMutationVariables
    >,
    'variables'
  >;
}) => Promise<InsertClientSelectWithOnConflictMutationResultEx>;
export type InsertClientSelectWithOnConflictLazyMutationReturn = [
  InsertClientSelectWithOnConflictWrappedLazyMutationFn,
  InsertClientSelectWithOnConflictMutationResultEx
];

// Function
function useInsertClientSelectWithOnConflict(
  options?: Omit<
    MutationHookOptions<InsertClientSelectWithOnConflictMutation, InsertClientSelectWithOnConflictMutationVariables>,
    'mutation' | 'variables'
  >
): InsertClientSelectWithOnConflictLazyMutationReturn {
  const lazyMutation: InsertClientSelectWithOnConflictLazyMutationFn = useMutation<
    InsertClientSelectWithOnConflictMutation,
    InsertClientSelectWithOnConflictMutationVariables
  >(InsertClientSelectWithOnConflictDocument, options);
  const pickClientSelect: PickInsertClientSelectFn = (
    mutation: InsertClientSelectWithOnConflictMutation | null | undefined
  ) => {
    return mutation?.insert_client?.returning && mutation.insert_client.returning[0];
  };

  const wrappedLazyMutation: InsertClientSelectWithOnConflictWrappedLazyMutationFn = async ({
    client,
    onConflict,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: client });
    const mutationOptions: MutationFunctionOptions<
      InsertClientSelectWithOnConflictMutation,
      InsertClientSelectWithOnConflictMutationVariables
    > = { variables: { objects: [objectForInsert], onConflict }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id) throw new Error(`if autoOptimisticResponse = true, id must be set in object 'client'`);
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertClientSelectWithOnConflictMutation>(
        {
          operationType: 'insert',
          entityName: 'client',
          objects: [objectForInsert as Client_Insert_Input & ObjectWithId],
        }
      );
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, clientSelect: pickClientSelect(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], clientSelect: pickClientSelect(lazyMutation[1].data) }];
}

// Types
type InsertClientSelectObjectsMutationResult = FetchResult<
  InsertClientSelectMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertClientSelectObjectsMutationResultEx = InsertClientSelectMutationResult &
  ClientSelectObjectsHookResultEx;

type PickInsertClientSelectObjectsFn = (
  mutation: InsertClientSelectMutation | null | undefined
) => ClientSelectFragment[];
type InsertClientSelectObjectsLazyMutationFn = MutationTuple<
  InsertClientSelectMutation,
  InsertClientSelectMutationVariables
>;
type InsertClientSelectObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<InsertClientSelectMutation, InsertClientSelectMutationVariables>
) => Promise<InsertClientSelectObjectsMutationResultEx>;
export type InsertClientSelectObjectsLazyMutationReturn = [
  InsertClientSelectObjectsWrappedLazyMutationFn,
  InsertClientSelectObjectsMutationResultEx
];

// Function
function useInsertClientSelectObjects(
  options?: Omit<MutationHookOptions<InsertClientSelectMutation, InsertClientSelectMutationVariables>, 'mutation'>
): InsertClientSelectObjectsLazyMutationReturn {
  const lazyMutation: InsertClientSelectObjectsLazyMutationFn = useMutation<
    InsertClientSelectMutation,
    InsertClientSelectMutationVariables
  >(InsertClientSelectDocument, options);
  const pickObjects: PickInsertClientSelectObjectsFn = (mutation: InsertClientSelectMutation | null | undefined) => {
    return mutation?.insert_client?.returning || [];
  };

  const wrappedLazyMutation: InsertClientSelectObjectsWrappedLazyMutationFn = async (options) => {
    if (options && options.variables && options.variables.objects)
      options.variables.objects = options.variables.objects.map((objectItem) =>
        stripInsertInputClientFields({ input: objectItem })
      );
    const fetchResult: InsertClientSelectObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Update Hooks
 */

type UpdateClientSelectByIdMutationResult = FetchResult<
  UpdateClientSelectByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateClientSelectByIdMutationResultEx = UpdateClientSelectByIdMutationResult &
  ClientSelectByIdHookResultEx;

type PickUpdateClientSelectByIdFn = (
  mutation: UpdateClientSelectByIdMutation | null | undefined
) => ClientSelectFragment | null | undefined;
type UpdateClientSelectByIdLazyMutationFn = MutationTuple<
  UpdateClientSelectByIdMutation,
  UpdateClientSelectByIdMutationVariables
>;
type UpdateClientSelectByIdWrappedLazyMutationFn = ({
  clientId,
  set,
  autoOptimisticResponse,
  options,
}: {
  clientId: string;
  set: Client_Set_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<UpdateClientSelectByIdMutation, UpdateClientSelectByIdMutationVariables>,
    'variables'
  >;
}) => Promise<UpdateClientSelectByIdMutationResultEx>;
export type UpdateClientSelectByIdLazyMutationReturn = [
  UpdateClientSelectByIdWrappedLazyMutationFn,
  UpdateClientSelectByIdMutationResultEx
];

function useUpdateClientSelectById(
  options?: Omit<
    MutationHookOptions<UpdateClientSelectByIdMutation, UpdateClientSelectByIdMutationVariables>,
    'mutation' | 'variables'
  >
): UpdateClientSelectByIdLazyMutationReturn {
  const lazyMutation: UpdateClientSelectByIdLazyMutationFn = useMutation<
    UpdateClientSelectByIdMutation,
    UpdateClientSelectByIdMutationVariables
  >(UpdateClientSelectByIdDocument, options);

  const pickClientSelect: PickUpdateClientSelectByIdFn = (mutation) => {
    return mutation?.update_client?.returning && mutation.update_client!.returning[0];
  };

  const wrappedLazyMutation: UpdateClientSelectByIdWrappedLazyMutationFn = async ({
    clientId,
    set,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<
      UpdateClientSelectByIdMutation,
      UpdateClientSelectByIdMutationVariables
    > = { variables: { id: clientId, set }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateClientSelectByIdMutation>({
        operationType: 'update',
        entityName: 'client',
        objects: [{ id: clientId, ...set }],
      });
    }

    const fetchResult: UpdateClientSelectByIdMutationResult = await lazyMutation[0]({
      variables: { id: clientId, set },
      ...mutationOptions,
    });
    return { ...fetchResult, clientSelect: pickClientSelect(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], clientSelect: pickClientSelect(lazyMutation[1].data) }];
}

//
//

// Types
type UpdateClientSelectObjectsMutationResult = FetchResult<
  UpdateClientSelectMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateClientSelectObjectsMutationResultEx = UpdateClientSelectObjectsMutationResult &
  ClientSelectObjectsHookResultEx;

// Function
type PickUpdateClientSelectObjectsFn = (
  mutation: UpdateClientSelectMutation | null | undefined
) => ClientSelectFragment[];
type UpdateClientSelectObjectsLazyMutationFn = MutationTuple<
  UpdateClientSelectMutation,
  UpdateClientSelectMutationVariables
>;
type UpdateClientSelectObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<UpdateClientSelectMutation, UpdateClientSelectMutationVariables>
) => Promise<UpdateClientSelectObjectsMutationResultEx>;
export type UpdateClientSelectObjectsLazyMutationReturn = [
  UpdateClientSelectObjectsWrappedLazyMutationFn,
  UpdateClientSelectObjectsMutationResultEx
];

function useUpdateClientSelectObjects(
  options?: Omit<MutationHookOptions<UpdateClientSelectMutation, UpdateClientSelectMutationVariables>, 'mutation'>
): UpdateClientSelectObjectsLazyMutationReturn {
  const lazyMutation: UpdateClientSelectObjectsLazyMutationFn = useMutation<
    UpdateClientSelectMutation,
    UpdateClientSelectMutationVariables
  >(UpdateClientSelectDocument, options);

  const pickObjects: PickUpdateClientSelectObjectsFn = (mutation: UpdateClientSelectMutation | null | undefined) => {
    return mutation?.update_client?.returning || [];
  };

  const wrappedLazyMutation: UpdateClientSelectObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: UpdateClientSelectObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

// ClientSelect Fragment Helper Object
//------------------------------------------------

export const ClientSelectFragmentGQLHooks = {
  useQueryById: useQueryClientSelectById,
  useQueryByIdLazy: useQueryClientSelectByIdLazy,
  useQueryObjects: useQueryClientSelectObjects,
  useQueryObjectsLazy: useQueryClientSelectObjectsLazy,
  useSubscribeToById: useSubscribeToClientSelectById,
  useSubscribeToObjects: useSubscribeToClientSelectObjects,
  useInsert: useInsertClientSelect,
  useInsertWithOnConflict: useInsertClientSelectWithOnConflict,
  useInsertObjects: useInsertClientSelectObjects,
  useUpdateById: useUpdateClientSelectById,
  useUpdateObjects: useUpdateClientSelectObjects,
};

// clientNotificationEmail REACT
//------------------------------------------------

export type ClientNotificationEmailByIdHookResultEx = {
  clientNotificationEmail: ClientNotificationEmailFragment | null | undefined;
};
export type ClientNotificationEmailObjectsHookResultEx = { objects: ClientNotificationEmailFragment[] };

/**
 *  Query Hook
 */

// Types
type QueryClientNotificationEmailByIdResult = LazyQueryResult<
  QueryClientNotificationEmailByIdQuery,
  QueryClientNotificationEmailByIdQueryVariables
>;
type QueryClientNotificationEmailByIdSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<
          QueryClientNotificationEmailByIdQuery,
          QueryClientNotificationEmailByIdQueryVariables,
          QueryClientNotificationEmailByIdQuery
        >,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryClientNotificationEmailByIdResultEx = Omit<
  QueryClientNotificationEmailByIdResult,
  'subscribeToMore'
> & { subscribeToMore: QueryClientNotificationEmailByIdSubScribeToMore } & ClientNotificationEmailByIdHookResultEx;

// Function
function useQueryClientNotificationEmailById({
  clientNotificationEmailId,
  options,
}: {
  clientNotificationEmailId: string;
  options?: Omit<
    QueryHookOptions<QueryClientNotificationEmailByIdQuery, QueryClientNotificationEmailByIdQueryVariables>,
    'query' | 'variables'
  >;
}): QueryClientNotificationEmailByIdResultEx {
  const _query: QueryClientNotificationEmailByIdResult = useQuery<
    QueryClientNotificationEmailByIdQuery,
    QueryClientNotificationEmailByIdQueryVariables
  >(QueryClientNotificationEmailByIdDocument, { variables: { clientNotificationEmailId }, ...options });

  const typedSubscribeToMore: QueryClientNotificationEmailByIdSubScribeToMore = (options) => {
    _query.subscribeToMore({
      document: QueryClientNotificationEmailByIdDocument,
      variables: { clientNotificationEmailId } as QueryClientNotificationEmailByIdQueryVariables,
      ...options,
    });
  };
  const { subscribeToMore, ...query } = _query;
  return {
    ...query,
    subscribeToMore: typedSubscribeToMore,
    clientNotificationEmail: query?.data?.clientNotificationEmail_by_pk,
  };
}

/**
 *  Lazy Query Hook
 */

// Types
type PickQueryClientNotificationEmailByIdFn = (
  query: QueryClientNotificationEmailByIdQuery | null | undefined
) => ClientNotificationEmailFragment | null | undefined;
type QueryClientNotificationEmailByIdLazyFn = [
  (options?: QueryLazyOptions<QueryClientNotificationEmailByIdQueryVariables> | undefined) => void,
  QueryClientNotificationEmailByIdResult
];
type QueryClientNotificationEmailByIdWrappedLazyFn = (
  options: Omit<QueryLazyOptions<QueryClientNotificationEmailByIdQueryVariables>, 'variables'>
) => void;
export type QueryClientNotificationEmailByIdLazyReturn = [
  QueryClientNotificationEmailByIdWrappedLazyFn,
  QueryClientNotificationEmailByIdResultEx
];

// Function
function useQueryClientNotificationEmailByIdLazy({
  clientNotificationEmailId,
  options,
}: {
  clientNotificationEmailId: string;
  options?: Omit<
    QueryHookOptions<QueryClientNotificationEmailByIdQuery, QueryClientNotificationEmailByIdQueryVariables>,
    'query' | 'variables'
  >;
}): QueryClientNotificationEmailByIdLazyReturn {
  const lazyQuery: QueryClientNotificationEmailByIdLazyFn = useLazyQuery<
    QueryClientNotificationEmailByIdQuery,
    QueryClientNotificationEmailByIdQueryVariables
  >(QueryClientNotificationEmailByIdDocument, options);

  // Setting up typed version of lazyQuery
  const pickQueryClientNotificationEmailById: PickQueryClientNotificationEmailByIdFn = (query) => {
    return query && query.clientNotificationEmail_by_pk;
  };
  const wrappedLazyQuery: QueryClientNotificationEmailByIdWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryClientNotificationEmailByIdSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({
        document: QueryClientNotificationEmailByIdDocument,
        variables: { clientNotificationEmailId } as QueryClientNotificationEmailByIdQueryVariables,
        ...options,
      });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    {
      ...lazyQueryResult,
      subscribeToMore: typedSubcribeToMore,
      clientNotificationEmail: pickQueryClientNotificationEmailById(lazyQuery[1].data),
    },
  ];
}

/**
 *  Query Collection Hook
 */

// Types
export type QueryClientNotificationEmailObjectsResult = LazyQueryResult<
  QueryClientNotificationEmailObjectsQuery,
  QueryClientNotificationEmailObjectsQueryVariables
>;
type QueryClientNotificationEmailObjectsSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<
          QueryClientNotificationEmailObjectsQuery,
          QueryClientNotificationEmailObjectsQueryVariables,
          QueryClientNotificationEmailObjectsQuery
        >,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryClientNotificationEmailObjectsResultEx = Omit<
  QueryClientNotificationEmailObjectsResult,
  'subscribeToMore'
> & {
  subscribeToMore: QueryClientNotificationEmailObjectsSubScribeToMore;
} & ClientNotificationEmailObjectsHookResultEx;

// Function
function useQueryClientNotificationEmailObjects(
  options: Omit<
    QueryHookOptions<QueryClientNotificationEmailObjectsQuery, QueryClientNotificationEmailObjectsQueryVariables>,
    'query'
  >
): QueryClientNotificationEmailObjectsResultEx {
  const _query: QueryClientNotificationEmailObjectsResult = useQuery<
    QueryClientNotificationEmailObjectsQuery,
    QueryClientNotificationEmailObjectsQueryVariables
  >(QueryClientNotificationEmailObjectsDocument, options);

  const typedSubscribeToMore: QueryClientNotificationEmailObjectsSubScribeToMore = (options) => {
    _query.subscribeToMore({ document: QueryClientNotificationEmailObjectsDocument, ...options });
  };
  const { subscribeToMore, ...query } = _query;

  return { ...query, subscribeToMore: typedSubscribeToMore, objects: query?.data?.clientNotificationEmail || [] };
}

/**
 *  Lazy Query Collection Hook
 */

// Types
type PickQueryClientNotificationEmailObjectsFn = (
  query: QueryClientNotificationEmailObjectsQuery | null | undefined
) => ClientNotificationEmailFragment[];
type QueryClientNotificationEmailObjectsLazyFn = [
  (options?: QueryLazyOptions<QueryClientNotificationEmailObjectsQueryVariables> | undefined) => void,
  QueryClientNotificationEmailObjectsResult
];
type QueryClientNotificationEmailObjectsWrappedLazyFn = (
  options?: QueryLazyOptions<QueryClientNotificationEmailObjectsQueryVariables>
) => void;
export type QueryClientNotificationEmailObjectsLazyReturn = [
  QueryClientNotificationEmailObjectsWrappedLazyFn,
  QueryClientNotificationEmailObjectsResultEx
];

// Function
function useQueryClientNotificationEmailObjectsLazy(
  options?: Omit<
    LazyQueryHookOptions<QueryClientNotificationEmailObjectsQuery, QueryClientNotificationEmailObjectsQueryVariables>,
    'query'
  >
): QueryClientNotificationEmailObjectsLazyReturn {
  const lazyQuery: QueryClientNotificationEmailObjectsLazyFn = useLazyQuery<
    QueryClientNotificationEmailObjectsQuery,
    QueryClientNotificationEmailObjectsQueryVariables
  >(QueryClientNotificationEmailObjectsDocument, options);

  // Setting up typed version of lazyQuery
  const pickObjects: PickQueryClientNotificationEmailObjectsFn = (
    query: QueryClientNotificationEmailObjectsQuery | null | undefined
  ) => {
    return query?.clientNotificationEmail || [];
  };
  const wrappedLazyQuery: QueryClientNotificationEmailObjectsWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryClientNotificationEmailObjectsSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({ document: QueryClientNotificationEmailObjectsDocument, ...options });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    { ...lazyQueryResult, subscribeToMore: typedSubcribeToMore, objects: pickObjects(lazyQuery[1].data) },
  ];
}

/**
 *  Subscription Hook
 */

// Types
type SubscribeToClientNotificationEmailByIdResult = {
  variables?: SubscribeToClientNotificationEmailByIdSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToClientNotificationEmailByIdSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToClientNotificationEmailByIdResultEx = SubscribeToClientNotificationEmailByIdResult &
  ClientNotificationEmailByIdHookResultEx;

// Function
function useSubscribeToClientNotificationEmailById({
  clientNotificationEmailId,
  options,
}: {
  clientNotificationEmailId: string;
  options?: Omit<
    SubscriptionHookOptions<
      SubscribeToClientNotificationEmailByIdSubscription,
      SubscribeToClientNotificationEmailByIdSubscriptionVariables
    >,
    'query' | 'variables'
  >;
}): SubscribeToClientNotificationEmailByIdResultEx {
  const subscription: SubscribeToClientNotificationEmailByIdResult = useSubscription<
    SubscribeToClientNotificationEmailByIdSubscription,
    SubscribeToClientNotificationEmailByIdSubscriptionVariables
  >(SubscribeToClientNotificationEmailByIdDocument, { variables: { clientNotificationEmailId }, ...options });
  return { ...subscription, clientNotificationEmail: subscription?.data?.clientNotificationEmail_by_pk };
}

/**
 *  Subscription Collection Hook
 */

// Types
export type SubscribeToClientNotificationEmailObjectsResult = {
  variables?: SubscribeToClientNotificationEmailObjectsSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToClientNotificationEmailObjectsSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToClientNotificationEmailObjectsResultEx = SubscribeToClientNotificationEmailObjectsResult &
  ClientNotificationEmailObjectsHookResultEx;

// Function
function useSubscribeToClientNotificationEmailObjects(
  options: Omit<
    SubscriptionHookOptions<
      SubscribeToClientNotificationEmailObjectsSubscription,
      SubscribeToClientNotificationEmailObjectsSubscriptionVariables
    >,
    'query'
  >
): SubscribeToClientNotificationEmailObjectsResultEx {
  const subscription: SubscribeToClientNotificationEmailObjectsResult = useSubscription<
    SubscribeToClientNotificationEmailObjectsSubscription,
    SubscribeToClientNotificationEmailObjectsSubscriptionVariables
  >(SubscribeToClientNotificationEmailObjectsDocument, options);
  return { ...subscription, objects: subscription?.data?.clientNotificationEmail || [] };
}

/**
 *  Insert Hooks
 */

// Types
type InsertClientNotificationEmailMutationResult = FetchResult<
  InsertClientNotificationEmailMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertClientNotificationEmailMutationResultEx = InsertClientNotificationEmailMutationResult &
  ClientNotificationEmailByIdHookResultEx;

type PickInsertClientNotificationEmailFn = (
  mutation: InsertClientNotificationEmailMutation | null | undefined
) => ClientNotificationEmailFragment | null | undefined;
type InsertClientNotificationEmailLazyMutationFn = MutationTuple<
  InsertClientNotificationEmailMutation,
  InsertClientNotificationEmailMutationVariables
>;
type InsertClientNotificationEmailWrappedLazyMutationFn = ({
  clientNotificationEmail,
  autoOptimisticResponse,
  options,
}: {
  clientNotificationEmail: ClientNotificationEmail_Insert_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<InsertClientNotificationEmailMutation, InsertClientNotificationEmailMutationVariables>,
    'variables'
  >;
}) => Promise<InsertClientNotificationEmailMutationResultEx>;
export type InsertClientNotificationEmailLazyMutationReturn = [
  InsertClientNotificationEmailWrappedLazyMutationFn,
  InsertClientNotificationEmailMutationResultEx
];

// Function
function useInsertClientNotificationEmail(
  options?: Omit<
    MutationHookOptions<InsertClientNotificationEmailMutation, InsertClientNotificationEmailMutationVariables>,
    'mutation' | 'variables'
  >
): InsertClientNotificationEmailLazyMutationReturn {
  const lazyMutation: InsertClientNotificationEmailLazyMutationFn = useMutation<
    InsertClientNotificationEmailMutation,
    InsertClientNotificationEmailMutationVariables
  >(InsertClientNotificationEmailDocument, options);
  const pickClientNotificationEmail: PickInsertClientNotificationEmailFn = (mutation) => {
    return (
      mutation?.insert_clientNotificationEmail?.returning && mutation?.insert_clientNotificationEmail?.returning[0]
    );
  };

  const wrappedLazyMutation: InsertClientNotificationEmailWrappedLazyMutationFn = async ({
    clientNotificationEmail,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: clientNotificationEmail });
    const mutationOptions: MutationFunctionOptions<
      InsertClientNotificationEmailMutation,
      InsertClientNotificationEmailMutationVariables
    > = { variables: { objects: [objectForInsert] }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id)
        throw new Error(`if autoOptimisticResponse = true, id must be set in object 'clientNotificationEmail'`);
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertClientNotificationEmailMutation>(
        {
          operationType: 'insert',
          entityName: 'clientNotificationEmail',
          objects: [objectForInsert as ClientNotificationEmail_Insert_Input & ObjectWithId],
        }
      );
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, clientNotificationEmail: pickClientNotificationEmail(fetchResult.data) };
  };

  return [
    wrappedLazyMutation,
    { ...lazyMutation[1], clientNotificationEmail: pickClientNotificationEmail(lazyMutation[1].data) },
  ];
}

//
//

// Types
type InsertClientNotificationEmailWithOnConflictMutationResult = FetchResult<
  InsertClientNotificationEmailWithOnConflictMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertClientNotificationEmailWithOnConflictMutationResultEx = InsertClientNotificationEmailWithOnConflictMutationResult &
  ClientNotificationEmailByIdHookResultEx;

type InsertClientNotificationEmailWithOnConflictLazyMutationFn = MutationTuple<
  InsertClientNotificationEmailWithOnConflictMutation,
  InsertClientNotificationEmailWithOnConflictMutationVariables
>;
type InsertClientNotificationEmailWithOnConflictWrappedLazyMutationFn = ({
  clientNotificationEmail,
  onConflict,
  autoOptimisticResponse,
  options,
}: {
  clientNotificationEmail: ClientNotificationEmail_Insert_Input;
  onConflict: ClientNotificationEmail_On_Conflict;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<
      InsertClientNotificationEmailWithOnConflictMutation,
      InsertClientNotificationEmailWithOnConflictMutationVariables
    >,
    'variables'
  >;
}) => Promise<InsertClientNotificationEmailWithOnConflictMutationResultEx>;
export type InsertClientNotificationEmailWithOnConflictLazyMutationReturn = [
  InsertClientNotificationEmailWithOnConflictWrappedLazyMutationFn,
  InsertClientNotificationEmailWithOnConflictMutationResultEx
];

// Function
function useInsertClientNotificationEmailWithOnConflict(
  options?: Omit<
    MutationHookOptions<
      InsertClientNotificationEmailWithOnConflictMutation,
      InsertClientNotificationEmailWithOnConflictMutationVariables
    >,
    'mutation' | 'variables'
  >
): InsertClientNotificationEmailWithOnConflictLazyMutationReturn {
  const lazyMutation: InsertClientNotificationEmailWithOnConflictLazyMutationFn = useMutation<
    InsertClientNotificationEmailWithOnConflictMutation,
    InsertClientNotificationEmailWithOnConflictMutationVariables
  >(InsertClientNotificationEmailWithOnConflictDocument, options);
  const pickClientNotificationEmail: PickInsertClientNotificationEmailFn = (
    mutation: InsertClientNotificationEmailWithOnConflictMutation | null | undefined
  ) => {
    return mutation?.insert_clientNotificationEmail?.returning && mutation.insert_clientNotificationEmail.returning[0];
  };

  const wrappedLazyMutation: InsertClientNotificationEmailWithOnConflictWrappedLazyMutationFn = async ({
    clientNotificationEmail,
    onConflict,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: clientNotificationEmail });
    const mutationOptions: MutationFunctionOptions<
      InsertClientNotificationEmailWithOnConflictMutation,
      InsertClientNotificationEmailWithOnConflictMutationVariables
    > = { variables: { objects: [objectForInsert], onConflict }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id)
        throw new Error(`if autoOptimisticResponse = true, id must be set in object 'clientNotificationEmail'`);
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertClientNotificationEmailWithOnConflictMutation>(
        {
          operationType: 'insert',
          entityName: 'clientNotificationEmail',
          objects: [objectForInsert as ClientNotificationEmail_Insert_Input & ObjectWithId],
        }
      );
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, clientNotificationEmail: pickClientNotificationEmail(fetchResult.data) };
  };

  return [
    wrappedLazyMutation,
    { ...lazyMutation[1], clientNotificationEmail: pickClientNotificationEmail(lazyMutation[1].data) },
  ];
}

// Types
type InsertClientNotificationEmailObjectsMutationResult = FetchResult<
  InsertClientNotificationEmailMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertClientNotificationEmailObjectsMutationResultEx = InsertClientNotificationEmailMutationResult &
  ClientNotificationEmailObjectsHookResultEx;

type PickInsertClientNotificationEmailObjectsFn = (
  mutation: InsertClientNotificationEmailMutation | null | undefined
) => ClientNotificationEmailFragment[];
type InsertClientNotificationEmailObjectsLazyMutationFn = MutationTuple<
  InsertClientNotificationEmailMutation,
  InsertClientNotificationEmailMutationVariables
>;
type InsertClientNotificationEmailObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<
    InsertClientNotificationEmailMutation,
    InsertClientNotificationEmailMutationVariables
  >
) => Promise<InsertClientNotificationEmailObjectsMutationResultEx>;
export type InsertClientNotificationEmailObjectsLazyMutationReturn = [
  InsertClientNotificationEmailObjectsWrappedLazyMutationFn,
  InsertClientNotificationEmailObjectsMutationResultEx
];

// Function
function useInsertClientNotificationEmailObjects(
  options?: Omit<
    MutationHookOptions<InsertClientNotificationEmailMutation, InsertClientNotificationEmailMutationVariables>,
    'mutation'
  >
): InsertClientNotificationEmailObjectsLazyMutationReturn {
  const lazyMutation: InsertClientNotificationEmailObjectsLazyMutationFn = useMutation<
    InsertClientNotificationEmailMutation,
    InsertClientNotificationEmailMutationVariables
  >(InsertClientNotificationEmailDocument, options);
  const pickObjects: PickInsertClientNotificationEmailObjectsFn = (
    mutation: InsertClientNotificationEmailMutation | null | undefined
  ) => {
    return mutation?.insert_clientNotificationEmail?.returning || [];
  };

  const wrappedLazyMutation: InsertClientNotificationEmailObjectsWrappedLazyMutationFn = async (options) => {
    if (options && options.variables && options.variables.objects)
      options.variables.objects = options.variables.objects.map((objectItem) =>
        stripInsertInputClientFields({ input: objectItem })
      );
    const fetchResult: InsertClientNotificationEmailObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Update Hooks
 */

type UpdateClientNotificationEmailByIdMutationResult = FetchResult<
  UpdateClientNotificationEmailByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateClientNotificationEmailByIdMutationResultEx = UpdateClientNotificationEmailByIdMutationResult &
  ClientNotificationEmailByIdHookResultEx;

type PickUpdateClientNotificationEmailByIdFn = (
  mutation: UpdateClientNotificationEmailByIdMutation | null | undefined
) => ClientNotificationEmailFragment | null | undefined;
type UpdateClientNotificationEmailByIdLazyMutationFn = MutationTuple<
  UpdateClientNotificationEmailByIdMutation,
  UpdateClientNotificationEmailByIdMutationVariables
>;
type UpdateClientNotificationEmailByIdWrappedLazyMutationFn = ({
  clientNotificationEmailId,
  set,
  autoOptimisticResponse,
  options,
}: {
  clientNotificationEmailId: string;
  set: ClientNotificationEmail_Set_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<
      UpdateClientNotificationEmailByIdMutation,
      UpdateClientNotificationEmailByIdMutationVariables
    >,
    'variables'
  >;
}) => Promise<UpdateClientNotificationEmailByIdMutationResultEx>;
export type UpdateClientNotificationEmailByIdLazyMutationReturn = [
  UpdateClientNotificationEmailByIdWrappedLazyMutationFn,
  UpdateClientNotificationEmailByIdMutationResultEx
];

function useUpdateClientNotificationEmailById(
  options?: Omit<
    MutationHookOptions<UpdateClientNotificationEmailByIdMutation, UpdateClientNotificationEmailByIdMutationVariables>,
    'mutation' | 'variables'
  >
): UpdateClientNotificationEmailByIdLazyMutationReturn {
  const lazyMutation: UpdateClientNotificationEmailByIdLazyMutationFn = useMutation<
    UpdateClientNotificationEmailByIdMutation,
    UpdateClientNotificationEmailByIdMutationVariables
  >(UpdateClientNotificationEmailByIdDocument, options);

  const pickClientNotificationEmail: PickUpdateClientNotificationEmailByIdFn = (mutation) => {
    return mutation?.update_clientNotificationEmail?.returning && mutation.update_clientNotificationEmail!.returning[0];
  };

  const wrappedLazyMutation: UpdateClientNotificationEmailByIdWrappedLazyMutationFn = async ({
    clientNotificationEmailId,
    set,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<
      UpdateClientNotificationEmailByIdMutation,
      UpdateClientNotificationEmailByIdMutationVariables
    > = { variables: { id: clientNotificationEmailId, set }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateClientNotificationEmailByIdMutation>(
        {
          operationType: 'update',
          entityName: 'clientNotificationEmail',
          objects: [{ id: clientNotificationEmailId, ...set }],
        }
      );
    }

    const fetchResult: UpdateClientNotificationEmailByIdMutationResult = await lazyMutation[0]({
      variables: { id: clientNotificationEmailId, set },
      ...mutationOptions,
    });
    return { ...fetchResult, clientNotificationEmail: pickClientNotificationEmail(fetchResult.data) };
  };

  return [
    wrappedLazyMutation,
    { ...lazyMutation[1], clientNotificationEmail: pickClientNotificationEmail(lazyMutation[1].data) },
  ];
}

//
//

// Types
type UpdateClientNotificationEmailObjectsMutationResult = FetchResult<
  UpdateClientNotificationEmailMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateClientNotificationEmailObjectsMutationResultEx = UpdateClientNotificationEmailObjectsMutationResult &
  ClientNotificationEmailObjectsHookResultEx;

// Function
type PickUpdateClientNotificationEmailObjectsFn = (
  mutation: UpdateClientNotificationEmailMutation | null | undefined
) => ClientNotificationEmailFragment[];
type UpdateClientNotificationEmailObjectsLazyMutationFn = MutationTuple<
  UpdateClientNotificationEmailMutation,
  UpdateClientNotificationEmailMutationVariables
>;
type UpdateClientNotificationEmailObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<
    UpdateClientNotificationEmailMutation,
    UpdateClientNotificationEmailMutationVariables
  >
) => Promise<UpdateClientNotificationEmailObjectsMutationResultEx>;
export type UpdateClientNotificationEmailObjectsLazyMutationReturn = [
  UpdateClientNotificationEmailObjectsWrappedLazyMutationFn,
  UpdateClientNotificationEmailObjectsMutationResultEx
];

function useUpdateClientNotificationEmailObjects(
  options?: Omit<
    MutationHookOptions<UpdateClientNotificationEmailMutation, UpdateClientNotificationEmailMutationVariables>,
    'mutation'
  >
): UpdateClientNotificationEmailObjectsLazyMutationReturn {
  const lazyMutation: UpdateClientNotificationEmailObjectsLazyMutationFn = useMutation<
    UpdateClientNotificationEmailMutation,
    UpdateClientNotificationEmailMutationVariables
  >(UpdateClientNotificationEmailDocument, options);

  const pickObjects: PickUpdateClientNotificationEmailObjectsFn = (
    mutation: UpdateClientNotificationEmailMutation | null | undefined
  ) => {
    return mutation?.update_clientNotificationEmail?.returning || [];
  };

  const wrappedLazyMutation: UpdateClientNotificationEmailObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: UpdateClientNotificationEmailObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Delete Hooks
 */

// Types
type RemoveClientNotificationEmailModelByIdFetchResult = FetchResult<
  RemoveClientNotificationEmailModelByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveClientNotificationEmailModelByIdMutationResultEx = RemoveClientNotificationEmailModelByIdFetchResult &
  RemoveEntitiesQueryHookResultEx;

// Function
type PickRemoveClientNotificationEmailModelFn = (
  mutation: RemoveClientNotificationEmailModelByIdMutation | null | undefined
) => number;
type RemoveClientNotificationEmailModelLazyMutationFn = MutationTuple<
  RemoveClientNotificationEmailModelByIdMutation,
  RemoveClientNotificationEmailModelByIdMutationVariables
>;
type RemoveClientNotificationEmailModelWrappedLazyMutationFn = ({
  clientNotificationEmailId,
  autoOptimisticResponse,
  options,
}: {
  clientNotificationEmailId: string;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<
      RemoveClientNotificationEmailModelByIdMutation,
      RemoveClientNotificationEmailModelByIdMutationVariables
    >,
    'variables'
  >;
}) => Promise<RemoveClientNotificationEmailModelByIdMutationResultEx>;
export type RemoveClientNotificationEmailModelLazyMutationReturn = [
  RemoveClientNotificationEmailModelWrappedLazyMutationFn,
  RemoveClientNotificationEmailModelByIdMutationResultEx
];

function useRemoveClientNotificationEmailModelById(
  options?: Omit<
    MutationHookOptions<
      RemoveClientNotificationEmailModelByIdMutation,
      RemoveClientNotificationEmailModelByIdMutationVariables
    >,
    'mutation' | 'variables'
  >
) {
  const lazyMutation: RemoveClientNotificationEmailModelLazyMutationFn = useMutation<
    RemoveClientNotificationEmailModelByIdMutation,
    RemoveClientNotificationEmailModelByIdMutationVariables
  >(RemoveClientNotificationEmailModelByIdDocument, options);

  const pickAffectedRows: PickRemoveClientNotificationEmailModelFn = (
    mutation: RemoveClientNotificationEmailModelByIdMutation | null | undefined
  ) => {
    return mutation?.delete_clientNotificationEmail?.affected_rows || 0;
  };

  const wrappedLazyMutation: RemoveClientNotificationEmailModelWrappedLazyMutationFn = async ({
    clientNotificationEmailId,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<
      RemoveClientNotificationEmailModelMutation,
      RemoveClientNotificationEmailModelByIdMutationVariables
    > = { variables: { id: clientNotificationEmailId }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<RemoveClientNotificationEmailModelMutation>(
        { operationType: 'delete', entityName: 'clientNotificationEmail', objects: [{ id: clientNotificationEmailId }] }
      );
    }
    if (!options || !options.update) {
      mutationOptions.update = generateUpdateFunctionForMutation<RemoveClientNotificationEmailModelByIdMutation>({
        operationType: 'delete',
        entityName: 'clientNotificationEmail',
        entityId: clientNotificationEmailId,
      });
    }

    const fetchResult: RemoveClientNotificationEmailModelByIdFetchResult = await lazyMutation[0](mutationOptions);
    return { ...fetchResult, affected_rows: pickAffectedRows(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], affected_rows: pickAffectedRows(lazyMutation[1].data) }] as [
    typeof wrappedLazyMutation,
    typeof lazyMutation[1] & { affected_rows: ReturnType<typeof pickAffectedRows> }
  ];
}

//
//

// Types
type RemoveClientNotificationEmailModelObjectsMutationResult = FetchResult<
  RemoveClientNotificationEmailModelMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveClientNotificationEmailModelObjectsMutationResultEx = RemoveClientNotificationEmailModelObjectsMutationResult &
  RemoveEntitiesQueryHookResultEx;

// Function
type PickRemoveClientNotificationEmailModelObjectsFn = (
  mutation: RemoveClientNotificationEmailModelMutation | null | undefined
) => number;
type RemoveClientNotificationEmailModelObjectsLazyMutationFn = MutationTuple<
  RemoveClientNotificationEmailModelMutation,
  RemoveClientNotificationEmailModelMutationVariables
>;
type RemoveClientNotificationEmailModelObjectsWrappedLazyMutationFn = (
  options: MutationFunctionOptions<
    RemoveClientNotificationEmailModelMutation,
    RemoveClientNotificationEmailModelMutationVariables
  >
) => Promise<RemoveClientNotificationEmailModelObjectsMutationResultEx>;
export type RemoveClientNotificationEmailModelObjectsLazyMutationReturn = [
  RemoveClientNotificationEmailModelObjectsWrappedLazyMutationFn,
  RemoveClientNotificationEmailModelObjectsMutationResultEx
];

function useRemoveClientNotificationEmailModelObjects(
  options?: Omit<
    MutationHookOptions<
      RemoveClientNotificationEmailModelMutation,
      RemoveClientNotificationEmailModelMutationVariables
    >,
    'mutation'
  >
): RemoveClientNotificationEmailModelObjectsLazyMutationReturn {
  const lazyMutation = useMutation<
    RemoveClientNotificationEmailModelMutation,
    RemoveClientNotificationEmailModelMutationVariables
  >(RemoveClientNotificationEmailModelDocument, options);

  const pickAffectedRows: PickRemoveClientNotificationEmailModelObjectsFn = (
    mutation: RemoveClientNotificationEmailModelMutation | null | undefined
  ) => {
    return mutation?.delete_clientNotificationEmail?.affected_rows || 0;
  };

  const wrappedLazyMutation: RemoveClientNotificationEmailModelObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: RemoveClientNotificationEmailModelObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, affected_rows: pickAffectedRows(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], affected_rows: pickAffectedRows(lazyMutation[1].data) }];
}

// ClientNotificationEmail Fragment Helper Object
//------------------------------------------------

export const ClientNotificationEmailFragmentGQLHooks = {
  useQueryById: useQueryClientNotificationEmailById,
  useQueryByIdLazy: useQueryClientNotificationEmailByIdLazy,
  useQueryObjects: useQueryClientNotificationEmailObjects,
  useQueryObjectsLazy: useQueryClientNotificationEmailObjectsLazy,
  useSubscribeToById: useSubscribeToClientNotificationEmailById,
  useSubscribeToObjects: useSubscribeToClientNotificationEmailObjects,
  useInsert: useInsertClientNotificationEmail,
  useInsertWithOnConflict: useInsertClientNotificationEmailWithOnConflict,
  useInsertObjects: useInsertClientNotificationEmailObjects,
  useUpdateById: useUpdateClientNotificationEmailById,
  useUpdateObjects: useUpdateClientNotificationEmailObjects,
};

// clientNotificationEmail MODEL HOOKS OBJECT
//------------------------------------------------

export const ClientNotificationEmailModelGQLHooks = {
  useRemoveById: useRemoveClientNotificationEmailModelById,
  useRemoveObjects: useRemoveClientNotificationEmailModelObjects,
};

// cloudTask REACT
//------------------------------------------------

export type CloudTaskByIdHookResultEx = { cloudTask: CloudTaskFragment | null | undefined };
export type CloudTaskObjectsHookResultEx = { objects: CloudTaskFragment[] };

/**
 *  Query Hook
 */

// Types
type QueryCloudTaskByIdResult = LazyQueryResult<QueryCloudTaskByIdQuery, QueryCloudTaskByIdQueryVariables>;
type QueryCloudTaskByIdSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<QueryCloudTaskByIdQuery, QueryCloudTaskByIdQueryVariables, QueryCloudTaskByIdQuery>,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryCloudTaskByIdResultEx = Omit<QueryCloudTaskByIdResult, 'subscribeToMore'> & {
  subscribeToMore: QueryCloudTaskByIdSubScribeToMore;
} & CloudTaskByIdHookResultEx;

// Function
function useQueryCloudTaskById({
  cloudTaskId,
  options,
}: {
  cloudTaskId: string;
  options?: Omit<QueryHookOptions<QueryCloudTaskByIdQuery, QueryCloudTaskByIdQueryVariables>, 'query' | 'variables'>;
}): QueryCloudTaskByIdResultEx {
  const _query: QueryCloudTaskByIdResult = useQuery<QueryCloudTaskByIdQuery, QueryCloudTaskByIdQueryVariables>(
    QueryCloudTaskByIdDocument,
    { variables: { cloudTaskId }, ...options }
  );

  const typedSubscribeToMore: QueryCloudTaskByIdSubScribeToMore = (options) => {
    _query.subscribeToMore({
      document: QueryCloudTaskByIdDocument,
      variables: { cloudTaskId } as QueryCloudTaskByIdQueryVariables,
      ...options,
    });
  };
  const { subscribeToMore, ...query } = _query;
  return { ...query, subscribeToMore: typedSubscribeToMore, cloudTask: query?.data?.cloudTask_by_pk };
}

/**
 *  Lazy Query Hook
 */

// Types
type PickQueryCloudTaskByIdFn = (
  query: QueryCloudTaskByIdQuery | null | undefined
) => CloudTaskFragment | null | undefined;
type QueryCloudTaskByIdLazyFn = [
  (options?: QueryLazyOptions<QueryCloudTaskByIdQueryVariables> | undefined) => void,
  QueryCloudTaskByIdResult
];
type QueryCloudTaskByIdWrappedLazyFn = (
  options: Omit<QueryLazyOptions<QueryCloudTaskByIdQueryVariables>, 'variables'>
) => void;
export type QueryCloudTaskByIdLazyReturn = [QueryCloudTaskByIdWrappedLazyFn, QueryCloudTaskByIdResultEx];

// Function
function useQueryCloudTaskByIdLazy({
  cloudTaskId,
  options,
}: {
  cloudTaskId: string;
  options?: Omit<QueryHookOptions<QueryCloudTaskByIdQuery, QueryCloudTaskByIdQueryVariables>, 'query' | 'variables'>;
}): QueryCloudTaskByIdLazyReturn {
  const lazyQuery: QueryCloudTaskByIdLazyFn = useLazyQuery<QueryCloudTaskByIdQuery, QueryCloudTaskByIdQueryVariables>(
    QueryCloudTaskByIdDocument,
    options
  );

  // Setting up typed version of lazyQuery
  const pickQueryCloudTaskById: PickQueryCloudTaskByIdFn = (query) => {
    return query && query.cloudTask_by_pk;
  };
  const wrappedLazyQuery: QueryCloudTaskByIdWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryCloudTaskByIdSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({
        document: QueryCloudTaskByIdDocument,
        variables: { cloudTaskId } as QueryCloudTaskByIdQueryVariables,
        ...options,
      });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    { ...lazyQueryResult, subscribeToMore: typedSubcribeToMore, cloudTask: pickQueryCloudTaskById(lazyQuery[1].data) },
  ];
}

/**
 *  Query Collection Hook
 */

// Types
export type QueryCloudTaskObjectsResult = LazyQueryResult<
  QueryCloudTaskObjectsQuery,
  QueryCloudTaskObjectsQueryVariables
>;
type QueryCloudTaskObjectsSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<
          QueryCloudTaskObjectsQuery,
          QueryCloudTaskObjectsQueryVariables,
          QueryCloudTaskObjectsQuery
        >,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryCloudTaskObjectsResultEx = Omit<QueryCloudTaskObjectsResult, 'subscribeToMore'> & {
  subscribeToMore: QueryCloudTaskObjectsSubScribeToMore;
} & CloudTaskObjectsHookResultEx;

// Function
function useQueryCloudTaskObjects(
  options: Omit<QueryHookOptions<QueryCloudTaskObjectsQuery, QueryCloudTaskObjectsQueryVariables>, 'query'>
): QueryCloudTaskObjectsResultEx {
  const _query: QueryCloudTaskObjectsResult = useQuery<QueryCloudTaskObjectsQuery, QueryCloudTaskObjectsQueryVariables>(
    QueryCloudTaskObjectsDocument,
    options
  );

  const typedSubscribeToMore: QueryCloudTaskObjectsSubScribeToMore = (options) => {
    _query.subscribeToMore({ document: QueryCloudTaskObjectsDocument, ...options });
  };
  const { subscribeToMore, ...query } = _query;

  return { ...query, subscribeToMore: typedSubscribeToMore, objects: query?.data?.cloudTask || [] };
}

/**
 *  Lazy Query Collection Hook
 */

// Types
type PickQueryCloudTaskObjectsFn = (query: QueryCloudTaskObjectsQuery | null | undefined) => CloudTaskFragment[];
type QueryCloudTaskObjectsLazyFn = [
  (options?: QueryLazyOptions<QueryCloudTaskObjectsQueryVariables> | undefined) => void,
  QueryCloudTaskObjectsResult
];
type QueryCloudTaskObjectsWrappedLazyFn = (options?: QueryLazyOptions<QueryCloudTaskObjectsQueryVariables>) => void;
export type QueryCloudTaskObjectsLazyReturn = [QueryCloudTaskObjectsWrappedLazyFn, QueryCloudTaskObjectsResultEx];

// Function
function useQueryCloudTaskObjectsLazy(
  options?: Omit<LazyQueryHookOptions<QueryCloudTaskObjectsQuery, QueryCloudTaskObjectsQueryVariables>, 'query'>
): QueryCloudTaskObjectsLazyReturn {
  const lazyQuery: QueryCloudTaskObjectsLazyFn = useLazyQuery<
    QueryCloudTaskObjectsQuery,
    QueryCloudTaskObjectsQueryVariables
  >(QueryCloudTaskObjectsDocument, options);

  // Setting up typed version of lazyQuery
  const pickObjects: PickQueryCloudTaskObjectsFn = (query: QueryCloudTaskObjectsQuery | null | undefined) => {
    return query?.cloudTask || [];
  };
  const wrappedLazyQuery: QueryCloudTaskObjectsWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryCloudTaskObjectsSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({ document: QueryCloudTaskObjectsDocument, ...options });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    { ...lazyQueryResult, subscribeToMore: typedSubcribeToMore, objects: pickObjects(lazyQuery[1].data) },
  ];
}

/**
 *  Subscription Hook
 */

// Types
type SubscribeToCloudTaskByIdResult = {
  variables?: SubscribeToCloudTaskByIdSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToCloudTaskByIdSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToCloudTaskByIdResultEx = SubscribeToCloudTaskByIdResult & CloudTaskByIdHookResultEx;

// Function
function useSubscribeToCloudTaskById({
  cloudTaskId,
  options,
}: {
  cloudTaskId: string;
  options?: Omit<
    SubscriptionHookOptions<SubscribeToCloudTaskByIdSubscription, SubscribeToCloudTaskByIdSubscriptionVariables>,
    'query' | 'variables'
  >;
}): SubscribeToCloudTaskByIdResultEx {
  const subscription: SubscribeToCloudTaskByIdResult = useSubscription<
    SubscribeToCloudTaskByIdSubscription,
    SubscribeToCloudTaskByIdSubscriptionVariables
  >(SubscribeToCloudTaskByIdDocument, { variables: { cloudTaskId }, ...options });
  return { ...subscription, cloudTask: subscription?.data?.cloudTask_by_pk };
}

/**
 *  Subscription Collection Hook
 */

// Types
export type SubscribeToCloudTaskObjectsResult = {
  variables?: SubscribeToCloudTaskObjectsSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToCloudTaskObjectsSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToCloudTaskObjectsResultEx = SubscribeToCloudTaskObjectsResult & CloudTaskObjectsHookResultEx;

// Function
function useSubscribeToCloudTaskObjects(
  options: Omit<
    SubscriptionHookOptions<SubscribeToCloudTaskObjectsSubscription, SubscribeToCloudTaskObjectsSubscriptionVariables>,
    'query'
  >
): SubscribeToCloudTaskObjectsResultEx {
  const subscription: SubscribeToCloudTaskObjectsResult = useSubscription<
    SubscribeToCloudTaskObjectsSubscription,
    SubscribeToCloudTaskObjectsSubscriptionVariables
  >(SubscribeToCloudTaskObjectsDocument, options);
  return { ...subscription, objects: subscription?.data?.cloudTask || [] };
}

/**
 *  Insert Hooks
 */

// Types
type InsertCloudTaskMutationResult = FetchResult<InsertCloudTaskMutation, Record<string, any>, Record<string, any>>;
export type InsertCloudTaskMutationResultEx = InsertCloudTaskMutationResult & CloudTaskByIdHookResultEx;

type PickInsertCloudTaskFn = (
  mutation: InsertCloudTaskMutation | null | undefined
) => CloudTaskFragment | null | undefined;
type InsertCloudTaskLazyMutationFn = MutationTuple<InsertCloudTaskMutation, InsertCloudTaskMutationVariables>;
type InsertCloudTaskWrappedLazyMutationFn = ({
  cloudTask,
  autoOptimisticResponse,
  options,
}: {
  cloudTask: CloudTask_Insert_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<MutationFunctionOptions<InsertCloudTaskMutation, InsertCloudTaskMutationVariables>, 'variables'>;
}) => Promise<InsertCloudTaskMutationResultEx>;
export type InsertCloudTaskLazyMutationReturn = [InsertCloudTaskWrappedLazyMutationFn, InsertCloudTaskMutationResultEx];

// Function
function useInsertCloudTask(
  options?: Omit<
    MutationHookOptions<InsertCloudTaskMutation, InsertCloudTaskMutationVariables>,
    'mutation' | 'variables'
  >
): InsertCloudTaskLazyMutationReturn {
  const lazyMutation: InsertCloudTaskLazyMutationFn = useMutation<
    InsertCloudTaskMutation,
    InsertCloudTaskMutationVariables
  >(InsertCloudTaskDocument, options);
  const pickCloudTask: PickInsertCloudTaskFn = (mutation) => {
    return mutation?.insert_cloudTask?.returning && mutation?.insert_cloudTask?.returning[0];
  };

  const wrappedLazyMutation: InsertCloudTaskWrappedLazyMutationFn = async ({
    cloudTask,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: cloudTask });
    const mutationOptions: MutationFunctionOptions<InsertCloudTaskMutation, InsertCloudTaskMutationVariables> = {
      variables: { objects: [objectForInsert] },
      ...options,
    };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id)
        throw new Error(`if autoOptimisticResponse = true, id must be set in object 'cloudTask'`);
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertCloudTaskMutation>({
        operationType: 'insert',
        entityName: 'cloudTask',
        objects: [objectForInsert as CloudTask_Insert_Input & ObjectWithId],
      });
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, cloudTask: pickCloudTask(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], cloudTask: pickCloudTask(lazyMutation[1].data) }];
}

//
//

// Types
type InsertCloudTaskWithOnConflictMutationResult = FetchResult<
  InsertCloudTaskWithOnConflictMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertCloudTaskWithOnConflictMutationResultEx = InsertCloudTaskWithOnConflictMutationResult &
  CloudTaskByIdHookResultEx;

type InsertCloudTaskWithOnConflictLazyMutationFn = MutationTuple<
  InsertCloudTaskWithOnConflictMutation,
  InsertCloudTaskWithOnConflictMutationVariables
>;
type InsertCloudTaskWithOnConflictWrappedLazyMutationFn = ({
  cloudTask,
  onConflict,
  autoOptimisticResponse,
  options,
}: {
  cloudTask: CloudTask_Insert_Input;
  onConflict: CloudTask_On_Conflict;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<InsertCloudTaskWithOnConflictMutation, InsertCloudTaskWithOnConflictMutationVariables>,
    'variables'
  >;
}) => Promise<InsertCloudTaskWithOnConflictMutationResultEx>;
export type InsertCloudTaskWithOnConflictLazyMutationReturn = [
  InsertCloudTaskWithOnConflictWrappedLazyMutationFn,
  InsertCloudTaskWithOnConflictMutationResultEx
];

// Function
function useInsertCloudTaskWithOnConflict(
  options?: Omit<
    MutationHookOptions<InsertCloudTaskWithOnConflictMutation, InsertCloudTaskWithOnConflictMutationVariables>,
    'mutation' | 'variables'
  >
): InsertCloudTaskWithOnConflictLazyMutationReturn {
  const lazyMutation: InsertCloudTaskWithOnConflictLazyMutationFn = useMutation<
    InsertCloudTaskWithOnConflictMutation,
    InsertCloudTaskWithOnConflictMutationVariables
  >(InsertCloudTaskWithOnConflictDocument, options);
  const pickCloudTask: PickInsertCloudTaskFn = (mutation: InsertCloudTaskWithOnConflictMutation | null | undefined) => {
    return mutation?.insert_cloudTask?.returning && mutation.insert_cloudTask.returning[0];
  };

  const wrappedLazyMutation: InsertCloudTaskWithOnConflictWrappedLazyMutationFn = async ({
    cloudTask,
    onConflict,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: cloudTask });
    const mutationOptions: MutationFunctionOptions<
      InsertCloudTaskWithOnConflictMutation,
      InsertCloudTaskWithOnConflictMutationVariables
    > = { variables: { objects: [objectForInsert], onConflict }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id)
        throw new Error(`if autoOptimisticResponse = true, id must be set in object 'cloudTask'`);
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertCloudTaskWithOnConflictMutation>(
        {
          operationType: 'insert',
          entityName: 'cloudTask',
          objects: [objectForInsert as CloudTask_Insert_Input & ObjectWithId],
        }
      );
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, cloudTask: pickCloudTask(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], cloudTask: pickCloudTask(lazyMutation[1].data) }];
}

// Types
type InsertCloudTaskObjectsMutationResult = FetchResult<
  InsertCloudTaskMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertCloudTaskObjectsMutationResultEx = InsertCloudTaskMutationResult & CloudTaskObjectsHookResultEx;

type PickInsertCloudTaskObjectsFn = (mutation: InsertCloudTaskMutation | null | undefined) => CloudTaskFragment[];
type InsertCloudTaskObjectsLazyMutationFn = MutationTuple<InsertCloudTaskMutation, InsertCloudTaskMutationVariables>;
type InsertCloudTaskObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<InsertCloudTaskMutation, InsertCloudTaskMutationVariables>
) => Promise<InsertCloudTaskObjectsMutationResultEx>;
export type InsertCloudTaskObjectsLazyMutationReturn = [
  InsertCloudTaskObjectsWrappedLazyMutationFn,
  InsertCloudTaskObjectsMutationResultEx
];

// Function
function useInsertCloudTaskObjects(
  options?: Omit<MutationHookOptions<InsertCloudTaskMutation, InsertCloudTaskMutationVariables>, 'mutation'>
): InsertCloudTaskObjectsLazyMutationReturn {
  const lazyMutation: InsertCloudTaskObjectsLazyMutationFn = useMutation<
    InsertCloudTaskMutation,
    InsertCloudTaskMutationVariables
  >(InsertCloudTaskDocument, options);
  const pickObjects: PickInsertCloudTaskObjectsFn = (mutation: InsertCloudTaskMutation | null | undefined) => {
    return mutation?.insert_cloudTask?.returning || [];
  };

  const wrappedLazyMutation: InsertCloudTaskObjectsWrappedLazyMutationFn = async (options) => {
    if (options && options.variables && options.variables.objects)
      options.variables.objects = options.variables.objects.map((objectItem) =>
        stripInsertInputClientFields({ input: objectItem })
      );
    const fetchResult: InsertCloudTaskObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Update Hooks
 */

type UpdateCloudTaskByIdMutationResult = FetchResult<
  UpdateCloudTaskByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateCloudTaskByIdMutationResultEx = UpdateCloudTaskByIdMutationResult & CloudTaskByIdHookResultEx;

type PickUpdateCloudTaskByIdFn = (
  mutation: UpdateCloudTaskByIdMutation | null | undefined
) => CloudTaskFragment | null | undefined;
type UpdateCloudTaskByIdLazyMutationFn = MutationTuple<
  UpdateCloudTaskByIdMutation,
  UpdateCloudTaskByIdMutationVariables
>;
type UpdateCloudTaskByIdWrappedLazyMutationFn = ({
  cloudTaskId,
  set,
  autoOptimisticResponse,
  options,
}: {
  cloudTaskId: string;
  set: CloudTask_Set_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<UpdateCloudTaskByIdMutation, UpdateCloudTaskByIdMutationVariables>,
    'variables'
  >;
}) => Promise<UpdateCloudTaskByIdMutationResultEx>;
export type UpdateCloudTaskByIdLazyMutationReturn = [
  UpdateCloudTaskByIdWrappedLazyMutationFn,
  UpdateCloudTaskByIdMutationResultEx
];

function useUpdateCloudTaskById(
  options?: Omit<
    MutationHookOptions<UpdateCloudTaskByIdMutation, UpdateCloudTaskByIdMutationVariables>,
    'mutation' | 'variables'
  >
): UpdateCloudTaskByIdLazyMutationReturn {
  const lazyMutation: UpdateCloudTaskByIdLazyMutationFn = useMutation<
    UpdateCloudTaskByIdMutation,
    UpdateCloudTaskByIdMutationVariables
  >(UpdateCloudTaskByIdDocument, options);

  const pickCloudTask: PickUpdateCloudTaskByIdFn = (mutation) => {
    return mutation?.update_cloudTask?.returning && mutation.update_cloudTask!.returning[0];
  };

  const wrappedLazyMutation: UpdateCloudTaskByIdWrappedLazyMutationFn = async ({
    cloudTaskId,
    set,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<
      UpdateCloudTaskByIdMutation,
      UpdateCloudTaskByIdMutationVariables
    > = { variables: { id: cloudTaskId, set }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateCloudTaskByIdMutation>({
        operationType: 'update',
        entityName: 'cloudTask',
        objects: [{ id: cloudTaskId, ...set }],
      });
    }

    const fetchResult: UpdateCloudTaskByIdMutationResult = await lazyMutation[0]({
      variables: { id: cloudTaskId, set },
      ...mutationOptions,
    });
    return { ...fetchResult, cloudTask: pickCloudTask(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], cloudTask: pickCloudTask(lazyMutation[1].data) }];
}

//
//

// Types
type UpdateCloudTaskObjectsMutationResult = FetchResult<
  UpdateCloudTaskMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateCloudTaskObjectsMutationResultEx = UpdateCloudTaskObjectsMutationResult &
  CloudTaskObjectsHookResultEx;

// Function
type PickUpdateCloudTaskObjectsFn = (mutation: UpdateCloudTaskMutation | null | undefined) => CloudTaskFragment[];
type UpdateCloudTaskObjectsLazyMutationFn = MutationTuple<UpdateCloudTaskMutation, UpdateCloudTaskMutationVariables>;
type UpdateCloudTaskObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<UpdateCloudTaskMutation, UpdateCloudTaskMutationVariables>
) => Promise<UpdateCloudTaskObjectsMutationResultEx>;
export type UpdateCloudTaskObjectsLazyMutationReturn = [
  UpdateCloudTaskObjectsWrappedLazyMutationFn,
  UpdateCloudTaskObjectsMutationResultEx
];

function useUpdateCloudTaskObjects(
  options?: Omit<MutationHookOptions<UpdateCloudTaskMutation, UpdateCloudTaskMutationVariables>, 'mutation'>
): UpdateCloudTaskObjectsLazyMutationReturn {
  const lazyMutation: UpdateCloudTaskObjectsLazyMutationFn = useMutation<
    UpdateCloudTaskMutation,
    UpdateCloudTaskMutationVariables
  >(UpdateCloudTaskDocument, options);

  const pickObjects: PickUpdateCloudTaskObjectsFn = (mutation: UpdateCloudTaskMutation | null | undefined) => {
    return mutation?.update_cloudTask?.returning || [];
  };

  const wrappedLazyMutation: UpdateCloudTaskObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: UpdateCloudTaskObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Delete Hooks
 */

// Types
type RemoveCloudTaskModelByIdFetchResult = FetchResult<
  RemoveCloudTaskModelByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveCloudTaskModelByIdMutationResultEx = RemoveCloudTaskModelByIdFetchResult &
  RemoveEntitiesQueryHookResultEx;

// Function
type PickRemoveCloudTaskModelFn = (mutation: RemoveCloudTaskModelByIdMutation | null | undefined) => number;
type RemoveCloudTaskModelLazyMutationFn = MutationTuple<
  RemoveCloudTaskModelByIdMutation,
  RemoveCloudTaskModelByIdMutationVariables
>;
type RemoveCloudTaskModelWrappedLazyMutationFn = ({
  cloudTaskId,
  autoOptimisticResponse,
  options,
}: {
  cloudTaskId: string;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<RemoveCloudTaskModelByIdMutation, RemoveCloudTaskModelByIdMutationVariables>,
    'variables'
  >;
}) => Promise<RemoveCloudTaskModelByIdMutationResultEx>;
export type RemoveCloudTaskModelLazyMutationReturn = [
  RemoveCloudTaskModelWrappedLazyMutationFn,
  RemoveCloudTaskModelByIdMutationResultEx
];

function useRemoveCloudTaskModelById(
  options?: Omit<
    MutationHookOptions<RemoveCloudTaskModelByIdMutation, RemoveCloudTaskModelByIdMutationVariables>,
    'mutation' | 'variables'
  >
) {
  const lazyMutation: RemoveCloudTaskModelLazyMutationFn = useMutation<
    RemoveCloudTaskModelByIdMutation,
    RemoveCloudTaskModelByIdMutationVariables
  >(RemoveCloudTaskModelByIdDocument, options);

  const pickAffectedRows: PickRemoveCloudTaskModelFn = (
    mutation: RemoveCloudTaskModelByIdMutation | null | undefined
  ) => {
    return mutation?.delete_cloudTask?.affected_rows || 0;
  };

  const wrappedLazyMutation: RemoveCloudTaskModelWrappedLazyMutationFn = async ({
    cloudTaskId,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<
      RemoveCloudTaskModelMutation,
      RemoveCloudTaskModelByIdMutationVariables
    > = { variables: { id: cloudTaskId }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<RemoveCloudTaskModelMutation>({
        operationType: 'delete',
        entityName: 'cloudTask',
        objects: [{ id: cloudTaskId }],
      });
    }
    if (!options || !options.update) {
      mutationOptions.update = generateUpdateFunctionForMutation<RemoveCloudTaskModelByIdMutation>({
        operationType: 'delete',
        entityName: 'cloudTask',
        entityId: cloudTaskId,
      });
    }

    const fetchResult: RemoveCloudTaskModelByIdFetchResult = await lazyMutation[0](mutationOptions);
    return { ...fetchResult, affected_rows: pickAffectedRows(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], affected_rows: pickAffectedRows(lazyMutation[1].data) }] as [
    typeof wrappedLazyMutation,
    typeof lazyMutation[1] & { affected_rows: ReturnType<typeof pickAffectedRows> }
  ];
}

//
//

// Types
type RemoveCloudTaskModelObjectsMutationResult = FetchResult<
  RemoveCloudTaskModelMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveCloudTaskModelObjectsMutationResultEx = RemoveCloudTaskModelObjectsMutationResult &
  RemoveEntitiesQueryHookResultEx;

// Function
type PickRemoveCloudTaskModelObjectsFn = (mutation: RemoveCloudTaskModelMutation | null | undefined) => number;
type RemoveCloudTaskModelObjectsLazyMutationFn = MutationTuple<
  RemoveCloudTaskModelMutation,
  RemoveCloudTaskModelMutationVariables
>;
type RemoveCloudTaskModelObjectsWrappedLazyMutationFn = (
  options: MutationFunctionOptions<RemoveCloudTaskModelMutation, RemoveCloudTaskModelMutationVariables>
) => Promise<RemoveCloudTaskModelObjectsMutationResultEx>;
export type RemoveCloudTaskModelObjectsLazyMutationReturn = [
  RemoveCloudTaskModelObjectsWrappedLazyMutationFn,
  RemoveCloudTaskModelObjectsMutationResultEx
];

function useRemoveCloudTaskModelObjects(
  options?: Omit<MutationHookOptions<RemoveCloudTaskModelMutation, RemoveCloudTaskModelMutationVariables>, 'mutation'>
): RemoveCloudTaskModelObjectsLazyMutationReturn {
  const lazyMutation = useMutation<RemoveCloudTaskModelMutation, RemoveCloudTaskModelMutationVariables>(
    RemoveCloudTaskModelDocument,
    options
  );

  const pickAffectedRows: PickRemoveCloudTaskModelObjectsFn = (
    mutation: RemoveCloudTaskModelMutation | null | undefined
  ) => {
    return mutation?.delete_cloudTask?.affected_rows || 0;
  };

  const wrappedLazyMutation: RemoveCloudTaskModelObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: RemoveCloudTaskModelObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, affected_rows: pickAffectedRows(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], affected_rows: pickAffectedRows(lazyMutation[1].data) }];
}

// CloudTask Fragment Helper Object
//------------------------------------------------

export const CloudTaskFragmentGQLHooks = {
  useQueryById: useQueryCloudTaskById,
  useQueryByIdLazy: useQueryCloudTaskByIdLazy,
  useQueryObjects: useQueryCloudTaskObjects,
  useQueryObjectsLazy: useQueryCloudTaskObjectsLazy,
  useSubscribeToById: useSubscribeToCloudTaskById,
  useSubscribeToObjects: useSubscribeToCloudTaskObjects,
  useInsert: useInsertCloudTask,
  useInsertWithOnConflict: useInsertCloudTaskWithOnConflict,
  useInsertObjects: useInsertCloudTaskObjects,
  useUpdateById: useUpdateCloudTaskById,
  useUpdateObjects: useUpdateCloudTaskObjects,
};

// cloudTask MODEL HOOKS OBJECT
//------------------------------------------------

export const CloudTaskModelGQLHooks = {
  useRemoveById: useRemoveCloudTaskModelById,
  useRemoveObjects: useRemoveCloudTaskModelObjects,
};

// clientEmailReport REACT
//------------------------------------------------

export type ClientEmailReportByIdHookResultEx = { clientEmailReport: ClientEmailReportFragment | null | undefined };
export type ClientEmailReportObjectsHookResultEx = { objects: ClientEmailReportFragment[] };

/**
 *  Query Hook
 */

// Types
type QueryClientEmailReportByIdResult = LazyQueryResult<
  QueryClientEmailReportByIdQuery,
  QueryClientEmailReportByIdQueryVariables
>;
type QueryClientEmailReportByIdSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<
          QueryClientEmailReportByIdQuery,
          QueryClientEmailReportByIdQueryVariables,
          QueryClientEmailReportByIdQuery
        >,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryClientEmailReportByIdResultEx = Omit<QueryClientEmailReportByIdResult, 'subscribeToMore'> & {
  subscribeToMore: QueryClientEmailReportByIdSubScribeToMore;
} & ClientEmailReportByIdHookResultEx;

// Function
function useQueryClientEmailReportById({
  clientEmailReportId,
  options,
}: {
  clientEmailReportId: string;
  options?: Omit<
    QueryHookOptions<QueryClientEmailReportByIdQuery, QueryClientEmailReportByIdQueryVariables>,
    'query' | 'variables'
  >;
}): QueryClientEmailReportByIdResultEx {
  const _query: QueryClientEmailReportByIdResult = useQuery<
    QueryClientEmailReportByIdQuery,
    QueryClientEmailReportByIdQueryVariables
  >(QueryClientEmailReportByIdDocument, { variables: { clientEmailReportId }, ...options });

  const typedSubscribeToMore: QueryClientEmailReportByIdSubScribeToMore = (options) => {
    _query.subscribeToMore({
      document: QueryClientEmailReportByIdDocument,
      variables: { clientEmailReportId } as QueryClientEmailReportByIdQueryVariables,
      ...options,
    });
  };
  const { subscribeToMore, ...query } = _query;
  return { ...query, subscribeToMore: typedSubscribeToMore, clientEmailReport: query?.data?.clientEmailReport_by_pk };
}

/**
 *  Lazy Query Hook
 */

// Types
type PickQueryClientEmailReportByIdFn = (
  query: QueryClientEmailReportByIdQuery | null | undefined
) => ClientEmailReportFragment | null | undefined;
type QueryClientEmailReportByIdLazyFn = [
  (options?: QueryLazyOptions<QueryClientEmailReportByIdQueryVariables> | undefined) => void,
  QueryClientEmailReportByIdResult
];
type QueryClientEmailReportByIdWrappedLazyFn = (
  options: Omit<QueryLazyOptions<QueryClientEmailReportByIdQueryVariables>, 'variables'>
) => void;
export type QueryClientEmailReportByIdLazyReturn = [
  QueryClientEmailReportByIdWrappedLazyFn,
  QueryClientEmailReportByIdResultEx
];

// Function
function useQueryClientEmailReportByIdLazy({
  clientEmailReportId,
  options,
}: {
  clientEmailReportId: string;
  options?: Omit<
    QueryHookOptions<QueryClientEmailReportByIdQuery, QueryClientEmailReportByIdQueryVariables>,
    'query' | 'variables'
  >;
}): QueryClientEmailReportByIdLazyReturn {
  const lazyQuery: QueryClientEmailReportByIdLazyFn = useLazyQuery<
    QueryClientEmailReportByIdQuery,
    QueryClientEmailReportByIdQueryVariables
  >(QueryClientEmailReportByIdDocument, options);

  // Setting up typed version of lazyQuery
  const pickQueryClientEmailReportById: PickQueryClientEmailReportByIdFn = (query) => {
    return query && query.clientEmailReport_by_pk;
  };
  const wrappedLazyQuery: QueryClientEmailReportByIdWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryClientEmailReportByIdSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({
        document: QueryClientEmailReportByIdDocument,
        variables: { clientEmailReportId } as QueryClientEmailReportByIdQueryVariables,
        ...options,
      });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    {
      ...lazyQueryResult,
      subscribeToMore: typedSubcribeToMore,
      clientEmailReport: pickQueryClientEmailReportById(lazyQuery[1].data),
    },
  ];
}

/**
 *  Query Collection Hook
 */

// Types
export type QueryClientEmailReportObjectsResult = LazyQueryResult<
  QueryClientEmailReportObjectsQuery,
  QueryClientEmailReportObjectsQueryVariables
>;
type QueryClientEmailReportObjectsSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<
          QueryClientEmailReportObjectsQuery,
          QueryClientEmailReportObjectsQueryVariables,
          QueryClientEmailReportObjectsQuery
        >,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryClientEmailReportObjectsResultEx = Omit<QueryClientEmailReportObjectsResult, 'subscribeToMore'> & {
  subscribeToMore: QueryClientEmailReportObjectsSubScribeToMore;
} & ClientEmailReportObjectsHookResultEx;

// Function
function useQueryClientEmailReportObjects(
  options: Omit<
    QueryHookOptions<QueryClientEmailReportObjectsQuery, QueryClientEmailReportObjectsQueryVariables>,
    'query'
  >
): QueryClientEmailReportObjectsResultEx {
  const _query: QueryClientEmailReportObjectsResult = useQuery<
    QueryClientEmailReportObjectsQuery,
    QueryClientEmailReportObjectsQueryVariables
  >(QueryClientEmailReportObjectsDocument, options);

  const typedSubscribeToMore: QueryClientEmailReportObjectsSubScribeToMore = (options) => {
    _query.subscribeToMore({ document: QueryClientEmailReportObjectsDocument, ...options });
  };
  const { subscribeToMore, ...query } = _query;

  return { ...query, subscribeToMore: typedSubscribeToMore, objects: query?.data?.clientEmailReport || [] };
}

/**
 *  Lazy Query Collection Hook
 */

// Types
type PickQueryClientEmailReportObjectsFn = (
  query: QueryClientEmailReportObjectsQuery | null | undefined
) => ClientEmailReportFragment[];
type QueryClientEmailReportObjectsLazyFn = [
  (options?: QueryLazyOptions<QueryClientEmailReportObjectsQueryVariables> | undefined) => void,
  QueryClientEmailReportObjectsResult
];
type QueryClientEmailReportObjectsWrappedLazyFn = (
  options?: QueryLazyOptions<QueryClientEmailReportObjectsQueryVariables>
) => void;
export type QueryClientEmailReportObjectsLazyReturn = [
  QueryClientEmailReportObjectsWrappedLazyFn,
  QueryClientEmailReportObjectsResultEx
];

// Function
function useQueryClientEmailReportObjectsLazy(
  options?: Omit<
    LazyQueryHookOptions<QueryClientEmailReportObjectsQuery, QueryClientEmailReportObjectsQueryVariables>,
    'query'
  >
): QueryClientEmailReportObjectsLazyReturn {
  const lazyQuery: QueryClientEmailReportObjectsLazyFn = useLazyQuery<
    QueryClientEmailReportObjectsQuery,
    QueryClientEmailReportObjectsQueryVariables
  >(QueryClientEmailReportObjectsDocument, options);

  // Setting up typed version of lazyQuery
  const pickObjects: PickQueryClientEmailReportObjectsFn = (
    query: QueryClientEmailReportObjectsQuery | null | undefined
  ) => {
    return query?.clientEmailReport || [];
  };
  const wrappedLazyQuery: QueryClientEmailReportObjectsWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryClientEmailReportObjectsSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({ document: QueryClientEmailReportObjectsDocument, ...options });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    { ...lazyQueryResult, subscribeToMore: typedSubcribeToMore, objects: pickObjects(lazyQuery[1].data) },
  ];
}

/**
 *  Subscription Hook
 */

// Types
type SubscribeToClientEmailReportByIdResult = {
  variables?: SubscribeToClientEmailReportByIdSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToClientEmailReportByIdSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToClientEmailReportByIdResultEx = SubscribeToClientEmailReportByIdResult &
  ClientEmailReportByIdHookResultEx;

// Function
function useSubscribeToClientEmailReportById({
  clientEmailReportId,
  options,
}: {
  clientEmailReportId: string;
  options?: Omit<
    SubscriptionHookOptions<
      SubscribeToClientEmailReportByIdSubscription,
      SubscribeToClientEmailReportByIdSubscriptionVariables
    >,
    'query' | 'variables'
  >;
}): SubscribeToClientEmailReportByIdResultEx {
  const subscription: SubscribeToClientEmailReportByIdResult = useSubscription<
    SubscribeToClientEmailReportByIdSubscription,
    SubscribeToClientEmailReportByIdSubscriptionVariables
  >(SubscribeToClientEmailReportByIdDocument, { variables: { clientEmailReportId }, ...options });
  return { ...subscription, clientEmailReport: subscription?.data?.clientEmailReport_by_pk };
}

/**
 *  Subscription Collection Hook
 */

// Types
export type SubscribeToClientEmailReportObjectsResult = {
  variables?: SubscribeToClientEmailReportObjectsSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToClientEmailReportObjectsSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToClientEmailReportObjectsResultEx = SubscribeToClientEmailReportObjectsResult &
  ClientEmailReportObjectsHookResultEx;

// Function
function useSubscribeToClientEmailReportObjects(
  options: Omit<
    SubscriptionHookOptions<
      SubscribeToClientEmailReportObjectsSubscription,
      SubscribeToClientEmailReportObjectsSubscriptionVariables
    >,
    'query'
  >
): SubscribeToClientEmailReportObjectsResultEx {
  const subscription: SubscribeToClientEmailReportObjectsResult = useSubscription<
    SubscribeToClientEmailReportObjectsSubscription,
    SubscribeToClientEmailReportObjectsSubscriptionVariables
  >(SubscribeToClientEmailReportObjectsDocument, options);
  return { ...subscription, objects: subscription?.data?.clientEmailReport || [] };
}

/**
 *  Insert Hooks
 */

// Types
type InsertClientEmailReportMutationResult = FetchResult<
  InsertClientEmailReportMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertClientEmailReportMutationResultEx = InsertClientEmailReportMutationResult &
  ClientEmailReportByIdHookResultEx;

type PickInsertClientEmailReportFn = (
  mutation: InsertClientEmailReportMutation | null | undefined
) => ClientEmailReportFragment | null | undefined;
type InsertClientEmailReportLazyMutationFn = MutationTuple<
  InsertClientEmailReportMutation,
  InsertClientEmailReportMutationVariables
>;
type InsertClientEmailReportWrappedLazyMutationFn = ({
  clientEmailReport,
  autoOptimisticResponse,
  options,
}: {
  clientEmailReport: ClientEmailReport_Insert_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<InsertClientEmailReportMutation, InsertClientEmailReportMutationVariables>,
    'variables'
  >;
}) => Promise<InsertClientEmailReportMutationResultEx>;
export type InsertClientEmailReportLazyMutationReturn = [
  InsertClientEmailReportWrappedLazyMutationFn,
  InsertClientEmailReportMutationResultEx
];

// Function
function useInsertClientEmailReport(
  options?: Omit<
    MutationHookOptions<InsertClientEmailReportMutation, InsertClientEmailReportMutationVariables>,
    'mutation' | 'variables'
  >
): InsertClientEmailReportLazyMutationReturn {
  const lazyMutation: InsertClientEmailReportLazyMutationFn = useMutation<
    InsertClientEmailReportMutation,
    InsertClientEmailReportMutationVariables
  >(InsertClientEmailReportDocument, options);
  const pickClientEmailReport: PickInsertClientEmailReportFn = (mutation) => {
    return mutation?.insert_clientEmailReport?.returning && mutation?.insert_clientEmailReport?.returning[0];
  };

  const wrappedLazyMutation: InsertClientEmailReportWrappedLazyMutationFn = async ({
    clientEmailReport,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: clientEmailReport });
    const mutationOptions: MutationFunctionOptions<
      InsertClientEmailReportMutation,
      InsertClientEmailReportMutationVariables
    > = { variables: { objects: [objectForInsert] }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id)
        throw new Error(`if autoOptimisticResponse = true, id must be set in object 'clientEmailReport'`);
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertClientEmailReportMutation>({
        operationType: 'insert',
        entityName: 'clientEmailReport',
        objects: [objectForInsert as ClientEmailReport_Insert_Input & ObjectWithId],
      });
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, clientEmailReport: pickClientEmailReport(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], clientEmailReport: pickClientEmailReport(lazyMutation[1].data) }];
}

//
//

// Types
type InsertClientEmailReportWithOnConflictMutationResult = FetchResult<
  InsertClientEmailReportWithOnConflictMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertClientEmailReportWithOnConflictMutationResultEx = InsertClientEmailReportWithOnConflictMutationResult &
  ClientEmailReportByIdHookResultEx;

type InsertClientEmailReportWithOnConflictLazyMutationFn = MutationTuple<
  InsertClientEmailReportWithOnConflictMutation,
  InsertClientEmailReportWithOnConflictMutationVariables
>;
type InsertClientEmailReportWithOnConflictWrappedLazyMutationFn = ({
  clientEmailReport,
  onConflict,
  autoOptimisticResponse,
  options,
}: {
  clientEmailReport: ClientEmailReport_Insert_Input;
  onConflict: ClientEmailReport_On_Conflict;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<
      InsertClientEmailReportWithOnConflictMutation,
      InsertClientEmailReportWithOnConflictMutationVariables
    >,
    'variables'
  >;
}) => Promise<InsertClientEmailReportWithOnConflictMutationResultEx>;
export type InsertClientEmailReportWithOnConflictLazyMutationReturn = [
  InsertClientEmailReportWithOnConflictWrappedLazyMutationFn,
  InsertClientEmailReportWithOnConflictMutationResultEx
];

// Function
function useInsertClientEmailReportWithOnConflict(
  options?: Omit<
    MutationHookOptions<
      InsertClientEmailReportWithOnConflictMutation,
      InsertClientEmailReportWithOnConflictMutationVariables
    >,
    'mutation' | 'variables'
  >
): InsertClientEmailReportWithOnConflictLazyMutationReturn {
  const lazyMutation: InsertClientEmailReportWithOnConflictLazyMutationFn = useMutation<
    InsertClientEmailReportWithOnConflictMutation,
    InsertClientEmailReportWithOnConflictMutationVariables
  >(InsertClientEmailReportWithOnConflictDocument, options);
  const pickClientEmailReport: PickInsertClientEmailReportFn = (
    mutation: InsertClientEmailReportWithOnConflictMutation | null | undefined
  ) => {
    return mutation?.insert_clientEmailReport?.returning && mutation.insert_clientEmailReport.returning[0];
  };

  const wrappedLazyMutation: InsertClientEmailReportWithOnConflictWrappedLazyMutationFn = async ({
    clientEmailReport,
    onConflict,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: clientEmailReport });
    const mutationOptions: MutationFunctionOptions<
      InsertClientEmailReportWithOnConflictMutation,
      InsertClientEmailReportWithOnConflictMutationVariables
    > = { variables: { objects: [objectForInsert], onConflict }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id)
        throw new Error(`if autoOptimisticResponse = true, id must be set in object 'clientEmailReport'`);
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertClientEmailReportWithOnConflictMutation>(
        {
          operationType: 'insert',
          entityName: 'clientEmailReport',
          objects: [objectForInsert as ClientEmailReport_Insert_Input & ObjectWithId],
        }
      );
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, clientEmailReport: pickClientEmailReport(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], clientEmailReport: pickClientEmailReport(lazyMutation[1].data) }];
}

// Types
type InsertClientEmailReportObjectsMutationResult = FetchResult<
  InsertClientEmailReportMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertClientEmailReportObjectsMutationResultEx = InsertClientEmailReportMutationResult &
  ClientEmailReportObjectsHookResultEx;

type PickInsertClientEmailReportObjectsFn = (
  mutation: InsertClientEmailReportMutation | null | undefined
) => ClientEmailReportFragment[];
type InsertClientEmailReportObjectsLazyMutationFn = MutationTuple<
  InsertClientEmailReportMutation,
  InsertClientEmailReportMutationVariables
>;
type InsertClientEmailReportObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<InsertClientEmailReportMutation, InsertClientEmailReportMutationVariables>
) => Promise<InsertClientEmailReportObjectsMutationResultEx>;
export type InsertClientEmailReportObjectsLazyMutationReturn = [
  InsertClientEmailReportObjectsWrappedLazyMutationFn,
  InsertClientEmailReportObjectsMutationResultEx
];

// Function
function useInsertClientEmailReportObjects(
  options?: Omit<
    MutationHookOptions<InsertClientEmailReportMutation, InsertClientEmailReportMutationVariables>,
    'mutation'
  >
): InsertClientEmailReportObjectsLazyMutationReturn {
  const lazyMutation: InsertClientEmailReportObjectsLazyMutationFn = useMutation<
    InsertClientEmailReportMutation,
    InsertClientEmailReportMutationVariables
  >(InsertClientEmailReportDocument, options);
  const pickObjects: PickInsertClientEmailReportObjectsFn = (
    mutation: InsertClientEmailReportMutation | null | undefined
  ) => {
    return mutation?.insert_clientEmailReport?.returning || [];
  };

  const wrappedLazyMutation: InsertClientEmailReportObjectsWrappedLazyMutationFn = async (options) => {
    if (options && options.variables && options.variables.objects)
      options.variables.objects = options.variables.objects.map((objectItem) =>
        stripInsertInputClientFields({ input: objectItem })
      );
    const fetchResult: InsertClientEmailReportObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Update Hooks
 */

type UpdateClientEmailReportByIdMutationResult = FetchResult<
  UpdateClientEmailReportByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateClientEmailReportByIdMutationResultEx = UpdateClientEmailReportByIdMutationResult &
  ClientEmailReportByIdHookResultEx;

type PickUpdateClientEmailReportByIdFn = (
  mutation: UpdateClientEmailReportByIdMutation | null | undefined
) => ClientEmailReportFragment | null | undefined;
type UpdateClientEmailReportByIdLazyMutationFn = MutationTuple<
  UpdateClientEmailReportByIdMutation,
  UpdateClientEmailReportByIdMutationVariables
>;
type UpdateClientEmailReportByIdWrappedLazyMutationFn = ({
  clientEmailReportId,
  set,
  autoOptimisticResponse,
  options,
}: {
  clientEmailReportId: string;
  set: ClientEmailReport_Set_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<UpdateClientEmailReportByIdMutation, UpdateClientEmailReportByIdMutationVariables>,
    'variables'
  >;
}) => Promise<UpdateClientEmailReportByIdMutationResultEx>;
export type UpdateClientEmailReportByIdLazyMutationReturn = [
  UpdateClientEmailReportByIdWrappedLazyMutationFn,
  UpdateClientEmailReportByIdMutationResultEx
];

function useUpdateClientEmailReportById(
  options?: Omit<
    MutationHookOptions<UpdateClientEmailReportByIdMutation, UpdateClientEmailReportByIdMutationVariables>,
    'mutation' | 'variables'
  >
): UpdateClientEmailReportByIdLazyMutationReturn {
  const lazyMutation: UpdateClientEmailReportByIdLazyMutationFn = useMutation<
    UpdateClientEmailReportByIdMutation,
    UpdateClientEmailReportByIdMutationVariables
  >(UpdateClientEmailReportByIdDocument, options);

  const pickClientEmailReport: PickUpdateClientEmailReportByIdFn = (mutation) => {
    return mutation?.update_clientEmailReport?.returning && mutation.update_clientEmailReport!.returning[0];
  };

  const wrappedLazyMutation: UpdateClientEmailReportByIdWrappedLazyMutationFn = async ({
    clientEmailReportId,
    set,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<
      UpdateClientEmailReportByIdMutation,
      UpdateClientEmailReportByIdMutationVariables
    > = { variables: { id: clientEmailReportId, set }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateClientEmailReportByIdMutation>({
        operationType: 'update',
        entityName: 'clientEmailReport',
        objects: [{ id: clientEmailReportId, ...set }],
      });
    }

    const fetchResult: UpdateClientEmailReportByIdMutationResult = await lazyMutation[0]({
      variables: { id: clientEmailReportId, set },
      ...mutationOptions,
    });
    return { ...fetchResult, clientEmailReport: pickClientEmailReport(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], clientEmailReport: pickClientEmailReport(lazyMutation[1].data) }];
}

//
//

// Types
type UpdateClientEmailReportObjectsMutationResult = FetchResult<
  UpdateClientEmailReportMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateClientEmailReportObjectsMutationResultEx = UpdateClientEmailReportObjectsMutationResult &
  ClientEmailReportObjectsHookResultEx;

// Function
type PickUpdateClientEmailReportObjectsFn = (
  mutation: UpdateClientEmailReportMutation | null | undefined
) => ClientEmailReportFragment[];
type UpdateClientEmailReportObjectsLazyMutationFn = MutationTuple<
  UpdateClientEmailReportMutation,
  UpdateClientEmailReportMutationVariables
>;
type UpdateClientEmailReportObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<UpdateClientEmailReportMutation, UpdateClientEmailReportMutationVariables>
) => Promise<UpdateClientEmailReportObjectsMutationResultEx>;
export type UpdateClientEmailReportObjectsLazyMutationReturn = [
  UpdateClientEmailReportObjectsWrappedLazyMutationFn,
  UpdateClientEmailReportObjectsMutationResultEx
];

function useUpdateClientEmailReportObjects(
  options?: Omit<
    MutationHookOptions<UpdateClientEmailReportMutation, UpdateClientEmailReportMutationVariables>,
    'mutation'
  >
): UpdateClientEmailReportObjectsLazyMutationReturn {
  const lazyMutation: UpdateClientEmailReportObjectsLazyMutationFn = useMutation<
    UpdateClientEmailReportMutation,
    UpdateClientEmailReportMutationVariables
  >(UpdateClientEmailReportDocument, options);

  const pickObjects: PickUpdateClientEmailReportObjectsFn = (
    mutation: UpdateClientEmailReportMutation | null | undefined
  ) => {
    return mutation?.update_clientEmailReport?.returning || [];
  };

  const wrappedLazyMutation: UpdateClientEmailReportObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: UpdateClientEmailReportObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Delete Hooks
 */

// Types
type RemoveClientEmailReportModelByIdFetchResult = FetchResult<
  RemoveClientEmailReportModelByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveClientEmailReportModelByIdMutationResultEx = RemoveClientEmailReportModelByIdFetchResult &
  RemoveEntitiesQueryHookResultEx;

// Function
type PickRemoveClientEmailReportModelFn = (
  mutation: RemoveClientEmailReportModelByIdMutation | null | undefined
) => number;
type RemoveClientEmailReportModelLazyMutationFn = MutationTuple<
  RemoveClientEmailReportModelByIdMutation,
  RemoveClientEmailReportModelByIdMutationVariables
>;
type RemoveClientEmailReportModelWrappedLazyMutationFn = ({
  clientEmailReportId,
  autoOptimisticResponse,
  options,
}: {
  clientEmailReportId: string;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<
      RemoveClientEmailReportModelByIdMutation,
      RemoveClientEmailReportModelByIdMutationVariables
    >,
    'variables'
  >;
}) => Promise<RemoveClientEmailReportModelByIdMutationResultEx>;
export type RemoveClientEmailReportModelLazyMutationReturn = [
  RemoveClientEmailReportModelWrappedLazyMutationFn,
  RemoveClientEmailReportModelByIdMutationResultEx
];

function useRemoveClientEmailReportModelById(
  options?: Omit<
    MutationHookOptions<RemoveClientEmailReportModelByIdMutation, RemoveClientEmailReportModelByIdMutationVariables>,
    'mutation' | 'variables'
  >
) {
  const lazyMutation: RemoveClientEmailReportModelLazyMutationFn = useMutation<
    RemoveClientEmailReportModelByIdMutation,
    RemoveClientEmailReportModelByIdMutationVariables
  >(RemoveClientEmailReportModelByIdDocument, options);

  const pickAffectedRows: PickRemoveClientEmailReportModelFn = (
    mutation: RemoveClientEmailReportModelByIdMutation | null | undefined
  ) => {
    return mutation?.delete_clientEmailReport?.affected_rows || 0;
  };

  const wrappedLazyMutation: RemoveClientEmailReportModelWrappedLazyMutationFn = async ({
    clientEmailReportId,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<
      RemoveClientEmailReportModelMutation,
      RemoveClientEmailReportModelByIdMutationVariables
    > = { variables: { id: clientEmailReportId }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<RemoveClientEmailReportModelMutation>({
        operationType: 'delete',
        entityName: 'clientEmailReport',
        objects: [{ id: clientEmailReportId }],
      });
    }
    if (!options || !options.update) {
      mutationOptions.update = generateUpdateFunctionForMutation<RemoveClientEmailReportModelByIdMutation>({
        operationType: 'delete',
        entityName: 'clientEmailReport',
        entityId: clientEmailReportId,
      });
    }

    const fetchResult: RemoveClientEmailReportModelByIdFetchResult = await lazyMutation[0](mutationOptions);
    return { ...fetchResult, affected_rows: pickAffectedRows(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], affected_rows: pickAffectedRows(lazyMutation[1].data) }] as [
    typeof wrappedLazyMutation,
    typeof lazyMutation[1] & { affected_rows: ReturnType<typeof pickAffectedRows> }
  ];
}

//
//

// Types
type RemoveClientEmailReportModelObjectsMutationResult = FetchResult<
  RemoveClientEmailReportModelMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveClientEmailReportModelObjectsMutationResultEx = RemoveClientEmailReportModelObjectsMutationResult &
  RemoveEntitiesQueryHookResultEx;

// Function
type PickRemoveClientEmailReportModelObjectsFn = (
  mutation: RemoveClientEmailReportModelMutation | null | undefined
) => number;
type RemoveClientEmailReportModelObjectsLazyMutationFn = MutationTuple<
  RemoveClientEmailReportModelMutation,
  RemoveClientEmailReportModelMutationVariables
>;
type RemoveClientEmailReportModelObjectsWrappedLazyMutationFn = (
  options: MutationFunctionOptions<RemoveClientEmailReportModelMutation, RemoveClientEmailReportModelMutationVariables>
) => Promise<RemoveClientEmailReportModelObjectsMutationResultEx>;
export type RemoveClientEmailReportModelObjectsLazyMutationReturn = [
  RemoveClientEmailReportModelObjectsWrappedLazyMutationFn,
  RemoveClientEmailReportModelObjectsMutationResultEx
];

function useRemoveClientEmailReportModelObjects(
  options?: Omit<
    MutationHookOptions<RemoveClientEmailReportModelMutation, RemoveClientEmailReportModelMutationVariables>,
    'mutation'
  >
): RemoveClientEmailReportModelObjectsLazyMutationReturn {
  const lazyMutation = useMutation<RemoveClientEmailReportModelMutation, RemoveClientEmailReportModelMutationVariables>(
    RemoveClientEmailReportModelDocument,
    options
  );

  const pickAffectedRows: PickRemoveClientEmailReportModelObjectsFn = (
    mutation: RemoveClientEmailReportModelMutation | null | undefined
  ) => {
    return mutation?.delete_clientEmailReport?.affected_rows || 0;
  };

  const wrappedLazyMutation: RemoveClientEmailReportModelObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: RemoveClientEmailReportModelObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, affected_rows: pickAffectedRows(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], affected_rows: pickAffectedRows(lazyMutation[1].data) }];
}

// ClientEmailReport Fragment Helper Object
//------------------------------------------------

export const ClientEmailReportFragmentGQLHooks = {
  useQueryById: useQueryClientEmailReportById,
  useQueryByIdLazy: useQueryClientEmailReportByIdLazy,
  useQueryObjects: useQueryClientEmailReportObjects,
  useQueryObjectsLazy: useQueryClientEmailReportObjectsLazy,
  useSubscribeToById: useSubscribeToClientEmailReportById,
  useSubscribeToObjects: useSubscribeToClientEmailReportObjects,
  useInsert: useInsertClientEmailReport,
  useInsertWithOnConflict: useInsertClientEmailReportWithOnConflict,
  useInsertObjects: useInsertClientEmailReportObjects,
  useUpdateById: useUpdateClientEmailReportById,
  useUpdateObjects: useUpdateClientEmailReportObjects,
};

// clientEmailReport MODEL HOOKS OBJECT
//------------------------------------------------

export const ClientEmailReportModelGQLHooks = {
  useRemoveById: useRemoveClientEmailReportModelById,
  useRemoveObjects: useRemoveClientEmailReportModelObjects,
};

// emailHistory REACT
//------------------------------------------------

export type EmailHistoryByIdHookResultEx = { emailHistory: EmailHistoryFragment | null | undefined };
export type EmailHistoryObjectsHookResultEx = { objects: EmailHistoryFragment[] };

/**
 *  Query Hook
 */

// Types
type QueryEmailHistoryByIdResult = LazyQueryResult<QueryEmailHistoryByIdQuery, QueryEmailHistoryByIdQueryVariables>;
type QueryEmailHistoryByIdSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<
          QueryEmailHistoryByIdQuery,
          QueryEmailHistoryByIdQueryVariables,
          QueryEmailHistoryByIdQuery
        >,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryEmailHistoryByIdResultEx = Omit<QueryEmailHistoryByIdResult, 'subscribeToMore'> & {
  subscribeToMore: QueryEmailHistoryByIdSubScribeToMore;
} & EmailHistoryByIdHookResultEx;

// Function
function useQueryEmailHistoryById({
  emailHistoryId,
  options,
}: {
  emailHistoryId: string;
  options?: Omit<
    QueryHookOptions<QueryEmailHistoryByIdQuery, QueryEmailHistoryByIdQueryVariables>,
    'query' | 'variables'
  >;
}): QueryEmailHistoryByIdResultEx {
  const _query: QueryEmailHistoryByIdResult = useQuery<QueryEmailHistoryByIdQuery, QueryEmailHistoryByIdQueryVariables>(
    QueryEmailHistoryByIdDocument,
    { variables: { emailHistoryId }, ...options }
  );

  const typedSubscribeToMore: QueryEmailHistoryByIdSubScribeToMore = (options) => {
    _query.subscribeToMore({
      document: QueryEmailHistoryByIdDocument,
      variables: { emailHistoryId } as QueryEmailHistoryByIdQueryVariables,
      ...options,
    });
  };
  const { subscribeToMore, ...query } = _query;
  return { ...query, subscribeToMore: typedSubscribeToMore, emailHistory: query?.data?.emailHistory_by_pk };
}

/**
 *  Lazy Query Hook
 */

// Types
type PickQueryEmailHistoryByIdFn = (
  query: QueryEmailHistoryByIdQuery | null | undefined
) => EmailHistoryFragment | null | undefined;
type QueryEmailHistoryByIdLazyFn = [
  (options?: QueryLazyOptions<QueryEmailHistoryByIdQueryVariables> | undefined) => void,
  QueryEmailHistoryByIdResult
];
type QueryEmailHistoryByIdWrappedLazyFn = (
  options: Omit<QueryLazyOptions<QueryEmailHistoryByIdQueryVariables>, 'variables'>
) => void;
export type QueryEmailHistoryByIdLazyReturn = [QueryEmailHistoryByIdWrappedLazyFn, QueryEmailHistoryByIdResultEx];

// Function
function useQueryEmailHistoryByIdLazy({
  emailHistoryId,
  options,
}: {
  emailHistoryId: string;
  options?: Omit<
    QueryHookOptions<QueryEmailHistoryByIdQuery, QueryEmailHistoryByIdQueryVariables>,
    'query' | 'variables'
  >;
}): QueryEmailHistoryByIdLazyReturn {
  const lazyQuery: QueryEmailHistoryByIdLazyFn = useLazyQuery<
    QueryEmailHistoryByIdQuery,
    QueryEmailHistoryByIdQueryVariables
  >(QueryEmailHistoryByIdDocument, options);

  // Setting up typed version of lazyQuery
  const pickQueryEmailHistoryById: PickQueryEmailHistoryByIdFn = (query) => {
    return query && query.emailHistory_by_pk;
  };
  const wrappedLazyQuery: QueryEmailHistoryByIdWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryEmailHistoryByIdSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({
        document: QueryEmailHistoryByIdDocument,
        variables: { emailHistoryId } as QueryEmailHistoryByIdQueryVariables,
        ...options,
      });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    {
      ...lazyQueryResult,
      subscribeToMore: typedSubcribeToMore,
      emailHistory: pickQueryEmailHistoryById(lazyQuery[1].data),
    },
  ];
}

/**
 *  Query Collection Hook
 */

// Types
export type QueryEmailHistoryObjectsResult = LazyQueryResult<
  QueryEmailHistoryObjectsQuery,
  QueryEmailHistoryObjectsQueryVariables
>;
type QueryEmailHistoryObjectsSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<
          QueryEmailHistoryObjectsQuery,
          QueryEmailHistoryObjectsQueryVariables,
          QueryEmailHistoryObjectsQuery
        >,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryEmailHistoryObjectsResultEx = Omit<QueryEmailHistoryObjectsResult, 'subscribeToMore'> & {
  subscribeToMore: QueryEmailHistoryObjectsSubScribeToMore;
} & EmailHistoryObjectsHookResultEx;

// Function
function useQueryEmailHistoryObjects(
  options: Omit<QueryHookOptions<QueryEmailHistoryObjectsQuery, QueryEmailHistoryObjectsQueryVariables>, 'query'>
): QueryEmailHistoryObjectsResultEx {
  const _query: QueryEmailHistoryObjectsResult = useQuery<
    QueryEmailHistoryObjectsQuery,
    QueryEmailHistoryObjectsQueryVariables
  >(QueryEmailHistoryObjectsDocument, options);

  const typedSubscribeToMore: QueryEmailHistoryObjectsSubScribeToMore = (options) => {
    _query.subscribeToMore({ document: QueryEmailHistoryObjectsDocument, ...options });
  };
  const { subscribeToMore, ...query } = _query;

  return { ...query, subscribeToMore: typedSubscribeToMore, objects: query?.data?.emailHistory || [] };
}

/**
 *  Lazy Query Collection Hook
 */

// Types
type PickQueryEmailHistoryObjectsFn = (
  query: QueryEmailHistoryObjectsQuery | null | undefined
) => EmailHistoryFragment[];
type QueryEmailHistoryObjectsLazyFn = [
  (options?: QueryLazyOptions<QueryEmailHistoryObjectsQueryVariables> | undefined) => void,
  QueryEmailHistoryObjectsResult
];
type QueryEmailHistoryObjectsWrappedLazyFn = (
  options?: QueryLazyOptions<QueryEmailHistoryObjectsQueryVariables>
) => void;
export type QueryEmailHistoryObjectsLazyReturn = [
  QueryEmailHistoryObjectsWrappedLazyFn,
  QueryEmailHistoryObjectsResultEx
];

// Function
function useQueryEmailHistoryObjectsLazy(
  options?: Omit<LazyQueryHookOptions<QueryEmailHistoryObjectsQuery, QueryEmailHistoryObjectsQueryVariables>, 'query'>
): QueryEmailHistoryObjectsLazyReturn {
  const lazyQuery: QueryEmailHistoryObjectsLazyFn = useLazyQuery<
    QueryEmailHistoryObjectsQuery,
    QueryEmailHistoryObjectsQueryVariables
  >(QueryEmailHistoryObjectsDocument, options);

  // Setting up typed version of lazyQuery
  const pickObjects: PickQueryEmailHistoryObjectsFn = (query: QueryEmailHistoryObjectsQuery | null | undefined) => {
    return query?.emailHistory || [];
  };
  const wrappedLazyQuery: QueryEmailHistoryObjectsWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryEmailHistoryObjectsSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({ document: QueryEmailHistoryObjectsDocument, ...options });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    { ...lazyQueryResult, subscribeToMore: typedSubcribeToMore, objects: pickObjects(lazyQuery[1].data) },
  ];
}

/**
 *  Subscription Hook
 */

// Types
type SubscribeToEmailHistoryByIdResult = {
  variables?: SubscribeToEmailHistoryByIdSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToEmailHistoryByIdSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToEmailHistoryByIdResultEx = SubscribeToEmailHistoryByIdResult & EmailHistoryByIdHookResultEx;

// Function
function useSubscribeToEmailHistoryById({
  emailHistoryId,
  options,
}: {
  emailHistoryId: string;
  options?: Omit<
    SubscriptionHookOptions<SubscribeToEmailHistoryByIdSubscription, SubscribeToEmailHistoryByIdSubscriptionVariables>,
    'query' | 'variables'
  >;
}): SubscribeToEmailHistoryByIdResultEx {
  const subscription: SubscribeToEmailHistoryByIdResult = useSubscription<
    SubscribeToEmailHistoryByIdSubscription,
    SubscribeToEmailHistoryByIdSubscriptionVariables
  >(SubscribeToEmailHistoryByIdDocument, { variables: { emailHistoryId }, ...options });
  return { ...subscription, emailHistory: subscription?.data?.emailHistory_by_pk };
}

/**
 *  Subscription Collection Hook
 */

// Types
export type SubscribeToEmailHistoryObjectsResult = {
  variables?: SubscribeToEmailHistoryObjectsSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToEmailHistoryObjectsSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToEmailHistoryObjectsResultEx = SubscribeToEmailHistoryObjectsResult &
  EmailHistoryObjectsHookResultEx;

// Function
function useSubscribeToEmailHistoryObjects(
  options: Omit<
    SubscriptionHookOptions<
      SubscribeToEmailHistoryObjectsSubscription,
      SubscribeToEmailHistoryObjectsSubscriptionVariables
    >,
    'query'
  >
): SubscribeToEmailHistoryObjectsResultEx {
  const subscription: SubscribeToEmailHistoryObjectsResult = useSubscription<
    SubscribeToEmailHistoryObjectsSubscription,
    SubscribeToEmailHistoryObjectsSubscriptionVariables
  >(SubscribeToEmailHistoryObjectsDocument, options);
  return { ...subscription, objects: subscription?.data?.emailHistory || [] };
}

/**
 *  Insert Hooks
 */

// Types
type InsertEmailHistoryMutationResult = FetchResult<
  InsertEmailHistoryMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertEmailHistoryMutationResultEx = InsertEmailHistoryMutationResult & EmailHistoryByIdHookResultEx;

type PickInsertEmailHistoryFn = (
  mutation: InsertEmailHistoryMutation | null | undefined
) => EmailHistoryFragment | null | undefined;
type InsertEmailHistoryLazyMutationFn = MutationTuple<InsertEmailHistoryMutation, InsertEmailHistoryMutationVariables>;
type InsertEmailHistoryWrappedLazyMutationFn = ({
  emailHistory,
  autoOptimisticResponse,
  options,
}: {
  emailHistory: EmailHistory_Insert_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<MutationFunctionOptions<InsertEmailHistoryMutation, InsertEmailHistoryMutationVariables>, 'variables'>;
}) => Promise<InsertEmailHistoryMutationResultEx>;
export type InsertEmailHistoryLazyMutationReturn = [
  InsertEmailHistoryWrappedLazyMutationFn,
  InsertEmailHistoryMutationResultEx
];

// Function
function useInsertEmailHistory(
  options?: Omit<
    MutationHookOptions<InsertEmailHistoryMutation, InsertEmailHistoryMutationVariables>,
    'mutation' | 'variables'
  >
): InsertEmailHistoryLazyMutationReturn {
  const lazyMutation: InsertEmailHistoryLazyMutationFn = useMutation<
    InsertEmailHistoryMutation,
    InsertEmailHistoryMutationVariables
  >(InsertEmailHistoryDocument, options);
  const pickEmailHistory: PickInsertEmailHistoryFn = (mutation) => {
    return mutation?.insert_emailHistory?.returning && mutation?.insert_emailHistory?.returning[0];
  };

  const wrappedLazyMutation: InsertEmailHistoryWrappedLazyMutationFn = async ({
    emailHistory,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: emailHistory });
    const mutationOptions: MutationFunctionOptions<InsertEmailHistoryMutation, InsertEmailHistoryMutationVariables> = {
      variables: { objects: [objectForInsert] },
      ...options,
    };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id)
        throw new Error(`if autoOptimisticResponse = true, id must be set in object 'emailHistory'`);
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertEmailHistoryMutation>({
        operationType: 'insert',
        entityName: 'emailHistory',
        objects: [objectForInsert as EmailHistory_Insert_Input & ObjectWithId],
      });
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, emailHistory: pickEmailHistory(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], emailHistory: pickEmailHistory(lazyMutation[1].data) }];
}

//
//

// Types
type InsertEmailHistoryWithOnConflictMutationResult = FetchResult<
  InsertEmailHistoryWithOnConflictMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertEmailHistoryWithOnConflictMutationResultEx = InsertEmailHistoryWithOnConflictMutationResult &
  EmailHistoryByIdHookResultEx;

type InsertEmailHistoryWithOnConflictLazyMutationFn = MutationTuple<
  InsertEmailHistoryWithOnConflictMutation,
  InsertEmailHistoryWithOnConflictMutationVariables
>;
type InsertEmailHistoryWithOnConflictWrappedLazyMutationFn = ({
  emailHistory,
  onConflict,
  autoOptimisticResponse,
  options,
}: {
  emailHistory: EmailHistory_Insert_Input;
  onConflict: EmailHistory_On_Conflict;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<
      InsertEmailHistoryWithOnConflictMutation,
      InsertEmailHistoryWithOnConflictMutationVariables
    >,
    'variables'
  >;
}) => Promise<InsertEmailHistoryWithOnConflictMutationResultEx>;
export type InsertEmailHistoryWithOnConflictLazyMutationReturn = [
  InsertEmailHistoryWithOnConflictWrappedLazyMutationFn,
  InsertEmailHistoryWithOnConflictMutationResultEx
];

// Function
function useInsertEmailHistoryWithOnConflict(
  options?: Omit<
    MutationHookOptions<InsertEmailHistoryWithOnConflictMutation, InsertEmailHistoryWithOnConflictMutationVariables>,
    'mutation' | 'variables'
  >
): InsertEmailHistoryWithOnConflictLazyMutationReturn {
  const lazyMutation: InsertEmailHistoryWithOnConflictLazyMutationFn = useMutation<
    InsertEmailHistoryWithOnConflictMutation,
    InsertEmailHistoryWithOnConflictMutationVariables
  >(InsertEmailHistoryWithOnConflictDocument, options);
  const pickEmailHistory: PickInsertEmailHistoryFn = (
    mutation: InsertEmailHistoryWithOnConflictMutation | null | undefined
  ) => {
    return mutation?.insert_emailHistory?.returning && mutation.insert_emailHistory.returning[0];
  };

  const wrappedLazyMutation: InsertEmailHistoryWithOnConflictWrappedLazyMutationFn = async ({
    emailHistory,
    onConflict,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: emailHistory });
    const mutationOptions: MutationFunctionOptions<
      InsertEmailHistoryWithOnConflictMutation,
      InsertEmailHistoryWithOnConflictMutationVariables
    > = { variables: { objects: [objectForInsert], onConflict }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id)
        throw new Error(`if autoOptimisticResponse = true, id must be set in object 'emailHistory'`);
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertEmailHistoryWithOnConflictMutation>(
        {
          operationType: 'insert',
          entityName: 'emailHistory',
          objects: [objectForInsert as EmailHistory_Insert_Input & ObjectWithId],
        }
      );
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, emailHistory: pickEmailHistory(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], emailHistory: pickEmailHistory(lazyMutation[1].data) }];
}

// Types
type InsertEmailHistoryObjectsMutationResult = FetchResult<
  InsertEmailHistoryMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertEmailHistoryObjectsMutationResultEx = InsertEmailHistoryMutationResult &
  EmailHistoryObjectsHookResultEx;

type PickInsertEmailHistoryObjectsFn = (
  mutation: InsertEmailHistoryMutation | null | undefined
) => EmailHistoryFragment[];
type InsertEmailHistoryObjectsLazyMutationFn = MutationTuple<
  InsertEmailHistoryMutation,
  InsertEmailHistoryMutationVariables
>;
type InsertEmailHistoryObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<InsertEmailHistoryMutation, InsertEmailHistoryMutationVariables>
) => Promise<InsertEmailHistoryObjectsMutationResultEx>;
export type InsertEmailHistoryObjectsLazyMutationReturn = [
  InsertEmailHistoryObjectsWrappedLazyMutationFn,
  InsertEmailHistoryObjectsMutationResultEx
];

// Function
function useInsertEmailHistoryObjects(
  options?: Omit<MutationHookOptions<InsertEmailHistoryMutation, InsertEmailHistoryMutationVariables>, 'mutation'>
): InsertEmailHistoryObjectsLazyMutationReturn {
  const lazyMutation: InsertEmailHistoryObjectsLazyMutationFn = useMutation<
    InsertEmailHistoryMutation,
    InsertEmailHistoryMutationVariables
  >(InsertEmailHistoryDocument, options);
  const pickObjects: PickInsertEmailHistoryObjectsFn = (mutation: InsertEmailHistoryMutation | null | undefined) => {
    return mutation?.insert_emailHistory?.returning || [];
  };

  const wrappedLazyMutation: InsertEmailHistoryObjectsWrappedLazyMutationFn = async (options) => {
    if (options && options.variables && options.variables.objects)
      options.variables.objects = options.variables.objects.map((objectItem) =>
        stripInsertInputClientFields({ input: objectItem })
      );
    const fetchResult: InsertEmailHistoryObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Update Hooks
 */

type UpdateEmailHistoryByIdMutationResult = FetchResult<
  UpdateEmailHistoryByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateEmailHistoryByIdMutationResultEx = UpdateEmailHistoryByIdMutationResult &
  EmailHistoryByIdHookResultEx;

type PickUpdateEmailHistoryByIdFn = (
  mutation: UpdateEmailHistoryByIdMutation | null | undefined
) => EmailHistoryFragment | null | undefined;
type UpdateEmailHistoryByIdLazyMutationFn = MutationTuple<
  UpdateEmailHistoryByIdMutation,
  UpdateEmailHistoryByIdMutationVariables
>;
type UpdateEmailHistoryByIdWrappedLazyMutationFn = ({
  emailHistoryId,
  set,
  autoOptimisticResponse,
  options,
}: {
  emailHistoryId: string;
  set: EmailHistory_Set_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<UpdateEmailHistoryByIdMutation, UpdateEmailHistoryByIdMutationVariables>,
    'variables'
  >;
}) => Promise<UpdateEmailHistoryByIdMutationResultEx>;
export type UpdateEmailHistoryByIdLazyMutationReturn = [
  UpdateEmailHistoryByIdWrappedLazyMutationFn,
  UpdateEmailHistoryByIdMutationResultEx
];

function useUpdateEmailHistoryById(
  options?: Omit<
    MutationHookOptions<UpdateEmailHistoryByIdMutation, UpdateEmailHistoryByIdMutationVariables>,
    'mutation' | 'variables'
  >
): UpdateEmailHistoryByIdLazyMutationReturn {
  const lazyMutation: UpdateEmailHistoryByIdLazyMutationFn = useMutation<
    UpdateEmailHistoryByIdMutation,
    UpdateEmailHistoryByIdMutationVariables
  >(UpdateEmailHistoryByIdDocument, options);

  const pickEmailHistory: PickUpdateEmailHistoryByIdFn = (mutation) => {
    return mutation?.update_emailHistory?.returning && mutation.update_emailHistory!.returning[0];
  };

  const wrappedLazyMutation: UpdateEmailHistoryByIdWrappedLazyMutationFn = async ({
    emailHistoryId,
    set,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<
      UpdateEmailHistoryByIdMutation,
      UpdateEmailHistoryByIdMutationVariables
    > = { variables: { id: emailHistoryId, set }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateEmailHistoryByIdMutation>({
        operationType: 'update',
        entityName: 'emailHistory',
        objects: [{ id: emailHistoryId, ...set }],
      });
    }

    const fetchResult: UpdateEmailHistoryByIdMutationResult = await lazyMutation[0]({
      variables: { id: emailHistoryId, set },
      ...mutationOptions,
    });
    return { ...fetchResult, emailHistory: pickEmailHistory(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], emailHistory: pickEmailHistory(lazyMutation[1].data) }];
}

//
//

// Types
type UpdateEmailHistoryObjectsMutationResult = FetchResult<
  UpdateEmailHistoryMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateEmailHistoryObjectsMutationResultEx = UpdateEmailHistoryObjectsMutationResult &
  EmailHistoryObjectsHookResultEx;

// Function
type PickUpdateEmailHistoryObjectsFn = (
  mutation: UpdateEmailHistoryMutation | null | undefined
) => EmailHistoryFragment[];
type UpdateEmailHistoryObjectsLazyMutationFn = MutationTuple<
  UpdateEmailHistoryMutation,
  UpdateEmailHistoryMutationVariables
>;
type UpdateEmailHistoryObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<UpdateEmailHistoryMutation, UpdateEmailHistoryMutationVariables>
) => Promise<UpdateEmailHistoryObjectsMutationResultEx>;
export type UpdateEmailHistoryObjectsLazyMutationReturn = [
  UpdateEmailHistoryObjectsWrappedLazyMutationFn,
  UpdateEmailHistoryObjectsMutationResultEx
];

function useUpdateEmailHistoryObjects(
  options?: Omit<MutationHookOptions<UpdateEmailHistoryMutation, UpdateEmailHistoryMutationVariables>, 'mutation'>
): UpdateEmailHistoryObjectsLazyMutationReturn {
  const lazyMutation: UpdateEmailHistoryObjectsLazyMutationFn = useMutation<
    UpdateEmailHistoryMutation,
    UpdateEmailHistoryMutationVariables
  >(UpdateEmailHistoryDocument, options);

  const pickObjects: PickUpdateEmailHistoryObjectsFn = (mutation: UpdateEmailHistoryMutation | null | undefined) => {
    return mutation?.update_emailHistory?.returning || [];
  };

  const wrappedLazyMutation: UpdateEmailHistoryObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: UpdateEmailHistoryObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Delete Hooks
 */

// Types
type RemoveEmailHistoryModelByIdFetchResult = FetchResult<
  RemoveEmailHistoryModelByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveEmailHistoryModelByIdMutationResultEx = RemoveEmailHistoryModelByIdFetchResult &
  RemoveEntitiesQueryHookResultEx;

// Function
type PickRemoveEmailHistoryModelFn = (mutation: RemoveEmailHistoryModelByIdMutation | null | undefined) => number;
type RemoveEmailHistoryModelLazyMutationFn = MutationTuple<
  RemoveEmailHistoryModelByIdMutation,
  RemoveEmailHistoryModelByIdMutationVariables
>;
type RemoveEmailHistoryModelWrappedLazyMutationFn = ({
  emailHistoryId,
  autoOptimisticResponse,
  options,
}: {
  emailHistoryId: string;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<RemoveEmailHistoryModelByIdMutation, RemoveEmailHistoryModelByIdMutationVariables>,
    'variables'
  >;
}) => Promise<RemoveEmailHistoryModelByIdMutationResultEx>;
export type RemoveEmailHistoryModelLazyMutationReturn = [
  RemoveEmailHistoryModelWrappedLazyMutationFn,
  RemoveEmailHistoryModelByIdMutationResultEx
];

function useRemoveEmailHistoryModelById(
  options?: Omit<
    MutationHookOptions<RemoveEmailHistoryModelByIdMutation, RemoveEmailHistoryModelByIdMutationVariables>,
    'mutation' | 'variables'
  >
) {
  const lazyMutation: RemoveEmailHistoryModelLazyMutationFn = useMutation<
    RemoveEmailHistoryModelByIdMutation,
    RemoveEmailHistoryModelByIdMutationVariables
  >(RemoveEmailHistoryModelByIdDocument, options);

  const pickAffectedRows: PickRemoveEmailHistoryModelFn = (
    mutation: RemoveEmailHistoryModelByIdMutation | null | undefined
  ) => {
    return mutation?.delete_emailHistory?.affected_rows || 0;
  };

  const wrappedLazyMutation: RemoveEmailHistoryModelWrappedLazyMutationFn = async ({
    emailHistoryId,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<
      RemoveEmailHistoryModelMutation,
      RemoveEmailHistoryModelByIdMutationVariables
    > = { variables: { id: emailHistoryId }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<RemoveEmailHistoryModelMutation>({
        operationType: 'delete',
        entityName: 'emailHistory',
        objects: [{ id: emailHistoryId }],
      });
    }
    if (!options || !options.update) {
      mutationOptions.update = generateUpdateFunctionForMutation<RemoveEmailHistoryModelByIdMutation>({
        operationType: 'delete',
        entityName: 'emailHistory',
        entityId: emailHistoryId,
      });
    }

    const fetchResult: RemoveEmailHistoryModelByIdFetchResult = await lazyMutation[0](mutationOptions);
    return { ...fetchResult, affected_rows: pickAffectedRows(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], affected_rows: pickAffectedRows(lazyMutation[1].data) }] as [
    typeof wrappedLazyMutation,
    typeof lazyMutation[1] & { affected_rows: ReturnType<typeof pickAffectedRows> }
  ];
}

//
//

// Types
type RemoveEmailHistoryModelObjectsMutationResult = FetchResult<
  RemoveEmailHistoryModelMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveEmailHistoryModelObjectsMutationResultEx = RemoveEmailHistoryModelObjectsMutationResult &
  RemoveEntitiesQueryHookResultEx;

// Function
type PickRemoveEmailHistoryModelObjectsFn = (mutation: RemoveEmailHistoryModelMutation | null | undefined) => number;
type RemoveEmailHistoryModelObjectsLazyMutationFn = MutationTuple<
  RemoveEmailHistoryModelMutation,
  RemoveEmailHistoryModelMutationVariables
>;
type RemoveEmailHistoryModelObjectsWrappedLazyMutationFn = (
  options: MutationFunctionOptions<RemoveEmailHistoryModelMutation, RemoveEmailHistoryModelMutationVariables>
) => Promise<RemoveEmailHistoryModelObjectsMutationResultEx>;
export type RemoveEmailHistoryModelObjectsLazyMutationReturn = [
  RemoveEmailHistoryModelObjectsWrappedLazyMutationFn,
  RemoveEmailHistoryModelObjectsMutationResultEx
];

function useRemoveEmailHistoryModelObjects(
  options?: Omit<
    MutationHookOptions<RemoveEmailHistoryModelMutation, RemoveEmailHistoryModelMutationVariables>,
    'mutation'
  >
): RemoveEmailHistoryModelObjectsLazyMutationReturn {
  const lazyMutation = useMutation<RemoveEmailHistoryModelMutation, RemoveEmailHistoryModelMutationVariables>(
    RemoveEmailHistoryModelDocument,
    options
  );

  const pickAffectedRows: PickRemoveEmailHistoryModelObjectsFn = (
    mutation: RemoveEmailHistoryModelMutation | null | undefined
  ) => {
    return mutation?.delete_emailHistory?.affected_rows || 0;
  };

  const wrappedLazyMutation: RemoveEmailHistoryModelObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: RemoveEmailHistoryModelObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, affected_rows: pickAffectedRows(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], affected_rows: pickAffectedRows(lazyMutation[1].data) }];
}

// EmailHistory Fragment Helper Object
//------------------------------------------------

export const EmailHistoryFragmentGQLHooks = {
  useQueryById: useQueryEmailHistoryById,
  useQueryByIdLazy: useQueryEmailHistoryByIdLazy,
  useQueryObjects: useQueryEmailHistoryObjects,
  useQueryObjectsLazy: useQueryEmailHistoryObjectsLazy,
  useSubscribeToById: useSubscribeToEmailHistoryById,
  useSubscribeToObjects: useSubscribeToEmailHistoryObjects,
  useInsert: useInsertEmailHistory,
  useInsertWithOnConflict: useInsertEmailHistoryWithOnConflict,
  useInsertObjects: useInsertEmailHistoryObjects,
  useUpdateById: useUpdateEmailHistoryById,
  useUpdateObjects: useUpdateEmailHistoryObjects,
};

// emailHistory MODEL HOOKS OBJECT
//------------------------------------------------

export const EmailHistoryModelGQLHooks = {
  useRemoveById: useRemoveEmailHistoryModelById,
  useRemoveObjects: useRemoveEmailHistoryModelObjects,
};

// sendgridEvent REACT
//------------------------------------------------

export type SendgridEventByIdHookResultEx = { sendgridEvent: SendgridEventFragment | null | undefined };
export type SendgridEventObjectsHookResultEx = { objects: SendgridEventFragment[] };

/**
 *  Query Hook
 */

// Types
type QuerySendgridEventByIdResult = LazyQueryResult<QuerySendgridEventByIdQuery, QuerySendgridEventByIdQueryVariables>;
type QuerySendgridEventByIdSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<
          QuerySendgridEventByIdQuery,
          QuerySendgridEventByIdQueryVariables,
          QuerySendgridEventByIdQuery
        >,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QuerySendgridEventByIdResultEx = Omit<QuerySendgridEventByIdResult, 'subscribeToMore'> & {
  subscribeToMore: QuerySendgridEventByIdSubScribeToMore;
} & SendgridEventByIdHookResultEx;

// Function
function useQuerySendgridEventById({
  sendgridEventId,
  options,
}: {
  sendgridEventId: string;
  options?: Omit<
    QueryHookOptions<QuerySendgridEventByIdQuery, QuerySendgridEventByIdQueryVariables>,
    'query' | 'variables'
  >;
}): QuerySendgridEventByIdResultEx {
  const _query: QuerySendgridEventByIdResult = useQuery<
    QuerySendgridEventByIdQuery,
    QuerySendgridEventByIdQueryVariables
  >(QuerySendgridEventByIdDocument, { variables: { sendgridEventId }, ...options });

  const typedSubscribeToMore: QuerySendgridEventByIdSubScribeToMore = (options) => {
    _query.subscribeToMore({
      document: QuerySendgridEventByIdDocument,
      variables: { sendgridEventId } as QuerySendgridEventByIdQueryVariables,
      ...options,
    });
  };
  const { subscribeToMore, ...query } = _query;
  return { ...query, subscribeToMore: typedSubscribeToMore, sendgridEvent: query?.data?.sendgridEvent_by_pk };
}

/**
 *  Lazy Query Hook
 */

// Types
type PickQuerySendgridEventByIdFn = (
  query: QuerySendgridEventByIdQuery | null | undefined
) => SendgridEventFragment | null | undefined;
type QuerySendgridEventByIdLazyFn = [
  (options?: QueryLazyOptions<QuerySendgridEventByIdQueryVariables> | undefined) => void,
  QuerySendgridEventByIdResult
];
type QuerySendgridEventByIdWrappedLazyFn = (
  options: Omit<QueryLazyOptions<QuerySendgridEventByIdQueryVariables>, 'variables'>
) => void;
export type QuerySendgridEventByIdLazyReturn = [QuerySendgridEventByIdWrappedLazyFn, QuerySendgridEventByIdResultEx];

// Function
function useQuerySendgridEventByIdLazy({
  sendgridEventId,
  options,
}: {
  sendgridEventId: string;
  options?: Omit<
    QueryHookOptions<QuerySendgridEventByIdQuery, QuerySendgridEventByIdQueryVariables>,
    'query' | 'variables'
  >;
}): QuerySendgridEventByIdLazyReturn {
  const lazyQuery: QuerySendgridEventByIdLazyFn = useLazyQuery<
    QuerySendgridEventByIdQuery,
    QuerySendgridEventByIdQueryVariables
  >(QuerySendgridEventByIdDocument, options);

  // Setting up typed version of lazyQuery
  const pickQuerySendgridEventById: PickQuerySendgridEventByIdFn = (query) => {
    return query && query.sendgridEvent_by_pk;
  };
  const wrappedLazyQuery: QuerySendgridEventByIdWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QuerySendgridEventByIdSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({
        document: QuerySendgridEventByIdDocument,
        variables: { sendgridEventId } as QuerySendgridEventByIdQueryVariables,
        ...options,
      });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    {
      ...lazyQueryResult,
      subscribeToMore: typedSubcribeToMore,
      sendgridEvent: pickQuerySendgridEventById(lazyQuery[1].data),
    },
  ];
}

/**
 *  Query Collection Hook
 */

// Types
export type QuerySendgridEventObjectsResult = LazyQueryResult<
  QuerySendgridEventObjectsQuery,
  QuerySendgridEventObjectsQueryVariables
>;
type QuerySendgridEventObjectsSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<
          QuerySendgridEventObjectsQuery,
          QuerySendgridEventObjectsQueryVariables,
          QuerySendgridEventObjectsQuery
        >,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QuerySendgridEventObjectsResultEx = Omit<QuerySendgridEventObjectsResult, 'subscribeToMore'> & {
  subscribeToMore: QuerySendgridEventObjectsSubScribeToMore;
} & SendgridEventObjectsHookResultEx;

// Function
function useQuerySendgridEventObjects(
  options: Omit<QueryHookOptions<QuerySendgridEventObjectsQuery, QuerySendgridEventObjectsQueryVariables>, 'query'>
): QuerySendgridEventObjectsResultEx {
  const _query: QuerySendgridEventObjectsResult = useQuery<
    QuerySendgridEventObjectsQuery,
    QuerySendgridEventObjectsQueryVariables
  >(QuerySendgridEventObjectsDocument, options);

  const typedSubscribeToMore: QuerySendgridEventObjectsSubScribeToMore = (options) => {
    _query.subscribeToMore({ document: QuerySendgridEventObjectsDocument, ...options });
  };
  const { subscribeToMore, ...query } = _query;

  return { ...query, subscribeToMore: typedSubscribeToMore, objects: query?.data?.sendgridEvent || [] };
}

/**
 *  Lazy Query Collection Hook
 */

// Types
type PickQuerySendgridEventObjectsFn = (
  query: QuerySendgridEventObjectsQuery | null | undefined
) => SendgridEventFragment[];
type QuerySendgridEventObjectsLazyFn = [
  (options?: QueryLazyOptions<QuerySendgridEventObjectsQueryVariables> | undefined) => void,
  QuerySendgridEventObjectsResult
];
type QuerySendgridEventObjectsWrappedLazyFn = (
  options?: QueryLazyOptions<QuerySendgridEventObjectsQueryVariables>
) => void;
export type QuerySendgridEventObjectsLazyReturn = [
  QuerySendgridEventObjectsWrappedLazyFn,
  QuerySendgridEventObjectsResultEx
];

// Function
function useQuerySendgridEventObjectsLazy(
  options?: Omit<LazyQueryHookOptions<QuerySendgridEventObjectsQuery, QuerySendgridEventObjectsQueryVariables>, 'query'>
): QuerySendgridEventObjectsLazyReturn {
  const lazyQuery: QuerySendgridEventObjectsLazyFn = useLazyQuery<
    QuerySendgridEventObjectsQuery,
    QuerySendgridEventObjectsQueryVariables
  >(QuerySendgridEventObjectsDocument, options);

  // Setting up typed version of lazyQuery
  const pickObjects: PickQuerySendgridEventObjectsFn = (query: QuerySendgridEventObjectsQuery | null | undefined) => {
    return query?.sendgridEvent || [];
  };
  const wrappedLazyQuery: QuerySendgridEventObjectsWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QuerySendgridEventObjectsSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({ document: QuerySendgridEventObjectsDocument, ...options });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    { ...lazyQueryResult, subscribeToMore: typedSubcribeToMore, objects: pickObjects(lazyQuery[1].data) },
  ];
}

/**
 *  Subscription Hook
 */

// Types
type SubscribeToSendgridEventByIdResult = {
  variables?: SubscribeToSendgridEventByIdSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToSendgridEventByIdSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToSendgridEventByIdResultEx = SubscribeToSendgridEventByIdResult & SendgridEventByIdHookResultEx;

// Function
function useSubscribeToSendgridEventById({
  sendgridEventId,
  options,
}: {
  sendgridEventId: string;
  options?: Omit<
    SubscriptionHookOptions<
      SubscribeToSendgridEventByIdSubscription,
      SubscribeToSendgridEventByIdSubscriptionVariables
    >,
    'query' | 'variables'
  >;
}): SubscribeToSendgridEventByIdResultEx {
  const subscription: SubscribeToSendgridEventByIdResult = useSubscription<
    SubscribeToSendgridEventByIdSubscription,
    SubscribeToSendgridEventByIdSubscriptionVariables
  >(SubscribeToSendgridEventByIdDocument, { variables: { sendgridEventId }, ...options });
  return { ...subscription, sendgridEvent: subscription?.data?.sendgridEvent_by_pk };
}

/**
 *  Subscription Collection Hook
 */

// Types
export type SubscribeToSendgridEventObjectsResult = {
  variables?: SubscribeToSendgridEventObjectsSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToSendgridEventObjectsSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToSendgridEventObjectsResultEx = SubscribeToSendgridEventObjectsResult &
  SendgridEventObjectsHookResultEx;

// Function
function useSubscribeToSendgridEventObjects(
  options: Omit<
    SubscriptionHookOptions<
      SubscribeToSendgridEventObjectsSubscription,
      SubscribeToSendgridEventObjectsSubscriptionVariables
    >,
    'query'
  >
): SubscribeToSendgridEventObjectsResultEx {
  const subscription: SubscribeToSendgridEventObjectsResult = useSubscription<
    SubscribeToSendgridEventObjectsSubscription,
    SubscribeToSendgridEventObjectsSubscriptionVariables
  >(SubscribeToSendgridEventObjectsDocument, options);
  return { ...subscription, objects: subscription?.data?.sendgridEvent || [] };
}

/**
 *  Insert Hooks
 */

// Types
type InsertSendgridEventMutationResult = FetchResult<
  InsertSendgridEventMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertSendgridEventMutationResultEx = InsertSendgridEventMutationResult & SendgridEventByIdHookResultEx;

type PickInsertSendgridEventFn = (
  mutation: InsertSendgridEventMutation | null | undefined
) => SendgridEventFragment | null | undefined;
type InsertSendgridEventLazyMutationFn = MutationTuple<
  InsertSendgridEventMutation,
  InsertSendgridEventMutationVariables
>;
type InsertSendgridEventWrappedLazyMutationFn = ({
  sendgridEvent,
  autoOptimisticResponse,
  options,
}: {
  sendgridEvent: SendgridEvent_Insert_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<InsertSendgridEventMutation, InsertSendgridEventMutationVariables>,
    'variables'
  >;
}) => Promise<InsertSendgridEventMutationResultEx>;
export type InsertSendgridEventLazyMutationReturn = [
  InsertSendgridEventWrappedLazyMutationFn,
  InsertSendgridEventMutationResultEx
];

// Function
function useInsertSendgridEvent(
  options?: Omit<
    MutationHookOptions<InsertSendgridEventMutation, InsertSendgridEventMutationVariables>,
    'mutation' | 'variables'
  >
): InsertSendgridEventLazyMutationReturn {
  const lazyMutation: InsertSendgridEventLazyMutationFn = useMutation<
    InsertSendgridEventMutation,
    InsertSendgridEventMutationVariables
  >(InsertSendgridEventDocument, options);
  const pickSendgridEvent: PickInsertSendgridEventFn = (mutation) => {
    return mutation?.insert_sendgridEvent?.returning && mutation?.insert_sendgridEvent?.returning[0];
  };

  const wrappedLazyMutation: InsertSendgridEventWrappedLazyMutationFn = async ({
    sendgridEvent,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: sendgridEvent });
    const mutationOptions: MutationFunctionOptions<
      InsertSendgridEventMutation,
      InsertSendgridEventMutationVariables
    > = { variables: { objects: [objectForInsert] }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id)
        throw new Error(`if autoOptimisticResponse = true, id must be set in object 'sendgridEvent'`);
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertSendgridEventMutation>({
        operationType: 'insert',
        entityName: 'sendgridEvent',
        objects: [objectForInsert as SendgridEvent_Insert_Input & ObjectWithId],
      });
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, sendgridEvent: pickSendgridEvent(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], sendgridEvent: pickSendgridEvent(lazyMutation[1].data) }];
}

//
//

// Types
type InsertSendgridEventWithOnConflictMutationResult = FetchResult<
  InsertSendgridEventWithOnConflictMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertSendgridEventWithOnConflictMutationResultEx = InsertSendgridEventWithOnConflictMutationResult &
  SendgridEventByIdHookResultEx;

type InsertSendgridEventWithOnConflictLazyMutationFn = MutationTuple<
  InsertSendgridEventWithOnConflictMutation,
  InsertSendgridEventWithOnConflictMutationVariables
>;
type InsertSendgridEventWithOnConflictWrappedLazyMutationFn = ({
  sendgridEvent,
  onConflict,
  autoOptimisticResponse,
  options,
}: {
  sendgridEvent: SendgridEvent_Insert_Input;
  onConflict: SendgridEvent_On_Conflict;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<
      InsertSendgridEventWithOnConflictMutation,
      InsertSendgridEventWithOnConflictMutationVariables
    >,
    'variables'
  >;
}) => Promise<InsertSendgridEventWithOnConflictMutationResultEx>;
export type InsertSendgridEventWithOnConflictLazyMutationReturn = [
  InsertSendgridEventWithOnConflictWrappedLazyMutationFn,
  InsertSendgridEventWithOnConflictMutationResultEx
];

// Function
function useInsertSendgridEventWithOnConflict(
  options?: Omit<
    MutationHookOptions<InsertSendgridEventWithOnConflictMutation, InsertSendgridEventWithOnConflictMutationVariables>,
    'mutation' | 'variables'
  >
): InsertSendgridEventWithOnConflictLazyMutationReturn {
  const lazyMutation: InsertSendgridEventWithOnConflictLazyMutationFn = useMutation<
    InsertSendgridEventWithOnConflictMutation,
    InsertSendgridEventWithOnConflictMutationVariables
  >(InsertSendgridEventWithOnConflictDocument, options);
  const pickSendgridEvent: PickInsertSendgridEventFn = (
    mutation: InsertSendgridEventWithOnConflictMutation | null | undefined
  ) => {
    return mutation?.insert_sendgridEvent?.returning && mutation.insert_sendgridEvent.returning[0];
  };

  const wrappedLazyMutation: InsertSendgridEventWithOnConflictWrappedLazyMutationFn = async ({
    sendgridEvent,
    onConflict,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: sendgridEvent });
    const mutationOptions: MutationFunctionOptions<
      InsertSendgridEventWithOnConflictMutation,
      InsertSendgridEventWithOnConflictMutationVariables
    > = { variables: { objects: [objectForInsert], onConflict }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id)
        throw new Error(`if autoOptimisticResponse = true, id must be set in object 'sendgridEvent'`);
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertSendgridEventWithOnConflictMutation>(
        {
          operationType: 'insert',
          entityName: 'sendgridEvent',
          objects: [objectForInsert as SendgridEvent_Insert_Input & ObjectWithId],
        }
      );
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, sendgridEvent: pickSendgridEvent(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], sendgridEvent: pickSendgridEvent(lazyMutation[1].data) }];
}

// Types
type InsertSendgridEventObjectsMutationResult = FetchResult<
  InsertSendgridEventMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertSendgridEventObjectsMutationResultEx = InsertSendgridEventMutationResult &
  SendgridEventObjectsHookResultEx;

type PickInsertSendgridEventObjectsFn = (
  mutation: InsertSendgridEventMutation | null | undefined
) => SendgridEventFragment[];
type InsertSendgridEventObjectsLazyMutationFn = MutationTuple<
  InsertSendgridEventMutation,
  InsertSendgridEventMutationVariables
>;
type InsertSendgridEventObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<InsertSendgridEventMutation, InsertSendgridEventMutationVariables>
) => Promise<InsertSendgridEventObjectsMutationResultEx>;
export type InsertSendgridEventObjectsLazyMutationReturn = [
  InsertSendgridEventObjectsWrappedLazyMutationFn,
  InsertSendgridEventObjectsMutationResultEx
];

// Function
function useInsertSendgridEventObjects(
  options?: Omit<MutationHookOptions<InsertSendgridEventMutation, InsertSendgridEventMutationVariables>, 'mutation'>
): InsertSendgridEventObjectsLazyMutationReturn {
  const lazyMutation: InsertSendgridEventObjectsLazyMutationFn = useMutation<
    InsertSendgridEventMutation,
    InsertSendgridEventMutationVariables
  >(InsertSendgridEventDocument, options);
  const pickObjects: PickInsertSendgridEventObjectsFn = (mutation: InsertSendgridEventMutation | null | undefined) => {
    return mutation?.insert_sendgridEvent?.returning || [];
  };

  const wrappedLazyMutation: InsertSendgridEventObjectsWrappedLazyMutationFn = async (options) => {
    if (options && options.variables && options.variables.objects)
      options.variables.objects = options.variables.objects.map((objectItem) =>
        stripInsertInputClientFields({ input: objectItem })
      );
    const fetchResult: InsertSendgridEventObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Update Hooks
 */

type UpdateSendgridEventByIdMutationResult = FetchResult<
  UpdateSendgridEventByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateSendgridEventByIdMutationResultEx = UpdateSendgridEventByIdMutationResult &
  SendgridEventByIdHookResultEx;

type PickUpdateSendgridEventByIdFn = (
  mutation: UpdateSendgridEventByIdMutation | null | undefined
) => SendgridEventFragment | null | undefined;
type UpdateSendgridEventByIdLazyMutationFn = MutationTuple<
  UpdateSendgridEventByIdMutation,
  UpdateSendgridEventByIdMutationVariables
>;
type UpdateSendgridEventByIdWrappedLazyMutationFn = ({
  sendgridEventId,
  set,
  autoOptimisticResponse,
  options,
}: {
  sendgridEventId: string;
  set: SendgridEvent_Set_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<UpdateSendgridEventByIdMutation, UpdateSendgridEventByIdMutationVariables>,
    'variables'
  >;
}) => Promise<UpdateSendgridEventByIdMutationResultEx>;
export type UpdateSendgridEventByIdLazyMutationReturn = [
  UpdateSendgridEventByIdWrappedLazyMutationFn,
  UpdateSendgridEventByIdMutationResultEx
];

function useUpdateSendgridEventById(
  options?: Omit<
    MutationHookOptions<UpdateSendgridEventByIdMutation, UpdateSendgridEventByIdMutationVariables>,
    'mutation' | 'variables'
  >
): UpdateSendgridEventByIdLazyMutationReturn {
  const lazyMutation: UpdateSendgridEventByIdLazyMutationFn = useMutation<
    UpdateSendgridEventByIdMutation,
    UpdateSendgridEventByIdMutationVariables
  >(UpdateSendgridEventByIdDocument, options);

  const pickSendgridEvent: PickUpdateSendgridEventByIdFn = (mutation) => {
    return mutation?.update_sendgridEvent?.returning && mutation.update_sendgridEvent!.returning[0];
  };

  const wrappedLazyMutation: UpdateSendgridEventByIdWrappedLazyMutationFn = async ({
    sendgridEventId,
    set,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<
      UpdateSendgridEventByIdMutation,
      UpdateSendgridEventByIdMutationVariables
    > = { variables: { id: sendgridEventId, set }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateSendgridEventByIdMutation>({
        operationType: 'update',
        entityName: 'sendgridEvent',
        objects: [{ id: sendgridEventId, ...set }],
      });
    }

    const fetchResult: UpdateSendgridEventByIdMutationResult = await lazyMutation[0]({
      variables: { id: sendgridEventId, set },
      ...mutationOptions,
    });
    return { ...fetchResult, sendgridEvent: pickSendgridEvent(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], sendgridEvent: pickSendgridEvent(lazyMutation[1].data) }];
}

//
//

// Types
type UpdateSendgridEventObjectsMutationResult = FetchResult<
  UpdateSendgridEventMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateSendgridEventObjectsMutationResultEx = UpdateSendgridEventObjectsMutationResult &
  SendgridEventObjectsHookResultEx;

// Function
type PickUpdateSendgridEventObjectsFn = (
  mutation: UpdateSendgridEventMutation | null | undefined
) => SendgridEventFragment[];
type UpdateSendgridEventObjectsLazyMutationFn = MutationTuple<
  UpdateSendgridEventMutation,
  UpdateSendgridEventMutationVariables
>;
type UpdateSendgridEventObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<UpdateSendgridEventMutation, UpdateSendgridEventMutationVariables>
) => Promise<UpdateSendgridEventObjectsMutationResultEx>;
export type UpdateSendgridEventObjectsLazyMutationReturn = [
  UpdateSendgridEventObjectsWrappedLazyMutationFn,
  UpdateSendgridEventObjectsMutationResultEx
];

function useUpdateSendgridEventObjects(
  options?: Omit<MutationHookOptions<UpdateSendgridEventMutation, UpdateSendgridEventMutationVariables>, 'mutation'>
): UpdateSendgridEventObjectsLazyMutationReturn {
  const lazyMutation: UpdateSendgridEventObjectsLazyMutationFn = useMutation<
    UpdateSendgridEventMutation,
    UpdateSendgridEventMutationVariables
  >(UpdateSendgridEventDocument, options);

  const pickObjects: PickUpdateSendgridEventObjectsFn = (mutation: UpdateSendgridEventMutation | null | undefined) => {
    return mutation?.update_sendgridEvent?.returning || [];
  };

  const wrappedLazyMutation: UpdateSendgridEventObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: UpdateSendgridEventObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Delete Hooks
 */

// Types
type RemoveSendgridEventModelByIdFetchResult = FetchResult<
  RemoveSendgridEventModelByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveSendgridEventModelByIdMutationResultEx = RemoveSendgridEventModelByIdFetchResult &
  RemoveEntitiesQueryHookResultEx;

// Function
type PickRemoveSendgridEventModelFn = (mutation: RemoveSendgridEventModelByIdMutation | null | undefined) => number;
type RemoveSendgridEventModelLazyMutationFn = MutationTuple<
  RemoveSendgridEventModelByIdMutation,
  RemoveSendgridEventModelByIdMutationVariables
>;
type RemoveSendgridEventModelWrappedLazyMutationFn = ({
  sendgridEventId,
  autoOptimisticResponse,
  options,
}: {
  sendgridEventId: string;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<RemoveSendgridEventModelByIdMutation, RemoveSendgridEventModelByIdMutationVariables>,
    'variables'
  >;
}) => Promise<RemoveSendgridEventModelByIdMutationResultEx>;
export type RemoveSendgridEventModelLazyMutationReturn = [
  RemoveSendgridEventModelWrappedLazyMutationFn,
  RemoveSendgridEventModelByIdMutationResultEx
];

function useRemoveSendgridEventModelById(
  options?: Omit<
    MutationHookOptions<RemoveSendgridEventModelByIdMutation, RemoveSendgridEventModelByIdMutationVariables>,
    'mutation' | 'variables'
  >
) {
  const lazyMutation: RemoveSendgridEventModelLazyMutationFn = useMutation<
    RemoveSendgridEventModelByIdMutation,
    RemoveSendgridEventModelByIdMutationVariables
  >(RemoveSendgridEventModelByIdDocument, options);

  const pickAffectedRows: PickRemoveSendgridEventModelFn = (
    mutation: RemoveSendgridEventModelByIdMutation | null | undefined
  ) => {
    return mutation?.delete_sendgridEvent?.affected_rows || 0;
  };

  const wrappedLazyMutation: RemoveSendgridEventModelWrappedLazyMutationFn = async ({
    sendgridEventId,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<
      RemoveSendgridEventModelMutation,
      RemoveSendgridEventModelByIdMutationVariables
    > = { variables: { id: sendgridEventId }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<RemoveSendgridEventModelMutation>({
        operationType: 'delete',
        entityName: 'sendgridEvent',
        objects: [{ id: sendgridEventId }],
      });
    }
    if (!options || !options.update) {
      mutationOptions.update = generateUpdateFunctionForMutation<RemoveSendgridEventModelByIdMutation>({
        operationType: 'delete',
        entityName: 'sendgridEvent',
        entityId: sendgridEventId,
      });
    }

    const fetchResult: RemoveSendgridEventModelByIdFetchResult = await lazyMutation[0](mutationOptions);
    return { ...fetchResult, affected_rows: pickAffectedRows(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], affected_rows: pickAffectedRows(lazyMutation[1].data) }] as [
    typeof wrappedLazyMutation,
    typeof lazyMutation[1] & { affected_rows: ReturnType<typeof pickAffectedRows> }
  ];
}

//
//

// Types
type RemoveSendgridEventModelObjectsMutationResult = FetchResult<
  RemoveSendgridEventModelMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveSendgridEventModelObjectsMutationResultEx = RemoveSendgridEventModelObjectsMutationResult &
  RemoveEntitiesQueryHookResultEx;

// Function
type PickRemoveSendgridEventModelObjectsFn = (mutation: RemoveSendgridEventModelMutation | null | undefined) => number;
type RemoveSendgridEventModelObjectsLazyMutationFn = MutationTuple<
  RemoveSendgridEventModelMutation,
  RemoveSendgridEventModelMutationVariables
>;
type RemoveSendgridEventModelObjectsWrappedLazyMutationFn = (
  options: MutationFunctionOptions<RemoveSendgridEventModelMutation, RemoveSendgridEventModelMutationVariables>
) => Promise<RemoveSendgridEventModelObjectsMutationResultEx>;
export type RemoveSendgridEventModelObjectsLazyMutationReturn = [
  RemoveSendgridEventModelObjectsWrappedLazyMutationFn,
  RemoveSendgridEventModelObjectsMutationResultEx
];

function useRemoveSendgridEventModelObjects(
  options?: Omit<
    MutationHookOptions<RemoveSendgridEventModelMutation, RemoveSendgridEventModelMutationVariables>,
    'mutation'
  >
): RemoveSendgridEventModelObjectsLazyMutationReturn {
  const lazyMutation = useMutation<RemoveSendgridEventModelMutation, RemoveSendgridEventModelMutationVariables>(
    RemoveSendgridEventModelDocument,
    options
  );

  const pickAffectedRows: PickRemoveSendgridEventModelObjectsFn = (
    mutation: RemoveSendgridEventModelMutation | null | undefined
  ) => {
    return mutation?.delete_sendgridEvent?.affected_rows || 0;
  };

  const wrappedLazyMutation: RemoveSendgridEventModelObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: RemoveSendgridEventModelObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, affected_rows: pickAffectedRows(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], affected_rows: pickAffectedRows(lazyMutation[1].data) }];
}

// SendgridEvent Fragment Helper Object
//------------------------------------------------

export const SendgridEventFragmentGQLHooks = {
  useQueryById: useQuerySendgridEventById,
  useQueryByIdLazy: useQuerySendgridEventByIdLazy,
  useQueryObjects: useQuerySendgridEventObjects,
  useQueryObjectsLazy: useQuerySendgridEventObjectsLazy,
  useSubscribeToById: useSubscribeToSendgridEventById,
  useSubscribeToObjects: useSubscribeToSendgridEventObjects,
  useInsert: useInsertSendgridEvent,
  useInsertWithOnConflict: useInsertSendgridEventWithOnConflict,
  useInsertObjects: useInsertSendgridEventObjects,
  useUpdateById: useUpdateSendgridEventById,
  useUpdateObjects: useUpdateSendgridEventObjects,
};

// sendgridEvent MODEL HOOKS OBJECT
//------------------------------------------------

export const SendgridEventModelGQLHooks = {
  useRemoveById: useRemoveSendgridEventModelById,
  useRemoveObjects: useRemoveSendgridEventModelObjects,
};

// csvUpload REACT
//------------------------------------------------

export type CsvUploadByIdHookResultEx = { csvUpload: CsvUploadFragment | null | undefined };
export type CsvUploadObjectsHookResultEx = { objects: CsvUploadFragment[] };

/**
 *  Query Hook
 */

// Types
type QueryCsvUploadByIdResult = LazyQueryResult<QueryCsvUploadByIdQuery, QueryCsvUploadByIdQueryVariables>;
type QueryCsvUploadByIdSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<QueryCsvUploadByIdQuery, QueryCsvUploadByIdQueryVariables, QueryCsvUploadByIdQuery>,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryCsvUploadByIdResultEx = Omit<QueryCsvUploadByIdResult, 'subscribeToMore'> & {
  subscribeToMore: QueryCsvUploadByIdSubScribeToMore;
} & CsvUploadByIdHookResultEx;

// Function
function useQueryCsvUploadById({
  csvUploadId,
  options,
}: {
  csvUploadId: string;
  options?: Omit<QueryHookOptions<QueryCsvUploadByIdQuery, QueryCsvUploadByIdQueryVariables>, 'query' | 'variables'>;
}): QueryCsvUploadByIdResultEx {
  const _query: QueryCsvUploadByIdResult = useQuery<QueryCsvUploadByIdQuery, QueryCsvUploadByIdQueryVariables>(
    QueryCsvUploadByIdDocument,
    { variables: { csvUploadId }, ...options }
  );

  const typedSubscribeToMore: QueryCsvUploadByIdSubScribeToMore = (options) => {
    _query.subscribeToMore({
      document: QueryCsvUploadByIdDocument,
      variables: { csvUploadId } as QueryCsvUploadByIdQueryVariables,
      ...options,
    });
  };
  const { subscribeToMore, ...query } = _query;
  return { ...query, subscribeToMore: typedSubscribeToMore, csvUpload: query?.data?.csvUpload_by_pk };
}

/**
 *  Lazy Query Hook
 */

// Types
type PickQueryCsvUploadByIdFn = (
  query: QueryCsvUploadByIdQuery | null | undefined
) => CsvUploadFragment | null | undefined;
type QueryCsvUploadByIdLazyFn = [
  (options?: QueryLazyOptions<QueryCsvUploadByIdQueryVariables> | undefined) => void,
  QueryCsvUploadByIdResult
];
type QueryCsvUploadByIdWrappedLazyFn = (
  options: Omit<QueryLazyOptions<QueryCsvUploadByIdQueryVariables>, 'variables'>
) => void;
export type QueryCsvUploadByIdLazyReturn = [QueryCsvUploadByIdWrappedLazyFn, QueryCsvUploadByIdResultEx];

// Function
function useQueryCsvUploadByIdLazy({
  csvUploadId,
  options,
}: {
  csvUploadId: string;
  options?: Omit<QueryHookOptions<QueryCsvUploadByIdQuery, QueryCsvUploadByIdQueryVariables>, 'query' | 'variables'>;
}): QueryCsvUploadByIdLazyReturn {
  const lazyQuery: QueryCsvUploadByIdLazyFn = useLazyQuery<QueryCsvUploadByIdQuery, QueryCsvUploadByIdQueryVariables>(
    QueryCsvUploadByIdDocument,
    options
  );

  // Setting up typed version of lazyQuery
  const pickQueryCsvUploadById: PickQueryCsvUploadByIdFn = (query) => {
    return query && query.csvUpload_by_pk;
  };
  const wrappedLazyQuery: QueryCsvUploadByIdWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryCsvUploadByIdSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({
        document: QueryCsvUploadByIdDocument,
        variables: { csvUploadId } as QueryCsvUploadByIdQueryVariables,
        ...options,
      });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    { ...lazyQueryResult, subscribeToMore: typedSubcribeToMore, csvUpload: pickQueryCsvUploadById(lazyQuery[1].data) },
  ];
}

/**
 *  Query Collection Hook
 */

// Types
export type QueryCsvUploadObjectsResult = LazyQueryResult<
  QueryCsvUploadObjectsQuery,
  QueryCsvUploadObjectsQueryVariables
>;
type QueryCsvUploadObjectsSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<
          QueryCsvUploadObjectsQuery,
          QueryCsvUploadObjectsQueryVariables,
          QueryCsvUploadObjectsQuery
        >,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryCsvUploadObjectsResultEx = Omit<QueryCsvUploadObjectsResult, 'subscribeToMore'> & {
  subscribeToMore: QueryCsvUploadObjectsSubScribeToMore;
} & CsvUploadObjectsHookResultEx;

// Function
function useQueryCsvUploadObjects(
  options: Omit<QueryHookOptions<QueryCsvUploadObjectsQuery, QueryCsvUploadObjectsQueryVariables>, 'query'>
): QueryCsvUploadObjectsResultEx {
  const _query: QueryCsvUploadObjectsResult = useQuery<QueryCsvUploadObjectsQuery, QueryCsvUploadObjectsQueryVariables>(
    QueryCsvUploadObjectsDocument,
    options
  );

  const typedSubscribeToMore: QueryCsvUploadObjectsSubScribeToMore = (options) => {
    _query.subscribeToMore({ document: QueryCsvUploadObjectsDocument, ...options });
  };
  const { subscribeToMore, ...query } = _query;

  return { ...query, subscribeToMore: typedSubscribeToMore, objects: query?.data?.csvUpload || [] };
}

/**
 *  Lazy Query Collection Hook
 */

// Types
type PickQueryCsvUploadObjectsFn = (query: QueryCsvUploadObjectsQuery | null | undefined) => CsvUploadFragment[];
type QueryCsvUploadObjectsLazyFn = [
  (options?: QueryLazyOptions<QueryCsvUploadObjectsQueryVariables> | undefined) => void,
  QueryCsvUploadObjectsResult
];
type QueryCsvUploadObjectsWrappedLazyFn = (options?: QueryLazyOptions<QueryCsvUploadObjectsQueryVariables>) => void;
export type QueryCsvUploadObjectsLazyReturn = [QueryCsvUploadObjectsWrappedLazyFn, QueryCsvUploadObjectsResultEx];

// Function
function useQueryCsvUploadObjectsLazy(
  options?: Omit<LazyQueryHookOptions<QueryCsvUploadObjectsQuery, QueryCsvUploadObjectsQueryVariables>, 'query'>
): QueryCsvUploadObjectsLazyReturn {
  const lazyQuery: QueryCsvUploadObjectsLazyFn = useLazyQuery<
    QueryCsvUploadObjectsQuery,
    QueryCsvUploadObjectsQueryVariables
  >(QueryCsvUploadObjectsDocument, options);

  // Setting up typed version of lazyQuery
  const pickObjects: PickQueryCsvUploadObjectsFn = (query: QueryCsvUploadObjectsQuery | null | undefined) => {
    return query?.csvUpload || [];
  };
  const wrappedLazyQuery: QueryCsvUploadObjectsWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryCsvUploadObjectsSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({ document: QueryCsvUploadObjectsDocument, ...options });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    { ...lazyQueryResult, subscribeToMore: typedSubcribeToMore, objects: pickObjects(lazyQuery[1].data) },
  ];
}

/**
 *  Subscription Hook
 */

// Types
type SubscribeToCsvUploadByIdResult = {
  variables?: SubscribeToCsvUploadByIdSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToCsvUploadByIdSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToCsvUploadByIdResultEx = SubscribeToCsvUploadByIdResult & CsvUploadByIdHookResultEx;

// Function
function useSubscribeToCsvUploadById({
  csvUploadId,
  options,
}: {
  csvUploadId: string;
  options?: Omit<
    SubscriptionHookOptions<SubscribeToCsvUploadByIdSubscription, SubscribeToCsvUploadByIdSubscriptionVariables>,
    'query' | 'variables'
  >;
}): SubscribeToCsvUploadByIdResultEx {
  const subscription: SubscribeToCsvUploadByIdResult = useSubscription<
    SubscribeToCsvUploadByIdSubscription,
    SubscribeToCsvUploadByIdSubscriptionVariables
  >(SubscribeToCsvUploadByIdDocument, { variables: { csvUploadId }, ...options });
  return { ...subscription, csvUpload: subscription?.data?.csvUpload_by_pk };
}

/**
 *  Subscription Collection Hook
 */

// Types
export type SubscribeToCsvUploadObjectsResult = {
  variables?: SubscribeToCsvUploadObjectsSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToCsvUploadObjectsSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToCsvUploadObjectsResultEx = SubscribeToCsvUploadObjectsResult & CsvUploadObjectsHookResultEx;

// Function
function useSubscribeToCsvUploadObjects(
  options: Omit<
    SubscriptionHookOptions<SubscribeToCsvUploadObjectsSubscription, SubscribeToCsvUploadObjectsSubscriptionVariables>,
    'query'
  >
): SubscribeToCsvUploadObjectsResultEx {
  const subscription: SubscribeToCsvUploadObjectsResult = useSubscription<
    SubscribeToCsvUploadObjectsSubscription,
    SubscribeToCsvUploadObjectsSubscriptionVariables
  >(SubscribeToCsvUploadObjectsDocument, options);
  return { ...subscription, objects: subscription?.data?.csvUpload || [] };
}

/**
 *  Insert Hooks
 */

// Types
type InsertCsvUploadMutationResult = FetchResult<InsertCsvUploadMutation, Record<string, any>, Record<string, any>>;
export type InsertCsvUploadMutationResultEx = InsertCsvUploadMutationResult & CsvUploadByIdHookResultEx;

type PickInsertCsvUploadFn = (
  mutation: InsertCsvUploadMutation | null | undefined
) => CsvUploadFragment | null | undefined;
type InsertCsvUploadLazyMutationFn = MutationTuple<InsertCsvUploadMutation, InsertCsvUploadMutationVariables>;
type InsertCsvUploadWrappedLazyMutationFn = ({
  csvUpload,
  autoOptimisticResponse,
  options,
}: {
  csvUpload: CsvUpload_Insert_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<MutationFunctionOptions<InsertCsvUploadMutation, InsertCsvUploadMutationVariables>, 'variables'>;
}) => Promise<InsertCsvUploadMutationResultEx>;
export type InsertCsvUploadLazyMutationReturn = [InsertCsvUploadWrappedLazyMutationFn, InsertCsvUploadMutationResultEx];

// Function
function useInsertCsvUpload(
  options?: Omit<
    MutationHookOptions<InsertCsvUploadMutation, InsertCsvUploadMutationVariables>,
    'mutation' | 'variables'
  >
): InsertCsvUploadLazyMutationReturn {
  const lazyMutation: InsertCsvUploadLazyMutationFn = useMutation<
    InsertCsvUploadMutation,
    InsertCsvUploadMutationVariables
  >(InsertCsvUploadDocument, options);
  const pickCsvUpload: PickInsertCsvUploadFn = (mutation) => {
    return mutation?.insert_csvUpload?.returning && mutation?.insert_csvUpload?.returning[0];
  };

  const wrappedLazyMutation: InsertCsvUploadWrappedLazyMutationFn = async ({
    csvUpload,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: csvUpload });
    const mutationOptions: MutationFunctionOptions<InsertCsvUploadMutation, InsertCsvUploadMutationVariables> = {
      variables: { objects: [objectForInsert] },
      ...options,
    };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id)
        throw new Error(`if autoOptimisticResponse = true, id must be set in object 'csvUpload'`);
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertCsvUploadMutation>({
        operationType: 'insert',
        entityName: 'csvUpload',
        objects: [objectForInsert as CsvUpload_Insert_Input & ObjectWithId],
      });
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, csvUpload: pickCsvUpload(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], csvUpload: pickCsvUpload(lazyMutation[1].data) }];
}

//
//

// Types
type InsertCsvUploadWithOnConflictMutationResult = FetchResult<
  InsertCsvUploadWithOnConflictMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertCsvUploadWithOnConflictMutationResultEx = InsertCsvUploadWithOnConflictMutationResult &
  CsvUploadByIdHookResultEx;

type InsertCsvUploadWithOnConflictLazyMutationFn = MutationTuple<
  InsertCsvUploadWithOnConflictMutation,
  InsertCsvUploadWithOnConflictMutationVariables
>;
type InsertCsvUploadWithOnConflictWrappedLazyMutationFn = ({
  csvUpload,
  onConflict,
  autoOptimisticResponse,
  options,
}: {
  csvUpload: CsvUpload_Insert_Input;
  onConflict: CsvUpload_On_Conflict;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<InsertCsvUploadWithOnConflictMutation, InsertCsvUploadWithOnConflictMutationVariables>,
    'variables'
  >;
}) => Promise<InsertCsvUploadWithOnConflictMutationResultEx>;
export type InsertCsvUploadWithOnConflictLazyMutationReturn = [
  InsertCsvUploadWithOnConflictWrappedLazyMutationFn,
  InsertCsvUploadWithOnConflictMutationResultEx
];

// Function
function useInsertCsvUploadWithOnConflict(
  options?: Omit<
    MutationHookOptions<InsertCsvUploadWithOnConflictMutation, InsertCsvUploadWithOnConflictMutationVariables>,
    'mutation' | 'variables'
  >
): InsertCsvUploadWithOnConflictLazyMutationReturn {
  const lazyMutation: InsertCsvUploadWithOnConflictLazyMutationFn = useMutation<
    InsertCsvUploadWithOnConflictMutation,
    InsertCsvUploadWithOnConflictMutationVariables
  >(InsertCsvUploadWithOnConflictDocument, options);
  const pickCsvUpload: PickInsertCsvUploadFn = (mutation: InsertCsvUploadWithOnConflictMutation | null | undefined) => {
    return mutation?.insert_csvUpload?.returning && mutation.insert_csvUpload.returning[0];
  };

  const wrappedLazyMutation: InsertCsvUploadWithOnConflictWrappedLazyMutationFn = async ({
    csvUpload,
    onConflict,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: csvUpload });
    const mutationOptions: MutationFunctionOptions<
      InsertCsvUploadWithOnConflictMutation,
      InsertCsvUploadWithOnConflictMutationVariables
    > = { variables: { objects: [objectForInsert], onConflict }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id)
        throw new Error(`if autoOptimisticResponse = true, id must be set in object 'csvUpload'`);
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertCsvUploadWithOnConflictMutation>(
        {
          operationType: 'insert',
          entityName: 'csvUpload',
          objects: [objectForInsert as CsvUpload_Insert_Input & ObjectWithId],
        }
      );
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, csvUpload: pickCsvUpload(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], csvUpload: pickCsvUpload(lazyMutation[1].data) }];
}

// Types
type InsertCsvUploadObjectsMutationResult = FetchResult<
  InsertCsvUploadMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertCsvUploadObjectsMutationResultEx = InsertCsvUploadMutationResult & CsvUploadObjectsHookResultEx;

type PickInsertCsvUploadObjectsFn = (mutation: InsertCsvUploadMutation | null | undefined) => CsvUploadFragment[];
type InsertCsvUploadObjectsLazyMutationFn = MutationTuple<InsertCsvUploadMutation, InsertCsvUploadMutationVariables>;
type InsertCsvUploadObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<InsertCsvUploadMutation, InsertCsvUploadMutationVariables>
) => Promise<InsertCsvUploadObjectsMutationResultEx>;
export type InsertCsvUploadObjectsLazyMutationReturn = [
  InsertCsvUploadObjectsWrappedLazyMutationFn,
  InsertCsvUploadObjectsMutationResultEx
];

// Function
function useInsertCsvUploadObjects(
  options?: Omit<MutationHookOptions<InsertCsvUploadMutation, InsertCsvUploadMutationVariables>, 'mutation'>
): InsertCsvUploadObjectsLazyMutationReturn {
  const lazyMutation: InsertCsvUploadObjectsLazyMutationFn = useMutation<
    InsertCsvUploadMutation,
    InsertCsvUploadMutationVariables
  >(InsertCsvUploadDocument, options);
  const pickObjects: PickInsertCsvUploadObjectsFn = (mutation: InsertCsvUploadMutation | null | undefined) => {
    return mutation?.insert_csvUpload?.returning || [];
  };

  const wrappedLazyMutation: InsertCsvUploadObjectsWrappedLazyMutationFn = async (options) => {
    if (options && options.variables && options.variables.objects)
      options.variables.objects = options.variables.objects.map((objectItem) =>
        stripInsertInputClientFields({ input: objectItem })
      );
    const fetchResult: InsertCsvUploadObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Update Hooks
 */

type UpdateCsvUploadByIdMutationResult = FetchResult<
  UpdateCsvUploadByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateCsvUploadByIdMutationResultEx = UpdateCsvUploadByIdMutationResult & CsvUploadByIdHookResultEx;

type PickUpdateCsvUploadByIdFn = (
  mutation: UpdateCsvUploadByIdMutation | null | undefined
) => CsvUploadFragment | null | undefined;
type UpdateCsvUploadByIdLazyMutationFn = MutationTuple<
  UpdateCsvUploadByIdMutation,
  UpdateCsvUploadByIdMutationVariables
>;
type UpdateCsvUploadByIdWrappedLazyMutationFn = ({
  csvUploadId,
  set,
  autoOptimisticResponse,
  options,
}: {
  csvUploadId: string;
  set: CsvUpload_Set_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<UpdateCsvUploadByIdMutation, UpdateCsvUploadByIdMutationVariables>,
    'variables'
  >;
}) => Promise<UpdateCsvUploadByIdMutationResultEx>;
export type UpdateCsvUploadByIdLazyMutationReturn = [
  UpdateCsvUploadByIdWrappedLazyMutationFn,
  UpdateCsvUploadByIdMutationResultEx
];

function useUpdateCsvUploadById(
  options?: Omit<
    MutationHookOptions<UpdateCsvUploadByIdMutation, UpdateCsvUploadByIdMutationVariables>,
    'mutation' | 'variables'
  >
): UpdateCsvUploadByIdLazyMutationReturn {
  const lazyMutation: UpdateCsvUploadByIdLazyMutationFn = useMutation<
    UpdateCsvUploadByIdMutation,
    UpdateCsvUploadByIdMutationVariables
  >(UpdateCsvUploadByIdDocument, options);

  const pickCsvUpload: PickUpdateCsvUploadByIdFn = (mutation) => {
    return mutation?.update_csvUpload?.returning && mutation.update_csvUpload!.returning[0];
  };

  const wrappedLazyMutation: UpdateCsvUploadByIdWrappedLazyMutationFn = async ({
    csvUploadId,
    set,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<
      UpdateCsvUploadByIdMutation,
      UpdateCsvUploadByIdMutationVariables
    > = { variables: { id: csvUploadId, set }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateCsvUploadByIdMutation>({
        operationType: 'update',
        entityName: 'csvUpload',
        objects: [{ id: csvUploadId, ...set }],
      });
    }

    const fetchResult: UpdateCsvUploadByIdMutationResult = await lazyMutation[0]({
      variables: { id: csvUploadId, set },
      ...mutationOptions,
    });
    return { ...fetchResult, csvUpload: pickCsvUpload(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], csvUpload: pickCsvUpload(lazyMutation[1].data) }];
}

//
//

// Types
type UpdateCsvUploadObjectsMutationResult = FetchResult<
  UpdateCsvUploadMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateCsvUploadObjectsMutationResultEx = UpdateCsvUploadObjectsMutationResult &
  CsvUploadObjectsHookResultEx;

// Function
type PickUpdateCsvUploadObjectsFn = (mutation: UpdateCsvUploadMutation | null | undefined) => CsvUploadFragment[];
type UpdateCsvUploadObjectsLazyMutationFn = MutationTuple<UpdateCsvUploadMutation, UpdateCsvUploadMutationVariables>;
type UpdateCsvUploadObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<UpdateCsvUploadMutation, UpdateCsvUploadMutationVariables>
) => Promise<UpdateCsvUploadObjectsMutationResultEx>;
export type UpdateCsvUploadObjectsLazyMutationReturn = [
  UpdateCsvUploadObjectsWrappedLazyMutationFn,
  UpdateCsvUploadObjectsMutationResultEx
];

function useUpdateCsvUploadObjects(
  options?: Omit<MutationHookOptions<UpdateCsvUploadMutation, UpdateCsvUploadMutationVariables>, 'mutation'>
): UpdateCsvUploadObjectsLazyMutationReturn {
  const lazyMutation: UpdateCsvUploadObjectsLazyMutationFn = useMutation<
    UpdateCsvUploadMutation,
    UpdateCsvUploadMutationVariables
  >(UpdateCsvUploadDocument, options);

  const pickObjects: PickUpdateCsvUploadObjectsFn = (mutation: UpdateCsvUploadMutation | null | undefined) => {
    return mutation?.update_csvUpload?.returning || [];
  };

  const wrappedLazyMutation: UpdateCsvUploadObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: UpdateCsvUploadObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Delete Hooks
 */

// Types
type RemoveCsvUploadModelByIdFetchResult = FetchResult<
  RemoveCsvUploadModelByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveCsvUploadModelByIdMutationResultEx = RemoveCsvUploadModelByIdFetchResult &
  RemoveEntitiesQueryHookResultEx;

// Function
type PickRemoveCsvUploadModelFn = (mutation: RemoveCsvUploadModelByIdMutation | null | undefined) => number;
type RemoveCsvUploadModelLazyMutationFn = MutationTuple<
  RemoveCsvUploadModelByIdMutation,
  RemoveCsvUploadModelByIdMutationVariables
>;
type RemoveCsvUploadModelWrappedLazyMutationFn = ({
  csvUploadId,
  autoOptimisticResponse,
  options,
}: {
  csvUploadId: string;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<RemoveCsvUploadModelByIdMutation, RemoveCsvUploadModelByIdMutationVariables>,
    'variables'
  >;
}) => Promise<RemoveCsvUploadModelByIdMutationResultEx>;
export type RemoveCsvUploadModelLazyMutationReturn = [
  RemoveCsvUploadModelWrappedLazyMutationFn,
  RemoveCsvUploadModelByIdMutationResultEx
];

function useRemoveCsvUploadModelById(
  options?: Omit<
    MutationHookOptions<RemoveCsvUploadModelByIdMutation, RemoveCsvUploadModelByIdMutationVariables>,
    'mutation' | 'variables'
  >
) {
  const lazyMutation: RemoveCsvUploadModelLazyMutationFn = useMutation<
    RemoveCsvUploadModelByIdMutation,
    RemoveCsvUploadModelByIdMutationVariables
  >(RemoveCsvUploadModelByIdDocument, options);

  const pickAffectedRows: PickRemoveCsvUploadModelFn = (
    mutation: RemoveCsvUploadModelByIdMutation | null | undefined
  ) => {
    return mutation?.delete_csvUpload?.affected_rows || 0;
  };

  const wrappedLazyMutation: RemoveCsvUploadModelWrappedLazyMutationFn = async ({
    csvUploadId,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<
      RemoveCsvUploadModelMutation,
      RemoveCsvUploadModelByIdMutationVariables
    > = { variables: { id: csvUploadId }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<RemoveCsvUploadModelMutation>({
        operationType: 'delete',
        entityName: 'csvUpload',
        objects: [{ id: csvUploadId }],
      });
    }
    if (!options || !options.update) {
      mutationOptions.update = generateUpdateFunctionForMutation<RemoveCsvUploadModelByIdMutation>({
        operationType: 'delete',
        entityName: 'csvUpload',
        entityId: csvUploadId,
      });
    }

    const fetchResult: RemoveCsvUploadModelByIdFetchResult = await lazyMutation[0](mutationOptions);
    return { ...fetchResult, affected_rows: pickAffectedRows(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], affected_rows: pickAffectedRows(lazyMutation[1].data) }] as [
    typeof wrappedLazyMutation,
    typeof lazyMutation[1] & { affected_rows: ReturnType<typeof pickAffectedRows> }
  ];
}

//
//

// Types
type RemoveCsvUploadModelObjectsMutationResult = FetchResult<
  RemoveCsvUploadModelMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveCsvUploadModelObjectsMutationResultEx = RemoveCsvUploadModelObjectsMutationResult &
  RemoveEntitiesQueryHookResultEx;

// Function
type PickRemoveCsvUploadModelObjectsFn = (mutation: RemoveCsvUploadModelMutation | null | undefined) => number;
type RemoveCsvUploadModelObjectsLazyMutationFn = MutationTuple<
  RemoveCsvUploadModelMutation,
  RemoveCsvUploadModelMutationVariables
>;
type RemoveCsvUploadModelObjectsWrappedLazyMutationFn = (
  options: MutationFunctionOptions<RemoveCsvUploadModelMutation, RemoveCsvUploadModelMutationVariables>
) => Promise<RemoveCsvUploadModelObjectsMutationResultEx>;
export type RemoveCsvUploadModelObjectsLazyMutationReturn = [
  RemoveCsvUploadModelObjectsWrappedLazyMutationFn,
  RemoveCsvUploadModelObjectsMutationResultEx
];

function useRemoveCsvUploadModelObjects(
  options?: Omit<MutationHookOptions<RemoveCsvUploadModelMutation, RemoveCsvUploadModelMutationVariables>, 'mutation'>
): RemoveCsvUploadModelObjectsLazyMutationReturn {
  const lazyMutation = useMutation<RemoveCsvUploadModelMutation, RemoveCsvUploadModelMutationVariables>(
    RemoveCsvUploadModelDocument,
    options
  );

  const pickAffectedRows: PickRemoveCsvUploadModelObjectsFn = (
    mutation: RemoveCsvUploadModelMutation | null | undefined
  ) => {
    return mutation?.delete_csvUpload?.affected_rows || 0;
  };

  const wrappedLazyMutation: RemoveCsvUploadModelObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: RemoveCsvUploadModelObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, affected_rows: pickAffectedRows(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], affected_rows: pickAffectedRows(lazyMutation[1].data) }];
}

// CsvUpload Fragment Helper Object
//------------------------------------------------

export const CsvUploadFragmentGQLHooks = {
  useQueryById: useQueryCsvUploadById,
  useQueryByIdLazy: useQueryCsvUploadByIdLazy,
  useQueryObjects: useQueryCsvUploadObjects,
  useQueryObjectsLazy: useQueryCsvUploadObjectsLazy,
  useSubscribeToById: useSubscribeToCsvUploadById,
  useSubscribeToObjects: useSubscribeToCsvUploadObjects,
  useInsert: useInsertCsvUpload,
  useInsertWithOnConflict: useInsertCsvUploadWithOnConflict,
  useInsertObjects: useInsertCsvUploadObjects,
  useUpdateById: useUpdateCsvUploadById,
  useUpdateObjects: useUpdateCsvUploadObjects,
};

// csvUpload MODEL HOOKS OBJECT
//------------------------------------------------

export const CsvUploadModelGQLHooks = {
  useRemoveById: useRemoveCsvUploadModelById,
  useRemoveObjects: useRemoveCsvUploadModelObjects,
};

// hubspotContactList REACT
//------------------------------------------------

export type HubspotContactListByIdHookResultEx = { hubspotContactList: HubspotContactListFragment | null | undefined };
export type HubspotContactListObjectsHookResultEx = { objects: HubspotContactListFragment[] };

/**
 *  Query Hook
 */

// Types
type QueryHubspotContactListByIdResult = LazyQueryResult<
  QueryHubspotContactListByIdQuery,
  QueryHubspotContactListByIdQueryVariables
>;
type QueryHubspotContactListByIdSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<
          QueryHubspotContactListByIdQuery,
          QueryHubspotContactListByIdQueryVariables,
          QueryHubspotContactListByIdQuery
        >,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryHubspotContactListByIdResultEx = Omit<QueryHubspotContactListByIdResult, 'subscribeToMore'> & {
  subscribeToMore: QueryHubspotContactListByIdSubScribeToMore;
} & HubspotContactListByIdHookResultEx;

// Function
function useQueryHubspotContactListById({
  hubspotContactListId,
  options,
}: {
  hubspotContactListId: string;
  options?: Omit<
    QueryHookOptions<QueryHubspotContactListByIdQuery, QueryHubspotContactListByIdQueryVariables>,
    'query' | 'variables'
  >;
}): QueryHubspotContactListByIdResultEx {
  const _query: QueryHubspotContactListByIdResult = useQuery<
    QueryHubspotContactListByIdQuery,
    QueryHubspotContactListByIdQueryVariables
  >(QueryHubspotContactListByIdDocument, { variables: { hubspotContactListId }, ...options });

  const typedSubscribeToMore: QueryHubspotContactListByIdSubScribeToMore = (options) => {
    _query.subscribeToMore({
      document: QueryHubspotContactListByIdDocument,
      variables: { hubspotContactListId } as QueryHubspotContactListByIdQueryVariables,
      ...options,
    });
  };
  const { subscribeToMore, ...query } = _query;
  return { ...query, subscribeToMore: typedSubscribeToMore, hubspotContactList: query?.data?.hubspotContactList_by_pk };
}

/**
 *  Lazy Query Hook
 */

// Types
type PickQueryHubspotContactListByIdFn = (
  query: QueryHubspotContactListByIdQuery | null | undefined
) => HubspotContactListFragment | null | undefined;
type QueryHubspotContactListByIdLazyFn = [
  (options?: QueryLazyOptions<QueryHubspotContactListByIdQueryVariables> | undefined) => void,
  QueryHubspotContactListByIdResult
];
type QueryHubspotContactListByIdWrappedLazyFn = (
  options: Omit<QueryLazyOptions<QueryHubspotContactListByIdQueryVariables>, 'variables'>
) => void;
export type QueryHubspotContactListByIdLazyReturn = [
  QueryHubspotContactListByIdWrappedLazyFn,
  QueryHubspotContactListByIdResultEx
];

// Function
function useQueryHubspotContactListByIdLazy({
  hubspotContactListId,
  options,
}: {
  hubspotContactListId: string;
  options?: Omit<
    QueryHookOptions<QueryHubspotContactListByIdQuery, QueryHubspotContactListByIdQueryVariables>,
    'query' | 'variables'
  >;
}): QueryHubspotContactListByIdLazyReturn {
  const lazyQuery: QueryHubspotContactListByIdLazyFn = useLazyQuery<
    QueryHubspotContactListByIdQuery,
    QueryHubspotContactListByIdQueryVariables
  >(QueryHubspotContactListByIdDocument, options);

  // Setting up typed version of lazyQuery
  const pickQueryHubspotContactListById: PickQueryHubspotContactListByIdFn = (query) => {
    return query && query.hubspotContactList_by_pk;
  };
  const wrappedLazyQuery: QueryHubspotContactListByIdWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryHubspotContactListByIdSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({
        document: QueryHubspotContactListByIdDocument,
        variables: { hubspotContactListId } as QueryHubspotContactListByIdQueryVariables,
        ...options,
      });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    {
      ...lazyQueryResult,
      subscribeToMore: typedSubcribeToMore,
      hubspotContactList: pickQueryHubspotContactListById(lazyQuery[1].data),
    },
  ];
}

/**
 *  Query Collection Hook
 */

// Types
export type QueryHubspotContactListObjectsResult = LazyQueryResult<
  QueryHubspotContactListObjectsQuery,
  QueryHubspotContactListObjectsQueryVariables
>;
type QueryHubspotContactListObjectsSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<
          QueryHubspotContactListObjectsQuery,
          QueryHubspotContactListObjectsQueryVariables,
          QueryHubspotContactListObjectsQuery
        >,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryHubspotContactListObjectsResultEx = Omit<QueryHubspotContactListObjectsResult, 'subscribeToMore'> & {
  subscribeToMore: QueryHubspotContactListObjectsSubScribeToMore;
} & HubspotContactListObjectsHookResultEx;

// Function
function useQueryHubspotContactListObjects(
  options: Omit<
    QueryHookOptions<QueryHubspotContactListObjectsQuery, QueryHubspotContactListObjectsQueryVariables>,
    'query'
  >
): QueryHubspotContactListObjectsResultEx {
  const _query: QueryHubspotContactListObjectsResult = useQuery<
    QueryHubspotContactListObjectsQuery,
    QueryHubspotContactListObjectsQueryVariables
  >(QueryHubspotContactListObjectsDocument, options);

  const typedSubscribeToMore: QueryHubspotContactListObjectsSubScribeToMore = (options) => {
    _query.subscribeToMore({ document: QueryHubspotContactListObjectsDocument, ...options });
  };
  const { subscribeToMore, ...query } = _query;

  return { ...query, subscribeToMore: typedSubscribeToMore, objects: query?.data?.hubspotContactList || [] };
}

/**
 *  Lazy Query Collection Hook
 */

// Types
type PickQueryHubspotContactListObjectsFn = (
  query: QueryHubspotContactListObjectsQuery | null | undefined
) => HubspotContactListFragment[];
type QueryHubspotContactListObjectsLazyFn = [
  (options?: QueryLazyOptions<QueryHubspotContactListObjectsQueryVariables> | undefined) => void,
  QueryHubspotContactListObjectsResult
];
type QueryHubspotContactListObjectsWrappedLazyFn = (
  options?: QueryLazyOptions<QueryHubspotContactListObjectsQueryVariables>
) => void;
export type QueryHubspotContactListObjectsLazyReturn = [
  QueryHubspotContactListObjectsWrappedLazyFn,
  QueryHubspotContactListObjectsResultEx
];

// Function
function useQueryHubspotContactListObjectsLazy(
  options?: Omit<
    LazyQueryHookOptions<QueryHubspotContactListObjectsQuery, QueryHubspotContactListObjectsQueryVariables>,
    'query'
  >
): QueryHubspotContactListObjectsLazyReturn {
  const lazyQuery: QueryHubspotContactListObjectsLazyFn = useLazyQuery<
    QueryHubspotContactListObjectsQuery,
    QueryHubspotContactListObjectsQueryVariables
  >(QueryHubspotContactListObjectsDocument, options);

  // Setting up typed version of lazyQuery
  const pickObjects: PickQueryHubspotContactListObjectsFn = (
    query: QueryHubspotContactListObjectsQuery | null | undefined
  ) => {
    return query?.hubspotContactList || [];
  };
  const wrappedLazyQuery: QueryHubspotContactListObjectsWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryHubspotContactListObjectsSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({ document: QueryHubspotContactListObjectsDocument, ...options });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    { ...lazyQueryResult, subscribeToMore: typedSubcribeToMore, objects: pickObjects(lazyQuery[1].data) },
  ];
}

/**
 *  Subscription Hook
 */

// Types
type SubscribeToHubspotContactListByIdResult = {
  variables?: SubscribeToHubspotContactListByIdSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToHubspotContactListByIdSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToHubspotContactListByIdResultEx = SubscribeToHubspotContactListByIdResult &
  HubspotContactListByIdHookResultEx;

// Function
function useSubscribeToHubspotContactListById({
  hubspotContactListId,
  options,
}: {
  hubspotContactListId: string;
  options?: Omit<
    SubscriptionHookOptions<
      SubscribeToHubspotContactListByIdSubscription,
      SubscribeToHubspotContactListByIdSubscriptionVariables
    >,
    'query' | 'variables'
  >;
}): SubscribeToHubspotContactListByIdResultEx {
  const subscription: SubscribeToHubspotContactListByIdResult = useSubscription<
    SubscribeToHubspotContactListByIdSubscription,
    SubscribeToHubspotContactListByIdSubscriptionVariables
  >(SubscribeToHubspotContactListByIdDocument, { variables: { hubspotContactListId }, ...options });
  return { ...subscription, hubspotContactList: subscription?.data?.hubspotContactList_by_pk };
}

/**
 *  Subscription Collection Hook
 */

// Types
export type SubscribeToHubspotContactListObjectsResult = {
  variables?: SubscribeToHubspotContactListObjectsSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToHubspotContactListObjectsSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToHubspotContactListObjectsResultEx = SubscribeToHubspotContactListObjectsResult &
  HubspotContactListObjectsHookResultEx;

// Function
function useSubscribeToHubspotContactListObjects(
  options: Omit<
    SubscriptionHookOptions<
      SubscribeToHubspotContactListObjectsSubscription,
      SubscribeToHubspotContactListObjectsSubscriptionVariables
    >,
    'query'
  >
): SubscribeToHubspotContactListObjectsResultEx {
  const subscription: SubscribeToHubspotContactListObjectsResult = useSubscription<
    SubscribeToHubspotContactListObjectsSubscription,
    SubscribeToHubspotContactListObjectsSubscriptionVariables
  >(SubscribeToHubspotContactListObjectsDocument, options);
  return { ...subscription, objects: subscription?.data?.hubspotContactList || [] };
}

/**
 *  Insert Hooks
 */

// Types
type InsertHubspotContactListMutationResult = FetchResult<
  InsertHubspotContactListMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertHubspotContactListMutationResultEx = InsertHubspotContactListMutationResult &
  HubspotContactListByIdHookResultEx;

type PickInsertHubspotContactListFn = (
  mutation: InsertHubspotContactListMutation | null | undefined
) => HubspotContactListFragment | null | undefined;
type InsertHubspotContactListLazyMutationFn = MutationTuple<
  InsertHubspotContactListMutation,
  InsertHubspotContactListMutationVariables
>;
type InsertHubspotContactListWrappedLazyMutationFn = ({
  hubspotContactList,
  autoOptimisticResponse,
  options,
}: {
  hubspotContactList: HubspotContactList_Insert_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<InsertHubspotContactListMutation, InsertHubspotContactListMutationVariables>,
    'variables'
  >;
}) => Promise<InsertHubspotContactListMutationResultEx>;
export type InsertHubspotContactListLazyMutationReturn = [
  InsertHubspotContactListWrappedLazyMutationFn,
  InsertHubspotContactListMutationResultEx
];

// Function
function useInsertHubspotContactList(
  options?: Omit<
    MutationHookOptions<InsertHubspotContactListMutation, InsertHubspotContactListMutationVariables>,
    'mutation' | 'variables'
  >
): InsertHubspotContactListLazyMutationReturn {
  const lazyMutation: InsertHubspotContactListLazyMutationFn = useMutation<
    InsertHubspotContactListMutation,
    InsertHubspotContactListMutationVariables
  >(InsertHubspotContactListDocument, options);
  const pickHubspotContactList: PickInsertHubspotContactListFn = (mutation) => {
    return mutation?.insert_hubspotContactList?.returning && mutation?.insert_hubspotContactList?.returning[0];
  };

  const wrappedLazyMutation: InsertHubspotContactListWrappedLazyMutationFn = async ({
    hubspotContactList,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: hubspotContactList });
    const mutationOptions: MutationFunctionOptions<
      InsertHubspotContactListMutation,
      InsertHubspotContactListMutationVariables
    > = { variables: { objects: [objectForInsert] }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id)
        throw new Error(`if autoOptimisticResponse = true, id must be set in object 'hubspotContactList'`);
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertHubspotContactListMutation>({
        operationType: 'insert',
        entityName: 'hubspotContactList',
        objects: [objectForInsert as HubspotContactList_Insert_Input & ObjectWithId],
      });
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, hubspotContactList: pickHubspotContactList(fetchResult.data) };
  };

  return [
    wrappedLazyMutation,
    { ...lazyMutation[1], hubspotContactList: pickHubspotContactList(lazyMutation[1].data) },
  ];
}

//
//

// Types
type InsertHubspotContactListWithOnConflictMutationResult = FetchResult<
  InsertHubspotContactListWithOnConflictMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertHubspotContactListWithOnConflictMutationResultEx = InsertHubspotContactListWithOnConflictMutationResult &
  HubspotContactListByIdHookResultEx;

type InsertHubspotContactListWithOnConflictLazyMutationFn = MutationTuple<
  InsertHubspotContactListWithOnConflictMutation,
  InsertHubspotContactListWithOnConflictMutationVariables
>;
type InsertHubspotContactListWithOnConflictWrappedLazyMutationFn = ({
  hubspotContactList,
  onConflict,
  autoOptimisticResponse,
  options,
}: {
  hubspotContactList: HubspotContactList_Insert_Input;
  onConflict: HubspotContactList_On_Conflict;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<
      InsertHubspotContactListWithOnConflictMutation,
      InsertHubspotContactListWithOnConflictMutationVariables
    >,
    'variables'
  >;
}) => Promise<InsertHubspotContactListWithOnConflictMutationResultEx>;
export type InsertHubspotContactListWithOnConflictLazyMutationReturn = [
  InsertHubspotContactListWithOnConflictWrappedLazyMutationFn,
  InsertHubspotContactListWithOnConflictMutationResultEx
];

// Function
function useInsertHubspotContactListWithOnConflict(
  options?: Omit<
    MutationHookOptions<
      InsertHubspotContactListWithOnConflictMutation,
      InsertHubspotContactListWithOnConflictMutationVariables
    >,
    'mutation' | 'variables'
  >
): InsertHubspotContactListWithOnConflictLazyMutationReturn {
  const lazyMutation: InsertHubspotContactListWithOnConflictLazyMutationFn = useMutation<
    InsertHubspotContactListWithOnConflictMutation,
    InsertHubspotContactListWithOnConflictMutationVariables
  >(InsertHubspotContactListWithOnConflictDocument, options);
  const pickHubspotContactList: PickInsertHubspotContactListFn = (
    mutation: InsertHubspotContactListWithOnConflictMutation | null | undefined
  ) => {
    return mutation?.insert_hubspotContactList?.returning && mutation.insert_hubspotContactList.returning[0];
  };

  const wrappedLazyMutation: InsertHubspotContactListWithOnConflictWrappedLazyMutationFn = async ({
    hubspotContactList,
    onConflict,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: hubspotContactList });
    const mutationOptions: MutationFunctionOptions<
      InsertHubspotContactListWithOnConflictMutation,
      InsertHubspotContactListWithOnConflictMutationVariables
    > = { variables: { objects: [objectForInsert], onConflict }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id)
        throw new Error(`if autoOptimisticResponse = true, id must be set in object 'hubspotContactList'`);
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertHubspotContactListWithOnConflictMutation>(
        {
          operationType: 'insert',
          entityName: 'hubspotContactList',
          objects: [objectForInsert as HubspotContactList_Insert_Input & ObjectWithId],
        }
      );
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, hubspotContactList: pickHubspotContactList(fetchResult.data) };
  };

  return [
    wrappedLazyMutation,
    { ...lazyMutation[1], hubspotContactList: pickHubspotContactList(lazyMutation[1].data) },
  ];
}

// Types
type InsertHubspotContactListObjectsMutationResult = FetchResult<
  InsertHubspotContactListMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertHubspotContactListObjectsMutationResultEx = InsertHubspotContactListMutationResult &
  HubspotContactListObjectsHookResultEx;

type PickInsertHubspotContactListObjectsFn = (
  mutation: InsertHubspotContactListMutation | null | undefined
) => HubspotContactListFragment[];
type InsertHubspotContactListObjectsLazyMutationFn = MutationTuple<
  InsertHubspotContactListMutation,
  InsertHubspotContactListMutationVariables
>;
type InsertHubspotContactListObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<InsertHubspotContactListMutation, InsertHubspotContactListMutationVariables>
) => Promise<InsertHubspotContactListObjectsMutationResultEx>;
export type InsertHubspotContactListObjectsLazyMutationReturn = [
  InsertHubspotContactListObjectsWrappedLazyMutationFn,
  InsertHubspotContactListObjectsMutationResultEx
];

// Function
function useInsertHubspotContactListObjects(
  options?: Omit<
    MutationHookOptions<InsertHubspotContactListMutation, InsertHubspotContactListMutationVariables>,
    'mutation'
  >
): InsertHubspotContactListObjectsLazyMutationReturn {
  const lazyMutation: InsertHubspotContactListObjectsLazyMutationFn = useMutation<
    InsertHubspotContactListMutation,
    InsertHubspotContactListMutationVariables
  >(InsertHubspotContactListDocument, options);
  const pickObjects: PickInsertHubspotContactListObjectsFn = (
    mutation: InsertHubspotContactListMutation | null | undefined
  ) => {
    return mutation?.insert_hubspotContactList?.returning || [];
  };

  const wrappedLazyMutation: InsertHubspotContactListObjectsWrappedLazyMutationFn = async (options) => {
    if (options && options.variables && options.variables.objects)
      options.variables.objects = options.variables.objects.map((objectItem) =>
        stripInsertInputClientFields({ input: objectItem })
      );
    const fetchResult: InsertHubspotContactListObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Update Hooks
 */

type UpdateHubspotContactListByIdMutationResult = FetchResult<
  UpdateHubspotContactListByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateHubspotContactListByIdMutationResultEx = UpdateHubspotContactListByIdMutationResult &
  HubspotContactListByIdHookResultEx;

type PickUpdateHubspotContactListByIdFn = (
  mutation: UpdateHubspotContactListByIdMutation | null | undefined
) => HubspotContactListFragment | null | undefined;
type UpdateHubspotContactListByIdLazyMutationFn = MutationTuple<
  UpdateHubspotContactListByIdMutation,
  UpdateHubspotContactListByIdMutationVariables
>;
type UpdateHubspotContactListByIdWrappedLazyMutationFn = ({
  hubspotContactListId,
  set,
  autoOptimisticResponse,
  options,
}: {
  hubspotContactListId: string;
  set: HubspotContactList_Set_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<UpdateHubspotContactListByIdMutation, UpdateHubspotContactListByIdMutationVariables>,
    'variables'
  >;
}) => Promise<UpdateHubspotContactListByIdMutationResultEx>;
export type UpdateHubspotContactListByIdLazyMutationReturn = [
  UpdateHubspotContactListByIdWrappedLazyMutationFn,
  UpdateHubspotContactListByIdMutationResultEx
];

function useUpdateHubspotContactListById(
  options?: Omit<
    MutationHookOptions<UpdateHubspotContactListByIdMutation, UpdateHubspotContactListByIdMutationVariables>,
    'mutation' | 'variables'
  >
): UpdateHubspotContactListByIdLazyMutationReturn {
  const lazyMutation: UpdateHubspotContactListByIdLazyMutationFn = useMutation<
    UpdateHubspotContactListByIdMutation,
    UpdateHubspotContactListByIdMutationVariables
  >(UpdateHubspotContactListByIdDocument, options);

  const pickHubspotContactList: PickUpdateHubspotContactListByIdFn = (mutation) => {
    return mutation?.update_hubspotContactList?.returning && mutation.update_hubspotContactList!.returning[0];
  };

  const wrappedLazyMutation: UpdateHubspotContactListByIdWrappedLazyMutationFn = async ({
    hubspotContactListId,
    set,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<
      UpdateHubspotContactListByIdMutation,
      UpdateHubspotContactListByIdMutationVariables
    > = { variables: { id: hubspotContactListId, set }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateHubspotContactListByIdMutation>({
        operationType: 'update',
        entityName: 'hubspotContactList',
        objects: [{ id: hubspotContactListId, ...set }],
      });
    }

    const fetchResult: UpdateHubspotContactListByIdMutationResult = await lazyMutation[0]({
      variables: { id: hubspotContactListId, set },
      ...mutationOptions,
    });
    return { ...fetchResult, hubspotContactList: pickHubspotContactList(fetchResult.data) };
  };

  return [
    wrappedLazyMutation,
    { ...lazyMutation[1], hubspotContactList: pickHubspotContactList(lazyMutation[1].data) },
  ];
}

//
//

// Types
type UpdateHubspotContactListObjectsMutationResult = FetchResult<
  UpdateHubspotContactListMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateHubspotContactListObjectsMutationResultEx = UpdateHubspotContactListObjectsMutationResult &
  HubspotContactListObjectsHookResultEx;

// Function
type PickUpdateHubspotContactListObjectsFn = (
  mutation: UpdateHubspotContactListMutation | null | undefined
) => HubspotContactListFragment[];
type UpdateHubspotContactListObjectsLazyMutationFn = MutationTuple<
  UpdateHubspotContactListMutation,
  UpdateHubspotContactListMutationVariables
>;
type UpdateHubspotContactListObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<UpdateHubspotContactListMutation, UpdateHubspotContactListMutationVariables>
) => Promise<UpdateHubspotContactListObjectsMutationResultEx>;
export type UpdateHubspotContactListObjectsLazyMutationReturn = [
  UpdateHubspotContactListObjectsWrappedLazyMutationFn,
  UpdateHubspotContactListObjectsMutationResultEx
];

function useUpdateHubspotContactListObjects(
  options?: Omit<
    MutationHookOptions<UpdateHubspotContactListMutation, UpdateHubspotContactListMutationVariables>,
    'mutation'
  >
): UpdateHubspotContactListObjectsLazyMutationReturn {
  const lazyMutation: UpdateHubspotContactListObjectsLazyMutationFn = useMutation<
    UpdateHubspotContactListMutation,
    UpdateHubspotContactListMutationVariables
  >(UpdateHubspotContactListDocument, options);

  const pickObjects: PickUpdateHubspotContactListObjectsFn = (
    mutation: UpdateHubspotContactListMutation | null | undefined
  ) => {
    return mutation?.update_hubspotContactList?.returning || [];
  };

  const wrappedLazyMutation: UpdateHubspotContactListObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: UpdateHubspotContactListObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Delete Hooks
 */

// Types
type RemoveHubspotContactListModelByIdFetchResult = FetchResult<
  RemoveHubspotContactListModelByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveHubspotContactListModelByIdMutationResultEx = RemoveHubspotContactListModelByIdFetchResult &
  RemoveEntitiesQueryHookResultEx;

// Function
type PickRemoveHubspotContactListModelFn = (
  mutation: RemoveHubspotContactListModelByIdMutation | null | undefined
) => number;
type RemoveHubspotContactListModelLazyMutationFn = MutationTuple<
  RemoveHubspotContactListModelByIdMutation,
  RemoveHubspotContactListModelByIdMutationVariables
>;
type RemoveHubspotContactListModelWrappedLazyMutationFn = ({
  hubspotContactListId,
  autoOptimisticResponse,
  options,
}: {
  hubspotContactListId: string;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<
      RemoveHubspotContactListModelByIdMutation,
      RemoveHubspotContactListModelByIdMutationVariables
    >,
    'variables'
  >;
}) => Promise<RemoveHubspotContactListModelByIdMutationResultEx>;
export type RemoveHubspotContactListModelLazyMutationReturn = [
  RemoveHubspotContactListModelWrappedLazyMutationFn,
  RemoveHubspotContactListModelByIdMutationResultEx
];

function useRemoveHubspotContactListModelById(
  options?: Omit<
    MutationHookOptions<RemoveHubspotContactListModelByIdMutation, RemoveHubspotContactListModelByIdMutationVariables>,
    'mutation' | 'variables'
  >
) {
  const lazyMutation: RemoveHubspotContactListModelLazyMutationFn = useMutation<
    RemoveHubspotContactListModelByIdMutation,
    RemoveHubspotContactListModelByIdMutationVariables
  >(RemoveHubspotContactListModelByIdDocument, options);

  const pickAffectedRows: PickRemoveHubspotContactListModelFn = (
    mutation: RemoveHubspotContactListModelByIdMutation | null | undefined
  ) => {
    return mutation?.delete_hubspotContactList?.affected_rows || 0;
  };

  const wrappedLazyMutation: RemoveHubspotContactListModelWrappedLazyMutationFn = async ({
    hubspotContactListId,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<
      RemoveHubspotContactListModelMutation,
      RemoveHubspotContactListModelByIdMutationVariables
    > = { variables: { id: hubspotContactListId }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<RemoveHubspotContactListModelMutation>(
        { operationType: 'delete', entityName: 'hubspotContactList', objects: [{ id: hubspotContactListId }] }
      );
    }
    if (!options || !options.update) {
      mutationOptions.update = generateUpdateFunctionForMutation<RemoveHubspotContactListModelByIdMutation>({
        operationType: 'delete',
        entityName: 'hubspotContactList',
        entityId: hubspotContactListId,
      });
    }

    const fetchResult: RemoveHubspotContactListModelByIdFetchResult = await lazyMutation[0](mutationOptions);
    return { ...fetchResult, affected_rows: pickAffectedRows(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], affected_rows: pickAffectedRows(lazyMutation[1].data) }] as [
    typeof wrappedLazyMutation,
    typeof lazyMutation[1] & { affected_rows: ReturnType<typeof pickAffectedRows> }
  ];
}

//
//

// Types
type RemoveHubspotContactListModelObjectsMutationResult = FetchResult<
  RemoveHubspotContactListModelMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveHubspotContactListModelObjectsMutationResultEx = RemoveHubspotContactListModelObjectsMutationResult &
  RemoveEntitiesQueryHookResultEx;

// Function
type PickRemoveHubspotContactListModelObjectsFn = (
  mutation: RemoveHubspotContactListModelMutation | null | undefined
) => number;
type RemoveHubspotContactListModelObjectsLazyMutationFn = MutationTuple<
  RemoveHubspotContactListModelMutation,
  RemoveHubspotContactListModelMutationVariables
>;
type RemoveHubspotContactListModelObjectsWrappedLazyMutationFn = (
  options: MutationFunctionOptions<
    RemoveHubspotContactListModelMutation,
    RemoveHubspotContactListModelMutationVariables
  >
) => Promise<RemoveHubspotContactListModelObjectsMutationResultEx>;
export type RemoveHubspotContactListModelObjectsLazyMutationReturn = [
  RemoveHubspotContactListModelObjectsWrappedLazyMutationFn,
  RemoveHubspotContactListModelObjectsMutationResultEx
];

function useRemoveHubspotContactListModelObjects(
  options?: Omit<
    MutationHookOptions<RemoveHubspotContactListModelMutation, RemoveHubspotContactListModelMutationVariables>,
    'mutation'
  >
): RemoveHubspotContactListModelObjectsLazyMutationReturn {
  const lazyMutation = useMutation<
    RemoveHubspotContactListModelMutation,
    RemoveHubspotContactListModelMutationVariables
  >(RemoveHubspotContactListModelDocument, options);

  const pickAffectedRows: PickRemoveHubspotContactListModelObjectsFn = (
    mutation: RemoveHubspotContactListModelMutation | null | undefined
  ) => {
    return mutation?.delete_hubspotContactList?.affected_rows || 0;
  };

  const wrappedLazyMutation: RemoveHubspotContactListModelObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: RemoveHubspotContactListModelObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, affected_rows: pickAffectedRows(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], affected_rows: pickAffectedRows(lazyMutation[1].data) }];
}

// HubspotContactList Fragment Helper Object
//------------------------------------------------

export const HubspotContactListFragmentGQLHooks = {
  useQueryById: useQueryHubspotContactListById,
  useQueryByIdLazy: useQueryHubspotContactListByIdLazy,
  useQueryObjects: useQueryHubspotContactListObjects,
  useQueryObjectsLazy: useQueryHubspotContactListObjectsLazy,
  useSubscribeToById: useSubscribeToHubspotContactListById,
  useSubscribeToObjects: useSubscribeToHubspotContactListObjects,
  useInsert: useInsertHubspotContactList,
  useInsertWithOnConflict: useInsertHubspotContactListWithOnConflict,
  useInsertObjects: useInsertHubspotContactListObjects,
  useUpdateById: useUpdateHubspotContactListById,
  useUpdateObjects: useUpdateHubspotContactListObjects,
};

// hubspotContactList MODEL HOOKS OBJECT
//------------------------------------------------

export const HubspotContactListModelGQLHooks = {
  useRemoveById: useRemoveHubspotContactListModelById,
  useRemoveObjects: useRemoveHubspotContactListModelObjects,
};

// hubspotContactListHubspotContact REACT
//------------------------------------------------

export type HubspotContactListHubspotContactByIdHookResultEx = {
  hubspotContactListHubspotContact: HubspotContactListHubspotContactFragment | null | undefined;
};
export type HubspotContactListHubspotContactObjectsHookResultEx = {
  objects: HubspotContactListHubspotContactFragment[];
};

/**
 *  Query Hook
 */

// Types
type QueryHubspotContactListHubspotContactByIdResult = LazyQueryResult<
  QueryHubspotContactListHubspotContactByIdQuery,
  QueryHubspotContactListHubspotContactByIdQueryVariables
>;
type QueryHubspotContactListHubspotContactByIdSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<
          QueryHubspotContactListHubspotContactByIdQuery,
          QueryHubspotContactListHubspotContactByIdQueryVariables,
          QueryHubspotContactListHubspotContactByIdQuery
        >,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryHubspotContactListHubspotContactByIdResultEx = Omit<
  QueryHubspotContactListHubspotContactByIdResult,
  'subscribeToMore'
> & {
  subscribeToMore: QueryHubspotContactListHubspotContactByIdSubScribeToMore;
} & HubspotContactListHubspotContactByIdHookResultEx;

// Function
function useQueryHubspotContactListHubspotContactById({
  hubspotContactListHubspotContactId,
  options,
}: {
  hubspotContactListHubspotContactId: string;
  options?: Omit<
    QueryHookOptions<
      QueryHubspotContactListHubspotContactByIdQuery,
      QueryHubspotContactListHubspotContactByIdQueryVariables
    >,
    'query' | 'variables'
  >;
}): QueryHubspotContactListHubspotContactByIdResultEx {
  const _query: QueryHubspotContactListHubspotContactByIdResult = useQuery<
    QueryHubspotContactListHubspotContactByIdQuery,
    QueryHubspotContactListHubspotContactByIdQueryVariables
  >(QueryHubspotContactListHubspotContactByIdDocument, {
    variables: { hubspotContactListHubspotContactId },
    ...options,
  });

  const typedSubscribeToMore: QueryHubspotContactListHubspotContactByIdSubScribeToMore = (options) => {
    _query.subscribeToMore({
      document: QueryHubspotContactListHubspotContactByIdDocument,
      variables: { hubspotContactListHubspotContactId } as QueryHubspotContactListHubspotContactByIdQueryVariables,
      ...options,
    });
  };
  const { subscribeToMore, ...query } = _query;
  return {
    ...query,
    subscribeToMore: typedSubscribeToMore,
    hubspotContactListHubspotContact: query?.data?.hubspotContactListHubspotContact_by_pk,
  };
}

/**
 *  Lazy Query Hook
 */

// Types
type PickQueryHubspotContactListHubspotContactByIdFn = (
  query: QueryHubspotContactListHubspotContactByIdQuery | null | undefined
) => HubspotContactListHubspotContactFragment | null | undefined;
type QueryHubspotContactListHubspotContactByIdLazyFn = [
  (options?: QueryLazyOptions<QueryHubspotContactListHubspotContactByIdQueryVariables> | undefined) => void,
  QueryHubspotContactListHubspotContactByIdResult
];
type QueryHubspotContactListHubspotContactByIdWrappedLazyFn = (
  options: Omit<QueryLazyOptions<QueryHubspotContactListHubspotContactByIdQueryVariables>, 'variables'>
) => void;
export type QueryHubspotContactListHubspotContactByIdLazyReturn = [
  QueryHubspotContactListHubspotContactByIdWrappedLazyFn,
  QueryHubspotContactListHubspotContactByIdResultEx
];

// Function
function useQueryHubspotContactListHubspotContactByIdLazy({
  hubspotContactListHubspotContactId,
  options,
}: {
  hubspotContactListHubspotContactId: string;
  options?: Omit<
    QueryHookOptions<
      QueryHubspotContactListHubspotContactByIdQuery,
      QueryHubspotContactListHubspotContactByIdQueryVariables
    >,
    'query' | 'variables'
  >;
}): QueryHubspotContactListHubspotContactByIdLazyReturn {
  const lazyQuery: QueryHubspotContactListHubspotContactByIdLazyFn = useLazyQuery<
    QueryHubspotContactListHubspotContactByIdQuery,
    QueryHubspotContactListHubspotContactByIdQueryVariables
  >(QueryHubspotContactListHubspotContactByIdDocument, options);

  // Setting up typed version of lazyQuery
  const pickQueryHubspotContactListHubspotContactById: PickQueryHubspotContactListHubspotContactByIdFn = (query) => {
    return query && query.hubspotContactListHubspotContact_by_pk;
  };
  const wrappedLazyQuery: QueryHubspotContactListHubspotContactByIdWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryHubspotContactListHubspotContactByIdSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({
        document: QueryHubspotContactListHubspotContactByIdDocument,
        variables: { hubspotContactListHubspotContactId } as QueryHubspotContactListHubspotContactByIdQueryVariables,
        ...options,
      });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    {
      ...lazyQueryResult,
      subscribeToMore: typedSubcribeToMore,
      hubspotContactListHubspotContact: pickQueryHubspotContactListHubspotContactById(lazyQuery[1].data),
    },
  ];
}

/**
 *  Query Collection Hook
 */

// Types
export type QueryHubspotContactListHubspotContactObjectsResult = LazyQueryResult<
  QueryHubspotContactListHubspotContactObjectsQuery,
  QueryHubspotContactListHubspotContactObjectsQueryVariables
>;
type QueryHubspotContactListHubspotContactObjectsSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<
          QueryHubspotContactListHubspotContactObjectsQuery,
          QueryHubspotContactListHubspotContactObjectsQueryVariables,
          QueryHubspotContactListHubspotContactObjectsQuery
        >,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryHubspotContactListHubspotContactObjectsResultEx = Omit<
  QueryHubspotContactListHubspotContactObjectsResult,
  'subscribeToMore'
> & {
  subscribeToMore: QueryHubspotContactListHubspotContactObjectsSubScribeToMore;
} & HubspotContactListHubspotContactObjectsHookResultEx;

// Function
function useQueryHubspotContactListHubspotContactObjects(
  options: Omit<
    QueryHookOptions<
      QueryHubspotContactListHubspotContactObjectsQuery,
      QueryHubspotContactListHubspotContactObjectsQueryVariables
    >,
    'query'
  >
): QueryHubspotContactListHubspotContactObjectsResultEx {
  const _query: QueryHubspotContactListHubspotContactObjectsResult = useQuery<
    QueryHubspotContactListHubspotContactObjectsQuery,
    QueryHubspotContactListHubspotContactObjectsQueryVariables
  >(QueryHubspotContactListHubspotContactObjectsDocument, options);

  const typedSubscribeToMore: QueryHubspotContactListHubspotContactObjectsSubScribeToMore = (options) => {
    _query.subscribeToMore({ document: QueryHubspotContactListHubspotContactObjectsDocument, ...options });
  };
  const { subscribeToMore, ...query } = _query;

  return {
    ...query,
    subscribeToMore: typedSubscribeToMore,
    objects: query?.data?.hubspotContactListHubspotContact || [],
  };
}

/**
 *  Lazy Query Collection Hook
 */

// Types
type PickQueryHubspotContactListHubspotContactObjectsFn = (
  query: QueryHubspotContactListHubspotContactObjectsQuery | null | undefined
) => HubspotContactListHubspotContactFragment[];
type QueryHubspotContactListHubspotContactObjectsLazyFn = [
  (options?: QueryLazyOptions<QueryHubspotContactListHubspotContactObjectsQueryVariables> | undefined) => void,
  QueryHubspotContactListHubspotContactObjectsResult
];
type QueryHubspotContactListHubspotContactObjectsWrappedLazyFn = (
  options?: QueryLazyOptions<QueryHubspotContactListHubspotContactObjectsQueryVariables>
) => void;
export type QueryHubspotContactListHubspotContactObjectsLazyReturn = [
  QueryHubspotContactListHubspotContactObjectsWrappedLazyFn,
  QueryHubspotContactListHubspotContactObjectsResultEx
];

// Function
function useQueryHubspotContactListHubspotContactObjectsLazy(
  options?: Omit<
    LazyQueryHookOptions<
      QueryHubspotContactListHubspotContactObjectsQuery,
      QueryHubspotContactListHubspotContactObjectsQueryVariables
    >,
    'query'
  >
): QueryHubspotContactListHubspotContactObjectsLazyReturn {
  const lazyQuery: QueryHubspotContactListHubspotContactObjectsLazyFn = useLazyQuery<
    QueryHubspotContactListHubspotContactObjectsQuery,
    QueryHubspotContactListHubspotContactObjectsQueryVariables
  >(QueryHubspotContactListHubspotContactObjectsDocument, options);

  // Setting up typed version of lazyQuery
  const pickObjects: PickQueryHubspotContactListHubspotContactObjectsFn = (
    query: QueryHubspotContactListHubspotContactObjectsQuery | null | undefined
  ) => {
    return query?.hubspotContactListHubspotContact || [];
  };
  const wrappedLazyQuery: QueryHubspotContactListHubspotContactObjectsWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryHubspotContactListHubspotContactObjectsSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({ document: QueryHubspotContactListHubspotContactObjectsDocument, ...options });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    { ...lazyQueryResult, subscribeToMore: typedSubcribeToMore, objects: pickObjects(lazyQuery[1].data) },
  ];
}

/**
 *  Subscription Hook
 */

// Types
type SubscribeToHubspotContactListHubspotContactByIdResult = {
  variables?: SubscribeToHubspotContactListHubspotContactByIdSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToHubspotContactListHubspotContactByIdSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToHubspotContactListHubspotContactByIdResultEx = SubscribeToHubspotContactListHubspotContactByIdResult &
  HubspotContactListHubspotContactByIdHookResultEx;

// Function
function useSubscribeToHubspotContactListHubspotContactById({
  hubspotContactListHubspotContactId,
  options,
}: {
  hubspotContactListHubspotContactId: string;
  options?: Omit<
    SubscriptionHookOptions<
      SubscribeToHubspotContactListHubspotContactByIdSubscription,
      SubscribeToHubspotContactListHubspotContactByIdSubscriptionVariables
    >,
    'query' | 'variables'
  >;
}): SubscribeToHubspotContactListHubspotContactByIdResultEx {
  const subscription: SubscribeToHubspotContactListHubspotContactByIdResult = useSubscription<
    SubscribeToHubspotContactListHubspotContactByIdSubscription,
    SubscribeToHubspotContactListHubspotContactByIdSubscriptionVariables
  >(SubscribeToHubspotContactListHubspotContactByIdDocument, {
    variables: { hubspotContactListHubspotContactId },
    ...options,
  });
  return {
    ...subscription,
    hubspotContactListHubspotContact: subscription?.data?.hubspotContactListHubspotContact_by_pk,
  };
}

/**
 *  Subscription Collection Hook
 */

// Types
export type SubscribeToHubspotContactListHubspotContactObjectsResult = {
  variables?: SubscribeToHubspotContactListHubspotContactObjectsSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToHubspotContactListHubspotContactObjectsSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToHubspotContactListHubspotContactObjectsResultEx = SubscribeToHubspotContactListHubspotContactObjectsResult &
  HubspotContactListHubspotContactObjectsHookResultEx;

// Function
function useSubscribeToHubspotContactListHubspotContactObjects(
  options: Omit<
    SubscriptionHookOptions<
      SubscribeToHubspotContactListHubspotContactObjectsSubscription,
      SubscribeToHubspotContactListHubspotContactObjectsSubscriptionVariables
    >,
    'query'
  >
): SubscribeToHubspotContactListHubspotContactObjectsResultEx {
  const subscription: SubscribeToHubspotContactListHubspotContactObjectsResult = useSubscription<
    SubscribeToHubspotContactListHubspotContactObjectsSubscription,
    SubscribeToHubspotContactListHubspotContactObjectsSubscriptionVariables
  >(SubscribeToHubspotContactListHubspotContactObjectsDocument, options);
  return { ...subscription, objects: subscription?.data?.hubspotContactListHubspotContact || [] };
}

/**
 *  Insert Hooks
 */

// Types
type InsertHubspotContactListHubspotContactMutationResult = FetchResult<
  InsertHubspotContactListHubspotContactMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertHubspotContactListHubspotContactMutationResultEx = InsertHubspotContactListHubspotContactMutationResult &
  HubspotContactListHubspotContactByIdHookResultEx;

type PickInsertHubspotContactListHubspotContactFn = (
  mutation: InsertHubspotContactListHubspotContactMutation | null | undefined
) => HubspotContactListHubspotContactFragment | null | undefined;
type InsertHubspotContactListHubspotContactLazyMutationFn = MutationTuple<
  InsertHubspotContactListHubspotContactMutation,
  InsertHubspotContactListHubspotContactMutationVariables
>;
type InsertHubspotContactListHubspotContactWrappedLazyMutationFn = ({
  hubspotContactListHubspotContact,
  autoOptimisticResponse,
  options,
}: {
  hubspotContactListHubspotContact: HubspotContactListHubspotContact_Insert_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<
      InsertHubspotContactListHubspotContactMutation,
      InsertHubspotContactListHubspotContactMutationVariables
    >,
    'variables'
  >;
}) => Promise<InsertHubspotContactListHubspotContactMutationResultEx>;
export type InsertHubspotContactListHubspotContactLazyMutationReturn = [
  InsertHubspotContactListHubspotContactWrappedLazyMutationFn,
  InsertHubspotContactListHubspotContactMutationResultEx
];

// Function
function useInsertHubspotContactListHubspotContact(
  options?: Omit<
    MutationHookOptions<
      InsertHubspotContactListHubspotContactMutation,
      InsertHubspotContactListHubspotContactMutationVariables
    >,
    'mutation' | 'variables'
  >
): InsertHubspotContactListHubspotContactLazyMutationReturn {
  const lazyMutation: InsertHubspotContactListHubspotContactLazyMutationFn = useMutation<
    InsertHubspotContactListHubspotContactMutation,
    InsertHubspotContactListHubspotContactMutationVariables
  >(InsertHubspotContactListHubspotContactDocument, options);
  const pickHubspotContactListHubspotContact: PickInsertHubspotContactListHubspotContactFn = (mutation) => {
    return (
      mutation?.insert_hubspotContactListHubspotContact?.returning &&
      mutation?.insert_hubspotContactListHubspotContact?.returning[0]
    );
  };

  const wrappedLazyMutation: InsertHubspotContactListHubspotContactWrappedLazyMutationFn = async ({
    hubspotContactListHubspotContact,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: hubspotContactListHubspotContact });
    const mutationOptions: MutationFunctionOptions<
      InsertHubspotContactListHubspotContactMutation,
      InsertHubspotContactListHubspotContactMutationVariables
    > = { variables: { objects: [objectForInsert] }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id)
        throw new Error(
          `if autoOptimisticResponse = true, id must be set in object 'hubspotContactListHubspotContact'`
        );
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertHubspotContactListHubspotContactMutation>(
        {
          operationType: 'insert',
          entityName: 'hubspotContactListHubspotContact',
          objects: [objectForInsert as HubspotContactListHubspotContact_Insert_Input & ObjectWithId],
        }
      );
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, hubspotContactListHubspotContact: pickHubspotContactListHubspotContact(fetchResult.data) };
  };

  return [
    wrappedLazyMutation,
    {
      ...lazyMutation[1],
      hubspotContactListHubspotContact: pickHubspotContactListHubspotContact(lazyMutation[1].data),
    },
  ];
}

//
//

// Types
type InsertHubspotContactListHubspotContactWithOnConflictMutationResult = FetchResult<
  InsertHubspotContactListHubspotContactWithOnConflictMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertHubspotContactListHubspotContactWithOnConflictMutationResultEx = InsertHubspotContactListHubspotContactWithOnConflictMutationResult &
  HubspotContactListHubspotContactByIdHookResultEx;

type InsertHubspotContactListHubspotContactWithOnConflictLazyMutationFn = MutationTuple<
  InsertHubspotContactListHubspotContactWithOnConflictMutation,
  InsertHubspotContactListHubspotContactWithOnConflictMutationVariables
>;
type InsertHubspotContactListHubspotContactWithOnConflictWrappedLazyMutationFn = ({
  hubspotContactListHubspotContact,
  onConflict,
  autoOptimisticResponse,
  options,
}: {
  hubspotContactListHubspotContact: HubspotContactListHubspotContact_Insert_Input;
  onConflict: HubspotContactListHubspotContact_On_Conflict;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<
      InsertHubspotContactListHubspotContactWithOnConflictMutation,
      InsertHubspotContactListHubspotContactWithOnConflictMutationVariables
    >,
    'variables'
  >;
}) => Promise<InsertHubspotContactListHubspotContactWithOnConflictMutationResultEx>;
export type InsertHubspotContactListHubspotContactWithOnConflictLazyMutationReturn = [
  InsertHubspotContactListHubspotContactWithOnConflictWrappedLazyMutationFn,
  InsertHubspotContactListHubspotContactWithOnConflictMutationResultEx
];

// Function
function useInsertHubspotContactListHubspotContactWithOnConflict(
  options?: Omit<
    MutationHookOptions<
      InsertHubspotContactListHubspotContactWithOnConflictMutation,
      InsertHubspotContactListHubspotContactWithOnConflictMutationVariables
    >,
    'mutation' | 'variables'
  >
): InsertHubspotContactListHubspotContactWithOnConflictLazyMutationReturn {
  const lazyMutation: InsertHubspotContactListHubspotContactWithOnConflictLazyMutationFn = useMutation<
    InsertHubspotContactListHubspotContactWithOnConflictMutation,
    InsertHubspotContactListHubspotContactWithOnConflictMutationVariables
  >(InsertHubspotContactListHubspotContactWithOnConflictDocument, options);
  const pickHubspotContactListHubspotContact: PickInsertHubspotContactListHubspotContactFn = (
    mutation: InsertHubspotContactListHubspotContactWithOnConflictMutation | null | undefined
  ) => {
    return (
      mutation?.insert_hubspotContactListHubspotContact?.returning &&
      mutation.insert_hubspotContactListHubspotContact.returning[0]
    );
  };

  const wrappedLazyMutation: InsertHubspotContactListHubspotContactWithOnConflictWrappedLazyMutationFn = async ({
    hubspotContactListHubspotContact,
    onConflict,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: hubspotContactListHubspotContact });
    const mutationOptions: MutationFunctionOptions<
      InsertHubspotContactListHubspotContactWithOnConflictMutation,
      InsertHubspotContactListHubspotContactWithOnConflictMutationVariables
    > = { variables: { objects: [objectForInsert], onConflict }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id)
        throw new Error(
          `if autoOptimisticResponse = true, id must be set in object 'hubspotContactListHubspotContact'`
        );
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertHubspotContactListHubspotContactWithOnConflictMutation>(
        {
          operationType: 'insert',
          entityName: 'hubspotContactListHubspotContact',
          objects: [objectForInsert as HubspotContactListHubspotContact_Insert_Input & ObjectWithId],
        }
      );
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, hubspotContactListHubspotContact: pickHubspotContactListHubspotContact(fetchResult.data) };
  };

  return [
    wrappedLazyMutation,
    {
      ...lazyMutation[1],
      hubspotContactListHubspotContact: pickHubspotContactListHubspotContact(lazyMutation[1].data),
    },
  ];
}

// Types
type InsertHubspotContactListHubspotContactObjectsMutationResult = FetchResult<
  InsertHubspotContactListHubspotContactMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertHubspotContactListHubspotContactObjectsMutationResultEx = InsertHubspotContactListHubspotContactMutationResult &
  HubspotContactListHubspotContactObjectsHookResultEx;

type PickInsertHubspotContactListHubspotContactObjectsFn = (
  mutation: InsertHubspotContactListHubspotContactMutation | null | undefined
) => HubspotContactListHubspotContactFragment[];
type InsertHubspotContactListHubspotContactObjectsLazyMutationFn = MutationTuple<
  InsertHubspotContactListHubspotContactMutation,
  InsertHubspotContactListHubspotContactMutationVariables
>;
type InsertHubspotContactListHubspotContactObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<
    InsertHubspotContactListHubspotContactMutation,
    InsertHubspotContactListHubspotContactMutationVariables
  >
) => Promise<InsertHubspotContactListHubspotContactObjectsMutationResultEx>;
export type InsertHubspotContactListHubspotContactObjectsLazyMutationReturn = [
  InsertHubspotContactListHubspotContactObjectsWrappedLazyMutationFn,
  InsertHubspotContactListHubspotContactObjectsMutationResultEx
];

// Function
function useInsertHubspotContactListHubspotContactObjects(
  options?: Omit<
    MutationHookOptions<
      InsertHubspotContactListHubspotContactMutation,
      InsertHubspotContactListHubspotContactMutationVariables
    >,
    'mutation'
  >
): InsertHubspotContactListHubspotContactObjectsLazyMutationReturn {
  const lazyMutation: InsertHubspotContactListHubspotContactObjectsLazyMutationFn = useMutation<
    InsertHubspotContactListHubspotContactMutation,
    InsertHubspotContactListHubspotContactMutationVariables
  >(InsertHubspotContactListHubspotContactDocument, options);
  const pickObjects: PickInsertHubspotContactListHubspotContactObjectsFn = (
    mutation: InsertHubspotContactListHubspotContactMutation | null | undefined
  ) => {
    return mutation?.insert_hubspotContactListHubspotContact?.returning || [];
  };

  const wrappedLazyMutation: InsertHubspotContactListHubspotContactObjectsWrappedLazyMutationFn = async (options) => {
    if (options && options.variables && options.variables.objects)
      options.variables.objects = options.variables.objects.map((objectItem) =>
        stripInsertInputClientFields({ input: objectItem })
      );
    const fetchResult: InsertHubspotContactListHubspotContactObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Update Hooks
 */

type UpdateHubspotContactListHubspotContactByIdMutationResult = FetchResult<
  UpdateHubspotContactListHubspotContactByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateHubspotContactListHubspotContactByIdMutationResultEx = UpdateHubspotContactListHubspotContactByIdMutationResult &
  HubspotContactListHubspotContactByIdHookResultEx;

type PickUpdateHubspotContactListHubspotContactByIdFn = (
  mutation: UpdateHubspotContactListHubspotContactByIdMutation | null | undefined
) => HubspotContactListHubspotContactFragment | null | undefined;
type UpdateHubspotContactListHubspotContactByIdLazyMutationFn = MutationTuple<
  UpdateHubspotContactListHubspotContactByIdMutation,
  UpdateHubspotContactListHubspotContactByIdMutationVariables
>;
type UpdateHubspotContactListHubspotContactByIdWrappedLazyMutationFn = ({
  hubspotContactListHubspotContactId,
  set,
  autoOptimisticResponse,
  options,
}: {
  hubspotContactListHubspotContactId: string;
  set: HubspotContactListHubspotContact_Set_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<
      UpdateHubspotContactListHubspotContactByIdMutation,
      UpdateHubspotContactListHubspotContactByIdMutationVariables
    >,
    'variables'
  >;
}) => Promise<UpdateHubspotContactListHubspotContactByIdMutationResultEx>;
export type UpdateHubspotContactListHubspotContactByIdLazyMutationReturn = [
  UpdateHubspotContactListHubspotContactByIdWrappedLazyMutationFn,
  UpdateHubspotContactListHubspotContactByIdMutationResultEx
];

function useUpdateHubspotContactListHubspotContactById(
  options?: Omit<
    MutationHookOptions<
      UpdateHubspotContactListHubspotContactByIdMutation,
      UpdateHubspotContactListHubspotContactByIdMutationVariables
    >,
    'mutation' | 'variables'
  >
): UpdateHubspotContactListHubspotContactByIdLazyMutationReturn {
  const lazyMutation: UpdateHubspotContactListHubspotContactByIdLazyMutationFn = useMutation<
    UpdateHubspotContactListHubspotContactByIdMutation,
    UpdateHubspotContactListHubspotContactByIdMutationVariables
  >(UpdateHubspotContactListHubspotContactByIdDocument, options);

  const pickHubspotContactListHubspotContact: PickUpdateHubspotContactListHubspotContactByIdFn = (mutation) => {
    return (
      mutation?.update_hubspotContactListHubspotContact?.returning &&
      mutation.update_hubspotContactListHubspotContact!.returning[0]
    );
  };

  const wrappedLazyMutation: UpdateHubspotContactListHubspotContactByIdWrappedLazyMutationFn = async ({
    hubspotContactListHubspotContactId,
    set,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<
      UpdateHubspotContactListHubspotContactByIdMutation,
      UpdateHubspotContactListHubspotContactByIdMutationVariables
    > = { variables: { id: hubspotContactListHubspotContactId, set }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateHubspotContactListHubspotContactByIdMutation>(
        {
          operationType: 'update',
          entityName: 'hubspotContactListHubspotContact',
          objects: [{ id: hubspotContactListHubspotContactId, ...set }],
        }
      );
    }

    const fetchResult: UpdateHubspotContactListHubspotContactByIdMutationResult = await lazyMutation[0]({
      variables: { id: hubspotContactListHubspotContactId, set },
      ...mutationOptions,
    });
    return { ...fetchResult, hubspotContactListHubspotContact: pickHubspotContactListHubspotContact(fetchResult.data) };
  };

  return [
    wrappedLazyMutation,
    {
      ...lazyMutation[1],
      hubspotContactListHubspotContact: pickHubspotContactListHubspotContact(lazyMutation[1].data),
    },
  ];
}

//
//

// Types
type UpdateHubspotContactListHubspotContactObjectsMutationResult = FetchResult<
  UpdateHubspotContactListHubspotContactMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateHubspotContactListHubspotContactObjectsMutationResultEx = UpdateHubspotContactListHubspotContactObjectsMutationResult &
  HubspotContactListHubspotContactObjectsHookResultEx;

// Function
type PickUpdateHubspotContactListHubspotContactObjectsFn = (
  mutation: UpdateHubspotContactListHubspotContactMutation | null | undefined
) => HubspotContactListHubspotContactFragment[];
type UpdateHubspotContactListHubspotContactObjectsLazyMutationFn = MutationTuple<
  UpdateHubspotContactListHubspotContactMutation,
  UpdateHubspotContactListHubspotContactMutationVariables
>;
type UpdateHubspotContactListHubspotContactObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<
    UpdateHubspotContactListHubspotContactMutation,
    UpdateHubspotContactListHubspotContactMutationVariables
  >
) => Promise<UpdateHubspotContactListHubspotContactObjectsMutationResultEx>;
export type UpdateHubspotContactListHubspotContactObjectsLazyMutationReturn = [
  UpdateHubspotContactListHubspotContactObjectsWrappedLazyMutationFn,
  UpdateHubspotContactListHubspotContactObjectsMutationResultEx
];

function useUpdateHubspotContactListHubspotContactObjects(
  options?: Omit<
    MutationHookOptions<
      UpdateHubspotContactListHubspotContactMutation,
      UpdateHubspotContactListHubspotContactMutationVariables
    >,
    'mutation'
  >
): UpdateHubspotContactListHubspotContactObjectsLazyMutationReturn {
  const lazyMutation: UpdateHubspotContactListHubspotContactObjectsLazyMutationFn = useMutation<
    UpdateHubspotContactListHubspotContactMutation,
    UpdateHubspotContactListHubspotContactMutationVariables
  >(UpdateHubspotContactListHubspotContactDocument, options);

  const pickObjects: PickUpdateHubspotContactListHubspotContactObjectsFn = (
    mutation: UpdateHubspotContactListHubspotContactMutation | null | undefined
  ) => {
    return mutation?.update_hubspotContactListHubspotContact?.returning || [];
  };

  const wrappedLazyMutation: UpdateHubspotContactListHubspotContactObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: UpdateHubspotContactListHubspotContactObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Delete Hooks
 */

// Types
type RemoveHubspotContactListHubspotContactModelByIdFetchResult = FetchResult<
  RemoveHubspotContactListHubspotContactModelByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveHubspotContactListHubspotContactModelByIdMutationResultEx = RemoveHubspotContactListHubspotContactModelByIdFetchResult &
  RemoveEntitiesQueryHookResultEx;

// Function
type PickRemoveHubspotContactListHubspotContactModelFn = (
  mutation: RemoveHubspotContactListHubspotContactModelByIdMutation | null | undefined
) => number;
type RemoveHubspotContactListHubspotContactModelLazyMutationFn = MutationTuple<
  RemoveHubspotContactListHubspotContactModelByIdMutation,
  RemoveHubspotContactListHubspotContactModelByIdMutationVariables
>;
type RemoveHubspotContactListHubspotContactModelWrappedLazyMutationFn = ({
  hubspotContactListHubspotContactId,
  autoOptimisticResponse,
  options,
}: {
  hubspotContactListHubspotContactId: string;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<
      RemoveHubspotContactListHubspotContactModelByIdMutation,
      RemoveHubspotContactListHubspotContactModelByIdMutationVariables
    >,
    'variables'
  >;
}) => Promise<RemoveHubspotContactListHubspotContactModelByIdMutationResultEx>;
export type RemoveHubspotContactListHubspotContactModelLazyMutationReturn = [
  RemoveHubspotContactListHubspotContactModelWrappedLazyMutationFn,
  RemoveHubspotContactListHubspotContactModelByIdMutationResultEx
];

function useRemoveHubspotContactListHubspotContactModelById(
  options?: Omit<
    MutationHookOptions<
      RemoveHubspotContactListHubspotContactModelByIdMutation,
      RemoveHubspotContactListHubspotContactModelByIdMutationVariables
    >,
    'mutation' | 'variables'
  >
) {
  const lazyMutation: RemoveHubspotContactListHubspotContactModelLazyMutationFn = useMutation<
    RemoveHubspotContactListHubspotContactModelByIdMutation,
    RemoveHubspotContactListHubspotContactModelByIdMutationVariables
  >(RemoveHubspotContactListHubspotContactModelByIdDocument, options);

  const pickAffectedRows: PickRemoveHubspotContactListHubspotContactModelFn = (
    mutation: RemoveHubspotContactListHubspotContactModelByIdMutation | null | undefined
  ) => {
    return mutation?.delete_hubspotContactListHubspotContact?.affected_rows || 0;
  };

  const wrappedLazyMutation: RemoveHubspotContactListHubspotContactModelWrappedLazyMutationFn = async ({
    hubspotContactListHubspotContactId,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<
      RemoveHubspotContactListHubspotContactModelMutation,
      RemoveHubspotContactListHubspotContactModelByIdMutationVariables
    > = { variables: { id: hubspotContactListHubspotContactId }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<RemoveHubspotContactListHubspotContactModelMutation>(
        {
          operationType: 'delete',
          entityName: 'hubspotContactListHubspotContact',
          objects: [{ id: hubspotContactListHubspotContactId }],
        }
      );
    }
    if (!options || !options.update) {
      mutationOptions.update = generateUpdateFunctionForMutation<RemoveHubspotContactListHubspotContactModelByIdMutation>(
        {
          operationType: 'delete',
          entityName: 'hubspotContactListHubspotContact',
          entityId: hubspotContactListHubspotContactId,
        }
      );
    }

    const fetchResult: RemoveHubspotContactListHubspotContactModelByIdFetchResult = await lazyMutation[0](
      mutationOptions
    );
    return { ...fetchResult, affected_rows: pickAffectedRows(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], affected_rows: pickAffectedRows(lazyMutation[1].data) }] as [
    typeof wrappedLazyMutation,
    typeof lazyMutation[1] & { affected_rows: ReturnType<typeof pickAffectedRows> }
  ];
}

//
//

// Types
type RemoveHubspotContactListHubspotContactModelObjectsMutationResult = FetchResult<
  RemoveHubspotContactListHubspotContactModelMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveHubspotContactListHubspotContactModelObjectsMutationResultEx = RemoveHubspotContactListHubspotContactModelObjectsMutationResult &
  RemoveEntitiesQueryHookResultEx;

// Function
type PickRemoveHubspotContactListHubspotContactModelObjectsFn = (
  mutation: RemoveHubspotContactListHubspotContactModelMutation | null | undefined
) => number;
type RemoveHubspotContactListHubspotContactModelObjectsLazyMutationFn = MutationTuple<
  RemoveHubspotContactListHubspotContactModelMutation,
  RemoveHubspotContactListHubspotContactModelMutationVariables
>;
type RemoveHubspotContactListHubspotContactModelObjectsWrappedLazyMutationFn = (
  options: MutationFunctionOptions<
    RemoveHubspotContactListHubspotContactModelMutation,
    RemoveHubspotContactListHubspotContactModelMutationVariables
  >
) => Promise<RemoveHubspotContactListHubspotContactModelObjectsMutationResultEx>;
export type RemoveHubspotContactListHubspotContactModelObjectsLazyMutationReturn = [
  RemoveHubspotContactListHubspotContactModelObjectsWrappedLazyMutationFn,
  RemoveHubspotContactListHubspotContactModelObjectsMutationResultEx
];

function useRemoveHubspotContactListHubspotContactModelObjects(
  options?: Omit<
    MutationHookOptions<
      RemoveHubspotContactListHubspotContactModelMutation,
      RemoveHubspotContactListHubspotContactModelMutationVariables
    >,
    'mutation'
  >
): RemoveHubspotContactListHubspotContactModelObjectsLazyMutationReturn {
  const lazyMutation = useMutation<
    RemoveHubspotContactListHubspotContactModelMutation,
    RemoveHubspotContactListHubspotContactModelMutationVariables
  >(RemoveHubspotContactListHubspotContactModelDocument, options);

  const pickAffectedRows: PickRemoveHubspotContactListHubspotContactModelObjectsFn = (
    mutation: RemoveHubspotContactListHubspotContactModelMutation | null | undefined
  ) => {
    return mutation?.delete_hubspotContactListHubspotContact?.affected_rows || 0;
  };

  const wrappedLazyMutation: RemoveHubspotContactListHubspotContactModelObjectsWrappedLazyMutationFn = async (
    options
  ) => {
    const fetchResult: RemoveHubspotContactListHubspotContactModelObjectsMutationResult = await lazyMutation[0](
      options
    );
    return { ...fetchResult, affected_rows: pickAffectedRows(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], affected_rows: pickAffectedRows(lazyMutation[1].data) }];
}

// HubspotContactListHubspotContact Fragment Helper Object
//------------------------------------------------

export const HubspotContactListHubspotContactFragmentGQLHooks = {
  useQueryById: useQueryHubspotContactListHubspotContactById,
  useQueryByIdLazy: useQueryHubspotContactListHubspotContactByIdLazy,
  useQueryObjects: useQueryHubspotContactListHubspotContactObjects,
  useQueryObjectsLazy: useQueryHubspotContactListHubspotContactObjectsLazy,
  useSubscribeToById: useSubscribeToHubspotContactListHubspotContactById,
  useSubscribeToObjects: useSubscribeToHubspotContactListHubspotContactObjects,
  useInsert: useInsertHubspotContactListHubspotContact,
  useInsertWithOnConflict: useInsertHubspotContactListHubspotContactWithOnConflict,
  useInsertObjects: useInsertHubspotContactListHubspotContactObjects,
  useUpdateById: useUpdateHubspotContactListHubspotContactById,
  useUpdateObjects: useUpdateHubspotContactListHubspotContactObjects,
};

// hubspotContactListHubspotContact MODEL HOOKS OBJECT
//------------------------------------------------

export const HubspotContactListHubspotContactModelGQLHooks = {
  useRemoveById: useRemoveHubspotContactListHubspotContactModelById,
  useRemoveObjects: useRemoveHubspotContactListHubspotContactModelObjects,
};

// hubspotContactProperty REACT
//------------------------------------------------

export type HubspotContactPropertyByIdHookResultEx = {
  hubspotContactProperty: HubspotContactPropertyFragment | null | undefined;
};
export type HubspotContactPropertyObjectsHookResultEx = { objects: HubspotContactPropertyFragment[] };

/**
 *  Query Hook
 */

// Types
type QueryHubspotContactPropertyByIdResult = LazyQueryResult<
  QueryHubspotContactPropertyByIdQuery,
  QueryHubspotContactPropertyByIdQueryVariables
>;
type QueryHubspotContactPropertyByIdSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<
          QueryHubspotContactPropertyByIdQuery,
          QueryHubspotContactPropertyByIdQueryVariables,
          QueryHubspotContactPropertyByIdQuery
        >,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryHubspotContactPropertyByIdResultEx = Omit<QueryHubspotContactPropertyByIdResult, 'subscribeToMore'> & {
  subscribeToMore: QueryHubspotContactPropertyByIdSubScribeToMore;
} & HubspotContactPropertyByIdHookResultEx;

// Function
function useQueryHubspotContactPropertyById({
  hubspotContactPropertyId,
  options,
}: {
  hubspotContactPropertyId: string;
  options?: Omit<
    QueryHookOptions<QueryHubspotContactPropertyByIdQuery, QueryHubspotContactPropertyByIdQueryVariables>,
    'query' | 'variables'
  >;
}): QueryHubspotContactPropertyByIdResultEx {
  const _query: QueryHubspotContactPropertyByIdResult = useQuery<
    QueryHubspotContactPropertyByIdQuery,
    QueryHubspotContactPropertyByIdQueryVariables
  >(QueryHubspotContactPropertyByIdDocument, { variables: { hubspotContactPropertyId }, ...options });

  const typedSubscribeToMore: QueryHubspotContactPropertyByIdSubScribeToMore = (options) => {
    _query.subscribeToMore({
      document: QueryHubspotContactPropertyByIdDocument,
      variables: { hubspotContactPropertyId } as QueryHubspotContactPropertyByIdQueryVariables,
      ...options,
    });
  };
  const { subscribeToMore, ...query } = _query;
  return {
    ...query,
    subscribeToMore: typedSubscribeToMore,
    hubspotContactProperty: query?.data?.hubspotContactProperty_by_pk,
  };
}

/**
 *  Lazy Query Hook
 */

// Types
type PickQueryHubspotContactPropertyByIdFn = (
  query: QueryHubspotContactPropertyByIdQuery | null | undefined
) => HubspotContactPropertyFragment | null | undefined;
type QueryHubspotContactPropertyByIdLazyFn = [
  (options?: QueryLazyOptions<QueryHubspotContactPropertyByIdQueryVariables> | undefined) => void,
  QueryHubspotContactPropertyByIdResult
];
type QueryHubspotContactPropertyByIdWrappedLazyFn = (
  options: Omit<QueryLazyOptions<QueryHubspotContactPropertyByIdQueryVariables>, 'variables'>
) => void;
export type QueryHubspotContactPropertyByIdLazyReturn = [
  QueryHubspotContactPropertyByIdWrappedLazyFn,
  QueryHubspotContactPropertyByIdResultEx
];

// Function
function useQueryHubspotContactPropertyByIdLazy({
  hubspotContactPropertyId,
  options,
}: {
  hubspotContactPropertyId: string;
  options?: Omit<
    QueryHookOptions<QueryHubspotContactPropertyByIdQuery, QueryHubspotContactPropertyByIdQueryVariables>,
    'query' | 'variables'
  >;
}): QueryHubspotContactPropertyByIdLazyReturn {
  const lazyQuery: QueryHubspotContactPropertyByIdLazyFn = useLazyQuery<
    QueryHubspotContactPropertyByIdQuery,
    QueryHubspotContactPropertyByIdQueryVariables
  >(QueryHubspotContactPropertyByIdDocument, options);

  // Setting up typed version of lazyQuery
  const pickQueryHubspotContactPropertyById: PickQueryHubspotContactPropertyByIdFn = (query) => {
    return query && query.hubspotContactProperty_by_pk;
  };
  const wrappedLazyQuery: QueryHubspotContactPropertyByIdWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryHubspotContactPropertyByIdSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({
        document: QueryHubspotContactPropertyByIdDocument,
        variables: { hubspotContactPropertyId } as QueryHubspotContactPropertyByIdQueryVariables,
        ...options,
      });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    {
      ...lazyQueryResult,
      subscribeToMore: typedSubcribeToMore,
      hubspotContactProperty: pickQueryHubspotContactPropertyById(lazyQuery[1].data),
    },
  ];
}

/**
 *  Query Collection Hook
 */

// Types
export type QueryHubspotContactPropertyObjectsResult = LazyQueryResult<
  QueryHubspotContactPropertyObjectsQuery,
  QueryHubspotContactPropertyObjectsQueryVariables
>;
type QueryHubspotContactPropertyObjectsSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<
          QueryHubspotContactPropertyObjectsQuery,
          QueryHubspotContactPropertyObjectsQueryVariables,
          QueryHubspotContactPropertyObjectsQuery
        >,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryHubspotContactPropertyObjectsResultEx = Omit<
  QueryHubspotContactPropertyObjectsResult,
  'subscribeToMore'
> & { subscribeToMore: QueryHubspotContactPropertyObjectsSubScribeToMore } & HubspotContactPropertyObjectsHookResultEx;

// Function
function useQueryHubspotContactPropertyObjects(
  options: Omit<
    QueryHookOptions<QueryHubspotContactPropertyObjectsQuery, QueryHubspotContactPropertyObjectsQueryVariables>,
    'query'
  >
): QueryHubspotContactPropertyObjectsResultEx {
  const _query: QueryHubspotContactPropertyObjectsResult = useQuery<
    QueryHubspotContactPropertyObjectsQuery,
    QueryHubspotContactPropertyObjectsQueryVariables
  >(QueryHubspotContactPropertyObjectsDocument, options);

  const typedSubscribeToMore: QueryHubspotContactPropertyObjectsSubScribeToMore = (options) => {
    _query.subscribeToMore({ document: QueryHubspotContactPropertyObjectsDocument, ...options });
  };
  const { subscribeToMore, ...query } = _query;

  return { ...query, subscribeToMore: typedSubscribeToMore, objects: query?.data?.hubspotContactProperty || [] };
}

/**
 *  Lazy Query Collection Hook
 */

// Types
type PickQueryHubspotContactPropertyObjectsFn = (
  query: QueryHubspotContactPropertyObjectsQuery | null | undefined
) => HubspotContactPropertyFragment[];
type QueryHubspotContactPropertyObjectsLazyFn = [
  (options?: QueryLazyOptions<QueryHubspotContactPropertyObjectsQueryVariables> | undefined) => void,
  QueryHubspotContactPropertyObjectsResult
];
type QueryHubspotContactPropertyObjectsWrappedLazyFn = (
  options?: QueryLazyOptions<QueryHubspotContactPropertyObjectsQueryVariables>
) => void;
export type QueryHubspotContactPropertyObjectsLazyReturn = [
  QueryHubspotContactPropertyObjectsWrappedLazyFn,
  QueryHubspotContactPropertyObjectsResultEx
];

// Function
function useQueryHubspotContactPropertyObjectsLazy(
  options?: Omit<
    LazyQueryHookOptions<QueryHubspotContactPropertyObjectsQuery, QueryHubspotContactPropertyObjectsQueryVariables>,
    'query'
  >
): QueryHubspotContactPropertyObjectsLazyReturn {
  const lazyQuery: QueryHubspotContactPropertyObjectsLazyFn = useLazyQuery<
    QueryHubspotContactPropertyObjectsQuery,
    QueryHubspotContactPropertyObjectsQueryVariables
  >(QueryHubspotContactPropertyObjectsDocument, options);

  // Setting up typed version of lazyQuery
  const pickObjects: PickQueryHubspotContactPropertyObjectsFn = (
    query: QueryHubspotContactPropertyObjectsQuery | null | undefined
  ) => {
    return query?.hubspotContactProperty || [];
  };
  const wrappedLazyQuery: QueryHubspotContactPropertyObjectsWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryHubspotContactPropertyObjectsSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({ document: QueryHubspotContactPropertyObjectsDocument, ...options });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    { ...lazyQueryResult, subscribeToMore: typedSubcribeToMore, objects: pickObjects(lazyQuery[1].data) },
  ];
}

/**
 *  Subscription Hook
 */

// Types
type SubscribeToHubspotContactPropertyByIdResult = {
  variables?: SubscribeToHubspotContactPropertyByIdSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToHubspotContactPropertyByIdSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToHubspotContactPropertyByIdResultEx = SubscribeToHubspotContactPropertyByIdResult &
  HubspotContactPropertyByIdHookResultEx;

// Function
function useSubscribeToHubspotContactPropertyById({
  hubspotContactPropertyId,
  options,
}: {
  hubspotContactPropertyId: string;
  options?: Omit<
    SubscriptionHookOptions<
      SubscribeToHubspotContactPropertyByIdSubscription,
      SubscribeToHubspotContactPropertyByIdSubscriptionVariables
    >,
    'query' | 'variables'
  >;
}): SubscribeToHubspotContactPropertyByIdResultEx {
  const subscription: SubscribeToHubspotContactPropertyByIdResult = useSubscription<
    SubscribeToHubspotContactPropertyByIdSubscription,
    SubscribeToHubspotContactPropertyByIdSubscriptionVariables
  >(SubscribeToHubspotContactPropertyByIdDocument, { variables: { hubspotContactPropertyId }, ...options });
  return { ...subscription, hubspotContactProperty: subscription?.data?.hubspotContactProperty_by_pk };
}

/**
 *  Subscription Collection Hook
 */

// Types
export type SubscribeToHubspotContactPropertyObjectsResult = {
  variables?: SubscribeToHubspotContactPropertyObjectsSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToHubspotContactPropertyObjectsSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToHubspotContactPropertyObjectsResultEx = SubscribeToHubspotContactPropertyObjectsResult &
  HubspotContactPropertyObjectsHookResultEx;

// Function
function useSubscribeToHubspotContactPropertyObjects(
  options: Omit<
    SubscriptionHookOptions<
      SubscribeToHubspotContactPropertyObjectsSubscription,
      SubscribeToHubspotContactPropertyObjectsSubscriptionVariables
    >,
    'query'
  >
): SubscribeToHubspotContactPropertyObjectsResultEx {
  const subscription: SubscribeToHubspotContactPropertyObjectsResult = useSubscription<
    SubscribeToHubspotContactPropertyObjectsSubscription,
    SubscribeToHubspotContactPropertyObjectsSubscriptionVariables
  >(SubscribeToHubspotContactPropertyObjectsDocument, options);
  return { ...subscription, objects: subscription?.data?.hubspotContactProperty || [] };
}

/**
 *  Insert Hooks
 */

// Types
type InsertHubspotContactPropertyMutationResult = FetchResult<
  InsertHubspotContactPropertyMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertHubspotContactPropertyMutationResultEx = InsertHubspotContactPropertyMutationResult &
  HubspotContactPropertyByIdHookResultEx;

type PickInsertHubspotContactPropertyFn = (
  mutation: InsertHubspotContactPropertyMutation | null | undefined
) => HubspotContactPropertyFragment | null | undefined;
type InsertHubspotContactPropertyLazyMutationFn = MutationTuple<
  InsertHubspotContactPropertyMutation,
  InsertHubspotContactPropertyMutationVariables
>;
type InsertHubspotContactPropertyWrappedLazyMutationFn = ({
  hubspotContactProperty,
  autoOptimisticResponse,
  options,
}: {
  hubspotContactProperty: HubspotContactProperty_Insert_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<InsertHubspotContactPropertyMutation, InsertHubspotContactPropertyMutationVariables>,
    'variables'
  >;
}) => Promise<InsertHubspotContactPropertyMutationResultEx>;
export type InsertHubspotContactPropertyLazyMutationReturn = [
  InsertHubspotContactPropertyWrappedLazyMutationFn,
  InsertHubspotContactPropertyMutationResultEx
];

// Function
function useInsertHubspotContactProperty(
  options?: Omit<
    MutationHookOptions<InsertHubspotContactPropertyMutation, InsertHubspotContactPropertyMutationVariables>,
    'mutation' | 'variables'
  >
): InsertHubspotContactPropertyLazyMutationReturn {
  const lazyMutation: InsertHubspotContactPropertyLazyMutationFn = useMutation<
    InsertHubspotContactPropertyMutation,
    InsertHubspotContactPropertyMutationVariables
  >(InsertHubspotContactPropertyDocument, options);
  const pickHubspotContactProperty: PickInsertHubspotContactPropertyFn = (mutation) => {
    return mutation?.insert_hubspotContactProperty?.returning && mutation?.insert_hubspotContactProperty?.returning[0];
  };

  const wrappedLazyMutation: InsertHubspotContactPropertyWrappedLazyMutationFn = async ({
    hubspotContactProperty,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: hubspotContactProperty });
    const mutationOptions: MutationFunctionOptions<
      InsertHubspotContactPropertyMutation,
      InsertHubspotContactPropertyMutationVariables
    > = { variables: { objects: [objectForInsert] }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id)
        throw new Error(`if autoOptimisticResponse = true, id must be set in object 'hubspotContactProperty'`);
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertHubspotContactPropertyMutation>({
        operationType: 'insert',
        entityName: 'hubspotContactProperty',
        objects: [objectForInsert as HubspotContactProperty_Insert_Input & ObjectWithId],
      });
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, hubspotContactProperty: pickHubspotContactProperty(fetchResult.data) };
  };

  return [
    wrappedLazyMutation,
    { ...lazyMutation[1], hubspotContactProperty: pickHubspotContactProperty(lazyMutation[1].data) },
  ];
}

//
//

// Types
type InsertHubspotContactPropertyWithOnConflictMutationResult = FetchResult<
  InsertHubspotContactPropertyWithOnConflictMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertHubspotContactPropertyWithOnConflictMutationResultEx = InsertHubspotContactPropertyWithOnConflictMutationResult &
  HubspotContactPropertyByIdHookResultEx;

type InsertHubspotContactPropertyWithOnConflictLazyMutationFn = MutationTuple<
  InsertHubspotContactPropertyWithOnConflictMutation,
  InsertHubspotContactPropertyWithOnConflictMutationVariables
>;
type InsertHubspotContactPropertyWithOnConflictWrappedLazyMutationFn = ({
  hubspotContactProperty,
  onConflict,
  autoOptimisticResponse,
  options,
}: {
  hubspotContactProperty: HubspotContactProperty_Insert_Input;
  onConflict: HubspotContactProperty_On_Conflict;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<
      InsertHubspotContactPropertyWithOnConflictMutation,
      InsertHubspotContactPropertyWithOnConflictMutationVariables
    >,
    'variables'
  >;
}) => Promise<InsertHubspotContactPropertyWithOnConflictMutationResultEx>;
export type InsertHubspotContactPropertyWithOnConflictLazyMutationReturn = [
  InsertHubspotContactPropertyWithOnConflictWrappedLazyMutationFn,
  InsertHubspotContactPropertyWithOnConflictMutationResultEx
];

// Function
function useInsertHubspotContactPropertyWithOnConflict(
  options?: Omit<
    MutationHookOptions<
      InsertHubspotContactPropertyWithOnConflictMutation,
      InsertHubspotContactPropertyWithOnConflictMutationVariables
    >,
    'mutation' | 'variables'
  >
): InsertHubspotContactPropertyWithOnConflictLazyMutationReturn {
  const lazyMutation: InsertHubspotContactPropertyWithOnConflictLazyMutationFn = useMutation<
    InsertHubspotContactPropertyWithOnConflictMutation,
    InsertHubspotContactPropertyWithOnConflictMutationVariables
  >(InsertHubspotContactPropertyWithOnConflictDocument, options);
  const pickHubspotContactProperty: PickInsertHubspotContactPropertyFn = (
    mutation: InsertHubspotContactPropertyWithOnConflictMutation | null | undefined
  ) => {
    return mutation?.insert_hubspotContactProperty?.returning && mutation.insert_hubspotContactProperty.returning[0];
  };

  const wrappedLazyMutation: InsertHubspotContactPropertyWithOnConflictWrappedLazyMutationFn = async ({
    hubspotContactProperty,
    onConflict,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: hubspotContactProperty });
    const mutationOptions: MutationFunctionOptions<
      InsertHubspotContactPropertyWithOnConflictMutation,
      InsertHubspotContactPropertyWithOnConflictMutationVariables
    > = { variables: { objects: [objectForInsert], onConflict }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id)
        throw new Error(`if autoOptimisticResponse = true, id must be set in object 'hubspotContactProperty'`);
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertHubspotContactPropertyWithOnConflictMutation>(
        {
          operationType: 'insert',
          entityName: 'hubspotContactProperty',
          objects: [objectForInsert as HubspotContactProperty_Insert_Input & ObjectWithId],
        }
      );
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, hubspotContactProperty: pickHubspotContactProperty(fetchResult.data) };
  };

  return [
    wrappedLazyMutation,
    { ...lazyMutation[1], hubspotContactProperty: pickHubspotContactProperty(lazyMutation[1].data) },
  ];
}

// Types
type InsertHubspotContactPropertyObjectsMutationResult = FetchResult<
  InsertHubspotContactPropertyMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertHubspotContactPropertyObjectsMutationResultEx = InsertHubspotContactPropertyMutationResult &
  HubspotContactPropertyObjectsHookResultEx;

type PickInsertHubspotContactPropertyObjectsFn = (
  mutation: InsertHubspotContactPropertyMutation | null | undefined
) => HubspotContactPropertyFragment[];
type InsertHubspotContactPropertyObjectsLazyMutationFn = MutationTuple<
  InsertHubspotContactPropertyMutation,
  InsertHubspotContactPropertyMutationVariables
>;
type InsertHubspotContactPropertyObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<InsertHubspotContactPropertyMutation, InsertHubspotContactPropertyMutationVariables>
) => Promise<InsertHubspotContactPropertyObjectsMutationResultEx>;
export type InsertHubspotContactPropertyObjectsLazyMutationReturn = [
  InsertHubspotContactPropertyObjectsWrappedLazyMutationFn,
  InsertHubspotContactPropertyObjectsMutationResultEx
];

// Function
function useInsertHubspotContactPropertyObjects(
  options?: Omit<
    MutationHookOptions<InsertHubspotContactPropertyMutation, InsertHubspotContactPropertyMutationVariables>,
    'mutation'
  >
): InsertHubspotContactPropertyObjectsLazyMutationReturn {
  const lazyMutation: InsertHubspotContactPropertyObjectsLazyMutationFn = useMutation<
    InsertHubspotContactPropertyMutation,
    InsertHubspotContactPropertyMutationVariables
  >(InsertHubspotContactPropertyDocument, options);
  const pickObjects: PickInsertHubspotContactPropertyObjectsFn = (
    mutation: InsertHubspotContactPropertyMutation | null | undefined
  ) => {
    return mutation?.insert_hubspotContactProperty?.returning || [];
  };

  const wrappedLazyMutation: InsertHubspotContactPropertyObjectsWrappedLazyMutationFn = async (options) => {
    if (options && options.variables && options.variables.objects)
      options.variables.objects = options.variables.objects.map((objectItem) =>
        stripInsertInputClientFields({ input: objectItem })
      );
    const fetchResult: InsertHubspotContactPropertyObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Update Hooks
 */

type UpdateHubspotContactPropertyByIdMutationResult = FetchResult<
  UpdateHubspotContactPropertyByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateHubspotContactPropertyByIdMutationResultEx = UpdateHubspotContactPropertyByIdMutationResult &
  HubspotContactPropertyByIdHookResultEx;

type PickUpdateHubspotContactPropertyByIdFn = (
  mutation: UpdateHubspotContactPropertyByIdMutation | null | undefined
) => HubspotContactPropertyFragment | null | undefined;
type UpdateHubspotContactPropertyByIdLazyMutationFn = MutationTuple<
  UpdateHubspotContactPropertyByIdMutation,
  UpdateHubspotContactPropertyByIdMutationVariables
>;
type UpdateHubspotContactPropertyByIdWrappedLazyMutationFn = ({
  hubspotContactPropertyId,
  set,
  autoOptimisticResponse,
  options,
}: {
  hubspotContactPropertyId: string;
  set: HubspotContactProperty_Set_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<
      UpdateHubspotContactPropertyByIdMutation,
      UpdateHubspotContactPropertyByIdMutationVariables
    >,
    'variables'
  >;
}) => Promise<UpdateHubspotContactPropertyByIdMutationResultEx>;
export type UpdateHubspotContactPropertyByIdLazyMutationReturn = [
  UpdateHubspotContactPropertyByIdWrappedLazyMutationFn,
  UpdateHubspotContactPropertyByIdMutationResultEx
];

function useUpdateHubspotContactPropertyById(
  options?: Omit<
    MutationHookOptions<UpdateHubspotContactPropertyByIdMutation, UpdateHubspotContactPropertyByIdMutationVariables>,
    'mutation' | 'variables'
  >
): UpdateHubspotContactPropertyByIdLazyMutationReturn {
  const lazyMutation: UpdateHubspotContactPropertyByIdLazyMutationFn = useMutation<
    UpdateHubspotContactPropertyByIdMutation,
    UpdateHubspotContactPropertyByIdMutationVariables
  >(UpdateHubspotContactPropertyByIdDocument, options);

  const pickHubspotContactProperty: PickUpdateHubspotContactPropertyByIdFn = (mutation) => {
    return mutation?.update_hubspotContactProperty?.returning && mutation.update_hubspotContactProperty!.returning[0];
  };

  const wrappedLazyMutation: UpdateHubspotContactPropertyByIdWrappedLazyMutationFn = async ({
    hubspotContactPropertyId,
    set,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<
      UpdateHubspotContactPropertyByIdMutation,
      UpdateHubspotContactPropertyByIdMutationVariables
    > = { variables: { id: hubspotContactPropertyId, set }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateHubspotContactPropertyByIdMutation>(
        {
          operationType: 'update',
          entityName: 'hubspotContactProperty',
          objects: [{ id: hubspotContactPropertyId, ...set }],
        }
      );
    }

    const fetchResult: UpdateHubspotContactPropertyByIdMutationResult = await lazyMutation[0]({
      variables: { id: hubspotContactPropertyId, set },
      ...mutationOptions,
    });
    return { ...fetchResult, hubspotContactProperty: pickHubspotContactProperty(fetchResult.data) };
  };

  return [
    wrappedLazyMutation,
    { ...lazyMutation[1], hubspotContactProperty: pickHubspotContactProperty(lazyMutation[1].data) },
  ];
}

//
//

// Types
type UpdateHubspotContactPropertyObjectsMutationResult = FetchResult<
  UpdateHubspotContactPropertyMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateHubspotContactPropertyObjectsMutationResultEx = UpdateHubspotContactPropertyObjectsMutationResult &
  HubspotContactPropertyObjectsHookResultEx;

// Function
type PickUpdateHubspotContactPropertyObjectsFn = (
  mutation: UpdateHubspotContactPropertyMutation | null | undefined
) => HubspotContactPropertyFragment[];
type UpdateHubspotContactPropertyObjectsLazyMutationFn = MutationTuple<
  UpdateHubspotContactPropertyMutation,
  UpdateHubspotContactPropertyMutationVariables
>;
type UpdateHubspotContactPropertyObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<UpdateHubspotContactPropertyMutation, UpdateHubspotContactPropertyMutationVariables>
) => Promise<UpdateHubspotContactPropertyObjectsMutationResultEx>;
export type UpdateHubspotContactPropertyObjectsLazyMutationReturn = [
  UpdateHubspotContactPropertyObjectsWrappedLazyMutationFn,
  UpdateHubspotContactPropertyObjectsMutationResultEx
];

function useUpdateHubspotContactPropertyObjects(
  options?: Omit<
    MutationHookOptions<UpdateHubspotContactPropertyMutation, UpdateHubspotContactPropertyMutationVariables>,
    'mutation'
  >
): UpdateHubspotContactPropertyObjectsLazyMutationReturn {
  const lazyMutation: UpdateHubspotContactPropertyObjectsLazyMutationFn = useMutation<
    UpdateHubspotContactPropertyMutation,
    UpdateHubspotContactPropertyMutationVariables
  >(UpdateHubspotContactPropertyDocument, options);

  const pickObjects: PickUpdateHubspotContactPropertyObjectsFn = (
    mutation: UpdateHubspotContactPropertyMutation | null | undefined
  ) => {
    return mutation?.update_hubspotContactProperty?.returning || [];
  };

  const wrappedLazyMutation: UpdateHubspotContactPropertyObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: UpdateHubspotContactPropertyObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Delete Hooks
 */

// Types
type RemoveHubspotContactPropertyModelByIdFetchResult = FetchResult<
  RemoveHubspotContactPropertyModelByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveHubspotContactPropertyModelByIdMutationResultEx = RemoveHubspotContactPropertyModelByIdFetchResult &
  RemoveEntitiesQueryHookResultEx;

// Function
type PickRemoveHubspotContactPropertyModelFn = (
  mutation: RemoveHubspotContactPropertyModelByIdMutation | null | undefined
) => number;
type RemoveHubspotContactPropertyModelLazyMutationFn = MutationTuple<
  RemoveHubspotContactPropertyModelByIdMutation,
  RemoveHubspotContactPropertyModelByIdMutationVariables
>;
type RemoveHubspotContactPropertyModelWrappedLazyMutationFn = ({
  hubspotContactPropertyId,
  autoOptimisticResponse,
  options,
}: {
  hubspotContactPropertyId: string;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<
      RemoveHubspotContactPropertyModelByIdMutation,
      RemoveHubspotContactPropertyModelByIdMutationVariables
    >,
    'variables'
  >;
}) => Promise<RemoveHubspotContactPropertyModelByIdMutationResultEx>;
export type RemoveHubspotContactPropertyModelLazyMutationReturn = [
  RemoveHubspotContactPropertyModelWrappedLazyMutationFn,
  RemoveHubspotContactPropertyModelByIdMutationResultEx
];

function useRemoveHubspotContactPropertyModelById(
  options?: Omit<
    MutationHookOptions<
      RemoveHubspotContactPropertyModelByIdMutation,
      RemoveHubspotContactPropertyModelByIdMutationVariables
    >,
    'mutation' | 'variables'
  >
) {
  const lazyMutation: RemoveHubspotContactPropertyModelLazyMutationFn = useMutation<
    RemoveHubspotContactPropertyModelByIdMutation,
    RemoveHubspotContactPropertyModelByIdMutationVariables
  >(RemoveHubspotContactPropertyModelByIdDocument, options);

  const pickAffectedRows: PickRemoveHubspotContactPropertyModelFn = (
    mutation: RemoveHubspotContactPropertyModelByIdMutation | null | undefined
  ) => {
    return mutation?.delete_hubspotContactProperty?.affected_rows || 0;
  };

  const wrappedLazyMutation: RemoveHubspotContactPropertyModelWrappedLazyMutationFn = async ({
    hubspotContactPropertyId,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<
      RemoveHubspotContactPropertyModelMutation,
      RemoveHubspotContactPropertyModelByIdMutationVariables
    > = { variables: { id: hubspotContactPropertyId }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<RemoveHubspotContactPropertyModelMutation>(
        { operationType: 'delete', entityName: 'hubspotContactProperty', objects: [{ id: hubspotContactPropertyId }] }
      );
    }
    if (!options || !options.update) {
      mutationOptions.update = generateUpdateFunctionForMutation<RemoveHubspotContactPropertyModelByIdMutation>({
        operationType: 'delete',
        entityName: 'hubspotContactProperty',
        entityId: hubspotContactPropertyId,
      });
    }

    const fetchResult: RemoveHubspotContactPropertyModelByIdFetchResult = await lazyMutation[0](mutationOptions);
    return { ...fetchResult, affected_rows: pickAffectedRows(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], affected_rows: pickAffectedRows(lazyMutation[1].data) }] as [
    typeof wrappedLazyMutation,
    typeof lazyMutation[1] & { affected_rows: ReturnType<typeof pickAffectedRows> }
  ];
}

//
//

// Types
type RemoveHubspotContactPropertyModelObjectsMutationResult = FetchResult<
  RemoveHubspotContactPropertyModelMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveHubspotContactPropertyModelObjectsMutationResultEx = RemoveHubspotContactPropertyModelObjectsMutationResult &
  RemoveEntitiesQueryHookResultEx;

// Function
type PickRemoveHubspotContactPropertyModelObjectsFn = (
  mutation: RemoveHubspotContactPropertyModelMutation | null | undefined
) => number;
type RemoveHubspotContactPropertyModelObjectsLazyMutationFn = MutationTuple<
  RemoveHubspotContactPropertyModelMutation,
  RemoveHubspotContactPropertyModelMutationVariables
>;
type RemoveHubspotContactPropertyModelObjectsWrappedLazyMutationFn = (
  options: MutationFunctionOptions<
    RemoveHubspotContactPropertyModelMutation,
    RemoveHubspotContactPropertyModelMutationVariables
  >
) => Promise<RemoveHubspotContactPropertyModelObjectsMutationResultEx>;
export type RemoveHubspotContactPropertyModelObjectsLazyMutationReturn = [
  RemoveHubspotContactPropertyModelObjectsWrappedLazyMutationFn,
  RemoveHubspotContactPropertyModelObjectsMutationResultEx
];

function useRemoveHubspotContactPropertyModelObjects(
  options?: Omit<
    MutationHookOptions<RemoveHubspotContactPropertyModelMutation, RemoveHubspotContactPropertyModelMutationVariables>,
    'mutation'
  >
): RemoveHubspotContactPropertyModelObjectsLazyMutationReturn {
  const lazyMutation = useMutation<
    RemoveHubspotContactPropertyModelMutation,
    RemoveHubspotContactPropertyModelMutationVariables
  >(RemoveHubspotContactPropertyModelDocument, options);

  const pickAffectedRows: PickRemoveHubspotContactPropertyModelObjectsFn = (
    mutation: RemoveHubspotContactPropertyModelMutation | null | undefined
  ) => {
    return mutation?.delete_hubspotContactProperty?.affected_rows || 0;
  };

  const wrappedLazyMutation: RemoveHubspotContactPropertyModelObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: RemoveHubspotContactPropertyModelObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, affected_rows: pickAffectedRows(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], affected_rows: pickAffectedRows(lazyMutation[1].data) }];
}

// HubspotContactProperty Fragment Helper Object
//------------------------------------------------

export const HubspotContactPropertyFragmentGQLHooks = {
  useQueryById: useQueryHubspotContactPropertyById,
  useQueryByIdLazy: useQueryHubspotContactPropertyByIdLazy,
  useQueryObjects: useQueryHubspotContactPropertyObjects,
  useQueryObjectsLazy: useQueryHubspotContactPropertyObjectsLazy,
  useSubscribeToById: useSubscribeToHubspotContactPropertyById,
  useSubscribeToObjects: useSubscribeToHubspotContactPropertyObjects,
  useInsert: useInsertHubspotContactProperty,
  useInsertWithOnConflict: useInsertHubspotContactPropertyWithOnConflict,
  useInsertObjects: useInsertHubspotContactPropertyObjects,
  useUpdateById: useUpdateHubspotContactPropertyById,
  useUpdateObjects: useUpdateHubspotContactPropertyObjects,
};

// hubspotContactProperty MODEL HOOKS OBJECT
//------------------------------------------------

export const HubspotContactPropertyModelGQLHooks = {
  useRemoveById: useRemoveHubspotContactPropertyModelById,
  useRemoveObjects: useRemoveHubspotContactPropertyModelObjects,
};

// salesforceContactField REACT
//------------------------------------------------

export type SalesforceContactFieldByIdHookResultEx = {
  salesforceContactField: SalesforceContactFieldFragment | null | undefined;
};
export type SalesforceContactFieldObjectsHookResultEx = { objects: SalesforceContactFieldFragment[] };

/**
 *  Query Hook
 */

// Types
type QuerySalesforceContactFieldByIdResult = LazyQueryResult<
  QuerySalesforceContactFieldByIdQuery,
  QuerySalesforceContactFieldByIdQueryVariables
>;
type QuerySalesforceContactFieldByIdSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<
          QuerySalesforceContactFieldByIdQuery,
          QuerySalesforceContactFieldByIdQueryVariables,
          QuerySalesforceContactFieldByIdQuery
        >,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QuerySalesforceContactFieldByIdResultEx = Omit<QuerySalesforceContactFieldByIdResult, 'subscribeToMore'> & {
  subscribeToMore: QuerySalesforceContactFieldByIdSubScribeToMore;
} & SalesforceContactFieldByIdHookResultEx;

// Function
function useQuerySalesforceContactFieldById({
  salesforceContactFieldId,
  options,
}: {
  salesforceContactFieldId: string;
  options?: Omit<
    QueryHookOptions<QuerySalesforceContactFieldByIdQuery, QuerySalesforceContactFieldByIdQueryVariables>,
    'query' | 'variables'
  >;
}): QuerySalesforceContactFieldByIdResultEx {
  const _query: QuerySalesforceContactFieldByIdResult = useQuery<
    QuerySalesforceContactFieldByIdQuery,
    QuerySalesforceContactFieldByIdQueryVariables
  >(QuerySalesforceContactFieldByIdDocument, { variables: { salesforceContactFieldId }, ...options });

  const typedSubscribeToMore: QuerySalesforceContactFieldByIdSubScribeToMore = (options) => {
    _query.subscribeToMore({
      document: QuerySalesforceContactFieldByIdDocument,
      variables: { salesforceContactFieldId } as QuerySalesforceContactFieldByIdQueryVariables,
      ...options,
    });
  };
  const { subscribeToMore, ...query } = _query;
  return {
    ...query,
    subscribeToMore: typedSubscribeToMore,
    salesforceContactField: query?.data?.salesforceContactField_by_pk,
  };
}

/**
 *  Lazy Query Hook
 */

// Types
type PickQuerySalesforceContactFieldByIdFn = (
  query: QuerySalesforceContactFieldByIdQuery | null | undefined
) => SalesforceContactFieldFragment | null | undefined;
type QuerySalesforceContactFieldByIdLazyFn = [
  (options?: QueryLazyOptions<QuerySalesforceContactFieldByIdQueryVariables> | undefined) => void,
  QuerySalesforceContactFieldByIdResult
];
type QuerySalesforceContactFieldByIdWrappedLazyFn = (
  options: Omit<QueryLazyOptions<QuerySalesforceContactFieldByIdQueryVariables>, 'variables'>
) => void;
export type QuerySalesforceContactFieldByIdLazyReturn = [
  QuerySalesforceContactFieldByIdWrappedLazyFn,
  QuerySalesforceContactFieldByIdResultEx
];

// Function
function useQuerySalesforceContactFieldByIdLazy({
  salesforceContactFieldId,
  options,
}: {
  salesforceContactFieldId: string;
  options?: Omit<
    QueryHookOptions<QuerySalesforceContactFieldByIdQuery, QuerySalesforceContactFieldByIdQueryVariables>,
    'query' | 'variables'
  >;
}): QuerySalesforceContactFieldByIdLazyReturn {
  const lazyQuery: QuerySalesforceContactFieldByIdLazyFn = useLazyQuery<
    QuerySalesforceContactFieldByIdQuery,
    QuerySalesforceContactFieldByIdQueryVariables
  >(QuerySalesforceContactFieldByIdDocument, options);

  // Setting up typed version of lazyQuery
  const pickQuerySalesforceContactFieldById: PickQuerySalesforceContactFieldByIdFn = (query) => {
    return query && query.salesforceContactField_by_pk;
  };
  const wrappedLazyQuery: QuerySalesforceContactFieldByIdWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QuerySalesforceContactFieldByIdSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({
        document: QuerySalesforceContactFieldByIdDocument,
        variables: { salesforceContactFieldId } as QuerySalesforceContactFieldByIdQueryVariables,
        ...options,
      });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    {
      ...lazyQueryResult,
      subscribeToMore: typedSubcribeToMore,
      salesforceContactField: pickQuerySalesforceContactFieldById(lazyQuery[1].data),
    },
  ];
}

/**
 *  Query Collection Hook
 */

// Types
export type QuerySalesforceContactFieldObjectsResult = LazyQueryResult<
  QuerySalesforceContactFieldObjectsQuery,
  QuerySalesforceContactFieldObjectsQueryVariables
>;
type QuerySalesforceContactFieldObjectsSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<
          QuerySalesforceContactFieldObjectsQuery,
          QuerySalesforceContactFieldObjectsQueryVariables,
          QuerySalesforceContactFieldObjectsQuery
        >,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QuerySalesforceContactFieldObjectsResultEx = Omit<
  QuerySalesforceContactFieldObjectsResult,
  'subscribeToMore'
> & { subscribeToMore: QuerySalesforceContactFieldObjectsSubScribeToMore } & SalesforceContactFieldObjectsHookResultEx;

// Function
function useQuerySalesforceContactFieldObjects(
  options: Omit<
    QueryHookOptions<QuerySalesforceContactFieldObjectsQuery, QuerySalesforceContactFieldObjectsQueryVariables>,
    'query'
  >
): QuerySalesforceContactFieldObjectsResultEx {
  const _query: QuerySalesforceContactFieldObjectsResult = useQuery<
    QuerySalesforceContactFieldObjectsQuery,
    QuerySalesforceContactFieldObjectsQueryVariables
  >(QuerySalesforceContactFieldObjectsDocument, options);

  const typedSubscribeToMore: QuerySalesforceContactFieldObjectsSubScribeToMore = (options) => {
    _query.subscribeToMore({ document: QuerySalesforceContactFieldObjectsDocument, ...options });
  };
  const { subscribeToMore, ...query } = _query;

  return { ...query, subscribeToMore: typedSubscribeToMore, objects: query?.data?.salesforceContactField || [] };
}

/**
 *  Lazy Query Collection Hook
 */

// Types
type PickQuerySalesforceContactFieldObjectsFn = (
  query: QuerySalesforceContactFieldObjectsQuery | null | undefined
) => SalesforceContactFieldFragment[];
type QuerySalesforceContactFieldObjectsLazyFn = [
  (options?: QueryLazyOptions<QuerySalesforceContactFieldObjectsQueryVariables> | undefined) => void,
  QuerySalesforceContactFieldObjectsResult
];
type QuerySalesforceContactFieldObjectsWrappedLazyFn = (
  options?: QueryLazyOptions<QuerySalesforceContactFieldObjectsQueryVariables>
) => void;
export type QuerySalesforceContactFieldObjectsLazyReturn = [
  QuerySalesforceContactFieldObjectsWrappedLazyFn,
  QuerySalesforceContactFieldObjectsResultEx
];

// Function
function useQuerySalesforceContactFieldObjectsLazy(
  options?: Omit<
    LazyQueryHookOptions<QuerySalesforceContactFieldObjectsQuery, QuerySalesforceContactFieldObjectsQueryVariables>,
    'query'
  >
): QuerySalesforceContactFieldObjectsLazyReturn {
  const lazyQuery: QuerySalesforceContactFieldObjectsLazyFn = useLazyQuery<
    QuerySalesforceContactFieldObjectsQuery,
    QuerySalesforceContactFieldObjectsQueryVariables
  >(QuerySalesforceContactFieldObjectsDocument, options);

  // Setting up typed version of lazyQuery
  const pickObjects: PickQuerySalesforceContactFieldObjectsFn = (
    query: QuerySalesforceContactFieldObjectsQuery | null | undefined
  ) => {
    return query?.salesforceContactField || [];
  };
  const wrappedLazyQuery: QuerySalesforceContactFieldObjectsWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QuerySalesforceContactFieldObjectsSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({ document: QuerySalesforceContactFieldObjectsDocument, ...options });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    { ...lazyQueryResult, subscribeToMore: typedSubcribeToMore, objects: pickObjects(lazyQuery[1].data) },
  ];
}

/**
 *  Subscription Hook
 */

// Types
type SubscribeToSalesforceContactFieldByIdResult = {
  variables?: SubscribeToSalesforceContactFieldByIdSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToSalesforceContactFieldByIdSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToSalesforceContactFieldByIdResultEx = SubscribeToSalesforceContactFieldByIdResult &
  SalesforceContactFieldByIdHookResultEx;

// Function
function useSubscribeToSalesforceContactFieldById({
  salesforceContactFieldId,
  options,
}: {
  salesforceContactFieldId: string;
  options?: Omit<
    SubscriptionHookOptions<
      SubscribeToSalesforceContactFieldByIdSubscription,
      SubscribeToSalesforceContactFieldByIdSubscriptionVariables
    >,
    'query' | 'variables'
  >;
}): SubscribeToSalesforceContactFieldByIdResultEx {
  const subscription: SubscribeToSalesforceContactFieldByIdResult = useSubscription<
    SubscribeToSalesforceContactFieldByIdSubscription,
    SubscribeToSalesforceContactFieldByIdSubscriptionVariables
  >(SubscribeToSalesforceContactFieldByIdDocument, { variables: { salesforceContactFieldId }, ...options });
  return { ...subscription, salesforceContactField: subscription?.data?.salesforceContactField_by_pk };
}

/**
 *  Subscription Collection Hook
 */

// Types
export type SubscribeToSalesforceContactFieldObjectsResult = {
  variables?: SubscribeToSalesforceContactFieldObjectsSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToSalesforceContactFieldObjectsSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToSalesforceContactFieldObjectsResultEx = SubscribeToSalesforceContactFieldObjectsResult &
  SalesforceContactFieldObjectsHookResultEx;

// Function
function useSubscribeToSalesforceContactFieldObjects(
  options: Omit<
    SubscriptionHookOptions<
      SubscribeToSalesforceContactFieldObjectsSubscription,
      SubscribeToSalesforceContactFieldObjectsSubscriptionVariables
    >,
    'query'
  >
): SubscribeToSalesforceContactFieldObjectsResultEx {
  const subscription: SubscribeToSalesforceContactFieldObjectsResult = useSubscription<
    SubscribeToSalesforceContactFieldObjectsSubscription,
    SubscribeToSalesforceContactFieldObjectsSubscriptionVariables
  >(SubscribeToSalesforceContactFieldObjectsDocument, options);
  return { ...subscription, objects: subscription?.data?.salesforceContactField || [] };
}

/**
 *  Insert Hooks
 */

// Types
type InsertSalesforceContactFieldMutationResult = FetchResult<
  InsertSalesforceContactFieldMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertSalesforceContactFieldMutationResultEx = InsertSalesforceContactFieldMutationResult &
  SalesforceContactFieldByIdHookResultEx;

type PickInsertSalesforceContactFieldFn = (
  mutation: InsertSalesforceContactFieldMutation | null | undefined
) => SalesforceContactFieldFragment | null | undefined;
type InsertSalesforceContactFieldLazyMutationFn = MutationTuple<
  InsertSalesforceContactFieldMutation,
  InsertSalesforceContactFieldMutationVariables
>;
type InsertSalesforceContactFieldWrappedLazyMutationFn = ({
  salesforceContactField,
  autoOptimisticResponse,
  options,
}: {
  salesforceContactField: SalesforceContactField_Insert_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<InsertSalesforceContactFieldMutation, InsertSalesforceContactFieldMutationVariables>,
    'variables'
  >;
}) => Promise<InsertSalesforceContactFieldMutationResultEx>;
export type InsertSalesforceContactFieldLazyMutationReturn = [
  InsertSalesforceContactFieldWrappedLazyMutationFn,
  InsertSalesforceContactFieldMutationResultEx
];

// Function
function useInsertSalesforceContactField(
  options?: Omit<
    MutationHookOptions<InsertSalesforceContactFieldMutation, InsertSalesforceContactFieldMutationVariables>,
    'mutation' | 'variables'
  >
): InsertSalesforceContactFieldLazyMutationReturn {
  const lazyMutation: InsertSalesforceContactFieldLazyMutationFn = useMutation<
    InsertSalesforceContactFieldMutation,
    InsertSalesforceContactFieldMutationVariables
  >(InsertSalesforceContactFieldDocument, options);
  const pickSalesforceContactField: PickInsertSalesforceContactFieldFn = (mutation) => {
    return mutation?.insert_salesforceContactField?.returning && mutation?.insert_salesforceContactField?.returning[0];
  };

  const wrappedLazyMutation: InsertSalesforceContactFieldWrappedLazyMutationFn = async ({
    salesforceContactField,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: salesforceContactField });
    const mutationOptions: MutationFunctionOptions<
      InsertSalesforceContactFieldMutation,
      InsertSalesforceContactFieldMutationVariables
    > = { variables: { objects: [objectForInsert] }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id)
        throw new Error(`if autoOptimisticResponse = true, id must be set in object 'salesforceContactField'`);
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertSalesforceContactFieldMutation>({
        operationType: 'insert',
        entityName: 'salesforceContactField',
        objects: [objectForInsert as SalesforceContactField_Insert_Input & ObjectWithId],
      });
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, salesforceContactField: pickSalesforceContactField(fetchResult.data) };
  };

  return [
    wrappedLazyMutation,
    { ...lazyMutation[1], salesforceContactField: pickSalesforceContactField(lazyMutation[1].data) },
  ];
}

//
//

// Types
type InsertSalesforceContactFieldWithOnConflictMutationResult = FetchResult<
  InsertSalesforceContactFieldWithOnConflictMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertSalesforceContactFieldWithOnConflictMutationResultEx = InsertSalesforceContactFieldWithOnConflictMutationResult &
  SalesforceContactFieldByIdHookResultEx;

type InsertSalesforceContactFieldWithOnConflictLazyMutationFn = MutationTuple<
  InsertSalesforceContactFieldWithOnConflictMutation,
  InsertSalesforceContactFieldWithOnConflictMutationVariables
>;
type InsertSalesforceContactFieldWithOnConflictWrappedLazyMutationFn = ({
  salesforceContactField,
  onConflict,
  autoOptimisticResponse,
  options,
}: {
  salesforceContactField: SalesforceContactField_Insert_Input;
  onConflict: SalesforceContactField_On_Conflict;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<
      InsertSalesforceContactFieldWithOnConflictMutation,
      InsertSalesforceContactFieldWithOnConflictMutationVariables
    >,
    'variables'
  >;
}) => Promise<InsertSalesforceContactFieldWithOnConflictMutationResultEx>;
export type InsertSalesforceContactFieldWithOnConflictLazyMutationReturn = [
  InsertSalesforceContactFieldWithOnConflictWrappedLazyMutationFn,
  InsertSalesforceContactFieldWithOnConflictMutationResultEx
];

// Function
function useInsertSalesforceContactFieldWithOnConflict(
  options?: Omit<
    MutationHookOptions<
      InsertSalesforceContactFieldWithOnConflictMutation,
      InsertSalesforceContactFieldWithOnConflictMutationVariables
    >,
    'mutation' | 'variables'
  >
): InsertSalesforceContactFieldWithOnConflictLazyMutationReturn {
  const lazyMutation: InsertSalesforceContactFieldWithOnConflictLazyMutationFn = useMutation<
    InsertSalesforceContactFieldWithOnConflictMutation,
    InsertSalesforceContactFieldWithOnConflictMutationVariables
  >(InsertSalesforceContactFieldWithOnConflictDocument, options);
  const pickSalesforceContactField: PickInsertSalesforceContactFieldFn = (
    mutation: InsertSalesforceContactFieldWithOnConflictMutation | null | undefined
  ) => {
    return mutation?.insert_salesforceContactField?.returning && mutation.insert_salesforceContactField.returning[0];
  };

  const wrappedLazyMutation: InsertSalesforceContactFieldWithOnConflictWrappedLazyMutationFn = async ({
    salesforceContactField,
    onConflict,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: salesforceContactField });
    const mutationOptions: MutationFunctionOptions<
      InsertSalesforceContactFieldWithOnConflictMutation,
      InsertSalesforceContactFieldWithOnConflictMutationVariables
    > = { variables: { objects: [objectForInsert], onConflict }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id)
        throw new Error(`if autoOptimisticResponse = true, id must be set in object 'salesforceContactField'`);
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertSalesforceContactFieldWithOnConflictMutation>(
        {
          operationType: 'insert',
          entityName: 'salesforceContactField',
          objects: [objectForInsert as SalesforceContactField_Insert_Input & ObjectWithId],
        }
      );
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, salesforceContactField: pickSalesforceContactField(fetchResult.data) };
  };

  return [
    wrappedLazyMutation,
    { ...lazyMutation[1], salesforceContactField: pickSalesforceContactField(lazyMutation[1].data) },
  ];
}

// Types
type InsertSalesforceContactFieldObjectsMutationResult = FetchResult<
  InsertSalesforceContactFieldMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertSalesforceContactFieldObjectsMutationResultEx = InsertSalesforceContactFieldMutationResult &
  SalesforceContactFieldObjectsHookResultEx;

type PickInsertSalesforceContactFieldObjectsFn = (
  mutation: InsertSalesforceContactFieldMutation | null | undefined
) => SalesforceContactFieldFragment[];
type InsertSalesforceContactFieldObjectsLazyMutationFn = MutationTuple<
  InsertSalesforceContactFieldMutation,
  InsertSalesforceContactFieldMutationVariables
>;
type InsertSalesforceContactFieldObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<InsertSalesforceContactFieldMutation, InsertSalesforceContactFieldMutationVariables>
) => Promise<InsertSalesforceContactFieldObjectsMutationResultEx>;
export type InsertSalesforceContactFieldObjectsLazyMutationReturn = [
  InsertSalesforceContactFieldObjectsWrappedLazyMutationFn,
  InsertSalesforceContactFieldObjectsMutationResultEx
];

// Function
function useInsertSalesforceContactFieldObjects(
  options?: Omit<
    MutationHookOptions<InsertSalesforceContactFieldMutation, InsertSalesforceContactFieldMutationVariables>,
    'mutation'
  >
): InsertSalesforceContactFieldObjectsLazyMutationReturn {
  const lazyMutation: InsertSalesforceContactFieldObjectsLazyMutationFn = useMutation<
    InsertSalesforceContactFieldMutation,
    InsertSalesforceContactFieldMutationVariables
  >(InsertSalesforceContactFieldDocument, options);
  const pickObjects: PickInsertSalesforceContactFieldObjectsFn = (
    mutation: InsertSalesforceContactFieldMutation | null | undefined
  ) => {
    return mutation?.insert_salesforceContactField?.returning || [];
  };

  const wrappedLazyMutation: InsertSalesforceContactFieldObjectsWrappedLazyMutationFn = async (options) => {
    if (options && options.variables && options.variables.objects)
      options.variables.objects = options.variables.objects.map((objectItem) =>
        stripInsertInputClientFields({ input: objectItem })
      );
    const fetchResult: InsertSalesforceContactFieldObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Update Hooks
 */

type UpdateSalesforceContactFieldByIdMutationResult = FetchResult<
  UpdateSalesforceContactFieldByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateSalesforceContactFieldByIdMutationResultEx = UpdateSalesforceContactFieldByIdMutationResult &
  SalesforceContactFieldByIdHookResultEx;

type PickUpdateSalesforceContactFieldByIdFn = (
  mutation: UpdateSalesforceContactFieldByIdMutation | null | undefined
) => SalesforceContactFieldFragment | null | undefined;
type UpdateSalesforceContactFieldByIdLazyMutationFn = MutationTuple<
  UpdateSalesforceContactFieldByIdMutation,
  UpdateSalesforceContactFieldByIdMutationVariables
>;
type UpdateSalesforceContactFieldByIdWrappedLazyMutationFn = ({
  salesforceContactFieldId,
  set,
  autoOptimisticResponse,
  options,
}: {
  salesforceContactFieldId: string;
  set: SalesforceContactField_Set_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<
      UpdateSalesforceContactFieldByIdMutation,
      UpdateSalesforceContactFieldByIdMutationVariables
    >,
    'variables'
  >;
}) => Promise<UpdateSalesforceContactFieldByIdMutationResultEx>;
export type UpdateSalesforceContactFieldByIdLazyMutationReturn = [
  UpdateSalesforceContactFieldByIdWrappedLazyMutationFn,
  UpdateSalesforceContactFieldByIdMutationResultEx
];

function useUpdateSalesforceContactFieldById(
  options?: Omit<
    MutationHookOptions<UpdateSalesforceContactFieldByIdMutation, UpdateSalesforceContactFieldByIdMutationVariables>,
    'mutation' | 'variables'
  >
): UpdateSalesforceContactFieldByIdLazyMutationReturn {
  const lazyMutation: UpdateSalesforceContactFieldByIdLazyMutationFn = useMutation<
    UpdateSalesforceContactFieldByIdMutation,
    UpdateSalesforceContactFieldByIdMutationVariables
  >(UpdateSalesforceContactFieldByIdDocument, options);

  const pickSalesforceContactField: PickUpdateSalesforceContactFieldByIdFn = (mutation) => {
    return mutation?.update_salesforceContactField?.returning && mutation.update_salesforceContactField!.returning[0];
  };

  const wrappedLazyMutation: UpdateSalesforceContactFieldByIdWrappedLazyMutationFn = async ({
    salesforceContactFieldId,
    set,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<
      UpdateSalesforceContactFieldByIdMutation,
      UpdateSalesforceContactFieldByIdMutationVariables
    > = { variables: { id: salesforceContactFieldId, set }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateSalesforceContactFieldByIdMutation>(
        {
          operationType: 'update',
          entityName: 'salesforceContactField',
          objects: [{ id: salesforceContactFieldId, ...set }],
        }
      );
    }

    const fetchResult: UpdateSalesforceContactFieldByIdMutationResult = await lazyMutation[0]({
      variables: { id: salesforceContactFieldId, set },
      ...mutationOptions,
    });
    return { ...fetchResult, salesforceContactField: pickSalesforceContactField(fetchResult.data) };
  };

  return [
    wrappedLazyMutation,
    { ...lazyMutation[1], salesforceContactField: pickSalesforceContactField(lazyMutation[1].data) },
  ];
}

//
//

// Types
type UpdateSalesforceContactFieldObjectsMutationResult = FetchResult<
  UpdateSalesforceContactFieldMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateSalesforceContactFieldObjectsMutationResultEx = UpdateSalesforceContactFieldObjectsMutationResult &
  SalesforceContactFieldObjectsHookResultEx;

// Function
type PickUpdateSalesforceContactFieldObjectsFn = (
  mutation: UpdateSalesforceContactFieldMutation | null | undefined
) => SalesforceContactFieldFragment[];
type UpdateSalesforceContactFieldObjectsLazyMutationFn = MutationTuple<
  UpdateSalesforceContactFieldMutation,
  UpdateSalesforceContactFieldMutationVariables
>;
type UpdateSalesforceContactFieldObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<UpdateSalesforceContactFieldMutation, UpdateSalesforceContactFieldMutationVariables>
) => Promise<UpdateSalesforceContactFieldObjectsMutationResultEx>;
export type UpdateSalesforceContactFieldObjectsLazyMutationReturn = [
  UpdateSalesforceContactFieldObjectsWrappedLazyMutationFn,
  UpdateSalesforceContactFieldObjectsMutationResultEx
];

function useUpdateSalesforceContactFieldObjects(
  options?: Omit<
    MutationHookOptions<UpdateSalesforceContactFieldMutation, UpdateSalesforceContactFieldMutationVariables>,
    'mutation'
  >
): UpdateSalesforceContactFieldObjectsLazyMutationReturn {
  const lazyMutation: UpdateSalesforceContactFieldObjectsLazyMutationFn = useMutation<
    UpdateSalesforceContactFieldMutation,
    UpdateSalesforceContactFieldMutationVariables
  >(UpdateSalesforceContactFieldDocument, options);

  const pickObjects: PickUpdateSalesforceContactFieldObjectsFn = (
    mutation: UpdateSalesforceContactFieldMutation | null | undefined
  ) => {
    return mutation?.update_salesforceContactField?.returning || [];
  };

  const wrappedLazyMutation: UpdateSalesforceContactFieldObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: UpdateSalesforceContactFieldObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Delete Hooks
 */

// Types
type RemoveSalesforceContactFieldModelByIdFetchResult = FetchResult<
  RemoveSalesforceContactFieldModelByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveSalesforceContactFieldModelByIdMutationResultEx = RemoveSalesforceContactFieldModelByIdFetchResult &
  RemoveEntitiesQueryHookResultEx;

// Function
type PickRemoveSalesforceContactFieldModelFn = (
  mutation: RemoveSalesforceContactFieldModelByIdMutation | null | undefined
) => number;
type RemoveSalesforceContactFieldModelLazyMutationFn = MutationTuple<
  RemoveSalesforceContactFieldModelByIdMutation,
  RemoveSalesforceContactFieldModelByIdMutationVariables
>;
type RemoveSalesforceContactFieldModelWrappedLazyMutationFn = ({
  salesforceContactFieldId,
  autoOptimisticResponse,
  options,
}: {
  salesforceContactFieldId: string;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<
      RemoveSalesforceContactFieldModelByIdMutation,
      RemoveSalesforceContactFieldModelByIdMutationVariables
    >,
    'variables'
  >;
}) => Promise<RemoveSalesforceContactFieldModelByIdMutationResultEx>;
export type RemoveSalesforceContactFieldModelLazyMutationReturn = [
  RemoveSalesforceContactFieldModelWrappedLazyMutationFn,
  RemoveSalesforceContactFieldModelByIdMutationResultEx
];

function useRemoveSalesforceContactFieldModelById(
  options?: Omit<
    MutationHookOptions<
      RemoveSalesforceContactFieldModelByIdMutation,
      RemoveSalesforceContactFieldModelByIdMutationVariables
    >,
    'mutation' | 'variables'
  >
) {
  const lazyMutation: RemoveSalesforceContactFieldModelLazyMutationFn = useMutation<
    RemoveSalesforceContactFieldModelByIdMutation,
    RemoveSalesforceContactFieldModelByIdMutationVariables
  >(RemoveSalesforceContactFieldModelByIdDocument, options);

  const pickAffectedRows: PickRemoveSalesforceContactFieldModelFn = (
    mutation: RemoveSalesforceContactFieldModelByIdMutation | null | undefined
  ) => {
    return mutation?.delete_salesforceContactField?.affected_rows || 0;
  };

  const wrappedLazyMutation: RemoveSalesforceContactFieldModelWrappedLazyMutationFn = async ({
    salesforceContactFieldId,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<
      RemoveSalesforceContactFieldModelMutation,
      RemoveSalesforceContactFieldModelByIdMutationVariables
    > = { variables: { id: salesforceContactFieldId }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<RemoveSalesforceContactFieldModelMutation>(
        { operationType: 'delete', entityName: 'salesforceContactField', objects: [{ id: salesforceContactFieldId }] }
      );
    }
    if (!options || !options.update) {
      mutationOptions.update = generateUpdateFunctionForMutation<RemoveSalesforceContactFieldModelByIdMutation>({
        operationType: 'delete',
        entityName: 'salesforceContactField',
        entityId: salesforceContactFieldId,
      });
    }

    const fetchResult: RemoveSalesforceContactFieldModelByIdFetchResult = await lazyMutation[0](mutationOptions);
    return { ...fetchResult, affected_rows: pickAffectedRows(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], affected_rows: pickAffectedRows(lazyMutation[1].data) }] as [
    typeof wrappedLazyMutation,
    typeof lazyMutation[1] & { affected_rows: ReturnType<typeof pickAffectedRows> }
  ];
}

//
//

// Types
type RemoveSalesforceContactFieldModelObjectsMutationResult = FetchResult<
  RemoveSalesforceContactFieldModelMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveSalesforceContactFieldModelObjectsMutationResultEx = RemoveSalesforceContactFieldModelObjectsMutationResult &
  RemoveEntitiesQueryHookResultEx;

// Function
type PickRemoveSalesforceContactFieldModelObjectsFn = (
  mutation: RemoveSalesforceContactFieldModelMutation | null | undefined
) => number;
type RemoveSalesforceContactFieldModelObjectsLazyMutationFn = MutationTuple<
  RemoveSalesforceContactFieldModelMutation,
  RemoveSalesforceContactFieldModelMutationVariables
>;
type RemoveSalesforceContactFieldModelObjectsWrappedLazyMutationFn = (
  options: MutationFunctionOptions<
    RemoveSalesforceContactFieldModelMutation,
    RemoveSalesforceContactFieldModelMutationVariables
  >
) => Promise<RemoveSalesforceContactFieldModelObjectsMutationResultEx>;
export type RemoveSalesforceContactFieldModelObjectsLazyMutationReturn = [
  RemoveSalesforceContactFieldModelObjectsWrappedLazyMutationFn,
  RemoveSalesforceContactFieldModelObjectsMutationResultEx
];

function useRemoveSalesforceContactFieldModelObjects(
  options?: Omit<
    MutationHookOptions<RemoveSalesforceContactFieldModelMutation, RemoveSalesforceContactFieldModelMutationVariables>,
    'mutation'
  >
): RemoveSalesforceContactFieldModelObjectsLazyMutationReturn {
  const lazyMutation = useMutation<
    RemoveSalesforceContactFieldModelMutation,
    RemoveSalesforceContactFieldModelMutationVariables
  >(RemoveSalesforceContactFieldModelDocument, options);

  const pickAffectedRows: PickRemoveSalesforceContactFieldModelObjectsFn = (
    mutation: RemoveSalesforceContactFieldModelMutation | null | undefined
  ) => {
    return mutation?.delete_salesforceContactField?.affected_rows || 0;
  };

  const wrappedLazyMutation: RemoveSalesforceContactFieldModelObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: RemoveSalesforceContactFieldModelObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, affected_rows: pickAffectedRows(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], affected_rows: pickAffectedRows(lazyMutation[1].data) }];
}

// SalesforceContactField Fragment Helper Object
//------------------------------------------------

export const SalesforceContactFieldFragmentGQLHooks = {
  useQueryById: useQuerySalesforceContactFieldById,
  useQueryByIdLazy: useQuerySalesforceContactFieldByIdLazy,
  useQueryObjects: useQuerySalesforceContactFieldObjects,
  useQueryObjectsLazy: useQuerySalesforceContactFieldObjectsLazy,
  useSubscribeToById: useSubscribeToSalesforceContactFieldById,
  useSubscribeToObjects: useSubscribeToSalesforceContactFieldObjects,
  useInsert: useInsertSalesforceContactField,
  useInsertWithOnConflict: useInsertSalesforceContactFieldWithOnConflict,
  useInsertObjects: useInsertSalesforceContactFieldObjects,
  useUpdateById: useUpdateSalesforceContactFieldById,
  useUpdateObjects: useUpdateSalesforceContactFieldObjects,
};

// salesforceContactField MODEL HOOKS OBJECT
//------------------------------------------------

export const SalesforceContactFieldModelGQLHooks = {
  useRemoveById: useRemoveSalesforceContactFieldModelById,
  useRemoveObjects: useRemoveSalesforceContactFieldModelObjects,
};

// hubspotIntegration REACT
//------------------------------------------------

export type HubspotIntegrationByIdHookResultEx = { hubspotIntegration: HubspotIntegrationFragment | null | undefined };
export type HubspotIntegrationObjectsHookResultEx = { objects: HubspotIntegrationFragment[] };

/**
 *  Query Hook
 */

// Types
type QueryHubspotIntegrationByIdResult = LazyQueryResult<
  QueryHubspotIntegrationByIdQuery,
  QueryHubspotIntegrationByIdQueryVariables
>;
type QueryHubspotIntegrationByIdSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<
          QueryHubspotIntegrationByIdQuery,
          QueryHubspotIntegrationByIdQueryVariables,
          QueryHubspotIntegrationByIdQuery
        >,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryHubspotIntegrationByIdResultEx = Omit<QueryHubspotIntegrationByIdResult, 'subscribeToMore'> & {
  subscribeToMore: QueryHubspotIntegrationByIdSubScribeToMore;
} & HubspotIntegrationByIdHookResultEx;

// Function
function useQueryHubspotIntegrationById({
  hubspotIntegrationId,
  options,
}: {
  hubspotIntegrationId: string;
  options?: Omit<
    QueryHookOptions<QueryHubspotIntegrationByIdQuery, QueryHubspotIntegrationByIdQueryVariables>,
    'query' | 'variables'
  >;
}): QueryHubspotIntegrationByIdResultEx {
  const _query: QueryHubspotIntegrationByIdResult = useQuery<
    QueryHubspotIntegrationByIdQuery,
    QueryHubspotIntegrationByIdQueryVariables
  >(QueryHubspotIntegrationByIdDocument, { variables: { hubspotIntegrationId }, ...options });

  const typedSubscribeToMore: QueryHubspotIntegrationByIdSubScribeToMore = (options) => {
    _query.subscribeToMore({
      document: QueryHubspotIntegrationByIdDocument,
      variables: { hubspotIntegrationId } as QueryHubspotIntegrationByIdQueryVariables,
      ...options,
    });
  };
  const { subscribeToMore, ...query } = _query;
  return { ...query, subscribeToMore: typedSubscribeToMore, hubspotIntegration: query?.data?.hubspotIntegration_by_pk };
}

/**
 *  Lazy Query Hook
 */

// Types
type PickQueryHubspotIntegrationByIdFn = (
  query: QueryHubspotIntegrationByIdQuery | null | undefined
) => HubspotIntegrationFragment | null | undefined;
type QueryHubspotIntegrationByIdLazyFn = [
  (options?: QueryLazyOptions<QueryHubspotIntegrationByIdQueryVariables> | undefined) => void,
  QueryHubspotIntegrationByIdResult
];
type QueryHubspotIntegrationByIdWrappedLazyFn = (
  options: Omit<QueryLazyOptions<QueryHubspotIntegrationByIdQueryVariables>, 'variables'>
) => void;
export type QueryHubspotIntegrationByIdLazyReturn = [
  QueryHubspotIntegrationByIdWrappedLazyFn,
  QueryHubspotIntegrationByIdResultEx
];

// Function
function useQueryHubspotIntegrationByIdLazy({
  hubspotIntegrationId,
  options,
}: {
  hubspotIntegrationId: string;
  options?: Omit<
    QueryHookOptions<QueryHubspotIntegrationByIdQuery, QueryHubspotIntegrationByIdQueryVariables>,
    'query' | 'variables'
  >;
}): QueryHubspotIntegrationByIdLazyReturn {
  const lazyQuery: QueryHubspotIntegrationByIdLazyFn = useLazyQuery<
    QueryHubspotIntegrationByIdQuery,
    QueryHubspotIntegrationByIdQueryVariables
  >(QueryHubspotIntegrationByIdDocument, options);

  // Setting up typed version of lazyQuery
  const pickQueryHubspotIntegrationById: PickQueryHubspotIntegrationByIdFn = (query) => {
    return query && query.hubspotIntegration_by_pk;
  };
  const wrappedLazyQuery: QueryHubspotIntegrationByIdWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryHubspotIntegrationByIdSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({
        document: QueryHubspotIntegrationByIdDocument,
        variables: { hubspotIntegrationId } as QueryHubspotIntegrationByIdQueryVariables,
        ...options,
      });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    {
      ...lazyQueryResult,
      subscribeToMore: typedSubcribeToMore,
      hubspotIntegration: pickQueryHubspotIntegrationById(lazyQuery[1].data),
    },
  ];
}

/**
 *  Query Collection Hook
 */

// Types
export type QueryHubspotIntegrationObjectsResult = LazyQueryResult<
  QueryHubspotIntegrationObjectsQuery,
  QueryHubspotIntegrationObjectsQueryVariables
>;
type QueryHubspotIntegrationObjectsSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<
          QueryHubspotIntegrationObjectsQuery,
          QueryHubspotIntegrationObjectsQueryVariables,
          QueryHubspotIntegrationObjectsQuery
        >,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryHubspotIntegrationObjectsResultEx = Omit<QueryHubspotIntegrationObjectsResult, 'subscribeToMore'> & {
  subscribeToMore: QueryHubspotIntegrationObjectsSubScribeToMore;
} & HubspotIntegrationObjectsHookResultEx;

// Function
function useQueryHubspotIntegrationObjects(
  options: Omit<
    QueryHookOptions<QueryHubspotIntegrationObjectsQuery, QueryHubspotIntegrationObjectsQueryVariables>,
    'query'
  >
): QueryHubspotIntegrationObjectsResultEx {
  const _query: QueryHubspotIntegrationObjectsResult = useQuery<
    QueryHubspotIntegrationObjectsQuery,
    QueryHubspotIntegrationObjectsQueryVariables
  >(QueryHubspotIntegrationObjectsDocument, options);

  const typedSubscribeToMore: QueryHubspotIntegrationObjectsSubScribeToMore = (options) => {
    _query.subscribeToMore({ document: QueryHubspotIntegrationObjectsDocument, ...options });
  };
  const { subscribeToMore, ...query } = _query;

  return { ...query, subscribeToMore: typedSubscribeToMore, objects: query?.data?.hubspotIntegration || [] };
}

/**
 *  Lazy Query Collection Hook
 */

// Types
type PickQueryHubspotIntegrationObjectsFn = (
  query: QueryHubspotIntegrationObjectsQuery | null | undefined
) => HubspotIntegrationFragment[];
type QueryHubspotIntegrationObjectsLazyFn = [
  (options?: QueryLazyOptions<QueryHubspotIntegrationObjectsQueryVariables> | undefined) => void,
  QueryHubspotIntegrationObjectsResult
];
type QueryHubspotIntegrationObjectsWrappedLazyFn = (
  options?: QueryLazyOptions<QueryHubspotIntegrationObjectsQueryVariables>
) => void;
export type QueryHubspotIntegrationObjectsLazyReturn = [
  QueryHubspotIntegrationObjectsWrappedLazyFn,
  QueryHubspotIntegrationObjectsResultEx
];

// Function
function useQueryHubspotIntegrationObjectsLazy(
  options?: Omit<
    LazyQueryHookOptions<QueryHubspotIntegrationObjectsQuery, QueryHubspotIntegrationObjectsQueryVariables>,
    'query'
  >
): QueryHubspotIntegrationObjectsLazyReturn {
  const lazyQuery: QueryHubspotIntegrationObjectsLazyFn = useLazyQuery<
    QueryHubspotIntegrationObjectsQuery,
    QueryHubspotIntegrationObjectsQueryVariables
  >(QueryHubspotIntegrationObjectsDocument, options);

  // Setting up typed version of lazyQuery
  const pickObjects: PickQueryHubspotIntegrationObjectsFn = (
    query: QueryHubspotIntegrationObjectsQuery | null | undefined
  ) => {
    return query?.hubspotIntegration || [];
  };
  const wrappedLazyQuery: QueryHubspotIntegrationObjectsWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryHubspotIntegrationObjectsSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({ document: QueryHubspotIntegrationObjectsDocument, ...options });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    { ...lazyQueryResult, subscribeToMore: typedSubcribeToMore, objects: pickObjects(lazyQuery[1].data) },
  ];
}

/**
 *  Subscription Hook
 */

// Types
type SubscribeToHubspotIntegrationByIdResult = {
  variables?: SubscribeToHubspotIntegrationByIdSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToHubspotIntegrationByIdSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToHubspotIntegrationByIdResultEx = SubscribeToHubspotIntegrationByIdResult &
  HubspotIntegrationByIdHookResultEx;

// Function
function useSubscribeToHubspotIntegrationById({
  hubspotIntegrationId,
  options,
}: {
  hubspotIntegrationId: string;
  options?: Omit<
    SubscriptionHookOptions<
      SubscribeToHubspotIntegrationByIdSubscription,
      SubscribeToHubspotIntegrationByIdSubscriptionVariables
    >,
    'query' | 'variables'
  >;
}): SubscribeToHubspotIntegrationByIdResultEx {
  const subscription: SubscribeToHubspotIntegrationByIdResult = useSubscription<
    SubscribeToHubspotIntegrationByIdSubscription,
    SubscribeToHubspotIntegrationByIdSubscriptionVariables
  >(SubscribeToHubspotIntegrationByIdDocument, { variables: { hubspotIntegrationId }, ...options });
  return { ...subscription, hubspotIntegration: subscription?.data?.hubspotIntegration_by_pk };
}

/**
 *  Subscription Collection Hook
 */

// Types
export type SubscribeToHubspotIntegrationObjectsResult = {
  variables?: SubscribeToHubspotIntegrationObjectsSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToHubspotIntegrationObjectsSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToHubspotIntegrationObjectsResultEx = SubscribeToHubspotIntegrationObjectsResult &
  HubspotIntegrationObjectsHookResultEx;

// Function
function useSubscribeToHubspotIntegrationObjects(
  options: Omit<
    SubscriptionHookOptions<
      SubscribeToHubspotIntegrationObjectsSubscription,
      SubscribeToHubspotIntegrationObjectsSubscriptionVariables
    >,
    'query'
  >
): SubscribeToHubspotIntegrationObjectsResultEx {
  const subscription: SubscribeToHubspotIntegrationObjectsResult = useSubscription<
    SubscribeToHubspotIntegrationObjectsSubscription,
    SubscribeToHubspotIntegrationObjectsSubscriptionVariables
  >(SubscribeToHubspotIntegrationObjectsDocument, options);
  return { ...subscription, objects: subscription?.data?.hubspotIntegration || [] };
}

/**
 *  Insert Hooks
 */

// Types
type InsertHubspotIntegrationMutationResult = FetchResult<
  InsertHubspotIntegrationMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertHubspotIntegrationMutationResultEx = InsertHubspotIntegrationMutationResult &
  HubspotIntegrationByIdHookResultEx;

type PickInsertHubspotIntegrationFn = (
  mutation: InsertHubspotIntegrationMutation | null | undefined
) => HubspotIntegrationFragment | null | undefined;
type InsertHubspotIntegrationLazyMutationFn = MutationTuple<
  InsertHubspotIntegrationMutation,
  InsertHubspotIntegrationMutationVariables
>;
type InsertHubspotIntegrationWrappedLazyMutationFn = ({
  hubspotIntegration,
  autoOptimisticResponse,
  options,
}: {
  hubspotIntegration: HubspotIntegration_Insert_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<InsertHubspotIntegrationMutation, InsertHubspotIntegrationMutationVariables>,
    'variables'
  >;
}) => Promise<InsertHubspotIntegrationMutationResultEx>;
export type InsertHubspotIntegrationLazyMutationReturn = [
  InsertHubspotIntegrationWrappedLazyMutationFn,
  InsertHubspotIntegrationMutationResultEx
];

// Function
function useInsertHubspotIntegration(
  options?: Omit<
    MutationHookOptions<InsertHubspotIntegrationMutation, InsertHubspotIntegrationMutationVariables>,
    'mutation' | 'variables'
  >
): InsertHubspotIntegrationLazyMutationReturn {
  const lazyMutation: InsertHubspotIntegrationLazyMutationFn = useMutation<
    InsertHubspotIntegrationMutation,
    InsertHubspotIntegrationMutationVariables
  >(InsertHubspotIntegrationDocument, options);
  const pickHubspotIntegration: PickInsertHubspotIntegrationFn = (mutation) => {
    return mutation?.insert_hubspotIntegration?.returning && mutation?.insert_hubspotIntegration?.returning[0];
  };

  const wrappedLazyMutation: InsertHubspotIntegrationWrappedLazyMutationFn = async ({
    hubspotIntegration,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: hubspotIntegration });
    const mutationOptions: MutationFunctionOptions<
      InsertHubspotIntegrationMutation,
      InsertHubspotIntegrationMutationVariables
    > = { variables: { objects: [objectForInsert] }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id)
        throw new Error(`if autoOptimisticResponse = true, id must be set in object 'hubspotIntegration'`);
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertHubspotIntegrationMutation>({
        operationType: 'insert',
        entityName: 'hubspotIntegration',
        objects: [objectForInsert as HubspotIntegration_Insert_Input & ObjectWithId],
      });
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, hubspotIntegration: pickHubspotIntegration(fetchResult.data) };
  };

  return [
    wrappedLazyMutation,
    { ...lazyMutation[1], hubspotIntegration: pickHubspotIntegration(lazyMutation[1].data) },
  ];
}

//
//

// Types
type InsertHubspotIntegrationWithOnConflictMutationResult = FetchResult<
  InsertHubspotIntegrationWithOnConflictMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertHubspotIntegrationWithOnConflictMutationResultEx = InsertHubspotIntegrationWithOnConflictMutationResult &
  HubspotIntegrationByIdHookResultEx;

type InsertHubspotIntegrationWithOnConflictLazyMutationFn = MutationTuple<
  InsertHubspotIntegrationWithOnConflictMutation,
  InsertHubspotIntegrationWithOnConflictMutationVariables
>;
type InsertHubspotIntegrationWithOnConflictWrappedLazyMutationFn = ({
  hubspotIntegration,
  onConflict,
  autoOptimisticResponse,
  options,
}: {
  hubspotIntegration: HubspotIntegration_Insert_Input;
  onConflict: HubspotIntegration_On_Conflict;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<
      InsertHubspotIntegrationWithOnConflictMutation,
      InsertHubspotIntegrationWithOnConflictMutationVariables
    >,
    'variables'
  >;
}) => Promise<InsertHubspotIntegrationWithOnConflictMutationResultEx>;
export type InsertHubspotIntegrationWithOnConflictLazyMutationReturn = [
  InsertHubspotIntegrationWithOnConflictWrappedLazyMutationFn,
  InsertHubspotIntegrationWithOnConflictMutationResultEx
];

// Function
function useInsertHubspotIntegrationWithOnConflict(
  options?: Omit<
    MutationHookOptions<
      InsertHubspotIntegrationWithOnConflictMutation,
      InsertHubspotIntegrationWithOnConflictMutationVariables
    >,
    'mutation' | 'variables'
  >
): InsertHubspotIntegrationWithOnConflictLazyMutationReturn {
  const lazyMutation: InsertHubspotIntegrationWithOnConflictLazyMutationFn = useMutation<
    InsertHubspotIntegrationWithOnConflictMutation,
    InsertHubspotIntegrationWithOnConflictMutationVariables
  >(InsertHubspotIntegrationWithOnConflictDocument, options);
  const pickHubspotIntegration: PickInsertHubspotIntegrationFn = (
    mutation: InsertHubspotIntegrationWithOnConflictMutation | null | undefined
  ) => {
    return mutation?.insert_hubspotIntegration?.returning && mutation.insert_hubspotIntegration.returning[0];
  };

  const wrappedLazyMutation: InsertHubspotIntegrationWithOnConflictWrappedLazyMutationFn = async ({
    hubspotIntegration,
    onConflict,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: hubspotIntegration });
    const mutationOptions: MutationFunctionOptions<
      InsertHubspotIntegrationWithOnConflictMutation,
      InsertHubspotIntegrationWithOnConflictMutationVariables
    > = { variables: { objects: [objectForInsert], onConflict }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id)
        throw new Error(`if autoOptimisticResponse = true, id must be set in object 'hubspotIntegration'`);
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertHubspotIntegrationWithOnConflictMutation>(
        {
          operationType: 'insert',
          entityName: 'hubspotIntegration',
          objects: [objectForInsert as HubspotIntegration_Insert_Input & ObjectWithId],
        }
      );
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, hubspotIntegration: pickHubspotIntegration(fetchResult.data) };
  };

  return [
    wrappedLazyMutation,
    { ...lazyMutation[1], hubspotIntegration: pickHubspotIntegration(lazyMutation[1].data) },
  ];
}

// Types
type InsertHubspotIntegrationObjectsMutationResult = FetchResult<
  InsertHubspotIntegrationMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertHubspotIntegrationObjectsMutationResultEx = InsertHubspotIntegrationMutationResult &
  HubspotIntegrationObjectsHookResultEx;

type PickInsertHubspotIntegrationObjectsFn = (
  mutation: InsertHubspotIntegrationMutation | null | undefined
) => HubspotIntegrationFragment[];
type InsertHubspotIntegrationObjectsLazyMutationFn = MutationTuple<
  InsertHubspotIntegrationMutation,
  InsertHubspotIntegrationMutationVariables
>;
type InsertHubspotIntegrationObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<InsertHubspotIntegrationMutation, InsertHubspotIntegrationMutationVariables>
) => Promise<InsertHubspotIntegrationObjectsMutationResultEx>;
export type InsertHubspotIntegrationObjectsLazyMutationReturn = [
  InsertHubspotIntegrationObjectsWrappedLazyMutationFn,
  InsertHubspotIntegrationObjectsMutationResultEx
];

// Function
function useInsertHubspotIntegrationObjects(
  options?: Omit<
    MutationHookOptions<InsertHubspotIntegrationMutation, InsertHubspotIntegrationMutationVariables>,
    'mutation'
  >
): InsertHubspotIntegrationObjectsLazyMutationReturn {
  const lazyMutation: InsertHubspotIntegrationObjectsLazyMutationFn = useMutation<
    InsertHubspotIntegrationMutation,
    InsertHubspotIntegrationMutationVariables
  >(InsertHubspotIntegrationDocument, options);
  const pickObjects: PickInsertHubspotIntegrationObjectsFn = (
    mutation: InsertHubspotIntegrationMutation | null | undefined
  ) => {
    return mutation?.insert_hubspotIntegration?.returning || [];
  };

  const wrappedLazyMutation: InsertHubspotIntegrationObjectsWrappedLazyMutationFn = async (options) => {
    if (options && options.variables && options.variables.objects)
      options.variables.objects = options.variables.objects.map((objectItem) =>
        stripInsertInputClientFields({ input: objectItem })
      );
    const fetchResult: InsertHubspotIntegrationObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Update Hooks
 */

type UpdateHubspotIntegrationByIdMutationResult = FetchResult<
  UpdateHubspotIntegrationByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateHubspotIntegrationByIdMutationResultEx = UpdateHubspotIntegrationByIdMutationResult &
  HubspotIntegrationByIdHookResultEx;

type PickUpdateHubspotIntegrationByIdFn = (
  mutation: UpdateHubspotIntegrationByIdMutation | null | undefined
) => HubspotIntegrationFragment | null | undefined;
type UpdateHubspotIntegrationByIdLazyMutationFn = MutationTuple<
  UpdateHubspotIntegrationByIdMutation,
  UpdateHubspotIntegrationByIdMutationVariables
>;
type UpdateHubspotIntegrationByIdWrappedLazyMutationFn = ({
  hubspotIntegrationId,
  set,
  autoOptimisticResponse,
  options,
}: {
  hubspotIntegrationId: string;
  set: HubspotIntegration_Set_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<UpdateHubspotIntegrationByIdMutation, UpdateHubspotIntegrationByIdMutationVariables>,
    'variables'
  >;
}) => Promise<UpdateHubspotIntegrationByIdMutationResultEx>;
export type UpdateHubspotIntegrationByIdLazyMutationReturn = [
  UpdateHubspotIntegrationByIdWrappedLazyMutationFn,
  UpdateHubspotIntegrationByIdMutationResultEx
];

function useUpdateHubspotIntegrationById(
  options?: Omit<
    MutationHookOptions<UpdateHubspotIntegrationByIdMutation, UpdateHubspotIntegrationByIdMutationVariables>,
    'mutation' | 'variables'
  >
): UpdateHubspotIntegrationByIdLazyMutationReturn {
  const lazyMutation: UpdateHubspotIntegrationByIdLazyMutationFn = useMutation<
    UpdateHubspotIntegrationByIdMutation,
    UpdateHubspotIntegrationByIdMutationVariables
  >(UpdateHubspotIntegrationByIdDocument, options);

  const pickHubspotIntegration: PickUpdateHubspotIntegrationByIdFn = (mutation) => {
    return mutation?.update_hubspotIntegration?.returning && mutation.update_hubspotIntegration!.returning[0];
  };

  const wrappedLazyMutation: UpdateHubspotIntegrationByIdWrappedLazyMutationFn = async ({
    hubspotIntegrationId,
    set,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<
      UpdateHubspotIntegrationByIdMutation,
      UpdateHubspotIntegrationByIdMutationVariables
    > = { variables: { id: hubspotIntegrationId, set }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateHubspotIntegrationByIdMutation>({
        operationType: 'update',
        entityName: 'hubspotIntegration',
        objects: [{ id: hubspotIntegrationId, ...set }],
      });
    }

    const fetchResult: UpdateHubspotIntegrationByIdMutationResult = await lazyMutation[0]({
      variables: { id: hubspotIntegrationId, set },
      ...mutationOptions,
    });
    return { ...fetchResult, hubspotIntegration: pickHubspotIntegration(fetchResult.data) };
  };

  return [
    wrappedLazyMutation,
    { ...lazyMutation[1], hubspotIntegration: pickHubspotIntegration(lazyMutation[1].data) },
  ];
}

//
//

// Types
type UpdateHubspotIntegrationObjectsMutationResult = FetchResult<
  UpdateHubspotIntegrationMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateHubspotIntegrationObjectsMutationResultEx = UpdateHubspotIntegrationObjectsMutationResult &
  HubspotIntegrationObjectsHookResultEx;

// Function
type PickUpdateHubspotIntegrationObjectsFn = (
  mutation: UpdateHubspotIntegrationMutation | null | undefined
) => HubspotIntegrationFragment[];
type UpdateHubspotIntegrationObjectsLazyMutationFn = MutationTuple<
  UpdateHubspotIntegrationMutation,
  UpdateHubspotIntegrationMutationVariables
>;
type UpdateHubspotIntegrationObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<UpdateHubspotIntegrationMutation, UpdateHubspotIntegrationMutationVariables>
) => Promise<UpdateHubspotIntegrationObjectsMutationResultEx>;
export type UpdateHubspotIntegrationObjectsLazyMutationReturn = [
  UpdateHubspotIntegrationObjectsWrappedLazyMutationFn,
  UpdateHubspotIntegrationObjectsMutationResultEx
];

function useUpdateHubspotIntegrationObjects(
  options?: Omit<
    MutationHookOptions<UpdateHubspotIntegrationMutation, UpdateHubspotIntegrationMutationVariables>,
    'mutation'
  >
): UpdateHubspotIntegrationObjectsLazyMutationReturn {
  const lazyMutation: UpdateHubspotIntegrationObjectsLazyMutationFn = useMutation<
    UpdateHubspotIntegrationMutation,
    UpdateHubspotIntegrationMutationVariables
  >(UpdateHubspotIntegrationDocument, options);

  const pickObjects: PickUpdateHubspotIntegrationObjectsFn = (
    mutation: UpdateHubspotIntegrationMutation | null | undefined
  ) => {
    return mutation?.update_hubspotIntegration?.returning || [];
  };

  const wrappedLazyMutation: UpdateHubspotIntegrationObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: UpdateHubspotIntegrationObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Delete Hooks
 */

// Types
type RemoveHubspotIntegrationModelByIdFetchResult = FetchResult<
  RemoveHubspotIntegrationModelByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveHubspotIntegrationModelByIdMutationResultEx = RemoveHubspotIntegrationModelByIdFetchResult &
  RemoveEntitiesQueryHookResultEx;

// Function
type PickRemoveHubspotIntegrationModelFn = (
  mutation: RemoveHubspotIntegrationModelByIdMutation | null | undefined
) => number;
type RemoveHubspotIntegrationModelLazyMutationFn = MutationTuple<
  RemoveHubspotIntegrationModelByIdMutation,
  RemoveHubspotIntegrationModelByIdMutationVariables
>;
type RemoveHubspotIntegrationModelWrappedLazyMutationFn = ({
  hubspotIntegrationId,
  autoOptimisticResponse,
  options,
}: {
  hubspotIntegrationId: string;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<
      RemoveHubspotIntegrationModelByIdMutation,
      RemoveHubspotIntegrationModelByIdMutationVariables
    >,
    'variables'
  >;
}) => Promise<RemoveHubspotIntegrationModelByIdMutationResultEx>;
export type RemoveHubspotIntegrationModelLazyMutationReturn = [
  RemoveHubspotIntegrationModelWrappedLazyMutationFn,
  RemoveHubspotIntegrationModelByIdMutationResultEx
];

function useRemoveHubspotIntegrationModelById(
  options?: Omit<
    MutationHookOptions<RemoveHubspotIntegrationModelByIdMutation, RemoveHubspotIntegrationModelByIdMutationVariables>,
    'mutation' | 'variables'
  >
) {
  const lazyMutation: RemoveHubspotIntegrationModelLazyMutationFn = useMutation<
    RemoveHubspotIntegrationModelByIdMutation,
    RemoveHubspotIntegrationModelByIdMutationVariables
  >(RemoveHubspotIntegrationModelByIdDocument, options);

  const pickAffectedRows: PickRemoveHubspotIntegrationModelFn = (
    mutation: RemoveHubspotIntegrationModelByIdMutation | null | undefined
  ) => {
    return mutation?.delete_hubspotIntegration?.affected_rows || 0;
  };

  const wrappedLazyMutation: RemoveHubspotIntegrationModelWrappedLazyMutationFn = async ({
    hubspotIntegrationId,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<
      RemoveHubspotIntegrationModelMutation,
      RemoveHubspotIntegrationModelByIdMutationVariables
    > = { variables: { id: hubspotIntegrationId }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<RemoveHubspotIntegrationModelMutation>(
        { operationType: 'delete', entityName: 'hubspotIntegration', objects: [{ id: hubspotIntegrationId }] }
      );
    }
    if (!options || !options.update) {
      mutationOptions.update = generateUpdateFunctionForMutation<RemoveHubspotIntegrationModelByIdMutation>({
        operationType: 'delete',
        entityName: 'hubspotIntegration',
        entityId: hubspotIntegrationId,
      });
    }

    const fetchResult: RemoveHubspotIntegrationModelByIdFetchResult = await lazyMutation[0](mutationOptions);
    return { ...fetchResult, affected_rows: pickAffectedRows(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], affected_rows: pickAffectedRows(lazyMutation[1].data) }] as [
    typeof wrappedLazyMutation,
    typeof lazyMutation[1] & { affected_rows: ReturnType<typeof pickAffectedRows> }
  ];
}

//
//

// Types
type RemoveHubspotIntegrationModelObjectsMutationResult = FetchResult<
  RemoveHubspotIntegrationModelMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveHubspotIntegrationModelObjectsMutationResultEx = RemoveHubspotIntegrationModelObjectsMutationResult &
  RemoveEntitiesQueryHookResultEx;

// Function
type PickRemoveHubspotIntegrationModelObjectsFn = (
  mutation: RemoveHubspotIntegrationModelMutation | null | undefined
) => number;
type RemoveHubspotIntegrationModelObjectsLazyMutationFn = MutationTuple<
  RemoveHubspotIntegrationModelMutation,
  RemoveHubspotIntegrationModelMutationVariables
>;
type RemoveHubspotIntegrationModelObjectsWrappedLazyMutationFn = (
  options: MutationFunctionOptions<
    RemoveHubspotIntegrationModelMutation,
    RemoveHubspotIntegrationModelMutationVariables
  >
) => Promise<RemoveHubspotIntegrationModelObjectsMutationResultEx>;
export type RemoveHubspotIntegrationModelObjectsLazyMutationReturn = [
  RemoveHubspotIntegrationModelObjectsWrappedLazyMutationFn,
  RemoveHubspotIntegrationModelObjectsMutationResultEx
];

function useRemoveHubspotIntegrationModelObjects(
  options?: Omit<
    MutationHookOptions<RemoveHubspotIntegrationModelMutation, RemoveHubspotIntegrationModelMutationVariables>,
    'mutation'
  >
): RemoveHubspotIntegrationModelObjectsLazyMutationReturn {
  const lazyMutation = useMutation<
    RemoveHubspotIntegrationModelMutation,
    RemoveHubspotIntegrationModelMutationVariables
  >(RemoveHubspotIntegrationModelDocument, options);

  const pickAffectedRows: PickRemoveHubspotIntegrationModelObjectsFn = (
    mutation: RemoveHubspotIntegrationModelMutation | null | undefined
  ) => {
    return mutation?.delete_hubspotIntegration?.affected_rows || 0;
  };

  const wrappedLazyMutation: RemoveHubspotIntegrationModelObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: RemoveHubspotIntegrationModelObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, affected_rows: pickAffectedRows(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], affected_rows: pickAffectedRows(lazyMutation[1].data) }];
}

// HubspotIntegration Fragment Helper Object
//------------------------------------------------

export const HubspotIntegrationFragmentGQLHooks = {
  useQueryById: useQueryHubspotIntegrationById,
  useQueryByIdLazy: useQueryHubspotIntegrationByIdLazy,
  useQueryObjects: useQueryHubspotIntegrationObjects,
  useQueryObjectsLazy: useQueryHubspotIntegrationObjectsLazy,
  useSubscribeToById: useSubscribeToHubspotIntegrationById,
  useSubscribeToObjects: useSubscribeToHubspotIntegrationObjects,
  useInsert: useInsertHubspotIntegration,
  useInsertWithOnConflict: useInsertHubspotIntegrationWithOnConflict,
  useInsertObjects: useInsertHubspotIntegrationObjects,
  useUpdateById: useUpdateHubspotIntegrationById,
  useUpdateObjects: useUpdateHubspotIntegrationObjects,
};

// hubspotIntegration MODEL HOOKS OBJECT
//------------------------------------------------

export const HubspotIntegrationModelGQLHooks = {
  useRemoveById: useRemoveHubspotIntegrationModelById,
  useRemoveObjects: useRemoveHubspotIntegrationModelObjects,
};

// salesforceIntegration REACT
//------------------------------------------------

export type SalesforceIntegrationByIdHookResultEx = {
  salesforceIntegration: SalesforceIntegrationFragment | null | undefined;
};
export type SalesforceIntegrationObjectsHookResultEx = { objects: SalesforceIntegrationFragment[] };

/**
 *  Query Hook
 */

// Types
type QuerySalesforceIntegrationByIdResult = LazyQueryResult<
  QuerySalesforceIntegrationByIdQuery,
  QuerySalesforceIntegrationByIdQueryVariables
>;
type QuerySalesforceIntegrationByIdSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<
          QuerySalesforceIntegrationByIdQuery,
          QuerySalesforceIntegrationByIdQueryVariables,
          QuerySalesforceIntegrationByIdQuery
        >,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QuerySalesforceIntegrationByIdResultEx = Omit<QuerySalesforceIntegrationByIdResult, 'subscribeToMore'> & {
  subscribeToMore: QuerySalesforceIntegrationByIdSubScribeToMore;
} & SalesforceIntegrationByIdHookResultEx;

// Function
function useQuerySalesforceIntegrationById({
  salesforceIntegrationId,
  options,
}: {
  salesforceIntegrationId: string;
  options?: Omit<
    QueryHookOptions<QuerySalesforceIntegrationByIdQuery, QuerySalesforceIntegrationByIdQueryVariables>,
    'query' | 'variables'
  >;
}): QuerySalesforceIntegrationByIdResultEx {
  const _query: QuerySalesforceIntegrationByIdResult = useQuery<
    QuerySalesforceIntegrationByIdQuery,
    QuerySalesforceIntegrationByIdQueryVariables
  >(QuerySalesforceIntegrationByIdDocument, { variables: { salesforceIntegrationId }, ...options });

  const typedSubscribeToMore: QuerySalesforceIntegrationByIdSubScribeToMore = (options) => {
    _query.subscribeToMore({
      document: QuerySalesforceIntegrationByIdDocument,
      variables: { salesforceIntegrationId } as QuerySalesforceIntegrationByIdQueryVariables,
      ...options,
    });
  };
  const { subscribeToMore, ...query } = _query;
  return {
    ...query,
    subscribeToMore: typedSubscribeToMore,
    salesforceIntegration: query?.data?.salesforceIntegration_by_pk,
  };
}

/**
 *  Lazy Query Hook
 */

// Types
type PickQuerySalesforceIntegrationByIdFn = (
  query: QuerySalesforceIntegrationByIdQuery | null | undefined
) => SalesforceIntegrationFragment | null | undefined;
type QuerySalesforceIntegrationByIdLazyFn = [
  (options?: QueryLazyOptions<QuerySalesforceIntegrationByIdQueryVariables> | undefined) => void,
  QuerySalesforceIntegrationByIdResult
];
type QuerySalesforceIntegrationByIdWrappedLazyFn = (
  options: Omit<QueryLazyOptions<QuerySalesforceIntegrationByIdQueryVariables>, 'variables'>
) => void;
export type QuerySalesforceIntegrationByIdLazyReturn = [
  QuerySalesforceIntegrationByIdWrappedLazyFn,
  QuerySalesforceIntegrationByIdResultEx
];

// Function
function useQuerySalesforceIntegrationByIdLazy({
  salesforceIntegrationId,
  options,
}: {
  salesforceIntegrationId: string;
  options?: Omit<
    QueryHookOptions<QuerySalesforceIntegrationByIdQuery, QuerySalesforceIntegrationByIdQueryVariables>,
    'query' | 'variables'
  >;
}): QuerySalesforceIntegrationByIdLazyReturn {
  const lazyQuery: QuerySalesforceIntegrationByIdLazyFn = useLazyQuery<
    QuerySalesforceIntegrationByIdQuery,
    QuerySalesforceIntegrationByIdQueryVariables
  >(QuerySalesforceIntegrationByIdDocument, options);

  // Setting up typed version of lazyQuery
  const pickQuerySalesforceIntegrationById: PickQuerySalesforceIntegrationByIdFn = (query) => {
    return query && query.salesforceIntegration_by_pk;
  };
  const wrappedLazyQuery: QuerySalesforceIntegrationByIdWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QuerySalesforceIntegrationByIdSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({
        document: QuerySalesforceIntegrationByIdDocument,
        variables: { salesforceIntegrationId } as QuerySalesforceIntegrationByIdQueryVariables,
        ...options,
      });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    {
      ...lazyQueryResult,
      subscribeToMore: typedSubcribeToMore,
      salesforceIntegration: pickQuerySalesforceIntegrationById(lazyQuery[1].data),
    },
  ];
}

/**
 *  Query Collection Hook
 */

// Types
export type QuerySalesforceIntegrationObjectsResult = LazyQueryResult<
  QuerySalesforceIntegrationObjectsQuery,
  QuerySalesforceIntegrationObjectsQueryVariables
>;
type QuerySalesforceIntegrationObjectsSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<
          QuerySalesforceIntegrationObjectsQuery,
          QuerySalesforceIntegrationObjectsQueryVariables,
          QuerySalesforceIntegrationObjectsQuery
        >,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QuerySalesforceIntegrationObjectsResultEx = Omit<
  QuerySalesforceIntegrationObjectsResult,
  'subscribeToMore'
> & { subscribeToMore: QuerySalesforceIntegrationObjectsSubScribeToMore } & SalesforceIntegrationObjectsHookResultEx;

// Function
function useQuerySalesforceIntegrationObjects(
  options: Omit<
    QueryHookOptions<QuerySalesforceIntegrationObjectsQuery, QuerySalesforceIntegrationObjectsQueryVariables>,
    'query'
  >
): QuerySalesforceIntegrationObjectsResultEx {
  const _query: QuerySalesforceIntegrationObjectsResult = useQuery<
    QuerySalesforceIntegrationObjectsQuery,
    QuerySalesforceIntegrationObjectsQueryVariables
  >(QuerySalesforceIntegrationObjectsDocument, options);

  const typedSubscribeToMore: QuerySalesforceIntegrationObjectsSubScribeToMore = (options) => {
    _query.subscribeToMore({ document: QuerySalesforceIntegrationObjectsDocument, ...options });
  };
  const { subscribeToMore, ...query } = _query;

  return { ...query, subscribeToMore: typedSubscribeToMore, objects: query?.data?.salesforceIntegration || [] };
}

/**
 *  Lazy Query Collection Hook
 */

// Types
type PickQuerySalesforceIntegrationObjectsFn = (
  query: QuerySalesforceIntegrationObjectsQuery | null | undefined
) => SalesforceIntegrationFragment[];
type QuerySalesforceIntegrationObjectsLazyFn = [
  (options?: QueryLazyOptions<QuerySalesforceIntegrationObjectsQueryVariables> | undefined) => void,
  QuerySalesforceIntegrationObjectsResult
];
type QuerySalesforceIntegrationObjectsWrappedLazyFn = (
  options?: QueryLazyOptions<QuerySalesforceIntegrationObjectsQueryVariables>
) => void;
export type QuerySalesforceIntegrationObjectsLazyReturn = [
  QuerySalesforceIntegrationObjectsWrappedLazyFn,
  QuerySalesforceIntegrationObjectsResultEx
];

// Function
function useQuerySalesforceIntegrationObjectsLazy(
  options?: Omit<
    LazyQueryHookOptions<QuerySalesforceIntegrationObjectsQuery, QuerySalesforceIntegrationObjectsQueryVariables>,
    'query'
  >
): QuerySalesforceIntegrationObjectsLazyReturn {
  const lazyQuery: QuerySalesforceIntegrationObjectsLazyFn = useLazyQuery<
    QuerySalesforceIntegrationObjectsQuery,
    QuerySalesforceIntegrationObjectsQueryVariables
  >(QuerySalesforceIntegrationObjectsDocument, options);

  // Setting up typed version of lazyQuery
  const pickObjects: PickQuerySalesforceIntegrationObjectsFn = (
    query: QuerySalesforceIntegrationObjectsQuery | null | undefined
  ) => {
    return query?.salesforceIntegration || [];
  };
  const wrappedLazyQuery: QuerySalesforceIntegrationObjectsWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QuerySalesforceIntegrationObjectsSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({ document: QuerySalesforceIntegrationObjectsDocument, ...options });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    { ...lazyQueryResult, subscribeToMore: typedSubcribeToMore, objects: pickObjects(lazyQuery[1].data) },
  ];
}

/**
 *  Subscription Hook
 */

// Types
type SubscribeToSalesforceIntegrationByIdResult = {
  variables?: SubscribeToSalesforceIntegrationByIdSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToSalesforceIntegrationByIdSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToSalesforceIntegrationByIdResultEx = SubscribeToSalesforceIntegrationByIdResult &
  SalesforceIntegrationByIdHookResultEx;

// Function
function useSubscribeToSalesforceIntegrationById({
  salesforceIntegrationId,
  options,
}: {
  salesforceIntegrationId: string;
  options?: Omit<
    SubscriptionHookOptions<
      SubscribeToSalesforceIntegrationByIdSubscription,
      SubscribeToSalesforceIntegrationByIdSubscriptionVariables
    >,
    'query' | 'variables'
  >;
}): SubscribeToSalesforceIntegrationByIdResultEx {
  const subscription: SubscribeToSalesforceIntegrationByIdResult = useSubscription<
    SubscribeToSalesforceIntegrationByIdSubscription,
    SubscribeToSalesforceIntegrationByIdSubscriptionVariables
  >(SubscribeToSalesforceIntegrationByIdDocument, { variables: { salesforceIntegrationId }, ...options });
  return { ...subscription, salesforceIntegration: subscription?.data?.salesforceIntegration_by_pk };
}

/**
 *  Subscription Collection Hook
 */

// Types
export type SubscribeToSalesforceIntegrationObjectsResult = {
  variables?: SubscribeToSalesforceIntegrationObjectsSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToSalesforceIntegrationObjectsSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToSalesforceIntegrationObjectsResultEx = SubscribeToSalesforceIntegrationObjectsResult &
  SalesforceIntegrationObjectsHookResultEx;

// Function
function useSubscribeToSalesforceIntegrationObjects(
  options: Omit<
    SubscriptionHookOptions<
      SubscribeToSalesforceIntegrationObjectsSubscription,
      SubscribeToSalesforceIntegrationObjectsSubscriptionVariables
    >,
    'query'
  >
): SubscribeToSalesforceIntegrationObjectsResultEx {
  const subscription: SubscribeToSalesforceIntegrationObjectsResult = useSubscription<
    SubscribeToSalesforceIntegrationObjectsSubscription,
    SubscribeToSalesforceIntegrationObjectsSubscriptionVariables
  >(SubscribeToSalesforceIntegrationObjectsDocument, options);
  return { ...subscription, objects: subscription?.data?.salesforceIntegration || [] };
}

/**
 *  Insert Hooks
 */

// Types
type InsertSalesforceIntegrationMutationResult = FetchResult<
  InsertSalesforceIntegrationMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertSalesforceIntegrationMutationResultEx = InsertSalesforceIntegrationMutationResult &
  SalesforceIntegrationByIdHookResultEx;

type PickInsertSalesforceIntegrationFn = (
  mutation: InsertSalesforceIntegrationMutation | null | undefined
) => SalesforceIntegrationFragment | null | undefined;
type InsertSalesforceIntegrationLazyMutationFn = MutationTuple<
  InsertSalesforceIntegrationMutation,
  InsertSalesforceIntegrationMutationVariables
>;
type InsertSalesforceIntegrationWrappedLazyMutationFn = ({
  salesforceIntegration,
  autoOptimisticResponse,
  options,
}: {
  salesforceIntegration: SalesforceIntegration_Insert_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<InsertSalesforceIntegrationMutation, InsertSalesforceIntegrationMutationVariables>,
    'variables'
  >;
}) => Promise<InsertSalesforceIntegrationMutationResultEx>;
export type InsertSalesforceIntegrationLazyMutationReturn = [
  InsertSalesforceIntegrationWrappedLazyMutationFn,
  InsertSalesforceIntegrationMutationResultEx
];

// Function
function useInsertSalesforceIntegration(
  options?: Omit<
    MutationHookOptions<InsertSalesforceIntegrationMutation, InsertSalesforceIntegrationMutationVariables>,
    'mutation' | 'variables'
  >
): InsertSalesforceIntegrationLazyMutationReturn {
  const lazyMutation: InsertSalesforceIntegrationLazyMutationFn = useMutation<
    InsertSalesforceIntegrationMutation,
    InsertSalesforceIntegrationMutationVariables
  >(InsertSalesforceIntegrationDocument, options);
  const pickSalesforceIntegration: PickInsertSalesforceIntegrationFn = (mutation) => {
    return mutation?.insert_salesforceIntegration?.returning && mutation?.insert_salesforceIntegration?.returning[0];
  };

  const wrappedLazyMutation: InsertSalesforceIntegrationWrappedLazyMutationFn = async ({
    salesforceIntegration,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: salesforceIntegration });
    const mutationOptions: MutationFunctionOptions<
      InsertSalesforceIntegrationMutation,
      InsertSalesforceIntegrationMutationVariables
    > = { variables: { objects: [objectForInsert] }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id)
        throw new Error(`if autoOptimisticResponse = true, id must be set in object 'salesforceIntegration'`);
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertSalesforceIntegrationMutation>({
        operationType: 'insert',
        entityName: 'salesforceIntegration',
        objects: [objectForInsert as SalesforceIntegration_Insert_Input & ObjectWithId],
      });
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, salesforceIntegration: pickSalesforceIntegration(fetchResult.data) };
  };

  return [
    wrappedLazyMutation,
    { ...lazyMutation[1], salesforceIntegration: pickSalesforceIntegration(lazyMutation[1].data) },
  ];
}

//
//

// Types
type InsertSalesforceIntegrationWithOnConflictMutationResult = FetchResult<
  InsertSalesforceIntegrationWithOnConflictMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertSalesforceIntegrationWithOnConflictMutationResultEx = InsertSalesforceIntegrationWithOnConflictMutationResult &
  SalesforceIntegrationByIdHookResultEx;

type InsertSalesforceIntegrationWithOnConflictLazyMutationFn = MutationTuple<
  InsertSalesforceIntegrationWithOnConflictMutation,
  InsertSalesforceIntegrationWithOnConflictMutationVariables
>;
type InsertSalesforceIntegrationWithOnConflictWrappedLazyMutationFn = ({
  salesforceIntegration,
  onConflict,
  autoOptimisticResponse,
  options,
}: {
  salesforceIntegration: SalesforceIntegration_Insert_Input;
  onConflict: SalesforceIntegration_On_Conflict;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<
      InsertSalesforceIntegrationWithOnConflictMutation,
      InsertSalesforceIntegrationWithOnConflictMutationVariables
    >,
    'variables'
  >;
}) => Promise<InsertSalesforceIntegrationWithOnConflictMutationResultEx>;
export type InsertSalesforceIntegrationWithOnConflictLazyMutationReturn = [
  InsertSalesforceIntegrationWithOnConflictWrappedLazyMutationFn,
  InsertSalesforceIntegrationWithOnConflictMutationResultEx
];

// Function
function useInsertSalesforceIntegrationWithOnConflict(
  options?: Omit<
    MutationHookOptions<
      InsertSalesforceIntegrationWithOnConflictMutation,
      InsertSalesforceIntegrationWithOnConflictMutationVariables
    >,
    'mutation' | 'variables'
  >
): InsertSalesforceIntegrationWithOnConflictLazyMutationReturn {
  const lazyMutation: InsertSalesforceIntegrationWithOnConflictLazyMutationFn = useMutation<
    InsertSalesforceIntegrationWithOnConflictMutation,
    InsertSalesforceIntegrationWithOnConflictMutationVariables
  >(InsertSalesforceIntegrationWithOnConflictDocument, options);
  const pickSalesforceIntegration: PickInsertSalesforceIntegrationFn = (
    mutation: InsertSalesforceIntegrationWithOnConflictMutation | null | undefined
  ) => {
    return mutation?.insert_salesforceIntegration?.returning && mutation.insert_salesforceIntegration.returning[0];
  };

  const wrappedLazyMutation: InsertSalesforceIntegrationWithOnConflictWrappedLazyMutationFn = async ({
    salesforceIntegration,
    onConflict,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: salesforceIntegration });
    const mutationOptions: MutationFunctionOptions<
      InsertSalesforceIntegrationWithOnConflictMutation,
      InsertSalesforceIntegrationWithOnConflictMutationVariables
    > = { variables: { objects: [objectForInsert], onConflict }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id)
        throw new Error(`if autoOptimisticResponse = true, id must be set in object 'salesforceIntegration'`);
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertSalesforceIntegrationWithOnConflictMutation>(
        {
          operationType: 'insert',
          entityName: 'salesforceIntegration',
          objects: [objectForInsert as SalesforceIntegration_Insert_Input & ObjectWithId],
        }
      );
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, salesforceIntegration: pickSalesforceIntegration(fetchResult.data) };
  };

  return [
    wrappedLazyMutation,
    { ...lazyMutation[1], salesforceIntegration: pickSalesforceIntegration(lazyMutation[1].data) },
  ];
}

// Types
type InsertSalesforceIntegrationObjectsMutationResult = FetchResult<
  InsertSalesforceIntegrationMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertSalesforceIntegrationObjectsMutationResultEx = InsertSalesforceIntegrationMutationResult &
  SalesforceIntegrationObjectsHookResultEx;

type PickInsertSalesforceIntegrationObjectsFn = (
  mutation: InsertSalesforceIntegrationMutation | null | undefined
) => SalesforceIntegrationFragment[];
type InsertSalesforceIntegrationObjectsLazyMutationFn = MutationTuple<
  InsertSalesforceIntegrationMutation,
  InsertSalesforceIntegrationMutationVariables
>;
type InsertSalesforceIntegrationObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<InsertSalesforceIntegrationMutation, InsertSalesforceIntegrationMutationVariables>
) => Promise<InsertSalesforceIntegrationObjectsMutationResultEx>;
export type InsertSalesforceIntegrationObjectsLazyMutationReturn = [
  InsertSalesforceIntegrationObjectsWrappedLazyMutationFn,
  InsertSalesforceIntegrationObjectsMutationResultEx
];

// Function
function useInsertSalesforceIntegrationObjects(
  options?: Omit<
    MutationHookOptions<InsertSalesforceIntegrationMutation, InsertSalesforceIntegrationMutationVariables>,
    'mutation'
  >
): InsertSalesforceIntegrationObjectsLazyMutationReturn {
  const lazyMutation: InsertSalesforceIntegrationObjectsLazyMutationFn = useMutation<
    InsertSalesforceIntegrationMutation,
    InsertSalesforceIntegrationMutationVariables
  >(InsertSalesforceIntegrationDocument, options);
  const pickObjects: PickInsertSalesforceIntegrationObjectsFn = (
    mutation: InsertSalesforceIntegrationMutation | null | undefined
  ) => {
    return mutation?.insert_salesforceIntegration?.returning || [];
  };

  const wrappedLazyMutation: InsertSalesforceIntegrationObjectsWrappedLazyMutationFn = async (options) => {
    if (options && options.variables && options.variables.objects)
      options.variables.objects = options.variables.objects.map((objectItem) =>
        stripInsertInputClientFields({ input: objectItem })
      );
    const fetchResult: InsertSalesforceIntegrationObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Update Hooks
 */

type UpdateSalesforceIntegrationByIdMutationResult = FetchResult<
  UpdateSalesforceIntegrationByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateSalesforceIntegrationByIdMutationResultEx = UpdateSalesforceIntegrationByIdMutationResult &
  SalesforceIntegrationByIdHookResultEx;

type PickUpdateSalesforceIntegrationByIdFn = (
  mutation: UpdateSalesforceIntegrationByIdMutation | null | undefined
) => SalesforceIntegrationFragment | null | undefined;
type UpdateSalesforceIntegrationByIdLazyMutationFn = MutationTuple<
  UpdateSalesforceIntegrationByIdMutation,
  UpdateSalesforceIntegrationByIdMutationVariables
>;
type UpdateSalesforceIntegrationByIdWrappedLazyMutationFn = ({
  salesforceIntegrationId,
  set,
  autoOptimisticResponse,
  options,
}: {
  salesforceIntegrationId: string;
  set: SalesforceIntegration_Set_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<UpdateSalesforceIntegrationByIdMutation, UpdateSalesforceIntegrationByIdMutationVariables>,
    'variables'
  >;
}) => Promise<UpdateSalesforceIntegrationByIdMutationResultEx>;
export type UpdateSalesforceIntegrationByIdLazyMutationReturn = [
  UpdateSalesforceIntegrationByIdWrappedLazyMutationFn,
  UpdateSalesforceIntegrationByIdMutationResultEx
];

function useUpdateSalesforceIntegrationById(
  options?: Omit<
    MutationHookOptions<UpdateSalesforceIntegrationByIdMutation, UpdateSalesforceIntegrationByIdMutationVariables>,
    'mutation' | 'variables'
  >
): UpdateSalesforceIntegrationByIdLazyMutationReturn {
  const lazyMutation: UpdateSalesforceIntegrationByIdLazyMutationFn = useMutation<
    UpdateSalesforceIntegrationByIdMutation,
    UpdateSalesforceIntegrationByIdMutationVariables
  >(UpdateSalesforceIntegrationByIdDocument, options);

  const pickSalesforceIntegration: PickUpdateSalesforceIntegrationByIdFn = (mutation) => {
    return mutation?.update_salesforceIntegration?.returning && mutation.update_salesforceIntegration!.returning[0];
  };

  const wrappedLazyMutation: UpdateSalesforceIntegrationByIdWrappedLazyMutationFn = async ({
    salesforceIntegrationId,
    set,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<
      UpdateSalesforceIntegrationByIdMutation,
      UpdateSalesforceIntegrationByIdMutationVariables
    > = { variables: { id: salesforceIntegrationId, set }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateSalesforceIntegrationByIdMutation>(
        {
          operationType: 'update',
          entityName: 'salesforceIntegration',
          objects: [{ id: salesforceIntegrationId, ...set }],
        }
      );
    }

    const fetchResult: UpdateSalesforceIntegrationByIdMutationResult = await lazyMutation[0]({
      variables: { id: salesforceIntegrationId, set },
      ...mutationOptions,
    });
    return { ...fetchResult, salesforceIntegration: pickSalesforceIntegration(fetchResult.data) };
  };

  return [
    wrappedLazyMutation,
    { ...lazyMutation[1], salesforceIntegration: pickSalesforceIntegration(lazyMutation[1].data) },
  ];
}

//
//

// Types
type UpdateSalesforceIntegrationObjectsMutationResult = FetchResult<
  UpdateSalesforceIntegrationMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateSalesforceIntegrationObjectsMutationResultEx = UpdateSalesforceIntegrationObjectsMutationResult &
  SalesforceIntegrationObjectsHookResultEx;

// Function
type PickUpdateSalesforceIntegrationObjectsFn = (
  mutation: UpdateSalesforceIntegrationMutation | null | undefined
) => SalesforceIntegrationFragment[];
type UpdateSalesforceIntegrationObjectsLazyMutationFn = MutationTuple<
  UpdateSalesforceIntegrationMutation,
  UpdateSalesforceIntegrationMutationVariables
>;
type UpdateSalesforceIntegrationObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<UpdateSalesforceIntegrationMutation, UpdateSalesforceIntegrationMutationVariables>
) => Promise<UpdateSalesforceIntegrationObjectsMutationResultEx>;
export type UpdateSalesforceIntegrationObjectsLazyMutationReturn = [
  UpdateSalesforceIntegrationObjectsWrappedLazyMutationFn,
  UpdateSalesforceIntegrationObjectsMutationResultEx
];

function useUpdateSalesforceIntegrationObjects(
  options?: Omit<
    MutationHookOptions<UpdateSalesforceIntegrationMutation, UpdateSalesforceIntegrationMutationVariables>,
    'mutation'
  >
): UpdateSalesforceIntegrationObjectsLazyMutationReturn {
  const lazyMutation: UpdateSalesforceIntegrationObjectsLazyMutationFn = useMutation<
    UpdateSalesforceIntegrationMutation,
    UpdateSalesforceIntegrationMutationVariables
  >(UpdateSalesforceIntegrationDocument, options);

  const pickObjects: PickUpdateSalesforceIntegrationObjectsFn = (
    mutation: UpdateSalesforceIntegrationMutation | null | undefined
  ) => {
    return mutation?.update_salesforceIntegration?.returning || [];
  };

  const wrappedLazyMutation: UpdateSalesforceIntegrationObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: UpdateSalesforceIntegrationObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Delete Hooks
 */

// Types
type RemoveSalesforceIntegrationModelByIdFetchResult = FetchResult<
  RemoveSalesforceIntegrationModelByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveSalesforceIntegrationModelByIdMutationResultEx = RemoveSalesforceIntegrationModelByIdFetchResult &
  RemoveEntitiesQueryHookResultEx;

// Function
type PickRemoveSalesforceIntegrationModelFn = (
  mutation: RemoveSalesforceIntegrationModelByIdMutation | null | undefined
) => number;
type RemoveSalesforceIntegrationModelLazyMutationFn = MutationTuple<
  RemoveSalesforceIntegrationModelByIdMutation,
  RemoveSalesforceIntegrationModelByIdMutationVariables
>;
type RemoveSalesforceIntegrationModelWrappedLazyMutationFn = ({
  salesforceIntegrationId,
  autoOptimisticResponse,
  options,
}: {
  salesforceIntegrationId: string;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<
      RemoveSalesforceIntegrationModelByIdMutation,
      RemoveSalesforceIntegrationModelByIdMutationVariables
    >,
    'variables'
  >;
}) => Promise<RemoveSalesforceIntegrationModelByIdMutationResultEx>;
export type RemoveSalesforceIntegrationModelLazyMutationReturn = [
  RemoveSalesforceIntegrationModelWrappedLazyMutationFn,
  RemoveSalesforceIntegrationModelByIdMutationResultEx
];

function useRemoveSalesforceIntegrationModelById(
  options?: Omit<
    MutationHookOptions<
      RemoveSalesforceIntegrationModelByIdMutation,
      RemoveSalesforceIntegrationModelByIdMutationVariables
    >,
    'mutation' | 'variables'
  >
) {
  const lazyMutation: RemoveSalesforceIntegrationModelLazyMutationFn = useMutation<
    RemoveSalesforceIntegrationModelByIdMutation,
    RemoveSalesforceIntegrationModelByIdMutationVariables
  >(RemoveSalesforceIntegrationModelByIdDocument, options);

  const pickAffectedRows: PickRemoveSalesforceIntegrationModelFn = (
    mutation: RemoveSalesforceIntegrationModelByIdMutation | null | undefined
  ) => {
    return mutation?.delete_salesforceIntegration?.affected_rows || 0;
  };

  const wrappedLazyMutation: RemoveSalesforceIntegrationModelWrappedLazyMutationFn = async ({
    salesforceIntegrationId,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<
      RemoveSalesforceIntegrationModelMutation,
      RemoveSalesforceIntegrationModelByIdMutationVariables
    > = { variables: { id: salesforceIntegrationId }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<RemoveSalesforceIntegrationModelMutation>(
        { operationType: 'delete', entityName: 'salesforceIntegration', objects: [{ id: salesforceIntegrationId }] }
      );
    }
    if (!options || !options.update) {
      mutationOptions.update = generateUpdateFunctionForMutation<RemoveSalesforceIntegrationModelByIdMutation>({
        operationType: 'delete',
        entityName: 'salesforceIntegration',
        entityId: salesforceIntegrationId,
      });
    }

    const fetchResult: RemoveSalesforceIntegrationModelByIdFetchResult = await lazyMutation[0](mutationOptions);
    return { ...fetchResult, affected_rows: pickAffectedRows(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], affected_rows: pickAffectedRows(lazyMutation[1].data) }] as [
    typeof wrappedLazyMutation,
    typeof lazyMutation[1] & { affected_rows: ReturnType<typeof pickAffectedRows> }
  ];
}

//
//

// Types
type RemoveSalesforceIntegrationModelObjectsMutationResult = FetchResult<
  RemoveSalesforceIntegrationModelMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveSalesforceIntegrationModelObjectsMutationResultEx = RemoveSalesforceIntegrationModelObjectsMutationResult &
  RemoveEntitiesQueryHookResultEx;

// Function
type PickRemoveSalesforceIntegrationModelObjectsFn = (
  mutation: RemoveSalesforceIntegrationModelMutation | null | undefined
) => number;
type RemoveSalesforceIntegrationModelObjectsLazyMutationFn = MutationTuple<
  RemoveSalesforceIntegrationModelMutation,
  RemoveSalesforceIntegrationModelMutationVariables
>;
type RemoveSalesforceIntegrationModelObjectsWrappedLazyMutationFn = (
  options: MutationFunctionOptions<
    RemoveSalesforceIntegrationModelMutation,
    RemoveSalesforceIntegrationModelMutationVariables
  >
) => Promise<RemoveSalesforceIntegrationModelObjectsMutationResultEx>;
export type RemoveSalesforceIntegrationModelObjectsLazyMutationReturn = [
  RemoveSalesforceIntegrationModelObjectsWrappedLazyMutationFn,
  RemoveSalesforceIntegrationModelObjectsMutationResultEx
];

function useRemoveSalesforceIntegrationModelObjects(
  options?: Omit<
    MutationHookOptions<RemoveSalesforceIntegrationModelMutation, RemoveSalesforceIntegrationModelMutationVariables>,
    'mutation'
  >
): RemoveSalesforceIntegrationModelObjectsLazyMutationReturn {
  const lazyMutation = useMutation<
    RemoveSalesforceIntegrationModelMutation,
    RemoveSalesforceIntegrationModelMutationVariables
  >(RemoveSalesforceIntegrationModelDocument, options);

  const pickAffectedRows: PickRemoveSalesforceIntegrationModelObjectsFn = (
    mutation: RemoveSalesforceIntegrationModelMutation | null | undefined
  ) => {
    return mutation?.delete_salesforceIntegration?.affected_rows || 0;
  };

  const wrappedLazyMutation: RemoveSalesforceIntegrationModelObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: RemoveSalesforceIntegrationModelObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, affected_rows: pickAffectedRows(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], affected_rows: pickAffectedRows(lazyMutation[1].data) }];
}

// SalesforceIntegration Fragment Helper Object
//------------------------------------------------

export const SalesforceIntegrationFragmentGQLHooks = {
  useQueryById: useQuerySalesforceIntegrationById,
  useQueryByIdLazy: useQuerySalesforceIntegrationByIdLazy,
  useQueryObjects: useQuerySalesforceIntegrationObjects,
  useQueryObjectsLazy: useQuerySalesforceIntegrationObjectsLazy,
  useSubscribeToById: useSubscribeToSalesforceIntegrationById,
  useSubscribeToObjects: useSubscribeToSalesforceIntegrationObjects,
  useInsert: useInsertSalesforceIntegration,
  useInsertWithOnConflict: useInsertSalesforceIntegrationWithOnConflict,
  useInsertObjects: useInsertSalesforceIntegrationObjects,
  useUpdateById: useUpdateSalesforceIntegrationById,
  useUpdateObjects: useUpdateSalesforceIntegrationObjects,
};

// salesforceIntegration MODEL HOOKS OBJECT
//------------------------------------------------

export const SalesforceIntegrationModelGQLHooks = {
  useRemoveById: useRemoveSalesforceIntegrationModelById,
  useRemoveObjects: useRemoveSalesforceIntegrationModelObjects,
};

// qaTaskAction REACT
//------------------------------------------------

export type QaTaskActionByIdHookResultEx = { qaTaskAction: QaTaskActionFragment | null | undefined };
export type QaTaskActionObjectsHookResultEx = { objects: QaTaskActionFragment[] };

/**
 *  Query Hook
 */

// Types
type QueryQaTaskActionByIdResult = LazyQueryResult<QueryQaTaskActionByIdQuery, QueryQaTaskActionByIdQueryVariables>;
type QueryQaTaskActionByIdSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<
          QueryQaTaskActionByIdQuery,
          QueryQaTaskActionByIdQueryVariables,
          QueryQaTaskActionByIdQuery
        >,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryQaTaskActionByIdResultEx = Omit<QueryQaTaskActionByIdResult, 'subscribeToMore'> & {
  subscribeToMore: QueryQaTaskActionByIdSubScribeToMore;
} & QaTaskActionByIdHookResultEx;

// Function
function useQueryQaTaskActionById({
  qaTaskActionId,
  options,
}: {
  qaTaskActionId: string;
  options?: Omit<
    QueryHookOptions<QueryQaTaskActionByIdQuery, QueryQaTaskActionByIdQueryVariables>,
    'query' | 'variables'
  >;
}): QueryQaTaskActionByIdResultEx {
  const _query: QueryQaTaskActionByIdResult = useQuery<QueryQaTaskActionByIdQuery, QueryQaTaskActionByIdQueryVariables>(
    QueryQaTaskActionByIdDocument,
    { variables: { qaTaskActionId }, ...options }
  );

  const typedSubscribeToMore: QueryQaTaskActionByIdSubScribeToMore = (options) => {
    _query.subscribeToMore({
      document: QueryQaTaskActionByIdDocument,
      variables: { qaTaskActionId } as QueryQaTaskActionByIdQueryVariables,
      ...options,
    });
  };
  const { subscribeToMore, ...query } = _query;
  return { ...query, subscribeToMore: typedSubscribeToMore, qaTaskAction: query?.data?.qaTaskAction_by_pk };
}

/**
 *  Lazy Query Hook
 */

// Types
type PickQueryQaTaskActionByIdFn = (
  query: QueryQaTaskActionByIdQuery | null | undefined
) => QaTaskActionFragment | null | undefined;
type QueryQaTaskActionByIdLazyFn = [
  (options?: QueryLazyOptions<QueryQaTaskActionByIdQueryVariables> | undefined) => void,
  QueryQaTaskActionByIdResult
];
type QueryQaTaskActionByIdWrappedLazyFn = (
  options: Omit<QueryLazyOptions<QueryQaTaskActionByIdQueryVariables>, 'variables'>
) => void;
export type QueryQaTaskActionByIdLazyReturn = [QueryQaTaskActionByIdWrappedLazyFn, QueryQaTaskActionByIdResultEx];

// Function
function useQueryQaTaskActionByIdLazy({
  qaTaskActionId,
  options,
}: {
  qaTaskActionId: string;
  options?: Omit<
    QueryHookOptions<QueryQaTaskActionByIdQuery, QueryQaTaskActionByIdQueryVariables>,
    'query' | 'variables'
  >;
}): QueryQaTaskActionByIdLazyReturn {
  const lazyQuery: QueryQaTaskActionByIdLazyFn = useLazyQuery<
    QueryQaTaskActionByIdQuery,
    QueryQaTaskActionByIdQueryVariables
  >(QueryQaTaskActionByIdDocument, options);

  // Setting up typed version of lazyQuery
  const pickQueryQaTaskActionById: PickQueryQaTaskActionByIdFn = (query) => {
    return query && query.qaTaskAction_by_pk;
  };
  const wrappedLazyQuery: QueryQaTaskActionByIdWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryQaTaskActionByIdSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({
        document: QueryQaTaskActionByIdDocument,
        variables: { qaTaskActionId } as QueryQaTaskActionByIdQueryVariables,
        ...options,
      });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    {
      ...lazyQueryResult,
      subscribeToMore: typedSubcribeToMore,
      qaTaskAction: pickQueryQaTaskActionById(lazyQuery[1].data),
    },
  ];
}

/**
 *  Query Collection Hook
 */

// Types
export type QueryQaTaskActionObjectsResult = LazyQueryResult<
  QueryQaTaskActionObjectsQuery,
  QueryQaTaskActionObjectsQueryVariables
>;
type QueryQaTaskActionObjectsSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<
          QueryQaTaskActionObjectsQuery,
          QueryQaTaskActionObjectsQueryVariables,
          QueryQaTaskActionObjectsQuery
        >,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryQaTaskActionObjectsResultEx = Omit<QueryQaTaskActionObjectsResult, 'subscribeToMore'> & {
  subscribeToMore: QueryQaTaskActionObjectsSubScribeToMore;
} & QaTaskActionObjectsHookResultEx;

// Function
function useQueryQaTaskActionObjects(
  options: Omit<QueryHookOptions<QueryQaTaskActionObjectsQuery, QueryQaTaskActionObjectsQueryVariables>, 'query'>
): QueryQaTaskActionObjectsResultEx {
  const _query: QueryQaTaskActionObjectsResult = useQuery<
    QueryQaTaskActionObjectsQuery,
    QueryQaTaskActionObjectsQueryVariables
  >(QueryQaTaskActionObjectsDocument, options);

  const typedSubscribeToMore: QueryQaTaskActionObjectsSubScribeToMore = (options) => {
    _query.subscribeToMore({ document: QueryQaTaskActionObjectsDocument, ...options });
  };
  const { subscribeToMore, ...query } = _query;

  return { ...query, subscribeToMore: typedSubscribeToMore, objects: query?.data?.qaTaskAction || [] };
}

/**
 *  Lazy Query Collection Hook
 */

// Types
type PickQueryQaTaskActionObjectsFn = (
  query: QueryQaTaskActionObjectsQuery | null | undefined
) => QaTaskActionFragment[];
type QueryQaTaskActionObjectsLazyFn = [
  (options?: QueryLazyOptions<QueryQaTaskActionObjectsQueryVariables> | undefined) => void,
  QueryQaTaskActionObjectsResult
];
type QueryQaTaskActionObjectsWrappedLazyFn = (
  options?: QueryLazyOptions<QueryQaTaskActionObjectsQueryVariables>
) => void;
export type QueryQaTaskActionObjectsLazyReturn = [
  QueryQaTaskActionObjectsWrappedLazyFn,
  QueryQaTaskActionObjectsResultEx
];

// Function
function useQueryQaTaskActionObjectsLazy(
  options?: Omit<LazyQueryHookOptions<QueryQaTaskActionObjectsQuery, QueryQaTaskActionObjectsQueryVariables>, 'query'>
): QueryQaTaskActionObjectsLazyReturn {
  const lazyQuery: QueryQaTaskActionObjectsLazyFn = useLazyQuery<
    QueryQaTaskActionObjectsQuery,
    QueryQaTaskActionObjectsQueryVariables
  >(QueryQaTaskActionObjectsDocument, options);

  // Setting up typed version of lazyQuery
  const pickObjects: PickQueryQaTaskActionObjectsFn = (query: QueryQaTaskActionObjectsQuery | null | undefined) => {
    return query?.qaTaskAction || [];
  };
  const wrappedLazyQuery: QueryQaTaskActionObjectsWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryQaTaskActionObjectsSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({ document: QueryQaTaskActionObjectsDocument, ...options });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    { ...lazyQueryResult, subscribeToMore: typedSubcribeToMore, objects: pickObjects(lazyQuery[1].data) },
  ];
}

/**
 *  Subscription Hook
 */

// Types
type SubscribeToQaTaskActionByIdResult = {
  variables?: SubscribeToQaTaskActionByIdSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToQaTaskActionByIdSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToQaTaskActionByIdResultEx = SubscribeToQaTaskActionByIdResult & QaTaskActionByIdHookResultEx;

// Function
function useSubscribeToQaTaskActionById({
  qaTaskActionId,
  options,
}: {
  qaTaskActionId: string;
  options?: Omit<
    SubscriptionHookOptions<SubscribeToQaTaskActionByIdSubscription, SubscribeToQaTaskActionByIdSubscriptionVariables>,
    'query' | 'variables'
  >;
}): SubscribeToQaTaskActionByIdResultEx {
  const subscription: SubscribeToQaTaskActionByIdResult = useSubscription<
    SubscribeToQaTaskActionByIdSubscription,
    SubscribeToQaTaskActionByIdSubscriptionVariables
  >(SubscribeToQaTaskActionByIdDocument, { variables: { qaTaskActionId }, ...options });
  return { ...subscription, qaTaskAction: subscription?.data?.qaTaskAction_by_pk };
}

/**
 *  Subscription Collection Hook
 */

// Types
export type SubscribeToQaTaskActionObjectsResult = {
  variables?: SubscribeToQaTaskActionObjectsSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToQaTaskActionObjectsSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToQaTaskActionObjectsResultEx = SubscribeToQaTaskActionObjectsResult &
  QaTaskActionObjectsHookResultEx;

// Function
function useSubscribeToQaTaskActionObjects(
  options: Omit<
    SubscriptionHookOptions<
      SubscribeToQaTaskActionObjectsSubscription,
      SubscribeToQaTaskActionObjectsSubscriptionVariables
    >,
    'query'
  >
): SubscribeToQaTaskActionObjectsResultEx {
  const subscription: SubscribeToQaTaskActionObjectsResult = useSubscription<
    SubscribeToQaTaskActionObjectsSubscription,
    SubscribeToQaTaskActionObjectsSubscriptionVariables
  >(SubscribeToQaTaskActionObjectsDocument, options);
  return { ...subscription, objects: subscription?.data?.qaTaskAction || [] };
}

/**
 *  Insert Hooks
 */

// Types
type InsertQaTaskActionMutationResult = FetchResult<
  InsertQaTaskActionMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertQaTaskActionMutationResultEx = InsertQaTaskActionMutationResult & QaTaskActionByIdHookResultEx;

type PickInsertQaTaskActionFn = (
  mutation: InsertQaTaskActionMutation | null | undefined
) => QaTaskActionFragment | null | undefined;
type InsertQaTaskActionLazyMutationFn = MutationTuple<InsertQaTaskActionMutation, InsertQaTaskActionMutationVariables>;
type InsertQaTaskActionWrappedLazyMutationFn = ({
  qaTaskAction,
  autoOptimisticResponse,
  options,
}: {
  qaTaskAction: QaTaskAction_Insert_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<MutationFunctionOptions<InsertQaTaskActionMutation, InsertQaTaskActionMutationVariables>, 'variables'>;
}) => Promise<InsertQaTaskActionMutationResultEx>;
export type InsertQaTaskActionLazyMutationReturn = [
  InsertQaTaskActionWrappedLazyMutationFn,
  InsertQaTaskActionMutationResultEx
];

// Function
function useInsertQaTaskAction(
  options?: Omit<
    MutationHookOptions<InsertQaTaskActionMutation, InsertQaTaskActionMutationVariables>,
    'mutation' | 'variables'
  >
): InsertQaTaskActionLazyMutationReturn {
  const lazyMutation: InsertQaTaskActionLazyMutationFn = useMutation<
    InsertQaTaskActionMutation,
    InsertQaTaskActionMutationVariables
  >(InsertQaTaskActionDocument, options);
  const pickQaTaskAction: PickInsertQaTaskActionFn = (mutation) => {
    return mutation?.insert_qaTaskAction?.returning && mutation?.insert_qaTaskAction?.returning[0];
  };

  const wrappedLazyMutation: InsertQaTaskActionWrappedLazyMutationFn = async ({
    qaTaskAction,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: qaTaskAction });
    const mutationOptions: MutationFunctionOptions<InsertQaTaskActionMutation, InsertQaTaskActionMutationVariables> = {
      variables: { objects: [objectForInsert] },
      ...options,
    };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id)
        throw new Error(`if autoOptimisticResponse = true, id must be set in object 'qaTaskAction'`);
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertQaTaskActionMutation>({
        operationType: 'insert',
        entityName: 'qaTaskAction',
        objects: [objectForInsert as QaTaskAction_Insert_Input & ObjectWithId],
      });
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, qaTaskAction: pickQaTaskAction(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], qaTaskAction: pickQaTaskAction(lazyMutation[1].data) }];
}

//
//

// Types
type InsertQaTaskActionWithOnConflictMutationResult = FetchResult<
  InsertQaTaskActionWithOnConflictMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertQaTaskActionWithOnConflictMutationResultEx = InsertQaTaskActionWithOnConflictMutationResult &
  QaTaskActionByIdHookResultEx;

type InsertQaTaskActionWithOnConflictLazyMutationFn = MutationTuple<
  InsertQaTaskActionWithOnConflictMutation,
  InsertQaTaskActionWithOnConflictMutationVariables
>;
type InsertQaTaskActionWithOnConflictWrappedLazyMutationFn = ({
  qaTaskAction,
  onConflict,
  autoOptimisticResponse,
  options,
}: {
  qaTaskAction: QaTaskAction_Insert_Input;
  onConflict: QaTaskAction_On_Conflict;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<
      InsertQaTaskActionWithOnConflictMutation,
      InsertQaTaskActionWithOnConflictMutationVariables
    >,
    'variables'
  >;
}) => Promise<InsertQaTaskActionWithOnConflictMutationResultEx>;
export type InsertQaTaskActionWithOnConflictLazyMutationReturn = [
  InsertQaTaskActionWithOnConflictWrappedLazyMutationFn,
  InsertQaTaskActionWithOnConflictMutationResultEx
];

// Function
function useInsertQaTaskActionWithOnConflict(
  options?: Omit<
    MutationHookOptions<InsertQaTaskActionWithOnConflictMutation, InsertQaTaskActionWithOnConflictMutationVariables>,
    'mutation' | 'variables'
  >
): InsertQaTaskActionWithOnConflictLazyMutationReturn {
  const lazyMutation: InsertQaTaskActionWithOnConflictLazyMutationFn = useMutation<
    InsertQaTaskActionWithOnConflictMutation,
    InsertQaTaskActionWithOnConflictMutationVariables
  >(InsertQaTaskActionWithOnConflictDocument, options);
  const pickQaTaskAction: PickInsertQaTaskActionFn = (
    mutation: InsertQaTaskActionWithOnConflictMutation | null | undefined
  ) => {
    return mutation?.insert_qaTaskAction?.returning && mutation.insert_qaTaskAction.returning[0];
  };

  const wrappedLazyMutation: InsertQaTaskActionWithOnConflictWrappedLazyMutationFn = async ({
    qaTaskAction,
    onConflict,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: qaTaskAction });
    const mutationOptions: MutationFunctionOptions<
      InsertQaTaskActionWithOnConflictMutation,
      InsertQaTaskActionWithOnConflictMutationVariables
    > = { variables: { objects: [objectForInsert], onConflict }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id)
        throw new Error(`if autoOptimisticResponse = true, id must be set in object 'qaTaskAction'`);
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertQaTaskActionWithOnConflictMutation>(
        {
          operationType: 'insert',
          entityName: 'qaTaskAction',
          objects: [objectForInsert as QaTaskAction_Insert_Input & ObjectWithId],
        }
      );
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, qaTaskAction: pickQaTaskAction(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], qaTaskAction: pickQaTaskAction(lazyMutation[1].data) }];
}

// Types
type InsertQaTaskActionObjectsMutationResult = FetchResult<
  InsertQaTaskActionMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertQaTaskActionObjectsMutationResultEx = InsertQaTaskActionMutationResult &
  QaTaskActionObjectsHookResultEx;

type PickInsertQaTaskActionObjectsFn = (
  mutation: InsertQaTaskActionMutation | null | undefined
) => QaTaskActionFragment[];
type InsertQaTaskActionObjectsLazyMutationFn = MutationTuple<
  InsertQaTaskActionMutation,
  InsertQaTaskActionMutationVariables
>;
type InsertQaTaskActionObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<InsertQaTaskActionMutation, InsertQaTaskActionMutationVariables>
) => Promise<InsertQaTaskActionObjectsMutationResultEx>;
export type InsertQaTaskActionObjectsLazyMutationReturn = [
  InsertQaTaskActionObjectsWrappedLazyMutationFn,
  InsertQaTaskActionObjectsMutationResultEx
];

// Function
function useInsertQaTaskActionObjects(
  options?: Omit<MutationHookOptions<InsertQaTaskActionMutation, InsertQaTaskActionMutationVariables>, 'mutation'>
): InsertQaTaskActionObjectsLazyMutationReturn {
  const lazyMutation: InsertQaTaskActionObjectsLazyMutationFn = useMutation<
    InsertQaTaskActionMutation,
    InsertQaTaskActionMutationVariables
  >(InsertQaTaskActionDocument, options);
  const pickObjects: PickInsertQaTaskActionObjectsFn = (mutation: InsertQaTaskActionMutation | null | undefined) => {
    return mutation?.insert_qaTaskAction?.returning || [];
  };

  const wrappedLazyMutation: InsertQaTaskActionObjectsWrappedLazyMutationFn = async (options) => {
    if (options && options.variables && options.variables.objects)
      options.variables.objects = options.variables.objects.map((objectItem) =>
        stripInsertInputClientFields({ input: objectItem })
      );
    const fetchResult: InsertQaTaskActionObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Update Hooks
 */

type UpdateQaTaskActionByIdMutationResult = FetchResult<
  UpdateQaTaskActionByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateQaTaskActionByIdMutationResultEx = UpdateQaTaskActionByIdMutationResult &
  QaTaskActionByIdHookResultEx;

type PickUpdateQaTaskActionByIdFn = (
  mutation: UpdateQaTaskActionByIdMutation | null | undefined
) => QaTaskActionFragment | null | undefined;
type UpdateQaTaskActionByIdLazyMutationFn = MutationTuple<
  UpdateQaTaskActionByIdMutation,
  UpdateQaTaskActionByIdMutationVariables
>;
type UpdateQaTaskActionByIdWrappedLazyMutationFn = ({
  qaTaskActionId,
  set,
  autoOptimisticResponse,
  options,
}: {
  qaTaskActionId: string;
  set: QaTaskAction_Set_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<UpdateQaTaskActionByIdMutation, UpdateQaTaskActionByIdMutationVariables>,
    'variables'
  >;
}) => Promise<UpdateQaTaskActionByIdMutationResultEx>;
export type UpdateQaTaskActionByIdLazyMutationReturn = [
  UpdateQaTaskActionByIdWrappedLazyMutationFn,
  UpdateQaTaskActionByIdMutationResultEx
];

function useUpdateQaTaskActionById(
  options?: Omit<
    MutationHookOptions<UpdateQaTaskActionByIdMutation, UpdateQaTaskActionByIdMutationVariables>,
    'mutation' | 'variables'
  >
): UpdateQaTaskActionByIdLazyMutationReturn {
  const lazyMutation: UpdateQaTaskActionByIdLazyMutationFn = useMutation<
    UpdateQaTaskActionByIdMutation,
    UpdateQaTaskActionByIdMutationVariables
  >(UpdateQaTaskActionByIdDocument, options);

  const pickQaTaskAction: PickUpdateQaTaskActionByIdFn = (mutation) => {
    return mutation?.update_qaTaskAction?.returning && mutation.update_qaTaskAction!.returning[0];
  };

  const wrappedLazyMutation: UpdateQaTaskActionByIdWrappedLazyMutationFn = async ({
    qaTaskActionId,
    set,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<
      UpdateQaTaskActionByIdMutation,
      UpdateQaTaskActionByIdMutationVariables
    > = { variables: { id: qaTaskActionId, set }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateQaTaskActionByIdMutation>({
        operationType: 'update',
        entityName: 'qaTaskAction',
        objects: [{ id: qaTaskActionId, ...set }],
      });
    }

    const fetchResult: UpdateQaTaskActionByIdMutationResult = await lazyMutation[0]({
      variables: { id: qaTaskActionId, set },
      ...mutationOptions,
    });
    return { ...fetchResult, qaTaskAction: pickQaTaskAction(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], qaTaskAction: pickQaTaskAction(lazyMutation[1].data) }];
}

//
//

// Types
type UpdateQaTaskActionObjectsMutationResult = FetchResult<
  UpdateQaTaskActionMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateQaTaskActionObjectsMutationResultEx = UpdateQaTaskActionObjectsMutationResult &
  QaTaskActionObjectsHookResultEx;

// Function
type PickUpdateQaTaskActionObjectsFn = (
  mutation: UpdateQaTaskActionMutation | null | undefined
) => QaTaskActionFragment[];
type UpdateQaTaskActionObjectsLazyMutationFn = MutationTuple<
  UpdateQaTaskActionMutation,
  UpdateQaTaskActionMutationVariables
>;
type UpdateQaTaskActionObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<UpdateQaTaskActionMutation, UpdateQaTaskActionMutationVariables>
) => Promise<UpdateQaTaskActionObjectsMutationResultEx>;
export type UpdateQaTaskActionObjectsLazyMutationReturn = [
  UpdateQaTaskActionObjectsWrappedLazyMutationFn,
  UpdateQaTaskActionObjectsMutationResultEx
];

function useUpdateQaTaskActionObjects(
  options?: Omit<MutationHookOptions<UpdateQaTaskActionMutation, UpdateQaTaskActionMutationVariables>, 'mutation'>
): UpdateQaTaskActionObjectsLazyMutationReturn {
  const lazyMutation: UpdateQaTaskActionObjectsLazyMutationFn = useMutation<
    UpdateQaTaskActionMutation,
    UpdateQaTaskActionMutationVariables
  >(UpdateQaTaskActionDocument, options);

  const pickObjects: PickUpdateQaTaskActionObjectsFn = (mutation: UpdateQaTaskActionMutation | null | undefined) => {
    return mutation?.update_qaTaskAction?.returning || [];
  };

  const wrappedLazyMutation: UpdateQaTaskActionObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: UpdateQaTaskActionObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Delete Hooks
 */

// Types
type RemoveQaTaskActionModelByIdFetchResult = FetchResult<
  RemoveQaTaskActionModelByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveQaTaskActionModelByIdMutationResultEx = RemoveQaTaskActionModelByIdFetchResult &
  RemoveEntitiesQueryHookResultEx;

// Function
type PickRemoveQaTaskActionModelFn = (mutation: RemoveQaTaskActionModelByIdMutation | null | undefined) => number;
type RemoveQaTaskActionModelLazyMutationFn = MutationTuple<
  RemoveQaTaskActionModelByIdMutation,
  RemoveQaTaskActionModelByIdMutationVariables
>;
type RemoveQaTaskActionModelWrappedLazyMutationFn = ({
  qaTaskActionId,
  autoOptimisticResponse,
  options,
}: {
  qaTaskActionId: string;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<RemoveQaTaskActionModelByIdMutation, RemoveQaTaskActionModelByIdMutationVariables>,
    'variables'
  >;
}) => Promise<RemoveQaTaskActionModelByIdMutationResultEx>;
export type RemoveQaTaskActionModelLazyMutationReturn = [
  RemoveQaTaskActionModelWrappedLazyMutationFn,
  RemoveQaTaskActionModelByIdMutationResultEx
];

function useRemoveQaTaskActionModelById(
  options?: Omit<
    MutationHookOptions<RemoveQaTaskActionModelByIdMutation, RemoveQaTaskActionModelByIdMutationVariables>,
    'mutation' | 'variables'
  >
) {
  const lazyMutation: RemoveQaTaskActionModelLazyMutationFn = useMutation<
    RemoveQaTaskActionModelByIdMutation,
    RemoveQaTaskActionModelByIdMutationVariables
  >(RemoveQaTaskActionModelByIdDocument, options);

  const pickAffectedRows: PickRemoveQaTaskActionModelFn = (
    mutation: RemoveQaTaskActionModelByIdMutation | null | undefined
  ) => {
    return mutation?.delete_qaTaskAction?.affected_rows || 0;
  };

  const wrappedLazyMutation: RemoveQaTaskActionModelWrappedLazyMutationFn = async ({
    qaTaskActionId,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<
      RemoveQaTaskActionModelMutation,
      RemoveQaTaskActionModelByIdMutationVariables
    > = { variables: { id: qaTaskActionId }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<RemoveQaTaskActionModelMutation>({
        operationType: 'delete',
        entityName: 'qaTaskAction',
        objects: [{ id: qaTaskActionId }],
      });
    }
    if (!options || !options.update) {
      mutationOptions.update = generateUpdateFunctionForMutation<RemoveQaTaskActionModelByIdMutation>({
        operationType: 'delete',
        entityName: 'qaTaskAction',
        entityId: qaTaskActionId,
      });
    }

    const fetchResult: RemoveQaTaskActionModelByIdFetchResult = await lazyMutation[0](mutationOptions);
    return { ...fetchResult, affected_rows: pickAffectedRows(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], affected_rows: pickAffectedRows(lazyMutation[1].data) }] as [
    typeof wrappedLazyMutation,
    typeof lazyMutation[1] & { affected_rows: ReturnType<typeof pickAffectedRows> }
  ];
}

//
//

// Types
type RemoveQaTaskActionModelObjectsMutationResult = FetchResult<
  RemoveQaTaskActionModelMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveQaTaskActionModelObjectsMutationResultEx = RemoveQaTaskActionModelObjectsMutationResult &
  RemoveEntitiesQueryHookResultEx;

// Function
type PickRemoveQaTaskActionModelObjectsFn = (mutation: RemoveQaTaskActionModelMutation | null | undefined) => number;
type RemoveQaTaskActionModelObjectsLazyMutationFn = MutationTuple<
  RemoveQaTaskActionModelMutation,
  RemoveQaTaskActionModelMutationVariables
>;
type RemoveQaTaskActionModelObjectsWrappedLazyMutationFn = (
  options: MutationFunctionOptions<RemoveQaTaskActionModelMutation, RemoveQaTaskActionModelMutationVariables>
) => Promise<RemoveQaTaskActionModelObjectsMutationResultEx>;
export type RemoveQaTaskActionModelObjectsLazyMutationReturn = [
  RemoveQaTaskActionModelObjectsWrappedLazyMutationFn,
  RemoveQaTaskActionModelObjectsMutationResultEx
];

function useRemoveQaTaskActionModelObjects(
  options?: Omit<
    MutationHookOptions<RemoveQaTaskActionModelMutation, RemoveQaTaskActionModelMutationVariables>,
    'mutation'
  >
): RemoveQaTaskActionModelObjectsLazyMutationReturn {
  const lazyMutation = useMutation<RemoveQaTaskActionModelMutation, RemoveQaTaskActionModelMutationVariables>(
    RemoveQaTaskActionModelDocument,
    options
  );

  const pickAffectedRows: PickRemoveQaTaskActionModelObjectsFn = (
    mutation: RemoveQaTaskActionModelMutation | null | undefined
  ) => {
    return mutation?.delete_qaTaskAction?.affected_rows || 0;
  };

  const wrappedLazyMutation: RemoveQaTaskActionModelObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: RemoveQaTaskActionModelObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, affected_rows: pickAffectedRows(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], affected_rows: pickAffectedRows(lazyMutation[1].data) }];
}

// QaTaskAction Fragment Helper Object
//------------------------------------------------

export const QaTaskActionFragmentGQLHooks = {
  useQueryById: useQueryQaTaskActionById,
  useQueryByIdLazy: useQueryQaTaskActionByIdLazy,
  useQueryObjects: useQueryQaTaskActionObjects,
  useQueryObjectsLazy: useQueryQaTaskActionObjectsLazy,
  useSubscribeToById: useSubscribeToQaTaskActionById,
  useSubscribeToObjects: useSubscribeToQaTaskActionObjects,
  useInsert: useInsertQaTaskAction,
  useInsertWithOnConflict: useInsertQaTaskActionWithOnConflict,
  useInsertObjects: useInsertQaTaskActionObjects,
  useUpdateById: useUpdateQaTaskActionById,
  useUpdateObjects: useUpdateQaTaskActionObjects,
};

// qaTaskAction MODEL HOOKS OBJECT
//------------------------------------------------

export const QaTaskActionModelGQLHooks = {
  useRemoveById: useRemoveQaTaskActionModelById,
  useRemoveObjects: useRemoveQaTaskActionModelObjects,
};

// qaTask REACT
//------------------------------------------------

export type QaTaskByIdHookResultEx = { qaTask: QaTaskFragment | null | undefined };
export type QaTaskObjectsHookResultEx = { objects: QaTaskFragment[] };

/**
 *  Query Hook
 */

// Types
type QueryQaTaskByIdResult = LazyQueryResult<QueryQaTaskByIdQuery, QueryQaTaskByIdQueryVariables>;
type QueryQaTaskByIdSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<QueryQaTaskByIdQuery, QueryQaTaskByIdQueryVariables, QueryQaTaskByIdQuery>,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryQaTaskByIdResultEx = Omit<QueryQaTaskByIdResult, 'subscribeToMore'> & {
  subscribeToMore: QueryQaTaskByIdSubScribeToMore;
} & QaTaskByIdHookResultEx;

// Function
function useQueryQaTaskById({
  qaTaskId,
  options,
}: {
  qaTaskId: string;
  options?: Omit<QueryHookOptions<QueryQaTaskByIdQuery, QueryQaTaskByIdQueryVariables>, 'query' | 'variables'>;
}): QueryQaTaskByIdResultEx {
  const _query: QueryQaTaskByIdResult = useQuery<QueryQaTaskByIdQuery, QueryQaTaskByIdQueryVariables>(
    QueryQaTaskByIdDocument,
    { variables: { qaTaskId }, ...options }
  );

  const typedSubscribeToMore: QueryQaTaskByIdSubScribeToMore = (options) => {
    _query.subscribeToMore({
      document: QueryQaTaskByIdDocument,
      variables: { qaTaskId } as QueryQaTaskByIdQueryVariables,
      ...options,
    });
  };
  const { subscribeToMore, ...query } = _query;
  return { ...query, subscribeToMore: typedSubscribeToMore, qaTask: query?.data?.qaTask_by_pk };
}

/**
 *  Lazy Query Hook
 */

// Types
type PickQueryQaTaskByIdFn = (query: QueryQaTaskByIdQuery | null | undefined) => QaTaskFragment | null | undefined;
type QueryQaTaskByIdLazyFn = [
  (options?: QueryLazyOptions<QueryQaTaskByIdQueryVariables> | undefined) => void,
  QueryQaTaskByIdResult
];
type QueryQaTaskByIdWrappedLazyFn = (
  options: Omit<QueryLazyOptions<QueryQaTaskByIdQueryVariables>, 'variables'>
) => void;
export type QueryQaTaskByIdLazyReturn = [QueryQaTaskByIdWrappedLazyFn, QueryQaTaskByIdResultEx];

// Function
function useQueryQaTaskByIdLazy({
  qaTaskId,
  options,
}: {
  qaTaskId: string;
  options?: Omit<QueryHookOptions<QueryQaTaskByIdQuery, QueryQaTaskByIdQueryVariables>, 'query' | 'variables'>;
}): QueryQaTaskByIdLazyReturn {
  const lazyQuery: QueryQaTaskByIdLazyFn = useLazyQuery<QueryQaTaskByIdQuery, QueryQaTaskByIdQueryVariables>(
    QueryQaTaskByIdDocument,
    options
  );

  // Setting up typed version of lazyQuery
  const pickQueryQaTaskById: PickQueryQaTaskByIdFn = (query) => {
    return query && query.qaTask_by_pk;
  };
  const wrappedLazyQuery: QueryQaTaskByIdWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryQaTaskByIdSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({
        document: QueryQaTaskByIdDocument,
        variables: { qaTaskId } as QueryQaTaskByIdQueryVariables,
        ...options,
      });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    { ...lazyQueryResult, subscribeToMore: typedSubcribeToMore, qaTask: pickQueryQaTaskById(lazyQuery[1].data) },
  ];
}

/**
 *  Query Collection Hook
 */

// Types
export type QueryQaTaskObjectsResult = LazyQueryResult<QueryQaTaskObjectsQuery, QueryQaTaskObjectsQueryVariables>;
type QueryQaTaskObjectsSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<QueryQaTaskObjectsQuery, QueryQaTaskObjectsQueryVariables, QueryQaTaskObjectsQuery>,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryQaTaskObjectsResultEx = Omit<QueryQaTaskObjectsResult, 'subscribeToMore'> & {
  subscribeToMore: QueryQaTaskObjectsSubScribeToMore;
} & QaTaskObjectsHookResultEx;

// Function
function useQueryQaTaskObjects(
  options: Omit<QueryHookOptions<QueryQaTaskObjectsQuery, QueryQaTaskObjectsQueryVariables>, 'query'>
): QueryQaTaskObjectsResultEx {
  const _query: QueryQaTaskObjectsResult = useQuery<QueryQaTaskObjectsQuery, QueryQaTaskObjectsQueryVariables>(
    QueryQaTaskObjectsDocument,
    options
  );

  const typedSubscribeToMore: QueryQaTaskObjectsSubScribeToMore = (options) => {
    _query.subscribeToMore({ document: QueryQaTaskObjectsDocument, ...options });
  };
  const { subscribeToMore, ...query } = _query;

  return { ...query, subscribeToMore: typedSubscribeToMore, objects: query?.data?.qaTask || [] };
}

/**
 *  Lazy Query Collection Hook
 */

// Types
type PickQueryQaTaskObjectsFn = (query: QueryQaTaskObjectsQuery | null | undefined) => QaTaskFragment[];
type QueryQaTaskObjectsLazyFn = [
  (options?: QueryLazyOptions<QueryQaTaskObjectsQueryVariables> | undefined) => void,
  QueryQaTaskObjectsResult
];
type QueryQaTaskObjectsWrappedLazyFn = (options?: QueryLazyOptions<QueryQaTaskObjectsQueryVariables>) => void;
export type QueryQaTaskObjectsLazyReturn = [QueryQaTaskObjectsWrappedLazyFn, QueryQaTaskObjectsResultEx];

// Function
function useQueryQaTaskObjectsLazy(
  options?: Omit<LazyQueryHookOptions<QueryQaTaskObjectsQuery, QueryQaTaskObjectsQueryVariables>, 'query'>
): QueryQaTaskObjectsLazyReturn {
  const lazyQuery: QueryQaTaskObjectsLazyFn = useLazyQuery<QueryQaTaskObjectsQuery, QueryQaTaskObjectsQueryVariables>(
    QueryQaTaskObjectsDocument,
    options
  );

  // Setting up typed version of lazyQuery
  const pickObjects: PickQueryQaTaskObjectsFn = (query: QueryQaTaskObjectsQuery | null | undefined) => {
    return query?.qaTask || [];
  };
  const wrappedLazyQuery: QueryQaTaskObjectsWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryQaTaskObjectsSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore && lazyQuery[1].subscribeToMore({ document: QueryQaTaskObjectsDocument, ...options });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    { ...lazyQueryResult, subscribeToMore: typedSubcribeToMore, objects: pickObjects(lazyQuery[1].data) },
  ];
}

/**
 *  Subscription Hook
 */

// Types
type SubscribeToQaTaskByIdResult = {
  variables?: SubscribeToQaTaskByIdSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToQaTaskByIdSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToQaTaskByIdResultEx = SubscribeToQaTaskByIdResult & QaTaskByIdHookResultEx;

// Function
function useSubscribeToQaTaskById({
  qaTaskId,
  options,
}: {
  qaTaskId: string;
  options?: Omit<
    SubscriptionHookOptions<SubscribeToQaTaskByIdSubscription, SubscribeToQaTaskByIdSubscriptionVariables>,
    'query' | 'variables'
  >;
}): SubscribeToQaTaskByIdResultEx {
  const subscription: SubscribeToQaTaskByIdResult = useSubscription<
    SubscribeToQaTaskByIdSubscription,
    SubscribeToQaTaskByIdSubscriptionVariables
  >(SubscribeToQaTaskByIdDocument, { variables: { qaTaskId }, ...options });
  return { ...subscription, qaTask: subscription?.data?.qaTask_by_pk };
}

/**
 *  Subscription Collection Hook
 */

// Types
export type SubscribeToQaTaskObjectsResult = {
  variables?: SubscribeToQaTaskObjectsSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToQaTaskObjectsSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToQaTaskObjectsResultEx = SubscribeToQaTaskObjectsResult & QaTaskObjectsHookResultEx;

// Function
function useSubscribeToQaTaskObjects(
  options: Omit<
    SubscriptionHookOptions<SubscribeToQaTaskObjectsSubscription, SubscribeToQaTaskObjectsSubscriptionVariables>,
    'query'
  >
): SubscribeToQaTaskObjectsResultEx {
  const subscription: SubscribeToQaTaskObjectsResult = useSubscription<
    SubscribeToQaTaskObjectsSubscription,
    SubscribeToQaTaskObjectsSubscriptionVariables
  >(SubscribeToQaTaskObjectsDocument, options);
  return { ...subscription, objects: subscription?.data?.qaTask || [] };
}

/**
 *  Insert Hooks
 */

// Types
type InsertQaTaskMutationResult = FetchResult<InsertQaTaskMutation, Record<string, any>, Record<string, any>>;
export type InsertQaTaskMutationResultEx = InsertQaTaskMutationResult & QaTaskByIdHookResultEx;

type PickInsertQaTaskFn = (mutation: InsertQaTaskMutation | null | undefined) => QaTaskFragment | null | undefined;
type InsertQaTaskLazyMutationFn = MutationTuple<InsertQaTaskMutation, InsertQaTaskMutationVariables>;
type InsertQaTaskWrappedLazyMutationFn = ({
  qaTask,
  autoOptimisticResponse,
  options,
}: {
  qaTask: QaTask_Insert_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<MutationFunctionOptions<InsertQaTaskMutation, InsertQaTaskMutationVariables>, 'variables'>;
}) => Promise<InsertQaTaskMutationResultEx>;
export type InsertQaTaskLazyMutationReturn = [InsertQaTaskWrappedLazyMutationFn, InsertQaTaskMutationResultEx];

// Function
function useInsertQaTask(
  options?: Omit<MutationHookOptions<InsertQaTaskMutation, InsertQaTaskMutationVariables>, 'mutation' | 'variables'>
): InsertQaTaskLazyMutationReturn {
  const lazyMutation: InsertQaTaskLazyMutationFn = useMutation<InsertQaTaskMutation, InsertQaTaskMutationVariables>(
    InsertQaTaskDocument,
    options
  );
  const pickQaTask: PickInsertQaTaskFn = (mutation) => {
    return mutation?.insert_qaTask?.returning && mutation?.insert_qaTask?.returning[0];
  };

  const wrappedLazyMutation: InsertQaTaskWrappedLazyMutationFn = async ({
    qaTask,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: qaTask });
    const mutationOptions: MutationFunctionOptions<InsertQaTaskMutation, InsertQaTaskMutationVariables> = {
      variables: { objects: [objectForInsert] },
      ...options,
    };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id) throw new Error(`if autoOptimisticResponse = true, id must be set in object 'qaTask'`);
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertQaTaskMutation>({
        operationType: 'insert',
        entityName: 'qaTask',
        objects: [objectForInsert as QaTask_Insert_Input & ObjectWithId],
      });
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, qaTask: pickQaTask(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], qaTask: pickQaTask(lazyMutation[1].data) }];
}

//
//

// Types
type InsertQaTaskWithOnConflictMutationResult = FetchResult<
  InsertQaTaskWithOnConflictMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertQaTaskWithOnConflictMutationResultEx = InsertQaTaskWithOnConflictMutationResult &
  QaTaskByIdHookResultEx;

type InsertQaTaskWithOnConflictLazyMutationFn = MutationTuple<
  InsertQaTaskWithOnConflictMutation,
  InsertQaTaskWithOnConflictMutationVariables
>;
type InsertQaTaskWithOnConflictWrappedLazyMutationFn = ({
  qaTask,
  onConflict,
  autoOptimisticResponse,
  options,
}: {
  qaTask: QaTask_Insert_Input;
  onConflict: QaTask_On_Conflict;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<InsertQaTaskWithOnConflictMutation, InsertQaTaskWithOnConflictMutationVariables>,
    'variables'
  >;
}) => Promise<InsertQaTaskWithOnConflictMutationResultEx>;
export type InsertQaTaskWithOnConflictLazyMutationReturn = [
  InsertQaTaskWithOnConflictWrappedLazyMutationFn,
  InsertQaTaskWithOnConflictMutationResultEx
];

// Function
function useInsertQaTaskWithOnConflict(
  options?: Omit<
    MutationHookOptions<InsertQaTaskWithOnConflictMutation, InsertQaTaskWithOnConflictMutationVariables>,
    'mutation' | 'variables'
  >
): InsertQaTaskWithOnConflictLazyMutationReturn {
  const lazyMutation: InsertQaTaskWithOnConflictLazyMutationFn = useMutation<
    InsertQaTaskWithOnConflictMutation,
    InsertQaTaskWithOnConflictMutationVariables
  >(InsertQaTaskWithOnConflictDocument, options);
  const pickQaTask: PickInsertQaTaskFn = (mutation: InsertQaTaskWithOnConflictMutation | null | undefined) => {
    return mutation?.insert_qaTask?.returning && mutation.insert_qaTask.returning[0];
  };

  const wrappedLazyMutation: InsertQaTaskWithOnConflictWrappedLazyMutationFn = async ({
    qaTask,
    onConflict,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: qaTask });
    const mutationOptions: MutationFunctionOptions<
      InsertQaTaskWithOnConflictMutation,
      InsertQaTaskWithOnConflictMutationVariables
    > = { variables: { objects: [objectForInsert], onConflict }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id) throw new Error(`if autoOptimisticResponse = true, id must be set in object 'qaTask'`);
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertQaTaskWithOnConflictMutation>({
        operationType: 'insert',
        entityName: 'qaTask',
        objects: [objectForInsert as QaTask_Insert_Input & ObjectWithId],
      });
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, qaTask: pickQaTask(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], qaTask: pickQaTask(lazyMutation[1].data) }];
}

// Types
type InsertQaTaskObjectsMutationResult = FetchResult<InsertQaTaskMutation, Record<string, any>, Record<string, any>>;
export type InsertQaTaskObjectsMutationResultEx = InsertQaTaskMutationResult & QaTaskObjectsHookResultEx;

type PickInsertQaTaskObjectsFn = (mutation: InsertQaTaskMutation | null | undefined) => QaTaskFragment[];
type InsertQaTaskObjectsLazyMutationFn = MutationTuple<InsertQaTaskMutation, InsertQaTaskMutationVariables>;
type InsertQaTaskObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<InsertQaTaskMutation, InsertQaTaskMutationVariables>
) => Promise<InsertQaTaskObjectsMutationResultEx>;
export type InsertQaTaskObjectsLazyMutationReturn = [
  InsertQaTaskObjectsWrappedLazyMutationFn,
  InsertQaTaskObjectsMutationResultEx
];

// Function
function useInsertQaTaskObjects(
  options?: Omit<MutationHookOptions<InsertQaTaskMutation, InsertQaTaskMutationVariables>, 'mutation'>
): InsertQaTaskObjectsLazyMutationReturn {
  const lazyMutation: InsertQaTaskObjectsLazyMutationFn = useMutation<
    InsertQaTaskMutation,
    InsertQaTaskMutationVariables
  >(InsertQaTaskDocument, options);
  const pickObjects: PickInsertQaTaskObjectsFn = (mutation: InsertQaTaskMutation | null | undefined) => {
    return mutation?.insert_qaTask?.returning || [];
  };

  const wrappedLazyMutation: InsertQaTaskObjectsWrappedLazyMutationFn = async (options) => {
    if (options && options.variables && options.variables.objects)
      options.variables.objects = options.variables.objects.map((objectItem) =>
        stripInsertInputClientFields({ input: objectItem })
      );
    const fetchResult: InsertQaTaskObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Update Hooks
 */

type UpdateQaTaskByIdMutationResult = FetchResult<UpdateQaTaskByIdMutation, Record<string, any>, Record<string, any>>;
export type UpdateQaTaskByIdMutationResultEx = UpdateQaTaskByIdMutationResult & QaTaskByIdHookResultEx;

type PickUpdateQaTaskByIdFn = (
  mutation: UpdateQaTaskByIdMutation | null | undefined
) => QaTaskFragment | null | undefined;
type UpdateQaTaskByIdLazyMutationFn = MutationTuple<UpdateQaTaskByIdMutation, UpdateQaTaskByIdMutationVariables>;
type UpdateQaTaskByIdWrappedLazyMutationFn = ({
  qaTaskId,
  set,
  autoOptimisticResponse,
  options,
}: {
  qaTaskId: string;
  set: QaTask_Set_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<MutationFunctionOptions<UpdateQaTaskByIdMutation, UpdateQaTaskByIdMutationVariables>, 'variables'>;
}) => Promise<UpdateQaTaskByIdMutationResultEx>;
export type UpdateQaTaskByIdLazyMutationReturn = [
  UpdateQaTaskByIdWrappedLazyMutationFn,
  UpdateQaTaskByIdMutationResultEx
];

function useUpdateQaTaskById(
  options?: Omit<
    MutationHookOptions<UpdateQaTaskByIdMutation, UpdateQaTaskByIdMutationVariables>,
    'mutation' | 'variables'
  >
): UpdateQaTaskByIdLazyMutationReturn {
  const lazyMutation: UpdateQaTaskByIdLazyMutationFn = useMutation<
    UpdateQaTaskByIdMutation,
    UpdateQaTaskByIdMutationVariables
  >(UpdateQaTaskByIdDocument, options);

  const pickQaTask: PickUpdateQaTaskByIdFn = (mutation) => {
    return mutation?.update_qaTask?.returning && mutation.update_qaTask!.returning[0];
  };

  const wrappedLazyMutation: UpdateQaTaskByIdWrappedLazyMutationFn = async ({
    qaTaskId,
    set,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<UpdateQaTaskByIdMutation, UpdateQaTaskByIdMutationVariables> = {
      variables: { id: qaTaskId, set },
      ...options,
    };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateQaTaskByIdMutation>({
        operationType: 'update',
        entityName: 'qaTask',
        objects: [{ id: qaTaskId, ...set }],
      });
    }

    const fetchResult: UpdateQaTaskByIdMutationResult = await lazyMutation[0]({
      variables: { id: qaTaskId, set },
      ...mutationOptions,
    });
    return { ...fetchResult, qaTask: pickQaTask(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], qaTask: pickQaTask(lazyMutation[1].data) }];
}

//
//

// Types
type UpdateQaTaskObjectsMutationResult = FetchResult<UpdateQaTaskMutation, Record<string, any>, Record<string, any>>;
export type UpdateQaTaskObjectsMutationResultEx = UpdateQaTaskObjectsMutationResult & QaTaskObjectsHookResultEx;

// Function
type PickUpdateQaTaskObjectsFn = (mutation: UpdateQaTaskMutation | null | undefined) => QaTaskFragment[];
type UpdateQaTaskObjectsLazyMutationFn = MutationTuple<UpdateQaTaskMutation, UpdateQaTaskMutationVariables>;
type UpdateQaTaskObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<UpdateQaTaskMutation, UpdateQaTaskMutationVariables>
) => Promise<UpdateQaTaskObjectsMutationResultEx>;
export type UpdateQaTaskObjectsLazyMutationReturn = [
  UpdateQaTaskObjectsWrappedLazyMutationFn,
  UpdateQaTaskObjectsMutationResultEx
];

function useUpdateQaTaskObjects(
  options?: Omit<MutationHookOptions<UpdateQaTaskMutation, UpdateQaTaskMutationVariables>, 'mutation'>
): UpdateQaTaskObjectsLazyMutationReturn {
  const lazyMutation: UpdateQaTaskObjectsLazyMutationFn = useMutation<
    UpdateQaTaskMutation,
    UpdateQaTaskMutationVariables
  >(UpdateQaTaskDocument, options);

  const pickObjects: PickUpdateQaTaskObjectsFn = (mutation: UpdateQaTaskMutation | null | undefined) => {
    return mutation?.update_qaTask?.returning || [];
  };

  const wrappedLazyMutation: UpdateQaTaskObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: UpdateQaTaskObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Delete Hooks
 */

// Types
type RemoveQaTaskModelByIdFetchResult = FetchResult<
  RemoveQaTaskModelByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveQaTaskModelByIdMutationResultEx = RemoveQaTaskModelByIdFetchResult & RemoveEntitiesQueryHookResultEx;

// Function
type PickRemoveQaTaskModelFn = (mutation: RemoveQaTaskModelByIdMutation | null | undefined) => number;
type RemoveQaTaskModelLazyMutationFn = MutationTuple<
  RemoveQaTaskModelByIdMutation,
  RemoveQaTaskModelByIdMutationVariables
>;
type RemoveQaTaskModelWrappedLazyMutationFn = ({
  qaTaskId,
  autoOptimisticResponse,
  options,
}: {
  qaTaskId: string;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<RemoveQaTaskModelByIdMutation, RemoveQaTaskModelByIdMutationVariables>,
    'variables'
  >;
}) => Promise<RemoveQaTaskModelByIdMutationResultEx>;
export type RemoveQaTaskModelLazyMutationReturn = [
  RemoveQaTaskModelWrappedLazyMutationFn,
  RemoveQaTaskModelByIdMutationResultEx
];

function useRemoveQaTaskModelById(
  options?: Omit<
    MutationHookOptions<RemoveQaTaskModelByIdMutation, RemoveQaTaskModelByIdMutationVariables>,
    'mutation' | 'variables'
  >
) {
  const lazyMutation: RemoveQaTaskModelLazyMutationFn = useMutation<
    RemoveQaTaskModelByIdMutation,
    RemoveQaTaskModelByIdMutationVariables
  >(RemoveQaTaskModelByIdDocument, options);

  const pickAffectedRows: PickRemoveQaTaskModelFn = (mutation: RemoveQaTaskModelByIdMutation | null | undefined) => {
    return mutation?.delete_qaTask?.affected_rows || 0;
  };

  const wrappedLazyMutation: RemoveQaTaskModelWrappedLazyMutationFn = async ({
    qaTaskId,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<
      RemoveQaTaskModelMutation,
      RemoveQaTaskModelByIdMutationVariables
    > = { variables: { id: qaTaskId }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<RemoveQaTaskModelMutation>({
        operationType: 'delete',
        entityName: 'qaTask',
        objects: [{ id: qaTaskId }],
      });
    }
    if (!options || !options.update) {
      mutationOptions.update = generateUpdateFunctionForMutation<RemoveQaTaskModelByIdMutation>({
        operationType: 'delete',
        entityName: 'qaTask',
        entityId: qaTaskId,
      });
    }

    const fetchResult: RemoveQaTaskModelByIdFetchResult = await lazyMutation[0](mutationOptions);
    return { ...fetchResult, affected_rows: pickAffectedRows(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], affected_rows: pickAffectedRows(lazyMutation[1].data) }] as [
    typeof wrappedLazyMutation,
    typeof lazyMutation[1] & { affected_rows: ReturnType<typeof pickAffectedRows> }
  ];
}

//
//

// Types
type RemoveQaTaskModelObjectsMutationResult = FetchResult<
  RemoveQaTaskModelMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveQaTaskModelObjectsMutationResultEx = RemoveQaTaskModelObjectsMutationResult &
  RemoveEntitiesQueryHookResultEx;

// Function
type PickRemoveQaTaskModelObjectsFn = (mutation: RemoveQaTaskModelMutation | null | undefined) => number;
type RemoveQaTaskModelObjectsLazyMutationFn = MutationTuple<
  RemoveQaTaskModelMutation,
  RemoveQaTaskModelMutationVariables
>;
type RemoveQaTaskModelObjectsWrappedLazyMutationFn = (
  options: MutationFunctionOptions<RemoveQaTaskModelMutation, RemoveQaTaskModelMutationVariables>
) => Promise<RemoveQaTaskModelObjectsMutationResultEx>;
export type RemoveQaTaskModelObjectsLazyMutationReturn = [
  RemoveQaTaskModelObjectsWrappedLazyMutationFn,
  RemoveQaTaskModelObjectsMutationResultEx
];

function useRemoveQaTaskModelObjects(
  options?: Omit<MutationHookOptions<RemoveQaTaskModelMutation, RemoveQaTaskModelMutationVariables>, 'mutation'>
): RemoveQaTaskModelObjectsLazyMutationReturn {
  const lazyMutation = useMutation<RemoveQaTaskModelMutation, RemoveQaTaskModelMutationVariables>(
    RemoveQaTaskModelDocument,
    options
  );

  const pickAffectedRows: PickRemoveQaTaskModelObjectsFn = (mutation: RemoveQaTaskModelMutation | null | undefined) => {
    return mutation?.delete_qaTask?.affected_rows || 0;
  };

  const wrappedLazyMutation: RemoveQaTaskModelObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: RemoveQaTaskModelObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, affected_rows: pickAffectedRows(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], affected_rows: pickAffectedRows(lazyMutation[1].data) }];
}

// QaTask Fragment Helper Object
//------------------------------------------------

export const QaTaskFragmentGQLHooks = {
  useQueryById: useQueryQaTaskById,
  useQueryByIdLazy: useQueryQaTaskByIdLazy,
  useQueryObjects: useQueryQaTaskObjects,
  useQueryObjectsLazy: useQueryQaTaskObjectsLazy,
  useSubscribeToById: useSubscribeToQaTaskById,
  useSubscribeToObjects: useSubscribeToQaTaskObjects,
  useInsert: useInsertQaTask,
  useInsertWithOnConflict: useInsertQaTaskWithOnConflict,
  useInsertObjects: useInsertQaTaskObjects,
  useUpdateById: useUpdateQaTaskById,
  useUpdateObjects: useUpdateQaTaskObjects,
};

// qaTask MODEL HOOKS OBJECT
//------------------------------------------------

export const QaTaskModelGQLHooks = {
  useRemoveById: useRemoveQaTaskModelById,
  useRemoveObjects: useRemoveQaTaskModelObjects,
};

// clientTerritory REACT
//------------------------------------------------

export type ClientTerritoryByIdHookResultEx = { clientTerritory: ClientTerritoryFragment | null | undefined };
export type ClientTerritoryObjectsHookResultEx = { objects: ClientTerritoryFragment[] };

/**
 *  Query Hook
 */

// Types
type QueryClientTerritoryByIdResult = LazyQueryResult<
  QueryClientTerritoryByIdQuery,
  QueryClientTerritoryByIdQueryVariables
>;
type QueryClientTerritoryByIdSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<
          QueryClientTerritoryByIdQuery,
          QueryClientTerritoryByIdQueryVariables,
          QueryClientTerritoryByIdQuery
        >,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryClientTerritoryByIdResultEx = Omit<QueryClientTerritoryByIdResult, 'subscribeToMore'> & {
  subscribeToMore: QueryClientTerritoryByIdSubScribeToMore;
} & ClientTerritoryByIdHookResultEx;

// Function
function useQueryClientTerritoryById({
  clientTerritoryId,
  options,
}: {
  clientTerritoryId: string;
  options?: Omit<
    QueryHookOptions<QueryClientTerritoryByIdQuery, QueryClientTerritoryByIdQueryVariables>,
    'query' | 'variables'
  >;
}): QueryClientTerritoryByIdResultEx {
  const _query: QueryClientTerritoryByIdResult = useQuery<
    QueryClientTerritoryByIdQuery,
    QueryClientTerritoryByIdQueryVariables
  >(QueryClientTerritoryByIdDocument, { variables: { clientTerritoryId }, ...options });

  const typedSubscribeToMore: QueryClientTerritoryByIdSubScribeToMore = (options) => {
    _query.subscribeToMore({
      document: QueryClientTerritoryByIdDocument,
      variables: { clientTerritoryId } as QueryClientTerritoryByIdQueryVariables,
      ...options,
    });
  };
  const { subscribeToMore, ...query } = _query;
  return { ...query, subscribeToMore: typedSubscribeToMore, clientTerritory: query?.data?.clientTerritory_by_pk };
}

/**
 *  Lazy Query Hook
 */

// Types
type PickQueryClientTerritoryByIdFn = (
  query: QueryClientTerritoryByIdQuery | null | undefined
) => ClientTerritoryFragment | null | undefined;
type QueryClientTerritoryByIdLazyFn = [
  (options?: QueryLazyOptions<QueryClientTerritoryByIdQueryVariables> | undefined) => void,
  QueryClientTerritoryByIdResult
];
type QueryClientTerritoryByIdWrappedLazyFn = (
  options: Omit<QueryLazyOptions<QueryClientTerritoryByIdQueryVariables>, 'variables'>
) => void;
export type QueryClientTerritoryByIdLazyReturn = [
  QueryClientTerritoryByIdWrappedLazyFn,
  QueryClientTerritoryByIdResultEx
];

// Function
function useQueryClientTerritoryByIdLazy({
  clientTerritoryId,
  options,
}: {
  clientTerritoryId: string;
  options?: Omit<
    QueryHookOptions<QueryClientTerritoryByIdQuery, QueryClientTerritoryByIdQueryVariables>,
    'query' | 'variables'
  >;
}): QueryClientTerritoryByIdLazyReturn {
  const lazyQuery: QueryClientTerritoryByIdLazyFn = useLazyQuery<
    QueryClientTerritoryByIdQuery,
    QueryClientTerritoryByIdQueryVariables
  >(QueryClientTerritoryByIdDocument, options);

  // Setting up typed version of lazyQuery
  const pickQueryClientTerritoryById: PickQueryClientTerritoryByIdFn = (query) => {
    return query && query.clientTerritory_by_pk;
  };
  const wrappedLazyQuery: QueryClientTerritoryByIdWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryClientTerritoryByIdSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({
        document: QueryClientTerritoryByIdDocument,
        variables: { clientTerritoryId } as QueryClientTerritoryByIdQueryVariables,
        ...options,
      });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    {
      ...lazyQueryResult,
      subscribeToMore: typedSubcribeToMore,
      clientTerritory: pickQueryClientTerritoryById(lazyQuery[1].data),
    },
  ];
}

/**
 *  Query Collection Hook
 */

// Types
export type QueryClientTerritoryObjectsResult = LazyQueryResult<
  QueryClientTerritoryObjectsQuery,
  QueryClientTerritoryObjectsQueryVariables
>;
type QueryClientTerritoryObjectsSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<
          QueryClientTerritoryObjectsQuery,
          QueryClientTerritoryObjectsQueryVariables,
          QueryClientTerritoryObjectsQuery
        >,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryClientTerritoryObjectsResultEx = Omit<QueryClientTerritoryObjectsResult, 'subscribeToMore'> & {
  subscribeToMore: QueryClientTerritoryObjectsSubScribeToMore;
} & ClientTerritoryObjectsHookResultEx;

// Function
function useQueryClientTerritoryObjects(
  options: Omit<QueryHookOptions<QueryClientTerritoryObjectsQuery, QueryClientTerritoryObjectsQueryVariables>, 'query'>
): QueryClientTerritoryObjectsResultEx {
  const _query: QueryClientTerritoryObjectsResult = useQuery<
    QueryClientTerritoryObjectsQuery,
    QueryClientTerritoryObjectsQueryVariables
  >(QueryClientTerritoryObjectsDocument, options);

  const typedSubscribeToMore: QueryClientTerritoryObjectsSubScribeToMore = (options) => {
    _query.subscribeToMore({ document: QueryClientTerritoryObjectsDocument, ...options });
  };
  const { subscribeToMore, ...query } = _query;

  return { ...query, subscribeToMore: typedSubscribeToMore, objects: query?.data?.clientTerritory || [] };
}

/**
 *  Lazy Query Collection Hook
 */

// Types
type PickQueryClientTerritoryObjectsFn = (
  query: QueryClientTerritoryObjectsQuery | null | undefined
) => ClientTerritoryFragment[];
type QueryClientTerritoryObjectsLazyFn = [
  (options?: QueryLazyOptions<QueryClientTerritoryObjectsQueryVariables> | undefined) => void,
  QueryClientTerritoryObjectsResult
];
type QueryClientTerritoryObjectsWrappedLazyFn = (
  options?: QueryLazyOptions<QueryClientTerritoryObjectsQueryVariables>
) => void;
export type QueryClientTerritoryObjectsLazyReturn = [
  QueryClientTerritoryObjectsWrappedLazyFn,
  QueryClientTerritoryObjectsResultEx
];

// Function
function useQueryClientTerritoryObjectsLazy(
  options?: Omit<
    LazyQueryHookOptions<QueryClientTerritoryObjectsQuery, QueryClientTerritoryObjectsQueryVariables>,
    'query'
  >
): QueryClientTerritoryObjectsLazyReturn {
  const lazyQuery: QueryClientTerritoryObjectsLazyFn = useLazyQuery<
    QueryClientTerritoryObjectsQuery,
    QueryClientTerritoryObjectsQueryVariables
  >(QueryClientTerritoryObjectsDocument, options);

  // Setting up typed version of lazyQuery
  const pickObjects: PickQueryClientTerritoryObjectsFn = (
    query: QueryClientTerritoryObjectsQuery | null | undefined
  ) => {
    return query?.clientTerritory || [];
  };
  const wrappedLazyQuery: QueryClientTerritoryObjectsWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryClientTerritoryObjectsSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({ document: QueryClientTerritoryObjectsDocument, ...options });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    { ...lazyQueryResult, subscribeToMore: typedSubcribeToMore, objects: pickObjects(lazyQuery[1].data) },
  ];
}

/**
 *  Subscription Hook
 */

// Types
type SubscribeToClientTerritoryByIdResult = {
  variables?: SubscribeToClientTerritoryByIdSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToClientTerritoryByIdSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToClientTerritoryByIdResultEx = SubscribeToClientTerritoryByIdResult &
  ClientTerritoryByIdHookResultEx;

// Function
function useSubscribeToClientTerritoryById({
  clientTerritoryId,
  options,
}: {
  clientTerritoryId: string;
  options?: Omit<
    SubscriptionHookOptions<
      SubscribeToClientTerritoryByIdSubscription,
      SubscribeToClientTerritoryByIdSubscriptionVariables
    >,
    'query' | 'variables'
  >;
}): SubscribeToClientTerritoryByIdResultEx {
  const subscription: SubscribeToClientTerritoryByIdResult = useSubscription<
    SubscribeToClientTerritoryByIdSubscription,
    SubscribeToClientTerritoryByIdSubscriptionVariables
  >(SubscribeToClientTerritoryByIdDocument, { variables: { clientTerritoryId }, ...options });
  return { ...subscription, clientTerritory: subscription?.data?.clientTerritory_by_pk };
}

/**
 *  Subscription Collection Hook
 */

// Types
export type SubscribeToClientTerritoryObjectsResult = {
  variables?: SubscribeToClientTerritoryObjectsSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToClientTerritoryObjectsSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToClientTerritoryObjectsResultEx = SubscribeToClientTerritoryObjectsResult &
  ClientTerritoryObjectsHookResultEx;

// Function
function useSubscribeToClientTerritoryObjects(
  options: Omit<
    SubscriptionHookOptions<
      SubscribeToClientTerritoryObjectsSubscription,
      SubscribeToClientTerritoryObjectsSubscriptionVariables
    >,
    'query'
  >
): SubscribeToClientTerritoryObjectsResultEx {
  const subscription: SubscribeToClientTerritoryObjectsResult = useSubscription<
    SubscribeToClientTerritoryObjectsSubscription,
    SubscribeToClientTerritoryObjectsSubscriptionVariables
  >(SubscribeToClientTerritoryObjectsDocument, options);
  return { ...subscription, objects: subscription?.data?.clientTerritory || [] };
}

/**
 *  Insert Hooks
 */

// Types
type InsertClientTerritoryMutationResult = FetchResult<
  InsertClientTerritoryMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertClientTerritoryMutationResultEx = InsertClientTerritoryMutationResult &
  ClientTerritoryByIdHookResultEx;

type PickInsertClientTerritoryFn = (
  mutation: InsertClientTerritoryMutation | null | undefined
) => ClientTerritoryFragment | null | undefined;
type InsertClientTerritoryLazyMutationFn = MutationTuple<
  InsertClientTerritoryMutation,
  InsertClientTerritoryMutationVariables
>;
type InsertClientTerritoryWrappedLazyMutationFn = ({
  clientTerritory,
  autoOptimisticResponse,
  options,
}: {
  clientTerritory: ClientTerritory_Insert_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<InsertClientTerritoryMutation, InsertClientTerritoryMutationVariables>,
    'variables'
  >;
}) => Promise<InsertClientTerritoryMutationResultEx>;
export type InsertClientTerritoryLazyMutationReturn = [
  InsertClientTerritoryWrappedLazyMutationFn,
  InsertClientTerritoryMutationResultEx
];

// Function
function useInsertClientTerritory(
  options?: Omit<
    MutationHookOptions<InsertClientTerritoryMutation, InsertClientTerritoryMutationVariables>,
    'mutation' | 'variables'
  >
): InsertClientTerritoryLazyMutationReturn {
  const lazyMutation: InsertClientTerritoryLazyMutationFn = useMutation<
    InsertClientTerritoryMutation,
    InsertClientTerritoryMutationVariables
  >(InsertClientTerritoryDocument, options);
  const pickClientTerritory: PickInsertClientTerritoryFn = (mutation) => {
    return mutation?.insert_clientTerritory?.returning && mutation?.insert_clientTerritory?.returning[0];
  };

  const wrappedLazyMutation: InsertClientTerritoryWrappedLazyMutationFn = async ({
    clientTerritory,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: clientTerritory });
    const mutationOptions: MutationFunctionOptions<
      InsertClientTerritoryMutation,
      InsertClientTerritoryMutationVariables
    > = { variables: { objects: [objectForInsert] }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id)
        throw new Error(`if autoOptimisticResponse = true, id must be set in object 'clientTerritory'`);
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertClientTerritoryMutation>({
        operationType: 'insert',
        entityName: 'clientTerritory',
        objects: [objectForInsert as ClientTerritory_Insert_Input & ObjectWithId],
      });
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, clientTerritory: pickClientTerritory(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], clientTerritory: pickClientTerritory(lazyMutation[1].data) }];
}

//
//

// Types
type InsertClientTerritoryWithOnConflictMutationResult = FetchResult<
  InsertClientTerritoryWithOnConflictMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertClientTerritoryWithOnConflictMutationResultEx = InsertClientTerritoryWithOnConflictMutationResult &
  ClientTerritoryByIdHookResultEx;

type InsertClientTerritoryWithOnConflictLazyMutationFn = MutationTuple<
  InsertClientTerritoryWithOnConflictMutation,
  InsertClientTerritoryWithOnConflictMutationVariables
>;
type InsertClientTerritoryWithOnConflictWrappedLazyMutationFn = ({
  clientTerritory,
  onConflict,
  autoOptimisticResponse,
  options,
}: {
  clientTerritory: ClientTerritory_Insert_Input;
  onConflict: ClientTerritory_On_Conflict;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<
      InsertClientTerritoryWithOnConflictMutation,
      InsertClientTerritoryWithOnConflictMutationVariables
    >,
    'variables'
  >;
}) => Promise<InsertClientTerritoryWithOnConflictMutationResultEx>;
export type InsertClientTerritoryWithOnConflictLazyMutationReturn = [
  InsertClientTerritoryWithOnConflictWrappedLazyMutationFn,
  InsertClientTerritoryWithOnConflictMutationResultEx
];

// Function
function useInsertClientTerritoryWithOnConflict(
  options?: Omit<
    MutationHookOptions<
      InsertClientTerritoryWithOnConflictMutation,
      InsertClientTerritoryWithOnConflictMutationVariables
    >,
    'mutation' | 'variables'
  >
): InsertClientTerritoryWithOnConflictLazyMutationReturn {
  const lazyMutation: InsertClientTerritoryWithOnConflictLazyMutationFn = useMutation<
    InsertClientTerritoryWithOnConflictMutation,
    InsertClientTerritoryWithOnConflictMutationVariables
  >(InsertClientTerritoryWithOnConflictDocument, options);
  const pickClientTerritory: PickInsertClientTerritoryFn = (
    mutation: InsertClientTerritoryWithOnConflictMutation | null | undefined
  ) => {
    return mutation?.insert_clientTerritory?.returning && mutation.insert_clientTerritory.returning[0];
  };

  const wrappedLazyMutation: InsertClientTerritoryWithOnConflictWrappedLazyMutationFn = async ({
    clientTerritory,
    onConflict,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: clientTerritory });
    const mutationOptions: MutationFunctionOptions<
      InsertClientTerritoryWithOnConflictMutation,
      InsertClientTerritoryWithOnConflictMutationVariables
    > = { variables: { objects: [objectForInsert], onConflict }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id)
        throw new Error(`if autoOptimisticResponse = true, id must be set in object 'clientTerritory'`);
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertClientTerritoryWithOnConflictMutation>(
        {
          operationType: 'insert',
          entityName: 'clientTerritory',
          objects: [objectForInsert as ClientTerritory_Insert_Input & ObjectWithId],
        }
      );
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, clientTerritory: pickClientTerritory(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], clientTerritory: pickClientTerritory(lazyMutation[1].data) }];
}

// Types
type InsertClientTerritoryObjectsMutationResult = FetchResult<
  InsertClientTerritoryMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertClientTerritoryObjectsMutationResultEx = InsertClientTerritoryMutationResult &
  ClientTerritoryObjectsHookResultEx;

type PickInsertClientTerritoryObjectsFn = (
  mutation: InsertClientTerritoryMutation | null | undefined
) => ClientTerritoryFragment[];
type InsertClientTerritoryObjectsLazyMutationFn = MutationTuple<
  InsertClientTerritoryMutation,
  InsertClientTerritoryMutationVariables
>;
type InsertClientTerritoryObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<InsertClientTerritoryMutation, InsertClientTerritoryMutationVariables>
) => Promise<InsertClientTerritoryObjectsMutationResultEx>;
export type InsertClientTerritoryObjectsLazyMutationReturn = [
  InsertClientTerritoryObjectsWrappedLazyMutationFn,
  InsertClientTerritoryObjectsMutationResultEx
];

// Function
function useInsertClientTerritoryObjects(
  options?: Omit<MutationHookOptions<InsertClientTerritoryMutation, InsertClientTerritoryMutationVariables>, 'mutation'>
): InsertClientTerritoryObjectsLazyMutationReturn {
  const lazyMutation: InsertClientTerritoryObjectsLazyMutationFn = useMutation<
    InsertClientTerritoryMutation,
    InsertClientTerritoryMutationVariables
  >(InsertClientTerritoryDocument, options);
  const pickObjects: PickInsertClientTerritoryObjectsFn = (
    mutation: InsertClientTerritoryMutation | null | undefined
  ) => {
    return mutation?.insert_clientTerritory?.returning || [];
  };

  const wrappedLazyMutation: InsertClientTerritoryObjectsWrappedLazyMutationFn = async (options) => {
    if (options && options.variables && options.variables.objects)
      options.variables.objects = options.variables.objects.map((objectItem) =>
        stripInsertInputClientFields({ input: objectItem })
      );
    const fetchResult: InsertClientTerritoryObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Update Hooks
 */

type UpdateClientTerritoryByIdMutationResult = FetchResult<
  UpdateClientTerritoryByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateClientTerritoryByIdMutationResultEx = UpdateClientTerritoryByIdMutationResult &
  ClientTerritoryByIdHookResultEx;

type PickUpdateClientTerritoryByIdFn = (
  mutation: UpdateClientTerritoryByIdMutation | null | undefined
) => ClientTerritoryFragment | null | undefined;
type UpdateClientTerritoryByIdLazyMutationFn = MutationTuple<
  UpdateClientTerritoryByIdMutation,
  UpdateClientTerritoryByIdMutationVariables
>;
type UpdateClientTerritoryByIdWrappedLazyMutationFn = ({
  clientTerritoryId,
  set,
  autoOptimisticResponse,
  options,
}: {
  clientTerritoryId: string;
  set: ClientTerritory_Set_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<UpdateClientTerritoryByIdMutation, UpdateClientTerritoryByIdMutationVariables>,
    'variables'
  >;
}) => Promise<UpdateClientTerritoryByIdMutationResultEx>;
export type UpdateClientTerritoryByIdLazyMutationReturn = [
  UpdateClientTerritoryByIdWrappedLazyMutationFn,
  UpdateClientTerritoryByIdMutationResultEx
];

function useUpdateClientTerritoryById(
  options?: Omit<
    MutationHookOptions<UpdateClientTerritoryByIdMutation, UpdateClientTerritoryByIdMutationVariables>,
    'mutation' | 'variables'
  >
): UpdateClientTerritoryByIdLazyMutationReturn {
  const lazyMutation: UpdateClientTerritoryByIdLazyMutationFn = useMutation<
    UpdateClientTerritoryByIdMutation,
    UpdateClientTerritoryByIdMutationVariables
  >(UpdateClientTerritoryByIdDocument, options);

  const pickClientTerritory: PickUpdateClientTerritoryByIdFn = (mutation) => {
    return mutation?.update_clientTerritory?.returning && mutation.update_clientTerritory!.returning[0];
  };

  const wrappedLazyMutation: UpdateClientTerritoryByIdWrappedLazyMutationFn = async ({
    clientTerritoryId,
    set,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<
      UpdateClientTerritoryByIdMutation,
      UpdateClientTerritoryByIdMutationVariables
    > = { variables: { id: clientTerritoryId, set }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateClientTerritoryByIdMutation>({
        operationType: 'update',
        entityName: 'clientTerritory',
        objects: [{ id: clientTerritoryId, ...set }],
      });
    }

    const fetchResult: UpdateClientTerritoryByIdMutationResult = await lazyMutation[0]({
      variables: { id: clientTerritoryId, set },
      ...mutationOptions,
    });
    return { ...fetchResult, clientTerritory: pickClientTerritory(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], clientTerritory: pickClientTerritory(lazyMutation[1].data) }];
}

//
//

// Types
type UpdateClientTerritoryObjectsMutationResult = FetchResult<
  UpdateClientTerritoryMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateClientTerritoryObjectsMutationResultEx = UpdateClientTerritoryObjectsMutationResult &
  ClientTerritoryObjectsHookResultEx;

// Function
type PickUpdateClientTerritoryObjectsFn = (
  mutation: UpdateClientTerritoryMutation | null | undefined
) => ClientTerritoryFragment[];
type UpdateClientTerritoryObjectsLazyMutationFn = MutationTuple<
  UpdateClientTerritoryMutation,
  UpdateClientTerritoryMutationVariables
>;
type UpdateClientTerritoryObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<UpdateClientTerritoryMutation, UpdateClientTerritoryMutationVariables>
) => Promise<UpdateClientTerritoryObjectsMutationResultEx>;
export type UpdateClientTerritoryObjectsLazyMutationReturn = [
  UpdateClientTerritoryObjectsWrappedLazyMutationFn,
  UpdateClientTerritoryObjectsMutationResultEx
];

function useUpdateClientTerritoryObjects(
  options?: Omit<MutationHookOptions<UpdateClientTerritoryMutation, UpdateClientTerritoryMutationVariables>, 'mutation'>
): UpdateClientTerritoryObjectsLazyMutationReturn {
  const lazyMutation: UpdateClientTerritoryObjectsLazyMutationFn = useMutation<
    UpdateClientTerritoryMutation,
    UpdateClientTerritoryMutationVariables
  >(UpdateClientTerritoryDocument, options);

  const pickObjects: PickUpdateClientTerritoryObjectsFn = (
    mutation: UpdateClientTerritoryMutation | null | undefined
  ) => {
    return mutation?.update_clientTerritory?.returning || [];
  };

  const wrappedLazyMutation: UpdateClientTerritoryObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: UpdateClientTerritoryObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Delete Hooks
 */

// Types
type RemoveClientTerritoryModelByIdFetchResult = FetchResult<
  RemoveClientTerritoryModelByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveClientTerritoryModelByIdMutationResultEx = RemoveClientTerritoryModelByIdFetchResult &
  RemoveEntitiesQueryHookResultEx;

// Function
type PickRemoveClientTerritoryModelFn = (mutation: RemoveClientTerritoryModelByIdMutation | null | undefined) => number;
type RemoveClientTerritoryModelLazyMutationFn = MutationTuple<
  RemoveClientTerritoryModelByIdMutation,
  RemoveClientTerritoryModelByIdMutationVariables
>;
type RemoveClientTerritoryModelWrappedLazyMutationFn = ({
  clientTerritoryId,
  autoOptimisticResponse,
  options,
}: {
  clientTerritoryId: string;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<RemoveClientTerritoryModelByIdMutation, RemoveClientTerritoryModelByIdMutationVariables>,
    'variables'
  >;
}) => Promise<RemoveClientTerritoryModelByIdMutationResultEx>;
export type RemoveClientTerritoryModelLazyMutationReturn = [
  RemoveClientTerritoryModelWrappedLazyMutationFn,
  RemoveClientTerritoryModelByIdMutationResultEx
];

function useRemoveClientTerritoryModelById(
  options?: Omit<
    MutationHookOptions<RemoveClientTerritoryModelByIdMutation, RemoveClientTerritoryModelByIdMutationVariables>,
    'mutation' | 'variables'
  >
) {
  const lazyMutation: RemoveClientTerritoryModelLazyMutationFn = useMutation<
    RemoveClientTerritoryModelByIdMutation,
    RemoveClientTerritoryModelByIdMutationVariables
  >(RemoveClientTerritoryModelByIdDocument, options);

  const pickAffectedRows: PickRemoveClientTerritoryModelFn = (
    mutation: RemoveClientTerritoryModelByIdMutation | null | undefined
  ) => {
    return mutation?.delete_clientTerritory?.affected_rows || 0;
  };

  const wrappedLazyMutation: RemoveClientTerritoryModelWrappedLazyMutationFn = async ({
    clientTerritoryId,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<
      RemoveClientTerritoryModelMutation,
      RemoveClientTerritoryModelByIdMutationVariables
    > = { variables: { id: clientTerritoryId }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<RemoveClientTerritoryModelMutation>({
        operationType: 'delete',
        entityName: 'clientTerritory',
        objects: [{ id: clientTerritoryId }],
      });
    }
    if (!options || !options.update) {
      mutationOptions.update = generateUpdateFunctionForMutation<RemoveClientTerritoryModelByIdMutation>({
        operationType: 'delete',
        entityName: 'clientTerritory',
        entityId: clientTerritoryId,
      });
    }

    const fetchResult: RemoveClientTerritoryModelByIdFetchResult = await lazyMutation[0](mutationOptions);
    return { ...fetchResult, affected_rows: pickAffectedRows(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], affected_rows: pickAffectedRows(lazyMutation[1].data) }] as [
    typeof wrappedLazyMutation,
    typeof lazyMutation[1] & { affected_rows: ReturnType<typeof pickAffectedRows> }
  ];
}

//
//

// Types
type RemoveClientTerritoryModelObjectsMutationResult = FetchResult<
  RemoveClientTerritoryModelMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveClientTerritoryModelObjectsMutationResultEx = RemoveClientTerritoryModelObjectsMutationResult &
  RemoveEntitiesQueryHookResultEx;

// Function
type PickRemoveClientTerritoryModelObjectsFn = (
  mutation: RemoveClientTerritoryModelMutation | null | undefined
) => number;
type RemoveClientTerritoryModelObjectsLazyMutationFn = MutationTuple<
  RemoveClientTerritoryModelMutation,
  RemoveClientTerritoryModelMutationVariables
>;
type RemoveClientTerritoryModelObjectsWrappedLazyMutationFn = (
  options: MutationFunctionOptions<RemoveClientTerritoryModelMutation, RemoveClientTerritoryModelMutationVariables>
) => Promise<RemoveClientTerritoryModelObjectsMutationResultEx>;
export type RemoveClientTerritoryModelObjectsLazyMutationReturn = [
  RemoveClientTerritoryModelObjectsWrappedLazyMutationFn,
  RemoveClientTerritoryModelObjectsMutationResultEx
];

function useRemoveClientTerritoryModelObjects(
  options?: Omit<
    MutationHookOptions<RemoveClientTerritoryModelMutation, RemoveClientTerritoryModelMutationVariables>,
    'mutation'
  >
): RemoveClientTerritoryModelObjectsLazyMutationReturn {
  const lazyMutation = useMutation<RemoveClientTerritoryModelMutation, RemoveClientTerritoryModelMutationVariables>(
    RemoveClientTerritoryModelDocument,
    options
  );

  const pickAffectedRows: PickRemoveClientTerritoryModelObjectsFn = (
    mutation: RemoveClientTerritoryModelMutation | null | undefined
  ) => {
    return mutation?.delete_clientTerritory?.affected_rows || 0;
  };

  const wrappedLazyMutation: RemoveClientTerritoryModelObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: RemoveClientTerritoryModelObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, affected_rows: pickAffectedRows(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], affected_rows: pickAffectedRows(lazyMutation[1].data) }];
}

// ClientTerritory Fragment Helper Object
//------------------------------------------------

export const ClientTerritoryFragmentGQLHooks = {
  useQueryById: useQueryClientTerritoryById,
  useQueryByIdLazy: useQueryClientTerritoryByIdLazy,
  useQueryObjects: useQueryClientTerritoryObjects,
  useQueryObjectsLazy: useQueryClientTerritoryObjectsLazy,
  useSubscribeToById: useSubscribeToClientTerritoryById,
  useSubscribeToObjects: useSubscribeToClientTerritoryObjects,
  useInsert: useInsertClientTerritory,
  useInsertWithOnConflict: useInsertClientTerritoryWithOnConflict,
  useInsertObjects: useInsertClientTerritoryObjects,
  useUpdateById: useUpdateClientTerritoryById,
  useUpdateObjects: useUpdateClientTerritoryObjects,
};

// clientTerritory MODEL HOOKS OBJECT
//------------------------------------------------

export const ClientTerritoryModelGQLHooks = {
  useRemoveById: useRemoveClientTerritoryModelById,
  useRemoveObjects: useRemoveClientTerritoryModelObjects,
};

// user REACT
//------------------------------------------------

export type UserByIdHookResultEx = { user: UserFragment | null | undefined };
export type UserObjectsHookResultEx = { objects: UserFragment[] };

/**
 *  Query Hook
 */

// Types
type QueryUserByIdResult = LazyQueryResult<QueryUserByIdQuery, QueryUserByIdQueryVariables>;
type QueryUserByIdSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<QueryUserByIdQuery, QueryUserByIdQueryVariables, QueryUserByIdQuery>,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryUserByIdResultEx = Omit<QueryUserByIdResult, 'subscribeToMore'> & {
  subscribeToMore: QueryUserByIdSubScribeToMore;
} & UserByIdHookResultEx;

// Function
function useQueryUserById({
  userId,
  options,
}: {
  userId: string;
  options?: Omit<QueryHookOptions<QueryUserByIdQuery, QueryUserByIdQueryVariables>, 'query' | 'variables'>;
}): QueryUserByIdResultEx {
  const _query: QueryUserByIdResult = useQuery<QueryUserByIdQuery, QueryUserByIdQueryVariables>(QueryUserByIdDocument, {
    variables: { userId },
    ...options,
  });

  const typedSubscribeToMore: QueryUserByIdSubScribeToMore = (options) => {
    _query.subscribeToMore({
      document: QueryUserByIdDocument,
      variables: { userId } as QueryUserByIdQueryVariables,
      ...options,
    });
  };
  const { subscribeToMore, ...query } = _query;
  return { ...query, subscribeToMore: typedSubscribeToMore, user: query?.data?.user_by_pk };
}

/**
 *  Lazy Query Hook
 */

// Types
type PickQueryUserByIdFn = (query: QueryUserByIdQuery | null | undefined) => UserFragment | null | undefined;
type QueryUserByIdLazyFn = [
  (options?: QueryLazyOptions<QueryUserByIdQueryVariables> | undefined) => void,
  QueryUserByIdResult
];
type QueryUserByIdWrappedLazyFn = (options: Omit<QueryLazyOptions<QueryUserByIdQueryVariables>, 'variables'>) => void;
export type QueryUserByIdLazyReturn = [QueryUserByIdWrappedLazyFn, QueryUserByIdResultEx];

// Function
function useQueryUserByIdLazy({
  userId,
  options,
}: {
  userId: string;
  options?: Omit<QueryHookOptions<QueryUserByIdQuery, QueryUserByIdQueryVariables>, 'query' | 'variables'>;
}): QueryUserByIdLazyReturn {
  const lazyQuery: QueryUserByIdLazyFn = useLazyQuery<QueryUserByIdQuery, QueryUserByIdQueryVariables>(
    QueryUserByIdDocument,
    options
  );

  // Setting up typed version of lazyQuery
  const pickQueryUserById: PickQueryUserByIdFn = (query) => {
    return query && query.user_by_pk;
  };
  const wrappedLazyQuery: QueryUserByIdWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryUserByIdSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({
        document: QueryUserByIdDocument,
        variables: { userId } as QueryUserByIdQueryVariables,
        ...options,
      });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    { ...lazyQueryResult, subscribeToMore: typedSubcribeToMore, user: pickQueryUserById(lazyQuery[1].data) },
  ];
}

/**
 *  Query Collection Hook
 */

// Types
export type QueryUserObjectsResult = LazyQueryResult<QueryUserObjectsQuery, QueryUserObjectsQueryVariables>;
type QueryUserObjectsSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<QueryUserObjectsQuery, QueryUserObjectsQueryVariables, QueryUserObjectsQuery>,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryUserObjectsResultEx = Omit<QueryUserObjectsResult, 'subscribeToMore'> & {
  subscribeToMore: QueryUserObjectsSubScribeToMore;
} & UserObjectsHookResultEx;

// Function
function useQueryUserObjects(
  options: Omit<QueryHookOptions<QueryUserObjectsQuery, QueryUserObjectsQueryVariables>, 'query'>
): QueryUserObjectsResultEx {
  const _query: QueryUserObjectsResult = useQuery<QueryUserObjectsQuery, QueryUserObjectsQueryVariables>(
    QueryUserObjectsDocument,
    options
  );

  const typedSubscribeToMore: QueryUserObjectsSubScribeToMore = (options) => {
    _query.subscribeToMore({ document: QueryUserObjectsDocument, ...options });
  };
  const { subscribeToMore, ...query } = _query;

  return { ...query, subscribeToMore: typedSubscribeToMore, objects: query?.data?.user || [] };
}

/**
 *  Lazy Query Collection Hook
 */

// Types
type PickQueryUserObjectsFn = (query: QueryUserObjectsQuery | null | undefined) => UserFragment[];
type QueryUserObjectsLazyFn = [
  (options?: QueryLazyOptions<QueryUserObjectsQueryVariables> | undefined) => void,
  QueryUserObjectsResult
];
type QueryUserObjectsWrappedLazyFn = (options?: QueryLazyOptions<QueryUserObjectsQueryVariables>) => void;
export type QueryUserObjectsLazyReturn = [QueryUserObjectsWrappedLazyFn, QueryUserObjectsResultEx];

// Function
function useQueryUserObjectsLazy(
  options?: Omit<LazyQueryHookOptions<QueryUserObjectsQuery, QueryUserObjectsQueryVariables>, 'query'>
): QueryUserObjectsLazyReturn {
  const lazyQuery: QueryUserObjectsLazyFn = useLazyQuery<QueryUserObjectsQuery, QueryUserObjectsQueryVariables>(
    QueryUserObjectsDocument,
    options
  );

  // Setting up typed version of lazyQuery
  const pickObjects: PickQueryUserObjectsFn = (query: QueryUserObjectsQuery | null | undefined) => {
    return query?.user || [];
  };
  const wrappedLazyQuery: QueryUserObjectsWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryUserObjectsSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore && lazyQuery[1].subscribeToMore({ document: QueryUserObjectsDocument, ...options });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    { ...lazyQueryResult, subscribeToMore: typedSubcribeToMore, objects: pickObjects(lazyQuery[1].data) },
  ];
}

/**
 *  Subscription Hook
 */

// Types
type SubscribeToUserByIdResult = {
  variables?: SubscribeToUserByIdSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToUserByIdSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToUserByIdResultEx = SubscribeToUserByIdResult & UserByIdHookResultEx;

// Function
function useSubscribeToUserById({
  userId,
  options,
}: {
  userId: string;
  options?: Omit<
    SubscriptionHookOptions<SubscribeToUserByIdSubscription, SubscribeToUserByIdSubscriptionVariables>,
    'query' | 'variables'
  >;
}): SubscribeToUserByIdResultEx {
  const subscription: SubscribeToUserByIdResult = useSubscription<
    SubscribeToUserByIdSubscription,
    SubscribeToUserByIdSubscriptionVariables
  >(SubscribeToUserByIdDocument, { variables: { userId }, ...options });
  return { ...subscription, user: subscription?.data?.user_by_pk };
}

/**
 *  Subscription Collection Hook
 */

// Types
export type SubscribeToUserObjectsResult = {
  variables?: SubscribeToUserObjectsSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToUserObjectsSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToUserObjectsResultEx = SubscribeToUserObjectsResult & UserObjectsHookResultEx;

// Function
function useSubscribeToUserObjects(
  options: Omit<
    SubscriptionHookOptions<SubscribeToUserObjectsSubscription, SubscribeToUserObjectsSubscriptionVariables>,
    'query'
  >
): SubscribeToUserObjectsResultEx {
  const subscription: SubscribeToUserObjectsResult = useSubscription<
    SubscribeToUserObjectsSubscription,
    SubscribeToUserObjectsSubscriptionVariables
  >(SubscribeToUserObjectsDocument, options);
  return { ...subscription, objects: subscription?.data?.user || [] };
}

/**
 *  Insert Hooks
 */

// Types
type InsertUserMutationResult = FetchResult<InsertUserMutation, Record<string, any>, Record<string, any>>;
export type InsertUserMutationResultEx = InsertUserMutationResult & UserByIdHookResultEx;

type PickInsertUserFn = (mutation: InsertUserMutation | null | undefined) => UserFragment | null | undefined;
type InsertUserLazyMutationFn = MutationTuple<InsertUserMutation, InsertUserMutationVariables>;
type InsertUserWrappedLazyMutationFn = ({
  user,
  autoOptimisticResponse,
  options,
}: {
  user: User_Insert_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<MutationFunctionOptions<InsertUserMutation, InsertUserMutationVariables>, 'variables'>;
}) => Promise<InsertUserMutationResultEx>;
export type InsertUserLazyMutationReturn = [InsertUserWrappedLazyMutationFn, InsertUserMutationResultEx];

// Function
function useInsertUser(
  options?: Omit<MutationHookOptions<InsertUserMutation, InsertUserMutationVariables>, 'mutation' | 'variables'>
): InsertUserLazyMutationReturn {
  const lazyMutation: InsertUserLazyMutationFn = useMutation<InsertUserMutation, InsertUserMutationVariables>(
    InsertUserDocument,
    options
  );
  const pickUser: PickInsertUserFn = (mutation) => {
    return mutation?.insert_user?.returning && mutation?.insert_user?.returning[0];
  };

  const wrappedLazyMutation: InsertUserWrappedLazyMutationFn = async ({ user, autoOptimisticResponse, options }) => {
    const objectForInsert = stripInsertInputClientFields({ input: user });
    const mutationOptions: MutationFunctionOptions<InsertUserMutation, InsertUserMutationVariables> = {
      variables: { objects: [objectForInsert] },
      ...options,
    };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id) throw new Error(`if autoOptimisticResponse = true, id must be set in object 'user'`);
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertUserMutation>({
        operationType: 'insert',
        entityName: 'user',
        objects: [objectForInsert as User_Insert_Input & ObjectWithId],
      });
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, user: pickUser(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], user: pickUser(lazyMutation[1].data) }];
}

//
//

// Types
type InsertUserWithOnConflictMutationResult = FetchResult<
  InsertUserWithOnConflictMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertUserWithOnConflictMutationResultEx = InsertUserWithOnConflictMutationResult & UserByIdHookResultEx;

type InsertUserWithOnConflictLazyMutationFn = MutationTuple<
  InsertUserWithOnConflictMutation,
  InsertUserWithOnConflictMutationVariables
>;
type InsertUserWithOnConflictWrappedLazyMutationFn = ({
  user,
  onConflict,
  autoOptimisticResponse,
  options,
}: {
  user: User_Insert_Input;
  onConflict: User_On_Conflict;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<InsertUserWithOnConflictMutation, InsertUserWithOnConflictMutationVariables>,
    'variables'
  >;
}) => Promise<InsertUserWithOnConflictMutationResultEx>;
export type InsertUserWithOnConflictLazyMutationReturn = [
  InsertUserWithOnConflictWrappedLazyMutationFn,
  InsertUserWithOnConflictMutationResultEx
];

// Function
function useInsertUserWithOnConflict(
  options?: Omit<
    MutationHookOptions<InsertUserWithOnConflictMutation, InsertUserWithOnConflictMutationVariables>,
    'mutation' | 'variables'
  >
): InsertUserWithOnConflictLazyMutationReturn {
  const lazyMutation: InsertUserWithOnConflictLazyMutationFn = useMutation<
    InsertUserWithOnConflictMutation,
    InsertUserWithOnConflictMutationVariables
  >(InsertUserWithOnConflictDocument, options);
  const pickUser: PickInsertUserFn = (mutation: InsertUserWithOnConflictMutation | null | undefined) => {
    return mutation?.insert_user?.returning && mutation.insert_user.returning[0];
  };

  const wrappedLazyMutation: InsertUserWithOnConflictWrappedLazyMutationFn = async ({
    user,
    onConflict,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: user });
    const mutationOptions: MutationFunctionOptions<
      InsertUserWithOnConflictMutation,
      InsertUserWithOnConflictMutationVariables
    > = { variables: { objects: [objectForInsert], onConflict }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id) throw new Error(`if autoOptimisticResponse = true, id must be set in object 'user'`);
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertUserWithOnConflictMutation>({
        operationType: 'insert',
        entityName: 'user',
        objects: [objectForInsert as User_Insert_Input & ObjectWithId],
      });
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, user: pickUser(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], user: pickUser(lazyMutation[1].data) }];
}

// Types
type InsertUserObjectsMutationResult = FetchResult<InsertUserMutation, Record<string, any>, Record<string, any>>;
export type InsertUserObjectsMutationResultEx = InsertUserMutationResult & UserObjectsHookResultEx;

type PickInsertUserObjectsFn = (mutation: InsertUserMutation | null | undefined) => UserFragment[];
type InsertUserObjectsLazyMutationFn = MutationTuple<InsertUserMutation, InsertUserMutationVariables>;
type InsertUserObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<InsertUserMutation, InsertUserMutationVariables>
) => Promise<InsertUserObjectsMutationResultEx>;
export type InsertUserObjectsLazyMutationReturn = [
  InsertUserObjectsWrappedLazyMutationFn,
  InsertUserObjectsMutationResultEx
];

// Function
function useInsertUserObjects(
  options?: Omit<MutationHookOptions<InsertUserMutation, InsertUserMutationVariables>, 'mutation'>
): InsertUserObjectsLazyMutationReturn {
  const lazyMutation: InsertUserObjectsLazyMutationFn = useMutation<InsertUserMutation, InsertUserMutationVariables>(
    InsertUserDocument,
    options
  );
  const pickObjects: PickInsertUserObjectsFn = (mutation: InsertUserMutation | null | undefined) => {
    return mutation?.insert_user?.returning || [];
  };

  const wrappedLazyMutation: InsertUserObjectsWrappedLazyMutationFn = async (options) => {
    if (options && options.variables && options.variables.objects)
      options.variables.objects = options.variables.objects.map((objectItem) =>
        stripInsertInputClientFields({ input: objectItem })
      );
    const fetchResult: InsertUserObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Update Hooks
 */

type UpdateUserByIdMutationResult = FetchResult<UpdateUserByIdMutation, Record<string, any>, Record<string, any>>;
export type UpdateUserByIdMutationResultEx = UpdateUserByIdMutationResult & UserByIdHookResultEx;

type PickUpdateUserByIdFn = (mutation: UpdateUserByIdMutation | null | undefined) => UserFragment | null | undefined;
type UpdateUserByIdLazyMutationFn = MutationTuple<UpdateUserByIdMutation, UpdateUserByIdMutationVariables>;
type UpdateUserByIdWrappedLazyMutationFn = ({
  userId,
  set,
  autoOptimisticResponse,
  options,
}: {
  userId: string;
  set: User_Set_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<MutationFunctionOptions<UpdateUserByIdMutation, UpdateUserByIdMutationVariables>, 'variables'>;
}) => Promise<UpdateUserByIdMutationResultEx>;
export type UpdateUserByIdLazyMutationReturn = [UpdateUserByIdWrappedLazyMutationFn, UpdateUserByIdMutationResultEx];

function useUpdateUserById(
  options?: Omit<MutationHookOptions<UpdateUserByIdMutation, UpdateUserByIdMutationVariables>, 'mutation' | 'variables'>
): UpdateUserByIdLazyMutationReturn {
  const lazyMutation: UpdateUserByIdLazyMutationFn = useMutation<
    UpdateUserByIdMutation,
    UpdateUserByIdMutationVariables
  >(UpdateUserByIdDocument, options);

  const pickUser: PickUpdateUserByIdFn = (mutation) => {
    return mutation?.update_user?.returning && mutation.update_user!.returning[0];
  };

  const wrappedLazyMutation: UpdateUserByIdWrappedLazyMutationFn = async ({
    userId,
    set,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<UpdateUserByIdMutation, UpdateUserByIdMutationVariables> = {
      variables: { id: userId, set },
      ...options,
    };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateUserByIdMutation>({
        operationType: 'update',
        entityName: 'user',
        objects: [{ id: userId, ...set }],
      });
    }

    const fetchResult: UpdateUserByIdMutationResult = await lazyMutation[0]({
      variables: { id: userId, set },
      ...mutationOptions,
    });
    return { ...fetchResult, user: pickUser(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], user: pickUser(lazyMutation[1].data) }];
}

//
//

// Types
type UpdateUserObjectsMutationResult = FetchResult<UpdateUserMutation, Record<string, any>, Record<string, any>>;
export type UpdateUserObjectsMutationResultEx = UpdateUserObjectsMutationResult & UserObjectsHookResultEx;

// Function
type PickUpdateUserObjectsFn = (mutation: UpdateUserMutation | null | undefined) => UserFragment[];
type UpdateUserObjectsLazyMutationFn = MutationTuple<UpdateUserMutation, UpdateUserMutationVariables>;
type UpdateUserObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<UpdateUserMutation, UpdateUserMutationVariables>
) => Promise<UpdateUserObjectsMutationResultEx>;
export type UpdateUserObjectsLazyMutationReturn = [
  UpdateUserObjectsWrappedLazyMutationFn,
  UpdateUserObjectsMutationResultEx
];

function useUpdateUserObjects(
  options?: Omit<MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>, 'mutation'>
): UpdateUserObjectsLazyMutationReturn {
  const lazyMutation: UpdateUserObjectsLazyMutationFn = useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    UpdateUserDocument,
    options
  );

  const pickObjects: PickUpdateUserObjectsFn = (mutation: UpdateUserMutation | null | undefined) => {
    return mutation?.update_user?.returning || [];
  };

  const wrappedLazyMutation: UpdateUserObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: UpdateUserObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Delete Hooks
 */

// Types
type RemoveUserModelByIdFetchResult = FetchResult<
  RemoveUserModelByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveUserModelByIdMutationResultEx = RemoveUserModelByIdFetchResult & RemoveEntitiesQueryHookResultEx;

// Function
type PickRemoveUserModelFn = (mutation: RemoveUserModelByIdMutation | null | undefined) => number;
type RemoveUserModelLazyMutationFn = MutationTuple<RemoveUserModelByIdMutation, RemoveUserModelByIdMutationVariables>;
type RemoveUserModelWrappedLazyMutationFn = ({
  userId,
  autoOptimisticResponse,
  options,
}: {
  userId: string;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<RemoveUserModelByIdMutation, RemoveUserModelByIdMutationVariables>,
    'variables'
  >;
}) => Promise<RemoveUserModelByIdMutationResultEx>;
export type RemoveUserModelLazyMutationReturn = [
  RemoveUserModelWrappedLazyMutationFn,
  RemoveUserModelByIdMutationResultEx
];

function useRemoveUserModelById(
  options?: Omit<
    MutationHookOptions<RemoveUserModelByIdMutation, RemoveUserModelByIdMutationVariables>,
    'mutation' | 'variables'
  >
) {
  const lazyMutation: RemoveUserModelLazyMutationFn = useMutation<
    RemoveUserModelByIdMutation,
    RemoveUserModelByIdMutationVariables
  >(RemoveUserModelByIdDocument, options);

  const pickAffectedRows: PickRemoveUserModelFn = (mutation: RemoveUserModelByIdMutation | null | undefined) => {
    return mutation?.delete_user?.affected_rows || 0;
  };

  const wrappedLazyMutation: RemoveUserModelWrappedLazyMutationFn = async ({
    userId,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<RemoveUserModelMutation, RemoveUserModelByIdMutationVariables> = {
      variables: { id: userId },
      ...options,
    };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<RemoveUserModelMutation>({
        operationType: 'delete',
        entityName: 'user',
        objects: [{ id: userId }],
      });
    }
    if (!options || !options.update) {
      mutationOptions.update = generateUpdateFunctionForMutation<RemoveUserModelByIdMutation>({
        operationType: 'delete',
        entityName: 'user',
        entityId: userId,
      });
    }

    const fetchResult: RemoveUserModelByIdFetchResult = await lazyMutation[0](mutationOptions);
    return { ...fetchResult, affected_rows: pickAffectedRows(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], affected_rows: pickAffectedRows(lazyMutation[1].data) }] as [
    typeof wrappedLazyMutation,
    typeof lazyMutation[1] & { affected_rows: ReturnType<typeof pickAffectedRows> }
  ];
}

//
//

// Types
type RemoveUserModelObjectsMutationResult = FetchResult<
  RemoveUserModelMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveUserModelObjectsMutationResultEx = RemoveUserModelObjectsMutationResult &
  RemoveEntitiesQueryHookResultEx;

// Function
type PickRemoveUserModelObjectsFn = (mutation: RemoveUserModelMutation | null | undefined) => number;
type RemoveUserModelObjectsLazyMutationFn = MutationTuple<RemoveUserModelMutation, RemoveUserModelMutationVariables>;
type RemoveUserModelObjectsWrappedLazyMutationFn = (
  options: MutationFunctionOptions<RemoveUserModelMutation, RemoveUserModelMutationVariables>
) => Promise<RemoveUserModelObjectsMutationResultEx>;
export type RemoveUserModelObjectsLazyMutationReturn = [
  RemoveUserModelObjectsWrappedLazyMutationFn,
  RemoveUserModelObjectsMutationResultEx
];

function useRemoveUserModelObjects(
  options?: Omit<MutationHookOptions<RemoveUserModelMutation, RemoveUserModelMutationVariables>, 'mutation'>
): RemoveUserModelObjectsLazyMutationReturn {
  const lazyMutation = useMutation<RemoveUserModelMutation, RemoveUserModelMutationVariables>(
    RemoveUserModelDocument,
    options
  );

  const pickAffectedRows: PickRemoveUserModelObjectsFn = (mutation: RemoveUserModelMutation | null | undefined) => {
    return mutation?.delete_user?.affected_rows || 0;
  };

  const wrappedLazyMutation: RemoveUserModelObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: RemoveUserModelObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, affected_rows: pickAffectedRows(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], affected_rows: pickAffectedRows(lazyMutation[1].data) }];
}

// User Fragment Helper Object
//------------------------------------------------

export const UserFragmentGQLHooks = {
  useQueryById: useQueryUserById,
  useQueryByIdLazy: useQueryUserByIdLazy,
  useQueryObjects: useQueryUserObjects,
  useQueryObjectsLazy: useQueryUserObjectsLazy,
  useSubscribeToById: useSubscribeToUserById,
  useSubscribeToObjects: useSubscribeToUserObjects,
  useInsert: useInsertUser,
  useInsertWithOnConflict: useInsertUserWithOnConflict,
  useInsertObjects: useInsertUserObjects,
  useUpdateById: useUpdateUserById,
  useUpdateObjects: useUpdateUserObjects,
};

// user MODEL HOOKS OBJECT
//------------------------------------------------

export const UserModelGQLHooks = {
  useRemoveById: useRemoveUserModelById,
  useRemoveObjects: useRemoveUserModelObjects,
};

// userInvite REACT
//------------------------------------------------

export type UserInviteByIdHookResultEx = { userInvite: UserInviteFragment | null | undefined };
export type UserInviteObjectsHookResultEx = { objects: UserInviteFragment[] };

/**
 *  Query Hook
 */

// Types
type QueryUserInviteByIdResult = LazyQueryResult<QueryUserInviteByIdQuery, QueryUserInviteByIdQueryVariables>;
type QueryUserInviteByIdSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<QueryUserInviteByIdQuery, QueryUserInviteByIdQueryVariables, QueryUserInviteByIdQuery>,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryUserInviteByIdResultEx = Omit<QueryUserInviteByIdResult, 'subscribeToMore'> & {
  subscribeToMore: QueryUserInviteByIdSubScribeToMore;
} & UserInviteByIdHookResultEx;

// Function
function useQueryUserInviteById({
  userInviteId,
  options,
}: {
  userInviteId: string;
  options?: Omit<QueryHookOptions<QueryUserInviteByIdQuery, QueryUserInviteByIdQueryVariables>, 'query' | 'variables'>;
}): QueryUserInviteByIdResultEx {
  const _query: QueryUserInviteByIdResult = useQuery<QueryUserInviteByIdQuery, QueryUserInviteByIdQueryVariables>(
    QueryUserInviteByIdDocument,
    { variables: { userInviteId }, ...options }
  );

  const typedSubscribeToMore: QueryUserInviteByIdSubScribeToMore = (options) => {
    _query.subscribeToMore({
      document: QueryUserInviteByIdDocument,
      variables: { userInviteId } as QueryUserInviteByIdQueryVariables,
      ...options,
    });
  };
  const { subscribeToMore, ...query } = _query;
  return { ...query, subscribeToMore: typedSubscribeToMore, userInvite: query?.data?.userInvite_by_pk };
}

/**
 *  Lazy Query Hook
 */

// Types
type PickQueryUserInviteByIdFn = (
  query: QueryUserInviteByIdQuery | null | undefined
) => UserInviteFragment | null | undefined;
type QueryUserInviteByIdLazyFn = [
  (options?: QueryLazyOptions<QueryUserInviteByIdQueryVariables> | undefined) => void,
  QueryUserInviteByIdResult
];
type QueryUserInviteByIdWrappedLazyFn = (
  options: Omit<QueryLazyOptions<QueryUserInviteByIdQueryVariables>, 'variables'>
) => void;
export type QueryUserInviteByIdLazyReturn = [QueryUserInviteByIdWrappedLazyFn, QueryUserInviteByIdResultEx];

// Function
function useQueryUserInviteByIdLazy({
  userInviteId,
  options,
}: {
  userInviteId: string;
  options?: Omit<QueryHookOptions<QueryUserInviteByIdQuery, QueryUserInviteByIdQueryVariables>, 'query' | 'variables'>;
}): QueryUserInviteByIdLazyReturn {
  const lazyQuery: QueryUserInviteByIdLazyFn = useLazyQuery<
    QueryUserInviteByIdQuery,
    QueryUserInviteByIdQueryVariables
  >(QueryUserInviteByIdDocument, options);

  // Setting up typed version of lazyQuery
  const pickQueryUserInviteById: PickQueryUserInviteByIdFn = (query) => {
    return query && query.userInvite_by_pk;
  };
  const wrappedLazyQuery: QueryUserInviteByIdWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryUserInviteByIdSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({
        document: QueryUserInviteByIdDocument,
        variables: { userInviteId } as QueryUserInviteByIdQueryVariables,
        ...options,
      });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    {
      ...lazyQueryResult,
      subscribeToMore: typedSubcribeToMore,
      userInvite: pickQueryUserInviteById(lazyQuery[1].data),
    },
  ];
}

/**
 *  Query Collection Hook
 */

// Types
export type QueryUserInviteObjectsResult = LazyQueryResult<
  QueryUserInviteObjectsQuery,
  QueryUserInviteObjectsQueryVariables
>;
type QueryUserInviteObjectsSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<
          QueryUserInviteObjectsQuery,
          QueryUserInviteObjectsQueryVariables,
          QueryUserInviteObjectsQuery
        >,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryUserInviteObjectsResultEx = Omit<QueryUserInviteObjectsResult, 'subscribeToMore'> & {
  subscribeToMore: QueryUserInviteObjectsSubScribeToMore;
} & UserInviteObjectsHookResultEx;

// Function
function useQueryUserInviteObjects(
  options: Omit<QueryHookOptions<QueryUserInviteObjectsQuery, QueryUserInviteObjectsQueryVariables>, 'query'>
): QueryUserInviteObjectsResultEx {
  const _query: QueryUserInviteObjectsResult = useQuery<
    QueryUserInviteObjectsQuery,
    QueryUserInviteObjectsQueryVariables
  >(QueryUserInviteObjectsDocument, options);

  const typedSubscribeToMore: QueryUserInviteObjectsSubScribeToMore = (options) => {
    _query.subscribeToMore({ document: QueryUserInviteObjectsDocument, ...options });
  };
  const { subscribeToMore, ...query } = _query;

  return { ...query, subscribeToMore: typedSubscribeToMore, objects: query?.data?.userInvite || [] };
}

/**
 *  Lazy Query Collection Hook
 */

// Types
type PickQueryUserInviteObjectsFn = (query: QueryUserInviteObjectsQuery | null | undefined) => UserInviteFragment[];
type QueryUserInviteObjectsLazyFn = [
  (options?: QueryLazyOptions<QueryUserInviteObjectsQueryVariables> | undefined) => void,
  QueryUserInviteObjectsResult
];
type QueryUserInviteObjectsWrappedLazyFn = (options?: QueryLazyOptions<QueryUserInviteObjectsQueryVariables>) => void;
export type QueryUserInviteObjectsLazyReturn = [QueryUserInviteObjectsWrappedLazyFn, QueryUserInviteObjectsResultEx];

// Function
function useQueryUserInviteObjectsLazy(
  options?: Omit<LazyQueryHookOptions<QueryUserInviteObjectsQuery, QueryUserInviteObjectsQueryVariables>, 'query'>
): QueryUserInviteObjectsLazyReturn {
  const lazyQuery: QueryUserInviteObjectsLazyFn = useLazyQuery<
    QueryUserInviteObjectsQuery,
    QueryUserInviteObjectsQueryVariables
  >(QueryUserInviteObjectsDocument, options);

  // Setting up typed version of lazyQuery
  const pickObjects: PickQueryUserInviteObjectsFn = (query: QueryUserInviteObjectsQuery | null | undefined) => {
    return query?.userInvite || [];
  };
  const wrappedLazyQuery: QueryUserInviteObjectsWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryUserInviteObjectsSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({ document: QueryUserInviteObjectsDocument, ...options });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    { ...lazyQueryResult, subscribeToMore: typedSubcribeToMore, objects: pickObjects(lazyQuery[1].data) },
  ];
}

/**
 *  Subscription Hook
 */

// Types
type SubscribeToUserInviteByIdResult = {
  variables?: SubscribeToUserInviteByIdSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToUserInviteByIdSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToUserInviteByIdResultEx = SubscribeToUserInviteByIdResult & UserInviteByIdHookResultEx;

// Function
function useSubscribeToUserInviteById({
  userInviteId,
  options,
}: {
  userInviteId: string;
  options?: Omit<
    SubscriptionHookOptions<SubscribeToUserInviteByIdSubscription, SubscribeToUserInviteByIdSubscriptionVariables>,
    'query' | 'variables'
  >;
}): SubscribeToUserInviteByIdResultEx {
  const subscription: SubscribeToUserInviteByIdResult = useSubscription<
    SubscribeToUserInviteByIdSubscription,
    SubscribeToUserInviteByIdSubscriptionVariables
  >(SubscribeToUserInviteByIdDocument, { variables: { userInviteId }, ...options });
  return { ...subscription, userInvite: subscription?.data?.userInvite_by_pk };
}

/**
 *  Subscription Collection Hook
 */

// Types
export type SubscribeToUserInviteObjectsResult = {
  variables?: SubscribeToUserInviteObjectsSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToUserInviteObjectsSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToUserInviteObjectsResultEx = SubscribeToUserInviteObjectsResult & UserInviteObjectsHookResultEx;

// Function
function useSubscribeToUserInviteObjects(
  options: Omit<
    SubscriptionHookOptions<
      SubscribeToUserInviteObjectsSubscription,
      SubscribeToUserInviteObjectsSubscriptionVariables
    >,
    'query'
  >
): SubscribeToUserInviteObjectsResultEx {
  const subscription: SubscribeToUserInviteObjectsResult = useSubscription<
    SubscribeToUserInviteObjectsSubscription,
    SubscribeToUserInviteObjectsSubscriptionVariables
  >(SubscribeToUserInviteObjectsDocument, options);
  return { ...subscription, objects: subscription?.data?.userInvite || [] };
}

/**
 *  Insert Hooks
 */

// Types
type InsertUserInviteMutationResult = FetchResult<InsertUserInviteMutation, Record<string, any>, Record<string, any>>;
export type InsertUserInviteMutationResultEx = InsertUserInviteMutationResult & UserInviteByIdHookResultEx;

type PickInsertUserInviteFn = (
  mutation: InsertUserInviteMutation | null | undefined
) => UserInviteFragment | null | undefined;
type InsertUserInviteLazyMutationFn = MutationTuple<InsertUserInviteMutation, InsertUserInviteMutationVariables>;
type InsertUserInviteWrappedLazyMutationFn = ({
  userInvite,
  autoOptimisticResponse,
  options,
}: {
  userInvite: UserInvite_Insert_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<MutationFunctionOptions<InsertUserInviteMutation, InsertUserInviteMutationVariables>, 'variables'>;
}) => Promise<InsertUserInviteMutationResultEx>;
export type InsertUserInviteLazyMutationReturn = [
  InsertUserInviteWrappedLazyMutationFn,
  InsertUserInviteMutationResultEx
];

// Function
function useInsertUserInvite(
  options?: Omit<
    MutationHookOptions<InsertUserInviteMutation, InsertUserInviteMutationVariables>,
    'mutation' | 'variables'
  >
): InsertUserInviteLazyMutationReturn {
  const lazyMutation: InsertUserInviteLazyMutationFn = useMutation<
    InsertUserInviteMutation,
    InsertUserInviteMutationVariables
  >(InsertUserInviteDocument, options);
  const pickUserInvite: PickInsertUserInviteFn = (mutation) => {
    return mutation?.insert_userInvite?.returning && mutation?.insert_userInvite?.returning[0];
  };

  const wrappedLazyMutation: InsertUserInviteWrappedLazyMutationFn = async ({
    userInvite,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: userInvite });
    const mutationOptions: MutationFunctionOptions<InsertUserInviteMutation, InsertUserInviteMutationVariables> = {
      variables: { objects: [objectForInsert] },
      ...options,
    };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id)
        throw new Error(`if autoOptimisticResponse = true, id must be set in object 'userInvite'`);
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertUserInviteMutation>({
        operationType: 'insert',
        entityName: 'userInvite',
        objects: [objectForInsert as UserInvite_Insert_Input & ObjectWithId],
      });
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, userInvite: pickUserInvite(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], userInvite: pickUserInvite(lazyMutation[1].data) }];
}

//
//

// Types
type InsertUserInviteWithOnConflictMutationResult = FetchResult<
  InsertUserInviteWithOnConflictMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertUserInviteWithOnConflictMutationResultEx = InsertUserInviteWithOnConflictMutationResult &
  UserInviteByIdHookResultEx;

type InsertUserInviteWithOnConflictLazyMutationFn = MutationTuple<
  InsertUserInviteWithOnConflictMutation,
  InsertUserInviteWithOnConflictMutationVariables
>;
type InsertUserInviteWithOnConflictWrappedLazyMutationFn = ({
  userInvite,
  onConflict,
  autoOptimisticResponse,
  options,
}: {
  userInvite: UserInvite_Insert_Input;
  onConflict: UserInvite_On_Conflict;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<InsertUserInviteWithOnConflictMutation, InsertUserInviteWithOnConflictMutationVariables>,
    'variables'
  >;
}) => Promise<InsertUserInviteWithOnConflictMutationResultEx>;
export type InsertUserInviteWithOnConflictLazyMutationReturn = [
  InsertUserInviteWithOnConflictWrappedLazyMutationFn,
  InsertUserInviteWithOnConflictMutationResultEx
];

// Function
function useInsertUserInviteWithOnConflict(
  options?: Omit<
    MutationHookOptions<InsertUserInviteWithOnConflictMutation, InsertUserInviteWithOnConflictMutationVariables>,
    'mutation' | 'variables'
  >
): InsertUserInviteWithOnConflictLazyMutationReturn {
  const lazyMutation: InsertUserInviteWithOnConflictLazyMutationFn = useMutation<
    InsertUserInviteWithOnConflictMutation,
    InsertUserInviteWithOnConflictMutationVariables
  >(InsertUserInviteWithOnConflictDocument, options);
  const pickUserInvite: PickInsertUserInviteFn = (
    mutation: InsertUserInviteWithOnConflictMutation | null | undefined
  ) => {
    return mutation?.insert_userInvite?.returning && mutation.insert_userInvite.returning[0];
  };

  const wrappedLazyMutation: InsertUserInviteWithOnConflictWrappedLazyMutationFn = async ({
    userInvite,
    onConflict,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: userInvite });
    const mutationOptions: MutationFunctionOptions<
      InsertUserInviteWithOnConflictMutation,
      InsertUserInviteWithOnConflictMutationVariables
    > = { variables: { objects: [objectForInsert], onConflict }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id)
        throw new Error(`if autoOptimisticResponse = true, id must be set in object 'userInvite'`);
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertUserInviteWithOnConflictMutation>(
        {
          operationType: 'insert',
          entityName: 'userInvite',
          objects: [objectForInsert as UserInvite_Insert_Input & ObjectWithId],
        }
      );
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, userInvite: pickUserInvite(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], userInvite: pickUserInvite(lazyMutation[1].data) }];
}

// Types
type InsertUserInviteObjectsMutationResult = FetchResult<
  InsertUserInviteMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertUserInviteObjectsMutationResultEx = InsertUserInviteMutationResult & UserInviteObjectsHookResultEx;

type PickInsertUserInviteObjectsFn = (mutation: InsertUserInviteMutation | null | undefined) => UserInviteFragment[];
type InsertUserInviteObjectsLazyMutationFn = MutationTuple<InsertUserInviteMutation, InsertUserInviteMutationVariables>;
type InsertUserInviteObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<InsertUserInviteMutation, InsertUserInviteMutationVariables>
) => Promise<InsertUserInviteObjectsMutationResultEx>;
export type InsertUserInviteObjectsLazyMutationReturn = [
  InsertUserInviteObjectsWrappedLazyMutationFn,
  InsertUserInviteObjectsMutationResultEx
];

// Function
function useInsertUserInviteObjects(
  options?: Omit<MutationHookOptions<InsertUserInviteMutation, InsertUserInviteMutationVariables>, 'mutation'>
): InsertUserInviteObjectsLazyMutationReturn {
  const lazyMutation: InsertUserInviteObjectsLazyMutationFn = useMutation<
    InsertUserInviteMutation,
    InsertUserInviteMutationVariables
  >(InsertUserInviteDocument, options);
  const pickObjects: PickInsertUserInviteObjectsFn = (mutation: InsertUserInviteMutation | null | undefined) => {
    return mutation?.insert_userInvite?.returning || [];
  };

  const wrappedLazyMutation: InsertUserInviteObjectsWrappedLazyMutationFn = async (options) => {
    if (options && options.variables && options.variables.objects)
      options.variables.objects = options.variables.objects.map((objectItem) =>
        stripInsertInputClientFields({ input: objectItem })
      );
    const fetchResult: InsertUserInviteObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Update Hooks
 */

type UpdateUserInviteByIdMutationResult = FetchResult<
  UpdateUserInviteByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateUserInviteByIdMutationResultEx = UpdateUserInviteByIdMutationResult & UserInviteByIdHookResultEx;

type PickUpdateUserInviteByIdFn = (
  mutation: UpdateUserInviteByIdMutation | null | undefined
) => UserInviteFragment | null | undefined;
type UpdateUserInviteByIdLazyMutationFn = MutationTuple<
  UpdateUserInviteByIdMutation,
  UpdateUserInviteByIdMutationVariables
>;
type UpdateUserInviteByIdWrappedLazyMutationFn = ({
  userInviteId,
  set,
  autoOptimisticResponse,
  options,
}: {
  userInviteId: string;
  set: UserInvite_Set_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<UpdateUserInviteByIdMutation, UpdateUserInviteByIdMutationVariables>,
    'variables'
  >;
}) => Promise<UpdateUserInviteByIdMutationResultEx>;
export type UpdateUserInviteByIdLazyMutationReturn = [
  UpdateUserInviteByIdWrappedLazyMutationFn,
  UpdateUserInviteByIdMutationResultEx
];

function useUpdateUserInviteById(
  options?: Omit<
    MutationHookOptions<UpdateUserInviteByIdMutation, UpdateUserInviteByIdMutationVariables>,
    'mutation' | 'variables'
  >
): UpdateUserInviteByIdLazyMutationReturn {
  const lazyMutation: UpdateUserInviteByIdLazyMutationFn = useMutation<
    UpdateUserInviteByIdMutation,
    UpdateUserInviteByIdMutationVariables
  >(UpdateUserInviteByIdDocument, options);

  const pickUserInvite: PickUpdateUserInviteByIdFn = (mutation) => {
    return mutation?.update_userInvite?.returning && mutation.update_userInvite!.returning[0];
  };

  const wrappedLazyMutation: UpdateUserInviteByIdWrappedLazyMutationFn = async ({
    userInviteId,
    set,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<
      UpdateUserInviteByIdMutation,
      UpdateUserInviteByIdMutationVariables
    > = { variables: { id: userInviteId, set }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateUserInviteByIdMutation>({
        operationType: 'update',
        entityName: 'userInvite',
        objects: [{ id: userInviteId, ...set }],
      });
    }

    const fetchResult: UpdateUserInviteByIdMutationResult = await lazyMutation[0]({
      variables: { id: userInviteId, set },
      ...mutationOptions,
    });
    return { ...fetchResult, userInvite: pickUserInvite(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], userInvite: pickUserInvite(lazyMutation[1].data) }];
}

//
//

// Types
type UpdateUserInviteObjectsMutationResult = FetchResult<
  UpdateUserInviteMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateUserInviteObjectsMutationResultEx = UpdateUserInviteObjectsMutationResult &
  UserInviteObjectsHookResultEx;

// Function
type PickUpdateUserInviteObjectsFn = (mutation: UpdateUserInviteMutation | null | undefined) => UserInviteFragment[];
type UpdateUserInviteObjectsLazyMutationFn = MutationTuple<UpdateUserInviteMutation, UpdateUserInviteMutationVariables>;
type UpdateUserInviteObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<UpdateUserInviteMutation, UpdateUserInviteMutationVariables>
) => Promise<UpdateUserInviteObjectsMutationResultEx>;
export type UpdateUserInviteObjectsLazyMutationReturn = [
  UpdateUserInviteObjectsWrappedLazyMutationFn,
  UpdateUserInviteObjectsMutationResultEx
];

function useUpdateUserInviteObjects(
  options?: Omit<MutationHookOptions<UpdateUserInviteMutation, UpdateUserInviteMutationVariables>, 'mutation'>
): UpdateUserInviteObjectsLazyMutationReturn {
  const lazyMutation: UpdateUserInviteObjectsLazyMutationFn = useMutation<
    UpdateUserInviteMutation,
    UpdateUserInviteMutationVariables
  >(UpdateUserInviteDocument, options);

  const pickObjects: PickUpdateUserInviteObjectsFn = (mutation: UpdateUserInviteMutation | null | undefined) => {
    return mutation?.update_userInvite?.returning || [];
  };

  const wrappedLazyMutation: UpdateUserInviteObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: UpdateUserInviteObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Delete Hooks
 */

// Types
type RemoveUserInviteModelByIdFetchResult = FetchResult<
  RemoveUserInviteModelByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveUserInviteModelByIdMutationResultEx = RemoveUserInviteModelByIdFetchResult &
  RemoveEntitiesQueryHookResultEx;

// Function
type PickRemoveUserInviteModelFn = (mutation: RemoveUserInviteModelByIdMutation | null | undefined) => number;
type RemoveUserInviteModelLazyMutationFn = MutationTuple<
  RemoveUserInviteModelByIdMutation,
  RemoveUserInviteModelByIdMutationVariables
>;
type RemoveUserInviteModelWrappedLazyMutationFn = ({
  userInviteId,
  autoOptimisticResponse,
  options,
}: {
  userInviteId: string;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<RemoveUserInviteModelByIdMutation, RemoveUserInviteModelByIdMutationVariables>,
    'variables'
  >;
}) => Promise<RemoveUserInviteModelByIdMutationResultEx>;
export type RemoveUserInviteModelLazyMutationReturn = [
  RemoveUserInviteModelWrappedLazyMutationFn,
  RemoveUserInviteModelByIdMutationResultEx
];

function useRemoveUserInviteModelById(
  options?: Omit<
    MutationHookOptions<RemoveUserInviteModelByIdMutation, RemoveUserInviteModelByIdMutationVariables>,
    'mutation' | 'variables'
  >
) {
  const lazyMutation: RemoveUserInviteModelLazyMutationFn = useMutation<
    RemoveUserInviteModelByIdMutation,
    RemoveUserInviteModelByIdMutationVariables
  >(RemoveUserInviteModelByIdDocument, options);

  const pickAffectedRows: PickRemoveUserInviteModelFn = (
    mutation: RemoveUserInviteModelByIdMutation | null | undefined
  ) => {
    return mutation?.delete_userInvite?.affected_rows || 0;
  };

  const wrappedLazyMutation: RemoveUserInviteModelWrappedLazyMutationFn = async ({
    userInviteId,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<
      RemoveUserInviteModelMutation,
      RemoveUserInviteModelByIdMutationVariables
    > = { variables: { id: userInviteId }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<RemoveUserInviteModelMutation>({
        operationType: 'delete',
        entityName: 'userInvite',
        objects: [{ id: userInviteId }],
      });
    }
    if (!options || !options.update) {
      mutationOptions.update = generateUpdateFunctionForMutation<RemoveUserInviteModelByIdMutation>({
        operationType: 'delete',
        entityName: 'userInvite',
        entityId: userInviteId,
      });
    }

    const fetchResult: RemoveUserInviteModelByIdFetchResult = await lazyMutation[0](mutationOptions);
    return { ...fetchResult, affected_rows: pickAffectedRows(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], affected_rows: pickAffectedRows(lazyMutation[1].data) }] as [
    typeof wrappedLazyMutation,
    typeof lazyMutation[1] & { affected_rows: ReturnType<typeof pickAffectedRows> }
  ];
}

//
//

// Types
type RemoveUserInviteModelObjectsMutationResult = FetchResult<
  RemoveUserInviteModelMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveUserInviteModelObjectsMutationResultEx = RemoveUserInviteModelObjectsMutationResult &
  RemoveEntitiesQueryHookResultEx;

// Function
type PickRemoveUserInviteModelObjectsFn = (mutation: RemoveUserInviteModelMutation | null | undefined) => number;
type RemoveUserInviteModelObjectsLazyMutationFn = MutationTuple<
  RemoveUserInviteModelMutation,
  RemoveUserInviteModelMutationVariables
>;
type RemoveUserInviteModelObjectsWrappedLazyMutationFn = (
  options: MutationFunctionOptions<RemoveUserInviteModelMutation, RemoveUserInviteModelMutationVariables>
) => Promise<RemoveUserInviteModelObjectsMutationResultEx>;
export type RemoveUserInviteModelObjectsLazyMutationReturn = [
  RemoveUserInviteModelObjectsWrappedLazyMutationFn,
  RemoveUserInviteModelObjectsMutationResultEx
];

function useRemoveUserInviteModelObjects(
  options?: Omit<MutationHookOptions<RemoveUserInviteModelMutation, RemoveUserInviteModelMutationVariables>, 'mutation'>
): RemoveUserInviteModelObjectsLazyMutationReturn {
  const lazyMutation = useMutation<RemoveUserInviteModelMutation, RemoveUserInviteModelMutationVariables>(
    RemoveUserInviteModelDocument,
    options
  );

  const pickAffectedRows: PickRemoveUserInviteModelObjectsFn = (
    mutation: RemoveUserInviteModelMutation | null | undefined
  ) => {
    return mutation?.delete_userInvite?.affected_rows || 0;
  };

  const wrappedLazyMutation: RemoveUserInviteModelObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: RemoveUserInviteModelObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, affected_rows: pickAffectedRows(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], affected_rows: pickAffectedRows(lazyMutation[1].data) }];
}

// UserInvite Fragment Helper Object
//------------------------------------------------

export const UserInviteFragmentGQLHooks = {
  useQueryById: useQueryUserInviteById,
  useQueryByIdLazy: useQueryUserInviteByIdLazy,
  useQueryObjects: useQueryUserInviteObjects,
  useQueryObjectsLazy: useQueryUserInviteObjectsLazy,
  useSubscribeToById: useSubscribeToUserInviteById,
  useSubscribeToObjects: useSubscribeToUserInviteObjects,
  useInsert: useInsertUserInvite,
  useInsertWithOnConflict: useInsertUserInviteWithOnConflict,
  useInsertObjects: useInsertUserInviteObjects,
  useUpdateById: useUpdateUserInviteById,
  useUpdateObjects: useUpdateUserInviteObjects,
};

// userInvite MODEL HOOKS OBJECT
//------------------------------------------------

export const UserInviteModelGQLHooks = {
  useRemoveById: useRemoveUserInviteModelById,
  useRemoveObjects: useRemoveUserInviteModelObjects,
};

// qaQualification REACT
//------------------------------------------------

export type QaQualificationByIdHookResultEx = { qaQualification: QaQualificationFragment | null | undefined };
export type QaQualificationObjectsHookResultEx = { objects: QaQualificationFragment[] };

/**
 *  Query Hook
 */

// Types
type QueryQaQualificationByIdResult = LazyQueryResult<
  QueryQaQualificationByIdQuery,
  QueryQaQualificationByIdQueryVariables
>;
type QueryQaQualificationByIdSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<
          QueryQaQualificationByIdQuery,
          QueryQaQualificationByIdQueryVariables,
          QueryQaQualificationByIdQuery
        >,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryQaQualificationByIdResultEx = Omit<QueryQaQualificationByIdResult, 'subscribeToMore'> & {
  subscribeToMore: QueryQaQualificationByIdSubScribeToMore;
} & QaQualificationByIdHookResultEx;

// Function
function useQueryQaQualificationById({
  qaQualificationId,
  options,
}: {
  qaQualificationId: string;
  options?: Omit<
    QueryHookOptions<QueryQaQualificationByIdQuery, QueryQaQualificationByIdQueryVariables>,
    'query' | 'variables'
  >;
}): QueryQaQualificationByIdResultEx {
  const _query: QueryQaQualificationByIdResult = useQuery<
    QueryQaQualificationByIdQuery,
    QueryQaQualificationByIdQueryVariables
  >(QueryQaQualificationByIdDocument, { variables: { qaQualificationId }, ...options });

  const typedSubscribeToMore: QueryQaQualificationByIdSubScribeToMore = (options) => {
    _query.subscribeToMore({
      document: QueryQaQualificationByIdDocument,
      variables: { qaQualificationId } as QueryQaQualificationByIdQueryVariables,
      ...options,
    });
  };
  const { subscribeToMore, ...query } = _query;
  return { ...query, subscribeToMore: typedSubscribeToMore, qaQualification: query?.data?.qaQualification_by_pk };
}

/**
 *  Lazy Query Hook
 */

// Types
type PickQueryQaQualificationByIdFn = (
  query: QueryQaQualificationByIdQuery | null | undefined
) => QaQualificationFragment | null | undefined;
type QueryQaQualificationByIdLazyFn = [
  (options?: QueryLazyOptions<QueryQaQualificationByIdQueryVariables> | undefined) => void,
  QueryQaQualificationByIdResult
];
type QueryQaQualificationByIdWrappedLazyFn = (
  options: Omit<QueryLazyOptions<QueryQaQualificationByIdQueryVariables>, 'variables'>
) => void;
export type QueryQaQualificationByIdLazyReturn = [
  QueryQaQualificationByIdWrappedLazyFn,
  QueryQaQualificationByIdResultEx
];

// Function
function useQueryQaQualificationByIdLazy({
  qaQualificationId,
  options,
}: {
  qaQualificationId: string;
  options?: Omit<
    QueryHookOptions<QueryQaQualificationByIdQuery, QueryQaQualificationByIdQueryVariables>,
    'query' | 'variables'
  >;
}): QueryQaQualificationByIdLazyReturn {
  const lazyQuery: QueryQaQualificationByIdLazyFn = useLazyQuery<
    QueryQaQualificationByIdQuery,
    QueryQaQualificationByIdQueryVariables
  >(QueryQaQualificationByIdDocument, options);

  // Setting up typed version of lazyQuery
  const pickQueryQaQualificationById: PickQueryQaQualificationByIdFn = (query) => {
    return query && query.qaQualification_by_pk;
  };
  const wrappedLazyQuery: QueryQaQualificationByIdWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryQaQualificationByIdSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({
        document: QueryQaQualificationByIdDocument,
        variables: { qaQualificationId } as QueryQaQualificationByIdQueryVariables,
        ...options,
      });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    {
      ...lazyQueryResult,
      subscribeToMore: typedSubcribeToMore,
      qaQualification: pickQueryQaQualificationById(lazyQuery[1].data),
    },
  ];
}

/**
 *  Query Collection Hook
 */

// Types
export type QueryQaQualificationObjectsResult = LazyQueryResult<
  QueryQaQualificationObjectsQuery,
  QueryQaQualificationObjectsQueryVariables
>;
type QueryQaQualificationObjectsSubScribeToMore = (
  options?:
    | Omit<
        SubscribeToMoreOptions<
          QueryQaQualificationObjectsQuery,
          QueryQaQualificationObjectsQueryVariables,
          QueryQaQualificationObjectsQuery
        >,
        'document' | 'variables'
      >
    | undefined
) => void;
export type QueryQaQualificationObjectsResultEx = Omit<QueryQaQualificationObjectsResult, 'subscribeToMore'> & {
  subscribeToMore: QueryQaQualificationObjectsSubScribeToMore;
} & QaQualificationObjectsHookResultEx;

// Function
function useQueryQaQualificationObjects(
  options: Omit<QueryHookOptions<QueryQaQualificationObjectsQuery, QueryQaQualificationObjectsQueryVariables>, 'query'>
): QueryQaQualificationObjectsResultEx {
  const _query: QueryQaQualificationObjectsResult = useQuery<
    QueryQaQualificationObjectsQuery,
    QueryQaQualificationObjectsQueryVariables
  >(QueryQaQualificationObjectsDocument, options);

  const typedSubscribeToMore: QueryQaQualificationObjectsSubScribeToMore = (options) => {
    _query.subscribeToMore({ document: QueryQaQualificationObjectsDocument, ...options });
  };
  const { subscribeToMore, ...query } = _query;

  return { ...query, subscribeToMore: typedSubscribeToMore, objects: query?.data?.qaQualification || [] };
}

/**
 *  Lazy Query Collection Hook
 */

// Types
type PickQueryQaQualificationObjectsFn = (
  query: QueryQaQualificationObjectsQuery | null | undefined
) => QaQualificationFragment[];
type QueryQaQualificationObjectsLazyFn = [
  (options?: QueryLazyOptions<QueryQaQualificationObjectsQueryVariables> | undefined) => void,
  QueryQaQualificationObjectsResult
];
type QueryQaQualificationObjectsWrappedLazyFn = (
  options?: QueryLazyOptions<QueryQaQualificationObjectsQueryVariables>
) => void;
export type QueryQaQualificationObjectsLazyReturn = [
  QueryQaQualificationObjectsWrappedLazyFn,
  QueryQaQualificationObjectsResultEx
];

// Function
function useQueryQaQualificationObjectsLazy(
  options?: Omit<
    LazyQueryHookOptions<QueryQaQualificationObjectsQuery, QueryQaQualificationObjectsQueryVariables>,
    'query'
  >
): QueryQaQualificationObjectsLazyReturn {
  const lazyQuery: QueryQaQualificationObjectsLazyFn = useLazyQuery<
    QueryQaQualificationObjectsQuery,
    QueryQaQualificationObjectsQueryVariables
  >(QueryQaQualificationObjectsDocument, options);

  // Setting up typed version of lazyQuery
  const pickObjects: PickQueryQaQualificationObjectsFn = (
    query: QueryQaQualificationObjectsQuery | null | undefined
  ) => {
    return query?.qaQualification || [];
  };
  const wrappedLazyQuery: QueryQaQualificationObjectsWrappedLazyFn = (options) => {
    return lazyQuery[0](options);
  };

  // Switching out SubcribeToMore with typed version
  const typedSubcribeToMore: QueryQaQualificationObjectsSubScribeToMore = (options) => {
    lazyQuery[1].subscribeToMore &&
      lazyQuery[1].subscribeToMore({ document: QueryQaQualificationObjectsDocument, ...options });
  };
  const { subscribeToMore, ...lazyQueryResult } = lazyQuery[1];

  return [
    wrappedLazyQuery,
    { ...lazyQueryResult, subscribeToMore: typedSubcribeToMore, objects: pickObjects(lazyQuery[1].data) },
  ];
}

/**
 *  Subscription Hook
 */

// Types
type SubscribeToQaQualificationByIdResult = {
  variables?: SubscribeToQaQualificationByIdSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToQaQualificationByIdSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToQaQualificationByIdResultEx = SubscribeToQaQualificationByIdResult &
  QaQualificationByIdHookResultEx;

// Function
function useSubscribeToQaQualificationById({
  qaQualificationId,
  options,
}: {
  qaQualificationId: string;
  options?: Omit<
    SubscriptionHookOptions<
      SubscribeToQaQualificationByIdSubscription,
      SubscribeToQaQualificationByIdSubscriptionVariables
    >,
    'query' | 'variables'
  >;
}): SubscribeToQaQualificationByIdResultEx {
  const subscription: SubscribeToQaQualificationByIdResult = useSubscription<
    SubscribeToQaQualificationByIdSubscription,
    SubscribeToQaQualificationByIdSubscriptionVariables
  >(SubscribeToQaQualificationByIdDocument, { variables: { qaQualificationId }, ...options });
  return { ...subscription, qaQualification: subscription?.data?.qaQualification_by_pk };
}

/**
 *  Subscription Collection Hook
 */

// Types
export type SubscribeToQaQualificationObjectsResult = {
  variables?: SubscribeToQaQualificationObjectsSubscriptionVariables;
  loading: boolean;
  data?: SubscribeToQaQualificationObjectsSubscription;
  error?: ApolloError | undefined;
};
export type SubscribeToQaQualificationObjectsResultEx = SubscribeToQaQualificationObjectsResult &
  QaQualificationObjectsHookResultEx;

// Function
function useSubscribeToQaQualificationObjects(
  options: Omit<
    SubscriptionHookOptions<
      SubscribeToQaQualificationObjectsSubscription,
      SubscribeToQaQualificationObjectsSubscriptionVariables
    >,
    'query'
  >
): SubscribeToQaQualificationObjectsResultEx {
  const subscription: SubscribeToQaQualificationObjectsResult = useSubscription<
    SubscribeToQaQualificationObjectsSubscription,
    SubscribeToQaQualificationObjectsSubscriptionVariables
  >(SubscribeToQaQualificationObjectsDocument, options);
  return { ...subscription, objects: subscription?.data?.qaQualification || [] };
}

/**
 *  Insert Hooks
 */

// Types
type InsertQaQualificationMutationResult = FetchResult<
  InsertQaQualificationMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertQaQualificationMutationResultEx = InsertQaQualificationMutationResult &
  QaQualificationByIdHookResultEx;

type PickInsertQaQualificationFn = (
  mutation: InsertQaQualificationMutation | null | undefined
) => QaQualificationFragment | null | undefined;
type InsertQaQualificationLazyMutationFn = MutationTuple<
  InsertQaQualificationMutation,
  InsertQaQualificationMutationVariables
>;
type InsertQaQualificationWrappedLazyMutationFn = ({
  qaQualification,
  autoOptimisticResponse,
  options,
}: {
  qaQualification: QaQualification_Insert_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<InsertQaQualificationMutation, InsertQaQualificationMutationVariables>,
    'variables'
  >;
}) => Promise<InsertQaQualificationMutationResultEx>;
export type InsertQaQualificationLazyMutationReturn = [
  InsertQaQualificationWrappedLazyMutationFn,
  InsertQaQualificationMutationResultEx
];

// Function
function useInsertQaQualification(
  options?: Omit<
    MutationHookOptions<InsertQaQualificationMutation, InsertQaQualificationMutationVariables>,
    'mutation' | 'variables'
  >
): InsertQaQualificationLazyMutationReturn {
  const lazyMutation: InsertQaQualificationLazyMutationFn = useMutation<
    InsertQaQualificationMutation,
    InsertQaQualificationMutationVariables
  >(InsertQaQualificationDocument, options);
  const pickQaQualification: PickInsertQaQualificationFn = (mutation) => {
    return mutation?.insert_qaQualification?.returning && mutation?.insert_qaQualification?.returning[0];
  };

  const wrappedLazyMutation: InsertQaQualificationWrappedLazyMutationFn = async ({
    qaQualification,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: qaQualification });
    const mutationOptions: MutationFunctionOptions<
      InsertQaQualificationMutation,
      InsertQaQualificationMutationVariables
    > = { variables: { objects: [objectForInsert] }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id)
        throw new Error(`if autoOptimisticResponse = true, id must be set in object 'qaQualification'`);
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertQaQualificationMutation>({
        operationType: 'insert',
        entityName: 'qaQualification',
        objects: [objectForInsert as QaQualification_Insert_Input & ObjectWithId],
      });
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, qaQualification: pickQaQualification(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], qaQualification: pickQaQualification(lazyMutation[1].data) }];
}

//
//

// Types
type InsertQaQualificationWithOnConflictMutationResult = FetchResult<
  InsertQaQualificationWithOnConflictMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertQaQualificationWithOnConflictMutationResultEx = InsertQaQualificationWithOnConflictMutationResult &
  QaQualificationByIdHookResultEx;

type InsertQaQualificationWithOnConflictLazyMutationFn = MutationTuple<
  InsertQaQualificationWithOnConflictMutation,
  InsertQaQualificationWithOnConflictMutationVariables
>;
type InsertQaQualificationWithOnConflictWrappedLazyMutationFn = ({
  qaQualification,
  onConflict,
  autoOptimisticResponse,
  options,
}: {
  qaQualification: QaQualification_Insert_Input;
  onConflict: QaQualification_On_Conflict;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<
      InsertQaQualificationWithOnConflictMutation,
      InsertQaQualificationWithOnConflictMutationVariables
    >,
    'variables'
  >;
}) => Promise<InsertQaQualificationWithOnConflictMutationResultEx>;
export type InsertQaQualificationWithOnConflictLazyMutationReturn = [
  InsertQaQualificationWithOnConflictWrappedLazyMutationFn,
  InsertQaQualificationWithOnConflictMutationResultEx
];

// Function
function useInsertQaQualificationWithOnConflict(
  options?: Omit<
    MutationHookOptions<
      InsertQaQualificationWithOnConflictMutation,
      InsertQaQualificationWithOnConflictMutationVariables
    >,
    'mutation' | 'variables'
  >
): InsertQaQualificationWithOnConflictLazyMutationReturn {
  const lazyMutation: InsertQaQualificationWithOnConflictLazyMutationFn = useMutation<
    InsertQaQualificationWithOnConflictMutation,
    InsertQaQualificationWithOnConflictMutationVariables
  >(InsertQaQualificationWithOnConflictDocument, options);
  const pickQaQualification: PickInsertQaQualificationFn = (
    mutation: InsertQaQualificationWithOnConflictMutation | null | undefined
  ) => {
    return mutation?.insert_qaQualification?.returning && mutation.insert_qaQualification.returning[0];
  };

  const wrappedLazyMutation: InsertQaQualificationWithOnConflictWrappedLazyMutationFn = async ({
    qaQualification,
    onConflict,
    autoOptimisticResponse,
    options,
  }) => {
    const objectForInsert = stripInsertInputClientFields({ input: qaQualification });
    const mutationOptions: MutationFunctionOptions<
      InsertQaQualificationWithOnConflictMutation,
      InsertQaQualificationWithOnConflictMutationVariables
    > = { variables: { objects: [objectForInsert], onConflict }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      if (!objectForInsert.id)
        throw new Error(`if autoOptimisticResponse = true, id must be set in object 'qaQualification'`);
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<InsertQaQualificationWithOnConflictMutation>(
        {
          operationType: 'insert',
          entityName: 'qaQualification',
          objects: [objectForInsert as QaQualification_Insert_Input & ObjectWithId],
        }
      );
    }

    const fetchResult = await lazyMutation[0](mutationOptions);

    return { ...fetchResult, qaQualification: pickQaQualification(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], qaQualification: pickQaQualification(lazyMutation[1].data) }];
}

// Types
type InsertQaQualificationObjectsMutationResult = FetchResult<
  InsertQaQualificationMutation,
  Record<string, any>,
  Record<string, any>
>;
export type InsertQaQualificationObjectsMutationResultEx = InsertQaQualificationMutationResult &
  QaQualificationObjectsHookResultEx;

type PickInsertQaQualificationObjectsFn = (
  mutation: InsertQaQualificationMutation | null | undefined
) => QaQualificationFragment[];
type InsertQaQualificationObjectsLazyMutationFn = MutationTuple<
  InsertQaQualificationMutation,
  InsertQaQualificationMutationVariables
>;
type InsertQaQualificationObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<InsertQaQualificationMutation, InsertQaQualificationMutationVariables>
) => Promise<InsertQaQualificationObjectsMutationResultEx>;
export type InsertQaQualificationObjectsLazyMutationReturn = [
  InsertQaQualificationObjectsWrappedLazyMutationFn,
  InsertQaQualificationObjectsMutationResultEx
];

// Function
function useInsertQaQualificationObjects(
  options?: Omit<MutationHookOptions<InsertQaQualificationMutation, InsertQaQualificationMutationVariables>, 'mutation'>
): InsertQaQualificationObjectsLazyMutationReturn {
  const lazyMutation: InsertQaQualificationObjectsLazyMutationFn = useMutation<
    InsertQaQualificationMutation,
    InsertQaQualificationMutationVariables
  >(InsertQaQualificationDocument, options);
  const pickObjects: PickInsertQaQualificationObjectsFn = (
    mutation: InsertQaQualificationMutation | null | undefined
  ) => {
    return mutation?.insert_qaQualification?.returning || [];
  };

  const wrappedLazyMutation: InsertQaQualificationObjectsWrappedLazyMutationFn = async (options) => {
    if (options && options.variables && options.variables.objects)
      options.variables.objects = options.variables.objects.map((objectItem) =>
        stripInsertInputClientFields({ input: objectItem })
      );
    const fetchResult: InsertQaQualificationObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Update Hooks
 */

type UpdateQaQualificationByIdMutationResult = FetchResult<
  UpdateQaQualificationByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateQaQualificationByIdMutationResultEx = UpdateQaQualificationByIdMutationResult &
  QaQualificationByIdHookResultEx;

type PickUpdateQaQualificationByIdFn = (
  mutation: UpdateQaQualificationByIdMutation | null | undefined
) => QaQualificationFragment | null | undefined;
type UpdateQaQualificationByIdLazyMutationFn = MutationTuple<
  UpdateQaQualificationByIdMutation,
  UpdateQaQualificationByIdMutationVariables
>;
type UpdateQaQualificationByIdWrappedLazyMutationFn = ({
  qaQualificationId,
  set,
  autoOptimisticResponse,
  options,
}: {
  qaQualificationId: string;
  set: QaQualification_Set_Input;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<UpdateQaQualificationByIdMutation, UpdateQaQualificationByIdMutationVariables>,
    'variables'
  >;
}) => Promise<UpdateQaQualificationByIdMutationResultEx>;
export type UpdateQaQualificationByIdLazyMutationReturn = [
  UpdateQaQualificationByIdWrappedLazyMutationFn,
  UpdateQaQualificationByIdMutationResultEx
];

function useUpdateQaQualificationById(
  options?: Omit<
    MutationHookOptions<UpdateQaQualificationByIdMutation, UpdateQaQualificationByIdMutationVariables>,
    'mutation' | 'variables'
  >
): UpdateQaQualificationByIdLazyMutationReturn {
  const lazyMutation: UpdateQaQualificationByIdLazyMutationFn = useMutation<
    UpdateQaQualificationByIdMutation,
    UpdateQaQualificationByIdMutationVariables
  >(UpdateQaQualificationByIdDocument, options);

  const pickQaQualification: PickUpdateQaQualificationByIdFn = (mutation) => {
    return mutation?.update_qaQualification?.returning && mutation.update_qaQualification!.returning[0];
  };

  const wrappedLazyMutation: UpdateQaQualificationByIdWrappedLazyMutationFn = async ({
    qaQualificationId,
    set,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<
      UpdateQaQualificationByIdMutation,
      UpdateQaQualificationByIdMutationVariables
    > = { variables: { id: qaQualificationId, set }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<UpdateQaQualificationByIdMutation>({
        operationType: 'update',
        entityName: 'qaQualification',
        objects: [{ id: qaQualificationId, ...set }],
      });
    }

    const fetchResult: UpdateQaQualificationByIdMutationResult = await lazyMutation[0]({
      variables: { id: qaQualificationId, set },
      ...mutationOptions,
    });
    return { ...fetchResult, qaQualification: pickQaQualification(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], qaQualification: pickQaQualification(lazyMutation[1].data) }];
}

//
//

// Types
type UpdateQaQualificationObjectsMutationResult = FetchResult<
  UpdateQaQualificationMutation,
  Record<string, any>,
  Record<string, any>
>;
export type UpdateQaQualificationObjectsMutationResultEx = UpdateQaQualificationObjectsMutationResult &
  QaQualificationObjectsHookResultEx;

// Function
type PickUpdateQaQualificationObjectsFn = (
  mutation: UpdateQaQualificationMutation | null | undefined
) => QaQualificationFragment[];
type UpdateQaQualificationObjectsLazyMutationFn = MutationTuple<
  UpdateQaQualificationMutation,
  UpdateQaQualificationMutationVariables
>;
type UpdateQaQualificationObjectsWrappedLazyMutationFn = (
  options?: MutationFunctionOptions<UpdateQaQualificationMutation, UpdateQaQualificationMutationVariables>
) => Promise<UpdateQaQualificationObjectsMutationResultEx>;
export type UpdateQaQualificationObjectsLazyMutationReturn = [
  UpdateQaQualificationObjectsWrappedLazyMutationFn,
  UpdateQaQualificationObjectsMutationResultEx
];

function useUpdateQaQualificationObjects(
  options?: Omit<MutationHookOptions<UpdateQaQualificationMutation, UpdateQaQualificationMutationVariables>, 'mutation'>
): UpdateQaQualificationObjectsLazyMutationReturn {
  const lazyMutation: UpdateQaQualificationObjectsLazyMutationFn = useMutation<
    UpdateQaQualificationMutation,
    UpdateQaQualificationMutationVariables
  >(UpdateQaQualificationDocument, options);

  const pickObjects: PickUpdateQaQualificationObjectsFn = (
    mutation: UpdateQaQualificationMutation | null | undefined
  ) => {
    return mutation?.update_qaQualification?.returning || [];
  };

  const wrappedLazyMutation: UpdateQaQualificationObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: UpdateQaQualificationObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, objects: pickObjects(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], objects: pickObjects(lazyMutation[1].data) }];
}

/**
 *  Delete Hooks
 */

// Types
type RemoveQaQualificationModelByIdFetchResult = FetchResult<
  RemoveQaQualificationModelByIdMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveQaQualificationModelByIdMutationResultEx = RemoveQaQualificationModelByIdFetchResult &
  RemoveEntitiesQueryHookResultEx;

// Function
type PickRemoveQaQualificationModelFn = (mutation: RemoveQaQualificationModelByIdMutation | null | undefined) => number;
type RemoveQaQualificationModelLazyMutationFn = MutationTuple<
  RemoveQaQualificationModelByIdMutation,
  RemoveQaQualificationModelByIdMutationVariables
>;
type RemoveQaQualificationModelWrappedLazyMutationFn = ({
  qaQualificationId,
  autoOptimisticResponse,
  options,
}: {
  qaQualificationId: string;
  autoOptimisticResponse?: boolean;
  options?: Omit<
    MutationFunctionOptions<RemoveQaQualificationModelByIdMutation, RemoveQaQualificationModelByIdMutationVariables>,
    'variables'
  >;
}) => Promise<RemoveQaQualificationModelByIdMutationResultEx>;
export type RemoveQaQualificationModelLazyMutationReturn = [
  RemoveQaQualificationModelWrappedLazyMutationFn,
  RemoveQaQualificationModelByIdMutationResultEx
];

function useRemoveQaQualificationModelById(
  options?: Omit<
    MutationHookOptions<RemoveQaQualificationModelByIdMutation, RemoveQaQualificationModelByIdMutationVariables>,
    'mutation' | 'variables'
  >
) {
  const lazyMutation: RemoveQaQualificationModelLazyMutationFn = useMutation<
    RemoveQaQualificationModelByIdMutation,
    RemoveQaQualificationModelByIdMutationVariables
  >(RemoveQaQualificationModelByIdDocument, options);

  const pickAffectedRows: PickRemoveQaQualificationModelFn = (
    mutation: RemoveQaQualificationModelByIdMutation | null | undefined
  ) => {
    return mutation?.delete_qaQualification?.affected_rows || 0;
  };

  const wrappedLazyMutation: RemoveQaQualificationModelWrappedLazyMutationFn = async ({
    qaQualificationId,
    autoOptimisticResponse,
    options,
  }) => {
    const mutationOptions: MutationFunctionOptions<
      RemoveQaQualificationModelMutation,
      RemoveQaQualificationModelByIdMutationVariables
    > = { variables: { id: qaQualificationId }, ...options };
    if (autoOptimisticResponse && (!options || !options.optimisticResponse)) {
      mutationOptions.optimisticResponse = generateOptimisticResponseForMutation<RemoveQaQualificationModelMutation>({
        operationType: 'delete',
        entityName: 'qaQualification',
        objects: [{ id: qaQualificationId }],
      });
    }
    if (!options || !options.update) {
      mutationOptions.update = generateUpdateFunctionForMutation<RemoveQaQualificationModelByIdMutation>({
        operationType: 'delete',
        entityName: 'qaQualification',
        entityId: qaQualificationId,
      });
    }

    const fetchResult: RemoveQaQualificationModelByIdFetchResult = await lazyMutation[0](mutationOptions);
    return { ...fetchResult, affected_rows: pickAffectedRows(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], affected_rows: pickAffectedRows(lazyMutation[1].data) }] as [
    typeof wrappedLazyMutation,
    typeof lazyMutation[1] & { affected_rows: ReturnType<typeof pickAffectedRows> }
  ];
}

//
//

// Types
type RemoveQaQualificationModelObjectsMutationResult = FetchResult<
  RemoveQaQualificationModelMutation,
  Record<string, any>,
  Record<string, any>
>;
export type RemoveQaQualificationModelObjectsMutationResultEx = RemoveQaQualificationModelObjectsMutationResult &
  RemoveEntitiesQueryHookResultEx;

// Function
type PickRemoveQaQualificationModelObjectsFn = (
  mutation: RemoveQaQualificationModelMutation | null | undefined
) => number;
type RemoveQaQualificationModelObjectsLazyMutationFn = MutationTuple<
  RemoveQaQualificationModelMutation,
  RemoveQaQualificationModelMutationVariables
>;
type RemoveQaQualificationModelObjectsWrappedLazyMutationFn = (
  options: MutationFunctionOptions<RemoveQaQualificationModelMutation, RemoveQaQualificationModelMutationVariables>
) => Promise<RemoveQaQualificationModelObjectsMutationResultEx>;
export type RemoveQaQualificationModelObjectsLazyMutationReturn = [
  RemoveQaQualificationModelObjectsWrappedLazyMutationFn,
  RemoveQaQualificationModelObjectsMutationResultEx
];

function useRemoveQaQualificationModelObjects(
  options?: Omit<
    MutationHookOptions<RemoveQaQualificationModelMutation, RemoveQaQualificationModelMutationVariables>,
    'mutation'
  >
): RemoveQaQualificationModelObjectsLazyMutationReturn {
  const lazyMutation = useMutation<RemoveQaQualificationModelMutation, RemoveQaQualificationModelMutationVariables>(
    RemoveQaQualificationModelDocument,
    options
  );

  const pickAffectedRows: PickRemoveQaQualificationModelObjectsFn = (
    mutation: RemoveQaQualificationModelMutation | null | undefined
  ) => {
    return mutation?.delete_qaQualification?.affected_rows || 0;
  };

  const wrappedLazyMutation: RemoveQaQualificationModelObjectsWrappedLazyMutationFn = async (options) => {
    const fetchResult: RemoveQaQualificationModelObjectsMutationResult = await lazyMutation[0](options);
    return { ...fetchResult, affected_rows: pickAffectedRows(fetchResult.data) };
  };

  return [wrappedLazyMutation, { ...lazyMutation[1], affected_rows: pickAffectedRows(lazyMutation[1].data) }];
}

// QaQualification Fragment Helper Object
//------------------------------------------------

export const QaQualificationFragmentGQLHooks = {
  useQueryById: useQueryQaQualificationById,
  useQueryByIdLazy: useQueryQaQualificationByIdLazy,
  useQueryObjects: useQueryQaQualificationObjects,
  useQueryObjectsLazy: useQueryQaQualificationObjectsLazy,
  useSubscribeToById: useSubscribeToQaQualificationById,
  useSubscribeToObjects: useSubscribeToQaQualificationObjects,
  useInsert: useInsertQaQualification,
  useInsertWithOnConflict: useInsertQaQualificationWithOnConflict,
  useInsertObjects: useInsertQaQualificationObjects,
  useUpdateById: useUpdateQaQualificationById,
  useUpdateObjects: useUpdateQaQualificationObjects,
};

// qaQualification MODEL HOOKS OBJECT
//------------------------------------------------

export const QaQualificationModelGQLHooks = {
  useRemoveById: useRemoveQaQualificationModelById,
  useRemoveObjects: useRemoveQaQualificationModelObjects,
};

/*
 * COMBINED HOOKS OBJECT
 */
export const GQLHooks = {
  Fragments: {
    ClientContactFilter: ClientContactFilterFragmentGQLHooks,
    ClientContactJobChange: ClientContactJobChangeFragmentGQLHooks,
    ClientContactExternal: ClientContactExternalFragmentGQLHooks,
    ClientContactQa: ClientContactQaFragmentGQLHooks,
    CrmContactEnrichmentHistory: CrmContactEnrichmentHistoryFragmentGQLHooks,
    ClientContactCrmData: ClientContactCrmDataFragmentGQLHooks,
    ClientExternal: ClientExternalFragmentGQLHooks,
    ClientQa: ClientQaFragmentGQLHooks,
    ClientInternal: ClientInternalFragmentGQLHooks,
    ClientSelect: ClientSelectFragmentGQLHooks,
    ClientNotificationEmail: ClientNotificationEmailFragmentGQLHooks,
    CloudTask: CloudTaskFragmentGQLHooks,
    ClientEmailReport: ClientEmailReportFragmentGQLHooks,
    EmailHistory: EmailHistoryFragmentGQLHooks,
    SendgridEvent: SendgridEventFragmentGQLHooks,
    CsvUpload: CsvUploadFragmentGQLHooks,
    HubspotContactList: HubspotContactListFragmentGQLHooks,
    HubspotContactListHubspotContact: HubspotContactListHubspotContactFragmentGQLHooks,
    HubspotContactProperty: HubspotContactPropertyFragmentGQLHooks,
    SalesforceContactField: SalesforceContactFieldFragmentGQLHooks,
    HubspotIntegration: HubspotIntegrationFragmentGQLHooks,
    SalesforceIntegration: SalesforceIntegrationFragmentGQLHooks,
    QaTaskAction: QaTaskActionFragmentGQLHooks,
    QaTask: QaTaskFragmentGQLHooks,
    ClientTerritory: ClientTerritoryFragmentGQLHooks,
    User: UserFragmentGQLHooks,
    UserInvite: UserInviteFragmentGQLHooks,
    QaQualification: QaQualificationFragmentGQLHooks,
  },
  Models: {
    ClientContactFilter: ClientContactFilterModelGQLHooks,
    ClientContactJobChange: ClientContactJobChangeModelGQLHooks,
    ClientContact: ClientContactModelGQLHooks,
    CrmContactEnrichmentHistory: CrmContactEnrichmentHistoryModelGQLHooks,
    ClientContactCrmData: ClientContactCrmDataModelGQLHooks,
    Client: ClientModelGQLHooks,
    ClientNotificationEmail: ClientNotificationEmailModelGQLHooks,
    CloudTask: CloudTaskModelGQLHooks,
    ClientEmailReport: ClientEmailReportModelGQLHooks,
    EmailHistory: EmailHistoryModelGQLHooks,
    SendgridEvent: SendgridEventModelGQLHooks,
    CsvUpload: CsvUploadModelGQLHooks,
    HubspotContactList: HubspotContactListModelGQLHooks,
    HubspotContactListHubspotContact: HubspotContactListHubspotContactModelGQLHooks,
    HubspotContactProperty: HubspotContactPropertyModelGQLHooks,
    SalesforceContactField: SalesforceContactFieldModelGQLHooks,
    HubspotIntegration: HubspotIntegrationModelGQLHooks,
    SalesforceIntegration: SalesforceIntegrationModelGQLHooks,
    QaTaskAction: QaTaskActionModelGQLHooks,
    QaTask: QaTaskModelGQLHooks,
    ClientTerritory: ClientTerritoryModelGQLHooks,
    User: UserModelGQLHooks,
    UserInvite: UserInviteModelGQLHooks,
    QaQualification: QaQualificationModelGQLHooks,
  },
};
