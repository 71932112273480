import { Box, Button, Chip, Grid, MenuItem, Select, Typography } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import React, { useContext, useMemo, useState } from 'react';

import { LoadingAndAlertContext } from '../../../..';
import LoadingButtonAnimated from '../../../../components/LoadingButtonAnimated';
import { GQLHooks } from '../../../../graphql/hasura/react';
import { refreshHubspotContactLists } from '../../../../modules/cloudFunctions';
import { AlertSeverity } from '../../../../utils/constants';
import { ClientContext } from '../../../Main';

interface Props {
  selectedListIds: string[];
  alreadySyncedListIds?: string[];
  setSelectedListIds: React.Dispatch<React.SetStateAction<string[]>>;
}

const SelectContactLists: React.FC<Props> = ({ selectedListIds, setSelectedListIds, alreadySyncedListIds }) => {
  const { selectedClient } = useContext(ClientContext);
  const { setSnackbarAlertData } = useContext(LoadingAndAlertContext);

  const [selectedListId, setSelectedListId] = useState('');

  const {
    objects: hubspotContactLists,
    refetch: refetchContactLists,
  } = GQLHooks.Fragments.HubspotContactList.useQueryObjects({
    variables: {
      where: {
        clientId: {
          _eq: selectedClient.id,
        },
      },
    },
  });

  const onClickRefreshContactLists = async () => {
    await refreshHubspotContactLists(selectedClient.hubspotIntegration!.id);
    refetchContactLists && (await refetchContactLists());
    setSnackbarAlertData({
      severity: AlertSeverity.SUCCESS,
      message: 'Contact lists refreshed',
    });
  };

  const sortedContactLists = useMemo(() => {
    return hubspotContactLists.slice().sort((listA, listB) => {
      return listA.name.toLowerCase() > listB.name.toLowerCase() ? 1 : -1;
    });
  }, [hubspotContactLists]);

  const menuItems = useMemo(() => {
    return sortedContactLists.map((list) => {
      return (
        <MenuItem key={list.listId} value={list.listId}>
          {list.name} {list.contactCount !== null ? `(${list.contactCount} contacts)` : null}
        </MenuItem>
      );
    });
  }, [sortedContactLists]);

  const onClickRemove = (listId: string) => {
    setSelectedListIds(selectedListIds.filter((id) => id !== listId));
  };

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedId = event.target.value as string;

    setSelectedListId(selectedId.toString());
  };

  const onClickAdd = () => {
    if (selectedListId && !selectedListIds.includes(selectedListId)) {
      setSelectedListIds([...selectedListIds, selectedListId]);
    }
  };

  return (
    <Grid container>
      {Boolean(selectedListIds.length) && (
        <Box margin={1}>
          <Grid container spacing={1}>
            <Grid item>
              <Typography variant="caption">Only sync in contacts from:</Typography>
            </Grid>
            {selectedListIds.map((id) => {
              const list = sortedContactLists.find((list) => list.listId === id);
              const listAlreadySynced = (alreadySyncedListIds || []).includes(id);
              return list ? (
                <Grid item key={id}>
                  <Chip
                    variant="outlined"
                    size="small"
                    label={list.name}
                    onDelete={listAlreadySynced ? undefined : () => onClickRemove(list.listId)}
                    color="primary"
                  />
                </Grid>
              ) : undefined;
            })}
          </Grid>
        </Box>
      )}

      <Grid container spacing={2} alignItems="center">
        <Grid item xs={8}>
          <Select value={selectedListId} onChange={handleChange} fullWidth>
            <MenuItem value={0} />
            {menuItems}
          </Select>
        </Grid>
        <Grid container item xs={4} alignItems="center" spacing={2}>
          <Grid item>
            <LoadingButtonAnimated
              initialIcon={<RefreshIcon />}
              onClick={onClickRefreshContactLists}
              tooltipText={'If you do not see the list you are looking for, press this to refresh the lists.'}
            />
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" type="submit" onClick={onClickAdd}>
              Add
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SelectContactLists;
