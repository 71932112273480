import { Button, Grid, Typography } from '@material-ui/core';
import React, { useState } from 'react';

import ChangePasswordModal from './account/ChangePasswordModal';

const AccountSettings: React.FC = () => {
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);

  return (
    <Grid container item direction="column" alignItems="flex-start" spacing={2}>
      <Grid item>
        <Typography variant="h5">Change Password</Typography>
      </Grid>
      <Grid item>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            setShowChangePasswordModal(true);
          }}
        >
          Change Password
        </Button>
      </Grid>
      <ChangePasswordModal
        showChangePasswordModal={showChangePasswordModal}
        setShowChangePasswordModal={setShowChangePasswordModal}
      />
    </Grid>
  );
};

export default AccountSettings;
