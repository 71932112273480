import { Box, Grid, Link, Typography } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { Redirect } from 'react-router-dom';

import UploadCsvModal from '../../components/uploadCSV/UploadCSVModal';
import { CSV_LOGO, HUBSPOT_LOGO, NavigationPath, SALESFORCE_LOGO, SECURE_ICON } from '../../utils/constants';
import { WarmlyUrl } from '../../utils/constantsAll/url';
import HubspotInitializeModal from '../integration/HubspotInitializeModal';
import SalesforceInitializeModal from '../integration/SalesforceInitializeModal';
import { ClientContext } from '../Main';

const UploadOrSync: React.FC = () => {
  const { selectedClient } = useContext(ClientContext);
  const [showUploadCsvModal, setShowUploadCsvModal] = useState(false);
  const [showSalesforceInitializeModal, setShowSalesforceInitializeModal] = useState(false);
  const [showHubspotInitializeModal, setShowHubspotInitializeModal] = useState(false);

  if (selectedClient.hubspotIntegration || selectedClient.salesforceIntegration) {
    return <Redirect to={NavigationPath.INTEGRATION} />;
  }

  return (
    <Grid container direction="column" justify="flex-start" alignItems="flex-start" spacing={3}>
      <Grid item container>
        <Typography variant="h2" color="primary">
          Sync your existing CRM or upload a CSV
        </Typography>
      </Grid>

      <Grid item container justify="center">
        <Box
          paddingY={2}
          // paddingX={2}
          // textAlign="center"
          style={{ cursor: 'pointer' }}
        >
          <Typography variant="h5" color="primary">
            <Box fontWeight="bold">
              We will not process your data until you’ve spoken with a Warmly sales rep to set up a plan. To get in
              touch with us,{' '}
              <Link
                underline="always"
                href={WarmlyUrl.Contact}
                target="_blank"
                rel="noopener noreferrer"
              >
                please fill out this form
              </Link>
              .
            </Box>
          </Typography>
        </Box>
      </Grid>

      <Grid item container justify="center">
        <Box paddingTop={1}>
          <Typography variant="h4" color="primary">
            Get notified when your champions change jobs.
          </Typography>
        </Box>
      </Grid>

      <Grid item container justify="center">
        <Box fontWeight="bold">
          <Typography variant="h4" color="primary">
            Click below
          </Typography>
        </Box>
      </Grid>

      <Grid container item direction="row" justify="center" alignItems="center" spacing={7}>
        <Grid item>
          <Box
            border={1}
            borderRadius={4}
            paddingY={2}
            marginY={2}
            width={250}
            height={100}
            borderColor="primary.main"
            textAlign="center"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setShowHubspotInitializeModal(true);
            }}
          >
            <img alt="hubspot-logo" src={HUBSPOT_LOGO} height="100%" />
          </Box>
        </Grid>

        <Grid item>
          <Box
            border={1}
            borderRadius={4}
            paddingY={2}
            marginY={2}
            width={250}
            height={100}
            borderColor="primary.main"
            textAlign="center"
            onClick={() => {
              setShowSalesforceInitializeModal(true);
            }}
            style={{ cursor: 'pointer' }}
          >
            <img alt="salesforce-logo" src={SALESFORCE_LOGO} height="100%" />
          </Box>
        </Grid>

        <Grid item>
          <Box
            border={1}
            borderRadius={4}
            paddingY={2}
            marginY={2}
            width={250}
            height={100}
            borderColor="primary.main"
            textAlign="center"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setShowUploadCsvModal(true);
            }}
          >
            <img alt="csv-logo" src={CSV_LOGO} height="100%" />
          </Box>
        </Grid>
      </Grid>
      <Grid container item direction="row" justify="center" alignItems="center">
        <Grid item>
          <Box width={300} height={100}>
            <Grid container spacing={2}>
              <Grid item>
                <img alt="secure-icon" src={SECURE_ICON} />
              </Grid>
              <Grid item xs>
                <Typography>
                  Your customer data will never be shared with anyone. Your customers will not be made aware of Warmly's
                  tracking service.
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>

      <Grid item container justify="center">
        <Box paddingTop={2} paddingBottom={3}>
          <Typography variant="h4" color="primary">
            Watch a 2 minute demo video below:
          </Typography>
        </Box>
      </Grid>

      <Grid container item direction="row" justify="center" alignItems="center">
        <Grid item>
          <Box width={700} height={500}>
            <iframe
              title="Warmly Product Demo Vdieo"
              src={'https://www.loom.com/embed/8a08371f0afe44c0bf3984b3a93213cd'}
              frameBorder="0"
              // webkitAllowFullScreen
              // mozallowfullscreen
              allowFullScreen
              style={{ width: '100%', height: '100%' }}
            />
          </Box>
        </Grid>
      </Grid>

      <Grid container item direction="row" justify="center" alignItems="center"></Grid>

      <UploadCsvModal showUploadCsvModal={showUploadCsvModal} setShowUploadCsvModal={setShowUploadCsvModal} />
      <SalesforceInitializeModal
        showSalesforceInitializeModal={showSalesforceInitializeModal}
        setShowSalesforceInitializeModal={setShowSalesforceInitializeModal}
      />
      <HubspotInitializeModal
        showHubspotInitializeModal={showHubspotInitializeModal}
        setShowHubspotInitializeModal={setShowHubspotInitializeModal}
      />
    </Grid>
  );
};

export default UploadOrSync;
