import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useContext } from 'react';

import { AuthContext } from '../../../components/auth/AuthProvider';
import { changePassword, reauthenticateUser } from '../../../modules/auth';
import { AlertSeverity } from '../../../utils/constants';
import { getAuthErrorMessage } from '../../../utils/errors';

interface ChangePasswordModalProps {
  showChangePasswordModal: boolean;
  setShowChangePasswordModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const ChangePasswordModal: React.FC<ChangePasswordModalProps> = ({
  showChangePasswordModal,
  setShowChangePasswordModal,
}) => {
  const { authUser } = useContext(AuthContext);

  const [newPassword, setNewPassword] = React.useState('');
  const [currentPassword, setCurrentPassword] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [passwordChanged, setPasswordChanged] = React.useState(false);
  const [alert, setAlert] = React.useState<AlertData | undefined>();

  const onCloseModal = () => {
    setShowChangePasswordModal(false);
  };

  const onClickChangePassword = async () => {
    setAlert(undefined);

    if (!newPassword.trim()) {
      setAlert({
        severity: AlertSeverity.ERROR,
        message: 'Please enter both your current and new passwords',
      });
    } else {
      try {
        setIsLoading(true);
        // Firebase requires reauthenticating before changing password or email
        await reauthenticateUser(authUser!, currentPassword);

        await changePassword(authUser!, newPassword);
        setAlert({ severity: AlertSeverity.SUCCESS, message: 'Password changed' });
        setPasswordChanged(true);
      } catch (err) {
        const errorMsg = getAuthErrorMessage(err);
        setAlert({
          severity: AlertSeverity.ERROR,
          message: errorMsg,
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <Dialog open={showChangePasswordModal} onClose={onCloseModal} fullWidth={true} maxWidth="sm">
      <DialogTitle>Change your password</DialogTitle>
      <DialogContent>
        <Box paddingY={1}>
          <Grid container spacing={3}>
            <Grid item xs={8} md={6}>
              <TextField
                label="Current Password"
                type="password"
                value={currentPassword}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setCurrentPassword(event.target.value);
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={8} md={6}>
              <TextField
                label="New Password"
                type="password"
                value={newPassword}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setNewPassword(event.target.value);
                }}
                fullWidth
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        {isLoading && (
          <Box marginLeft={2}>
            <CircularProgress />
          </Box>
        )}
        {passwordChanged ? (
          <Button variant="contained" color="primary" onClick={onCloseModal}>
            Ok
          </Button>
        ) : (
          <Button variant="contained" color="primary" onClick={onClickChangePassword} disabled={isLoading}>
            Confirm
          </Button>
        )}
      </DialogActions>
      {alert && <Alert severity={alert.severity}>{alert.message}</Alert>}
    </Dialog>
  );
};

export default ChangePasswordModal;
