import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';

import { CURRENT_ENVIRONMENT, Environment } from '../../../utils/constants';

const useStyles = makeStyles((theme) => ({
  headerRow: {
    fontWeight: 'bold',
    fontSize: theme.typography.h6.fontSize,
    marginBottom: 1,
  },
}));
interface Props {
  totalClientContactCount?: number;
}

const EnrichedCrmHeaders: React.FC<Props> = ({ totalClientContactCount }) => {
  const classes = useStyles();

  return (
    <Grid container style={{ marginTop: 30 }}>
      <Grid item xs={12}>
        <Grid container wrap="nowrap" direction="row" alignItems="center" className={classes.headerRow}>
          <Grid item xs={3}>
            <Typography variant="body2">
              {typeof totalClientContactCount === 'number' &&
                `Total ${totalClientContactCount.toLocaleString()} contacts`}
            </Typography>
          </Grid>
          <Grid container item direction="column" justify="center" alignItems="center" xs={3}>
            <Box textAlign="center">Email</Box>
          </Grid>
          <Grid container item direction="column" justify="center" alignItems="center" xs={2}>
            <Box textAlign="center">Company</Box>
          </Grid>
          <Grid
            container
            item
            direction="column"
            justify="center"
            alignItems="center"
            xs={CURRENT_ENVIRONMENT === Environment.Production ? 4 : 3}
          >
            <Box textAlign="center">Title</Box>
          </Grid>

          {CURRENT_ENVIRONMENT !== Environment.Production && (
            <Grid container item direction="column" justify="center" alignItems="center" xs={1}>
              <Box textAlign="center">Owner</Box>
            </Grid>
          )}

          <Grid item style={{ visibility: 'hidden' }}>
            <Box marginX={2} aria-label="linked-in" style={{ cursor: 'pointer' }}>
              <FontAwesomeIcon icon={faLinkedin} size="2x" />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EnrichedCrmHeaders;
