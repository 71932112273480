import { Box, Checkbox, CircularProgress, FormControlLabel } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Alert } from '@material-ui/lab';
import React, { useState } from 'react';

import { ClientInternalFragment } from '../../../../../graphql';
import { adminCreateProxyCrawlTask } from '../../../../../modules/admin';
import { AlertSeverity } from '../../../../../utils/constants';
import { getApiErrorMessage } from '../../../../../utils/errors';

interface Props {
  client: ClientInternalFragment;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const CreateProxyCrawlTaskModal: React.FC<Props> = ({ client, isOpen, setIsOpen }) => {
  const [alert, setAlert] = useState<AlertData | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const [forceRecrawl, setForceRecrawl] = useState(false);

  const handleClose = () => {
    setAlert(undefined);
    setIsLoading(false);
    setIsOpen(false);
  };

  const handleChangeForceRecrawl = () => {
    setForceRecrawl(!forceRecrawl);
  };

  const onConfirm = async () => {
    setAlert(undefined);
    setIsLoading(true);
    try {
      await adminCreateProxyCrawlTask(client.id, forceRecrawl);
      setAlert({
        severity: AlertSeverity.SUCCESS,
        message: 'Cloud task successfully created',
      });
    } catch (err) {
      const errorMessage = getApiErrorMessage(err);
      setAlert({
        severity: AlertSeverity.ERROR,
        message: errorMessage,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} aria-labelledby="delete-contacts">
      <DialogTitle>Create ProxyCrawl Task {client.name}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          This will create a task for sending ProxyCrawl requests. Please note that the tasks are run one client at a
          time. By default, our server will only ProxyCrawl contacts that have not been crawled in the last 3 days, but
          if you would like to force a recrawl of the client's contacts regardless of the last crawled date, use the
          checkbox below.
        </DialogContentText>
        <FormControlLabel
          control={
            <Checkbox checked={forceRecrawl} onChange={handleChangeForceRecrawl} name="forceRecrawl" color="primary" />
          }
          label="Force Recrawl"
        />
      </DialogContent>
      <DialogActions>
        {isLoading && (
          <Box marginTop={1}>
            <CircularProgress color="primary" />
          </Box>
        )}
        {!(alert?.severity === AlertSeverity.SUCCESS) ? (
          <>
            <Button onClick={handleClose} variant="outlined" color="primary" disabled={isLoading}>
              Cancel
            </Button>
            <Button onClick={onConfirm} variant="contained" color="primary" disabled={isLoading}>
              Confirm
            </Button>
          </>
        ) : (
          <Button onClick={handleClose} variant="outlined" color="primary" disabled={isLoading}>
            Ok
          </Button>
        )}
      </DialogActions>
      <Box>{alert && <Alert severity={alert.severity}>{alert.message}</Alert>}</Box>
    </Dialog>
  );
};

export default CreateProxyCrawlTaskModal;
