import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Tooltip,
  Typography,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { Alert } from '@material-ui/lab';
import { AxiosError } from 'axios';
import React, { useContext, useState } from 'react';

import { UserContext } from '../../../components/auth/UserRouter';
import { inviteNewUser } from '../../../modules/emails';
import { AlertSeverity, HTTP_RESPONSE, UserClientPermission, UserRole, WARMLY_EMAIL } from '../../../utils/constants';
import { ClientContext } from '../../Main';
import AdminUserInfoModal from './AdminUserInfoModal';

const InviteUser: React.FC = () => {
  const { selectedClient } = useContext(ClientContext);
  const { user } = useContext(UserContext);
  const [inviteeEmail, setInviteeEmail] = useState('');
  const [inviteeIsAdmin, setInviteeIsAdmin] = useState(false);
  const [showAdminUserInfoModal, setShowAdminUserInfoModal] = useState(false);
  const [sendingEmail, setSendingEmail] = useState(false);
  const [alert, setAlert] = useState<AlertData | undefined>();

  const handleInviteEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInviteeEmail(event.target.value);
  };

  const onClickInvite = async () => {
    setAlert(undefined);

    if (!inviteeEmail.trim()) {
      setAlert({
        severity: AlertSeverity.ERROR,
        message: 'Please enter a valid email address',
      });
    } else {
      try {
        setSendingEmail(true);
        const inviteeClientPermission = inviteeIsAdmin ? UserClientPermission.Admin : null;

        const inviteResponse = await inviteNewUser({
          inviteeEmail: inviteeEmail.trim().toLowerCase(),
          clientId: selectedClient.id,
          inviteeClientPermission,
        });

        if (inviteResponse.status === HTTP_RESPONSE.OK) {
          setAlert({ severity: AlertSeverity.SUCCESS, message: 'Invite successfully sent!' });
        } else {
          throw new Error();
        }
      } catch (err) {
        const response = (err as AxiosError).response;

        if (response?.status === HTTP_RESPONSE.CONFLICT) {
          setAlert({
            severity: AlertSeverity.ERROR,
            message: `Invite could not be sent. A user with that email already exists.`,
          });
        } else {
          setAlert({
            severity: AlertSeverity.ERROR,
            message: `An error occurred while sending the invite. Please verify the email address is correct. \n 
              If you continue to encounter this error, please contact us at ${WARMLY_EMAIL.CSM}`,
          });
        }
      } finally {
        setSendingEmail(false);
      }
    }
  };

  return (
    <Grid container item direction="column" alignItems="flex-start" spacing={2}>
      <Grid item>
        <Typography variant="h5">Invite another user from {selectedClient.name}</Typography>

        {user?.role === UserRole.INTERNAL && selectedClient.id !== user.clientId ? (
          <Box marginTop={2}>
            <Alert severity={AlertSeverity.WARNING}>
              As an internal user, you have selected {selectedClient.name} as the client, and invites will be sent on
              behalf of that client. The email will still show that you ({user.firstName}) are the person who invited
              them, however.
            </Alert>
          </Box>
        ) : null}
      </Grid>

      <Grid container item alignItems="flex-end" xs={8} spacing={3}>
        <Grid item xs>
          <FormControl fullWidth>
            <InputLabel htmlFor="add-user-email-input">Email</InputLabel>
            <Input
              id="add-user-email-input"
              value={inviteeEmail}
              onChange={handleInviteEmailChange}
              startAdornment={
                <InputAdornment position="start">
                  <FontAwesomeIcon size="lg" icon={faEnvelope} />
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
        <Grid item>
          <Tooltip
            title={
              user.clientPermission !== UserClientPermission.Admin
                ? 'Only an admin user can invite other users as admins'
                : 'If checked, the invited user will be an admin user'
            }
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={inviteeIsAdmin}
                  onChange={() => {
                    setInviteeIsAdmin(!inviteeIsAdmin);
                  }}
                  disabled={user.clientPermission !== UserClientPermission.Admin}
                  name="isAdmin"
                  color="primary"
                />
              }
              label={
                <Box>
                  Admin
                  <IconButton
                    color="primary"
                    aria-label="more-info-admin-users"
                    onClick={() => setShowAdminUserInfoModal(true)}
                  >
                    <InfoIcon />
                  </IconButton>
                </Box>
              }
            />
          </Tooltip>
        </Grid>
        <Grid container item alignItems="center" xs>
          <Button variant="contained" color="primary" onClick={onClickInvite} disabled={sendingEmail}>
            Send email invite{' '}
            {user?.role === UserRole.INTERNAL && selectedClient.id !== user.clientId
              ? `on behalf of ${selectedClient.name}`
              : ''}
          </Button>
          {sendingEmail && (
            <Box marginLeft={2}>
              <CircularProgress />
            </Box>
          )}
        </Grid>
      </Grid>

      <Grid item xs={12}>
        {alert && <Alert severity={alert.severity}>{alert.message}</Alert>}
      </Grid>

      <AdminUserInfoModal isOpen={showAdminUserInfoModal} setIsOpen={setShowAdminUserInfoModal} />
    </Grid>
  );
};

export default InviteUser;
