import { Backdrop, Box, CircularProgress, Grid, Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useContext, useEffect, useMemo, useState } from 'react';

import { UserContext } from '../../components/auth/UserRouter';
import CustomPagination from '../../components/CustomPagination';
import { Order_By, QueryWarmCompanyQuery, useQueryWarmCompanyQuery } from '../../graphql';
import { UserRole } from '../../utils/constants';
import { ClientContext } from '../Main';
import ClientSelect from './adminPanel/ClientSelect';
import CompanyContactItem from './WarmCompaniesItem';

export interface CompanyContacts {
  contactCount: number;
  contacts: any;
  companyName: string;
  companyImage: string;
  companyLinkedInURL: string;
}

const COMPANIES_PER_PAGE = 10;

const WarmCompanies: React.FC = () => {
  const { selectedClientId, setSelectedClientId } = useContext(ClientContext);
  const { user } = useContext(UserContext);
  const [currentPage, setCurrentPage] = useState(0);
  const [warmCompanies, setWarmCompanies] = useState<QueryWarmCompanyQuery['warmCompany']>([]);
  const [searchedCompany, setSearchedCompany] = useState<QueryWarmCompanyQuery['warmCompany'][number] | undefined>();

  const { data: warmCompaniesQuery, loading: loadingWarmCompanies } = useQueryWarmCompanyQuery({
    variables: {
      where: {
        clientId: {
          _eq: selectedClientId,
        },
      },
      order_by: [
        {
          clientContactCount: Order_By.Desc,
        },
      ],
    },
  });

  const totalWarmCompaniesCount = warmCompaniesQuery?.warmCompany?.length;
  const allCompanies = useMemo(
    () =>
      (warmCompaniesQuery?.warmCompany || []).map((warmCompany) => ({
        companyId: warmCompany.companyId,
        companyPrimaryName: warmCompany.companyPrimaryName,
        clientContactCount: warmCompany.clientContactCount,
      })),
    [warmCompaniesQuery?.warmCompany]
  );

  useEffect(() => {
    if (!loadingWarmCompanies && warmCompaniesQuery?.warmCompany) {
      setWarmCompanies(
        warmCompaniesQuery.warmCompany.slice(
          currentPage * COMPANIES_PER_PAGE,
          (currentPage + 1) * COMPANIES_PER_PAGE + 1
        )
      );
    }
  }, [warmCompaniesQuery?.warmCompany, loadingWarmCompanies, currentPage]);

  const warmCompanyItems = useMemo(
    () =>
      warmCompanies.map((warmCompany) => {
        return <CompanyContactItem key={warmCompany.companyId} warmCompany={warmCompany} />;
      }),
    [warmCompanies]
  );

  return (
    <Grid container spacing={3}>
      {user.role === UserRole.INTERNAL ? (
        <Grid item>
          <ClientSelect selectedClientId={selectedClientId} onSelectClientId={setSelectedClientId} />
        </Grid>
      ) : null}
      <Grid item xs={12}>
        <Typography variant="h1" color="primary">
          Warm Companies
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {totalWarmCompaniesCount ? (
          <Grid container>
            <Typography component="div" variant="h6">
              <Box fontWeight="fontWeightBold">How to Use Warm Companies:</Box>
              <Box>Get warm intros into specific accounts to help your team land more deals.</Box>
              <Box>Reach out to contacts below for a warm intro into a target account.</Box>
            </Typography>
          </Grid>
        ) : null}
        {!loadingWarmCompanies && !warmCompanies?.length ? (
          <Typography variant="h6">
            <Box fontWeight="bold">
              We have not yet detected Warm Companies in your contacts. Please note that Warm Companies are generally
              available after you receive your first job change report.
            </Box>
          </Typography>
        ) : null}
      </Grid>

      <Grid item>
        {allCompanies.length ? (
          <Autocomplete
            freeSolo
            options={allCompanies}
            onChange={(_event: React.ChangeEvent<{}>, value) => {
              if (typeof value !== 'string' && value) {
                setSearchedCompany(value);
              } else {
                setSearchedCompany(undefined);
              }
            }}
            getOptionSelected={(option, value) => option.companyId === value.companyId}
            getOptionLabel={(option) =>
              option.companyPrimaryName && option.clientContactCount
                ? `${option.companyPrimaryName} (${option.clientContactCount} contacts)`
                : ''
            }
            style={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Search for a company" variant="outlined" />}
            clearOnEscape={true}
          />
        ) : null}
      </Grid>

      <Grid item xs={12}>
        <Grid container alignItems="center">
          <Grid item style={{ width: '90%' }}>
            {warmCompanies.length ? (
              <>
                {searchedCompany ? (
                  <Box marginBottom={3}>
                    <Typography variant="subtitle1">Selected</Typography>
                    <CompanyContactItem warmCompany={searchedCompany} />
                  </Box>
                ) : null}
                <Typography variant="subtitle1">All warm companies</Typography>
                {warmCompanyItems}
              </>
            ) : null}
            <Grid item>
              <Box marginTop={2}>
                {totalWarmCompaniesCount ? (
                  <CustomPagination
                    totalCount={totalWarmCompaniesCount}
                    currentPage={currentPage}
                    itemsPerPage={COMPANIES_PER_PAGE}
                    onChangePage={setCurrentPage}
                  />
                ) : null}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Backdrop open={loadingWarmCompanies} style={{ zIndex: 10000 }}>
        <Grid container direction="column" alignItems="center" justify="center">
          <Grid item xs>
            <CircularProgress color="primary" />
          </Grid>
        </Grid>
      </Backdrop>
    </Grid>
  );
};

export default WarmCompanies;
