import { useReactiveVar } from '@apollo/client';
import { Backdrop, Box, CircularProgress, Grid, Snackbar, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useContext, useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom';

import { LoadingAndAlertContext } from '..';
import { AuthContext } from '../components/auth/AuthProvider';
import UserRouter from '../components/auth/UserRouter';
import { NavigationPath } from '../utils/constants';
import { apolloLoggedIn } from './ApolloClientProvider';

const LoggedInRouter: React.FC = () => {
  const { isLoading, snackbarAlertData, setSnackbarAlertData, loadingMessage } = useContext(LoadingAndAlertContext);
  const { authUser, loadingAuth } = useContext(AuthContext);
  const history = useHistory();
  const loggedIn = useReactiveVar(apolloLoggedIn)

  useEffect(() => {

    if (!authUser && !loadingAuth) {
      history.push(NavigationPath.LOGIN);
    }
  }, [history, authUser, loadingAuth]);

  if (!authUser && !loadingAuth) {
    return <Redirect to={NavigationPath.LOGIN} />;
  } else if (loadingAuth) {
    return <Backdrop open={loadingAuth} />;
  }

  return (
    <>
      <Backdrop open={isLoading} style={{ zIndex: 10000 }}>
        <Grid container direction="column" alignItems="center" justify="center">
          {loadingMessage && (
            <Grid item xs>
              <Typography variant="h5" color="primary">
                <Box marginBottom={2} fontWeight="bold">
                  {loadingMessage}
                </Box>
              </Typography>
            </Grid>
          )}
          <Grid item xs>
            <CircularProgress color="primary" />
          </Grid>
        </Grid>
      </Backdrop>

      <Snackbar
        open={Boolean(snackbarAlertData?.message)}
        autoHideDuration={8000}
        onClose={() => setSnackbarAlertData(undefined)}
      >
        <Alert onClose={() => setSnackbarAlertData(undefined)} severity={snackbarAlertData?.severity}>
          {snackbarAlertData?.message}
        </Alert>
      </Snackbar>

      {loggedIn && <UserRouter />}
    </>
  );
};

export default LoggedInRouter;
