export enum IntegrationType {
  HubSpot = 'HubSpot',
  Salesforce = 'Salesforce',
}
export const TestClientSuffix = '_warmlytest';

export enum ClientSubscriptionStatus {
  Deactivated = 'Deactivated',
  DPP = 'DPP',
}

export enum ClientOwnerMappingStatus {
  AssigningOwners = 'AssigningOwners',
  Completed = 'Completed',
  Error = 'Error',
}