import { Box, CircularProgress, List, ListItem, TextField, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { Alert } from '@material-ui/lab';
import React, { useContext, useState } from 'react';

import {
  ClientContactCrmDataFragment,
  ClientContactFilter_Constraint,
  ClientContactFilter_Update_Column,
  CsvUploadFragment,
} from '../../../../graphql';
import { GQLHooks } from '../../../../graphql/hasura/react';
import { logError } from '../../../../modules/analytics';
import { createQaTasks } from '../../../../modules/qaTasks';
import { AlertSeverity } from '../../../../utils/constants';
import { consoleLogDev } from '../../../../utils/errors';
import { ClientContext } from '../../../Main';
import { CrmDataFieldComparison } from '../QaManagement';

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  filterCrmTitleSearchTerm: string;
  filterCrmData?: ClientContactCrmDataFragment;
  filterCrmDataFieldComparisonOperator: string;
  filterCrmDataFieldValue?: string;
  filterCsvUpload?: CsvUploadFragment;
  clientContactCount?: number;
  excludeAlreadyAssigned: boolean;
}
const ConfirmQaModal: React.FC<Props> = ({
  isOpen,
  setIsOpen,
  filterCrmTitleSearchTerm,
  filterCrmData,
  filterCrmDataFieldComparisonOperator,
  filterCrmDataFieldValue,
  filterCsvUpload,
  clientContactCount,
  excludeAlreadyAssigned,
}) => {
  const [alert, setAlert] = useState<AlertData | undefined>();
  const { selectedClient } = useContext(ClientContext);
  const [isLoading, setIsLoading] = useState(false);
  const [filterLimit, setFilterLimit] = useState<number>(0);
  const [qaTaskPriority, setQaTaskPriority] = useState<number>(1);
  const [insertClientContactFilterWithOnConflict] = GQLHooks.Fragments.ClientContactFilter.useInsertWithOnConflict();

  const handleClose = () => {
    setAlert(undefined);
    setIsLoading(false);
    setIsOpen(false);
  };

  const onChangeFilterLimit = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterLimit(+event.target.value);
  };

  const onChangeQaTaskPriority = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQaTaskPriority(+event.target.value);
  };

  const onConfirm = async () => {
    try {
      setAlert(undefined);
      setIsLoading(true);
      if (!filterCrmData && !filterCrmTitleSearchTerm && !filterCsvUpload) {
        await createQaTasks({
          clientId: selectedClient.id,
          priority: qaTaskPriority,
          limit: filterLimit,
          excludeAlreadyAssigned,
        });

        consoleLogDev('Created QA Tasks - unfiltered');
      } else if (!filterCrmData && !filterCrmTitleSearchTerm && filterCsvUpload) {
        await createQaTasks({
          clientId: selectedClient.id,
          priority: qaTaskPriority,
          limit: filterLimit,
          excludeAlreadyAssigned,
          csvUploadId: filterCsvUpload.id,
        });

        consoleLogDev('Created QA Tasks - filtered using just CSV Upload');
      } else if (filterCrmData) {
        if (!filterCrmDataFieldValue?.trim()) {
          throw new Error('Missing input');
        }

        const insertClientContactFilterQuery = await insertClientContactFilterWithOnConflict({
          clientContactFilter: {
            clientId: selectedClient.id,
            fieldName: filterCrmData.fieldName,
            fieldOperator: filterCrmDataFieldComparisonOperator,
            fieldValue: filterCrmDataFieldValue,
            lastFilteredClientContactCount: clientContactCount,
            fieldTable: 'clientContactCrmData',
            fieldCrmType: filterCrmData.crmType,
          },
          onConflict: {
            constraint: ClientContactFilter_Constraint.ClientContactFilterClientIdFieldTableFieldNameFieldOperKey,
            update_columns: [ClientContactFilter_Update_Column.LastFilteredClientContactCount],
          },
        });

        const clientContactFilter = insertClientContactFilterQuery.clientContactFilter;

        if (!clientContactFilter) {
          throw new Error('Error inserting filter');
        }

        await createQaTasks({
          clientId: selectedClient.id,
          priority: qaTaskPriority,
          limit: filterLimit,
          csvUploadId: filterCsvUpload?.id,
          clientContactFilterId: clientContactFilter.id,
          excludeAlreadyAssigned,
        });

        consoleLogDev('Created QA Tasks - filtered using custom CRM data');
      } else if (filterCrmTitleSearchTerm?.trim()) {
        const insertClientContactFilterQuery = await insertClientContactFilterWithOnConflict({
          clientContactFilter: {
            clientId: selectedClient.id,
            fieldName: 'crmTitle',
            fieldOperator: CrmDataFieldComparison.Contains, // for title search, we always do contains
            fieldValue: filterCrmTitleSearchTerm?.trim(),
            lastFilteredClientContactCount: clientContactCount,
            fieldTable: 'clientContact',
          },
          onConflict: {
            constraint: ClientContactFilter_Constraint.ClientContactFilterClientIdFieldTableFieldNameFieldOperKey,
            update_columns: [ClientContactFilter_Update_Column.LastFilteredClientContactCount],
          },
        });

        const clientContactFilter = insertClientContactFilterQuery.clientContactFilter;

        if (!clientContactFilter) {
          throw new Error('Error inserting filter');
        }

        await createQaTasks({
          clientId: selectedClient.id,
          priority: qaTaskPriority,
          limit: filterLimit,
          csvUploadId: filterCsvUpload?.id,
          clientContactFilterId: clientContactFilter.id,
          excludeAlreadyAssigned,
        });

        consoleLogDev('Created QA Tasks - filtered using CRM title');
      } else {
        throw new Error('Invalid filter');
      }

      setAlert({
        severity: AlertSeverity.SUCCESS,
        message: 'QA assignment sent to queue, please check Cloud Task (client-contact-action) for status!',
      });
    } catch (err) {
      logError(err);
      setAlert({
        severity: AlertSeverity.ERROR,
        message: 'Error - Could not assign QA',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} aria-labelledby="assign-filtered-contacts">
      <DialogContent>
        <Typography variant="h6">
          Assign {clientContactCount?.toLocaleString()} contacts from {selectedClient.name} based on the following
          filter?
        </Typography>
        <List>
          {!filterCsvUpload && !filterCrmData && !filterCrmTitleSearchTerm ? (
            <ListItem>All contacts - no filter</ListItem>
          ) : null}
          {filterCsvUpload ? <ListItem>From CSV: {filterCsvUpload.fileName}</ListItem> : null}
          {filterCrmData ? (
            <ListItem>
              {filterCrmData.crmType || 'CSV'} Field: {filterCrmData.fieldName} {filterCrmDataFieldComparisonOperator}{' '}
              {filterCrmDataFieldValue || '❌  missing input'}
            </ListItem>
          ) : null}
          {filterCrmTitleSearchTerm ? <ListItem>Title includes {filterCrmTitleSearchTerm}</ListItem> : null}
        </List>
        <Typography variant="caption">
          You can also limit the number of contacts assigned based on this filter (0 = unlimited)
        </Typography>
        <TextField
          value={filterLimit}
          onChange={onChangeFilterLimit}
          size="small"
          type="number"
          margin="dense"
          label={'QA assign limit'}
          style={{ marginRight: 16 }}
        />
        <TextField
          value={qaTaskPriority}
          onChange={onChangeQaTaskPriority}
          size="small"
          type="number"
          margin="dense"
          label={'Priority (1 = highest)'}
        />
      </DialogContent>
      <DialogActions>
        {isLoading && (
          <Box marginTop={1}>
            <CircularProgress color="primary" />
          </Box>
        )}
        {!(alert?.severity === AlertSeverity.SUCCESS) ? (
          <>
            <Button onClick={handleClose} variant="outlined" color="primary" disabled={isLoading}>
              Cancel
            </Button>
            <Button onClick={onConfirm} variant="contained" color="primary" disabled={isLoading}>
              Assign {filterLimit ? filterLimit?.toLocaleString() : clientContactCount?.toLocaleString()} Contacts
            </Button>
          </>
        ) : (
          <Button onClick={handleClose} variant="outlined" color="primary" disabled={isLoading}>
            Ok
          </Button>
        )}
      </DialogActions>
      <Box>{alert && <Alert severity={alert.severity}>{alert.message}</Alert>}</Box>
    </Dialog>
  );
};

export default ConfirmQaModal;
