import { Box, CircularProgress, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, { useContext, useState } from 'react';

import { LoadingAndAlertContext } from '../../..';
import { ClientTerritoryFragment, UserFragment } from '../../../graphql';
import { GQLHooks } from '../../../graphql/hasura/react';
import { AlertSeverity } from '../../../utils/constants';
import { consoleLogDev, getApiErrorMessage } from '../../../utils/errors';

interface Props {
  user: UserFragment;
  clientTerritory: ClientTerritoryFragment;
  onRemoveSuccess: () => void;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const TerritoryOwnerRemoveModal: React.FC<Props> = ({ user, clientTerritory, isOpen, setIsOpen, onRemoveSuccess }) => {
  const { setSnackbarAlertData } = useContext(LoadingAndAlertContext);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteClientTerritory] = GQLHooks.Models.ClientTerritory.useRemoveById();

  const handleClose = () => {
    setIsLoading(false);
    setIsOpen(false);
  };

  const onConfirm = async () => {
    setSnackbarAlertData(undefined);
    setIsLoading(true);
    try {
      await deleteClientTerritory({
        clientTerritoryId: clientTerritory.id,
      });

      setSnackbarAlertData({
        severity: AlertSeverity.SUCCESS,
        message: 'Territory successfully removed',
      });

      await onRemoveSuccess();
      handleClose();
    } catch (err) {
      consoleLogDev(err);
      const errorMessage = getApiErrorMessage(err);
      setSnackbarAlertData({
        severity: AlertSeverity.ERROR,
        message: errorMessage,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} aria-labelledby="delete-contacts">
      <DialogTitle>Remove {clientTerritory.territory.formattedAddress}</DialogTitle>
      <DialogContent>
        <Box>
          <Typography>
            By clicking CONFIRM, {user.firstName} {user.lastName} will no longer be an owner for{' '}
            {clientTerritory.territory.formattedAddress}.
          </Typography>
        </Box>
        <Box marginTop={1}>
          <Typography variant="subtitle1">
            * Please note that this will only impact job changes detected in the future, and any contacts that you import going forward.
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        {isLoading && (
          <Box marginTop={1}>
            <CircularProgress color="primary" />
          </Box>
        )}
        <Button onClick={handleClose} variant="outlined" color="primary" disabled={isLoading}>
          Cancel
        </Button>
        <Button onClick={onConfirm} variant="contained" color="primary" disabled={isLoading}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TerritoryOwnerRemoveModal;
