import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useContext, useState } from 'react';

import {
  ClientContact_Bool_Exp,
  ClientContact_Select_Column,
  ClientContactCrmData_Select_Column,
  ClientContactCrmDataFragment,
  CsvUploadFragment,
  Order_By,
  useQueryClientContactCountQuery,
} from '../../../graphql';
import { GQLHooks } from '../../../graphql/hasura/react';
import { ClientContext } from '../../Main';
import ClientSelect from './ClientSelect';
// import QADeleteTasks from './qaDataReview/QADeleteQATasks';
import QaClientContactsTable from './qaManagement/ClientContactsQaTable';
import ClientCsvUploadSelect from './qaManagement/ClientCsvUploadSelect';
import ConfirmQaModal from './qaManagement/ConfirmQaModal';

export enum CrmDataFieldComparison {
  Contains = 'Contains',
  Equals = 'Equals',
}

export const QA_CONTACTS_PER_PAGE = 20;

const QaManagement: React.FC = () => {
  const { selectedClientId, setSelectedClientId } = useContext(ClientContext);

  const [showConfirmQaModal, setShowConfirmQaModal] = useState(false);

  const [clientContactFilter, setClientContactFilter] = useState<ClientContact_Bool_Exp>({});
  const [crmTitleSearchTerm, setCrmTitleSearchTerm] = useState('');
  const [crmData, setCrmData] = useState<ClientContactCrmDataFragment | undefined>();
  const [crmDataFieldValue, setCrmDataFieldValue] = useState('');
  const [crmDataFieldComparisonOperator, setCrmDataFieldComparisonOperator] = useState(CrmDataFieldComparison.Contains);
  const [selectedCsvUpload, setSelectedCsvUpload] = useState<CsvUploadFragment | undefined>();
  const [excludeAlreadyAssigned, setExcludeAlreadyAssigned] = useState(false);

  const [currentPage, setCurrentPage] = useState(0);

  const onSelectClientId = (clientId: string) => {
    setCurrentPage(0);
    setClientContactFilter({});
    setCrmTitleSearchTerm('');
    setCrmData(undefined);
    setCrmDataFieldValue('');
    setSelectedCsvUpload(undefined);
    setSelectedClientId(clientId);
  };

  const onClickResetFilter = () => {
    setCurrentPage(0);
    setClientContactFilter({});
    setCrmTitleSearchTerm('');
    setCrmData(undefined);
    setCrmDataFieldValue('');
    setSelectedCsvUpload(undefined);
  };

  const onClickFilterByTitle = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setCrmData(undefined);
    setCrmDataFieldValue('');

    if (!crmTitleSearchTerm.trim()) {
      alert('Please enter a value for title');
    } else {
      setCurrentPage(0);
      setClientContactFilter({
        crmTitle: {
          _ilike: `%${crmTitleSearchTerm.trim()}%`,
        },
      });
    }
  };

  const onClickFilterByCrmData = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setCrmTitleSearchTerm('');

    if (!crmDataFieldValue.trim()) {
      alert('Please enter a value for CRM data');
    } else if (!crmData) {
      alert('Please select a CRM data field');
    } else {
      // Right now we only support searching via _ilike, but maybe in the future we'll introduce fuzzy search
      const fieldValue =
        crmDataFieldComparisonOperator === CrmDataFieldComparison.Contains
          ? `%${crmDataFieldValue}%`
          : crmDataFieldValue;

      setClientContactFilter({
        crmData: {
          crmType: crmData.crmType
            ? {
                _eq: crmData.crmType,
              }
            : {
                _is_null: true,
              },
          fieldName: {
            _eq: crmData.fieldName,
          },
          fieldValue: {
            _ilike: fieldValue,
          },
        },
      });

      setCurrentPage(0);
    }
  };

  const {
    objects: clientContactsFromQuery,
    loading: loadingClientContacts,
  } = GQLHooks.Fragments.ClientContactQa.useQueryObjects({
    variables: {
      where: {
        clientId: {
          _eq: selectedClientId,
        },
        qaAssignedAt: excludeAlreadyAssigned
          ? {
              _is_null: true,
            }
          : undefined,
        csvUploadId: selectedCsvUpload ? { _eq: selectedCsvUpload.id } : undefined,
        ...clientContactFilter,
      },
      limit: QA_CONTACTS_PER_PAGE,
      offset: currentPage * QA_CONTACTS_PER_PAGE,
      order_by: [{ [ClientContact_Select_Column.SerialId]: Order_By.Desc }],
    },
  });

  const { data: clientContactCountQuery } = useQueryClientContactCountQuery({
    variables: {
      where: {
        clientId: {
          _eq: selectedClientId,
        },
        qaAssignedAt: excludeAlreadyAssigned
          ? {
              _is_null: true,
            }
          : undefined,
        csvUploadId: selectedCsvUpload ? { _eq: selectedCsvUpload.id } : undefined,
        ...clientContactFilter,
      },
    },
  });

  const { objects: distinctCrmData } = GQLHooks.Fragments.ClientContactCrmData.useQueryObjects({
    variables: {
      where: {
        clientId: {
          _eq: selectedClientId,
        },
      },
      distinct_on: [ClientContactCrmData_Select_Column.CrmType, ClientContactCrmData_Select_Column.FieldName],
    },
  });

  const clientContactCount = clientContactCountQuery?.clientContact_aggregate.aggregate?.totalCount;

  // TODO allow selecting previously used filters
  // const {
  //   objects: clientContactFilters,
  //   loading: loadingCLientContactFilters,
  // } = GQLHooks.Fragments.ClientContactCrmData.useQueryObjects({
  //   variables: {
  //     where: {
  //       clientId: {
  //         _eq: selectedClientId,
  //       },
  //     },
  //   },
  // });

  // TODO deleting cloud tasks

  const onChangeCrmData = (event: React.ChangeEvent<{ value: unknown }>) => {
    event.preventDefault();
    setCrmTitleSearchTerm('');
    const crmDataId = event.target.value as string;
    const selectedCrmData = distinctCrmData?.find((crmDataRow) => crmDataRow.id === crmDataId);
    setCrmData(selectedCrmData);
  };

  const onChangeComparisonOperator = (event: React.ChangeEvent<{ value: unknown }>) => {
    const comparisonOperator = event.target.value as CrmDataFieldComparison;
    setCrmDataFieldComparisonOperator(comparisonOperator);
  };

  return (
    <Grid container direction="row" spacing={3} alignItems="center">
      <Grid item xs={4}>
        <ClientSelect selectedClientId={selectedClientId} onSelectClientId={onSelectClientId} />
      </Grid>
      <Grid item>Optional:</Grid>
      <Grid item xs={4}>
        <ClientCsvUploadSelect onSelectCsvUpload={setSelectedCsvUpload} selectedCsvUploadId={selectedCsvUpload?.id} />
      </Grid>
      <Grid item xs={2}>
        <Button variant="contained" color="primary" onClick={onClickResetFilter}>
          Reset Filters
        </Button>
      </Grid>

      <Grid container item>
        <form style={{ width: '100%' }}>
          <Grid container item alignItems="center" spacing={3}>
            <Grid item xs={4}>
              <TextField
                label="Filter by CRM Title"
                value={crmTitleSearchTerm}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setCrmTitleSearchTerm(event.target.value);
                }}
                fullWidth
              />
            </Grid>

            <Grid item>
              <Button
                variant="contained"
                color="primary"
                disabled={loadingClientContacts}
                type="submit"
                onClick={onClickFilterByTitle}
              >
                Get Client Contacts
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>

      <Grid container item spacing={2}>
        <Grid item>
          <Typography variant="h6">Or select custom CRM data</Typography>
        </Grid>
      </Grid>

      <Grid container item>
        <Grid container item alignItems="center" spacing={3}>
          <Grid item xs={4} md={2}>
            <Select fullWidth value={crmData?.id || ''} displayEmpty onChange={onChangeCrmData} label="CRM Data">
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {distinctCrmData.map((crmData) => {
                return (
                  <MenuItem key={crmData.id} value={crmData.id}>
                    {`${crmData.fieldLabel || crmData.fieldName} (${crmData.crmType || 'CSV'})`}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
          <Grid item xs={4} md={2}>
            <Select
              fullWidth
              value={crmDataFieldComparisonOperator}
              onChange={onChangeComparisonOperator}
              label="comparison-operator"
            >
              {Object.values(CrmDataFieldComparison).map((comparisonOperator) => {
                return (
                  <MenuItem key={comparisonOperator} value={comparisonOperator}>
                    {comparisonOperator}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
          <Grid item xs={4} md={3}>
            <TextField
              placeholder="Filter by Custom CRM Data"
              value={crmDataFieldValue}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setCrmDataFieldValue(event.target.value);
              }}
              fullWidth
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              disabled={loadingClientContacts}
              type="submit"
              onClick={onClickFilterByCrmData}
            >
              Get Client Contacts
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid container item spacing={2}>
        <Grid item>
          {/* <QADeleteTasks clientContacts={clientContacts} selectedClientContacts={selectedClientContacts} /> */}
        </Grid>
      </Grid>

      <Grid container item>
        <Box marginBottom={2}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <Typography>{clientContactCount?.toLocaleString() || 0} contacts</Typography>
            </Grid>
            <Grid item>
              <Button variant="contained" color="secondary" onClick={() => setShowConfirmQaModal(true)}>
                Assign Contacts
              </Button>
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={excludeAlreadyAssigned}
                    onChange={() => setExcludeAlreadyAssigned(!excludeAlreadyAssigned)}
                    name="excludeAlreadyAssigned"
                    color="primary"
                  />
                }
                label="Exclude Already Assigned Contacts"
              />
            </Grid>
            <Grid item>
              <Box fontWeight="bold">
                After assignments have completed, you need to refresh this page to see the updated QA Assigned dates
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Grid item xs={12}>
          <QaClientContactsTable
            onSelectPage={setCurrentPage}
            currentPage={currentPage}
            clientContactCount={clientContactCount || 0}
            clientContacts={clientContactsFromQuery || []}
            crmDataFieldName={crmData?.fieldName}
            crmDataCrmType={crmData?.crmType}
          />
        </Grid>
      </Grid>
      <ConfirmQaModal
        isOpen={showConfirmQaModal}
        setIsOpen={setShowConfirmQaModal}
        filterCrmTitleSearchTerm={crmTitleSearchTerm}
        filterCrmData={crmData}
        filterCrmDataFieldComparisonOperator={crmDataFieldComparisonOperator}
        filterCrmDataFieldValue={crmDataFieldValue}
        filterCsvUpload={selectedCsvUpload}
        clientContactCount={clientContactCount || 0}
        excludeAlreadyAssigned={excludeAlreadyAssigned}
      />
      <Backdrop open={loadingClientContacts} style={{ zIndex: 10000 }}>
        <Grid container direction="column" alignItems="center" justify="center">
          <Grid item xs>
            <CircularProgress color="primary" />
          </Grid>
        </Grid>
      </Backdrop>
    </Grid>
  );
};

export default QaManagement;
