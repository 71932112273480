import { Box, CircularProgress, TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Alert } from '@material-ui/lab';
import React, { useState } from 'react';

import { ClientExternalFragment } from '../../../../../graphql';
import { generateJobChanges } from '../../../../../modules/admin';
import { AlertSeverity } from '../../../../../utils/constants';
import { getApiErrorMessage } from '../../../../../utils/errors';

interface Props {
  client: ClientExternalFragment;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const GenerateJobChangesModal: React.FC<Props> = ({ client, isOpen, setIsOpen }) => {
  const [alert, setAlert] = useState<AlertData | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const [field, setField] = useState(0);

  const handleClose = () => {
    setAlert(undefined);
    setIsLoading(false);
    setIsOpen(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setField(+event.target.value);
  };

  const onConfirm = async () => {
    if (!field) {
      window.alert('Field is required');
    }

    setAlert(undefined);
    setIsLoading(true);
    try {
      await generateJobChanges(client.id, field);
      setAlert({
        severity: AlertSeverity.SUCCESS,
        message: 'Jobs successfully generated',
      });
    } catch (err) {
      const errorMessage = getApiErrorMessage(err);
      setAlert({
        severity: AlertSeverity.ERROR,
        message: errorMessage,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} aria-labelledby="delete-contacts">
      <DialogTitle>Generate Job Changes For {client.name}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          This will create random generated job changes for up to the number of client contacts you specify. Please note that every job change will result in the creation of a new company as well.
        </DialogContentText>
        <TextField label="number" value={field} onChange={handleChange} variant="outlined" />
      </DialogContent>
      <DialogActions>
        {isLoading && (
          <Box marginTop={1}>
            <CircularProgress color="primary" />
          </Box>
        )}
        {!(alert?.severity === AlertSeverity.SUCCESS) ? (
          <>
            <Button onClick={handleClose} variant="outlined" color="primary" disabled={isLoading}>
              Cancel
            </Button>
            <Button onClick={onConfirm} variant="contained" color="primary" disabled={isLoading}>
              Confirm
            </Button>
          </>
        ) : (
          <Button onClick={handleClose} variant="outlined" color="primary" disabled={isLoading}>
            Ok
          </Button>
        )}
      </DialogActions>
      <Box>{alert && <Alert severity={alert.severity}>{alert.message}</Alert>}</Box>
    </Dialog>
  );
};

export default GenerateJobChangesModal;
