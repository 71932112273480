import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import React, { useContext } from 'react';

import { CsvUploadFragment } from '../../../../graphql';
import { GQLHooks } from '../../../../graphql/hasura/react';
import { getDateStringFromTimestampString } from '../../../../utils/functions';
import { ClientContext } from '../../../Main';

interface ClientSelectProps {
  onSelectCsvUpload: (csvUpload: CsvUploadFragment) => void;
  selectedCsvUploadId?: string;
}
const ClientCsvUploadSelect: React.FC<ClientSelectProps> = ({ onSelectCsvUpload, selectedCsvUploadId = '' }) => {
  const { selectedClientId } = useContext(ClientContext);
  const { objects: csvUploads } = GQLHooks.Fragments.CsvUpload.useQueryObjects({
    variables: {
      where: {
        clientId: { _eq: selectedClientId },
      },
    },
  });

  // Filter out CSV uploads that did not finish syncing, and filter out CSV uploaded prior to the Postgres migration
  // because previously we did not track which client contact came from which CSV
  const sortedCsvUploads = (csvUploads?.slice() || [])
    .filter(
      (upload) => upload.syncCompletedAt && upload.uploadedAt && new Date(upload.uploadedAt) > new Date('2020-08-03')
    )
    .sort((uploadA, uploadB) => {
      return +new Date(uploadB.uploadedAt!) - +new Date(uploadA.uploadedAt!);
    });

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedId = event.target.value as string;
    const selectedCsvUpload = sortedCsvUploads.find((upload) => upload.id === selectedId);
    onSelectCsvUpload(selectedCsvUpload!);
  };

  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel id="select-csv-upload-dropdown-label">CSV Upload</InputLabel>
      <Select
        labelId="select-csv-upload-dropdown-label"
        value={selectedCsvUploadId}
        onChange={handleChange}
        label="CSV Upload"
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {sortedCsvUploads.map((csvUpload) => {
          return (
            <MenuItem key={csvUpload.id} value={csvUpload.id}>
              {csvUpload.fileName || 'no filename'} (
              {getDateStringFromTimestampString(csvUpload.syncCompletedAt!)})
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default ClientCsvUploadSelect;
