import { ClientContactExternalFragment } from '../graphql';
import { ClientContactAttribute, ClientContactAttributeMetaData, QAJobChangeStatus } from './constants';

export const checkEmailFormat = (email: string): boolean => {
  let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const extractLinkedinIdFromLinkedinUrl = (url: string | undefined | null): string | null => {
  if (!url?.trim()) {
    return null;
  }

  const match = url.match(/https?:\/\/.*\.?linkedin.com\/in\/([^?|/]*)/);
  if (!match) {
    return null;
  }

  const [, linkedInId] = match;

  return linkedInId;
};

export const addhttps = (url: string): string => {
  if (!/^(?:f|ht)tps?:\/\//.test(url)) {
    url = 'https://' + url;
  }
  return url;
};

type ClientContactWithName = {
  firstName?: string | null;
  lastName?: string | null;
  fullName?: string | null;
};

export const getClientContactName = (clientContact: ClientContactWithName, initials?: boolean): string => {
  if (!clientContact) {
    return '';
  }

  const { firstName = '', lastName = '', fullName = '' } = clientContact;
  const firstNameInitial = firstName?.[0].toUpperCase() || '';
  const lastNameInitial = lastName?.[0].toUpperCase() || '';
  const fullNameInitials = fullName?.slice(0, 2) || '';

  if (fullName) {
    if (initials) {
      return fullNameInitials;
    } else {
      return fullName;
    }
  } else if (firstName || lastName) {
    if (initials) {
      return `${firstNameInitial} ${lastNameInitial}`;
    } else {
      return `${firstName} ${lastName}`;
    }
  } else {
    return '';
  }
};

export const getAttribute = (csvHeader: string): ClientContactAttribute => {
  csvHeader = csvHeader.toLowerCase();
  for (let attribute in ClientContactAttributeMetaData) {
    if (ClientContactAttributeMetaData[attribute as ClientContactAttribute].potentialCsvHeaders.includes(csvHeader)) {
      return attribute as ClientContactAttribute;
    }
  }

  return ClientContactAttribute.OTHER;
};

export type MappedAttributes = {
  [headerName: string]: ClientContactAttribute;
};

export const getDateTimeStringFromTimestampString = (timestampString?: string | null): string | undefined => {
  if (!timestampString?.trim()) {
    return undefined;
  } else {
    return new Date(timestampString).toLocaleString();
  }
};
export const getDateStringFromTimestampString = (timestampString?: string | null): string | undefined => {
  const dateTimeString = getDateTimeStringFromTimestampString(timestampString);
  return dateTimeString ? dateTimeString.split(',')[0] : undefined;
};

export const wait = (milliseconds: number): Promise<void> =>
  new Promise((resolve) => setTimeout(resolve, milliseconds));

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getPreviousJob = (
  clientContact: ClientContactExternalFragment
): { previousCompanyName: string | undefined | null; previousCompanyTitle: string | undefined | null } => {
  const currentJob = clientContact.contactData?.currentJob;
  const jobChangeStatus = clientContact.currentJobChange?.status;

  const nonCurrentJobsSortedDesc = (clientContact.contactData?.jobs.slice() || [])
    .filter((job) => job.id !== currentJob?.id)
    .sort((jobA, jobB) => {
      if (jobA.startDate && jobB.startDate) {
        return +new Date(jobB.startDate) - +new Date(jobA.startDate);
      } else {
        return +new Date(jobB.createdAt) - +new Date(jobA.createdAt);
      }
    });

  const previousJob = nonCurrentJobsSortedDesc.length ? nonCurrentJobsSortedDesc[0] : undefined;

  // During initial read-in QA will mark as job/title change even though it happend a few jobs back. In this case
  // we fall back to showing crmTitle and companyName as the previous job.
  let previousCompanyName = previousJob?.companyName ? previousJob.companyName : clientContact.crmCompanyName;
  let previousCompanyTitle = previousJob?.title ? previousJob.title : clientContact.crmTitle;

  if (previousCompanyName === currentJob?.companyName && jobChangeStatus === QAJobChangeStatus.JOB_CHANGED) {
    previousCompanyName = clientContact.crmCompanyName;
    previousCompanyTitle = clientContact.crmTitle;
  }

  if (previousCompanyTitle === currentJob?.title && jobChangeStatus === QAJobChangeStatus.JOB_CHANGED_SAME_COMPANY) {
    previousCompanyName = clientContact.crmCompanyName;
    previousCompanyTitle = clientContact.crmTitle;
  }

  return { previousCompanyName, previousCompanyTitle };
};
