import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import AuthProvider from './components/auth/AuthProvider';
import { initializeAmplitude, initializeLogging } from './modules/analytics';
import ApolloClientProvider from './pages/ApolloClientProvider';
import GenericError from './pages/GenericError';
import LoggedInRouter from './pages/LoggedInRouter';
import Login from './pages/Login';
import LogOut from './pages/LogOut';
import Onboard from './pages/Onboard';
import ResetPassword from './pages/ResetPassword';
import SignUp from './pages/SignUp';
import UnderMaintenance from './pages/UnderMaintenance';
import * as serviceWorker from './serviceWorker';
import { CURRENT_ENVIRONMENT, Environment, NavigationPath, WarmlyColor } from './utils/constants';

const theme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: 13,
      },
    },
  },
  palette: {
    primary: { main: WarmlyColor.WARMLY_PURPLE, contrastText: WarmlyColor.WHITE },
    secondary: { main: WarmlyColor.SALMON, contrastText: WarmlyColor.WHITE },
    background: {
      default: WarmlyColor.LIGHT_BLUE,
    },
    tonalOffset: {
      light: 0.85,
      dark: 0.4,
    },
  },
  typography: {
    fontFamily: ['Manrope', '"Helvetica Neue"', 'sans-serif'].join(','),
    fontWeightRegular: 300,
    fontWeightMedium: 400,
    fontWeightBold: 500,
    fontSize: 13,
    h1: {
      fontFamily: 'Manrope',
      fontSize: '2.25rem',
    },
    h2: {
      fontFamily: 'Manrope',
      fontSize: '2rem',
    },
    h3: {
      fontFamily: 'Manrope',
      fontSize: '1.75rem',
    },
    h4: {
      fontFamily: 'Manrope',
      fontSize: '1.5rem',
    },
    h5: {
      fontFamily: 'Manrope',
      fontSize: '1.25rem',
    },
    h6: {
      fontFamily: 'Manrope',
      fontSize: '1rem',
    },
    subtitle1: {
      fontSize: '0.8rem',
    },
    subtitle2: {
      fontSize: '0.8rem',
      fontWeight: 500,
    },
    body1: {
      letterSpacing: '0.02em',
    },
    body2: {
      fontSize: '0.9rem',
      letterSpacing: '0.03em',
    },
  },
});

export const LoadingAndAlertContext = React.createContext<LoadingAndAlertContextData>({} as LoadingAndAlertContextData);

if (CURRENT_ENVIRONMENT === Environment.Development) {
  console.log('Dev environment detected - tracking React rendering');
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React);
}

const Root: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [loadingMessage, setLoadingMessage] = useState<string | undefined>('');
  const [snackbarAlertData, setSnackbarAlertData] = useState<AlertData | undefined>();

  return (
    <AuthProvider>
      <BrowserRouter>
        <LoadingAndAlertContext.Provider
          value={{
            isLoading,
            setIsLoading,
            loadingMessage,
            setLoadingMessage,
            snackbarAlertData,
            setSnackbarAlertData,
          }}
        >
          <ApolloClientProvider>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <Switch>
                <Route exact={true} path={NavigationPath.SIGN_UP} component={SignUp} />
                <Route exact={true} path={NavigationPath.LOGIN} component={Login} />
                <Route exact={true} path={NavigationPath.ONBOARD} component={Onboard} />
                <Route exact={true} path={NavigationPath.RESET_PASSWORD} component={ResetPassword} />
                <Route exact={true} path={NavigationPath.GENERIC_ERROR} component={GenericError} />
                <Route exact={true} path={NavigationPath.LOGOUT} component={LogOut} />
                <Route exact={true} path={NavigationPath.UNDER_MAINTENANCE} component={UnderMaintenance} />
                <Route path={NavigationPath.MAIN} component={LoggedInRouter} />
              </Switch>
            </ThemeProvider>
          </ApolloClientProvider>
        </LoadingAndAlertContext.Provider>
      </BrowserRouter>
    </AuthProvider>
  );
};

ReactDOM.render(<Root />, document.getElementById('root'));

initializeLogging();

// initiate amplitude metrics tracking
initializeAmplitude();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
