import { Grid } from '@material-ui/core';
import React from 'react';

import { Order_By } from '../../../graphql';
import { GQLHooks } from '../../../graphql/hasura/react';
import CreateUser from './CreateUser';
import UsersManagementTable from './usersManagement/UsersManagementTable';

const UsersManagement: React.FC = () => {
  let { objects: users = [], loading: loadingUsers, refetch: refetchUsers } = GQLHooks.Fragments.User.useQueryObjects({
    variables: {
      where: {},
      order_by: [
        {
          createdAt: Order_By.Desc,
        },
      ],
    },
  });

  return (
    <>
      <Grid container item>
        <Grid item xs={12}>
          <CreateUser />
        </Grid>
      </Grid>
      <Grid container item>
        <Grid item xs={12}>
          <UsersManagementTable users={users} loading={loadingUsers} refetchUsers={refetchUsers} />
        </Grid>
      </Grid>
    </>
  );
};

export default UsersManagement;
