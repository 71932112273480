export enum QaActionLinkedinUrlStatus {
  NOT_FOUND = 'NOT_FOUND',
  FOUND = 'FOUND',
}

export enum QaActionAssessment {
  CORRECT = 'CORRECT',
  INCORRECT = 'INCORRECT',
}

export enum QaTaskStatus {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
  DELETED = 'DELETED',
}