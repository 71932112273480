import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache, makeVar, NormalizedCacheObject } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { RetryLink } from '@apollo/client/link/retry';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import firebase from 'firebase';
import React, { useContext, useEffect, useState } from 'react';

import { AuthContext } from '../components/auth/AuthProvider';
import { HASURA_URI, HASURA_WS } from '../config/graphql';
import { logError } from '../modules/analytics';

export const apolloLoggedIn = makeVar(false);

const ApolloClientProvider: React.FC = ({ children }) => {
  const { authUser } = useContext(AuthContext);
  const [apolloClient, setApolloClient] = useState<ApolloClient<NormalizedCacheObject>>(
    new ApolloClient({
      link: new HttpLink({
        uri: HASURA_URI,
      }),
      cache: new InMemoryCache(),
    })
  );

  useEffect(() => {
    const handleUserChange = async (authUser: firebase.User) => {
      try {
        if (authUser) {
          const idTokenResult = await authUser.getIdTokenResult(true);
          const hasuraClaim = idTokenResult.claims['https://hasura.io/jwt/claims'];

          if (!hasuraClaim) {
            apolloLoggedIn(false);
            return;
          } else {
            apolloLoggedIn(true);
          }

          // https://www.apollographql.com/docs/react/networking/authentication/
          const authLink = setContext(async (_, { headers }) => {
            // return the headers to the context so link can read them
            const token = await authUser.getIdToken();

            return {
              headers: {
                ...(headers || {}),
                authorization: token ? `Bearer ${token}` : '',
              },
            };
          });

          const httpLink = new HttpLink({
            uri: HASURA_URI,
          });

          const wsLink = new WebSocketLink({
            uri: HASURA_WS,
            options: {
              lazy: true,
              reconnect: true,
              connectionParams: async () => {
                const token = await authUser.getIdToken();
                return {
                  headers: {
                    Authorization: token ? `Bearer ${token}` : '',
                  },
                };
              },
            },
          });

          // https://www.apollographql.com/docs/react/data/subscriptions/#3-use-different-transports-for-different-operations
          const link = new RetryLink().split(
            ({ query }) => {
              const definition = getMainDefinition(query);
              return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
            },
            wsLink,
            httpLink
          );

          const apolloClient = new ApolloClient({
            link: authLink.concat(link),
            cache: new InMemoryCache(),
          });

          setApolloClient(apolloClient);
        }
      } catch (err) {
        logError(err);
      }
    };

    if (authUser) {
      handleUserChange(authUser);
    } else {
      apolloLoggedIn(false);
    }
  }, [authUser]);

  return apolloClient ? <ApolloProvider client={apolloClient}>{children}</ApolloProvider> : null;
};

export default ApolloClientProvider;
