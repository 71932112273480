import { Box, CircularProgress } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Alert } from '@material-ui/lab';
import React, { useState } from 'react';

import { ClientExternalFragment } from '../../../../graphql';
import { GQLHooks } from '../../../../graphql/hasura/react';
import { AlertSeverity } from '../../../../utils/constants';
import { getApiErrorMessage } from '../../../../utils/errors';

interface Props {
  client: ClientExternalFragment;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const DeleteClientContactsModal: React.FC<Props> = ({ client, isOpen, setIsOpen }) => {
  const [alert, setAlert] = useState<AlertData | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const [deleteClientContacts] = GQLHooks.Models.ClientContact.useRemoveObjects();

  const handleClose = () => {
    setAlert(undefined);
    setIsLoading(false);
    setIsOpen(false);
  };

  const onConfirmDeleteClientContacts = async () => {
    setAlert(undefined);
    setIsLoading(true);
    try {
      const queryFilter = {
        variables: {
          where: {
            clientId: {
              _eq: client.id,
            },
          },
        },
      };

      const { errors: deleteClientContactsErrors } = await deleteClientContacts(queryFilter);

      if (deleteClientContactsErrors) {
        console.error(deleteClientContactsErrors);
      }

      console.log('✅  Client contacts deleted');

      setAlert({
        severity: AlertSeverity.SUCCESS,
        message: 'Client contacts successfully deleted',
      });
    } catch (err) {
      const errorMessage = getApiErrorMessage(err);
      setAlert({
        severity: AlertSeverity.ERROR,
        message: errorMessage,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} aria-labelledby="delete-contacts">
      <DialogTitle>Delete Client Contacts for {client.name}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          This will delete client contacts for {client.name}. This action will not impact the contact data connected to
          the client contacts.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {isLoading && (
          <Box marginTop={1}>
            <CircularProgress color="primary" />
          </Box>
        )}
        {!(alert?.severity === AlertSeverity.SUCCESS) ? (
          <>
            <Button onClick={handleClose} variant="outlined" color="primary" disabled={isLoading}>
              Cancel
            </Button>
            <Button onClick={onConfirmDeleteClientContacts} variant="contained" color="primary" disabled={isLoading}>
              Confirm
            </Button>
          </>
        ) : (
          <Button onClick={handleClose} variant="outlined" color="primary" disabled={isLoading}>
            Ok
          </Button>
        )}
      </DialogActions>
      <Box>{alert && <Alert severity={alert.severity}>{alert.message}</Alert>}</Box>
    </Dialog>
  );
};

export default DeleteClientContactsModal;
