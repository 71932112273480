import { TablePagination } from '@material-ui/core';
import React from 'react';

interface Props {
  currentPage: number;
  itemsPerPage: number;
  totalCount: number;
  onChangePage: (page: number) => void;
}

const CustomTablePagination: React.FC<Props> = ({ currentPage, totalCount, itemsPerPage, onChangePage }) => {
  return (
    <TablePagination
      // Hide rows per page dropdown
      rowsPerPageOptions={[]}
      count={totalCount}
      rowsPerPage={itemsPerPage}
      page={currentPage}
      onPageChange={(event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => {
        onChangePage(page);
      }}
      size="medium"
    />
  );
};

export default CustomTablePagination;
