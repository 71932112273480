import { Box, CircularProgress, Grid, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Alert } from '@material-ui/lab';
import React, { useState } from 'react';

import { ClientExternalFragment } from '../../graphql';
import { GQLHooks } from '../../graphql/hasura/react';
import { resyncClientContacts } from '../../modules/admin';
import { AlertSeverity } from '../../utils/constants';
import { getApiErrorMessage } from '../../utils/errors';
import SelectContactLists from './integrationSteps/selectContactLists/SelectContactLists';

interface Props {
  client: ClientExternalFragment;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const SyncHubspotContactListsModal: React.FC<Props> = ({ client, isOpen, setIsOpen }) => {
  const alreadySyncedListIds = (client.hubspotIntegration?.mapping as IntegrationMapping).selectedListIds || [];
  const [alert, setAlert] = useState<AlertData | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedListIds, setSelectedListIds] = useState<string[]>(alreadySyncedListIds);
  const [updateHubspotIntegration] = GQLHooks.Fragments.HubspotIntegration.useUpdateById();

  const handleClose = () => {
    setAlert(undefined);
    setIsLoading(false);
    setIsOpen(false);
  };

  const onConfirm = async () => {
    setAlert(undefined);
    setIsLoading(true);
    try {
      await updateHubspotIntegration({
        hubspotIntegrationId: client.hubspotIntegration!.id,
        set: {
          mapping: {
            ...(client.hubspotIntegration?.mapping || {}),
            selectedListIds,
          },
        },
      });
      await resyncClientContacts(client.id);
      setAlert({
        severity: AlertSeverity.SUCCESS,
        message: 'We are now syncing your lists',
      });
    } catch (err) {
      const errorMessage = getApiErrorMessage(err);
      setAlert({
        severity: AlertSeverity.ERROR,
        message: errorMessage,
      });
    } finally {
      setIsLoading(false);
      handleClose();
    }
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} aria-labelledby="sync-lists">
      <DialogTitle>Sync Additional Lists from {client.integrationType}?</DialogTitle>
      <DialogContent>
        <Grid container item xs={11} spacing={1}>
          <Grid item>
            <Typography>If you would like to sync in additional contact lists, you may select them below:</Typography>
          </Grid>
          <SelectContactLists
            selectedListIds={selectedListIds}
            setSelectedListIds={setSelectedListIds}
            alreadySyncedListIds={alreadySyncedListIds}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        {isLoading && (
          <Box marginTop={1}>
            <CircularProgress color="primary" />
          </Box>
        )}
        {!(alert?.severity === AlertSeverity.SUCCESS) ? (
          <>
            <Button onClick={handleClose} variant="outlined" color="primary" disabled={isLoading}>
              Cancel
            </Button>
            <Button
              onClick={onConfirm}
              variant="contained"
              color="primary"
              disabled={isLoading || selectedListIds.length === alreadySyncedListIds.length}
            >
              Confirm
            </Button>
          </>
        ) : (
          <Button onClick={handleClose} variant="outlined" color="primary" disabled={isLoading}>
            Ok
          </Button>
        )}
      </DialogActions>
      <Box>{alert && <Alert severity={alert.severity}>{alert.message}</Alert>}</Box>
    </Dialog>
  );
};

export default SyncHubspotContactListsModal;
