import {
  Box,
  Button,
  Checkbox,
  DialogActions,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Popover,
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import FilterListIcon from '@material-ui/icons/FilterList';
import React, { useEffect, useState } from 'react';

import { UserFragment } from '../../../graphql';

const useStyles = makeStyles(() =>
  createStyles({
    filterIconButton: {
      padding: '5px',
    },
  })
);

interface Props {
  filter?: JobChangeFilter;
  handleAddOrUpdateFilter: (filter: JobChangeFilter) => void;
  handleClearFilter: (filter: JobChangeFilter) => void;
  ownerUsers: UserFragment[];
}

const FilterByOwnerPopover: React.FC<Props> = ({ filter, handleAddOrUpdateFilter, handleClearFilter, ownerUsers }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const [checkedUserIds, setCheckedUserIds] = useState<(string | null)[]>([]);

  useEffect(() => {
    setCheckedUserIds([...(filter?.ownerUserIds || [])]);
  }, [filter?.ownerUserIds]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onResetChecked = () => {
    setCheckedUserIds([]);
  };

  const handleChange = (userId: string | null, checked: boolean) => {
    if (checked) {
      setCheckedUserIds([...checkedUserIds, userId]);
    } else {
      setCheckedUserIds(checkedUserIds.filter((checkedUserId) => checkedUserId !== userId));
    }
  };

  const onClickApply = () => {
    if (!checkedUserIds.length) {
      if (!filter) {
        setAnchorEl(null);
        return;
      } else {
        handleClearFilter(filter);
      }
    } else {
      handleAddOrUpdateFilter({
        ownerUserIds: checkedUserIds,
      });
    }
    setAnchorEl(null);
  };

  return (
    <Box>
      <IconButton aria-label="filter" onClick={handleClick} className={classes.filterIconButton}>
        <FilterListIcon fontSize="small" />
      </IconButton>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box padding={1}>
          <FormControl component="fieldset">
            <Box>Filter by owner</Box>
            <FormGroup>
              {ownerUsers.map((ownerUser) => {
                return (
                  <FormControlLabel
                    key={ownerUser.id}
                    control={
                      <Checkbox
                        checked={checkedUserIds.includes(ownerUser.id)}
                        onChange={(e) => {
                          handleChange(ownerUser.id, e.target.checked);
                        }}
                        color="primary"
                      />
                    }
                    label={`${ownerUser.firstName} ${ownerUser.lastName} (${ownerUser.email})`}
                  />
                );
              })}

              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkedUserIds.includes(null)}
                    onChange={(e) => {
                      handleChange(null, e.target.checked);
                    }}
                    color="primary"
                  />
                }
                label="Unassigned"
              />
            </FormGroup>
          </FormControl>
        </Box>
        <Grid container justify="center">
          <Grid item>
            <DialogActions>
              <Button variant="outlined" autoFocus onClick={onResetChecked} color="primary">
                Reset
              </Button>
              <Button variant="contained" onClick={onClickApply} color="primary">
                Apply
              </Button>
            </DialogActions>
          </Grid>
        </Grid>
      </Popover>
    </Box>
  );
};

export default FilterByOwnerPopover;
