import { Box, Button, CircularProgress, Grid, Tooltip, Typography } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Alert } from '@material-ui/lab';
import React, { useContext, useState } from 'react';

import { GQLHooks } from '../../../graphql/hasura/react';
import { logError } from '../../../modules/analytics';
import {
  AlertSeverity,
  ClientContactAttribute,
  ClientContactAttributeMetaData,
  IntegrationType,
} from '../../../utils/constants';
import { getApiErrorMessage } from '../../../utils/errors';
import { ClientContext } from '../../Main';
import HelpMenu from './HelpMenu';
import MapAttributesTable from './mapAttributes/MapAttributesTable';
import SelectContactLists from './selectContactLists/SelectContactLists';

export const defaultHubspotMapping: HubspotMapping = {
  [ClientContactAttribute.FIRST_NAME]: 'firstname',
  [ClientContactAttribute.LAST_NAME]: 'lastname',
  [ClientContactAttribute.CURRENT_TITLE]: 'jobtitle',
  [ClientContactAttribute.EMAIL]: 'email',
  [ClientContactAttribute.CURRENT_COMPANY]: 'company',
};

export const defaultSalesforceMapping: SalesforceMapping = {
  [ClientContactAttribute.FIRST_NAME]: 'FirstName',
  [ClientContactAttribute.LAST_NAME]: 'LastName',
  [ClientContactAttribute.CURRENT_TITLE]: 'Title',
  [ClientContactAttribute.EMAIL]: 'Email',
};

export const requiredWarmlyAttributes = [
  ClientContactAttribute.FIRST_NAME,
  ClientContactAttribute.LAST_NAME,
  ClientContactAttribute.EMAIL,
];

const CustomizeFields: React.FC = () => {
  const { selectedClient } = useContext(ClientContext);
  const [errorMessage, setErrorMessage] = useState('');
  const defaultMapping =
    selectedClient.integrationType === IntegrationType.HubSpot ? defaultHubspotMapping : defaultSalesforceMapping;
  const [integrationMapping, setIntegrationMapping] = useState<IntegrationMapping>(defaultMapping);
  const [selectedListIds, setSelectedListIds] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const [updateHubspotIntegration] = GQLHooks.Fragments.HubspotIntegration.useUpdateById();
  const [updateSalesforceIntegration] = GQLHooks.Fragments.SalesforceIntegration.useUpdateById();
  const [updateClient] = GQLHooks.Fragments.ClientExternal.useUpdateById();

  const fieldDisplayTextPlural = selectedClient.integrationType === IntegrationType.HubSpot ? 'properties' : 'fields';

  const onClickConfirm = async () => {
    try {
      // Check for any missing required field mappings
      const missingAttributes = [];

      for (const requiredAttribute of requiredWarmlyAttributes) {
        const attributeDisplayText = ClientContactAttributeMetaData[requiredAttribute].displayText;

        if (!integrationMapping[requiredAttribute]) {
          missingAttributes.push(attributeDisplayText);
        }
      }

      if (missingAttributes.length) {
        const errMsg = `Missing required attributes: ${missingAttributes.join(', ')}`;
        setErrorMessage(errMsg);
        return;
      }

      setIsLoading(true);

      await updateClient({
        clientId: selectedClient.id,
        set: {
          syncStatus: null,
        },
      });

      let updateIntegrationMutation;

      if (selectedClient.integrationType === IntegrationType.HubSpot) {
        updateIntegrationMutation = await updateHubspotIntegration({
          hubspotIntegrationId: selectedClient.hubspotIntegration!.id,
          set: {
            mapping: {
              ...integrationMapping,
              selectedListIds,
            },
          },
        });
      } else if (selectedClient.integrationType === IntegrationType.Salesforce) {
        updateIntegrationMutation = await updateSalesforceIntegration({
          salesforceIntegrationId: selectedClient.salesforceIntegration!.id,
          set: {
            mapping: integrationMapping,
          },
        });
      }

      if (updateIntegrationMutation?.errors) {
        logError(
          new Error('Error updating mapping and list selection'),
          JSON.stringify(updateIntegrationMutation?.errors)
        );
        throw updateIntegrationMutation?.errors;
      }
    } catch (err) {
      setErrorMessage(getApiErrorMessage(err));
      setIsLoading(false);
    }
  };

  return (
    <>
      <Grid container direction="row" spacing={1}>
        <Grid item xs={8}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Typography component="div" variant="h4">
                <Box fontWeight="bold">
                  Step 1 – Map {selectedClient.integrationType} {fieldDisplayTextPlural}
                </Box>
              </Typography>
            </Grid>
            <Grid item>
              <MapAttributesTable
                integrationMapping={integrationMapping}
                setIntegrationMapping={setIntegrationMapping}
              />
            </Grid>
            {selectedClient.integrationType === IntegrationType.HubSpot ? (
              <Grid container spacing={1}>
                <Grid item>
                  <Typography variant="subtitle2">
                    We sync in all of your {selectedClient.integrationType} contacts by default. However, you may select
                    specific HubSpot lists to sync instead.
                  </Typography>
                  <Typography variant="caption">
                    *You must press ADD after selecting each list that you want to sync
                  </Typography>
                </Grid>
                <SelectContactLists selectedListIds={selectedListIds} setSelectedListIds={setSelectedListIds} />
              </Grid>
            ) : null}
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <HelpMenu />
        </Grid>
      </Grid>

      <Grid container justify="flex-end" alignItems="center" spacing={3}>
        {errorMessage && (
          <Grid item xs>
            <Alert severity={AlertSeverity.ERROR}>{errorMessage}</Alert>
          </Grid>
        )}
      </Grid>

      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        style={{ position: 'fixed', bottom: '40px', right: '70px' }}
      >
        {isLoading && (
          <Box marginRight={2}>
            <CircularProgress size={28} />
          </Box>
        )}
        <Tooltip title="Don't worry, this does not initiate the syncing process. That will kick off after the next step!">
          <Button
            variant="contained"
            color="primary"
            onClick={onClickConfirm}
            disabled={isLoading}
            endIcon={<ChevronRightIcon />}
          >
            Next
          </Button>
        </Tooltip>
      </Box>
    </>
  );
};

export default CustomizeFields;
