import { Grid, Typography } from '@material-ui/core';
import React from 'react';

import AccountSettings from './settings/AccountSettings';
import NotificationSettings from './settings/NotificationSettings';

const Settings: React.FC = () => {
  return (
    <>
      <Grid container direction="column" justify="flex-start" alignItems="flex-start" spacing={3}>
        <Grid container item>
          <Typography variant="h1" color="primary">
            Settings
          </Typography>
        </Grid>
        <Grid item>
          <AccountSettings />
        </Grid>
        <Grid item>
          <NotificationSettings />
        </Grid>
      </Grid>
    </>
  );
};

export default Settings;
