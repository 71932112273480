import { Button, Grid, IconButton, MenuItem, Select, TextField, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { Autocomplete } from '@material-ui/lab';
import { debounce } from 'lodash';
import React, { useContext, useMemo, useState } from 'react';

import { ClientContactCrmData_Select_Column, ClientContactCrmDataFragment, Order_By } from '../../../graphql';
import { GQLHooks } from '../../../graphql/hasura/react';
import { ClientContext } from '../../Main';

interface Props {
  distinctCrmData: ClientContactCrmDataFragment[];
  selectedCrmData?: ClientContactCrmDataFragment;
  filter?: JobChangeFilter;
  handleAddOrUpdateFilter: (filter: JobChangeFilter) => void;
  handleDeleteFilter: (filter: JobChangeFilter) => void;
}

const JobChangeFilter: React.FC<Props> = ({
  distinctCrmData,
  selectedCrmData,
  filter,
  handleAddOrUpdateFilter,
  handleDeleteFilter,
}) => {
  const { selectedClientId } = useContext(ClientContext);

  const [searchTerms, setSearchTerms] = useState<string[] | undefined>(filter ? filter.fieldValueSearchTerms : []);
  const [searchQueryValue, setSearchQueryValue] = useState<string | undefined>();
  const [crmData, setCrmData] = useState<ClientContactCrmDataFragment | undefined>(selectedCrmData);

  const { objects: crmValueSearchData } = GQLHooks.Fragments.ClientContactCrmData.useQueryObjects({
    variables: {
      where: {
        clientId: {
          _eq: selectedClientId,
        },
        crmType: {
          _eq: crmData?.crmType,
        },
        fieldName: {
          _eq: crmData?.fieldName,
        },
        fieldValue: {
          _ilike: `${searchQueryValue}%`,
        },
      },
      limit: 100,
      distinct_on: [ClientContactCrmData_Select_Column.FieldValue],
      order_by: [
        {
          fieldValue: Order_By.Asc,
        },
      ],
    },
    skip: !searchQueryValue?.length || !crmData,
  });

  const autocompleteOptions = crmValueSearchData
    .map((crmData) => crmData.fieldValue)
    .filter((value) => value) as string[];

  const debouncedHandleSearchInputChange = useMemo(() => {
    return debounce((queryValue) => {
      setSearchQueryValue(queryValue);
    }, 500);
  }, []);

  const onClickAddOrUpdateFilter = () => {
    if (!crmData) {
      return;
    } else {
      handleAddOrUpdateFilter({
        crmType: crmData.crmType || null,
        fieldName: crmData.fieldName,
        fieldValueSearchTerms: searchTerms,
      });

      if (!filter) {
        setCrmData(undefined);
        setSearchTerms([]);
        setSearchQueryValue(undefined);
      }
    }
  };

  const onChangeSearchTerms = (_event: React.ChangeEvent<{}>, value: string[]) => {
    setSearchTerms(value);
  };

  const onChangeSearchInput = (_event: React.ChangeEvent<{}>, value: string) => {
    debouncedHandleSearchInputChange(value);
  };

  const onChangeCrmData = (event: React.ChangeEvent<{ value: unknown }>) => {
    event.preventDefault();
    const crmDataId = event.target.value as string;
    const selectedCrmData = distinctCrmData?.find((crmDataRow) => crmDataRow.id === crmDataId);
    setCrmData(selectedCrmData);
  };

  const onClickDeleteFilter = () => {
    if (!filter) {
      return;
    } else {
      handleDeleteFilter(filter);
    }
  };

  return (
    <Grid container item>
      <Grid container item alignItems="center" spacing={2}>
        <Grid item xs={3}>
          <Select fullWidth value={crmData?.id || ''} displayEmpty onChange={onChangeCrmData} label="CRM Data">
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {distinctCrmData.map((crmData) => {
              return (
                <MenuItem key={crmData.id} value={crmData.id}>
                  {`${crmData.fieldLabel || crmData.fieldName} (${crmData.crmType || 'CSV'})`}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>
        <Grid item xs={1}>
          includes
        </Grid>
        <Grid item xs={5}>
          <Autocomplete
            multiple
            value={searchTerms}
            onChange={onChangeSearchTerms}
            onInputChange={onChangeSearchInput}
            options={autocompleteOptions}
            limitTags={2}
            renderInput={(params) => <TextField {...params} variant="standard" placeholder="Select one or more (exact match only)" />}
          />
        </Grid>
        <Grid item xs={2}>
          {filter ? (
            <>
              <Button
                size="small"
                variant="contained"
                onClick={onClickAddOrUpdateFilter}
                disabled={!crmData || (searchTerms && !searchTerms.length)}
              >
                Update
              </Button>
              <Tooltip title="Delete filter">
                <IconButton aria-label="delete-filter" onClick={onClickDeleteFilter}>
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </>
          ) : (
            <Button size="small" variant="contained" onClick={onClickAddOrUpdateFilter}>
              Add
            </Button>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default JobChangeFilter;
