import Axios, { AxiosResponse } from 'axios';

import { ClientExternalFragment } from '../graphql';
import { EmailCategory, gaeApiURL, IntegrationType } from '../utils/constants';
import { getAuthToken } from './auth';

Axios.defaults.headers.common['Cache-Control'] = 'no-cache';
Axios.defaults.timeout = 500000;

export const uploadCsvClientContacts = async (csvUploadId: string): Promise<AxiosResponse> => {
  const functionURL = gaeApiURL.csv.createUploadCsvTask;
  const body = {
    csvUploadId,
  };

  const authToken = await getAuthToken();

  return Axios.post(functionURL, body, {
    headers: {
      Authorization: 'Bearer ' + authToken,
    },
  });
};

export const initiateSalesforceIntegration = async (): Promise<void> => {
  const functionURL = gaeApiURL.salesforce.auth;

  const authToken = await getAuthToken();

  const salesforceAuthURL = await Axios.get<string>(functionURL, {
    headers: {
      Authorization: 'Bearer ' + authToken,
    },
  });

  if (salesforceAuthURL.data) {
    window.location.assign(salesforceAuthURL.data);
  }
};

export const initiateHubspotIntegration = async (): Promise<void> => {
  const functionURL = gaeApiURL.hubspot.auth;

  const authToken = await getAuthToken();

  const hubspotAuthURL = await Axios.get<string>(functionURL, {
    headers: {
      Authorization: 'Bearer ' + authToken,
      'Cache-Control': 'no-cache',
    },
  });

  if (hubspotAuthURL.data) {
    window.location.assign(hubspotAuthURL.data);
  }
};

export const initiateSalesforceSyncContacts = async (salesforceIntegrationId: string): Promise<AxiosResponse> => {
  const functionURL = gaeApiURL.salesforce.syncContacts;

  const authToken = await getAuthToken();
  const body = { salesforceIntegrationId };

  return Axios.post(functionURL, body, {
    headers: {
      Authorization: 'Bearer ' + authToken,
    },
  });
};

export const initiateHubspotSyncContacts = async (hubspotIntegrationId: string): Promise<AxiosResponse> => {
  const functionURL = gaeApiURL.hubspot.syncContacts;

  const authToken = await getAuthToken();
  const body = { hubspotIntegrationId };

  return Axios.post(functionURL, body, {
    headers: {
      Authorization: 'Bearer ' + authToken,
    },
  });
};

export const approveSalesforceContact = async (clientContactId: string) => {
  const functionURL = gaeApiURL.salesforce.approveContact;
  const body = { clientContactId };

  const authToken = await getAuthToken();

  return Axios.put(functionURL, body, {
    headers: {
      Authorization: 'Bearer ' + authToken,
    },
  });
};

export const approveHubspotContact = async (clientContactId: string) => {
  const functionURL = gaeApiURL.hubspot.approveContact;
  const body = { clientContactId };

  const authToken = await getAuthToken();

  return Axios.put(functionURL, body, {
    headers: {
      Authorization: 'Bearer ' + authToken,
    },
  });
};

export const requestPasswordResetEmail = async (email: string) => {
  const functionURL = gaeApiURL.user.resetPassword;

  const authToken = await getAuthToken();

  return Axios.post(
    functionURL,
    { email },
    {
      headers: {
        Authorization: 'Bearer ' + authToken,
      },
    }
  );
};

export const createEnrichCrmCloudTask = async (client: ClientExternalFragment) => {
  let functionURL = '';

  if (client.integrationType === IntegrationType.HubSpot) {
    functionURL = gaeApiURL.hubspot.createEnrichCrmTask;
  } else if (client.integrationType === IntegrationType.Salesforce) {
    functionURL = gaeApiURL.salesforce.createEnrichCrmTask;
  }

  const authToken = await getAuthToken();

  return Axios.post(
    functionURL,
    { clientId: client.id },
    {
      headers: {
        Authorization: 'Bearer ' + authToken,
      },
    }
  );
};

export const createSyncHubspotPropertyCloudTask = async (clientId: string, propertyName: string) => {
  const url = gaeApiURL.hubspot.createSyncPropertyTask;
  const body = { clientId, propertyName };

  const authToken = await getAuthToken();

  return Axios.post(url, body, {
    headers: {
      Authorization: 'Bearer ' + authToken,
    },
  });
};

export const createSyncSalesforceFieldCloudTask = async (clientId: string, fieldName: string) => {
  const url = gaeApiURL.salesforce.createSyncFieldTask;
  const body = { clientId, fieldName };

  const authToken = await getAuthToken();

  return Axios.post(url, body, {
    headers: {
      Authorization: 'Bearer ' + authToken,
    },
  });
};

export const createScheduledEmailCloudTask = async (
  userId: string,
  emailCategory: EmailCategory,
  inSeconds: number
) => {
  const url = gaeApiURL.emails.createScheduledEmailTask;
  const body = { userId, emailCategory, inSeconds };

  const authToken = await getAuthToken();

  return Axios.post(url, body, {
    headers: {
      Authorization: 'Bearer ' + authToken,
    },
  });
};

export const refreshHubspotContactLists = async (hubspotIntegrationId: string) => {
  const url = gaeApiURL.hubspot.refreshContactLists;
  const body = { hubspotIntegrationId };

  const authToken = await getAuthToken();

  return Axios.post(url, body, {
    headers: {
      Authorization: 'Bearer ' + authToken,
    },
  });
};

export const refreshHubspotContactProperties = async (hubspotIntegrationId: string) => {
  const url = gaeApiURL.hubspot.refreshContactProperties;
  const body = { hubspotIntegrationId };

  const authToken = await getAuthToken();

  return Axios.post(url, body, {
    headers: {
      Authorization: 'Bearer ' + authToken,
    },
  });
};

export const refreshSalesforceContactFields = async (salesforceIntegrationId: string) => {
  const url = gaeApiURL.salesforce.refreshContactFields;
  const body = { salesforceIntegrationId };

  const authToken = await getAuthToken();

  return Axios.post(url, body, {
    headers: {
      Authorization: 'Bearer ' + authToken,
    },
  });
};

export const exportContactsCsv = async (clientId: string, jobChangesOnly: boolean) => {
  const url = gaeApiURL.csv.exportContactsCsv;
  const body = { clientId, jobChangesOnly };

  const authToken = await getAuthToken();

  return Axios.post(url, body, {
    headers: {
      Authorization: 'Bearer ' + authToken,
    },
  });
};

export const mapTerritoryForUser = async ({ userId, placeId }: { userId: string; placeId: string }) => {
  const url = gaeApiURL.user.mapTerritory;
  const body = { userId, placeId };

  const authToken = await getAuthToken();

  return Axios.post(url, body, {
    headers: {
      Authorization: 'Bearer ' + authToken,
    },
  });
};

export const getTheOrgChartURL = async (companyName: string) => {
  // Encode and use template literal since Axios has its own different encoding when using query params
  const orgName = encodeURIComponent(companyName.toLowerCase());
  const url = `${gaeApiURL.company.getTheOrg}/?orgName=${orgName}`;

  const authToken = await getAuthToken();

  const response = await Axios.get(url, {
    headers: {
      Authorization: 'Bearer ' + authToken,
    },
  });

  return response.data;
};

export const assignOwners = async (clientId: string) => {
  const url = gaeApiURL.client.assignOwners;

  const authToken = await getAuthToken();

  return Axios.post(
    url,
    {
      clientId,
    },
    {
      headers: {
        Authorization: 'Bearer ' + authToken,
      },
    }
  );
};

export const verifyRecaptcha = async (recaptchaValue: string) => {
  const url = gaeApiURL.user.verifyRecaptcha;

  return Axios.post(url, {
    recaptchaValue,
  });
};
