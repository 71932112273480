import { Grid, MenuItem, Select } from '@material-ui/core';
import React from 'react';

import UsersManagementRoleChip from '../pages/dashboard/adminPanel/usersManagement/UsersManagementRoleChip';

interface Props {
  options: SelectOption[];
  selectedValue?: string;
  onSelect: (value: string) => Promise<void> | void;
  displayUserRoleChips?: boolean;
}

const SelectDropdown: React.FC<Props> = ({ options, selectedValue = '', onSelect, displayUserRoleChips }) => {
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newValue = event.target.value as string;

    onSelect(newValue);
  };

  return (
    <Grid container>
      <Select value={selectedValue} onChange={handleChange} displayEmpty fullWidth>
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {options.map((option) => {
          return (
            <MenuItem key={option.value} value={option.value}> 
            {
              displayUserRoleChips ? (
                <UsersManagementRoleChip role={option.value}/>
              ) : (
                option.value
              )
            }
            </MenuItem>
          );
        })}
      </Select>
    </Grid>
  );
};

export default SelectDropdown;
