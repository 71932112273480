import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Grid, makeStyles, Menu, MenuItem, Typography,  } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

import { LoadingAndAlertContext } from '../../../src';
import { ClientContactExternalFragment } from '../../graphql';
import { AlertSeverity, AmplitudeEventType, WarmlyColor } from '../../utils/constants';
import { LightTooltip } from './JobChangeCrmAction';

const FIXED_HEIGHT = 100;

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    height: FIXED_HEIGHT,
  },
  row: {
    height: '100%',
  },
  rowItem: {
    height: FIXED_HEIGHT,
  },
  avatar: {
    height: FIXED_HEIGHT,
    width: FIXED_HEIGHT,
  },
  dropDownButton: {
    minWidth: 30,
    padding: 2,
    marginLeft: 10,
  },
}));

interface JobChangeEmailProps {
  clientContact: ClientContactExternalFragment;
  onSelectEmail: (s: string) => void;
  setAmplitudeEmailClickEvent: (e: AmplitudeEventType) => void;
}

const JobChangeEmail: React.FC<JobChangeEmailProps> = ({ clientContact, onSelectEmail, setAmplitudeEmailClickEvent }) => {
  const [selectedEmail, setSelectedEmail] = useState('');
  const [selectedEmailColor, setSelectedEmailColor] = useState(WarmlyColor.BLACK);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { setSnackbarAlertData } = useContext(LoadingAndAlertContext);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const classes = useStyles();

  const removeEmailAdditions = (email: string) => {
    if (email!.includes('(outdated)')) {
      email = email.replace('(outdated)', '');
    } else if (email!.includes(' ✅')) {
      email = email.replace(' ✅', '');
    } else if (email!.includes('(not validated)')) {
      email = email.replace('(not validated)', '');
    }
    return email;
  };

  const handleClose = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    const email = (event.target as HTMLInputElement).textContent as string;
    setSelectedEmail(email);
    // Check if email string has "(outdated)"
    if (email!.includes('(outdated)')) {
      setSelectedEmailColor(WarmlyColor.GRAY);
    } else {
      setSelectedEmailColor(WarmlyColor.BLACK);
    }
    // Update the state in parent component with the selected email
    onSelectEmail(removeEmailAdditions(email));
    setAnchorEl(null);
  };

  const onCopyEmail = (email: string) => {
    const alertData: AlertData = {
      severity: AlertSeverity.SUCCESS,
      message: `${email} copied to clipboard!`,
    };
    setAmplitudeEmailClickEvent(AmplitudeEventType.CLICK_EMAIL_TEXT);
    setSnackbarAlertData(alertData);
  }

  const allContactEmails = clientContact.contactData?.contactEmails || [];
  const validContactEmails = allContactEmails.filter((contactEmail) => contactEmail.isValid);
  const foundEmailToDisplay =
    validContactEmails.length > 0
      ? `${validContactEmails[validContactEmails.length - 1]?.email} ✅`
      : allContactEmails[allContactEmails.length - 1]?.email;
  const emailToDisplay = allContactEmails.length > 0 ? foundEmailToDisplay : (clientContact.crmEmail || 'no email detected');

  const emailTextElement = <Typography variant="subtitle1">{emailToDisplay}</Typography>;
  const emailDisplayElement = (
      allContactEmails.length > 0 ? (
        emailTextElement
      ) : (
        //TODO: We'll want to check if crmEmail is valid by pinging it and then write custom logic to show whether
        // crmEmail is 1) valid 2) outdated 3) not pinged
        <Box color={WarmlyColor.GRAY}>{emailTextElement}</Box>
      )
  );

  let emails: JSX.Element[] = [];

  if (clientContact.contactData?.contactEmails?.length) {
    if (clientContact.crmEmail) {
      emails.push(
        <MenuItem key={clientContact.id} onClick={handleClose} value={clientContact.crmEmail}>{`${
          clientContact.crmEmail
        } ${
          clientContact.crmEmailIsValid === false ? '(outdated)' : clientContact.crmEmailIsValid === true ? ' ✅' : ''
        }`}</MenuItem>
      );
    }

    emails = emails.concat(
      clientContact.contactData.contactEmails.map((email) => {
        return email.isValid ? (
          <MenuItem key={email.id} onClick={handleClose} value={email.email}>{`${email.email} ${'✅'}`}</MenuItem>
        ) : email.isValid === false ? (
          <MenuItem key={email.id} onClick={handleClose} value={email.email}>
            <Box color={WarmlyColor.GRAY}>{`${email.email} (outdated)`}</Box>
          </MenuItem>
        ) : (
          <MenuItem key={email.id} onClick={handleClose} value={email.email}>
            <Box color={WarmlyColor.GRAY}>{`${email.email} (not validated)`}</Box>
          </MenuItem>
        );
      })
    );
  } else if (clientContact.crmEmail) {
    emails.push(
      <MenuItem key={clientContact.id} onClick={handleClose} value={clientContact.crmEmail}>{`${
        clientContact.crmEmail
      } ${
        clientContact.crmEmailIsValid === false ? '(outdated)' : clientContact.crmEmailIsValid === true ? ' ✅' : ''
      }`}</MenuItem>
    );
  }

  return (
    <Grid container item justify="center" alignItems="center" className={classes.rowItem}>
      {/* Displays emails  */}
      <Grid container item xs={9} direction="column" justify="center" alignItems="center">
        <Grid>
          <Grid item>
            <Box style={{ overflowWrap: 'anywhere' }}>
              <Box style={{ textAlign: 'center', color: selectedEmailColor }} flexGrow={1}>
                { selectedEmail ? (
                  // Multiple emails -> copy the selected one
                  <LightTooltip title='Click to copy email'>
                    <CopyToClipboard 
                      text={removeEmailAdditions(selectedEmail) || ''} 
                      onCopy={() => {onCopyEmail(removeEmailAdditions(selectedEmail))}}
                    >
                      <Button style={{ textTransform: 'none' }}>
                        <Typography variant="subtitle1">{selectedEmail}</Typography>
                      </Button>
                    </CopyToClipboard>
                  </LightTooltip>
                ) : (emailToDisplay === 'no email detected') ? (
                  // No emails -> do not provide ability to copy
                  emailDisplayElement
                ) : (
                  // Just one email -> copy it
                  <LightTooltip title='Click to copy email'>
                    <CopyToClipboard 
                      text={removeEmailAdditions(emailToDisplay) || ''} 
                      onCopy={() => {onCopyEmail(removeEmailAdditions(emailToDisplay))}}
                    >
                      <Button style={{ textTransform: 'none' }}>
                        {emailDisplayElement}
                      </Button>
                    </CopyToClipboard>
                  </LightTooltip>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>

      {/* Displays dropdown in right side of column menu when # of found emails > 1 */}
      {emails.length > 1 ? (
        <Grid container item xs={1} spacing={1} direction="column" justify="center">
          <Box>
            <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
              {emails}
            </Menu>
            <Button size="small" aria-haspopup="true" onClick={handleClick} className={classes.dropDownButton}>
              <FontAwesomeIcon size="sm" icon={faChevronDown} color={WarmlyColor.DARK_BLUE} />
            </Button>
          </Box>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default JobChangeEmail;
