import { Box, Button, Popover,} from '@material-ui/core';
import { createStyles, makeStyles, } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied';
import React, { useContext,useState } from 'react';

import { LoadingAndAlertContext } from '../../..';
import { getTheOrgChartURL } from '../../../modules/cloudFunctions';
import { AlertSeverity, THE_ORG_LOGO_ICON, WarmlyColor } from '../../../utils/constants';

const useStyles = makeStyles((theme) => 
  createStyles({
    orgChartButton: {
      borderRadius: '20%/50%',
      border: '1px solid',
      textTransform: 'none',
      padding: '0px',
      fontSize: '0.7rem',
      fontWeight: theme.typography.fontWeightBold,
    },
    theOrgButton: {
      textTransform: 'none',
      fontSize: '0.7rem',
      color: theme.palette.common.white,
      fontWeight: theme.typography.fontWeightBold,
    },
    orgChartResultBox: {
      display: 'flex',
      fontSize: '0.7rem',
      alignItems: 'center',
      fontWeight: theme.typography.fontWeightBold,
      marginBottom: '5px',
    }
  })
);

interface Props {
  companyName: string,
  disabled?: boolean,
}

const TheOrgFindOrgChartButton: React.FC<Props> = ({ companyName, disabled }) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [theOrgChartFound, setTheOrgChartFound] = useState(false);
  const [theOrgChartUrl, setTheOrgChartUrl] = useState('');
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [requestFiled, setRequestFiled] = useState(false);

  const { setSnackbarAlertData } = useContext(LoadingAndAlertContext);

  const todaysDate = new Date().toLocaleDateString();

  const requestMailToString = `mailto:olivia@theorg.com?subject=Request for ${companyName} to join The Org
  &body=A Warmly user has requested an org chart of ${companyName} on ${todaysDate}`;

  const onClickOrgChart = async (event: React.MouseEvent<HTMLButtonElement>) => {
    try {
      setIsLoading(true);
      setAnchorEl(event.currentTarget);

      const { theOrgFound, orgChartUrl } = await getTheOrgChartURL(companyName);
      setTheOrgChartFound(theOrgFound);
      setTheOrgChartUrl(orgChartUrl);
    } catch (err) {
      let errorMessage = 'Error occurred while retrieving org chart';
      let alertSeverity = AlertSeverity.ERROR;

      const alertData: AlertData = {
        severity: alertSeverity,
        message: errorMessage,
      };

      setSnackbarAlertData(alertData);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsLoading(false);
    setTheOrgChartFound(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'the-org-popover' : undefined;

  return (
    <>
      <Box display="flex" flexDirection="row" flexWrap="noWrap" alignItems="center" justifyContent="space-between">
      <Box>
        <Button 
          variant="outlined" 
          color="primary" 
          className={classes.orgChartButton}
          onClick={onClickOrgChart}
          disabled={disabled}
        >
          Org chart
        </Button>
      </Box>
      </Box>
      <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Box padding={1}>
          { !isLoading && ( theOrgChartFound ?
            <Box>
              <Box className={classes.orgChartResultBox} color={WarmlyColor.SUCCESS_GREEN}>
                <CheckIcon style={{ marginRight: '2px' }}/> Found!
              </Box>
              <Button 
                href={theOrgChartUrl} 
                target="_blank" 
                variant="contained" 
                className={classes.theOrgButton}
                style={{ backgroundColor: WarmlyColor.SUCCESS_GREEN }}
              >
                Open in The Org 
                <img alt="the-org-logo" src={THE_ORG_LOGO_ICON} width="20px" />
              </Button>
            </Box>
            :
            <Box>
              <Box className={classes.orgChartResultBox} color={WarmlyColor.WARNING_YELLOW}>
                <SentimentDissatisfiedIcon style={{ marginRight: '4px' }}/> Not found
              </Box>
              <Button 
                href={requestMailToString}
                target="_blank"
                rel="noopener noreferrer"
                variant="contained" 
                className={classes.theOrgButton}
                component="a"
                onClick={() => setRequestFiled(true)}
                style={{ backgroundColor: requestFiled ? WarmlyColor.DISABLED_GRAY : WarmlyColor.WARNING_YELLOW }}
              >
                  { requestFiled ? 'Request again from The Org': 'Request from The Org' }
                  <img alt="the-org-logo" src={THE_ORG_LOGO_ICON} width="20px" />
              </Button>
            </Box>
          )}
          { isLoading &&               
            <Box 
              fontSize="0.75rem" 
              fontStyle="italic"
              alignItems="center"
            >
              Checking...
            </Box>
          }
        </Box>
      </Popover>
    </>
  );
}

export default TheOrgFindOrgChartButton;