import { Box, CircularProgress } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Alert } from '@material-ui/lab';
import React, { useContext, useState } from 'react';

import { UserContext } from '../../../../components/auth/UserRouter';
import { ClientExternalFragment } from '../../../../graphql';
import { GQLHooks } from '../../../../graphql/hasura/react';
import { deleteClient } from '../../../../modules/admin';
import { AlertSeverity, UserRole } from '../../../../utils/constants';
import { getApiErrorMessage } from '../../../../utils/errors';

interface Props {
  client: ClientExternalFragment;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const DeleteClientModal: React.FC<Props> = ({ client, isOpen, setIsOpen }) => {
  const [alert, setAlert] = useState<AlertData | undefined>();
  const { user } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteClientContacts] = GQLHooks.Models.ClientContact.useRemoveObjects();
  const [deleteEnrichmentHistory] = GQLHooks.Models.CrmContactEnrichmentHistory.useRemoveObjects();
  const [deleteJobChanges] = GQLHooks.Models.ClientContactJobChange.useRemoveObjects();
  const [deleteCrmData] = GQLHooks.Models.ClientContactCrmData.useRemoveObjects();

  const [deleteHubspotContactListContacts] = GQLHooks.Models.HubspotContactListHubspotContact.useRemoveObjects();
  const [deleteHubspotContactList] = GQLHooks.Models.HubspotContactList.useRemoveObjects();
  const [deleteHubspotContactProperty] = GQLHooks.Models.HubspotContactProperty.useRemoveObjects();
  const [deleteHubspotIntegration] = GQLHooks.Models.HubspotIntegration.useRemoveObjects();

  const [deleteSalesforceContactField] = GQLHooks.Models.SalesforceContactField.useRemoveObjects();
  const [deleteSalesforceIntegration] = GQLHooks.Models.SalesforceIntegration.useRemoveObjects();

  const [deleteCsvUploads] = GQLHooks.Models.CsvUpload.useRemoveObjects();

  const [deleteClientNotificationEmails] = GQLHooks.Models.ClientNotificationEmail.useRemoveObjects();
  const [deleteEmailHistories] = GQLHooks.Models.EmailHistory.useRemoveObjects();
  const [deleteClientEmailReports] = GQLHooks.Models.ClientEmailReport.useRemoveObjects();
  const [deleteSendgridEvents] = GQLHooks.Models.SendgridEvent.useRemoveObjects();

  const [deleteClientContactFilters] = GQLHooks.Models.ClientContactFilter.useRemoveObjects();
  const [deleteClientTerritories] = GQLHooks.Models.ClientTerritory.useRemoveObjects();
  const [deleteUserInvites] = GQLHooks.Models.UserInvite.useRemoveObjects();

  const handleClose = () => {
    setAlert(undefined);
    setIsLoading(false);
    setIsOpen(false);
  };

  const onConfirmDeleteClient = async () => {
    setAlert(undefined);
    setIsLoading(true);
    try {
      if (client.id === user.clientId || user.role !== UserRole.INTERNAL) {
        setAlert({
          severity: AlertSeverity.ERROR,
          message: 'You may not delete your own client',
        });

        return;
      }

      const queryFilter = {
        variables: {
          where: {
            clientId: {
              _eq: client.id,
            },
          },
        },
      };

      await deleteClientContacts(queryFilter);
      console.log('➡️ Client contacts deleted');

      await deleteEnrichmentHistory(queryFilter);
      console.log('➡️ Enrichment history deleted');

      await deleteJobChanges(queryFilter);
      console.log('➡️ Job changes deleted');

      await deleteCrmData(queryFilter);
      console.log('➡️ CRM data deleted');

      // We do not delete QA Tasks and QA Task Actions - their clientIds will be set to null

      await deleteHubspotContactListContacts(queryFilter);
      console.log('Hubspot List contacts deleted!');

      await deleteHubspotContactList(queryFilter);
      console.log('Hubspot Lists deleted!');

      await deleteHubspotContactProperty(queryFilter);
      console.log('Hubspot contact properties deleted!');

      await deleteHubspotIntegration(queryFilter);
      console.log('Hubspot integration deleted!');

      await deleteSalesforceContactField(queryFilter);
      console.log('Salesforce contact fields deleted!');

      await deleteSalesforceIntegration(queryFilter);
      console.log('Salesforce integration deleted!');

      await deleteCsvUploads(queryFilter);
      console.log('CSV uploads deleted!');

      await deleteClientNotificationEmails(queryFilter);
      console.log('Client notification emails deleted!');

      await deleteEmailHistories(queryFilter);
      console.log('Email histories deleted!');

      await deleteClientEmailReports(queryFilter);
      console.log('Client email reports deleted!');

      await deleteSendgridEvents(queryFilter);
      console.log('Sendgrid events deleted!');

      await deleteClientContactFilters(queryFilter);
      console.log('Client contact filters (for QA) deleted!');

      await deleteClientTerritories(queryFilter);
      console.log('Client territories deleted!');

      await deleteUserInvites({
        variables: {
          where: {
            inviterClientId: {
              _eq: client.id,
            },
          },
        },
      });
      console.log('User invites deleted!');

      await deleteClient(client.id);

      setAlert({
        severity: AlertSeverity.SUCCESS,
        message: 'Client successfully deleted',
      });
    } catch (err) {
      const errorMessage = getApiErrorMessage(err);
      setAlert({
        severity: AlertSeverity.ERROR,
        message: errorMessage,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} aria-labelledby="delete-contacts">
      <DialogTitle>Delete {client.name}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          This will start a hard delete process for deleting the client, {client.name}. 🚨🚨 Please note that all associated
          data (including users, contacts, integrations, email records, etc.) will be deleted and cannot be recovered.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {isLoading && (
          <Box marginTop={1}>
            <CircularProgress color="primary" />
          </Box>
        )}
        {!(alert?.severity === AlertSeverity.SUCCESS) ? (
          <>
            <Button onClick={handleClose} variant="outlined" color="primary" disabled={isLoading}>
              Cancel
            </Button>
            <Button onClick={onConfirmDeleteClient} variant="contained" color="primary" disabled={isLoading}>
              Confirm
            </Button>
          </>
        ) : (
          <Button onClick={handleClose} variant="outlined" color="primary" disabled={isLoading}>
            Ok
          </Button>
        )}
      </DialogActions>
      <Box>{alert && <Alert severity={alert.severity}>{alert.message}</Alert>}</Box>
    </Dialog>
  );
};

export default DeleteClientModal;
