import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useState } from 'react';

import { GQLHooks } from '../../../../graphql/hasura/react';
import { logError } from '../../../../modules/analytics';
import { AlertSeverity } from '../../../../utils/constants';
import { getApiErrorMessage } from '../../../../utils/errors';
import { CloudTaskRowData } from '../CloudTasks';
import RecreateCloudTaskModal from './cloudTasksActions/RecreateCloudTaskModal';

interface Props {
  cloudTask: CloudTaskRowData;
}

const CloudTasksActions: React.FC<Props> = ({ cloudTask }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showRecreateTaskModal, setShowRecreateTaskModal] = useState(false);

  const [deleteCloudTask] = GQLHooks.Models.CloudTask.useRemoveById();

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleActionMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onCloseMenu = () => {
    setAnchorEl(null);
  };

  const onCloseModals = () => {
    setErrorMessage('');
    setIsLoading(false);
    setShowDeleteModal(false);
  };

  const onClickDelete = () => {
    setShowDeleteModal(true);
    setAnchorEl(null);
  };

  const onClickRecreate = () => {
    setShowRecreateTaskModal(true);
    setAnchorEl(null);
  };

  const onConfirmDelete = async () => {
    setIsLoading(true);

    try {
      await deleteCloudTask({ cloudTaskId: cloudTask.id });
      onCloseModals();
    } catch (err) {
      const errMessage = getApiErrorMessage(err);
      logError(err, `Error while deleting cloud task - ${errMessage}`);
      setErrorMessage(errMessage);
      setIsLoading(false);
    }
  };

  return (
    <Grid container justify="center">
      <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleActionMenuClick}>
        <FontAwesomeIcon icon={faBars} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        onClose={onCloseMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem onClick={onClickDelete}>Delete</MenuItem>
        <MenuItem onClick={onClickRecreate}>Recreate (Not Working)</MenuItem>
      </Menu>

      <Dialog open={showDeleteModal} onClose={onCloseModals}>
        <DialogTitle>Delete cloud task {cloudTask.description}?</DialogTitle>
        <DialogContent>
          <Typography>
            Note that this will only delete the record of the cloud task in our database, it will <strong>NOT</strong>{' '}
            delete or stop the task itself (e.g., if you delete a task that is CREATED or PROCESSING, it will still run
            in the background).
          </Typography>
          <Box marginY={2}>
            <Typography variant="body2">
              This action is primarly used for clean-up / fixing issues that arise when a task is submitted but failed
              to reach the task handler, in which case the task will be stuck at "CREATED" and you can't re-run.
              Deleting this record will allow you to re-run the task.
            </Typography>
          </Box>
          {errorMessage && <Alert severity={AlertSeverity.ERROR}>{errorMessage}</Alert>}
        </DialogContent>
        <DialogActions>
          {isLoading && (
            <Box marginRight={2}>
              <CircularProgress />
            </Box>
          )}
          <Button variant="outlined" color="primary" onClick={onCloseModals} disabled={isLoading}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={onConfirmDelete} disabled={isLoading}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <RecreateCloudTaskModal
        cloudTask={cloudTask}
        isOpen={showRecreateTaskModal}
        setIsOpen={setShowRecreateTaskModal}
      />
    </Grid>
  );
};

export default CloudTasksActions;
