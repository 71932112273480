import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import { UserContext } from '../../../components/auth/UserRouter';
import SelectDropdown from '../../../components/SelectDropdown';
import { QaTaskActionFragment, QaTaskFragment } from '../../../graphql';
import { GQLHooks } from '../../../graphql/hasura/react';
import {
  QaActionLinkedinUrlStatus,
  QAButtonType,
  QAJobChangeStatus as QAJobChangeStatusMap,
  QATaskActionType,
} from '../../../utils/constants';
import { checkEmailFormat, getClientContactName } from '../../../utils/functions';
import ConfirmTaskButton from '../buttons/ConfirmTaskButton';
import QAFindNewEmailButton from '../buttons/FindNewEmailButton';

interface Props {
  qaTask: QaTaskFragment;
  getQaTask: () => void;
}
const useStyles = makeStyles(() => ({
  paper: {
    width: '100%',
  },
  grid: {
    height: 100,
  },
}));

const QAApproverView: React.FC<Props> = ({ qaTask, getQaTask }) => {
  const { user } = useContext(UserContext);
  const [qaTaskAction, setQaTaskAction] = useState<QaTaskActionFragment>();
  const [previousQaTaskAction, setPreviousQaTaskAction] = useState<QaTaskActionFragment>();
  const { data: previousQaTaskQuery } = GQLHooks.Fragments.QaTask.useQueryById({
    qaTaskId: qaTask.previousQaTaskId!,
  });

  const classes = useStyles();

  const [QALinkedInURL, setQALinkedInURL] = useState('');
  const [QAJobChangeStatus, setQAJobChangeStatus] = useState('');
  const [QAFirstName, setQAFirstName] = useState('');
  const [QALastName, setQALastName] = useState('');
  const [QANewEmail, setQANewEmail] = useState('');
  const [QACompanyDomain, setQACompanyDomain] = useState('');
  const [generatedEmail, setGeneratedEmail] = useState('');
  const [QAApproverNotes, setQAApproverNotes] = useState('');
  const [QAJobChangeStatusRequiredError, setQAJobChangeStatusRequiredError] = useState(false);
  const [QANewEmailError, setQANewEmailError] = useState(false);
  const [QALinkedInURLNotFound, setQALinkedInURLNotFound] = useState(false);
  const [QALinkedInURLError, setQALinkedInURLError] = useState(false);
  const [isEmailLoading, setIsEmailLoading] = useState(false);
  const [validationError, setValidationError] = useState(true);
  const [removeAllDataError, setRemoveAllDataError] = useState(false);

  useEffect(() => {
    const previousQaTask = previousQaTaskQuery?.qaTask_by_pk;
    if (previousQaTask) {
      const previousQaTaskAction = previousQaTask.qaTaskActions
        .slice()
        .filter((task) => task.completedAt)
        .sort((taskA, taskB) => +new Date(taskB.completedAt!) - +new Date(taskA.completedAt!))[0];

      setQALinkedInURL(previousQaTaskAction.linkedinUrl || '');
      setQAJobChangeStatus(previousQaTaskAction.jobChangeStatus || '');
      setQANewEmail(previousQaTaskAction.updatedEmail || '');
      setQALinkedInURLNotFound(
        previousQaTaskAction.linkedinUrlStatus === QaActionLinkedinUrlStatus.FOUND ? false : true
      );
      setPreviousQaTaskAction(previousQaTaskAction);
    }
  }, [previousQaTaskQuery]);

  useEffect(() => {
    // get first and last name from clientContact. Used for getting new email
    const contactFullName = qaTask.clientContact?.fullName || '';
    const firstName = qaTask.clientContact?.firstName || contactFullName?.split(' ', 2)[0] || '';
    const lastName = qaTask.clientContact?.lastName || contactFullName?.split(' ', 2)[1] || '';
    setQAFirstName(firstName.toLowerCase());
    setQALastName(lastName.toLowerCase());

    const existingQaTaskAction = qaTask.qaTaskActions.find((action) => action.qaUserId === user.id);

    if (existingQaTaskAction) {
      setQaTaskAction(existingQaTaskAction);
    } else {
      throw new Error(`No task action found for task id ${qaTask.id}`);
    }
  }, [qaTask, user.id]);

  const clearForm = () => {
    setQALinkedInURL('');
    setQAJobChangeStatus('');
    setQANewEmail('');
    setQACompanyDomain('');
    setGeneratedEmail('');
    setQALastName('');
    setQAFirstName('');
    setQAApproverNotes('');
    setQALinkedInURLNotFound(false);
    setValidationError(false);
    setQANewEmailError(false);
    setQALinkedInURLError(false);
    setQAJobChangeStatusRequiredError(false);
    setRemoveAllDataError(false);
  };

  const onSelectJobChangeStatus = (newStatus: string) => {
    setQAJobChangeStatus(newStatus);
  };

  const jobChangeStatusOptions: SelectOption[] = Object.values(QAJobChangeStatusMap)
    .filter((value) => {
      return (
        value === QAJobChangeStatusMap.JOB_CHANGED ||
        value === QAJobChangeStatusMap.JOB_CHANGED_SAME_COMPANY ||
        value === QAJobChangeStatusMap.LEFT_OLD_JOB_NO_NEW_JOB ||
        value === QAJobChangeStatusMap.NO_JOB_CHANGE
      );
    })
    .map((value) => {
      return { value, label: value };
    });

  const onClickIcon = (QACollectorLinkedInURL: string) => {
    window.open(QACollectorLinkedInURL || '', '_blank');
  };

  const qaFormValidation = useCallback(() => {
    // check that linkedin URL contains linkedin.com/in
    if (QALinkedInURL.includes('linkedin.com/in')) {
      setQALinkedInURLError(false);
    } else {
      setQALinkedInURLError(true);
      setValidationError(true);
    }

    // check that qa email is of valid email format

    if (!checkEmailFormat(QANewEmail) && QANewEmail.length > 0) {
      setQANewEmailError(true);
      setValidationError(true);
    } else {
      setQANewEmailError(false);
    }

    // If linkedin URL is not empty then job change status is required
    if (QALinkedInURL.length > 0 && QAJobChangeStatus.length === 0) {
      setQAJobChangeStatusRequiredError(true);
      setValidationError(true);
    } else {
      setQAJobChangeStatusRequiredError(false);
    }

    // if there are no errors then set validationError flag to false so button is not disabled
    if (!QALinkedInURLError && !QANewEmailError && !QAJobChangeStatusRequiredError) {
      setValidationError(false);
    }

    // if LinkedIn URL checkbox checked true then validation flag to false so button is not disabled
    if (QALinkedInURLNotFound === true) {
      setValidationError(false);
    }

    // if LinkedIn URL Not Found marked as true then remove all data and set Job Change Status to None
    if (QALinkedInURLNotFound && (QALinkedInURL.length > 0 || QAJobChangeStatus.length > 0 || QANewEmail.length > 0)) {
      setValidationError(true);
      setRemoveAllDataError(true);
    } else {
      setRemoveAllDataError(false);
    }
  }, [
    QAJobChangeStatus,
    QAJobChangeStatusRequiredError,
    QALinkedInURL,
    QALinkedInURLError,
    QALinkedInURLNotFound,
    QANewEmail,
    QANewEmailError,
  ]);

  useEffect(() => {
    qaFormValidation();
  }, [qaFormValidation]);

  const companyNameDisplay = qaTask?.clientContact?.crmCompanyName || '';
  const emailDisplay = qaTask?.clientContact?.crmEmail || '';
  const titleDisplay = qaTask?.clientContact?.crmTitle || '';
  const contactNameDisplay = getClientContactName(qaTask?.clientContact!);
  const linkedinUrlDisplay = qaTask?.clientContact?.crmLinkedinUrl || '';

  const QAOldLinkedInJob = previousQaTaskAction?.previousLinkedinJob || '';
  const QANewLinkedInJob = previousQaTaskAction?.updatedLinkedinJob || '';
  const isEscalated = qaTask.isEscalated;
  const QAEnrichUserName = `${qaTask.assignedUser?.firstName} ${qaTask.assignedUser?.lastName}`;
  const QANotes = previousQaTaskAction?.notes || '';

  if (!qaTaskAction) {
    return <CircularProgress />;
  }

  return (
    <Box>
      <Box maxWidth="95%" display="flex" flexGrow={1} marginLeft={5} marginTop={6}>
        <Grid container justify="flex-start" direction="row" spacing={5}>
          <Grid item xs={3}>
            <Typography variant="h1">Approve</Typography>
            {isEscalated && (
              <Typography variant="h1" style={{ color: 'red' }}>
                *Escalated*
              </Typography>
            )}
          </Grid>
          <Grid item xs={5}>
            <Typography variant="h5">QA Name: {QAEnrichUserName}</Typography>
          </Grid>
          <Grid item xs={4}>
            {/* TODO implement over due task acount */}
            {/* <Typography variant="h1">{openApproveTasksDue} Tasks Overdue</Typography> */}
          </Grid>
          <Grid container item xs={12} direction="column" justify="flex-start" alignItems="center">
            <Grid container item direction="row" justify="center" alignItems="center">
              <Grid item style={{ width: 'inherit' }}>
                <Grid container direction="row" justify="flex-start" alignItems="center">
                  <Grid item xs={2}>
                    <Typography variant="h6" color="primary">
                      Full Name:
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Box fontWeight="bold">
                      <Typography>{contactNameDisplay}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography variant="h6" color="primary">
                      Generate new email:
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item direction="row" justify="center" alignItems="center">
              <Grid item style={{ width: 'inherit' }}>
                <Grid container direction="row" justify="flex-start" alignItems="center">
                  <Grid item xs={2}>
                    <Typography variant="h6" color="primary">
                      Email:
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Box fontWeight="font">
                      <Typography>{emailDisplay}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      value={QAFirstName}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setQAFirstName(event.target.value);
                      }}
                      helperText="First Name"
                      fullWidth
                      multiline={true}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      value={QALastName}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setQALastName(event.target.value);
                      }}
                      helperText="Last Name"
                      fullWidth
                      multiline={true}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item direction="row" justify="center" alignItems="center">
              <Grid item style={{ width: 'inherit' }}>
                <Grid container direction="row" justify="flex-start" alignItems="center">
                  <Grid item xs={2}>
                    <Typography variant="h6" color="primary">
                      Job Title:
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Box fontWeight="bold">
                      <Typography>{titleDisplay}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={5}>
                    <TextField
                      value={QACompanyDomain}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setQACompanyDomain(event.target.value);
                      }}
                      helperText="New Company Domain"
                      fullWidth
                      multiline={true}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item direction="row" justify="center" alignItems="center">
              <Grid item style={{ width: 'inherit' }}>
                <Grid container direction="row" justify="flex-start" alignItems="center">
                  <Grid item xs={2}>
                    <Typography variant="h6" color="primary">
                      Company Name:
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Box fontWeight="bold">
                      <Typography>{companyNameDisplay}</Typography>
                    </Box>
                  </Grid>
                  <Grid item container direction="row" xs={5}>
                    <Grid item>
                      <QAFindNewEmailButton
                        fullName={contactNameDisplay}
                        firstName={QAFirstName}
                        lastName={QALastName}
                        oldEmail={emailDisplay}
                        companyDomain={QACompanyDomain}
                        id={qaTask.clientContact!.id}
                        setGeneratedEmail={setGeneratedEmail}
                        setIsEmailLoading={setIsEmailLoading}
                        isEmailLoading={isEmailLoading}
                      />
                    </Grid>
                    <Grid item>
                      {isEmailLoading && (
                        <Box marginLeft={1} marginTop={1}>
                          <CircularProgress color="primary" size={20} />
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item direction="row" justify="center" alignItems="center">
              <Grid item style={{ width: 'inherit' }}>
                <Grid container direction="row" justify="flex-start" alignItems="center">
                  <Grid item xs={2}>
                    <Typography variant="h6" color="primary">
                      LinkedIn URL:
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Box fontWeight="bold">
                      <a target="_blank" rel="noopener noreferrer" href={linkedinUrlDisplay}>
                        {linkedinUrlDisplay}
                      </a>
                    </Box>
                  </Grid>
                  <Grid item xs={5}>
                    {generatedEmail === 'No Email Found' ? (
                      <Box fontWeight="bold" color="red">
                        <Typography color="primary">No email found</Typography>
                      </Box>
                    ) : (
                      <Box fontWeight="bold">
                        <Typography color="primary">{generatedEmail}</Typography>
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item direction="row" justify="center" alignItems="center">
              <Grid item style={{ width: 'inherit' }}>
                <Grid container direction="row" justify="flex-start" alignItems="center">
                  <Grid item xs={2}>
                    <Typography variant="h6" color="primary">
                      QA Notes:
                    </Typography>
                  </Grid>
                  <Grid item xs={10}>
                    <Box fontWeight="bold">
                      <Typography>{QANotes}</Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container item xs={12} direction="column" justify="flex-start" alignItems="center">
            <Grid container item direction="row" justify="center" alignItems="center">
              <Grid item style={{ width: 'inherit' }}>
                <Grid container direction="row" justify="flex-start" alignItems="center">
                  <Grid item xs={4}>
                    <Typography variant="h6" color="primary">
                      Found LinkedIn Old Job
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Box fontWeight="bold">
                      <Typography>{QAOldLinkedInJob}</Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid className={classes.grid} xs={12} container item direction="row" justify="center" alignItems="center">
              <Paper className={classes.paper}>
                <Grid container item direction="row" justify="center" alignItems="center">
                  <Grid item style={{ width: 'inherit' }}>
                    <Grid container direction="row" justify="flex-start" alignItems="center">
                      <Grid item xs={4}>
                        <Typography variant="h6" color="primary">
                          Found LinkedIn New Job
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Box fontWeight="bold">
                          <Typography>{QANewLinkedInJob}</Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>

            <Grid container item direction="row" justify="center" alignItems="center">
              <Grid item style={{ width: 'inherit' }}>
                <Grid container direction="row" justify="flex-start" alignItems="center">
                  <Grid item xs={4}>
                    <Typography variant="h6" color="primary">
                      Found LinkedIn URL:
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    LinkedIn URL Not Found
                    <Checkbox
                      onClick={() => {
                        setQALinkedInURLNotFound(!QALinkedInURLNotFound);
                      }}
                      checked={QALinkedInURLNotFound}
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container direction="row">
                      <Grid item xs={1}>
                        <IconButton
                          color="primary"
                          aria-label="linkedin"
                          onClick={() => onClickIcon(QALinkedInURL)}
                          size="small"
                        >
                          <FontAwesomeIcon icon={faLinkedin} size="lg" />
                        </IconButton>
                      </Grid>
                      <Grid item xs={11}>
                        <TextField
                          value={QALinkedInURL}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setQALinkedInURL(event.target.value);
                            qaFormValidation();
                          }}
                          error={QALinkedInURLError}
                          helperText={QALinkedInURLError ? 'Find LinkedIn URL. URL must include "linkedin.com/in"' : ''}
                          fullWidth
                          multiline={true}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item direction="row" justify="center" alignItems="center">
              <Grid item style={{ width: 'inherit' }}>
                <Grid container direction="row" justify="flex-start" alignItems="center">
                  <Grid item xs={4}>
                    <Typography variant="h6" color="primary">
                      Found Job Change Status
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <SelectDropdown
                      options={jobChangeStatusOptions}
                      selectedValue={QAJobChangeStatus || ''}
                      onSelect={onSelectJobChangeStatus}
                    />
                    {QAJobChangeStatusRequiredError ? (
                      <Grid item>
                        <Box color="red">Select dropdown job change status</Box>
                      </Grid>
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item direction="row" justify="center" alignItems="center">
              <Grid item style={{ width: 'inherit' }}>
                <Grid container direction="row" justify="flex-start" alignItems="center">
                  <Grid item xs={4}>
                    <Typography variant="h6" color="primary">
                      Found New Email
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      value={QANewEmail}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setQANewEmail(event.target.value);
                      }}
                      fullWidth
                      error={QANewEmailError}
                      helperText={QANewEmailError ? 'Incorrect Email Format' : ''}
                      multiline={true}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item direction="row" justify="center" alignItems="center">
              <Grid item style={{ width: 'inherit' }}>
                <Grid container direction="row" justify="flex-start" alignItems="center">
                  <Grid item xs={4}>
                    <Typography variant="h6" color="primary">
                      Approver Notes to QA
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      value={QAApproverNotes}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setQAApproverNotes(event.target.value);
                      }}
                      fullWidth
                      multiline={true}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {removeAllDataError ? (
            <Grid container xs={12} direction="column" item justify="center">
              <Box color="red">LinkedIn URL Not Found so remove all data and set Job Change Status to "None"</Box>
            </Grid>
          ) : null}
          <Grid container item justify="center" spacing={7}>
            <Grid item>
              <ConfirmTaskButton
                buttonType={QAButtonType.ESCALATE}
                getQaTask={getQaTask}
                qaTaskAction={qaTaskAction}
                QATaskActionData={{
                  QALinkedInURL,
                  QALinkedInURLNotFound,
                  QAOldLinkedInJob,
                  QANewLinkedInJob,
                  QAJobChangeStatus,
                  QAApproverNotes,
                  actionType: QATaskActionType.ESCALATE,
                  QANewEmail,
                  userId: user?.id || '',
                  userName: `${user?.firstName} ${user?.lastName}`,
                }}
                clearForm={clearForm}
              />
            </Grid>
            <Grid item>
              <ConfirmTaskButton
                buttonType={QAButtonType.INCORRECT}
                getQaTask={getQaTask}
                qaTaskAction={qaTaskAction}
                QATaskActionData={{
                  QALinkedInURL,
                  QALinkedInURLNotFound,
                  QAOldLinkedInJob,
                  QANewLinkedInJob,
                  QAJobChangeStatus,
                  QAApproverNotes,
                  actionType: QATaskActionType.RESOLVE,
                  QANewEmail,
                  userId: user?.id || '',
                  userName: `${user?.firstName} ${user?.lastName}`,
                }}
                clearForm={clearForm}
                validationError={validationError}
              />
            </Grid>
            <Grid item>
              <ConfirmTaskButton
                buttonType={QAButtonType.CORRECT}
                getQaTask={getQaTask}
                qaTaskAction={qaTaskAction}
                QATaskActionData={{
                  QALinkedInURL,
                  QALinkedInURLNotFound,
                  QAOldLinkedInJob,
                  QANewLinkedInJob,
                  QAJobChangeStatus,
                  QAApproverNotes,
                  actionType: QATaskActionType.RESOLVE,
                  QANewEmail,
                  userId: user?.id || '',
                  userName: `${user?.firstName} ${user?.lastName}`,
                }}
                clearForm={clearForm}
                validationError={validationError}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default QAApproverView;
