import { Chip } from '@material-ui/core';
import React from 'react';

import { ChipColor, WarmlyColor } from '../../../../utils/constants';

interface Props {
  role: string
}

const UsersManagementRoleChip: React.FC<Props> = ({ role }) => {

  let chipColor = '';
  switch(role) {
    case 'INTERNAL':
      chipColor = ChipColor.PURPLE;
      break;
    case 'CLIENT':
      chipColor = WarmlyColor.SALMON;
      break;
    case 'TEST':
      chipColor = ChipColor.LIGHT_GRAY;
      break;
    case 'QA_NEW':
      chipColor = ChipColor.MAROON;
      break;
    case 'APPROVER_NEW':
      chipColor = ChipColor.BROWN;
      break;
  };

  return (
    <Chip 
      label={role} 
      style={{ color: 'white', backgroundColor: chipColor }}
    />
  );
}

export default UsersManagementRoleChip;
