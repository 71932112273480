import * as Sentry from '@sentry/browser';
import amplitude from 'amplitude-js';
import firebase from 'firebase/app';

import {
  AMPLITUDE_API_KEY,
  AnalyticsEvent,
  AnalyticsParameter,
  CURRENT_ENVIRONMENT,
  Environment,
  SENTRY_DSN,
  UserRole,
} from '../utils/constants';

const firebaseAnalytics = firebase.analytics();

export const initializeLogging = () => {
  if (CURRENT_ENVIRONMENT === Environment.Development) {
    console.log('Dev environment detected - errors are not reported to Sentry');
  } else {
    Sentry.init({
      dsn: SENTRY_DSN,
      environment: CURRENT_ENVIRONMENT,
      // Note that the SENTRY_RELEASE env variable is set in package.json, it is NOT in .env and therefore
      // is always undefined in dev environment
      release: process.env.REACT_APP_SENTRY_RELEASE,
    });
  }
};

export const initializeAmplitude = () => {
  if (CURRENT_ENVIRONMENT === Environment.Development) {
    console.log('Dev environment detected - metrics not reported to Amplitude');
    amplitude.getInstance().init(AMPLITUDE_API_KEY);
  } else {
    amplitude.getInstance().init(AMPLITUDE_API_KEY);
  }
};

interface AmplitudeEvent {
  userId: string;
  event: string;
  userProperties?: any;
  eventProperties?: any;
}

export const setAmplitudeEvent = ({ userId, event, userProperties, eventProperties = {} }: AmplitudeEvent) => {
  if (CURRENT_ENVIRONMENT === Environment.Development) {
    console.info(
      'Amplitude event sent - ',
      event,
      ' for ',
      userId,
      ' user properties ',
      userProperties,
      ' event properties ',
      eventProperties
    );
    amplitude.setUserId(userId);
    amplitude.getInstance().logEvent(event, eventProperties);
    if (userProperties) {
      amplitude.getInstance().setUserProperties(userProperties);
    }
  } else {
    amplitude.setUserId(userId);
    amplitude.getInstance().logEvent(event, eventProperties);
    if (userProperties) {
      amplitude.getInstance().setUserProperties(userProperties);
    }
  }
};

export const setLogUser = (user: Sentry.User) => Sentry.setUser(user);

export const logError = (error: Error | Papa.ParseError, description: string = 'No description') => {
  if (CURRENT_ENVIRONMENT === Environment.Development) {
    console.info(description);
    console.error(error);
  } else {
    Sentry.captureException(error, {
      extra: {
        description,
      },
    });
  }
};

interface UserLoginEvent {
  userId: string;
  firstName: string;
  lastName: string;
  clientName: string;
  clientId: string;
  userType: UserRole;
  paymentStatus: string;
  status: string;
  fullName: string;
  companyName_fullName: string;
}
export const analyticsSetUserOnLogin = ({
  userId,
  firstName,
  lastName,
  clientName,
  clientId,
  userType,
  paymentStatus,
  status,
  fullName,
  companyName_fullName,
}: UserLoginEvent) => {
  firebaseAnalytics.setUserId(userId);

  firebaseAnalytics.setUserProperties({
    [AnalyticsParameter.FIRST_NAME]: firstName,
    [AnalyticsParameter.LAST_NAME]: lastName,
    [AnalyticsParameter.CLIENT_NAME]: clientName,
    [AnalyticsParameter.CLIENT_ID]: clientId,
    [AnalyticsParameter.USER_TYPE]: userType,
    [AnalyticsParameter.PAYMENT_STATUS]: paymentStatus,
    [AnalyticsParameter.STATUS]: status,
    [AnalyticsParameter.FULL_NAME]: fullName,
    [AnalyticsParameter.COMPANY_NAME_FULL_NAME]: companyName_fullName,
  });
};

interface UserSignUpEvent {
  firstName: string;
  lastName: string;
  clientName: string;
  email: string;
  companyName_fullName: string;
}
export const analyticsSignUpEvent = ({
  firstName,
  lastName,
  clientName,
  email,
  companyName_fullName,
}: UserSignUpEvent) => {
  firebaseAnalytics.logEvent(AnalyticsEvent.SIGN_UP, {
    [AnalyticsParameter.FIRST_NAME]: firstName,
    [AnalyticsParameter.LAST_NAME]: lastName,
    [AnalyticsParameter.CLIENT_NAME]: clientName,
    [AnalyticsParameter.EMAIL]: email,
    [AnalyticsParameter.COMPANY_NAME_FULL_NAME]: companyName_fullName,
  });
};
