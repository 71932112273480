import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faPeopleArrows, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Box, Divider, Grid, Link, makeStyles, Typography } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Skeleton } from '@material-ui/lab';
import React, { useContext, useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

import { LoadingAndAlertContext } from '../..';
import { ClientContactExternalFragment, QueryWarmCompanyQuery } from '../../graphql';
import { GQLHooks } from '../../graphql/hasura/react';
import { AlertSeverity } from '../../utils/constants';
import { getClientContactName } from '../../utils/functions';
import { ClientContext } from '../Main';

const FIXED_HEIGHT = 40;

const useStyles = makeStyles(() => ({
  avatar: {
    height: FIXED_HEIGHT,
    width: FIXED_HEIGHT,
  },
}));

interface Props {
  warmCompany: QueryWarmCompanyQuery['warmCompany'][number];
}

const CompanyContactItem: React.FC<Props> = ({ warmCompany }) => {
  const { setSnackbarAlertData } = useContext(LoadingAndAlertContext);
  const { selectedClientId } = useContext(ClientContext);
  const classes = useStyles();

  const [expanded, setExpanded] = useState(false);
  const [querySent, setQuerySent] = useState(false);
  const [clientContacts, setClientContacts] = useState<ClientContactExternalFragment[]>();
  const [
    getClientContacts,
    { objects: clientContactsFromQuery, loading: loadingClientContacts },
  ] = GQLHooks.Fragments.ClientContactExternal.useQueryObjectsLazy();

  useEffect(() => {
    if (expanded && !querySent) {
      setQuerySent(true);
      getClientContacts({
        variables: {
          where: {
            contactData: {
              jobs: {
                companyId: {
                  _eq: warmCompany.companyId,
                },
              },
            },
            clientId: {
              _eq: selectedClientId,
            },
          },
        },
      });
    }
  }, [expanded, getClientContacts, selectedClientId, warmCompany.companyId, querySent]);

  useEffect(() => {
    if (clientContactsFromQuery.length && !loadingClientContacts) {
      setClientContacts(clientContactsFromQuery);
    }
  }, [clientContactsFromQuery, loadingClientContacts]);

  const companyLinkedinUrl = warmCompany.companyLinkedinUrl;

  const CompanyContactItemHeaders = () => {
    return (
      <Grid container direction="row" alignItems="center" justify="space-between" spacing={3}>
        <Grid item md={1} xs={10}>
          <Box fontWeight="bold" textAlign="center">
            Full Name
          </Box>
        </Grid>
        <Grid item md={2} xs={10}>
          <Box fontWeight="bold" textAlign="center">
            {warmCompany.companyPrimaryName} Job Title
          </Box>
        </Grid>

        <Grid item md={2} xs={10}>
          <Box fontWeight="bold" textAlign="center">
            Employment Dates
          </Box>
        </Grid>

        <Grid item md={2} xs={3}>
          <Box fontWeight="bold" textAlign="center">
            Reach Out
          </Box>
        </Grid>

        <Grid item md={2} xs={10}>
          <Box fontWeight="bold" textAlign="center">
            Current Title
          </Box>
        </Grid>
        <Grid item md={1} xs={10}>
          <Box fontWeight="bold" textAlign="center">
            Current Company
          </Box>
        </Grid>
        <Grid item md={2} xs={10}>
          <Box fontWeight="bold" textAlign="center">
            Employment Dates
          </Box>
        </Grid>
      </Grid>
    );
  };

  return (
    <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
        <Grid container justify="space-between" alignItems="center">
          <Grid item md={1} xs={6}>
            <Box>
              <Avatar variant="square" className={classes.avatar} src={warmCompany.companyLogoUrl || undefined}>
                <Typography variant="subtitle1">{warmCompany.companyPrimaryName}</Typography>
              </Avatar>
            </Box>
          </Grid>
          <Grid item md={2} xs={6}>
            <Typography>{warmCompany.companyPrimaryName}</Typography>
          </Grid>
          <Grid item md={1} xs={6}>
            {warmCompany.clientContactCount ? (
              <Typography>
                {warmCompany.clientContactCount} {warmCompany.clientContactCount > 1 ? `contacts` : 'contact'}
              </Typography>
            ) : null}
          </Grid>
          <Grid item md={1} xs={6}>
            <Link
              href={companyLinkedinUrl || undefined}
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e: React.MouseEvent) => {
                if (!companyLinkedinUrl) {
                  e.preventDefault();
                }
              }}
            >
              <Box
                aria-label="linked-in"
                marginBottom={1}
                marginLeft={3}
                color={companyLinkedinUrl ? undefined : 'grey.200'}
              >
                <FontAwesomeIcon icon={faLinkedin} size="2x" />
              </Box>
            </Link>
          </Grid>
          <Grid item container justify="flex-end" md={7} xs={6}>
            <Grid item>
              { !expanded && <Typography>Expand to see Warm Intros</Typography> }
            </Grid>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container direction="column" alignItems="center">
          <CompanyContactItemHeaders />
          <Grid item>
            <Box m={3}>
              <Divider variant="middle" absolute />
            </Box>
          </Grid>
          <Grid container direction="row" alignItems="center">
            <Grid item md={12}>
              {loadingClientContacts || !clientContacts ? (
                <Skeleton variant="rect" height={80} />
              ) : (
                clientContacts.map((clientContact) => {
                  const warmCompanyJob = clientContact.contactData?.jobs.find((job) => {
                    return job.companyId === warmCompany.companyId;
                  });
                  const currentJob = clientContact.contactData?.currentJob;
                  const contactLinkedinUrl = clientContact.linkedinUrl || clientContact.contactData?.linkedinUrl;

                  const contactEmails = clientContact.contactData?.contactEmails;
                  const validContactEmailsSortedDesc = contactEmails
                    ?.filter((email) => email.isValid === true)
                    .sort((emailA, emailB) => {
                      return +new Date(emailB.createdAt) - +new Date(emailA.createdAt);
                    });

                  let latestValidEmail = clientContact.crmEmail;
                  let crmEmailIsValid = Boolean(clientContact.crmEmail && clientContact.crmEmailIsValid);

                  if (!crmEmailIsValid) {
                    const lastestValidContactEmail = validContactEmailsSortedDesc?.[0];

                    if (lastestValidContactEmail) {
                      latestValidEmail = lastestValidContactEmail.email;
                    }
                  }

                  return (
                    <Grid
                      key={clientContact.id}
                      container
                      direction="row"
                      alignItems="center"
                      justify="space-between"
                      spacing={3}
                    >
                      <Grid item md={1} xs={10}>
                        <Typography align="center">{getClientContactName(clientContact)}</Typography>
                      </Grid>

                      <Grid item md={2} xs={10}>
                        <Typography align="center">{warmCompanyJob?.title || '-'}</Typography>
                      </Grid>

                      <Grid item md={2} xs={10}>
                        <Typography align="center">
                          {warmCompanyJob?.startDateText || 'unknown'}
                          {' - '}
                          {warmCompanyJob?.endDateText ? warmCompanyJob.endDateText : null}
                        </Typography>
                      </Grid>

                      <Grid container justify="center" alignItems="center" direction="row" item md={2} xs={3}>
                        <Grid item>
                          <Link
                            href={contactLinkedinUrl || undefined}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={(e: React.MouseEvent) => {
                              if (!contactLinkedinUrl) {
                                e.preventDefault();
                              }
                            }}
                          >
                            <Box aria-label="linked-in" color={contactLinkedinUrl ? undefined : 'grey.200'}>
                              <FontAwesomeIcon icon={faLinkedin} size="2x" />
                            </Box>
                          </Link>
                        </Grid>
                        <Grid item>
                          <CopyToClipboard
                            text={latestValidEmail || ''}
                            onCopy={() => {
                              if (latestValidEmail) {
                                const alertData: AlertData = {
                                  severity: AlertSeverity.SUCCESS,
                                  message: `Email copied to clipboard!`,
                                };

                                setSnackbarAlertData(alertData);
                              }
                            }}
                          >
                            <Box
                              marginLeft={3}
                              aria-label="email"
                              style={{ cursor: 'pointer' }}
                              color={latestValidEmail ? undefined : 'grey.200'}
                            >
                              <FontAwesomeIcon icon={faEnvelope} size="2x" />
                            </Box>
                          </CopyToClipboard>
                        </Grid>
                        {
                          <Grid item>
                            <Box marginLeft={3} aria-label="linked-in">
                              <FontAwesomeIcon
                                icon={currentJob?.companyId === warmCompany.companyId ? faUser : faPeopleArrows}
                                size="2x"
                              />
                            </Box>
                          </Grid>
                        }
                      </Grid>

                      <Grid item md={2} xs={10}>
                        <Typography align="center">{currentJob?.title || '-'}</Typography>
                      </Grid>
                      <Grid item md={1} xs={10}>
                        <Typography align="center">{currentJob?.companyName || '-'}</Typography>
                      </Grid>
                      <Grid item md={2} xs={10}>
                        <Typography align="center">
                          {currentJob?.startDateText || 'unknown'}
                          {' - '}
                          {currentJob?.endDateText ? currentJob.endDateText : null}
                        </Typography>
                      </Grid>
                    </Grid>
                  );
                })
              )}
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default CompanyContactItem;
