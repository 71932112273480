import MaterialTable from 'material-table';
import React from 'react';

import { tableIcons } from '../../../../components/icons';
import { ClientInternalFragment, QueryClientsClientContactCountQuery } from '../../../../graphql';
import { EmailCategory } from '../../../../utils/constantsAll';
import ClientManagementActions from './ClientManagementActions';

interface Props {
  clients: ClientInternalFragment[];
  clientsClientContactCount: QueryClientsClientContactCountQuery['client'];
}

enum ClientsManagementRowField {
  Name = 'Name',
  CodeName = 'CodeName',
  IntegrationType = 'IntegrationType',
  TotalContactCount = 'TotalContactCount',
  QaAssignedContactCount = 'QaAssignedContactCount',
  LastWeeklyReportSentAt = 'LastWeeklyReportSentAt',
  Client = 'Client',
}

type ClientsManagementRowData = Record<ClientsManagementRowField, string | number | undefined | null | ClientInternalFragment>;

const ClientsManagementTable: React.FC<Props> = ({ clients, clientsClientContactCount }) => {
  const rows: ClientsManagementRowData[] = clients.map((client) => {
    const totalContactCount = clientsClientContactCount.find((summaryClient) => summaryClient.id === client.id)
      ?.allClientContacts.aggregate?.totalCount;

    const qaAssignedContactCount = clientsClientContactCount.find((summaryClient) => summaryClient.id === client.id)
      ?.clientContacts.aggregate?.totalCount;

    const weeklyClientReports = client.clientEmailReports.filter(
      (emailReport) => emailReport.emailCategory === EmailCategory.CLIENT_WEEKLY_REPORT
    );

    const lastWeeklyReport = weeklyClientReports?.sort(
      (reportA, reportB) => +new Date(reportB.sentAt) - +new Date(reportA.sentAt)
    )[0];

    return {
      [ClientsManagementRowField.Name]: client.name,
      [ClientsManagementRowField.CodeName]: client.codeName,
      [ClientsManagementRowField.IntegrationType]: client.integrationType || 'none / csv',
      [ClientsManagementRowField.TotalContactCount]: totalContactCount,
      [ClientsManagementRowField.QaAssignedContactCount]: qaAssignedContactCount,
      [ClientsManagementRowField.LastWeeklyReportSentAt]: lastWeeklyReport?.sentAt,
      [ClientsManagementRowField.Client]: client,
    };
  });

  return (
    <MaterialTable<ClientsManagementRowData>
      icons={tableIcons}
      columns={[
        {
          title: 'Name',
          field: ClientsManagementRowField.Name,
          cellStyle: { width: 200 },
          headerStyle: { width: 200 },
        },
        {
          title: 'Code Name',
          field: ClientsManagementRowField.CodeName,
        },
        {
          title: 'Integration',
          field: ClientsManagementRowField.IntegrationType,
        },
        {
          title: 'All Contacts Count',
          field: ClientsManagementRowField.TotalContactCount,
          type: 'numeric',
          filtering: false,
          render: rowData => rowData.TotalContactCount ? rowData.TotalContactCount.toLocaleString() : '-',
        },
        {
          title: 'QA Assigned Contacts Count',
          field: ClientsManagementRowField.QaAssignedContactCount,
          type: 'numeric',
          filtering: false,
          render: rowData => rowData.QaAssignedContactCount ? rowData.QaAssignedContactCount?.toLocaleString() : '-',
        },
        {
          title: 'Last Weekly Report',
          field: ClientsManagementRowField.LastWeeklyReportSentAt,
          type: 'datetime',
          filtering: false,
        },
        {
          title: 'Actions',
          render: (rowData) => {
            return <ClientManagementActions client={rowData.Client as ClientInternalFragment} />;
          },
          sorting: false,
          filtering: false,
          cellStyle: { width: 50 },
          headerStyle: { width: 50, textAlign: 'center' },
        },
      ]}
      data={rows}
      title="Clients Management"
      options={{
        pageSize: 20,
        filtering: true,
      }}
    />
  );
};

export default ClientsManagementTable;
