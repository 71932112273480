import { faCheckSquare, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Hidden,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core';
import React, { useState } from 'react';

import { logError } from '../../modules/analytics';
import { initiateHubspotIntegration } from '../../modules/cloudFunctions';
import {
  HUBSPOT_LOGO,
  HubspotURL,
  WARMLY_EMAIL,
  WARMLY_LOGO_HORIZONTAL_COLOR,
  WarmlyColor,
} from '../../utils/constants';

interface Props {
  showHubspotInitializeModal: boolean;
  setShowHubspotInitializeModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const HubspotInitializeModal: React.FC<Props> = ({ showHubspotInitializeModal, setShowHubspotInitializeModal }) => {
  const [isLoading, setIsLoading] = useState(false);
  const onClose = () => {
    setShowHubspotInitializeModal(false);
  };

  const onClickConfirm = async () => {
    setIsLoading(true);
    try {
      await initiateHubspotIntegration();
    } catch (err) {
      logError(err, `Error initializing HubSpot integration`);
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={showHubspotInitializeModal} onClose={onClose} maxWidth="md">
      <DialogTitle disableTypography>
        <Typography variant="h3">Sync HubSpot with Warmly</Typography>
        <Typography variant="h5">Empower your team with enriched data and timely updates</Typography>
      </DialogTitle>
      <DialogContent>
        <Box padding={2}>
          <Grid container spacing={3}>
            <Hidden xsDown>
              <Grid container item justify="space-around" alignItems="center" sm={3} md={6}>
                <Grid container item justify="center" xs={12}>
                  <img alt="hubspot-logo" src={HUBSPOT_LOGO} width="50%" />
                </Grid>

                <Grid container item xs={12} justify="center">
                  <FontAwesomeIcon size="3x" icon={faSyncAlt} color={WarmlyColor.DARK_BLUE} />
                </Grid>

                <Grid container item xs={12} justify="center">
                  <img alt="warmly-logo" src={WARMLY_LOGO_HORIZONTAL_COLOR} width="50%" />
                </Grid>
              </Grid>
            </Hidden>

            <Grid container item xs={12} sm={8} md={6}>
              <Box marginTop={2}>
                <Typography>
                  For the integration process, we highly recommend that you use a HubSpot Super Admin account. We will:
                </Typography>
              </Box>
              <List dense={true}>
                <ListItem>
                  <ListItemIcon>
                    <FontAwesomeIcon size="lg" icon={faCheckSquare} color={WarmlyColor.DARK_BLUE} />
                  </ListItemIcon>
                  <ListItemText
                    primary="Ask for Access to Your Contacts"
                    secondary={
                      <Link href={HubspotURL.USER_PERMISSIONS_GUIDE} target="_blank" rel="noopener noreferrer">
                        Do I have permission? Open new tab for help page.
                      </Link>
                    }
                  />
                </ListItem>
              </List>

              <Box marginTop={2}>
                <Typography>
                  * Please note that it may take 10 - 15 minutes for all of your contacts to sync in from HubSpot
                </Typography>
              </Box>

              <Box marginTop={2} marginBottom={1} fontWeight="bold">
                Questions? We can help! We’re here for you anytime at{' '}
                <Link
                  href={`https://mail.google.com/mail/?view=cm&fs=1&to=${WARMLY_EMAIL.CSM}&su=[Warmly] Question about Salesforce Integration`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {WARMLY_EMAIL.CSM}
                </Link>
              </Box>
            </Grid>
          </Grid>

          <Grid container direction="row" justify="flex-end" spacing={3}>
            <Grid item>
              <Button variant="outlined" color="primary" onClick={onClose}>
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" color="primary" onClick={onClickConfirm} disabled={isLoading}>
                Confirm & Continue
              </Button>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default HubspotInitializeModal;
