import { Box, Grid } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { NavigationPath, WARMLY_LOGO_HORIZONTAL_COLOR } from '../utils/constants';
import { AuthContext } from './auth/AuthProvider';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      boxShadow: 'none',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      borderBottom: `solid 1px ${theme.palette.grey[200]}`,
    },
    button: {
      color: theme.palette.grey[500],
      fontSize: '1rem',
      textTransform: 'none',
    },
  })
);

interface Props {
  hideButtons?: boolean
}

const NavBar: React.FC<Props> = ({ hideButtons }) => {
  const { authUser } = useContext(AuthContext);
  const classes = useStyles();
  const history = useHistory();

  const login = () => {
    history.push(NavigationPath.LOGIN);
  };

  const logout = () => {
    history.push(NavigationPath.LOGOUT);
  };

  const signUp = () => {
    history.push(NavigationPath.SIGN_UP);
  };

  const returnToHomepage = () => {
    history.push(NavigationPath.MAIN);
  };

  return (
    <AppBar position="static" color="transparent" className={classes.root}>
      <Toolbar>
        <Grid container justify="space-between">
          <Grid item xs={5} sm={4} md={3}>
            <Box marginY={1} onClick={returnToHomepage} style={{ cursor: 'pointer' }}>
              <img alt="warmly-logo" src={WARMLY_LOGO_HORIZONTAL_COLOR} width="50%" />
            </Box>
          </Grid>
          {!hideButtons && (
            !authUser ? (
              <Grid container item xs={5} sm={6} justify="flex-end" alignItems="center">
                <Button size="large" onClick={login} className={classes.button}>
                  Login
                </Button>
                <Button size="large" onClick={signUp} className={classes.button}>
                  Sign Up
                </Button>
              </Grid>
            ) : (
              <Grid container item xs={5} sm={6} justify="flex-end" alignItems="center">
                <Button size="large" onClick={logout} className={classes.button}>
                  Log Out
                </Button>
              </Grid>
            )
          )}
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

export default NavBar;
