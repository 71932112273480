import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Badge, Box, Divider, Grid, Link, makeStyles, Paper, Tooltip } from '@material-ui/core';
import React from 'react';

import { ClientContactExternalFragment } from '../../../graphql';
import {
  CrmContactEnrichmentType,
  CURRENT_ENVIRONMENT,
  Environment,
  JOBCHANGE_DOTTED_LINE,
} from '../../../utils/constants';
import { extractLinkedinIdFromLinkedinUrl, getClientContactName } from '../../../utils/functions';

const FIXED_HEIGHT = 76;
const FIXED_HEIGHT_EXPANDED = 140;

const HEIGHT_TRANSITION = 'height 0.2s ease-in';

const useStyles = makeStyles(() => ({
  paper: {
    width: '100%',
    height: FIXED_HEIGHT,
    transition: HEIGHT_TRANSITION,
  },
  paper_expanded: {
    width: '100%',
    height: FIXED_HEIGHT_EXPANDED,
    transition: HEIGHT_TRANSITION,
  },
  row: {
    height: '100%',
    transition: HEIGHT_TRANSITION,
  },
  avatar: {
    height: FIXED_HEIGHT,
    width: FIXED_HEIGHT,
  },
}));

interface ItemDataProps {
  onClick?: () => void;
  changed?: boolean;
  expanded?: boolean;
  currentDataValue?: string;
  previousDataValue?: string;
}

const ItemDataContainer: React.FC<ItemDataProps> = ({
  onClick = () => {},
  changed = false,
  expanded = false,
  currentDataValue,
  previousDataValue,
}) => {
  return (
    <Box
      style={{
        cursor: changed ? 'pointer' : undefined,
        transition: HEIGHT_TRANSITION,
      }}
      onClick={changed ? onClick : () => {}}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      bgcolor={changed ? 'secondary.light' : ''}
      width="100%"
      height={expanded ? FIXED_HEIGHT_EXPANDED - 2 : FIXED_HEIGHT - 2}
    >
      {expanded && changed && previousDataValue && (
        <>
          <Box textAlign="center" color="text.disabled">
            {previousDataValue}
          </Box>
          <Box marginY={1}>
            <img alt="dotted-line" src={JOBCHANGE_DOTTED_LINE} width="1" height="25" />
          </Box>
        </>
      )}
      <Box textAlign="center" style={{ overflowWrap: 'anywhere' }}>
        {currentDataValue || '-'}
      </Box>
    </Box>
  );
};

interface EnrichedCrmProps {
  clientContact: ClientContactExternalFragment;
  expanded?: boolean;
  toggleExpanded: () => void;
}

const EnrichedCrmItem: React.FC<EnrichedCrmProps> = ({ clientContact, expanded, toggleExpanded }) => {
  const classes = useStyles();
  const enrichmentHistories = clientContact.enrichmentHistories.slice().sort((historyA, historyB) => {
    return +new Date(historyB.createdAt) - +new Date(historyA.createdAt);
  });

  const CategoryDivider = () => <Divider orientation="vertical" style={{ height: expanded ? '100%' : '70%' }} />;

  const onClickItemData = () => {
    toggleExpanded();
  };

  const contactData = clientContact.contactData;

  const crmSyncedLinkedin = enrichmentHistories.find(
    (enrichmentHistory) => enrichmentHistory.enrichmentType === CrmContactEnrichmentType.LINKEDIN_URL
  )?.updatedValue;
  const crmSyncedCompayName = enrichmentHistories.find(
    (enrichmentHistory) => enrichmentHistory.enrichmentType === CrmContactEnrichmentType.COMPANY_NAME
  )?.updatedValue;
  const crmSyncedTitle = enrichmentHistories.find(
    (enrichmentHistory) => enrichmentHistory.enrichmentType === CrmContactEnrichmentType.TITLE
  )?.updatedValue;
  const crmSyncedEmail = enrichmentHistories.find(
    (enrichmentHistory) => enrichmentHistory.enrichmentType === CrmContactEnrichmentType.EMAIL
  )?.updatedValue;

  const crmLinkedin = crmSyncedLinkedin || clientContact.crmLinkedinUrl || '';
  const crmCompanyName = crmSyncedCompayName || clientContact.crmCompanyName || '';
  const crmTitle = crmSyncedTitle || clientContact.crmTitle || '';

  const linkedinUpdated = Boolean(
    clientContact.linkedinId && clientContact.linkedinId !== extractLinkedinIdFromLinkedinUrl(crmLinkedin)
  );
  const companyNameUpdated = Boolean(
    contactData?.currentJob?.companyName && contactData.currentJob.companyName !== crmCompanyName
  );
  const titleUpdated = Boolean(
    contactData?.currentJob?.title && contactData.currentJob.title.trim() !== crmTitle.trim()
  );
  // contactData should always have a linkedInURL, but we use a fallback just in case
  const linkedinUrl = contactData?.linkedinUrl || clientContact.linkedinUrl || clientContact.crmLinkedinUrl;

  const linkedInTooltipText =
    linkedinUrl && linkedinUpdated
      ? `Previous: ${clientContact.crmLinkedinUrl || 'no LinkedIn'} ➡️ Updated: ${linkedinUrl}`
      : '';

  const contactEmails = contactData?.contactEmails;
  const validContactEmailsSortedDesc = contactEmails
    ?.filter((email) => email.isValid === true)
    .sort((emailA, emailB) => {
      return +new Date(emailB.createdAt) - +new Date(emailA.createdAt);
    });

  let crmEmailIsValid = Boolean(clientContact.crmEmail && clientContact.crmEmailIsValid);
  let emailUpdated = false;
  const lastestValidContactEmail = validContactEmailsSortedDesc?.[0];

  if (
    crmEmailIsValid === false &&
    lastestValidContactEmail?.email &&
    lastestValidContactEmail.email !== clientContact.crmEmail &&
    lastestValidContactEmail.email !== crmSyncedEmail
  ) {
    emailUpdated = true;
  }

  if (!linkedinUpdated && !companyNameUpdated && !titleUpdated && !emailUpdated) {
    // If there are no detected changes, or if the data has already been synced back, we never expand the row
    expanded = false;
  }

  return (
    <Grid container style={{ marginBottom: 3 }}>
      <Grid item xs>
        <Paper variant="outlined" className={expanded ? classes.paper_expanded : classes.paper}>
          <Grid container wrap="nowrap" direction="row" alignItems="center" className={classes.row}>
            <Grid container item alignItems="center" xs={3}>
              <Grid item style={{ width: FIXED_HEIGHT }}>
                <Avatar variant="square" className={classes.avatar} src={contactData?.profilePhotoUrl || undefined}>
                  {getClientContactName(clientContact, true)}
                </Avatar>
              </Grid>
              <Grid item xs>
                <Box textAlign="center">
                  <Box fontWeight="bold">{getClientContactName(clientContact)}</Box>
                </Box>
              </Grid>
            </Grid>

            <CategoryDivider />

            <Grid container item direction="column" justify="center" alignItems="center" xs={3}>
              <ItemDataContainer
                onClick={onClickItemData}
                changed={emailUpdated}
                expanded={expanded}
                currentDataValue={
                  (emailUpdated ? lastestValidContactEmail?.email : (crmSyncedEmail || clientContact.crmEmail)) || undefined
                }
                previousDataValue={crmSyncedEmail || clientContact.crmEmail || '-'}
              />
            </Grid>

            <CategoryDivider />

            <Grid container item direction="column" justify="center" alignItems="center" xs={2}>
              <ItemDataContainer
                onClick={onClickItemData}
                changed={companyNameUpdated}
                expanded={expanded}
                currentDataValue={companyNameUpdated ? contactData?.currentJob?.companyName! : crmCompanyName}
                previousDataValue={clientContact.crmCompanyName || '-'}
              />
            </Grid>

            <CategoryDivider />

            <Grid
              container
              item
              direction="column"
              justify="center"
              alignItems="center"
              xs={CURRENT_ENVIRONMENT === Environment.Production ? 4 : 3}
            >
              <ItemDataContainer
                onClick={onClickItemData}
                changed={titleUpdated}
                expanded={expanded}
                currentDataValue={titleUpdated ? contactData?.currentJob?.title! : crmTitle}
                previousDataValue={crmTitle || '-'}
              />
            </Grid>

            {CURRENT_ENVIRONMENT !== Environment.Production && (
              <Grid container item direction="column" justify="center" alignItems="center" xs={1}>
                <ItemDataContainer
                  onClick={onClickItemData}
                  changed={titleUpdated}
                  expanded={expanded}
                  currentDataValue={
                    clientContact.currentJobChange?.ownerUser
                      ? `${clientContact.currentJobChange.ownerUser.firstName} ${clientContact.currentJobChange.ownerUser.lastName}`
                      : 'unassigned'
                  }
                  previousDataValue={
                    clientContact.ownerUser
                      ? `${clientContact.ownerUser.firstName} ${clientContact.ownerUser.lastName}`
                      : 'unassigned'
                  }
                />
              </Grid>
            )}

            <CategoryDivider />

            <Grid item>
              <Link
                href={linkedinUrl ? linkedinUrl : undefined}
                target="_blank"
                rel="noopener noreferrer"
                onClick={(e: React.MouseEvent) => {
                  if (!linkedinUrl) {
                    e.preventDefault();
                  }
                }}
              >
                <Box
                  marginX={2}
                  aria-label="linked-in"
                  style={{ cursor: 'pointer' }}
                  color={!linkedinUrl ? 'grey.300' : undefined}
                >
                  {linkedinUpdated ? (
                    <Tooltip title={linkedInTooltipText} aria-label="add">
                      <Badge variant="dot" color="secondary">
                        <FontAwesomeIcon icon={faLinkedin} size="2x" />
                      </Badge>
                    </Tooltip>
                  ) : (
                    <FontAwesomeIcon icon={faLinkedin} size="2x" />
                  )}
                </Box>
              </Link>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default EnrichedCrmItem;
