import Axios from 'axios';

import { gaeApiURL } from '../utils/constants';
import { getAuthToken } from './auth';

export type UserSignUpData = {
  firstName: string;
  lastName: string;
  email: string;
  clientName?: string;
  firebaseUid: string;
  userInviteId?: string;
};

export const signUpUser = async (userSignUpData: UserSignUpData) => {
  const functionURL = gaeApiURL.user.signUp;

  const authToken = await getAuthToken();

  const body = userSignUpData;

  return Axios.post(functionURL, body, {
    headers: {
      Authorization: 'Bearer ' + authToken,
    },
  });
};
