import { faClock } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { Alert } from '@material-ui/lab';
import React, { useContext, useState } from 'react';

import { GQLHooks } from '../../../graphql/hasura/react';
import { initiateHubspotSyncContacts, initiateSalesforceSyncContacts } from '../../../modules/cloudFunctions';
import { AlertSeverity, IntegrationType, WARMLY_EMAIL, WarmlyColor } from '../../../utils/constants';
import { getApiErrorMessage } from '../../../utils/errors';
import { ClientContext } from '../../Main';

const estimateSyncTime = (contactCount: number) => {
  let estimateText = '';

  if (contactCount > 50000) {
    estimateText = 'about 30+ minutes';
  } else if (contactCount > 5000) {
    estimateText = 'about 10-20 minutes';
  } else {
    estimateText = 'a few minutes';
  }

  return estimateText;
};

const SyncContacts: React.FC = () => {
  const { selectedClient } = useContext(ClientContext);

  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [updateHubspotIntegration] = GQLHooks.Fragments.HubspotIntegration.useUpdateById();
  const [updateSalesforceIntegration] = GQLHooks.Fragments.SalesforceIntegration.useUpdateById();

  let contactCount: number | undefined;

  if (selectedClient.integrationType === IntegrationType.HubSpot) {
    contactCount = selectedClient.hubspotIntegration?.totalContactCount || 0;
  } else if (selectedClient.integrationType === IntegrationType.Salesforce) {
    contactCount = selectedClient.salesforceIntegration?.totalContactCount || 0;
  }

  // const getTitleMessge = () => {
  //   if (selectedClient.integrationType === IntegrationType.HubSpot) {
  //     const contactListIdsToSync = (selectedClient.hubspotIntegration?.mapping as IntegrationMapping)?.selectedListIds;

  //     if (contactListIdsToSync?.length) {
  //       return (
  //         <>
  //           <em>{contactListIdsToSync.length}</em> contact list(s) selected and ready to sync
  //         </>
  //       );
  //     } else if (contactCount) {
  //       return (
  //         <>
  //           <em>{contactCount.toLocaleString()}</em> contacts detected and ready to sync
  //         </>
  //       );
  //     }
  //   } else if (selectedClient.integrationType === IntegrationType.Salesforce && contactCount) {
  //     return (
  //       <>
  //         <em>{contactCount.toLocaleString()}</em> contacts detected and ready to sync
  //       </>
  //     );
  //   }
  // };

  const onClickConfirm = async () => {
    try {
      setIsLoading(true);
      if (selectedClient.integrationType === IntegrationType.HubSpot) {
        await initiateHubspotSyncContacts(selectedClient.hubspotIntegration?.id!);
      } else if (selectedClient.integrationType === IntegrationType.Salesforce) {
        await initiateSalesforceSyncContacts(selectedClient.salesforceIntegration?.id!);
      }
    } catch (err) {
      setErrorMessage(getApiErrorMessage(err));
      setIsLoading(false);
    }
  };

  const onClickBack = async () => {
    try {
      setIsLoading(true);
      if (selectedClient.integrationType === IntegrationType.HubSpot) {
        await updateHubspotIntegration({
          hubspotIntegrationId: selectedClient.hubspotIntegration!.id,
          set: {
            mapping: null,
          },
        });
      } else if (selectedClient.integrationType === IntegrationType.Salesforce) {
        await updateSalesforceIntegration({
          salesforceIntegrationId: selectedClient.salesforceIntegration!.id,
          set: {
            mapping: null,
          },
        });
      }
    } catch (err) {
      setErrorMessage(getApiErrorMessage(err));
      setIsLoading(false);
    }
  };

  return (
    <>
      <Box display="flex" justifyContent="center">
        <Grid container direction="column" item xs={9}>
          <Grid item style={{ paddingBottom: 20 }}>
            <Typography component="div" variant="h4">
              <Box fontWeight="bold">Step 2 – Sync data</Box>
            </Typography>
          </Grid>
          {/* <Grid item>
          <Typography variant="subtitle2">{getTitleMessge()}</Typography>
          </Grid> */}
          <Paper>
            <Grid item>
              {contactCount ? (
                <List>
                  <ListItem>
                    <ListItemIcon>
                      <FontAwesomeIcon icon={faClock} size="4x" color={WarmlyColor.DARK_GRAY} />
                    </ListItemIcon>
                    <Box paddingLeft={2} color={WarmlyColor.DARK_GRAY}>
                      <ListItemText primary="Estimated sync time is..." />
                      <ListItemText 
                        primary={estimateSyncTime(contactCount)} 
                        primaryTypographyProps={{
                          variant: 'h5' ,
                          style: { fontWeight: 'bold' }
                        }}
                      />
                    </Box>
                  </ListItem>
                </List>
              ) : null}
            </Grid>
            <Grid item>
              <Box paddingX={2} paddingBottom={1}>
                <Grid container item direction="row" justify="space-between">
                  <Grid item>
                    A Warmly team member will be in touch with you to determine which contacts you would like monitored.
                  </Grid>
                  <Grid item>
                    Questions? Email{' '}
                    <Link
                      href={`https://mail.google.com/mail/?view=cm&fs=1&to=${WARMLY_EMAIL.CSM}&su=[Warmly] Question about CRM Integration`}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ fontWeight: 'bold' }}
                    >
                      {WARMLY_EMAIL.CSM}
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Paper>
          <Grid item>
            {errorMessage && (
              <Box marginY={2}>
                <Alert severity={AlertSeverity.ERROR}>{errorMessage}</Alert>
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
      
      <Box
        display="flex" 
        flexDirection="row" 
        alignItems="center"
        style={{ position: 'fixed', bottom: '40px', right: '70px'}}
      >
        <Grid container direction="row" spacing={1} alignItems="center">
          <Grid item>
          <Button 
            variant="outlined" 
            color="primary" 
            onClick={onClickBack} 
            disabled={isLoading}
            startIcon={<KeyboardBackspaceIcon />}
            style={{ fontWeight: 500, backgroundColor: WarmlyColor.WHITE }}
          >
            Back
          </Button>
          </Grid>
          <Grid item>
            {isLoading && (
              <Box>
                <CircularProgress size={28} />
              </Box>
            )}
          </Grid>
          <Grid item>
            <Button 
              variant="contained" 
              color="primary" 
              onClick={onClickConfirm} 
              disabled={isLoading}
              style={{ fontWeight: 500 }}
            >
              Confirm & Sync
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default SyncContacts;
