import { useReactiveVar } from '@apollo/client';
import { Box, Button, CircularProgress, FormControl, Grid, Hidden, TextField, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';

import NavBar from '../components/NavBar';
import { firebaseAuth } from '../config/firebase';
import { UserInviteFragment } from '../graphql';
import { GQLHooks } from '../graphql/hasura/react';
import { analyticsSignUpEvent, logError } from '../modules/analytics';
import { signUpUser } from '../modules/user';
import { AlertSeverity, IMAGE_PERSON_STANDING, NavigationPath, WarmlyColor } from '../utils/constants';
import { getAuthErrorMessage, isAuthError } from '../utils/errors';
import { apolloLoggedIn } from './ApolloClientProvider';

const Onboard = () => {
  const loggedIn = useReactiveVar(apolloLoggedIn);

  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [userInvite, setUserInvte] = useState<UserInviteFragment>();

  const query = new URLSearchParams(useLocation().search);
  const inviteId = decodeURIComponent(query.get('inviteId') || '');

  const { data: userInviteQuery, loading: loadingUserInvite } = GQLHooks.Fragments.UserInvite.useQueryById({
    userInviteId: inviteId,
  });

  const { data: legacyUserInviteQuery } = GQLHooks.Fragments.UserInvite.useQueryObjects({
    variables: {
      where: {
        firestoreId: {
          _eq: inviteId,
        },
      },
    },
  });

  useEffect(() => {
    if (!loadingUserInvite && !userInviteQuery?.userInvite_by_pk) {
      if (legacyUserInviteQuery?.userInvite[0]) {
        const legacyUserInvte = legacyUserInviteQuery?.userInvite[0];
        if (new Date(legacyUserInvte.expireAt) < new Date()) {
          setErrorMessage('The invite link has expired (invitations expire two weeks from when they were sent).');
        } else {
          setUserInvte(legacyUserInvte);
        }
      }
    } else if (userInviteQuery?.userInvite_by_pk) {
      if (new Date(userInviteQuery?.userInvite_by_pk.expireAt) < new Date()) {
        setErrorMessage('The invite link has expired (invitations expire two weeks from when they were sent).');
      } else {
        setErrorMessage('');
        setUserInvte(userInviteQuery.userInvite_by_pk);
      }
    }
  }, [userInviteQuery, legacyUserInviteQuery, loadingUserInvite]);

  if (loggedIn) {
    return <Redirect to={NavigationPath.MAIN} />;
  }

  const onSubmitOnboard = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    if (!userInvite) {
      return;
    }

    let validationErrorMessage = '';
    if (!firstName.trim() || !lastName.trim() || !password.trim()) {
      validationErrorMessage = 'Required: ';
      let emptyFields = [];

      if (!firstName.trim()) {
        emptyFields.push('first name');
      }

      if (!lastName.trim()) {
        emptyFields.push('last name');
      }

      if (!password.trim()) {
        emptyFields.push('password');
      }

      validationErrorMessage += emptyFields.join(', ');
    }

    if (validationErrorMessage) {
      setErrorMessage(validationErrorMessage);
      return;
    }

    try {
      setIsLoading(true);
      const authUserCredential = await firebaseAuth.createUserWithEmailAndPassword(userInvite.inviteeEmail, password);

      const authUser = authUserCredential.user;

      if (!authUser) {
        return <Redirect to={NavigationPath.GENERIC_ERROR} />;
      }

      const userSignUpData = {
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        email: userInvite.inviteeEmail,
        firebaseUid: authUser.uid,
        userInviteId: userInvite.id,
      };

      await signUpUser(userSignUpData);

      analyticsSignUpEvent({
        firstName,
        lastName,
        clientName: userInvite.inviterClientName,
        email: userInvite.inviteeEmail,
        companyName_fullName: `${userInvite.inviterClientName}_${firstName}_${lastName}`,
      });

      // We need to force a refresh on the Firebase token before refreshing the page,
      // so ApolloClientProvider can get the latest custom claims
      authUser.getIdToken(true);
      // Firebase authUser does not auto-update idToken in response to custom claims change
      // so we need to force a page refresh so ApolloClientProvider
      // can retrieve authUser with the latest custom claims
      window.location.assign(NavigationPath.UPLOAD);
    } catch (err) {
      if (!isAuthError(err)) {
        // We only log errors if they are not basic auth errors
        logError(err, 'Error while onboarding new user');
      }
      const errorMessage = getAuthErrorMessage(err);
      setErrorMessage(errorMessage);
      setIsLoading(false);
    }
  };

  return (
    <>
      <NavBar />
      <Grid container justify="center" style={{ backgroundColor: WarmlyColor.WHITE }}>
        <Hidden xsDown>
          <Grid item sm={3}>
            <img className="person-standing" alt="Person" src={IMAGE_PERSON_STANDING} width="75%" />
          </Grid>
        </Hidden>

        <Grid item xs={8} sm={6} md={4}>
          {userInvite && (
            <FormControl>
              <Box marginTop={4} marginLeft={-4}>
                <Typography variant="h1" color="primary">
                  You have been invited to sign up as a member of {userInvite.inviterClientName}!
                </Typography>

                <Box marginTop={2}>
                  <Typography variant="h5" color="primary">
                    Please fill out the form to complete onboarding
                  </Typography>
                  <Typography variant="h6" color="primary">
                    (The email for the account will be {userInvite.inviteeEmail})
                  </Typography>
                </Box>

                <Box marginTop={3}>
                  <TextField
                    label="First Name*"
                    value={firstName}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setFirstName(event.target.value);
                    }}
                    fullWidth
                  />
                </Box>

                <Box marginTop={3}>
                  <TextField
                    label="Last Name*"
                    value={lastName}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setLastName(event.target.value);
                    }}
                    fullWidth
                  />
                </Box>

                <Box marginTop={3}>
                  <TextField
                    label="Password*"
                    type="password"
                    value={password}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setPassword(event.target.value);
                    }}
                    fullWidth
                  />
                </Box>

                <Box marginTop={3}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    onClick={onSubmitOnboard}
                    disabled={isLoading}
                  >
                    Complete Onboarding
                  </Button>
                  {isLoading && (
                    <Box marginTop={1} marginLeft={2}>
                      <CircularProgress />
                    </Box>
                  )}
                </Box>
              </Box>
            </FormControl>
          )}
          <Box marginY={3}>{errorMessage && <Alert severity={AlertSeverity.ERROR}>{errorMessage}</Alert>}</Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Onboard;
