export const WarmlyUrl = {
  MarketingHome: 'https://www.getwarmly.com',
  Dashboard: 'https://dashboard.getwarmly.com',
  Help: 'https://help.getwarmly.com',
  ToS: 'https://www.getwarmly.com/terms-of-service',
  PrivacyPolicy: 'https://www.getwarmly.com/privacy-policy',
  Contact: `https://www.getwarmly.com/#CTA`
};

export const WarmlyHelpCenterArticleUrl = {
  OwnerMapping: 'https://help.getwarmly.com/Owner-Mapping-bc4e7c28422d458bba76d8bd49a59c27',
};
