import { Tooltip } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fab from '@material-ui/core/Fab';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import SaveIcon from '@material-ui/icons/Save';
import clsx from 'clsx';
import React from 'react';

import { wait } from '../utils/functions';

// Code lifted from https://material-ui.com/components/progress/

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative',
    },
    buttonSuccess: {
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    },
    fabProgress: {
      color: theme.palette.secondary.main,
      position: 'absolute',
      top: -4,
      left: -4,
      zIndex: 1,
    },
  })
);

interface Props {
  initialIcon?: JSX.Element;
  successIcon?: JSX.Element;
  tooltipText?: string;
  onClick: () => void;
  onComplete?: () => void;
}

const LoadingButtonAnimated: React.FC<Props> = ({ onClick, onComplete, initialIcon, successIcon, tooltipText }) => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,
  });

  const handleButtonClick = async () => {
    if (!loading) {
      setSuccess(false);
      setLoading(true);
      try {
        await onClick();
        // We add a 1 second loading time to avoid a "flash or orange circle" if the onClick completes too fast
        await wait(1000);
        setSuccess(true);
        onComplete && onComplete();
      } catch (err) {
        // TODO: error handling
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Tooltip title={tooltipText || ''}>
      <div className={classes.wrapper}>
        <Fab aria-label="action" color="primary" size="small" className={buttonClassname} onClick={handleButtonClick}>
          {success ? successIcon || initialIcon || <CheckIcon /> : initialIcon || <SaveIcon />}
        </Fab>
        {loading && <CircularProgress size={48} className={classes.fabProgress} />}
      </div>
    </Tooltip>
  );
};

export default LoadingButtonAnimated;
