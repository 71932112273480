import { Box, Button, Grid } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';

import { LoadingAndAlertContext } from '../..';
import { UserContext } from '../../components/auth/UserRouter';
import { QaTaskFragment } from '../../graphql';
import { GQLHooks } from '../../graphql/hasura/react';
import { getAssignedQaTask } from '../../modules/qaTasks';
import { QATaskType } from '../../utils/constants';
import { consoleLogDev } from '../../utils/errors';
import { wait } from '../../utils/functions';
import QANavBar from './QANavBar';
import QAApproverView from './qaViews/QAApproverView';
import QACollectorView from './qaViews/QACollectorView';

const QADashboardRouter: React.FC = () => {
  const { user } = useContext(UserContext);

  const { setIsLoading } = useContext(LoadingAndAlertContext);
  const [qaTask, setQaTask] = useState<QaTaskFragment | undefined>();
  const { data: qaTaskQuery, refetch: refetchQaTask } = GQLHooks.Fragments.QaTask.useQueryObjects({
    variables: {
      where: {
        assignedUserId: {
          _eq: user.id,
        },
        completedAt: {
          _is_null: true,
        },
      },
      limit: 1,
    },
  });

  useEffect(() => {
    if (qaTaskQuery?.qaTask.length) {
      setQaTask(qaTaskQuery?.qaTask[0]);
    } else {
      setQaTask(undefined);
    }
  }, [qaTaskQuery]);

  consoleLogDev('🌈    QaTask', qaTask);

  const onClickGetQaTask = async () => {
    setIsLoading(true);
    await getAssignedQaTask();
    await wait(200); // Add a slight delay, probably not necessary but in case the function is invoked multiple times
    if (refetchQaTask) {
      await refetchQaTask();
    }
    setIsLoading(false);
  };

  return (
    <Box paddingBottom={5}>
      <QANavBar />
      {!qaTask ? (
        <Grid item>
          <Box margin={3}>
            <Button size="large" variant="contained" color="primary" onClick={() => onClickGetQaTask()}>
              Start Task
            </Button>
          </Box>
        </Grid>
      ) : null}
      {qaTask?.taskType === QATaskType.ENRICH_CONTACT ? (
        <QACollectorView qaTask={qaTask} getQaTask={onClickGetQaTask} />
      ) : null}
      {qaTask?.taskType === QATaskType.APPROVE_ENRICH_CONTACT ? (
        <QAApproverView qaTask={qaTask} getQaTask={onClickGetQaTask} />
      ) : null}
    </Box>
  );
};

export default QADashboardRouter;
