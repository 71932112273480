import { Box, Grid, Typography } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { TabContext, TabPanel } from '@material-ui/lab';
import React, { useState } from 'react';

import { WarmlyColor } from '../../utils/constants';
import NewJobChangeTable from './jobChanges/NewJobChangeTable';
import PastJobChangeTable from './jobChanges/PastJobChangeTable';

const useStyles = makeStyles((theme) =>
  createStyles({
    appBar: {
      boxShadow: 'none',
      borderBottom: `1px solid ${WarmlyColor.DISABLED_GRAY}`,
      width: 'auto',
      '& .MuiTab-root': {
        display: 'inline-block',
        minWidth: 'auto',
        fontSize: '1rem',
        fontWeight: theme.typography.fontWeightBold,
        textTransform: 'none',
      },
      '& .MuiTab-textColorInherit.Mui-selected': {
        color: theme.palette.primary.main,
      },
    },
    indicator: {
      borderRadius: '4px 4px 0 0',
      backgroundColor: theme.palette.primary.main,
      height: '3px',
    },
    tabPanel: {
      width: '100%',
      paddingLeft: 0,
    },
  })
);

enum TabValue {
  pastNotifications = 'pastNotifications',
  jobChangesNew = 'jobChangesNew',
}

const JobChanges = () => {
  const [tabValue, setTabValue] = useState(TabValue.jobChangesNew);

  const classes = useStyles();

  const handleTabChange = (_e: React.ChangeEvent<{}>, newTabValue: TabValue) => {
    setTabValue(newTabValue);
  };

  return (
    <Grid container direction="column" justify="flex-start" alignItems="flex-start" spacing={3}>
      <Grid container item>
        <Box fontWeight="bold" color={WarmlyColor.BLACK} marginLeft={1}>
          <Typography component="div" variant="h1">
            Job change notifications
          </Typography>
        </Box>
      </Grid>

      <Grid container item>
        <TabContext value={tabValue}>
          <AppBar position="static" color="transparent" className={classes.appBar}>
            <Tabs
              classes={{ indicator: classes.indicator }}
              value={tabValue}
              onChange={handleTabChange}
              aria-label="job change tabs"
            >
              <Tab label="Job changes" value={TabValue.jobChangesNew} />
              <Tab label="Past notifications" value={TabValue.pastNotifications} />
            </Tabs>
          </AppBar>
          <TabPanel value={TabValue.jobChangesNew} className={classes.tabPanel}>
            <NewJobChangeTable />
          </TabPanel>
          <TabPanel value={TabValue.pastNotifications} className={classes.tabPanel}>
            <PastJobChangeTable />
          </TabPanel>
        </TabContext>
      </Grid>
    </Grid>
  );
};

export default JobChanges;
