import React, { useContext, useEffect } from 'react';

import { AuthContext } from '../components/auth/AuthProvider';
import { firebaseAuth } from '../config/firebase';
import { CURRENT_ENVIRONMENT, Environment, NavigationPath } from '../utils/constants';
import { WarmlyUrl } from '../utils/constantsAll/url';

const LogOut: React.FC = () => {
  const { authUser } = useContext(AuthContext);

  useEffect(() => {
    const signedOutURL = CURRENT_ENVIRONMENT !== Environment.Production ? NavigationPath.MAIN : WarmlyUrl.MarketingHome;

    if (authUser) {
      firebaseAuth.signOut().then(() => {
        window.location.assign(signedOutURL);
      });
    } else {
      window.location.assign(signedOutURL);
    }
  }, [authUser]);

  return null;
};

export default LogOut;
