import { faCheckSquare, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Hidden,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core';
import React, { useState } from 'react';

import { logError } from '../../modules/analytics';
import { initiateSalesforceIntegration } from '../../modules/cloudFunctions';
import {
  SALESFORCE_LOGO,
  SalesforceURL,
  WARMLY_EMAIL,
  WARMLY_LOGO_HORIZONTAL_COLOR,
  WarmlyColor,
} from '../../utils/constants';

interface SalesforceInitializeModalProps {
  showSalesforceInitializeModal: boolean;
  setShowSalesforceInitializeModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const SalesforceInitializeModal: React.FC<SalesforceInitializeModalProps> = ({
  showSalesforceInitializeModal,
  setShowSalesforceInitializeModal,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const onClose = () => {
    setShowSalesforceInitializeModal(false);
  };

  const onClickConfirm = async () => {
    setIsLoading(true);
    try {
      await initiateSalesforceIntegration();
    } catch (err) {
      logError(err, `Error initializing Salesforce integration`);
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={showSalesforceInitializeModal} onClose={onClose} maxWidth="md">
      <DialogTitle disableTypography>
        <Typography variant="h3">Sync Salesforce with Warmly</Typography>
        <Typography variant="h5">Empower your team with enriched data and timely updates</Typography>
      </DialogTitle>
      <DialogContent>
        <Box padding={2}>
          <Grid container spacing={3}>
            <Hidden xsDown>
              <Grid container item justify="space-around" alignItems="center" sm={3} md={6}>
                <Grid container item justify="center" xs={12}>
                  <img alt="salesforce-logo" src={SALESFORCE_LOGO} width="40%" />
                </Grid>

                <Grid container item xs={12} justify="center">
                  <FontAwesomeIcon size="3x" icon={faSyncAlt} color={WarmlyColor.SALMON} />
                </Grid>

                <Grid container item justify="center" xs={12}>
                  <img alt="warmly-logo" src={WARMLY_LOGO_HORIZONTAL_COLOR} width="50%" />
                </Grid>
              </Grid>
            </Hidden>

            <Grid container item xs={12} sm={8} md={6}>
              <Box marginTop={2}>
                <Typography>Verify your Salesforce account includes:</Typography>
              </Box>
              <List dense={true}>
                <ListItem>
                  <ListItemIcon>
                    <FontAwesomeIcon size="lg" icon={faCheckSquare} color={WarmlyColor.DARK_BLUE} />
                  </ListItemIcon>
                  <ListItemText
                    primary="API Access"
                    secondary={
                      <Link href={SalesforceURL.EDITIONS_WITH_API_ACCESS} target="_blank" rel="noopener noreferrer">
                        What’s this? Open new tab for help page.
                      </Link>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FontAwesomeIcon size="lg" icon={faCheckSquare} color={WarmlyColor.DARK_BLUE} />
                  </ListItemIcon>
                  <ListItemText
                    primary="Admin rights"
                    secondary={
                      <>
                        <Link href={SalesforceURL.ADMIN_USER_DESCRIPTION} target="_blank" rel="noopener noreferrer">
                          What’s this? Open new tab for help page.
                        </Link>
                        <br />
                        <Link href={SalesforceURL.ADD_ADMIN_USER} target="_blank" rel="noopener noreferrer">
                          ( How do I create an Admin account? )
                        </Link>
                      </>
                    }
                  />
                </ListItem>
              </List>

              <Box marginTop={2}>
                <Typography>
                  * Please note that it may take 10 - 15 minutes for all of your contacts, accounts, and opportunities to sync in from Salesforce
                </Typography>
              </Box>

              <Box marginTop={2} marginBottom={1} fontWeight="bold">
                Questions? We can help! We’re here for you anytime at{' '}
                <Link
                  href={`https://mail.google.com/mail/?view=cm&fs=1&to=${WARMLY_EMAIL.CSM}&su=[Warmly] Question about Salesforce Integration`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {WARMLY_EMAIL.CSM}
                </Link>
              </Box>
            </Grid>
          </Grid>

          <Grid container direction="row" justify="flex-end" spacing={3}>
            <Grid item>
              <Button variant="outlined" color="primary" onClick={onClose}>
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" color="primary" onClick={onClickConfirm} disabled={isLoading}>
                Confirm & Continue
              </Button>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default SalesforceInitializeModal;
