import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { NavigationPath } from '../../utils/constants';
import AllClientsManagement from './adminPanel/AllClientsManagement';
import ClientManagement from './adminPanel/ClientManagement';
import CloudTasks from './adminPanel/CloudTasks';
import QaManagement from './adminPanel/QaManagement';
import UsersManagement from './adminPanel/UsersManagement';

const AdminPanel: React.FC = () => {
  return (
    <Grid container direction="column" justify="flex-start" alignItems="flex-start" spacing={3}>
      <Grid container item>
        <Typography variant="h1" color="primary">
          Admin Panel
        </Typography>
      </Grid>

      <Switch>
        <Route exact={true} path={NavigationPath.ADMIN_CLIENT_MANAGEMENT}>
          <ClientManagement />
        </Route>
        <Route exact={true} path={NavigationPath.UPLOAD}>
          <Redirect to={NavigationPath.UPLOAD} />
        </Route>
        <Route exact={true} path={NavigationPath.ADMIN_ALL_CLIENTS_MANAGEMENT}>
          <AllClientsManagement />
        </Route>
        <Route exact={true} path={NavigationPath.ADMIN_USERS_MANAGEMENT}>
          <UsersManagement />
        </Route>
        <Route exact={true} path={NavigationPath.ADMIN_QA_MANAGEMENT}>
          <QaManagement />
        </Route>
        <Route exact={true} path={NavigationPath.ADMIN_CLOUD_TASKS}>
          <CloudTasks />
        </Route>
        <Route>
          <Redirect to={NavigationPath.ADMIN_CLIENT_MANAGEMENT} />
        </Route>
      </Switch>
    </Grid>
  );
};

export default AdminPanel;
