import { Box, Card, CardContent, CardMedia, Grid, Typography } from '@material-ui/core';
import React from 'react';

import { CEO_AVATAR, CTO_AVATAR, WARMLY_EMAIL } from '../../utils/constants';

const ContactUs: React.FC = () => {
  return (
    <Grid container direction="column" justify="flex-start" alignItems="flex-start" spacing={3}>
      <Grid container item>
        <Typography variant="h1" color="primary">
          Contact Us
        </Typography>
      </Grid>

      <Grid container item direction="row" justify="flex-start" alignItems="flex-start" spacing={3}>
        <Grid item xs={12} md={6}>
          <Card>
            <Grid container direction="row" justify="flex-start">
              <Grid container item xs={3} justify="center">
                <CardMedia component="img" alt="CEO Max" image={CEO_AVATAR} title="Contact our CEO" />
              </Grid>
              <Grid item xs={9}>
                <CardContent>
                  <Typography component="h5" variant="h5">
                    Questions / feedback about our business?
                  </Typography>
                  <Typography variant="subtitle1" color="textSecondary">
                    Directly contact our CEO
                  </Typography>
                  <Box marginTop={1}>
                    <Typography variant="h6">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`https://mail.google.com/mail/?view=cm&fs=1&to=${WARMLY_EMAIL.CEO}&su=[Warmly] CEO Questions/Feedback`}
                      >
                        {WARMLY_EMAIL.CEO}
                      </a>
                      <div>303-900-8442</div>
                    </Typography>
                  </Box>
                </CardContent>
              </Grid>
            </Grid>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card>
            <Grid container direction="row" justify="flex-start">
              <Grid container item xs={3} justify="center">
                <CardMedia component="img" alt="CTO Carina" image={CTO_AVATAR} title="Contact our support team" />
              </Grid>
              <Grid item xs={9}>
                <CardContent>
                  <Typography component="h5" variant="h5">
                    Questions / feedback about using our app?
                  </Typography>
                  <Typography variant="subtitle1" color="textSecondary">
                    Directly contact our technology team
                  </Typography>
                  <Box marginTop={1}>
                    <Typography variant="h6">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`https://mail.google.com/mail/?view=cm&fs=1&to=${WARMLY_EMAIL.CSM}&su=[Warmly] Engineering Team Questions/Feedback`}
                      >
                        {WARMLY_EMAIL.CSM}
                      </a>
                    </Typography>
                  </Box>
                </CardContent>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ContactUs;
