import { Box, CircularProgress } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { Alert } from '@material-ui/lab';
import { AxiosError } from 'axios';
import React, { useState } from 'react';

import { requestPasswordResetEmail } from '../../modules/cloudFunctions';
import { AlertSeverity } from '../../utils/constants';
import { getAuthErrorMessage } from '../../utils/errors';

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const PasswordResetModal: React.FC<Props> = ({ isOpen, setIsOpen }) => {
  const [email, setEmail] = useState('');
  const [alert, setAlert] = useState<AlertData | undefined>();
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
  };

  const onConfirmResetPassword = async () => {
    if (email.trim()) {
      setAlert(undefined);
      setIsLoading(true);
      try {
        await requestPasswordResetEmail(email);
        setAlert({
          severity: AlertSeverity.SUCCESS,
          message: 'Password reset email sent',
        });
      } catch (err) {
        let errorMessage = '';
        if ((err as AxiosError).response?.data === 'EMAIL_NOT_FOUND') {
          errorMessage = 'We could not find an existing user with that email';
        } else if ((err as AxiosError).response?.data === 'EMAIL_INVALID') {
          errorMessage = 'Invalid email format';
        } else {
          errorMessage = getAuthErrorMessage(err);
        }
        setAlert({
          severity: AlertSeverity.ERROR,
          message: errorMessage,
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} aria-labelledby="reset-password-form">
      <DialogTitle>Reset Password</DialogTitle>
      <DialogContent>
        <DialogContentText>To reset your password, please enter your account email below</DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Email Address"
          type="email"
          value={email}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setEmail(event.target.value);
          }}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        {isLoading && (
          <Box marginTop={1}>
            <CircularProgress color="primary" />
          </Box>
        )}
        <Button onClick={handleClose} variant="outlined" color="primary" disabled={isLoading}>
          Cancel
        </Button>
        <Button onClick={onConfirmResetPassword} variant="contained" color="primary" disabled={isLoading || !email.trim()}>
          Reset
        </Button>
      </DialogActions>
      <Box>{alert && <Alert severity={alert.severity}>{alert.message}</Alert>}</Box>
    </Dialog>
  );
};

export default PasswordResetModal;
