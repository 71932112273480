import Axios from 'axios';

import { gaeApiURL, UserQaQualification, UserRole } from '../utils/constants';
import { getAuthToken } from './auth';

export const generateJobChanges = async (clientId: string, numberOfJobChanges: number) => {
  const functionURL = gaeApiURL.admin.generateJobChanges;

  const authToken = await getAuthToken();
  const body = {
    clientId,
    numberOfJobChanges,
  };

  return Axios.post<string>(functionURL, body, {
    headers: {
      Authorization: 'Bearer ' + authToken,
      'Cache-Control': 'no-cache',
    },
  });
};

export const resyncClientContacts = async (clientId: string) => {
  const functionURL = gaeApiURL.admin.resyncClientContacts;

  const authToken = await getAuthToken();

  return Axios.put<string>(
    functionURL,
    { clientId },
    {
      headers: {
        Authorization: 'Bearer ' + authToken,
        'Cache-Control': 'no-cache',
      },
    }
  );
};

export const findUpdatedEmail = async (
  clientContactId: string,
  firstName: string,
  lastName: string,
  oldEmail: string,
  companyDomain: string
) => {
  const functionURL = `${gaeApiURL.qaTask.findUpdatedEmail}`;
  const authToken = await getAuthToken();

  const body = {
    clientContactId,
    companyDomain,
    firstName,
    lastName,
    oldEmail,
  };

  return Axios.post<string>(functionURL, body, {
    headers: {
      Authorization: 'Bearer ' + authToken,
      'Cache-Control': 'no-cache',
    },
  });
};

export const sendCSVExport = async (clientId: string, exportType: string) => {
  const functionURL = `${gaeApiURL.admin.sendQACSVExport}/${clientId}`;

  const body = {
    exportType: exportType,
  };

  const authToken = await getAuthToken();

  return Axios.post<string>(functionURL, body, {
    headers: {
      Authorization: 'Bearer ' + authToken,
      'Cache-Control': 'no-cache',
    },
  });
};

export const deleteClient = async (clientId: string) => {
  const functionURL = gaeApiURL.admin.deleteClient;

  const body = {
    clientId,
  };

  const authToken = await getAuthToken();

  return Axios.post<string>(functionURL, body, {
    headers: {
      Authorization: 'Bearer ' + authToken,
      'Cache-Control': 'no-cache',
    },
  });
};

export const sendWeeklyJobChangeReport = async (clientId: string, testEmail: string | undefined = undefined) => {
  const functionURL = `${gaeApiURL.admin.sendWeeklyJobChangeReport}`;

  const body = {
    clientId,
    testEmail,
  };

  const authToken = await getAuthToken();

  return Axios.post<string>(functionURL, body, {
    headers: {
      Authorization: 'Bearer ' + authToken,
      'Cache-Control': 'no-cache',
    },
  });
};

export interface CreateNewUser {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  role: UserRole;
  clientId?: string;
  clientName?: string;
  qaQualifications?: UserQaQualification[];
}

export const adminCreateNewUser = async (body: CreateNewUser) => {
  const functionURL = gaeApiURL.admin.createNewUser;

  const authToken = await getAuthToken();

  return Axios.post(functionURL, body, {
    headers: {
      Authorization: 'Bearer ' + authToken,
      'Cache-Control': 'no-cache',
    },
  });
};

export const adminCreateProxyCrawlTask = async (clientId: string, forceRecrawl: boolean) => {
  const functionURL = gaeApiURL.admin.createProxyCrawlTask;

  const body = {
    clientId,
    forceRecrawl,
  };

  const authToken = await getAuthToken();

  return Axios.post(functionURL, body, {
    headers: {
      Authorization: 'Bearer ' + authToken,
      'Cache-Control': 'no-cache',
    },
  });
};

export const adminRecreateTask = async (cloudTaskId: string) => {
  // const functionURL = gaeApiURL.admin.recreateTask;
  // const body: RecreateCloudTask = {
  //   cloudTaskId,
  // };
  // const authToken = await getAuthToken();
  // return Axios.post(functionURL, body, {
  //   headers: {
  //     Authorization: 'Bearer ' + authToken,
  //     'Cache-Control': 'no-cache',
  //   },
  // });
};

export const adminCreateDedupClientContactsTask = async (clientId: string, field: string) => {
  const functionURL = gaeApiURL.admin.createDedupClientContactsTask;

  const body = {
    clientId,
    field,
  };

  const authToken = await getAuthToken();

  return Axios.post(functionURL, body, {
    headers: {
      Authorization: 'Bearer ' + authToken,
      'Cache-Control': 'no-cache',
    },
  });
};

export const adminUpdateUserRole = async (userId: string, updatedRole: string) => {
  const url = gaeApiURL.admin.updateUserRole;
  const body = { userId, updatedRole };

  const authToken = await getAuthToken();

  return Axios.post(url, body, {
    headers: {
      Authorization: 'Bearer ' + authToken,
      'Cache-Control': 'no-cache',
    },
  });
}
