import { faArrowAltCircleDown } from '@fortawesome/free-regular-svg-icons';
import { faAngleDoubleDown, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, CircularProgress, Grid, IconButton, Tooltip, Typography, withStyles, } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import React, { useContext, useState } from 'react';

import { LoadingAndAlertContext } from '../../..';
import { UserContext } from '../../../components/auth/UserRouter';
import { ConfirmCRMApproveModal } from '../../../components/crm/ConfirmCRMApproveModal';
import ExportCSVButton from '../../../components/exportCSV/ExportCSVButton';
import ExportCSVModal from '../../../components/exportCSV/ExportCSVModal';
import { Order_By } from '../../../graphql';
import { GQLHooks } from '../../../graphql/hasura/react';
import { logError } from '../../../modules/analytics';
import { exportContactsCsv } from '../../../modules/cloudFunctions';
import { AlertSeverity, CloudTaskStatus, taskQueue, } from '../../../utils/constants';
import { ClientContext } from '../../Main';

const BUTTON_MARGIN = 30;

const EnrichedCrmOptionButton = withStyles((theme) => ({
  root: {
    paddingLeft: BUTTON_MARGIN,
    paddingRight: BUTTON_MARGIN,
    backgroundColor: theme.palette.common.white,
    textTransform: 'none',
    fontWeight: 300,
    borderColor: theme.palette.grey[300],
  },
}))(Button);

interface Props {
  setAllRowsExpanded: (allExpanded: boolean) => void;
}

const EnrichedCrmOptions: React.FC<Props> = ({ setAllRowsExpanded }) => {
  const { selectedClient } = useContext(ClientContext);
  const { user } = useContext(UserContext);
  const { setIsLoading, setSnackbarAlertData } = useContext(LoadingAndAlertContext);
  const [showConfirmCRMApproveModal, setShowConfirmCRMApproveModal] = useState(false);
  const [showExportCsvModal, setShowExportCsvModal] = useState(false);

  // NOTE we currently have no good way to only get cloud task for a specific action
  // so we approximate by using createdByUserId and description
  // We should probably adopt another way to notify clients of CSV export completion
  const { data: cloudTasks } = GQLHooks.Fragments.CloudTask.useSubscribeToObjects({
    variables: {
      where: {
        queueName: {
          _eq: taskQueue.clientContactActions.name,
        },
        createdByUserId: {
          _eq: user.id,
        },
        description: {
          _eq: 'CSV contacts export',
        },
      },
      limit: 1,
      order_by: [
        {
          createdAt: Order_By.Desc,
        },
      ],
    },
  });

  const isExportingCsv =
    cloudTasks?.cloudTask[0]?.status === CloudTaskStatus.CREATED ||
    cloudTasks?.cloudTask[0]?.status === CloudTaskStatus.PROCESSING;

  const exportCsvError = cloudTasks?.cloudTask[0]?.status === CloudTaskStatus.ERROR;

  const onClickExpandAll = () => {
    setAllRowsExpanded(true);
  };

  const onConfirmSendCSVExport = async () => {
    try {
      setIsLoading(true);

      const clientId = selectedClient.id, jobChangesOnly = false;
      await exportContactsCsv( clientId, jobChangesOnly );

      const alertData: AlertData = {
        severity: AlertSeverity.SUCCESS,
        message: `We are now processing your request to email CSV`,
      };

      setSnackbarAlertData(alertData);
    } catch (err) {
      logError(err, `Error while sending csv export email for ${selectedClient?.name}`);

      const alertData: AlertData = {
        severity: AlertSeverity.ERROR,
        message: `Error while sending CSV export`,
      };

      setSnackbarAlertData(alertData);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Grid container direction="row" justify="space-between">
      <Grid item>
        <Grid container direction="row" spacing={1}>
          <Grid item>
            <Typography variant="h5" color="secondary">
              <Box fontWeight="bold">Orange </Box>
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h5" color="primary">
              highlight = Warmly enriched info
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container direction="row" justify="flex-end" alignItems="center" spacing={3}>
          {isExportingCsv ? (
            <Grid item xs={2} container spacing={1} justify="center">
              <Grid item>
                <CircularProgress size={16} />
              </Grid>
              <Grid item>
                <Typography variant="caption">exporting CSV...</Typography>
              </Grid>
            </Grid>
          ) : (
            <>
              {exportCsvError && (
                <Typography variant="caption">
                  We encountered an error while exporting CSV, our team has been notified of the issue
                </Typography>
              )}
              <Grid item>
                <ExportCSVButton
                  size="small"
                  variant="outlined"
                  startIcon={<FontAwesomeIcon icon={faArrowAltCircleDown} />}
                  onClick={onConfirmSendCSVExport}
                >
                  Email me CSV
                </ExportCSVButton>
                <IconButton
                  color="primary"
                  aria-label="more-info"
                  component="span"
                  onClick={() => setShowExportCsvModal(true)}
                >
                  <InfoIcon />
                </IconButton>
              </Grid>
            </>
          )}
          
          <Grid item>
            <EnrichedCrmOptionButton
              size="small"
              variant="outlined"
              startIcon={<FontAwesomeIcon icon={faAngleDoubleDown} />}
              onClick={onClickExpandAll}
            >
              Expand and show all updates
            </EnrichedCrmOptionButton>
          </Grid>

          {selectedClient?.integrationType && (
            <>
              <Tooltip title="Clicking this will provide additional information before we begin syncing">
                <Grid item>
                  <EnrichedCrmOptionButton
                    size="small"
                    variant="outlined"
                    onClick={() => {
                      setShowConfirmCRMApproveModal(true);
                    }}
                  >
                    <Box marginRight={1}>Sync to CRM info</Box>
                    <FontAwesomeIcon icon={faCheck} />
                  </EnrichedCrmOptionButton>
                </Grid>
              </Tooltip>

              <ConfirmCRMApproveModal
                showConfirmCRMApproveModal={showConfirmCRMApproveModal}
                setShowConfirmCRMApproveModal={setShowConfirmCRMApproveModal}
              />
            </>
          )}

          <ExportCSVModal setIsOpen={setShowExportCsvModal} isOpen={showExportCsvModal} jobChangesOnly={false}/>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EnrichedCrmOptions;
