import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Link } from '@material-ui/core';
import React from 'react';

import { WARMLY_EMAIL } from '../../../utils/constants';

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const AdminUserInfoModal: React.FC<Props> = ({ isOpen, setIsOpen }) => {
  const onCloseModal = () => {
    setIsOpen(false);
  };

  return (
    <Dialog open={isOpen} onClose={onCloseModal}>
      <DialogTitle>Admin Users</DialogTitle>
      <DialogContent>
        <Box>
          Admin users can view all job changes. Standard, non-admin users can only view job changes for contacts that
          they have been assigned via owner mapping. This also applies to Warmly's job change reports.
        </Box>
        <Box marginTop={2}>
          In addition, only admin users can make modifications to owner mapping (e.g., add and remove territories).
        </Box>

        <Box marginTop={2}>
          As always, if you have any questions, please contact us at{' '}
          <Link
            href={`https://mail.google.com/mail/?view=cm&fs=1&to=${WARMLY_EMAIL.CSM}&su=[Warmly] Question about Admin Users`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {WARMLY_EMAIL.CSM}
          </Link>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={onCloseModal}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AdminUserInfoModal;
