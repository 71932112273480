import { Pagination } from '@material-ui/lab';
import React from 'react';

interface Props {
  currentPage: number;
  itemsPerPage: number;
  totalCount: number;
  onChangePage: (page: number) => void;
}

const CustomPagination: React.FC<Props> = ({ currentPage, totalCount, itemsPerPage, onChangePage }) => {
  // Internally our page count starts at 1, but for display purposes the pagination component starts at 1
  return (
    <Pagination
      count={Math.ceil(totalCount / itemsPerPage)}
      page={currentPage + 1}
      onChange={(_e: unknown, pageNumber: number) => {
        onChangePage(pageNumber - 1);
      }}
      size="large"
    />
  );
};

export default CustomPagination;
