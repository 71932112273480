import { Box, FormControl, MenuItem, Select, TableCell, TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';

import { HubspotContactPropertyFragment, SalesforceContactFieldFragment } from '../../../../graphql';
import { ClientContactAttribute, ClientContactAttributeMetaData, IntegrationType } from '../../../../utils/constants';
import { defaultHubspotMapping, defaultSalesforceMapping, requiredWarmlyAttributes } from '../CustomizeFields';

const useStyles = makeStyles(() => ({
  select: {
    '& .MuiOutlinedInput-input': {
      padding: '10px',
    },
  },
}));

interface MapAttributesRowProps {
  warmlyAttribute: ClientContactAttribute;
  integrationType: IntegrationType;
  onChangeProperty: (propertyName: string) => void;
  crmContactFields?: (SalesforceContactFieldFragment | HubspotContactPropertyFragment)[];
}

const MapAttributesRow: React.FC<MapAttributesRowProps> = ({
  warmlyAttribute,
  integrationType,
  crmContactFields = [],
  onChangeProperty,
}) => {
  const classes = useStyles();

  const initialFieldSelected =
    integrationType === IntegrationType.HubSpot
      ? defaultHubspotMapping[warmlyAttribute]
      : defaultSalesforceMapping[warmlyAttribute];

  const [selectedFieldName, setSelectedFieldName] = useState(initialFieldSelected || '');

  const selectedField = crmContactFields.find((field) => field.name === selectedFieldName);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedField = event.target.value as string;
    setSelectedFieldName(selectedField);
    onChangeProperty(selectedField);
  };

  let attributeDisplayText = ClientContactAttributeMetaData[warmlyAttribute].displayText;
  if (requiredWarmlyAttributes.includes(warmlyAttribute)) {
    attributeDisplayText = attributeDisplayText + '*';
  }

  return (
    <TableRow hover>
      <TableCell>
        <Box textAlign="left">{attributeDisplayText}</Box>
      </TableCell>
      <TableCell>
        <Box textAlign="left">{selectedField?.name}</Box>
      </TableCell>
      <TableCell>
        <FormControl variant="outlined" style={{ width: '100%' }}>
          <Select autoWidth value={selectedFieldName} onChange={handleChange} className={classes.select}>
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {crmContactFields.map((field) => {
              return (
                <MenuItem key={field.name} value={field.name}>
                  {field.label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </TableCell>
    </TableRow>
  );
};

export default MapAttributesRow;
