import { Box, Checkbox, CircularProgress, Grid, TextField, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';

import { UserContext } from '../../../components/auth/UserRouter';
import SelectDropdown from '../../../components/SelectDropdown';
import { QaTaskActionFragment, QaTaskFragment } from '../../../graphql';
import { QAButtonType, QAJobChangeStatus as QAJobChangeStatusMap, QATaskActionType } from '../../../utils/constants';
import { checkEmailFormat, getClientContactName } from '../../../utils/functions';
import ConfirmTaskButton from '../buttons/ConfirmTaskButton';
import QAFindNewEmailButton from '../buttons/FindNewEmailButton';

interface Props {
  qaTask: QaTaskFragment;
  getQaTask: () => void;
}

const QACollectorView: React.FC<Props> = ({ qaTask, getQaTask }) => {
  const { user } = useContext(UserContext);

  const [qaTaskAction, setQaTaskAction] = useState<QaTaskActionFragment>();

  const [QALinkedInURL, setQALinkedInURL] = useState('');
  const [QAOldLinkedInJob, setQAOldLinkedInJob] = useState('');
  const [QANewLinkedInJob, setQANewLinkedInJob] = useState('');
  const [QAJobChangeStatus, setQAJobChangeStatus] = useState('');
  const [QAFirstName, setQAFirstName] = useState('');
  const [QALastName, setQALastName] = useState('');
  const [QANewEmail, setQANewEmail] = useState('');
  const [QANotes, setQANotes] = useState('');
  const [QACompanyDomain, setQACompanyDomain] = useState('');
  const [generatedEmail, setGeneratedEmail] = useState('');
  const [QAJobChangeStatusRequiredError, setQAJobChangeStatusRequiredError] = useState(false);
  const [QANewEmailError, setQANewEmailError] = useState(false);
  const [QAJobChangeStatusError, setQAJobChangeStatusError] = useState(false);
  const [QALinkedInURLNotFound, setQALinkedInURLNotFound] = useState(false);
  const [QALinkedInURLError, setQALinkedInURLError] = useState(false);
  const [isEmailLoading, setIsEmailLoading] = useState(false);
  const [validationError, setValidationError] = useState(true);
  const [removeAllDataError, setRemoveAllDataError] = useState(false);

  useEffect(() => {
    // get first and last name from clientContact. Used for getting new email
    const contactFullName = qaTask.clientContact?.fullName || '';
    const firstName = qaTask.clientContact?.firstName || contactFullName?.split(' ', 2)[0] || '';
    const lastName = qaTask.clientContact?.lastName || contactFullName?.split(' ', 2)[1] || '';
    setQAFirstName(firstName.toLowerCase());
    setQALastName(lastName.toLowerCase());

    const existingQaTaskAction = qaTask.qaTaskActions.find((action) => action.qaUserId === user.id);

    if (existingQaTaskAction) {
      setQaTaskAction(existingQaTaskAction);
    } else {
      throw new Error(`No task action found for task id ${qaTask.id}`);
    }
  }, [qaTask, user.id]);

  const clearForm = () => {
    setQALinkedInURL('');
    setQAOldLinkedInJob('');
    setQANewLinkedInJob('');
    setQAJobChangeStatus('');
    setQANewEmail('');
    setQANotes('');
    setQACompanyDomain('');
    setGeneratedEmail('');
    setQALastName('');
    setQAFirstName('');
    setQALinkedInURLNotFound(false);
    setValidationError(false);
    setQAJobChangeStatusError(false);
    setQANewEmailError(false);
    setQALinkedInURLError(false);
    setQAJobChangeStatusRequiredError(false);
    setRemoveAllDataError(false);
  };

  const onSelectJobChangeStatus = (newStatus: string) => {
    setQAJobChangeStatus(newStatus);
  };

  const jobChangeStatusOptions: SelectOption[] = Object.values(QAJobChangeStatusMap)
    .filter((value) => {
      return (
        value === QAJobChangeStatusMap.JOB_CHANGED ||
        value === QAJobChangeStatusMap.JOB_CHANGED_SAME_COMPANY ||
        value === QAJobChangeStatusMap.LEFT_OLD_JOB_NO_NEW_JOB ||
        value === QAJobChangeStatusMap.NO_JOB_CHANGE
      );
    })
    .map((value) => {
      return { value, label: value };
    });

  useEffect(() => {
    // check that linkedin URL contains linkedin.com/in
    if (QALinkedInURL.includes('linkedin.com/in')) {
      setQALinkedInURLError(false);
    } else {
      setQALinkedInURLError(true);
      setValidationError(true);
    }

    // check that qa email is of valid email format

    if (!checkEmailFormat(QANewEmail) && QANewEmail.length > 0) {
      setQANewEmailError(true);
      setValidationError(true);
    } else {
      setQANewEmailError(false);
    }

    // Check if job change but no old/newjob info
    if (
      (QAOldLinkedInJob === '' || QANewLinkedInJob === '') &&
      (QAJobChangeStatus === QAJobChangeStatusMap.JOB_CHANGED ||
        QAJobChangeStatus === QAJobChangeStatusMap.JOB_CHANGED_SAME_COMPANY)
    ) {
      setQAJobChangeStatusError(true);
      setValidationError(true);
    } else {
      setQAJobChangeStatusError(false);
    }

    // If linkedin URL is not empty then job change status is required
    if (QALinkedInURL.length > 0 && QAJobChangeStatus.length === 0) {
      setQAJobChangeStatusRequiredError(true);
      setValidationError(true);
    } else {
      setQAJobChangeStatusRequiredError(false);
    }

    // if there are no errors then set validationError flag to false so button is not disabled
    if (!QALinkedInURLError && !QANewEmailError && !QAJobChangeStatusError && !QAJobChangeStatusRequiredError) {
      setValidationError(false);
    }

    // if LinkedIn URL checkbox checked true then validation flag to false so button is not disabled
    if (QALinkedInURLNotFound === true) {
      setValidationError(false);
    }

    // if LinkedIn URL Not Found marked as true then remove all data and set Job Change Status to None
    if (QALinkedInURLNotFound && (QALinkedInURL.length > 0 || QAJobChangeStatus.length > 0 || QANewEmail.length > 0)) {
      setValidationError(true);
      setRemoveAllDataError(true);
    } else {
      setRemoveAllDataError(false);
    }
  }, [
    QAJobChangeStatus,
    QAJobChangeStatusError,
    QAJobChangeStatusRequiredError,
    QALinkedInURL,
    QALinkedInURLError,
    QALinkedInURLNotFound,
    QANewEmail,
    QANewEmailError,
    QANewLinkedInJob,
    QAOldLinkedInJob,
  ]);

  const companyNameDisplay = qaTask?.clientContact?.crmCompanyName || '';
  const emailDisplay = qaTask?.clientContact?.crmEmail || '';
  const titleDisplay = qaTask?.clientContact?.crmTitle || '';
  const contactNameDisplay = getClientContactName(qaTask?.clientContact!);
  const linkedinUrlDisplay = qaTask?.clientContact?.crmLinkedinUrl || '';

  if (!qaTaskAction) {
    return <CircularProgress />;
  }

  return (
    <Box>
      <Box maxWidth="95%" display="flex" flexGrow={1} marginLeft={5} marginTop={6}>
        <Grid container justify="center" direction="row" spacing={5}>
          <Grid item xs={8}>
            <Typography variant="h1">Enrich</Typography>
            {qaTask.isEscalated && (
              <Typography variant="h1" style={{ color: 'red' }}>
                *Escalated*
              </Typography>
            )}
          </Grid>
          <Grid item xs={4}>
            {/* TODO implement task over due count */}
            {/* <Typography variant="h1">{openEnrichTasksDue} Tasks Overdue</Typography> */}
          </Grid>
          <Grid container item xs={12} direction="column" justify="flex-start" alignItems="center">
            <Grid container item direction="row" justify="center" alignItems="center">
              <Grid item style={{ width: 'inherit' }}>
                <Grid container direction="row" justify="flex-start" alignItems="center">
                  <Grid item xs={2}>
                    <Typography variant="h6" color="primary">
                      Full Name:
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Box fontWeight="bold">
                      <Typography>{contactNameDisplay}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography variant="h6" color="primary">
                      Generate new email:
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item direction="row" justify="center" alignItems="center">
              <Grid item style={{ width: 'inherit' }}>
                <Grid container direction="row" justify="flex-start" alignItems="center">
                  <Grid item xs={2}>
                    <Typography variant="h6" color="primary">
                      Email:
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Box fontWeight="bold">
                      <Typography>{emailDisplay}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      value={QAFirstName}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setQAFirstName(event.target.value);
                      }}
                      helperText="First Name"
                      fullWidth
                      multiline={true}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      value={QALastName}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setQALastName(event.target.value);
                      }}
                      helperText="Last Name"
                      fullWidth
                      multiline={true}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item direction="row" justify="center" alignItems="center">
              <Grid item style={{ width: 'inherit' }}>
                <Grid container direction="row" justify="flex-start" alignItems="center">
                  <Grid item xs={2}>
                    <Typography variant="h6" color="primary">
                      Job Title:
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Box fontWeight="bold">
                      <Typography>{titleDisplay}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={5}>
                    <TextField
                      value={QACompanyDomain}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setQACompanyDomain(event.target.value);
                      }}
                      helperText="New Company Domain"
                      fullWidth
                      multiline={true}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item direction="row" justify="center" alignItems="center">
              <Grid item style={{ width: 'inherit' }}>
                <Grid container direction="row" justify="flex-start" alignItems="center">
                  <Grid item xs={2}>
                    <Typography variant="h6" color="primary">
                      Company Name:
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Box fontWeight="bold">
                      <Typography>{companyNameDisplay}</Typography>
                    </Box>
                  </Grid>
                  <Grid item container direction="row" xs={5}>
                    <Grid item>
                      <QAFindNewEmailButton
                        fullName={contactNameDisplay}
                        firstName={QAFirstName}
                        lastName={QALastName}
                        oldEmail={emailDisplay}
                        companyDomain={QACompanyDomain}
                        id={qaTask.clientContact!.id}
                        setGeneratedEmail={setGeneratedEmail}
                        setIsEmailLoading={setIsEmailLoading}
                        isEmailLoading={isEmailLoading}
                      />
                    </Grid>
                    <Grid item>
                      {isEmailLoading && (
                        <Box marginLeft={1} marginTop={1}>
                          <CircularProgress color="primary" size={20} />
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item direction="row" justify="center" alignItems="center">
              <Grid item style={{ width: 'inherit' }}>
                <Grid container direction="row" justify="flex-start" alignItems="center">
                  <Grid item xs={2}>
                    <Typography variant="h6" color="primary">
                      LinkedIn URL:
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Box fontWeight="bold">
                      <a target="_blank" rel="noopener noreferrer" href={linkedinUrlDisplay}>
                        {linkedinUrlDisplay}
                      </a>
                    </Box>
                  </Grid>
                  <Grid item xs={5}>
                    {generatedEmail === 'No Email Found' ? (
                      <Box fontWeight="bold" color="red">
                        <Typography color="primary">No Email Found</Typography>
                      </Box>
                    ) : (
                      <Box fontWeight="bold">
                        <Typography color="primary">{generatedEmail}</Typography>
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container direction="column" item xs={6} justify="center">
            <Grid item>
              <Box marginY={1}>
                <strong>Input LinkedIn URL</strong>
              </Box>
            </Grid>
            <Grid item>
              <TextField
                value={QALinkedInURL}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setQALinkedInURL(event.target.value);
                }}
                error={QALinkedInURLError}
                helperText={QALinkedInURLError ? 'Find LinkedIn URL. URL must include "linkedin.com/in"' : ''}
                fullWidth
                multiline={true}
              />
            </Grid>
          </Grid>
          <Grid container direction="column" item xs={6} justify="center">
            <Grid item>
              <Box marginY={1}>
                <strong>LinkedIn URL NOT Found</strong>
              </Box>
              <Checkbox
                checked={QALinkedInURLNotFound}
                onClick={() => {
                  setQALinkedInURLNotFound(!QALinkedInURLNotFound);
                }}
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </Grid>
            <Grid item></Grid>
          </Grid>
          <Grid container direction="column" item xs={6} justify="center">
            <Grid item>
              <Box marginY={1}>
                <strong>Input Old Job (If Job Change)</strong>
              </Box>
            </Grid>
            <Grid item>
              <TextField
                value={QAOldLinkedInJob}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setQAOldLinkedInJob(event.target.value);
                }}
                fullWidth
                error={QAJobChangeStatusError}
                helperText={QAJobChangeStatusError ? 'Input old LinkedIn job info' : ''}
                multiline={true}
              />
            </Grid>
          </Grid>
          <Grid container xs={6} direction="column" item justify="center">
            <Grid item>
              <Box marginY={1}>
                <strong>Input New Job (If Job Change)</strong>
              </Box>
            </Grid>
            <Grid item>
              <TextField
                value={QANewLinkedInJob}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setQANewLinkedInJob(event.target.value);
                }}
                fullWidth
                error={QAJobChangeStatusError}
                helperText={QAJobChangeStatusError ? 'Input new LinkedIn job info' : ''}
                multiline={true}
              />
            </Grid>
          </Grid>
          <Grid container xs={12} direction="column" item justify="center">
            <Grid item>
              <Box marginY={1}>
                <strong>Job Change Status *</strong>
              </Box>
            </Grid>
            <Grid item>
              <SelectDropdown
                options={jobChangeStatusOptions}
                selectedValue={QAJobChangeStatus || ''}
                onSelect={onSelectJobChangeStatus}
              />
            </Grid>
            {QAJobChangeStatusRequiredError ? (
              <Grid item>
                <Box color="red">Select dropdown job change status</Box>
              </Grid>
            ) : null}
          </Grid>
          <Grid container xs={3} direction="column" item justify="center">
            <Grid item>
              <Box marginY={1}>
                <strong>New Email (Only if new company job change)</strong>
              </Box>
            </Grid>
            <Grid item>
              <TextField
                value={QANewEmail}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setQANewEmail(event.target.value);
                }}
                error={QANewEmailError}
                helperText={QANewEmailError ? 'Incorrect Email Format' : ''}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container xs={9} direction="column" item justify="center">
            <Grid item>
              <Box marginY={1}>
                <strong>Notes</strong>
              </Box>
            </Grid>
            <Grid item>
              <TextField
                value={QANotes}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setQANotes(event.target.value);
                }}
                fullWidth
                multiline={true}
              />
            </Grid>
          </Grid>
          {removeAllDataError ? (
            <Grid container xs={12} direction="column" item justify="center">
              <Box color="red">LinkedIn URL Not Found so remove all data and set Job Change Status to "None"</Box>
            </Grid>
          ) : null}
          <Grid container item justify="center" spacing={7}>
            <Grid item>
              <ConfirmTaskButton
                buttonType={QAButtonType.ESCALATE}
                getQaTask={getQaTask}
                qaTaskAction={qaTaskAction}
                QATaskActionData={{
                  QALinkedInURL,
                  QALinkedInURLNotFound,
                  QANotes,
                  QAOldLinkedInJob,
                  QANewLinkedInJob,
                  QAJobChangeStatus,
                  actionType: QATaskActionType.ESCALATE,
                  QANewEmail,
                  userId: user?.id || '',
                  userName: `${user?.firstName} ${user?.lastName}`,
                }}
                clearForm={clearForm}
              />
            </Grid>
            <Grid item>
              <ConfirmTaskButton
                buttonType={QAButtonType.NEXT}
                getQaTask={getQaTask}
                qaTaskAction={qaTaskAction}
                QATaskActionData={{
                  QALinkedInURL,
                  QALinkedInURLNotFound,
                  QANotes,
                  QAOldLinkedInJob,
                  QANewLinkedInJob,
                  QAJobChangeStatus,
                  actionType: QATaskActionType.RESOLVE,
                  QANewEmail,
                  userId: user?.id || '',
                  userName: `${user?.firstName} ${user?.lastName}`,
                }}
                clearForm={clearForm}
                validationError={validationError}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default QACollectorView;
