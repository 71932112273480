import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useContext, useState } from 'react';

import { LoadingAndAlertContext } from '../../../../';
import SelectDropdown from '../../../../components/SelectDropdown';
import { UserFragment } from '../../../../graphql';
import { adminUpdateUserRole } from '../../../../modules/admin';
import { logError } from '../../../../modules/analytics';
import { AlertSeverity, UserRole } from '../../../../utils/constants';
import { getApiErrorMessage } from '../../../../utils/errors';


interface Props {
  user: UserFragment;
  refetchUsers?: () => Promise<{}>;
}

const UserManagementActions: React.FC<Props> = ({ user , refetchUsers }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [showUpdateUserRoleModal, setShowUpdateUserRoleModal] = useState(false);
  const [userRole, setUserRole] = useState(user.role);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { setSnackbarAlertData } = useContext(LoadingAndAlertContext);

  const userRoleOptions: SelectOption[] = Object.values(UserRole)
  .filter((value) => {
    // Ignore the deprecated old QA roles
    return !(value === UserRole.APPROVER) && !(value === UserRole.QA);
  })
  .map((value) => {
    return { value, label: value };
  });

  const onSelectUserRole = (updatedRole: string) => {
    setUserRole(updatedRole);
  };

  const handleActionMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onCloseMenu = () => {
    setAnchorEl(null);
  };

  const onCloseModals = () => {
    setErrorMessage('');
    setIsLoading(false);
    setShowUpdateUserRoleModal(false);
  };

  const onClickUpdateUserRole = () => {
    setShowUpdateUserRoleModal(true);
    setAnchorEl(null);
  };

  const onConfirmUpdateUserRole = async () => {
    let validationErrorMessage = '';
    if (!userRole) {
      validationErrorMessage = 'Please select a new user role';
    } else if (userRole === user.role) {
      validationErrorMessage = 'The selected role cannot be the same as the existing user role';
    }
    if (validationErrorMessage) {
      setErrorMessage(validationErrorMessage);
      return;
    }
    try {
      setErrorMessage('');
      setIsLoading(true);
      await adminUpdateUserRole(user.id, userRole);
      if (refetchUsers) {
        await refetchUsers();
      }
      onCloseModals();
      
      const alertData: AlertData = {
        severity: AlertSeverity.SUCCESS,
        message: `Updated ${user.firstName} ${user.lastName}'s role to ${userRole}`,
      };

      setSnackbarAlertData(alertData);
    } catch (err) {
      const errMessage = getApiErrorMessage(err);
      setErrorMessage(errMessage);
      logError(err, `Error while updating user role - ${errMessage}`);
      setIsLoading(false);
    }
  };

  return (
    <Grid container justify="center">
      <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleActionMenuClick}>
        <FontAwesomeIcon icon={faBars} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        onClose={onCloseMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem onClick={onClickUpdateUserRole}>Update User Role</MenuItem>
      </Menu>

      <Dialog open={showUpdateUserRoleModal} onClose={onCloseModals}>
        <DialogTitle disableTypography>Update User Role</DialogTitle>
        <DialogContent>
          {errorMessage && <Alert severity={AlertSeverity.ERROR}>{errorMessage}</Alert>}
          <Box>
            The current role for <i>{user.firstName} {user.lastName}</i> is {user.role}.
          </Box>
          <Box marginTop={3}>
            Updated User Role
            <SelectDropdown 
              options={userRoleOptions} 
              selectedValue={userRole} 
              onSelect={onSelectUserRole} 
              displayUserRoleChips
            />
          </Box>
          <Box marginTop={3}>
            <span role="img" aria-label="warning">
              ⚠️
            </span>
            Changing the user's role will update their permissions accordingly. Please confirm to continue.
          </Box>
        </DialogContent>
        <DialogActions>
          {isLoading && (
            <Box marginRight={2}>
              <CircularProgress />
            </Box>
          )}
          <Button variant="outlined" color="primary" onClick={onCloseModals} disabled={isLoading}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={onConfirmUpdateUserRole} disabled={isLoading}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default UserManagementActions;