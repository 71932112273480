import { useApolloClient } from '@apollo/client';
import { useEffect, useState } from 'react';

import { ClientExternalFragment, HubspotContactPropertyFragment, SalesforceContactFieldFragment } from '../graphql';
import { GQLHelpers } from '../graphql/hasura/ts';
import { IntegrationType } from '../utils/constants';
import { consoleLogDev } from '../utils/errors';

export const useCrmContactFields = (client: ClientExternalFragment) => {
  const [crmContactFields, setCrmContactFields] = useState<
    (SalesforceContactFieldFragment | HubspotContactPropertyFragment)[]
  >();
  const apolloClient = useApolloClient();

  useEffect(() => {
    const getCrmContactFields = async () => {
      consoleLogDev('Retrieving client CRM Contact fields');
      if (client.integrationType === IntegrationType.HubSpot) {
        const fieldsQuery = await GQLHelpers.Fragments.HubspotContactProperty.queryObjects({
          apolloClient,
          options: {
            variables: {
              where: {
                clientId: {
                  _eq: client.id,
                },
              },
            },
          },
        });

        // We slice the result, since Apollo's query results are immutable and creates issues
        // such as not being able to call .sort
        const sortedFields = fieldsQuery.data.hubspotContactProperty.slice().sort((fieldA, fieldB) => {
          const labelA = fieldA.label || fieldA.name;
          const labelB = fieldB.label || fieldB.name;
          return labelA.toLowerCase() > labelB.toLowerCase() ? 1 : -1;
        });

        setCrmContactFields(sortedFields);
      } else if (client.integrationType === IntegrationType.Salesforce) {
        const fieldsQuery = await GQLHelpers.Fragments.SalesforceContactField.queryObjects({
          apolloClient,
          options: {
            variables: {
              where: {
                clientId: {
                  _eq: client.id,
                },
              },
            },
          },
        });

        // We slice the result, since Apollo's query results are immutable and creates issues
        // such as not being able to call .sort
        const sortedFields = fieldsQuery.data.salesforceContactField.slice().sort((fieldA, fieldB) => {
          const labelA = fieldA.label || fieldA.name;
          const labelB = fieldB.label || fieldB.name;
          return labelA.toLowerCase() > labelB.toLowerCase() ? 1 : -1;
        });

        setCrmContactFields(sortedFields);
      }
    };

    getCrmContactFields();
  }, [client, apolloClient]);

  return crmContactFields;
};
