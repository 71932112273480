import { Box, Link, TablePagination, Typography } from '@material-ui/core';
import MaterialTable, { Column } from 'material-table';
import React from 'react';

import { tableIcons } from '../../../../components/icons';
import { ClientContactQaFragment } from '../../../../graphql';
import { getClientContactName, getDateStringFromTimestampString } from '../../../../utils/functions';
import { QA_CONTACTS_PER_PAGE } from '../QaManagement';

interface Props {
  clientContacts: ClientContactQaFragment[];
  onSelectPage: (pageNumber: number) => void;
  currentPage: number;
  clientContactCount: number;
  crmDataFieldName?: string;
  crmDataCrmType?: string | null;
}

enum QaClientContactRowField {
  serialId = 'serialId',
  name = 'name',
  createdAt = 'createdAt',
  qaAssignedAt = 'qaAssignedAt',
  crmDataFieldName = 'crmDataFieldName',
  crmDataFieldValue = 'crmDataFieldValue',
  crmCompanyName = 'crmCompanyName',
  crmTitle = 'crmTitle',
  linkedinUrl = 'linkedinUrl',
}

export interface QaClientContactRowData {
  [QaClientContactRowField.serialId]: number;
  [QaClientContactRowField.name]: string;
  [QaClientContactRowField.createdAt]: string;
  [QaClientContactRowField.qaAssignedAt]?: string | null;
  [QaClientContactRowField.crmDataFieldName]: string;
  [QaClientContactRowField.crmDataFieldValue]?: string | null;
  [QaClientContactRowField.crmCompanyName]?: string | null;
  [QaClientContactRowField.crmTitle]?: string | null;
  [QaClientContactRowField.linkedinUrl]?: string | null;
}

const QaClientContactsTable: React.FC<Props> = ({
  clientContacts,
  onSelectPage,
  currentPage,
  clientContactCount,
  crmDataFieldName,
  crmDataCrmType,
}) => {
  const contactRowData = clientContacts.map((clientContact) => {
    const crmData = crmDataFieldName
      ? clientContact.crmData.find(
          (crmDataRow) => crmDataRow.fieldName === crmDataFieldName && crmDataRow.crmType === crmDataCrmType
        )
      : null;

    const rowData: QaClientContactRowData = {
      serialId: clientContact.serialId,
      name: getClientContactName(clientContact),
      createdAt: clientContact.createdAt,
      qaAssignedAt: clientContact.qaAssignedAt,
      crmDataFieldName: crmData ? crmData.fieldName : '',
      crmDataFieldValue: crmData ? crmData.fieldValue : '-',
      linkedinUrl: clientContact.linkedinUrl,
      crmCompanyName: clientContact.crmCompanyName,
      crmTitle: clientContact.crmTitle,
    };

    return rowData;
  });

  const allColumns: Column<QaClientContactRowData>[] = [
    {
      title: 'Date Created',
      field: QaClientContactRowField.createdAt,
      render: (rowData) => {
        return getDateStringFromTimestampString(rowData.createdAt);
      },
      type: 'datetime',
    },
    {
      title: 'QA Assigned Date',
      field: QaClientContactRowField.qaAssignedAt,
      render: (rowData) => {
        return rowData.qaAssignedAt ? getDateStringFromTimestampString(rowData.qaAssignedAt) : 'not assigned';
      },
    },
    {
      title: 'Name',
      field: QaClientContactRowField.name,
    },
    {
      title: 'CRM Job',
      render: (rowData) => {
        return (
          <Box>
            <Typography variant="body2">{rowData.crmTitle || 'no title'}</Typography>
            <Typography variant="caption">{rowData.crmCompanyName}</Typography>
          </Box>
        );
      },
    },
    {
      title: `Custom CRM Data`,
      field: QaClientContactRowField.crmDataFieldValue,
      render: (rowData) => {
        return (
          <Box>
            <Typography variant="body2">{rowData.crmDataFieldValue}</Typography>
          </Box>
        );
      },
    },
    {
      title: 'LinkedIn URL',
      field: QaClientContactRowField.linkedinUrl,
      cellStyle: { width: 300, minWidth: 300 },
      headerStyle: { width: 300, minWidth: 300 },
      render: (rowData) => {
        return rowData.linkedinUrl ? (
          <Link href={`https://${rowData.linkedinUrl}`} target="_blank" rel="noopener noreferrer">
            {rowData.linkedinUrl}
          </Link>
        ) : (
          'no LinkedIn URL'
        );
      },
    },
  ];

  return (
    <MaterialTable
      icons={tableIcons}
      columns={allColumns}
      data={contactRowData}
      title="Assign Monitored Contacts"
      options={{
        draggable: false,
        search: false,
        pageSize: 20,
        sorting: false,
      }}
      onSelectionChange={() => {}}
      components={{
        Pagination: (props) => (
          <TablePagination
            {...props}
            rowsPerPage={QA_CONTACTS_PER_PAGE}
            count={clientContactCount}
            page={currentPage}
            onPageChange={(_e, pageNumber) => {
              onSelectPage(pageNumber);
            }}
          />
        ),
      }}
    />
  );
};

export default React.memo(QaClientContactsTable);
