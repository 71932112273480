import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useContext, useState } from 'react';

import { UserContext } from '../../components/auth/UserRouter';
import { GQLHooks } from '../../graphql/hasura/react';
import { logError } from '../../modules/analytics';
import { AlertSeverity, UserRole } from '../../utils/constants';
import { getApiErrorMessage } from '../../utils/errors';
import { ClientContext } from '../Main';
import DeleteNotificationEmail from './notification/DeleteNotificationEmail';

const NotificationSettings: React.FC = () => {
  const { selectedClient } = useContext(ClientContext);
  const { user } = useContext(UserContext);
  const {
    data: clientNotificationEmailsQuery,
    refetch: refetchEmailNotifications,
  } = GQLHooks.Fragments.ClientNotificationEmail.useQueryObjects({
    variables: {
      where: {
        clientId: {
          _eq: selectedClient.id,
        },
      },
    },
  });
  const [insertEmailNotification] = GQLHooks.Fragments.ClientNotificationEmail.useInsert();

  const sortedEmailNotifications = (clientNotificationEmailsQuery?.clientNotificationEmail || [])
    .slice()
    .sort((emailNotificationA, emailNotificationB) => {
      return +new Date(emailNotificationB.createdAt) - +new Date(emailNotificationA.createdAt);
    });

  const [newEmail, setNewEmail] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);
  const [alert, setAlert] = useState<AlertData | undefined>();

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewEmail(event.target.value);
  };

  const onClickInvite = async () => {
    if (!newEmail.trim()) {
      setAlert({
        severity: AlertSeverity.ERROR,
        message: 'Please enter an email address',
      });
    } else if (
      sortedEmailNotifications.find((emailNotification) => emailNotification.email === newEmail.toLowerCase().trim())
    ) {
      console.log('onClickInvite -> sortedEmailNotifications', sortedEmailNotifications);
      setAlert({
        severity: AlertSeverity.ERROR,
        message: 'Email already added to notifications',
      });
    } else {
      try {
        setAlert(undefined);
        setIsUpdating(true);

        await insertEmailNotification({
          clientNotificationEmail: {
            email: newEmail.trim().toLowerCase(),
            clientId: selectedClient.id,
          },
        });

        refetchEmailNotifications && refetchEmailNotifications();
      } catch (err) {
        const errMessage = getApiErrorMessage(err);
        logError(err, `Error inviting user to selectedClient ${selectedClient?.name} - ${errMessage}`);

        setAlert({
          severity: AlertSeverity.ERROR,
          message: errMessage,
        });
      } finally {
        setIsUpdating(false);
      }
    }
  };

  return (
    <Grid container item direction="column" alignItems="flex-start" spacing={2}>
      <Grid item>
        <Typography variant="h5">Notification Settings</Typography>
        {user?.role === UserRole.INTERNAL && selectedClient.id !== user.clientId ? (
          <Box marginTop={2}>
            <Alert severity={AlertSeverity.WARNING}>
              As an internal user, you have selected to modify {selectedClient.name}'s notification emails.
            </Alert>
          </Box>
        ) : (
          <Typography variant="subtitle1">
            By default, we send job change reports to users who have registered an account on Warmly. However, you may update
            the preferred email to receive our reports.
          </Typography>
        )}
      </Grid>

      <Grid item>
        <Typography variant="subtitle1">Emails receiving job notifications</Typography>
        {sortedEmailNotifications.map((emailNotification) => {
          return (
            <DeleteNotificationEmail
              key={emailNotification.id}
              emailNotification={emailNotification}
              refetchEmailNotifications={refetchEmailNotifications}
            />
          );
        })}
      </Grid>

      <Grid container item alignItems="flex-end" xs={8} spacing={3}>
        <Grid item xs={7} md={5}>
          <FormControl fullWidth>
            <InputLabel htmlFor="change-notification-email">Add email to job change notifications</InputLabel>
            <Input
              id="change-notification-email"
              value={newEmail}
              onChange={handleEmailChange}
              startAdornment={
                <InputAdornment position="start">
                  <FontAwesomeIcon size="lg" icon={faEnvelope} />
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
        <Grid container item alignItems="center" xs>
          <Button variant="contained" color="primary" onClick={onClickInvite} disabled={isUpdating}>
            Confirm
          </Button>
          {isUpdating && (
            <Box marginLeft={2}>
              <CircularProgress />
            </Box>
          )}
        </Grid>
      </Grid>

      <Grid item xs={12}>
        {alert && <Alert severity={alert.severity}>{alert.message}</Alert>}
      </Grid>
    </Grid>
  );
};

export default NotificationSettings;
