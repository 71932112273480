import { Button,  withStyles, } from '@material-ui/core';

const BUTTON_MARGIN = 20;

const ExportCSVButton = withStyles((theme) => ({
  root: {
    paddingLeft: BUTTON_MARGIN,
    paddingRight: BUTTON_MARGIN,
    backgroundColor: theme.palette.common.white,
    fontColor: theme.palette.common.black,
    textTransform: 'none',
    fontWeight: 300,
    borderColor: theme.palette.grey[300],
    lineHeight: 1.25,
    maxHeight: '50px',
  },
}))(Button);

export default ExportCSVButton;