import React, { useContext } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { NavigationPath, UserRole } from '../../utils/constants';
import { UserContext } from './UserRouter';

const AdminRoute: React.FC<RouteProps> = ({ children, ...options }) => {
  const { user } = useContext(UserContext);

  if (user.role !== UserRole.INTERNAL) {
    return <Redirect to={NavigationPath.MAIN} />;
  }

  return <Route {...options}>{children}</Route>;
};

export default AdminRoute;
