import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, CircularProgress, Grid, makeStyles, Tooltip, withStyles } from '@material-ui/core';
import React, { useState } from 'react';

import FirstTimeApproveModal from '../../components/crm/FirstTimeApproveModal';
import { ClientContactExternalFragment, ClientExternalFragment } from '../../graphql';
import { IntegrationType } from '../../utils/constants';

const FIXED_HEIGHT = 100;

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    height: FIXED_HEIGHT,
  },
  row: {
    height: '100%',
  },
  rowItem: {
    height: FIXED_HEIGHT,
  },
  avatar: {
    height: FIXED_HEIGHT,
    width: FIXED_HEIGHT,
  },
  dropDownButton: {
    minWidth: 30,
    padding: 2,
    marginLeft: 10,
  },
}));

export const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 12,
  },
}))(Tooltip);

interface JobChangeCrmActionProps {
  client: ClientExternalFragment;
  clientContact: ClientContactExternalFragment;
  onClickApprove: () => void;
  onClickDismiss: () => void;
  isLoading: boolean;
}

const JobChangeCrmAction: React.FC<JobChangeCrmActionProps> = ({
  client,
  clientContact,
  onClickApprove,
  onClickDismiss,
  isLoading,
}) => {
  const classes = useStyles();

  const [showFirstTimeApproveModal, setShowFirstTimeApproveModal] = useState(false);
  const isFirstTimeApprove =
    (client.integrationType === IntegrationType.HubSpot &&
      !client.hubspotIntegration?.warmlyContactPropertiesCreatedAt) ||
    (client.integrationType === IntegrationType.Salesforce &&
      !client.salesforceIntegration?.warmlyContactFieldsCreatedAt);

  // We disable syncing back to CRM if the client contact does not have integration with HubSpot or Salesforce
  const disableCrmSync = (!clientContact.salesforceId && !clientContact.hubspotId) || !client.integrationType;

  return (
    <Box width="100%" height="100%">
      <Grid container alignItems="center" className={classes.row}>
        <Grid container item direction="row" justify="center" alignItems="center" xs={12} spacing={1}>
          <Grid item xs={12}>
            <LightTooltip
              title={
                disableCrmSync
                  ? 'Contact must be synced from a CRM to enable syncing back data'
                  : 'Sync new job data from Warmly back to your CRM'
              }
            >
              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => {
                    isFirstTimeApprove ? setShowFirstTimeApproveModal(true) : onClickApprove();
                  }}
                  startIcon={<FontAwesomeIcon size="lg" icon={faCheckCircle} />}
                  disabled={disableCrmSync}
                >
                  Approve
                  <Box ml={2}>{isLoading && <CircularProgress size={18} color="secondary" />}</Box>
                </Button>
              </Box>
            </LightTooltip>
          </Grid>
          <Grid item xs={12}>
            <LightTooltip title="Do not show this specific job change notification again. This person will remain in your CRM tab.">
              <Button
                variant="contained"
                fullWidth
                onClick={onClickDismiss}
                startIcon={<FontAwesomeIcon size="lg" icon={faTimesCircle} />}
              >
                Dismiss
                <Box ml={2}>{isLoading && <CircularProgress size={18} />}</Box>
              </Button>
            </LightTooltip>
          </Grid>
        </Grid>
        {isFirstTimeApprove && (
          <FirstTimeApproveModal
            client={client}
            isOpen={showFirstTimeApproveModal}
            setIsOpen={setShowFirstTimeApproveModal}
            onConfirm={onClickApprove}
          />
        )}
      </Grid>
    </Box>
  );
};

export default JobChangeCrmAction;
