import { createStyles, makeStyles, Tooltip } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Skeleton } from '@material-ui/lab';
import React, { useContext } from 'react';

import { useCrmContactFields } from '../../../../hooks/graphql';
import { ClientContactAttribute, IntegrationType } from '../../../../utils/constants';
import { ClientContext } from '../../../Main';
import MapAttributesRow from './MapAttributesRow';


const useStyles = makeStyles(() =>
  createStyles({
    tableCellHead: {
      minWidth: 200,
      fontWeight: 'bold', 
      padding: 16,
    }
  })
);

interface Props {
  integrationMapping: HubspotMapping;
  setIntegrationMapping: React.Dispatch<React.SetStateAction<HubspotMapping>>;
}

const MapAttributesTable: React.FC<Props> = ({ integrationMapping, setIntegrationMapping }) => {
  const { selectedClient } = useContext(ClientContext);
  const crmContactFields = useCrmContactFields(selectedClient);

  const classes = useStyles();

  const warmlyAttributes = [
    ClientContactAttribute.FIRST_NAME,
    ClientContactAttribute.LAST_NAME,
    ClientContactAttribute.EMAIL,
    ClientContactAttribute.CURRENT_TITLE,
    ClientContactAttribute.LINKEDIN_URL,
    ClientContactAttribute.LINKEDIN_ID,
  ];

  // For salesforce we pull the company name from the company object, so we
  // do not allow the client to set their own company name (for now)
  if (selectedClient.integrationType === IntegrationType.HubSpot) {
    warmlyAttributes.push(ClientContactAttribute.CURRENT_COMPANY);
  }

  const columns = [
    { id: 'warmly_attribute', label: 'Warmly attribute (*required)', minWidth: 200 },
    {
      id: `${selectedClient.integrationType}_field_name`,
      label:
        selectedClient.integrationType === IntegrationType.HubSpot ? 'HubSpot property name' : 'Associated object',
      minWidth: 200,
    },
    {
      id: `${selectedClient.integrationType}_field_selected`,
      label: selectedClient.integrationType === IntegrationType.HubSpot ? 'Select property' : 'Select field',
      minWidth: 200,
    },
  ];

  return crmContactFields ? (
    <Paper>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              {columns.map((column) => {
                if (column.id === `${selectedClient.integrationType}_field_name`) {
                  return (
                    <Tooltip
                      key={column.id}
                      title={`This is the unique identifier name that ${selectedClient.integrationType} uses under the hood, don't worry if you don't recognize it!`}
                    >
                      <TableCell align="left" className={classes.tableCellHead}>
                        {column.label}
                      </TableCell>
                    </Tooltip>
                  );
                } else {
                  return (
                    <TableCell key={column.id} align="left" className={classes.tableCellHead}>
                      {column.label}
                    </TableCell>
                  );
                }
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {warmlyAttributes.map((attribute: ClientContactAttribute) => {
              const onChangeProperty = (propertyName: string) => {
                setIntegrationMapping({ ...integrationMapping, [attribute]: propertyName });
              };

              return (
                <MapAttributesRow
                  key={attribute}
                  warmlyAttribute={attribute}
                  onChangeProperty={onChangeProperty}
                  integrationType={selectedClient.integrationType as IntegrationType}
                  crmContactFields={crmContactFields}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  ) : (
    <Skeleton />
  );
};

export default MapAttributesTable;
