import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faAddressCard, faAt, faCalendarDay, faIdCardAlt, faLaptop, faTable } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { LoadingAndAlertContext } from '../..';
import { logError } from '../../modules/analytics';
import { createEnrichCrmCloudTask } from '../../modules/cloudFunctions';
import { ClientContext } from '../../pages/Main';
import {
  AlertSeverity,
  HubspotURL,
  IntegrationType,
  NavigationPath,
  SalesforceURL,
  WARMLY_EMAIL,
  WarmlyColor,
} from '../../utils/constants';

interface ConfirmCRMApproveModalProps {
  showConfirmCRMApproveModal: boolean;
  setShowConfirmCRMApproveModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ConfirmCRMApproveModal: React.FC<ConfirmCRMApproveModalProps> = ({
  showConfirmCRMApproveModal,
  setShowConfirmCRMApproveModal,
}) => {
  const { selectedClient } = useContext(ClientContext);
  const { setIsLoading, setSnackbarAlertData } = useContext(LoadingAndAlertContext);
  const history = useHistory();
  if (
    selectedClient?.integrationType !== IntegrationType.HubSpot &&
    selectedClient?.integrationType !== IntegrationType.Salesforce
  ) {
    return null;
  }

  const integrationType = selectedClient?.integrationType;
  const fieldDisplayText = integrationType === IntegrationType.HubSpot ? 'property' : 'field';
  const fieldDisplayTextPlural = integrationType === IntegrationType.HubSpot ? 'properties' : 'fields';
  const isFirstTimeApprove =
    (selectedClient.integrationType === IntegrationType.HubSpot &&
      !selectedClient.hubspotIntegration?.warmlyContactPropertiesCreatedAt) ||
    (selectedClient.integrationType === IntegrationType.Salesforce &&
      !selectedClient.salesforceIntegration?.warmlyContactFieldsCreatedAt);

  const onClose = () => {
    setShowConfirmCRMApproveModal(false);
  };

  const onConfirmSyncAll = async () => {
    try {
      setIsLoading(true);
      await createEnrichCrmCloudTask(selectedClient);

      const alertData: AlertData = {
        severity: AlertSeverity.SUCCESS,
        message: `Now syncing back data to ${integrationType}`,
      };

      setSnackbarAlertData(alertData);
      history.push(NavigationPath.INTEGRATION);
    } catch (err) {
      logError(err, 'Error while syncing back CRM data');
      const alertData: AlertData = {
        severity: AlertSeverity.ERROR,
        message: `Error occurred while syncing back CRM data, our team has been notified of the issue`,
      };

      setSnackbarAlertData(alertData);
      setShowConfirmCRMApproveModal(false);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={showConfirmCRMApproveModal} onClose={onClose}>
      <DialogTitle>Sync data back to CRM</DialogTitle>
      <DialogContent>
        We will sync back the following enriched data for your contacts to {integrationType}:
        <List dense={true}>
          <ListItem>
            <ListItemIcon>
              <FontAwesomeIcon icon={faAddressCard} color={WarmlyColor.DARK_BLUE} />
            </ListItemIcon>
            New Title
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <FontAwesomeIcon icon={faAddressCard} color={WarmlyColor.DARK_BLUE} />
            </ListItemIcon>
            New Company
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <FontAwesomeIcon icon={faLaptop} color={WarmlyColor.DARK_BLUE} />
            </ListItemIcon>
            New Company URL
          </ListItem>
          {selectedClient.salesforceIntegration?.accountsLastSyncedAt && (
            <ListItem>
              <ListItemIcon>
                <FontAwesomeIcon icon={faIdCardAlt} color={WarmlyColor.DARK_BLUE} />
              </ListItemIcon>
              New Company Account
            </ListItem>
          )}
          <ListItem>
            <ListItemIcon>
              <FontAwesomeIcon icon={faAt} color={WarmlyColor.DARK_BLUE} />
            </ListItemIcon>
            New Email
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <FontAwesomeIcon icon={faLinkedin} color={WarmlyColor.DARK_BLUE} />
            </ListItemIcon>
            LinkedIn URL
          </ListItem>
          {selectedClient.salesforceIntegration?.accountsLastSyncedAt && (
            <ListItem>
              <ListItemIcon>
                <FontAwesomeIcon icon={faCalendarDay} color={WarmlyColor.DARK_BLUE} />
              </ListItemIcon>
              Last Position Change Date
            </ListItem>
          )}
        </List>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography style={{ fontWeight: 'bold' }}>How does this work?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container alignItems="center">
              <Box>In order to sync back enriched data in your CRM, we will:</Box>
              <List dense={true}>
                <ListItem>
                  <ListItemIcon>
                    <FontAwesomeIcon icon={faTable} color={WarmlyColor.DARK_BLUE} />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      integrationType === IntegrationType.Salesforce
                        ? `Create six custom ${fieldDisplayTextPlural} to store the enriched data: New Title, New Company, New Company Account, LinkedIn URL, New Company URL, and New Email`
                        : `Create five custom ${fieldDisplayTextPlural} to store the enriched data: Latest Job Title, Latest Company Name, LinkedIn URL, New Company URL, and New Email`
                    }
                    secondary={
                      integrationType === IntegrationType.HubSpot ? (
                        <Link href={HubspotURL.CUSTOM_PROPERTIES} target="_blank" rel="noopener noreferrer">
                          What’s this? Open new tab for help page.
                        </Link>
                      ) : (
                        <Link href={SalesforceURL.ADD_CUSTOM_FIELD} target="_blank" rel="noopener noreferrer">
                          What’s this? Open new tab for help page.
                        </Link>
                      )
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FontAwesomeIcon icon={faTable} color={WarmlyColor.DARK_BLUE} />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      integrationType === IntegrationType.Salesforce
                        ? `Create one other custom ${fieldDisplayText}, Last Position Change Date, which is the approximate start date for the most recent job position`
                        : `Create one other custom ${fieldDisplayText}, Job Change, to indicate if we detected a job change (true / false). This is only synced if you hit APPROVE in the Job Changes page`
                    }
                    secondary={
                      integrationType === IntegrationType.HubSpot ? (
                        <Link href={HubspotURL.CUSTOM_PROPERTIES} target="_blank" rel="noopener noreferrer">
                          What’s this? Open new tab for help page.
                        </Link>
                      ) : (
                        <Link href={SalesforceURL.ADD_CUSTOM_FIELD} target="_blank" rel="noopener noreferrer">
                          What’s this? Open new tab for help page.
                        </Link>
                      )
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <FontAwesomeIcon icon={faTable} color={WarmlyColor.DARK_BLUE} />
                  </ListItemIcon>
                  <ListItemText
                    primary={`Never overwrite any non-Warmly-created ${fieldDisplayTextPlural} in your CRM`}
                  />
                </ListItem>
              </List>
              {isFirstTimeApprove && (
                <Box marginBottom={2} fontWeight="bold">
                  Please note that it may take up to ~1 minute to set up these {fieldDisplayTextPlural} when you sync
                  for the first time
                </Box>
              )}
              <Box>
                As always, if you have any questions, please contact us at{' '}
                <Link
                  href={`https://mail.google.com/mail/?view=cm&fs=1&to=${WARMLY_EMAIL.CSM}&su=[Warmly] Question about CRM Sync`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {WARMLY_EMAIL.CSM}
                </Link>
              </Box>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" color="primary">
          Cancel
        </Button>
        <Button onClick={onConfirmSyncAll} variant="contained" color="primary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
