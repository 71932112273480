import { Box, Grid, Link, Typography } from '@material-ui/core';
import React from 'react';

import NavBar from '../components/NavBar';
import { ERROR_CAT, WARMLY_EMAIL, WarmlyColor } from '../utils/constants';

const GenericError: React.FC = () => {
  return (
    <>
      <NavBar />
      <Grid
        container
        direction="column"
        alignItems="center"
        justify="center"
        style={{ backgroundColor: WarmlyColor.WHITE }}
        spacing={3}
      >
        <Grid item>
          <Typography variant="h1">Oh no!</Typography>
        </Grid>
        <Grid item xs={6} md={4}>
          <img className="person-standing" alt="Person" src={ERROR_CAT} width="100%" />
        </Grid>
        <Grid item xs={6}>
          <Box textAlign="center">
            <Typography variant="h5">
              Our server encountered an error while processing your request, we are terribly sorry and will get on to it
              right away! If you have any questions, please feel free to contact us any time:
            </Typography>
            <Typography variant="h5">
              <Link
                target="_blank"
                rel="noopener noreferrer"
                href={`https://mail.google.com/mail/?view=cm&fs=1&to=${WARMLY_EMAIL.SUPPORT_GENERAL}&su=[Warmly] Question`}
              >
                {WARMLY_EMAIL.SUPPORT_GENERAL}
              </Link>
            </Typography>
            <Box marginY={1}>
              <Typography variant="caption">
                <Link target="_blank" rel="noopener noreferrer" href="https://ls.graphics/illustrations">
                  illustration provided by ls.graphics
                </Link>
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default GenericError;
