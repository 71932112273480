import Axios from 'axios';

import { gaeApiURL } from '../utils/constants';
import { getAuthToken } from './auth';

export const confirmQATask = async (qaTaskActionId: string) => {
  const functionURL = gaeApiURL.qaTask.confirmQATask;

  const authToken = await getAuthToken();

  const body = {
    qaTaskActionId,
  };

  return Axios.post(functionURL, body, {
    headers: {
      Authorization: 'Bearer ' + authToken,
      'Cache-Control': 'no-cache',
    },
  });
};

export interface CreateQaTasks {
  clientContactFilterId?: string;
  csvUploadId?: string;
  excludeAlreadyAssigned: boolean;
  priority: number;
  limit: number;
  clientId: string;
}

export const createQaTasks = async (body: CreateQaTasks) => {
  const functionURL = gaeApiURL.qaTask.createQATasks;

  const authToken = await getAuthToken();

  return Axios.post(functionURL, body, {
    headers: {
      Authorization: 'Bearer ' + authToken,
      'Cache-Control': 'no-cache',
    },
  });
};

export const getAssignedQaTask = async () => {
  const functionURL = gaeApiURL.qaTask.getQATask;

  const authToken = await getAuthToken();

  return Axios.get(functionURL, {
    headers: {
      Authorization: 'Bearer ' + authToken,
      'Cache-Control': 'no-cache',
    },
  });
};
