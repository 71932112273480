import { Box, Button, CircularProgress, Grid, Link, Typography } from '@material-ui/core';
import React, { useContext, useState } from 'react';

import { UserContext } from '../../components/auth/UserRouter';
import { GQLHooks } from '../../graphql/hasura/react';
import { ClientOwnerMappingStatus, UserClientPermission } from '../../utils/constants';
import { WarmlyHelpCenterArticleUrl } from '../../utils/constantsAll/url';
import { ClientContext } from '../Main';
import TerritoryOwnerMappingItem from './territoryOwnerMapping/TerritoryOwnerMappingItem';
import ConfirmMappingModal from './users/ConfirmMappingModal';
import InviteUser from './users/InviteUser';

const TerritoryOwnerMapping: React.FC = () => {
  const [showInviteUser, setShowInviteUser] = useState(false);
  const [showConfirmMappingsModal, setShowConfirmMappingsModal] = useState(false);
  const { selectedClient } = useContext(ClientContext);
  const { user } = useContext(UserContext);

  const isAdmin = user.clientPermission === UserClientPermission.Admin;

  const {
    refetch: refetchClientTerritories,
    objects: clientTerritories,
  } = GQLHooks.Fragments.ClientTerritory.useQueryObjects({
    variables: {
      where: {
        clientId: {
          _eq: selectedClient.id,
        },
      },
    },
  });

  const { objects: users } = GQLHooks.Fragments.User.useQueryObjects({
    variables: {
      where: {
        clientId: {
          _eq: selectedClient.id,
        },
      },
    },
  });

  const onAddOwnerClicked = () => {
    setShowInviteUser(!showInviteUser);
  };

  return (
    <Grid container direction="row" spacing={2}>
      <Grid container item spacing={1}>
        <Grid item xs={12}>
          <Box fontWeight="bold">
            Owner Mapping allows you to map potential leads to members of your team based on territory and company size.
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box display="block">
            <Link
              underline="always"
              href={WarmlyHelpCenterArticleUrl.OwnerMapping}
              target="_blank"
              rel="noopener noreferrer"
            >
              More details here.
            </Link>
          </Box>
        </Grid>
      </Grid>

      <Grid container item direction="column" alignItems="center">
        <Grid container direction="row" alignItems="flex-start" spacing={1}>
          <Grid item xs={2}>
            <Box marginLeft={2} fontWeight="bold">
              Name
            </Box>
          </Grid>
          <Grid item xs={10}>
            <Box fontWeight="bold">Territories</Box>
          </Grid>
        </Grid>
        {users &&
          clientTerritories &&
          users.map((user) => {
            return (
              <TerritoryOwnerMappingItem
                key={user.id}
                isAdmin={isAdmin}
                refetchClientTerritories={refetchClientTerritories!}
                user={user}
                clientTerritories={clientTerritories}
              />
            );
          })}
      </Grid>

      {isAdmin && (
        <Grid container item spacing={2} alignItems="center" justify="flex-end">
          <Grid item>
            <Button
              size="large"
              variant="contained"
              color="primary"
              onClick={() => {
                setShowConfirmMappingsModal(true);
              }}
              disabled={selectedClient.ownerMappingStatus === ClientOwnerMappingStatus.AssigningOwners}
            >
              Confirm Mapping
            </Button>
          </Grid>
          {selectedClient.ownerMappingStatus === ClientOwnerMappingStatus.AssigningOwners && (
            <>
              <Grid item>
                <CircularProgress size={25} />
              </Grid>
              <Grid item>
                <Typography variant="subtitle2">Confirming mapping...</Typography>
              </Grid>
            </>
          )}
          <ConfirmMappingModal isOpen={showConfirmMappingsModal} setIsOpen={setShowConfirmMappingsModal} />
        </Grid>
      )}

      <Grid container item>
        <Box marginRight="auto">
          <Button variant="outlined" color="primary" onClick={onAddOwnerClicked}>
            {showInviteUser ? '➖ Add User' : '➕ Add User'}
          </Button>
        </Box>
      </Grid>
      <Grid container item>
        <Box display={showInviteUser ? undefined : 'none'} width="100%">
          <InviteUser />
        </Box>
      </Grid>
    </Grid>
  );
};

export default TerritoryOwnerMapping;
