import Skeleton from '@material-ui/lab/Skeleton';
import MaterialTable from 'material-table';
import React, { useEffect, useState } from 'react';

import { tableIcons } from '../../../../components/icons';
import { UserFragment } from '../../../../graphql';
import { getDateTimeStringFromTimestampString } from '../../../../utils/functions';
import UserManagementActions from './UserManagementActions';
import UsersManagementRoleChip from './UsersManagementRoleChip';

interface Props {
  users: UserFragment[];
  loading: boolean;
  refetchUsers?: () => Promise<{}>;
}

enum UsersManagementRowField {
  FirstName = 'FirstName',
  LastName = 'LastName',
  Email = 'Email',
  Role = 'Role',
  CreatedAt = 'CreatedAt',
  UpdatedAt = 'UpdatedAt',
  ClientName = 'ClientName',
  User = 'User',
}

type UsersManagementRowData = Record<UsersManagementRowField, string | undefined | null | UserFragment> 

const UsersManagementTable: React.FC<Props> = ({ users, loading, refetchUsers }) => {

  const [isLoading, setIsLoading] = useState(loading);
  useEffect(() => { setIsLoading(loading) }, [loading]);

  const rows: UsersManagementRowData[] = users.map((user) => {
    return {
      [UsersManagementRowField.FirstName]: user.firstName,
      [UsersManagementRowField.LastName]: user.lastName,
      [UsersManagementRowField.Email]: user.email || 'None found',
      [UsersManagementRowField.Role]: user.role,
      [UsersManagementRowField.CreatedAt]: user.createdAt,
      [UsersManagementRowField.UpdatedAt]: user.updatedAt,
      [UsersManagementRowField.ClientName]: user.client.name,
      [UsersManagementRowField.User]: user,
    };
  });

  const displaySkeleton = (width?: number, margin?: string) => {
    return <Skeleton variant="rect" width={width} style={{ marginLeft: margin }} />
  }

  return (
    <MaterialTable<UsersManagementRowData>
      icons={tableIcons}
      columns={[
        {
          title: 'First Name',
          field: UsersManagementRowField.FirstName,
          render: rowData => { 
            return isLoading ? displaySkeleton() : rowData.FirstName;
          }
        },
        {
          title: 'Last Name',
          field: UsersManagementRowField.LastName,
          render: rowData => { 
            return isLoading ? displaySkeleton() : rowData.LastName;
          }
        },
        {
          title: 'Email',
          field: UsersManagementRowField.Email,
          render: rowData => { 
            return isLoading ? displaySkeleton() : rowData.Email;
          }
        },
        {
          title: 'Client',
          field: UsersManagementRowField.ClientName,
          render: rowData => { 
            return isLoading ? displaySkeleton() : rowData.ClientName;
          }
        },
        {
          title: 'Role',
          field: UsersManagementRowField.Role,
          render: rowData => {
            return isLoading ? displaySkeleton() : <UsersManagementRoleChip role={rowData.Role as string} />
          },
        },
        {
          title: 'Created At',
          field: UsersManagementRowField.CreatedAt,
          type: 'datetime',
          filtering: false,
          render: rowData => { 
            return isLoading ? displaySkeleton() : getDateTimeStringFromTimestampString(rowData.CreatedAt as string);
          }
        },
        {
          title: 'Updated At',
          field: UsersManagementRowField.UpdatedAt,
          type: 'datetime',
          filtering: false,
          render: rowData => { 
            return isLoading ? displaySkeleton() : getDateTimeStringFromTimestampString(rowData.UpdatedAt as string);
          }
        },
        {
          title: 'Actions',
          render: (rowData) => { 
            return isLoading ?
            displaySkeleton(30, "35%")
            : <UserManagementActions user={rowData.User as UserFragment} refetchUsers={refetchUsers} />;
          },
          sorting: false,
          filtering: false,
          cellStyle: { width: 50 },
          headerStyle: { width: 50, textAlign: 'center' },
        },
      ]}
      data={rows}
      title="Users Management"
      options={{
        pageSize: 20,
        filtering: true,
      }}
    />
  );
};

export default UsersManagementTable;
