import { Box, Grid, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import MaterialTable from 'material-table';
import React, { useState } from 'react';

import { tableIcons } from '../../../components/icons';
import SelectDropdown from '../../../components/SelectDropdown';
import { Order_By } from '../../../graphql';
import { GQLHooks } from '../../../graphql/hasura/react';
import { taskQueue } from '../../../utils/constants';
import CloudTasksActions from './cloudTasks/CloudTasksActions';

const selectOptions: SelectOption[] = [
  { label: taskQueue.sendProxyCrawlRequests.name, value: taskQueue.sendProxyCrawlRequests.name },
  { label: taskQueue.syncCrmContacts.name, value: taskQueue.syncCrmContacts.name },
  { label: taskQueue.clientContactActions.name, value: taskQueue.clientContactActions.name },
  { label: taskQueue.scheduledEmail.name, value: taskQueue.scheduledEmail.name },
  { label: taskQueue.scheduledQuickActions.name, value: taskQueue.scheduledQuickActions.name },
];

enum CloudTaskRowField {
  id = 'id',
  queueName = 'queueName',
  updatedAt = 'updatedAt',
  createdByUserText = 'createdByUserText',
  description = 'description',
  status = 'status',
  executionTime = 'executionTime',
  taskIdentifier = 'taskIdentifier',
  taskActionData = 'taskActionData',
}

export interface CloudTaskRowData {
  [CloudTaskRowField.id]: string;
  [CloudTaskRowField.queueName]: string;
  [CloudTaskRowField.updatedAt]: Date;
  [CloudTaskRowField.createdByUserText]: string;
  [CloudTaskRowField.description]: string;
  [CloudTaskRowField.status]: string;
  [CloudTaskRowField.executionTime]?: number;
  [CloudTaskRowField.taskIdentifier]: string;
}

const CloudTasks: React.FC = () => {
  const [selectedQueueName, setSelectedQueueName] = useState<string>(taskQueue.sendProxyCrawlRequests.name);

  const onSelectQueueName = (queueName: string) => {
    setSelectedQueueName(queueName);
  };

  const { data: cloudTasks } = GQLHooks.Fragments.CloudTask.useSubscribeToObjects({
    variables: {
      where: {
        queueName: {
          _eq: selectedQueueName,
        },
      },
      limit: 40,
      order_by: [
        {
          updatedAt: Order_By.Desc,
        },
      ],
    },
  });

  const cloudTaskRows: CloudTaskRowData[] = (cloudTasks?.cloudTask || []).map((cloudTask) => {
    return {
      id: cloudTask.id,
      queueName: cloudTask.queueName,
      updatedAt: new Date(cloudTask.updatedAt),
      createdByUserText: cloudTask.createdByUser
        ? `${cloudTask.createdByUser?.firstName} ${cloudTask.createdByUser?.lastName}`
        : '-',
      description: cloudTask.description || '-',
      status: cloudTask.status || '-',
      executionTime: cloudTask.executionTime || undefined,
      taskIdentifier: cloudTask.taskIdentifier || '-',
    };
  });

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h6" color="primary">
          Cloud Tasks - {selectedQueueName ? selectedQueueName : 'None Selected'}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <SelectDropdown options={selectOptions} selectedValue={selectedQueueName} onSelect={onSelectQueueName} />
      </Grid>
      {!selectedQueueName && (
        <Grid item xs={12}>
          <Typography>Please select a queue name to manage its associated cloud tasks</Typography>
        </Grid>
      )}
      {selectedQueueName && (
        <Grid item xs={12}>
          {cloudTasks ? (
            <MaterialTable<CloudTaskRowData>
              icons={tableIcons}
              columns={[
                {
                  title: 'Updated',
                  field: CloudTaskRowField.updatedAt,
                  cellStyle: { width: 120, minWidth: 120, maxWidth: 120 },
                  headerStyle: { width: 120, minWidth: 120, maxWidth: 120 },
                  render: (rowData) => {
                    return rowData.updatedAt.toLocaleString();
                  },
                },
                {
                  title: 'Unique ID',
                  field: CloudTaskRowField.taskIdentifier,
                  cellStyle: { width: 200, minWidth: 200, maxWidth: 200 },
                  headerStyle: { width: 200, minWidth: 200, maxWidth: 200 },
                  render: (rowData) => {
                    return <Box style={{ overflowWrap: 'anywhere' }}>{rowData.taskIdentifier}</Box>;
                  },
                },
                {
                  title: 'Description',
                  field: CloudTaskRowField.description,
                  cellStyle: { width: 400, minWidth: 400, maxWidth: 400 },
                  headerStyle: { width: 400, minWidth: 400, maxWidth: 400 },
                },
                {
                  title: 'Status',
                  field: CloudTaskRowField.status,
                },
                {
                  title: 'Execution Time (sec)',
                  field: CloudTaskRowField.executionTime,
                  render: (rowData) => rowData.executionTime?.toLocaleString(),
                },
                {
                  title: 'Created By',
                  field: CloudTaskRowField.createdByUserText,
                },
                {
                  title: 'Actions',
                  render: (rowData) => {
                    return <CloudTasksActions cloudTask={rowData} />;
                  },
                  sorting: false,
                  cellStyle: { width: 50 },
                  headerStyle: { width: 50, textAlign: 'center' },
                },
              ]}
              data={cloudTaskRows}
              title="Recent 40 Tasks"
              options={{
                pageSize: 20,
              }}
            />
          ) : (
            <Skeleton variant="rect" height={80} />
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default CloudTasks;
