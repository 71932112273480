import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Autocomplete from '@material-ui/lab/Autocomplete';
import parse from 'autosuggest-highlight/parse';
import { debounce } from 'lodash';
import React, { useEffect, useMemo, useRef } from 'react';

import { ExternalScriptStatus, useExternalScript } from '../../../hooks/util';
import { GOOGLE_MAPS_API_KEY } from '../../../utils/constants';
import { consoleLogDev } from '../../../utils/errors';

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}));

interface Props {
  onSelectPlace: (place: google.maps.places.AutocompletePrediction) => Promise<void>;
}

// https://sebastiandedeyne.com/writing-a-custom-react-hook-google-places-autocomplete/
const TerritoryAutocomplete: React.FC<Props> = ({ onSelectPlace }) => {
  const classes = useStyles();
  const [searchValue, setSearchValue] = React.useState('');
  const [options, setOptions] = React.useState<google.maps.places.AutocompletePrediction[]>([]);
  const [inputValue, setInputValue] = React.useState('');
  const googleMapsScriptStatus = useExternalScript(
    `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`
  );

  const autocompleteService = useRef<google.maps.places.AutocompleteService>();

  if (!autocompleteService.current && googleMapsScriptStatus === ExternalScriptStatus.Ready) {
    const googleService = window.google || google;

    autocompleteService.current = new googleService.maps.places.AutocompleteService();
    consoleLogDev(`🗺 Google Maps script loaded`);
  }

  const debouncedHandleInputChange = useMemo(() => {
    return debounce((value: string) => {
      setSearchValue(value);
    }, 300);
  }, []);

  useEffect(() => {
    if (!autocompleteService.current || !searchValue.trim()) {
      setOptions([]);
      return;
    }

    autocompleteService.current.getPlacePredictions({ input: searchValue, types: ['(regions)'] }, (result, status) => {
      setOptions(result || []);
    });
  }, [searchValue]);

  return (
    <Autocomplete
      style={{ width: 300 }}
      selectOnFocus
      clearOnBlur
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
      // We need specific settings to fix a Material UI warning
      // https://github.com/mui-org/material-ui/issues/18514#issuecomment-680133120
      options={options}
      getOptionSelected={(option, value) => {
        return option.place_id === value.place_id;
      }}
      filterSelectedOptions
      value={null}
      onChange={(_event, newSelectedPlace) => {
        if (newSelectedPlace) {
          onSelectPlace(newSelectedPlace).then(() => {
            setInputValue('');
          });
        }
      }}
      inputValue={inputValue}
      onInputChange={(_event, newInputValue) => {
        setInputValue(newInputValue);

        // The debounced function handles searching after the input value has changed
        debouncedHandleInputChange(newInputValue);
      }}
      renderInput={(params) => <TextField {...params} label="Search" variant="outlined" fullWidth />}
      renderOption={(option) => {
        const matches = option.structured_formatting.main_text_matched_substrings;
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match: any) => [match.offset, match.offset + match.length])
        );

        return (
          <Grid container alignItems="center">
            <Grid item>
              <LocationOnIcon className={classes.icon} />
            </Grid>
            <Grid item xs>
              {parts.map((part, index) => (
                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                  {part.text}
                </span>
              ))}
              <Typography variant="body2" color="textSecondary">
                {option.structured_formatting.secondary_text}
              </Typography>
            </Grid>
          </Grid>
        );
      }}
    />
  );
};

export default TerritoryAutocomplete;
