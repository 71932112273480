import { useReactiveVar } from '@apollo/client';
import { Box, Button, CircularProgress, Grid, Hidden, TextField, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Redirect } from 'react-router-dom';

import NavBar from '../components/NavBar';
import { firebaseAuth } from '../config/firebase';
import { analyticsSignUpEvent, logError } from '../modules/analytics';
import { verifyRecaptcha } from '../modules/cloudFunctions';
import { signUpUser, UserSignUpData } from '../modules/user';
import {
  AlertSeverity,
  GOOGLE_RECAPTCHA_SITE_KEY,
  IMAGE_PERSON_STANDING,
  NavigationPath,
  WarmlyColor,
} from '../utils/constants';
import { WarmlyUrl } from '../utils/constantsAll/url';
import { getAuthErrorMessage, isAuthError } from '../utils/errors';
import { apolloLoggedIn } from './ApolloClientProvider';

export default function SignUp() {
  const [isLoading, setIsLoading] = useState(false);
  const loggedIn = useReactiveVar(apolloLoggedIn);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [recaptchaValue, setRecaptchaValue] = useState('');

  const onSubmit = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    let validationErrorMessage = '';
    if (!email.trim() || !firstName.trim() || !lastName.trim() || !companyName.trim()) {
      validationErrorMessage = 'Required fields: ';
      let emptyFields = [];
      if (!email) {
        emptyFields.push('email');
      }
      if (!firstName) {
        emptyFields.push('first name');
      }
      if (!lastName) {
        emptyFields.push('last name');
      }
      if (!companyName) {
        emptyFields.push('company');
      }
      validationErrorMessage += emptyFields.join(', ');
      validationErrorMessage += '. ';
    }

    if (validationErrorMessage) {
      setErrorMessage(validationErrorMessage);
      setIsLoading(false);
      return;
    }

    try {
      setIsLoading(true);

      await verifyRecaptcha(recaptchaValue);
    } catch {
      setErrorMessage('ReCaptcha failed, please try again');
      return;
    }

    try {
      const authUserCredential = await firebaseAuth.createUserWithEmailAndPassword(email, password);

      const authUser = authUserCredential.user;

      if (!authUser) {
        return <Redirect to={NavigationPath.GENERIC_ERROR} />;
      }

      const userSignUpData: UserSignUpData = {
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        email: email.trim(),
        clientName: companyName.trim(),
        firebaseUid: authUser.uid,
      };

      await signUpUser(userSignUpData);

      analyticsSignUpEvent({
        firstName,
        lastName,
        clientName: companyName,
        email,
        companyName_fullName: `${companyName}_${firstName}_${lastName}`,
      });

      // We need to force a refresh on the Firebase token before refreshing the page,
      // so ApolloClientProvider can get the latest custom claims
      authUser.getIdToken(true);
      // Firebase authUser does not mutate in response to custom claims change
      // so we need to force a page refresh so ApolloClientProvider
      // can retrieve authUser with the latest custom claims
      window.location.assign(NavigationPath.UPLOAD);
    } catch (err) {
      if (!isAuthError(err)) {
        // We only log errors if they are not basic auth errors
        logError(err, 'Error while creating new user');
      }
      const errorMessage = getAuthErrorMessage(err);
      setErrorMessage(errorMessage);
      setIsLoading(false);
    }
  };

  if (loggedIn) {
    return <Redirect to={NavigationPath.MAIN} />;
  }

  const onChangeReCaptcha = (value: string | null) => {
    if (value) {
      setRecaptchaValue(value);
    }
  };

  const onExpiredReCaptcha = () => {
    setRecaptchaValue('');
  };

  return (
    <>
      <NavBar />
      <Grid container justify="center" style={{ backgroundColor: WarmlyColor.WHITE }}>
        <Hidden xsDown>
          <Grid item sm={3}>
            <img className="person-standing" alt="Person" src={IMAGE_PERSON_STANDING} width="75%" />
          </Grid>
        </Hidden>

        <Grid item xs={8} sm={6} md={4}>
          <form>
            <Box marginTop={4} marginLeft={-4}>
              <Typography variant="h1" color="primary">
                Sign up for your account
              </Typography>

              <Box marginTop={3}>
                <TextField
                  label="First Name*"
                  value={firstName}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setFirstName(event.target.value);
                  }}
                  fullWidth
                />
              </Box>

              <Box marginTop={3}>
                <TextField
                  label="Last Name*"
                  value={lastName}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setLastName(event.target.value);
                  }}
                  fullWidth
                />
              </Box>

              <Box marginTop={3}>
                <TextField
                  label="Company name*"
                  value={companyName}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setCompanyName(event.target.value);
                  }}
                  fullWidth
                />
              </Box>

              <Box marginTop={3}>
                <TextField
                  label="Email*"
                  type="email"
                  value={email}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setEmail(event.target.value);
                  }}
                  fullWidth
                />
              </Box>

              <Box marginTop={3}>
                <TextField
                  label="Password*"
                  type="password"
                  value={password}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setPassword(event.target.value);
                  }}
                  fullWidth
                />
              </Box>

              <Box marginTop={3}>
                <ReCAPTCHA
                  sitekey={GOOGLE_RECAPTCHA_SITE_KEY}
                  onChange={onChangeReCaptcha}
                  onExpired={onExpiredReCaptcha}
                />
              </Box>

              <Box marginTop={3}>
                {isLoading ? (
                  <Box marginLeft={2}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    onClick={onSubmit}
                    disabled={isLoading || !recaptchaValue}
                  >
                    Create Account
                  </Button>
                )}
              </Box>

              <Box marginTop={3}>
                <Typography variant="body2" color="textPrimary">
                  By clicking Create Account you are agreeing to our{' '}
                  <a target="_blank" rel="noopener noreferrer" href={WarmlyUrl.ToS}>
                    Terms of Service
                  </a>{' '}
                  and{' '}
                  <a target="_blank" rel="noopener noreferrer" href={WarmlyUrl.PrivacyPolicy}>
                    Privacy Policy
                  </a>
                  .
                </Typography>
              </Box>

              <Box marginTop={3}>{errorMessage && <Alert severity={AlertSeverity.ERROR}>{errorMessage}</Alert>}</Box>
            </Box>
          </form>
        </Grid>
      </Grid>
    </>
  );
}
