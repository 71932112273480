import { faHubspot, faSalesforce } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Icon, Typography } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import BusinessOutlinedIcon from '@material-ui/icons/BusinessOutlined';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import ContactSupportOutlinedIcon from '@material-ui/icons/ContactSupportOutlined';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import LibraryBooksOutlinedIcon from '@material-ui/icons/LibraryBooksOutlined';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import React, { useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { LightTooltip } from '../pages/dashboard/jobChanges/NewJobChangeCrmAction';
import { ClientContext } from '../pages/Main';
import { IntegrationType, NavigationPath, UserRole, WARMLY_LOGO_VERTICAL_COLOR, WarmlyColor } from '../utils/constants';
import { WarmlyUrl } from '../utils/constantsAll/url';
import { UserContext } from './auth/UserRouter';
import SubMenu, { SubMenuItem } from './sidebar/SubMenu';

const FIXED_DRAWER_WIDTH = 120;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: FIXED_DRAWER_WIDTH,
    flexShrink: 0,
  },
  drawerPaper: {
    width: FIXED_DRAWER_WIDTH,
  },
  selectedItem: {
    height: '80px',
    justifyContent: 'center',
    backgroundColor: WarmlyColor.SELECTED_BLUE,
    borderRadius: '0 8px 8px 0',
    '&:hover': {
      backgroundColor: WarmlyColor.SELECTED_BLUE,
    },
    '& .MuiListItemIcon-root': {
      justifyContent: 'center',
      color: theme.palette.primary.main,
    },
    '& .MuiListItemText-primary': {
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightBold,
    },
  },
  notSelectedItem: {
    height: '80px',
    justifyContent: 'center',
    '&:hover': {
      borderRadius: '0 8px 8px 0',
    },
    '& .MuiListItemIcon-root': {
      justifyContent: 'center',
    },
    '& .MuiListItemText-primary': {
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
  list: {
    padding: '0px',
    '& .MuiListItemText-root': {
      color: WarmlyColor.GRAY,
      '& .MuiListItemText-primary': {
        textAlign: 'center',
        fontSize: '0.8rem',
        fontWeight: 'bold',
      },
    },
  },
}));

const NavListItemIcon = withStyles({
  root: {
    minWidth: 30,
  },
})(ListItemIcon);

const adminSubMenuItems: SubMenuItem[] = [
  {
    navigationPath: NavigationPath.ADMIN_CLIENT_MANAGEMENT,
    navigationLabel: 'One client',
  },
  {
    navigationPath: NavigationPath.ADMIN_ALL_CLIENTS_MANAGEMENT,
    navigationLabel: 'All clients',
  },
  {
    navigationPath: NavigationPath.ADMIN_QA_MANAGEMENT,
    navigationLabel: 'QA',
  },
  {
    navigationPath: NavigationPath.ADMIN_USERS_MANAGEMENT,
    navigationLabel: 'Users',
  },
  {
    navigationPath: NavigationPath.ADMIN_CLOUD_TASKS,
    navigationLabel: 'Cloud tasks',
  },
];

const Sidebar = () => {
  const { selectedClient: client } = useContext(ClientContext);
  const { user } = useContext(UserContext);

  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  // Separate user's email name and domain into two lines
  const userEmailSplit = user?.email.split('@');
  const userEmailName = userEmailSplit[0].concat('@');
  const userEmailDomain = userEmailSplit[1];

  const navigateTo = (destination: NavigationPath) => {
    return () => {
      history.push(destination);
    };
  };

  const navigateToURL = (destination: string) => {
    return () => {
      // rel='noopener' Keeps You Safe and Fast
      // https://love2dev.com/blog/rel-noopener/#:~:text=When%20a%20hyperlink%20opens%20a,hyperlink%20using%20the%20target%20attribute.
      window.open(destination, '_blank', 'noopener');
    };
  };

  const logOut = () => {
    history.push(NavigationPath.LOGOUT);
  };

  const getSelectedClassName = (pathName: NavigationPath) => {
    return location.pathname.startsWith(pathName) ? classes.selectedItem : classes.notSelectedItem;
  };

  const UserClientTooltipInfo: JSX.Element = (
    <Box textAlign="center" style={{ overflowWrap: 'anywhere', cursor: 'default' }}>
      <Typography component="div" variant="caption">
        <Box fontWeight="fontWeightBold">Logged in as:</Box>
        <Box fontSize="0.7rem">{userEmailName}</Box>
        <Box fontSize="0.7rem" marginTop="-5px">
          {userEmailDomain}
        </Box>
        <Box fontSize="0.8rem" fontWeight="fontWeightBold" color={WarmlyColor.GRAY}>
          {client?.name}
        </Box>
      </Typography>
    </Box>
  );

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="left"
    >
      <Box textAlign="center" marginTop={4} marginBottom={2}>
        <Icon>
          <img style={{ width: 100 }} alt="warmly-logo" src={WARMLY_LOGO_VERTICAL_COLOR} />
        </Icon>
      </Box>

      <List className={classes.list}>
        <ListItem
          button
          onClick={navigateTo(NavigationPath.JOB_CHANGES)}
          className={getSelectedClassName(NavigationPath.JOB_CHANGES)}
        >
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            <NavListItemIcon>
              <WorkOutlineIcon />
            </NavListItemIcon>
            <ListItemText primary="Job changes" />
          </Box>
        </ListItem>

        <ListItem
          button
          onClick={navigateTo(NavigationPath.WARM_COMPANIES)}
          className={getSelectedClassName(NavigationPath.WARM_COMPANIES)}
        >
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            <NavListItemIcon>
              <BusinessOutlinedIcon />
            </NavListItemIcon>
            <ListItemText primary="Warm companies" />
          </Box>
        </ListItem>

        <ListItem
          button
          onClick={navigateTo(NavigationPath.ENRICHED_CRM)}
          className={getSelectedClassName(NavigationPath.ENRICHED_CRM)}
        >
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            <NavListItemIcon>
              <PeopleOutlineIcon />
            </NavListItemIcon>
            <ListItemText primary="Contacts" />
          </Box>
        </ListItem>

        {client?.integrationType !== IntegrationType.Salesforce && client?.integrationType !== IntegrationType.HubSpot && (
          <ListItem
            button
            onClick={navigateTo(NavigationPath.UPLOAD)}
            className={getSelectedClassName(NavigationPath.UPLOAD)}
          >
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
              <NavListItemIcon>
                <CloudUploadOutlinedIcon />
              </NavListItemIcon>
              <ListItemText primary="Upload" />
            </Box>
          </ListItem>
        )}

        {client?.integrationType === IntegrationType.Salesforce && (
          <ListItem
            button
            onClick={navigateTo(NavigationPath.INTEGRATION_SALESFORCE)}
            className={getSelectedClassName(NavigationPath.INTEGRATION_SALESFORCE)}
          >
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
              <NavListItemIcon>
                <FontAwesomeIcon size="lg" icon={faSalesforce} color={WarmlyColor.GRAY} />
              </NavListItemIcon>
              <ListItemText primary="Salesforce integration" />
            </Box>
          </ListItem>
        )}

        {client?.integrationType === IntegrationType.HubSpot && (
          <ListItem
            button
            onClick={navigateTo(NavigationPath.INTEGRATION_HUBSPOT)}
            className={getSelectedClassName(NavigationPath.INTEGRATION_HUBSPOT)}
          >
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
              <NavListItemIcon>
                <FontAwesomeIcon size="lg" icon={faHubspot} color={WarmlyColor.GRAY} />
              </NavListItemIcon>
              <ListItemText primary="HubSpot integration" />
            </Box>
          </ListItem>
        )}

        <ListItem
          button
          onClick={navigateTo(NavigationPath.USERS)}
          className={getSelectedClassName(NavigationPath.USERS)}
        >
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            <NavListItemIcon>
              <SupervisedUserCircleIcon />
            </NavListItemIcon>
            <ListItemText primary="Users" />
          </Box>
        </ListItem>

        <ListItem button onClick={navigateToURL(WarmlyUrl.Help)} className={classes.notSelectedItem}>
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            <NavListItemIcon>
              <LibraryBooksOutlinedIcon />
            </NavListItemIcon>
            <ListItemText primary="Help center" />
          </Box>
        </ListItem>

        <ListItem
          button
          onClick={navigateTo(NavigationPath.CONTACT_US)}
          className={getSelectedClassName(NavigationPath.CONTACT_US)}
        >
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            <NavListItemIcon>
              <ContactSupportOutlinedIcon />
            </NavListItemIcon>
            <ListItemText primary="Contact us" />
          </Box>
        </ListItem>

        <ListItem
          button
          onClick={navigateTo(NavigationPath.SETTINGS)}
          className={getSelectedClassName(NavigationPath.SETTINGS)}
        >
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            <NavListItemIcon>
              <SettingsOutlinedIcon />
            </NavListItemIcon>
            <ListItemText primary="Settings" />
          </Box>
        </ListItem>

        {user?.role === UserRole.INTERNAL && (
          <>
            <ListItem
              button
              onClick={navigateTo(NavigationPath.ADMIN)}
              className={getSelectedClassName(NavigationPath.ADMIN)}
            >
              <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                <NavListItemIcon>
                  <VerifiedUserOutlinedIcon />
                </NavListItemIcon>
                <ListItemText primary="Admin" />
              </Box>
            </ListItem>
            {location.pathname.startsWith(NavigationPath.ADMIN) && <SubMenu subMenuItems={adminSubMenuItems} />}
          </>
        )}

        <LightTooltip title={UserClientTooltipInfo} placement="right">
          <ListItem button onClick={logOut} className={classes.notSelectedItem}>
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
              <NavListItemIcon>
                <ExitToAppOutlinedIcon />
              </NavListItemIcon>
              <ListItemText primary="Logout" />
            </Box>
          </ListItem>
        </LightTooltip>
      </List>
    </Drawer>
  );
};

export default Sidebar;
