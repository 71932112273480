import { Box, CircularProgress } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Alert } from '@material-ui/lab';
import React, { useState } from 'react';

import { ClientExternalFragment } from '../../../../graphql';
import { GQLHooks } from '../../../../graphql/hasura/react';
import { AlertSeverity } from '../../../../utils/constants';
import { getApiErrorMessage } from '../../../../utils/errors';

interface Props {
  client: ClientExternalFragment;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const ResetClientIntegrationModal: React.FC<Props> = ({ client, isOpen, setIsOpen }) => {
  const [alert, setAlert] = useState<AlertData | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const [deleteHubspotContactListContacts] = GQLHooks.Models.HubspotContactListHubspotContact.useRemoveObjects();
  const [deleteHubspotContactList] = GQLHooks.Models.HubspotContactList.useRemoveObjects();
  const [deleteHubspotContactProperty] = GQLHooks.Models.HubspotContactProperty.useRemoveObjects();
  const [deleteHubspotIntegration] = GQLHooks.Models.HubspotIntegration.useRemoveObjects();
  const [deleteSalesforceContactField] = GQLHooks.Models.SalesforceContactField.useRemoveObjects();
  const [deleteSalesforceIntegration] = GQLHooks.Models.SalesforceIntegration.useRemoveObjects();
  const [updateClient] = GQLHooks.Fragments.ClientExternal.useUpdateById();

  const handleClose = () => {
    setAlert(undefined);
    setIsLoading(false);
    setIsOpen(false);
  };

  const onConfirmResetClientIntegration = async () => {
    setAlert(undefined);
    setIsLoading(true);
    try {
      const queryFilter = {
        variables: {
          where: {
            clientId: {
              _eq: client.id,
            },
          },
        },
      };

      console.log(`Deleting integration data for ${client.name}`);

      await updateClient({
        clientId: client.id,
        set: {
          hubspotIntegrationId: null,
          salesforceIntegrationId: null,
          integrationType: null,
          syncStatus: null,
        },
      });
      console.log(`Updated client integration IDs to be null`);

      await deleteHubspotContactListContacts(queryFilter);
      console.log('List contacts deleted!');
      await deleteHubspotContactList(queryFilter);
      console.log('Lists deleted!');
      await deleteHubspotContactProperty(queryFilter);
      console.log('Contact properties deleted!');
      await deleteHubspotIntegration(queryFilter);
      console.log('Hubspot integration deleted!');
      await deleteSalesforceContactField(queryFilter);
      console.log('Salesforce contact fields deleted!');
      await deleteSalesforceIntegration(queryFilter);
      console.log('Salesforce integration deleted!');
      console.log('Integration reset');
      setAlert({
        severity: AlertSeverity.SUCCESS,
        message: 'Client integration successfully reset',
      });
    } catch (err) {
      const errorMessage = getApiErrorMessage(err);
      setAlert({
        severity: AlertSeverity.ERROR,
        message: errorMessage,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} aria-labelledby="reset-integration">
      <DialogTitle>Reset Client Integration for {client.name}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          This will reset client CRM integration for {client.name}. For example, for HubSpot clients, this will delete
          their hubspotIntegration field, hubspotId, etc. After which, they will see the normal "Upload" page instead of
          the HubSpot integration page, and they can re-integrate with HubSpot or another CRM / CSV. This action will
          not delete their contacts. Note that this will also delete HubSpot contact lists and all HubSpot/Salesforce
          fields.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {isLoading && (
          <Box marginTop={1}>
            <CircularProgress color="primary" />
          </Box>
        )}
        {!(alert?.severity === AlertSeverity.SUCCESS) ? (
          <>
            <Button onClick={handleClose} variant="outlined" color="primary" disabled={isLoading}>
              Cancel
            </Button>
            <Button onClick={onConfirmResetClientIntegration} variant="contained" color="primary" disabled={isLoading}>
              Confirm
            </Button>
          </>
        ) : (
          <Button onClick={handleClose} variant="outlined" color="primary" disabled={isLoading}>
            Ok
          </Button>
        )}
      </DialogActions>
      <Box>{alert && <Alert severity={alert.severity}>{alert.message}</Alert>}</Box>
    </Dialog>
  );
};

export default ResetClientIntegrationModal;
