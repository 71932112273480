import { Box, Checkbox, Grid, IconButton, Tooltip, Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import InfoIcon from '@material-ui/icons/Info';
import { Skeleton } from '@material-ui/lab';
import React, { useContext, useState } from 'react';

import { UserContext } from '../../../components/auth/UserRouter';
import { UserFragment } from '../../../graphql';
import { GQLHooks } from '../../../graphql/hasura/react';
import { UserClientPermission, UserRole } from '../../../utils/constantsAll';
import { ClientContext } from '../../Main';
import AdminUserInfoModal from './AdminUserInfoModal';

const UsersTable: React.FC = () => {
  const { selectedClientId } = useContext(ClientContext);
  const { user } = useContext(UserContext);
  const [showAdminUserInfoModal, setShowAdminUserInfoModal] = useState(false);

  const { objects: users, refetch: refetchUsers } = GQLHooks.Fragments.User.useQueryObjects({
    variables: {
      where: {
        clientId: {
          _eq: selectedClientId,
        },
      },
    },
  });

  const [updateUser] = GQLHooks.Fragments.User.useUpdateById();

  const onChangeUserAdmin = async (userToUpdate: UserFragment) => {
    if (user.clientPermission !== UserClientPermission.Admin) {
      return;
    }

    if (userToUpdate.clientPermission === UserClientPermission.Admin) {
      await updateUser({
        userId: userToUpdate.id,
        set: {
          clientPermission: null,
        },
      });

      refetchUsers && refetchUsers();
    } else {
      await updateUser({
        userId: userToUpdate.id,
        set: {
          clientPermission: UserClientPermission.Admin,
        },
      });

      refetchUsers && refetchUsers();
    }
  };

  return (
    <Grid container item direction="column" xs={11} lg={6} spacing={2}>
      <Grid item>
        <Typography variant="h5">Current users</Typography>
      </Grid>
      <Grid item>
        {users ? (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell align="left">Email</TableCell>
                  <TableCell align="left">
                    Admin
                    <IconButton
                      color="primary"
                      aria-label="more-info-admin-users"
                      onClick={() => setShowAdminUserInfoModal(true)}
                    >
                      <InfoIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map((clientUser) => (
                  <TableRow key={clientUser.id}>
                    <TableCell component="th" scope="row">
                      <Box>{`${clientUser.firstName} ${clientUser.lastName}`}</Box>
                      {user.role === UserRole.INTERNAL ? <Box>({clientUser.role})</Box> : null}
                    </TableCell>
                    <TableCell align="left">{clientUser.email}</TableCell>
                    <TableCell align="left">
                      <Tooltip
                        title={
                          user.clientPermission !== UserClientPermission.Admin
                            ? 'Only admins can make other users admins'
                            : ''
                        }
                      >
                        <Box>
                          <Checkbox
                            checked={clientUser.clientPermission === UserClientPermission.Admin}
                            disabled={user.clientPermission !== UserClientPermission.Admin || clientUser.id === user.id}
                            onChange={() => onChangeUserAdmin(clientUser)}
                          />
                        </Box>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Skeleton variant="rect" height={80} />
        )}
      </Grid>

      <AdminUserInfoModal isOpen={showAdminUserInfoModal} setIsOpen={setShowAdminUserInfoModal} />
    </Grid>
  );
};

export default UsersTable;
