import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  TextField,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useState } from 'react';

import { ClientInternalFragment } from '../../../../graphql';
import { deleteClient, sendWeeklyJobChangeReport } from '../../../../modules/admin';
import { logError } from '../../../../modules/analytics';
import { AlertSeverity } from '../../../../utils/constants';
import { getApiErrorMessage } from '../../../../utils/errors';
import CreateProxyCrawlTaskModal from './clientManagementActions/CreateProxyCrawlTaskModal';

interface Props {
  client: ClientInternalFragment;
}

const ClientManagementActions: React.FC<Props> = ({ client }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [showDeleteClientModal, setShowDeleteClientModal] = useState(false);
  const [showEnrichQATasksModal, setShowEnrichQATasksModal] = useState(false);
  const [showUpdateTaskPriority, setShowUpdateTaskPriority] = useState(false);
  const [showSendWeeklyReportModal, setShowWeeklyReportModal] = useState(false);
  const [showCreateProxyCrawlTaskModal, setShowCreateProxyCrawlTaskModal] = useState(false);
  const [QATasksCreated, setQATasksCreated] = useState('999999999'); // Tasks created is set high so that by default we create tasks for all client contacts
  const [QATaskPriority, setQATaskPriority] = useState('3'); // Task priority is set to 1 as highest priority
  const [testEmail, setTestEmail] = useState('alan@warmly.ai');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleActionMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onCloseMenu = () => {
    setAnchorEl(null);
  };

  const onCloseModals = () => {
    setErrorMessage('');
    setIsLoading(false);
    setShowDeleteClientModal(false);
    setShowEnrichQATasksModal(false);
    setShowWeeklyReportModal(false);
    setShowUpdateTaskPriority(false);
  };

  const onClickDeleteClient = () => {
    setShowDeleteClientModal(true);
    setAnchorEl(null);
  };

  const onClickCreateEnrichQATasks = () => {
    setShowEnrichQATasksModal(true);
    setAnchorEl(null);
  };

  const onChangeTasksCreatedLimit = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQATasksCreated(event.target.value);
  };

  const onChangeQAPriority = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQATaskPriority(event.target.value);
  };

  const onChangeTestEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTestEmail(event.target.value);
  };

  const onClickUpdateTaskPriority = () => {
    setShowUpdateTaskPriority(true);
    setAnchorEl(null);
  };

  const onClickSendWeeklyReportModal = () => {
    setShowWeeklyReportModal(true);
    setAnchorEl(null);
  };

  const onClickCreateProxyCrawlTask = () => {
    setShowCreateProxyCrawlTaskModal(true);
    setAnchorEl(null);
  };

  const onConfirmDeleteClient = async () => {
    setIsLoading(true);

    try {
      await deleteClient(client.id);
      onCloseModals();
    } catch (err) {
      const errMessage = getApiErrorMessage(err);
      setErrorMessage(errMessage);
      logError(err, `Error while deleting client - ${errMessage}`);
      setIsLoading(false);
    }
  };

  const onConfirmCreateQAEnrichTasks = async () => {
    setIsLoading(true);

    try {
      alert('This action is currently disabled. Please use the QA management page.');
      // await createQaTasks({clientId: client.id, limit: +QATasksCreated, priority: +QATaskPriority});
      onCloseModals();
    } catch (err) {
      const errMessage = getApiErrorMessage(err);
      logError(err, `Error while deleting client - ${errMessage}`);
      setIsLoading(false);
    }
  };

  const onConfirmUpdateQATaskPriority = async () => {
    setIsLoading(true);

    try {
      // TODO low priority - change client QA priority
      // await updateQATaskPriority(client.id, QATaskPriority);
      onCloseModals();
    } catch (err) {
      const errMessage = getApiErrorMessage(err);
      logError(err, `Error while updating task priority for client - ${errMessage}`);
      setIsLoading(false);
    }
  };

  const onConfirmSendTestEmail = async () => {
    setIsLoading(true);

    try {
      await sendWeeklyJobChangeReport(client.id, testEmail);
      onCloseModals();
    } catch (err) {
      const errMessage = getApiErrorMessage(err);
      setErrorMessage(errMessage);
      logError(err, `Error while sending test job change report email  - ${errMessage}`);
      setIsLoading(false);
    }
  };

  const onConfirmSendEmail = async () => {
    setIsLoading(true);

    try {
      await sendWeeklyJobChangeReport(client.id);
      onCloseModals();
    } catch (err) {
      const errMessage = getApiErrorMessage(err);
      setErrorMessage(errMessage);
      logError(err, `Error while scraping all contacts - ${errMessage}`);
      setIsLoading(false);
    }
  };

  return (
    <Grid container justify="center">
      <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleActionMenuClick}>
        <FontAwesomeIcon icon={faBars} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        onClose={onCloseMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {/* TODO medium priority - client management actions */}
        <MenuItem onClick={onClickDeleteClient}>Delete Client (Not working)</MenuItem>
        <MenuItem onClick={onClickCreateEnrichQATasks}>Create QA Enrich Tasks (Not working)</MenuItem>
        <MenuItem onClick={onClickUpdateTaskPriority}>Update Task Priority (Not working)</MenuItem>
        <MenuItem onClick={onClickSendWeeklyReportModal}>Send Job Change Report</MenuItem>
        <MenuItem onClick={onClickCreateProxyCrawlTask} disabled={isLoading}>
          Create ProxyCrawl Task
        </MenuItem>
      </Menu>

      {/* Deletes the client and all associated client contacts */}
      <Dialog open={showDeleteClientModal} onClose={onCloseModals}>
        <DialogTitle>
          <span role="img" aria-label="surprise">
            🙀
          </span>
          Deleting the client {client.name} will delete all their client contacts (the contact data will be unaffected),
          are you sure?
        </DialogTitle>
        <DialogContent>{errorMessage && <Alert severity={AlertSeverity.ERROR}>{errorMessage}</Alert>}</DialogContent>
        <DialogActions>
          {isLoading && (
            <Box marginRight={2}>
              <CircularProgress />
            </Box>
          )}
          <Button variant="outlined" color="primary" onClick={onCloseModals} disabled={isLoading}>
            No
          </Button>
          <Button variant="contained" color="primary" onClick={onConfirmDeleteClient} disabled={isLoading}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      {/* Create QA tasks from all client contacts  */}
      <Dialog open={showEnrichQATasksModal} onClose={onCloseModals}>
        <DialogTitle>Create QA enrich contact tasks for {client.name}.</DialogTitle>
        <Box paddingRight={10} paddingLeft={10}>
          <TextField
            value={QATasksCreated}
            onChange={onChangeTasksCreatedLimit}
            disabled={isLoading}
            size="small"
            type="number"
            margin="dense"
            fullWidth
            label={'tasks created limit'}
          />
          <TextField
            value={QATaskPriority}
            onChange={onChangeQAPriority}
            disabled={isLoading}
            size="small"
            type="number"
            margin="dense"
            fullWidth
            label={'Priority (1 = highest)'}
          />
        </Box>
        <DialogContent>{errorMessage && <Alert severity={AlertSeverity.ERROR}>{errorMessage}</Alert>}</DialogContent>
        <DialogActions>
          {isLoading && (
            <Box marginRight={2}>
              <CircularProgress />
            </Box>
          )}
          <Button variant="outlined" color="primary" onClick={onCloseModals} disabled={isLoading}>
            No
          </Button>
          <Button variant="contained" color="primary" onClick={onConfirmCreateQAEnrichTasks} disabled={isLoading}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      {/* Create Update Task Priority from all client contacts  */}
      <Dialog open={showUpdateTaskPriority} onClose={onCloseModals}>
        <DialogTitle>Update Task Priority of all open tasks for {client.name}.</DialogTitle>
        <Box paddingRight={10} paddingLeft={10}>
          <TextField
            value={QATaskPriority}
            onChange={onChangeQAPriority}
            disabled={isLoading}
            size="small"
            type="number"
            margin="dense"
            fullWidth
            label={'Priority (1 = highest)'}
          />
        </Box>
        <DialogContent>{errorMessage && <Alert severity={AlertSeverity.ERROR}>{errorMessage}</Alert>}</DialogContent>
        <DialogActions>
          {isLoading && (
            <Box marginRight={2}>
              <CircularProgress />
            </Box>
          )}
          <Button variant="outlined" color="primary" onClick={onCloseModals} disabled={isLoading}>
            No
          </Button>
          <Button variant="contained" color="primary" onClick={onConfirmUpdateQATaskPriority} disabled={isLoading}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      {/* Send weekly report email and test email */}
      <Dialog open={showSendWeeklyReportModal} onClose={onCloseModals}>
        <DialogTitle>
          <span role="img" aria-label="surprise">
            💌
          </span>
          Send weekly job change report email to {client.name}
        </DialogTitle>

        <DialogContent>{errorMessage && <Alert severity={AlertSeverity.ERROR}>{errorMessage}</Alert>}</DialogContent>
        <Box paddingRight={10} paddingLeft={10}>
          <TextField
            value={testEmail}
            onChange={onChangeTestEmail}
            disabled={isLoading}
            size="medium"
            margin="dense"
            fullWidth
            label={'Test Email'}
          />
        </Box>
        <DialogActions>
          {isLoading && (
            <Box marginRight={2}>
              <CircularProgress />
            </Box>
          )}
          <Button variant="outlined" color="primary" onClick={onCloseModals} disabled={isLoading}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={onConfirmSendTestEmail} disabled={isLoading}>
            Send Test Email
          </Button>
          <Button variant="contained" color="secondary" onClick={onConfirmSendEmail} disabled={isLoading}>
            Send Email
          </Button>
        </DialogActions>
      </Dialog>

      <CreateProxyCrawlTaskModal
        client={client}
        isOpen={showCreateProxyCrawlTaskModal}
        setIsOpen={setShowCreateProxyCrawlTaskModal}
      />
    </Grid>
  );
};

export default ClientManagementActions;
