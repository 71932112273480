import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Link } from '@material-ui/core';
import React from 'react';

import { WARMLY_EMAIL } from '../../utils/constants';

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  jobChangesOnly?: boolean;
}

const ExportCSVModal: React.FC<Props> = ({ isOpen, setIsOpen, jobChangesOnly }) => {
  const onCloseModal = () => {
    setIsOpen(false);
  };

  return (
    <Dialog open={isOpen} onClose={onCloseModal}>
      <DialogTitle>Export Contacts to CSV</DialogTitle>
      <DialogContent>
        <Box>
          By clicking the Email me CSV button, we will export
          { jobChangesOnly ? ' all job changes data, including sales opportunity and customer success, '
            : ' all of your contacts in Warmly, including job change and enriched data, '}
          to a CSV. On average, we expect the process to take 1-2 minutes for every 10,000 contacts. The CSV will be provided to you via a download link sent to your email.
        </Box>
        <Box marginTop={2}>
          As always, if you have any questions, please contact us at{' '}
          <Link
            href={`https://mail.google.com/mail/?view=cm&fs=1&to=${WARMLY_EMAIL.CSM}&su=[Warmly] Question about CSV Export`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {WARMLY_EMAIL.CSM}
          </Link>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={onCloseModal}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExportCSVModal;
