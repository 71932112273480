import { Box, Chip, Grid, Tooltip, Typography } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { TabContext, TabPanel } from '@material-ui/lab';
import React, { useContext, useState } from 'react';

import { UserContext } from '../../components/auth/UserRouter';
import { UserClientPermission, WarmlyColor } from '../../utils/constants';
import TerritoryOwnerMapping from './TerritoryOwnerMapping';
import InviteUser from './users/InviteUser';
import UsersTable from './users/UsersTable';

const useStyles = makeStyles((theme) =>
  createStyles({
    appBar: {
      boxShadow: 'none',
      borderBottom: `1px solid ${WarmlyColor.DISABLED_GRAY}`,
      width: 'auto',
      '& .MuiTab-root': {
        display: 'inline-block',
        minWidth: 'auto',
        fontSize: '1rem',
        fontWeight: theme.typography.fontWeightBold,
        textTransform: 'none',
      },
      '& .MuiTab-textColorInherit.Mui-selected': {
        color: theme.palette.primary.main,
      },
    },
    indicator: {
      borderRadius: '4px 4px 0 0',
      backgroundColor: theme.palette.primary.main,
      height: '3px',
    },
    tabPanel: {
      width: '100%',
      paddingLeft: 0,
    },
  })
);

enum TabValue {
  OwnerMapping = 'OwnerMapping',
  UserPermissions = 'UserPermissions',
}

const Users = () => {
  const [tabValue, setTabValue] = useState(TabValue.OwnerMapping);
  const { user } = useContext(UserContext);

  const classes = useStyles();

  const handleTabChange = (_e: React.ChangeEvent<{}>, newTabValue: TabValue) => {
    setTabValue(newTabValue);
  };

  return (
    <Grid container direction="column" justify="flex-start" alignItems="flex-start" spacing={3}>
      <Grid container item>
        <Box fontWeight="bold" marginLeft={1}>
          <Typography component="div" variant="h1">
            Users Management
          </Typography>
        </Box>
      </Grid>

      <Grid container item>
        <TabContext value={tabValue}>
          <AppBar position="static" color="transparent" className={classes.appBar}>
            <Tabs
              classes={{ indicator: classes.indicator }}
              value={tabValue}
              onChange={handleTabChange}
              aria-label="users tabs"
            >
              {user.clientPermission === UserClientPermission.Admin ? (
                <Tab
                  label={
                    <Box>
                      Owner Mapping{' '}
                      <Tooltip title="As an Admin user, you have the permission to make changes to owner mapping">
                        <Chip label="Admin" color="secondary" />
                      </Tooltip>
                    </Box>
                  }
                  value={TabValue.OwnerMapping}
                />
              ) : (
                'Owner Mapping'
              )}
              <Tab label="Users & Permissions" value={TabValue.UserPermissions} />
            </Tabs>
          </AppBar>
          <TabPanel value={TabValue.OwnerMapping} className={classes.tabPanel}>
            <TerritoryOwnerMapping />
          </TabPanel>
          <TabPanel value={TabValue.UserPermissions} className={classes.tabPanel}>
            <Grid container item direction="column" alignItems="flex-start" spacing={2}>
              <InviteUser />
              <UsersTable />
            </Grid>
          </TabPanel>
        </TabContext>
      </Grid>
    </Grid>
  );
};

export default Users;
