import { 
  Box, 
  Button,
  DialogActions,
  Grid,
  IconButton, 
  Popover,
  Slider,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles, } from '@material-ui/core/styles';
import FilterListIcon from '@material-ui/icons/FilterList';
import { isEqual } from 'lodash';
import React, { useState } from 'react';

const MIN_SLIDER_VALUE = 0;
const MAX_SLIDER_VALUE = 250;
const SLIDER_RANGE = [MIN_SLIDER_VALUE, MAX_SLIDER_VALUE];
const MIN_EMPLOYEE_COUNT = 0;
const MAX_EMPLOYEE_COUNT = 500000;

const useStyles = makeStyles((theme) =>
  createStyles({
    filterIconButton: {
      padding: '2px',
    },
    slider: {
      width: 260,
    },
    sliderDisplayedValue: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      borderRadius: '4px',
      fontSize: 12,
      textAlign: 'center',
      width: '40px',
    }
  }),
);

const marks = [
  {
    value: 0,
    scaledValue: MIN_EMPLOYEE_COUNT,
    label: "1"
  },
  {
    value: 25,
    scaledValue: 10,
  },
  {
    value: 50,
    scaledValue: 50,
  },
  {
    value: 75,
    scaledValue: 200,
  },
  {
    value: 100,
    scaledValue: 500,
  },
  {
    value: 125,
    scaledValue: 1000,
  },
  {
    value: 150,
    scaledValue: 5000,
  },
  {
    value: 175,
    scaledValue: 10000,
  },
  {
    value: 200,
    scaledValue: 50000,
  },
  {
    value: 225,
    scaledValue: 100000,
  },
  {
    value: 250,
    scaledValue: MAX_EMPLOYEE_COUNT,
    label: "500K"
  }
];
interface Props {
  filter?: JobChangeFilter;
  handleAddOrUpdateFilter: (filter: JobChangeFilter) => void;
  handleClearFilter: (filter: JobChangeFilter) => void;
}

const FilterByCompanySizePopover: React.FC<Props> = ({ filter, handleAddOrUpdateFilter, handleClearFilter }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const getInitialFilteredRange = (): number[] => {
    let initialFilteredRange = SLIDER_RANGE;
    if (filter && filter.employeeCount && filter.employeeCount.sliderRange) {
      initialFilteredRange = filter.employeeCount.sliderRange;
    }
    return initialFilteredRange;
  }

  const [value, setValue] = useState<number[]>(getInitialFilteredRange());

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setValue(getInitialFilteredRange());
  };

  const handleClose = () => {
    setAnchorEl(null);
    setValue(SLIDER_RANGE);
  };

  const handleSliderChange = (event: any, newValue: number | number[]) => {
    const newValues = newValue as number[];
    setValue(newValues);
  };

  const onClickApply = () => {
    if (isEqual(value, SLIDER_RANGE)) {
      if (!filter) {
        handleClose();
      } else {
        handleClearFilter(filter);
      }
    } else {
      handleAddOrUpdateFilter({
        employeeCount: {
          range: [scale(value[0]), scale(value[1])],
          sliderRange: value,
        },
      });
    }
  };

  const scale = (value: number) => {
    if (value === 0) {
      return 0;
    }
    const prevMarkIndex = Math.floor(value / 25);
    const prevMark = marks[prevMarkIndex];
    const remainder = value % 25;
    if (remainder === 0) {
      return prevMark.scaledValue;
    }
    const nextMark = marks[prevMarkIndex + 1];
    const increment = (nextMark.scaledValue - prevMark.scaledValue) / 25;
    return remainder * increment + prevMark.scaledValue;
  };

  const valueText = (value: number) => {
      return `${value} employees`;   
  };

  const formatNumValue = (value: number) => {
    if (value === 0) {
      return 1;
    } else if (value > 999) {
      return (value / 1000).toFixed(0) + "K";
    } else if (value < 1000) {
      return value;
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? 'filter-by-company-size-popover' : undefined;

  return (
    <Box>
      <IconButton aria-label="filter" onClick={handleClick} className={classes.filterIconButton}>
        <FilterListIcon fontSize="small"/>
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box padding={1}>
          <Grid container item direction="column" alignItems="center" justify="center">
            <Grid item style={{ paddingBottom: '8px', width: '300px'}}>
              <Typography id="range-slider">
                Filter by size of company
              </Typography>
              <Typography variant="caption" gutterBottom>
                (number of employees on Linkedin)
              </Typography>
            </Grid>
            <Grid container item direction="row" alignItems="center" justify="space-between">
              <Grid item>
                <Box className={classes.sliderDisplayedValue}>
                  {formatNumValue(scale(value[0])) as string}
                </Box>
              </Grid>
              <Grid item>
                <Box className={classes.sliderDisplayedValue}>
                  {formatNumValue(scale(value[1])) as string}
                </Box>
              </Grid>
            </Grid>
            <Grid item>
              <Box paddingTop={1}>
                <Slider
                  className={classes.slider}
                  value={value}
                  min={MIN_SLIDER_VALUE}
                  step={5}
                  max={MAX_SLIDER_VALUE}
                  marks={marks}
                  scale={scale}
                  onChange={handleSliderChange}
                  valueLabelDisplay="off"
                  valueLabelFormat={formatNumValue}
                  aria-labelledby="company-size-range-slider"
                  getAriaValueText={valueText}
                />
              </Box>
            </Grid>
            <Grid item>
              <DialogActions>
                <Button variant="outlined" autoFocus onClick={() => setValue(SLIDER_RANGE)} color="primary">
                  Reset
                </Button>
                <Button variant="contained" onClick={onClickApply} color="primary">
                  Apply
                </Button>
              </DialogActions>
            </Grid>
          </Grid>
        </Box>
      </Popover>
    </Box>
  );
}

export default FilterByCompanySizePopover;