import Axios, { AxiosResponse } from 'axios';

import { gaeApiURL, UserClientPermission } from '../utils/constants';
import { getAuthToken } from './auth';

interface InviteNewUser {
  inviteeEmail: string;
  clientId: string;
  inviteeClientPermission: UserClientPermission | null;
}

export const inviteNewUser = async ({
  inviteeEmail,
  clientId,
  inviteeClientPermission,
}: InviteNewUser): Promise<AxiosResponse<{}>> => {
  const functionURL = gaeApiURL.user.inviteNewUser;
  const body = {
    inviteeEmail,
    inviteeClientPermission,
    clientId,
  };

  const authToken = await getAuthToken();

  return Axios.post(functionURL, body, {
    headers: {
      Authorization: 'Bearer ' + authToken,
    },
  });
};
