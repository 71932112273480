import { Box, makeStyles } from '@material-ui/core';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { NavigationPath } from '../../utils/constants';

const FIXED_MARGIN_LEFT = '22px';

const useStyles = makeStyles((theme) => ({
  selectedItem: {
    marginLeft: FIXED_MARGIN_LEFT,
    cursor: 'pointer',
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.primary.main,
  },
  notSelectedItem: {
    marginLeft: FIXED_MARGIN_LEFT,
    cursor: 'pointer',
    color: theme.palette.grey[600],
  },
}));

export type SubMenuItem = {
  navigationPath: NavigationPath;
  navigationLabel: string;
  openNewTab?: boolean;
};

interface Props {
  subMenuItems: SubMenuItem[];
}

const SubMenu: React.FC<Props> = ({ subMenuItems }) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const navigateTo = (destination: NavigationPath, openNewTab?: boolean) => {
    return () => {
      // rel='noopener' Keeps You Safe and Fast
      // https://love2dev.com/blog/rel-noopener/#:~:text=When%20a%20hyperlink%20opens%20a,hyperlink%20using%20the%20target%20attribute.
      openNewTab ? window.open(destination, '_blank', 'noopener') : history.push(destination);
    };
  };

  const getSelectedClassName = (pathName: NavigationPath) => {
    return location.pathname.startsWith(pathName) ? classes.selectedItem : classes.notSelectedItem;
  };

  return (
    <>
      {subMenuItems.map((item) => {
        return (
          <Box
            key={item.navigationLabel}
            marginBottom={1}
            fontSize="0.8rem"
            className={getSelectedClassName(item.navigationPath)}
            onClick={navigateTo(item.navigationPath, item.openNewTab)}
          >
            {item.navigationLabel}
          </Box>
        );
      })}
    </>
  );
};

export default SubMenu;
