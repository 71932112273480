import { Backdrop, Box, CircularProgress, Grid, makeStyles, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';

import CustomPagination from '../../../components/CustomPagination';
import { ClientContactExternalFragment, Order_By, useQueryClientContactCountQuery } from '../../../graphql';
import { GQLHooks } from '../../../graphql/hasura/react';
import { WarmlyColor } from '../../../utils/constants';
import { ClientContext } from '../../Main';
import PastJobChangeItem from './PastJobChangeItem';

const CONTACTS_PER_PAGE = 10;

const useStyles = makeStyles((theme) => ({
  stickyActionsColumn: {
    '& table:first-child': {
      '& tr': {
        '& td:first-child, th:first-child': {
          backgroundColor: WarmlyColor.LIGHT_BLUE,
          position: 'sticky',
          left: 0,
          zIndex: 999,
        },
        '& th:first-child': {
          zIndex: 9999,
        },
      },
    },
  },
}));

const PastJobChangeTable: React.FC = () => {
  const { selectedClient } = useContext(ClientContext);
  const [currentPage, setCurrentPage] = useState(0);
  const [clientContacts, setClientContacts] = useState<ClientContactExternalFragment[]>([]);

  const classes = useStyles();

  const {
    objects: clientContactsFromQuery,
    loading: loadingClientContacts,
  } = GQLHooks.Fragments.ClientContactExternal.useQueryObjects({
    variables: {
      where: {
        clientId: {
          _eq: selectedClient.id,
        },
        currentJobChange: {
          clientAction: {
            _is_null: false,
          },
        },
      },
      limit: CONTACTS_PER_PAGE,
      offset: currentPage * CONTACTS_PER_PAGE,
      order_by: [
        {
          currentJobChange: {
            clientActionUpdatedAt: Order_By.Desc,
          },
        },
      ],
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    // We use this effect because Apollo resets the result array to empty array
    // between each query, and we don't want to show a "flash of empty page" whenever
    // the user changes page number
    if (!loadingClientContacts && clientContactsFromQuery) {
      setClientContacts(clientContactsFromQuery);
    }
  }, [loadingClientContacts, clientContactsFromQuery]);

  const { data, loading: loadingTotalCount } = useQueryClientContactCountQuery({
    variables: {
      where: {
        clientId: {
          _eq: selectedClient.id,
        },
        currentJobChange: {
          clientAction: {
            _is_null: false,
          },
        },
      },
    },
    fetchPolicy: 'network-only',
  });

  const totalClientContactCount = data?.clientContact_aggregate.aggregate?.totalCount;

  return (
    <>
      {!loadingTotalCount && (
        <>
          <Grid container item direction="row" justify="flex-start" alignItems="center">
            {!loadingTotalCount && totalClientContactCount ? (
              <Grid container direction="row" justify="flex-start" alignItems="center">
                <Grid item>
                  <Box marginLeft={2}>
                    <CustomPagination
                      totalCount={totalClientContactCount}
                      currentPage={currentPage}
                      itemsPerPage={CONTACTS_PER_PAGE}
                      onChangePage={setCurrentPage}
                    />
                  </Box>
                </Grid>
              </Grid>
            ) : null}
          </Grid>
          <Box p={1} />
          <Grid container item direction="column" alignItems="center">
            <Box className={classes.stickyActionsColumn} style={{ maxWidth: '100%', marginRight: 20 }}>
              {clientContacts.map((clientContact, i) => (
                <PastJobChangeItem key={clientContact.id} clientContact={clientContact} showHeaders={i === 0} />
              ))}
            </Box>
          </Grid>
          <Grid container item direction="column" alignItems="center">
            {!totalClientContactCount && !loadingTotalCount && (
              <Typography variant="h6">
                No past job change notifications. The most recent job changes that you APPROVE or DISMISS will appear here.
              </Typography>
            )}
          </Grid>
        </>
      )}
      {!loadingTotalCount && totalClientContactCount ? (
        <Grid container direction="row" justify="flex-start" alignItems="center" style={{ marginTop: 20 }}>
          <Grid item>
            <Box marginLeft={2}>
              <CustomPagination
                totalCount={totalClientContactCount}
                currentPage={currentPage}
                itemsPerPage={CONTACTS_PER_PAGE}
                onChangePage={setCurrentPage}
              />
            </Box>
          </Grid>
        </Grid>
      ) : null}
      <Backdrop open={loadingClientContacts} style={{ zIndex: 10000 }}>
        <Grid container direction="column" alignItems="center" justify="center">
          <Grid item xs>
            <CircularProgress color="primary" />
          </Grid>
        </Grid>
      </Backdrop>
    </>
  );
};

export default PastJobChangeTable;
